/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@clr/angular";
import * as i2 from "@angular/common";
import * as i3 from "./ag-grid-button-renderer.component";
var styles_AgGridButtonRendererComponent = [];
var RenderType_AgGridButtonRendererComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AgGridButtonRendererComponent, data: {} });
export { RenderType_AgGridButtonRendererComponent as RenderType_AgGridButtonRendererComponent };
function View_AgGridButtonRendererComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "clr-icon", [["shape", "plus"]], null, null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i1.ClrIconCustomTag, [], null, null)], null, null); }
function View_AgGridButtonRendererComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "clr-icon", [["shape", "copy"]], null, null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i1.ClrIconCustomTag, [], null, null)], null, null); }
function View_AgGridButtonRendererComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "clr-icon", [["shape", "trash"], ["style", "color:#d23b3b"]], null, null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i1.ClrIconCustomTag, [], null, null)], null, null); }
function View_AgGridButtonRendererComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "clr-icon", [["shape", "angle left"]], null, null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i1.ClrIconCustomTag, [], null, null)], null, null); }
function View_AgGridButtonRendererComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "clr-icon", [["shape", "angle down"]], null, null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i1.ClrIconCustomTag, [], null, null)], null, null); }
function View_AgGridButtonRendererComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "clr-icon", [["shape", "times"], ["style", "color:#d23b3b"]], null, null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i1.ClrIconCustomTag, [], null, null)], null, null); }
function View_AgGridButtonRendererComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "button", [["class", "btn btn-link"], ["type", "button"]], [[8, "title", 0], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AgGridButtonRendererComponent_2)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AgGridButtonRendererComponent_3)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AgGridButtonRendererComponent_4)), i0.ɵdid(6, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AgGridButtonRendererComponent_5)), i0.ɵdid(8, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AgGridButtonRendererComponent_6)), i0.ɵdid(10, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AgGridButtonRendererComponent_7)), i0.ɵdid(12, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(13, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.params.icon === "plus"); _ck(_v, 2, 0, currVal_2); var currVal_3 = (_co.params.icon === "copy"); _ck(_v, 4, 0, currVal_3); var currVal_4 = (_co.params.icon === "trash"); _ck(_v, 6, 0, currVal_4); var currVal_5 = (_co.params.icon === "angle left"); _ck(_v, 8, 0, currVal_5); var currVal_6 = (_co.params.icon === "angle down"); _ck(_v, 10, 0, currVal_6); var currVal_7 = (_co.params.icon === "times"); _ck(_v, 12, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.params.title; var currVal_1 = _co.params.disabled; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_8 = _co.params.buttonCaption; _ck(_v, 13, 0, currVal_8); }); }
export function View_AgGridButtonRendererComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AgGridButtonRendererComponent_1)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.params; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AgGridButtonRendererComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-ag-grid-button-renderer", [], null, null, null, View_AgGridButtonRendererComponent_0, RenderType_AgGridButtonRendererComponent)), i0.ɵdid(1, 49152, null, 0, i3.AgGridButtonRendererComponent, [], null, null)], null, null); }
var AgGridButtonRendererComponentNgFactory = i0.ɵccf("app-ag-grid-button-renderer", i3.AgGridButtonRendererComponent, View_AgGridButtonRendererComponent_Host_0, {}, {}, []);
export { AgGridButtonRendererComponentNgFactory as AgGridButtonRendererComponentNgFactory };
