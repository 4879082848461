import { Injectable } from '@angular/core';
import { MetadataService } from './metadata.service';
import { DataCachingService } from './data-caching.service';
import { Observable, Subject, BehaviorSubject, ReplaySubject, combineLatest } from 'rxjs';
import { InstitutionDataService } from './institution-data.service';
import { StringHelper } from '../../helpers/string-helper';
import { map } from 'rxjs/internal/operators';
import { isArray } from 'rxjs/internal/util/isArray';
import { AddressPersonFioCacheService } from './address-person-fio-cache.service';
import { AppConstants } from '../../app-constants';
import { DateHelper } from '../../helpers/date-helper';

@Injectable()
export class LookupSourceService {

  constructor(private metadataService: MetadataService,
              private dataCachingService: DataCachingService,
              private institutionDataService: InstitutionDataService,
              private personFioCacheService: AddressPersonFioCacheService) {

  }

  private static getMaxCacheSize(lookupName: string) {
    if (lookupName.startsWith('product-subtype')) {
      return 20000;
    }

    return 200;
  }

  public static filterLookup(allValues: any[],
                             filterValueInArrayControlName1: string, filterValueInArrayValue1: any,
                             filterValueInArrayControlName2: string, filterValueInArrayValue2: any,
                             filterArrayIncludeControlName: string, filterArrayIncludeValue: any[]): any[] {
    if (filterValueInArrayValue1) {
      allValues = allValues.filter(el => {
        // если у элемента нет никаких настроек - до их появления считаем, что он проходит фильтрацию
        if (!el[filterValueInArrayControlName1] || !(el[filterValueInArrayControlName1] as any[]).length) {
          return true;
        } else {
          return el[filterValueInArrayControlName1].includes(filterValueInArrayValue1);
        }
      });
    }
    if (filterValueInArrayValue2) {
      allValues = allValues.filter(el => {
        // если у элемента нет никаких настроек - до их появления считаем, что он проходит фильтрацию
        if (!el[filterValueInArrayControlName2] || !(el[filterValueInArrayControlName2] as any[]).length) {
          return true;
        } else {
          return el[filterValueInArrayControlName2].includes(filterValueInArrayValue2);
        }
      });
    }
    if (filterArrayIncludeValue && filterArrayIncludeValue.length) {
      allValues = allValues.filter(el =>
        el[filterArrayIncludeControlName] && filterArrayIncludeValue.includes(el[filterArrayIncludeControlName]));
    }

    return allValues;
  }

  public invalidateLookup(lookupName: string, isStartWith: boolean = false) {
    if (!lookupName) {
      return;
    }
    lookupName = lookupName.toLowerCase();
    if (isStartWith) {
      this.dataCachingService.removeCachedDataByStartKey('LookupSourceService', lookupName);
      this.dataCachingService.removeCachedDataByStartKey('LookupSourceServiceObj', lookupName);
    } else {
      this.dataCachingService.removeCachedData('LookupSourceService', lookupName);
      this.dataCachingService.removeCachedData('LookupSourceServiceObj', lookupName);
    }
  }

  public getLookup(lookupName: string, clone?: boolean, sort?: boolean, showHistory?: boolean): Observable<any[]> {
    if (!lookupName) {
      return new BehaviorSubject([]);
    }

    lookupName = lookupName.toLowerCase();
    let existing: Subject<any[]> = this.dataCachingService.getCachedData('LookupSourceService', lookupName);
    let existingActual: Subject<any[]> = this.dataCachingService.getCachedData('LookupSourceServiceActual', lookupName);

    if (!existing || !existingActual) {
      existing = new ReplaySubject<any[]>();
      existingActual = new ReplaySubject<any[]>();
      this.dataCachingService.addToCache('LookupSourceService', lookupName, existing,
        LookupSourceService.getMaxCacheSize(lookupName));
      this.dataCachingService.addToCache('LookupSourceServiceActual', lookupName, existingActual,
        LookupSourceService.getMaxCacheSize(lookupName));
      existing.subscribe((val: any[]) => {
        let val2 = [];
        if (isArray(val)) {
          val.forEach(el => {
            if (el && !el.dateDeleted) {
              val2.push(el);
            }
          });
        } else {
          val2 = val;
        }

        existingActual.next(val2);
      });
      this.preloadData(lookupName, existing);
    }

    const lookupSliceToUse = showHistory ? existing : existingActual;

    if (clone || sort) {
      return lookupSliceToUse.pipe(map(dict => {
        const newDict = [];
        dict.forEach(el => {
          const newEl = {};
          Object.assign(newEl, el);
          newDict.push(newEl);
        });

        if (sort) {
          newDict.sort((a, b) => a.caption.localeCompare(b.caption));
        }

        return newDict;
      }));
    } else {
      return lookupSliceToUse;
    }
  }

  public getLookupObj(lookupName: string): Observable<any> {
    if (lookupName === 'addr-street') {
      throw new Error('getLookupObj для addr-street не работает');
    }

    lookupName = lookupName.toLowerCase();
    let existing: Subject<any> = this.dataCachingService.getCachedData('LookupSourceServiceObj', lookupName);

    if (existing) {
      return existing;
    } else {
      existing = new ReplaySubject<any>();

      this.getLookup(lookupName, false, false, true).subscribe((arr): any => {
        if (!isArray(arr)) {
          existing.next(arr);
        } else {
          const val = {};

          if (arr) {
            arr.forEach(item => {
              val[item.id] = item.caption + (item.dateDeleted ? ' (удалённая запись)' : '');
              val['Obj' + item.id.toString()] = item;
            });
          }
          existing.next(val);
        }
      });

      this.dataCachingService.addToCache('LookupSourceServiceObj', lookupName, existing,
        LookupSourceService.getMaxCacheSize(lookupName));

      return existing;
    }
  }

  private preloadTwoLevelLookup(lookupName: string, firstLevelLookupName: string, branchFieldName: string,
                                idFieldName: string, existing: Subject<any[]>, parentId: number = null) {

    if (!parentId) {
      this.preloadThreeLevelLookup(lookupName, firstLevelLookupName, branchFieldName, idFieldName, existing);
      return;
    }

    // если мы ищем с parentId,
    // то сначала в кэше проверим наличие данных по firstLevelLookupName и при наличии внутри найдем по parentId, иначе грузим данные
    // либо также грузим, если внутри данных по firstLevelLookupName отсутствуют для parentId
    const existingFirstLevelAll = this.dataCachingService.getCachedData('LookupSourceService', firstLevelLookupName);
    if (existingFirstLevelAll) {
      (existingFirstLevelAll as ReplaySubject<any[]>)
        .subscribe(values => {
          if (values[parentId]) {
            existing.next(values[parentId]);
          } else {
            this.preloadThreeLevelLookup(lookupName, firstLevelLookupName, branchFieldName, idFieldName, existing);
          }
        });
    } else {
      this.preloadThreeLevelLookup(lookupName, firstLevelLookupName, branchFieldName, idFieldName, existing);
    }
  }

  private preloadThreeLevelLookup(lookupName: string, firstLevelLookupName: string, branchFieldName: string,
                                  idFieldName: string, existing: Subject<any[]>) {
    const lookupNameLower = lookupName ? lookupName.toLowerCase() : lookupName;
    const firstLevelLookupNameLower = firstLevelLookupName ? firstLevelLookupName.toLowerCase() : '';

    this.metadataService.getMetadata(firstLevelLookupNameLower).subscribe(val => {
      const entityType = lookupNameLower.substr(firstLevelLookupNameLower.length)
        ? parseInt(lookupNameLower.substr(firstLevelLookupNameLower.length), 10)
        : undefined;
      const allLookupObj = {};

      val.forEach(item => {
        if (!allLookupObj[item[branchFieldName]]) {
          allLookupObj[item[branchFieldName]] = [];
        }
        const cloned = {id: item[idFieldName]};
        Object.assign(cloned, item);
        allLookupObj[item[branchFieldName]].push(cloned);
      });

      let updatedExisting = false;

      for (const key in allLookupObj) {
        if (parseInt(key, 10) === entityType) {
          existing.next(allLookupObj[key]);
          updatedExisting = true;
        } else {
          const byEntityTypeSubj = new ReplaySubject();
          this.dataCachingService.addToCache('LookupSourceService', firstLevelLookupName + key, byEntityTypeSubj,
            LookupSourceService.getMaxCacheSize(firstLevelLookupName));
          byEntityTypeSubj.next(allLookupObj[key]);
        }
      }

      if (!updatedExisting && entityType && !allLookupObj[entityType]) {
        existing.next([]);
      }

      if (lookupNameLower === firstLevelLookupName) {
        existing.next(allLookupObj as any[]);
      } else {
        const allSubj = new ReplaySubject();
        this.dataCachingService.addToCache('LookupSourceService', firstLevelLookupName, allSubj,
          LookupSourceService.getMaxCacheSize(firstLevelLookupName));
        allSubj.next(allLookupObj);
      }
    });
  }

  private preloadData(lookupNameLower: string, existing: Subject<any[]>) {
    if (lookupNameLower.startsWith('animal-breed')) {
      const matches = lookupNameLower.match(/animal-breed(\d+)/);
      this.preloadTwoLevelLookup(lookupNameLower, 'animal-breed', 'animalTypeId', 'id', existing,
        matches ? +matches[1] : null);
    } else if (lookupNameLower.startsWith('animal-subtype')) {
      const matches = lookupNameLower.match(/animal-subtype(\d+)/);
      this.preloadTwoLevelLookup(lookupNameLower, 'animal-subtype', 'animalTypeId', 'id', existing,
        matches ? +matches[1] : null);
    } else if (lookupNameLower.startsWith('product-subtype') && !lookupNameLower.startsWith('product-subtype/stable')) {
      const matches = lookupNameLower.match(/product-subtype(\d+)/);
      this.preloadTwoLevelLookup(lookupNameLower, 'product-subtype', 'productTypeId', 'id', existing,
        matches ? +matches[1] : null);
    } else if (lookupNameLower.startsWith('product-expertise-subtype')) {
      this.preloadTwoLevelLookup(lookupNameLower, 'product-expertise-subtype', 'productExpertiseTypeId', 'id', existing);
    } else if (lookupNameLower.startsWith('institution-indicator-subtype')) {
      this.preloadTwoLevelLookup(lookupNameLower, 'institution-indicator-subtype', 'indicatorTypeId', 'id', existing);
    } else if (lookupNameLower.startsWith('disease-serotype')) {
      this.preloadTwoLevelLookup(lookupNameLower, 'disease-serotype', 'diseaseTypeId', 'id', existing);
    } else if (lookupNameLower.startsWith('sys-query-view')) {
      this.preloadTwoLevelLookup(lookupNameLower, 'sys-query-view', 'entityType', 'id', existing);
    } else if (lookupNameLower.startsWith('addr-city')) {
      if (lookupNameLower === 'addr-city-ex') {
        this.metadataService.getCitiesLookupEx().subscribe(val => {
          existing.next(val);
        });
      } else if (lookupNameLower === 'addr-city') {
        this.metadataService.getCitiesLookup().subscribe(val => {
          existing.next(val);
        });
      } else {
        const matches = lookupNameLower.match(/addr-city(\d+)/);
        if (matches) {
          this.metadataService.getCitiesInRegionLookup(+matches[1]).subscribe(val => {
            existing.next(val);
          });
        } else {
          existing.next([]);
        }
      }
    } else if (lookupNameLower === 'institution') {
      this.institutionDataService.search().subscribe(val => {
        existing.next(val.map(item => {
          return {
            id: item.id,
            caption: item.shortCaption || item.caption
          };
        }));
      });
    } else if (lookupNameLower === 'head-institution') {
      this.institutionDataService.getHeadInstitutions().subscribe(val => {
        existing.next(val.map(item => {
          return {
            id: item.id,
            caption: item.shortCaption || item.caption
          };
        }));
      });
    } else if (lookupNameLower === 'slaughtery-institution') {
      this.institutionDataService.getSlaughteryInstitutions().subscribe(val => {
        existing.next(val.map(item => {
          return {
            id: item.id,
            caption: item.shortCaption || item.caption
          };
        }));
      });
    } else if (lookupNameLower === 'lab-institution') {
      this.institutionDataService.getLabInstitutions().subscribe(val => {
        existing.next(val.map(item => {
          return {
            id: item.id,
            caption: item.shortCaption || item.caption
          };
        }));
      });
    } else if (lookupNameLower === 'all-institution') {
      this.institutionDataService.getAllInstitutions().subscribe(val => {
        existing.next(val.map(item => {
          return {
            id: item.id,
            caption: item.shortCaption || item.caption
          };
        }));
      });
    } else if (lookupNameLower === 'addr-street-type') {
      this.metadataService.getMetadata(lookupNameLower).subscribe(val => existing.next(val));
    } else if (lookupNameLower.startsWith('addr-street-')) {
      const matches = lookupNameLower.match(/addr-street-(\d+)-(\d+)/);
      if (matches) {
        this.metadataService.getStreetsLookup(+matches[1], +matches[2]).subscribe(val => {
          existing.next(val);
        });
      } else {
        existing.next([]);
      }
    } else if (lookupNameLower === 'report-agg-dict') {
      existing.next([{id: 'drug-type', caption: 'Виды препаратов'}, {id: 'disease-type', caption: 'Болезни животных'}]);
    } else if (lookupNameLower === 'gender') {
      existing.next([{id: true, caption: 'Муж'}, {id: false, caption: 'Жен'}]);
    } else if (lookupNameLower === 'gender-animal') {
      existing.next([{id: true, caption: 'Самец'}, {id: false, caption: 'Самка'}]);
    } else if (lookupNameLower === 'gender-animal-group') {
      existing.next([{id: 1, caption: 'Самцы'}, {id: 2, caption: 'Самки'}, {id: 3, caption: 'Смешанная группа'}]);
    } else if (lookupNameLower === 'person-group-visibility') {
      existing.next([{id: 1, caption: 'Глобально'}, {id: 2, caption: 'Учреждение'}, {id: 3, caption: 'Пользователь'}]);
    } else if (lookupNameLower === 'journal-change-type') {
      existing.next([{id: 1, caption: 'Добавление'}, {id: 2, caption: 'Редактирование'}, {id: 3, caption: 'Удаление'},
        {id: 4, caption: 'Получение доступа'}, {id: 8, caption: 'Системное уведомление'}]);
    } else if (lookupNameLower === 'report-output-format') {
      existing.next([{id: 'odt', caption: 'XDocReport Open Document (.odt)'},
        {id: 'odtPdf', caption: 'XDocReport PDF .odt'},
        {id: 'jasperPdf', caption: 'JasperReports PDF .jrxml'},
        {id: 'jasperXlsx', caption: 'JasperReports Xls .jrxml'},
        {id: 'jasperDocx', caption: 'JasperReports Docx .jrxml'}]);
    } else if (lookupNameLower === 'daily-schedule-kind') {
      existing.next([{id: 1, caption: 'Ежедневно'}, {id: 2, caption: 'Еженедельно'}, {id: 3, caption: 'Ежемесячно'}]);
    } else if (lookupNameLower === 'security-function-scope') {
      existing.next([{id: 1, caption: 'Все объекты'}, {id: 2, caption: 'Объекты, связанные с учреждением'},
        {id: 3, caption: 'Объекты пользователей учреждения'}, {id: 4, caption: 'Объекты пользователя'}]);
    } else if (lookupNameLower === 'week-days') {
      existing.next([{id: 0, caption: 'Понедельник', shortCaption: 'Пн'},
        {id: 1, caption: 'Вторник', shortCaption: 'Вт'}, {id: 2, caption: 'Среда', shortCaption: 'Ср'},
        {id: 3, caption: 'Четверг', shortCaption: 'Чт'}, {id: 4, caption: 'Пятница', shortCaption: 'Пт'},
        {id: 5, caption: 'Суббота', shortCaption: 'Сб'}, {id: 6, caption: 'Воскресенье', shortCaption: 'Вс'}]);
    } else if (lookupNameLower === 'months') {
      existing.next([
        {id: 1, caption: 'январь'}, {id: 2, caption: 'февраль'}, {id: 3, caption: 'март'},
        {id: 4, caption: 'апрель'}, {id: 5, caption: 'май'}, {id: 6, caption: 'июнь'},
        {id: 7, caption: 'июль'}, {id: 8, caption: 'август'}, {id: 9, caption: 'сентябрь'},
        {id: 10, caption: 'октябрь'}, {id: 11, caption: 'ноябрь'}, {id: 12, caption: 'декабрь'}]);
    } else if (lookupNameLower === 'security-role') {
      this.metadataService.getAllRoles().subscribe(val => {
        existing.next(val);
      });
    } else if (lookupNameLower === 'animal-types-4-vet-a') {
      existing.next([
        {id: 'Крупный рогатый скот', caption: 'Крупный рогатый скот'},
        {id: 'Овцы (козы)', caption: 'Овцы (козы)'},
        {id: 'Свиньи', caption: 'Свиньи'},
        {id: 'Лошади', caption: 'Лошади'},
        {
          id: 'Птица по видам (куры,утки,гуси,индейки,цесарки,перепела,страусы)',
          caption: 'Птица по видам (куры,утки,гуси,индейки,цесарки,перепела,страусы)'
        },
        {
          id: 'Другие виды животных (олени, норки, песцы, соболя, иные плотоядные животные)',
          caption: 'Другие виды животных (олени, норки, песцы, соболя, иные плотоядные животные)'
        },
      ]);
    } else if (lookupNameLower === 'drug-cost-type') {
      existing.next([
        {id: 1, caption: 'Рубль', shortCaption: 'руб.'},
        {id: 2, caption: 'Копейка', shortCaption: 'коп.'}
        ]);
    } else if (lookupNameLower === 'security-function') {
      this.metadataService.getAllFunctions().subscribe(val => {
        existing.next(val);
      });
    } else if (lookupNameLower.startsWith('report-')) {
      this.metadataService.getReports(lookupNameLower.substr(7)).subscribe(val => {
        existing.next(val);
      });
    } else if (lookupNameLower.startsWith('institution-branch')) {
      const institutionId = parseInt(lookupNameLower.substr(18), 10);
      this.institutionDataService.getInstitutionBranchesForLookup(institutionId).subscribe(val => {
        existing.next(val);
      });
    } else if (lookupNameLower.startsWith('institution-employee')) {
      const institutionId = parseInt(lookupNameLower.substr(20), 10);
      if (institutionId) {
        this.institutionDataService.getInstitutionEmployeesForLookup(institutionId).subscribe(val => {
          existing.next(val.map(item => {
            return {
              id: item.id,
              caption: StringHelper.getPersonTitle(item),
              shortCaption: StringHelper.getPersonShortTitle(item),
              uniqueUserId: item.uniqueUserId
            };
          }));
        });
      } else {
        this.metadataService.getMetadata(lookupNameLower).subscribe(val => existing.next(val));
      }
    } else if (lookupNameLower.startsWith('disease-type/may-be-introduced-quarantine')) {
      const matches = lookupNameLower.match(/disease-type\/may-be-introduced-quarantine(\d+)/);
      if (matches) {
        this.getLookup('disease-type/may-be-introduced-quarantine')
          .subscribe(types => existing.next(types.find(type => type.id === +matches[1])));
      } else {
        this.metadataService.getMetadata(lookupNameLower).subscribe(val => existing.next(val));
      }
    } else if (lookupNameLower === 'user-manuals/all') {
      this.metadataService.getListUserManuals().subscribe(val => existing.next(val));
    } else {
      this.metadataService.getMetadata(lookupNameLower).subscribe(val => existing.next(val));
    }
  }

  getLookupCaption(value: any, lookupName: string, useShort?: boolean, objId2?: any): Observable<string> {
    if (lookupName.toLowerCase() === 'address') {
      if (value === -2) {
        return new BehaviorSubject<any>(AppConstants.HIDDEN_FIELD_TEXT);
      } else {
        return this.personFioCacheService.getAddress(value).pipe(map(val => val.caption));
      }
    } else if (lookupName.toLowerCase() === 'addr-by-region-city-ids') {

      return this.getLookupObj('addr-city')
        .pipe(map(cityLookup => {
          const city = cityLookup['Obj' + value + '-' + objId2];
          if (!city) {
            return '';
          }

          return city.comments + ', ' + city.caption;
        }));
    } else if (lookupName.toLowerCase() === 'quarantine') {

      const caption = new BehaviorSubject('');

      this.personFioCacheService.getQuarantineLookup(value)
        .subscribe((quarantine: any) => {

          if (quarantine.data.id === 0) {
            caption.next(AddressPersonFioCacheService.OBJ_ACCESS_DENIED.caption);
            return;
          }

          let sourceTerritory = {regionId: null, cityId: null};
          let periodCaption = '';
          if (quarantine.data && quarantine.data.territories && quarantine.data.territories.length) {
            quarantine.data.territories.forEach(territory => {
              if (territory.quarantineTerritoryTypeId === 2) {
                sourceTerritory = territory;
              }
            });
          }
          if (quarantine.data && quarantine.data.stagePeriods && quarantine.data.stagePeriods.length) {
            quarantine.data.stagePeriods.forEach(period => {
              if (period.quarantineStageId === 3) {
                periodCaption = period && period['dateFrom']
                  ? 'с ' + StringHelper.getRuDate(DateHelper.toDate(period['dateFrom'])) +
                  (period['dateToInclusive']
                    ? ' по ' + StringHelper.getRuDate(DateHelper.toDate(period['dateToInclusive']))
                    : '')
                  : '';
              }
            });
          }

          combineLatest(
            [this.getLookupCaption(quarantine.data.diseaseTypeId, 'disease-type'),
              this.getLookupCaption(quarantine.data.currentStatusId, 'quarantine-status'),
              this.getLookupCaption(sourceTerritory.regionId, 'addr-by-region-city-ids', null, sourceTerritory.cityId)])
            .subscribe(([diseaseCaption, statusCaption, addressCaption]) => {
              caption.next(`${diseaseCaption}, ${statusCaption}, ${periodCaption} ${addressCaption ? '(' + addressCaption + ')' : ''}`);
            });
        });

      return caption;
    } else {
      if (!useShort) {
        return this.getLookupObj(objId2 ? lookupName + objId2 : lookupName).pipe(map(lookup => lookup[value] || 'N/A'));
      } else {
        return this.getLookupObj(objId2 ? lookupName + objId2 : lookupName)
          .pipe(map(lookup => lookup['Obj' + value]
            ? (lookup['Obj' + value].shortCaption || lookup['Obj' + value].caption)
            : 'N/A'));
      }
    }
  }
}
