import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { MetadataService } from '../../../logic/services/metadata.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MetaBrowseBaseComponent } from '../meta-browse.base.component';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { JobRunnerUiService } from '../../../ui/infrastructure/job-runner-ui.service';
import { StringHelper } from '../../../helpers/string-helper';
import { DataCachingService } from '../../../logic/services/data-caching.service';

@Component({
  templateUrl: './disease-type-browse.component.html'
})
export class DiseaseTypeBrowseComponent extends MetaBrowseBaseComponent {

  gridColumnDefs = [
    {headerName: '№', field: 'id', width: 100},
    {headerName: 'Наименование', field: 'caption', tooltipField: 'caption', width: 350},
    {headerName: 'Код', field: 'code', tooltipField: 'caption', width: 80},
    {headerName: 'Группа', field: 'kind', tooltipField: 'caption', width: 200},
    {headerName: 'Guid', field: 'guid', tooltipField: 'guid', width: 200},
    {
      headerName: 'Удалено', field: 'dateDeleted', width: 100,
      valueFormatter: params => StringHelper.getRuTimeWithSeconds(params.value),
    },
    {
      headerName: 'Огр.мероприятия',
      field: 'mayBeIntroducedQuarantine',
      valueFormatter: params => params && params.value ? 'Да' : 'Нет'
    },
  ];

  constructor(route: ActivatedRoute,
              metadataService: MetadataService,
              alertService: AlertService,
              globalWaitingOverlayService: GlobalWaitingOverlayService,
              lookupService: LookupSourceService,
              bookmarkService: BookmarkService,
              fb: FormBuilder,
              private jobRunnerUiService: JobRunnerUiService,
              dataCachingService: DataCachingService) {
    super(route, metadataService, alertService, globalWaitingOverlayService, lookupService, fb, bookmarkService, dataCachingService);
  }

  getMetaTitle(): string {
    return 'Болезни животных';
  }

  getMetaName(): string {
    return 'disease-type';
  }

  getGroupDef(row: any) {
    return {
      id: [row.id, Validators.compose([Validators.required, Validators.pattern(/^\d{1,5}$/)])],
      caption: [row.caption, Validators.required],
      code: [row.code, Validators.required],
      kind: [row.kind, Validators.required],
      mv4ChapterId: row.mv4ChapterId,
      infectionKind: row.infectionKind,
      include1Vet: row.include1Vet,
      guid: row.guid,
      mayBeIntroducedQuarantine: [row.mayBeIntroducedQuarantine || false, Validators.required],
      liquidationRegulationsFileUri: row.liquidationRegulationsFileUri,
    };
  }

  editRow(row: any) {
    this.currentFormGroup = this.fb.group(this.getGroupDef(row));
    this.currentIsNew = !this.getId(this.currentFormGroup);

    if (!this.currentIsNew) {
      this.metadataService.getMetadataSingle(this.getMetaName(), this.getId(this.currentFormGroup)).subscribe(detailedRec => {
        this.currentFormGroup = this.fb.group(this.getGroupDef(detailedRec));
        this.editModalOpened = true;
      });
    } else {
      this.metadataService.getMetadata(this.getMetaName() + '/get-for-create')
        .subscribe(newRec => {
          this.currentFormGroup = this.fb.group(this.getGroupDef(newRec));
          this.editModalOpened = true;
        });
    }
  }

  getRowStyle(params: any) {
    if (params.data && params.data.dateDeleted) {
      return {color: 'silver'};
    }
  }

  refreshResults() {
    this.globalWaitingOverlayService.StartWaiting();

    this.metadataService.getMetadata(this.getMetaName()).subscribe({
      next: data => {
        this._searchResults = data;
        this.dataCachingService.addToCache(this.getMetaName() + '_Data', '1', data);
        this.lookupService.invalidateLookup(this.getMetaName());
        this.lookupService.invalidateLookup('disease-type/quarantine-diseases');
        this.globalWaitingOverlayService.EndWaiting();
      }, error: () => {
        this.globalWaitingOverlayService.EndWaiting();
      }
    });
  }
}
