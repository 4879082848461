import { FormGroup } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
var QueryIntegerFilterComponent = /** @class */ (function () {
    function QueryIntegerFilterComponent() {
        this.class = 'form-group';
        this.padding = '0 0 0 17rem';
    }
    QueryIntegerFilterComponent.prototype.isInvalid = function (cname) {
        return FormHelper.isInvalid(this.contextFormGroup, cname);
    };
    Object.defineProperty(QueryIntegerFilterComponent.prototype, "filtered", {
        get: function () {
            return !!this.contextFormGroup.get('_par_' + this.parameterName + '_filter').value;
        },
        enumerable: true,
        configurable: true
    });
    return QueryIntegerFilterComponent;
}());
export { QueryIntegerFilterComponent };
