import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/internal/operators';
import { StringHelper } from '../../helpers/string-helper';

@Injectable({
  providedIn: 'root',
})
export class DrugDataService {

  constructor(private http: HttpClient) {
  }

  search(params: any): Observable<any[]> {
    const qstring = environment.api + '/drug/search';
    return this.http.post(qstring, params)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  searchDrugSpending(id: number): Observable<any> {
    const qstring = environment.api + '/drug/search-spending/' + id;
    return this.http.get(qstring, {headers: {'interceptNoAccess': 'true'}})
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  getDrugCommonForEdit(id: number): Observable<any> {
    const qstring = environment.api + '/drug/' + id.toString(10);
    return this.http.get(qstring, {headers: {'interceptNoAccess': 'true'}});
  }

  getDrugCommonForLookup(id: number): Observable<any> {
    const qstring = environment.api + '/drug/lookup/' + id.toString(10);
    return this.http.get(qstring);
  }

  storeDrugCommonInfo(model: any) {
    const qstring = environment.api + '/drug/store-common';
    return this.http.post(qstring, model)
      .pipe(map((response: any, error: any) => {
        return response.data.id as number;
      }));
  }

  storeDrugWriteoff(model: any) {
    const qstring = environment.api + '/drug/store-writeoff';
    return this.http.post(qstring, model);
  }

  deleteDrugWriteoff(id: any, date: any) {
    const qstring = environment.api + '/drug/delete-writeoff/' + id + '/' + StringHelper.getISODate(new Date(date));
    return this.http.post(qstring, undefined);
  }

  addMoving(date: any, drugId: any, value: any, packageCount: any, institutionId: any, institutionBranchId: any) {
    const qstring = environment.api + '/drug/add-moving/' + StringHelper.getISODate(new Date(date)) + '/' + drugId +
      '/' + value + '/' + packageCount + '/' + institutionId + '/' + institutionBranchId;
    return this.http.post(qstring, undefined);
  }

  deleteDrug(id: number) {
    const qstring = environment.api + '/drug/delete/' + id;
    return this.http.post(qstring, undefined);
  }

  getReservedByCurrentUser(): Observable<any[]> {
    const qstring = environment.api + '/drug/reserved-by-current-user';
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }
}
