/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@clr/angular";
import * as i3 from "../../logic/pipes/lookup-source.pipe";
import * as i4 from "../../logic/services/lookup-source.service";
import * as i5 from "../../logic/services/address-person-fio-cache.service";
import * as i6 from "@angular/platform-browser";
import * as i7 from "./ag-grid-search-modal-editor.component";
import * as i8 from "../../logic/services/app-navigation.service";
var styles_AgGridSearchModalEditorComponent = [];
var RenderType_AgGridSearchModalEditorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AgGridSearchModalEditorComponent, data: {} });
export { RenderType_AgGridSearchModalEditorComponent as RenderType_AgGridSearchModalEditorComponent };
function View_AgGridSearchModalEditorComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "span", [["style", "max-width:calc(100% - 20px);overflow:hidden;text-overflow:ellipsis;padding-left:10px"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "])), i0.ɵppd(3, 3), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(5, 0, null, null, 2, "button", [["class", "btn btn-danger btn-link itech-inline-tool-btn"], ["style", "position:absolute;right:0;top:0;z-index:10;color:grey"], ["title", "\u041E\u0447\u0438\u0441\u0442\u0438\u0442\u044C"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clearValue() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "clr-icon", [["shape", "times"]], null, null, null, null, null)), i0.ɵdid(7, 16384, null, 0, i2.ClrIconCustomTag, [], null, null)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 4).transform(i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent, 0), _co.getControl().value, _co.params.lookupName, true)))); _ck(_v, 2, 0, currVal_0); }); }
function View_AgGridSearchModalEditorComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u041F\u043E\u0438\u0441\u043A..."]))], null, null); }
function View_AgGridSearchModalEditorComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "button", [["class", "btn btn-danger btn-link itech-inline-tool-btn"], ["type", "button"]], [[4, "width", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.search() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "clr-icon", [["shape", "search"]], null, null, null, null, null)), i0.ɵdid(2, 16384, null, 0, i2.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AgGridSearchModalEditorComponent_3)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.getControl().value; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.getControl().value ? "20px" : "100%"); _ck(_v, 0, 0, currVal_0); }); }
export function View_AgGridSearchModalEditorComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i3.LookupSourcePipe, [i4.LookupSourceService, i5.AddressPersonFioCacheService, i6.DomSanitizer]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AgGridSearchModalEditorComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["noValue", 2]], null, 0, null, View_AgGridSearchModalEditorComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getControl().value; var currVal_1 = i0.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_AgGridSearchModalEditorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-ag-grid-search-modal-editor", [], null, null, null, View_AgGridSearchModalEditorComponent_0, RenderType_AgGridSearchModalEditorComponent)), i0.ɵdid(1, 49152, null, 0, i7.AgGridSearchModalEditorComponent, [i8.AppNavigationService], null, null)], null, null); }
var AgGridSearchModalEditorComponentNgFactory = i0.ɵccf("app-ag-grid-search-modal-editor", i7.AgGridSearchModalEditorComponent, View_AgGridSearchModalEditorComponent_Host_0, {}, {}, []);
export { AgGridSearchModalEditorComponentNgFactory as AgGridSearchModalEditorComponentNgFactory };
