/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@clr/angular";
import * as i2 from "./app-checkbox-select.component.ngfactory";
import * as i3 from "./app-checkbox-select.component";
import * as i4 from "../../logic/services/lookup-source.service";
import * as i5 from "@angular/forms";
import * as i6 from "@angular/common";
import * as i7 from "./app-checkbox-select-with-parent.component";
var styles_AppCheckboxSelectWithParentComponent = [];
var RenderType_AppCheckboxSelectWithParentComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppCheckboxSelectWithParentComponent, data: {} });
export { RenderType_AppCheckboxSelectWithParentComponent as RenderType_AppCheckboxSelectWithParentComponent };
function View_AppCheckboxSelectWithParentComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" (", ") "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedIds[_v.parent.context.$implicit.id].length; _ck(_v, 1, 0, currVal_0); }); }
function View_AppCheckboxSelectWithParentComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "clr-icon", [["shape", "angle up"]], null, null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i1.ClrIconCustomTag, [], null, null)], null, null); }
function View_AppCheckboxSelectWithParentComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "clr-icon", [["shape", "angle down"]], null, null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i1.ClrIconCustomTag, [], null, null)], null, null); }
function View_AppCheckboxSelectWithParentComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-checkbox-select", [], null, null, null, i2.View_AppCheckboxSelectComponent_0, i2.RenderType_AppCheckboxSelectComponent)), i0.ɵdid(2, 573440, null, 0, i3.AppCheckboxSelectComponent, [i4.LookupSourceService, i5.FormBuilder], { disabled: [0, "disabled"], contextControlName: [1, "contextControlName"], contextFormGroup: [2, "contextFormGroup"], sorted: [3, "sorted"], lookupName: [4, "lookupName"], nameId: [5, "nameId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; var currVal_1 = _co.contextControlName; var currVal_2 = _co.contextFormGroup; var currVal_3 = true; var currVal_4 = (_co.lookupName + _v.parent.context.$implicit.id); var currVal_5 = _co.nameId; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_AppCheckboxSelectWithParentComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [], [[4, "width", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "button", [["class", "btn btn-outline"], ["style", "background:none;border:none;margin:0;text-transform:none;color:black;letter-spacing:normal"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = ((_v.context.$implicit.expanded = !_v.context.$implicit.expanded) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "span", [["style", "font-size:11px"]], [[4, "color", null]], null, null, null, null)), (_l()(), i0.ɵted(3, null, [" ", " "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppCheckboxSelectWithParentComponent_2)), i0.ɵdid(5, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppCheckboxSelectWithParentComponent_3)), i0.ɵdid(7, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppCheckboxSelectWithParentComponent_4)), i0.ɵdid(9, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppCheckboxSelectWithParentComponent_5)), i0.ɵdid(11, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = (_co.selectedIds[_v.context.$implicit.id] && _co.selectedIds[_v.context.$implicit.id].length); _ck(_v, 5, 0, currVal_4); var currVal_5 = _v.context.$implicit.expanded; _ck(_v, 7, 0, currVal_5); var currVal_6 = !_v.context.$implicit.expanded; _ck(_v, 9, 0, currVal_6); var currVal_7 = _v.context.$implicit.expanded; _ck(_v, 11, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.expanded ? "100%" : undefined); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.disabled; _ck(_v, 1, 0, currVal_1); var currVal_2 = ((_co.selectedIds[_v.context.$implicit.id] && _co.selectedIds[_v.context.$implicit.id].length) ? "blue" : undefined); _ck(_v, 2, 0, currVal_2); var currVal_3 = _v.context.$implicit.caption; _ck(_v, 3, 0, currVal_3); }); }
export function View_AppCheckboxSelectWithParentComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "form-group itech-group-whole-line"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "label", [], [[1, "for", 0]], null, null, null, null)), i0.ɵdid(2, 212992, null, 0, i1.ClrLabel, [[2, i1.ɵbe], [2, i1.ɵbf], [2, i1.ɵbg], i0.Renderer2, i0.ElementRef], null, null), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_AppCheckboxSelectWithParentComponent_1)), i0.ɵdid(4, 278528, null, 0, i6.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpid(131072, i6.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_1 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform(_co.lookupParent())); _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).forAttr; _ck(_v, 1, 0, currVal_0); }); }
export function View_AppCheckboxSelectWithParentComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-checkbox-select-with-parent", [], null, null, null, View_AppCheckboxSelectWithParentComponent_0, RenderType_AppCheckboxSelectWithParentComponent)), i0.ɵdid(1, 573440, null, 0, i7.AppCheckboxSelectWithParentComponent, [i4.LookupSourceService], null, null)], null, null); }
var AppCheckboxSelectWithParentComponentNgFactory = i0.ɵccf("app-checkbox-select-with-parent", i7.AppCheckboxSelectWithParentComponent, View_AppCheckboxSelectWithParentComponent_Host_0, { contextFormGroup: "contextFormGroup", contextControlName: "contextControlName", lookupName: "lookupName", lookupNameParent: "lookupNameParent", nameId: "nameId", disabled: "disabled" }, {}, []);
export { AppCheckboxSelectWithParentComponentNgFactory as AppCheckboxSelectWithParentComponentNgFactory };
