<section class="form-block itech-block-large" [formGroup]="contextFormGroup">
  <div>
    <app-edit-quarantine-stages-file [label]="'Оповещение о подозрении на заболевание'" [serverSideErrorsProvider]="serverSideErrorsProvider"
                                     [contextFormGroup]="contextFormGroup" [controlName]="'notification'"
                                     (dirtyFormGroup)="markAsDirty()" [id]="id" [isAccessEdit]="isAccessEdit" [tooltipPosition]="'bottom-right'">
    </app-edit-quarantine-stages-file>
    <app-edit-quarantine-stages-file [label]="'Акт эпизоотического обследования'" [serverSideErrorsProvider]="serverSideErrorsProvider"
                                     [contextFormGroup]="contextFormGroup" [controlName]="'actEpizooticExamination'"
                                     (dirtyFormGroup)="markAsDirty()" [id]="id" [isAccessEdit]="isAccessEdit">
    </app-edit-quarantine-stages-file>
    <app-edit-quarantine-decision-next-stage *ngIf="currentStatusId === 0 && !noSuspicionStage && !contextFormGroup.get('stagePeriods').value.length"
                                             [title]="'Решение о введении этапа'" [noSuspicionStage]="noSuspicionStage"
                                             [stageId]="0" [contextFormGroup]="contextFormGroup"
                                             [serverSideErrorsProvider]="serverSideErrorsProvider"
                                             (needUpdateQuarantine)="needUpdateQuarantine.next()" [quarantineId]="id"
                                             [quarantineCurrentStatusId]="currentStatusId"></app-edit-quarantine-decision-next-stage>
    <app-edit-quarantine-stages-file [label]="'Направление представителя службы в очаг'" [withoutNumber]="true"
                                     [contextFormGroup]="contextFormGroup" [controlName]="'sendRepresentativeToSource'"
                                     (dirtyFormGroup)="markAsDirty()" [id]="id" [isAccessEdit]="isAccessEdit"
                                     [serverSideErrorsProvider]="serverSideErrorsProvider">
    </app-edit-quarantine-stages-file>
    <app-edit-quarantine-stages-file [label]="'Оповещение граничащих и других, куда возможен занос заболевания, субъектов РФ'"
                                     [contextFormGroup]="contextFormGroup" [controlName]="'notificationOtherSubject'"
                                     (dirtyFormGroup)="markAsDirty()" [id]="id" [isAccessEdit]="isAccessEdit"
                                     [deadlineDateTime]="contextFormGroup.get('notificationDateUpload').value &&
                                                         !contextFormGroup.get('notificationOtherSubjectDateUpload').value
                                                         ? getDeadline(contextFormGroup.get('notificationDateUpload').value, 24)
                                                         : null"
                                     [serverSideErrorsProvider]="serverSideErrorsProvider">
    </app-edit-quarantine-stages-file>
    <app-edit-quarantine-stages-file [label]="'Информирование органов местного самоуправления'"
                                     [contextFormGroup]="contextFormGroup" [controlName]="'notificationLocalAuthority'"
                                     (dirtyFormGroup)="markAsDirty()" [id]="id" [isAccessEdit]="isAccessEdit"
                                     [deadlineDateTime]="contextFormGroup.get('notificationDateUpload').value &&
                                                         !contextFormGroup.get('notificationLocalAuthorityDateUpload').value
                                                         ? getDeadline(contextFormGroup.get('notificationDateUpload').value, 24)
                                                         : null"
                                     [serverSideErrorsProvider]="serverSideErrorsProvider">
    </app-edit-quarantine-stages-file>
    <app-edit-quarantine-stages-file [label]="'Информирование населения и владельцев животных о подозрении заболевания и требованиях правил'"
                                     [contextFormGroup]="contextFormGroup" [controlName]="'notificationPeople'"
                                     (dirtyFormGroup)="markAsDirty()" [id]="id" [isAccessEdit]="isAccessEdit"
                                     [serverSideErrorsProvider]="serverSideErrorsProvider">
    </app-edit-quarantine-stages-file>
    <app-edit-quarantine-stages-file [label]="'Отбор проб в ветеринарную лабораторию'" [serverSideErrorsProvider]="serverSideErrorsProvider"
                                     [contextFormGroup]="contextFormGroup" [controlName]="'selectionSamplesToLaboratory'"
                                     (dirtyFormGroup)="markAsDirty()" [id]="id" [isAccessEdit]="isAccessEdit"
                                     [marginBottom]="0">
    </app-edit-quarantine-stages-file>
    <div class="form-group" style="display:block" *ngIf="contextFormGroup.get('selectionSamplesToLaboratory').value.length" [formGroup]="contextFormGroup">
      <label></label>
      <div style="display:block">
        <div>Информация о развитии ситуации</div>
        <div>
          <label for="selectionSamplesToLaboratoryDescription" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                 [class.invalid]="isInvalid(contextFormGroup, 'selectionSamplesToLaboratoryDescription')"
                 style="margin-top:0">
            <textarea type="text" id="selectionSamplesToLaboratoryDescription" class="itech-control-normal itech-quarantine-textarea"
                      placeholder="Краткая информация о развитии ситуации"
                      formControlName="selectionSamplesToLaboratoryDescription" (change)="setNullIfWhitespace('selectionSamplesToLaboratoryDescription')"
                      [attr.disabled]="!isAccessEdit ? true : undefined"></textarea>
            <app-validation-tooltip [input]="contextFormGroup.controls['selectionSamplesToLaboratoryDescription']"></app-validation-tooltip>
          </label>
        </div>
      </div>
    </div>
    <app-edit-quarantine-stages-file [label]="'Получение информации о результатах исследования (Протокол, срочный отчет 4-ВЕТ-Б)'"
                                     [contextFormGroup]="contextFormGroup" [controlName]="'resultFromLaboratory'"
                                     (dirtyFormGroup)="markAsDirty()" [id]="id" [isAccessEdit]="isAccessEdit"
                                     [marginBottom]="0" [serverSideErrorsProvider]="serverSideErrorsProvider">
    </app-edit-quarantine-stages-file>
    <div class="form-group" style="margin-bottom: 20px" *ngIf="contextFormGroup.get('resultFromLaboratory').value.length">
      <label></label>
      <div style="display:block">
        <div>Результат:
        <app-combo-lookup class="itech-control-medium" style="width:323px" [validationTooltipRight]="true"
                          [contextControlId]="'resultFromLaboratoryId'" [sorted]="true"
                          [contextControlName]="'resultFromLaboratoryId'" [lookupName]="'exam-result'" [disabled]="!isAccessEdit"
                          [contextFormGroup]="contextFormGroup"></app-combo-lookup>
        </div>
      </div>
    </div>

    <app-edit-quarantine-stages-file [label]="'Информирование заинтересованных лиц о выявлении заболевания ' +
                                              '(Министра, Губернатора, РСХН, МО РФ, МВД РФ, НАЦГвардия, ГУФСИН, ФСБ)'"
                                     [contextFormGroup]="contextFormGroup" [controlName]="'notificationConcernedAuthorityDetection'"
                                     (dirtyFormGroup)="markAsDirty()" [id]="id" [isAccessEdit]="isAccessEdit"
                                     [marginBottom]="!isAccessEdit ? 50 : 40"
                                     [deadlineDateTime]="contextFormGroup.get('resultFromLaboratoryDateUpload').value &&
                                                         contextFormGroup.get('resultFromLaboratoryId').value != 2 &&
                                                         !contextFormGroup.get('notificationConcernedAuthorityDetectionDateUpload').value
                                                         ? getDeadline(contextFormGroup.get('resultFromLaboratoryDateUpload').value, 24)
                                                         : null"
                                     [serverSideErrorsProvider]="serverSideErrorsProvider">
    </app-edit-quarantine-stages-file>
    <app-edit-quarantine-stages-file [label]="'Информирование заинтересованных лиц об отсутствии заболевания'"
                                     [contextFormGroup]="contextFormGroup" [controlName]="'notificationConcernedAuthorityAbsence'"
                                     (dirtyFormGroup)="markAsDirty()" [id]="id" [isAccessEdit]="isAccessEdit"
                                     [deadlineDateTime]="contextFormGroup.get('resultFromLaboratoryDateUpload').value &&
                                                         contextFormGroup.get('resultFromLaboratoryId').value == 2 &&
                                                         !contextFormGroup.get('notificationConcernedAuthorityAbsenceDateUpload').value
                                                         ? getDeadline(contextFormGroup.get('resultFromLaboratoryDateUpload').value, 24)
                                                         : null"
                                     [serverSideErrorsProvider]="serverSideErrorsProvider">
    </app-edit-quarantine-stages-file>
    <app-edit-quarantine-decision-next-stage [stageId]="statusSuspicion" [contextFormGroup]="contextFormGroup" *ngIf="!noSuspicionStage"
                                             [serverSideErrorsProvider]="serverSideErrorsProvider"
                                             (needUpdateQuarantine)="needUpdateQuarantine.next()" [quarantineId]="id"
                                             [quarantineCurrentStatusId]="currentStatusId"></app-edit-quarantine-decision-next-stage>
  </div>
</section>
