
      <div *ngIf="(gridApi && gridApi.isVisibleContextMenu && contextMenu) || false"
           class="app-grid-context-menu" style="position: fixed;z-index:100"
           [style.left.px]="positionLeft" [style.top.px]="positionTop">
        <clr-dropdown class="app-grid-context-menu">
          <clr-dropdown-menu class="app-grid-context-menu" clrPosition="right-top" *clrIfOpen="gridApi.isVisibleContextMenu">
            <h4 class="dropdown-header app-grid-context-menu">Выберите действие</h4>
            <ng-container *ngFor="let menu of contextMenu;index as i">
              <button type="button" *ngIf="!menu.subMenu" class="btn app-grid-context-menu-btn app-grid-context-menu"
                      (click)="clickItem(menu)" clrDropdownItem>
                  <clr-icon class="app-grid-context-menu" *ngIf="menu.iconName" [attr.shape]="menu.iconName"></clr-icon>
                  &nbsp;{{menu.name}}
              </button>
              <ng-container *ngIf="menu.subMenu">
                <clr-dropdown class="app-grid-context-menu">
                  <button type="button" class="btn app-grid-context-menu-btn app-grid-context-menu"
                          (click)="clickItem(menu)" clrDropdownTrigger>
                    <clr-icon class="app-grid-context-menu" *ngIf="menu.iconName" [attr.shape]="menu.iconName"></clr-icon>
                    &nbsp;{{menu.name}}
                  </button>
                  <clr-dropdown-menu class="app-grid-context-menu" clrPosition="right-top">
                    <ng-container *ngFor="let subMenuItem of menu.subMenu;index as j">
                      <div *ngIf="subMenuItem === 'divider'" class="dropdown-divider app-grid-context-menu"></div>
                      <ng-container *ngIf="subMenuItem !== 'divider'">
                        <button *ngIf="!subMenuItem.controlType" type="button"
                                class="btn app-grid-context-menu-btn app-grid-context-menu"
                                 (click)="clickItem(subMenuItem)" clrDropdownItem>
                          <clr-icon class="app-grid-context-menu" *ngIf="subMenuItem.iconName"
                                    [attr.shape]="subMenuItem.iconName"></clr-icon>
                          &nbsp;{{subMenuItem.name}}
                        </button>
                        <div *ngIf="subMenuItem.controlType && subMenuItem.controlType==='checkbox'" class="app-grid-context-menu">
                          <label for="submenu{{i}}{{j}}" class="app-grid-context-menu"></label>
                          <div class="checkbox app-grid-context-menu">
                            <input type="checkbox" class="app-grid-context-menu" id="submenu{{i}}{{j}}"
                                   [(ngModel)]="subMenuItem.checked" [ngModelOptions]="{standalone: true}"
                                   (ngModelChange)="subMenuCheckedChange(menu.subMenu, subMenuItem, menu.name)">
                            <label for="submenu{{i}}{{j}}" class="app-grid-context-menu">
                              {{subMenuItem.name + (!subMenuItem.rowIndexGroupSelected || +subMenuItem.rowIndexGroupSelected < 0
                                    ? '' : ' (' + (+subMenuItem.rowIndexGroupSelected + 1)  + ')')}}
                            </label>
                          </div>
                        </div>
                      </ng-container>
                    </ng-container>
                  </clr-dropdown-menu>
                </clr-dropdown>
              </ng-container>
            </ng-container>
          </clr-dropdown-menu>
        </clr-dropdown>
      </div>