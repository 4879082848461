import { Component, Input, OnInit } from '@angular/core';
import { DataCachingService } from '../../logic/services/data-caching.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BookmarkService } from '../../logic/services/bookmark.service';
import { AppNavigationService } from '../../logic/services/app-navigation.service';
import { FormBuilder } from '@angular/forms';
import { FormHelper } from '../../ui/controls/form-helper';
import { SecurityService } from '../../logic/services/security.service';
import { EditAgentCommonFormComponent } from './common/edit-agent-common-form.component';
import { AgentDataService } from '../../logic/services/agent-data.service';

@Component({
  selector: 'app-create-agent',
  templateUrl: './create-agent.component.html'
})
export class CreateAgentComponent implements OnInit {

  @Input() model: any;
  creating = false;
  created = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private bookmarkService: BookmarkService,
              private agentDataService: AgentDataService,
              private dataCachingService: DataCachingService,
              private appNavigationService: AppNavigationService,
              private securityService: SecurityService,
              private fb: FormBuilder) {
  }

  ngOnInit() {

    this.securityService.getUserInfo().subscribe(currentUserInfo => {


      this.router.events.subscribe((val) => {
        if (!this.created) {
          this.refreshCurrentBookmark(true);
        }
      });

      this.route.paramMap
        .subscribe(params => {

          const randomUniqueTag = params.get('randomUniqueTag');

          const existing = this.dataCachingService.getCachedData('CreateAgent', randomUniqueTag);

          if (!existing) {
            this.model = {
              randomUniqueTag: randomUniqueTag,
              serverSideValidationErrors: []
            };
            this.model.form = EditAgentCommonFormComponent.createFormGroup(this.fb, this.model, {
              legalFormId: 0,
              address: {
                regionId: currentUserInfo ? currentUserInfo.defaultRegionId : undefined,
                cityId: currentUserInfo ? currentUserInfo.defaultCityId : undefined,
                countryId: 'RUS',
              },
            });

            this.dataCachingService.addToCache('CreateAgent', randomUniqueTag, this.model, 10);
          } else {
            this.model = existing;
          }

          this.refreshCurrentBookmark();
        });
    });
  }

  refreshCurrentBookmark(skipIfNotExists?: boolean) {
    let caption = this.model.form.get('shortCaption').value || '';
    if (caption) {
      caption = ' (' + caption + ')';
    }
    this.bookmarkService.addOpenCardBookmark(this.model.randomUniqueTag,
      `Новая карточка ${caption}`,
      ['/agent-create', this.model.randomUniqueTag], 'users', skipIfNotExists);
  }

  createCard() {

    FormHelper.markAsSubmitted(this.model.form);
    EditAgentCommonFormComponent.prettifyFormGroup(this.model.form);

    if (!this.model.form.valid) {
      FormHelper.setFocusToInvalidControl(this.model.form);
      return;
    }

    const dataToStore = this.model.form.value;

    this.creating = true;

    this.agentDataService.storeAgentCommonInfo(dataToStore).subscribe((createdAgent: any) => {
      this.creating = false;
      this.created = true;
      this.bookmarkService.removeOpenCardBookmark(['/agent-create'], this.model.randomUniqueTag);
      this.appNavigationService.performAgentEditing(createdAgent.id);
    }, error => {
      this.creating = false;
      FormHelper.setSingleFormGroupServerSideValidationErrors(error, this.model, this.model.form);
      FormHelper.setFocusToInvalidControl(this.model.form);
    });
  }
}
