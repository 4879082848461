import { Component, Input, OnChanges } from '@angular/core';
import { AppNavigationService } from '../../logic/services/app-navigation.service';
import { LookupSourcePipe } from '../../logic/pipes/lookup-source.pipe';
import { LookupSourceService } from '../../logic/services/lookup-source.service';
import { AddressPersonFioCacheService } from '../../logic/services/address-person-fio-cache.service';

@Component({
  selector: 'app-link-button',
  template: `
    <ng-container *ngIf="id || !noValueText; else noValue">
      <div *ngIf="result">
        {{preText}}
        <div class="itech-text-field itech-control-xlarge" [style.width.px]="widthControl" *ngIf="result['id']>0; else elseBeforeZero">
          <a class="itech-person-link" [title]="title" (click)="this.clickNavigation()">
            <clr-icon *ngIf="result['iconName'] && result['iconName'] === 'flame'" shape="flame" size="14"
                      [style.color]="result['iconColor']" style="margin: 5px 0 0 5px;vertical-align:top;"></clr-icon>
              <clr-icon *ngIf="result['iconName'] && result['iconName'] === 'recycle'" shape="recycle" size="14"
                        style="margin-left: 5px"></clr-icon>
              <clr-icon *ngIf="result['iconName'] && result['iconName'] === 'truck'" shape="truck" size="14"
                        style="margin-left: 5px"></clr-icon>
            {{result['shortCaption'] || result['caption']}}
            {{idComment ? '(' + (resultComment['shortCaption'] || resultComment['caption']) + ')' : ''}}
          </a>
        </div>
        <ng-template #elseBeforeZero>
          <label style="font-weight:bold;">
            {{result['shortCaption'] || result['caption']}}
            {{idComment ? '(' + (resultComment['shortCaption'] || resultComment['caption']) + ')' : ''}}
          </label>
        </ng-template>
      </div>
    </ng-container>
    <ng-template #noValue>
      <div *ngIf="noValueText">{{noValueText}}</div>
    </ng-template>`
})
export class AppLinkButtonComponent implements OnChanges {
  @Input() preText = '';
  title = '';
  @Input() id: number;
  @Input() lookupName = '';
  @Input() useShort = true;
  @Input() idComment: number;
  @Input() lookupNameComment = '';
  @Input() useShortComment = false;
  @Input() widthControl = undefined;
  @Input() noValueText = undefined;

  result = {};
  resultComment = {};

  constructor(private navigationService: AppNavigationService,
              public lookupSource: LookupSourceService,
              private personFioCacheService: AddressPersonFioCacheService) {
  }

  ngOnChanges() {
    LookupSourcePipe.getLookupObjectFromCache(this.id, this.lookupName, this.useShort, this.lookupSource, this.personFioCacheService)
      .subscribe(info => this.result = info, () => this.result = AddressPersonFioCacheService.OBJ_ACCESS_DENIED);
    if (this.idComment) {
      LookupSourcePipe.getLookupObjectFromCache(this.idComment, this.lookupNameComment + this.id, this.useShortComment,
        this.lookupSource, this.personFioCacheService)
        .subscribe(info => this.resultComment = info, () => this.resultComment = AddressPersonFioCacheService.OBJ_ACCESS_DENIED);
    }

    switch (this.lookupName.toLowerCase()) {
      case 'agent': this.title = 'Нажмите, чтобы перейти к карточке хозяйствующего субъекта'; return;
      case 'animal': this.title = 'Нажмите, чтобы перейти к карточке животного'; return;
      case 'animal-external': this.title = 'Нажмите, чтобы перейти к карточке животного'; return;
      case 'animal-event': this.title = 'Нажмите, чтобы перейти к карточке мероприятия'; return;
      case 'drug': this.title = 'Нажмите, чтобы перейти к карточке препарата'; return;
      case 'institution': this.title = 'Нажмите, чтобы перейти к карточке учреждения'; return;
      case 'product': this.title = 'Нажмите, чтобы перейти к карточке продукции'; return;
      case 'product-expertise': this.title = 'Нажмите, чтобы перейти к карточке экспертизы продукции'; return;
      case 'product-transaction': this.title = 'Нажмите, чтобы перейти к карточке транзакции'; return;
      case 'stable': this.title = 'Нажмите, чтобы перейти к карточке поднадзорного объекта'; return;
      case 'person': this.title = 'Нажмите, чтобы перейти к карточке клиента'; return;
      case 'quarantine': this.title = 'Нажмите, чтобы перейти к карточке карантина'; return;
    }
  }

  clickNavigation() {
    if (!this.result['id']) {
      return;
    }
    switch (this.lookupName.toLowerCase()) {
      case 'agent': this.navigationService.performAgentEditing(this.result['id']); return;
      case 'animal': this.navigationService.performAnimalEditing(this.result['id']); return;
      case 'animal-external': this.navigationService.performAnimalEditing(
        this.result['id'], undefined, undefined, true); return;
      case 'animal-event': this.navigationService.performAnimalEventEditing(this.result['id']); return;
      case 'drug': this.navigationService.performDrugEditing(this.result['id']); return;
      case 'institution': this.navigationService.performInstitutionEditing(this.result['id']); return;
      case 'product': this.navigationService.performProductEditing(this.result['id']); return;
      case 'product-expertise': this.navigationService.performProductExpertiseEditing(this.result['productId'], this.result['id']); return;
      case 'product-transaction': this.navigationService.performProductTransactionEditing(this.result['id']); return;
      case 'stable': this.navigationService.performStableEditing(this.result['id']); return;
      case 'person': this.navigationService.performPersonEditing(this.result['id']); return;
      case 'quarantine': this.navigationService.performQuarantineEditing(this.result['id']); return;
      default: return;
    }
  }
}
