import { OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { FormBuilder } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';
import { EditStableCommonFormComponent } from './edit-stable-common-form.component';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { StableDataService } from '../../../logic/services/stable-data.service';
import { StableSearchModalComponent } from '../search/stable-search-modal.component';
import { MetadataService } from '../../../logic/services/metadata.service';
var EditStableCommonComponent = /** @class */ (function () {
    function EditStableCommonComponent(bookmarkService, stableDataService, alertService, cache, dataCachingService, waitingOverlay, fb, navigationService, metadataService) {
        this.bookmarkService = bookmarkService;
        this.stableDataService = stableDataService;
        this.alertService = alertService;
        this.cache = cache;
        this.dataCachingService = dataCachingService;
        this.waitingOverlay = waitingOverlay;
        this.fb = fb;
        this.navigationService = navigationService;
        this.metadataService = metadataService;
        this.model = {};
        this.storing = false;
        this.updateSyncStateTimer = {};
        this.updateSyncStatePending = {};
        this.isInvalid = FormHelper.isInvalid;
        this.clrModalOpen = false;
        this.formGroupMerge = this.fb.group({
            mergeSourceId: null
        });
        this.selectedCheckboxConfirmAction = false;
    }
    Object.defineProperty(EditStableCommonComponent.prototype, "contextFormGroup", {
        get: function () {
            return this.model ? this.model.contextFormGroup : undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditStableCommonComponent.prototype, "syncStates", {
        get: function () {
            return this.model && this.model.syncStates ? Object.values(this.model.syncStates) : undefined;
        },
        enumerable: true,
        configurable: true
    });
    EditStableCommonComponent.prototype.ngOnChanges = function (changes) {
        if (this.id) {
            this.refreshFormDataInternal();
        }
    };
    EditStableCommonComponent.prototype.store = function () {
        var _this = this;
        FormHelper.markAsSubmitted(this.contextFormGroup);
        if (!this.contextFormGroup.valid) {
            return;
        }
        this.storing = true;
        this.stableDataService.storeStableCommonInfo(this.contextFormGroup.value).subscribe(function (val) {
            _this.storing = false;
            _this.cache.invalidateStable(_this.id);
            _this.contextFormGroup.get('agents').controls.forEach(function (agentCtrl) {
                var agentId = agentCtrl.get('agentId').value;
                if (agentId) {
                    _this.dataCachingService.removeCachedData('EditAgentStables', agentId.toString());
                }
            });
            _this.bookmarkService.refreshStableCardBookmark(_this.id, _this.newAgentId);
            _this.contextFormGroup.markAsUntouched();
            _this.contextFormGroup.markAsPristine();
            FormHelper.setSingleFormGroupServerSideValidationErrors({}, _this.model, _this.contextFormGroup);
            _this.updateModelSyncStateFromLookup(val);
            _this.refreshSyncState(10);
            _this.alertService.success("\u041E\u0431\u0449\u0430\u044F \u0438\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F \u0434\u043B\u044F [" + _this.contextFormGroup.get('caption').value + "] \u0443\u0441\u043F\u0435\u0448\u043D\u043E \u0441\u043E\u0445\u0440\u0430\u043D\u0435\u043D\u0430");
        }, function (error) {
            _this.storing = false;
            FormHelper.setSingleFormGroupServerSideValidationErrors(error, _this.model, _this.contextFormGroup);
            window.document.getElementById('stable-common-content').scrollTop = 0;
        });
    };
    EditStableCommonComponent.prototype.cancelEdit = function () {
        this.dataCachingService.removeCachedData('EditStableCommon', this.id.toString());
        this.refreshFormDataInternal();
    };
    EditStableCommonComponent.prototype.refreshFormData = function (syncMerc) {
        if (syncMerc === void 0) { syncMerc = false; }
        this.dataCachingService.removeCachedData('EditStableCommon', this.id.toString());
        this.refreshFormDataInternal(syncMerc);
    };
    EditStableCommonComponent.prototype.refreshFormDataInternal = function (syncMerc) {
        var _this = this;
        if (syncMerc === void 0) { syncMerc = false; }
        this.waitingOverlay.StartWaiting();
        var existing = this.dataCachingService.getCachedData('EditStableCommon', this.id.toString());
        if (existing && existing.newAgentId === this.newAgentId) {
            this.model = existing;
            this.waitingOverlay.EndWaiting();
        }
        else {
            (syncMerc
                ? this.stableDataService.getStableCommonForEditWithSync(this.id)
                : this.stableDataService.getStableCommonForEdit(this.id))
                .subscribe(function (data) {
                _this.model = { id: data.id, serverSideValidationErrors: [] };
                _this.model.contextFormGroup = EditStableCommonFormComponent.createFormGroup(_this.fb, _this.model, data);
                _this.updateModelSyncStateFromLookup(data);
                _this.refreshSyncState(10);
                _this.model.newAgentId = _this.newAgentId;
                _this.cache.invalidateStable(_this.id);
                _this.dataCachingService.addToCache('EditStableCommon', _this.id.toString(), _this.model);
                var agents = _this.model.contextFormGroup.get('agents');
                if (_this.newAgentId && (!agents.controls.length || agents.controls[0].get('agentId').value !== _this.newAgentId)) {
                    var fg = EditStableCommonFormComponent.addStableAgent(_this.fb, agents);
                    fg.get('agentId').setValue(_this.newAgentId);
                }
                _this.bookmarkService.refreshStableCardBookmark(_this.id, _this.newAgentId);
                _this.waitingOverlay.EndWaiting();
            }, function () {
                _this.waitingOverlay.EndWaiting();
            });
        }
    };
    EditStableCommonComponent.prototype.updateModelSyncStateFromLookup = function (data) {
        data.targetObjId = this.id;
        data.conversationTypeId = -2;
        this.updateModelSyncState(data, -2);
    };
    EditStableCommonComponent.prototype.updateModelSyncState = function (data, conversationTypeId) {
        if (!this.model.syncStates) {
            this.model.syncStates = {};
        }
        this.model.syncStates[conversationTypeId] = data;
    };
    EditStableCommonComponent.prototype.refreshSyncState = function (conversationTypeId) {
        this.model.syncStates[conversationTypeId] = null;
        this.refreshSyncStateInternal(conversationTypeId);
    };
    EditStableCommonComponent.prototype.refreshSyncStateInternal = function (conversationTypeId) {
        var _this = this;
        if (!this.contextFormGroup) {
            return;
        }
        var state = this.model && this.model.syncStates ? this.model.syncStates[conversationTypeId] : undefined;
        // если ничего не запланировано по синхронизации - ничего и не может произойти и обновлять состояние не надо
        if (state && (!state.mercuryExchangeNextTime || new Date(state.mercuryExchangeNextTime) > new Date())) {
            return;
        }
        if (this.updateSyncStatePending[conversationTypeId]) {
            return;
        }
        this.updateSyncStatePending[conversationTypeId] = true;
        (conversationTypeId === -2
            ? this.stableDataService.getStableCommonForLookup(this.id)
            : this.metadataService.getSyncState(this.id, conversationTypeId))
            .subscribe({
            next: function (data) {
                _this.updateSyncStatePending[conversationTypeId] = false;
                if (conversationTypeId === -2) {
                    _this.updateModelSyncStateFromLookup(data);
                }
                else {
                    if (data) {
                        data.withoutMercuryUuid = true;
                    }
                    _this.updateModelSyncState(data, conversationTypeId);
                }
            }, error: function () {
                _this.updateSyncStatePending[conversationTypeId] = false;
                if (conversationTypeId === -2) {
                    _this.updateModelSyncStateFromLookup({});
                }
                else {
                    _this.updateModelSyncState({}, conversationTypeId);
                }
            }
        });
    };
    EditStableCommonComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.updateSyncStateTimer[-2] = setInterval(function () { return _this.refreshSyncStateInternal(-2); }, 10000);
        this.updateSyncStateTimer[10] = setInterval(function () { return _this.refreshSyncStateInternal(10); }, 10000);
    };
    EditStableCommonComponent.prototype.ngOnDestroy = function () {
        var _this = this;
        if (this.updateSyncStateTimer) {
            Object.keys(this.updateSyncStateTimer).forEach(function (x) { return clearInterval(_this.updateSyncStateTimer[x]); });
            this.updateSyncStateTimer = {};
        }
    };
    EditStableCommonComponent.prototype.deleteStable = function () {
        var _this = this;
        this.alertService.confirmModal('Вы уверены, что хотите аннулировать поднадзорный объект?').subscribe(function () {
            _this.stableDataService.deleteStable(_this.id).subscribe(function () {
                _this.dataCachingService.removeCachedData('EditStableCommon', _this.id.toString());
                _this.bookmarkService.removeOpenCardBookmark(['/stable-edit'], _this.id.toString());
            });
        });
    };
    EditStableCommonComponent.prototype.restoreDeletedStable = function () {
        var _this = this;
        this.alertService.confirmModal('Вы уверены, что хотите восстановить аннулированный поднадзорный объект?').subscribe(function () {
            _this.stableDataService.restoreDeletedStable(_this.id).subscribe(function () {
                _this.dataCachingService.removeCachedData('EditStableCommon', _this.id.toString());
                _this.refreshFormDataInternal();
            });
        });
    };
    EditStableCommonComponent.prototype.mergeStable = function () {
        var _this = this;
        this.stableDataService.mergeStable(this.id, this.formGroupMerge.get('mergeSourceId').value)
            .subscribe(function () {
            _this.clearCacheAfterMerge();
            _this.bookmarkService.removeOpenCardBookmark(['/stable-edit'], _this.formGroupMerge.get('mergeSourceId').value.toString());
            _this.closeMergeModal();
        }, function (error) { return FormHelper.setSingleFormGroupServerSideValidationErrors(error, _this, _this.formGroupMerge); });
    };
    EditStableCommonComponent.prototype.clearCacheAfterMerge = function () {
        var _this = this;
        var animals = this.dataCachingService.getCachedData('EditStableAnimals', this.formGroupMerge.get('mergeSourceId').value.toString());
        if (animals && animals.searchResults) {
            animals.searchResults.forEach(function (animal) { return _this.dataCachingService.removeCachedData('EditAnimalCommon', animal.id.toString()); });
        }
        this.dataCachingService.removeCachedData('EditStableAnimals', this.formGroupMerge.get('mergeSourceId').value.toString());
        this.dataCachingService.removeCachedData('EditStableAnimals', this.id.toString());
        var events = this.dataCachingService.getCachedData('EditStableEvents', this.formGroupMerge.get('mergeSourceId').value.toString());
        if (events && events.searchResults) {
            events.searchResults.forEach(function (ev) { return _this.dataCachingService.removeCachedData('EditAnimalEventCommon', ev.id.toString()); });
        }
        this.dataCachingService.removeCachedData('EditStableEvents', this.formGroupMerge.get('mergeSourceId').value.toString());
        this.dataCachingService.removeCachedData('EditStableEvents', this.id.toString());
        var products = this.dataCachingService.getCachedData('EditStableProducts', this.formGroupMerge.get('mergeSourceId').value.toString());
        if (products && products.searchResults) {
            products.searchResults.forEach(function (product) { return _this.dataCachingService.removeCachedData('EditProductCommon', product.id.toString()); });
        }
        this.dataCachingService.removeCachedData('EditStableProducts', this.formGroupMerge.get('mergeSourceId').value.toString());
        this.dataCachingService.removeCachedData('EditStableProducts', this.id.toString());
        this.dataCachingService.removeCachedData('EditStableCommon', this.formGroupMerge.get('mergeSourceId').value.toString());
        this.dataCachingService.removeCachedData('EditStableCommon', this.id.toString());
    };
    EditStableCommonComponent.prototype.searchStable = function () {
        var _this = this;
        this.navigationService.searchStable(StableSearchModalComponent).subscribe(function (val) {
            if (val) {
                _this.formGroupMerge.get('mergeSourceId').setValue(val);
            }
        });
    };
    EditStableCommonComponent.prototype.closeMergeModal = function () {
        this.clrModalOpen = false;
        this.selectedCheckboxConfirmAction = false;
        this.formGroupMerge.reset();
    };
    EditStableCommonComponent.prototype.runStableAgentsSync = function () {
        var _this = this;
        this.stableDataService.runStableAgentsSync(this.id)
            .subscribe(function () { return _this.refreshSyncState(10); });
    };
    return EditStableCommonComponent;
}());
export { EditStableCommonComponent };
