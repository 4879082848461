import { Component, Input } from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { ServerSideErrorsProvider } from '../../../logic/validators/server-side-errors-provider';
import { serverSideErrorsValidator } from '../../../logic/validators/server-side-errors-validator.directive';
import { AppFormGroup } from '../../../ui/controls/app-form-group';
import { DateHelper } from '../../../helpers/date-helper';
import { StringHelper } from '../../../helpers/string-helper';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { BehaviorSubject, Observable, of } from 'rxjs/index';
import { MetadataService } from '../../../logic/services/metadata.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { ModalParams, ModalSize } from '../../../logic/services/app-navigation.service.models';
import { DrugTypeBrowseCreateModalComponent } from '../../dictionary/drug/drug-type-browse-create-modal.component';
import { AppModalPreviewFileComponent } from '../../../ui/controls/app-modal-preview-file.component';

@Component({
  selector: 'app-edit-drug-common-form',
  templateUrl: './edit-drug-common-form.component.html'
})
export class EditDrugCommonFormComponent {

  @Input() contextFormGroup: AppFormGroup;

  isInvalid = FormHelper.isInvalid;
  processMoneyKeypress = FormHelper.processMoneyKeypress;
  changeCost = EditDrugCommonFormComponent.changeCost;
  changeStrValue = EditDrugCommonFormComponent.changeDecimalStrValue;

  private _drugTypeCache: any = {};

  public static createFormGroup(fb: FormBuilder, serverSideErrorsProvider: ServerSideErrorsProvider, val: any) {
    if (val && val.cost && +val.costTypeId === 2) {
      val.cost = (val.cost * 100).toFixed(3);
    }
    const group = fb.group({
      id: val.id,
      createdTime: val.createdTime,
      createdUser: val.createdUser,
      drugTypeId: [val.drugTypeId, Validators.compose([Validators.required,
        serverSideErrorsValidator('drugTypeId', serverSideErrorsProvider)])],
      serial: [val.serial, Validators.compose([
        Validators.required,
        serverSideErrorsValidator('serial', serverSideErrorsProvider)])],
      party: [val.party, Validators.compose([
        serverSideErrorsValidator('party', serverSideErrorsProvider)])],
      code: [val.code, Validators.compose([
        serverSideErrorsValidator('code', serverSideErrorsProvider)])],
      manufactureDate: [val.manufactureDate, Validators.compose([
        Validators.required,
        FormHelper.validateDateTimePicker(),
        serverSideErrorsValidator('manufactureDate', serverSideErrorsProvider),
      ])],
      expireDate: [val.expireDate, Validators.compose([
        Validators.required,
        FormHelper.validateDateTimePicker(),
        serverSideErrorsValidator('expireDate', serverSideErrorsProvider),
      ])],
      expireDurationCaption: '',
      actNumber: [val.actNumber, Validators.compose([
        Validators.required,
        serverSideErrorsValidator('actNumber', serverSideErrorsProvider)])],
      totalValue: val.totalValue,
      totalValueStr: [val.totalValue ? val.totalValue.toString().replace('.', ',') : null,
        Validators.compose([
          FormHelper.validateDrugDecimal(),
          Validators.required,
          serverSideErrorsValidator('totalValue', serverSideErrorsProvider)])],
      drugUnitTypeId: [val.drugUnitTypeId,
        Validators.compose([
          Validators.required,
          serverSideErrorsValidator('drugUnitTypeId', serverSideErrorsProvider)])],
      cost: val.cost,
      costStr: [val.cost ? val.cost.toString().replace('.', ',') : null,
        Validators.compose([
          FormHelper.validateMoneyThreeDecimalComma(),
          Validators.required,
          serverSideErrorsValidator('cost', serverSideErrorsProvider)])],
      costTypeId: [val.costTypeId || 1,
        Validators.compose([
          Validators.required,
          serverSideErrorsValidator('costTypeId', serverSideErrorsProvider)])],
      budgetTypeId: [val.budgetTypeId, Validators.compose([Validators.required,
        serverSideErrorsValidator('budgetTypeId', serverSideErrorsProvider)])],
      budgetReceiveTypeId: [val.budgetReceiveTypeId, Validators.compose([
        serverSideErrorsValidator('budgetReceiveTypeId', serverSideErrorsProvider)])],
      budgetReceiveComment: [val.budgetReceiveComment, Validators.compose([
        serverSideErrorsValidator('budgetReceiveComment', serverSideErrorsProvider)])],
      expiresYears: [val.expiresYears, Validators.compose([Validators.required, Validators.pattern(/^\d{1,2}$/),
        serverSideErrorsValidator('expiresYears', serverSideErrorsProvider)])],
      expiresMonths: [val.expiresMonths, Validators.compose([Validators.required, Validators.pattern(/^\d{1,2}$/),
        serverSideErrorsValidator('expiresMonths', serverSideErrorsProvider)])],
      expiresDays: [val.expiresDays, Validators.compose([Validators.required, Validators.pattern(/^\d{1,2}$/),
        serverSideErrorsValidator('expiresDays', serverSideErrorsProvider)])],
      firstPackageCount: val.firstPackageCount,
      firstPackageCountStr: [val.firstPackageCount ? val.firstPackageCount.toString().replace('.', ',') : null,
        Validators.compose([
          serverSideErrorsValidator('firstPackageCount', serverSideErrorsProvider)])],
      firstPackageUnitId: [val.firstPackageUnitId, Validators.compose([
        serverSideErrorsValidator('firstPackageUnitId', serverSideErrorsProvider)])],
      totalSum: 0,
      currentValue: val.currentValue,
      currentSum: 0,
      spentValue: val.spentValue,
      spentSum: 0,
      writeoffValue: val.writeoffValue,
      writeoffSum: 0,
      movedValue: val.movedValue,
      movedSum: 0,
      movedSumExtra: fb.array((val.movedSumExtra || []).map(el => {
        el.count = (el.count || 0).toString().replace('.', ',');
        el.sum = (el.sum || 0).toFixed(3).toString().replace('.', ',');
        return el;
      })),
      movedSumExtraExpanded: false,
      reservedValue: (val.reserved || []).reduce((partialSum, a) => partialSum + a.currentValue, 0),
      reservedSum: 0,
      institutionId: val.institutionId,
      institutionBranchId: val.institutionBranchId,
      sourceDrugId: val.sourceDrugId,
      incomingDate: [val.incomingDate, serverSideErrorsValidator('incomingDate', serverSideErrorsProvider)],
      incomingDocs: EditDrugCommonFormComponent.buildIncomingDocsFormArray(fb, val.incomingDocs),
      comments: val.comments,
      reserved: EditDrugCommonFormComponent.buildReservedFormArray(fb, val.reserved),
      drugIntroductionTypeIds: fb.array((val.drugIntroductionTypeIds || []).map(el => fb.control(el))),
    });

    group.get('expireDate').disable();
    group.get('expireDurationCaption').disable();
    group.get('totalSum').disable();
    group.get('currentSum').disable();
    group.get('spentSum').disable();
    group.get('writeoffSum').disable();
    group.get('movedSum').disable();
    group.get('movedSumExtra').disable();
    group.get('movedSumExtraExpanded').disable();
    group.get('reservedSum').disable();

    group.valueChanges.subscribe(() => {
      let expireDate: Date;

      if (group.get('manufactureDate').value) {
        const manufacturingDate = new Date(group.get('manufactureDate').value);
        const days = +(group.get('expiresDays').value || 0);
        const months = +(group.get('expiresMonths').value || 0);
        const years = +(group.get('expiresYears').value || 0);
        expireDate = DateHelper.addDuration(manufacturingDate, years, months, days);
      }

      if (StringHelper.getISODate(expireDate) !== group.get('expireDate').value) {
        group.get('expireDate').setValue(StringHelper.getISODate(expireDate));
        EditDrugCommonFormComponent.buildExpireDurationCaption(group);
      }
    });

    EditDrugCommonFormComponent.buildExpireDurationCaption(group);
    EditDrugCommonFormComponent.changeCost(group);

    return group;
  }

  private static buildExpireDurationCaption(fg: FormGroup) {
    if (!fg.get('expireDate').value) {
      fg.get('expireDurationCaption').setValue(null);
      return;
    }
    const time = DateHelper.durationInDays(DateHelper.startDay(new Date()), DateHelper.startDay(fg.get('expireDate').value));
    if (time < 0) {
      fg.get('expireDurationCaption').setValue(null);
      return;
    }
    const age = DateHelper.getPluralDay(time);
    fg.get('expireDurationCaption').setValue(age ? 'Осталось: ' + (age) : null);
  }

  private static buildReservedFormArray(fb: FormBuilder, val: any[]) {
    if (!val || !val.length) {
      val = [];
    }

    return fb.array(val.map(el => EditDrugCommonFormComponent.buildReservedFormGroup(fb, el)));
  }

  private static buildReservedFormGroup(fb: FormBuilder, val: any) {
    return fb.group({
      drugId: [val.drugId, Validators.required],
      institutionId: [val.institutionId, Validators.required],
      institutionEmployeeId: [val.institutionEmployeeId, Validators.required],
      currentValue: [val.currentValue],
      currentValueStr: [val.currentValue ? val.currentValue.toString().replace('.', ',') : null,
        Validators.compose([Validators.required, FormHelper.validateDrugDecimal()])],
    });
  }

  private static buildIncomingDocsFormArray(fb: FormBuilder, val: any[]) {
    if (!val || !val.length) {
      val = [];
    }

    return fb.array([...val, {}].map(el => EditDrugCommonFormComponent.buildIncomingDocFormGroup(fb, el)));
  }

  private static buildIncomingDocFormGroup(fb: FormBuilder, val: any) {
    return fb.group({
      typeId: [val.typeId],
      date: [val.date, FormHelper.conditionalValidate(Validators.required,
        ctrl => ctrl.parent && ctrl.parent.get('typeId').value)],
      number: [val.number, FormHelper.conditionalValidate(Validators.required,
        ctrl => ctrl.parent && ctrl.parent.get('typeId').value)],
    });
  }

  private static changeCost(fg: FormGroup) {
    if (!fg) {
      return;
    }

    EditDrugCommonFormComponent.changeDecimalStrValue(fg, 'costStr');

    const cost = +fg.get('cost').value || 0;
    fg.get('totalSum').setValue(Number((fg.get('totalValue').value || 0) * cost).toFixed(3));
    fg.get('currentSum').setValue(Number((fg.get('currentValue').value || 0) * cost).toFixed(3));
    fg.get('spentSum').setValue(Number((fg.get('spentValue').value || 0) * cost).toFixed(3));
    fg.get('writeoffSum').setValue(Number((fg.get('writeoffValue').value || 0) * cost).toFixed(3));
    fg.get('movedSum').setValue(Number((fg.get('movedValue').value || 0) * cost).toFixed(3));
    fg.get('reservedSum').setValue(Number((fg.get('reservedValue').value || 0) * cost).toFixed(3));
  }

  public static changeDecimalStrValue(fg: FormGroup, controlName: string) {
    const val = fg.get(controlName).value;
    fg.get(controlName.substring(0, controlName.length - 3)).setValue(
      val ? val.replace(',', '.') : null
    );
  }

  get selectedDrugTypeInfo$(): Observable<any> {

    if (!this.contextFormGroup || ! this.contextFormGroup.get('drugTypeId').value) {
      return of(undefined);
    }

    const drugTypeId = this.contextFormGroup.get('drugTypeId').value;

    if (!this._drugTypeCache[drugTypeId]) {
      this._drugTypeCache[drugTypeId] = new BehaviorSubject<any>({});
      this.metadataService.getMetadataSingle('drug-type', drugTypeId).subscribe(value => {
        this._drugTypeCache[drugTypeId].next(value);
      });
    }

    return this._drugTypeCache[drugTypeId];
  }

  get reserved(): FormArray {
    return this.contextFormGroup.get('reserved') as FormArray;
  }

  get incomingDocs(): FormArray {
    return this.contextFormGroup.get('incomingDocs') as FormArray;
  }

  constructor(public navigationService: AppNavigationService,
              private metadataService: MetadataService,
              private fb: FormBuilder,
              private lookupService: LookupSourceService) {

  }

  addReserved() {
    this.reserved.push(EditDrugCommonFormComponent.buildReservedFormGroup(this.fb, {
      drugId: this.contextFormGroup.get('id').value,
      institutionId: this.contextFormGroup.get('institutionId').value,
    }));
  }

  deleteReserve(index: number) {
    this.reserved.removeAt(index);
    this.contextFormGroup.markAsDirty();
  }

  changeIncomingDocType(ix: number) {
    if (!this.incomingDocs.at(ix).value.typeId) {
      this.incomingDocs.removeAt(ix);
    }
    if (this.incomingDocs.getRawValue().every(el => el.typeId)) {
      this.incomingDocs.push(EditDrugCommonFormComponent.buildIncomingDocFormGroup(this.fb, {}));
    }
  }

  changeDrugTypeId() {
    if (this.contextFormGroup.get('id').value || !this.contextFormGroup.get('drugTypeId').value) {
      return;
    }
    this.lookupService.getLookupObj('drug-type/full-record')
      .subscribe(drugTypes => {
        if (this.contextFormGroup.get('drugTypeId').value) {
          const dt = drugTypes['Obj' + this.contextFormGroup.get('drugTypeId').value];
          if (dt) {
            this.contextFormGroup.get('drugUnitTypeId').setValue(dt.packageUnitId);
            this.contextFormGroup.get('expiresDays').setValue(dt.expiresDays);
            this.contextFormGroup.get('expiresMonths').setValue(dt.expiresMonths);
            this.contextFormGroup.get('expiresYears').setValue(dt.expiresYears);
          }
        }
      });
  }

  confirmToCreateDrugType() {
    this.navigationService.showModal(
      DrugTypeBrowseCreateModalComponent,
      new ModalParams<any>(
        ModalSize.lg,
        'Создание вида препарата',
        'Создать',
        {
          isCustom: true
        }))
      .subscribe(newDrugType => {
        if (newDrugType) {
          this.contextFormGroup.controls['drugTypeId'].setValue(newDrugType);
          this.changeDrugTypeId();
        }
      });
  }

  previewFile(file: any) {
    return this.navigationService.previewFile(AppModalPreviewFileComponent, { fileUri: file });
  }

  getPrettyTitle(file: any) {
    return StringHelper.getPrettyTitleFile(file);
  }
}
