/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "@clr/angular";
import * as i3 from "./ag-grid-text-input-editor.component";
var styles_AgGridTextInputEditorComponent = [];
var RenderType_AgGridTextInputEditorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AgGridTextInputEditorComponent, data: {} });
export { RenderType_AgGridTextInputEditorComponent as RenderType_AgGridTextInputEditorComponent };
export function View_AgGridTextInputEditorComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { inputField: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 10, "label", [["aria-haspopup", "true"], ["class", "app-ag-grid-text-input-editor"], ["role", "tooltip"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null], [1, "for", 0]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 540672, null, 0, i1.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i0.ɵprd(2048, null, i1.ControlContainer, null, [i1.FormGroupDirective]), i0.ɵdid(4, 16384, null, 0, i1.NgControlStatusGroup, [[4, i1.ControlContainer]], null, null), i0.ɵdid(5, 212992, null, 0, i2.ClrLabel, [[2, i2.ɵbe], [2, i2.ɵbf], [2, i2.ɵbg], i0.Renderer2, i0.ElementRef], { forAttr: [0, "forAttr"] }, null), (_l()(), i0.ɵeld(6, 0, [[1, 0], ["inputField", 1]], null, 5, "input", [["class", "app-ag-grid-text-input"], ["type", "text"]], [[8, "id", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "keypress"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 7)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 7).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 7)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 7)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("keypress" === en)) {
        var pd_4 = (_co.keyPressCallback($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i0.ɵdid(7, 16384, null, 0, i1.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i1.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i1.DefaultValueAccessor]), i0.ɵdid(9, 671744, null, 0, i1.FormControlName, [[3, i1.ControlContainer], [8, null], [8, null], [6, i1.NG_VALUE_ACCESSOR], [2, i1.ɵangular_packages_forms_forms_k]], { name: [0, "name"] }, null), i0.ɵprd(2048, null, i1.NgControl, null, [i1.FormControlName]), i0.ɵdid(11, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.formGroup; _ck(_v, 2, 0, currVal_8); var currVal_9 = i0.ɵinlineInterpolate(2, "", _co.controlName, "+", _co.rndToken, ""); _ck(_v, 5, 0, currVal_9); var currVal_18 = _co.controlName; _ck(_v, 9, 0, currVal_18); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 4).ngClassValid; var currVal_5 = i0.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 4).ngClassPending; var currVal_7 = i0.ɵnov(_v, 5).forAttr; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_10 = i0.ɵinlineInterpolate(2, "", _co.controlName, "+", _co.rndToken, ""); var currVal_11 = i0.ɵnov(_v, 11).ngClassUntouched; var currVal_12 = i0.ɵnov(_v, 11).ngClassTouched; var currVal_13 = i0.ɵnov(_v, 11).ngClassPristine; var currVal_14 = i0.ɵnov(_v, 11).ngClassDirty; var currVal_15 = i0.ɵnov(_v, 11).ngClassValid; var currVal_16 = i0.ɵnov(_v, 11).ngClassInvalid; var currVal_17 = i0.ɵnov(_v, 11).ngClassPending; _ck(_v, 6, 0, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17); }); }
export function View_AgGridTextInputEditorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-ag-grid-text-input-editor", [], null, null, null, View_AgGridTextInputEditorComponent_0, RenderType_AgGridTextInputEditorComponent)), i0.ɵdid(1, 49152, null, 0, i3.AgGridTextInputEditorComponent, [], null, null)], null, null); }
var AgGridTextInputEditorComponentNgFactory = i0.ɵccf("app-ag-grid-text-input-editor", i3.AgGridTextInputEditorComponent, View_AgGridTextInputEditorComponent_Host_0, {}, {}, []);
export { AgGridTextInputEditorComponentNgFactory as AgGridTextInputEditorComponentNgFactory };
