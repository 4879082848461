<nav style="max-height:100%">
  <section class="sidenav-content" style="max-height:100%">

    <a class="nav-link" *ngIf="securityService.isAccess('dashboard') | async"
       [routerLink]="['/dashboard']" routerLinkActive="active">
      <clr-icon shape="home"></clr-icon>
      Стартовая страница
    </a>

    <section class="nav-group">
      <input id="tabRegistries" type="checkbox">
      <label for="tabRegistries">
        Реестры
      </label>
      <ul class="nav-list">
        <li>
          <a class="nav-link" *ngIf="securityService.isAccess('agent') | async"
             [routerLink]="['/agent-search']" routerLinkActive="active">
            <clr-icon shape="users"></clr-icon>
            Хозяйствующие субъекты
          </a>
        </li>
        <li>
          <a class="nav-link" *ngIf="securityService.isAccess('stable') | async"
             [routerLink]="['/stable-search']" routerLinkActive="active">
            <clr-icon shape="building"></clr-icon>
            Поднадзорные объекты
          </a>
        </li>
        <li>
          <a class="nav-link" *ngIf="securityService.isAccess('animal') | async"
             [routerLink]="['/animal-search']" routerLinkActive="active">
            <clr-icon shape="piggy-bank"></clr-icon>
            Животные
          </a>
        </li>
        <li>
          <a class="nav-link" *ngIf="securityService.isAccess('drug') | async"
             [routerLink]="['/drug-search']" routerLinkActive="active">
            <clr-icon shape="flask"></clr-icon>
            Препараты
          </a>
        </li>
        <li>
          <a class="nav-link" *ngIf="securityService.isAccess('product') | async"
             [routerLink]="['/product-search']" routerLinkActive="active">
            <clr-icon shape="truck"></clr-icon>
            Продукция / Документы
          </a>
        </li>
        <li>
          <a class="nav-link"  *ngIf="securityService.isAccess('quarantine') | async"
             [routerLink]="['/quarantine-block']" routerLinkActive="active">
            <clr-icon shape="flame"></clr-icon>
            Карантины
          </a>
        </li>
        <li>
          <a class="nav-link" *ngIf="securityService.isAccess('slaughtery-view') | async"
             [routerLink]="['/slaughtery-view']" routerLinkActive="active">
            <clr-icon shape="eye"></clr-icon>
            Предубойный осмотр
          </a>
        </li>
      </ul>
    </section>

    <a class="nav-link" *ngIf="securityService.isAccess('query') | async"
       [routerLink]="['/query-manager']" routerLinkActive="active">
    <clr-icon shape="bolt"></clr-icon>
    Менеджер выборок
    </a>

    <a class="nav-link" *ngIf="securityService.isAccess('pst-report') | async"
       [routerLink]="['/pst-report']" routerLinkActive="active">
      <clr-icon shape="line-chart"></clr-icon>
      Эл. отчетность
    </a>

    <section class="nav-group" *ngIf="bookmarkService.peopleCardBookmarks.length > 0">
      <input id="tabPeoplecards" type="checkbox">
      <label for="tabPeoplecards">
        Открытые карточки
      </label>
      <ul class="nav-list">
        <ng-container *ngFor="let item of bookmarkService.topLevelBookmarks">
          <li class="itech-closeable">
            <a class="nav-link" [routerLink]="item.route" routerLinkActive="active">
              <clr-icon [attr.shape]="item.icon"
                        [ngClass]="{'has-badge': item.uniqueTag.startsWith('new'), 'is-solid': !item.uniqueTag.startsWith('new')}">
              </clr-icon>
              {{item.title}}
            </a>

            <clr-icon shape="times-circle" class="is-solid itech-hover-close"
                      (click)="bookmarkService.removeOpenCardBookmark(item.route, item.uniqueTag)">
            </clr-icon>
          </li>

          <ng-container *ngFor="let child of bookmarkService.getChildBookmarks(item.route[0] + item.route[1])">

            <li class="itech-closeable">
              <a class="nav-link itech-child1" [routerLink]="child.route" routerLinkActive="active">
                <clr-icon *ngIf="child.icon=='piggy-bank'"
                          shape="piggy-bank"
                          [ngClass]="{'has-badge': child.uniqueTag.startsWith('new'), 'is-solid': !child.uniqueTag.startsWith('new')}">
                </clr-icon>
                <clr-icon *ngIf="child.icon=='color-picker'"
                          shape="color-picker"
                          [ngClass]="{'has-badge': child.uniqueTag.startsWith('new'), 'is-solid': !child.uniqueTag.startsWith('new')}">
                </clr-icon>
                {{child.title}}
              </a>

              <clr-icon shape="times-circle" class="is-solid itech-hover-close"
                        (click)="bookmarkService.removeOpenCardBookmark(child.route, child.uniqueTag)">
              </clr-icon>
            </li>

            <li *ngFor="let child2 of bookmarkService.getChildBookmarks(child.route[0] + child.route[1])"
                 class="itech-closeable">
              <a class="nav-link itech-child2" [routerLink]="child2.route" routerLinkActive="active">
                <clr-icon *ngIf="child2.icon=='color-picker'"
                          shape="color-picker"
                          [ngClass]="{'has-badge': child2.uniqueTag.startsWith('new'), 'is-solid': !child2.uniqueTag.startsWith('new')}">
                </clr-icon>
                <clr-icon *ngIf="child2.icon=='certificate'"
                          shape="certificate"
                          [ngClass]="{'has-badge': child2.uniqueTag.startsWith('new'), 'is-solid': !child2.uniqueTag.startsWith('new')}">
                </clr-icon>
                {{child2.title}}
              </a>

              <clr-icon shape="times-circle" class="is-solid itech-hover-close"
                        (click)="bookmarkService.removeOpenCardBookmark(child2.route, child2.uniqueTag)">
              </clr-icon>
            </li>
          </ng-container>
        </ng-container>
      </ul>
    </section>

    <section class="nav-group" *ngIf="bookmarkService.searchBookmarks.length > 0">
      <input id="tabSearch" type="checkbox">
      <label for="tabSearch">
        Результаты поиска
      </label>
      <ul class="nav-list">
        <li *ngFor="let item of bookmarkService.searchBookmarks" class="itech-closeable">
          <a class="nav-link" [routerLink]="item.route" routerLinkActive="active">
            <clr-icon shape="search"></clr-icon>
            {{item.title}}
          </a>

          <clr-icon shape="times-circle" class="is-solid itech-hover-close"
                    (click)="bookmarkService.removeSearchBookmark(item.title)">
          </clr-icon>
        </li>
      </ul>
    </section>

    <section class="nav-group" *ngIf="bookmarkService.dictionaryBookmarks.length > 0">
      <input id="tabDictionary" type="checkbox">
      <label for="tabDictionary">
        Справочники
      </label>
      <ul class="nav-list">
        <li *ngFor="let item of bookmarkService.dictionaryBookmarks" class="itech-closeable">
          <a class="nav-link" [routerLink]="item.route" routerLinkActive="active">
            <clr-icon shape="copy"></clr-icon>
            {{item.title}}
          </a>

          <clr-icon shape="times-circle" class="is-solid itech-hover-close"
                    (click)="bookmarkService.removeDictionaryBookmark(item.title)">
          </clr-icon>
        </li>
      </ul>
    </section>

    <section class="nav-group" *ngIf="bookmarkService.groupOperationBookmarks.length > 0">
      <input id="tabGroupOperations" type="checkbox">
      <label for="tabGroupOperations">
        Регламентные операции
      </label>
      <ul class="nav-list">
        <li *ngFor="let item of bookmarkService.groupOperationBookmarks" class="itech-closeable">
          <a class="nav-link" [routerLink]="item.route" routerLinkActive="active">
            <clr-icon shape="bolt">
            </clr-icon>
            {{item.title}}
          </a>

          <clr-icon shape="times-circle" class="is-solid itech-hover-close"
                    (click)="bookmarkService.removeGroupOperationBookmark(item.uniqueTag)">
          </clr-icon>
        </li>
      </ul>
    </section>

  </section>
</nav>
