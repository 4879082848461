/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./app-combo-lookup.component.ngfactory";
import * as i2 from "./app-combo-lookup.component";
import * as i3 from "../../logic/services/lookup-source.service";
import * as i4 from "@angular/common";
import * as i5 from "./app-combo-inline-multilookup.component";
import * as i6 from "@angular/forms";
var styles_AppComboInlineMultilookupComponent = [];
var RenderType_AppComboInlineMultilookupComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComboInlineMultilookupComponent, data: {} });
export { RenderType_AppComboInlineMultilookupComponent as RenderType_AppComboInlineMultilookupComponent };
function View_AppComboInlineMultilookupComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00A0\u00A0"]))], null, null); }
function View_AppComboInlineMultilookupComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-combo-lookup", [], null, null, null, i1.View_AppComboLookupComponent_0, i1.RenderType_AppComboLookupComponent)), i0.ɵdid(2, 573440, null, 0, i2.AppComboLookupComponent, [i3.LookupSourceService], { contextControlName: [0, "contextControlName"], contextFormGroup: [1, "contextFormGroup"], contextControlId: [2, "contextControlId"], disabled: [3, "disabled"], sorted: [4, "sorted"], required: [5, "required"], lookupName: [6, "lookupName"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppComboInlineMultilookupComponent_2)), i0.ɵdid(4, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.index; var currVal_1 = _co.boundArray; var currVal_2 = (_co.contextControlName + _v.context.index); var currVal_3 = _co.disabled; var currVal_4 = _co.sorted; var currVal_5 = false; var currVal_6 = _co.lookupName; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = !_co.noControlsDivider; _ck(_v, 4, 0, currVal_7); }, null); }
export function View_AppComboInlineMultilookupComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppComboInlineMultilookupComponent_1)), i0.ɵdid(1, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-combo-lookup", [], null, null, null, i1.View_AppComboLookupComponent_0, i1.RenderType_AppComboLookupComponent)), i0.ɵdid(3, 573440, null, 0, i2.AppComboLookupComponent, [i3.LookupSourceService], { contextControlName: [0, "contextControlName"], contextFormGroup: [1, "contextFormGroup"], contextControlId: [2, "contextControlId"], disabled: [3, "disabled"], sorted: [4, "sorted"], required: [5, "required"], lookupName: [6, "lookupName"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.boundArray.controls; _ck(_v, 1, 0, currVal_0); var currVal_1 = "value"; var currVal_2 = _co.emptyElementForm; var currVal_3 = (_co.contextControlName + "N"); var currVal_4 = _co.disabled; var currVal_5 = _co.sorted; var currVal_6 = false; var currVal_7 = _co.lookupName; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
export function View_AppComboInlineMultilookupComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-combo-inline-multilookup", [], null, null, null, View_AppComboInlineMultilookupComponent_0, RenderType_AppComboInlineMultilookupComponent)), i0.ɵdid(1, 573440, null, 0, i5.AppComboInlineMultilookupComponent, [i6.FormBuilder], null, null)], null, null); }
var AppComboInlineMultilookupComponentNgFactory = i0.ɵccf("app-combo-inline-multilookup", i5.AppComboInlineMultilookupComponent, View_AppComboInlineMultilookupComponent_Host_0, { disabled: "disabled", contextControlName: "contextControlName", contextFormGroup: "contextFormGroup", sorted: "sorted", lookupName: "lookupName", noControlsDivider: "noControlsDivider" }, {}, []);
export { AppComboInlineMultilookupComponentNgFactory as AppComboInlineMultilookupComponentNgFactory };
