<div class="itech-common-wrap no-pager">
  <div>
    <app-slaughtery-view-form (rowClicked)="appNavigationService.performAnimalEventEditing($event.col16)" [model]="model">
    </app-slaughtery-view-form>
  </div>

  <div class="itech-form-actions">
    <button class="btn btn-outline-secondary" (click)="printSlaughtery()">
      <clr-icon shape="printer"></clr-icon>&nbsp;Распечатать
    </button>
  </div>
</div>
