import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BookmarkService } from '../../logic/services/bookmark.service';
import { DataCachingService } from '../../logic/services/data-caching.service';
import { QuarantineDataService } from '../../logic/services/quarantine-data.service';
import { EditQuarantineCommonComponent } from './common/edit-quarantine-common.component';
import { FormBuilder, Validators } from '@angular/forms';
import { AppNavigationService } from '../../logic/services/app-navigation.service';
import { FormHelper } from '../../ui/controls/form-helper';
import { AlertService } from '../../ui/infrastructure/alert.service';
import { AddressPersonFioCacheService } from '../../logic/services/address-person-fio-cache.service';
import { EditQuarantineStagesComponent } from './stages/edit-quarantine-stages.component';
import { JobRunnerUiService } from '../../ui/infrastructure/job-runner-ui.service';
import { AnimalSearchResultsFormComponent } from '../edit-animal/search/animal-search-results-form.component';
import { BehaviorSubject } from 'rxjs';
import { LookupSourceService } from '../../logic/services/lookup-source.service';
import { StableSearchResultsFormComponent } from '../edit-stable/search/stable-search-results-form.component';
import { StringHelper } from '../../helpers/string-helper';
import { DateHelper } from '../../helpers/date-helper';
import { SecurityService } from '../../logic/services/security.service';
import { EditQuarantineEventsController } from './events/edit-quarantine-events.controller';
import { EditAnimalComponent } from '../edit-animal/edit-animal.component';
import { AnimalDataService } from '../../logic/services/animal-data.service';
import { serverSideErrorsValidator } from '../../logic/validators/server-side-errors-validator.directive';
var EditQuarantineComponent = /** @class */ (function () {
    function EditQuarantineComponent(route, bookmarkService, dataCachingService, quarantineDataService, fb, alertService, cache, navigationService, jobRunnerUiService, lookupSourceService, securityService, eventsCtlr, animalDataService) {
        this.route = route;
        this.bookmarkService = bookmarkService;
        this.dataCachingService = dataCachingService;
        this.quarantineDataService = quarantineDataService;
        this.fb = fb;
        this.alertService = alertService;
        this.cache = cache;
        this.navigationService = navigationService;
        this.jobRunnerUiService = jobRunnerUiService;
        this.lookupSourceService = lookupSourceService;
        this.securityService = securityService;
        this.eventsCtlr = eventsCtlr;
        this.animalDataService = animalDataService;
        this.model = EditQuarantineComponent.createDefaultModel();
        this.isAccessEdit = false;
        this.storing = false;
        this.dirty = false;
        this.changeStatusModalOpened = false;
        this.createAnimalEventModalOpened = false;
        this.createAnimalEventStoreProcess = false;
        this.isInvalid = FormHelper.isInvalid;
        this.equalsSome = FormHelper.equalsSome;
        this.gridAnimalColumnDefs = AnimalSearchResultsFormComponent.getGridColumnDefs(true).concat(EditAnimalComponent.getGridGroupingColumns(), [{
                field: 'trappedTypeGroup', hide: true, excludeExport: true,
                valueGetter: function (params) { return params && params.data ? params.data['trappedTypeIdForGroup'] : ''; },
                rowGroupingSettings: {
                    name: 'Зоне',
                    renderParams: {
                        lookupName: '/quarantine/trapped-type',
                        lookupValueGetter: function (row) { return row.value; },
                        getChildrenCount: function (node) { return EditAnimalComponent.getCountForGroup(node); },
                    }
                }
            },]);
        this.gridStableColumnDefs = [
            {
                headerName: '', field: '', width: 50, excludeExport: true,
                checkboxSelection: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true,
            },
            {
                headerName: '№', field: 'id', width: 90,
            },
            {
                headerName: 'Наименование', field: 'caption', width: 220,
                cellRenderer: function (params) {
                    var animalStateHtml = params.data.overdueEventsCount && params.data.overdueEventsCount > 0
                        ? '<clr-icon shape="history" style="color:red"' +
                            ' title="Имеются просроченные мероприятия: ' + params.data.overdueEventsCount + '"></clr-icon>'
                        : '<clr-icon shape="check" style="color:green" title="Просроченные мероприятия отсутствуют"></clr-icon>';
                    return animalStateHtml + '&nbsp;' + params.value;
                }
            },
            {
                headerName: 'Состояние ПО',
                field: 'overdueEventsCount',
                hide: true,
                suppressColumnsToolPanel: true,
                suppressFiltersToolPanel: true,
            },
            {
                headerName: 'Вид', field: 'stableTypeId', width: 100,
                valueFormatter: function (params) { return params.value ? StableSearchResultsFormComponent.stableTypeIdLookup[params.value] : '<не указан>'; },
                rowGroupingSettings: {
                    name: 'Виду',
                    renderParams: {
                        lookupName: 'stable-type',
                        lookupValueGetter: function (row) { return row.value; },
                    }
                }
            },
            {
                headerName: 'Адрес', field: 'address', width: 300,
            },
            {
                headerName: 'Владелец', field: 'owner', width: 200,
                rowGroupingSettings: {
                    name: 'Владельцу',
                    renderParams: {
                        captionGetter: function (row) { return row.value; },
                    }
                }
            },
            {
                headerName: 'Жив. под карантином', field: 'animalCountTrappedToQuarantine', width: 160,
            },
        ];
        this.selectedAnimalsFromGrid = [];
        this.selectedStableIdFromGrid = [];
        this.periodCaption = '';
    }
    EditQuarantineComponent.createDefaultModel = function () {
        return {
            commonTabActive: true,
            stagesTabActive: false,
            quaEventsTabActive: false,
            animalTabActive: false,
            stableTabActive: false,
            eventsTabActive: false,
            messageTabActive: false,
            createAnimalEventServerSideErrorsProvider: {
                serverSideErrorsProvider: [],
            },
        };
    };
    Object.defineProperty(EditQuarantineComponent.prototype, "contextFormGroups", {
        get: function () {
            return this.model ? this.model.contextFormGroups : undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditQuarantineComponent.prototype, "contextFormGroupCommon", {
        get: function () {
            return this.contextFormGroups ? this.contextFormGroups.common : undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditQuarantineComponent.prototype, "contextFormGroupStages", {
        get: function () {
            return this.contextFormGroups ? this.contextFormGroups.stages : undefined;
        },
        enumerable: true,
        configurable: true
    });
    EditQuarantineComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.paramMap
            .subscribe(function (params) {
            _this.id = parseInt(params.get('id'), 10);
            var existing = _this.dataCachingService.getCachedData('EditQuarantine', _this.id.toString());
            if (existing) {
                _this.model = existing;
            }
            else {
                _this.model = EditQuarantineComponent.createDefaultModel();
                _this.dataCachingService.addToCache('EditQuarantine', _this.id.toString(), _this.model);
            }
            _this.refreshFormDataInternal();
        });
    };
    EditQuarantineComponent.prototype.refreshFormData = function () {
        if (this.model.quaEventsTabActive) {
            this.eventsCtlr.refreshFormData(this.id);
        }
        else {
            this.dataCachingService.removeCachedData('EditQuarantine_Data', this.id.toString());
            this.dataCachingService.removeCachedData('EditQuarantine_Data_TrappedAnimal', this.id.toString());
            this.dataCachingService.removeCachedData('EditQuarantine_Data_TrappedStable', this.id.toString());
            this.cache.invalidateQuarantine(this.id);
            if (this.model && this.model.serverSideValidationErrors) {
                this.model.serverSideValidationErrors = [];
            }
            this.refreshFormDataInternal();
        }
    };
    EditQuarantineComponent.prototype.refreshFormDataInternal = function (event) {
        var _this = this;
        if (event === void 0) { event = true; }
        if (!event) {
            return;
        }
        if (!this.model.contextFormGroups) {
            this.model.contextFormGroups = {};
        }
        this.trappedStable = undefined;
        this.trappedAnimal = undefined;
        var existing = this.dataCachingService.getCachedData('EditQuarantine_Data', this.id.toString());
        if (existing) {
            existing.data.currentStatusId = existing.currentStatusId;
            this.updateFormGroups(existing.data);
            this.updatePeriodCaption();
            this.isAccessEdit = existing.accessEdit;
            this.bookmarkService.refreshQuarantineCardBookmark(this.id);
            this.dirty = false;
        }
        else {
            this.quarantineDataService.getForEditById(this.id)
                .subscribe(function (quarantine) {
                quarantine.data.currentStatusId = quarantine.currentStatusId;
                _this.isAccessEdit = quarantine.accessEdit;
                _this.updateFormGroups(quarantine.data);
                _this.updatePeriodCaption();
                _this.dataCachingService.addToCache('EditQuarantine_Data', _this.id.toString(), quarantine);
                _this.bookmarkService.refreshQuarantineCardBookmark(_this.id);
                _this.dirty = false;
            });
        }
    };
    EditQuarantineComponent.prototype.updateFormGroups = function (data) {
        if (data === void 0) { data = {}; }
        this.model.contextFormGroups.common = EditQuarantineCommonComponent.createFormGroupCommon(this.fb, this.model, data);
        this.model.contextFormGroups.stages = EditQuarantineStagesComponent.createFormGroupStages(this.fb, this.model, data);
    };
    EditQuarantineComponent.prototype.updatePeriodCaption = function () {
        var currentStatusId = +this.contextFormGroupCommon.get('currentStatusId').value;
        if (currentStatusId > 2) {
            var stage = this.model.contextFormGroups.stages.get('stagePeriods').value[3];
            if (stage) {
                if (stage.dateFrom) {
                    this.periodCaption = 'с ' + StringHelper.getRuDate(DateHelper.toDate(stage.dateFrom)) +
                        (stage.dateToInclusive ? ' по ' + StringHelper.getRuDate(DateHelper.toDate(stage.dateToInclusive)) : '');
                }
            }
        }
        else {
            this.periodCaption = '';
        }
    };
    EditQuarantineComponent.prototype.cancelEdit = function () {
        this.dataCachingService.removeCachedData('EditQuarantine_Data', this.id.toString());
        this.refreshFormData();
    };
    EditQuarantineComponent.prototype.store = function () {
        // мы сохраняем текущую открытую вкладку карточки,
        // т.к. у пользователя может быть права на редактирование одной вкладки,
        // но отсутствовать на другую
        if (!this.model) {
            return;
        }
        this.contextFormGroupStages.updateValueAndValidity();
        var data = tslib_1.__assign({}, this.contextFormGroupCommon.value, this.contextFormGroupStages.value);
        if (this.model.commonTabActive) {
            this.storeCommonTab(data);
        }
        else if (this.model.stagesTabActive) {
            this.storeStagesTab(data);
        }
    };
    EditQuarantineComponent.prototype.storeCommonTab = function (data) {
        var _this = this;
        FormHelper.markAsSubmitted(this.contextFormGroupCommon);
        if (this.contextFormGroupCommon.invalid) {
            FormHelper.setFocusToInvalidControl(this.contextFormGroupCommon);
            return;
        }
        this.storing = true;
        this.quarantineDataService.storeCommon(data)
            .subscribe(function (res) {
            _this.postStoreProcess(res);
        }, function (error) {
            FormHelper.setSingleFormGroupServerSideValidationErrorsWithRelationMsgKey(error, _this.model, _this.contextFormGroupCommon);
            _this.storing = false;
            document.getElementById('quarantineCommonContent').scrollTop = 0;
        });
    };
    EditQuarantineComponent.prototype.storeStagesTab = function (data) {
        var _this = this;
        FormHelper.markAsSubmitted(this.contextFormGroupStages);
        if (this.contextFormGroupStages.invalid) {
            FormHelper.setFocusToInvalidControl(this.contextFormGroupStages);
            return;
        }
        this.storing = true;
        this.quarantineDataService.storeStages(data)
            .subscribe(function (res) {
            _this.postStoreProcess(res);
        }, function (error) {
            _this.storing = false;
            FormHelper.setSingleFormGroupServerSideValidationErrorsWithRelationMsgKey(error, _this.model, _this.contextFormGroupStages);
        });
    };
    EditQuarantineComponent.prototype.postStoreProcess = function (response) {
        FormHelper.setSingleFormGroupServerSideValidationErrors({}, this.model, this.contextFormGroupCommon);
        FormHelper.setSingleFormGroupServerSideValidationErrors({}, this.model, this.contextFormGroupStages);
        this.cache.invalidateQuarantine(response.data.data.id);
        this.lookupSourceService.invalidateLookup('disease-type/quarantine-diseases');
        this.dataCachingService.removeCachedData('EditQuarantine_Data', this.id.toString());
        this.dataCachingService.addToCache('EditQuarantine_Data', this.id.toString(), response.data);
        this.refreshFormDataInternal();
        this.storing = false;
    };
    EditQuarantineComponent.prototype.runCalculateTrappedObjectsOp = function () {
        var _this = this;
        this.jobRunnerUiService.runOperation(13 /* "Актуализация сведений о попавших по карантин предприятий и животных"*/, {
            type: 'quarantineCalculateTrappedObject',
            quarantineId: this.id
        }, undefined, undefined, false)
            .subscribe({
            next: function (data) {
                _this.refreshFormData();
                _this.alertService.success('Сведения о попавших под карантин предприятиях и животных актуализированы');
            }
        });
    };
    EditQuarantineComponent.prototype.openChangeStatusModal = function () {
        var _this = this;
        this.changeStatusFormGroup = this.fb.group({
            newStatusId: [null, Validators.required],
            dateFromNewStatus: [null, Validators.required],
            checkboxValue: false,
        });
        this.changeStatusFormGroup.get('newStatusId').valueChanges
            .subscribe(function (change) { return _this.changeStatusChangeNewStatusId(change); });
        this.changeStatusModalOpened = true;
    };
    EditQuarantineComponent.prototype.changeStatusChangeNewStatusId = function (newStatusId) {
        if (!newStatusId) {
            this.changeStatusFormGroup.get('dateFromNewStatus').setValue(null);
        }
        var existStage = this.model.contextFormGroups.stages
            ? this.model.contextFormGroups.stages.get('stagePeriods').value[newStatusId]
            : undefined;
        this.changeStatusFormGroup.get('dateFromNewStatus')
            .setValue(existStage && existStage['dateFrom'] ? existStage['dateFrom'] : null);
    };
    EditQuarantineComponent.prototype.changeStatus = function () {
        var _this = this;
        if (!this.changeStatusFormGroup.valid) {
            FormHelper.setFocusToInvalidControl(this.changeStatusFormGroup);
            return;
        }
        this.quarantineDataService.forceChangeStatus(this.id, this.changeStatusFormGroup.get('newStatusId').value, this.changeStatusFormGroup.get('dateFromNewStatus').value)
            .subscribe(function () {
            _this.lookupSourceService.invalidateLookup('disease-type/quarantine-diseases');
            _this.refreshFormData();
            _this.changeStatusModalOpened = false;
        });
    };
    EditQuarantineComponent.prototype.getTrappedAllAnimal = function () {
        var _this = this;
        if (this.trappedAnimal) {
            return this.trappedAnimal;
        }
        this.trappedAnimal = new BehaviorSubject([]);
        var existing = this.dataCachingService.getCachedData('EditQuarantine_Data_TrappedAnimal', this.id.toString());
        if (existing) {
            this.trappedAnimal.next(existing);
        }
        else {
            this.lookupSourceService.getLookupObj('animal-type').subscribe(function (animalTypeLookup) {
                _this.lookupSourceService.getLookupObj('animal-subtype').subscribe(function (animalSubtypeIdLookup) {
                    _this.quarantineDataService.searchAllTrappedAnimal(_this.id)
                        .subscribe(function (allAnimal) {
                        allAnimal.forEach(function (el) {
                            var animalSubtype = (animalSubtypeIdLookup[el.animalTypeId] || []).find(function (x) { return x.id === el.animalSubtypeId; });
                            el.animalTypeIdCaption = el.animalTypeId ? animalTypeLookup[el.animalTypeId] : '<<Не указан>>';
                            el.animalSubtypeIdCaption = animalSubtype ? animalSubtype.caption : '<<не указан>>';
                            el.totalCount = el.totalCount || 1;
                            if (el.trappedTypeIds && el.trappedTypeIds.length) {
                                el.trappedTypeIds.sort();
                                el.trappedTypeIdForGroup = el.trappedTypeIds[0];
                            }
                        });
                        _this.dataCachingService.addToCache('EditQuarantine_Data_TrappedAnimal', _this.id.toString(), allAnimal);
                        _this.trappedAnimal.next(allAnimal);
                    });
                });
            });
        }
        return this.trappedAnimal;
    };
    EditQuarantineComponent.prototype.getTrappedAllStable = function () {
        var _this = this;
        if (this.trappedStable) {
            return this.trappedStable;
        }
        this.trappedStable = new BehaviorSubject([]);
        var existing = this.dataCachingService.getCachedData('EditQuarantine_Data_TrappedStable', this.id.toString());
        if (existing) {
            this.trappedStable.next(existing);
        }
        else {
            this.lookupSourceService.getLookupObj('stable-type').subscribe(function (stableTypeLookup) {
                StableSearchResultsFormComponent.stableTypeIdLookup = stableTypeLookup;
                _this.quarantineDataService.searchAllTrappedStable(_this.id)
                    .subscribe(function (allStable) {
                    _this.dataCachingService.addToCache('EditQuarantine_Data_TrappedStable', _this.id.toString(), allStable);
                    _this.trappedStable.next(allStable);
                });
            });
        }
        return this.trappedStable;
    };
    EditQuarantineComponent.prototype.onAnimalGridSelectionChanged = function (params) {
        if (!params || !params.data || !params.data.id) {
            return;
        }
        var ix = this.selectedAnimalsFromGrid.findIndex(function (x) { return +x.id === +params.data.id; });
        if (ix === -1) {
            this.selectedAnimalsFromGrid.push(params.data);
        }
        else {
            this.selectedAnimalsFromGrid.splice(ix, 1);
        }
    };
    EditQuarantineComponent.prototype.createAnimalEvent = function () {
        var mapArr = this.selectedAnimalsFromGrid.map(function (el) { return el.id; });
        this.navigationService.performAnimalEventCreationManyByQuarantineId(this.id, mapArr[0], mapArr.splice(1), null, this.contextFormGroupCommon ? this.contextFormGroupCommon.get('diseaseTypeId').value : null);
    };
    EditQuarantineComponent.prototype.onStableGridSelectionChanged = function (params) {
        if (!params.data.id) {
            return;
        }
        var ix = this.selectedStableIdFromGrid.indexOf(params.data.id);
        if (ix === -1) {
            this.selectedStableIdFromGrid.push(params.data.id);
        }
        else {
            this.selectedStableIdFromGrid.splice(ix, 1);
        }
    };
    EditQuarantineComponent.prototype.createStableEvent = function () {
        this.navigationService.performStableEventCreationManyByQuarantineId(this.id, this.selectedStableIdFromGrid[0], this.selectedStableIdFromGrid.splice(1));
    };
    EditQuarantineComponent.prototype.deleteQuarantine = function () {
        var _this = this;
        this.alertService.confirmModal('Вы уверены, что хотите безвозвратно удалить карточку карантина?').subscribe(function (val) {
            if (val) {
                _this.quarantineDataService.deleteQuarantine(_this.id)
                    .subscribe(function () {
                    _this.dataCachingService.removeCachedData('EditQuarantine', _this.id.toString());
                    _this.dataCachingService.removeCachedData('EditQuarantine_Data', _this.id.toString());
                    _this.dataCachingService.removeCachedData('EditQuarantine_Data_TrappedAnimal', _this.id.toString());
                    _this.dataCachingService.removeCachedData('EditQuarantine_Data_TrappedStable', _this.id.toString());
                    _this.bookmarkService.removeOpenCardBookmark(['/quarantine-edit'], _this.id.toString());
                });
            }
        });
    };
    EditQuarantineComponent.prototype.getDisabledChoicesChangeStatus = function () {
        return this.contextFormGroupCommon.get('noSuspicionStage').value
            ? [this.contextFormGroupCommon.get('currentStatusId').value, EditQuarantineStagesComponent.QUARANTINE_STAGE.SUSPICION]
            : [this.contextFormGroupCommon.get('currentStatusId').value];
    };
    EditQuarantineComponent.prototype.openCreateAnimalEventModal = function () {
        var _this = this;
        this.lookupSourceService.getLookupObj('event-type').subscribe(function (lookup) {
            _this.createAnimalEventFormGroup = _this.fb.group({
                date: [null, Validators.compose([FormHelper.validateDateTimePicker(),
                        serverSideErrorsValidator('date', _this.model.createAnimalEventServerSideErrorsProvider)])],
                eventTypeId: [null, Validators.compose([Validators.required,
                        serverSideErrorsValidator('eventTypeId', _this.model.createAnimalEventServerSideErrorsProvider)])],
                eventKind: null,
                checkboxValue: false,
                animals: _this.fb.array((_this.selectedAnimalsFromGrid || []).map(function (el) { return el.id; })),
                quarantineId: _this.id,
                diseaseTypeId: _this.contextFormGroupCommon.get('diseaseTypeId').value,
                laboratoryInstitutionId: [null, Validators.compose([
                        serverSideErrorsValidator('laboratoryInstitutionId', _this.model.createAnimalEventServerSideErrorsProvider)
                    ])],
                examNo: [null, Validators.compose([
                        serverSideErrorsValidator('examNo', _this.model.createAnimalEventServerSideErrorsProvider)
                    ])],
            });
            _this.createAnimalEventFormGroup.get('eventTypeId').valueChanges.subscribe(function () {
                _this.createAnimalEventFormGroup.get('eventKind').setValue((lookup['Obj' + _this.createAnimalEventFormGroup.get('eventTypeId').value] || {}).eventKind);
            });
        });
        this.createAnimalEventModalOpened = true;
    };
    EditQuarantineComponent.prototype.storeAnimalEvent = function () {
        var _this = this;
        FormHelper.markAsSubmitted(this.createAnimalEventFormGroup);
        if (!this.createAnimalEventFormGroup.valid) {
            FormHelper.setFocusToInvalidControl(this.createAnimalEventFormGroup);
            return;
        }
        this.createAnimalEventStoreProcess = true;
        this.animalDataService.createAnimalEventsLazy(this.createAnimalEventFormGroup.value)
            .subscribe(function () {
            _this.refreshFormData();
            FormHelper.setSingleFormGroupServerSideValidationErrors({}, _this.model.createAnimalEventServerSideErrorsProvider, _this.createAnimalEventFormGroup);
            _this.createAnimalEventModalOpened = false;
            _this.createAnimalEventStoreProcess = false;
        }, function (err) {
            FormHelper.setSingleFormGroupServerSideValidationErrors(err, _this.model.createAnimalEventServerSideErrorsProvider, _this.createAnimalEventFormGroup);
            _this.createAnimalEventStoreProcess = false;
        });
    };
    EditQuarantineComponent.prototype.animalGridReady = function ($event) {
        this.selectedAnimalsFromGrid = [];
        this.selectedStableIdFromGrid = [];
    };
    return EditQuarantineComponent;
}());
export { EditQuarantineComponent };
