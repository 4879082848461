import { Component, Input } from '@angular/core';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { StableDataService } from '../../../logic/services/stable-data.service';
import { StringHelper } from '../../../helpers/string-helper';
import { StableSearchModalComponent } from '../../edit-stable/search/stable-search-modal.component';
import { JobRunnerUiService } from '../../../ui/infrastructure/job-runner-ui.service';

@Component({
  selector: 'app-edit-agent-stables',
  templateUrl: './edit-agent-stables.component.html'
})
export class EditAgentStablesComponent {

  @Input() model: any = {};

  isInvalid = FormHelper.isInvalid;

  gridColumnDefs = [
    {
      headerName: '', field: '', width: 50,
      checkboxSelection: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true,
    },
    {
      headerName: '№', field: 'id', width: 90,
    },
    {
      headerName: 'Наименование', field: 'caption', width: 250,
      cellRenderer: params => {
        const animalStateHtml = params.data.overdueEventsCount && params.data.overdueEventsCount > 0
          ? '<clr-icon shape="history" style="color:red"' +
            ' title="Имеются просроченные мероприятия: ' + params.data.overdueEventsCount + '"></clr-icon>'
          : '<clr-icon shape="check" style="color:green" title="Просроченные мероприятия отсутствуют"></clr-icon>';

        return animalStateHtml + '&nbsp;' + params.value;
      }
    },
    {
      headerName: 'Состояние ПО',
      field: 'overdueEventsCount',
      hide: true,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
    },
    {
      headerName: 'Вид', field: 'stableTypeId', width: 180,
      valueFormatter: params => params.value ? this.stableTypeIdLookup[params.value] : '<не указан>',
    },
    {
      headerName: 'Площадь, кв.м.', field: 'square', width: 140,
    },
    {
      headerName: 'Кадастровый номер', field: 'cadNo', width: 180,
    },
    {
      headerName: 'Адрес', field: 'address', width: 350,
    },
    {
      headerName: 'Владение с', field: 'dateFrom', width: 120,
      valueFormatter: params => StringHelper.getRuDate(params.value),
    },
    {
      headerName: 'Владение по', field: 'dateTo', width: 120,
      valueFormatter: params => StringHelper.getRuDate(params.value),
    },
  ];

  stableTypeIdLookup: any = {};

  private _id: number;
  @Input()
  get id(): number {
    return this._id;
  }

  set id(id: number) {
    if (this._id !== id) {
      this._id = id;
      this.refreshFormDataInternal();
    }
  }

  constructor(private stableDataService: StableDataService,
              private dataCachingService: DataCachingService,
              private waitingOverlay: GlobalWaitingOverlayService,
              private lookupSourceService: LookupSourceService,
              public navigationService: AppNavigationService,
              private alertService: AlertService,
              private jobRunnerUiService: JobRunnerUiService) {

    this.lookupSourceService.getLookupObj('stable-type').subscribe(lookup => this.stableTypeIdLookup = lookup);
  }

  refreshFormData() {
    this.dataCachingService.removeCachedData('EditAgentStables', this.id.toString());
    this.refreshFormDataInternal();
  }

  refreshFormDataInternal() {
    this.waitingOverlay.StartWaiting();

    const existing = this.dataCachingService.getCachedData('EditAgentStables', this.id.toString());

    if (existing) {
      this.model = existing;
      this.waitingOverlay.EndWaiting();
    } else {
      this.stableDataService.searchByAgent(this.id, this.model.showHistory)
        .subscribe({
          next: data => {
            this.model = {searchResults: data, showHistory: this.model.showHistory};
            this.dataCachingService.addToCache('EditAgentStables', this.id.toString(), this.model);
            this.waitingOverlay.EndWaiting();
          },
          error: () => {
            this.waitingOverlay.EndWaiting();
          }
        });
    }
  }

  getRowStyle(params) {
    if (params.data && (params.data.dateTo || params.data.deletedTime || params.data.defaultHidden)) {
      return {color: 'silver'};
    }
  }

  addExisting() {
    this.navigationService.searchStable(StableSearchModalComponent).subscribe(val => {
      if (val) {
        this.navigationService.performStableEditing(val, this.id);
      }
    });
  }

  addNew() {
    this.navigationService.performStableCreation(this.id);
  }

  updateFromMercuryAndRefreshFormData() {
    this.jobRunnerUiService.runOperation(4 /*Обновление списка поднадзорных объектов хозяйствующего субъекта*/,
      {
        type: 'singleAgentStableSync',
        agentId: this.id
      }, undefined, undefined, true)
      .subscribe({
        next: (data) => {
          const dataParsed = data ? JSON.parse(data) : [];
          if (dataParsed.length) {
            this.alertService.success('Поднадзорные объекты обновлены (добавлено, изменено - ' +
              dataParsed.length + ' объектов)');
            this.refreshFormData();
          } else {
            this.alertService.success('Все сведения актуальны - обновленных (новых) объектов для загрузки не обнаружено');
          }
        }
      });
  }

  onGridSelectionChanged(params: any) {
    this.model.selectedRows = params.api.getSelectedRows();
  }

  changeVisibility() {
    this.waitingOverlay.StartWaiting();
    this.model.searchResults.forEach(r => this.model.selectedRows.some(item => item.id === r.id)
        ? r.defaultHidden = !r.defaultHidden
        : r.defaultHidden);

        this.stableDataService.updateDefaultHiddenForStables(this.model.searchResults).subscribe({
      next: (data) => {
        this.refreshFormData();
        this.waitingOverlay.EndWaiting();
      }
    });
  }
}
