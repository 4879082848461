import { Component, EventEmitter } from '@angular/core';
import { SecurityService } from '../../logic/services/security.service';
import { AlertService } from '../../ui/infrastructure/alert.service';
import { AgGridButtonRendererComponent } from '../../ui/controls/ag-grid-button-renderer.component';

@Component({
  selector: 'app-user-tags-autofill',
  template: `
    <div class="itech-common-wrap" id="interceptNoAccess">
      <div id="agent-common-content">
        <div class="itech-doc-container" style="min-height:100%">
          <div style="padding:5px;width:100%;min-height:88%">
            <app-grid [rowData]="(this.userService.getUserInfo()|async).userSettingUi.tags"
                      [columnDefs]="gridColumnDefs"
                      [uniqueGridName]="'UserTagsAutofillGrid'" [frameworkComponents]="frameworkComponents"
                      [exportFileName]="'Тэги автозаполнения ' + (userService.getUserInfo()|async).id">
            </app-grid>
          </div>
        </div>
      </div>

      <div class="itech-form-actions">
        <div>
          <button class="btn btn-success-outline" (click)="editTag({})">
            <clr-icon shape="plus"></clr-icon>&nbsp;Добавить
          </button>
        </div>
      </div>
    </div>
    <app-user-tags-autofill-edit-modal [modalOpenedEmit]="editTagModalOpened">
    </app-user-tags-autofill-edit-modal>
  `,
})
export class UserTagsAutofillComponent {
  storing = false;
  editTagModalOpened = new EventEmitter();

  frameworkComponents: any = {
    buttonRenderer: AgGridButtonRendererComponent,
  };

  gridColumnDefs = [
    {
      headerName: '',
      field: '',
      cellRenderer: 'buttonRenderer',
      cellRendererParams: row => {
        return {
          icon: 'trash',
          title: 'Удалить строку',
          onClickCallback: () =>
            this.alertService.confirmModal(`Вы уверены, что хотите удалить тэг "${row.data.caption}"?`)
              .subscribe(() => this.userService.removeUserTag(row.data.id).subscribe(() => {})),
        };
      },
      cellClass: 'app-grid-editable-button-cell',
      width: 22,
    },
    {
      headerName: 'Ид',
      field: 'id',
      width: 80,
      onCellClicked: cell => this.editTag(cell.data),
    },
    {
      headerName: 'Группа',
      field: 'groupCaption',
      width: 140,
      onCellClicked: cell => this.editTag(cell.data),
    },
    {
      headerName: 'Наименование',
      field: 'caption',
      width: 140,
      onCellClicked: cell => this.editTag(cell.data),
    },
    {
      headerName: 'Содержимое',
      field: 'value',
      width: 500,
      onCellClicked: cell => this.editTag(cell.data),
    },
  ];

  constructor(public userService: SecurityService,
              public alertService: AlertService) {
  }

  editTag(params: any) {
    this.editTagModalOpened.emit(params);
  }
}
