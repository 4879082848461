import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular/dist/interfaces';

@Component({
  selector: 'app-journal-detail-cell-renderer',
  template: `
    <div class="full-width-panel">
      <div class="full-width-details">
        {{params.data.changeInfo}}
      </div>
    </div>`
})
export class JournalMasterDetailCellRendererComponent implements ICellRendererAngularComp {

  params: any;
  masterGridApi: any;
  masterRowIndex: any;

  // called on init
  agInit(params: any): void {
    this.params = params;

    this.masterGridApi = params.api;
    this.masterRowIndex = params.rowIndex;
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.params = params;
    return false;
  }
}
