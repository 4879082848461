import * as tslib_1 from "tslib";
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { MetadataService } from '../../../logic/services/metadata.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MetaBrowseBaseComponent } from '../meta-browse.base.component';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
var AddrCityTypeBrowseComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AddrCityTypeBrowseComponent, _super);
    function AddrCityTypeBrowseComponent(route, metadataService, alertService, globalWaitingOverlayService, lookupService, fb, bookmarkService, dataCachingService) {
        var _this = _super.call(this, route, metadataService, alertService, globalWaitingOverlayService, lookupService, fb, bookmarkService, dataCachingService) || this;
        _this.gridColumnDefs = [
            { headerName: '№', field: 'id', width: 100 },
            { headerName: 'Наименование', field: 'caption', width: 500 },
            { headerName: 'Краткое наименование', field: 'shortCaption', width: 200 },
        ];
        return _this;
    }
    AddrCityTypeBrowseComponent.prototype.getMetaTitle = function () {
        return 'Типы населенных пунктов';
    };
    AddrCityTypeBrowseComponent.prototype.getMetaName = function () {
        return 'addr-city-type';
    };
    AddrCityTypeBrowseComponent.prototype.getGroupDef = function (row) {
        return {
            id: [row.id, Validators.compose([Validators.required, Validators.pattern(/^\d{1,2}$/)])],
            caption: [row.caption, Validators.required],
            shortCaption: [row.shortCaption, Validators.required],
        };
    };
    return AddrCityTypeBrowseComponent;
}(MetaBrowseBaseComponent));
export { AddrCityTypeBrowseComponent };
