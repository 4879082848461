<div class="itech-common-wrap no-pager">
  <div>
    <div class="itech-content-header itech-no-margin-bottom">
      <clr-icon shape="event" size="36"></clr-icon>&nbsp;Роли пользователей
    </div>

    <div class="itech-security-roles-navigation">

      <div class="itech-block-inline" id="selectedPersonGroup">
        <div class="select">
          <select [(ngModel)]="selectedUserRole" (change)="selectedRoleChange()">
            <option *ngFor="let item of userRoles" [ngValue]="item">
              {{item.caption}}
            </option>
          </select>
        </div>

        <div class="itech-control-desc">
          Выберите роль для редактирования, или создайте новую роль, нажав на "+"
        </div>
      </div>

      <button type="button" class="btn btn-danger btn-link itech-inline-tool-btn" title="Добавить новую роль"
              (click)="addRole()" [disabled]="!selectedUserRole || selectedUserRole.id? undefined: true">
        <clr-icon shape="plus"></clr-icon>
      </button>

      <button type="button" class="btn btn-danger btn-link itech-inline-tool-btn" title="Копировать выбранную роль"
              (click)="copyRole()" [disabled]="!selectedUserRole">
        <clr-icon shape="copy"></clr-icon>
      </button>
    </div>

    <h1 *ngIf="!contextFormGroup">
      <clr-icon shape="info-standard" size="36"></clr-icon>
      Выберите роль для редактирования или добавьте новую
    </h1>

    <div *ngIf="contextFormGroup" class="form compact" [formGroup]="contextFormGroup">

      <section class="form-block itech-block-large">
        <label>Общая информация</label>

        <div class="form-group">
          <label for="caption" class="required">Наименование</label>
          <label for="caption" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                 [class.invalid]="isInvalid(contextFormGroup, 'caption')">
            <input type="text" id="caption" class="itech-control-m-large" required
                   formControlName="caption">
            <app-validation-tooltip [input]="contextFormGroup.controls['caption']"></app-validation-tooltip>
          </label>
        </div>
      </section>

      <h1 *ngIf="itemsLoading">
        <span class="spinner spinner-md"></span>Загружаю список функций
      </h1>

      <clr-tabs *ngIf="groups && groups.length">
        <clr-tab  *ngFor="let group of groups; index as j">
          <button clrTabLink>{{group.caption}}</button>
          <ng-template [(clrIfActive)]="group.tabActive">
            <clr-tab-content>
              <section class="form-block itech-block-large">

                <ng-container *ngFor="let item of group.items; index as i" [formGroup]="item.formGroup">

                  <div class="form-group">
                    <label for="caption">
                      <div class="checkbox-inline">
                        <input type="checkbox" id="funcChecked{{i}}{{j}}" formControlName="checked">
                        <label for="funcChecked{{i}}{{j}}">
                          {{item.func.caption}}
                          <clr-tooltip *ngIf="item.nestedFuncs.length">
                            <clr-icon clrTooltipTrigger shape="exclamation-circle" size="16"></clr-icon>
                            <clr-tooltip-content clrPosition="top-right" clrSize="lg" *clrIfOpen>
                              Функции с теми же настройками:
                              <ul *ngFor="let nested of item.nestedFuncs; index as n">
                                <li>{{nested.func.caption}}</li>
                              </ul>
                            </clr-tooltip-content>
                          </clr-tooltip>
                        </label>
                      </div>
                    </label>
                    <app-combo-lookup class="itech-control-large"
                                      [disabled]="item.formGroup.get('checked').value && item.func.appliesScopes ? undefined: true"
                                      [contextControlId]="'objectsScope' + i + '_' + j"
                                      [contextControlName]="'objectsScope'"
                                      [lookupName]="'security-function-scope'"
                                      [required]="false"
                                      [contextFormGroup]="item.formGroup"></app-combo-lookup>
                  </div>

                  <div class="form-group"
                       *ngIf="item.formGroup.get('checked').value && (item.func.metaName1 || item.func.metaName2 || item.func.metaName3 || item.func.metaNameCombineKey1)">
                    <label>
                    </label>
                    <a *ngIf="!item.toggled" class="itech-link" (click)="item.toggled = true">
                      Показать дополнительные параметры
                      <clr-icon shape="caret down"></clr-icon>
                    </a>
                    <a *ngIf="item.toggled" class="itech-link" (click)="item.toggled = false">
                      Скрыть дополнительные параметры
                      <clr-icon shape="caret"></clr-icon>
                    </a>
                  </div>

                  <app-ur-choose-object-meta *ngIf="item.toggled && item.func.metaName1" [metaGroupName]="'objectsMeta1'"
                                             [item]="item" [groupId]="i + '_' + j" [lookupName]="item.func.metaName1">
                  </app-ur-choose-object-meta>
                  <app-ur-choose-object-meta *ngIf="item.toggled && item.func.metaName2" [metaGroupName]="'objectsMeta2'"
                                             [item]="item" [groupId]="i + '_' + j" [lookupName]="item.func.metaName2">
                  </app-ur-choose-object-meta>
                  <app-ur-choose-object-meta *ngIf="item.toggled && item.func.metaName3" [metaGroupName]="'objectsMeta3'"
                                             [item]="item" [groupId]="i + '_' + j" [lookupName]="item.func.metaName3">
                  </app-ur-choose-object-meta>
                  <app-ur-choose-object-meta-combine-key *ngIf="item.toggled && item.func.metaNameCombineKey1" [metaGroupName]="'objectsMetaCombineKey1'"
                                                         [item]="item" [groupId]="i + '_' + j" [lookupName]="item.func.metaNameCombineKey1"
                                                         [lookupNameParent]="item.func.metaNameCombineKey1Parent">
                  </app-ur-choose-object-meta-combine-key>
                </ng-container>
              </section>
            </clr-tab-content>
          </ng-template>
        </clr-tab>
      </clr-tabs>
    </div>

    <app-doc-validation-errors *ngIf="contextFormGroup"
                               [validationErrors]="contextFormGroup.docLevelServerSideValidationErrors">
    </app-doc-validation-errors>

  </div>

  <div>
    <div *ngIf="contextFormGroup" class="itech-form-actions">
      <div>
        <button *ngIf="contextFormGroup.get('id').value" type="button" class="btn btn-warning-outline"
                (click)="deleteCurrentRole()">
          <clr-icon shape="trash"></clr-icon>&nbsp;Удалить роль
        </button>
      </div>

      <div>
      <button class="btn btn-primary-outline" [disabled]="selectedUserRole.id?undefined: true"
              (click)="navigationService.navigateJournal(undefined, 4, selectedUserRole.id, undefined)">
        <clr-icon shape="view-list"></clr-icon>&nbsp;Журнал
      </button>
      </div>

      <div>
        <button type="button" class="btn btn-primary" (click)="store()" [clrLoading]="storing"
                [disabled]="storing || !contextFormGroup || !contextFormGroup.dirty">
          <clr-icon shape="check"></clr-icon>&nbsp;Сохранить
        </button>
        <button type="button" class="btn btn-warning" (click)="createContextFormGroup()"
                [disabled]="storing || !contextFormGroup || !contextFormGroup.dirty">
          <clr-icon shape="undo"></clr-icon>&nbsp;Отмена
        </button>
      </div>
    </div>
  </div>
</div>
