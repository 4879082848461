import { FormGroup } from '@angular/forms';
var QueryDetailsStableFieldsComponent = /** @class */ (function () {
    function QueryDetailsStableFieldsComponent() {
        this.class = 'form-block itech-block-normal';
    }
    Object.defineProperty(QueryDetailsStableFieldsComponent.prototype, "streetLookupName", {
        get: function () {
            return 'addr-street-' + this.contextFormGroup.get('_par_city_region_id').value +
                '-' + this.contextFormGroup.get('_par_city_id').value;
        },
        enumerable: true,
        configurable: true
    });
    return QueryDetailsStableFieldsComponent;
}());
export { QueryDetailsStableFieldsComponent };
