
      <div *ngIf="contextFormGroup.get(controlName).value && (isAccessView || isAccessEdit)" class="itech-form-group-one-line">
        {{preTitle}}
        <a class="itech-link align-bottom" style="padding-right: 5px;color:#472cdc"
           (click)="previewFile(contextFormGroup.get(controlName).value)">
          {{index ? index + ' ' : ''}}{{getPrettyTitle(contextFormGroup.get(controlName).value)}}</a>
        <button *ngIf="isAccessEdit" type="button" class="btn btn-danger btn-link itech-inline-tool-btn" title="Удалить файл"
                (click)="deleteFile()" style="margin:0;vertical-align:top">
          <clr-icon shape="trash" style="transform: inherit;color: #d23b3b"></clr-icon>
        </button>
      </div>
      <div *ngIf="!contextFormGroup.get(controlName).value && isAccessEdit" class="itech-form-group-one-line">
          <label for="{{controlName}}{{contextControlId}}" style="min-width:185px;margin-top:0"
                 [class.invalid]="isInvalid(contextFormGroup, controlName)">
              <a class="itech-link itech-control-normal" (click)="fileUploadClick()" id="{{controlName}}{{contextControlId}}"
                [style.color]="contextFormGroup.get(controlName).invalid ? 'red' : ''">
                <input *ngIf="!newFileToUploadToDirectionResetFlag" type="file" #newFileToUploadToDirectionResetFlag
                     (change)="fileChanged($event)" style="visibility: hidden;width: 0">
                <clr-icon shape="upload" [style.color]="contextFormGroup.get(controlName).invalid ? 'red' : 'green'"></clr-icon>
                &nbsp;Загрузить новый файл
              </a>
          </label>
      </div>
      <div *ngIf="!isAccessEdit && !isAccessView" class="itech-form-group-one-line"
      style="color: silver">
          {{preTitle}}{{contextFormGroup.get(controlName).value ? getPrettyTitle(contextFormGroup.get(controlName).value) : 'отсутствует'}}
      </div>