import { OnChanges, SimpleChanges } from '@angular/core';
var MoMapComponent = /** @class */ (function () {
    function MoMapComponent() {
    }
    MoMapComponent.prototype.ngOnChanges = function (changes) {
        if (changes.hasOwnProperty('data')) {
            this.prettifyData();
            this.updateDataColors();
        }
    };
    MoMapComponent.prototype.mouseover = function (id) {
        this.focusedId = id;
        this.focusedMoData = this.data[id];
    };
    MoMapComponent.prototype.mouseout = function (id) {
        if (this.focusedId === id) {
            this.focusedId = undefined;
            this.focusedMoData = undefined;
        }
    };
    MoMapComponent.prototype.updateDataColors = function () {
        // ищем минимальные-максимальные значения, чтобы потом нормировать
        var minVal = 100000000000000, maxVal = 0;
        for (var mo in this.data) {
            if (!this.data.hasOwnProperty(mo)) {
                continue;
            }
            var singleMoData = this.data[mo];
            if (minVal > singleMoData.color) {
                minVal = singleMoData.color;
            }
            if (maxVal < singleMoData.color) {
                maxVal = singleMoData.color;
            }
        }
        // теперь нормируем, вычисляя цвет по шкале от 0 (минимальное) до 20 (максимальное)
        for (var mo in this.data) {
            if (!this.data.hasOwnProperty(mo)) {
                continue;
            }
            var singleMoData = this.data[mo];
            if (maxVal > minVal && singleMoData.color > 0) {
                var perc = (singleMoData.color - minVal) * 20 / (maxVal - minVal);
                singleMoData.__app_color = MoMapComponent.colorStops[Math.floor(perc)];
            }
            else {
                singleMoData.__app_color = 'white';
            }
        }
    };
    MoMapComponent.prototype.prettifyData = function () {
        var _loop_1 = function (i) {
            if (!this_1.data[i.toString()]) {
                this_1.data[i.toString()] = {};
            }
            var singleMoData = this_1.data[i.toString()];
            if (!singleMoData.__app_params || !singleMoData.__app_params.length) {
                singleMoData.__app_params = [];
            }
            for (var mo in singleMoData) {
                if (!singleMoData.hasOwnProperty(mo)) {
                    continue;
                }
                if (mo && mo.startsWith('disp__')) {
                    if (mo.endsWith('__array')) {
                        if (!singleMoData[mo] || !singleMoData[mo].length) {
                            continue;
                        }
                        var arr = singleMoData[mo].split(';');
                        arr.forEach(function (el) { return singleMoData.__app_params.push({ name: el.split('::')[0], value: el.split('::')[1] }); });
                    }
                    else {
                        singleMoData.__app_params.push({ name: mo.substring(6), value: singleMoData[mo] });
                    }
                }
            }
        };
        var this_1 = this;
        for (var i = 1; i < 100; i++) {
            _loop_1(i);
        }
    };
    MoMapComponent.colorStops = ['#c0fff6',
        '#90fff6',
        '#60fff6',
        '#30fff6',
        '#00fff6',
        '#00ffd1',
        '#00ffa4',
        '#00ff75',
        '#00ff5e',
        '#00ff00',
        '#2eff00',
        '#4eff00',
        '#aaff00',
        '#e2ff00',
        '#ffeb00',
        '#ffc100',
        '#ffaa00',
        '#ff9800',
        '#ff8000',
        '#ff6c00',
        '#ff4600',
    ];
    return MoMapComponent;
}());
export { MoMapComponent };
