import * as tslib_1 from "tslib";
import { MetaBrowseBaseComponent } from '../meta-browse.base.component';
import { ActivatedRoute } from '@angular/router';
import { MetadataService } from '../../../logic/services/metadata.service';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { FormBuilder, Validators } from '@angular/forms';
import { JobRunnerUiService } from '../../../ui/infrastructure/job-runner-ui.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { StringHelper } from '../../../helpers/string-helper';
var DrugPackageTypeBrowseComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DrugPackageTypeBrowseComponent, _super);
    function DrugPackageTypeBrowseComponent(route, metadataService, alertService, globalWaitingOverlayService, lookupService, bookmarkService, fb, jobRunnerUiService, dataCachingService) {
        var _this = _super.call(this, route, metadataService, alertService, globalWaitingOverlayService, lookupService, fb, bookmarkService, dataCachingService) || this;
        _this.jobRunnerUiService = jobRunnerUiService;
        _this.gridColumnDefs = [
            { headerName: '№', field: 'id', width: 100 },
            { headerName: 'Наименование', field: 'caption', tooltipField: 'caption', width: 350 },
            { headerName: 'Guid', field: 'guid', tooltipField: 'guid', width: 200 },
            {
                headerName: 'Удалено', field: 'dateDeleted', width: 100,
                valueFormatter: function (params) { return StringHelper.getRuDate(params.value); },
            },
        ];
        return _this;
    }
    DrugPackageTypeBrowseComponent.prototype.getMetaTitle = function () {
        return 'Виды упаковок';
    };
    DrugPackageTypeBrowseComponent.prototype.getMetaName = function () {
        return 'drug-package-type';
    };
    DrugPackageTypeBrowseComponent.prototype.getId = function (formGroup) {
        return formGroup.get('id').value;
    };
    DrugPackageTypeBrowseComponent.prototype.getGroupDef = function (row) {
        return {
            id: [row.id, Validators.compose([Validators.required, Validators.pattern(/^\d{1,5}$/)])],
            caption: [row.caption, Validators.required],
            guid: row.guid,
        };
    };
    DrugPackageTypeBrowseComponent.prototype.getRowStyle = function (params) {
        if (params.data && params.data.dateDeleted) {
            return { color: 'silver' };
        }
    };
    return DrugPackageTypeBrowseComponent;
}(MetaBrowseBaseComponent));
export { DrugPackageTypeBrowseComponent };
