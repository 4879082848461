import { ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { PstReportService } from '../../services/pst-report.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { AgentSearchModalComponent } from '../../../pages/edit-agent/search/agent-search-modal.component';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';
import { combineLatest, noop, of } from 'rxjs';
import { PstReportStructureService } from '../../services/pst-report-structure.service';
import { map } from 'rxjs/operators';
import { IndicatorDependencyModalComponent } from '../indicator-dependency-modal.component';
import { ReportIndicatorMetaEditModalComponent } from '../meta/report-indicator-meta-edit-modal.component';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { PstIndicatorTypeMetaEditModalComponent } from '../meta/pst-indicator-type-meta-edit-modal.component';
import { NumericHelper } from '../../../helpers/numeric-helper';
import { SecurityService } from '../../../logic/services/security.service';
var PstBranchTabColEditComponent = /** @class */ (function () {
    function PstBranchTabColEditComponent(lookupSourceService, navigationService, pstReportService, cacheService, pstReportStructureService, appNavigationService, alertService, securityService) {
        this.lookupSourceService = lookupSourceService;
        this.navigationService = navigationService;
        this.pstReportService = pstReportService;
        this.cacheService = cacheService;
        this.pstReportStructureService = pstReportStructureService;
        this.appNavigationService = appNavigationService;
        this.alertService = alertService;
        this.securityService = securityService;
        this.scrollLeft = 0;
    }
    PstBranchTabColEditComponent.prototype.ngOnChanges = function (changes) {
        if (changes.hasOwnProperty('branch') || changes.hasOwnProperty('model')) {
            this.rebuildHeader();
            this.startUpdatingRowsStructure();
        }
    };
    PstBranchTabColEditComponent.prototype.rebuildHeader = function () {
        var _this = this;
        this.branch.headerRows = [];
        this.branch.rootRowsGroup = [];
        this.branch.rootRowsGroupHash = {};
        if (!this.branch || !this.branch.indicators) {
            return;
        }
        var depth = 1;
        this.branch.indicators.forEach(function (indicator) {
            var path = (indicator.displayTitle || '').split('->');
            if (path.length > depth) {
                depth = path.length;
            }
        });
        var rowGroupCaptions = (this.branch.meta.params['row-titles'] || '').split('|');
        var _loop_1 = function (lvl) {
            var row = [];
            this_1.branch.indicators.forEach(function (indicator) {
                var path = (indicator.displayTitle || '').split('->');
                var ix_spacer = -1;
                for (var i = 1; i < path.length; i++) {
                    if (!path[i]) {
                        ix_spacer = i;
                        path[i] = path[i - 1];
                    }
                }
                if (ix_spacer >= 0) {
                    while (path.length < depth) {
                        path.splice(ix_spacer, 0, path[ix_spacer]);
                    }
                }
                var displayTitlePath = path.slice(0, lvl < path.length ? lvl + 1 : path.length).join('->');
                // заголовок формируем только по 0-й строке
                if (!indicator.meta.rowNo) {
                    row[indicator.meta.colNo - 1] = {
                        meta: indicator.meta,
                        displayTitle: lvl < path.length ? path[lvl] : path[path.length - 1],
                        displayTitlePath: displayTitlePath,
                        colSpan: 1,
                        rowSpan: 1,
                        visible: function () { return _this.model.showHiddenCells || (!indicator.meta.hidden && !indicator.indicatorMeta.isProtected); },
                    };
                }
                if (!_this.branch.rootRowsGroup[indicator.meta.rowNo]) {
                    _this.branch.rootRowsGroup[indicator.meta.rowNo] = {
                        hash: {},
                        children: [],
                        caption: rowGroupCaptions[indicator.meta.rowNo],
                        indicators: [],
                        hasEditableCells: false,
                        visible: function () { return !_this.model.reportMeta.regionId; },
                    };
                }
                var rowGroup = _this.branch.rootRowsGroup[indicator.meta.rowNo];
                rowGroup.indicators[indicator.meta.colNo - 1] = indicator;
                var indicatorLevel = _this.branch.meta.indicatorLevel;
                if (!_this.model.reportMeta.regionId && _this.branch.meta.subjectIndicatorLevel) {
                    indicatorLevel = _this.branch.meta.subjectIndicatorLevel;
                }
                if (!rowGroup.hasEditableCells && indicator.indicatorMeta
                    && indicator.indicatorMeta.id && !indicator.indicatorMeta.formula
                    && _this.model.reportMeta.regionId && indicatorLevel === (indicator.indicatorMeta.editableLevel || 60)) {
                    rowGroup.hasEditableCells = true;
                }
                if (!_this.branch.rootRowsGroupHash[indicator.meta.indicatorTypeId]) {
                    _this.branch.rootRowsGroupHash[indicator.meta.indicatorTypeId] = [];
                }
                _this.branch.rootRowsGroupHash[indicator.meta.indicatorTypeId][indicator.meta.rowNo] = rowGroup;
            });
            this_1.branch.headerRows.push(row);
        };
        var this_1 = this;
        for (var lvl = 0; lvl < depth; lvl++) {
            _loop_1(lvl);
        }
        this.pstReportStructureService.mergeHeaderCells(this.branch);
        // level 10: субъектовый
        for (var _i = 0, _a = this.branch.rootRowsGroup; _i < _a.length; _i++) {
            var rowGroup = _a[_i];
            rowGroup.level = 10;
            rowGroup.indicatorTypeMeta = this.getLevelSlice(rowGroup, 10);
        }
    };
    PstBranchTabColEditComponent.prototype.getSuggestedTableWidth = function () {
        if (!this.branch.headerRows || !this.branch.headerRows.length) {
            return undefined;
        }
        var lastRow = this.branch.headerRows[this.branch.headerRows.length - 1];
        var width = 0;
        for (var iCol = 0; iCol < lastRow.length; iCol++) {
            if (lastRow[iCol].visible()) {
                width += (lastRow[iCol].meta.displayWidth || 60);
            }
        }
        return width + 200 + "px";
    };
    PstBranchTabColEditComponent.prototype.searchAgent = function (rowGroup) {
        var _this = this;
        this.navigationService.searchAgent(AgentSearchModalComponent).subscribe(function (agentId) {
            if (agentId) {
                var indicatorLevel = _this.branch.meta.indicatorLevel;
                if (!_this.model.reportMeta.regionId && _this.branch.meta.subjectIndicatorLevel) {
                    indicatorLevel = _this.branch.meta.subjectIndicatorLevel;
                }
                _this.addAgentRow(agentId, rowGroup, indicatorLevel === 60 ? _this.branch.defaultSubtypeId : null);
            }
        });
    };
    PstBranchTabColEditComponent.prototype.addAgentRow = function (agentId, rowGroup, indicatorSubtypeId) {
        var _this = this;
        this.lookupSourceService.getLookupObj('addr-region-group').subscribe(function (regionGroupLookup) {
            _this.lookupSourceService.getLookupObj('pst-indicator-subtype').subscribe(function (indicatorSubtypeLookup) {
                _this.lookupSourceService.getLookupObj('addr-region').subscribe(function (regionLookup) {
                    _this.cacheService.getAgent(agentId).subscribe(function (agent) {
                        var regionId = _this.model.reportMeta.regionId;
                        var regionGroupId = regionLookup["Obj" + regionId].regionGroupId;
                        var kfhType = agent.legalFormId === 31 || agent.legalFormId === 13 || agent.legalFormId === 45 || agent.legalFormId === 0
                            ? 2
                            : 1;
                        var indicatorLevel = _this.branch.meta.indicatorLevel;
                        if (!_this.model.reportMeta.regionId && _this.branch.meta.subjectIndicatorLevel) {
                            indicatorLevel = _this.branch.meta.subjectIndicatorLevel;
                        }
                        _this.addRowWithGroups(rowGroup, regionGroupLookup, regionLookup, regionGroupId, regionId, kfhType, agentId, agent.shortCaption, indicatorSubtypeId, indicatorSubtypeLookup[indicatorSubtypeId], indicatorLevel);
                    });
                });
            });
        });
    };
    PstBranchTabColEditComponent.prototype.addRowWithGroups = function (rowGroup, regionGroupLookup, regionLookup, regionGroupId, regionId, kfhType, agentId, agentCaption, subtypeId, subtypeCaption, level) {
        var _this = this;
        if (!regionGroupId) {
            return;
        }
        // level 10: regionGroupId
        if (!rowGroup.hash[regionGroupId]) {
            rowGroup.hash[regionGroupId] = {
                caption: regionGroupLookup["Obj" + regionGroupId].caption,
                hash: {},
                parent: rowGroup,
                children: [],
                level: 20,
                regionId: undefined,
                regionGroupId: regionGroupId,
                kfhType: undefined,
                agentId: undefined,
                subtypeId: undefined,
                indicatorTypeMeta: this.getLevelSlice(rowGroup, 20),
                visible: function () { return !_this.model.reportMeta.regionId; },
            };
            rowGroup.children.push(rowGroup.hash[regionGroupId]);
        }
        var lvl10node = rowGroup.hash[regionGroupId];
        if (!regionId || level < 30) {
            return;
        }
        // level 20: regionId
        if (!lvl10node.hash[regionId]) {
            lvl10node.hash[regionId] = {
                caption: regionLookup["Obj" + regionId].caption,
                level: 30,
                hash: {},
                parent: lvl10node,
                children: [],
                regionId: regionId,
                regionGroupId: regionGroupId,
                kfhType: undefined,
                agentId: undefined,
                subtypeId: undefined,
                indicatorTypeMeta: this.getLevelSlice(rowGroup, 30),
                visible: function () { return true; },
            };
            lvl10node.children.push(lvl10node.hash[regionId]);
        }
        var lvl20node = lvl10node.hash[regionId];
        if (!kfhType || level < 40) {
            return;
        }
        // level 30: kfhType КФХ/предприятия
        if (!lvl20node.hash[kfhType]) {
            lvl20node.hash[kfhType] = {
                caption: kfhType === 2 ? 'Крестьянско-фермерские хозяйства' : 'Предприятия',
                hash: {},
                parent: lvl20node,
                children: [],
                level: 40,
                regionId: regionId,
                regionGroupId: regionGroupId,
                kfhType: kfhType,
                agentId: undefined,
                subtypeId: undefined,
                indicatorTypeMeta: this.getLevelSlice(rowGroup, 40),
                visible: function () { return true; },
            };
            lvl20node.children.push(lvl20node.hash[kfhType]);
        }
        var lvl30node = lvl20node.hash[kfhType];
        if (!agentId || level < 50) {
            return;
        }
        // level 40: agentId
        if (!lvl30node.hash[agentId]) {
            var lvl40children_1 = [];
            lvl30node.hash[agentId] = {
                agentCaption: agentCaption,
                caption: agentCaption,
                level: 50,
                regionId: regionId,
                regionGroupId: regionGroupId,
                kfhType: kfhType,
                agentId: agentId,
                subtypeId: undefined,
                hash: {},
                parent: lvl30node,
                children: lvl40children_1,
                indicatorTypeMeta: this.getLevelSlice(rowGroup, 50),
                visible: function () { return lvl40children_1.length !== 1; },
            };
            lvl30node.children.push(lvl30node.hash[agentId]);
        }
        var lvl40node = lvl30node.hash[agentId];
        if (!subtypeId || level < 60) {
            return;
        }
        // level 50: subtypeId
        if (!lvl40node.hash[subtypeId]) {
            lvl40node.hash[subtypeId] = {
                level: 60,
                regionId: regionId,
                regionGroupId: regionGroupId,
                kfhType: kfhType,
                agentId: agentId,
                subtypeId: subtypeId,
                parent: lvl40node,
                children: [],
                indicatorTypeMeta: this.getLevelSlice(rowGroup, 60),
                displayCaption: function () { return "" + agentCaption + (_this.branch.hasSubtypes ? ', ' + subtypeCaption : ''); },
                visible: function () { return !_this.branch.meta.params['filterKfhType'] || _this.branch.meta.params['filterKfhType'] == kfhType; },
            };
            lvl40node.children.push(lvl40node.hash[subtypeId]);
        }
    };
    PstBranchTabColEditComponent.prototype.getLevelSlice = function (rowGroup, lvl) {
        return rowGroup.indicators.map(function (indicator) {
            if (indicator) {
                return indicator.indicatorMeta;
            }
            return undefined;
        });
    };
    PstBranchTabColEditComponent.prototype.startUpdatingRowsStructure = function () {
        var _this = this;
        if (!this.model || !this.branch) {
            return;
        }
        combineLatest([
            this.securityService.getUserInfo(),
            this.lookupSourceService.getLookupObj('addr-region-group'),
            this.lookupSourceService.getLookup('pst-indicator-subtype'),
            this.lookupSourceService.getLookupObj('pst-indicator-subtype'),
            this.lookupSourceService.getLookupObj('addr-region'),
        ]).subscribe(function (_a) {
            var userInfo = _a[0], regionGroupLookup = _a[1], indicatorSubtypes = _a[2], indicatorSubtypeLookup = _a[3], regionLookup = _a[4];
            _this.updateDefaultBranchSubtype(indicatorSubtypes);
            var hasUserAgent = false;
            var _loop_2 = function (indicatorKey) {
                if (!_this.model.values.hasOwnProperty(indicatorKey)) {
                    return "continue";
                }
                var value = _this.model.values[indicatorKey];
                var indicatorMeta = _this.model.indicatorTypesMap[value.indicatorTypeId];
                if (!_this.model.showHiddenCells && indicatorMeta && indicatorMeta.isProtected) {
                    return "continue";
                }
                // поддержку срезов по subtypeId и kfhType мы не пока добавляли в таблице по графам, поэтому игнорим подобные показатели
                if ((value.level <= 50 && value.subtypeId) || (value.level <= 30 && value.kfhType)) {
                    return "continue";
                }
                var rowGroups = _this.branch.rootRowsGroupHash[value.indicatorTypeId] || [];
                if (rowGroups && rowGroups.length && value.agentId === userInfo.agentId) {
                    hasUserAgent = true;
                }
                (value.agentId ? _this.cacheService.getAgent(value.agentId) : of(undefined)).subscribe(function (agent) {
                    rowGroups.forEach(function (rowGroup) {
                        _this.addRowWithGroups(rowGroup, regionGroupLookup, regionLookup, value.regionGroupId, value.regionId, value.kfhType, agent ? agent.id : undefined, agent ? agent.shortCaption : undefined, value.subtypeId, value.subtypeId ? indicatorSubtypeLookup[value.subtypeId] : undefined, value.level);
                    });
                    _this.sortRowGroupsTimeout(_this.branch.rootRowsGroup);
                });
            };
            for (var indicatorKey in _this.model.values) {
                _loop_2(indicatorKey);
            }
            if (userInfo.restrictPstAgentId && !hasUserAgent) {
                _this.branch.rootRowsGroup.forEach(function (rowGroup) {
                    _this.addAgentRow(userInfo.agentId, rowGroup, _this.branch.defaultSubtypeId);
                });
            }
        });
        this.securityService.getUserInfo().subscribe(function (userInfo) {
            _this.lookupSourceService.getLookupObj('addr-region-group').subscribe(function (regionGroupLookup) {
                _this.lookupSourceService.getLookup('pst-indicator-subtype').subscribe(function (indicatorSubtypes) {
                    _this.lookupSourceService.getLookupObj('pst-indicator-subtype').subscribe(function (indicatorSubtypeLookup) {
                        _this.lookupSourceService.getLookupObj('addr-region').subscribe(function (regionLookup) {
                        });
                    });
                });
            });
        });
    };
    PstBranchTabColEditComponent.prototype.cellClick = function (indicatorLevel, colIndex, rowGroup) {
        if (this.getFocused(indicatorLevel, colIndex, rowGroup)) {
            this.setEditingCell(indicatorLevel, colIndex, indicatorLevel.indicatorTypeMeta[colIndex], rowGroup, '');
        }
        else {
            this.setFocusedCell(indicatorLevel, colIndex, rowGroup);
        }
        //
        // console.log('reportIndicator:', reportIndicator);
        // console.log('indicatorTypeMeta:', indicatorLevel.indicatorTypeMeta[colIndex]);
        // console.log('indicatorLevel:', indicatorLevel);
    };
    PstBranchTabColEditComponent.prototype.setFocusedCell = function (indicatorLevel, colIndex, rowGroup) {
        this.model.__cl_focusedBranch = this.branch;
        this.model.__cl_focusedRowGroup = rowGroup;
        this.model.__cl_focusedIndicatorLevel = indicatorLevel;
        this.model.__cl_focusedIndicatorColIndex = colIndex;
        this.model.__cl_editingBranch = undefined;
        this.model.__cl_storing_error = undefined;
    };
    PstBranchTabColEditComponent.prototype.setEditingCell = function (indicatorLevel, colIndex, indicatorTypeMeta, rowGroup, proposedValue) {
        if (!indicatorTypeMeta || indicatorTypeMeta.formula || indicatorLevel.level !== (indicatorTypeMeta.editableLevel || 60)) {
            return;
        }
        this.setFocusedCell(indicatorLevel, colIndex, rowGroup);
        var reportIndicator = rowGroup.indicators[colIndex];
        this.setProposedCellValueStr(indicatorLevel, reportIndicator, indicatorTypeMeta, proposedValue);
        this.model.__cl_editingBranch = this.branch;
        this.model.__cl_editingRowGroup = rowGroup;
        this.model.__cl_editingIndicatorLevel = indicatorLevel;
        this.model.__cl_editingIndicatorColIndex = colIndex;
    };
    PstBranchTabColEditComponent.prototype.tableKeyDown = function ($event) {
        if (this.model.__cl_focusedBranch === this.branch) {
            var rowGroup = this.model.__cl_focusedRowGroup;
            var indicatorLevel = this.model.__cl_focusedIndicatorLevel;
            var colIndex = this.model.__cl_focusedIndicatorColIndex;
            var indicatorTypeMeta = indicatorLevel.indicatorTypeMeta[colIndex];
            var reportIndicator = rowGroup.indicators[colIndex];
            if (!this.getEditing(indicatorLevel, colIndex, rowGroup)) {
                if ($event.key >= '0' && $event.key <= '9') {
                    this.setEditingCell(indicatorLevel, colIndex, indicatorTypeMeta, rowGroup, $event.key);
                }
                if ($event.key === 'F2') {
                    this.setEditingCell(indicatorLevel, colIndex, indicatorTypeMeta, rowGroup, '');
                }
                if ($event.key === 'ArrowLeft') {
                    this.setFocusedCell(indicatorLevel, colIndex - 1 >= 0 ? colIndex - 1 : 0, rowGroup);
                }
                if ($event.key === 'ArrowRight') {
                    this.setFocusedCell(indicatorLevel, colIndex + 1 < rowGroup.indicators.length
                        ? colIndex + 1
                        : rowGroup.indicators.length - 1, rowGroup);
                }
                if ($event.key === 'ArrowDown') {
                    $event.preventDefault();
                    this.setFocusedCell(this.findNextLevel(indicatorLevel, rowGroup), colIndex, rowGroup);
                }
                if ($event.key === 'ArrowUp') {
                    $event.preventDefault();
                    this.setFocusedCell(this.findPreviousLevel(indicatorLevel, rowGroup), colIndex, rowGroup);
                }
                if ($event.key === 'F3') {
                    var code = this.pstReportStructureService.getIndicatorValueCode(this.branch, indicatorLevel, reportIndicator, indicatorTypeMeta);
                    var rec = this.model.values[code];
                    if (rec) {
                        $event.preventDefault();
                        this.appNavigationService.showIndicatorDependencies(IndicatorDependencyModalComponent, rec, this.model.reportMeta.id)
                            .subscribe(noop);
                    }
                }
                if ($event.key === 'Delete' && !indicatorTypeMeta.formula) {
                    this.pstReportStructureService.storeIndicatorValue(this.model, reportIndicator, indicatorTypeMeta, indicatorLevel, undefined);
                }
            }
        }
    };
    PstBranchTabColEditComponent.prototype.getFocused = function (indicatorLevel, colIndex, rowGroup) {
        return this.model.__cl_focusedBranch === this.branch &&
            this.model.__cl_focusedRowGroup === rowGroup &&
            this.model.__cl_focusedIndicatorLevel === indicatorLevel &&
            this.model.__cl_focusedIndicatorColIndex === colIndex;
    };
    PstBranchTabColEditComponent.prototype.getEditing = function (indicatorLevel, colIndex, rowGroup) {
        return this.model.__cl_editingBranch === this.branch &&
            this.model.__cl_editingRowGroup === rowGroup &&
            this.model.__cl_editingIndicatorLevel === indicatorLevel &&
            this.model.__cl_editingIndicatorColIndex === colIndex;
    };
    PstBranchTabColEditComponent.prototype.getReadOnly = function (indicatorLevel, colIndex, rowGroup) {
        var branchIndicatorLevel = this.branch.meta.indicatorLevel;
        if (!this.model.reportMeta.regionId && this.branch.meta.subjectIndicatorLevel) {
            branchIndicatorLevel = this.branch.meta.subjectIndicatorLevel;
        }
        return indicatorLevel.indicatorTypeMeta[colIndex] && (indicatorLevel.indicatorTypeMeta[colIndex].formula
            || (indicatorLevel.indicatorTypeMeta[colIndex].editableLevel || 60) !== branchIndicatorLevel);
    };
    PstBranchTabColEditComponent.prototype.setProposedCellValueStr = function (indicatorLevel, reportIndicator, indicatorTypeMeta, strValue) {
        if (!reportIndicator || !indicatorTypeMeta || !indicatorLevel) {
            return;
        }
        var code = this.pstReportStructureService.getIndicatorValueCode(this.branch, indicatorLevel, reportIndicator, indicatorTypeMeta);
        if (!this.model.values[code]) {
            this.model.values[code] = {
                date: reportIndicator.indicatorDate,
                indicatorTypeId: indicatorTypeMeta.id,
                regionGroupId: indicatorLevel.regionGroupId,
                regionId: indicatorLevel.regionId,
                kfhType: indicatorLevel.kfhType,
                agentId: indicatorLevel.agentId,
            };
        }
        if (strValue) {
            var parsedVal = NumericHelper.roundDecimal(parseFloat(strValue), indicatorTypeMeta.precision);
            this.model.values[code].proposedValue = parsedVal;
        }
        else {
            this.model.values[code].proposedValue = undefined;
        }
    };
    PstBranchTabColEditComponent.prototype.updateDefaultBranchSubtype = function (indicatorSubtypes) {
        var _this = this;
        var lvlGroupCaption = this.branch.indicators[0].indicatorMeta.lvlGroupCaption;
        this.branch.hasSubtypes = lvlGroupCaption !== 'Иное';
        indicatorSubtypes.forEach(function (subtype) {
            if (subtype.groupCaption === lvlGroupCaption && subtype.isDefault) {
                _this.branch.defaultSubtypeId = subtype.id;
            }
        });
    };
    PstBranchTabColEditComponent.prototype.getAvailableSubtypes$ = function () {
        var _this = this;
        return this.lookupSourceService.getLookup('pst-indicator-subtype').pipe(map(function (indicatorSubtypes) {
            var lvlGroupCaption = _this.branch.indicators[0].indicatorMeta.lvlGroupCaption;
            return indicatorSubtypes.filter(function (subtype) { return subtype.groupCaption === lvlGroupCaption; });
        }));
    };
    PstBranchTabColEditComponent.prototype.onTableCtnrScroll = function ($event) {
        this.scrollLeft = this.tableCtnr.nativeElement.scrollLeft;
    };
    PstBranchTabColEditComponent.prototype.editReportIndicator = function (reportIndicator) {
        var _this = this;
        this.appNavigationService.showReportIndicatorEdit(ReportIndicatorMetaEditModalComponent, reportIndicator.meta)
            .subscribe(function (updated) {
            Object.assign(reportIndicator.meta, updated);
            _this.alertService.success('Описание графы успешно сохранено. ' +
                'Обновите отчет, чтобы посмотреть измененную форму.');
        });
    };
    PstBranchTabColEditComponent.prototype.editIndicatorType = function (reportIndicator) {
        var _this = this;
        this.appNavigationService.showIndicatorTypeEdit(PstIndicatorTypeMetaEditModalComponent, reportIndicator.indicatorMeta)
            .subscribe(function (updated) {
            Object.assign(reportIndicator.indicatorMeta, updated);
            _this.alertService.success('Вид показателя успешно сохранен. ' +
                'Обновите отчет, чтобы посмотреть измененную форму.');
        });
    };
    PstBranchTabColEditComponent.prototype.addReportIndicator = function () {
        var _this = this;
        this.appNavigationService.showReportIndicatorEdit(ReportIndicatorMetaEditModalComponent, {
            createNew: true,
            reportTypeId: this.branch.meta.reportTypeId,
            branchOrderNo: this.branch.meta.orderNo,
            colNo: 1,
            rowNo: 0,
        })
            .subscribe(function () {
            _this.alertService.success('Описание графы успешно сохранено. ' +
                'Обновите отчет, чтобы посмотреть измененную форму.');
        });
    };
    PstBranchTabColEditComponent.prototype.sortRowGroupsTimeout = function (parents) {
        var _this = this;
        if (this.hSortTimeout) {
            clearTimeout(this.hSortTimeout);
            this.hSortTimeout = undefined;
        }
        this.hSortTimeout = setTimeout(function () { return _this.sortRowGroups(parents); }, 100);
    };
    PstBranchTabColEditComponent.prototype.sortRowGroups = function (parents) {
        var _this = this;
        if (!parents || !parents.length) {
            return;
        }
        parents.sort(function (a, b) { return (a.caption || '').localeCompare(b.caption || ''); });
        parents.forEach(function (parent) { return _this.sortRowGroups(parent.children); });
    };
    PstBranchTabColEditComponent.prototype.findNextLevel = function (indicatorLevel, rowGroup) {
        var ixLevel = indicatorLevel.parent && indicatorLevel.parent.children
            ? indicatorLevel.parent.children.findIndex(function (el) { return el === indicatorLevel; })
            : -1;
        if (ixLevel < 0) {
            return indicatorLevel;
        }
        var nextLevel;
        if (ixLevel + 1 < indicatorLevel.parent.children.length) {
            nextLevel = indicatorLevel.parent.children[ixLevel + 1];
            while (nextLevel != null && nextLevel.children && nextLevel.children.length) {
                nextLevel = nextLevel.children[0];
            }
        }
        else {
            nextLevel = indicatorLevel.parent ? indicatorLevel.parent : indicatorLevel;
        }
        if (nextLevel === indicatorLevel) {
            return indicatorLevel;
        }
        else if (!nextLevel.visible()) {
            nextLevel = this.findNextLevel(nextLevel, rowGroup);
            return nextLevel.visible() ? nextLevel : indicatorLevel;
        }
        else {
            return nextLevel;
        }
    };
    PstBranchTabColEditComponent.prototype.findPreviousLevel = function (indicatorLevel, rowGroup) {
        var prevLevel;
        if (indicatorLevel.children && indicatorLevel.children.length) {
            prevLevel = indicatorLevel.children[indicatorLevel.children.length - 1];
        }
        else {
            var ixLevel = indicatorLevel.parent && indicatorLevel.parent.children
                ? indicatorLevel.parent.children.findIndex(function (el) { return el === indicatorLevel; })
                : -1;
            if (ixLevel > 0) {
                prevLevel = indicatorLevel.parent.children[ixLevel - 1];
            }
            else {
                prevLevel = indicatorLevel.parent;
                while (prevLevel) {
                    var ixPrevLevel = prevLevel.parent && prevLevel.parent.children
                        ? prevLevel.parent.children.findIndex(function (el) { return el === prevLevel; })
                        : -1;
                    if (ixPrevLevel > 0) {
                        prevLevel = prevLevel.parent.children[ixPrevLevel - 1];
                        break;
                    }
                    else {
                        prevLevel = prevLevel.parent;
                    }
                }
                prevLevel = prevLevel ? prevLevel : indicatorLevel;
            }
        }
        if (prevLevel === indicatorLevel) {
            return indicatorLevel;
        }
        else if (!prevLevel.visible()) {
            prevLevel = this.findPreviousLevel(prevLevel, rowGroup);
            return prevLevel.visible() ? prevLevel : indicatorLevel;
        }
        else {
            return prevLevel;
        }
    };
    return PstBranchTabColEditComponent;
}());
export { PstBranchTabColEditComponent };
