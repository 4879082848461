<div class="itech-common-wrap" id="interceptNoAccess">
  <div>
    <div class="itech-doc-container" *ngIf="contextFormGroup">
      <div class="itech-doc-image">
        <clr-icon shape="bank"></clr-icon>
      </div>
      <div class="itech-doc-content">
        <app-edit-institution-common-form [contextFormGroup]="contextFormGroup"></app-edit-institution-common-form>
      </div>
    </div>
  </div>
  <div class="itech-form-actions">
    <div>
      <button class="btn btn-secondary" (click)="refreshFormData()">
        <clr-icon shape="refresh"></clr-icon>&nbsp;Обновить
      </button>
      <app-reports-button [reportKind]="'institution'" [objId1]="id" [dropdownPosition]="'top-left'">
      </app-reports-button>
      <button class="btn btn-primary-outline" (click)="navigationService.navigateJournal(undefined, 1, id, undefined)">
        <clr-icon shape="view-list"></clr-icon>&nbsp;Журнал
      </button>
      <button class="btn btn-warning-outline" (click)="deleteInstitution()">
        <clr-icon shape="trash"></clr-icon> Удалить
      </button>
    </div>
    <div>
      <button [clrLoading]="storing" class="btn btn-primary itech-form-save"
              [disabled]="!contextFormGroup || !contextFormGroup.dirty || storing" (click)="store()">
        <clr-icon shape="check"></clr-icon>&nbsp;Сохранить
      </button>
      <button class="btn btn-warning itech-form-cancel"
              [disabled]="!contextFormGroup || !contextFormGroup.dirty" (click)="cancelEdit()">
        <clr-icon shape="undo"></clr-icon>&nbsp;Отменить
      </button>
    </div>
  </div>
</div>
