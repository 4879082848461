import { Component, Input } from '@angular/core';
import { LookupSourceService } from '../../logic/services/lookup-source.service';
import { JobRunnerUiService } from '../infrastructure/job-runner-ui.service';
import { AlertService } from '../infrastructure/alert.service';
import { AppModalPreviewFileComponent } from './app-modal-preview-file.component';
import { AppNavigationService } from '../../logic/services/app-navigation.service';

@Component({
  selector: 'app-products-reports-button',
  template: `
    <clr-dropdown>
      <button class="btn btn-secondary" clrDropdownToggle
              [disabled]="model.selectedRows && model.selectedRows.length ? undefined : true">
        Распечатать
      </button>
      <clr-dropdown-menu *clrIfOpen [clrPosition]="'right-bottom'">
        <label class="dropdown-header">Выберите печатную форму</label>
        <button *ngFor="let report of lookupSourceService.getLookup('report-product') | async"
            type="button" clrDropdownItem [title]="report.caption"
          (click)="concatDocumentsReports(report.id)">
          {{report.caption}}
        </button>
      </clr-dropdown-menu>
    </clr-dropdown>`
})
export class AppProductsReportsButtonComponent {
  @Input() model;

  constructor(public jobRunnerUiService: JobRunnerUiService,
              public lookupSourceService: LookupSourceService,
              private navigationService: AppNavigationService,
              private alertService: AlertService) {
  }

  concatDocumentsReports(reportId: number) {
    this.jobRunnerUiService.runOperation(19, /*Печать одной отчетной формы для нескольких документов*/
      {
        type: 'concatDocumentsReports',
        reportId: reportId,
        objIds: this.model.selectedRowsIds,
      }, undefined, undefined, true)
      .subscribe({
        next: (data) => {
          this.navigationService.previewFile(AppModalPreviewFileComponent, { fileUri: data });
        }
      });
  }
}
