import { MetadataService } from './metadata.service';
import { DataCachingService } from './data-caching.service';
import { BehaviorSubject, ReplaySubject, combineLatest } from 'rxjs';
import { InstitutionDataService } from './institution-data.service';
import { StringHelper } from '../../helpers/string-helper';
import { map } from 'rxjs/internal/operators';
import { isArray } from 'rxjs/internal/util/isArray';
import { AddressPersonFioCacheService } from './address-person-fio-cache.service';
import { AppConstants } from '../../app-constants';
import { DateHelper } from '../../helpers/date-helper';
var LookupSourceService = /** @class */ (function () {
    function LookupSourceService(metadataService, dataCachingService, institutionDataService, personFioCacheService) {
        this.metadataService = metadataService;
        this.dataCachingService = dataCachingService;
        this.institutionDataService = institutionDataService;
        this.personFioCacheService = personFioCacheService;
    }
    LookupSourceService.getMaxCacheSize = function (lookupName) {
        if (lookupName.startsWith('product-subtype')) {
            return 20000;
        }
        return 200;
    };
    LookupSourceService.filterLookup = function (allValues, filterValueInArrayControlName1, filterValueInArrayValue1, filterValueInArrayControlName2, filterValueInArrayValue2, filterArrayIncludeControlName, filterArrayIncludeValue) {
        if (filterValueInArrayValue1) {
            allValues = allValues.filter(function (el) {
                // если у элемента нет никаких настроек - до их появления считаем, что он проходит фильтрацию
                if (!el[filterValueInArrayControlName1] || !el[filterValueInArrayControlName1].length) {
                    return true;
                }
                else {
                    return el[filterValueInArrayControlName1].includes(filterValueInArrayValue1);
                }
            });
        }
        if (filterValueInArrayValue2) {
            allValues = allValues.filter(function (el) {
                // если у элемента нет никаких настроек - до их появления считаем, что он проходит фильтрацию
                if (!el[filterValueInArrayControlName2] || !el[filterValueInArrayControlName2].length) {
                    return true;
                }
                else {
                    return el[filterValueInArrayControlName2].includes(filterValueInArrayValue2);
                }
            });
        }
        if (filterArrayIncludeValue && filterArrayIncludeValue.length) {
            allValues = allValues.filter(function (el) {
                return el[filterArrayIncludeControlName] && filterArrayIncludeValue.includes(el[filterArrayIncludeControlName]);
            });
        }
        return allValues;
    };
    LookupSourceService.prototype.invalidateLookup = function (lookupName, isStartWith) {
        if (isStartWith === void 0) { isStartWith = false; }
        if (!lookupName) {
            return;
        }
        lookupName = lookupName.toLowerCase();
        if (isStartWith) {
            this.dataCachingService.removeCachedDataByStartKey('LookupSourceService', lookupName);
            this.dataCachingService.removeCachedDataByStartKey('LookupSourceServiceObj', lookupName);
        }
        else {
            this.dataCachingService.removeCachedData('LookupSourceService', lookupName);
            this.dataCachingService.removeCachedData('LookupSourceServiceObj', lookupName);
        }
    };
    LookupSourceService.prototype.getLookup = function (lookupName, clone, sort, showHistory) {
        if (!lookupName) {
            return new BehaviorSubject([]);
        }
        lookupName = lookupName.toLowerCase();
        var existing = this.dataCachingService.getCachedData('LookupSourceService', lookupName);
        var existingActual = this.dataCachingService.getCachedData('LookupSourceServiceActual', lookupName);
        if (!existing || !existingActual) {
            existing = new ReplaySubject();
            existingActual = new ReplaySubject();
            this.dataCachingService.addToCache('LookupSourceService', lookupName, existing, LookupSourceService.getMaxCacheSize(lookupName));
            this.dataCachingService.addToCache('LookupSourceServiceActual', lookupName, existingActual, LookupSourceService.getMaxCacheSize(lookupName));
            existing.subscribe(function (val) {
                var val2 = [];
                if (isArray(val)) {
                    val.forEach(function (el) {
                        if (el && !el.dateDeleted) {
                            val2.push(el);
                        }
                    });
                }
                else {
                    val2 = val;
                }
                existingActual.next(val2);
            });
            this.preloadData(lookupName, existing);
        }
        var lookupSliceToUse = showHistory ? existing : existingActual;
        if (clone || sort) {
            return lookupSliceToUse.pipe(map(function (dict) {
                var newDict = [];
                dict.forEach(function (el) {
                    var newEl = {};
                    Object.assign(newEl, el);
                    newDict.push(newEl);
                });
                if (sort) {
                    newDict.sort(function (a, b) { return a.caption.localeCompare(b.caption); });
                }
                return newDict;
            }));
        }
        else {
            return lookupSliceToUse;
        }
    };
    LookupSourceService.prototype.getLookupObj = function (lookupName) {
        if (lookupName === 'addr-street') {
            throw new Error('getLookupObj для addr-street не работает');
        }
        lookupName = lookupName.toLowerCase();
        var existing = this.dataCachingService.getCachedData('LookupSourceServiceObj', lookupName);
        if (existing) {
            return existing;
        }
        else {
            existing = new ReplaySubject();
            this.getLookup(lookupName, false, false, true).subscribe(function (arr) {
                if (!isArray(arr)) {
                    existing.next(arr);
                }
                else {
                    var val_1 = {};
                    if (arr) {
                        arr.forEach(function (item) {
                            val_1[item.id] = item.caption + (item.dateDeleted ? ' (удалённая запись)' : '');
                            val_1['Obj' + item.id.toString()] = item;
                        });
                    }
                    existing.next(val_1);
                }
            });
            this.dataCachingService.addToCache('LookupSourceServiceObj', lookupName, existing, LookupSourceService.getMaxCacheSize(lookupName));
            return existing;
        }
    };
    LookupSourceService.prototype.preloadTwoLevelLookup = function (lookupName, firstLevelLookupName, branchFieldName, idFieldName, existing, parentId) {
        var _this = this;
        if (parentId === void 0) { parentId = null; }
        if (!parentId) {
            this.preloadThreeLevelLookup(lookupName, firstLevelLookupName, branchFieldName, idFieldName, existing);
            return;
        }
        // если мы ищем с parentId,
        // то сначала в кэше проверим наличие данных по firstLevelLookupName и при наличии внутри найдем по parentId, иначе грузим данные
        // либо также грузим, если внутри данных по firstLevelLookupName отсутствуют для parentId
        var existingFirstLevelAll = this.dataCachingService.getCachedData('LookupSourceService', firstLevelLookupName);
        if (existingFirstLevelAll) {
            existingFirstLevelAll
                .subscribe(function (values) {
                if (values[parentId]) {
                    existing.next(values[parentId]);
                }
                else {
                    _this.preloadThreeLevelLookup(lookupName, firstLevelLookupName, branchFieldName, idFieldName, existing);
                }
            });
        }
        else {
            this.preloadThreeLevelLookup(lookupName, firstLevelLookupName, branchFieldName, idFieldName, existing);
        }
    };
    LookupSourceService.prototype.preloadThreeLevelLookup = function (lookupName, firstLevelLookupName, branchFieldName, idFieldName, existing) {
        var _this = this;
        var lookupNameLower = lookupName ? lookupName.toLowerCase() : lookupName;
        var firstLevelLookupNameLower = firstLevelLookupName ? firstLevelLookupName.toLowerCase() : '';
        this.metadataService.getMetadata(firstLevelLookupNameLower).subscribe(function (val) {
            var entityType = lookupNameLower.substr(firstLevelLookupNameLower.length)
                ? parseInt(lookupNameLower.substr(firstLevelLookupNameLower.length), 10)
                : undefined;
            var allLookupObj = {};
            val.forEach(function (item) {
                if (!allLookupObj[item[branchFieldName]]) {
                    allLookupObj[item[branchFieldName]] = [];
                }
                var cloned = { id: item[idFieldName] };
                Object.assign(cloned, item);
                allLookupObj[item[branchFieldName]].push(cloned);
            });
            var updatedExisting = false;
            for (var key in allLookupObj) {
                if (parseInt(key, 10) === entityType) {
                    existing.next(allLookupObj[key]);
                    updatedExisting = true;
                }
                else {
                    var byEntityTypeSubj = new ReplaySubject();
                    _this.dataCachingService.addToCache('LookupSourceService', firstLevelLookupName + key, byEntityTypeSubj, LookupSourceService.getMaxCacheSize(firstLevelLookupName));
                    byEntityTypeSubj.next(allLookupObj[key]);
                }
            }
            if (!updatedExisting && entityType && !allLookupObj[entityType]) {
                existing.next([]);
            }
            if (lookupNameLower === firstLevelLookupName) {
                existing.next(allLookupObj);
            }
            else {
                var allSubj = new ReplaySubject();
                _this.dataCachingService.addToCache('LookupSourceService', firstLevelLookupName, allSubj, LookupSourceService.getMaxCacheSize(firstLevelLookupName));
                allSubj.next(allLookupObj);
            }
        });
    };
    LookupSourceService.prototype.preloadData = function (lookupNameLower, existing) {
        if (lookupNameLower.startsWith('animal-breed')) {
            var matches = lookupNameLower.match(/animal-breed(\d+)/);
            this.preloadTwoLevelLookup(lookupNameLower, 'animal-breed', 'animalTypeId', 'id', existing, matches ? +matches[1] : null);
        }
        else if (lookupNameLower.startsWith('animal-subtype')) {
            var matches = lookupNameLower.match(/animal-subtype(\d+)/);
            this.preloadTwoLevelLookup(lookupNameLower, 'animal-subtype', 'animalTypeId', 'id', existing, matches ? +matches[1] : null);
        }
        else if (lookupNameLower.startsWith('product-subtype') && !lookupNameLower.startsWith('product-subtype/stable')) {
            var matches = lookupNameLower.match(/product-subtype(\d+)/);
            this.preloadTwoLevelLookup(lookupNameLower, 'product-subtype', 'productTypeId', 'id', existing, matches ? +matches[1] : null);
        }
        else if (lookupNameLower.startsWith('product-expertise-subtype')) {
            this.preloadTwoLevelLookup(lookupNameLower, 'product-expertise-subtype', 'productExpertiseTypeId', 'id', existing);
        }
        else if (lookupNameLower.startsWith('institution-indicator-subtype')) {
            this.preloadTwoLevelLookup(lookupNameLower, 'institution-indicator-subtype', 'indicatorTypeId', 'id', existing);
        }
        else if (lookupNameLower.startsWith('disease-serotype')) {
            this.preloadTwoLevelLookup(lookupNameLower, 'disease-serotype', 'diseaseTypeId', 'id', existing);
        }
        else if (lookupNameLower.startsWith('sys-query-view')) {
            this.preloadTwoLevelLookup(lookupNameLower, 'sys-query-view', 'entityType', 'id', existing);
        }
        else if (lookupNameLower.startsWith('addr-city')) {
            if (lookupNameLower === 'addr-city-ex') {
                this.metadataService.getCitiesLookupEx().subscribe(function (val) {
                    existing.next(val);
                });
            }
            else if (lookupNameLower === 'addr-city') {
                this.metadataService.getCitiesLookup().subscribe(function (val) {
                    existing.next(val);
                });
            }
            else {
                var matches = lookupNameLower.match(/addr-city(\d+)/);
                if (matches) {
                    this.metadataService.getCitiesInRegionLookup(+matches[1]).subscribe(function (val) {
                        existing.next(val);
                    });
                }
                else {
                    existing.next([]);
                }
            }
        }
        else if (lookupNameLower === 'institution') {
            this.institutionDataService.search().subscribe(function (val) {
                existing.next(val.map(function (item) {
                    return {
                        id: item.id,
                        caption: item.shortCaption || item.caption
                    };
                }));
            });
        }
        else if (lookupNameLower === 'head-institution') {
            this.institutionDataService.getHeadInstitutions().subscribe(function (val) {
                existing.next(val.map(function (item) {
                    return {
                        id: item.id,
                        caption: item.shortCaption || item.caption
                    };
                }));
            });
        }
        else if (lookupNameLower === 'slaughtery-institution') {
            this.institutionDataService.getSlaughteryInstitutions().subscribe(function (val) {
                existing.next(val.map(function (item) {
                    return {
                        id: item.id,
                        caption: item.shortCaption || item.caption
                    };
                }));
            });
        }
        else if (lookupNameLower === 'lab-institution') {
            this.institutionDataService.getLabInstitutions().subscribe(function (val) {
                existing.next(val.map(function (item) {
                    return {
                        id: item.id,
                        caption: item.shortCaption || item.caption
                    };
                }));
            });
        }
        else if (lookupNameLower === 'all-institution') {
            this.institutionDataService.getAllInstitutions().subscribe(function (val) {
                existing.next(val.map(function (item) {
                    return {
                        id: item.id,
                        caption: item.shortCaption || item.caption
                    };
                }));
            });
        }
        else if (lookupNameLower === 'addr-street-type') {
            this.metadataService.getMetadata(lookupNameLower).subscribe(function (val) { return existing.next(val); });
        }
        else if (lookupNameLower.startsWith('addr-street-')) {
            var matches = lookupNameLower.match(/addr-street-(\d+)-(\d+)/);
            if (matches) {
                this.metadataService.getStreetsLookup(+matches[1], +matches[2]).subscribe(function (val) {
                    existing.next(val);
                });
            }
            else {
                existing.next([]);
            }
        }
        else if (lookupNameLower === 'report-agg-dict') {
            existing.next([{ id: 'drug-type', caption: 'Виды препаратов' }, { id: 'disease-type', caption: 'Болезни животных' }]);
        }
        else if (lookupNameLower === 'gender') {
            existing.next([{ id: true, caption: 'Муж' }, { id: false, caption: 'Жен' }]);
        }
        else if (lookupNameLower === 'gender-animal') {
            existing.next([{ id: true, caption: 'Самец' }, { id: false, caption: 'Самка' }]);
        }
        else if (lookupNameLower === 'gender-animal-group') {
            existing.next([{ id: 1, caption: 'Самцы' }, { id: 2, caption: 'Самки' }, { id: 3, caption: 'Смешанная группа' }]);
        }
        else if (lookupNameLower === 'person-group-visibility') {
            existing.next([{ id: 1, caption: 'Глобально' }, { id: 2, caption: 'Учреждение' }, { id: 3, caption: 'Пользователь' }]);
        }
        else if (lookupNameLower === 'journal-change-type') {
            existing.next([{ id: 1, caption: 'Добавление' }, { id: 2, caption: 'Редактирование' }, { id: 3, caption: 'Удаление' },
                { id: 4, caption: 'Получение доступа' }, { id: 8, caption: 'Системное уведомление' }]);
        }
        else if (lookupNameLower === 'report-output-format') {
            existing.next([{ id: 'odt', caption: 'XDocReport Open Document (.odt)' },
                { id: 'odtPdf', caption: 'XDocReport PDF .odt' },
                { id: 'jasperPdf', caption: 'JasperReports PDF .jrxml' },
                { id: 'jasperXlsx', caption: 'JasperReports Xls .jrxml' },
                { id: 'jasperDocx', caption: 'JasperReports Docx .jrxml' }]);
        }
        else if (lookupNameLower === 'daily-schedule-kind') {
            existing.next([{ id: 1, caption: 'Ежедневно' }, { id: 2, caption: 'Еженедельно' }, { id: 3, caption: 'Ежемесячно' }]);
        }
        else if (lookupNameLower === 'security-function-scope') {
            existing.next([{ id: 1, caption: 'Все объекты' }, { id: 2, caption: 'Объекты, связанные с учреждением' },
                { id: 3, caption: 'Объекты пользователей учреждения' }, { id: 4, caption: 'Объекты пользователя' }]);
        }
        else if (lookupNameLower === 'week-days') {
            existing.next([{ id: 0, caption: 'Понедельник', shortCaption: 'Пн' },
                { id: 1, caption: 'Вторник', shortCaption: 'Вт' }, { id: 2, caption: 'Среда', shortCaption: 'Ср' },
                { id: 3, caption: 'Четверг', shortCaption: 'Чт' }, { id: 4, caption: 'Пятница', shortCaption: 'Пт' },
                { id: 5, caption: 'Суббота', shortCaption: 'Сб' }, { id: 6, caption: 'Воскресенье', shortCaption: 'Вс' }]);
        }
        else if (lookupNameLower === 'months') {
            existing.next([
                { id: 1, caption: 'январь' }, { id: 2, caption: 'февраль' }, { id: 3, caption: 'март' },
                { id: 4, caption: 'апрель' }, { id: 5, caption: 'май' }, { id: 6, caption: 'июнь' },
                { id: 7, caption: 'июль' }, { id: 8, caption: 'август' }, { id: 9, caption: 'сентябрь' },
                { id: 10, caption: 'октябрь' }, { id: 11, caption: 'ноябрь' }, { id: 12, caption: 'декабрь' }
            ]);
        }
        else if (lookupNameLower === 'security-role') {
            this.metadataService.getAllRoles().subscribe(function (val) {
                existing.next(val);
            });
        }
        else if (lookupNameLower === 'animal-types-4-vet-a') {
            existing.next([
                { id: 'Крупный рогатый скот', caption: 'Крупный рогатый скот' },
                { id: 'Овцы (козы)', caption: 'Овцы (козы)' },
                { id: 'Свиньи', caption: 'Свиньи' },
                { id: 'Лошади', caption: 'Лошади' },
                {
                    id: 'Птица по видам (куры,утки,гуси,индейки,цесарки,перепела,страусы)',
                    caption: 'Птица по видам (куры,утки,гуси,индейки,цесарки,перепела,страусы)'
                },
                {
                    id: 'Другие виды животных (олени, норки, песцы, соболя, иные плотоядные животные)',
                    caption: 'Другие виды животных (олени, норки, песцы, соболя, иные плотоядные животные)'
                },
            ]);
        }
        else if (lookupNameLower === 'drug-cost-type') {
            existing.next([
                { id: 1, caption: 'Рубль', shortCaption: 'руб.' },
                { id: 2, caption: 'Копейка', shortCaption: 'коп.' }
            ]);
        }
        else if (lookupNameLower === 'security-function') {
            this.metadataService.getAllFunctions().subscribe(function (val) {
                existing.next(val);
            });
        }
        else if (lookupNameLower.startsWith('report-')) {
            this.metadataService.getReports(lookupNameLower.substr(7)).subscribe(function (val) {
                existing.next(val);
            });
        }
        else if (lookupNameLower.startsWith('institution-branch')) {
            var institutionId = parseInt(lookupNameLower.substr(18), 10);
            this.institutionDataService.getInstitutionBranchesForLookup(institutionId).subscribe(function (val) {
                existing.next(val);
            });
        }
        else if (lookupNameLower.startsWith('institution-employee')) {
            var institutionId = parseInt(lookupNameLower.substr(20), 10);
            if (institutionId) {
                this.institutionDataService.getInstitutionEmployeesForLookup(institutionId).subscribe(function (val) {
                    existing.next(val.map(function (item) {
                        return {
                            id: item.id,
                            caption: StringHelper.getPersonTitle(item),
                            shortCaption: StringHelper.getPersonShortTitle(item),
                            uniqueUserId: item.uniqueUserId
                        };
                    }));
                });
            }
            else {
                this.metadataService.getMetadata(lookupNameLower).subscribe(function (val) { return existing.next(val); });
            }
        }
        else if (lookupNameLower.startsWith('disease-type/may-be-introduced-quarantine')) {
            var matches_1 = lookupNameLower.match(/disease-type\/may-be-introduced-quarantine(\d+)/);
            if (matches_1) {
                this.getLookup('disease-type/may-be-introduced-quarantine')
                    .subscribe(function (types) { return existing.next(types.find(function (type) { return type.id === +matches_1[1]; })); });
            }
            else {
                this.metadataService.getMetadata(lookupNameLower).subscribe(function (val) { return existing.next(val); });
            }
        }
        else if (lookupNameLower === 'user-manuals/all') {
            this.metadataService.getListUserManuals().subscribe(function (val) { return existing.next(val); });
        }
        else {
            this.metadataService.getMetadata(lookupNameLower).subscribe(function (val) { return existing.next(val); });
        }
    };
    LookupSourceService.prototype.getLookupCaption = function (value, lookupName, useShort, objId2) {
        var _this = this;
        if (lookupName.toLowerCase() === 'address') {
            if (value === -2) {
                return new BehaviorSubject(AppConstants.HIDDEN_FIELD_TEXT);
            }
            else {
                return this.personFioCacheService.getAddress(value).pipe(map(function (val) { return val.caption; }));
            }
        }
        else if (lookupName.toLowerCase() === 'addr-by-region-city-ids') {
            return this.getLookupObj('addr-city')
                .pipe(map(function (cityLookup) {
                var city = cityLookup['Obj' + value + '-' + objId2];
                if (!city) {
                    return '';
                }
                return city.comments + ', ' + city.caption;
            }));
        }
        else if (lookupName.toLowerCase() === 'quarantine') {
            var caption_1 = new BehaviorSubject('');
            this.personFioCacheService.getQuarantineLookup(value)
                .subscribe(function (quarantine) {
                if (quarantine.data.id === 0) {
                    caption_1.next(AddressPersonFioCacheService.OBJ_ACCESS_DENIED.caption);
                    return;
                }
                var sourceTerritory = { regionId: null, cityId: null };
                var periodCaption = '';
                if (quarantine.data && quarantine.data.territories && quarantine.data.territories.length) {
                    quarantine.data.territories.forEach(function (territory) {
                        if (territory.quarantineTerritoryTypeId === 2) {
                            sourceTerritory = territory;
                        }
                    });
                }
                if (quarantine.data && quarantine.data.stagePeriods && quarantine.data.stagePeriods.length) {
                    quarantine.data.stagePeriods.forEach(function (period) {
                        if (period.quarantineStageId === 3) {
                            periodCaption = period && period['dateFrom']
                                ? 'с ' + StringHelper.getRuDate(DateHelper.toDate(period['dateFrom'])) +
                                    (period['dateToInclusive']
                                        ? ' по ' + StringHelper.getRuDate(DateHelper.toDate(period['dateToInclusive']))
                                        : '')
                                : '';
                        }
                    });
                }
                combineLatest([_this.getLookupCaption(quarantine.data.diseaseTypeId, 'disease-type'),
                    _this.getLookupCaption(quarantine.data.currentStatusId, 'quarantine-status'),
                    _this.getLookupCaption(sourceTerritory.regionId, 'addr-by-region-city-ids', null, sourceTerritory.cityId)])
                    .subscribe(function (_a) {
                    var diseaseCaption = _a[0], statusCaption = _a[1], addressCaption = _a[2];
                    caption_1.next(diseaseCaption + ", " + statusCaption + ", " + periodCaption + " " + (addressCaption ? '(' + addressCaption + ')' : ''));
                });
            });
            return caption_1;
        }
        else {
            if (!useShort) {
                return this.getLookupObj(objId2 ? lookupName + objId2 : lookupName).pipe(map(function (lookup) { return lookup[value] || 'N/A'; }));
            }
            else {
                return this.getLookupObj(objId2 ? lookupName + objId2 : lookupName)
                    .pipe(map(function (lookup) { return lookup['Obj' + value]
                    ? (lookup['Obj' + value].shortCaption || lookup['Obj' + value].caption)
                    : 'N/A'; }));
            }
        }
    };
    return LookupSourceService;
}());
export { LookupSourceService };
