/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./query.all.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../../../../node_modules/@clr/angular/clr-angular.ngfactory";
import * as i4 from "@clr/angular";
import * as i5 from "@angular/common";
import * as i6 from "../../ui/controls/app-grid.component.ngfactory";
import * as i7 from "../../ui/controls/app-grid.component";
import * as i8 from "../../logic/services/data-caching.service";
import * as i9 from "../../logic/services/security.service";
import * as i10 from "./query-details-results.component";
import * as i11 from "./helpers/query-runner-ui.service";
import * as i12 from "../../logic/services/lookup-source.service";
var styles_QueryDetailsResultsComponent = [i0.styles];
var RenderType_QueryDetailsResultsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_QueryDetailsResultsComponent, data: {} });
export { RenderType_QueryDetailsResultsComponent as RenderType_QueryDetailsResultsComponent };
function View_QueryDetailsResultsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "option", [], [[1, "selected", 0]], null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [2, i2.SelectControlValueAccessor]], { ngValue: [0, "ngValue"] }, null), i1.ɵdid(2, 147456, null, 0, i2.ɵangular_packages_forms_forms_s, [i1.ElementRef, i1.Renderer2, [8, null]], { ngValue: [0, "ngValue"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var currVal_1 = _v.context.$implicit.id; _ck(_v, 1, 0, currVal_1); var currVal_2 = _v.context.$implicit.id; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_v.context.$implicit.id == _co.model.queryViewId) ? true : undefined); _ck(_v, 0, 0, currVal_0); var currVal_3 = _v.context.$implicit.caption; _ck(_v, 3, 0, currVal_3); }); }
export function View_QueryDetailsResultsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 35, "div", [["class", "query-manager-details"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u041F\u043E\u043B\u0443\u0447\u0435\u043D\u0438\u0435 \u0438 \u043F\u0440\u043E\u0441\u043C\u043E\u0442\u0440 \u0440\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442\u043E\u0432 \u0432\u044B\u0431\u043E\u0440\u043A\u0438 "])), (_l()(), i1.ɵeld(3, 0, null, null, 29, "div", [["class", "itech-form-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 6, "button", [["class", "btn btn-secondary"]], [[1, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.refreshFormData() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ClrLoadingButton_0, i3.RenderType_ClrLoadingButton)), i1.ɵdid(6, 49152, null, 0, i4.ClrLoadingButton, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵprd(2048, null, i4.LoadingListener, null, [i4.ClrLoadingButton]), i1.ɵdid(8, 147456, null, 0, i4.ClrLoading, [[2, i4.LoadingListener]], { loadingState: [0, "loadingState"] }, null), (_l()(), i1.ɵeld(9, 0, null, 0, 1, "clr-icon", [["shape", "refresh"]], null, null, null, null, null)), i1.ɵdid(10, 16384, null, 0, i4.ClrIconCustomTag, [], null, null), (_l()(), i1.ɵted(-1, 0, ["\u00A0\u041E\u0431\u043D\u043E\u0432\u0438\u0442\u044C "])), (_l()(), i1.ɵeld(12, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 9, "div", [["class", "select"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, [["paymentDocumentId", 1]], null, 8, "select", [["id", "queryViewId"], ["style", "width:300px;max-width:300px"], ["title", "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u043E\u0442\u043E\u0431\u0440\u0430\u0436\u0435\u043D\u0438\u0435"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onChange($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 15).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.model.queryViewId = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 16384, null, 0, i2.SelectControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.SelectControlValueAccessor]), i1.ɵdid(17, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(19, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_QueryDetailsResultsComponent_1)), i1.ɵdid(21, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(23, 0, null, null, 1, "div", [["class", "itech-control-desc"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u043E\u0442\u043E\u0431\u0440\u0430\u0436\u0435\u043D\u0438\u0435, \u043D\u0430\u0436\u043C\u0438\u0442\u0435 \"\u041E\u0431\u043D\u043E\u0432\u0438\u0442\u044C\""])), (_l()(), i1.ɵeld(25, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 6, "button", [["class", "btn btn-success-outline"]], [[1, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.exportCsv() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ClrLoadingButton_0, i3.RenderType_ClrLoadingButton)), i1.ɵdid(27, 49152, null, 0, i4.ClrLoadingButton, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵprd(2048, null, i4.LoadingListener, null, [i4.ClrLoadingButton]), i1.ɵdid(29, 147456, null, 0, i4.ClrLoading, [[2, i4.LoadingListener]], { loadingState: [0, "loadingState"] }, null), (_l()(), i1.ɵeld(30, 0, null, 0, 1, "clr-icon", [["shape", "export"]], null, null, null, null, null)), i1.ɵdid(31, 16384, null, 0, i4.ClrIconCustomTag, [], null, null), (_l()(), i1.ɵted(-1, 0, ["\u00A0\u0412\u044B\u0433\u0440\u0443\u0437\u0438\u0442\u044C \u0432 CSV "])), (_l()(), i1.ɵeld(33, 0, null, null, 2, "div", [["class", "form"], ["style", "overflow-y: hidden"]], null, null, null, null, null)), (_l()(), i1.ɵeld(34, 0, null, null, 1, "app-grid", [], null, [[null, "rowClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("rowClicked" === en)) {
        var pd_0 = (_co.queryRunnerUi.navigateRow(_co.model, $event.data) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_AppGridComponent_0, i6.RenderType_AppGridComponent)), i1.ɵdid(35, 114688, null, 0, i7.AppGridComponent, [i8.DataCachingService, i9.SecurityService], { uniqueGridName: [0, "uniqueGridName"], columnDefs: [1, "columnDefs"], rowData: [2, "rowData"], pagination: [3, "pagination"], paginationAutoPageSize: [4, "paginationAutoPageSize"], exportFileName: [5, "exportFileName"], pinnedTopRowData: [6, "pinnedTopRowData"] }, { rowClicked: "rowClicked" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.queryRunnerUi.quering; _ck(_v, 8, 0, currVal_1); var currVal_9 = _co.model.queryViewId; _ck(_v, 17, 0, currVal_9); var currVal_10 = i1.ɵunv(_v, 21, 0, i1.ɵnov(_v, 22).transform(_co.lookupSourceService.getLookup(("sys-query-view" + _co.model.entityType.id.toString())))); _ck(_v, 21, 0, currVal_10); var currVal_12 = _co.queryRunnerUi.exporting; _ck(_v, 29, 0, currVal_12); var currVal_13 = "QueryDetailsResults"; var currVal_14 = _co.model.query.dataColumns; var currVal_15 = _co.model.query.results; var currVal_16 = true; var currVal_17 = true; var currVal_18 = "\u0420\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442\u044B \u0432\u044B\u0431\u043E\u0440\u043A\u0438"; var currVal_19 = _co.model.query.aggSumRow; _ck(_v, 35, 0, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17, currVal_18, currVal_19); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 6).disabled ? "" : null); _ck(_v, 5, 0, currVal_0); var currVal_2 = i1.ɵnov(_v, 19).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 19).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 19).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 19).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 19).ngClassValid; var currVal_7 = i1.ɵnov(_v, 19).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 19).ngClassPending; _ck(_v, 14, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_11 = (i1.ɵnov(_v, 27).disabled ? "" : null); _ck(_v, 26, 0, currVal_11); }); }
export function View_QueryDetailsResultsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-query-details-results", [], null, null, null, View_QueryDetailsResultsComponent_0, RenderType_QueryDetailsResultsComponent)), i1.ɵdid(1, 114688, null, 0, i10.QueryDetailsResultsComponent, [i11.QueryRunnerUiService, i12.LookupSourceService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var QueryDetailsResultsComponentNgFactory = i1.ɵccf("app-query-details-results", i10.QueryDetailsResultsComponent, View_QueryDetailsResultsComponent_Host_0, { model: "model" }, {}, []);
export { QueryDetailsResultsComponentNgFactory as QueryDetailsResultsComponentNgFactory };
