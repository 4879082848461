import { noop, Subject, timer } from 'rxjs';
import { GroupOperationsService } from '../../logic/services/group-operations.service';
import { MetadataService } from '../../logic/services/metadata.service';
import { environment } from '../../../environments/environment';
import { AlertService } from './alert.service';
import * as i0 from "@angular/core";
import * as i1 from "../../logic/services/group-operations.service";
import * as i2 from "../../logic/services/metadata.service";
import * as i3 from "./alert.service";
var JobRunnerUiService = /** @class */ (function () {
    function JobRunnerUiService(groupOperationsService, metadataService, alertService) {
        var _this = this;
        this.groupOperationsService = groupOperationsService;
        this.metadataService = metadataService;
        this.alertService = alertService;
        this.model = {
            operationId: undefined,
            operationTypeId: undefined,
            state: undefined,
            result: undefined,
            started: false,
            completed: false,
            canceling: false,
            completeSubj: undefined,
            isVolatile: false
        };
        this.checkingCurrentOperationStateActive = false;
        this.tickTimer = timer(1000, 1000);
        this.tickSub = this.tickTimer.subscribe(function (t) { return _this.checkCurrentOperationState(); });
    }
    JobRunnerUiService.prototype.operationIsRunning = function () {
        return this.model.started;
    };
    JobRunnerUiService.prototype.runOperation = function (opType, params, file, data, isVolatile) {
        if (this.model.started) {
            return;
        }
        var model = this.model;
        model.started = true;
        model.completed = false;
        model.canceling = false;
        model.operationTypeId = opType;
        model.operationId = undefined;
        model.result = undefined;
        model.state = undefined;
        model.completeSubj = new Subject();
        model.isVolatile = isVolatile;
        this.groupOperationsService.runOperation(this.model.operationTypeId, params, file, data).subscribe({
            next: function (opKey) {
                model.operationId = opKey.id;
            },
            error: function () {
                model.started = false;
            }
        });
        return model.completeSubj.asObservable();
    };
    JobRunnerUiService.prototype.runOperationDirect = function (opType, params, file, data, isVolatile) {
        var model = {};
        model.started = true;
        model.completed = false;
        model.canceling = false;
        model.operationTypeId = opType;
        model.operationId = undefined;
        model.result = undefined;
        model.state = undefined;
        model.completeSubj = new Subject();
        model.isVolatile = isVolatile;
        this.groupOperationsService.runOperation(model.operationTypeId, params, file, data).subscribe({
            next: function (opKey) {
                model.operationId = opKey.id;
            },
            error: function () {
                model.started = false;
            }
        });
    };
    JobRunnerUiService.prototype.cancelOperation = function () {
        if (!this.model.started || this.model.completed || this.model.canceling || !this.model.operationId
            || !this.model.operationTypeId) {
            return;
        }
        var model = this.model;
        model.canceling = true;
        this.groupOperationsService.cancelOperation(this.model.operationTypeId, this.model.operationId).subscribe(noop);
    };
    JobRunnerUiService.prototype.getPercProgress = function () {
        return this.model.state ? Math.floor(this.model.state.progress / 100) : 0;
    };
    JobRunnerUiService.prototype.checkCurrentOperationState = function () {
        var _this = this;
        if (!this.model.operationId
            || (this.model.state && this.model.state.state === 3)
            || (this.model.state && this.model.state.state === 5)
            || this.checkingCurrentOperationStateActive) {
            return;
        }
        this.checkingCurrentOperationStateActive = true;
        this.groupOperationsService.getOperationStatus(this.model.operationTypeId, this.model.operationId)
            .subscribe({
            next: function (state) {
                _this.checkingCurrentOperationStateActive = false;
                _this.model.state = state;
                if (_this.model.state.state === 3 || _this.model.state.state === 5) {
                    _this.completeOperation(_this.model.state.result);
                }
            },
            error: function () {
                _this.checkingCurrentOperationStateActive = false;
            }
        });
    };
    JobRunnerUiService.prototype.completeOperation = function (volatileResult) {
        var _this = this;
        if (this.model.completed) {
            return;
        }
        this.model.completed = true;
        this.model.canceling = false;
        // если волатильная операция прошла успешно, то для неё в последнем статусе возвращается сразу результат,
        // а сама операция удаляется на сервере, поэтому запрошивать по ней данные бесполезно - вернется ошибка
        if (this.model.isVolatile && volatileResult) {
            this.model.result = { result: volatileResult };
            this.model.started = false;
            this.navigateResults();
        }
        else {
            this.groupOperationsService.getOperationResult(this.model.operationTypeId, this.model.operationId)
                .subscribe(function (result) {
                _this.model.result = result;
                _this.model.started = false;
                if (_this.model.result.result) {
                    _this.navigateResults();
                }
                else if (_this.model.result.message) {
                    _this.alertService.error(_this.model.result.message);
                    _this.model.completeSubj.error(_this.model.result);
                }
            });
        }
    };
    JobRunnerUiService.prototype.navigateResults = function () {
        this.model.completeSubj.next(this.model.result.result);
        // если операция волатильная, значит там уже конкретные результаты возвращаются в виде строки (например, JSON, см. строку выше),
        // а никаких файлов и т.п. в результатах уже нету и нечего скачивать и отображать
        if (!this.model.isVolatile) {
            this.metadataService.preloadFile(this.model.result.result).subscribe(function (fileHash) {
                window.open(environment.api + '/files/get?preloadId=' + encodeURIComponent(fileHash));
            });
        }
    };
    JobRunnerUiService.ngInjectableDef = i0.defineInjectable({ factory: function JobRunnerUiService_Factory() { return new JobRunnerUiService(i0.inject(i1.GroupOperationsService), i0.inject(i2.MetadataService), i0.inject(i3.AlertService)); }, token: JobRunnerUiService, providedIn: "root" });
    return JobRunnerUiService;
}());
export { JobRunnerUiService };
