/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@clr/angular";
import * as i2 from "@angular/common";
import * as i3 from "./app-validation-tooltip.component.ngfactory";
import * as i4 from "./app-validation-tooltip.component";
import * as i5 from "./app-datetime-picker.component";
var styles_AppDatetimePickerComponent = [];
var RenderType_AppDatetimePickerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppDatetimePickerComponent, data: {} });
export { RenderType_AppDatetimePickerComponent as RenderType_AppDatetimePickerComponent };
function View_AppDatetimePickerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [[2, 0], ["timePortion", 1]], null, 0, "input", [["required", ""], ["type", "time"]], [[8, "id", 0], [1, "disabled", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.timePortionControlChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.contextControlName, "Time"); var currVal_1 = (_co.disabled ? true : undefined); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_AppDatetimePickerComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { datePortion: 0 }), i0.ɵqud(671088640, 2, { timePortion: 0 }), (_l()(), i0.ɵeld(2, 0, null, null, 6, "label", [["aria-haspopup", "true"], ["class", "tooltip tooltip-validation tooltip-md tooltip-right"], ["role", "tooltip"]], [[2, "invalid", null], [1, "for", 0]], null, null, null, null)), i0.ɵdid(3, 212992, null, 0, i1.ClrLabel, [[2, i1.ɵbe], [2, i1.ɵbf], [2, i1.ɵbg], i0.Renderer2, i0.ElementRef], { forAttr: [0, "forAttr"] }, null), (_l()(), i0.ɵeld(4, 0, [[1, 0], ["datePortion", 1]], null, 0, "input", [["required", ""], ["type", "date"]], [[8, "id", 0], [1, "disabled", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.datePortionControlChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppDatetimePickerComponent_1)), i0.ɵdid(6, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 1, "app-validation-tooltip", [], [[1, "class", 0]], null, null, i3.View_AppValidationTooltipComponent_0, i3.RenderType_AppValidationTooltipComponent)), i0.ɵdid(8, 49152, null, 0, i4.AppValidationTooltipComponent, [], { input: [0, "input"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = i0.ɵinlineInterpolate(1, "", _co.contextControlName, "Date"); _ck(_v, 3, 0, currVal_2); var currVal_5 = _co.withTime; _ck(_v, 6, 0, currVal_5); var currVal_7 = _co.contextFormGroup.controls[_co.contextControlName]; _ck(_v, 8, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isInvalid(_co.contextControlName); var currVal_1 = i0.ɵnov(_v, 3).forAttr; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = i0.ɵinlineInterpolate(1, "", _co.contextControlName, "Date"); var currVal_4 = (_co.disabled ? true : undefined); _ck(_v, 4, 0, currVal_3, currVal_4); var currVal_6 = i0.ɵnov(_v, 8).class; _ck(_v, 7, 0, currVal_6); }); }
export function View_AppDatetimePickerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-datetime-picker", [], null, null, null, View_AppDatetimePickerComponent_0, RenderType_AppDatetimePickerComponent)), i0.ɵdid(1, 573440, null, 0, i5.AppDatetimePickerComponent, [], null, null)], null, null); }
var AppDatetimePickerComponentNgFactory = i0.ɵccf("app-datetime-picker", i5.AppDatetimePickerComponent, View_AppDatetimePickerComponent_Host_0, { disabled: "disabled", withTime: "withTime", contextControlName: "contextControlName", contextFormGroup: "contextFormGroup" }, {}, []);
export { AppDatetimePickerComponentNgFactory as AppDatetimePickerComponentNgFactory };
