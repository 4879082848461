import { Component, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { EditProductCommonFormComponent } from './edit-product-common-form.component';

@Component({
  selector: 'app-edit-product-packaging',
  template: `
        <div *ngFor="let pack of packages.controls; index as i" [formGroup]="pack">
          <app-combo-lookup [validationTooltipRight]="true" class="itech-control-normal"
                            [contextControlId]="rndToken + 'packageLevelId' + i"
                            [contextControlName]="'packageLevelId'" (change)="changePackageLevel(i)"
                            [lookupName]="'product-package-level'" [contextFormGroup]="pack"></app-combo-lookup>
          &nbsp;
          <label for="{{rndToken}}count{{i}}" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md"
                 [class.invalid]="isInvalid(pack, 'count')">
            <input type="text" id="{{rndToken}}count{{i}}" placeholder="Кол-во" class="itech-control-tiny"
                   formControlName="count">
            <app-validation-tooltip [input]="pack.controls['count']"></app-validation-tooltip>
          </label>
          &nbsp;
          <app-combo-lookup [validationTooltipRight]="true" class="itech-control-normal"
                            [contextControlId]="rndToken + 'packageTypeId' + i"
                            [contextControlName]="'packageTypeId'"
                            [lookupName]="'drug-package-type'" [contextFormGroup]="pack"></app-combo-lookup>
          &nbsp;
          <button type="button" class="btn btn-link" style="text-transform:none;letter-spacing:normal;padding:0;margin:0;border:none"
                  *ngIf="isDraft && pack.value.packageLevelId; else noDraft"
                  (click)="pack.get('expandedMark').setValue(!pack.get('expandedMark').value)">
            <clr-icon *ngIf="pack.get('expandedMark').value" shape="angle up"></clr-icon>
            <clr-icon *ngIf="!pack.get('expandedMark').value && (getMarkings(pack).controls.length-1) !== 0" shape="angle down"></clr-icon>
            <clr-icon *ngIf="!pack.get('expandedMark').value && (getMarkings(pack).controls.length-1) === 0" shape="plus"></clr-icon>
            Маркировка
            <clr-icon *ngIf="isInvalidMarkings(pack)" shape="info-cycle" style="color:#a32100"></clr-icon>
          </button>
          <ng-template #noDraft>
            <button type="button" class="btn btn-link" style="text-transform:none;letter-spacing:normal;padding:0;margin:0;border:none"
                    *ngIf="!isDraft && getMarkings(pack).controls.length"
                    (click)="pack.get('expandedMark').setValue(!pack.get('expandedMark').value)">
              <clr-icon *ngIf="pack.get('expandedMark').value" shape="angle up"></clr-icon>
              <clr-icon *ngIf="!pack.get('expandedMark').value" shape="angle down"></clr-icon>
              Маркировка
              <clr-icon *ngIf="isInvalidMarkings(pack)" shape="info-cycle" style="color:#a32100"></clr-icon>
            </button>
          </ng-template>
          <span *ngIf="pack.get('currentCount').value != null">&nbsp;&nbsp;(остаток {{pack.get('currentCount').value}})</span>
          <ng-container *ngIf="pack.get('expandedMark').value">
            <div *ngFor="let mark of getMarkings(pack).controls; index as j" [formGroup]="mark" style="margin-left:88px">
              <app-combo-lookup [validationTooltipRight]="true" class="itech-control-normal"
                                [contextControlId]="rndToken + 'marking' + i + j"
                                [contextControlName]="'markingTypeId'" (change)="changeMarkingType(pack, j)"
                                [lookupName]="'product-marking-type'"
                                [contextFormGroup]="mark"></app-combo-lookup>
              &nbsp;
              <label for="{{rndToken}}mark{{i}}{{j}}" aria-haspopup="true" role="tooltip"
                     class="tooltip tooltip-validation tooltip-md"
                     [class.invalid]="isInvalid(mark, 'marking')">
                  <app-input-text [contextId]="rndToken+'mark'+i+j" [classNames]="'itech-control-medium'"
                                  [formControlObj]="mark.get('marking')" [htmlType]="1" [placeholder]="'Наименование'"></app-input-text>
                <app-validation-tooltip [input]="mark.controls['marking']"></app-validation-tooltip>
              </label>
            </div>
          </ng-container>
        </div>`
})
export class EditProductPackagingComponent {

  @Input() packages: FormArray;
  @Input() isDraft;

  isInvalid = FormHelper.isInvalid;
  rndToken = Math.floor(Math.random() * 1000000);

  constructor(private fb: FormBuilder) {
  }

  getMarkings(pack) {
    return pack.get('markings') as FormArray;
  }

  changePackageLevel(ix: number) {
    if (!this.packages.at(ix).value.packageLevelId) {
      this.packages.removeAt(ix);
    }
    if (this.isDraft && this.packages.getRawValue().every(el => el.packageLevelId)) {
      this.packages.push(EditProductCommonFormComponent.buildPackage(this.fb, {}, this.isDraft));
    }
  }

  changeMarkingType(pack, ix: number) {
    if (!this.getMarkings(pack).at(ix).value.markingTypeId) {
      this.getMarkings(pack).removeAt(ix);
    }
    if (this.isDraft && this.getMarkings(pack).getRawValue().every(el => el.markingTypeId)) {
      this.getMarkings(pack).push(EditProductCommonFormComponent.buildMarking(this.fb, {}));
    }
  }

  isInvalidMarkings(pack: FormGroup) {
    return this.getMarkings(pack).controls.some(x => x.invalid);
  }
}
