import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataCachingService } from '../../logic/services/data-caching.service';
import { BookmarkService } from '../../logic/services/bookmark.service';

@Component({
  selector: 'app-edit-agent',
  templateUrl: './edit-agent.component.html'
})
export class EditAgentComponent implements OnInit {

  @Input() model: any = EditAgentComponent.createDefaultModel();
  @Input() id: number;

  static createDefaultModel(): any {
    return {
      commonTabActive: true,
      stablesTabActive: false,
      animalsTabActive: false,
      ticketsTabActive: false,
    };
  }

  constructor(private route: ActivatedRoute,
              private bookmarkService: BookmarkService,
              private dataCachingService: DataCachingService) {
  }

  ngOnInit() {

    this.route.paramMap
      .subscribe(params => {
        this.id = parseInt(params.get('id'), 10);

        const existing = this.dataCachingService.getCachedData('EditAgent', this.id.toString());

        this.bookmarkService.refreshAgentCardBookmark(this.id);

        if (existing) {
          this.model = existing;
        } else {
          this.model = EditAgentComponent.createDefaultModel();
          this.dataCachingService.addToCache('EditAgent', this.id.toString(), this.model);
        }
      });
  }

  showLoadedAnimalCount(): string {
    const existing = this.dataCachingService.getCachedData('EditAgentAnimals', this.id.toString());

    if (!existing || !existing.searchResults) {
      return '';
    } else {
      let count = 0;
      existing.searchResults.forEach(el => count += el.animalGroup ? (el.totalCount || 0) : 1);
      return ' (' + count.toString() + ')';
    }
  }
}
