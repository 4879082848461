/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./product-search-results-form.component.ngfactory";
import * as i2 from "./product-search-results-form.component";
import * as i3 from "../../../logic/services/product-data.service";
import * as i4 from "../../../ui/infrastructure/global-waiting-overlay.service";
import * as i5 from "../../../logic/services/app-navigation.service";
import * as i6 from "../../../logic/services/lookup-source.service";
import * as i7 from "../../../logic/services/security.service";
import * as i8 from "./product-search-modal.component";
import * as i9 from "@angular/forms";
var styles_ProductSearchModalComponent = [];
var RenderType_ProductSearchModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProductSearchModalComponent, data: {} });
export { RenderType_ProductSearchModalComponent as RenderType_ProductSearchModalComponent };
export function View_ProductSearchModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["style", "height: calc(100vh - 300px); background-color: white; position: relative;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-product-search-results-form", [], null, [[null, "selectionChanged"], [null, "rowClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectionChanged" === en)) {
        var pd_0 = (_co.selectedChanges($event) !== false);
        ad = (pd_0 && ad);
    } if (("rowClicked" === en)) {
        var pd_1 = (_co.chooseProduct($event.id) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_ProductSearchResultsFormComponent_0, i1.RenderType_ProductSearchResultsFormComponent)), i0.ɵdid(2, 114688, null, 0, i2.ProductSearchResultsFormComponent, [i3.ProductDataService, i4.GlobalWaitingOverlayService, i5.AppNavigationService, i6.LookupSourceService, i7.SecurityService], { model: [0, "model"], autoChangeExpanded: [1, "autoChangeExpanded"] }, { rowClicked: "rowClicked", selectionChanged: "selectionChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model; var currVal_1 = true; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_ProductSearchModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_ProductSearchModalComponent_0, RenderType_ProductSearchModalComponent)), i0.ɵdid(1, 114688, null, 0, i8.ProductSearchModalComponent, [i5.AppNavigationService, i9.FormBuilder], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProductSearchModalComponentNgFactory = i0.ɵccf("ng-component", i8.ProductSearchModalComponent, View_ProductSearchModalComponent_Host_0, {}, {}, []);
export { ProductSearchModalComponentNgFactory as ProductSearchModalComponentNgFactory };
