/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./agent-search-results-form.component.ngfactory";
import * as i2 from "./agent-search-results-form.component";
import * as i3 from "../../../logic/services/agent-data.service";
import * as i4 from "../../../ui/infrastructure/global-waiting-overlay.service";
import * as i5 from "../../../logic/services/app-navigation.service";
import * as i6 from "../../../logic/services/lookup-source.service";
import * as i7 from "../../../logic/services/security.service";
import * as i8 from "./agent-search-modal.component";
import * as i9 from "@angular/forms";
import * as i10 from "../../../ui/infrastructure/alert.service";
var styles_AgentSearchModalComponent = [];
var RenderType_AgentSearchModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AgentSearchModalComponent, data: {} });
export { RenderType_AgentSearchModalComponent as RenderType_AgentSearchModalComponent };
export function View_AgentSearchModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["style", "height: 600px; background-color: white; position: relative;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-agent-search-results-form", [], null, [[null, "rowClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("rowClicked" === en)) {
        var pd_0 = (_co.chooseAgent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_AgentSearchResultsFormComponent_0, i1.RenderType_AgentSearchResultsFormComponent)), i0.ɵdid(2, 114688, null, 0, i2.AgentSearchResultsFormComponent, [i3.AgentDataService, i4.GlobalWaitingOverlayService, i5.AppNavigationService, i6.LookupSourceService, i7.SecurityService], { model: [0, "model"], availableChangeStableId: [1, "availableChangeStableId"], createdAgentCallback: [2, "createdAgentCallback"] }, { rowClicked: "rowClicked" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model; var currVal_1 = _co.model.filterFormGroup.get("availableChangeStableId").value; var currVal_2 = _co.createdCallback; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_AgentSearchModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_AgentSearchModalComponent_0, RenderType_AgentSearchModalComponent)), i0.ɵdid(1, 114688, null, 0, i8.AgentSearchModalComponent, [i5.AppNavigationService, i9.FormBuilder, i10.AlertService, i3.AgentDataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AgentSearchModalComponentNgFactory = i0.ɵccf("ng-component", i8.AgentSearchModalComponent, View_AgentSearchModalComponent_Host_0, {}, {}, []);
export { AgentSearchModalComponentNgFactory as AgentSearchModalComponentNgFactory };
