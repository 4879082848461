import * as tslib_1 from "tslib";
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { MetadataService } from '../../../logic/services/metadata.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MetaBrowseBaseComponent } from '../meta-browse.base.component';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { StringHelper } from '../../../helpers/string-helper';
import { DataCachingService } from '../../../logic/services/data-caching.service';
var QuarantineEventResponsibleBrowseComponent = /** @class */ (function (_super) {
    tslib_1.__extends(QuarantineEventResponsibleBrowseComponent, _super);
    function QuarantineEventResponsibleBrowseComponent(route, metadataService, alertService, globalWaitingOverlayService, lookupService, bookmarkService, fb, dataCachingService) {
        var _this = _super.call(this, route, metadataService, alertService, globalWaitingOverlayService, lookupService, fb, bookmarkService, dataCachingService) || this;
        _this.gridColumnDefs = [
            { headerName: '№', field: 'id', width: 100 },
            { headerName: 'Отчитывающийся', field: 'fillResponsibleKindCaption', tooltipField: 'fillResponsibleKindCaption', width: 200 },
            { headerName: 'Наименование', field: 'caption', tooltipField: 'caption', width: 350 },
            {
                headerName: 'Удалено', field: 'dateDeleted', width: 100,
                valueFormatter: function (params) { return StringHelper.getRuTimeWithSeconds(params.value); },
            },
        ];
        return _this;
    }
    QuarantineEventResponsibleBrowseComponent.prototype.getMetaTitle = function () {
        return 'Ответственные за мероприятия';
    };
    QuarantineEventResponsibleBrowseComponent.prototype.getMetaName = function () {
        return 'quarantine-event-responsible';
    };
    QuarantineEventResponsibleBrowseComponent.prototype.refreshResults = function () {
        var _this = this;
        this.globalWaitingOverlayService.StartWaiting();
        this.lookupService.getLookupObj('quarantine-fill-responsible-kind').subscribe(function (responsibleKindLookup) {
            _this.metadataService.getMetadata(_this.getMetaName()).subscribe({
                next: function (data) {
                    data.forEach(function (el) {
                        el.fillResponsibleKindCaption = responsibleKindLookup[el.fillResponsibleKind] || '<<Неизвестная категория>>';
                    });
                    _this._searchResults = data;
                    _this.lookupService.invalidateLookup(_this.getMetaName());
                    _this.dataCachingService.addToCache(_this.getMetaName() + '_Data', '1', data);
                    _this.globalWaitingOverlayService.EndWaiting();
                }, error: function () {
                    _this.globalWaitingOverlayService.EndWaiting();
                }
            });
        });
    };
    QuarantineEventResponsibleBrowseComponent.prototype.getGroupDef = function (row) {
        return {
            id: [row.id, Validators.compose([Validators.required, Validators.pattern(/^\d{1,5}$/)])],
            fillResponsibleKind: [row.fillResponsibleKind, Validators.compose([Validators.required])],
            caption: [row.caption, Validators.required],
        };
    };
    QuarantineEventResponsibleBrowseComponent.prototype.getRowStyle = function (params) {
        if (params.data && params.data.dateDeleted) {
            return { color: 'silver' };
        }
    };
    return QuarantineEventResponsibleBrowseComponent;
}(MetaBrowseBaseComponent));
export { QuarantineEventResponsibleBrowseComponent };
