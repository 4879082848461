<div class="itech-content-header">
  <clr-icon shape="truck" size="36" class="has-badge"></clr-icon>&nbsp;Создание нового ветеринарного документа
</div>

<app-edit-product-common-form *ngIf="model" [model]="model" [contextFormGroup]="model.form"
                              [serverSideErrorsProvider]="this.model" [validityControlsEvent]="validityControlsEvent">
</app-edit-product-common-form>

<p>
  <button [clrLoading]="creating" [disabled]="creating" class="btn btn-primary"
          (click)="createCard()">
    <clr-icon shape="check"></clr-icon>&nbsp;Создать
  </button>
  <button [clrLoading]="creating" [disabled]="creating || (this.model.form && this.model.form.get('isTemplate').value)" class="btn btn-primary"
          (click)="createCard(true)">
    <clr-icon shape="check"></clr-icon>&nbsp;Создать и зарегистрировать
  </button>
</p>
