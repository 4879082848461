<div class="itech-content-header itech-no-margin-bottom">
  <clr-icon shape="view-list" size="36"></clr-icon>&nbsp;Группы клиентов
</div>

<div class="itech-person-groups-content">

  <div class="itech-person-groups-navigation">

    <div class="itech-block-inline" id="selectedPersonGroup">
      <div class="select">
        <select [(ngModel)]="selectedPersonGroup" (change)="selectedGroupChange()">
          <option *ngFor="let item of personGroups" [ngValue]="item">
            {{item.caption}}
          </option>
        </select>
      </div>

      <div class="itech-control-desc">
        Выберите группу для редактирования, или создайте новую группу, нажав на "+"
      </div>
    </div>

    <button type="button" class="btn btn-danger btn-link itech-inline-tool-btn" title="Добавить новую группу клиентов"
            (click)="addPersonGroup()" [disabled]="!selectedPersonGroup || selectedPersonGroup.id? undefined: true">
      <clr-icon shape="plus"></clr-icon>
    </button>

    <br>
    <br>

    <div class="itech-group-whole-line" [formGroup]="newPeopleFormGroup"
         *ngIf="selectedPersonGroup && selectedPersonGroup.id">
      <label></label>
      <label for="newPersonText"
             aria-haspopup="true"
             role="tooltip"
             class="tooltip tooltip-validation tooltip-md tooltip-top-left"
             [class.invalid]="isInvalid(newPeopleFormGroup, 'newPersonText')">
        <input type="text" id="newPersonText" formControlName="newPersonText" class="itech-control-m-large"
               (keydown)="newPersonTextKeyDown($event)" placeholder="Введите данные для поиска гражданина">
        <app-validation-tooltip [input]="newPeopleFormGroup.controls['newPersonText']"></app-validation-tooltip>
      </label>
      <button type="button" class="btn btn-link itech-inline-link-btn" (click)="addClient()">
        Добавить
      </button>

      <div class="itech-control-desc">
        Добавьте клиента в группу
      </div>
    </div>
  </div>

  <div *ngIf="contextFormGroup" class="itech-person-groups-form">

    <h4>Параметры выбранной группы</h4>

    <div class="form compact" [formGroup]="contextFormGroup">
      <section class="form-block itech-block-normal">

        <div class="form-group">
          <label for="caption" class="required">Наименование</label>
          <label for="caption" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                 [class.invalid]="isInvalid(contextFormGroup, 'caption')">
            <input type="text" id="caption" class="itech-control-m-large" required
                   formControlName="caption">
            <app-validation-tooltip [input]="contextFormGroup.controls['caption']"></app-validation-tooltip>
          </label>
        </div>

        <div class="form-group">
          <label for="person-group-visibility" class="required">Уровень доступности</label>
          <app-combo-lookup class="itech-control-large"
                            [contextControlId]="'person-group-visibility'"
                            [contextControlName]="'personGroupVisibility'"
                            [lookupName]="'person-group-visibility'"
                            [contextFormGroup]="contextFormGroup"></app-combo-lookup>
        </div>

      </section>
    </div>

    <app-doc-validation-errors [validationErrors]="contextFormGroup.docLevelServerSideValidationErrors">
    </app-doc-validation-errors>

    <div class="itech-form-actions">
      <div>
        <button *ngIf="contextFormGroup.get('id').value" type="button" class="btn btn-warning-outline"
                (click)="deleteCurrentPersonGroup()">
          <clr-icon shape="trash"></clr-icon>&nbsp;Удалить группу
        </button>
      </div>
      <div>
        <button type="button" class="btn btn-primary" (click)="storePersonGroup()" [clrLoading]="storing"
                [disabled]="storing || !contextFormGroup || !contextFormGroup.dirty">
          <clr-icon shape="check"></clr-icon>&nbsp;Сохранить
        </button>
        <button type="button" class="btn btn-warning" (click)="createContextFormGroup()"
                [disabled]="storing || !contextFormGroup || !contextFormGroup.dirty">
          <clr-icon shape="undo"></clr-icon>&nbsp;Отмена
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="contextFormGroup" class="itech-person-groups-items">

    <div *ngIf="!itemsLoading && (!items || items.length == 0)" class="itech-no-data-text">
      В группе отсутствуют клиенты
    </div>

    <div *ngIf="itemsLoading">
      <span class="spinner spinner-sm"></span>Загружаю список клиентов
    </div>

    <ng-container *ngFor="let item of items; index as i">

      <div class="form-group">
        {{i+1}}.
        <app-link-button [id]="item" [lookupName]="'person'"></app-link-button>
        <button type="button" class="btn btn-danger btn-link itech-inline-tool-btn" title="Удалить из списка"
                (click)="deleteClient(i)">
          <clr-icon shape="trash"></clr-icon>
        </button>
      </div>

    </ng-container>
  </div>
</div>
