import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Required } from '../../../ui/infrastructure/app-decotators';
import { FormHelper } from '../../../ui/controls/form-helper';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProductDataService } from '../../../logic/services/product-data.service';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';

@Component({
  selector: 'app-products-search-results-with-tabs',
  template: `
    <ng-container *ngIf="viewType">
      <div class="clr-control-container clr-control-inline"style="padding-left:5px;border-left:1px solid silver" [formGroup]="viewType">
        <clr-signpost *ngIf="currentStableId" class="itech-sync-status-lbl"
                      style="margin-left:8px;margin-right:8px;margin-top:10px">
          <a class="itech-inline-link-btn itech-link" clrSignpostTrigger title="Шаблоны">
            <clr-icon shape="file-settings"></clr-icon>
          </a>
          <clr-signpost-content [clrPosition]="'right-bottom'" class="compact form itech-highlighted-sections"
                                style="max-width:21rem" *clrIfOpen>
            <h6 style="margin-top:0;font-weight:bold">Шаблоны:</h6>
            <div *ngFor="let template of templates; index as i">
              <button type="button" class="btn btn-link" style="margin:0;padding:0;min-width:20px;"
                      (click)="performToTemplate(template)" clrSignpostTrigger>
                <clr-icon shape="pencil"></clr-icon>
              </button>
              {{template.templateCaption}}
            </div>
          </clr-signpost-content>
        </clr-signpost>
        <div>
          <div class="clr-control-container clr-control-inline" style="padding-left: 5px;border-left:1px solid silver"
               [formGroup]="viewType">
            <div class="clr-radio-wrapper itech-highlight-checked">
              <input id="transactionTypeAll" type="radio" formControlName="transactionType"
                     value="1" class="clr-radio" (ngModelChange)="changeViewType()"/>
              <label for="transactionTypeAll">Все</label>
            </div>
            <ng-container *ngIf="currentStableId">
              &nbsp;&nbsp;&nbsp;
              <div class="clr-radio-wrapper itech-highlight-checked">
                <input id="transactionTypeOutgoing" type="radio" formControlName="transactionType"
                       value="2" class="clr-radio" (ngModelChange)="changeViewType()"/>
                <label for="transactionTypeOutgoing">Исходящие</label>
              </div>
              &nbsp;&nbsp;&nbsp;
              <div class="clr-radio-wrapper itech-highlight-checked">
                <input id="transactionTypeIncoming" type="radio" formControlName="transactionType"
                       value="3" class="clr-radio" (ngModelChange)="changeViewType()"/>
                <label for="transactionTypeIncoming">Входящие</label>
              </div>
            </ng-container>
            <ng-container *ngIf="!currentStableId">
              &nbsp;&nbsp;&nbsp;
              <div class="clr-radio-wrapper itech-highlight-checked">
                <input id="transactionTypeTransport" type="radio" formControlName="transactionType"
                       value="4" class="clr-radio" (ngModelChange)="changeViewType()"/>
                <label for="transactionTypeTransport">Транспортные</label>
              </div>
            </ng-container>
            &nbsp;&nbsp;&nbsp;
            <div class="clr-radio-wrapper itech-highlight-checked">
              <input id="transactionTypeProduction" type="radio" formControlName="transactionType"
                     value="5" class="clr-radio" (ngModelChange)="changeViewType()"/>
              <label for="transactionTypeProduction">Производственные</label>
            </div>
          </div>
          <div class="clr-control-container clr-control-inline" style="padding-left:5px;border-left:1px solid silver"
               [formGroup]="viewType">
            <div class="clr-radio-wrapper itech-highlight-checked">
              <input id="statusAll" type="radio" formControlName="status"
                     value="1" class="clr-radio" (ngModelChange)="changeViewType()"/>
              <label for="statusAll">Все</label>
            </div>
            &nbsp;&nbsp;&nbsp;
            <div class="clr-radio-wrapper itech-highlight-checked">
              <input id="statusIssued" type="radio" formControlName="status"
                     value="2" class="clr-radio" (ngModelChange)="changeViewType()"/>
              <label for="statusIssued">Оформленные</label>
            </div>
            &nbsp;&nbsp;&nbsp;
            <div class="clr-radio-wrapper itech-highlight-checked">
              <input id="statusNotIssued" type="radio" formControlName="status"
                     value="6" class="clr-radio" (ngModelChange)="changeViewType()"/>
              <label for="statusNotIssued">Неоформленные</label>
            </div>
            &nbsp;&nbsp;&nbsp;
            <div class="clr-radio-wrapper itech-highlight-checked">
              <input id="statusDeleted" type="radio" formControlName="status"
                     value="3" class="clr-radio" (ngModelChange)="changeViewType()"/>
              <label for="statusDeleted">Аннулированные</label>
            </div>
            &nbsp;&nbsp;&nbsp;
            <div class="clr-radio-wrapper itech-highlight-checked">
              <input id="statusCleared" type="radio" formControlName="status"
                     value="4" class="clr-radio" (ngModelChange)="changeViewType()"/>
              <label for="statusCleared">{{+viewType.get('transactionType').value === 5 ? 'Израсходованные' : 'Погашенные'}}</label>
            </div>
            &nbsp;&nbsp;&nbsp;
            <div class="clr-radio-wrapper itech-highlight-checked">
              <input id="statusSync" type="radio" formControlName="status"
                     value="5" class="clr-radio" (ngModelChange)="changeViewType()"/>
              <label for="statusSync">На синхронизации</label>
            </div>
            &nbsp;&nbsp;&nbsp;
            <div class="clr-radio-wrapper itech-highlight-checked">
              <input id="statusRequest" type="radio" formControlName="status"
                     value="7" class="clr-radio" (ngModelChange)="changeViewType()"/>
              <label for="statusRequest">Заявки</label>
            </div>
          </div>
        </div>
      </div>
      <div style="position:absolute;bottom:0;top:50px;left:0;right:0">
        <app-grid style="width: 100%; height: 100%" [pagination]="false" [getRowStyle]="getRowStyle"
                  [uniqueGridName]="uniqueGridName" [rowData]="filteredData" [columnDefs]="columnDefs"
                  [masterDetail]="true" [detailRowHeight]="'450'" [icons]="gridIcons" [exportFileName]="exportFileName"
                  [detailCellRenderer]="detailCellRenderer" [frameworkComponents]="frameworkComponents"
                  [rowMultiSelectWithClick]="true" [rowSelection]="'multiple'"
                  (rowDoubleClicked)="rowDoubleClicked.emit($event)"
                  (selectionChanged)="selectionChanged.emit($event)"
                  (modelUpdated)="modelUpdated.emit($event)"></app-grid>
      </div>
    </ng-container>`
})
export class ProductSearchResultsWithTabsComponent implements OnChanges {
  @Input() @Required columnDefs: any[] = [];
  @Input() @Required uniqueGridName;

  @Input() data: any[] = [];
  @Input() templates: any[] = [];
  @Input() frameworkComponents: any;
  @Input() detailCellRenderer: any;
  @Input() gridIcons: any;
  @Input() getRowStyle: any;
  @Input() exportFileName = 'Продукция';
  @Input() currentStableId;

  @Output() rowDoubleClicked = new EventEmitter();
  @Output() selectionChanged = new EventEmitter();
  @Output() modelUpdated = new EventEmitter();

  filteredData = [];
  equalsSome = FormHelper.equalsSome;

  viewType: FormGroup;

  constructor(private dataCachingService: DataCachingService,
              private productDataService: ProductDataService,
              public navigationService: AppNavigationService,
              private fb: FormBuilder) {
  }

  ngOnChanges() {
    this.viewType = this.fb.group({
      transactionType: this.dataCachingService.getCachedData(
      this.uniqueGridName + '_TableWithTabs_transactionType' + this.currentStableId, '1') || '1',
      status: this.dataCachingService.getCachedData(
      this.uniqueGridName + '_TableWithTabs_status' + this.currentStableId, '1') || '1',
  });
    this.changeViewType();
  }

  public changeViewType() {
    this.filteredData = this.filterByStatus(this.filterByTransactionType(this.data));

    this.dataCachingService.addToCache(this.uniqueGridName + '_TableWithTabs_transactionType' + this.currentStableId,
      '1', this.viewType.get('transactionType').value);
    this.dataCachingService.addToCache(this.uniqueGridName + '_TableWithTabs_status' + this.currentStableId,
      '1', this.viewType.get('status').value);
  }

  private filterByTransactionType(data: any[]): any[] {
    if (+this.viewType.get('transactionType').value === 1) {
      return data;
    } else if (+this.viewType.get('transactionType').value === 2) {
      return data.filter(x => this.equalsSome(x.transactionType, 1, 2, 3) && x.originStableId === this.currentStableId);
    } else if (+this.viewType.get('transactionType').value === 3) {
      return data.filter(x => this.equalsSome(x.transactionType, 1, 2, 3) && x.destinationStableId === this.currentStableId);
    } else if (+this.viewType.get('transactionType').value === 4) {
      return data.filter(x => this.equalsSome(x.transactionType, 1, 2, 3));
    } else if (+this.viewType.get('transactionType').value === 5) {
      return data.filter(x => this.equalsSome(x.transactionType, 4));
    } else {
      return [];
    }
  }

  private filterByStatus(data: any[]): any[] {
    if (+this.viewType.get('status').value === 1) {
      return data;
    } else if (+this.viewType.get('status').value === 2) {
      return data.filter(x => x.status === 2);
    } else if (+this.viewType.get('status').value === 3) {
      return data.filter(x => x.status === 4);
    } else if (+this.viewType.get('status').value === 4) {
      return data.filter(x => this.equalsSome(x.status, 3, 6));
    } else if (+this.viewType.get('status').value === 5) {
      return data.filter(x => this.equalsSome(x.status, 1, 5, 7));
    } else if (+this.viewType.get('status').value === 6) {
      return data.filter(x => this.equalsSome(x.status, -100, 0, 1));
    } else if (+this.viewType.get('status').value === 7) {
      return data.filter(x => x.status === -1);
    } else {
      return [];
    }
  }

  performToTemplate(template: any) {
    this.navigationService.performProductEditing(template.id);
  }
}
