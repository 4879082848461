import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { AppFormGroup } from '../../../ui/controls/app-form-group';
import { ServerSideErrorsProvider } from '../../../logic/validators/server-side-errors-provider';
import { FormHelper } from '../../../ui/controls/form-helper';
import { EditQuarantineStagesComponent } from './edit-quarantine-stages.component';

@Component({
  selector: 'app-edit-quarantine-cancel',
  templateUrl: './edit-quarantine-stages-cancel.component.html',
  styleUrls: ['../edit-quarantine.all.css']
})
export class EditQuarantineStagesCancelComponent implements OnChanges {

  @Input() id: number;
  @Input() currentStatusId: number;
  @Input() contextFormGroup: AppFormGroup;
  @Input() serverSideErrorsProvider: ServerSideErrorsProvider;
  @Input() isAccessEdit = false;

  @Output() dirtyFormGroup = new EventEmitter();
  @Output() needUpdateQuarantine = new EventEmitter();

  isInvalid = FormHelper.isInvalid;
  statusCancel = EditQuarantineStagesComponent.QUARANTINE_STAGE.CANCEL;

  ngOnChanges() {
    this.contextFormGroup.valueChanges.subscribe(x => this.dirtyFormGroup.next());
  }

  markAsDirty() {
    this.contextFormGroup.markAsDirty();
    this.dirtyFormGroup.next();
  }

  changeFile($event: any) {
    this.markAsDirty();
  }
}
