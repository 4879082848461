import { Component, OnInit } from '@angular/core';
import { AppNavigationService } from '../../logic/services/app-navigation.service';
import { SecurityService } from '../../logic/services/security.service';
import { Router } from '@angular/router';

@Component({
  templateUrl: './unavailable.component.html'
})
export class UnavailableComponent implements OnInit {

  unavailable = true;

  ngOnInit(): void {
    this.securityService.queryServerUserInfo().subscribe(() => {
      this.unavailable = false;
      this.router.navigate(['dashboard']);
    });
  }

  constructor(private securityService: SecurityService, private router: Router) {
  }
}
