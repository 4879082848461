import { FormHelper } from '../../../ui/controls/form-helper';
import { Validators } from '@angular/forms';
import { AppFormGroup } from '../../../ui/controls/app-form-group';
var EditExpertiseIndicatorsComponent = /** @class */ (function () {
    function EditExpertiseIndicatorsComponent() {
    }
    EditExpertiseIndicatorsComponent.getIndicatorGroupDef = function (fb, serviceData) {
        return fb.group({
            expertiseId: serviceData.expertiseId,
            indicatorTypeId: serviceData.indicatorTypeId,
            indicatorTypeCaption: serviceData.indicatorTypeCaption,
            value: [
                serviceData.value,
                Validators.compose([
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) {
                        return ctrl && ctrl.parent && (+ctrl.parent.get('expertiseResultType').value > 0 || ctrl.parent.get('conclusion').value);
                    })
                ])
            ],
            expertiseResultType: [
                serviceData.expertiseResultType,
                Validators.compose([Validators.required])
            ],
            conclusion: [
                serviceData.conclusion,
                Validators.compose([
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) {
                        return ctrl && ctrl.parent && (ctrl.parent.get('value').value || +ctrl.parent.get('expertiseResultType').value > 0);
                    })
                ]),
            ],
            mercuryNumber: serviceData.mercuryNumber,
            syncErrorText: serviceData.syncErrorText,
            needSync: serviceData.needSync || false,
            indicatorTypeLegalActCaption: serviceData.indicatorTypeLegalActCaption,
            indicatorTypeMethodCaption: serviceData.indicatorTypeMethodCaption,
            indicatorTypeNormativeValueCaption: serviceData.indicatorTypeNormativeValueCaption,
        });
    };
    EditExpertiseIndicatorsComponent.buildFormArrayDef = function (fb, serverSideErrorsProvider) {
        return {
            indicators: fb.array([])
        };
    };
    Object.defineProperty(EditExpertiseIndicatorsComponent.prototype, "indicators", {
        get: function () {
            return this.contextFormGroup.get('indicators');
        },
        enumerable: true,
        configurable: true
    });
    EditExpertiseIndicatorsComponent.prototype.isInvalid = function (fg, cname) {
        return FormHelper.isInvalid(fg, cname);
    };
    EditExpertiseIndicatorsComponent.prototype.getRowColor = function (item) {
        if (+item.get('expertiseResultType').value == 1) {
            return 'green';
        }
        else if (+item.get('expertiseResultType').value == 2) {
            return 'red';
        }
        else if (+item.get('expertiseResultType').value == 3) {
            return 'blue';
        }
    };
    return EditExpertiseIndicatorsComponent;
}());
export { EditExpertiseIndicatorsComponent };
