import { Component, OnDestroy, OnInit } from '@angular/core';
import { QueryService } from '../../logic/services/query.service';
import { DataCachingService } from '../../logic/services/data-caching.service';
import { AppNavigationService } from '../../logic/services/app-navigation.service';
import { ActivatedRoute } from '@angular/router';
import { QueryRunnerUiService } from './helpers/query-runner-ui.service';
import { BookmarkService } from '../../logic/services/bookmark.service';
import { FormHelper } from '../../ui/controls/form-helper';

@Component({
  selector: 'app-query-standalone-results',
  templateUrl: './query-standalone-results.component.html',
  styleUrls: ['./query.all.css']
})
export class QueryStandaloneResultsComponent implements OnInit, OnDestroy {

  public model: any;
  public id: number;

  private gridOptions: any;
  private shouldRestoreState: boolean;

  private static createDefaultModel(): any {
    return {
      data: [],
      dataColumns: [],
      entityType: undefined,
      query: undefined,
      condition: undefined
    };
  }

  constructor(private dataCachingService: DataCachingService,
              private route: ActivatedRoute,
              private queryService: QueryService,
              private appNavigationService: AppNavigationService,
              private bookmarkService: BookmarkService,
              public queryRunnerUi: QueryRunnerUiService) {
  }

  ngOnInit() {

    this.route.paramMap
      .subscribe(params => {
        this.id = parseInt(params.get('id'), 10);

        const existing = this.dataCachingService.getCachedData('QueryStandaloneResultsComponent', this.id.toString());

        this.bookmarkService.refreshQueryViewBookmark(this.id, this.appNavigationService.getFavoriteQueryCaption(this.id));

        if (existing) {
          this.model = existing;
          this.shouldRestoreState = true;
        } else {
          this.queryService.byId(this.id).subscribe(query => {
            this.model = QueryStandaloneResultsComponent.createDefaultModel();
            this.model.query = {
              data: query,
              conditions: this.queryService.buildConditionsForEdit(query.conditions, query.entityType)
            };
            this.model.entityType = {id: query.entityType};
            this.dataCachingService.addToCache('QueryStandaloneResultsComponent', this.id.toString(), this.model);
            this.refreshFormData();
          });
        }
      });
  }

  ngOnDestroy(): void {
    FormHelper.persistAgGridState(this.gridOptions, this.model, 'agGridState');
    this.gridOptions = undefined;
  }

  agGridReady(gridOptions: any) {
    this.gridOptions = gridOptions;
    if (this.shouldRestoreState) {
      this.shouldRestoreState = false;
      FormHelper.restoreAgGridState(this.gridOptions, this.model, 'agGridState');
    }
  }

  refreshFormData() {
    FormHelper.persistAgGridState(this.gridOptions, this.model, 'agGridState');
    this.model.query.results = undefined;
    this.shouldRestoreState = true;
    setTimeout(() => {
      this.refreshFormDataInternal();
    });
  }

  refreshFormDataInternal() {

    if (this.model.query.results) {
      return;
    }

    this.queryRunnerUi.runQuery(this.model, this.model.entityType.id);
  }
}
