import { Component, Input } from '@angular/core';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstitutionDataService } from '../../../logic/services/institution-data.service';
import { FormHelper } from '../../../ui/controls/form-helper';
import { ServerSideErrorsProvider } from '../../../logic/validators/server-side-errors-provider';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';
import { EditInstitutionCommonFormComponent } from './edit-institution-common-form.component';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { AppFormGroup } from '../../../ui/controls/app-form-group';

@Component({
  selector: 'app-edit-institution-common',
  templateUrl: './edit-institution-common.component.html'
})
export class EditInstitutionCommonComponent implements ServerSideErrorsProvider {

  private _id: number;

  @Input()
  get id(): number {
    return this._id;
  }

  set id(id: number) {
    if (this._id !== id) {
      this._id = id;
      this.refreshFormDataInternal();
    }
  }

  @Input() model: any = {};
  storing = false;
  serverSideValidationErrors: any[];

  get contextFormGroup(): AppFormGroup {
    return this.model ? this.model.contextFormGroup : undefined;
  }

  constructor(private bookmarkService: BookmarkService,
              private institutionDataService: InstitutionDataService,
              private alertService: AlertService,
              private cache: AddressPersonFioCacheService,
              private dataCachingService: DataCachingService,
              private waitingOverlay: GlobalWaitingOverlayService,
              private fb: FormBuilder,
              public navigationService: AppNavigationService) {
  }

  store() {
    FormHelper.markAsSubmitted(this.contextFormGroup);

    if (!this.contextFormGroup.valid) {
      return;
    }

    this.storing = true;

    this.institutionDataService.storeInstitutionCommonInfo(this.contextFormGroup.value).subscribe(val => {
      this.storing = false;
      this.cache.invalidateInstitution(this.id);
      this.bookmarkService.refreshInstitutionCardBookmark(this.id);
      this.contextFormGroup.markAsUntouched();
      this.contextFormGroup.markAsPristine();
      this.alertService.success(`Общая информация для [${this.contextFormGroup.get('shortCaption').value}] успешно сохранена`);
    }, error => {
      this.storing = false;
      FormHelper.setSingleFormGroupServerSideValidationErrors(error, this.model, this.contextFormGroup);
    });
  }

  isInvalid(fg: FormGroup, cname: string) {
    return FormHelper.isInvalid(fg, cname);
  }

  cancelEdit() {
    this.dataCachingService.removeCachedData('EditInstitutionCommon', this.id.toString());
    this.refreshFormDataInternal();
  }

  refreshFormData() {
    this.dataCachingService.removeCachedData('EditInstitutionCommon', this.id.toString());
    this.refreshFormDataInternal();
  }

  refreshFormDataInternal() {
    this.waitingOverlay.StartWaiting();

    this.model = {id: this.id, serverSideValidationErrors: []};

    const existing = this.dataCachingService.getCachedData('EditInstitutionCommon', this.id.toString());

    if (existing) {
      this.model = existing;
      this.waitingOverlay.EndWaiting();
    } else {
      this.institutionDataService.getInstitutionCommonForEdit(this.id).subscribe(data => {
        this.model.id = data.id;
        this.model.contextFormGroup = EditInstitutionCommonFormComponent.createFormGroup(this.fb, this.model, data);
        this.dataCachingService.addToCache('EditInstitutionCommon', this.id.toString(), this.model);
        this.waitingOverlay.EndWaiting();
      }, () => {
        this.waitingOverlay.EndWaiting();
      });
    }
  }

  deleteInstitution() {
    this.alertService.confirmModal('Вы уверены, что хотите удалить безвозвратно карточку учреждения?').subscribe(() => {
      this.institutionDataService.deleteInstitution(this.id).subscribe(() => {
        this.dataCachingService.removeCachedData('EditInstitutionCommon', this.id.toString());
        this.bookmarkService.removeOpenCardBookmark(['/institution-edit'], this.id.toString());
        this.cache.invalidateInstitution(this.id);
      }, error => {
        FormHelper.setSingleFormGroupServerSideValidationErrorsWithRelationMsgKey(error, this.model, this.model.contextFormGroup);
      });
    });
  }
}
