import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstitutionDataService } from '../../../logic/services/institution-data.service';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { SecurityService } from '../../../logic/services/security.service';

@Component({
  selector: 'app-institution-employee-modal',
  templateUrl: './institution-employee-modal.component.html'
})
export class InstitutionEmployeeModalComponent {

  @Input() addEmployeeModalOpened = false;
  @Output() addEmployeeModalOpenedChanged = new EventEmitter();
  @Input() model: any;
  @Input() currentFormGroup: FormGroup;

  tabActive = {
    commonTabActive: true,
    accessTabActive: false,
    mercuryTabActive: false,
    mobileTabActive: false,
  };

  private _currentEmployee: any;
  @Input()
  get currentEmployee(): any {
    return this._currentEmployee;
  }

  set currentEmployee(employee) {
    if (this._currentEmployee !== employee) {
      this._currentEmployee = employee;
    }
  }

  @Input() currentIsNew = false;
  services: any[];
  @Input() id: number;

  @Input() employees: any[];

  mercuryAuthInfo;
  mercuryLoading = true;

  device: FormGroup;
  deviceLoading = true;
  deviceStoring = false;
  deviceDeleting = false;
  deviceHasPinCode = false;

  constructor(private readonly institutionDataService: InstitutionDataService,
              private readonly fb: FormBuilder,
              private readonly alertService: AlertService,
              private readonly userService: SecurityService) {
  }

  isInvalid(fg: FormGroup, cname: string) {
    return FormHelper.isInvalid(fg, cname);
  }

  addEditEmployeeCompleted() {
    if (this.tabActive.mercuryTabActive) {
      return;
    }

    if (!this.currentFormGroup.valid) {
      FormHelper.markAsSubmitted(this.currentFormGroup);
      return;
    }

    if (this.currentIsNew) {
      this.employees.push(this.currentFormGroup.value);
    } else {

      Object.assign(this.currentEmployee, this.currentFormGroup.value);
    }

    this.model.dirty = true;
    this.addEmployeeModalOpened = false;
    this.addEmployeeModalOpenedChanged.next();
  }

  addEditEmployeeCancelled() {
    this.addEmployeeModalOpened = false;
    this.addEmployeeModalOpenedChanged.next();
  }

  storeMercuryAuthInfo() {
    if (!this.tabActive.mercuryTabActive) {
      return;
    }
    if (!this.mercuryAuthInfo.valid) {
      FormHelper.markAsSubmitted(this.mercuryAuthInfo);
      return;
    }
    this.institutionDataService.storeEmployeeMercuryAuthInfo(this.id, this.currentFormGroup.get('id').value, this.mercuryAuthInfo.value)
      .subscribe(() => this.getMercuryAuthInfo());
  }

  deleteEmployee(employee: any) {
    if (employee.id) {
      this.model.delete.push(employee.id);
    }

    const index = this.employees.findIndex(item => item === employee);
    this.employees.splice(index, 1);
    this.model.dirty = true;
    this.addEmployeeModalOpened = false;
    this.addEmployeeModalOpenedChanged.next();
  }

  regionIdCityIdChanged(event: any) {
    setTimeout(() => {
      const matches = this.currentFormGroup.value.regionIdCityId
        ? this.currentFormGroup.value.regionIdCityId.match(/(\d+)-(\d+)/)
        : undefined;

      this.currentFormGroup.get('defaultRegionId').setValue(matches ? matches[1] : undefined);
      this.currentFormGroup.get('defaultCityId').setValue(matches ? matches[2] : undefined);
    }, 300);
  }

  getMercuryAuthInfo() {
    if (this.tabActive.mercuryTabActive && this.currentFormGroup.get('id').value) {
      this.mercuryLoading = true;

      this.institutionDataService.getEmployeeMercuryAuthInfo(this.id, this.currentFormGroup.get('id').value)
        .subscribe(info => {
            this.mercuryAuthInfo = this.fb.group({
              login: [info.login, Validators.required],
              password: [info.password, Validators.required],
              hasLogin: info.hasLogin,
              hasPassword: info.hasPassword,
            });

            this.mercuryLoading = false;
          },
          () => this.mercuryLoading = false);
    }
  }

  getEmployeeDevice() {
    if (this.tabActive.mobileTabActive && this.currentFormGroup.get('id').value) {
      this.deviceLoading = true;

      this.institutionDataService.getEmployeeDevice(this.id, this.currentFormGroup.get('id').value)
        .subscribe(info => {
            if (!info) {
              this.device = null;
            } else {
              this.device = this.fb.group({
                uid: [info.uid],
                uniqueUserId: [info.uniqueUserId],
                deviceInfo: [{ value: info.deviceInfo, disabled: true }],
                registrationRequestTime: [{ value: info.registrationRequestTime, disabled: true }],
                lastAccessTime: [{ value: info.lastAccessTime, disabled: true }],
                pinCode: [info.pinCode, Validators.compose([
                  Validators.required,
                  Validators.pattern('\\d+'),
                  Validators.minLength(4),
                ])],
              });

              this.deviceHasPinCode = !!this.device.value.pinCode;
            }

            this.deviceLoading = false;
          },
          () => this.deviceLoading = false);
    }
  }

  storeEmployeeDevice() {
    if (!this.tabActive.mobileTabActive) {
      return;
    }
    if (!this.device.valid) {
      FormHelper.markAsSubmitted(this.device);
      return;
    }

    this.deviceStoring = true;

    this.institutionDataService.acceptEmployeeDevice(this.device.value)
      .subscribe(() => {
          this.deviceStoring = false;
          this.device.markAsPristine();
          this.alertService.success('PIN-код успешно установлен');
        },
        () => this.deviceStoring = false);
  }

  deleteEmployeeDevice() {
    if (!this.tabActive.mobileTabActive || !this.device) {
      return;
    }

    this.alertService.confirmModal('Вы действительно хотите удалить устройство?')
      .subscribe(() => {
        this.deviceDeleting = true;

        this.institutionDataService.deleteEmployeeDevice(this.device.value)
          .subscribe(() => this.deviceDeleting = false, () => this.deviceDeleting = false);

        this.device = undefined;
      });
  }
}
