import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { MetadataService } from '../../logic/services/metadata.service';
import { StringHelper } from '../../helpers/string-helper';
import { FormHelper } from '../../ui/controls/form-helper';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SecurityService } from '../../logic/services/security.service';
import { LookupSourceService } from '../../logic/services/lookup-source.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-mercury-sync-status-signpost',
  templateUrl: './mercury-sync-status-signpost.component.html'
})
export class AgentSyncStatusSignpostComponent implements OnChanges {

  @Input() syncStates: any[] = [];
  @Output() syncStateUpdated = new EventEmitter<any>();
  @Output() editStarted = new EventEmitter<boolean>();

  availableConversationStages: any[];
  syncStateForUpdate: FormGroup;

  frontSyncState;
  equalsSome = FormHelper.equalsSome;

  constructor(public metadataService: MetadataService,
              public securityService: SecurityService,
              public lookupSourceService: LookupSourceService,
              private fb: FormBuilder) {
  }

  public buildSyncState(item: any) {
      this.syncStateForUpdate = this.fb.group({
        targetObjId: item.targetObjId,
        conversationStageId: item.conversationStageId,
        conversationTypeId: item.conversationTypeId,
        mercuryExchangeLastResult: item.mercuryExchangeLastResult,
        mercuryApplicationUuid: item.mercuryApplicationUuid
      });
  }

  ngOnChanges() {
    if (!this.syncStates) {
      return;
    }
    this.syncStates = this.syncStates.filter(x => x && !!x.conversationTypeId);
    if (!this.syncStates.length) {
      return;
    }
    this.initFrontSyncState();
  }

  initFrontSyncState() {
    if (this.findAndInitSyncStateByStatus([2, 3, 4])) {
      return;
    }
    if (this.findAndInitSyncStateByStatus([-1])) {
      return;
    }
    if (this.findAndInitSyncStateByStatus([-2, 1])) {
      return;
    }
    this.findAndInitSyncStateByStatus([0]);
  }

  findAndInitSyncStateByStatus(status = []): boolean {
    this.frontSyncState = this.syncStates.find(x => this.equalsSome(+x.mercuryExchangeStatus, ...status));
    return !!this.frontSyncState;
  }

  forceSyncNextTime(state: any) {
    state.mercuryExchangeNextTime = StringHelper.getISODate(new Date());
    this.metadataService.forceMercuryNextTimeSync(state.targetObjId, state.conversationTypeId)
      .subscribe(() => {
      });
  }

  isMercuryExchangeNextTimeInFuture(state: any) {
    return state &&
      ((+state.mercuryExchangeStatus === -1 && !state.mercuryExchangeNextTime) ||
      (state.mercuryExchangeNextTime && state.mercuryExchangeNextTime > StringHelper.getISODate(new Date())));
  }

  updateSyncState(syncState: any) {
    syncState.isEditStarted = false;
    this.metadataService.updateConversation(this.syncStateForUpdate.value)
      .subscribe(() => {
        this.syncStateUpdated.emit();
    });
  }

  clearMercuryApplicationUuid() {
    this.syncStateForUpdate.get('mercuryApplicationUuid').setValue(null);
  }

  cancelEditSyncState(state: any) {
    state.isEditStarted = false;
    this.syncStateForUpdate = undefined;
    this.editStarted.emit(false);
  }

  startEditSyncState(state: any) {
    if (!this.syncStateForUpdate) {
      this.buildSyncState(state);
    }

    if (!FormHelper.equalsSome(+state.conversationTypeId, -1, -2)) {
      this.lookupSourceService.getLookup('mercury-conversation-stages/' + (state.lookupStage || state.conversationTypeId))
        .subscribe(items => {
          this.availableConversationStages = items;
          const find = this.availableConversationStages.find(item => item.id === 0);
          if (!find) {
            this.availableConversationStages.push({'id': 0, 'caption': 'Завершено'});
          }
        });
    }

    state.isEditStarted = !state.isEditStarted;

    this.syncStates.forEach(syncState => {
      if (syncState.conversationTypeId !== state.conversationTypeId) {
        syncState.isEditStarted = false;
      }
    });

    this.editStarted.emit(true);
  }

  tryDownloadLogs(state: any) {
    if (!state) {
      return;
    }

    this.metadataService.tryDownloadLogsConversation(state)
      .subscribe(fileHash => window.open(environment.api + '/files/get?preloadId=' + encodeURIComponent(fileHash)));
  }
}
