import * as tslib_1 from "tslib";
import { FormBuilder } from '@angular/forms';
import { FormHelper } from '../../ui/controls/form-helper';
import { MetadataService } from '../../logic/services/metadata.service';
import { GlobalWaitingOverlayService } from '../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../logic/services/lookup-source.service';
import { AgentSearchModalComponent } from '../edit-agent/search/agent-search-modal.component';
import { AppNavigationService } from '../../logic/services/app-navigation.service';
import { AlertService } from '../../ui/infrastructure/alert.service';
import { AddressPersonFioCacheService } from '../../logic/services/address-person-fio-cache.service';
import { BookmarkService } from '../../logic/services/bookmark.service';
import { MetaBrowseBaseComponent } from '../dictionary/meta-browse.base.component';
import { ActivatedRoute } from '@angular/router';
import { DataCachingService } from '../../logic/services/data-caching.service';
var AppDistributionAgentAvailablePinsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AppDistributionAgentAvailablePinsComponent, _super);
    function AppDistributionAgentAvailablePinsComponent(route, metadataService, alertService, globalWaitingOverlayService, lookupService, bookmarkService, fb, personFioCacheService, navigationService, dataCachingService) {
        var _this = _super.call(this, route, metadataService, alertService, globalWaitingOverlayService, lookupService, fb, bookmarkService, dataCachingService) || this;
        _this.route = route;
        _this.metadataService = metadataService;
        _this.alertService = alertService;
        _this.globalWaitingOverlayService = globalWaitingOverlayService;
        _this.lookupService = lookupService;
        _this.bookmarkService = bookmarkService;
        _this.fb = fb;
        _this.personFioCacheService = personFioCacheService;
        _this.navigationService = navigationService;
        _this._searchResults = [];
        _this.currentIsNew = false;
        _this.editModalOpened = false;
        _this.isInvalid = FormHelper.isInvalid;
        _this.gridColumnDefs = [
            { headerName: 'Хозяйствующий субъект', field: 'agentCaption', tooltipField: 'agentCaption', width: 300 },
            { headerName: 'Номенклатура', field: 'nomenclatureCaption', tooltipField: 'nomenclatureCaption', width: 300 },
            { headerName: 'Номер с', field: 'numberFrom', width: 100 },
            { headerName: 'Номер по', field: 'numberTo', width: 100 },
        ];
        return _this;
    }
    AppDistributionAgentAvailablePinsComponent.prototype.refreshResults = function () {
        var _this = this;
        this.globalWaitingOverlayService.StartWaiting();
        this.lookupService.getLookupObj('nomenclature-animal-pin')
            .subscribe(function (nomenclatureLookup) {
            _this.metadataService.getMetadata(_this.getMetaName()).subscribe({
                next: function (data) {
                    if (!data || !data.length) {
                        _this._searchResults = [];
                        _this.globalWaitingOverlayService.EndWaiting();
                        return;
                    }
                    var _loop_1 = function (i) {
                        var el = data[i];
                        el.nomenclatureCaption = nomenclatureLookup['Obj' + el.nomenclatureAnimalPinId].caption;
                        _this.personFioCacheService.getAgent(el.agentId)
                            .subscribe(function (agent) {
                            el.agentCaption = agent['shortCaption'];
                            if (i === data.length - 1) {
                                _this._searchResults = data;
                                _this.lookupService.invalidateLookup(_this.getMetaName());
                                _this.globalWaitingOverlayService.EndWaiting();
                            }
                        });
                    };
                    for (var i = 0; i < data.length; i++) {
                        _loop_1(i);
                    }
                }, error: function () {
                    _this.globalWaitingOverlayService.EndWaiting();
                }
            });
        });
    };
    AppDistributionAgentAvailablePinsComponent.prototype.editRow = function (row) {
        var _this = this;
        this.currentFormGroup = this.fb.group(this.getGroupDef(row));
        this.currentIsNew = !this.currentFormGroup.get('id').value;
        if (!this.currentIsNew) {
            this.metadataService.getMetadataSingle(this.getMetaName(), this.currentFormGroup.get('id').value)
                .subscribe(function (detailedRec) {
                _this.currentFormGroup = _this.fb.group(_this.getGroupDef(detailedRec));
                _this.editModalOpened = true;
            });
        }
        else {
            this.editModalOpened = true;
        }
    };
    AppDistributionAgentAvailablePinsComponent.prototype.getGroupDef = function (row) {
        return {
            id: row.id,
            agentId: row.agentId,
            nomenclatureAnimalPinId: row.nomenclatureAnimalPinId,
            numberFrom: row.numberFrom,
            numberTo: row.numberTo,
        };
    };
    AppDistributionAgentAvailablePinsComponent.prototype.searchAgent = function () {
        var _this = this;
        this.navigationService.searchAgent(AgentSearchModalComponent).subscribe(function (val) {
            if (val) {
                _this.currentFormGroup.get('agentId').setValue(val);
                _this.currentFormGroup.markAsDirty();
            }
        });
    };
    AppDistributionAgentAvailablePinsComponent.prototype.getMetaName = function () {
        return 'agent-available-pins';
    };
    AppDistributionAgentAvailablePinsComponent.prototype.getMetaTitle = function () {
        return 'Распределение бирок животных';
    };
    return AppDistributionAgentAvailablePinsComponent;
}(MetaBrowseBaseComponent));
export { AppDistributionAgentAvailablePinsComponent };
