import { Component, Input, OnChanges } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LookupSourceService } from '../../logic/services/lookup-source.service';
import { FormArray, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-checkbox-select-with-parent',
  template: `
      <div class="form-group itech-group-whole-line">
      <label>
      </label>
      <div *ngFor="let parent of (lookupParent() | async)"
           [style.width]="parent.expanded ? '100%' : undefined">
          <button type="button" class="btn btn-outline"
                  [disabled]="disabled"
                  style="background:none;border:none;margin:0;text-transform:none;color:black;letter-spacing:normal"
                  (click)="parent.expanded = !parent.expanded">
              <span style="font-size:11px" [style.color]="selectedIds[parent.id] && selectedIds[parent.id].length ? 'blue' : undefined">
                {{ parent.caption }}
                <span *ngIf="selectedIds[parent.id] && selectedIds[parent.id].length">
                  ({{selectedIds[parent.id].length}})
                </span>
              </span>
              <clr-icon *ngIf="parent.expanded" shape="angle up"></clr-icon>
              <clr-icon *ngIf="!parent.expanded" shape="angle down"></clr-icon>
          </button>
          <div *ngIf="parent.expanded">
              <app-checkbox-select [lookupName]="lookupName + parent.id" [contextControlName]="contextControlName"
                                   [contextFormGroup]="contextFormGroup" [sorted]="true" [nameId]="nameId"
                                   [disabled]="disabled">
              </app-checkbox-select>
          </div>
      </div>
  </div>`
})
export class AppCheckboxSelectWithParentComponent implements OnChanges {
  @Input() contextFormGroup: FormGroup;
  @Input() contextControlName;
  @Input() lookupName;
  @Input() lookupNameParent;
  @Input() nameId = 'uniqueId';
  @Input() disabled = false;

  selectedIds = {};

  parentLookupItems$ = undefined;

  constructor(private lookupService: LookupSourceService) {
  }

  ngOnChanges(): void {
    if (!this.lookupName || !this.contextFormGroup) {
      return;
    }

    this.selectedIds = {};
    (this.contextFormGroup.get(this.contextControlName) as FormArray)
      .getRawValue().forEach(id => this.updateSelectedIds(id ? id.toString() : null));

    // прогрузим справочник заранее
    this.lookupService.getLookup(this.lookupName).toPromise().then();

    // при каждой отметке/снятии будем изменять наше описание о количестве выделенных элементов
    this.contextFormGroup.get(this.contextControlName).valueChanges.subscribe(stringIds => {
      this.selectedIds = {};
      (stringIds as any[]).forEach(id => this.updateSelectedIds(id ? id.toString() : null));
    });
  }

  private updateSelectedIds(stringId: string) {
    if (!stringId) {
      return;
    }
    const parentId = +(stringId.substring(0, stringId.length - 9));
    const id = +(stringId.substring(stringId.length - 9, stringId.length));

    if (!this.selectedIds[parentId]) {
      this.selectedIds[parentId] = [];
    }

    if (!(this.selectedIds[parentId] as any[]).includes(id)) {
      (this.selectedIds[parentId] as any[]).push(id);
    }
  }

  public lookupParent(): Observable<any[]> {

    if (this.parentLookupItems$) {
      return this.parentLookupItems$;
    } else {
      this.parentLookupItems$ = new BehaviorSubject([]);
      this.lookupService.getLookup(this.lookupNameParent)
        .subscribe(response => response.forEach(el => {
          el.expanded = false;
          response.sort((a, b) => a.caption.localeCompare(b.caption));
          this.parentLookupItems$.next(response);
        }));
      return this.parentLookupItems$;
    }
  }
}
