
    <ng-container [formGroup]="contextFormGroup"  *ngIf="contextFormGroup
    && (!queryUserMode || queryUserMode['_par_' + parameterName + '_filter_mq'])">
      <label [style.background]="filtered?'linear-gradient(to right, #90ff90, white)':undefined">
        <ng-content></ng-content>
        <div class="checkbox checkbox-inline" title="Значение отсутствует (не указано)" *ngIf="!queryUserMode">
          <input type="checkbox" id="{{'_par_' + parameterName + '_filter_novalue'}}" [(ngModel)]="noValueChecked"
                 [ngModelOptions]="{standalone: true}">
          <label for="{{'_par_' + parameterName + '_filter_novalue'}}">X</label>
        </div>
      </label>
      <ng-container style="flex: 1000 1 auto">
        <ng-container *ngIf="this.parameterName.includes('agent_id')">
          <app-link-button [id]="contextFormGroup.controls['_par_' + parameterName].value" [lookupName]="getLookupName()"></app-link-button>
          <button *ngIf="contextFormGroup.controls['_par_' + parameterName].value" type="button"
                  (click)="clearValue()"
                  class="btn btn-danger btn-link itech-inline-tool-btn" title="Очистить">
            <clr-icon shape="times"></clr-icon>
          </button>
          <button *ngIf="!contextFormGroup.controls['_par_' + parameterName].value" type="button" (click)="search()"
                  class="btn btn-danger btn-link itech-inline-tool-btn" title="Выбрать">
            <clr-icon shape="search"></clr-icon>
          </button>
        </ng-container>

        <ng-container *ngIf="parameterName.includes('stable_id')">
          <ng-container *ngIf="!contextFormGroup.get('_par_' + parameterName).value.length">
            <button type="button" (click)="search()"
                    class="btn btn-danger btn-link itech-inline-tool-btn" title="Выбрать">
              <clr-icon shape="search"></clr-icon>
            </button>
          </ng-container>
          <ng-container *ngIf="contextFormGroup.get('_par_' + parameterName).value.length">
            <ng-container *ngFor="let stable of contextFormGroup.controls['_par_' + parameterName].value; index as i">
              <app-link-button [id]="contextFormGroup.controls['_par_' + parameterName].value[i]" [lookupName]="getLookupName()">
              </app-link-button>
              <button *ngIf="contextFormGroup.controls['_par_' + parameterName].value" type="button"
                      (click)="clearValue(i)"
                      class="btn btn-danger btn-link itech-inline-tool-btn" title="Очистить">
                <clr-icon shape="times"></clr-icon>
              </button>
              <button *ngIf="contextFormGroup.get('_par_' + parameterName).value.length == (i + 1)" type="button" (click)="search()"
                      class="btn btn-danger btn-link itech-inline-tool-btn" title="Выбрать">
                <clr-icon shape="search"></clr-icon>
              </button>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  