import { Pipe, PipeTransform } from '@angular/core';
import { LookupSourceService } from '../services/lookup-source.service';
import { AddressPersonFioCacheService } from '../services/address-person-fio-cache.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AppConstants } from '../../app-constants';
import { map } from 'rxjs/internal/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { FormHelper } from '../../ui/controls/form-helper';

@Pipe({name: 'lookup'})
export class LookupSourcePipe implements PipeTransform {
  constructor(private lookupSourceService: LookupSourceService,
              private personFioCacheService: AddressPersonFioCacheService,
              private sanitizer: DomSanitizer) {

  }

  public static getLookupObjectFromCache(value: any, lookupName: string, useShort: boolean,
                                         lookupSourceService: LookupSourceService,
                                         personFioCacheService: AddressPersonFioCacheService): Observable<any> {
    if (!value && value !== false && value !== 0) {
      return new BehaviorSubject<any>({id: 0, caption: '-'});
    }

    if (lookupName.toLowerCase() === 'address') {
      if (value === -2) {
        return new BehaviorSubject<any>({id: -2, caption: AppConstants.HIDDEN_FIELD_TEXT});
      } else {
        return personFioCacheService.getAddress(value).pipe(map(val => {
          return { id: val.id, caption: val.caption };
        }));
      }
    } else if (lookupName.toLowerCase() === 'stable') {
      return personFioCacheService.getStable(value).pipe(map(val => {
        return { id: val.id, caption: (useShort ? (val.shortCaption || val.caption) : val.caption) };
      }));
    } else if (lookupName.toLowerCase() === 'pst-report') {
      return personFioCacheService.getPstReport(value).pipe(map(val => {
        return { id: val.id, caption: (useShort ? (val.shortCaption || val.caption) : val.caption) };
      }));
    } else if (lookupName.toLowerCase() === 'agent') {
      return personFioCacheService.getAgent(value).pipe(map(val => {
        return { id: val.id, caption: (useShort ? (val.shortCaption || val.caption) : val.caption) };
      }));
    } else if (lookupName.toLowerCase() === 'event') {
      return personFioCacheService.getAnimalEvent(value).pipe(map(val => {
        return { id: val.id, caption: (useShort ? (val.shortCaption || val.caption) : val.caption) };
      }));
    } else if (lookupName.toLowerCase() === 'animal') {
      return personFioCacheService.getAnimal(value).pipe(map(val => {
        return {
          id: val.id,
          caption: (useShort ? (val.shortCaption || val.caption) : val.caption),
          iconName: val['trappedToQuarantines'] ? 'flame' : undefined,
          iconColor: val['trappedToQuarantines'] ? 'red' : undefined,
        };
      }));
    } else if (lookupName.toLowerCase() === 'animal-external') {
      return personFioCacheService.getAnimalExternal(value).pipe(map(val => {
        return {
          id: val.id,
          caption: (useShort ? (val.shortCaption || val.caption) : val.caption),
        };
      }));
    } else if (lookupName.toLowerCase() === 'drug') {
      return personFioCacheService.getDrug(value).pipe(map(val => {
        return { id: val.id, caption: (useShort ? (val.shortCaption || val.caption) : val.caption) };
      }));
    } else if (lookupName.toLowerCase() === 'in-drug-unit-type') {
      return personFioCacheService.getDrug(value).pipe(map(val => {
        return { id: val.id, caption: val.unitTypeCaption };
      }));
    } else if (lookupName.toLowerCase() === 'product') {
      return personFioCacheService.getProduct(value).pipe(map(val => {
        return {
          id: val.id,
          caption: (useShort ? (val.shortCaption || val.caption) : val.caption),
          comment: val.comments,
          iconName: val.transactionType && FormHelper.equalsSome(+val.transactionType, 1, 2, 3, 4)
            ? +val.transactionType === 4 ? 'recycle' : 'truck'
            : undefined,
          iconName2: val.isExpiredDate ? 'history' : undefined,
          iconColor2: 'red',
        };
      }));
    } else if (lookupName.toLowerCase() === 'product-expertise') {
      return personFioCacheService.getProductExpertise(value).pipe(map(val => {
        return { id: val.id, caption: (useShort ? (val.shortCaption || val.caption) : val.caption), productId: val.productId, };
      }));
    } else if (lookupName.toLowerCase() === 'product-transaction') {
      return of({ id: value, caption: 'транзакция #' + value });
    } else if (lookupName.toLowerCase() === 'institution') {
      return personFioCacheService.getInstitution(value).pipe(map(val => {
        return { id: val.id, caption: (useShort ? (val.shortCaption || val.caption) : val.caption) };
      }));
    } else if (lookupName.toLowerCase() === 'user') {
      return personFioCacheService.getUser(value).pipe(map(val => {
        return { id: val.id, caption: val.caption };
      }));
    } else if (lookupName.toLowerCase() === 'quarantine') {
      const result = new BehaviorSubject({});

      personFioCacheService.getQuarantineLookup(value).subscribe(val =>
        lookupSourceService.getLookupCaption(value, 'quarantine')
          .subscribe(caption => result.next({ id: val.data.id, caption: caption })));

      return result;
    } else {
      if (!useShort) {
        return lookupSourceService.getLookupObj(lookupName).pipe(map(lookup => {
          return { id: value, caption: lookup[value] || 'N/A' };
        }));
      } else {
        return lookupSourceService.getLookupObj(lookupName)
          .pipe(map(lookup => {
            return {
              id: value,
              caption: lookup['Obj' + value]
                ? (lookup['Obj' + value].shortCaption || lookup['Obj' + value].caption)
                : 'N/A'
            };
          }));
      }
    }
  }

  transform(value: any,
            lookupName: string,
            useShort?: boolean,
            isHtml?: boolean,
            showIcon?: boolean,
            isCommentInsteadCaption?: boolean,
  ): Observable<string> {
    return LookupSourcePipe.getLookupObjectFromCache(value, lookupName, useShort, this.lookupSourceService, this.personFioCacheService)
      .pipe(map(val => {
        return isHtml
          ? this.sanitizer.bypassSecurityTrustHtml(val.caption
            ? showIcon
              ? `${val.iconName
                ? `<clr-icon shape="${val.iconName}" ${val.iconColor ? 'style="color:' + val.iconColor + '"' : ''}></clr-icon>`
                : ''}
                ${val.iconName2
                ? `<clr-icon shape="${val.iconName2}" ${val.iconColor2 ? 'style="color:' + val.iconColor2 + '"' : ''}></clr-icon>`
                : ''}
                ${val.caption}`
              : isCommentInsteadCaption && val.comment ? val.comment : val.caption
            : '<span class="spinner spinner-sm" style="vertical-align:middle"></span>') as string
          : isCommentInsteadCaption && val.comment ? val.comment : val.caption;
      }));
  }
}
