import { Component } from '@angular/core';
import { MetaBrowseBaseComponent } from '../meta-browse.base.component';
import { ActivatedRoute } from '@angular/router';
import { MetadataService } from '../../../logic/services/metadata.service';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JobRunnerUiService } from '../../../ui/infrastructure/job-runner-ui.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { StringHelper } from '../../../helpers/string-helper';

@Component({
  templateUrl: './drug-manufacturer-browse.component.html'
})
export class DrugManufacturerBrowseComponent extends MetaBrowseBaseComponent {

  gridColumnDefs = [
    {headerName: '№', field: 'id', width: 100},
    {headerName: 'Наименование', field: 'caption', tooltipField: 'caption', width: 350},
  ];

  constructor(route: ActivatedRoute,
              metadataService: MetadataService,
              alertService: AlertService,
              globalWaitingOverlayService: GlobalWaitingOverlayService,
              lookupService: LookupSourceService,
              bookmarkService: BookmarkService,
              fb: FormBuilder,
              private jobRunnerUiService: JobRunnerUiService,
              dataCachingService: DataCachingService) {
    super(route, metadataService, alertService, globalWaitingOverlayService, lookupService, fb, bookmarkService, dataCachingService);
  }

  getMetaTitle(): string {
    return 'Производители препаратов';
  }

  getMetaName(): string {
    return 'drug-manufacturer';
  }

  getId(formGroup: FormGroup): any {
    return formGroup.get('id').value;
  }

  getGroupDef(row: any) {
    return {
      id: [row.id],
      caption: [row.caption, Validators.required],
    };
  }

  getRowStyle(params: any) {
    if (params.data && params.data.dateDeleted) {
      return {color: 'silver'};
    }
  }
}
