import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-branch-fed-header',
  templateUrl: './pst-branch-fed-header.component.html',
})
export class PstBranchFedHeaderComponent {
  @Input() branch: any;
  @Input() model: any;

}
