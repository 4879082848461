import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-combo-inline-multilookup',
  template: `
    <ng-container *ngFor="let item of boundArray.controls; index as i">
      <app-combo-lookup [contextControlId]="contextControlName + i"
                        [contextControlName]="i" [required]="false"
                        [lookupName]="lookupName" [sorted]="sorted" [disabled]="disabled"
                        [contextFormGroup]="boundArray"></app-combo-lookup>
      <ng-container *ngIf="!noControlsDivider">&nbsp;&nbsp;</ng-container>
    </ng-container>
    <app-combo-lookup [contextControlId]="contextControlName + 'N'"
                      [contextControlName]="'value'" [required]="false"
                      [lookupName]="lookupName" [sorted]="sorted" [disabled]="disabled"
                      [contextFormGroup]="emptyElementForm"></app-combo-lookup>
  `
})
export class AppComboInlineMultilookupComponent implements OnChanges {
  @Input() disabled;
  @Input() contextControlName;
  @Input() contextFormGroup;
  @Input() sorted = false;
  @Input() lookupName: string;
  @Input() noControlsDivider = false;

  emptyElementForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.emptyElementForm = fb.group({
      value: ''
    });

    this.emptyElementForm.valueChanges.subscribe(() => {
      if (this.emptyElementForm.get('value').value || this.emptyElementForm.get('value').value === 0) {
        setTimeout(() => {
          this.boundArray.push(this.fb.control(this.emptyElementForm.get('value').value));
          this.boundArray.markAsDirty();
          this.emptyElementForm.get('value').setValue(undefined);
        }, 100);
      }
    });
  }

  get boundArray(): FormArray {
    return this.contextFormGroup.get(this.contextControlName) as FormArray;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('contextFormGroup') || changes.hasOwnProperty('contextControlName')) {
      if (this.contextFormGroup && this.contextControlName) {

        this.boundArray.valueChanges.subscribe(() => {
          this.prettifyBoundArray();
        });
        this.prettifyBoundArray();
      }
    }
  }

  private prettifyBoundArray() {
    setTimeout(() => {
      let i = 0;
      while (i < this.boundArray.length) {
        if (!this.boundArray.controls[i].value && this.boundArray.controls[i].value !== 0) {
          this.boundArray.removeAt(i);
          this.boundArray.markAsDirty();
        } else {
          i++;
        }
      }
    }, 100);
  }
}
