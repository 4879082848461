import * as tslib_1 from "tslib";
import { QueryService } from '../../logic/services/query.service';
import { DataCachingService } from '../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../logic/services/lookup-source.service';
import { FormBuilder } from '@angular/forms';
import { AlertService } from '../../ui/infrastructure/alert.service';
import { FormHelper } from '../../ui/controls/form-helper';
import { QueryDetailsEntityComponent } from './query-details-entity.component';
var QueryDetailsDrugComponent = /** @class */ (function (_super) {
    tslib_1.__extends(QueryDetailsDrugComponent, _super);
    function QueryDetailsDrugComponent(queryService, dataCachingService, waitingOverlay, lookupSourceService, alertService, fb) {
        var _this = _super.call(this, queryService, dataCachingService, waitingOverlay, lookupSourceService, alertService, fb) || this;
        _this.EntityType = 8; // drug
        return _this;
    }
    QueryDetailsDrugComponent.getGroupDef = function (fb, condition) {
        return {
            type: 'drug',
            _par_created_institution: condition.data._par_created_institution,
            _par_created_institution_filter: condition.data._par_created_institution_filter || 0,
            _par_created_institution_employee: condition.data._par_created_institution_employee,
            _par_created_institution_employee_filter: condition.data._par_created_institution_employee_filter || 0,
            _par_created_time_filter: condition.data._par_created_time_filter || 0,
            _par_created_time_from: condition.data._par_created_time_from,
            _par_created_time_to: condition.data._par_created_time_to,
            _par_drug_type_ids_filter: condition.data._par_drug_type_ids_filter,
            _par_drug_type_ids: fb.array(condition.data._par_drug_type_ids || []),
            _par_drug_kind_ids_filter: condition.data._par_drug_kind_ids_filter,
            _par_drug_kind_ids: fb.array(condition.data._par_drug_kind_ids || []),
            _par_institution_id_filter: condition.data._par_institution_id_filter,
            _par_institution_id: condition.data._par_institution_id,
            _par_budget_type_id_filter: condition.data._par_budget_type_id_filter || 0,
            _par_budget_type_id: condition.data._par_budget_type_id,
            _par_manufacture_date_filter: condition.data._par_manufacture_date_filter || 0,
            _par_manufacture_date_from: [condition.data._par_manufacture_date_from, FormHelper.validateDateTimePicker()],
            _par_manufacture_date_to: [condition.data._par_manufacture_date_to, FormHelper.validateDateTimePicker()],
            _par_incoming_date_filter: condition.data._par_incoming_date_filter || 0,
            _par_incoming_date_from: [condition.data._par_incoming_date_from, FormHelper.validateDateTimePicker()],
            _par_incoming_date_to: [condition.data._par_incoming_date_to, FormHelper.validateDateTimePicker()],
            _par_move_date_filter: condition.data._par_move_date_filter || 0,
            _par_move_date_from: [condition.data._par_move_date_from, FormHelper.validateDateTimePicker()],
            _par_move_date_to: [condition.data._par_move_date_to, FormHelper.validateDateTimePicker()],
            _par_writeoff_date_filter: condition.data._par_writeoff_date_filter || 0,
            _par_writeoff_date_from: [condition.data._par_writeoff_date_from, FormHelper.validateDateTimePicker()],
            _par_writeoff_date_to: [condition.data._par_writeoff_date_to, FormHelper.validateDateTimePicker()],
            _par_using_date_filter: condition.data._par_using_date_filter || 0,
            _par_using_date_from: [condition.data._par_using_date_from, FormHelper.validateDateTimePicker()],
            _par_using_date_to: [condition.data._par_using_date_to, FormHelper.validateDateTimePicker()],
            _par_action_date_filter: condition.data._par_action_date_filter || 0,
            _par_action_date_from: [condition.data._par_action_date_from, FormHelper.validateDateTimePicker()],
            _par_action_date_to: [condition.data._par_action_date_to, FormHelper.validateDateTimePicker()],
        };
    };
    QueryDetailsDrugComponent.prototype.getGroupDef = function () {
        return QueryDetailsDrugComponent.getGroupDef(this.fb, this.model.condition);
    };
    return QueryDetailsDrugComponent;
}(QueryDetailsEntityComponent));
export { QueryDetailsDrugComponent };
