
    <ng-container [formGroup]="contextFormGroup" *ngIf="controlVisible()">
      <label style="height:1rem; max-width: 17rem; width: 17rem" 
             [style.background]="filtered?'linear-gradient(to right, #90ff90, white)':undefined">
        <ng-content></ng-content>
        :
      </label>
      <div class="radio-inline">
        <input type="radio" id="{{'_par_' + parameterName + '_filter0'}}"
               [formControlName]="'_par_' + parameterName + '_filter'" [value]="0">
        <label for="{{'_par_' + parameterName + '_filter0'}}">Не фильтровать</label>
      </div>
      <div class="radio-inline" title="Спросить у пользователя" *ngIf="!queryUserMode">
        <input type="radio" id="{{'_par_' + parameterName + '_filter108'}}"
               [formControlName]="'_par_' + parameterName + '_filter'" [value]="108">
        <label for="{{'_par_' + parameterName + '_filter108'}}">?</label>
      </div>
      <div class="radio-inline">
        <input type="radio" id="{{'_par_' + parameterName + '_filter1'}}"
               [formControlName]="'_par_' + parameterName + '_filter'" [value]="1">
        <label for="{{'_par_' + parameterName + '_filter1'}}">Отсутствует</label>
      </div>
      <div class="radio-inline">
        <input type="radio" id="{{'_par_' + parameterName + '_filter2'}}"
               [formControlName]="'_par_' + parameterName + '_filter'" [value]="2">
        <label for="{{'_par_' + parameterName + '_filter2'}}">Отмечен</label>
      </div>
      <div class="radio-inline">
        <input type="radio" id="{{'_par_' + parameterName + '_filter3'}}"
               [formControlName]="'_par_' + parameterName + '_filter'" [value]="3">
        <label for="{{'_par_' + parameterName + '_filter3'}}">Не отмечен</label>
      </div>
    </ng-container>
  