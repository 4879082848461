
    <div [class.app-checkbox-select-border]="!selectType" [style.padding.px]="selectType ? 0 : 5">
        <div *ngIf="!compact && !selectType" style="width:100%">
            <button type="button" class="btn btn-primary btn-link" (click)="selectAll()"
                    style="margin:0 10px 0 0;padding:0;font-size:10px">
                выбрать все
            </button>
            <button type="button" class="btn btn-primary btn-link" (click)="unselectAll()"
                    style="margin:0;padding:0;font-size:10px">
                снять все
            </button>
        </div>
        <ng-container *ngIf="!selectType; else selectTypeHtml">
            <div *ngFor="let item of items; index as i" class="checkbox itech-no-margins"
                 [class.disabled]="disabled">
                <input type="checkbox" id="check{{i}}_{{rndToken}}" [disabled]="disabled" [(ngModel)]="item.checked"
                       (change)="updateContextFormGroup(item)">
                <label for="check{{i}}_{{rndToken}}">{{useShort ? item.data.shortCaption || item.data.caption : item.data.caption}}</label>
            </div>
        </ng-container>
        <ng-template #selectTypeHtml>
            <label for="{{contextControlName}}" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md">
                <input autocomplete="off" #textInput type="text" id="{{contextControlName}}"
                       (focus)="onFocus()" (blur)="onBlur()" [class.disabled]="disabled" [attr.disabled]="disabled?true:undefined"/>
            </label>
            <div *ngIf="focusedSelectType" style="position: relative; top: 7px; left: -150px">
                <div class="itech-lookup-popup-container">
                    <div *ngFor="let item of items; index as i" (click)="updateContextFormGroup(item)"
                         class="itech-lookup-popup-element checkbox itech-no-margins" id="{{'ppp' + rndToken + '_' + i}}">
                        <input type="checkbox" id="check{{i}}_{{rndToken}}" [disabled]="disabled" [(ngModel)]="item.checked"
                               (change)="updateContextFormGroup(item)">
                        <label for="selectCheckboxes{{i}}_{{rndToken}}">
                            {{useShort ? item.data.shortCaption || item.data.caption : item.data.caption}}
                        </label>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
  