<div class="itech-content-header">
  <clr-icon shape="printer" size="36"></clr-icon>&nbsp;{{ReportMetadata.operationTitle}}
</div>

<div *ngIf="contextFormGroup" class="compact form" [formGroup]="contextFormGroup">

  <section class="form-block itech-block-medium itech-block-inline">

    <ng-container *ngIf="contextFormGroup.get('institutionId')">
      <div class="form-group">
        <label for="institutionId">Учреждение</label>
        <app-text-lookup class="itech-control-xlarge" [contextControlId]="'institutionId'" [sorted]="true"
                         [contextControlName]="'institutionId'" [lookupName]="'all-institution'" [required]="false"
                         [contextFormGroup]="contextFormGroup"
                         [disabled]="model.started ? true : undefined"></app-text-lookup>
      </div>
      <div class="form-group">
        <label></label>
        <div class="itech-control-desc">
          Укажите учреждение для подготовки отчета. Очистите поле, чтобы подготовить сводный отчет по
          всем учреждениям
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="contextFormGroup.get('regionId')">
      <div class="form-group">
        <label for="regionId">Мун. образование</label>
        <app-text-lookup class="itech-control-xlarge" [contextControlId]="'regionId'" [sorted]="true"
                         [contextControlName]="'regionId'" [lookupName]="'addr-region'" [required]="false"
                         [contextFormGroup]="contextFormGroup"
                         [disabled]="model.started ? true : undefined"></app-text-lookup>
      </div>
      <div class="form-group">
        <label></label>
        <div class="itech-control-desc">
          Укажите муниципальное образование для подготовки отчета. Очистите поле, чтобы подготовить сводный отчет по
          всем МО
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="contextFormGroup.get('dateReport')">
      <div class="form-group">
        <label class="required">Дата отчета</label>
        <label for="dateReport" aria-haspopup="true" role="tooltip"
               class="tooltip tooltip-validation tooltip-md itech-control-medium"
               [class.invalid]="isInvalid('dateReport')">
          <input type="date" id="dateReport" formControlName="dateReport" required
                 [attr.disabled]="model.started ? true : undefined">
          <app-validation-tooltip [input]="contextFormGroup.controls['dateReport']"></app-validation-tooltip>
        </label>
      </div>
      <div class="form-group">
        <label></label>
        <div class="itech-control-desc">
          Укажите дату, на которую формируется отчет (первое число месяца(квартала), следующего за отчетным). Отчет
          формируется
          нарастающим итогом с начала года.
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="contextFormGroup.get('dateFrom') || contextFormGroup.get('dateTo')">
      <div class="form-group">
        <label class="required">Дата с/по</label>
        <label *ngIf="contextFormGroup.get('dateFrom')"
               for="dateFrom" aria-haspopup="true" role="tooltip"
               class="tooltip tooltip-validation tooltip-md itech-control-medium"
               [class.invalid]="isInvalid('dateFrom')">
          <input type="date" id="dateFrom" formControlName="dateFrom" required
                 [attr.disabled]="model.started ? true : undefined">
          <app-validation-tooltip [input]="contextFormGroup.controls['dateFrom']"></app-validation-tooltip>
        </label>
        <label *ngIf="contextFormGroup.get('dateTo')"
               for="dateTo" aria-haspopup="true" role="tooltip"
               class="tooltip tooltip-validation tooltip-md itech-control-medium"
               [class.invalid]="isInvalid('dateTo')">
          <input type="date" id="dateTo" formControlName="dateTo" required
                 [attr.disabled]="model.started ? true : undefined">
          <app-validation-tooltip [input]="contextFormGroup.controls['dateTo']"></app-validation-tooltip>
        </label>
      </div>
      <div class="itech-control-desc">
        Укажите срок, за который формируется отчет. Дата "по" указывается "без включения", например, чтобы построить
        отчет за весь 2019 год, укажите 'дата с' = 01.01.2019, 'дата по' = 01.01.2020
      </div>
    </ng-container>

    <ng-container *ngIf="contextFormGroup.get('dateActual')">
      <div class="form-group">
        <label class="required">Дата создания</label>
        <label for="dateActual" aria-haspopup="true" role="tooltip"
               class="tooltip tooltip-validation tooltip-md itech-control-medium"
               [class.invalid]="isInvalid('dateActual')">
          <input type="date" id="dateActual" formControlName="dateActual" required
                 [attr.disabled]="model.started ? true : undefined">
          <app-validation-tooltip [input]="contextFormGroup.controls['dateActual']"></app-validation-tooltip>
        </label>
      </div>
      <div class="form-group">
        <label></label>
        <div class="itech-control-desc">
          Укажите дату, по состоянию на которую учитывать внесенные изменения в части оказанных услуг. Изменения,
          фактически внесенные после указанной даты, не будут учтены в отчете.
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="contextFormGroup.get('uploadedFileUri')">
      <div class="form-group">
        <label for="uploadedFileUri">
          Загружаемый файл
        </label>
        <label for="uploadedFileUri" aria-haspopup="true" role="tooltip"
               class="tooltip tooltip-validation tooltip-md"
               [class.invalid]="isInvalid('uploadedFileUri')">
          <input type="file" id="uploadedFileUri" placeholder="Загружаемый файл"
                 [attr.disabled]="model.started ? true : undefined"
                 class="itech-control-xlarge" (change)="fileChanged($event)">
        </label>
      </div>
      <div class="form-group">
        <label></label>
        <div class="itech-control-desc">
          Выберите загружаемый файл
        </div>
      </div>
    </ng-container>

  </section>

  <div>
    <button [clrLoading]="model.started && !model.completed" [disabled]="model.started" class="btn btn-primary"
            (click)="runOperation()">Выполнить
    </button>
  </div>

  <div *ngIf="model.state && !model.completed">
    <div>
      Операция выполняется
    </div>

    <div class="progress labeled">
      <progress max="10000" value="{{model.state.progress}}" data-displayval="0%"></progress>
      <span>{{getPercProgress()}}%</span>
    </div>

    <div>
      {{model.state.message}}
    </div>
  </div>

  <div *ngIf="model.state && model.completed && model.result && model.result.stateId === 3">
    <clr-icon shape="check-circle" class="is-solid" style="color: green"></clr-icon>
    &nbsp;Операция завершена
    &nbsp;<button class="btn btn-link" (click)="navigateResults()">Скачать</button>
  </div>

  <div *ngIf="model.state && model.completed && model.result && model.result.stateId === 5">
    <clr-icon shape="exclamation-circle" class="is-solid" style="color: red"></clr-icon>
    Произошла ошибка при выполнении операции
    <br>
    <clr-icon shape="exclamation-circle" class="is-solid" style="color: red"></clr-icon>
    {{model.result.message}}
  </div>
</div>
