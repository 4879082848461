import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { AppFormGroup } from './app-form-group';
import { MetadataService } from '../../logic/services/metadata.service';
import { error } from 'util';
import { FormHelper } from './form-helper';
import { SecurityService } from '../../logic/services/security.service';
import { StringHelper } from '../../helpers/string-helper';
import { AppModalPreviewFileComponent } from './app-modal-preview-file.component';
import { AppNavigationService } from '../../logic/services/app-navigation.service';

@Component({
  selector: 'app-single-file',
  template: `
      <div *ngIf="contextFormGroup.get(controlName).value && (isAccessView || isAccessEdit)" class="itech-form-group-one-line">
        {{preTitle}}
        <a class="itech-link align-bottom" style="padding-right: 5px;color:#472cdc"
           (click)="previewFile(contextFormGroup.get(controlName).value)">
          {{index ? index + ' ' : ''}}{{getPrettyTitle(contextFormGroup.get(controlName).value)}}</a>
        <button *ngIf="isAccessEdit" type="button" class="btn btn-danger btn-link itech-inline-tool-btn" title="Удалить файл"
                (click)="deleteFile()" style="margin:0;vertical-align:top">
          <clr-icon shape="trash" style="transform: inherit;color: #d23b3b"></clr-icon>
        </button>
      </div>
      <div *ngIf="!contextFormGroup.get(controlName).value && isAccessEdit" class="itech-form-group-one-line">
          <label for="{{controlName}}{{contextControlId}}" style="min-width:185px;margin-top:0"
                 [class.invalid]="isInvalid(contextFormGroup, controlName)">
              <a class="itech-link itech-control-normal" (click)="fileUploadClick()" id="{{controlName}}{{contextControlId}}"
                [style.color]="contextFormGroup.get(controlName).invalid ? 'red' : ''">
                <input *ngIf="!newFileToUploadToDirectionResetFlag" type="file" #newFileToUploadToDirectionResetFlag
                     (change)="fileChanged($event)" style="visibility: hidden;width: 0">
                <clr-icon shape="upload" [style.color]="contextFormGroup.get(controlName).invalid ? 'red' : 'green'"></clr-icon>
                &nbsp;Загрузить новый файл
              </a>
          </label>
      </div>
      <div *ngIf="!isAccessEdit && !isAccessView" class="itech-form-group-one-line"
      style="color: silver">
          {{preTitle}}{{contextFormGroup.get(controlName).value ? getPrettyTitle(contextFormGroup.get(controlName).value) : 'отсутствует'}}
      </div>`
})
export class AppSingleFileComponent {

  @Input() contextFormGroup: AppFormGroup;
  @Input() controlName = 'uri';                   // в какой контрол записываем uri загруженного файла
  @Input() contextControlId = '';
  @Input() userIdControlName = 'userIdUpload';    // контрол для записи ид пользователя, загрузившего файл
  @Input() uriUpload: string;                     // uri после environment, на который будет отправляться файл
  @Input() index = 0;
  @Input() preTitle = '';                         // текст, отображаемый перед файлом
  @Input() isAccessEdit = true;                   // право редактирования файла
  @Input() isAccessView = false;                   // право просмотра файла

  // по умолчанию используется общий uri скачивания, который требует только аутентифицированного пользователя,
  // по желанию можно указать другой, если нужна специфичная настройка прав
  @Input() downloadFileUri = 'files/preload';

  @Output() changeFile = new EventEmitter();      // сигнал об изменении/удалении файла

  isInvalid = FormHelper.isInvalid;

  newFileToUploadToDirectionResetFlag = false;
  @ViewChildren('newFileToUploadToDirectionResetFlag') newFileToUploadToDirection: QueryList<any>;

  constructor(private metadataService: MetadataService,
              private userService: SecurityService,
              private navigationService: AppNavigationService) {
  }

  getPrettyTitle(file: any) {
    return StringHelper.getPrettyTitleFile(file);
  }

  previewFile(file: any) {
    return this.navigationService.previewFile(AppModalPreviewFileComponent, { fileUri: file });
  }

  fileUploadClick() {
    this.newFileToUploadToDirection.toArray()[0].nativeElement.click();
  }

  fileChanged(e) {
    if (e.target.files && e.target.files.length) {
      if (!this.uriUpload) {
        error('не указан uri для загрузки');
      }

      this.metadataService.uploadFile(this.uriUpload, e.target.files[0])
        .subscribe(fileUri => {
          this.contextFormGroup.get(this.controlName).setValue(fileUri);

          if (this.contextFormGroup.contains(this.userIdControlName)) {
            this.userService.getCurrentUserId().subscribe(id => {
              this.contextFormGroup.get(this.userIdControlName).setValue(id.toString());
              this.contextFormGroup.markAsDirty();
              this.changeFile.next();
            });
          } else {
            this.contextFormGroup.markAsDirty();
            this.changeFile.next();
          }
        });

      // не знаю другого адекватного в данной ситуации способа очистить input[type=file]
      this.newFileToUploadToDirectionResetFlag = true;
      setTimeout(() => {
        this.newFileToUploadToDirectionResetFlag = false;
      });
    }
  }

  deleteFile() {
    this.contextFormGroup.get(this.controlName).setValue(null);
    if (this.contextFormGroup.contains(this.userIdControlName)) {
      this.contextFormGroup.get(this.userIdControlName).setValue(null);
    }
    this.contextFormGroup.markAsDirty();
    this.changeFile.next();
  }
}
