import { Component, Input } from '@angular/core';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { InstitutionDataService } from '../../../logic/services/institution-data.service';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { StringHelper } from '../../../helpers/string-helper';

@Component({
  selector: 'app-edit-institution-indicators',
  templateUrl: './edit-institution-indicators.component.html'
})
export class EditInstitutionIndicatorsComponent {

  @Input() model: any = {};
  storing = false;

  get contextFormGroup(): FormGroup {
    return this.model.contextFormGroup;
  }

  private _id: number;
  @Input()
  get id(): number {
    return this._id;
  }

  set id(id: number) {
    if (this._id !== id) {
      this._id = id;
      this.refreshFormDataInternal();
    }
  }

  constructor(private institutionDataService: InstitutionDataService,
              private alertService: AlertService,
              private dataCachingService: DataCachingService,
              private waitingOverlay: GlobalWaitingOverlayService,
              private lookupSourceService: LookupSourceService,
              private fb: FormBuilder,
              public navigationService: AppNavigationService) {
  }

  isInvalid(fg: FormGroup, cname: string) {
    return FormHelper.isInvalid(fg, cname);
  }

  ensureFormGroup(indicatorTypesList, indicatorSubtypesLookup, arr: any[] = []) {

    if (!this.model.contextFormGroup) {

      const contextFormGroupLookup = {};

      this.model.contextFormGroup = this.fb.group({
        indicators: this.buildFormArray(arr, contextFormGroupLookup)
      });

      this.model.indicators = indicatorTypesList.map(indicatorType => {
        return {
          indicatorType: indicatorType,
          indicators: this.mapIndicatorSubtypes(this.model.contextFormGroup, contextFormGroupLookup,
            indicatorSubtypesLookup, indicatorType.id)
        };
      });

    }
  }

  private mapIndicatorSubtypes(contextFormGroup, contextFormGroupLookup, indicatorSubtypesLookup, indicatorTypeId: any) {
    return (indicatorSubtypesLookup[indicatorTypeId] || []).map(indicatorSubtype => {

      const subtypeIndicators = contextFormGroupLookup['' + indicatorTypeId + ':' + indicatorSubtype.id] || [];

      const info = {
        indicatorSubtype: indicatorSubtype,
        indicators: subtypeIndicators,
        last: subtypeIndicators.length > 0 ? subtypeIndicators[subtypeIndicators.length - 1] : undefined,
        next: this.fb.group(this.getIndicatorGroupDef({
          date: StringHelper.getISODate(new Date()),
          indicatorTypeId: indicatorSubtype.indicatorTypeId,
          indicatorSubtypeId: indicatorSubtype.id,
        })),
        nextIsBound: false,
      };

      info.next.valueChanges.subscribe(() => {
        if (!info.nextIsBound) {
          (contextFormGroup.get('indicators') as FormArray).push(info.next);
          contextFormGroup.markAsDirty();
          info.indicators.push(info.next);
          info.nextIsBound = true;
        }
      });

      return info;
    });
  }

  getIndicatorGroupDef(val: any = {}) {
    return {
      id: val.id,
      date: [val.date, FormHelper.validateDateTimePicker()],
      indicatorTypeId: val.indicatorTypeId,
      indicatorSubtypeId: val.indicatorSubtypeId,
      value: [val.value, FormHelper.validateMoney()],
    };
  }

  buildFormArray(indicatorsData: any[], contextFormGroupLookup: any) {

    indicatorsData.sort((x, y) => x.date.localeCompare(y.date));

    return this.fb.array(indicatorsData.map(item => {
      const group = this.fb.group(this.getIndicatorGroupDef(item));

      const key = '' + item.indicatorTypeId + ':' + item.indicatorSubtypeId;
      if (!contextFormGroupLookup[key]) {
        contextFormGroupLookup[key] = [];
      }

      contextFormGroupLookup[key].push(group);

      return group;
    }));
  }

  store() {
    if (!this.contextFormGroup.valid) {
      return;
    }

    this.storing = true;

    this.institutionDataService.storeInstitutionIndicators(this.id,
      this.contextFormGroup.value.indicators,
      this.model.delete)
      .subscribe({
        next: () => {
          this.storing = false;
          // обязательно обновляем форму из БД - чтобы загрузить идшники для новых бранчей
          this.refreshFormData();
        },
        error: () => {
          this.storing = false;
        }
      });
  }

  cancelEdit() {
    this.dataCachingService.removeCachedData('EditInstitutionIndicators', this.id.toString());
    this.refreshFormDataInternal();
  }

  refreshFormData() {
    this.dataCachingService.removeCachedData('EditInstitutionIndicators', this.id.toString());
    this.refreshFormDataInternal();
  }

  refreshFormDataInternal() {
    this.waitingOverlay.StartWaiting();

    const existing = this.dataCachingService.getCachedData('EditInstitutionIndicators', this.id.toString());

    if (existing) {
      this.model = existing;
      this.waitingOverlay.EndWaiting();
    } else {
      this.lookupSourceService.getLookup('institution-indicator-type', true, true).subscribe(indicatorTypes => {
        this.lookupSourceService.getLookupObj('institution-indicator-subtype').subscribe(indicatorSubtypes => {
          this.institutionDataService.getInstitutionIndicatorsForEdit(this.id).subscribe({
            next: data => {
              this.model = {id: this.id, delete: []};
              this.ensureFormGroup(indicatorTypes, indicatorSubtypes, data);
              this.dataCachingService.addToCache('EditInstitutionIndicators', this.id.toString(), this.model);
              this.waitingOverlay.EndWaiting();
            }, error: () => {
              this.waitingOverlay.EndWaiting();
            }
          });
        });
      });
    }
  }

  showHistory(indicator: any) {
    indicator.showHistory = true;
  }

  deleteIndicator(indicator: any, history: any) {
    const indicatorsFA = (this.model.contextFormGroup.get('indicators') as FormArray);
    const index = indicatorsFA.controls.findIndex(x => x === history);

    if (index >= 0) {
      indicatorsFA.removeAt(index);
      indicatorsFA.markAsDirty();

      const idToDelete = history.get('id').value;
      if (idToDelete) {
        this.model.delete.push(idToDelete);
      }
    }

    const index2 = indicator.indicators.findIndex(x => x === history);

    if (index2 >= 0) {
      indicator.indicators.splice(index2, 1);

      indicator.last = indicator.indicators.length > 0 ? indicator.indicators[indicator.indicators.length - 1] : undefined;
      indicator.next = this.fb.group(this.getIndicatorGroupDef({
        date: StringHelper.getISODate(new Date()),
        indicatorTypeId: indicator.indicatorSubtype.indicatorTypeId,
        indicatorSubtypeId: indicator.indicatorSubtype.id,
      }));
    }
  }
}
