import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { DrugSearchResultsFormComponent } from './drug-search-results-form.component';
import { IModalBody, ModalResult } from '../../../logic/services/app-navigation.service.models';
import { Observable } from 'rxjs';

@Component({
  template: `
    <div style="height: 80vh; background-color: white; position: relative;">
      <app-drug-search-results-form [model]="model" [autoChangeExpanded]="true" (rowClicked)="chooseDrug($event)">
      </app-drug-search-results-form>
    </div>
  `
})
export class DrugSearchModalComponent implements OnInit, IModalBody<any, void> {

  model = DrugSearchResultsFormComponent.getDefaultModel();

  constructor(public appNavigationService: AppNavigationService,
              private fb: FormBuilder) {
  }

  ngOnInit() {
    this.model = DrugSearchResultsFormComponent.getDefaultModel(this.fb, this.appNavigationService.customModalParams);
  }

  chooseDrug(val: any) {
    this.appNavigationService.customModalAcceptExternalPromiseNumber.next(val);
    this.appNavigationService.customModalOpened = false;
  }

  initModalBody(data: any) {
  }

  onModalAccept$(): Observable<ModalResult<void>> {
    return undefined;
  }
}
