import { FormGroup } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
var QueryAddressFilterComponent = /** @class */ (function () {
    function QueryAddressFilterComponent() {
        this.class = 'form-group';
    }
    QueryAddressFilterComponent.prototype.isInvalid = function (cname) {
        return FormHelper.isInvalid(this.contextFormGroup, cname);
    };
    Object.defineProperty(QueryAddressFilterComponent.prototype, "customFilterChecked", {
        get: function () {
            return this.contextFormGroup && (+this.contextFormGroup.get('_par_' + this.parameterName + '_filter').value) === 110;
        },
        set: function (val) {
            if (this.contextFormGroup) {
                this.contextFormGroup.get('_par_' + this.parameterName + '_filter').setValue(val ? 110 : 0);
                this.contextFormGroup.markAsDirty();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QueryAddressFilterComponent.prototype, "noValueChecked", {
        get: function () {
            return this.contextFormGroup && (+this.contextFormGroup.get('_par_' + this.parameterName + '_filter').value) === 1;
        },
        set: function (val) {
            if (this.contextFormGroup) {
                this.contextFormGroup.get('_par_' + this.parameterName + '_filter').setValue(val ? 1 : 0);
                this.contextFormGroup.markAsDirty();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QueryAddressFilterComponent.prototype, "filtered", {
        get: function () {
            var addressObj = this.contextFormGroup.get('_par_' + this.parameterName).value;
            return !!this.contextFormGroup.get('_par_' + this.parameterName + '_filter').value
                || addressObj.regionId || addressObj.cityId || addressObj.other || addressObj.countryId || addressObj.subjfedId
                || addressObj.streetId || addressObj.house || addressObj.building || addressObj.room || addressObj.addressShortText
                || addressObj.cadNumber || addressObj.coordsLatitude || addressObj.coordsLongitude;
        },
        enumerable: true,
        configurable: true
    });
    return QueryAddressFilterComponent;
}());
export { QueryAddressFilterComponent };
