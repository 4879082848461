import { Injectable } from '@angular/core';
import {Observable, BehaviorSubject, ReplaySubject, of} from 'rxjs';
import { DataCachingService } from './data-caching.service';
import { MetadataService } from './metadata.service';
import { InstitutionDataService } from './institution-data.service';
import { AgentDataService } from './agent-data.service';
import { StableDataService } from './stable-data.service';
import { AnimalDataService } from './animal-data.service';
import { DrugDataService } from './drug-data.service';
import { ProductDataService } from './product-data.service';
import { QuarantineDataService } from './quarantine-data.service';
import { map } from 'rxjs/operators';
import {PstReportService} from '../../pst-report/services/pst-report.service';

@Injectable()
export class AddressPersonFioCacheService {
  constructor(private dataCachingService: DataCachingService,
              private institutionDataService: InstitutionDataService,
              private agentDataService: AgentDataService,
              private metadataService: MetadataService,
              private stableDataService: StableDataService,
              private animalDataService: AnimalDataService,
              private drugDataService: DrugDataService,
              private productDataService: ProductDataService,
              private quarantineDataService: QuarantineDataService,
              private pstReportService: PstReportService) {
  }

  static lookupObjectStub = new BehaviorSubject({ caption: 'Н/Д', shortCaption: 'Н/Д' });
  static lookupObjectSystem = new BehaviorSubject({ caption: 'Система', shortCaption: 'Система' });
  static lookupObjectExternal = new BehaviorSubject({ caption: 'Внешняя система', shortCaption: 'Внешняя система' });
  static lookupObjectUnknownMercuryUser = new BehaviorSubject({
    caption: 'Неизвестный пользователь Меркурия',
    shortCaption: 'Неизвестный пользователь Меркурия',
  });
  static lookupObjectOpenApiAgent = new BehaviorSubject({ caption: 'Внешняя система через открытое API', shortCaption: 'Открытое API' });

  public static OBJ_ACCESS_DENIED = { id: 0, caption: '<<доступ запрещен>>' };

  animalIdsAccumulator = [];
  animalIdsTimer = undefined;
  userIdsAccumulator = [];
  userIdsTimer = undefined;
  agentIdsAccumulator = [];
  agentIdsTimer = undefined;
  productIdsAccumulator = [];
  productIdsTimer = undefined;
  productExpertiseIdsAccumulator = [];
  productExpertiseIdsTimer = undefined;

  public getAddress(id: number): Observable<any> {

    if (!id) {
      return new ReplaySubject();
    }

    let existing = this.dataCachingService.getCachedData('AddressPersonFioCacheService_Address', id.toString(10));

    if (existing) {
      return existing;
    } else {
      existing = new ReplaySubject();
      this.dataCachingService.addToCache('AddressPersonFioCacheService_Address', id.toString(10), existing, 1000);
      this.metadataService.getAddress(id).subscribe(
        val => existing.next(val),
        error => existing.next('Неизвестный адрес #' + id.toString(10)));
      return existing;
    }
  }

  public getInstitution(id: number): Observable<any> {

    if (!id) {
      return AddressPersonFioCacheService.lookupObjectStub;
    }

    let existing = this.dataCachingService.getCachedData('AddressPersonFioCacheService_Institution', id.toString(10));

    if (existing) {
      return existing;
    } else {
      existing = new ReplaySubject();
      this.dataCachingService.addToCache('AddressPersonFioCacheService_Institution', id.toString(10), existing);
      this.institutionDataService.getInstitutionCommonForLookup(id).subscribe(
        val => existing.next(val),
        error => existing.next('Неизвестное учреждение #' + id.toString(10)));
      return existing;
    }
  }

  public getAgent(id: number): Observable<any> {

    if (!id) {
      return AddressPersonFioCacheService.lookupObjectStub;
    }

    let existing = this.dataCachingService.getCachedData('AddressPersonFioCacheService_Agent', id.toString(10));

    if (existing) {
      return existing;
    } else {
      existing = new ReplaySubject();
      this.dataCachingService.addToCache('AddressPersonFioCacheService_Agent', id.toString(10), existing, 1000);
      this.agentIdsAccumulator.push(id);

      if (this.agentIdsTimer) {
        const tmpTimer = this.agentIdsTimer;
        this.agentIdsTimer = undefined;
        clearTimeout(tmpTimer);
      }

      this.agentIdsTimer = setTimeout(() => {
        if (this.agentIdsAccumulator.length) {
          const tmpIds = this.agentIdsAccumulator;
          this.agentIdsAccumulator = [];

          this.agentDataService.getAgentCommonForLookup2(tmpIds).subscribe(
            records => {
              records.forEach(val => {
                const valSubj = this.dataCachingService.getCachedData('AddressPersonFioCacheService_Agent', val.id);
                if (valSubj) {
                  valSubj.next(val);
                }
              });
            }, err => existing.next('Неизвестный хозяйствующий субъект #' + id.toString(10)));
        }
      });

      return existing;
    }
  }

  public getProduct(id: number): Observable<any> {

    if (!id) {
      return AddressPersonFioCacheService.lookupObjectStub;
    }

    let existing = this.dataCachingService.getCachedData('AddressPersonFioCacheService_Product', id.toString(10));

    if (existing) {
      return existing;
    } else {
      existing = new ReplaySubject();
      this.dataCachingService.addToCache('AddressPersonFioCacheService_Product', id.toString(10), existing, 2000);
      this.productIdsAccumulator.push(id);

      if (this.productIdsTimer) {
        const tmpTimer = this.productIdsTimer;
        this.productIdsTimer = undefined;
        clearTimeout(tmpTimer);
      }

      this.productIdsTimer = setTimeout(() => {
        if (this.productIdsAccumulator.length) {
          const tmpIds = this.productIdsAccumulator;
          this.productIdsAccumulator = [];

          this.productDataService.getProductCommonForLookup2(tmpIds).subscribe(
            records => {
              records.forEach(val => {
                const valSubj = this.dataCachingService.getCachedData('AddressPersonFioCacheService_Product', val.id);
                if (valSubj) {
                  valSubj.next(val);
                }
              });
            }, err => existing.next('Неизвестная продукция #' + id.toString(10)));
        }
      });

      return existing;
    }
  }

  public getProductExpertise(id: number): Observable<any> {

    if (!id) {
      return AddressPersonFioCacheService.lookupObjectStub;
    }

    let existing = this.dataCachingService.getCachedData('AddressPersonFioCacheService_ProductExpertise', id.toString(10));

    if (existing) {
      return existing;
    } else {
      existing = new ReplaySubject();
      this.dataCachingService.addToCache('AddressPersonFioCacheService_ProductExpertise', id.toString(10), existing, 2000);
      this.productExpertiseIdsAccumulator.push(id);

      if (this.productExpertiseIdsTimer) {
        const tmpTimer = this.productExpertiseIdsTimer;
        this.productExpertiseIdsTimer = undefined;
        clearTimeout(tmpTimer);
      }

      this.productExpertiseIdsTimer = setTimeout(() => {
        if (this.productExpertiseIdsAccumulator.length) {
          const tmpIds = this.productExpertiseIdsAccumulator;
          this.productExpertiseIdsAccumulator = [];

          this.productDataService.getProductExpertiseForLookup(tmpIds).subscribe(
            records => {
              records.forEach(val => {
                const valSubj = this.dataCachingService.getCachedData('AddressPersonFioCacheService_ProductExpertise', val.id);
                if (valSubj) {
                  valSubj.next(val);
                }
              });
            }, err => existing.next('Неизвестная экспертиза продукции #' + id.toString(10)));
        }
      });

      return existing;
    }
  }

  public getProductTransaction(id: number): Observable<any> {

    if (!id) {
      return AddressPersonFioCacheService.lookupObjectStub;
    }

    let existing = this.dataCachingService.getCachedData('AddressPersonFioCacheService_ProductTransaction', id.toString(10));

    if (existing) {
      return existing;
    } else {
      existing = new ReplaySubject();
      this.dataCachingService.addToCache('AddressPersonFioCacheService_ProductTransaction', id.toString(10), existing);
      existing.next({ id: id, caption: 'транзакция #' + id });
      return existing;
    }
  }

  public getProductsByTransactionId(id: number): Observable<any[]> {

    if (!id) {
      return of([]);
    }

    let existing = this.dataCachingService.getCachedData('AddressPersonFioCacheService_ProductsByTransactionId', id.toString(10));

    if (existing) {
      return existing;
    } else {
      existing = new ReplaySubject();
      this.dataCachingService.addToCache('AddressPersonFioCacheService_ProductsByTransactionId', id.toString(10), existing);
      this.productDataService.getByTransactionId(id).subscribe(
        val => existing.next(val),
        error => existing.next([]));
      return existing;
    }
  }

  public getStable(id: number): Observable<any> {

    if (!id) {
      return AddressPersonFioCacheService.lookupObjectStub;
    }

    let existing = this.dataCachingService.getCachedData('AddressPersonFioCacheService_Stable', id.toString(10));

    if (existing) {
      return existing;
    } else {
      existing = new ReplaySubject();
      this.dataCachingService.addToCache('AddressPersonFioCacheService_Stable', id.toString(10), existing);
      this.stableDataService.getStableCommonForLookup(id).subscribe(
        val => existing.next(val),
        error => existing.next('Неизвестный поднадзорный объект #' + id.toString(10)));
      return existing;
    }
  }

  public getDrug(id: number): Observable<any> {

    if (!id) {
      return AddressPersonFioCacheService.lookupObjectStub;
    }

    let existing = this.dataCachingService.getCachedData('AddressPersonFioCacheService_Drug', id.toString(10));

    if (existing) {
      return existing;
    } else {
      existing = new ReplaySubject();
      this.dataCachingService.addToCache('AddressPersonFioCacheService_Drug', id.toString(10), existing);
      this.drugDataService.getDrugCommonForLookup(id).subscribe(
        val => existing.next(val),
        error => existing.next('Неизвестный препарат #' + id.toString(10)));
      return existing;
    }
  }

  public getAnimal(id: number): Observable<any> {

    if (!id) {
      return AddressPersonFioCacheService.lookupObjectStub;
    }

    let existing = this.dataCachingService.getCachedData('AddressPersonFioCacheService_Animal', id.toString(10));

    if (existing) {
      return existing;
    } else {
      existing = new BehaviorSubject({id: id});
      this.dataCachingService.addToCache('AddressPersonFioCacheService_Animal', id.toString(10), existing, 100);
      this.animalIdsAccumulator.push(id);

      if (this.animalIdsTimer) {
        const tmpTimer = this.animalIdsTimer;
        this.animalIdsTimer = undefined;
        clearTimeout(tmpTimer);
      }

      this.animalIdsTimer = setTimeout(() => {
        if (this.animalIdsAccumulator.length) {
          const tmpIds = this.animalIdsAccumulator;
          this.animalIdsAccumulator = [];
          this.animalDataService.getAnimalCommonForLookup2(tmpIds).subscribe(
            records => {
              records.forEach(val => {
                const valSubj = this.dataCachingService.getCachedData('AddressPersonFioCacheService_Animal', val.id);
                if (valSubj) {
                  valSubj.next(val);
                }
              });
            }, err => existing.next('Неизвестное животное #' + id.toString(10)));
        }
      });

      return existing;
    }
  }

  public getAnimalExternal(id: number): Observable<any> {

    if (!id) {
      return AddressPersonFioCacheService.lookupObjectStub;
    }

    let existing = this.dataCachingService.getCachedData('AddressPersonFioCacheService_AnimalExternal', id.toString(10));

    if (existing) {
      return existing;
    } else {
      existing = new ReplaySubject();
      this.dataCachingService.addToCache('AddressPersonFioCacheService_AnimalExternal', id.toString(10), existing);
      this.animalDataService.getAnimalExternalForLookup(id).subscribe(
        val => existing.next(val),
        error => existing.next('Неизвестное неподтверждённое животное #' + id.toString(10)));
      return existing;
    }
  }

  public getAnimalEvent(id: number): Observable<any> {

    if (!id) {
      return AddressPersonFioCacheService.lookupObjectStub;
    }

    let existing = this.dataCachingService.getCachedData('AddressPersonFioCacheService_AnimalEvent', id.toString(10));

    if (existing) {
      return existing;
    } else {
      existing = new ReplaySubject();
      this.dataCachingService.addToCache('AddressPersonFioCacheService_AnimalEvent', id.toString(10), existing);
      this.animalDataService.getAnimalEventCommonForLookup(id).subscribe(
        val => existing.next(val),
        error => existing.next('Неизвестное мероприятие #' + id.toString(10)));
      return existing;
    }
  }

  public getPstReport(id: number): Observable<any> {
    if (!id) {
      return AddressPersonFioCacheService.lookupObjectStub;
    }

    let existing = this.dataCachingService.getCachedData('AddressPersonFioCacheService_PstReport', id.toString(10));

    if (existing) {
      return existing;
    } else {
      existing = new ReplaySubject();
      this.dataCachingService.addToCache('AddressPersonFioCacheService_PstReport', id.toString(10), existing);
      this.pstReportService.getForLookup(id).subscribe(
        val => existing.next(val),
        error => existing.next('Неизвестный отчет #' + id.toString(10)));
      return existing;
    }
  }

  public getUser(id: number): Observable<any> {

    if (!id && id !== 0) {
      return AddressPersonFioCacheService.lookupObjectStub;
    }
    if (id === 0) {
      return AddressPersonFioCacheService.lookupObjectSystem;
    }
    if (id === -100) {
      return AddressPersonFioCacheService.lookupObjectExternal;
    }
    if (id === -200) {
      return AddressPersonFioCacheService.lookupObjectUnknownMercuryUser;
    }
    if (id === -300) {
      return AddressPersonFioCacheService.lookupObjectOpenApiAgent;
    }

    let existing = this.dataCachingService.getCachedData('AddressPersonFioCacheService_User', id.toString(10));

    if (existing) {
      return existing;
    } else {
      existing = new BehaviorSubject({id: id});
      this.dataCachingService.addToCache('AddressPersonFioCacheService_User', id.toString(10), existing, 1000);
      this.userIdsAccumulator.push(id);

      if (this.userIdsTimer) {
        const tmpTimer = this.userIdsTimer;
        this.userIdsTimer = undefined;
        clearTimeout(tmpTimer);
      }

      this.userIdsTimer = setTimeout(() => {
        if (this.userIdsAccumulator.length) {
          const tmpIds = this.userIdsAccumulator;
          this.userIdsAccumulator = [];
          this.metadataService.getUserForLookup2(tmpIds).subscribe(
            records => {
              records.forEach(val => {
                const valSubj = this.dataCachingService.getCachedData('AddressPersonFioCacheService_User', val.id);
                if (valSubj) {
                  valSubj.next(val);
                }
              });
            });
        }
      });

      return existing;
    }
  }

  public invalidateInstitution(id: number) {
    this.dataCachingService.removeCachedData('AddressPersonFioCacheService_Institution', id.toString(10));
  }

  public invalidateStable(id: number) {
    this.dataCachingService.removeCachedData('AddressPersonFioCacheService_Stable', id.toString(10));
  }

  public invalidateAnimal(id: number) {
    this.dataCachingService.removeCachedData('AddressPersonFioCacheService_Animal', id.toString(10));
  }

  public invalidateAnimalExternal(id: number) {
    this.dataCachingService.removeCachedData('AddressPersonFioCacheService_AnimalExternal', id.toString(10));
  }

  public invalidateAnimalEvent(id: number) {
    this.dataCachingService.removeCachedData('AddressPersonFioCacheService_AnimalEvent', id.toString(10));
  }

  public invalidateAgent(id: number) {
    this.dataCachingService.removeCachedData('AddressPersonFioCacheService_Agent', id.toString(10));
  }

  public invalidateProduct(id: number) {
    this.dataCachingService.removeCachedData('AddressPersonFioCacheService_Product', id.toString(10));
  }

  public invalidateDrug(id: number) {
    this.dataCachingService.removeCachedData('AddressPersonFioCacheService_Drug', id.toString(10));
  }

  public getQuarantineLookup(id: number): Observable<any> {

    if (!id) {
      return AddressPersonFioCacheService.lookupObjectStub;
    }

    const existing = this.dataCachingService.getCachedData('LookupQuarantine_Data', id.toString(10));

    if (existing) {
      return new BehaviorSubject(existing);
    } else {
      return this.quarantineDataService.getForLookup(id)
        .pipe(map((response: any) => {
          this.dataCachingService.addToCache('LookupQuarantine_Data', id.toString(10), response);
          response.data.currentStatusId = response.currentStatusId;
          return response as any;
        }));
    }
  }

  public invalidateQuarantine(id: number) {
    this.dataCachingService.removeCachedData('LookupQuarantine_Data', id.toString(10));
  }
}
