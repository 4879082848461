<button type="button" [classList]="'btn ' + btnClasses"
         [disabled]="disabled || (disabledIfNotSession && !(securityService.getUserMercurySession() | async))"
         [clrLoading]="clrLoading" [title]="caption" (click)="action.emit()">
      <div [class]="(securityService.getUserMercurySession() | async)
                    ? ''
                    : 'it-btn-sync-web-caption'">
          <clr-icon *ngIf="iconName === 'refresh'" shape="refresh" role="none" style="margin-right:5px"></clr-icon>
          <clr-icon *ngIf="iconName === 'check'" shape="check" role="none" style="margin-right:5px"></clr-icon>
          <clr-icon *ngIf="iconName === 'undo'" shape="undo" role="none" style="margin-right:5px"></clr-icon>
          {{caption}}
      </div>
      <div *ngIf="!(securityService.getUserMercurySession() | async)"
           class="itech-control-desc itech-desc-error" style="text-transform:none;font-size:10px;letter-spacing:normal;">
          {{notSessionCaption}}
      </div>
 </button>