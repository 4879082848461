<div class="itech-content-header">
  <clr-icon shape="color-picker" size="36" class="has-badge"></clr-icon>&nbsp;Создание карточки мероприятия
</div>

<app-edit-animal-event-common-form #animalEventFormChild *ngIf="model" [contextFormGroup]="model.form" [animal]="model.animal"
                                   (resultsCountsChanged)="getSelectedAnimalsCount()"
                                   [eventType]="model.eventType" [showAddManyAnimal]="true" [model]="model">
</app-edit-animal-event-common-form>

<ng-container *ngIf="model">
  <p *ngIf="model.animal; else stableActions">
    <button [clrLoading]="creating" [disabled]="creating || !selectedAnimalsCount" class="btn btn-primary"
            (click)="createCard()">
      <clr-icon shape="check"></clr-icon>&nbsp;Создать{{' (Выбрано ' + selectedAnimalsCount + ' животных)'}}
    </button>
    <button [clrLoading]="creating" [disabled]="creating || !selectedAnimalsCount" class="btn btn-primary"
            (click)="createCard(true)">
      <clr-icon shape="check"></clr-icon>&nbsp;Создать и продолжить
    </button>
  </p>
  <ng-template #stableActions>
    <p>
      <button [clrLoading]="creating" [disabled]="creating" class="btn btn-primary" (click)="createCard()">
        <clr-icon shape="check"></clr-icon>&nbsp;Создать
      </button>
    </p>
  </ng-template>
</ng-container>
