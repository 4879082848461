/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./journal-master-detail-cell-renderer.component";
var styles_JournalMasterDetailCellRendererComponent = [];
var RenderType_JournalMasterDetailCellRendererComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_JournalMasterDetailCellRendererComponent, data: {} });
export { RenderType_JournalMasterDetailCellRendererComponent as RenderType_JournalMasterDetailCellRendererComponent };
export function View_JournalMasterDetailCellRendererComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "full-width-panel"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "full-width-details"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.params.data.changeInfo; _ck(_v, 2, 0, currVal_0); }); }
export function View_JournalMasterDetailCellRendererComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-journal-detail-cell-renderer", [], null, null, null, View_JournalMasterDetailCellRendererComponent_0, RenderType_JournalMasterDetailCellRendererComponent)), i0.ɵdid(1, 49152, null, 0, i1.JournalMasterDetailCellRendererComponent, [], null, null)], null, null); }
var JournalMasterDetailCellRendererComponentNgFactory = i0.ɵccf("app-journal-detail-cell-renderer", i1.JournalMasterDetailCellRendererComponent, View_JournalMasterDetailCellRendererComponent_Host_0, {}, {}, []);
export { JournalMasterDetailCellRendererComponentNgFactory as JournalMasterDetailCellRendererComponentNgFactory };
