export function Required(target: object, key: string) {
  Object.defineProperty(target, key, {
    get () {
      throw new Error(`Attribute ${key} is required`);
    },
    set (value) {
      Object.defineProperty(target, key, { value, writable: true, configurable: true });
    },
  });
}
