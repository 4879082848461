import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormHelper } from '../../../ui/controls/form-helper';
import { EditQuarantineEventsController } from './edit-quarantine-events.controller';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';

@Component({
  selector: 'app-edit-quarantine-events',
  templateUrl: './edit-quarantine-events.component.html'
})
export class EditQuarantineEventsComponent implements OnChanges {
  @Input() id;

  isInvalid = FormHelper.isInvalid;

  constructor(public ctlr: EditQuarantineEventsController,
              public navigationService: AppNavigationService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('id')) {
      this.ctlr.refreshFormData(this.id);
    }
  }
}
