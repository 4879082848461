import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { AddressPersonFioCacheService } from '../../logic/services/address-person-fio-cache.service';
import { MetadataService } from '../../logic/services/metadata.service';

@Component({
  selector: 'app-product-transaction-detail-cell-renderer',
  template: `
      <ng-container *ngFor="let transaction of transactions|keyvalue">
        <div class="itech-doc-container" style="white-space: normal;margin-top:10px" id="rootContainer">
          <div class="itech-doc-image" style="margin-top:5px">
            <p style="font-weight:bold;color:black;margin:0 0 10px 0;letter-spacing:normal;line-height:normal;font-size:12px;">
              Транзакция #{{transaction.key}}
            </p>
            <app-mercury-sync-status-signpost class="itech-signpost-grid-detail" [syncStates]="[syncState[+transaction.key]]"
                                              (syncStateUpdated)="refreshSyncState(+transaction.key)">
            </app-mercury-sync-status-signpost>
          </div>
          <div class="itech-doc-content">
            <div style="color:black;margin-left: 10px">
              <div>
                <table class="table table-noborder table-compact" style="margin-top: 2px">
                  <thead>
                    <tr>
                      <th class="left" style="width:35%">Сырьё</th>
                      <th class="left" style="width:65%;">Выработанная продукция</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="left" style="font-size:12px;line-height:normal">
                        <app-link-button *ngFor="let raw of (transaction.value[1] || [])" [id]="+raw" [lookupName]="'product'">
                        </app-link-button>
                      </td>
                      <td class="left" style="font-size:12px;line-height:normal">
                        <app-link-button *ngFor="let batch of (transaction.value[2] || [])" [id]="+batch" [lookupName]="'product'">
                        </app-link-button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </ng-container>`
})
export class AgGridProductTransactionDetailRendererComponent implements ICellRendererAngularComp {

  params: any;

  transactions = {};

  syncState = {};

  constructor(private cacheService: AddressPersonFioCacheService,
              private metadataService: MetadataService) {
  }

  agInit(params: any): void {
    this.params = params;
    (this.params.data.transactionIds || []).forEach(transactionId => {
      this.cacheService.getProductsByTransactionId(transactionId)
        .subscribe(data => {
          if (!this.transactions[transactionId]) {
            this.transactions[transactionId] = {};
          }
          (data || []).forEach(x => {
            if (!this.transactions[transactionId][+x.batchTypeId]) {
              this.transactions[transactionId][+x.batchTypeId] = [];
            }
            this.transactions[transactionId][+x.batchTypeId].push(x.productId);
          });
          this.refreshSyncState(transactionId);
        });
    });
  }

  refresh(params: any): boolean {
    this.params = params;
    return false;
  }

  public refreshSyncState(transactionId: number) {
    this.syncState[transactionId] = undefined;
    this.refreshSyncStateInternal(transactionId);
  }

  private refreshSyncStateInternal(transactionId: number) {

    if (!transactionId) {
      return;
    }

    this.metadataService.getSyncState(transactionId, 7).subscribe({
      next: data => {
        this.syncState[transactionId] = data;
        this.syncState[transactionId].withoutMercuryUuid = true;
      }
    });
  }
}
