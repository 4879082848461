<clr-tabs>
  <clr-tab>
    <button clrTabLink>Общее</button>
    <ng-template [(clrIfActive)]="model.commonTabActive">
      <clr-tab-content>
        <div class="edit-page-tab">
          <app-edit-product-common [id]="id"></app-edit-product-common>
        </div>
      </clr-tab-content>
    </ng-template>
  </clr-tab>
  <clr-tab>
    <button clrTabLink><clr-icon shape="flask"></clr-icon>Экспертизы / исследования</button>
    <ng-template [(clrIfActive)]="model.expertisesTabActive">
      <clr-tab-content>
        <div class="edit-page-tab">
          <app-edit-product-expertises [id]="id" [expertiseId]="expertiseId" [productId]="id"></app-edit-product-expertises>
        </div>
      </clr-tab-content>
    </ng-template>
  </clr-tab>
  <clr-tab>
    <button clrTabLink><clr-icon shape="truck"></clr-icon>Производная продукция</button>
    <ng-template [(clrIfActive)]="model.subproductsTabActive">
      <clr-tab-content>
        <div class="edit-page-tab">
          <app-edit-product-subproducts [id]="id"></app-edit-product-subproducts>
        </div>
      </clr-tab-content>
    </ng-template>
  </clr-tab>
</clr-tabs>
