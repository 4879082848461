import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {Observable, of, Subject} from 'rxjs';
import {IModalBody, ModalResult} from '../../../logic/services/app-navigation.service.models';
import {AppNavigationService} from '../../../logic/services/app-navigation.service';
import {PstReportService} from '../../services/pst-report.service';
import {FormHelper} from '../../../ui/controls/form-helper';

@Component({
  template: `
      <div *ngIf="contextFormGroup" class="form compact" [formGroup]="contextFormGroup"
           style="max-height: 60vh; background-color: white; position: relative;">
          <section class="form-block itech-block-normal">

              <div class="form-group">
                  <label for="orderNo">№ раздела</label>
                  <label for="orderNo" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md tooltip-top-left"
                         [class.invalid]="isInvalid(contextFormGroup, 'orderNo')">
                      <input type="text" id="orderNo" class="itech-control-normal"
                             formControlName="orderNo">
                      <app-validation-tooltip
                              [input]="contextFormGroup.controls['orderNo']"></app-validation-tooltip>
                  </label>
              </div>

              <div class="form-group">
                  <label for="editorKind">Редактор раздела</label>
                  <label for="editorKind" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md tooltip-top-left"
                         [class.invalid]="isInvalid(contextFormGroup, 'editorKind')">
                      <div class="select">
                          <select formControlName="editorKind" class="itech-control-xlarge">
                              <option [ngValue]="'Табл_по_графам'">
                                  Таблица по графам
                              </option>
                              <option [ngValue]="'Табл_по_строкам'">
                                  Таблица по ячейкам
                              </option>
                              <option [ngValue]="'Табл_стройки'">
                                  Таблица по стройкам
                              </option>
                              <option [ngValue]="'Табл_сделки_продажа'">
                                  Таблица со сделками о продаже
                              </option>
                              <option [ngValue]="'Табл_сделки_покупка'">
                                  Таблица со сделками о покупке
                              </option>
                          </select>
                      </div>
                  </label>
              </div>

              <div class="form-group">
                  <label for="pageTitle" class="required">Название страницы</label>
                  <label for="pageTitle" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md tooltip-top-left"
                         [class.invalid]="isInvalid(contextFormGroup, 'pageTitle')">
                      <input type="text" id="pageTitle" class="itech-control-xlarge"
                             formControlName="pageTitle">
                      <app-validation-tooltip
                              [input]="contextFormGroup.controls['pageTitle']"></app-validation-tooltip>
                  </label>
              </div>

              <div class="form-group itech-double-height v-resize">
                  <label for="displayTitle">Название раздела</label>
                  <label for="displayTitle" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                         [class.invalid]="isInvalid(contextFormGroup, 'displayTitle')">
                        <textarea type="text" id="displayTitle" class="itech-control-xlarge itech-text-wall"
                                  formControlName="displayTitle"></textarea>
                      <app-validation-tooltip
                              [input]="contextFormGroup.controls['displayTitle']"></app-validation-tooltip>
                  </label>
              </div>

              <div class="form-group">
                  <label for="indicatorLevel" class="required">Уровень показателя</label>
                  <label for="indicatorLevel" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md tooltip-top-left"
                         [class.invalid]="isInvalid(contextFormGroup, 'indicatorLevel')">
                      <input type="text" id="indicatorLevel" class="itech-control-normal"
                             formControlName="indicatorLevel">
                      <app-validation-tooltip
                              [input]="contextFormGroup.controls['indicatorLevel']"></app-validation-tooltip>
                  </label>
                  <label for="subjectIndicatorLevel">Уровень в край. сводке</label>
                  <label for="subjectIndicatorLevel" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md tooltip-top-left"
                         [class.invalid]="isInvalid(contextFormGroup, 'subjectIndicatorLevel')">
                      <input type="text" id="subjectIndicatorLevel" class="itech-control-normal"
                             formControlName="subjectIndicatorLevel">
                      <app-validation-tooltip
                              [input]="contextFormGroup.controls['subjectIndicatorLevel']"></app-validation-tooltip>
                  </label>
              </div>

              <div class="form-group itech-double-height v-resize">
                  <label for="paramsStr">Доп. параметры</label>
                  <label for="paramsStr" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                         [class.invalid]="isInvalid(contextFormGroup, 'paramsStr')">
                        <textarea type="text" id="paramsStr" class="itech-control-xlarge itech-text-wall"
                                  formControlName="paramsStr"></textarea>
                      <app-validation-tooltip
                              [input]="contextFormGroup.controls['paramsStr']"></app-validation-tooltip>
                  </label>
              </div>

              <div class="form-group">
                  <label for="level60ObjKind">Категория объектов</label>
                  <label for="level60ObjKind" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md tooltip-top-left"
                         [class.invalid]="isInvalid(contextFormGroup, 'level60ObjKind')">
                      <input type="text" id="level60ObjKind" class="itech-control-xlarge"
                             formControlName="level60ObjKind">
                      <app-validation-tooltip
                              [input]="contextFormGroup.controls['level60ObjKind']"></app-validation-tooltip>
                  </label>
              </div>
          </section>
      </div>
  `
})
export class ReportBranchMetaEditModalComponent implements OnInit, IModalBody<any, void> {

  contextFormGroup: any;

  isInvalid = FormHelper.isInvalid;

  constructor(public appNavigationService: AppNavigationService,
              private fb: FormBuilder,
              private pstReportService: PstReportService,
  ) {
  }

  ngOnInit() {
  }

  initModalBody(data: any) {
    this.contextFormGroup = this.fb.group({
      createNew: data.createNew,
      reportTypeId: data.reportTypeId,
      oldOrderNo: data.orderNo,
      orderNo: [data.orderNo, Validators.compose([Validators.pattern(/^\d{1,4}$/), Validators.required])],
      editorKind: [data.editorKind, Validators.required],
      indicatorLevel: [data.indicatorLevel, Validators.pattern(/^\d{1,2}$/)],
      subjectIndicatorLevel: [data.subjectIndicatorLevel, Validators.pattern(/^\d{1,2}$/)],
      displayTitle: data.displayTitle,
      pageTitle: [data.pageTitle, Validators.required],
      paramsStr: JSON.stringify(data.params || {}),
      params: data.params,
      level60ObjKind: data.level60ObjKind,
      updatedTime: data.updatedTime,
    });
  }

  onModalAccept$(): Observable<ModalResult<any>> {
    const retVal = new Subject<ModalResult<any>>();

    if (!this.contextFormGroup.valid) {
      return of(new ModalResult(false, undefined));
    }

    const valToStore = this.contextFormGroup.value;
    valToStore.params = JSON.parse(this.contextFormGroup.get('paramsStr').value || undefined);

    const storeSubj = this.contextFormGroup.get('createNew').value
      ? this.pstReportService.createReportTypeBranch(
        valToStore,
      )
      : this.pstReportService.updateReportTypeBranch(
        valToStore,
        this.contextFormGroup.get('reportTypeId').value,
        this.contextFormGroup.get('oldOrderNo').value,
      );

    storeSubj.subscribe({
      next:
        updated => {
          retVal.next(new ModalResult(true, updated));
        },
      error: () => {
        retVal.next(new ModalResult(false, undefined));
      }
    });

    return retVal;
  }
}
