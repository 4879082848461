import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TicketsPlanningUiService } from './tickets-planning-ui-service';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AgentSearchModalComponent } from '../../edit-agent/search/agent-search-modal.component';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { AnimalSearchModalComponent } from '../../edit-animal/search/animal-search-modal.component';
import { DateHelper } from '../../../helpers/date-helper';

@Component({
  selector: 'app-ticket-edit-modal',
  templateUrl: './ticket-edit-modal.component.html',
  styleUrls: ['./tickets-planning.component.css']
})
export class TicketEditModalComponent {

  @Input() model: any = {};
  @Output() forceReloadEmitter = new EventEmitter<boolean>();

  isInvalid = FormHelper.isInvalid;
  processMoneyKeypress = FormHelper.processMoneyKeypressDot;
  isAfterCurrentDate = date => DateHelper.isAfter(date, new Date()) > 0;

  constructor(public planningUi: TicketsPlanningUiService,
              private navigationService: AppNavigationService) {
  }

  get ticketFormGroup() {
    return this.planningUi.ticketFormGroup;
  }

  get isTemplate() {
    return this.planningUi.ticketFormGroup.get('isTemplate').value;
  }

  get isDistribute() {
    return this.planningUi.ticketFormGroup.get('isDistribute').value;
  }

  get readonly() {
    return this.planningUi.ticketReadonly;
  }

  get modalValidationErrorsHost() {
    return this.planningUi.modalValidationErrorsHost;
  }

  searchAgent() {
    this.navigationService.searchAgent(AgentSearchModalComponent, { availableCreateAgent: true })
      .subscribe(val => {
        if (val) {
          this.ticketFormGroup.get('agentId').setValue(val);
          this.ticketFormGroup.markAsDirty();
        }
      });
  }

  removeAgent() {
    this.ticketFormGroup.controls['agentId'].setValue(undefined);
    this.ticketFormGroup.controls['animalId'].setValue(undefined);
    this.ticketFormGroup.controls['isAnimalExternal'].setValue(undefined);
    this.ticketFormGroup.markAsDirty();
  }

  searchAnimal() {
    this.navigationService.searchAnimal(AnimalSearchModalComponent,
      {
        agentId: this.ticketFormGroup.get('agentId').value,
        animalTypeId: this.ticketFormGroup.get('animalTypeId').value,
        animalSubtypeId: this.ticketFormGroup.get('animalSubtypeId').value,
        availableShowExternal: true,
      }).subscribe(val => {
        if (val) {
          this.ticketFormGroup.get('isAnimalExternal').setValue(!!val.tempId);
          this.ticketFormGroup.get('animalId').setValue(val.tempId || val.id);
          this.ticketFormGroup.markAsDirty();
        }
      });
  }

  removeAnimal() {
    this.ticketFormGroup.controls['animalId'].setValue(undefined);
    this.ticketFormGroup.controls['isAnimalExternal'].setValue(undefined);
    this.ticketFormGroup.markAsDirty();
  }

  addEditTicketCompleted() {
    this.planningUi.addEditTicketCompleted(this.model, this.forceReloadEmitter);
  }

  deleteTicket() {
    if (!this.ticketFormGroup.get('isTemplate').value) {
      this.planningUi.deleteOrCancelTicketModal(this.planningUi.editedTicket, this.model);
    } else {
      this.planningUi.deleteTicketTemplate(this.ticketFormGroup.value, this.model);
    }
  }

  distributeTickets() {
    this.planningUi.distributeTickets(this.model, this.forceReloadEmitter);
  }

  markSuccessful() {
    this.planningUi.markSuccessful();
  }
}
