import { DataCachingService } from '../../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { FormHelper } from '../../../ui/controls/form-helper';
import { StableDataService } from '../../../logic/services/stable-data.service';
import { FormBuilder, Validators } from '@angular/forms';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { finalize } from 'rxjs/operators';
var EditStableProductionTemplateComponent = /** @class */ (function () {
    function EditStableProductionTemplateComponent(fb, stableDataService, waitingOverlay, dataCachingService, navigationService) {
        this.fb = fb;
        this.stableDataService = stableDataService;
        this.waitingOverlay = waitingOverlay;
        this.dataCachingService = dataCachingService;
        this.navigationService = navigationService;
        this.model = {};
        this.storing = false;
        this.model.currentDate = new Date();
    }
    Object.defineProperty(EditStableProductionTemplateComponent.prototype, "contextFormGroup", {
        get: function () {
            this.ensureFormGroup();
            return this.model.contextFormGroup;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditStableProductionTemplateComponent.prototype, "id", {
        get: function () {
            return this._id;
        },
        set: function (id) {
            if (this._id !== id) {
                this._id = id;
                this.refreshFormDataInternal();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditStableProductionTemplateComponent.prototype, "templates", {
        get: function () {
            return this.contextFormGroup.get('templates');
        },
        enumerable: true,
        configurable: true
    });
    EditStableProductionTemplateComponent.prototype.isInvalid = function (fg, cname) {
        return FormHelper.isInvalid(fg, cname);
    };
    EditStableProductionTemplateComponent.prototype.ensureFormGroup = function (arr) {
        if (arr === void 0) { arr = []; }
        if (!this.model.contextFormGroup) {
            this.model.contextFormGroup = this.fb.group({
                templates: this.buildFormArray(arr)
            });
        }
    };
    EditStableProductionTemplateComponent.prototype.buildFormArray = function (templatesData) {
        var _this = this;
        return this.fb.array(templatesData.map(function (item) { return _this.fb.group(_this.getProductionTemplateGroupDef(item)); }));
    };
    EditStableProductionTemplateComponent.prototype.getProductionTemplateGroupDef = function (val) {
        var _this = this;
        if (val === void 0) { val = {}; }
        return {
            id: val.id,
            stableId: val.stableId,
            caption: [val.caption, Validators.required],
            sourceProductTypeId: [val.sourceProductTypeId, Validators.required],
            sourceProductSubtypeId: [val.sourceProductSubtypeId, Validators.required],
            productionStableProductSubtypes: this.fb.array((val.productionStableProductSubtypes || []).map(function (el) {
                return _this.fb.group(_this.getStableProductionTemplateProductBatchGroupDef(el));
            })),
            expanded: false,
        };
    };
    EditStableProductionTemplateComponent.prototype.getStableProductionTemplateProductBatchGroupDef = function (val) {
        if (val === void 0) { val = {}; }
        return {
            stableProductionTemplateId: val.stableProductionTemplateId,
            stableProductSubtypeId: val.stableProductSubtypeId,
            unitId: val.unitId,
            batchId: val.batchId,
        };
    };
    EditStableProductionTemplateComponent.prototype.refreshFormData = function () {
        this.dataCachingService.removeCachedData('EditStableProductionTemplate', this.id.toString());
        this.dataCachingService.removeCachedDataByStartKey('EditStableProductionTemplateProductionStableProductSubtypes', this.id + '-');
        this.refreshFormDataInternal();
    };
    EditStableProductionTemplateComponent.prototype.refreshFormDataInternal = function () {
        var _this = this;
        this.waitingOverlay.StartWaiting();
        var existing = this.dataCachingService.getCachedData('EditStableProductionTemplate', this.id.toString());
        if (existing) {
            this.model = existing;
            this.waitingOverlay.EndWaiting();
        }
        else {
            this.stableDataService.getProductionTemplate(this.id).subscribe(function (data) {
                _this.model = { id: _this.id, delete: [] };
                _this.ensureFormGroup(data);
                _this.dataCachingService.addToCache('EditStableProductionTemplate', _this.id.toString(), _this.model);
                _this.dataCachingService.removeCachedDataByStartKey('EditStableProductionTemplateProductionStableProductSubtypes', _this.id + '-');
                (data || []).forEach(function (el) {
                    var existingEl = _this.dataCachingService.getCachedData('EditStableProductionTemplateProductionStableProductSubtypes', _this.id + "-" + el.sourceProductTypeId + "-" + el.sourceProductSubtypeId);
                    if (!existingEl) {
                        _this.dataCachingService.addToCache('EditStableProductionTemplateProductionStableProductSubtypes', _this.id + "-" + el.sourceProductTypeId + "-" + el.sourceProductSubtypeId, []);
                        existingEl = _this.dataCachingService.getCachedData('EditStableProductionTemplateProductionStableProductSubtypes', _this.id + "-" + el.sourceProductTypeId + "-" + el.sourceProductSubtypeId);
                    }
                    existingEl.push.apply(existingEl, el.productionStableProductSubtypes);
                });
                _this.waitingOverlay.EndWaiting();
            }, function () {
                _this.waitingOverlay.EndWaiting();
            });
        }
    };
    EditStableProductionTemplateComponent.prototype.deleteTemplate = function (item, index) {
        var idToDelete = this.templates.controls[index].get('id').value;
        if (idToDelete) {
            this.model.delete.push(item.value);
        }
        this.templates.removeAt(index);
        this.templates.markAsDirty();
    };
    EditStableProductionTemplateComponent.prototype.addTemplate = function () {
        var fg = this.fb.group(this.getProductionTemplateGroupDef());
        this.templates.push(fg);
        FormHelper.markAsSubmitted(fg);
        this.contextFormGroup.markAsDirty();
    };
    EditStableProductionTemplateComponent.prototype.store = function () {
        var _this = this;
        FormHelper.markAsSubmitted(this.contextFormGroup);
        if (!this.contextFormGroup.valid) {
            return;
        }
        this.storing = true;
        this.stableDataService.storeProductionTemplate(this.id, this.templates.value, this.model.delete)
            .pipe(finalize(function () { return _this.storing = false; }))
            .subscribe({
            next: function (updated) {
                _this.model = { id: _this.id, delete: [] };
                _this.ensureFormGroup(updated);
                _this.dataCachingService.addToCache('EditStableProductionTemplate', _this.id.toString(), _this.model);
                FormHelper.setSingleFormGroupServerSideValidationErrors({}, _this.model, _this.contextFormGroup);
            },
            error: function (err) {
                FormHelper.setSingleFormGroupServerSideValidationErrors(err, _this.model, _this.contextFormGroup);
            }
        });
    };
    EditStableProductionTemplateComponent.prototype.addProductBatch = function (item) {
        item.get('productionStableProductSubtypes').push(this.fb.group(this.getStableProductionTemplateProductBatchGroupDef()));
        this.templates.markAsDirty();
        this.contextFormGroup.markAsDirty();
    };
    EditStableProductionTemplateComponent.prototype.cancelEdit = function () {
        this.refreshFormData();
    };
    return EditStableProductionTemplateComponent;
}());
export { EditStableProductionTemplateComponent };
