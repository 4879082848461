import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BookmarkService} from '../../logic/services/bookmark.service';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {DataCachingService} from '../../logic/services/data-caching.service';
import {GroupOperationsService} from '../../logic/services/group-operations.service';

import {MetadataService} from '../../logic/services/metadata.service';
import {Observable, Subscription, timer} from 'rxjs/index';
import {environment} from '../../../environments/environment';
import {FormHelper} from '../../ui/controls/form-helper';
import {GroupOperationMetadataService} from '../../logic/services/group-operation-metadata.service';
import {AgentSearchModalComponent} from '../../pages/edit-agent/search/agent-search-modal.component';
import {AppNavigationService} from '../../logic/services/app-navigation.service';

@Component({
  templateUrl: './change-agent-indicators-runner.component.html'
})
export class ChangeAgentIndicatorsRunnerComponent implements OnInit, OnDestroy {

  OperationParamsCode = 'pstReportChangeIndicators';
  OperationTypeId = 21; /*Замена хозяйствующего субъекта в электронных отчетах*/
  ComponentModelCacheId: string;

  model: any = ChangeAgentIndicatorsRunnerComponent.createNewModel();

  private tickTimer: Observable<number>;
  private tickSub: Subscription;

  get contextFormGroup(): FormGroup {
    return this.model.form;
  }

  static createNewModel(): any {
    return {
      form: undefined,
      operationId: undefined,
      operationTypeId: undefined,
      state: undefined,
      result: undefined,
      started: false,
      completed: false,
      startedFromOtherUi: false,
      newlyEnteredFile: undefined,
    };
  }

  constructor(private bmService: BookmarkService,
              private fb: FormBuilder,
              private dataCachingService: DataCachingService,
              private route: ActivatedRoute,
              private groupOperationsService: GroupOperationsService,
              private groupOperationMetadataService: GroupOperationMetadataService,
              private metadataService: MetadataService,
              private navigationService: AppNavigationService) {
  }

  ngOnInit(): void {
    this.tickTimer = timer(1000, 1000);
    this.tickSub = this.tickTimer.subscribe(t => this.checkCurrentOperationState());

    this.route.paramMap
      .subscribe(params => {
        this.ComponentModelCacheId = 'GroupOp_' + this.OperationParamsCode;

        const randomUniqueTag = params.get('uniqueTag');

        const existing = this.dataCachingService.getCachedData(this.ComponentModelCacheId, randomUniqueTag);

        if (!existing) {
          this.model.operationId = undefined;

          this.model.operationTypeId = this.OperationTypeId;

          this.model.form = this.fb.group({
            type: 'pstReportChangeIndicators',
            sourceAgentId: [null, Validators.required],
            destinationAgentId: [null, Validators.required],
            filterRegionId: null,
          });

          this.dataCachingService.addToCache(this.ComponentModelCacheId, randomUniqueTag, this.model);
        } else {
          this.model = existing;
        }

        this.addBookmark(params);
      });
  }

  ngOnDestroy(): void {
    if (this.tickSub) {
      this.tickSub.unsubscribe();
      this.tickSub = undefined;
    }
  }

  checkCurrentOperationState() {
    if (!this.model.operationId
      || (this.model.state && this.model.state.state === 3)
      || (this.model.state && this.model.state.state === 5)) {
      return;
    }

    this.groupOperationsService.getOperationStatus(this.model.operationTypeId, this.model.operationId)
      .subscribe(state => {
        this.model.state = state;

        if (this.model.state.state === 3 || this.model.state.state === 5) {
          this.completeOperation();
        }
      });
  }

  runOperation() {
    if (!this.contextFormGroup.valid || this.model.started) {
      return;
    }

    const model = this.model;
    model.started = true;

    this.groupOperationsService.runOperation(
      this.model.operationTypeId,
      this.contextFormGroup.value,
      this.model.newlyEnteredFile).subscribe(
      opKey => {
        model.operationId = opKey.id;
      },
      () => {
        model.started = false;
      }
    );
  }

  isInvalid(cname: string) {
    return FormHelper.isInvalid(this.contextFormGroup, cname);
  }

  private completeOperation() {

    if (this.model.completed) {
      return;
    }

    this.model.completed = true;

    this.groupOperationsService.getOperationResult(this.model.operationTypeId, this.model.operationId)
      .subscribe(result => {
        this.model.result = result;

        if (this.model.result.result && !this.model.startedFromOtherUi) {
          this.navigateResults();
        }
      });
  }

  navigateResults() {
    this.metadataService.preloadFile(this.model.result.result).subscribe(fileHash => {
      window.open(environment.api + '/files/get?preloadId=' + encodeURIComponent(fileHash));
    });
  }

  getPercProgress() {
    return this.model.state ? Math.floor(this.model.state.progress / 100) : 0;
  }

  addBookmark(params: ParamMap) {
    const randomUniqueTag = params.get('uniqueTag');
    this.bmService.addGroupOperationBookmark('Замена хозяйствующего субъекта в электронных отчетах', randomUniqueTag,
      ['/operations/pst-change-agent-indicators']);
  }

  searchAgent(fieldName: string) {
    this.navigationService.searchAgent(AgentSearchModalComponent).subscribe(val => {
      if (val) {
        this.contextFormGroup.get(fieldName).setValue(val);
        this.contextFormGroup.markAsDirty();
      }
    });
  }
}
