
      <ag-grid-angular #agGrid class="app-grid-editable ag-theme-balham" [style.width.px]="widthGrid" [style.height.px]="gridHeight"
                       [rowHeight]="defaultRowHeight" [frameworkComponents]="frameworkComponents"
                       [enableFillHandle]="true" [enableRangeSelection]="true" [fillOperation]="fillOperation"
                       [rowData]="formArray.controls" [columnDefs]="colDefs" [localeTextFunc]="agGridLocaleTextFunc"
                       [defaultColDef]="defColDef" (gridReady)="onGridReady($event)" [statusBar]="statusBar"
                       [suppressRowTransform]="true" [overlayNoRowsTemplate]="'Нет данных для отображения'"
                       [masterDetail]="true" [detailCellRenderer]="'formMasterDetailComponent'" [detailRowHeight]="detailRowHeight"
                       [detailCellRendererParams]="{}" [singleClickEdit]="singleClickEdit" (cellKeyDown)="onCellKeyDown($event)"
                       [suppressPropertyNamesCheck]="true" [getContextMenuItems]="getContextMenuItems"
                       [processCellForClipboard]="processCellForClipboard" [processCellFromClipboard]="processCellFromClipboard">
      </ag-grid-angular>