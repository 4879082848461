import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Observable, of } from 'rxjs';
import { AddressPersonFioCacheService } from '../../logic/services/address-person-fio-cache.service';
import { LookupSourceService } from '../../logic/services/lookup-source.service';
import { map } from 'rxjs/operators';
import { AppNavigationService } from '../../logic/services/app-navigation.service';
import { FormHelper } from './form-helper';


@Component({
  selector: 'app-ag-grid-lookup-renderer',
  template: `
      <ng-container *ngIf="isPipe; else noPipe">
          <button *ngIf="params.value && equalsSome(params.lookupName, 'agent', 'animal', 'product', 'stable')"
                  type="button" class="btn btn-link" (click)="performAction()" [title]="'Перейти к карточке'"
                  style="position:absolute;top:7px;left:0;padding:0;margin:0;border:none;min-width:20px;max-height:20px;min-height:20px;">
              <clr-icon shape="angle right" style="vertical-align:super"></clr-icon>
          </button>
          <div style="min-height:24px;padding-left:5px"
               [title]="params.value|lookup:params.lookupName + (params.value2 || ''):true:false:false:true|async"
               [innerHtml]="params.value|lookup:params.lookupName + (params.value2 || ''):true:true:true|async"></div>
      </ng-container>
      <ng-template #noPipe>
          <div *ngIf="isGroup" (click)="onClickExpanded()"
               [class.first-grouping]="params.node.rowGroupIndex === 0"
               [class.second-grouping]="params.node.rowGroupIndex === 1"
               [class.third-grouping]="params.node.rowGroupIndex === 2"
               [class.fourth-grouping]="params.node.rowGroupIndex === 3"
               [class.fifth-grouping]="params.node.rowGroupIndex === 4">
              <clr-icon *ngIf="params.node.expanded" shape="angle up"></clr-icon>
              <clr-icon *ngIf="!params.node.expanded" shape="angle down"></clr-icon>
              {{ getLookup$() | async }}&nbsp;
              {{ params.comment ? params.comment : ''}}
          </div>
          <span *ngIf="!isGroup && params && params.navigateAction" class="itech-link in-table-link"
                (click)="navigateAction()">{{ getLookup$() | async }}</span>
          <span *ngIf="!isGroup && params && !params.navigateAction">{{ getLookup$() | async }}</span>
      </ng-template>`
})
export class AgGridLookupRendererComponent implements ICellRendererAngularComp {

  constructor(private cacheService: AddressPersonFioCacheService,
              private lookupService: LookupSourceService,
              private navigationService: AppNavigationService) {}

  public params: any;
  public isGroup = false;
  public isPipe = false;

  equalsSome = FormHelper.equalsSome;

  // called on init
  agInit(params: any): void {
    this.params = params;
    this.isPipe = params && params.isPipe;
    this.isGroup = params && params.node && params.node.group;
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  public getLookup$(): Observable<any> {
    if (!this.params || !this.params.lookupName || !this.params.value || (this.params.requiredValue2 && !this.params.value2)) {
      return of(this.params.value ? this.params.value : '');
    }
    // objId2 - составной второй ключ для лукапа, objId2FieldName это имя поля, где в данных хранится ключ
    const objId2 = this.params.value2
      ? this.params.value2
      : this.params.objId2FieldName
        ? this.params.data[this.params.objId2FieldName]
        : null;
    return this.lookupService.getLookupCaption(this.params.value, this.params.lookupName, this.params.useShort, objId2)
      .pipe(map(caption => caption === 'N/A' && this.params.captionIfNotFound ? this.params.captionIfNotFound : caption));
  }

  navigateAction() {
    if (this.params.navigateAction) {
      const objId2 = this.params.objId2FieldName ? this.params.data[this.params.objId2FieldName] : null;
      this.params.navigateAction(this.params.value, objId2);
    }
  }

  onClickExpanded() {
    this.params.api.setRowNodeExpanded(this.params.node, !this.params.node.expanded);
  }

  performAction() {
    if (this.params.lookupName === 'agent') {
      this.navigationService.performAgentEditing(this.params.value);
    } if (this.params.lookupName === 'animal') {
      this.navigationService.performAnimalEditing(this.params.value);
    } else if (this.params.lookupName === 'product') {
      this.navigationService.performProductEditing(this.params.value);
    } else if (this.params.lookupName === 'stable') {
      this.navigationService.performStableEditing(this.params.value);
    }
  }
}
