import { Component, OnInit } from '@angular/core';
import { MetadataService } from '../../logic/services/metadata.service';
import { DataCachingService } from '../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../ui/infrastructure/global-waiting-overlay.service';
import {BookmarkService} from '../../logic/services/bookmark.service';

@Component({
  selector: 'app-product-statistic',
  templateUrl: './product-statistic.component.html',
})
export class ProductStatisticComponent implements OnInit {

  model: any[] = [];

  gridColumnDefs = [
    {
      headerName: 'Наименование', field: 'caption', width: 270,
    },
    {
      headerName: 'Текущий год', field: 'currentYear', width: 140,
    },
    {
      headerName: 'Текущий месяц', field: 'currentMonth', width: 140,
    },
    {
      headerName: 'Текущая неделя', field: 'currentWeek', width: 140,
    },
    {
      headerName: 'За 30 дней', field: 'prev30Days', width: 110,
    },
    {
      headerName: 'За 7 дней', field: 'prev7Days', width: 110,
    },
    {
      headerName: 'Сегодня', field: 'today', width: 110,
    },
  ];

  constructor(private metadataService: MetadataService,
              private dataCachingService: DataCachingService,
              private globalWaitingOverlayService: GlobalWaitingOverlayService,
              private bookmarkService: BookmarkService) {
  }

  ngOnInit() {
    this.refreshData();
    this.bookmarkService.addDictionaryBookmark(
      'Статистика ВСД',
      ['/product-statistic']);
  }

  refreshData() {
    this.globalWaitingOverlayService.StartWaiting();
    const existing = this.dataCachingService.getCachedData('productStatistic', '1');

    if (existing) {
      this.model = existing;
      this.globalWaitingOverlayService.EndWaiting();
    } else {
      this.metadataService.getStatistic()
        .subscribe(res => {
          const data = [];
          Object.keys(res).forEach(k => {
            data.push({
              caption: this.getKeyCaption(k),
              currentYear: res[k].currentYear,
              currentMonth: res[k].currentMonth,
              currentWeek: res[k].currentWeek,
              prev30Days: res[k].prev30Days,
              prev7Days: res[k].prev7Days,
              today: res[k].today
            });
          });
          this.model = data;
          this.dataCachingService.addToCache('productStatistic', '1', this.model);
          this.globalWaitingOverlayService.EndWaiting();
        });
    }
  }

  getKeyCaption(key: string) {
    switch (key) {
      case 'addedAllProduct': return 'Всего ВСД';
      case 'addedAllProductionProduct': return 'Производственных ВСД';
      case 'addedAllTransportProduct': return 'Транспортных ВСД';
      case 'addedUsersProduct': return 'Всего ВСД (пользователями)';
      case 'addedUsersProductionProduct': return 'Производственных ВСД (пользователями)';
      case 'addedUsersTransportProduct': return 'Транспортных ВСД (пользователями)';
      case 'clearedUsersTransportProduct': return 'Погашено ВСД';
      case 'deletedUsersTransportProduct': return 'Аннулированно ВСД';
      case 'addedUsersTransaction': return 'Транзакций';
      case 'addedUsersExpertise': return 'ВСЭ';
      default: return 'Неизвестно';
    }
  }

  refreshResults() {
    this.dataCachingService.removeCachedData('productStatistic', '1');
    this.refreshData();
  }
}
