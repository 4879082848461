/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./view-quarantine-animalstable-events-search-results.component.ngfactory";
import * as i2 from "./view-quarantine-animalstable-events-search-results.component";
import * as i3 from "../../../logic/services/quarantine-data.service";
import * as i4 from "../../../logic/services/app-navigation.service";
import * as i5 from "../../../logic/services/lookup-source.service";
import * as i6 from "./view-quarantine-animalstable-events.component";
var styles_ViewQuarantineAnimalstableEventsComponent = [];
var RenderType_ViewQuarantineAnimalstableEventsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ViewQuarantineAnimalstableEventsComponent, data: {} });
export { RenderType_ViewQuarantineAnimalstableEventsComponent as RenderType_ViewQuarantineAnimalstableEventsComponent };
export function View_ViewQuarantineAnimalstableEventsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "itech-common-wrap"], ["id", "interceptNoAccess"], ["style", "top:35px;margin-left:0"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["style", "width: 100%;height: 100%"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-view-quarantine-animalstable-events-search-results", [], null, null, null, i1.View_ViewQuarantineAnimalstableEventsSearchResultsComponent_0, i1.RenderType_ViewQuarantineAnimalstableEventsSearchResultsComponent)), i0.ɵdid(3, 573440, null, 0, i2.ViewQuarantineAnimalstableEventsSearchResultsComponent, [i3.QuarantineDataService, i4.AppNavigationService, i5.LookupSourceService], { quarantineId: [0, "quarantineId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.quarantineId; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ViewQuarantineAnimalstableEventsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-view-quarantine-animalstable-events", [], null, null, null, View_ViewQuarantineAnimalstableEventsComponent_0, RenderType_ViewQuarantineAnimalstableEventsComponent)), i0.ɵdid(1, 49152, null, 0, i6.ViewQuarantineAnimalstableEventsComponent, [], null, null)], null, null); }
var ViewQuarantineAnimalstableEventsComponentNgFactory = i0.ɵccf("app-view-quarantine-animalstable-events", i6.ViewQuarantineAnimalstableEventsComponent, View_ViewQuarantineAnimalstableEventsComponent_Host_0, { quarantineId: "quarantineId" }, {}, []);
export { ViewQuarantineAnimalstableEventsComponentNgFactory as ViewQuarantineAnimalstableEventsComponentNgFactory };
