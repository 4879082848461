<div class="itech-common-wrap" id="interceptNoAccess">
  <div style="width: 100%">
    <app-grid [pagination]="false" [getRowStyle]="getRowStyle" [uniqueGridName]="'EditStableEventsGrid'"
              [rowData]="model.searchResults" (rowClicked)="navigationService.performAnimalEventEditing($event.data.id)"
              [columnDefs]="gridColumnDefs"
              [exportFileName]="'Список мероприятий у ПО ' + (id|lookup:'stable'|async)"></app-grid>
  </div>

  <div class="itech-form-actions">
    <div>
      <button class="btn btn-secondary" (click)="refreshFormData()">
        <clr-icon shape="refresh"></clr-icon>&nbsp;Обновить
      </button>
      <button class="btn btn-primary-outline" (click)="navigationService.navigateJournal(undefined, 13, id, undefined)">
        <clr-icon shape="view-list"></clr-icon>&nbsp;Журнал
      </button>
      <button class="btn btn-success-outline" (click)="addNew()">
        <clr-icon shape="plus"></clr-icon>&nbsp;Добавить
      </button>
    </div>
  </div>
</div>
