import { OnInit } from '@angular/core';
import { FormHelper } from '../../ui/controls/form-helper';
import { StringHelper } from '../../helpers/string-helper';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DataCachingService } from '../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../ui/infrastructure/global-waiting-overlay.service';
import { MetadataService } from '../../logic/services/metadata.service';
import { BookmarkService } from '../../logic/services/bookmark.service';
import { DateHelper } from '../../helpers/date-helper';
var InstitutionEmployeeStatisticComponent = /** @class */ (function () {
    function InstitutionEmployeeStatisticComponent(route, dataCachingService, globalWaitingOverlayService, metadataService, fb, bookmarkService) {
        this.route = route;
        this.dataCachingService = dataCachingService;
        this.globalWaitingOverlayService = globalWaitingOverlayService;
        this.metadataService = metadataService;
        this.fb = fb;
        this.bookmarkService = bookmarkService;
        this.model = InstitutionEmployeeStatisticComponent.getDefaultModel(this.fb);
        this.isInvalid = FormHelper.isInvalid;
        this.defColDef = {
            sortable: true,
            filter: true,
            resizable: true,
        };
        this.gridColumnDefs = [];
    }
    InstitutionEmployeeStatisticComponent.getDefaultModel = function (fb, defaultFilterParams) {
        return {
            searchResults: [],
            pinnedBottomRowData: [],
            filterFormGroup: fb ? InstitutionEmployeeStatisticComponent.buildSearchForm(fb, defaultFilterParams) : undefined,
        };
    };
    InstitutionEmployeeStatisticComponent.buildSearchForm = function (fb, defaultFilterParams) {
        if (defaultFilterParams === void 0) { defaultFilterParams = {}; }
        return fb.group({
            typeId: [defaultFilterParams.typeId, Validators.required],
            institutionId: defaultFilterParams.institutionId,
            dateFrom: [defaultFilterParams.dateFrom, Validators.compose([Validators.required, FormHelper.validateDateTimePicker()])],
            dateTo: [defaultFilterParams.dateTo, Validators.compose([Validators.required, FormHelper.validateDateTimePicker()])],
            viewType: 1,
        });
    };
    InstitutionEmployeeStatisticComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.paramMap
            .subscribe(function () {
            var existing = _this.dataCachingService.getCachedData('InstitutionEmployeeStatisticComponent', '1');
            if (existing) {
                _this.model = existing;
            }
            else {
                _this.model = InstitutionEmployeeStatisticComponent.getDefaultModel(_this.fb, {
                    dateFrom: StringHelper.getISODate(DateHelper.startOfTheWeek(DateHelper.addMonths(new Date(), -1))),
                    dateTo: StringHelper.getISODate(DateHelper.addDays(DateHelper.endOfTheWeek(new Date()), 1)),
                });
                _this.dataCachingService.addToCache('InstitutionEmployeeStatisticComponent', '1', _this.model);
                _this.refreshResults();
            }
            _this.bookmarkService.addDictionaryBookmark('Статистика действий пользователей', ['/institution-employee-statistic/']);
        });
    };
    InstitutionEmployeeStatisticComponent.prototype.agGridReady = function (params) {
        this.gridApi = params.api;
    };
    InstitutionEmployeeStatisticComponent.prototype.refreshResults = function () {
        var _this = this;
        FormHelper.markAsSubmitted(this.model.filterFormGroup);
        if (!this.model.filterFormGroup.valid) {
            return;
        }
        this.dataCachingService.removeCachedData('InstitutionEmployeeStatisticComponent', 'sourceData');
        this.globalWaitingOverlayService.StartWaiting();
        this.metadataService.getEmployeeStatistic(this.model.filterFormGroup.get('typeId').value, new Date(this.model.filterFormGroup.get('dateFrom').value), new Date(this.model.filterFormGroup.get('dateTo').value))
            .subscribe({
            next: function (data) {
                _this.dataCachingService.addToCache('InstitutionEmployeeStatisticComponent', 'sourceData', data);
                _this.buildDynamicTable(data);
                _this.globalWaitingOverlayService.EndWaiting();
            }, error: function () {
                _this.globalWaitingOverlayService.EndWaiting();
            }
        });
    };
    InstitutionEmployeeStatisticComponent.prototype.filterEnterPressed = function ($event) {
        if ($event.key === 'Enter') {
            this.refreshResults();
        }
    };
    InstitutionEmployeeStatisticComponent.prototype.buildDynamicTable = function (data) {
        var _this = this;
        if (data === void 0) { data = []; }
        this.gridColumnDefs = [];
        this.gridColumnDefs.push(this.getInstitutionColumn());
        if (+this.model.filterFormGroup.get('viewType').value === 2) {
            this.gridColumnDefs.push(this.getUserColumn());
        }
        var dates = [];
        var groupingData = {};
        data.sort(function (a, b) { return a.dateFrom.localeCompare(b.dateFrom); });
        data.forEach(function (el) {
            if (!dates.includes(el.dateFrom)) {
                _this.gridColumnDefs.push({
                    headerName: StringHelper.getRuDate(el.dateFrom) + '\n' + StringHelper.getRuDate(el.dateTo),
                    field: el.dateFrom,
                    width: 110,
                    valueGetter: function (params) { return params.data && params.data[el.dateFrom] ? params.data[el.dateFrom].count : 0; },
                    cellRenderer: _this.countsCellRenderer
                });
                dates.push(el.dateFrom);
            }
            if (+_this.model.filterFormGroup.get('viewType').value === 2) {
                _this.groupingByUser(groupingData, el);
            }
            else {
                _this.groupingByInstitution(groupingData, el);
            }
        });
        this.gridColumnDefs.push({ headerName: 'Итого', sort: 'desc', field: 'sum', width: 110, cellStyle: { 'font-weight': 'bold' } });
        var newData = Object.values(groupingData);
        var pinnedBottomRowData = { institutionCaption: 'Итого', sum: 0 };
        newData.forEach(function (el) {
            if (!(dates[0] in el)) {
                el[dates[0]] = { count: 0, diff: 0 };
            }
            if (!pinnedBottomRowData[dates[0]]) {
                pinnedBottomRowData[dates[0]] = { count: 0, diff: 0 };
            }
            pinnedBottomRowData[dates[0]].count += el[dates[0]].count;
            pinnedBottomRowData.sum += el[dates[0]].count;
            el['sum'] = el[dates[0]].count;
        });
        var _loop_1 = function (i) {
            if (!pinnedBottomRowData[dates[i]]) {
                pinnedBottomRowData[dates[i]] = { count: 0, diff: 0 };
            }
            newData.forEach(function (el) {
                if (dates[i] in el) {
                    if (dates[i - 1] in el) {
                        el[dates[i]].diff = el[dates[i]].count - el[dates[i - 1]].count;
                    }
                    else {
                        el[dates[i]].diff = el[dates[i]].count;
                    }
                }
                else if (dates[i - 1] in el) {
                    el[dates[i]] = { count: 0, diff: el[dates[i - 1]].count * -1 };
                }
                else {
                    el[dates[i]] = { count: 0, diff: 0 };
                }
                el['sum'] += el[dates[i]].count;
                pinnedBottomRowData[dates[i]].count += el[dates[i]].count;
                pinnedBottomRowData[dates[i]].diff = pinnedBottomRowData[dates[i]].count - pinnedBottomRowData[dates[i - 1]].count;
                pinnedBottomRowData.sum += el[dates[i]].count;
            });
        };
        for (var i = 1; i < dates.length; i++) {
            _loop_1(i);
        }
        this.model.searchResults = newData.filter(function (el) { return +el['sum']; });
        this.model.pinnedBottomRowData = [pinnedBottomRowData];
        if (this.gridApi) {
            this.gridApi.setRowData(this.model.searchResults);
            this.gridApi.setPinnedBottomRowData(this.model.pinnedBottomRowData);
        }
    };
    InstitutionEmployeeStatisticComponent.prototype.groupingByUser = function (groupingData, rowData) {
        if (!groupingData[rowData.userId]) {
            groupingData[rowData.userId] = {
                userId: rowData.userId,
                userCaption: rowData.userCaption,
                institutionCaption: rowData.institutionCaption,
                institutionId: rowData.institutionId,
            };
        }
        this.fillRowData(groupingData, rowData.userId, rowData);
    };
    InstitutionEmployeeStatisticComponent.prototype.groupingByInstitution = function (groupingData, rowData) {
        if (!groupingData[rowData.institutionId]) {
            groupingData[rowData.institutionId] = {
                institutionCaption: rowData.institutionCaption,
                institutionId: rowData.institutionId,
            };
        }
        this.fillRowData(groupingData, rowData.institutionId, rowData);
    };
    InstitutionEmployeeStatisticComponent.prototype.fillRowData = function (groupingData, mainFieldName, rowData) {
        if (!groupingData[mainFieldName][rowData.dateFrom]) {
            groupingData[mainFieldName][rowData.dateFrom] = { count: 0, diff: 0 };
        }
        groupingData[mainFieldName][rowData.dateFrom].count += (rowData[this.getFieldNameByCurrentType()] || 0);
    };
    InstitutionEmployeeStatisticComponent.prototype.getFieldNameByCurrentType = function () {
        if (+this.model.filterFormGroup.get('typeId').value === 1) {
            return 'common';
        }
        if (+this.model.filterFormGroup.get('typeId').value === 2) {
            return 'agentCreated';
        }
        if (+this.model.filterFormGroup.get('typeId').value === 3) {
            return 'agentEdited';
        }
        if (+this.model.filterFormGroup.get('typeId').value === 4) {
            return 'stableCreated';
        }
        if (+this.model.filterFormGroup.get('typeId').value === 5) {
            return 'stableEdited';
        }
        if (+this.model.filterFormGroup.get('typeId').value === 6) {
            return 'animalCreated';
        }
        if (+this.model.filterFormGroup.get('typeId').value === 7) {
            return 'animalEdited';
        }
        if (+this.model.filterFormGroup.get('typeId').value === 8) {
            return 'drugCreated';
        }
        if (+this.model.filterFormGroup.get('typeId').value === 9) {
            return 'drugEdited';
        }
        if (+this.model.filterFormGroup.get('typeId').value === 10) {
            return 'eventCreated';
        }
        if (+this.model.filterFormGroup.get('typeId').value === 11) {
            return 'eventEdited';
        }
        if (+this.model.filterFormGroup.get('typeId').value === 12) {
            return 'productExpertiseCreated';
        }
        if (+this.model.filterFormGroup.get('typeId').value === 13) {
            return 'productExpertiseEdited';
        }
        if (+this.model.filterFormGroup.get('typeId').value === 14) {
            return 'productConfirm';
        }
        if (+this.model.filterFormGroup.get('typeId').value === 15) {
            return 'productCleared';
        }
        return '';
    };
    InstitutionEmployeeStatisticComponent.prototype.getUserColumn = function () {
        return {
            headerName: 'Пользователь', field: 'userId', width: 170, pinned: 'left',
            valueGetter: function (params) { return params && params.data ? params.data.userCaption : '<<неизвестно>>'; },
        };
    };
    InstitutionEmployeeStatisticComponent.prototype.getInstitutionColumn = function () {
        return {
            headerName: 'Учреждение', field: 'institutionId', width: 240, pinned: 'left',
            valueGetter: function (params) { return params && params.data ? params.data.institutionCaption : '<<неизвестно>>'; },
        };
    };
    InstitutionEmployeeStatisticComponent.prototype.changeViewType = function () {
        var cachedData = this.dataCachingService.getCachedData('InstitutionEmployeeStatisticComponent', 'sourceData');
        if (cachedData) {
            this.buildDynamicTable(cachedData);
        }
        else {
            this.refreshResults();
        }
    };
    InstitutionEmployeeStatisticComponent.prototype.countsCellRenderer = function (row) {
        if (!row.data || !row.colDef || !row.colDef.field) {
            return 0;
        }
        var value = row.data[row.colDef.field];
        var difference = !!value.diff
            ? value.diff < 0
                ? ' <clr-icon shape="caret down" size="12" style="color:red"></clr-icon><span style="color:red">' + (-value.diff) + '</span>'
                : ' <clr-icon shape="caret up" size="12" style="color:green"></clr-icon><span style="color:green">' + value.diff + '</span>'
            : '';
        return value.count + '&nbsp;' + difference;
    };
    return InstitutionEmployeeStatisticComponent;
}());
export { InstitutionEmployeeStatisticComponent };
