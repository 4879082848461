import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { SecurityService } from '../../logic/services/security.service';
import { finalize } from 'rxjs/operators';
import { FormHelper } from './form-helper';
import {FormBuilder, Validators} from '@angular/forms';
import { DataCachingService } from '../../logic/services/data-caching.service';

@Component({
  selector: 'app-auth-mercury',
  template: `
<ng-container *ngIf="currentFormGroup">
  <ng-container *ngIf="currentFormGroup.controls['authMercuryInProcess'].value; else hasCurrentSession">
    <div style="display:flex;justify-content:center;overflow:hidden;">
      <span class="spinner spinner-md"></span>
    </div>
  </ng-container>
  <ng-template #hasCurrentSession>
    <ng-container *ngIf="(securityService.getUserMercurySession() | async); else noCurrentSession">
      <span style="font-size:12px;color:black;">Вы успешно авторизованы в Меркурии</span>
      <a class="itech-person-link not-icon" style="font-size:12px" (click)="logout()">
        Выйти
      </a>
    </ng-container>
  </ng-template>
  <ng-template #noCurrentSession>
    <ng-container *ngIf="!currentFormGroup.controls['authMercuryStatusId'].value; else authProcess">
      <span style="font-size:12px;color:black;">Вы не авторизованы в Меркурии</span>
      <a class="itech-person-link not-icon" style="font-size:12px" (click)="goToAuthMercury()">
        Авторизоваться
      </a>
    </ng-container>
    <ng-template #authProcess>
      <div *ngIf="equalsSome(currentFormGroup.controls['authMercuryStatusId'].value, 1, 2) && currentFormAuthMercuryGroup"
           class="compact form itech-highlighted-sections" [formGroup]="currentFormAuthMercuryGroup">
        <section class="form-block itech-block-normal"
                 [style.min-height.px]="currentFormGroup.controls['authMercuryStatusId'].value === 1
                   ? 140
                   : currentFormGroup.controls['authMercuryStatusId'].value === 2 ? 90 : undefined">
          <ng-container *ngIf="currentFormGroup.controls['authMercuryStatusId'].value === 1; else authStatusTelCode">
            <div class="form-group">
              <label for="authMercuryLogin" class="required">Логин</label>
              <label for="authMercuryLogin" aria-haspopup="true" role="tooltip"
                     class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                     [class.invalid]="isInvalid(currentFormAuthMercuryGroup, 'login')">
                <input type="text" id="authMercuryLogin" placeholder="Логин" class="itech-control-medium" formControlName="login">
                <app-validation-tooltip [input]="currentFormAuthMercuryGroup.controls['login']"></app-validation-tooltip>
              </label>
            </div>
            <div class="form-group">
              <label for="authMercuryPassword" class="required">Пароль</label>
              <label for="authMercuryPassword" aria-haspopup="true" role="tooltip"
                     class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                     [class.invalid]="isInvalid(currentFormAuthMercuryGroup, 'password')">
                <input type="password" id="authMercuryPassword" placeholder="Пароль" class="itech-control-medium"
                       formControlName="password">
                <app-validation-tooltip [input]="currentFormAuthMercuryGroup.controls['password']"></app-validation-tooltip>
              </label>
            </div>
          </ng-container>
          <ng-template #authStatusTelCode>
            <ng-container *ngIf="currentFormGroup.controls['authMercuryStatusId'].value === 2">
              <div class="form-group">
                <label for="authMercuryTelCode" class="required">Код из смс</label>
                <label for="authMercuryTelCode" aria-haspopup="true" role="tooltip"
                       class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                       [class.invalid]="isInvalid(currentFormAuthMercuryGroup, 'telCode')">
                  <input type="text" id="authMercuryTelCode" placeholder="Код из смс" class="itech-control-medium"
                         formControlName="telCode">
                  <app-validation-tooltip [input]="currentFormAuthMercuryGroup.controls['telCode']"></app-validation-tooltip>
                </label>
              </div>
            </ng-container>
          </ng-template>
          <a class="itech-person-link not-icon" style="font-size:12px" (click)="tryAuthMercury()">
            Войти
          </a>
        </section>
      </div>
    </ng-template>
  </ng-template>
</ng-container>
`,
})
export class AuthMercuryComponent implements OnInit {

  @Input() openAuthMercuryDropdownEvent = new EventEmitter();

  currentFormGroup = undefined;
  currentFormAuthMercuryGroup = undefined;

  equalsSome = FormHelper.equalsSome;
  isInvalid = FormHelper.isInvalid;

  private static getGroupDef(fb: FormBuilder) {
    return fb.group({
      authMercuryStatusId: undefined,
      authMercuryContent: undefined,
      authMercuryInProcess: false,
    });
  }

  private static getAuthMercuryGroupDef(fb: FormBuilder, val: any = {}) {
    return fb.group({
      authMercuryStatusId: val.authMercuryStatusId,
      login: [val.login, FormHelper.conditionalValidate(Validators.required,
        ctrl => ctrl.parent && +ctrl.parent.get('authMercuryStatusId').value === 1)],
      password: [val.password, FormHelper.conditionalValidate(Validators.required,
        ctrl => ctrl.parent && +ctrl.parent.get('authMercuryStatusId').value === 1)],
      telCode: [val.telCode, FormHelper.conditionalValidate(Validators.required,
        ctrl => ctrl.parent && +ctrl.parent.get('authMercuryStatusId').value === 2)],
      captcha: [val.captcha],
    });
  }

  constructor(private securityService: SecurityService,
              private fb: FormBuilder,
              private dataCachingService: DataCachingService) {
  }

  ngOnInit() {
    if (!this.currentFormGroup) {
      const existing = this.dataCachingService.getCachedData('AuthMercuryComponent', '1');
      if (existing) {
        this.currentFormGroup = existing;
      } else {
        this.currentFormGroup = AuthMercuryComponent.getGroupDef(this.fb);
        this.dataCachingService.addToCache('AuthMercuryComponent', '1', this.currentFormGroup);
      }
    }
    if (!this.currentFormAuthMercuryGroup) {
      const existing = this.dataCachingService.getCachedData('AuthMercuryComponent', '2');
      if (existing) {
        this.currentFormAuthMercuryGroup = existing;
      } else {
        this.currentFormAuthMercuryGroup = AuthMercuryComponent.getAuthMercuryGroupDef(this.fb);
        this.dataCachingService.addToCache('AuthMercuryComponent', '2', this.currentFormAuthMercuryGroup);
      }
    }
  }

  goToAuthMercury() {
    this.currentFormGroup.get('authMercuryInProcess').setValue(true);
    this.securityService.getUserMercuryGoToAuth()
      .pipe(finalize(() => this.currentFormGroup.get('authMercuryInProcess').setValue(false)))
      .subscribe(res => this.postProcess(res));
  }

  tryAuthMercury() {
    this.currentFormAuthMercuryGroup.get('authMercuryStatusId').setValue(this.currentFormGroup.get('authMercuryStatusId').value);

    FormHelper.markAsSubmitted(this.currentFormAuthMercuryGroup);

    if (!this.currentFormAuthMercuryGroup.valid) {
      return;
    }

    this.currentFormGroup.get('authMercuryInProcess').setValue(true);
    this.securityService.tryUserMercuryAuth(this.currentFormAuthMercuryGroup.value)
      .pipe(finalize(() => this.currentFormGroup.get('authMercuryInProcess').setValue(false)))
      .subscribe(res => this.postProcess(res));
  }

  postProcess(result: any) {
    this.currentFormGroup.get('authMercuryStatusId').setValue(result.authMercuryStatusId);
    this.currentFormGroup.get('authMercuryContent').setValue(result.content);
    this.tryInitAuthMercuryFg(result);
  }

  tryInitAuthMercuryFg(result: any) {
    if (FormHelper.equalsSome(+this.currentFormGroup.get('authMercuryStatusId').value, 100)) {
      this.securityService.invalidateUserMercurySession();
      this.currentFormAuthMercuryGroup = undefined;
    } else if (FormHelper.equalsSome(+this.currentFormGroup.get('authMercuryStatusId').value, 1, 2)) {
      this.currentFormAuthMercuryGroup = AuthMercuryComponent.getAuthMercuryGroupDef(this.fb, result);
    } else {
      this.currentFormAuthMercuryGroup = undefined;
    }
  }

  logout() {
    this.currentFormGroup.get('authMercuryInProcess').setValue(true);
    this.securityService.logoutUserMercury()
      .pipe(finalize(() => {
        this.currentFormGroup = AuthMercuryComponent.getGroupDef(this.fb);
        this.dataCachingService.addToCache('AuthMercuryComponent', '1', this.currentFormGroup);
      }))
      .subscribe(() => {
        this.securityService.invalidateUserMercurySession();
        this.dataCachingService.removeCachedData('AuthMercuryComponent', '2');
        this.currentFormAuthMercuryGroup = undefined;
      });
  }
}
