import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/internal/operators';

@Injectable({
  providedIn: 'root',
})
export class PstReportService {

  constructor(private http: HttpClient) {
  }

  public getReportData(reportId: number): Observable<any> {
    return this.http.get(`${environment.api}/pst-report/report-full-data/${reportId}`);
  }

  public getListReports(searchParams: any): Observable<any[]> {
    return this.http.post(`${environment.api}/pst-report/list-reports`, searchParams)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  public storeIndicator(indicatorData: any, reportId: number): Observable<any> {
    return this.http.post(`${environment.api}/pst-report/store-indicator/${reportId}`, indicatorData);
  }

  public addConstruction(reportId: any, data: any): Observable<any> {
    return this.http.post(`${environment.api}/pst-report/add-construction/${reportId}`, data);
  }

  public updateConstruction(reportId: any, data: any): Observable<any> {
    return this.http.post(`${environment.api}/pst-report/update-construction/${reportId}`, data);
  }

  public addDeal(reportId: any, data: any): Observable<any> {
    return this.http.post(`${environment.api}/pst-report/add-deal/${reportId}`, data);
  }

  public updateDeal(reportId: any, data: any): Observable<any> {
    return this.http.post(`${environment.api}/pst-report/update-deal/${reportId}`, data);
  }

  getForLookup(id: number) {
    return this.http.get(`${environment.api}/pst-report/lookup/${id}`);
  }

  calcDependencies(indicatorKey: any): Observable<any[]> {
    return this.http.post(`${environment.api}/pst-report/calc-dependencies`, indicatorKey)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  public updateReportTypeIndicator(data: any,
                                   reportTypeId: any, branchOrderNo: any, colNo: any, rowNo: any): Observable<any> {
    const qstring = `${environment.api}/metadata/pst-report-type-indicator/update/${reportTypeId}/${branchOrderNo}`
      + `/${colNo}/${rowNo}`;
    return this.http.post(qstring, data)
      .pipe(map((response: any) => {
        return response.data as any;
      }));
  }

  public deleteReportTypeIndicator(data: any,
                                   reportTypeId: any, branchOrderNo: any, colNo: any, rowNo: any): Observable<any> {
    const qstring = `${environment.api}/metadata/pst-report-type-indicator/delete/${reportTypeId}/${branchOrderNo}`
      + `/${colNo}/${rowNo}`;
    return this.http.post(qstring, data)
      .pipe(map((response: any) => {
        return response.data as any;
      }));
  }

  public createReportTypeIndicator(data: any): Observable<any> {
    const qstring = `${environment.api}/metadata/pst-report-type-indicator`;
    return this.http.post(qstring, data)
      .pipe(map((response: any) => {
        return response.data as any;
      }));
  }

  public updateReportTypeBranch(data: any, reportTypeId: any, orderNo: any): Observable<any> {
    const qstring = `${environment.api}/metadata/pst-report-type-branch/update/${reportTypeId}/${orderNo}`;
    return this.http.post(qstring, data)
      .pipe(map((response: any) => {
        return response.data as any;
      }));
  }

  public createReportTypeBranch(data: any): Observable<any> {
    const qstring = `${environment.api}/metadata/pst-report-type-branch`;
    return this.http.post(qstring, data)
      .pipe(map((response: any) => {
        return response.data as any;
      }));
  }
}
