import { EventEmitter } from '@angular/core';
import { TicketsPlanningUiService } from './tickets-planning-ui-service';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AgentSearchModalComponent } from '../../edit-agent/search/agent-search-modal.component';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { AnimalSearchModalComponent } from '../../edit-animal/search/animal-search-modal.component';
import { DateHelper } from '../../../helpers/date-helper';
var TicketEditModalComponent = /** @class */ (function () {
    function TicketEditModalComponent(planningUi, navigationService) {
        this.planningUi = planningUi;
        this.navigationService = navigationService;
        this.model = {};
        this.forceReloadEmitter = new EventEmitter();
        this.isInvalid = FormHelper.isInvalid;
        this.processMoneyKeypress = FormHelper.processMoneyKeypressDot;
        this.isAfterCurrentDate = function (date) { return DateHelper.isAfter(date, new Date()) > 0; };
    }
    Object.defineProperty(TicketEditModalComponent.prototype, "ticketFormGroup", {
        get: function () {
            return this.planningUi.ticketFormGroup;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TicketEditModalComponent.prototype, "isTemplate", {
        get: function () {
            return this.planningUi.ticketFormGroup.get('isTemplate').value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TicketEditModalComponent.prototype, "isDistribute", {
        get: function () {
            return this.planningUi.ticketFormGroup.get('isDistribute').value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TicketEditModalComponent.prototype, "readonly", {
        get: function () {
            return this.planningUi.ticketReadonly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TicketEditModalComponent.prototype, "modalValidationErrorsHost", {
        get: function () {
            return this.planningUi.modalValidationErrorsHost;
        },
        enumerable: true,
        configurable: true
    });
    TicketEditModalComponent.prototype.searchAgent = function () {
        var _this = this;
        this.navigationService.searchAgent(AgentSearchModalComponent, { availableCreateAgent: true })
            .subscribe(function (val) {
            if (val) {
                _this.ticketFormGroup.get('agentId').setValue(val);
                _this.ticketFormGroup.markAsDirty();
            }
        });
    };
    TicketEditModalComponent.prototype.removeAgent = function () {
        this.ticketFormGroup.controls['agentId'].setValue(undefined);
        this.ticketFormGroup.controls['animalId'].setValue(undefined);
        this.ticketFormGroup.controls['isAnimalExternal'].setValue(undefined);
        this.ticketFormGroup.markAsDirty();
    };
    TicketEditModalComponent.prototype.searchAnimal = function () {
        var _this = this;
        this.navigationService.searchAnimal(AnimalSearchModalComponent, {
            agentId: this.ticketFormGroup.get('agentId').value,
            animalTypeId: this.ticketFormGroup.get('animalTypeId').value,
            animalSubtypeId: this.ticketFormGroup.get('animalSubtypeId').value,
            availableShowExternal: true,
        }).subscribe(function (val) {
            if (val) {
                _this.ticketFormGroup.get('isAnimalExternal').setValue(!!val.tempId);
                _this.ticketFormGroup.get('animalId').setValue(val.tempId || val.id);
                _this.ticketFormGroup.markAsDirty();
            }
        });
    };
    TicketEditModalComponent.prototype.removeAnimal = function () {
        this.ticketFormGroup.controls['animalId'].setValue(undefined);
        this.ticketFormGroup.controls['isAnimalExternal'].setValue(undefined);
        this.ticketFormGroup.markAsDirty();
    };
    TicketEditModalComponent.prototype.addEditTicketCompleted = function () {
        this.planningUi.addEditTicketCompleted(this.model, this.forceReloadEmitter);
    };
    TicketEditModalComponent.prototype.deleteTicket = function () {
        if (!this.ticketFormGroup.get('isTemplate').value) {
            this.planningUi.deleteOrCancelTicketModal(this.planningUi.editedTicket, this.model);
        }
        else {
            this.planningUi.deleteTicketTemplate(this.ticketFormGroup.value, this.model);
        }
    };
    TicketEditModalComponent.prototype.distributeTickets = function () {
        this.planningUi.distributeTickets(this.model, this.forceReloadEmitter);
    };
    TicketEditModalComponent.prototype.markSuccessful = function () {
        this.planningUi.markSuccessful();
    };
    return TicketEditModalComponent;
}());
export { TicketEditModalComponent };
