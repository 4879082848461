<div class="itech-common-wrap" id="interceptNoAccess">
  <div>
    <div style="padding: 5px; height: 100%">
      <app-grid [getRowStyle]="getRowStyle" [uniqueGridName]="'EditInstitutionEmployeesGrid'"
                [exportFileName]="'Список сотрудников учреждения ' + (id|lookup:'institution'|async)"
                [rowData]="employees" [columnDefs]="gridColumnDefs"></app-grid>
    </div>
  </div>

  <div class="itech-form-actions">
    <div>
      <button class="btn btn-primary-outline" (click)="appNavigationService.navigateJournal(undefined, 3, id, undefined)">
        <clr-icon shape="view-list"></clr-icon>&nbsp;Журнал
      </button>

      <button class="btn btn-secondary" (click)="refreshFormData()">
        <clr-icon shape="refresh"></clr-icon>&nbsp;Обновить
      </button>
      <button class="btn btn-success-outline" (click)="addEmployee()">
        <clr-icon shape="plus"></clr-icon>&nbsp;Добавить
      </button>
    </div>
    <div>
      <button [clrLoading]="storing" class="btn btn-primary itech-form-save"
              [disabled]="!model.dirty || storing" (click)="store()">
        <clr-icon shape="check"></clr-icon>&nbsp;Сохранить
      </button>
      <button class="btn btn-warning itech-form-cancel" [disabled]="!model.dirty" (click)="cancelEdit()">
        <clr-icon shape="undo"></clr-icon>&nbsp;Отменить
      </button>
    </div>
  </div>
</div>

<app-institution-employee-modal *ngIf="addEmployeeModalOpened" [currentFormGroup]="currentFormGroup" [employees]="employees" [id]="id"
                                [currentEmployee]="currentEmployee" [currentIsNew]="currentIsNew"
                                (addEmployeeModalOpenedChanged)="addEmployeeModalOpenedChanged()"
                                [addEmployeeModalOpened]="addEmployeeModalOpened" [model]="model">
</app-institution-employee-modal>
