import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AppFormGroup } from '../../../ui/controls/app-form-group';
import { FormHelper } from '../../../ui/controls/form-helper';
import { StringHelper } from '../../../helpers/string-helper';
import { DateHelper } from '../../../helpers/date-helper';
import { FormArray, FormBuilder } from '@angular/forms';
import { EditQuarantineStagesComponent } from './edit-quarantine-stages.component';
import { ServerSideErrorsProvider } from '../../../logic/validators/server-side-errors-provider';

@Component({
  selector: 'app-edit-quarantine-stages-file',
  template: `
      <div style="display:flex">
        <clr-icon shape="alarm-clock" [title]="getRemainingTime()" *ngIf="deadlineDateTime"
                  style="margin:10px 5px 0 -20px" [style.color]="violationDeadline ? 'red' : undefined"></clr-icon>
        <div class="form-group" style="display:block;min-height:40px"
             [style.margin-bottom.px]="isAccessEdit ? marginBottom : marginBottom + 20">
            <label>{{label}}</label>
            <div *ngFor="let file of controlFormArray.controls; index as i" [formGroup]="getFormGroup(file)">
                <label for="date{{controlName}}{{i}}" style="margin-right: 5px">Дата</label>
                <label for="date{{controlName}}{{i}}" aria-haspopup="true" role="tooltip"
                       class="tooltip tooltip-validation tooltip-md disabled {{'tooltip-'+tooltipPosition}}"
                       [class.invalid]="isInvalid(getFormGroup(file), 'date')">
                    <input type="date" id="date{{controlName}}{{i}}" class="itech-control-normal"
                           formControlName="date" [attr.disabled]="!isAccessEdit ? true : undefined">
                    <app-validation-tooltip [input]="getFormGroup(file).controls['date']"></app-validation-tooltip>
                </label>
                <label for="number{{controlName}}{{i}}" style="margin: 0 5px 0 10px">Номер</label>
                <label for="number{{controlName}}{{i}}" aria-haspopup="true" role="tooltip"
                       class="tooltip tooltip-validation tooltip-md {{'tooltip-'+tooltipPosition}}"
                       [class.invalid]="isInvalid(getFormGroup(file), 'number')">
                    <input type="text" id="number{{controlName}}{{i}}" class="itech-control-normal"
                           (change)="changeNumberDoc(getFormGroup(file))"
                           formControlName="number" [attr.disabled]="!isAccessEdit ? true : undefined">
                    <app-validation-tooltip [input]="getFormGroup(file).controls['number']"></app-validation-tooltip>
                </label>
                <div style="padding-top: 0.2rem">
                    <app-single-file [contextFormGroup]="getFormGroup(file)" [isAccessEdit]="isAccessEdit"
                                     [uriUpload]="'/quarantine/upload-file/' + id" (changeFile)="markAsDirty()"
                                     [preTitle]="'Прикрепленный файл: '" [downloadFileUri]="'quarantine/files/preload'">
                    </app-single-file>
                    <div *ngIf="isAccessEdit && getFormGroup(file).get('dateUpload').value" style="font-size:0.45rem">
                        загружено {{getDateUploadToString(getFormGroup(file).get('dateUpload').value)}}
                        пользователем {{file.get('userIdUpload').value|lookup:'user'|async}}
                    </div>
                </div>
                <label *ngIf="labelDateToInclusive" for="dateToInclusive{{controlName}}{{i}}"
                       style="margin-right: 5px">{{labelDateToInclusive}}</label>
                <label *ngIf="labelDateToInclusive" for="dateToInclusive{{controlName}}{{i}}" aria-haspopup="true" role="tooltip"
                       class="tooltip tooltip-validation tooltip-md disabled {{'tooltip-'+tooltipPosition}}"
                       [class.invalid]="isInvalid(getFormGroup(file), 'dateToInclusive')">
                    <input type="date" id="dateToInclusive{{controlName}}{{i}}" class="itech-control-normal"
                           formControlName="dateToInclusive" [attr.disabled]="!isAccessEdit ? true : undefined">
                    <app-validation-tooltip [input]="getFormGroup(file).controls['dateToInclusive']"></app-validation-tooltip>
                </label>
                <hr>
            </div>
            <button type="button" class="btn btn-link" (click)="addNewOrderToExtend()">
                <clr-icon shape="plus"></clr-icon>
                Добавить файл
            </button>
        </div>
      </div>`,
  styleUrls: ['../edit-quarantine.all.css']
})
export class EditQuarantineStagesFileComponent implements OnInit, OnChanges {

  @Input() contextFormGroup: AppFormGroup;
  @Input() controlName: string;                       // имя контрола для хранения данные о прикрепленном файле
  @Input() id: number;                                // ид карантина
  @Input() label: string;                             // наименование группы
  @Input() withoutNumber = false;                     // без номера
  @Input() withoutDate = false;                       // без даты
  @Input() isAccessEdit = true;                       // право редактирования
  @Input() marginBottom = 20;
  @Input() deadlineDateTime: Date;
  @Input() labelDateToInclusive: string;
  @Input() serverSideErrorsProvider: ServerSideErrorsProvider;
  @Input() tooltipPosition = 'top-right';

  @Output() dirtyFormGroup = new EventEmitter();

  isInvalid = FormHelper.isInvalid;
  violationDeadline = false;

  constructor(private fb: FormBuilder) {
  }

  get controlFormArray() {
    return this.contextFormGroup.get(this.controlName) as FormArray;
  }

  getFormGroup(file) {
    return file as AppFormGroup;
  }

  ngOnInit() {
    this.contextFormGroup.get(this.controlName).valueChanges.subscribe(() => this.dirtyFormGroup.next());
  }

  ngOnChanges(): void {
    if (this.deadlineDateTime) {
      this.violationDeadline = new Date().getTime() - this.deadlineDateTime.getTime() > 0;
    }
  }

  fileFormGroupByControlName(controlName: string): AppFormGroup {
    return this.contextFormGroup.get(controlName) as AppFormGroup;
  }

  markAsDirty() {
    this.contextFormGroup.markAsDirty();
    this.dirtyFormGroup.next();
  }

  changeFile($event: any) {
    if (this.contextFormGroup.contains(this.controlName + 'DateUpload')) {
      this.contextFormGroup.get(this.controlName + 'DateUpload').setValue(null);
    }
    this.markAsDirty();
  }

  getDateUploadToString(dateStr: string) {
    return StringHelper.getRuTimeWithSeconds(DateHelper.toDate(dateStr));
  }

  changeNumberDoc(file: AppFormGroup) {
    if (file.get('number').value === '') {
      file.get('number').setValue(null);
    }
  }

  getRemainingTime() {
    return 'Срок прикрепления до ' + StringHelper.getRuTimeWithSeconds(this.deadlineDateTime);
  }

  addNewOrderToExtend() {
    this.controlFormArray.push(
      this.fb.group(EditQuarantineStagesComponent.buildFormGroupFile(this.fb, this.serverSideErrorsProvider,
        {}, this.controlName + '-' + this.controlFormArray.length)));
  }
}
