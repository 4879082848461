import {Component, EventEmitter, Input, Output} from '@angular/core';
import {isUndefined} from 'util';
import {StringHelper} from '../../helpers/string-helper';
import {Router} from '@angular/router';

@Component({
  selector: 'app-ind-dep-tree-nodes',
  template: `
      <ng-container [clrLoading]='loading' *ngIf="parent">
          <clr-tree-node *ngFor='let item of parent.children'
                         (clrSelectedChange)="changeSelectedChildItems(item, $event)">
              <b *ngIf="item.value.value">{{formatRuNumeric(item.meta.precision, item.value.value)}}</b>
              <b *ngIf="!item.value.value" style="color: red">Н/Д</b>
              <span style="color: #1a73e8">&nbsp;{{item.levelCaption}}</span>
              <span title="ид вида: {{item.meta.id}}">&nbsp;{{item.meta.caption}}</span>
              <ng-template [(clrIfExpanded)]="item.expanded" *ngIf="item.children && item.children.length > 0">
                  <!-- отчеты, в которых указан показатель-->
                  <ng-container *ngFor="let reportId of parent.reports">
                      <clr-tree-node *ngIf="reportId !== ownerReportId">
                          <a class="itech-link in-table-link" (click)="onNavigateReport.emit(reportId)">
                              <clr-icon shape="line-chart"></clr-icon>
                              Отчет {{reportId|lookup:'pst-report'|async}}</a>
                      </clr-tree-node>
                  </ng-container>
                  <app-ind-dep-tree-nodes [parent]="item" [ownerReportId]="ownerReportId"
                                          (onNavigateReport)="onNavigateReport.emit($event)">
                  </app-ind-dep-tree-nodes>
              </ng-template>
          </clr-tree-node>
      </ng-container>
  `
})
export class AppIndDepTreeNodesComponent {
  loading = false;

  private _parent: any = {children: [], expanded: false};

  formatRuNumeric = StringHelper.formatRuNumeric;

  @Input() ownerReportId: any;
  @Output() onNavigateReport = new EventEmitter<any>();

  @Input()
  get parent(): any {
    return this._parent;
  }

  set parent(node) {
    if (this._parent !== node) {
      this._parent = node;

      setTimeout(() => {
        if (this._parent) {
          this._parent.expanded = isUndefined(this._parent.expanded) ? false : this._parent.expanded;
        }
      }, 0);
    }
  }

  getSelectedLeafes(): any[] {
    const selected = [];
    this.fillSelectedLeafs(this.parent, selected);
    return selected;
  }

  private fillSelectedLeafs(node: any, selected: any[], hasSelectedParent: boolean = false) {
    const nodeIsLeaf = !node.children || node.children.length <= 0;
    if (nodeIsLeaf) {
      if (node.selected || hasSelectedParent) {
        selected.push(node);
      }
    } else {
      node.children.forEach(item => this.fillSelectedLeafs(item, selected, node.selected || hasSelectedParent));
    }
  }

  // fix: Если в модели узла дерева проинициализировано своейство selected, то дерево некорректно работает
  // при разворачивании: оно начинает менять состояние выбранности узлов верхнего уровня при подгрузке
  // дочерних элементов со свойством selected
  changeSelectedChildItems(parent, selected) {
    if (!isUndefined(parent.expanded) && !parent.expanded) {
      this.setSelectedChildItems(parent, selected);
    }
  }

  private setSelectedChildItems(parent, selected) {
    if (parent.children) {
      parent.children.forEach(child => {
        if (!isUndefined(child.selected) && child.selected !== selected) {
          child.selected = selected;
        }
        this.setSelectedChildItems(child, selected);
      });
    }
  }
}
