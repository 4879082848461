import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { Required } from '../infrastructure/app-decotators';
import { AgGridLocalization } from './ag-grid-localization';
import { AgGridLookupRendererComponent } from './ag-grid-lookup-renderer.component';
import { AgGridButtonRendererComponent } from './ag-grid-button-renderer.component';
import { AgGridTextLookupEditorComponent } from './ag-grid-text-lookup-editor.component';
import { AgGridSearchModalEditorComponent } from './ag-grid-search-modal-editor.component';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { AgGridTextInputEditorComponent } from './ag-grid-text-input-editor.component';
import { AgGridButtonStatusBarComponent } from './ag-grid-button-status-bar.component';
import { AgGridFormMasterDetailComponent } from './ag-grid-form-master-detail.component';
import { FormHelper } from './form-helper';
var AppGridEditableComponent = /** @class */ (function () {
    /* tslint:enable */
    function AppGridEditableComponent(fb) {
        this.fb = fb;
        this.defaultGridHeight = 62;
        this.defaultRowHeight = 32;
        this.frameworkComponents = {
            lookupRenderer: AgGridLookupRendererComponent,
            buttonRenderer: AgGridButtonRendererComponent,
            textLookupEditor: AgGridTextLookupEditorComponent,
            searchModalEditor: AgGridSearchModalEditorComponent,
            textInputEditor: AgGridTextInputEditorComponent,
            statusBarComponent: AgGridButtonStatusBarComponent,
            formMasterDetailComponent: AgGridFormMasterDetailComponent,
        };
        this.agGridLocaleTextFunc = AgGridLocalization.getLocalization;
        this.colDefs = [];
        this.defColDef = {
            resizable: true,
            autoHeight: true,
            suppressKeyboardEvent: this.suppressKeys,
        };
        this.gridReady = new EventEmitter();
        this.cellValueChanged = new EventEmitter();
        this.deletingRow = new EventEmitter();
        this.widthGrid = 700;
        this.masterDetail = false;
        this.detailRowHeight = 450;
        this.forceUpdateData = new EventEmitter();
        this.singleClickEdit = true;
        this.withEmptyRow = true;
        this.withAdd = true;
        this.withCopy = true;
        this.withDelete = true;
        this.recalculateHeight = true;
        this.validityControlsEvent = new EventEmitter();
        this.masterDetailRowAvailableFunc = function (rowNode) { return true; };
    }
    /* tslint:disable */
    AppGridEditableComponent.clearSelectedCells = function (params) {
        var cellRanges = params.api.getCellRanges();
        cellRanges.forEach(function (cells) {
            var colIds = cells.columns.map(function (col) { return col.colId; });
            var startRowIndex = Math.min(cells.startRow.rowIndex, cells.endRow.rowIndex);
            var endRowIndex = Math.max(cells.startRow.rowIndex, cells.endRow.rowIndex);
            AppGridEditableComponent.clearCells(params, startRowIndex, endRowIndex, colIds);
        });
    };
    AppGridEditableComponent.clearCells = function (params, start, end, columns) {
        var _loop_1 = function (i) {
            var rowNode = params.api.getRowNode(i);
            columns.forEach(function (column) {
                if (rowNode.data && rowNode.data.contains(column)) {
                    rowNode.data.controls[column].setValue('');
                }
            });
            params.api.redrawRows({ rowNodes: [rowNode] });
        };
        for (var i = start; i <= end; i++) {
            _loop_1(i);
        }
    };
    AppGridEditableComponent.prototype.onGridReady = function (params) {
        var _this = this;
        this.api = params.api;
        this.columnApi = params.columnApi;
        if (!this.columnDefs || !this.columnDefs.length) {
            return;
        }
        this.colDefs = this.columnDefs.slice();
        this.addButtonEditRow(this.withAdd, this.withCopy, this.withDelete);
        this.columnSettings();
        if (!this.formArray.controls.length) {
            this.addRow(this.fb.group({}));
        }
        else {
            this.setRowData();
        }
        this.forceUpdateData.subscribe(function () {
            _this.setRowData();
            _this.colDefs.forEach(function (def) {
                if (def.valueChangedCallback) {
                    _this.formArray.controls.forEach(function (c) { return def.valueChangedCallback(c.get(def.field).value, c, null, _this.api); });
                }
            });
            _this.api.getRenderedNodes().forEach(function (node) { return node.expanded = false; });
            _this.api.refreshCells({ force: true });
        });
        this.validityControlsEvent.subscribe(function () { return _this.validityControls(); });
        this.gridReady.emit(params);
    };
    // добавляем слева кнопки (добавить, копировать, удалить строки)
    AppGridEditableComponent.prototype.addButtonEditRow = function (withAdd, withCopy, withDelete) {
        var _this = this;
        if (withAdd) {
            this.colDefs.unshift({
                width: 22,
                editorType: AppGridEditableComponent.BUTTON_TYPE,
                buttonRendererParams: function (row) {
                    return {
                        icon: 'plus',
                        title: 'Добавить строку',
                        onClickCallback: function (params) {
                            var cloneData = FormHelper.cloneAbstractControl(params.data);
                            FormHelper.resetAllFields(cloneData, _this.columnDefs.filter(function (def) { return def.copyToNewRow; }).map(function (def) { return def.field; }));
                            _this.addRow(cloneData, true, row.rowIndex + 1);
                        },
                    };
                },
            });
        }
        if (withCopy) {
            this.colDefs.unshift({
                width: 22,
                editorType: AppGridEditableComponent.BUTTON_TYPE,
                buttonRendererParams: function (row) {
                    return {
                        icon: 'copy',
                        title: 'Копировать строку',
                        onClickCallback: function (params) { return _this.addRow(params.data, true, _this.formArray.length, true, false); },
                    };
                },
            });
        }
        if (withDelete) {
            this.colDefs.unshift({
                width: 22,
                editorType: AppGridEditableComponent.BUTTON_TYPE,
                buttonRendererParams: function (row) {
                    return {
                        icon: 'trash',
                        title: 'Удалить строку',
                        onClickCallback: function () { return _this.deleteRow(row.rowIndex); },
                    };
                },
            });
        }
        if (this.masterDetail) {
            this.colDefs.push({
                width: 22,
                editorType: AppGridEditableComponent.BUTTON_TYPE,
                buttonRendererParams: function (row) {
                    return {
                        icon: row.node.expanded ? 'angle left' : 'angle left',
                        title: 'Раскрыть строку',
                        disabled: !_this.masterDetailRowAvailableFunc(row),
                        onClickCallback: function (params) {
                            row.node.setExpanded(!row.node.expanded);
                            params.icon = row.node.expanded ? 'angle down' : 'angle left';
                            _this.recalculateHeightGrid();
                        },
                    };
                },
            });
        }
    };
    // обрабатываем editor's в соответствии с настройками каждого столбца
    AppGridEditableComponent.prototype.columnSettings = function () {
        var _this = this;
        this.colDefs.forEach(function (def) {
            _this.commonSettings(def);
            if (def.editorType === AppGridEditableComponent.EDITOR_TYPE_MODAL_SEARCH) {
                _this.modalSearchSettings(def);
            }
            else if (def.editorType === AppGridEditableComponent.EDITOR_TYPE_TEXT_LOOKUP) {
                _this.lookupSettings(def);
            }
            else if (def.editorType === AppGridEditableComponent.EDITOR_TYPE_TEXT_INPUT) {
                _this.textInputSettings(def);
            }
            else if (def.editorType === AppGridEditableComponent.BUTTON_TYPE) {
                _this.buttonSettings(def);
            }
            else if (def.editorType === AppGridEditableComponent.HIDDEN_FIELD ||
                def.editorType === AppGridEditableComponent.DATE_COMBO ||
                def.editorType === AppGridEditableComponent.DATE_PICKER ||
                def.editorType === AppGridEditableComponent.CHECKBOX ||
                def.editorType === AppGridEditableComponent.COMPONENT ||
                def.editorType === AppGridEditableComponent.APP_COMBO_LOOKUP ||
                def.editorType === AppGridEditableComponent.TEXT_AREA) {
            }
            else {
                throw new Error("Unknown attribute 'editorType'");
            }
        });
    };
    AppGridEditableComponent.prototype.commonSettings = function (def) {
        var _this = this;
        if (def.required) {
            if (!def.cellClassRules) {
                def.cellClassRules = {};
            }
            def.cellClassRules['ag-cell-error-validation'] = function (params) {
                return (def.required(params) && !params.data.get(def.field).value) || params.data.get(def.field).invalid;
            };
        }
        if (def.isInnerForm) {
            def.hide = true;
        }
        if (def.recalculateDetailHeightAfterChange) {
            def.onCellValueChanged = function () {
                _this.recalculateHeightGrid();
                setTimeout(function () {
                    _this.cellValueChanged.emit();
                }, 10);
            };
        }
    };
    AppGridEditableComponent.prototype.modalSearchSettings = function (def) {
        var _this = this;
        def.cellEditor = 'searchModalEditor';
        def.cellEditorParams = function (row) {
            return {
                controlName: def.field,
                value: row.data[def.field],
                lookupName: def.lookupName,
                searchParams: def.searchParams,
                manySelectedCallback: function (params, values) {
                    values.forEach(function (v) {
                        var cloneData = FormHelper.cloneAbstractControl(params.data);
                        cloneData.get(def.field).setValue(v);
                        if (params.colDef.valueChangedCallback) {
                            params.colDef.valueChangedCallback(v, cloneData, params.node, _this.api);
                        }
                        _this.addRow(cloneData, false, null, false);
                    });
                    _this.setRowData();
                },
                callbackAfterChangeValue: def.callbackAfterChangeValue,
            };
        };
        def.cellRenderer = 'lookupRenderer';
        def.cellRendererParams = function (row) {
            return {
                value: row.data.get([def.field]).value,
                lookupName: def.lookupName,
                isPipe: true,
            };
        };
    };
    AppGridEditableComponent.prototype.lookupSettings = function (def) {
        def.cellEditor = 'textLookupEditor';
        def.cellEditorParams = function (row) {
            return {
                value: row.data.get([def.field]).value,
                controlName: def.field,
                lookupName: def.lookupName,
                parentFieldLookup: def.parentFieldLookup,
            };
        };
        def.cellRenderer = 'lookupRenderer';
        def.cellRendererParams = function (row) {
            return {
                value: row.data.get([def.field]).value,
                value2: def.parentFieldLookup ? row.data.get([def.parentFieldLookup]).value : null,
                requiredValue2: def.requiredValue2,
                lookupName: def.lookupName,
                isPipe: def.isPipe,
            };
        };
    };
    AppGridEditableComponent.prototype.textInputSettings = function (def) {
        def.cellEditor = 'textInputEditor';
        def.cellEditorParams = function (row) {
            return {
                value: row.data.get([def.field]).value,
                controlName: def.field,
                keyPressCallback: def.keyPressCallback,
            };
        };
        def.valueFormatter = function (params) { return params.data.get([def.field]).value; };
    };
    AppGridEditableComponent.prototype.buttonSettings = function (def) {
        def.cellRenderer = 'buttonRenderer';
        def.cellRendererParams = def.buttonRendererParams;
        def.editable = false;
        def.cellClass = 'app-grid-editable-button-cell';
    };
    AppGridEditableComponent.prototype.addRow = function (data, updatedData, index, isNeedCloned, calcDefValue) {
        if (updatedData === void 0) { updatedData = true; }
        if (isNeedCloned === void 0) { isNeedCloned = true; }
        if (calcDefValue === void 0) { calcDefValue = true; }
        var cloneData = isNeedCloned ? FormHelper.cloneAbstractControl(data) : data;
        if (calcDefValue) {
            this.columnDefs.forEach(function (colDef) {
                if ('defValueFunc' in colDef) {
                    colDef.defValueFunc(cloneData);
                }
            });
        }
        if (index) {
            this.formArray.insert(index, cloneData);
        }
        else {
            this.formArray.push(cloneData);
        }
        if (updatedData && this.api) {
            this.setRowData();
        }
    };
    AppGridEditableComponent.prototype.deleteRow = function (index) {
        var _this = this;
        var deleteData = this.formArray.at(index);
        this.columnDefs.forEach(function (colDef) {
            if (colDef.valueChangedCallback) {
                deleteData.get(colDef.field).setValue(null);
                colDef.valueChangedCallback(null, deleteData, null, _this.api);
            }
        });
        if (this.formArray.length === 1 && this.withEmptyRow) {
            var cloneData = FormHelper.cloneAbstractControl(this.formArray.at(index));
            FormHelper.resetAllFields(cloneData, this.columnDefs.filter(function (def) { return def.copyToNewRow; }).map(function (def) { return def.field; }));
            this.formArray.removeAt(index);
            this.addRow(cloneData);
        }
        else {
            this.formArray.removeAt(index);
        }
        if (this.api) {
            this.setRowData();
        }
        this.deletingRow.emit(deleteData);
    };
    AppGridEditableComponent.prototype.setRowData = function () {
        this.api.setRowData(this.formArray.controls);
        this.recalculateHeightGrid();
    };
    AppGridEditableComponent.prototype.fillOperation = function (params) {
        if (params.direction === 'down' || params.direction === 'up') {
            var cellRanges = params.api.getCellRanges();
            if (!cellRanges || !cellRanges.length) {
                return;
            }
            var cell = cellRanges[0];
            var colDef = params.column.getColDef();
            var editNode = params.api.getDisplayedRowAtIndex(params.direction === 'down'
                ? cell.endRow.rowIndex + params.currentIndex + 1
                : cell.startRow.rowIndex - params.currentIndex - 1);
            var editable = colDef.editable
                ? typeof colDef.editable === 'function'
                    ? colDef.editable.call(this, editNode)
                    : colDef.editable
                : false;
            if (editable) {
                editNode.data.get(colDef.field).setValue(params.api.getDisplayedRowAtIndex(cell.startRow.rowIndex).data.get(colDef.field).value);
                params.api.redrawRows({ rowNodes: [editNode] });
            }
            return editNode && editNode.data && editNode.data.contains(colDef.field) ? editNode.data.get(colDef.field).value : undefined;
        }
    };
    AppGridEditableComponent.prototype.recalculateHeightGrid = function () {
        var _this = this;
        if (!this.api) {
            return;
        }
        if (this.recalculateHeight) {
            var allDetailRowsHeight_1 = 0;
            this.api.getRenderedNodes().forEach(function (node) {
                if (node.expanded && node.detailNode) {
                    node.detailNode.setRowHeight(_this.masterDetailCalcHeightFunc
                        ? _this.masterDetailCalcHeightFunc(node)
                        : _this.detailRowHeight);
                    allDetailRowsHeight_1 += node.detailNode.rowHeight;
                    node.rowHeightEstimated = true;
                }
                if (!node.detail) {
                    node.setRowHeight(_this.defaultRowHeight);
                }
            });
            var val = this.formArray.length * this.defaultRowHeight + this.defaultGridHeight + allDetailRowsHeight_1;
            this.gridHeight = this.maxHeight && val > this.maxHeight
                ? this.maxHeight
                : val;
        }
        else {
            this.gridHeight = this.defaultGridHeight;
        }
        this.api.onRowHeightChanged();
    };
    AppGridEditableComponent.prototype.validityControls = function () {
        var _this = this;
        this.columnDefs.forEach(function (colDef) {
            if (colDef.isInnerForm) {
                _this.api.getRenderedNodes().forEach(function (node) {
                    if (node.data.contains(colDef.field) && node.data.get(colDef.field).invalid) {
                        node.setExpanded(true);
                        _this.recalculateHeightGrid();
                        setTimeout(function () {
                            var invalidControl = _this.recursiveSearchInvalidControl(node.data.get(colDef.field));
                            if (invalidControl) {
                                invalidControl.nativeElement.focus();
                            }
                        }, 100);
                    }
                });
            }
        });
    };
    AppGridEditableComponent.prototype.recursiveSearchInvalidControl = function (fg) {
        var _this = this;
        if (!fg) {
            return null;
        }
        if (fg instanceof FormGroup) {
            var invalidControl_1 = null;
            Object.keys(fg.value).forEach(function (k) {
                if (!invalidControl_1) {
                    invalidControl_1 = _this.recursiveSearchInvalidControl(fg.get(k));
                }
            });
            return invalidControl_1;
        }
        else if (fg instanceof FormArray) {
            var invalided = fg.controls.filter(function (k) { return k.invalid; });
            return invalided.length ? this.recursiveSearchInvalidControl(invalided[0]) : null;
        }
        if (!fg.invalid) {
            return fg;
        }
        return fg;
    };
    AppGridEditableComponent.prototype.getContextMenuItems = function (params) {
        return [
            'copy',
            'paste',
            {
                name: 'Очистить',
                shortcut: 'Delete',
                action: function () {
                    if (params.api && params.node && params.node.data && params.column && params.column.colDef && params.column.colDef.field) {
                        AppGridEditableComponent.clearSelectedCells(params);
                    }
                },
                icon: '<clr-icon shape="trash" style="color:#7F8C8D;"></clr-icon>',
            },
        ];
    };
    AppGridEditableComponent.prototype.processCellForClipboard = function (params) {
        return params.node.data.controls[params.column.colDef.field].value;
    };
    AppGridEditableComponent.prototype.processCellFromClipboard = function (params) {
        if (!params || !params.api || !params.node || !params.node.data || !params.column || !params.column.colDef) {
            return;
        }
        params.node.data.controls[params.column.colDef.field].setValue(params.value);
        params.node.setDataValue(params.column, params.value);
        if (params.column.colDef.valueChangedCallback) {
            params.column.colDef.valueChangedCallback(params.value, params.node.data, params.node, params.api);
        }
        params.api.redrawRows({ rowNodes: [params.node] });
    };
    AppGridEditableComponent.prototype.onCellKeyDown = function (params) {
        var keyPress = params.event.key;
        if (keyPress && keyPress.toLowerCase() === 'delete') {
            params.api.stopEditing();
            AppGridEditableComponent.clearSelectedCells(params);
        }
    };
    AppGridEditableComponent.prototype.suppressKeys = function (params) {
        return ['delete'].some(function (suppressedKey) {
            return params.event.key.toLowerCase() === suppressedKey;
        });
    };
    AppGridEditableComponent.EDITOR_TYPE_MODAL_SEARCH = 1;
    AppGridEditableComponent.EDITOR_TYPE_TEXT_LOOKUP = 2;
    AppGridEditableComponent.EDITOR_TYPE_TEXT_INPUT = 3;
    AppGridEditableComponent.BUTTON_TYPE = 4;
    AppGridEditableComponent.HIDDEN_FIELD = 5;
    AppGridEditableComponent.DATE_COMBO = 6;
    AppGridEditableComponent.DATE_PICKER = 7;
    AppGridEditableComponent.CHECKBOX = 8;
    AppGridEditableComponent.COMPONENT = 9;
    AppGridEditableComponent.APP_COMBO_LOOKUP = 10;
    AppGridEditableComponent.TEXT_AREA = 11;
    tslib_1.__decorate([
        Required,
        tslib_1.__metadata("design:type", Array)
    ], AppGridEditableComponent.prototype, "columnDefs", void 0);
    tslib_1.__decorate([
        Required,
        tslib_1.__metadata("design:type", FormArray)
    ], AppGridEditableComponent.prototype, "formArray", void 0);
    return AppGridEditableComponent;
}());
export { AppGridEditableComponent };
