import * as tslib_1 from "tslib";
import { MetaBrowseBaseComponent } from '../meta-browse.base.component';
import { ActivatedRoute } from '@angular/router';
import { MetadataService } from '../../../logic/services/metadata.service';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { FormBuilder, Validators } from '@angular/forms';
import { JobRunnerUiService } from '../../../ui/infrastructure/job-runner-ui.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
var DrugManufacturerBrowseComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DrugManufacturerBrowseComponent, _super);
    function DrugManufacturerBrowseComponent(route, metadataService, alertService, globalWaitingOverlayService, lookupService, bookmarkService, fb, jobRunnerUiService, dataCachingService) {
        var _this = _super.call(this, route, metadataService, alertService, globalWaitingOverlayService, lookupService, fb, bookmarkService, dataCachingService) || this;
        _this.jobRunnerUiService = jobRunnerUiService;
        _this.gridColumnDefs = [
            { headerName: '№', field: 'id', width: 100 },
            { headerName: 'Наименование', field: 'caption', tooltipField: 'caption', width: 350 },
        ];
        return _this;
    }
    DrugManufacturerBrowseComponent.prototype.getMetaTitle = function () {
        return 'Производители препаратов';
    };
    DrugManufacturerBrowseComponent.prototype.getMetaName = function () {
        return 'drug-manufacturer';
    };
    DrugManufacturerBrowseComponent.prototype.getId = function (formGroup) {
        return formGroup.get('id').value;
    };
    DrugManufacturerBrowseComponent.prototype.getGroupDef = function (row) {
        return {
            id: [row.id],
            caption: [row.caption, Validators.required],
        };
    };
    DrugManufacturerBrowseComponent.prototype.getRowStyle = function (params) {
        if (params.data && params.data.dateDeleted) {
            return { color: 'silver' };
        }
    };
    return DrugManufacturerBrowseComponent;
}(MetaBrowseBaseComponent));
export { DrugManufacturerBrowseComponent };
