import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/internal/operators';
import {PstReportService} from './pst-report.service';

@Injectable({
  providedIn: 'root',
})
export class PstReportStructureService {

  constructor(private pstReportService: PstReportService) {
  }

  public storeIndicatorValue(model: any, reportIndicator: any, indicatorTypeMeta: any, indicatorLevel: any, value: any): boolean {
    // реентерабельности тут нету - пока не сохранился предыдущий - новый не даем сохранять
    if (model.__cl_storing_indicator) {
      return false;
    }
    model.__cl_storing_indicator = true;
    const indicator = {
      date: reportIndicator.indicatorDate,
      indicatorTypeId: indicatorTypeMeta.id,
      level: indicatorLevel.level,
      regionGroupId: indicatorLevel.regionGroupId,
      regionId: indicatorLevel.regionId,
      kfhType: indicatorLevel.kfhType,
      agentId: indicatorLevel.agentId,
      subtypeId: indicatorLevel.subtypeId,
      constructionId: indicatorLevel.constructionId,
      sellDealId: indicatorLevel.sellDealId,
      buyDealId: indicatorLevel.buyDealId,
      value,
    };

    this.pstReportService.storeIndicator(indicator, model.reportMeta.id).subscribe({
      next: updatedIndicators => {
        for (const key in updatedIndicators) {
          if (updatedIndicators.hasOwnProperty(key)) {
            model.values[key] = updatedIndicators[key];
          }
        }
        model.__cl_storing_indicator = false;
      }, error: err => {
        model.__cl_storing_indicator = false;
        model.__cl_storing_error = err.error && err.error.messages && err.error.messages.length
          ? err.error.messages[0].message
          : 'Неизвестная ошибка';
      }
    });
    return true;
  }

  public getIndicatorValueCode(branch: any, indicatorLevel: any, reportIndicator: any, indicatorTypeMeta: any) {
    const useAgentId = indicatorLevel.level >= 30 && branch.meta.params['use-agent-id'] ? +branch.meta.params['use-agent-id'] : undefined;
    const useKfhType = useAgentId ? 1 : undefined;
    const useSubtypeId = reportIndicator.meta.params['use-subtype-id'] ? +reportIndicator.meta.params['use-subtype-id'] : undefined;
    const code = `${indicatorTypeMeta.id}:${indicatorLevel.level}:${reportIndicator.indicatorDate}:`
      + `${indicatorLevel.regionGroupId || 'null'}:${indicatorLevel.regionId || 'null'}:`
      + `${reportIndicator.meta.kfhType || useKfhType || indicatorLevel.kfhType || 'null'}:`
      + `${useAgentId || indicatorLevel.agentId || 'null'}:`
      + `${useSubtypeId || indicatorLevel.subtypeId || 'null'}:${indicatorLevel.constructionId || 'null'}:`
      + `${indicatorLevel.sellDealId || 'null'}:${indicatorLevel.buyDealId || 'null'}`;
    return code;
  }

  public mergeHeaderCells(branch: any) {
    // объединим колонки с одинаковым названием по вертикали
    const lastRow = branch.headerRows[branch.headerRows.length - 1];
    for (let iRow = 0; iRow < branch.headerRows.length; iRow++) {
      for (let iCol = 0; iCol < lastRow.length; iCol++) {
        if (!branch.headerRows[iRow][iCol]) {
          continue;
        }
        let iRow2 = iRow + 1;
        while (iRow2 < branch.headerRows.length
        && branch.headerRows[iRow][iCol].rowSpan
        && branch.headerRows[iRow][iCol].displayTitle === branch.headerRows[iRow2][iCol].displayTitle) {
          branch.headerRows[iRow][iCol].rowSpan++;
          branch.headerRows[iRow2][iCol].rowSpan = 0;
          iRow2++;
        }

        if (iRow2 === branch.headerRows.length && branch.headerRows[iRow][iCol].rowSpan) {
          branch.headerRows[iRow][iCol].lastRow = true;
        }
      }
    }
    // объединим колонки с одинаковым названием по горизонтали
    for (let iCol = 0; iCol < lastRow.length; iCol++) {
      for (let iRow = 0; iRow < branch.headerRows.length; iRow++) {
        if (!branch.headerRows[iRow][iCol]) {
          continue;
        }
        let iCol2 = iCol + 1;
        while (iCol2 < lastRow.length
              && branch.headerRows[iRow][iCol].colSpan
              && branch.headerRows[iRow][iCol].displayTitlePath === branch.headerRows[iRow][iCol2].displayTitlePath) {
          if (typeof branch.headerRows[iRow][iCol2].visible !== 'function' || branch.headerRows[iRow][iCol2].visible()) {
            branch.headerRows[iRow][iCol].colSpan++;
          }
          branch.headerRows[iRow][iCol2].colSpan = 0;
          iCol2++;
        }
      }
    }
  }
}
