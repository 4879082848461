<div id="interceptNoAccess">
  <div [formGroup]="editMessage">
    <label for="textNewMessage" style="display:block"
           aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md">
    <textarea type="text" id="textNewMessage" style="width:500px;height:100px"
              class="itech-control-normal itech-quarantine-textarea itech-quarantine-textarea-large"
              placeholder="Текст сообщения..." maxlength="255" formControlName="message"></textarea>
    <button type="button" class="btn btn-primary-outline btn-link" (click)="store()"
            style="height:100px;vertical-align:bottom;">
      отправить
    </button>
    </label>
  </div>
  <div>
    <div *ngFor="let message of messages" style="display:block;padding:10px 0">
      <span style="font-size:10px">
        {{formatTime(message.time)}}
        {{message.userId|lookup:'user'|async}}
      </span>
      <button *ngIf="message.userId == (securityService.getCurrentUserId() | async)"
              type="button" class="btn btn-danger btn-link itech-inline-tool-btn" title="Редактировать"
              (click)="edit(message)">
        <clr-icon shape="pencil"></clr-icon>
      </button>
      <button *ngIf="message.userId == (securityService.getCurrentUserId() | async)"
              type="button" class="btn btn-danger btn-link itech-inline-tool-btn" title="Удалить"
              (click)="delete(message)">
        <clr-icon shape="trash" style="color:red"></clr-icon>
      </button>
      <div style="border:1px solid silver;border-radius:5px;padding:5px 10px;max-width:max-content"
            [style.background-color]="message.userId == (securityService.getCurrentUserId() | async) ? '#aca5ff5e' : '#e6e6e6'">
        {{message.message}}
      </div>
    </div>
  </div>

</div>
