
<div class="form compact" *ngIf="currentFormGroup" [formGroup]="currentFormGroup">
  <ng-container *ngFor="let control of currentFormGroup.controls | keyvalue">
    <div class="form-group">
      <label>{{(params[control.key] || {}).caption}}</label>
      <ng-container *ngIf="+((params[control.key] || {}).type) === 1">
        <label [for]="control.key" aria-haspopup="true" role="tooltip"
               class="tooltip tooltip-validation tooltip-md"
               [class.invalid]="isInvalid(currentFormGroup, control.key)">
          <input type="date" [id]="control.key" class="itech-control-normal" [formControlName]="control.key">
          <app-validation-tooltip [input]="currentFormGroup.controls[control.key]"></app-validation-tooltip>
        </label>
      </ng-container>
    </div>
  </ng-container>
</div>
  