import { Component, Input } from '@angular/core';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { ProductDataService } from '../../../logic/services/product-data.service';
import { ProductSearchResultsFormComponent } from '../search/product-search-results-form.component';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';
import { AgGridProductTransactionDetailRendererComponent } from '../../../ui/controls/ag-grid-product-transaction-detail-renderer.component';
import { ProductSearchResultsComponent } from '../search/product-search-results.component';
import { AlertService } from '../../../ui/infrastructure/alert.service';

@Component({
  selector: 'app-edit-product-subproducts',
  templateUrl: './edit-product-subproducts.component.html'
})
export class EditProductSubproductsComponent {

  @Input() model: any = {};

  isInvalid = FormHelper.isInvalid;
  getRowStyle = ProductSearchResultsFormComponent.getRowStyle;
  gridColumnDefs = ProductSearchResultsFormComponent.getGridColumnDefs();

  detailCellRenderer = 'myDetailCellRenderer';
  frameworkComponents = { myDetailCellRenderer: AgGridProductTransactionDetailRendererComponent };
  gridIcons = {
    groupContracted: '<clr-icon shape="organization" style="cursor: pointer"></clr-icon>',
  };

  animalTypeIdLookup: any = {};

  private _id: number;
  @Input()
  get id(): number {
    return this._id;
  }

  set id(id: number) {
    if (this._id !== id) {
      this._id = id;
      this.refreshFormDataInternal();
    }
  }

  constructor(private productDataService: ProductDataService,
              private dataCachingService: DataCachingService,
              private waitingOverlay: GlobalWaitingOverlayService,
              private lookupSourceService: LookupSourceService,
              public navigationService: AppNavigationService,
              public cacheService: AddressPersonFioCacheService,
              private alertService: AlertService) {

    this.lookupSourceService.getLookupObj('animal-type').subscribe(lookup => this.animalTypeIdLookup = lookup);
  }

  refreshFormData() {
    this.dataCachingService.removeCachedData('EditProductSubproducts', this.id.toString());
    this.refreshFormDataInternal();
  }

  refreshFormDataInternal() {
    this.waitingOverlay.StartWaiting();

    const existing = this.dataCachingService.getCachedData('EditProductSubproducts', this.id.toString());

    if (existing) {
      this.model = existing;
      this.waitingOverlay.EndWaiting();
    } else {
      this.productDataService.searchBySourceProduct(this.id)
        .subscribe(data => {
          this.lookupSourceService.getLookupObj('product-subtype').subscribe(productSubtypeLookup => {
            this.lookupSourceService.getLookupObj('product-status').subscribe(productStatusLookup => {
              this.lookupSourceService.getLookupObj('transaction-type').subscribe(transactionTypeLookup => {
                data.forEach(el => {
                  el.transactionTypeCaption = transactionTypeLookup[el.transactionType];
                  el.statusCaption = productStatusLookup[el.status];
                  if (el.status === -100 && el.templateCaption) {
                    el.statusCaption = 'Шаблон: ' + el.templateCaption;
                  }

                  if (el.stableProductSubtypeCaption) {
                    el.productSubtypeIdCaption = el.stableProductSubtypeCaption;
                  } else {
                    const productSubtypeRec = productSubtypeLookup[el.productTypeId].find(ptst => ptst.id === el.productSubtypeId);
                    el.productSubtypeIdCaption = productSubtypeRec ? productSubtypeRec.caption : undefined;
                  }
                });
                this.model = {searchResults: data, showHistory: this.model.showHistory, selectedRows: []};
                this.dataCachingService.addToCache('EditProductSubproducts', this.id.toString(), this.model);
                this.waitingOverlay.EndWaiting();
              });
            });
          });
        }, () => {
          this.waitingOverlay.EndWaiting();
        });
    }
  }

  addNew() {
    this.navigationService.performProductDerivation(this.id, 4);
  }

  onGridSelectionChanged(params: any) {
    this.model.selectedRows = params.api.getSelectedRows();
    this.model.selectedRowsIds = this.model.selectedRows.map(x => x.id);
  }

  deleteDocument() {
    ProductSearchResultsComponent.deleteManyDocuments(this.model.selectedRows, this.alertService, this.productDataService,
      () => this.refreshFormData());
  }

  sendToRegistration() {
    ProductSearchResultsComponent.sendManyDocumentsToRegistration(this.model.selectedRows, this.alertService, this.productDataService,
      () => this.refreshFormData());
  }

  sendToClearance() {
    ProductSearchResultsComponent.sendManyDocumentsToClearance(this.model.selectedRows, this.alertService, this.productDataService,
      () => this.refreshFormData());
  }

  createTransportForSelectedDocuments() {
    ProductSearchResultsComponent.createTransportForSelectedDocuments(this.model.selectedRows, this.navigationService);
  }
}
