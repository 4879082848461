import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AddressComponent } from './address.component';
import { StableSearchModalComponent } from '../../pages/edit-stable/search/stable-search-modal.component';
import { AppNavigationService } from '../../logic/services/app-navigation.service';
var AddressQuarantineTerritoryComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AddressQuarantineTerritoryComponent, _super);
    function AddressQuarantineTerritoryComponent(navigationService) {
        var _this = _super.call(this) || this;
        _this.navigationService = navigationService;
        _this.kind = [
            { id: 0, caption: 'В регионе' },
            { id: 1, caption: 'Вне региона' },
            { id: 2, caption: 'Иной' },
            { id: 3, caption: 'Мун.образование' },
            { id: 4, caption: 'Поднадзорный объект' }
        ];
        return _this;
    }
    AddressQuarantineTerritoryComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.contextFormGroup = this.contextFormGroupExtended.controls['address'];
        this.contextFormGroup.get('kind').valueChanges.subscribe(function () {
            return _this.contextFormGroupExtended.get('kind').setValue(_this.contextFormGroup.get('kind').value);
        });
    };
    AddressQuarantineTerritoryComponent.prototype.searchStable = function (formGroup) {
        this.navigationService.searchStable(StableSearchModalComponent, {})
            .subscribe(function (val) {
            if (val) {
                formGroup.get('stableId').setValue(val);
                formGroup.markAsDirty();
            }
        });
    };
    return AddressQuarantineTerritoryComponent;
}(AddressComponent));
export { AddressQuarantineTerritoryComponent };
