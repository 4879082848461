import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { DataCachingService } from '../../logic/services/data-caching.service';
import { FormHelper } from '../../ui/controls/form-helper';
import { GlobalWaitingOverlayService } from '../../ui/infrastructure/global-waiting-overlay.service';
import { MetadataService } from '../../logic/services/metadata.service';
import { BookmarkService } from '../../logic/services/bookmark.service';
import { environment } from '../../../environments/environment';
import { StringHelper } from '../../helpers/string-helper';
import { AddressPersonFioCacheService } from '../../logic/services/address-person-fio-cache.service';
import { LookupSourceService } from '../../logic/services/lookup-source.service';
import { DateHelper } from '../../helpers/date-helper';
import { AppNavigationService } from '../../logic/services/app-navigation.service';
var ViewIntegrationLog2Component = /** @class */ (function () {
    function ViewIntegrationLog2Component(route, dataCachingService, lookupSourceService, globalWaitingOverlayService, personFioCacheService, metadataService, fb, bookmarkService, navigationService) {
        var _this = this;
        this.route = route;
        this.dataCachingService = dataCachingService;
        this.lookupSourceService = lookupSourceService;
        this.globalWaitingOverlayService = globalWaitingOverlayService;
        this.personFioCacheService = personFioCacheService;
        this.metadataService = metadataService;
        this.fb = fb;
        this.bookmarkService = bookmarkService;
        this.navigationService = navigationService;
        this.model = ViewIntegrationLog2Component.getDefaultModel(this.fb);
        this.isInvalid = FormHelper.isInvalid;
        this.gridColumnDefs = [
            {
                headerName: 'Время', field: 'mercuryExchangeLastTime', width: 140,
                valueFormatter: function (params) { return StringHelper.getRuTimeWithSeconds(params.value); },
            },
            {
                headerName: 'Вид операции', field: 'conversationTypeCaption', width: 180,
                cellRenderer: function (params) {
                    if (params.data.title === 'Запрос') {
                        return '<clr-icon shape="redo"></clr-icon>&nbsp;' + params.value;
                    }
                    else if (params.data.title === 'Ответ') {
                        return '<clr-icon shape="undo" style="color: green"></clr-icon>&nbsp;' + params.value;
                    }
                    else {
                        return params.value;
                    }
                }
            },
            {
                headerName: 'Пользователь', field: 'createdUserCaption', width: 200,
            },
            {
                headerName: 'Статус', field: 'mercuryExchangeStatusCaption', width: 200,
                cellRenderer: (function (row) { return _this.mercuryExchangeStatusCellRender(row); }),
            },
            {
                headerName: 'Посл. результат', field: 'mercuryExchangeLastResultCaption', width: 200,
                cellRenderer: (function (row) { return _this.mercuryExchangeLastResultCellRender(row); }),
            },
            {
                headerName: 'След. попытка', field: 'mercuryExchangeNextTime', width: 140,
                valueFormatter: function (params) { return StringHelper.getRuTimeWithSeconds(params.value); },
            },
            {
                headerName: 'Подробности',
                field: 'mercuryExchangeLastResultText'
            },
            {
                headerName: 'Время создания', field: 'createdTime', hide: true,
                valueFormatter: function (params) { return StringHelper.getRuTimeWithSeconds(params.value); },
            },
        ];
    }
    ViewIntegrationLog2Component.getDefaultModel = function (fb, defaultFilterParams) {
        return {
            searchResults: [],
            filterFormGroup: fb ? ViewIntegrationLog2Component.buildSearchForm(fb, defaultFilterParams) : undefined
        };
    };
    ViewIntegrationLog2Component.buildSearchForm = function (fb, defaultFilterParams) {
        if (defaultFilterParams === void 0) { defaultFilterParams = {}; }
        return fb.group({
            dateType: 1,
            dateFrom: [defaultFilterParams.dateFrom, Validators.compose([
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent && +ctrl.parent.get('dateType').value === 1; }),
                    FormHelper.validateDateTimePicker()
                ])],
            dateTo: [defaultFilterParams.dateTo, Validators.compose([
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent && +ctrl.parent.get('dateType').value === 1; }),
                    FormHelper.validateDateTimePicker()
                ])],
            dateCreatedFrom: [defaultFilterParams.dateCreatedFrom, Validators.compose([
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent && +ctrl.parent.get('dateType').value === 2; }),
                    FormHelper.validateDateTimePicker()
                ])],
            dateCreatedTo: [defaultFilterParams.dateCreatedTo, Validators.compose([
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent && +ctrl.parent.get('dateType').value === 2; }),
                    FormHelper.validateDateTimePicker()
                ])],
            conversationTypeId: defaultFilterParams.conversationTypeId,
            conversationStageId: defaultFilterParams.conversationStageId,
            mercuryExchangeStatuses: fb.array((defaultFilterParams.mercuryExchangeStatuses || []).map(function (el) { return fb.control(el); })),
            mercuryExchangeLastResults: fb.array((defaultFilterParams.mercuryExchangeLastResults || []).map(function (el) { return fb.control(el); })),
        });
    };
    Object.defineProperty(ViewIntegrationLog2Component.prototype, "getDateType", {
        get: function () {
            return +this.model.filterFormGroup.get('dateType').value;
        },
        enumerable: true,
        configurable: true
    });
    ViewIntegrationLog2Component.prototype.ngOnInit = function () {
        var _this = this;
        this.route.paramMap
            .subscribe(function () {
            var existing = _this.dataCachingService.getCachedData('ViewIntegrationLog2Component', '1');
            if (existing) {
                _this.model = existing;
            }
            else {
                _this.model = ViewIntegrationLog2Component.getDefaultModel(_this.fb, {
                    dateFrom: StringHelper.getISODate(DateHelper.addDays(new Date(), -2)),
                    dateTo: StringHelper.getISODate(new Date()),
                    mercuryExchangeStatuses: [-1, 0, 2, 3, 4],
                });
                _this.dataCachingService.addToCache('ViewIntegrationLog2Component', '1', _this.model);
                _this.refreshResults();
            }
            _this.bookmarkService.addDictionaryBookmark('Просмотр журнала интеграции', ['/view-integration-log2/']);
        });
    };
    ViewIntegrationLog2Component.prototype.refreshResults = function () {
        var _this = this;
        FormHelper.markAsSubmitted(this.model.filterFormGroup);
        if (!this.model.filterFormGroup.valid) {
            return;
        }
        this.globalWaitingOverlayService.StartWaiting();
        this.lookupSourceService.getLookupObj('mercury-agent-exchange-status').subscribe(function (exchangeStatuses) {
            _this.lookupSourceService.getLookupObj('mercury-exchange-result').subscribe(function (exchangeLastResults) {
                _this.lookupSourceService.getLookupObj('mercury-conversation-types').subscribe(function (conversationTypes) {
                    _this.metadataService.searchConversations(_this.model.filterFormGroup.value)
                        .subscribe({
                        next: function (data) {
                            _this.model.searchResults = data;
                            data.forEach(function (row) {
                                row.conversationTypeCaption = conversationTypes[row.conversationTypeId];
                                row.mercuryExchangeStatusCaption = exchangeStatuses[row.mercuryExchangeStatus];
                                row.mercuryExchangeLastResultCaption = exchangeLastResults[row.mercuryExchangeLastResult];
                                _this.personFioCacheService.getUser(row.createdUser).subscribe(function (userRow) {
                                    row.createdUserCaption = userRow.caption;
                                    _this.enqueeRedraw();
                                });
                            });
                            _this.globalWaitingOverlayService.EndWaiting();
                        },
                        error: function () {
                            _this.globalWaitingOverlayService.EndWaiting();
                        }
                    });
                });
            });
        });
    };
    ViewIntegrationLog2Component.prototype.filterEnterPressed = function ($event) {
        if ($event.key === 'Enter') {
            this.refreshResults();
        }
    };
    ViewIntegrationLog2Component.prototype.downloadFile = function (uri) {
        this.metadataService.preloadFile(uri).subscribe(function (fileHash) {
            window.open(environment.api + '/files/get?preloadId=' + encodeURIComponent(fileHash));
        });
    };
    ViewIntegrationLog2Component.prototype.enqueeRedraw = function () {
        var _this = this;
        if (this.redrawTimerId) {
            clearTimeout(this.redrawTimerId);
        }
        this.redrawTimerId = setTimeout(function () {
            _this.agGridApi.redrawRows();
        }, 500);
    };
    ViewIntegrationLog2Component.prototype.agGridReady = function (params) {
        this.agGridApi = params.api;
    };
    ViewIntegrationLog2Component.prototype.mercuryExchangeStatusCellRender = function (row) {
        var resultIcon = '';
        switch (row.data.mercuryExchangeStatus) {
            case 1:
            case -2:
                resultIcon = ViewIntegrationLog2Component.successIcon;
                break;
            case -1:
                resultIcon = ViewIntegrationLog2Component.failIcon;
                break;
            case 2:
            case 3:
            case 4:
                resultIcon = ViewIntegrationLog2Component.processIcon;
                break;
        }
        return resultIcon + " " + row.data.mercuryExchangeStatusCaption;
    };
    ViewIntegrationLog2Component.prototype.mercuryExchangeLastResultCellRender = function (row) {
        var resultIcon = ViewIntegrationLog2Component.failIcon;
        switch (row.data.mercuryExchangeLastResult) {
            case 1:
                resultIcon = ViewIntegrationLog2Component.successIcon;
                break;
            case 2:
                resultIcon = ViewIntegrationLog2Component.processIcon;
                break;
        }
        return resultIcon + " " + row.data.mercuryExchangeLastResultCaption;
    };
    ViewIntegrationLog2Component.prototype.gridDblClick = function ($event) {
        if ($event.data.conversationTypeId === -1) {
            this.navigationService.performAgentEditing($event.data.targetObjId);
        }
        else if ($event.data.conversationTypeId === -2 || $event.data.conversationTypeId === 2 || $event.data.conversationTypeId === 5
            || $event.data.conversationTypeId === 10) {
            this.navigationService.performStableEditing($event.data.targetObjId);
        }
        else {
            this.navigationService.performProductEditing($event.data.targetObjId);
        }
    };
    ViewIntegrationLog2Component.prototype.changeToggle = function () {
        this.model.filterFormGroup.get('dateType').setValue(+this.model.filterFormGroup.get('dateType').value === 1 ? 2 : 1);
        if (+this.model.filterFormGroup.get('dateType').value === 1) {
            this.model.filterFormGroup.get('dateFrom').setValue(this.model.filterFormGroup.get('dateCreatedFrom').value);
            this.model.filterFormGroup.get('dateTo').setValue(this.model.filterFormGroup.get('dateCreatedTo').value);
            this.model.filterFormGroup.get('dateCreatedFrom').setValue(null);
            this.model.filterFormGroup.get('dateCreatedTo').setValue(null);
        }
        else {
            this.model.filterFormGroup.get('dateCreatedFrom').setValue(this.model.filterFormGroup.get('dateFrom').value);
            this.model.filterFormGroup.get('dateCreatedTo').setValue(this.model.filterFormGroup.get('dateTo').value);
            this.model.filterFormGroup.get('dateFrom').setValue(null);
            this.model.filterFormGroup.get('dateTo').setValue(null);
        }
    };
    ViewIntegrationLog2Component.successIcon = '<clr-icon shape="success-standard" class="is-solid" style="color: green"></clr-icon>';
    ViewIntegrationLog2Component.failIcon = '<clr-icon shape="times-circle" class="is-solid" style="color: red"></clr-icon>';
    ViewIntegrationLog2Component.processIcon = '<clr-icon shape="play" class="is-solid" style="color: navy"></clr-icon>';
    return ViewIntegrationLog2Component;
}());
export { ViewIntegrationLog2Component };
