<div class="itech-common-wrap" id="interceptNoAccess">
  <div style="width: 100%">
    <app-grid [pagination]="false" [getRowStyle]="getRowStyle" [uniqueGridName]="'EditAnimalEventsGrid'"
              [rowData]="model.searchResults" (rowClicked)="navigationService.performAnimalEventEditing($event.data.id)"
              [columnDefs]="gridColumnDefs"
              [exportFileName]="'Список мероприятий животного ' + (id|lookup:'animal'|async)"></app-grid>
  </div>

  <div class="itech-form-actions">
    <div>
      <button class="btn btn-secondary" (click)="refreshFormData()" [disabled]="isExternal ? true : undefined">
        <clr-icon shape="refresh"></clr-icon>&nbsp;Обновить
      </button>
      <button class="btn btn-primary-outline" [disabled]="isExternal ? true : undefined"
              (click)="navigationService.navigateJournal(undefined, 9, id, undefined)">
        <clr-icon shape="view-list"></clr-icon>&nbsp;Журнал
      </button>
      <button class="btn btn-success-outline" (click)="addNew()" [disabled]="isExternal ? true : undefined">
        <clr-icon shape="plus"></clr-icon>&nbsp;Добавить
      </button>
    </div>
  </div>
</div>
