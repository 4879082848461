import { Component, Input } from '@angular/core';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { AnimalDataService } from '../../../logic/services/animal-data.service';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';
import { EditAgentAnimalsComponent } from '../../edit-agent/animals/edit-agent-animals.component';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { JobRunnerUiService } from '../../../ui/infrastructure/job-runner-ui.service';

@Component({
  selector: 'app-edit-stable-animals',
  templateUrl: './edit-stable-animals.component.html'
})
export class EditStableAnimalsComponent {

  @Input() model: any = {};

  isInvalid = FormHelper.isInvalid;

  gridColumnDefs = EditAgentAnimalsComponent.getGridColumnDefs();

  private _id: number;
  @Input()
  get id(): number {
    return this._id;
  }

  set id(id: number) {
    if (this._id !== id) {
      this._id = id;
      this.refreshFormDataInternal();
    }
  }

  constructor(private animalDataService: AnimalDataService,
              private dataCachingService: DataCachingService,
              private waitingOverlay: GlobalWaitingOverlayService,
              private cacheService: AddressPersonFioCacheService,
              private lookupSourceService: LookupSourceService,
              public navigationService: AppNavigationService,
              private jobRunnerUiService: JobRunnerUiService,
              private alertService: AlertService) {
  }

  refreshFormData() {
    this.dataCachingService.removeCachedData('EditStableAnimals', this.id.toString());
    this.refreshFormDataInternal();
  }

  refreshFormDataInternal() {
    this.waitingOverlay.StartWaiting();

    const existing = this.dataCachingService.getCachedData('EditStableAnimals', this.id.toString());

    if (existing) {
      this.model = existing;
      this.waitingOverlay.EndWaiting();
    } else {
      this.lookupSourceService.getLookupObj('animal-type').subscribe(animalTypeLookup => {
        this.lookupSourceService.getLookupObj('animal-subtype').subscribe(animalSubtypeIdLookup  => {
        this.animalDataService.searchByStable(this.id, this.model.showHistory)
          .subscribe({
            next: data => {

              data.forEach(el => {
                const animalSubtype = (animalSubtypeIdLookup[el.animalTypeId] || []).find(x => x.id === el.animalSubtypeId);
                el.animalTypeIdCaption = el.animalTypeId ? animalTypeLookup[el.animalTypeId] : '<<Не указан>>';
                el.animalSubtypeIdCaption = animalSubtype ? animalSubtype.caption : '<<не указан>>';

                el.totalCount = el.animalGroup ? el.totalCount : el.deathDate ? 0 : 1;
              });

              this.model = {searchResults: data, showHistory: this.model.showHistory};
              this.dataCachingService.addToCache('EditStableAnimals', this.id.toString(), this.model);
              this.waitingOverlay.EndWaiting();
            },
            error: () => {
              this.waitingOverlay.EndWaiting();
            }
          });
        });
      });
    }
  }

  getRowStyle(params) {
    if (params.data && +params.data.totalCount === 0) {
      return {color: 'silver'};
    }
  }

  addNew() {
    this.cacheService.getStable(this.id).subscribe(stable => {
      this.navigationService.performAnimalCreation(stable.currentAgentId, this.id);
    });
  }

  updateFromMercuryAndRefreshFormData() {
    setTimeout(() => {
      this.jobRunnerUiService.runOperation(16, /* Обновление данных о животных из журнала продукции Меркурия */
        {
          type: 'updateAnimalsFromMercuryJournalSync',
          stableId: this.id
        }, undefined, undefined, false)
        .subscribe(() => {
          this.refreshFormData();
          this.alertService.success('Сведения о животных актуализированы');
        });
    }, 1);
  }

  onGridSelectionChanged(params: any) {
    this.model.selectedRows = (params.api.getSelectedRows() || []).filter(x => x.tempId);
  }

  confirmSelected() {
    this.alertService.confirmModal('Подтвердить выделенных животных?')
      .subscribe(val => {
        if (val) {
          this.animalDataService.confirmManyAnimalExternal(this.model.selectedRows).subscribe(() => this.refreshFormData());
        }
      });
  }
}
