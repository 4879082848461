import { Component, OnInit } from '@angular/core';
import { AppNavigationService } from '../../logic/services/app-navigation.service';
import { SecurityService } from '../../logic/services/security.service';
import { ActivatedRoute } from '@angular/router';
import { DateHelper } from '../../helpers/date-helper';
import { StringHelper } from '../../helpers/string-helper';
import { AddressPersonFioCacheService } from '../../logic/services/address-person-fio-cache.service';
import { LookupSourceService } from '../../logic/services/lookup-source.service';
import { JournalMasterDetailCellRendererComponent } from './journal-master-detail-cell-renderer.component';
import { JournalLookupCellRendererComponent } from './journal-lookup-cell-renderer.component';
import { DataCachingService } from '../../logic/services/data-caching.service';

@Component({
  templateUrl: './journal.component.html'
})
export class JournalComponent implements OnInit {

  userId: number;
  objId1: number;
  objId2: number;
  objKindId: number;
  journal = [];

  gridColumnDefs = [
    {
      headerName: 'Объект', field: 'objCaption', width: 200,
      // cellRenderer: params => '<a>' + params.data.objCaption + '</a>'
      cellRenderer: 'lookupRenderer',
      cellRendererParams: (row) => {
        return {
          type: JournalLookupCellRendererComponent.TYPE_OBJ_CAPTION,
          data: row.data,
        };
      },
    },
    {
      headerName: 'Время', field: 'changeTime',
      valueFormatter: params => StringHelper.getRuTimeWithSeconds(params.value),
      width: 120,
    },
    {
      headerName: 'Тип', field: 'changeTypeCaption',
      width: 100,
      cellRenderer: 'lookupRenderer',
      cellRendererParams: (row) => {
        return {
          type: JournalLookupCellRendererComponent.TYPE_CHANGE_TYPE,
          data: row.data,
        };
      },
    },
    {
      headerName: 'Учреждение',
      field: 'institutionId',
      width: 160,
      cellRenderer: 'lookupRenderer',
      cellRendererParams: (row) => {
        return {
          type: JournalLookupCellRendererComponent.TYPE_INSTITUTION_CAPTION,
          data: row.data,
        };
      },
    },
    {
      headerName: 'Пользователь',
      field: 'userId',
      width: 200,
      cellRenderer: 'lookupRenderer',
      cellRendererParams: (row) => {
        return {
          type: JournalLookupCellRendererComponent.TYPE_USER_CAPTION,
          data: row.data,
        };
      },
    },
    {
      headerName: 'Ip-адрес',
      field: 'ip',
      width: 160,
    },
    {
      headerName: 'Подробности',
      field: 'changeInfo',
      cellRenderer: 'agGroupCellRenderer'
    },
  ];

  detailCellRenderer = 'myDetailCellRenderer';
  frameworkComponents = {
    myDetailCellRenderer: JournalMasterDetailCellRendererComponent,
    lookupRenderer: JournalLookupCellRendererComponent,
  };

  gridApi;

  constructor(private route: ActivatedRoute,
              private securityService: SecurityService,
              private personFioCacheService: AddressPersonFioCacheService,
              private lookupSourceService: LookupSourceService,
              private navigationService: AppNavigationService,
              private dataCachingService: DataCachingService) {
  }

  ngOnInit(): void {

    this.route.paramMap
      .subscribe(params => {
        this.userId = +params.get('userId') || undefined;
        this.objId1 = +params.get('objId1') || undefined;
        this.objId2 = +params.get('objId2') || undefined;
        this.objKindId = +params.get('objKindId') || undefined;

        this.refreshResults();
      });
  }

  refreshResults(showAll?: boolean) {
    if (this.userId) {
      const existing = this.dataCachingService.getCachedData('Journal', 'byUserId');
      if (existing) {
        this.journal = existing;
      } else {
        const dateFrom = showAll ? new Date('2000-01-01') : DateHelper.addMonths(new Date(), -1);
        this.securityService.getJournalByUser(this.userId, dateFrom, new Date('9999-12-31'))
          .subscribe(val => {
            this.journal = val;
            this.dataCachingService.addToCache('Journal', 'byUserId', val);
          });
      }
    } else {
      this.securityService.getJournalByObject(this.objKindId, this.objId1, this.objId2)
        .subscribe(val => this.journal = val);
    }
  }

  agGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  cellClicked($event: any) {
    if ($event.colDef.field !== 'objCaption') {
      return;
    }

    if ($event.data.objKindId === 1) {
      this.navigationService.performInstitutionEditing($event.data.objId1);
    } else if ($event.data.objKindId === 2) {
      this.navigationService.performInstitutionEditing($event.data.objId1);
    } else if ($event.data.objKindId === 3) {
      this.navigationService.performInstitutionEditing($event.data.objId1);
    } else if ($event.data.objKindId === 4) {
      this.navigationService.performSecurityRoleEditing($event.data.objId1);
    } else if ($event.data.objKindId === 5) {
      this.navigationService.performAgentEditing($event.data.objId1);
    } else if ($event.data.objKindId === 6) {
      this.navigationService.performStableEditing($event.data.objId1);
    } else if ($event.data.objKindId === 7) {
      this.navigationService.performAnimalEditing($event.data.objId1);
    } else if ($event.data.objKindId === 8) {
      this.navigationService.performDrugEditing($event.data.objId1);
    } else if ($event.data.objKindId === 9) {
      this.navigationService.performAnimalEventEditing($event.data.objId2);
    } else if ($event.data.objKindId === 10) {
      this.navigationService.performProductEditing($event.data.objId1);
    } else if ($event.data.objKindId === 11) {
      this.navigationService.performProductExpertiseEditing($event.data.objId1, $event.data.objId2);
    } else if ($event.data.objKindId === 21) {
      this.navigationService.performQuarantineEditing($event.data.objId1);
    } else if ($event.data.objKindId === 22) {
      this.navigationService.performQuarantineEditing($event.data.objId1);
    }
  }

  getRowStyle(params) {
    if (params.data && params.data.changeType === 4) {
      return {color: 'blue'};
    }
  }
}
