import { FormGroup } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
var QueryLookupListFilterComponent = /** @class */ (function () {
    function QueryLookupListFilterComponent() {
        this.useCheckboxes = true;
        this.itemTitle = 'Выбор';
        this.class = 'form-group itech-group-whole-line';
    }
    QueryLookupListFilterComponent.prototype.isInvalid = function (cname) {
        return FormHelper.isInvalid(this.contextFormGroup, cname);
    };
    Object.defineProperty(QueryLookupListFilterComponent.prototype, "customFilterChecked", {
        get: function () {
            return this.contextFormGroup && (+this.contextFormGroup.get('_par_' + this.parameterName + '_filter').value) === 102;
        },
        set: function (val) {
            if (this.contextFormGroup) {
                this.contextFormGroup.get('_par_' + this.parameterName + '_filter').setValue(val ? 102 : 0);
                this.contextFormGroup.markAsDirty();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QueryLookupListFilterComponent.prototype, "filtered", {
        get: function () {
            return !!this.contextFormGroup.get('_par_' + this.parameterName + '_filter').value
                || (!!this.contextFormGroup.get('_par_' + this.parameterName).value
                    && !!this.contextFormGroup.get('_par_' + this.parameterName).value.length);
        },
        enumerable: true,
        configurable: true
    });
    return QueryLookupListFilterComponent;
}());
export { QueryLookupListFilterComponent };
