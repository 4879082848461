/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "@angular/common";
import * as i3 from "./pst-branch-cell-text-edit.component";
var styles_PstBranchCellTextEditComponent = [];
var RenderType_PstBranchCellTextEditComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PstBranchCellTextEditComponent, data: {} });
export { RenderType_PstBranchCellTextEditComponent as RenderType_PstBranchCellTextEditComponent };
function View_PstBranchCellTextEditComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cellValueDisplay; _ck(_v, 1, 0, currVal_0); }); }
function View_PstBranchCellTextEditComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["input", 1]], null, 7, "input", [["maxlength", "255"], ["type", "text"]], [[1, "maxlength", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "keydown"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 2)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 2)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.cellValueStr = $event) !== false);
        ad = (pd_4 && ad);
    } if (("keydown" === en)) {
        var pd_5 = (_co.inputKeyDown($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 16384, null, 0, i1.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i1.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵdid(3, 540672, null, 0, i1.MaxLengthValidator, [], { maxlength: [0, "maxlength"] }, null), i0.ɵprd(1024, null, i1.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i1.MaxLengthValidator]), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i1.DefaultValueAccessor]), i0.ɵdid(6, 671744, null, 0, i1.NgModel, [[8, null], [6, i1.NG_VALIDATORS], [8, null], [6, i1.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i1.NgControl, null, [i1.NgModel]), i0.ɵdid(8, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = "255"; _ck(_v, 3, 0, currVal_8); var currVal_9 = _co.cellValueStr; _ck(_v, 6, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = (i0.ɵnov(_v, 3).maxlength ? i0.ɵnov(_v, 3).maxlength : null); var currVal_1 = i0.ɵnov(_v, 8).ngClassUntouched; var currVal_2 = i0.ɵnov(_v, 8).ngClassTouched; var currVal_3 = i0.ɵnov(_v, 8).ngClassPristine; var currVal_4 = i0.ɵnov(_v, 8).ngClassDirty; var currVal_5 = i0.ɵnov(_v, 8).ngClassValid; var currVal_6 = i0.ɵnov(_v, 8).ngClassInvalid; var currVal_7 = i0.ɵnov(_v, 8).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
function View_PstBranchCellTextEditComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "it-focus-border"]], [[2, "it-cell-storing", null], [2, "it-cell-error", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.__cl_storing_indicator; var currVal_1 = _co.model.__cl_storing_error; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_PstBranchCellTextEditComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "it-cell-error-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.__cl_storing_error; _ck(_v, 1, 0, currVal_0); }); }
export function View_PstBranchCellTextEditComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { inputControl: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchCellTextEditComponent_1)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchCellTextEditComponent_2)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchCellTextEditComponent_3)), i0.ɵdid(6, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchCellTextEditComponent_4)), i0.ɵdid(8, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.editing; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.editing; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.focused; _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.focused && _co.model.__cl_storing_error); _ck(_v, 8, 0, currVal_3); }, null); }
export function View_PstBranchCellTextEditComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-branch-cell-text-edit", [], null, null, null, View_PstBranchCellTextEditComponent_0, RenderType_PstBranchCellTextEditComponent)), i0.ɵdid(1, 573440, null, 0, i3.PstBranchCellTextEditComponent, [], null, null)], null, null); }
var PstBranchCellTextEditComponentNgFactory = i0.ɵccf("app-branch-cell-text-edit", i3.PstBranchCellTextEditComponent, View_PstBranchCellTextEditComponent_Host_0, { branch: "branch", model: "model", indicatorLevel: "indicatorLevel", originalCellValue: "originalCellValue", editing: "editing", focused: "focused" }, { changed: "changed" }, []);
export { PstBranchCellTextEditComponentNgFactory as PstBranchCellTextEditComponentNgFactory };
