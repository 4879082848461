import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { HolidaysService } from '../../../logic/services/holidays.service';
import { TicketsPlanningUiService } from './tickets-planning-ui-service';
var TicketsPlanningDayComponent = /** @class */ (function () {
    function TicketsPlanningDayComponent(navigationService, holidaysService, planningUi) {
        this.navigationService = navigationService;
        this.holidaysService = holidaysService;
        this.planningUi = planningUi;
        this.day = {
            date: undefined,
            tickets: [],
            continuousTickets: [],
            slots: [],
            available: true,
        };
        this.model = {};
        this.fixedEmployeeMode = true;
        this.zoomedMode = undefined;
    }
    Object.defineProperty(TicketsPlanningDayComponent.prototype, "currentDate", {
        get: function () {
            var cDate = new Date();
            return new Date(cDate.getFullYear(), cDate.getMonth(), cDate.getDate());
        },
        enumerable: true,
        configurable: true
    });
    TicketsPlanningDayComponent.prototype.toggleExpandedSlot = function (slot) {
        if (!this.zoomedMode) {
            return;
        }
        if (this.model.expandedSlot !== slot) {
            this.model.expandedSlot = slot;
        }
        else {
            this.model.expandedSlot = undefined;
        }
    };
    TicketsPlanningDayComponent.prototype.slotClick = function (slot) {
        if (!this.zoomedMode) {
            return;
        }
        if (slot.tickets.length > 0 || !this.model.personId || !this.model.institutionServiceId || !this.day.available
            || this.day.continuousTickets.length > 0) {
            this.toggleExpandedSlot(slot);
        }
        else {
            this.planningUi.addTicket(slot, this.model);
        }
    };
    TicketsPlanningDayComponent.prototype.isCurrentlyFilteredSlot = function (slot) {
        // return !!slot.tickets.find(service =>
        //   this.planningUi.isCurrentlyFilteredVSlot(service, this.fixedEmployeeMode, this.model));
    };
    TicketsPlanningDayComponent.prototype.isCurrentlyFilteredVSlotTail = function (tickets) {
        // return tickets.slice(2).find(
        //   service => this.planningUi.isCurrentlyFilteredVSlot(service, this.fixedEmployeeMode, this.model));
    };
    return TicketsPlanningDayComponent;
}());
export { TicketsPlanningDayComponent };
