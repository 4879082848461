import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { MetadataService } from '../../../logic/services/metadata.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MetaBrowseBaseComponent } from '../meta-browse.base.component';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { AgGridLocalization } from '../../../ui/controls/ag-grid-localization';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { JobRunnerUiService } from '../../../ui/infrastructure/job-runner-ui.service';
import { StringHelper } from '../../../helpers/string-helper';
import { DataCachingService } from '../../../logic/services/data-caching.service';

@Component({
  templateUrl: './disease-serotype-browse.component.html'
})
export class DiseaseSerotypeBrowseComponent extends MetaBrowseBaseComponent {

  gridColumnDefs = [
    {headerName: '№', field: 'id', width: 100},
    {headerName: 'Заболевание', field: 'diseaseTypeCaption', tooltipField: 'diseaseTypeCaption', width: 200},
    {headerName: 'Серотип', field: 'caption', tooltipField: 'caption', width: 350},
    {
      headerName: 'Удалено', field: 'dateDeleted', width: 100,
      valueFormatter: params => StringHelper.getRuTimeWithSeconds(params.value),
    },
  ];

  constructor(route: ActivatedRoute,
              metadataService: MetadataService,
              alertService: AlertService,
              globalWaitingOverlayService: GlobalWaitingOverlayService,
              lookupService: LookupSourceService,
              bookmarkService: BookmarkService,
              fb: FormBuilder,
              private jobRunnerUiService: JobRunnerUiService,
              dataCachingService: DataCachingService) {
    super(route, metadataService, alertService, globalWaitingOverlayService, lookupService, fb, bookmarkService, dataCachingService);
  }

  getMetaTitle(): string {
    return 'Серотипы возбудителей';
  }

  getMetaName(): string {
    return 'disease-serotype';
  }

  getId(formGroup: FormGroup): any {
    return formGroup.get('diseaseTypeId').value && formGroup.get('id').value
      ? formGroup.get('diseaseTypeId').value + '/' + formGroup.get('id').value
      : undefined;
  }

  refreshResults() {
    this.globalWaitingOverlayService.StartWaiting();

    this.lookupService.getLookupObj('disease-type').subscribe(diseaseTypeLookup => {
      this.metadataService.getMetadata(this.getMetaName()).subscribe({
        next: data => {

          data.forEach(el => {
            el.diseaseTypeCaption = diseaseTypeLookup[el.diseaseTypeId] || '<<Неизвестная болезнь>>';
          });

          this._searchResults = data;
          this.lookupService.invalidateLookup(this.getMetaName());
          this.dataCachingService.addToCache(this.getMetaName() + '_Data', '1', data);
          this.globalWaitingOverlayService.EndWaiting();
        }, error: () => {
          this.globalWaitingOverlayService.EndWaiting();
        }
      });
    });
  }

  getGroupDef(row: any) {
    return {
      id: [row.id, Validators.compose([Validators.required, Validators.pattern(/^\d{1,5}$/)])],
      diseaseTypeId: [row.diseaseTypeId, Validators.compose([Validators.required])],
      caption: [row.caption, Validators.required],
    };
  }

  getRowStyle(params: any) {
    if (params.data && params.data.dateDeleted) {
      return {color: 'silver'};
    }
  }
}
