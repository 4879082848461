/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./common/edit-agent-common-form.component.ngfactory";
import * as i2 from "./common/edit-agent-common-form.component";
import * as i3 from "@angular/forms";
import * as i4 from "@clr/angular";
import * as i5 from "@angular/common";
import * as i6 from "../../../../node_modules/@clr/angular/clr-angular.ngfactory";
import * as i7 from "./create-agent.component";
import * as i8 from "@angular/router";
import * as i9 from "../../logic/services/bookmark.service";
import * as i10 from "../../logic/services/agent-data.service";
import * as i11 from "../../logic/services/data-caching.service";
import * as i12 from "../../logic/services/app-navigation.service";
import * as i13 from "../../logic/services/security.service";
var styles_CreateAgentComponent = [];
var RenderType_CreateAgentComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CreateAgentComponent, data: {} });
export { RenderType_CreateAgentComponent as RenderType_CreateAgentComponent };
function View_CreateAgentComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-edit-agent-common-form", [], null, null, null, i1.View_EditAgentCommonFormComponent_0, i1.RenderType_EditAgentCommonFormComponent)), i0.ɵdid(1, 573440, null, 0, i2.EditAgentCommonFormComponent, [i3.FormBuilder], { contextFormGroup: [0, "contextFormGroup"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.form; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CreateAgentComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "itech-content-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "clr-icon", [["class", "has-badge"], ["shape", "users"], ["size", "36"]], null, null, null, null, null)), i0.ɵdid(2, 16384, null, 0, i4.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, ["\u00A0\u0421\u043E\u0437\u0434\u0430\u043D\u0438\u0435 \u043D\u043E\u0432\u043E\u0439 \u043A\u0430\u0440\u0442\u043E\u0447\u043A\u0438 \u0445\u043E\u0437. \u0441\u0443\u0431\u044A\u0435\u043A\u0442\u0430\n"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CreateAgentComponent_1)), i0.ɵdid(5, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 7, "p", [], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 6, "button", [["class", "btn btn-primary"]], [[1, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createCard() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_ClrLoadingButton_0, i6.RenderType_ClrLoadingButton)), i0.ɵdid(8, 49152, null, 0, i4.ClrLoadingButton, [i0.ElementRef, i0.Renderer2], { disabled: [0, "disabled"] }, null), i0.ɵprd(2048, null, i4.LoadingListener, null, [i4.ClrLoadingButton]), i0.ɵdid(10, 147456, null, 0, i4.ClrLoading, [[2, i4.LoadingListener]], { loadingState: [0, "loadingState"] }, null), (_l()(), i0.ɵeld(11, 0, null, 0, 1, "clr-icon", [["shape", "check"]], null, null, null, null, null)), i0.ɵdid(12, 16384, null, 0, i4.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, 0, ["\u00A0\u0421\u043E\u0437\u0434\u0430\u0442\u044C "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model; _ck(_v, 5, 0, currVal_0); var currVal_2 = _co.creating; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.creating; _ck(_v, 10, 0, currVal_3); }, function (_ck, _v) { var currVal_1 = (i0.ɵnov(_v, 8).disabled ? "" : null); _ck(_v, 7, 0, currVal_1); }); }
export function View_CreateAgentComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-create-agent", [], null, null, null, View_CreateAgentComponent_0, RenderType_CreateAgentComponent)), i0.ɵdid(1, 114688, null, 0, i7.CreateAgentComponent, [i8.ActivatedRoute, i8.Router, i9.BookmarkService, i10.AgentDataService, i11.DataCachingService, i12.AppNavigationService, i13.SecurityService, i3.FormBuilder], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CreateAgentComponentNgFactory = i0.ɵccf("app-create-agent", i7.CreateAgentComponent, View_CreateAgentComponent_Host_0, { model: "model" }, {}, []);
export { CreateAgentComponentNgFactory as CreateAgentComponentNgFactory };
