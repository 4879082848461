import { EventEmitter, OnInit } from '@angular/core';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AppFormGroup } from '../../../ui/controls/app-form-group';
import { AgentSearchModalComponent } from '../../edit-agent/search/agent-search-modal.component';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';
import { StableSearchModalComponent } from '../../edit-stable/search/stable-search-modal.component';
import { FormBuilder, FormControl } from '@angular/forms';
import { AppGridEditableComponent } from '../../../ui/controls/app-grid-editable.component';
import { EditProductCommonFormComponent } from './edit-product-common-form.component';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { ProductDataService } from '../../../logic/services/product-data.service';
import { BehaviorSubject, combineLatest, of, ReplaySubject } from 'rxjs';
import { EditProductCommonComponent } from './edit-product-common.component';
import { StringHelper } from '../../../helpers/string-helper';
import { StableDataService } from '../../../logic/services/stable-data.service';
import { DateHelper } from '../../../helpers/date-helper';
var EditProductSourceFormComponent = /** @class */ (function () {
    function EditProductSourceFormComponent(navigationService, cacheService, lookupService, fb, alertService, dataCachingService, productDataService, stableDataService) {
        var _this = this;
        this.navigationService = navigationService;
        this.cacheService = cacheService;
        this.lookupService = lookupService;
        this.fb = fb;
        this.alertService = alertService;
        this.dataCachingService = dataCachingService;
        this.productDataService = productDataService;
        this.stableDataService = stableDataService;
        this.validityControlsEvent = new EventEmitter();
        this.changeTransactionTypeEmitter = new EventEmitter();
        this.isInvalid = FormHelper.isInvalid;
        this.equalsSome = FormHelper.equalsSome;
        this.isHideDates = false;
        this.forceUpdateTransportTableDataEmitter = new EventEmitter();
        this.forceUpdateProductBatchesTableDataEmitter = new EventEmitter();
        this.processMoneyKeypress = FormHelper.processMoneyKeypressDot;
        this.productIdsForEditAccumulator = [];
        this.productIdsForEditTimer = undefined;
        this.transportDocForCreateAccumulator = [];
        this.transportDocForCreateTimer = undefined;
        this.sourceBatchGridDef = [
            {
                headerName: 'Документ',
                field: 'productId',
                width: 400,
                editorType: AppGridEditableComponent.EDITOR_TYPE_MODAL_SEARCH,
                lookupName: 'product',
                recalculateDetailHeightAfterChange: true,
                editable: function () { return true; },
                searchParams: function (rowData) {
                    return {
                        stableId: EditProductCommonFormComponent.isProductionTransaction(_this.productFormGroup)
                            ? rowData && rowData.parent && rowData.parent.parent && rowData.parent.parent.contains('stableId')
                                ? rowData.parent.parent.get('stableId').value
                                : undefined
                            : undefined,
                    };
                },
                valueChangedCallback: function (newValue, rowData, rowNode, gridApi) {
                    if (rowData && rowData.parent && rowData.parent.parent) {
                        rowData.parent.parent.get('producedBatches').controls.forEach(function (produced) {
                            while (produced.get('animalIds').length) {
                                produced.get('animalIds').removeAt(0);
                            }
                        });
                        rowData.parent.parent.get('sourceBatches').value.forEach(function (source) {
                            if (source.productId) {
                                var existing = _this.dataCachingService.getCachedData('EditProductCommon', source.productId.toString()) ||
                                    _this.dataCachingService.getCachedData('EditProductCommon_manyProductCache', source.productId.toString());
                                var subject_1 = new BehaviorSubject({});
                                if (existing) {
                                    subject_1.next(existing.contextFormGroup ? existing.contextFormGroup.value : existing);
                                }
                                else {
                                    _this.productIdsForEditAccumulator.push(newValue);
                                    if (_this.productIdsForEditTimer) {
                                        var tmpTimer = _this.productIdsForEditTimer;
                                        _this.productIdsForEditTimer = undefined;
                                        clearTimeout(tmpTimer);
                                    }
                                    _this.productIdsForEditTimer = setTimeout(function () {
                                        if (_this.productIdsForEditAccumulator.length) {
                                            var tmpIds = _this.productIdsForEditAccumulator;
                                            _this.productIdsForEditAccumulator = [];
                                            _this.productDataService.getProductCommonForEdit2(Array.from(new Set(tmpIds))).subscribe(function (records) {
                                                records.forEach(function (val) {
                                                    _this.dataCachingService.addToCache('EditProductCommon', source.productId.toString(), val);
                                                    _this.dataCachingService.addToCache('EditProductCommon_manyProductCache', source.productId.toString(), val);
                                                    if (+val.id === +source.productId) {
                                                        subject_1.next(val);
                                                    }
                                                });
                                            });
                                        }
                                    }, 500);
                                }
                                subject_1.subscribe(function (product) {
                                    if (product) {
                                        (product['sources'] || []).forEach(function (ps) {
                                            var existingTemplates = _this.dataCachingService.getCachedData('EditStableProductionTemplateProductionStableProductSubtypes', rowData.parent.parent.get('stableId').value + "-" + ps.productTypeId + "-" + ps.productSubtypeId);
                                            (existingTemplates
                                                ? of(existingTemplates)
                                                : _this.stableDataService.searchStableProductSubtypesFromProductionTemplates(rowData.parent.parent.get('stableId').value, ps.productTypeId, ps.productSubtypeId))
                                                .subscribe(function (stableProductSubtypeIds) {
                                                _this.dataCachingService.addToCache('EditStableProductionTemplateProductionStableProductSubtypes', rowData.parent.parent.get('stableId').value + "-" + ps.productTypeId + "-" + ps.productSubtypeId, stableProductSubtypeIds);
                                                (stableProductSubtypeIds || []).forEach(function (sps) {
                                                    var existingSps = (rowData.parent.parent.controls['producedBatches'].controls || [])
                                                        .findIndex(function (el) { return +(el.get('stableProductSubtypeId').value) === sps.stableProductSubtypeId; });
                                                    if (existingSps < 0) {
                                                        rowData.parent.parent.controls['producedBatches'].controls.push(_this.fb.group(EditProductCommonFormComponent.buildProductBatch(_this.fb, {
                                                            stableId: rowData.parent.parent.get('stableId').value,
                                                            stableProductSubtypeId: sps.stableProductSubtypeId,
                                                            unitId: sps.unitId,
                                                            batchId: sps.batchId,
                                                            manufactureDateKind: 1,
                                                            manufactureDate: StringHelper.getISODateWithHourMinute(DateHelper.startDay(new Date())),
                                                            expireDateKind: 1,
                                                        })));
                                                        _this.forceUpdateProductBatchesTableDataEmitter.emit();
                                                    }
                                                    if (!rowData.parent.parent.controls['producedBatches'].controls[0].get('stableProductSubtypeId').value) {
                                                        rowData.parent.parent.controls['producedBatches'].removeAt(0);
                                                    }
                                                });
                                                [].concat.apply([], (product['sources'] || []).map(function (s) { return s.sourceAnimalIds; })).forEach(function (animalId) {
                                                    rowData.parent.parent.get('producedBatches').controls.forEach(function (p) {
                                                        if (!p.get('animalIds').value.includes(animalId)) {
                                                            p.get('animalIds').push(new FormControl(animalId));
                                                        }
                                                    });
                                                });
                                                _this.forceUpdateProductBatchesTableDataEmitter.emit();
                                            });
                                        });
                                    }
                                });
                            }
                        });
                    }
                    if (newValue) {
                        EditProductSourceFormComponent.runBackgroundUpdateProductFromMercury(newValue, _this.fb, _this.productDataService, _this.dataCachingService);
                    }
                },
            },
            {
                headerName: 'Списываемое кол-во',
                field: 'writeOff',
                width: 170,
                editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_INPUT,
                keyPressCallback: FormHelper.processMoneyKeypressDot,
                required: function (params) { return EditProductCommonFormComponent.isProductionTransaction(_this.productFormGroup) &&
                    params.data.get('productId').value; },
                editable: function () { return true; },
            },
        ];
        // если будет изменена высота после изменения содержимого master detail - откорректируй в this::masterDetailCalcHeightFunc,
        // иначе некоторые поля могут скрыться за границами
        this.productBatchGridDef = [
            {
                headerName: 'Животные',
                field: 'animalIds',
                editorType: AppGridEditableComponent.COMPONENT,
                isInnerForm: true,
                recalculateDetailHeightAfterChange: true,
                componentType: 2,
                copyToNewRow: true,
            },
            {
                field: 'stableId', hide: true, editorType: AppGridEditableComponent.HIDDEN_FIELD, copyToNewRow: true,
            },
            {
                field: 'isAnimalProductKind', hide: true, editorType: AppGridEditableComponent.HIDDEN_FIELD,
            },
            {
                headerName: 'Номенклатура',
                field: 'stableProductSubtypeId',
                width: 400,
                editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_LOOKUP,
                lookupName: 'stable-product-subtype/stable/false/',
                parentFieldLookup: 'stableId',
                requiredValue2: true,
                required: function () { return EditProductCommonFormComponent.isProductionTransaction(_this.productFormGroup); },
                editable: function () { return true; },
                recalculateDetailHeightAfterChange: true,
                valueChangedCallback: function (newValue, rowData, rowNode, gridApi) {
                    if (newValue && rowData.parent && rowData.parent.parent &&
                        rowData.parent.parent.contains('stableId') && rowData.parent.parent.get('stableId').value) {
                        combineLatest([
                            _this.lookupService.getLookupObj('stable-product-subtype/stable/false/' + rowData.parent.parent.get('stableId').value),
                            _this.lookupService.getLookupObj('product-subtype')
                        ])
                            .subscribe(function (_a) {
                            var lookupItems = _a[0], productSubtypes = _a[1];
                            var sps = lookupItems['Obj' + newValue];
                            rowData.get('isAnimalProductKind').setValue(sps.productKindId === 3);
                            if (!rowData.get('isAnimalProductKind').value && !rowData.get('manufactureDateKind').value) {
                                var manDate = DateHelper.startDay(new Date());
                                rowData.get('manufactureDateKind').setValue(1);
                                rowData.get('manufactureDate').setValue(StringHelper.getISODateWithHourMinute(manDate));
                                rowData.get('expireDateYear').setValue(manDate.getFullYear());
                                rowData.get('expireDateMonth').setValue(manDate.getMonth() + 1);
                                rowData.get('expireDateDay').setValue(manDate.getDate());
                            }
                            if (!rowData.get('isAnimalProductKind').value && !rowData.get('expireDateKind').value) {
                                rowData.get('expireDateKind').setValue(1);
                            }
                            var ps = productSubtypes[sps.productTypeId].find(function (ptst) { return ptst.id === sps.productSubtypeId; });
                            if (ps) {
                                if ((ps.availableManufactureProductDateKindIds || []).length) {
                                    ps.availableManufactureProductDateKindIds.sort();
                                    rowData.get('manufactureDateKind').setValue(ps.availableManufactureProductDateKindIds.length > 1 && !ps.availableManufactureProductDateKindIds[0]
                                        ? ps.availableManufactureProductDateKindIds[1]
                                        : ps.availableManufactureProductDateKindIds[0]);
                                }
                                if ((ps.availableExpireProductDateKindIds || []).length) {
                                    ps.availableExpireProductDateKindIds.sort();
                                    rowData.get('expireDateKind').setValue(ps.availableExpireProductDateKindIds.length > 1 && !ps.availableExpireProductDateKindIds[0]
                                        ? ps.availableExpireProductDateKindIds[1]
                                        : ps.availableExpireProductDateKindIds[0]);
                                }
                            }
                        });
                    }
                    else {
                        rowData.get('isAnimalProductKind').setValue(false);
                    }
                },
            },
            {
                headerName: 'Кол-во',
                field: 'valueCount',
                width: 125,
                editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_INPUT,
                keyPressCallback: FormHelper.processMoneyKeypressDot,
                required: function () { return EditProductCommonFormComponent.isProductionTransaction(_this.productFormGroup); },
                editable: function () { return true; },
            },
            {
                headerName: 'Ед.изм.',
                field: 'unitId',
                width: 125,
                editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_LOOKUP,
                lookupName: 'drug-unit-type/product',
                required: function () { return EditProductCommonFormComponent.isProductionTransaction(_this.productFormGroup); },
                editable: function () { return true; },
            },
            {
                headerName: 'Уровень/кол-во/тип/маркировки упаковки',
                field: 'packages',
                editorType: AppGridEditableComponent.COMPONENT,
                isInnerForm: true,
                recalculateDetailHeightAfterChange: true,
                componentType: 1,
                isVisible: function (data) { return !data.get('isAnimalProductKind').value; },
            },
            {
                headerName: 'Время производства',
                field: 'manufactureDate',
                field2: 'manufactureDate2',
                editorType: AppGridEditableComponent.DATE_COMBO,
                isInnerForm: true,
                recalculateDetailHeightAfterChange: true,
                requiredDayAndHour: function (data) { return EditProductCommonFormComponent.isProductionTransaction(_this.productFormGroup) &&
                    EditProductSourceFormComponent.isRequiredDateField(data); },
                isVisible: function (data) { return !data.get('isAnimalProductKind').value; },
                defValueFunc: function (data) {
                    data.get('manufactureDateKind').setValue(1);
                    var manDate = DateHelper.startDay(new Date());
                    data.get('manufactureDate').setValue(StringHelper.getISODateWithHourMinute(manDate));
                    data.get('manufactureDateYear').setValue(manDate.getFullYear());
                    data.get('manufactureDateMonth').setValue(manDate.getMonth() + 1);
                    data.get('manufactureDateDay').setValue(manDate.getDate());
                },
                valueChangedCallback: function (newValue, rowData, rowNode, gridApi) {
                    if (rowData.get('stableProductSubtypeId').value &&
                        rowData.parent && rowData.parent.parent && rowData.parent.parent.get('stableId').value) {
                        _this.lookupService.getLookupObj('stable-product-subtype/stable/false/' + rowData.parent.parent.get('stableId').value)
                            .subscribe(function (spsLookup) {
                            var sps = spsLookup['Obj' + rowData.get('stableProductSubtypeId').value];
                            if ((sps || {}).calcExpireDate) {
                                var manDate = rowData.get('manufactureDate').value;
                                var calcDate = DateHelper.calcDateByFormula(sps.calcExpireDate, manDate ? new Date(manDate) : undefined);
                                if (calcDate) {
                                    rowData.get('expireDateYear').setValue(calcDate.getFullYear());
                                    rowData.get('expireDateMonth').setValue(calcDate.getMonth() + 1);
                                    rowData.get('expireDateDay').setValue(calcDate.getDate());
                                    rowData.get('expireDate').setValue(StringHelper.getISODateWithHourMinute(calcDate));
                                }
                            }
                        });
                    }
                }
            },
            {
                headerName: 'Срок годности',
                field: 'expireDate',
                field2: 'expireDate2',
                editorType: AppGridEditableComponent.DATE_COMBO,
                isInnerForm: true,
                recalculateDetailHeightAfterChange: true,
                requiredDayAndHour: function (data) { return EditProductCommonFormComponent.isProductionTransaction(_this.productFormGroup) &&
                    EditProductSourceFormComponent.isRequiredDateField(data); },
                isVisible: function (data) { return !data.get('isAnimalProductKind').value; },
                required: function (params) { return true; },
                defValueFunc: function (data) { return data.get('expireDateKind').setValue(1); },
            },
            {
                headerName: 'Срок годности (доп)',
                hideHeader: true,
                field: 'perishable',
                caption: 'Скоропортящаяся продукция',
                editorType: AppGridEditableComponent.CHECKBOX,
                isInnerForm: true,
                isVisible: function (data) { return FormHelper.equalsSome(+data.get('expireDateKind').value, 1, 2, 3) &&
                    !data.get('isAnimalProductKind').value; },
            },
            {
                headerName: 'Номер произв.партии',
                field: 'batchId',
                editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_INPUT,
                isInnerForm: true,
                sizeControl: 'itech-control-larger',
                required: function () { return EditProductCommonFormComponent.isProductionTransaction(_this.productFormGroup); },
            },
        ];
        // если будет изменена высота после изменения содержимого master detail - откорректируй в this::transportMasterDetailCalcHeightFunc,
        // иначе некоторые поля могут скрыться за границами
        this.transportDocsGridDef = [
            {
                headerName: 'Место отправления',
                field: 'stableId',
                width: 150,
                editorType: AppGridEditableComponent.EDITOR_TYPE_MODAL_SEARCH,
                lookupName: 'stable',
                editable: function (rowNode) {
                    if (!rowNode || !rowNode.data || !rowNode.data.parent || !rowNode.data.parent.parent) {
                        return true;
                    }
                    return +rowNode.data.parent.parent.get('transactionType').value !== 3 && !rowNode.data.get('productId').value;
                },
                required: function () { return false; },
                cellClassRules: {
                    'app-ag-grid-no-editable-cell': function (params) { return params && params.data && params.data.parent && params.data.parent.parent &&
                        +params.data.parent.parent.get('transactionType').value === 3 && !params.data.get('productId').value; },
                },
            },
            {
                headerName: 'Документ',
                field: 'productId',
                width: 300,
                editorType: AppGridEditableComponent.EDITOR_TYPE_MODAL_SEARCH,
                lookupName: 'product',
                searchParams: function (rowData) {
                    return {
                        stableId: !EditProductCommonFormComponent.isProductionTransaction(_this.productFormGroup)
                            ? rowData.parent && rowData.parent.parent
                                ? rowData.parent.parent.get('originStableId').value || rowData.get('stableId').value
                                : rowData.get('stableId').value
                            : undefined,
                        availableChangeStableId: true,
                    };
                },
                editable: function () { return true; },
                required: function (params) {
                    return (!params.data.parent || !params.data.parent.parent && !params.data.parent.parent.get('isTemplate').value) &&
                        !EditProductCommonFormComponent.isProductionTransaction(_this.productFormGroup);
                },
                valueChangedCallback: function (newValue, rowData, rowNode, gridApi) {
                    if (newValue && rowData && rowData.parent && rowData.parent.parent) {
                        if (!_this.dataCachingService.getCachedData("getTransportDocumentForCreate_" + newValue, rowData.parent.parent.get('transactionType').value)) {
                            _this.dataCachingService.addToCache("getTransportDocumentForCreate_" + newValue, rowData.parent.parent.get('transactionType').value, new BehaviorSubject({}));
                            _this.transportDocForCreateAccumulator.push(newValue);
                            if (_this.transportDocForCreateTimer) {
                                var tmpTimer = _this.transportDocForCreateTimer;
                                _this.transportDocForCreateTimer = undefined;
                                clearTimeout(tmpTimer);
                            }
                            _this.transportDocForCreateTimer = setTimeout(function () {
                                if (_this.transportDocForCreateAccumulator.length) {
                                    var tmpIds = _this.transportDocForCreateAccumulator;
                                    _this.transportDocForCreateAccumulator = [];
                                    _this.productDataService.getTransportDocumentForCreate2(+rowData.parent.parent.get('transactionType').value, Array.from(new Set(tmpIds))).subscribe(function (records) { return records.forEach(function (val) {
                                        _this.dataCachingService.getCachedData("getTransportDocumentForCreate_" + val.productId, rowData.parent.parent.get('transactionType').value).next(val);
                                    }); });
                                }
                            }, 500);
                        }
                        _this.dataCachingService.getCachedData("getTransportDocumentForCreate_" + newValue, rowData.parent.parent.get('transactionType').value)
                            .subscribe(function (val) {
                            if (+rowData.parent.parent.get('transactionType').value === 1) { // Перевозка со сменой владельца
                                val['destinationAgentId'] = rowData.get('destinationAgentId').value;
                                val['destinationStableId'] = rowData.get('destinationStableId').value;
                            }
                            else if (+rowData.parent.parent.get('transactionType').value === 2) { // Перевозка без смены владельца
                                val['destinationStableId'] = rowData.get('destinationStableId').value;
                            }
                            else if (+rowData.parent.parent.get('transactionType').value === 3) { // Смена владельца без перевозки
                                val['destinationAgentId'] = rowData.get('destinationAgentId').value;
                            }
                            val['valueCount'] = rowData.get('valueCount').value || val['valueCount'];
                            val['locationProsperity'] = rowData.get('locationProsperity').value || val['locationProsperity'];
                            val['productReasonId'] = rowData.get('productReasonId').value || val['productReasonId'];
                            var stableProductSubtypeId = rowData.get('stableProductSubtypeId').value || val['stableProductSubtypeId'];
                            var stableProductSubtypeIdSource = rowData.get('stableProductSubtypeIdSource').value || val['stableProductSubtypeId'];
                            if (val['stableId'] && stableProductSubtypeId && stableProductSubtypeIdSource) {
                                setTimeout(function () {
                                    _this.lookupService.getLookup('stable-product-subtype/stable/with-bonus/true/' +
                                        val['stableId'] + '/' + stableProductSubtypeIdSource).subscribe(function (items) {
                                        var stableProductSubtype = (items || []).find(function (el) { return el.id === +stableProductSubtypeId; });
                                        val['productTypeId'] = stableProductSubtype ? stableProductSubtype.productTypeId : undefined;
                                        rowData.patchValue(EditProductCommonFormComponent.buildTransportDocumentsGroupDefs(_this.fb, val).value);
                                        while (rowData.get('packages').value.length) {
                                            rowData.get('packages').removeAt(0);
                                        }
                                        (val.packages || []).concat([{}]).forEach(function (pack) { return rowData.get('packages')
                                            .push(EditProductCommonFormComponent.buildPackage(_this.fb, pack, true)); });
                                        rowData.get('loaded').setValue(true);
                                        gridApi.refreshCells({ force: true });
                                    });
                                }, 700);
                            }
                        });
                        EditProductSourceFormComponent.runBackgroundUpdateProductFromMercury(newValue, _this.fb, _this.productDataService, _this.dataCachingService);
                    }
                },
            },
            {
                field: 'stableId', hide: true, editorType: AppGridEditableComponent.HIDDEN_FIELD, copyToNewRow: true,
            },
            {
                headerName: 'Кол-во',
                field: 'valueCount',
                width: 75,
                editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_INPUT,
                keyPressCallback: FormHelper.processMoneyKeypressDot,
                required: function (params) {
                    return (!params.data.parent || !params.data.parent.parent && !params.data.parent.parent.get('isTemplate').value) &&
                        !EditProductCommonFormComponent.isProductionTransaction(_this.productFormGroup) &&
                        params.data.get('productId').value;
                },
                editable: function () { return true; },
            },
            {
                headerName: 'Получатель',
                field: 'destinationAgentId',
                width: 180,
                editorType: AppGridEditableComponent.EDITOR_TYPE_MODAL_SEARCH,
                lookupName: 'agent',
                editable: function (rowNode) {
                    if (!rowNode || !rowNode.data || !rowNode.data.parent || !rowNode.data.parent.parent) {
                        return true;
                    }
                    return +rowNode.data.parent.parent.get('transactionType').value !== 2;
                },
                required: function (params) {
                    return (!params.data.parent || !params.data.parent.parent && !params.data.parent.parent.get('isTemplate').value) &&
                        !EditProductCommonFormComponent.isProductionTransaction(_this.productFormGroup) &&
                        params.data.get('productId').value;
                },
                searchParams: function (rowData) {
                    return {
                        stableId: !EditProductCommonFormComponent.isProductionTransaction(_this.productFormGroup)
                            ? rowData.get('destinationStableId').value
                            : undefined,
                        availableChangeStableId: true,
                        forceAutoStart: !EditProductCommonFormComponent.isProductionTransaction(_this.productFormGroup)
                            ? !!rowData.get('destinationStableId').value
                            : undefined,
                    };
                },
                callbackAfterChangeValue: function (params) {
                    if (params.data.get('destinationAgentId').value && !params.data.get('destinationStableId').value) {
                        setTimeout(function () {
                            return params.api.startEditingCell({ rowIndex: params.rowIndex, colKey: 'destinationStableId' });
                        }, 500);
                    }
                },
                cellClassRules: {
                    'app-ag-grid-no-editable-cell': function (params) { return params && params.data && params.data.parent && params.data.parent.parent &&
                        +params.data.parent.parent.get('transactionType').value === 2; },
                },
            },
            {
                headerName: 'Место получения',
                field: 'destinationStableId',
                width: 180,
                editorType: AppGridEditableComponent.EDITOR_TYPE_MODAL_SEARCH,
                lookupName: 'stable',
                editable: function (rowNode) {
                    if (!rowNode || !rowNode.data || !rowNode.data.parent || !rowNode.data.parent.parent) {
                        return true;
                    }
                    return +rowNode.data.parent.parent.get('transactionType').value !== 3;
                },
                required: function (params) {
                    return (!params.data.parent || !params.data.parent.parent && !params.data.parent.parent.get('isTemplate').value) &&
                        !EditProductCommonFormComponent.isProductionTransaction(_this.productFormGroup) &&
                        params.data.get('productId').value;
                },
                searchParams: function (rowData) {
                    return {
                        agentId: !EditProductCommonFormComponent.isProductionTransaction(_this.productFormGroup)
                            ? rowData.get('destinationAgentId').value
                            : undefined,
                        availableChangeAgentId: true,
                        forceAutoStart: !EditProductCommonFormComponent.isProductionTransaction(_this.productFormGroup)
                            ? !!rowData.get('destinationAgentId').value
                            : undefined,
                    };
                },
                callbackAfterChangeValue: function (params) {
                    if (params.data.get('destinationStableId').value && !params.data.get('destinationAgentId').value) {
                        setTimeout(function () {
                            return params.api.startEditingCell({ rowIndex: params.rowIndex, colKey: 'destinationAgentId' });
                        }, 500);
                    }
                },
                cellClassRules: {
                    'app-ag-grid-no-editable-cell': function (params) { return params && params.data && params.data.parent && params.data.parent.parent &&
                        +params.data.parent.parent.get('transactionType').value === 3; },
                },
            },
            {
                headerName: 'Номенклатура',
                field: 'stableProductSubtypeId',
                editorType: AppGridEditableComponent.APP_COMBO_LOOKUP,
                lookupName: 'stable-product-subtype/stable/with-bonus/true/',
                parentFieldLookup: 'stableId',
                parentField2Lookup: 'stableProductSubtypeIdSource',
                isInnerForm: true,
                required: function (params) {
                    return (!params.data.parent || !params.data.parent.parent && !params.data.parent.parent.get('isTemplate').value) &&
                        !EditProductCommonFormComponent.isProductionTransaction(_this.productFormGroup) &&
                        params.data.get('productId').value;
                },
            },
            {
                field: 'stableProductSubtypeIdSource', hide: true, editorType: AppGridEditableComponent.HIDDEN_FIELD, copyToNewRow: true,
            },
            {
                headerName: 'Уровень/кол-во/тип/маркировки упаковки',
                field: 'packages',
                editorType: AppGridEditableComponent.COMPONENT,
                isInnerForm: true,
                recalculateDetailHeightAfterChange: true,
                componentType: 1,
            },
            {
                headerName: 'Серия/номер защ. бланка',
                field: 'protectedDocSerial',
                editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_INPUT,
                isInnerForm: true,
                sizeControl: 'itech-control-small',
            },
            {
                headerName: 'Серия/номер защ. бланка',
                field: 'protectedDocNo',
                editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_INPUT,
                isInnerForm: true,
                sizeControl: 'itech-control-small',
            },
            {
                headerName: 'Вет.сан. экспертиза',
                field: 'expertiseStatus',
                editorType: AppGridEditableComponent.APP_COMBO_LOOKUP,
                lookupName: 'product-expertise-status',
                isInnerForm: true,
                required: function (params) { return true; },
            },
            {
                headerName: 'Вет.сан. экспертиза',
                field: 'cargoInspected',
                caption: 'Контроль гос. ветврачом',
                editorType: AppGridEditableComponent.CHECKBOX,
                isInnerForm: true,
            },
            {
                headerName: 'Благополучие местности',
                field: 'locationProsperity',
                editorType: AppGridEditableComponent.TEXT_AREA,
                isInnerForm: true,
                required: function (params) { return true; },
            },
            {
                headerName: 'Нахождение в таможенной зоне',
                field: 'animalSpentPeriodCode',
                editorType: AppGridEditableComponent.APP_COMBO_LOOKUP,
                lookupName: 'animal-spent-period',
                isInnerForm: true,
            },
            {
                headerName: 'Нахождение в таможенной зоне',
                hideHeader: true,
                field: 'monthsSpent',
                placeholder: 'мес',
                editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_INPUT,
                isInnerForm: true,
                sizeControl: 'itech-control-small',
                isVisible: function (data) { return data.get('animalSpentPeriodCode').value === 'IN_MONTHS'; },
            },
            {
                headerName: 'Карантин',
                field: 'quarantineDuration',
                placeholder: 'Дни',
                editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_INPUT,
                onlyDigits: true,
                isInnerForm: true,
                sizeControl: 'itech-control-small',
            },
            {
                headerName: 'Карантин',
                placeholder: 'Информация',
                field: 'quarantineComments',
                editorType: AppGridEditableComponent.TEXT_AREA,
                isInnerForm: true,
                isVisible: function (data) { return +data.get('quarantineDuration').value > 0; },
            },
            {
                headerName: 'Некачественная продукция',
                hideHeader: true,
                field: 'badQuality',
                caption: 'Некачественная продукция',
                editorType: AppGridEditableComponent.CHECKBOX,
                isInnerForm: true,
            },
            {
                headerName: 'Цель производства/сбыта',
                field: 'productReasonId',
                editorType: AppGridEditableComponent.APP_COMBO_LOOKUP,
                lookupName: 'product-reason',
                filterArrayControlName: 'mercuryGuid',
                filterArray: function (data) { return +data.get('productTypeId').value === 609
                    ? ['8670b7f0-5274-4d68-02a1-30262a34402f', '98ecd86b-01ff-e3b0-28ed-018fc16a0af0', '0bd9d889-66c3-eeca-c580-78687dd9aea0',
                        'b208516c-437d-a5c0-791d-b9096f67bd69', 'cc6ed069-340b-c83e-78aa-0ef09bb61af6'] : []; },
                isInnerForm: true,
                sizeControl: 'itech-control-xlarge',
                required: function (params) { return true; },
            },
            {
                headerName: 'Комментарии',
                field: 'comments',
                editorType: AppGridEditableComponent.TEXT_AREA,
                isInnerForm: true,
            },
            {
                headerName: 'Другой ТТН',
                hideHeader: true,
                field: 'otherTtn',
                caption: 'Другой ТТН',
                editorType: AppGridEditableComponent.CHECKBOX,
                recalculateDetailHeightAfterChange: true,
                isInnerForm: true,
            },
            {
                headerName: 'Вид тов.-трансп. накладной',
                field: 'ttnTypeId',
                editorType: AppGridEditableComponent.APP_COMBO_LOOKUP,
                lookupName: 'ttn-type',
                recalculateDetailHeightAfterChange: true,
                isVisible: function (data) { return data.get('otherTtn').value; },
                isInnerForm: true,
                sizeControl: 'itech-control-large',
            },
            {
                headerName: 'Серия номер и дата ТТН',
                field: 'ttnSerial',
                editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_INPUT,
                isInnerForm: true,
                isVisible: function (data) { return data.get('ttnTypeId').value; },
                sizeControl: 'itech-control-small',
            },
            {
                headerName: 'Серия номер и дата ТТН',
                field: 'ttnNo',
                editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_INPUT,
                isInnerForm: true,
                isVisible: function (data) { return data.get('ttnTypeId').value; },
                sizeControl: 'itech-control-small',
            },
            {
                headerName: 'Серия номер и дата ТТН',
                field: 'ttnDate',
                editorType: AppGridEditableComponent.DATE_PICKER,
                withTime: false,
                isInnerForm: true,
                isVisible: function (data) { return data.get('ttnTypeId').value; },
                sizeControl: 'itech-control-normal',
            },
        ];
    }
    Object.defineProperty(EditProductSourceFormComponent.prototype, "productId", {
        get: function () {
            return this.productFormGroup.get('id').value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditProductSourceFormComponent.prototype, "isDraft", {
        get: function () {
            return !this.productId || !this.productFormGroup.get('status').value || +this.productFormGroup.get('status').value === -100;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditProductSourceFormComponent.prototype, "productTypeId", {
        get: function () {
            return this.contextFormGroup.get('productTypeId');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditProductSourceFormComponent.prototype, "batchIds", {
        get: function () {
            return this.contextFormGroup.get('batchId');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditProductSourceFormComponent.prototype, "isProductionTransaction", {
        get: function () {
            return EditProductCommonFormComponent.isProductionTransaction(this.productFormGroup);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditProductSourceFormComponent.prototype, "isTransportOrMovingTransaction", {
        get: function () {
            return EditProductCommonFormComponent.isTransportOrMovingTransaction(this.productFormGroup);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditProductSourceFormComponent.prototype, "packages", {
        get: function () {
            return this.contextFormGroup.get('packages');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditProductSourceFormComponent.prototype, "manyProducts", {
        get: function () {
            return this.productFormGroup.get('manyProducts');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditProductSourceFormComponent.prototype, "transportDocuments", {
        get: function () {
            return this.productFormGroup.get('transportDocuments');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditProductSourceFormComponent.prototype, "lookupNameStableProductSubtype", {
        get: function () {
            var stableId = this.productFormGroup.get('producers').value.length
                ? this.productFormGroup.get('producers').value[0].stableId
                : this.isProductionTransaction
                    ? this.productFormGroup.get('originStableId').value
                    : undefined;
            var sps = this.contextFormGroup.get('stableProductSubtypeId').value;
            return stableId
                ? "stable-product-subtype/stable/include-unknown/" + stableId + "/" + (sps ? sps : 'null')
                : '';
        },
        enumerable: true,
        configurable: true
    });
    EditProductSourceFormComponent.isRequiredDateField = function (formGroup) {
        return formGroup.get('perishable').value;
    };
    EditProductSourceFormComponent.runBackgroundUpdateProductFromMercury = function (newValue, fb, productDataService, dataCachingService) {
        var _this = this;
        this.productIdsUpdateFromMercuryAccumulator.push(newValue);
        if (this.productIdsUpdateFromMercuryTimer) {
            var tmpTimer = this.productIdsUpdateFromMercuryTimer;
            this.productIdsUpdateFromMercuryTimer = undefined;
            clearTimeout(tmpTimer);
        }
        this.productIdsUpdateFromMercuryTimer = setTimeout(function () {
            if (_this.productIdsUpdateFromMercuryAccumulator.length) {
                var tmpIds_1 = _this.productIdsUpdateFromMercuryAccumulator;
                _this.productIdsUpdateFromMercuryAccumulator = [];
                productDataService.backgroundUpdateProductFromMercury2(tmpIds_1).subscribe(function (records) {
                    records.forEach(function (val) {
                        var existing = dataCachingService.getCachedData('EditProductCommon', val.id.toString()) ||
                            EditProductCommonComponent.getDefaultModel(val.id);
                        existing.contextFormGroup = EditProductCommonFormComponent.createFormGroup(fb, existing, val);
                        dataCachingService.addToCache('EditProductCommon', val.id.toString(), existing);
                        dataCachingService.addToCache('EditProductCommon_manyProductCache', val.id.toString(), val);
                    });
                    productDataService.getProductCommonForLookup2(tmpIds_1).subscribe(function (records2) { return records2.forEach(function (val2) {
                        return EditProductSourceFormComponent.updateCacheProduct(val2.id, val2, dataCachingService);
                    }); });
                });
            }
        }, 500);
    };
    EditProductSourceFormComponent.updateCacheProduct = function (productId, newValue, dataCachingService) {
        var cacheProduct = new ReplaySubject();
        dataCachingService.addToCache('AddressPersonFioCacheService_Product', productId.toString(10), cacheProduct);
        cacheProduct.next(newValue);
    };
    EditProductSourceFormComponent.runBackgroundUpdateStableProductSubtypeFromMercury = function (stableId, stableDataService, lookupService) {
        var _this = this;
        this.stableProductSubtypeStableIdsUpdateFromMercuryAccumulator.push(stableId);
        if (this.stableProductSubtypeStableIdsUpdateFromMercuryTimer) {
            var tmpTimer = this.stableProductSubtypeStableIdsUpdateFromMercuryTimer;
            this.stableProductSubtypeStableIdsUpdateFromMercuryTimer = undefined;
            clearTimeout(tmpTimer);
        }
        this.stableProductSubtypeStableIdsUpdateFromMercuryTimer = setTimeout(function () {
            if (_this.stableProductSubtypeStableIdsUpdateFromMercuryAccumulator.length) {
                var tmpIds = _this.stableProductSubtypeStableIdsUpdateFromMercuryAccumulator;
                _this.stableProductSubtypeStableIdsUpdateFromMercuryAccumulator = [];
                stableDataService.backgroundUpdateStableProductSubtype(tmpIds)
                    .subscribe(function (records) {
                    records.forEach(function (val) {
                        lookupService.invalidateLookup('stable-product-subtype/stable/false/' + val);
                    });
                });
            }
        }, 500);
    };
    EditProductSourceFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.changeProductTypeId();
        this.changeTransactionTypeEmitter.subscribe(function () {
            _this.forceUpdateTransportTableDataEmitter.emit();
            _this.changeProductTypeId();
        });
    };
    Object.defineProperty(EditProductSourceFormComponent.prototype, "sourceAnimalIds", {
        get: function () {
            return this.contextFormGroup.get('sourceAnimalIds');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditProductSourceFormComponent.prototype, "sourceProductIds", {
        get: function () {
            return this.contextFormGroup.get('sourceProductIds');
        },
        enumerable: true,
        configurable: true
    });
    EditProductSourceFormComponent.prototype.searchAgent = function () {
        var _this = this;
        this.navigationService.searchAgent(AgentSearchModalComponent, {
            stableId: this.contextFormGroup.get('destinationStableId').value,
        }).subscribe(function (val) {
            if (val) {
                _this.contextFormGroup.get('destinationAgentId').setValue(val);
                _this.contextFormGroup.markAsDirty();
            }
        });
    };
    EditProductSourceFormComponent.prototype.searchStableByAgent = function (agentId) {
        var _this = this;
        this.cacheService.getAgent(agentId).subscribe(function (agent) {
            return _this.searchStable({ owner: agent.id ? agent.caption : '', agentId: agent.id })
                .subscribe(function (val) {
                if (val) {
                    _this.contextFormGroup.get('destinationStableId').setValue(val);
                    _this.contextFormGroup.markAsDirty();
                }
            });
        });
    };
    EditProductSourceFormComponent.prototype.searchStable = function (params) {
        if (params === void 0) { params = {}; }
        return this.navigationService.searchStable(StableSearchModalComponent, params);
    };
    EditProductSourceFormComponent.prototype.changeProductTypeId = function () {
        var _this = this;
        if (!this.productTypeId.value || !this.isTransportOrMovingTransaction) {
            this.isHideDates = false;
            return;
        }
        this.lookupService.getLookupObj('product-type/' + this.productTypeId.value)
            .subscribe(function (value) { return _this.isHideDates = !_this.productId && value.kind === 3; });
    };
    EditProductSourceFormComponent.prototype.addNewTransaction = function () {
        var _this = this;
        this.searchStable()
            .subscribe(function (val) {
            if (val) {
                _this.manyProducts.push(EditProductCommonFormComponent.buildManyProductsGroupDefs(_this.fb, { stableId: val }));
                EditProductSourceFormComponent.runBackgroundUpdateStableProductSubtypeFromMercury(val, _this.stableDataService, _this.lookupService);
            }
        });
    };
    EditProductSourceFormComponent.prototype.removeManyProduct = function (index) {
        var _this = this;
        this.alertService.confirmModal('Удалить животное со всеми вырабатываемыми продукциями?')
            .subscribe(function (val) {
            if (val) {
                _this.manyProducts.removeAt(index);
            }
        });
    };
    EditProductSourceFormComponent.prototype.copyManyProductForNewStable = function (manyProduct) {
        var _this = this;
        this.searchStable()
            .subscribe(function (val) {
            if (val) {
                var data = Object.assign({}, manyProduct.value);
                data.producedBatches = manyProduct.get('producedBatches').value;
                data.sourceBatches = manyProduct.get('sourceBatches').value;
                if (+data.stableId !== val) {
                    data.stableId = val;
                    data.producedBatches.forEach(function (p) {
                        p.stableId = val;
                        p.stableProductSubtypeId = null;
                    });
                }
                else {
                    data.producedBatches.forEach(function (p) { return p.stableId = val; });
                }
                _this.manyProducts.push(EditProductCommonFormComponent.buildManyProductsGroupDefs(_this.fb, data));
            }
        });
    };
    EditProductSourceFormComponent.prototype.masterDetailCalcHeightFunc = function (node) {
        var packHeight = (node.data.get('packages').value || [])
            .map(function (x) { return 37 + (x.expandedMark ? 37 * x.markings.length : 0); })
            .reduce(function (partialSum, a) { return partialSum + a; }, 0);
        var manufactureDateHeight = 72 + (+node.data.get('manufactureDateKind').value === 3 ? 36 : 0);
        var expireDateHeight = 72 + (+node.data.get('expireDateKind').value === 3 ? 36 : 0);
        var animalsHeight = node.data.get('animalIds').value
            ? (node.data.get('animalIds').value.length + 1) * 17
            : 0;
        return node && node.data
            ? node.data.get('isAnimalProductKind').value
                ? 65 + animalsHeight
                : 100 + packHeight + manufactureDateHeight + expireDateHeight + animalsHeight
            : 0;
    };
    EditProductSourceFormComponent.prototype.transportMasterDetailCalcHeightFunc = function (node) {
        var packHeight = (node.data.get('packages').value || [])
            .map(function (x) { return 37 + (x.expandedMark ? 37 * x.markings.length : 0); })
            .reduce(function (partialSum, a) { return partialSum + a; }, 0);
        var isShowQuarantineInfo = node.data.get('animalSpentPeriodCode').value === 'IN_MONTHS';
        var otherTtn = node.data.get('otherTtn').value;
        var ttnTypeId = node.data.get('ttnTypeId').value;
        return 5 + (packHeight || 0) + (isShowQuarantineInfo ? 37 : 0) + (otherTtn ? +ttnTypeId ? 100 : 60 : 17) + 343;
    };
    EditProductSourceFormComponent.prototype.isRequiredDateField = function (formGroup) {
        return EditProductSourceFormComponent.isRequiredDateField(formGroup);
    };
    EditProductSourceFormComponent.prototype.availableRowMasterDetailTransport = function (node) {
        if (!node.data.get('loaded').value) {
            return undefined;
        }
        return node.data.get('productId').value;
    };
    EditProductSourceFormComponent.prototype.transportGridReady = function (event) {
        if (!this.productFormGroup.get('fromTemplate').value) {
            this.forceUpdateTransportTableDataEmitter.emit();
        }
    };
    EditProductSourceFormComponent.prototype.isNeedMainTtn = function () {
        var find = this.productFormGroup.get('transportDocuments').value.find(function (doc) { return !doc.otherTtn; });
        if (!find) {
            this.contextFormGroup.get('needMainTtn').setValue(false);
        }
        else {
            this.contextFormGroup.get('needMainTtn').setValue(true);
        }
    };
    EditProductSourceFormComponent.productIdsUpdateFromMercuryAccumulator = [];
    EditProductSourceFormComponent.productIdsUpdateFromMercuryTimer = undefined;
    EditProductSourceFormComponent.stableProductSubtypeStableIdsUpdateFromMercuryAccumulator = [];
    EditProductSourceFormComponent.stableProductSubtypeStableIdsUpdateFromMercuryTimer = undefined;
    return EditProductSourceFormComponent;
}());
export { EditProductSourceFormComponent };
