/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./animal-search-results-form.component.ngfactory";
import * as i2 from "./animal-search-results-form.component";
import * as i3 from "../../../logic/services/animal-data.service";
import * as i4 from "../../../ui/infrastructure/global-waiting-overlay.service";
import * as i5 from "../../../logic/services/app-navigation.service";
import * as i6 from "../../../logic/services/lookup-source.service";
import * as i7 from "../../../logic/services/security.service";
import * as i8 from "@clr/angular";
import * as i9 from "./animal-search-results.component";
import * as i10 from "@angular/router";
import * as i11 from "../../../logic/services/data-caching.service";
import * as i12 from "@angular/forms";
var styles_AnimalSearchResultsComponent = [];
var RenderType_AnimalSearchResultsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AnimalSearchResultsComponent, data: {} });
export { RenderType_AnimalSearchResultsComponent as RenderType_AnimalSearchResultsComponent };
export function View_AnimalSearchResultsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "itech-common-wrap no-pager"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-animal-search-results-form", [], null, [[null, "rowClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("rowClicked" === en)) {
        var pd_0 = (_co.rowClickedCallback($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_AnimalSearchResultsFormComponent_0, i1.RenderType_AnimalSearchResultsFormComponent)), i0.ɵdid(3, 573440, null, 0, i2.AnimalSearchResultsFormComponent, [i3.AnimalDataService, i4.GlobalWaitingOverlayService, i5.AppNavigationService, i6.LookupSourceService, i7.SecurityService], { model: [0, "model"] }, { rowClicked: "rowClicked" }), (_l()(), i0.ɵeld(4, 0, null, null, 4, "div", [["class", "itech-form-actions"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 3, "button", [["class", "btn btn-link btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.appNavigationService.performAnimalCreation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "clr-icon", [["class", "has-badge"], ["shape", "piggy-bank"]], null, null, null, null, null)), i0.ɵdid(7, 16384, null, 0, i8.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, ["\u00A0\u0417\u0430\u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u043E\u0432\u0430\u0442\u044C \u043D\u043E\u0432\u043E\u0435 \u0436\u0438\u0432\u043E\u0442\u043D\u043E\u0435 "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_AnimalSearchResultsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-animal-search-results", [], null, null, null, View_AnimalSearchResultsComponent_0, RenderType_AnimalSearchResultsComponent)), i0.ɵdid(1, 114688, null, 0, i9.AnimalSearchResultsComponent, [i10.ActivatedRoute, i11.DataCachingService, i5.AppNavigationService, i12.FormBuilder], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AnimalSearchResultsComponentNgFactory = i0.ɵccf("app-animal-search-results", i9.AnimalSearchResultsComponent, View_AnimalSearchResultsComponent_Host_0, {}, {}, []);
export { AnimalSearchResultsComponentNgFactory as AnimalSearchResultsComponentNgFactory };
