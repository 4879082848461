import { Component, ElementRef, ViewChild } from '@angular/core';
import { ICellEditorAngularComp} from '@ag-grid-community/angular';
import { FormGroup } from '@angular/forms';
import { FormHelper } from './form-helper';

@Component({
  selector: 'app-ag-grid-text-input-editor',
  template: `
      <label for="{{controlName}}+{{rndToken}}" aria-haspopup="true" role="tooltip" [formGroup]="formGroup"
             class="app-ag-grid-text-input-editor">
          <input #inputField type="text" class="app-ag-grid-text-input" id="{{controlName}}+{{rndToken}}"
                 [formControlName]="controlName" (keypress)="keyPressCallback($event)">
      </label>
  `,
})
export class AgGridTextInputEditorComponent implements ICellEditorAngularComp {

  public params: any;
  public formGroup: FormGroup;
  public controlName: string;
  public keyPressCallback;

  @ViewChild('inputField') inputField: ElementRef<HTMLInputElement>;
  rndToken = Math.floor(Math.random() * 1000000);
  isInvalid = FormHelper.isInvalid;

  agInit(params: any): void {
    this.params = params;
    if (params.charPress) {
      this.params.data.get(this.params.controlName).setValue(params.charPress);
    }
    this.formGroup = this.params.data;
    this.controlName = this.params.controlName;
    this.keyPressCallback = this.params.keyPressCallback || (event => {});
    setTimeout(() => {
      this.inputField.nativeElement.focus();
      if (!params.charPress) {
        this.inputField.nativeElement.setSelectionRange(0, (this.params.data.get(this.params.controlName).value || '').toString().length);
      }
    }, 0);
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  getValue(): any {
    return this.formGroup.get(this.controlName).value;
  }
}
