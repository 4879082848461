import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalWaitingOverlayService } from '../../ui/infrastructure/global-waiting-overlay.service';
import { DataCachingService } from '../../logic/services/data-caching.service';
import { AppNavigationService } from '../../logic/services/app-navigation.service';
import { InstitutionDataService } from '../../logic/services/institution-data.service';
import { StringHelper } from '../../helpers/string-helper';
import { LookupSourceService } from '../../logic/services/lookup-source.service';
import { AgGridLookupRendererComponent } from '../../ui/controls/ag-grid-lookup-renderer.component';

@Component({
  selector: 'app-institution-search-results',
  templateUrl: './institution-search-results.component.html'
})
export class InstitutionSearchResultsComponent implements OnInit {

  _searchResults: any[] = [];

  frameworkComponents = {
    lookupRenderer: AgGridLookupRendererComponent,
  };

  gridColumnDefs = [
    {
      headerName: '№', field: 'id', width: 90,
    },
    {
      headerName: 'Наименование', field: 'shortCaption', width: 370,
    },
    {
      headerName: 'Орг. форма',
      field: 'legalFormId',
      cellRenderer: 'lookupRenderer',
      cellRendererParams: (row) => {
        return {
          value: row.value,
          lookupName: 'legal-form'
        };
      },
      filterParams: {
        cellRenderer: 'lookupRenderer',
        cellRendererParams: (row) => {
          return {
            value: row.value,
            lookupName: 'legal-form'
          };
        },
      },
      width: 180
    },
    {
      headerName: 'Вид учреждения',
      field: 'institutionTypeId',
      cellRenderer: 'lookupRenderer',
      cellRendererParams: (row) => {
        return {
          value: row.value,
          lookupName: 'institution-type'
        };
      },
      filterParams: {
        cellRenderer: 'lookupRenderer',
        cellRendererParams: (row) => {
          return {
            value: row.value,
            lookupName: 'institution-type'
          };
        },
      },
      width: 350
    },
    {
      headerName: 'Адрес', field: 'address', width: 350,
    },
  ];

  constructor(private route: ActivatedRoute,
              private institutionDataService: InstitutionDataService,
              private globalWaitingOverlayService: GlobalWaitingOverlayService,
              private dataCachingService: DataCachingService,
              public appNavigationService: AppNavigationService) {}

  refreshResults() {
    this.globalWaitingOverlayService.StartWaiting();

    this.institutionDataService.search()
      .subscribe(data => {
        this._searchResults = data;
        this.dataCachingService.addToCache('InstitutionSearchResults', '1', this._searchResults);
        this.globalWaitingOverlayService.EndWaiting();
      }, error => {
        this.globalWaitingOverlayService.EndWaiting();
      });
  }

  ngOnInit() {

    this.route.paramMap
      .subscribe(params => {

        this._searchResults = [];
        const existing = this.dataCachingService.getCachedData('InstitutionSearchResults', '1');

        if (existing) {
          this._searchResults = existing;
        } else {
          this.refreshResults();
        }
      });
  }

}
