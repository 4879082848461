<div class="query-manager-details">
  <h1>
    Редактирование общих параметров выборки
  </h1>

  <div class="itech-form-actions">
    <div>
      <button [attr.disabled]="model.query.data.id? undefined: true" class="btn btn-warning-outline"
              (click)="deleteQuery()">
        <clr-icon shape="trash"></clr-icon> Удалить
      </button>

      <button class="btn btn-primary-outline" (click)="appNavigationService.navigateJournal(undefined, 20, model.query.data.id, undefined)">
        <clr-icon shape="view-list"></clr-icon>&nbsp;Журнал
      </button>
    </div>

    <div>
      <label for="favorite"></label>
      <div class="checkbox">
        <input type="checkbox" id="favorite" [(ngModel)]="favorite" [ngModelOptions]="{standalone: true}">
        <label for="favorite">Добавить в избранное</label>
      </div>
    </div>

    <div>
      <button [clrLoading]="storing" class="btn btn-primary itech-form-save"
              [disabled]="!contextFormGroup.dirty || storing"
              (click)="store()">
        <clr-icon shape="check"></clr-icon>&nbsp;Сохранить
      </button>
      <button class="btn btn-warning itech-form-cancel" [disabled]="!contextFormGroup.dirty"
              (click)="cancelEdit()">
        <clr-icon shape="undo"></clr-icon>&nbsp;Отменить
      </button>
    </div>
  </div>

  <div class="form compact" [formGroup]="contextFormGroup">
    <section class="form-block itech-block-normal">

      <div class="form-group">
        <label for="caption" class="required">Наименование</label>
        <label for="caption" aria-haspopup="true" role="tooltip"
               class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
               [class.invalid]="isInvalid(contextFormGroup, 'caption')">
          <input type="text" id="caption" class="itech-control-m-large" required
                 formControlName="caption">
          <app-validation-tooltip [input]="contextFormGroup.controls['caption']"></app-validation-tooltip>
        </label>
      </div>

      <div class="form-group">
        <label for="person-group-visibility" class="required">Уровень доступности</label>
        <app-combo-lookup class="itech-control-large"
                          [contextControlId]="'person-group-visibility'"
                          [contextControlName]="'personGroupVisibility'"
                          [lookupName]="'person-group-visibility'"
                          [contextFormGroup]="contextFormGroup"></app-combo-lookup>
      </div>

      <div class="form-group itech-group-whole-line">
        <label for="showOnlyCount"></label>
        <div class="checkbox">
          <input type="checkbox" id="showOnlyCount" formControlName="showOnlyCount">
          <label for="showOnlyCount">Показывать количество записей вместо записей</label>
        </div>
      </div>

    </section>
  </div>

</div>
