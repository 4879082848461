<div class="itech-common-wrap" id="interceptNoAccess">
  <div>
    <div class="main-container" style="max-height: 100%;">
      <div class="content-container">
        <nav class="sidenav">
          <section class="sidenav-content">
            <section *ngFor="let groupBranchMessage of allMessages | keyvalue; index as i" class="nav-group collapsible">
              <ng-container *ngIf="groupBranchMessage.value">
                <input id="tabGroupMessage{{i}}" type="checkbox">
                <label class="icon-before-text" for="tabGroupMessage{{i}}" style="font-weight: bold">
                  {{+groupBranchMessage.key | lookup: 'institution-branch' + id | async}}
                </label>
                <ul *ngFor="let groupAgentMessage of getGroupData(groupBranchMessage.value) | keyvalue; index as j" class="nav-list">
                  <li>
                    <input id="tabGroupMessage{{i}}{{j}}" type="checkbox">
                    <label class="icon-before-text" for="tabGroupMessage{{i}}{{j}}">
                      {{+groupAgentMessage.key | lookup: 'agent' | async}}
                    </label>
                    <ul *ngFor="let messageType of getGroupData(groupAgentMessage.value) | keyvalue" class="nav-list">
                      <li>
                        <a class="nav-link"
                           [class.active]="selectedMessages && selectedMessages.agentId && selectedMessages.agentMessageTypeId &&
                                       +groupAgentMessage.key === selectedMessages.agentId && +messageType.key === selectedMessages.agentMessageTypeId"
                           (click)="changeSelectedMessages(+groupBranchMessage.key, +groupAgentMessage.key, +messageType.key, messageType.value.messages)">
                            <span class="badge badge-info" *ngIf="+messageType.value.countNotReading">
                              {{+messageType.value.countNotReading}}
                            </span>
                          {{+messageType.key | lookup: 'agent-message-type' | async}}
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </ng-container>
            </section>
          </section>
        </nav>
        <div class="content-area">
          <ng-container *ngIf="selectedMessages && selectedMessages.messages; else noSelectedMessages">
            <app-edit-institution-agent-message-form [messages]="selectedMessages.messages"
                                                     style="display:flex;height:calc(100% - 50px);">
            </app-edit-institution-agent-message-form>
            <div class="form-group itech-group-whole-line" style="max-height:50px;display:flex">
              <label for="newMessageText"></label>
              <textarea type="text" id="newMessageText" placeholder="Введите сообщение..."
                        style="resize:none;width:calc(100% - 150px)" (keydown)="keyPressMessage($event)"
                        [disabled]="storing" [(ngModel)]="newMessageText" [ngModelOptions]="{standalone: true}">
              </textarea>
              <button [clrLoading]="storing" class="btn btn-primary btn-link"
                      style="height:40px;width:150px;margin-left:10px;"
                      [disabled]="!newMessageText || storing"
                      (click)="store()">
                <clr-icon shape="arrow right"></clr-icon>&nbsp;Отправить
              </button>
            </div>
          </ng-container>
          <ng-template #noSelectedMessages>
            <div style="min-height:100%;display:flex;justify-content:center;align-items:center;">Не выбрано обращение</div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <div class="itech-form-actions">
  </div>
</div>
