<div class="itech-common-wrap no-pager">
  <div class="itech-v-header-content">
    <div class="itech-content-header">
      <clr-icon shape="file-group" size="36"></clr-icon>&nbsp;Болезни животных
    </div>

    <div style="padding: 5px; height: 90%">
      <app-grid [uniqueGridName]="getMetaName()" [columnDefs]="gridColumnDefs" [exportFileName]="getMetaTitle()"
                [rowData]="_searchResults" (rowClicked)="editRow($event.data)" [getRowStyle]="getRowStyle"></app-grid>
    </div>
  </div>
  <div class="itech-form-actions">
    <div>
      <button class="btn btn-link btn-primary" (click)="refreshResults()">
        <clr-icon shape="refresh" class="has-badge"></clr-icon>&nbsp;Обновить список
      </button>
      <button class="btn btn-link btn-primary" (click)="editRow({})">
        <clr-icon shape="plus" has-badge="true"></clr-icon>&nbsp;Добавить новую запись
      </button>
    </div>
  </div>
</div>


<clr-modal [(clrModalOpen)]="editModalOpened" *ngIf="editModalOpened" [clrModalClosable]="false" [clrModalSize]="'lg'">
  <h3 class="modal-title">Редактирование записи
    <span *ngIf="currentIsNew" style="color:green"> [Новая]</span></h3>
  <div class="modal-body"  style="min-height: 300px">
    <div class="form compact" [formGroup]="currentFormGroup">
      <section class="form-block itech-block-normal">
        <div class="form-group">
          <label for="id" class="required">Ид подвида</label>
          <label for="id" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                 [class.invalid]="isInvalid(currentFormGroup, 'id')">
            <input type="text" id="id" placeholder="0000" class="itech-control-xlarge" formControlName="id">
            <app-validation-tooltip [input]="currentFormGroup.controls['id']"></app-validation-tooltip>
          </label>
        </div>

        <div class="form-group">
          <label for="caption" class="required">Наименование</label>
          <label for="caption" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                 [class.invalid]="isInvalid(currentFormGroup, 'caption')">
            <input type="text" id="caption" placeholder="Наименование" class="itech-control-xlarge"
                   formControlName="caption">
            <app-validation-tooltip [input]="currentFormGroup.controls['caption']"></app-validation-tooltip>
          </label>
        </div>

        <div class="form-group">
          <label for="code">Код</label>
          <label for="code" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                 [class.invalid]="isInvalid(currentFormGroup, 'code')">
            <input type="text" id="code" placeholder="00" class="itech-control-tiny"
                   formControlName="code" maxlength="5">
            <app-validation-tooltip [input]="currentFormGroup.controls['code']"></app-validation-tooltip>
          </label>
        </div>

        <div class="form-group">
          <label for="kind">Группа</label>
          <label for="kind" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                 [class.invalid]="isInvalid(currentFormGroup, 'kind')">
            <input type="text" id="kind" placeholder="00" class="itech-control-xlarge" formControlName="kind">
            <app-validation-tooltip [input]="currentFormGroup.controls['kind']"></app-validation-tooltip>
          </label>
        </div>

        <div class="form-group">
          <label for="infectionKind">Инфекционность</label>
          <app-combo-lookup [validationTooltipRight]="true" class="itech-control-xlarge"
                            [contextControlId]="'infectionKind'"
                            [contextControlName]="'infectionKind'"
                            [lookupName]="'disease-infection-kind'"
                            [contextFormGroup]="currentFormGroup"></app-combo-lookup>
        </div>

        <div class="form-group">
          <label for="guid">Guid</label>
          <label for="guid" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md tooltip-top-left"
                 [class.invalid]="isInvalid(currentFormGroup, 'guid')">
            <input type="text" id="guid" placeholder="00000000-aaaa-bbbb-cccc-000000000000" class="itech-control-xlarge"
                   formControlName="guid">
            <app-validation-tooltip [input]="currentFormGroup.controls['guid']"></app-validation-tooltip>
          </label>
        </div>

        <div class="form-group itech-group-whole-line">
          <div class="checkbox-inline">
            <input type="checkbox" id="mayBeIntroducedQuarantine" formControlName="mayBeIntroducedQuarantine"
                  (change)="currentFormGroup.get('liquidationRegulationsFileUri').setValue(null)">
            <label for="mayBeIntroducedQuarantine">Могут вводиться ограничительные мероприятия</label>
          </div>
          <div class="checkbox-inline">
            <input type="checkbox" id="include1Vet" formControlName="include1Vet">
            <label for="include1Vet">Включается в отчет 1-ВЕТ</label>
          </div>
        </div>

        <div class="form-group" *ngIf="currentFormGroup.get('mayBeIntroducedQuarantine').value" style="min-height:50px">
          <label for="liquidationRegulationsFileUri">Ветеринарные правила по ликвидации болезни</label>
          <label for="liquidationRegulationsFileUri" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md tooltip-bottom-left" style="margin-left: 5px"
                 [class.invalid]="isInvalid(currentFormGroup, 'liquidationRegulationsFileUri')">
            <app-single-file *ngIf="!currentIsNew" id="liquidationRegulationsFileUri"
                             [contextFormGroup]="currentFormGroup" [controlName]="'liquidationRegulationsFileUri'"
                             [uriUpload]="'/metadata/disease-type/upload-file/' + currentFormGroup.get('id').value">
            </app-single-file>
            <app-validation-tooltip [input]="currentFormGroup.controls['liquidationRegulationsFileUri']"></app-validation-tooltip>
          </label>
        </div>

        <div class="form-group">
          <label for="mv4ChapterId">Раздел МВ-4</label>
          <app-combo-lookup [validationTooltipRight]="true" class="itech-control-xlarge"
                            [contextControlId]="'mv4ChapterId'"
                            [contextControlName]="'mv4ChapterId'"
                            [lookupName]="'mv4-chapter'"
                            [contextFormGroup]="currentFormGroup"></app-combo-lookup>
        </div>
      </section>
    </div>
  </div>
  <div class="modal-footer itech-form-actions">
    <div>
      <button *ngIf="!currentIsNew" type="button" class="btn btn-warning-outline"
              (click)="delete(currentFormGroup.value)">
        <clr-icon shape="trash"></clr-icon>&nbsp;Удалить
      </button>
    </div>
    <div>
      <button type="button" class="btn btn-primary" (click)="addEditCompleted()">
        <clr-icon shape="check"></clr-icon>&nbsp;Сохранить
      </button>
      <button type="button" class="btn btn-warning" (click)="editModalOpened = false">
        <clr-icon shape="undo"></clr-icon>&nbsp;Отмена
      </button>
    </div>
  </div>
</clr-modal>
