import { ChangeDetectorRef, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AddressComponent } from '../../../ui/controls/address.component';
import { ServerSideErrorsProvider } from '../../../logic/validators/server-side-errors-provider';
import { serverSideErrorsValidator } from '../../../logic/validators/server-side-errors-validator.directive';
import { AppFormGroup } from '../../../ui/controls/app-form-group';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { AgentSearchModalComponent } from '../../edit-agent/search/agent-search-modal.component';
import { ProductDataService } from '../../../logic/services/product-data.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { StringHelper } from '../../../helpers/string-helper';
import { DateHelper } from '../../../helpers/date-helper';
var EditProductCommonFormComponent = /** @class */ (function () {
    function EditProductCommonFormComponent(fb, appNavigationService, globalWaitingOverlayService, productDataService, lookupSourceService, cdr) {
        this.fb = fb;
        this.appNavigationService = appNavigationService;
        this.globalWaitingOverlayService = globalWaitingOverlayService;
        this.productDataService = productDataService;
        this.lookupSourceService = lookupSourceService;
        this.cdr = cdr;
        this.validityControlsEvent = new EventEmitter();
        this.changeTransactionTypeEmitter = new EventEmitter();
        this.isInvalid = FormHelper.isInvalid;
        this.equalsSome = FormHelper.equalsSome;
        this.dateComboToStringPrettify = DateHelper.dateComboToStringPrettify;
    }
    EditProductCommonFormComponent.isTransportTransaction = function (formGroup) {
        return formGroup && FormHelper.equalsSome(+formGroup.get('transactionType').value, 1, 2);
    };
    EditProductCommonFormComponent.isTransportOrMovingTransaction = function (formGroup) {
        return formGroup && FormHelper.equalsSome(+formGroup.get('transactionType').value, 1, 2, 3);
    };
    EditProductCommonFormComponent.isProductionTransaction = function (formGroup) {
        return formGroup && +formGroup.get('transactionType').value === 4;
    };
    EditProductCommonFormComponent.createFormGroup = function (fb, serverSideErrorsProvider, val, fromTemplate) {
        if (fromTemplate === void 0) { fromTemplate = false; }
        if (!val.templateVisibility) {
            if (val.templateInstitutionEmployeeId) {
                val.templateVisibility = 3;
            }
            else if (val.templateInstitutionId) {
                val.templateVisibility = 2;
            }
            else {
                val.templateVisibility = 1;
            }
        }
        return fb.group({
            id: val.id,
            fromTemplate: fromTemplate,
            status: val.status,
            requestStatus: val.requestStatus,
            requestMercuryNumber: val.requestMercuryNumber,
            createdTime: val.createdTime,
            createdUser: val.createdUser,
            confirmedUser: val.confirmedUser,
            transactionType: [val.transactionType, Validators.compose([Validators.required,
                    serverSideErrorsValidator('transactionType', serverSideErrorsProvider)])],
            usePaperDoc: val.usePaperDoc,
            documentTargetType: [val.documentTargetType, Validators.compose([
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent && +ctrl.parent.get('transactionType').value !== 4; }),
                    serverSideErrorsValidator('documentTargetType', serverSideErrorsProvider)
                ])],
            isTemplate: !!val.templateCaption,
            templateVisibility: [val.templateVisibility,
                FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent && ctrl.parent.get('isTemplate').value; })],
            templateCaption: [val.templateCaption, Validators.compose([
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent && ctrl.parent.get('isTemplate').value; }),
                    serverSideErrorsValidator('templateCaption', serverSideErrorsProvider)
                ])],
            templateInstitutionId: [val.templateInstitutionId,
                serverSideErrorsValidator('templateInstitutionId', serverSideErrorsProvider)],
            templateInstitutionEmployeeId: [val.templateInstitutionEmployeeId,
                serverSideErrorsValidator('templateInstitutionEmployeeId', serverSideErrorsProvider)],
            storageWayId: [val.storageWayId, Validators.compose([
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent &&
                        FormHelper.equalsSome(+ctrl.parent.get('transactionType').value, 1, 2); }),
                    serverSideErrorsValidator('storageWayId', serverSideErrorsProvider)
                ])],
            originAgentId: val.originAgentId,
            originStableId: val.originStableId,
            brokerAgentId: val.brokerAgentId,
            mercuryUuid: val.mercuryUuid,
            sources: EditProductCommonFormComponent.buildSourcesArray(fb, serverSideErrorsProvider, val.sources, !val.id || !val.status || +val.status === -100),
            path: EditProductCommonFormComponent.buildPathArray(fb, serverSideErrorsProvider, val.path),
            transactions: fb.array(val.transactions || []),
            producers: EditProductCommonFormComponent.buildProducersArray(fb, serverSideErrorsProvider, val.producers),
            clearedComment: val.clearedComment,
            deletedTime: val.deletedTime,
            deletedUser: val.deletedUser,
            deletedReason: val.deletedReason,
            manyProducts: EditProductCommonFormComponent.buildManyProductsArray(fb, val.manyProducts),
            transportDocuments: EditProductCommonFormComponent.buildTransportDocumentsArray(fb, val.transportDocuments, fromTemplate),
            regionalizationRouteSections: EditProductCommonFormComponent
                .buildRegionalizationRouteSectionsArray(fb, serverSideErrorsProvider, val.regionalizationRouteSections),
            hasRequirements: (val.path || []).some(function (p) { return (p.regionalizationRequirements || []).length > 0; }) || false,
        });
    };
    EditProductCommonFormComponent.buildSourcesArray = function (fb, serverSideErrorsProvider, val, isDraft) {
        if (!val || !val.length) {
            val = [];
        }
        return fb.array(val.map(function (agentPerson) { return EditProductCommonFormComponent.buildSource(fb, agentPerson, isDraft); }));
    };
    EditProductCommonFormComponent.buildSource = function (fb, source, isDraft) {
        return fb.group({
            destinationAgentId: [source.destinationAgentId, Validators.compose([
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent &&
                        !ctrl.parent.parent.parent.get('isTemplate').value &&
                        ctrl.parent.parent.parent.get('id').value &&
                        +ctrl.parent.parent.parent.get('transactionType').value !== 4; })
                ])],
            destinationStableId: [source.destinationStableId, Validators.compose([
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent &&
                        !ctrl.parent.parent.parent.get('isTemplate').value &&
                        ctrl.parent.parent.parent.get('id').value &&
                        +ctrl.parent.parent.parent.get('transactionType').value !== 4; })
                ])],
            templateOriginStableId: source.templateOriginStableId,
            packages: EditProductCommonFormComponent.buildPackagesArray(fb, source.packages, isDraft),
            unitId: [source.unitId, Validators.compose([FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent &&
                        !ctrl.parent.parent.parent.get('isTemplate').value &&
                        ctrl.parent.parent.parent.get('id').value; })])],
            value: [source.value, Validators.compose([FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent &&
                        !ctrl.parent.parent.parent.get('isTemplate').value &&
                        ctrl.parent.parent.parent.get('id').value; }),
                    FormHelper.conditionalValidate(FormHelper.validateMoneyThreeDecimal(), function (ctrl) { return ctrl.parent &&
                        !ctrl.parent.parent.parent.get('isTemplate').value &&
                        ctrl.parent.parent.parent.get('id').value; })])],
            currentValue: source.currentValue,
            acceptValue: source.acceptValue,
            productTypeId: [source.productTypeId, Validators.compose([FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent &&
                        !ctrl.parent.parent.parent.get('isTemplate').value &&
                        ctrl.parent.parent.parent.get('id').value; })])],
            productSubtypeId: [source.productSubtypeId, Validators.compose([FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent &&
                        !ctrl.parent.parent.parent.get('isTemplate').value &&
                        ctrl.parent.parent.parent.get('id').value; })])],
            stableProductSubtypeId: source.stableProductSubtypeId,
            manufactureDateKind: source.manufactureDateKind || 0,
            manufactureDate: [source.manufactureDate, Validators.compose([FormHelper.validateDateTimePicker()])],
            manufactureDateYear: [source.manufactureDateYear,
                Validators.compose([Validators.min(1917), Validators.max(2100)])],
            manufactureDateMonth: [source.manufactureDateMonth, Validators.compose([Validators.min(1), Validators.max(12)])],
            manufactureDateDay: [source.manufactureDateDay, Validators.compose([Validators.min(1), Validators.max(31)])],
            manufactureDateHour: [source.manufactureDateHour, Validators.compose([Validators.min(0), Validators.max(23)])],
            manufactureDate2: [source.manufactureDate2, Validators.compose([FormHelper.validateDateTimePicker()])],
            manufactureDate2Year: [source.manufactureDate2Year,
                Validators.compose([Validators.min(1917), Validators.max(2100)])],
            manufactureDate2Month: [source.manufactureDate2Month, Validators.compose([Validators.min(1), Validators.max(12)])],
            manufactureDate2Day: [source.manufactureDate2Day, Validators.compose([Validators.min(1), Validators.max(31)])],
            manufactureDate2Hour: [source.manufactureDate2Hour, Validators.compose([Validators.min(0), Validators.max(23)])],
            expireDateKind: source.expireDateKind || 0,
            expireDate: [source.expireDate, Validators.compose([FormHelper.validateDateTimePicker()])],
            expireDateYear: [source.expireDateYear,
                Validators.compose([Validators.min(1917), Validators.max(2100)])],
            expireDateMonth: [source.expireDateMonth,
                Validators.compose([Validators.min(1), Validators.max(12)])],
            expireDateDay: [source.expireDateDay,
                Validators.compose([Validators.min(1), Validators.max(31)])],
            expireDateHour: [source.expireDateHour,
                Validators.compose([Validators.min(0), Validators.max(23)])],
            expireDate2: [source.expireDate2,
                Validators.compose([FormHelper.validateDateTimePicker()])],
            expireDate2Year: [source.expireDate2Year,
                Validators.compose([Validators.min(1917), Validators.max(2100)])],
            expireDate2Month: [source.expireDate2Month,
                Validators.compose([Validators.min(1), Validators.max(12)])],
            expireDate2Day: [source.expireDate2Day,
                Validators.compose([Validators.min(1), Validators.max(31)])],
            expireDate2Hour: [source.expireDate2Hour,
                Validators.compose([Validators.min(0), Validators.max(23)])],
            perishable: source.perishable,
            expertiseStatus: [source.expertiseStatus, Validators.compose([
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent && !ctrl.parent.parent.parent.get('isTemplate').value; })
                ])],
            comments: source.comments,
            productReasonId: [source.productReasonId, Validators.compose([FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent &&
                        !ctrl.parent.parent.parent.get('isTemplate').value &&
                        ctrl.parent.parent.parent.get('id').value &&
                        +ctrl.parent.parent.parent.get('transactionType').value !== 4; })])],
            badQuality: source.badQuality,
            shipmentNo: source.shipmentNo,
            protectedDocSerial: source.protectedDocSerial,
            protectedDocNo: source.protectedDocNo,
            mercuryDocNo: source.mercuryDocNo,
            ttnNo: source.ttnNo,
            ttnSerial: source.ttnSerial,
            needMainTtn: source.needMainTtn || true,
            useRestriction: source.useRestriction,
            originCountry: source.originCountry,
            ttnDate: [source.ttnDate, Validators.compose([FormHelper.validateDateTimePicker()])],
            ttnTypeId: [source.ttnTypeId, Validators.compose([
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent &&
                        !ctrl.parent.parent.parent.get('isTemplate').value &&
                        +ctrl.parent.parent.parent.get('transactionType').value !== 4 &&
                        ctrl.parent.get('needMainTtn').value; })
                ])],
            sourceAnimalIds: fb.array((source.sourceAnimalIds || []).map(function (el) { return fb.control(el); })),
            sourceProductIds: fb.array((source.sourceProductIds || []).map(function (el) { return fb.control(el); })),
            cargoInspected: source.cargoInspected,
            locationProsperity: [source.locationProsperity, Validators.compose([
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent && !ctrl.parent.parent.parent.get('isTemplate').value; })
                ])],
            animalSpentPeriodCode: source.animalSpentPeriodCode,
            monthsSpent: [source.monthsSpent, Validators.pattern(/^\d{1,9}$/)],
            quarantineDuration: [source.quarantineDuration, Validators.pattern(/^\d{1,9}$/)],
            quarantineComments: source.quarantineComments,
            batchId: fb.array((!source.batchId || !source.batchId.length ? [''] : source.batchId).map(function (el) { return fb.control(el); })),
            markingTypeId: source.markingTypeId,
            marking: source.marking,
            discrepancyReports: fb.array((source.discrepancyReports || []).map(function (el) {
                return EditProductCommonFormComponent.buildDiscrepancyReports(fb, el);
            })),
        });
    };
    EditProductCommonFormComponent.buildPathArray = function (fb, serverSideErrorsProvider, val) {
        if (!val || !val.length) {
            val = [];
        }
        if (val.length < 1) {
            val.push({ withReload: true });
        }
        if (val.length < 2) {
            val.push({ withReload: false });
        }
        return fb.array(val.map(function (el) { return EditProductCommonFormComponent.buildPath(fb, serverSideErrorsProvider, el); }));
    };
    EditProductCommonFormComponent.buildPath = function (fb, serverSideErrorsProvider, path) {
        return fb.group({
            addressId: path.addressId,
            withReload: [path.withReload, Validators.compose([Validators.required])],
            transportationKindId: [path.transportationKindId, Validators.compose([
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent
                        && ctrl.parent.get('withReload').value &&
                        FormHelper.equalsSome(+ctrl.parent.parent.parent.get('transactionType').value, 1, 2); })
                ])],
            vesselNo: [path.vesselNo, Validators.compose([
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent
                        && ctrl.parent.get('withReload').value &&
                        FormHelper.equalsSome(+ctrl.parent.parent.parent.get('transactionType').value, 1, 2); }),
                    serverSideErrorsValidator('vesselNo', serverSideErrorsProvider)
                ])],
            trailerNo: [path.trailerNo],
            containerNo: [path.containerNo],
            productId: path.productId,
            countryId: [path.productId ? path.countryId : 'RUS', Validators.compose([
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent
                        && ctrl.parent.get('withReload').value &&
                        FormHelper.equalsSome(+ctrl.parent.get('transportationKindId').value, 1) &&
                        FormHelper.equalsSome(+ctrl.parent.parent.parent.get('transactionType').value, 1, 2); })
                ])],
            address: fb.group(AddressComponent.buildFormGroupDef(fb, serverSideErrorsProvider, path.address, false)),
            regionalizationRequirements: EditProductCommonFormComponent.buildRegionalizationRequirementsArray(fb, serverSideErrorsProvider, path.regionalizationRequirements),
            pointCaption: [path.pointCaption],
        });
    };
    EditProductCommonFormComponent.buildRegionalizationRouteSectionsArray = function (fb, serverSideErrorsProvider, val) {
        if (!val || !val.length) {
            val = [];
        }
        return fb.array(val.map(function (el) { return EditProductCommonFormComponent.buildRegionalizationRouteSections(fb, serverSideErrorsProvider, el); }));
    };
    EditProductCommonFormComponent.buildRegionalizationRouteSections = function (fb, serverSideErrorsProvider, routeSection) {
        return fb.group({
            addressId: routeSection.addressId,
            routeSectionId: routeSection.routeSectionId,
            regionalizationRules: EditProductCommonFormComponent.buildRegionalizationRulesArray(fb, serverSideErrorsProvider, routeSection.regionalizationRules),
        });
    };
    EditProductCommonFormComponent.buildRegionalizationRulesArray = function (fb, serverSideErrorsProvider, val) {
        if (!val || !val.length) {
            val = [];
        }
        return fb.array(val.map(function (el) { return EditProductCommonFormComponent.buildRegionalizationRule(fb, serverSideErrorsProvider, el); }));
    };
    EditProductCommonFormComponent.buildRegionalizationRule = function (fb, serverSideErrorsProvider, rule) {
        return fb.group({
            productSubtypeGuid: rule.productSubtypeGuid,
            productSubtypeCaption: rule.productSubtypeCaption,
            decision: rule.decision,
            regionalizationRequirements: EditProductCommonFormComponent.buildRegionalizationRequirementsArray(fb, serverSideErrorsProvider, rule.regionalizationRequirements),
        });
    };
    EditProductCommonFormComponent.buildRegionalizationRequirementsArray = function (fb, serverSideErrorsProvider, val) {
        if (!val || !val.length) {
            val = [];
        }
        return fb.array(val.map(function (el) { return EditProductCommonFormComponent.buildRegionalizationRequirements(fb, serverSideErrorsProvider, el); }));
    };
    EditProductCommonFormComponent.buildRegionalizationRequirements = function (fb, serverSideErrorsProvider, requirement) {
        return fb.group({
            diseaseTypeId: requirement.diseaseTypeId,
            decision: requirement.decision,
            conditionGroups: EditProductCommonFormComponent.buildRegionalizationGroupsArray(fb, serverSideErrorsProvider, requirement.conditionGroups),
        });
    };
    EditProductCommonFormComponent.buildRegionalizationGroupsArray = function (fb, serverSideErrorsProvider, val) {
        if (!val || !val.length) {
            val = [];
        }
        return fb.array(val.map(function (el) { return EditProductCommonFormComponent.buildRegionalizationGroups(fb, serverSideErrorsProvider, el); }));
    };
    EditProductCommonFormComponent.buildRegionalizationGroups = function (fb, serverSideErrorsProvider, group) {
        return fb.group({
            acceptedGroup: group.acceptedGroup || false,
            conditionResults: EditProductCommonFormComponent.buildRegionalizationResultsArray(fb, serverSideErrorsProvider, group.conditionResults),
        });
    };
    EditProductCommonFormComponent.buildRegionalizationResultsArray = function (fb, serverSideErrorsProvider, val) {
        if (!val || !val.length) {
            val = [];
        }
        return fb.array(val.map(function (el) { return EditProductCommonFormComponent.buildRegionalizationResults(fb, serverSideErrorsProvider, el); }));
    };
    EditProductCommonFormComponent.buildRegionalizationResults = function (fb, serverSideErrorsProvider, result) {
        return fb.group({
            conditionId: result.conditionId,
        });
    };
    EditProductCommonFormComponent.buildProducersArray = function (fb, serverSideErrorsProvider, val) {
        if (!val || !val.length) {
            val = [];
        }
        return fb.array(val.map(function (el) { return EditProductCommonFormComponent.buildProducers(fb, serverSideErrorsProvider, el); }));
    };
    EditProductCommonFormComponent.buildProducers = function (fb, serverSideErrorsProvider, path) {
        return fb.group({
            stableId: path.stableId,
            role: path.role,
        });
    };
    EditProductCommonFormComponent.buildPackagesArray = function (fb, val, isDraft) {
        if (!val || !val.length) {
            val = isDraft ? [] : [{}];
        }
        if (isDraft) {
            val = val.concat([{}]);
        }
        return fb.array(val.map(function (pack) { return EditProductCommonFormComponent.buildPackage(fb, pack, isDraft); }));
    };
    EditProductCommonFormComponent.buildPackage = function (fb, pack, isDraft) {
        return fb.group({
            packageLevelId: pack.packageLevelId,
            packageTypeId: [pack.packageTypeId,
                FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent && ctrl.parent.get('packageLevelId').value; })],
            count: [pack.count, Validators.compose([FormHelper.conditionalValidate(Validators.pattern(/^\d{1,10}$/), function (ctrl) { return ctrl.parent && ctrl.parent.get('packageLevelId').value; }),
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent && ctrl.parent.get('packageLevelId').value; })])],
            currentCount: pack.currentCount,
            markings: fb.array((isDraft
                ? (pack.markings || []).concat([{}]) : pack.markings || [])
                .map(function (x) { return EditProductCommonFormComponent.buildMarking(fb, x); })),
            expandedMark: false,
        });
    };
    EditProductCommonFormComponent.buildMarking = function (fb, mark) {
        return fb.group({
            markingTypeId: [mark.markingTypeId,
                FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent && ctrl.parent.get('marking').value; })],
            marking: [mark.marking,
                FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent && ctrl.parent.get('markingTypeId').value; })],
        });
    };
    EditProductCommonFormComponent.buildManyProductsArray = function (fb, val) {
        if (!val || !val.length) {
            val = [];
        }
        return fb.array(val.map(function (manyProduct) { return EditProductCommonFormComponent.buildManyProductsGroupDefs(fb, manyProduct); }));
    };
    EditProductCommonFormComponent.buildManyProductsGroupDefs = function (fb, data) {
        if (data === void 0) { data = {}; }
        var fg = fb.group({
            stableId: data.stableId,
            sourceBatches: fb.array([]),
            producedBatches: fb.array([]),
            expanded: false,
        });
        if (data.producedBatches && data.producedBatches.length) {
            data.producedBatches.forEach(function (produced) {
                produced.stableId = data.stableId;
                fg.get('producedBatches').push(fb.group(EditProductCommonFormComponent.buildProductBatch(fb, produced)));
            });
        }
        else {
            var manDate = DateHelper.startDay(new Date());
            fg.get('producedBatches')
                .push(fb.group(EditProductCommonFormComponent.buildProductBatch(fb, {
                stableId: data.stableId,
                manufactureDateKind: 1,
                manufactureDate: StringHelper.getISODateWithHourMinute(manDate),
                manufactureDateYear: manDate.getFullYear(),
                manufactureDateMonth: manDate.getMonth() + 1,
                manufactureDateDay: manDate.getDate(),
                expireDateKind: 1,
            })));
        }
        if (data.sourceBatches && data.sourceBatches.length) {
            data.sourceBatches.forEach(function (produced) {
                return fg.get('sourceBatches').push(fb.group(EditProductCommonFormComponent.buildSourceBatch(fb, produced)));
            });
        }
        else {
            fg.get('sourceBatches').push(fb.group(EditProductCommonFormComponent.buildSourceBatch(fb)));
        }
        return fg;
    };
    EditProductCommonFormComponent.buildProductBatch = function (fb, data) {
        if (data === void 0) { data = {}; }
        return {
            stableId: data.stableId,
            stableProductSubtypeId: data.stableProductSubtypeId,
            animalIds: fb.array(data.animalIds || []),
            isAnimalProductKind: data.isAnimalProductKind || false,
            valueCount: [data.valueCount, FormHelper.validateMoneyThreeDecimal()],
            unitId: data.unitId,
            packages: EditProductCommonFormComponent.buildPackagesArray(fb, data.packages, true),
            isDraft: true,
            batchId: data.batchId,
            manufactureDateKind: data.manufactureDateKind || 0,
            manufactureDate: [data.manufactureDate, Validators.compose([FormHelper.validateDateTimePicker()])],
            manufactureDateYear: [data.manufactureDateYear,
                Validators.compose([Validators.min(1917), Validators.max(2100)])],
            manufactureDateMonth: [data.manufactureDateMonth,
                Validators.compose([Validators.min(1), Validators.max(12)])],
            manufactureDateDay: [data.manufactureDateDay,
                Validators.compose([Validators.min(1), Validators.max(31)])],
            manufactureDateHour: [data.manufactureDateHour,
                Validators.compose([Validators.min(0), Validators.max(23)])],
            manufactureDate2: [data.manufactureDate2,
                Validators.compose([FormHelper.validateDateTimePicker()])],
            manufactureDate2Year: [data.manufactureDate2Year,
                Validators.compose([Validators.min(1917), Validators.max(2100)])],
            manufactureDate2Month: [data.manufactureDate2Month,
                Validators.compose([Validators.min(1), Validators.max(12)])],
            manufactureDate2Day: [data.manufactureDate2Day,
                Validators.compose([Validators.min(1), Validators.max(31)])],
            manufactureDate2Hour: [data.manufactureDate2Hour,
                Validators.compose([Validators.min(0), Validators.max(23)])],
            expireDateKind: data.expireDateKind || 0,
            expireDate: [data.expireDate, Validators.compose([FormHelper.validateDateTimePicker()])],
            expireDateYear: [data.expireDateYear,
                Validators.compose([Validators.min(1917), Validators.max(2100)])],
            expireDateMonth: [data.expireDateMonth,
                Validators.compose([Validators.min(1), Validators.max(12)])],
            expireDateDay: [data.expireDateDay,
                Validators.compose([Validators.min(1), Validators.max(31)])],
            expireDateHour: [data.expireDateHour,
                Validators.compose([Validators.min(0), Validators.max(23)])],
            expireDate2: [data.expireDate2,
                Validators.compose([FormHelper.validateDateTimePicker()])],
            expireDate2Year: [data.expireDate2Year,
                Validators.compose([Validators.min(1917), Validators.max(2100)])],
            expireDate2Month: [data.expireDate2Month,
                Validators.compose([Validators.min(1), Validators.max(12)])],
            expireDate2Day: [data.expireDate2Day,
                Validators.compose([Validators.min(1), Validators.max(31)])],
            expireDate2Hour: [data.expireDate2Hour,
                Validators.compose([Validators.min(0), Validators.max(23)])],
            perishable: data.perishable || false,
        };
    };
    EditProductCommonFormComponent.buildSourceBatch = function (fb, data) {
        if (data === void 0) { data = {}; }
        return {
            productId: data.productId,
            writeOff: [data.writeOff, FormHelper.validateMoneyThreeDecimal()],
        };
    };
    EditProductCommonFormComponent.buildTransportDocumentsArray = function (fb, val, fromTemplate) {
        if (fromTemplate === void 0) { fromTemplate = false; }
        if (!val || !val.length) {
            val = [{}];
        }
        return fb.array(val.map(function (doc) { return EditProductCommonFormComponent.buildTransportDocumentsGroupDefs(fb, doc, fromTemplate); }));
    };
    EditProductCommonFormComponent.buildTransportDocumentsGroupDefs = function (fb, data, fromTemplate) {
        if (data === void 0) { data = {}; }
        return fb.group({
            productId: data.productId,
            stableId: data.stableId,
            valueCount: [data.valueCount, FormHelper.validateMoneyThreeDecimal()],
            stableProductSubtypeId: data.stableProductSubtypeId,
            stableProductSubtypeIdSource: data.stableProductSubtypeId,
            productTypeId: data.productTypeId,
            destinationAgentId: data.destinationAgentId,
            destinationStableId: data.destinationStableId,
            packages: EditProductCommonFormComponent.buildPackagesArray(fb, data.packages, true),
            protectedDocSerial: data.protectedDocSerial,
            protectedDocNo: data.protectedDocNo,
            expertiseStatus: data.expertiseStatus,
            cargoInspected: data.cargoInspected,
            locationProsperity: data.locationProsperity,
            animalSpentPeriodCode: data.animalSpentPeriodCode,
            monthsSpent: data.monthsSpent,
            quarantineDuration: data.quarantineDuration,
            quarantineComments: data.quarantineComments,
            badQuality: data.badQuality,
            productReasonId: data.productReasonId,
            comments: data.comments,
            isDraft: true,
            loaded: data.loaded || fromTemplate,
            otherTtn: data.otherTtn || false,
            ttnNo: data.ttnNo,
            ttnSerial: data.ttnSerial,
            ttnDate: [data.ttnDate, Validators.compose([FormHelper.validateDateTimePicker()])],
            ttnTypeId: [data.ttnTypeId, Validators.compose([
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent && ctrl.parent.get('otherTtn').value &&
                        +ctrl.parent.parent.parent.get('transactionType').value !== 4; })
                ])],
        });
    };
    EditProductCommonFormComponent.buildDiscrepancyReports = function (fb, data) {
        return fb.group({
            productId: data.productId,
            productSourceId: data.productSourceId,
            id: data.id,
            date: data.date,
            serial: data.serial,
            number: data.number,
            reason: data.reason,
            caption: data.caption,
            originStableProductSubtypeId: data.originStableProductSubtypeId,
            stableProductSubtypeId: data.stableProductSubtypeId,
            originVolume: data.originVolume,
            volume: data.volume,
            originExpireDateKind: data.originExpireDateKind,
            originExpireDate: data.originExpireDate,
            originExpireDateYear: data.originExpireDateYear,
            originExpireDateMonth: data.originExpireDateMonth,
            originExpireDateDay: data.originExpireDateDay,
            originExpireDateHour: data.originExpireDateHour,
            originExpireDate2: data.originExpireDate2,
            originExpireDate2Year: data.originExpireDate2Year,
            originExpireDate2Month: data.originExpireDate2Month,
            originExpireDate2Day: data.originExpireDate2Day,
            originExpireDate2Hour: data.originExpireDate2Hour,
            expireDateKind: data.expireDateKind,
            expireDate: data.expireDate,
            expireDateYear: data.expireDateYear,
            expireDateMonth: data.expireDateMonth,
            expireDateDay: data.expireDateDay,
            expireDateHour: data.expireDateHour,
            expireDate2: data.expireDate2,
            expireDate2Year: data.expireDate2Year,
            expireDate2Month: data.expireDate2Month,
            expireDate2Day: data.expireDate2Day,
            expireDate2Hour: data.expireDate2Hour,
            originTtnNo: data.originTtnNo,
            originTtnSerial: data.originTtnSerial,
            originTtnDate: data.originTtnDate,
            ttnSerial: data.ttnSerial,
            ttnNo: data.ttnNo,
            ttnDate: data.ttnDate,
            originVesselNo: data.originVesselNo,
            originTrailerNo: data.originTrailerNo,
            originContainerNo: data.originContainerNo,
            vesselNo: data.vesselNo,
            trailerNo: data.trailerNo,
            containerNo: data.containerNo,
        });
    };
    EditProductCommonFormComponent.prettifyFormGroup = function (fg) {
    };
    Object.defineProperty(EditProductCommonFormComponent.prototype, "regionalizationRouteSections", {
        get: function () {
            return this.contextFormGroup.get('regionalizationRouteSections');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditProductCommonFormComponent.prototype, "sources", {
        get: function () {
            return this.contextFormGroup.get('sources');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditProductCommonFormComponent.prototype, "path", {
        get: function () {
            return this.contextFormGroup.get('path');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditProductCommonFormComponent.prototype, "producers", {
        get: function () {
            return this.contextFormGroup.get('producers');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditProductCommonFormComponent.prototype, "manyProducts", {
        get: function () {
            return this.contextFormGroup.get('manyProducts');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditProductCommonFormComponent.prototype, "requestStatus", {
        get: function () {
            return +this.contextFormGroup.get('requestStatus').value;
        },
        enumerable: true,
        configurable: true
    });
    EditProductCommonFormComponent.prototype.addPath = function () {
        var fg = EditProductCommonFormComponent.buildPath(this.fb, this.serverSideErrorsProvider, { withReload: false });
        this.path.insert(this.path.length - 1, fg);
        this.path.markAsDirty();
    };
    EditProductCommonFormComponent.prototype.deletePath = function (i) {
        this.path.removeAt(i);
        this.path.markAsDirty();
    };
    EditProductCommonFormComponent.prototype.addSource = function () {
        var fg = EditProductCommonFormComponent.buildSource(this.fb, { ttnTypeId: 0, manufactureDate: 'T00:00', expireDate: 'T00:00', originCountry: 'RUS' });
        this.sources.push(fg);
        this.sources.markAsDirty();
    };
    EditProductCommonFormComponent.prototype.deleteSource = function (i) {
        this.sources.removeAt(i);
        this.sources.markAsDirty();
        if (!this.sources.length) {
            while (this.manyProducts.length) {
                this.manyProducts.removeAt(0);
            }
            this.addSource();
        }
    };
    EditProductCommonFormComponent.prototype.getAllSourceAnimals = function () {
        var animals = [];
        for (var _i = 0, _a = this.contextFormGroup.get('sources').controls; _i < _a.length; _i++) {
            var sourceFg = _a[_i];
            for (var _b = 0, _c = sourceFg.get('sourceAnimalIds').controls; _b < _c.length; _b++) {
                var sourceAnimalFg = _c[_b];
                animals.push(sourceAnimalFg);
            }
        }
        return animals;
    };
    EditProductCommonFormComponent.prototype.getAllDestinationStables = function () {
        var stables = [];
        var _loop_1 = function (sourceFg) {
            if (sourceFg.get('destinationStableId').value && !stables.some(function (x) { return x.value === sourceFg.get('destinationStableId').value; })) {
                stables.push(sourceFg.get('destinationStableId'));
            }
        };
        for (var _i = 0, _a = this.contextFormGroup.get(this.contextFormGroup.get('id').value ? 'sources' : 'transportDocuments').controls; _i < _a.length; _i++) {
            var sourceFg = _a[_i];
            _loop_1(sourceFg);
        }
        return stables;
    };
    EditProductCommonFormComponent.prototype.isTransportTransaction = function () {
        return EditProductCommonFormComponent.isTransportTransaction(this.contextFormGroup);
    };
    EditProductCommonFormComponent.prototype.isTransportOrMovingTransaction = function () {
        return EditProductCommonFormComponent.isTransportOrMovingTransaction(this.contextFormGroup);
    };
    EditProductCommonFormComponent.prototype.changeTransactionType = function () {
        // что за cdr и зачем он нужен тут - #15797
        this.cdr.detach();
        if (this.contextFormGroup.get('transactionType').value == 4) {
            this.contextFormGroup.get('documentTargetType').setValue(2); // на отправителя
        }
        this.changeTransactionTypeEmitter.emit();
        this.contextFormGroup.updateValueAndValidity();
        this.cdr.detectChanges();
        this.cdr.reattach();
    };
    EditProductCommonFormComponent.prototype.getRndToken = function () {
        return this.contextFormGroup.get('id').value
            ? this.contextFormGroup.get('id').value
            : this.model && this.model.randomUniqueTag ? this.model.randomUniqueTag : Math.floor(Math.random() * 1000000);
    };
    EditProductCommonFormComponent.prototype.searchBrokerAgent = function () {
        var _this = this;
        this.appNavigationService.searchAgent(AgentSearchModalComponent).subscribe(function (val) {
            if (val) {
                _this.contextFormGroup.get('brokerAgentId').setValue(val);
                _this.contextFormGroup.markAsDirty();
            }
        });
    };
    EditProductCommonFormComponent.prototype.removeBroker = function () {
        this.contextFormGroup.controls['brokerAgentId'].setValue(undefined);
        this.contextFormGroup.markAsDirty();
    };
    EditProductCommonFormComponent.prototype.checkRegionalization = function () {
        var _this = this;
        this.globalWaitingOverlayService.StartWaiting();
        var sections = this.contextFormGroup.get('regionalizationRouteSections');
        while (sections.length > 0) {
            sections.removeAt(0);
        }
        this.productDataService.checkRegionalization(this.contextFormGroup.value).subscribe(function (val) {
            val.forEach(function (el) {
                _this.contextFormGroup.get('regionalizationRouteSections')
                    .push(EditProductCommonFormComponent.buildRegionalizationRouteSections(_this.fb, _this.serverSideErrorsProvider, el));
            });
            _this.contextFormGroup.get('hasRequirements').setValue(val.some(function (el) { return (el.regionalizationRules || []).some(function (r) { return +r.decision === 2; }); }));
            FormHelper.setSingleFormGroupServerSideValidationErrors({}, _this.model, _this.contextFormGroup);
            _this.lookupSourceService.invalidateLookup('regionalization-condition');
            _this.globalWaitingOverlayService.EndWaiting();
        }, function (error) {
            FormHelper.setSingleFormGroupServerSideValidationErrors(error, _this.model, _this.contextFormGroup);
            _this.globalWaitingOverlayService.EndWaiting();
        });
    };
    EditProductCommonFormComponent.prototype.markAsDirty = function () {
        this.contextFormGroup.markAsDirty();
    };
    EditProductCommonFormComponent.prototype.changeAcceptedGroup = function (group, requirement) {
        if (group.get('acceptedGroup').value) {
            requirement.get('conditionGroups').controls.forEach(function (g) {
                g.get('acceptedGroup').setValue(false);
            });
            group.get('acceptedGroup').setValue(true);
        }
    };
    return EditProductCommonFormComponent;
}());
export { EditProductCommonFormComponent };
