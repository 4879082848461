<div class="itech-common-wrap" id="interceptNoAccess">
  <div id="drug-common-content">
    <div class="itech-doc-container" *ngIf="contextFormGroup">
      <div class="itech-doc-image">
        <clr-icon shape="flask"></clr-icon>
      </div>
      <div class="itech-doc-content">
        <app-edit-drug-common-form [contextFormGroup]="contextFormGroup"></app-edit-drug-common-form>
      </div>
    </div>
  </div>
  <div class="itech-form-actions">
    <div>
      <button class="btn btn-secondary" (click)="refreshFormData()">
        <clr-icon shape="refresh"></clr-icon>&nbsp;Обновить
      </button>
      <app-reports-button [reportKind]="'drug'" [objId1]="id" [dropdownPosition]="'top-left'">
      </app-reports-button>

      <clr-dropdown>
        <button class="btn btn-warning-outline" clrDropdownToggle>
          Действия
          <clr-icon shape="caret down"></clr-icon>
        </button>
        <clr-dropdown-menu *clrIfOpen [clrPosition]="'top-left'">
          <label class="dropdown-header">Выберите действие</label>
          <button class="btn btn-primary-outline" (click)="navigationService.navigateJournal(undefined, 8, id, undefined)">
            <clr-icon shape="view-list"></clr-icon>&nbsp;Журнал
          </button>
          <div class="dropdown-divider"></div>
          <button class="btn btn-warning-outline" (click)="deleteDrug()">
            <clr-icon shape="trash"></clr-icon>
            Удалить
          </button>
        </clr-dropdown-menu>
      </clr-dropdown>

    </div>
    <div>
      <button [clrLoading]="storing" class="btn btn-primary itech-form-save"
              [disabled]="!contextFormGroup || !contextFormGroup.dirty || storing" (click)="store()">
        <clr-icon shape="check"></clr-icon>&nbsp;Сохранить
      </button>
      <button class="btn btn-warning itech-form-cancel"
              [disabled]="!contextFormGroup || !contextFormGroup.dirty" (click)="cancelEdit()">
        <clr-icon shape="undo"></clr-icon>&nbsp;Отменить
      </button>
    </div>
  </div>
</div>
