import { FormGroup } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
var QueryLookupComboFilterComponent = /** @class */ (function () {
    function QueryLookupComboFilterComponent() {
        this.class = 'form-group';
    }
    QueryLookupComboFilterComponent.prototype.isInvalid = function (cname) {
        return FormHelper.isInvalid(this.contextFormGroup, cname);
    };
    Object.defineProperty(QueryLookupComboFilterComponent.prototype, "customFilterChecked", {
        get: function () {
            return this.contextFormGroup && (+this.contextFormGroup.get('_par_' + this.parameterName + '_filter').value) === 101;
        },
        set: function (val) {
            if (this.contextFormGroup) {
                this.contextFormGroup.get('_par_' + this.parameterName + '_filter').setValue(val ? 101 : 0);
                this.contextFormGroup.markAsDirty();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QueryLookupComboFilterComponent.prototype, "noValueChecked", {
        get: function () {
            return this.contextFormGroup && (+this.contextFormGroup.get('_par_' + this.parameterName + '_filter').value) === 1;
        },
        set: function (val) {
            if (this.contextFormGroup) {
                this.contextFormGroup.get('_par_' + this.parameterName + '_filter').setValue(val ? 1 : 0);
                this.contextFormGroup.markAsDirty();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QueryLookupComboFilterComponent.prototype, "filtered", {
        get: function () {
            return !!this.contextFormGroup.get('_par_' + this.parameterName + '_filter').value
                || !!this.contextFormGroup.get('_par_' + this.parameterName).value;
        },
        enumerable: true,
        configurable: true
    });
    return QueryLookupComboFilterComponent;
}());
export { QueryLookupComboFilterComponent };
