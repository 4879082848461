import { Component, Input, OnChanges } from '@angular/core';
import { FormHelper } from './form-helper';
import { FormBuilder, Validators } from '@angular/forms';
import { ServerSideErrorsProvider } from '../../logic/validators/server-side-errors-provider';
import { serverSideErrorsValidator } from '../../logic/validators/server-side-errors-validator.directive';
import { StringHelper } from '../../helpers/string-helper';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
})
export class AddressComponent implements OnChanges {
  @Input() contextFormGroup;
  @Input() required = true;
  @Input() disabled = false;
  @Input() kind = [
    { id: 0, caption: 'В регионе' },
    { id: 1, caption: 'Вне региона' },
    { id: 2, caption: 'Иной' }
  ];

  rndToken = Math.floor(Math.random() * 1000000);
  cadNumberPattern = StringHelper.cadNumberPattern;

  public static buildFormGroupDef(fb: FormBuilder, serverSideErrorsProvider: ServerSideErrorsProvider, value: any,
                                  required: boolean = true, addErrorsValidator: boolean = true): any {

    const address = value || {};

    return {
      kind: (address.kind || '0').toString(),
      regionId: addErrorsValidator
        ? [address.regionId, Validators.compose([required
          ? FormHelper.conditionalValidate(Validators.required,
              ctrl => !ctrl.parent || ctrl.parent.get('kind').value == '0' || ctrl.parent.get('kind').value == '2')
          : undefined,
          serverSideErrorsValidator('regionId', serverSideErrorsProvider)])]
        : address.regionId,
      cityId: addErrorsValidator
        ? [address.cityId, Validators.compose([required
          ? FormHelper.conditionalValidate(Validators.required, ctrl => !ctrl.parent || ctrl.parent.get('kind').value == '0')
          : undefined,
          serverSideErrorsValidator('cityId', serverSideErrorsProvider)])]
        : address.cityId,
      regionIdCityId: addErrorsValidator
        ? [address.regionId && address.cityId ? address.regionId.toString() + '-' + address.cityId.toString() : undefined,
          Validators.compose([required
            ? FormHelper.conditionalValidate(Validators.required, ctrl => !ctrl.parent || ctrl.parent.get('kind').value == '0')
            : undefined,
          serverSideErrorsValidator('cityId', serverSideErrorsProvider)])]
        : address.regionId && address.cityId ? address.regionId.toString() + '-' + address.cityId.toString() : undefined,
      streetId: addErrorsValidator
        ? [address.streetId, Validators.compose([required
          ? FormHelper.conditionalValidate(Validators.required, ctrl => !ctrl.parent || ctrl.parent.get('kind').value == '0')
          : undefined,
          serverSideErrorsValidator('streetId', serverSideErrorsProvider)])]
        : address.streetId,
      house: addErrorsValidator ? [address.house, serverSideErrorsValidator('house', serverSideErrorsProvider)] : address.house,
      building: addErrorsValidator ? [address.building, serverSideErrorsValidator('building', serverSideErrorsProvider)] : address.building,
      room: addErrorsValidator ? [address.room, serverSideErrorsValidator('room', serverSideErrorsProvider)] : address.room,
      other: addErrorsValidator
        ? [address.other, Validators.compose([required
          ? FormHelper.conditionalValidate(Validators.required,
            ctrl => !ctrl.parent || ctrl.parent.get('kind').value == '1' || ctrl.parent.get('kind').value == '2')
          : undefined, serverSideErrorsValidator('other', serverSideErrorsProvider)])]
        : address.other,
      countryId: addErrorsValidator
        ? [address.countryId,
            Validators.compose([required
                ? FormHelper.conditionalValidate(Validators.required,
                    ctrl => !ctrl.parent || ctrl.parent.get('kind').value == '1')
                : undefined, serverSideErrorsValidator('countryId', serverSideErrorsProvider)])] : address.countryId,
      subjfedId: addErrorsValidator
        ? [address.subjfedId, serverSideErrorsValidator('subjfedId', serverSideErrorsProvider)] : address.subjfedId,
      cadNumberStr: addErrorsValidator
        ? [StringHelper.stringCadNumberSplitWithColon(address.cadNumber), serverSideErrorsValidator('cadNumber', serverSideErrorsProvider)]
        : StringHelper.stringCadNumberSplitWithColon(address.cadNumber),
      cadNumber: address.cadNumber,
      coordsLatitude: addErrorsValidator
        ? [address.coordsLatitude, serverSideErrorsValidator('coordsLatitude', serverSideErrorsProvider)] : address.coordsLatitude,
      coordsLongitude: addErrorsValidator
        ? [address.coordsLongitude, serverSideErrorsValidator('coordsLongitude', serverSideErrorsProvider)] : address.coordsLongitude,
      ikarLocalityGuid: address.ikarLocalityGuid,
      ikarDistrictGuid: address.ikarDistrictGuid,
      addressShortText: address.addressShortText,
    };
  }

  ngOnChanges() {
    this.contextFormGroup.get('cadNumberStr').valueChanges
      .subscribe(() => this.contextFormGroup.get('cadNumber')
        .setValue(StringHelper.stringCadNumberWithoutColon(this.contextFormGroup.get('cadNumberStr').value)));
  }

  isInvalid(cname: string) {
    return FormHelper.isInvalid(this.contextFormGroup, cname);
  }

  regionIdCityIdChanged(event: any) {
    setTimeout(() => {
      const matches = this.contextFormGroup.value && this.contextFormGroup.value.regionIdCityId
        ? this.contextFormGroup.value.regionIdCityId.match(/(\d+)-(\d+)/)
        : undefined;
      this.contextFormGroup.get('regionId').setValue(matches ? matches[1] : undefined);
      this.contextFormGroup.get('cityId').setValue(matches ? matches[2] : undefined);
      this.contextFormGroup.get('streetId').setValue(undefined);
    }, 300);
  }

  cadNumberStrChanged(event: any) {
    setTimeout(() => {
      this.contextFormGroup.get('cadNumber')
        .setValue(StringHelper.stringCadNumberWithoutColon(this.contextFormGroup.get('cadNumberStr').value));
    }, 300);
  }
}
