import { Component, Input, OnChanges, HostBinding } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';

@Component({
  selector: 'app-query-integer-filter',
  styles: [`
    .radio-inline {
      font-size: 10px;
    }

    label > .radio-inline:first-child {
      margin-left: 5px;
    }

    .radio-inline > label::before {
      top: 0;
    }
  `],
  template: `
    <ng-container [formGroup]="contextFormGroup" *ngIf="contextFormGroup
    && (!queryUserMode || queryUserMode['_par_' + parameterName + '_filter_mq'])">
      <label style="max-width: 17rem; width: 17rem"
             [style.background]="filtered?'linear-gradient(to right, #90ff90, white)':undefined">
        <ng-content></ng-content>
        :
        <div class="radio-inline">
          <input type="radio" id="{{'_par_' + parameterName + '_filter0'}}"
                 [formControlName]="'_par_' + parameterName + '_filter'" [value]="0">
          <label for="{{'_par_' + parameterName + '_filter0'}}">Не фильтр.:</label>
        </div>
        <div class="radio-inline" title="Спросить у пользователя" *ngIf="!queryUserMode">
          <input type="radio" id="{{'_par_' + parameterName + '_filter103'}}"
                 [formControlName]="'_par_' + parameterName + '_filter'" [value]="103">
          <label for="{{'_par_' + parameterName + '_filter103'}}">?</label>
        </div>
        <div class="radio-inline">
          <input type="radio" id="{{'_par_' + parameterName + '_filter1'}}"
                 [formControlName]="'_par_' + parameterName + '_filter'" [value]="1">
          <label for="{{'_par_' + parameterName + '_filter1'}}">Отсутств.:</label>
        </div>
        <div class="radio-inline">
          <input type="radio" id="{{'_par_' + parameterName + '_filter2'}}"
                 [formControlName]="'_par_' + parameterName + '_filter'" [value]="2">
          <label for="{{'_par_' + parameterName + '_filter2'}}">Значен.:</label>
        </div>
      </label>
      <label aria-haspopup="true" role="tooltip"
             class="tooltip tooltip-validation tooltip-md tooltip-right"
             [class.invalid]="isInvalid('_par_' + parameterName)">
        <input [formControlName]="'_par_' + parameterName" type="text"
               [attr.disabled]="contextFormGroup.controls['_par_' + parameterName + '_filter'].value == 2? undefined: true">
        <app-validation-tooltip
          [input]="contextFormGroup.controls['_par_' + parameterName]"></app-validation-tooltip>
      </label>
    </ng-container>
  `
})
export class QueryIntegerFilterComponent {
  @Input() contextFormGroup: FormGroup;
  @Input() parameterName: string;
  @Input() queryUserMode: false;
  @HostBinding('attr.class') class = 'form-group';
  @HostBinding('style.padding') padding = '0 0 0 17rem';

  isInvalid(cname: string) {
    return FormHelper.isInvalid(this.contextFormGroup, cname);
  }

  get filtered() {
    return !!this.contextFormGroup.get('_par_' + this.parameterName + '_filter').value;
  }
}
