import { Component, Input, OnInit } from '@angular/core';
import { QueryRunnerUiService } from './helpers/query-runner-ui.service';
import { LookupSourceService } from '../../logic/services/lookup-source.service';

@Component({
  selector: 'app-query-details-results',
  templateUrl: './query-details-results.component.html',
  styleUrls: ['./query.all.css']
})
export class QueryDetailsResultsComponent implements OnInit {
  @Input() model = {
    data: [],
    dataColumns: [],
    entityType: undefined,
    query: undefined,
    condition: undefined,
    queryViewId: undefined,
  };

  constructor(public queryRunnerUi: QueryRunnerUiService,
              public lookupSourceService: LookupSourceService) {
  }

  ngOnInit() {
    this.refreshFormDataInternal();
  }

  refreshFormData() {
    this.model.query.results = undefined;
    this.refreshFormDataInternal();
  }

  refreshFormDataInternal() {

    if (this.model.query.results) {
      return;
    }

    this.queryRunnerUi.runQuery(this.model, this.model.queryViewId);
  }

  exportCsv() {
    this.queryRunnerUi.exportQueryCsv(this.model, this.model.queryViewId);
  }
}
