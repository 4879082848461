import { Component, Input } from '@angular/core';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { StringHelper } from '../../../helpers/string-helper';
import { AnimalDataService } from '../../../logic/services/animal-data.service';
import { DateHelper } from '../../../helpers/date-helper';

@Component({
  selector: 'app-edit-animal-events',
  templateUrl: './edit-animal-events.component.html'
})
export class EditAnimalEventsComponent {

  @Input() model: any = {};
  @Input() isExternal = false;

  isInvalid = FormHelper.isInvalid;

  gridColumnDefs = [
    {
      headerName: '№', field: 'id', width: 70,
    },
    {
      headerName: 'Синхронизация', field: 'mercuryId', width: 50,
      cellRenderer: params => {
        return params && params.data && params.data['isSync']
          ? `<clr-icon shape="event" style="color:green" title="Синхронизация проведена"></clr-icon>`
          : ``;
      },
      filterValueGetter: params => params.data['isSync'] ? 'синхронизировано' : 'не синхронизировано',
      cellStyle: {'padding-left': '17px'},
    },
    {
      headerName: 'Дата', field: 'date', width: 120,
      cellRenderer: params => {
        if (!params || !params.data) {
          return '';
        }

        let html = '';
        if (params.data['notExecuted']) {
          html = '<clr-icon shape="times" style="color:silver"></clr-icon>';
        }
        if (DateHelper.isExpiredEvent(params.data['date'], params.data['datePlanning'])) {
          html = '<clr-icon shape="history" style="color:red" title="Мероприятие просрочено"></clr-icon>';
        } else {
          html = params.data['date'] ?
            '<clr-icon shape="check" style="color:green"></clr-icon>' :
            '<clr-icon shape="alarm-clock" style="color:silver"></clr-icon>';
        }
        return html + ' ' + (StringHelper.getRuDate(params.value));
      },
      valueGetter: params => {
        return params.data['date'] || params.data['datePlanning'];
      }
    },
    {
      headerName: 'Вид', field: 'eventTypeId', width: 230,
      valueFormatter: params => params.value
        ? (params.data.performedByAgent ? '[ХС] ' : '') + this.eventTypeIdLookup[params.value]
        : '<не указан>',
    },
    {
      headerName: 'Заболевание', field: 'diseaseTypeId', width: 250,
      valueFormatter: params => params.value ? this.diseaseTypeIdLookup[params.value] : '<не указано>',
      cellStyle: params => {
        return {
          color: params.value && params.data.diseasePositive ? 'red' : 'silver'
        };
      },
    },
    {
      headerName: 'Препараты', field: 'drugsCaption', width: 250,
      cellStyle: params => {
        return {
          color: !params.value ? 'silver' : undefined
        };
      },
    },
    {
      headerName: 'Поголовье', width: 450,
      valueFormatter: params => {
        if (!params || !params.data || !params.data.counts) {
          return '';
        }
        return params.data.counts
          .map(cnt => cnt.caption + ': ' + (cnt.count || '0') + ' (' + cnt.countDiff + ')')
          .join('; ');
      },
      cellRenderer: this.countsCellRenderer
    },
  ];

  eventTypeIdLookup: any = {};
  diseaseTypeIdLookup: any = {};

  private _id: number;
  @Input()
  get id(): number {
    return this._id;
  }

  set id(id: number) {
    if (this._id !== id) {
      this._id = id;
      this.refreshFormDataInternal();
    }
  }

  constructor(private animalDataService: AnimalDataService,
              private dataCachingService: DataCachingService,
              private waitingOverlay: GlobalWaitingOverlayService,
              private lookupSourceService: LookupSourceService,
              public navigationService: AppNavigationService) {

    this.lookupSourceService.getLookupObj('event-type').subscribe(lookup => this.eventTypeIdLookup = lookup);
    this.lookupSourceService.getLookupObj('disease-type').subscribe(lookup => this.diseaseTypeIdLookup = lookup);
  }

  refreshFormData() {
    if (!this.isExternal) {
      this.dataCachingService.removeCachedData('EditAnimalEvents', this.id.toString());
    }
    this.refreshFormDataInternal();
  }

  refreshFormDataInternal() {
    if (this.isExternal) {
      this.model = { searchResults: [] };
      return;
    }

    this.waitingOverlay.StartWaiting();

    const existing = this.dataCachingService.getCachedData('EditAnimalEvents', this.id.toString());

    if (existing) {
      this.model = existing;
      this.waitingOverlay.EndWaiting();
    } else {
      this.animalDataService.readAnimalEvents(this.id)
        .subscribe(data => {
          data.sort((a, b) => (b.date || b.datePlanning).localeCompare((a.date || a.datePlanning)));
          this.model = { searchResults: data };
          this.dataCachingService.addToCache('EditAnimalEvents', this.id.toString(), this.model);
          this.waitingOverlay.EndWaiting();
        }, error => {
          this.waitingOverlay.EndWaiting();
        });
    }
  }

  addNew() {
    this.navigationService.performAnimalEventCreation(this.id);
  }

  countsCellRenderer(row: any) {
    if (!row.data || !row.data.counts || !row.data.counts.length) {
      return '';
    }

    let html = '';

    row.data.counts.forEach(cnt => {
      html = html + cnt.caption + ': ' + (cnt.count || '0') + EditAnimalEventsComponent.renderDiff(cnt.countDiff) + '; ';
    });

    return html;
  }

  private static renderDiff(val: number): string {
    if (!val || isNaN(val)) {
      return '';
    }

    const upIcon = '<clr-icon shape="caret up" size="12" style="color:green"></clr-icon>';
    const downIcon = '<clr-icon shape="caret down" size="12" style="color:red"></clr-icon>';

    if (val < 0) {
      return ' ' + downIcon + '<span style="color:red">' + (-val) + '</span>';
    } else {
      return ' ' + upIcon + '<span style="color:green">' + val + '</span>';
    }
  }

  getRowStyle(params: any) {
    if (params.data && params.data.notExecuted) {
      return { color: 'silver' };
    }
  }
}
