import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { QueryService } from '../../../logic/services/query.service';
import { BehaviorSubject } from 'rxjs/index';
import { map } from 'rxjs/internal/operators';
import { JobRunnerUiService } from '../../../ui/infrastructure/job-runner-ui.service';
import { GroupOperationsService } from '../../../logic/services/group-operations.service';
import { DateHelper } from '../../../helpers/date-helper';
import { StringHelper } from '../../../helpers/string-helper';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../logic/services/query.service";
import * as i2 from "../../../ui/infrastructure/job-runner-ui.service";
import * as i3 from "../../../logic/services/app-navigation.service";
import * as i4 from "../../../logic/services/lookup-source.service";
var QueryRunnerUiService = /** @class */ (function () {
    function QueryRunnerUiService(queryService, jobRunnerUiService, navigationService, lookupService) {
        this.queryService = queryService;
        this.jobRunnerUiService = jobRunnerUiService;
        this.navigationService = navigationService;
        this.lookupService = lookupService;
        this.exporting = false;
        this.quering = false;
    }
    QueryRunnerUiService.getPeriodValue = function (params, colName) {
        var periodStart = Object.getOwnPropertyDescriptor(params.data, 'periodStart' + colName.substr(4));
        var periodEnd = Object.getOwnPropertyDescriptor(params.data, 'periodEnd' + colName.substr(4));
        if (!periodStart || !periodEnd) {
            return null;
        }
        return DateHelper.durationYearMonthDay(new Date(periodStart.value), new Date(periodEnd.value));
    };
    QueryRunnerUiService.prototype.runQuery = function (model, queryViewId) {
        var _this = this;
        this.runQueryOperation(model, queryViewId, function (m1, queryCustomParams, queryViewParams) { return _this.runQueryInternal(m1, queryCustomParams, queryViewId, queryViewParams); });
    };
    QueryRunnerUiService.prototype.exportQueryCsv = function (model, queryViewId) {
        var _this = this;
        if (!this.exporting) {
            this.runQueryOperation(model, queryViewId, function (m1, queryCustomParams) { return _this.exportQueryCsvInternal(m1, queryCustomParams, queryViewId); });
        }
    };
    QueryRunnerUiService.prototype.runQueryOperation = function (model, queryViewId, qOperation) {
        var _this = this;
        var customParams = this.queryService.queryContainsUserParams(model.query);
        var requireParamsObs = customParams
            ? this.navigationService.queryUserForQueryParams(model.query)
                .pipe(map(function () { return _this.queryService.mergeQueryUserParams(model.query); }))
            : new BehaviorSubject(undefined);
        requireParamsObs.subscribe(function (queryCustomParams) {
            _this.lookupService.getLookupObj('sys-query-view' + model.entityType.id.toString())
                .subscribe(function (queryViewLookup) {
                var queryView = queryViewLookup['Obj' + queryViewId];
                (queryView && queryView.runParams && queryView.runParams.length
                    ? _this.navigationService.queryUserForQueryViewParams(queryView.runParams)
                    : new BehaviorSubject(undefined))
                    .subscribe(function (queryViewParams) {
                    return qOperation(model, queryCustomParams, queryViewParams);
                });
            });
        });
    };
    QueryRunnerUiService.prototype.navigateRow = function (model, row) {
        if (model.entityType.id === 1) {
            if (row.id) {
                this.navigationService.performStableEditing(row.id);
            }
        }
        else if (model.entityType.id === 2) {
            if (row.id) {
                this.navigationService.performAnimalEditing(row.id);
            }
        }
        if (model.entityType.id === 3) {
            if (row.id) {
                this.navigationService.performAnimalEventEditing(row.id);
            }
        }
        if (model.entityType.id === 4) {
            if (row.id) {
                this.navigationService.performQuarantineEditing(row.id);
            }
        }
        if (model.entityType.id === 5) {
            if (row.id) {
                this.navigationService.performAgentEditing(row.id);
            }
        }
        if (model.entityType.id === 7) {
            if (row.id) {
                this.navigationService.performProductEditing(row.id);
            }
        }
        if (model.entityType.id === 8) {
            if (row.id) {
                this.navigationService.performDrugEditing(row.id);
            }
        }
    };
    QueryRunnerUiService.prototype.runQueryInternal = function (model, queryCustomParams, queryViewId, queryViewParams) {
        var _this = this;
        model.query.results = [];
        model.query.dataColumns = [];
        model.query.aggSumRow = [];
        this.quering = true;
        setTimeout(function () {
            _this.jobRunnerUiService.runOperation(GroupOperationsService.QueryRunner, {
                type: 'queryRunner',
                query: {
                    queryId: model.query.data.id,
                    queryViewId: queryViewId,
                    paramValues: queryCustomParams,
                    queryViewParams: queryViewParams,
                }
            }, undefined, undefined, true)
                .subscribe({
                next: function (jsonStr) {
                    var data = JSON.parse(jsonStr);
                    model.query.results = data;
                    model.query.dataColumns = [];
                    model.query.aggSumRow = [];
                    var aggRow = {};
                    if (Array.isArray(model.query.results) && model.query.results.length > 0) {
                        var firstRow_1 = model.query.results[0];
                        var viewColumns = [];
                        for (var colName in firstRow_1) {
                            if (firstRow_1.hasOwnProperty(colName) && colName.startsWith('_')) {
                                viewColumns.push(colName);
                            }
                        }
                        viewColumns.sort();
                        viewColumns.forEach(function (colName) {
                            var colNameSplit = colName.split('__');
                            var aggSum = colNameSplit.includes('aggsum');
                            var fixedParamIx = colNameSplit.findIndex(function (el) { return el.startsWith('fixed') && el.length > 5; });
                            var fixedParam = fixedParamIx >= 0 ? colNameSplit[fixedParamIx].substr(5) : null;
                            if (aggSum) {
                                var sum_1 = 0;
                                (model.query.results || []).forEach(function (row) { return sum_1 += (+row[colName] || 0); });
                                aggRow[colName] = fixedParam ? sum_1.toFixed(fixedParam) : sum_1;
                            }
                            model.query.dataColumns.push({
                                headerName: aggSum ? colNameSplit[0].substring(5) : colName.substr(5),
                                field: firstRow_1.hasOwnProperty('sorted' + colName.substr(4)) ? 'sorted' + colName.substr(4) : colName,
                                valueFormatter: function (params) {
                                    if (!params || !params.data || !params.data.hasOwnProperty(colName)) {
                                        return null;
                                    }
                                    if (!firstRow_1.hasOwnProperty('periodStart' + colName.substr(4)) &&
                                        !firstRow_1.hasOwnProperty('periodEnd' + colName.substr(4))) {
                                        return fixedParam ? (+params.value || 0).toFixed(fixedParam) : params.value;
                                    }
                                },
                                valueGetter: function (params) {
                                    if (!params || !params.data || !params.data.hasOwnProperty(colName)) {
                                        return null;
                                    }
                                    if (firstRow_1.hasOwnProperty('periodStart' + colName.substr(4)) &&
                                        firstRow_1.hasOwnProperty('periodEnd' + colName.substr(4))) {
                                        return QueryRunnerUiService.getPeriodValue(params, colName);
                                    }
                                    return Object.getOwnPropertyDescriptor(params.data, colName).value;
                                },
                                filterParams: {
                                    valueGetter: function (params) {
                                        if (!params) {
                                            return null;
                                        }
                                        if (firstRow_1.hasOwnProperty('periodStart' + colName.substr(4)) &&
                                            firstRow_1.hasOwnProperty('periodEnd' + colName.substr(4))) {
                                            return QueryRunnerUiService.getPeriodValue(params, colName);
                                        }
                                        return Object.getOwnPropertyDescriptor(params.data, colName).value;
                                    },
                                    cellRenderer: function (params) {
                                        if (!params) {
                                            return null;
                                        }
                                        if (params.value) {
                                            return '<div title="' + StringHelper.saveQuotes(params.value) + '">' + params.value + '</div>';
                                        }
                                        else {
                                            return '(Пусто)';
                                        }
                                    }
                                },
                                tooltipField: colName
                            });
                        });
                    }
                    if (Object.keys(aggRow).length) {
                        model.query.aggSumRow.push(aggRow);
                    }
                    _this.quering = false;
                },
                error: function () {
                    model.query.results = [];
                    _this.quering = false;
                }
            });
        }, 1);
    };
    QueryRunnerUiService.prototype.exportQueryCsvInternal = function (model, queryCustomParams, queryViewId) {
        var _this = this;
        this.exporting = true;
        setTimeout(function () {
            _this.jobRunnerUiService.runOperation(GroupOperationsService.QueryCsvExporter, {
                type: 'queryCsvExporter',
                query: {
                    queryId: model.query.data.id,
                    queryViewId: queryViewId,
                    paramValues: queryCustomParams
                }
            })
                .subscribe({
                next: function () {
                    _this.exporting = false;
                }, error: function () {
                    _this.exporting = false;
                }
            });
        }, 1);
    };
    QueryRunnerUiService.ngInjectableDef = i0.defineInjectable({ factory: function QueryRunnerUiService_Factory() { return new QueryRunnerUiService(i0.inject(i1.QueryService), i0.inject(i2.JobRunnerUiService), i0.inject(i3.AppNavigationService), i0.inject(i4.LookupSourceService)); }, token: QueryRunnerUiService, providedIn: "root" });
    return QueryRunnerUiService;
}());
export { QueryRunnerUiService };
