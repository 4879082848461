
    <ng-container *ngIf="viewType">
      <div class="clr-control-container clr-control-inline"style="padding-left:5px;border-left:1px solid silver" [formGroup]="viewType">
        <clr-signpost *ngIf="currentStableId" class="itech-sync-status-lbl"
                      style="margin-left:8px;margin-right:8px;margin-top:10px">
          <a class="itech-inline-link-btn itech-link" clrSignpostTrigger title="Шаблоны">
            <clr-icon shape="file-settings"></clr-icon>
          </a>
          <clr-signpost-content [clrPosition]="'right-bottom'" class="compact form itech-highlighted-sections"
                                style="max-width:21rem" *clrIfOpen>
            <h6 style="margin-top:0;font-weight:bold">Шаблоны:</h6>
            <div *ngFor="let template of templates; index as i">
              <button type="button" class="btn btn-link" style="margin:0;padding:0;min-width:20px;"
                      (click)="performToTemplate(template)" clrSignpostTrigger>
                <clr-icon shape="pencil"></clr-icon>
              </button>
              {{template.templateCaption}}
            </div>
          </clr-signpost-content>
        </clr-signpost>
        <div>
          <div class="clr-control-container clr-control-inline" style="padding-left: 5px;border-left:1px solid silver"
               [formGroup]="viewType">
            <div class="clr-radio-wrapper itech-highlight-checked">
              <input id="transactionTypeAll" type="radio" formControlName="transactionType"
                     value="1" class="clr-radio" (ngModelChange)="changeViewType()"/>
              <label for="transactionTypeAll">Все</label>
            </div>
            <ng-container *ngIf="currentStableId">
              &nbsp;&nbsp;&nbsp;
              <div class="clr-radio-wrapper itech-highlight-checked">
                <input id="transactionTypeOutgoing" type="radio" formControlName="transactionType"
                       value="2" class="clr-radio" (ngModelChange)="changeViewType()"/>
                <label for="transactionTypeOutgoing">Исходящие</label>
              </div>
              &nbsp;&nbsp;&nbsp;
              <div class="clr-radio-wrapper itech-highlight-checked">
                <input id="transactionTypeIncoming" type="radio" formControlName="transactionType"
                       value="3" class="clr-radio" (ngModelChange)="changeViewType()"/>
                <label for="transactionTypeIncoming">Входящие</label>
              </div>
            </ng-container>
            <ng-container *ngIf="!currentStableId">
              &nbsp;&nbsp;&nbsp;
              <div class="clr-radio-wrapper itech-highlight-checked">
                <input id="transactionTypeTransport" type="radio" formControlName="transactionType"
                       value="4" class="clr-radio" (ngModelChange)="changeViewType()"/>
                <label for="transactionTypeTransport">Транспортные</label>
              </div>
            </ng-container>
            &nbsp;&nbsp;&nbsp;
            <div class="clr-radio-wrapper itech-highlight-checked">
              <input id="transactionTypeProduction" type="radio" formControlName="transactionType"
                     value="5" class="clr-radio" (ngModelChange)="changeViewType()"/>
              <label for="transactionTypeProduction">Производственные</label>
            </div>
          </div>
          <div class="clr-control-container clr-control-inline" style="padding-left:5px;border-left:1px solid silver"
               [formGroup]="viewType">
            <div class="clr-radio-wrapper itech-highlight-checked">
              <input id="statusAll" type="radio" formControlName="status"
                     value="1" class="clr-radio" (ngModelChange)="changeViewType()"/>
              <label for="statusAll">Все</label>
            </div>
            &nbsp;&nbsp;&nbsp;
            <div class="clr-radio-wrapper itech-highlight-checked">
              <input id="statusIssued" type="radio" formControlName="status"
                     value="2" class="clr-radio" (ngModelChange)="changeViewType()"/>
              <label for="statusIssued">Оформленные</label>
            </div>
            &nbsp;&nbsp;&nbsp;
            <div class="clr-radio-wrapper itech-highlight-checked">
              <input id="statusNotIssued" type="radio" formControlName="status"
                     value="6" class="clr-radio" (ngModelChange)="changeViewType()"/>
              <label for="statusNotIssued">Неоформленные</label>
            </div>
            &nbsp;&nbsp;&nbsp;
            <div class="clr-radio-wrapper itech-highlight-checked">
              <input id="statusDeleted" type="radio" formControlName="status"
                     value="3" class="clr-radio" (ngModelChange)="changeViewType()"/>
              <label for="statusDeleted">Аннулированные</label>
            </div>
            &nbsp;&nbsp;&nbsp;
            <div class="clr-radio-wrapper itech-highlight-checked">
              <input id="statusCleared" type="radio" formControlName="status"
                     value="4" class="clr-radio" (ngModelChange)="changeViewType()"/>
              <label for="statusCleared">{{+viewType.get('transactionType').value === 5 ? 'Израсходованные' : 'Погашенные'}}</label>
            </div>
            &nbsp;&nbsp;&nbsp;
            <div class="clr-radio-wrapper itech-highlight-checked">
              <input id="statusSync" type="radio" formControlName="status"
                     value="5" class="clr-radio" (ngModelChange)="changeViewType()"/>
              <label for="statusSync">На синхронизации</label>
            </div>
            &nbsp;&nbsp;&nbsp;
            <div class="clr-radio-wrapper itech-highlight-checked">
              <input id="statusRequest" type="radio" formControlName="status"
                     value="7" class="clr-radio" (ngModelChange)="changeViewType()"/>
              <label for="statusRequest">Заявки</label>
            </div>
          </div>
        </div>
      </div>
      <div style="position:absolute;bottom:0;top:50px;left:0;right:0">
        <app-grid style="width: 100%; height: 100%" [pagination]="false" [getRowStyle]="getRowStyle"
                  [uniqueGridName]="uniqueGridName" [rowData]="filteredData" [columnDefs]="columnDefs"
                  [masterDetail]="true" [detailRowHeight]="'450'" [icons]="gridIcons" [exportFileName]="exportFileName"
                  [detailCellRenderer]="detailCellRenderer" [frameworkComponents]="frameworkComponents"
                  [rowMultiSelectWithClick]="true" [rowSelection]="'multiple'"
                  (rowDoubleClicked)="rowDoubleClicked.emit($event)"
                  (selectionChanged)="selectionChanged.emit($event)"
                  (modelUpdated)="modelUpdated.emit($event)"></app-grid>
      </div>
    </ng-container>