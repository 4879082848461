import { JobRunnerUiService } from '../infrastructure/job-runner-ui.service';
var JobRunnerModalComponent = /** @class */ (function () {
    function JobRunnerModalComponent(jobRunnerUiService) {
        this.jobRunnerUiService = jobRunnerUiService;
    }
    Object.defineProperty(JobRunnerModalComponent.prototype, "model", {
        get: function () {
            return this.jobRunnerUiService.model;
        },
        enumerable: true,
        configurable: true
    });
    return JobRunnerModalComponent;
}());
export { JobRunnerModalComponent };
