/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@clr/angular";
import * as i2 from "../../ui/controls/app-grid.component.ngfactory";
import * as i3 from "../../ui/controls/app-grid.component";
import * as i4 from "../../logic/services/data-caching.service";
import * as i5 from "../../logic/services/security.service";
import * as i6 from "./product-statistic.component";
import * as i7 from "../../logic/services/metadata.service";
import * as i8 from "../../ui/infrastructure/global-waiting-overlay.service";
import * as i9 from "../../logic/services/bookmark.service";
var styles_ProductStatisticComponent = [];
var RenderType_ProductStatisticComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProductStatisticComponent, data: {} });
export { RenderType_ProductStatisticComponent as RenderType_ProductStatisticComponent };
export function View_ProductStatisticComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "div", [["class", "itech-common-wrap no-pager"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [["style", "display: flex; flex-direction: column;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "itech-content-header itech-no-margin-bottom"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "clr-icon", [["shape", "view-list"], ["size", "36"]], null, null, null, null, null)), i0.ɵdid(4, 16384, null, 0, i1.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, ["\u00A0\u0421\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043A\u0430 \u0412\u0421\u0414 "])), (_l()(), i0.ɵeld(6, 0, null, null, 2, "div", [["style", "padding: 5px; height: 88%"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "app-grid", [], null, null, null, i2.View_AppGridComponent_0, i2.RenderType_AppGridComponent)), i0.ɵdid(8, 114688, null, 0, i3.AppGridComponent, [i4.DataCachingService, i5.SecurityService], { uniqueGridName: [0, "uniqueGridName"], columnDefs: [1, "columnDefs"], rowData: [2, "rowData"], exportFileName: [3, "exportFileName"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 5, "div", [["class", "itech-form-actions"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 3, "button", [["class", "btn btn-link btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.refreshResults() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "clr-icon", [["class", "has-badge"], ["shape", "refresh"]], null, null, null, null, null)), i0.ɵdid(13, 16384, null, 0, i1.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, ["\u00A0\u041E\u0431\u043D\u043E\u0432\u0438\u0442\u044C \u0441\u043F\u0438\u0441\u043E\u043A "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Statistic"; var currVal_1 = _co.gridColumnDefs; var currVal_2 = _co.model; var currVal_3 = "\u0421\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043A\u0430 \u0412\u0421\u0414"; _ck(_v, 8, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_ProductStatisticComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-product-statistic", [], null, null, null, View_ProductStatisticComponent_0, RenderType_ProductStatisticComponent)), i0.ɵdid(1, 114688, null, 0, i6.ProductStatisticComponent, [i7.MetadataService, i4.DataCachingService, i8.GlobalWaitingOverlayService, i9.BookmarkService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProductStatisticComponentNgFactory = i0.ɵccf("app-product-statistic", i6.ProductStatisticComponent, View_ProductStatisticComponent_Host_0, {}, {}, []);
export { ProductStatisticComponentNgFactory as ProductStatisticComponentNgFactory };
