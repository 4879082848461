<div class="itech-search-table-with-filter" *ngIf="securityService.isAccess('animal') | async">
  <div style="display: flex; flex-direction: row;">
    <clr-icon shape="search" size="72"></clr-icon>
    <div class="compact form itech-highlighted-sections" [formGroup]="model.filterFormGroup"
         *ngIf="model && model.filterFormGroup">
      <section class="form-block itech-block-medium">
        <h3>Поиск животных</h3>
        <div class="form-group">
          <label for="caption">Наименование</label>
          <label for="caption" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                 [class.invalid]="isInvalid(model.filterFormGroup, 'caption')">
            <app-input-text [contextId]="'caption'" [classNames]="'itech-control-medium'"
                            [formControlObj]="model.filterFormGroup.get('caption')" [htmlType]="1"
                            [placeholder]="'Наименование/Бирка'" (keyDownEmitter)="filterEnterPressed($event)">
            </app-input-text>
            <app-validation-tooltip [input]="model.filterFormGroup.controls['caption']"></app-validation-tooltip>
          </label>
          <label *ngIf="model.filterFormGroup.get('availableShowExternal').value; else onlyDeleted"
                 style="display:flex;align-items:center"
                 [style.width.rem]="!model.filterFormGroup.get('toggleConfirmedOrExternal').value ? 8 : undefined"
                 [style.max-width.rem]="!model.filterFormGroup.get('toggleConfirmedOrExternal').value ? 8 : undefined">
            <button class="btn btn-icon-link" style="margin:0 10px 0 0;padding:0;max-height:20px;min-width:20px;border:none"
                    (click)="changeToggleConfirmedOrExternal()">
              <clr-icon shape="switch" style="display:block" class="has-badge"></clr-icon>
            </button>
            <div *ngIf="model.filterFormGroup.get('toggleConfirmedOrExternal').value; else toggleExternal"
                 class="checkbox-inline" style="margin-top:0">
              <input type="checkbox" id="showDeletedToggle" formControlName="showDeleted" (change)="refreshResults()">
              <label for="showDeletedToggle">Показывать выбывших</label>
            </div>
            <ng-template #toggleExternal>
              <div class="checkbox-inline" style="margin-top:0">
                <input type="checkbox" id="showExternal" formControlName="showExternal" (change)="refreshResults()">
                <label for="showExternal">Неподтверждённые</label>
              </div>
            </ng-template>
          </label>
          <ng-template #onlyDeleted>
            <div class="checkbox-inline">
              <input type="checkbox" id="showDeleted" formControlName="showDeleted" (change)="refreshResults()">
              <label for="showDeleted">Показывать выбывших</label>
            </div>
          </ng-template>
        </div>
        <div class="form-group">
          <label for="address">Адрес / Кадастр. номер</label>
          <label for="address" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                 [class.invalid]="isInvalid(model.filterFormGroup, 'address')">
            <app-input-text [contextId]="'address'" [classNames]="'itech-control-medium'"
                            [formControlObj]="model.filterFormGroup.get('address')" [htmlType]="1"
                            [placeholder]="'Адрес'" (keyDownEmitter)="filterEnterPressed($event)">
            </app-input-text>
            <app-validation-tooltip [input]="model.filterFormGroup.controls['address']"></app-validation-tooltip>
          </label>
          <label for="cadNo" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                 [class.invalid]="isInvalid(model.filterFormGroup, 'cadNo')">
            <app-input-text [contextId]="'cadNo'" [classNames]="'itech-control-medium'"
                            [formControlObj]="model.filterFormGroup.get('cadNo')" [htmlType]="1"
                            [placeholder]="'Кадастровый номер'" (keyDownEmitter)="filterEnterPressed($event)">
            </app-input-text>
            <app-validation-tooltip [input]="model.filterFormGroup.controls['cadNo']"></app-validation-tooltip>
          </label>
        </div>
        <div class="form-group" *ngIf="!model.filterFormGroup.controls['agentId'].value">
          <label for="owner">Владелец</label>
          <label for="owner" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                 [class.invalid]="isInvalid(model.filterFormGroup, 'owner')">
            <app-input-text [contextId]="'owner'" [classNames]="'itech-control-xlarge'"
                            [formControlObj]="model.filterFormGroup.get('owner')" [htmlType]="1"
                            [placeholder]="'Владелец'" (keyDownEmitter)="filterEnterPressed($event)">
            </app-input-text>
            <app-validation-tooltip [input]="model.filterFormGroup.controls['owner']"></app-validation-tooltip>
          </label>
        </div>
        <div class="form-group" *ngIf="model.filterFormGroup.controls['agentId'].value">
          <label>Владелец</label>
          <div class="itech-text-field itech-text-field-no-wrap" style="width:476px;max-width:476px"
               title="{{model.filterFormGroup.controls['agentId'].value | lookup: 'agent' | async}}">
            {{model.filterFormGroup.controls['agentId'].value | lookup: 'agent' | async}}
          </div>
          &nbsp;&nbsp;
          <button type="button" class="btn btn-danger btn-link itech-inline-tool-btn" title="Указать другого владельца"
                  (click)="model.filterFormGroup.controls['agentId'].setValue(undefined)">
            <clr-icon shape="remove"></clr-icon>
          </button>
        </div>
        <div class="form-group" *ngIf="model.filterFormGroup.controls['stableId'].value">
          <label>Местонахождение</label>
          <div class="itech-text-field itech-text-field-no-wrap" style="width:476px;max-width:476px"
               title="{{model.filterFormGroup.controls['stableId'].value | lookup: 'stable' | async}}">
            {{model.filterFormGroup.controls['stableId'].value | lookup: 'stable' | async}}
          </div>
          &nbsp;&nbsp;
          <button type="button" class="btn btn-danger btn-link itech-inline-tool-btn" title="Указать другое местонахождение"
                  (click)="model.filterFormGroup.controls['stableId'].setValue(undefined)">
            <clr-icon shape="remove"></clr-icon>
          </button>
        </div>
        <div class="form-group">
          <label for="animalTypeId">Вид / подвид</label>
          <app-combo-lookup [validationTooltipRight]="true" class="itech-control-medium"
                            [contextControlId]="'animalTypeId'" [sorted]="true"
                            [contextControlName]="'animalTypeId'"
                            [lookupName]="'animal-type'" [contextFormGroup]="model.filterFormGroup"></app-combo-lookup>
          &nbsp;&nbsp;
          <app-combo-lookup [validationTooltipRight]="true" class="itech-control-medium"
                            [contextControlId]="'animalSubtypeId'" [sorted]="true"
                            [contextControlName]="'animalSubtypeId'"
                            [disabled]="!model.filterFormGroup.get('animalTypeId').value"
                            [lookupName]="'animal-subtype' + (model.filterFormGroup.get('animalTypeId').value || 0)"
                            [contextFormGroup]="model.filterFormGroup"></app-combo-lookup>
        </div>
        <div class="form-group itech-group-whole-line">
          <button class="btn btn-primary" (click)="refreshResults()">
            <clr-icon shape="search" class="has-badge"></clr-icon>&nbsp;Поиск
          </button>
        </div>
      </section>
    </div>
  </div>

  <div [style.top.px]="model.filterFormGroup.controls['stableId'].value ? 242 : 220">
    <app-grid [pagination]="false" [getRowStyle]="getRowStyle"
              (gridReady)="onGridReady($event)" [rowData]="model.searchResults" [columnDefs]="gridColumnDefs"
              [rowMultiSelectWithClick]="addManyAnimals" [rowSelection]="addManyAnimals? 'multiple': undefined"
              (rowClicked)="rowClicked.emit($event.data)" (selectionChanged)="onGridSelectionChanged($event)"
              [uniqueGridName]="'AnimalSearchResultGrid'" [exportFileName]="'Список животных'"></app-grid>
  </div>
</div>
