import { Router, NavigationStart } from '@angular/router';
import { Subject, BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
var AlertService = /** @class */ (function () {
    function AlertService(router, toastr) {
        var _this = this;
        this.router = router;
        this.toastr = toastr;
        this.subject = new Subject();
        this.keepAfterNavigationChange = false;
        this.confirmModalMsg = '';
        this.confirmModalAcceptButtonText = '';
        this.confirmModalOpened = false;
        this._activeHttpRequests = 0;
        this._activeHttpRequestsObs = new BehaviorSubject(0);
        // clear alert message on route change
        router.events.subscribe(function (event) {
            if (event instanceof NavigationStart) {
                if (_this.keepAfterNavigationChange) {
                    // only keep for a single location change
                    _this.keepAfterNavigationChange = false;
                }
                else {
                    // clear alert
                    _this.subject.next();
                }
            }
        });
    }
    Object.defineProperty(AlertService.prototype, "activeHttpRequests", {
        get: function () {
            return this._activeHttpRequestsObs;
        },
        enumerable: true,
        configurable: true
    });
    AlertService.prototype.incrementHttpRequests = function () {
        var _this = this;
        this._activeHttpRequests++;
        setTimeout(function () {
            if (_this._activeHttpRequestsObs.getValue() !== _this._activeHttpRequests) {
                _this._activeHttpRequestsObs.next(_this._activeHttpRequests);
            }
        }, 500);
    };
    AlertService.prototype.decrementHttpRequests = function () {
        var _this = this;
        this._activeHttpRequests--;
        setTimeout(function () {
            if (_this._activeHttpRequestsObs.getValue() !== _this._activeHttpRequests) {
                _this._activeHttpRequestsObs.next(_this._activeHttpRequests);
            }
        }, 500);
    };
    AlertService.prototype.extractMessageToShow = function (message) {
        if (message) {
            return message.message || message;
        }
        else {
            return 'Операция завершена без дополнительных уведомлений';
        }
    };
    AlertService.prototype.success = function (message, keepAfterNavigationChange) {
        if (keepAfterNavigationChange === void 0) { keepAfterNavigationChange = false; }
        this.toastr.success(this.extractMessageToShow(message));
    };
    AlertService.prototype.info = function (message, keepAfterNavigationChange) {
        if (keepAfterNavigationChange === void 0) { keepAfterNavigationChange = false; }
        this.toastr.info(this.extractMessageToShow(message));
    };
    AlertService.prototype.error = function (message, keepAfterNavigationChange) {
        if (keepAfterNavigationChange === void 0) { keepAfterNavigationChange = false; }
        this.toastr.error(this.extractMessageToShow(message));
    };
    AlertService.prototype.warning = function (message, keepAfterNavigationChange) {
        if (keepAfterNavigationChange === void 0) { keepAfterNavigationChange = false; }
        this.toastr.warning(this.extractMessageToShow(message));
    };
    AlertService.prototype.confirmModal = function (msg, acceptButtonText, condition) {
        var _this = this;
        if (acceptButtonText === void 0) { acceptButtonText = 'Да'; }
        if (condition === void 0) { condition = true; }
        this.confirmModalAcceptPromise = new Subject();
        if (condition) {
            this.confirmModalMsg = msg;
            this.confirmModalAcceptButtonText = acceptButtonText;
            this.confirmModalOpened = true;
        }
        else {
            setTimeout(function () {
                _this.confirmModalAcceptPromise.next(true);
            }, 1);
        }
        return this.confirmModalAcceptPromise.asObservable();
    };
    return AlertService;
}());
export { AlertService };
