<div class="itech-common-wrap no-pager">
  <div>
    <div *ngIf="model" class="itech-content-header itech-no-margin-bottom itech-small">
      <clr-icon shape="bolt" size="24"></clr-icon>&nbsp;{{model.query.data.caption}}
    </div>
    <div style="padding: 5px; height: 95%">
      <app-grid *ngIf="model && model.query && model.query.results"
                [pagination]="true" [paginationAutoPageSize]="true" [rowData]="model.query.results"
                (rowClicked)="queryRunnerUi.navigateRow(model, $event.data)" (gridReady)="agGridReady($event)"
                [columnDefs]="model.query.dataColumns" [uniqueGridName]="'QueryStandaloneResults'"
                [pinnedTopRowData]="model.query.aggSumRow"
                [exportFileName]="'Результаты выборки'"></app-grid>
    </div>
  </div>

  <div class="itech-form-actions">
    <button class="btn btn-link btn-primary" (click)="refreshFormData()" [clrLoading]="queryRunnerUi.quering">
      <clr-icon shape="refresh" class="has-badge"></clr-icon>&nbsp;Обновить</button>
  </div>
</div>
