/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@clr/angular/clr-angular.ngfactory";
import * as i2 from "@clr/angular";
import * as i3 from "./edit-animal-event-common.component.ngfactory";
import * as i4 from "./edit-animal-event-common.component";
import * as i5 from "../../../logic/services/bookmark.service";
import * as i6 from "../../../logic/services/animal-data.service";
import * as i7 from "../../../ui/infrastructure/alert.service";
import * as i8 from "../../../logic/services/address-person-fio-cache.service";
import * as i9 from "../../../logic/services/lookup-source.service";
import * as i10 from "../../../logic/services/data-caching.service";
import * as i11 from "../../../ui/infrastructure/global-waiting-overlay.service";
import * as i12 from "@angular/forms";
import * as i13 from "../../../logic/services/app-navigation.service";
import * as i14 from "./edit-animal-event.component";
import * as i15 from "@angular/router";
var styles_EditAnimalEventComponent = [];
var RenderType_EditAnimalEventComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EditAnimalEventComponent, data: {} });
export { RenderType_EditAnimalEventComponent as RenderType_EditAnimalEventComponent };
export function View_EditAnimalEventComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 23, "clr-tabs", [], null, null, null, i1.View_ClrTabs_0, i1.RenderType_ClrTabs)), i0.ɵprd(512, null, i2.ɵz, i2.ɵz, []), i0.ɵprd(512, null, i2.ɵd, i2.ɵd, []), i0.ɵprd(512, null, i2.ɵer, i2.ɵer, []), i0.ɵprd(1024, null, i2.ɵes, i2.ɵet, []), i0.ɵdid(5, 1228800, null, 1, i2.ClrTabs, [i2.ɵz, i2.ɵd, i2.ɵer, i2.ɵes, i2.ClrCommonStringsService, i0.PLATFORM_ID], null, null), i0.ɵqud(603979776, 1, { tabs: 1 }), i0.ɵdid(7, 8404992, null, 0, i2.ɵev, [], null, null), (_l()(), i0.ɵeld(8, 0, null, null, 15, "clr-tab", [], null, null, null, i1.View_ClrTab_0, i1.RenderType_ClrTab)), i0.ɵprd(1024, null, i2.ɵw, i2.ɵx, []), i0.ɵdid(10, 180224, [[1, 4]], 2, i2.ClrTab, [i2.ɵz, i2.ɵw, i2.ɵer], null, null), i0.ɵqud(335544320, 2, { tabLink: 0 }), i0.ɵqud(335544320, 3, { tabContent: 0 }), i0.ɵprd(512, null, i2.ɵeq, i2.ɵeq, []), (_l()(), i0.ɵeld(14, 16777216, null, 0, 3, "button", [["clrTabLink", ""], ["role", "tab"], ["type", "button"]], [[8, "id", 0], [1, "aria-selected", 0], [1, "aria-hidden", 0], [1, "aria-controls", 0], [2, "btn", null], [2, "btn-link", null], [2, "nav-link", null], [2, "nav-item", null], [2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 15).activate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(15, 16384, [[2, 4]], 0, i2.ClrTabLink, [i2.ɵz, i2.ɵw, i2.ɵeq, i0.ElementRef, i0.ComponentFactoryResolver, i0.ViewContainerRef, i2.ɵes], null, null), i0.ɵdid(16, 2244608, null, 0, i2.ɵew, [i0.ChangeDetectorRef, [2, i2.ɵev], i2.ɵw, i2.ɵz], null, null), (_l()(), i0.ɵted(-1, null, ["\u041C\u0435\u0440\u043E\u043F\u0440\u0438\u044F\u0442\u0438\u0435"])), (_l()(), i0.ɵeld(18, 0, null, 0, 5, "clr-tab-content", [], null, null, null, i1.View_ClrTabContent_0, i1.RenderType_ClrTabContent)), i0.ɵdid(19, 180224, [[3, 4]], 0, i2.ClrTabContent, [i2.ɵz, i2.ɵw, i2.ɵeq, i2.ɵer], null, null), i0.ɵdid(20, 2244608, null, 0, i2.ɵew, [i0.ChangeDetectorRef, [2, i2.ɵev], i2.ɵw, i2.ɵz], null, null), (_l()(), i0.ɵeld(21, 0, null, 0, 2, "div", [["class", "edit-page-tab"]], null, null, null, null, null)), (_l()(), i0.ɵeld(22, 0, null, null, 1, "app-edit-animal-event-common", [], null, null, null, i3.View_EditAnimalEventCommonComponent_0, i3.RenderType_EditAnimalEventCommonComponent)), i0.ɵdid(23, 573440, null, 0, i4.EditAnimalEventCommonComponent, [i5.BookmarkService, i6.AnimalDataService, i7.AlertService, i8.AddressPersonFioCacheService, i9.LookupSourceService, i10.DataCachingService, i11.GlobalWaitingOverlayService, i12.FormBuilder, i13.AppNavigationService], { id: [0, "id"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_9 = _co.id; _ck(_v, 23, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 15).tabLinkId; var currVal_1 = i0.ɵnov(_v, 15).active; var currVal_2 = false; var currVal_3 = i0.ɵnov(_v, 15).ariaControls; var currVal_4 = true; var currVal_5 = !i0.ɵnov(_v, 15).inOverflow; var currVal_6 = !i0.ɵnov(_v, 15).inOverflow; var currVal_7 = !i0.ɵnov(_v, 15).inOverflow; var currVal_8 = i0.ɵnov(_v, 15).active; _ck(_v, 14, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_EditAnimalEventComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_EditAnimalEventComponent_0, RenderType_EditAnimalEventComponent)), i0.ɵdid(1, 114688, null, 0, i14.EditAnimalEventComponent, [i15.ActivatedRoute, i5.BookmarkService, i10.DataCachingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EditAnimalEventComponentNgFactory = i0.ɵccf("ng-component", i14.EditAnimalEventComponent, View_EditAnimalEventComponent_Host_0, { model: "model", id: "id" }, {}, []);
export { EditAnimalEventComponentNgFactory as EditAnimalEventComponentNgFactory };
