<div class="itech-common-wrap no-pager">
  <div class="itech-v-header-content not-scrolled">
    <div class="itech-content-header itech-no-margin-bottom">
      <clr-icon shape="file-group" size="36"></clr-icon>&nbsp;{{getMetaTitle()}}
    </div>

    <div style="padding: 5px; height: 90%">
      <app-grid [rowData]="_searchResults" (rowClicked)="editRow($event.data)" [uniqueGridName]="getMetaName()"
                [columnDefs]="gridColumnDefs" [exportFileName]="getMetaTitle()"></app-grid>
    </div>
  </div>
  <div class="itech-form-actions">
    <div>
      <button class="btn btn-link btn-primary" (click)="refreshResults()">
        <clr-icon shape="refresh" class="has-badge"></clr-icon>&nbsp;Обновить список
      </button>
      <button class="btn btn-link btn-primary"
              (click)="editRow({branchedByInstitution: false, branchedByYear: false, branchedByMonth: false, branchedByDay: false})">
        <clr-icon shape="plus" has-badge="true"></clr-icon>&nbsp;Добавить новую запись
      </button>
    </div>
  </div>
</div>


<clr-modal [(clrModalOpen)]="editModalOpened" *ngIf="editModalOpened" [clrModalClosable]="false">
  <h3 class="modal-title">Редактирование подвида
    <span *ngIf="currentIsNew" style="color:green"> [Новая]</span></h3>
  <div class="modal-body">
    <div class="form compact" [formGroup]="currentFormGroup">
      <section class="form-block">

        <div class="form-group">
          <label for="id" class="required">Ид</label>
          <label for="id" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                 [class.invalid]="isInvalid(currentFormGroup, 'id')">
            <input type="text" id="id" placeholder="Ид" class="itech-control-m-large"
                   formControlName="id">
            <app-validation-tooltip [input]="currentFormGroup.controls['id']"></app-validation-tooltip>
          </label>
        </div>

        <div class="form-group">
          <label for="caption" class="required">Наименование</label>
          <label for="caption" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                 [class.invalid]="isInvalid(currentFormGroup, 'caption')">
            <input type="text" id="caption" placeholder="Наименование" class="itech-control-m-large" maxlength="255"
                   formControlName="caption">
            <app-validation-tooltip [input]="currentFormGroup.controls['caption']"></app-validation-tooltip>
          </label>
        </div>

        <div class="form-group">
          <label for="groupCaption">Группа</label>
          <label for="groupCaption" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md tooltip-top-left"
                 [class.invalid]="isInvalid(currentFormGroup, 'groupCaption')">
            <input type="text" id="groupCaption" class="itech-control-m-large" maxlength="255"
                   formControlName="groupCaption">
            <app-validation-tooltip [input]="currentFormGroup.controls['groupCaption']"></app-validation-tooltip>
          </label>
        </div>

        <div class="form-group">
          <div class="checkbox-inline">
            <input type="checkbox" id="isDefault" formControlName="isDefault">
            <label for="isDefault">По умолчанию</label>
          </div>
        </div>

      </section>
    </div>
  </div>
  <div class="modal-footer itech-form-actions">
    <div>
      <button *ngIf="!currentIsNew" type="button" class="btn btn-warning-outline"
              (click)="delete(currentFormGroup.value)">
        <clr-icon shape="trash"></clr-icon>&nbsp;Удалить
      </button>
    </div>
    <div>
      <button type="button" class="btn btn-primary" (click)="addEditCompleted()">
        <clr-icon shape="check"></clr-icon>&nbsp;Сохранить
      </button>
      <button type="button" class="btn btn-warning" (click)="editModalOpened = false">
        <clr-icon shape="undo"></clr-icon>&nbsp;Отмена
      </button>
    </div>
  </div>
</clr-modal>
