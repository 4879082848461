import { ChangeDetectorRef, ElementRef, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { StringHelper } from '../../../helpers/string-helper';
import { FormHelper } from '../../../ui/controls/form-helper';
import { PstReportStructureService } from '../../services/pst-report-structure.service';
var PstBranchCellDateEditComponent = /** @class */ (function () {
    function PstBranchCellDateEditComponent(lookupSourceService, navigationService, changeDetectorRef, pstReportStructureService) {
        this.lookupSourceService = lookupSourceService;
        this.navigationService = navigationService;
        this.changeDetectorRef = changeDetectorRef;
        this.pstReportStructureService = pstReportStructureService;
        this.changed = new EventEmitter();
        this.processMoneyKeypress = FormHelper.processMoneyKeypressDot;
        this.valueStr = '';
        this.valueLoaded = false;
        this.valueUpdated = false;
    }
    Object.defineProperty(PstBranchCellDateEditComponent.prototype, "proposedCellValue", {
        get: function () {
            return this.model.__cl_editingProposedValue;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PstBranchCellDateEditComponent.prototype, "cellValueDisplay", {
        get: function () {
            return StringHelper.getRuDate(this.originalCellValue);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PstBranchCellDateEditComponent.prototype, "cellValueStr", {
        get: function () {
            if (!this.valueLoaded) {
                var value = this.originalCellValue;
                if (this.proposedCellValue || this.proposedCellValue === 0) {
                    this.valueUpdated = true;
                    value = this.proposedCellValue;
                }
                if (!value) {
                    this.valueStr = '';
                }
                else {
                    this.valueStr = StringHelper.getRuDate(value);
                }
                this.valueLoaded = true;
            }
            return this.valueStr;
        },
        set: function (strValue) {
            this.valueUpdated = true;
            this.valueStr = strValue;
        },
        enumerable: true,
        configurable: true
    });
    PstBranchCellDateEditComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.hasOwnProperty('editing')) {
            if (this.editing) {
                setTimeout(function () {
                    if (_this.inputControl) {
                        _this.inputControl.nativeElement.focus();
                    }
                }, 100);
            }
            if (!this.editing && this.valueUpdated) {
                setTimeout(function () {
                    _this.updateCellValueInModel();
                    _this.valueLoaded = false;
                    _this.valueUpdated = false;
                }, 100);
            }
        }
    };
    PstBranchCellDateEditComponent.prototype.inputKeyDown = function ($event) {
        if ($event.key === 'Enter') {
            // заканчиваем редактирование с записью измененного значения
            this.model.__cl_editingBranch = undefined;
            this.inputControl.nativeElement.closest('table').focus();
        }
        if ($event.key === 'Escape') {
            // заканчиваем редактирование без записи измененного значения
            this.valueLoaded = false;
            this.valueUpdated = false;
            this.model.__cl_editingBranch = undefined;
            this.inputControl.nativeElement.closest('table').focus();
        }
    };
    PstBranchCellDateEditComponent.prototype.updateCellValueInModel = function () {
        var strValue = this.valueStr;
        strValue = (strValue || '').replace(/[^\d.,]/g, '')
            .replace(/[,]/g, '.');
        var parsedVal;
        if (strValue) {
            parsedVal = StringHelper.getISODate(StringHelper.parseRussianDate(strValue));
        }
        if (this.changed) {
            this.changed.emit(parsedVal);
        }
    };
    return PstBranchCellDateEditComponent;
}());
export { PstBranchCellDateEditComponent };
