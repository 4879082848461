<section class="form-block itech-block-large" [formGroup]="contextFormGroup">
    <div>
    <app-edit-quarantine-stages-file [label]="'Подтверждение проведения качества заключительной дезинфекции'"
                                     [contextFormGroup]="contextFormGroup" [controlName]="'confirmOfQualityFinalDisinfection'"
                                     (dirtyFormGroup)="markAsDirty()" [id]="id" [isAccessEdit]="isAccessEdit"
                                     [serverSideErrorsProvider]="serverSideErrorsProvider" [tooltipPosition]="'bottom-right'">
    </app-edit-quarantine-stages-file>
    <app-edit-quarantine-stages-file [label]="'Акт заключительного обследования'"
                                     [contextFormGroup]="contextFormGroup" [controlName]="'actFinalExamination'"
                                     (dirtyFormGroup)="markAsDirty()" [id]="id" [isAccessEdit]="isAccessEdit"
                                     [serverSideErrorsProvider]="serverSideErrorsProvider">
    </app-edit-quarantine-stages-file>
    <app-edit-quarantine-stages-file [label]="'Представление на отмену ограничительных мероприятий'"
                                     [contextFormGroup]="contextFormGroup" [controlName]="'submissionCancel'"
                                     (dirtyFormGroup)="markAsDirty()" [id]="id" [isAccessEdit]="isAccessEdit"
                                     [serverSideErrorsProvider]="serverSideErrorsProvider">
    </app-edit-quarantine-stages-file>
    <app-edit-quarantine-stages-file [label]="'Представление на отмену ограничительных мероприятий в случае особо опасного заболевания'"
                                     [contextFormGroup]="contextFormGroup" [controlName]="'submissionCancelHighlyDangerous'"
                                     (dirtyFormGroup)="markAsDirty()" [id]="id" [isAccessEdit]="isAccessEdit"
                                     [withoutNumber]="true" [withoutDate]="true" [marginBottom]="40"
                                     [serverSideErrorsProvider]="serverSideErrorsProvider">
    </app-edit-quarantine-stages-file>
    <app-edit-quarantine-stages-file [label]="'НПА по отмене ограничительных мероприятий'"
                                     [contextFormGroup]="contextFormGroup" [controlName]="'orderCancel'"
                                     (dirtyFormGroup)="markAsDirty()" [id]="id" [isAccessEdit]="isAccessEdit"
                                     [serverSideErrorsProvider]="serverSideErrorsProvider">
    </app-edit-quarantine-stages-file>
    <app-edit-quarantine-stages-file [label]="'Отчетность по событию мероприятий после карантина'"
                                     [contextFormGroup]="contextFormGroup" [controlName]="'reportEventsAfterCancel'"
                                     (dirtyFormGroup)="markAsDirty()" [id]="id" [isAccessEdit]="isAccessEdit"
                                     [serverSideErrorsProvider]="serverSideErrorsProvider">
    </app-edit-quarantine-stages-file>
    <app-edit-quarantine-decision-next-stage [title]="'Решение о закрытии карантина'"
                                             [stageId]="statusCancel" [contextFormGroup]="contextFormGroup"
                                             [serverSideErrorsProvider]="serverSideErrorsProvider"
                                             (needUpdateQuarantine)="needUpdateQuarantine.next()" [quarantineId]="id"
                                             [quarantineCurrentStatusId]="currentStatusId"></app-edit-quarantine-decision-next-stage>
  </div>
</section>
