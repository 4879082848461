import { DataCachingService } from '../../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { StringHelper } from '../../../helpers/string-helper';
import { DrugDataService } from '../../../logic/services/drug-data.service';
import { FormBuilder, Validators } from '@angular/forms';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { combineLatest, of } from 'rxjs';
import { EditDrugCommonFormComponent } from '../common/edit-drug-common-form.component';
import { map } from 'rxjs/operators';
var EditDrugSpendingComponent = /** @class */ (function () {
    function EditDrugSpendingComponent(drugDataService, dataCachingService, waitingOverlay, navigationService, personFioCacheService, lookupSourceService, alertService, fb) {
        var _this = this;
        this.drugDataService = drugDataService;
        this.dataCachingService = dataCachingService;
        this.waitingOverlay = waitingOverlay;
        this.navigationService = navigationService;
        this.personFioCacheService = personFioCacheService;
        this.lookupSourceService = lookupSourceService;
        this.alertService = alertService;
        this.fb = fb;
        this.model = {};
        this.isInvalid = FormHelper.isInvalid;
        this.changeDecimalStrValue = EditDrugCommonFormComponent.changeDecimalStrValue;
        this.writeoffModalOpened = false;
        this.moveModalOpened = false;
        this.processMoneyKeypress = FormHelper.processMoneyKeypress;
        this.gridColumnDefs = [
            {
                headerName: 'Дата', field: 'date', width: 120,
                valueFormatter: function (params) { return StringHelper.getRuDate(params.value); },
            },
            {
                headerName: 'Объект списания', field: 'caption', width: 400,
            },
            {
                headerName: 'Целевое назначение', field: 'reason', width: 250,
            },
            {
                headerName: 'Количество', field: 'spentValue', width: 110,
                valueFormatter: function (params) { return params.value + ' ' + _this.model.unitTypeShortName; }
            },
            {
                headerName: 'Цена за ед.', field: 'cost', width: 110,
                valueFormatter: function (params) { return params.value; }
            },
            {
                headerName: 'Сумма', field: 'sum', width: 110,
                valueFormatter: function (params) { return params.value; }
            },
            {
                headerName: 'Кто списал', field: 'createdUserCaption', width: 180,
                valueFormatter: function (params) {
                    if (params.data) {
                        return params.data.createdTime
                            ? params.data.createdUserCaption + ', ' + StringHelper.getRuTimeWithSeconds(params.data.createdTime)
                            : params.data.createdUserCaption || 'Н/Д';
                    }
                    else {
                        return params.value;
                    }
                },
            },
            {
                headerName: 'Доступные действия',
                onCellClicked: function (params) { return !params.data.destinationDrugId && !params.data.animalEventId
                    ? _this.deleteWriteoff(_this.id, params.data.date)
                    : undefined; },
                cellRenderer: function (params) { return !params.data.destinationDrugId && !params.data.animalEventId
                    ? '<a class="in-table-link">Удалить</a>'
                    : ''; }
            },
        ];
    }
    Object.defineProperty(EditDrugSpendingComponent.prototype, "id", {
        get: function () {
            return this._id;
        },
        set: function (id) {
            if (this._id !== id) {
                this._id = id;
                this.refreshFormDataInternal();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditDrugSpendingComponent.prototype, "outerDrugFormGroup", {
        get: function () {
            var _this = this;
            var existing = this.dataCachingService.getCachedData('EditDrugCommon', this.id.toString());
            if (existing) {
                return of(existing.contextFormGroup);
            }
            else {
                return this.drugDataService.getDrugCommonForEdit(this.id)
                    .pipe(map(function (drug) {
                    return EditDrugCommonFormComponent.createFormGroup(_this.fb, { serverSideValidationErrors: [] }, drug);
                }));
            }
        },
        enumerable: true,
        configurable: true
    });
    EditDrugSpendingComponent.prototype.refreshFormData = function () {
        this.dataCachingService.removeCachedData('EditDrugWriteoffs', this.id.toString());
        this.refreshFormDataInternal();
    };
    EditDrugSpendingComponent.prototype.refreshFormDataInternal = function () {
        var _this = this;
        this.waitingOverlay.StartWaiting();
        var existing = this.dataCachingService.getCachedData('EditDrugWriteoffs', this.id.toString());
        if (existing) {
            this.model = existing;
            this.waitingOverlay.EndWaiting();
        }
        else {
            combineLatest([
                this.lookupSourceService.getLookupObj('drug-unit-type/drug'),
                this.lookupSourceService.getLookupObj('drug-package-type'),
                this.lookupSourceService.getLookupObj('drug-cost-type'),
                this.outerDrugFormGroup
            ]).subscribe(function (_a) {
                var unitTypeLookup = _a[0], packageTypeLookup = _a[1], drugCostTypeLookup = _a[2], outDrugFg = _a[3];
                _this.drugDataService.searchDrugSpending(_this.id)
                    .subscribe({
                    next: function (data) {
                        _this.model = { searchResults: data };
                        if (outDrugFg != null) {
                            _this.buildTypeName(outDrugFg, 'drugUnitTypeId', 'unitType', unitTypeLookup, 'единиц', 'ед');
                        }
                        data.forEach(function (row) {
                            _this.personFioCacheService.getUser(row.createdUser).subscribe(function (userRow) {
                                row.createdUserCaption = userRow.caption;
                                var dcti = drugCostTypeLookup['Obj' + outDrugFg.get('costTypeId').value || 1];
                                row.cost = Number(outDrugFg.get('cost').value || 0).toFixed(3) + ' ' +
                                    (dcti.shortCaption || dcti.caption);
                                row.sum = Number((row.spentValue || 0) * (outDrugFg.get('cost').value || 0)).toFixed(3) + ' ' +
                                    (dcti.shortCaption || dcti.caption);
                                _this.enqueeRedraw();
                            });
                        });
                        _this.dataCachingService.addToCache('EditDrugWriteoffs', _this.id.toString(), _this.model);
                        _this.waitingOverlay.EndWaiting();
                    }, error: function () {
                        _this.waitingOverlay.EndWaiting();
                    }
                });
            });
        }
    };
    EditDrugSpendingComponent.prototype.buildTypeName = function (outDrugFg, controlName, modelName, lookup, defName, defShortName) {
        if (lookup === void 0) { lookup = {}; }
        if (defName === void 0) { defName = '-'; }
        if (defShortName === void 0) { defShortName = '-'; }
        var val = outDrugFg.get(controlName).value;
        if (val) {
            this.model[modelName + 'Name'] = ((lookup['Obj' + val] || {}).caption || '').toLowerCase();
            this.model[modelName + 'ShortName'] = (lookup['Obj' + val] || {}).shortCaption || '';
        }
        else {
            this.model[modelName + 'Name'] = defName;
            this.model[modelName + 'ShortName'] = defShortName;
        }
    };
    EditDrugSpendingComponent.prototype.onGridReady = function (params) {
        this.gridApi = params.api;
    };
    EditDrugSpendingComponent.prototype.enqueeRedraw = function () {
        var _this = this;
        if (this.redrawTimerId) {
            clearTimeout(this.redrawTimerId);
        }
        this.redrawTimerId = setTimeout(function () {
            _this.gridApi.redrawRows();
        }, 500);
    };
    EditDrugSpendingComponent.prototype.navigate = function (data) {
        if (data.animalEventId) {
            this.navigationService.performAnimalEventEditing(data.animalEventId);
        }
        else if (data.destinationDrugId) {
            this.navigationService.performDrugEditing(data.destinationDrugId);
        }
    };
    EditDrugSpendingComponent.prototype.addNewWriteoff = function () {
        this.currentFormGroup = this.fb.group({
            date: [StringHelper.getISODate(new Date()), Validators.compose([Validators.required, FormHelper.validateDateTimePicker()])],
            spentValue: [''],
            spentValueStr: ['', Validators.compose([Validators.required, FormHelper.validateDrugDecimal()])],
            actNo: ['', Validators.compose([Validators.required])],
            reasonText: '',
            recycleWay: '',
            drugId: this.id,
            toOtherSubject: false,
            animalType4VetA: undefined,
            eventsCount: ['', Validators.compose([Validators.pattern(/^\d{0,8}$/)])],
        });
        this.writeoffModalOpened = true;
    };
    EditDrugSpendingComponent.prototype.addWriteoffCompleted = function () {
        var _this = this;
        FormHelper.markAsSubmitted(this.currentFormGroup);
        if (!this.currentFormGroup.valid) {
            return;
        }
        this.drugDataService.storeDrugWriteoff(this.currentFormGroup.value)
            .subscribe(function () {
            _this.dataCachingService.removeCachedData('EditDrugCommon', _this.id.toString());
            FormHelper.setSingleFormGroupServerSideValidationErrors({}, _this.model, _this.currentFormGroup);
            _this.writeoffModalOpened = false;
            _this.refreshFormData();
        }, function (err) { return FormHelper.setSingleFormGroupServerSideValidationErrors(err, _this.model, _this.currentFormGroup); });
    };
    EditDrugSpendingComponent.prototype.deleteWriteoff = function (id, date) {
        var _this = this;
        this.alertService.confirmModal('Вы уверены, что хотите удалить списание?').subscribe(function () {
            _this.drugDataService.deleteDrugWriteoff(id, date).subscribe(function () {
                _this.dataCachingService.removeCachedData('EditDrugCommon', _this.id.toString());
                _this.refreshFormData();
            });
        });
    };
    EditDrugSpendingComponent.prototype.addNewMove = function () {
        var _this = this;
        this.currentFormGroup = this.fb.group({
            date: [StringHelper.getISODate(new Date()), Validators.compose([Validators.required, FormHelper.validateDateTimePicker()])],
            value: [''],
            valueStr: ['', Validators.compose([Validators.required, FormHelper.validateDrugDecimal()])],
            packageCount: [1, Validators.compose([Validators.pattern(/^\d{1,10}$/)])],
            institutionId: ['', Validators.compose([Validators.required])],
            institutionBranchId: ['', Validators.compose([Validators.required])],
            drugId: this.id
        });
        var lastInstitutionValue;
        this.currentFormGroup.get('institutionId').valueChanges.subscribe(function () {
            if (lastInstitutionValue !== _this.currentFormGroup.get('institutionId').value) {
                lastInstitutionValue = _this.currentFormGroup.get('institutionId').value;
                _this.currentFormGroup.get('institutionBranchId').setValue(undefined);
            }
        });
        this.moveModalOpened = true;
    };
    EditDrugSpendingComponent.prototype.addMoveCompleted = function () {
        var _this = this;
        FormHelper.markAsSubmitted(this.currentFormGroup);
        if (!this.currentFormGroup.valid) {
            return;
        }
        var params = this.currentFormGroup.value;
        this.drugDataService.addMoving(params.date, params.drugId, params.value || 0, params.packageCount || 0, params.institutionId, params.institutionBranchId).subscribe(function () {
            _this.dataCachingService.removeCachedData('EditDrugCommon', _this.id.toString());
            FormHelper.setSingleFormGroupServerSideValidationErrors({}, _this.model, _this.currentFormGroup);
            _this.moveModalOpened = false;
            _this.refreshFormData();
        }, function (err) { return FormHelper.setSingleFormGroupServerSideValidationErrors(err, _this.model, _this.currentFormGroup); });
    };
    EditDrugSpendingComponent.prototype.animalType4VetAChanged = function ($event) {
        if (!this.currentFormGroup.controls['animalType4VetA'].value) {
            this.currentFormGroup.controls['eventsCount'].setValue(null);
        }
    };
    return EditDrugSpendingComponent;
}());
export { EditDrugSpendingComponent };
