import { Component, ElementRef, EventEmitter, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { environment } from '../../../../environments/environment';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { SecurityService } from '../../../logic/services/security.service';
import { EsiaService } from '../../../esia/esia.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { JobRunnerUiService } from '../../../ui/infrastructure/job-runner-ui.service';
import { noop, Observable } from 'rxjs/index';
import { ImpersonateParamsComponent } from './impersonate-params.component';
import { ModalParams, ModalSize } from '../../../logic/services/app-navigation.service.models';
import { MetadataService } from '../../../logic/services/metadata.service';
import { AppModalPreviewFileComponent } from '../../../ui/controls/app-modal-preview-file.component';
import { UserService } from '../../../logic/services/user.service';
import { FormHelper } from '../../../ui/controls/form-helper';
import { map } from 'rxjs/operators';
import {RecalculatePstIndicatorsParamsComponent} from './recalculate-pst-indicators-params.component';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  testEnvText = '';
  versionText = '';
  userInfo: any;
  institutionGroups: any;

  newFileToUploadFlag = false;
  @ViewChildren('newFileToUploadFlag') newFileToUpload: QueryList<any>;
  @ViewChild('notificationMenu') notificationMenuRef: ElementRef;

  equalsSome = FormHelper.equalsSome;
  isHasNewNotification = false;
  maxCountNotification = 15;

  openAuthMercuryDropdownEvent = new EventEmitter();

  constructor(public alertService: AlertService,
              public securityService: SecurityService,
              public appNavigationService: AppNavigationService,
              public userService: UserService,
              public lookupSourceService: LookupSourceService,
              public esiaService: EsiaService,
              private jobRunnerUiService: JobRunnerUiService,
              private metadataService: MetadataService,
              private fb: FormBuilder) {
    if (!environment.production) {
      this.testEnvText = 'ТЕСТОВАЯ ';
    }
    this.versionText = environment.version;
  }

  ngOnInit() {
    if (this.esiaService.loggedIn()) {
      this.securityService.getUserInfo().subscribe(userInfo => {
        this.userInfo = userInfo;
      });
    }

    this.lookupSourceService.getLookup('institution', true, false).subscribe(val => {
      val.sort((a, b) => a.id - b.id);
      let i = 0;
      this.institutionGroups = [];
      val.forEach(institution => {
        const groupNo = Math.trunc(i / 11);
        if (!this.institutionGroups[groupNo]) {
          this.institutionGroups.push([]);
        }
        this.institutionGroups[groupNo].push(institution);
        i++;
      });
    });

    this.getNotifications$().subscribe(val => {
      if (!this.notificationMenuRef) {
        this.isHasNewNotification = this.isHasNewNotification = val.some(x => x.isNew) ? true : this.isHasNewNotification;
      } else {
        this.isHasNewNotification = false;
        val.forEach(x => {
          x.badge = x.isNew;
          x.isNew = false;
        });
      }
    });
  }

  public navigateUserInstitution() {
    this.securityService.getUserInfo().subscribe(userInfo => {
      if (userInfo.institutionId) {
        this.appNavigationService.performInstitutionEditing(userInfo.institutionId);
      }
    });
  }

  logout() {
    this.esiaService.logout();
  }

  runUpdateProductTypesOp() {
    setTimeout(() => {
      this.jobRunnerUiService.runOperation(5 /* "Обновление справочника продукции из Меркурия"*/,
        {
          type: 'productTypeSync'
        }, undefined, undefined, false)
        .subscribe(noop);
    }, 1);
  }


  runUpdateDictionariesOp() {
    setTimeout(() => {
      this.jobRunnerUiService.runOperation(12 /* "Обновление общих справочников из Ветис"*/,
        {
          type: 'dictionarySync'
        }, undefined, undefined, false)
        .subscribe(noop);
    }, 1);
  }

  runUpdateAnimalTypesOp() {
    setTimeout(() => {
      this.jobRunnerUiService.runOperation(8 /* "Обновление справочника животных из Меркурия"*/,
        {
          type: 'animalTypeSync'
        }, undefined, undefined, false)
        .subscribe(noop);
    }, 1);
  }

  runUpdateAddresses() {
    setTimeout(() => {
      this.jobRunnerUiService.runOperation(9 /* "Актуализация наименований адресов в БД"*/,
        {
          type: 'updateAddressesInDataBase'
        }, undefined, undefined, false)
        .subscribe(noop);
    }, 1);
  }

  runUpdatePstIndicators() {
    this.appNavigationService.showModal(
      RecalculatePstIndicatorsParamsComponent,
      new ModalParams<any>(
        ModalSize.default,
        'Укажите параметры обновления показателей',
        'Пересчитать',
        {})).subscribe(
      {
        next: (params: any) => {
          this.jobRunnerUiService.runOperation(20 /* "Перерасчет показателей электронных отчетных форм"*/,
            {
              type: 'pstCalcIndicators',
              dateFrom: params.dateFrom,
              dateTo: params.dateTo,
              indicatorTypeId: params.indicatorTypeId,
              reportTypeId: params.reportTypeId,
            }, undefined, undefined, false)
            .subscribe(noop);
        }
      });
  }

  impersonateUserUi() {
    this.appNavigationService.showModal(
      ImpersonateParamsComponent,
      new ModalParams<any>(
        ModalSize.default,
        'Выберите пользователя',
        'Имперсонализовать',
        {})).subscribe(
      {
        next: noop
      });
  }

  impersonate() {
    this.esiaService.impersonate(undefined).subscribe(noop);
  }

  fileChanged(e) {
    if (e.target.files && e.target.files.length) {

      this.metadataService.uploadFile('/user-manuals/upload-file', e.target.files[0])
        .subscribe(fileUri => {
          // не знаю другого адекватного в данной ситуации способа очистить input[type=file]
          this.newFileToUploadFlag = true;
          setTimeout(() => {
            this.newFileToUploadFlag = false;
            this.lookupSourceService.invalidateLookup('user-manuals/all');
          });
      });
    }
  }

  fileUploadClick() {
    this.newFileToUpload.toArray()[0].nativeElement.click();
  }

  previewFile(file: any) {
    return this.appNavigationService.previewFile(AppModalPreviewFileComponent, { fileUri: 'user-manuals/' + file });
  }

  deleteFile(fileName: string) {
    this.metadataService.deleteUserManual(fileName)
      .subscribe(() => this.lookupSourceService.invalidateLookup('user-manuals/all'));
  }

  getNotifications$(): Observable<any[]> {
    return this.userService.getNotifications$()
      .pipe(map((response: any[]) => {
        return response as any[];
      }));
  }

  hoverNotification(row: any) {
    row.isNew = false;
    row.badge = false;
  }

  runStressPstIndicators() {
    this.jobRunnerUiService.runOperationDirect(22 /* "Стресс тест показателей"*/,
      {
        type: 'pstCalcIndicatorsHighload',
        regionId: 15,
      }, undefined, undefined, false);
    this.jobRunnerUiService.runOperationDirect(22 /* "Стресс тест показателей"*/,
      {
        type: 'pstCalcIndicatorsHighload',
        regionId: 10,
      }, undefined, undefined, false);
    this.jobRunnerUiService.runOperationDirect(22 /* "Стресс тест показателей"*/,
      {
        type: 'pstCalcIndicatorsHighload',
        regionId: 22,
      }, undefined, undefined, false);
    this.jobRunnerUiService.runOperationDirect(22 /* "Стресс тест показателей"*/,
      {
        type: 'pstCalcIndicatorsHighload',
        regionId: 11,
      }, undefined, undefined, false);
    this.jobRunnerUiService.runOperationDirect(22 /* "Стресс тест показателей"*/,
      {
        type: 'pstCalcIndicatorsHighload',
        regionId: 31,
      }, undefined, undefined, false);
  }
}
