
      <div class="itech-common-wrap" id="interceptNoAccess">
        <div id="agent-common-content">
          <div class="itech-doc-container">
            <div class="compact form itech-highlighted-sections">
              <section class="form-block itech-block-medium">
                <clr-progress-bar *ngIf="loading" clrValue="0" clrLoop></clr-progress-bar>

                <ng-container *ngIf="!loading">
                  <ng-container *ngIf="favouriteStableIds && favouriteStableIds.length; else noFavouriteStables">
                    <ng-container *ngFor="let stableId of favouriteStableIds">
                      <div class="form-group itech-group-whole-line itech-flex-link-with-remove">
                        <app-link-button [id]="stableId" [lookupName]="'stable'"></app-link-button>
                        <button type="button" class="btn btn-danger btn-link itech-inline-tool-btn"
                                [clrLoading]="loading" style="margin-top:3px"
                                title="Удалить из избранного" (click)="removeStableFromFavourite(stableId)">
                          <clr-icon shape="trash"></clr-icon>
                        </button>
                      </div>
                    </ng-container>
                  </ng-container>
                  <ng-template #noFavouriteStables>
                    Нет избранных поднадзорных объектов
                  </ng-template>
                </ng-container>
              </section>
            </div>
          </div>
        </div>
      </div>
  