import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ag-grid-cell-component-handler',
  template: `
      <app-edit-product-packaging *ngIf="colDef.componentType === 1" [packages]="params.data.get('packages')"
                                  [isDraft]="params.data.get('isDraft')"></app-edit-product-packaging>
      <app-edit-animal-list *ngIf="colDef.componentType === 2" class="edit-animal-list-flex"
                            [animals]="params.data.get(colDef.field)" [showLabel]="false"
                            [filterSearchParams]="params.data.value"></app-edit-animal-list>
  `
})
export class AgGridCellComponentHandlerComponent {

  @Input() public params: any;
  @Input() colDef: any = {};
}
