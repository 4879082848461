<div *ngIf="branch">{{branch.displayTitle}}</div>
<div #tableCtnr class="it-pst-tab-col-tbl-wrapper" (scroll)="onTableCtnrScroll($event)">
  <table *ngIf="branch && branch.headerRows" class="it-pst-tab-col-tbl" tabindex="0"
         (keydown)="tableKeyDown($event)" [style.width]="getSuggestedTableWidth()">
    <thead>
    <tr *ngFor="let headerRow of branch.headerRows; index as ix">
      <td *ngIf="ix===0" [attr.rowspan]="branch.headerRows.length" style="width: 30px">
        <div class="it-title-cell-overlay" style="border-width: 1px; justify-content: space-evenly"
             [style.left]="(scrollLeft-1)+'px'" [style.right]="'-'+(scrollLeft+1)+'px'">
          №<br>п/п
        </div>
      </td>
      <td *ngIf="ix===0" [attr.rowspan]="branch.headerRows.length" style="width: 180px">
        <div class="it-title-cell-overlay" style="justify-content: space-evenly"
             [style.left]="(scrollLeft-1)+'px'" [style.right]="'-'+(scrollLeft+2)+'px'">
          Наименование предприятия
        </div>
      </td>
      <ng-container *ngFor="let headerCol of headerRow">
        <td *ngIf="headerCol.rowSpan && headerCol.colSpan" [attr.colspan]="headerCol.colSpan"
            [attr.rowspan]="headerCol.rowSpan"
            [style.display]="headerCol.visible() ? 'visible' : 'none'"
            [style.width]="headerCol.meta.displayWidth && headerCol.lastRow? headerCol.meta.displayWidth + 'px' : undefined"
            class="it-indicator"
            [class.it-vertical]="headerCol.meta.displayVertical && headerCol.lastRow">
          <span>{{headerCol.displayTitle}}</span>
        </td>
      </ng-container>
    </tr>

    </thead>
    <tbody>
    <ng-container *ngFor="let rootRowsGroup of branch.rootRowsGroup">
      <tr class="it-row-header" *ngIf="rootRowsGroup.caption">
        <td [attr.colspan]="branch.headerRows[branch.headerRows.length-1].length + 2">{{rootRowsGroup.caption}}</td>
      </tr>
      <tr *ngIf="model.showReportStructure">
        <td class="it-read-only" colspan="2">
          <div class="it-title-cell-overlay" [style.left]="(scrollLeft-1)+'px'" style="width: 210px; border-width: 0 0 1px 1px">
          </div>
        </td>
        <td *ngFor="let headerCol of branch.headerRows[branch.headerRows.length-1]; index as ii"
            class="it-indicator it-read-only">
          <a class="itech-link in-table-link edit-layout" (click)="editReportIndicator(rootRowsGroup.indicators[ii])">
            <clr-icon shape="edit"></clr-icon>
            графа</a>
          <br>
          <a class="itech-link in-table-link edit-layout" (click)="editIndicatorType(rootRowsGroup.indicators[ii])">
            <clr-icon shape="edit"></clr-icon>
            показ.</a>
        </td>
      </tr>
      <!--
      Здесь древовидная структура с группировками отчета - уровень Групп МО
      -->
      <ng-container *ngFor="let group10 of rootRowsGroup.children">
        <tr class="it-row-header" *ngIf="!this.model.reportMeta.regionId && group10.children.length">
          <td [attr.colspan]="branch.headerRows[branch.headerRows.length-1].length + 2">
            {{group10.caption}}
            <div class="it-title-cell-overlay" [style.left]="(scrollLeft-1)+'px'" style="width: 200px; border-width: 0 0 1px 1px">
              {{group10.caption}}
            </div>
          </td>
        </tr>
        <!--
        Здесь древовидная структура с группировками отчета - уровень МО
        -->
        <ng-container *ngFor="let group20 of group10.children">
          <tr class="it-row-header" *ngIf="!this.model.reportMeta.regionId && group20.children.length">
            <td [attr.colspan]="branch.headerRows[branch.headerRows.length-1].length + 2">
              {{group20.caption}}
              <div class="it-title-cell-overlay" [style.left]="(scrollLeft-1)+'px'" style="width: 200px; border-width: 0 0 1px 1px">
                {{group20.caption}}
              </div>
            </td>
          </tr>
          <!--
          Здесь древовидная структура с группировками отчета - уровень КФХ / предприятия
          -->
          <ng-container *ngFor="let group30 of group20.children">
            <!--
            Здесь древовидная структура с группировками отчета - уровень КФХ / предприятия
            -->
            <ng-container *ngFor="let group40 of group30.children; index as ix40">
              <!--
              Здесь древовидная структура с группировками отчета - уровень классификаторов-подтипов
              -->
              <ng-container *ngFor="let group50 of group40.children; index as ix">
                <tr *ngIf="group50.visible()">
                  <td class="it-read-only" style="width: 30px">
                    <div class="it-title-cell-overlay"[style.left]="(scrollLeft-1)+'px'" [style.right]="'-'+(scrollLeft+2)+'px'">
                      {{ix + 1}}
                    </div>
                  </td>
                  <td class="it-read-only" style="width: 180px">
                    <a class="in-table-link itech-link" (click)="navigationService.performAgentEditing(group50.agentId)">
                      {{group50.displayCaption()}}
                    </a>
                    <div class="it-title-cell-overlay" [style.left]="(scrollLeft-1)+'px'" [style.right]="'-'+(scrollLeft+2)+'px'">
                      <a class="in-table-link itech-link" (click)="navigationService.performAgentEditing(group50.agentId)">
                        {{group50.displayCaption()}}
                      </a>
                      <div class="it-add-subtype" *ngIf="branch.hasSubtypes">
                        <clr-dropdown *ngIf="ix === group40.children.length - 1">
                          <a class="in-table-link itech-link" clrDropdownToggle title="Добавить подкатегорию">
                            <clr-icon shape="plus"></clr-icon>
                          </a>
                          <clr-dropdown-menu *clrIfOpen [clrPosition]="'right-bottom'">
                            <label class="dropdown-header">Выберите категорию</label>
                            <button *ngFor="let cat of getAvailableSubtypes$() | async" [title]="cat.caption"
                                    type="button" clrDropdownItem
                                    (click)="addAgentRow(group40.agentId, rootRowsGroup, cat.id)">{{cat.caption}}
                            </button>
                          </clr-dropdown-menu>
                        </clr-dropdown>
                      </div>
                    </div>
                  </td>
                  <td *ngFor="let headerCol of branch.headerRows[branch.headerRows.length-1]; index as ii"
                      [class.it-read-only]="getReadOnly(group50, ii, rootRowsGroup)"
                      [attr.colspan]="headerCol.colSpan"
                      [style.display]="headerCol.visible() ? 'visible' : 'none'"
                      (click)="cellClick(group50, ii, rootRowsGroup)"
                      [class.it-focused]="getFocused(group50, ii, rootRowsGroup)"
                      [class.it-editing]="getEditing(group50, ii, rootRowsGroup)"
                      class="it-indicator">
                    <app-branch-cell-edit *ngIf="group50.indicatorTypeMeta[ii]"
                                          [model]="model" [branch]="branch" [indicatorLevel]="group50"
                                          [focused]="getFocused(group50, ii, rootRowsGroup)"
                                          [indicatorTypeMeta]="group50.indicatorTypeMeta[ii]"
                                          [editing]="getEditing(group50, ii, rootRowsGroup)"
                                          [reportIndicator]="rootRowsGroup.indicators[ii]"></app-branch-cell-edit>
                  </td>
                </tr>
              </ng-container>
              <tr [class.it-row-footer]="group40.children.length" *ngIf="group40.visible()">
                <td class="it-read-only">
                  <div class="it-title-cell-overlay" [style.left]="(scrollLeft-1)+'px'" [style.right]="'-'+(scrollLeft+2)+'px'">
                    {{ix40 + 1}}
                  </div>
                </td>
                <td *ngIf="!group40.children.length" class="it-read-only">
                  <a class="in-table-link itech-link" (click)="navigationService.performAgentEditing(group40.agentId)">
                    {{group40.caption}}
                  </a>
                  <div class="it-title-cell-overlay" [style.left]="(scrollLeft-1)+'px'" [style.right]="'-'+(scrollLeft+2)+'px'">
                    <a class="in-table-link itech-link" (click)="navigationService.performAgentEditing(group40.agentId)">
                      {{group40.caption}}
                    </a>
                  </div>
                </td>
                <td *ngIf="group40.children.length" class="it-read-only">
                  Итого по «{{group40.caption}}»:
                  <div class="it-title-cell-overlay" [style.left]="(scrollLeft-1)+'px'" [style.right]="'-'+(scrollLeft+2)+'px'">
                    Итого по «{{group40.caption}}»:
                  </div>
                </td>
                <td *ngFor="let headerCol of branch.headerRows[branch.headerRows.length-1]; index as ii"
                    [class.it-read-only]="getReadOnly(group40, ii, rootRowsGroup)"
                    [attr.colspan]="headerCol.colSpan"
                    [style.display]="headerCol.visible() ? 'visible' : 'none'"
                    (click)="cellClick(group40, ii, rootRowsGroup)"
                    [class.it-focused]="getFocused(group40, ii, rootRowsGroup)"
                    [class.it-editing]="getEditing(group40, ii, rootRowsGroup)"
                    class="it-indicator">
                  <app-branch-cell-edit *ngIf="group40.indicatorTypeMeta[ii]"
                                        [model]="model" [branch]="branch" [indicatorLevel]="group40"
                                        [focused]="getFocused(group40, ii, rootRowsGroup)"
                                        [indicatorTypeMeta]="group40.indicatorTypeMeta[ii]"
                                        [editing]="getEditing(group40, ii, rootRowsGroup)"
                                        [reportIndicator]="rootRowsGroup.indicators[ii]"></app-branch-cell-edit>
                </td>
              </tr>
            </ng-container>
            <tr [class.it-row-footer]="group30.children.length"  *ngIf="group30.visible()">
              <td colspan="2" *ngIf="!group30.children.length" class="it-read-only">
                {{group30.caption}}
                <div class="it-title-cell-overlay"
                     [style.left]="(scrollLeft-1)+'px'" [style.right]="'-'+(scrollLeft+2)+'px'">
                  {{group30.caption}}
                </div>
              </td>
              <td colspan="2" *ngIf="group30.children.length" class="it-read-only">Итого по «{{group30.caption}}»:
                <div class="it-title-cell-overlay"
                     [style.left]="(scrollLeft-1)+'px'" [style.right]="'-'+(scrollLeft+2)+'px'">
                  Итого по «{{group30.caption}}»:
                </div>
              </td>
              <td *ngFor="let headerCol of branch.headerRows[branch.headerRows.length-1]; index as ii"
                  [class.it-read-only]="getReadOnly(group30, ii, rootRowsGroup)"
                  [attr.colspan]="headerCol.colSpan"
                  [style.display]="headerCol.visible() ? 'visible' : 'none'"
                  (click)="cellClick(group30, ii, rootRowsGroup)"
                  [class.it-focused]="getFocused(group30, ii, rootRowsGroup)"
                  [class.it-editing]="getEditing(group30, ii, rootRowsGroup)"
                  class="it-indicator">
                <app-branch-cell-edit *ngIf="group30.indicatorTypeMeta[ii]"
                                      [model]="model" [branch]="branch" [indicatorLevel]="group30"
                                      [focused]="getFocused(group30, ii, rootRowsGroup)"
                                      [indicatorTypeMeta]="group30.indicatorTypeMeta[ii]"
                                      [editing]="getEditing(group30, ii, rootRowsGroup)"
                                      [reportIndicator]="rootRowsGroup.indicators[ii]"></app-branch-cell-edit>
              </td>
            </tr>
          </ng-container>
          <tr [class.it-row-footer]="group20.children.length"  *ngIf="group20.visible()">
            <td colspan="2" *ngIf="!group20.children.length" class="it-read-only">
              {{this.model.reportMeta.regionId && rootRowsGroup.caption ? rootRowsGroup.caption : group20.caption }}
              <div class="it-title-cell-overlay" [style.left]="(scrollLeft-1)+'px'" [style.right]="'-'+(scrollLeft+2)+'px'">
                {{this.model.reportMeta.regionId && rootRowsGroup.caption ? rootRowsGroup.caption : group20.caption }}
              </div>
            </td>
            <td colspan="2" *ngIf="group20.children.length" class="it-read-only">
              Итого
              по «{{this.model.reportMeta.regionId && rootRowsGroup.caption ? rootRowsGroup.caption
                : group20.caption }}»:
              <div class="it-title-cell-overlay"
                   [style.left]="(scrollLeft-1)+'px'" [style.right]="'-'+(scrollLeft+2)+'px'">
                Итого
                по «{{this.model.reportMeta.regionId && rootRowsGroup.caption ? rootRowsGroup.caption
                  : group20.caption }}»:
              </div>
            </td>
            <td *ngFor="let headerCol of branch.headerRows[branch.headerRows.length-1]; index as ii"
                [attr.colspan]="headerCol.colSpan"
                [style.display]="headerCol.visible() ? 'visible' : 'none'"
                [class.it-read-only]="getReadOnly(group20, ii, rootRowsGroup)"
                (click)="cellClick(group20, ii, rootRowsGroup)"
                [class.it-focused]="getFocused(group20, ii, rootRowsGroup)"
                [class.it-editing]="getEditing(group20, ii, rootRowsGroup)"
                class="it-indicator">
              <app-branch-cell-edit *ngIf="group20.indicatorTypeMeta[ii]"
                                    [model]="model" [branch]="branch" [indicatorLevel]="group20"
                                    [focused]="getFocused(group20, ii, rootRowsGroup)"
                                    [indicatorTypeMeta]="group20.indicatorTypeMeta[ii]"
                                    [editing]="getEditing(group20, ii, rootRowsGroup)"
                                    [reportIndicator]="rootRowsGroup.indicators[ii]"></app-branch-cell-edit>
            </td>
          </tr>
        </ng-container>
        <tr [class.it-row-footer]="group10.children.length"  *ngIf="group10.visible()">
          <td colspan="2" *ngIf="!group10.children.length" class="it-read-only">{{group10.caption}}
            <div class="it-title-cell-overlay"
                 [style.left]="(scrollLeft-1)+'px'" [style.right]="'-'+(scrollLeft+2)+'px'">
              {{group10.caption}}
            </div>
          </td>
          <td colspan="2" *ngIf="group10.children.length" class="it-read-only">Итого по «{{group10.caption}}»:
            <div class="it-title-cell-overlay" [style.left]="(scrollLeft-1)+'px'" [style.right]="'-'+(scrollLeft+2)+'px'">
              Итого по «{{group10.caption}}»:
            </div>
          </td>
          <td *ngFor="let headerCol of branch.headerRows[branch.headerRows.length-1]; index as ii"
              [attr.colspan]="headerCol.colSpan"
              [style.display]="headerCol.visible() ? 'visible' : 'none'"
              (click)="cellClick(group10, ii, rootRowsGroup)"
              [class.it-focused]="getFocused(group10, ii, rootRowsGroup)"
              [class.it-editing]="getEditing(group10, ii, rootRowsGroup)"
              [class.it-read-only]="getReadOnly(group10, ii, rootRowsGroup)"
              class="it-indicator">
            <app-branch-cell-edit *ngIf="group10.indicatorTypeMeta[ii]"
                                  [model]="model" [branch]="branch" [indicatorLevel]="group10"
                                  [focused]="getFocused(group10, ii, rootRowsGroup)"
                                  [indicatorTypeMeta]="group10.indicatorTypeMeta[ii]"
                                  [editing]="getEditing(group10, ii, rootRowsGroup)"
                                  [reportIndicator]="rootRowsGroup.indicators[ii]"></app-branch-cell-edit>
          </td>
        </tr>
      </ng-container>

      <tr class="it-row-footer" *ngIf="rootRowsGroup.visible()">
        <td colspan="2" class="it-read-only">Итого по краю:
          <div class="it-title-cell-overlay"
               [style.left]="(scrollLeft-1)+'px'" [style.right]="'-'+(scrollLeft+2)+'px'">
            Итого по краю:
          </div>
        </td>
        <td *ngFor="let headerCol of branch.headerRows[branch.headerRows.length-1]; index as ii"
            [attr.colspan]="headerCol.colSpan"
            (click)="cellClick(rootRowsGroup, ii, rootRowsGroup)"
            [style.display]="headerCol.visible() ? 'visible' : 'none'"
            [class.it-focused]="getFocused(rootRowsGroup, ii, rootRowsGroup)"
            [class.it-editing]="getEditing(rootRowsGroup, ii, rootRowsGroup)"
            class="it-indicator it-read-only">
          <app-branch-cell-edit *ngIf="rootRowsGroup.indicatorTypeMeta[ii]"
                                [model]="model" [branch]="branch" [indicatorLevel]="rootRowsGroup"
                                [focused]="getFocused(rootRowsGroup, ii, rootRowsGroup)"
                                [indicatorTypeMeta]="rootRowsGroup.indicatorTypeMeta[ii]"
                                [editing]="getEditing(rootRowsGroup, ii, rootRowsGroup)"
                                [reportIndicator]="rootRowsGroup.indicators[ii]"></app-branch-cell-edit>
        </td>
      </tr>

      <!--
      Строка с добавлением хоз. субъекта в отчет
      -->
      <tr class="it-row-footer" *ngIf="rootRowsGroup.hasEditableCells
      && !(securityService.getUserInfo() | async).restrictPstAgentId">
        <td [attr.colspan]="2" style="border-right: none">
          <a class="btn-link in-table-link itech-link" title="Добавить предприятие"
             (click)="searchAgent(rootRowsGroup)">
            <clr-icon shape="plus"></clr-icon>
            Добавить предприятие
          </a>
          <div class="it-title-cell-overlay" [style.left]="(scrollLeft-1)+'px'" style="width: 210px; border-width: 0 0 1px 1px">
            <a class="btn-link in-table-link itech-link" title="Добавить предприятие"
               (click)="searchAgent(rootRowsGroup)">
              <clr-icon shape="plus"></clr-icon>
              Добавить предприятие
            </a>
          </div>
        </td>
        <td [attr.colspan]="branch.headerRows[branch.headerRows.length-1].length">
          <div *ngIf="model.showReportStructure">
            <a class="itech-link in-table-link edit-layout" (click)="addReportIndicator()">
              <clr-icon shape="plus"></clr-icon>
              Добавить новую графу в раздел</a>
          </div>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
</div>
