import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { StringHelper } from '../../helpers/string-helper';
import { map } from 'rxjs/internal/operators';

@Injectable()
export class GroupOperationsService {

  public static QueryRunner = 6;
  public static QueryCsvExporter = 7;

  constructor(private http: HttpClient) {
  }

  public runOperation(opType: number, params: any, file?: any, data?: any): Observable<any> {
    const qstring = environment.api + '/jobs/' + opType;

    const formData = new FormData();
    formData.append('meta', new Blob([JSON.stringify(params)], {type: 'application/json'}));
    if (file) {
      formData.append('file', file);
    }
    if (data) {
      formData.append('file', new Blob([JSON.stringify(data)], {type: 'application/json'}));
    }

    return this.http.post(qstring, formData)
      .pipe(map((response: any) => {
        return response.data;
      }));
  }

  public getOperationStatus(typeId: number, id: number): Observable<any> {
    const qstring = environment.api + '/jobs/' + typeId + '/' + id;
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  public getOperationResult(typeId: number, id: number): Observable<any> {
    const qstring = environment.api + '/jobs/data/' + typeId + '/' + id;
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  public cancelOperation(typeId: number, id: number): Observable<any> {
    const qstring = environment.api + '/jobs/delete/' + typeId + '/' + id;
    return this.http.post(qstring, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  public readUserOperations(dateFrom?: Date, dateTo?: Date, stateId?: number): Observable<any[]> {
    let qstring = environment.api + '/jobs/data?_=1';

    if (dateFrom) {
      qstring = qstring + '&dateFrom=' + StringHelper.getISODate(dateFrom);
    }
    if (dateTo) {
      qstring = qstring + '&dateTo=' + StringHelper.getISODate(dateTo);
    }
    if (stateId) {
      qstring = qstring + '&state=' + stateId;
    }

    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }
}
