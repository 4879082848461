
    <ng-container [formGroup]="contextFormGroup" *ngIf="contextFormGroup
    && (!queryUserMode || queryUserMode['_par_' + parameterName + '_filter_mq'])">
      <label>
      </label>
      <div class="checkbox checkbox-inline" [style.background]="filtered?'linear-gradient(to right, #90ff90, white)':undefined">
        <span style="color: black"><ng-content></ng-content></span>&nbsp;
        <input *ngIf="!queryUserMode" type="checkbox" id="{{'_par_' + parameterName + '_filter_mq'}}" [(ngModel)]="customFilterChecked"
               [ngModelOptions]="{standalone: true}">
        <label *ngIf="!queryUserMode" for="{{'_par_' + parameterName + '_filter_mq'}}" title="Запросить у пользователя">?</label>
      </div>
      <div>
        <app-combo-multilookup *ngIf="!useCheckboxes; else checkboxMode" class="itech-control-xlarge"
                               [itemTitle]="itemTitle" [lookupName]="lookupName" [sorted]="true"
                               [contextControlName]="'_par_' + parameterName" [contextFormGroup]="contextFormGroup">
        </app-combo-multilookup>
        <ng-template #checkboxMode>
          <app-checkbox-select class="itech-control-x-large" [disabled]="customFilterChecked || hasParentParamFilter"
                               [contextControlName]="'_par_' + parameterName" [lookupName]="lookupName" [sorted]="true"
                               [contextFormGroup]="contextFormGroup"></app-checkbox-select>
        </ng-template>
      </div>
    </ng-container>
  