/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@clr/angular";
import * as i2 from "@angular/common";
import * as i3 from "../../logic/pipes/lookup-source.pipe";
import * as i4 from "../../logic/services/lookup-source.service";
import * as i5 from "../../logic/services/address-person-fio-cache.service";
import * as i6 from "@angular/platform-browser";
import * as i7 from "./edit-animal-list.component";
import * as i8 from "../../logic/services/app-navigation.service";
import * as i9 from "@angular/forms";
var styles_EditAnimalListComponent = [];
var RenderType_EditAnimalListComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EditAnimalListComponent, data: {} });
export { RenderType_EditAnimalListComponent as RenderType_EditAnimalListComponent };
function View_EditAnimalListComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "label", [["class", "required"]], [[1, "for", 0]], null, null, null, null)), i0.ɵdid(1, 212992, null, 0, i1.ClrLabel, [[2, i1.ɵbe], [2, i1.ɵbf], [2, i1.ɵbg], i0.Renderer2, i0.ElementRef], null, null), (_l()(), i0.ɵted(-1, null, ["\u0416\u0438\u0432\u043E\u0442\u043D\u044B\u0435"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).forAttr; _ck(_v, 0, 0, currVal_0); }); }
function View_EditAnimalListComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-danger btn-link itech-inline-tool-btn"], ["style", "margin:-3px 0 0 0"], ["title", "\u0423\u0434\u0430\u043B\u0438\u0442\u044C \u0436\u0438\u0432\u043E\u0442\u043D\u043E\u0435"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.delete(_v.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "clr-icon", [["shape", "trash"], ["size", "12"], ["style", "transform: inherit;color: #d23b3b"]], null, null, null, null, null)), i0.ɵdid(2, 16384, null, 0, i1.ClrIconCustomTag, [], null, null)], null, null); }
function View_EditAnimalListComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "a", [["class", "itech-link"], ["style", "overflow-wrap: break-word"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.appNavigationService.performAnimalEditing(_v.context.$implicit.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(2, null, [" ", ".\u00A0"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵppd(4, 5), i0.ɵpid(131072, i2.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EditAnimalListComponent_4)), i0.ɵdid(7, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.showDeleteBtn; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (_v.context.index + 1); _ck(_v, 2, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 5).transform(i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent.parent, 0), _v.context.$implicit.value, "animal", false, true, true)))); _ck(_v, 3, 0, currVal_1); }); }
function View_EditAnimalListComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "form-group"]], [[4, "min-height", "px"], [4, "padding", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EditAnimalListComponent_2)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "itech-text-field itech-control-xlarge itech-text-wall itech-many-animal"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EditAnimalListComponent_3)), i0.ɵdid(5, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.showLabel; _ck(_v, 2, 0, currVal_2); var currVal_3 = _co.animals.controls; _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.showAddManyAnimal ? 36 : undefined); var currVal_1 = (!_co.showLabel ? 0 : undefined); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_EditAnimalListComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "label", [], [[2, "required", null], [1, "for", 0]], null, null, null, null)), i0.ɵdid(1, 212992, null, 0, i1.ClrLabel, [[2, i1.ɵbe], [2, i1.ɵbf], [2, i1.ɵbg], i0.Renderer2, i0.ElementRef], null, null), (_l()(), i0.ɵted(2, null, ["", ""]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.noAnimalsSelected(); var currVal_1 = i0.ɵnov(_v, 1).forAttr; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = (_co.noAnimalsSelected() ? "\u0416\u0438\u0432\u043E\u0442\u043D\u044B\u0435" : ""); _ck(_v, 2, 0, currVal_2); }); }
function View_EditAnimalListComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "form-group"]], [[4, "padding", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EditAnimalListComponent_6)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "a", [["class", "itech-link in-table-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addManyAnimals() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u0437\u0432\u0435\u0440\u0435\u0439..."]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.showLabel; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.showLabel ? 0 : undefined); _ck(_v, 0, 0, currVal_0); }); }
export function View_EditAnimalListComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i3.LookupSourcePipe, [i4.LookupSourceService, i5.AddressPersonFioCacheService, i6.DomSanitizer]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EditAnimalListComponent_1)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EditAnimalListComponent_5)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.animals.controls.length !== 0); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.showAddManyAnimal; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_EditAnimalListComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-edit-animal-list", [], null, null, null, View_EditAnimalListComponent_0, RenderType_EditAnimalListComponent)), i0.ɵdid(1, 114688, null, 0, i7.EditAnimalListComponent, [i8.AppNavigationService, i9.FormBuilder, i5.AddressPersonFioCacheService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EditAnimalListComponentNgFactory = i0.ɵccf("app-edit-animal-list", i7.EditAnimalListComponent, View_EditAnimalListComponent_Host_0, { animals: "animals", showAddManyAnimal: "showAddManyAnimal", contextFormGroup: "contextFormGroup", showLabel: "showLabel", filterSearchParams: "filterSearchParams", showDeleteBtn: "showDeleteBtn" }, {}, []);
export { EditAnimalListComponentNgFactory as EditAnimalListComponentNgFactory };
