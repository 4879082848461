
      <div style="display:flex">
        <clr-icon shape="alarm-clock" [title]="getRemainingTime()" *ngIf="deadlineDateTime"
                  style="margin:10px 5px 0 -20px" [style.color]="violationDeadline ? 'red' : undefined"></clr-icon>
        <div class="form-group" style="display:block;min-height:40px"
             [style.margin-bottom.px]="isAccessEdit ? marginBottom : marginBottom + 20">
            <label>{{label}}</label>
            <div *ngFor="let file of controlFormArray.controls; index as i" [formGroup]="getFormGroup(file)">
                <label for="date{{controlName}}{{i}}" style="margin-right: 5px">Дата</label>
                <label for="date{{controlName}}{{i}}" aria-haspopup="true" role="tooltip"
                       class="tooltip tooltip-validation tooltip-md disabled {{'tooltip-'+tooltipPosition}}"
                       [class.invalid]="isInvalid(getFormGroup(file), 'date')">
                    <input type="date" id="date{{controlName}}{{i}}" class="itech-control-normal"
                           formControlName="date" [attr.disabled]="!isAccessEdit ? true : undefined">
                    <app-validation-tooltip [input]="getFormGroup(file).controls['date']"></app-validation-tooltip>
                </label>
                <label for="number{{controlName}}{{i}}" style="margin: 0 5px 0 10px">Номер</label>
                <label for="number{{controlName}}{{i}}" aria-haspopup="true" role="tooltip"
                       class="tooltip tooltip-validation tooltip-md {{'tooltip-'+tooltipPosition}}"
                       [class.invalid]="isInvalid(getFormGroup(file), 'number')">
                    <input type="text" id="number{{controlName}}{{i}}" class="itech-control-normal"
                           (change)="changeNumberDoc(getFormGroup(file))"
                           formControlName="number" [attr.disabled]="!isAccessEdit ? true : undefined">
                    <app-validation-tooltip [input]="getFormGroup(file).controls['number']"></app-validation-tooltip>
                </label>
                <div style="padding-top: 0.2rem">
                    <app-single-file [contextFormGroup]="getFormGroup(file)" [isAccessEdit]="isAccessEdit"
                                     [uriUpload]="'/quarantine/upload-file/' + id" (changeFile)="markAsDirty()"
                                     [preTitle]="'Прикрепленный файл: '" [downloadFileUri]="'quarantine/files/preload'">
                    </app-single-file>
                    <div *ngIf="isAccessEdit && getFormGroup(file).get('dateUpload').value" style="font-size:0.45rem">
                        загружено {{getDateUploadToString(getFormGroup(file).get('dateUpload').value)}}
                        пользователем {{file.get('userIdUpload').value|lookup:'user'|async}}
                    </div>
                </div>
                <label *ngIf="labelDateToInclusive" for="dateToInclusive{{controlName}}{{i}}"
                       style="margin-right: 5px">{{labelDateToInclusive}}</label>
                <label *ngIf="labelDateToInclusive" for="dateToInclusive{{controlName}}{{i}}" aria-haspopup="true" role="tooltip"
                       class="tooltip tooltip-validation tooltip-md disabled {{'tooltip-'+tooltipPosition}}"
                       [class.invalid]="isInvalid(getFormGroup(file), 'dateToInclusive')">
                    <input type="date" id="dateToInclusive{{controlName}}{{i}}" class="itech-control-normal"
                           formControlName="dateToInclusive" [attr.disabled]="!isAccessEdit ? true : undefined">
                    <app-validation-tooltip [input]="getFormGroup(file).controls['dateToInclusive']"></app-validation-tooltip>
                </label>
                <hr>
            </div>
            <button type="button" class="btn btn-link" (click)="addNewOrderToExtend()">
                <clr-icon shape="plus"></clr-icon>
                Добавить файл
            </button>
        </div>
      </div>