import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {LookupSourceService} from '../../../logic/services/lookup-source.service';
import {AppNavigationService} from '../../../logic/services/app-navigation.service';
import {StringHelper} from '../../../helpers/string-helper';
import {FormHelper} from '../../../ui/controls/form-helper';
import {PstReportStructureService} from '../../services/pst-report-structure.service';

@Component({
  selector: 'app-branch-cell-lookup-edit',
  templateUrl: './pst-branch-cell-lookup-edit.component.html',
})
export class PstBranchCellLookupEditComponent implements OnChanges {
  @Input() branch: any;
  @Input() model: any;
  @Input() lookupName: string;
  @Input() indicatorLevel: any;
  @Input() originalCellValue: any;
  @Input() editing: boolean;
  @Input() focused: boolean;

  @Output() changed = new EventEmitter();

  @ViewChild('input') inputControl: ElementRef;

  processMoneyKeypress = FormHelper.processMoneyKeypressDot;

  valueStr = '';
  valueLoaded = false;
  valueUpdated = false;

  lookupItems: any[];

  get cellValueEdit() {
    if (!this.valueLoaded) {
      this.valueStr = this.originalCellValue;
      this.valueLoaded = true;
    }

    return this.valueStr;
  }

  set cellValueEdit(strValue: any) {
    this.valueUpdated = true;
    this.valueStr = strValue;
  }

  constructor(
    private lookupSourceService: LookupSourceService,
    private navigationService: AppNavigationService,
    private changeDetectorRef: ChangeDetectorRef,
    private pstReportStructureService: PstReportStructureService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('lookupName')) {
      this.lookupSourceService.getLookup(this.lookupName).subscribe(lookup => {
        this.lookupItems = lookup;
      });
    }

    if (changes.hasOwnProperty('editing')) {
      if (this.editing) {
        setTimeout(() => {
          if (this.inputControl) {
            this.inputControl.nativeElement.focus();
          }
        }, 100);
      }

      if (!this.editing && this.valueUpdated) {
        setTimeout(() => {
          this.updateCellValueInModel();
          this.valueLoaded = false;
          this.valueUpdated = false;
        }, 100);
      }
    }
  }

  updateCellValueInModel() {
    let parsedVal;
    if (this.valueStr) {
      parsedVal = +this.valueStr;
    }

    if (this.changed) {
      this.changed.emit(parsedVal);
    }
  }

  onChange($event: Event) {

  }
}
