
      <app-query-lookup-combo-filter parameterName="created_institution" [lookupName]="'all-institution'" [contextFormGroup]="contextFormGroup"
                                     [queryUserMode]="queryUserMode">Учреждение-создатель
      </app-query-lookup-combo-filter>
      <app-query-lookup-combo-filter *ngIf="contextFormGroup.get('_par_created_institution').value" [contextFormGroup]="contextFormGroup"
                                     parameterName="created_institution_employee"
                                     [lookupName]="'institution-employee' + contextFormGroup.get('_par_created_institution').value"
                                     [queryUserMode]="queryUserMode">Пользователь-создатель
      </app-query-lookup-combo-filter>
      <app-query-date-time-filter parameterName="created_time" [contextFormGroup]="contextFormGroup"
                                  [queryUserMode]="queryUserMode">Время создания
      </app-query-date-time-filter>
      <app-query-lookup-combo-filter parameterName="transaction_type" [lookupName]="'transaction-type'"
                                     [contextFormGroup]="contextFormGroup" [queryUserMode]="queryUserMode">Тип транз.
      </app-query-lookup-combo-filter>
      <app-query-string-filter parameterName="stable_product_subtype_caption" [contextFormGroup]="contextFormGroup"
                               [queryUserMode]="queryUserMode">Наименов.
      </app-query-string-filter>
      <app-query-string-filter parameterName="batch_id" [contextFormGroup]="contextFormGroup"
                               [queryUserMode]="queryUserMode">Номер произв.партии
      </app-query-string-filter>
      <app-query-date-time-filter parameterName="manufacture_date" [contextFormGroup]="contextFormGroup"
                                  [queryUserMode]="queryUserMode">Дата производства
      </app-query-date-time-filter>
      <app-query-date-time-filter parameterName="expiration_date" [contextFormGroup]="contextFormGroup"
                                  [queryUserMode]="queryUserMode">Срок годности
      </app-query-date-time-filter>
      <app-query-stable-agent-filter parameterName="origin_agent_id" [contextFormGroup]="contextFormGroup"
                                     [queryUserMode]="queryUserMode">Отправитель
      </app-query-stable-agent-filter>
      <app-query-address-filter parameterName="origin_agent_address" [contextFormGroup]="contextFormGroup"
                               [queryUserMode]="queryUserMode">Адрес отправителя
      </app-query-address-filter>
      <app-query-stable-agent-filter parameterName="origin_stable_id" [contextFormGroup]="contextFormGroup"
                                     [queryUserMode]="queryUserMode">Место отпр.
      </app-query-stable-agent-filter>
      <app-query-address-filter parameterName="origin_stable_address" [contextFormGroup]="contextFormGroup"
                                [queryUserMode]="queryUserMode">Адрес места отправ.
      </app-query-address-filter>
      <app-query-stable-agent-filter parameterName="destination_agent_id" [contextFormGroup]="contextFormGroup"
                                     [queryUserMode]="queryUserMode">Получатель
      </app-query-stable-agent-filter>
      <app-query-address-filter parameterName="destination_agent_address" [contextFormGroup]="contextFormGroup"
                                [queryUserMode]="queryUserMode">Адрес получ.
      </app-query-address-filter>
      <app-query-stable-agent-filter parameterName="destination_stable_id" [contextFormGroup]="contextFormGroup"
                                     [queryUserMode]="queryUserMode">Место получ.
      </app-query-stable-agent-filter>
      <app-query-address-filter parameterName="destination_stable_address" [contextFormGroup]="contextFormGroup"
                                [queryUserMode]="queryUserMode">Адрес места получ.
      </app-query-address-filter>
      <app-query-lookup-combo-filter parameterName="storage_way_id" [lookupName]="'storage-way'" [contextFormGroup]="contextFormGroup"
                                     [queryUserMode]="queryUserMode">Способ хранения при перевозке
      </app-query-lookup-combo-filter>
      <app-query-lookup-combo-filter parameterName="origin_country" [lookupName]="'country'" [queryUserMode]="queryUserMode"
                                     [contextFormGroup]="contextFormGroup">Страна происхождения
      </app-query-lookup-combo-filter>
      <app-query-lookup-combo-filter parameterName="expertise_status" [lookupName]="'product-expertise-status'" [contextFormGroup]="contextFormGroup"
                                     [queryUserMode]="queryUserMode">Вет.сан.экспертиза
      </app-query-lookup-combo-filter>
      <app-query-lookup-combo-filter parameterName="use_restriction" [lookupName]="'product-use-restriction'" [contextFormGroup]="contextFormGroup"
                                     [queryUserMode]="queryUserMode">Огранич. по использ.
      </app-query-lookup-combo-filter>
      <app-query-string-filter parameterName="location_prosperity" [contextFormGroup]="contextFormGroup"
                               [queryUserMode]="queryUserMode">Благополучие местности
      </app-query-string-filter>
      <app-query-lookup-combo-filter parameterName="animal_spent_period_code" [lookupName]="'animal-spent-period'" [contextFormGroup]="contextFormGroup"
                                     [queryUserMode]="queryUserMode">Нахождение в тамож. зоне
      </app-query-lookup-combo-filter>
      <app-query-integer-filter parameterName="months_spent" [contextFormGroup]="contextFormGroup"
                               [queryUserMode]="queryUserMode">Карантин
      </app-query-integer-filter>
      <app-query-boolean-filter parameterName="bad_quality" [contextFormGroup]="contextFormGroup"
                                [queryUserMode]="queryUserMode">Некачеств. продукция
      </app-query-boolean-filter>
      <app-query-lookup-combo-filter parameterName="product_reason_id" [lookupName]="'product-reason'" [contextFormGroup]="contextFormGroup"
                                     [queryUserMode]="queryUserMode">Цель производства/сбыта
      </app-query-lookup-combo-filter>
      <app-query-lookup-combo-filter parameterName="ttn_type_id" [lookupName]="'ttn-type'"
                                     [contextFormGroup]="contextFormGroup"
                                     [queryUserMode]="queryUserMode">ТТН
      </app-query-lookup-combo-filter>
      <ng-container *ngIf="contextFormGroup.get('_par_ttn_type_id').value && contextFormGroup.get('_par_ttn_type_id').value != 0">
        <app-query-string-filter parameterName="ttn_serial" [contextFormGroup]="contextFormGroup"
                                 [queryUserMode]="queryUserMode">Серия ТТН
        </app-query-string-filter>
        <app-query-string-filter parameterName="ttn_no" [contextFormGroup]="contextFormGroup"
                                 [queryUserMode]="queryUserMode">Номер ТТН
        </app-query-string-filter>
        <app-query-date-time-filter parameterName="ttn_date" [contextFormGroup]="contextFormGroup"
                                    [queryUserMode]="queryUserMode">Дата ТТН
        </app-query-date-time-filter>
      </ng-container>
      <app-query-stable-agent-filter parameterName="broker_agent_id" [contextFormGroup]="contextFormGroup"
                                     [queryUserMode]="queryUserMode">Фирма-перевозчик
      </app-query-stable-agent-filter>
      <app-query-lookup-combo-filter parameterName="transportation_kind_id" [lookupName]="'transportation-kind'"
                                     [contextFormGroup]="contextFormGroup"
                                     [queryUserMode]="queryUserMode">Вид трансп.
      </app-query-lookup-combo-filter>
      <app-query-string-filter parameterName="comments" [contextFormGroup]="contextFormGroup"
                               [queryUserMode]="queryUserMode">Коммент.
      </app-query-string-filter>