<div class="compact form itech-highlighted-sections" [formGroup]="contextFormGroup" *ngIf="contextFormGroup">

  <app-doc-validation-errors [validationErrors]="contextFormGroup.docLevelServerSideValidationErrors">
  </app-doc-validation-errors>

  <section class="form-block itech-block-normal">

    <h3>Мероприятие (событие)</h3>

    <div class="form-group itech-group-whole-line" *ngIf="contextFormGroup.get('createdTime').value">
      <label></label>
      <div class="itech-control-desc">
        Карточка создана:
        {{contextFormGroup.get('createdTime').value | rtime}},
        {{contextFormGroup.get('createdUser').value | lookup: 'user' | async}}
      </div>
    </div>

    <ng-container *ngIf="!contextFormGroup.get('animalStableMode').value">
      <div class="form-group" *ngIf="animal && contextFormGroup.get('animalId').value">
        <label class="required">Зверь</label>
        <app-link-button [id]="contextFormGroup.controls['animalId'].value" [lookupName]="'animal'"></app-link-button>
      </div>

      <div class="form-group" *ngIf="animal && contextFormGroup.get('id').value
          && contextFormGroup.get('animalSubTypeId').value && contextFormGroup.get('animalTypeId').value">
        <label>Подвид</label>
        <label>{{contextFormGroup.get('animalSubTypeId').value|lookup:'animal-subtype' + contextFormGroup.get('animalTypeId').value|async}}</label>
      </div>

      <div class="form-group"
           *ngIf="animal && contextFormGroup.get('animalId').value && !animal.animalGroup && showAddManyAnimal">
        <label></label>
        <div class="itech-control-xlarge">
          <a class="itech-link in-table-link" (click)="setStableAnimalMode()">
            Выбрать животных в поднадзорном объекте
          </a>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="contextFormGroup.get('animalStableMode').value">
      <app-link-button [preText]="'Все животные в'" [id]="animal.stableId" [lookupName]="'stable'"></app-link-button>
      <div style="height:300px">
        <app-grid [pagination]="false" [getRowStyle]="getRowStyle" [columnDefs]="gridColumnDefs"
                  (gridReady)="onGridReady($event)" [rowData]="animal.searchResults" [rowMultiSelectWithClick]="true"
                  [rowSelection]="'multiple'" [uniqueGridName]="'EditAnimalEventCommonFormGrid'"
                  (selectionChanged)="onGridSelectionChanged($event)"></app-grid>
      </div>
    </ng-container>

    <div class="form-group" *ngIf="contextFormGroup.get('stableId').value">
      <label class="required">{{ contextFormGroup.get('stables').value.length
        ? 'Поднадзорный объект' : 'Поднадзорные объекты' }}</label>
      <app-link-button [id]="contextFormGroup.get('stableId').value" [lookupName]="'stable'"></app-link-button>
      <div *ngFor="let stableId of contextFormGroup.get('stables').value" class="itech-text-field itech-control-xlarge">
        <a class="itech-person-link"
           (click)="appNavigationService.performStableEditing(stableId)">
          {{stableId|lookup:'stable'|async}}
        </a>
      </div>
    </div>

    <div class="form-group">
      <label for="datePlanning">Дата план / факт / №</label>
      <label for="datePlanning" aria-haspopup="true" role="tooltip"
             class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'datePlanning')">
        <input type="date" id="datePlanning" class="itech-control-normal" formControlName="datePlanning">
        <app-validation-tooltip [input]="contextFormGroup.controls['datePlanning']"></app-validation-tooltip>
      </label>
      &nbsp;&nbsp;
      <label for="date" aria-haspopup="true" role="tooltip"
             class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'date')">
        <input type="date" id="date" class="itech-control-normal" formControlName="date">
        <app-validation-tooltip [input]="contextFormGroup.controls['date']"></app-validation-tooltip>
        <clr-icon *ngIf="contextFormGroup.get('mercuryId').value" shape="lock" class="immutable-field-icon"></clr-icon>
      </label>
      &nbsp;&nbsp;
      <label for="examNo" aria-haspopup="true" role="tooltip"
             class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'examNo')">
        <input type="text" id="examNo" placeholder="№" class="itech-control-normal"
               formControlName="examNo">
        <app-validation-tooltip [input]="contextFormGroup.controls['examNo']"></app-validation-tooltip>
        <clr-icon *ngIf="contextFormGroup.get('mercuryId').value" shape="lock" class="immutable-field-icon"></clr-icon>
      </label>
    </div>

    <div class="form-group">
      <label></label>

      <div class="checkbox-inline">
        <input type="checkbox" id="performedByAgent" formControlName="performedByAgent">
        <label for="performedByAgent">Проведено самостоятельно хозяйств. субъектом</label>
      </div>
    </div>

    <div class="form-group">
      <label for="eventTypeId" class="required">Вид мероприятия</label>
      <app-text-lookup class="itech-control-xlarge" [contextControlId]="'eventTypeId'" [sorted]="true"
                       [disabled]="contextFormGroup.get('id').value" [filterArrayIncludeValue]="availableEvents"
                       [contextControlName]="'eventTypeId'" [lookupName]="'event-type' + (animal? '/animal': '/stable')"
                       [commentsFieldName]="'comments'" [contextFormGroup]="contextFormGroup" (change)="changeResultLookupNameAndUpdateGrid()"></app-text-lookup>
    </div>

    <div class="form-group" *ngIf="eventType && (eventType.id === 32 || eventType.id === 36)">
      <label for="slaughterPlace">Место убоя</label>
      <app-combo-lookup class="itech-control-large" [contextControlId]="'slaughterPlace'" [sorted]="true"
                        [contextControlName]="'slaughterPlace'" [lookupName]="'animal-slaughter-place'"
                        [contextFormGroup]="contextFormGroup"></app-combo-lookup>
      &nbsp;&nbsp;&nbsp;
      <div class="checkbox-inline" *ngIf="eventType.id === 32">
        <input type="checkbox" id="sanitarySlaughter" formControlName="sanitarySlaughter">
        <label for="sanitarySlaughter">Санитарный убой</label>
      </div>
    </div>

    <div class="form-group" *ngIf="eventType && (eventType.id === 32 || eventType.id === 36)
    && contextFormGroup.get('slaughterPlace').value == 2">
      <label for="slaughterInstitutionId" class="required">Убойный пункт</label>
      <app-text-lookup class="itech-control-xlarge" [contextControlId]="'slaughterInstitutionId'" [sorted]="true"
                       [contextControlName]="'slaughterInstitutionId'" [lookupName]="'slaughtery-institution'"
                       [contextFormGroup]="contextFormGroup"></app-text-lookup>
    </div>

    <div class="form-group" *ngIf="eventType && (eventType.id === 32 || eventType.id === 36)">
      <label for="temperature">{{!rangeTemperature ? 'Температура' : 'Диап. температуры'}}</label>
      <div style="display:flex;align-items:center">
        {{rangeTemperature ? 'от&nbsp;' : ''}}
        <label for="temperature" aria-haspopup="true" role="tooltip"
               class="tooltip tooltip-validation tooltip-md"
               [class.invalid]="isInvalid(contextFormGroup, 'temperature')">
          <input type="text" id="temperature" placeholder="°C" class="itech-control-tiny"
                 formControlName="temperature" (keypress)="processMoneyKeypress($event)">
          <app-validation-tooltip [input]="contextFormGroup.controls['temperature']"></app-validation-tooltip>
        </label>
        {{rangeTemperature ? '&nbsp;до&nbsp;' : ''}}
        <label for="temperatureTo" *ngIf="rangeTemperature"
               aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
               [class.invalid]="isInvalid(contextFormGroup, 'temperatureTo')">
          <input type="text" id="temperatureTo" placeholder="°C" class="itech-control-tiny"
                 formControlName="temperatureTo" (keypress)="processMoneyKeypress($event)">
          <app-validation-tooltip [input]="contextFormGroup.controls['temperatureTo']"></app-validation-tooltip>
        </label>
        <label style="display:flex;align-items:center">
          <button class="btn btn-icon-link" style="margin:0;padding:0;max-height:20px;min-width:20px;border:none" (click)="changeRangeTemperature()">
            <clr-icon shape="switch" style="display:block" class="has-badge"></clr-icon>
          </button>
        </label>
      </div>
    </div>

    <div class="form-group" *ngIf="eventType && (eventType.id === 32 || eventType.id === 36)">
      <label for="temperature">Состояние слизистой</label>
      <label for="mucousCondition" aria-haspopup="true" role="tooltip"
             class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'mucousCondition')">
        <input type="text" id="mucousCondition" placeholder="Состояние слизистой" class="itech-control-larger"
               formControlName="mucousCondition">
        <app-validation-tooltip [input]="contextFormGroup.controls['mucousCondition']"></app-validation-tooltip>
      </label>
    </div>

    <div class="form-group" *ngIf="eventType && eventType.eventKind !== 4">
      <label for="diseaseTypeId" class="required">Заболевание</label>
      <div>
        <app-text-lookup *ngIf="!addAllAvailableDiseases" class="itech-control-xlarge"
                         [contextControlId]="'diseaseTypeId'" [sorted]="true"
                         [contextControlName]="'diseaseTypeId'" [lookupName]="'disease-type'"
                         [commentsFieldName]="'kind'"
                         [contextFormGroup]="contextFormGroup"
                         [filterArrayIncludeValue]="availableDisease"></app-text-lookup>
        <div *ngIf="availableDisease && availableDisease.length">
          <div class="checkbox-inline">
            <input type="checkbox" id="addAllAvailableDiseases" [(ngModel)]="addAllAvailableDiseases"
                   (change)="changeAddAllAvailableDiseases()"
                   [ngModelOptions]="{standalone: true}">
            <label for="addAllAvailableDiseases">Добавить все заболевания по препарату</label>
          </div>
        </div>
        <div *ngFor="let diseaseId of contextFormGroup.get('diseases').value"
             class="itech-text-field itech-control-xlarge itech-text-wall">
          <label style="margin:0">
            {{diseaseId|lookup:'disease-type'|async}}
          </label>
        </div>

      </div>
    </div>

    <div class="form-group" *ngIf="eventType && eventType.eventKind === 1">
      <label for="laboratoryInstitutionId" class="required">Лаборатория</label>
      <app-combo-lookup class="itech-control-xlarge" [contextControlId]="'laboratoryInstitutionId'" [sorted]="true"
                        [contextControlName]="'laboratoryInstitutionId'" [lookupName]="'lab-institution'"
                        [contextFormGroup]="contextFormGroup"></app-combo-lookup>
      <clr-icon *ngIf="contextFormGroup.get('mercuryId').value" shape="lock" class="immutable-field-icon"></clr-icon>
    </div>

    <div *ngIf="eventType && eventType.eventKind === 1">
      <div *ngFor="let diagnosticTypeResult of diagnosticTypeResults.controls; index as i">
        <div class="form-group" [formGroup]="diagnosticTypeResult">
          <label for="diagnosticTypeId">Способ диагностики/Результат</label>
          <app-combo-lookup class="itech-control-medium" [contextControlId]="'diagnosticTypeId' + i" [sorted]="true"
                            [contextControlName]="'diagnosticTypeId'" [lookupName]="'diagnostic-type'" (change)="diagnosticTypeIdChange(i)"
                            [contextFormGroup]="diagnosticTypeResult"></app-combo-lookup>

          &nbsp;&nbsp;
          <app-combo-lookup *ngIf="diagnosticTypeResult.get('diagnosticTypeId').value" class="itech-control-normal" [contextControlId]="'diagnosticResultId' + i" [sorted]="false"
                            [contextControlName]="'diagnosticResultId'" [lookupName]="'diagnostic-result'"
                            [contextFormGroup]="diagnosticTypeResult"></app-combo-lookup>

          &nbsp;&nbsp;
          <label for="diagnosticResultValue{{i}}" *ngIf="diagnosticTypeResult.get('diagnosticResultId').value == 4 && diagnosticTypeResult.get('diagnosticTypeId').value"
                 aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md"
                 [class.invalid]="isInvalid(diagnosticTypeResult, 'diagnosticResultValue')">
            <input type="text" id="diagnosticResultValue{{i}}" placeholder="Цифровое значение" class="itech-control-small"
                   formControlName="diagnosticResultValue" (keypress)="processMoneyKeypress($event)">
            <app-validation-tooltip [input]="diagnosticTypeResult.controls['diagnosticResultValue']"></app-validation-tooltip>
          </label>
          <clr-icon *ngIf="contextFormGroup.get('mercuryId').value" shape="lock" class="immutable-field-icon"></clr-icon>
        </div>
      </div>
    </div>

    <div class="form-group" *ngIf="eventType && eventType.eventKind === 1">
      <label for="eventIndicatorId">Наименование показателя</label>
      <app-combo-lookup class="itech-control-xlarge" [contextControlId]="'eventIndicatorId'" [sorted]="true"
                        [contextControlName]="'eventIndicatorId'" [lookupName]="'event-indicator'"
                        [contextFormGroup]="contextFormGroup"></app-combo-lookup>
      <clr-icon *ngIf="contextFormGroup.get('mercuryId').value" shape="lock" class="immutable-field-icon"></clr-icon>
    </div>

    <div class="form-group" *ngIf="eventType && eventType.eventKind === 1">
      <label for="testMaterialId">Исследуемый материал</label>
      <app-combo-lookup class="itech-control-xlarge" [contextControlId]="'testMaterialId'" [sorted]="true"
                        [contextControlName]="'testMaterialId'" [lookupName]="'test-material'"
                        [contextFormGroup]="contextFormGroup"></app-combo-lookup>
    </div>

    <div class="form-group" *ngIf="contextFormGroup.controls['testMaterialId'].value == 15">
      <label for="useRestriction">Кол-во продукции, кг. / ограничения</label>
      <label for="restrictedAmountKg" aria-haspopup="true" role="tooltip"
             class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'restrictedAmountKg')">
        <input type="text" id="restrictedAmountKg" placeholder="кол прод кг." class="itech-control-small"
               formControlName="restrictedAmountKg">
        <app-validation-tooltip [input]="contextFormGroup.controls['restrictedAmountKg']"></app-validation-tooltip>
      </label>
      &nbsp;&nbsp;&nbsp;
      <label for="restrictedSubamountKg" aria-haspopup="true" role="tooltip"
             class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'restrictedSubamountKg')">
        <input type="text" id="restrictedSubamountKg" placeholder="кол суб/прод кг." class="itech-control-small"
               formControlName="restrictedSubamountKg">
        <app-validation-tooltip [input]="contextFormGroup.controls['restrictedSubamountKg']"></app-validation-tooltip>
      </label>
      &nbsp;&nbsp;&nbsp;
      <app-combo-lookup class="itech-control-large" [contextControlId]="'useRestriction'" [sorted]="true"
                        [contextControlName]="'useRestriction'" [lookupName]="'product-use-restriction'"
                        [contextFormGroup]="contextFormGroup"></app-combo-lookup>
    </div>

    <div class="form-group" *ngIf="eventType && (eventType.id === 29 || eventType.id === 30)">
      <label *ngIf="eventType.id === 29" class="required">Получатель</label>
      <label *ngIf="eventType.id === 30" class="required">Отправитель</label>
      <app-link-button [id]="contextFormGroup.controls['otherAgentId'].value" [lookupName]="'agent'"></app-link-button>
      <button *ngIf="!contextFormGroup.controls['id'].value" type="button" (click)="searchAgent()"
              class="btn btn-danger btn-link itech-inline-tool-btn" title="Выбрать владельца">
        <clr-icon shape="search"></clr-icon>
      </button>
    </div>

    <div class="form-group" *ngIf="eventType && (eventType.id === 29 || eventType.id === 30)">
      <label *ngIf="eventType.id === 29" class="required">Место получения</label>
      <label *ngIf="eventType.id === 30" class="required">Место отправления</label>
      <app-link-button [id]="contextFormGroup.controls['otherStableId'].value"
                       [lookupName]="'stable'"></app-link-button>
      <button *ngIf="!contextFormGroup.controls['id'].value" type="button" (click)="searchStable()"
              class="btn btn-danger btn-link itech-inline-tool-btn" title="Выбрать местонахождение">
        <clr-icon shape="search"></clr-icon>
      </button>
    </div>

    <div class="form-group"
         *ngIf="contextFormGroup.controls['id'].value && eventType && equalsSome(eventType.id, 14, 29, 30)">
      <label *ngIf="equalsSome(eventType.id, 14, 29)">Новое животное</label>
      <label *ngIf="eventType.id === 30">Исходное животное</label>
      <app-link-button [id]="contextFormGroup.controls['otherAnimalId'].value"
                       [lookupName]="'animal'"></app-link-button>
      <button *ngIf="!contextFormGroup.controls['id'].value" type="button" (click)="searchAnimal()"
              class="btn btn-danger btn-link itech-inline-tool-btn" title="Выбрать животное">
        <clr-icon shape="search"></clr-icon>
      </button>
    </div>

    <div *ngIf="eventType && (trappedToQuarantine || []).length" class="form-group">
      <label for="quarantineId">В рамках карантина</label>
      <app-combo-lookup *ngIf="!contextFormGroup.get('quarantineId').value"
                        class="itech-control-xlarge" [contextControlId]="'quarantineId'" [sorted]="true"
                        [contextControlName]="'quarantineId'" [lookupItems]="trappedToQuarantine"
                        [contextFormGroup]="contextFormGroup"></app-combo-lookup>
      <app-link-button *ngIf="contextFormGroup.get('quarantineId').value"
                       [id]="contextFormGroup.get('quarantineId').value" [lookupName]="'quarantine'"></app-link-button>
      <button type="button" class="btn btn-link"
              *ngIf="contextFormGroup.get('quarantineId').value"
              (click)="contextFormGroup.get('quarantineId').setValue(null)"
              style="max-width: 20px;min-width:0;padding:0;margin:0;">
        <clr-icon shape="times" size="16" style="color:red"></clr-icon>&nbsp;
      </button>
    </div>

    <div class="form-group" *ngIf="eventType && eventType.eventKind === 5">
      <label for="square">Площадь обработки (кв.м.)</label>
      <label for="square" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'square')">
        <input type="text" id="square" placeholder="0.00" class="itech-control-normal"
               formControlName="square" (keypress)="processMoneyKeypress($event)">
        <app-validation-tooltip [input]="contextFormGroup.controls['square']"></app-validation-tooltip>
      </label>
    </div>

    <div class="form-group" *ngIf="eventType && eventType.eventKind === 1">
      <label></label>
      <div class="checkbox-inline">
        <input type="checkbox" id="secondary" formControlName="secondary">
        <label for="secondary">Повторное исследование</label>
      </div>
    </div>

    <div class="form-group" *ngIf="eventType">
      <label></label>
      <div class="checkbox-inline">
        <input type="checkbox" id="isForced" [ngModel]="contextFormGroup.get('isForced').value"
               [ngModelOptions]="{standalone: true}" (click)="changeIsForced()">
        <label for="isForced">{{eventType.id === 42 ? 'Вынужденный убой' : 'Вынужденное'}}</label>
      </div>
    </div>

    <div class="form-group" *ngIf="eventType && contextFormGroup.get('isForced').value">
      <label class="required">Причина вынужденности</label>
      <app-combo-lookup class="itech-control-large"
                        [contextControlId]="'forcedReasonId'"
                        [contextControlName]="'forcedReasonId'"
                        [lookupName]="'event-forced-reason'"
                        [contextFormGroup]="contextFormGroup"></app-combo-lookup>
    </div>

    <div class="form-group" *ngIf="eventType && eventType.id === 29">
      <label for="deathReasonId">Причина выбытия</label>
      <app-combo-lookup class="itech-control-large" [disabled]="contextFormGroup.get('id').value"
                        [contextControlId]="'deathReasonId'"
                        [contextControlName]="'deathReasonId'"
                        [lookupName]="'death-reason'"
                        [contextFormGroup]="contextFormGroup"></app-combo-lookup>
    </div>

    <div class="form-group itech-double-height v-resize">
      <label for="comments">Примечания</label>
      <label for="comments" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'comments')">
        <app-input-text [contextId]="'comments'" [classNames]="'itech-control-xlarge itech-text-wall'"
                        [formControlObj]="contextFormGroup.get('comments')" [htmlType]="2"
                        [placeholder]="'Укажите дополнительную информацию'"></app-input-text>
        <app-validation-tooltip [input]="contextFormGroup.controls['comments']"></app-validation-tooltip>
      </label>
    </div>


  </section>

  <section class="form-block itech-block-normal"
           *ngIf="eventType && (eventType.eventKind === 1 || eventType.eventKind === 2 || eventType.eventKind === 3) &&
           contextFormGroup.get('date').value">

    <h3>Результаты</h3>

    <div *ngIf="!contextFormGroup.get('id').value">
      <a class="itech-link in-table-link" (click)="addManyAnimals()">Добавить зверей...</a>
    </div>

    <div *ngIf="animalEventSingleResults.controls.length && !contextFormGroup.get('id').value && +contextFormGroup.get('eventKind').value !== 4">
      <label>{{getLabelWithCounts(false)}}</label>
      <div style="display: flex; max-height: 400px">
        <app-grid-editable #appGridSingleChild (gridReady)="onSingleGridReady($event)" [columnDefs]="gridResultSingleColDefs"
                           [formArray]="animalEventSingleResults" [widthGrid]="1050" [withEmptyRow]="false" [maxHeight]="400"
                           [withAdd]="false" [withCopy]="false" [defaultGridHeight]="50" (deletingRow)="deletingRow($event)">
        </app-grid-editable>
      </div>
    </div>

    <div *ngIf="animalEventGroupResults.controls.length && !contextFormGroup.get('id').value && +contextFormGroup.get('eventKind').value !== 4"
        style="padding-top: 15px">
      <label>{{getLabelWithCounts(true)}}</label>
      <div style="display: flex">
        <app-grid-editable #appGridGroupChild (gridReady)="onGroupGridReady($event)" [columnDefs]="gridResultGroupColDefs"
                           [formArray]="animalEventGroupResults" [widthGrid]="1050" [withEmptyRow]="false" [maxHeight]="400"
                           [withAdd]="false" [withCopy]="false" [defaultGridHeight]="50" (deletingRow)="deletingRow($event)">
        </app-grid-editable>
      </div>
    </div>

    <div class="form-group" *ngIf="animal && !animal.animalGroup && contextFormGroup.get('id').value">
      <label for="examResult" class="required">Результат</label>
      <app-combo-lookup class="itech-control-xlarge" [contextControlId]="'examResult'" [sorted]="true"
                        [contextControlName]="'examResult'" [disabledChoices]="eventType.eventKind === 1 ? [4] : []"
                        [contextFormGroup]="contextFormGroup" [lookupItems]="availableResults"></app-combo-lookup>
      <clr-icon *ngIf="contextFormGroup.get('mercuryId').value" shape="lock" class="immutable-field-icon"></clr-icon>
    </div>

    <div class="form-group" *ngIf="animal && animal.animalGroup && contextFormGroup.get('id').value">
      <label for="countPositive">Положит / Отрицат / Среагировало</label>
      <label for="countPositive" aria-haspopup="true" role="tooltip"
             class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'countPositive')">
        <input type="text" id="countPositive" placeholder="положит" class="itech-control-small"
               formControlName="countPositive">
        <app-validation-tooltip [input]="contextFormGroup.controls['countPositive']"></app-validation-tooltip>
      </label>
      <label for="countNegative" aria-haspopup="true" role="tooltip"
             class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'countNegative')">
        <input type="text" id="countNegative" placeholder="отриц" class="itech-control-small"
               formControlName="countNegative">
        <app-validation-tooltip [input]="contextFormGroup.controls['countNegative']"></app-validation-tooltip>
      </label>
      <label for="countRespond" aria-haspopup="true" role="tooltip"
             class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'countRespond')">
        <input type="text" id="countRespond" placeholder="реаг" class="itech-control-small"
               formControlName="countRespond">
        <app-validation-tooltip [input]="contextFormGroup.controls['countRespond']"></app-validation-tooltip>
      </label>
    </div>

    <div class="form-group" *ngIf="animal && animal.animalGroup && contextFormGroup.get('id').value">
      <label for="countExamined">
        {{getCaptionExamined()}} / {{getCaptionIsolated()}}
      </label>
      <label for="countExamined" aria-haspopup="true" role="tooltip"
             class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'countExamined')">
        <input type="text" id="countExamined" [placeholder]="getCaptionExamined(true)" class="itech-control-small"
               formControlName="countExamined">
        <app-validation-tooltip [input]="contextFormGroup.controls['countExamined']"></app-validation-tooltip>
      </label>
      <label for="countIsolated" aria-haspopup="true" role="tooltip"
             class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'countIsolated')">
        <input type="text" id="countIsolated" [placeholder]="getCaptionIsolated(true)" class="itech-control-small"
               formControlName="countIsolated">
        <app-validation-tooltip [input]="contextFormGroup.controls['countIsolated']"></app-validation-tooltip>
      </label>
    </div>

    <div class="form-group" *ngIf="animal && contextFormGroup.get('id').value && eventType && eventType.id === 32
                                   ? animal.animalGroup
                                     ? +contextFormGroup.get('countIsolated').value > 0
                                     : +contextFormGroup.get('examResult').value === 1
                                   : false"
         style="min-height:55px;">
      <label for="countExamined">
        Причина запрета на убой
      </label>
      <label for="reasonIsolated" aria-haspopup="true" role="tooltip"
             class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'reasonIsolated')">
        <app-input-text [contextId]="'reasonIsolated'" [classNames]="'itech-control-xlarge itech-text-wall no-resize'"
                        [formControlObj]="contextFormGroup.get('reasonIsolated')" [htmlType]="2"
                        [placeholder]="'Укажите причину, по которой установлен запрет на убой'"></app-input-text>
        <app-validation-tooltip [input]="contextFormGroup.controls['reasonIsolated']"></app-validation-tooltip>
      </label>
    </div>

    <div class="form-group" *ngIf="animal && !animal.animalGroup && contextFormGroup.get('id').value">
      <label for="animalState" class='required'>Состояние</label>
      <app-combo-lookup class="itech-control-xlarge" [contextControlId]="'animalState'" [sorted]="true"
                        [contextControlName]="'animalState'" [lookupName]="'animal-state'"
                        [contextFormGroup]="contextFormGroup"></app-combo-lookup>
      <clr-icon *ngIf="contextFormGroup.get('mercuryId').value" shape="lock" class="immutable-field-icon"></clr-icon>
    </div>

    <div class="form-group" *ngIf="animal && contextFormGroup.get('diseaseTypeId').value">
      <label for="diseaseSerotypeId">Серотип</label>
      <app-combo-lookup class="itech-control-xlarge" [contextControlId]="'diseaseSerotypeId'" [sorted]="true"
                        [contextControlName]="'diseaseSerotypeId'"
                        [lookupName]="'disease-serotype' + contextFormGroup.get('diseaseTypeId').value"
                        [contextFormGroup]="contextFormGroup"></app-combo-lookup>
    </div>

  </section>

  <section class="form-block itech-block-normal" *ngIf="animal && animal.animalTypeId && animal.animalGroup &&
    (contextFormGroup.get('id').value || (!contextFormGroup.get('id').value && +contextFormGroup.get('eventKind').value === 4))">

    <h3>Изменение поголовья</h3>

    <ng-container *ngFor="let count of counts.controls; index as i">

      <div class="form-group itech-group-whole-line" [formGroup]="count">
        <label></label>
        <app-combo-lookup class="itech-control-xlarge" [contextControlId]="'animalSubTypeId' + i"
                          [contextControlName]="'animalSubTypeId'" [required]="false" [sorted]="true"
                          [lookupName]="'animal-subtype' + animal.animalTypeId"
                          [disabled]="!contextFormGroup.get('date').value"
                          [contextFormGroup]="count"></app-combo-lookup>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <label for="count{{i}}" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
               [class.invalid]="isInvalid(count, 'countDiff')">
          <input type="text" id="count{{i}}" placeholder="Кол-во" class="itech-control-small"
                 formControlName="countDiff" [attr.disabled]="!contextFormGroup.get('date').value ? true : undefined">
          <app-validation-tooltip [input]="count.controls['countDiff']"></app-validation-tooltip>
        </label>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <app-combo-lookup class="itech-control-medium" [contextControlId]="'gender' + i"
                          [contextControlName]="'gender'" [lookupName]="'gender-animal-group'"
                          [contextFormGroup]="count"></app-combo-lookup>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <div class="itech-text-field" *ngIf="count.controls['countBefore'].value">
          Имеется {{count.controls['countBefore'].value}}
        </div>
      </div>
    </ng-container>
  </section>

  <section class="form-block itech-block-normal" *ngIf="eventType && eventType.eventKind !== 4">

    <h3>Препараты</h3>

    <ng-container *ngFor="let drug of drugs.controls; index as i">

      <div class="form-group" [formGroup]="drug">
        <label for="drugTypeId{{i}}">
          <span class="checkbox-inline">
            <input type="checkbox" id="ownMoney{{i}}" formControlName="__own_money"
                   (change)="changeOwnDrug(i, drug.get('__own_money').value)">
            <label for="ownMoney{{i}}">Приобретено самостоятельно</label>
          </span>
        </label>
        <app-text-lookup *ngIf="drug.controls['__own_money'].value"
                         class="itech-control-larger" [contextControlId]="'drugTypeId' + i"
                         [contextControlName]="'drugTypeId'" [required]="false"
                         [commentsFieldName]="'comments'" [sorted]="true"
                         [lookupName]="'drug-type'"
                         [filterValueInArrayValue1]="contextFormGroup.get('eventTypeId').value"
                         [filterValueInArrayValue2]="contextFormGroup.get('diseaseTypeId').value"
                         [contextFormGroup]="drug"></app-text-lookup>

        <app-link-button *ngIf="!drug.controls['__own_money'].value && drug.controls['drugId'].value"
                         [id]="drug.controls['drugId'].value" [lookupName]="'drug'"
                         [widthControl]="378"></app-link-button>
        <div class="itech-text-field itech-control-larger" style="width:378px"
             *ngIf="!drug.controls['__own_money'].value && !drug.controls['drugId'].value">
          <a class="itech-form-preselect-link"
             (click)="searchDrug(drug)">
            &lt;&lt;Выберите препарат&gt;&gt;
          </a>
        </div>
        <button type="button" class="btn btn-danger btn-link itech-inline-tool-btn"
                title="Выбрать препарат" *ngIf="!drug.controls['__own_money'].value"
                (click)="searchDrug(drug)">
          <clr-icon shape="search"></clr-icon>
        </button>

        &nbsp;&nbsp;&nbsp;&nbsp;
        <label for="spentValueStr{{i}}" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
               [class.invalid]="isInvalid(drug, 'spentValueStr')">
          <input type="text" id="spentValueStr{{i}}" placeholder="Объем" class="itech-control-small"
                 formControlName="spentValueStr" (keypress)="processMoneyKeypressDrug($event)"
                 (change)="changeDecimalStrValue(drug, 'spentValueStr')">
          <app-validation-tooltip [input]="drug.controls['spentValueStr']"></app-validation-tooltip>
        </label>

        <div class="itech-text-field" style="width: 70px">
          {{drug.get('drugId').value|lookup: 'in-drug-unit-type':true|async}}
        </div>

        <app-combo-lookup class="itech-control-tiny" [contextControlId]="'sensibility' + i" [sorted]="true"
                          [contextControlName]="'sensibility'" [lookupName]="'sensibility-type'"
                          [contextFormGroup]="drug"></app-combo-lookup>

      </div>

      <div class="form-group" *ngIf="!drug.controls['__own_money'].value && !drug.controls['drugId'].value">
        <label></label>
        <div class="itech-control-desc itech-control-xlarge">
          Выберите имеющийся в наличии в ветеринарной службе препарат, или укажите галочку <i>"Приобретено
          самостоятельно"</i>
        </div>
      </div>
    </ng-container>

  </section>

  <section class="form-block itech-block-normal">

    <h3>Файлы</h3>

    <div *ngFor="let file of files.controls; index as i" [formGroup]="getFormGroup(file)"
         style="display:flex;padding-top:0.2rem">
      <div>
        <label></label>
        <app-combo-lookup class="itech-control-xlarge" [contextControlId]="'typeId' + i" [sorted]="true"
                          [contextControlName]="'typeId'" [lookupName]="'animal-event-file-type'"
                          [contextFormGroup]="file"></app-combo-lookup>
      </div>
      <div style="padding-left: 0.5rem">
        <app-single-file [contextFormGroup]="file" [contextControlId]="i"
                         [uriUpload]="'/animal/event/upload-file'"
                         [preTitle]="'Прикрепленный файл: '">
        </app-single-file>
      </div>
    </div>
    <button type="button" class="btn btn-link" (click)="addNewFile()">
      <clr-icon shape="plus"></clr-icon>
      Добавить
    </button>
  </section>
</div>
