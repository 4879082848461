<ng-container [formGroup]="contextFormGroup">


  <div class="form-group itech-group-whole-line">
    <label></label>
    <div class="clr-control-container clr-control-inline">
      <div class="clr-radio-wrapper itech-highlight-checked" *ngFor="let k of kind; index as i">
        <ng-container *ngIf="i>0">&nbsp;&nbsp;&nbsp;</ng-container>
        <input id="{{rndToken}}address{{k.id}}" type="radio" formControlName="kind" value="{{k.id}}" class="clr-radio" [attr.disabled]="disabled?true:undefined"/>
        <label for="{{rndToken}}address{{k.id}}">{{k.caption}}</label>
      </div>
    </div>
  </div>

  <ng-container *ngIf="contextFormGroup.get('kind').value == '0'">

    <div class="form-group">
      <label for="{{rndToken}}regionIdCityId" [class.required]="required">Населенный пункт</label>
      <app-text-lookup class="itech-control-xlarge" [contextControlId]="rndToken + 'regionIdCityId'" [sorted]="true"
                       [contextControlName]="'regionIdCityId'" [lookupName]="'addr-city'"
                       [commentsFieldName]="'comments'" [disabled]="disabled"
                       [contextFormGroup]="contextFormGroup" (change)="regionIdCityIdChanged($event)"></app-text-lookup>
    </div>

    <div class="form-group">
      <label for="{{rndToken}}streetId" [class.required]="required">Улица</label>
      <app-text-lookup class="itech-control-xlarge" [contextControlId]="rndToken + 'streetId'"
                       [contextControlName]="'streetId'" [sorted]="true"
                       [lookupName]="'addr-street-' + contextFormGroup.get('regionId').value + '-' + contextFormGroup.get('cityId').value"
                       [contextFormGroup]="contextFormGroup" [disabled]="disabled"></app-text-lookup>
    </div>

    <div class="form-group">
      <label for="{{rndToken}}house">№ дома/строения/квартиры</label>
      <label for="{{rndToken}}house" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid('house')">
        <input type="text" id="{{rndToken}}house" formControlName="house" class="itech-control-medium" placeholder="дом"
               [attr.disabled]="disabled?true:undefined">
        <app-validation-tooltip [input]="contextFormGroup.controls['house']"></app-validation-tooltip>
      </label>
      <label for="{{rndToken}}building" aria-haspopup="true" role="tooltip"
             class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid('building')">
        <input type="text" id="{{rndToken}}building" formControlName="building" class="itech-control-small"
               placeholder="стр" [attr.disabled]="disabled?true:undefined">
        <app-validation-tooltip [input]="contextFormGroup.controls['building']"></app-validation-tooltip>
      </label>
      <label for="{{rndToken}}room" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid('room')">
        <input type="text" id="{{rndToken}}room" formControlName="room" class="itech-control-small" placeholder="кв"
               [attr.disabled]="disabled?true:undefined">
        <app-validation-tooltip [input]="contextFormGroup.controls['room']"></app-validation-tooltip>
      </label>
    </div>
    <div class="form-group">
      <label for="{{rndToken}}other3">Адрес (текстом)</label>
      <label for="{{rndToken}}other3" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid('other')">
        <input type="text" id="{{rndToken}}other3" formControlName="other" class="itech-control-xlarge"
               placeholder="Адрес (текстом)" [attr.disabled]="disabled?true:undefined">
        <app-validation-tooltip [input]="contextFormGroup.controls['other']"></app-validation-tooltip>
      </label>
    </div>

  </ng-container>

  <ng-container *ngIf="contextFormGroup.get('kind').value == '1'">
    <div class="form-group">
      <label for="{{rndToken}}country" [class.required]="required">Страна</label>
      <app-text-lookup class="itech-control-xlarge" [contextControlId]="rndToken + 'country'" [sorted]="true"
                       [contextControlName]="'countryId'" [lookupName]="'country'"
                       [contextFormGroup]="contextFormGroup" [disabled]="disabled"></app-text-lookup>
    </div>
    <div class="form-group" *ngIf="contextFormGroup.get('countryId').value === 'RUS'">
      <label for="{{rndToken}}subjfed" [class.required]="required">Субъект РФ</label>
      <app-text-lookup class="itech-control-xlarge" [contextControlId]="rndToken + 'subjfed'" [sorted]="true"
                       [contextControlName]="'subjfedId'" [lookupName]="'addr-subjfed'"
                       [contextFormGroup]="contextFormGroup" [disabled]="disabled"></app-text-lookup>
    </div>
    <div class="form-group">
      <label for="{{rndToken}}other1">Адрес (текстом)</label>
      <label for="{{rndToken}}other1" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid('other')">
        <input type="text" id="{{rndToken}}other1" formControlName="other" class="itech-control-xlarge"
               placeholder="Адрес (текстом)" [attr.disabled]="disabled?true:undefined">
        <app-validation-tooltip [input]="contextFormGroup.controls['other']"></app-validation-tooltip>
      </label>
    </div>
  </ng-container>

  <ng-container *ngIf="contextFormGroup.get('kind').value == '2'">
    <div class="form-group">
      <label for="{{rndToken}}region" [class.required]="required">Муниципальное образование</label>
      <app-text-lookup class="itech-control-xlarge" [contextControlId]="rndToken + 'region'" [sorted]="true"
                       [contextControlName]="'regionId'" [lookupName]="'addr-region'"
                       [contextFormGroup]="contextFormGroup" [disabled]="disabled"></app-text-lookup>
    </div>
    <div class="form-group">
      <label for="cadNumberStr">Кад.номер</label>
      <label for="cadNumberStr" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid('cadNumberStr')">
        <input type="text" id="cadNumberStr" placeholder="00:00:0000000:0000000" class="itech-control-xlarge"
               formControlName="cadNumberStr" [pattern]="cadNumberPattern" (change)="cadNumberStrChanged($event)">
        <app-validation-tooltip [input]="contextFormGroup.controls['cadNumberStr']"></app-validation-tooltip>
      </label>
    </div>
    <div class="form-group">
      <label for="coordsLatitude">Координаты</label>
      <label for="coordsLatitude" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid('coordsLatitude')" style="margin-right:0">
        <input type="text" id="costMin" class="itech-control-normal"
               placeholder="Широта" formControlName="coordsLatitude">
        <app-validation-tooltip [input]="contextFormGroup.controls['coordsLatitude']"></app-validation-tooltip>
      </label>
      <label>с.ш.</label>
      &nbsp;&nbsp;
      <label for="coordsLongitude" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid('coordsLongitude')" style="margin-right:0">
        <input type="text" id="coordsLongitude" class="itech-control-normal"
               placeholder="Долгота" formControlName="coordsLongitude">
        <app-validation-tooltip [input]="contextFormGroup.controls['coordsLongitude']"></app-validation-tooltip>
      </label>
      <label>в.д.</label>
    </div>
    <div class="form-group">
      <label for="{{rndToken}}other2" [class.required]="required">Адрес (текстом)</label>
      <label for="{{rndToken}}other2" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid('other')">
        <input type="text" id="{{rndToken}}other2" formControlName="other" class="itech-control-xlarge"
               placeholder="Адрес (текстом)" [attr.disabled]="disabled?true:undefined">
        <app-validation-tooltip [input]="contextFormGroup.controls['other']"></app-validation-tooltip>
      </label>
    </div>
  </ng-container>

  <ng-container *ngIf="contextFormGroup.get('kind').value == '3'">
    <div class="form-group">
      <label for="{{rndToken}}region3">Муниципальное образование</label>
      <app-text-lookup class="itech-control-xlarge" [validationTooltipRight]="true"
                       [contextControlId]="rndToken + 'region3'" [sorted]="true"
                       [contextControlName]="'regionId'" [lookupName]="'addr-region'"
                       [contextFormGroup]="contextFormGroup" [disabled]="disabled"
                       (change)="contextFormGroup.get('cityId').setValue(null)"></app-text-lookup>
    </div>
    <div class="form-group" *ngIf="contextFormGroup.get('regionId').value">
      <label for="{{rndToken}}city3">Населенный пункт</label>
      <app-text-lookup class="itech-control-xlarge" [validationTooltipRight]="true"
                       [contextControlId]="rndToken + 'city3'" [sorted]="true"
                       [contextControlName]="'cityId'"
                       [lookupName]="'addr-city' + contextFormGroup.get('regionId').value"
                       [contextFormGroup]="contextFormGroup" [disabled]="disabled"></app-text-lookup>
    </div>
    <div class="form-group" *ngIf="contextFormGroup.get('regionId').value">
      <label for="{{rndToken}}cadNumberStr3">Кадастровый номер</label>
      <label for="{{rndToken}}cadNumberStr3" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid('cadNumberStr')">
        <input type="text" id="{{rndToken}}cadNumberStr3" placeholder="00:00:0000000:0000000" class="itech-control-xlarge"
               formControlName="cadNumberStr" [attr.disabled]="disabled?true:undefined"
               [pattern]="cadNumberPattern">
        <app-validation-tooltip [input]="contextFormGroup.controls['cadNumberStr']"></app-validation-tooltip>
      </label>
    </div>
  </ng-container>
  <ng-container *ngIf="contextFormGroup.get('kind').value == '4'">
    <div class="form-group">
      <label class="required">Поднадзорный объект</label>
      <app-link-button [id]="contextFormGroupExtended.controls['stableId'].value" [lookupName]="'stable'"></app-link-button>
      <button type="button" class="btn btn-danger btn-link itech-inline-tool-btn" title="Выбрать поднадзорный объект"
              (click)="searchStable(contextFormGroupExtended)">
        <clr-icon shape="search"></clr-icon>
      </button>
    </div>
    <div class="form-group" *ngIf="!contextFormGroupExtended.controls['stableId'].value">
      <label></label>
      <div class="itech-control-desc itech-desc-error">Выберите поднадзорный объект</div>
    </div>
  </ng-container>

</ng-container>
