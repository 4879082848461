import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { AppFormGroup } from '../../../ui/controls/app-form-group';
import { ProductDataService } from '../../../logic/services/product-data.service';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormHelper } from '../../../ui/controls/form-helper';
import { EditExpertiseIndicatorsComponent } from './edit-expertise-indicators.component';
import { EditExpertiseCommonComponent } from './edit-expertise-common.component';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { MetadataService } from '../../../logic/services/metadata.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';

class Model {
  valid: any = {};
  dirty: any = {};
  expertises = [];
  lastProductExpertiseTypeId: any;

  private _selected: any;

  get selected() {
    return this._selected;
  }

  set selected(value: any) {
    if (this._selected !== value) {
      this._selected = value;
      this.onSelectedChange.next(value);
    }
  }

  onSelectedChange: EventEmitter<any> = new EventEmitter<any>();
}

@Component({
  selector: 'app-edit-product-expertises',
  templateUrl: './edit-product-expertises.component.html',
  styleUrls: ['./edit-product-expertises.all.css']
})
export class EditProductExpertisesComponent implements OnInit, OnDestroy {

  @Input() productId: number;

  model: Model = new Model();

  updateSyncStateTimer: any;
  updateSyncStatePending = false;

  private _expertiseId: number;
  @Input()
  get expertiseId(): number {
    return this._expertiseId;
  }

  set expertiseId(expertiseId: number) {
    if (expertiseId !== this._expertiseId) {
      this._expertiseId = expertiseId;
      this.trySelectExpertiseById(expertiseId);
    }
  }

  private _id: number;
  @Input()
  get id(): number {
    return this._id;
  }

  set id(id: number) {
    if (this._id !== id) {

      if (this.model.selected && this.expertiseForm) {
        Object.assign(this.model.selected.data, this.expertiseForm.value);
      }

      this._id = id;
      this.refreshFormDataInternal();
    }
  }

  public get expertiseForm(): AppFormGroup {
    return this.model.selected ? this.model.selected.form : undefined;
  }

  public storing = false;

  constructor(private productDataService: ProductDataService,
              public navigationService: AppNavigationService,
              private alertService: AlertService,
              private dataCachingService: DataCachingService,
              private waitingOverlay: GlobalWaitingOverlayService,
              private fb: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private metadataService: MetadataService,
              private lookupService: LookupSourceService,
              private cacheService: AddressPersonFioCacheService) {
  }

  ngOnInit() {
    this.updateSyncStateTimer = setInterval(() => this.refreshSyncStateInternal(false), 3000);
  }

  ngOnDestroy() {
    // если переходим на другую вкладку с выбранной сохраненной экспертизы - надо избавиться от хвоста в url
    if (this.router.isActive('/product-edit/' + this.id + '/expertises', false)) {
      this.router.navigate(['../..'], { relativeTo: this.route });
    }
    if (this.updateSyncStateTimer) {
      clearInterval(this.updateSyncStateTimer);
      this.updateSyncStateTimer = undefined;
    }
  }

  refreshFormDataInternal() {
    this.waitingOverlay.StartWaiting();

    const existing = this.dataCachingService.getCachedData('EditProductExpertises', this.id.toString());

    if (existing && existing.expertises) {
      this.model = existing;

      if (this.expertiseId) {
        this.trySelectExpertiseById(this.expertiseId);
      } else {
        if (this.model.selected) {
          this.tryNavigateToExpertise(this.model.selected.data.id);
        }
      }

      this.waitingOverlay.EndWaiting();
    } else {
      this.productDataService.getProductExpertises(this.id).subscribe(data => {
        this.setExpertisesModel(data);
        this.waitingOverlay.EndWaiting();
      }, error => {
        this.setExpertisesModel([]);
        this.waitingOverlay.EndWaiting();
      });
    }
  }

  setExpertisesModel(data: any[]) {

    const expertisesLookup = {};
    this.model.expertises = [];

    data.sort((el1, el2) => -el1.expertiseTime.localeCompare(el2.expertiseTime));

    data.forEach(val => {

      let rqData = expertisesLookup[val.id];
      if (!rqData) {
        rqData = {};
        expertisesLookup[val.id] = rqData;
      }
      rqData.data = val;
      this.model.expertises.push(rqData);
    });

    this.trySelectExpertiseById(this.expertiseId);

    this.dataCachingService.addToCache('EditProductExpertises', this.id.toString(), Object.assign({}, this.model));
  }

  trySelectExpertiseById(expertiseId: number) {
    if (!expertiseId) {
      this.setCurrentExpertise(undefined);
      return;
    }
    let request: any;
    if (this.model.expertises && this.model.expertises.length > 0) {
      request = expertiseId ? this.findExpertise(expertiseId) : this.model.expertises[0];
      request = request ? request : this.model.expertises[0];
    }
    if (request) {
      this.trySelectExpertise(request);
    }
  }

  trySelectExpertise(expertise: any) {
    const needSkipNavigate = this.model.selected && (this.model.selected === expertise
      || this.model.selected.data.id === expertise.data.id);

    this.setCurrentExpertise(expertise);
    // для firefox чтобы не вызвать цикличную навигацию по обращениям
    if (this.model.selected && expertise && expertise.data && !needSkipNavigate) {
      this.tryNavigateToExpertise(expertise.data.id);
    }
  }

  tryNavigateToExpertise(expertiseId: number) {
    if (expertiseId) {
      this.router.navigate(['/product-edit', this.id, 'expertises', expertiseId]);
    }
  }

  findExpertise(expertiseId: number) {
    for (const item of this.model.expertises) {
      if (item.data.id === expertiseId) {
        return item;
      }
    }
    return undefined;
  }

  store(startSync: boolean) {
    FormHelper.markAsSubmitted(this.expertiseForm);

    this.expertiseForm.updateValueAndValidity();

    if (!this.expertiseForm.valid) {
      return;
    }

    this.storing = true;

    Object.assign(this.model.selected.data, this.expertiseForm.value);

    this.productDataService.storeProductExpertiseInfo(this.model.selected.data, startSync).subscribe(val => {
      this.storing = false;
      this.model.selected.data.id = val.id;
      this.expertiseId = val.id;
      this.expertiseForm.markAsPristine();
      this.expertiseForm.markAsUntouched();
      this.refreshCurrentExpertise();
      FormHelper.setSingleFormGroupServerSideValidationErrors({}, this.model.selected, this.expertiseForm);

      (val.relatedProductExpertiseIds || []).forEach(relatedId => {
        this.cacheService.getProductExpertise(relatedId)
          .subscribe(related =>
            this.dataCachingService.removeCachedData('EditProductExpertises', related.productId.toString()));
      });
    }, error => {
      this.storing = false;
      FormHelper.setSingleFormGroupServerSideValidationErrors(error, this.model.selected, this.expertiseForm);
    });
  }

  cancelEdit() {
    if (this.model.selected.data.id) {
      delete this.model.selected.form;
      this.model.selected.loaded = false;
    } else {

      const ix = this.model.expertises.indexOf(this.model.selected);
      if (ix >= 0) {
        this.model.expertises.splice(ix, 1);
      }
      this.model.selected = this.model.expertises.length > 0 ? this.model.expertises[0] : undefined;
    }
    this.trySelectExpertise(this.model.selected);
  }

  refreshFormData() {
    this.model.expertises = undefined;
    this.lookupService.invalidateLookup('expertise-indicator-type');
    this.refreshFormDataInternal();
  }

  ensureSelectedFormGroup() {
    if (!this.model.selected.form) {

      const expertiseGroupDef = {
        productId: this.id
      };

      Object.assign(expertiseGroupDef, EditExpertiseCommonComponent.buildFormGroupDef(this.fb, this.model.selected));
      Object.assign(expertiseGroupDef, EditExpertiseIndicatorsComponent.buildFormArrayDef(this.fb, this.model.selected));

      this.model.selected.form = this.fb.group(expertiseGroupDef);

      this.model.selected.form.get('productExpertiseTypeId').valueChanges.subscribe(() => {
        if (this.model.lastProductExpertiseTypeId !== this.model.selected.form.get('productExpertiseTypeId').value) {
          this.model.selected.form.get('productExpertiseSubtypeId').setValue(undefined);
          this.model.lastProductExpertiseTypeId = this.model.selected.form.get('productExpertiseTypeId').value;
        }
      });
    }
  }

  refreshCurrentExpertise() {
    if (!this.model.selected) {
      return;
    }

    this.model.selected.loaded = false;
    this.setCurrentExpertise(this.model.selected);
  }

  setCurrentExpertise(item: any) {
    if (this.expertiseForm && this.model.selected && !this.model.selected.noAccess) {
      Object.assign(this.model.selected.data, this.expertiseForm.value);
    }

    this.model.selected = item;

    if (!this.model.selected) {
      return;
    }

    // Вводим контейнер для полезной информации уровня представления, которая будет храниться в разрезе обращений.
    // Компоненты с целью уменьшения общей нагрузки на приложение смогут наполнять этот контейнер данными,
    // вычисленными на клиенте или полученными отдельными запросами
    this.model.selected.viewData = this.model.selected.viewData ? this.model.selected.viewData : {};

    // для запоминания открытой вкладки в обращении - общее, начисления, выплаты
    this.model.selected.viewData.tabsActive = this.model.selected.viewData.tabsActive ? this.model.selected.viewData.tabsActive : {
      commonActive: true,
      chargesActive: false,
      paymentActive: false
    };

    this.ensureSelectedFormGroup();

    if (!this.model.selected.loaded) {
      this.waitingOverlay.StartWaiting();

      this.productDataService.getProductExpertiseForEdit(this.id, this.model.selected.data.id).subscribe(data => {
        this.model.selected.serverSideValidationErrors = [];
        Object.assign(this.model.selected.data, data);
        this.expertiseForm.patchValue(this.model.selected.data);

        this.setupExpertiseFormArrays();
        this.expertiseForm.updateValueAndValidity();
        this.model.selected.loaded = true;
        this.refreshSyncState(true);
        this.waitingOverlay.EndWaiting();
      }, () => {
        this.model.selected.noAccess = true;
        this.model.selected.loaded = true;
        this.model.selected.data.socServices = [];
        this.refreshSyncState(true);

        this.waitingOverlay.EndWaiting();
      });
    } else {
      this.expertiseForm.patchValue(this.model.selected.data);
      this.setupExpertiseFormArrays();
      this.expertiseForm.updateValueAndValidity();
      this.refreshSyncState(true);
    }
  }

  setupExpertiseFormArrays() {
    this.lookupService.getLookupObj('expertise-indicator-type')
      .subscribe(lookupObj => {
        const indicatorsFormArrayDef = (this.model.selected.data.indicators || []).map(
          el => {
            const it = lookupObj['Obj' + el.indicatorTypeId] || {};
            el.indicatorTypeCaption = it.caption || '-';
            el.indicatorTypeLegalActCaption = it.legalAct || '-';
            el.indicatorTypeMethodCaption = it.method || '-';
            el.indicatorTypeNormativeValueCaption = it.normativeValue || '-';
            return EditExpertiseIndicatorsComponent.getIndicatorGroupDef(this.fb, el);
          });
        indicatorsFormArrayDef.sort((a, b) =>
          +a.get('expertiseResultType').value === +b.get('expertiseResultType').value
            ? a.get('indicatorTypeCaption').value.localeCompare(b.get('indicatorTypeCaption').value)
            : a.get('expertiseResultType').value > b.get('expertiseResultType').value ? -1 : 1);
        this.expertiseForm.setControl('indicators', this.fb.array(indicatorsFormArrayDef));
        this.expertiseForm.setControl('relatedProductExpertiseIds',
          this.fb.array((this.model.selected.data.relatedProductExpertiseIds || []).map(el => this.fb.control(el))));
        this.expertiseForm.get('relatedCreate').setValue((this.model.selected.data.relatedProductExpertiseIds || []).length);
      });
  }

  private getDefaultExpertiseModel(expertiseData: any): any {
    return {
      data: expertiseData,
      loaded: true,
      dummy: false
    };
  }

  addExpertise(expertiseId?: number) {
    this.waitingOverlay.StartWaiting();
    this.productDataService.getForCreateProductExpertise(expertiseId, this.productId).subscribe((newExpertiseData: any) => {
        const newExpertise = this.getDefaultExpertiseModel(newExpertiseData);

        this.model.expertises.splice(0, 0, newExpertise);

        this.setCurrentExpertise(newExpertise);

        this.expertiseForm.markAsDirty();

        this.waitingOverlay.EndWaiting();
      },
      () => {
        this.waitingOverlay.EndWaiting();
      });
  }

  deleteExpertise() {
    if (!this.model.selected) {
      return;
    }

    this.alertService.confirmModal('Вы уверены, что хотите удалить экспертизу?').subscribe(confirm => {
      if (confirm) {
        this.productDataService.deleteProductExpertise(this.model.selected.data.productId, this.model.selected.data.id)
          .subscribe(() => {
            this.reloadExpertisesList();
          });
      }
    });
  }

  reloadExpertisesList() {
    this.dataCachingService.removeCachedData('EditProductExpertises', this.id.toString());
    this.refreshFormData();
  }

  private refreshSyncState(isFirst: boolean) {
    this.model.selected.syncState = undefined;
    this.refreshSyncStateInternal(isFirst);
  }

  private refreshSyncStateInternal(isFirst: boolean) {

    if (!this.model || !this.model.selected || !this.model.selected.data ||
        !this.model.selected.data.id || (!isFirst && !this.model.selected.syncState)) {
      return;
    }

    // если ничего не запланировано по синхронизации - ничего и не может произойти и обновлять состояние не надо
    if (this.model && this.model.selected && this.model.selected.syncState && this.model.selected.syncState.mercuryExchangeStatus < 2
        && (!this.model.selected.syncState.mercuryExchangeNextTime ||
            new Date(this.model.selected.syncState.mercuryExchangeNextTime) > new Date())) {
      return;
    }

    if (this.updateSyncStatePending) {
      return;
    }

    this.updateSyncStatePending = true;
    const prevStageId = (this.model.selected.syncState || {}).conversationStageId;

    this.metadataService.getSyncState(this.model.selected.data.id, 8).subscribe({
      next: data => {
        this.updateSyncStatePending = false;
        this.model.selected.syncState = data;
        if (this.model.selected.syncState) {
          this.model.selected.syncState.withoutMercuryUuid = true;
          this.model.selected.syncState.lookupStage = this.model.selected.data.laboratoryEventTypeId === 36
            ? 'product-expertise'
            : 'common';
        }
        if (!isFirst && +data.mercuryExchangeStatus < 2) {
          this.refreshAfterSync(data.targetObjId);
          if (prevStageId && +prevStageId < +data.conversationStageId) {
            setTimeout(() => { this.refreshSyncState(true); }, 1000);
          }
        }
      }, error: () => {
        this.updateSyncStatePending = false;
      }
    });
  }

  refreshAfterSync(syncExpertiseId: number) {
    if (!syncExpertiseId || !this.model.selected || +this.model.selected.data.id !== syncExpertiseId) {
      return;
    }
    this.refreshFormData();
  }

  getDisabledReports() {
    return [1].includes(this.model.selected.data.conclusionResultTypeId)
      ? [81]
      : [2].includes(this.model.selected.data.conclusionResultTypeId)
        ? [79, 80]
        : [79, 80, 81];
  }
}
