import { Component, Input, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { MetadataService } from '../../../logic/services/metadata.service';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { StringHelper } from '../../../helpers/string-helper';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-drug-type-browse-form',
  templateUrl: './drug-type-browse-form.component.html'
})
export class DrugTypeBrowseFormComponent {
  @Input() currentFormGroup: FormGroup;

  isInvalid = FormHelper.isInvalid;
  processMoneyKeypress = FormHelper.processMoneyKeypressDot;

  newFileToUploadFlag = false;
  @ViewChildren('newFileToUploadFlag') newFileToUpload: QueryList<any>;

  public static getGroupDef(fb: FormBuilder, data: any) {
    return {
      id: data.id,
      isCustom: data.isCustom,
      drugKindId: [data.drugKindId, Validators.compose([Validators.required])],
      caption: [data.caption, Validators.compose([Validators.required, Validators.maxLength(255)])],
      expiresYears: [data.expiresYears || 0, Validators.compose([Validators.pattern(/^\d{1,5}$/)])],
      expiresMonths: [data.expiresMonths || 0, Validators.compose([Validators.pattern(/^\d{1,5}$/)])],
      expiresDays: [data.expiresDays || 0, Validators.compose([Validators.pattern(/^\d{1,5}$/)])],
      packageTypeId: [data.packageTypeId, Validators.compose([Validators.required])],
      packageUnitId: [data.packageUnitId, Validators.compose([Validators.required])],
      packageValue: [data.packageValue, Validators.compose([Validators.required])],
      manufacturerId: data.manufacturerId,
      captionNonproprietary: [data.captionNonproprietary, Validators.compose([Validators.maxLength(255)])],
      contraindicationsTxt: data.contraindicationsTxt,
      indicationsTxt: data.indicationsTxt,
      storageConditionsTxt: data.storageConditionsTxt,
      sideEffectsTxt: data.sideEffectsTxt,
      registryNo: [data.registryNo, Validators.compose([Validators.maxLength(255)])],
      countryReg: [data.id ? data.countryReg : 'RUS', Validators.compose([Validators.required])],
      eventTypeIds: fb.array(data.eventTypeIds || []),
      diseaseTypeIds: fb.array(data.diseaseTypeIds || []),
      manualFiles: fb.array(data.manualFiles || []),
      animalSubtypeKeys: fb.array((data.animalSubtypeKeys || [])),
      planPstIndicatorTypesYear: fb.array(data.planPstIndicatorTypesYear || []),
      planPstIndicatorTypesQuarter: fb.array(data.planPstIndicatorTypesQuarter || []),
      requirePstIndicatorTypesQuarter: fb.array(data.requirePstIndicatorTypesQuarter || []),
      animalTypes4VetA: fb.array(data.animalTypes4VetA || []),
      fedCode: data.fedCode,
    };
  }

  constructor(private metadataService: MetadataService,
              private navigationService: AppNavigationService,
              private fb: FormBuilder) {
  }

  fileChanged(e) {
    if (e.target.files && e.target.files.length) {

      this.metadataService.uploadFile('/metadata/drug-type/upload-file/manuals', e.target.files[0])
        .subscribe(fileUri => {
          // не знаю другого адекватного в данной ситуации способа очистить input[type=file]
          this.newFileToUploadFlag = true;
          (this.currentFormGroup.get('manualFiles') as FormArray).push(this.fb.control(fileUri));
          this.currentFormGroup.markAsDirty();
          setTimeout(() => {
            this.newFileToUploadFlag = false;
          });
        });
    }
  }

  fileUploadClick() {
    this.newFileToUpload.toArray()[0].nativeElement.click();
  }

  deleteManualFile(ix: number) {
    (this.currentFormGroup.get('manualFiles') as FormArray).removeAt(ix);
    this.currentFormGroup.markAsDirty();
  }

  getPrettyTitle(file: any) {
    return StringHelper.getPrettyTitleFile(file);
  }

  downloadFile(fileUri: string) {
    this.metadataService.preloadFile(fileUri).subscribe(fileHash => {
      window.open(environment.api + '/files/get?preloadId=' + encodeURIComponent(fileHash));
    });
  }
}
