import { EventEmitter } from '@angular/core';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { InstitutionTicketDataService } from '../../../logic/services/institution-ticket-data.service';
import { AgGridLookupRendererComponent } from '../../../ui/controls/ag-grid-lookup-renderer.component';
import { EditAnimalTicketsComponent } from '../../edit-animal/tickets/edit-animal-tickets.component';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { AgGridButtonRendererComponent } from '../../../ui/controls/ag-grid-button-renderer.component';
import { TicketsPlanningUiService } from '../../edit-institution/tickets/tickets-planning-ui-service';
var EditAgentTicketsComponent = /** @class */ (function () {
    function EditAgentTicketsComponent(institutionTicketDataService, dataCachingService, waitingOverlay, navigationService, alertService, ticketUiService) {
        this.institutionTicketDataService = institutionTicketDataService;
        this.dataCachingService = dataCachingService;
        this.waitingOverlay = waitingOverlay;
        this.navigationService = navigationService;
        this.alertService = alertService;
        this.ticketUiService = ticketUiService;
        this.model = {};
        this.ticketActionEmitter = undefined;
        this.frameworkComponents = {
            lookupRenderer: AgGridLookupRendererComponent,
            buttonRenderer: AgGridButtonRendererComponent,
        };
        this.getRowStyle = EditAnimalTicketsComponent.getRowStyle;
        this.gridColumnDefs = EditAnimalTicketsComponent.getColumnDefs().concat([{
                headerName: 'Животное', width: 200,
                field: 'animalId',
                cellRenderer: 'lookupRenderer',
                cellRendererParams: function (row) {
                    return {
                        value: row.value,
                        lookupName: !row.data.isAnimalExternal ? 'animal' : 'animal-external',
                        isPipe: true,
                    };
                }
            },]);
    }
    Object.defineProperty(EditAgentTicketsComponent.prototype, "id", {
        get: function () {
            return this._id;
        },
        set: function (id) {
            if (this._id !== id) {
                this._id = id;
                this.refreshFormDataInternal();
            }
        },
        enumerable: true,
        configurable: true
    });
    EditAgentTicketsComponent.prototype.refreshFormData = function () {
        this.dataCachingService.removeCachedData('EditAgentTickets', this.id.toString());
        this.refreshFormDataInternal();
    };
    EditAgentTicketsComponent.prototype.refreshFormDataInternal = function () {
        var _this = this;
        this.waitingOverlay.StartWaiting();
        var existing = this.dataCachingService.getCachedData('EditAgentTickets', this.id.toString());
        if (existing) {
            this.model = existing;
            this.waitingOverlay.EndWaiting();
        }
        else {
            this.institutionTicketDataService.searchByAgent(this.id)
                .subscribe({
                next: function (data) {
                    data.sort(function (a, b) { return b.startTime.localeCompare(b.startTime); });
                    _this.model = { searchResults: data };
                    _this.dataCachingService.addToCache('EditAgentTickets', _this.id.toString(), _this.model);
                    _this.waitingOverlay.EndWaiting();
                },
                error: function () {
                    _this.waitingOverlay.EndWaiting();
                }
            });
        }
    };
    EditAgentTicketsComponent.prototype.clickTicket = function (event) {
        var _this = this;
        this.ticketActionEmitter = new EventEmitter();
        this.ticketUiService.editTicket(event, true, this.ticketActionEmitter);
        this.ticketActionEmitter.subscribe(function (emit) {
            if (emit) {
                _this.refreshFormData();
            }
        });
    };
    return EditAgentTicketsComponent;
}());
export { EditAgentTicketsComponent };
