import { DataCachingService } from '../../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { StringHelper } from '../../../helpers/string-helper';
import { AnimalDataService } from '../../../logic/services/animal-data.service';
import { DateHelper } from '../../../helpers/date-helper';
var EditAnimalEventsComponent = /** @class */ (function () {
    function EditAnimalEventsComponent(animalDataService, dataCachingService, waitingOverlay, lookupSourceService, navigationService) {
        var _this = this;
        this.animalDataService = animalDataService;
        this.dataCachingService = dataCachingService;
        this.waitingOverlay = waitingOverlay;
        this.lookupSourceService = lookupSourceService;
        this.navigationService = navigationService;
        this.model = {};
        this.isExternal = false;
        this.isInvalid = FormHelper.isInvalid;
        this.gridColumnDefs = [
            {
                headerName: '№', field: 'id', width: 70,
            },
            {
                headerName: 'Синхронизация', field: 'mercuryId', width: 50,
                cellRenderer: function (params) {
                    return params && params.data && params.data['isSync']
                        ? "<clr-icon shape=\"event\" style=\"color:green\" title=\"\u0421\u0438\u043D\u0445\u0440\u043E\u043D\u0438\u0437\u0430\u0446\u0438\u044F \u043F\u0440\u043E\u0432\u0435\u0434\u0435\u043D\u0430\"></clr-icon>"
                        : "";
                },
                filterValueGetter: function (params) { return params.data['isSync'] ? 'синхронизировано' : 'не синхронизировано'; },
                cellStyle: { 'padding-left': '17px' },
            },
            {
                headerName: 'Дата', field: 'date', width: 120,
                cellRenderer: function (params) {
                    if (!params || !params.data) {
                        return '';
                    }
                    var html = '';
                    if (params.data['notExecuted']) {
                        html = '<clr-icon shape="times" style="color:silver"></clr-icon>';
                    }
                    if (DateHelper.isExpiredEvent(params.data['date'], params.data['datePlanning'])) {
                        html = '<clr-icon shape="history" style="color:red" title="Мероприятие просрочено"></clr-icon>';
                    }
                    else {
                        html = params.data['date'] ?
                            '<clr-icon shape="check" style="color:green"></clr-icon>' :
                            '<clr-icon shape="alarm-clock" style="color:silver"></clr-icon>';
                    }
                    return html + ' ' + (StringHelper.getRuDate(params.value));
                },
                valueGetter: function (params) {
                    return params.data['date'] || params.data['datePlanning'];
                }
            },
            {
                headerName: 'Вид', field: 'eventTypeId', width: 230,
                valueFormatter: function (params) { return params.value
                    ? (params.data.performedByAgent ? '[ХС] ' : '') + _this.eventTypeIdLookup[params.value]
                    : '<не указан>'; },
            },
            {
                headerName: 'Заболевание', field: 'diseaseTypeId', width: 250,
                valueFormatter: function (params) { return params.value ? _this.diseaseTypeIdLookup[params.value] : '<не указано>'; },
                cellStyle: function (params) {
                    return {
                        color: params.value && params.data.diseasePositive ? 'red' : 'silver'
                    };
                },
            },
            {
                headerName: 'Препараты', field: 'drugsCaption', width: 250,
                cellStyle: function (params) {
                    return {
                        color: !params.value ? 'silver' : undefined
                    };
                },
            },
            {
                headerName: 'Поголовье', width: 450,
                valueFormatter: function (params) {
                    if (!params || !params.data || !params.data.counts) {
                        return '';
                    }
                    return params.data.counts
                        .map(function (cnt) { return cnt.caption + ': ' + (cnt.count || '0') + ' (' + cnt.countDiff + ')'; })
                        .join('; ');
                },
                cellRenderer: this.countsCellRenderer
            },
        ];
        this.eventTypeIdLookup = {};
        this.diseaseTypeIdLookup = {};
        this.lookupSourceService.getLookupObj('event-type').subscribe(function (lookup) { return _this.eventTypeIdLookup = lookup; });
        this.lookupSourceService.getLookupObj('disease-type').subscribe(function (lookup) { return _this.diseaseTypeIdLookup = lookup; });
    }
    Object.defineProperty(EditAnimalEventsComponent.prototype, "id", {
        get: function () {
            return this._id;
        },
        set: function (id) {
            if (this._id !== id) {
                this._id = id;
                this.refreshFormDataInternal();
            }
        },
        enumerable: true,
        configurable: true
    });
    EditAnimalEventsComponent.prototype.refreshFormData = function () {
        if (!this.isExternal) {
            this.dataCachingService.removeCachedData('EditAnimalEvents', this.id.toString());
        }
        this.refreshFormDataInternal();
    };
    EditAnimalEventsComponent.prototype.refreshFormDataInternal = function () {
        var _this = this;
        if (this.isExternal) {
            this.model = { searchResults: [] };
            return;
        }
        this.waitingOverlay.StartWaiting();
        var existing = this.dataCachingService.getCachedData('EditAnimalEvents', this.id.toString());
        if (existing) {
            this.model = existing;
            this.waitingOverlay.EndWaiting();
        }
        else {
            this.animalDataService.readAnimalEvents(this.id)
                .subscribe(function (data) {
                data.sort(function (a, b) { return (b.date || b.datePlanning).localeCompare((a.date || a.datePlanning)); });
                _this.model = { searchResults: data };
                _this.dataCachingService.addToCache('EditAnimalEvents', _this.id.toString(), _this.model);
                _this.waitingOverlay.EndWaiting();
            }, function (error) {
                _this.waitingOverlay.EndWaiting();
            });
        }
    };
    EditAnimalEventsComponent.prototype.addNew = function () {
        this.navigationService.performAnimalEventCreation(this.id);
    };
    EditAnimalEventsComponent.prototype.countsCellRenderer = function (row) {
        if (!row.data || !row.data.counts || !row.data.counts.length) {
            return '';
        }
        var html = '';
        row.data.counts.forEach(function (cnt) {
            html = html + cnt.caption + ': ' + (cnt.count || '0') + EditAnimalEventsComponent.renderDiff(cnt.countDiff) + '; ';
        });
        return html;
    };
    EditAnimalEventsComponent.renderDiff = function (val) {
        if (!val || isNaN(val)) {
            return '';
        }
        var upIcon = '<clr-icon shape="caret up" size="12" style="color:green"></clr-icon>';
        var downIcon = '<clr-icon shape="caret down" size="12" style="color:red"></clr-icon>';
        if (val < 0) {
            return ' ' + downIcon + '<span style="color:red">' + (-val) + '</span>';
        }
        else {
            return ' ' + upIcon + '<span style="color:green">' + val + '</span>';
        }
    };
    EditAnimalEventsComponent.prototype.getRowStyle = function (params) {
        if (params.data && params.data.notExecuted) {
            return { color: 'silver' };
        }
    };
    return EditAnimalEventsComponent;
}());
export { EditAnimalEventsComponent };
