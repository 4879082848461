<clr-tabs>
  <clr-tab>
    <button clrTabLink>Общее</button>
    <ng-template [(clrIfActive)]="model.commonTabActive">
      <clr-tab-content>
        <div class="edit-page-tab">
          <app-edit-stable-common [id]="id" [newAgentId]="newAgentId"></app-edit-stable-common>
        </div>
      </clr-tab-content>
    </ng-template>
  </clr-tab>
  <clr-tab>
    <button clrTabLink><clr-icon shape="piggy-bank"></clr-icon>Животные<span class="itech-page-header-extras">{{showLoadedAnimalCount()}}</span></button>
    <ng-template [(clrIfActive)]="model.animalsTabActive">
      <clr-tab-content>
        <div class="edit-page-tab">
          <app-edit-stable-animals [id]="id"></app-edit-stable-animals>
        </div>
      </clr-tab-content>
    </ng-template>
  </clr-tab>
  <clr-tab>
    <button clrTabLink><clr-icon shape="color-picker"></clr-icon>Мероприятия/События</button>
    <ng-template [(clrIfActive)]="model.eventsTabActive">
      <clr-tab-content>
        <div class="edit-page-tab">
          <app-edit-stable-events [id]="id"></app-edit-stable-events>
        </div>
      </clr-tab-content>
    </ng-template>
  </clr-tab>
  <clr-tab>
    <button clrTabLink><clr-icon shape="truck"></clr-icon>Продукция/Документы</button>
    <ng-template [(clrIfActive)]="model.productsTabActive">
      <clr-tab-content>
        <div class="edit-page-tab">
          <app-edit-stable-products [id]="id"></app-edit-stable-products>
        </div>
      </clr-tab-content>
    </ng-template>
  </clr-tab>
  <clr-tab>
    <button clrTabLink><clr-icon shape="form"></clr-icon>Номенклатура продукции</button>
    <ng-template [(clrIfActive)]="model.productSubtype">
      <clr-tab-content>
        <div class="edit-page-tab">
          <app-edit-stable-product-subtype [stableId]="id"></app-edit-stable-product-subtype>
        </div>
      </clr-tab-content>
    </ng-template>
  </clr-tab>
  <clr-tab>
    <button clrTabLink><clr-icon shape="file-settings"></clr-icon>Шаблоны переработок</button>
    <ng-template [(clrIfActive)]="model.productionTemplate">
      <clr-tab-content>
        <div class="edit-page-tab">
          <app-edit-stable-production-template [id]="id"></app-edit-stable-production-template>
        </div>
      </clr-tab-content>
    </ng-template>
  </clr-tab>
</clr-tabs>
