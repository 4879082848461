/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./common/edit-quarantine-common.component.ngfactory";
import * as i2 from "./common/edit-quarantine-common.component";
import * as i3 from "../../logic/services/data-caching.service";
import * as i4 from "../../ui/infrastructure/global-waiting-overlay.service";
import * as i5 from "../../logic/services/security.service";
import * as i6 from "../../logic/services/metadata.service";
import * as i7 from "../../logic/services/lookup-source.service";
import * as i8 from "../../logic/services/app-navigation.service";
import * as i9 from "@clr/angular";
import * as i10 from "@angular/common";
import * as i11 from "../../../../node_modules/@clr/angular/clr-angular.ngfactory";
import * as i12 from "./create-quarantine.component";
import * as i13 from "@angular/forms";
import * as i14 from "@angular/router";
import * as i15 from "../../logic/services/quarantine-data.service";
import * as i16 from "../../logic/services/bookmark.service";
var styles_CreateQuarantineComponent = [];
var RenderType_CreateQuarantineComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CreateQuarantineComponent, data: {} });
export { RenderType_CreateQuarantineComponent as RenderType_CreateQuarantineComponent };
function View_CreateQuarantineComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-edit-quarantine-common", [], null, null, null, i1.View_EditQuarantineCommonComponent_0, i1.RenderType_EditQuarantineCommonComponent)), i0.ɵdid(1, 573440, null, 0, i2.EditQuarantineCommonComponent, [i3.DataCachingService, i4.GlobalWaitingOverlayService, i5.SecurityService, i6.MetadataService, i7.LookupSourceService, i8.AppNavigationService], { contextFormGroup: [0, "contextFormGroup"], serverSideErrorsProvider: [1, "serverSideErrorsProvider"], isAccessEdit: [2, "isAccessEdit"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contextFormGroup; var currVal_1 = _co.model; var currVal_2 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_CreateQuarantineComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "itech-content-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "clr-icon", [["class", "has-badge"], ["shape", "flame"], ["size", "36"]], null, null, null, null, null)), i0.ɵdid(2, 16384, null, 0, i9.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, ["\u00A0\u0421\u043E\u0437\u0434\u0430\u043D\u0438\u0435 \u043D\u043E\u0432\u043E\u0433\u043E \u043A\u0430\u0440\u0430\u043D\u0442\u0438\u043D\u0430\n"])), (_l()(), i0.ɵeld(4, 0, null, null, 5, "div", [["style", "display:flex"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["style", "max-width:100px"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "clr-icon", [["shape", "flame"], ["size", "80"], ["style", "margin-left:10px"]], null, null, null, null, null)), i0.ɵdid(7, 16384, null, 0, i9.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CreateQuarantineComponent_1)), i0.ɵdid(9, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(10, 0, null, null, 7, "p", [], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 6, "button", [["class", "btn btn-primary"]], [[1, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createQuarantine() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_ClrLoadingButton_0, i11.RenderType_ClrLoadingButton)), i0.ɵdid(12, 49152, null, 0, i9.ClrLoadingButton, [i0.ElementRef, i0.Renderer2], { disabled: [0, "disabled"] }, null), i0.ɵprd(2048, null, i9.LoadingListener, null, [i9.ClrLoadingButton]), i0.ɵdid(14, 147456, null, 0, i9.ClrLoading, [[2, i9.LoadingListener]], { loadingState: [0, "loadingState"] }, null), (_l()(), i0.ɵeld(15, 0, null, 0, 1, "clr-icon", [["shape", "check"]], null, null, null, null, null)), i0.ɵdid(16, 16384, null, 0, i9.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, 0, ["\u00A0\u0421\u043E\u0437\u0434\u0430\u0442\u044C "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model; _ck(_v, 9, 0, currVal_0); var currVal_2 = _co.creating; _ck(_v, 12, 0, currVal_2); var currVal_3 = _co.creating; _ck(_v, 14, 0, currVal_3); }, function (_ck, _v) { var currVal_1 = (i0.ɵnov(_v, 12).disabled ? "" : null); _ck(_v, 11, 0, currVal_1); }); }
export function View_CreateQuarantineComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-create-quarantine", [], null, null, null, View_CreateQuarantineComponent_0, RenderType_CreateQuarantineComponent)), i0.ɵdid(1, 114688, null, 0, i12.CreateQuarantineComponent, [i13.FormBuilder, i14.ActivatedRoute, i15.QuarantineDataService, i8.AppNavigationService, i16.BookmarkService, i3.DataCachingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CreateQuarantineComponentNgFactory = i0.ɵccf("app-create-quarantine", i12.CreateQuarantineComponent, View_CreateQuarantineComponent_Host_0, { model: "model", serverSideValidationErrors: "serverSideValidationErrors" }, {}, []);
export { CreateQuarantineComponentNgFactory as CreateQuarantineComponentNgFactory };
