import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BookmarkService } from '../../logic/services/bookmark.service';
import { DataCachingService } from '../../logic/services/data-caching.service';
import { AppFormGroup } from '../../ui/controls/app-form-group';
import { QuarantineDataService } from '../../logic/services/quarantine-data.service';
import { EditQuarantineCommonComponent } from './common/edit-quarantine-common.component';
import { FormBuilder, Validators } from '@angular/forms';
import { AppNavigationService } from '../../logic/services/app-navigation.service';
import { FormHelper } from '../../ui/controls/form-helper';
import { AlertService } from '../../ui/infrastructure/alert.service';
import { AddressPersonFioCacheService } from '../../logic/services/address-person-fio-cache.service';
import { EditQuarantineStagesComponent } from './stages/edit-quarantine-stages.component';
import { JobRunnerUiService } from '../../ui/infrastructure/job-runner-ui.service';
import { AnimalSearchResultsFormComponent } from '../edit-animal/search/animal-search-results-form.component';
import { BehaviorSubject } from 'rxjs';
import { LookupSourceService } from '../../logic/services/lookup-source.service';
import { StableSearchResultsFormComponent } from '../edit-stable/search/stable-search-results-form.component';
import { StringHelper } from '../../helpers/string-helper';
import { DateHelper } from '../../helpers/date-helper';
import { SecurityService } from '../../logic/services/security.service';
import { EditQuarantineEventsController } from './events/edit-quarantine-events.controller';
import { EditAnimalComponent } from '../edit-animal/edit-animal.component';
import { AnimalDataService } from '../../logic/services/animal-data.service';
import { serverSideErrorsValidator } from '../../logic/validators/server-side-errors-validator.directive';

@Component({
  selector: 'app-edit-quarantine',
  templateUrl: './edit-quarantine.component.html',
  styleUrls: ['./edit-quarantine.all.css']
})
export class EditQuarantineComponent implements OnInit {

  model: any = EditQuarantineComponent.createDefaultModel();
  id: number;

  isAccessEdit = false;

  storing = false;
  dirty = false;

  changeStatusModalOpened = false;
  changeStatusFormGroup;

  createAnimalEventModalOpened = false;
  createAnimalEventFormGroup: AppFormGroup;
  createAnimalEventStoreProcess = false;

  isInvalid = FormHelper.isInvalid;
  equalsSome = FormHelper.equalsSome;

  gridAnimalColumnDefs = [...AnimalSearchResultsFormComponent.getGridColumnDefs(true),
                          ...EditAnimalComponent.getGridGroupingColumns(),
    {
      field: 'trappedTypeGroup', hide: true, excludeExport: true,
      valueGetter: params => params && params.data ? params.data['trappedTypeIdForGroup'] : '',
      rowGroupingSettings: {
        name: 'Зоне',
        renderParams: {
          lookupName: '/quarantine/trapped-type',
          lookupValueGetter: row => row.value,
          getChildrenCount: node => EditAnimalComponent.getCountForGroup(node),
        }
      }
    },
  ];
  gridStableColumnDefs = [
    {
      headerName: '', field: '', width: 50, excludeExport: true,
      checkboxSelection: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true,
    },
    {
      headerName: '№', field: 'id', width: 90,
    },
    {
      headerName: 'Наименование', field: 'caption', width: 220,
      cellRenderer: params => {
        const animalStateHtml = params.data.overdueEventsCount && params.data.overdueEventsCount > 0
          ? '<clr-icon shape="history" style="color:red"' +
          ' title="Имеются просроченные мероприятия: ' + params.data.overdueEventsCount + '"></clr-icon>'
          : '<clr-icon shape="check" style="color:green" title="Просроченные мероприятия отсутствуют"></clr-icon>';

        return animalStateHtml + '&nbsp;' + params.value;
      }
    },
    {
      headerName: 'Состояние ПО',
      field: 'overdueEventsCount',
      hide: true,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
    },
    {
      headerName: 'Вид', field: 'stableTypeId', width: 100,
      valueFormatter: params => params.value ? StableSearchResultsFormComponent.stableTypeIdLookup[params.value] : '<не указан>',
      rowGroupingSettings: {
        name: 'Виду',
        renderParams: {
          lookupName: 'stable-type',
          lookupValueGetter: row => row.value,
        }
      }
    },
    {
      headerName: 'Адрес', field: 'address', width: 300,
    },
    {
      headerName: 'Владелец', field: 'owner', width: 200,
      rowGroupingSettings: {
        name: 'Владельцу',
        renderParams: {
          captionGetter: row => row.value,
        }
      }
    },
    {
      headerName: 'Жив. под карантином', field: 'animalCountTrappedToQuarantine', width: 160,
    },
  ];

  selectedAnimalsFromGrid = [];
  selectedStableIdFromGrid = [];

  periodCaption = '';

  trappedAnimal: BehaviorSubject<any[]>;
  trappedStable: BehaviorSubject<any[]>;

  static createDefaultModel(): any {
    return {
      commonTabActive: true,
      stagesTabActive: false,
      quaEventsTabActive: false,
      animalTabActive: false,
      stableTabActive: false,
      eventsTabActive: false,
      messageTabActive: false,
      createAnimalEventServerSideErrorsProvider: {
        serverSideErrorsProvider: [],
      },
    };
  }

  get contextFormGroups(): any {
    return this.model ? this.model.contextFormGroups : undefined;
  }

  get contextFormGroupCommon(): AppFormGroup {
    return this.contextFormGroups ? this.contextFormGroups.common : undefined;
  }

  get contextFormGroupStages(): AppFormGroup {
    return this.contextFormGroups ? this.contextFormGroups.stages : undefined;
  }

  constructor(private route: ActivatedRoute,
              private bookmarkService: BookmarkService,
              private dataCachingService: DataCachingService,
              private quarantineDataService: QuarantineDataService,
              private fb: FormBuilder,
              private alertService: AlertService,
              private cache: AddressPersonFioCacheService,
              public navigationService: AppNavigationService,
              private jobRunnerUiService: JobRunnerUiService,
              private lookupSourceService: LookupSourceService,
              public securityService: SecurityService,
              private eventsCtlr: EditQuarantineEventsController,
              private animalDataService: AnimalDataService) {
  }

  ngOnInit() {

    this.route.paramMap
      .subscribe(params => {
        this.id = parseInt(params.get('id'), 10);

        const existing = this.dataCachingService.getCachedData('EditQuarantine', this.id.toString());

        if (existing) {
          this.model = existing;
        } else {
          this.model = EditQuarantineComponent.createDefaultModel();
          this.dataCachingService.addToCache('EditQuarantine', this.id.toString(), this.model);
        }

        this.refreshFormDataInternal();
      });
  }

  refreshFormData() {

    if (this.model.quaEventsTabActive) {

      this.eventsCtlr.refreshFormData(this.id);

    } else {

      this.dataCachingService.removeCachedData('EditQuarantine_Data', this.id.toString());
      this.dataCachingService.removeCachedData('EditQuarantine_Data_TrappedAnimal', this.id.toString());
      this.dataCachingService.removeCachedData('EditQuarantine_Data_TrappedStable', this.id.toString());
      this.cache.invalidateQuarantine(this.id);
      if (this.model && this.model.serverSideValidationErrors) {
        this.model.serverSideValidationErrors = [];
      }
      this.refreshFormDataInternal();
    }
  }

  refreshFormDataInternal(event = true) {

    if (!event) {
      return;
    }

    if (!this.model.contextFormGroups) {
      this.model.contextFormGroups = {};
    }
    this.trappedStable = undefined;
    this.trappedAnimal = undefined;

    const existing = this.dataCachingService.getCachedData('EditQuarantine_Data', this.id.toString());

    if (existing) {
      existing.data.currentStatusId = existing.currentStatusId;
      this.updateFormGroups(existing.data);
      this.updatePeriodCaption();
      this.isAccessEdit = existing.accessEdit;
      this.bookmarkService.refreshQuarantineCardBookmark(this.id);
      this.dirty = false;
    } else {

      this.quarantineDataService.getForEditById(this.id)
        .subscribe(quarantine => {
          quarantine.data.currentStatusId = quarantine.currentStatusId;
          this.isAccessEdit = quarantine.accessEdit;
          this.updateFormGroups(quarantine.data);
          this.updatePeriodCaption();
          this.dataCachingService.addToCache('EditQuarantine_Data', this.id.toString(), quarantine);
          this.bookmarkService.refreshQuarantineCardBookmark(this.id);
          this.dirty = false;
        });
    }
  }

  updateFormGroups(data: any = {}) {
    this.model.contextFormGroups.common = EditQuarantineCommonComponent.createFormGroupCommon(this.fb, this.model, data);
    this.model.contextFormGroups.stages = EditQuarantineStagesComponent.createFormGroupStages(this.fb, this.model, data);
  }

  updatePeriodCaption() {
    const currentStatusId = +this.contextFormGroupCommon.get('currentStatusId').value;
    if (currentStatusId > 2) {
      const stage = this.model.contextFormGroups.stages.get('stagePeriods').value[3];
      if (stage) {
        if (stage.dateFrom) {
          this.periodCaption = 'с ' + StringHelper.getRuDate(DateHelper.toDate(stage.dateFrom)) +
            (stage.dateToInclusive ? ' по ' + StringHelper.getRuDate(DateHelper.toDate(stage.dateToInclusive)) : '');
        }
      }
    } else {
      this.periodCaption = '';
    }
  }

  cancelEdit() {
    this.dataCachingService.removeCachedData('EditQuarantine_Data', this.id.toString());
    this.refreshFormData();
  }

  store() {

    // мы сохраняем текущую открытую вкладку карточки,
    // т.к. у пользователя может быть права на редактирование одной вкладки,
    // но отсутствовать на другую
    if (!this.model) {
      return;
    }

    this.contextFormGroupStages.updateValueAndValidity();

    const data = { ...this.contextFormGroupCommon.value, ...this.contextFormGroupStages.value };

    if (this.model.commonTabActive) {
      this.storeCommonTab(data);
    } else if (this.model.stagesTabActive) {
      this.storeStagesTab(data);
    }

  }

  storeCommonTab(data: any) {

    FormHelper.markAsSubmitted(this.contextFormGroupCommon);

    if (this.contextFormGroupCommon.invalid) {
      FormHelper.setFocusToInvalidControl(this.contextFormGroupCommon);
      return;
    }

    this.storing = true;

    this.quarantineDataService.storeCommon(data)
      .subscribe(res => {
        this.postStoreProcess(res);
      }, error => {
        FormHelper.setSingleFormGroupServerSideValidationErrorsWithRelationMsgKey(error, this.model, this.contextFormGroupCommon);
        this.storing = false;
        document.getElementById('quarantineCommonContent').scrollTop = 0;
      });
  }

  storeStagesTab(data: any) {
    FormHelper.markAsSubmitted(this.contextFormGroupStages);

    if (this.contextFormGroupStages.invalid) {
      FormHelper.setFocusToInvalidControl(this.contextFormGroupStages);
      return;
    }

    this.storing = true;

    this.quarantineDataService.storeStages(data)
      .subscribe(res => {
        this.postStoreProcess(res);
      }, error => {

        this.storing = false;

        FormHelper.setSingleFormGroupServerSideValidationErrorsWithRelationMsgKey(error, this.model, this.contextFormGroupStages);
      });
  }

  postStoreProcess(response: any) {
    FormHelper.setSingleFormGroupServerSideValidationErrors({}, this.model, this.contextFormGroupCommon);
    FormHelper.setSingleFormGroupServerSideValidationErrors({}, this.model, this.contextFormGroupStages);
    this.cache.invalidateQuarantine(response.data.data.id);
    this.lookupSourceService.invalidateLookup('disease-type/quarantine-diseases');
    this.dataCachingService.removeCachedData('EditQuarantine_Data', this.id.toString());
    this.dataCachingService.addToCache('EditQuarantine_Data', this.id.toString(), response.data);
    this.refreshFormDataInternal();
    this.storing = false;
  }

  runCalculateTrappedObjectsOp() {
    this.jobRunnerUiService.runOperation(13 /* "Актуализация сведений о попавших по карантин предприятий и животных"*/,
      {
        type: 'quarantineCalculateTrappedObject',
        quarantineId: this.id
      }, undefined, undefined, false)
      .subscribe({
        next: (data) => {
          this.refreshFormData();
          this.alertService.success('Сведения о попавших под карантин предприятиях и животных актуализированы');
        }
      });
  }

  openChangeStatusModal() {
    this.changeStatusFormGroup = this.fb.group({
      newStatusId: [null, Validators.required],
      dateFromNewStatus: [null, Validators.required],
      checkboxValue: false,
    });

    this.changeStatusFormGroup.get('newStatusId').valueChanges
      .subscribe(change => this.changeStatusChangeNewStatusId(change));

    this.changeStatusModalOpened = true;
  }

  changeStatusChangeNewStatusId(newStatusId: number) {
    if (!newStatusId) {
      this.changeStatusFormGroup.get('dateFromNewStatus').setValue(null);
    }
    const existStage = this.model.contextFormGroups.stages
      ? this.model.contextFormGroups.stages.get('stagePeriods').value[newStatusId]
      : undefined;

    this.changeStatusFormGroup.get('dateFromNewStatus')
      .setValue(existStage && existStage['dateFrom'] ? existStage['dateFrom'] : null);
  }

  changeStatus() {

    if (!this.changeStatusFormGroup.valid) {
      FormHelper.setFocusToInvalidControl(this.changeStatusFormGroup);
      return;
    }

    this.quarantineDataService.forceChangeStatus(this.id,
      this.changeStatusFormGroup.get('newStatusId').value,
      this.changeStatusFormGroup.get('dateFromNewStatus').value)
      .subscribe(() => {
        this.lookupSourceService.invalidateLookup('disease-type/quarantine-diseases');
        this.refreshFormData();
        this.changeStatusModalOpened = false;
      });
  }

  getTrappedAllAnimal(): BehaviorSubject<any[]> {
    if (this.trappedAnimal) {
      return this.trappedAnimal;
    }

    this.trappedAnimal = new BehaviorSubject([]);

    const existing = this.dataCachingService.getCachedData('EditQuarantine_Data_TrappedAnimal', this.id.toString());

    if (existing) {
      this.trappedAnimal.next(existing);
    } else {
      this.lookupSourceService.getLookupObj('animal-type').subscribe(animalTypeLookup => {
        this.lookupSourceService.getLookupObj('animal-subtype').subscribe(animalSubtypeIdLookup  => {
        this.quarantineDataService.searchAllTrappedAnimal(this.id)
          .subscribe(allAnimal => {
            allAnimal.forEach(el => {
              const animalSubtype = (animalSubtypeIdLookup[el.animalTypeId] || []).find(x => x.id === el.animalSubtypeId);
              el.animalTypeIdCaption = el.animalTypeId ? animalTypeLookup[el.animalTypeId] : '<<Не указан>>';
              el.animalSubtypeIdCaption = animalSubtype ? animalSubtype.caption : '<<не указан>>';

              el.totalCount = el.totalCount || 1;

              if (el.trappedTypeIds && el.trappedTypeIds.length) {
                el.trappedTypeIds.sort();
                el.trappedTypeIdForGroup = el.trappedTypeIds[0];
              }
            });
            this.dataCachingService.addToCache('EditQuarantine_Data_TrappedAnimal', this.id.toString(), allAnimal);
            this.trappedAnimal.next(allAnimal);
          });
        });
      });
    }

    return this.trappedAnimal;
  }

  getTrappedAllStable(): BehaviorSubject<any[]> {
    if (this.trappedStable) {
      return this.trappedStable;
    }

    this.trappedStable = new BehaviorSubject([]);

    const existing = this.dataCachingService.getCachedData('EditQuarantine_Data_TrappedStable', this.id.toString());

    if (existing) {
      this.trappedStable.next(existing);
    } else {
      this.lookupSourceService.getLookupObj('stable-type').subscribe(stableTypeLookup => {
        StableSearchResultsFormComponent.stableTypeIdLookup = stableTypeLookup;
        this.quarantineDataService.searchAllTrappedStable(this.id)
          .subscribe(allStable => {
            this.dataCachingService.addToCache('EditQuarantine_Data_TrappedStable', this.id.toString(), allStable);
            this.trappedStable.next(allStable);
          });
      });
    }

    return this.trappedStable;
  }

  onAnimalGridSelectionChanged(params: any) {
    if (!params || !params.data || !params.data.id) {
      return;
    }

    const ix = this.selectedAnimalsFromGrid.findIndex(x => +x.id === +params.data.id);
    if (ix === -1) {
      this.selectedAnimalsFromGrid.push(params.data);
    } else {
      this.selectedAnimalsFromGrid.splice(ix, 1);
    }
  }

  createAnimalEvent() {
    const mapArr = this.selectedAnimalsFromGrid.map(el => el.id);
    this.navigationService.performAnimalEventCreationManyByQuarantineId(this.id, mapArr[0], mapArr.splice(1),
      null, this.contextFormGroupCommon ? this.contextFormGroupCommon.get('diseaseTypeId').value : null);
  }

  onStableGridSelectionChanged(params: any) {
    if (!params.data.id) {
      return;
    }
    const ix = this.selectedStableIdFromGrid.indexOf(params.data.id);
    if (ix === -1) {
      this.selectedStableIdFromGrid.push(params.data.id);
    } else {
      this.selectedStableIdFromGrid.splice(ix, 1);
    }
  }

  createStableEvent() {
    this.navigationService.performStableEventCreationManyByQuarantineId(this.id, this.selectedStableIdFromGrid[0],
      this.selectedStableIdFromGrid.splice(1));
  }

  deleteQuarantine() {
    this.alertService.confirmModal('Вы уверены, что хотите безвозвратно удалить карточку карантина?').subscribe(val => {
      if (val) {
        this.quarantineDataService.deleteQuarantine(this.id)
          .subscribe(() => {
            this.dataCachingService.removeCachedData('EditQuarantine', this.id.toString());
            this.dataCachingService.removeCachedData('EditQuarantine_Data', this.id.toString());
            this.dataCachingService.removeCachedData('EditQuarantine_Data_TrappedAnimal', this.id.toString());
            this.dataCachingService.removeCachedData('EditQuarantine_Data_TrappedStable', this.id.toString());
            this.bookmarkService.removeOpenCardBookmark(['/quarantine-edit'], this.id.toString());
          });
      }
    });
  }

  getDisabledChoicesChangeStatus(): any[] {
    return this.contextFormGroupCommon.get('noSuspicionStage').value
           ? [this.contextFormGroupCommon.get('currentStatusId').value, EditQuarantineStagesComponent.QUARANTINE_STAGE.SUSPICION]
           : [this.contextFormGroupCommon.get('currentStatusId').value];
  }

  openCreateAnimalEventModal() {

    this.lookupSourceService.getLookupObj('event-type').subscribe(lookup => {

      this.createAnimalEventFormGroup = this.fb.group({
        date: [null, Validators.compose([FormHelper.validateDateTimePicker(),
          serverSideErrorsValidator('date', this.model.createAnimalEventServerSideErrorsProvider)])],
        eventTypeId: [null, Validators.compose([Validators.required,
          serverSideErrorsValidator('eventTypeId', this.model.createAnimalEventServerSideErrorsProvider)])],
        eventKind: null,
        checkboxValue: false,
        animals: this.fb.array((this.selectedAnimalsFromGrid || []).map(el => el.id)),
        quarantineId: this.id,
        diseaseTypeId: this.contextFormGroupCommon.get('diseaseTypeId').value,
        laboratoryInstitutionId: [null, Validators.compose([
          serverSideErrorsValidator('laboratoryInstitutionId', this.model.createAnimalEventServerSideErrorsProvider)])],
        examNo: [null, Validators.compose([
          serverSideErrorsValidator('examNo', this.model.createAnimalEventServerSideErrorsProvider)])],
      }) as AppFormGroup;

      this.createAnimalEventFormGroup.get('eventTypeId').valueChanges.subscribe(() => {
        this.createAnimalEventFormGroup.get('eventKind').setValue(
          (lookup['Obj' + this.createAnimalEventFormGroup.get('eventTypeId').value] || {}).eventKind
        );
      });
    });

    this.createAnimalEventModalOpened = true;
  }

  storeAnimalEvent() {

    FormHelper.markAsSubmitted(this.createAnimalEventFormGroup);

    if (!this.createAnimalEventFormGroup.valid) {
      FormHelper.setFocusToInvalidControl(this.createAnimalEventFormGroup);
      return;
    }

    this.createAnimalEventStoreProcess = true;

    this.animalDataService.createAnimalEventsLazy(this.createAnimalEventFormGroup.value)
      .subscribe(() => {
        this.refreshFormData();
        FormHelper.setSingleFormGroupServerSideValidationErrors({},
            this.model.createAnimalEventServerSideErrorsProvider, this.createAnimalEventFormGroup);
        this.createAnimalEventModalOpened = false;
          this.createAnimalEventStoreProcess = false;
        },
      err => {
        FormHelper.setSingleFormGroupServerSideValidationErrors(err,
          this.model.createAnimalEventServerSideErrorsProvider, this.createAnimalEventFormGroup);
        this.createAnimalEventStoreProcess = false;
      });
  }

  animalGridReady($event: any) {
    this.selectedAnimalsFromGrid = [];
    this.selectedStableIdFromGrid = [];
  }
}
