<div *ngIf="validationErrors && validationErrors.length > 0">
  <clr-alert *ngFor="let msg of validationErrors" [clrAlertType]="'danger'" [clrAlertClosable]="alertClosable">
    <div clr-alert-item class="alert-item">
      <span class="alert-text" style="max-width: fit-content">
          {{msg.message}} &nbsp;
          <a *ngIf="msg.navigateKind==='AGENT'" [routerLink]="['/agent-edit', msg.navigateKey]">
            показать
          </a>
          <a *ngIf="msg.navigateKind==='QUARANTINE'" [routerLink]="['/quarantine-edit', msg.navigateKey]">
            показать
          </a>
          <a *ngIf="msg.navigateKind==='ANIMAL'" [routerLink]="['/animal-edit', msg.navigateKey]">
            показать
          </a>
          <a *ngIf="msg.navigateKind==='PRODUCT'" [routerLink]="['/product-edit', msg.navigateKey]">
            показать
          </a>
          <a *ngIf="msg.navigateKind==='PRODUCT_EXPERTISE'"
             [routerLink]="['/product-edit/' + msg.navigateKey + '/expertises/' + msg.navigateKey2]">
            показать
          </a>
          <a *ngIf="msg.navigateKind==='STABLE'" [routerLink]="['/stable-edit', msg.navigateKey]">
            показать
          </a>
          <a *ngIf="msg.navigateKind==='ANIMAL_EVENT'" [routerLink]="['/animal-event-edit', msg.navigateKey]">
            показать
          </a>
          <a *ngIf="msg.navigateKind==='DRUG'" [routerLink]="['/drug-edit', msg.navigateKey]">
            показать
          </a>
      </span>
    </div>
  </clr-alert>
</div>
