import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-doc-validation-errors',
  templateUrl: './doc-validation-errors.component.html'
})
export class DocValidationErrorsComponent {

  @Input() validationErrors = [];
  @Input() alertClosable = true;
}
