import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { ServerSideErrorsProvider } from '../../../logic/validators/server-side-errors-provider';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';
import { EditAgentCommonFormComponent } from './edit-agent-common-form.component';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { AppFormGroup } from '../../../ui/controls/app-form-group';
import { AgentDataService } from '../../../logic/services/agent-data.service';
import { MetadataService } from '../../../logic/services/metadata.service';
import { AgentSearchModalComponent } from '../search/agent-search-modal.component';
import { AgentSearchResultsFormComponent } from '../search/agent-search-results-form.component';

@Component({
  selector: 'app-edit-agent-common',
  templateUrl: './edit-agent-common.component.html'
})
export class EditAgentCommonComponent implements ServerSideErrorsProvider, OnInit, OnDestroy {

  private _id: number;

  @Input()
  get id(): number {
    return this._id;
  }

  set id(id: number) {
    if (this._id !== id) {
      this._id = id;
      this.refreshFormDataInternal();
    }
  }

  @Input() model: any = {};
  storing = false;
  serverSideValidationErrors: any[];

  updateSyncStateTimer: any = {};
  updateSyncStatePending: any = {};

  clrModalOpen = false;
  formGroupMerge = this.fb.group({
    mergeSourceId: null
  }) as AppFormGroup;
  selectedCheckboxConfirmAction = false;

  get contextFormGroup(): AppFormGroup {
    return this.model ? this.model.contextFormGroup : undefined;
  }

  get syncStates(): any[] {
    return this.model && this.model.syncStates ? Object.values(this.model.syncStates) : undefined;
  }

  constructor(private bookmarkService: BookmarkService,
              private agentDataService: AgentDataService,
              private alertService: AlertService,
              private cache: AddressPersonFioCacheService,
              private dataCachingService: DataCachingService,
              private waitingOverlay: GlobalWaitingOverlayService,
              private fb: FormBuilder,
              public navigationService: AppNavigationService,
              private metadataService: MetadataService) {
  }

  store() {

    FormHelper.markAsSubmitted(this.contextFormGroup);
    EditAgentCommonFormComponent.prettifyFormGroup(this.contextFormGroup);

    if (!this.contextFormGroup.valid) {
      return;
    }

    this.storing = true;

    this.agentDataService.storeAgentCommonInfo(this.contextFormGroup.value).subscribe(val => {
      this.storing = false;
      this.cache.invalidateAgent(this.id);
      this.bookmarkService.refreshAgentCardBookmark(this.id);
      this.contextFormGroup.markAsUntouched();
      this.contextFormGroup.markAsPristine();
      FormHelper.setSingleFormGroupServerSideValidationErrors({}, this.model, this.contextFormGroup);
      this.updateModelSyncStateFromLookup(val);
      this.alertService.success(`Общая информация для [${this.contextFormGroup.get('shortCaption').value}] успешно сохранена`);
    }, error => {
      this.storing = false;
      FormHelper.setSingleFormGroupServerSideValidationErrors(error, this.model, this.contextFormGroup);
      window.document.getElementById('agent-common-content').scrollTop = 0;
    });
  }

  isInvalid(fg: FormGroup, cname: string) {
    return FormHelper.isInvalid(fg, cname);
  }

  cancelEdit() {
    this.dataCachingService.removeCachedData('EditAgentCommon', this.id.toString());
    this.refreshFormDataInternal();
  }

  refreshFormData(resyncWithMercury?: boolean) {
    this.dataCachingService.removeCachedData('EditAgentCommon', this.id.toString());
    this.refreshFormDataInternal(resyncWithMercury);
  }

  refreshFormDataInternal(resyncWithMercury?: boolean) {
    this.waitingOverlay.StartWaiting();

    this.model = { id: this.id, serverSideValidationErrors: [], syncState: {} };

    const existing = this.dataCachingService.getCachedData('EditAgentCommon', this.id.toString());

    if (existing) {
      this.model = existing;
      this.waitingOverlay.EndWaiting();
    } else {
      (resyncWithMercury
        ? this.agentDataService.getAgentCommonForEditWithSync(this.id)
        : this.agentDataService.getAgentCommonForEdit(this.id)).subscribe({
        next: data => {
          this.model.id = data.id;
          this.model.contextFormGroup = EditAgentCommonFormComponent.createFormGroup(this.fb, this.model, data);
          this.updateModelSyncStateFromLookup(data);
          this.cache.invalidateAgent(this.id);
          this.dataCachingService.addToCache('EditAgentCommon', this.id.toString(), this.model);
          this.waitingOverlay.EndWaiting();
        }, error: () => {
          this.waitingOverlay.EndWaiting();
        }
      });
    }
  }

  private updateModelSyncStateFromLookup(data: any) {
    data.targetObjId = this.id;
    data.conversationTypeId = -1;
    this.updateModelSyncState(data, -1);
  }

  private updateModelSyncState(data: any, conversationTypeId: number) {
    if (!this.model.syncStates) {
      this.model.syncStates = {};
    }
    this.model.syncStates[conversationTypeId] = data;
  }

  private refreshSyncStateInternal(conversationTypeId: number) {

    if (!this.contextFormGroup) {
      return;
    }

    const state = this.model && this.model.syncStates ? this.model.syncStates[conversationTypeId] : undefined;

    // если ничего не запланировано по синхронизации - ничего и не может произойти и обновлять состояние не надо
    if (state && (!state.mercuryExchangeNextTime || new Date(state.mercuryExchangeNextTime) > new Date())) {
      return;
    }

    if (this.updateSyncStatePending[conversationTypeId]) {
      return;
    }

    this.updateSyncStatePending[conversationTypeId] = true;

    (conversationTypeId === -1
      ? this.agentDataService.getAgentCommonForLookup(this.id)
      : this.metadataService.getSyncState(this.id, conversationTypeId))
      .subscribe({
        next: data => {
          this.updateSyncStatePending[conversationTypeId] = false;
          if (conversationTypeId === -1) {
            this.updateModelSyncStateFromLookup(data);
          } else {
            this.updateModelSyncState(data, conversationTypeId);
          }
        }, error: () => {
          if (conversationTypeId === -1) {
            this.updateModelSyncStateFromLookup({});
          } else {
            this.updateModelSyncState({}, conversationTypeId);
          }
        }
      });
  }

  ngOnInit(): void {
    this.updateSyncStateTimer[-1] = setInterval(() => this.refreshSyncStateInternal(-1), 10000);
  }

  ngOnDestroy(): void {
    if (this.updateSyncStateTimer) {
      Object.keys(this.updateSyncStateTimer).forEach(x => clearInterval(this.updateSyncStateTimer[x]));
      this.updateSyncStateTimer = {};
    }
  }

  deleteAgent() {
    this.alertService.confirmModal('Вы уверены, что хотите аннулировать хозяйствующий субъект?').subscribe(() => {
      this.agentDataService.deleteAgent(this.id).subscribe(() => {
        this.dataCachingService.removeCachedData('EditAgentCommon', this.id.toString());
        this.bookmarkService.removeOpenCardBookmark(['/agent-edit'], this.id.toString());
      });
    });
  }

  restoreDeletedAgent() {
    this.alertService.confirmModal('Вы уверены, что хотите восстановить аннулированный хозяйствующий субъект?').subscribe(() => {
      this.agentDataService.restoreDeletedAgent(this.id).subscribe(() => {
        this.dataCachingService.removeCachedData('EditAgentCommon', this.id.toString());
        this.refreshFormDataInternal();
      });
    });
  }

  removePersonalAccountAgent() {
    this.alertService.confirmModal('В результате удаления личного кабинета у ХС будет удалена контактная информация,' +
      ' неподтверждённые животные, история переписок с учреждениями, отменены зарезервированные талоны. ' +
      'Вы уверены, что хотите удалить личный кабинет хозяйствующего субъекта во внешней системе?')
      .subscribe(() => {
        this.agentDataService.removePersonalAccountAgent(this.id).subscribe(() => {
          this.refreshFormData();
        });
      });
  }

  searchAgent() {
    this.navigationService.searchAgent(AgentSearchModalComponent, {
      excludeId: this.id,
      excludeCaption: AgentSearchResultsFormComponent.EXCLUDE_CAPTIONS.CURRENT_OPEN,
    }).subscribe(val => {
      if (val) {
        this.formGroupMerge.get('mergeSourceId').setValue(val);
      }
    });
  }

  closeMergeModal() {
    this.clrModalOpen = false;
    this.selectedCheckboxConfirmAction = false;
    this.formGroupMerge.reset();
  }

  mergeAgent() {
    this.agentDataService.mergeAgent(this.id, this.formGroupMerge.get('mergeSourceId').value)
      .subscribe(
        () => {
          this.clearCacheAfterMerge();
          this.bookmarkService.removeOpenCardBookmark(['/stable-edit'], this.formGroupMerge.get('mergeSourceId').value.toString());
          this.closeMergeModal();
        },
        error => FormHelper.setSingleFormGroupServerSideValidationErrors(error, this, this.formGroupMerge));
  }

  clearCacheAfterMerge() {

    const animals = this.dataCachingService.getCachedData('EditAgentAnimals', this.formGroupMerge.get('mergeSourceId').value.toString());
    if (animals && animals.searchResults) {
      animals.searchResults.forEach(animal => this.dataCachingService.removeCachedData('EditAnimalCommon', animal.id.toString()));
    }
    this.dataCachingService.removeCachedData('EditAgentAnimals', this.formGroupMerge.get('mergeSourceId').value.toString());
    this.dataCachingService.removeCachedData('EditAgentAnimals', this.id.toString());

    const stables = this.dataCachingService.getCachedData('EditAgentStables', this.formGroupMerge.get('mergeSourceId').value.toString());
    if (stables && stables.searchResults) {
      stables.searchResults.forEach(stable => this.dataCachingService.removeCachedData('EditStableCommon', stable.id.toString()));
    }
    this.dataCachingService.removeCachedData('EditAgentStables', this.formGroupMerge.get('mergeSourceId').value.toString());
    this.dataCachingService.removeCachedData('EditAgentStables', this.id.toString());

    this.dataCachingService.removeCachedData('EditAgentTickets', this.formGroupMerge.get('mergeSourceId').value.toString());
    this.dataCachingService.removeCachedData('EditAgentTickets', this.id.toString());

    this.dataCachingService.removeCachedData('EditAgentCommon', this.formGroupMerge.get('mergeSourceId').value.toString());
    this.dataCachingService.removeCachedData('EditAgentCommon', this.id.toString());
  }

  grantAccessOpenApi() {
    this.actionAccessOpenApi(true);
  }

  revokeAccessOpenApi() {
    this.actionAccessOpenApi(false);
  }

  actionAccessOpenApi(isGrant: boolean) {
    (isGrant
      ? this.agentDataService.grantAccessOpenApi(this.id)
      : this.agentDataService.revokeAccessOpenApi(this.id)
    ).subscribe(() => this.refreshFormData());
  }
}
