
    <app-query-lookup-combo-filter parameterName="created_institution" [lookupName]="'all-institution'"
                                   [contextFormGroup]="contextFormGroup" [queryUserMode]="queryUserMode">
      Учреждение-создатель
    </app-query-lookup-combo-filter>
    <app-query-lookup-combo-filter *ngIf="contextFormGroup.get('_par_created_institution').value"
                                   parameterName="created_institution_employee"
                                   [lookupName]="'institution-employee' + contextFormGroup.get('_par_created_institution').value"
                                   [contextFormGroup]="contextFormGroup" [queryUserMode]="queryUserMode">
      Пользователь-создатель
    </app-query-lookup-combo-filter>
    <app-query-date-time-filter parameterName="created_time" [contextFormGroup]="contextFormGroup"
                                [queryUserMode]="queryUserMode">
      Время создания
    </app-query-date-time-filter>
    <app-query-date-time-filter parameterName="birth_date" [contextFormGroup]="contextFormGroup"
                                [queryUserMode]="queryUserMode">
      Дата рождения
    </app-query-date-time-filter>
    <app-query-date-time-filter parameterName="death_date" [contextFormGroup]="contextFormGroup"
                                [queryUserMode]="queryUserMode">
      Дата смерти
    </app-query-date-time-filter>
    <app-query-boolean-filter parameterName="animal_group" [contextFormGroup]="contextFormGroup"
                              [queryUserMode]="queryUserMode">
      Групповой учёт
    </app-query-boolean-filter>
    <app-query-string-filter parameterName="title" [contextFormGroup]="contextFormGroup"
                             [queryUserMode]="queryUserMode">Наименование (кличка)
    </app-query-string-filter>
    <app-query-boolean-filter parameterName="animal_is_retired" [contextFormGroup]="contextFormGroup"
                              [queryUserMode]="queryUserMode">
      Архивный
    </app-query-boolean-filter>
    <app-query-lookup-combo-filter parameterName="animal_type" [lookupName]="'animal-type'"
                                   [contextFormGroup]="contextFormGroup" [queryUserMode]="queryUserMode">
      Вид животн.
    </app-query-lookup-combo-filter>
    <app-query-lookup-list-filter parameterName="animal_subtype" *ngIf="contextFormGroup.get('_par_animal_type').value"
                                  [lookupName]="'animal-subtype' + contextFormGroup.get('_par_animal_type').value"
                                  [contextFormGroup]="contextFormGroup" [queryUserMode]="queryUserMode"
                                  [hasParentParamFilter]="contextFormGroup.get('_par_animal_type_filter').value === 101 ? true : undefined">
      Подвид животн.
    </app-query-lookup-list-filter>
    <app-query-lookup-list-filter parameterName="animal_breed" *ngIf="contextFormGroup.get('_par_animal_type').value"
                                  [lookupName]="'animal-breed' + contextFormGroup.get('_par_animal_type').value"
                                  [contextFormGroup]="contextFormGroup" [queryUserMode]="queryUserMode">
      Порода
    </app-query-lookup-list-filter>
    <app-query-date-time-filter parameterName="total_count" [contextFormGroup]="contextFormGroup"
                                [queryUserMode]="queryUserMode" [useDatePicker]="false">
      Кол-во в группе
    </app-query-date-time-filter>
    <app-query-string-filter parameterName="color" [contextFormGroup]="contextFormGroup"
                             [queryUserMode]="queryUserMode">Масть
    </app-query-string-filter>
    <app-query-date-time-filter parameterName="weight" [contextFormGroup]="contextFormGroup"
                                [queryUserMode]="queryUserMode" [useDatePicker]="false">
        Вес
    </app-query-date-time-filter>
    <app-query-lookup-combo-filter parameterName="animal_reason" [lookupName]="'animal-reason'"
                                   [contextFormGroup]="contextFormGroup" [queryUserMode]="queryUserMode">
      Цель владен.
    </app-query-lookup-combo-filter>
    <app-query-lookup-combo-filter parameterName="animal_death_reason" [lookupName]="'death-reason'"
                                   [contextFormGroup]="contextFormGroup" [queryUserMode]="queryUserMode">
      Прич. выбыт.
    </app-query-lookup-combo-filter>
    <app-query-lookup-combo-filter parameterName="animal_gender" [lookupName]="'gender-animal'"
                                   [contextFormGroup]="contextFormGroup" [queryUserMode]="queryUserMode">
      Пол животн.
    </app-query-lookup-combo-filter>
    <app-query-lookup-combo-filter parameterName="origin_country" [lookupName]="'country'"
                                   [contextFormGroup]="contextFormGroup" [queryUserMode]="queryUserMode">
      Страна происхождения
    </app-query-lookup-combo-filter>
  