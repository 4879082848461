import { AddressPersonFioCacheService } from './address-person-fio-cache.service';
import { Router } from '@angular/router';
import { LookupSourceService } from './lookup-source.service';
import * as i0 from "@angular/core";
import * as i1 from "./address-person-fio-cache.service";
import * as i2 from "@angular/router";
import * as i3 from "./lookup-source.service";
var BookmarkService = /** @class */ (function () {
    function BookmarkService(cacheService, router, lookupService) {
        this.cacheService = cacheService;
        this.router = router;
        this.lookupService = lookupService;
        this.searchBookmarks = [];
        this.groupOperationBookmarks = [];
        this.peopleCardBookmarks = [];
        this.dictionaryBookmarks = [];
    }
    Object.defineProperty(BookmarkService.prototype, "topLevelBookmarks", {
        get: function () {
            var retVal = [];
            this.peopleCardBookmarks.forEach(function (bm) {
                if (!bm.parentTag) {
                    retVal.push(bm);
                }
            });
            return retVal;
        },
        enumerable: true,
        configurable: true
    });
    BookmarkService.prototype.getChildBookmarks = function (parentTag) {
        var retVal = [];
        this.peopleCardBookmarks.forEach(function (bm) {
            if (bm.parentTag === parentTag) {
                retVal.push(bm);
            }
        });
        return retVal;
    };
    BookmarkService.prototype.addOpenCardBookmark = function (uniqueTag, title, route, icon, skipIfNotExists, parentTag) {
        if (icon === void 0) { icon = 'user'; }
        if (!route || !route[0]) {
            throw new Error('При добавлении закладки не указан или некорректно указан маршрут (route)');
        }
        var existing = this.peopleCardBookmarks.find(function (value) { return value.uniqueTag === uniqueTag
            && value.route[0] === route[0]; });
        if (!existing) {
            if (!skipIfNotExists) {
                this.peopleCardBookmarks.push({
                    title: title,
                    route: route,
                    uniqueTag: uniqueTag,
                    icon: icon,
                    parentTag: parentTag
                });
            }
        }
        else {
            existing.title = title;
            existing.route = route;
            existing.parentTag = parentTag;
            existing.icon = icon;
        }
        if (existing && parentTag && parentTag.startsWith('/stable-edit')) {
            this.refreshStableCardBookmark(+parentTag.substring(12), undefined);
        }
        if (existing && parentTag && parentTag.startsWith('/animal-edit')) {
            this.refreshAnimalCardBookmark(+parentTag.substring(12), undefined, undefined);
        }
    };
    BookmarkService.prototype.addGroupOperationBookmark = function (title, uniqueTag, route) {
        if (!this.groupOperationBookmarks.find(function (value) { return value.uniqueTag === uniqueTag; })) {
            this.groupOperationBookmarks.push({ title: title, route: route, uniqueTag: uniqueTag });
        }
    };
    BookmarkService.prototype.removeOpenCardBookmark = function (route, uniqueTag) {
        var _this = this;
        var bm;
        var ix = this.peopleCardBookmarks.findIndex(function (value) {
            bm = value;
            return value.uniqueTag === uniqueTag && value.route[0] === route[0];
        });
        if (ix >= 0) {
            this.peopleCardBookmarks.splice(ix, 1);
            if (bm.route.length > 0 && this.router.url.startsWith(bm.route[0] + '/' + uniqueTag)) {
                this.router.navigate(['']);
            }
        }
        this.getChildBookmarks(route[0] + uniqueTag).forEach(function (el) {
            _this.removeOpenCardBookmark(el.route, el.uniqueTag);
        });
    };
    BookmarkService.prototype.removeGroupOperationBookmark = function (uniqueTag) {
        var bm;
        var ix = this.groupOperationBookmarks.findIndex(function (value) {
            bm = value;
            return value.uniqueTag === uniqueTag;
        });
        if (ix >= 0) {
            this.groupOperationBookmarks.splice(ix, 1);
            if (bm.route.length > 0 && this.router.url.startsWith(bm.route[0])) {
                this.router.navigate(['']);
            }
        }
    };
    BookmarkService.prototype.removeSearchBookmark = function (title) {
        var bm;
        var ix = this.searchBookmarks.findIndex(function (value) {
            bm = value;
            return value.title === title;
        });
        if (ix >= 0) {
            this.searchBookmarks.splice(ix, 1);
            if (bm.route.length > 0 && this.router.url === bm.route[0] + '/' + encodeURIComponent(bm.route[1])) {
                this.router.navigate(['']);
            }
        }
    };
    BookmarkService.prototype.refreshInstitutionCardBookmark = function (institutionId) {
        var _this = this;
        if (!institutionId) {
            return;
        }
        this.addOpenCardBookmark(institutionId.toString(), "\u041A\u0430\u0440\u0442\u043E\u0447\u043A\u0430 #" + institutionId, ['/institution-edit', +institutionId], 'bank');
        setTimeout(function () {
            return _this.cacheService.getInstitution(+institutionId).subscribe(function (institution) {
                _this.addOpenCardBookmark(institutionId.toString(), institution.shortCaption, ['/institution-edit', +institutionId], 'bank');
            });
        }, 300);
    };
    BookmarkService.prototype.refreshAgentCardBookmark = function (agentId) {
        var _this = this;
        if (!agentId) {
            return;
        }
        this.addOpenCardBookmark(agentId.toString(), "\u041A\u0430\u0440\u0442\u043E\u0447\u043A\u0430 #" + agentId, ['/agent-edit', +agentId], 'users');
        setTimeout(function () {
            return _this.cacheService.getAgent(+agentId).subscribe(function (agent) {
                _this.addOpenCardBookmark(agentId.toString(), agent.shortCaption, ['/agent-edit', +agentId], 'users');
            });
        }, 300);
    };
    BookmarkService.prototype.refreshProductCardBookmark = function (productId) {
        var _this = this;
        if (!productId) {
            return;
        }
        this.addOpenCardBookmark(productId.toString(), "\u041A\u0430\u0440\u0442\u043E\u0447\u043A\u0430 #" + productId, ['/product-edit', +productId], 'truck');
        setTimeout(function () {
            return _this.cacheService.getProduct(+productId).subscribe(function (product) {
                _this.addOpenCardBookmark(productId.toString(), product.caption, ['/product-edit', +productId], +product['comments'] === 4 ? 'recycle' : 'truck');
            });
        }, 300);
    };
    BookmarkService.prototype.refreshProductTransactionCardBookmark = function (transactionId) {
        var _this = this;
        if (!transactionId) {
            return;
        }
        this.addOpenCardBookmark(transactionId.toString(), "\u041A\u0430\u0440\u0442\u043E\u0447\u043A\u0430 \u0442\u0440\u0430\u043D\u0437\u0430\u043A\u0446\u0438\u0438 #" + transactionId, ['/product-transaction', +transactionId], 'organization');
        this.cacheService.getProductTransaction(+transactionId).subscribe(function (product) {
            _this.addOpenCardBookmark(transactionId.toString(), product.caption, ['/product-transaction', +transactionId], 'organization');
        });
    };
    BookmarkService.prototype.refreshStableCardBookmark = function (stableId, newAgentId) {
        var _this = this;
        if (!stableId) {
            return;
        }
        this.addOpenCardBookmark(stableId.toString(), "\u041A\u0430\u0440\u0442\u043E\u0447\u043A\u0430 #" + stableId, ['/stable-edit', +stableId, { newAgentId: newAgentId }], 'building');
        setTimeout(function () {
            return _this.cacheService.getStable(+stableId).subscribe(function (agent) {
                _this.addOpenCardBookmark(stableId.toString(), agent.caption, ['/stable-edit', +stableId, { newAgentId: newAgentId }], 'building');
            });
        }, 300);
    };
    BookmarkService.prototype.refreshDrugCardBookmark = function (drugId) {
        var _this = this;
        if (!drugId) {
            return;
        }
        this.addOpenCardBookmark(drugId.toString(), "\u041A\u0430\u0440\u0442\u043E\u0447\u043A\u0430 #" + drugId, ['/drug-edit', +drugId], 'flask');
        setTimeout(function () {
            return _this.cacheService.getDrug(+drugId).subscribe(function (drug) {
                _this.addOpenCardBookmark(drugId.toString(), drug.caption, ['/drug-edit', +drugId], 'flask');
            });
        }, 300);
    };
    BookmarkService.prototype.refreshAnimalCardBookmark = function (animalId, newAgentId, newStableId) {
        var _this = this;
        if (!animalId) {
            return;
        }
        this.cacheService.getAnimal(+animalId).subscribe(function (animal) {
            _this.addOpenCardBookmark(animalId.toString(), animal.caption, ['/animal-edit', +animalId, { newAgentId: newAgentId, newStableId: newStableId }], 'piggy-bank', undefined, animal.stableId ? '/stable-edit' + animal.stableId.toString() : undefined);
        });
    };
    BookmarkService.prototype.refreshAnimalEventCardBookmark = function (animalEventId) {
        var _this = this;
        if (!animalEventId) {
            return;
        }
        this.addOpenCardBookmark(animalEventId.toString(), "\u041A\u0430\u0440\u0442\u043E\u0447\u043A\u0430 #" + animalEventId, ['/animal-event-edit', +animalEventId, {}], 'color-picker');
        setTimeout(function () {
            return _this.cacheService.getAnimalEvent(+animalEventId).subscribe(function (animalEvent) {
                var parentTag;
                if (animalEvent.animalId) {
                    parentTag = '/animal-edit' + animalEvent.animalId.toString();
                }
                else if (animalEvent.stableId) {
                    parentTag = '/stable-edit' + animalEvent.stableId.toString();
                }
                _this.addOpenCardBookmark(animalEventId.toString(), animalEvent.caption, ['/animal-event-edit', +animalEventId, {}], 'color-picker', undefined, parentTag);
            });
        }, 300);
    };
    BookmarkService.prototype.addDictionaryBookmark = function (title, route) {
        if (!this.dictionaryBookmarks.find(function (value) { return value.title === title; })) {
            this.dictionaryBookmarks.push({ title: title, route: route });
        }
    };
    BookmarkService.prototype.removeDictionaryBookmark = function (title) {
        var ix = this.dictionaryBookmarks.findIndex(function (value) {
            return value.title === title;
        });
        if (ix >= 0) {
            var bm = this.dictionaryBookmarks.splice(ix, 1)[0];
            if (bm.route.length > 0 && this.router.isActive(bm.route.join('/'), false)) {
                this.router.navigate(['']);
            }
        }
    };
    BookmarkService.prototype.refreshQueryViewBookmark = function (queryId, caption) {
        if (!queryId) {
            return;
        }
        this.addOpenCardBookmark(queryId.toString(), caption, ['/query-view', +queryId], 'bolt');
    };
    BookmarkService.prototype.refreshPstReportBookmark = function (reportId) {
        var _this = this;
        if (!reportId) {
            return;
        }
        this.addOpenCardBookmark(reportId.toString(), "\u041E\u0442\u0447\u0435\u0442 #" + reportId, ['/pst-report-edit', +reportId], 'line-chart');
        setTimeout(function () {
            return _this.cacheService.getPstReport(+reportId).subscribe(function (animalEvent) {
                _this.addOpenCardBookmark(reportId.toString(), animalEvent.caption, ['/pst-report-edit', +reportId], 'line-chart');
            });
        }, 300);
    };
    BookmarkService.prototype.refreshQuarantineCardBookmark = function (id) {
        var _this = this;
        if (!id) {
            return;
        }
        this.lookupService.getLookupCaption(id, 'quarantine')
            .subscribe(function (caption) { return _this.addOpenCardBookmark(id.toString(), caption, ['/quarantine-edit', +id], 'flame'); });
    };
    BookmarkService.ngInjectableDef = i0.defineInjectable({ factory: function BookmarkService_Factory() { return new BookmarkService(i0.inject(i1.AddressPersonFioCacheService), i0.inject(i2.Router), i0.inject(i3.LookupSourceService)); }, token: BookmarkService, providedIn: "root" });
    return BookmarkService;
}());
export { BookmarkService };
