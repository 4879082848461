import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { FormBuilder, Validators } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { MetadataService } from '../../../logic/services/metadata.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { map } from 'rxjs/internal/operators';
var QuarantineTemplateBrowseComponent = /** @class */ (function () {
    function QuarantineTemplateBrowseComponent(route, router, fb, alertService, metadataService, globalWaitingOverlayService, navigationService, lookupSourceService) {
        this.route = route;
        this.router = router;
        this.fb = fb;
        this.alertService = alertService;
        this.metadataService = metadataService;
        this.globalWaitingOverlayService = globalWaitingOverlayService;
        this.navigationService = navigationService;
        this.lookupSourceService = lookupSourceService;
        this.templates = [];
        this.storing = false;
        this.isInvalid = FormHelper.isInvalid;
    }
    QuarantineTemplateBrowseComponent.getEventGroupDef = function (val, fb, eventTypeLookup) {
        if (val === void 0) { val = {}; }
        var files = (val.files || []).slice();
        files.push({});
        return {
            templateId: val.templateId,
            id: val.id,
            eventTypeId: [val.eventTypeId, Validators.required],
            orderNo: val.orderNo,
            responsibleTypeId: fb.array((val.responsibleTypeId || []).map(function (el) { return fb.control(el); })),
            periodTypeId: val.periodTypeId,
            periodTypeOther: val.periodTypeOther,
            __editing: !!val.__editing,
            __hovered: !!val.__hovered,
            __branchId: eventTypeLookup ? (eventTypeLookup['Obj' + val.eventTypeId] || {}).branchId : undefined
        };
    };
    QuarantineTemplateBrowseComponent.buildFormArray = function (eventsData, fb, eventTypeLookup) {
        eventsData.sort(function (x, y) { return x.orderNo - y.orderNo; });
        return fb.array(eventsData.map(function (item) { return fb.group(QuarantineTemplateBrowseComponent.getEventGroupDef(item, fb, eventTypeLookup)); }));
    };
    QuarantineTemplateBrowseComponent.buildFormGroup = function (fb, data, eventTypeLookup) {
        if (data === void 0) { data = {}; }
        return fb.group({
            id: data.id,
            caption: data.caption,
            appliedDiseases: fb.array((data.appliedDiseases || []).map(function (el) { return fb.control(el); })),
            events: QuarantineTemplateBrowseComponent.buildFormArray(data.events || [], fb, eventTypeLookup)
        });
    };
    Object.defineProperty(QuarantineTemplateBrowseComponent.prototype, "id", {
        get: function () {
            return this.selectedTemplate ? this.selectedTemplate.id : undefined;
        },
        enumerable: true,
        configurable: true
    });
    QuarantineTemplateBrowseComponent.prototype.refreshTemplates = function () {
        var _this = this;
        this.globalWaitingOverlayService.StartWaiting();
        this.metadataService.getMetadata('quarantine-template').subscribe({
            next: function (data) {
                data.sort(function (a, b) { return a.caption.localeCompare(b.caption); });
                _this.templates = data;
                _this.navigateInitialTemplate();
                _this.globalWaitingOverlayService.EndWaiting();
            }, error: function (error) {
                _this.alertService.error(error);
                _this.globalWaitingOverlayService.EndWaiting();
            }
        });
    };
    QuarantineTemplateBrowseComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.paramMap
            .subscribe(function (params) {
            _this.templates = [];
            _this.initialTemplateId = +params.get('id') || undefined;
            _this.navigateInitialTemplate();
            _this.refreshTemplates();
        });
    };
    QuarantineTemplateBrowseComponent.prototype.deleteCurrentTemplate = function () {
        var _this = this;
        if (!this.selectedTemplate.id) {
            return;
        }
        this.alertService.confirmModal('Вы уверены, что хотите безвозвратно удалить текущий шаблон?')
            .subscribe(function () {
            _this.metadataService.deleteMeta('quarantine-template', _this.selectedTemplate.id).subscribe(function () {
                _this.router.navigate(["dictionary/quarantine-template"]);
            });
        });
    };
    QuarantineTemplateBrowseComponent.prototype.addTemplate = function () {
        this.selectedTemplate = { id: undefined, caption: 'Новый шаблон', events: [], appliedDiseases: [] };
        this.templates.push(this.selectedTemplate);
        this.refreshFormData();
    };
    QuarantineTemplateBrowseComponent.prototype.selectedTemplateChange = function () {
        if (this.selectedTemplate && this.selectedTemplate.id) {
            this.router.navigate(["dictionary/quarantine-template/" + this.selectedTemplate.id]);
        }
        else {
            this.refreshFormData();
        }
    };
    QuarantineTemplateBrowseComponent.prototype.navigateInitialTemplate = function () {
        var _this = this;
        if (!this.templates) {
            return;
        }
        this.selectedTemplate = this.templates.find(function (item) { return (item.id === _this.initialTemplateId); });
        this.refreshFormData();
    };
    QuarantineTemplateBrowseComponent.prototype.getEvents = function () {
        return this.contextFormGroup.get('events');
    };
    QuarantineTemplateBrowseComponent.prototype.store = function () {
        var _this = this;
        if (!this.contextFormGroup.valid) {
            return;
        }
        this.storing = true;
        if (this.id) {
            this.metadataService.updateMeta('quarantine-template', this.contextFormGroup.value, this.id)
                .subscribe({
                next: function () {
                    _this.storing = false;
                    _this.refreshFormData();
                },
                error: function () {
                    _this.storing = false;
                }
            });
        }
        else {
            this.metadataService.createMeta('quarantine-template', this.contextFormGroup.value)
                .subscribe({
                next: function (data) {
                    _this.router.navigate(["dictionary/quarantine-template/" + data.data.id]);
                    _this.storing = false;
                },
                error: function () {
                    _this.storing = false;
                }
            });
        }
    };
    QuarantineTemplateBrowseComponent.prototype.refreshFormData = function () {
        this.refreshFormDataInternal();
    };
    QuarantineTemplateBrowseComponent.prototype.refreshFormDataInternal = function () {
        var _this = this;
        if (!this.selectedTemplate) {
            return;
        }
        this.globalWaitingOverlayService.StartWaiting();
        this.lookupSourceService.getLookupObj('quarantine-event-type').subscribe(function (eventTypeLookup) {
            if (!_this.id) {
                _this.contextFormGroup = QuarantineTemplateBrowseComponent.buildFormGroup(_this.fb, {}, eventTypeLookup);
                _this.globalWaitingOverlayService.EndWaiting();
            }
            else {
                _this.metadataService.getMetadataSingle('quarantine-template', _this.id).subscribe({
                    next: function (data) {
                        _this.contextFormGroup = QuarantineTemplateBrowseComponent.buildFormGroup(_this.fb, data, eventTypeLookup);
                        _this.globalWaitingOverlayService.EndWaiting();
                    }, error: function (error) {
                        _this.alertService.error(error);
                        _this.globalWaitingOverlayService.EndWaiting();
                    }
                });
            }
        });
    };
    QuarantineTemplateBrowseComponent.prototype.addEvent = function () {
        var _this = this;
        this.lookupSourceService.getLookupObj('quarantine-event-type').subscribe(function (eventTypeLookup) {
            _this.getEvents().push(_this.fb.group(QuarantineTemplateBrowseComponent.getEventGroupDef({
                orderNo: _this.calculateNextOrderNo(),
                __editing: true
            }, _this.fb, eventTypeLookup)));
            _this.contextFormGroup.markAsDirty();
        });
    };
    QuarantineTemplateBrowseComponent.prototype.deleteEvent = function (eventItem) {
        var index = this.getEvents().controls.findIndex(function (el) { return el === eventItem; });
        if (index < 0) {
            return;
        }
        this.getEvents().removeAt(index);
        this.contextFormGroup.markAsDirty();
    };
    QuarantineTemplateBrowseComponent.prototype.calculateNextOrderNo = function () {
        var maxNo = 0;
        this.getEvents().controls.forEach(function (ctrl) {
            if (ctrl.value.orderNo > maxNo) {
                maxNo = ctrl.value.orderNo;
            }
        });
        return maxNo + 1;
    };
    QuarantineTemplateBrowseComponent.prototype.goEditing = function (eventItem) {
        eventItem.get('__editing').setValue(true);
        eventItem.markAsDirty();
    };
    QuarantineTemplateBrowseComponent.prototype.goEndEditing = function (eventItem) {
        eventItem.get('__editing').setValue(false);
    };
    QuarantineTemplateBrowseComponent.prototype.getResponsiblesTitle = function (eventItem) {
        return this.lookupSourceService.getLookupObj('quarantine-event-responsible').pipe(map(function (respLookup) {
            var strResp = '';
            eventItem.value.responsibleTypeId.forEach(function (rt) {
                if (rt) {
                    strResp = strResp + (strResp ? ', ' : '') + respLookup[rt];
                }
            });
            return strResp;
        }));
    };
    QuarantineTemplateBrowseComponent.prototype.getUsedBranchIds = function () {
        var branchIds = [];
        var branchHash = {};
        this.getEvents().controls.forEach(function (eventItem) {
            var branchId = eventItem.get('__branchId').value;
            if (!branchHash[branchId]) {
                branchHash[branchId] = true;
                branchIds.push(branchId);
            }
        });
        return branchIds;
    };
    QuarantineTemplateBrowseComponent.prototype.getEventsByBranch = function (branchId, includeNoBranch) {
        var controls = [];
        this.getEvents().controls.forEach(function (eventItem) {
            var tid = eventItem.get('__branchId').value;
            if ((tid == branchId) || (!tid && includeNoBranch)) {
                controls.push(eventItem);
            }
        });
        return controls;
    };
    QuarantineTemplateBrowseComponent.prototype.moveUp = function (eventItem) {
        var index = this.getEvents().controls.findIndex(function (el) { return el === eventItem; });
        if (index <= 0) {
            return;
        }
        this.moveItem(eventItem, this.getEvents().controls[index - 1]);
    };
    QuarantineTemplateBrowseComponent.prototype.moveDown = function (eventItem) {
        var index = this.getEvents().controls.findIndex(function (el) { return el === eventItem; });
        if (index >= this.getEvents().controls.length - 1) {
            return;
        }
        this.moveItem(eventItem, this.getEvents().controls[index + 1]);
    };
    QuarantineTemplateBrowseComponent.prototype.moveItem = function (eventItem, eventItem2) {
        var orderNo = eventItem.value.orderNo;
        eventItem.get('orderNo').setValue(eventItem2.value.orderNo);
        eventItem.markAsDirty();
        eventItem2.get('orderNo').setValue(orderNo);
        eventItem2.markAsDirty();
        this.getEvents().controls.sort(function (x, y) { return x.get('orderNo').value - y.get('orderNo').value; });
    };
    return QuarantineTemplateBrowseComponent;
}());
export { QuarantineTemplateBrowseComponent };
