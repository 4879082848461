import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { AnimalSearchResultsFormComponent } from './animal-search-results-form.component';
import { IModalBody, ModalResult } from '../../../logic/services/app-navigation.service.models';
import { Observable } from 'rxjs';

@Component({
  template: `
    <div style="height: 60vh; background-color: white; position: relative;">
      <app-animal-search-results-form
        [model]="model" [addManyAnimals]="addManyAnimals"
        (selectionChanged)="selectedAnimalIds = $event"
        (rowClicked)="chooseAnimal($event)">
      </app-animal-search-results-form>
    </div>
    <div class="modal-footer itech-form-actions" *ngIf="addManyAnimals">
      <div>
      </div>
      <div>
        <button class="btn btn-success" (click)="chooseMultipleAnimals()"
                [disabled]="!selectedAnimalIds || !selectedAnimalIds.length">
          <clr-icon shape="check"></clr-icon>&nbsp;Выбрать
          {{selectedAnimalIds && selectedAnimalIds.length ? ' (' + selectedAnimalIds.length.toString() + ' шт.)' : ''}}
        </button>
      </div>
    </div>
  `
})
export class AnimalSearchModalComponent implements OnInit, IModalBody<any, void> {

  model = AnimalSearchResultsFormComponent.getDefaultModel();
  addManyAnimals = false;
  selectedAnimalIds: any[];

  constructor(public appNavigationService: AppNavigationService,
              private fb: FormBuilder) {
  }

  ngOnInit() {
    this.model = AnimalSearchResultsFormComponent.getDefaultModel(this.fb, this.appNavigationService.customModalParams);
    this.addManyAnimals = this.appNavigationService.customModalParams.addManyAnimals;
  }

  chooseAnimal(val: any) {
    if (!this.addManyAnimals) {
      this.appNavigationService.customModalAcceptExternalPromise.next(val);
      this.appNavigationService.customModalOpened = false;
    }
  }

  chooseMultipleAnimals() {
    if (this.addManyAnimals && this.selectedAnimalIds && this.selectedAnimalIds.length) {
      this.appNavigationService.customModalAcceptExternalPromiseAny.next(this.selectedAnimalIds);
      this.appNavigationService.customModalOpened = false;
    }
  }

  initModalBody(data: any) {
  }

  onModalAccept$(): Observable<ModalResult<void>> {
    return undefined;
  }
}
