<clr-tabs>
  <clr-tab>
    <button clrTabLink>Общее</button>
    <ng-template [(clrIfActive)]="model.commonTabActive">
      <clr-tab-content>
        <div class="edit-page-tab">
          <app-edit-drug-common [id]="id"></app-edit-drug-common>
        </div>
      </clr-tab-content>
    </ng-template>
  </clr-tab>
  <clr-tab>
    <button clrTabLink><clr-icon shape="flask"></clr-icon>Расход</button>
    <ng-template [(clrIfActive)]="model.spendingTabActive">
      <clr-tab-content>
        <div class="edit-page-tab">
          <app-edit-drug-spending [id]="id"></app-edit-drug-spending>
        </div>
      </clr-tab-content>
    </ng-template>
  </clr-tab>
</clr-tabs>
