<div class="itech-common-wrap no-pager">
  <div>
    <div class="itech-search-table-with-filter">
      <div style="display: flex; flex-direction: row;">
        <clr-icon shape="search" size="72"></clr-icon>
        <div class="compact form itech-highlighted-sections" [formGroup]="model.filterFormGroup"
             *ngIf="model && model.filterFormGroup">
          <section class="form-block itech-block-medium">
            <h3>Просмотр журнала интеграции Ветис.API</h3>
            <div class="form-group">
              <label for="opName">Вид операции</label>
              <app-text-lookup [validationTooltipRight]="true" class="itech-control-xlarge"
                               [contextControlId]="'opName'" [sorted]="true"
                               [contextControlName]="'opName'"
                               [lookupName]="'integration-op-type'" [contextFormGroup]="model.filterFormGroup"></app-text-lookup>
            </div>
            <div class="form-group">
              <label for="dateX">Дата операции</label>
              <label for="dateX" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                     [class.invalid]="isInvalid(model.filterFormGroup, 'dateX')">
                <input type="date" id="dateX" formControlName="dateX" (keydown)="filterEnterPressed($event)">
                <app-validation-tooltip [input]="model.filterFormGroup.controls['dateX']"></app-validation-tooltip>
              </label>
            </div>
          </section>
        </div>
      </div>

      <div>
        <app-grid [pagination]="false" [columnDefs]="gridColumnDefs"
                  [uniqueGridName]="'ViewIntegrationLog'" [rowData]="model.searchResults"></app-grid>
      </div>
    </div>
  </div>

  <div class="itech-form-actions">
    <button class="btn btn-primary" (click)="refreshResults()">
      <clr-icon shape="search" class="has-badge"></clr-icon>&nbsp;Обновить
    </button>
  </div>
</div>
