import { Component, HostBinding, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-query-details-drug-fields',
  template: `
      <app-query-lookup-combo-filter parameterName="created_institution" [lookupName]="'all-institution'"
                                     [contextFormGroup]="contextFormGroup" [queryUserMode]="queryUserMode">
          Учреждение-создатель
      </app-query-lookup-combo-filter>
      <app-query-lookup-combo-filter *ngIf="contextFormGroup.get('_par_created_institution').value"
                                     parameterName="created_institution_employee"
                                     [lookupName]="'institution-employee' + contextFormGroup.get('_par_created_institution').value"
                                     [contextFormGroup]="contextFormGroup" [queryUserMode]="queryUserMode">
          Пользователь-создатель
      </app-query-lookup-combo-filter>
      <app-query-date-time-filter parameterName="created_time" [contextFormGroup]="contextFormGroup"
                                  [queryUserMode]="queryUserMode">
          Время создания
      </app-query-date-time-filter>
      <app-query-lookup-list-filter parameterName="drug_kind_ids" [lookupName]="'drug-kind'" [useCheckboxes]="false"
                                    [contextFormGroup]="contextFormGroup" [queryUserMode]="queryUserMode"
                                    itemTitle="Вид препарата">
          Виды препарата
      </app-query-lookup-list-filter>
      <app-query-lookup-list-filter parameterName="drug_type_ids" [lookupName]="'drug-type'" [useCheckboxes]="false"
                                    [contextFormGroup]="contextFormGroup" [queryUserMode]="queryUserMode"
                                    itemTitle="Наименование">
          Наименования
      </app-query-lookup-list-filter>
      <app-query-lookup-combo-filter parameterName="institution_id" [lookupName]="'institution'"
                                     [contextFormGroup]="contextFormGroup" [queryUserMode]="queryUserMode">
          Учреждение
      </app-query-lookup-combo-filter>
      <app-query-lookup-combo-filter parameterName="budget_type_id" [lookupName]="'budget-type'"
                                     [contextFormGroup]="contextFormGroup" [queryUserMode]="queryUserMode">
          Бюджет
      </app-query-lookup-combo-filter>
      <app-query-date-time-filter parameterName="manufacture_date" [contextFormGroup]="contextFormGroup"
                                  [queryUserMode]="queryUserMode">
          Дата производства
      </app-query-date-time-filter>
      <app-query-date-time-filter parameterName="incoming_date" [contextFormGroup]="contextFormGroup"
                                  [queryUserMode]="queryUserMode">
          Дата прихода
      </app-query-date-time-filter>
      <app-query-date-time-filter parameterName="move_date" [contextFormGroup]="contextFormGroup"
                                  [queryUserMode]="queryUserMode">
          Дата передачи
      </app-query-date-time-filter>
      <app-query-date-time-filter parameterName="writeoff_date" [contextFormGroup]="contextFormGroup"
                                  [queryUserMode]="queryUserMode">
          Дата списания
      </app-query-date-time-filter>
      <app-query-date-time-filter parameterName="using_date" [contextFormGroup]="contextFormGroup"
                                  [queryUserMode]="queryUserMode">
          Дата расходования
      </app-query-date-time-filter>
      <app-query-date-time-filter parameterName="action_date" [contextFormGroup]="contextFormGroup"
                                  [queryUserMode]="queryUserMode">
          Дата движения
      </app-query-date-time-filter>
  `
})
export class QueryDetailsDrugFieldsComponent {
  @Input() contextFormGroup: FormGroup;
  @Input() queryUserMode;
  @HostBinding('attr.class') class = 'form-block itech-block-normal';
}
