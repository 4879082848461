import { Component, Input } from '@angular/core';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { ProductSearchResultsFormComponent } from '../../edit-product/search/product-search-results-form.component';
import { ProductDataService } from '../../../logic/services/product-data.service';
import { AgGridProductTransactionDetailRendererComponent } from '../../../ui/controls/ag-grid-product-transaction-detail-renderer.component';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { ProductSearchResultsComponent } from '../../edit-product/search/product-search-results.component';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';

@Component({
  selector: 'app-edit-animal-products',
  templateUrl: './edit-animal-products.component.html'
})
export class EditAnimalProductsComponent {

  @Input() model: any = {};
  @Input() isExternal = false;

  isInvalid = FormHelper.isInvalid;
  getRowStyle = ProductSearchResultsFormComponent.getRowStyle;
  gridColumnDefs = ProductSearchResultsFormComponent.getGridColumnDefs();

  detailCellRenderer = 'myDetailCellRenderer';
  frameworkComponents = { myDetailCellRenderer: AgGridProductTransactionDetailRendererComponent };
  gridIcons = {
    groupContracted: '<clr-icon shape="organization" style="cursor: pointer"></clr-icon>',
  };

  stableId;

  private _id: number;
  @Input()
  get id(): number {
    return this._id;
  }

  set id(id: number) {
    if (this._id !== id) {
      this._id = id;
      this.refreshFormDataInternal();
    }
  }

  constructor(private productDataService: ProductDataService,
              private dataCachingService: DataCachingService,
              private waitingOverlay: GlobalWaitingOverlayService,
              private lookupSourceService: LookupSourceService,
              public navigationService: AppNavigationService,
              private alertService: AlertService,
              private cacheService: AddressPersonFioCacheService) {
  }

  refreshFormData() {
    if (!this.isExternal) {
      this.dataCachingService.removeCachedData('EditAnimalProducts', this.id.toString());
    }
    this.refreshFormDataInternal();
  }

  refreshFormDataInternal() {
    if (this.isExternal) {
      this.model = { searchResults: [] };
      return;
    }

    this.waitingOverlay.StartWaiting();

    const existing = this.dataCachingService.getCachedData('EditAnimalProducts', this.id.toString());

    if (existing) {
      this.model = existing;
      this.stableId = existing.stableId;
      this.waitingOverlay.EndWaiting();
    } else {
      this.cacheService.getAnimal(this.id)
        .subscribe(animal => {
          this.stableId = animal.stableId;

          this.productDataService.getAnimalProducts(this.id, this.model.showHistory)
            .subscribe({
              next: data => {

                this.lookupSourceService.getLookupObj('product-subtype').subscribe(productSubtypeLookup => {
                  this.lookupSourceService.getLookupObj('product-status').subscribe(productStatusLookup => {
                    this.lookupSourceService.getLookupObj('transaction-type').subscribe(transactionTypeLookup => {
                      data.forEach(el => {
                        el.transactionTypeCaption = transactionTypeLookup[el.transactionType];
                        el.statusCaption = productStatusLookup[el.status];
                        if (el.status === -100 && el.templateCaption) {
                          el.statusCaption = 'Шаблон: ' + el.templateCaption;
                        }

                        if (el.stableProductSubtypeCaption) {
                          el.productSubtypeIdCaption = el.stableProductSubtypeCaption;
                        } else {
                          const productSubtypeRec = productSubtypeLookup[el.productTypeId].find(ptst => ptst.id === el.productSubtypeId);
                          el.productSubtypeIdCaption = productSubtypeRec ? productSubtypeRec.caption : undefined;
                        }
                      });
                      data.sort((a, b) => b.id - a.id);
                      this.model = {searchResults: data, showHistory: this.model.showHistory, stableId: this.stableId};
                      this.dataCachingService.addToCache('EditAnimalProducts', this.id.toString(), this.model);
                      this.waitingOverlay.EndWaiting();
                    });
                  });
                });
              },
              error: () => {
                this.waitingOverlay.EndWaiting();
              }
            });
        });
    }
  }

  addNew() {
    this.navigationService.performProductCreation(undefined, undefined,
      this.id, 4 /* Производство/переработка */);

  }

  onGridSelectionChanged(params: any) {
    this.model.selectedRows = params.api.getSelectedRows();
  }

  deleteDocument() {
    ProductSearchResultsComponent.deleteManyDocuments(this.model.selectedRows, this.alertService, this.productDataService,
      () => this.refreshFormData());
  }

  sendToRegistration() {
    ProductSearchResultsComponent.sendManyDocumentsToRegistration(this.model.selectedRows, this.alertService, this.productDataService,
      () => this.refreshFormData());
  }

  sendToClearance() {
    ProductSearchResultsComponent.sendManyDocumentsToClearance(this.model.selectedRows, this.alertService, this.productDataService,
      () => this.refreshFormData());
  }

  createTransportForSelectedDocuments() {
    ProductSearchResultsComponent.createTransportForSelectedDocuments(this.model.selectedRows, this.navigationService);
  }
}
