<div class="itech-content-header">
  <clr-icon shape="bank" size="36" class="has-badge"></clr-icon>&nbsp;Создание новой карточки учреждения / предприятия
</div>

<app-edit-institution-common-form [contextFormGroup]="model.form"></app-edit-institution-common-form>

<p>
  <button [clrLoading]="creating" [disabled]="creating" class="btn btn-primary"
          (click)="createCard()">
    <clr-icon shape="check"></clr-icon>&nbsp;Создать
  </button>
</p>
