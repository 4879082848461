import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { PstReportService } from '../../services/pst-report.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { AgentSearchModalComponent } from '../../../pages/edit-agent/search/agent-search-modal.component';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';
import { combineLatest, noop, Observable, of } from 'rxjs';
import { PstReportStructureService } from '../../services/pst-report-structure.service';
import { map } from 'rxjs/operators';
import { IndicatorDependencyModalComponent } from '../indicator-dependency-modal.component';
import { ReportIndicatorMetaEditModalComponent } from '../meta/report-indicator-meta-edit-modal.component';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { PstIndicatorTypeMetaEditModalComponent } from '../meta/pst-indicator-type-meta-edit-modal.component';
import { NumericHelper } from '../../../helpers/numeric-helper';
import { SecurityService } from '../../../logic/services/security.service';

@Component({
  selector: 'app-branch-tab-col-edit',
  templateUrl: './pst-branch-tab-col-edit.component.html',
})
export class PstBranchTabColEditComponent implements OnChanges {
  @Input() branch: any;
  @Input() model: any;

  @ViewChild('tableCtnr')
  tableCtnr: ElementRef;
  scrollLeft: any = 0;
  hSortTimeout: any;

  constructor(
    private lookupSourceService: LookupSourceService,
    public navigationService: AppNavigationService,
    private pstReportService: PstReportService,
    private cacheService: AddressPersonFioCacheService,
    private pstReportStructureService: PstReportStructureService,
    private appNavigationService: AppNavigationService,
    private alertService: AlertService,
    public securityService: SecurityService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('branch') || changes.hasOwnProperty('model')) {
      this.rebuildHeader();
      this.startUpdatingRowsStructure();
    }
  }

  private rebuildHeader() {
    this.branch.headerRows = [];

    this.branch.rootRowsGroup = [];
    this.branch.rootRowsGroupHash = {};

    if (!this.branch || !this.branch.indicators) {
      return;
    }

    let depth = 1;
    this.branch.indicators.forEach(indicator => {
      const path = (indicator.displayTitle || '').split('->');
      if (path.length > depth) {
        depth = path.length;
      }
    });

    const rowGroupCaptions = (this.branch.meta.params['row-titles'] || '').split('|');

    for (let lvl = 0; lvl < depth; lvl++) {
      const row = [];
      this.branch.indicators.forEach(indicator => {
        const path = (indicator.displayTitle || '').split('->');

        let ix_spacer = -1;
        for (let i = 1; i < path.length; i++) {
          if (!path[i]) {
            ix_spacer = i;
            path[i] = path[i - 1];
          }
        }

        if (ix_spacer >= 0) {
          while (path.length < depth) {
            path.splice(ix_spacer, 0, path[ix_spacer]);
          }
        }

        const displayTitlePath = path.slice(0, lvl < path.length ? lvl + 1 : path.length).join('->');

        // заголовок формируем только по 0-й строке
        if (!indicator.meta.rowNo) {
          row[indicator.meta.colNo - 1] = {
            meta: indicator.meta,
            displayTitle: lvl < path.length ? path[lvl] : path[path.length - 1],
            displayTitlePath,
            colSpan: 1,
            rowSpan: 1,
            visible: () => this.model.showHiddenCells || (!indicator.meta.hidden && !indicator.indicatorMeta.isProtected),
          };
        }

        if (!this.branch.rootRowsGroup[indicator.meta.rowNo]) {
          this.branch.rootRowsGroup[indicator.meta.rowNo] = {
            hash: {},
            children: [],
            caption: rowGroupCaptions[indicator.meta.rowNo],
            indicators: [],
            hasEditableCells: false,
            visible: () => !this.model.reportMeta.regionId,
          };
        }
        const rowGroup = this.branch.rootRowsGroup[indicator.meta.rowNo];
        rowGroup.indicators[indicator.meta.colNo - 1] = indicator;
        let indicatorLevel = this.branch.meta.indicatorLevel;
        if (!this.model.reportMeta.regionId && this.branch.meta.subjectIndicatorLevel) {
          indicatorLevel = this.branch.meta.subjectIndicatorLevel;
        }
        if (!rowGroup.hasEditableCells && indicator.indicatorMeta
          && indicator.indicatorMeta.id && !indicator.indicatorMeta.formula
          && this.model.reportMeta.regionId && indicatorLevel === (indicator.indicatorMeta.editableLevel || 60)) {
          rowGroup.hasEditableCells = true;
        }

        if (!this.branch.rootRowsGroupHash[indicator.meta.indicatorTypeId]) {
          this.branch.rootRowsGroupHash[indicator.meta.indicatorTypeId] = [];
        }

        this.branch.rootRowsGroupHash[indicator.meta.indicatorTypeId][indicator.meta.rowNo] = rowGroup;
      });

      this.branch.headerRows.push(row);
    }

    this.pstReportStructureService.mergeHeaderCells(this.branch);

    // level 10: субъектовый
    for (const rowGroup of this.branch.rootRowsGroup) {
      rowGroup.level = 10;
      rowGroup.indicatorTypeMeta = this.getLevelSlice(rowGroup, 10);
    }
  }

  getSuggestedTableWidth() {
    if (!this.branch.headerRows || !this.branch.headerRows.length) {
      return undefined;
    }

    const lastRow = this.branch.headerRows[this.branch.headerRows.length - 1];
    let width = 0;
    for (let iCol = 0; iCol < lastRow.length; iCol++) {
      if (lastRow[iCol].visible()) {
        width += (lastRow[iCol].meta.displayWidth || 60);
      }
    }

    return `${width + 200}px`;
  }

  searchAgent(rowGroup: any) {
    this.navigationService.searchAgent(AgentSearchModalComponent).subscribe(agentId => {
      if (agentId) {
        let indicatorLevel = this.branch.meta.indicatorLevel;
        if (!this.model.reportMeta.regionId && this.branch.meta.subjectIndicatorLevel) {
          indicatorLevel = this.branch.meta.subjectIndicatorLevel;
        }

        this.addAgentRow(agentId, rowGroup, indicatorLevel === 60 ? this.branch.defaultSubtypeId : null);
      }
    });
  }

  private addAgentRow(agentId: any, rowGroup: any, indicatorSubtypeId: any) {
    this.lookupSourceService.getLookupObj('addr-region-group').subscribe(regionGroupLookup => {
      this.lookupSourceService.getLookupObj('pst-indicator-subtype').subscribe(indicatorSubtypeLookup => {
        this.lookupSourceService.getLookupObj('addr-region').subscribe(regionLookup => {
          this.cacheService.getAgent(agentId).subscribe(agent => {
            const regionId = this.model.reportMeta.regionId;
            const regionGroupId = regionLookup[`Obj${regionId}`].regionGroupId;
            const kfhType = agent.legalFormId === 31 || agent.legalFormId === 13 || agent.legalFormId === 45 || agent.legalFormId === 0
              ? 2
              : 1;

            let indicatorLevel = this.branch.meta.indicatorLevel;
            if (!this.model.reportMeta.regionId && this.branch.meta.subjectIndicatorLevel) {
              indicatorLevel = this.branch.meta.subjectIndicatorLevel;
            }

            this.addRowWithGroups(rowGroup, regionGroupLookup, regionLookup, regionGroupId,
              regionId, kfhType, agentId, agent.shortCaption, indicatorSubtypeId,
              indicatorSubtypeLookup[indicatorSubtypeId], indicatorLevel);
          });
        });
      });
    });
  }

  private addRowWithGroups(rowGroup, regionGroupLookup, regionLookup, regionGroupId, regionId, kfhType, agentId,
                           agentCaption, subtypeId, subtypeCaption, level: any) {
    if (!regionGroupId) {
      return;
    }

    // level 10: regionGroupId
    if (!rowGroup.hash[regionGroupId]) {
      rowGroup.hash[regionGroupId] = {
        caption: regionGroupLookup[`Obj${regionGroupId}`].caption,
        hash: {},
        parent: rowGroup,
        children: [],
        level: 20,
        regionId: undefined,
        regionGroupId,
        kfhType: undefined,
        agentId: undefined,
        subtypeId: undefined,
        indicatorTypeMeta: this.getLevelSlice(rowGroup, 20),
        visible: () => !this.model.reportMeta.regionId,
      };
      rowGroup.children.push(rowGroup.hash[regionGroupId]);
    }
    const lvl10node = rowGroup.hash[regionGroupId];

    if (!regionId || level < 30) {
      return;
    }

    // level 20: regionId
    if (!lvl10node.hash[regionId]) {
      lvl10node.hash[regionId] = {
        caption: regionLookup[`Obj${regionId}`].caption,
        level: 30,
        hash: {},
        parent: lvl10node,
        children: [],
        regionId,
        regionGroupId,
        kfhType: undefined,
        agentId: undefined,
        subtypeId: undefined,
        indicatorTypeMeta: this.getLevelSlice(rowGroup, 30),
        visible: () => true,
      };
      lvl10node.children.push(lvl10node.hash[regionId]);
    }
    const lvl20node = lvl10node.hash[regionId];

    if (!kfhType || level < 40) {
      return;
    }

    // level 30: kfhType КФХ/предприятия
    if (!lvl20node.hash[kfhType]) {
      lvl20node.hash[kfhType] = {
        caption: kfhType === 2 ? 'Крестьянско-фермерские хозяйства' : 'Предприятия',
        hash: {},
        parent: lvl20node,
        children: [],
        level: 40,
        regionId,
        regionGroupId,
        kfhType,
        agentId: undefined,
        subtypeId: undefined,
        indicatorTypeMeta: this.getLevelSlice(rowGroup, 40),
        visible: () => true,
      };
      lvl20node.children.push(lvl20node.hash[kfhType]);
    }
    const lvl30node = lvl20node.hash[kfhType];

    if (!agentId || level < 50) {
      return;
    }

    // level 40: agentId
    if (!lvl30node.hash[agentId]) {
      const lvl40children = [];
      lvl30node.hash[agentId] = {
        agentCaption,
        caption: agentCaption,
        level: 50,
        regionId,
        regionGroupId,
        kfhType,
        agentId,
        subtypeId: undefined,
        hash: {},
        parent: lvl30node,
        children: lvl40children,
        indicatorTypeMeta: this.getLevelSlice(rowGroup, 50),
        visible: () => lvl40children.length !== 1,
      };
      lvl30node.children.push(lvl30node.hash[agentId]);
    }
    const lvl40node = lvl30node.hash[agentId];

    if (!subtypeId || level < 60) {
      return;
    }

    // level 50: subtypeId
    if (!lvl40node.hash[subtypeId]) {
      lvl40node.hash[subtypeId] = {
        level: 60,
        regionId,
        regionGroupId,
        kfhType,
        agentId,
        subtypeId,
        parent: lvl40node,
        children: [],
        indicatorTypeMeta: this.getLevelSlice(rowGroup, 60),
        displayCaption: () => `${agentCaption}${this.branch.hasSubtypes ? ', ' + subtypeCaption : ''}`,
        visible: () => !this.branch.meta.params['filterKfhType'] || this.branch.meta.params['filterKfhType'] == kfhType,
      };
      lvl40node.children.push(lvl40node.hash[subtypeId]);
    }
  }

  private getLevelSlice(rowGroup: any, lvl: number): any[] {
    return rowGroup.indicators.map(indicator => {
      if (indicator) {
        return indicator.indicatorMeta;
      }

      return undefined;
    });
  }

  private startUpdatingRowsStructure() {
    if (!this.model || !this.branch) {
      return;
    }


    combineLatest([
      this.securityService.getUserInfo(),
      this.lookupSourceService.getLookupObj('addr-region-group'),
      this.lookupSourceService.getLookup('pst-indicator-subtype'),
      this.lookupSourceService.getLookupObj('pst-indicator-subtype'),
      this.lookupSourceService.getLookupObj('addr-region'),
    ]).subscribe(([userInfo, regionGroupLookup, indicatorSubtypes, indicatorSubtypeLookup, regionLookup]) => {
      this.updateDefaultBranchSubtype(indicatorSubtypes);

      let hasUserAgent = false;

      for (const indicatorKey in this.model.values) {
        if (!this.model.values.hasOwnProperty(indicatorKey)) {
          continue;
        }
        const value = this.model.values[indicatorKey];

        const indicatorMeta = this.model.indicatorTypesMap[value.indicatorTypeId];

        if (!this.model.showHiddenCells && indicatorMeta && indicatorMeta.isProtected) {
          continue;
        }

        // поддержку срезов по subtypeId и kfhType мы не пока добавляли в таблице по графам, поэтому игнорим подобные показатели
        if ((value.level <= 50 && value.subtypeId) || (value.level <= 30 && value.kfhType)) {
          continue;
        }

        const rowGroups = this.branch.rootRowsGroupHash[value.indicatorTypeId] || [];

        if (rowGroups && rowGroups.length && value.agentId === userInfo.agentId) {
          hasUserAgent = true;
        }

        (value.agentId ? this.cacheService.getAgent(value.agentId) : of(undefined)).subscribe(agent => {
          rowGroups.forEach(rowGroup => {
            this.addRowWithGroups(
              rowGroup,
              regionGroupLookup,
              regionLookup,
              value.regionGroupId,
              value.regionId,
              value.kfhType,
              agent ? agent.id : undefined,
              agent ? agent.shortCaption : undefined,
              value.subtypeId,
              value.subtypeId ? indicatorSubtypeLookup[value.subtypeId] : undefined,
              value.level);
          });

          this.sortRowGroupsTimeout(this.branch.rootRowsGroup);
        });
      }

      if (userInfo.restrictPstAgentId && !hasUserAgent) {
        this.branch.rootRowsGroup.forEach(rowGroup => {
          this.addAgentRow(userInfo.agentId, rowGroup, this.branch.defaultSubtypeId);
        });
      }
    });

    this.securityService.getUserInfo().subscribe(userInfo => {
      this.lookupSourceService.getLookupObj('addr-region-group').subscribe(regionGroupLookup => {
        this.lookupSourceService.getLookup('pst-indicator-subtype').subscribe(indicatorSubtypes => {
          this.lookupSourceService.getLookupObj('pst-indicator-subtype').subscribe(indicatorSubtypeLookup => {
            this.lookupSourceService.getLookupObj('addr-region').subscribe(regionLookup => {
            });
          });
        });
      });
    });
  }

  cellClick(indicatorLevel: any, colIndex: any, rowGroup: any) {
    if (this.getFocused(indicatorLevel, colIndex, rowGroup)) {
      this.setEditingCell(indicatorLevel, colIndex, indicatorLevel.indicatorTypeMeta[colIndex], rowGroup, '');
    } else {
      this.setFocusedCell(indicatorLevel, colIndex, rowGroup);
    }
    //
    // console.log('reportIndicator:', reportIndicator);
    // console.log('indicatorTypeMeta:', indicatorLevel.indicatorTypeMeta[colIndex]);
    // console.log('indicatorLevel:', indicatorLevel);
  }

  private setFocusedCell(indicatorLevel: any, colIndex: any, rowGroup: any) {
    this.model.__cl_focusedBranch = this.branch;
    this.model.__cl_focusedRowGroup = rowGroup;
    this.model.__cl_focusedIndicatorLevel = indicatorLevel;
    this.model.__cl_focusedIndicatorColIndex = colIndex;
    this.model.__cl_editingBranch = undefined;
    this.model.__cl_storing_error = undefined;
  }

  private setEditingCell(indicatorLevel: any, colIndex: any, indicatorTypeMeta: any, rowGroup: any, proposedValue: any) {
    if (!indicatorTypeMeta || indicatorTypeMeta.formula || indicatorLevel.level !== (indicatorTypeMeta.editableLevel || 60)) {
      return;
    }
    this.setFocusedCell(indicatorLevel, colIndex, rowGroup);
    const reportIndicator = rowGroup.indicators[colIndex];
    this.setProposedCellValueStr(indicatorLevel, reportIndicator, indicatorTypeMeta, proposedValue);
    this.model.__cl_editingBranch = this.branch;
    this.model.__cl_editingRowGroup = rowGroup;
    this.model.__cl_editingIndicatorLevel = indicatorLevel;
    this.model.__cl_editingIndicatorColIndex = colIndex;
  }

  tableKeyDown($event) {
    if (this.model.__cl_focusedBranch === this.branch) {
      const rowGroup = this.model.__cl_focusedRowGroup;
      const indicatorLevel = this.model.__cl_focusedIndicatorLevel;
      const colIndex = this.model.__cl_focusedIndicatorColIndex;
      const indicatorTypeMeta = indicatorLevel.indicatorTypeMeta[colIndex];
      const reportIndicator = rowGroup.indicators[colIndex];

      if (!this.getEditing(indicatorLevel, colIndex, rowGroup)) {
        if ($event.key >= '0' && $event.key <= '9') {
          this.setEditingCell(indicatorLevel, colIndex, indicatorTypeMeta, rowGroup, $event.key);
        }
        if ($event.key === 'F2') {
          this.setEditingCell(indicatorLevel, colIndex, indicatorTypeMeta, rowGroup, '');
        }
        if ($event.key === 'ArrowLeft') {
          this.setFocusedCell(indicatorLevel, colIndex - 1 >= 0 ? colIndex - 1 : 0, rowGroup);
        }
        if ($event.key === 'ArrowRight') {
          this.setFocusedCell(indicatorLevel, colIndex + 1 < rowGroup.indicators.length
            ? colIndex + 1
            : rowGroup.indicators.length - 1, rowGroup);
        }
        if ($event.key === 'ArrowDown') {
          $event.preventDefault();
          this.setFocusedCell(this.findNextLevel(indicatorLevel, rowGroup), colIndex, rowGroup);
        }
        if ($event.key === 'ArrowUp') {
          $event.preventDefault();
          this.setFocusedCell(this.findPreviousLevel(indicatorLevel, rowGroup), colIndex, rowGroup);
        }
        if ($event.key === 'F3') {
          const code = this.pstReportStructureService.getIndicatorValueCode(
            this.branch, indicatorLevel, reportIndicator, indicatorTypeMeta);
          const rec = this.model.values[code];
          if (rec) {
            $event.preventDefault();
            this.appNavigationService.showIndicatorDependencies(IndicatorDependencyModalComponent, rec, this.model.reportMeta.id)
              .subscribe(noop);
          }
        }

        if ($event.key === 'Delete' && !indicatorTypeMeta.formula) {
          this.pstReportStructureService.storeIndicatorValue(
            this.model, reportIndicator, indicatorTypeMeta, indicatorLevel, undefined);
        }
      }
    }
  }

  getFocused(indicatorLevel: any, colIndex: any, rowGroup: any) {
    return this.model.__cl_focusedBranch === this.branch &&
      this.model.__cl_focusedRowGroup === rowGroup &&
      this.model.__cl_focusedIndicatorLevel === indicatorLevel &&
      this.model.__cl_focusedIndicatorColIndex === colIndex;
  }

  getEditing(indicatorLevel: any, colIndex: any, rowGroup: any) {
    return this.model.__cl_editingBranch === this.branch &&
      this.model.__cl_editingRowGroup === rowGroup &&
      this.model.__cl_editingIndicatorLevel === indicatorLevel &&
      this.model.__cl_editingIndicatorColIndex === colIndex;
  }

  getReadOnly(indicatorLevel: any, colIndex: any, rowGroup: any) {
    let branchIndicatorLevel = this.branch.meta.indicatorLevel;
    if (!this.model.reportMeta.regionId && this.branch.meta.subjectIndicatorLevel) {
      branchIndicatorLevel = this.branch.meta.subjectIndicatorLevel;
    }

    return indicatorLevel.indicatorTypeMeta[colIndex] && (indicatorLevel.indicatorTypeMeta[colIndex].formula
      || (indicatorLevel.indicatorTypeMeta[colIndex].editableLevel || 60) !== branchIndicatorLevel);
  }

  setProposedCellValueStr(indicatorLevel: any, reportIndicator: any, indicatorTypeMeta: any, strValue: any) {
    if (!reportIndicator || !indicatorTypeMeta || !indicatorLevel) {
      return;
    }

    const code = this.pstReportStructureService.getIndicatorValueCode(
      this.branch,
      indicatorLevel,
      reportIndicator,
      indicatorTypeMeta,
    );

    if (!this.model.values[code]) {
      this.model.values[code] = {
        date: reportIndicator.indicatorDate,
        indicatorTypeId: indicatorTypeMeta.id,
        regionGroupId: indicatorLevel.regionGroupId,
        regionId: indicatorLevel.regionId,
        kfhType: indicatorLevel.kfhType,
        agentId: indicatorLevel.agentId,
      };
    }

    if (strValue) {
      const parsedVal = NumericHelper.roundDecimal(parseFloat(strValue), indicatorTypeMeta.precision);
      this.model.values[code].proposedValue = parsedVal;
    } else {
      this.model.values[code].proposedValue = undefined;
    }
  }

  private updateDefaultBranchSubtype(indicatorSubtypes: any[]) {
    const lvlGroupCaption = this.branch.indicators[0].indicatorMeta.lvlGroupCaption;
    this.branch.hasSubtypes = lvlGroupCaption !== 'Иное';
    indicatorSubtypes.forEach(subtype => {
      if (subtype.groupCaption === lvlGroupCaption && subtype.isDefault) {
        this.branch.defaultSubtypeId = subtype.id;
      }
    });
  }

  getAvailableSubtypes$(): Observable<any[]> {
    return this.lookupSourceService.getLookup('pst-indicator-subtype').pipe(map(
      indicatorSubtypes => {
        const lvlGroupCaption = this.branch.indicators[0].indicatorMeta.lvlGroupCaption;
        return indicatorSubtypes.filter(subtype => subtype.groupCaption === lvlGroupCaption);
      }));
  }

  onTableCtnrScroll($event: any) {
    this.scrollLeft = this.tableCtnr.nativeElement.scrollLeft;
  }

  editReportIndicator(reportIndicator: any) {
    this.appNavigationService.showReportIndicatorEdit(ReportIndicatorMetaEditModalComponent, reportIndicator.meta)
      .subscribe(updated => {
        Object.assign(reportIndicator.meta, updated);
        this.alertService.success('Описание графы успешно сохранено. ' +
          'Обновите отчет, чтобы посмотреть измененную форму.');
      });
  }

  editIndicatorType(reportIndicator: any) {
    this.appNavigationService.showIndicatorTypeEdit(PstIndicatorTypeMetaEditModalComponent, reportIndicator.indicatorMeta)
      .subscribe(updated => {
        Object.assign(reportIndicator.indicatorMeta, updated);
        this.alertService.success('Вид показателя успешно сохранен. ' +
          'Обновите отчет, чтобы посмотреть измененную форму.');
      });
  }

  addReportIndicator() {
    this.appNavigationService.showReportIndicatorEdit(ReportIndicatorMetaEditModalComponent,
      {
        createNew: true,
        reportTypeId: this.branch.meta.reportTypeId,
        branchOrderNo: this.branch.meta.orderNo,
        colNo: 1,
        rowNo: 0,
      })
      .subscribe(() => {
        this.alertService.success('Описание графы успешно сохранено. ' +
          'Обновите отчет, чтобы посмотреть измененную форму.');
      });
  }

  private sortRowGroupsTimeout(parents: any[]) {
    if (this.hSortTimeout) {
      clearTimeout(this.hSortTimeout);
      this.hSortTimeout = undefined;
    }
    this.hSortTimeout = setTimeout(() => this.sortRowGroups(parents), 100);
  }

  private sortRowGroups(parents: any[]) {
    if (!parents || !parents.length) {
      return;
    }

    parents.sort((a, b) => (a.caption || '').localeCompare(b.caption || ''));
    parents.forEach(parent => this.sortRowGroups(parent.children));
  }

  private findNextLevel(indicatorLevel: any, rowGroup: any) {
    const ixLevel = indicatorLevel.parent && indicatorLevel.parent.children
      ? indicatorLevel.parent.children.findIndex(el => el === indicatorLevel)
      : -1;

    if (ixLevel < 0) {
      return indicatorLevel;
    }

    let nextLevel;
    if (ixLevel + 1 < indicatorLevel.parent.children.length) {
      nextLevel = indicatorLevel.parent.children[ixLevel + 1];
      while (nextLevel != null && nextLevel.children && nextLevel.children.length) {
        nextLevel = nextLevel.children[0];
      }
    } else {
      nextLevel = indicatorLevel.parent ? indicatorLevel.parent : indicatorLevel;
    }

    if (nextLevel === indicatorLevel) {
      return indicatorLevel;
    } else if (!nextLevel.visible()) {
      nextLevel = this.findNextLevel(nextLevel, rowGroup);
      return nextLevel.visible() ? nextLevel : indicatorLevel;
    } else {
      return nextLevel;
    }
  }

  private findPreviousLevel(indicatorLevel: any, rowGroup: any) {
    let prevLevel;
    if (indicatorLevel.children && indicatorLevel.children.length) {
      prevLevel = indicatorLevel.children[indicatorLevel.children.length - 1];
    } else {
      const ixLevel = indicatorLevel.parent && indicatorLevel.parent.children
        ? indicatorLevel.parent.children.findIndex(el => el === indicatorLevel)
        : -1;

      if (ixLevel > 0) {
        prevLevel = indicatorLevel.parent.children[ixLevel - 1];
      } else {
        prevLevel = indicatorLevel.parent;
        while (prevLevel) {
          const ixPrevLevel = prevLevel.parent && prevLevel.parent.children
            ? prevLevel.parent.children.findIndex(el => el === prevLevel)
            : -1;
          if (ixPrevLevel > 0) {
            prevLevel = prevLevel.parent.children[ixPrevLevel - 1];
            break;
          } else {
            prevLevel = prevLevel.parent;
          }
        }
        prevLevel = prevLevel ? prevLevel : indicatorLevel;
      }
    }

    if (prevLevel === indicatorLevel) {
      return indicatorLevel;
    } else if (!prevLevel.visible()) {
      prevLevel = this.findPreviousLevel(prevLevel, rowGroup);
      return prevLevel.visible() ? prevLevel : indicatorLevel;
    } else {
      return prevLevel;
    }
  }

}
