<div class="itech-common-wrap" id="interceptNoAccess">
  <div style="width: 100%">
    <app-grid [pagination]="false" [getRowStyle] = "getRowStyle" [columnDefs]="gridColumnDefs"
              [rowData]="model.searchResults" [uniqueGridName]="'EditAgentStablesGrid'"
              [rowMultiSelectWithClick]="true" [rowSelection]="'multiple'"
              (selectionChanged)="onGridSelectionChanged($event)"
              [exportFileName]="'Список поднадзорных объектов у ХС ' + (id|lookup:'agent'|async)"
              (rowClicked)="navigationService.performStableEditing($event.data.id)"></app-grid>
  </div>

  <div class="itech-form-actions">
    <div>
      <button class="btn btn-secondary" (click)="refreshFormData()">
        <clr-icon shape="refresh"></clr-icon>&nbsp;Обновить
      </button>
      <!--<button class="btn btn-success-outline" (click)="addExisting()">-->
        <!--<clr-icon shape="check"></clr-icon>&nbsp;Добавить существ.-->
      <!--</button>-->
      <clr-dropdown>
        <button class="btn btn-secondary" clrDropdownToggle>
          Действия
          <clr-icon shape="caret down"></clr-icon>
        </button>
        <clr-dropdown-menu *clrIfOpen [clrPosition]="'top-left'">
          <button type="button" clrDropdownItem
                  (click)="updateFromMercuryAndRefreshFormData()">
            <clr-icon shape="refresh"></clr-icon>&nbsp;Обновить список из Меркурия
          </button>
          <button type="button" clrDropdownItem (click)="changeVisibility()">
            <clr-icon shape="eye-hide"></clr-icon>&nbsp;Изменить настройки видимости
          </button>
        </clr-dropdown-menu>
      </clr-dropdown>
      <button class="btn btn-success-outline" (click)="addNew()">
        <clr-icon shape="plus"></clr-icon>&nbsp;Добавить новый
      </button>
    </div>

    <div>
      <div class="checkbox">
        <input type="checkbox" id="showHistoryCb" [(ngModel)]="model.showHistory" (change)="refreshFormData()">
        <label for="showHistoryCb">Показывать историю</label>
      </div>
    </div>
  </div>
</div>
