import * as tslib_1 from "tslib";
import { MetaBrowseBaseComponent } from '../meta-browse.base.component';
import { ActivatedRoute } from '@angular/router';
import { MetadataService } from '../../../logic/services/metadata.service';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { FormBuilder, Validators } from '@angular/forms';
import { DataCachingService } from '../../../logic/services/data-caching.service';
var InstitutionTicketQuestBrowseComponent = /** @class */ (function (_super) {
    tslib_1.__extends(InstitutionTicketQuestBrowseComponent, _super);
    function InstitutionTicketQuestBrowseComponent(route, metadataService, alertService, globalWaitingOverlayService, lookupService, bookmarkService, fb, dataCachingService) {
        var _this = _super.call(this, route, metadataService, alertService, globalWaitingOverlayService, lookupService, fb, bookmarkService, dataCachingService) || this;
        _this.gridColumnDefs = [
            { headerName: '№', field: 'id', width: 100 },
            { headerName: 'Наименование', field: 'caption', tooltipField: 'caption', width: 500 },
        ];
        return _this;
    }
    InstitutionTicketQuestBrowseComponent.prototype.getMetaTitle = function () {
        return 'Вопросы талонов приёма';
    };
    InstitutionTicketQuestBrowseComponent.prototype.getMetaName = function () {
        return 'institution-ticket-quest';
    };
    InstitutionTicketQuestBrowseComponent.prototype.getGroupDef = function (row) {
        return {
            id: [row.id, Validators.compose([Validators.required, Validators.pattern(/^\d{1,3}$/)])],
            caption: [row.caption, Validators.required],
        };
    };
    return InstitutionTicketQuestBrowseComponent;
}(MetaBrowseBaseComponent));
export { InstitutionTicketQuestBrowseComponent };
