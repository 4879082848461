import { EventEmitter } from '@angular/core';
import { isUndefined } from 'util';
import { StringHelper } from '../../helpers/string-helper';
var AppIndDepTreeNodesComponent = /** @class */ (function () {
    function AppIndDepTreeNodesComponent() {
        this.loading = false;
        this._parent = { children: [], expanded: false };
        this.formatRuNumeric = StringHelper.formatRuNumeric;
        this.onNavigateReport = new EventEmitter();
    }
    Object.defineProperty(AppIndDepTreeNodesComponent.prototype, "parent", {
        get: function () {
            return this._parent;
        },
        set: function (node) {
            var _this = this;
            if (this._parent !== node) {
                this._parent = node;
                setTimeout(function () {
                    if (_this._parent) {
                        _this._parent.expanded = isUndefined(_this._parent.expanded) ? false : _this._parent.expanded;
                    }
                }, 0);
            }
        },
        enumerable: true,
        configurable: true
    });
    AppIndDepTreeNodesComponent.prototype.getSelectedLeafes = function () {
        var selected = [];
        this.fillSelectedLeafs(this.parent, selected);
        return selected;
    };
    AppIndDepTreeNodesComponent.prototype.fillSelectedLeafs = function (node, selected, hasSelectedParent) {
        var _this = this;
        if (hasSelectedParent === void 0) { hasSelectedParent = false; }
        var nodeIsLeaf = !node.children || node.children.length <= 0;
        if (nodeIsLeaf) {
            if (node.selected || hasSelectedParent) {
                selected.push(node);
            }
        }
        else {
            node.children.forEach(function (item) { return _this.fillSelectedLeafs(item, selected, node.selected || hasSelectedParent); });
        }
    };
    // fix: Если в модели узла дерева проинициализировано своейство selected, то дерево некорректно работает
    // при разворачивании: оно начинает менять состояние выбранности узлов верхнего уровня при подгрузке
    // дочерних элементов со свойством selected
    AppIndDepTreeNodesComponent.prototype.changeSelectedChildItems = function (parent, selected) {
        if (!isUndefined(parent.expanded) && !parent.expanded) {
            this.setSelectedChildItems(parent, selected);
        }
    };
    AppIndDepTreeNodesComponent.prototype.setSelectedChildItems = function (parent, selected) {
        var _this = this;
        if (parent.children) {
            parent.children.forEach(function (child) {
                if (!isUndefined(child.selected) && child.selected !== selected) {
                    child.selected = selected;
                }
                _this.setSelectedChildItems(child, selected);
            });
        }
    };
    return AppIndDepTreeNodesComponent;
}());
export { AppIndDepTreeNodesComponent };
