import * as tslib_1 from "tslib";
import { QueryService } from '../../logic/services/query.service';
import { DataCachingService } from '../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../logic/services/lookup-source.service';
import { FormBuilder } from '@angular/forms';
import { AlertService } from '../../ui/infrastructure/alert.service';
import { FormHelper } from '../../ui/controls/form-helper';
import { QueryDetailsEntityComponent } from './query-details-entity.component';
var QueryDetailsEventComponent = /** @class */ (function (_super) {
    tslib_1.__extends(QueryDetailsEventComponent, _super);
    function QueryDetailsEventComponent(queryService, dataCachingService, waitingOverlay, lookupSourceService, alertService, fb) {
        var _this = _super.call(this, queryService, dataCachingService, waitingOverlay, lookupSourceService, alertService, fb) || this;
        _this.EntityType = 3; // event
        return _this;
    }
    QueryDetailsEventComponent.getGroupDef = function (condition, fb) {
        return {
            type: 'event',
            _par_date_planning_filter: condition.data._par_date_planning_filter || 0,
            _par_date_planning_from: [condition.data._par_date_planning_from, FormHelper.validateDateTimePicker()],
            _par_date_planning_to: [condition.data._par_date_planning_to, FormHelper.validateDateTimePicker()],
            _par_date_filter: condition.data._par_date_filter || 0,
            _par_date_from: [condition.data._par_date_from, FormHelper.validateDateTimePicker()],
            _par_date_to: [condition.data._par_date_to, FormHelper.validateDateTimePicker()],
            _par_event_types_filter: condition.data._par_event_types_filter,
            _par_event_types: fb.array(condition.data._par_event_types || []),
            _par_diagnostic_types_filter: condition.data._par_diagnostic_types_filter,
            _par_diagnostic_types: fb.array(condition.data._par_diagnostic_types || []),
            _par_disease_type_filter: condition.data._par_disease_type_filter,
            _par_disease_type: condition.data._par_disease_type,
            _par_created_institution_filter: condition.data._par_created_institution_filter || 0,
            _par_created_institution: condition.data._par_created_institution,
            _par_created_institution_employee_filter: condition.data._par_created_institution_employee_filter || 0,
            _par_created_institution_employee: condition.data._par_created_institution_employee,
            _par_created_time_filter: condition.data._par_created_time_filter || 0,
            _par_created_time_from: [condition.data._par_created_time_from, FormHelper.validateDateTimePicker()],
            _par_created_time_to: [condition.data._par_created_time_to, FormHelper.validateDateTimePicker()],
            _par_exam_result_filter: condition.data._par_exam_result_filter || 0,
            _par_exam_result: condition.data._par_exam_result,
        };
    };
    QueryDetailsEventComponent.prototype.getGroupDef = function () {
        return QueryDetailsEventComponent.getGroupDef(this.model.condition, this.fb);
    };
    return QueryDetailsEventComponent;
}(QueryDetailsEntityComponent));
export { QueryDetailsEventComponent };
