import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { MetadataService } from '../../../logic/services/metadata.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MetaBrowseBaseComponent } from '../meta-browse.base.component';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';

@Component({
  templateUrl: './addr-city-type-browse.component.html'
})
export class AddrCityTypeBrowseComponent extends MetaBrowseBaseComponent {

  gridColumnDefs = [
    {headerName: '№', field: 'id', width: 100},
    {headerName: 'Наименование', field: 'caption', width: 500},
    {headerName: 'Краткое наименование', field: 'shortCaption', width: 200},
  ];

  constructor(route: ActivatedRoute,
              metadataService: MetadataService,
              alertService: AlertService,
              globalWaitingOverlayService: GlobalWaitingOverlayService,
              lookupService: LookupSourceService,
              fb: FormBuilder,
              bookmarkService: BookmarkService,
              dataCachingService: DataCachingService) {
    super(route, metadataService, alertService, globalWaitingOverlayService, lookupService, fb, bookmarkService, dataCachingService);
  }

  getMetaTitle(): string {
    return 'Типы населенных пунктов';
  }

  getMetaName(): string {
    return 'addr-city-type';
  }

  getGroupDef(row: any) {
    return {
      id: [row.id, Validators.compose([ Validators.required, Validators.pattern(/^\d{1,2}$/)])],
      caption: [row.caption, Validators.required],
      shortCaption: [row.shortCaption, Validators.required],
    };
  }
}
