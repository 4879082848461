<div class="itech-common-wrap no-pager">
  <div>
    <div class="itech-content-header itech-no-margin-bottom">
      <clr-icon shape="event" size="36"></clr-icon>&nbsp;Шаблоны карантинных мероприятий
    </div>

    <div class="itech-security-roles-navigation">

      <div class="itech-block-inline" id="selectedPersonGroup">
        <div class="select">
          <select [(ngModel)]="selectedTemplate" (change)="selectedTemplateChange()">
            <option *ngFor="let item of templates" [ngValue]="item">
              {{item.caption}}
            </option>
          </select>
        </div>

        <div class="itech-control-desc">
          Выберите шаблон для редактирования, или создайте новый шаблон, нажав на "+"
        </div>
      </div>

      <button type="button" class="btn btn-danger btn-link itech-inline-tool-btn" title="Добавить новый шаблон"
              (click)="addTemplate()" [disabled]="!selectedTemplate || selectedTemplate.id? undefined: true">
        <clr-icon shape="plus"></clr-icon>
      </button>
    </div>

    <h1 *ngIf="!contextFormGroup">
      <clr-icon shape="info-standard" size="36"></clr-icon>
      Выберите шаблон для редактирования или добавьте новый
    </h1>

    <div *ngIf="contextFormGroup" class="form compact" [formGroup]="contextFormGroup">

      <section class="form-block itech-block-large itech-person-groups-form">
        <div class="form-group">
          <label for="caption" class="required">Наименование</label>
          <label for="caption" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                 [class.invalid]="isInvalid(contextFormGroup, 'caption')">
            <input type="text" id="caption" class="itech-control-m-large" required
                   formControlName="caption">
            <app-validation-tooltip [input]="contextFormGroup.controls['caption']"></app-validation-tooltip>
          </label>
        </div>

        <div class="form-group">
          <label for="caption" class="required">Болезни</label>
          <app-combo-inline-multilookup [lookupName]="'disease-type'" [contextControlName]="'appliedDiseases'"
                                        [contextFormGroup]="contextFormGroup" [sorted]="true">
          </app-combo-inline-multilookup>
        </div>


      </section>

      <div style="position: absolute; left: 0; right: 0; top: 200px; bottom: 0; overflow-y: scroll">
        <div class="form compact itech-quarantine-form" [formGroup]="contextFormGroup" style="padding-right:20px">
          <section class="form-block itech-block-normal itech-request-area" formArrayName="events">

            <table class="itech-qua-events-tbl">
              <thead>
              <tr>
                <td>
                  №<br>п/п
                </td>
                <td>
                  Наименование мероприятия
                </td>
                <td>
                  Срок выполнения
                </td>
                <td>
                  Ответственные
                </td>
              </tr>
              </thead>
              <tbody>
              <ng-container *ngFor="let branchId of getUsedBranchIds(); index as kk">

                <tr>
                  <td colspan="4" style="font-weight: bold">
                    {{branchId|lookup:'quarantine-event-branch'|async}}
                  </td>
                </tr>

                <ng-container
                  *ngFor="let item of getEventsByBranch(branchId, getUsedBranchIds().length - 1 === kk); index as i"
                  [formGroup]="item">

                  <tr *ngIf="!item.get('__editing').value" (mouseenter)="item.get('__hovered').setValue(true)"
                      (mouseleave)="item.get('__hovered').setValue(false)">
                    <td>
                      {{kk+1}}.{{i+1}}
                    </td>
                    <td style="position: relative">
                      {{item.get('eventTypeId').value | lookup: 'quarantine-event-type' | async}}


                      <div *ngIf="item.get('__hovered').value"
                           style="position: absolute; right: 10px; bottom: 3px; background: #ddd; border-radius: 3px">
                        &nbsp;<a class="itech-link in-table-link" (click)="deleteEvent(item)" style="color: darkred">
                        <clr-icon shape="trash"></clr-icon>&nbsp;удалить</a>
                        &nbsp;<a class="itech-link in-table-link" (click)="moveUp(item)">
                        <clr-icon shape="caret up"></clr-icon>&nbsp;вверх</a>
                        &nbsp;<a class="itech-link in-table-link" (click)="moveDown(item)">
                        <clr-icon shape="caret down"></clr-icon>&nbsp;вниз</a>
                        &nbsp;<a class="itech-link in-table-link" (click)="goEditing(item)">
                        <clr-icon shape="pencil"></clr-icon>&nbsp;изменить</a>
                      </div>

                    </td>
                    <td>
                      {{item.get('periodTypeId').value == 0? item.get('periodTypeOther').value
                      : (item.get('periodTypeId').value | lookup: 'quarantine-event-period' | async)}}
                    </td>
                    <td>
                      {{getResponsiblesTitle(item)|async}}
                    </td>
                  </tr>

                  <tr *ngIf="item.get('__editing').value">
                    <td>
                      {{kk+1}}.{{i+1}}
                    <td>
                      <app-combo-lookup [validationTooltipRight]="true" class="itech-control-xxlarge" [sorted]="true"
                                        [contextControlName]="'eventTypeId'" [contextControlId]="'eventTypeId' + i"
                                        [lookupName]="'quarantine-event-type'"
                                        [contextFormGroup]="item"></app-combo-lookup>

                      <div>
                        <button class="btn btn-link itech-form-save" (click)="goEndEditing(item)">
                          <clr-icon shape="check"></clr-icon>&nbsp;Подтвердить
                        </button>
                        <button class="btn btn-link itech-form-cancel" [disabled]="!contextFormGroup.dirty"
                                (click)="refreshFormData()">
                          <clr-icon shape="undo"></clr-icon>&nbsp;Отменить
                        </button>
                      </div>
                    </td>
                    <td>
                      <app-combo-lookup [validationTooltipBottomLeft]="true" class="itech-control-large" [sorted]="true"
                                        [contextControlName]="'periodTypeId'" [contextControlId]="'periodTypeId' + i"
                                        [lookupName]="'quarantine-event-period'"
                                        [contextFormGroup]="item"></app-combo-lookup>
                      <br>
                      <label *ngIf="item.get('periodTypeId').value == 0" for="periodTypeOther{{i}}" aria-haspopup="true"
                             role="tooltip"
                             class="tooltip tooltip-validation tooltip-md"
                             [class.invalid]="isInvalid(item, 'periodTypeOther')">
                        <input type="text" id="periodTypeOther{{i}}" placeholder="Иной срок" class="itech-control-large"
                               formControlName="periodTypeOther">
                        <app-validation-tooltip [input]="item.controls['periodTypeOther']"></app-validation-tooltip>
                      </label>
                    </td>
                    <td>
                      <app-combo-inline-multilookup [lookupName]="'quarantine-event-responsible'"
                                                    [contextControlName]="'responsibleTypeId'" [noControlsDivider]="true"
                                                    [contextFormGroup]="item" [sorted]="true">
                      </app-combo-inline-multilookup>
                    </td>
                  </tr>
                </ng-container>
              </ng-container>

              <tr>
                <td>
                </td>
                <td>
                  <button class="btn btn-link" (click)="addEvent()">
                    <clr-icon shape="plus"></clr-icon>&nbsp;Добавить
                  </button>
                </td>
                <td>
                </td>
                <td>
                </td>
              </tr>

              </tbody>
            </table>
          </section>
        </div>
      </div>
    </div>

    <app-doc-validation-errors *ngIf="contextFormGroup"
                               [validationErrors]="contextFormGroup.docLevelServerSideValidationErrors">
    </app-doc-validation-errors>

  </div>

  <div>
    <div *ngIf="contextFormGroup" class="itech-form-actions">
      <div>
        <button *ngIf="contextFormGroup.get('id').value" type="button" class="btn btn-warning-outline"
                (click)="deleteCurrentTemplate()">
          <clr-icon shape="trash"></clr-icon>&nbsp;Удалить шаблон
        </button>
      </div>

      <div>
        <button type="button" class="btn btn-primary" (click)="store()" [clrLoading]="storing"
                [disabled]="storing || !contextFormGroup || !contextFormGroup.dirty">
          <clr-icon shape="check"></clr-icon>&nbsp;Сохранить
        </button>
        <button type="button" class="btn btn-warning" (click)="refreshFormData()"
                [disabled]="storing || !contextFormGroup || !contextFormGroup.dirty">
          <clr-icon shape="undo"></clr-icon>&nbsp;Отмена
        </button>
      </div>
    </div>
  </div>
</div>
