import { OnDestroy, OnInit } from '@angular/core';
import { timer } from 'rxjs/index';
import { MetadataService } from '../../logic/services/metadata.service';
import { first } from 'rxjs/internal/operators';
var TelemetryComponent = /** @class */ (function () {
    function TelemetryComponent(metadataService) {
        this.metadataService = metadataService;
        // lineChart
        this.lineChartData = [
            { data: [0], label: 'Запросы, мс' },
            { data: [0], label: 'Запросы, ед', yAxisID: 'y-axis-1' },
        ];
        this.lineChartLabels = [''];
        this.lineChartOptions = {
            animation: {
                duration: 0
            },
            scales: {
                xAxes: [{}],
                yAxes: [
                    {
                        id: 'y-axis-0',
                        position: 'left',
                    },
                    {
                        id: 'y-axis-1',
                        position: 'right',
                        gridLines: {
                            color: 'rgba(0,0,0,0)',
                        },
                    }
                ]
            }
        };
        this.lineChartColors = [
            {
                backgroundColor: 'rgba(148,255,148,0.2)',
                borderColor: 'rgba(148,255,148,1)',
                pointBackgroundColor: 'rgba(148,255,148,1)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(148,255,148,0.8)'
            },
            {
                backgroundColor: 'rgba(0,0,0,0)',
                borderColor: 'rgba(148,148,255,1)',
                pointBackgroundColor: 'rgba(148,148,255,1)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(148,148,255,0.8)'
            },
        ];
        this.lineChartLegend = true;
        this.lineChartType = 'line';
        this._interval = '3';
        this._conversationTypeId = '@Total';
        this.conversationTypes = [{ Id: undefined, Name: 'Загружаю список...' }];
        this.conversationTypes2 = [{ Id: undefined, Name: 'Загружаю список...' }];
        this.runningOperations = [];
    }
    Object.defineProperty(TelemetryComponent.prototype, "interval", {
        get: function () {
            return this._interval;
        },
        set: function (value) {
            this._interval = value;
            this.tickerFunc();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TelemetryComponent.prototype, "conversationTypeId", {
        get: function () {
            return this._conversationTypeId;
        },
        set: function (value) {
            this._conversationTypeId = value;
            this.tickerFunc();
        },
        enumerable: true,
        configurable: true
    });
    TelemetryComponent.prototype.ngOnInit = function () {
        this.refreshGatheredOperations();
        this.refreshRunningOperations();
        this.tickerFunc();
    };
    TelemetryComponent.prototype.refreshGatheredOperations = function () {
        var _this = this;
        this.metadataService.getGatheredOperations().subscribe(function (items) {
            _this.conversationTypes = items;
            _this.conversationTypes.sort(function (a, b) { return (a.a || '').localeCompare(b.a || ''); });
            _this.conversationTypes2 = items.slice();
            _this.conversationTypes2.sort(function (b, a) { return (a.b || 0) - (b.b || 0); });
        });
    };
    TelemetryComponent.prototype.refreshRunningOperations = function () {
        var _this = this;
        this.metadataService.getRunningOperations().subscribe(function (items) {
            _this.runningOperations = items;
            _this.runningOperations.sort(function (a, b) { return (a.durationMs || 0) - (b.durationMs || 0); });
        });
    };
    TelemetryComponent.prototype.ngOnDestroy = function () {
        if (this.sub) {
            this.sub.unsubscribe();
            this.sub = undefined;
        }
    };
    TelemetryComponent.prototype.tickerFunc = function () {
        var _this = this;
        var cInterval = +this.interval;
        var refreshPeriod = cInterval * 10000;
        if (this.sub) {
            this.sub.unsubscribe();
            this.sub = undefined;
        }
        this.timer = timer(refreshPeriod, refreshPeriod);
        this.sub = this.timer.pipe(first()).subscribe(function (t) { return _this.tickerFunc(); });
        this.metadataService.getOperationTelemetry(this._conversationTypeId, cInterval).subscribe(function (data) {
            // clearing out all
            _this.lineChartData[0].data = [];
            _this.lineChartLabels = [];
            // filling array
            _this.populateSeries(data);
        });
    };
    TelemetryComponent.prototype.populateSeries = function (data) {
        this.lineChartData[0].data = data.map(function (x) { return x.a; });
        this.lineChartData[1].data = data.map(function (x) { return x.b; });
        this.lineChartLabels = data.map(function (x) { return x.c; });
    };
    TelemetryComponent.prototype.formatAverage = function (item) {
        return item.c ? Math.round(+item.b / +item.c).toString() + ' мс' : '-';
    };
    return TelemetryComponent;
}());
export { TelemetryComponent };
