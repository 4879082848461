import { Component, Input, OnChanges } from '@angular/core';
import { SecurityService } from '../../../logic/services/security.service';
import { InstitutionDataService } from '../../../logic/services/institution-data.service';
import {StringHelper} from '../../../helpers/string-helper';

@Component({
  selector: 'app-edit-institution-agent-messages',
  templateUrl: './edit-institution-agent-messages.component.html'
})
export class EditInstitutionAgentMessagesComponent implements OnChanges {

  @Input() id: number;
  allMessages: {
    [institutionBranchId: number]: {
      [agentId: number]: {
        [agentMessageTypeId: number]: {
          messages: any[],
          countNotReading: 0,
        },
      },
    },
  };

  selectedMessages: {
    institutionBranchId: number,
    agentId: number,
    agentMessageTypeId: number,
    messages: any[],
  } = undefined;

  newMessageText: string = undefined;

  storing = false;

  constructor(private institutionDataService: InstitutionDataService,
              public securityService: SecurityService) {
  }

  ngOnChanges() {
    this.institutionDataService.getAllMessages(this.id)
      .subscribe(data => {
        const grouping = {};

        data.forEach(message => {
          this.createGroupIfNotExists(grouping, message);
          grouping[message.institutionBranchId][message.agentId][message.agentMessageTypeId].messages.push(message);
        });

        this.sort(grouping);
        this.allMessages = grouping;
        this.updateNotReadingMessages();

        setInterval(() => {
          this.institutionDataService.getLastMessages(this.id)
            .subscribe(lastMessages => {
              let changes = false;
              lastMessages.forEach(message => {
                this.createGroupIfNotExists(this.allMessages, message);

                const branchId = message.institutionBranchId;
                const agentId = message.agentId;
                const agentMessageTypeId = message.agentMessageTypeId;

                if (this.allMessages[branchId][agentId][agentMessageTypeId].messages
                    .findIndex(x => +x.id === +message.id) < 0) {

                  this.allMessages[branchId][agentId][agentMessageTypeId].messages.push(message);
                  this.markReading(this.allMessages[branchId][agentId][agentMessageTypeId].messages);
                  changes = true;
                }
              });
              if (changes) {
                this.sort(this.allMessages);
                this.updateNotReadingMessages();
              }
            });
        }, 5000);
      });
  }

  createGroupIfNotExists(data: any, message: any) {
    if (!(message.institutionBranchId in data)) {
      data[message.institutionBranchId] = {};
    }
    if (!(message.agentId in data[message.institutionBranchId])) {
      data[message.institutionBranchId][message.agentId] = {};
    }
    if (!(message.agentMessageTypeId in data[message.institutionBranchId][message.agentId])) {
      data[message.institutionBranchId][message.agentId][message.agentMessageTypeId] = { messages: [], countNotReading: 0 };
    }
  }

  sort(data: any) {
    Object.keys(data).forEach((institutionBranchId) => {
      Object.keys(data[institutionBranchId]).forEach((agentId) => {
        Object.keys(data[institutionBranchId][agentId]).forEach((typeId) => {
          data[institutionBranchId][agentId][typeId].messages.sort(((a, b) => (b.date || '').localeCompare(a.date)));
        });
      });
    });
  }

  updateNotReadingMessages() {
    Object.keys(this.allMessages).forEach((institutionBranchId) => {
      Object.keys(this.allMessages[institutionBranchId]).forEach((agentId) => {
        Object.keys(this.allMessages[institutionBranchId][agentId]).forEach((typeId) => {
          this.allMessages[institutionBranchId][agentId][typeId].countNotReading =
            this.allMessages[institutionBranchId][agentId][typeId].messages.filter(x => !x.userId && !x.dateReadingRecipient).length;
        });
      });
    });
  }

  getGroupData(data: any) {
    return data as any;
  }

  changeSelectedMessages(institutionBranchId: number, agentId: number, questId: number, messages: any[]) {
    this.selectedMessages = {
      institutionBranchId: institutionBranchId,
      agentId: agentId,
      agentMessageTypeId: questId,
      messages: messages,
    };

    this.markReading(messages);
  }

  markReading(messages: any[]) {
    const newReading = [];

    messages.forEach(message => {
      if (!message.userId && !message.dateReadingRecipient) {
        newReading.push(+message.id);
      }
    });

    if (this.selectedMessages && this.selectedMessages.agentId && newReading.length) {
      this.institutionDataService.markReading(this.selectedMessages.agentId, this.id, this.selectedMessages.institutionBranchId,
        this.selectedMessages.agentMessageTypeId, newReading)
        .subscribe(() => {

          const branchId = this.selectedMessages.institutionBranchId;
          const agentId = this.selectedMessages.agentId;
          const messageTypeId = this.selectedMessages.agentMessageTypeId;

          this.allMessages[branchId][agentId][messageTypeId].messages
            .forEach(message => {
              if (newReading.includes(+message.id)) {
                message.dateReadingRecipient = StringHelper.getISODateWithHourMinute(new Date());
                this.allMessages[branchId][agentId][messageTypeId].countNotReading--;
              }
            });
        });
    }
  }

  store() {
    if (!this.newMessageText || !this.newMessageText.trim().length) {
      return;
    }

    this.storing = true;

    this.institutionDataService.storeMessage({
      agentId: this.selectedMessages.agentId,
      institutionId: this.id,
      institutionBranchId: this.selectedMessages.institutionBranchId,
      agentMessageTypeId: this.selectedMessages.agentMessageTypeId,
      text: this.newMessageText,
    }).subscribe(val => {
      this.allMessages[val.institutionBranchId][val.agentId][val.agentMessageTypeId].messages.push(val);
      this.sort(this.allMessages);
      this.storing = false;
      this.newMessageText = '';
    }, () => this.storing = false);
  }

  keyPressMessage(event: any) {
    if (event.key === 'Enter') {
      this.store();
    }
  }
}
