<div class="itech-ticket-planning-day-container"
     [class.tooltip]="!zoomedMode"
     [class.itech-zoomed]="zoomedMode"
     [class.itech-holiday]="holidaysService.isHoliday(day.date)"
     [class.itech-current-month]="day.date.getMonth() == model.displayedMonth && day.date.getFullYear() == model.displayedYear"
     [class.itech-current-day]="day.date.getTime() == currentDate.getTime()">
        <span *ngIf="!zoomedMode" class="tooltip-content"
              [class.tooltip-top-left]="(day.date.getDay() == 0 || day.date.getDay() == 6) && day.index > 7"
              [class.tooltip-bottom-left]="(day.date.getDay() == 0 || day.date.getDay() == 6) && day.index <= 7"
              [class.tooltip-bottom-right]="!(day.date.getDay() == 0 || day.date.getDay() == 6) && day.index <= 7">
          Щелкните для увеличения
        </span>
  <div class="itech-ticket-planning-day-header">
    {{day.date | rdate}} ({{day.date | date: 'EEEEE'}})
  </div>
  <div class="itech-ticket-planning-day-content">

    <div class="itech-ticket-planning-day-vcontent">
      <div *ngFor="let slot of day.slots; index as ixSlot" class="tooltip tooltip-top itech-ticket-day-slot"
           role="tooltip"
           [class.itech-focused]="slot===model.expandedSlot"
           [class.itech-highlighted]="isCurrentlyFilteredSlot(slot)"
           [class.itech-holiday]="holidaysService.isHoliday(day.date)"
           [class.itech-empty-day-slot]="!slot.tickets || slot.tickets.length === 0"
           [class.itech-filled-day-slot]="slot.tickets && slot.tickets.length > 0">

        <div (click)="slotClick(slot)">
          {{planningUi.getSlotCaption(slot)|async}}
        </div>

        <span *ngIf="zoomedMode && !model.expandedSlot" class="tooltip-content"
              [class.tooltip-top-left]="day.index > model.zoomedDayIndex && ixSlot >= 8"
              [class.tooltip-bottom-left]="day.index > model.zoomedDayIndex && ixSlot < 8"
              [class.tooltip-bottom-right]="day.index <= model.zoomedDayIndex && ixSlot < 8">
        {{slot.timeStart}} по {{slot.timeEnd}}. Щелкните для просмотра подробной информации
        </span>

        <div *ngIf="slot===model.expandedSlot" class="card itech-slot-detais-container"
             [style.right]="day.index > model.zoomedDayIndex ? 0 : undefined">
          <div class="card-header">
            {{day.date | rdate}} с {{slot.timeStart}} до {{slot.timeEnd}}
            <a class="itech-popup-close-btn" (click)="toggleExpandedSlot(slot)">
              <clr-icon shape="times"></clr-icon>
            </a>
          </div>
          <div class="card-block" style="max-height: 370px; overflow-y: auto;">
            <div class="card-title" *ngIf="!slot.tickets.length">
              Талоны отсутствуют
            </div>
            <table *ngIf="slot.tickets.length" class="itech-slot-details-table">
              <thead>
              <tr>
                <th style="width:160px">Вопрос</th>
                <th style="width:80px">Врач</th>
                <th style="width:150px">ХС</th>
                <th style="width:70px">Дата с</th>
                <th style="width:50px">Прод.</th>
              </tr>
              </thead>
              <tbody>
                <tr *ngFor="let ticket of slot.tickets" class="itech-ticket-info"
                    [style.background-color]="ticket.data.agentId ? ticket.data.cancelTime ? '#ffc7c7' : '' : '#e6ffe6'">
                  <td (click)="planningUi.editTicket(ticket)">
                    <div *ngFor="let quest of ticket.data.institutionTicketQuestIds">
                      <clr-icon shape="check" style="color: green" *ngIf="(ticket.data.selectedInstitutionTicketQuestIds || []).includes(quest)"
                                title="Выбрано при записи"></clr-icon>
                      {{quest|lookup:'institution-ticket-quest':true|async}}
                    </div>
                  </td>
                  <td (click)="planningUi.editTicket(ticket)">
                    {{ticket.data.institutionEmployeeId|lookup:'institution-employee' + ticket.data.institutionId:true|async}}
                  </td>
                  <td>
                    <app-link-button [lookupName]="'agent'" [id]="ticket.data.agentId" [noValueText]="'СВОБОДНО'"></app-link-button>
                  </td>
                  <td (click)="planningUi.editTicket(ticket)">
                    {{ticket.data.startTime|rtime}}
                  </td>
                  <td (click)="planningUi.editTicket(ticket)">
                    {{ticket.data.durationMinutes}}&nbsp;мин.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="card-footer" *ngIf="workingInstitutionId">
            <button class="btn btn-sm btn-link" (click)="planningUi.addTicket(slot, model)">
              Добавить талон
            </button>
          </div>
          <div class="card-footer" *ngIf="!workingInstitutionId">
            <div class="itech-control-desc itech-desc-error itech-desc-inline">
              Редактирование талонов в данном режиме не предусмотрено (сначала необходимо произвести имперсонализацию под
              конкретным учреждением)
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="(day.continuousTickets || []).length > 0" class="itech-ticket-planning-day-hcontent">
      {{(day.continuousTickets || [])[0]}}
    </div>
    <div *ngIf="(day.continuousTickets || []).length > 1" class="itech-ticket-planning-day-hcontent">
      {{(day.continuousTickets || [])[1]}}
    </div>
    <div *ngIf="(day.continuousTickets || []).length == 3" class="itech-ticket-planning-day-hcontent">
      {{(day.continuousTickets || [])[2]}}
    </div>
    <div *ngIf="(day.continuousTickets || []).length > 3" class="itech-ticket-planning-day-hcontent"
         [class.itech-highlighted]="isCurrentlyFilteredVSlotTail((day.continuousTickets || []))">
      Еще {{(day.continuousTickets || []).length - 2}} тал...
    </div>

  </div>
</div>
