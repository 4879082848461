import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AgGridModule } from '@ag-grid-community/angular';

import { DashboardComponent } from './layout/dashboard/dashboard.component';
import { UiModule } from '../ui/ui.module';
import { ServerSideErrorsValidatorDirective } from '../logic/validators/server-side-errors-validator.directive';
import { PassportOrgCodeDirective } from '../logic/validators/passport-org-code.directive';
import { SnilsDirective } from '../logic/validators/snils.directive';
import { SearchParamsDirective } from '../logic/validators/search-params.directive';
import { InstitutionSearchResultsComponent } from './edit-institution/institution-search-results.component';
import { EditInstitutionComponent } from './edit-institution/edit-institution.component';
import { EditInstitutionCommonComponent } from './edit-institution/common/edit-institution-common.component';
import { EditInstitutionEmployeesComponent } from './edit-institution/employees/edit-institution-employees.component';
import { EditInstitutionBranchesComponent } from './edit-institution/branches/edit-institution-branches.component';
import { CreateInstitutionComponent } from './edit-institution/create-institution.component';
import { EditInstitutionCommonFormComponent } from './edit-institution/common/edit-institution-common-form.component';
import { GroupOperationsBrowseComponent } from './common/group-operations-browse.component';
import { AddrCityBrowseComponent } from './dictionary/address/addr-city-browse.component';
import { PersonGroupsBrowseComponent } from './person-groups/person-groups-browse.component';
import { ReportsBrowseComponent } from './dictionary/reports/reports-browse.component';
import { UnavailableComponent } from './common/unavailable.component';
import { JournalComponent } from './common/journal.component';
import { UserRolesBrowseComponent } from './users/user-roles-browse.component';
import { AppUrChooseObjectMetaComponent } from './users/app-ur-choose-object-meta.component';
import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './layout/header/header.component';
import { MainComponent } from './layout/main/main.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { AppCustomModalComponent } from './common/app-custom-modal.component';
import { InstitutionEmployeeModalComponent } from './edit-institution/employees/institution-employee-modal.component';
import { AddrStreetBrowseComponent } from './dictionary/address/addr-street-browse.component';
import { AddrStreetTypeBrowseComponent } from './dictionary/address/addr-street-type-browse.component';
import { AddrCityTypeBrowseComponent } from './dictionary/address/addr-city-type-browse.component';
import { QueryManagerComponent } from './query/query-manager.component';
import { RelationNodesComponent } from './query/relation-nodes.component';
import { QueryDetailsResultsComponent } from './query/query-details-results.component';
import { QueryDetailsStableComponent } from './query/query-details-stable.component';
import { QueryDetailsQueryComponent } from './query/query-details-query.component';
import { QueryDateTimeFilterComponent } from './query/controls/query-date-time-filter.component';
import { QueryStringFilterComponent } from './query/controls/query-string-filter.component';
import { QueryDetailsAnimalComponent } from './query/query-details-animal.component';
import { QueryCommonInfoComponent } from './query/controls/query-common-info.component';
import { QueryDetailsEventComponent } from './query/query-details-event.component';
import { QueryBooleanFilterComponent } from './query/controls/query-boolean-filter.component';
import { QueryLookupListFilterComponent } from './query/controls/query-lookup-list-filter.component';
import { QueryLookupComboFilterComponent } from './query/controls/query-lookup-combo-filter.component';
import { QueryDetailsAnimalFieldsComponent } from './query/query-details-animal-fields.component';
import { QueryParamsComponent } from './query/query-params.component';
import { QueryDetailsEventFieldsComponent } from './query/query-details-event-fields.component';
import { QueryDetailsStableFieldsComponent } from './query/query-details-stable-fields.component';
import { SmevMessageComponent } from './smev-messages/smev-message.component';
import { SmevMsgEditor400001Component } from './smev-messages/custom-message-editors/smev-msg-editor-400001.component';
import { ClarityModule } from '@clr/angular';
import { VersionHistoryComponent } from './layout/version-history/version-history.component';
import { InstitutionPostBrowseComponent } from './dictionary/institution/institution-post-browse.component';
import { EditAgentCommonComponent } from './edit-agent/common/edit-agent-common.component';
import { EditAgentCommonFormComponent } from './edit-agent/common/edit-agent-common-form.component';
import { EditAgentStablesComponent } from './edit-agent/stables/edit-agent-stables.component';
import { AgentSearchResultsComponent } from './edit-agent/search/agent-search-results.component';
import { EditAgentComponent } from './edit-agent/edit-agent.component';
import { CreateAgentComponent } from './edit-agent/create-agent.component';
import { JournalMasterDetailCellRendererComponent } from './common/journal-master-detail-cell-renderer.component';
import { EditAgentPersonFormComponent } from './edit-agent/common/edit-agent-person-form.component';
import { EditStableAgentFormComponent } from './edit-stable/common/edit-stable-agent-form.component';
import { EditStableCommonComponent } from './edit-stable/common/edit-stable-common.component';
import { EditStableCommonFormComponent } from './edit-stable/common/edit-stable-common-form.component';
import { CreateStableComponent } from './edit-stable/create-stable.component';
import { EditStableComponent } from './edit-stable/edit-stable.component';
import { AgentSearchResultsFormComponent } from './edit-agent/search/agent-search-results-form.component';
import { AgentSearchModalComponent } from './edit-agent/search/agent-search-modal.component';
import { StableSearchModalComponent } from './edit-stable/search/stable-search-modal.component';
import { StableSearchResultsComponent } from './edit-stable/search/stable-search-results.component';
import { StableSearchResultsFormComponent } from './edit-stable/search/stable-search-results-form.component';
import { AnimalSearchModalComponent } from './edit-animal/search/animal-search-modal.component';
import { AnimalSearchResultsFormComponent } from './edit-animal/search/animal-search-results-form.component';
import { AnimalSearchResultsComponent } from './edit-animal/search/animal-search-results.component';
import { EditAnimalCommonFormComponent } from './edit-animal/common/edit-animal-common-form.component';
import { EditAnimalCommonComponent } from './edit-animal/common/edit-animal-common.component';
import { EditAnimalAgentFormComponent } from './edit-animal/common/edit-animal-agent-form.component';
import { CreateAnimalComponent } from './edit-animal/create-animal.component';
import { EditAnimalComponent } from './edit-animal/edit-animal.component';
import { EditAnimalStableFormComponent } from './edit-animal/common/edit-animal-stable-form.component';
import { EditAgentAnimalsComponent } from './edit-agent/animals/edit-agent-animals.component';
import { EditStableAnimalsComponent } from './edit-stable/animals/edit-stable-animals.component';
import { EditDrugCommonComponent } from './edit-drug/common/edit-drug-common.component';
import { EditDrugCommonFormComponent } from './edit-drug/common/edit-drug-common-form.component';
import { CreateDrugComponent } from './edit-drug/create-drug.component';
import { EditDrugComponent } from './edit-drug/edit-drug.component';
import { DrugSearchModalComponent } from './edit-drug/search/drug-search-modal.component';
import { DrugSearchResultsFormComponent } from './edit-drug/search/drug-search-results-form.component';
import { DrugSearchResultsComponent } from './edit-drug/search/drug-search-results.component';
import { EditAnimalEventsComponent } from './edit-animal/events/edit-animal-events.component';
import { EditAnimalEventCommonComponent } from './edit-animal/events/edit-animal-event-common.component';
import { EditAnimalEventCommonFormComponent } from './edit-animal/events/edit-animal-event-common-form.component';
import { CreateAnimalEventComponent } from './edit-animal/events/create-animal-event.component';
import { EditAnimalEventComponent } from './edit-animal/events/edit-animal-event.component';
import { EditDrugSpendingComponent } from './edit-drug/writeoffs/edit-drug-spending.component';
import { AgentSyncStatusSignpostComponent } from './common/mercury-sync-status-signpost.component';
import { MoMapComponent } from './layout/dashboard/mo-map.component';
import { GroupOperationRunnerComponent } from './common/group-operation-runner.component';
import { EditProductCommonComponent } from './edit-product/common/edit-product-common.component';
import { EditProductCommonFormComponent } from './edit-product/common/edit-product-common-form.component';
import { EditProductPathFormComponent } from './edit-product/common/edit-product-path-form.component';
import { EditProductSourceFormComponent } from './edit-product/common/edit-product-source-form.component';
import { ProductSearchResultsComponent } from './edit-product/search/product-search-results.component';
import { ProductSearchModalComponent } from './edit-product/search/product-search-modal.component';
import { ProductSearchResultsFormComponent } from './edit-product/search/product-search-results-form.component';
import { EditProductSubproductsComponent } from './edit-product/subproducts/edit-product-subproducts.component';
import { CreateProductComponent } from './edit-product/create-product.component';
import { EditProductComponent } from './edit-product/edit-product.component';
import { EditAnimalListComponent } from './edit-animal/edit-animal-list.component';
import { EditExpertiseCommonComponent } from './edit-product/expertise/edit-expertise-common.component';
import { EditExpertiseIndicatorsComponent } from './edit-product/expertise/edit-expertise-indicators.component';
import { EditProductExpertisesComponent } from './edit-product/expertise/edit-product-expertises.component';
import { ExpertiseIndicatorTypeBrowseComponent } from './dictionary/product/expertise-indicator-type-browse.component';
import { EditStableEventsComponent } from './edit-stable/events/edit-stable-events.component';
import { QueryAddrCityFilterComponent } from './query/controls/query-addr-city-filter.component';
import { QueryStandaloneResultsComponent } from './query/query-standalone-results.component';
import { QueryIntegerFilterComponent } from './query/controls/query-integer-filter.component';
import { ViewIntegrationLogComponent } from './common/view-integration-log.component';
import { EditAnimalProductsComponent } from './edit-animal/products/edit-animal-products.component';
import { EditStableProductsComponent } from './edit-stable/products/edit-stable-products.component';
import { AnimalSubtypeBrowseComponent } from './dictionary/animal/animal-subtype-browse.component';
import { AnimalBreedBrowseComponent } from './dictionary/animal/animal-breed-browse.component';
import { ImpersonateParamsComponent } from './layout/header/impersonate-params.component';
import { DiseaseTypeBrowseComponent } from './dictionary/animal/disease-type-browse.component';
import { EventTypeBrowseComponent } from './dictionary/event/event-type-browse.component';
import { DeathReasonBrowseComponent } from './dictionary/animal/death-reason-browse.component';
import { AppUrChooseObjectMetaCombineKeyComponent } from './users/app-ur-choose-object-meta-combine-key.component';
import { QuarantineSearchResultsComponent } from './edit-quarantine/search/quarantine-search-results.component';
import { AgGridLookupRendererComponent } from '../ui/controls/ag-grid-lookup-renderer.component';
import { CreateQuarantineComponent } from './edit-quarantine/create-quarantine.component';
import { EditQuarantineCommonComponent } from './edit-quarantine/common/edit-quarantine-common.component';
import { EditQuarantineComponent } from './edit-quarantine/edit-quarantine.component';
import { EditQuarantineStagesComponent } from './edit-quarantine/stages/edit-quarantine-stages.component';
import { EditQuarantineStagesSuspicionComponent } from './edit-quarantine/stages/edit-quarantine-stages-suspicion.component';
import { EditQuarantineStagesDetectionComponent } from './edit-quarantine/stages/edit-quarantine-stages-detection.component';
import { EditQuarantineStagesIntroductionComponent } from './edit-quarantine/stages/edit-quarantine-stages-introduction.component';
import { EditQuarantineStagesCancelComponent } from './edit-quarantine/stages/edit-quarantine-stages-cancel.component';
import { EditQuarantineStagesFileComponent } from './edit-quarantine/stages/edit-quarantine-stages-file.component';
import { EditQuarantineStagesDecisionNextStageComponent } from './edit-quarantine/stages/edit-quarantine-stages-decision-next-stage.component';
import { EventTestMaterialBrowseComponent } from './dictionary/event/event-test-material-browse.component';
import { ViewQuarantineAnimalstableEventsSearchResultsComponent } from './edit-quarantine/events/view-quarantine-animalstable-events-search-results.component';
import { ViewQuarantineAnimalstableEventsComponent } from './edit-quarantine/events/view-quarantine-animalstable-events.component';
import { EditInstitutionIndicatorsComponent } from './edit-institution/indicators/edit-institution-indicators.component';
import { InstitutionTypeBrowseComponent } from './dictionary/institution/institution-type-browse.component';
import { NomenclatureAnimalPinBrowseComponent } from './dictionary/nomenclature/nomenclature-animal-pin-browse.component';
import { AppDistributionAgentAvailablePinsComponent } from './common/app-distribution-agent-available-pins.component';
import { DiseaseSerotypeBrowseComponent } from './dictionary/animal/disease-serotype-browse.component';
import { EditQuarantineMessagesComponent } from './edit-quarantine/messages/edit-quarantine-messages.component';
import { QueryDetailsQuarantineFieldsComponent } from './query/query-details-quarantine-fields.component';
import { QueryDetailsQuarantineComponent } from './query/query-details-quarantine.component';
import { DrugPackageTypeBrowseComponent } from './dictionary/drug/drug-package-type-browse.component';
import { DrugTypeBrowseComponent } from './dictionary/drug/drug-type-browse.component';
import { EditQuarantineEventsComponent } from './edit-quarantine/events/edit-quarantine-events.component';
import { QuarantineEventTypeBrowseComponent } from './dictionary/quarantine/quarantine-event-type-browse.component';
import { QuarantineEventBranchBrowseComponent } from './dictionary/quarantine/quarantine-event-branch-browse.component';
import { QuarantineEventResponsibleBrowseComponent } from './dictionary/quarantine/quarantine-event-responsible-browse.component';
import { QuarantineEventPeriodBrowseComponent } from './dictionary/quarantine/quarantine-event-period-browse.component';
import { QuarantineTemplateBrowseComponent } from './dictionary/quarantine/quarantine-template-browse.component';
import { EditStableProductSubtypeComponent } from './edit-stable/product-subtype/edit-stable-product-subtype.component';
import { SlaughteryViewComponent } from './edit-animal/search/slaughtery-view.component';
import { SlaughteryViewFormComponent } from './edit-animal/search/slaughtery-view-form.component';
import { QueryDetailsAgentComponent } from './query/query-details-agent.component';
import { QueryDetailsAgentFieldsComponent } from './query/query-details-agent-fields.component';
import { ProductSubtypeBrowseComponent } from './dictionary/product/product-subtype-browse.component';
import { EditQuarantineCommonTerritoryComponent } from './edit-quarantine/common/edit-quarantine-common-territory.component';
import { QuarantineBlockComponent } from './edit-quarantine/quarantine-block.component';
import { AppModalPreviewFileComponent } from '../ui/controls/app-modal-preview-file.component';
import { SendToClearanceModalComponent } from './edit-product/common/send-to-clearance-modal.component';
import { AgGridButtonRendererComponent } from '../ui/controls/ag-grid-button-renderer.component';
import { AgGridTextLookupEditorComponent } from '../ui/controls/ag-grid-text-lookup-editor.component';
import { AgGridSearchModalEditorComponent } from '../ui/controls/ag-grid-search-modal-editor.component';
import { AgGridTextInputEditorComponent } from '../ui/controls/ag-grid-text-input-editor.component';
import { AuthFoundMultipleComponent } from 'app/esia/auth-found-multiple.component';
import { AgGridProductTransactionDetailRendererComponent } from '../ui/controls/ag-grid-product-transaction-detail-renderer.component';
import { AgGridButtonStatusBarComponent } from '../ui/controls/ag-grid-button-status-bar.component';
import { AgGridFormMasterDetailComponent } from '../ui/controls/ag-grid-form-master-detail.component';
import { ProductSearchResultsWithTabsComponent } from './edit-product/search/product-search-results-with-tabs.component';
import { EditProductPackagingComponent } from './edit-product/common/edit-product-packaging.component';
import { AgGridCellComponentHandlerComponent } from '../ui/controls/ag-grid-cell-component-handler.component';
import { JournalLookupCellRendererComponent } from './common/journal-lookup-cell-renderer.component';
import { EventDiagnosticTypeBrowseComponent} from './dictionary/event/event-diagnostic-type-browse.component';
import { EventIndicatorBrowseComponent} from './dictionary/event/event-indicator-browse.component';
import { ViewIntegrationLog2Component } from './common/view-integration-log2.component';
import { ProductStatisticComponent } from './common/product-statistic.component';
import { TelemetryComponent } from './common/telemetry.component';
import { ChartsModule } from 'ng2-charts';
import { InstitutionTicketQuestBrowseComponent } from './dictionary/institution/institution-ticket-quest-browse.component';
import { CalendarBrowseComponent } from './dictionary/common/calendar-browse.component';
import { TicketsPlanningComponent } from './edit-institution/tickets/tickets-planning.component';
import { TicketsPlanningDayComponent } from './edit-institution/tickets/tickets-planning-day.component';
import { TicketEditModalComponent } from './edit-institution/tickets/ticket-edit-modal.component';
import { TicketDeleteModalComponent } from './edit-institution/tickets/ticket-delete-modal.component';
import { EditAnimalTicketsComponent } from './edit-animal/tickets/edit-animal-tickets.component';
import { EditAgentTicketsComponent } from './edit-agent/tickets/edit-agent-tickets.component';
import { EditInstitutionAgentMessagesComponent } from './edit-institution/messages/edit-institution-agent-messages.component';
import { EditInstitutionAgentMessageFormComponent } from './edit-institution/messages/edit-institution-agent-message-form.component';
import { QueryDetailsInstitutionTicketComponent } from './query/query-details-institution-ticket.component';
import { QueryDetailsInstitutionTicketFieldsComponent } from './query/query-details-institution-ticket-fields.component';
import { QueryDetailsProductComponent } from './query/query-details-product.component';
import { QueryDetailsProductFieldsComponent } from './query/query-details-product-fields.component';
import { QueryStableAgentFilterComponent } from './query/controls/query-stable-agent-filter.component';
import { QueryAddressFilterComponent } from './query/controls/query-address-filter.component';
import { InstitutionEmployeeStatisticComponent } from './common/institution-employee-statistic.component';
import { CreateAgentModalComponent } from './edit-agent/create-agent-modal.component';
import { PersonalAccountCommonComponent } from './user-personal-account/personal-account-common.component';
import { UserFavouriteStablesComponent } from './user-personal-account/user-favourite-stables.component';
import { UserReservedDrugsComponent } from './user-personal-account/user-reserved-drugs.component';
import { RecalculatePstIndicatorsParamsComponent } from './layout/header/recalculate-pst-indicators-params.component';
import { UserTagsAutofillComponent } from './user-personal-account/user-tags-autofill.component';
import { EditStableProductionTemplateComponent } from './edit-stable/production-template/edit-stable-production-template.component';
import { EventForcedReasonBrowseComponent } from './dictionary/event/event-forced-reason-browse.component';
import { DrugTypeBrowseCreateModalComponent } from './dictionary/drug/drug-type-browse-create-modal.component';
import { DrugTypeBrowseFormComponent } from './dictionary/drug/drug-type-browse-form.component';
import { DrugManufacturerBrowseComponent } from './dictionary/drug/drug-manufacturer-browse.component';
import { QueryDetailsDrugComponent } from './query/query-details-drug.component';
import { QueryDetailsDrugFieldsComponent } from './query/query-details-drug-fields.component';
import { QueryViewParamsComponent } from './query/query-view-params.component';
import { PstIndicatorSubtypeBrowseComponent } from '../pst-report/pages/meta/pst-indicator-subtype-browse.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        ClarityModule,
        UiModule,
        AgGridModule.withComponents([]),
        ChartsModule
    ],
  declarations: [
    LayoutComponent,
    HeaderComponent,
    MainComponent,
    SidebarComponent,
    DashboardComponent,
    InstitutionSearchResultsComponent,
    ServerSideErrorsValidatorDirective,
    PassportOrgCodeDirective,
    SnilsDirective,
    SearchParamsDirective,
    EditInstitutionComponent,
    EditInstitutionCommonComponent,
    EditInstitutionCommonFormComponent,
    EditInstitutionEmployeesComponent,
    EditInstitutionBranchesComponent,
    CreateInstitutionComponent,
    GroupOperationsBrowseComponent,
    AddrCityBrowseComponent,
    PersonGroupsBrowseComponent,
    ReportsBrowseComponent,
    UnavailableComponent,
    JournalComponent,
    UserRolesBrowseComponent,
    AppUrChooseObjectMetaComponent,
    AppUrChooseObjectMetaCombineKeyComponent,
    AppCustomModalComponent,
    InstitutionEmployeeModalComponent,
    AddrStreetBrowseComponent,
    AddrStreetTypeBrowseComponent,
    AddrCityTypeBrowseComponent,
    QueryManagerComponent,
    RelationNodesComponent,
    QueryDetailsResultsComponent,
    QueryDetailsStableComponent,
    QueryDetailsQueryComponent,
    QueryDateTimeFilterComponent,
    QueryStringFilterComponent,
    QueryDetailsAnimalComponent,
    QueryCommonInfoComponent,
    QueryDetailsEventComponent,
    QueryBooleanFilterComponent,
    QueryLookupListFilterComponent,
    QueryLookupComboFilterComponent,
    QueryStableAgentFilterComponent,
    QueryDetailsAnimalFieldsComponent,
    QueryDetailsEventFieldsComponent,
    QueryDetailsStableFieldsComponent,
    QueryDetailsQuarantineFieldsComponent,
    QueryDetailsQuarantineComponent,
    QueryParamsComponent,
    QueryViewParamsComponent,
    QueryDetailsAgentComponent,
    QueryDetailsProductComponent,
    QueryDetailsAgentFieldsComponent,
    QueryDetailsProductFieldsComponent,
    QueryDetailsInstitutionTicketComponent,
    QueryDetailsInstitutionTicketFieldsComponent,
    QueryAddressFilterComponent,
    QueryDetailsDrugComponent,
    QueryDetailsDrugFieldsComponent,
    SmevMessageComponent,
    SmevMsgEditor400001Component,
    VersionHistoryComponent,
    InstitutionPostBrowseComponent,
    InstitutionTypeBrowseComponent,
    InstitutionTicketQuestBrowseComponent,
    CreateAgentComponent,
    EditAgentComponent,
    EditAgentCommonComponent,
    EditAgentCommonFormComponent,
    EditAgentStablesComponent,
    AgentSearchModalComponent,
    AgentSearchResultsComponent,
    AgentSearchResultsFormComponent,
    CreateAgentModalComponent,
    JournalMasterDetailCellRendererComponent,
    JournalLookupCellRendererComponent,
    EditAgentPersonFormComponent,
    EditStableAgentFormComponent,
    EditStableCommonComponent,
    EditStableCommonFormComponent,
    CreateStableComponent,
    EditStableComponent,
    StableSearchResultsComponent,
    StableSearchResultsFormComponent,
    StableSearchModalComponent,
    AnimalSearchModalComponent,
    AnimalSearchResultsFormComponent,
    AnimalSearchResultsComponent,
    EditAnimalCommonFormComponent,
    EditAnimalCommonComponent,
    EditAnimalAgentFormComponent,
    CreateAnimalComponent,
    EditAnimalComponent,
    EditAnimalStableFormComponent,
    EditAgentAnimalsComponent,
    EditStableAnimalsComponent,
    EditDrugCommonComponent,
    EditDrugCommonFormComponent,
    CreateDrugComponent,
    EditDrugComponent,
    DrugSearchModalComponent,
    DrugSearchResultsFormComponent,
    DrugSearchResultsComponent,
    EditAnimalEventsComponent,
    EditAnimalEventCommonComponent,
    EditAnimalEventCommonFormComponent,
    CreateAnimalEventComponent,
    EditAnimalEventComponent,
    EditDrugSpendingComponent,
    DiseaseSerotypeBrowseComponent,
    AgentSyncStatusSignpostComponent,
    ProductStatisticComponent,
    PersonalAccountCommonComponent,
    UserFavouriteStablesComponent,
    UserReservedDrugsComponent,
    UserTagsAutofillComponent,
    MoMapComponent,
    GroupOperationRunnerComponent,
    EditProductCommonComponent,
    EditProductCommonFormComponent,
    EditProductPathFormComponent,
    EditProductSourceFormComponent,
    EditProductPackagingComponent,
    ProductSearchResultsComponent,
    ProductSearchModalComponent,
    ProductSearchResultsFormComponent,
    ProductSearchResultsWithTabsComponent,
    EditProductSubproductsComponent,
    CreateProductComponent,
    EditProductComponent,
    EditAnimalListComponent,
    EditExpertiseCommonComponent,
    EditExpertiseIndicatorsComponent,
    EditProductExpertisesComponent,
    ExpertiseIndicatorTypeBrowseComponent,
    EditStableEventsComponent,
    EditStableProductSubtypeComponent,
    QueryAddrCityFilterComponent,
    QueryStandaloneResultsComponent,
    QueryIntegerFilterComponent,
    ViewIntegrationLogComponent,
    ViewIntegrationLog2Component,
    InstitutionEmployeeStatisticComponent,
    EditAnimalProductsComponent,
    EditStableProductsComponent,
    EditStableProductionTemplateComponent,
    AnimalSubtypeBrowseComponent,
    AnimalBreedBrowseComponent,
    DeathReasonBrowseComponent,
    ImpersonateParamsComponent,
    DiseaseTypeBrowseComponent,
    EventTypeBrowseComponent,
    EventForcedReasonBrowseComponent,
    EventTestMaterialBrowseComponent,
    EventDiagnosticTypeBrowseComponent,
    EventIndicatorBrowseComponent,
    AgGridLookupRendererComponent,
    AgGridButtonRendererComponent,
    AgGridTextLookupEditorComponent,
    AgGridSearchModalEditorComponent,
    AgGridTextInputEditorComponent,
    AgGridProductTransactionDetailRendererComponent,
    AgGridFormMasterDetailComponent,
    AgGridCellComponentHandlerComponent,
    QuarantineSearchResultsComponent,
    CreateQuarantineComponent,
    QuarantineBlockComponent,
    EditQuarantineComponent,
    EditQuarantineCommonComponent,
    EditQuarantineCommonTerritoryComponent,
    EditQuarantineStagesComponent,
    EditQuarantineStagesFileComponent,
    EditQuarantineStagesSuspicionComponent,
    EditQuarantineStagesDetectionComponent,
    EditQuarantineStagesIntroductionComponent,
    EditQuarantineStagesCancelComponent,
    EditQuarantineStagesDecisionNextStageComponent,
    ViewQuarantineAnimalstableEventsComponent,
    ViewQuarantineAnimalstableEventsSearchResultsComponent,
    EditInstitutionIndicatorsComponent,
    EditInstitutionAgentMessagesComponent,
    EditInstitutionAgentMessageFormComponent,
    NomenclatureAnimalPinBrowseComponent,
    AppDistributionAgentAvailablePinsComponent,
    EditQuarantineMessagesComponent,
    DrugPackageTypeBrowseComponent,
    DrugTypeBrowseComponent,
    DrugTypeBrowseFormComponent,
    DrugManufacturerBrowseComponent,
    EditQuarantineEventsComponent,
    QuarantineEventTypeBrowseComponent,
    QuarantineEventBranchBrowseComponent,
    QuarantineEventResponsibleBrowseComponent,
    QuarantineEventPeriodBrowseComponent,
    QuarantineTemplateBrowseComponent,
    SlaughteryViewComponent,
    SlaughteryViewFormComponent,
    ProductSubtypeBrowseComponent,
    AppModalPreviewFileComponent,
    SendToClearanceModalComponent,
    AuthFoundMultipleComponent,
    AgGridButtonStatusBarComponent,
    TelemetryComponent,
    CalendarBrowseComponent,
    TicketsPlanningComponent,
    TicketsPlanningDayComponent,
    TicketEditModalComponent,
    TicketDeleteModalComponent,
    EditAnimalTicketsComponent,
    EditAgentTicketsComponent,
    RecalculatePstIndicatorsParamsComponent,
    PstIndicatorSubtypeBrowseComponent,
    DrugTypeBrowseCreateModalComponent,
    DrugTypeBrowseFormComponent,
  ],
  exports: [
    LayoutComponent,
    DashboardComponent,
    AppCustomModalComponent,
    AuthFoundMultipleComponent,
  ],

  entryComponents: [
    QueryParamsComponent,
    QueryViewParamsComponent,
    JournalMasterDetailCellRendererComponent,
    JournalLookupCellRendererComponent,
    AgentSearchModalComponent,
    CreateAgentModalComponent,
    StableSearchModalComponent,
    AnimalSearchModalComponent,
    DrugSearchModalComponent,
    ProductSearchModalComponent,
    ImpersonateParamsComponent,
    AgGridLookupRendererComponent,
    AgGridButtonRendererComponent,
    AgGridTextLookupEditorComponent,
    AgGridSearchModalEditorComponent,
    AgGridTextInputEditorComponent,
    AgGridProductTransactionDetailRendererComponent,
    AgGridFormMasterDetailComponent,
    AgGridCellComponentHandlerComponent,
    AppModalPreviewFileComponent,
    SendToClearanceModalComponent,
    AgGridButtonStatusBarComponent,
    RecalculatePstIndicatorsParamsComponent,
    DrugTypeBrowseCreateModalComponent,
  ]
})
export class PagesModule {
}
