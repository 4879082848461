import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {Observable, Subject} from 'rxjs';
import {IModalBody, ModalResult} from '../../../logic/services/app-navigation.service.models';
import {AppNavigationService} from '../../../logic/services/app-navigation.service';
import {PstReportService} from '../../services/pst-report.service';
import {FormHelper} from '../../../ui/controls/form-helper';
import {AlertService} from '../../../ui/infrastructure/alert.service';

@Component({
  template: `
      <div *ngIf="contextFormGroup" class="form compact" [formGroup]="contextFormGroup"
           style="max-height: 60vh; background-color: white; position: relative;">
          <section class="form-block itech-block-normal">

              <div class="form-group">
                  <label for="colNo">№ графы</label>
                  <label for="colNo" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md tooltip-top-left"
                         [class.invalid]="isInvalid(contextFormGroup, 'colNo')">
                      <input type="text" id="colNo" placeholder="№ графы" class="itech-control-normal"
                             formControlName="colNo">
                      <app-validation-tooltip
                              [input]="contextFormGroup.controls['colNo']"></app-validation-tooltip>
                  </label>
              </div>

              <div class="form-group">
                  <label for="rowNo">№ строки</label>
                  <label for="rowNo" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md tooltip-top-left"
                         [class.invalid]="isInvalid(contextFormGroup, 'rowNo')">
                      <input type="text" id="rowNo" placeholder="№ строки" class="itech-control-normal"
                             formControlName="rowNo">
                      <app-validation-tooltip
                              [input]="contextFormGroup.controls['rowNo']"></app-validation-tooltip>
                  </label>
              </div>

              <div class="form-group">
                  <label for="indicatorTypeId" class="required">Показатель</label>
                  <app-text-lookup class="itech-control-xlarge" [contextControlId]="'indicatorTypeId'"
                                   [validationTooltipTopLeft]="true"
                                   [contextControlName]="'indicatorTypeId'" [lookupName]="'pst-indicator-type'"
                                   [contextFormGroup]="contextFormGroup"></app-text-lookup>
              </div>

              <div class="form-group itech-double-height v-resize">
                  <label for="displayTitle" class="required">Отображаемое название</label>
                  <label for="displayTitle" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                         [class.invalid]="isInvalid(contextFormGroup, 'displayTitle')">
                        <textarea type="text" id="displayTitle"
                                  placeholder="Отображаемое название" class="itech-control-xlarge itech-text-wall"
                                  formControlName="displayTitle"></textarea>
                      <app-validation-tooltip
                              [input]="contextFormGroup.controls['displayTitle']"></app-validation-tooltip>
                  </label>
              </div>

              <div class="form-group">
                  <label for="dateMode" class="required">Режим выбора даты</label>
                  <app-combo-lookup class="itech-control-xlarge" [contextControlId]="'dateMode'"
                                    [validationTooltipTopLeft]="true"
                                    [contextControlName]="'dateMode'" [lookupName]="'pst-report-date-mode'"
                                    [contextFormGroup]="contextFormGroup"></app-combo-lookup>
              </div>

              <div class="form-group">
                  <label for="kfhType">Тип хозяйствующего субъекта</label>
                  <app-combo-lookup class="itech-control-xlarge" [contextControlId]="'kfhType'"
                                    [validationTooltipTopLeft]="true"
                                    [contextControlName]="'kfhType'" [lookupName]="'pst-indicator-kfh-type'"
                                    [contextFormGroup]="contextFormGroup"></app-combo-lookup>
              </div>

              <div class="form-group">
                  <label for="displayWidth">Ширина графы, px</label>
                  <label for="displayWidth" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md tooltip-top-left"
                         [class.invalid]="isInvalid(contextFormGroup, 'displayWidth')">
                      <input type="text" id="displayWidth" placeholder="Ширина графы, px" class="itech-control-normal"
                             formControlName="displayWidth">
                      <app-validation-tooltip
                              [input]="contextFormGroup.controls['displayWidth']"></app-validation-tooltip>
                  </label>
              </div>

              <div class="form-group itech-group-whole-line">
                  <label for="displayVertical"></label>
                  <div class="checkbox">
                      <input type="checkbox" id="displayVertical" formControlName="displayVertical">
                      <label for="displayVertical">Отображать вертикально</label>
                  </div>
              </div>

              <div class="form-group itech-double-height v-resize">
                  <label for="paramsStr">Доп. параметры</label>
                  <label for="paramsStr" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                         [class.invalid]="isInvalid(contextFormGroup, 'paramsStr')">
                        <textarea type="text" id="paramsStr" class="itech-control-xlarge itech-text-wall"
                                  formControlName="paramsStr"></textarea>
                      <app-validation-tooltip
                              [input]="contextFormGroup.controls['paramsStr']"></app-validation-tooltip>
                  </label>
              </div>
              
              <div class="form-group itech-group-whole-line">
                  <label for="hidden"></label>
                  <div class="checkbox">
                      <input type="checkbox" id="hidden" formControlName="hidden">
                      <label for="hidden">Временно скрыть (не отображать)</label>
                  </div>
              </div>
              <div class="form-group itech-group-whole-line">
                  <button type="button" class="btn btn-warning-outline" (click)="deleteReportIndicator()">
                      <clr-icon shape="trash"></clr-icon>&nbsp;Удалить графу
                  </button>
              </div>
          </section>
      </div>
  `
})
export class ReportIndicatorMetaEditModalComponent implements OnInit, IModalBody<any, void> {

  contextFormGroup: any;

  isInvalid = FormHelper.isInvalid;

  constructor(public appNavigationService: AppNavigationService,
              private fb: FormBuilder,
              private pstReportService: PstReportService,
              private alertService: AlertService,
  ) {
  }

  ngOnInit() {
  }

  initModalBody(data: any) {
    this.contextFormGroup = this.fb.group({
      createNew: data.createNew,
      reportTypeId: data.reportTypeId,
      branchOrderNo: data.branchOrderNo,
      oldColNo: data.colNo,
      oldRowNo: data.rowNo,
      colNo: [data.colNo, Validators.compose([Validators.pattern(/^\d{1,2}$/), Validators.required])],
      rowNo: [data.rowNo, Validators.compose([Validators.pattern(/^\d{1,2}$/), Validators.required])],
      indicatorTypeId: [data.indicatorTypeId, Validators.required],
      displayTitle: [data.displayTitle, Validators.required],
      dateMode: [data.dateMode, Validators.required],
      displayWidth: [data.displayWidth, Validators.pattern(/^\d{1,3}$/)],
      displayVertical: data.displayVertical,
      kfhType: data.kfhType,
      hidden: data.hidden,
      updatedTime: data.updatedTime,
      paramsStr: JSON.stringify(data.params || {}),
    });
  }

  onModalAccept$(): Observable<ModalResult<any>> {
    const retVal = new Subject<ModalResult<any>>();

    const valToStore = this.contextFormGroup.value;
    valToStore.params = JSON.parse(this.contextFormGroup.get('paramsStr').value || undefined);

    const storeSubj = this.contextFormGroup.get('createNew').value
      ? this.pstReportService.createReportTypeIndicator(
        valToStore,
      )
      : this.pstReportService.updateReportTypeIndicator(
        valToStore,
        this.contextFormGroup.get('reportTypeId').value,
        this.contextFormGroup.get('branchOrderNo').value,
        this.contextFormGroup.get('oldColNo').value,
        this.contextFormGroup.get('oldRowNo').value,
      );

    storeSubj.subscribe({
      next:
        updated => {
          retVal.next(new ModalResult(true, updated));
        },
      error: () => {
        retVal.next(new ModalResult(false, undefined));
      }
    });

    return retVal;
  }

  deleteReportIndicator() {
    this.alertService.confirmModal('Вы действительно хотите удалить графу отчета?')
      .subscribe(() => {
        this.pstReportService.deleteReportTypeIndicator(
          this.contextFormGroup.value,
          this.contextFormGroup.get('reportTypeId').value,
          this.contextFormGroup.get('branchOrderNo').value,
          this.contextFormGroup.get('oldColNo').value,
          this.contextFormGroup.get('oldRowNo').value,
        ).subscribe(() => {
          this.alertService.success('Описание графы успешно удалено. ' +
            'Обновите отчет, чтобы посмотреть измененную форму.');
          this.appNavigationService.customModalOpened = false;
        });
      });
  }
}
