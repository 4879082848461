
    <ng-container [formGroup]="contextFormGroup" *ngIf="contextFormGroup
    && (!queryUserMode || queryUserMode['_par_' + parameterName + '_filter_mq'])">
      <label style="max-width: 17rem; width: 17rem" [style.background]="filtered?'linear-gradient(to right, #90ff90, white)':undefined">
        <ng-content></ng-content>
        :
        <div class="radio-inline">
          <input type="radio" id="{{'_par_' + parameterName + '_filter0'}}"
                 [formControlName]="'_par_' + parameterName + '_filter'" [value]="0">
          <label for="{{'_par_' + parameterName + '_filter0'}}">Не фильтр.:</label>
        </div>
        <div class="radio-inline" title="Спросить у пользователя" *ngIf="!queryUserMode">
          <input type="radio" id="{{'_par_' + parameterName + '_filter103'}}"
                 [formControlName]="'_par_' + parameterName + '_filter'" [value]="103">
          <label for="{{'_par_' + parameterName + '_filter103'}}">?</label>
        </div>
        <div class="radio-inline">
          <input type="radio" id="{{'_par_' + parameterName + '_filter1'}}"
                 [formControlName]="'_par_' + parameterName + '_filter'" [value]="1">
          <label for="{{'_par_' + parameterName + '_filter1'}}">Отсутств.:</label>
        </div>
        <div class="radio-inline">
          <input type="radio" id="{{'_par_' + parameterName + '_filter2'}}"
                 [formControlName]="'_par_' + parameterName + '_filter'" [value]="2">
          <label for="{{'_par_' + parameterName + '_filter2'}}">В диапаз.:</label>
        </div>
      </label>
      <label aria-haspopup="true" role="tooltip"
             class="tooltip tooltip-validation tooltip-md tooltip-right"
             [class.invalid]="isInvalid('_par_' + parameterName + '_from')">
        <input [formControlName]="'_par_' + parameterName + '_from'" [attr.type]="useDatePicker? 'date': 'text'"
               [attr.disabled]="contextFormGroup.controls['_par_' + parameterName + '_filter'].value == 2? undefined: true">
        <app-validation-tooltip
          [input]="contextFormGroup.controls['_par_' + parameterName + '_from']"></app-validation-tooltip>
      </label>
      <label aria-haspopup="true" role="tooltip"
             class="tooltip tooltip-validation tooltip-md tooltip-right"
             [class.invalid]="isInvalid('_par_' + parameterName + '_to')">
        <input [formControlName]="'_par_' + parameterName + '_to'" [attr.type]="useDatePicker? 'date': 'text'"
               [attr.disabled]="contextFormGroup.controls['_par_' + parameterName + '_filter'].value == 2? undefined: true">
        <app-validation-tooltip
          [input]="contextFormGroup.controls['_par_' + parameterName + '_to']"></app-validation-tooltip>
      </label>
    </ng-container>
  