import { Component, Input, OnChanges } from '@angular/core';
import { fromEvent } from 'rxjs';
import { ValueFormatterParams } from '@ag-grid-community/all-modules';
import { DataCachingService } from '../../logic/services/data-caching.service';

@Component({
  selector: 'app-grid-context-menu',
  template: `
      <div *ngIf="(gridApi && gridApi.isVisibleContextMenu && contextMenu) || false"
           class="app-grid-context-menu" style="position: fixed;z-index:100"
           [style.left.px]="positionLeft" [style.top.px]="positionTop">
        <clr-dropdown class="app-grid-context-menu">
          <clr-dropdown-menu class="app-grid-context-menu" clrPosition="right-top" *clrIfOpen="gridApi.isVisibleContextMenu">
            <h4 class="dropdown-header app-grid-context-menu">Выберите действие</h4>
            <ng-container *ngFor="let menu of contextMenu;index as i">
              <button type="button" *ngIf="!menu.subMenu" class="btn app-grid-context-menu-btn app-grid-context-menu"
                      (click)="clickItem(menu)" clrDropdownItem>
                  <clr-icon class="app-grid-context-menu" *ngIf="menu.iconName" [attr.shape]="menu.iconName"></clr-icon>
                  &nbsp;{{menu.name}}
              </button>
              <ng-container *ngIf="menu.subMenu">
                <clr-dropdown class="app-grid-context-menu">
                  <button type="button" class="btn app-grid-context-menu-btn app-grid-context-menu"
                          (click)="clickItem(menu)" clrDropdownTrigger>
                    <clr-icon class="app-grid-context-menu" *ngIf="menu.iconName" [attr.shape]="menu.iconName"></clr-icon>
                    &nbsp;{{menu.name}}
                  </button>
                  <clr-dropdown-menu class="app-grid-context-menu" clrPosition="right-top">
                    <ng-container *ngFor="let subMenuItem of menu.subMenu;index as j">
                      <div *ngIf="subMenuItem === 'divider'" class="dropdown-divider app-grid-context-menu"></div>
                      <ng-container *ngIf="subMenuItem !== 'divider'">
                        <button *ngIf="!subMenuItem.controlType" type="button"
                                class="btn app-grid-context-menu-btn app-grid-context-menu"
                                 (click)="clickItem(subMenuItem)" clrDropdownItem>
                          <clr-icon class="app-grid-context-menu" *ngIf="subMenuItem.iconName"
                                    [attr.shape]="subMenuItem.iconName"></clr-icon>
                          &nbsp;{{subMenuItem.name}}
                        </button>
                        <div *ngIf="subMenuItem.controlType && subMenuItem.controlType==='checkbox'" class="app-grid-context-menu">
                          <label for="submenu{{i}}{{j}}" class="app-grid-context-menu"></label>
                          <div class="checkbox app-grid-context-menu">
                            <input type="checkbox" class="app-grid-context-menu" id="submenu{{i}}{{j}}"
                                   [(ngModel)]="subMenuItem.checked" [ngModelOptions]="{standalone: true}"
                                   (ngModelChange)="subMenuCheckedChange(menu.subMenu, subMenuItem, menu.name)">
                            <label for="submenu{{i}}{{j}}" class="app-grid-context-menu">
                              {{subMenuItem.name + (!subMenuItem.rowIndexGroupSelected || +subMenuItem.rowIndexGroupSelected < 0
                                    ? '' : ' (' + (+subMenuItem.rowIndexGroupSelected + 1)  + ')')}}
                            </label>
                          </div>
                        </div>
                      </ng-container>
                    </ng-container>
                  </clr-dropdown-menu>
                </clr-dropdown>
              </ng-container>
            </ng-container>
          </clr-dropdown-menu>
        </clr-dropdown>
      </div>`
})
export class AgGridContextMenuComponent implements OnChanges {
  @Input() gridApi;
  @Input() positionLeft;
  @Input() positionTop;
  @Input() uniqueGridName;

  contextMenu;

  selectedCheckboxes = {};

  constructor(private dataCachingService: DataCachingService) {
    fromEvent(window, 'click').subscribe($event => {
      if (!this.gridApi) {
        return;
      }
      if ($event.target['className'] && typeof $event.target['className'] === 'string' &&
          $event.target['className'].includes('app-grid-context-menu')) {
        return;
      }
      this.hideContextMenu();
    });
  }

  ngOnChanges(): void {
    if (!this.gridApi) {
      return;
    }

    this.createContextMenu();
  }

  private createContextMenu() {
    this.contextMenu = [];

    const paramsExport = {
      allColumns: !this.gridApi['appCustomIncludeExportColumnIds'].length,
      fileName: this.gridApi['appCustomExportFileName'] ? this.gridApi['appCustomExportFileName'] : 'Экспорт таблицы',
      processCellCallback: cellParams => {
        // к каждой ячейке применяем её valueFormatter при наличии
        const colDef = cellParams.column.getColDef();
        if (colDef.valueFormatter) {
          const valueFormatterParams: ValueFormatterParams = {
            ...cellParams,
            data: cellParams.node.data,
            node: cellParams.node,
            colDef: cellParams.column.getColDef()
          };
          return colDef.valueFormatter(valueFormatterParams);
        }

        return cellParams.value;
      }
    };

    if (this.gridApi['appCustomIncludeExportColumnIds'].length) {
      paramsExport['columnKeys'] = this.gridApi['appCustomIncludeExportColumnIds'];
    }

    const groupingMenu = this.getGroupingContextMenu();

    if (groupingMenu) {
      this.contextMenu.push(groupingMenu);
    }

    this.contextMenu.push({
      name: 'Экспорт в Excel',
      iconName: 'export',
      action: () => this.gridApi.exportDataAsExcel(paramsExport),
    });
  }

  clickItem(menu: any) {
    if (menu.subMenu) {
      return;
    }

    if (menu.action && !menu.closedByClick) {
      menu.action();
      this.hideContextMenu();
    }
  }

  hideContextMenu() {
    if (this.gridApi) {
      this.gridApi['isVisibleContextMenu'] = false;
      this.createContextMenu();
    }
  }

  subMenuCheckedChange(subMenu: any[], subMenuItem: any, menuName: string) {
    if (!this.selectedCheckboxes[menuName]) {
      this.selectedCheckboxes[menuName] = [];
    }

    if (subMenuItem.checked) {
      this.selectedCheckboxes[menuName].push(subMenuItem.name);
    } else {
      const el = this.selectedCheckboxes[menuName].indexOf(subMenuItem.name);
      if (el >= 0) {
        this.selectedCheckboxes[menuName].splice(el, 1);
      }
    }

    this.refreshSubMenu(subMenu, menuName);
  }

  refreshSubMenu(subMenu: any[], menuName: string) {

    if (!this.selectedCheckboxes[menuName]) {
      return;
    }

    subMenu.forEach(el => {
      if (el.controlType && el.controlType === 'checkbox') {
        const ix = this.selectedCheckboxes[menuName].indexOf(el.name);
        if (ix >= 0) {
          el.rowIndexGroupSelected = ix.toString();
        } else {
          el.rowIndexGroupSelected = undefined;
        }
      }
    });
  }

  getGroupingContextMenu() {
    if (!this.gridApi || !this.gridApi.appCustomColDefs || !this.gridApi.appCustomColDefs.length) {
      return null;
    }

    this.selectedCheckboxes = {};
    const groupingContextMenu = [];

    (this.gridApi.appCustomColDefs as any[]).forEach(el => {

      if (el['rowGroupingSettings']) {

        const settings = el['rowGroupingSettings'];

        if (el.rowGroup) {
          if (!this.selectedCheckboxes['Группировать по']) {
            this.selectedCheckboxes['Группировать по'] = [];
          }
          this.selectedCheckboxes['Группировать по'].splice(el.rowGroupIndex, 0, settings.name);
        }

        const subMenu = {
          name: settings.name,
          action: () => this.getGroupingActionFunc(el, subMenu),
          controlType: 'checkbox',
          checked: el.rowGroup,
          closedByClick: false,
          context: el,
        };
        groupingContextMenu.push(subMenu);
      }
    });

    if (!groupingContextMenu.length) {
      return null;
    }

    this.refreshSubMenu(groupingContextMenu, 'Группировать по');

    groupingContextMenu.push('divider',
      {
        name: 'Применить',
        iconName: 'check',
        action: () => this.applyGrouping(groupingContextMenu.slice(0, groupingContextMenu.length - 3)),
      },
      {
        name: 'Сбросить',
        iconName: 'times',
        action: () => this.applyGrouping([]),
      });

    return {
      name: 'Группировать по',
      iconName: 'file-group',
      subMenu: groupingContextMenu,
    };
  }

  getGroupingActionFunc(columnByGroup: any, subMenu: any) {

    const renderParams = columnByGroup['rowGroupingSettings']['renderParams'];

    if (!renderParams) {
      return;
    }

    if (!subMenu.rowIndexGroupSelected || +subMenu.rowIndexGroupSelected < 0) {
      return;
    }

    this.gridApi.appCustomColDefs.forEach(el => {
      if (el.field === columnByGroup.field) {
        el.rowGroup = true;
        el.rowGroupIndex = +subMenu.rowIndexGroupSelected;
      }
    });

    if (!this.gridApi['appCustomRowRendererParams']) {
      this.gridApi['appCustomRowRendererParams'] = {};
    }
    this.gridApi['appCustomRowRendererParams'][+subMenu.rowIndexGroupSelected] = renderParams;
  }

  resetGrouping() {
    if (!this.gridApi.appCustomColDefs || !this.gridApi.appCustomColDefs.length) {
      return;
    }
    this.gridApi.appCustomColDefs.forEach(el => {
      el.rowGroup = false;
      el.rowGroupIndex = undefined;
    });
    this.gridApi['appCustomRowRendererParams'] = {};
  }

  applyGrouping(subMenu: any[]) {
    this.resetGrouping();

    subMenu.forEach(el => {
      if (el.checked && el.action) {
        el.action();
      }
    });

    this.gridApi.setColumnDefs([]);
    this.gridApi.setColumnDefs(this.gridApi.appCustomColDefs);

    this.dataCachingService.addToCache(this.uniqueGridName + '_RowRendererParams', '1', this.gridApi['appCustomRowRendererParams']);
    this.dataCachingService.addToCache(this.uniqueGridName + '_GroupingModel', '1', subMenu);
  }
}
