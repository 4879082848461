import { Component, EventEmitter, Input } from '@angular/core';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { InstitutionTicketDataService } from '../../../logic/services/institution-ticket-data.service';
import { AgGridLookupRendererComponent } from '../../../ui/controls/ag-grid-lookup-renderer.component';
import { EditAnimalTicketsComponent } from '../../edit-animal/tickets/edit-animal-tickets.component';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { AgGridButtonRendererComponent } from '../../../ui/controls/ag-grid-button-renderer.component';
import { TicketsPlanningUiService } from '../../edit-institution/tickets/tickets-planning-ui-service';

@Component({
  selector: 'app-agent-tickets',
  templateUrl: './edit-agent-tickets.component.html'
})
export class EditAgentTicketsComponent {

  @Input() model: any = {};

  ticketActionEmitter: EventEmitter<boolean> = undefined;

  private _id: number;
  @Input()
  get id(): number {
    return this._id;
  }

  set id(id: number) {
    if (this._id !== id) {
      this._id = id;
      this.refreshFormDataInternal();
    }
  }

  frameworkComponents: any = {
    lookupRenderer: AgGridLookupRendererComponent,
    buttonRenderer: AgGridButtonRendererComponent,
  };

  getRowStyle = EditAnimalTicketsComponent.getRowStyle;
  gridColumnDefs = [...EditAnimalTicketsComponent.getColumnDefs(),
    {
      headerName: 'Животное', width: 200,
      field: 'animalId',
      cellRenderer: 'lookupRenderer',
      cellRendererParams: (row) => {
        return {
          value: row.value,
          lookupName: !row.data.isAnimalExternal ? 'animal' : 'animal-external',
          isPipe: true,
        };
      }
    },
  ];

  constructor(private institutionTicketDataService: InstitutionTicketDataService,
              private dataCachingService: DataCachingService,
              private waitingOverlay: GlobalWaitingOverlayService,
              public navigationService: AppNavigationService,
              private alertService: AlertService,
              private ticketUiService: TicketsPlanningUiService) {
  }

  refreshFormData() {
    this.dataCachingService.removeCachedData('EditAgentTickets', this.id.toString());
    this.refreshFormDataInternal();
  }

  refreshFormDataInternal() {
    this.waitingOverlay.StartWaiting();

    const existing = this.dataCachingService.getCachedData('EditAgentTickets', this.id.toString());

    if (existing) {
      this.model = existing;
      this.waitingOverlay.EndWaiting();
    } else {
      this.institutionTicketDataService.searchByAgent(this.id)
        .subscribe({
          next: data => {

              data.sort((a, b) => b.startTime.localeCompare(b.startTime));
              this.model = {searchResults: data};
              this.dataCachingService.addToCache('EditAgentTickets', this.id.toString(), this.model);
              this.waitingOverlay.EndWaiting();
          },
          error: () => {
            this.waitingOverlay.EndWaiting();
          }
        });
    }
  }

  public clickTicket(event: any) {
    this.ticketActionEmitter = new EventEmitter<boolean>();
    this.ticketUiService.editTicket(event, true, this.ticketActionEmitter);
    this.ticketActionEmitter.subscribe(emit => {
      if (emit) {
        this.refreshFormData();
      }
    });
  }
}
