<div class="query-manager-details">
  <h1>
    Получение и просмотр результатов выборки
  </h1>

  <div class="itech-form-actions">
    <div>
      <button class="btn btn-secondary" (click)="refreshFormData()" [clrLoading]="queryRunnerUi.quering">
        <clr-icon shape="refresh"></clr-icon>&nbsp;Обновить
      </button>
    </div>
    <div>
      <div class="select">
        <select title="Выберите отображение" id="queryViewId" [(ngModel)]="model.queryViewId" #paymentDocumentId
                style="width:300px;max-width:300px">
          <option
            *ngFor="let item of lookupSourceService.getLookup('sys-query-view' + this.model.entityType.id.toString()) | async"
            [ngValue]="item.id"
            [attr.selected]="item.id==model.queryViewId?true: undefined">
            {{item.caption}}
          </option>
        </select>
      </div>
      <div class="itech-control-desc">Выберите отображение, нажмите "Обновить"</div>
    </div>
    <div>
      <button class="btn btn-success-outline" (click)="exportCsv()" [clrLoading]="queryRunnerUi.exporting">
        <clr-icon shape="export"></clr-icon>&nbsp;Выгрузить в CSV
      </button>
    </div>
  </div>

  <div class="form" style="overflow-y: hidden">
    <app-grid [pagination]="true" [paginationAutoPageSize]="true" [rowData]="model.query.results"
              [pinnedTopRowData]="model.query.aggSumRow"
              (rowClicked)="queryRunnerUi.navigateRow(model, $event.data)" [columnDefs]="model.query.dataColumns"
              [uniqueGridName]="'QueryDetailsResults'" [exportFileName]="'Результаты выборки'"></app-grid>
  </div>
</div>
