import { Component, OnInit } from '@angular/core';
import { MetadataService } from '../../../logic/services/metadata.service';
import { combineLatest, noop } from 'rxjs/index';
import { DateHelper } from '../../../helpers/date-helper';
import { StringHelper } from '../../../helpers/string-helper';
import { DatePipe } from '@angular/common';
import { AlertService } from '../../../ui/infrastructure/alert.service';

@Component({
  templateUrl: './calendar-browse.component.html',
  styleUrls: ['./calendar-browse.component.css']
})
export class CalendarBrowseComponent implements OnInit {

  year: number;
  months: any[];
  availableYears: any;

  constructor(private metadataService: MetadataService,
              private alertService: AlertService) {
  }

  private static buildMonthsModel(holidaysMap, workdaysMap, year) {

    const months = [];

    const transformMonth = new DatePipe('ru');

    for (let monthNo = 0; monthNo < 12; monthNo++) {
      const monthStart = new Date(year, monthNo, 1);

      const month = {
        caption: StringHelper.capitalizeFirst(transformMonth.transform(monthStart, 'LLLL')),
        // 6 недель у нас максимум: по ним и распределяем
        weeks: [{days: []}, {days: []}, {days: []}, {days: []}, {days: []}, {days: []}]
      };

      const daysCount = DateHelper.daysInMonth(year, monthNo);

      for (let dayNo = 0; dayNo < daysCount; dayNo++) {
        const weekNo = Math.floor((dayNo + ((monthStart.getDay() + 6) % 7)) / 7);
        const dayInWeekNo = (dayNo + ((monthStart.getDay() + 6) % 7)) % 7;

        const week = month.weeks[weekNo];

        if (!week.days || !week.days.length) {
          week.days = [undefined, undefined, undefined, undefined, undefined, undefined, undefined];
        }

        const dayDate = new Date(year, monthNo, dayNo + 1);
        const weekend = dayInWeekNo === 5 || dayInWeekNo === 6;
        const holiday = (weekend && !workdaysMap[StringHelper.getISODate(dayDate)]) ||
          holidaysMap[StringHelper.getISODate(dayDate)];

        week.days[dayInWeekNo] = {
          date: dayDate,
          day: dayNo + 1,
          weekend: weekend,
          holiday: holiday
        };
      }

      months.push(month);
    }
    return months;
  }

  ngOnInit(): void {
    this.year = new Date().getFullYear();

    this.availableYears = [];
    for (let i = 0; i < 12; i++) {
      this.availableYears.push(this.year + 2 - i);
    }

    this.refreshFormData();
  }

  private refreshFormData() {
    combineLatest([this.metadataService.getExtraHolidays(this.year), this.metadataService.getExtraWorkdays(this.year)])
      .subscribe({
        next: ([holidaysMap, workdaysMap]) => {
          this.months = CalendarBrowseComponent.buildMonthsModel(holidaysMap, workdaysMap, this.year);
        }
      });
  }

  dayClick(day: any) {
    const prompt = day.holiday ? 'Сделать рабочим днем?' : 'Сделать выходным днем?';

    this.alertService.confirmModal(prompt).subscribe({
      next: () => {
        const onSuccess = () => {
          day.holiday = !day.holiday;
        };

        if (day.holiday && day.weekend) {
          this.metadataService.addWorkday(day.date).subscribe({next: onSuccess});
        } else if (!day.holiday && day.weekend) {
          this.metadataService.deleteWorkday(day.date).subscribe({next: onSuccess});
        } else if (day.holiday && !day.weekend) {
          this.metadataService.deleteHoliday(day.date).subscribe({next: onSuccess});
        } else if (!day.holiday && !day.weekend) {
          this.metadataService.addHoliday(day.date).subscribe({next: onSuccess});
        }
      }
    });
  }

  changeYear(year: number) {
    this.year = year;
    this.months = undefined;
    this.refreshFormData();
  }
}
