import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ClarityModule } from '@clr/angular';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RDatePipe } from './pipes/r-date.pipe';
import { RTimePipe } from './pipes/r-time.pipe';
import { AppValidationTooltipComponent } from './controls/app-validation-tooltip.component';
import { AppComboLookupComponent } from './controls/app-combo-lookup.component';
import { AppConfirmModalComponent } from './controls/app-confirm-modal.component';
import { AppTreeNodesComponent } from './controls/app-tree-nodes.component';
import { RMonthPipe } from './pipes/r-month.pipe';
import { AddressComponent } from './controls/address.component';
import { RMonthSPipe } from './pipes/r-month-s.pipe';
import { AppCustomModalHostDirective } from './controls/app-custom-modal-host.directive';
import { AppReportsButtonComponent } from './controls/app-reports-button.component';
import { AppSelectInstitutionServiceComponent } from './controls/app-select-institution-service.component';
import { AppChooseTreeNodesComponent } from './controls/app-choose-tree-nodes.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { AppCheckboxSelectComponent } from './controls/app-checkbox-select.component';
import { AppDatetimePickerComponent } from './controls/app-datetime-picker.component';
import { DocValidationErrorsComponent } from './controls/doc-validation-errors.component';
import { AppTextLookupComponent } from './controls/app-text-lookup.component';
import { JobRunnerModalComponent } from './controls/job-runner-modal.component';
import { AppComboMultilookupComponent } from './controls/app-combo-multilookup.component';
import { AppLoggingModalComponent } from './controls/app-logging-modal.component';
import { AppDateComboComponent } from './controls/app-date-combo.component';
import { AppDateStringFieldsComponent } from './controls/app-date-string-fields.component';
import { AppSingleFileComponent } from './controls/app-single-file.component';
import { AppCheckboxSelectWithParentComponent } from './controls/app-checkbox-select-with-parent.component';
import { AppTrappedQuarantineSignpostComponent } from './controls/app-trapped-quarantine-signpost.component';
import { AppLinkButtonComponent } from './controls/app-link-button.component';
import { AppComboInlineMultilookupComponent } from './controls/app-combo-inline-multilookup.component';
import { AppGridComponent } from './controls/app-grid.component';
import { AgGridModule } from '@ag-grid-community/angular';
import { AgGridContextMenuComponent } from './controls/ag-grid-context-menu.component';
import { AddressQuarantineTerritoryComponent } from './controls/address-quarantine-territory.component';
import { AppGridEditableComponent } from './controls/app-grid-editable.component';
import { LookupSourcePipe } from '../logic/pipes/lookup-source.pipe';
import { AppProductsReportsButtonComponent } from './controls/app-products-reports-button.component';
import { AppInputTextComponent } from './controls/app-input-text.component';
import { UserTagsAutofillEditModalComponent } from './controls/user-tags-autofill-edit-modal.component';
import { AuthMercuryComponent } from './controls/auth-mercury.component';
import { AppBtnSyncWebComponent } from './controls/app-btn-sync-web.component';

@NgModule({
  imports: [
    CommonModule,
    ClarityModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ClickOutsideModule,
    AgGridModule,
  ],

  declarations: [
    LookupSourcePipe,
    RDatePipe,
    RMonthPipe,
    RMonthSPipe,
    RTimePipe,
    AppValidationTooltipComponent,
    AppComboLookupComponent,
    AppConfirmModalComponent,
    AppTreeNodesComponent,
    AddressComponent,
    AddressQuarantineTerritoryComponent,
    AppCustomModalHostDirective,
    AppReportsButtonComponent,
    AppProductsReportsButtonComponent,
    AppSelectInstitutionServiceComponent,
    AppChooseTreeNodesComponent,
    AppCheckboxSelectComponent,
    AppCheckboxSelectWithParentComponent,
    AppDatetimePickerComponent,
    AppDateComboComponent,
    AppDateStringFieldsComponent,
    DocValidationErrorsComponent,
    AppTextLookupComponent,
    JobRunnerModalComponent,
    AppComboMultilookupComponent,
    AppLoggingModalComponent,
    AppSingleFileComponent,
    AppTrappedQuarantineSignpostComponent,
    AppLinkButtonComponent,
    AppComboInlineMultilookupComponent,
    AppGridComponent,
    AgGridContextMenuComponent,
    AppGridEditableComponent,
    AppInputTextComponent,
    UserTagsAutofillEditModalComponent,
    AuthMercuryComponent,
    AppBtnSyncWebComponent,
  ],

  exports: [
    LookupSourcePipe,
    RDatePipe,
    RMonthPipe,
    RMonthSPipe,
    RTimePipe,
    AppValidationTooltipComponent,
    AppComboLookupComponent,
    AppConfirmModalComponent,
    AppTreeNodesComponent,
    AddressComponent,
    AddressQuarantineTerritoryComponent,
    AppReportsButtonComponent,
    AppSelectInstitutionServiceComponent,
    AppChooseTreeNodesComponent,
    AppCheckboxSelectComponent,
    AppCheckboxSelectWithParentComponent,
    AppCustomModalHostDirective,
    AppDatetimePickerComponent,
    AppDateComboComponent,
    AppDateStringFieldsComponent,
    DocValidationErrorsComponent,
    AppTextLookupComponent,
    JobRunnerModalComponent,
    AppComboMultilookupComponent,
    AppLoggingModalComponent,
    AppLinkButtonComponent,
    AppSingleFileComponent,
    AppTrappedQuarantineSignpostComponent,
    AppComboInlineMultilookupComponent,
    AppGridComponent,
    AgGridContextMenuComponent,
    AppGridEditableComponent,
    AppProductsReportsButtonComponent,
    AppInputTextComponent,
    UserTagsAutofillEditModalComponent,
    AuthMercuryComponent,
    AppBtnSyncWebComponent,
  ],

})
export class UiModule {
}
