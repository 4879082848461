<div *ngIf="securityService.isAccess('quarantine') | async">
  <div>
    <clr-tabs>
      <clr-tab>
        <button clrTabLink>Приказ № 476</button>
        <ng-template [(clrIfActive)]="model.orderTabActive" (clrIfActiveChange)="changeTab()">
          <clr-tab-content>
            <div class="edit-page-tab" style="margin-left:0">
              <div class="itech-common-wrap" id="interceptNoAccess">
                <div id="agent-common-content" style="bottom:0">
                  <div class="itech-doc-container">
                    <div class="itech-doc-content">
                      <div>
                        <h4 style="max-width:850px;text-align:center;margin:0 0 30px 0;">
                          Перечень заразных, в том числе особо опасных, болезней,
                          по которым могут устанавливаться ограничительные мероприятия (карантин)
                        </h4>
                      </div>
                      <table class="table table-noborder" style="max-width:850px;">
                        <thead>
                        <tr>
                          <th style="width:360px;padding:10px 0;">Заболевание</th>
                          <th style="width:320px;padding:10px 0;">Действия</th>
                          <th style="width:80px;padding:10px 0;">
                            За год
                            <a role="tooltip" aria-haspopup="true" class="tooltip tooltip-sm tooltip-top-right">
                              <clr-icon shape="info-circle" size="md"></clr-icon>
                              <span class="tooltip-content">В течение года был статус 'Введен'</span>
                            </a>
                          </th>
                          <th style="width:90px;padding:10px 0;">
                            Текущие
                            <a role="tooltip" aria-haspopup="true" class="tooltip tooltip-sm tooltip-top-right">
                              <clr-icon shape="info-circle" size="md"></clr-icon>
                              <span class="tooltip-content">В настоящее время статус 'Введен'</span>
                            </a>
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        <ng-container *ngFor="let disease of diseases; index as i">
                          <tr>
                            <td class="left">{{disease.code}}. {{disease.caption}}</td>
                            <td class="left" style="display:flex">
                              <div>
                                <a class="itech-link align-bottom" style="padding-right: 5px;color:#472cdc" (click)="createQuarantine(disease.id)">
                                  Создать карантин
                                </a>
                                &nbsp;&nbsp;&nbsp;
                                <a class="itech-link align-bottom" style="padding-right: 5px;color:#472cdc"
                                   *ngIf="disease.liquidationRegulationsFileUri"
                                   (click)="previewFile(disease.liquidationRegulationsFileUri)">
                                  Правила по ликвидации</a>
                              </div>
                            </td>
                            <td>
                              <ng-container *ngIf="+disease.quarantineYearCount > 0; else countYearZero">
                                <a class="itech-link align-bottom" style="font-weight:bold"
                                   (click)="navigateToSearch(disease.id, 1)">{{disease.quarantineYearCount}}</a>
                              </ng-container>
                              <ng-template #countYearZero>
                                <span style="font-weight:bold">{{disease.quarantineYearCount}}</span>
                              </ng-template>
                            </td>
                            <td>
                              <ng-container *ngIf="+disease.quarantineCurrentCount > 0; else countCurrentZero">
                                <a class="itech-link align-bottom" style="font-weight:bold;color:red"
                                   (click)="navigateToSearch(disease.id, 2)">{{disease.quarantineCurrentCount}}</a>
                              </ng-container>
                              <ng-template #countCurrentZero>
                                <span style="font-weight:bold;color:red">{{disease.quarantineCurrentCount}}</span>
                              </ng-template>
                            </td>
                          </tr>
                        </ng-container>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          </clr-tab-content>
        </ng-template>
      </clr-tab>
      <clr-tab>
        <button clrTabLink>Поиск</button>
        <ng-template [(clrIfActive)]="model.searchTabActive" (clrIfActiveChange)="changeTab()">
          <clr-tab-content>
            <div class="edit-page-tab" style="margin-left:0">
              <app-quarantine-search-results></app-quarantine-search-results>
            </div>
          </clr-tab-content>
        </ng-template>
      </clr-tab>
    </clr-tabs>
  </div>
</div>
