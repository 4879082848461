<ng-container [formGroup]="contextFormGroup">

  <app-address *ngIf="showAddressControl" [contextFormGroup]="contextFormGroup.get('address')"
               [required]="true"></app-address>

  <div class="form-group itech-group-whole-line" *ngIf="showWithReloadControl">
    <div class="checkbox-inline">
      <input type="checkbox" id="{{rndToken}}withReload" formControlName="withReload" (click)="cdr.detectChanges()">
      <label for="{{rndToken}}withReload">С перегрузкой</label>
    </div>
  </div>

  <div class="form-group" *ngIf="contextFormGroup.controls['withReload'].value">
    <label for="{{rndToken}}transportationKindId" class="required">Вид транспорта</label>
    <app-combo-lookup [validationTooltipRight]="true" class="itech-control-large"
                      [contextControlId]="rndToken + 'transportationKindId'"
                      [contextControlName]="'transportationKindId'" [disabledChoices]="[6,7]"
                      [lookupName]="'transportation-kind'" [contextFormGroup]="contextFormGroup"></app-combo-lookup>
  </div>

  <div class="form-group" *ngIf="contextFormGroup.controls['withReload'].value && +contextFormGroup.get('transportationKindId').value === 1">
    <label for="{{rndToken}}countryId" class="required">Страна регистрации ТС</label>
    <app-combo-lookup [validationTooltipRight]="true" class="itech-control-large"
                      [contextControlId]="rndToken + 'countryId'"
                      [contextControlName]="'countryId'"
                      [lookupName]="'country'" [contextFormGroup]="contextFormGroup"></app-combo-lookup>
  </div>

  <div class="form-group" *ngIf="contextFormGroup.controls['withReload'].value">
    <label for="{{rndToken}}vesselNo" class="required">{{getTransportationFieldsTitle()}}</label>
    <label for="{{rndToken}}vesselNo" aria-haspopup="true" role="tooltip"
           class="tooltip tooltip-validation tooltip-md"
           [class.invalid]="isInvalid(contextFormGroup, 'vesselNo')">
      <app-input-text [contextId]="rndToken + 'vesselNo'" [classNames]="'itech-control-normal'"
                      [formControlObj]="contextFormGroup.get('vesselNo')" [htmlType]="1"></app-input-text>
      <app-validation-tooltip [input]="contextFormGroup.controls['vesselNo']"></app-validation-tooltip>
    </label>
    <label *ngIf="+contextFormGroup.controls['transportationKindId'].value === 1 && contextFormGroup.controls['countryId'].value">
      <div title="{{getVesselNoHelp()}}">
        <clr-icon shape="help" class="cert-icon"></clr-icon>
      </div>
    </label>
    <label *ngIf="getTrailerNoFieldVisible()" for="{{rndToken}}trailerNo" aria-haspopup="true" role="tooltip"
           class="tooltip tooltip-validation tooltip-md"
           [class.invalid]="isInvalid(contextFormGroup, 'trailerNo')">
      <app-input-text [contextId]="rndToken + 'trailerNo'" [classNames]="'itech-control-normal'"
                      [formControlObj]="contextFormGroup.get('trailerNo')" [htmlType]="1"></app-input-text>
      <app-validation-tooltip [input]="contextFormGroup.controls['trailerNo']"></app-validation-tooltip>
    </label>
    <label *ngIf="getContainerNoFieldVisible()" for="{{rndToken}}containerNo" aria-haspopup="true" role="tooltip"
           class="tooltip tooltip-validation tooltip-md"
           [class.invalid]="isInvalid(contextFormGroup, 'containerNo')">
      <app-input-text [contextId]="rndToken + 'containerNo'" [classNames]="'itech-control-normal'"
                      [formControlObj]="contextFormGroup.get('containerNo')" [htmlType]="1"></app-input-text>
      <app-validation-tooltip [input]="contextFormGroup.controls['containerNo']"></app-validation-tooltip>
    </label>
  </div>

  <div class="form-group" [formGroup]="contextFormGroup" *ngIf="showAddressControl">
    <label for="pointCaption">Наименование точки</label>
    <label for="pointCaption" aria-haspopup="true" role="tooltip"
           class="tooltip tooltip-validation tooltip-md"
           [class.invalid]="isInvalid(contextFormGroup, 'pointCaption')">
      <app-input-text [contextId]="rndToken + 'pointCaption'" [classNames]="'itech-control-xlarge'"
                      [formControlObj]="contextFormGroup.get('pointCaption')" [htmlType]="1"></app-input-text>
      <app-validation-tooltip [input]="contextFormGroup.controls['pointCaption']"></app-validation-tooltip>
    </label>
  </div>

</ng-container>
