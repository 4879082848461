<div class="itech-doc-container" *ngIf="contextFormGroup" xmlns="http://www.w3.org/1999/html">
  <div class="itech-doc-content" style="display:flex">
    <div class="itech-doc-image">
      <clr-icon shape="piggy-bank"></clr-icon>
      <div style="line-height:1">
        <ng-container *ngIf="!isExternalAnimal; else externalAnimalBadge">
          <app-trapped-quarantine-signpost style="margin-top:20px;"
                                           [trappedQuarantine]="contextFormGroup.get('trappedToQuarantine').value"
                                           [objectCaption]="'Животное'">
          </app-trapped-quarantine-signpost>
          <ng-container *ngIf="contextFormGroup.get('tempId').value">
            <div>
              <a class="itech-inline-link-btn" style="margin-top:20px;font-size:12px;color:#0079b8;cursor:pointer;"
                 (click)="navigationService.performAnimalEditing(contextFormGroup.get('tempId').value, null, null, true)">
                <clr-icon shape="warning-standard" size="60"></clr-icon>
                Требуется подтвердить животное
              </a>
            </div>
          </ng-container>
        </ng-container>
        <ng-template #externalAnimalBadge>
          <clr-icon shape="hourglass" style="margin-top:20px;color:#fb8c00" size="60" title="Неподтверждённая карточка"></clr-icon>
        </ng-template>
      </div>
    </div>
    <div class="compact form itech-highlighted-sections" [formGroup]="contextFormGroup">

      <app-doc-validation-errors [validationErrors]="contextFormGroup.docLevelServerSideValidationErrors">
      </app-doc-validation-errors>

      <section class="form-block itech-block-medium">
        <h3>Общая информация {{contextFormGroup.get('idNumber').value}}
          <ng-container *ngFor="let productInfo of contextFormGroup.get('animalSubtypesWithProduct').value; index as i">
            <button *ngIf="productInfo" style="border: none" title="{{getCertTitle(productInfo)}}"
                    (click)="appNavigationService.performProductEditing(productInfo.productId)">
              <clr-icon shape="certificate" class="cert-icon"></clr-icon>
            </button>
          </ng-container>
        </h3>

        <div class="form-group itech-group-whole-line">
          <label></label>
          <div class="itech-control-desc" [class.itech-desc-error]="contextFormGroup.get('deletedTime').value">
            Карточка создана:
            {{contextFormGroup.get('createdTime').value | rtime}},
            {{contextFormGroup.get('createdUser').value | lookup: 'user' | async}}
          </div>
          <div *ngIf="contextFormGroup.get('deletedTime').value" class="itech-control-desc itech-desc-error">
            Карточка удалена:
            {{contextFormGroup.get('deletedTime').value | rtime}},
            {{contextFormGroup.get('deletedUser').value | lookup: 'user' | async}}
          </div>
        </div>

        <div class="form-group itech-group-whole-line" *ngIf="contextFormGroup.controls['mercuryJournalNo'].value">
          <label></label>
          <div class="itech-control-desc">
            Запись журнала в Меркурии: {{contextFormGroup.get('mercuryJournalNo').value}}
            (guid: {{contextFormGroup.get('mercuryUuid').value}})
          </div>
        </div>

        <div class="form-group itech-group-whole-line">
          <label for="animalGroup"></label>
          <div class="checkbox" [class.disabled]="isExternalAnimal || contextFormGroup.get('idNumber').value">
            <input type="checkbox" id="animalGroup" formControlName="animalGroup"
                   [attr.disabled]="isExternalAnimal || contextFormGroup.get('idNumber').value">
            <label for="animalGroup">Животное учитывается в составе группы</label>
          </div>
        </div>

        <div class="form-group">
          <label for="animalTypeId" class="required">Вид животного</label>
          <app-text-lookup [validationTooltipRight]="true" class="itech-control-xlarge"
                            [contextControlId]="'animalTypeId'" [sorted]="true"
                            [contextControlName]="'animalTypeId'"
                            [disabled]="isExternalAnimal || contextFormGroup.get('idNumber').value"
                            [lookupName]="'animal-type'" [contextFormGroup]="contextFormGroup"></app-text-lookup>
        </div>
        <div class="form-group" *ngIf="!isSingleAnimal() && counts.length > 1">
          <label></label>
          <div class="itech-control-desc">
            Нельзя редактировать вид животного, если по нему уже вносились мероприятия
          </div>
        </div>

        <div class="form-group" *ngIf="contextFormGroup.get('animalTypeId').value && isSingleAnimal()">
          <label for="animalSubTypeId" class="required">Подвид</label>
          <app-text-lookup [validationTooltipRight]="true" class="itech-control-xlarge"
                            [contextControlId]="'animalSubTypeId'" [sorted]="true"
                            [disabled]="isExternalAnimal || contextFormGroup.get('idNumber').value"
                            [contextControlName]="'animalSubTypeId'"
                            [lookupName]="'animal-subtype' + contextFormGroup.get('animalTypeId').value"
                            [contextFormGroup]="contextFormGroup"></app-text-lookup>
        </div>


        <ng-container *ngIf="contextFormGroup.get('animalTypeId').value && !isSingleAnimal()">
          <ng-container *ngIf="contextFormGroup.get('id').value">
            <ng-container *ngFor="let count of counts; index as i">
              <div class="form-group">
                <label>{{i?'':'Текущее количество животных'}}</label>
                <div class="itech-text-field itech-control-xlarge">
                  {{count.animalSubTypeId|lookup:'animal-subtype' + count.animalTypeId|async}}:
                  {{count.count}} ({{count.gender|lookup:'gender-animal-group'|async}})
                </div>
              </div>
            </ng-container>
            <div class="form-group">
              <label></label>
              <div class="itech-control-desc">
                <a class="itech-link" (click)="navigateToEventsPage()">Для редактирования количества животных воспользуйтесь
                  разделом "Мероприятия/События"</a>
              </div>
            </div>
            <ng-container>
              <div class="form-group">
                <label>Дата рождения</label>
                <app-date-combo [contextFormGroup]="contextFormGroup" [isRequiredDayAndHour]="isRequiredDateField"
                                [withTime]="false" [withApproximateDate]="false"
                                [controlNameDate1]="'birthDate'" [controlNameDate2]="'birthDate2'"></app-date-combo>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!contextFormGroup.get('id').value">
            <ng-container *ngFor="let count of newCardCounts.controls; index as i">

              <div class="form-group" [formGroup]="count">
                <label>{{i?'':'Подвиды'}}</label>
                <app-combo-lookup class="itech-control-large" [contextControlId]="'animalSubTypeId' + i"
                                  [contextControlName]="'animalSubTypeId'" [required]="false" [sorted]="true"
                                  [lookupName]="'animal-subtype' + contextFormGroup.get('animalTypeId').value"
                                  [contextFormGroup]="count"></app-combo-lookup>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <label for="count{{i}}" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                       [class.invalid]="isInvalid(count, 'countDiff')">
                  <input type="text" id="count{{i}}" placeholder="Кол-во" class="itech-control-small"
                         formControlName="countDiff">
                  <app-validation-tooltip [input]="count.controls['countDiff']"></app-validation-tooltip>
                </label>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <app-combo-lookup class="itech-control-medium" [contextControlId]="'gender' + i"
                                  [contextControlName]="'gender'" [lookupName]="'gender-animal-group'"
                                  [contextFormGroup]="count"></app-combo-lookup>
              </div>
            </ng-container>
            <div class="form-group">
              <label for="birthDateDate">
                Дата рождения
              </label>
              <app-date-combo [contextFormGroup]="contextFormGroup" [isRequiredDayAndHour]="isRequiredDateField"
                              [withTime]="false" [withApproximateDate]="false"
                              [controlNameDate1]="'birthDate'" [controlNameDate2]="'birthDate2'"></app-date-combo>
            </div>

          </ng-container>
        </ng-container>

        <div class="form-group">
          <label for="animalReasonId" class="required">Цель содержания</label>
          <app-combo-lookup [validationTooltipRight]="true" class="itech-control-xlarge"
                            [contextControlId]="'animalReasonId'"
                            [contextControlName]="'animalReasonId'"
                            [disabled]="isExternalAnimal || contextFormGroup.get('idNumber').value"
                            [lookupName]="'animal-reason'"
                            [contextFormGroup]="contextFormGroup"></app-combo-lookup>
        </div>

        <div class="form-group" *ngIf="contextFormGroup.get('animalReasonId').value == 2">
          <label for="animalReasonText">Описание цели содержания</label>
          <label for="animalReasonText" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                 [class.invalid]="isInvalid(contextFormGroup, 'animalReasonText')">
            <input type="text" id="animalReasonText" class="itech-control-xlarge" formControlName="animalReasonText">
            <app-validation-tooltip [input]="contextFormGroup.controls['animalReasonText']"></app-validation-tooltip>
          </label>
        </div>

      <div class="form-group" *ngIf="isSingleAnimal()">
        <label for="pin" class="required">Бирка&nbsp;{{placeholderPinTemplate?'['+placeholderPinTemplate+']':''}}</label>
        <label for="pin" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
               [class.invalid]="isInvalid(contextFormGroup, 'pin')">
          <input type="text" id="pin" [placeholder]="placeholderPinTemplate?placeholderPinTemplate:'Бирка'"
                 class="itech-control-xlarge" formControlName="pin" [attr.disabled]="isExternalAnimal?true:undefined">
          <app-validation-tooltip [input]="contextFormGroup.controls['pin']"></app-validation-tooltip>
        </label>
      </div>

        <div class="form-group itech-double-height">
          <label for="caption" [class.required]="!isSingleAnimal()">{{isSingleAnimal()? 'Кличка': 'Наименование'}}</label>
          <label for="caption" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                 [class.invalid]="isInvalid(contextFormGroup, 'caption')">
                <textarea type="text" id="caption" [attr.disabled]="isExternalAnimal?true:undefined"
                          placeholder="Введите кличку животного или наименование группы животных"
                          class="itech-control-xlarge" formControlName="caption">
                </textarea>
            <app-validation-tooltip [input]="contextFormGroup.controls['caption']"></app-validation-tooltip>
          </label>
        </div>

        <div class="form-group" *ngIf="contextFormGroup.get('animalTypeId').value && isSingleAnimal()">
          <label for="animalBreedId">Порода</label>
          <app-text-lookup [validationTooltipRight]="true" class="itech-control-xlarge"
                           [contextControlId]="'animalBreedId'"
                           [contextControlName]="'animalBreedId'"
                           [lookupName]="'animal-breed' + contextFormGroup.get('animalTypeId').value"
                           [disabled]="isExternalAnimal"
                           [contextFormGroup]="contextFormGroup"></app-text-lookup>
        </div>

        <div class="form-group" *ngIf="isSingleAnimal()">
          <label for="color" class="required">Масть / пол</label>
          <label for="color" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                 [class.invalid]="isInvalid(contextFormGroup, 'color')">
            <input type="text" id="color" placeholder="Масть" class="itech-control-large"
                   formControlName="color" [attr.disabled]="isExternalAnimal?true:undefined">
            <app-validation-tooltip [input]="contextFormGroup.controls['color']"></app-validation-tooltip>
          </label>
          <app-combo-lookup class="itech-control-normal" [contextControlId]="'gender'"
                            [disabled]="isExternalAnimal"
                            [contextControlName]="'gender'" [lookupName]="'gender-animal'"
                            [contextFormGroup]="contextFormGroup"></app-combo-lookup>
        </div>

        <div class="form-group" *ngIf="isSingleAnimal()">
          <label for="birthDate" class="required">
            Дата рождения
          </label>
          <label for="birthDate" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md"
                 [class.invalid]="isInvalid(contextFormGroup, 'birthDate')">
            <input type="date" id="birthDate" class="itech-control-normal"
                   formControlName="birthDate" [attr.disabled]="isExternalAnimal?true:undefined">
            <app-validation-tooltip [input]="contextFormGroup.controls['birthDate']"></app-validation-tooltip>
          </label>
          <label for="age">
            {{getCaptionAge()}}
          </label>
        </div>

        <div class="form-group" *ngIf="isSingleAnimal()">
          <label for="weight">Вес, кг</label>
          <label for="weight" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                 [class.invalid]="isInvalid(contextFormGroup, 'weight')">
            <input type="text" id="weight" placeholder="0.00" class="itech-control-normal"
                   [attr.disabled]="isExternalAnimal?true:undefined"
                   formControlName="weight" (keypress)="processMoneyKeypress($event)">
           <app-validation-tooltip [input]="contextFormGroup.controls['weight']"></app-validation-tooltip>
          </label>
        </div>

        <div class="form-group" *ngIf="isSingleAnimal()">
          <label for="height">Рост, см</label>
          <label for="height" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                 [class.invalid]="isInvalid(contextFormGroup, 'height')">
            <input type="text" id="height" placeholder="0.00" class="itech-control-normal"
                   [attr.disabled]="isExternalAnimal?true:undefined"
                   formControlName="height" (keypress)="processMoneyKeypress($event)">
            <app-validation-tooltip [input]="contextFormGroup.controls['height']"></app-validation-tooltip>
          </label>
        </div>

        <div class="form-group" *ngIf="isSingleAnimal()">
          <label for="deathDate" [class.highlight-warn]="contextFormGroup.controls['deathDate'].value">
            Дата / причина выбытия
          </label>
          <label for="deathDate" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md"
                 [class.invalid]="isInvalid(contextFormGroup, 'deathDate')">
            <input type="date" id="deathDate" class="itech-control-normal"
                   formControlName="deathDate" [attr.disabled]="isExternalAnimal?true:undefined">
            <app-validation-tooltip [input]="contextFormGroup.controls['deathDate']"></app-validation-tooltip>
          </label>
          <app-combo-lookup [validationTooltipRight]="true" class="itech-control-large"
                            [contextControlId]="'deathReasonId'"
                            [contextControlName]="'deathReasonId'"
                            [lookupName]="'death-reason'"
                            [disabled]="isExternalAnimal"
                            [contextFormGroup]="contextFormGroup"></app-combo-lookup>
        </div>

        <div class="form-group">
          <label for="originCountry">Страна происхождения</label>
          <app-combo-lookup [validationTooltipRight]="true" class="itech-control-xlarge"
                            [contextControlId]="'originCountry'" [sorted]="true"
                            [contextControlName]="'originCountry'"
                            [lookupName]="'country'"
                            [contextFormGroup]="contextFormGroup"></app-combo-lookup>
        </div>

        <div class="form-group" *ngIf="isSingleAnimal()">
          <label for="identityDocSerial">
            Паспорт животного
          </label>
          <label for="identityDocSerial" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md tooltip-top-left"
                 [class.invalid]="isInvalid(contextFormGroup, 'identityDocSerial')">
            <input type="text" id="identityDocSerial" placeholder="Серия" class="itech-control-small"
                   autocomplete="off" formControlName="identityDocSerial">
            <app-validation-tooltip [input]="contextFormGroup.controls['identityDocSerial']"></app-validation-tooltip>
          </label>
          <label for="identityDocNumber" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md tooltip-top-left"
                 [class.invalid]="isInvalid(contextFormGroup, 'identityDocNumber')">
            <input type="text" id="identityDocNumber" placeholder="Номер" class="itech-control-small"
                   autocomplete="off" formControlName="identityDocNumber">
            <app-validation-tooltip [input]="contextFormGroup.controls['identityDocNumber']"></app-validation-tooltip>
          </label>
          <label for="identityDocDate" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md tooltip-top-left"
                 [class.invalid]="isInvalid(contextFormGroup, 'identityDocDate')">
            <input type="date" id="identityDocDate" formControlName="identityDocDate">
            <app-validation-tooltip [input]="contextFormGroup.controls['identityDocDate']"></app-validation-tooltip>
          </label>
        </div>

        <div class="form-group" *ngIf="isSingleAnimal()">
          <label for="identityDocIssuer">Кем выдан</label>
          <label for="identityDocIssuer" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md tooltip-top-left"
                 [class.invalid]="isInvalid(contextFormGroup, 'identityDocIssuer')">
            <input type="text" id="identityDocIssuer" placeholder="Кем выдан паспорт животного"
                   class="itech-control-xlarge"
                   formControlName="identityDocIssuer">
            <app-validation-tooltip [input]="contextFormGroup.controls['identityDocIssuer']"></app-validation-tooltip>
          </label>
        </div>

      </section>

      <section class="form-block itech-block-medium">
        <h3>Владелец животного
          &nbsp;&nbsp;&nbsp;
          <button *ngIf="contextFormGroup.controls['id'].value" type="button" class="btn btn-link itech-inline-link-btn"
                  (click)="addAnimalAgent()">
            <clr-icon shape="edit"></clr-icon>
            Сменить владельца
          </button>
          &nbsp;
          <button *ngIf="contextFormGroup.controls['id'].value" type="button" class="btn btn-link itech-inline-link-btn"
                  (click)="changeAnimalAgentOrStableNoVsd()">(Без оформления ВСД)
          </button>
        </h3>

        <div style="display: flex; flex-direction: row; margin-bottom: 10px">
          <div style="flex: 1 1 auto; margin-right: 10px; flex-direction: column; display: flex; align-items: center">
            <clr-icon shape="users" size="72"></clr-icon>
          </div>
          <div class="form-group" style="flex: 1000 1 auto">
            <label class="required">Владелец</label>
            <app-link-button [id]="contextFormGroup.controls['currentAgentId'].value" [lookupName]="'agent'"></app-link-button>
            <button *ngIf="!contextFormGroup.controls['id'].value" type="button" (click)="searchAgent()"
                    class="btn btn-danger btn-link itech-inline-tool-btn" title="Выбрать владельца">
              <clr-icon shape="search"></clr-icon>
            </button>
          </div>
        </div>
      </section>

      <section class="form-block itech-block-medium">
        <h3>Местонахождение животного
          &nbsp;&nbsp;&nbsp;
          <button *ngIf="contextFormGroup.controls['id'].value" type="button" class="btn btn-link itech-inline-link-btn"
                  (click)="addAnimalStable()">
            <clr-icon shape="edit"></clr-icon>
            Сменить местонахождение
          </button>
          &nbsp;
          <button *ngIf="contextFormGroup.controls['id'].value"type="button" class="btn btn-link itech-inline-link-btn"
                  (click)="changeAnimalAgentOrStableNoVsd()">(Без оформления ВСД)
          </button>
        </h3>
        <div style="display: flex; flex-direction: row; margin-bottom: 10px">
          <div style="flex: 1 1 auto; margin-right: 10px; flex-direction: column; display: flex; align-items: center">
            <clr-icon shape="building" size="72"></clr-icon>
          </div>
          <div class="form-group" style="flex: 1000 1 auto">
            <label class="required">Местонахождение</label>
            <app-link-button [id]="contextFormGroup.controls['currentStableId'].value" [lookupName]="'stable'"></app-link-button>
            <button *ngIf="!contextFormGroup.controls['id'].value" type="button" (click)="searchStable()"
                    class="btn btn-danger btn-link itech-inline-tool-btn" title="Выбрать местонахождение">
              <clr-icon shape="search"></clr-icon>
            </button>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
