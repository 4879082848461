import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-relation-nodes',
  template: `
    <ng-container [clrLoading]='loading' *ngIf="condition">
      <clr-tree-node *ngFor="let relation of condition.relations">
        <button class="clr-treenode-link"
                [class.active]="model.selectedNode == relation && model.nodeType == 'condition'"
                (click)="relationClick.emit({parentCondition: condition, condition: relation})"
                title="{{relation.data.relationType | lookup:'query-relation-type' | async}}">
          {{relation.data.relationType | lookup:'query-relation-type' | async}}
        </button>
        <ng-template [(clrIfExpanded)]="relation.expanded" *ngIf="relation.relations && relation.relations.length">
          <app-relation-nodes [condition]="relation" (relationClick)="innerRelationClick($event)"
                              [model]="model"></app-relation-nodes>
        </ng-template>
      </clr-tree-node>
    </ng-container>
  `
})
export class RelationNodesComponent {
  @Input() model: any;
  @Input() condition: any;
  @Output() relationClick = new EventEmitter();
  loading: any;

  innerRelationClick($event: any) {
    this.relationClick.emit($event);
  }
}
