import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { StableSearchResultsFormComponent } from './stable-search-results-form.component';
import { IModalBody, ModalResult } from '../../../logic/services/app-navigation.service.models';
import { Observable } from 'rxjs';

@Component({
  template: `
    <div style="height: 600px; background-color: white; position: relative;">
      <app-stable-search-results-form
        [model]="model"
        (rowClicked)="chooseStable($event.id)">
      </app-stable-search-results-form>
    </div>
  `
})
export class StableSearchModalComponent implements OnInit, IModalBody<any, void> {

  model = StableSearchResultsFormComponent.getDefaultModel();

  constructor(public appNavigationService: AppNavigationService,
              private fb: FormBuilder) {
  }

  ngOnInit() {
    this.model = StableSearchResultsFormComponent.getDefaultModel(this.fb, this.appNavigationService.customModalParams);
  }

  chooseStable(val: number) {
    this.appNavigationService.customModalAcceptExternalPromiseNumber.next(val);
    this.appNavigationService.customModalOpened = false;
  }

  initModalBody(data: any) {
  }

  onModalAccept$(): Observable<ModalResult<void>> {
    return undefined;
  }
}
