import { EventEmitter, OnChanges } from '@angular/core';
import { Validators } from '@angular/forms';
import { ServerSideErrorsProvider } from '../../../logic/validators/server-side-errors-provider';
import { AppFormGroup } from '../../../ui/controls/app-form-group';
import { FormHelper } from '../../../ui/controls/form-helper';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { SecurityService } from '../../../logic/services/security.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { StringHelper } from '../../../helpers/string-helper';
import { AddressComponent } from '../../../ui/controls/address.component';
import { serverSideErrorsValidator } from '../../../logic/validators/server-side-errors-validator.directive';
import { MetadataService } from '../../../logic/services/metadata.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { AppModalPreviewFileComponent } from '../../../ui/controls/app-modal-preview-file.component';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
var EditQuarantineCommonComponent = /** @class */ (function () {
    function EditQuarantineCommonComponent(dataCachingService, waitingOverlay, userService, metadataService, lookupService, navigationService) {
        this.dataCachingService = dataCachingService;
        this.waitingOverlay = waitingOverlay;
        this.userService = userService;
        this.metadataService = metadataService;
        this.lookupService = lookupService;
        this.navigationService = navigationService;
        this.isAccessEdit = false;
        this.dirtyFormGroup = new EventEmitter();
        this.isInvalid = FormHelper.isInvalid;
        this.liquidationRegulationsFileUri = null;
        this.susceptibleUniqueAnimalSubtypeIdsModalOpen = false;
        this.QUARANTINE_TERRITORY_TYPE = {
            SOURCE: 1,
            BAD_LOCALITY: 2,
            DANGER_AREA: 3,
            FIRST_DANGER_AREA: 4,
            SECOND_DANGER_AREA: 5,
        };
    }
    Object.defineProperty(EditQuarantineCommonComponent.prototype, "territories", {
        get: function () {
            return this.contextFormGroup.get('territories');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditQuarantineCommonComponent.prototype, "susceptibleUniqueAnimalSubtypeIds", {
        get: function () {
            return this.contextFormGroup.get('susceptibleUniqueAnimalSubtypeIds');
        },
        enumerable: true,
        configurable: true
    });
    EditQuarantineCommonComponent.createFormGroupCommon = function (fb, serverSideErrorsProvider, val) {
        var _this = this;
        return fb.group({
            id: val.id,
            diseaseTypeId: [val.diseaseTypeId, Validators.compose([Validators.required,
                    serverSideErrorsValidator('diseaseTypeId', serverSideErrorsProvider)])],
            sourcePathogenAnimalTypeId: [val.sourcePathogenAnimalTypeId,
                Validators.compose([serverSideErrorsValidator('sourcePathogenAnimalTypeId', serverSideErrorsProvider)])],
            sourcePathogenAnimalSubtypeId: [val.sourcePathogenAnimalSubtypeId,
                Validators.compose([serverSideErrorsValidator('sourcePathogenAnimalSubtypeId', serverSideErrorsProvider)])],
            sourcePathogenAnimalOtherCaption: [val.sourcePathogenAnimalOtherCaption,
                Validators.compose([serverSideErrorsValidator('sourcePathogenAnimalOtherCaption', serverSideErrorsProvider)])],
            dateFrom: [val.dateFrom || StringHelper.getISODate(new Date()),
                Validators.compose([Validators.required, FormHelper.validateDateTimePicker,
                    serverSideErrorsValidator('dateFrom', serverSideErrorsProvider)])],
            responsibleInstitutionId: [val.responsibleInstitutionId,
                Validators.compose([serverSideErrorsValidator('responsibleInstitutionId', serverSideErrorsProvider)])],
            responsibleInstitutionEmployeeId: [val.responsibleInstitutionEmployeeId, Validators.compose([Validators.required,
                    serverSideErrorsValidator('responsibleInstitutionEmployeeId', serverSideErrorsProvider)])],
            territories: (val.territories && val.territories.length
                ? fb.array(val.territories.map(function (el, ix) { return fb.group(_this.buildFormGroupTerritory(fb, serverSideErrorsProvider, el || {}, 'territories-' + ix)); }))
                : fb.array([])),
            currentStatusId: val.currentStatusId,
            needActualizeTrappedObjects: val.needActualizeTrappedObjects || false,
            trappedStableCount: val.trappedStableCount,
            trappedAnimalCount: val.trappedAnimalCount,
            susceptibleUniqueAnimalSubtypeIds: fb.array(val.susceptibleUniqueAnimalSubtypeIds || []),
            isViolationDeadlineFile: val.isViolationDeadlineFile,
            noSuspicionStage: val.noSuspicionStage || false,
            personApprovedEventPlanPost: [val.personApprovedEventPlanPost,
                Validators.compose([serverSideErrorsValidator('personApprovedEventPlanPost', serverSideErrorsProvider)])],
            personApprovedEventPlanFio: [val.personApprovedEventPlanFio,
                Validators.compose([serverSideErrorsValidator('personApprovedEventPlanFio', serverSideErrorsProvider)])],
        });
    };
    EditQuarantineCommonComponent.buildFormGroupTerritory = function (fb, serverSideErrorsProvider, val, formGroupNameRelation) {
        return {
            quarantineId: [val.quarantineId,
                Validators.compose([serverSideErrorsValidator('quarantineId', serverSideErrorsProvider, formGroupNameRelation)])],
            quarantineTerritoryTypeId: [val.quarantineTerritoryTypeId,
                Validators.compose([serverSideErrorsValidator('quarantineTerritoryTypeId', serverSideErrorsProvider, formGroupNameRelation)])],
            kind: [(val.kind || '0').toString(),
                Validators.compose([serverSideErrorsValidator('kind', serverSideErrorsProvider, formGroupNameRelation)])],
            regionId: [val.regionId,
                Validators.compose([serverSideErrorsValidator('regionId', serverSideErrorsProvider, formGroupNameRelation)])],
            cityId: [val.cityId,
                Validators.compose([serverSideErrorsValidator('cityId', serverSideErrorsProvider, formGroupNameRelation)])],
            cadNumber: [StringHelper.stringCadNumberSplitWithColon(val.cadNumber),
                Validators.compose([serverSideErrorsValidator('cadNumber', serverSideErrorsProvider, formGroupNameRelation)])],
            addressId: [val.addressId,
                Validators.compose([serverSideErrorsValidator('addressId', serverSideErrorsProvider, formGroupNameRelation)])],
            address: fb.group(AddressComponent.buildFormGroupDef(fb, serverSideErrorsProvider, val.address, true, val.quarantineTerritoryTypeId)),
            radius: [val.radius, Validators.compose([Validators.pattern(/^\d*$/),
                    serverSideErrorsValidator('radius', serverSideErrorsProvider, formGroupNameRelation)])],
            comment: [val.comment,
                Validators.compose([serverSideErrorsValidator('comment', serverSideErrorsProvider, formGroupNameRelation)])],
            stableId: [val.stableId,
                Validators.compose([serverSideErrorsValidator('stableId', serverSideErrorsProvider, formGroupNameRelation)])],
        };
    };
    EditQuarantineCommonComponent.prototype.ngOnChanges = function () {
        var _this = this;
        if (!this.contextFormGroup) {
            return;
        }
        this.waitingOverlay.StartWaiting();
        this.userService.getUserInfo().subscribe(function (info) {
            _this.sourcePathogenAnimalOtherCaptionCheckbox = !!_this.contextFormGroup.get('sourcePathogenAnimalOtherCaption').value;
            if (!_this.contextFormGroup.get('id').value) {
                _this.contextFormGroup.get('responsibleInstitutionId').setValue(info.institutionId);
                _this.contextFormGroup.get('responsibleInstitutionEmployeeId').setValue(info.institutionEmployeeId);
            }
            if (_this.contextFormGroup.get('diseaseTypeId').value) {
                _this.updateLiquidationRegulationsFileUri(_this.contextFormGroup.get('diseaseTypeId').value);
            }
            _this.contextFormGroup.get('diseaseTypeId').valueChanges.subscribe(function (change) { return _this.updateLiquidationRegulationsFileUri(change); });
            _this.contextFormGroup.valueChanges.subscribe(function () { return _this.dirtyFormGroup.next(); });
            _this.waitingOverlay.EndWaiting();
        });
    };
    EditQuarantineCommonComponent.prototype.updateLiquidationRegulationsFileUri = function (diseaseTypeId) {
        var _this = this;
        this.lookupService.getLookupObj('disease-type/may-be-introduced-quarantine' + diseaseTypeId)
            .subscribe(function (diseaseType) { return _this.liquidationRegulationsFileUri = diseaseType ? diseaseType.liquidationRegulationsFileUri : null; });
    };
    EditQuarantineCommonComponent.prototype.resetAnimalSubtypeId = function () {
        this.contextFormGroup.get('sourcePathogenAnimalSubtypeId').reset();
        this.contextFormGroup.markAsDirty();
        this.dirtyFormGroup.next();
    };
    EditQuarantineCommonComponent.prototype.sourcePathogenAnimalOtherCaptionCheckboxChange = function () {
        this.contextFormGroup.get('sourcePathogenAnimalTypeId').reset();
        this.contextFormGroup.get('sourcePathogenAnimalSubtypeId').reset();
        this.contextFormGroup.get('sourcePathogenAnimalOtherCaption').reset();
        this.contextFormGroup.markAsDirty();
        this.dirtyFormGroup.next();
    };
    EditQuarantineCommonComponent.prototype.getPrettyTitle = function (file) {
        return StringHelper.getPrettyTitleFile(file);
    };
    EditQuarantineCommonComponent.prototype.previewFile = function (file) {
        return this.navigationService.previewFile(AppModalPreviewFileComponent, { fileUri: file });
    };
    return EditQuarantineCommonComponent;
}());
export { EditQuarantineCommonComponent };
