/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./drug-type-browse-form.component.ngfactory";
import * as i2 from "./drug-type-browse-form.component";
import * as i3 from "../../../logic/services/metadata.service";
import * as i4 from "../../../logic/services/app-navigation.service";
import * as i5 from "@angular/forms";
import * as i6 from "./drug-type-browse-create-modal.component";
import * as i7 from "../../../logic/services/lookup-source.service";
var styles_DrugTypeBrowseCreateModalComponent = [];
var RenderType_DrugTypeBrowseCreateModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DrugTypeBrowseCreateModalComponent, data: {} });
export { RenderType_DrugTypeBrowseCreateModalComponent as RenderType_DrugTypeBrowseCreateModalComponent };
export function View_DrugTypeBrowseCreateModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-drug-type-browse-form", [], null, null, null, i1.View_DrugTypeBrowseFormComponent_0, i1.RenderType_DrugTypeBrowseFormComponent)), i0.ɵdid(1, 49152, null, 0, i2.DrugTypeBrowseFormComponent, [i3.MetadataService, i4.AppNavigationService, i5.FormBuilder], { currentFormGroup: [0, "currentFormGroup"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentFormGroup; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_DrugTypeBrowseCreateModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_DrugTypeBrowseCreateModalComponent_0, RenderType_DrugTypeBrowseCreateModalComponent)), i0.ɵdid(1, 49152, null, 0, i6.DrugTypeBrowseCreateModalComponent, [i5.FormBuilder, i3.MetadataService, i7.LookupSourceService], null, null)], null, null); }
var DrugTypeBrowseCreateModalComponentNgFactory = i0.ɵccf("ng-component", i6.DrugTypeBrowseCreateModalComponent, View_DrugTypeBrowseCreateModalComponent_Host_0, {}, {}, []);
export { DrugTypeBrowseCreateModalComponentNgFactory as DrugTypeBrowseCreateModalComponentNgFactory };
