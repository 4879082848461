<div class="query-manager-main-cont">
  <div class="query-manager-navigation">
    <ng-container *ngIf="model.entityTypes">
      <clr-tree-node *ngFor='let entityType of model.entityTypes'>
        <button class="clr-treenode-link" (click)="onEntityClick(entityType)"
                [class.active]="model.selectedNode == entityType && model.nodeType == 'entity'">
          {{entityType.caption}}
        </button>

        <ng-template [(clrIfExpanded)]="entityType.expanded">

          <clr-tree-node *ngFor="let query of lazyLoadQueries(entityType)">
            <button class="clr-treenode-link"
                    [class.active]="model.selectedNode == query && model.nodeType == 'query'"
                    (click)="onQueryClick(entityType, query)"
                    [style.color]="query.data.id?undefined:'silver'"
                    [class.itech-query]="query.data.id"
                    title="{{query.data.caption}}">
              <clr-icon *ngIf="query.data.id" shape="bolt" class="is-solid" style="color: green"></clr-icon>
              {{query.data.caption}}
            </button>
            <ng-template [(clrIfExpanded)]="query.expanded" *ngIf="query.data.id">
              <clr-tree-node *ngFor="let condition of query.conditions">
                <button class="clr-treenode-link"
                        [class.active]="model.selectedNode == condition && model.nodeType == 'condition'"
                        (click)="onConditionClick(entityType, query, condition, undefined)"
                        [style.color]="condition.isNew?'silver': undefined">
                  <ng-container *ngIf="condition.data.conditionType === 'add'">
                    Включить
                  </ng-container>
                  <ng-container *ngIf="condition.data.conditionType === 'remove'">
                    Исключить
                  </ng-container>
                </button>
                <ng-template [(clrIfExpanded)]="condition.expanded" *ngIf="condition.relations">
                  <app-relation-nodes [condition]="condition" [model]="model"
                                      (relationClick)="onConditionClick(entityType, query, $event.condition, $event.parentCondition)"></app-relation-nodes>
                </ng-template>
              </clr-tree-node>
              <clr-tree-node>
                <button class="clr-treenode-link"
                        [class.active]="model.selectedNode == query && model.nodeType == 'results'"
                        (click)="onQueryViewClick(entityType, query)">
                  <clr-icon shape="search" style="color: darkcyan;margin-top: -2px"></clr-icon>
                  Просмотр
                </button>
              </clr-tree-node>
            </ng-template>
          </clr-tree-node>
        </ng-template>
      </clr-tree-node>
    </ng-container>
  </div>
  <div *ngIf="!model.selected" class="query-manager-no-query">
    Выберите раздел
  </div>
  <app-query-details-results *ngIf="model.nodeType=='results'" [model]="model"></app-query-details-results>
  <app-query-details-query *ngIf="model.nodeType=='query'" [model]="model"></app-query-details-query>
  <app-query-details-agent *ngIf="model.nodeType=='condition' && model.condition.data.type === 'agent'"
                            [model]="model" (creatingRelation)="creatingRelation($event)"></app-query-details-agent>
  <app-query-details-stable *ngIf="model.nodeType=='condition' && model.condition.data.type === 'stable'"
                            [model]="model" (creatingRelation)="creatingRelation($event)"></app-query-details-stable>
  <app-query-details-animal *ngIf="model.nodeType=='condition' && model.condition.data.type === 'animal'"
                            [model]="model" (creatingRelation)="creatingRelation($event)"></app-query-details-animal>
  <app-query-details-product *ngIf="model.nodeType=='condition' && model.condition.data.type === 'product'"
                            [model]="model" (creatingRelation)="creatingRelation($event)"></app-query-details-product>
  <app-query-details-event *ngIf="model.nodeType=='condition' && model.condition.data.type === 'event'"
                           [model]="model" (creatingRelation)="creatingRelation($event)"></app-query-details-event>
  <app-query-details-quarantine *ngIf="model.nodeType=='condition' && model.condition.data.type === 'quarantine'"
                           [model]="model" (creatingRelation)="creatingRelation($event)"></app-query-details-quarantine>
  <app-query-details-institution-ticket *ngIf="model.nodeType=='condition' && model.condition.data.type === 'institution-ticket'"
                                        [model]="model" (creatingRelation)="creatingRelation($event)"></app-query-details-institution-ticket>
  <app-query-details-drug *ngIf="model.nodeType=='condition' && model.condition.data.type === 'drug'"
                          [model]="model" (creatingRelation)="creatingRelation($event)"></app-query-details-drug>
</div>
