
      <ng-container [clrLoading]='loading' *ngIf="parent">
          <clr-tree-node *ngFor='let item of parent.children'
                         (clrSelectedChange)="changeSelectedChildItems(item, $event)">
              <b *ngIf="item.value.value">{{formatRuNumeric(item.meta.precision, item.value.value)}}</b>
              <b *ngIf="!item.value.value" style="color: red">Н/Д</b>
              <span style="color: #1a73e8">&nbsp;{{item.levelCaption}}</span>
              <span title="ид вида: {{item.meta.id}}">&nbsp;{{item.meta.caption}}</span>
              <ng-template [(clrIfExpanded)]="item.expanded" *ngIf="item.children && item.children.length > 0">
                  <!-- отчеты, в которых указан показатель-->
                  <ng-container *ngFor="let reportId of parent.reports">
                      <clr-tree-node *ngIf="reportId !== ownerReportId">
                          <a class="itech-link in-table-link" (click)="onNavigateReport.emit(reportId)">
                              <clr-icon shape="line-chart"></clr-icon>
                              Отчет {{reportId|lookup:'pst-report'|async}}</a>
                      </clr-tree-node>
                  </ng-container>
                  <app-ind-dep-tree-nodes [parent]="item" [ownerReportId]="ownerReportId"
                                          (onNavigateReport)="onNavigateReport.emit($event)">
                  </app-ind-dep-tree-nodes>
              </ng-template>
          </clr-tree-node>
      </ng-container>
  