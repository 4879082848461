import { DataCachingService } from '../../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { ProductSearchResultsFormComponent } from '../../edit-product/search/product-search-results-form.component';
import { ProductDataService } from '../../../logic/services/product-data.service';
import { AgGridProductTransactionDetailRendererComponent } from '../../../ui/controls/ag-grid-product-transaction-detail-renderer.component';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { ProductSearchResultsComponent } from '../../edit-product/search/product-search-results.component';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';
var EditAnimalProductsComponent = /** @class */ (function () {
    function EditAnimalProductsComponent(productDataService, dataCachingService, waitingOverlay, lookupSourceService, navigationService, alertService, cacheService) {
        this.productDataService = productDataService;
        this.dataCachingService = dataCachingService;
        this.waitingOverlay = waitingOverlay;
        this.lookupSourceService = lookupSourceService;
        this.navigationService = navigationService;
        this.alertService = alertService;
        this.cacheService = cacheService;
        this.model = {};
        this.isExternal = false;
        this.isInvalid = FormHelper.isInvalid;
        this.getRowStyle = ProductSearchResultsFormComponent.getRowStyle;
        this.gridColumnDefs = ProductSearchResultsFormComponent.getGridColumnDefs();
        this.detailCellRenderer = 'myDetailCellRenderer';
        this.frameworkComponents = { myDetailCellRenderer: AgGridProductTransactionDetailRendererComponent };
        this.gridIcons = {
            groupContracted: '<clr-icon shape="organization" style="cursor: pointer"></clr-icon>',
        };
    }
    Object.defineProperty(EditAnimalProductsComponent.prototype, "id", {
        get: function () {
            return this._id;
        },
        set: function (id) {
            if (this._id !== id) {
                this._id = id;
                this.refreshFormDataInternal();
            }
        },
        enumerable: true,
        configurable: true
    });
    EditAnimalProductsComponent.prototype.refreshFormData = function () {
        if (!this.isExternal) {
            this.dataCachingService.removeCachedData('EditAnimalProducts', this.id.toString());
        }
        this.refreshFormDataInternal();
    };
    EditAnimalProductsComponent.prototype.refreshFormDataInternal = function () {
        var _this = this;
        if (this.isExternal) {
            this.model = { searchResults: [] };
            return;
        }
        this.waitingOverlay.StartWaiting();
        var existing = this.dataCachingService.getCachedData('EditAnimalProducts', this.id.toString());
        if (existing) {
            this.model = existing;
            this.stableId = existing.stableId;
            this.waitingOverlay.EndWaiting();
        }
        else {
            this.cacheService.getAnimal(this.id)
                .subscribe(function (animal) {
                _this.stableId = animal.stableId;
                _this.productDataService.getAnimalProducts(_this.id, _this.model.showHistory)
                    .subscribe({
                    next: function (data) {
                        _this.lookupSourceService.getLookupObj('product-subtype').subscribe(function (productSubtypeLookup) {
                            _this.lookupSourceService.getLookupObj('product-status').subscribe(function (productStatusLookup) {
                                _this.lookupSourceService.getLookupObj('transaction-type').subscribe(function (transactionTypeLookup) {
                                    data.forEach(function (el) {
                                        el.transactionTypeCaption = transactionTypeLookup[el.transactionType];
                                        el.statusCaption = productStatusLookup[el.status];
                                        if (el.status === -100 && el.templateCaption) {
                                            el.statusCaption = 'Шаблон: ' + el.templateCaption;
                                        }
                                        if (el.stableProductSubtypeCaption) {
                                            el.productSubtypeIdCaption = el.stableProductSubtypeCaption;
                                        }
                                        else {
                                            var productSubtypeRec = productSubtypeLookup[el.productTypeId].find(function (ptst) { return ptst.id === el.productSubtypeId; });
                                            el.productSubtypeIdCaption = productSubtypeRec ? productSubtypeRec.caption : undefined;
                                        }
                                    });
                                    data.sort(function (a, b) { return b.id - a.id; });
                                    _this.model = { searchResults: data, showHistory: _this.model.showHistory, stableId: _this.stableId };
                                    _this.dataCachingService.addToCache('EditAnimalProducts', _this.id.toString(), _this.model);
                                    _this.waitingOverlay.EndWaiting();
                                });
                            });
                        });
                    },
                    error: function () {
                        _this.waitingOverlay.EndWaiting();
                    }
                });
            });
        }
    };
    EditAnimalProductsComponent.prototype.addNew = function () {
        this.navigationService.performProductCreation(undefined, undefined, this.id, 4 /* Производство/переработка */);
    };
    EditAnimalProductsComponent.prototype.onGridSelectionChanged = function (params) {
        this.model.selectedRows = params.api.getSelectedRows();
    };
    EditAnimalProductsComponent.prototype.deleteDocument = function () {
        var _this = this;
        ProductSearchResultsComponent.deleteManyDocuments(this.model.selectedRows, this.alertService, this.productDataService, function () { return _this.refreshFormData(); });
    };
    EditAnimalProductsComponent.prototype.sendToRegistration = function () {
        var _this = this;
        ProductSearchResultsComponent.sendManyDocumentsToRegistration(this.model.selectedRows, this.alertService, this.productDataService, function () { return _this.refreshFormData(); });
    };
    EditAnimalProductsComponent.prototype.sendToClearance = function () {
        var _this = this;
        ProductSearchResultsComponent.sendManyDocumentsToClearance(this.model.selectedRows, this.alertService, this.productDataService, function () { return _this.refreshFormData(); });
    };
    EditAnimalProductsComponent.prototype.createTransportForSelectedDocuments = function () {
        ProductSearchResultsComponent.createTransportForSelectedDocuments(this.model.selectedRows, this.navigationService);
    };
    return EditAnimalProductsComponent;
}());
export { EditAnimalProductsComponent };
