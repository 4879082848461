<div class="itech-common-wrap" id="interceptNoAccess">
  <div style="width: 100%">
    <app-grid [pagination]="false" [rowData]="model.searchResults" [columnDefs]="gridColumnDefs"
              (rowClicked)="navigate($event.data)" [uniqueGridName]="'EditDrugSpendingGrid'"
              (gridReady)="onGridReady($event)"
              [exportFileName]="'Расход препарата ' +  + (id|lookup:'drug'|async)"></app-grid>
  </div>

  <div class="itech-form-actions">
    <div>
      <button class="btn btn-secondary" (click)="refreshFormData()">
        <clr-icon shape="refresh"></clr-icon>&nbsp;Обновить
      </button>
      <button class="btn btn-success-outline" (click)="addNewMove()">
        <clr-icon shape="step-forward"></clr-icon>&nbsp;Передача
      </button>
      <button class="btn btn-warning-outline" (click)="addNewWriteoff()">
        <clr-icon shape="plus"></clr-icon>&nbsp;Списание
      </button>
    </div>
  </div>
</div>

<clr-modal [(clrModalOpen)]="writeoffModalOpened" *ngIf="writeoffModalOpened" [clrModalClosable]="false">
  <h3 class="modal-title">Добавление списания</h3>
  <div class="modal-body">
    <div class="form compact" [formGroup]="currentFormGroup">
      <section class="form-block itech-block-large" style="padding-top: 10px">

        <div class="form-group">
          <label for="date" class="required">Дата списания</label>
          <label for="date" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                 [class.invalid]="isInvalid(currentFormGroup, 'date')">
            <input type="date" id="date" class="itech-control-normal"
                   formControlName="date">
            <app-validation-tooltip [input]="currentFormGroup.controls['date']"></app-validation-tooltip>
          </label>
        </div>

        <div class="form-group">
          <label for="actNo" class="required">Номер акта</label>
          <label for="actNo" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md tooltip-top-left"
                 [class.invalid]="isInvalid(currentFormGroup, 'actNo')">
            <input type="text" id="actNo" placeholder="№ акта" class="itech-control-normal"
                   formControlName="actNo">
            <app-validation-tooltip [input]="currentFormGroup.controls['actNo']"></app-validation-tooltip>
          </label>
        </div>

        <div class="form-group">
          <label for="spentValueStr" class="required">Объем, {{model.unitTypeName}}</label>
          <label for="spentValueStr" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md tooltip-top-left"
                 [class.invalid]="isInvalid(currentFormGroup, 'spentValueStr')">
            <input type="text" id="spentValueStr" placeholder="Объем, {{model.unitTypeShortName}}"
                   class="itech-control-normal"
                   formControlName="spentValueStr" (keypress)="processMoneyKeypress($event)"
                   (change)="changeDecimalStrValue(currentFormGroup, 'spentValueStr')">
            <app-validation-tooltip [input]="currentFormGroup.controls['spentValueStr']"></app-validation-tooltip>
          </label>
        </div>

        <div class="form-group itech-group-whole-line">
          <div class="checkbox-inline">
            <input type="checkbox" id="toOtherSubject" formControlName="toOtherSubject">
            <label for="toOtherSubject">
              В другой субъект
            </label>
          </div>
        </div>

        <div class="form-group">
          <label for="animalType4VetA">Списывается на мероприятия с животными</label>
          <app-combo-lookup class="itech-control-large" [contextControlId]="'animalType4VetA'" [sorted]="true"
                            [contextControlName]="'animalType4VetA'" [lookupName]="'animal-types-4-vet-a'" (change)="animalType4VetAChanged($event)"
                            [contextFormGroup]="currentFormGroup"></app-combo-lookup>
        </div>

        <div class="form-group" *ngIf="currentFormGroup.controls['animalType4VetA'].value">
          <label for="eventsCount" class="required">Количество проведенных мероприятий</label>
          <label for="eventsCount" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md tooltip-top-left"
                 [class.invalid]="isInvalid(currentFormGroup, 'eventsCount')">
            <input type="text" id="eventsCount" class="itech-control-normal" formControlName="eventsCount">
            <app-validation-tooltip [input]="currentFormGroup.controls['eventsCount']"></app-validation-tooltip>
          </label>
        </div>

        <div class="form-group itech-group-whole-line itech-double-height">
          <label for="reasonText"></label>
          <label for="reasonText" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md tooltip-top-left"
                 [class.invalid]="isInvalid(currentFormGroup, 'reasonText')">
            <textarea type="text" id="reasonText" placeholder="Укажите причину списания"
                      class="itech-control-larger itech-text-wall" formControlName="reasonText">
            </textarea>
            <app-validation-tooltip [input]="currentFormGroup.controls['reasonText']"></app-validation-tooltip>
          </label>
        </div>

        <div class="form-group itech-group-whole-line itech-double-height">
          <label for="recycleWay"></label>
          <label for="recycleWay" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md tooltip-top-left"
                 [class.invalid]="isInvalid(currentFormGroup, 'recycleWay')">
            <textarea type="text" id="recycleWay" placeholder="Укажите способ уничтожения"
                      class="itech-control-larger itech-text-wall" formControlName="recycleWay">
            </textarea>
            <app-validation-tooltip [input]="currentFormGroup.controls['recycleWay']"></app-validation-tooltip>
          </label>
        </div>


      </section>
    </div>
  </div>
  <div class="modal-footer itech-form-actions">
    <div>
    </div>
    <div>
      <button type="button" class="btn btn-primary" (click)="addWriteoffCompleted()">
        <clr-icon shape="check"></clr-icon>&nbsp;Списать
      </button>
      <button type="button" class="btn btn-warning" (click)="writeoffModalOpened = false">
        <clr-icon shape="undo"></clr-icon>&nbsp;Отмена
      </button>
    </div>
  </div>
</clr-modal>


<clr-modal [(clrModalOpen)]="moveModalOpened" *ngIf="moveModalOpened" [clrModalClosable]="false">
  <h3 class="modal-title">Передача препарата</h3>
  <div class="modal-body">
    <div class="form compact" [formGroup]="currentFormGroup">
      <section class="form-block itech-block-normal" style="padding-top: 10px">

        <div class="form-group">
          <label for="date" class="required">Дата передачи</label>
          <label for="date" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                 [class.invalid]="isInvalid(currentFormGroup, 'date')">
            <input type="date" id="date" class="itech-control-normal"
                   formControlName="date">
            <app-validation-tooltip [input]="currentFormGroup.controls['date']"></app-validation-tooltip>
          </label>
        </div>

        <div class="form-group">
          <label for="valueStr" class="required">Количество, {{model.unitTypeName}}</label>
          <label for="valueStr" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                 [class.invalid]="isInvalid(currentFormGroup, 'valueStr')">
            <input type="text" id="valueStr" placeholder="Кол-во, {{model.unitTypeShortName}}."
                   class="itech-control-normal"
                   formControlName="valueStr" (keypress)="processMoneyKeypress($event)"
                   (change)="changeDecimalStrValue(currentFormGroup, 'valueStr')">
            <app-validation-tooltip [input]="currentFormGroup.controls['valueStr']"></app-validation-tooltip>
          </label>
        </div>

        <div class="form-group">
          <label for="packageCount">Количество упаковок, {{model.packageTypeName}}</label>
          <label for="packageCount" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                 [class.invalid]="isInvalid(currentFormGroup, 'packageCount')">
            <input type="text" id="packageCount" placeholder="Кол-во, {{model.packageTypeShortName}}."
                   class="itech-control-normal"
                   formControlName="packageCount" (keypress)="processMoneyKeypress($event)">
            <app-validation-tooltip [input]="currentFormGroup.controls['packageCount']"></app-validation-tooltip>
          </label>
        </div>

        <div class="form-group">
          <label for="institutionId" class="required">Учреждение</label>
          <app-combo-lookup [validationTooltipTopLeft]="true" class="itech-control-large"
                            [contextControlId]="'institutionId'"
                            [contextControlName]="'institutionId'"
                            [lookupName]="'institution'" [contextFormGroup]="currentFormGroup"></app-combo-lookup>
        </div>

        <div class="form-group">
          <label for="institutionId" class="required">Подразделение</label>
          <app-combo-lookup [validationTooltipTopLeft]="true" class="itech-control-large"
                            [contextControlId]="'institutionBranchId'"
                            [contextControlName]="'institutionBranchId'"
                            [lookupName]="'institution-branch' + (currentFormGroup.get('institutionId').value || 0)"
                            [contextFormGroup]="currentFormGroup"></app-combo-lookup>
        </div>

      </section>
    </div>
  </div>
  <div class="modal-footer itech-form-actions">
    <div>
    </div>
    <div>
      <button type="button" class="btn btn-primary" (click)="addMoveCompleted()">
        <clr-icon shape="check"></clr-icon>&nbsp;Передать
      </button>
      <button type="button" class="btn btn-warning" (click)="moveModalOpened = false">
        <clr-icon shape="undo"></clr-icon>&nbsp;Отмена
      </button>
    </div>
  </div>
</clr-modal>
