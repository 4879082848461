import { Component } from '@angular/core';
import { MetaBrowseBaseComponent } from '../meta-browse.base.component';
import { ActivatedRoute } from '@angular/router';
import { MetadataService } from '../../../logic/services/metadata.service';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import {StringHelper} from '../../../helpers/string-helper';

@Component({
  templateUrl: './event-test-material-browse.component.html'
})
export class EventTestMaterialBrowseComponent extends MetaBrowseBaseComponent {

  gridColumnDefs = [
    {headerName: '№', field: 'id', width: 100},
    {headerName: 'Наименование', field: 'caption', tooltipField: 'caption', width: 350},
    {
      headerName: 'Дата удаления', field: 'dateDeleted', width: 200,
      valueFormatter: params => StringHelper.getRuDate(params.value),
    },
  ];

  constructor(route: ActivatedRoute,
              metadataService: MetadataService,
              alertService: AlertService,
              globalWaitingOverlayService: GlobalWaitingOverlayService,
              lookupService: LookupSourceService,
              bookmarkService: BookmarkService,
              fb: FormBuilder,
              dataCachingService: DataCachingService) {
    super(route, metadataService, alertService, globalWaitingOverlayService, lookupService, fb, bookmarkService, dataCachingService);
  }

  getMetaTitle(): string {
    return 'Исследуемые материалы';
  }

  getMetaName(): string {
    return 'test-material';
  }

  getId(formGroup: FormGroup): any {
    return formGroup.get('id').value;
  }

  getGroupDef(row: any): any {
    return {
      id: [row.id, Validators.compose([Validators.required, Validators.pattern(/^\d{1,5}$/)])],
      caption: [row.caption, Validators.required],
      dateDeleted: row.dateDeleted,
    };
  }

  getRowStyle(params: any) {
    if (params.data && params.data.dateDeleted) {
      return {color: 'silver'};
    }
  }
}
