<div class="itech-common-wrap" id="interceptNoAccess">
  <div id="animal-event-common-content">
    <div class="itech-doc-container" *ngIf="contextFormGroup">
      <div class="itech-doc-image">
        <clr-icon shape="event"></clr-icon>
        <div style="margin-top:20px">
          <ng-container>
            <div class="trigger-item">
              <clr-signpost class="itech-sync-status-lbl">
                <a *ngIf="contextFormGroup.get('mercuryId').value || contextFormGroup.get('syncWithJournalNumbers').value.length"
                   class="itech-inline-link-btn" clrSignpostTrigger style="color: green">
                  <clr-icon shape="lock"></clr-icon>
                  Синхронизация проведена
                </a>
                <a *ngIf="!contextFormGroup.get('mercuryId').value && !contextFormGroup.get('syncWithJournalNumbers').value.length"
                   class="itech-inline-link-btn" clrSignpostTrigger style="color: red">
                  <clr-icon shape="unlock"></clr-icon>
                  Синхронизация не проведена
                </a>
                <clr-signpost-content *ngIf="!contextFormGroup.get('mercuryId').value &&
                                             !contextFormGroup.get('syncWithJournalNumbers').value.length &&
                                              contextFormGroup.get('syncError').value"
                                      [clrPosition]="'right-bottom'" class="compact form itech-highlighted-sections" style="max-width:21rem">
                  <div class="compact form itech-highlighted-sections">
                    <section class="form-block itech-block-normal">
                      <div class="form-group itech-group-whole-line" style="margin-top: 5px">
                      <textarea class="itech-control-m-large itech-text-wall" readonly
                                style="color:red;min-height:100px;max-width:100%;width:100%;">{{contextFormGroup.get('syncError').value}}</textarea>
                      </div>
                    </section>
                  </div>
                </clr-signpost-content>
              </clr-signpost>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="itech-doc-content">
        <app-edit-animal-event-common-form [contextFormGroup]="contextFormGroup" [animal]="model.animal"
                                           [eventType]="model.eventType" [model]="model">
        </app-edit-animal-event-common-form>
      </div>
    </div>
  </div>
  <div class="itech-form-actions" *ngIf="contextFormGroup">
    <div>
      <button class="btn btn-secondary" (click)="refreshFormData()">
        <clr-icon shape="refresh"></clr-icon>&nbsp;Обновить
      </button>
      <app-reports-button [reportKind]="reportKind" [objId1]="id" [dropdownPosition]="'top-left'">
      </app-reports-button>

      <clr-dropdown>
        <button class="btn btn-warning-outline" clrDropdownToggle>
          Действия <clr-icon shape="caret down"></clr-icon>
        </button>
        <clr-dropdown-menu *clrIfOpen [clrPosition]="'top-left'">
          <label class="dropdown-header">Выберите действие</label>
          <button *ngIf="model.animal" class="btn btn-primary-outline"
                  (click)="navigationService.navigateJournal(undefined, model.animal? 9: 13, model.animal? model.animal.id: model.stableId, id)">
            <clr-icon shape="view-list"></clr-icon>&nbsp;Журнал
          </button>
          <div class="dropdown-divider"></div>
          <button class="btn btn-warning-outline" (click)="deleteAnimalEvent()">
            <clr-icon shape="trash"></clr-icon> Удалить мероприятие (событие)
          </button>
        </clr-dropdown-menu>
      </clr-dropdown>

    </div>
    <div>
      <button [clrLoading]="storing" class="btn btn-primary itech-form-save"
              [disabled]="!contextFormGroup || !contextFormGroup.dirty || storing" (click)="store()">
        <clr-icon shape="check"></clr-icon>&nbsp;Сохранить
      </button>
      <button class="btn btn-warning itech-form-cancel"
              [disabled]="!contextFormGroup || !contextFormGroup.dirty" (click)="cancelEdit()">
        <clr-icon shape="undo"></clr-icon>&nbsp;Отменить
      </button>
    </div>
  </div>
</div>
