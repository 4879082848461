import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataCachingService } from '../../logic/services/data-caching.service';
import { BookmarkService } from '../../logic/services/bookmark.service';

@Component({
  selector: 'app-edit-institution',
  templateUrl: './edit-institution.component.html'
})
export class EditInstitutionComponent implements OnInit {

  @Input() model: any = EditInstitutionComponent.createDefaultModel();
  @Input() id: number;

  static createDefaultModel(): any {
    return {
      commonTabActive: true,
      branchesTabActive: false,
      employeesTabActive: false,
      indicatorsTabActive: false,
      ticketsTabActive: false,
      messagesTabActive: false,
    };
  }

  constructor(private route: ActivatedRoute,
              private bookmarkService: BookmarkService,
              private dataCachingService: DataCachingService) {
  }

  ngOnInit() {

    this.route.paramMap
      .subscribe(params => {
        this.id = parseInt(params.get('id'), 10);

        const existing = this.dataCachingService.getCachedData('EditInstitution', this.id.toString());

        this.bookmarkService.refreshInstitutionCardBookmark(this.id);

        if (existing) {
          this.model = existing;
        } else {
          this.model = EditInstitutionComponent.createDefaultModel();
          this.dataCachingService.addToCache('EditInstitution', this.id.toString(), this.model);
        }
      });
  }
}
