<div #headerDiv *ngIf="branch">{{branch.displayTitle}}</div>
<div class="it-pst-tab-col-tbl-wrapper" style="margin-right: 10px" [style.scroll-padding-top]="theadHeight + 'px'"
     [style.height]="getWrapperHeight()">
  <table *ngIf="branch && branch.headerRows" class="it-pst-tab-col-tbl" tabindex="0" (keydown)="tableKeyDown($event)"
         style="border-top: none">
    <thead #thead class="it-sticky-top">
    <tr *ngFor="let headerRow of branch.headerRows; index as ix">
      <ng-container *ngFor="let headerCol of headerRow">
        <td *ngIf="headerCol.rowSpan && headerCol.colSpan"
            [attr.colspan]="headerCol.colSpan" [attr.rowspan]="headerCol.rowSpan"
            [style.width]="headerCol.indicator.meta.displayWidth? headerCol.indicator.meta.displayWidth + 'px' : undefined"
            [style.border-top]="ix===0? '1px solid black': undefined"
            class="it-indicator">
          {{headerCol.displayTitle}}
        </td>
      </ng-container>
    </tr>
    </thead>
    <tbody *ngIf="this.branch.rootRowsGroup">
    <tr *ngFor="let row of this.branch.rootRowsGroup.children; index as i_row">
      <ng-container *ngFor="let headerCol of branch.headerRows[branch.headerRows.length-1]; index as ii">
        <td *ngIf="row.indicators[ii].colSpan !== 0"
            [class.pst-table-header-row]="row.indicators[ii].colSpan === row.indicators.length"
            [style]="getCellStyle(row, row.indicators[ii], this.branch.rootRowsGroup, ii)"
            [class.it-read-only]="getCellReadonly(row, ii)"
            [attr.colspan]="row.indicators[ii].colSpan"
            (click)="cellClick(row, ii, row.indicators[ii].reportIndicator)"
            [class.it-focused]="getFocused(row, ii)"
            [class.it-static-text]="row.indicators[ii].cellFixedStr"
            [class.it-editing]="getEditing(row, ii)"
            class="it-indicator">
          <app-branch-cell-edit *ngIf="!row.indicators[ii].cellFixedStr"
                                [model]="model" [branch]="branch" [indicatorLevel]="row" [focused]="getFocused(row, ii)"
                                [indicatorTypeMeta]="row.indicators[ii].meta" [editing]="getEditing(row, ii)"
                                [reportIndicator]="row.indicators[ii].reportIndicator"></app-branch-cell-edit>
          <span *ngIf="row.indicators[ii].cellFixedStr">
            {{row.indicators[ii].cellFixedStr}}
          </span>

          <div class="it-add-subtype" *ngIf="showAddSubtypeBtn(row.indicators[ii])">
            <clr-dropdown>
              <a class="in-table-link itech-link" clrDropdownToggle title="Добавить подкатегорию">
                <clr-icon shape="plus"></clr-icon>
              </a>
              <clr-dropdown-menu *clrIfOpen [clrPosition]="'right-top'">
                <label class="dropdown-header">Выберите категорию</label>
                <button *ngFor="let cat of getAvailableSubtypes$(row.indicators[ii]) | async" [title]="cat.caption"
                        type="button" clrDropdownItem
                        (click)="cloneSubtypeRow(row, i_row, cat)">{{cat.caption}}
                </button>
              </clr-dropdown-menu>
            </clr-dropdown>
          </div>
        </td>
      </ng-container>
    </tr>
    </tbody>
  </table>
</div>
