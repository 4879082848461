<div class="form compact" *ngIf="currentFormGroup" [formGroup]="currentFormGroup">
  <clr-tabs>
    <clr-tab>
      <button clrTabLink>Общее</button>
      <clr-tab-content id="commonTab">
        <div class="edit-page-tab">
          <section class="form-block itech-block-normal" style="min-height: 645px;">
            <div class="form-group">
              <label for="id" class="required">Ид / Тип препарата</label>
              <label for="id" aria-haspopup="true" role="tooltip"
                     class="tooltip tooltip-validation tooltip-md tooltip-bottom-left">
                <input type="text" id="id" class="itech-control-small" formControlName="id" [attr.disabled]="true">
              </label>
              &nbsp;
              <app-text-lookup [validationTooltipRight]="true" class="itech-control-larger" [sorted]="true"
                               [contextControlId]="'drugKindId'" [contextControlName]="'drugKindId'"
                               [disabled]="!currentFormGroup.get('isCustom').value"
                               [lookupName]="'drug-kind'" [contextFormGroup]="currentFormGroup"></app-text-lookup>
            </div>

            <div class="form-group">
              <label for="caption" class="required">Наименование</label>
              <label for="caption" aria-haspopup="true" role="tooltip"
                     [class.invalid]="isInvalid(currentFormGroup, 'caption')"
                     class="tooltip tooltip-validation tooltip-md tooltip-top-left">
                <input type="text" id="caption" class="itech-control-xlarge" formControlName="caption"
                       [attr.disabled]="currentFormGroup.get('isCustom').value ? undefined : true">
                <app-validation-tooltip [input]="currentFormGroup.controls['caption']"></app-validation-tooltip>
              </label>
            </div>

            <div class="form-group">
              <label for="registryNo">№ рег.удостов.</label>
              <label for="registryNo" aria-haspopup="true" role="tooltip"
                     [class.invalid]="isInvalid(currentFormGroup, 'registryNo')"
                     class="tooltip tooltip-validation tooltip-md tooltip-top-left">
                <input type="text" id="registryNo" class="itech-control-xlarge" formControlName="registryNo"
                       [attr.disabled]="currentFormGroup.get('isCustom').value ? undefined : true">
                <app-validation-tooltip [input]="currentFormGroup.controls['registryNo']"></app-validation-tooltip>
              </label>
            </div>

            <div class="form-group">
              <label for="countryReg">Страна регистрации / Код по заявке</label>
              <app-text-lookup [validationTooltipRight]="true" class="itech-control-m-large"
                               [contextControlId]="'countryReg'" [contextControlName]="'countryReg'"
                               [disabled]="!currentFormGroup.get('isCustom').value"
                               [lookupName]="'country'" [contextFormGroup]="currentFormGroup"></app-text-lookup>
              &nbsp;
              <label for="fedCode" aria-haspopup="true" role="tooltip"
                     class="tooltip tooltip-validation tooltip-md"
                     [class.invalid]="isInvalid(currentFormGroup, 'fedCode')">
                <input type="text" id="fedCode" class="itech-control-normal" formControlName="fedCode">
                <app-validation-tooltip [input]="currentFormGroup.controls['fedCode']"></app-validation-tooltip>
              </label>
            </div>

            <div class="form-group">
              <label for="expiresYears">Срок годности</label>
              <label for="expiresYears" aria-haspopup="true" role="tooltip"
                     class="tooltip tooltip-validation tooltip-md"
                     [class.invalid]="isInvalid(currentFormGroup, 'expiresYears')">
                <input type="text" id="expiresYears" placeholder="лет" class="itech-control-small"
                       formControlName="expiresYears">
                <app-validation-tooltip [input]="currentFormGroup.controls['expiresYears']"></app-validation-tooltip>
              </label>
              <label for="expiresMonths" aria-haspopup="true" role="tooltip"
                     class="tooltip tooltip-validation tooltip-md"
                     [class.invalid]="isInvalid(currentFormGroup, 'expiresMonths')">
                <input type="text" id="expiresMonths" placeholder="месяцев" class="itech-control-small"
                       formControlName="expiresMonths">
                <app-validation-tooltip [input]="currentFormGroup.controls['expiresMonths']"></app-validation-tooltip>
              </label>
              <label for="expiresDays" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                     [class.invalid]="isInvalid(currentFormGroup, 'expiresDays')">
                <input type="text" id="expiresDays" placeholder="дней" class="itech-control-small"
                       formControlName="expiresDays">
                <app-validation-tooltip [input]="currentFormGroup.controls['expiresDays']"></app-validation-tooltip>
              </label>
            </div>

            <div class="form-group">
              <label for="packagesCount" class="required">Тип упаковок</label>
              <app-text-lookup [validationTooltipRight]="true" class="itech-control-xlarge" [sorted]="true"
                               [contextControlId]="'packageTypeId'" [contextControlName]="'packageTypeId'"
                               [lookupName]="'drug-package-type'"
                               [contextFormGroup]="currentFormGroup"></app-text-lookup>
            </div>

            <div class="form-group">
              <label for="packageValue" class="required">В одной упаковке</label>
              <label for="packageValue" aria-haspopup="true" role="tooltip"
                     class="tooltip tooltip-validation tooltip-md"
                     [class.invalid]="isInvalid(currentFormGroup, 'packageValue')">
                <input type="text" id="packageValue" placeholder="кол-во" class="itech-control-normal"
                       formControlName="packageValue" (keypress)="processMoneyKeypress($event)">
                <app-validation-tooltip [input]="currentFormGroup.controls['packageValue']"></app-validation-tooltip>
              </label>
              <app-combo-lookup [validationTooltipRight]="true" class="itech-control-medium"
                                [contextControlId]="'packageUnitId'" [sorted]="true"
                                [contextControlName]="'packageUnitId'"
                                [lookupName]="'drug-unit-type/drug'"
                                [contextFormGroup]="currentFormGroup"></app-combo-lookup>
            </div>

            <div class="form-group">
              <label for="manufacturerId">Производитель</label>
              <app-text-lookup [validationTooltipRight]="true" class="itech-control-xlarge"
                               [contextControlId]="'manufacturerId'" [sorted]="true"
                               [contextControlName]="'manufacturerId'"
                               [disabled]="!currentFormGroup.get('isCustom').value"
                               [lookupName]="'drug-manufacturer'"
                               [contextFormGroup]="currentFormGroup"></app-text-lookup>
            </div>

            <div class="form-group itech-double-height">
              <label for="captionNonproprietary">МНН или хим.наименование</label>
              <label for="captionNonproprietary" aria-haspopup="true" role="tooltip"
                     class="tooltip tooltip-validation tooltip-md"
                     [class.invalid]="isInvalid(currentFormGroup, 'captionNonproprietary')">
                <textarea type="text" id="captionNonproprietary" placeholder="МНН или хим.наименование"
                          class="itech-control-xlarge" formControlName="captionNonproprietary"
                          [attr.disabled]="currentFormGroup.get('isCustom').value ? undefined : true">
                </textarea>
                <app-validation-tooltip
                  [input]="currentFormGroup.controls['captionNonproprietary']"></app-validation-tooltip>
              </label>
            </div>

            <div class="form-group itech-double-height">
              <label for="contraindicationsTxt">Противопоказания</label>
              <label for="contraindicationsTxt" aria-haspopup="true" role="tooltip"
                     class="tooltip tooltip-validation tooltip-md"
                     [class.invalid]="isInvalid(currentFormGroup, 'contraindicationsTxt')">
                <textarea type="text" id="contraindicationsTxt" placeholder="Противопоказания"
                          class="itech-control-xlarge" formControlName="contraindicationsTxt"
                          [attr.disabled]="currentFormGroup.get('isCustom').value ? undefined : true">
                </textarea>
                <app-validation-tooltip
                  [input]="currentFormGroup.controls['contraindicationsTxt']"></app-validation-tooltip>
              </label>
            </div>

            <div class="form-group itech-double-height">
              <label for="indicationsTxt">Показания к применению</label>
              <label for="indicationsTxt" aria-haspopup="true" role="tooltip"
                     class="tooltip tooltip-validation tooltip-md"
                     [class.invalid]="isInvalid(currentFormGroup, 'indicationsTxt')">
                <textarea type="text" id="indicationsTxt" placeholder="Показания к применению"
                          class="itech-control-xlarge" formControlName="indicationsTxt"
                          [attr.disabled]="currentFormGroup.get('isCustom').value ? undefined : true">
                </textarea>
                <app-validation-tooltip [input]="currentFormGroup.controls['indicationsTxt']"></app-validation-tooltip>
              </label>
            </div>

            <div class="form-group itech-double-height">
              <label for="storageConditionsTxt">Условия хранения</label>
              <label for="storageConditionsTxt" aria-haspopup="true" role="tooltip"
                     class="tooltip tooltip-validation tooltip-md"
                     [class.invalid]="isInvalid(currentFormGroup, 'storageConditionsTxt')">
                <textarea type="text" id="storageConditionsTxt" placeholder="Условия хранения"
                          class="itech-control-xlarge" formControlName="storageConditionsTxt"
                          [attr.disabled]="currentFormGroup.get('isCustom').value ? undefined : true">
                </textarea>
                <app-validation-tooltip
                  [input]="currentFormGroup.controls['storageConditionsTxt']"></app-validation-tooltip>
              </label>
            </div>

            <div class="form-group itech-double-height">
              <label for="sideEffectsTxt">Побочные действия</label>
              <label for="sideEffectsTxt" aria-haspopup="true" role="tooltip"
                     class="tooltip tooltip-validation tooltip-md"
                     [class.invalid]="isInvalid(currentFormGroup, 'sideEffectsTxt')">
                <textarea type="text" id="sideEffectsTxt" placeholder="Побочные действия"
                          class="itech-control-xlarge" formControlName="sideEffectsTxt"
                          [attr.disabled]="currentFormGroup.get('isCustom').value ? undefined : true">
                </textarea>
                <app-validation-tooltip [input]="currentFormGroup.controls['sideEffectsTxt']"></app-validation-tooltip>
              </label>
            </div>

            <div class="form-group">
              <label for="manualFiles">Инструкции</label>
              <div>
                <ng-container *ngFor="let file of currentFormGroup.controls['manualFiles'].value; index as i">
                  <div class="itech-form-group-one-line" style="width:510px;display:flex">
                    <a class="itech-link align-bottom" style="padding: 0 10px;color:black;display:inline-block;"
                       (click)="downloadFile(file)">
                      {{getPrettyTitle(file)}}
                    </a>
                    <button type="button" class="btn btn-danger btn-link itech-inline-tool-btn" style="margin:6px;"
                            title="Удалить файл" (click)="deleteManualFile(i)">
                      <clr-icon shape="trash" style="transform: inherit;color: #d23b3b"></clr-icon>
                    </button>
                  </div>
                </ng-container>
                <div>
                  <label for="uploadManualFile" style="padding: 0 10px;">
                    <a class="itech-link itech-control-normal" (click)="fileUploadClick()" id="uploadManualFile">
                      <input *ngIf="!newFileToUploadFlag" type="file" #newFileToUploadFlag
                             (change)="fileChanged($event)" style="visibility: hidden;width: 0">
                      <clr-icon shape="upload" style="color: green"></clr-icon>&nbsp;Добавить инструкцию
                    </a>
                  </label>
                </div>

              </div>
            </div>

          </section>
        </div>
      </clr-tab-content>
    </clr-tab>
    <clr-tab>
      <button clrTabLink>Мероприятия</button>
      <clr-tab-content id="eventTypeIds">
        <div class="edit-page-tab" style="max-height:500px;overflow:auto">
          <app-checkbox-select [sorted]="true"
                               [contextControlName]="'eventTypeIds'" [lookupName]="'event-type'"
                               [contextFormGroup]="currentFormGroup"></app-checkbox-select>
        </div>
      </clr-tab-content>
    </clr-tab>
    <clr-tab>
      <button clrTabLink>Заболевания</button>
      <clr-tab-content id="diseaseTypeIds">
        <div class="edit-page-tab" style="min-height:620px;overflow:auto">
          <app-combo-multilookup [classControl]="'itech-control-xlarge'" [itemTitle]="'Болезнь'"
                                 [contextControlName]="'diseaseTypeIds'" [lookupName]="'disease-type'"
                                 [contextFormGroup]="currentFormGroup"></app-combo-multilookup>
        </div>
      </clr-tab-content>
    </clr-tab>
    <clr-tab>
      <button clrTabLink>Животные</button>
      <clr-tab-content id="animalSubtypeIds">
        <div class="edit-page-tab" style="max-height:500px;overflow:auto">
          <app-checkbox-select-with-parent [lookupNameParent]="'animal-type'" [lookupName]="'animal-subtype'"
                                           [contextFormGroup]="currentFormGroup"
                                           [contextControlName]="'animalSubtypeKeys'">
          </app-checkbox-select-with-parent>
        </div>
      </clr-tab-content>
    </clr-tab>
    <clr-tab>
      <button clrTabLink>Отчетность</button>
      <clr-tab-content id="planPstIndicatorTypesYear">
        <div class="edit-page-tab" style="min-height:620px;overflow:auto">
          <div><b>Соответствующие показатели годового плана диагностических исследований</b></div>
          <app-combo-multilookup [classControl]="'itech-control-xlarge'" [itemTitle]="'Показатель'"
                                 [contextControlName]="'planPstIndicatorTypesYear'" [lookupName]="'pst-indicator-type'"
                                 [contextFormGroup]="currentFormGroup"></app-combo-multilookup>
          <div><b>Соответствующие показатели квартального плана диагностических исследований</b></div>
          <app-combo-multilookup [classControl]="'itech-control-xlarge'" [itemTitle]="'Показатель'"
                                 [contextControlName]="'planPstIndicatorTypesQuarter'"
                                 [lookupName]="'pst-indicator-type'"
                                 [contextFormGroup]="currentFormGroup"></app-combo-multilookup>
          <div><b>Соответствующие показатели квартальной заявки на препараты/диагностикумы</b></div>
          <app-combo-multilookup [classControl]="'itech-control-xlarge'" [itemTitle]="'Показатель'"
                                 [contextControlName]="'requirePstIndicatorTypesQuarter'"
                                 [lookupName]="'pst-indicator-type'"
                                 [contextFormGroup]="currentFormGroup"></app-combo-multilookup>
          <div><b>Разделы формы 4-ВЕТ-А, в которых учитывать диагностикум</b></div>
          <app-checkbox-select [contextControlName]="'animalTypes4VetA'" [lookupName]="'animal-types-4-vet-a'"
                               [contextFormGroup]="currentFormGroup"></app-checkbox-select>
        </div>
      </clr-tab-content>
    </clr-tab>
  </clr-tabs>
</div>
