import { OnChanges, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { QuarantineDataService } from '../../../logic/services/quarantine-data.service';
import { StringHelper } from '../../../helpers/string-helper';
import { DateHelper } from '../../../helpers/date-helper';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { combineLatest } from 'rxjs';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { SecurityService } from '../../../logic/services/security.service';
import { ActivatedRoute } from '@angular/router';
var QuarantineSearchResultsComponent = /** @class */ (function () {
    function QuarantineSearchResultsComponent(waitingOverlay, quarantineService, navigationService, lookupService, dataCachingService, securityService, route, fb) {
        this.waitingOverlay = waitingOverlay;
        this.quarantineService = quarantineService;
        this.navigationService = navigationService;
        this.lookupService = lookupService;
        this.dataCachingService = dataCachingService;
        this.securityService = securityService;
        this.route = route;
        this.fb = fb;
        this.model = {};
        this.rowData = [];
        this.rowDataWithViolations = [];
        this.onlyWithViolations = false;
        this.isInvalid = FormHelper.isInvalid;
        this.getRowStyle = QuarantineSearchResultsComponent.getRowStyle;
        this.gridColumnDefs = [
            {
                headerName: '', field: 'isViolationDeadlineFile', excludeExport: true, width: 40,
                cellRenderer: function (params) {
                    if (!params.value) {
                        return '<clr-icon shape="check" style="color:green"></clr-icon>';
                    }
                    else {
                        return '<clr-icon shape="history" style="color:red" title="Нарушены сроки прикрепления документов"></clr-icon>';
                    }
                },
            },
            {
                headerName: '№', field: 'id', width: 75,
            },
            {
                headerName: 'Заболевание', field: 'diseaseCaption', width: 195,
            },
            {
                headerName: 'Неблагополучный пункт', field: 'addressCaption', width: 285,
                filterParams: {
                    cellRenderer: function (params) {
                        if (params.value) {
                            return '<div title="' + StringHelper.saveQuotes(params.value) + '">' + params.value + '</div>';
                        }
                        else {
                            return '(Пусто)';
                        }
                    }
                },
            },
            {
                headerName: 'Срок действия', width: 155,
                valueGetter: function (params) {
                    if (!params || !params.data || !params.data.dateFrom) {
                        return '';
                    }
                    return 'с ' + StringHelper.getRuDate(DateHelper.toDate(params.data.dateFrom)) +
                        (params.data.dateToInclusive ? ' по ' + StringHelper.getRuDate(DateHelper.toDate(params.data.dateToInclusive)) : '');
                },
                comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
                    if ((!nodeA || !nodeA.data) && (!nodeB || !nodeB.data)) {
                        return 0;
                    }
                    if (!nodeA.data.dateFrom && !nodeB.data.dateFrom) {
                        return 0;
                    }
                    if (!nodeA.data.dateFrom) {
                        return -1;
                    }
                    if (!nodeB.data.dateFrom) {
                        return 1;
                    }
                    return DateHelper.isAfter(nodeA.data.dateFrom, nodeB.data.dateFrom);
                }
            },
            {
                headerName: 'Количество попавших под карантин', width: 155,
                valueGetter: function (params) {
                    if (!params || !params.data) {
                        return 0;
                    }
                    return (+params.data.countTrappedStable || 0) + (+params.data.countTrappedAnimal || 0);
                },
                cellRenderer: function (params) {
                    if (!params || !params.data) {
                        return '';
                    }
                    return "<clr-icon shape=\"building\" style=\"color:green\"></clr-icon>\n                &nbsp;" + (params.data.countTrappedStable || 0) + "&nbsp;&nbsp;\n                <clr-icon shape=\"piggy-bank\" style=\"color:green\"></clr-icon>\n                &nbsp;" + (params.data.countTrappedAnimal || 0);
                }
            },
            {
                headerName: 'Статус', field: 'statusCaption', width: 170,
            },
        ];
    }
    Object.defineProperty(QuarantineSearchResultsComponent.prototype, "filterFormGroup", {
        get: function () {
            return this.model ? this.model.filterFormGroup : undefined;
        },
        enumerable: true,
        configurable: true
    });
    QuarantineSearchResultsComponent.getDefaultModel = function (fb, defaultFilterParams) {
        return {
            searchResults: [],
            selectedRows: [],
            filterFormGroup: fb ? QuarantineSearchResultsComponent.buildSearchForm(fb, defaultFilterParams) : undefined
        };
    };
    QuarantineSearchResultsComponent.buildSearchForm = function (fb, defaultFilterParams) {
        if (defaultFilterParams === void 0) { defaultFilterParams = {}; }
        return fb.group({
            cadNo: defaultFilterParams.cadNo,
            regionId: defaultFilterParams.regionId,
            cityId: defaultFilterParams.cityId,
            diseaseTypeId: defaultFilterParams.diseaseTypeId,
            dateFrom: defaultFilterParams.dateFrom,
            dateToInclusive: defaultFilterParams.dateToInclusive,
            noFilteredByRights: defaultFilterParams.noFilteredByRights,
            currentStatusId: defaultFilterParams.currentStatusId,
            forceStartSearch: defaultFilterParams.forceStartSearch || false,
        });
    };
    QuarantineSearchResultsComponent.getRowStyle = function (params) {
        if (params.data && params.data.currentStatusId === 0) {
            return { color: 'black' };
        }
        if (params.data && params.data.currentStatusId === 5) {
            return { color: 'silver' };
        }
        return { color: 'green' };
    };
    QuarantineSearchResultsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.paramMap
            .subscribe(function () {
            var existing = _this.dataCachingService.getCachedData('QuarantineSearchResults', '1');
            if (existing) {
                _this.model = existing;
                _this.rowData = _this.model.searchResults;
                if (_this.model.filterFormGroup && _this.model.filterFormGroup.get('forceStartSearch').value) {
                    _this.refreshResults();
                }
            }
            else {
                _this.model = QuarantineSearchResultsComponent.getDefaultModel(_this.fb);
                _this.dataCachingService.addToCache('QuarantineSearchResults', '1', _this.model);
            }
        });
    };
    QuarantineSearchResultsComponent.prototype.ngOnChanges = function () {
        var existing = this.dataCachingService.getCachedData('QuarantineSearch', 'model');
        this.rowDataWithViolations = this.dataCachingService.getCachedData('QuarantineSearch', 'rowDataWithViolations') || [];
        if (existing && existing.searchResults) {
            this.model = existing;
            this.onlyWithViolations = this.dataCachingService.getCachedData('QuarantineSearch', 'paramsVisibleViolations') || false;
            this.rowData = this.onlyWithViolations ? this.rowDataWithViolations : this.model.searchResults;
        }
    };
    QuarantineSearchResultsComponent.prototype.refreshResults = function () {
        var _this = this;
        if (!this.model.filterFormGroup.valid) {
            return;
        }
        this.waitingOverlay.StartWaiting();
        this.quarantineService.search(this.model.filterFormGroup.value)
            .subscribe(function (data) {
            if (!data.length) {
                _this.model.searchResults = [];
                _this.model.selectedRows = [];
                _this.rowData = [];
                _this.rowDataWithViolations = [];
                _this.waitingOverlay.EndWaiting();
                return;
            }
            data.forEach(function (val, ix) {
                return combineLatest([_this.lookupService.getLookupCaption(val.diseaseTypeId, 'disease-type'),
                    _this.lookupService.getLookupCaption(val.currentStatusId, 'quarantine-status'),
                    _this.lookupService.getLookupCaption(val.badLocalityRegionId, 'addr-by-region-city-ids', null, val.badLocalityCityId)])
                    .subscribe(function (_a) {
                    var diseaseCaption = _a[0], statusCaption = _a[1], addressCaption = _a[2];
                    val['diseaseCaption'] = diseaseCaption;
                    val['addressCaption'] = addressCaption;
                    val['statusCaption'] = statusCaption;
                    if (ix === data.length - 1) {
                        _this.model.searchResults = data;
                        _this.model.selectedRows = [];
                        _this.rowDataWithViolations = [];
                        data.forEach(function (el) {
                            if (el.isViolationDeadlineFile) {
                                _this.rowDataWithViolations.push(el);
                            }
                        });
                        _this.rowData = _this.onlyWithViolations ? _this.rowDataWithViolations : data;
                        _this.dataCachingService.addToCache('QuarantineSearch', 'model', _this.model);
                        _this.dataCachingService.addToCache('QuarantineSearch', 'rowDataWithViolations', _this.rowDataWithViolations);
                        _this.waitingOverlay.EndWaiting();
                    }
                });
            });
        }, function () {
            _this.waitingOverlay.EndWaiting();
        });
    };
    QuarantineSearchResultsComponent.prototype.getLookupNameForCity = function () {
        return 'addr-city' +
            (this.model.filterFormGroup && this.model.filterFormGroup.get('regionId').value
                ? this.model.filterFormGroup.get('regionId').value : '');
    };
    QuarantineSearchResultsComponent.prototype.performQuarantineCard = function (quarantineData) {
        this.navigationService.performQuarantineEditing(quarantineData.id);
    };
    QuarantineSearchResultsComponent.prototype.filterEnterPressed = function ($event) {
        if ($event.key === 'Enter') {
            this.refreshResults();
        }
    };
    QuarantineSearchResultsComponent.prototype.changeRowData = function () {
        this.dataCachingService.addToCache('QuarantineSearch', 'paramsVisibleViolations', this.onlyWithViolations);
        if (this.onlyWithViolations) {
            this.rowData = this.rowDataWithViolations;
        }
        else {
            this.rowData = this.model.searchResults;
        }
    };
    return QuarantineSearchResultsComponent;
}());
export { QuarantineSearchResultsComponent };
