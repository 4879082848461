/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@clr/angular";
import * as i2 from "@angular/common";
import * as i3 from "../../logic/pipes/lookup-source.pipe";
import * as i4 from "../../logic/services/lookup-source.service";
import * as i5 from "../../logic/services/address-person-fio-cache.service";
import * as i6 from "@angular/platform-browser";
import * as i7 from "./ag-grid-lookup-renderer.component";
import * as i8 from "../../logic/services/app-navigation.service";
var styles_AgGridLookupRendererComponent = [];
var RenderType_AgGridLookupRendererComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AgGridLookupRendererComponent, data: {} });
export { RenderType_AgGridLookupRendererComponent as RenderType_AgGridLookupRendererComponent };
function View_AgGridLookupRendererComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-link"], ["style", "position:absolute;top:7px;left:0;padding:0;margin:0;border:none;min-width:20px;max-height:20px;min-height:20px;"], ["type", "button"]], [[8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.performAction() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "clr-icon", [["shape", "angle right"], ["style", "vertical-align:super"]], null, null, null, null, null)), i0.ɵdid(2, 16384, null, 0, i1.ClrIconCustomTag, [], null, null)], null, function (_ck, _v) { var currVal_0 = "\u041F\u0435\u0440\u0435\u0439\u0442\u0438 \u043A \u043A\u0430\u0440\u0442\u043E\u0447\u043A\u0435"; _ck(_v, 0, 0, currVal_0); }); }
function View_AgGridLookupRendererComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AgGridLookupRendererComponent_2)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 4, "div", [["style", "min-height:24px;padding-left:5px"]], [[8, "title", 0], [8, "innerHTML", 1]], null, null, null, null)), i0.ɵppd(4, 6), i0.ɵpid(131072, i2.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵppd(6, 5), i0.ɵpid(131072, i2.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.params.value && _co.equalsSome(_co.params.lookupName, "agent", "animal", "product", "stable")); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 5).transform(i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent, 0), _co.params.value, (_co.params.lookupName + (_co.params.value2 || "")), true, false, false, true)))); var currVal_2 = i0.ɵunv(_v, 3, 1, i0.ɵnov(_v, 7).transform(i0.ɵunv(_v, 3, 1, _ck(_v, 6, 0, i0.ɵnov(_v.parent, 0), _co.params.value, (_co.params.lookupName + (_co.params.value2 || "")), true, true, true)))); _ck(_v, 3, 0, currVal_1, currVal_2); }); }
function View_AgGridLookupRendererComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "clr-icon", [["shape", "angle up"]], null, null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i1.ClrIconCustomTag, [], null, null)], null, null); }
function View_AgGridLookupRendererComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "clr-icon", [["shape", "angle down"]], null, null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i1.ClrIconCustomTag, [], null, null)], null, null); }
function View_AgGridLookupRendererComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [], [[2, "first-grouping", null], [2, "second-grouping", null], [2, "third-grouping", null], [2, "fourth-grouping", null], [2, "fifth-grouping", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickExpanded() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AgGridLookupRendererComponent_5)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AgGridLookupRendererComponent_6)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(5, null, [" ", "\u00A0 ", " "])), i0.ɵpid(131072, i2.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.params.node.expanded; _ck(_v, 2, 0, currVal_5); var currVal_6 = !_co.params.node.expanded; _ck(_v, 4, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.params.node.rowGroupIndex === 0); var currVal_1 = (_co.params.node.rowGroupIndex === 1); var currVal_2 = (_co.params.node.rowGroupIndex === 2); var currVal_3 = (_co.params.node.rowGroupIndex === 3); var currVal_4 = (_co.params.node.rowGroupIndex === 4); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_7 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform(_co.getLookup$())); var currVal_8 = (_co.params.comment ? _co.params.comment : ""); _ck(_v, 5, 0, currVal_7, currVal_8); }); }
function View_AgGridLookupRendererComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "itech-link in-table-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateAction() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), i0.ɵpid(131072, i2.AsyncPipe, [i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.getLookup$())); _ck(_v, 1, 0, currVal_0); }); }
function View_AgGridLookupRendererComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), i0.ɵpid(131072, i2.AsyncPipe, [i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.getLookup$())); _ck(_v, 1, 0, currVal_0); }); }
function View_AgGridLookupRendererComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AgGridLookupRendererComponent_4)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AgGridLookupRendererComponent_7)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AgGridLookupRendererComponent_8)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isGroup; _ck(_v, 1, 0, currVal_0); var currVal_1 = ((!_co.isGroup && _co.params) && _co.params.navigateAction); _ck(_v, 3, 0, currVal_1); var currVal_2 = ((!_co.isGroup && _co.params) && !_co.params.navigateAction); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_AgGridLookupRendererComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i3.LookupSourcePipe, [i4.LookupSourceService, i5.AddressPersonFioCacheService, i6.DomSanitizer]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AgGridLookupRendererComponent_1)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["noPipe", 2]], null, 0, null, View_AgGridLookupRendererComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isPipe; var currVal_1 = i0.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_AgGridLookupRendererComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-ag-grid-lookup-renderer", [], null, null, null, View_AgGridLookupRendererComponent_0, RenderType_AgGridLookupRendererComponent)), i0.ɵdid(1, 49152, null, 0, i7.AgGridLookupRendererComponent, [i5.AddressPersonFioCacheService, i4.LookupSourceService, i8.AppNavigationService], null, null)], null, null); }
var AgGridLookupRendererComponentNgFactory = i0.ɵccf("app-ag-grid-lookup-renderer", i7.AgGridLookupRendererComponent, View_AgGridLookupRendererComponent_Host_0, {}, {}, []);
export { AgGridLookupRendererComponentNgFactory as AgGridLookupRendererComponentNgFactory };
