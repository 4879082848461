<span *ngIf="!editing && this.originalCellValue">
  {{this.originalCellValue|lookup:lookupName|async}}
</span>
<ng-container *ngIf="editing && lookupItems">
  <div class="select" [class.itech-focused]="true">
    <select #input [(ngModel)]="cellValueEdit" (change)="onChange($event)">
      <option></option>
      <option *ngFor="let item of lookupItems" [value]="item.id" [title]="item.caption">
        {{item.caption}}
      </option>
    </select>
  </div>
</ng-container>
<div class="it-focus-border" *ngIf="focused" [class.it-cell-storing]="model.__cl_storing_indicator"
     [class.it-cell-error]="model.__cl_storing_error">
</div>
<div class="it-cell-error-text" *ngIf="focused && model.__cl_storing_error">
  {{model.__cl_storing_error}}
</div>
