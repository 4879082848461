<clr-tabs>
  <clr-tab>
    <button clrTabLink>Избранные ПО</button>
    <ng-template [(clrIfActive)]="model.favouriteStablesTabActive">
      <clr-tab-content id="favouriteStableTabActive">
        <div class="edit-page-tab">
          <app-user-favourite-stables></app-user-favourite-stables>
        </div>
      </clr-tab-content>
    </ng-template>
  </clr-tab>
  <clr-tab>
    <button clrTabLink><clr-icon shape="flask"></clr-icon>Выданные препараты</button>
    <ng-template [(clrIfActive)]="model.reservedDrugsTabActive">
      <clr-tab-content>
        <div class="edit-page-tab">
          <app-user-reserved-drugs></app-user-reserved-drugs>
        </div>
      </clr-tab-content>
    </ng-template>
  </clr-tab>
  <clr-tab>
    <button clrTabLink><clr-icon shape="tag"></clr-icon>Тэги автозаполнения</button>
    <ng-template [(clrIfActive)]="model.tagsAutofillTabActive">
      <clr-tab-content>
        <div class="edit-page-tab">
          <app-user-tags-autofill></app-user-tags-autofill>
        </div>
      </clr-tab-content>
    </ng-template>
  </clr-tab>
</clr-tabs>
