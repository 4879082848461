import * as tslib_1 from "tslib";
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { MetadataService } from '../../../logic/services/metadata.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MetaBrowseBaseComponent } from '../meta-browse.base.component';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { JobRunnerUiService } from '../../../ui/infrastructure/job-runner-ui.service';
import { StringHelper } from '../../../helpers/string-helper';
import { FormHelper } from '../../../ui/controls/form-helper';
import { DataCachingService } from '../../../logic/services/data-caching.service';
var AnimalSubtypeBrowseComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AnimalSubtypeBrowseComponent, _super);
    function AnimalSubtypeBrowseComponent(route, metadataService, alertService, globalWaitingOverlayService, lookupService, bookmarkService, fb, jobRunnerUiService, dataCachingService) {
        var _this = _super.call(this, route, metadataService, alertService, globalWaitingOverlayService, lookupService, fb, bookmarkService, dataCachingService) || this;
        _this.jobRunnerUiService = jobRunnerUiService;
        _this.gridColumnDefs = [
            { headerName: '№', field: 'id', width: 100 },
            { headerName: 'Тип', field: 'animalTypeCaption', tooltipField: 'animalTypeCaption', width: 200 },
            { headerName: 'Наименование', field: 'caption', tooltipField: 'caption', width: 350 },
            { headerName: 'Pin', field: 'pin', tooltipField: 'pin', width: 60 },
            { headerName: 'Guid (Меркурий)', field: 'guid', tooltipField: 'guid', width: 260 },
            {
                headerName: 'Удалено', field: 'dateDeleted', width: 100,
                valueFormatter: function (params) { return StringHelper.getRuTimeWithSeconds(params.value); },
            },
        ];
        return _this;
    }
    AnimalSubtypeBrowseComponent.prototype.getMetaTitle = function () {
        return 'Подвиды животных';
    };
    AnimalSubtypeBrowseComponent.prototype.getMetaName = function () {
        return 'animal-subtype';
    };
    AnimalSubtypeBrowseComponent.prototype.getId = function (formGroup) {
        return formGroup.get('animalTypeId').value && formGroup.get('id').value
            ? formGroup.get('animalTypeId').value + '/' + formGroup.get('id').value
            : undefined;
    };
    AnimalSubtypeBrowseComponent.prototype.refreshResults = function () {
        var _this = this;
        this.globalWaitingOverlayService.StartWaiting();
        this.lookupService.getLookupObj('animal-type').subscribe(function (animalTypeLookup) {
            _this.metadataService.getMetadata(_this.getMetaName()).subscribe({
                next: function (data) {
                    data.forEach(function (el) {
                        el.animalTypeCaption = animalTypeLookup[el.animalTypeId] || '<<Неизвестный вид>>';
                    });
                    _this._searchResults = data;
                    _this.lookupService.invalidateLookup(_this.getMetaName());
                    _this.dataCachingService.addToCache(_this.getMetaName() + '_Data', '1', data);
                    _this.globalWaitingOverlayService.EndWaiting();
                }, error: function () {
                    _this.globalWaitingOverlayService.EndWaiting();
                }
            });
        });
    };
    AnimalSubtypeBrowseComponent.prototype.getGroupDef = function (row) {
        var _this = this;
        return {
            id: [row.id, Validators.compose([Validators.required, Validators.pattern(/^\d{1,5}$/)])],
            animalTypeId: [row.animalTypeId, Validators.compose([Validators.required])],
            caption: [row.caption, Validators.required],
            guid: row.guid,
            pin: row.pin,
            nomenclatureAnimalPinId: row.nomenclatureAnimalPinId,
            eventsPlanning: (row.eventsPlanning && row.eventsPlanning.length
                ? this.fb.array(row.eventsPlanning.map(function (el, ix) { return _this.fb.group(_this.buildGroupDefEventPlanning(el || {})); })
                    .sort(function (a, b) {
                    return (a.get('diseaseTypeId').value - b.get('diseaseTypeId').value) + (a.get('eventTypeId').value - b.get('eventTypeId').value);
                }))
                : this.fb.array([])),
            isCustom: row.isCustom || false,
            onlyIndividualAccounting: row.onlyIndividualAccounting || false,
            availableForExternal: row.availableForExternal || false,
            captionForExternal: [row.captionForExternal, FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl && ctrl.parent && ctrl.parent.get('availableForExternal').value; })],
        };
    };
    AnimalSubtypeBrowseComponent.prototype.buildGroupDefEventPlanning = function (val) {
        var _this = this;
        return {
            animalTypeId: val.animalTypeId,
            animalSubtypeId: val.animalSubtypeId,
            ownerTypeId: [val.ownerTypeId, Validators.required],
            diseaseTypeId: [val.diseaseTypeId, Validators.required],
            eventTypeId: [val.eventTypeId, Validators.required],
            diagnosticTypeIds: this.fb.array((val.diagnosticTypeIds || []).map(function (el) { return _this.fb.control(el); })),
            testMaterialId: val.testMaterialId,
            periodicityDay: [val.periodicityDay, Validators.compose([Validators.required, Validators.pattern(/^\d{1,4}$/), Validators.min(0)])],
            dateFrom: [val.dateFrom, Validators.compose([Validators.required, FormHelper.validateDateTimePicker()])]
        };
    };
    AnimalSubtypeBrowseComponent.prototype.runUpdateAnimalTypesOp = function () {
        var _this = this;
        setTimeout(function () {
            _this.jobRunnerUiService.runOperation(8 /* "Обновление справочника животных из Меркурия"*/, {
                type: 'animalTypeSync'
            }, undefined, undefined, false)
                .subscribe(function () {
                _this.alertService.success('Обновление справочника животных из Меркурия успешно завершено');
                _this.refreshResults();
            });
        }, 1);
    };
    AnimalSubtypeBrowseComponent.prototype.getRowStyle = function (params) {
        if (params.data && params.data.dateDeleted) {
            return { color: 'silver' };
        }
    };
    AnimalSubtypeBrowseComponent.prototype.getEventsPlanning = function () {
        return this.currentFormGroup.get('eventsPlanning');
    };
    AnimalSubtypeBrowseComponent.prototype.addNewEventPlanning = function () {
        this.getEventsPlanning().push(this.fb.group(this.buildGroupDefEventPlanning({})));
    };
    AnimalSubtypeBrowseComponent.prototype.getFormGroup = function (data) {
        return data;
    };
    AnimalSubtypeBrowseComponent.prototype.runFirstEventPlanningOp = function () {
        this.jobRunnerUiService.runOperation(14 /* "Планирование первоначальных мероприятий"*/, {
            type: 'firstEventPlanning',
            animalTypeId: this.currentFormGroup.get('animalTypeId').value,
            animalSubtypeId: this.currentFormGroup.get('id').value
        }, undefined, undefined, false)
            .subscribe({ next: function () { } });
    };
    return AnimalSubtypeBrowseComponent;
}(MetaBrowseBaseComponent));
export { AnimalSubtypeBrowseComponent };
