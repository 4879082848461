import { FormGroup } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
var QueryStringFilterComponent = /** @class */ (function () {
    function QueryStringFilterComponent() {
        this.class = 'form-group';
    }
    QueryStringFilterComponent.prototype.isInvalid = function (cname) {
        return FormHelper.isInvalid(this.contextFormGroup, cname);
    };
    Object.defineProperty(QueryStringFilterComponent.prototype, "customFilterChecked", {
        get: function () {
            return this.contextFormGroup && (+this.contextFormGroup.get('_par_' + this.parameterName + '_filter').value) === 109;
        },
        set: function (val) {
            if (this.contextFormGroup) {
                this.contextFormGroup.get('_par_' + this.parameterName + '_filter').setValue(val ? 109 : 0);
                this.contextFormGroup.markAsDirty();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QueryStringFilterComponent.prototype, "noValueChecked", {
        get: function () {
            return this.contextFormGroup && (+this.contextFormGroup.get('_par_' + this.parameterName + '_filter').value) === 1;
        },
        set: function (val) {
            if (this.contextFormGroup) {
                this.contextFormGroup.get('_par_' + this.parameterName + '_filter').setValue(val ? 1 : 0);
                this.contextFormGroup.markAsDirty();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QueryStringFilterComponent.prototype, "filtered", {
        get: function () {
            return !!this.contextFormGroup.get('_par_' + this.parameterName + '_filter').value
                || !!this.contextFormGroup.get('_par_' + this.parameterName).value;
        },
        enumerable: true,
        configurable: true
    });
    return QueryStringFilterComponent;
}());
export { QueryStringFilterComponent };
