import { StringHelper } from '../../helpers/string-helper';
import { LookupSourceService } from './lookup-source.service';
import { AsyncSubject, combineLatest, forkJoin, Observable } from 'rxjs/index';
import { SecurityService } from './security.service';
import { map } from 'rxjs/internal/operators';
import { DateHelper } from '../../helpers/date-helper';
import { FormHelper } from '../../ui/controls/form-helper';
import { FormBuilder } from '@angular/forms';
import { first } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./lookup-source.service";
import * as i2 from "@angular/forms";
import * as i3 from "./security.service";
var GroupOperationReportsMetadataService = /** @class */ (function () {
    function GroupOperationReportsMetadataService(lookupSourceService, fb, securityService) {
        var _this = this;
        this.lookupSourceService = lookupSourceService;
        this.fb = fb;
        this.securityService = securityService;
        this.reports$ = new AsyncSubject();
        this.reportGroups$ = new AsyncSubject();
        var reportGroupsObj = {};
        // универсалный компонент отчетов также можно использовать для отчетов по выборкам
        // тип отчетов при этом будет query-<entityTypeId>. entityTypeId = 1 - выборка по гражданам
        // если добавится новый тип отчетов - нужно добывить его ниже в reportKinds
        var reportKinds = ['aggregated', 'query-1'];
        forkJoin(reportKinds.map(function (reportKind) { return _this.lookupSourceService.getLookup('report-' + reportKind).pipe(first()); }))
            .subscribe({
            next: function (reportsLists) {
                var reportsRaw = [].concat.apply([], reportsLists);
                var reportGroups = [];
                var reports = {};
                reportsRaw.forEach(function (reportRaw) {
                    var report = GroupOperationReportsMetadataService.buildReportFromRawMetadata(reportRaw);
                    reports[report.opCode] = report;
                });
                for (var rname in reports) {
                    if (reports.hasOwnProperty(rname)) {
                        var report = reports[rname];
                        report.operationParamsCode = rname;
                        if (!reportGroupsObj[report.group]) {
                            reportGroupsObj[report.group] = { caption: report.group, reports: [] };
                            reportGroups.push(reportGroupsObj[report.group]);
                        }
                        reportGroupsObj[report.group].reports.push(report);
                    }
                }
                _this.reportGroups$.next(reportGroups);
                _this.reportGroups$.complete();
                _this.reports$.next(reports);
                _this.reports$.complete();
            }
        });
    }
    GroupOperationReportsMetadataService.getDefaultValue = function (paramDef, userInfo) {
        if (paramDef.defaultValue === '$cmonth') {
            return StringHelper.getISODate(DateHelper.startOfTheMonth(new Date()));
        }
        else if (paramDef.defaultValue === '$cyear') {
            return StringHelper.getISODate(DateHelper.startOfTheYear(new Date()));
        }
        else if (paramDef.defaultValue === '$cquarter') {
            return StringHelper.getISODate(DateHelper.startOfTheQuarter(new Date()));
        }
        else if (paramDef.defaultValue === '$emonth') {
            return StringHelper.getISODate(DateHelper.endOfTheMonthByDate(new Date()));
        }
        else if (paramDef.defaultValue === '$cnextMonth') {
            return StringHelper.getISODate(DateHelper.startOfTheNextMonth(new Date()));
        }
        else if (paramDef.defaultValue === '$cdate') {
            return StringHelper.getISODate(new Date());
        }
        else if (paramDef.defaultValue === '$emptylist') {
            return [];
        }
        else if (paramDef.defaultValue === '$institution') {
            return +userInfo.institutionId;
        }
        else {
            return paramDef.defaultValue;
        }
    };
    GroupOperationReportsMetadataService.buildReportFromRawMetadata = function (reportRaw) {
        return {
            id: reportRaw.id,
            opCode: 'op' + reportRaw.id,
            operationTypeId: reportRaw.jobTypeId,
            group: reportRaw.groupCaption || 'Прочие отчеты',
            caption: reportRaw.caption,
            operationTitle: 'Создание отчета \'' + reportRaw.caption + '\'',
            customParameters: reportRaw.customParameters
        };
    };
    GroupOperationReportsMetadataService.prototype.getMassReportGroups$ = function () {
        return this.reportGroups$;
    };
    GroupOperationReportsMetadataService.prototype.getReportMetadata$ = function (opCode) {
        return this.reports$.pipe(map(function (reports) { return reports[opCode]; }));
    };
    GroupOperationReportsMetadataService.prototype.getReportMetadataByJobTypeId$ = function (jobTypeId, reportId) {
        return this.reports$.pipe(map(function (reports) {
            for (var rname in reports) {
                if (reports.hasOwnProperty(rname) && reports[rname].operationTypeId === jobTypeId
                    && (!reportId || reports[rname].id === reportId)) {
                    return reports[rname];
                }
            }
            return undefined;
        }));
    };
    GroupOperationReportsMetadataService.prototype.getOpParamsGroupDef$ = function (params, opCode) {
        var _this = this;
        return this.getReportMetadata$(opCode).pipe(map(function (report) {
            if (!report) {
                return undefined;
            }
            var groupDef = { exportSur: false };
            report.customParameters.forEach(function (paramDef) {
                if (+paramDef.dataType === 4) { // type: array
                    groupDef[paramDef.code] = _this.fb.array([]);
                }
                else {
                    groupDef[paramDef.code] = [params.get(paramDef.code)];
                }
                if (+paramDef.dataType === 2) { // type: DateTime
                    groupDef[paramDef.code].push(FormHelper.validateDateTimePicker());
                }
            });
            groupDef.reportId = report.id;
            return groupDef;
        }));
    };
    GroupOperationReportsMetadataService.prototype.getOpDefaultParams$ = function (opCode) {
        return combineLatest([
            this.getReportMetadata$(opCode),
            this.securityService.getUserInfo()
        ]).pipe(map(function (_a) {
            var report = _a[0], userInfo = _a[1];
            if (!report) {
                return undefined;
            }
            var groupDef = {};
            report.customParameters.forEach(function (paramDef) {
                groupDef[paramDef.code] = GroupOperationReportsMetadataService.getDefaultValue(paramDef, userInfo);
            });
            groupDef.uniqueTag = 'new$' + Math.floor(Math.random() * 100000000000) + 1;
            return groupDef;
        }));
    };
    GroupOperationReportsMetadataService.ngInjectableDef = i0.defineInjectable({ factory: function GroupOperationReportsMetadataService_Factory() { return new GroupOperationReportsMetadataService(i0.inject(i1.LookupSourceService), i0.inject(i2.FormBuilder), i0.inject(i3.SecurityService)); }, token: GroupOperationReportsMetadataService, providedIn: "root" });
    return GroupOperationReportsMetadataService;
}());
export { GroupOperationReportsMetadataService };
