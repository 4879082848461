import {Component, Input} from '@angular/core';
import {FormHelper} from './form-helper';

@Component({
  selector: 'app-date-string-fields',
  template: `
      <div [formGroup]="contextFormGroup">
          <label for="day + {{controlNameDate +'Day_' + rndToken}}" [class.required]="isRequiredDayAndHour(contextFormGroup)">День</label>
          <label for="day + {{controlNameDate +'Day_' + rndToken}}" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md"
                 [class.invalid]="isInvalid(controlNameDate +'Day')">
              <input type="number" min="1" max="31" id="day + {{controlNameDate +'Day_' + rndToken}}" class="itech-control-small"
                     formControlName="{{controlNameDate +'Day'}}" style="width:70px;padding:0;text-align:center">
          </label>
          &nbsp;&nbsp;&nbsp;
          <label for="month + {{controlNameDate +'Month_' + rndToken}}" class="required">Месяц</label>
          <label for="month + {{controlNameDate +'Month_' + rndToken}}" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md"
                 [class.invalid]="isInvalid(controlNameDate +'Month')">
              <input type="number" min="1" max="12" id="month + {{controlNameDate +'Month_' + rndToken}}" class="itech-control-small"
                     formControlName="{{controlNameDate +'Month'}}" style="width:70px;padding:0;text-align:center">
          </label>
          &nbsp;&nbsp;&nbsp;
          <label for="year + {{controlNameDate +'Year_' + rndToken}}" class="required">Год</label>
          <label for="year + {{controlNameDate +'Year_' + rndToken}}" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md"
                 [class.invalid]="isInvalid(controlNameDate +'Year')">
              <input type="number" min="1917" id="year + {{controlNameDate +'Year_' + rndToken}}" class="itech-control-small"
                     formControlName="{{controlNameDate +'Year'}}" style="width:70px;padding:0;text-align:center">
          </label>
          &nbsp;&nbsp;&nbsp;
          <label *ngIf="withTime" for="hour + {{controlNameDate +'Hour_' + rndToken}}"
                 [class.required]="isRequiredDayAndHour(contextFormGroup)">Час</label>
          <label *ngIf="withTime" for="hour + {{controlNameDate +'Hour_' + rndToken}}" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md" [class.invalid]="isInvalid(controlNameDate +'Hour')">
              <input type="number" min="0" max="23" id="hour + {{controlNameDate +'Hour_' + rndToken}}" class="itech-control-small"
                     formControlName="{{controlNameDate +'Hour'}}" style="width:75px;padding:0;text-align:center">
          </label>
      </div>`,
  styles: [`
      label.required:after {
        content: '*';
        font-size: .58479532rem;
        line-height: .5rem;
        color: #c92100;
        margin-left: 0.25rem;
      }
  `]
})
export class AppDateStringFieldsComponent {

  @Input() contextFormGroup;
  @Input() withTime = true;
  @Input() controlNameDate: string;
  @Input() isRequiredDayAndHour: (data) => false;

  rndToken = Math.floor(Math.random() * 1000000);

  isInvalid(controlName) {
    return FormHelper.isInvalid(this.contextFormGroup, controlName);
  }
}
