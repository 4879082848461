/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../ui/controls/app-grid.component.ngfactory";
import * as i2 from "../../ui/controls/app-grid.component";
import * as i3 from "../../logic/services/data-caching.service";
import * as i4 from "../../logic/services/security.service";
import * as i5 from "@angular/common";
import * as i6 from "@clr/angular";
import * as i7 from "./user-reserved-drugs.component";
import * as i8 from "../../ui/infrastructure/global-waiting-overlay.service";
import * as i9 from "../../logic/services/bookmark.service";
import * as i10 from "../../logic/services/drug-data.service";
import * as i11 from "../../logic/services/app-navigation.service";
var styles_UserReservedDrugsComponent = [];
var RenderType_UserReservedDrugsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_UserReservedDrugsComponent, data: {} });
export { RenderType_UserReservedDrugsComponent as RenderType_UserReservedDrugsComponent };
function View_UserReservedDrugsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["style", "padding:5px;width:100%;min-height:88%"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "app-grid", [], null, [[null, "rowClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("rowClicked" === en)) {
        var pd_0 = (_co.navigateToDrug($event.data.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_AppGridComponent_0, i1.RenderType_AppGridComponent)), i0.ɵdid(3, 114688, null, 0, i2.AppGridComponent, [i3.DataCachingService, i4.SecurityService], { uniqueGridName: [0, "uniqueGridName"], columnDefs: [1, "columnDefs"], rowData: [2, "rowData"], frameworkComponents: [3, "frameworkComponents"], exportFileName: [4, "exportFileName"] }, { rowClicked: "rowClicked" }), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "ReservedUserGrid"; var currVal_1 = _co.gridColumnDefs; var currVal_2 = _co.reservedDrugs; var currVal_3 = _co.frameworkComponents; var currVal_4 = ("\u0412\u044B\u0434\u0430\u043D\u043D\u044B\u0435 \u043F\u0440\u0435\u043F\u0430\u0440\u0430\u0442\u044B " + i0.ɵunv(_v, 3, 4, i0.ɵnov(_v, 4).transform(_co.userService.getUserInfo())).id); _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_UserReservedDrugsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, [" \u0423 \u0412\u0430\u0441 \u043D\u0435 \u0438\u043C\u0435\u0435\u0442\u0441\u044F \u0432\u044B\u0434\u0430\u043D\u043D\u044B\u0445 \u043F\u0440\u0435\u043F\u0430\u0440\u0430\u0442\u043E\u0432 "]))], null, null); }
export function View_UserReservedDrugsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "itech-common-wrap"], ["id", "interceptNoAccess"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["id", "agent-common-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "itech-doc-container"], ["style", "min-height:100%"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UserReservedDrugsComponent_1)), i0.ɵdid(4, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["noReservedDrugs", 2]], null, 0, null, View_UserReservedDrugsComponent_2)), (_l()(), i0.ɵeld(6, 0, null, null, 5, "div", [["class", "itech-form-actions"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 3, "button", [["class", "btn btn-link btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.refreshFormData() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "clr-icon", [["class", "has-badge"], ["shape", "refresh"]], null, null, null, null, null)), i0.ɵdid(10, 16384, null, 0, i6.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, ["\u00A0\u041E\u0431\u043D\u043E\u0432\u0438\u0442\u044C \u0441\u043F\u0438\u0441\u043E\u043A "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.reservedDrugs && _co.reservedDrugs.length); var currVal_1 = i0.ɵnov(_v, 5); _ck(_v, 4, 0, currVal_0, currVal_1); }, null); }
export function View_UserReservedDrugsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-user-reserved-drugs", [], null, null, null, View_UserReservedDrugsComponent_0, RenderType_UserReservedDrugsComponent)), i0.ɵdid(1, 114688, null, 0, i7.UserReservedDrugsComponent, [i4.SecurityService, i3.DataCachingService, i8.GlobalWaitingOverlayService, i9.BookmarkService, i10.DrugDataService, i11.AppNavigationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserReservedDrugsComponentNgFactory = i0.ɵccf("app-user-reserved-drugs", i7.UserReservedDrugsComponent, View_UserReservedDrugsComponent_Host_0, {}, {}, []);
export { UserReservedDrugsComponentNgFactory as UserReservedDrugsComponentNgFactory };
