import { Component, Input, OnInit } from '@angular/core';
import { DataCachingService } from '../../logic/services/data-caching.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BookmarkService } from '../../logic/services/bookmark.service';
import { AppNavigationService } from '../../logic/services/app-navigation.service';
import { FormBuilder } from '@angular/forms';
import { FormHelper } from '../../ui/controls/form-helper';
import { InstitutionDataService } from '../../logic/services/institution-data.service';
import { EditInstitutionCommonFormComponent } from './common/edit-institution-common-form.component';
import { SecurityService } from '../../logic/services/security.service';

@Component({
  selector: 'app-create-institution',
  templateUrl: './create-institution.component.html'
})
export class CreateInstitutionComponent implements OnInit {

  @Input() model: any;
  creating = false;
  created = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private bookmarkService: BookmarkService,
              private institutionDataService: InstitutionDataService,
              private dataCachingService: DataCachingService,
              private appNavigationService: AppNavigationService,
              private securityService: SecurityService,
              private fb: FormBuilder) {
  }

  ngOnInit() {

    this.securityService.getUserInfo().subscribe(currentUserInfo => {


      this.router.events.subscribe((val) => {
        if (!this.created) {
          this.refreshCurrentBookmark(true);
        }
      });

      this.route.paramMap
        .subscribe(params => {

          const randomUniqueTag = params.get('randomUniqueTag');

          const existing = this.dataCachingService.getCachedData('CreateInstitution', randomUniqueTag);

          if (!existing) {
            this.model = {
              randomUniqueTag: randomUniqueTag,
              serverSideValidationErrors: []
            };
            this.model.form = EditInstitutionCommonFormComponent.createFormGroup(this.fb, this.model, {
              address: {
                regionId: currentUserInfo ? currentUserInfo.defaultRegionId : undefined,
                cityId: currentUserInfo ? currentUserInfo.defaultCityId : undefined,
                countryId: 'RUS',
              }
            });

            this.dataCachingService.addToCache('CreateInstitution', randomUniqueTag, this.model, 10);
          } else {
            this.model = existing;
          }

          this.refreshCurrentBookmark();
        });
    });
  }

  refreshCurrentBookmark(skipIfNotExists?: boolean) {
    let caption = this.model.form.get('shortCaption').value || '';
    if (caption) {
      caption = ' (' + caption + ')';
    }
    this.bookmarkService.addOpenCardBookmark(this.model.randomUniqueTag,
      `Новая карточка ${caption}`,
      ['/institution-create', this.model.randomUniqueTag], 'bank', skipIfNotExists);
  }

  createCard() {

    FormHelper.markAsSubmitted(this.model.form);

    if (!this.model.form.valid) {
      return;
    }

    const dataToStore = this.model.form.value;

    this.creating = true;

    this.institutionDataService.storeInstitutionCommonInfo(dataToStore).subscribe((createdInstitutionId: any) => {
      this.creating = false;
      this.created = true;
      this.bookmarkService.removeOpenCardBookmark(['/institution-create'], this.model.randomUniqueTag);
      this.appNavigationService.performInstitutionEditing(createdInstitutionId);
    }, error => {
      this.creating = false;
      FormHelper.setSingleFormGroupServerSideValidationErrors(error, this.model, this.model.form);
    });
  }
}
