import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { FormBuilder, Validators } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { StableDataService } from '../../../logic/services/stable-data.service';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { JobRunnerUiService } from '../../../ui/infrastructure/job-runner-ui.service';
import { SecurityService } from '../../../logic/services/security.service';
import { StringHelper } from '../../../helpers/string-helper';

@Component({
  selector: 'app-stable-search-results-form',
  templateUrl: './stable-search-results-form.component.html'
})
export class StableSearchResultsFormComponent implements OnInit {
  static stableTypeIdLookup: any = {};

  @Output() rowClicked = new EventEmitter();
  @Input() model = StableSearchResultsFormComponent.getDefaultModel();
  latestUpdatedFromMercuryAgentId: number;

  processMoneyKeypress = FormHelper.processMoneyKeypress;

  toggleCadNoOrCode = true;

  isInvalid = FormHelper.isInvalid;

  gridColumnDefs = StableSearchResultsFormComponent.getGridColumnDefs();

  public static getGridColumnDefs(addCheckboxSelection = false) {
    return [
      {
        headerName: '', field: '', width: 50, hide: !addCheckboxSelection, excludeExport: true,
        checkboxSelection: addCheckboxSelection, headerCheckboxSelection: addCheckboxSelection,
        headerCheckboxSelectionFilteredOnly: true,
      },
      {
        headerName: '№', field: 'id', width: 90,
        cellRenderer: params => {
          if (params.data.mercurySearchedUuid && !params.data.id) {
            return '<span class="label label-info">Меркурий</span>';
          } else if (params.data.mercurySearchedUuid && params.data.id) {
            return '<clr-icon shape="check" style="color: green"></clr-icon>&nbsp;' + params.value;
          } else {
            return params.value;
          }
        }
      },
      {
        headerName: 'Наименование', field: 'caption', width: 220,
        filterParams: {
          cellRenderer: params => {
            if (params.value) {
              return '<div title="' + StringHelper.saveQuotes(params.value) + '">' + params.value + '</div>';
            } else {
              return '(Пусто)';
            }
          }
        },
        cellRenderer: params => {
          const animalStateHtml = params.data.overdueEventsCount && params.data.overdueEventsCount > 0
            ? '<clr-icon shape="history" style="color:red"' +
            ' title="Имеются просроченные мероприятия: ' + params.data.overdueEventsCount + '"></clr-icon>'
            : '<clr-icon shape="check" style="color:green" title="Просроченные мероприятия отсутствуют"></clr-icon>';

          return animalStateHtml + '&nbsp;' + params.value;
        }
      },
      {
        headerName: 'Состояние ПО',
        field: 'overdueEventsCount',
        hide: true,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
      },
      {
        headerName: 'Вид', field: 'stableTypeId', width: 120,
        valueFormatter: params => params.value ? StableSearchResultsFormComponent.stableTypeIdLookup[params.value] : '<не указан>',
      },
      {
        headerName: 'Адрес', field: 'address', width: 300,
        filterParams: {
          cellRenderer: params => {
            if (params.value) {
              return '<div title="' + StringHelper.saveQuotes(params.value) + '">' + params.value + '</div>';
            } else {
              return '(Пусто)';
            }
          }
        },
      },
      {
        headerName: 'Владелец', field: 'owner', width: 200,
        filterParams: {
          cellRenderer: params => {
            if (params.value) {
              return '<div title="' + StringHelper.saveQuotes(params.value) + '">' + params.value + '</div>';
            } else {
              return '(Пусто)';
            }
          }
        },
      },
      {
        headerName: 'Площадь, кв.м.', field: 'square', width: 140,
      },
      {
        headerName: 'Кадастровый номер', field: 'cadNo', width: 180,
      },
    ];
  }

  public static getDefaultModel(fb?: FormBuilder, defaultFilterParams?: any) {
    return {
      searchResults: [],
      filterFormGroup: fb ? StableSearchResultsFormComponent.buildSearchForm(fb, defaultFilterParams) : undefined
    };
  }

  public static buildSearchForm(fb: FormBuilder, defaultFilterParams: any = {}) {

    return fb.group({
      id: [defaultFilterParams.id, Validators.pattern(/^\d{1,10}$/)],
      code: defaultFilterParams.code,
      caption: defaultFilterParams.caption,
      owner: defaultFilterParams.owner,
      address: defaultFilterParams.address,
      cadNo: defaultFilterParams.cadNo,
      agentId: defaultFilterParams.agentId,
      showDeleted: defaultFilterParams.showDeleted,
      availableChangeAgentId: defaultFilterParams.availableChangeAgentId,
      forceAutoStart: defaultFilterParams.forceAutoStart || false,
    });
  }

  constructor(private stableDataService: StableDataService,
              private globalWaitingOverlayService: GlobalWaitingOverlayService,
              public appNavigationService: AppNavigationService,
              private lookupSourceService: LookupSourceService,
              private jobRunnerUiService: JobRunnerUiService,
              private alertService: AlertService,
              public securityService: SecurityService) {

      this.lookupSourceService.getLookupObj('stable-type')
        .subscribe(lookup => StableSearchResultsFormComponent.stableTypeIdLookup = lookup);
  }

  ngOnInit() {
    if (this.model.filterFormGroup && this.model.filterFormGroup.get('forceAutoStart').value) {
      this.refreshResults();
    }
  }

  refreshResults() {

    if (!this.model.filterFormGroup.valid) {
      return;
    }

    this.globalWaitingOverlayService.StartWaiting();

    this.stableDataService.search(this.model.filterFormGroup.value)
      .subscribe({
        next: data => {
          this.model.searchResults = data;
          this.globalWaitingOverlayService.EndWaiting();

          // если у нас конкретный ХС, мы первый раз нажали "поиск", и мы ничего не нашли по ХС - запускаем обновление
          if (!data.length && this.model.filterFormGroup.get('agentId').value !== this.latestUpdatedFromMercuryAgentId) {
            this.latestUpdatedFromMercuryAgentId = this.model.filterFormGroup.get('agentId').value;
            this.updateFromMercuryAndRefreshFormData();
          }
        }, error: () => {
          this.globalWaitingOverlayService.EndWaiting();
        }
      });
  }

  filterEnterPressed($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      this.refreshResults();
    }
  }

  updateFromMercuryAndRefreshFormData() {
    if (!this.model.filterFormGroup.get('agentId').value) {
      return;
    }

    setTimeout(() => {
      this.jobRunnerUiService.runOperation(4 /*Обновление списка поднадзорных объектов хозяйствующего субъекта*/,
        {
          type: 'singleAgentStableSync',
          agentId: this.model.filterFormGroup.get('agentId').value
        }, undefined, undefined, true)
        .subscribe({
          next: (data) => {
            this.model.searchResults = data ? JSON.parse(data) : [];
            if (this.model.searchResults.length) {
              this.alertService.success('Поднадзорные объекты обновлены. В форме отображены новые и измененные объекты');
            } else {
              this.alertService.success('Все сведения актуальны - обновленных (новых) объектов для загрузки не обнаружено');
            }
          }, error: () => {
          }
        });
    }, 1);
  }

  getRowStyle(params: any) {
    if (params.data && params.data.deletedTime) {
      return {color: 'silver'};
    }
  }

  changeToggleCadNoOrCode() {
    this.toggleCadNoOrCode = !this.toggleCadNoOrCode;
    this.model.filterFormGroup.get(this.toggleCadNoOrCode ? 'code' : 'cadNo').setValue(null);
  }
}
