import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LookupSourceService } from '../../logic/services/lookup-source.service';

@Component({
  selector: 'app-ur-choose-object-meta',
  template: `
    <div class="form-group itech-group-whole-line" [formGroup]="item.formGroup">
      <label>
      </label>
      <div class="radio-inline">
        <input type="radio" id="{{metaGroupName}}Kind1{{groupId}}"
               formControlName="{{metaGroupName}}Kind" [value]="1">
        <label for="{{metaGroupName}}Kind1{{groupId}}">Разрешить:</label>
      </div>
      <div class="radio-inline">
        <input type="radio" id="{{metaGroupName}}Kind2{{groupId}}"
               formControlName="{{metaGroupName}}Kind" [value]="2">
        <label for="{{metaGroupName}}Kind2{{groupId}}">Разрешить все кроме:</label>
      </div>
      <div class="radio-inline">
        <input type="radio" id="{{metaGroupName}}Kind3{{groupId}}"
               formControlName="{{metaGroupName}}Kind" [value]="3">
        <label for="{{metaGroupName}}Kind3{{groupId}}">Не проверять:</label>
      </div>
    </div>

    <div class="form-group itech-group-whole-line" *ngIf="item.toggled && item.func.metaName1">
      <label>
      </label>
      <app-checkbox-select [lookupName]="lookupName" [contextControlName]="metaGroupName"
                           [contextFormGroup]="item.formGroup" [sorted]="true"
                           [disabled]="item.formGroup.get(metaGroupName + 'Kind').value == 3? true: undefined">
      </app-checkbox-select>
    </div>
  `
})
export class AppUrChooseObjectMetaComponent {
  @Input() item;
  @Input() metaGroupName;
  @Input() groupId;
  @Input() lookupName;
}
