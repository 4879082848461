<div class="message-view">
  <div *ngFor="let message of messages" class="message"
       [class.outcoming]="message.userId"
       [class.incoming]="!message.userId">
    <div class="message-header">
      <div>{{getRuDatetime(message.date)}}</div>
      <div>{{message.userId
          ? (+message.userId|lookup:'user':true|async)
          : (+message.agentId|lookup:'agent':true|async)}}
      </div>
    </div>
    <div class="message-text">{{message.text}}</div>
  </div>
</div>
