import { Component, Input } from '@angular/core';
import { LookupSourceService } from '../../logic/services/lookup-source.service';
import { AppNavigationService } from '../../logic/services/app-navigation.service';

@Component({
  selector: 'app-trapped-quarantine-signpost',
  template: `
  <div class="trigger-item" *ngIf="trappedQuarantine">
    <clr-signpost class="itech-trapped-quarantine-lbl">
      <a class="itech-inline-link-btn" clrSignpostTrigger style="display:grid;color:red">
        <clr-icon shape="flame" size="60"></clr-icon>
          <label>Карантин</label>
      </a>
      <clr-signpost-content class="itech-signpost-content" [clrPosition]="'right-bottom'" *clrIfOpen style="max-width:500px">
        <div class="form compact" style="text-align:left;padding:0" *ngIf="trappedQuarantine">

            <table class="table" style="margin:0;border:none">
              <tbody>
                <tr *ngFor="let trappedQuarantine of trappedQuarantine | keyvalue; index as i">
                  <td style="width:30px;vertical-align:middle">{{i+1}}</td>
                  <td style="width:300px;vertical-align:middle">
                    <app-link-button [id]="trappedQuarantine.key" [lookupName]="'quarantine'"></app-link-button>
                  </td>
                  <td style="width:200px;vertical-align:middle">
                    <div *ngIf="trappedQuarantine.value">
                      {{getLookupCaption(trappedQuarantine.value[0].trappedTypeId) | async}}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
        </div>
      </clr-signpost-content>
    </clr-signpost>
  </div>`
})
export class AppTrappedQuarantineSignpostComponent {
  @Input() trappedQuarantine: any;
  @Input() objectCaption = '';

  constructor(public lookupService: LookupSourceService,
              public navigationService: AppNavigationService) {
  }

  getLookupCaption(trappedTypeId: number) {
    return this.lookupService.getLookupCaption(trappedTypeId, 'quarantine/trapped-type');
  }
}
