<div class="itech-content-header">
  <clr-icon shape="flask" size="36" class="has-badge"></clr-icon>&nbsp;Создание нового препарата
</div>

<app-edit-drug-common-form *ngIf="model" [contextFormGroup]="model.form">
</app-edit-drug-common-form>

<p>
  <button [clrLoading]="creating" [disabled]="creating" class="btn btn-primary"
          (click)="createCard()">
    <clr-icon shape="check"></clr-icon>&nbsp;Создать
  </button>
</p>
