<div class="itech-dashboard-container" *ngIf="securityService.isAccess('dashboard') | async">

  <app-mo-map [data]="moData"></app-mo-map>

  <div id="mo-reports">
    <clr-dropdown>
      <button [disabled]="availableMapReports.length == 0? true: undefined"
              class="btn btn-link btn-primary" clrDropdownToggle>
        {{currentMapReport != null? currentMapReport.caption: 'Выберите отчет'}}
        <clr-icon shape="caret down"></clr-icon>
      </button>
      <clr-dropdown-menu *clrIfOpen [clrPosition]="dropdownPosition">
        <label class="dropdown-header">Выберите отчет</label>
        <button *ngFor="let report of availableMapReports"
                type="button" clrDropdownItem (click)="chooseMapReport(report)">[{{report.id}}] {{report.caption}}
        </button>
      </clr-dropdown-menu>
    </clr-dropdown>
  </div>

  <div>

    <div class="card">
      <div class="card-block">
        <h3 class="card-title">Создание</h3>
      </div>
      <div class="card-footer">
        <button class="btn btn-link btn-primary" (click)="appNavigationService.performAgentCreation()">
          <clr-icon shape="users" class="has-badge"></clr-icon>&nbsp;Регистрация хозяйствующего субъекта
        </button>
        <br>
        <button class="btn btn-link btn-primary" (click)="appNavigationService.performStableCreation()">
          <clr-icon shape="building" class="has-badge"></clr-icon>&nbsp;Регистрация поднадзорного объекта
        </button>
        <br>
        <button class="btn btn-link btn-primary" (click)="appNavigationService.performAnimalCreation()">
          <clr-icon shape="piggy-bank" class="has-badge"></clr-icon>&nbsp;Регистрация животного
        </button>
        <br>
        <button class="btn btn-link btn-primary" (click)="appNavigationService.performDrugCreation()">
          <clr-icon shape="flask" class="has-badge"></clr-icon>&nbsp;Регистрация препарата
        </button>
        <br>
        <button class="btn btn-link btn-primary" (click)="appNavigationService.performProductCreation()">
          <clr-icon shape="truck" class="has-badge"></clr-icon>&nbsp;Регистрация ветеринарного документа
        </button>
      </div>
    </div>


    <div class="card">
      <div class="card-block">
        <h3 class="card-title">Выборки/Отчеты</h3>
      </div>
      <div class="card-footer">
        <ng-container *ngFor="let reportGroup of (userService.getReportData$() | async)">
          <clr-dropdown style="width: 350px">
            <button class="btn btn-link btn-primary" [clrLoading]="printing" clrDropdownToggle>
              <clr-icon shape="printer"></clr-icon>&nbsp;{{reportGroup.caption}}
              <clr-icon shape="caret down"></clr-icon>
            </button>
            <clr-dropdown-menu *clrIfOpen [clrPosition]="dropdownPosition">
              <label class="dropdown-header">Выберите отчет</label>
              <button *ngFor="let reportMeta of reportGroup.reports" type="button" clrDropdownItem title="{{reportMeta.caption}}"
                      (click)="appNavigationService.performGroupOperation(reportMeta.operationParamsCode)">
                {{reportMeta.caption}}
              </button>
            </clr-dropdown-menu>
          </clr-dropdown>
        </ng-container>

        <clr-dropdown>
          <button [disabled]="availableReports.length == 0 || printing ?true: undefined" class="btn btn-link btn-primary"
                  [clrLoading]="printing" clrDropdownToggle>
            <clr-icon shape="printer"></clr-icon>&nbsp;Подготовить выборку
            <clr-icon shape="caret down"></clr-icon>
          </button>
          <clr-dropdown-menu *clrIfOpen [clrPosition]="dropdownPosition">
            <label class="dropdown-header">Выберите выборку</label>
            <button *ngFor="let report of availableReports"
                    type="button" clrDropdownItem (click)="printForm(report)">[{{report.id}}] {{report.caption}}
            </button>
          </clr-dropdown-menu>
        </clr-dropdown>
      </div>
    </div>

    <div class="card">
      <div class="card-block">
        <h3 class="card-title">Избранное</h3>
      </div>
      <div class="card-footer">
        <div *ngIf="!appNavigationService.favoriteQueries.length" style="max-width: 367px">
          Избранные выборки отсутствуют. Вы можете добавить в избранное интересующие выборки при редактировании основной
          информации
          о выборке в менеджере выборок.
        </div>

        <ng-container *ngFor="let query of appNavigationService.favoriteQueries">
          <button class="btn btn-link btn-primary" [routerLink]="['/query-view', query.id]" title="{{query.caption}}">
            <clr-icon shape="bolt" class="has-badge"></clr-icon>&nbsp;{{query.caption}}
          </button>
          <br>
        </ng-container>
      </div>
    </div>

  </div>
</div>
