import {IModalBody, ModalResult} from '../../../logic/services/app-navigation.service.models';
import {Component, Input} from '@angular/core';
import {AppFormGroup} from '../../../ui/controls/app-form-group';
import {FormBuilder, Validators} from '@angular/forms';
import {LookupSourceService} from '../../../logic/services/lookup-source.service';
import {Observable, of, Subject} from 'rxjs/index';
import {FormHelper} from '../../../ui/controls/form-helper';
import {EsiaService} from '../../../esia/esia.service';

@Component({
  template: `
      <div class="form compact" [formGroup]="contextFormGroup" style="min-height: 400px">

          <section class="form-block itech-block-normal">

              <div class="form-group">
                  <label for="indicatorTypeId" class="required">Показатель</label>
                  <app-text-lookup class="itech-control-m-large" [contextControlId]="'indicatorTypeId'" [sorted]="true"
                                   [contextControlName]="'indicatorTypeId'" [lookupName]="'pst-indicator-type'"
                                   [validationTooltipBottomLeft]="true"
                                   [contextFormGroup]="contextFormGroup"></app-text-lookup>
              </div>

              <div class="form-group">
                  <label for="reportTypeId" class="required">Вид отчета</label>
                  <app-text-lookup class="itech-control-m-large" [contextControlId]="'reportTypeId'" [sorted]="true"
                                   [contextControlName]="'reportTypeId'" [lookupName]="'pst-report-type'"
                                   [validationTooltipBottomLeft]="true"
                                   [contextFormGroup]="contextFormGroup"></app-text-lookup>
              </div>

              <div class="form-group">
                  <label>Даты с / по</label>
                  <label for="dateFrom" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md"
                         [class.invalid]="isInvalid(contextFormGroup, 'dateFrom')">
                      <input type="date" id="dateFrom" class="itech-control-normal"
                             formControlName="dateFrom">
                      <app-validation-tooltip [input]="contextFormGroup.controls['dateFrom']"></app-validation-tooltip>
                  </label>
                  &nbsp;
                  <label for="dateTo" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md"
                         [class.invalid]="isInvalid(contextFormGroup, 'dateTo')">
                      <input type="date" id="dateTo" class="itech-control-normal"
                             formControlName="dateTo">
                      <app-validation-tooltip [input]="contextFormGroup.controls['dateTo']"></app-validation-tooltip>
                  </label>
              </div>

          </section>

          <app-doc-validation-errors [validationErrors]="contextFormGroup.docLevelServerSideValidationErrors">
          </app-doc-validation-errors>

      </div>
  `
})
export class RecalculatePstIndicatorsParamsComponent implements IModalBody<any, any> {

  @Input() contextFormGroup: AppFormGroup;
  isInvalid = FormHelper.isInvalid;

  public serverSideValidationErrors = [];

  public static buildFormArrayDef(fb: FormBuilder): any {
    return {
      indicatorTypeId: [''],
      reportTypeId: [''],
      dateFrom: ['', Validators.compose([Validators.required, FormHelper.validateDateTimePicker])],
      dateTo: ['', Validators.compose([Validators.required, FormHelper.validateDateTimePicker])],
    };
  }

  constructor(private lookupSourceService: LookupSourceService,
              private esiaService: EsiaService,
              private fb: FormBuilder) {

    this.contextFormGroup = fb.group(RecalculatePstIndicatorsParamsComponent.buildFormArrayDef(fb)) as AppFormGroup;
  }

  onModalAccept$(): Observable<ModalResult<any>> {
    FormHelper.markAsSubmitted(this.contextFormGroup);
    if (!this.contextFormGroup.valid) {
      return of(new ModalResult(false, undefined));
    }
    return of(new ModalResult(true, this.contextFormGroup.value));
  }

  initModalBody(data: any) {
    this.contextFormGroup.patchValue(data);
  }
}
