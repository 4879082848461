import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';
import { Directive, Input } from '@angular/core';
import { StringHelper } from '../../helpers/string-helper';
import { PersonSearchParams } from '../../model/person-search-params';

@Directive({
  selector: '[appSearchParams]',
  providers: [{provide: NG_VALIDATORS, useExisting: SearchParamsDirective, multi: true}]
})
export class SearchParamsDirective implements Validator {

  validate(control: AbstractControl): { [key: string]: any } {
    return searchParamsValidator()(control);
  }
}

export function searchParamsValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {

    if (control.value && control.value.trim() !== ''
      && PersonSearchParams.fromString(control.value).mode === PersonSearchParams.ModeUnknown) {
      return {
        appSearchParams: {
          value: control.value,
          message: 'Указаны некорректные параметры для поиска/создания карточки гражданина'
        }
      };
    } else {
      return null;
    }

  };
}
