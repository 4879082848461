import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FormBuilder, Validators} from '@angular/forms';
import {DataCachingService} from '../../logic/services/data-caching.service';
import {FormHelper} from '../../ui/controls/form-helper';
import {GlobalWaitingOverlayService} from '../../ui/infrastructure/global-waiting-overlay.service';
import {MetadataService} from '../../logic/services/metadata.service';
import {BookmarkService} from '../../logic/services/bookmark.service';
import {environment} from '../../../environments/environment';
import {StringHelper} from '../../helpers/string-helper';
import {GridApi} from '@ag-grid-community/core/dist/es6/gridApi';
import {AddressPersonFioCacheService} from '../../logic/services/address-person-fio-cache.service';
import {LookupSourceService} from '../../logic/services/lookup-source.service';
import {DateHelper} from '../../helpers/date-helper';
import {AppNavigationService} from '../../logic/services/app-navigation.service';

@Component({
  templateUrl: './view-integration-log2.component.html'
})
export class ViewIntegrationLog2Component implements OnInit {

  static readonly successIcon = '<clr-icon shape="success-standard" class="is-solid" style="color: green"></clr-icon>';
  static readonly failIcon = '<clr-icon shape="times-circle" class="is-solid" style="color: red"></clr-icon>';
  static readonly processIcon = '<clr-icon shape="play" class="is-solid" style="color: navy"></clr-icon>';

  model = ViewIntegrationLog2Component.getDefaultModel(this.fb);

  isInvalid = FormHelper.isInvalid;

  gridColumnDefs = [
    {
      headerName: 'Время', field: 'mercuryExchangeLastTime', width: 140,
      valueFormatter: params => StringHelper.getRuTimeWithSeconds(params.value),
    },
    {
      headerName: 'Вид операции', field: 'conversationTypeCaption', width: 180,
      cellRenderer: params => {
        if (params.data.title === 'Запрос') {
          return '<clr-icon shape="redo"></clr-icon>&nbsp;' + params.value;
        } else if (params.data.title === 'Ответ') {
          return '<clr-icon shape="undo" style="color: green"></clr-icon>&nbsp;' + params.value;
        } else {
          return params.value;
        }
      }
    },
    {
      headerName: 'Пользователь', field: 'createdUserCaption', width: 200,
    },
    {
      headerName: 'Статус', field: 'mercuryExchangeStatusCaption', width: 200,
      cellRenderer: (row => this.mercuryExchangeStatusCellRender(row)),
    },
    {
      headerName: 'Посл. результат', field: 'mercuryExchangeLastResultCaption', width: 200,
      cellRenderer: (row => this.mercuryExchangeLastResultCellRender(row)),
    },
    {
      headerName: 'След. попытка', field: 'mercuryExchangeNextTime', width: 140,
      valueFormatter: params => StringHelper.getRuTimeWithSeconds(params.value),
    },
    {
      headerName: 'Подробности',
      field: 'mercuryExchangeLastResultText'
    },
    {
      headerName: 'Время создания', field: 'createdTime', hide: true,
      valueFormatter: params => StringHelper.getRuTimeWithSeconds(params.value),
    },
  ];

  redrawTimerId: any;
  agGridApi: GridApi;

  constructor(private route: ActivatedRoute,
              private dataCachingService: DataCachingService,
              private lookupSourceService: LookupSourceService,
              private globalWaitingOverlayService: GlobalWaitingOverlayService,
              private personFioCacheService: AddressPersonFioCacheService,
              private metadataService: MetadataService,
              private fb: FormBuilder,
              private bookmarkService: BookmarkService,
              private navigationService: AppNavigationService,
  ) {
  }

  private static getDefaultModel(fb?: FormBuilder, defaultFilterParams?: any) {
    return {
      searchResults: [],
      filterFormGroup: fb ? ViewIntegrationLog2Component.buildSearchForm(fb, defaultFilterParams) : undefined
    };
  }

  public static buildSearchForm(fb: FormBuilder, defaultFilterParams: any = {}) {
    return fb.group({
      dateType: 1,
      dateFrom: [defaultFilterParams.dateFrom, Validators.compose([
        FormHelper.conditionalValidate(Validators.required, ctrl => ctrl.parent && +ctrl.parent.get('dateType').value === 1),
        FormHelper.validateDateTimePicker()])],
      dateTo: [defaultFilterParams.dateTo, Validators.compose([
        FormHelper.conditionalValidate(Validators.required, ctrl => ctrl.parent && +ctrl.parent.get('dateType').value === 1),
        FormHelper.validateDateTimePicker()])],
      dateCreatedFrom: [defaultFilterParams.dateCreatedFrom, Validators.compose([
        FormHelper.conditionalValidate(Validators.required, ctrl => ctrl.parent && +ctrl.parent.get('dateType').value === 2),
        FormHelper.validateDateTimePicker()])],
      dateCreatedTo: [defaultFilterParams.dateCreatedTo, Validators.compose([
        FormHelper.conditionalValidate(Validators.required, ctrl => ctrl.parent && +ctrl.parent.get('dateType').value === 2),
        FormHelper.validateDateTimePicker()])],
      conversationTypeId: defaultFilterParams.conversationTypeId,
      conversationStageId: defaultFilterParams.conversationStageId,
      mercuryExchangeStatuses: fb.array((defaultFilterParams.mercuryExchangeStatuses || []).map(el => fb.control(el))),
      mercuryExchangeLastResults: fb.array((defaultFilterParams.mercuryExchangeLastResults || []).map(el => fb.control(el))),
    });
  }

  get getDateType(): number {
    return +this.model.filterFormGroup.get('dateType').value;
  }

  ngOnInit() {

    this.route.paramMap
      .subscribe(() => {

        const existing = this.dataCachingService.getCachedData('ViewIntegrationLog2Component', '1');

        if (existing) {
          this.model = existing;
        } else {
          this.model = ViewIntegrationLog2Component.getDefaultModel(this.fb,
            {
              dateFrom: StringHelper.getISODate(DateHelper.addDays(new Date(), -2)),
              dateTo: StringHelper.getISODate(new Date()),
              mercuryExchangeStatuses: [-1, 0, 2, 3, 4],
            });
          this.dataCachingService.addToCache('ViewIntegrationLog2Component', '1', this.model);
          this.refreshResults();
        }

        this.bookmarkService.addDictionaryBookmark(
          'Просмотр журнала интеграции',
          ['/view-integration-log2/']);
      });
  }

  refreshResults() {

    FormHelper.markAsSubmitted(this.model.filterFormGroup);

    if (!this.model.filterFormGroup.valid) {
      return;
    }

    this.globalWaitingOverlayService.StartWaiting();

    this.lookupSourceService.getLookupObj('mercury-agent-exchange-status').subscribe(exchangeStatuses => {
      this.lookupSourceService.getLookupObj('mercury-exchange-result').subscribe(exchangeLastResults => {
        this.lookupSourceService.getLookupObj('mercury-conversation-types').subscribe(conversationTypes => {
          this.metadataService.searchConversations(this.model.filterFormGroup.value)
            .subscribe({
              next: data => {
                this.model.searchResults = data;

                data.forEach(row => {
                  row.conversationTypeCaption = conversationTypes[row.conversationTypeId];
                  row.mercuryExchangeStatusCaption = exchangeStatuses[row.mercuryExchangeStatus];
                  row.mercuryExchangeLastResultCaption = exchangeLastResults[row.mercuryExchangeLastResult];

                  this.personFioCacheService.getUser(row.createdUser).subscribe(userRow => {
                    row.createdUserCaption = userRow.caption;
                    this.enqueeRedraw();
                  });
                });

                this.globalWaitingOverlayService.EndWaiting();
              },
              error: () => {
                this.globalWaitingOverlayService.EndWaiting();
              }
            });
        });
      });
    });
  }

  filterEnterPressed($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      this.refreshResults();
    }
  }

  downloadFile(uri: string) {
    this.metadataService.preloadFile(uri).subscribe(fileHash => {
      window.open(environment.api + '/files/get?preloadId=' + encodeURIComponent(fileHash));
    });
  }

  private enqueeRedraw() {
    if (this.redrawTimerId) {
      clearTimeout(this.redrawTimerId);
    }

    this.redrawTimerId = setTimeout(() => {
      this.agGridApi.redrawRows();
    }, 500);
  }

  agGridReady(params: any) {
    this.agGridApi = params.api;
  }

  private mercuryExchangeStatusCellRender(row: any) {
    let resultIcon = '';

    switch (row.data.mercuryExchangeStatus) {
      case 1:
      case -2:
        resultIcon = ViewIntegrationLog2Component.successIcon;
        break;
      case -1:
        resultIcon = ViewIntegrationLog2Component.failIcon;
        break;
      case 2:
      case 3:
      case 4:
        resultIcon = ViewIntegrationLog2Component.processIcon;
        break;
    }

    return `${resultIcon} ${row.data.mercuryExchangeStatusCaption}`;
  }

  private mercuryExchangeLastResultCellRender(row: any) {
    let resultIcon = ViewIntegrationLog2Component.failIcon;

    switch (row.data.mercuryExchangeLastResult) {
      case 1:
        resultIcon = ViewIntegrationLog2Component.successIcon;
        break;
      case 2:
        resultIcon = ViewIntegrationLog2Component.processIcon;
        break;
    }

    return `${resultIcon} ${row.data.mercuryExchangeLastResultCaption}`;
  }

  gridDblClick($event: any) {
    if ($event.data.conversationTypeId === -1) {
      this.navigationService.performAgentEditing($event.data.targetObjId);
    } else if ($event.data.conversationTypeId === -2 || $event.data.conversationTypeId === 2 || $event.data.conversationTypeId === 5
      || $event.data.conversationTypeId === 10) {
      this.navigationService.performStableEditing($event.data.targetObjId);
    } else {
      this.navigationService.performProductEditing($event.data.targetObjId);
    }
  }

  changeToggle() {
    this.model.filterFormGroup.get('dateType').setValue(+this.model.filterFormGroup.get('dateType').value === 1 ? 2 : 1);
    if (+this.model.filterFormGroup.get('dateType').value === 1) {
      this.model.filterFormGroup.get('dateFrom').setValue(this.model.filterFormGroup.get('dateCreatedFrom').value);
      this.model.filterFormGroup.get('dateTo').setValue(this.model.filterFormGroup.get('dateCreatedTo').value);
      this.model.filterFormGroup.get('dateCreatedFrom').setValue(null);
      this.model.filterFormGroup.get('dateCreatedTo').setValue(null);
    } else {
      this.model.filterFormGroup.get('dateCreatedFrom').setValue(this.model.filterFormGroup.get('dateFrom').value);
      this.model.filterFormGroup.get('dateCreatedTo').setValue(this.model.filterFormGroup.get('dateTo').value);
      this.model.filterFormGroup.get('dateFrom').setValue(null);
      this.model.filterFormGroup.get('dateTo').setValue(null);
    }
  }
}
