import { Component, HostBinding, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-query-details-stable-fields',
  template: `
    <app-query-lookup-combo-filter parameterName="created_institution" [lookupName]="'all-institution'"
                                   [contextFormGroup]="contextFormGroup" [queryUserMode]="queryUserMode">
      Учреждение-создатель
    </app-query-lookup-combo-filter>
    <app-query-lookup-combo-filter *ngIf="contextFormGroup.get('_par_created_institution').value"
                                   parameterName="created_institution_employee"
                                   [lookupName]="'institution-employee' + contextFormGroup.get('_par_created_institution').value"
                                   [contextFormGroup]="contextFormGroup" [queryUserMode]="queryUserMode">
      Пользователь-создатель
    </app-query-lookup-combo-filter>
    <app-query-date-time-filter parameterName="created_time" [contextFormGroup]="contextFormGroup"
                                [queryUserMode]="queryUserMode">
      Время создания
    </app-query-date-time-filter>
    <app-query-lookup-combo-filter parameterName="deleted_institution" [lookupName]="'all-institution'"
                                   [contextFormGroup]="contextFormGroup" [queryUserMode]="queryUserMode">
      Удалившее учрежд.
    </app-query-lookup-combo-filter>
    <app-query-lookup-combo-filter *ngIf="contextFormGroup.get('_par_deleted_institution').value"
                                   parameterName="deleted_institution_employee"
                                   [lookupName]="'institution-employee' + contextFormGroup.get('_par_deleted_institution').value"
                                   [contextFormGroup]="contextFormGroup" [queryUserMode]="queryUserMode">
      Удаливший польз.
    </app-query-lookup-combo-filter>
    <app-query-date-time-filter parameterName="deleted_time" [contextFormGroup]="contextFormGroup"
                                [queryUserMode]="queryUserMode">
      Время удаления
    </app-query-date-time-filter>
    <app-query-string-filter parameterName="title" [contextFormGroup]="contextFormGroup"
                             [queryUserMode]="queryUserMode">Наименов.
    </app-query-string-filter>
    <app-query-lookup-combo-filter parameterName="region_id" [lookupName]="'addr-region'"
                                   [contextFormGroup]="contextFormGroup" [queryUserMode]="queryUserMode">
      Территория
    </app-query-lookup-combo-filter>
    <app-query-addr-city-filter parameterName="regionIdCityId" [lookupName]="'addr-city-ex'"
                                [contextFormGroup]="contextFormGroup" [queryUserMode]="queryUserMode">
      Нас. пункт
    </app-query-addr-city-filter>
    <app-query-lookup-combo-filter *ngIf="contextFormGroup.get('_par_city_id').value"
                                   parameterName="street_id"
                                   [lookupName]="streetLookupName"
                                   [contextFormGroup]="contextFormGroup" [queryUserMode]="queryUserMode">
      Улица
    </app-query-lookup-combo-filter>
    <app-query-string-filter *ngIf="contextFormGroup.get('_par_street_id').value"
                             parameterName="addr_house" [contextFormGroup]="contextFormGroup"
                             [queryUserMode]="queryUserMode">№ дома
    </app-query-string-filter>
    <app-query-boolean-filter parameterName="other_type_address" [contextFormGroup]="contextFormGroup"
                              [queryUserMode]="queryUserMode">Иной адрес</app-query-boolean-filter>
    <app-query-string-filter parameterName="other_text_address" [contextFormGroup]="contextFormGroup"
                             [queryUserMode]="queryUserMode">Адрес (текст)
    </app-query-string-filter>
  `
})
export class QueryDetailsStableFieldsComponent {
  @Input() contextFormGroup: FormGroup;
  @Input() queryUserMode;
  @HostBinding('attr.class') class = 'form-block itech-block-normal';

  public get streetLookupName() {
    return 'addr-street-' + this.contextFormGroup.get('_par_city_region_id').value +
      '-' + this.contextFormGroup.get('_par_city_id').value;
  }
}
