<div class="itech-common-wrap no-pager">
  <div>
    <app-animal-search-results-form (rowClicked)="rowClickedCallback($event)" [model]="model">
    </app-animal-search-results-form>
  </div>

  <div class="itech-form-actions">
    <button class="btn btn-link btn-primary" (click)="appNavigationService.performAnimalCreation()">
      <clr-icon shape="piggy-bank" class="has-badge"></clr-icon>&nbsp;Зарегистрировать новое животное
    </button>
  </div>
</div>
