import { Component } from '@angular/core';
import { MetaBrowseBaseComponent } from '../meta-browse.base.component';
import { ActivatedRoute } from '@angular/router';
import { MetadataService } from '../../../logic/services/metadata.service';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { JobRunnerUiService } from '../../../ui/infrastructure/job-runner-ui.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import {DrugTypeBrowseFormComponent} from './drug-type-browse-form.component';
import {combineLatest} from 'rxjs';

@Component({
  templateUrl: './drug-type-browse.component.html'
})
export class DrugTypeBrowseComponent extends MetaBrowseBaseComponent {

  gridColumnDefs = [
    {headerName: 'Ид', field: 'id', width: 50},
    {headerName: 'Реестровый №', field: 'registryNo', tooltipField: 'registryNo', width: 180},
    {headerName: 'Наименование', field: 'caption', tooltipField: 'caption', width: 400},
    {headerName: 'Тип', field: 'drugKindCaption', tooltipField: 'drugKindCaption', width: 250},
  ];

  constructor(route: ActivatedRoute,
              metadataService: MetadataService,
              alertService: AlertService,
              globalWaitingOverlayService: GlobalWaitingOverlayService,
              lookupService: LookupSourceService,
              bookmarkService: BookmarkService,
              fb: FormBuilder,
              private jobRunnerUiService: JobRunnerUiService,
              dataCachingService: DataCachingService) {
    super(route, metadataService, alertService, globalWaitingOverlayService, lookupService, fb, bookmarkService, dataCachingService);
  }

  getMetaTitle(): string {
    return 'Виды препаратов';
  }

  getMetaName(): string {
    return 'drug-type';
  }

  getId(formGroup: FormGroup): any {
    return formGroup.get('id').value;
  }

  getGroupDef(row: any) {
    return DrugTypeBrowseFormComponent.getGroupDef(this.fb, row);
  }

  getFormGroup(data: any) {
    return data as FormGroup;
  }

  refreshResults() {
    this.globalWaitingOverlayService.StartWaiting();

    combineLatest(this.metadataService.getMetadata('drug-type/full-record'),
      this.lookupService.getLookupObj('drug-kind')).subscribe({
      next: ([data, drugKindLookup]) => {
        this._searchResults = data;
        this._searchResults.forEach(drugType => {
          drugType.drugKindCaption = drugKindLookup[drugType.drugKindId];
        });
        this.dataCachingService.addToCache(this.getMetaName() + '_Data', '1', data);
        this.lookupService.invalidateLookup(this.getMetaName());
        this.globalWaitingOverlayService.EndWaiting();
      }, error: () => {
        this.globalWaitingOverlayService.EndWaiting();
      }
    });
  }

}
