<div class="form compact" [formGroup]="contextFormGroup" style="min-height: 400px">

  <section class="form-block itech-block-normal">

    <div class="form-group">
      <label for="institutionId" class="required">Учреждение</label>
      <app-text-lookup class="itech-control-m-large" [contextControlId]="'institutionId'" [sorted]="true"
                       [contextControlName]="'institutionId'" [lookupName]="'all-institution'" [required]="true"
                       [validationTooltipBottomLeft]="true"
                       [contextFormGroup]="contextFormGroup"></app-text-lookup>
    </div>

    <div class="form-group" *ngIf="contextFormGroup.controls['institutionId'].value">
      <label>Работник</label>
      <app-text-lookup class="itech-control-m-large" [contextControlId]="'institutionEmployeeId'" [sorted]="true"
                       [contextControlName]="'institutionEmployeeId'" [required]="true"
                       [visibleValidationTextError]="false"
                       [lookupName]="'institution-employee' + contextFormGroup.controls['institutionId'].value"
                       [contextFormGroup]="contextFormGroup"></app-text-lookup>
    </div>

  </section>

  <app-doc-validation-errors [validationErrors]="contextFormGroup.docLevelServerSideValidationErrors">
  </app-doc-validation-errors>

</div>
