import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/internal/operators';
import { StringHelper } from '../../helpers/string-helper';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var DrugDataService = /** @class */ (function () {
    function DrugDataService(http) {
        this.http = http;
    }
    DrugDataService.prototype.search = function (params) {
        var qstring = environment.api + '/drug/search';
        return this.http.post(qstring, params)
            .pipe(map(function (response) {
            return response;
        }));
    };
    DrugDataService.prototype.searchDrugSpending = function (id) {
        var qstring = environment.api + '/drug/search-spending/' + id;
        return this.http.get(qstring, { headers: { 'interceptNoAccess': 'true' } })
            .pipe(map(function (response) {
            return response;
        }));
    };
    DrugDataService.prototype.getDrugCommonForEdit = function (id) {
        var qstring = environment.api + '/drug/' + id.toString(10);
        return this.http.get(qstring, { headers: { 'interceptNoAccess': 'true' } });
    };
    DrugDataService.prototype.getDrugCommonForLookup = function (id) {
        var qstring = environment.api + '/drug/lookup/' + id.toString(10);
        return this.http.get(qstring);
    };
    DrugDataService.prototype.storeDrugCommonInfo = function (model) {
        var qstring = environment.api + '/drug/store-common';
        return this.http.post(qstring, model)
            .pipe(map(function (response, error) {
            return response.data.id;
        }));
    };
    DrugDataService.prototype.storeDrugWriteoff = function (model) {
        var qstring = environment.api + '/drug/store-writeoff';
        return this.http.post(qstring, model);
    };
    DrugDataService.prototype.deleteDrugWriteoff = function (id, date) {
        var qstring = environment.api + '/drug/delete-writeoff/' + id + '/' + StringHelper.getISODate(new Date(date));
        return this.http.post(qstring, undefined);
    };
    DrugDataService.prototype.addMoving = function (date, drugId, value, packageCount, institutionId, institutionBranchId) {
        var qstring = environment.api + '/drug/add-moving/' + StringHelper.getISODate(new Date(date)) + '/' + drugId +
            '/' + value + '/' + packageCount + '/' + institutionId + '/' + institutionBranchId;
        return this.http.post(qstring, undefined);
    };
    DrugDataService.prototype.deleteDrug = function (id) {
        var qstring = environment.api + '/drug/delete/' + id;
        return this.http.post(qstring, undefined);
    };
    DrugDataService.prototype.getReservedByCurrentUser = function () {
        var qstring = environment.api + '/drug/reserved-by-current-user';
        return this.http.get(qstring)
            .pipe(map(function (response) {
            return response;
        }));
    };
    DrugDataService.ngInjectableDef = i0.defineInjectable({ factory: function DrugDataService_Factory() { return new DrugDataService(i0.inject(i1.HttpClient)); }, token: DrugDataService, providedIn: "root" });
    return DrugDataService;
}());
export { DrugDataService };
