import { MetadataService } from '../../logic/services/metadata.service';
import { environment } from '../../../environments/environment';
import { forkJoin, ReplaySubject } from 'rxjs/index';
import { FormBuilder } from '@angular/forms';
import { FormHelper } from './form-helper';
import { LookupSourceService } from '../../logic/services/lookup-source.service';
import { SecurityService } from '../../logic/services/security.service';
import { GroupOperationReportsMetadataService } from '../../logic/services/group-operation-reports-metadata.service';
import { first } from 'rxjs/internal/operators';
import { AppModalPreviewFileComponent } from './app-modal-preview-file.component';
import { AppNavigationService } from '../../logic/services/app-navigation.service';
var AppReportsButtonComponent = /** @class */ (function () {
    function AppReportsButtonComponent(lookupSourceService, metadataService, fb, securityService, navigationService) {
        this.lookupSourceService = lookupSourceService;
        this.metadataService = metadataService;
        this.fb = fb;
        this.securityService = securityService;
        this.navigationService = navigationService;
        this.isInvalid = FormHelper.isInvalid;
        this.disabledReports = [];
        this.printing = false;
        this.availableReports = [];
        this.availableGroups = [];
        this.tinyLinkMode = false;
        this.dropdownPosition = 'bottom-left';
        this.showCaretIcon = true;
        this.chooseParamsModalVisible = false;
    }
    Object.defineProperty(AppReportsButtonComponent.prototype, "reportKind", {
        get: function () {
            return this._reportKind;
        },
        set: function (val) {
            var _this = this;
            if (val !== this.reportKind) {
                this._reportKind = val;
                var reportKinds = val.split(',');
                forkJoin(reportKinds.map(function (reportKind) { return _this.lookupSourceService.getLookup('report-' + reportKind).pipe(first()); }))
                    .subscribe(function (reports) {
                    var reportList = [].concat.apply([], reports);
                    _this.setupReportList(reportList);
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    AppReportsButtonComponent.prototype.setupReportList = function (reportList) {
        var groups = [];
        var groupHash = {};
        var reportsWithoutGroup = [];
        (reportList || []).forEach(function (report) {
            if (report.groupCaption) {
                if (!groupHash[report.groupCaption]) {
                    groupHash[report.groupCaption] = { caption: report.groupCaption, reports: [] };
                    groups.push(groupHash[report.groupCaption]);
                }
                groupHash[report.groupCaption].reports.push(report);
            }
            else {
                reportsWithoutGroup.push(report);
            }
        });
        this.availableReports = reportsWithoutGroup;
        this.availableGroups = groups;
    };
    AppReportsButtonComponent.prototype.printForm = function (report) {
        var _this = this;
        this.printing = true;
        this.chooseReportCustomParams(report).subscribe(function (params) {
            if (!params) {
                _this.printing = false;
                return;
            }
            _this.metadataService.createReport({
                reportId: report.id,
                objId1: _this.objId1,
                objId2: _this.objId2,
                objId3: _this.objId3,
                customParamValues: params,
                preview: _this.metadataService.isAvailableFormatsForPreviewFile(report.outputFormat),
            }).subscribe({
                next: function (fileHash) {
                    _this.printing = false;
                    if (_this.metadataService.isAvailableFormatsForPreviewFile(report.outputFormat)) {
                        _this.navigationService.previewFile(AppModalPreviewFileComponent, {
                            fileHash: fileHash,
                            buildSourceFormatFunc: function () { return _this.metadataService.createReport({
                                reportId: report.id,
                                objId1: _this.objId1,
                                objId2: _this.objId2,
                                objId3: _this.objId3,
                                customParamValues: params,
                            }).subscribe(function (hash) { return window.open(hash.startsWith('http')
                                ? hash
                                : environment.api + '/files/get?preloadId=' + encodeURIComponent(hash)); }); },
                        });
                    }
                    else {
                        window.open(fileHash.startsWith('http')
                            ? fileHash
                            : environment.api + '/files/get?preloadId=' + encodeURIComponent(fileHash));
                    }
                },
                error: function () {
                    _this.printing = false;
                }
            });
        });
    };
    AppReportsButtonComponent.prototype.chooseReportCustomParams = function (report) {
        var _this = this;
        this.paramsChoosedObs = new ReplaySubject();
        if (!report.customParameters || !report.customParameters.length) {
            this.paramsChoosedObs.next([]);
        }
        else {
            this.securityService.getUserInfo().subscribe(function (userInfo) {
                _this.paramsValues = _this.fb.array(report.customParameters.map(function (el) {
                    var defaultVal = GroupOperationReportsMetadataService.getDefaultValue(el, userInfo);
                    return _this.fb.group({
                        customParamCode: el.code, displayName: el.displayName,
                        comments: el.comments, dataType: el.dataType, lookupName: el.lookupName,
                        paramDateValue: [el.dataType === 2 ? defaultVal : undefined, FormHelper.validateDateTimePicker()],
                        paramStringValue: el.dataType === 1 ? defaultVal : undefined,
                        paramIntValue: el.dataType === 3 ? defaultVal : undefined,
                        paramIntArrayValue: []
                    });
                }));
            });
            this.checkOnEditableCustomParams(this.paramsValues);
        }
        return this.paramsChoosedObs;
    };
    AppReportsButtonComponent.prototype.chooseParamsCompleted = function () {
        if (!this.paramsValues.valid) {
            return;
        }
        this.paramsChoosedObs.next(this.paramsValues.value);
        this.chooseParamsModalVisible = false;
    };
    AppReportsButtonComponent.prototype.chooseParamsCancelled = function () {
        this.paramsChoosedObs.next(undefined);
        this.chooseParamsModalVisible = false;
    };
    AppReportsButtonComponent.prototype.checkOnEditableCustomParams = function (paramsValues) {
        var control = paramsValues.value;
        for (var i = control.length - 1; i >= 0; i--) {
            if (control[i].dataType === 999) {
                this.paramsValues.removeAt(i);
            }
        }
        if (paramsValues.value.length) {
            this.chooseParamsModalVisible = true;
        }
        else {
            this.paramsChoosedObs.next([]);
        }
    };
    return AppReportsButtonComponent;
}());
export { AppReportsButtonComponent };
