/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./layout.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./header/header.component.ngfactory";
import * as i3 from "./header/header.component";
import * as i4 from "../../ui/infrastructure/alert.service";
import * as i5 from "../../logic/services/security.service";
import * as i6 from "../../logic/services/app-navigation.service";
import * as i7 from "../../logic/services/user.service";
import * as i8 from "../../logic/services/lookup-source.service";
import * as i9 from "../../esia/esia.service";
import * as i10 from "../../ui/infrastructure/job-runner-ui.service";
import * as i11 from "../../logic/services/metadata.service";
import * as i12 from "@angular/forms";
import * as i13 from "./main/main.component.ngfactory";
import * as i14 from "./main/main.component";
import * as i15 from "../../ui/infrastructure/global-waiting-overlay.service";
import * as i16 from "../../ui/controls/app-confirm-modal.component.ngfactory";
import * as i17 from "../../ui/controls/app-confirm-modal.component";
import * as i18 from "../common/app-custom-modal.component.ngfactory";
import * as i19 from "../common/app-custom-modal.component";
import * as i20 from "./version-history/version-history.component.ngfactory";
import * as i21 from "./version-history/version-history.component";
import * as i22 from "../../app.version-history";
import * as i23 from "../../ui/controls/job-runner-modal.component.ngfactory";
import * as i24 from "../../ui/controls/job-runner-modal.component";
import * as i25 from "../../ui/controls/app-logging-modal.component.ngfactory";
import * as i26 from "../../ui/controls/app-logging-modal.component";
import * as i27 from "@angular/common";
import * as i28 from "../../esia/auth-found-multiple.component.ngfactory";
import * as i29 from "../../esia/auth-found-multiple.component";
import * as i30 from "@angular/router";
import * as i31 from "./layout.component";
var styles_LayoutComponent = [i0.styles];
var RenderType_LayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LayoutComponent, data: {} });
export { RenderType_LayoutComponent as RenderType_LayoutComponent };
function View_LayoutComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(2, 114688, null, 0, i3.HeaderComponent, [i4.AlertService, i5.SecurityService, i6.AppNavigationService, i7.UserService, i8.LookupSourceService, i9.EsiaService, i10.JobRunnerUiService, i11.MetadataService, i12.FormBuilder], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "app-main", [], null, null, null, i13.View_MainComponent_0, i13.RenderType_MainComponent)), i1.ɵdid(4, 114688, null, 0, i14.MainComponent, [i15.GlobalWaitingOverlayService], null, null), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-confirm-modal", [], null, null, null, i16.View_AppConfirmModalComponent_0, i16.RenderType_AppConfirmModalComponent)), i1.ɵdid(7, 49152, null, 0, i17.AppConfirmModalComponent, [i4.AlertService], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-custom-modal", [], null, null, null, i18.View_AppCustomModalComponent_0, i18.RenderType_AppCustomModalComponent)), i1.ɵdid(9, 49152, null, 0, i19.AppCustomModalComponent, [i6.AppNavigationService], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-version-history-modal", [], null, null, null, i20.View_VersionHistoryComponent_0, i20.RenderType_VersionHistoryComponent)), i1.ɵdid(11, 49152, null, 0, i21.VersionHistoryComponent, [i22.AppVersionHistory, i6.AppNavigationService], null, null), (_l()(), i1.ɵeld(12, 0, null, null, 1, "app-job-runner-modal", [], null, null, null, i23.View_JobRunnerModalComponent_0, i23.RenderType_JobRunnerModalComponent)), i1.ɵdid(13, 49152, null, 0, i24.JobRunnerModalComponent, [i10.JobRunnerUiService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 4, 0); }, null); }
function View_LayoutComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-logging-modal", [], null, null, null, i25.View_AppLoggingModalComponent_0, i25.RenderType_AppLoggingModalComponent)), i1.ɵdid(1, 49152, null, 0, i26.AppLoggingModalComponent, [], null, null)], null, null); }
function View_LayoutComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "main-container itech-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_LayoutComponent_2)), i1.ɵdid(2, 16384, null, 0, i27.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i27.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LayoutComponent_3)), i1.ɵdid(5, 16384, null, 0, i27.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.securityService.getCurrentUserId())); _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.securityService.currentUserInfoLoaded(); _ck(_v, 5, 0, currVal_1); }, null); }
function View_LayoutComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-auth-multiple", [], null, null, null, i28.View_AuthFoundMultipleComponent_0, i28.RenderType_AuthFoundMultipleComponent)), i1.ɵdid(1, 114688, null, 0, i29.AuthFoundMultipleComponent, [i9.EsiaService, i30.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_LayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LayoutComponent_1)), i1.ɵdid(1, 16384, null, 0, i27.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LayoutComponent_4)), i1.ɵdid(3, 16384, null, 0, i27.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.esiaService.loggedIn(); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.esiaService.authMultipleInstitutionForUser; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_LayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-layout", [], null, null, null, View_LayoutComponent_0, RenderType_LayoutComponent)), i1.ɵdid(1, 114688, null, 0, i31.LayoutComponent, [i5.SecurityService, i9.EsiaService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LayoutComponentNgFactory = i1.ɵccf("app-layout", i31.LayoutComponent, View_LayoutComponent_Host_0, {}, {}, ["*"]);
export { LayoutComponentNgFactory as LayoutComponentNgFactory };
