import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '../../ui/infrastructure/alert.service';
import { GlobalWaitingOverlayService } from '../../ui/infrastructure/global-waiting-overlay.service';
import { AppNavigationService } from '../../logic/services/app-navigation.service';
import { PersonDataService } from '../../logic/services/person-data.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormHelper } from '../../ui/controls/form-helper';
import { SecurityService } from '../../logic/services/security.service';
import { MetadataService } from '../../logic/services/metadata.service';
import { LookupSourceService } from '../../logic/services/lookup-source.service';

@Component({
  templateUrl: './user-roles-browse.component.html',
  styleUrls: ['./user-roles-browse.component.css']
})
export class UserRolesBrowseComponent implements OnInit {

  initialRoleId: number;
  userRoles: any[] = [];
  selectedUserRole: any;
  contextFormGroupBoundUserRole: any;
  contextFormGroup: FormGroup;
  serverSideValidationErrors: any[];
  storing = false;
  groups = [];
  itemsLoading = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: FormBuilder,
              private alertService: AlertService,
              private metadataService: MetadataService,
              private globalWaitingOverlayService: GlobalWaitingOverlayService,
              private navigationService: AppNavigationService,
              private securityService: SecurityService,
              private lookupSourceService: LookupSourceService) {
  }

  refreshUserRoles() {
    this.globalWaitingOverlayService.StartWaiting();

    this.metadataService.getAllRoles().subscribe(data => {
      data.sort((a, b) => a.caption.localeCompare(b.caption));
      this.userRoles = data;
      this.navigateInitialGroup();
      this.globalWaitingOverlayService.EndWaiting();
    }, error => {
      this.alertService.error(error);
      this.globalWaitingOverlayService.EndWaiting();
    });
  }

  isInvalid(fg: FormGroup, cname: string) {
    return FormHelper.isInvalid(fg, cname);
  }

  ngOnInit() {

    this.route.paramMap
      .subscribe(params => {

        this.userRoles = [];

        this.initialRoleId = +params.get('id') || undefined;

        this.navigateInitialGroup();

        this.refreshUserRoles();
      });
  }

  deleteCurrentRole() {
    if (!this.selectedUserRole.id) {
      return;
    }

    this.alertService.confirmModal('Вы уверены, что хотите безвозвратно удалить текущую роль?')
      .subscribe(() => {
        this.metadataService.deleteSecurityRole(this.selectedUserRole.id).subscribe(() => {
          this.router.navigate([`user-roles-browse`]);
        });
      });
  }

  createContextFormGroup() {
    if (this.selectedUserRole) {
      if (!this.selectedUserRole.personGroupVisibility) {
        if (this.selectedUserRole.scopeInstitutionId) {
          this.selectedUserRole.personGroupVisibility = 2;
        } else {
          this.selectedUserRole.personGroupVisibility = 1;
        }
      }
      this.selectedUserRole.rightsGroup = this.fb.array([]);
      this.contextFormGroup = this.fb.group(this.selectedUserRole);

      this.groups = [];

      if (!this.selectedUserRole.id) {
        this.contextFormGroup.markAsDirty();
        this.fillItems(this.selectedUserRole);
      } else {
        this.itemsLoading = true;
        this.metadataService.getRoleForEdit(this.selectedUserRole.id).subscribe(val => {
          this.fillItems(val);
          this.itemsLoading = false;
        }, () => {
          this.itemsLoading = false;
        });
      }
    } else {
      this.contextFormGroup = undefined;
    }

    this.contextFormGroupBoundUserRole = this.selectedUserRole;
  }

  store() {
    if (this.storing) {
      return;
    }

    this.storing = true;

    FormHelper.markAsSubmitted(this.contextFormGroup);
    if (!this.contextFormGroup.valid) {
      return;
    }

    this.securityService.getUserInfo().subscribe(userInfo => {

      const dataToStore = this.contextFormGroup.value;
      if (+dataToStore.personGroupVisibility === 2) {
        dataToStore.scopeInstitutionId = userInfo.institutionId;
      } else {
        dataToStore.scopeInstitutionId = undefined;
      }

      dataToStore.rights = [];

      this.groups.forEach(group => {
        group.items.forEach(item => {
          if (item.formGroup.get('checked').value) {
            dataToStore.rights.push(item.formGroup.value);
          }
        });
      });

      this.metadataService.storeRole(dataToStore).subscribe(val => {
        Object.assign(this.selectedUserRole, dataToStore);
        this.selectedUserRole.id = val.id;
        this.selectedUserRole.version = val.version;
        this.createContextFormGroup();
        this.storing = false;
      }, error => {
        this.storing = false;
        this.lookupSourceService.invalidateLookup('security-role');
        FormHelper.setSingleFormGroupServerSideValidationErrors(error, this, this.contextFormGroup);
      });
    });
  }

  addRole() {
    this.selectedUserRole = { id: undefined, caption: 'Новая роль', roleVisibility: 2, rights: [] };
    this.userRoles.push(this.selectedUserRole);
    this.invalidateContextFormGroup();
  }

  copyRole() {
    const copyData = Object.assign({}, this.selectedUserRole);
    copyData.caption = copyData.caption + ' (копия)';

    this.userRoles.push(copyData);
    this.selectedUserRole = copyData;
    this.invalidateContextFormGroup();

    this.contextFormGroup.get('id').setValue(null);
    this.contextFormGroup.markAsDirty();
  }

  invalidateContextFormGroup() {
    if (!this.contextFormGroup || this.contextFormGroupBoundUserRole !== this.selectedUserRole) {
      this.createContextFormGroup();
    }
  }

  selectedRoleChange() {
    if (this.selectedUserRole && this.selectedUserRole.id) {
      this.router.navigate([`user-roles-browse/${this.selectedUserRole.id}`]);
    } else {
      this.invalidateContextFormGroup();
    }
  }

  private navigateInitialGroup() {
    if (!this.userRoles) {
      return;
    }

    this.selectedUserRole = this.userRoles.find(item => (item.id === this.initialRoleId));
    this.invalidateContextFormGroup();
  }

  private fillItems(val: any) {
    this.groups = [];
    const groups = {};
    const funcItemsMap = {};
    const parentChildMap = {};

    this.metadataService.getAllFunctions().subscribe(functions => {

      functions.sort((a, b) => a.caption.localeCompare(b.caption));

      functions.forEach(func => {
        const funcItem = { func: func, nestedFuncs: [], formGroup: null };
        funcItemsMap[func.id] = funcItem;
        // при наличии базовой функции будем текущую показывать только в рамках базовой без отдельной выдачи прав
        if (func.parentId) {
          const children = parentChildMap[func.parentId] ? parentChildMap[func.parentId] : parentChildMap[func.parentId] = [];
          children.push(funcItem);
        } else {
          let valItem = val.rights.find(el => el.functionId === func.id);
          if (!valItem) {
            valItem = {
              checked: false,
              objectsScope: func.appliesScopes ? undefined : 1,
              functionId: func.id,
              objectsMeta1Kind: 3,
              objectsMeta1: this.fb.array([]),
              objectsMeta2Kind: 3,
              objectsMeta2: this.fb.array([]),
              objectsMeta3Kind: 3,
              objectsMeta3: this.fb.array([]),
              objectsMetaCombineKey1Kind: 3,
              objectsMetaCombineKey1: this.fb.array([]),
            };
          } else {
            valItem.checked = true;
            valItem.objectsMeta1 = this.fb.array(valItem.objectsMeta1 || []);
            valItem.objectsMeta2 = this.fb.array(valItem.objectsMeta2 || []);
            valItem.objectsMeta3 = this.fb.array(valItem.objectsMeta3 || []);
            valItem.objectsMetaCombineKey1 = this.fb.array(valItem.objectsMetaCombineKey1 || []);
          }

          const fg = this.fb.group(valItem);
          (this.contextFormGroup.get('rightsGroup') as FormArray).push(fg);
          funcItem.formGroup = fg;

          if (!groups[func.groupCaption]) {
            groups[func.groupCaption] = { items: [], caption: func.groupCaption };
            this.groups.push(groups[func.groupCaption]);
          }

          groups[func.groupCaption].items.push(funcItem);
        }
      });

      for (const parentId in parentChildMap) {
        if (parentChildMap[parentId] && funcItemsMap[parentId]) {
          funcItemsMap[parentId].nestedFuncs = parentChildMap[parentId];
        }
      }

      this.groups.sort((a, b) => a.caption.localeCompare(b.caption));
      if (this.groups.length > 0) {
        this.groups[0].tabActive = true;
      }
    });
  }
}
