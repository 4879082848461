import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/internal/operators';

@Injectable()
export class InstitutionDataService {

  constructor(private http: HttpClient) {
  }

  public search(): Observable<any[]> {
    const qstring = environment.api + '/institution/search';
    return this.http.post(qstring, {})
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  deleteInstitution(id: number) {
    const qstring = environment.api + '/institution/delete/' + id;
    return this.http.post(qstring, undefined);
  }

  getInstitutionCommonForEdit(id: number): Observable<any> {
    const qstring = environment.api + '/institution/' + id.toString(10);
    return this.http.get(qstring, {headers: {'interceptNoAccess': 'true'}});
  }

  getInstitutionCommonForLookup(id: number): Observable<any> {
    const qstring = environment.api + '/institution/lookup/' + id.toString(10);
    return this.http.get(qstring);
  }

  getInstitutionBranchesForEdit(id: number): Observable<any[]> {
    const qstring = environment.api + '/institution/branches/' + id.toString(10);
    return this.http.get(qstring, {headers: {'interceptNoAccess': 'true'}})
      .pipe(map(item => {
        return item as any[];
      }));
  }

  getInstitutionIndicatorsForEdit(id: number): Observable<any[]> {
    const qstring = environment.api + '/institution/indicators/' + id.toString(10);
    return this.http.get(qstring, {headers: {'interceptNoAccess': 'true'}})
      .pipe(map(item => {
        return item as any[];
      }));
  }

  getInstitutionEmployeesForEdit(id: number): Observable<any[]> {
    const qstring = environment.api + '/institution/employees/' + id.toString(10);
    return this.http.get(qstring, {headers: {'interceptNoAccess': 'true'}})
      .pipe(map(item => {
        return item as any[];
      }));
  }

  getInstitutionBranchesForLookup(id: number): Observable<any[]> {
    const qstring = environment.api + '/institution/branches/lookup/' + id.toString(10);
    return this.http.get(qstring).pipe(map(item => {
      return item as any[];
    }));
  }

  getInstitutionEmployeesForLookup(id: number): Observable<any[]> {
    const qstring = environment.api + '/institution/employees/lookup/' + id.toString(10);
    return this.http.get(qstring).pipe(map(item => {
      return item as any[];
    }));
  }

  storeInstitutionCommonInfo(model: any) {
    const qstring = environment.api + '/institution/store-common';
    return this.http.post(qstring, model)
      .pipe(map((response: any, error: any) => {
        return response.data.id as number;
      }));
  }

  storeInstitutionBranches(institutionId: any, branches: any[], deleted: any[]) {
    const model = {
      institutionId: institutionId,
      branches: branches,
      delete: deleted
    };

    const qstring = environment.api + '/institution/store-branches';
    return this.http.post(qstring, model)
      .pipe(map((response: any, error: any) => {
        return true;
      }));
  }

  storeInstitutionIndicators(institutionId: any, indicators: any[], deleted: any[]) {
    const model = {
      institutionId: institutionId,
      indicators: indicators,
      delete: deleted
    };

    const qstring = environment.api + '/institution/store-indicators';
    return this.http.post(qstring, model).pipe(map(() => true));
  }

  storeInstitutionEmployees(institutionId: any, employees: any[], deleted: any[]) {
    const model = {
      institutionId: institutionId,
      employees: employees,
      delete: deleted
    };

    const qstring = environment.api + '/institution/store-employees';
    return this.http.post(qstring, model)
      .pipe(map((response: any, error: any) => {
        return true;
      }));
  }

  public getAllInstitutions(): Observable<any[]> {
    const qstring = environment.api + '/institution/all-institutions-list';
    return this.http.post(qstring, {})
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  public getHeadInstitutions(): Observable<any[]> {
    const qstring = environment.api + '/institution/head-institutions-list';
    return this.http.post(qstring, {})
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  public getSlaughteryInstitutions(): Observable<any[]> {
    const qstring = environment.api + '/institution/slaughtery-institutions-list';
    return this.http.post(qstring, {})
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  public getLabInstitutions(): Observable<any[]> {
    const qstring = environment.api + '/institution/lab-institutions-list';
    return this.http.post(qstring, {})
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  public getEmployeeMercuryAuthInfo(institutionId: number, employeeId: number): Observable<any> {
    const qstring = environment.api + '/institution/employee-mercury-auth-info/' + institutionId.toString() + '/' + employeeId.toString();
    return this.http.get(qstring);
  }

  public storeEmployeeMercuryAuthInfo(institutionId: number, employeeId: number, authInfo: any): Observable<any> {
    const qstring = environment.api + '/institution/employee-mercury-auth-info/store/'
      + institutionId.toString() + '/' + employeeId.toString();

    return this.http.post(qstring, authInfo);
  }

  public getEmployeeDevice(institutionId: number, employeeId: number): Observable<any> {
    const qstring = environment.api + '/institution/employee-device/' + institutionId.toString() + '/' + employeeId.toString();
    return this.http.get(qstring);
  }

  public acceptEmployeeDevice(device: any): Observable<any> {
    const qstring = environment.api + '/institution/employee-device/accept';
    return this.http.post(qstring, device);
  }

  public deleteEmployeeDevice(device: any): Observable<any> {
    const qstring = environment.api + '/institution/employee-device/delete';
    return this.http.post(qstring, device);
  }

  public getAllMessages(institutionId: any): Observable<any[]> {
    const qstring = environment.api + '/institution/message/by-institution/' + institutionId;
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  public getLastMessages(institutionId: any): Observable<any[]> {
    const qstring = environment.api + '/institution/message/by-institution-last/' + institutionId;
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  public storeMessage(data: any): Observable<any> {
    const qstring = environment.api + '/institution/message/store';
    return this.http.post(qstring, data);
  }

  public markReading(
    agentId: number,
    institutionId: number,
    institutionBranchId: number,
    messageTypeId: number,
    messageIds: number[],
  ): Observable<any> {
    const qstring = environment.api +
      `/institution/message/mark-reading/${agentId}/${institutionId}/${institutionBranchId}/${messageTypeId}/${messageIds}`;
    return this.http.get(qstring);
  }

  public getFavouriteStableIds(institutionId: number, institutionEmployeeId: number): Observable<number[]> {
    const qstring = `${environment.api}/institution/employees/get-favourite-stables/${institutionId}/${institutionEmployeeId}`;
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response.data as number[];
      }));
  }

  public removeFavouriteStableId(institutionId: number, institutionEmployeeId: number, stableId: number): Observable<number> {
    const qstring = environment.api + '/institution/employees/remove-favourite-stable/' +
      institutionId + '/' + institutionEmployeeId + '/' + stableId;
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response.data as any;
      }));
  }
}
