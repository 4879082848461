import { ElementRef, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
var PstBranchCellTextEditComponent = /** @class */ (function () {
    function PstBranchCellTextEditComponent() {
        this.changed = new EventEmitter();
        this.valueStr = '';
        this.valueLoaded = false;
        this.valueUpdated = false;
    }
    Object.defineProperty(PstBranchCellTextEditComponent.prototype, "proposedCellValue", {
        get: function () {
            return this.model.__cl_editingProposedValue;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PstBranchCellTextEditComponent.prototype, "cellValueDisplay", {
        get: function () {
            return this.originalCellValue;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PstBranchCellTextEditComponent.prototype, "cellValueStr", {
        get: function () {
            if (!this.valueLoaded) {
                var value = this.originalCellValue;
                if (this.proposedCellValue || this.proposedCellValue === 0) {
                    this.valueUpdated = true;
                    value = this.proposedCellValue;
                }
                if (!value) {
                    this.valueStr = '';
                }
                else {
                    this.valueStr = value;
                }
                this.valueLoaded = true;
            }
            return this.valueStr;
        },
        set: function (strValue) {
            this.valueUpdated = true;
            this.valueStr = strValue;
        },
        enumerable: true,
        configurable: true
    });
    PstBranchCellTextEditComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.hasOwnProperty('editing')) {
            if (this.editing) {
                setTimeout(function () {
                    if (_this.inputControl) {
                        _this.inputControl.nativeElement.focus();
                    }
                }, 100);
            }
            if (!this.editing && this.valueUpdated) {
                setTimeout(function () {
                    _this.updateCellValueInModel();
                    _this.valueLoaded = false;
                    _this.valueUpdated = false;
                }, 100);
            }
        }
    };
    PstBranchCellTextEditComponent.prototype.inputKeyDown = function ($event) {
        if ($event.key === 'Enter') {
            // заканчиваем редактирование с записью измененного значения
            this.model.__cl_editingBranch = undefined;
            this.inputControl.nativeElement.closest('table').focus();
        }
        if ($event.key === 'Escape') {
            // заканчиваем редактирование без записи измененного значения
            this.valueLoaded = false;
            this.valueUpdated = false;
            this.model.__cl_editingBranch = undefined;
            this.inputControl.nativeElement.closest('table').focus();
        }
    };
    PstBranchCellTextEditComponent.prototype.updateCellValueInModel = function () {
        var parsedVal;
        if (this.valueStr) {
            parsedVal = this.valueStr.trim();
        }
        if (this.changed) {
            this.changed.emit(parsedVal);
        }
    };
    return PstBranchCellTextEditComponent;
}());
export { PstBranchCellTextEditComponent };
