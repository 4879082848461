import { FormGroup } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
var QueryBooleanFilterComponent = /** @class */ (function () {
    function QueryBooleanFilterComponent() {
        this.class = 'form-group';
    }
    QueryBooleanFilterComponent.prototype.isInvalid = function (cname) {
        return FormHelper.isInvalid(this.contextFormGroup, cname);
    };
    Object.defineProperty(QueryBooleanFilterComponent.prototype, "filtered", {
        get: function () {
            return !!this.contextFormGroup.get('_par_' + this.parameterName + '_filter').value;
        },
        enumerable: true,
        configurable: true
    });
    QueryBooleanFilterComponent.prototype.controlVisible = function () {
        return this.contextFormGroup
            && (!this.queryUserMode || this.queryUserMode['_par_' + this.parameterName + '_filter_mq']);
    };
    return QueryBooleanFilterComponent;
}());
export { QueryBooleanFilterComponent };
