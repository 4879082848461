import { Component } from '@angular/core';
import { QueryDetailsEntityComponent } from './query-details-entity.component';
import { QueryService } from '../../logic/services/query.service';
import { DataCachingService } from '../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../logic/services/lookup-source.service';
import { AlertService } from '../../ui/infrastructure/alert.service';
import { FormBuilder } from '@angular/forms';
import { FormHelper } from '../../ui/controls/form-helper';

@Component({
  selector: 'app-query-details-agent',
  templateUrl: './query-details-agent.component.html',
  styleUrls: ['./query.all.css']
})
export class QueryDetailsAgentComponent extends QueryDetailsEntityComponent {

  EntityType = 5; // agent

  constructor(queryService: QueryService,
              dataCachingService: DataCachingService,
              waitingOverlay: GlobalWaitingOverlayService,
              lookupSourceService: LookupSourceService,
              alertService: AlertService,
              fb: FormBuilder) {

    super(queryService, dataCachingService, waitingOverlay, lookupSourceService, alertService, fb);
  }

  static getGroupDef(condition: any) {
    return {
      type: 'agent',

      _par_created_institution: condition.data._par_created_institution,
      _par_created_institution_filter: condition.data._par_created_institution_filter || 0,
      _par_created_institution_employee: condition.data._par_created_institution_employee,
      _par_created_institution_employee_filter: condition.data._par_created_institution_employee_filter || 0,
      _par_created_time_filter: condition.data._par_created_time_filter || 0,
      _par_created_time_from: [condition.data._par_created_time_from, FormHelper.validateDateTimePicker()],
      _par_created_time_to: [condition.data._par_created_time_to, FormHelper.validateDateTimePicker()],

      _par_deleted_institution: condition.data._par_deleted_institution,
      _par_deleted_institution_filter: condition.data._par_deleted_institution_filter || 0,
      _par_deleted_institution_employee: condition.data._par_deleted_institution_employee,
      _par_deleted_institution_employee_filter: condition.data._par_deleted_institution_employee_filter || 0,
      _par_deleted_time_filter: condition.data._par_deleted_time_filter || 0,
      _par_deleted_time_from: [condition.data._par_deleted_time_from, FormHelper.validateDateTimePicker()],
      _par_deleted_time_to: [condition.data._par_deleted_time_to, FormHelper.validateDateTimePicker()],

      _par_legal_form_id_filter: condition.data._par_legal_form_id_filter || 0,
      _par_legal_form_id: condition.data._par_legal_form_id,
      _par_title_filter: condition.data._par_title_filter || 0,
      _par_title: condition.data._par_title,

      _par_date_tax_registered_filter: condition.data._par_date_tax_registered_filter || 0,
      _par_date_tax_registered_from: [condition.data._par_date_tax_registered_from, FormHelper.validateDateTimePicker()],
      _par_date_tax_registered_to: [condition.data._par_date_tax_registered_to, FormHelper.validateDateTimePicker()],
      _par_date_tax_included_filter: condition.data._par_date_tax_included_filter || 0,
      _par_date_tax_included_from: [condition.data._par_date_tax_included_from, FormHelper.validateDateTimePicker()],
      _par_date_tax_included_to: [condition.data._par_date_tax_included_to, FormHelper.validateDateTimePicker()],
      _par_date_tax_eliminated_filter: condition.data._par_date_tax_eliminated_filter || 0,
      _par_date_tax_eliminated_from: [condition.data._par_date_tax_eliminated_from, FormHelper.validateDateTimePicker()],
      _par_date_tax_eliminated_to: [condition.data._par_date_tax_eliminated_to, FormHelper.validateDateTimePicker()],

      _par_address_legal_filter: condition.data._par_address_legal_filter || 0,
      _par_address_legal: condition.data._par_address_legal,
      _par_address_fact_filter: condition.data._par_address_fact_filter || 0,
      _par_address_fact: condition.data._par_address_fact,

      _par_date_registered_external_registry_filter: condition.data._par_date_registered_external_registry_filter || 0,
      _par_date_registered_external_registry_from:
        [condition.data._par_date_registered_external_registry_from, FormHelper.validateDateTimePicker()],
      _par_date_registered_external_registry_to:
        [condition.data._par_date_registered_external_registry_to, FormHelper.validateDateTimePicker()],
    };
  }

  getGroupDef() {
    return QueryDetailsAgentComponent.getGroupDef(this.model.condition);
  }
}
