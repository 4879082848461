import { Component, Input, OnInit } from '@angular/core';
import { QueryService } from '../../logic/services/query.service';
import { DataCachingService } from '../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../logic/services/lookup-source.service';

@Component({
  templateUrl: './query-manager.component.html',
  styleUrls: ['./query.all.css']
})
export class QueryManagerComponent implements OnInit {

  FormModelCacheName = 'QueryManagerComponent_Cache';

  @Input() model = {
    entityTypes: [],
    data: [],
    dataColumns: [],
    entityType: undefined,
    query: undefined,
    queryViewId: undefined,
    nodeType: undefined,
    condition: undefined,
    parentCondition: undefined,
    selectedNode: undefined,
    selected: false
  };

  public storing = false;

  constructor(private queryService: QueryService,
              private dataCachingService: DataCachingService,
              private lookupSourceService: LookupSourceService) {
  }

  ngOnInit() {
    this.refreshFormDataInternal();
  }

  refreshFormDataInternal() {

    const existing = this.dataCachingService.getCachedData(this.FormModelCacheName, '1');

    if (existing && existing.data) {

      this.model = existing;
    } else {

      this.dataCachingService.addToCache(this.FormModelCacheName, '1', this.model, 1);
    }

    if (!this.model.entityTypes.length) {
      this.lookupSourceService.getLookup('query-entity-type', true).subscribe(dict => {
        this.model.entityTypes = dict;
      });
    }
  }

  lazyLoadQueries(entityType: any) {

    if (!entityType.queries) {
      entityType.queries = [];
      entityType.queries.push({data: {caption: 'Новая выборка', entityType: entityType.id}, conditions: []});

      setTimeout(() => {

        entityType.queriesLoading = true;

        this.queryService.loadQueries(entityType.id).subscribe(
          queries => {

            queries.sort((el, el2) => el.caption.localeCompare(el2.caption));

            entityType.queries = queries.map(el => {
              return {data: el, conditions: this.queryService.buildConditionsForEdit(el.conditions, el.entityType)};
            });
            entityType.queries.push({data: {caption: 'Новая выборка', entityType: entityType.id}, conditions: []});
            entityType.queriesLoading = false;
          },
          error => {
            entityType.queriesLoading = false;
          });
      });
    }

    return entityType.queries;
  }

  onConditionClick(entityType: any, query: any, condition: any, parentCondition: any) {
    this.model.entityType = entityType;
    this.model.query = query;
    this.model.queryViewId = undefined;
    this.model.condition = condition;
    this.model.nodeType = 'condition';
    this.model.selectedNode = condition;
    this.model.parentCondition = parentCondition;
  }

  onQueryClick(entityType: any, query: any) {
    this.model.entityType = entityType;
    this.model.query = query;
    this.model.queryViewId = undefined;
    this.model.condition = undefined;
    this.model.nodeType = 'query';
    this.model.selectedNode = query;
    this.model.parentCondition = undefined;
  }

  onQueryViewClick(entityType: any, query: any) {
    this.model.entityType = entityType;
    this.model.query = query;
    this.model.queryViewId = this.model.entityType.id;
    this.model.condition = undefined;
    this.model.nodeType = 'results';
    this.model.selectedNode = query;
    this.model.parentCondition = undefined;
  }

  onEntityClick(entityType: any) {
    this.model.entityType = entityType;
    this.model.query = undefined;
    this.model.queryViewId = undefined;
    this.model.condition = undefined;
    this.model.nodeType = 'entity';
    this.model.selectedNode = entityType;
    this.model.parentCondition = undefined;
  }

  onEntityQueriesClick(entityType: any) {
    this.model.entityType = entityType;
    this.model.query = undefined;
    this.model.queryViewId = undefined;
    this.model.condition = undefined;
    this.model.nodeType = 'entityQueries';
    this.model.selectedNode = entityType;
    this.model.parentCondition = undefined;
  }

  creatingRelation($event) {

    const rt = $event.relationType;
    const parentCondition = $event.condition;
    if (!parentCondition.relations) {
      parentCondition.relations = [];
    }

    const condition = {
      data: {
        relationType: rt.id,
        arityFrom: 1,
        arityTo: 1000,
        type: this.queryService.getConditionTypeClassName(rt.targetEntityType),
      }, isNew: true
    };

    parentCondition.relations.push(condition);

    parentCondition.expanded = true;
    this.onConditionClick(this.model.entityType, this.model.query, condition, parentCondition);
  }
}
