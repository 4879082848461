import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { StringHelper } from '../../helpers/string-helper';
import { AsyncSubject, combineLatest, forkJoin, Observable, of } from 'rxjs/index';
import { SecurityService } from './security.service';
import { LookupSourceService } from './lookup-source.service';
import { DateHelper } from '../../helpers/date-helper';
import { first, map } from 'rxjs/internal/operators';
import { FormHelper } from '../../ui/controls/form-helper';

@Injectable({
  providedIn: 'root',
})
export class GroupOperationMetadataService {

  private reports$ = new AsyncSubject<any>();
  private reportGroups$ = new AsyncSubject<any[]>();

  constructor(private lookupSourceService: LookupSourceService,
              private fb: FormBuilder,
              private securityService: SecurityService) {

    const reportGroupsObj = {};

    // универсалный компонент отчетов также можно использовать для отчетов по выборкам
    // тип отчетов при этом будет query-<entityTypeId>. entityTypeId = 1 - выборка по гражданам
    // если добавится новый тип отчетов - нужно добывить его ниже в reportKinds
    const reportKinds = ['aggregated', 'query-1'];

    forkJoin(<Observable<any[]>[]>reportKinds.map(
      reportKind => this.lookupSourceService.getLookup('report-' + reportKind).pipe(first())))
      .subscribe({
        next: reportsLists => {
          const reportsRaw = [].concat.apply([], reportsLists);

          const reportGroups = [];
          const reports: any = {};

          reportsRaw.forEach(reportRaw => {
            const report = GroupOperationMetadataService.buildReportFromRawMetadata(reportRaw);
            reports[report.opCode] = report;
          });

          for (const rname in reports) {
            if (reports.hasOwnProperty(rname)) {
              const report = reports[rname];
              report.operationParamsCode = rname;
              if (!reportGroupsObj[report.group]) {
                reportGroupsObj[report.group] = {caption: report.group, reports: []};
                reportGroups.push(reportGroupsObj[report.group]);
              }
              reportGroupsObj[report.group].reports.push(report);
            }
          }

          this.reportGroups$.next(reportGroups);
          this.reportGroups$.complete();
          this.reports$.next(reports);
          this.reports$.complete();
        }
      });
  }

  public static getDefaultValue(paramDef: any, userInfo: any): any {
    if (paramDef.defaultValue === '$cmonth') {
      return StringHelper.getISODate(DateHelper.startOfTheMonth(new Date()));
    } else if (paramDef.defaultValue === '$cyear') {
      return StringHelper.getISODate(DateHelper.startOfTheYear(new Date()));
    } else if (paramDef.defaultValue === '$cquarter') {
      return StringHelper.getISODate(DateHelper.startOfTheQuarter(new Date()));
    } else if (paramDef.defaultValue === '$emonth') {
      return StringHelper.getISODate(DateHelper.endOfTheMonthByDate(new Date()));
    } else if (paramDef.defaultValue === '$cnextMonth') {
      return StringHelper.getISODate(DateHelper.startOfTheNextMonth(new Date()));
    } else if (paramDef.defaultValue === '$cdate') {
      return StringHelper.getISODate(new Date());
    } else if (paramDef.defaultValue === '$emptylist') {
      return [];
    } else if (paramDef.defaultValue === '$institution') {
      return +userInfo.institutionId;
    } else {
      return paramDef.defaultValue;
    }
  }

  public static buildReportFromRawMetadata(reportRaw: any) {
    return {
      id: reportRaw.id,
      opCode: 'op' + reportRaw.id,
      operationTypeId: reportRaw.jobTypeId,
      group: reportRaw.groupCaption || 'Прочие отчеты',
      caption: reportRaw.caption,
      operationTitle: 'Создание отчета \'' + reportRaw.caption + '\'',
      customParameters: reportRaw.customParameters
    };
  }

  public getMassReportGroups$(): Observable<any[]> {
    return this.reportGroups$;
  }

  public getReportMetadata$(opCode: string): Observable<any> {
    return this.reports$.pipe(map(reports => reports[opCode]));
  }

  public getReportMetadataByJobTypeId$(jobTypeId: number, reportId?: number): Observable<any> {
    return this.reports$.pipe(map(reports => {
      for (const rname in reports) {
        if (reports.hasOwnProperty(rname) && reports[rname].operationTypeId === jobTypeId
          && (!reportId || reports[rname].id === reportId)) {
          return reports[rname];
        }
      }
      return undefined;
    }));
  }

  public getOpParamsGroupDef$(params: ParamMap, opCode: string): Observable<any> {
    return this.getReportMetadata$(opCode).pipe(map(report => {
      if (!report) {
        return undefined;
      }

      const groupDef: any = {exportSur: false};

      report.customParameters.forEach(paramDef => {
        if (+paramDef.dataType === 4) { // type: array
          groupDef[paramDef.code] = this.fb.array([]);
        } else {
          groupDef[paramDef.code] = [params.get(paramDef.code) || paramDef.defaultValue];
        }
        if (+paramDef.dataType === 2) { // type: DateTime
          groupDef[paramDef.code].push(FormHelper.validateDateTimePicker());
        }
      });

      groupDef.reportId = report.id;

      return groupDef;
    }));
  }

  public getOpDefaultParams$(opCode: string): Observable<any> {
    return combineLatest([
      this.getReportMetadata$(opCode),
      this.securityService.getUserInfo()
    ]).pipe(map(([report, userInfo]) => {
        if (!report) {
          return undefined;
        }

        const groupDef: any = {};

        report.customParameters.forEach(paramDef => {
          groupDef[paramDef.code] = GroupOperationMetadataService.getDefaultValue(paramDef, userInfo);
        });

        groupDef.uniqueTag = 'new$' + Math.floor(Math.random() * 100000000000) + 1;

        return groupDef;
      }
    ));
  }
}
