import { StringHelper } from '../helpers/string-helper';

export class PersonSearchParams {
  static readonly ModeUnknown = 0;
  static readonly ModeFio = 1;
  static readonly ModeSnils = 2;
  static readonly ModeIdentityDoc = 3;
  static readonly ModeAddress = 4;
  static readonly ModeFioXact = 5;
  static readonly ModePersonId = 6;
  static readonly ModeAccountBik = 8;
  static readonly ModeEgpuOrderId = 9;

  static readonly ModeTwinFioPart = 10;
  static readonly ModeTwinFioFull = 11;
  static readonly ModeTwinFioBirth = 12;

  mode: number;
  snils: number;
  epguOrderId: number;
  bik: string;
  accountNumber: string;
  lastName: string;
  firstName: string;
  middleName: string;
  birthDate: Date;
  streetName: string;
  houseNo: string;
  roomNo: number;
  docSubtypeId: number;
  docSeria: string;
  docNumber: string;
  docIssueDate: Date;
  personId: number;
  asoiCardNumber: string;

  static fromString(shortValue: string): PersonSearchParams {

    try {
      // строка быстрого поиска есть строка вида "фамилия имя отчество дата рождения"
      // при этом дата рождения, или отчество, или имя и отчество могут отсутствовать
      // символы ";", "," считаем разделителями, эквивалентными пробелам
      shortValue = shortValue.replace(',', ' ').replace(';', ' ').trim();

      if (shortValue.toLowerCase() === 'дв фио') {
        const params = new PersonSearchParams();
        params.mode = PersonSearchParams.ModeTwinFioFull;
        return params;
      }

      if (shortValue.toLowerCase() === 'дв фи') {
        const params = new PersonSearchParams();
        params.mode = PersonSearchParams.ModeTwinFioPart;
        return params;
      }

      if (shortValue.toLowerCase() === 'дв др') {
        const params = new PersonSearchParams();
        params.mode = PersonSearchParams.ModeTwinFioBirth;
        return params;
      }

      const m1 = shortValue.match(/[а-яА-ЯёЁ\s-]+\s+\d{1,2}\.\d{1,2}\.\d{4}/g);
      const m2 = shortValue.match(/[а-яА-ЯёЁ\s-]+/g);
      if ((m1 && m1.indexOf(shortValue) >= 0) /*ФИО с датой рождения*/
        || (m2 && m2.indexOf(shortValue) >= 0) /*ФИО просто*/) {
        return PersonSearchParams.parseFio(shortValue);
      }

      const m3 = shortValue.match(/\d{3}[- ]*\d{3}[- ]*\d{3}[- ]*\d{2}/g);
      if (m3 && m3.indexOf(shortValue) >= 0) {
        // СНИЛС
        const snilsParams = new PersonSearchParams();
        snilsParams.mode = PersonSearchParams.ModeSnils;
        snilsParams.snils = StringHelper.strToSnils(shortValue);
        return snilsParams;
      }

      const m4 = shortValue.match(/епгу\s+\d+/g);
      if (m4 && m4.indexOf(shortValue) >= 0) {
        // номер епгу
        return PersonSearchParams.parseEpgu(shortValue);
      }

      const m8 = shortValue.match(/ид\s+\d+/g);
      if (m8 && m8.indexOf(shortValue) >= 0) {
        // номер епгу
        return PersonSearchParams.parseId(shortValue);
      }

      const m5 = shortValue.match(/пасп\s+[\dа-яА-ЯIVX-]+\s+\d+/g);
      if (m5 && m5.indexOf(shortValue) >= 0) {
        // паспорт серия номер
        return PersonSearchParams.parseIdentity(1, shortValue);
      }

      const m6 = shortValue.match(/свид\s+[\dа-яА-ЯIVX-]+\s+\d+/g);
      if (m6 && m6.indexOf(shortValue) >= 0) {
        // свид. о рожд. серия номер
        return PersonSearchParams.parseIdentity(2, shortValue);
      }

      const m7 = shortValue.match(/^(.+?)(\d+[а-яА-ЯёЁ-]*\s*)(\d*)$/);
      if (m7 && m7.indexOf(shortValue) >= 0) {

        return PersonSearchParams.parseAddress(shortValue);
      }
    } catch (err) {
    // глушим все возможные ошибки преобразований. если ошибка - значит режим - неизвестный
    }

    const unknownParams = new PersonSearchParams();
    unknownParams.mode = PersonSearchParams.ModeUnknown;
    return unknownParams;
  }

  static parseEpgu(shortValue: string): PersonSearchParams {
    const retVal = new PersonSearchParams();
    retVal.mode = PersonSearchParams.ModeEgpuOrderId;
    const matches = shortValue.match(/епгу\s+(\d+)/);
    retVal.epguOrderId = parseInt(matches.length > 1 ? matches[1] : '0', 10);
    return retVal;
  }

  static parseId(shortValue: string): PersonSearchParams {
    const retVal = new PersonSearchParams();
    retVal.mode = PersonSearchParams.ModePersonId;
    const matches = shortValue.match(/ид\s+(\d+)/);
    retVal.personId = parseInt(matches.length > 1 ? matches[1] : '0', 10);
    return retVal;
  }

  static parseIdentity(docSubtypeId: number, shortValue: string): PersonSearchParams {
    const retVal = new PersonSearchParams();
    retVal.mode = PersonSearchParams.ModeIdentityDoc;
    let partSignature = '';
    let seriesFormat = '';
    switch (docSubtypeId) {
      case 1:
        partSignature = 'пасп';
        seriesFormat = '9999';
        break;
      case 2:
        partSignature = 'свид';
        seriesFormat = 'RR-ББ';
        break;
    }

    const matches = shortValue.match(partSignature + `\\s+([\\dа-яА-ЯIVX-]+)\\s+(\\d{1,9})`);
    retVal.docSeria = StringHelper.normalizeSeries(matches[1], seriesFormat);
    retVal.docNumber = matches[2];
    retVal.docSubtypeId = docSubtypeId;
    return retVal;
  }

  static parseAddress(shortValue: string) {
    const retVal = new PersonSearchParams();
    retVal.mode = PersonSearchParams.ModeAddress;

    const matches = shortValue.match(/^(.+?)(\d+[а-яА-ЯёЁ-]*\s*)(\d*)$/);
    retVal.streetName = PersonSearchParams.toFilterString(matches[1].trim());
    retVal.houseNo = matches[2].trim();
    if (matches.length > 3 && matches[3] !== '') {
      retVal.roomNo = parseInt(matches[3], 10);
    } else {
      retVal.roomNo = undefined;
    }
    return retVal;
  }

  static parseFio(shortValue: string): PersonSearchParams {
    const retVal = new PersonSearchParams();
    retVal.mode = PersonSearchParams.ModeFio;

    // сначала найдем и отсечём от остального дату рождения - используя следующие допущения:
    // (1) ФИО не может содержать цифр
    // (2) Дата рождения всегда начинается с цифры
    // (3) Дата рождения всегда стоит последней в строке поиска
    const index = shortValue.search(/[0-9]+/);
    // если успешно нашли дату рождения, отсекаем и сохраняем её
    if (index >= 0) {
      const dateBirth = StringHelper.parseRussianDate(shortValue.substring(index));
      if (!dateBirth) {
        throw new Error(
          `Дата рождения в строке быстрого поиска представлена ` +
          `в неверном формате ("${shortValue.substring(index)}"). Правильный формат - 'дд.мм.гггг'`);
      }
      if (dateBirth < new Date(Date.UTC(1850, 1, 1))) {
        throw new Error(`Дата рождения в строке поиска слишком маленькая (${dateBirth})`);
      }
      retVal.birthDate = dateBirth;
      shortValue = shortValue.substr(0, index);
    }

    shortValue = shortValue.replace('  ', ' ');
    // Теперь слева направо извлекаем фамилию, имя, отчество (имя и отчество могут отсутствовать).
    // при этом подразумеваем, что они разделены пробелами
    const fio = shortValue.split(' ');

    if (fio.length === 0) {
      throw new Error('Строка поиска должна содержать как минимум искомую фамилию');
    }

    if (fio.length > 0) {
      retVal.lastName = PersonSearchParams.toFilterString(fio[0].trim().replace('_', ' '));
    }
    if (fio.length > 1) {
      retVal.firstName = PersonSearchParams.toFilterString(fio[1].trim().replace('_', ' '));
    } else {
      retVal.firstName = PersonSearchParams.toFilterString('');
    }
    if (fio.length > 2) {
      retVal.middleName = PersonSearchParams.toFilterString(fio[2].trim().replace('_', ' '));
    } else {
      retVal.middleName = PersonSearchParams.toFilterString('');
    }

    return retVal;
  }

  static toFilterString(filter: string): string {
    if (!filter) {
      filter = '';
    }
    filter = filter.trim();
    filter = filter.replace('*', '%%');
    filter = filter.endsWith('%') ? filter : filter + '%';
    return filter;
  }

  getModeDisplay(): string {
    switch (this.mode) {
      case PersonSearchParams.ModeFio:
        return 'По ФИО/Д.Р.';
      case PersonSearchParams.ModeSnils:
        return 'По СНИЛС';
      case PersonSearchParams.ModeIdentityDoc:
        return 'По серии номеру документа';
      case PersonSearchParams.ModeAddress:
        return 'По адресу';
      case PersonSearchParams.ModeFioXact:
        return 'По ФИО (точн. совп.)';
      case PersonSearchParams.ModePersonId:
        return 'По № дела';
      case PersonSearchParams.ModeAccountBik:
        return 'По номеру счета';
      case PersonSearchParams.ModeEgpuOrderId:
        return 'По номеру заяв. ЕПГУ';
      case PersonSearchParams.ModeTwinFioFull:
        return 'Полное совпадение ФИО';
      case PersonSearchParams.ModeTwinFioPart:
        return 'Частичное совпадение ФИО';
      case PersonSearchParams.ModeTwinFioBirth:
        return 'Отличается только дата рождения';
      default:
        return 'Неизвестный режим поиска';
    }
  }
}
