/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./calendar-browse.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@clr/angular";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../node_modules/@clr/angular/clr-angular.ngfactory";
import * as i5 from "./calendar-browse.component";
import * as i6 from "../../../logic/services/metadata.service";
import * as i7 from "../../../ui/infrastructure/alert.service";
var styles_CalendarBrowseComponent = [i0.styles];
var RenderType_CalendarBrowseComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CalendarBrowseComponent, data: {} });
export { RenderType_CalendarBrowseComponent as RenderType_CalendarBrowseComponent };
function View_CalendarBrowseComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "clr-icon", [["shape", "check"]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.ClrIconCustomTag, [], null, null)], null, null); }
function View_CalendarBrowseComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "button", [["clrDropdownItem", ""], ["type", "button"]], [[2, "disabled", null], [2, "dropdown-item", null], [1, "role", 0], [1, "aria-disabled", 0], [1, "disabled", 0], [1, "id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeYear(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(1024, null, i2.ɵo, i2.ɵp, []), i1.ɵprd(512, [[1, 4]], i2.ɵr, i2.ɵt, [i2.ɵo, i1.ElementRef, i1.Renderer2, i1.PLATFORM_ID]), i1.ɵdid(3, 4341760, null, 0, i2.ClrDropdownItem, [i2.ClrDropdown, i1.ElementRef, i2.ɵe, i1.Renderer2, i2.ɵr], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CalendarBrowseComponent_4)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(6, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_6 = (_co.year == _v.context.$implicit); _ck(_v, 5, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).disabled; var currVal_1 = true; var currVal_2 = "menuitem"; var currVal_3 = i1.ɵnov(_v, 3).disabled; var currVal_4 = ((i1.ɵnov(_v, 3).disabled && i1.ɵnov(_v, 3).setByDeprecatedDisabled) ? "" : null); var currVal_5 = i1.ɵnov(_v, 3).dropdownItemId; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_7 = _v.context.$implicit; _ck(_v, 6, 0, currVal_7); }); }
function View_CalendarBrowseComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "clr-dropdown-menu", [], [[2, "dropdown-menu", null], [1, "role", 0], [2, "is-off-screen", null]], null, null, i4.View_ClrDropdownMenu_0, i4.RenderType_ClrDropdownMenu)), i1.ɵdid(1, 9617408, null, 1, i2.ClrDropdownMenu, [i1.Injector, [2, i2.ɵh], [3, i2.ClrDropdownMenu], i2.ɵl], { position: [0, "position"] }, null), i1.ɵqud(603979776, 1, { items: 1 }), (_l()(), i1.ɵeld(3, 0, null, 0, 2, "label", [["class", "dropdown-header"]], [[1, "for", 0]], null, null, null, null)), i1.ɵdid(4, 212992, null, 0, i2.ClrLabel, [[2, i2.ɵbe], [2, i2.ɵbf], [2, i2.ɵbg], i1.Renderer2, i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, null, ["\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0434\u0440\u0443\u0433\u043E\u0439 \u0433\u043E\u0434"])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_CalendarBrowseComponent_3)), i1.ɵdid(7, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = "bottom-left"; _ck(_v, 1, 0, currVal_3); _ck(_v, 4, 0); var currVal_5 = _co.availableYears; _ck(_v, 7, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = "menu"; var currVal_2 = i1.ɵnov(_v, 1).isOffScreen; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = i1.ɵnov(_v, 4).forAttr; _ck(_v, 3, 0, currVal_4); }); }
function View_CalendarBrowseComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "clr-dropdown", [], [[2, "dropdown", null], [2, "open", null]], null, null, i4.View_ClrDropdown_0, i4.RenderType_ClrDropdown)), i1.ɵprd(6144, null, i2.ɵh, null, [i1.ElementRef]), i1.ɵprd(6144, null, i2.ɵr, null, [i2.ɵl]), i1.ɵprd(512, null, i2.ɵd, i2.ɵd, []), i1.ɵprd(1024, null, i2.ɵe, i2.ɵf, [[3, i2.ɵe]]), i1.ɵdid(5, 180224, null, 0, i2.ClrDropdown, [[3, i2.ClrDropdown], i2.ɵd, i1.ChangeDetectorRef, i2.ɵe], null, null), i1.ɵprd(1024, null, i2.ɵo, i2.ɵp, []), i1.ɵprd(1024, null, i2.ɵi, i2.ɵj, [[3, i2.ɵi], i1.Renderer2]), i1.ɵprd(131584, null, i2.ɵl, i2.ɵl, [i2.ɵo, i1.Renderer2, [3, i2.ɵl], i2.ɵd, i2.ɵi, i1.PLATFORM_ID]), (_l()(), i1.ɵeld(9, 0, null, 0, 4, "button", [["class", "btn btn-link"], ["clrDropdownToggle", ""]], [[2, "dropdown-toggle", null], [2, "dropdown-item", null], [2, "expandable", null], [2, "active", null], [1, "aria-haspopup", 0], [1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onDropdownTriggerClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 16384, null, 0, i2.ClrDropdownTrigger, [i2.ClrDropdown, i2.ɵd, i1.ElementRef, i2.ɵl], null, null), (_l()(), i1.ɵted(-1, null, [" \u0414\u0440\u0443\u0433\u043E\u0439 \u0433\u043E\u0434"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "clr-icon", [["shape", "caret down"]], null, null, null, null, null)), i1.ɵdid(13, 16384, null, 0, i2.ClrIconCustomTag, [], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_CalendarBrowseComponent_2)), i1.ɵdid(15, 147456, null, 0, i2.ClrIfOpen, [i2.ɵd, i1.TemplateRef, i1.ViewContainerRef], { open: [0, "open"] }, null)], function (_ck, _v) { var currVal_8 = null; _ck(_v, 15, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = i1.ɵnov(_v, 5).ifOpenService.open; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 10).isRootLevelToggle; var currVal_3 = !i1.ɵnov(_v, 10).isRootLevelToggle; var currVal_4 = !i1.ɵnov(_v, 10).isRootLevelToggle; var currVal_5 = i1.ɵnov(_v, 10).active; var currVal_6 = "menu"; var currVal_7 = i1.ɵnov(_v, 10).active; _ck(_v, 9, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
function View_CalendarBrowseComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "itech-calendar-one-day"], ["title", "\u0429\u0435\u043B\u043A\u043D\u0438\u0442\u0435, \u0447\u0442\u043E\u0431\u044B \u0438\u0437\u043C\u0435\u043D\u0438\u0442\u044C \u0440\u0430\u0431\u043E\u0447\u0438\u0439/\u0432\u044B\u0445\u043E\u0434\u043D\u043E\u0439"]], [[2, "itech-empty", null], [2, "itech-holiday", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dayClick(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = !_v.context.$implicit; var currVal_1 = (_v.context.$implicit ? _v.context.$implicit.holiday : undefined); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = (_v.context.$implicit ? _v.context.$implicit.day : undefined); _ck(_v, 1, 0, currVal_2); }); }
function View_CalendarBrowseComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "itech-calendar-one-week"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CalendarBrowseComponent_7)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.days; _ck(_v, 2, 0, currVal_0); }, null); }
function View_CalendarBrowseComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "itech-calendar-one-month"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 14, "div", [["class", "itech-calendar-week-headers"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u041F\u043D"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0412\u0442"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0421\u0440"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0427\u0442"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u041F\u0442"])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "div", [["class", "itech-holiday"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0421\u0431"])), (_l()(), i1.ɵeld(16, 0, null, null, 1, "div", [["class", "itech-holiday"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0412\u0441"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CalendarBrowseComponent_6)), i1.ɵdid(19, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.weeks; _ck(_v, 19, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.caption; _ck(_v, 2, 0, currVal_0); }); }
export function View_CalendarBrowseComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "h1", [["class", "itech-calendar-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["\u041F\u0440\u043E\u0438\u0437\u0432\u043E\u0434\u0441\u0442\u0432\u0435\u043D\u043D\u044B\u0439 \u043A\u0430\u043B\u0435\u043D\u0434\u0430\u0440\u044C \u043D\u0430 ", " \u0433\u043E\u0434 "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CalendarBrowseComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "itech-calendar-cont"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "itech-calendar-months-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "itech-calendar-months-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CalendarBrowseComponent_5)), i1.ɵdid(8, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.availableYears; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.months; _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.year; _ck(_v, 1, 0, currVal_0); }); }
export function View_CalendarBrowseComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_CalendarBrowseComponent_0, RenderType_CalendarBrowseComponent)), i1.ɵdid(1, 114688, null, 0, i5.CalendarBrowseComponent, [i6.MetadataService, i7.AlertService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CalendarBrowseComponentNgFactory = i1.ɵccf("ng-component", i5.CalendarBrowseComponent, View_CalendarBrowseComponent_Host_0, {}, {}, []);
export { CalendarBrowseComponentNgFactory as CalendarBrowseComponentNgFactory };
