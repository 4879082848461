/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./slaughtery-view-form.component.ngfactory";
import * as i2 from "./slaughtery-view-form.component";
import * as i3 from "../../../logic/services/animal-data.service";
import * as i4 from "../../../ui/infrastructure/global-waiting-overlay.service";
import * as i5 from "../../../logic/services/app-navigation.service";
import * as i6 from "../../../logic/services/security.service";
import * as i7 from "@clr/angular";
import * as i8 from "./slaughtery-view.component";
import * as i9 from "@angular/router";
import * as i10 from "../../../logic/services/data-caching.service";
import * as i11 from "@angular/forms";
import * as i12 from "../../../logic/services/metadata.service";
var styles_SlaughteryViewComponent = [];
var RenderType_SlaughteryViewComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SlaughteryViewComponent, data: {} });
export { RenderType_SlaughteryViewComponent as RenderType_SlaughteryViewComponent };
export function View_SlaughteryViewComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "itech-common-wrap no-pager"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-slaughtery-view-form", [], null, [[null, "rowClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("rowClicked" === en)) {
        var pd_0 = (_co.appNavigationService.performAnimalEventEditing($event.col16) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_SlaughteryViewFormComponent_0, i1.RenderType_SlaughteryViewFormComponent)), i0.ɵdid(3, 49152, null, 0, i2.SlaughteryViewFormComponent, [i3.AnimalDataService, i4.GlobalWaitingOverlayService, i5.AppNavigationService, i6.SecurityService], { model: [0, "model"] }, { rowClicked: "rowClicked" }), (_l()(), i0.ɵeld(4, 0, null, null, 4, "div", [["class", "itech-form-actions"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 3, "button", [["class", "btn btn-outline-secondary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.printSlaughtery() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "clr-icon", [["shape", "printer"]], null, null, null, null, null)), i0.ɵdid(7, 16384, null, 0, i7.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, ["\u00A0\u0420\u0430\u0441\u043F\u0435\u0447\u0430\u0442\u0430\u0442\u044C "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_SlaughteryViewComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-slaughtery-view", [], null, null, null, View_SlaughteryViewComponent_0, RenderType_SlaughteryViewComponent)), i0.ɵdid(1, 114688, null, 0, i8.SlaughteryViewComponent, [i9.ActivatedRoute, i10.DataCachingService, i5.AppNavigationService, i11.FormBuilder, i12.MetadataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SlaughteryViewComponentNgFactory = i0.ɵccf("app-slaughtery-view", i8.SlaughteryViewComponent, View_SlaughteryViewComponent_Host_0, {}, {}, []);
export { SlaughteryViewComponentNgFactory as SlaughteryViewComponentNgFactory };
