import { Component } from '@angular/core';
import { QueryDetailsEntityComponent } from './query-details-entity.component';
import { QueryService } from '../../logic/services/query.service';
import { DataCachingService } from '../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../logic/services/lookup-source.service';
import { AlertService } from '../../ui/infrastructure/alert.service';
import { FormBuilder } from '@angular/forms';
import { FormHelper } from '../../ui/controls/form-helper';

@Component({
  selector: 'app-query-details-quarantine',
  templateUrl: './query-details-quarantine.component.html',
  styleUrls: ['./query.all.css']
})
export class QueryDetailsQuarantineComponent extends QueryDetailsEntityComponent {
  EntityType = 4; // Карантин

  constructor(queryService: QueryService,
              dataCachingService: DataCachingService,
              waitingOverlay: GlobalWaitingOverlayService,
              lookupSourceService: LookupSourceService,
              alertService: AlertService,
              fb: FormBuilder) {

    super(queryService, dataCachingService, waitingOverlay, lookupSourceService, alertService, fb);
  }

  static getGroupDef(fb: FormBuilder, condition: any) {
    return {
      type: 'quarantine',

      _par_disease_type: condition.data._par_disease_type,
      _par_disease_type_filter: condition.data._par_disease_type_filter || 0,
      _par_source_pathogen_animal_type: condition.data._par_source_pathogen_animal_type,
      _par_source_pathogen_animal_type_filter: condition.data._par_source_pathogen_animal_type_filter || 0,
      _par_source_pathogen_animal_subtype: condition.data._par_source_pathogen_animal_subtype,
      _par_source_pathogen_animal_subtype_filter: condition.data._par_source_pathogen_animal_subtype_filter || 0,
      _par_susceptible_unique_animal_subtype: fb.array(condition.data._par_susceptible_unique_animal_subtype || []),
      _par_susceptible_unique_animal_subtype_filter: condition.data._par_susceptible_unique_animal_subtype_filter || 0,
      _par_current_status: condition.data._par_current_status,
      _par_current_status_filter: condition.data._par_current_status_filter || 0,
      _par_date_filter: condition.data._par_date_filter || 0,
      _par_date_from: [condition.data._par_date_from, FormHelper.validateDateTimePicker()],
      _par_date_to: [condition.data._par_date_to, FormHelper.validateDateTimePicker()],
      _par_responsible_institution: condition.data._par_responsible_institution,
      _par_responsible_institution_filter: condition.data._par_responsible_institution_filter || 0,
      _par_responsible_institution_employee: condition.data._par_responsible_institution_employee,
      _par_responsible_institution_employee_filter: condition.data._par_responsible_institution_employee_filter || 0,
      _par_territory_type: condition.data._par_territory_type,
      _par_territory_type_filter: condition.data._par_territory_type_filter || 0,
      _par_city: condition.data._par_city,
      _par_city_filter: condition.data._par_city_filter || 0,
      _par_region: condition.data._par_region,
      _par_region_filter: condition.data._par_region_filter || 0,
      _par_start_quarantine_date_filter: condition.data._par_start_quarantine_date_filter || 0,
      _par_start_quarantine_date_from: [condition.data._par_start_quarantine_date_from, FormHelper.validateDateTimePicker()],
      _par_start_quarantine_date_to: [condition.data._par_start_quarantine_date_to, FormHelper.validateDateTimePicker()],
      _par_end_quarantine_date_filter: condition.data._par_end_quarantine_date_filter || 0,
      _par_end_quarantine_date_from: [condition.data._par_end_quarantine_date_from, FormHelper.validateDateTimePicker()],
      _par_end_quarantine_date_to: [condition.data._par_end_quarantine_date_to, FormHelper.validateDateTimePicker()],
    };
  }

  getGroupDef() {
    return QueryDetailsQuarantineComponent.getGroupDef(this.fb, this.model.condition);
  }
}
