import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';

@Component({
  templateUrl: './edit-animal-event.component.html'
})
export class EditAnimalEventComponent implements OnInit {

  @Input() model: any = EditAnimalEventComponent.createDefaultModel();
  @Input() id: number;

  static createDefaultModel(): any {
    return {
      commonTabActive: true,
      eventsTabActive: false,
      drugsTabActive: false,
      illnessTabActive: false,
    };
  }

  constructor(private route: ActivatedRoute,
              private bookmarkService: BookmarkService,
              private dataCachingService: DataCachingService) {
  }

  ngOnInit() {

    this.route.paramMap
      .subscribe(params => {
        this.id = parseInt(params.get('id'), 10);

        const existing = this.dataCachingService.getCachedData('EditAnimalEvent', this.id.toString());

        this.bookmarkService.refreshAnimalEventCardBookmark(this.id);

        if (existing) {
          this.model = existing;
        } else {
          this.model = EditAnimalEventComponent.createDefaultModel();
          this.dataCachingService.addToCache('EditAnimalEvent', this.id.toString(), this.model);
        }
      });
  }
}
