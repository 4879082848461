import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { AlertService } from '../../ui/infrastructure/alert.service';
import { isString } from 'util';
import { EsiaService } from '../../esia/esia.service';

@Injectable()
export class AlertsHttpInterceptor implements HttpInterceptor {

  constructor(private alertService: AlertService) {

  }

  private lastDefaultError: Date;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.alertService.incrementHttpRequests();
    return next.handle(req).pipe(tap(
      // Succeeds when there is a response; ignore other events
      event => {
        if (event instanceof HttpResponse) {
          this.alertService.decrementHttpRequests();
          this.processMessages(event.body && event.body.messages || []);
        }
      },
      // Operation failed; error is an HttpErrorResponse
      error => {
        this.alertService.decrementHttpRequests();
        if (error.error && error.error.exception && error.error.exception === 'ru.infotech24.v19main.security.oauth.EsiaAuthException') {
          EsiaService.clear(true);
          return;
        }

        if (error.error && isString(error.error.data) && error.error.data === 'NO-ACCESS') {

          if (Boolean(JSON.parse(req.headers.get('interceptNoAccess')))) {
            const element = document.getElementById('interceptNoAccess');
            if (element) {
              const message = error.error.messages && error.error.messages.length && error.error.messages[0].message
                ? error.error.messages[0].message
                : 'Доступ к разделу запрещен';

              const html = `<div style="width:40%;float:left">
                              <div style="text-align:center"><h1 style="font-size:48px">&#128584;</h1></div>
                              <div style="text-align:center"><h3>${message}</h3></div>
                            </div>`;

              const node = document.createElement('div');
              node.id = 'no-access';
              node.style.height = '100%';
              node.innerHTML = html;
              const appRoot = element.parentNode;
              appRoot.removeChild(element);
              appRoot.appendChild(node);
              return;
            }
          }
        }

        if (error.error && error.error.data &&
          error.error.data.exception === 'ru.infotech24.v19main.security.oauth.EsiaFoundMultipleInstitutionException') {
          return;
        }

        if (error.error && error.error.messages) {
          this.processMessages(error.error.messages);
        } else {

          if (error.error && error.error.exception === 'ru.infotech24.common.exceptions.WebClientConnectivityException') {
            this.alertService.warning(error.error.message || 'Ошибка соединения с внешними системами. Попробуйте позже');
            return;
          }

          if (error.error &&
            error.error.exception === 'ru.infotech24.common.exceptions.conversation.ConversationPermanentException' ||
            error.error.exception === 'ru.infotech24.common.exceptions.conversation.ConversationTemporaryException') {
            this.alertService.error(error.error.message || 'Ошибка при синхронизации');
            return;
          }

          // показываем не чаще чем раз в секунду
          if (!this.lastDefaultError || (new Date().getTime() - this.lastDefaultError.getTime()) > 1000) {
            if (error.status === 0) {
              this.alertService.warning('Потеряно соединение с сервером');
            } else {
              this.alertService.warning('Операция не произведена из-за ошибок на сервере');
            }
          }

          this.lastDefaultError = new Date();
        }

        if (error.error && isString(error.error.data) && error.error.data !== 'NO-ACCESS') {
          this.alertService.warning(error.error.data);
        }
      }
    ));
  }

  processMessages(messages: any[]): any {
    if (!messages) {
      return;
    }
    messages.forEach(item => {
      switch (item.severity) {
        case 'Success':
          this.alertService.success(item.text || item.message);
          break;
        case 'Info':
          this.alertService.info(item.text || item.message);
          break;
        case 'Warning':
          this.alertService.warning(item.text || item.message);
          break;
        case 'Error':
          this.alertService.error(item.text || item.message);
          break;
      }
    });
  }
}
