import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { v4 as Uuid } from 'uuid';
import { environment } from '../../environments/environment';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StringHelper } from '../helpers/string-helper';
import { SecurityService } from '../logic/services/security.service';
import { AlertService } from '../ui/infrastructure/alert.service';
import { first } from 'rxjs/internal/operators';
import { Router } from '@angular/router';

@Injectable()
export class EsiaService {

  public static REDIRECT_URI = document.baseURI;

  private static auth: {
    accessToken: string,
    loggedIn: boolean,
    authInstitutionId: number
  };

  public authMultipleInstitutionForUser = false;

  // Нельзя сразу заинжектить HttpClient в конструкторе, т.к. этот сервис инжектится в HttpInterceptor,
  // а в него нельзя инжектить HttpClient напрямую либо через другие зависимости, т.к. получается циклическая зависимость.
  // Сервисы _securityService и _alertService также зависят от httpClient, поэтому их также инжектим по требованию.
  private _http: HttpClient;
  private _securityService: SecurityService;
  private _alertService: AlertService;
  private _router: Router;

  private get http(): HttpClient {
    return !this._http ? this._http = this.injector.get(HttpClient) : this._http;
  }

  private get securityService(): SecurityService {
    return !this._securityService ? this._securityService = this.injector.get(SecurityService) : this._securityService;
  }

  private get alertService(): AlertService {
    return !this._alertService ? this._alertService = this.injector.get(AlertService) : this._alertService;
  }

  private get router(): Router {
    return !this._router ? this._router = this.injector.get(Router) : this._router;
  }

  constructor(private injector: Injector) {
    if (!EsiaService.auth) {
      EsiaService.auth = JSON.parse(localStorage.getItem('esia'));
    }
  }

  static clear(redirectToRoot: boolean): void {
    EsiaService.auth = null;
    localStorage.removeItem('esia');
    localStorage.removeItem('state');
    if (redirectToRoot) {
      window.location.href = document.baseURI;
    }
  }

  static validateState(state: string): boolean {
    const storedState = localStorage.getItem('state');
    const result = storedState === state;
    if (storedState) {
      localStorage.removeItem('state');
    }
    return result;
  }

  init(): Promise<void> {
    return new Promise<any>((resolve, reject) => {
      const code = StringHelper.getQueryParam('code');
      const state = StringHelper.getQueryParam('state');

      // Это не редирект от ЕСИА
      if (!code || !state) {
        if (!this.loggedIn()) {
          this.login(); // Получаем url для аутентификации и переходим на него
        } else {
          resolve();    // Мы уже вошли
        }
      } else { // А это редирект
        if (!EsiaService.validateState(state)) {
          reject('Invalid state received');
          window.location.href = document.baseURI;
          return;
        }

        console.log('Callback is OK');

        this.authenticate(code)
          .subscribe(
            data => data && data.notFound ? reject(data.notFound) : resolve(),
            error => {
              reject(error);
              window.location.href = document.baseURI;
            });
      }
    });
  }

  loggedIn(): boolean {
    return EsiaService.auth !== null && EsiaService.auth.accessToken !== null && EsiaService.auth.loggedIn;
  }

  // Запуск процесса входа, редирект на сайт ЕСИА. ЕСИА должен сделать редирект к EsiaCallback
  login(): void {
    EsiaService.clear(false);

    this.getLoginUrl().subscribe(value => {
      if (!environment.production) {
        console.log(value.url);
      }

      window.location.href = value.url;
    });
  }

  authenticate(code: string, impersonateAsUserId?: number, institutionId?: number): Observable<any> {
    const params: any = { 'code': code, redirectUri: EsiaService.REDIRECT_URI };

    if (impersonateAsUserId) {
      params.impersonateAsUserId = impersonateAsUserId;
    }

    if (institutionId) {
      params.institutionId = institutionId;
    }

    if (EsiaService.auth && EsiaService.auth.accessToken) {
      params.accessToken = EsiaService.auth.accessToken;
    }

    return forkJoin([this.getLogoutUrlDirect().pipe(first()), this.getAuth(params).pipe(first())])
      .pipe(map(([logoutUrl, authData]) => {
        if (authData.notFound) {

          try {
            const err = JSON.parse(authData.notFound);

            this.authMultipleInstitutionForUser = true;
            if (err.accessToken) {
              this.setAuth(err.accessToken, false, params.institutionId);
            }

            return this.router.navigate(['esia/auth/found-multiple'],
              {state: {data: {authData: err, logoutUrl: logoutUrl}}}).then();

          } catch (err) {
            this.showNotFoundMessage(authData.notFound, logoutUrl);
          }
        }

        return authData;
      }));
  }

  private getAuth(params: any): Observable<any> {
    return this.http.get(`${environment.api}/esia/` + (!params.institutionId ? 'auth' : 'auth-by-institution-id'), { params: params })
      .pipe(map((data: any) => {
        if (data.notFound) {
          return data;
        }
        EsiaService.auth = { accessToken: data.accessToken, loggedIn: true, authInstitutionId: params.institutionId };
        localStorage.setItem('esia', JSON.stringify(EsiaService.auth));
        this.securityService.invalidateCurrentUser();
        return data;
      }));
  }

  impersonate(impersonateAsUserId: number): Observable<any> {
    const params: any = impersonateAsUserId ? { impersonateAsUserId: impersonateAsUserId } : {};

    return this.http.get(
      `${environment.api}/esia/impersonate`,
      { params: params })
      .pipe(map((data: any) => {
        if (data.notFound) {
          this.alertService.error(data.notFound);
        } else {
          EsiaService.auth = { accessToken: data.accessToken, loggedIn: true, authInstitutionId: EsiaService.auth.authInstitutionId };
          localStorage.setItem('esia', JSON.stringify(EsiaService.auth));
          this.securityService.invalidateCurrentUser();
          this.alertService.success('Имперсонализация прошла успешно!');
        }

        return data;
      }));
  }

  getToken(): string {
    if (!this.loggedIn()) {
      throw new Error('Cant`t get a token. Not logged in.');
    }
    return EsiaService.auth.accessToken;
  }

  getAuthInstitutionId(): number {
    return EsiaService.auth.authInstitutionId;
  }

  logout(): void {
    console.log('*** LOGOUT');
    localStorage.removeItem('esia');
    localStorage.removeItem('state');

    this.getLogoutUrl()
      .subscribe(
        {
          next: (url: string) => {
            this.securityService.markCurrentUserLoggingOut();
            window.location.href = url;
          },
          error: error => {
            console.log(error);
            window.location.href = document.baseURI;
          },
          complete: () => {
            EsiaService.auth = null;
            this.authMultipleInstitutionForUser = false;
          }
        });
  }

  private getLoginUrl(): Observable<any> {
    const state = Uuid();
    localStorage.setItem('state', state);

    return this.http.get(`${environment.api}/esia/get-login-url`,
      { params: { 'state': state, 'redirectUri': EsiaService.REDIRECT_URI } });
  }

  private getLogoutUrl(): Observable<string> {
    return this.http.get(`${environment.api}/esia/logout`,
      { params: { 'redirectUri': document.baseURI } })
      .pipe(map((value: Response) => value.url));
  }

  private getLogoutUrlDirect(): Observable<string> {
    return this.http.get(`${environment.api}/esia/get-logout-url`,
      { params: { 'redirectUri': document.baseURI } })
      .pipe(map((value: Response) => value.url));
  }

  public setAuth(accessToken: string, loggedIn: boolean, authInstitutionId: number) {
    EsiaService.auth = { accessToken: accessToken, loggedIn: loggedIn, authInstitutionId: authInstitutionId };
    localStorage.setItem('esia', JSON.stringify(EsiaService.auth));
    this.securityService.invalidateCurrentUser();
  }

  private showNotFoundMessage(reason: string, logoutUrl: string): void {
    const html = `<div>
  <h1>Вход не выполнен</h1>
  <h4 id="reason">${reason}</h4>
  <p>Если хотите войти через другую учетную запись, выйдите из текущей</p>
  <button class="btn btn-primary itech-link" onclick="window.location.href = '${logoutUrl}'">Выйти из Госуслуг</button>
</div>`;

    const node = document.createElement('div');
    node.id = 'not-logged-in';
    node.style.height = '100%';
    node.innerHTML = html;
    let loadContainer: Element = document.getElementById('load-container');
    if (!loadContainer) {
      loadContainer = document.getElementsByTagName('app-layout')[0];
    }
    const appRoot = loadContainer.parentNode;
    appRoot.removeChild(loadContainer);
    appRoot.appendChild(node);
  }
}
