import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { AgentSearchResultsFormComponent } from './agent-search-results-form.component';
import { FormBuilder } from '@angular/forms';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { AgentDataService } from '../../../logic/services/agent-data.service';

@Component({
  selector: 'app-agent-search-results',
  templateUrl: './agent-search-results.component.html'
})
export class AgentSearchResultsComponent implements OnInit {

  model = AgentSearchResultsFormComponent.getDefaultModel();

  constructor(private route: ActivatedRoute,
              private dataCachingService: DataCachingService,
              public appNavigationService: AppNavigationService,
              private fb: FormBuilder,
              private alertService: AlertService,
              private agentDataService: AgentDataService) {
  }

  ngOnInit() {

    this.route.paramMap
      .subscribe(params => {

        const existing = this.dataCachingService.getCachedData('AgentSearchResults', '1');

        if (existing) {
          this.model = existing;
        } else {
          this.model = AgentSearchResultsFormComponent.getDefaultModel(this.fb);
          this.dataCachingService.addToCache('AgentSearchResults', '1', this.model);
        }
      });
  }

  performAgentEditing(agentData: any) {

    if (agentData.id) {
      this.appNavigationService.performAgentEditing(agentData.id);
    } else if (agentData.mercurySearchedUuid) {
      this.alertService.confirmModal('Нажмите "Продолжить", чтобы создать карточку хозяйствующего субъекта на ' +
        'основе данных в системе Меркурий', 'Продолжить').subscribe(() => {
        this.agentDataService.createByMercuryGuid(agentData.mercurySearchedUuid).subscribe(newAgentData => {
          this.appNavigationService.performAgentEditing(newAgentData.id);
        });
      });
    }
  }
}
