
      <section class="form-block itech-block-medium">
          <h3>{{captionTerritory}}</h3>
          <ng-container *ngFor="let area of territories.controls; index as i" [formGroup]="getFormGroup(area)">
              <div *ngIf="getFormGroup(area).get('quarantineTerritoryTypeId').value == quarantineTerritoryTypeId"
                   style="display: flex; flex-direction: row;margin-bottom: 20px">
                  <div style="flex:1 1 auto;margin:15px 10px 0 0;flex-direction: column;display:flex;align-items:center;width:75px">
                      <clr-icon shape="map-marker" size="60"></clr-icon>
                      <a class="itech-link in-table-link" (click)="removeFormGroupTerritory(i)">Удалить</a>
                  </div>
                  <div style="flex: 1000 1 auto">
                      <div *ngIf="getFormGroup(area).get('quarantineTerritoryTypeId').value == quarantineTerritoryTypeId">
                          <app-address-quarantine id="address{{quarantineTerritoryTypeId}}{{i}}" [required]="true"
                                                  [contextFormGroup]="getFormGroup(area).get('address')"
                                                  [contextFormGroupExtended]="getFormGroup(area)" [disabled]="!isAccessEdit">
                          </app-address-quarantine>
                          <div class="form-group" *ngIf="getFormGroup(area).get('address').get('regionId').value && isRadius">
                              <label for="secondDangerAreaRadius{{i}}">Радиус, км</label>
                              <label for="secondDangerAreaRadius{{i}}" aria-haspopup="true" role="tooltip"
                                     class="tooltip tooltip-validation tooltip-md"
                                     [class.invalid]="isInvalid(getFormGroup(area), 'radius')">
                                  <input type="text" id="secondDangerAreaRadius{{i}}" class="itech-control-normal"
                                         formControlName="radius" [attr.disabled]="!isAccessEdit ? true : undefined">
                                  <app-validation-tooltip [input]="getFormGroup(area).controls['radius']"></app-validation-tooltip>
                              </label>
                          </div>
                          <div class="form-group">
                              <label for="placeSourceComment{{i}}">Примечание</label>
                              <label for="placeSourceComment{{i}}" aria-haspopup="true" role="tooltip"
                                     class="tooltip tooltip-validation tooltip-md"
                                     [class.invalid]="isInvalid(getFormGroup(area), 'comment')">
                                  <input type="text" id="placeSourceComment{{i}}" placeholder="Примечание" class="itech-control-xlarge"
                                         formControlName="comment" [attr.disabled]="!isAccessEdit ? true : undefined" maxlength="255">
                                  <app-validation-tooltip [input]="getFormGroup(area).controls['comment']"></app-validation-tooltip>
                              </label>
                          </div>
                      </div>
                  </div>
              </div>
          </ng-container>

          <div class="form-group itech-group-whole-line">
              <label></label>
              <button type="button" class="btn btn-link itech-inline-link-btn"
                      (click)="addNewFormGroupTerritory(quarantineTerritoryTypeId)">
                  <clr-icon shape="plus"></clr-icon>
                  Добавить
              </button>
          </div>
      </section>