<section class="form-block itech-block-large" [formGroup]="contextFormGroup">
    <div>
    <app-edit-quarantine-decision-next-stage *ngIf="currentStatusId === 0 && noSuspicionStage && !contextFormGroup.get('stagePeriods').value.length"
                                             [title]="'Решение о введении этапа'" [noSuspicionStage]="noSuspicionStage"
                                             [stageId]="0" [contextFormGroup]="contextFormGroup"
                                             [serverSideErrorsProvider]="serverSideErrorsProvider"
                                             (needUpdateQuarantine)="needUpdateQuarantine.next()" [quarantineId]="id"
                                             [quarantineCurrentStatusId]="currentStatusId"></app-edit-quarantine-decision-next-stage>
    <app-edit-quarantine-stages-file [label]="'Направление представления в службу ветеринарного надзора'"
                                     [contextFormGroup]="contextFormGroup" [controlName]="'sendRepresentativeToVeterinaryControl'"
                                     (dirtyFormGroup)="markAsDirty()" [id]="id" [isAccessEdit]="isAccessEdit"
                                     [deadlineDateTime]="getDeadline('sendRepresentativeToVeterinaryControlDateUpload', 12)"
                                     [serverSideErrorsProvider]="serverSideErrorsProvider" [tooltipPosition]="'bottom-right'">
    </app-edit-quarantine-stages-file>
    <app-edit-quarantine-stages-file [label]="'Срочный отчет 4-ВЕТ-Б'"
                                     [contextFormGroup]="contextFormGroup" [controlName]="'urgentReport'"
                                     (dirtyFormGroup)="markAsDirty()" [id]="id" [isAccessEdit]="isAccessEdit"
                                     [serverSideErrorsProvider]="serverSideErrorsProvider"
                                     [deadlineDateTime]="getDeadline('urgentReportDateUpload', 12)">
    </app-edit-quarantine-stages-file>
    <app-edit-quarantine-stages-file [label]="'Проведение вынужденной дезинфекции'" [serverSideErrorsProvider]="serverSideErrorsProvider"
                                     [contextFormGroup]="contextFormGroup" [controlName]="'performingForcedDisinfection'"
                                     (dirtyFormGroup)="markAsDirty()" [id]="id" [isAccessEdit]="isAccessEdit"
                                     [deadlineDateTime]="getDeadline('performingForcedDisinfectionDateUpload', 12)">
    </app-edit-quarantine-stages-file>
    <app-edit-quarantine-stages-file [label]="'Акт на уничтожение трупа'" [serverSideErrorsProvider]="serverSideErrorsProvider"
                                     [contextFormGroup]="contextFormGroup" [controlName]="'actDestructionCorpse'"
                                     (dirtyFormGroup)="markAsDirty()" [id]="id" [isAccessEdit]="isAccessEdit"
                                     [deadlineDateTime]="getDeadline('actDestructionCorpseDateUpload', 12)">
    </app-edit-quarantine-stages-file>
    <app-edit-quarantine-stages-file [label]="'Предоставление документов на право собственности, кадастрового номера земельного участка, адреса и т.д.'"
                                     [withoutDate]="true" [withoutNumber]="true" [marginBottom]="60"
                                     [contextFormGroup]="contextFormGroup" [controlName]="'docOwnership'"
                                     (dirtyFormGroup)="markAsDirty()" [id]="id" [isAccessEdit]="isAccessEdit"
                                     [deadlineDateTime]="getDeadline('docOwnershipDateUpload', 12)"
                                     [serverSideErrorsProvider]="serverSideErrorsProvider">
    </app-edit-quarantine-stages-file>
    <app-edit-quarantine-stages-file [label]="'Направление представления и его копий Заинтересованным лицам (Министр, Губернатор, РСХН и т.д.)'"
                                     [contextFormGroup]="contextFormGroup" [controlName]="'sendSubmissionToConcernedAuthority'"
                                     (dirtyFormGroup)="markAsDirty()" [id]="id" [isAccessEdit]="isAccessEdit"
                                     [marginBottom]="!isAccessEdit ? 50 : 40" [serverSideErrorsProvider]="serverSideErrorsProvider"
                                     [deadlineDateTime]="getDeadline('sendSubmissionToConcernedAuthorityDateUpload', 24)">
    </app-edit-quarantine-stages-file>
    <app-edit-quarantine-stages-file [label]="'Проведение ПЭК'" [withoutDate]="true" [withoutNumber]="true"
                                     [contextFormGroup]="contextFormGroup" [controlName]="'antiEpizooticExpertCommission'"
                                     (dirtyFormGroup)="markAsDirty()" [id]="id" [isAccessEdit]="isAccessEdit"
                                     [deadlineDateTime]="getDeadline('antiEpizooticExpertCommissionDateUpload', 24)"
                                     [serverSideErrorsProvider]="serverSideErrorsProvider">
    </app-edit-quarantine-stages-file>
    <app-edit-quarantine-stages-file [label]="'Приказ о введении карантина'"
                                     [contextFormGroup]="contextFormGroup" [controlName]="'orderOnIntroduction'"
                                     (dirtyFormGroup)="markAsDirty()" [id]="id" [isAccessEdit]="isAccessEdit"
                                     [deadlineDateTime]="getDeadline('orderOnIntroductionDateUpload', 24)"
                                     [serverSideErrorsProvider]="serverSideErrorsProvider">
    </app-edit-quarantine-stages-file>
    <app-edit-quarantine-decision-next-stage [stageId]="statusDetection" [contextFormGroup]="contextFormGroup"
                                             [serverSideErrorsProvider]="serverSideErrorsProvider"
                                             (needUpdateQuarantine)="needUpdateQuarantine.next()" [quarantineId]="id"
                                             [quarantineCurrentStatusId]="currentStatusId"></app-edit-quarantine-decision-next-stage>
  </div>
</section>
