<div class="itech-common-wrap no-pager">
  <div style="display: flex; flex-direction: column;">
    <div class="itech-content-header itech-no-margin-bottom">
      <clr-icon shape="view-list" size="36"></clr-icon>&nbsp;Журнал операций
    </div>
    <h4 *ngIf="objKindId == 1">
      Учреждение ветеринарии {{objId1|lookup:'institution'|async}}
    </h4>
    <h4 *ngIf="objKindId == 2">
      Филиалы учреждения {{objId1|lookup:'institution':true|async}}
    </h4>
    <h4 *ngIf="objKindId == 3">
      Работники учреждения {{objId1|lookup:'institution':true|async}}
    </h4>
    <h4 *ngIf="objKindId == 4">
      Роль пользователя "{{objId1|lookup:'security-role':true|async}}"
    </h4>
    <h4 *ngIf="objKindId == 5">
      Хозяйствующий субъект {{objId1|lookup:'agent':true|async}}
    </h4>
    <h4 *ngIf="objKindId == 6">
      Поднадзорный объект {{objId1|lookup:'stable':true|async}}
    </h4>
    <h4 *ngIf="objKindId == 7">
      Животное {{objId1|lookup:'animal':true|async}}
    </h4>
    <h4 *ngIf="objKindId == 8">
      Препарат {{objId1|lookup:'drug':true|async}}
    </h4>
    <h4 *ngIf="objKindId == 9 && !objId2">
      Мероприятия животного {{objId1|lookup:'animal':true|async}}
    </h4>
    <h4 *ngIf="objKindId == 9 && objId2">
      Мероприятие {{objId2|lookup:'event':true|async}}
    </h4>
    <h4 *ngIf="objKindId == 10">
      Продукция / документ {{objId1|lookup:'product':true|async}}
    </h4>
    <h4 *ngIf="objKindId == 11">
      Экспертиза продукции {{objId1|lookup:'product':true|async}}:{{objId2}}
    </h4>
    <h4 *ngIf="objKindId == 20">
      Выборка {{ objId1  }}
    </h4>
    <h4 *ngIf="objKindId == 21">
      Карантин {{ objId1  }}
    </h4>
    <h4 *ngIf="!objKindId && userId">
      Действия пользователя {{userId|lookup:'user'|async}}
    </h4>

    <div style="padding: 5px; height: 88%">
      <app-grid (gridReady)="agGridReady($event)" [uniqueGridName]="'Journal'" (cellClicked)="cellClicked($event)"
                [exportFileName]="'Журнал'" [getRowStyle]="getRowStyle" [masterDetail]="true" [detailRowHeight]="'150'"
                [rowData]="journal" [columnDefs]="gridColumnDefs" [detailCellRenderer]="detailCellRenderer"
                [frameworkComponents]="frameworkComponents"></app-grid>
    </div>

    <div class="itech-control-desc itech-desc-error" *ngIf="userId">
      <clr-icon shape="exclamation-circle"></clr-icon>
      Показаны операции за последний месяц
    </div>
  </div>

  <div class="itech-form-actions">
    <div>
      <button class="btn btn-link btn-primary" (click)="refreshResults()">
        <clr-icon shape="refresh" class="has-badge"></clr-icon>&nbsp;Обновить список
      </button>
    </div>
  </div>
</div>
