<div class="itech-common-wrap no-pager">
  <div>
    <div class="itech-search-table-with-filter">
      <div style="display: flex; flex-direction: row; bottom: 250px">
        <clr-icon shape="search" size="72"></clr-icon>
        <div class="compact form itech-highlighted-sections" [formGroup]="model.filterFormGroup"
             *ngIf="model && model.filterFormGroup">
          <section class="form-block itech-block-medium">
            <h3>Просмотр журнала интеграции Ветис.API</h3>
            <div class="form-group">
              <label for="conversationTypeId">Вид операции</label>
              <app-text-lookup [validationTooltipRight]="true" class="itech-control-xlarge"
                               [contextControlId]="'conversationTypeId'" [sorted]="true" [required]="false"
                               [contextControlName]="'conversationTypeId'"
                               [lookupName]="'mercury-conversation-types'" [contextFormGroup]="model.filterFormGroup">
              </app-text-lookup>
            </div>
            <div class="form-group">
              <label [for]="getDateType === 1 ? 'dateFrom' : 'dateCreatedFrom'">
                <button class="btn btn-icon-link" style="margin:0;padding:0;max-height:20px;min-width:20px;border:none"
                        (click)="changeToggle()">
                  <clr-icon shape="switch" style="display:block" class="has-badge"></clr-icon>
                </button>
                {{getDateType === 1 ? 'Дата операции c/по' : 'Дата создания c/по'}}
              </label>
              <ng-container *ngIf="getDateType === 1; else dateType2">
                <label for="dateFrom" aria-haspopup="true" role="tooltip"
                       class="tooltip tooltip-validation tooltip-md"
                       [class.invalid]="isInvalid(model.filterFormGroup, 'dateFrom')">
                  <input type="date" id="dateFrom" formControlName="dateFrom" (keydown)="filterEnterPressed($event)">
                  <app-validation-tooltip [input]="model.filterFormGroup.controls['dateFrom']"></app-validation-tooltip>
                  &nbsp;&nbsp;
                </label>
                <label for="dateTo" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                       [class.invalid]="isInvalid(model.filterFormGroup, 'dateTo')">
                  <input type="date" id="dateTo" formControlName="dateTo" (keydown)="filterEnterPressed($event)">
                  <app-validation-tooltip [input]="model.filterFormGroup.controls['dateTo']"></app-validation-tooltip>
                  &nbsp;&nbsp;
                </label>
              </ng-container>
              <ng-template #dateType2>
                <label for="dateCreatedFrom" aria-haspopup="true" role="tooltip"
                       class="tooltip tooltip-validation tooltip-md"
                       [class.invalid]="isInvalid(model.filterFormGroup, 'dateCreatedFrom')">
                  <input type="date" id="dateCreatedFrom" formControlName="dateCreatedFrom" (keydown)="filterEnterPressed($event)">
                  <app-validation-tooltip [input]="model.filterFormGroup.controls['dateCreatedFrom']"></app-validation-tooltip>
                  &nbsp;&nbsp;
                </label>
                <label for="dateCreatedTo" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                       [class.invalid]="isInvalid(model.filterFormGroup, 'dateCreatedTo')">
                  <input type="date" id="dateCreatedTo" formControlName="dateCreatedTo" (keydown)="filterEnterPressed($event)">
                  <app-validation-tooltip [input]="model.filterFormGroup.controls['dateCreatedTo']"></app-validation-tooltip>
                </label>
              </ng-template>
              &nbsp;&nbsp;
              <a class="itech-link in-table-link" [routerLink]="'/view-integration-log/'">Просмотр сквозного на дату
                журнала</a>
            </div>

            <div class="form-group">
              <label>Статусы синхронизации</label>
              <app-checkbox-select class="itech-control-xxlarge" [compact]="true"
                                   [contextControlName]="'mercuryExchangeStatuses'" [lookupName]="'mercury-agent-exchange-status'"
                                   [contextFormGroup]="model.filterFormGroup"></app-checkbox-select>
            </div>

            <div class="form-group" style="margin-top: 5px">
              <label>Результаты операции</label>
              <app-checkbox-select class="itech-control-xxlarge" [compact]="true"
                                   [contextControlName]="'mercuryExchangeLastResults'" [lookupName]="'mercury-exchange-result'"
                                   [contextFormGroup]="model.filterFormGroup"></app-checkbox-select>
            </div>
          </section>
        </div>
      </div>

      <div style="top: 250px">
        <app-grid [pagination]="false" [columnDefs]="gridColumnDefs" (gridReady)="agGridReady($event)"
                  (rowDoubleClicked)="gridDblClick($event)"
                  [uniqueGridName]="'ViewIntegrationLog2'" [rowData]="model.searchResults"></app-grid>
      </div>
    </div>
  </div>

  <div class="itech-form-actions">
    <button class="btn btn-primary" (click)="refreshResults()">
      <clr-icon shape="search" class="has-badge"></clr-icon>&nbsp;Обновить
    </button>
  </div>
</div>
