<clr-tabs *ngIf="model">
  <clr-tab *ngIf="!model.pages.length">
    <button clrTabLink>
      Добавьте новый раздел
    </button>
    <clr-tab-content>
      <div class="edit-page-tab">
        <div class="itech-common-wrap">
          <div>
            <div *ngIf="model.showReportStructure">
              <a class="itech-link in-table-link" (click)="addReportBranch()">
                <clr-icon shape="plus"></clr-icon>
                Добавить новый раздел</a>
            </div>
          </div>
        </div>
      </div>
    </clr-tab-content>
  </clr-tab>
  <clr-tab *ngFor="let page of model.pages">
    <button clrTabLink>
      <clr-icon *ngIf="model.reportMeta.reportState === 10" shape="lock"></clr-icon>
      <clr-icon *ngIf="model.reportMeta.reportState === 200" shape="check" style="color: green"></clr-icon>
      {{page.displayTitle}}
    </button>
    <ng-template [(clrIfActive)]="page.tabActive">
      <clr-tab-content>
        <div class="edit-page-tab">
          <div class="itech-common-wrap">
            <div>
              <div *ngIf="model.showReportStructure" style="border-bottom: 1px solid silver; margin-bottom: 5px">
                <a class="itech-link in-table-link edit-layout" (click)="addIndicatorType()">
                  <clr-icon shape="plus"></clr-icon>
                  Добавить новый вид показателя</a>
                &nbsp;
                <a class="itech-link in-table-link edit-layout" (click)="addReportBranch()">
                  <clr-icon shape="plus"></clr-icon>
                  Добавить новый раздел</a>
              </div>
              <ng-container *ngFor="let branch of page.branches">
                <div *ngIf="model.showReportStructure">
                  <a class="itech-link in-table-link edit-layout" (click)="editReportBranch(branch)">
                    <clr-icon shape="edit"></clr-icon>
                    Редактировать описание раздела</a>
                </div>
                <app-branch-tab-col-edit *ngIf="branch.meta.editorKind === 'Табл_по_графам'"
                                         [branch]="branch" [model]="model">
                </app-branch-tab-col-edit>
                <app-branch-tab-row-edit *ngIf="branch.meta.editorKind === 'Табл_по_строкам'"
                                         [branch]="branch" [model]="model">
                </app-branch-tab-row-edit>
                <app-branch-construction-edit *ngIf="branch.meta.editorKind === 'Табл_стройки'"
                                              [branch]="branch" [model]="model">
                </app-branch-construction-edit>
                <app-branch-sell-deal-edit *ngIf="branch.meta.editorKind === 'Табл_сделки_продажа'"
                                           [branch]="branch" [model]="model">
                </app-branch-sell-deal-edit>
                <app-branch-buy-deal-edit *ngIf="branch.meta.editorKind === 'Табл_сделки_покупка'"
                                          [branch]="branch" [model]="model">
                </app-branch-buy-deal-edit>
                <app-branch-fed-header *ngIf="branch.meta.editorKind === 'Шапка_фед'"
                                       [branch]="branch" [model]="model">
                </app-branch-fed-header>
                <br>
              </ng-container>
            </div>

            <div class="itech-form-actions">
              <div>
                <button class="btn btn-secondary" (click)="refreshFormData()">
                  <clr-icon shape="refresh"></clr-icon>&nbsp;Обновить
                </button>
                <button type="button" class="btn btn-success btn-outline" (click)="printReport()" [disabled]="printing"
                        [clrLoading]="printing">
                  <clr-icon shape="printer"></clr-icon>&nbsp;Печать
                </button>
                <button
                  *ngIf="!model.reportMeta.regionId && (model.reportMeta.reportState === 1 || !model.reportMeta.reportState)"
                  type="button" class="btn btn-success btn-outline"
                  (click)="approveReport()">
                  <clr-icon shape="check"></clr-icon>&nbsp;Утвердить отчетную форму
                </button>
                <clr-tooltip
                  *ngIf="model.reportMeta.regionId && (model.reportMeta.reportState === 1 || !model.reportMeta.reportState)">
                  <button type="button" class="btn btn-success btn-outline" clrTooltipTrigger (click)="lockReport()"
                          [disabled]="jobRunnerUiService.operationIsRunning()">
                    <clr-icon shape="lock"></clr-icon>&nbsp;Отправить
                  </button>
                  <clr-tooltip-content clrPosition="top-right" clrSize="lg" *clrIfOpen>
                    Отправить отчетную форму
                  </clr-tooltip-content>
                </clr-tooltip>
                <button *ngIf="model.reportMeta.reportState === 10" type="button" class="btn btn-success btn-outline"
                        (click)="unlockReport()" [disabled]="jobRunnerUiService.operationIsRunning()">
                  <clr-icon shape="unlock"></clr-icon>&nbsp;Снять отметку об отправке
                </button>
                <button *ngIf="model.reportMeta.reportState === 200" type="button" class="btn btn-success btn-outline"
                        (click)="unlockReport()" [disabled]="jobRunnerUiService.operationIsRunning()">
                  <clr-icon shape="unlock"></clr-icon>&nbsp;Снять отметку об утверждении
                </button>
                <clr-tooltip
                  *ngIf="!model.showReportStructure && (securityService.isAccess('pst-report-structure') | async)">
                  <button type="button" clrTooltipTrigger class="btn btn-success btn-outline"
                          (click)="this.refreshFormData(true, true)">
                    <clr-icon shape="cog"></clr-icon>&nbsp;Структура
                  </button>
                  <clr-tooltip-content clrPosition="top-right" clrSize="lg" *clrIfOpen>
                    Редактировать структуру отчетной формы
                  </clr-tooltip-content>
                </clr-tooltip>
                <clr-tooltip *ngIf="model && !model.showHiddenCells">
                  <button type="button" clrTooltipTrigger class="btn btn-success btn-outline" (click)="this.refreshFormData(true)">
                    <clr-icon shape="eye"></clr-icon>
                  </button>
                  <clr-tooltip-content clrPosition="top-right" clrSize="lg" *clrIfOpen>
                    Показать защищенные ячейки
                  </clr-tooltip-content>
                </clr-tooltip>
                <clr-tooltip>
                  <button type="button" clrTooltipTrigger class="btn btn-success btn-outline" (click)="navigateNextReport(-1)">
                    <clr-icon shape="rewind"></clr-icon>
                  </button>
                  <clr-tooltip-content clrPosition="top-right" clrSize="lg" *clrIfOpen>
                    Перейти к предыдущему отчетному периоду
                  </clr-tooltip-content>
                </clr-tooltip>
                <clr-tooltip>
                  <button type="button" clrTooltipTrigger class="btn btn-success btn-outline" (click)="navigateNextReport(1)">
                    <clr-icon shape="fast-forward"></clr-icon>
                  </button>
                  <clr-tooltip-content clrPosition="top-right" clrSize="lg" *clrIfOpen>
                    Перейти к следующему отчетному периоду
                  </clr-tooltip-content>
                </clr-tooltip>
              </div>
            </div>
          </div>
        </div>
      </clr-tab-content>
    </ng-template>
  </clr-tab>
</clr-tabs>
