import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AppVersionHistory {
  public versions = [

    {
      version: '0.0.16',
      releaseDate: '08.11.2023',
      changes: [],
      changesPart: [
        {
          partCaption: 'Общее',
          changes: [
            'Реализована настройка для автозаполнения полей по щелчку на тэге  при создании ВСД',
            'Добавлено в окно Добавления/Редактирования тэга в поле Содержимое подсчет количества' +
            ' оставшихся для ввода символов с указанием максимальной длины текста',
            'Доработан план вакцинации возможностью указать количество планируемых исследований' +
            ' с разделением на 3 вида источника финансирования',
            'Реализованы индивидуальные настройки таблиц для каждого пользователя',
            'Разработан шлюз, позволяющий внешним (иным) информационным системам обращаться' +
            ' к АИС «Велес» за получением или с внесением данных',
          ]
        },
        {
          partCaption: 'ВСД',
          changes: [
            'Доработано использование дат в ВСД согласно справочнику условий форматов дат по видам продукции',
            'Доработана синхронизация срока годности формата "Не ограничен"',
            'Реализована возможность направления акта несоответствия при гашении ВСД',
            'Реализована проверка актуальности номенклатуры перед созданием ВСД',
            'Реализован шаблоны вырабатываемой продукции для поднадзорных объектов при переработке живых животных',
            'Доработан функционал создания производственного ВСД на живое животное из карточки животного,' +
            ' а именно - добавлена кнопка «Создать ВСД на животное»',
            'Доработана таблица с результатами поиска ВСД на вкладке «Продукция»',
            'Доработано отображение пользователя, оформившего ВСД в Меркурии',
            'Доработан шаблон для случаев, когда продукция отправляется из разных площадок в одно место получения',
            'Доработан шаблон для транспортного ВСД – сделано необязательным заполнение поля Документ',
            'Доработатна таблица «Сырьё» на форме создания ВСД производственной транзакции' +
            ' в части выделения красным цветом продукции с истекшим сроком годности',
            'Добавилена информация о маркировке во всплывающую подсказку в таблице Вырабатываемая продукция',
          ]
        },
        {
          partCaption: 'Животные',
          changes: [
            'Доработана карточка группового животного в части добавления поля с указанием пола для подвида животного',
          ]
        },
        {
          partCaption: 'Карантины',
          changes: [
            'Реализована возможность проведения исследований и обработок «одним кликом» для всех животных в какой-либо зоне карантина',
          ]
        },
        {
          partCaption: 'Мероприятия',
          changes: [
            'Реализованы печатные формы актов по мероприятиям',
            'Реализована возможность добавлять животных массово через таблицу,' +
            ' аналогичную таблице Вырабатываемая продукция в производственном ВСД',
            'Доработана карточка мероприятия вида «Убой»',
          ]
        },
        {
          partCaption: 'Препараты',
          changes: [
            'Реализован механизм ограничения возможности внесения первичной информации о движении препаратов',
            'Добавлена в справочник препаратов вкладка Животные',
            'Добавлкна возможность добавления препаратов пользователями  с соответствующей ролью',
            'Добавлен раздел «Препараты» в существующий модуль выборок',
            'Доработан поиск препаратов: добавлены доп. поля',
            'Расширен справочник препаратов настройкой, в которой возможно указать против каких болезней действует препарат',
            'Реализован в табличной форме сбор данных о планируемой потребности учреждений' +
            ' в препаратах на календарный год, поквартально согласно заявке',
            'Доработана возможность передачи препаратов в другие учреждения в ед.изм.препарата',
          ]
        },
        {
          partCaption: 'Отчёты',
          changes: [
            'Актуализирована отчетная форма 1-вет  Сведения о заразных болезнях животных',
            'Реализована отчетная форма  Ежемесячные сведения о движении биопрепаратов',
            'Реализована отчетная форма Информация о расходовании Продукции на противоэпизотические мероприятия',
            'Реализована отчетная форма 5-вет',
            'Реализована отчетная форма 4-вет-А',
            'Доработана отчетная форма 1-вет-В',
            'Добавлена возможность печати Журнала предубойного осмотра  в pdf',
          ]
        },
        {
          partCaption: 'Экспертизы',
          changes: [
            'Реализовано массовое создание ВСЭ на продукцию из одной транзакции',
          ]
        }
      ],
    },
    {
      version: '0.0.12',
      releaseDate: '30.06.2022',
      changes: [],
      changesPart: [
        {
          partCaption: 'Общее',
          changes: [
            'Доработано корректное сохранение адресов, пришедших через шлюз',
            'Увеличено количество попыток отправки запросов в Меркурий до трех попыток,' +
            ' при их исчерпании формируется ошибка "Меркурий недоступен. Повторите операцию позднее"',
            'Все асинхронные операции по совершению юридически значимых действий (создание, гашение, аннулирование ВСД)' +
            ' выполняются от пользователя, запустившего (перезапустившего) такую операцию',
            'Существенно уменьшено время ожидания выполнения запроса шлюзом для получения результата обработки заявки',
            'При отсутствии данных учетной записи Меркурия у пользователя будет отображено сообщение об ошибке при запуске операции',
            'При создании ВСД текст ошибки "У животного отсутствует подвид животных, соответствующий указанному подвиду продукции"' +
            ' заменен на "На текущую дату у животного отсутствует подвид, соответствующий указанному подвиду продукции.' +
            ' Исправьте дату мероприятия Естественное изменение поголовья на более раннюю"',
            'Доработана фильтрация в таблицах: при выборе всех записей скрытые записи не участвуют в выборе',
            'Доработан режим редактирования таблиц в ВСД: режим редактирования включается одиночным кликом, а не двойным',
            'Добавлена полоса прокрутки в левое меню Системы',
          ]
        },
        {
          partCaption: 'ВСД',
          changes: [
            'Реализовано создание животных после загрузки из Меркурия производственного ВСД',
            'Реализовано создание животных после загрузки из Меркурия погашенного транспортного ВСД',
            'Реализовано создание животных после изменения статуса ВСД при обновлении из Меркурия',
            'Доработано передвижение животных (мероприятия выбытие-прибытие) при обработках погашенных транспортных ВСД',
            'Доработано создание животного с мероприятием Прибытие при гашении загруженного из Меркурия транспортного ВСД',
            'Доработаны алгоритмы поиска актуальной записи журнала продукции в Меркурии для животного',
            'Доработана обработка с дальнейшем возможностью гашения/использования в качестве сырья/перемещения' +
            ' транспортных ВСД с продукцией без номенклатуры',
            'Реализовано помесячное отображение записей списка ВСД в карточке ПО в целях оптимизации',
            'При обновлении/актуализации списка ВСД для ПО также загружаются журналы продукции всех получателей погашенных' +
            ' транспортных ВСД',
            'Доработано разделение перевозимой группы животных на принятые и возвращаемые при частичном гашении транспортного ВСД',
            'Реализовано объединение идентичных упаковок',
            'Реализовано автоматическое добавление связок ХС-ПО при обработке полученных из Меркурия ВСД' +
            ' (производитель из производственных ВСД, отправитель из транспортных ВСД, получатель из погашенных транспортных ВСД)',
            'Доработано оформление производственной транзакции, где сырьём является животное',
            'Кнопка "Оформить транспортный ВСД" переименована в "Оформить новый ВСД" и она теперь формирует универсальный проект ВСД' +
            ' для производственной или транспортной транзакции',
            'Поле "Номер производственной партии" сделан обязательным для заполнения в производственных ВСД',
            'Добавлена возможность выбора нескольких вариантов статусов на форме поиска в условиях фильтрации',
            'Добавлено автозаполнение условий фильтрации ХС и ПО при поиске документов',
            'В проекте ВСД количество исходной продукции автоматически заполняется её текущим остатком',
            'Уменьшен период для запроса списка ВСД со 180 дней до 14 дней для оптимизации',
            'Реализовано автоматическое заполнение номенклатуры при создании проекта производственного ВСД для животного',
            'Исправлена ошибка, при которой невозможно было создавать производственный ВСД для животного, если имелся аннулированный',
            'Добавлена автозамена запятой на точку в ВСД в поле "Количество"',
            'Исправлена ошибка, при которой у исходной продукции искалась созданная при регистрации запись журнала,' +
            ' а не последняя актуальная',
            'Доработано сбрасывание текста ошибки и этапов синхронизации при обновлении ВСД из Меркурия и' +
            ' принудительной установки актуального статуса "Погашен"',
            'Реализовано добавление нескольких позиций продукции в транспортном ВСД',
            'Изменена процедура открытия транзакции со списанием сырья и регистрации вырабатываемой продукции.' +
            ' Теперь в первую очередь списывается сырьё, а затем регистрируется вырабатываемая продукция',
            'Исправлена ошибка, не позволяющая распечатать ВСД',
            'Изменён алгоритм поиска перевозимой продукции. Ранее поиск осуществлялся автоматически, теперь ВСД указывается пользователем',
            'При смене типа транзакции при создании транспортного ВСД автоматически заполняются получатель и место получения',
            'Доработана валидация отправителя, места отправления, получателя, места получения в зависимости от типа транзакции',
            'Исправлено отображение в ВСД номенклатуры без производителя',
            'Исправлены ошибки при работе с номенклатурой без производителя во время синхронизации',
            'В таблице создания ВСД изменено действие кнопки "+", которая теперь добавляет пустую строку,' +
            ' и добавлена кнопка копирования строки',
            'При создании проекта транспортного ВСД сделано автоматическое заполнение упаковки текущим остатком',
            'При создании ВСД на групповое животное дата производства ставится приоритетно из даты рождения,' +
            ' а не даты изменения поголовья',
            'При некорректно указанной номенклатуре для подвида одиночного животного отображается корректное сообщение об ошибке',
            'Реализована отправка в Меркурий серии и номера бланка при оформлении ВСД',
            'Реализовано автоматическое заполнение «Производителя» из номенклатуры при создании производственного ВСД ',
            'Теперь при возникшей ошибке во время открытия производственной транзакции' +
            ' останавливается синхронизация всех входящих в неё ВСД',
            'Реализовано создание проекта производственного ВСД по кнопке  "Добавить продукцию"',
            'Доработана загрузка справочника номенклатуры для случаев, когда отсутствует место производства в ВСД',
            'Убраны следующие поля при создании транспортного ВСД: "Страна происхождения", "Ограничения по использованию",' +
            ' "Номер производственной партии". Эти поля заполняются автоматически из перевозимого ВСД',
            'При создании транспортного ВСД следующие поля перенесены в таблицу для каждого перевозимого ВСД:' +
            ' "Уровень/кол-во/тип/маркировки упаковки", "Серия/номер защ. бланка", "Вет.сан. экспертиза", "Благополучие местности",' +
            ' "Нахождение в таможенной зоне", "Карантин", "Некачественная продукция", "Цель производства/сбыта", "Комментарии"',
            'Добавлена проверка на длину заполнения и допустимые символы в поле "Номер производственной партии"',
            'Теперь, в случае некорректно заполненных данных в таблице при создании ВСД, такая строка таблицы будет раскрываться',
            'Добавлена кнопка "Оформить транспортный ВСД" в формы результатов поиска ВСД,' +
            ' позволяющая создать проект ВСД с отмеченными ВСД в качестве перевозимой продукции"',
            'Добавлена кнопка "Погасить документы" в формы результатов поиска ВСД,' +
            ' позволяющая направить на погашение в полном объеме сразу несколько ВСД',
            'В условия фильтрации для поиска ВСД исправлен поиск по полю "UUID"',
            'В условия фильтрации для поиска ВСД добавлена возможность поиска по наименованию продукции',
            'Реализовано автоматическое добавление животных в вырабатываемую продукцию из сырья',
          ]
        },
        {
          partCaption: 'Животные',
          changes: [
            'Реализована возможность ввода интервала дат рождения для групповых животных',
            'Поля Подвид и Кол-во при создании групповых животных сделаны обязательными для заполнения',
            'При удалении животного, фигурирующего в ВСД, отображается соответствующая ошибка',
            'Оптимизирован поиск списка ВСД',
            'Увеличено количество отображаемых записей в результатах поиска животных до 100',
            'Добавлено отображение даты рождения в карточку группового животного',
            'Исправлено отображение записей в фильтрации по столбцу в таблицах результатов поиска',
            'Доработан поиск по бирке животного',
          ]
        },
        {
          partCaption: 'Мероприятия',
          changes: [
            'Реализована синхронизация лабораторных исследований при регистрации ВСД',
            'В карточке мероприятия теперь можно указать несколько способов диагностики',
            'Реализована отправка мероприятий в Меркурий при их создании (при наличии ВСД у животного)',
            'Реализована загрузка из Меркурия исследований по живым животным',
            'Добавлен новый метод исследования - РДП',
            'Поле "Результат" для способов диагностики сделано не обязательным для заполнения',
            'Поле Лаборатория сделано обязательным для заполнения',
            'Добавлен вариант "Обработано" в поле "Результат" в карточке мероприятия одиночного животного',
            'При отправке мероприятий в Меркурий приоритетным полем является показатель, затем заболевание',
          ]
        },
        {
          partCaption: 'Предприятия',
          changes: [
            'При обновлении информации о ПО из Меркурия теперь всегда обновляется адрес',
            'Доработаны алгоритмы обработки адресов из Меркурия',
            'Добавлены поля "Владелец ТМ" и "Производитель" в номенклатуре',
            'При попытке синхронизации связок ХС-ПО у несинхронизированного ПО отображается корректная ошибка',
            'Удаленные записи номенклатур продукции по умолчанию скрыты',
            'Теперь для обновления номенклатуры у ПО не требуется его владелец',
            'При загрузке предприятия с Меркурия без наименования, в системе устанавливается наименование по умолчанию "без наименования"',
            'Доработано получение осуществляющих деятельность ХС на предприятии',
            'Сделано описание ошибки неправильного логина или пароля пользователя на русском языке',
            'Исправлена ошибка при обновлении ПО' +
            ' "Для иного адреса обязательно должен быть указан или кадастровый номер, или ширина и долгота"',
          ]
        },
        {
          partCaption: 'Хозяйствующие субъекты',
          changes: [
            'Доработана загрузка ПО при обновлении из Меркурия',
            'Оптимизирован поиск предприятий',
            'Добавлена возможность скрыть ненужные предприятия в списке поднадзорных объектов',
          ]
        },
        {
          partCaption: 'Отчёты',
          changes: [
            'В отчете "Использование ИС Ветеринария" добавлена информация о проведенных предубойных осмотрах,' +
            ' ВСЭ, созданных и погашенных ВСД пользователями',
          ]
        },
        {
          partCaption: 'Экспертизы',
          changes: [
            'Добавлена проверка длины поля Наименования показателя',
          ]
        }
      ],
    },
    {
      version: '0.0.11',
      releaseDate: '31.03.2022',
      changes: [],
      changesPart: [
        {
          partCaption: 'Общее',
          changes: [
            'Все процессы синхронизации разбиты на этапы, которые отображаются в окне выполнения операции синхронизации',
            'Теперь при возникновения ошибки соединения с Меркурием в процессе операций синхронизации' +
            ' система будет пытаться 3 раза выполнить запрос,' +
            ' после чего, в случае неудачи, появится ошибка \'Меркурий недоступен. Повторите операцию позднее\'',
            'Доработаны поиски по текстовым полям, теперь в результаты будут попадать сначала найденные записи по точному совпадению, ' +
            'затем записи с допуском на опечатки',
            'Доработан интерфейс окна внесения логина и пароля от личного кабинета Меркурия',
            'Теперь в окне статуса синхронизации может быть более одной записи',
            'Оптимизирована скорость работы журнала',
          ]
        },
        {
          partCaption: 'ВСД',
          changes: [
            'Реализована загрузка погашенных сертификатов из Меркурия',
            'Доработана загрузка ВСД с несколькими одинаковыми производителями из Меркурия',
            'Реализована операция загрузки ВСД с обновлением существующих',
            'Теперь ВСЭ возможно создавать только у производственных или погашенных транспортных ВСД',
            'Исправлена ошибка, когда было возможно указать дату в формате, отличном от ГГГГ-ММ-ДД',
            'Убрана валидация даты производства для документа на живое животное, так как данная дата рассчитывается автоматически системой',
            'Исправлена ошибка, при которой терялись описания упаковок и маркировок при формировании проекта документа через кнопку ' +
              '\'Оформить новый транспортный ВСД\'',
            'Реализована возможность создавать ВСД, где в качестве источника указывать продукцию',
            'Исправлена ошибка \'Исходная продукция не синхронизирована - не указан Guid записи журнала\'',
            'Доработана загрузка всех связанных документов для ВСД',
            'При получении списка ВСД из Меркурия теперь дополнительно загружаются все записи номенклатуры, фигурирующие во всех таких ВСД',
            'Исправлено некорректное отображение интервала дат после загрузки ВСД из Меркурия',
            'Исправлена ошибка \' Запись складского журнала продукции с указанным идентификатором не найдена в реестре РСХН, ' +
              'либо идентификатор не соответствует установленному формату.\'',
            'Исправлена ошибка \'Список производителей в сведениях о принимаемой партии должен совпадать с указанным в ВСД\', ' +
              'возникающая при гашении ВСД',
            'Доработана загрузка из Меркурия ВСД с указанным полем \'фирма-перевозчик\'',
            'Доработана загрузка, обработка и гашение ВСД с отсутствующей идентифицирующей информацией о транспорте',
            'В таблицах вкладок \'Продукция, документы\' теперь имеется возможность отображать ВСД, соответствующие фильтру ' +
              '\'Все/Входящие/Исходящие/Погашенные/Производственные/Аннулированные/На синхронизации\'',
            'Теперь операция по получению списка ВСД для ПО будет запрашивать в первый раз все ВСД за 6 месяцев, ' +
              'а в последующие разы с момента последнего успешного выполнения данной операции',
            'Исправлена работа системы с номеклатурами, не имеющими места производства или не относящимися к производителю',
            'Исправлена ошибка, возникающая при наличии в пришедшем из Меркурия документе ' +
              'номера производственной партии длиной более 256 символов',
            'Реализована поддержка адресов вне РФ',
            'Теперь возможно указать несколько упаковок продукции с одной или более маркировками для каждой упаковки',
            'Исправлены ошибки, приводившие к незаполнению остатка продукции у документа',
            'Доработана регистрация ВСД с типом транзакции \'Смена владельца без перевозки\'',
            'Исправлена ошибка \'При оформлении транспортной партии должно быть указано перемещаемое животное\'',
            'При создании транспортного ВСД на живых животных скрыты для заполнения поля \'Время производства\' и \'Срок годности\', ' +
              'так как указанные поля заполняются системой автоматически',
            'Исправлена ошибка, в результате которой не сохранялись настройки фильтрации в таблице с продукцией ' +
              'при переходе между такими таблицами',
          ]
        },
        {
          partCaption: 'Мероприятия',
          changes: [
            'Добавлено автоматически заполняемое не редактируемое поле \'Ед.изм.\' в поле \'Препараты\' карточки мероприятия',
            'Иное профилактическое мероприятие в Меркурий теперь является обработкой, а не иммунизацией',
            'Исправлено описание полей \'Разрешено на убой\' и \'Запрещено на убой\'',
          ]
        },
        {
          partCaption: 'Предприятия',
          changes: [
            'Реализован автоматический поиск номера в реестре Меркурия при его отсутствии в ответах на запрос через шлюз',
            'Теперь не будут отображаться в списках ПО аннулированные объекты без включенного режима \'Показать историю\'',
            'Добавлен запрет на обновление из Меркурия информации в аннулированных карточках ПО',
            'Добавлена кнопка \'Восстановить\', предназначенная для восстановления аннулированных карточек',
            'После выполнения операции по получению ВСД будет скачиваться лог-файл со статистикой и возникшими ошибками',
            'Добавлена операция \'Актуализировать ВСД из Меркурия\', которая всегда загружает ВСД за 6 месяцев и ' +
              'обновляет информацию в ранее сохраненных',
          ]
        },
        {
          partCaption: 'Справочники',
          changes: [
            'Добавлена кнопка копирования роли',
          ]
        },
        {
          partCaption: 'Учреждения',
          changes: [
            'Поле \'Филиал\' в карточке работника переименовано в \'Пункт, участок, лаборатория\'',
          ]
        },
        {
          partCaption: 'Отчёты',
          changes: [
            'Для формирования отчёта по использованию программы ИС "Ветеринария" теперь возможно указание периода дат',
            'В отчёте по использованию программы ИС "Ветеринария" теперь отображается информация о количестве внесённых животных и ВСД',
          ]
        }
      ],
    },
    {
      version: '0.0.10',
      releaseDate: '20.12.2021',
      changes: [],
      changesPart: [
        {
          partCaption: 'Общее',
          changes: [
            'При входе в новую версию будет появляться уведомление об изменениях',
            'Пользователь может выбрать, под каким работником авторизоваться в системе,' +
            ' если он зарегистрирован в нескольких учреждениях',
          ]
        },
        {
          partCaption: 'Справочники',
          changes: [
            'В справочник "Способ диагностики" добавлены записи "Клинический осмотр" и "Маллеинизация"',
            'В справочнике "Подвиды продукции" добавлено поле "требует убоя" с вариантами "да" и "нет"',
            'В справочнике "Подвиды продукции" отображаются показатели экспертизы с датой последнего обновления списка',
            'Справочник "Виды упаковок" приведён в соответствие со справочником Меркурия',
            'Актуализирован справочник "Цель производства/сбыта" из Меркурия',
          ]
        },
        {
          partCaption: 'Животные',
          changes: [
            'В карточке животного в поле "порода" добавлен текстовый поиск',
            'В ссылках на карточку возле имени животного отображается иконка карантина,' +
            ' если животное в настоящее время находится под карантином',
            'При создании группового животного можно сразу указать поголовье и дату мероприятия',
            'В разделе "Предубойный осмотр" строки подсвечиваются цветами в зависимости от результата осмотра',
            'При участии животного в качестве сырья в транзакции автоматически ему будет' +
            ' добавляться мероприятие "Убой" (для одиночных - дата и причина выбытия) при успешном списании в Меркурии',
            'Реализовано добавление животных на основе записей журнала продукции в Меркурии' +
            ' (кнопка "Обновить из Меркурия" во вкладке "Животные" у поднадзорного объекта)',
          ]
        },
        {
          partCaption: 'Мероприятия',
          changes: [
            'В карточке мероприятия добавлено поле "Площадь обработки, кв.м."',
            'В карточке мероприятия типа "Предубойный осмотр" можно указать диапазон температуры,' +
            ' добавлено текстовое поле "Причина запрета"',
            'Добавлен тип мероприятия "Убой"',
          ]
        },
        {
          partCaption: 'Поднадзорные объекты',
          changes: [
            'Поднадзорные объекты типов "Приют", "Рыб. хоз. бассейн", "Рыб. хоз. пруд", "Рыб-пром. водоем"' +
            ' регистрируются в Меркурии под типом "Предприятие"',
            'Во время/после синхронизации поднадзорного объекта разрешено редактирование полей,' +
            ' не участвующих в синхронизации (поля, которые запрещено изменять, имеют иконку замка)',
          ]
        },
        {
          partCaption: 'Ветеринарные документы',
          changes: [
            'Переработана форма создания производственных ВСД (реализовано массовое создание на разные номенклатуры,' +
            ' копирование выработанной продукции для другого животного, списание сырья)',
            'При создании производственных ВСД со списанием сырья такие ВСД будут объединяться в одну транзакцию',
            'Реализована синхронизация общей транзакции с Меркурием' +
            ' (открытие транзакции, добавление выработанной продукции, добавление сырья, оформление транзакции)',
            'В карточке документа во вкладке "Выработанная продукция" отображается вся продукция,' +
            ' которая была выработана при участии такого документа в качестве сырья',
            'В производственном ВСД поле "Цель производства" не обязательно к заполнению',
            'При создании производственного ВСД обязательно должна быть указана номенклатура',
            'Автоматически запрашивается номенклатура производителя из Меркурия при создании производственного ВСД' +
            ' (если указанная в ВСД не существует)',
            'В транспортных ВСД поле "Время производства" не обязательно к заполение для вида продукции "Живые животные"',
            'При смене типов дат в полях выбора ранее заполеннные поля сохраняются',
            'Возможно создание ВСД с прикреплением животных, находящихся в настоящее время под карантином',
            'Убраны виды транспорта "Перегон" и "Речной", т.к. они не поддерживаются используемым шлюзом Ветис.API',
            'Доработана отправка всего маршрута следования продукции при регистрации транспортного ВСД',
            'При гашении транспортного ВСД теперь пользователю предлагается на выбор' +
            ' "В полном объеме", "Принимается частично, частично возвращается", "Не принимается в полном объеме"',
            'При гашении транспортного ВСД теперь можно указать количество принимаемой/возвращаемой продукции',
            'В таблицах с продукциями столбец "Тип транзакции" заменён на иконку',
            'В таблицах с продукциями столбец "Статус" убран',
            'В таблицах с продукциями добавлен столбец "Остаток", отображающий текущий остаток количества продукции в документе',
            'В таблицах с продукциями добавлен столбец с иконкой, обозначающей, что документ входит в общую транзакции' +
            ' (при клике на иконку отображается подробная информация о транзакции)',
          ]
        },
        {
          partCaption: 'Ветсанэкспертизы',
          changes: [
            'Реструктуризирована форма экспертизы',
            'Реализована синхронизация экспертиз с Меркурием' +
            ' (регистрация в Меркурии, добавление/редактирование показателей, принятие решения)',
            'Добавлены печатные формы "Заключение", "Акт", "Талон"',
          ]
        },
        {
          partCaption: 'Исправление ошибок',
          changes: [
            'Исправлена ошибка при отправке на регистрацию производственного ВСД с датой производства в виде периода',
            'Исправлена ошибка при редактировании записей справочника "Исследуемый материал"',
            'Исправлена ошибка при создании записи в справочнике "Болезни животных"',
          ]
        },
      ]
    },
    {
      version: '0.0.9',
      releaseDate: '17.08.2021',
      changes: [
        'Добавлен раздел \'Предубойный осмотр\'',
        'Добавлено мероприятие \'Предубойный осмотр\'',
        'Добавлен запрет на создание мероприятия \'Естественное изм. поголовья\'' +
        ' с целью увеличения количества животных в группе более 1 раза',
        'Добавлена настройка \'Только индивидуальный учет\' в справочник Подвиды животных. ' +
        'При отмеченной настройке подвид животных больше не может учитываться группой',
        'Добавлена настройка \'Без срока годности\' в справочник подвидов продукции',
        'Реализованы печатные формы ВСД',
        'В карточку ПО добавлена вкладка \'Номенклатура продукции\'',
        'Добавлена печатная форма \'План мероприятий по ликвидации болезней\' в рамках карантина',
        'Реализовано создание производственного ВСД, доработан транспортный ВСД',
        'Запрещено создание транспортного ВСД без имеющегося производственного ВСД',
        'Доработано перемещение животных после гашения входящего транспортного ВСД через мероприятия выбытие-прибытие',
        'Запрещено удалять мероприятия, созданные на основании документов',
        'В менеджер выборок добавлен раздел \'Хозяйствующий субъект\'',
        'Реализовано создание производственного ВСД',
        'В таблицы с животными и ПО добавлена возможность группировок из меню по клику правой кнопки мыши',
        'Исправлены ошибки при создании ХС типа КФХ',
        'Запрещено изменять поля ХС, участвующие в синхронизации с Меркурием, после её успешного завершения',
        '22.09.2021: у карантинов добавлен параметр \'Отсутствует этап подозрение\'',
        '22.09.2021: адреса зон карантинов теперь могут быть одним из типов:' +
        ' \'в регионе\', \'вне региона\', \'иной\', \'мун.образование\', \'поднадзорный объект\'',
        '22.09.2021: изменен вид раздела \'Карантины\'',
        '22.09.2021: теперь файлы форматов \'odt\', \'docx\', \'pdf\', \'jpg\', \'png\', в том числе отчеты,' +
        ' будут открываться в окне предварительного просмотра',
      ],
    },
    {
      version: '0.0.8',
      releaseDate: '18.06.2021',
      changes: [
        'Добавлен раздел \'Карантины\'',
        'В карточке животного добавлено поле \'Вес\'',
        'Добавлен расчет возраста животного на текущий момент или дату выбытия',
        'Поднадзорным органам и животным добавлен бэйдж попадания под карантин (при наличии)',
        'В результатах поисков животных и поднадзорных объектов добавлено отображение наличия просроченных мероприятий',
        'Добавлена печатные формы \'Справка благополучия\' и \'Расширенная справка благополучия\' для поднадзорных объектов',
        'В карточке мероприятия добавлены поля \'Дата (план)\', \'В рамках карантина\'',
        'Реализована возможность планирования мероприятий для всех подвидов животных',
        'Реализовано автоматическое планирования мероприятий для животных',
        'Реализована возможность добавления в карточку мероприятия документов',
        'Реализован механизм применения, списания и ведения отчётности по ассоциированным вакцинам',
        'Добавлены отчеты \'МВ_1_ВЕТ_А Сведения о противоэпизоотических мероприятиях\', ' +
        '\'МВ_1_ВЕТ_В_КК Отчет о движении биопрепаратов\', ' +
        '\'МВ_1_ВЕТ Сведения о заразных болезнях животных\', ' +
        '\'МВ_1_ВЕТ_Г Сведения о мероприятиях против гиподерматоза КРС и северных оленей\', ' +
        '\'МВ_2 _ВЕТ Сведения о незаразных болезнях животных\', ' +
        '\'МВ_3_ВЕТ Сведения о болезнях рыб и других гидробионтов\', ' +
        '\'МВ_4_ВЕТ_Б Срочный отчет о выявлении карантинных и особо опасных заболеваний\', ' +
        '\'МВ_4_ВЕТ_КК Сведения о работе ветеринарных лабораторий (10 разделов)\', ' +
        '\'МВ_5_ВЕТ Сведения о ветеринарно-санитарной экспертизе сырья и продуктов\', ' +
        '\'МВ_бактериол_исслед Бактериологические исследования\', ' +
        '\'МВ_вирусол_исслед.Вирусологические исследования\', ' +
        '\'МВ_исслед_лошадей Результаты гельминтокопрологических исследований лошадей\', ' +
        '\'МВ_исслед_МРС Результаты гельминтокопрологических исследований МРС\', ' +
        '\'МВ_исслед_свиней Результаты гельминтокопрологических исследований свиней\', ' +
        '\'МВ_наличие лептоспир.Результаты исследований с/х животных на лептоспироз, микроскопия мочи\', ' +
        '\'МВ_Серология_4 Исследование крови абортировавших животных\', ' +
        '\'Прилож_МВ_4_ВЕТ Приложение к форме 4-ВЕТ (11 разделов)\'',
        'На карту стартовой страницы добавлены фильтры \'Действующие карантины\', ' +
        '\'Регистрируемые заболевания\', \'Регистрируемые заболевания (за 3 мес.)\', ' +
        '\'Регистрируемые заболевания (за 6 мес.)\', \'Регистрируемые заболевания (за 12 мес.)\''
      ]
    },
    {
      version: '0.0.7',
      releaseDate: '02.06.2021',
      changes: [
        'Добавлен справочник \'Виды учреждений\'',
        'Добавлен справочник \'Номенклатура бирок животных\'',
        'Добавлен справочник \'Распределение бирок животных\'',
        'В справочнике заболеваний добавлено поле \'Инфекционность\'',
        'В справочнике подвидов животных убрано поле \'Уникальность бирки среди подвида\' и добавлено поле \'Номенклатура\'',
        'В карточку учреждения добавлена вкладка \'Показатели\'',
        'В карточку учреждения добавлены поля \'Вид учреждения\', \'Курирующее учреждение\'',
        'В карточку мероприятия добавлены поля \'Серотип\', \'Устойчивость\', \'Повторное исследование\', ' +
        '\'Место убоя\', \'Санитарный убой\', \'Ограничения\', \'Количество продукции\'',
        'В карточку мероприятия добавлен запрет на изменение поля \'Вид мероприятия\' после сохранения',
        'В карточку продукции добавлены поля \'Страна происхождения\', \'Ограничения по использованию\'',
        'Значительно расширены настройки доступа к различным действиям пользователями',
        'Экспертиза продукции расширена полями: \'Лаборатория\', \'Вид экспертизы\', \'Подвид экспертизы\', ' +
        '\'Исследуемый материал\', \'Вид исследования\', \'Способ диагностики\', \'Результат\', ' +
        '\'Место проведения\', \'Заболевание\'',
        'При отсутствии доступа к ХС, ПО, животному, мероприятию пользователю вместо ссылки на карточку ' +
        'соответствующего объекта будет отображаться <<доступ запрещен>>',
        'При отсутствии доступа к ХС, ПО, животному, мероприятию в результатах поиска будут скрыты некоторые поля',
        'Отображение в карточке животного шаблона номенклатуры (при наличии)',
        'При наличии номенклатуры для подвида все животные этого подвида должны иметь уникальную внутри всех подвидов, ' +
        'которые прикреплены к этой номенклатуре, бирку по шаблону',
        'При наличии выделенного диапазона бирок для ХС животные, подвид которых соответствует номенклатуре, ' +
        'должны иметь бирку в соответствие с шаблоном и входить в выделенный диапазон',
        'При сохранении учреждения создается ХС по ИНН (в случае отсутствия)',
        'Добавлены отчеты \'Форма (ПФ). МВ_Серология_4\', \'Форма (ПФ).Прилож_МВ_4_ВЕТ\', \'Форма (ПФ).МВ_4_ВЕТ_КК (НИ)\', ' +
        '\'Форма (ПФ).МВ_4_ВЕТ_КК (КВАРТ)\', \'Форма (ПФ).МВ_5_ВЕТ (НИ)\', \'Форма (ПФ).МВ_5_ВЕТ (КВАРТ)\', ' +
        '\'Форма (ПФ).МВ_1_ВЕТ.Сведения_о_заразных_болезнях_животных (НИ)\', ' +
        '\'Форма (ПФ).МВ_1_ВЕТ.Сведения_о_заразных_болезнях_животных (КВАРТ)\', ' +
        '\'Форма (ПФ).МВ_1_ВЕТ_А Сведения о противоэпизоотических мероприятиях (НИ)\', ' +
        '\'Форма (ПФ).МВ_1_ВЕТ_А Сведения о противоэпизоотических мероприятиях (КВАРТ)\', \'Форма (ПФ).МВ_1_ВЕТ_Г.ГИПОДЕРМАТОЗ (НИ)\', ' +
        '\'Форма (ПФ).МВ_1_ВЕТ_Г.ГИПОДЕРМАТОЗ (КВАРТ)\', \'Форма (ПФ).МВ_4_ВЕТ_Б\''
      ]
    },
    {
      version: '0.0.6',
      releaseDate: '19.03.2021',
      changes: [
        'Добавлен новый тип объекта - Приют',
        'В настройке роли \'Редактирование животного\' добавлена возможность ' +
        'предоставления доступа для роли к конкретному подвиду животных',
        'Подвидам животных добавлен критерий \'Уникальность бирки среди подвида\'',
        'Исправлено сокрытие всплывающего окна карты другими компонентами'
      ]
    },
    {
      version: '0.0.5',
      releaseDate: '11.02.2021',
      changes: [
        'Реализована функция объединения карточек поднадзорных объектов',
        'Изменен цвет отображения записей в Журнале операций о получении доступа на синий',
        'Реализована синхронизация справочников (заболевания, цели, единицы измерения) из Ветис',
        'Реализована возможность редактирования справочника причин выбытия',
        'Реализована возможность гашения ВСД в полном объеме',
        'Реализована возможность обновления информации о поднадзорном органе из Цербер'
      ]
    },
    {
      version: '0.0.4',
      releaseDate: '24.08.2020',
      changes: [
        'Реализовано массовое разнесение мероприятий по животным в поднадзорном объекте',
        'Реализовано удаление + отдельные права на списание препаратов',
        'Реализована передача препаратов меньшими объемами, чем 1 упаковка',
        'Реализована галочка "Проведено самостоятельно хозяйств. субъектом" для мероприятия',
        'Реализовано отображение пользователя и времени в информации о расходовании препарата',
        'Реализован поиск животных по виду / подвиду',
        'Реализован сводный отчет "Количество животных (по ХС)"',
        'Реализован сводный отчет "Количество животных"',
        'Реализовано аннулирование хозяйствующего субъекта',
        'При создании нового препарата количество упаковок заполняется значением по умолчанию "1 коробка"',
        'Существенно расширен состав полей для поиска животных в выборках',
        'Реализовано аннулирование поднадзорного объекта',
        'Реализован ручной запуск обновления хозяйствующего субъекта из Меркурия',
      ]
    },
    {
      version: '0.0.3',
      releaseDate: '16.04.2020',
      changes: [
        'Базовая версия',
      ]
    },
  ];
}
