import { AlertService } from '../../../ui/infrastructure/alert.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { FormBuilder, Validators } from '@angular/forms';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { QuarantineDataService } from '../../../logic/services/quarantine-data.service';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/internal/operators';
import * as i0 from "@angular/core";
import * as i1 from "../../../logic/services/quarantine-data.service";
import * as i2 from "../../../ui/infrastructure/alert.service";
import * as i3 from "../../../ui/infrastructure/global-waiting-overlay.service";
import * as i4 from "../../../logic/services/lookup-source.service";
import * as i5 from "@angular/forms";
import * as i6 from "../../../logic/services/app-navigation.service";
var EditQuarantineEventsController = /** @class */ (function () {
    function EditQuarantineEventsController(quarantineDataService, alertService, waitingOverlay, lookupSourceService, fb, navigationService) {
        this.quarantineDataService = quarantineDataService;
        this.alertService = alertService;
        this.waitingOverlay = waitingOverlay;
        this.lookupSourceService = lookupSourceService;
        this.fb = fb;
        this.navigationService = navigationService;
        this.quarantines = {};
    }
    EditQuarantineEventsController.buildFormGroupFile = function (fb, val) {
        return fb.group({
            uri: [val.uri],
            userIdUpload: val.userIdUpload,
            dateUpload: val.dateUpload,
        });
    };
    EditQuarantineEventsController.getEventGroupDef = function (val, fb, eventTypeLookup) {
        if (val === void 0) { val = {}; }
        var files = (val.files || []).slice();
        files.push({});
        return {
            quarantineId: val.quarantineId,
            id: val.id,
            eventTypeId: [val.eventTypeId, Validators.required],
            orderNo: val.orderNo,
            responsibleTypeId: fb.array((val.responsibleTypeId || []).map(function (el) { return fb.control(el); })),
            responsibleInstitutionId: val.responsibleInstitutionId,
            responsibleOther: val.responsibleOther,
            periodTypeId: val.periodTypeId,
            periodTypeOther: val.periodTypeOther,
            dateCompleted: val.dateCompleted,
            comments: val.comments,
            files: fb.array(files.map(function (el) { return EditQuarantineEventsController.buildFormGroupFile(fb, el); })),
            __editing: !!val.__editing,
            __hovered: !!val.__hovered,
            __reporting: !!val.__reporting,
            __branchId: eventTypeLookup ? (eventTypeLookup['Obj' + val.eventTypeId] || {}).branchId : undefined
        };
    };
    EditQuarantineEventsController.buildFormArray = function (eventsData, fb, eventTypeLookup) {
        eventsData.sort(function (x, y) { return x.orderNo - y.orderNo; });
        return fb.array(eventsData.map(function (item) { return fb.group(EditQuarantineEventsController.getEventGroupDef(item, fb, eventTypeLookup)); }));
    };
    EditQuarantineEventsController.buildFormGroup = function (quarantineId, fb, arr, eventTypeLookup) {
        if (arr === void 0) { arr = []; }
        return fb.group({
            events: EditQuarantineEventsController.buildFormArray(arr, fb, eventTypeLookup)
        });
    };
    EditQuarantineEventsController.prototype.getStoring = function (quarantineId) {
        return this.quarantines[quarantineId] ? this.quarantines[quarantineId].storing : false;
    };
    EditQuarantineEventsController.prototype.getContextFormGroup = function (quarantineId) {
        return this.quarantines[quarantineId] ? this.quarantines[quarantineId].contextFormGroup : undefined;
    };
    EditQuarantineEventsController.prototype.getEvents = function (quarantineId) {
        return this.getContextFormGroup(quarantineId).get('events');
    };
    EditQuarantineEventsController.prototype.store = function (quarantineId) {
        var _this = this;
        var eventItems = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            eventItems[_i - 1] = arguments[_i];
        }
        if (!this.getContextFormGroup(quarantineId).valid || !eventItems || !eventItems.length) {
            return;
        }
        this.quarantines[quarantineId].storing = true;
        this.quarantineDataService.storeEvents(quarantineId, eventItems.map(function (x) { return x.value; }), this.quarantines[quarantineId].delete)
            .subscribe({
            next: function () {
                _this.refreshFormData(quarantineId);
                _this.quarantines[quarantineId].storing = false;
            },
            error: function () {
                _this.quarantines[quarantineId].storing = false;
            }
        });
    };
    EditQuarantineEventsController.prototype.refreshFormData = function (quarantineId) {
        delete this.quarantines[quarantineId];
        this.refreshFormDataInternal(quarantineId);
    };
    EditQuarantineEventsController.prototype.refreshFormDataInternal = function (quarantineId) {
        var _this = this;
        if (!this.quarantines[quarantineId]) {
            this.waitingOverlay.StartWaiting();
            this.lookupSourceService.getLookupObj('quarantine-event-type').subscribe(function (eventTypeLookup) {
                _this.quarantineDataService.getEventsForEdit(quarantineId).subscribe({
                    next: function (data) {
                        _this.quarantines[quarantineId] = {
                            id: quarantineId,
                            delete: [],
                            contextFormGroup: EditQuarantineEventsController.buildFormGroup(quarantineId, _this.fb, data, eventTypeLookup)
                        };
                        _this.waitingOverlay.EndWaiting();
                    }, error: function (error) {
                        _this.waitingOverlay.EndWaiting();
                    }
                });
            });
        }
    };
    EditQuarantineEventsController.prototype.addEvent = function (quarantineId) {
        var _this = this;
        this.lookupSourceService.getLookupObj('quarantine-event-type').subscribe(function (eventTypeLookup) {
            _this.getEvents(quarantineId).push(_this.fb.group(EditQuarantineEventsController.getEventGroupDef({
                orderNo: _this.calculateNextOrderNo(quarantineId),
                __editing: true
            }, _this.fb, eventTypeLookup)));
            _this.getContextFormGroup(quarantineId).markAsDirty();
        });
    };
    EditQuarantineEventsController.prototype.deleteEvent = function (quarantineId, eventItem) {
        var _this = this;
        var index = this.getEvents(quarantineId).controls.findIndex(function (el) { return el === eventItem; });
        if (index < 0) {
            return;
        }
        this.alertService.confirmModal('Вы уверены, что хотите безвозвратно удалить выбранную строку плана мероприятий?')
            .subscribe(function () {
            var idToDelete = _this.getEvents(quarantineId).controls[index].get('id').value;
            if (idToDelete) {
                _this.quarantines[quarantineId].storing = true;
                _this.quarantineDataService.storeEvents(quarantineId, [], [idToDelete])
                    .subscribe({
                    next: function () {
                        _this.getEvents(quarantineId).removeAt(index);
                        _this.quarantines[quarantineId].storing = false;
                    },
                    error: function () {
                        _this.quarantines[quarantineId].storing = false;
                    }
                });
            }
        });
    };
    EditQuarantineEventsController.prototype.singleFileChanged = function (quarantineId, eventItem) {
        var filesArr = eventItem.get('files');
        var i = 0;
        while (i < filesArr.length) {
            var fileI = filesArr.controls[i];
            if (fileI.value && fileI.value.uri) {
                i++;
            }
            else {
                filesArr.removeAt(i);
            }
        }
        filesArr.push(EditQuarantineEventsController.buildFormGroupFile(this.fb, {}));
        eventItem.markAsDirty();
    };
    EditQuarantineEventsController.prototype.hasKgkuResponsible = function (quarantineId, eventItem) {
        for (var _i = 0, _a = eventItem.controls['responsibleTypeId'].controls; _i < _a.length; _i++) {
            var ctrl = _a[_i];
            if (ctrl.value == 2 /*Специалисты госветслужбы КГКУ*/ || ctrl.value == 3 /*Руководитель КГКУ*/) {
                return true;
            }
        }
        return false;
    };
    EditQuarantineEventsController.prototype.calculateNextOrderNo = function (quarantineId) {
        var maxNo = 0;
        this.getEvents(quarantineId).controls.forEach(function (ctrl) {
            if (ctrl.value.orderNo > maxNo) {
                maxNo = ctrl.value.orderNo;
            }
        });
        return maxNo + 1;
    };
    EditQuarantineEventsController.prototype.goEditing = function (quarantineId, eventItem) {
        eventItem.get('__editing').setValue(true);
        eventItem.markAsDirty();
    };
    EditQuarantineEventsController.prototype.goReporting = function (quarantineId, eventItem) {
        eventItem.get('__reporting').setValue(true);
        eventItem.markAsDirty();
    };
    EditQuarantineEventsController.prototype.getResponsiblesTitle = function (quarantineId, eventItem) {
        return combineLatest(this.lookupSourceService.getLookupObj('quarantine-event-responsible'), this.lookupSourceService.getLookupObj('institution')).pipe(map(function (_a) {
            var respLookup = _a[0], institLookup = _a[1];
            var kgkuCaption = eventItem.get('responsibleInstitutionId').value
                ? institLookup[eventItem.get('responsibleInstitutionId').value] : undefined;
            var strResp = '';
            eventItem.value.responsibleTypeId.forEach(function (rt) {
                if (rt) {
                    var respCaption = respLookup[rt];
                    if (kgkuCaption) {
                        respCaption = respCaption.replace('КГКУ', kgkuCaption);
                    }
                    strResp = strResp + (strResp ? ', ' : '') + respCaption;
                }
            });
            if (eventItem.get('responsibleOther').value) {
                strResp = strResp + (strResp ? ', ' : '') + eventItem.get('responsibleOther').value;
            }
            return strResp;
        }));
    };
    EditQuarantineEventsController.prototype.getUsedBranchIds = function (quarantineId) {
        var branchIds = [];
        var branchHash = {};
        this.getEvents(quarantineId).controls.forEach(function (eventItem) {
            var branchId = eventItem.get('__branchId').value;
            if (!branchHash[branchId]) {
                branchHash[branchId] = true;
                branchIds.push(branchId);
            }
        });
        return branchIds;
    };
    EditQuarantineEventsController.prototype.getEventsByBranch = function (quarantineId, branchId, includeNoBranch) {
        var controls = [];
        this.getEvents(quarantineId).controls.forEach(function (eventItem) {
            var tid = eventItem.get('__branchId').value;
            if ((tid == branchId) || (!tid && includeNoBranch)) {
                controls.push(eventItem);
            }
        });
        return controls;
    };
    EditQuarantineEventsController.prototype.moveUp = function (quarantineId, eventItem) {
        var index = this.getEvents(quarantineId).controls.findIndex(function (el) { return el === eventItem; });
        if (index <= 0) {
            return;
        }
        this.moveItem(quarantineId, eventItem, this.getEvents(quarantineId).controls[index - 1]);
    };
    EditQuarantineEventsController.prototype.moveDown = function (quarantineId, eventItem) {
        var index = this.getEvents(quarantineId).controls.findIndex(function (el) { return el === eventItem; });
        if (index >= this.getEvents(quarantineId).controls.length - 1) {
            return;
        }
        this.moveItem(quarantineId, eventItem, this.getEvents(quarantineId).controls[index + 1]);
    };
    EditQuarantineEventsController.prototype.moveItem = function (quarantineId, eventItem, eventItem2) {
        var _this = this;
        var orderNo = eventItem.value.orderNo;
        eventItem.get('orderNo').setValue(eventItem2.value.orderNo);
        eventItem2.get('orderNo').setValue(orderNo);
        this.getEvents(quarantineId).controls.sort(function (x, y) { return x.get('orderNo').value - y.get('orderNo').value; });
        this.quarantines[quarantineId].storing = true;
        this.quarantineDataService.storeEvents(quarantineId, [eventItem.value, eventItem2.value], [])
            .subscribe({
            next: function () {
                _this.quarantines[quarantineId].storing = false;
            },
            error: function () {
                _this.quarantines[quarantineId].storing = false;
            }
        });
    };
    EditQuarantineEventsController.ngInjectableDef = i0.defineInjectable({ factory: function EditQuarantineEventsController_Factory() { return new EditQuarantineEventsController(i0.inject(i1.QuarantineDataService), i0.inject(i2.AlertService), i0.inject(i3.GlobalWaitingOverlayService), i0.inject(i4.LookupSourceService), i0.inject(i5.FormBuilder), i0.inject(i6.AppNavigationService)); }, token: EditQuarantineEventsController, providedIn: "root" });
    return EditQuarantineEventsController;
}());
export { EditQuarantineEventsController };
