/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@clr/angular";
import * as i2 from "@angular/common";
import * as i3 from "./ag-grid-button-status-bar.component";
var styles_AgGridButtonStatusBarComponent = [".btn.btn-link.itech-inline-tool-btn.itech-grid-status-bar-button-right[_ngcontent-%COMP%] {\n        width: 100%;\n        letter-spacing: normal;\n        text-transform: none;\n        font-weight: 350;\n    }"];
var RenderType_AgGridButtonStatusBarComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_AgGridButtonStatusBarComponent, data: {} });
export { RenderType_AgGridButtonStatusBarComponent as RenderType_AgGridButtonStatusBarComponent };
function View_AgGridButtonStatusBarComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "clr-icon", [["shape", "copy"], ["size", "22"]], null, null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i1.ClrIconCustomTag, [], null, null)], null, null); }
function View_AgGridButtonStatusBarComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "clr-icon", [["shape", "pinboard"], ["size", "22"]], null, null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i1.ClrIconCustomTag, [], null, null)], null, null); }
function View_AgGridButtonStatusBarComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 6, "span", [["class", "component"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 5, "button", [["class", "btn btn-link itech-inline-tool-btn itech-grid-status-bar-button-right"], ["type", "button"]], [[4, "color", null], [8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AgGridButtonStatusBarComponent_2)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AgGridButtonStatusBarComponent_3)), i0.ɵdid(7, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(8, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.params.icon && (_co.params.icon === "copy")); _ck(_v, 5, 0, currVal_2); var currVal_3 = (_co.params.icon && (_co.params.icon === "pinboard")); _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.params.color; var currVal_1 = (_co.params.title || "\u0432\u044B\u043F\u043E\u043B\u043D\u0438\u0442\u044C \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0435"); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_4 = (_co.params.caption ? _co.params.caption : ""); _ck(_v, 8, 0, currVal_4); }); }
export function View_AgGridButtonStatusBarComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AgGridButtonStatusBarComponent_1)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.params; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AgGridButtonStatusBarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-grid-button-status-bar", [], null, null, null, View_AgGridButtonStatusBarComponent_0, RenderType_AgGridButtonStatusBarComponent)), i0.ɵdid(1, 49152, null, 0, i3.AgGridButtonStatusBarComponent, [], null, null)], null, null); }
var AgGridButtonStatusBarComponentNgFactory = i0.ɵccf("app-grid-button-status-bar", i3.AgGridButtonStatusBarComponent, View_AgGridButtonStatusBarComponent_Host_0, {}, {}, []);
export { AgGridButtonStatusBarComponentNgFactory as AgGridButtonStatusBarComponentNgFactory };
