<div class="itech-common-wrap" id="interceptNoAccess">
  <div style="width: 100%">
    <app-grid [pagination]="false" [getRowStyle]="getRowStyle" [columnDefs]="gridColumnDefs" [rowSelection]="'multiple'"
              [rowData]="model.searchResults" (selectionChanged)="onGridSelectionChanged($event)"
              (rowClicked)="navigationService.performAnimalEditing($event.data.id || $event.data.tempId, null, null, !$event.data.id)"
              [exportFileName]="'Список животных у ПО ' + (id|lookup:'stable'|async)"
              [uniqueGridName]="'EditStableAnimalsGrid'"></app-grid>
  </div>

  <div class="itech-form-actions">
    <div>
      <button class="btn btn-secondary" (click)="refreshFormData()">
        <clr-icon shape="refresh"></clr-icon>&nbsp;Обновить
      </button>
      <clr-dropdown>
        <button class="btn btn-secondary" clrDropdownToggle>
          Действия
          <clr-icon shape="caret down"></clr-icon>
        </button>
        <clr-dropdown-menu *clrIfOpen [clrPosition]="'top-left'">
          <label class="dropdown-header">Выберите действие
            {{model.selectedRows && model.selectedRows.length ? ' (' + model.selectedRows.length + ' элемент(ов))' : ''}}</label>
          <button type="button" clrDropdownItem (click)="confirmSelected()"
                  [disabled]="model.selectedRows && model.selectedRows.length ? undefined : true">Подтвердить животных</button>
          <div class="dropdown-divider"></div>
          <button class="btn btn-outline-secondary" (click)="updateFromMercuryAndRefreshFormData()">
            <clr-icon shape="refresh"></clr-icon>&nbsp;Обновить из Меркурия
          </button>
        </clr-dropdown-menu>
      </clr-dropdown>
      <button class="btn btn-success-outline" (click)="addNew()">
        <clr-icon shape="plus"></clr-icon>&nbsp;Добавить
      </button>
    </div>

    <div>
      <div class="checkbox">
        <input type="checkbox" id="showHistoryCb" [(ngModel)]="model.showHistory" (change)="refreshFormData()">
        <label for="showHistoryCb">Показывать историю</label>
      </div>
    </div>
  </div>
</div>
