/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/@clr/angular/clr-angular.ngfactory";
import * as i2 from "@clr/angular";
import * as i3 from "@angular/common";
import * as i4 from "../../ui/controls/app-custom-modal-host.directive";
import * as i5 from "./app-custom-modal.component";
import * as i6 from "../../logic/services/app-navigation.service";
var styles_AppCustomModalComponent = [];
var RenderType_AppCustomModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppCustomModalComponent, data: {} });
export { RenderType_AppCustomModalComponent as RenderType_AppCustomModalComponent };
function View_AppCustomModalComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "button", [["class", "btn btn-primary"], ["type", "button"]], [[1, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.acceptModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ClrLoadingButton_0, i1.RenderType_ClrLoadingButton)), i0.ɵdid(2, 49152, null, 0, i2.ClrLoadingButton, [i0.ElementRef, i0.Renderer2], { disabled: [0, "disabled"] }, null), i0.ɵprd(2048, null, i2.LoadingListener, null, [i2.ClrLoadingButton]), i0.ɵdid(4, 147456, null, 0, i2.ClrLoading, [[2, i2.LoadingListener]], { loadingState: [0, "loadingState"] }, null), (_l()(), i0.ɵeld(5, 0, null, 0, 1, "clr-icon", [["shape", "check"]], null, null, null, null, null)), i0.ɵdid(6, 16384, null, 0, i2.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(7, 0, ["\u00A0", " "])), (_l()(), i0.ɵeld(8, 0, null, null, 3, "button", [["class", "btn btn-warning"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.navigationService.customModalOpened = false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "clr-icon", [["shape", "undo"]], null, null, null, null, null)), i0.ɵdid(10, 16384, null, 0, i2.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, ["\u00A0\u041E\u0442\u043C\u0435\u043D\u0430 "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.navigationService.customModalAcceptPressed ? true : undefined); _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.navigationService.customModalAcceptPressed; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i0.ɵnov(_v, 2).disabled ? "" : null); _ck(_v, 1, 0, currVal_0); var currVal_3 = _co.navigationService.customModalAcceptButtonText; _ck(_v, 7, 0, currVal_3); }); }
function View_AppCustomModalComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "clr-modal", [], [[2, "open", null]], [[null, "clrModalOpenChange"], ["body", "keyup.escape"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("body:keyup.escape" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).close() !== false);
        ad = (pd_0 && ad);
    } if (("clrModalOpenChange" === en)) {
        var pd_1 = ((_co.navigationService.customModalOpened = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_ClrModal_0, i1.RenderType_ClrModal)), i0.ɵprd(8704, null, i2.ɵed, i2.ɵed, [i3.DOCUMENT]), i0.ɵprd(1024, null, i2.ɵo, i2.ɵp, []), i0.ɵdid(3, 704512, null, 0, i2.ClrModal, [i2.ɵed, i2.ClrCommonStringsService, i0.PLATFORM_ID, i2.ɵo], { _open: [0, "_open"], size: [1, "size"] }, { _openChanged: "clrModalOpenChange" }), (_l()(), i0.ɵeld(4, 0, null, 1, 1, "h3", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, [" ", " "])), (_l()(), i0.ɵeld(6, 0, null, 2, 3, "div", [["class", "modal-body"], ["style", "min-height:200px;max-height:80vh"]], [[1, "tabindex", 0]], [[null, "focus"], [null, "mousedown"], [null, "mouseup"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i0.ɵnov(_v, 7).focus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mousedown" === en)) {
        var pd_1 = (i0.ɵnov(_v, 7).mouseDown() !== false);
        ad = (pd_1 && ad);
    } if (("mouseup" === en)) {
        var pd_2 = (i0.ɵnov(_v, 7).mouseUp() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(7, 16384, null, 0, i2.ɵee, [], null, null), (_l()(), i0.ɵeld(8, 16777216, null, null, 1, null, null, null, null, null, null, null)), i0.ɵdid(9, 16384, [[1, 4]], 0, i4.AppCustomModalHostDirective, [i0.ViewContainerRef, i0.ComponentFactoryResolver], { appCustomModalHost: [0, "appCustomModalHost"], appCustomModalHostOnBodyCreate: [1, "appCustomModalHostOnBodyCreate"] }, null), (_l()(), i0.ɵand(16777216, null, 3, 1, null, View_AppCustomModalComponent_2)), i0.ɵdid(11, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.navigationService.customModalOpened; var currVal_2 = _co.navigationService.customModalSize; _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_5 = _co.navigationService.customModalComponent; var currVal_6 = _co.onBodyComponentCreate; _ck(_v, 9, 0, currVal_5, currVal_6); var currVal_7 = _co.navigationService.customModalAcceptButtonText; _ck(_v, 11, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 3)._open; _ck(_v, 0, 0, currVal_0); var currVal_3 = _co.navigationService.customModalTitle; _ck(_v, 5, 0, currVal_3); var currVal_4 = "0"; _ck(_v, 6, 0, currVal_4); }); }
export function View_AppCustomModalComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { viewChildren: 1 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppCustomModalComponent_1)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.navigationService.customModalOpened; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AppCustomModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-custom-modal", [], null, null, null, View_AppCustomModalComponent_0, RenderType_AppCustomModalComponent)), i0.ɵdid(1, 49152, null, 0, i5.AppCustomModalComponent, [i6.AppNavigationService], null, null)], null, null); }
var AppCustomModalComponentNgFactory = i0.ɵccf("app-custom-modal", i5.AppCustomModalComponent, View_AppCustomModalComponent_Host_0, {}, {}, []);
export { AppCustomModalComponentNgFactory as AppCustomModalComponentNgFactory };
