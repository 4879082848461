/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/@clr/angular/clr-angular.ngfactory";
import * as i2 from "@clr/angular";
import * as i3 from "@angular/common";
import * as i4 from "./app-logging-modal.component";
var styles_AppLoggingModalComponent = [];
var RenderType_AppLoggingModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppLoggingModalComponent, data: {} });
export { RenderType_AppLoggingModalComponent as RenderType_AppLoggingModalComponent };
export function View_AppLoggingModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "clr-modal", [], [[2, "open", null]], [[null, "clrModalOpenChange"], ["body", "keyup.escape"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("body:keyup.escape" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).close() !== false);
        ad = (pd_0 && ad);
    } if (("clrModalOpenChange" === en)) {
        var pd_1 = ((_co.modalOpen = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_ClrModal_0, i1.RenderType_ClrModal)), i0.ɵprd(8704, null, i2.ɵed, i2.ɵed, [i3.DOCUMENT]), i0.ɵprd(1024, null, i2.ɵo, i2.ɵp, []), i0.ɵdid(3, 704512, null, 0, i2.ClrModal, [i2.ɵed, i2.ClrCommonStringsService, i0.PLATFORM_ID, i2.ɵo], { _open: [0, "_open"], closable: [1, "closable"] }, { _openChanged: "clrModalOpenChange" }), (_l()(), i0.ɵeld(4, 0, null, 2, 8, "div", [["class", "modal-body"]], [[1, "tabindex", 0]], [[null, "focus"], [null, "mousedown"], [null, "mouseup"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i0.ɵnov(_v, 5).focus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mousedown" === en)) {
        var pd_1 = (i0.ɵnov(_v, 5).mouseDown() !== false);
        ad = (pd_1 && ad);
    } if (("mouseup" === en)) {
        var pd_2 = (i0.ɵnov(_v, 5).mouseUp() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(5, 16384, null, 0, i2.ɵee, [], null, null), (_l()(), i0.ɵeld(6, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 5, "div", [["style", "display: flex; flex-direction: row; align-items: center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "clr-icon", [["class", "is-solid"], ["shape", "hourglass"], ["size", "70"], ["style", "margin: 0 10px 0 10px; flex: 1 0 auto"]], null, null, null, null, null)), i0.ɵdid(9, 16384, null, 0, i2.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵeld(10, 0, null, null, 2, "div", [["style", "flex: 1000 1 auto"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "h3", [["style", "margin-top: 0"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u041F\u0440\u043E\u0432\u0435\u0440\u043A\u0430 \u0434\u0430\u043D\u043D\u044B\u0445 \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u044F"])), (_l()(), i0.ɵeld(13, 0, null, 3, 0, "div", [["class", "modal-footer itech-form-actions"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.modalOpen; var currVal_2 = false; _ck(_v, 3, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 3)._open; _ck(_v, 0, 0, currVal_0); var currVal_3 = "0"; _ck(_v, 4, 0, currVal_3); }); }
export function View_AppLoggingModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-logging-modal", [], null, null, null, View_AppLoggingModalComponent_0, RenderType_AppLoggingModalComponent)), i0.ɵdid(1, 49152, null, 0, i4.AppLoggingModalComponent, [], null, null)], null, null); }
var AppLoggingModalComponentNgFactory = i0.ɵccf("app-logging-modal", i4.AppLoggingModalComponent, View_AppLoggingModalComponent_Host_0, {}, {}, []);
export { AppLoggingModalComponentNgFactory as AppLoggingModalComponentNgFactory };
