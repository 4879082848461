/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../ui/controls/app-grid.component.ngfactory";
import * as i2 from "../../ui/controls/app-grid.component";
import * as i3 from "../../logic/services/data-caching.service";
import * as i4 from "../../logic/services/security.service";
import * as i5 from "@angular/common";
import * as i6 from "@clr/angular";
import * as i7 from "../../ui/controls/user-tags-autofill-edit-modal.component.ngfactory";
import * as i8 from "../../ui/controls/user-tags-autofill-edit-modal.component";
import * as i9 from "@angular/forms";
import * as i10 from "./user-tags-autofill.component";
import * as i11 from "../../ui/infrastructure/alert.service";
var styles_UserTagsAutofillComponent = [];
var RenderType_UserTagsAutofillComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_UserTagsAutofillComponent, data: {} });
export { RenderType_UserTagsAutofillComponent as RenderType_UserTagsAutofillComponent };
export function View_UserTagsAutofillComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [["class", "itech-common-wrap"], ["id", "interceptNoAccess"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["id", "agent-common-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "itech-doc-container"], ["style", "min-height:100%"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 4, "div", [["style", "padding:5px;width:100%;min-height:88%"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 3, "app-grid", [], null, null, null, i1.View_AppGridComponent_0, i1.RenderType_AppGridComponent)), i0.ɵdid(5, 114688, null, 0, i2.AppGridComponent, [i3.DataCachingService, i4.SecurityService], { uniqueGridName: [0, "uniqueGridName"], columnDefs: [1, "columnDefs"], rowData: [2, "rowData"], frameworkComponents: [3, "frameworkComponents"], exportFileName: [4, "exportFileName"] }, null), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(8, 0, null, null, 5, "div", [["class", "itech-form-actions"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 3, "button", [["class", "btn btn-success-outline"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.editTag({}) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "clr-icon", [["shape", "plus"]], null, null, null, null, null)), i0.ɵdid(12, 16384, null, 0, i6.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, ["\u00A0\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C "])), (_l()(), i0.ɵeld(14, 0, null, null, 1, "app-user-tags-autofill-edit-modal", [], null, null, null, i7.View_UserTagsAutofillEditModalComponent_0, i7.RenderType_UserTagsAutofillEditModalComponent)), i0.ɵdid(15, 114688, null, 0, i8.UserTagsAutofillEditModalComponent, [i9.FormBuilder, i4.SecurityService], { modalOpenedEmit: [0, "modalOpenedEmit"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "UserTagsAutofillGrid"; var currVal_1 = _co.gridColumnDefs; var currVal_2 = i0.ɵunv(_v, 5, 2, i0.ɵnov(_v, 6).transform(_co.userService.getUserInfo())).userSettingUi.tags; var currVal_3 = _co.frameworkComponents; var currVal_4 = ("\u0422\u044D\u0433\u0438 \u0430\u0432\u0442\u043E\u0437\u0430\u043F\u043E\u043B\u043D\u0435\u043D\u0438\u044F " + i0.ɵunv(_v, 5, 4, i0.ɵnov(_v, 7).transform(_co.userService.getUserInfo())).id); _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.editTagModalOpened; _ck(_v, 15, 0, currVal_5); }, null); }
export function View_UserTagsAutofillComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-user-tags-autofill", [], null, null, null, View_UserTagsAutofillComponent_0, RenderType_UserTagsAutofillComponent)), i0.ɵdid(1, 49152, null, 0, i10.UserTagsAutofillComponent, [i4.SecurityService, i11.AlertService], null, null)], null, null); }
var UserTagsAutofillComponentNgFactory = i0.ɵccf("app-user-tags-autofill", i10.UserTagsAutofillComponent, View_UserTagsAutofillComponent_Host_0, {}, {}, []);
export { UserTagsAutofillComponentNgFactory as UserTagsAutofillComponentNgFactory };
