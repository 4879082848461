import { Component, OnInit } from '@angular/core';
import { IModalBody, ModalResult } from '../../logic/services/app-navigation.service.models';
import { AppNavigationService } from '../../logic/services/app-navigation.service';
import { FormBuilder } from '@angular/forms';
import { AlertService } from '../../ui/infrastructure/alert.service';
import { AgentDataService } from '../../logic/services/agent-data.service';
import { Observable } from 'rxjs';
import { EditAgentCommonFormComponent } from './common/edit-agent-common-form.component';
import { FormHelper } from '../../ui/controls/form-helper';

@Component({
  template: `
      <app-edit-agent-common-form *ngIf="model" [contextFormGroup]="model.form"></app-edit-agent-common-form>

      <p>
          <button [clrLoading]="creating" [disabled]="creating" class="btn btn-primary"
                  (click)="createCard()">
              <clr-icon shape="check"></clr-icon>&nbsp;Создать
          </button>
      </p>
  `
})
export class CreateAgentModalComponent implements OnInit, IModalBody<any, void> {

  model: any = {};
  creating = false;
  created = false;

  constructor(public appNavigationService: AppNavigationService,
              private fb: FormBuilder,
              private alertService: AlertService,
              private agentDataService: AgentDataService) {
  }

  ngOnInit() {
    this.model.form = EditAgentCommonFormComponent.createFormGroup(this.fb, this.model, { legalFormId: 0 });
  }

  createCard() {

    EditAgentCommonFormComponent.prettifyFormGroup(this.model.form);
    FormHelper.markAsSubmitted(this.model.form);

    if (!this.model.form.valid) {
      return;
    }

    const dataToStore = this.model.form.value;

    this.creating = true;

    this.agentDataService.storeAgentCommonInfo(dataToStore).subscribe((createdAgent: any) => {
      this.creating = false;
      this.created = true;
      if (createdAgent.id) {
        this.appNavigationService.customModalAcceptExternalPromiseAny.next(createdAgent);
        this.appNavigationService.customModalOpened = false;
      }
    }, error => {
      this.creating = false;
      FormHelper.setSingleFormGroupServerSideValidationErrors(error, this.model, this.model.form);
    });
  }

  initModalBody(data: any) {
  }

  onModalAccept$(): Observable<ModalResult<void>> {
    return undefined;
  }
}
