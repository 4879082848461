
      <div class="form compact" [formGroup]="contextFormGroup" style="min-height: 400px">

          <section class="form-block itech-block-normal">

              <div class="form-group">
                  <label for="indicatorTypeId" class="required">Показатель</label>
                  <app-text-lookup class="itech-control-m-large" [contextControlId]="'indicatorTypeId'" [sorted]="true"
                                   [contextControlName]="'indicatorTypeId'" [lookupName]="'pst-indicator-type'"
                                   [validationTooltipBottomLeft]="true"
                                   [contextFormGroup]="contextFormGroup"></app-text-lookup>
              </div>

              <div class="form-group">
                  <label for="reportTypeId" class="required">Вид отчета</label>
                  <app-text-lookup class="itech-control-m-large" [contextControlId]="'reportTypeId'" [sorted]="true"
                                   [contextControlName]="'reportTypeId'" [lookupName]="'pst-report-type'"
                                   [validationTooltipBottomLeft]="true"
                                   [contextFormGroup]="contextFormGroup"></app-text-lookup>
              </div>

              <div class="form-group">
                  <label>Даты с / по</label>
                  <label for="dateFrom" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md"
                         [class.invalid]="isInvalid(contextFormGroup, 'dateFrom')">
                      <input type="date" id="dateFrom" class="itech-control-normal"
                             formControlName="dateFrom">
                      <app-validation-tooltip [input]="contextFormGroup.controls['dateFrom']"></app-validation-tooltip>
                  </label>
                  &nbsp;
                  <label for="dateTo" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md"
                         [class.invalid]="isInvalid(contextFormGroup, 'dateTo')">
                      <input type="date" id="dateTo" class="itech-control-normal"
                             formControlName="dateTo">
                      <app-validation-tooltip [input]="contextFormGroup.controls['dateTo']"></app-validation-tooltip>
                  </label>
              </div>

          </section>

          <app-doc-validation-errors [validationErrors]="contextFormGroup.docLevelServerSideValidationErrors">
          </app-doc-validation-errors>

      </div>
  