import * as tslib_1 from "tslib";
import { QueryDetailsEntityComponent } from './query-details-entity.component';
import { QueryService } from '../../logic/services/query.service';
import { DataCachingService } from '../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../logic/services/lookup-source.service';
import { AlertService } from '../../ui/infrastructure/alert.service';
import { FormBuilder } from '@angular/forms';
import { FormHelper } from '../../ui/controls/form-helper';
var QueryDetailsQuarantineComponent = /** @class */ (function (_super) {
    tslib_1.__extends(QueryDetailsQuarantineComponent, _super);
    function QueryDetailsQuarantineComponent(queryService, dataCachingService, waitingOverlay, lookupSourceService, alertService, fb) {
        var _this = _super.call(this, queryService, dataCachingService, waitingOverlay, lookupSourceService, alertService, fb) || this;
        _this.EntityType = 4; // Карантин
        return _this;
    }
    QueryDetailsQuarantineComponent.getGroupDef = function (fb, condition) {
        return {
            type: 'quarantine',
            _par_disease_type: condition.data._par_disease_type,
            _par_disease_type_filter: condition.data._par_disease_type_filter || 0,
            _par_source_pathogen_animal_type: condition.data._par_source_pathogen_animal_type,
            _par_source_pathogen_animal_type_filter: condition.data._par_source_pathogen_animal_type_filter || 0,
            _par_source_pathogen_animal_subtype: condition.data._par_source_pathogen_animal_subtype,
            _par_source_pathogen_animal_subtype_filter: condition.data._par_source_pathogen_animal_subtype_filter || 0,
            _par_susceptible_unique_animal_subtype: fb.array(condition.data._par_susceptible_unique_animal_subtype || []),
            _par_susceptible_unique_animal_subtype_filter: condition.data._par_susceptible_unique_animal_subtype_filter || 0,
            _par_current_status: condition.data._par_current_status,
            _par_current_status_filter: condition.data._par_current_status_filter || 0,
            _par_date_filter: condition.data._par_date_filter || 0,
            _par_date_from: [condition.data._par_date_from, FormHelper.validateDateTimePicker()],
            _par_date_to: [condition.data._par_date_to, FormHelper.validateDateTimePicker()],
            _par_responsible_institution: condition.data._par_responsible_institution,
            _par_responsible_institution_filter: condition.data._par_responsible_institution_filter || 0,
            _par_responsible_institution_employee: condition.data._par_responsible_institution_employee,
            _par_responsible_institution_employee_filter: condition.data._par_responsible_institution_employee_filter || 0,
            _par_territory_type: condition.data._par_territory_type,
            _par_territory_type_filter: condition.data._par_territory_type_filter || 0,
            _par_city: condition.data._par_city,
            _par_city_filter: condition.data._par_city_filter || 0,
            _par_region: condition.data._par_region,
            _par_region_filter: condition.data._par_region_filter || 0,
            _par_start_quarantine_date_filter: condition.data._par_start_quarantine_date_filter || 0,
            _par_start_quarantine_date_from: [condition.data._par_start_quarantine_date_from, FormHelper.validateDateTimePicker()],
            _par_start_quarantine_date_to: [condition.data._par_start_quarantine_date_to, FormHelper.validateDateTimePicker()],
            _par_end_quarantine_date_filter: condition.data._par_end_quarantine_date_filter || 0,
            _par_end_quarantine_date_from: [condition.data._par_end_quarantine_date_from, FormHelper.validateDateTimePicker()],
            _par_end_quarantine_date_to: [condition.data._par_end_quarantine_date_to, FormHelper.validateDateTimePicker()],
        };
    };
    QueryDetailsQuarantineComponent.prototype.getGroupDef = function () {
        return QueryDetailsQuarantineComponent.getGroupDef(this.fb, this.model.condition);
    };
    return QueryDetailsQuarantineComponent;
}(QueryDetailsEntityComponent));
export { QueryDetailsQuarantineComponent };
