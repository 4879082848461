/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./query.all.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@clr/angular";
import * as i3 from "../../ui/controls/app-grid.component.ngfactory";
import * as i4 from "../../ui/controls/app-grid.component";
import * as i5 from "../../logic/services/data-caching.service";
import * as i6 from "../../logic/services/security.service";
import * as i7 from "@angular/common";
import * as i8 from "../../../../node_modules/@clr/angular/clr-angular.ngfactory";
import * as i9 from "./query-standalone-results.component";
import * as i10 from "@angular/router";
import * as i11 from "../../logic/services/query.service";
import * as i12 from "../../logic/services/app-navigation.service";
import * as i13 from "../../logic/services/bookmark.service";
import * as i14 from "./helpers/query-runner-ui.service";
var styles_QueryStandaloneResultsComponent = [i0.styles];
var RenderType_QueryStandaloneResultsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_QueryStandaloneResultsComponent, data: {} });
export { RenderType_QueryStandaloneResultsComponent as RenderType_QueryStandaloneResultsComponent };
function View_QueryStandaloneResultsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "itech-content-header itech-no-margin-bottom itech-small"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "clr-icon", [["shape", "bolt"], ["size", "24"]], null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i2.ClrIconCustomTag, [], null, null), (_l()(), i1.ɵted(3, null, ["\u00A0", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.query.data.caption; _ck(_v, 3, 0, currVal_0); }); }
function View_QueryStandaloneResultsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-grid", [], null, [[null, "rowClicked"], [null, "gridReady"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("rowClicked" === en)) {
        var pd_0 = (_co.queryRunnerUi.navigateRow(_co.model, $event.data) !== false);
        ad = (pd_0 && ad);
    } if (("gridReady" === en)) {
        var pd_1 = (_co.agGridReady($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_AppGridComponent_0, i3.RenderType_AppGridComponent)), i1.ɵdid(1, 114688, null, 0, i4.AppGridComponent, [i5.DataCachingService, i6.SecurityService], { uniqueGridName: [0, "uniqueGridName"], columnDefs: [1, "columnDefs"], rowData: [2, "rowData"], pagination: [3, "pagination"], paginationAutoPageSize: [4, "paginationAutoPageSize"], exportFileName: [5, "exportFileName"], pinnedTopRowData: [6, "pinnedTopRowData"] }, { gridReady: "gridReady", rowClicked: "rowClicked" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "QueryStandaloneResults"; var currVal_1 = _co.model.query.dataColumns; var currVal_2 = _co.model.query.results; var currVal_3 = true; var currVal_4 = true; var currVal_5 = "\u0420\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442\u044B \u0432\u044B\u0431\u043E\u0440\u043A\u0438"; var currVal_6 = _co.model.query.aggSumRow; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_QueryStandaloneResultsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "itech-common-wrap no-pager"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QueryStandaloneResultsComponent_1)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["style", "padding: 5px; height: 95%"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QueryStandaloneResultsComponent_2)), i1.ɵdid(6, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 7, "div", [["class", "itech-form-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 6, "button", [["class", "btn btn-link btn-primary"]], [[1, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.refreshFormData() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_ClrLoadingButton_0, i8.RenderType_ClrLoadingButton)), i1.ɵdid(9, 49152, null, 0, i2.ClrLoadingButton, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵprd(2048, null, i2.LoadingListener, null, [i2.ClrLoadingButton]), i1.ɵdid(11, 147456, null, 0, i2.ClrLoading, [[2, i2.LoadingListener]], { loadingState: [0, "loadingState"] }, null), (_l()(), i1.ɵeld(12, 0, null, 0, 1, "clr-icon", [["class", "has-badge"], ["shape", "refresh"]], null, null, null, null, null)), i1.ɵdid(13, 16384, null, 0, i2.ClrIconCustomTag, [], null, null), (_l()(), i1.ɵted(-1, 0, ["\u00A0\u041E\u0431\u043D\u043E\u0432\u0438\u0442\u044C"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model; _ck(_v, 3, 0, currVal_0); var currVal_1 = ((_co.model && _co.model.query) && _co.model.query.results); _ck(_v, 6, 0, currVal_1); var currVal_3 = _co.queryRunnerUi.quering; _ck(_v, 11, 0, currVal_3); }, function (_ck, _v) { var currVal_2 = (i1.ɵnov(_v, 9).disabled ? "" : null); _ck(_v, 8, 0, currVal_2); }); }
export function View_QueryStandaloneResultsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-query-standalone-results", [], null, null, null, View_QueryStandaloneResultsComponent_0, RenderType_QueryStandaloneResultsComponent)), i1.ɵdid(1, 245760, null, 0, i9.QueryStandaloneResultsComponent, [i5.DataCachingService, i10.ActivatedRoute, i11.QueryService, i12.AppNavigationService, i13.BookmarkService, i14.QueryRunnerUiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var QueryStandaloneResultsComponentNgFactory = i1.ɵccf("app-query-standalone-results", i9.QueryStandaloneResultsComponent, View_QueryStandaloneResultsComponent_Host_0, {}, {}, []);
export { QueryStandaloneResultsComponentNgFactory as QueryStandaloneResultsComponentNgFactory };
