<div class="itech-doc-container" *ngIf="contextFormGroup">
  <div class="itech-doc-content" style="display:flex">
    <div class="itech-doc-image">
      <clr-icon shape="building"></clr-icon>
      <app-mercury-sync-status-signpost [syncStates]="syncStates" style="max-width:60px" (syncStateUpdated)="syncStateUpdated.emit()">
      </app-mercury-sync-status-signpost>
      <app-trapped-quarantine-signpost [trappedQuarantine]="contextFormGroup.get('trappedToQuarantine').value"
                                       [objectCaption]="'Поднадзорный орган'">
      </app-trapped-quarantine-signpost>
    </div>
    <div class="compact form itech-highlighted-sections" [formGroup]="contextFormGroup" *ngIf="contextFormGroup">

      <app-doc-validation-errors [validationErrors]="contextFormGroup.docLevelServerSideValidationErrors">
      </app-doc-validation-errors>

      <section class="form-block itech-block-medium">
        <h3>
          Общая информация
          <button type="button" class="btn btn-link" style="margin:0;padding:0;min-width:20px;"
                  (click)="changeFavourite()" [clrLoading]="changeFavouriteLoading">
            <ng-container *ngIf="!(((userService.getUserInfo()|async).favouriteStableIds || []).includes(id)); else isFavourite">
              <clr-icon shape="star" size="22" title="Добавить в избранное"></clr-icon>
            </ng-container>
            <ng-template #isFavourite>
              <clr-icon shape="star" class="is-solid is-warning" size="22" title="Удалить из избранного"></clr-icon>
            </ng-template>
          </button>
        </h3>

        <div class="form-group itech-group-whole-line">
          <label></label>
          <div class="itech-control-desc" [class.itech-desc-error]="contextFormGroup.get('deletedTime').value">
            Карточка создана:
            {{contextFormGroup.get('createdTime').value | rtime}},
            {{contextFormGroup.get('createdUser').value | lookup: 'user' | async}}
          </div>
          <div *ngIf="contextFormGroup.get('deletedTime').value" class="itech-control-desc itech-desc-error">
            ; Карточка удалена:
            {{contextFormGroup.get('deletedTime').value | rtime}},
            {{contextFormGroup.get('deletedUser').value | lookup: 'user' | async}}
          </div>
        </div>

        <div class="form-group">
          <label for="stableTypeId" class="required">Тип объекта</label>
          <app-combo-lookup [validationTooltipRight]="true" class="itech-control-xlarge"
                            [contextControlId]="'stableTypeId'"
                            [contextControlName]="'stableTypeId'"
                            [lookupName]="'stable-type'" [contextFormGroup]="contextFormGroup"></app-combo-lookup>
          <clr-icon *ngIf="syncCommonInfo['mercuryUuid']" shape="lock" style="color:gray;vertical-align:top;margin-top:10px;"></clr-icon>
        </div>

        <div class="form-group itech-double-height">
          <label for="caption" class="required">Наименование</label>
          <label for="caption" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                 [class.invalid]="isInvalid(contextFormGroup, 'caption')">
                <textarea type="text" id="caption"
                          placeholder="Введите наименование поднадзорного объекта, например 'Загон для кроликов'"
                          class="itech-control-xlarge" formControlName="caption">
                </textarea>
            <app-validation-tooltip [input]="contextFormGroup.controls['caption']"></app-validation-tooltip>
            <clr-icon *ngIf="syncCommonInfo['mercuryUuid']" shape="lock" style="color:gray;vertical-align:top;margin-top:16px;"></clr-icon>
          </label>
        </div>

        <div class="form-group">
          <label for="cadNo">Кадастровый номер участка</label>
          <label for="cadNo" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                 [class.invalid]="isInvalid(contextFormGroup, 'cadNo')">
            <input type="text" id="cadNo" placeholder="24:12:1234567:1" class="itech-control-xlarge"
                   formControlName="cadNo" [pattern]="cadNumberPattern">
            <app-validation-tooltip [input]="contextFormGroup.controls['cadNo']"></app-validation-tooltip>
          </label>
        </div>

        <div class="form-group">
          <label for="square">Площадь, кв. м.</label>
          <label for="square" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                 [class.invalid]="isInvalid(contextFormGroup, 'square')">
            <input type="text" id="square" placeholder="00000.00" class="itech-control-xlarge"
                   formControlName="square">
            <app-validation-tooltip [input]="contextFormGroup.controls['square']"></app-validation-tooltip>
          </label>
        </div>

        <div *ngFor="let item of codes.controls; index as i">
          <div class="form-group" >
            <label for="caption{{i}}">
              Номер (код) №{{i+1}}
            </label>
            <div class="itech-text-field itech-control-xlarge">{{item.value}}</div>
          </div>
        </div>

        <!--<div *ngFor="let item of activityTypeCaptions.controls; index as i">-->
          <!--<div class="form-group" >-->
            <!--<label for="activityTypeCaption{{i}}">-->
              <!--Вид деятельности №{{i+1}}-->
            <!--</label>-->
            <!--<label for="activityTypeCaption{{i}}" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"-->
                   <!--[class.invalid]="isInvalidControl(item)">-->
              <!--<input type="text" id="activityTypeCaption{{i}}" placeholder="Наименование вида деятельности"-->
                     <!--class="itech-control-xlarge" [formControl]="item">-->
              <!--<app-validation-tooltip [input]="item"></app-validation-tooltip>-->
            <!--</label>-->
            <!--<button type="button" class="btn btn-danger btn-link itech-inline-tool-btn" title="Удалить вид деятельности"-->
                    <!--(click)="deleteActivityTypeCaption(i)">-->
              <!--<clr-icon shape="trash"></clr-icon>-->
            <!--</button>-->
          <!--</div>-->
        <!--</div>-->

        <!--<button class="btn btn-link" (click)="addActivityTypeCaption()">-->
          <!--<clr-icon shape="plus"></clr-icon>&nbsp;Добавить вид деятельности-->
        <!--</button>-->

      </section>

      <section class="form-block itech-block-medium">
        <h3>
          Адрес поднадзорного объекта
          <clr-icon *ngIf="syncCommonInfo['mercuryUuid']" shape="lock" style="color:gray;"></clr-icon>
        </h3>
        <app-address [contextFormGroup]="contextFormGroup.get('address')" [required]="true"></app-address>
      </section>

      <section class="form-block itech-block-medium">
        <h3>Владелец
          <clr-icon *ngIf="syncCommonInfo['mercuryUuid']" shape="lock" style="color:gray;"></clr-icon>
        </h3>
        <div>
          <div class="form-group">
            <label class="required">Хозяйствующий субъект</label>
            <app-link-button [id]="contextFormGroup.controls['currentOwnerId'].value" [lookupName]="'agent'"></app-link-button>
            <button type="button" class="btn btn-danger btn-link itech-inline-tool-btn" title="Выбрать владельца"
                    (click)="searchOwner()" [disabled]="contextFormGroup.controls['id'].value? true: undefined">
              <clr-icon shape="search"></clr-icon>
            </button>
          </div>
        </div>
      </section>

      <section class="form-block itech-block-medium" *ngIf="contextFormGroup.controls['id'].value">
        <h3>Осуществляющие деятельность
          &nbsp;
          <app-btn-sync-web [iconName]="'refresh'" [caption]="'Синхронизировать'" (action)="runStableAgentSync()">
          </app-btn-sync-web>
        </h3>
        <ng-container *ngFor="let agent of agents.controls; index as i">
          <ng-container *ngIf="i < expandedAgentCounts">
            <div style="display: flex; flex-direction: row; margin-bottom: 10px">
              <app-edit-stable-agent-form style="flex: 1000 1 auto" [contextFormGroup]="agent"></app-edit-stable-agent-form>
            </div>
          </ng-container>
        </ng-container>
        <button type="button" style="text-transform:none;letter-spacing:0;"
                *ngIf="agents.controls.length > 2 && expandedAgentCounts < agents.controls.length"
                class="btn btn-link itech-inline-link-btn" (click)="expandedAgentCounts = expandedAgentCounts + 10">
          Показать еще {{agents.controls.length - expandedAgentCounts > 10 ? 10 : agents.controls.length - expandedAgentCounts}} записей
        </button>
        <button type="button" style="text-transform:none;letter-spacing:0;" *ngIf="expandedAgentCounts > 2"
                class="btn btn-link itech-inline-link-btn" (click)="expandedAgentCounts = 2">
          Скрыть
        </button>
      </section>
    </div>
  </div>
</div>
