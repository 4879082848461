import { Component, Input, OnChanges, HostBinding } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';

@Component({
  selector: 'app-query-lookup-list-filter',
  template: `
    <ng-container [formGroup]="contextFormGroup" *ngIf="contextFormGroup
    && (!queryUserMode || queryUserMode['_par_' + parameterName + '_filter_mq'])">
      <label>
      </label>
      <div class="checkbox checkbox-inline" [style.background]="filtered?'linear-gradient(to right, #90ff90, white)':undefined">
        <span style="color: black"><ng-content></ng-content></span>&nbsp;
        <input *ngIf="!queryUserMode" type="checkbox" id="{{'_par_' + parameterName + '_filter_mq'}}" [(ngModel)]="customFilterChecked"
               [ngModelOptions]="{standalone: true}">
        <label *ngIf="!queryUserMode" for="{{'_par_' + parameterName + '_filter_mq'}}" title="Запросить у пользователя">?</label>
      </div>
      <div>
        <app-combo-multilookup *ngIf="!useCheckboxes; else checkboxMode" class="itech-control-xlarge"
                               [itemTitle]="itemTitle" [lookupName]="lookupName" [sorted]="true"
                               [contextControlName]="'_par_' + parameterName" [contextFormGroup]="contextFormGroup">
        </app-combo-multilookup>
        <ng-template #checkboxMode>
          <app-checkbox-select class="itech-control-x-large" [disabled]="customFilterChecked || hasParentParamFilter"
                               [contextControlName]="'_par_' + parameterName" [lookupName]="lookupName" [sorted]="true"
                               [contextFormGroup]="contextFormGroup"></app-checkbox-select>
        </ng-template>
      </div>
    </ng-container>
  `
})
export class QueryLookupListFilterComponent {
  @Input() contextFormGroup: FormGroup;
  @Input() parameterName: string;
  @Input() lookupName: string;
  @Input() queryUserMode: false;
  @Input() hasParentParamFilter: false;
  @Input() useCheckboxes = true;
  @Input() itemTitle: string = 'Выбор';
  @HostBinding('attr.class') class = 'form-group itech-group-whole-line';

  isInvalid(cname: string) {
    return FormHelper.isInvalid(this.contextFormGroup, cname);
  }

  get customFilterChecked() {
    return this.contextFormGroup && (+this.contextFormGroup.get('_par_' + this.parameterName + '_filter').value) === 102;
  }

  set customFilterChecked(val: any) {
    if (this.contextFormGroup) {
      this.contextFormGroup.get('_par_' + this.parameterName + '_filter').setValue(val ? 102 : 0);
      this.contextFormGroup.markAsDirty();
    }
  }

  get filtered() {
    return !!this.contextFormGroup.get('_par_' + this.parameterName + '_filter').value
      || (!!this.contextFormGroup.get('_par_' + this.parameterName).value
        && !!this.contextFormGroup.get('_par_' + this.parameterName).value.length);
  }
}
