import { Component, EventEmitter, Input } from '@angular/core';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { InstitutionTicketDataService } from '../../../logic/services/institution-ticket-data.service';
import { StringHelper } from '../../../helpers/string-helper';
import { AgGridLookupRendererComponent } from '../../../ui/controls/ag-grid-lookup-renderer.component';
import { AgGridButtonRendererComponent } from '../../../ui/controls/ag-grid-button-renderer.component';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { TicketsPlanningUiService } from '../../edit-institution/tickets/tickets-planning-ui-service';

@Component({
  selector: 'app-animal-tickets',
  templateUrl: './edit-animal-tickets.component.html'
})
export class EditAnimalTicketsComponent {

  @Input() model: any = {};
  @Input() isExternal = false;

  ticketActionEmitter: EventEmitter<boolean> = undefined;

  private _id: number;
  @Input()
  get id(): number {
    return this._id;
  }

  set id(id: number) {
    if (this._id !== id) {
      this._id = id;
      this.refreshFormDataInternal();
    }
  }

  frameworkComponents: any = {
    lookupRenderer: AgGridLookupRendererComponent,
    buttonRenderer: AgGridButtonRendererComponent,
  };

  getRowStyle = EditAnimalTicketsComponent.getRowStyle;
  gridColumnDefs = [...EditAnimalTicketsComponent.getColumnDefs(),
    {
      headerName: 'ХС', width: 200,
      field: 'agentId',
      cellRenderer: 'lookupRenderer',
      cellRendererParams: (row) => {
        return {
          value: row.value,
          lookupName: 'agent',
          isPipe: true,
        };
      }
    },
  ];

  public static getColumnDefs(): any[] {
    return [
      {
        headerName: '№', field: 'id', width: 100,
      },
      {
        headerName: 'Время', width: 160,
        field: 'startTime',
        valueFormatter: params => StringHelper.getRuTime(params.value),
        cellRenderer: params =>
          (params.data.dateSuccessful
            ? '<clr-icon shape="check" style="color:green" title="Подтверждённый приём"></clr-icon>&nbsp;'
            : '') + StringHelper.getRuTime(params.value),
      },
      {
        headerName: 'Учреждение', width: 160,
        field: 'institutionId',
        cellRenderer: 'lookupRenderer',
        cellRendererParams: (row) => {
          return {
            value: row.value,
            lookupName: 'institution',
            isPipe: true,
          };
        }
      },
      {
        headerName: 'Филиал', width: 160,
        field: 'institutionBranchId',
        cellRenderer: 'lookupRenderer',
        cellRendererParams: (row) => {
          return {
            value: row.value,
            value2: row.data.institutionId,
            lookupName: 'institution-branch',
            isPipe: true,
          };
        }
      },
      {
        headerName: 'Врач', width: 160,
        field: 'institutionEmployeeId',
        cellRenderer: 'lookupRenderer',
        cellRendererParams: (row) => {
          return {
            value: row.value,
            value2: row.data.institutionId,
            lookupName: 'institution-employee',
            isPipe: true,
          };
        }
      },
    ];
  }

  public static getRowStyle(params) {
    if (!params || !params.data) {
      return;
    } else if (params.data.cancelTime) {
      return {color: 'silver'};
    }
  }

  constructor(private institutionTicketDataService: InstitutionTicketDataService,
              private dataCachingService: DataCachingService,
              private waitingOverlay: GlobalWaitingOverlayService,
              public navigationService: AppNavigationService,
              private alertService: AlertService,
              private ticketUiService: TicketsPlanningUiService) {
  }

  refreshFormData() {
    this.dataCachingService.removeCachedData('EditAnimalTickets' + (this.isExternal ? 'External' : ''), this.id.toString());
    this.refreshFormDataInternal();
  }

  refreshFormDataInternal() {
    this.waitingOverlay.StartWaiting();

    const existing = this.dataCachingService.getCachedData('EditAnimalTickets' + (this.isExternal ? 'External' : ''), this.id.toString());

    if (existing) {
      this.model = existing;
      this.waitingOverlay.EndWaiting();
    } else {
      this.institutionTicketDataService.searchByAnimal(this.id, this.isExternal)
        .subscribe({
          next: data => {

              data.sort((a, b) => b.startTime.localeCompare(b.startTime));
              this.model = {searchResults: data};
              this.dataCachingService.addToCache('EditAnimalTickets' + (this.isExternal ? 'External' : ''), this.id.toString(), this.model);
              this.waitingOverlay.EndWaiting();
          },
          error: () => {
            this.waitingOverlay.EndWaiting();
          }
        });
    }
  }

  public clickTicket(event: any) {
    this.ticketActionEmitter = new EventEmitter<boolean>();
    this.ticketUiService.editTicket(event, true, this.ticketActionEmitter);
    this.ticketActionEmitter.subscribe(emit => {
      if (emit) {
        this.refreshFormData();
      }
    });
  }
}
