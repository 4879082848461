import * as tslib_1 from "tslib";
import { Required } from '../../../ui/infrastructure/app-decotators';
import { StringHelper } from '../../../helpers/string-helper';
var EditInstitutionAgentMessageFormComponent = /** @class */ (function () {
    function EditInstitutionAgentMessageFormComponent() {
        this.getRuDatetime = StringHelper.getRuTime;
    }
    tslib_1.__decorate([
        Required,
        tslib_1.__metadata("design:type", Array)
    ], EditInstitutionAgentMessageFormComponent.prototype, "messages", void 0);
    return EditInstitutionAgentMessageFormComponent;
}());
export { EditInstitutionAgentMessageFormComponent };
