import { Component, Input } from '@angular/core';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { StringHelper } from '../../../helpers/string-helper';
import { DrugDataService } from '../../../logic/services/drug-data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { combineLatest, Observable, of } from 'rxjs';
import { EditDrugCommonFormComponent } from '../common/edit-drug-common-form.component';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-edit-drug-spending',
  templateUrl: './edit-drug-spending.component.html'
})
export class EditDrugSpendingComponent {

  @Input() model: any = {};

  isInvalid = FormHelper.isInvalid;
  changeDecimalStrValue = EditDrugCommonFormComponent.changeDecimalStrValue;
  writeoffModalOpened = false;
  moveModalOpened = false;
  currentFormGroup: FormGroup;

  processMoneyKeypress = FormHelper.processMoneyKeypress;

  gridApi;

  gridColumnDefs = [
    {
      headerName: 'Дата', field: 'date', width: 120,
      valueFormatter: params => StringHelper.getRuDate(params.value),
    },
    {
      headerName: 'Объект списания', field: 'caption', width: 400,
    },
    {
      headerName: 'Целевое назначение', field: 'reason', width: 250,
    },
    {
      headerName: 'Количество', field: 'spentValue', width: 110,
      valueFormatter: params => params.value + ' ' + this.model.unitTypeShortName
    },
    {
      headerName: 'Цена за ед.', field: 'cost', width: 110,
      valueFormatter: params => params.value
    },
    {
      headerName: 'Сумма', field: 'sum', width: 110,
      valueFormatter: params => params.value
    },
    {
      headerName: 'Кто списал', field: 'createdUserCaption', width: 180,
      valueFormatter: params => {
        if (params.data) {
          return params.data.createdTime
            ? params.data.createdUserCaption + ', ' + StringHelper.getRuTimeWithSeconds(params.data.createdTime)
            : params.data.createdUserCaption || 'Н/Д';
        } else {
          return params.value;
        }
      },
    },
    {
      headerName: 'Доступные действия',
      onCellClicked: params => !params.data.destinationDrugId && !params.data.animalEventId
        ? this.deleteWriteoff(this.id, params.data.date)
        : undefined,
      cellRenderer: params => !params.data.destinationDrugId && !params.data.animalEventId
        ? '<a class="in-table-link">Удалить</a>'
        : ''
    },
  ];

  private _id: number;
  @Input()
  get id(): number {
    return this._id;
  }

  set id(id: number) {
    if (this._id !== id) {
      this._id = id;
      this.refreshFormDataInternal();
    }
  }

  public get outerDrugFormGroup(): Observable<FormGroup> {
    const existing = this.dataCachingService.getCachedData('EditDrugCommon', this.id.toString());
    if (existing) {
      return of(existing.contextFormGroup);
    } else {
      return this.drugDataService.getDrugCommonForEdit(this.id)
        .pipe(map((drug) =>
          EditDrugCommonFormComponent.createFormGroup(this.fb, {serverSideValidationErrors: []}, drug)));
    }
  }

  constructor(private drugDataService: DrugDataService,
              private dataCachingService: DataCachingService,
              private waitingOverlay: GlobalWaitingOverlayService,
              public navigationService: AppNavigationService,
              private personFioCacheService: AddressPersonFioCacheService,
              private lookupSourceService: LookupSourceService,
              private alertService: AlertService,
              private fb: FormBuilder) {
  }

  refreshFormData() {
    this.dataCachingService.removeCachedData('EditDrugWriteoffs', this.id.toString());
    this.refreshFormDataInternal();
  }

  refreshFormDataInternal() {
    this.waitingOverlay.StartWaiting();

    const existing = this.dataCachingService.getCachedData('EditDrugWriteoffs', this.id.toString());

    if (existing) {
      this.model = existing;
      this.waitingOverlay.EndWaiting();
    } else {
      combineLatest([
        this.lookupSourceService.getLookupObj('drug-unit-type/drug'),
        this.lookupSourceService.getLookupObj('drug-package-type'),
        this.lookupSourceService.getLookupObj('drug-cost-type'),
        this.outerDrugFormGroup
    ]).subscribe(([unitTypeLookup, packageTypeLookup, drugCostTypeLookup, outDrugFg]) => {

        this.drugDataService.searchDrugSpending(this.id)
          .subscribe({
            next: data => {
              this.model = {searchResults: data};

              if (outDrugFg != null) {
                this.buildTypeName(outDrugFg, 'drugUnitTypeId', 'unitType',
                  unitTypeLookup, 'единиц', 'ед');
              }

              data.forEach(row => {
                this.personFioCacheService.getUser(row.createdUser).subscribe(userRow => {
                  row.createdUserCaption = userRow.caption;
                  const dcti = drugCostTypeLookup['Obj' + outDrugFg.get('costTypeId').value || 1];
                  row.cost = Number(outDrugFg.get('cost').value || 0).toFixed(3) + ' ' +
                    (dcti.shortCaption || dcti.caption);
                  row.sum = Number((row.spentValue || 0) * (outDrugFg.get('cost').value || 0)).toFixed(3) + ' ' +
                    (dcti.shortCaption || dcti.caption);
                  this.enqueeRedraw();
                });
              });

              this.dataCachingService.addToCache('EditDrugWriteoffs', this.id.toString(), this.model);
              this.waitingOverlay.EndWaiting();
            }, error: () => {
              this.waitingOverlay.EndWaiting();
            }
          });
      });
    }
  }

  buildTypeName(outDrugFg: FormGroup, controlName: string, modelName: string, lookup = {}, defName = '-', defShortName = '-') {
    const val = outDrugFg.get(controlName).value;
    if (val) {
      this.model[modelName + 'Name'] = ((lookup['Obj' + val] || {}).caption || '').toLowerCase();
      this.model[modelName + 'ShortName'] = (lookup['Obj' + val] || {}).shortCaption || '';
    } else {
      this.model[modelName + 'Name'] = defName;
      this.model[modelName + 'ShortName'] = defShortName;
    }
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
  }

  private redrawTimerId: any;

  private enqueeRedraw() {

    if (this.redrawTimerId) {
      clearTimeout(this.redrawTimerId);
    }

    this.redrawTimerId = setTimeout(() => {
      this.gridApi.redrawRows();
    }, 500);
  }

  navigate(data: any) {
    if (data.animalEventId) {
      this.navigationService.performAnimalEventEditing(data.animalEventId);
    } else if (data.destinationDrugId) {
      this.navigationService.performDrugEditing(data.destinationDrugId);
    }
  }

  addNewWriteoff() {
    this.currentFormGroup = this.fb.group({
      date: [StringHelper.getISODate(new Date()), Validators.compose([Validators.required, FormHelper.validateDateTimePicker()])],
      spentValue: [''],
      spentValueStr: ['', Validators.compose([Validators.required, FormHelper.validateDrugDecimal()])],
      actNo: ['', Validators.compose([Validators.required])],
      reasonText: '',
      recycleWay: '',
      drugId: this.id,
      toOtherSubject: false,
      animalType4VetA: undefined,
      eventsCount: ['', Validators.compose([Validators.pattern(/^\d{0,8}$/)])],
    });
    this.writeoffModalOpened = true;
  }

  addWriteoffCompleted() {
    FormHelper.markAsSubmitted(this.currentFormGroup);

    if (!this.currentFormGroup.valid) {
      return;
    }

    this.drugDataService.storeDrugWriteoff(this.currentFormGroup.value)
      .subscribe(() => {
        this.dataCachingService.removeCachedData('EditDrugCommon', this.id.toString());
        FormHelper.setSingleFormGroupServerSideValidationErrors({}, this.model, this.currentFormGroup);

        this.writeoffModalOpened = false;
        this.refreshFormData();
      }, err => FormHelper.setSingleFormGroupServerSideValidationErrors(err, this.model, this.currentFormGroup));
  }

  private deleteWriteoff(id: number, date: any) {
    this.alertService.confirmModal('Вы уверены, что хотите удалить списание?').subscribe(() => {
      this.drugDataService.deleteDrugWriteoff(id, date).subscribe(() => {
        this.dataCachingService.removeCachedData('EditDrugCommon', this.id.toString());
        this.refreshFormData();
      });
    });
  }

  addNewMove() {
    this.currentFormGroup = this.fb.group({
      date: [StringHelper.getISODate(new Date()), Validators.compose([Validators.required, FormHelper.validateDateTimePicker()])],
      value: [''],
      valueStr: ['', Validators.compose([Validators.required, FormHelper.validateDrugDecimal()])],
      packageCount: [1, Validators.compose([Validators.pattern(/^\d{1,10}$/)])],
      institutionId: ['', Validators.compose([Validators.required])],
      institutionBranchId: ['', Validators.compose([Validators.required])],
      drugId: this.id
    });

    let lastInstitutionValue;

    this.currentFormGroup.get('institutionId').valueChanges.subscribe(() => {
      if (lastInstitutionValue !== this.currentFormGroup.get('institutionId').value) {
        lastInstitutionValue = this.currentFormGroup.get('institutionId').value;
        this.currentFormGroup.get('institutionBranchId').setValue(undefined);
      }
    });

    this.moveModalOpened = true;
  }

  addMoveCompleted() {
    FormHelper.markAsSubmitted(this.currentFormGroup);

    if (!this.currentFormGroup.valid) {
      return;
    }

    const params = this.currentFormGroup.value;

    this.drugDataService.addMoving(params.date, params.drugId,
      params.value || 0, params.packageCount || 0,
      params.institutionId, params.institutionBranchId).subscribe(() => {
      this.dataCachingService.removeCachedData('EditDrugCommon', this.id.toString());
      FormHelper.setSingleFormGroupServerSideValidationErrors({}, this.model, this.currentFormGroup);

      this.moveModalOpened = false;
      this.refreshFormData();
    }, err => FormHelper.setSingleFormGroupServerSideValidationErrors(err, this.model, this.currentFormGroup));
  }

  animalType4VetAChanged($event: any) {
    if (!this.currentFormGroup.controls['animalType4VetA'].value) {
      this.currentFormGroup.controls['eventsCount'].setValue(null);
    }
  }
}
