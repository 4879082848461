<span *ngIf="indicatorTypeMeta && !editing" style="margin-left: 10px">
  <ng-container *ngIf="indicatorTypeMeta.levelFormula !== '-' || indicatorTypeMeta.formula
  || indicatorLevel.level === (indicatorTypeMeta.editableLevel || 60); else crossTemplate">
    {{formatRuNumeric(indicatorTypeMeta.precision, originalCellValue)}}
  </ng-container>
  <ng-template #crossTemplate>
    <span style="margin-right: 20px">X</span>
  </ng-template>
</span>
<ng-container *ngIf="indicatorTypeMeta && editing">
  <input #input type="text" maxlength="18" (keypress)="processMoneyKeypress($event)" [(ngModel)]="cellValueStr"
         (keydown)="inputKeyDown($event)"/>
</ng-container>
<div class="it-indicator-icon" *ngIf="indicatorTypeMeta && !editing && originalCellData && (originalCellData.state === 10 || originalCellData.state === 200)">
  <clr-tooltip>
    <clr-icon *ngIf="originalCellData.state === 10" clrTooltipTrigger shape="lock" style="color: navy"></clr-icon>
    <clr-icon *ngIf="originalCellData.state === 200" clrTooltipTrigger shape="check" style="color: green"></clr-icon>
    <clr-tooltip-content clrPosition="top-right" clrSize="lg" *clrIfOpen>
      {{getIndicatorHint()}}
      <br>
      Дата показателя: {{originalCellData.date}}
    </clr-tooltip-content>
  </clr-tooltip>
</div>
<div class="it-focus-border" *ngIf="focused" [class.it-cell-storing]="model.__cl_storing_indicator"
     [class.it-cell-error]="model.__cl_storing_error">
</div>
<div class="it-cell-error-text" *ngIf="focused && model.__cl_storing_error">
  {{model.__cl_storing_error}}
</div>
