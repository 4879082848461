import { ElementRef, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SecurityService } from '../../logic/services/security.service';
var AppInputTextComponent = /** @class */ (function () {
    function AppInputTextComponent(userService) {
        this.userService = userService;
        this.addNewTagModalOpened = new EventEmitter();
        this.keyDownEmitter = new EventEmitter();
        this.placeholder = '';
        this.disabled = false;
        this.keyPressCallback = function (event) { };
    }
    Object.defineProperty(AppInputTextComponent.prototype, "htmlTypeInput", {
        get: function () {
            return AppInputTextComponent.HTML_TYPE_INPUT;
        },
        enumerable: true,
        configurable: true
    });
    AppInputTextComponent.prototype.selectTag = function (tag) {
        var _this = this;
        this.formControlObj.setValue((this.formControlObj.value ? this.formControlObj.value + ' ' : '') + tag.value);
        this.formControlObj.markAsDirty();
        setTimeout(function () { return _this.textInput.nativeElement.focus(); }, 500);
    };
    AppInputTextComponent.prototype.addTag = function () {
        this.addNewTagModalOpened.emit({ value: this.formControlObj.value });
    };
    AppInputTextComponent.HTML_TYPE_INPUT = 1;
    AppInputTextComponent.HTML_TYPE_TEXTAREA = 2;
    return AppInputTextComponent;
}());
export { AppInputTextComponent };
