import { Component, Input, OnChanges } from '@angular/core';
import { StableDataService } from '../../../logic/services/stable-data.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { StringHelper } from '../../../helpers/string-helper';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { combineLatest, of } from 'rxjs';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { JobRunnerUiService } from '../../../ui/infrastructure/job-runner-ui.service';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-edit-stable-product-subtype',
  templateUrl: './edit-stable-product-subtype.component.html'
})
export class EditStableProductSubtypeComponent implements OnChanges {

  @Input() stableId;
  _searchResults = {all: [], withoutDeleted: []};

  currentFormGroup: FormGroup;
  currentIsNew = false;
  editModalOpened = false;
  isShowDeleted = false;
  storing = false;
  isInvalid = FormHelper.isInvalid;

  gridColumnDefs = [
    {
      headerName: 'Владелец ТМ', field: 'ownerAgentCaption', width: 200,
    },
    {
      headerName: 'Производитель', field: 'producerAgentCaption', width: 200,
    },
    {
      headerName: 'Тип продукции', field: 'productTypeCaption', width: 200,
    },
    {
      headerName: 'Подтип продукции', field: 'productSubtypeCaption', width: 200,
    },
    {
      headerName: 'Наименование', field: 'caption', width: 200,
    },
    {
      headerName: 'Меркурий guid', field: 'mercuryGuid', width: 200,
    },
    {
      headerName: 'Дата удаления', field: 'dateDeleted', width: 200,
      valueFormatter: params => StringHelper.getRuDate(params.value),
    },
    {
      headerName: 'Формула срока годности', field: 'calcExpireDate', width: 200,
    },
  ];

  constructor(public lookupService: LookupSourceService,
              public fb: FormBuilder,
              private stableDataService: StableDataService,
              private jobRunnerUiService: JobRunnerUiService,
              private alertService: AlertService,
              private cacheService: AddressPersonFioCacheService,
              private dataCachingService: DataCachingService) {
  }

  ngOnChanges() {
    const existing = this.dataCachingService.getCachedData('StableProductSubtype', this.stableId);
    if (existing) {
      this._searchResults = existing;
    } else {
      this.refresh();
    }
  }

  refresh() {
    this.dataCachingService.removeCachedData('StableProductSubtype', this.stableId);
    this.lookupService.invalidateLookup('stable-product-subtype/stable/false/' + this.stableId);
    this.lookupService.invalidateLookup('stable-product-subtype/stable/include-unknown/' + this.stableId, true);
    this.refreshResults();
  }

  getGroupDef(val: any) {
    return {
      stableId: val && val.stableId ? val.stableId : this.stableId,
      productTypeId: val.productTypeId,
      productSubtypeId: val.productSubtypeId,
      id: val.id,
      caption: val.caption,
      isDefault: val.isDefault,
      mercuryGuid: val.mercuryGuid,
      mercuryRecversionGuid: val.mercuryRecversionGuid,
      dateDeleted: val.dateDeleted,
      producerAgentId: val.producerAgentId,
      ownerAgentId: val.ownerAgentId,
      calcExpireDate: val.calcExpireDate,
    };
  }

  refreshResults() {
    this.currentIsNew = false;
    this.currentFormGroup = undefined;
    this.editModalOpened = false;

    combineLatest([this.lookupService.getLookupObj('product-subtype'),
      this.lookupService.getLookupObj('product-type'),
      this.stableDataService.getAllProductSubtypes(this.stableId)])
      .subscribe(([productSubtypeLookup, productTypeLookup, data]) => {
        data.forEach(el => {

          combineLatest([
            el.producerAgentId ? this.cacheService.getAgent(el.producerAgentId) : of(null),
            el.ownerAgentId ? this.cacheService.getAgent(el.ownerAgentId) : of(null)
          ])
            .subscribe(([producer, owner]) => {
              el.productTypeCaption = productTypeLookup[el.productTypeId];
              const productSubtypeRec = productSubtypeLookup[el.productTypeId].find(ptst => ptst.id === el.productSubtypeId);
              el.productSubtypeCaption = productSubtypeRec ? productSubtypeRec.caption : undefined;
              el.producerAgentCaption = producer ? producer.shortCaption || producer.caption : '-';
              el.ownerAgentCaption = owner ? owner.shortCaption || owner.caption : '-';
            });
        });
        this._searchResults.all = data;
        this._searchResults.withoutDeleted = data.filter(x => !x.dateDeleted);
        this.dataCachingService.addToCache('StableProductSubtype', this.stableId, this._searchResults);
      });
  }

  updateFromMercuryAndRefreshFormData() {
    this.jobRunnerUiService.runOperation(15, /* Актуализация номенклатуры поднадзорного объекта */
      {
        type: 'updateProductItemsStableSync',
        stableId: this.stableId
      }, undefined, undefined, true)
      .subscribe((data) => {
        this.alertService.success(data);
        this.refresh();
      });
  }

  getRowData() {
    return this.isShowDeleted ? this._searchResults.all : this._searchResults.withoutDeleted;
  }

  getRowStyle(params) {
    if (params.data && params.data.dateDeleted) {
      return {color: 'silver'};
    }
  }

  editRow($event: any) {
    this.currentFormGroup = this.fb.group(this.getGroupDef($event.data));
    this.editModalOpened = true;
  }

  storeRecord() {
    if (!this.currentFormGroup) {
      return;
    }

    FormHelper.markAsSubmitted(this.currentFormGroup);

    if (!this.currentFormGroup.valid) {
      return;
    }

    this.storing = true;
    this.stableDataService.storeStableProductSubtype(this.currentFormGroup.value.id, this.currentFormGroup.value)
      .pipe(finalize(() => this.storing = false))
      .subscribe(() => {
        this.refresh();
        this.currentFormGroup = undefined;
        this.editModalOpened = false;
      });
  }
}
