import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/internal/operators';
import { SecurityService } from './security.service';
import { StringHelper } from '../../helpers/string-helper';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./security.service";
var ProductDataService = /** @class */ (function () {
    function ProductDataService(http, securityService) {
        this.http = http;
        this.securityService = securityService;
    }
    ProductDataService.prototype.search = function (searchParams) {
        var qstring = environment.api + '/product/search';
        return this.http.post(qstring, searchParams)
            .pipe(map(function (response) {
            return response;
        }));
    };
    ProductDataService.prototype.getProductExpertises = function (productId) {
        var qstring = environment.api + '/product-expertise/search/' + productId;
        return this.http.post(qstring, {}, { headers: { 'interceptNoAccess': 'true' } })
            .pipe(map(function (response) {
            return response;
        }));
    };
    ProductDataService.prototype.searchBySourceProduct = function (productId) {
        var qstring = environment.api + '/product/search-by-source-product/' + productId;
        return this.http.get(qstring, { headers: { 'interceptNoAccess': 'true' } })
            .pipe(map(function (response) {
            return response;
        }));
    };
    ProductDataService.prototype.getProductCommonForEdit = function (id) {
        var qstring = environment.api + '/product/' + id.toString(10);
        return this.http.get(qstring, { headers: { 'interceptNoAccess': 'true' } })
            .pipe(map(function (item) {
            return item.data;
        }));
    };
    ProductDataService.prototype.getProductCommonForEdit2 = function (ids) {
        var qstring = environment.api + '/product';
        return this.http.post(qstring, ids)
            .pipe(map(function (item) {
            return item.data;
        }));
    };
    ProductDataService.prototype.backgroundUpdateProductFromMercury = function (id) {
        var qstring = environment.api + '/product/background-update-from-mercury/' + id.toString(10);
        return this.http.get(qstring)
            .pipe(map(function (item) {
            return item;
        }));
    };
    ProductDataService.prototype.backgroundUpdateProductFromMercury2 = function (ids) {
        var qstring = environment.api + '/product/background-update-from-mercury/';
        return this.http.post(qstring, ids)
            .pipe(map(function (item) {
            return item;
        }));
    };
    ProductDataService.prototype.getProductExpertiseForEdit = function (productId, expertiseId) {
        var qstring = environment.api + '/product-expertise/' + productId.toString() + '/' + expertiseId.toString();
        return this.http.get(qstring).pipe(map(function (item) {
            return item.data;
        }));
    };
    ProductDataService.prototype.getProductCommonForLookup = function (id) {
        var qstring = environment.api + '/product/lookup/' + id.toString(10);
        return this.http.get(qstring);
    };
    ProductDataService.prototype.getProductCommonForLookup2 = function (ids) {
        var qstring = environment.api + '/product/lookup';
        return this.http.post(qstring, ids)
            .pipe(map(function (response) {
            return response;
        }));
    };
    ProductDataService.prototype.getProductExpertiseForLookup = function (ids) {
        var qstring = environment.api + '/product-expertise/lookup';
        return this.http.post(qstring, ids)
            .pipe(map(function (response) {
            return response;
        }));
    };
    ProductDataService.prototype.storeProductCommonInfo = function (model) {
        var _this = this;
        var retVal = new Subject();
        this.securityService.getUserInfo().subscribe(function (userInfo) {
            var dataToStore = model;
            if (+dataToStore.templateVisibility === 2) {
                dataToStore.templateInstitutionId = userInfo.institutionId;
                dataToStore.templateInstitutionEmployeeId = undefined;
            }
            else if (+dataToStore.templateVisibility === 3) {
                dataToStore.templateInstitutionId = userInfo.institutionId;
                dataToStore.templateInstitutionEmployeeId = userInfo.institutionEmployeeId;
            }
            else {
                dataToStore.templateInstitutionId = undefined;
                dataToStore.templateInstitutionEmployeeId = undefined;
            }
            var qstring = environment.api + '/product/store-common';
            _this.http.post(qstring, model).subscribe(function (response) {
                retVal.next(response.data);
            }, function (error) {
                retVal.error(error);
            });
        });
        return retVal;
    };
    ProductDataService.prototype.checkRegionalization = function (model) {
        var qstring = environment.api + '/product/check-regionalization';
        return this.http.post(qstring, model)
            .pipe(map(function (response) {
            return response;
        }));
    };
    ProductDataService.prototype.getForCreateProductExpertise = function (expertiseId, productId) {
        var qstring = environment.api + '/product-expertise/get-for-create';
        return this.http.post(qstring, { expertiseId: expertiseId, productId: productId })
            .pipe(map(function (response) {
            return response;
        }));
    };
    ProductDataService.prototype.storeProductExpertiseInfo = function (model, startSync) {
        var qstring = environment.api + '/product-expertise/store-common' + (startSync ? '/with-sync' : '');
        return this.http.post(qstring, model).pipe(map(function (item) {
            return item.data;
        }));
    };
    ProductDataService.prototype.deleteProduct = function (productId) {
        var qstring = environment.api + '/product/delete/' + productId.toString();
        return this.http.post(qstring, {});
    };
    ProductDataService.prototype.deleteProductExpertise = function (productId, expertiseId) {
        var qstring = environment.api + '/product-expertise/delete/' + productId.toString() + '/' + expertiseId.toString();
        return this.http.post(qstring, {});
    };
    ProductDataService.prototype.sendToRegistration = function (productIds) {
        var qstring = environment.api + '/product/send-to-registration';
        return this.http.post(qstring, { productIds: productIds });
    };
    ProductDataService.prototype.sendToDeletion = function (productId, reason) {
        var qstring = environment.api + '/product/send-to-deletion/' + productId.toString();
        return this.http.post(qstring, reason);
    };
    ProductDataService.prototype.sendToClearance = function (data) {
        var qstring = environment.api + '/product/send-to-clearance';
        return this.http.post(qstring, data);
    };
    ProductDataService.prototype.getStableProducts = function (stableId, dateFrom, dateTo) {
        var qstring = environment.api + '/product/search-by-stable/' + stableId + '/' +
            StringHelper.getISODate(dateFrom) + '/' + StringHelper.getISODate(dateTo);
        return this.http.get(qstring, { headers: { 'interceptNoAccess': 'true' } })
            .pipe(map(function (response) {
            return response;
        }));
    };
    ProductDataService.prototype.getStableProductsTemplates = function (stableId) {
        var qstring = environment.api + '/product/search-by-stable/templates/' + stableId;
        return this.http.get(qstring)
            .pipe(map(function (response) {
            return response;
        }));
    };
    ProductDataService.prototype.getAnimalProducts = function (animalId, showHistory) {
        var qstring = environment.api + '/product/search-by-animal/' + animalId + '/' + !!showHistory;
        return this.http.get(qstring, { headers: { 'interceptNoAccess': 'true' } })
            .pipe(map(function (response) {
            return response;
        }));
    };
    ProductDataService.prototype.getProductForCreate = function (params) {
        var qstring = environment.api + '/product/get-for-create';
        return this.http.post(qstring, params).pipe(map(function (item) {
            return item.data;
        }));
    };
    ProductDataService.prototype.getTransportDocumentForCreate = function (transactionTypeId, sourceProductId) {
        var qstring = environment.api + ("/product/get-transport-document-for-create/" + transactionTypeId + "/" + sourceProductId);
        return this.http.post(qstring, {}).pipe(map(function (item) {
            return item.data;
        }));
    };
    ProductDataService.prototype.getTransportDocumentForCreate2 = function (transactionTypeId, sourceProductIds) {
        var qstring = environment.api + ("/product/get-transport-document-for-create/" + transactionTypeId);
        return this.http.post(qstring, sourceProductIds).pipe(map(function (item) {
            return item.data;
        }));
    };
    ProductDataService.prototype.getByTransactionId = function (transactionId) {
        var qstring = environment.api + '/product/by-transaction/' + transactionId.toString();
        return this.http.get(qstring)
            .pipe(map(function (response) {
            return response;
        }));
    };
    ProductDataService.prototype.startSyncDecisionMakingProductRequest = function (data) {
        var qstring = environment.api + '/product/product-request/decision-making';
        return this.http.post(qstring, data);
    };
    ProductDataService.ngInjectableDef = i0.defineInjectable({ factory: function ProductDataService_Factory() { return new ProductDataService(i0.inject(i1.HttpClient), i0.inject(i2.SecurityService)); }, token: ProductDataService, providedIn: "root" });
    return ProductDataService;
}());
export { ProductDataService };
