import { Component, Input, OnInit } from '@angular/core';
import { AppNavigationService } from '../../logic/services/app-navigation.service';
import { QuarantineDataService } from '../../logic/services/quarantine-data.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { EditQuarantineCommonComponent } from './common/edit-quarantine-common.component';
import { AppFormGroup } from '../../ui/controls/app-form-group';
import { FormHelper } from '../../ui/controls/form-helper';
import { DataCachingService } from '../../logic/services/data-caching.service';
import { BookmarkService } from '../../logic/services/bookmark.service';
import { StringHelper } from '../../helpers/string-helper';

@Component({
  selector: 'app-create-quarantine',
  templateUrl: './create-quarantine.component.html'
})
export class CreateQuarantineComponent implements OnInit {

  @Input() model: any;
  @Input() serverSideValidationErrors: [];

  creating = false;

  get contextFormGroup(): AppFormGroup {
    return this.model ? this.model.form : undefined;
  }

  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private quarantineService: QuarantineDataService,
              private navigationService: AppNavigationService,
              private bookmarkService: BookmarkService,
              private dataCachingService: DataCachingService) {
  }

  ngOnInit() {
    this.route.paramMap
      .subscribe(params => {

        const randomUniqueTag = params.get('randomUniqueTag');
        const paramDiseaseTypeId = params.get('diseaseTypeId') ? +params.get('diseaseTypeId') : undefined;

        const existing = this.dataCachingService.getCachedData('CreateQuarantine', randomUniqueTag);

        if (!existing) {
          this.model = {
            randomUniqueTag: randomUniqueTag,
            serverSideValidationErrors: this.serverSideValidationErrors
          };

          this.model.form = EditQuarantineCommonComponent.createFormGroupCommon(this.fb, this.model, {
            diseaseTypeId: paramDiseaseTypeId,
          });

          this.dataCachingService.addToCache('CreateQuarantine', randomUniqueTag, this.model, 10);
        } else {
          this.model = existing;
        }

        this.refreshCurrentBookmark();
      });
  }

  createQuarantine() {
    FormHelper.markAsSubmitted(this.contextFormGroup);

    if (this.contextFormGroup.invalid) {
      FormHelper.setFocusToInvalidControl(this.contextFormGroup);
      return;
    }

    this.quarantineService.storeCommon(this.contextFormGroup.value)
      .subscribe(res => {
        FormHelper.setSingleFormGroupServerSideValidationErrors({}, this.model, this.contextFormGroup);
        this.bookmarkService.removeOpenCardBookmark(['/quarantine-create'], this.model.randomUniqueTag);
        this.dataCachingService.removeCachedData('CreateQuarantine', this.model.randomUniqueTag);
        this.dataCachingService.addToCache('EditQuarantine_Data', res.data.data.id.toString(), res.data);
        this.navigationService.performQuarantineEditing(res.data.data.id);
      }, error => FormHelper.setSingleFormGroupServerSideValidationErrorsWithRelationMsgKey(error, this.model, this.contextFormGroup));
  }

  refreshCurrentBookmark(skipIfNotExists?: boolean) {
    this.bookmarkService.addOpenCardBookmark(this.model.randomUniqueTag,
      `Новый карантин` +
      (this.model.form.get('dateFrom').value ? ' от ' + StringHelper.getRuDate(this.model.form.get('dateFrom').value) : ''),
      ['/quarantine-create', this.model.randomUniqueTag], 'flame', skipIfNotExists);
  }
}
