
      <div class="itech-common-wrap" id="interceptNoAccess">
        <div id="agent-common-content">
          <div class="itech-doc-container" style="min-height:100%">
            <ng-container *ngIf="reservedDrugs && reservedDrugs.length; else noReservedDrugs">
              <div style="padding:5px;width:100%;min-height:88%">
                <app-grid [rowData]="reservedDrugs" [columnDefs]="gridColumnDefs" (rowClicked)="navigateToDrug($event.data.id)"
                          [frameworkComponents]="frameworkComponents" [uniqueGridName]="'ReservedUserGrid'"
                          [exportFileName]="'Выданные препараты ' + (userService.getUserInfo()|async).id">
                </app-grid>
              </div>
            </ng-container>
            <ng-template #noReservedDrugs>
              У Вас не имеется выданных препаратов
            </ng-template>
          </div>
        </div>

        <div class="itech-form-actions">
          <div>
            <button class="btn btn-link btn-primary" (click)="refreshFormData()">
              <clr-icon shape="refresh" class="has-badge"></clr-icon>&nbsp;Обновить список
            </button>
          </div>
        </div>
      </div>
  