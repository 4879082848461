/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./drug-search-results-form.component.ngfactory";
import * as i2 from "./drug-search-results-form.component";
import * as i3 from "../../../logic/services/drug-data.service";
import * as i4 from "../../../ui/infrastructure/global-waiting-overlay.service";
import * as i5 from "../../../logic/services/app-navigation.service";
import * as i6 from "../../../logic/services/lookup-source.service";
import * as i7 from "../../../logic/services/security.service";
import * as i8 from "./drug-search-modal.component";
import * as i9 from "@angular/forms";
var styles_DrugSearchModalComponent = [];
var RenderType_DrugSearchModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DrugSearchModalComponent, data: {} });
export { RenderType_DrugSearchModalComponent as RenderType_DrugSearchModalComponent };
export function View_DrugSearchModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["style", "height: 80vh; background-color: white; position: relative;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-drug-search-results-form", [], null, [[null, "rowClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("rowClicked" === en)) {
        var pd_0 = (_co.chooseDrug($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_DrugSearchResultsFormComponent_0, i1.RenderType_DrugSearchResultsFormComponent)), i0.ɵdid(2, 49152, null, 0, i2.DrugSearchResultsFormComponent, [i3.DrugDataService, i4.GlobalWaitingOverlayService, i5.AppNavigationService, i6.LookupSourceService, i7.SecurityService], { model: [0, "model"], autoChangeExpanded: [1, "autoChangeExpanded"] }, { rowClicked: "rowClicked" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model; var currVal_1 = true; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_DrugSearchModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_DrugSearchModalComponent_0, RenderType_DrugSearchModalComponent)), i0.ɵdid(1, 114688, null, 0, i8.DrugSearchModalComponent, [i5.AppNavigationService, i9.FormBuilder], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DrugSearchModalComponentNgFactory = i0.ɵccf("ng-component", i8.DrugSearchModalComponent, View_DrugSearchModalComponent_Host_0, {}, {}, []);
export { DrugSearchModalComponentNgFactory as DrugSearchModalComponentNgFactory };
