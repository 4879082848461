import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { QuarantineDataService } from '../../../logic/services/quarantine-data.service';
import { StringHelper } from '../../../helpers/string-helper';
import { DateHelper } from '../../../helpers/date-helper';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { combineLatest } from 'rxjs';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { SecurityService } from '../../../logic/services/security.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-quarantine-search-results',
  templateUrl: './quarantine-search-results.component.html'
})
export class QuarantineSearchResultsComponent implements OnInit, OnChanges {

  @Input() model: any = {};

  rowData = [];
  rowDataWithViolations = [];

  onlyWithViolations = false;

  get filterFormGroup(): FormGroup {
    return this.model ? this.model.filterFormGroup : undefined;
  }

  isInvalid = FormHelper.isInvalid;
  getRowStyle = QuarantineSearchResultsComponent.getRowStyle;

  gridColumnDefs = [
    {
      headerName: '', field: 'isViolationDeadlineFile', excludeExport: true, width: 40,
      cellRenderer: params => {
        if (!params.value) {
          return '<clr-icon shape="check" style="color:green"></clr-icon>';
        } else {
          return '<clr-icon shape="history" style="color:red" title="Нарушены сроки прикрепления документов"></clr-icon>';
        }
      },
    },
    {
      headerName: '№', field: 'id', width: 75,
    },
    {
      headerName: 'Заболевание', field: 'diseaseCaption', width: 195,
    },
    {
      headerName: 'Неблагополучный пункт', field: 'addressCaption', width: 285,
      filterParams: {
        cellRenderer: params => {
          if (params.value) {
            return '<div title="' + StringHelper.saveQuotes(params.value) + '">' + params.value + '</div>';
          } else {
            return '(Пусто)';
          }
        }
      },
    },
    {
      headerName: 'Срок действия', width: 155,
      valueGetter: params => {
        if (!params || !params.data || !params.data.dateFrom) {
          return '';
        }

        return 'с ' + StringHelper.getRuDate(DateHelper.toDate(params.data.dateFrom)) +
          (params.data.dateToInclusive ? ' по ' + StringHelper.getRuDate(DateHelper.toDate(params.data.dateToInclusive)) : '');
      },
      comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
        if ((!nodeA || !nodeA.data) && (!nodeB || !nodeB.data)) {
          return 0;
        }
        if (!nodeA.data.dateFrom && !nodeB.data.dateFrom) {
          return 0;
        }
        if (!nodeA.data.dateFrom) {
          return -1;
        }
        if (!nodeB.data.dateFrom) {
          return 1;
        }
        return DateHelper.isAfter(nodeA.data.dateFrom, nodeB.data.dateFrom);
      }
    },
    {
      headerName: 'Количество попавших под карантин', width: 155,
      valueGetter: params => {
        if (!params || !params.data) {
          return 0;
        }

        return (+params.data.countTrappedStable || 0) + (+params.data.countTrappedAnimal || 0);
      },
      cellRenderer: params => {
        if (!params || !params.data) {
          return '';
        }

        return `<clr-icon shape="building" style="color:green"></clr-icon>
                &nbsp;${params.data.countTrappedStable || 0}&nbsp;&nbsp;
                <clr-icon shape="piggy-bank" style="color:green"></clr-icon>
                &nbsp;${params.data.countTrappedAnimal || 0}`;
      }
    },
    {
      headerName: 'Статус', field: 'statusCaption', width: 170,
    },
  ];

  public static getDefaultModel(fb?: FormBuilder, defaultFilterParams?: any) {
    return {
      searchResults: [],
      selectedRows: [],
      filterFormGroup: fb ? QuarantineSearchResultsComponent.buildSearchForm(fb, defaultFilterParams) : undefined
    };
  }

  public static buildSearchForm(fb: FormBuilder, defaultFilterParams: any = {}) {

    return fb.group({
      cadNo: defaultFilterParams.cadNo,
      regionId: defaultFilterParams.regionId,
      cityId: defaultFilterParams.cityId,
      diseaseTypeId: defaultFilterParams.diseaseTypeId,
      dateFrom: defaultFilterParams.dateFrom,
      dateToInclusive: defaultFilterParams.dateToInclusive,
      noFilteredByRights: defaultFilterParams.noFilteredByRights,
      currentStatusId: defaultFilterParams.currentStatusId,
      forceStartSearch: defaultFilterParams.forceStartSearch || false,
    });
  }

  public static getRowStyle(params) {

    if (params.data && params.data.currentStatusId === 0) {
      return {color: 'black'};
    }

    if (params.data && params.data.currentStatusId === 5) {
      return {color: 'silver'};
    }

    return {color: 'green'};
  }

  constructor(private waitingOverlay: GlobalWaitingOverlayService,
              private quarantineService: QuarantineDataService,
              private navigationService: AppNavigationService,
              private lookupService: LookupSourceService,
              private dataCachingService: DataCachingService,
              public securityService: SecurityService,
              private route: ActivatedRoute,
              private fb: FormBuilder) {
  }

  ngOnInit() {

    this.route.paramMap
      .subscribe(() => {
        const existing = this.dataCachingService.getCachedData('QuarantineSearchResults', '1');

        if (existing) {
          this.model = existing;
          this.rowData = this.model.searchResults;
          if (this.model.filterFormGroup && this.model.filterFormGroup.get('forceStartSearch').value) {
            this.refreshResults();
          }
        } else {
          this.model = QuarantineSearchResultsComponent.getDefaultModel(this.fb);
          this.dataCachingService.addToCache('QuarantineSearchResults', '1', this.model);
        }
      });
  }

  ngOnChanges() {
    const existing = this.dataCachingService.getCachedData('QuarantineSearch', 'model');
    this.rowDataWithViolations = this.dataCachingService.getCachedData('QuarantineSearch', 'rowDataWithViolations') || [];

    if (existing && existing.searchResults) {
      this.model = existing;
      this.onlyWithViolations = this.dataCachingService.getCachedData('QuarantineSearch', 'paramsVisibleViolations') || false;

      this.rowData = this.onlyWithViolations ? this.rowDataWithViolations : this.model.searchResults;
    }
  }

  refreshResults() {

    if (!this.model.filterFormGroup.valid) {
      return;
    }

    this.waitingOverlay.StartWaiting();

    this.quarantineService.search(this.model.filterFormGroup.value)
      .subscribe(data => {

        if (!data.length) {
          this.model.searchResults = [];
          this.model.selectedRows = [];
          this.rowData = [];
          this.rowDataWithViolations = [];
          this.waitingOverlay.EndWaiting();
          return;
        }

        data.forEach((val, ix) =>
          combineLatest(
              [this.lookupService.getLookupCaption(val.diseaseTypeId, 'disease-type'),
                this.lookupService.getLookupCaption(val.currentStatusId, 'quarantine-status'),
                this.lookupService.getLookupCaption(val.badLocalityRegionId, 'addr-by-region-city-ids', null, val.badLocalityCityId)])
            .subscribe(([diseaseCaption, statusCaption, addressCaption]) => {
              val['diseaseCaption'] = diseaseCaption;
              val['addressCaption'] = addressCaption;
              val['statusCaption'] = statusCaption;

              if (ix === data.length - 1) {
                this.model.searchResults = data;
                this.model.selectedRows = [];
                this.rowDataWithViolations = [];
                data.forEach(el => {
                  if (el.isViolationDeadlineFile) {
                    this.rowDataWithViolations.push(el);
                  }
                });
                this.rowData = this.onlyWithViolations ? this.rowDataWithViolations : data;

                this.dataCachingService.addToCache('QuarantineSearch', 'model', this.model);
                this.dataCachingService.addToCache('QuarantineSearch', 'rowDataWithViolations', this.rowDataWithViolations);

                this.waitingOverlay.EndWaiting();
              }
        }));
      }, () => {
        this.waitingOverlay.EndWaiting();
      });
  }

  getLookupNameForCity() {
    return 'addr-city' +
      (this.model.filterFormGroup && this.model.filterFormGroup.get('regionId').value
        ? this.model.filterFormGroup.get('regionId').value : '');
  }

  performQuarantineCard(quarantineData: any) {
    this.navigationService.performQuarantineEditing(quarantineData.id);
  }

  filterEnterPressed($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      this.refreshResults();
    }
  }

  changeRowData() {
    this.dataCachingService.addToCache('QuarantineSearch', 'paramsVisibleViolations', this.onlyWithViolations);
    if (this.onlyWithViolations) {
      this.rowData = this.rowDataWithViolations;
    } else {
      this.rowData = this.model.searchResults;
    }
  }
}
