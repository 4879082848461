<div class="itech-search-table-with-filter" *ngIf="securityService.isAccess('drug') | async">
  <div style="display: flex; flex-direction: row;">
    <clr-icon shape="search" [attr.size]="expandedParams ? 72 : 24"></clr-icon>
    <div class="compact form itech-highlighted-sections" [formGroup]="model.filterFormGroup"
         *ngIf="model && model.filterFormGroup">
      <section class="form-block itech-block-medium">
        <h3 style="display: flex">
          Поиск препаратов
          <button type="button" class="btn btn-outline"
                  style="background: none;border: none;margin: 0 0 0 10px;text-transform: none;color: black;
                  letter-spacing:normal;min-width:15px;max-width:15px;max-height:24px;padding:0;display:flex;align-items:end"
                  (click)="expandedParams = !expandedParams">
            <clr-icon *ngIf="expandedParams" shape="angle up"></clr-icon>
            <clr-icon *ngIf="!expandedParams" shape="angle down"></clr-icon>
          </button>
        </h3>
        <ng-container *ngIf="expandedParams">
          <div class="form-group">
              <label style="display:flex;align-items:center">
                <button class="btn btn-icon-link" style="margin:0;padding:0;max-height:20px;min-width:20px;border:none"
                        (click)="model.filterFormGroup.get('toggleCaptionOrDisease').setValue(!model.filterFormGroup.get('toggleCaptionOrDisease').value)">
                  <clr-icon shape="switch" style="display:block" class="has-badge"></clr-icon>
                </button>
                {{!model.filterFormGroup.get('toggleCaptionOrDisease').value ? 'Наименование / тип' : 'Заболевание'}}
              </label>
            <div *ngIf="!model.filterFormGroup.get('toggleCaptionOrDisease').value" style="display:flex">
              <app-text-lookup [validationTooltipRight]="true" class="itech-control-medium"
                               [contextControlId]="'drugTypeId'" [contextControlName]="'drugTypeId'"
                               [commentsFieldName]="'comments'" [sorted]="true"
                               [lookupName]="'drug-type'" [contextFormGroup]="model.filterFormGroup"></app-text-lookup>
              &nbsp;&nbsp;&nbsp;
              <app-text-lookup [validationTooltipRight]="true" class="itech-control-medium"
                               [contextControlId]="'drugKindId'" [contextControlName]="'drugKindId'"
                               [sorted]="true"
                               [lookupName]="'drug-kind'" [contextFormGroup]="model.filterFormGroup"></app-text-lookup>
            </div>
            <div *ngIf="model.filterFormGroup.get('toggleCaptionOrDisease').value" style="display:flex">
              <app-text-lookup class="itech-control-medium" [validationTooltipRight]="true"
                               [contextControlId]="'diseaseTypeId'" [sorted]="true"
                               [contextControlName]="'diseaseTypeId'" [lookupName]="'disease-type'"
                               [contextFormGroup]="model.filterFormGroup"></app-text-lookup>
              &nbsp;&nbsp;&nbsp;
              <app-text-lookup class="itech-control-medium" [validationTooltipRight]="true"
                               [contextControlId]="'eventTypeId'" [sorted]="true"
                               [contextControlName]="'eventTypeId'" [lookupName]="'event-type'"
                               [contextFormGroup]="model.filterFormGroup"></app-text-lookup>
            </div>
          </div>

          <div class="form-group">
            <label for="serial">Серия/производитель/№ р/у</label>
            <label for="serial" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                   [class.invalid]="isInvalid(model.filterFormGroup, 'serial')">
              <input type="text" id="serial" placeholder="Серия" class="itech-control-normal"
                     formControlName="serial" (keydown)="filterEnterPressed($event)">
              <app-validation-tooltip [input]="model.filterFormGroup.controls['serial']"></app-validation-tooltip>
            </label>
            &nbsp;&nbsp;&nbsp;
            <label for="manufacturerCaption" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                   [class.invalid]="isInvalid(model.filterFormGroup, 'manufacturerCaption')">
              <input type="text" id="manufacturerCaption" placeholder="Производитель" class="itech-control-medium"
                     formControlName="manufacturerCaption" (keydown)="filterEnterPressed($event)" style="width:200px">
              <app-validation-tooltip [input]="model.filterFormGroup.controls['manufacturerCaption']"></app-validation-tooltip>
            </label>
            &nbsp;&nbsp;&nbsp;
            <label for="registryNo" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                   [class.invalid]="isInvalid(model.filterFormGroup, 'registryNo')">
              <input type="text" id="registryNo" placeholder="№ рег.удост." class="itech-control-medium"
                     formControlName="registryNo" (keydown)="filterEnterPressed($event)" style="width:200px">
              <app-validation-tooltip [input]="model.filterFormGroup.controls['registryNo']"></app-validation-tooltip>
            </label>
          </div>
          <div class="form-group">
            <label>
              Период произв./срока годности
            </label>
            <div style="display: flex">
              <label for="manufacturingFrom" aria-haspopup="true" role="tooltip"
                     class="tooltip tooltip-validation tooltip-md"
                     [class.invalid]="isInvalid(model.filterFormGroup, 'manufacturingFrom')">
                <input type="date" id="manufacturingFrom" class="itech-control-normal"
                       formControlName="manufacturingFrom">
                <app-validation-tooltip
                  [input]="model.filterFormGroup.controls['manufacturingFrom']"></app-validation-tooltip>
              </label>
              &nbsp;&nbsp;&nbsp;
              <label for="manufacturingTo" aria-haspopup="true" role="tooltip"
                     class="tooltip tooltip-validation tooltip-md"
                     [class.invalid]="isInvalid(model.filterFormGroup, 'manufacturingTo')">
                <input type="date" id="manufacturingTo" class="itech-control-normal"
                       formControlName="manufacturingTo">
                <app-validation-tooltip
                  [input]="model.filterFormGroup.controls['manufacturingTo']"></app-validation-tooltip>
              </label>
              &nbsp;
              <label>/</label>
              &nbsp;
              <label for="expirationDateFrom" aria-haspopup="true" role="tooltip"
                     class="tooltip tooltip-validation tooltip-md"
                     [class.invalid]="isInvalid(model.filterFormGroup, 'expirationDateFrom')">
                <input type="date" id="expirationDateFrom" class="itech-control-normal"
                       formControlName="expirationDateFrom">
                <app-validation-tooltip
                  [input]="model.filterFormGroup.controls['expirationDateFrom']"></app-validation-tooltip>
              </label>
              &nbsp;&nbsp;&nbsp;
              <label for="expirationDateTo" aria-haspopup="true" role="tooltip"
                     class="tooltip tooltip-validation tooltip-md"
                     [class.invalid]="isInvalid(model.filterFormGroup, 'expirationDateTo')">
                <input type="date" id="expirationDateTo" class="itech-control-normal"
                       formControlName="expirationDateTo">
                <app-validation-tooltip
                  [input]="model.filterFormGroup.controls['expirationDateTo']"></app-validation-tooltip>
              </label>
            </div>
          </div>
          <div class="form-group">
            <label style="display:flex;align-items:center">
              <button class="btn btn-icon-link" style="margin:0;padding:0;max-height:20px;min-width:20px;border:none"
                      (click)="model.filterFormGroup.get('toggleActOrIncoming').setValue(!model.filterFormGroup.get('toggleActOrIncoming').value)">
                <clr-icon shape="switch" style="display:block" class="has-badge"></clr-icon>
              </button>
              {{model.filterFormGroup.get('toggleActOrIncoming').value ? 'Номер/период акта' : 'Период поступления/выдачи'}}
            </label>
            <div *ngIf="model.filterFormGroup.get('toggleActOrIncoming').value" style="display:flex">
              <label for="actNumber" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                     [class.invalid]="isInvalid(model.filterFormGroup, 'actNumber')">
                <input type="text" id="actNumber" placeholder="Номер акта" class="itech-control-small"
                       formControlName="actNumber" (keydown)="filterEnterPressed($event)">
                <app-validation-tooltip [input]="model.filterFormGroup.controls['actNumber']"></app-validation-tooltip>
              </label>
              &nbsp;
              <label for="actDateFrom" aria-haspopup="true" role="tooltip"
                     class="tooltip tooltip-validation tooltip-md"
                     [class.invalid]="isInvalid(model.filterFormGroup, 'actDateFrom')">
                <input type="date" id="actDateFrom" class="itech-control-normal" formControlName="actDateFrom">
                <app-validation-tooltip [input]="model.filterFormGroup.controls['actDateFrom']"></app-validation-tooltip>
              </label>
              &nbsp;
              <label for="actDateTo" aria-haspopup="true" role="tooltip"
                     class="tooltip tooltip-validation tooltip-md"
                     [class.invalid]="isInvalid(model.filterFormGroup, 'actDateTo')">
                <input type="date" id="actDateTo" class="itech-control-normal" formControlName="actDateTo">
                <app-validation-tooltip [input]="model.filterFormGroup.controls['actDateTo']"></app-validation-tooltip>
              </label>
            </div>
            <div *ngIf="!model.filterFormGroup.get('toggleActOrIncoming').value" style="display:flex">
              <label for="incomingDateFrom" aria-haspopup="true" role="tooltip"
                     class="tooltip tooltip-validation tooltip-md"
                     [class.invalid]="isInvalid(model.filterFormGroup, 'incomingDateFrom')">
                <input type="date" id="incomingDateFrom" class="itech-control-normal"
                       formControlName="incomingDateFrom">
                <app-validation-tooltip [input]="model.filterFormGroup.controls['incomingDateFrom']"></app-validation-tooltip>
              </label>
              &nbsp;&nbsp;&nbsp;
              <label for="incomingDateTo" aria-haspopup="true" role="tooltip"
                     class="tooltip tooltip-validation tooltip-md"
                     [class.invalid]="isInvalid(model.filterFormGroup, 'incomingDateTo')">
                <input type="date" id="incomingDateTo" class="itech-control-normal"
                       formControlName="incomingDateTo">
                <app-validation-tooltip [input]="model.filterFormGroup.controls['incomingDateTo']"></app-validation-tooltip>
              </label>
              &nbsp;
              <label>/</label>
              &nbsp;
              <label for="spentDateFrom" aria-haspopup="true" role="tooltip"
                     class="tooltip tooltip-validation tooltip-md"
                     [class.invalid]="isInvalid(model.filterFormGroup, 'spentDateFrom')">
                <input type="date" id="spentDateFrom" class="itech-control-normal"
                       formControlName="spentDateFrom">
                <app-validation-tooltip [input]="model.filterFormGroup.controls['spentDateFrom']"></app-validation-tooltip>
              </label>
              &nbsp;&nbsp;&nbsp;
              <label for="spentDateTo" aria-haspopup="true" role="tooltip"
                     class="tooltip tooltip-validation tooltip-md"
                     [class.invalid]="isInvalid(model.filterFormGroup, 'spentDateTo')">
                <input type="date" id="spentDateTo" class="itech-control-normal"
                       formControlName="spentDateTo">
                <app-validation-tooltip [input]="model.filterFormGroup.controls['spentDateTo']"></app-validation-tooltip>
              </label>
            </div>
          </div>

          <div class="form-group">
            <label for="costMin">Цена от / по</label>
            <label for="costMin" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                   [class.invalid]="isInvalid(model.filterFormGroup, 'costMin')">
              <input type="text" id="costMin" class="itech-control-normal" formControlName="costMin">
              <app-validation-tooltip [input]="model.filterFormGroup.controls['costMin']"></app-validation-tooltip>
            </label>
            <label for="costMax" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                   [class.invalid]="isInvalid(model.filterFormGroup, 'costMax')">
              <input type="text" id="costMax" class="itech-control-normal" formControlName="costMax">
              <app-validation-tooltip [input]="model.filterFormGroup.controls['costMax']"></app-validation-tooltip>
            </label>
          </div>

          <div class="form-group">
            <label for="institutionId">Местонахождение</label>
            <app-combo-lookup [validationTooltipRight]="true" class="itech-control-medium"
                              [contextControlId]="'institutionId'"
                              [contextControlName]="'institutionId'"
                              [lookupName]="'institution'" [contextFormGroup]="model.filterFormGroup"></app-combo-lookup>
            &nbsp;&nbsp;&nbsp;
            <app-combo-lookup *ngIf="model.filterFormGroup.get('institutionId').value"
                              [validationTooltipRight]="true" class="itech-control-normal"
                              [contextControlId]="'institutionBranchId'"
                              [contextControlName]="'institutionBranchId'"
                              [lookupName]="'institution-branch' + model.filterFormGroup.get('institutionId').value"
                              [contextFormGroup]="model.filterFormGroup"></app-combo-lookup>
            &nbsp;&nbsp;&nbsp;
            <div class="checkbox-inline" title="Показывать израсходованные" style="margin-bottom:0;margin-left:5px">
              <input type="checkbox" id="includeExhausted" formControlName="includeExhausted">
              <label for="includeExhausted">Израсходовано</label>
            </div>
          </div>

          <div class="form-group itech-group-whole-line">
            <button class="btn btn-primary" style="margin:0" (click)="refreshResults()">
              <clr-icon shape="search" class="has-badge"></clr-icon>&nbsp;Поиск
            </button>
          </div>
        </ng-container>
      </section>
    </div>
  </div>

  <div [style.top.px]="expandedParams ? 280 : 30">
    <app-grid [pagination]="false" [getRowStyle]="getRowStyle" [rowData]="model.searchResults"
              (rowClicked)="rowClicked.emit($event.data)" [columnDefs]="gridColumnDefs"
              [uniqueGridName]="'DrugSearchResultsFormGrid'" [exportFileName]="'Список препаратов'"></app-grid>
  </div>
</div>
