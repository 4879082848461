<div class="itech-common-wrap no-pager">
  <div class="itech-v-header-content">
    <div class="itech-content-header">
      <clr-icon shape="file-group" size="36"></clr-icon>&nbsp;Подвиды животных
    </div>

    <div style="padding: 5px; height: 90%">
      <app-grid [rowData]="_searchResults" [columnDefs]="gridColumnDefs" [uniqueGridName]="getMetaName()"
                (rowClicked)="editRow($event.data)" [getRowStyle]="getRowStyle" [exportFileName]="getMetaTitle()">
      </app-grid>
    </div>
  </div>
  <div class="itech-form-actions">
    <div>
      <button class="btn btn-link btn-primary" (click)="refreshResults()">
        <clr-icon shape="refresh" class="has-badge"></clr-icon>&nbsp;Обновить список
      </button>
      <button class="btn btn-link btn-primary" (click)="editRow({})">
        <clr-icon shape="plus" has-badge="true"></clr-icon>&nbsp;Добавить новую запись
      </button>
    </div>
    <div>
      <button class="btn btn-link btn-primary" (click)="runUpdateAnimalTypesOp()">Обновление справочника из Меркурия
      </button>
    </div>
  </div>
</div>


<clr-modal [(clrModalOpen)]="editModalOpened" *ngIf="editModalOpened" [clrModalClosable]="false" [clrModalSize]="'lg'">
  <h3 class="modal-title">Редактирование записи
    <span *ngIf="currentIsNew" style="color:green"> [Новая]</span></h3>
  <div class="modal-body">
    <div class="form compact" [formGroup]="currentFormGroup"><clr-tabs>
      <clr-tab>
        <button clrTabLink>Общее</button>
        <clr-tab-content id="commonTab">
          <div class="edit-page-tab">
            <section class="form-block itech-block-normal">
              <div class="form-group">
                <label for="animalTypeId" class="required">Вид животного</label>
                <app-combo-lookup [validationTooltipBottomLeft]="true" class="itech-control-xlarge"
                                  [contextControlId]="'animalTypeId'" [sorted]="true"
                                  [contextControlName]="'animalTypeId'" [disabled]="currentFormGroup.get('id').value"
                                  [lookupName]="'animal-type'" [contextFormGroup]="currentFormGroup"></app-combo-lookup>
              </div>

              <div class="form-group">
                <label for="id" class="required">Ид подвида</label>
                <label for="id" aria-haspopup="true" role="tooltip"
                       class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                       [class.invalid]="isInvalid(currentFormGroup, 'id')">
                  <input type="text" id="id" placeholder="0000" class="itech-control-normal" formControlName="id"
                         [attr.disabled]="!currentIsNew && currentFormGroup.get('id').value? 1: undefined">
                  <app-validation-tooltip [input]="currentFormGroup.controls['id']"></app-validation-tooltip>
                </label>

                <div class="form-group itech-group-whole-line">
                  <div class="checkbox-inline">
                    <input type="checkbox" id="onlyIndividualAccounting" formControlName="onlyIndividualAccounting">
                    <label for="onlyIndividualAccounting">Только индивидуальный учёт</label>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label for="caption" class="required">Наименование</label>
                <label for="caption" aria-haspopup="true" role="tooltip"
                       class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                       [class.invalid]="isInvalid(currentFormGroup, 'caption')">
                  <input type="text" id="caption" placeholder="Наименование" class="itech-control-xlarge"
                         formControlName="caption">
                  <app-validation-tooltip [input]="currentFormGroup.controls['caption']"></app-validation-tooltip>
                </label>
              </div>

              <div class="form-group">
                <label for="pin">Pin</label>
                <label for="pin" aria-haspopup="true" role="tooltip"
                       class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                       [class.invalid]="isInvalid(currentFormGroup, 'pin')">
                  <input type="text" id="pin" placeholder="000" class="itech-control-tiny"
                         formControlName="pin" maxlength="3">
                  <app-validation-tooltip [input]="currentFormGroup.controls['pin']"></app-validation-tooltip>
                </label>
              </div>

              <div class="form-group">
                <label for="nomenclatureAnimalPinId" class="required">Номенклатура</label>
                <app-combo-lookup [validationTooltipRight]="true" class="itech-control-xlarge"
                                  [contextControlId]="'nomenclatureAnimalPinId'"
                                  [contextControlName]="'nomenclatureAnimalPinId'"
                                  [lookupName]="'nomenclature-animal-pin'"
                                  [contextFormGroup]="currentFormGroup"></app-combo-lookup>
              </div>

              <div class="form-group">
                <label for="guid">Guid</label>
                <label for="guid" aria-haspopup="true" role="tooltip"
                       class="tooltip tooltip-validation tooltip-md tooltip-top-left"
                       [class.invalid]="isInvalid(currentFormGroup, 'guid')">
                  <input type="text" id="guid" placeholder="00000000-aaaa-bbbb-cccc-000000000000" class="itech-control-xlarge"
                         formControlName="guid">
                  <app-validation-tooltip [input]="currentFormGroup.controls['guid']"></app-validation-tooltip>
                </label>
              </div>

              <div class="form-group itech-group-whole-line">
                <div class="checkbox-inline">
                  <input type="checkbox" id="isCustom" formControlName="isCustom">
                  <label for="isCustom">Игнорировать при синхронизации</label>
                </div>
              </div>

              <div class="form-group itech-group-whole-line">
                <div class="checkbox-inline">
                  <input type="checkbox" id="availableForExternal" formControlName="availableForExternal">
                  <label for="availableForExternal">Доступно для внешней системы</label>
                </div>
              </div>

              <div class="form-group" *ngIf="currentFormGroup.get('availableForExternal').value">
                <label for="captionForExternal">Наименование во внешней системе</label>
                <label for="captionForExternal" aria-haspopup="true" role="tooltip"
                       class="tooltip tooltip-validation tooltip-md tooltip-top-left"
                       [class.invalid]="isInvalid(currentFormGroup, 'captionForExternal')">
                  <input type="text" id="captionForExternal" placeholder="Наименование" class="itech-control-xlarge"
                         formControlName="captionForExternal">
                  <app-validation-tooltip [input]="currentFormGroup.controls['captionForExternal']"></app-validation-tooltip>
                </label>
              </div>
            </section>
          </div>
        </clr-tab-content>
      </clr-tab>

      <clr-tab>
        <button clrTabLink>Планирование мероприятий</button>
        <clr-tab-content id="eventPlanning">
          <div class="edit-page-tab">
            <section class="form-block itech-block-normal">
              <div *ngFor="let eventPlanning of getEventsPlanning().controls; index as i">
                <div class="form-group">
                  <label for="diseaseTypeId" class="required">Тип владельца</label>
                  <div style="display:grid">
                    <app-text-lookup class="itech-control-large" [validationTooltipRight]="true"
                                      [contextControlId]="'ownerTypeId' + i" [sorted]="true"
                                      [contextControlName]="'ownerTypeId'" [lookupName]="'legal-form/general-type'"
                                      [contextFormGroup]="getFormGroup(eventPlanning)"></app-text-lookup>
                  </div>
                </div>
                <div class="form-group">
                  <label for="diseaseTypeId" class="required">Заболевание</label>
                  <div style="display:grid">
                    <app-text-lookup class="itech-control-large" [validationTooltipRight]="true"
                                      [contextControlId]="'diseaseTypeId' + i" [sorted]="true"
                                      [contextControlName]="'diseaseTypeId'" [lookupName]="'disease-type'"
                                      [contextFormGroup]="getFormGroup(eventPlanning)"></app-text-lookup>
                  </div>
                </div>
                <div class="form-group">
                  <label for="diseaseTypeId" class="required">Вид мероприятия</label>
                  <div style="display:grid">
                    <app-text-lookup class="itech-control-large" [validationTooltipRight]="true"
                                      [contextControlId]="'eventTypeId' + i" [sorted]="true"
                                      [contextControlName]="'eventTypeId'" [lookupName]="'event-type/animal'"
                                      [contextFormGroup]="getFormGroup(eventPlanning)"></app-text-lookup>
                  </div>
                </div>
                <div class="form-group">
                  <label for="diagnosticTypeIds">Способ диагностики</label>
                  <app-combo-inline-multilookup [lookupName]="'diagnostic-type'"
                                                [contextControlName]="'diagnosticTypeIds'" [noControlsDivider]="true"
                                                [contextFormGroup]="getFormGroup(eventPlanning)" [sorted]="true">
                  </app-combo-inline-multilookup>
                </div>
                <div class="form-group">
                  <label for="testMaterialId">Исследуемый материал</label>
                  <app-text-lookup class="itech-control-xlarge" [contextControlId]="'testMaterialId' + i" [sorted]="true"
                                    [contextControlName]="'testMaterialId'" [lookupName]="'test-material'"
                                    [contextFormGroup]="getFormGroup(eventPlanning)"></app-text-lookup>
                </div>
                <div class="form-group" [formGroup]="getFormGroup(eventPlanning)">
                  <label for="periodicityDay" class="required">Периодичность в днях</label>
                  <label for="periodicityDay" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                         [class.invalid]="isInvalid(getFormGroup(eventPlanning), 'periodicityDay')">
                    <input type="number" id="periodicityDay{{i}}" placeholder="0000" formControlName="periodicityDay">
                    <app-validation-tooltip [input]="getFormGroup(eventPlanning).controls['periodicityDay']"></app-validation-tooltip>
                  </label>
                </div>
                <div class="form-group" [formGroup]="getFormGroup(eventPlanning)">
                  <label for="dateFrom" class="required">Дата с</label>
                  <label for="dateFrom" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md"
                         [class.invalid]="isInvalid(getFormGroup(eventPlanning), 'dateFrom')">
                    <input type="date" id="dateFrom{{i}}" formControlName="dateFrom">
                    <app-validation-tooltip [input]="getFormGroup(eventPlanning).controls['dateFrom']"></app-validation-tooltip>
                  </label>
                </div>
                <hr>
              </div>

              <button type="button" class="btn btn-link" (click)="addNewEventPlanning()">
                <clr-icon shape="plus"></clr-icon>
                Добавить мероприятие
              </button>
            </section>
          </div>
        </clr-tab-content>
      </clr-tab>
    </clr-tabs>

    </div>
  </div>
  <div class="modal-footer itech-form-actions">
    <div>
      <button *ngIf="!currentIsNew" type="button" class="btn btn-warning-outline"
              (click)="delete(currentFormGroup.value)">
        <clr-icon shape="trash"></clr-icon>&nbsp;Удалить
      </button>
    </div>
    <div>
      <button type="button" class="btn btn-primary-outline" (click)="runFirstEventPlanningOp()">
        <clr-icon shape="history"></clr-icon>&nbsp;Запланировать мероприятия
      </button>
      <button type="button" class="btn btn-primary" (click)="addEditCompleted()">
        <clr-icon shape="check"></clr-icon>&nbsp;Сохранить
      </button>
      <button type="button" class="btn btn-warning" (click)="editModalOpened = false">
        <clr-icon shape="undo"></clr-icon>&nbsp;Отмена
      </button>
    </div>
  </div>
</clr-modal>
