import { EventEmitter, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormHelper } from '../../ui/controls/form-helper';
var QueryDetailsEntityComponent = /** @class */ (function () {
    function QueryDetailsEntityComponent(queryService, dataCachingService, waitingOverlay, lookupSourceService, alertService, fb) {
        this.queryService = queryService;
        this.dataCachingService = dataCachingService;
        this.waitingOverlay = waitingOverlay;
        this.lookupSourceService = lookupSourceService;
        this.alertService = alertService;
        this.fb = fb;
        this.model = {
            data: [],
            dataColumns: [],
            nodeType: undefined,
            entityType: undefined,
            selectedNode: undefined,
            query: undefined,
            parentCondition: undefined,
            condition: undefined
        };
        this.storing = false;
        this.serverSideValidationErrors = [];
        this.relationTypes = [];
        this.creatingRelation = new EventEmitter();
    }
    QueryDetailsEntityComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.lookupSourceService.getLookup('query-relation-type').subscribe(function (relationTypes) {
            _this.relationTypes = [];
            relationTypes.forEach(function (rel) {
                if (rel.entityType === _this.EntityType) {
                    _this.relationTypes.push(rel);
                }
            });
        });
    };
    QueryDetailsEntityComponent.prototype.invalidateContextFormGroup = function () {
        if (this.model && this.model.condition && this.model.condition.contextFormGroup) {
            this.model.condition.contextFormGroup = undefined;
        }
    };
    Object.defineProperty(QueryDetailsEntityComponent.prototype, "contextFormGroup", {
        get: function () {
            if (!this.model.condition.contextFormGroup) {
                var gdef = this.getGroupDef();
                if (this.model.condition.data.relationType) {
                    Object.assign(gdef, {
                        arityFrom: [this.model.condition.data.arityFrom, Validators.compose([Validators.required, Validators.pattern(/^\d{1,4}$/)])],
                        arityTo: [this.model.condition.data.arityTo, Validators.compose([Validators.required, Validators.pattern(/^\d{1,4}$/)])],
                        relationType: [this.model.condition.data.relationType]
                    });
                }
                Object.assign(gdef, {
                    filterRelatedToInstitution: this.model.condition.data.filterRelatedToInstitution
                });
                this.model.condition.contextFormGroup = this.fb.group(gdef);
                if (this.model.condition.isNew) {
                    this.model.condition.contextFormGroup.markAsDirty();
                }
                if (!this.model.query.data.id) {
                    this.model.query.contextFormGroup.markAsDirty();
                }
            }
            return this.model.condition.contextFormGroup;
        },
        enumerable: true,
        configurable: true
    });
    QueryDetailsEntityComponent.prototype.store = function () {
        var _this = this;
        if (this.storing) {
            return;
        }
        FormHelper.markAsSubmitted(this.contextFormGroup);
        if (!this.contextFormGroup.valid) {
            return;
        }
        this.storing = true;
        var dataToStore = this.contextFormGroup.value;
        Object.assign(this.model.condition.data, dataToStore);
        this.model.condition.isNew = false;
        this.queryService.storeQuery(this.queryService.buildQueryForSave(this.model.query)).subscribe({
            next: function () {
                _this.queryService.updateConditionsEditStructure(_this.model.query.conditions, _this.model.query.data.entityType);
                _this.invalidateContextFormGroup();
                _this.storing = false;
            },
            error: function (error) {
                _this.storing = false;
                FormHelper.setSingleFormGroupServerSideValidationErrors(error, _this, _this.contextFormGroup);
            }
        });
    };
    QueryDetailsEntityComponent.prototype.deleteCondition = function () {
        var _this = this;
        this.alertService.confirmModal('Вы уверены, что хотите удалить условие фильтрации?').subscribe(function (val) {
            if (!val) {
                return;
            }
            var whereToFind = _this.model.parentCondition ? _this.model.parentCondition.relations : _this.model.query.conditions;
            var ix = whereToFind.findIndex(function (el) { return el === _this.model.condition; });
            if (ix >= 0) {
                whereToFind.splice(ix, 1);
            }
            _this.store();
            _this.model.condition = undefined;
            _this.model.parentCondition = undefined;
            _this.model.nodeType = 'query';
            _this.model.selectedNode = _this.model.query;
        });
    };
    QueryDetailsEntityComponent.prototype.addRelation = function (rt) {
        this.creatingRelation.emit({ relationType: rt, condition: this.model.condition });
    };
    QueryDetailsEntityComponent.prototype.isInvalid = function (cname) {
        return FormHelper.isInvalid(this.contextFormGroup, cname);
    };
    return QueryDetailsEntityComponent;
}());
export { QueryDetailsEntityComponent };
