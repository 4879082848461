import * as tslib_1 from "tslib";
import { QueryService } from '../../logic/services/query.service';
import { DataCachingService } from '../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../logic/services/lookup-source.service';
import { FormBuilder } from '@angular/forms';
import { AlertService } from '../../ui/infrastructure/alert.service';
import { FormHelper } from '../../ui/controls/form-helper';
import { QueryDetailsEntityComponent } from './query-details-entity.component';
var QueryDetailsInstitutionTicketComponent = /** @class */ (function (_super) {
    tslib_1.__extends(QueryDetailsInstitutionTicketComponent, _super);
    function QueryDetailsInstitutionTicketComponent(queryService, dataCachingService, waitingOverlay, lookupSourceService, alertService, fb) {
        var _this = _super.call(this, queryService, dataCachingService, waitingOverlay, lookupSourceService, alertService, fb) || this;
        _this.EntityType = 6; // institution-ticket
        return _this;
    }
    QueryDetailsInstitutionTicketComponent.getGroupDef = function (condition) {
        return {
            type: 'institution-ticket',
            _par_created_institution_filter: condition.data._par_created_institution_filter || 0,
            _par_created_institution: condition.data._par_created_institution,
            _par_created_institution_employee_filter: condition.data._par_created_institution_employee_filter || 0,
            _par_created_institution_employee: condition.data._par_created_institution_employee,
            _par_created_time_filter: condition.data._par_created_time_filter || 0,
            _par_created_time_from: [condition.data._par_created_time_from, FormHelper.validateDateTimePicker()],
            _par_created_time_to: [condition.data._par_created_time_to, FormHelper.validateDateTimePicker()],
            _par_start_time_filter: condition.data._par_start_time_filter || 0,
            _par_start_time_from: [condition.data._par_start_time_from, FormHelper.validateDateTimePicker()],
            _par_start_time_to: [condition.data._par_start_time_to, FormHelper.validateDateTimePicker()],
            _par_institution_filter: condition.data._par_institution_filter,
            _par_institution: condition.data._par_institution,
            _par_reservation_time_filter: condition.data._par_reservation_time_filter || 0,
            _par_reservation_time_from: [condition.data._par_reservation_time_from, FormHelper.validateDateTimePicker()],
            _par_reservation_time_to: [condition.data._par_reservation_time_to, FormHelper.validateDateTimePicker()],
            _par_reservation_external_user_filter: condition.data._par_reservation_external_user_filter,
            _par_reservation_external_user: condition.data._par_reservation_external_user,
            _par_cancel_time_filter: condition.data._par_cancel_time_filter || 0,
            _par_cancel_time_from: [condition.data._par_cancel_time_from, FormHelper.validateDateTimePicker()],
            _par_cancel_time_to: [condition.data._par_cancel_time_to, FormHelper.validateDateTimePicker()],
            _par_cancel_external_user_filter: condition.data._par_cancel_external_user_filter,
            _par_cancel_external_user: condition.data._par_cancel_external_user,
        };
    };
    QueryDetailsInstitutionTicketComponent.prototype.getGroupDef = function () {
        return QueryDetailsInstitutionTicketComponent.getGroupDef(this.model.condition);
    };
    return QueryDetailsInstitutionTicketComponent;
}(QueryDetailsEntityComponent));
export { QueryDetailsInstitutionTicketComponent };
