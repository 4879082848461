<div class="itech-common-wrap" id="interceptNoAccess">
  <div>
    <div class="form compact" [formGroup]="contextFormGroup">

      <app-doc-validation-errors [validationErrors]="contextFormGroup.docLevelServerSideValidationErrors">
      </app-doc-validation-errors>

      <section class="form-block itech-block-little itech-request-area" formArrayName="branches">

        <div *ngIf="branches.length == 0" class="itech-no-data-text">Филиалы отсутствуют</div>
        <clr-tree>
          <clr-tree-node *ngFor="let item of branches.controls; index as i" [formGroupName]="i">
            <button class="clr-treenode-link">
              {{item.controls['caption'].value || 'новый филиал'}}
              <button type="button" style="margin: 0" class="btn btn-danger btn-link itech-inline-tool-btn"
                      title="Удалить филиал" (click)="deleteBranch(i)">
                <clr-icon shape="trash"></clr-icon>
              </button>
            </button>
            <clr-tree-node>
              <section class="form-block itech-block-little itech-request-area"
                       [style.min-height.px]="item.controls['isAddress'].value ? 465 : 185">
                <div class="form-group itech-group-whole-line">
                  <label></label>
                  <label for="caption{{i}}" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                         [class.invalid]="isInvalid(item, 'caption')">
                    <input type="text" id="caption{{i}}" placeholder="Наименование" class="itech-control-large"
                           formControlName="caption">
                    <app-validation-tooltip [input]="item.controls['caption']"></app-validation-tooltip>
                  </label>
                </div>
                <div class="form-group itech-group-whole-line">
                  <div class="checkbox-inline">
                    <input type="checkbox" id="changeAddress{{i}}" formControlName="isAddress">
                    <label for="changeAddress{{i}}">
                      Указать адрес
                    </label>
                  </div>
                </div>
                <section class="form-block itech-block-medium" *ngIf="item.controls['isAddress'].value">
                  <div class="itech-control-desc">
                    Укажите адрес, если он отличается от адреса, указанного во вкладке "Общее"
                  </div>
                  <app-address [contextFormGroup]="item.controls['address']"
                               [kind]="[{ id: 0, caption: 'В регионе' }]"></app-address>
                </section>
                <div class="form-group itech-group-whole-line">
                  <div class="checkbox-inline">
                    <input type="checkbox" id="isVetClinic{{i}}" formControlName="isVetClinic">
                    <label for="isVetClinic{{i}}">Является вет.клиникой</label>
                  </div>
                </div>
                <div class="form-group">
                  <label for="email{{i}}">
                    email&nbsp;
                  </label>
                  <label for="email{{i}}" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                         [class.invalid]="isInvalid(item, 'email')">
                    <input type="text" id="email{{i}}" placeholder="email" class="itech-control-medium"
                           formControlName="email">
                    <app-validation-tooltip [input]="item.controls['email']"></app-validation-tooltip>
                  </label>
                </div>
                <div class="form-group">
                  <label for="phone{{i}}">
                    Тел.&nbsp;
                  </label>
                  <label for="phone{{i}}" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                         [class.invalid]="isInvalid(item, 'phone')">
                    <input type="text" id="phone{{i}}" placeholder="Телефон" class="itech-control-medium"
                           formControlName="phone">
                    <app-validation-tooltip [input]="item.controls['phone']"></app-validation-tooltip>
                  </label>
                </div>
              </section>
            </clr-tree-node>
          </clr-tree-node>
        </clr-tree>
      </section>
    </div>
  </div>

  <div class="itech-form-actions">
    <div>
      <button class="btn btn-primary-outline" (click)="navigationService.navigateJournal(undefined, 2, id, undefined)">
        <clr-icon shape="view-list"></clr-icon>&nbsp;Журнал
      </button>

      <button class="btn btn-secondary" (click)="refreshFormData()">
        <clr-icon shape="refresh"></clr-icon>&nbsp;Обновить
      </button>
      <button class="btn btn-success-outline" (click)="addBranch()">
        <clr-icon shape="plus"></clr-icon>&nbsp;Добавить
      </button>
    </div>
    <div>
      <button [clrLoading]="storing" class="btn btn-primary itech-form-save"
              [disabled]="!contextFormGroup.dirty || storing"
              (click)="store()">
        <clr-icon shape="check"></clr-icon>&nbsp;Сохранить
      </button>
      <button class="btn btn-warning itech-form-cancel" [disabled]="!contextFormGroup.dirty" (click)="cancelEdit()">
        <clr-icon shape="undo"></clr-icon>&nbsp;Отменить
      </button>
    </div>
  </div>
</div>
