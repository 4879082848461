/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./common/edit-stable-common-form.component.ngfactory";
import * as i2 from "./common/edit-stable-common-form.component";
import * as i3 from "@angular/forms";
import * as i4 from "../../logic/services/app-navigation.service";
import * as i5 from "../../logic/services/security.service";
import * as i6 from "@clr/angular";
import * as i7 from "@angular/common";
import * as i8 from "../../../../node_modules/@clr/angular/clr-angular.ngfactory";
import * as i9 from "./create-stable.component";
import * as i10 from "@angular/router";
import * as i11 from "../../logic/services/bookmark.service";
import * as i12 from "../../logic/services/stable-data.service";
import * as i13 from "../../logic/services/data-caching.service";
var styles_CreateStableComponent = [];
var RenderType_CreateStableComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CreateStableComponent, data: {} });
export { RenderType_CreateStableComponent as RenderType_CreateStableComponent };
function View_CreateStableComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-edit-stable-common-form", [], null, null, null, i1.View_EditStableCommonFormComponent_0, i1.RenderType_EditStableCommonFormComponent)), i0.ɵdid(1, 573440, null, 0, i2.EditStableCommonFormComponent, [i3.FormBuilder, i4.AppNavigationService, i5.SecurityService], { contextFormGroup: [0, "contextFormGroup"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.form; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CreateStableComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "itech-content-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "clr-icon", [["class", "has-badge"], ["shape", "building"], ["size", "36"]], null, null, null, null, null)), i0.ɵdid(2, 16384, null, 0, i6.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, ["\u00A0\u0421\u043E\u0437\u0434\u0430\u043D\u0438\u0435 \u043D\u043E\u0432\u043E\u0433\u043E \u043F\u043E\u0434\u043D\u0430\u0434\u0437\u043E\u0440\u043D\u043E\u0433\u043E \u043E\u0431\u044A\u0435\u043A\u0442\u0430\n"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CreateStableComponent_1)), i0.ɵdid(5, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 7, "p", [], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 6, "button", [["class", "btn btn-primary"]], [[1, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createCard() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_ClrLoadingButton_0, i8.RenderType_ClrLoadingButton)), i0.ɵdid(8, 49152, null, 0, i6.ClrLoadingButton, [i0.ElementRef, i0.Renderer2], { disabled: [0, "disabled"] }, null), i0.ɵprd(2048, null, i6.LoadingListener, null, [i6.ClrLoadingButton]), i0.ɵdid(10, 147456, null, 0, i6.ClrLoading, [[2, i6.LoadingListener]], { loadingState: [0, "loadingState"] }, null), (_l()(), i0.ɵeld(11, 0, null, 0, 1, "clr-icon", [["shape", "check"]], null, null, null, null, null)), i0.ɵdid(12, 16384, null, 0, i6.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, 0, ["\u00A0\u0421\u043E\u0437\u0434\u0430\u0442\u044C "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model; _ck(_v, 5, 0, currVal_0); var currVal_2 = _co.creating; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.creating; _ck(_v, 10, 0, currVal_3); }, function (_ck, _v) { var currVal_1 = (i0.ɵnov(_v, 8).disabled ? "" : null); _ck(_v, 7, 0, currVal_1); }); }
export function View_CreateStableComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-create-stable", [], null, null, null, View_CreateStableComponent_0, RenderType_CreateStableComponent)), i0.ɵdid(1, 114688, null, 0, i9.CreateStableComponent, [i10.ActivatedRoute, i10.Router, i11.BookmarkService, i12.StableDataService, i13.DataCachingService, i4.AppNavigationService, i5.SecurityService, i3.FormBuilder], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CreateStableComponentNgFactory = i0.ɵccf("app-create-stable", i9.CreateStableComponent, View_CreateStableComponent_Host_0, { model: "model" }, {}, []);
export { CreateStableComponentNgFactory as CreateStableComponentNgFactory };
