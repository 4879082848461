/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/@clr/angular/clr-angular.ngfactory";
import * as i2 from "@clr/angular";
import * as i3 from "./user-favourite-stables.component.ngfactory";
import * as i4 from "./user-favourite-stables.component";
import * as i5 from "../../logic/services/security.service";
import * as i6 from "../../logic/services/institution-data.service";
import * as i7 from "./user-reserved-drugs.component.ngfactory";
import * as i8 from "./user-reserved-drugs.component";
import * as i9 from "../../logic/services/data-caching.service";
import * as i10 from "../../ui/infrastructure/global-waiting-overlay.service";
import * as i11 from "../../logic/services/bookmark.service";
import * as i12 from "../../logic/services/drug-data.service";
import * as i13 from "../../logic/services/app-navigation.service";
import * as i14 from "./user-tags-autofill.component.ngfactory";
import * as i15 from "./user-tags-autofill.component";
import * as i16 from "../../ui/infrastructure/alert.service";
import * as i17 from "./personal-account-common.component";
var styles_PersonalAccountCommonComponent = [];
var RenderType_PersonalAccountCommonComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PersonalAccountCommonComponent, data: {} });
export { RenderType_PersonalAccountCommonComponent as RenderType_PersonalAccountCommonComponent };
function View_PersonalAccountCommonComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "clr-tab-content", [["id", "favouriteStableTabActive"]], null, null, null, i1.View_ClrTabContent_0, i1.RenderType_ClrTabContent)), i0.ɵdid(1, 180224, [[3, 4]], 0, i2.ClrTabContent, [i2.ɵz, i2.ɵw, i2.ɵeq, i2.ɵer], { tabContentId: [0, "tabContentId"] }, null), i0.ɵdid(2, 2244608, null, 0, i2.ɵew, [i0.ChangeDetectorRef, [2, i2.ɵev], i2.ɵw, i2.ɵz], null, null), (_l()(), i0.ɵeld(3, 0, null, 0, 2, "div", [["class", "edit-page-tab"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "app-user-favourite-stables", [], null, null, null, i3.View_UserFavouriteStablesComponent_0, i3.RenderType_UserFavouriteStablesComponent)), i0.ɵdid(5, 114688, null, 0, i4.UserFavouriteStablesComponent, [i5.SecurityService, i6.InstitutionDataService], null, null)], function (_ck, _v) { var currVal_0 = "favouriteStableTabActive"; _ck(_v, 1, 0, currVal_0); _ck(_v, 5, 0); }, null); }
function View_PersonalAccountCommonComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "clr-tab-content", [], null, null, null, i1.View_ClrTabContent_0, i1.RenderType_ClrTabContent)), i0.ɵdid(1, 180224, [[5, 4]], 0, i2.ClrTabContent, [i2.ɵz, i2.ɵw, i2.ɵeq, i2.ɵer], null, null), i0.ɵdid(2, 2244608, null, 0, i2.ɵew, [i0.ChangeDetectorRef, [2, i2.ɵev], i2.ɵw, i2.ɵz], null, null), (_l()(), i0.ɵeld(3, 0, null, 0, 2, "div", [["class", "edit-page-tab"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "app-user-reserved-drugs", [], null, null, null, i7.View_UserReservedDrugsComponent_0, i7.RenderType_UserReservedDrugsComponent)), i0.ɵdid(5, 114688, null, 0, i8.UserReservedDrugsComponent, [i5.SecurityService, i9.DataCachingService, i10.GlobalWaitingOverlayService, i11.BookmarkService, i12.DrugDataService, i13.AppNavigationService], null, null)], function (_ck, _v) { _ck(_v, 5, 0); }, null); }
function View_PersonalAccountCommonComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "clr-tab-content", [], null, null, null, i1.View_ClrTabContent_0, i1.RenderType_ClrTabContent)), i0.ɵdid(1, 180224, [[7, 4]], 0, i2.ClrTabContent, [i2.ɵz, i2.ɵw, i2.ɵeq, i2.ɵer], null, null), i0.ɵdid(2, 2244608, null, 0, i2.ɵew, [i0.ChangeDetectorRef, [2, i2.ɵev], i2.ɵw, i2.ɵz], null, null), (_l()(), i0.ɵeld(3, 0, null, 0, 2, "div", [["class", "edit-page-tab"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "app-user-tags-autofill", [], null, null, null, i14.View_UserTagsAutofillComponent_0, i14.RenderType_UserTagsAutofillComponent)), i0.ɵdid(5, 49152, null, 0, i15.UserTagsAutofillComponent, [i5.SecurityService, i16.AlertService], null, null)], null, null); }
export function View_PersonalAccountCommonComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 47, "clr-tabs", [], null, null, null, i1.View_ClrTabs_0, i1.RenderType_ClrTabs)), i0.ɵprd(512, null, i2.ɵz, i2.ɵz, []), i0.ɵprd(512, null, i2.ɵd, i2.ɵd, []), i0.ɵprd(512, null, i2.ɵer, i2.ɵer, []), i0.ɵprd(1024, null, i2.ɵes, i2.ɵet, []), i0.ɵdid(5, 1228800, null, 1, i2.ClrTabs, [i2.ɵz, i2.ɵd, i2.ɵer, i2.ɵes, i2.ClrCommonStringsService, i0.PLATFORM_ID], null, null), i0.ɵqud(603979776, 1, { tabs: 1 }), i0.ɵdid(7, 8404992, null, 0, i2.ɵev, [], null, null), (_l()(), i0.ɵeld(8, 0, null, null, 11, "clr-tab", [], null, null, null, i1.View_ClrTab_0, i1.RenderType_ClrTab)), i0.ɵprd(1024, null, i2.ɵw, i2.ɵx, []), i0.ɵdid(10, 180224, [[1, 4]], 2, i2.ClrTab, [i2.ɵz, i2.ɵw, i2.ɵer], null, null), i0.ɵqud(335544320, 2, { tabLink: 0 }), i0.ɵqud(603979776, 3, { tabContent: 0 }), i0.ɵprd(512, null, i2.ɵeq, i2.ɵeq, []), (_l()(), i0.ɵeld(14, 16777216, null, 0, 3, "button", [["clrTabLink", ""], ["role", "tab"], ["type", "button"]], [[8, "id", 0], [1, "aria-selected", 0], [1, "aria-hidden", 0], [1, "aria-controls", 0], [2, "btn", null], [2, "btn-link", null], [2, "nav-link", null], [2, "nav-item", null], [2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 15).activate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(15, 16384, [[2, 4]], 0, i2.ClrTabLink, [i2.ɵz, i2.ɵw, i2.ɵeq, i0.ElementRef, i0.ComponentFactoryResolver, i0.ViewContainerRef, i2.ɵes], null, null), i0.ɵdid(16, 2244608, null, 0, i2.ɵew, [i0.ChangeDetectorRef, [2, i2.ɵev], i2.ɵw, i2.ɵz], null, null), (_l()(), i0.ɵted(-1, null, ["\u0418\u0437\u0431\u0440\u0430\u043D\u043D\u044B\u0435 \u041F\u041E"])), (_l()(), i0.ɵand(16777216, null, 0, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clrIfActiveChange" === en)) {
        var pd_0 = ((_co.model.favouriteStablesTabActive = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_PersonalAccountCommonComponent_1)), i0.ɵdid(19, 147456, null, 0, i2.ClrIfActive, [i2.ɵz, i2.ɵw, i0.TemplateRef, i0.ViewContainerRef], { active: [0, "active"] }, { activeChange: "clrIfActiveChange" }), (_l()(), i0.ɵeld(20, 0, null, null, 13, "clr-tab", [], null, null, null, i1.View_ClrTab_0, i1.RenderType_ClrTab)), i0.ɵprd(1024, null, i2.ɵw, i2.ɵx, []), i0.ɵdid(22, 180224, [[1, 4]], 2, i2.ClrTab, [i2.ɵz, i2.ɵw, i2.ɵer], null, null), i0.ɵqud(335544320, 4, { tabLink: 0 }), i0.ɵqud(603979776, 5, { tabContent: 0 }), i0.ɵprd(512, null, i2.ɵeq, i2.ɵeq, []), (_l()(), i0.ɵeld(26, 16777216, null, 0, 5, "button", [["clrTabLink", ""], ["role", "tab"], ["type", "button"]], [[8, "id", 0], [1, "aria-selected", 0], [1, "aria-hidden", 0], [1, "aria-controls", 0], [2, "btn", null], [2, "btn-link", null], [2, "nav-link", null], [2, "nav-item", null], [2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 27).activate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(27, 16384, [[4, 4]], 0, i2.ClrTabLink, [i2.ɵz, i2.ɵw, i2.ɵeq, i0.ElementRef, i0.ComponentFactoryResolver, i0.ViewContainerRef, i2.ɵes], null, null), i0.ɵdid(28, 2244608, null, 0, i2.ɵew, [i0.ChangeDetectorRef, [2, i2.ɵev], i2.ɵw, i2.ɵz], null, null), (_l()(), i0.ɵeld(29, 0, null, null, 1, "clr-icon", [["shape", "flask"]], null, null, null, null, null)), i0.ɵdid(30, 16384, null, 0, i2.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, ["\u0412\u044B\u0434\u0430\u043D\u043D\u044B\u0435 \u043F\u0440\u0435\u043F\u0430\u0440\u0430\u0442\u044B"])), (_l()(), i0.ɵand(16777216, null, 0, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clrIfActiveChange" === en)) {
        var pd_0 = ((_co.model.reservedDrugsTabActive = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_PersonalAccountCommonComponent_2)), i0.ɵdid(33, 147456, null, 0, i2.ClrIfActive, [i2.ɵz, i2.ɵw, i0.TemplateRef, i0.ViewContainerRef], { active: [0, "active"] }, { activeChange: "clrIfActiveChange" }), (_l()(), i0.ɵeld(34, 0, null, null, 13, "clr-tab", [], null, null, null, i1.View_ClrTab_0, i1.RenderType_ClrTab)), i0.ɵprd(1024, null, i2.ɵw, i2.ɵx, []), i0.ɵdid(36, 180224, [[1, 4]], 2, i2.ClrTab, [i2.ɵz, i2.ɵw, i2.ɵer], null, null), i0.ɵqud(335544320, 6, { tabLink: 0 }), i0.ɵqud(603979776, 7, { tabContent: 0 }), i0.ɵprd(512, null, i2.ɵeq, i2.ɵeq, []), (_l()(), i0.ɵeld(40, 16777216, null, 0, 5, "button", [["clrTabLink", ""], ["role", "tab"], ["type", "button"]], [[8, "id", 0], [1, "aria-selected", 0], [1, "aria-hidden", 0], [1, "aria-controls", 0], [2, "btn", null], [2, "btn-link", null], [2, "nav-link", null], [2, "nav-item", null], [2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 41).activate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(41, 16384, [[6, 4]], 0, i2.ClrTabLink, [i2.ɵz, i2.ɵw, i2.ɵeq, i0.ElementRef, i0.ComponentFactoryResolver, i0.ViewContainerRef, i2.ɵes], null, null), i0.ɵdid(42, 2244608, null, 0, i2.ɵew, [i0.ChangeDetectorRef, [2, i2.ɵev], i2.ɵw, i2.ɵz], null, null), (_l()(), i0.ɵeld(43, 0, null, null, 1, "clr-icon", [["shape", "tag"]], null, null, null, null, null)), i0.ɵdid(44, 16384, null, 0, i2.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, ["\u0422\u044D\u0433\u0438 \u0430\u0432\u0442\u043E\u0437\u0430\u043F\u043E\u043B\u043D\u0435\u043D\u0438\u044F"])), (_l()(), i0.ɵand(16777216, null, 0, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clrIfActiveChange" === en)) {
        var pd_0 = ((_co.model.tagsAutofillTabActive = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_PersonalAccountCommonComponent_3)), i0.ɵdid(47, 147456, null, 0, i2.ClrIfActive, [i2.ɵz, i2.ɵw, i0.TemplateRef, i0.ViewContainerRef], { active: [0, "active"] }, { activeChange: "clrIfActiveChange" })], function (_ck, _v) { var _co = _v.component; var currVal_9 = _co.model.favouriteStablesTabActive; _ck(_v, 19, 0, currVal_9); var currVal_19 = _co.model.reservedDrugsTabActive; _ck(_v, 33, 0, currVal_19); var currVal_29 = _co.model.tagsAutofillTabActive; _ck(_v, 47, 0, currVal_29); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 15).tabLinkId; var currVal_1 = i0.ɵnov(_v, 15).active; var currVal_2 = false; var currVal_3 = i0.ɵnov(_v, 15).ariaControls; var currVal_4 = true; var currVal_5 = !i0.ɵnov(_v, 15).inOverflow; var currVal_6 = !i0.ɵnov(_v, 15).inOverflow; var currVal_7 = !i0.ɵnov(_v, 15).inOverflow; var currVal_8 = i0.ɵnov(_v, 15).active; _ck(_v, 14, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_10 = i0.ɵnov(_v, 27).tabLinkId; var currVal_11 = i0.ɵnov(_v, 27).active; var currVal_12 = false; var currVal_13 = i0.ɵnov(_v, 27).ariaControls; var currVal_14 = true; var currVal_15 = !i0.ɵnov(_v, 27).inOverflow; var currVal_16 = !i0.ɵnov(_v, 27).inOverflow; var currVal_17 = !i0.ɵnov(_v, 27).inOverflow; var currVal_18 = i0.ɵnov(_v, 27).active; _ck(_v, 26, 0, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17, currVal_18); var currVal_20 = i0.ɵnov(_v, 41).tabLinkId; var currVal_21 = i0.ɵnov(_v, 41).active; var currVal_22 = false; var currVal_23 = i0.ɵnov(_v, 41).ariaControls; var currVal_24 = true; var currVal_25 = !i0.ɵnov(_v, 41).inOverflow; var currVal_26 = !i0.ɵnov(_v, 41).inOverflow; var currVal_27 = !i0.ɵnov(_v, 41).inOverflow; var currVal_28 = i0.ɵnov(_v, 41).active; _ck(_v, 40, 0, currVal_20, currVal_21, currVal_22, currVal_23, currVal_24, currVal_25, currVal_26, currVal_27, currVal_28); }); }
export function View_PersonalAccountCommonComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-personal-account", [], null, null, null, View_PersonalAccountCommonComponent_0, RenderType_PersonalAccountCommonComponent)), i0.ɵdid(1, 114688, null, 0, i17.PersonalAccountCommonComponent, [i11.BookmarkService, i9.DataCachingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PersonalAccountCommonComponentNgFactory = i0.ɵccf("app-personal-account", i17.PersonalAccountCommonComponent, View_PersonalAccountCommonComponent_Host_0, {}, {}, []);
export { PersonalAccountCommonComponentNgFactory as PersonalAccountCommonComponentNgFactory };
