import { Component, HostBinding, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-query-details-institution-ticket-fields',
  template: `
    <app-query-lookup-combo-filter parameterName="created_institution" [lookupName]="'all-institution'"
                                   [contextFormGroup]="contextFormGroup" [queryUserMode]="queryUserMode">
      Учреждение-создатель
    </app-query-lookup-combo-filter>
    <app-query-lookup-combo-filter *ngIf="contextFormGroup.get('_par_created_institution').value"
                                   parameterName="created_institution_employee"
                                   [lookupName]="'institution-employee' + contextFormGroup.get('_par_created_institution').value"
                                   [contextFormGroup]="contextFormGroup" [queryUserMode]="queryUserMode">
      Пользователь-создатель
    </app-query-lookup-combo-filter>
    <app-query-date-time-filter parameterName="created_time" [contextFormGroup]="contextFormGroup"
                                [queryUserMode]="queryUserMode">
      Время создания
    </app-query-date-time-filter>
    <app-query-date-time-filter parameterName="start_time" [contextFormGroup]="contextFormGroup"
                                [queryUserMode]="queryUserMode">
      Время приёма
    </app-query-date-time-filter>
    <app-query-lookup-combo-filter parameterName="institution" [lookupName]="'all-institution'"
                                   [contextFormGroup]="contextFormGroup" [queryUserMode]="queryUserMode">
      Учреждение приёма
    </app-query-lookup-combo-filter>
    <app-query-date-time-filter parameterName="reservation_time" [contextFormGroup]="contextFormGroup"
                                [queryUserMode]="queryUserMode">
      Время резервирования
    </app-query-date-time-filter>
    <app-query-boolean-filter parameterName="reservation_external_user" [contextFormGroup]="contextFormGroup"
                              [queryUserMode]="queryUserMode">
      Резерв. внешним пол.
    </app-query-boolean-filter>
    <app-query-date-time-filter parameterName="cancel_time" [contextFormGroup]="contextFormGroup"
                                [queryUserMode]="queryUserMode">
      Время отмены
    </app-query-date-time-filter>
    <app-query-boolean-filter parameterName="cancel_external_user" [contextFormGroup]="contextFormGroup"
                              [queryUserMode]="queryUserMode">
      Отмена внешним пол.
    </app-query-boolean-filter>
  `
})
export class QueryDetailsInstitutionTicketFieldsComponent {
  @Input() contextFormGroup: FormGroup;
  @Input() queryUserMode;
  @HostBinding('attr.class') class = 'form-block itech-block-normal';
}
