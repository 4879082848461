import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { MetadataService } from '../../../logic/services/metadata.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MetaBrowseBaseComponent } from '../meta-browse.base.component';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';

@Component({
  templateUrl: './addr-street-browse.component.html'
})
export class AddrStreetBrowseComponent extends MetaBrowseBaseComponent {

  gridColumnDefs = [
    {
      headerName: 'Населенный пункт', field: 'regionIdCityId', width: 300,
      valueFormatter: params => this.addrCityMap[params.value]
    },
    {headerName: '№', field: 'id', width: 100},
    {
      headerName: 'Тип', field: 'type', width: 100,
      valueFormatter: params => this.addrStreetTypeMap[params.value]
    },
    {headerName: 'Наименование', field: 'caption', width: 300},
    {headerName: 'Код ПФР 6.2', field: 'codePfr62', width: 180}
  ];

  addrCityMap: any;
  addrStreetTypeMap: any;

  constructor(route: ActivatedRoute,
              metadataService: MetadataService,
              alertService: AlertService,
              globalWaitingOverlayService: GlobalWaitingOverlayService,
              lookupService: LookupSourceService,
              fb: FormBuilder,
              bookmarkService: BookmarkService,
              dataCachingService: DataCachingService) {
    super(route, metadataService, alertService, globalWaitingOverlayService, lookupService, fb, bookmarkService, dataCachingService);
    this.lookupService.getLookupObj('addr-city').subscribe(dict => { this.addrCityMap = dict; });
    this.lookupService.getLookupObj('addr-street-type').subscribe(dict => { this.addrStreetTypeMap = dict; });
  }

  getMetaTitle(): string {
    return 'Улицы';
  }

  getMetaName(): string {
    return 'addr-street';
  }

  getId(formGroup: FormGroup): any {
    return formGroup.get('regionId').value && formGroup.get('cityId').value && formGroup.get('id').value
      ? formGroup.get('regionId').value + '/' + formGroup.get('cityId').value + '/' + formGroup.get('id').value
      : undefined;
  }

  refreshResults() {
    this.globalWaitingOverlayService.StartWaiting();

    this.metadataService.getMetadata(
      this.getMetaName()
    ).subscribe(data => {
      data.forEach(row => {
        row.regionIdCityId = row.regionId && row.cityId ? row.regionId.toString() + '-' + row.cityId.toString() : undefined;
      });
      this._searchResults = data;
      this.dataCachingService.addToCache(this.getMetaName() + '_Data', '1', data);
      this.lookupService.invalidateLookup(this.getMetaName());
      this.globalWaitingOverlayService.EndWaiting();
    }, error => {
      this.globalWaitingOverlayService.EndWaiting();
    });
  }

  getGroupDef(row: any) {
    return {
      id: row.id,
      type: [row.type, Validators.required],
      regionId: [row.regionId, Validators.required],
      cityId: [row.cityId, Validators.required],
      regionIdCityId: [row.regionId && row.cityId ? row.regionId.toString() + '-' + row.cityId.toString() : undefined,
        Validators.required],
      caption: [row.caption, Validators.required],
      codePfr62: row.codePfr62,
      aoguid: row.aoguid,
    };
  }

  regionIdCityIdChanged(event: any) {
    setTimeout(() => {
      const matches = this.currentFormGroup.value.regionIdCityId.match(/(\d+)-(\d+)/);
      this.currentFormGroup.get('regionId').setValue(matches ? matches[1] : undefined);
      this.currentFormGroup.get('cityId').setValue(matches ? matches[2] : undefined);
    }, 300);
  }
}
