
<ng-container *ngIf="currentFormGroup">
  <ng-container *ngIf="currentFormGroup.controls['authMercuryInProcess'].value; else hasCurrentSession">
    <div style="display:flex;justify-content:center;overflow:hidden;">
      <span class="spinner spinner-md"></span>
    </div>
  </ng-container>
  <ng-template #hasCurrentSession>
    <ng-container *ngIf="(securityService.getUserMercurySession() | async); else noCurrentSession">
      <span style="font-size:12px;color:black;">Вы успешно авторизованы в Меркурии</span>
      <a class="itech-person-link not-icon" style="font-size:12px" (click)="logout()">
        Выйти
      </a>
    </ng-container>
  </ng-template>
  <ng-template #noCurrentSession>
    <ng-container *ngIf="!currentFormGroup.controls['authMercuryStatusId'].value; else authProcess">
      <span style="font-size:12px;color:black;">Вы не авторизованы в Меркурии</span>
      <a class="itech-person-link not-icon" style="font-size:12px" (click)="goToAuthMercury()">
        Авторизоваться
      </a>
    </ng-container>
    <ng-template #authProcess>
      <div *ngIf="equalsSome(currentFormGroup.controls['authMercuryStatusId'].value, 1, 2) && currentFormAuthMercuryGroup"
           class="compact form itech-highlighted-sections" [formGroup]="currentFormAuthMercuryGroup">
        <section class="form-block itech-block-normal"
                 [style.min-height.px]="currentFormGroup.controls['authMercuryStatusId'].value === 1
                   ? 140
                   : currentFormGroup.controls['authMercuryStatusId'].value === 2 ? 90 : undefined">
          <ng-container *ngIf="currentFormGroup.controls['authMercuryStatusId'].value === 1; else authStatusTelCode">
            <div class="form-group">
              <label for="authMercuryLogin" class="required">Логин</label>
              <label for="authMercuryLogin" aria-haspopup="true" role="tooltip"
                     class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                     [class.invalid]="isInvalid(currentFormAuthMercuryGroup, 'login')">
                <input type="text" id="authMercuryLogin" placeholder="Логин" class="itech-control-medium" formControlName="login">
                <app-validation-tooltip [input]="currentFormAuthMercuryGroup.controls['login']"></app-validation-tooltip>
              </label>
            </div>
            <div class="form-group">
              <label for="authMercuryPassword" class="required">Пароль</label>
              <label for="authMercuryPassword" aria-haspopup="true" role="tooltip"
                     class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                     [class.invalid]="isInvalid(currentFormAuthMercuryGroup, 'password')">
                <input type="password" id="authMercuryPassword" placeholder="Пароль" class="itech-control-medium"
                       formControlName="password">
                <app-validation-tooltip [input]="currentFormAuthMercuryGroup.controls['password']"></app-validation-tooltip>
              </label>
            </div>
          </ng-container>
          <ng-template #authStatusTelCode>
            <ng-container *ngIf="currentFormGroup.controls['authMercuryStatusId'].value === 2">
              <div class="form-group">
                <label for="authMercuryTelCode" class="required">Код из смс</label>
                <label for="authMercuryTelCode" aria-haspopup="true" role="tooltip"
                       class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                       [class.invalid]="isInvalid(currentFormAuthMercuryGroup, 'telCode')">
                  <input type="text" id="authMercuryTelCode" placeholder="Код из смс" class="itech-control-medium"
                         formControlName="telCode">
                  <app-validation-tooltip [input]="currentFormAuthMercuryGroup.controls['telCode']"></app-validation-tooltip>
                </label>
              </div>
            </ng-container>
          </ng-template>
          <a class="itech-person-link not-icon" style="font-size:12px" (click)="tryAuthMercury()">
            Войти
          </a>
        </section>
      </div>
    </ng-template>
  </ng-template>
</ng-container>
