/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./version-history.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../node_modules/@clr/angular/clr-angular.ngfactory";
import * as i4 from "@clr/angular";
import * as i5 from "./version-history.component";
import * as i6 from "../../../app.version-history";
import * as i7 from "../../../logic/services/app-navigation.service";
var styles_VersionHistoryComponent = [i0.styles];
var RenderType_VersionHistoryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VersionHistoryComponent, data: {} });
export { RenderType_VersionHistoryComponent as RenderType_VersionHistoryComponent };
function View_VersionHistoryComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [["class", "version-change"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_VersionHistoryComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [["class", "version-change"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_VersionHistoryComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [["class", "version-change"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VersionHistoryComponent_5)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.changes; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.partCaption; _ck(_v, 1, 0, currVal_0); }); }
function View_VersionHistoryComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "version-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "version-no"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "version-date"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["\u00A0(\u0412\u044B\u043F\u0443\u0449\u0435\u043D\u0430 ", ")"])), (_l()(), i1.ɵeld(6, 0, null, null, 4, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VersionHistoryComponent_3)), i1.ɵdid(8, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VersionHistoryComponent_4)), i1.ɵdid(10, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_2 = _v.context.$implicit.changes; _ck(_v, 8, 0, currVal_2); var currVal_3 = _v.context.$implicit.changesPart; _ck(_v, 10, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.version; _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit.releaseDate; _ck(_v, 5, 0, currVal_1); }); }
function View_VersionHistoryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "clr-modal", [["class", "itech-version-history"]], [[2, "open", null]], [[null, "clrModalOpenChange"], ["body", "keyup.escape"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("body:keyup.escape" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).close() !== false);
        ad = (pd_0 && ad);
    } if (("clrModalOpenChange" === en)) {
        var pd_1 = ((_co.appNavigationService.versionHistoryOpened = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_ClrModal_0, i3.RenderType_ClrModal)), i1.ɵprd(8704, null, i4.ɵed, i4.ɵed, [i2.DOCUMENT]), i1.ɵprd(1024, null, i4.ɵo, i4.ɵp, []), i1.ɵdid(3, 704512, null, 0, i4.ClrModal, [i4.ɵed, i4.ClrCommonStringsService, i1.PLATFORM_ID, i4.ɵo], { _open: [0, "_open"], closable: [1, "closable"], size: [2, "size"] }, { _openChanged: "clrModalOpenChange" }), (_l()(), i1.ɵeld(4, 0, null, 1, 1, "h3", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0418\u0441\u0442\u043E\u0440\u0438\u044F \u0438\u0437\u043C\u0435\u043D\u0435\u043D\u0438\u0439 \u0418\u0421 \u0412\u0435\u0442\u0435\u0440\u0438\u043D\u0430\u0440\u0438\u044F"])), (_l()(), i1.ɵeld(6, 0, null, 2, 3, "div", [["class", "modal-body"]], [[1, "tabindex", 0]], [[null, "focus"], [null, "mousedown"], [null, "mouseup"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).focus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mousedown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).mouseDown() !== false);
        ad = (pd_1 && ad);
    } if (("mouseup" === en)) {
        var pd_2 = (i1.ɵnov(_v, 7).mouseUp() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 16384, null, 0, i4.ɵee, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VersionHistoryComponent_2)), i1.ɵdid(9, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(10, 0, null, 3, 6, "div", [["class", "modal-footer itech-form-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 3, "button", [["class", "btn btn-link"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.appNavigationService.versionHistoryOpened = false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "clr-icon", [["shape", "times"]], null, null, null, null, null)), i1.ɵdid(15, 16384, null, 0, i4.ClrIconCustomTag, [], null, null), (_l()(), i1.ɵted(-1, null, ["\u00A0\u0417\u0430\u043A\u0440\u044B\u0442\u044C "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.appNavigationService.versionHistoryOpened; var currVal_2 = true; var currVal_3 = "lg"; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); var currVal_5 = _co.appVersionHistory.versions; _ck(_v, 9, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3)._open; _ck(_v, 0, 0, currVal_0); var currVal_4 = "0"; _ck(_v, 6, 0, currVal_4); }); }
export function View_VersionHistoryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_VersionHistoryComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.appNavigationService.versionHistoryOpened; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_VersionHistoryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-version-history-modal", [], null, null, null, View_VersionHistoryComponent_0, RenderType_VersionHistoryComponent)), i1.ɵdid(1, 49152, null, 0, i5.VersionHistoryComponent, [i6.AppVersionHistory, i7.AppNavigationService], null, null)], null, null); }
var VersionHistoryComponentNgFactory = i1.ɵccf("app-version-history-modal", i5.VersionHistoryComponent, View_VersionHistoryComponent_Host_0, {}, {}, []);
export { VersionHistoryComponentNgFactory as VersionHistoryComponentNgFactory };
