import { OnChanges } from '@angular/core';
import { StableDataService } from '../../../logic/services/stable-data.service';
import { FormBuilder } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { StringHelper } from '../../../helpers/string-helper';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { combineLatest, of } from 'rxjs';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { JobRunnerUiService } from '../../../ui/infrastructure/job-runner-ui.service';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { finalize } from 'rxjs/operators';
var EditStableProductSubtypeComponent = /** @class */ (function () {
    function EditStableProductSubtypeComponent(lookupService, fb, stableDataService, jobRunnerUiService, alertService, cacheService, dataCachingService) {
        this.lookupService = lookupService;
        this.fb = fb;
        this.stableDataService = stableDataService;
        this.jobRunnerUiService = jobRunnerUiService;
        this.alertService = alertService;
        this.cacheService = cacheService;
        this.dataCachingService = dataCachingService;
        this._searchResults = { all: [], withoutDeleted: [] };
        this.currentIsNew = false;
        this.editModalOpened = false;
        this.isShowDeleted = false;
        this.storing = false;
        this.isInvalid = FormHelper.isInvalid;
        this.gridColumnDefs = [
            {
                headerName: 'Владелец ТМ', field: 'ownerAgentCaption', width: 200,
            },
            {
                headerName: 'Производитель', field: 'producerAgentCaption', width: 200,
            },
            {
                headerName: 'Тип продукции', field: 'productTypeCaption', width: 200,
            },
            {
                headerName: 'Подтип продукции', field: 'productSubtypeCaption', width: 200,
            },
            {
                headerName: 'Наименование', field: 'caption', width: 200,
            },
            {
                headerName: 'Меркурий guid', field: 'mercuryGuid', width: 200,
            },
            {
                headerName: 'Дата удаления', field: 'dateDeleted', width: 200,
                valueFormatter: function (params) { return StringHelper.getRuDate(params.value); },
            },
            {
                headerName: 'Формула срока годности', field: 'calcExpireDate', width: 200,
            },
        ];
    }
    EditStableProductSubtypeComponent.prototype.ngOnChanges = function () {
        var existing = this.dataCachingService.getCachedData('StableProductSubtype', this.stableId);
        if (existing) {
            this._searchResults = existing;
        }
        else {
            this.refresh();
        }
    };
    EditStableProductSubtypeComponent.prototype.refresh = function () {
        this.dataCachingService.removeCachedData('StableProductSubtype', this.stableId);
        this.lookupService.invalidateLookup('stable-product-subtype/stable/false/' + this.stableId);
        this.lookupService.invalidateLookup('stable-product-subtype/stable/include-unknown/' + this.stableId, true);
        this.refreshResults();
    };
    EditStableProductSubtypeComponent.prototype.getGroupDef = function (val) {
        return {
            stableId: val && val.stableId ? val.stableId : this.stableId,
            productTypeId: val.productTypeId,
            productSubtypeId: val.productSubtypeId,
            id: val.id,
            caption: val.caption,
            isDefault: val.isDefault,
            mercuryGuid: val.mercuryGuid,
            mercuryRecversionGuid: val.mercuryRecversionGuid,
            dateDeleted: val.dateDeleted,
            producerAgentId: val.producerAgentId,
            ownerAgentId: val.ownerAgentId,
            calcExpireDate: val.calcExpireDate,
        };
    };
    EditStableProductSubtypeComponent.prototype.refreshResults = function () {
        var _this = this;
        this.currentIsNew = false;
        this.currentFormGroup = undefined;
        this.editModalOpened = false;
        combineLatest([this.lookupService.getLookupObj('product-subtype'),
            this.lookupService.getLookupObj('product-type'),
            this.stableDataService.getAllProductSubtypes(this.stableId)])
            .subscribe(function (_a) {
            var productSubtypeLookup = _a[0], productTypeLookup = _a[1], data = _a[2];
            data.forEach(function (el) {
                combineLatest([
                    el.producerAgentId ? _this.cacheService.getAgent(el.producerAgentId) : of(null),
                    el.ownerAgentId ? _this.cacheService.getAgent(el.ownerAgentId) : of(null)
                ])
                    .subscribe(function (_a) {
                    var producer = _a[0], owner = _a[1];
                    el.productTypeCaption = productTypeLookup[el.productTypeId];
                    var productSubtypeRec = productSubtypeLookup[el.productTypeId].find(function (ptst) { return ptst.id === el.productSubtypeId; });
                    el.productSubtypeCaption = productSubtypeRec ? productSubtypeRec.caption : undefined;
                    el.producerAgentCaption = producer ? producer.shortCaption || producer.caption : '-';
                    el.ownerAgentCaption = owner ? owner.shortCaption || owner.caption : '-';
                });
            });
            _this._searchResults.all = data;
            _this._searchResults.withoutDeleted = data.filter(function (x) { return !x.dateDeleted; });
            _this.dataCachingService.addToCache('StableProductSubtype', _this.stableId, _this._searchResults);
        });
    };
    EditStableProductSubtypeComponent.prototype.updateFromMercuryAndRefreshFormData = function () {
        var _this = this;
        this.jobRunnerUiService.runOperation(15, /* Актуализация номенклатуры поднадзорного объекта */ {
            type: 'updateProductItemsStableSync',
            stableId: this.stableId
        }, undefined, undefined, true)
            .subscribe(function (data) {
            _this.alertService.success(data);
            _this.refresh();
        });
    };
    EditStableProductSubtypeComponent.prototype.getRowData = function () {
        return this.isShowDeleted ? this._searchResults.all : this._searchResults.withoutDeleted;
    };
    EditStableProductSubtypeComponent.prototype.getRowStyle = function (params) {
        if (params.data && params.data.dateDeleted) {
            return { color: 'silver' };
        }
    };
    EditStableProductSubtypeComponent.prototype.editRow = function ($event) {
        this.currentFormGroup = this.fb.group(this.getGroupDef($event.data));
        this.editModalOpened = true;
    };
    EditStableProductSubtypeComponent.prototype.storeRecord = function () {
        var _this = this;
        if (!this.currentFormGroup) {
            return;
        }
        FormHelper.markAsSubmitted(this.currentFormGroup);
        if (!this.currentFormGroup.valid) {
            return;
        }
        this.storing = true;
        this.stableDataService.storeStableProductSubtype(this.currentFormGroup.value.id, this.currentFormGroup.value)
            .pipe(finalize(function () { return _this.storing = false; }))
            .subscribe(function () {
            _this.refresh();
            _this.currentFormGroup = undefined;
            _this.editModalOpened = false;
        });
    };
    return EditStableProductSubtypeComponent;
}());
export { EditStableProductSubtypeComponent };
