import { AlertService } from '../../../ui/infrastructure/alert.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstitutionDataService } from '../../../logic/services/institution-data.service';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { StringHelper } from '../../../helpers/string-helper';
var EditInstitutionEmployeesComponent = /** @class */ (function () {
    function EditInstitutionEmployeesComponent(institutionDataService, alertService, dataCachingService, waitingOverlay, lookupSourceService, appNavigationService, fb) {
        var _this = this;
        this.institutionDataService = institutionDataService;
        this.alertService = alertService;
        this.dataCachingService = dataCachingService;
        this.waitingOverlay = waitingOverlay;
        this.lookupSourceService = lookupSourceService;
        this.appNavigationService = appNavigationService;
        this.fb = fb;
        this.model = {};
        this.storing = false;
        this.addEmployeeModalOpened = false;
        this.gridColumnDefs = [
            {
                headerName: '№', field: 'id', width: 90,
                onCellClicked: function (data) { return _this.editEmployee(data.data); }
            },
            {
                headerName: 'Фамилия', field: 'lastName', width: 140,
                onCellClicked: function (data) { return _this.editEmployee(data.data); }
            },
            {
                headerName: 'Имя', field: 'firstName', width: 140,
                onCellClicked: function (data) { return _this.editEmployee(data.data); }
            },
            {
                headerName: 'Отчество', field: 'middleName', width: 140,
                onCellClicked: function (data) { return _this.editEmployee(data.data); }
            },
            {
                headerName: 'Дата рождения', field: 'birthDate', width: 120,
                valueFormatter: function (params) { return StringHelper.getRuDate(params.value); },
                onCellClicked: function (data) { return _this.editEmployee(data.data); }
            },
            {
                headerName: 'Должность', field: 'postId',
                valueFormatter: function (params) { return _this.institutionPostLookup[params.value]; },
                onCellClicked: function (data) { return _this.editEmployee(data.data); },
                comparator: function (a, b) {
                    return _this.stringComparator(_this.institutionPostLookup[a], _this.institutionPostLookup[b]);
                }
            },
            {
                headerName: 'Пункт, участок, лаборатория', field: 'branchId',
                valueFormatter: function (params) { return _this.institutionBranchLookup[params.value]; },
                onCellClicked: function (data) { return _this.editEmployee(data.data); },
                comparator: function (a, b) {
                    return _this.stringComparator(_this.institutionBranchLookup[a], _this.institutionBranchLookup[b]);
                }
            },
            {
                headerName: 'Населенный пункт по умолчанию', field: 'defaultCityId',
                valueGetter: function (params) { return params.data.defaultCityId ? params.data.defaultRegionId + '-' + params.data.defaultCityId : null; },
                valueFormatter: function (params) { return _this.employeeRegionIdCityIdLookup[params.value]; },
                onCellClicked: function (data) { return _this.editEmployee(data.data); },
                comparator: function (a, b) {
                    return _this.stringComparator(_this.employeeRegionIdCityIdLookup[a], _this.employeeRegionIdCityIdLookup[b]);
                }
            },
            {
                headerName: 'Дата увольнения', field: 'retireDate',
                valueFormatter: function (params) { return StringHelper.getRuDate(params.value); },
                onCellClicked: function (data) { return _this.editEmployee(data.data); }
            },
        ];
        this.currentIsNew = false;
        this.institutionBranchLookup = {};
        this.institutionPostLookup = {};
        this.employeeRegionIdCityIdLookup = {};
    }
    Object.defineProperty(EditInstitutionEmployeesComponent.prototype, "employees", {
        get: function () {
            return this.model.employees;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditInstitutionEmployeesComponent.prototype, "id", {
        get: function () {
            return this._id;
        },
        set: function (id) {
            var _this = this;
            if (this._id !== id) {
                this._id = id;
                this.lookupSourceService.getLookupObj('institution-post').subscribe(function (lookup) { return _this.institutionPostLookup = lookup; });
                this.lookupSourceService.getLookupObj('institution-branch' + id).subscribe(function (lookup) { return _this.institutionBranchLookup = lookup; });
                this.lookupSourceService.getLookupObj('addr-city').subscribe(function (lookup) { return _this.employeeRegionIdCityIdLookup = lookup; });
                this.refreshFormDataInternal();
            }
        },
        enumerable: true,
        configurable: true
    });
    EditInstitutionEmployeesComponent.prototype.stringComparator = function (s1, s2) {
        if (!s1 && !s2) {
            return 0;
        }
        if (!s1) {
            return 1;
        }
        if (!s2) {
            return -1;
        }
        if (s1.toLowerCase() < s2.toLowerCase()) {
            return -1;
        }
        if (s1.toLowerCase() > s2.toLowerCase()) {
            return 1;
        }
        return 0;
    };
    EditInstitutionEmployeesComponent.prototype.getRowStyle = function (params) {
        if (params.data && params.data.retireDate) {
            return { color: 'silver' };
        }
    };
    EditInstitutionEmployeesComponent.prototype.isInvalid = function (fg, cname) {
        return FormHelper.isInvalid(fg, cname);
    };
    EditInstitutionEmployeesComponent.prototype.getEmployeeGroupDef = function (val) {
        var _this = this;
        if (val === void 0) { val = {}; }
        return {
            id: val.id,
            lastName: [val.lastName, Validators.required],
            firstName: [val.firstName, Validators.required],
            middleName: val.middleName,
            fullFioGen: val.fullFioGen,
            birthDate: [val.birthDate, Validators.compose([Validators.required, FormHelper.validateDateTimePicker()])],
            postId: [val.postId, Validators.required],
            branchId: [val.branchId, Validators.required],
            retireDate: [val.retireDate, FormHelper.validateDateTimePicker()],
            defaultRegionId: val.defaultRegionId,
            defaultCityId: val.defaultCityId,
            regionIdCityId: val.defaultRegionId && val.defaultCityId ? val.defaultRegionId.toString()
                + '-' + val.defaultCityId.toString() : undefined,
            asoiRoles: (val.asoiRoles && val.asoiRoles.length
                ? this.fb.array(val.asoiRoles.map(function (el) { return _this.fb.control(el); }))
                : this.fb.array([])),
            email: val.email,
        };
    };
    EditInstitutionEmployeesComponent.prototype.buildFormArray = function (branchesData) {
        var _this = this;
        return this.fb.array(branchesData.map(function (item) { return _this.fb.group(_this.getEmployeeGroupDef(item)); }));
    };
    EditInstitutionEmployeesComponent.prototype.store = function () {
        var _this = this;
        this.storing = true;
        this.institutionDataService.storeInstitutionEmployees(this.id, this.model.employees, this.model.delete)
            .subscribe(function (val) {
            _this.storing = false;
            // обязательно обновляем форму из БД - чтобы загрузить идшники для новых сотрудников
            _this.refreshFormData();
            // обновим поисковый кэш работников (чтобы комбобоксы заполнились актуальными данными, там где работники используются)
            _this.lookupSourceService.invalidateLookup('institution-employee' + _this.id.toString());
        }, function (error) {
            _this.storing = false;
        });
    };
    EditInstitutionEmployeesComponent.prototype.cancelEdit = function () {
        this.dataCachingService.removeCachedData('EditInstitutionEmployees', this.id.toString());
        this.refreshFormDataInternal();
    };
    EditInstitutionEmployeesComponent.prototype.refreshFormData = function () {
        this.dataCachingService.removeCachedData('EditInstitutionServices', this.id.toString());
        this.dataCachingService.removeCachedData('EditInstitutionEmployeeServices', this.id.toString());
        this.dataCachingService.removeCachedData('EditInstitutionEmployees', this.id.toString());
        this.refreshFormDataInternal();
    };
    EditInstitutionEmployeesComponent.prototype.refreshFormDataInternal = function () {
        var _this = this;
        this.waitingOverlay.StartWaiting();
        var existing = this.dataCachingService.getCachedData('EditInstitutionEmployees', this.id.toString());
        if (existing) {
            this.model = existing;
            this.waitingOverlay.EndWaiting();
        }
        else {
            this.institutionDataService.getInstitutionEmployeesForEdit(this.id).subscribe(function (data) {
                _this.model = { id: _this.id, delete: [], employees: data };
                _this.dataCachingService.addToCache('EditInstitutionEmployees', _this.id.toString(), _this.model);
                _this.waitingOverlay.EndWaiting();
            }, function () {
                _this.waitingOverlay.EndWaiting();
            });
        }
    };
    EditInstitutionEmployeesComponent.prototype.addEmployee = function () {
        this.currentFormGroup = this.fb.group(this.getEmployeeGroupDef());
        delete this.services;
        this.currentIsNew = true;
        this.currentEmployee = this.currentFormGroup.value;
        this.addEmployeeModalOpened = true;
    };
    EditInstitutionEmployeesComponent.prototype.editEmployee = function (employee) {
        this.currentFormGroup = this.fb.group(this.getEmployeeGroupDef(employee));
        delete this.services;
        this.currentIsNew = false;
        this.currentEmployee = employee;
        this.addEmployeeModalOpened = true;
    };
    EditInstitutionEmployeesComponent.prototype.addEmployeeModalOpenedChanged = function () {
        var _this = this;
        this.addEmployeeModalOpened = false;
        // иначе ag-grid не перезагрузит список
        var empl = this.model.employees;
        this.model.employees = [];
        setTimeout(function () {
            _this.model.employees = empl;
        });
    };
    return EditInstitutionEmployeesComponent;
}());
export { EditInstitutionEmployeesComponent };
