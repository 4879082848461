
      <clr-modal *ngIf="contextFormGroup && modalOpened" [(clrModalOpen)]="modalOpened"
                 [clrModalSize]="'lg'">
        <h3 class="modal-title">{{contextFormGroup.controls['id'].value ? 'Редактирование' : 'Добавление'}} тэга</h3>

        <div class="modal-body" style="min-height:200px">
          <div class="form compact" [formGroup]="contextFormGroup">
            <app-doc-validation-errors [validationErrors]="contextFormGroup.docLevelServerSideValidationErrors">
            </app-doc-validation-errors>

            <section class="form-block itech-block-normal" [formGroup]="contextFormGroup">
              <div class="form-group">
                <label for="caption" class="required">Наименование</label>
                <label for="caption" aria-haspopup="true" role="tooltip"
                       class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                       [class.invalid]="isInvalid(contextFormGroup, 'caption')">
                  <input type="text" id="caption" class="itech-control-xlarge" placeholder="Наименование"
                         formControlName="caption" maxlength="20">
                  <app-validation-tooltip [input]="contextFormGroup.controls['caption']"></app-validation-tooltip>
                </label>
              </div>
              <div class="form-group">
                <label for="groupCaption">Группа</label>
                <label for="groupCaption" aria-haspopup="true" role="tooltip"
                       class="tooltip tooltip-validation tooltip-md"
                       [class.invalid]="isInvalid(contextFormGroup, 'groupCaption')">
                  <input type="text" id="groupCaption" class="itech-control-xlarge" placeholder="Наименование"
                         formControlName="groupCaption" maxlength="20">
                  <app-validation-tooltip [input]="contextFormGroup.controls['groupCaption']"></app-validation-tooltip>
                </label>
              </div>
              <div class="form-group" style="min-height: 65px">
                <label for="value" class="required">Содержимое</label>
                <label for="value" aria-haspopup="true" role="tooltip"
                       class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                       [class.invalid]="isInvalid(contextFormGroup, 'value')">
                    <textarea type="text" id="value" placeholder="Содержимое тэга"
                              style="resize:none;width:500px;height:50px" maxlength="200"
                              class="itech-control-xlarge itech-text-wall" formControlName="value">
                    </textarea>
                  <app-validation-tooltip [input]="contextFormGroup.controls['value']"></app-validation-tooltip>
                </label>
              </div>
              <div class="form-group">
                <label></label>
                <div class="itech-control-desc" [style.color]="'#000'">
                  Максимальная длина 200 символов.
                  {{contextFormGroup.controls['value'].value ? ' Осталось:' + (200 - contextFormGroup.controls['value'].value.length) : ''}}
                </div>
              </div>
            </section>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="acceptModal()">
            <clr-icon shape="check"></clr-icon>&nbsp;Сохранить
          </button>
          <button type="button" class="btn btn-warning" (click)="modalOpened = false">
            <clr-icon shape="undo"></clr-icon>&nbsp;Отмена
          </button>
        </div>

      </clr-modal>
  