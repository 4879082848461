import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AddressComponent } from '../../../ui/controls/address.component';
import { ServerSideErrorsProvider } from '../../../logic/validators/server-side-errors-provider';
import { serverSideErrorsValidator } from '../../../logic/validators/server-side-errors-validator.directive';
import { AppFormGroup } from '../../../ui/controls/app-form-group';

@Component({
  selector: 'app-edit-institution-common-form',
  templateUrl: './edit-institution-common-form.component.html'
})
export class EditInstitutionCommonFormComponent {

  @Input() contextFormGroup: AppFormGroup;

  public static createFormGroup(fb: FormBuilder, serverSideErrorsProvider: ServerSideErrorsProvider, val: any) {
    return fb.group({
      id: val.id,
      caption: [val.caption, Validators.compose([Validators.required,
        serverSideErrorsValidator('caption', serverSideErrorsProvider)])],
      shortCaption: [val.shortCaption, Validators.compose([Validators.required,
        serverSideErrorsValidator('shortCaption', serverSideErrorsProvider)])],
      legalFormId: [val.legalFormId, Validators.compose([Validators.required,
        serverSideErrorsValidator('legalFormId', serverSideErrorsProvider)])],
      institutionTypeId: [val.institutionTypeId, Validators.compose([Validators.required,
        serverSideErrorsValidator('institutionTypeId', serverSideErrorsProvider)])],
      headInstitutionId: [val.headInstitutionId, Validators.compose([
        serverSideErrorsValidator('headInstitutionId', serverSideErrorsProvider)])],
      phone: [val.phone, serverSideErrorsValidator('phone', serverSideErrorsProvider)],
      email: [val.email, serverSideErrorsValidator('email', serverSideErrorsProvider)],
      otherTextInfo: [val.otherTextInfo, serverSideErrorsValidator('otherTextInfo', serverSideErrorsProvider)],
      ogrn: [val.ogrn, Validators.compose([Validators.pattern(/^\d*$/),
        serverSideErrorsValidator('ogrn', serverSideErrorsProvider)])],
      dateRegistered: [val.dateRegistered, Validators.compose([Validators.required, FormHelper.validateDateTimePicker(),
        serverSideErrorsValidator('dateRegistered', serverSideErrorsProvider)])],
      address: fb.group(AddressComponent.buildFormGroupDef(fb, serverSideErrorsProvider, val.address)),
      inn: [val.inn, Validators.compose([Validators.pattern(/^\d*$/),
        serverSideErrorsValidator('inn', serverSideErrorsProvider)])],
      kpp: [val.kpp, Validators.compose([Validators.pattern(/^\d*$/),
        serverSideErrorsValidator('kpp', serverSideErrorsProvider)])],
      bankAccount: [val.bankAccount, Validators.compose([Validators.pattern(/^\d*$/),
        serverSideErrorsValidator('bankAccount', serverSideErrorsProvider)])],
      bankPAccount: [val.bankPAccount, Validators.compose([
        serverSideErrorsValidator('bankPAccount', serverSideErrorsProvider)])],
      bankBik: [val.bankBik, Validators.compose([Validators.pattern(/^\d*$/),
        serverSideErrorsValidator('bankBik', serverSideErrorsProvider)])],
      workingRegionId: fb.array(val.workingRegionId || []),
    });
  }

  isInvalid(fg: FormGroup, cname: string) {
    return FormHelper.isInvalid(fg, cname);
  }
}
