<div class="itech-tickets-cont">
  <div class="itech-tickets-top-actions-cntr">

    <a *ngIf="!model.zoomedDays" class="itech-inline-link-btn itech-link" title="Внесение талонов по расписанию"
       (click)="distributeTickets({})">
      <clr-icon shape="bolt"></clr-icon>
    </a>

    <clr-signpost class="itech-sync-status-lbl" *ngIf="!model.zoomedDays">
      <a class="itech-inline-link-btn itech-link" clrSignpostTrigger title="Шаблоны">
        <clr-icon shape="file-settings"></clr-icon>
      </a>
      <clr-signpost-content [clrPosition]="'right-bottom'" class="compact form itech-highlighted-sections"
                            style="max-width:21rem" *clrIfOpen>
        <h6 style="margin-top:0;font-weight:bold">Шаблоны</h6>
        <div *ngFor="let template of model.ticketTemplates; index as i">
          <button type="button" class="btn btn-link" style="margin:0;padding:0;min-width:20px;"
                  (click)="distributeTickets(template)" clrSignpostTrigger>
            <clr-icon shape="bolt"></clr-icon>
          </button>
          <button type="button" class="btn btn-link" style="margin:0;padding:0;min-width:20px;"
                  (click)="editTemplate(template)" clrSignpostTrigger>
            <clr-icon shape="pencil"></clr-icon>
          </button>
          {{template.templateCaption}}
        </div>
        <button type="button" class="btn btn-link" style="margin:0;padding:0;min-width:20px;" clrSignpostTrigger
                (click)="ticketPlanningUiService.createTemplate({ institutionId: workingInstitutionId })">
          <clr-icon shape="plus"></clr-icon>&nbsp;Добавить шаблон
        </button>
      </clr-signpost-content>
    </clr-signpost>

    <a *ngIf="model.zoomedDays" class="itech-link itech-move-back" (click)="unZoom()"
       title="Вернуться обратно">
      <clr-icon shape="undo"></clr-icon>&nbsp;Назад
    </a>

    <div *ngIf="!model.zoomedDays"
         class="itech-tickets-calendar">
      <a class="itech-link" title="Предыдущий месяц" (click)="navigatePreviousMonth()">
        <clr-icon shape="rewind"></clr-icon>
      </a>
      <div style="line-height: 14px;margin-left: 3px;margin-right: 3px;">{{currentMonthDate | rmonth}}</div>
      <a class="itech-link" title="Следующий месяц" (click)="navigateNextMonth()">
        <clr-icon shape="fast-forward"></clr-icon>
      </a>
    </div>

    <div class="form form-group itech-group-whole-line" *ngIf="!model.zoomedDays">
      <app-combo-lookup [validationTooltipRight]="true"
                        [contextControlId]="'institutionBranchId'"
                        [contextControlName]="'institutionBranchId'"
                        [lookupName]="'institution-branch' + model.institutionId"
                        [contextFormGroup]="model.searchParams"></app-combo-lookup>
    </div>

    <button *ngIf="!model.zoomedDays" type="button" class="btn btn-link no-focus-border" (click)="reloadDays()"
            style="padding: 0px 11px 9px 0; margin: 0px 0px -7px 0; line-height: 12px;"
            title="Обновить данные"> <clr-icon shape="refresh"></clr-icon>
    </button>
  </div>
  <div class="itech-ticket-planning-days-wrap">
    <div *ngIf="!model.zoomedDays" class="itech-ticket-planning-days-container">
      <app-tickets-planning-day *ngFor="let day of model.days; index as i" [day]="day" [model]="model"
                                [workingInstitutionId]="workingInstitutionId" (click)="zoomDay(i)">
      </app-tickets-planning-day>
    </div>

    <div *ngIf="model.zoomedDays" class="itech-ticket-planning-days-container itech-zoomed">
      <a class="itech-link itech-inline-link-btn" (click)="zoomDay(model.zoomedDayIndex - 1)">
        <clr-icon size="32" shape="rewind" class="is-solid"></clr-icon>
      </a>

      <app-tickets-planning-day *ngFor="let day of model.zoomedDays" [day]="day" [model]="model" [zoomedMode]="true"
                                 [fixedEmployeeMode]="fixedEmployeeMode"
                                 [workingEmployeeId]="workingEmployeeId"
                                 [workingInstitutionId]="workingInstitutionId">
      </app-tickets-planning-day>

      <a class="itech-link itech-inline-link-btn" (click)="zoomDay(model.zoomedDayIndex + 1)">
        <clr-icon size="32" shape="fast-forward" class="is-solid"></clr-icon>
      </a>
    </div>
  </div>

</div>

<app-ticket-edit-modal [model]="model" (forceReloadEmitter)="reloadDays()"></app-ticket-edit-modal>
<app-ticket-delete-modal [model]="model"></app-ticket-delete-modal>
