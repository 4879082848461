import { Component } from '@angular/core';
import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-ag-grid-text-lookup-editor',
  template: `
    <app-text-lookup class="app-ag-grid-text-lookup-editor"
                     [contextControlId]="'textLookupEditor' + rndToken" (change)="changeValue()" [sorted]="true"
                     [focusAfterRender]="true" [fixedWidthPx]="params.colDef.width - 2" [fixedHeightPx]="30"
                     [lookupName]="this.params.lookupName +
                      (this.params.parentFieldLookup ? this.params.data.get(this.params.parentFieldLookup).value : '')"
                     [contextFormGroup]="formGroup" [contextControlName]="controlName">
    </app-text-lookup>
  `,
})
export class AgGridTextLookupEditorComponent implements ICellEditorAngularComp {

  public params: any;
  public formGroup: FormGroup;
  public controlName: string;

  rndToken = Math.floor(Math.random() * 1000000);

  agInit(params: any): void {
    this.params = params;
    this.formGroup = this.params.data;
    this.controlName = this.params.controlName;
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  getValue(): any {
    return this.formGroup.get(this.controlName).value;
  }

  changeValue() {
    if (this.params.colDef.valueChangedCallback) {
      this.params.colDef.valueChangedCallback(this.getValue(), this.params.data, this.params.node, this.params.api);
    }

    setTimeout(() => this.params.api.stopEditing(), 500);
  }

  isPopup(): boolean {
    return true;
  }
}
