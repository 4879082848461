import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { StableSearchResultsFormComponent } from './stable-search-results-form.component';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';

@Component({
  selector: 'app-stable-search-results',
  templateUrl: './stable-search-results.component.html'
})
export class StableSearchResultsComponent implements OnInit {

  model = StableSearchResultsFormComponent.getDefaultModel();

  constructor(private route: ActivatedRoute,
              private dataCachingService: DataCachingService,
              public appNavigationService: AppNavigationService,
              private fb: FormBuilder) {
  }

  ngOnInit() {

    this.route.paramMap
      .subscribe(params => {

        const existing = this.dataCachingService.getCachedData('StableSearchResults', '1');

        if (existing) {
          this.model = existing;
        } else {
          this.model = StableSearchResultsFormComponent.getDefaultModel(this.fb);
          this.dataCachingService.addToCache('StableSearchResults', '1', this.model);
        }
      });
  }
}
