import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { FormBuilder } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { ServerSideErrorsProvider } from '../../../logic/validators/server-side-errors-provider';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';
import { EditAnimalCommonFormComponent } from './edit-animal-common-form.component';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { AppFormGroup } from '../../../ui/controls/app-form-group';
import { AnimalDataService } from '../../../logic/services/animal-data.service';
import { InstitutionTicketDataService } from '../../../logic/services/institution-ticket-data.service';

@Component({
  selector: 'app-edit-animal-common',
  templateUrl: './edit-animal-common.component.html'
})
export class EditAnimalCommonComponent implements ServerSideErrorsProvider, OnChanges {
  @Input() id: number;
  @Input() newAgentId: number;
  @Input() newStableId: number;
  @Input() isExternalAnimal = false;
  @Output() onNavigateEventsClicked: EventEmitter<any> = new EventEmitter<any>();

  model: any = {};
  storing = false;
  serverSideValidationErrors: any[];

  isInvalid = FormHelper.isInvalid;

  get contextFormGroup(): AppFormGroup {
    return this.model ? this.model.contextFormGroup : undefined;
  }

  constructor(private bookmarkService: BookmarkService,
              private animalDataService: AnimalDataService,
              private alertService: AlertService,
              private cache: AddressPersonFioCacheService,
              private dataCachingService: DataCachingService,
              private waitingOverlay: GlobalWaitingOverlayService,
              private fb: FormBuilder,
              public navigationService: AppNavigationService,
              private institutionTicketDataService: InstitutionTicketDataService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.id) {
      this.refreshFormDataInternal();
    }
  }

  store() {

    FormHelper.markAsSubmitted(this.contextFormGroup);

    if (!this.contextFormGroup.valid) {
      return;
    }

    this.storing = true;

    this.animalDataService.storeAnimalCommonInfo(this.contextFormGroup.value).subscribe(val => {
      this.storing = false;
      this.invalidateCaches();
      this.bookmarkService.refreshAnimalCardBookmark(this.id, this.newAgentId, this.newStableId);
      FormHelper.setSingleFormGroupServerSideValidationErrors({}, this.model, this.contextFormGroup);
      this.alertService.success('Общая информация для [' +
        (this.contextFormGroup.get('caption').value || this.contextFormGroup.get('pin').value) + '] успешно сохранена');
      this.refreshFormData();
    }, error => {
      this.storing = false;
      FormHelper.setSingleFormGroupServerSideValidationErrors(error, this.model, this.contextFormGroup);
      window.document.getElementById('animal-common-content').scrollTop = 0;
    });
  }

  private invalidateCaches(isExternalAnimal: boolean = false) {
    if (!isExternalAnimal) {
      this.cache.invalidateAnimal(this.id);
    } else {
      this.cache.invalidateAnimalExternal(this.contextFormGroup.get('tempId').value);
    }
    const agentId = this.contextFormGroup.get('currentAgentId').value;
    if (agentId) {
      this.dataCachingService.removeCachedData('EditAgentAnimals', agentId.toString());
    }
    const stableId = this.contextFormGroup.get('currentStableId').value;
    if (stableId) {
      this.dataCachingService.removeCachedData('EditStableAnimals', stableId.toString());
    }
  }

  cancelEdit() {
    this.dataCachingService.removeCachedData('EditAnimalCommon', this.id.toString());
    this.refreshFormDataInternal();
  }

  refreshFormData() {
    this.dataCachingService.removeCachedData('EditAnimalCommon', this.id.toString());
    this.refreshFormDataInternal();
  }

  refreshFormDataInternal() {
    this.waitingOverlay.StartWaiting();

    this.model = {id: this.id, serverSideValidationErrors: []};

    const existing = this.dataCachingService.getCachedData('EditAnimalCommon' +
      (this.isExternalAnimal ? '_External' : ''), this.id.toString());

    if (existing && existing.newAgentId === this.newAgentId && existing.newStableId === this.newStableId) {
      this.model = existing;
      this.waitingOverlay.EndWaiting();
    } else {
      (!this.isExternalAnimal
        ? this.animalDataService.getAnimalCommonForEdit(this.id)
        : this.animalDataService.getTempAnimalCommonForEdit(this.id))
      .subscribe({
        next: data => {
          this.model.id = data.id;
          this.model.contextFormGroup = EditAnimalCommonFormComponent.createFormGroup(this.fb, this.model, data);
          if (this.model.contextFormGroup.get('birthDate').value && this.model.contextFormGroup.get('animalGroup').value) {
            this.model.contextFormGroup.get('birthDate').value = this.model.contextFormGroup.get('birthDate').value + 'T00:00:00';
          }
          this.model.newAgentId = this.newAgentId;
          this.model.newStableId = this.newStableId;
          this.cache.invalidateAnimal(this.id);
          this.dataCachingService.addToCache('EditAnimalCommon' +
            (this.isExternalAnimal ? '_External' : ''), this.id.toString(), this.model);

          if (!this.isExternalAnimal) {
            this.bookmarkService.refreshAnimalCardBookmark(this.id, this.newAgentId, this.newStableId);
          }

          this.waitingOverlay.EndWaiting();
        }, error: () => {
          this.waitingOverlay.EndWaiting();
        }
      });
    }
  }

  cloneAnimal() {
    this.navigationService.performAnimalCreation(undefined, undefined, this.id);
  }

  navigateToEventsPage() {
    if (this.onNavigateEventsClicked) {
      this.onNavigateEventsClicked.emit();
    }
  }

  deleteAnimal() {
    this.alertService.confirmModal('Вы уверены, что хотите удалить безвозвратно карточку животного?').subscribe(() => {
      this.animalDataService.deleteAnimal(this.id).subscribe(() => {
        this.dataCachingService.removeCachedData('EditAnimalCommon', this.id.toString());
        this.bookmarkService.removeOpenCardBookmark(['/animal-edit'], this.id.toString());
        this.invalidateCaches();
      });
    });
  }

  confirmAnimalFromExternal() {

    this.storing = true;

    this.animalDataService.confirmAnimalExternal(this.id)
      .subscribe((val) => {
        this.clearCacheExternal(val.id);
        this.tryChangeAnimalInTickets(val);
        this.alertService.success(`Карточка животного [${this.contextFormGroup.get('caption').value
        || this.contextFormGroup.get('pin').value}] успешно подтверждена`);
        this.navigationService.performAnimalEditing(val.id);
        this.storing = false;
      },
      () => {
        FormHelper.setSingleFormGroupServerSideValidationErrors({}, this.model, this.contextFormGroup);
        this.storing = true;
      });
  }

  rejectAnimalExternal() {
    this.alertService.confirmModal('Вы уверены, что хотите отказать пользователю внешней системы' +
      ' в регистрации карточки животного?').subscribe(() => {
      this.animalDataService.rejectAnimalExternal(this.id).subscribe(() => {
        this.dataCachingService.removeCachedData('EditAnimalCommon_External', this.id.toString());
        this.dataCachingService.removeCachedData('AddressPersonFioCacheService_AnimalExternal', this.id.toString(10));
        this.invalidateCaches(true);
        if (this.contextFormGroup.get('currentAgentId').value) {
          this.navigationService.performAgentEditing(this.contextFormGroup.get('currentAgentId').value);
        } else if (this.contextFormGroup.get('currentStableId').value) {
          this.navigationService.performStableEditing(this.contextFormGroup.get('currentStableId').value);
        }
      });
    });
  }

  clearCacheExternal(newId: number) {
    this.dataCachingService.removeCachedData('EditAnimalCommon', newId.toString());
    this.dataCachingService.removeCachedData('AddressPersonFioCacheService', newId.toString(10));
    this.invalidateCaches(true);
    this.dataCachingService.removeCachedData('EditAnimalCommon_External', this.id.toString());
    this.dataCachingService.removeCachedData('AddressPersonFioCacheService_AnimalExternal', this.id.toString(10));
    FormHelper.setSingleFormGroupServerSideValidationErrors({}, this.model, this.contextFormGroup);
  }

  tryChangeAnimalInTickets(animal: any) {
    this.institutionTicketDataService.searchByAnimal(animal.id)
      .subscribe(tickets =>
        tickets.forEach(ticket =>
          this.dataCachingService.removeCachedDataByStartKey('TicketsPlanningComponent', ticket.institutionId)));
    if (animal.currentAgentId) {
      this.dataCachingService.removeCachedData('EditAgentTickets', animal.currentAgentId.toString());
    }
  }

  createProduct() {
    this.navigationService.performProductCreation(undefined, undefined, this.id, 4);
  }
}
