import { FormGroup } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
var QueryDateTimeFilterComponent = /** @class */ (function () {
    function QueryDateTimeFilterComponent() {
        this.queryUserMode = false;
        this.useDatePicker = true;
        this.class = 'form-group';
        this.padding = '0 0 0 17rem';
    }
    QueryDateTimeFilterComponent.prototype.isInvalid = function (cname) {
        return FormHelper.isInvalid(this.contextFormGroup, cname);
    };
    Object.defineProperty(QueryDateTimeFilterComponent.prototype, "filtered", {
        get: function () {
            return !!this.contextFormGroup.get('_par_' + this.parameterName + '_filter').value;
        },
        enumerable: true,
        configurable: true
    });
    return QueryDateTimeFilterComponent;
}());
export { QueryDateTimeFilterComponent };
