import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { StringHelper } from '../../helpers/string-helper';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var QuarantineDataService = /** @class */ (function () {
    function QuarantineDataService(http) {
        this.http = http;
        this._mainUri = environment.api + '/quarantine/';
    }
    QuarantineDataService.prettify = function (data) {
        if (data['territories'] && data['territories'].length) {
            data['territories'].forEach(function (territory) {
                return territory['cadNumber'] = StringHelper.stringCadNumberWithoutColon(territory['cadNumber']);
            });
        }
        if (data && data['stagePeriods']) {
            data['stagePeriods'] = Object.keys(data['stagePeriods']).map(function (key) { return data['stagePeriods'][key]; })
                .filter(function (el) { return el && el.quarantineId && el.quarantineStageId && el.dateFrom; });
        }
        return data;
    };
    QuarantineDataService.prototype.prettifySearchParams = function (params) {
        // есть два варианта выбора населенного пункта
        // после выбора муниципального образования и просто выбором населенного пункта без муниц образования
        // поэтому второй вариант надо обработать и сохранить в приемлемом виде
        if (!params.regionId && params.cityId) {
            var val = params.cityId.split('-');
            params.regionId = val[0];
            params.cityId = val[1];
        }
        return params;
    };
    QuarantineDataService.prototype.search = function (searchParams) {
        var qstring = this._mainUri + 'search';
        return this.http.post(qstring, this.prettifySearchParams(searchParams))
            .pipe(map(function (response) {
            return response;
        }));
    };
    QuarantineDataService.prototype.storeCommon = function (data) {
        var qstring = this._mainUri + 'store-common';
        return this.http.post(qstring, QuarantineDataService.prettify(data))
            .pipe(map(function (response) {
            return response;
        }));
    };
    QuarantineDataService.prototype.storeStages = function (data) {
        var qstring = this._mainUri + 'store-stages';
        return this.http.post(qstring, QuarantineDataService.prettify(data))
            .pipe(map(function (response) {
            return response;
        }));
    };
    QuarantineDataService.prototype.getForEditById = function (id) {
        var qstring = this._mainUri + 'get-for-edit/' + id.toString();
        return this.http.get(qstring, { headers: { 'interceptNoAccess': 'true' } })
            .pipe(map(function (response) {
            return response;
        }));
    };
    QuarantineDataService.prototype.getForLookup = function (id) {
        var qstring = this._mainUri + 'lookup/' + id.toString();
        return this.http.get(qstring)
            .pipe(map(function (response) {
            return response;
        }));
    };
    QuarantineDataService.prototype.closeCurrentStage = function (quarantineId, dateClosed, dateToInclusiveNextStage) {
        if (dateToInclusiveNextStage === void 0) { dateToInclusiveNextStage = null; }
        var qstring = this._mainUri + 'close-current-stage/' + quarantineId.toString(10);
        return this.http.post(qstring, {
            dateFrom: StringHelper.getISODateWithMilliseconds(dateClosed),
            dateTo: (StringHelper.getISODateWithMilliseconds(dateToInclusiveNextStage) || null)
        })
            .pipe(map(function (response) {
            return response;
        }));
    };
    QuarantineDataService.prototype.forceChangeStatus = function (quarantineId, newStatusId, dateFrom) {
        var qstring = this._mainUri + 'force-change-status/' +
            quarantineId + '/' + newStatusId + '/' + StringHelper.getISODate(dateFrom ? new Date(dateFrom) : new Date());
        return this.http.get(qstring)
            .pipe(map(function (response) {
            return response;
        }));
    };
    QuarantineDataService.prototype.getTrappedAnimal = function (animalId, date) {
        var qstring = environment.api + '/animal/check-trapped-quarantine/' + animalId + '/' + StringHelper.getISODate(new Date(date));
        return this.http.get(qstring)
            .pipe(map(function (response) {
            return response;
        }));
    };
    QuarantineDataService.prototype.getTrappedStable = function (stableId, date) {
        var qstring = environment.api + '/stable/check-trapped-quarantine/' + stableId + '/' + StringHelper.getISODate(new Date(date));
        return this.http.get(qstring)
            .pipe(map(function (response) {
            return response;
        }));
    };
    QuarantineDataService.prototype.searchAllTrappedAnimal = function (quarantineId) {
        var qstring = this._mainUri + 'trapped-all/animal/' + quarantineId;
        return this.http.get(qstring, { headers: { 'interceptNoAccess': 'true' } })
            .pipe(map(function (response) {
            return response;
        }));
    };
    QuarantineDataService.prototype.searchAllTrappedStable = function (quarantineId) {
        var qstring = this._mainUri + 'trapped-all/stable/' + quarantineId;
        return this.http.get(qstring, { headers: { 'interceptNoAccess': 'true' } })
            .pipe(map(function (response) {
            return response;
        }));
    };
    QuarantineDataService.prototype.getEventsByQuarantineId = function (quarantineId) {
        var qstring = this._mainUri + 'events/' + quarantineId;
        return this.http.get(qstring, { headers: { 'interceptNoAccess': 'true' } })
            .pipe(map(function (response) {
            return response;
        }));
    };
    QuarantineDataService.prototype.getAllMessages = function (quarantineId) {
        var qstring = this._mainUri + 'get-messages/' + quarantineId;
        return this.http.get(qstring, { headers: { 'interceptNoAccess': 'true' } })
            .pipe(map(function (response) {
            return response;
        }));
    };
    QuarantineDataService.prototype.deleteQuarantine = function (quarantineId) {
        var qstring = this._mainUri + 'delete/' + quarantineId;
        return this.http.get(qstring);
    };
    QuarantineDataService.prototype.storeMessage = function (data) {
        var qstring = this._mainUri + 'store-messages';
        return this.http.post(qstring, data)
            .pipe(map(function (response) {
            return response;
        }));
    };
    QuarantineDataService.prototype.storeEvents = function (quarantineId, events, deleted) {
        var model = {
            quarantineId: quarantineId,
            events: events,
            delete: deleted
        };
        var qstring = environment.api + '/quarantine/store-events';
        return this.http.post(qstring, model)
            .pipe(map(function (response, error) {
            return true;
        }));
    };
    QuarantineDataService.prototype.getEventsForEdit = function (id) {
        var qstring = environment.api + '/quarantine/qua-events/' + id.toString(10);
        return this.http.get(qstring, { headers: { 'interceptNoAccess': 'true' } })
            .pipe(map(function (item) {
            return item;
        }));
    };
    QuarantineDataService.ngInjectableDef = i0.defineInjectable({ factory: function QuarantineDataService_Factory() { return new QuarantineDataService(i0.inject(i1.HttpClient)); }, token: QuarantineDataService, providedIn: "root" });
    return QuarantineDataService;
}());
export { QuarantineDataService };
