import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../../ui/infrastructure/alert.service';
import { GlobalWaitingOverlayService } from '../../ui/infrastructure/global-waiting-overlay.service';
import { MetadataService } from '../../logic/services/metadata.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormHelper } from '../../ui/controls/form-helper';
import { Observable } from 'rxjs';
import { LookupSourceService } from '../../logic/services/lookup-source.service';
import { BookmarkService } from '../../logic/services/bookmark.service';
import { DataCachingService } from '../../logic/services/data-caching.service';

export abstract class MetaBrowseBaseComponent implements OnInit {

  _searchResults: any[] = [];

  currentFormGroup: FormGroup;
  currentIsNew = false;
  editModalOpened = false;

  model = {
    serverSideValidationErrors: []
  };

  isInvalid = FormHelper.isInvalid;

  constructor(protected route: ActivatedRoute,
              protected metadataService: MetadataService,
              protected alertService: AlertService,
              protected globalWaitingOverlayService: GlobalWaitingOverlayService,
              protected lookupService: LookupSourceService,
              protected fb: FormBuilder,
              protected bookmarkService: BookmarkService,
              protected dataCachingService: DataCachingService) {
  }

  abstract getMetaName(): string;

  abstract getMetaTitle(): string;

  abstract getGroupDef(row: any): any;

  getId(formGroup: FormGroup): any {
    return formGroup.get('id').value;
  }

  refreshResults() {
    this.globalWaitingOverlayService.StartWaiting();

    this.metadataService.getMetadata(this.getMetaName()).subscribe({
      next: data => {
        this._searchResults = data;
        this.dataCachingService.addToCache(this.getMetaName() + '_Data', '1', data);
        this.lookupService.invalidateLookup(this.getMetaName());
        this.globalWaitingOverlayService.EndWaiting();
      }, error: () => {
        this.globalWaitingOverlayService.EndWaiting();
      }
    });
  }

  ngOnInit() {

    this.route.paramMap
      .subscribe(() => {

        this._searchResults = [];

        const existing = this.dataCachingService.getCachedData(this.getMetaName() + '_Data', '1');
        if (existing) {
          this._searchResults = existing;
        } else {
          this.refreshResults();
        }

        this.bookmarkService.addDictionaryBookmark(
          this.getMetaTitle(),
          ['/dictionary/' + this.getMetaName()]);
      });
  }

  editRow(row: any) {
    this.currentFormGroup = this.fb.group(this.getGroupDef(row));
    this.currentIsNew = !this.getId(this.currentFormGroup);

    if (!this.currentIsNew) {
      this.metadataService.getMetadataSingle(this.getMetaName(), this.getId(this.currentFormGroup)).subscribe(detailedRec => {
        this.currentFormGroup = this.fb.group(this.getGroupDef(detailedRec));
        this.editModalOpened = true;
      });
    } else {
      this.editModalOpened = true;
    }
  }

  addEditCompleted() {

    FormHelper.markAsSubmitted(this.currentFormGroup);

    if (!this.currentFormGroup.valid) {
      return;
    }

    const storingObs: Observable<any> = this.currentIsNew
      ? this.metadataService.createMeta(this.getMetaName(), this.currentFormGroup.value)
      : this.metadataService.updateMeta(this.getMetaName(), this.currentFormGroup.value, this.getId(this.currentFormGroup));

    storingObs.subscribe(() => {
      this.refreshResults();
      FormHelper.setSingleFormGroupServerSideValidationErrors({}, this.model, this.currentFormGroup);
      this.editModalOpened = false;
    }, err => FormHelper.setSingleFormGroupServerSideValidationErrors(err, this.model, this.currentFormGroup));
  }

  delete(row: any) {

    this.alertService.confirmModal('Удалить выбранную запись?').subscribe(val => {
      if (val) {
        this.metadataService.deleteMeta(this.getMetaName(), this.getId(this.currentFormGroup))
          .subscribe(() => {
            this.editModalOpened = false;
            this.refreshResults();
          });
      }
    });
  }

  checkUniqueCaption($event: string, currentFormGroup: FormGroup) {
    const exist = this._searchResults.find(ex => ex.caption.toLowerCase() === $event.toLowerCase()
      && (currentFormGroup.get('id').value ? ex.id.toString() !== currentFormGroup.get('id').value.toString() : true));
    if (exist) {
      currentFormGroup.controls['caption'].setErrors([{'message': 'Наименование уже существует'}]);
    }
  }
}
