import { Component, Input, OnChanges } from '@angular/core';
import { QuarantineDataService } from '../../../logic/services/quarantine-data.service';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { StringHelper } from '../../../helpers/string-helper';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { DateHelper } from '../../../helpers/date-helper';

@Component({
  selector: 'app-view-quarantine-animalstable-events-search-results',
  templateUrl: './view-quarantine-animalstable-events-search-results.component.html'
})
export class ViewQuarantineAnimalstableEventsSearchResultsComponent implements OnChanges {
  @Input() quarantineId: number;

  rowData = [];

  isInvalid = FormHelper.isInvalid;

  gridColumnDefs = [
    {
      headerName: '', width: 50, excludeExport: true,
      cellRenderer: params => {
        if (!params || !params.data) {
          return '';
        }
        if (params.data['notExecuted']) {
          return '<clr-icon shape="times" style="color:silver"></clr-icon>';
        }
        if (DateHelper.isExpiredEvent(params.data['date'], params.data['datePlanning'])) {
          return '<clr-icon shape="history" style="color:red" title="Мероприятие просрочено"></clr-icon>';
        } else {
          return params.data['date'] ?
            '<clr-icon shape="check" style="color:green"></clr-icon>' :
            '<clr-icon shape="alarm-clock" style="color:silver"></clr-icon>';
        }
      }
    },
    {
      headerName: 'Вид', field: 'eventTypeId',
      valueFormatter: params => params.value
        ? (params.data.performedByAgent ? '[ХС] ' : '') + this.eventTypeIdLookup[params.value]
        : '<не указан>',
    },
    {
      headerName: 'Дата (план)', field: 'datePlanning',
      valueFormatter: params => StringHelper.getRuDate(params.value),
    },
    {
      headerName: 'Дата (факт)', field: 'date',
      valueFormatter: params => StringHelper.getRuDate(params.value),
    },
    {
      headerName: 'Описание мероприятия', field: 'comments',
    },
    {
      headerName: 'Наличие файлов', field: 'hasFiles',
      valueFormatter: params => params && params.value ? 'Да' : 'Нет'
    },
    {
      headerName: 'Кол-во животных', field: 'countAnimal',
    },
  ];

  eventTypeIdLookup: any = {};

  public static getBadgeViolation(params) {
    if (!params.data || !params.data.datePlanning) {
      return false;
    }

    if ((!params.data.date && new Date(params.data.datePlanning) < new Date()) ||
         new Date(params.data.datePlanning) < new Date(params.data.date)) {
      return false;
    }

    return true;
  }

  constructor(private quarantineDataService: QuarantineDataService,
              public navigationService: AppNavigationService,
              private lookupSourceService: LookupSourceService) {
    this.lookupSourceService.getLookupObj('event-type').subscribe(lookup => this.eventTypeIdLookup = lookup);
  }

  ngOnChanges(): void {
    this.quarantineDataService.getEventsByQuarantineId(this.quarantineId)
      .subscribe(data => {
        data.sort((a, b) => (b.date || b.datePlanning).localeCompare((a.date || a.datePlanning)));
        this.rowData = data;
      });
  }

  getRowStyle(params: any) {
    if (params.data && params.data.notExecuted) {
      return {color: 'silver'};
    }
  }
}
