import { ChangeDetectorRef, ElementRef, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { FormHelper } from '../../../ui/controls/form-helper';
import { PstReportStructureService } from '../../services/pst-report-structure.service';
var PstBranchCellLookupEditComponent = /** @class */ (function () {
    function PstBranchCellLookupEditComponent(lookupSourceService, navigationService, changeDetectorRef, pstReportStructureService) {
        this.lookupSourceService = lookupSourceService;
        this.navigationService = navigationService;
        this.changeDetectorRef = changeDetectorRef;
        this.pstReportStructureService = pstReportStructureService;
        this.changed = new EventEmitter();
        this.processMoneyKeypress = FormHelper.processMoneyKeypressDot;
        this.valueStr = '';
        this.valueLoaded = false;
        this.valueUpdated = false;
    }
    Object.defineProperty(PstBranchCellLookupEditComponent.prototype, "cellValueEdit", {
        get: function () {
            if (!this.valueLoaded) {
                this.valueStr = this.originalCellValue;
                this.valueLoaded = true;
            }
            return this.valueStr;
        },
        set: function (strValue) {
            this.valueUpdated = true;
            this.valueStr = strValue;
        },
        enumerable: true,
        configurable: true
    });
    PstBranchCellLookupEditComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.hasOwnProperty('lookupName')) {
            this.lookupSourceService.getLookup(this.lookupName).subscribe(function (lookup) {
                _this.lookupItems = lookup;
            });
        }
        if (changes.hasOwnProperty('editing')) {
            if (this.editing) {
                setTimeout(function () {
                    if (_this.inputControl) {
                        _this.inputControl.nativeElement.focus();
                    }
                }, 100);
            }
            if (!this.editing && this.valueUpdated) {
                setTimeout(function () {
                    _this.updateCellValueInModel();
                    _this.valueLoaded = false;
                    _this.valueUpdated = false;
                }, 100);
            }
        }
    };
    PstBranchCellLookupEditComponent.prototype.updateCellValueInModel = function () {
        var parsedVal;
        if (this.valueStr) {
            parsedVal = +this.valueStr;
        }
        if (this.changed) {
            this.changed.emit(parsedVal);
        }
    };
    PstBranchCellLookupEditComponent.prototype.onChange = function ($event) {
    };
    return PstBranchCellLookupEditComponent;
}());
export { PstBranchCellLookupEditComponent };
