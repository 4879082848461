<div class="product-expertises-cont" id="interceptNoAccess">
  <div class="product-expertise-list-navigation">
    <div *ngIf="!model.expertises || model.expertises.length == 0" class="product-expertise-list-empty">
      Данные отсутствуют
    </div>

    <ng-container *ngFor="let item of model.expertises">

      <div class="product-expertise-list-item"
           (click)="trySelectExpertise(item)"
           [class.itech-selected]="item===model.selected">
        <div><clr-icon shape="flask"></clr-icon></div>
        <div class="product-expertise-list-sync-state-project" *ngIf="!item.data.mercuryNumber">
          Проект
        </div>
        <div class="product-expertise-list-sync-state" *ngIf="item.data.mercuryNumber && !item.data.conclusionMercuryNumber">
          {{item.data.mercuryNumber}}
        </div>
        <div class="product-expertise-list-sync-state-final-result" *ngIf="item.data.conclusionMercuryNumber">
          {{item.data.conclusionMercuryNumber}}
        </div>
        <div [style.color]="!item.data.mercuryNumber ? undefined
                            : item.data.conclusionResultTypeId == 1 ? 'green'
                              : item.data.conclusionResultTypeId == 2 ? 'red'
                                : undefined" style="margin-left: 2px">
          {{item.data.laboratoryEventTypeId|lookup:'event-type'|async}}</div> от
        <div>{{item.data.expertiseTime|rtime}}</div>
        <div class="itech-expertise-number">{{item.data.id ? '№' + item.data.id : ''}}</div>
        <div *ngIf="item.form && item.form.dirty" class="itech-list-item-changed" title="Запись была изменена"></div>
      </div>
    </ng-container>
  </div>
  <div *ngIf="!model.selected" class="product-expertise-no-expertise">
    Экспертиза не выбрана
  </div>
  <div *ngIf="model.selected && model.selected.noAccess" class="product-expertise-no-expertise">
    Доступ к экспертизе запрещен
  </div>
  <div *ngIf="model.selected && !model.selected.noAccess" class="product-expertise-details">
    <h1>
      <div class="product-expertise-common-sync-status">
        <app-mercury-sync-status-signpost *ngIf="expertiseForm.get('laboratoryEventTypeId').value" (syncStateUpdated)="refreshFormDataInternal()"
                                          [syncStates]="[model.selected.syncState]" style="margin-left:20px">
        </app-mercury-sync-status-signpost>
      </div>
      Данные экспертизы/исследования от {{model.selected.data.expertiseTime|rtime}}
    </h1>

    <div class="itech-form-actions">
      <div>
        <button class="btn btn-primary-outline"
                [attr.disabled]="this.model.selected.data.id ? undefined: true"
                (click)="navigationService.navigateJournal(undefined, 11, productId)">
          <clr-icon shape="view-list"></clr-icon>&nbsp;Журнал
        </button>

        <app-reports-button [disabled]="!this.model.selected.data.id ||
                                        !this.model.selected.data.conclusionMercuryNumber ? true : undefined"
                            [reportKind]="'product-expertise'"
                            [disabledReports]="getDisabledReports()"
                            [objId1]="model.selected.data.id">
        </app-reports-button>

        <clr-dropdown>
          <button type="button" class="btn btn-primary-outline" clrDropdownTrigger>
            Действия
            <clr-icon shape="caret down"></clr-icon>
          </button>
          <clr-dropdown-menu *clrIfOpen [clrPosition]="'bottom-left'">
            <button [disabled]="this.model.selected.data.id? undefined: true" class="btn btn-warning-outline"
                    (click)="addExpertise(this.model.selected.data.id)" clrDropdownItem>
              <clr-icon shape="copy"></clr-icon>&nbsp;Создать копию
            </button>
            <div class="dropdown-divider"></div>
            <button [disabled]="!this.model.selected.data.id || this.model.selected.data.mercuryNumber?true:undefined"
                    class="btn btn-warning-outline" (click)="deleteExpertise()" clrDropdownItem>
              <clr-icon shape="trash"></clr-icon>&nbsp;Удалить
            </button>
          </clr-dropdown-menu>
        </clr-dropdown>

      </div>
      <div>
        <clr-dropdown>
          <button type="button" class="btn btn-primary-outline" clrDropdownTrigger>
            Сохранить
            <clr-icon shape="caret down"></clr-icon>
          </button>
          <clr-dropdown-menu *clrIfOpen [clrPosition]="'bottom-left'">
            <button [clrLoading]="storing" class="btn" clrDropdownItem
                    [disabled]="!expertiseForm.dirty || storing || model.selected.noAccess"
                    (click)="store(false)">
              Без синхронизации
            </button>
            <app-btn-sync-web [caption]="'С синхронизацией'" [clrLoading]="storing" clrDropdownItem
                              (action)="store(true)">
            </app-btn-sync-web>
          </clr-dropdown-menu>
        </clr-dropdown>
        <button class="btn btn-warning itech-form-cancel" [disabled]="!expertiseForm.dirty || model.selected.noAccess"
                (click)="cancelEdit()">
          <clr-icon shape="undo"></clr-icon>&nbsp;Отменить
        </button>
      </div>
    </div>

    <app-doc-validation-errors [validationErrors]="expertiseForm.docLevelServerSideValidationErrors">
    </app-doc-validation-errors>

    <div class="form" style="display: flex; overflow: auto;flex-direction: column">
      <app-edit-expertise-common [contextFormGroup]="expertiseForm" [productId]="id"
                                 [expertiseId]="+this.model.selected.data.id"></app-edit-expertise-common>
    </div>
  </div>

  <div class="itech-form-actions">
    <div>
      <button type="button" class="btn btn-link" (click)="addExpertise()">
        Добавить экспертизу
      </button>
    </div>
    <div>
      <button class="btn btn-secondary" (click)="reloadExpertisesList()">
        <clr-icon shape="refresh"></clr-icon>&nbsp;Обновить
      </button>
    </div>
  </div>
</div>
