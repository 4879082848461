import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';
import { SecurityService } from '../../../logic/services/security.service';
import { StringHelper } from '../../../helpers/string-helper';
import { DateHelper } from '../../../helpers/date-helper';
import { combineLatest } from 'rxjs';
import { InstitutionTicketDataService } from '../../../logic/services/institution-ticket-data.service';
import { TicketsPlanningUiService } from './tickets-planning-ui-service';
import { FormBuilder, Validators } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
var TicketsPlanningComponent = /** @class */ (function () {
    function TicketsPlanningComponent(route, bookmarkService, alertService, dataCachingService, navigationService, lookupSourceService, institutionTicketDataService, itemsCache, securityService, ticketPlanningUiService, fb) {
        this.route = route;
        this.bookmarkService = bookmarkService;
        this.alertService = alertService;
        this.dataCachingService = dataCachingService;
        this.navigationService = navigationService;
        this.lookupSourceService = lookupSourceService;
        this.institutionTicketDataService = institutionTicketDataService;
        this.itemsCache = itemsCache;
        this.securityService = securityService;
        this.ticketPlanningUiService = ticketPlanningUiService;
        this.fb = fb;
        /* режим fixedEmployeeMode=true означает, что форма запущена как отдельный route-view, предназначенный для редактирования
        * услуг конкретного работника конкретного учреждения. В этом режиме может быть достпно сколько угодно клиентов, но только
        * одно учреждение и один специалист, что обуславлиает особенности отображения режима (не показываем те поля, значения которых
        * очевидны из контекста.
        * режим fixedEmployeeMode=false означает, что форма запущена как дочернее view в карточке человека. соответственно,
        * услуги на форме могут быть показаны только для одного клиента, а работников и учреждений может быть сколько угодно.
        * В этом режиме есть 2 "под-режима" - для работника учреждения (с возможностью редактирования) и для иных работников,
        * например, работников головной организации (без возможности редактирования)*/
        this.fixedEmployeeMode = true;
        this.model = {
            displayedMonth: new Date().getMonth(),
            displayedYear: new Date().getFullYear(),
            oneDayDate: StringHelper.getISODate(new Date()),
            institutionId: this.workingInstitutionId,
            institutionEmployeeId: this.workingEmployeeId,
            ticketTemplates: [],
            zoomedDayIndex: undefined,
            viewMode: 1,
            searchParams: this.fb.group({
                institutionBranchId: [null, Validators.required],
            }),
        };
    }
    Object.defineProperty(TicketsPlanningComponent.prototype, "currentMonthDate", {
        get: function () {
            return new Date(this.model.displayedYear, this.model.displayedMonth, 1);
        },
        enumerable: true,
        configurable: true
    });
    TicketsPlanningComponent.prototype.ngOnInit = function () {
        this.initAsStandaloneView();
        this.reloadDays();
        this.refreshTemplates();
    };
    TicketsPlanningComponent.prototype.initAsStandaloneView = function () {
        var _this = this;
        this.securityService.getUserInfo().subscribe(function (userInfo) {
            _this.workingInstitutionId = _this.fixedInstitutionId || userInfo.institutionId || undefined;
            _this.workingEmployeeId = userInfo.institutionEmployeeId || undefined;
            _this.workingBranchId = userInfo.institutionBranchId || undefined;
            var existing = _this.dataCachingService.getCachedData('TicketsPlanningComponent', _this.workingInstitutionId + '-' + _this.workingEmployeeId);
            if (existing) {
                _this.model = existing;
            }
            else {
                var cDate = new Date();
                _this.model = {
                    displayedMonth: cDate.getMonth(),
                    displayedYear: cDate.getFullYear(),
                    oneDayDate: StringHelper.getISODate(cDate),
                    institutionId: _this.workingInstitutionId,
                    institutionEmployeeId: _this.workingEmployeeId,
                    institutionBranchId: _this.workingBranchId,
                    zoomedDayIndex: undefined,
                    viewMode: 1,
                    searchParams: _this.fb.group({
                        institutionBranchId: [null, Validators.required],
                    }),
                };
                _this.reloadWorkingEmployeeDepartments();
                _this.reloadDays();
                _this.dataCachingService.addToCache('TicketsPlanningComponent', _this.workingInstitutionId + '-' + _this.workingEmployeeId, _this.model, 2);
            }
        });
    };
    TicketsPlanningComponent.prototype.reloadWorkingEmployeeDepartments = function () {
        var _this = this;
        combineLatest([
            this.lookupSourceService.getLookupObj('institution-employee' + this.workingInstitutionId),
            this.lookupSourceService.getLookupObj('institution-branch' + this.workingInstitutionId)
        ]).subscribe(function (_a) {
            var employees = _a[0], branches = _a[1];
            _this.model.workingEmployeeBranches = [];
            var employee = employees['Obj' + _this.workingEmployeeId];
            if (!employee || !employee.branches || !employee.branches.length) {
                return;
            }
            _this.model.institutionBranchId = employee.departments[0];
            employee.branches.forEach(function (branchId) {
                var branch = branches['Obj' + branchId];
                if (branch) {
                    _this.model.workingEmployeeBranches.push(branch);
                }
            });
            _this.model.workingEmployeeBranches.sort(function (item1, item2) { return item1.caption ? item1.caption.localeCompare(item2.caption) : 0; });
        });
    };
    TicketsPlanningComponent.prototype.reloadDays = function () {
        this.reloadDaysCalendarMode();
    };
    TicketsPlanningComponent.prototype.reloadDaysCalendarMode = function () {
        var _this = this;
        FormHelper.markAsSubmitted(this.model.searchParams);
        if (!this.model.searchParams.valid) {
            return;
        }
        var currentDate = DateHelper.startOfTheWeek(new Date(this.model.displayedYear, this.model.displayedMonth, 1));
        currentDate.setHours(0, 0, 0, 0);
        var endDate = DateHelper.endOfTheMonth(this.model.displayedYear, this.model.displayedMonth);
        var days = [];
        var index = 0;
        var _loop_1 = function () {
            var day = {
                index: index,
                date: currentDate,
                tickets: [],
                slots: [
                    { tickets: [], timeStart: '00:00', timeEnd: '08:00', day: undefined },
                    { tickets: [], timeStart: '08:00', timeEnd: '09:00', day: undefined },
                    { tickets: [], timeStart: '09:00', timeEnd: '10:00', day: undefined },
                    { tickets: [], timeStart: '10:00', timeEnd: '11:00', day: undefined },
                    { tickets: [], timeStart: '11:00', timeEnd: '12:00', day: undefined },
                    { tickets: [], timeStart: '12:00', timeEnd: '13:00', day: undefined },
                    { tickets: [], timeStart: '13:00', timeEnd: '14:00', day: undefined },
                    { tickets: [], timeStart: '14:00', timeEnd: '15:00', day: undefined },
                    { tickets: [], timeStart: '15:00', timeEnd: '16:00', day: undefined },
                    { tickets: [], timeStart: '16:00', timeEnd: '17:00', day: undefined },
                    { tickets: [], timeStart: '17:00', timeEnd: '18:00', day: undefined },
                    { tickets: [], timeStart: '18:00', timeEnd: '23:59:59', day: undefined }
                ],
                continuousTickets: []
            };
            day.slots.forEach(function (elem) {
                elem.day = day;
            });
            days.push(day);
            currentDate = DateHelper.addDays(currentDate, 1);
            index = index + 1;
        };
        while (currentDate <= endDate) {
            _loop_1();
        }
        this.model.days = days;
        this.institutionTicketDataService.fillDaysModelTickets(days, this.fixedEmployeeMode ? this.workingInstitutionId : undefined, this.fixedEmployeeMode ? this.model.searchParams.get('institutionBranchId').value : undefined).subscribe({
            next: function () {
                if (_this.model.zoomedDayIndex) {
                    _this.zoomDay(_this.model.zoomedDayIndex);
                }
            }
        });
    };
    TicketsPlanningComponent.prototype.navigatePreviousMonth = function () {
        this.model.displayedMonth = this.model.displayedMonth - 1;
        if (this.model.displayedMonth < 0) {
            this.model.displayedMonth = 11;
            this.model.displayedYear = this.model.displayedYear - 1;
        }
        this.reloadDays();
    };
    TicketsPlanningComponent.prototype.navigateNextMonth = function () {
        this.model.displayedMonth = this.model.displayedMonth + 1;
        if (this.model.displayedMonth > 11) {
            this.model.displayedMonth = 0;
            this.model.displayedYear = this.model.displayedYear + 1;
        }
        this.reloadDays();
    };
    TicketsPlanningComponent.prototype.zoomDay = function (i) {
        if (i >= this.model.days.length - 1) {
            i = this.model.days.length - 2;
        }
        if (i < 1) {
            i = 1;
        }
        this.model.zoomedDayIndex = i;
        this.model.zoomedDays = [];
        this.model.zoomedDays.push(this.model.days[i - 1]);
        this.model.zoomedDays.push(this.model.days[i]);
        this.model.zoomedDays.push(this.model.days[i + 1]);
    };
    TicketsPlanningComponent.prototype.unZoom = function () {
        this.model.zoomedDayIndex = undefined;
        this.model.zoomedDays = undefined;
        this.model.expandedSlot = undefined;
    };
    TicketsPlanningComponent.prototype.refreshTemplates = function () {
        this.institutionTicketDataService.refreshTemplates(this.model);
    };
    TicketsPlanningComponent.prototype.editTemplate = function (data) {
        this.ticketPlanningUiService.editTicket({ data: data });
    };
    TicketsPlanningComponent.prototype.distributeTickets = function (template) {
        var temp = Object.assign({}, template);
        temp.isDistribute = true;
        temp.isTemplate = false;
        temp.templateCaption = undefined;
        temp.id = undefined;
        temp.institutionId = this.workingInstitutionId;
        this.ticketPlanningUiService.editTicket({ data: temp });
    };
    return TicketsPlanningComponent;
}());
export { TicketsPlanningComponent };
