import {Component, DoCheck, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {PstReportService} from '../../services/pst-report.service';
import {LookupSourceService} from '../../../logic/services/lookup-source.service';
import {AppNavigationService} from '../../../logic/services/app-navigation.service';
import {AddressPersonFioCacheService} from '../../../logic/services/address-person-fio-cache.service';
import {PstReportStructureService} from '../../services/pst-report-structure.service';
import {IndicatorDependencyModalComponent} from '../indicator-dependency-modal.component';
import {noop, Observable} from 'rxjs';
import {DomSanitizer} from '@angular/platform-browser';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-branch-tab-row-edit',
  templateUrl: './pst-branch-tab-row-edit.component.html',
})
export class PstBranchTabRowEditComponent implements OnChanges, DoCheck {
  @Input() branch: any;
  @Input() model: any;

  headerDivHeight: any;
  theadHeight: any;

  @ViewChild('headerDiv') headerDivElement: any;
  @ViewChild('thead') theadElement: any;

  constructor(
    private lookupSourceService: LookupSourceService,
    private navigationService: AppNavigationService,
    private pstReportService: PstReportService,
    private cacheService: AddressPersonFioCacheService,
    private pstReportStructureService: PstReportStructureService,
    private appNavigationService: AppNavigationService,
    private domSanitizer: DomSanitizer,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('branch') || changes.hasOwnProperty('model')) {
      this.rebuildHeader();
      this.startRebuildingRows();
    }
  }

  ngDoCheck(): void {
    this.headerDivHeight = this.headerDivElement && this.headerDivElement.nativeElement
      ? this.headerDivElement.nativeElement.offsetHeight || 0
      : 0;
    this.theadHeight = this.theadElement && this.theadElement.nativeElement ?
      this.theadElement.nativeElement.offsetHeight || 0
      : 0;
  }

  private rebuildHeader() {
    this.branch.headerRows = [];

    if (!this.branch || !this.branch.indicators) {
      return;
    }

    let depth = 1;
    this.branch.indicators.forEach(indicator => {
      // в табличном представлении структуру колонок всегда пишем по строке с номером 1
      // (там в каждой строке свои показатели со своим описанием - для универсальности структуры - мы берем только
      // первую, чтобы определить заголовки)
      if (indicator.meta.rowNo !== 1) {
        return;
      }

      const path = (indicator.displayTitle || '').split('->');
      if (path.length > depth) {
        depth = path.length;
      }
    });

    for (let lvl = 0; lvl < depth; lvl++) {
      const row = [];
      this.branch.indicators.forEach((indicator, index) => {
        // в табличном представлении структуру колонок всегда пишем по строке с номером 1
        // (там в каждой строке свои показатели со своим описанием - для универсальности структуры - мы берем только
        // первую, чтобы определить заголовки)
        if (indicator.meta.rowNo !== 1) {
          return;
        }
        const path = (indicator.displayTitle || '').split('|')[0].split('->');

        let ix_spacer = -1;
        for (let i = 1; i < path.length; i++) {
          if (!path[i]) {
            ix_spacer = i;
            path[i] = path[i - 1];
          }
        }

        if (ix_spacer >= 0) {
          while (path.length < depth) {
            path.splice(ix_spacer, 0, path[ix_spacer]);
          }
        }

        const displayTitlePath = path.slice(0, lvl < path.length ? lvl + 1 : path.length).join('->');

        row[indicator.meta.colNo - 1] = {
          indicator,
          displayTitle: lvl < path.length ? path[lvl] : path[path.length - 1],
          displayTitlePath,
          colSpan: 1,
          rowSpan: 1,
        };
      });

      this.branch.headerRows.push(row);
    }

    this.pstReportStructureService.mergeHeaderCells(this.branch);
  }

  private startRebuildingRows() {
    this.lookupSourceService.getLookupObj('addr-region').subscribe(regionLookup => {
      this.branch.rootRowsGroup = {
        hash: {},
        children: []
      };

      const useAgentId = this.branch.meta.params['use-agent-id'] ? +this.branch.meta.params['use-agent-id'] : undefined;
      const useKfhType = useAgentId ? 1 : undefined;

      this.branch.indicators.forEach((indicator, index) => {
        if (!this.branch.rootRowsGroup.children[indicator.meta.rowNo - 1]) {
          let indicatorLevel = this.branch.meta.indicatorLevel;
          if (!this.model.reportMeta.regionId && this.branch.meta.subjectIndicatorLevel) {
            indicatorLevel = this.branch.meta.subjectIndicatorLevel;
          }
          // для 60-го уровня, клонируемая строка имеет 50-й уровень, чтобы показывать итоги по добавленным подвидам
          if (indicatorLevel === 60 && indicator.meta.params['clone-subtype-cells']) {
            indicatorLevel = 50;
          }

          const useSubtypeId = indicator.meta.params['use-subtype-id'] ? +indicator.meta.params['use-subtype-id'] : undefined;
          const indicatorsRow = {
            indicators: [],
            regionGroupId: this.model.reportMeta.regionId
              ? regionLookup[`Obj${this.model.reportMeta.regionId}`].regionGroupId
              : undefined,
            regionId: this.model.reportMeta.regionId,
            level: indicatorLevel,
            kfhType: indicatorLevel >= 50 ? useKfhType : undefined,
            agentId: indicatorLevel >= 50 ? useAgentId : undefined,
            subtypeId: useSubtypeId,
          };
          this.branch.rootRowsGroup.children[indicator.meta.rowNo - 1] = indicatorsRow;
        }

        const displayTitleSplit = (indicator.displayTitle || '').split('|');
        const row = this.branch.rootRowsGroup.children[indicator.meta.rowNo - 1];
        row.indicators[indicator.meta.colNo - 1] = {
          reportIndicator: indicator,
          meta: indicator.indicatorMeta,
          cellFixedStr: displayTitleSplit.length > 1 ? displayTitleSplit[1] : undefined,
          colSpan: displayTitleSplit.length > 2 && displayTitleSplit[2] ? +displayTitleSplit[2] : undefined,
        };
      });
    });
  }

  cellClick(indicatorLevel: any, colIndex: any, reportIndicator: any) {
    if (this.getFocused(indicatorLevel, colIndex)) {
      this.setEditingCell(indicatorLevel, colIndex, indicatorLevel.indicators[colIndex].meta, '');
    } else {
      this.setFocusedCell(indicatorLevel, colIndex);
    }
    // console.log('reportIndicator:', reportIndicator);
    // console.log('indicatorTypeMeta:', indicatorLevel.indicators[colIndex].meta);
    // console.log('indicatorLevel:', indicatorLevel);
  }

  private setFocusedCell(indicatorLevel: any, colIndex: any) {
    this.model.__cl_focusedBranch = this.branch;
    this.model.__cl_focusedIndicatorLevel = indicatorLevel;
    this.model.__cl_focusedIndicatorColIndex = colIndex;
    this.model.__cl_editingBranch = undefined;
    this.model.__cl_storing_error = undefined;
  }

  private setEditingCell(indicatorLevel: any, colIndex: any, indicatorTypeMeta: any, proposedValue: any) {
    if (!indicatorTypeMeta || indicatorTypeMeta.formula || indicatorLevel.level !== (indicatorTypeMeta.editableLevel || 60)) {
      return;
    }
    this.setFocusedCell(indicatorLevel, colIndex);
    const reportIndicator = indicatorLevel.indicators[colIndex].reportIndicator;
    this.setProposedCellValueStr(indicatorLevel, reportIndicator, indicatorTypeMeta, proposedValue);
    this.model.__cl_editingBranch = this.branch;
    this.model.__cl_editingIndicatorLevel = indicatorLevel;
    this.model.__cl_editingIndicatorColIndex = colIndex;
  }

  tableKeyDown($event) {
    if (this.model.__cl_focusedBranch === this.branch) {
      const indicatorLevel = this.model.__cl_focusedIndicatorLevel;
      const colIndex = this.model.__cl_focusedIndicatorColIndex;
      const indicatorTypeMeta = indicatorLevel.indicators[colIndex].meta;
      const reportIndicator = indicatorLevel.indicators[colIndex].reportIndicator;

      if (!this.getEditing(indicatorLevel, colIndex)) {
        if ($event.key >= '0' && $event.key <= '9') {
          this.setEditingCell(indicatorLevel, colIndex, indicatorTypeMeta, $event.key);
        }
        if ($event.key === 'F2') {
          this.setEditingCell(indicatorLevel, colIndex, indicatorTypeMeta, '');
        }
        if ($event.key === 'F3') {
          const code = this.pstReportStructureService.getIndicatorValueCode(
            this.branch, indicatorLevel, reportIndicator, indicatorTypeMeta);
          const rec = this.model.values[code];
          if (rec) {
            $event.preventDefault();
            this.appNavigationService.showIndicatorDependencies(IndicatorDependencyModalComponent, rec, this.model.reportMeta.id)
              .subscribe(noop);
          }
        }
        if ($event.key === 'ArrowLeft') {
          this.setFocusedCell(indicatorLevel, colIndex - 1 >= 0 ? colIndex - 1 : 0);
          $event.preventDefault();
        }
        if ($event.key === 'ArrowRight') {
          this.setFocusedCell(indicatorLevel, colIndex + 1 < indicatorLevel.indicators.length
            ? colIndex + 1
            : indicatorLevel.indicators.length - 1);
          $event.preventDefault();
        }
        if ($event.key === 'ArrowUp') {
          const ixSelectedLevel = this.branch.rootRowsGroup.children.findIndex((el: any) => el === indicatorLevel);
          if (ixSelectedLevel > 0) {
            this.setFocusedCell(this.branch.rootRowsGroup.children[ixSelectedLevel - 1], colIndex);
            $event.preventDefault();
          }
        }
        if ($event.key === 'ArrowDown') {
          const ixSelectedLevel = this.branch.rootRowsGroup.children.findIndex((el: any) => el === indicatorLevel);
          if (ixSelectedLevel + 1 < this.branch.rootRowsGroup.children.length) {
            this.setFocusedCell(this.branch.rootRowsGroup.children[ixSelectedLevel + 1], colIndex);
            $event.preventDefault();
          }
        }
        if ($event.key === 'Delete' && !indicatorTypeMeta.formula) {
          this.pstReportStructureService.storeIndicatorValue(
            this.model, reportIndicator, indicatorTypeMeta, indicatorLevel, undefined);
        }
      }
    }
  }

  getFocused(indicatorLevel: any, colIndex: any) {
    return this.model.__cl_focusedBranch === this.branch &&
      this.model.__cl_focusedIndicatorLevel === indicatorLevel &&
      this.model.__cl_focusedIndicatorColIndex === colIndex;
  }

  getEditing(indicatorLevel: any, colIndex: any) {
    return this.model.__cl_editingBranch === this.branch &&
      this.model.__cl_editingIndicatorLevel === indicatorLevel &&
      this.model.__cl_editingIndicatorColIndex === colIndex;
  }

  setProposedCellValueStr(indicatorLevel: any, reportIndicator: any, indicatorTypeMeta: any, strValue: any) {
    if (!reportIndicator || !indicatorTypeMeta || !indicatorLevel) {
      return;
    }

    const code = this.pstReportStructureService.getIndicatorValueCode(
      this.branch,
      indicatorLevel,
      reportIndicator,
      indicatorTypeMeta,
    );

    if (!this.model.values[code]) {
      this.model.values[code] = {
        date: reportIndicator.indicatorDate,
        indicatorTypeId: indicatorTypeMeta.id,
        regionGroupId: indicatorLevel.regionGroupId,
        regionId: indicatorLevel.regionId,
        kfhType: indicatorLevel.kfhType,
        agentId: indicatorLevel.agentId,
      };
    }

    if (strValue) {
      let parsedVal = parseFloat(strValue);
      const precision = indicatorTypeMeta.precision;
      if (precision === 0) {
        parsedVal = Math.floor(strValue);
      } else {
        parsedVal = Math.floor(strValue * Math.pow(10, precision)) / Math.pow(10, precision);
      }
      this.model.values[code].proposedValue = parsedVal;
    } else {
      this.model.values[code].proposedValue = undefined;
    }
  }

  getCellReadonly(row, ii) {
    return (row.indicators && row.indicators[ii].meta.formula) || row.indicators[ii].cellFixedStr
      || (row.indicators[ii].reportIndicator && row.indicators[ii].reportIndicator.meta.params
        && row.indicators[ii].reportIndicator.meta.params.excluded === 'true')
      || (row.level !== row.indicators[ii].meta.editableLevel);
  }

  getWrapperHeight() {
    if (this.branch.meta.params['tableHeight']) {
      return this.branch.meta.params['tableHeight'];
    }
    if (this.model.tabPageHeight) {
      return `${this.model.tabPageHeight - 20 - this.headerDivHeight}px`;
    }
    return 'calc(100% - 25px)';
  }

  getCellStyle(indicatorLevel: any, indicator: any, rowGroup: any, ii: any) {
    let style = '';
    if (indicator.reportIndicator.meta.params['custom-style']) {
      style = style + ';' + indicator.reportIndicator.meta.params['custom-style'];
    }
    return this.domSanitizer.bypassSecurityTrustStyle(style);
  }

  getAvailableSubtypes$(rowIndicator: any): Observable<any[]> {
    return this.lookupSourceService.getLookup('pst-indicator-subtype').pipe(map(
      indicatorSubtypes => {
        const lvlGroupCaption = rowIndicator.meta.lvlGroupCaption;
        const filtered = indicatorSubtypes.filter(subtype => subtype.groupCaption === lvlGroupCaption);
        filtered.sort((a: any, b: any) => (a.caption || '').localeCompare(b.caption || ''));
        return filtered;
      }));
  }

  cloneSubtypeRow(row: any, i_row: number, subtype: any) {
    const cloneRow = JSON.parse(JSON.stringify(row));
    this.branch.rootRowsGroup.children.splice(i_row + 1, 0, cloneRow);
    cloneRow.indicators[0].cellFixedStr = subtype.caption;
    cloneRow.indicators[0].reportIndicator.meta.params['custom-style'] =
      (cloneRow.indicators[0].reportIndicator.meta.params['custom-style'] || '') + ';padding-left: 40px';
    cloneRow.indicators.forEach(rowIndicator => {
      delete rowIndicator.reportIndicator.meta.params['add-subtype-button'];
      rowIndicator.reportIndicator.meta.params['use-subtype-id'] = subtype.id;
    });
    cloneRow.subtypeId = subtype.id;
    cloneRow.level = 60;
  }

  showAddSubtypeBtn(rowIndicator: any) {
    return rowIndicator.reportIndicator.meta.params['add-subtype-button'] === 'region'
      && this.model.reportMeta.regionId;
  }
}
