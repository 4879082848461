import { EventEmitter, OnChanges, OnInit } from '@angular/core';
import { AppFormGroup } from '../../../ui/controls/app-form-group';
import { FormHelper } from '../../../ui/controls/form-helper';
import { StringHelper } from '../../../helpers/string-helper';
import { DateHelper } from '../../../helpers/date-helper';
import { FormBuilder } from '@angular/forms';
import { EditQuarantineStagesComponent } from './edit-quarantine-stages.component';
import { ServerSideErrorsProvider } from '../../../logic/validators/server-side-errors-provider';
var EditQuarantineStagesFileComponent = /** @class */ (function () {
    function EditQuarantineStagesFileComponent(fb) {
        this.fb = fb;
        this.withoutNumber = false; // без номера
        this.withoutDate = false; // без даты
        this.isAccessEdit = true; // право редактирования
        this.marginBottom = 20;
        this.tooltipPosition = 'top-right';
        this.dirtyFormGroup = new EventEmitter();
        this.isInvalid = FormHelper.isInvalid;
        this.violationDeadline = false;
    }
    Object.defineProperty(EditQuarantineStagesFileComponent.prototype, "controlFormArray", {
        get: function () {
            return this.contextFormGroup.get(this.controlName);
        },
        enumerable: true,
        configurable: true
    });
    EditQuarantineStagesFileComponent.prototype.getFormGroup = function (file) {
        return file;
    };
    EditQuarantineStagesFileComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.contextFormGroup.get(this.controlName).valueChanges.subscribe(function () { return _this.dirtyFormGroup.next(); });
    };
    EditQuarantineStagesFileComponent.prototype.ngOnChanges = function () {
        if (this.deadlineDateTime) {
            this.violationDeadline = new Date().getTime() - this.deadlineDateTime.getTime() > 0;
        }
    };
    EditQuarantineStagesFileComponent.prototype.fileFormGroupByControlName = function (controlName) {
        return this.contextFormGroup.get(controlName);
    };
    EditQuarantineStagesFileComponent.prototype.markAsDirty = function () {
        this.contextFormGroup.markAsDirty();
        this.dirtyFormGroup.next();
    };
    EditQuarantineStagesFileComponent.prototype.changeFile = function ($event) {
        if (this.contextFormGroup.contains(this.controlName + 'DateUpload')) {
            this.contextFormGroup.get(this.controlName + 'DateUpload').setValue(null);
        }
        this.markAsDirty();
    };
    EditQuarantineStagesFileComponent.prototype.getDateUploadToString = function (dateStr) {
        return StringHelper.getRuTimeWithSeconds(DateHelper.toDate(dateStr));
    };
    EditQuarantineStagesFileComponent.prototype.changeNumberDoc = function (file) {
        if (file.get('number').value === '') {
            file.get('number').setValue(null);
        }
    };
    EditQuarantineStagesFileComponent.prototype.getRemainingTime = function () {
        return 'Срок прикрепления до ' + StringHelper.getRuTimeWithSeconds(this.deadlineDateTime);
    };
    EditQuarantineStagesFileComponent.prototype.addNewOrderToExtend = function () {
        this.controlFormArray.push(this.fb.group(EditQuarantineStagesComponent.buildFormGroupFile(this.fb, this.serverSideErrorsProvider, {}, this.controlName + '-' + this.controlFormArray.length)));
    };
    return EditQuarantineStagesFileComponent;
}());
export { EditQuarantineStagesFileComponent };
