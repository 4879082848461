/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@clr/angular";
import * as i2 from "../../../node_modules/@clr/angular/clr-angular.ngfactory";
import * as i3 from "@angular/common";
import * as i4 from "./auth-found-multiple.component";
import * as i5 from "./esia.service";
import * as i6 from "@angular/router";
var styles_AuthFoundMultipleComponent = [];
var RenderType_AuthFoundMultipleComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AuthFoundMultipleComponent, data: {} });
export { RenderType_AuthFoundMultipleComponent as RenderType_AuthFoundMultipleComponent };
function View_AuthFoundMultipleComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.login(_v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["\u0412\u043E\u0439\u0442\u0438"])), (_l()(), i0.ɵeld(4, 0, null, null, 2, "label", [], [[1, "for", 0]], null, null, null, null)), i0.ɵdid(5, 212992, null, 0, i1.ClrLabel, [[2, i1.ɵbe], [2, i1.ɵbf], [2, i1.ɵbg], i0.Renderer2, i0.ElementRef], { forAttr: [0, "forAttr"] }, null), (_l()(), i0.ɵted(6, null, ["", ""]))], function (_ck, _v) { var currVal_1 = i0.ɵinlineInterpolate(1, "", _v.context.$implicit.id, ""); _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 5).forAttr; _ck(_v, 4, 0, currVal_0); var currVal_2 = _v.context.$implicit.caption; _ck(_v, 6, 0, currVal_2); }); }
export function View_AuthFoundMultipleComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "clr-modal", [], [[2, "open", null]], [[null, "clrModalOpenChange"], ["body", "keyup.escape"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("body:keyup.escape" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).close() !== false);
        ad = (pd_0 && ad);
    } if (("clrModalOpenChange" === en)) {
        var pd_1 = ((_co.esiaService.authMultipleInstitutionForUser = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_ClrModal_0, i2.RenderType_ClrModal)), i0.ɵprd(8704, null, i1.ɵed, i1.ɵed, [i3.DOCUMENT]), i0.ɵprd(1024, null, i1.ɵo, i1.ɵp, []), i0.ɵdid(3, 704512, null, 0, i1.ClrModal, [i1.ɵed, i1.ClrCommonStringsService, i0.PLATFORM_ID, i1.ɵo], { _open: [0, "_open"], closable: [1, "closable"], size: [2, "size"] }, { _openChanged: "clrModalOpenChange" }), (_l()(), i0.ɵeld(4, 0, null, 1, 1, "h3", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u0412\u044B\u0431\u043E\u0440 \u0443\u0447\u0440\u0435\u0436\u0434\u0435\u043D\u0438\u044F"])), (_l()(), i0.ɵeld(6, 0, null, 2, 5, "div", [["class", "modal-body"]], [[1, "tabindex", 0]], [[null, "focus"], [null, "mousedown"], [null, "mouseup"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i0.ɵnov(_v, 7).focus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mousedown" === en)) {
        var pd_1 = (i0.ɵnov(_v, 7).mouseDown() !== false);
        ad = (pd_1 && ad);
    } if (("mouseup" === en)) {
        var pd_2 = (i0.ɵnov(_v, 7).mouseUp() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(7, 16384, null, 0, i1.ɵee, [], null, null), (_l()(), i0.ɵeld(8, 0, null, null, 1, "p", [["style", "padding-bottom: 20px"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u041F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u044C \u0437\u0430\u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u043E\u0432\u0430\u043D \u0432 \u043D\u0435\u0441\u043A\u043E\u043B\u044C\u043A\u0438\u0445 \u0443\u0447\u0440\u0435\u0436\u0434\u0435\u043D\u0438\u044F\u0445, \u043D\u0435\u043E\u0431\u0445\u043E\u0434\u0438\u043C\u043E \u0432\u044B\u0431\u0440\u0430\u0442\u044C, \u0432 \u043A\u0430\u043A\u043E\u043C \u0430\u0432\u0442\u043E\u0440\u0438\u0437\u043E\u0432\u0430\u0442\u044C\u0441\u044F"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AuthFoundMultipleComponent_1)), i0.ɵdid(11, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(12, 0, null, 3, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 1, "button", [["class", "btn btn-outline"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["\u0412\u044B\u0439\u0442\u0438"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.esiaService.authMultipleInstitutionForUser; var currVal_2 = false; var currVal_3 = ""; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); var currVal_5 = _co.institutions; _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 3)._open; _ck(_v, 0, 0, currVal_0); var currVal_4 = "0"; _ck(_v, 6, 0, currVal_4); }); }
export function View_AuthFoundMultipleComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-auth-multiple", [], null, null, null, View_AuthFoundMultipleComponent_0, RenderType_AuthFoundMultipleComponent)), i0.ɵdid(1, 114688, null, 0, i4.AuthFoundMultipleComponent, [i5.EsiaService, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AuthFoundMultipleComponentNgFactory = i0.ɵccf("app-auth-multiple", i4.AuthFoundMultipleComponent, View_AuthFoundMultipleComponent_Host_0, {}, {}, []);
export { AuthFoundMultipleComponentNgFactory as AuthFoundMultipleComponentNgFactory };
