import { EventEmitter } from '@angular/core';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { InstitutionTicketDataService } from '../../../logic/services/institution-ticket-data.service';
import { StringHelper } from '../../../helpers/string-helper';
import { AgGridLookupRendererComponent } from '../../../ui/controls/ag-grid-lookup-renderer.component';
import { AgGridButtonRendererComponent } from '../../../ui/controls/ag-grid-button-renderer.component';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { TicketsPlanningUiService } from '../../edit-institution/tickets/tickets-planning-ui-service';
var EditAnimalTicketsComponent = /** @class */ (function () {
    function EditAnimalTicketsComponent(institutionTicketDataService, dataCachingService, waitingOverlay, navigationService, alertService, ticketUiService) {
        this.institutionTicketDataService = institutionTicketDataService;
        this.dataCachingService = dataCachingService;
        this.waitingOverlay = waitingOverlay;
        this.navigationService = navigationService;
        this.alertService = alertService;
        this.ticketUiService = ticketUiService;
        this.model = {};
        this.isExternal = false;
        this.ticketActionEmitter = undefined;
        this.frameworkComponents = {
            lookupRenderer: AgGridLookupRendererComponent,
            buttonRenderer: AgGridButtonRendererComponent,
        };
        this.getRowStyle = EditAnimalTicketsComponent.getRowStyle;
        this.gridColumnDefs = EditAnimalTicketsComponent.getColumnDefs().concat([{
                headerName: 'ХС', width: 200,
                field: 'agentId',
                cellRenderer: 'lookupRenderer',
                cellRendererParams: function (row) {
                    return {
                        value: row.value,
                        lookupName: 'agent',
                        isPipe: true,
                    };
                }
            },]);
    }
    Object.defineProperty(EditAnimalTicketsComponent.prototype, "id", {
        get: function () {
            return this._id;
        },
        set: function (id) {
            if (this._id !== id) {
                this._id = id;
                this.refreshFormDataInternal();
            }
        },
        enumerable: true,
        configurable: true
    });
    EditAnimalTicketsComponent.getColumnDefs = function () {
        return [
            {
                headerName: '№', field: 'id', width: 100,
            },
            {
                headerName: 'Время', width: 160,
                field: 'startTime',
                valueFormatter: function (params) { return StringHelper.getRuTime(params.value); },
                cellRenderer: function (params) {
                    return (params.data.dateSuccessful
                        ? '<clr-icon shape="check" style="color:green" title="Подтверждённый приём"></clr-icon>&nbsp;'
                        : '') + StringHelper.getRuTime(params.value);
                },
            },
            {
                headerName: 'Учреждение', width: 160,
                field: 'institutionId',
                cellRenderer: 'lookupRenderer',
                cellRendererParams: function (row) {
                    return {
                        value: row.value,
                        lookupName: 'institution',
                        isPipe: true,
                    };
                }
            },
            {
                headerName: 'Филиал', width: 160,
                field: 'institutionBranchId',
                cellRenderer: 'lookupRenderer',
                cellRendererParams: function (row) {
                    return {
                        value: row.value,
                        value2: row.data.institutionId,
                        lookupName: 'institution-branch',
                        isPipe: true,
                    };
                }
            },
            {
                headerName: 'Врач', width: 160,
                field: 'institutionEmployeeId',
                cellRenderer: 'lookupRenderer',
                cellRendererParams: function (row) {
                    return {
                        value: row.value,
                        value2: row.data.institutionId,
                        lookupName: 'institution-employee',
                        isPipe: true,
                    };
                }
            },
        ];
    };
    EditAnimalTicketsComponent.getRowStyle = function (params) {
        if (!params || !params.data) {
            return;
        }
        else if (params.data.cancelTime) {
            return { color: 'silver' };
        }
    };
    EditAnimalTicketsComponent.prototype.refreshFormData = function () {
        this.dataCachingService.removeCachedData('EditAnimalTickets' + (this.isExternal ? 'External' : ''), this.id.toString());
        this.refreshFormDataInternal();
    };
    EditAnimalTicketsComponent.prototype.refreshFormDataInternal = function () {
        var _this = this;
        this.waitingOverlay.StartWaiting();
        var existing = this.dataCachingService.getCachedData('EditAnimalTickets' + (this.isExternal ? 'External' : ''), this.id.toString());
        if (existing) {
            this.model = existing;
            this.waitingOverlay.EndWaiting();
        }
        else {
            this.institutionTicketDataService.searchByAnimal(this.id, this.isExternal)
                .subscribe({
                next: function (data) {
                    data.sort(function (a, b) { return b.startTime.localeCompare(b.startTime); });
                    _this.model = { searchResults: data };
                    _this.dataCachingService.addToCache('EditAnimalTickets' + (_this.isExternal ? 'External' : ''), _this.id.toString(), _this.model);
                    _this.waitingOverlay.EndWaiting();
                },
                error: function () {
                    _this.waitingOverlay.EndWaiting();
                }
            });
        }
    };
    EditAnimalTicketsComponent.prototype.clickTicket = function (event) {
        var _this = this;
        this.ticketActionEmitter = new EventEmitter();
        this.ticketUiService.editTicket(event, true, this.ticketActionEmitter);
        this.ticketActionEmitter.subscribe(function (emit) {
            if (emit) {
                _this.refreshFormData();
            }
        });
    };
    return EditAnimalTicketsComponent;
}());
export { EditAnimalTicketsComponent };
