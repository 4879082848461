
      <div [formGroup]="contextFormGroup">
          <div class="clr-control-container clr-control-inline">
              <div class="clr-radio-wrapper itech-highlight-checked">
                  <input [id]="'radioFixedPicker' + controlNameDate1+'_'+rndToken" type="radio"
                         [formControlName]="controlNameDate1 + 'Kind'" [value]="1" class="clr-radio"/>
                  <label [for]="'radioFixedPicker' + controlNameDate1+'_'+rndToken">Точ.дата</label>
              </div>
              &nbsp;&nbsp;&nbsp;
              <div *ngIf="withApproximateDate" class="clr-radio-wrapper itech-highlight-checked">
                  <input [id]="'radioFixedFields' + controlNameDate1+'_'+rndToken" type="radio"
                         [formControlName]="controlNameDate1 + 'Kind'" [value]="2" class="clr-radio"/>
                  <label [for]="'radioFixedFields' + controlNameDate1+'_'+rndToken">Прим.дата</label>
              </div>
              &nbsp;&nbsp;&nbsp;
              <div class="clr-radio-wrapper itech-highlight-checked">
                  <input [id]="'radioPeriod' + controlNameDate1+'_'+rndToken" type="radio"
                         [formControlName]="controlNameDate1 + 'Kind'" [value]="3" class="clr-radio"/>
                  <label [for]="'radioPeriod' + controlNameDate1+'_'+rndToken">Период</label>
              </div>
              &nbsp;&nbsp;&nbsp;
              <div class="clr-radio-wrapper itech-highlight-checked">
                  <input [id]="'radioUnknown' + controlNameDate1+'_'+rndToken" type="radio"
                         [formControlName]="controlNameDate1 + 'Kind'" [value]="4" class="clr-radio"/>
                  <label [for]="'radioUnknown' + controlNameDate1+'_'+rndToken">Неизвестно</label>
              </div>
              &nbsp;&nbsp;&nbsp;
              <div class="clr-radio-wrapper itech-highlight-checked">
                  <input [id]="'radioUnlimited' + controlNameDate1+'_'+rndToken" type="radio"
                         [formControlName]="controlNameDate1 + 'Kind'" [value]="5" class="clr-radio"/>
                  <label [for]="'radioUnlimited' + controlNameDate1+'_'+rndToken">Не ограничен</label>
              </div>
              &nbsp;&nbsp;&nbsp;
              <div class="clr-radio-wrapper itech-highlight-checked">
                  <input [id]="'radioEmpty' + controlNameDate1+'_'+rndToken" type="radio"
                         [formControlName]="controlNameDate1 + 'Kind'" [value]="0" class="clr-radio"/>
                  <label [for]="'radioEmpty' + controlNameDate1+'_'+rndToken">Отсут.</label>
              </div>
          </div>

          <div *ngIf="contextFormGroup.get(controlNameDate1 + 'Kind').value == 1">
              <app-datetime-picker [contextFormGroup]="contextFormGroup" [withTime]="withTime"
                                   [contextControlName]="controlNameDate1"></app-datetime-picker>
          </div>
          <div *ngIf="contextFormGroup.get(controlNameDate1 + 'Kind').value == 2">
              <app-date-string-fields [contextFormGroup]="contextFormGroup" [isRequiredDayAndHour]="isRequiredDayAndHour"
                                      [controlNameDate]="controlNameDate1"></app-date-string-fields>
          </div>
          <div *ngIf="contextFormGroup.get(controlNameDate1 + 'Kind').value == 3">
              <div style="display:flex;">
                  <label style="padding-right: 10px">Начало: </label>
                  <app-date-string-fields [contextFormGroup]="contextFormGroup" [isRequiredDayAndHour]="isRequiredDayAndHour"
                                          [controlNameDate]="controlNameDate1"
                                          [withTime]="withTime"></app-date-string-fields>
              </div>
              <div style="display:flex;">
                  <label style="padding-right: 17px">Конец: </label>
                  <app-date-string-fields [contextFormGroup]="contextFormGroup" [isRequiredDayAndHour]="isRequiredDayAndHour"
                                          [controlNameDate]="controlNameDate2"
                                          [withTime]="withTime"></app-date-string-fields>
              </div>
          </div>
      </div>