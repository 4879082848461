import { Injectable, Type } from '@angular/core';
import { Router } from '@angular/router';
import { SecurityService } from './security.service';
import { StringHelper } from '../../helpers/string-helper';
import { DateHelper } from '../../helpers/date-helper';
import { Observable, Subject } from 'rxjs';
import { StorageHelper } from '../../helpers/storage-helper';
import { GroupOperationMetadataService } from './group-operation-metadata.service';
import { QueryParamsComponent } from '../../pages/query/query-params.component';
import { ModalParams, ModalSize } from './app-navigation.service.models';
import { environment } from '../../../environments/environment';
import { MetadataService } from './metadata.service';
import { AlertService } from '../../ui/infrastructure/alert.service';
import { QueryViewParamsComponent } from '../../pages/query/query-view-params.component';

@Injectable()
export class AppNavigationService {

  public customModalParams: any = {};
  public customModalOpened = false;
  public customModalTitle = 'Действие';
  public customModalAcceptButtonText = 'ОК';
  public customModalAcceptPressed = false;
  public customModalSize = 'sm';
  public customModalComponent = undefined;
  public customModalAcceptInternalPromise: Subject<boolean>;
  public customModalAcceptExternalPromise: Subject<any>;
  public customModalAcceptExternalPromiseNumber: Subject<number>;
  public customModalAcceptExternalPromiseAny: Subject<any>;

  public versionHistoryOpened = false;

  public showModal<TComponent, TParams, TResult>(component: Type<TComponent>,
                                                 params: ModalParams<TParams>): Observable<TResult> {
    if (params.size) {
      switch (params.size) {
        case ModalSize.sm:
          this.customModalSize = 'sm';
          break;
        case ModalSize.medium:
          this.customModalSize = '';
          break;
        case ModalSize.lg:
          this.customModalSize = 'lg';
          break;
        case ModalSize.xl:
          this.customModalSize = 'xl';
          break;
        default:
          this.customModalSize = '';
          break;
      }
    } else {
      this.customModalSize = '';
    }

    this.customModalComponent = component;
    this.customModalTitle = params.title ? params.title : '';
    this.customModalAcceptButtonText = params.acceptText ? params.acceptText : 'OK';
    this.customModalParams = params.initBodyParams;

    this.customModalAcceptPressed = false;
    this.customModalOpened = true;

    this.customModalAcceptExternalPromise = new Subject<TResult>();
    return this.customModalAcceptExternalPromise.asObservable();
  }

  constructor(private router: Router,
              private groupOperationMetadataService: GroupOperationMetadataService,
              private securityService: SecurityService,
              private metadataService: MetadataService,
              private alertService: AlertService) {
  }

  public performSearch(searchString: string) {
    const route = ['/person-search', searchString];
    this.router.navigate(route);
  }

  public performPersonEditing(personId: number) {
    this.router.navigate(['/person-edit', personId]);
  }

  public performInstitutionCreation() {
    const randomUniqueTag = 'new$' + Math.floor(Math.random() * 100000000000) + 1;
    this.router.navigate(['/institution-create', randomUniqueTag]);
  }

  public performAgentCreation() {
    const randomUniqueTag = 'new$' + Math.floor(Math.random() * 100000000000) + 1;
    this.router.navigate(['/agent-create', randomUniqueTag]);
  }

  public performDrugCreation() {
    const randomUniqueTag = 'new$' + Math.floor(Math.random() * 100000000000) + 1;
    this.router.navigate(['/drug-create', randomUniqueTag]);
  }

  public performStableCreation(newAgentId?: number) {
    const randomUniqueTag = 'new$' + Math.floor(Math.random() * 100000000000) + 1;
    this.router.navigate(['/stable-create', randomUniqueTag, {newAgentId: newAgentId}]);
  }

  public performAnimalEventCreation(animalId: number, eventTypeId?: number, stableId?: number, animalIds?: any[], stableIds?: any[]) {
    const randomUniqueTag = 'new$' + Math.floor(Math.random() * 100000000000) + 1;
      this.router.navigate(['/animal-event-create', randomUniqueTag, {animalId: animalId, eventTypeId: eventTypeId || '',
        stableId: stableId || '', animals: animalIds ? '[' + animalIds + ']' : '[]',  stables: stableIds ? '[' + stableIds + ']' : '[]'}]);
  }

  public performAnimalEventCreationManyByQuarantineId(quarantineId: number, animalId: number, animalIds: any[],
                                                      eventTypeId?: number, diseaseTypeId?: number) {
    const randomUniqueTag = 'new$' + Math.floor(Math.random() * 100000000000) + 1;
    this.router.navigate(['/animal-event-create', randomUniqueTag,
      {quarantineId: quarantineId, animalId: animalId, animals: '[' + animalIds + ']',
        eventTypeId: eventTypeId || '', diseaseTypeId: diseaseTypeId || ''}]);
  }

  public performStableEventCreation(stableId: number) {
    const randomUniqueTag = 'new$' + Math.floor(Math.random() * 100000000000) + 1;
    this.router.navigate(['/animal-event-create', randomUniqueTag, {stableId: stableId}]);
  }

  public performStableEventCreationManyByQuarantineId(quarantineId: number, stableId: number, stableIds: any[], eventTypeId?: number) {
    const randomUniqueTag = 'new$' + Math.floor(Math.random() * 100000000000) + 1;
    this.router.navigate(['/animal-event-create', randomUniqueTag,
      {quarantineId: quarantineId, stableId: stableId, stables: '[' + stableIds + ']', eventTypeId: eventTypeId || ''}]);
  }

  public performAnimalCreation(newAgentId?: number, newStableId?: number, animalToCloneId?: number) {
    const randomUniqueTag = 'new$' + Math.floor(Math.random() * 100000000000) + 1;
    this.router.navigate(['/animal-create', randomUniqueTag, {
      newAgentId: newAgentId, newStableId: newStableId,
      animalToCloneId: animalToCloneId
    }]);
  }

  public performProductCreation(sourceProductId?: number, productToCloneId?: number,
                                sourceAnimalId?: number, transactionType?: number,
                                originStableId?: number, transportProductIds?: any[],
                                fromTemplate?: boolean) {
    const randomUniqueTag = 'new$' + Math.floor(Math.random() * 100000000000) + 1;
    this.router.navigate(['/product-create', randomUniqueTag, {
      sourceProductId: sourceProductId || '',
      productToCloneId: productToCloneId || '',
      sourceAnimalId: sourceAnimalId || '',
      transactionType: transactionType || '',
      originStableId: originStableId || '',
      transportProductIds: transportProductIds || '',
      fromTemplate: fromTemplate || 'false',
    }]);
  }

  public performProductDerivation(sourceProductId?: number, transactionType?: number) {
    const randomUniqueTag = 'new$' + Math.floor(Math.random() * 100000000000) + 1;
    this.router.navigate(['/product-create', randomUniqueTag, {
      sourceProductId: sourceProductId || '',
      transactionType: transactionType || ''
    }]);
  }

  public performInstitutionEditing(id: number) {
    this.router.navigate(['/institution-edit', id]);
  }

  public performAgentEditing(id: number) {
    this.router.navigate(['/agent-edit', id]);
  }

  public performProductEditing(id: number) {
    this.router.navigate(['/product-edit', id]);
  }

  public performProductExpertiseEditing(productId: number, id: number) {
    this.router.navigate([`/product-edit/${productId}/expertises`, id]);
  }

  public performProductTransactionEditing(id: number) {
    this.router.navigate(['/product-transaction', id]);
  }

  public performAnimalEventEditing(id: number) {
    this.router.navigate(['/animal-event-edit', id]);
  }

  public performStableEditing(id: number, newAgentId?: number) {
    this.router.navigate(['/stable-edit', id, {newAgentId: newAgentId}]);
  }

  public performDrugEditing(id: number) {
    this.router.navigate(['/drug-edit', id]);
  }

  public performAnimalEditing(id: number, newAgentId?: number, newStableId?: number, isExternalAnimal?: boolean) {
    this.router.navigate(['/animal-edit', id, {newAgentId: newAgentId, newStableId: newStableId, isExternalAnimal: isExternalAnimal}]);
  }

  public performSecurityRoleEditing(id: number) {
    this.router.navigate(['/security-role', id]);
  }

  public navigateGroupOperation(jobData: any) {
    const params = JSON.parse(jobData.parameters);
    params.uniqueTag = jobData.id;
    // сначала делали каждый отчет через групповые операции по отдельности, для гибкости. Потом сделали универсальный
    // компонент для запуска таких отчетов, содержащий ограниченный набор параметров, подходящих 95% отчетов.
    // Если чего-то не помещается в универсальный механизм, можно или его доработать, или если совсем "левые" параметры,
    // то по старинке сделать свою форму
    this.groupOperationMetadataService.getReportMetadataByJobTypeId$(jobData.typeId, params.reportId).subscribe(opMeta => {
      if (opMeta) {
        this.performGroupOperation(opMeta.operationParamsCode, params);
      } else {
        if (jobData.result) {
          this.metadataService.preloadFile(jobData.result).subscribe(fileHash => {
            window.open(environment.api + '/files/get?preloadId=' + encodeURIComponent(fileHash));
          });
        } else if (jobData.message) {
          this.alertService.error('Результат нештатно завершившейся массовой операции: ' + jobData.message);
        }
      }
    });
  }

  public performGroupOperation(opCode: string, params?: any) {
    this.groupOperationMetadataService.getOpDefaultParams$(opCode).subscribe(defaultParams => {
      this.router.navigate(['/operations/general', opCode, (params || defaultParams).uniqueTag, params || defaultParams]);
    });
  }

  public navigateJournal(userId: number, objKindId?: number, objId1?: number, objId2?: number) {
    this.router.navigate(['/journal', {userId: userId, objKindId: objKindId, objId1: objId1, objId2: objId2}]);
  }

  public queryUserForQueryParams(query: any): Observable<boolean> {
    return this.showModal(
      QueryParamsComponent,
      new ModalParams<any>(
        ModalSize.lg,
        'Ввод параметров запроса',
        'Выполнить',
        query));
  }

  public queryUserForQueryViewParams(viewParams: any[]): Observable<boolean> {
    return this.showModal(
      QueryViewParamsComponent,
      new ModalParams<any>(
        ModalSize.medium,
        'Параметры отображения результатов',
        'Выполнить',
        viewParams));
  }

  public createAgentModal(component: any, searchParams: any = {}): Observable<any> {
    this.customModalSize = 'lg';
    this.customModalAcceptInternalPromise = new Subject<boolean>();
    this.customModalAcceptExternalPromiseAny = new Subject<any>();
    this.customModalOpened = true;
    this.customModalComponent = component;
    this.customModalTitle = 'Создание нового хозяйствующего субъекта';
    this.customModalAcceptButtonText = undefined;
    this.customModalAcceptPressed = false;
    this.customModalParams = searchParams;
    return this.customModalAcceptExternalPromiseAny.asObservable();
  }

  public searchAgent(component: any, searchParams: any = {}): Observable<number> {
    this.customModalSize = 'lg';
    this.customModalAcceptInternalPromise = new Subject<boolean>();
    this.customModalAcceptExternalPromiseNumber = new Subject<number>();
    this.customModalOpened = true;
    this.customModalComponent = component;
    this.customModalTitle = 'Выбор хозяйствующего субъекта';
    this.customModalAcceptButtonText = undefined;
    this.customModalAcceptPressed = false;
    this.customModalParams = searchParams;
    return this.customModalAcceptExternalPromiseNumber.asObservable();
  }

  public searchAnimal(component: any, searchParams: any = {}): Observable<any> {
    this.customModalSize = 'lg';
    this.customModalAcceptInternalPromise = new Subject<boolean>();
    this.customModalAcceptExternalPromise = new Subject<any>();
    this.customModalOpened = true;
    this.customModalComponent = component;
    this.customModalTitle = 'Выбор животного';
    this.customModalAcceptButtonText = undefined;
    this.customModalAcceptPressed = false;
    searchParams.addManyAnimals = false;
    this.customModalParams = searchParams;
    return this.customModalAcceptExternalPromise.asObservable();
  }

  public searchManyAnimals(component: any, searchParams: any = {}): Observable<any> {
    this.customModalSize = 'lg';
    this.customModalAcceptInternalPromise = new Subject<boolean>();
    this.customModalAcceptExternalPromiseAny = new Subject<any>();
    this.customModalOpened = true;
    this.customModalComponent = component;
    this.customModalTitle = 'Выбор животных из списка';
    this.customModalAcceptButtonText = undefined;
    this.customModalAcceptPressed = false;
    searchParams.addManyAnimals = true;
    this.customModalParams = searchParams;
    return this.customModalAcceptExternalPromiseAny.asObservable();
  }

  public searchStable(component: any, searchParams: any = {}): Observable<number> {
    this.customModalSize = 'lg';
    this.customModalAcceptInternalPromise = new Subject<boolean>();
    this.customModalAcceptExternalPromiseNumber = new Subject<number>();
    this.customModalOpened = true;
    this.customModalComponent = component;
    this.customModalTitle = 'Выбор поднадзорного объекта';
    this.customModalAcceptButtonText = undefined;
    this.customModalAcceptPressed = false;
    this.customModalParams = searchParams;
    return this.customModalAcceptExternalPromiseNumber.asObservable();
  }

  public searchDrug(component: any, searchParams: any = {}): Observable<any> {
    this.customModalAcceptExternalPromiseNumber = new Subject<number>();
    this.securityService.getUserInfo().subscribe(userInfo => {
      this.customModalSize = 'xl';
      this.customModalAcceptInternalPromise = new Subject<boolean>();
      this.customModalOpened = true;
      this.customModalComponent = component;
      this.customModalTitle = 'Выбор препарата';
      this.customModalAcceptButtonText = undefined;
      this.customModalAcceptPressed = false;
      this.customModalParams = searchParams;
      this.customModalParams.institutionId = userInfo.institutionId;
    });
    return this.customModalAcceptExternalPromiseNumber.asObservable();
  }

  public searchManyProducts(component: any, searchParams: any = {}): Observable<any> {
    this.customModalSize = 'xl';
    this.customModalAcceptInternalPromise = new Subject<boolean>();
    this.customModalAcceptExternalPromise = new Subject<any>();
    this.customModalOpened = true;
    this.customModalComponent = component;
    this.customModalTitle = 'Выбор документов из списка';
    this.customModalAcceptButtonText = undefined;
    this.customModalAcceptPressed = false;
    searchParams.addManySelected = true;
    this.customModalParams = searchParams;
    this.customModalAcceptButtonText = 'Выбрать';
    return this.customModalAcceptExternalPromise.asObservable();
  }

  public previewFile(component: any, params: any = {}) {
    this.customModalSize = 'xl';
    this.customModalAcceptInternalPromise = new Subject<boolean>();
    this.customModalAcceptExternalPromiseNumber = new Subject<number>();
    this.customModalOpened = true;
    this.customModalComponent = component;
    this.customModalTitle = '';
    this.customModalAcceptButtonText = undefined;
    this.customModalAcceptPressed = false;
    this.customModalParams = params;
  }

  public sendProductToClearance(component: any, params: any = {}): Observable<boolean> {
    this.customModalSize = 'lg';
    this.customModalAcceptInternalPromise = new Subject<boolean>();
    this.customModalAcceptExternalPromise = new Subject<boolean>();
    this.customModalOpened = true;
    this.customModalComponent = component;
    this.customModalTitle = 'Гашение документа';
    this.customModalAcceptButtonText = 'Направить';
    this.customModalAcceptPressed = false;
    this.customModalParams = params;
    return this.customModalAcceptExternalPromise.asObservable();
  }

  public showIndicatorDependencies(component: any, indicatorKey: any, ownerReportId: any): Observable<number> {
    this.customModalSize = 'xl';
    this.customModalAcceptInternalPromise = new Subject<boolean>();
    this.customModalAcceptExternalPromiseNumber = new Subject<number>();
    this.customModalOpened = true;
    this.customModalComponent = component;
    this.customModalTitle = 'Дерево расчета показателя';
    this.customModalAcceptButtonText = undefined;
    this.customModalAcceptPressed = false;
    this.customModalParams = {indicatorKey, ownerReportId};
    return this.customModalAcceptExternalPromiseNumber.asObservable();
  }

  public showReportIndicatorEdit(component: any, reportIndicatorMeta: any): Observable<number> {
    this.customModalSize = 'lg';
    this.customModalAcceptInternalPromise = new Subject<boolean>();
    this.customModalAcceptExternalPromise = new Subject<any>();
    this.customModalOpened = true;
    this.customModalComponent = component;
    this.customModalTitle = 'Редактирование графы отчета';
    this.customModalAcceptButtonText = 'Сохранить';
    this.customModalAcceptPressed = false;
    this.customModalParams = reportIndicatorMeta;
    return this.customModalAcceptExternalPromise.asObservable();
  }

  public showReportBranchEdit(component: any, reportBranchMeta: any): Observable<number> {
    this.customModalSize = 'lg';
    this.customModalAcceptInternalPromise = new Subject<boolean>();
    this.customModalAcceptExternalPromise = new Subject<any>();
    this.customModalOpened = true;
    this.customModalComponent = component;
    this.customModalTitle = 'Редактирование раздела отчета';
    this.customModalAcceptButtonText = 'Сохранить';
    this.customModalAcceptPressed = false;
    this.customModalParams = reportBranchMeta;
    return this.customModalAcceptExternalPromise.asObservable();
  }

  public showIndicatorTypeEdit(component: any, indicatorTypeMeta: any): Observable<number> {
    this.customModalSize = 'lg';
    this.customModalAcceptInternalPromise = new Subject<boolean>();
    this.customModalAcceptExternalPromise = new Subject<any>();
    this.customModalOpened = true;
    this.customModalComponent = component;
    this.customModalTitle = 'Редактирование показателя';
    this.customModalAcceptButtonText = 'Сохранить';
    this.customModalAcceptPressed = false;
    this.customModalParams = indicatorTypeMeta;
    return this.customModalAcceptExternalPromise.asObservable();
  }

  public updateLastAccessObjects(objectId: number, branch: string) {
    if (!StorageHelper.storageAvailable()) {
      return;
    }

    const cards = this.safelyReadCardsFromLocalStorage(branch);

    const ixExisting = cards.items.findIndex(el => el === objectId);

    if (ixExisting >= 0) {
      cards.items.splice(ixExisting, 1);
    }

    if (cards.items.length >= 10) {
      cards.items.splice(0, 1);
    }

    cards.items.push(objectId);

    window.localStorage.setItem(this.getLastAccessObjectsLocalStorageKey(branch), JSON.stringify(cards));
  }

  public getLastAccessedObjects(branch: string): any[] {
    if (!StorageHelper.storageAvailable()) {
      return [];
    }

    const cards = this.safelyReadCardsFromLocalStorage(branch);

    return cards.items.reverse();
  }

  private safelyReadCardsFromLocalStorage(branch: string) {
    const branchLocalStorageKey = this.getLastAccessObjectsLocalStorageKey(branch);
    let cards: any = {items: []};

    try {
      cards = JSON.parse(window.localStorage.getItem(branchLocalStorageKey));
      if (!cards) {
        cards = {items: []};
      }
      if (cards && (!cards.items || !Array.isArray(cards.items))) {
        cards.items = [];
      }
    } catch (error) {
    }
    return cards;
  }

  private getLastAccessObjectsLocalStorageKey(branch: string) {
    return `itech-vet-2019-last-cards[${branch}]`;
  }

  public get favoriteQueries(): any[] {
    if (!StorageHelper.storageAvailable()) {
      return [];
    }

    const favoritesObj = this.readFavoritesObj();

    const favorites = [];

    for (const queryId in favoritesObj) {
      if (favoritesObj.hasOwnProperty(queryId) && !isNaN(+queryId)) {
        favorites.push({id: queryId, caption: favoritesObj[queryId].caption});
      }
    }

    favorites.sort((a, b) => a.caption.localeCompare(b.caption));

    return favorites;
  }

  public setFavoriteQuery(queryId: number, queryCaption: string, favorite: boolean) {
    if (!StorageHelper.storageAvailable()) {
      return;
    }

    const favoritesObj = this.readFavoritesObj();

    if (!favorite) {
      if (favoritesObj[queryId]) {
        delete favoritesObj[queryId];
      }
    } else {
      favoritesObj[queryId] = {id: queryId, caption: queryCaption};
    }

    window.localStorage.setItem('itech-vet-2019-favorite-queries', JSON.stringify(favoritesObj));
  }

  public getFavoriteQuery(queryId: number): boolean {
    if (!StorageHelper.storageAvailable()) {
      return;
    }

    const favoritesObj = this.readFavoritesObj();

    return !!favoritesObj[queryId];
  }

  public getFavoriteQueryCaption(queryId: number): string {
    if (!StorageHelper.storageAvailable()) {
      return;
    }

    const favoritesObj = this.readFavoritesObj();

    return favoritesObj[queryId] ? favoritesObj[queryId].caption : 'Неизвестная выборка';
  }

  private readFavoritesObj() {
    let favoritesObj: any = {};

    try {
      favoritesObj = JSON.parse(window.localStorage.getItem('itech-vet-2019-favorite-queries'));
      if (!favoritesObj) {
        favoritesObj = {};
      }
    } catch (error) {
    }
    return favoritesObj;
  }

  public performQuarantineCreation(diseaseTypeId?: any) {
    const randomUniqueTag = 'new$' + Math.floor(Math.random() * 100000000000) + 1;
    this.router.navigate(['/quarantine-create', randomUniqueTag, {diseaseTypeId: diseaseTypeId}]);
  }

  public performQuarantineEditing(id: number) {
    this.router.navigate(['/quarantine-edit', id]);
  }
}
