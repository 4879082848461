import { EventEmitter, Input, OnInit, Output } from '@angular/core';
import { QueryService } from '../../logic/services/query.service';
import { DataCachingService } from '../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../logic/services/lookup-source.service';
import { FormBuilder, Validators } from '@angular/forms';
import { AlertService } from '../../ui/infrastructure/alert.service';
import { FormHelper } from '../../ui/controls/form-helper';

export abstract class QueryDetailsEntityComponent implements OnInit {

  EntityType: number;

  @Input() model = {
    data: [],
    dataColumns: [],
    nodeType: undefined,
    entityType: undefined,
    selectedNode: undefined,
    query: undefined,
    parentCondition: undefined,
    condition: undefined
  };

  storing = false;
  serverSideValidationErrors = [];
  relationTypes = [];

  @Output() creatingRelation = new EventEmitter();

  constructor(protected queryService: QueryService,
              protected dataCachingService: DataCachingService,
              protected waitingOverlay: GlobalWaitingOverlayService,
              protected lookupSourceService: LookupSourceService,
              protected alertService: AlertService,
              protected fb: FormBuilder) {
  }

  abstract getGroupDef(): any;

  ngOnInit(): void {

    this.lookupSourceService.getLookup('query-relation-type').subscribe(relationTypes => {

      this.relationTypes = [];

      relationTypes.forEach(rel => {
        if (rel.entityType === this.EntityType) {
          this.relationTypes.push(rel);
        }
      });
    });
  }

  invalidateContextFormGroup() {
    if (this.model && this.model.condition && this.model.condition.contextFormGroup) {
      this.model.condition.contextFormGroup = undefined;
    }
  }

  get contextFormGroup() {
    if (!this.model.condition.contextFormGroup) {

      const gdef = this.getGroupDef();

      if (this.model.condition.data.relationType) {
        Object.assign(gdef, {
          arityFrom: [this.model.condition.data.arityFrom, Validators.compose([Validators.required, Validators.pattern(/^\d{1,4}$/)])],
          arityTo: [this.model.condition.data.arityTo, Validators.compose([Validators.required, Validators.pattern(/^\d{1,4}$/)])],
          relationType: [this.model.condition.data.relationType]
        });
      }

      Object.assign(gdef, {
        filterRelatedToInstitution: this.model.condition.data.filterRelatedToInstitution
      });

      this.model.condition.contextFormGroup = this.fb.group(gdef);

      if (this.model.condition.isNew) {
        this.model.condition.contextFormGroup.markAsDirty();
      }

      if (!this.model.query.data.id) {
        this.model.query.contextFormGroup.markAsDirty();
      }
    }

    return this.model.condition.contextFormGroup;
  }

  store() {
    if (this.storing) {
      return;
    }

    FormHelper.markAsSubmitted(this.contextFormGroup);
    if (!this.contextFormGroup.valid) {
      return;
    }

    this.storing = true;

    const dataToStore = this.contextFormGroup.value;

    Object.assign(this.model.condition.data, dataToStore);
    this.model.condition.isNew = false;
    this.queryService.storeQuery(this.queryService.buildQueryForSave(this.model.query)).subscribe({
      next: () => {
        this.queryService.updateConditionsEditStructure(this.model.query.conditions, this.model.query.data.entityType);
        this.invalidateContextFormGroup();

        this.storing = false;
      },
      error: error => {
        this.storing = false;
        FormHelper.setSingleFormGroupServerSideValidationErrors(error, this, this.contextFormGroup);
      }
    });
  }

  deleteCondition() {
    this.alertService.confirmModal('Вы уверены, что хотите удалить условие фильтрации?').subscribe(val => {
      if (!val) {
        return;
      }

      const whereToFind = this.model.parentCondition ? this.model.parentCondition.relations : this.model.query.conditions;

      const ix = whereToFind.findIndex(el => el === this.model.condition);
      if (ix >= 0) {
        whereToFind.splice(ix, 1);
      }

      this.store();

      this.model.condition = undefined;
      this.model.parentCondition = undefined;
      this.model.nodeType = 'query';
      this.model.selectedNode = this.model.query;
    });
  }

  addRelation(rt: any) {
    this.creatingRelation.emit({relationType: rt, condition: this.model.condition});
  }

  isInvalid(cname: string) {
    return FormHelper.isInvalid(this.contextFormGroup, cname);
  }
}
