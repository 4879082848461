<div class="itech-common-wrap no-pager">
  <div class="itech-v-header-content">
    <div class="itech-content-header">
      <clr-icon shape="file-group" size="36"></clr-icon>&nbsp;Виды показателей для экспертизы
    </div>

    <div style="padding: 5px; height: 90%">
      <app-grid [uniqueGridName]="getMetaName()" [exportFileName]="getMetaTitle()" [rowData]="_searchResults"
                (rowClicked)="editRow($event.data)" [columnDefs]="gridColumnDefs"></app-grid>
    </div>
  </div>
  <div class="itech-form-actions">
    <div>
      <button class="btn btn-link btn-primary" (click)="refreshResults()">
        <clr-icon shape="refresh" class="has-badge"></clr-icon>&nbsp;Обновить список
      </button>
      <button class="btn btn-link btn-primary" (click)="editRow({})">
        <clr-icon shape="plus" has-badge="true"></clr-icon>&nbsp;Добавить новую запись
      </button>
    </div>
  </div>
</div>


<clr-modal [(clrModalOpen)]="editModalOpened" *ngIf="editModalOpened" [clrModalClosable]="false" [clrModalSize]="'lg'">
  <h3 class="modal-title">Редактирование записи
    <span *ngIf="currentIsNew" style="color:green"> [Новая]</span></h3>
  <div class="modal-body">
    <div class="form compact" [formGroup]="currentFormGroup">
      <section class="form-block itech-block-normal">
        <div class="form-group">
          <label for="id" class="required">Ид вида</label>
          <label for="id" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                 [class.invalid]="isInvalid(currentFormGroup, 'id')">
            <input type="text" id="id" placeholder="Ид" class="itech-control-xlarge"
                   formControlName="id">
            <app-validation-tooltip [input]="currentFormGroup.controls['id']"></app-validation-tooltip>
          </label>
        </div>

        <div class="form-group">
          <label for="caption" class="required">Наименование</label>
          <label for="caption" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                 [class.invalid]="isInvalid(currentFormGroup, 'caption')">
            <input type="text" id="caption" placeholder="Наименование" class="itech-control-xlarge"
                   formControlName="caption">
            <app-validation-tooltip [input]="currentFormGroup.controls['caption']"></app-validation-tooltip>
          </label>
        </div>

        <div class="form-group">
          <label for="legalAct" class="required">Нормативный документ</label>
          <label for="legalAct" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                 [class.invalid]="isInvalid(currentFormGroup, 'legalAct')">
            <input type="text" id="legalAct" placeholder="Нормативный документ" class="itech-control-xlarge"
                   formControlName="legalAct">
            <app-validation-tooltip [input]="currentFormGroup.controls['legalAct']"></app-validation-tooltip>
          </label>
        </div>

        <div class="form-group">
          <label for="method" class="required">Метод исследования</label>
          <label for="method" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md tooltip-top-left"
                 [class.invalid]="isInvalid(currentFormGroup, 'method')">
            <input type="text" id="method" placeholder="Метод исследования" class="itech-control-xlarge"
                   formControlName="method">
            <app-validation-tooltip [input]="currentFormGroup.controls['method']"></app-validation-tooltip>
          </label>
        </div>

        <div class="form-group">
          <label for="normativeValue" class="required">Нормативное значение</label>
          <label for="normativeValue" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md tooltip-top-left"
                 [class.invalid]="isInvalid(currentFormGroup, 'normativeValue')">
            <input type="text" id="normativeValue" placeholder="Нормативное значение" class="itech-control-normal"
                   formControlName="normativeValue">
            <app-validation-tooltip [input]="currentFormGroup.controls['normativeValue']"></app-validation-tooltip>
          </label>
        </div>
      </section>
    </div>
  </div>
  <div class="modal-footer itech-form-actions">
    <div>
      <button *ngIf="!currentIsNew" type="button" class="btn btn-warning-outline"
              (click)="delete(currentFormGroup.value)">
        <clr-icon shape="trash"></clr-icon>&nbsp;Удалить
      </button>
    </div>
    <div>
      <button type="button" class="btn btn-primary" (click)="addEditCompleted()">
        <clr-icon shape="check"></clr-icon>&nbsp;Сохранить
      </button>
      <button type="button" class="btn btn-warning" (click)="editModalOpened = false">
        <clr-icon shape="undo"></clr-icon>&nbsp;Отмена
      </button>
    </div>
  </div>
</clr-modal>
