<div class="main-container itech-container" *ngIf="esiaService.loggedIn()">
  <ng-container *ngIf="securityService.getCurrentUserId()|async">
    <app-header></app-header>
    <app-main>
      <ng-content></ng-content>
    </app-main>
    <app-confirm-modal></app-confirm-modal>
    <app-custom-modal></app-custom-modal>
    <app-version-history-modal></app-version-history-modal>
    <app-job-runner-modal></app-job-runner-modal>
  </ng-container>
  <app-logging-modal *ngIf="!securityService.currentUserInfoLoaded()"></app-logging-modal>
</div>
<app-auth-multiple *ngIf="esiaService.authMultipleInstitutionForUser"></app-auth-multiple>
