<div class="compact form itech-highlighted-sections" *ngIf="contextFormGroup">

  <app-doc-validation-errors [validationErrors]="contextFormGroup.docLevelServerSideValidationErrors">
  </app-doc-validation-errors>

  <section class="form-block itech-block-medium">
    <h3>Общая информация
      <span *ngIf="contextFormGroup.controls['status'].value === -100"
            [style.color]="'silver'">
        &nbsp;&nbsp;<clr-icon shape="file-settings"></clr-icon>&nbsp;Шаблон</span>
      <span *ngIf="contextFormGroup.controls['status'].value === -1"
            [style.color]="'navy'">
        &nbsp;&nbsp;Заявка
        &nbsp;
        <span [style.color]="requestStatus === 1 ? 'navy' : requestStatus === 2 ? 'green' : requestStatus === 3 ? 'red' : undefined">
          [{{contextFormGroup.controls['requestStatus'].value|lookup:'product-request-status'|async}}]
        </span>
      </span>
      <span *ngIf="contextFormGroup.controls['status'].value === 0"
            [style.color]="'silver'">
        &nbsp;&nbsp;<clr-icon shape="edit"></clr-icon>&nbsp;Проект</span>
      <span *ngIf="contextFormGroup.controls['status'].value === 1"
            [style.color]="'navy'">
        &nbsp;&nbsp;<clr-icon shape="pause"></clr-icon>&nbsp;На регистрации</span>
      <span *ngIf="contextFormGroup.controls['status'].value === 2"
            [style.color]="'green'">
        &nbsp;&nbsp;<clr-icon shape="check"></clr-icon>&nbsp;Оформлен</span>
      <span *ngIf="contextFormGroup.controls['status'].value === 3"
            [style.color]="'gray'">
        &nbsp;&nbsp;Погашен</span>
      <span *ngIf="contextFormGroup.controls['status'].value === 4"
            [style.color]="'red'">
        &nbsp;&nbsp;Аннулирован</span>
      <span *ngIf="contextFormGroup.controls['status'].value === 5"
            [style.color]="'navy'">
        &nbsp;&nbsp;<clr-icon shape="sync"></clr-icon>&nbsp;Направлен запрос на погашение</span>
      <span *ngIf="contextFormGroup.controls['status'].value === 6"
            [style.color]="'gray'">
        &nbsp;&nbsp;Израсходован</span>
      <span *ngIf="contextFormGroup.controls['status'].value === 7"
            [style.color]="'navy'">
        &nbsp;&nbsp;<clr-icon shape="sync"></clr-icon>&nbsp;Направлен запрос на аннулирование</span>
      <clr-icon *ngIf="contextFormGroup.controls['clearedComment'].value" size="24" style="margin-left: 10px"
                shape="error-standart" [style.color]="'red'" [title]="contextFormGroup.controls['clearedComment'].value">
      </clr-icon>
    </h3>

    <div class="form-group itech-group-whole-line">
      <label></label>
      <div class="itech-control-desc">
        Карточка создана:
        {{contextFormGroup.get('createdTime').value | rtime}},
        {{contextFormGroup.get('createdUser').value | lookup: 'user' | async}}
      </div>
    </div>

    <div class="form-group itech-group-whole-line" *ngIf="contextFormGroup.get('confirmedUser').value">
      <label></label>
      <div class="itech-control-desc">
        Документ оформлен в Меркурии пользователем: {{contextFormGroup.get('confirmedUser').value | lookup: 'user' | async}}
      </div>
    </div>

    <div class="form-group itech-group-whole-line" *ngIf="contextFormGroup.controls['status'].value === 4">
      <label></label>
      <div class="itech-control-desc" style="color:red">
        Карточка аннулирована:
        {{contextFormGroup.get('deletedTime').value | rtime}},
        {{contextFormGroup.get('deletedUser').value | lookup: 'user' | async}}
        &nbsp; (причина: {{contextFormGroup.get('deletedReason').value}})
      </div>
    </div>

    <div class="form-group itech-group-whole-line" *ngIf="!contextFormGroup.controls['id'].value">
      <div class="checkbox-inline" [formGroup]="contextFormGroup">
        <input type="checkbox" id="isTemplate" formControlName="isTemplate">
        <label for="isTemplate">Сделать шаблоном</label>
      </div>
    </div>

    <ng-container *ngIf="contextFormGroup.controls['isTemplate'].value">

      <div class="form-group" [formGroup]="contextFormGroup">
        <label for="templateCaption" class="required">Наименование шаблона</label>
        <label for="templateCaption" aria-haspopup="true" role="tooltip"
               class="tooltip tooltip-validation tooltip-md"
               [class.invalid]="isInvalid(contextFormGroup, 'templateCaption')">
          <input type="text" id="templateCaption" class="itech-control-xlarge"
                 formControlName="templateCaption">
          <app-validation-tooltip [input]="contextFormGroup.controls['templateCaption']"></app-validation-tooltip>
        </label>
      </div>

      <div class="form-group">
        <label for="template-visibility" class="required">Уровень доступности</label>
        <app-combo-lookup class="itech-control-large"
                          [contextControlId]="'template-visibility'"
                          [contextControlName]="'templateVisibility'"
                          [lookupName]="'person-group-visibility'"
                          [contextFormGroup]="contextFormGroup"></app-combo-lookup>
      </div>
    </ng-container>

    <div class="form-group">
      <label for="transactionType" class="required">Тип транзакции</label>
      <app-combo-lookup *ngIf="!contextFormGroup.get('id').value" (change)="changeTransactionType()"
                        [validationTooltipRight]="true" class="itech-control-large"
                        [contextControlId]="'transactionType'" [sorted]="true"
                        [contextControlName]="'transactionType'" [disabledChoices]="[5,6]"
                        [lookupName]="'transaction-type'" [contextFormGroup]="contextFormGroup"></app-combo-lookup>
      <div class="itech-text-field itech-control-large" *ngIf="contextFormGroup.get('id').value">
        {{contextFormGroup.get('transactionType').value|lookup:'transaction-type'|async}}
      </div>
      &nbsp;&nbsp;&nbsp;
      <div class="checkbox-inline" [formGroup]="contextFormGroup">
        <input type="checkbox" id="usePaperDoc" formControlName="usePaperDoc">
        <label for="usePaperDoc">Выписан на бумаге</label>
      </div>
    </div>


    <div class="form-group" *ngIf="isTransportOrMovingTransaction()">
      <label for="documentTargetType" class="required">Защищенный бланк</label>
      <app-combo-lookup [validationTooltipRight]="true" class="itech-control-xlarge"
                        [contextControlId]="'documentTargetType'" [sorted]="true"
                        [contextControlName]="'documentTargetType'"
                        [lookupName]="'document-target-type'" [contextFormGroup]="contextFormGroup"></app-combo-lookup>
    </div>

    <div class="form-group" *ngIf="isTransportTransaction()">
      <label for="storageWayId" class="required">Способ хранения при перевозке</label>
      <app-combo-lookup [validationTooltipRight]="true" class="itech-control-xlarge"
                        [contextControlId]="'storageWayId'" [sorted]="true"
                        [contextControlName]="'storageWayId'"
                        [lookupName]="'storage-way'" [contextFormGroup]="contextFormGroup"></app-combo-lookup>
    </div>

    <div class="form-group" *ngIf="contextFormGroup.controls['id'].value && contextFormGroup.controls['originAgentId'].value">
      <label>{{isTransportOrMovingTransaction()? 'Отправитель' : 'Производитель'}}</label>
      <app-link-button [id]="contextFormGroup.controls['originAgentId'].value" [lookupName]="'agent'"></app-link-button>
    </div>

    <div class="form-group" *ngIf="contextFormGroup.controls['id'].value && contextFormGroup.controls['originStableId'].value">
      <label>{{isTransportOrMovingTransaction()? 'Место отправления' : 'Место производства'}}</label>
      <app-link-button [id]="contextFormGroup.controls['originStableId'].value" [lookupName]="'stable'"></app-link-button>
    </div>

    <div class="form-group" *ngIf="contextFormGroup.controls['id'].value && isTransportOrMovingTransaction() && producers.controls.length">
      <label>Место производства</label>
      <app-link-button *ngFor="let producer of producers.controls" [id]="producer.get('stableId').value"
                       [lookupName]="'stable'"></app-link-button>
    </div>
  </section>

  <section class="form-block itech-block-medium">
    <h3>Продукция</h3>
    <ng-container *ngFor="let source of sources.controls; index as i">
      <div style="display: flex; flex-direction: row; margin-bottom: 10px">
        <div style="flex: 1 1 auto; margin-right: 10px; flex-direction: column; display: flex; align-items: center">
          <clr-icon shape="bundle" size="72"></clr-icon>
          <a class="itech-link in-table-link" (click)="deleteSource(i)">Удалить</a>
          <clr-signpost *ngIf="(source.get('discrepancyReports').value || []).length" class="itech-sync-status-lbl">
            <a class="itech-inline-link-btn" clrSignpostTrigger>
              <clr-icon shape="list" size="50"></clr-icon>
            </a>
            <clr-signpost-content [clrPosition]="'right-bottom'" class="compact form itech-highlighted-sections" style="max-width:21rem">
              <h6 style="text-align:center;font-weight:bold;margin-top:0;">Акт несоответствий</h6>
              <ng-container *ngFor="let report of source.get('discrepancyReports').controls; index as i">
                <div class="compact form itech-highlighted-sections" style="border-top:1px solid silver">
                  <section class="form-block itech-block-medium">
                    <div class="form-group">
                      <label>Дата</label>
                      <div class="itech-text-field itech-control-large">
                        {{report.get('date').value | rdate}}
                      </div>
                    </div>
                    <div class="form-group" *ngIf="report.get('serial').value || report.get('number').value">
                      <label>Серия/номер</label>
                      <div class="itech-text-field itech-control-large">
                        {{report.get('serial').value || '-'}}/{{report.get('number').value || '-'}}
                      </div>
                    </div>
                    <div class="form-group" *ngIf="report.get('reason').value">
                      <label>Причина составления</label>
                      <div class="itech-text-field itech-control-large">
                        {{report.get('reason').value}}
                      </div>
                    </div>
                    <div class="form-group" *ngIf="report.get('caption').value">
                      <label>Описание несоответствий</label>
                      <div class="itech-text-field itech-control-large">
                        {{report.get('caption').value}}
                      </div>
                    </div>
                    <div class="form-group" *ngIf="report.get('stableProductSubtypeId').value">
                      <label>Номенклатура</label>
                      <div class="itech-text-field itech-control-large">
                        {{report.get('originStableProductSubtypeId').value}} -> {{report.get('stableProductSubtypeId').value}}
                      </div>
                    </div>
                    <div class="form-group" *ngIf="report.get('volume').value">
                      <label>Количество</label>
                      <div class="itech-text-field itech-control-large">
                        {{report.get('originVolume').value}} -> {{report.get('volume').value}}
                      </div>
                    </div>
                    <div class="form-group" *ngIf="report.get('expireDateKind').value">
                      <label>Срок годности</label>
                      <div class="itech-text-field itech-control-large">
                        {{dateComboToStringPrettify(report.value, 'originExpireDate')}} -> {{dateComboToStringPrettify(report.value, 'expireDate')}}
                      </div>
                    </div>
                    <div class="form-group" *ngIf="report.get('ttnSerial').value">
                      <label>Серия ТТН</label>
                      <div class="itech-text-field itech-control-large">
                        {{report.get('originTtnSerial').value}} -> {{report.get('ttnSerial').value}}
                      </div>
                    </div>
                    <div class="form-group" *ngIf="report.get('ttnNo').value">
                      <label>Номер ТТН</label>
                      <div class="itech-text-field itech-control-large">
                        {{report.get('originTtnNo').value}} -> {{report.get('ttnNo').value}}
                      </div>
                    </div>
                    <div class="form-group" *ngIf="report.get('ttnDate').value">
                      <label>Дата ТТН</label>
                      <div class="itech-text-field itech-control-large">
                        {{report.get('originTtnDate').value}} -> {{report.get('ttnDate').value}}
                      </div>
                    </div>
                    <div class="form-group" *ngIf="report.get('vesselNo').value">
                      <label>№ ТС</label>
                      <div class="itech-text-field itech-control-large">
                        {{report.get('originVesselNo').value}} -> {{report.get('vesselNo').value}}
                      </div>
                    </div>
                    <div class="form-group" *ngIf="report.get('trailerNo').value">
                      <label>№ прицепа</label>
                      <div class="itech-text-field itech-control-large">
                        {{report.get('originTrailerNo').value}} -> {{report.get('trailerNo').value}}
                      </div>
                    </div>
                    <div class="form-group" *ngIf="report.get('containerNo').value">
                      <label>№ контейнера</label>
                      <div class="itech-text-field itech-control-large">
                        {{report.get('originContainerNo').value}} -> {{report.get('containerNo').value}}
                      </div>
                    </div>
                  </section>
                </div>
              </ng-container>
            </clr-signpost-content>
          </clr-signpost>
        </div>
        <app-edit-product-source-form id="productSource{{i}}" style="flex: 1000 1 auto" [productFormGroup]="contextFormGroup"
                                      [validityControlsEvent]="validityControlsEvent" [changeTransactionTypeEmitter]="changeTransactionTypeEmitter"
                                      [contextFormGroup]="source" [rndToken]="getRndToken() + i"></app-edit-product-source-form>
      </div>
    </ng-container>
  </section>

  <section class="form-block itech-block-medium" *ngIf="isTransportTransaction()">
    <h3>Маршрут</h3>

    <ng-container *ngFor="let p of path.controls; index as i">
      <div style="display: flex; flex-direction: row">
        <div style="flex: 1 1 auto; margin-right: 10px; flex-direction: column; display: flex; align-items: center">
          <clr-icon shape="truck" size="72" *ngIf="i!==path.controls.length - 1"></clr-icon>
          <clr-icon shape="flag" size="72" *ngIf="i===path.controls.length - 1"></clr-icon>
          <a class="itech-link in-table-link" *ngIf="i>0 && i < path.controls.length - 1" (click)="deletePath(i)">Удалить</a>
          <clr-icon shape="fast-forward" *ngIf="i < path.controls.length - 1"
                    style="transform: rotate(90deg); color: #aaaaaa" size="48"></clr-icon>
        </div>

        <div style="flex: 1000 1 auto">

          <div *ngIf="i===0 && !p.get('addressId').value" class="form-group itech-group-whole-line">
            <label></label>
            <div class="itech-text-field itech-control-xlarge itech-text-wall">
              <a class="itech-link" *ngFor="let animal of getAllSourceAnimals(); index as ix"
                 (click)="appNavigationService.performAnimalEditing(animal.value)">
                {{ix+1}}. {{animal.value|lookup:'animal'|async}}
              </a>
            </div>
          </div>

          <div *ngIf="i===0 && p.get('addressId').value" class="form-group itech-group-whole-line">
            <label></label>
            <div class="itech-text-field itech-control-xlarge">
              {{p.get('addressId').value|lookup:'address'|async}}
            </div>
          </div>

          <div *ngIf="i===path.controls.length - 1" class="form-group itech-group-whole-line">
            <label></label>

            <div class="itech-control-desc itech-desc-error" *ngIf="!getAllDestinationStables().length">
              Выберите получателя и место получения выше в разделе "Продукция"
            </div>

            <app-link-button *ngFor="let stable of getAllDestinationStables(); index as ix"
                             [id]="stable.value" [lookupName]="'stable'"></app-link-button>
          </div>

          <div class="form-group" *ngIf="i===0 && (!contextFormGroup.controls['id'].value || +contextFormGroup.controls['status'].value === 0 ||
                                   contextFormGroup.controls['brokerAgentId'].value)">
            <label>Фирма-перевозчик</label>
            <div style="display: flex">
              <app-link-button class="app-link-button-auto-width" [id]="contextFormGroup.controls['brokerAgentId'].value"
                               [lookupName]="'agent'"></app-link-button>
              &nbsp;&nbsp;
              <button *ngIf="!contextFormGroup.controls['brokerAgentId'].value"
                      type="button" class="btn btn-danger btn-link itech-inline-tool-btn" title="Выбрать поднадзорный объект"
                      (click)="searchBrokerAgent()">
                <clr-icon shape="search"></clr-icon>
              </button>
              <button *ngIf="contextFormGroup.controls['brokerAgentId'].value"
                      type="button" class="btn btn-danger btn-link itech-inline-tool-btn" title="Очистить поле"
                      (click)="removeBroker()">
                <clr-icon shape="remove"></clr-icon>
              </button>
            </div>
          </div>

          <app-edit-product-path-form [showAddressControl]="i>0 && i < path.controls.length - 1"
                                      [showWithReloadControl]="i>0 && i < path.controls.length - 1"
                                      [contextFormGroup]="p"></app-edit-product-path-form>
        </div>
      </div>
    </ng-container>

    <div class="form-group itech-group-whole-line">
      <label></label>
      <button type="button" class="btn btn-link itech-inline-link-btn" (click)="addPath()"
              [disabled]="getAllSourceAnimals().length === 0 && getAllDestinationStables().length === 0? true: undefined">
        <clr-icon shape="plus"></clr-icon>
        Добавить промежуточный пункт
      </button>
    </div>
    <div class="form-group itech-group-whole-line"
         *ngIf="getAllSourceAnimals().length === 0 && getAllDestinationStables().length === 0">
      <label></label>
      <div class="itech-control-desc itech-desc-error">
        Для выбора промежуточных точек маршрута сначала выберите начальную (животное или входящую продукцию) и конечную
        точку (получателя продукции, место получения продукции) в записи о продукции
      </div>
    </div>
  </section>

  <section *ngIf="isTransportTransaction() && !contextFormGroup.controls['isTemplate'].value" class="form-block itech-block-medium">
    <h3 *ngIf="!contextFormGroup.controls['id'].value ||
               (contextFormGroup.controls['id'].value && contextFormGroup.controls['hasRequirements'].value)">
      Регионализация
    </h3>
    <div *ngIf="!contextFormGroup.controls['id'].value">
      <button type="button" class="btn btn-link itech-inline-link-btn" (click)="checkRegionalization()">
        <clr-icon shape="checkbox-list"></clr-icon>
        Проверить регионализацию
      </button>
      <div *ngIf="contextFormGroup.controls['regionalizationRouteSections'].value.length">
        <ng-container *ngFor="let section of regionalizationRouteSections.controls; index as i1">
          <ng-container *ngFor="let rule of section.get('regionalizationRules').controls; index as i2">
            <ng-container *ngIf="rule.get('regionalizationRequirements').controls.length">
              <div>
                <label>
                  <strong>
                    Адрес: {{section.get('addressId').value|lookup:'address'|async}}
                  </strong>
                </label>
              </div>
              <div style="margin: 5px 0 0 10px">
                <label>
                  <strong>
                    Продукция <strong>{{rule.get('productSubtypeCaption').value}}</strong>
                  </strong>
                </label>
              </div>
              <div *ngFor="let requirement of rule.get('regionalizationRequirements').controls; index as i3">
                <label style="margin: 5px 0 0 20px">
                  <strong>
                    Заболевание: {{(requirement.get('diseaseTypeId').value|lookup:'disease-type'|async)}}
                  </strong>
                </label>
                <div class="form-group itech-group-whole-line">
                  <ng-container *ngFor="let group of requirement.get('conditionGroups').controls; index as i4"
                                [formGroup]="group">
                    <div class="checkbox-inline checkbox-middle" style="margin-left: 20px; margin-bottom: 0"
                         [class.invalid]="isInvalid(group, 'acceptedGroup')">
                      <input type="checkbox" id="acceptedGroup{{i1}}{{i2}}{{i3}}{{i4}}"
                             formControlName="acceptedGroup" (change)="changeAcceptedGroup(group, requirement)">
                      <label for="acceptedGroup{{i1}}{{i2}}{{i3}}{{i4}}">
                        <div *ngFor="let result of group.get('conditionResults').controls" [formGroup]="result"
                             [style.color]="group.get('acceptedGroup').value? 'green': undefined">
                          {{(result.get('conditionId').value|lookup:'regionalization-condition'|async)}}
                        </div>
                      </label>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
      <div *ngIf="contextFormGroup.controls['regionalizationRouteSections'].value.length && !contextFormGroup.controls['hasRequirements'].value">
        <label>Условий для перевозки не найдено. Отправьте документ на регистрацию.</label>
      </div>
    </div>
    <ng-container *ngIf="contextFormGroup.controls['id'].value && contextFormGroup.controls['hasRequirements'].value">
      <div *ngIf="equalsSome(contextFormGroup.controls['status'].value, 0, 1)">
        <ng-container *ngFor="let p of path.controls; index as i1">
          <ng-container *ngIf="p.get('regionalizationRequirements').controls.length">
            <label>
              <strong>
                Адрес: {{p.get('address.addressShortText').value}}
              </strong>
            </label>
            <div *ngFor="let requirement of p.get('regionalizationRequirements').controls; index as i2">
              <label style="margin: 5px 0 0 10px">
                <strong>
                  Заболевание: {{(requirement.get('diseaseTypeId').value|lookup:'disease-type'|async)}}
                </strong>
              </label>
              <div class="form-group itech-group-whole-line">
                <ng-container *ngFor="let group of requirement.get('conditionGroups').controls; index as i3"
                     [formGroup]="group" class="form-group itech-group-whole-line">
                  <div class="checkbox-inline checkbox-middle" style="margin-left: 20px"
                       [class.invalid]="isInvalid(group, 'acceptedGroup')">
                    <input type="checkbox" id="acceptedGroup{{i1}}{{i2}}{{i3}}"
                           formControlName="acceptedGroup" (change)="changeAcceptedGroup(group, requirement)">
                    <label for="acceptedGroup{{i1}}{{i2}}{{i3}}">
                      <div *ngFor="let result of group.get('conditionResults').controls" [formGroup]="result"
                           [style.color]="group.get('acceptedGroup').value? 'green': undefined">
                        {{(result.get('conditionId').value|lookup:'regionalization-condition'|async)}}
                      </div>
                    </label>
                  </div>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <div *ngIf="equalsSome(contextFormGroup.controls['status'].value, 2, 3, 5)">
        <label><strong>Согласно правилам регионализации данная продукция может перемещаться в адрес
          указанного получателя при выполнении следующих условий перевозки:</strong></label>
        <ng-container *ngFor="let p of path.controls">
          <ng-container *ngFor="let requirement of p.get('regionalizationRequirements').controls">
            <ng-container *ngFor="let group of requirement.get('conditionGroups').controls">
              <ng-container *ngIf="group.get('acceptedGroup').value">
                <ng-container *ngFor="let result of group.get('conditionResults').controls" [formGroup]="result">
                  <label>
                    <li>{{(result.get('conditionId').value|lookup:'regionalization-condition'|async)}}
                      ({{(requirement.get('diseaseTypeId').value|lookup:'disease-type'|async)}}).</li>
                  </label>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </section>
  &nbsp;&nbsp;
</div>
