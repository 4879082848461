
      <div class="form-group itech-group-whole-line">
      <label>
      </label>
      <div *ngFor="let parent of (lookupParent() | async)"
           [style.width]="parent.expanded ? '100%' : undefined">
          <button type="button" class="btn btn-outline"
                  [disabled]="disabled"
                  style="background:none;border:none;margin:0;text-transform:none;color:black;letter-spacing:normal"
                  (click)="parent.expanded = !parent.expanded">
              <span style="font-size:11px" [style.color]="selectedIds[parent.id] && selectedIds[parent.id].length ? 'blue' : undefined">
                {{ parent.caption }}
                <span *ngIf="selectedIds[parent.id] && selectedIds[parent.id].length">
                  ({{selectedIds[parent.id].length}})
                </span>
              </span>
              <clr-icon *ngIf="parent.expanded" shape="angle up"></clr-icon>
              <clr-icon *ngIf="!parent.expanded" shape="angle down"></clr-icon>
          </button>
          <div *ngIf="parent.expanded">
              <app-checkbox-select [lookupName]="lookupName + parent.id" [contextControlName]="contextControlName"
                                   [contextFormGroup]="contextFormGroup" [sorted]="true" [nameId]="nameId"
                                   [disabled]="disabled">
              </app-checkbox-select>
          </div>
      </div>
  </div>