import { ChangeDetectorRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { AnimalDataService } from '../../../logic/services/animal-data.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { SecurityService } from '../../../logic/services/security.service';
import { EditAnimalEventCommonFormComponent } from './edit-animal-event-common-form.component';
import { FormHelper } from '../../../ui/controls/form-helper';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { StringHelper } from '../../../helpers/string-helper';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';
import { combineLatest, of } from 'rxjs/index';
var CreateAnimalEventComponent = /** @class */ (function () {
    function CreateAnimalEventComponent(route, router, cache, bookmarkService, animalDataService, dataCachingService, appNavigationService, securityService, lookupSourceService, cdr, fb) {
        this.route = route;
        this.router = router;
        this.cache = cache;
        this.bookmarkService = bookmarkService;
        this.animalDataService = animalDataService;
        this.dataCachingService = dataCachingService;
        this.appNavigationService = appNavigationService;
        this.securityService = securityService;
        this.lookupSourceService = lookupSourceService;
        this.cdr = cdr;
        this.fb = fb;
        this.creating = false;
        this.created = false;
        this.selectedAnimalsCount = 0;
    }
    CreateAnimalEventComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.securityService.getUserInfo().subscribe(function (currentUserInfo) {
            _this.router.events.subscribe(function (val) {
                if (!_this.created) {
                    _this.refreshCurrentBookmark(true);
                }
            });
            _this.route.paramMap
                .subscribe(function (params) {
                var randomUniqueTag = params.get('randomUniqueTag');
                _this.animalId = params.get('animalId') ? +params.get('animalId') : undefined;
                _this.stableId = params.get('stableId') ? +params.get('stableId') : undefined;
                _this.eventTypeId = params.get('eventTypeId') ? +params.get('eventTypeId') : undefined;
                _this.animals = params.get('animals') ? JSON.parse(params.get('animals')) : undefined;
                _this.quarantineId = params.get('quarantineId') ? +params.get('quarantineId') : undefined;
                _this.stables = params.get('stables') ? JSON.parse(params.get('stables')) : undefined;
                _this.diseaseTypeId = params.get('diseaseTypeId') ? +params.get('diseaseTypeId') : undefined;
                var existing = _this.dataCachingService.getCachedData('CreateAnimalEvent', randomUniqueTag);
                if (!existing) {
                    var animal$ = _this.animalId
                        ? _this.cache.getAnimal(_this.animalId)
                        : of(undefined);
                    var animals$ = _this.animals && _this.animals.length
                        ? _this.animalDataService.getAnimalCommonForLookup2(_this.animals)
                        : of(undefined);
                    combineLatest([animal$, animals$])
                        .subscribe(function (_a) {
                        var animal = _a[0], animals = _a[1];
                        _this.model = {
                            randomUniqueTag: randomUniqueTag,
                            serverSideValidationErrors: [],
                            animal: animal
                        };
                        _this.model.form = EditAnimalEventCommonFormComponent.createFormGroup(_this.fb, _this.model, {
                            animalId: _this.animalId,
                            stableId: _this.stableId,
                            eventTypeId: _this.eventTypeId,
                            diseaseTypeId: _this.diseaseTypeId,
                            date: StringHelper.getISODate(new Date()),
                            animals: _this.animals,
                            quarantineId: _this.quarantineId,
                            stables: _this.stables,
                            animalEventSingleResults: (animals || [])
                                .filter(function (el) { return !(el.animalGroup || false); })
                                .map(function (el) {
                                return {
                                    animalId: el.id,
                                    caption: el.caption,
                                };
                            }),
                            animalEventGroupResults: (animals || [])
                                .filter(function (el) { return el.animalGroup || false; })
                                .map(function (el) {
                                return {
                                    animalId: el.id,
                                    caption: el.caption,
                                    animalTypeId: el.animalTypeId,
                                    animalSubTypeId: el.animalSubTypeId,
                                };
                            }),
                        }, function () { return _this.model.animal; });
                        _this.model.form.get('animalGroup').setValue(_this.model.animal ? _this.model.animal.animalGroup : false);
                        _this.lookupSourceService.getLookupObj('event-type').subscribe(function (lookup) {
                            _this.model.form.get('eventTypeId').valueChanges.subscribe(function () {
                                _this.model.eventType = lookup['Obj' + _this.model.form.get('eventTypeId').value];
                                _this.model.form.get('eventKind').setValue(_this.model.eventType ? _this.model.eventType.eventKind : null);
                            });
                            _this.model.eventType = lookup['Obj' + _this.model.form.get('eventTypeId').value];
                        });
                        _this.invalidateCaches();
                        _this.dataCachingService.addToCache('CreateAnimalEvent', randomUniqueTag, _this.model, 10);
                        _this.refreshCurrentBookmark();
                    });
                }
                else {
                    _this.model = existing;
                    _this.refreshCurrentBookmark();
                }
            });
        });
    };
    CreateAnimalEventComponent.prototype.invalidateCaches = function () {
        if (this.animalId) {
            this.dataCachingService.removeCachedData('EditAnimalEvents', this.animalId.toString());
        }
        if (this.stableId) {
            this.dataCachingService.removeCachedData('EditStableEvents', this.stableId.toString());
        }
    };
    CreateAnimalEventComponent.prototype.refreshCurrentBookmark = function (skipIfNotExists) {
        var _this = this;
        this.lookupSourceService.getLookupObj('event-type').subscribe(function (lookup) {
            var caption = lookup[_this.model.form.get('eventTypeId').value] || '';
            var parentTag;
            if (_this.quarantineId) {
                parentTag = '/quarantine-edit' + _this.quarantineId.toString();
            }
            else if (_this.animalId) {
                parentTag = '/animal-edit' + _this.animalId.toString();
            }
            else if (_this.stableId) {
                parentTag = '/stable-edit' + _this.stableId.toString();
            }
            _this.bookmarkService.addOpenCardBookmark(_this.model.randomUniqueTag, "\u041D\u043E\u0432\u043E\u0435 \u043C\u0435\u0440\u043E\u043F\u0440\u0438\u044F\u0442\u0438\u0435 " + caption, ['/animal-event-create', _this.model.randomUniqueTag, { animalId: _this.animalId, stableId: _this.stableId }], 'color-picker', skipIfNotExists, parentTag);
        });
    };
    CreateAnimalEventComponent.prototype.createCard = function (continueCreating) {
        var _this = this;
        FormHelper.markAsSubmitted(this.model.form);
        if (!this.model.form.valid) {
            return;
        }
        var dataToStore = this.model.form.value;
        this.creating = true;
        if (dataToStore.animalStableMode) {
            dataToStore.animalId = dataToStore.animals[0];
        }
        if (dataToStore.drugs) {
            for (var i = 0; i < dataToStore.drugs.length; i++) {
                if (dataToStore.drugs[i].drugId) {
                    dataToStore.drugs[i].drugTypeId = null;
                }
            }
        }
        var storeObs = !(dataToStore.animals || []).length && !(dataToStore.stables || []).length && !(dataToStore.diseases || []).length &&
            !(dataToStore.animalEventSingleResults || []).length && !(dataToStore.animalEventGroupResults || []).length
            ? this.animalDataService.storeAnimalEvent(dataToStore)
            : this.animalDataService.createAnimalEvents(dataToStore);
        storeObs.subscribe(function (createdAnimalEvent) {
            _this.creating = false;
            _this.created = true;
            _this.bookmarkService.removeOpenCardBookmark(['/animal-event-create'], _this.model.randomUniqueTag);
            if (continueCreating) {
                _this.animalEventFormChild.gridApiSingle = null;
                _this.animalEventFormChild.gridApiGroup = null;
                _this.appNavigationService.performAnimalEventCreation(_this.animalId, _this.eventTypeId, _this.stableId, dataToStore.animals, dataToStore.stables);
            }
            else {
                _this.appNavigationService.performAnimalEventEditing(createdAnimalEvent.id);
            }
        }, function (error) {
            _this.creating = false;
            FormHelper.setSingleFormGroupServerSideValidationErrors(error, _this.model, _this.model.form);
        });
    };
    CreateAnimalEventComponent.prototype.getSelectedAnimalsCount = function () {
        var singleAnimals = this.model.form.get('animalEventSingleResults');
        var groupAnimals = this.model.form.get('animalEventGroupResults');
        this.selectedAnimalsCount = singleAnimals.length + groupAnimals.length;
        this.cdr.detectChanges();
    };
    return CreateAnimalEventComponent;
}());
export { CreateAnimalEventComponent };
