import { Component, Input, OnInit } from '@angular/core';
import { DataCachingService } from '../../logic/services/data-caching.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BookmarkService } from '../../logic/services/bookmark.service';
import { AppNavigationService } from '../../logic/services/app-navigation.service';
import { FormArray, FormBuilder } from '@angular/forms';
import { FormHelper } from '../../ui/controls/form-helper';
import { SecurityService } from '../../logic/services/security.service';
import { EditAnimalCommonFormComponent } from './common/edit-animal-common-form.component';
import { AnimalDataService } from '../../logic/services/animal-data.service';

@Component({
  selector: 'app-create-animal',
  templateUrl: './create-animal.component.html'
})
export class CreateAnimalComponent implements OnInit {

  @Input() model: any;
  newAgentId: number;
  newStableId: number;
  creating = false;
  created = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private bookmarkService: BookmarkService,
              private animalDataService: AnimalDataService,
              private dataCachingService: DataCachingService,
              private appNavigationService: AppNavigationService,
              private securityService: SecurityService,
              private fb: FormBuilder) {
  }

  ngOnInit() {

    this.securityService.getUserInfo().subscribe(currentUserInfo => {

      this.router.events.subscribe(() => {
        if (!this.created) {
          this.refreshCurrentBookmark(true);
        }
      });

      this.route.paramMap
        .subscribe(params => {

          const randomUniqueTag = params.get('randomUniqueTag');
          this.newAgentId = params.get('newAgentId') ? +params.get('newAgentId') : undefined;
          this.newStableId = params.get('newStableId') ? +params.get('newStableId') : undefined;

          const existing = this.dataCachingService.getCachedData('CreateAnimal', randomUniqueTag);

          if (!existing) {
            this.model = {
              randomUniqueTag: randomUniqueTag,
              serverSideValidationErrors: []
            };

            const animalToCloneId = params.get('animalToCloneId') ? +params.get('animalToCloneId') : undefined;

            if (!animalToCloneId) {
              this.createNewAnimalFormGroup(currentUserInfo);
            } else {
              this.createClonedAnimalFormGroup(animalToCloneId);
            }

            this.dataCachingService.addToCache('CreateAnimal', randomUniqueTag, this.model, 10);
          } else {
            this.model = existing;
            this.refreshCurrentBookmark();
          }
        });
    });
  }

  private createClonedAnimalFormGroup(animalToCloneId: number) {
    this.animalDataService.getAnimalCommonForEdit(animalToCloneId).subscribe(data => {
      this.model.form = EditAnimalCommonFormComponent.createFormGroup(this.fb, this.model, data, true);
      this.model.form.get('id').setValue(undefined);
      this.model.form.get('gender').setValue(undefined);
      this.model.form.get('pin').setValue(undefined);
      this.model.form.get('idNumber').setValue(undefined);
      this.model.form.get('caption').setValue(undefined);

      const animalSubtypesWithProduct = this.model.form.get('animalSubtypesWithProduct') as FormArray;
      const stables = this.model.form.get('stables') as FormArray;
      const agents = this.model.form.get('agents') as FormArray;
      while (animalSubtypesWithProduct.length) {
        animalSubtypesWithProduct.removeAt(0);
      }
      while (stables && stables.length > 1) {
        stables.removeAt(1);
      }
      while (agents && agents.length > 1) {
        agents.removeAt(1);
      }
      this.refreshCurrentBookmark();
    });
  }

  private createNewAnimalFormGroup(currentUserInfo) {
    this.model.form = EditAnimalCommonFormComponent.createFormGroup(this.fb, this.model, {
      address: {
        regionId: currentUserInfo ? currentUserInfo.defaultRegionId : undefined,
        cityId: currentUserInfo ? currentUserInfo.defaultCityId : undefined,
        countryId: 'RUS',
      },
      animalGroup: false,
      currentAgentId: this.newAgentId,
      currentStableId: this.newStableId,
      originCountry: 'RUS',
    });

    this.refreshCurrentBookmark();
  }

  refreshCurrentBookmark(skipIfNotExists?: boolean) {
    const caption = this.model.form.get('caption').value || '';

    this.bookmarkService.addOpenCardBookmark(this.model.randomUniqueTag,
      `Новая карточка ${caption}`,
      ['/animal-create', this.model.randomUniqueTag, {newAgentId: this.newAgentId, newStableId: this.newStableId}],
      'piggy-bank', skipIfNotExists,
      this.newStableId ? '/stable-edit' + this.newStableId : undefined);
  }

  createCard(continueCreating?: boolean, isCreateProductionCert?: boolean, sendProductionCertToRegistration?: boolean) {

    FormHelper.markAsSubmitted(this.model.form);

    if (!this.model.form.valid) {
      return;
    }

    const dataToStore = this.model.form.value;

    if (isCreateProductionCert) {
      dataToStore['isCreateProductionCert'] = true;
      dataToStore['sendProductionCertToRegistrationAfterCreate'] = sendProductionCertToRegistration;
    }

    this.creating = true;

    this.animalDataService.storeAnimalCommonInfo(dataToStore).subscribe({
      next: (createdAnimalId: any) => {
        this.creating = false;
        this.created = true;

        const agentId = this.model.form.get('currentAgentId').value;
        if (agentId) {
          this.dataCachingService.removeCachedData('EditAgentAnimals', agentId.toString());
        }

        const stableId = this.model.form.get('currentStableId').value;
        if (stableId) {
          this.dataCachingService.removeCachedData('EditStableAnimals', stableId.toString());
        }

        this.bookmarkService.removeOpenCardBookmark(['/animal-create'], this.model.randomUniqueTag);

        if (continueCreating) {
          this.bookmarkService.refreshAnimalCardBookmark(createdAnimalId, this.newAgentId, this.newStableId);
          this.appNavigationService.performAnimalCreation(undefined, undefined, createdAnimalId);
        } else {
          this.appNavigationService.performAnimalEditing(createdAnimalId);
        }
      }, error: error => {
        this.creating = false;
        FormHelper.setSingleFormGroupServerSideValidationErrors(error, this.model, this.model.form);
      }
    });
  }
}
