<div class="itech-common-wrap no-pager">
  <div>
    <div class="itech-content-header itech-no-margin-bottom">
      <clr-icon shape="bolt" size="36"></clr-icon>&nbsp;Журнал регламентных операций
    </div>

    <div style="padding: 5px; height: 90%">
      <app-grid (gridReady)="agGridReady($event)" [uniqueGridName]="'GroupOperationsBrowse'"
                [rowData]="_searchResults" [columnDefs]="gridColumnDefs" [getRowNodeId]="rowIdFunc"
                (rowClicked)="appNavigationService.navigateGroupOperation($event.data)"></app-grid>
    </div>

    <div class="itech-control-desc itech-desc-error">
      <clr-icon shape="exclamation-circle"></clr-icon>
      Показаны операции за последние 2 месяца
    </div>
  </div>

  <div class="itech-form-actions">
    <div>
      <button class="btn btn-link btn-primary" (click)="refreshResults()">
        <clr-icon shape="refresh" class="has-badge"></clr-icon>&nbsp;Обновить список
      </button>
    </div>
  </div>
</div>
