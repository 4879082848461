<div class="itech-common-wrap no-pager">
  <div>
    <div style="padding: 5px; height: 100%">
      <app-grid [pagination]="true" [rowData]="_searchResults" [paginationAutoPageSize]="true"
                (rowClicked)="appNavigationService.performInstitutionEditing($event.data.id)"
                [columnDefs]="gridColumnDefs" [uniqueGridName]="'InstitutionSearchResultsGrid'"
                [exportFileName]="'Список учреждений'" [frameworkComponents]="frameworkComponents"></app-grid>
    </div>
  </div>

  <div class="itech-form-actions">
    <button class="btn btn-link btn-primary" (click)="refreshResults()">
      <clr-icon shape="refresh" class="has-badge"></clr-icon>&nbsp;Обновить результаты поиска
    </button>
    <button class="btn btn-link btn-primary" (click)="appNavigationService.performInstitutionCreation()">
      <clr-icon shape="bank" class="has-badge"></clr-icon>&nbsp;Зарегистрировать новое учреждение
    </button>
  </div>
</div>
