import * as tslib_1 from "tslib";
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { MetadataService } from '../../../logic/services/metadata.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MetaBrowseBaseComponent } from '../meta-browse.base.component';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { JobRunnerUiService } from '../../../ui/infrastructure/job-runner-ui.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
var EventForcedReasonBrowseComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EventForcedReasonBrowseComponent, _super);
    function EventForcedReasonBrowseComponent(route, metadataService, alertService, globalWaitingOverlayService, lookupService, bookmarkService, fb, jobRunnerUiService, dataCachingService) {
        var _this = _super.call(this, route, metadataService, alertService, globalWaitingOverlayService, lookupService, fb, bookmarkService, dataCachingService) || this;
        _this.jobRunnerUiService = jobRunnerUiService;
        _this.gridColumnDefs = [
            { headerName: '№', field: 'id', width: 100 },
            { headerName: 'Наименование', field: 'caption', tooltipField: 'caption', width: 350 },
        ];
        return _this;
    }
    EventForcedReasonBrowseComponent.prototype.getMetaTitle = function () {
        return 'Причины вынужденности';
    };
    EventForcedReasonBrowseComponent.prototype.getMetaName = function () {
        return 'event-forced-reason';
    };
    EventForcedReasonBrowseComponent.prototype.getGroupDef = function (row) {
        return {
            id: [row.id, Validators.compose([Validators.required, Validators.pattern(/^\d{1,5}$/)])],
            caption: [row.caption, Validators.required],
        };
    };
    EventForcedReasonBrowseComponent.prototype.getRowStyle = function (params) {
        if (params.data && params.data.dateDeleted) {
            return { color: 'silver' };
        }
    };
    return EventForcedReasonBrowseComponent;
}(MetaBrowseBaseComponent));
export { EventForcedReasonBrowseComponent };
