<clr-tabs>
  <clr-tab>
    <button clrTabLink>Общее</button>
    <ng-template [(clrIfActive)]="model.commonTabActive">
      <clr-tab-content>
        <div class="edit-page-tab">
          <app-edit-institution-common [id]="id"></app-edit-institution-common>
        </div>
      </clr-tab-content>
    </ng-template>
  </clr-tab>
  <clr-tab>
    <button clrTabLink><clr-icon shape="organization"></clr-icon>Пункты, участки, лаборатории</button>
    <ng-template [(clrIfActive)]="model.branchesTabActive">
      <clr-tab-content>
        <div class="edit-page-tab">
          <app-edit-institution-branches [id]="id"></app-edit-institution-branches>
        </div>
      </clr-tab-content>
    </ng-template>
  </clr-tab>
  <clr-tab>
    <button clrTabLink><clr-icon shape="line-chart" style="color: green"></clr-icon>Показатели</button>
    <ng-template [(clrIfActive)]="model.indicatorsTabActive">
      <clr-tab-content>
        <div class="edit-page-tab">
          <app-edit-institution-indicators [id]="id"></app-edit-institution-indicators>
        </div>
      </clr-tab-content>
    </ng-template>
  </clr-tab>
  <clr-tab>
    <button clrTabLink><img src="/assets/images/32x32/users_3.png"/>Работники</button>
    <ng-template [(clrIfActive)]="model.employeesTabActive">
      <clr-tab-content>
        <div class="edit-page-tab">
          <app-edit-institution-employees [id]="id"></app-edit-institution-employees>
        </div>
      </clr-tab-content>
    </ng-template>
  </clr-tab>
  <clr-tab>
    <button clrTabLink><clr-icon shape="e-check" class="is-solid" style="color:green"></clr-icon>Талоны приёма</button>
    <ng-template [(clrIfActive)]="model.ticketsTabActive">
      <clr-tab-content>
        <div class="edit-page-tab">
          <app-tickets-planning [fixedInstitutionId]="id"></app-tickets-planning>
        </div>
      </clr-tab-content>
    </ng-template>
  </clr-tab>
  <clr-tab>
    <button clrTabLink><clr-icon shape="chat-bubble"></clr-icon>Сообщения</button>
    <ng-template [(clrIfActive)]="model.messagesTabActive">
      <clr-tab-content>
        <div class="edit-page-tab">
          <app-edit-institution-agent-messages [id]="id"></app-edit-institution-agent-messages>
        </div>
      </clr-tab-content>
    </ng-template>
  </clr-tab>
</clr-tabs>
