import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/internal/operators';
import { DateHelper } from '../../helpers/date-helper';
import { StringHelper } from '../../helpers/string-helper';

@Injectable({
  providedIn: 'root',
})
export class AnimalDataService {

  constructor(private http: HttpClient) {
  }

  public searchByAgent(agentId: number, showHistory: boolean): Observable<any[]> {
    showHistory = !!showHistory;
    const qstring = environment.api + '/animal/search-by-agent/' + agentId + '/' + showHistory;
    return this.http.get(qstring, {headers: {'interceptNoAccess': 'true'}})
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  public searchByStable(stableId: number, showHistory: boolean): Observable<any[]> {
    showHistory = !!showHistory;
    const qstring = environment.api + '/animal/search-by-stable/' + stableId + '/' + showHistory;
    return this.http.get(qstring, {headers: {'interceptNoAccess': 'true'}})
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  public search(params: any): Observable<any[]> {
    const qstring = environment.api + '/animal/search';
    return this.http.post(qstring, params)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  public viewSlaughtery(params: any): Observable<any[]> {
    const qstring = environment.api + `/animal/events/report-slaughtery/${params.institutionId || '0'}/${params.date}`;
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  getAnimalCommonForEdit(id: number): Observable<any> {
    const qstring = environment.api + '/animal/' + id.toString(10);
    return this.http.get(qstring, {headers: {'interceptNoAccess': 'true'}});
  }

  getTempAnimalCommonForEdit(id: number): Observable<any> {
    const qstring = environment.api + '/animal/external/' + id.toString(10);
    return this.http.get(qstring, {headers: {'interceptNoAccess': 'true'}});
  }

  getAnimalEventForEdit(id: number): Observable<any> {
    const qstring = environment.api + '/animal/event/' + id.toString(10);
    return this.http.get(qstring, {headers: {'interceptNoAccess': 'true'}});
  }

  getAnimalCommonForLookup(id: number): Observable<any> {
    const qstring = environment.api + '/animal/lookup/' + id.toString(10);
    return this.http.get(qstring);
  }

  getAnimalCommonForLookup2(ids: number[]): Observable<any[]> {
    const qstring = environment.api + '/animal/lookup';
    return this.http.post(qstring, ids)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  getAnimalExternalForLookup(id: number): Observable<any> {
    const qstring = environment.api + '/animal/external/lookup/' + id.toString(10);
    return this.http.get(qstring);
  }

  getAnimalEventCommonForLookup(id: number): Observable<any> {
    const qstring = environment.api + '/animal/event/lookup/' + id.toString(10);
    return this.http.get(qstring);
  }

  storeAnimalCommonInfo(model: any) {
    const qstring = environment.api + '/animal/store-common';
    return this.http.post(qstring, model)
      .pipe(map((response: any, error: any) => {
        return response.data.id as number;
      }));
  }

  storeAnimalEvent(model: any) {
    const qstring = environment.api + '/animal/store-event';
    return this.http.post(qstring, model)
      .pipe(map((response: any, error: any) => {
        return response.data as number;
      }));
  }

  createAnimalEvents(model: any) {
    const qstring = environment.api + '/animal/create-events';
    return this.http.post(qstring, model)
      .pipe(map((response: any, error: any) => {
        return response.data as any;
      }));
  }

  createAnimalEventsLazy(model: any) {
    const qstring = environment.api + '/animal/create-events/lazy';
    return this.http.post(qstring, model)
      .pipe(map((response: any, error: any) => {
        return response.data as any;
      }));
  }

  deleteAnimalEvent(id: number) {
    const qstring = environment.api + '/animal/delete-event/' + id;
    return this.http.post(qstring, undefined);
  }

  deleteAnimal(id: number) {
    const qstring = environment.api + '/animal/delete/' + id;
    return this.http.post(qstring, undefined);
  }

  readAnimalEvents(animalId: number): Observable<any[]> {
    const qstring = environment.api + '/animal/animal-events/' + animalId;
    return this.http.get(qstring, {headers: {'interceptNoAccess': 'true'}})
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  getAnimalCountsOnDate(animalId: number, date: Date, cutoffEventId: number): Observable<any[]> {
    const qstring = environment.api + '/animal/events/counts-on-date/' + animalId.toString(10) + '/' +
      StringHelper.getISODate(date) + (cutoffEventId ? '/' + cutoffEventId.toString(10) : '');
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  getAllAnimalEventPlans() {
    const qstring = environment.api + '/animal-event-plan/all';
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  getAnimalEventPlanValues(year: any, regionId: any) {
    const qstring = environment.api + '/animal-event-plan/values/' + year + '/' + regionId;
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response.data as any[];
      }));
  }

  getAnimalEventPlanForEdit(year: any) {
    const qstring = environment.api + '/animal-event-plan/' + year;
    return this.http.get(qstring);
  }

  insertAnimalEventPlan(model: any) {
    const qstring = environment.api + '/animal-event-plan/insert-common';
    return this.http.post(qstring, model)
      .pipe(map((response: any, error: any) => {
        return response.data.id as number;
      }));
  }

  updateAnimalEventPlan(model: any) {
    const qstring = environment.api + '/animal-event-plan/update-common';
    return this.http.post(qstring, model)
      .pipe(map((response: any, error: any) => {
        return response.data.id as number;
      }));
  }

  updateAnimalEventPlanValues(year: any, regionId: any, model: any) {
    const qstring = environment.api + '/animal-event-plan/update-values/' + year + '/' + regionId;
    return this.http.post(qstring, model);
  }

  confirmAnimalExternal(animalExternalId: number) {
    const qstring = environment.api + '/animal/external/confirm/' + animalExternalId.toString(10);
    return this.http.get(qstring)
      .pipe(map((response: any, error: any) => {
        return response.data as any;
      }));
  }

  confirmManyAnimalExternal(data: any[]) {
    const qstring = `${environment.api}/animal/external/confirm`;
    return this.http.post(qstring, data)
      .pipe(map((response: any, error: any) => {
        return response.data as any[];
      }));
  }

  rejectAnimalExternal(animalExternalId: number) {
    const qstring = environment.api + '/animal/external/reject/' + animalExternalId.toString(10);
    return this.http.get(qstring)
      .pipe(map((response: any, error: any) => {
        return response.data as any;
      }));
  }
}
