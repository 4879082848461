import { DoCheck, OnChanges, SimpleChanges } from '@angular/core';
import { PstReportService } from '../../services/pst-report.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';
import { PstReportStructureService } from '../../services/pst-report-structure.service';
import { IndicatorDependencyModalComponent } from '../indicator-dependency-modal.component';
import { noop } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
var PstBranchTabRowEditComponent = /** @class */ (function () {
    function PstBranchTabRowEditComponent(lookupSourceService, navigationService, pstReportService, cacheService, pstReportStructureService, appNavigationService, domSanitizer) {
        this.lookupSourceService = lookupSourceService;
        this.navigationService = navigationService;
        this.pstReportService = pstReportService;
        this.cacheService = cacheService;
        this.pstReportStructureService = pstReportStructureService;
        this.appNavigationService = appNavigationService;
        this.domSanitizer = domSanitizer;
    }
    PstBranchTabRowEditComponent.prototype.ngOnChanges = function (changes) {
        if (changes.hasOwnProperty('branch') || changes.hasOwnProperty('model')) {
            this.rebuildHeader();
            this.startRebuildingRows();
        }
    };
    PstBranchTabRowEditComponent.prototype.ngDoCheck = function () {
        this.headerDivHeight = this.headerDivElement && this.headerDivElement.nativeElement
            ? this.headerDivElement.nativeElement.offsetHeight || 0
            : 0;
        this.theadHeight = this.theadElement && this.theadElement.nativeElement ?
            this.theadElement.nativeElement.offsetHeight || 0
            : 0;
    };
    PstBranchTabRowEditComponent.prototype.rebuildHeader = function () {
        this.branch.headerRows = [];
        if (!this.branch || !this.branch.indicators) {
            return;
        }
        var depth = 1;
        this.branch.indicators.forEach(function (indicator) {
            // в табличном представлении структуру колонок всегда пишем по строке с номером 1
            // (там в каждой строке свои показатели со своим описанием - для универсальности структуры - мы берем только
            // первую, чтобы определить заголовки)
            if (indicator.meta.rowNo !== 1) {
                return;
            }
            var path = (indicator.displayTitle || '').split('->');
            if (path.length > depth) {
                depth = path.length;
            }
        });
        var _loop_1 = function (lvl) {
            var row = [];
            this_1.branch.indicators.forEach(function (indicator, index) {
                // в табличном представлении структуру колонок всегда пишем по строке с номером 1
                // (там в каждой строке свои показатели со своим описанием - для универсальности структуры - мы берем только
                // первую, чтобы определить заголовки)
                if (indicator.meta.rowNo !== 1) {
                    return;
                }
                var path = (indicator.displayTitle || '').split('|')[0].split('->');
                var ix_spacer = -1;
                for (var i = 1; i < path.length; i++) {
                    if (!path[i]) {
                        ix_spacer = i;
                        path[i] = path[i - 1];
                    }
                }
                if (ix_spacer >= 0) {
                    while (path.length < depth) {
                        path.splice(ix_spacer, 0, path[ix_spacer]);
                    }
                }
                var displayTitlePath = path.slice(0, lvl < path.length ? lvl + 1 : path.length).join('->');
                row[indicator.meta.colNo - 1] = {
                    indicator: indicator,
                    displayTitle: lvl < path.length ? path[lvl] : path[path.length - 1],
                    displayTitlePath: displayTitlePath,
                    colSpan: 1,
                    rowSpan: 1,
                };
            });
            this_1.branch.headerRows.push(row);
        };
        var this_1 = this;
        for (var lvl = 0; lvl < depth; lvl++) {
            _loop_1(lvl);
        }
        this.pstReportStructureService.mergeHeaderCells(this.branch);
    };
    PstBranchTabRowEditComponent.prototype.startRebuildingRows = function () {
        var _this = this;
        this.lookupSourceService.getLookupObj('addr-region').subscribe(function (regionLookup) {
            _this.branch.rootRowsGroup = {
                hash: {},
                children: []
            };
            var useAgentId = _this.branch.meta.params['use-agent-id'] ? +_this.branch.meta.params['use-agent-id'] : undefined;
            var useKfhType = useAgentId ? 1 : undefined;
            _this.branch.indicators.forEach(function (indicator, index) {
                if (!_this.branch.rootRowsGroup.children[indicator.meta.rowNo - 1]) {
                    var indicatorLevel = _this.branch.meta.indicatorLevel;
                    if (!_this.model.reportMeta.regionId && _this.branch.meta.subjectIndicatorLevel) {
                        indicatorLevel = _this.branch.meta.subjectIndicatorLevel;
                    }
                    // для 60-го уровня, клонируемая строка имеет 50-й уровень, чтобы показывать итоги по добавленным подвидам
                    if (indicatorLevel === 60 && indicator.meta.params['clone-subtype-cells']) {
                        indicatorLevel = 50;
                    }
                    var useSubtypeId = indicator.meta.params['use-subtype-id'] ? +indicator.meta.params['use-subtype-id'] : undefined;
                    var indicatorsRow = {
                        indicators: [],
                        regionGroupId: _this.model.reportMeta.regionId
                            ? regionLookup["Obj" + _this.model.reportMeta.regionId].regionGroupId
                            : undefined,
                        regionId: _this.model.reportMeta.regionId,
                        level: indicatorLevel,
                        kfhType: indicatorLevel >= 50 ? useKfhType : undefined,
                        agentId: indicatorLevel >= 50 ? useAgentId : undefined,
                        subtypeId: useSubtypeId,
                    };
                    _this.branch.rootRowsGroup.children[indicator.meta.rowNo - 1] = indicatorsRow;
                }
                var displayTitleSplit = (indicator.displayTitle || '').split('|');
                var row = _this.branch.rootRowsGroup.children[indicator.meta.rowNo - 1];
                row.indicators[indicator.meta.colNo - 1] = {
                    reportIndicator: indicator,
                    meta: indicator.indicatorMeta,
                    cellFixedStr: displayTitleSplit.length > 1 ? displayTitleSplit[1] : undefined,
                    colSpan: displayTitleSplit.length > 2 && displayTitleSplit[2] ? +displayTitleSplit[2] : undefined,
                };
            });
        });
    };
    PstBranchTabRowEditComponent.prototype.cellClick = function (indicatorLevel, colIndex, reportIndicator) {
        if (this.getFocused(indicatorLevel, colIndex)) {
            this.setEditingCell(indicatorLevel, colIndex, indicatorLevel.indicators[colIndex].meta, '');
        }
        else {
            this.setFocusedCell(indicatorLevel, colIndex);
        }
        // console.log('reportIndicator:', reportIndicator);
        // console.log('indicatorTypeMeta:', indicatorLevel.indicators[colIndex].meta);
        // console.log('indicatorLevel:', indicatorLevel);
    };
    PstBranchTabRowEditComponent.prototype.setFocusedCell = function (indicatorLevel, colIndex) {
        this.model.__cl_focusedBranch = this.branch;
        this.model.__cl_focusedIndicatorLevel = indicatorLevel;
        this.model.__cl_focusedIndicatorColIndex = colIndex;
        this.model.__cl_editingBranch = undefined;
        this.model.__cl_storing_error = undefined;
    };
    PstBranchTabRowEditComponent.prototype.setEditingCell = function (indicatorLevel, colIndex, indicatorTypeMeta, proposedValue) {
        if (!indicatorTypeMeta || indicatorTypeMeta.formula || indicatorLevel.level !== (indicatorTypeMeta.editableLevel || 60)) {
            return;
        }
        this.setFocusedCell(indicatorLevel, colIndex);
        var reportIndicator = indicatorLevel.indicators[colIndex].reportIndicator;
        this.setProposedCellValueStr(indicatorLevel, reportIndicator, indicatorTypeMeta, proposedValue);
        this.model.__cl_editingBranch = this.branch;
        this.model.__cl_editingIndicatorLevel = indicatorLevel;
        this.model.__cl_editingIndicatorColIndex = colIndex;
    };
    PstBranchTabRowEditComponent.prototype.tableKeyDown = function ($event) {
        if (this.model.__cl_focusedBranch === this.branch) {
            var indicatorLevel_1 = this.model.__cl_focusedIndicatorLevel;
            var colIndex = this.model.__cl_focusedIndicatorColIndex;
            var indicatorTypeMeta = indicatorLevel_1.indicators[colIndex].meta;
            var reportIndicator = indicatorLevel_1.indicators[colIndex].reportIndicator;
            if (!this.getEditing(indicatorLevel_1, colIndex)) {
                if ($event.key >= '0' && $event.key <= '9') {
                    this.setEditingCell(indicatorLevel_1, colIndex, indicatorTypeMeta, $event.key);
                }
                if ($event.key === 'F2') {
                    this.setEditingCell(indicatorLevel_1, colIndex, indicatorTypeMeta, '');
                }
                if ($event.key === 'F3') {
                    var code = this.pstReportStructureService.getIndicatorValueCode(this.branch, indicatorLevel_1, reportIndicator, indicatorTypeMeta);
                    var rec = this.model.values[code];
                    if (rec) {
                        $event.preventDefault();
                        this.appNavigationService.showIndicatorDependencies(IndicatorDependencyModalComponent, rec, this.model.reportMeta.id)
                            .subscribe(noop);
                    }
                }
                if ($event.key === 'ArrowLeft') {
                    this.setFocusedCell(indicatorLevel_1, colIndex - 1 >= 0 ? colIndex - 1 : 0);
                    $event.preventDefault();
                }
                if ($event.key === 'ArrowRight') {
                    this.setFocusedCell(indicatorLevel_1, colIndex + 1 < indicatorLevel_1.indicators.length
                        ? colIndex + 1
                        : indicatorLevel_1.indicators.length - 1);
                    $event.preventDefault();
                }
                if ($event.key === 'ArrowUp') {
                    var ixSelectedLevel = this.branch.rootRowsGroup.children.findIndex(function (el) { return el === indicatorLevel_1; });
                    if (ixSelectedLevel > 0) {
                        this.setFocusedCell(this.branch.rootRowsGroup.children[ixSelectedLevel - 1], colIndex);
                        $event.preventDefault();
                    }
                }
                if ($event.key === 'ArrowDown') {
                    var ixSelectedLevel = this.branch.rootRowsGroup.children.findIndex(function (el) { return el === indicatorLevel_1; });
                    if (ixSelectedLevel + 1 < this.branch.rootRowsGroup.children.length) {
                        this.setFocusedCell(this.branch.rootRowsGroup.children[ixSelectedLevel + 1], colIndex);
                        $event.preventDefault();
                    }
                }
                if ($event.key === 'Delete' && !indicatorTypeMeta.formula) {
                    this.pstReportStructureService.storeIndicatorValue(this.model, reportIndicator, indicatorTypeMeta, indicatorLevel_1, undefined);
                }
            }
        }
    };
    PstBranchTabRowEditComponent.prototype.getFocused = function (indicatorLevel, colIndex) {
        return this.model.__cl_focusedBranch === this.branch &&
            this.model.__cl_focusedIndicatorLevel === indicatorLevel &&
            this.model.__cl_focusedIndicatorColIndex === colIndex;
    };
    PstBranchTabRowEditComponent.prototype.getEditing = function (indicatorLevel, colIndex) {
        return this.model.__cl_editingBranch === this.branch &&
            this.model.__cl_editingIndicatorLevel === indicatorLevel &&
            this.model.__cl_editingIndicatorColIndex === colIndex;
    };
    PstBranchTabRowEditComponent.prototype.setProposedCellValueStr = function (indicatorLevel, reportIndicator, indicatorTypeMeta, strValue) {
        if (!reportIndicator || !indicatorTypeMeta || !indicatorLevel) {
            return;
        }
        var code = this.pstReportStructureService.getIndicatorValueCode(this.branch, indicatorLevel, reportIndicator, indicatorTypeMeta);
        if (!this.model.values[code]) {
            this.model.values[code] = {
                date: reportIndicator.indicatorDate,
                indicatorTypeId: indicatorTypeMeta.id,
                regionGroupId: indicatorLevel.regionGroupId,
                regionId: indicatorLevel.regionId,
                kfhType: indicatorLevel.kfhType,
                agentId: indicatorLevel.agentId,
            };
        }
        if (strValue) {
            var parsedVal = parseFloat(strValue);
            var precision = indicatorTypeMeta.precision;
            if (precision === 0) {
                parsedVal = Math.floor(strValue);
            }
            else {
                parsedVal = Math.floor(strValue * Math.pow(10, precision)) / Math.pow(10, precision);
            }
            this.model.values[code].proposedValue = parsedVal;
        }
        else {
            this.model.values[code].proposedValue = undefined;
        }
    };
    PstBranchTabRowEditComponent.prototype.getCellReadonly = function (row, ii) {
        return (row.indicators && row.indicators[ii].meta.formula) || row.indicators[ii].cellFixedStr
            || (row.indicators[ii].reportIndicator && row.indicators[ii].reportIndicator.meta.params
                && row.indicators[ii].reportIndicator.meta.params.excluded === 'true')
            || (row.level !== row.indicators[ii].meta.editableLevel);
    };
    PstBranchTabRowEditComponent.prototype.getWrapperHeight = function () {
        if (this.branch.meta.params['tableHeight']) {
            return this.branch.meta.params['tableHeight'];
        }
        if (this.model.tabPageHeight) {
            return this.model.tabPageHeight - 20 - this.headerDivHeight + "px";
        }
        return 'calc(100% - 25px)';
    };
    PstBranchTabRowEditComponent.prototype.getCellStyle = function (indicatorLevel, indicator, rowGroup, ii) {
        var style = '';
        if (indicator.reportIndicator.meta.params['custom-style']) {
            style = style + ';' + indicator.reportIndicator.meta.params['custom-style'];
        }
        return this.domSanitizer.bypassSecurityTrustStyle(style);
    };
    PstBranchTabRowEditComponent.prototype.getAvailableSubtypes$ = function (rowIndicator) {
        return this.lookupSourceService.getLookup('pst-indicator-subtype').pipe(map(function (indicatorSubtypes) {
            var lvlGroupCaption = rowIndicator.meta.lvlGroupCaption;
            var filtered = indicatorSubtypes.filter(function (subtype) { return subtype.groupCaption === lvlGroupCaption; });
            filtered.sort(function (a, b) { return (a.caption || '').localeCompare(b.caption || ''); });
            return filtered;
        }));
    };
    PstBranchTabRowEditComponent.prototype.cloneSubtypeRow = function (row, i_row, subtype) {
        var cloneRow = JSON.parse(JSON.stringify(row));
        this.branch.rootRowsGroup.children.splice(i_row + 1, 0, cloneRow);
        cloneRow.indicators[0].cellFixedStr = subtype.caption;
        cloneRow.indicators[0].reportIndicator.meta.params['custom-style'] =
            (cloneRow.indicators[0].reportIndicator.meta.params['custom-style'] || '') + ';padding-left: 40px';
        cloneRow.indicators.forEach(function (rowIndicator) {
            delete rowIndicator.reportIndicator.meta.params['add-subtype-button'];
            rowIndicator.reportIndicator.meta.params['use-subtype-id'] = subtype.id;
        });
        cloneRow.subtypeId = subtype.id;
        cloneRow.level = 60;
    };
    PstBranchTabRowEditComponent.prototype.showAddSubtypeBtn = function (rowIndicator) {
        return rowIndicator.reportIndicator.meta.params['add-subtype-button'] === 'region'
            && this.model.reportMeta.regionId;
    };
    return PstBranchTabRowEditComponent;
}());
export { PstBranchTabRowEditComponent };
