import { Component, OnInit } from '@angular/core';
import { SecurityService } from '../../logic/services/security.service';
import { DataCachingService } from '../../logic/services/data-caching.service';
import { LookupSourceService } from '../../logic/services/lookup-source.service';
import { MetadataService } from '../../logic/services/metadata.service';
import { AppNavigationService } from '../../logic/services/app-navigation.service';
import { QuarantineSearchResultsComponent } from './search/quarantine-search-results.component';
import { FormBuilder } from '@angular/forms';
import { AppModalPreviewFileComponent } from '../../ui/controls/app-modal-preview-file.component';
import { DateHelper } from '../../helpers/date-helper';
import { StringHelper } from '../../helpers/string-helper';

@Component({
  selector: 'app-quarantine-block',
  templateUrl: './quarantine-block.component.html'
})
export class QuarantineBlockComponent implements OnInit {

  model = QuarantineBlockComponent.createDefaultModel();
  diseases = [];

  static createDefaultModel(tabActive?: string) {
    return {
      orderTabActive: tabActive ? tabActive === 'orderTabActive' : true,
      searchTabActive: tabActive ? tabActive === 'searchTabActive' : false,
      reportsTabActive: tabActive ? tabActive === 'reportsTabActive' : false,
    };
  }

  constructor (public securityService: SecurityService,
               private dataCachingService: DataCachingService,
               private lookupService: LookupSourceService,
               private metadataService: MetadataService,
               private navigationService: AppNavigationService,
               private fb: FormBuilder) {
  }

  ngOnInit() {
    const existing = this.dataCachingService.getCachedData('QuarantineBlockModel', '1');
    if (existing) {
      this.model = existing;
    } else {
      this.model = QuarantineBlockComponent.createDefaultModel();
      this.dataCachingService.addToCache('QuarantineBlockModel', '1', this.model);
    }
    this.refreshDataInternal();
  }

  refreshDataInternal() {
    this.lookupService.getLookup('disease-type/quarantine-diseases')
      .subscribe(dis => {
        dis.sort((a, b) => (a.code || '').localeCompare((b.code || '')));
        this.diseases = dis;
      });
  }

  changeTab() {
    this.dataCachingService.addToCache('QuarantineBlockModel', '1', this.model);
  }

  previewFile(file: any) {
    return this.navigationService.previewFile( AppModalPreviewFileComponent, { fileUri: file });
  }

  createQuarantine(diseaseTypeId: number) {
    this.navigationService.performQuarantineCreation(diseaseTypeId);
  }

  navigateToSearch(diseaseTypeId: number, durationType?: number) {
    const filterParams = QuarantineSearchResultsComponent.getDefaultModel(this.fb, {
      diseaseTypeId: diseaseTypeId,
      dateFrom: durationType === 1 ? StringHelper.getISODate(DateHelper.addMonths(new Date(), -12)) : undefined,
      currentStatusId: durationType === 2 ? 3 : undefined,
      forceStartSearch: true,
    });
    this.dataCachingService.addToCache('QuarantineSearchResults', '1', filterParams);

    this.model = QuarantineBlockComponent.createDefaultModel('searchTabActive');
    this.dataCachingService.addToCache('QuarantineBlockModel', '1', this.model);
  }
}
