import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/internal/operators';

@Injectable({
  providedIn: 'root',
})
export class AgentDataService {

  constructor(private http: HttpClient) {
  }

  public search(searchParams: any): Observable<any[]> {
    const qstring = environment.api + '/agent/search';
    return this.http.post(qstring, searchParams)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  getAgentCommonForEdit(id: number): Observable<any> {
    const qstring = environment.api + '/agent/' + id.toString(10);
    return this.http.get(qstring, {headers: {'interceptNoAccess': 'true'}})
      .pipe(map((item: any) => {
        return item.data;
      }));
  }

  getAgentCommonForEditWithSync(id: number): Observable<any> {
    const qstring = environment.api + '/agent/resync-with-mercury/' + id.toString(10);
    return this.http.get(qstring, {headers: {'interceptNoAccess': 'true'}})
      .pipe(map((item: any) => {
        return item.data;
      }));
  }

  createByMercuryGuid(mercuryUuid: string): Observable<any> {
    const qstring = environment.api + '/agent/create-by-mercury-guid/' + mercuryUuid;
    return this.http.post(qstring, undefined).pipe(map((item: any) => {
      return item.data;
    }));
  }

  getAgentCommonForLookup(id: number): Observable<any> {
    const qstring = environment.api + '/agent/lookup/' + id.toString(10);
    return this.http.get(qstring);
  }

  getAgentCommonForLookup2(ids: number[]): Observable<any[]> {
    const qstring = environment.api + '/agent/lookup';
    return this.http.post(qstring, ids)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  getAgentBranchesForEdit(id: number): Observable<any[]> {
    const qstring = environment.api + '/agent/branches/' + id.toString(10);
    return this.http.get(qstring).pipe(map(item => {
      return item as any[];
    }));
  }

  getAgentEmployeesForEdit(id: number): Observable<any[]> {
    const qstring = environment.api + '/agent/employees/' + id.toString(10);
    return this.http.get(qstring).pipe(map(item => {
      return item as any[];
    }));
  }

  getAgentBranchesForLookup(id: number): Observable<any[]> {
    const qstring = environment.api + '/agent/branches/lookup/' + id.toString(10);
    return this.http.get(qstring).pipe(map(item => {
      return item as any[];
    }));
  }

  getAgentEmployeesForLookup(id: number): Observable<any[]> {
    const qstring = environment.api + '/agent/employees/lookup/' + id.toString(10);
    return this.http.get(qstring).pipe(map(item => {
      return item as any[];
    }));
  }

  storeAgentCommonInfo(model: any) {
    const qstring = environment.api + '/agent/store-common';
    return this.http.post(qstring, model)
      .pipe(map((response: any, error: any) => {
        return response.data;
      }));
  }

  storeAgentBranches(AgentId: any, branches: any[], deleted: any[]) {
    const model = {
      AgentId: AgentId,
      branches: branches,
      delete: deleted
    };

    const qstring = environment.api + '/agent/store-branches';
    return this.http.post(qstring, model)
      .pipe(map((response: any, error: any) => {
        return true;
      }));
  }

  storeAgentEmployees(AgentId: any, employees: any[], deleted: any[]) {
    const model = {
      AgentId: AgentId,
      employees: employees,
      delete: deleted
    };

    const qstring = environment.api + '/agent/store-employees';
    return this.http.post(qstring, model)
      .pipe(map((response: any, error: any) => {
        return true;
      }));
  }

  deleteAgent(id: number) {
    const qstring = environment.api + '/agent/delete/' + id.toString(10);
    return this.http.post(qstring, {});
  }

  restoreDeletedAgent(id: number) {
    const qstring = environment.api + '/agent/restore-deleted/' + id.toString(10);
    return this.http.post(qstring, {});
  }

  removePersonalAccountAgent(id: number) {
    const qstring = environment.api + '/agent/remove-personal-account/' + id.toString(10);
    return this.http.post(qstring, {});
  }

  mergeAgent(mainAgentId: number, sourceAgentId: number) {
    const qstring = environment.api + '/agent/merge/' + mainAgentId + '/' + sourceAgentId;
    return this.http.get(qstring);
  }

  grantAccessOpenApi(agentId: number) {
    const qstring = environment.api + '/agent/grant-access-open-api/' + agentId;
    return this.http.get(qstring);
  }

  revokeAccessOpenApi(agentId: number) {
    const qstring = environment.api + '/agent/revoke-access-open-api/' + agentId;
    return this.http.get(qstring);
  }
}
