import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AgentSearchModalComponent } from '../../edit-agent/search/agent-search-modal.component';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { StableSearchModalComponent } from '../../edit-stable/search/stable-search-modal.component';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';
var QueryStableAgentFilterComponent = /** @class */ (function () {
    function QueryStableAgentFilterComponent(cacheService, navigationService) {
        this.cacheService = cacheService;
        this.navigationService = navigationService;
        this.class = 'form-group';
    }
    QueryStableAgentFilterComponent.prototype.isInvalid = function (cname) {
        return FormHelper.isInvalid(this.contextFormGroup, cname);
    };
    Object.defineProperty(QueryStableAgentFilterComponent.prototype, "customFilterChecked", {
        get: function () {
            if (this.parameterName.includes('agent_id')) {
                return this.contextFormGroup && (+this.contextFormGroup.get('_par_' + this.parameterName + '_filter').value) === 101;
            }
            else if (this.parameterName.includes('stable_id')) {
                return this.contextFormGroup && (+this.contextFormGroup.get('_par_' + this.parameterName + '_filter').value) === 102;
            }
        },
        set: function (val) {
            if (this.contextFormGroup) {
                this.parameterName.includes('agent_id')
                    ? this.contextFormGroup.get('_par_' + this.parameterName + '_filter').setValue(val ? 101 : 0)
                    : this.contextFormGroup.get('_par_' + this.parameterName + '_filter').setValue(val ? 102 : 0);
                this.contextFormGroup.markAsDirty();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QueryStableAgentFilterComponent.prototype, "noValueChecked", {
        get: function () {
            return this.contextFormGroup && (+this.contextFormGroup.get('_par_' + this.parameterName + '_filter').value) === 1;
        },
        set: function (val) {
            if (this.contextFormGroup) {
                this.contextFormGroup.get('_par_' + this.parameterName + '_filter').setValue(val ? 1 : 0);
                this.contextFormGroup.markAsDirty();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QueryStableAgentFilterComponent.prototype, "filtered", {
        get: function () {
            return !!this.contextFormGroup.get('_par_' + this.parameterName + '_filter').value
                || this.parameterName.includes('stable_id')
                ? !!this.contextFormGroup.get('_par_' + this.parameterName).value.length
                : !!this.contextFormGroup.get('_par_' + this.parameterName).value;
        },
        enumerable: true,
        configurable: true
    });
    QueryStableAgentFilterComponent.prototype.search = function () {
        var _this = this;
        if (this.parameterName.includes('agent_id')) {
            this.navigationService.searchAgent(AgentSearchModalComponent, {}).subscribe(function (val) {
                if (val) {
                    _this.contextFormGroup.controls['_par_' + _this.parameterName].setValue(val);
                    _this.contextFormGroup.markAsDirty();
                }
            });
        }
        else if (this.parameterName.includes('stable_id')) {
            this.navigationService.searchStable(StableSearchModalComponent, {}).subscribe(function (val) {
                if (val) {
                    _this.contextFormGroup.get('_par_' + _this.parameterName).push(new FormControl(val));
                    _this.contextFormGroup.markAsDirty();
                }
            });
        }
    };
    QueryStableAgentFilterComponent.prototype.clearValue = function (index) {
        if (this.parameterName.includes('agent_id')) {
            this.contextFormGroup.controls['_par_' + this.parameterName].setValue(null);
        }
        else if (this.parameterName.includes('stable_id')) {
            var stableIds = this.contextFormGroup.get('_par_' + this.parameterName);
            stableIds.removeAt(index);
        }
        this.contextFormGroup.markAsDirty();
    };
    QueryStableAgentFilterComponent.prototype.getLookupName = function () {
        if (this.parameterName.includes('agent_id')) {
            return 'agent';
        }
        else if (this.parameterName.includes('stable_id')) {
            return 'stable';
        }
    };
    return QueryStableAgentFilterComponent;
}());
export { QueryStableAgentFilterComponent };
