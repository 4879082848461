import * as tslib_1 from "tslib";
import { EventEmitter, OnChanges } from '@angular/core';
import { Required } from '../../../ui/infrastructure/app-decotators';
import { FormHelper } from '../../../ui/controls/form-helper';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { FormBuilder } from '@angular/forms';
import { ProductDataService } from '../../../logic/services/product-data.service';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
var ProductSearchResultsWithTabsComponent = /** @class */ (function () {
    function ProductSearchResultsWithTabsComponent(dataCachingService, productDataService, navigationService, fb) {
        this.dataCachingService = dataCachingService;
        this.productDataService = productDataService;
        this.navigationService = navigationService;
        this.fb = fb;
        this.columnDefs = [];
        this.data = [];
        this.templates = [];
        this.exportFileName = 'Продукция';
        this.rowDoubleClicked = new EventEmitter();
        this.selectionChanged = new EventEmitter();
        this.modelUpdated = new EventEmitter();
        this.filteredData = [];
        this.equalsSome = FormHelper.equalsSome;
    }
    ProductSearchResultsWithTabsComponent.prototype.ngOnChanges = function () {
        this.viewType = this.fb.group({
            transactionType: this.dataCachingService.getCachedData(this.uniqueGridName + '_TableWithTabs_transactionType' + this.currentStableId, '1') || '1',
            status: this.dataCachingService.getCachedData(this.uniqueGridName + '_TableWithTabs_status' + this.currentStableId, '1') || '1',
        });
        this.changeViewType();
    };
    ProductSearchResultsWithTabsComponent.prototype.changeViewType = function () {
        this.filteredData = this.filterByStatus(this.filterByTransactionType(this.data));
        this.dataCachingService.addToCache(this.uniqueGridName + '_TableWithTabs_transactionType' + this.currentStableId, '1', this.viewType.get('transactionType').value);
        this.dataCachingService.addToCache(this.uniqueGridName + '_TableWithTabs_status' + this.currentStableId, '1', this.viewType.get('status').value);
    };
    ProductSearchResultsWithTabsComponent.prototype.filterByTransactionType = function (data) {
        var _this = this;
        if (+this.viewType.get('transactionType').value === 1) {
            return data;
        }
        else if (+this.viewType.get('transactionType').value === 2) {
            return data.filter(function (x) { return _this.equalsSome(x.transactionType, 1, 2, 3) && x.originStableId === _this.currentStableId; });
        }
        else if (+this.viewType.get('transactionType').value === 3) {
            return data.filter(function (x) { return _this.equalsSome(x.transactionType, 1, 2, 3) && x.destinationStableId === _this.currentStableId; });
        }
        else if (+this.viewType.get('transactionType').value === 4) {
            return data.filter(function (x) { return _this.equalsSome(x.transactionType, 1, 2, 3); });
        }
        else if (+this.viewType.get('transactionType').value === 5) {
            return data.filter(function (x) { return _this.equalsSome(x.transactionType, 4); });
        }
        else {
            return [];
        }
    };
    ProductSearchResultsWithTabsComponent.prototype.filterByStatus = function (data) {
        var _this = this;
        if (+this.viewType.get('status').value === 1) {
            return data;
        }
        else if (+this.viewType.get('status').value === 2) {
            return data.filter(function (x) { return x.status === 2; });
        }
        else if (+this.viewType.get('status').value === 3) {
            return data.filter(function (x) { return x.status === 4; });
        }
        else if (+this.viewType.get('status').value === 4) {
            return data.filter(function (x) { return _this.equalsSome(x.status, 3, 6); });
        }
        else if (+this.viewType.get('status').value === 5) {
            return data.filter(function (x) { return _this.equalsSome(x.status, 1, 5, 7); });
        }
        else if (+this.viewType.get('status').value === 6) {
            return data.filter(function (x) { return _this.equalsSome(x.status, -100, 0, 1); });
        }
        else if (+this.viewType.get('status').value === 7) {
            return data.filter(function (x) { return x.status === -1; });
        }
        else {
            return [];
        }
    };
    ProductSearchResultsWithTabsComponent.prototype.performToTemplate = function (template) {
        this.navigationService.performProductEditing(template.id);
    };
    tslib_1.__decorate([
        Required,
        tslib_1.__metadata("design:type", Array)
    ], ProductSearchResultsWithTabsComponent.prototype, "columnDefs", void 0);
    tslib_1.__decorate([
        Required,
        tslib_1.__metadata("design:type", Object)
    ], ProductSearchResultsWithTabsComponent.prototype, "uniqueGridName", void 0);
    return ProductSearchResultsWithTabsComponent;
}());
export { ProductSearchResultsWithTabsComponent };
