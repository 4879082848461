<header class="header-6 header itech-header" [class.itech-test-header]="testEnvText">
  <div class="branding itech-header">
    <a [routerLink]="['/']" class="nav-link">
      <clr-icon shape="objects"></clr-icon>
      <span class="title">{{testEnvText}}ИС Ветеринария</span>
    </a>
  </div>
  <div class="header-actions itech-header">
    <div *ngIf="alertService.activeHttpRequests | async" class="nav-text itech-active-requests-header"
         title="Количество запросов, выполняющихся в настоящее время на сервере">
      <img src="/assets/images/progress.gif"> {{alertService.activeHttpRequests | async}}
    </div>
    <clr-dropdown>
      <button class="nav-icon" clrDropdownToggle (click)="openAuthMercuryDropdownEvent.emit()">
        <img *ngIf="(securityService.getUserMercurySession() | async)" style="height:24px"
             src="/assets/images/ico/mercury.png">
        <img *ngIf="!(securityService.getUserMercurySession() | async)" style="height:24px"
             src="/assets/images/ico/mercury-gray.png">
      </button>
      <clr-dropdown-menu style="min-width:440px;" *clrIfOpen clrPosition="bottom-right">
        <h4 class="dropdown-header">Авторизация в Меркурии</h4>
        <clr-dropdown style="max-height:400px;overflow:auto;padding:0 12px;">
          <app-auth-mercury [openAuthMercuryDropdownEvent]="openAuthMercuryDropdownEvent"></app-auth-mercury>
        </clr-dropdown>
      </clr-dropdown-menu>
    </clr-dropdown>

    <clr-dropdown *ngIf="esiaService.loggedIn()">
      <button class="nav-icon" clrDropdownToggle [style.opacity]="isHasNewNotification ? 1 : undefined"
              (click)="isHasNewNotification = false">
        <clr-icon shape="bell" style="border-radius:50%;" [style.color.green]="isHasNewNotification"
                  [class.is-solid]="isHasNewNotification" [class.has-badge]="isHasNewNotification"
                  [class.pulse]="isHasNewNotification"></clr-icon>
        <clr-icon shape="caret down"></clr-icon>
      </button>
      <clr-dropdown-menu style="min-width:565px;" *clrIfOpen clrPosition="bottom-right">
        <h4 class="dropdown-header" #notificationMenu>Уведомления</h4>
        <clr-dropdown style="max-height:520px;overflow:auto">
          <ng-container *ngIf="!(getNotifications$() | async).length; else hasNotifications">
            <div class="form-group itech-group-whole-line notification-row empty-notification-label">
              <span>Нет уведомлений</span>
            </div>
          </ng-container>
          <ng-template #hasNotifications>

            <table class="table" style="margin:0;border:none;max-width:550px">
              <tbody>
                <ng-container *ngFor="let notification of (getNotifications$() | async); index as i">
                  <tr *ngIf="i < maxCountNotification" class="notification-row" (mouseenter)="hoverNotification(notification)">
                    <td style="max-width:200px;vertical-align:middle">
                      <clr-icon *ngIf="notification.badge" class="notification-result-icon" shape="new" style="color:navy"></clr-icon>&nbsp;
                      {{+notification.typeId|lookup:'user-notification-type/by-kind/' + notification.kindId|async}}
                    </td>
                    <td style="max-width:300px;vertical-align:middle">
                      <app-link-button *ngIf="+notification.kindId === 1 && equalsSome(+notification.typeId, 3,4,6,11)"
                                       class="notification-link" clrDropdownItem
                                       [id]="notification.objId1" [lookupName]="'product'" [widthControl]="250"></app-link-button>
                      <app-link-button *ngIf="+notification.kindId === 1 && equalsSome(+notification.typeId, 2,5,9,10)"
                                       class="notification-link" clrDropdownItem
                                       [id]="notification.objId1" [lookupName]="'stable'" [widthControl]="250"></app-link-button>
                      <app-link-button *ngIf="+notification.kindId === 1 && equalsSome(+notification.typeId, 8)"
                                       class="notification-link" clrDropdownItem
                                       [id]="notification.objId1" [lookupName]="'product-expertise'" [widthControl]="250"></app-link-button>
                      <div *ngIf="+notification.kindId === 1 && equalsSome(+notification.typeId, 7)"
                           class="notification-text">
                        Транзакция переработки #{{notification.objId1}}
                      </div>
                      <div *ngIf="+notification.kindId === 2 && equalsSome(+notification.typeId, 1)"
                           class="notification-text">
                        {{notification.comment}}
                      </div>
                    </td>
                    <td style="max-width:50px;vertical-align:middle">
                      <clr-icon *ngIf="+notification.stateId === -2" class="notification-result-icon" size="22" shape="pause" style="color:navy"></clr-icon>
                      <span *ngIf="+notification.stateId === -1" class="spinner spinner-sm" style="border-radius:50%;"></span>
                      <span *ngIf="+notification.stateId === 0" style="color:silver">Новая</span>
                      <clr-icon *ngIf="+notification.stateId === 1" class="notification-result-icon" size="22" shape="check" style="color:green"></clr-icon>
                      <clr-icon *ngIf="+notification.stateId === 2" class="notification-result-icon" size="22" shape="exclamation-circle" style="color:red"></clr-icon>
                      <clr-icon *ngIf="+notification.stateId === 3" class="notification-result-icon" size="22" shape="exclamation-circle" style="color:blue"></clr-icon>
                    </td>
                  </tr>
                </ng-container>
                <ng-container *ngIf="maxCountNotification > 15 || (getNotifications$() | async).length > maxCountNotification">
                  <tr class="notification-row">
                    <td>
                      <a *ngIf="(getNotifications$() | async).length > maxCountNotification"
                         class="itech-link" style="font-size:12px" (click)="maxCountNotification = maxCountNotification + 10">
                        показать ещё
                        <clr-icon shape="caret down"></clr-icon>
                      </a>
                    </td>
                    <td>
                      <a *ngIf="maxCountNotification > 15"
                         class="itech-link" style="font-size:12px" (click)="maxCountNotification = 15">
                        скрыть
                        <clr-icon shape="caret up"></clr-icon>
                      </a>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </ng-template>
        </clr-dropdown>
      </clr-dropdown-menu>
    </clr-dropdown>

    <clr-dropdown *ngIf="esiaService.loggedIn()">
      <button class="nav-icon" clrDropdownToggle style="width:90px;font-size:12px;text-align:end">
        <clr-icon shape="help-info" style="left:0"></clr-icon>Инструкция
      </button>
      <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
        <h4 class="dropdown-header">Инструкция</h4>
        <clr-dropdown>
          <ng-container *ngFor="let file of (lookupSourceService.getLookup('user-manuals/all') | async)">
            <div class="itech-form-group-one-line" style="width:320px;display:flex">
              <a class="itech-link align-bottom" style="padding: 0 10px;color:black;display:inline-block;width:280px;overflow:hidden;font-size:14px"
                 (click)="previewFile(file)">
                {{file}}
              </a>
              <button *ngIf="(securityService.getUserInfo() | async).administrator" type="button"
                      class="btn btn-danger btn-link itech-inline-tool-btn" title="Удалить файл"
                      (click)="deleteFile(file)" style="margin:6px;">
                <clr-icon shape="trash" style="transform: inherit;color: #d23b3b"></clr-icon>
              </button>
            </div>
          </ng-container>
          <ng-container *ngIf="(securityService.getUserInfo() | async).administrator">
            <div class="dropdown-divider"></div>
            <div>
              <label for="uploadUserManual" style="padding: 0 10px;">
                <a class="itech-link itech-control-normal" (click)="fileUploadClick()" id="uploadUserManual"
                  style="font-size:14px;color:black">
                  <input *ngIf="!newFileToUploadFlag" type="file" #newFileToUploadFlag
                         (change)="fileChanged($event)" style="visibility: hidden;width: 0">
                  <clr-icon shape="upload"></clr-icon>&nbsp;Добавить инструкцию
                </a>
              </label>
            </div>
          </ng-container>
        </clr-dropdown>
      </clr-dropdown-menu>
    </clr-dropdown>

    <div class="nav-text itech-active-requests-header"
         title="Версия приложения (щелкните, чтобы посмотреть список изменений)"
         style="cursor: pointer" (click)="appNavigationService.versionHistoryOpened = true">
      {{versionText}}
    </div>

    <clr-dropdown *ngIf="esiaService.loggedIn()">
      <button class="nav-icon" clrDropdownToggle>
        <clr-icon shape="settings"></clr-icon>
        <clr-icon shape="caret down"></clr-icon>
      </button>
      <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
        <h4 class="dropdown-header">Настройки</h4>
        <clr-dropdown>
          <button type="button" clrDropdownTrigger>Справочники</button>
          <clr-dropdown-menu clrPosition="left-top">
            <clr-dropdown>
              <button type="button" clrDropdownTrigger>Адресные справочники</button>
              <clr-dropdown-menu clrPosition="left-top">
                <button type="button" clrDropdownItem
                        (click)="appNavigationService.performGroupOperation('op9')">Загрузка ФИАС
                </button>
                <button type="button" clrDropdownItem (click)="runUpdateAddresses()">Актуализация наименований адресов в
                  БД
                </button>
                <div class="dropdown-divider"></div>
                <button type="button" clrDropdownItem [routerLink]="['/dictionary/addr-city']">Населенные пункты
                </button>
                <button type="button" clrDropdownItem [routerLink]="['/dictionary/addr-city-type']">Типы населенных
                  пунктов
                </button>
                <button type="button" clrDropdownItem [routerLink]="['/dictionary/addr-street']">Улицы</button>
                <button type="button" clrDropdownItem [routerLink]="['/dictionary/addr-street-type']">Типы улиц</button>
              </clr-dropdown-menu>
            </clr-dropdown>

            <clr-dropdown>
              <button type="button" clrDropdownTrigger>Животные</button>
              <clr-dropdown-menu clrPosition="left-top">
                <button type="button" clrDropdownItem [routerLink]="['/dictionary/animal-breed']">Породы животных
                </button>
                <button type="button" clrDropdownItem [routerLink]="['/dictionary/animal-subtype']">Подвиды животных
                </button>
                <div class="dropdown-divider"></div>
                <button type="button" clrDropdownItem (click)="runUpdateAnimalTypesOp()">Обновление справочника животных
                </button>
                <div class="dropdown-divider"></div>
                <button type="button" clrDropdownItem [routerLink]="['/dictionary/disease-type']">Болезни животных
                </button>
                <button type="button" clrDropdownItem [routerLink]="['/dictionary/disease-serotype']">Серотипы
                  возбудителей
                </button>
                <button type="button" clrDropdownItem [routerLink]="['/dictionary/death-reason']">Причины выбытия
                  животных
                </button>
                <div class="dropdown-divider"></div>
                <button type="button" clrDropdownItem [routerLink]="['/dictionary/nomenclature-animal-pin']">
                  Номенклатура бирок животных
                </button>
                <button type="button" clrDropdownItem [routerLink]="['/dictionary/agent-available-pins']">Распределение
                  бирок животных
                </button>
              </clr-dropdown-menu>
            </clr-dropdown>

            <clr-dropdown>
              <button type="button" clrDropdownTrigger>Карантины</button>
              <clr-dropdown-menu clrPosition="left-top">
                <button type="button" clrDropdownItem [routerLink]="['/dictionary/quarantine-event-type']">Виды
                  мероприятий
                </button>
                <button type="button" clrDropdownItem [routerLink]="['/dictionary/quarantine-event-branch']">Разделы
                  мероприятий
                </button>
                <button type="button" clrDropdownItem [routerLink]="['/dictionary/quarantine-event-responsible']">
                  Ответственные за мероприятия
                </button>
                <button type="button" clrDropdownItem [routerLink]="['/dictionary/quarantine-event-period']">Сроки
                  карантинных мероприятий
                </button>
                <div class="dropdown-divider"></div>
                <button type="button" clrDropdownItem [routerLink]="['/dictionary/quarantine-template']">Шаблоны
                  мероприятий
                </button>
              </clr-dropdown-menu>
            </clr-dropdown>

            <clr-dropdown>
              <button type="button" clrDropdownTrigger>Мероприятия</button>
              <clr-dropdown-menu clrPosition="left-top">
                <button type="button" clrDropdownItem [routerLink]="['/dictionary/event-type']">Виды мероприятий
                </button>
                <button type="button" clrDropdownItem [routerLink]="['/dictionary/test-material']">Исследуемые
                  материалы
                </button>
                <button type="button" clrDropdownItem [routerLink]="['/dictionary/diagnostic-type']">Способы диагностики
                </button>
                <button type="button" clrDropdownItem [routerLink]="['/dictionary/event-indicator']">Наименование показателей
                </button>
                <button type="button" clrDropdownItem [routerLink]="['/dictionary/event-forced-reason']">Причины вынужденности
                </button>
              </clr-dropdown-menu>
            </clr-dropdown>

            <clr-dropdown>
              <button type="button" clrDropdownTrigger>Препараты</button>
              <clr-dropdown-menu clrPosition="left-top">
                <button type="button" clrDropdownItem [routerLink]="['/dictionary/drug-type']">Виды препаратов</button>
                <button type="button" clrDropdownItem [routerLink]="['/dictionary/drug-package-type']">Виды упаковок
                </button>
                <button type="button" clrDropdownItem [routerLink]="['/dictionary/drug-manufacturer']">Производители препаратов</button>
              </clr-dropdown-menu>
            </clr-dropdown>

            <clr-dropdown>
              <button type="button" clrDropdownTrigger>Продукция</button>
              <clr-dropdown-menu clrPosition="left-top">
                <button type="button" clrDropdownItem [routerLink]="['/dictionary/product-subtype']">Подвиды продукции
                </button>
                <button type="button" clrDropdownItem [routerLink]="['/dictionary/expertise-indicator-type']">Показатели
                  для
                  экспертизы
                </button>
              </clr-dropdown-menu>
            </clr-dropdown>

            <clr-dropdown>
              <button type="button" clrDropdownTrigger>Учреждения</button>
              <clr-dropdown-menu clrPosition="left-top">
                <button type="button" clrDropdownItem [routerLink]="['/dictionary/institution-type']">
                  Виды учреждений
                </button>
                <button type="button" clrDropdownItem [routerLink]="['/dictionary/institution-post']">
                  Должности в учреждениях
                </button>
                <button type="button" clrDropdownItem [routerLink]="['/dictionary/institution-ticket-quest']">
                  Вопросы талонов приёма
                </button>
              </clr-dropdown-menu>
            </clr-dropdown>

            <clr-dropdown>
              <button type="button" clrDropdownTrigger>Эл. отчетность</button>
              <clr-dropdown-menu clrPosition="left-top">
                <button type="button" clrDropdownItem (click)="runUpdatePstIndicators()">Актуализация показателей
                </button>
<!--                <button type="button" clrDropdownItem (click)="runStressPstIndicators()">Стресс-тест-->
<!--                </button>-->
                <button type="button" clrDropdownItem [routerLink]="['/operations/pst-change-agent-indicators']">
                  Замена предприятия в отчетах
                </button>
                <div class="dropdown-divider"></div>
                <button type="button" clrDropdownItem [routerLink]="['/dictionary/pst-report-type']">Виды электронных
                  отчетов
                </button>
                <button type="button" clrDropdownItem [routerLink]="['/dictionary/pst-indicator-subtype']">Подвиды показателей
                </button>
              </clr-dropdown-menu>
            </clr-dropdown>


            <div class="dropdown-divider"></div>
            <button type="button" clrDropdownItem (click)="runUpdateProductTypesOp()">Обновление справочника продукции
            </button>

            <button type="button" clrDropdownItem (click)="runUpdateDictionariesOp()">Обновление общих справочников из
              Ветис
            </button>
            <button title="Загрузка из государственного реестра лекарственных средств для ветеринарного применения"
                    type="button" clrDropdownItem
                    (click)="appNavigationService.performGroupOperation('op8')">
              Загрузка из государственного реестра лекарственных средств для ветеринарного применения
            </button>

            <div class="dropdown-divider"></div>
            <button type="button" clrDropdownItem [routerLink]="['/dictionary/calendar']">
              Производственный календарь
            </button>
          </clr-dropdown-menu>
        </clr-dropdown>
        <clr-dropdown>
          <button type="button" clrDropdownTrigger>Безопасность</button>
          <clr-dropdown-menu clrPosition="left-bottom">
            <button type="button" clrDropdownItem [routerLink]="['user-roles-browse']">Роли пользователей</button>
          </clr-dropdown-menu>
        </clr-dropdown>
        <button type="button" clrDropdownItem [routerLink]="['/dictionary/reports-browse']">Отчетные формы</button>
        <button type="button" clrDropdownItem [routerLink]="['/institution-search']">Учреждения / предприятия</button>
      </clr-dropdown-menu>
    </clr-dropdown>

    <clr-dropdown *ngIf="esiaService.loggedIn()">
      <button class="nav-icon" clrDropdownToggle style="width: auto">
        <span style="font-size: 12px; padding-right: 24px">{{securityService.getCurrentUserShortFio() | async}}</span>
        <clr-icon shape="caret down"></clr-icon>
      </button>
      <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
        <h4 class="dropdown-header">{{securityService.getCurrentUserShortFio() | async}}</h4>
        <clr-dropdown>
          <button type="button" clrDropdownTrigger>Последние карточки</button>
          <clr-dropdown-menu clrPosition="left-top">
            <button
                *ngIf="!appNavigationService.getLastAccessedObjects('stable') || !appNavigationService.getLastAccessedObjects('stable').length"
                type="button" clrDropdownItem class="disabled" disabled>Отсутствуют
            </button>
            <button *ngFor="let stableId of appNavigationService.getLastAccessedObjects('stable')" type="button"
                    clrDropdownItem
                    [routerLink]="['/stable-edit', stableId]" title="{{stableId|lookup:'stable'|async}}">
              <clr-icon shape="building" class="is-solid"></clr-icon>
              &nbsp;{{stableId|lookup:'stable'|async}}
            </button>
          </clr-dropdown-menu>
        </clr-dropdown>

        <div class="dropdown-divider"></div>
        <button type="button" [routerLink]="['personal-account']" clrDropdownItem>
          Личный кабинет пользователя
        </button>
        <button type="button" *ngIf="userInfo && userInfo.institutionId" (click)="navigateUserInstitution()"
                clrDropdownItem>
          Карточка учреждения
        </button>
        <div class="dropdown-divider"></div>
        <button type="button" [routerLink]="['group-operation-browse']" clrDropdownItem>
          Журнал регламентных операций
        </button>
        <button type="button" *ngIf="userInfo && userInfo.id"
                (click)="appNavigationService.navigateJournal(userInfo.id)" clrDropdownItem>
          Журнал произведенных действий
        </button>
        <button type="button" clrDropdownItem [routerLink]="['view-integration-log2']">Журнал операций Ветис.API</button>
        <button type="button" clrDropdownItem [routerLink]="['product-statistic']">Статистика ВСД</button>
        <button type="button" clrDropdownItem [routerLink]="['institution-employee-statistic']">Статистика действий пользователей</button>
        <div class="dropdown-divider"></div>

        <button type="button" *ngIf="userInfo && userInfo.id"
                (click)="impersonateUserUi()" clrDropdownItem>
          Имперсонализация
        </button>

        <button clrDropdownItem (click)="impersonate()">Отменить текущую
          имперсонализацию
        </button>

        <button *ngIf="securityService.isAccess('telemetry') | async"
                type="button" [routerLink]="['telemetry']" clrDropdownItem>
          Телеметрия
        </button>
        <div class="dropdown-divider"></div>
        <button type="button" (click)="logout()" clrDropdownItem>Выйти из приложения</button>
      </clr-dropdown-menu>
    </clr-dropdown>
  </div>
</header>
