<div class="itech-common-wrap" id="interceptNoAccess">
  <div style="width: 100%">
    <app-grid [pagination]="false" [getRowStyle]="getRowStyle" [columnDefs]="gridColumnDefs"
              [rowSelection]="'multiple'" [rowData]="model.searchResults"
              (rowDoubleClicked)="navigationService.performProductEditing($event.data.id)"
              [exportFileName]="'Список производной продукции от ' +  + (id|lookup:'product'|async)"
              [uniqueGridName]="'EditProductSubproductsGrid'" [masterDetail]="true" [icons]="gridIcons"
              [detailCellRenderer]="detailCellRenderer" [frameworkComponents]="frameworkComponents"
              [detailRowHeight]="'450'" [rowMultiSelectWithClick]="true" (selectionChanged)="onGridSelectionChanged($event)">
    </app-grid>
  </div>

  <div class="itech-form-actions">
    <div>
      <button class="btn btn-secondary" (click)="refreshFormData()">
        <clr-icon shape="refresh"></clr-icon>&nbsp;Обновить
      </button>

      <clr-dropdown>
        <button class="btn btn-secondary" clrDropdownToggle>
          Действия
          <clr-icon shape="caret down"></clr-icon>
        </button>
        <clr-dropdown-menu *clrIfOpen [clrPosition]="'top-left'">
          <div class="dropdown-divider"></div>
          <button type="button" clrDropdownItem (click)="sendToRegistration()"
                  [disabled]="model.selectedRows && model.selectedRows.length ? undefined : true">Отправить на регистрацию</button>
          <button type="button" clrDropdownItem (click)="sendToClearance()"
                  [disabled]="model.selectedRows && model.selectedRows.length ? undefined : true">Погасить документы</button>
          <div class="dropdown-divider"></div>
          <button type="button" clrDropdownItem (click)="createTransportForSelectedDocuments()"
                  [disabled]="model.selectedRows && model.selectedRows.length ? undefined : true">Оформить транспортный ВСД</button>
          <div class="dropdown-divider"></div>
          <app-products-reports-button [model]="model">
          </app-products-reports-button>
          <div class="dropdown-divider"></div>
          <button type="button" clrDropdownItem (click)="deleteDocument()"
                  [disabled]="model.selectedRows && model.selectedRows.length ? undefined : true">Удалить документ(ы)</button>
        </clr-dropdown-menu>
      </clr-dropdown>
      <button class="btn btn-success-outline" (click)="addNew()">
        <clr-icon shape="plus"></clr-icon>&nbsp;Добавить
      </button>
    </div>
  </div>
</div>
