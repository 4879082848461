<div class="itech-common-wrap no-pager">
  <div>
    <div class="itech-search-table-with-filter">
      <div style="display: flex; flex-direction: row;">
        <clr-icon shape="search" size="72"></clr-icon>
        <div class="compact form itech-highlighted-sections" [formGroup]="model.filterFormGroup"
             *ngIf="model && model.filterFormGroup">
          <section class="form-block itech-block-medium">
            <h3>Просмотр статистики действий пользователей</h3>
            <div class="form-group">
              <label for="typeId">Вид операции</label>
              <app-combo-lookup [validationTooltipRight]="true" class="itech-control-xlarge"
                                [contextControlId]="'typeId'" [contextControlName]="'typeId'"
                                [lookupName]="'institution-employee-statistic-type'"
                                [contextFormGroup]="model.filterFormGroup"></app-combo-lookup>
            </div>
            <div class="form-group">
              <label for="dateFrom">Дата с/по</label>
              <label for="dateFrom" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                     [class.invalid]="isInvalid(model.filterFormGroup, 'dateFrom')">
                <input type="date" id="dateFrom" formControlName="dateFrom" (keydown)="filterEnterPressed($event)">
                <app-validation-tooltip [input]="model.filterFormGroup.controls['dateFrom']"></app-validation-tooltip>
              </label>
              <label for="dateTo" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                     [class.invalid]="isInvalid(model.filterFormGroup, 'dateTo')">
                <input type="date" id="dateTo" formControlName="dateTo" (keydown)="filterEnterPressed($event)">
                <app-validation-tooltip [input]="model.filterFormGroup.controls['dateTo']"></app-validation-tooltip>
              </label>
            </div>
            <div class="clr-control-container clr-control-inline">
              <div class="clr-radio-wrapper itech-highlight-checked">
                <input id="institutionViewType" type="radio" (ngModelChange)="changeViewType()"
                       formControlName="viewType" [value]="1" class="clr-radio"/>
                <label for="institutionViewType">По учреждениям</label>
              </div>
              &nbsp;&nbsp;&nbsp;
              <div class="clr-radio-wrapper itech-highlight-checked">
                <input id="employeeViewType" type="radio" (ngModelChange)="changeViewType()"
                       formControlName="viewType" [value]="2" class="clr-radio"/>
                <label for="employeeViewType">По работникам</label>
              </div>
            </div>
          </section>
        </div>
      </div>

      <div>
        <app-grid class="app-grid-header-wrap" [pagination]="false" [columnDefs]="gridColumnDefs"
                  [rowData]="model.searchResults" (gridReady)="agGridReady($event)"
                  [exportFileName]="'Статистика действий пользователей'"
                  [defaultColDef]="defColDef" [uniqueGridName]="'InstitutionEmployeeStatistic'"></app-grid>
      </div>
    </div>
  </div>

  <div class="itech-form-actions">
    <button class="btn btn-primary" (click)="refreshResults()">
      <clr-icon shape="search" class="has-badge"></clr-icon>&nbsp;Обновить
    </button>
  </div>
</div>
