import * as tslib_1 from "tslib";
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { MetadataService } from '../../../logic/services/metadata.service';
import { FormBuilder, Validators } from '@angular/forms';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { MetaBrowseBaseComponent } from '../../../pages/dictionary/meta-browse.base.component';
var PstIndicatorSubtypeBrowseComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PstIndicatorSubtypeBrowseComponent, _super);
    function PstIndicatorSubtypeBrowseComponent(route, metadataService, alertService, globalWaitingOverlayService, lookupService, fb, bookmarkService, dataCachingService) {
        var _this = _super.call(this, route, metadataService, alertService, globalWaitingOverlayService, lookupService, fb, bookmarkService, dataCachingService) || this;
        _this.gridColumnDefs = [
            { headerName: '№', field: 'id', width: 100 },
            { headerName: 'Наименование', field: 'caption', tooltipField: 'caption', width: 400 },
            { headerName: 'Группа', field: 'groupCaption', tooltipField: 'groupCaption', width: 300 },
        ];
        return _this;
    }
    PstIndicatorSubtypeBrowseComponent.prototype.getMetaTitle = function () {
        return 'Подвиды показателей';
    };
    PstIndicatorSubtypeBrowseComponent.prototype.getMetaName = function () {
        return 'pst-indicator-subtype';
    };
    PstIndicatorSubtypeBrowseComponent.prototype.refreshResults = function () {
        var _this = this;
        this.globalWaitingOverlayService.StartWaiting();
        this.metadataService.getMetadata(this.getMetaName()).subscribe({
            next: function (data) {
                _this._searchResults = data;
                _this.dataCachingService.addToCache(_this.getMetaName() + '_Data', '1', data);
                _this.lookupService.invalidateLookup(_this.getMetaName());
                _this.globalWaitingOverlayService.EndWaiting();
            }, error: function () {
                _this.globalWaitingOverlayService.EndWaiting();
            }
        });
    };
    PstIndicatorSubtypeBrowseComponent.prototype.getGroupDef = function (row) {
        return {
            id: [row.id, Validators.compose([Validators.required, Validators.pattern(/^\d{1,6}$/)])],
            caption: [row.caption, Validators.required],
            groupCaption: row.groupCaption,
            isDefault: row.isDefault,
        };
    };
    return PstIndicatorSubtypeBrowseComponent;
}(MetaBrowseBaseComponent));
export { PstIndicatorSubtypeBrowseComponent };
