import { EventEmitter, OnChanges } from '@angular/core';
import { AppFormGroup } from '../../../ui/controls/app-form-group';
import { ServerSideErrorsProvider } from '../../../logic/validators/server-side-errors-provider';
import { FormHelper } from '../../../ui/controls/form-helper';
import { StringHelper } from '../../../helpers/string-helper';
import { EditQuarantineStagesComponent } from './edit-quarantine-stages.component';
import { FormBuilder } from '@angular/forms';
import { DateHelper } from '../../../helpers/date-helper';
var EditQuarantineStagesIntroductionComponent = /** @class */ (function () {
    function EditQuarantineStagesIntroductionComponent(fb) {
        this.fb = fb;
        this.isAccessEdit = false;
        this.dirtyFormGroup = new EventEmitter();
        this.needUpdateQuarantine = new EventEmitter();
        this.statusIntroduction = EditQuarantineStagesComponent.QUARANTINE_STAGE.INTRODUCTION;
        this.isInvalid = FormHelper.isInvalid;
    }
    Object.defineProperty(EditQuarantineStagesIntroductionComponent.prototype, "orderToExtend", {
        get: function () {
            return this.contextFormGroup.get('orderToExtend');
        },
        enumerable: true,
        configurable: true
    });
    EditQuarantineStagesIntroductionComponent.prototype.getFormGroup = function (order) {
        return order;
    };
    EditQuarantineStagesIntroductionComponent.prototype.ngOnChanges = function () {
        var _this = this;
        this.contextFormGroup.valueChanges.subscribe(function (x) { return _this.dirtyFormGroup.next(); });
    };
    EditQuarantineStagesIntroductionComponent.prototype.markAsDirty = function () {
        this.contextFormGroup.markAsDirty();
        this.dirtyFormGroup.next();
    };
    EditQuarantineStagesIntroductionComponent.prototype.setNullIfWhitespace = function (controlName) {
        if (StringHelper.isNullOrWhitespace(this.contextFormGroup.get(controlName).value)) {
            this.contextFormGroup.get(controlName).setValue(null);
        }
    };
    EditQuarantineStagesIntroductionComponent.prototype.addNewOrderToExtend = function () {
        this.orderToExtend.push(this.fb.group(EditQuarantineStagesComponent.buildFormGroupFile(this.fb, this.serverSideErrorsProvider, {}, 'orderToExtend-' + this.orderToExtend.length)));
    };
    EditQuarantineStagesIntroductionComponent.prototype.getDateUploadToString = function (dateStr) {
        return StringHelper.getRuTimeWithSeconds(DateHelper.toDate(dateStr));
    };
    EditQuarantineStagesIntroductionComponent.prototype.changeNumberDoc = function (fg) {
        if (fg.get('number').value === '') {
            fg.get('number').setValue(null);
        }
    };
    return EditQuarantineStagesIntroductionComponent;
}());
export { EditQuarantineStagesIntroductionComponent };
