<ng-container *ngIf="syncStates && syncStates.length">
    <div class="trigger-item" *ngIf="frontSyncState">
      <clr-signpost class="itech-sync-status-lbl">
        <a *ngIf="+frontSyncState.mercuryExchangeStatus === -1"
           class="itech-inline-link-btn" clrSignpostTrigger style="color: red">
          <clr-icon *ngIf="frontSyncState.mercuryConversationErrorTypeId == 30; else circle" shape="checkbox-list" style="color: red"></clr-icon>
          <ng-template #circle>
            <clr-icon shape="exclamation-circle" style="color: red"></clr-icon>
          </ng-template>
          Синхронизация с ошибками в данных
        </a>
        <a *ngIf="+frontSyncState.mercuryExchangeStatus === -2"
           class="itech-inline-link-btn" clrSignpostTrigger style="color: green">
          <clr-icon shape="lock"></clr-icon>
          Синхронизация проведена
        </a>
        <a *ngIf="+frontSyncState.mercuryExchangeStatus === 1 && (frontSyncState.withoutMercuryUuid || frontSyncState.mercuryUuid)"
           class="itech-inline-link-btn" clrSignpostTrigger style="color: green">
          <clr-icon shape="check"></clr-icon>
          Синхронизация успешна
        </a>
        <a *ngIf="+frontSyncState.mercuryExchangeStatus === 1 && !frontSyncState.withoutMercuryUuid && !frontSyncState.mercuryUuid"
           class="itech-inline-link-btn" clrSignpostTrigger>
          <clr-icon shape="check"></clr-icon>
          Синхронизация не требуется
        </a>
        <a *ngIf="+frontSyncState.mercuryExchangeStatus === 0"
           class="itech-inline-link-btn" clrSignpostTrigger style="color: orange">
          <clr-icon shape="exclamation-circle"></clr-icon>
          Синхронизация не запланирована
        </a>
        <a *ngIf="equalsSome(+frontSyncState.mercuryExchangeStatus, 2, 3, 4)"
           class="itech-inline-link-btn" clrSignpostTrigger style="color: navy">
          <clr-icon *ngIf="+frontSyncState.mercuryExchangeLastResult == -2" shape="exclamation-circle"
                    style="color: orange"></clr-icon>
          <ng-container *ngIf="frontSyncState.mercuryExchangeNextTime">
            <span class="spinner spinner-sm"></span>
            Синхронизация выполняется
          </ng-container>
          <ng-container *ngIf="!frontSyncState.mercuryExchangeNextTime && frontSyncState.mercuryExchangeLastResult != '-2'">
            <clr-icon shape="exclamation-circle"></clr-icon>
            Синхронизация не запланирована
          </ng-container>
        </a>
        <clr-signpost-content [clrPosition]="'right-bottom'" class="compact form itech-highlighted-sections"
                              style="max-width:22rem" *clrIfOpen>
              <div class="compact form itech-highlighted-sections">
                <section *ngFor="let state of syncStates; index as i" class="form-block itech-block-normal">
                  <div class="form-group">
                    <label>Тип операции</label>
                    <div class="itech-text-field itech-control-large">
                      {{state.conversationTypeId|lookup:'mercury-conversation-types'|async}}
                    </div>
                  </div>
              <div class="form-group">
                <label>Статус синхронизации</label>
                <div class="itech-text-field itech-control-large">
                  {{state.mercuryExchangeStatus|lookup:'mercury-agent-exchange-status'|async}}
                </div>
              </div>
              <div class="form-group">
                <label>Текущий этап</label>
                <ng-container *ngIf="!state.isEditStarted || equalsSome(+state.conversationTypeId, -1, -2); else changeStage">
                  <ng-container *ngIf="+state.conversationStageId === 0; else haveStageId">
                    <div class="itech-text-field itech-control-large">
                      Завершено
                    </div>
                  </ng-container>
                  <ng-template #haveStageId>
                    <div class="itech-text-field itech-control-large">
                      {{state.conversationStageId|lookup:'mercury-conversation-stages/' + (state.lookupStage || state.conversationTypeId)|async}}
                    </div>
                  </ng-template>
                </ng-container>
                <ng-template #changeStage>
                  <div class="itech-control-large">
                    <app-combo-lookup [validationTooltipTopLeft]="true" class="itech-control-large" [contextControlId]="'conversationStageId'"
                                      [contextControlName]="'conversationStageId'" [lookupItems]="availableConversationStages"
                                      [contextFormGroup]="syncStateForUpdate"></app-combo-lookup>
                  </div>
                </ng-template>
              </div>
              <div class="form-group" *ngIf="state.createdUser">
                <label>Пользователь</label>
                <div class="itech-text-field itech-control-large">
                  {{state.createdUser|lookup:'user'|async}}
                </div>
              </div>
              <div class="form-group" *ngIf="state.mercuryUuid">
                <label>GUID записи в ИС Меркурий</label>
                <div class="itech-text-field itech-control-large" style="color: green">
                  {{state.mercuryUuid}}
                </div>
              </div>
              <div class="form-group" *ngIf="state.mercuryJournalNo">
                <label>№ в журнале ИС Меркурий</label>
                <div class="itech-text-field itech-control-large" style="color: green">
                  {{state.mercuryJournalNo}}
                </div>
              </div>
              <div class="form-group" *ngIf="state.mercuryApplicationUuid">
                <label>UUID действ. запроса Ветис.API</label>
                <div class="itech-text-field itech-control-large" style="display:flex;align-items:center;justify-content:center;">
                  <span class="spinner spinner-inline spinner-tiny"></span>
                  {{state.isEditStarted ? syncStateForUpdate.get('mercuryApplicationUuid').value : state.mercuryApplicationUuid}}
                  <button *ngIf="state.isEditStarted" type="button" (click)="clearMercuryApplicationUuid()" title="Очистить"
                          class="btn btn-danger btn-link itech-inline-tool-btn">
                    <clr-icon shape="trash" style="color: red"></clr-icon>
                  </button>
                </div>
              </div>
              <div class="form-group" *ngIf="state.mercuryExchangeNextTime">
                <label>След. операция</label>
                <div class="itech-text-field itech-control-large">
                  {{state.mercuryExchangeNextTime|rtime}}
                </div>
              </div>
              <div class="form-group" *ngIf="isMercuryExchangeNextTimeInFuture(state)">
                <label></label>
                <a class="itech-link in-table-link itech-control-large" (click)="forceSyncNextTime(state)">Запустить немедленно</a>
              </div>
              <div class="form-group">
                <label>Время посл. операции</label>
                <div class="itech-text-field itech-control-large">
                  {{state.mercuryExchangeLastTime|rtime}}
                </div>
              </div>
              <div class="form-group">
                <label>Результат посл. операции</label>
                <ng-container *ngIf="!state.isEditStarted; else changeResult">
                  <div class="itech-text-field itech-control-large">
                    {{state.mercuryExchangeLastResult|lookup:'mercury-exchange-result'|async}}
                    <a class="itech-link in-table-link itech-control-large" (click)="tryDownloadLogs(state)">
                      <clr-icon shape="download"></clr-icon>
                    </a>
                  </div>
                </ng-container>
                <ng-template #changeResult>
                  <div class="itech-control-large">
                    <app-combo-lookup [validationTooltipTopLeft]="true" class="itech-control-large" [contextControlId]="'mercuryExchangeLastResult'"
                                      [contextControlName]="'mercuryExchangeLastResult'" [lookupName]="'mercury-exchange-result'"
                                      [contextFormGroup]="syncStateForUpdate"></app-combo-lookup>
                  </div>
                </ng-template>
              </div>
              <div class="form-group itech-group-whole-line" style="margin-top: 5px">
                <label></label>
                <textarea *ngIf="state.mercuryExchangeLastResultText"
                          class="itech-control-m-large itech-text-wall" readonly
                          style="color:red;min-height:100px;max-width:100%;width:100%;">{{state.mercuryExchangeLastResultText}}</textarea>
              </div>
              <div *ngIf="(securityService.getUserInfo() | async).allowedEditMercuryConversation">
                <ng-container *ngIf="state.isEditStarted; else editButton">
                  <button type="button" class="btn btn-link itech-inline-link-btn" style="padding-right: 80px" (click)="updateSyncState(state)">
                    <clr-icon shape="check"></clr-icon>&nbsp;Сохранить
                  </button>
                  <button type="button" class="btn btn-link itech-inline-link-btn" (click)="cancelEditSyncState(state)">
                    <clr-icon shape="undo"></clr-icon>&nbsp;Отменить
                  </button>
                </ng-container>
                <ng-template #editButton>
                  <button type="button" class="signpost-edit-button" title="Редактировать" (click)="startEditSyncState(state)">
                    <clr-icon shape="edit"></clr-icon>
                  </button>
                </ng-template>
              </div>
            </section>
          </div>
        </clr-signpost-content>
      </clr-signpost>
    </div>
</ng-container>
