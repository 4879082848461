/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "@clr/angular";
import * as i3 from "../../../../node_modules/@clr/angular/clr-angular.ngfactory";
import * as i4 from "@angular/common";
import * as i5 from "./user-tags-autofill-edit-modal.component.ngfactory";
import * as i6 from "./user-tags-autofill-edit-modal.component";
import * as i7 from "../../logic/services/security.service";
import * as i8 from "./app-input-text.component";
var styles_AppInputTextComponent = [];
var RenderType_AppInputTextComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppInputTextComponent, data: {} });
export { RenderType_AppInputTextComponent as RenderType_AppInputTextComponent };
function View_AppInputTextComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["textInput", 1]], null, 5, "input", [["style", "padding-left:20px"], ["type", "text"]], [[8, "id", 0], [8, "classList", 0], [8, "placeholder", 0], [1, "disabled", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "keypress"], [null, "keydown"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 2)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 2)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("keypress" === en)) {
        var pd_4 = (_co.keyPressCallback($event) !== false);
        ad = (pd_4 && ad);
    } if (("keydown" === en)) {
        var pd_5 = (_co.keyDownEmitter.emit($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 16384, null, 0, i1.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i1.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i1.DefaultValueAccessor]), i0.ɵdid(4, 540672, null, 0, i1.FormControlDirective, [[8, null], [8, null], [6, i1.NG_VALUE_ACCESSOR], [2, i1.ɵangular_packages_forms_forms_k]], { form: [0, "form"] }, null), i0.ɵprd(2048, null, i1.NgControl, null, [i1.FormControlDirective]), i0.ɵdid(6, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_11 = _co.formControlObj; _ck(_v, 4, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contextId; var currVal_1 = _co.classNames; var currVal_2 = _co.placeholder; var currVal_3 = (_co.disabled ? true : undefined); var currVal_4 = i0.ɵnov(_v, 6).ngClassUntouched; var currVal_5 = i0.ɵnov(_v, 6).ngClassTouched; var currVal_6 = i0.ɵnov(_v, 6).ngClassPristine; var currVal_7 = i0.ɵnov(_v, 6).ngClassDirty; var currVal_8 = i0.ɵnov(_v, 6).ngClassValid; var currVal_9 = i0.ɵnov(_v, 6).ngClassInvalid; var currVal_10 = i0.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10]); }); }
function View_AppInputTextComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [[1, 0], ["textInput", 1]], null, 5, "textarea", [["style", "padding-left:20px"], ["type", "text"]], [[8, "id", 0], [8, "placeholder", 0], [8, "classList", 0], [1, "disabled", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "keydown"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 1)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 1)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("keydown" === en)) {
        var pd_4 = (_co.keyDownEmitter.emit($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i1.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i1.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i1.DefaultValueAccessor]), i0.ɵdid(3, 540672, null, 0, i1.FormControlDirective, [[8, null], [8, null], [6, i1.NG_VALUE_ACCESSOR], [2, i1.ɵangular_packages_forms_forms_k]], { form: [0, "form"] }, null), i0.ɵprd(2048, null, i1.NgControl, null, [i1.FormControlDirective]), i0.ɵdid(5, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_11 = _co.formControlObj; _ck(_v, 3, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contextId; var currVal_1 = _co.placeholder; var currVal_2 = _co.classNames; var currVal_3 = (_co.disabled ? true : undefined); var currVal_4 = i0.ɵnov(_v, 5).ngClassUntouched; var currVal_5 = i0.ɵnov(_v, 5).ngClassTouched; var currVal_6 = i0.ɵnov(_v, 5).ngClassPristine; var currVal_7 = i0.ɵnov(_v, 5).ngClassDirty; var currVal_8 = i0.ɵnov(_v, 5).ngClassValid; var currVal_9 = i0.ɵnov(_v, 5).ngClassInvalid; var currVal_10 = i0.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10]); }); }
function View_AppInputTextComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "button", [["class", "btn btn-link app-input-tag-icon"], ["clrDropdownItem", ""], ["style", "padding-left:10px;font-size:12px;line-height:14px;height: 18px;"], ["type", "button"]], [[2, "disabled", null], [2, "dropdown-item", null], [1, "role", 0], [1, "aria-disabled", 0], [1, "disabled", 0], [1, "id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectTag(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(1024, null, i2.ɵo, i2.ɵp, []), i0.ɵprd(512, [[3, 4]], i2.ɵr, i2.ɵt, [i2.ɵo, i0.ElementRef, i0.Renderer2, i0.PLATFORM_ID]), i0.ɵdid(3, 4341760, null, 0, i2.ClrDropdownItem, [i2.ClrDropdown, i0.ElementRef, i2.ɵe, i0.Renderer2, i2.ɵr], null, null), (_l()(), i0.ɵted(4, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 3).disabled; var currVal_1 = true; var currVal_2 = "menuitem"; var currVal_3 = i0.ɵnov(_v, 3).disabled; var currVal_4 = ((i0.ɵnov(_v, 3).disabled && i0.ɵnov(_v, 3).setByDeprecatedDisabled) ? "" : null); var currVal_5 = i0.ɵnov(_v, 3).dropdownItemId; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = _v.context.$implicit.caption; _ck(_v, 4, 0, currVal_6); }); }
function View_AppInputTextComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "clr-dropdown-menu", [["style", "padding:5px 0"]], [[2, "dropdown-menu", null], [1, "role", 0], [2, "is-off-screen", null]], null, null, i3.View_ClrDropdownMenu_0, i3.RenderType_ClrDropdownMenu)), i0.ɵdid(1, 9617408, null, 1, i2.ClrDropdownMenu, [i0.Injector, [2, i2.ɵh], [3, i2.ClrDropdownMenu], i2.ɵl], { position: [0, "position"] }, null), i0.ɵqud(603979776, 3, { items: 1 }), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_AppInputTextComponent_8)), i0.ɵdid(4, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_3 = "right-top"; _ck(_v, 1, 0, currVal_3); var currVal_4 = _v.parent.parent.context.$implicit.value; _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = "menu"; var currVal_2 = i0.ɵnov(_v, 1).isOffScreen; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_AppInputTextComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 13, "clr-dropdown", [["style", "width:100%"]], [[2, "dropdown", null], [2, "open", null]], null, null, i3.View_ClrDropdown_0, i3.RenderType_ClrDropdown)), i0.ɵprd(6144, null, i2.ɵh, null, [i0.ElementRef]), i0.ɵprd(512, null, i2.ɵd, i2.ɵd, []), i0.ɵprd(1024, null, i2.ɵe, i2.ɵf, [[3, i2.ɵe]]), i0.ɵdid(5, 180224, null, 0, i2.ClrDropdown, [[3, i2.ClrDropdown], i2.ɵd, i0.ChangeDetectorRef, i2.ɵe], null, null), i0.ɵprd(1024, null, i2.ɵo, i2.ɵp, []), i0.ɵprd(1024, null, i2.ɵi, i2.ɵj, [[3, i2.ɵi], i0.Renderer2]), i0.ɵprd(131584, null, i2.ɵl, i2.ɵl, [i2.ɵo, i0.Renderer2, [3, i2.ɵl], i2.ɵd, i2.ɵi, i0.PLATFORM_ID]), i0.ɵprd(2048, [[2, 4]], i2.ɵr, null, [i2.ɵl]), (_l()(), i0.ɵeld(10, 0, null, 0, 2, "button", [["class", "btn btn-link app-input-tag-icon"], ["clrDropdownToggle", ""], ["style", "padding-left:10px;font-size:12px;line-height:14px;height: 18px;"]], [[2, "dropdown-toggle", null], [2, "dropdown-item", null], [2, "expandable", null], [2, "active", null], [1, "aria-haspopup", 0], [1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 11).onDropdownTriggerClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(11, 16384, null, 0, i2.ClrDropdownTrigger, [i2.ClrDropdown, i2.ɵd, i0.ElementRef, i2.ɵl], null, null), (_l()(), i0.ɵted(12, null, [" ", " "])), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_AppInputTextComponent_7)), i0.ɵdid(14, 147456, null, 0, i2.ClrIfOpen, [i2.ɵd, i0.TemplateRef, i0.ViewContainerRef], { open: [0, "open"] }, null)], function (_ck, _v) { var currVal_9 = null; _ck(_v, 14, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = i0.ɵnov(_v, 5).ifOpenService.open; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = i0.ɵnov(_v, 11).isRootLevelToggle; var currVal_3 = !i0.ɵnov(_v, 11).isRootLevelToggle; var currVal_4 = !i0.ɵnov(_v, 11).isRootLevelToggle; var currVal_5 = i0.ɵnov(_v, 11).active; var currVal_6 = "menu"; var currVal_7 = i0.ɵnov(_v, 11).active; _ck(_v, 10, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = _v.parent.context.$implicit.key; _ck(_v, 12, 0, currVal_8); }); }
function View_AppInputTextComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "button", [["class", "btn btn-link app-input-tag-icon"], ["clrDropdownItem", ""], ["style", "padding-left:10px;font-size:12px;line-height:14px;height: 18px;"], ["type", "button"]], [[2, "disabled", null], [2, "dropdown-item", null], [1, "role", 0], [1, "aria-disabled", 0], [1, "disabled", 0], [1, "id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectTag(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(1024, null, i2.ɵo, i2.ɵp, []), i0.ɵprd(512, [[2, 4]], i2.ɵr, i2.ɵt, [i2.ɵo, i0.ElementRef, i0.Renderer2, i0.PLATFORM_ID]), i0.ɵdid(3, 4341760, null, 0, i2.ClrDropdownItem, [i2.ClrDropdown, i0.ElementRef, i2.ɵe, i0.Renderer2, i2.ɵr], null, null), (_l()(), i0.ɵted(4, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 3).disabled; var currVal_1 = true; var currVal_2 = "menuitem"; var currVal_3 = i0.ɵnov(_v, 3).disabled; var currVal_4 = ((i0.ɵnov(_v, 3).disabled && i0.ɵnov(_v, 3).setByDeprecatedDisabled) ? "" : null); var currVal_5 = i0.ɵnov(_v, 3).dropdownItemId; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = _v.context.$implicit.caption; _ck(_v, 4, 0, currVal_6); }); }
function View_AppInputTextComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppInputTextComponent_10)), i0.ɵdid(1, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.value; _ck(_v, 1, 0, currVal_0); }, null); }
function View_AppInputTextComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppInputTextComponent_6)), i0.ɵdid(2, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["withoutGroup", 2]], null, 0, null, View_AppInputTextComponent_9))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.key !== "-1"); var currVal_1 = i0.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_AppInputTextComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 3, null, View_AppInputTextComponent_5)), i0.ɵdid(2, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(0, i4.KeyValuePipe, [i0.KeyValueDiffers]), (_l()(), i0.ɵeld(5, 0, null, null, 0, "div", [["class", "dropdown-divider"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 4).transform(i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.userService.getUserInfo())).userSettingUi.groupingTags)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_AppInputTextComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "clr-dropdown-menu", [["style", "padding:5px 0"]], [[2, "dropdown-menu", null], [1, "role", 0], [2, "is-off-screen", null]], null, null, i3.View_ClrDropdownMenu_0, i3.RenderType_ClrDropdownMenu)), i0.ɵdid(1, 9617408, null, 1, i2.ClrDropdownMenu, [i0.Injector, [2, i2.ɵh], [3, i2.ClrDropdownMenu], i2.ɵl], { position: [0, "position"] }, null), i0.ɵqud(603979776, 2, { items: 1 }), (_l()(), i0.ɵand(16777216, null, 0, 2, null, View_AppInputTextComponent_4)), i0.ɵdid(4, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(6, 0, null, 0, 4, "button", [["class", "btn btn-link app-input-tag-icon"], ["clrDropdownItem", ""], ["style", "padding-left:10px;font-size:12px;line-height:14px;height: 18px;"], ["type", "button"]], [[2, "disabled", null], [2, "dropdown-item", null], [1, "role", 0], [1, "aria-disabled", 0], [1, "disabled", 0], [1, "id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addTag() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(1024, null, i2.ɵo, i2.ɵp, []), i0.ɵprd(512, [[2, 4]], i2.ɵr, i2.ɵt, [i2.ɵo, i0.ElementRef, i0.Renderer2, i0.PLATFORM_ID]), i0.ɵdid(9, 4341760, null, 0, i2.ClrDropdownItem, [i2.ClrDropdown, i0.ElementRef, i2.ɵe, i0.Renderer2, i2.ɵr], null, null), (_l()(), i0.ɵted(-1, null, [" \u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u0442\u044D\u0433 "]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = "bottom-left"; _ck(_v, 1, 0, currVal_3); var currVal_4 = (i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform(_co.userService.getUserInfo())).userSettingUi.tags || i0.ɵEMPTY_ARRAY).length; _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = "menu"; var currVal_2 = i0.ɵnov(_v, 1).isOffScreen; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_5 = i0.ɵnov(_v, 9).disabled; var currVal_6 = true; var currVal_7 = "menuitem"; var currVal_8 = i0.ɵnov(_v, 9).disabled; var currVal_9 = ((i0.ɵnov(_v, 9).disabled && i0.ɵnov(_v, 9).setByDeprecatedDisabled) ? "" : null); var currVal_10 = i0.ɵnov(_v, 9).dropdownItemId; _ck(_v, 6, 0, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); }); }
export function View_AppInputTextComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { textInput: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppInputTextComponent_1)), i0.ɵdid(2, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["htmlTypeTextArea", 2]], null, 0, null, View_AppInputTextComponent_2)), (_l()(), i0.ɵeld(4, 0, null, null, 15, "div", [["class", "app-input-tag"], ["style", "max-width:17px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 14, "clr-dropdown", [], [[2, "dropdown", null], [2, "open", null]], null, null, i3.View_ClrDropdown_0, i3.RenderType_ClrDropdown)), i0.ɵprd(6144, null, i2.ɵh, null, [i0.ElementRef]), i0.ɵprd(6144, null, i2.ɵr, null, [i2.ɵl]), i0.ɵprd(512, null, i2.ɵd, i2.ɵd, []), i0.ɵprd(1024, null, i2.ɵe, i2.ɵf, [[3, i2.ɵe]]), i0.ɵdid(10, 180224, null, 0, i2.ClrDropdown, [[3, i2.ClrDropdown], i2.ɵd, i0.ChangeDetectorRef, i2.ɵe], null, null), i0.ɵprd(1024, null, i2.ɵo, i2.ɵp, []), i0.ɵprd(1024, null, i2.ɵi, i2.ɵj, [[3, i2.ɵi], i0.Renderer2]), i0.ɵprd(131584, null, i2.ɵl, i2.ɵl, [i2.ɵo, i0.Renderer2, [3, i2.ɵl], i2.ɵd, i2.ɵi, i0.PLATFORM_ID]), (_l()(), i0.ɵeld(14, 0, null, 0, 3, "button", [["class", "btn btn-link app-input-tag-icon"], ["clrDropdownToggle", ""]], [[2, "dropdown-toggle", null], [2, "dropdown-item", null], [2, "expandable", null], [2, "active", null], [1, "aria-haspopup", 0], [1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 15).onDropdownTriggerClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(15, 16384, null, 0, i2.ClrDropdownTrigger, [i2.ClrDropdown, i2.ɵd, i0.ElementRef, i2.ɵl], null, null), (_l()(), i0.ɵeld(16, 0, null, null, 1, "clr-icon", [["shape", "tag"], ["size", "14"], ["style", "color:green"]], null, null, null, null, null)), i0.ɵdid(17, 16384, null, 0, i2.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_AppInputTextComponent_3)), i0.ɵdid(19, 147456, null, 0, i2.ClrIfOpen, [i2.ɵd, i0.TemplateRef, i0.ViewContainerRef], { open: [0, "open"] }, null), (_l()(), i0.ɵeld(20, 0, null, null, 1, "app-user-tags-autofill-edit-modal", [], null, null, null, i5.View_UserTagsAutofillEditModalComponent_0, i5.RenderType_UserTagsAutofillEditModalComponent)), i0.ɵdid(21, 114688, null, 0, i6.UserTagsAutofillEditModalComponent, [i1.FormBuilder, i7.SecurityService], { modalOpenedEmit: [0, "modalOpenedEmit"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.htmlType === _co.htmlTypeInput); var currVal_1 = i0.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_10 = null; _ck(_v, 19, 0, currVal_10); var currVal_11 = _co.addNewTagModalOpened; _ck(_v, 21, 0, currVal_11); }, function (_ck, _v) { var currVal_2 = true; var currVal_3 = i0.ɵnov(_v, 10).ifOpenService.open; _ck(_v, 5, 0, currVal_2, currVal_3); var currVal_4 = i0.ɵnov(_v, 15).isRootLevelToggle; var currVal_5 = !i0.ɵnov(_v, 15).isRootLevelToggle; var currVal_6 = !i0.ɵnov(_v, 15).isRootLevelToggle; var currVal_7 = i0.ɵnov(_v, 15).active; var currVal_8 = "menu"; var currVal_9 = i0.ɵnov(_v, 15).active; _ck(_v, 14, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }); }
export function View_AppInputTextComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-input-text", [], null, null, null, View_AppInputTextComponent_0, RenderType_AppInputTextComponent)), i0.ɵdid(1, 49152, null, 0, i8.AppInputTextComponent, [i7.SecurityService], null, null)], null, null); }
var AppInputTextComponentNgFactory = i0.ɵccf("app-input-text", i8.AppInputTextComponent, View_AppInputTextComponent_Host_0, { contextId: "contextId", formControlObj: "formControlObj", classNames: "classNames", htmlType: "htmlType", placeholder: "placeholder", disabled: "disabled", keyPressCallback: "keyPressCallback" }, { keyDownEmitter: "keyDownEmitter" }, []);
export { AppInputTextComponentNgFactory as AppInputTextComponentNgFactory };
