import { AlertService } from '../../../ui/infrastructure/alert.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { FormBuilder, Validators } from '@angular/forms';
import { InstitutionDataService } from '../../../logic/services/institution-data.service';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { AddressComponent } from '../../../ui/controls/address.component';
var EditInstitutionBranchesComponent = /** @class */ (function () {
    function EditInstitutionBranchesComponent(institutionDataService, alertService, dataCachingService, waitingOverlay, lookupSourceService, fb, navigationService) {
        this.institutionDataService = institutionDataService;
        this.alertService = alertService;
        this.dataCachingService = dataCachingService;
        this.waitingOverlay = waitingOverlay;
        this.lookupSourceService = lookupSourceService;
        this.fb = fb;
        this.navigationService = navigationService;
        this.model = {};
        this.storing = false;
    }
    Object.defineProperty(EditInstitutionBranchesComponent.prototype, "contextFormGroup", {
        get: function () {
            this.ensureFormGroup();
            return this.model.contextFormGroup;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditInstitutionBranchesComponent.prototype, "id", {
        get: function () {
            return this._id;
        },
        set: function (id) {
            if (this._id !== id) {
                this._id = id;
                this.refreshFormDataInternal();
            }
        },
        enumerable: true,
        configurable: true
    });
    EditInstitutionBranchesComponent.prototype.isInvalid = function (fg, cname) {
        return FormHelper.isInvalid(fg, cname);
    };
    EditInstitutionBranchesComponent.prototype.ensureFormGroup = function (arr) {
        if (arr === void 0) { arr = []; }
        if (!this.model.contextFormGroup) {
            this.model.contextFormGroup = this.fb.group({
                branches: this.buildFormArray(arr)
            });
        }
    };
    EditInstitutionBranchesComponent.prototype.getBranchGroupDef = function (val) {
        if (val === void 0) { val = {}; }
        return {
            id: val.id,
            caption: [val.caption, Validators.required],
            isAddress: !!val.address,
            addressId: val.addressId,
            address: this.fb.group(AddressComponent.buildFormGroupDef(this.fb, this.model, val.address, false)),
            email: val.email,
            phone: val.phone,
            isVetClinic: val.isVetClinic,
        };
    };
    EditInstitutionBranchesComponent.prototype.buildFormArray = function (branchesData) {
        var _this = this;
        return this.fb.array(branchesData.map(function (item) { return _this.fb.group(_this.getBranchGroupDef(item)); }));
    };
    Object.defineProperty(EditInstitutionBranchesComponent.prototype, "branches", {
        get: function () {
            return this.contextFormGroup.get('branches');
        },
        enumerable: true,
        configurable: true
    });
    EditInstitutionBranchesComponent.prototype.store = function () {
        var _this = this;
        FormHelper.markAsSubmitted(this.contextFormGroup);
        if (!this.contextFormGroup.valid) {
            return;
        }
        this.storing = true;
        this.setNullIfMissingAddress(this.contextFormGroup.value.branches);
        this.institutionDataService.storeInstitutionBranches(this.id, this.contextFormGroup.value.branches, this.model.delete)
            .subscribe(function (val) {
            _this.storing = false;
            // обязательно обновляем форму из БД - чтобы загрузить идшники для новых бранчей
            _this.refreshFormData();
            // обновим поисковый кэш филиалов (чтобы комбобоксы заполнились актуальными данными, там где филиалы используются)
            _this.lookupSourceService.invalidateLookup('institution-branch' + _this.id.toString());
            FormHelper.setSingleFormGroupServerSideValidationErrors({}, _this.model, _this.contextFormGroup);
        }, function (error) {
            FormHelper.setSingleFormGroupServerSideValidationErrors(error, _this.model, _this.contextFormGroup);
            _this.storing = false;
        });
    };
    EditInstitutionBranchesComponent.prototype.cancelEdit = function () {
        this.dataCachingService.removeCachedData('EditInstitutionBranches', this.id.toString());
        this.refreshFormDataInternal();
    };
    EditInstitutionBranchesComponent.prototype.refreshFormData = function () {
        this.dataCachingService.removeCachedData('EditInstitutionBranches', this.id.toString());
        this.refreshFormDataInternal();
    };
    EditInstitutionBranchesComponent.prototype.refreshFormDataInternal = function () {
        var _this = this;
        this.waitingOverlay.StartWaiting();
        var existing = this.dataCachingService.getCachedData('EditInstitutionBranches', this.id.toString());
        if (existing) {
            this.model = existing;
            this.waitingOverlay.EndWaiting();
        }
        else {
            this.institutionDataService.getInstitutionBranchesForEdit(this.id).subscribe(function (data) {
                _this.model = { id: _this.id, delete: [] };
                _this.ensureFormGroup(data);
                _this.dataCachingService.addToCache('EditInstitutionBranches', _this.id.toString(), _this.model);
                _this.waitingOverlay.EndWaiting();
            }, function () {
                _this.waitingOverlay.EndWaiting();
            });
        }
    };
    EditInstitutionBranchesComponent.prototype.addBranch = function () {
        this.branches.push(this.fb.group(this.getBranchGroupDef()));
        this.contextFormGroup.markAsDirty();
    };
    EditInstitutionBranchesComponent.prototype.deleteBranch = function (index) {
        var idToDelete = this.branches.controls[index].get('id').value;
        if (idToDelete) {
            this.model.delete.push(idToDelete);
        }
        this.branches.removeAt(index);
        this.branches.markAsDirty();
    };
    EditInstitutionBranchesComponent.prototype.setNullIfMissingAddress = function (branches) {
        branches.forEach(function (branch) {
            if (!branch.isAddress || !branch.address.regionId) {
                branch.address = null;
                branch.addressId = null;
            }
        });
    };
    return EditInstitutionBranchesComponent;
}());
export { EditInstitutionBranchesComponent };
