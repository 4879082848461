import { ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { PstReportService } from '../../services/pst-report.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { StringHelper } from '../../../helpers/string-helper';
import { FormHelper } from '../../../ui/controls/form-helper';
import { PstReportStructureService } from '../../services/pst-report-structure.service';
import { NumericHelper } from '../../../helpers/numeric-helper';
var PstBranchCellEditComponent = /** @class */ (function () {
    function PstBranchCellEditComponent(lookupSourceService, navigationService, pstReportService, pstReportStructureService) {
        this.lookupSourceService = lookupSourceService;
        this.navigationService = navigationService;
        this.pstReportService = pstReportService;
        this.pstReportStructureService = pstReportStructureService;
        this.formatRuNumeric = StringHelper.formatRuNumeric;
        this.processMoneyKeypress = FormHelper.processMoneyKeypress;
        this.valueStr = '';
        this.valueLoaded = false;
        this.valueUpdated = false;
    }
    Object.defineProperty(PstBranchCellEditComponent.prototype, "originalCellValue", {
        get: function () {
            if (!this.reportIndicator || !this.indicatorTypeMeta || !this.indicatorLevel) {
                return undefined;
            }
            var rec = this.model.values[this.code];
            return rec && (rec.value || rec.value === 0) ? parseFloat(rec.value) : undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PstBranchCellEditComponent.prototype, "originalCellData", {
        get: function () {
            if (!this.reportIndicator || !this.indicatorTypeMeta || !this.indicatorLevel) {
                return undefined;
            }
            var rec = this.model.values[this.code];
            return rec;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PstBranchCellEditComponent.prototype, "proposedCellValue", {
        get: function () {
            if (!this.reportIndicator || !this.indicatorTypeMeta || !this.indicatorLevel) {
                return undefined;
            }
            var rec = this.model.values[this.code];
            return rec && (rec.proposedValue || rec.proposedValue === 0) ? parseFloat(rec.proposedValue) : undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PstBranchCellEditComponent.prototype, "cellValueStr", {
        get: function () {
            if (!this.valueLoaded) {
                var value = this.originalCellValue;
                if (this.proposedCellValue || this.proposedCellValue === 0) {
                    this.valueUpdated = true;
                    value = this.proposedCellValue;
                }
                if (!value && value !== 0) {
                    this.valueStr = '';
                }
                else {
                    this.valueStr = value.toString().replace('.', ',');
                }
                this.valueLoaded = true;
            }
            return this.valueStr;
        },
        set: function (strValue) {
            this.valueUpdated = true;
            this.valueStr = strValue;
        },
        enumerable: true,
        configurable: true
    });
    PstBranchCellEditComponent.prototype.setFocusedCell = function (indicatorLevel, colIndex, rowGroup) {
        this.model.__cl_focusedBranch = this.branch;
        this.model.__cl_focusedRowGroup = rowGroup;
        this.model.__cl_focusedIndicatorLevel = indicatorLevel;
        this.model.__cl_focusedIndicatorColIndex = colIndex;
        this.model.__cl_editingBranch = undefined;
        this.model.__cl_storing_error = undefined;
    };
    Object.defineProperty(PstBranchCellEditComponent.prototype, "code", {
        get: function () {
            return this.pstReportStructureService.getIndicatorValueCode(this.branch, this.indicatorLevel, this.reportIndicator, this.indicatorTypeMeta);
        },
        enumerable: true,
        configurable: true
    });
    PstBranchCellEditComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.hasOwnProperty('editing')) {
            if (this.editing) {
                setTimeout(function () {
                    if (_this.inputControl) {
                        _this.inputControl.nativeElement.focus();
                    }
                }, 100);
            }
            if (!this.editing && this.valueUpdated) {
                setTimeout(function () {
                    _this.updateCellValueInModel();
                    _this.valueLoaded = false;
                    _this.valueUpdated = false;
                }, 100);
            }
        }
    };
    PstBranchCellEditComponent.prototype.inputKeyDown = function ($event) {
        if ($event.key === 'Enter') {
            // заканчиваем редактирование с записью измененного значения
            this.model.__cl_editingBranch = undefined;
            this.inputControl.nativeElement.closest('table').focus();
            this.setFocusedCell(this.model.__cl_editingIndicatorLevel, this.findNextEditableIndex(this.model.__cl_editingIndicatorColIndex, this.model.__cl_editingIndicatorLevel.indicatorTypeMeta), this.model.__cl_editingRowGroup);
        }
        if ($event.key === 'Escape') {
            // заканчиваем редактирование без записи измененного значения
            this.valueLoaded = false;
            this.valueUpdated = false;
            this.model.__cl_editingBranch = undefined;
            this.inputControl.nativeElement.closest('table').focus();
        }
    };
    PstBranchCellEditComponent.prototype.updateCellValueInModel = function () {
        if (!this.reportIndicator || !this.indicatorTypeMeta || !this.indicatorLevel) {
            return;
        }
        var strValue = this.valueStr;
        strValue = (strValue || '').replace(/[^\d.,]/g, '')
            .replace(/[,]/g, '.')
            .replace(/[.](?=.*[.])/g, '');
        if (strValue.endsWith('.')) {
            strValue = strValue + '0';
        }
        var parsedVal;
        if (strValue) {
            parsedVal = NumericHelper.roundDecimal(parseFloat(strValue), this.indicatorTypeMeta.precision);
        }
        this.pstReportStructureService.storeIndicatorValue(this.model, this.reportIndicator, this.indicatorTypeMeta, this.indicatorLevel, parsedVal);
    };
    PstBranchCellEditComponent.prototype.findNextEditableIndex = function (startColIndex, indicatorTypeMetas) {
        if (!indicatorTypeMetas) {
            return startColIndex;
        }
        var colIndex = startColIndex;
        do {
            colIndex = colIndex + 1;
        } while (colIndex < indicatorTypeMetas.length && indicatorTypeMetas[colIndex].formula);
        return colIndex < indicatorTypeMetas.length ? colIndex : startColIndex;
    };
    PstBranchCellEditComponent.prototype.getIndicatorHint = function () {
        if (!this.originalCellData || !this.originalCellData.state || this.originalCellData.state === 1) {
            return undefined;
        }
        var stateTitlePart = this.originalCellData.state === 10 ? 'направлен' : 'принят';
        return "\u041F\u043E\u043A\u0430\u0437\u0430\u0442\u0435\u043B\u044C " + stateTitlePart + " " + StringHelper.getRuTimeWithSeconds(this.originalCellData.stateTime);
    };
    return PstBranchCellEditComponent;
}());
export { PstBranchCellEditComponent };
