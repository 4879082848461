import { Injectable } from '@angular/core';
import { AddressPersonFioCacheService } from './address-person-fio-cache.service';
import { Router } from '@angular/router';
import { LookupSourceService } from './lookup-source.service';

@Injectable({providedIn: 'root'})
export class BookmarkService {
  public searchBookmarks: any[] = [];
  public groupOperationBookmarks: any[] = [];
  public peopleCardBookmarks: any[] = [];
  public dictionaryBookmarks: any[] = [];

  constructor(private cacheService: AddressPersonFioCacheService, private router: Router,
              private lookupService: LookupSourceService) {

  }

  public get topLevelBookmarks(): any[] {
    const retVal = [];
    this.peopleCardBookmarks.forEach(bm => {
      if (!bm.parentTag) {
        retVal.push(bm);
      }
    });
    return retVal;
  }

  public getChildBookmarks(parentTag: string): any[] {
    const retVal = [];
    this.peopleCardBookmarks.forEach(bm => {
      if (bm.parentTag === parentTag) {
        retVal.push(bm);
      }
    });
    return retVal;
  }

  public addOpenCardBookmark(uniqueTag: string, title: string, route: any[], icon: string = 'user',
                             skipIfNotExists?: boolean, parentTag?: string) {

    if (!route || !route[0]) {
      throw new Error('При добавлении закладки не указан или некорректно указан маршрут (route)');
    }

    const existing = this.peopleCardBookmarks.find(value => value.uniqueTag === uniqueTag
      && value.route[0] === route[0]);

    if (!existing) {
      if (!skipIfNotExists) {
        this.peopleCardBookmarks.push({
          title: title,
          route: route,
          uniqueTag: uniqueTag,
          icon: icon,
          parentTag: parentTag
        });
      }
    } else {
      existing.title = title;
      existing.route = route;
      existing.parentTag = parentTag;
      existing.icon = icon;
    }


    if (existing && parentTag && parentTag.startsWith('/stable-edit')) {
      this.refreshStableCardBookmark(+parentTag.substring(12), undefined);
    }

    if (existing && parentTag && parentTag.startsWith('/animal-edit')) {
      this.refreshAnimalCardBookmark(+parentTag.substring(12), undefined, undefined);
    }
  }

  public addGroupOperationBookmark(title: string, uniqueTag: string, route: any[]) {
    if (!this.groupOperationBookmarks.find(value => value.uniqueTag === uniqueTag)) {
      this.groupOperationBookmarks.push({title: title, route: route, uniqueTag: uniqueTag});
    }
  }

  public removeOpenCardBookmark(route: any[], uniqueTag: string) {
    let bm: any;
    const ix = this.peopleCardBookmarks.findIndex(value => {
      bm = value;
      return value.uniqueTag === uniqueTag && value.route[0] === route[0];
    });

    if (ix >= 0) {
      this.peopleCardBookmarks.splice(ix, 1);

      if (bm.route.length > 0 && this.router.url.startsWith(bm.route[0] + '/' + uniqueTag)) {
        this.router.navigate(['']);
      }
    }

    this.getChildBookmarks(route[0] + uniqueTag).forEach(el => {
      this.removeOpenCardBookmark(el.route, el.uniqueTag);
    });
  }

  public removeGroupOperationBookmark(uniqueTag: string) {
    let bm: any;
    const ix = this.groupOperationBookmarks.findIndex(value => {
      bm = value;
      return value.uniqueTag === uniqueTag;
    });

    if (ix >= 0) {
      this.groupOperationBookmarks.splice(ix, 1);

      if (bm.route.length > 0 && this.router.url.startsWith(bm.route[0])) {
        this.router.navigate(['']);
      }
    }
  }

  public removeSearchBookmark(title: string) {
    let bm: any;
    const ix = this.searchBookmarks.findIndex(value => {
      bm = value;
      return value.title === title;
    });

    if (ix >= 0) {
      this.searchBookmarks.splice(ix, 1);

      if (bm.route.length > 0 && this.router.url === bm.route[0] + '/' + encodeURIComponent(bm.route[1])) {
        this.router.navigate(['']);
      }
    }
  }

  public refreshInstitutionCardBookmark(institutionId: number) {
    if (!institutionId) {
      return;
    }

    this.addOpenCardBookmark(institutionId.toString(),
      `Карточка #${institutionId}`,
      ['/institution-edit', +institutionId], 'bank');

    setTimeout(() =>
      this.cacheService.getInstitution(+institutionId).subscribe(institution => {
        this.addOpenCardBookmark(institutionId.toString(),
          institution.shortCaption,
          ['/institution-edit', +institutionId], 'bank');
      }), 300);
  }

  public refreshAgentCardBookmark(agentId: number) {
    if (!agentId) {
      return;
    }

    this.addOpenCardBookmark(agentId.toString(),
      `Карточка #${agentId}`,
      ['/agent-edit', +agentId], 'users');

    setTimeout(() =>
      this.cacheService.getAgent(+agentId).subscribe(agent => {
        this.addOpenCardBookmark(agentId.toString(),
          agent.shortCaption,
          ['/agent-edit', +agentId], 'users');
      }), 300);
  }

  public refreshProductCardBookmark(productId: number) {
    if (!productId) {
      return;
    }

    this.addOpenCardBookmark(productId.toString(),
      `Карточка #${productId}`,
      ['/product-edit', +productId], 'truck');

    setTimeout(() =>
      this.cacheService.getProduct(+productId).subscribe(product => {
        this.addOpenCardBookmark(productId.toString(),
          product.caption,
          ['/product-edit', +productId], +product['comments'] === 4 ? 'recycle' : 'truck');
        }), 300);
  }

  public refreshProductTransactionCardBookmark(transactionId: number) {
    if (!transactionId) {
      return;
    }

    this.addOpenCardBookmark(transactionId.toString(),
      `Карточка транзакции #${transactionId}`,
      ['/product-transaction', +transactionId], 'organization');

    this.cacheService.getProductTransaction(+transactionId).subscribe(product => {
      this.addOpenCardBookmark(transactionId.toString(),
        product.caption,
        ['/product-transaction', +transactionId], 'organization');
    });
  }

  public refreshStableCardBookmark(stableId: number, newAgentId: any) {
    if (!stableId) {
      return;
    }

    this.addOpenCardBookmark(stableId.toString(),
      `Карточка #${stableId}`,
      ['/stable-edit', +stableId, {newAgentId: newAgentId}], 'building');

    setTimeout(() =>
      this.cacheService.getStable(+stableId).subscribe(agent => {
        this.addOpenCardBookmark(stableId.toString(),
          agent.caption,
          ['/stable-edit', +stableId, {newAgentId: newAgentId}], 'building');
      }), 300);
  }

  public refreshDrugCardBookmark(drugId: number) {
    if (!drugId) {
      return;
    }

    this.addOpenCardBookmark(drugId.toString(),
      `Карточка #${drugId}`,
      ['/drug-edit', +drugId], 'flask');

    setTimeout(() =>
      this.cacheService.getDrug(+drugId).subscribe(drug => {
        this.addOpenCardBookmark(drugId.toString(),
          drug.caption,
          ['/drug-edit', +drugId], 'flask');
      }), 300);
  }

  public refreshAnimalCardBookmark(animalId: number, newAgentId: any, newStableId: any) {
    if (!animalId) {
      return;
    }

    this.cacheService.getAnimal(+animalId).subscribe(animal => {
      this.addOpenCardBookmark(animalId.toString(),
        animal.caption,
        ['/animal-edit', +animalId, {newAgentId: newAgentId, newStableId: newStableId}], 'piggy-bank',
        undefined, animal.stableId ? '/stable-edit' + animal.stableId.toString() : undefined);
    });
  }

  public refreshAnimalEventCardBookmark(animalEventId: number) {

    if (!animalEventId) {
      return;
    }

    this.addOpenCardBookmark(animalEventId.toString(),
      `Карточка #${animalEventId}`,
      ['/animal-event-edit', +animalEventId, {}], 'color-picker');

    setTimeout(() =>
      this.cacheService.getAnimalEvent(+animalEventId).subscribe(animalEvent => {

        let parentTag;

        if (animalEvent.animalId) {
          parentTag = '/animal-edit' + animalEvent.animalId.toString();
        } else if (animalEvent.stableId) {
          parentTag = '/stable-edit' + animalEvent.stableId.toString();
        }

        this.addOpenCardBookmark(animalEventId.toString(),
          animalEvent.caption,
          ['/animal-event-edit', +animalEventId, {}], 'color-picker',
          undefined, parentTag);
      }), 300);
  }

  public addDictionaryBookmark(title: string, route: any[]) {
    if (!this.dictionaryBookmarks.find(value => value.title === title)) {
      this.dictionaryBookmarks.push({title: title, route: route});
    }
  }

  public removeDictionaryBookmark(title: string) {
    const ix = this.dictionaryBookmarks.findIndex(value => {
      return value.title === title;
    });

    if (ix >= 0) {
      const bm = this.dictionaryBookmarks.splice(ix, 1)[0];

      if (bm.route.length > 0 && this.router.isActive(bm.route.join('/'), false)) {
        this.router.navigate(['']);
      }
    }
  }

  public refreshQueryViewBookmark(queryId: number, caption: string) {
    if (!queryId) {
      return;
    }

    this.addOpenCardBookmark(queryId.toString(), caption, ['/query-view', +queryId], 'bolt');
  }

  public refreshPstReportBookmark(reportId: number) {
    if (!reportId) {
      return;
    }

    this.addOpenCardBookmark(reportId.toString(),
      `Отчет #${reportId}`,
      ['/pst-report-edit', +reportId], 'line-chart');

    setTimeout(() =>
      this.cacheService.getPstReport(+reportId).subscribe(animalEvent => {
        this.addOpenCardBookmark(reportId.toString(),
          animalEvent.caption,
          ['/pst-report-edit', +reportId], 'line-chart');
      }), 300);
  }


  public refreshQuarantineCardBookmark(id: number) {
    if (!id) {
      return;
    }

    this.lookupService.getLookupCaption(id, 'quarantine')
      .subscribe(caption => this.addOpenCardBookmark(id.toString(),
        caption, ['/quarantine-edit', +id], 'flame'));
  }
}
