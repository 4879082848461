
      <ag-grid-angular #agGrid style="width: 100%; height: 100%;" class="ag-theme-balham" [pagination]="pagination"
                       [localeTextFunc]="agGridLocaleTextFunc" [getRowStyle]="getRowStyle" [suppressPropertyNamesCheck]="true"
                       [defaultColDef]="defaultColDef ? defaultColDef : defColDef" (gridReady)="onGridReady($event)"
                       (sortChanged)="sortedChange()" (filterChanged)="filterChange()" (columnPinned)="columnPinnedChange()"
                       (columnVisible)="visibleChange()" (columnMoved)="columnMovedChange()"
                       (rowSelected)="rowSelected.emit($event)" (columnResized)="columnResizeChange()"
                       [rowData]="rowData || []" [overlayNoRowsTemplate]="'Нет данных для отображения'" [enableBrowserTooltips]="true"
                       [rowMultiSelectWithClick]="rowMultiSelectWithClick" [rowSelection]="rowSelection"
                       (rowClicked)="rowClickedEvent($event)" (rowDoubleClicked)="rowDoubleClickedEvent($event)"
                       (cellClicked)="cellClicked.emit($event)" (selectionChanged)="selectionChanged.emit($event)"
                       [columnDefs]="columnDefs" [getRowNodeId]="getRowNodeId" (gridSizeChanged)="gridSizeChanged.emit($event)"
                       [frameworkComponents]="frameworkComponents" [paginationAutoPageSize]="paginationAutoPageSize"
                       [masterDetail]="true" [detailRowHeight]="detailRowHeight" [detailCellRenderer]="detailCellRenderer"
                       [icons]="icons" [autoGroupColumnDef]="autoGroupColumnDef" [groupMultiAutoColumn]="true" [groupUseEntireRow]="true"
                       [groupRowRenderer]="groupRowRenderer" [groupRowRendererParams]="groupRowRendererParams"
                       [excelStyles]="excelStyles" (rowDataChanged)="rowDataChanged($event)" [statusBar]="statusBar"
                       (contextmenu)="clickContextMenu($event)" [suppressContextMenu]="true" [preventDefaultOnContextMenu]="true"
                       (modelUpdated)="modelUpdated.emit($event)" [suppressColumnMoveAnimation]="true"
                       [pinnedBottomRowData]="pinnedBottomRowData" [pinnedTopRowData]="pinnedTopRowData">
      </ag-grid-angular>
      <app-grid-context-menu [gridApi]="api" [uniqueGridName]="uniqueGridName"
                             [positionTop]="contextMenuPositionTop" [positionLeft]="contextMenuPositionLeft">
      </app-grid-context-menu>