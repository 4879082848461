
    <div class="form-group itech-group-whole-line" [formGroup]="item.formGroup">
      <label>
      </label>
      <div class="radio-inline">
        <input type="radio" id="{{metaGroupName}}Kind1{{groupId}}"
               formControlName="{{metaGroupName}}Kind" [value]="1">
        <label for="{{metaGroupName}}Kind1{{groupId}}">Разрешить:</label>
      </div>
      <div class="radio-inline">
        <input type="radio" id="{{metaGroupName}}Kind2{{groupId}}"
               formControlName="{{metaGroupName}}Kind" [value]="2">
        <label for="{{metaGroupName}}Kind2{{groupId}}">Разрешить все кроме:</label>
      </div>
      <div class="radio-inline">
        <input type="radio" id="{{metaGroupName}}Kind3{{groupId}}"
               formControlName="{{metaGroupName}}Kind" [value]="3">
        <label for="{{metaGroupName}}Kind3{{groupId}}">Не проверять:</label>
      </div>
    </div>

    <div class="form-group itech-group-whole-line" *ngIf="item.toggled && item.func.metaNameCombineKey1"
         id="{{metaGroupName}}dictionary{{groupId}}">
      <label>
      </label>
      <div *ngFor="let parent of (lookupParent() | async)"
           [style.width]="parent.expanded ? '100%' : undefined">
        <button type="button" class="btn btn-outline"
                [disabled]="item.formGroup.get(metaGroupName + 'Kind').value == 3? true: undefined"
                style="background:none;border:none;margin:0;text-transform:none;color:black;letter-spacing:normal"
                (click)="parent.expanded = !parent.expanded">
          {{ parent.caption }}
          <span style="font-size:11px;color:blue">
              ({{selectedIds[parent.id] ? (selectedIds[parent.id].length) : (0) }})
          </span>
          <clr-icon *ngIf="parent.expanded" shape="angle up"></clr-icon>
          <clr-icon *ngIf="!parent.expanded" shape="angle down"></clr-icon>
        </button>
        <div *ngIf="parent.expanded">
          <app-checkbox-select [lookupName]="lookupName + parent.id" [contextControlName]="metaGroupName"
                               [contextFormGroup]="item.formGroup" [sorted]="true" [nameId]="'uniqueId'"
                               [disabled]="item.formGroup.get(metaGroupName + 'Kind').value == 3? true: undefined">
          </app-checkbox-select>
        </div>
      </div>
    </div>
  