import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataCachingService } from '../../logic/services/data-caching.service';
import { BookmarkService } from '../../logic/services/bookmark.service';
import { AppNavigationService } from "../../logic/services/app-navigation.service";

@Component({
  selector: 'app-edit-stable',
  templateUrl: './edit-stable.component.html'
})
export class EditStableComponent implements OnInit {

  @Input() model: any = EditStableComponent.createDefaultModel();
  @Input() id: number;
  newAgentId: number;

  static createDefaultModel(): any {
    return {
      commonTabActive: true,
      animalsTabActive: false,
      eventsTabActive: false,
      productsTabActive: false,
      productSubtype: false,
      productionTemplate: false,
    };
  }

  constructor(
    private route: ActivatedRoute,
    private bookmarkService: BookmarkService,
    private dataCachingService: DataCachingService,
    private appNavigationService: AppNavigationService,
  ) {
  }

  ngOnInit() {

    this.route.paramMap
      .subscribe(params => {
        this.id = parseInt(params.get('id'), 10);
        this.newAgentId = !isNaN(+params.get('newAgentId')) ? +params.get('newAgentId') : undefined;

        this.appNavigationService.updateLastAccessObjects(this.id, 'stable');

        const existing = this.dataCachingService.getCachedData('EditStable', this.id.toString());

        this.bookmarkService.refreshStableCardBookmark(this.id, this.newAgentId);

        if (existing) {
          this.model = existing;
        } else {
          this.model = EditStableComponent.createDefaultModel();
          this.model.newAgentId = this.newAgentId;
          this.dataCachingService.addToCache('EditStable', this.id.toString(), this.model);
        }
      });
  }

  showLoadedAnimalCount(): string {
    const existing = this.dataCachingService.getCachedData('EditStableAnimals', this.id.toString());

    if (!existing || !existing.searchResults) {
      return '';
    } else {
      let count = 0;
      existing.searchResults.forEach(el => count += el.animalGroup ? (el.totalCount || 0) : 1);
      return ' (' + count.toString() + ')';
    }
  }
}
