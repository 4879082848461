import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/internal/operators';
import { DateHelper } from '../../helpers/date-helper';
import { StringHelper } from '../../helpers/string-helper';

@Injectable()
export class MetadataService {

  private availableFormatsForPreviewFile = [];

  constructor(private http: HttpClient) {
    this.http.get(environment.api + '/files/available-formats-preview')
      .pipe(map((response: any) => response as any[]))
      .subscribe(res => this.availableFormatsForPreviewFile = res);
  }

  public isAvailableFormatsForPreviewFile(format: string): boolean {
    return format && this.availableFormatsForPreviewFile.includes(format);
  }

  public getMetadata(metadataName: string): Observable<any[]> {
    const qstring = environment.api + '/metadata/' + metadataName;
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  public getMetadataSingle(metadataName: string, id: string): Observable<any> {
    const qstring = environment.api + '/metadata/' + metadataName + '/' + id;
    return this.http.get(qstring);
  }

  public getAddress(id: number): Observable<any> {
    return this.http.get(environment.api + '/address/' + id);
  }

  public getUserForLookup2(ids: number[]): Observable<any[]> {
    const qstring = environment.api + '/security/user/lookup';
    return this.http.post(qstring, ids)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  public getCitiesLookup(): Observable<any[]> {
    const qstring = environment.api + '/address/city-lookup';
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  public getCitiesInRegionLookup(regionId: number): Observable<any[]> {
    const qstring = environment.api + '/address/city-lookup/' + regionId;
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  public getCitiesLookupEx(): Observable<any[]> {
    const qstring = environment.api + '/address/city-lookup-with-comments';
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  public getStreetsLookup(regionId: number, cityId: number): Observable<any[]> {
    const qstring = environment.api + '/address/street-lookup/' + regionId + '/' + cityId;
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  public preloadFile(uri: string): Observable<string> {
    const qstring = environment.api + '/files/preload?uri=' + encodeURIComponent(uri);
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response.data as string;
      }));
  }

  public previewFile(preloadId: string): Observable<string> {
    const qstring = environment.api + '/files/preview?uri=' + encodeURIComponent(preloadId);
    return this.http.get(qstring, { responseType: 'arraybuffer' as 'json'})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  public getFileArrayBuffer(preloadId: string): Observable<string> {
    const qstring = environment.api + '/files/get?preloadId=' + encodeURIComponent(preloadId);
    return this.http.get(qstring, { responseType: 'arraybuffer' as 'json'})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  public createReport(reportParams: any): Observable<string> {
    const qstring = environment.api + '/reporting/create';
    return this.http.post(qstring, reportParams)
      .pipe(map((response: any) => {
        return response.data as string;
      }));
  }

  public createJsonReport(reportParams: any): Observable<any[]> {
    const qstring = environment.api + '/reporting/create-json';
    return this.http.post(qstring, reportParams)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  public getReports(reportKind: string): Observable<any[]> {
    const qstring = environment.api + '/reporting/meta/by-kind/' + encodeURIComponent(reportKind);
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  public getAllReports(): Observable<any[]> {
    const qstring = environment.api + '/reporting/meta/all';
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  public storeReportMeta(reportMeta: any, file: any): Observable<any> {
      const qstring = environment.api + '/reporting/meta/store';

    const formData = new FormData();

    formData.append('file', file);
    formData.append('meta', new Blob([JSON.stringify(reportMeta)], { type: 'application/json' }));

    return this.http.post(qstring, formData)
      .pipe(map((response: any) => {
        return response.data;
      }));
  }

  public deleteReportMeta(id: number) {
    const qstring = environment.api + '/reporting/meta/delete/' + id;
    return this.http.post(qstring, undefined);
  }

  public getAllRoles(): Observable<any[]> {
    const qstring = environment.api + '/security/meta/roles/all';
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  public getRoleForEdit(id: any): Observable<any> {
    const qstring = environment.api + '/security/meta/roles/' + id;
    return this.http.get(qstring);
  }

  public storeRole(data: any): Observable<any> {
    const qstring = environment.api + '/security/meta/roles/store';
    return this.http.post(qstring, data).pipe(map(val => (val as any).data));
  }

  public deleteSecurityRole(id: any): Observable<any> {
    const qstring = environment.api + '/security/meta/roles/delete/' + id;
    return this.http.post(qstring, null);
  }

  public getAllFunctions(): Observable<any[]> {
    const qstring = environment.api + '/security/meta/functions/all';
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  public updateMeta(metadataName: string, data: any, id: any): Observable<any> {
    const qstring = environment.api + '/metadata/' + metadataName + '/update/' + id;
    return this.http.post(qstring, data)
      .pipe(map((response: any) => {
        return response as any;
      }));
  }

  public createMeta(metadataName: string, data: any): Observable<any> {
    const qstring = environment.api + '/metadata/' + metadataName;
    return this.http.post(qstring, data)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  public deleteMeta(metadataName: string, id: any): Observable<any[]> {
    const qstring = environment.api + '/metadata/' + metadataName + '/delete/' + id;
    return this.http.post(qstring, null)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  public getIntegrationLog(opName: string, dateX: Date): Observable<any[]> {
    const qstring = environment.api + '/integration-log/read/' + opName + '/' + StringHelper.getISODate(dateX);
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  public searchConversations(params: any): Observable<any[]> {
    const qstring = environment.api + '/integration-log/search/';
    return this.http.post(qstring, params)
        .pipe(map((response: any) => {
          return response as any[];
        }));
  }

  public updateConversation(syncState: any) {
    const qstring = environment.api + '/integration-log/update-sync/';
    return this.http.post(qstring, syncState)
      .pipe(map((response: any) => {
        return response as any;
      }));
  }

  public tryDownloadLogsConversation(syncState: any) {
    const qstring = `${environment.api}/integration-log/download-sync-logs/${syncState.conversationTypeId}/${syncState.targetObjId}`;
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response.data as any;
      }));
  }

  public getStatistic(): Observable<any> {
    const qstring = environment.api + '/integration-log/statistic/';
    return this.http.get(qstring);
  }

  public getEmployeeStatistic(typeId: number, dateFrom: Date, dateTo: Date): Observable<any[]> {
    const qstring = environment.api + '/reporting/employee-statistic/' + typeId + '/' +
      StringHelper.getISODate(dateFrom) + '/' + StringHelper.getISODate(dateTo);
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response.data as any[];
      }));
  }

  public getSyncState(targetObjId: number, conversationTypeId: number): Observable<any> {
    const qstring = environment.api + '/integration-log/sync-state/' + targetObjId + '/' + conversationTypeId;
    return this.http.get(qstring);
  }

  public forceMercuryNextTimeSync(targetObjId: number, conversationTypeId: number): Observable<any> {
    const qstring = environment.api + '/integration-log/force-sync/' + targetObjId + '/' + conversationTypeId;
    return this.http.get(qstring);
  }

  public uploadFile(uri: string, file: any): Observable<any> {
    const qstring = environment.api + uri;

    const formData = new FormData();

    formData.append('file', file);
    formData.append('fileName', new Blob([file.name], { type: 'text/plain' }));

    return this.http.post(qstring, formData)
      .pipe(map((response: any) => {
        return response.data;
      }));
  }

  public clearExpertiseIndicatorTypeIdsForProductSubtype(productTypeId: number, id: number): Observable<any> {
    const qstring = environment.api + '/metadata/product-subtype/clear-expertise-indicators/' + productTypeId + '/' + id;
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response as any;
      }));
  }

  public getListUserManuals(): Observable<any[]> {
    const qstring = environment.api + '/user-manuals/all';
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  public deleteUserManual(fileName: string): Observable<any[]> {
    const qstring = environment.api + '/user-manuals/delete?fileName=' + encodeURIComponent(fileName);
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  public getGatheredOperations(): Observable<any[]> {
    const qstring = environment.api + '/system/get-gathered-operations';
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response.data as any[];
      }));
  }

  public getRunningOperations(): Observable<any[]> {
    const qstring = environment.api + '/system/get-running-operations';
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response.data as any[];
      }));
  }

  public getOperationTelemetry(opCode: string, factor: number): Observable<any[]> {
    const qstring = environment.api + '/system/get-operation-telemetry';
    return this.http.post(qstring, {opCode: opCode, factor: factor})
      .pipe(map((response: any) => {
        return response.data as any[];
      }));
  }

  public getExtraHolidays(year: number): Observable<any> {
    const qstring = environment.api + '/metadata/calendar/holidays/' + year.toString();
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        const datesMap = {};
        response.forEach(el => {
          datesMap[StringHelper.getISODate(new Date(el))] = true;
        });
        return datesMap;
      }));
  }

  public getExtraWorkdays(year: number): Observable<any> {
    const qstring = environment.api + '/metadata/calendar/workdays/' + year.toString();
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        const datesMap = {};
        response.forEach(el => {
          datesMap[StringHelper.getISODate(new Date(el))] = true;
        });
        return datesMap;
      }));
  }

  public addWorkday(date: Date): Observable<any> {
    const qstring = environment.api + '/metadata/calendar/add-workday/' + StringHelper.getISODate(date);
    return this.http.post(qstring, {});
  }

  public deleteWorkday(date: Date): Observable<any> {
    const qstring = environment.api + '/metadata/calendar/delete-workday/' + StringHelper.getISODate(date);
    return this.http.post(qstring, {});
  }

  public addHoliday(date: Date): Observable<any> {
    const qstring = environment.api + '/metadata/calendar/add-holiday/' + StringHelper.getISODate(date);
    return this.http.post(qstring, {});
  }

  public deleteHoliday(date: Date): Observable<any> {
    const qstring = environment.api + '/metadata/calendar/delete-holiday/' + StringHelper.getISODate(date);
    return this.http.post(qstring, {});
  }
}
