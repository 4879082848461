import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { SlaughteryViewFormComponent } from './slaughtery-view-form.component';
import { environment } from '../../../../environments/environment';
import { MetadataService } from '../../../logic/services/metadata.service';
import { AppModalPreviewFileComponent } from '../../../ui/controls/app-modal-preview-file.component';

@Component({
  selector: 'app-slaughtery-view',
  templateUrl: './slaughtery-view.component.html'
})
export class SlaughteryViewComponent implements OnInit {

  model = SlaughteryViewFormComponent.getDefaultModel();

  constructor(private route: ActivatedRoute,
              private dataCachingService: DataCachingService,
              public appNavigationService: AppNavigationService,
              private fb: FormBuilder,
              private metadataService: MetadataService) {
  }

  ngOnInit() {

    this.route.paramMap
      .subscribe(() => {

        const existing = this.dataCachingService.getCachedData('SlaughteryViewComponent', '1');

        if (existing) {
          this.model = existing;
        } else {
          this.model = SlaughteryViewFormComponent.getDefaultModel(this.fb);
          this.dataCachingService.addToCache('SlaughteryViewComponent', '1', this.model);
        }
      });
  }

  printSlaughtery() {

    if (!this.model || !this.model.filterFormGroup.valid) {
      return;
    }

    this.metadataService.createReport(this.getReportParams(true)).subscribe({
      next: fileHash => {
        this.appNavigationService.previewFile(AppModalPreviewFileComponent, {
          fileHash: fileHash,
          buildSourceFormatFunc: () => this.metadataService.createReport(this.getReportParams(false))
            .subscribe(hash => window.open(hash.startsWith('http')
              ? hash
              : environment.api + '/files/get?preloadId=' + encodeURIComponent(hash))),
        });
      }
    });
  }

  getReportParams(preview: boolean) {
    return {
      reportId: 76, // конкретный отчет
      objId1: undefined,
      objId2: undefined,
      objId3: undefined,
      customParamValues: [
        {customParamCode: 'dateReport', paramDateValue: this.model.filterFormGroup.get('date').value},
        {customParamCode: 'institutionId', paramIntValue: this.model.filterFormGroup.get('institutionId').value}],
      preview: preview,
    };
  }
}
