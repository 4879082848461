<div class="itech-common-wrap" id="interceptNoAccess">
  <div style="width: 100%">
    <app-grid [pagination]="false" [uniqueGridName]="'EditStableProductSubtypeGrid'" (rowClicked)="editRow($event)"
              [rowData]="getRowData()" [columnDefs]="gridColumnDefs" [getRowStyle]="getRowStyle"
              [exportFileName]="'Номенклатура продукции ПО ' + (stableId|lookup:'stable'|async)"></app-grid>
  </div>

  <div class="itech-form-actions">
    <div>
      <button class="btn btn-secondary" (click)="refresh()">
        <clr-icon shape="refresh"></clr-icon>&nbsp;Обновить
      </button>
      <button class="btn btn-secondary" (click)="updateFromMercuryAndRefreshFormData()">
        <clr-icon shape="refresh"></clr-icon>&nbsp;Обновить из Меркурия
      </button>
    </div>

    <div>
      <div class="checkbox">
        <input type="checkbox" id="showHistoryCb" [(ngModel)]="isShowDeleted">
        <label for="showHistoryCb">Показывать историю</label>
      </div>
    </div>
  </div>
</div>


<clr-modal [(clrModalOpen)]="editModalOpened" *ngIf="editModalOpened" [clrModalClosable]="false" [clrModalSize]="'lg'">
  <h3 class="modal-title">Редактирование записи
    <span *ngIf="currentIsNew" style="color:green"> [Новая]</span></h3>
  <div class="modal-body" style="min-height: 100px;">
    <div class="form compact" [formGroup]="currentFormGroup">
      <section class="form-block itech-block-normal">
        <div class="form-group">
          <label for="caption">Наименование</label>
          <span>{{currentFormGroup.get('caption').value}}</span>
        </div>

        <div class="form-group">
          <label for="calcExpireDate">Формула расчёта срока годности</label>
          <label for="calcExpireDate" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md tooltip-top-left"
                 [class.invalid]="isInvalid(currentFormGroup, 'calcExpireDate')">
            <input type="text" id="calcExpireDate" placeholder="Формула" class="itech-control-xlarge"
                   formControlName="calcExpireDate">
            <app-validation-tooltip [input]="currentFormGroup.controls['calcExpireDate']"></app-validation-tooltip>
          </label>
        </div>
      </section>
    </div>
  </div>
  <div class="modal-footer itech-form-actions">
    <div>
    </div>
    <div>
      <button type="button" class="btn btn-primary" (click)="storeRecord()">
        <clr-icon shape="check"></clr-icon>&nbsp;Сохранить
      </button>
      <button type="button" class="btn btn-warning" (click)="editModalOpened = false">
        <clr-icon shape="undo"></clr-icon>&nbsp;Отмена
      </button>
    </div>
  </div>
</clr-modal>
