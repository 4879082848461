import { AlertService } from '../../../ui/infrastructure/alert.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { StableDataService } from '../../../logic/services/stable-data.service';
import { StringHelper } from '../../../helpers/string-helper';
import { StableSearchModalComponent } from '../../edit-stable/search/stable-search-modal.component';
import { JobRunnerUiService } from '../../../ui/infrastructure/job-runner-ui.service';
var EditAgentStablesComponent = /** @class */ (function () {
    function EditAgentStablesComponent(stableDataService, dataCachingService, waitingOverlay, lookupSourceService, navigationService, alertService, jobRunnerUiService) {
        var _this = this;
        this.stableDataService = stableDataService;
        this.dataCachingService = dataCachingService;
        this.waitingOverlay = waitingOverlay;
        this.lookupSourceService = lookupSourceService;
        this.navigationService = navigationService;
        this.alertService = alertService;
        this.jobRunnerUiService = jobRunnerUiService;
        this.model = {};
        this.isInvalid = FormHelper.isInvalid;
        this.gridColumnDefs = [
            {
                headerName: '', field: '', width: 50,
                checkboxSelection: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true,
            },
            {
                headerName: '№', field: 'id', width: 90,
            },
            {
                headerName: 'Наименование', field: 'caption', width: 250,
                cellRenderer: function (params) {
                    var animalStateHtml = params.data.overdueEventsCount && params.data.overdueEventsCount > 0
                        ? '<clr-icon shape="history" style="color:red"' +
                            ' title="Имеются просроченные мероприятия: ' + params.data.overdueEventsCount + '"></clr-icon>'
                        : '<clr-icon shape="check" style="color:green" title="Просроченные мероприятия отсутствуют"></clr-icon>';
                    return animalStateHtml + '&nbsp;' + params.value;
                }
            },
            {
                headerName: 'Состояние ПО',
                field: 'overdueEventsCount',
                hide: true,
                suppressColumnsToolPanel: true,
                suppressFiltersToolPanel: true,
            },
            {
                headerName: 'Вид', field: 'stableTypeId', width: 180,
                valueFormatter: function (params) { return params.value ? _this.stableTypeIdLookup[params.value] : '<не указан>'; },
            },
            {
                headerName: 'Площадь, кв.м.', field: 'square', width: 140,
            },
            {
                headerName: 'Кадастровый номер', field: 'cadNo', width: 180,
            },
            {
                headerName: 'Адрес', field: 'address', width: 350,
            },
            {
                headerName: 'Владение с', field: 'dateFrom', width: 120,
                valueFormatter: function (params) { return StringHelper.getRuDate(params.value); },
            },
            {
                headerName: 'Владение по', field: 'dateTo', width: 120,
                valueFormatter: function (params) { return StringHelper.getRuDate(params.value); },
            },
        ];
        this.stableTypeIdLookup = {};
        this.lookupSourceService.getLookupObj('stable-type').subscribe(function (lookup) { return _this.stableTypeIdLookup = lookup; });
    }
    Object.defineProperty(EditAgentStablesComponent.prototype, "id", {
        get: function () {
            return this._id;
        },
        set: function (id) {
            if (this._id !== id) {
                this._id = id;
                this.refreshFormDataInternal();
            }
        },
        enumerable: true,
        configurable: true
    });
    EditAgentStablesComponent.prototype.refreshFormData = function () {
        this.dataCachingService.removeCachedData('EditAgentStables', this.id.toString());
        this.refreshFormDataInternal();
    };
    EditAgentStablesComponent.prototype.refreshFormDataInternal = function () {
        var _this = this;
        this.waitingOverlay.StartWaiting();
        var existing = this.dataCachingService.getCachedData('EditAgentStables', this.id.toString());
        if (existing) {
            this.model = existing;
            this.waitingOverlay.EndWaiting();
        }
        else {
            this.stableDataService.searchByAgent(this.id, this.model.showHistory)
                .subscribe({
                next: function (data) {
                    _this.model = { searchResults: data, showHistory: _this.model.showHistory };
                    _this.dataCachingService.addToCache('EditAgentStables', _this.id.toString(), _this.model);
                    _this.waitingOverlay.EndWaiting();
                },
                error: function () {
                    _this.waitingOverlay.EndWaiting();
                }
            });
        }
    };
    EditAgentStablesComponent.prototype.getRowStyle = function (params) {
        if (params.data && (params.data.dateTo || params.data.deletedTime || params.data.defaultHidden)) {
            return { color: 'silver' };
        }
    };
    EditAgentStablesComponent.prototype.addExisting = function () {
        var _this = this;
        this.navigationService.searchStable(StableSearchModalComponent).subscribe(function (val) {
            if (val) {
                _this.navigationService.performStableEditing(val, _this.id);
            }
        });
    };
    EditAgentStablesComponent.prototype.addNew = function () {
        this.navigationService.performStableCreation(this.id);
    };
    EditAgentStablesComponent.prototype.updateFromMercuryAndRefreshFormData = function () {
        var _this = this;
        this.jobRunnerUiService.runOperation(4 /*Обновление списка поднадзорных объектов хозяйствующего субъекта*/, {
            type: 'singleAgentStableSync',
            agentId: this.id
        }, undefined, undefined, true)
            .subscribe({
            next: function (data) {
                var dataParsed = data ? JSON.parse(data) : [];
                if (dataParsed.length) {
                    _this.alertService.success('Поднадзорные объекты обновлены (добавлено, изменено - ' +
                        dataParsed.length + ' объектов)');
                    _this.refreshFormData();
                }
                else {
                    _this.alertService.success('Все сведения актуальны - обновленных (новых) объектов для загрузки не обнаружено');
                }
            }
        });
    };
    EditAgentStablesComponent.prototype.onGridSelectionChanged = function (params) {
        this.model.selectedRows = params.api.getSelectedRows();
    };
    EditAgentStablesComponent.prototype.changeVisibility = function () {
        var _this = this;
        this.waitingOverlay.StartWaiting();
        this.model.searchResults.forEach(function (r) { return _this.model.selectedRows.some(function (item) { return item.id === r.id; })
            ? r.defaultHidden = !r.defaultHidden
            : r.defaultHidden; });
        this.stableDataService.updateDefaultHiddenForStables(this.model.searchResults).subscribe({
            next: function (data) {
                _this.refreshFormData();
                _this.waitingOverlay.EndWaiting();
            }
        });
    };
    return EditAgentStablesComponent;
}());
export { EditAgentStablesComponent };
