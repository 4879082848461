
    <ng-container *ngIf="id || !noValueText; else noValue">
      <div *ngIf="result">
        {{preText}}
        <div class="itech-text-field itech-control-xlarge" [style.width.px]="widthControl" *ngIf="result['id']>0; else elseBeforeZero">
          <a class="itech-person-link" [title]="title" (click)="this.clickNavigation()">
            <clr-icon *ngIf="result['iconName'] && result['iconName'] === 'flame'" shape="flame" size="14"
                      [style.color]="result['iconColor']" style="margin: 5px 0 0 5px;vertical-align:top;"></clr-icon>
              <clr-icon *ngIf="result['iconName'] && result['iconName'] === 'recycle'" shape="recycle" size="14"
                        style="margin-left: 5px"></clr-icon>
              <clr-icon *ngIf="result['iconName'] && result['iconName'] === 'truck'" shape="truck" size="14"
                        style="margin-left: 5px"></clr-icon>
            {{result['shortCaption'] || result['caption']}}
            {{idComment ? '(' + (resultComment['shortCaption'] || resultComment['caption']) + ')' : ''}}
          </a>
        </div>
        <ng-template #elseBeforeZero>
          <label style="font-weight:bold;">
            {{result['shortCaption'] || result['caption']}}
            {{idComment ? '(' + (resultComment['shortCaption'] || resultComment['caption']) + ')' : ''}}
          </label>
        </ng-template>
      </div>
    </ng-container>
    <ng-template #noValue>
      <div *ngIf="noValueText">{{noValueText}}</div>
    </ng-template>