import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { DataCachingService } from '../../logic/services/data-caching.service';
import { FormHelper } from '../../ui/controls/form-helper';
import { GlobalWaitingOverlayService } from '../../ui/infrastructure/global-waiting-overlay.service';
import { MetadataService } from '../../logic/services/metadata.service';
import { BookmarkService } from '../../logic/services/bookmark.service';
import { environment } from '../../../environments/environment';
import { StringHelper } from '../../helpers/string-helper';

@Component({
  templateUrl: './view-integration-log.component.html'
})
export class ViewIntegrationLogComponent implements OnInit {

  model = ViewIntegrationLogComponent.getDefaultModel();

  isInvalid = FormHelper.isInvalid;

  gridColumnDefs = [
    {
      headerName: 'Время', field: 'time', width: 220,
      valueFormatter: params => StringHelper.getRuTimeWithSeconds(params.value),
    },
    {
      headerName: 'Направление', field: 'title', width: 140,
      cellRenderer: params => {
        if (params.data.title === 'Запрос') {
          return '<clr-icon shape="redo"></clr-icon>&nbsp;' + params.value;
        } else if (params.data.title === 'Ответ') {
          return '<clr-icon shape="undo" style="color: green"></clr-icon>&nbsp;' + params.value;
        } else {
          return params.value;
        }
      }
    },
    {
      headerName: 'Длительность, мс', field: 'durationMs', width: 200,
    },
    {
      headerName: 'Идентификатор заявки', field: 'applicationId', width: 300,
      rowGroupingSettings: {
        name: 'UUID',
        renderParams: {
          captionGetter: row => row.value,
        }
      },
    },
    {
      headerName: 'Операции', field: 'uri', width: 110, excludeExport: true,
      onCellClicked: params => this.downloadFile(params.data.fileUri),
      cellRenderer: () => {
        return '<a class="itech-link in-table-link">Скачать</a>';
      }
    },
  ];

  constructor(private route: ActivatedRoute,
              private dataCachingService: DataCachingService,
              private globalWaitingOverlayService: GlobalWaitingOverlayService,
              private metadataService: MetadataService,
              private fb: FormBuilder,
              private bookmarkService: BookmarkService) {
  }

  private static getDefaultModel(fb?: FormBuilder, defaultFilterParams?: any) {
    return {
      searchResults: [],
      filterFormGroup: fb ? ViewIntegrationLogComponent.buildSearchForm(fb, defaultFilterParams) : undefined
    };
  }

  public static buildSearchForm(fb: FormBuilder, defaultFilterParams: any = {}) {

    return fb.group({
      opName: [defaultFilterParams.opName, Validators.required],
      dateX: [defaultFilterParams.dateX, Validators.compose([Validators.required, FormHelper.validateDateTimePicker()])],
    });
  }

  ngOnInit() {

    this.route.paramMap
      .subscribe(() => {

        const existing = this.dataCachingService.getCachedData('ViewIntegrationLogComponent', '1');

        if (existing) {
          this.model = existing;
        } else {
          this.model = ViewIntegrationLogComponent.getDefaultModel(this.fb, {dateX: StringHelper.getISODate(new Date())});
          this.dataCachingService.addToCache('ViewIntegrationLogComponent', '1', this.model);
          this.refreshResults();
        }

        this.bookmarkService.addDictionaryBookmark(
          'Просмотр журнала интеграции (на дату)',
          ['/view-integration-log/']);
      });
  }

  refreshResults() {

    if (!this.model.filterFormGroup.valid) {
      return;
    }

    this.globalWaitingOverlayService.StartWaiting();

    this.metadataService.getIntegrationLog(this.model.filterFormGroup.get('opName').value,
      new Date(this.model.filterFormGroup.get('dateX').value))
      .subscribe({
        next: data => {
          this.model.searchResults = data;
          this.globalWaitingOverlayService.EndWaiting();

        }, error: () => {
          this.globalWaitingOverlayService.EndWaiting();
        }
      });
  }

  filterEnterPressed($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      this.refreshResults();
    }
  }

  downloadFile(uri: string) {
    this.metadataService.preloadFile(uri).subscribe(fileHash => {
      window.open(environment.api + '/files/get?preloadId=' + encodeURIComponent(fileHash));
    });
  }
}
