import * as tslib_1 from "tslib";
import { MetaBrowseBaseComponent } from '../meta-browse.base.component';
import { ActivatedRoute } from '@angular/router';
import { MetadataService } from '../../../logic/services/metadata.service';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { FormBuilder, Validators } from '@angular/forms';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { StringHelper } from '../../../helpers/string-helper';
var EventTestMaterialBrowseComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EventTestMaterialBrowseComponent, _super);
    function EventTestMaterialBrowseComponent(route, metadataService, alertService, globalWaitingOverlayService, lookupService, bookmarkService, fb, dataCachingService) {
        var _this = _super.call(this, route, metadataService, alertService, globalWaitingOverlayService, lookupService, fb, bookmarkService, dataCachingService) || this;
        _this.gridColumnDefs = [
            { headerName: '№', field: 'id', width: 100 },
            { headerName: 'Наименование', field: 'caption', tooltipField: 'caption', width: 350 },
            {
                headerName: 'Дата удаления', field: 'dateDeleted', width: 200,
                valueFormatter: function (params) { return StringHelper.getRuDate(params.value); },
            },
        ];
        return _this;
    }
    EventTestMaterialBrowseComponent.prototype.getMetaTitle = function () {
        return 'Исследуемые материалы';
    };
    EventTestMaterialBrowseComponent.prototype.getMetaName = function () {
        return 'test-material';
    };
    EventTestMaterialBrowseComponent.prototype.getId = function (formGroup) {
        return formGroup.get('id').value;
    };
    EventTestMaterialBrowseComponent.prototype.getGroupDef = function (row) {
        return {
            id: [row.id, Validators.compose([Validators.required, Validators.pattern(/^\d{1,5}$/)])],
            caption: [row.caption, Validators.required],
            dateDeleted: row.dateDeleted,
        };
    };
    EventTestMaterialBrowseComponent.prototype.getRowStyle = function (params) {
        if (params.data && params.data.dateDeleted) {
            return { color: 'silver' };
        }
    };
    return EventTestMaterialBrowseComponent;
}(MetaBrowseBaseComponent));
export { EventTestMaterialBrowseComponent };
