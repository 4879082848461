/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./relation-nodes.component";
import * as i2 from "@clr/angular";
import * as i3 from "../../../../node_modules/@clr/angular/clr-angular.ngfactory";
import * as i4 from "@angular/common";
import * as i5 from "../../logic/pipes/lookup-source.pipe";
import * as i6 from "../../logic/services/lookup-source.service";
import * as i7 from "../../logic/services/address-person-fio-cache.service";
import * as i8 from "@angular/platform-browser";
var styles_RelationNodesComponent = [];
var RenderType_RelationNodesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RelationNodesComponent, data: {} });
export { RenderType_RelationNodesComponent as RenderType_RelationNodesComponent };
function View_RelationNodesComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-relation-nodes", [], null, [[null, "relationClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("relationClick" === en)) {
        var pd_0 = (_co.innerRelationClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_RelationNodesComponent_0, RenderType_RelationNodesComponent)), i0.ɵdid(1, 49152, null, 0, i1.RelationNodesComponent, [], { model: [0, "model"], condition: [1, "condition"] }, { relationClick: "relationClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model; var currVal_1 = _v.parent.parent.context.$implicit; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_RelationNodesComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 2, function (_v, en, $event) { var ad = true; if (("clrIfExpandedChange" === en)) {
        var pd_0 = ((_v.parent.context.$implicit.expanded = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_RelationNodesComponent_4)), i0.ɵdid(1, 212992, null, 0, i2.ClrIfExpanded, [[2, i0.TemplateRef], i0.ViewContainerRef, i0.ElementRef, i0.Renderer2, i2.ɵcz], { expanded: [0, "expanded"] }, { expandedChange: "clrIfExpandedChange" }), i0.ɵdid(2, 147456, null, 0, i2.ɵdg, [[2, i2.ɵcr]], null, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.expanded; _ck(_v, 1, 0, currVal_0); }, null); }
function View_RelationNodesComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "clr-tree-node", [], [[2, "clr-tree-node", null], [1, "role", 0], [1, "aria-multiselectable", 0], [1, "aria-selected", 0]], null, null, i3.View_ClrTreeNode_0, i3.RenderType_ClrTreeNode)), i0.ɵprd(6144, null, i2.LoadingListener, null, [i2.ɵcz]), i0.ɵprd(1024, null, i2.ɵo, i2.ɵp, []), i0.ɵprd(1024, null, i2.ɵdz, i2.ɵea, [[3, i2.ɵdz]]), i0.ɵprd(512, null, i2.ɵcz, i2.ɵcz, []), i0.ɵdid(5, 245760, null, 0, i2.ClrTreeNode, [i2.ɵo, [3, i2.ClrTreeNode], i2.ɵdz, i2.ɵcz, i2.ClrCommonStringsService, i0.Injector], null, null), (_l()(), i0.ɵeld(6, 0, null, 0, 5, "button", [["class", "clr-treenode-link"]], [[2, "active", null], [8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.relationClick.emit({ parentCondition: _co.condition, condition: _v.context.$implicit }) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵppd(7, 2), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(9, null, [" ", " "])), i0.ɵppd(10, 2), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, 2, 1, null, View_RelationNodesComponent_3)), i0.ɵdid(13, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { _ck(_v, 5, 0); var currVal_7 = (_v.context.$implicit.relations && _v.context.$implicit.relations.length); _ck(_v, 13, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = i0.ɵnov(_v, 5).treeNodeRole; var currVal_2 = i0.ɵnov(_v, 5).rootAriaMultiSelectable; var currVal_3 = i0.ɵnov(_v, 5).ariaSelected; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = ((_co.model.selectedNode == _v.context.$implicit) && (_co.model.nodeType == "condition")); var currVal_5 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 6, 1, i0.ɵnov(_v, 8).transform(i0.ɵunv(_v, 6, 1, _ck(_v, 7, 0, i0.ɵnov(_v.parent.parent, 0), _v.context.$implicit.data.relationType, "query-relation-type")))), ""); _ck(_v, 6, 0, currVal_4, currVal_5); var currVal_6 = i0.ɵunv(_v, 9, 0, i0.ɵnov(_v, 11).transform(i0.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i0.ɵnov(_v.parent.parent, 0), _v.context.$implicit.data.relationType, "query-relation-type")))); _ck(_v, 9, 0, currVal_6); }); }
function View_RelationNodesComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), i0.ɵdid(1, 147456, null, 0, i2.ClrLoading, [[2, i2.LoadingListener]], { loadingState: [0, "loadingState"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RelationNodesComponent_2)), i0.ɵdid(3, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.condition.relations; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_RelationNodesComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i5.LookupSourcePipe, [i6.LookupSourceService, i7.AddressPersonFioCacheService, i8.DomSanitizer]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RelationNodesComponent_1)), i0.ɵdid(2, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.condition; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_RelationNodesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-relation-nodes", [], null, null, null, View_RelationNodesComponent_0, RenderType_RelationNodesComponent)), i0.ɵdid(1, 49152, null, 0, i1.RelationNodesComponent, [], null, null)], null, null); }
var RelationNodesComponentNgFactory = i0.ɵccf("app-relation-nodes", i1.RelationNodesComponent, View_RelationNodesComponent_Host_0, { model: "model", condition: "condition" }, { relationClick: "relationClick" }, []);
export { RelationNodesComponentNgFactory as RelationNodesComponentNgFactory };
