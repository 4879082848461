<section class="form-block itech-block-large" [formGroup]="contextFormGroup">
  <div>
    <app-edit-quarantine-stages-file [label]="'Информирование населения и владельцев животных о возникновении заболевания'"
                                     [contextFormGroup]="contextFormGroup" [controlName]="'informingPeopleAboutDetectionDisease'"
                                     (dirtyFormGroup)="markAsDirty()" [id]="id" [isAccessEdit]="isAccessEdit"
                                     [serverSideErrorsProvider]="serverSideErrorsProvider" [tooltipPosition]="'bottom-right'">
    </app-edit-quarantine-stages-file>
    <app-edit-quarantine-stages-file [label]="'Исполнение плана мероприятий по ликвидации заболевания'"
                                     [contextFormGroup]="contextFormGroup" [controlName]="'implementationPlanForEliminationDisease'"
                                     (dirtyFormGroup)="markAsDirty()" [id]="id" [isAccessEdit]="isAccessEdit"
                                     [marginBottom]="0" [withoutNumber]="true" [serverSideErrorsProvider]="serverSideErrorsProvider">
    </app-edit-quarantine-stages-file>
    <div class="form-group" style="display:block;margin-bottom:20px"
         *ngIf="contextFormGroup.get('implementationPlanForEliminationDisease').value.length" [formGroup]="contextFormGroup">
      <label></label>
      <div style="display:block">
        <div>Краткое описание</div>
        <div>
          <label for="implementationPlanForEliminationDiseaseDescription"
                 aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                 [class.invalid]="isInvalid(contextFormGroup, 'implementationPlanForEliminationDiseaseDescription')"
                 style="margin-top:0">
            <textarea type="text" id="implementationPlanForEliminationDiseaseDescription"
                      class="itech-control-normal itech-quarantine-textarea itech-quarantine-textarea-large"
                      placeholder="Краткое описание"
                      formControlName="implementationPlanForEliminationDiseaseDescription"
                      (change)="setNullIfWhitespace('implementationPlanForEliminationDiseaseDescription')"
                      [attr.disabled]="!isAccessEdit ? true : undefined"></textarea>
            <app-validation-tooltip [input]="contextFormGroup.controls['implementationPlanForEliminationDiseaseDescription']"></app-validation-tooltip>
          </label>
        </div>
      </div>
    </div>
    <app-edit-quarantine-stages-file [label]="'Исполнение плана мероприятий по дезинфекции'" [serverSideErrorsProvider]="serverSideErrorsProvider"
                                     [contextFormGroup]="contextFormGroup" [controlName]="'implementationPlanForDisinfection'"
                                     (dirtyFormGroup)="markAsDirty()" [id]="id" [isAccessEdit]="isAccessEdit"
                                     [marginBottom]="0" [withoutNumber]="true">
    </app-edit-quarantine-stages-file>
    <div class="form-group" style="display:block"
         *ngIf="contextFormGroup.get('implementationPlanForDisinfection').value.length" [formGroup]="contextFormGroup">
      <label></label>
      <div style="display:block">
        <div>Краткое описание</div>
        <div>
          <label for="implementationPlanForDisinfectionDescription"
                 aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                 [class.invalid]="isInvalid(contextFormGroup, 'implementationPlanForDisinfectionDescription')"
                 style="margin-top:0">
            <textarea type="text" id="implementationPlanForDisinfectionDescription"
                      class="itech-control-normal itech-quarantine-textarea itech-quarantine-textarea-large"
                      placeholder="Краткое описание"
                      formControlName="implementationPlanForDisinfectionDescription"
                      (change)="setNullIfWhitespace('implementationPlanForDisinfectionDescription')"
                      [attr.disabled]="!isAccessEdit ? true : undefined"></textarea>
            <app-validation-tooltip [input]="contextFormGroup.controls['implementationPlanForDisinfectionDescription']"></app-validation-tooltip>
          </label>
        </div>
      </div>
    </div>
    <app-edit-quarantine-stages-file [label]="'Решение о продлении карантина'" [serverSideErrorsProvider]="serverSideErrorsProvider"
                                     [contextFormGroup]="contextFormGroup" [controlName]="'orderToExtend'"
                                     (dirtyFormGroup)="markAsDirty()" [id]="id" [isAccessEdit]="isAccessEdit"
                                     [marginBottom]="0" [withoutNumber]="true" [labelDateToInclusive]="'Продлен по'">
    </app-edit-quarantine-stages-file>
    <app-edit-quarantine-decision-next-stage [stageId]="statusIntroduction" [contextFormGroup]="contextFormGroup"
                                             [serverSideErrorsProvider]="serverSideErrorsProvider"
                                             (needUpdateQuarantine)="needUpdateQuarantine.next()" [quarantineId]="id"
                                             [quarantineCurrentStatusId]="currentStatusId"></app-edit-quarantine-decision-next-stage>
  </div>
</section>
