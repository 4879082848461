
    <clr-dropdown>
      <button class="btn btn-secondary" clrDropdownToggle
              [disabled]="model.selectedRows && model.selectedRows.length ? undefined : true">
        Распечатать
      </button>
      <clr-dropdown-menu *clrIfOpen [clrPosition]="'right-bottom'">
        <label class="dropdown-header">Выберите печатную форму</label>
        <button *ngFor="let report of lookupSourceService.getLookup('report-product') | async"
            type="button" clrDropdownItem [title]="report.caption"
          (click)="concatDocumentsReports(report.id)">
          {{report.caption}}
        </button>
      </clr-dropdown-menu>
    </clr-dropdown>