import { Injectable } from '@angular/core';
import { PersonSearchParams } from '../../model/person-search-params';
import { Observable ,  Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { DocTypes } from '../../model/doc-types';
import { StringHelper } from '../../helpers/string-helper';
import { PassportOrgCodeDirective } from '../validators/passport-org-code.directive';
import { DateHelper } from '../../helpers/date-helper';
import { map } from 'rxjs/internal/operators';

@Injectable()
export class PersonDataService {

  constructor(private http: HttpClient) {
  }

  public searchPeople(params: PersonSearchParams): Observable<any[]> {
    const qstring = environment.api + '/person/search';
    return this.http.post(qstring, params)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  createNewPersonCard(personCardData: any): Observable<number> {
    const qstring = environment.api + '/person/create';
    return this.http.post(qstring, personCardData)
      .pipe(map((response: any, error: any) => {
        return response.data.id as number;
      }));
  }

  storePersonCommonInfo(personCommonData: any, deleted: any[]) {
    const sanitizedData: any = {};
    Object.assign(sanitizedData, personCommonData);
    if (sanitizedData.snils && isNaN(sanitizedData.snils)) {
      sanitizedData.snils = StringHelper.strToSnils(sanitizedData.snils);
    }

    const qstring = environment.api + '/person/store-common';
    return this.http.post(qstring, {person: sanitizedData, delete: deleted});
  }

  storePersonDocuments(documentsData: any[], deleted: any[], version: number, personId: any) {
    return this.http.post(environment.api + '/person/store-documents', {
      documents: documentsData,
      delete: deleted,
      version: version,
      personId: personId
    });
  }

  getPersonCommonInfo(id: number): Observable<any> {
    const qstring = environment.api + '/person/edit/' + id;
    return this.http.get(qstring).pipe(map((val: any) => {
      if (val.snils) {
        val.snils = StringHelper.snilsToStr(val.snils);
      }
      return val;
    }));
  }

  getPersonRecord(id: number): Observable<any> {
    const qstring = environment.api + '/person/' + id;
    return this.http.get(qstring);
  }

  getPersonDocuments(id: number): Observable<any> {
    return this.http.get(environment.api + '/person/edit-documents/' + id);
  }

  getDefaultDocumentModel(docTypeId: number, personId?: number, existingDocs?: any[], currentUserInfo?: any): any {
    const model: any = {docTypeId: docTypeId, docSourceId: 1, personId: personId};

    switch (docTypeId) {
      case DocTypes.Address:
        model.docDate = StringHelper.getISODate(new Date());
        model.t2DateFrom = StringHelper.getISODate(new Date());
        model.address = {
          regionId: currentUserInfo ? currentUserInfo.defaultRegionId : undefined,
          cityId: currentUserInfo ? currentUserInfo.defaultCityId : undefined,
          countryId: 'RUS',
        };
        break;
      case DocTypes.Accounting:
        model.docSubtypeId = 1;
        model.docDate = StringHelper.getISODate(new Date());
        const lastFio = this.findLastFioDoc(existingDocs);
        if (lastFio) {
          model.t1BirthDate = lastFio.t1BirthDate;
          model.t1LastName = lastFio.t1LastName;
          model.t1FirstName = lastFio.t1FirstName;
          model.t1MiddleName = lastFio.t1MiddleName;
          model.extraBool1 = lastFio.extraBool1;
        }
        break;
      case DocTypes.Identity:
        const lastFio2 = this.findLastFioDoc(existingDocs);
        if (lastFio2) {
          model.t1BirthDate = lastFio2.t1BirthDate;
          model.t1LastName = lastFio2.t1LastName;
          model.t1FirstName = lastFio2.t1FirstName;
          model.t1MiddleName = lastFio2.t1MiddleName;
          model.extraBool1 = lastFio2.extraBool1;
        }
        break;
      case DocTypes.Work:
        model.docSubtypeId = 1;
        model.docDate = StringHelper.getISODate(new Date());
        break;
      case DocTypes.Independence:
        model.docSubtypeId = 1;
        model.docDate = StringHelper.getISODate(new Date());
        model.independenceInfoList = [];
        break;
    }
    return model;
  }

  findLastFioDoc(existingDocs: any[]): any {
    if (!existingDocs) {
      return undefined;
    }
    let cdate = '0000-00-00';
    let lastDoc: any;
    existingDocs.forEach(doc => {
      if ((doc.docTypeId === 1 || doc.docTypeId === 3) && doc.docDate >= cdate) {
        lastDoc = doc;
        cdate = lastDoc.docDate;
      }
    });
    return lastDoc;
  }

  getPersonRequests(personId: number) {
    const qstring = environment.api + '/request/person/' + personId.toString(10);
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  getPersonIpraList(personId: number) {
    const qstring = environment.api + '/ipra/person/' + personId.toString(10);
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  getPersonRequest(personId: number, requestId: number): Observable<any> {
    const qstring = environment.api + '/request/' + personId.toString(10) + '/' + requestId.toString(10);
    return this.http.get(qstring);
  }

  getPersonRequestClarificationProject(personId: number, requestId: number): Observable<any> {
    const qstring = environment.api + '/request/clarification-project/' + personId.toString(10) + '/' + requestId.toString(10);
    return this.http.get(qstring);
  }

  getPersonIpra(personId: number, ipraId: number): Observable<any> {
    const qstring = environment.api + '/ipra/' + personId.toString(10) + '/' + ipraId.toString(10);
    return this.http.get(qstring);
  }

  storeRequest(request: any): Observable<number> {
    const qstring = environment.api + '/request/store';
    return this.http.post(qstring, request)
      .pipe(map((response: any, error: any) => {
        return response.data.id as number;
      }));
  }

  storeIpra(request: any): Observable<number> {
    const qstring = environment.api + '/ipra/store';
    return this.http.post(qstring, request)
      .pipe(map((response: any, error: any) => {
        return response.data.id as number;
      }));
  }

  calculateRequestDefaultServices(request: any): Observable<any[]> {
    const qstring = environment.api + '/request/calculate-soc-services';
    return this.http.post(qstring, request)
      .pipe(map((response: any, error: any) => {
        return response.data as any[];
      }));
  }

  calculateRequestAmounts(request: any): Observable<any[]> {
    const qstring = environment.api + '/request/calculate-amounts';
    return this.http.post(qstring, request)
      .pipe(map((response: any, error: any) => {
        return response.data as any[];
      }));
  }

  deleteRequest(personId: number, requestId: number) {
    const qstring = environment.api + '/request/delete/' + personId + '/' + requestId;
    return this.http.post(qstring, {});
  }

  deleteIpra(personId: number, ipraId: number) {
    const qstring = environment.api + '/ipra/delete/' + personId + '/' + ipraId;
    return this.http.post(qstring, {});
  }

  getPersonGroups(): Observable<any> {
    const qstring = environment.api + '/person/group/';
    return this.http.get(qstring);
  }

  getPersonGroupsByPerson(personId: number): Observable<any> {
    const qstring = environment.api + '/person/group/by-person/' + personId;
    return this.http.get(qstring);
  }

  getPersonGroupItems(personGroupId: number): Observable<any[]> {
    const qstring = environment.api + '/person/group/items/' + personGroupId;
    return this.http.get(qstring).pipe(map(val => (val as any[])));
  }

  storePersonGroup(personGroup: any): Observable<number> {
    const qstring = environment.api + '/person/group/store';
    return this.http.post(qstring, personGroup)
      .pipe(map((response: any, error: any) => {
        return response.data.id as number;
      }));
  }

  deletePersonGroup(personGroupId: number) {
    const qstring = environment.api + '/person/group/delete/' + personGroupId;
    return this.http.post(qstring, {});
  }

  deletePersonGroupItem(personGroupId: number, personId: number) {
    const qstring = environment.api + '/person/group/items/delete/' + personGroupId + '/' + personId;
    return this.http.post(qstring, {});
  }

  addPersonGroupItem(personGroupId: number, personId: number) {
    const qstring = environment.api + '/person/group/items/add/' + personGroupId + '/' + personId;
    return this.http.post(qstring, {});
  }

  acquireAccess(params: any) {

    const sanitizedParams: any = {};
    Object.assign(sanitizedParams, params);
    if (sanitizedParams.snils && isNaN(sanitizedParams.snils)) {
      sanitizedParams.snils = StringHelper.strToSnils(sanitizedParams.snils);
    }

    const qstring = environment.api + '/person/acquire-access';
    return this.http.post(qstring, sanitizedParams);
  }

  calculateQueueNumber(personId: any, requestId: any): Observable<any> {
    const qstring = environment.api + '/request/queue/calculate-number/' + personId + '/' + requestId;
    return this.http.post(qstring, {});
  }

  searchByInstitution(institutionId: number, requestTypeId: any, showCompleted: boolean) {
    const qstring = environment.api + '/person/search/institution-clients/' + institutionId + '/' + requestTypeId + '/'
      + (showCompleted ? '1' : '0');
    return this.http.post(qstring, {});
  }

  mergePerson(mainPersonId: any, personId: any) {
    const qstring = environment.api + '/person/merge/' + mainPersonId + '/' + personId;
    return this.http.post(qstring, {});
  }

  public uploadFileToRequest(personId: any, requestId: any, file: any): Observable<string> {
    const qstring = environment.api + '/request/upload-file/' + personId + '/' + requestId;

    const formData = new FormData();

    formData.append('file', file);
    formData.append('fileName', new Blob([file.name], { type: 'text/plain' }));

    return this.http.post(qstring, formData)
      .pipe(map((response: any) => {
        return response.data;
      }));
  }

  public uploadFileToIpra(personId: any, ipraId: any, file: any): Observable<string> {
    const qstring = environment.api + '/ipra/upload-file/' + personId + '/' + ipraId;

    const formData = new FormData();

    formData.append('file', file);
    formData.append('fileName', new Blob([file.name], { type: 'text/plain' }));

    return this.http.post(qstring, formData)
      .pipe(map((response: any) => {
        return response.data;
      }));
  }

  buildSmevMessageXml(request: any, smevMessageTypeId: number, targetPersonId: number): Observable<string> {
    const qstring = environment.api + '/request/build-smev-message-xml/' + smevMessageTypeId + '/' + targetPersonId;
    return this.http.post(qstring, request)
      .pipe(map((response: any, error: any) => {
        return response.data as string;
      }));
  }
}
