<span *ngIf="!editing">
  {{cellValueDisplay}}
</span>
<ng-container *ngIf="editing">
  <input #input type="text" maxlength="255" [(ngModel)]="cellValueStr"
         (keydown)="inputKeyDown($event)"/>
</ng-container>
<div class="it-focus-border" *ngIf="focused" [class.it-cell-storing]="model.__cl_storing_indicator"
     [class.it-cell-error]="model.__cl_storing_error">
</div>
<div class="it-cell-error-text" *ngIf="focused && model.__cl_storing_error">
  {{model.__cl_storing_error}}
</div>
