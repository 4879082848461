
    <div style="height: 60vh; background-color: white; position: relative;">
      <app-animal-search-results-form
        [model]="model" [addManyAnimals]="addManyAnimals"
        (selectionChanged)="selectedAnimalIds = $event"
        (rowClicked)="chooseAnimal($event)">
      </app-animal-search-results-form>
    </div>
    <div class="modal-footer itech-form-actions" *ngIf="addManyAnimals">
      <div>
      </div>
      <div>
        <button class="btn btn-success" (click)="chooseMultipleAnimals()"
                [disabled]="!selectedAnimalIds || !selectedAnimalIds.length">
          <clr-icon shape="check"></clr-icon>&nbsp;Выбрать
          {{selectedAnimalIds && selectedAnimalIds.length ? ' (' + selectedAnimalIds.length.toString() + ' шт.)' : ''}}
        </button>
      </div>
    </div>
  