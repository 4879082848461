import { OnChanges, SimpleChanges } from '@angular/core';
import { LookupSourceService } from '../../logic/services/lookup-source.service';
import { FormHelper } from './form-helper';
import { Subject } from 'rxjs';
import { map } from 'rxjs/internal/operators';
import { FormArray, FormControl } from '@angular/forms';
var AppComboLookupComponent = /** @class */ (function () {
    function AppComboLookupComponent(lookupSourceService) {
        this.lookupSourceService = lookupSourceService;
        this.validationTooltipRight = false;
        this.validationTooltipTopLeft = false;
        this.validationTooltipBottomLeft = false;
        this.validationTooltipBottomRight = false;
        this.disabled = false;
        this.disabledChoices = [];
        this.change = new Subject();
        this.sorted = false;
        this.required = true;
        this.lookupItems = [];
        this.captionFieldName = 'caption';
        // 2 вида фильтрации:
        // - передаем значение (filterValueInArrayValue) и оно должно содержаться в массиве filterValueInArrayName объекта из lookup
        this.filterValueInArrayControlName1 = 'filterList1';
        this.filterValueInArrayControlName2 = 'filterList2';
        // - передаем массив в filterArrayIncludeValue и значение filterArrayIncludeIdName объекта из lookup должен содержаться в этом массиве
        this.filterArrayIncludeControlName = 'id';
        this.focused = false;
        this.hovered = false;
    }
    Object.defineProperty(AppComboLookupComponent.prototype, "lookupName", {
        get: function () {
            return this._lookupName;
        },
        set: function (newLookupName) {
            if (this._lookupName !== newLookupName && newLookupName) {
                this._lookupName = newLookupName;
                this.initLookupItems();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComboLookupComponent.prototype, "boundControl", {
        get: function () {
            if (!this.contextFormGroup) {
                return undefined;
            }
            return this.contextFormGroup instanceof FormArray
                ? this.contextFormGroup.controls[this.contextControlName]
                : this.contextFormGroup.get(this.contextControlName);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComboLookupComponent.prototype, "controlIsDisabled", {
        get: function () {
            return this.boundControl && this.boundControl.disabled;
        },
        enumerable: true,
        configurable: true
    });
    AppComboLookupComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if ((changes.hasOwnProperty('lookupName') || changes.hasOwnProperty('contextFormGroup')
            || changes.hasOwnProperty('contextControlName')) && this.boundControl) {
            // вот это все нам нужно, чтобы отследить, что присваивается контролу, и добавить в список удаленный элемент,
            // которого еще в списке нету (чтобы корректно отображались ранее выбранные удаленные элементы, и при этом список
            // не содержал всех удаленных элементов)
            this.boundControl.valueChanges.subscribe(function () {
                _this.tryUpdateBonusDeletedItem();
            });
            this.tryUpdateBonusDeletedItem();
        }
    };
    AppComboLookupComponent.prototype.tryUpdateBonusDeletedItem = function () {
        var _this = this;
        if (!this.lookupName) {
            return;
        }
        this.lookupSourceService.getLookupObj(this.lookupName).subscribe(function (lookup) {
            var newVal = _this.boundControl.value;
            if (newVal && !_this.lookupItems.find(function (el) { return el.id == newVal; })) {
                _this.bonusDeletedItem = lookup['Obj' + newVal];
            }
            else {
                _this.bonusDeletedItem = undefined;
            }
        });
    };
    AppComboLookupComponent.prototype.initLookupItems = function () {
        var _this = this;
        this.getLookup().subscribe(function (items) {
            _this.lookupItems = LookupSourceService.filterLookup(items, _this.filterValueInArrayControlName1, _this.filterValueInArrayValue1, _this.filterValueInArrayControlName2, _this.filterValueInArrayValue2, _this.filterArrayIncludeControlName, _this.filterArrayIncludeValue);
            _this.tryUpdateBonusDeletedItem();
        });
    };
    AppComboLookupComponent.prototype.isDisabledChoice = function (val) {
        if (!this.disabledChoices) {
            return false;
        }
        return (this.disabledChoices.find(function (item) { return item == val; }) >= 0);
    };
    AppComboLookupComponent.prototype.focus = function () {
        this.focused = true;
    };
    AppComboLookupComponent.prototype.blur = function () {
        this.focused = false;
    };
    AppComboLookupComponent.prototype.getLookup = function () {
        var _this = this;
        return this.lookupSourceService.getLookup(this.lookupName).pipe(map(function (lookup) {
            if (!_this.sorted) {
                lookup.sort(function (item1, item2) { return item1.id - item2.id; });
            }
            else {
                lookup.sort(function (item1, item2) { return item1.caption ? item1.caption.localeCompare(item2.caption) : 0; });
            }
            return lookup;
        }));
    };
    AppComboLookupComponent.prototype.isInvalid = function (cname) {
        return FormHelper.isInvalid(this.contextFormGroup, cname);
    };
    AppComboLookupComponent.prototype.clearValue = function () {
        this.contextFormGroup.controls[this.contextControlName].setValue(undefined);
        this.contextFormGroup.controls[this.contextControlName].markAsDirty();
        this.change.next({});
    };
    AppComboLookupComponent.prototype.hover = function () {
        this.hovered = true;
    };
    AppComboLookupComponent.prototype.hoverEnd = function () {
        this.hovered = false;
    };
    AppComboLookupComponent.prototype.onChange = function (event) {
        var s = event.target;
        if (s.value) {
            this.change.next(this.lookupItems.find(function (x) { return x.id.toString() === s.value; }));
        }
        else {
            this.change.next({});
        }
        event.stopPropagation();
    };
    return AppComboLookupComponent;
}());
export { AppComboLookupComponent };
