/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/forms";
import * as i3 from "../../../logic/pipes/lookup-source.pipe";
import * as i4 from "../../../logic/services/lookup-source.service";
import * as i5 from "../../../logic/services/address-person-fio-cache.service";
import * as i6 from "@angular/platform-browser";
import * as i7 from "./pst-branch-cell-lookup-edit.component";
import * as i8 from "../../../logic/services/app-navigation.service";
import * as i9 from "../../services/pst-report-structure.service";
var styles_PstBranchCellLookupEditComponent = [];
var RenderType_PstBranchCellLookupEditComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PstBranchCellLookupEditComponent, data: {} });
export { RenderType_PstBranchCellLookupEditComponent as RenderType_PstBranchCellLookupEditComponent };
function View_PstBranchCellLookupEditComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", "\n"])), i0.ɵppd(2, 2), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 3).transform(i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 0), _co.originalCellValue, _co.lookupName)))); _ck(_v, 1, 0, currVal_0); }); }
function View_PstBranchCellLookupEditComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "option", [], [[8, "title", 0]], null, null, null, null)), i0.ɵdid(1, 147456, null, 0, i2.NgSelectOption, [i0.ElementRef, i0.Renderer2, [2, i2.SelectControlValueAccessor]], { value: [0, "value"] }, null), i0.ɵdid(2, 147456, null, 0, i2.ɵangular_packages_forms_forms_s, [i0.ElementRef, i0.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i0.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var currVal_1 = _v.context.$implicit.id; _ck(_v, 1, 0, currVal_1); var currVal_2 = _v.context.$implicit.id; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.caption; _ck(_v, 0, 0, currVal_0); var currVal_3 = _v.context.$implicit.caption; _ck(_v, 3, 0, currVal_3); }); }
function View_PstBranchCellLookupEditComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 11, "div", [["class", "select"]], [[2, "itech-focused", null]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, [[1, 0], ["input", 1]], null, 10, "select", [], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onChange($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 3).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.cellValueEdit = $event) !== false);
        ad = (pd_2 && ad);
    } if (("change" === en)) {
        var pd_3 = (_co.onChange($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 16384, null, 0, i2.SelectControlValueAccessor, [i0.Renderer2, i0.ElementRef], null, null), i0.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.SelectControlValueAccessor]), i0.ɵdid(5, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i0.ɵdid(7, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i0.ɵeld(8, 0, null, null, 2, "option", [], null, null, null, null, null)), i0.ɵdid(9, 147456, null, 0, i2.NgSelectOption, [i0.ElementRef, i0.Renderer2, [2, i2.SelectControlValueAccessor]], null, null), i0.ɵdid(10, 147456, null, 0, i2.ɵangular_packages_forms_forms_s, [i0.ElementRef, i0.Renderer2, [8, null]], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchCellLookupEditComponent_3)), i0.ɵdid(12, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.cellValueEdit; _ck(_v, 5, 0, currVal_8); var currVal_9 = _co.lookupItems; _ck(_v, 12, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = true; _ck(_v, 1, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 7).ngClassUntouched; var currVal_2 = i0.ɵnov(_v, 7).ngClassTouched; var currVal_3 = i0.ɵnov(_v, 7).ngClassPristine; var currVal_4 = i0.ɵnov(_v, 7).ngClassDirty; var currVal_5 = i0.ɵnov(_v, 7).ngClassValid; var currVal_6 = i0.ɵnov(_v, 7).ngClassInvalid; var currVal_7 = i0.ɵnov(_v, 7).ngClassPending; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
function View_PstBranchCellLookupEditComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "it-focus-border"]], [[2, "it-cell-storing", null], [2, "it-cell-error", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.__cl_storing_indicator; var currVal_1 = _co.model.__cl_storing_error; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_PstBranchCellLookupEditComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "it-cell-error-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.__cl_storing_error; _ck(_v, 1, 0, currVal_0); }); }
export function View_PstBranchCellLookupEditComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i3.LookupSourcePipe, [i4.LookupSourceService, i5.AddressPersonFioCacheService, i6.DomSanitizer]), i0.ɵqud(671088640, 1, { inputControl: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchCellLookupEditComponent_1)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchCellLookupEditComponent_2)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchCellLookupEditComponent_4)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchCellLookupEditComponent_5)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.editing && _co.originalCellValue); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.editing && _co.lookupItems); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.focused; _ck(_v, 7, 0, currVal_2); var currVal_3 = (_co.focused && _co.model.__cl_storing_error); _ck(_v, 9, 0, currVal_3); }, null); }
export function View_PstBranchCellLookupEditComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-branch-cell-lookup-edit", [], null, null, null, View_PstBranchCellLookupEditComponent_0, RenderType_PstBranchCellLookupEditComponent)), i0.ɵdid(1, 573440, null, 0, i7.PstBranchCellLookupEditComponent, [i4.LookupSourceService, i8.AppNavigationService, i0.ChangeDetectorRef, i9.PstReportStructureService], null, null)], null, null); }
var PstBranchCellLookupEditComponentNgFactory = i0.ɵccf("app-branch-cell-lookup-edit", i7.PstBranchCellLookupEditComponent, View_PstBranchCellLookupEditComponent_Host_0, { branch: "branch", model: "model", lookupName: "lookupName", indicatorLevel: "indicatorLevel", originalCellValue: "originalCellValue", editing: "editing", focused: "focused" }, { changed: "changed" }, []);
export { PstBranchCellLookupEditComponentNgFactory as PstBranchCellLookupEditComponentNgFactory };
