import { FormBuilder, Validators } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { serverSideErrorsValidator } from '../../../logic/validators/server-side-errors-validator.directive';
import { AppFormGroup } from '../../../ui/controls/app-form-group';
import { DateHelper } from '../../../helpers/date-helper';
import { StringHelper } from '../../../helpers/string-helper';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { BehaviorSubject, of } from 'rxjs/index';
import { MetadataService } from '../../../logic/services/metadata.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { ModalParams, ModalSize } from '../../../logic/services/app-navigation.service.models';
import { DrugTypeBrowseCreateModalComponent } from '../../dictionary/drug/drug-type-browse-create-modal.component';
import { AppModalPreviewFileComponent } from '../../../ui/controls/app-modal-preview-file.component';
var EditDrugCommonFormComponent = /** @class */ (function () {
    function EditDrugCommonFormComponent(navigationService, metadataService, fb, lookupService) {
        this.navigationService = navigationService;
        this.metadataService = metadataService;
        this.fb = fb;
        this.lookupService = lookupService;
        this.isInvalid = FormHelper.isInvalid;
        this.processMoneyKeypress = FormHelper.processMoneyKeypress;
        this.changeCost = EditDrugCommonFormComponent.changeCost;
        this.changeStrValue = EditDrugCommonFormComponent.changeDecimalStrValue;
        this._drugTypeCache = {};
    }
    EditDrugCommonFormComponent.createFormGroup = function (fb, serverSideErrorsProvider, val) {
        if (val && val.cost && +val.costTypeId === 2) {
            val.cost = (val.cost * 100).toFixed(3);
        }
        var group = fb.group({
            id: val.id,
            createdTime: val.createdTime,
            createdUser: val.createdUser,
            drugTypeId: [val.drugTypeId, Validators.compose([Validators.required,
                    serverSideErrorsValidator('drugTypeId', serverSideErrorsProvider)])],
            serial: [val.serial, Validators.compose([
                    Validators.required,
                    serverSideErrorsValidator('serial', serverSideErrorsProvider)
                ])],
            party: [val.party, Validators.compose([
                    serverSideErrorsValidator('party', serverSideErrorsProvider)
                ])],
            code: [val.code, Validators.compose([
                    serverSideErrorsValidator('code', serverSideErrorsProvider)
                ])],
            manufactureDate: [val.manufactureDate, Validators.compose([
                    Validators.required,
                    FormHelper.validateDateTimePicker(),
                    serverSideErrorsValidator('manufactureDate', serverSideErrorsProvider),
                ])],
            expireDate: [val.expireDate, Validators.compose([
                    Validators.required,
                    FormHelper.validateDateTimePicker(),
                    serverSideErrorsValidator('expireDate', serverSideErrorsProvider),
                ])],
            expireDurationCaption: '',
            actNumber: [val.actNumber, Validators.compose([
                    Validators.required,
                    serverSideErrorsValidator('actNumber', serverSideErrorsProvider)
                ])],
            totalValue: val.totalValue,
            totalValueStr: [val.totalValue ? val.totalValue.toString().replace('.', ',') : null,
                Validators.compose([
                    FormHelper.validateDrugDecimal(),
                    Validators.required,
                    serverSideErrorsValidator('totalValue', serverSideErrorsProvider)
                ])],
            drugUnitTypeId: [val.drugUnitTypeId,
                Validators.compose([
                    Validators.required,
                    serverSideErrorsValidator('drugUnitTypeId', serverSideErrorsProvider)
                ])],
            cost: val.cost,
            costStr: [val.cost ? val.cost.toString().replace('.', ',') : null,
                Validators.compose([
                    FormHelper.validateMoneyThreeDecimalComma(),
                    Validators.required,
                    serverSideErrorsValidator('cost', serverSideErrorsProvider)
                ])],
            costTypeId: [val.costTypeId || 1,
                Validators.compose([
                    Validators.required,
                    serverSideErrorsValidator('costTypeId', serverSideErrorsProvider)
                ])],
            budgetTypeId: [val.budgetTypeId, Validators.compose([Validators.required,
                    serverSideErrorsValidator('budgetTypeId', serverSideErrorsProvider)])],
            budgetReceiveTypeId: [val.budgetReceiveTypeId, Validators.compose([
                    serverSideErrorsValidator('budgetReceiveTypeId', serverSideErrorsProvider)
                ])],
            budgetReceiveComment: [val.budgetReceiveComment, Validators.compose([
                    serverSideErrorsValidator('budgetReceiveComment', serverSideErrorsProvider)
                ])],
            expiresYears: [val.expiresYears, Validators.compose([Validators.required, Validators.pattern(/^\d{1,2}$/),
                    serverSideErrorsValidator('expiresYears', serverSideErrorsProvider)])],
            expiresMonths: [val.expiresMonths, Validators.compose([Validators.required, Validators.pattern(/^\d{1,2}$/),
                    serverSideErrorsValidator('expiresMonths', serverSideErrorsProvider)])],
            expiresDays: [val.expiresDays, Validators.compose([Validators.required, Validators.pattern(/^\d{1,2}$/),
                    serverSideErrorsValidator('expiresDays', serverSideErrorsProvider)])],
            firstPackageCount: val.firstPackageCount,
            firstPackageCountStr: [val.firstPackageCount ? val.firstPackageCount.toString().replace('.', ',') : null,
                Validators.compose([
                    serverSideErrorsValidator('firstPackageCount', serverSideErrorsProvider)
                ])],
            firstPackageUnitId: [val.firstPackageUnitId, Validators.compose([
                    serverSideErrorsValidator('firstPackageUnitId', serverSideErrorsProvider)
                ])],
            totalSum: 0,
            currentValue: val.currentValue,
            currentSum: 0,
            spentValue: val.spentValue,
            spentSum: 0,
            writeoffValue: val.writeoffValue,
            writeoffSum: 0,
            movedValue: val.movedValue,
            movedSum: 0,
            movedSumExtra: fb.array((val.movedSumExtra || []).map(function (el) {
                el.count = (el.count || 0).toString().replace('.', ',');
                el.sum = (el.sum || 0).toFixed(3).toString().replace('.', ',');
                return el;
            })),
            movedSumExtraExpanded: false,
            reservedValue: (val.reserved || []).reduce(function (partialSum, a) { return partialSum + a.currentValue; }, 0),
            reservedSum: 0,
            institutionId: val.institutionId,
            institutionBranchId: val.institutionBranchId,
            sourceDrugId: val.sourceDrugId,
            incomingDate: [val.incomingDate, serverSideErrorsValidator('incomingDate', serverSideErrorsProvider)],
            incomingDocs: EditDrugCommonFormComponent.buildIncomingDocsFormArray(fb, val.incomingDocs),
            comments: val.comments,
            reserved: EditDrugCommonFormComponent.buildReservedFormArray(fb, val.reserved),
            drugIntroductionTypeIds: fb.array((val.drugIntroductionTypeIds || []).map(function (el) { return fb.control(el); })),
        });
        group.get('expireDate').disable();
        group.get('expireDurationCaption').disable();
        group.get('totalSum').disable();
        group.get('currentSum').disable();
        group.get('spentSum').disable();
        group.get('writeoffSum').disable();
        group.get('movedSum').disable();
        group.get('movedSumExtra').disable();
        group.get('movedSumExtraExpanded').disable();
        group.get('reservedSum').disable();
        group.valueChanges.subscribe(function () {
            var expireDate;
            if (group.get('manufactureDate').value) {
                var manufacturingDate = new Date(group.get('manufactureDate').value);
                var days = +(group.get('expiresDays').value || 0);
                var months = +(group.get('expiresMonths').value || 0);
                var years = +(group.get('expiresYears').value || 0);
                expireDate = DateHelper.addDuration(manufacturingDate, years, months, days);
            }
            if (StringHelper.getISODate(expireDate) !== group.get('expireDate').value) {
                group.get('expireDate').setValue(StringHelper.getISODate(expireDate));
                EditDrugCommonFormComponent.buildExpireDurationCaption(group);
            }
        });
        EditDrugCommonFormComponent.buildExpireDurationCaption(group);
        EditDrugCommonFormComponent.changeCost(group);
        return group;
    };
    EditDrugCommonFormComponent.buildExpireDurationCaption = function (fg) {
        if (!fg.get('expireDate').value) {
            fg.get('expireDurationCaption').setValue(null);
            return;
        }
        var time = DateHelper.durationInDays(DateHelper.startDay(new Date()), DateHelper.startDay(fg.get('expireDate').value));
        if (time < 0) {
            fg.get('expireDurationCaption').setValue(null);
            return;
        }
        var age = DateHelper.getPluralDay(time);
        fg.get('expireDurationCaption').setValue(age ? 'Осталось: ' + (age) : null);
    };
    EditDrugCommonFormComponent.buildReservedFormArray = function (fb, val) {
        if (!val || !val.length) {
            val = [];
        }
        return fb.array(val.map(function (el) { return EditDrugCommonFormComponent.buildReservedFormGroup(fb, el); }));
    };
    EditDrugCommonFormComponent.buildReservedFormGroup = function (fb, val) {
        return fb.group({
            drugId: [val.drugId, Validators.required],
            institutionId: [val.institutionId, Validators.required],
            institutionEmployeeId: [val.institutionEmployeeId, Validators.required],
            currentValue: [val.currentValue],
            currentValueStr: [val.currentValue ? val.currentValue.toString().replace('.', ',') : null,
                Validators.compose([Validators.required, FormHelper.validateDrugDecimal()])],
        });
    };
    EditDrugCommonFormComponent.buildIncomingDocsFormArray = function (fb, val) {
        if (!val || !val.length) {
            val = [];
        }
        return fb.array(val.concat([{}]).map(function (el) { return EditDrugCommonFormComponent.buildIncomingDocFormGroup(fb, el); }));
    };
    EditDrugCommonFormComponent.buildIncomingDocFormGroup = function (fb, val) {
        return fb.group({
            typeId: [val.typeId],
            date: [val.date, FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent && ctrl.parent.get('typeId').value; })],
            number: [val.number, FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent && ctrl.parent.get('typeId').value; })],
        });
    };
    EditDrugCommonFormComponent.changeCost = function (fg) {
        if (!fg) {
            return;
        }
        EditDrugCommonFormComponent.changeDecimalStrValue(fg, 'costStr');
        var cost = +fg.get('cost').value || 0;
        fg.get('totalSum').setValue(Number((fg.get('totalValue').value || 0) * cost).toFixed(3));
        fg.get('currentSum').setValue(Number((fg.get('currentValue').value || 0) * cost).toFixed(3));
        fg.get('spentSum').setValue(Number((fg.get('spentValue').value || 0) * cost).toFixed(3));
        fg.get('writeoffSum').setValue(Number((fg.get('writeoffValue').value || 0) * cost).toFixed(3));
        fg.get('movedSum').setValue(Number((fg.get('movedValue').value || 0) * cost).toFixed(3));
        fg.get('reservedSum').setValue(Number((fg.get('reservedValue').value || 0) * cost).toFixed(3));
    };
    EditDrugCommonFormComponent.changeDecimalStrValue = function (fg, controlName) {
        var val = fg.get(controlName).value;
        fg.get(controlName.substring(0, controlName.length - 3)).setValue(val ? val.replace(',', '.') : null);
    };
    Object.defineProperty(EditDrugCommonFormComponent.prototype, "selectedDrugTypeInfo$", {
        get: function () {
            var _this = this;
            if (!this.contextFormGroup || !this.contextFormGroup.get('drugTypeId').value) {
                return of(undefined);
            }
            var drugTypeId = this.contextFormGroup.get('drugTypeId').value;
            if (!this._drugTypeCache[drugTypeId]) {
                this._drugTypeCache[drugTypeId] = new BehaviorSubject({});
                this.metadataService.getMetadataSingle('drug-type', drugTypeId).subscribe(function (value) {
                    _this._drugTypeCache[drugTypeId].next(value);
                });
            }
            return this._drugTypeCache[drugTypeId];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditDrugCommonFormComponent.prototype, "reserved", {
        get: function () {
            return this.contextFormGroup.get('reserved');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditDrugCommonFormComponent.prototype, "incomingDocs", {
        get: function () {
            return this.contextFormGroup.get('incomingDocs');
        },
        enumerable: true,
        configurable: true
    });
    EditDrugCommonFormComponent.prototype.addReserved = function () {
        this.reserved.push(EditDrugCommonFormComponent.buildReservedFormGroup(this.fb, {
            drugId: this.contextFormGroup.get('id').value,
            institutionId: this.contextFormGroup.get('institutionId').value,
        }));
    };
    EditDrugCommonFormComponent.prototype.deleteReserve = function (index) {
        this.reserved.removeAt(index);
        this.contextFormGroup.markAsDirty();
    };
    EditDrugCommonFormComponent.prototype.changeIncomingDocType = function (ix) {
        if (!this.incomingDocs.at(ix).value.typeId) {
            this.incomingDocs.removeAt(ix);
        }
        if (this.incomingDocs.getRawValue().every(function (el) { return el.typeId; })) {
            this.incomingDocs.push(EditDrugCommonFormComponent.buildIncomingDocFormGroup(this.fb, {}));
        }
    };
    EditDrugCommonFormComponent.prototype.changeDrugTypeId = function () {
        var _this = this;
        if (this.contextFormGroup.get('id').value || !this.contextFormGroup.get('drugTypeId').value) {
            return;
        }
        this.lookupService.getLookupObj('drug-type/full-record')
            .subscribe(function (drugTypes) {
            if (_this.contextFormGroup.get('drugTypeId').value) {
                var dt = drugTypes['Obj' + _this.contextFormGroup.get('drugTypeId').value];
                if (dt) {
                    _this.contextFormGroup.get('drugUnitTypeId').setValue(dt.packageUnitId);
                    _this.contextFormGroup.get('expiresDays').setValue(dt.expiresDays);
                    _this.contextFormGroup.get('expiresMonths').setValue(dt.expiresMonths);
                    _this.contextFormGroup.get('expiresYears').setValue(dt.expiresYears);
                }
            }
        });
    };
    EditDrugCommonFormComponent.prototype.confirmToCreateDrugType = function () {
        var _this = this;
        this.navigationService.showModal(DrugTypeBrowseCreateModalComponent, new ModalParams(ModalSize.lg, 'Создание вида препарата', 'Создать', {
            isCustom: true
        }))
            .subscribe(function (newDrugType) {
            if (newDrugType) {
                _this.contextFormGroup.controls['drugTypeId'].setValue(newDrugType);
                _this.changeDrugTypeId();
            }
        });
    };
    EditDrugCommonFormComponent.prototype.previewFile = function (file) {
        return this.navigationService.previewFile(AppModalPreviewFileComponent, { fileUri: file });
    };
    EditDrugCommonFormComponent.prototype.getPrettyTitle = function (file) {
        return StringHelper.getPrettyTitleFile(file);
    };
    return EditDrugCommonFormComponent;
}());
export { EditDrugCommonFormComponent };
