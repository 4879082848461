
    <div class="compact form itech-highlighted-sections" *ngIf="model"
         [style.min-height.vh]="model && model.get('issueDiscrepancyReport').value ? 60 : undefined">
      <app-doc-validation-errors [validationErrors]="model.docLevelServerSideValidationErrors">
      </app-doc-validation-errors>

      <section class="form-block itech-block-medium" [formGroup]="model" style="min-height:100px">
        <div class="form-group">
          <label for="acceptanceTypeId" class="required">Тип приёмки</label>
          <app-combo-lookup [validationTooltipRight]="true" class="itech-control-large"
                            [contextControlId]="'acceptanceTypeId'" (change)="acceptanceTypeChange()"
                            [contextControlName]="'acceptanceTypeId'" [sorted]="true"
                            [lookupName]="'product-acceptance-type'" [contextFormGroup]="model"></app-combo-lookup>
        </div>
        <ng-container *ngIf="+model.get('acceptanceTypeId').value === 1; else partiallyAcceptance">
          <div class="form-group">
            <label for="volume" class="required">Принимаемое кол-во</label>
            <label for="volume" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                   [class.invalid]="isInvalid(model, 'volume')">
              <input type="text" id="volume" placeholder="Кол-во" class="itech-control-small" formControlName="volume"
                     (change)="volumeChange('volume')" (keypress)="processMoneyKeypress($event)">
              <app-validation-tooltip [input]="model.controls['volume']"></app-validation-tooltip>
            </label>
            <app-combo-lookup [validationTooltipRight]="true" class="itech-control-normal"
                              [contextControlId]="'unitId'" [disabled]="true"
                              [contextControlName]="'unitId'" [sorted]="true"
                              [lookupName]="'drug-unit-type/product'" [contextFormGroup]="model"></app-combo-lookup>
          </div>
          <div class="form-group">
            <label for="acceptPackages">Уровень/кол-во/тип/маркировки упаковки</label>
            <div>
              <app-edit-product-packaging [packages]="model.get('acceptPackages')" [isDraft]="true">
              </app-edit-product-packaging>
            </div>
          </div>
          <div *ngIf="!model.get('issueDiscrepancyReport').value" class="form-group">
            <label></label>
            <div class="itech-control-desc">
              Гашение без оформления акта несоответствия в полном объеме допустимо
              в пределах &#177;5% ({{model.get('clearance5percentCaption').value}})
            </div>
          </div>
        </ng-container>
        <ng-template #partiallyAcceptance>
            <ng-container *ngIf="+model.get('acceptanceTypeId').value === 2; else returnAllAcceptance">
              <div class="form-group">
                <label for="volume" class="required">Принимаемое кол-во</label>
                <label for="volume" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                       [class.invalid]="isInvalid(model, 'volume')">
                  <input type="text" id="volume" placeholder="Кол-во" class="itech-control-small" formControlName="volume"
                         (change)="volumeChange('volume')" (keypress)="processMoneyKeypress($event)">
                    <app-validation-tooltip [input]="model.controls['volume']"></app-validation-tooltip>
                </label>
                <app-combo-lookup [validationTooltipRight]="true" class="itech-control-normal"
                                  [contextControlId]="'unitId'" [disabled]="true"
                                  [contextControlName]="'unitId'" [sorted]="true"
                                  [lookupName]="'drug-unit-type/product'" [contextFormGroup]="model"></app-combo-lookup>
              </div>
              <div class="form-group">
                <label for="acceptPackages">Уровень/кол-во/тип/маркировки упаковки</label>
                <div>
                  <app-edit-product-packaging [packages]="model.get('acceptPackages')" [isDraft]="true">
                  </app-edit-product-packaging>
                </div>
              </div>
              <div class="form-group">
                <label for="returnVolume" class="required">Возвращаемое кол-во</label>
                <label for="returnVolume" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                       [class.invalid]="isInvalid(model, 'returnVolume')" (keypress)="processMoneyKeypress($event)">
                  <input type="text" id="returnVolume" placeholder="Кол-во" class="itech-control-small" formControlName="returnVolume">
                  <app-validation-tooltip [input]="model.controls['returnVolume']"></app-validation-tooltip>
                </label>
                <app-combo-lookup [validationTooltipRight]="true" class="itech-control-normal"
                                  [contextControlId]="'unitId'" [disabled]="true" [contextControlName]="'unitId'" [sorted]="true"
                                  [lookupName]="'drug-unit-type/product'" [contextFormGroup]="model"></app-combo-lookup>
              </div>
              <div *ngIf="!model.get('issueDiscrepancyReport').value" class="form-group">
                <label></label>
                <div class="itech-control-desc">
                  Общий объём принимаемого и возвращаемого количества должен быть
                  в пределах &#177;5% ({{model.get('clearance5percentCaption').value}})
                </div>
              </div>
            </ng-container>
        </ng-template>
        <ng-template #returnAllAcceptance>
          <div class="form-group">
            <label></label>
            <div class="itech-control-desc">
              На весь объём продукции будет оформлен возвратный ВСД.
              Возвратом считается отправка продукции отправителю в том же транспортном средстве.
              В случае если возврат продукции осуществляется в другом транспортном средстве или не в момент приемки,
              то подобное перемещение не считается возвратом и оформляется отдельным транспортным ВСД.
            </div>
          </div>
        </ng-template>

        <div *ngIf="equalsSome(model.get('acceptanceTypeId').value, 1, 2)" class="form-group itech-group-whole-line">
          <div class="checkbox-inline">
            <input type="checkbox" id="issueDiscrepancyReport" formControlName="issueDiscrepancyReport">
            <label for="issueDiscrepancyReport">Оформить акт несоответствия</label>
          </div>
        </div>

        <ng-container *ngIf="model.get('issueDiscrepancyReport').value">
          <div class="form-group">
            <label for="discrepancyReportDate" class="required">Дата акта</label>
            <label for="discrepancyReportDate" aria-haspopup="true" role="tooltip"
                   class="tooltip tooltip-validation tooltip-md itech-control-medium"
                   [class.invalid]="isInvalid(model, 'discrepancyReportDate')">
                <input type="date" id="discrepancyReportDate" formControlName="discrepancyReportDate" required>
                <app-validation-tooltip [input]="model.controls['discrepancyReportDate']"></app-validation-tooltip>
            </label>
          </div>
          <div class="form-group">
            <label for="discrepancyReportSerial">Серия/Номер акта</label>
            <label for="discrepancyReportSerial" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md">
              <input type="text" id="discrepancyReportSerial" placeholder="Серия акта" class="itech-control-normal"
                     formControlName="discrepancyReportSerial">
            </label>
            <label for="discrepancyReportNumber" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md">
              <input type="text" id="discrepancyReportNumber" placeholder="Номер акта" class="itech-control-normal"
                     formControlName="discrepancyReportNumber">
            </label>
          </div>
          <div class="form-group">
            <label for="discrepancyReportReason">Причина несоответствий</label>
            <label for="discrepancyReportReason" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md">
              <app-input-text [contextId]="'discrepancyReportReason'" [classNames]="'itech-control-xlarge'"
                              [formControlObj]="model.get('discrepancyReportReason')" [htmlType]="1" [placeholder]="'Укажите причину'">
              </app-input-text>
            </label>
          </div>
          <div class="form-group" [style.min-height.px]="65">
            <label for="discrepancyReportCaption">Описание несоответствий</label>
            <label for="discrepancyReportCaption" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                   [class.invalid]="isInvalid(model, 'discrepancyReportCaption')">
              <app-input-text [contextId]="'discrepancyReportCaption'" [classNames]="'itech-control-xlarge no-resize'"
                              [formControlObj]="model.get('discrepancyReportCaption')" [htmlType]="2"
                              [placeholder]="'Опишите несоответствия...'">
              </app-input-text>
              <app-validation-tooltip [input]="model.controls['discrepancyReportCaption']"></app-validation-tooltip>
            </label>
          </div>
              <div class="form-group">
                <label for="stableProductSubtypeId" class="required">Номенклатура</label>
                <app-text-lookup [validationTooltipRight]="true" class="itech-control-xlarge"
                                 [contextControlId]="'discrepancyReportStableProductSubtypeId'" [sorted]="true"
                                 [contextControlName]="'discrepancyReportStableProductSubtypeId'"
                                 [lookupName]="getLookupNameStableProductSubtype()"
                                 [contextFormGroup]="model"></app-text-lookup>
              </div>
              <div class="form-group">
                <label for="discrepancyReportTtnTypeId" class="required">Вид ТТН</label>
                <app-combo-lookup [validationTooltipRight]="true" class="itech-control-large" [disabled]="true"
                                  [contextControlId]="'discrepancyReportTtnTypeId'"
                                  [contextControlName]="'discrepancyReportTtnTypeId'"
                                  [lookupName]="'ttn-type'" [contextFormGroup]="model"></app-combo-lookup>
              </div>
              <div class="form-group" *ngIf="+model.get('discrepancyReportTtnTypeId').value">
                <label for="discrepancyReportTtnSerial">Серия номер и дата ТТН</label>
                <label for="discrepancyReportTtnSerial" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                       [class.invalid]="isInvalid(model, 'discrepancyReportTtnSerial')">
                  <app-input-text [contextId]="'discrepancyReportTtnSerial'" [classNames]="'itech-control-small'"
                                  [formControlObj]="model.get('discrepancyReportTtnSerial')" [htmlType]="1"></app-input-text>
                  <app-validation-tooltip [input]="model.controls['discrepancyReportTtnSerial']"></app-validation-tooltip>
                </label>
                <label for="discrepancyReportTtnNo" aria-haspopup="true" role="tooltip"
                       class="tooltip tooltip-validation tooltip-md"
                       [class.invalid]="isInvalid(model, 'discrepancyReportTtnNo')">
                  <app-input-text [contextId]="'discrepancyReportTtnNo'" [classNames]="'itech-control-small'"
                                  [formControlObj]="model.get('discrepancyReportTtnNo')" [htmlType]="1"></app-input-text>
                  <app-validation-tooltip [input]="model.controls['discrepancyReportTtnNo']"></app-validation-tooltip>
                </label>
                <label for="discrepancyReportTtnDate" aria-haspopup="true" role="tooltip"
                       class="tooltip tooltip-validation tooltip-md"
                       [class.invalid]="isInvalid(model, 'discrepancyReportTtnDate')">
                  <input type="date" id="discrepancyReportTtnDate" class="itech-control-normal"
                         formControlName="discrepancyReportTtnDate">
                  <app-validation-tooltip [input]="model.controls['discrepancyReportTtnDate']"></app-validation-tooltip>
                </label>
              </div>
              <div class="form-group">
                <label for="discrepancyReportVesselNo">№ ТС</label>
                <label for="discrepancyReportVesselNo" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                       [class.invalid]="isInvalid(model, 'discrepancyReportVesselNo')">
                  <app-input-text [contextId]="'discrepancyReportVesselNo'" [classNames]="'itech-control-normal'"
                                  [formControlObj]="model.get('discrepancyReportVesselNo')" [htmlType]="1"
                                  [placeholder]="'машины'"></app-input-text>
                  <app-validation-tooltip [input]="model.controls['discrepancyReportVesselNo']"></app-validation-tooltip>
                </label>
                <label for="discrepancyReportTrailerNo" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                       [class.invalid]="isInvalid(model, 'discrepancyReportTrailerNo')">
                  <app-input-text [contextId]="'discrepancyReportTrailerNo'" [classNames]="'itech-control-normal'"
                                  [formControlObj]="model.get('discrepancyReportTrailerNo')" [htmlType]="1"
                                  [placeholder]="'прицепа'"></app-input-text>
                  <app-validation-tooltip [input]="model.controls['discrepancyReportTrailerNo']"></app-validation-tooltip>
                </label>
                <label for="discrepancyReportContainerNo" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                       [class.invalid]="isInvalid(model, 'discrepancyReportContainerNo')">
                  <app-input-text [contextId]="'discrepancyReportContainerNo'" [classNames]="'itech-control-normal'"
                                  [formControlObj]="model.get('discrepancyReportContainerNo')" [htmlType]="1"
                                  [placeholder]="'контейнера'"></app-input-text>
                  <app-validation-tooltip [input]="model.controls['discrepancyReportContainerNo']"></app-validation-tooltip>
                </label>
              </div>
              <div class="form-group">
                <label for="expireDate">Срок годности</label>
                <app-date-combo [contextFormGroup]="model" [isRequiredDayAndHour]="isRequiredDateField"
                                [controlNameDate1]="'discrepancyReportExpireDate'" [controlNameDate2]="'discrepancyReportExpireDate2'">
                </app-date-combo>
              </div>
            </ng-container>
      </section>
    </div>