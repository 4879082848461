import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { FormBuilder, Validators } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { ProductDataService } from '../../../logic/services/product-data.service';
import { EditProductCommonFormComponent } from './edit-product-common-form.component';
import { Router } from '@angular/router';
import { MetadataService } from '../../../logic/services/metadata.service';
import { SendToClearanceModalComponent } from './send-to-clearance-modal.component';
import { serverSideErrorsValidator } from '../../../logic/validators/server-side-errors-validator.directive';
var EditProductCommonComponent = /** @class */ (function () {
    function EditProductCommonComponent(bookmarkService, productDataService, alertService, cache, dataCachingService, waitingOverlay, fb, navigationService, router, metadataService) {
        this.bookmarkService = bookmarkService;
        this.productDataService = productDataService;
        this.alertService = alertService;
        this.cache = cache;
        this.dataCachingService = dataCachingService;
        this.waitingOverlay = waitingOverlay;
        this.fb = fb;
        this.navigationService = navigationService;
        this.router = router;
        this.metadataService = metadataService;
        this.equalsSome = FormHelper.equalsSome;
        this.isAllowedToDelete = false;
        this.model = {};
        this.storing = false;
        this.loading = false;
        this.updateSyncStateTimer = {};
        this.updateSyncStatePending = {};
        this.deletedModalOpen = false;
        this.deletedFormGroup = this.fb.group({
            deletedReason: null
        });
        this.deletedSelectedCheckboxConfirmAction = false;
        this.rejectProductRequestModalOpen = false;
        this.rejectProductRequestFormGroup = this.fb.group({
            rejectReason: [null, Validators.compose([
                    serverSideErrorsValidator('rejectReason', this.model)
                ])]
        });
    }
    Object.defineProperty(EditProductCommonComponent.prototype, "id", {
        get: function () {
            return this._id;
        },
        set: function (id) {
            if (this._id !== id) {
                this._id = id;
                this.refreshFormDataInternal();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditProductCommonComponent.prototype, "contextFormGroup", {
        get: function () {
            return this.model ? this.model.contextFormGroup : undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditProductCommonComponent.prototype, "syncStates", {
        get: function () {
            return this.model && this.model.syncStates ? Object.values(this.model.syncStates) : undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditProductCommonComponent.prototype, "isRequest", {
        get: function () {
            return this.contextFormGroup && +this.contextFormGroup.controls['status'].value === -1;
        },
        enumerable: true,
        configurable: true
    });
    EditProductCommonComponent.getDefaultModel = function (id) {
        if (id === void 0) { id = null; }
        return { id: id, serverSideValidationErrors: [], syncStates: {} };
    };
    EditProductCommonComponent.prototype.store = function () {
        var _this = this;
        FormHelper.markAsSubmitted(this.contextFormGroup);
        EditProductCommonFormComponent.prettifyFormGroup(this.contextFormGroup);
        if (!this.contextFormGroup.valid) {
            return;
        }
        this.storing = true;
        this.productDataService.storeProductCommonInfo(this.contextFormGroup.value).subscribe(function (val) {
            _this.storing = false;
            _this.cache.invalidateProduct(_this.id);
            _this.bookmarkService.refreshProductCardBookmark(_this.id);
            _this.contextFormGroup.markAsUntouched();
            _this.contextFormGroup.markAsPristine();
            _this.dataCachingService.removeCachedData('EditProductCommon', _this.id.toString());
            _this.model.contextFormGroup = EditProductCommonFormComponent.createFormGroup(_this.fb, _this.model, val);
            _this.dataCachingService.addToCache('EditProductCommon', _this.id.toString(), _this.model);
            FormHelper.setSingleFormGroupServerSideValidationErrorsWithRelationMsgKey({}, _this.model, _this.contextFormGroup);
            _this.alertService.success("\u0414\u043E\u043A\u0443\u043C\u0435\u043D\u0442 \u0443\u0441\u043F\u0435\u0448\u043D\u043E \u0441\u043E\u0445\u0440\u0430\u043D\u0435\u043D");
            _this.refreshSyncState();
        }, function (error) {
            _this.storing = false;
            FormHelper.setSingleFormGroupServerSideValidationErrorsWithRelationMsgKey(error, _this.model, _this.contextFormGroup);
            window.document.getElementById('product-common-content').scrollTop = 0;
        });
    };
    EditProductCommonComponent.prototype.isInvalid = function (fg, cname) {
        return FormHelper.isInvalid(fg, cname);
    };
    EditProductCommonComponent.prototype.cancelEdit = function () {
        this.dataCachingService.removeCachedData('EditProductCommon', this.id.toString());
        this.refreshFormDataInternal();
    };
    EditProductCommonComponent.prototype.refreshFormData = function () {
        this.loading = true;
        this.dataCachingService.removeCachedData('EditProductCommon', this.id.toString());
        this.refreshFormDataInternal();
    };
    EditProductCommonComponent.prototype.refreshFormDataInternal = function () {
        var _this = this;
        this.waitingOverlay.StartWaiting();
        this.model = EditProductCommonComponent.getDefaultModel(this.id);
        var existing = this.dataCachingService.getCachedData('EditProductCommon', this.id.toString());
        if (existing) {
            this.model = existing;
            this.waitingOverlay.EndWaiting();
        }
        else {
            this.productDataService.getProductCommonForEdit(this.id).subscribe(function (data) {
                _this.model.id = data.id;
                _this.model.contextFormGroup = EditProductCommonFormComponent.createFormGroup(_this.fb, _this.model, data);
                _this.cache.invalidateProduct(_this.id);
                _this.dataCachingService.addToCache('EditProductCommon', _this.id.toString(), _this.model);
                _this.waitingOverlay.EndWaiting();
                _this.refreshSyncState();
                _this.loading = false;
            }, function () {
                _this.waitingOverlay.EndWaiting();
                _this.loading = false;
            });
        }
    };
    EditProductCommonComponent.prototype.deleteDocument = function () {
        var _this = this;
        this.alertService.confirmModal('Вы уверены, что хотите удалить безвозвратно карточку продукции?').subscribe(function () {
            _this.productDataService.deleteProduct(_this.id).subscribe(function () {
                _this.bookmarkService.removeOpenCardBookmark(['/product-edit'], _this.id.toString());
                _this.router.navigate(['/product-search']);
            });
        });
    };
    EditProductCommonComponent.prototype.sendToRegistration = function () {
        var _this = this;
        if (!this.validateRegionalization()) {
            return;
        }
        this.alertService.confirmModal('Вы уверены, что хотите направить документ на регистрацию в Меркурий?').subscribe(function () {
            _this.productDataService.sendToRegistration([_this.id]).subscribe(function () {
                _this.refreshFormData();
            }, function (error) { return FormHelper.setSingleFormGroupServerSideValidationErrors(error, { serverSideValidationErrors: [] }, _this.contextFormGroup); });
        });
    };
    EditProductCommonComponent.prototype.sendToDeletion = function () {
        var _this = this;
        this.productDataService.sendToDeletion(this.id, this.deletedFormGroup.get('deletedReason').value)
            .subscribe(function () {
            _this.closeDeletedModal();
            _this.refreshFormData();
        }, function (error) { return FormHelper.setSingleFormGroupServerSideValidationErrors(error, { serverSideValidationErrors: [] }, _this.deletedFormGroup); });
    };
    EditProductCommonComponent.prototype.sendToClearance = function () {
        var _this = this;
        var source = this.contextFormGroup && this.contextFormGroup.get('sources')
            ? this.contextFormGroup.get('sources').at(0)
            : null;
        var path = this.contextFormGroup && this.contextFormGroup.get('path')
            ? this.contextFormGroup.get('path').at(this.contextFormGroup.get('path').value.length - 2)
            : null;
        this.navigationService.sendProductToClearance(SendToClearanceModalComponent, tslib_1.__assign({ productId: this.id, volume: source ? source.get('value').value : 0, fullVolume: source ? source.get('value').value : 0, unitId: source ? source.get('unitId').value : null, productFormGroup: this.contextFormGroup }, tslib_1.__assign({}, (source ? source.value : {}), (path ? path.value : {})))).subscribe(function () { return _this.refreshFormData(); }, function (error) { return FormHelper.setSingleFormGroupServerSideValidationErrors(error, { serverSideValidationErrors: [] }, _this.contextFormGroup); });
    };
    EditProductCommonComponent.prototype.cloneProduct = function (fromTemplate) {
        if (fromTemplate === void 0) { fromTemplate = false; }
        this.navigationService.performProductCreation(undefined, this.id, undefined, undefined, undefined, undefined, fromTemplate);
    };
    EditProductCommonComponent.prototype.deriveProduct = function (transactionTypeId) {
        this.navigationService.performProductDerivation(this.id, transactionTypeId);
    };
    EditProductCommonComponent.prototype.refreshSyncState = function () {
        this.model.syncStates = {};
        if (+this.contextFormGroup.get('status').value === 0) {
            this.refreshSyncStateInternal(3);
        }
        else if (+this.contextFormGroup.get('status').value > 0) {
            this.refreshSyncStateInternal(3);
            this.refreshSyncStateInternal(4);
            this.refreshSyncStateInternal(6);
        }
        else if (+this.contextFormGroup.get('status').value === -1) {
            this.refreshSyncStateInternal(11);
        }
    };
    EditProductCommonComponent.prototype.refreshSyncStateInternal = function (conversationTypeId) {
        var _this = this;
        if (!this.contextFormGroup) {
            return;
        }
        if (this.equalsSome(conversationTypeId, 3, 4, 6) && +this.contextFormGroup.get('status').value < 0) {
            return;
        }
        else if (this.equalsSome(conversationTypeId, 11) && +this.contextFormGroup.get('status').value !== -1) {
            return;
        }
        var state = this.model && this.model.syncStates ? this.model.syncStates[conversationTypeId] : undefined;
        // если ничего не запланировано по синхронизации - ничего и не может произойти и обновлять состояние не надо
        if (state && (!state.mercuryExchangeNextTime || new Date(state.mercuryExchangeNextTime) > new Date())) {
            return;
        }
        if (this.updateSyncStatePending[conversationTypeId]) {
            return;
        }
        this.updateSyncStatePending[conversationTypeId] = true;
        this.metadataService.getSyncState(this.id, conversationTypeId).subscribe({
            next: function (data) {
                if (_this.contextFormGroup && data && +conversationTypeId === 11) {
                    data.withoutMercuryUuid = true;
                    if (_this.equalsSome(_this.contextFormGroup.get('requestStatus').value, -1, 2)) {
                        data.lookupStage = 'product-request/accept';
                    }
                }
                _this.updateSyncStatePending[conversationTypeId] = false;
                _this.model.syncStates[conversationTypeId] = data;
                if (_this.model.syncStates[conversationTypeId]) {
                    _this.model.syncStates[conversationTypeId].isEditStarted = false;
                }
            }, error: function () {
                _this.updateSyncStatePending[conversationTypeId] = false;
                _this.model.syncStates[conversationTypeId] = {};
            }
        });
    };
    EditProductCommonComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.updateSyncStateTimer[3] = setInterval(function () { return _this.refreshSyncStateInternal(3); }, 10000);
        this.updateSyncStateTimer[4] = setInterval(function () { return _this.refreshSyncStateInternal(4); }, 10000);
        this.updateSyncStateTimer[6] = setInterval(function () { return _this.refreshSyncStateInternal(6); }, 10000);
        this.updateSyncStateTimer[11] = setInterval(function () { return _this.refreshSyncStateInternal(11); }, 10000);
    };
    EditProductCommonComponent.prototype.ngOnDestroy = function () {
        var _this = this;
        if (this.updateSyncStateTimer) {
            Object.keys(this.updateSyncStateTimer).forEach(function (x) { return clearInterval(_this.updateSyncStateTimer[x]); });
            this.updateSyncStateTimer = {};
        }
    };
    EditProductCommonComponent.prototype.isTransportOrMovingTransaction = function () {
        return EditProductCommonFormComponent.isTransportOrMovingTransaction(this.contextFormGroup);
    };
    EditProductCommonComponent.prototype.isTransportTransaction = function () {
        return EditProductCommonFormComponent.isTransportTransaction(this.contextFormGroup);
    };
    EditProductCommonComponent.prototype.closeDeletedModal = function () {
        this.deletedModalOpen = false;
        this.deletedSelectedCheckboxConfirmAction = false;
        this.deletedFormGroup.reset();
    };
    EditProductCommonComponent.prototype.isAvailableReports = function () {
        return this.contextFormGroup && this.isTransportOrMovingTransaction() &&
            this.equalsSome(+this.contextFormGroup.get('status').value, 2, 3);
    };
    EditProductCommonComponent.prototype.validateRegionalization = function () {
        if (!this.contextFormGroup) {
            return false;
        }
        if (!this.isTransportTransaction() || !this.contextFormGroup.get('hasRequirements').value) {
            return true;
        }
        FormHelper.markAsSubmitted(this.contextFormGroup);
        var valid = true;
        for (var _i = 0, _a = this.contextFormGroup.get('path').controls; _i < _a.length; _i++) {
            var path = _a[_i];
            if (path.get('regionalizationRequirements').value.length) {
                for (var _b = 0, _c = path.get('regionalizationRequirements').controls; _b < _c.length; _b++) {
                    var requirement = _c[_b];
                    if (!requirement.get('conditionGroups').getRawValue().some(function (x) { return x.acceptedGroup; })) {
                        valid = false;
                        requirement.get('conditionGroups').controls
                            .forEach(function (x) { return x.get('acceptedGroup').setErrors({ 'incorrect': true }); });
                    }
                }
            }
        }
        if (!valid) {
            this.alertService.error('Перед отправкой на регистрацию, отметьте условия регионализации, сохраните документ и повторите отправку');
        }
        return valid;
    };
    EditProductCommonComponent.prototype.applyRequest = function () {
        var _this = this;
        this.alertService.confirmModal('Вы уверены, что хотите принять положительное решение по заявки и оформить по ней транзакцию?')
            .subscribe(function (val) {
            if (val) {
                _this.processDecisionMakingRequest(2);
            }
        });
    };
    EditProductCommonComponent.prototype.closeRejectProductRequestModal = function () {
        this.rejectProductRequestModalOpen = false;
        this.rejectProductRequestFormGroup.reset();
    };
    EditProductCommonComponent.prototype.processDecisionMakingRequest = function (newProductRequestStatus) {
        var _this = this;
        this.productDataService.startSyncDecisionMakingProductRequest({
            productRequestIds: [this.id],
            newProductRequestStatus: newProductRequestStatus,
            rejectReason: this.rejectProductRequestFormGroup.get('rejectReason').value,
        })
            .subscribe(function () {
            _this.closeRejectProductRequestModal();
            _this.refreshFormData();
        }, function (error) { return FormHelper.setSingleFormGroupServerSideValidationErrors(error, _this.model, _this.rejectProductRequestFormGroup); });
    };
    EditProductCommonComponent.prototype.isAllowedToDeleteCheck = function () {
        var find = this.syncStates.find(function (syncState) { return syncState.conversationTypeId === 3; });
        if (find) {
            if ((this.equalsSome(find.mercuryExchangeStatus, 0, -1) && find.conversationStageId < 5) ||
                this.model.contextFormGroup.get('mercuryUuid').value === null ||
                !this.equalsSome(this.model.contextFormGroup.get('status').value, 2, 3, 4, 6)) {
                this.isAllowedToDelete = true;
            }
        }
        else {
            if (this.equalsSome(this.contextFormGroup.controls['status'].value, 0, -100)) {
                this.isAllowedToDelete = true;
            }
        }
    };
    return EditProductCommonComponent;
}());
export { EditProductCommonComponent };
