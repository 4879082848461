/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./app-link-button.component.ngfactory";
import * as i3 from "./app-link-button.component";
import * as i4 from "../../logic/services/app-navigation.service";
import * as i5 from "../../logic/services/lookup-source.service";
import * as i6 from "../../logic/services/address-person-fio-cache.service";
import * as i7 from "../../../../node_modules/@clr/angular/clr-angular.ngfactory";
import * as i8 from "@clr/angular";
import * as i9 from "./app-trapped-quarantine-signpost.component";
var styles_AppTrappedQuarantineSignpostComponent = [];
var RenderType_AppTrappedQuarantineSignpostComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppTrappedQuarantineSignpostComponent, data: {} });
export { RenderType_AppTrappedQuarantineSignpostComponent as RenderType_AppTrappedQuarantineSignpostComponent };
function View_AppTrappedQuarantineSignpostComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.getLookupCaption(_v.parent.context.$implicit.value[0].trappedTypeId))); _ck(_v, 1, 0, currVal_0); }); }
function View_AppTrappedQuarantineSignpostComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "td", [["style", "width:30px;vertical-align:middle"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "td", [["style", "width:300px;vertical-align:middle"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "app-link-button", [], null, null, null, i2.View_AppLinkButtonComponent_0, i2.RenderType_AppLinkButtonComponent)), i0.ɵdid(5, 573440, null, 0, i3.AppLinkButtonComponent, [i4.AppNavigationService, i5.LookupSourceService, i6.AddressPersonFioCacheService], { id: [0, "id"], lookupName: [1, "lookupName"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 2, "td", [["style", "width:200px;vertical-align:middle"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppTrappedQuarantineSignpostComponent_5)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.key; var currVal_2 = "quarantine"; _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_3 = _v.context.$implicit.value; _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (_v.context.index + 1); _ck(_v, 2, 0, currVal_0); }); }
function View_AppTrappedQuarantineSignpostComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "form compact"], ["style", "text-align:left;padding:0"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "table", [["class", "table"], ["style", "margin:0;border:none"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "tbody", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_AppTrappedQuarantineSignpostComponent_4)), i0.ɵdid(4, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpid(0, i1.KeyValuePipe, [i0.KeyValueDiffers])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform(_co.trappedQuarantine)); _ck(_v, 4, 0, currVal_0); }, null); }
function View_AppTrappedQuarantineSignpostComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "clr-signpost-content", [["class", "itech-signpost-content"], ["style", "max-width:500px"]], [[2, "signpost-content", null], [8, "id", 0], [2, "is-off-screen", null]], null, null, i7.View_ClrSignpostContent_0, i7.RenderType_ClrSignpostContent)), i0.ɵprd(1024, null, i8.ɵo, i8.ɵp, []), i0.ɵdid(2, 8568832, null, 0, i8.ClrSignpostContent, [i0.Injector, [2, i8.ɵh], i8.ClrCommonStringsService, i8.ɵo, i8.ɵda], { position: [0, "position"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_AppTrappedQuarantineSignpostComponent_3)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = "right-bottom"; _ck(_v, 2, 0, currVal_3); var currVal_4 = _co.trappedQuarantine; _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = i0.ɵnov(_v, 2).signpostContentId; var currVal_2 = i0.ɵnov(_v, 2).isOffScreen; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_AppTrappedQuarantineSignpostComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 15, "div", [["class", "trigger-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 14, "clr-signpost", [["class", "itech-trapped-quarantine-lbl"]], [[2, "signpost", null]], null, null, i7.View_ClrSignpost_0, i7.RenderType_ClrSignpost)), i0.ɵprd(6144, null, i8.ɵh, null, [i0.ElementRef]), i0.ɵdid(3, 49152, null, 1, i8.ClrSignpost, [i8.ClrCommonStringsService], null, null), i0.ɵqud(335544320, 1, { customTrigger: 0 }), i0.ɵprd(512, null, i8.ɵd, i8.ɵd, []), i0.ɵprd(512, null, i8.ɵda, i8.ɵda, []), (_l()(), i0.ɵeld(7, 0, null, 0, 6, "a", [["class", "itech-inline-link-btn signpost-trigger"], ["clrSignpostTrigger", ""], ["style", "display:grid;color:red"]], [[1, "aria-label", 0], [1, "aria-expanded", 0], [1, "aria-controls", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 8).onSignpostTriggerClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(8, 147456, [[1, 4]], 0, i8.ClrSignpostTrigger, [i8.ɵd, i0.Renderer2, i0.ElementRef, i8.ClrCommonStringsService, i8.ɵda, i0.PLATFORM_ID], null, null), (_l()(), i0.ɵeld(9, 0, null, null, 1, "clr-icon", [["shape", "flame"], ["size", "60"]], null, null, null, null, null)), i0.ɵdid(10, 16384, null, 0, i8.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵeld(11, 0, null, null, 2, "label", [], [[1, "for", 0]], null, null, null, null)), i0.ɵdid(12, 212992, null, 0, i8.ClrLabel, [[2, i8.ɵbe], [2, i8.ɵbf], [2, i8.ɵbg], i0.Renderer2, i0.ElementRef], null, null), (_l()(), i0.ɵted(-1, null, ["\u041A\u0430\u0440\u0430\u043D\u0442\u0438\u043D"])), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_AppTrappedQuarantineSignpostComponent_2)), i0.ɵdid(15, 147456, null, 0, i8.ClrIfOpen, [i8.ɵd, i0.TemplateRef, i0.ViewContainerRef], { open: [0, "open"] }, null)], function (_ck, _v) { _ck(_v, 12, 0); var currVal_5 = null; _ck(_v, 15, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = true; _ck(_v, 1, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 8).commonStrings.keys.signpostToggle; var currVal_2 = i0.ɵnov(_v, 8).ariaExpanded; var currVal_3 = i0.ɵnov(_v, 8).ariaControl; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = i0.ɵnov(_v, 12).forAttr; _ck(_v, 11, 0, currVal_4); }); }
export function View_AppTrappedQuarantineSignpostComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppTrappedQuarantineSignpostComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.trappedQuarantine; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AppTrappedQuarantineSignpostComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-trapped-quarantine-signpost", [], null, null, null, View_AppTrappedQuarantineSignpostComponent_0, RenderType_AppTrappedQuarantineSignpostComponent)), i0.ɵdid(1, 49152, null, 0, i9.AppTrappedQuarantineSignpostComponent, [i5.LookupSourceService, i4.AppNavigationService], null, null)], null, null); }
var AppTrappedQuarantineSignpostComponentNgFactory = i0.ɵccf("app-trapped-quarantine-signpost", i9.AppTrappedQuarantineSignpostComponent, View_AppTrappedQuarantineSignpostComponent_Host_0, { trappedQuarantine: "trappedQuarantine", objectCaption: "objectCaption" }, {}, []);
export { AppTrappedQuarantineSignpostComponentNgFactory as AppTrappedQuarantineSignpostComponentNgFactory };
