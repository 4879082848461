import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { MetadataService } from '../../../logic/services/metadata.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MetaBrowseBaseComponent } from '../meta-browse.base.component';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';

@Component({
  templateUrl: './addr-city-browse.component.html'
})
export class AddrCityBrowseComponent extends MetaBrowseBaseComponent {

  addrCityTypeMap: any;
  addrRegionMap: any;

  gridColumnDefs = [
    {headerName: '№', field: 'id', width: 100},
    {
      headerName: 'Тип', field: 'type', width: 100,
      valueFormatter: params => this.addrCityTypeMap[params.value]
    },
    {headerName: 'Наименование', field: 'caption', width: 300},
    {
      headerName: 'Муниципальное образование', field: 'regionId', tooltipField: 'regionId', width: 250,
      valueFormatter: params => this.addrRegionMap[params.value]
    },
    {headerName: 'РК', field: 'rk', tooltipField: 'rk', width: 100},
    {headerName: 'Код ПФР 6.2', field: 'codePfr62', tooltipField: 'codePfr62', width: 180}
  ];

  constructor(route: ActivatedRoute,
              metadataService: MetadataService,
              alertService: AlertService,
              globalWaitingOverlayService: GlobalWaitingOverlayService,
              lookupService: LookupSourceService,
              fb: FormBuilder,
              bookmarkService: BookmarkService,
              dataCachingService: DataCachingService) {
    super(route, metadataService, alertService, globalWaitingOverlayService, lookupService, fb, bookmarkService, dataCachingService);
    this.lookupService.getLookupObj('addr-city-type').subscribe(dict => { this.addrCityTypeMap = dict; });
    this.lookupService.getLookupObj('addr-region').subscribe(dict => { this.addrRegionMap = dict; });
  }

  getMetaTitle(): string {
    return 'Населенные пункты';
  }

  getMetaName(): string {
    return 'addr-city';
  }

  getId(formGroup: FormGroup): any {
    return formGroup.get('regionId').value && formGroup.get('id').value
      ? formGroup.get('regionId').value + '/' + formGroup.get('id').value
      : undefined;
  }

  getGroupDef(row: any) {
    return {
      id: row.id,
      type: [row.type, Validators.required],
      regionId: [row.regionId, Validators.required],
      caption: [row.caption, Validators.required],
      rk: [row.rk, Validators.compose([Validators.pattern(/^\d\.\d$/), Validators.required])],
      isDefault: false,
      codePfr62: row.codePfr62,
      aoguid: row.aoguid,
    };
  }
}
