
    <ng-container *ngIf="getControl().value; else noValue">
        <span style="max-width:calc(100% - 20px);overflow:hidden;text-overflow:ellipsis;padding-left:10px">
          {{getControl().value|lookup:params.lookupName:true|async}}
        </span>
        <button type="button" class="btn btn-danger btn-link itech-inline-tool-btn" title="Очистить"
                (click)="clearValue()" style="position:absolute;right:0;top:0;z-index:10;color:grey">
          <clr-icon shape="times"></clr-icon>
        </button>
    </ng-container>
    <ng-template #noValue>
      <button type="button" (click)="search()" class="btn btn-danger btn-link itech-inline-tool-btn"
              [style.width]="getControl().value ? '20px' : '100%'">
        <clr-icon shape="search"></clr-icon>
        <span *ngIf="!getControl().value">Поиск...</span>
      </button>
    </ng-template>
  