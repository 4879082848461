import { Component, Input } from '@angular/core';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AppFormGroup } from '../../../ui/controls/app-form-group';

@Component({
  selector: 'app-edit-agent-person-form',
  templateUrl: './edit-agent-person-form.component.html'
})
export class EditAgentPersonFormComponent {

  @Input() contextFormGroup: AppFormGroup;
  @Input() syncState: any;

  rndToken = Math.floor(Math.random() * 1000000);

  isInvalid = FormHelper.isInvalid;
}
