import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { StringHelper } from '../../helpers/string-helper';
import { map } from 'rxjs/internal/operators';
import { Router } from '@angular/router';
import { DataCachingService } from './data-caching.service';

@Injectable()
export class SecurityService {

  constructor(private http: HttpClient,
              private router: Router,
              private dataCachingService: DataCachingService) {
  }

  private userObservable: BehaviorSubject<any>;
  private loggingOut = false;
  private userMercuryHasActiveSession: BehaviorSubject<boolean>;

  public getUserInfo(): Observable<any> {

    if (!this.userObservable) {
      this.userObservable = new BehaviorSubject<any>({});

      if (!this.loggingOut) {
        this.queryServerUserInfo().subscribe((response: any) => {
          this.userObservable.next(response);

          this.isAccess('dashboard').subscribe(hasAccess => {
            if (!hasAccess) {
              this.router.navigate(['/pst-report']);
            }
          });

          this.userObservable.getValue().userSettingUi.groupingTags = this.groupingTags(this.userObservable.getValue().userSettingUi.tags);
          this.addToCacheTableSetting(this.userObservable.getValue().userSettingUi.tableSettings || []);
        });
      }
    }

    return this.userObservable;
  }

  public invalidateCurrentUser() {
    this.userObservable = undefined;
  }

  public markCurrentUserLoggingOut() {
    this.loggingOut = true;
    this.invalidateCurrentUser();
  }

  public queryServerUserInfo(): Observable<any> {

    const qstring = environment.api + '/security/current-user-info';

    return this.http.get(qstring);
  }

  public getCurrentUserShortFio(): Observable<string> {
    return this.getUserInfo().pipe(map(userInfo => userInfo.lastName ? StringHelper.getPersonShortTitle(userInfo) : ''));
  }

  public getCurrentUserId(): Observable<number> {
    return this.getUserInfo().pipe(map(userInfo => userInfo.id));
  }

  public currentUserInfoLoaded(): boolean {
    return this.userObservable && this.userObservable.value && this.userObservable.value.id;
  }

  public getJournalByObject(objKindId: number, objId1: number, objId2: number): Observable<any[]> {
    const qstring = environment.api + '/security/journal/by-object/' + objKindId + '/' + objId1
      + (objId2 ? '/' + objId2 : '');
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  public getJournalByUser(userId: number, dateFrom: Date, dateTo: Date): Observable<any[]> {
    const qstring = environment.api + '/security/journal/by-user/' + userId + '/' + StringHelper.getISODate(dateFrom)
      + '/' + StringHelper.getISODate(dateTo);
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  public isAccess(pageName: string): Observable<boolean> {
    return this.getUserInfo()
      .pipe(map(userInfo => !userInfo.accessToSections || userInfo.accessToSections[pageName]));
  }

  public changeFavourite(stableId: number): Observable<any> {
    const user = this.userObservable.getValue();
    if ((user.favouriteStableIds || []).includes(stableId)) {
      return this.removeStableFromFavourite(stableId)
        .pipe(map(() => this.removeFavouriteStableFromCurrentUser(stableId)));
    } else {
      return this.addStableToFavourite(stableId)
        .pipe(map(() => this.addFavouriteStableToCurrentUser(stableId)));
    }
  }

  private addStableToFavourite(stableId: number): Observable<any> {
    const qstring = environment.api + '/security/add-to-favourite-user/' + stableId;
    return this.http.post(qstring, {})
      .pipe(map((response: any) => {
        return response as any;
      }));
  }

  private removeStableFromFavourite(stableId: number): Observable<any> {
    const qstring = environment.api + '/security/remove-from-favourite-user/' + stableId;
    return this.http.post(qstring, {})
      .pipe(map((response: any) => {
        return response as any;
      }));
  }

  public addFavouriteStableToCurrentUser(stableId: number) {
    this.userObservable.getValue().favouriteStableIds.push(stableId);
  }

  public removeFavouriteStableFromCurrentUser(stableId: number) {
    const index = this.userObservable.getValue().favouriteStableIds.indexOf(stableId);
    if (index > -1) {
      this.userObservable.getValue().favouriteStableIds.splice(index, 1);
    }
  }

  public storeUserTag(tag: any): Observable<any[]> {
    const qstring = environment.api + '/security/store-user-tag';
    return this.http.post(qstring, tag)
      .pipe(map((response: any) => {
        this.updateTags(response.data);
        return response.data as any[];
      }));
  }

  public removeUserTag(tagId: number): Observable<any[]> {
    const qstring = environment.api + '/security/remove-user-tag/' + tagId;
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        this.updateTags(response.data);
        return response.data as any[];
      }));
  }

  private updateTags(tags: any[]) {
    this.userObservable.getValue().userSettingUi.tags = tags;
    this.userObservable.getValue().userSettingUi.groupingTags = this.groupingTags(tags);
  }

  private groupingTags(tags: any[]): any {
      if (!tags || !tags.length) {
        return undefined;
      }
      const group = {};

      tags.forEach(tag => {
        const groupName = tag.groupCaption || '-1';
        if (!group[groupName]) {
          group[groupName] = [];
        }
        group[groupName].push(tag);
      });

      return group;
  }

  public storeTableSetting(tableSetting: any): Observable<any[]> {
    const qstring = environment.api + '/security/store-table-setting';
    return this.http.post(qstring, tableSetting)
      .pipe(map((response: any) => {
        this.addToCacheTableSetting(response.data);
        return response.data as any[];
      }));
  }

  private addToCacheTableSetting(settings: any[] = []) {
    settings.forEach(setting => {
      if (setting.sort) {
        this.dataCachingService.addToCache(setting.tableName + '_SortModel', '1', JSON.parse(setting.sort));
      }
      if (setting.filter) {
        this.dataCachingService.addToCache(setting.tableName + '_FilterModel', '1', JSON.parse(setting.filter));
      }
      if (setting.pinned) {
        this.dataCachingService.addToCache(setting.tableName + '_PinnedModel', '1', JSON.parse(setting.pinned));
      }
      if (setting.visible) {
        this.dataCachingService.addToCache(setting.tableName + '_VisibleModel', '1', JSON.parse(setting.visible));
      }
      if (setting.moved) {
        this.dataCachingService.addToCache(setting.tableName + '_MovedModel', '1', JSON.parse(setting.moved));
      }
      if (setting.widthCols) {
        this.dataCachingService.addToCache(setting.tableName + '_ResizeModel', '1', JSON.parse(setting.widthCols));
      }
    });
  }

  public getUserMercurySession(): Observable<boolean> {
    if (!this.userMercuryHasActiveSession) {
      this.userMercuryHasActiveSession = new BehaviorSubject<boolean>(false);
      this.userMercuryIsActiveSession().subscribe(val => this.userMercuryHasActiveSession.next(val));
      setInterval(
        () => this.userMercuryIsActiveSession().subscribe(val => this.userMercuryHasActiveSession.next(val)),
        10 * 60 * 1000); // раз в 10 минут
    }

    return this.userMercuryHasActiveSession;
  }

  public invalidateUserMercurySession() {
    this.userMercuryHasActiveSession = undefined;
  }

  private userMercuryIsActiveSession(): Observable<boolean> {
    const qstring = environment.api + '/security/user-mercury/is-active-session';
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response.data as boolean;
      }));
  }

  public getUserMercuryGoToAuth(): Observable<any> {
    const qstring = environment.api + '/security/user-mercury/go-to-auth';
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response.data as any;
      }));
  }

  public tryUserMercuryAuth(data: any): Observable<any> {
    const qstring = environment.api + '/security/user-mercury/auth';
    return this.http.post(qstring, data)
      .pipe(map((response: any) => {
        return response.data as any;
      }));
  }

  public logoutUserMercury(): Observable<any> {
    const qstring = environment.api + '/security/user-mercury/logout';
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response.data as any;
      }));
  }
}
