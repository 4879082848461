import { EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { BehaviorSubject } from 'rxjs';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { StringHelper } from '../../../helpers/string-helper';
import { DateHelper } from '../../../helpers/date-helper';
import { InstitutionTicketDataService } from '../../../logic/services/institution-ticket-data.service';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "../../../logic/services/lookup-source.service";
import * as i3 from "../../../logic/services/institution-ticket-data.service";
import * as i4 from "../../../ui/infrastructure/alert.service";
import * as i5 from "../../../logic/services/address-person-fio-cache.service";
var TicketsPlanningUiService = /** @class */ (function () {
    function TicketsPlanningUiService(fb, lookupService, institutionTicketDataService, alertService, cacheService) {
        this.fb = fb;
        this.lookupService = lookupService;
        this.institutionTicketDataService = institutionTicketDataService;
        this.alertService = alertService;
        this.cacheService = cacheService;
        this.editTicketModalOpened = false;
        this.deleteTicketModalOpened = false;
        this.modalValidationErrorsHost = { serverSideValidationErrors: [] };
        this.actionTicketEmitter = undefined;
        this.storing = false;
        this.deleting = false;
    }
    TicketsPlanningUiService.getGroupDef = function (fb, data) {
        return fb.group({
            id: data.id,
            isTemplate: data.isTemplate || !!data.templateCaption || false,
            isDistribute: data.isDistribute || false,
            templateCaption: [data.templateCaption, FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent && ctrl.parent.get('isTemplate').value; })],
            createdTime: data.createdTime,
            createdUser: data.createdUser,
            startTime: [data.startTime, Validators.compose([FormHelper.validateDateTimePicker(),
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent && !ctrl.parent.get('isTemplate').value && !ctrl.parent.get('isDistribute').value; })])],
            durationMinutes: [data.durationMinutes, Validators.compose([Validators.pattern(/^\d{1,4}$/),
                    Validators.min(0), Validators.max(1440),
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent && !ctrl.parent.get('isTemplate').value && !ctrl.parent.get('isDistribute').value; })])],
            institutionId: [data.institutionId, Validators.compose([Validators.required])],
            institutionBranchId: [data.institutionBranchId, Validators.compose([FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent && !ctrl.parent.get('isTemplate').value && !ctrl.parent.get('isDistribute').value; })])],
            institutionEmployeeId: [data.institutionEmployeeId, Validators.compose([FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent && !ctrl.parent.get('isTemplate').value && !ctrl.parent.get('isDistribute').value; })])],
            institutionTicketQuestIds: fb.array(data.institutionTicketQuestIds || []),
            selectedInstitutionTicketQuestIds: fb.array(data.selectedInstitutionTicketQuestIds || []),
            cabinetNumber: [data.cabinetNumber, Validators.compose([FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent && !ctrl.parent.get('isTemplate').value && !ctrl.parent.get('isDistribute').value; })])],
            comment: data.comment,
            animalTypeId: data.animalTypeId,
            animalSubtypeId: data.animalSubtypeId,
            unavailableTime: [data.unavailableTime, Validators.compose([FormHelper.validateDateTimePicker(),
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent && !ctrl.parent.get('isTemplate').value && !ctrl.parent.get('isDistribute').value; })])],
            agentId: data.agentId,
            animalId: data.animalId,
            isAnimalExternal: data.isAnimalExternal || false,
            reservationTime: [data.reservationTime, FormHelper.validateDateTimePicker()],
            reservationUser: data.reservationUser,
            cancelTime: [data.cancelTime, FormHelper.validateDateTimePicker()],
            cancelUser: data.cancelUser,
            cancelCaption: [data.cancelCaption],
            dateSuccessful: [data.dateSuccessful, FormHelper.validateDateTimePicker()],
            userSuccessful: data.userSuccessful,
            durationTypeId: [data.durationTypeId, FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent && ctrl.parent.get('isDistribute').value; })],
            daysOfWeek: fb.array(data.daysOfWeek || []),
            periodicityTypeId: [data.periodicityTypeId, FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent && ctrl.parent.get('isDistribute').value; })],
            inclusiveTimeDayStart: [data.inclusiveTimeDayStart, FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent && ctrl.parent.get('isDistribute').value; })],
            inclusiveTimeDayEnd: [data.inclusiveTimeDayEnd],
            exclusiveTimeDayStart: [data.exclusiveTimeDayStart],
            exclusiveTimeDayEnd: [data.exclusiveTimeDayEnd],
            isDeleteInsteadAdding: data.isDeleteInsteadAdding || false,
            unavailableHour: [data.unavailableHour, Validators.compose([Validators.pattern(/^\d{1,3}$/),
                    Validators.min(1), Validators.max(336),
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent && ctrl.parent.get('isDistribute').value; })])],
        });
    };
    TicketsPlanningUiService.prototype.addTicket = function (slot, model, oneDay) {
        var startDate = slot ? this.calculateFirstEmptyTime(slot.day.date, slot) : oneDay.date;
        this.editTicket({
            data: {
                startTime: StringHelper.getISODateWithHourMinute(startDate),
                unavailableTime: StringHelper.getISODateWithHourMinute(DateHelper.addDays(startDate, -1)),
                institutionId: model.institutionId,
                institutionBranchId: model.searchParams.get('institutionBranchId').value,
                institutionEmployeeId: model.institutionEmployeeId,
            },
        });
    };
    TicketsPlanningUiService.prototype.editTicket = function (data, readonly, actionTicketEmitter) {
        if (readonly === void 0) { readonly = false; }
        this.editedTicket = data;
        this.ticketReadonly = readonly;
        this.editTicketModalOpened = true;
        this.modalValidationErrorsHost.serverSideValidationErrors = [];
        this.ticketFormGroup = TicketsPlanningUiService.getGroupDef(this.fb, this.editedTicket.data);
        this.actionTicketEmitter = actionTicketEmitter;
    };
    TicketsPlanningUiService.prototype.getSlotCaption = function (value) {
        var retVal = new BehaviorSubject('');
        if (value && value.tickets && value.tickets.length > 0) {
            this.getSlotTicketCaption(value.timeStart, value.tickets, retVal);
        }
        else {
            retVal.next(value.timeStart + ' <<< НЕ РАСПРЕДЕЛЕНО >>>');
        }
        return retVal;
    };
    TicketsPlanningUiService.prototype.getSlotTicketCaption = function (time, tickets, retVal) {
        if (tickets.length === 0) {
            return;
        }
        if (tickets.length === 1) {
            if (tickets[0].data.agentId) {
                this.cacheService.getAgent(tickets[0].data.agentId)
                    .subscribe(function (val) {
                    retVal.next(time + " " + (val.shortCaption || val.caption));
                });
            }
            else {
                retVal.next(time + " 1 " + (tickets[0].data.cancelTime ? 'ОТМ.' : 'СВОБ.'));
            }
        }
        else {
            var reservationCount_1 = 0;
            var cancelCount_1 = 0;
            tickets.filter(function (x) {
                if (x.data.cancelTime) {
                    cancelCount_1++;
                }
                else if (x.data.agentId) {
                    reservationCount_1++;
                }
            });
            var caption = '';
            var isHaveFree = tickets.length > (reservationCount_1 + cancelCount_1);
            if (isHaveFree) {
                caption += (tickets.length - reservationCount_1) + ' СВОБ.';
            }
            if (reservationCount_1 > 0) {
                caption += (isHaveFree ? ' | ' : '') + reservationCount_1 + ' ЗАРЕЗ.';
            }
            if (cancelCount_1 > 0) {
                caption += (isHaveFree || reservationCount_1 > 0 ? ' | ' : '') + cancelCount_1 + ' ОТМ.';
            }
            retVal.next(time + ' ' + caption);
        }
    };
    TicketsPlanningUiService.prototype.createTemplateFromCurrentTicket = function () {
        this.createTemplate(Object.assign({}, this.ticketFormGroup.value));
    };
    TicketsPlanningUiService.prototype.createTemplate = function (data) {
        data.id = undefined;
        data.isTemplate = true;
        data.isDistribute = false;
        this.editTicket({ data: data });
    };
    TicketsPlanningUiService.prototype.addEditTicketCompleted = function (model, successEmitter) {
        var _this = this;
        FormHelper.markAsSubmitted(this.ticketFormGroup);
        if (!this.ticketFormGroup.valid) {
            return;
        }
        this.editedTicket.newData = this.ticketFormGroup.value;
        this.storing = true;
        (!this.editedTicket.newData.isTemplate
            ? this.institutionTicketDataService.storeTicket(model.days, this.editedTicket)
            : this.institutionTicketDataService.storeTicketTemplate(this.editedTicket))
            .subscribe({
            next: function () {
                if (_this.editedTicket.newData.isTemplate) {
                    _this.institutionTicketDataService.refreshTemplates(model);
                }
                _this.storingSuccess(successEmitter);
            },
            error: function (err) {
                _this.storing = false;
                FormHelper.setSingleFormGroupServerSideValidationErrors(err, _this.modalValidationErrorsHost, _this.ticketFormGroup);
            }
        });
    };
    TicketsPlanningUiService.prototype.calculateFirstEmptyTime = function (day, slot) {
        var maxTicketEndDate = DateHelper.dateOf(day, slot.timeStart);
        var maxAllowedEndDate = DateHelper.dateOf(day, slot.timeEnd);
        slot.tickets.forEach(function (ticket) {
            var ticketEndDate = new Date(ticket.data.endDate);
            // если у нас есть услуги, полностью перекрывающие слот, просто возвращаем время начала слота (возможно,
            // пользователь хочет "наслоить" оказания, и/или в начале слота есть пустое место)
            if (ticketEndDate > maxAllowedEndDate) {
                return DateHelper.dateOf(day, slot.timeStart);
            }
            else if (ticketEndDate > maxTicketEndDate) {
                maxTicketEndDate = ticketEndDate;
            }
        });
        return maxTicketEndDate;
    };
    TicketsPlanningUiService.prototype.deleteOrCancelTicketModal = function (data, model) {
        if (data.data.reservationTime) {
            this.editTicketModalOpened = false;
            this.deleteTicketModalOpened = true;
        }
        else {
            this.deleteTicket(model);
        }
    };
    TicketsPlanningUiService.prototype.deleteTicket = function (model) {
        var _this = this;
        this.alertService.confirmModal('Вы действительно хотите удалить талон ?')
            .subscribe(function () {
            _this.editedTicket.newData = _this.ticketFormGroup.value;
            _this.storing = true;
            _this.institutionTicketDataService.deleteTicket(model.days, _this.editedTicket.newData).subscribe({
                next: function () { return _this.storingSuccess(_this.actionTicketEmitter); },
                error: function (err) {
                    _this.storing = false;
                    FormHelper.setSingleFormGroupServerSideValidationErrors(err, _this.modalValidationErrorsHost, _this.ticketFormGroup);
                }
            });
        });
    };
    TicketsPlanningUiService.prototype.deleteTicketTemplate = function (data, model) {
        var _this = this;
        this.alertService.confirmModal('Вы действительно хотите удалить шаблон талонов ?')
            .subscribe(function (val) {
            if (val) {
                if (!_this.editedTicket) {
                    _this.editedTicket = {};
                }
                _this.editedTicket.newData = data;
                _this.storing = true;
                _this.institutionTicketDataService.deleteTicketTemplate(_this.editedTicket.newData).subscribe({
                    next: function () {
                        _this.institutionTicketDataService.refreshTemplates(model);
                        _this.storingSuccess();
                    },
                    error: function (err) {
                        _this.storing = false;
                        FormHelper.setSingleFormGroupServerSideValidationErrors(err, _this.modalValidationErrorsHost, _this.ticketFormGroup);
                    }
                });
            }
        });
    };
    TicketsPlanningUiService.prototype.distributeTickets = function (model, successEmitter) {
        var _this = this;
        this.alertService.confirmModal('Вы действительно хотите распределить талоны по шаблону ?')
            .subscribe(function (val) {
            if (val) {
                if (!_this.editedTicket) {
                    _this.editedTicket = {};
                }
                _this.editedTicket.newData = _this.ticketFormGroup.value;
                _this.storing = true;
                _this.institutionTicketDataService.distributeTickets(_this.editedTicket.newData).subscribe({
                    next: function () { return _this.storingSuccess(successEmitter); },
                    error: function (err) {
                        _this.storing = false;
                        FormHelper.setSingleFormGroupServerSideValidationErrors(err, _this.modalValidationErrorsHost, _this.ticketFormGroup);
                    }
                });
            }
        });
    };
    TicketsPlanningUiService.prototype.closeEditModal = function () {
        this.editTicketModalOpened = false;
    };
    TicketsPlanningUiService.prototype.markSuccessful = function () {
        var _this = this;
        this.alertService.confirmModal('Вы действительно хотите подтвердить факт приёма по талону ?')
            .subscribe(function (val) {
            if (val) {
                if (!_this.editedTicket) {
                    return;
                }
                _this.storing = true;
                _this.institutionTicketDataService.markSuccessful(_this.ticketFormGroup.get('id').value).subscribe({
                    next: function () { return _this.storingSuccess(_this.actionTicketEmitter); },
                    error: function (err) {
                        _this.storing = false;
                        FormHelper.setSingleFormGroupServerSideValidationErrors(err, _this.modalValidationErrorsHost, _this.ticketFormGroup);
                    }
                });
            }
        });
    };
    TicketsPlanningUiService.prototype.storingSuccess = function (successEmitter) {
        this.storing = false;
        this.editTicketModalOpened = false;
        this.deleteTicketModalOpened = false;
        this.editedTicket = undefined;
        this.ticketFormGroup = undefined;
        if (successEmitter) {
            successEmitter.emit(true);
        }
    };
    TicketsPlanningUiService.ngInjectableDef = i0.defineInjectable({ factory: function TicketsPlanningUiService_Factory() { return new TicketsPlanningUiService(i0.inject(i1.FormBuilder), i0.inject(i2.LookupSourceService), i0.inject(i3.InstitutionTicketDataService), i0.inject(i4.AlertService), i0.inject(i5.AddressPersonFioCacheService)); }, token: TicketsPlanningUiService, providedIn: "root" });
    return TicketsPlanningUiService;
}());
export { TicketsPlanningUiService };
