import * as tslib_1 from "tslib";
import { MetaBrowseBaseComponent } from '../meta-browse.base.component';
import { ActivatedRoute } from '@angular/router';
import { MetadataService } from '../../../logic/services/metadata.service';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { FormBuilder } from '@angular/forms';
import { JobRunnerUiService } from '../../../ui/infrastructure/job-runner-ui.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { DrugTypeBrowseFormComponent } from './drug-type-browse-form.component';
import { combineLatest } from 'rxjs';
var DrugTypeBrowseComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DrugTypeBrowseComponent, _super);
    function DrugTypeBrowseComponent(route, metadataService, alertService, globalWaitingOverlayService, lookupService, bookmarkService, fb, jobRunnerUiService, dataCachingService) {
        var _this = _super.call(this, route, metadataService, alertService, globalWaitingOverlayService, lookupService, fb, bookmarkService, dataCachingService) || this;
        _this.jobRunnerUiService = jobRunnerUiService;
        _this.gridColumnDefs = [
            { headerName: 'Ид', field: 'id', width: 50 },
            { headerName: 'Реестровый №', field: 'registryNo', tooltipField: 'registryNo', width: 180 },
            { headerName: 'Наименование', field: 'caption', tooltipField: 'caption', width: 400 },
            { headerName: 'Тип', field: 'drugKindCaption', tooltipField: 'drugKindCaption', width: 250 },
        ];
        return _this;
    }
    DrugTypeBrowseComponent.prototype.getMetaTitle = function () {
        return 'Виды препаратов';
    };
    DrugTypeBrowseComponent.prototype.getMetaName = function () {
        return 'drug-type';
    };
    DrugTypeBrowseComponent.prototype.getId = function (formGroup) {
        return formGroup.get('id').value;
    };
    DrugTypeBrowseComponent.prototype.getGroupDef = function (row) {
        return DrugTypeBrowseFormComponent.getGroupDef(this.fb, row);
    };
    DrugTypeBrowseComponent.prototype.getFormGroup = function (data) {
        return data;
    };
    DrugTypeBrowseComponent.prototype.refreshResults = function () {
        var _this = this;
        this.globalWaitingOverlayService.StartWaiting();
        combineLatest(this.metadataService.getMetadata('drug-type/full-record'), this.lookupService.getLookupObj('drug-kind')).subscribe({
            next: function (_a) {
                var data = _a[0], drugKindLookup = _a[1];
                _this._searchResults = data;
                _this._searchResults.forEach(function (drugType) {
                    drugType.drugKindCaption = drugKindLookup[drugType.drugKindId];
                });
                _this.dataCachingService.addToCache(_this.getMetaName() + '_Data', '1', data);
                _this.lookupService.invalidateLookup(_this.getMetaName());
                _this.globalWaitingOverlayService.EndWaiting();
            }, error: function () {
                _this.globalWaitingOverlayService.EndWaiting();
            }
        });
    };
    return DrugTypeBrowseComponent;
}(MetaBrowseBaseComponent));
export { DrugTypeBrowseComponent };
