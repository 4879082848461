import { Component, Input } from '@angular/core';
import { FormHelper } from '../../../ui/controls/form-helper';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ServerSideErrorsProvider } from '../../../logic/validators/server-side-errors-provider';
import { AppFormGroup } from '../../../ui/controls/app-form-group';

@Component({
  selector: 'app-edit-expertise-indicators',
  templateUrl: './edit-expertise-indicators.component.html',
  styleUrls: ['./edit-product-expertises.all.css']
})
export class EditExpertiseIndicatorsComponent {

  @Input() contextFormGroup: AppFormGroup;
  @Input() productId: number;
  @Input() expertiseId: number;
  @Input() indicatorsEventEmitter;

  public static getIndicatorGroupDef(fb: FormBuilder, serviceData: any): any {
    return fb.group(
      {
        expertiseId: serviceData.expertiseId,
        indicatorTypeId: serviceData.indicatorTypeId,
        indicatorTypeCaption: serviceData.indicatorTypeCaption,
        value: [
          serviceData.value,
          Validators.compose([
              FormHelper.conditionalValidate(Validators.required, ctrl =>
                ctrl && ctrl.parent && (+ctrl.parent.get('expertiseResultType').value > 0 || ctrl.parent.get('conclusion').value))])
        ],
        expertiseResultType: [
          serviceData.expertiseResultType,
          Validators.compose([Validators.required])
        ],
        conclusion: [
          serviceData.conclusion,
          Validators.compose([
            FormHelper.conditionalValidate(Validators.required, ctrl =>
              ctrl && ctrl.parent && (ctrl.parent.get('value').value || +ctrl.parent.get('expertiseResultType').value > 0))]),
        ],
        mercuryNumber: serviceData.mercuryNumber,
        syncErrorText: serviceData.syncErrorText,
        needSync: serviceData.needSync || false,
        indicatorTypeLegalActCaption: serviceData.indicatorTypeLegalActCaption,
        indicatorTypeMethodCaption: serviceData.indicatorTypeMethodCaption,
        indicatorTypeNormativeValueCaption: serviceData.indicatorTypeNormativeValueCaption,
      });
  }

  public static buildFormArrayDef(fb: FormBuilder, serverSideErrorsProvider: ServerSideErrorsProvider): any {
    return {
      indicators: fb.array([])
    };
  }

  get indicators(): FormArray {
    return this.contextFormGroup.get('indicators') as FormArray;
  }

  isInvalid(fg: FormGroup, cname: string) {
    return FormHelper.isInvalid(fg, cname);
  }

  getRowColor(item: FormGroup) {
    if (+item.get('expertiseResultType').value == 1) {
      return 'green';
    } else if (+item.get('expertiseResultType').value == 2) {
      return 'red';
    } else if (+item.get('expertiseResultType').value == 3) {
      return 'blue';
    }
  }
}
