import * as tslib_1 from "tslib";
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { MetadataService } from '../../../logic/services/metadata.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MetaBrowseBaseComponent } from '../meta-browse.base.component';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { JobRunnerUiService } from '../../../ui/infrastructure/job-runner-ui.service';
import { StringHelper } from '../../../helpers/string-helper';
import { DataCachingService } from '../../../logic/services/data-caching.service';
var DiseaseTypeBrowseComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DiseaseTypeBrowseComponent, _super);
    function DiseaseTypeBrowseComponent(route, metadataService, alertService, globalWaitingOverlayService, lookupService, bookmarkService, fb, jobRunnerUiService, dataCachingService) {
        var _this = _super.call(this, route, metadataService, alertService, globalWaitingOverlayService, lookupService, fb, bookmarkService, dataCachingService) || this;
        _this.jobRunnerUiService = jobRunnerUiService;
        _this.gridColumnDefs = [
            { headerName: '№', field: 'id', width: 100 },
            { headerName: 'Наименование', field: 'caption', tooltipField: 'caption', width: 350 },
            { headerName: 'Код', field: 'code', tooltipField: 'caption', width: 80 },
            { headerName: 'Группа', field: 'kind', tooltipField: 'caption', width: 200 },
            { headerName: 'Guid', field: 'guid', tooltipField: 'guid', width: 200 },
            {
                headerName: 'Удалено', field: 'dateDeleted', width: 100,
                valueFormatter: function (params) { return StringHelper.getRuTimeWithSeconds(params.value); },
            },
            {
                headerName: 'Огр.мероприятия',
                field: 'mayBeIntroducedQuarantine',
                valueFormatter: function (params) { return params && params.value ? 'Да' : 'Нет'; }
            },
        ];
        return _this;
    }
    DiseaseTypeBrowseComponent.prototype.getMetaTitle = function () {
        return 'Болезни животных';
    };
    DiseaseTypeBrowseComponent.prototype.getMetaName = function () {
        return 'disease-type';
    };
    DiseaseTypeBrowseComponent.prototype.getGroupDef = function (row) {
        return {
            id: [row.id, Validators.compose([Validators.required, Validators.pattern(/^\d{1,5}$/)])],
            caption: [row.caption, Validators.required],
            code: [row.code, Validators.required],
            kind: [row.kind, Validators.required],
            mv4ChapterId: row.mv4ChapterId,
            infectionKind: row.infectionKind,
            include1Vet: row.include1Vet,
            guid: row.guid,
            mayBeIntroducedQuarantine: [row.mayBeIntroducedQuarantine || false, Validators.required],
            liquidationRegulationsFileUri: row.liquidationRegulationsFileUri,
        };
    };
    DiseaseTypeBrowseComponent.prototype.editRow = function (row) {
        var _this = this;
        this.currentFormGroup = this.fb.group(this.getGroupDef(row));
        this.currentIsNew = !this.getId(this.currentFormGroup);
        if (!this.currentIsNew) {
            this.metadataService.getMetadataSingle(this.getMetaName(), this.getId(this.currentFormGroup)).subscribe(function (detailedRec) {
                _this.currentFormGroup = _this.fb.group(_this.getGroupDef(detailedRec));
                _this.editModalOpened = true;
            });
        }
        else {
            this.metadataService.getMetadata(this.getMetaName() + '/get-for-create')
                .subscribe(function (newRec) {
                _this.currentFormGroup = _this.fb.group(_this.getGroupDef(newRec));
                _this.editModalOpened = true;
            });
        }
    };
    DiseaseTypeBrowseComponent.prototype.getRowStyle = function (params) {
        if (params.data && params.data.dateDeleted) {
            return { color: 'silver' };
        }
    };
    DiseaseTypeBrowseComponent.prototype.refreshResults = function () {
        var _this = this;
        this.globalWaitingOverlayService.StartWaiting();
        this.metadataService.getMetadata(this.getMetaName()).subscribe({
            next: function (data) {
                _this._searchResults = data;
                _this.dataCachingService.addToCache(_this.getMetaName() + '_Data', '1', data);
                _this.lookupService.invalidateLookup(_this.getMetaName());
                _this.lookupService.invalidateLookup('disease-type/quarantine-diseases');
                _this.globalWaitingOverlayService.EndWaiting();
            }, error: function () {
                _this.globalWaitingOverlayService.EndWaiting();
            }
        });
    };
    return DiseaseTypeBrowseComponent;
}(MetaBrowseBaseComponent));
export { DiseaseTypeBrowseComponent };
