<div class="itech-content-header">
  <clr-icon shape="flame" size="36" class="has-badge"></clr-icon>&nbsp;Создание нового карантина
</div>

<div style="display:flex">
  <div style="max-width:100px">
    <clr-icon shape="flame" size="80" style="margin-left:10px"></clr-icon>
  </div>
  <app-edit-quarantine-common *ngIf="model" [contextFormGroup]="contextFormGroup" [serverSideErrorsProvider]="this.model"
                              [isAccessEdit]="true"></app-edit-quarantine-common>
</div>

<p>
  <button [clrLoading]="creating" [disabled]="creating" class="btn btn-primary"
          (click)="createQuarantine()">
    <clr-icon shape="check"></clr-icon>&nbsp;Создать
  </button>
</p>
