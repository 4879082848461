<div class="itech-common-wrap no-pager">
  <div class="itech-v-header-content not-scrolled">
    <div class="itech-content-header itech-no-margin-bottom">
      <clr-icon shape="file-group" size="36"></clr-icon>&nbsp;Распределение бирок животных
    </div>

    <div style="padding: 5px; height: 90%">
      <app-grid [rowData]="_searchResults" [exportFileName]="getMetaTitle()" [uniqueGridName]="getMetaName()"
                (rowClicked)="editRow($event.data)" [columnDefs]="gridColumnDefs"></app-grid>
    </div>
  </div>
  <div class="itech-form-actions">
    <div>
      <button class="btn btn-link btn-primary" (click)="refreshResults()">
        <clr-icon shape="refresh" class="has-badge"></clr-icon>&nbsp;Обновить список
      </button>
      <button class="btn btn-link btn-primary"
              (click)="editRow({branchedByInstitution: false, branchedByYear: false, branchedByMonth: false, branchedByDay: false})">
        <clr-icon shape="plus" has-badge="true"></clr-icon>&nbsp;Добавить новую запись
      </button>
    </div>
  </div>
</div>


<clr-modal [(clrModalOpen)]="editModalOpened" *ngIf="editModalOpened" [clrModalClosable]="false">
  <h3 class="modal-title">Распределение бирок животных
    <span *ngIf="currentIsNew" style="color:green"> [Новая]</span></h3>
  <div class="modal-body" style="min-height:200px">

    <app-doc-validation-errors [validationErrors]="model.serverSideValidationErrors">
    </app-doc-validation-errors>

    <div class="form compact" [formGroup]="currentFormGroup">
      <section class="form-block">

        <div class="form-group" style="flex: 1000 1 auto">
          <label class="required">Хоз. субъект</label>
          <app-link-button [id]="currentFormGroup.controls['agentId'].value" [lookupName]="'agent'" [widthControl]="312"></app-link-button>
          <button *ngIf="!currentFormGroup.controls['agentId'].value" type="button" (click)="searchAgent()"
                  class="btn btn-danger btn-link itech-inline-tool-btn" title="Выбрать владельца">
            <clr-icon shape="search"></clr-icon>
          </button>
        </div>

        <div class="form-group">
          <label for="id" class="required">Номер с</label>
          <label for="id" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                 [class.invalid]="isInvalid(currentFormGroup, 'numberFrom')">
            <input type="text" id="numberFrom" placeholder="Номер с" class="itech-control-normal"
                   formControlName="numberFrom">
            <app-validation-tooltip [input]="currentFormGroup.controls['numberFrom']"></app-validation-tooltip>
          </label>
        </div>

        <div class="form-group">
          <label for="id" class="required">Номер по</label>
          <label for="id" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                 [class.invalid]="isInvalid(currentFormGroup, 'numberTo')">
            <input type="text" id="numberTo" placeholder="Номер по" class="itech-control-normal"
                   formControlName="numberTo">
            <app-validation-tooltip [input]="currentFormGroup.controls['numberTo']"></app-validation-tooltip>
          </label>
        </div>

        <div class="form-group">
          <label for="nomenclatureAnimalPinId" class="required">Номенклатура</label>
          <app-combo-lookup [validationTooltipRight]="true" class="itech-control-medium" [required]="true"
                            [contextControlId]="'nomenclatureAnimalPinId'"
                            [contextControlName]="'nomenclatureAnimalPinId'"
                            [lookupName]="'nomenclature-animal-pin'"
                            [contextFormGroup]="currentFormGroup"></app-combo-lookup>
        </div>

      </section>
    </div>
  </div>
  <div class="modal-footer itech-form-actions">
    <div>
      <button *ngIf="!currentIsNew" type="button" class="btn btn-warning-outline"
              (click)="delete(currentFormGroup.value)">
        <clr-icon shape="trash"></clr-icon>&nbsp;Удалить
      </button>
    </div>
    <div>
      <button type="button" class="btn btn-primary" (click)="addEditCompleted()">
        <clr-icon shape="check"></clr-icon>&nbsp;Сохранить
      </button>
      <button type="button" class="btn btn-warning" (click)="editModalOpened = false">
        <clr-icon shape="undo"></clr-icon>&nbsp;Отмена
      </button>
    </div>
  </div>
</clr-modal>
