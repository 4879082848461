import { FormArray, FormControl, FormControlDirective, FormControlName, FormGroup, Validators } from '@angular/forms';
import { serverSideErrorsValidator } from '../../logic/validators/server-side-errors-validator.directive';
import { isArray } from "util";
// nativeElement добавляется во все контролы - за счет этого можно обратиться к nativeElement и вызвать focus()
// Проверить производительность. Если будет тормозить - убирать - но также нужно убрать все вызовы setFocusToInvalidControl
var originFormControlNgOnChanges = FormControlDirective.prototype.ngOnChanges;
FormControlDirective.prototype.ngOnChanges = function () {
    this.form.nativeElement = this.valueAccessor._elementRef.nativeElement;
    return originFormControlNgOnChanges.apply(this, arguments);
};
var originFormControlNameNgOnChanges = FormControlName.prototype.ngOnChanges;
FormControlName.prototype.ngOnChanges = function () {
    var result = originFormControlNameNgOnChanges.apply(this, arguments);
    this.control.nativeElement = this.valueAccessor._elementRef ? this.valueAccessor._elementRef.nativeElement : null;
    return result;
};
var FormHelper = /** @class */ (function () {
    function FormHelper() {
    }
    FormHelper.isInvalid = function (fg, controlName) {
        var control = fg.controls[controlName];
        if (!control) {
            return true;
        }
        return control.invalid && (control.dirty || control.touched || FormHelper.isSubmitted(fg));
    };
    FormHelper.equalsSome = function (value) {
        var probeValues = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            probeValues[_i - 1] = arguments[_i];
        }
        if (!probeValues || !probeValues.length) {
            return false;
        }
        return probeValues.some(function (el) { return el == value; });
    };
    FormHelper.isInvalidControl = function (control) {
        if (!control) {
            return true;
        }
        return control.invalid && (control.dirty || control.touched || FormHelper.isSubmitted(control.parent));
    };
    FormHelper.markAsSubmitted = function (fg) {
        fg.submitted = true;
        FormHelper.markAsTouched(fg);
    };
    FormHelper.markAsTouched = function (fg) {
        Object.keys(fg.controls).forEach(function (field) {
            var control = fg.get(field);
            control.markAsTouched({ onlySelf: true });
            control.updateValueAndValidity();
            if (control instanceof FormGroup) {
                FormHelper.markAsTouched(control);
            }
            else if (control instanceof FormArray) {
                FormHelper.markAsTouched(control);
            }
        });
    };
    FormHelper.isSubmitted = function (fg) {
        while (fg) {
            if (fg.submitted) {
                return true;
            }
            fg = fg.parent;
        }
        return false;
    };
    FormHelper.setSingleFormGroupServerSideValidationErrors = function (error, validationErrorsHostObj, fg) {
        validationErrorsHostObj.serverSideValidationErrors = error.error && error.error.data && error.error.data[0]
            && !error.error.data.localeCompare
            ? error.error.data[0]
            : undefined;
        fg.docLevelServerSideValidationErrors = [];
        if (!validationErrorsHostObj.serverSideValidationErrors) {
            return;
        }
        if (!Array.isArray(validationErrorsHostObj.serverSideValidationErrors)) {
            validationErrorsHostObj.serverSideValidationErrors = [validationErrorsHostObj.serverSideValidationErrors];
        }
        setTimeout(function () {
            validationErrorsHostObj.serverSideValidationErrors.forEach(function (item) {
                if (item.fieldName) {
                    var control = fg.controls[item.fieldName];
                    if (control) {
                        item.badValue = control.value;
                        control.updateValueAndValidity();
                    }
                    if (item.violationType === 'fieldBean' && !fg.docLevelServerSideValidationErrors.includes(item)) {
                        fg.docLevelServerSideValidationErrors.push(item);
                    }
                }
                else {
                    if (!fg.docLevelServerSideValidationErrors.includes(item)) {
                        fg.docLevelServerSideValidationErrors.push(item);
                    }
                }
            });
        }, 1);
    };
    FormHelper.setSingleFormGroupServerSideValidationErrorsWithRelationMsgKey = function (error, validationErrorsHostObj, fg) {
        if (error && error.error && error.error.data && isArray(error.error.data[0])) {
            error.error.data[0].forEach(function (err) {
                if (err.relationMsgKey && !fg.contains(err.fieldName)) {
                    if (fg.contains(err.relationMsgKey)) {
                        FormHelper.setSingleFormGroupServerSideValidationErrors(error, validationErrorsHostObj, fg.get(err.relationMsgKey));
                        return;
                    }
                    // если пришла ошибка с relationMsgKey с разделителем (например, field-0),
                    // то обрабатываем эту ошибку как массив
                    // ищем по индексу (из примера выше -0) форму и передаем на обработку дальше
                    var key = err.relationMsgKey.split('-');
                    if (key.length && key.length > 1 && fg.contains(key[0])) {
                        FormHelper.setSingleFormGroupServerSideValidationErrors(error, validationErrorsHostObj, fg.get(key[0]).at(parseInt(key[1], 10)));
                    }
                }
                else {
                    FormHelper.setSingleFormGroupServerSideValidationErrors(error, validationErrorsHostObj, fg);
                }
            });
        }
    };
    FormHelper.getChar = function (event) {
        if (event.which == null) { // IE
            if (event.keyCode < 32) { // спец. символ
                return null;
            }
            return String.fromCharCode(event.keyCode);
        }
        if (event.which !== 0 && event.charCode !== 0) { // все кроме IE
            if (event.which < 32) {
                return null; // спец. символ
            }
            return String.fromCharCode(event.which); // остальные
        }
        return null; // спец. символ
    };
    FormHelper.processMoneyKeypress = function (e) {
        if (e.ctrlKey || e.altKey || e.metaKey) { // спец. сочетание - не обрабатываем
            return false;
        }
        var char = FormHelper.getChar(e);
        if (char === '0' || char === '1' || char === '2' || char === '3' || char === '4' || char === '5' || char === '6'
            || char === '7' || char === '8' || char === '9' || char === ',') {
            return true;
        }
        else if (char === '/' || char === '.' || char === '?' || char === '<' || char === '>' || char === 'б'
            || char === 'Б' || char === 'ю' || char === 'Ю') {
            var caret = FormHelper.getCaretPosition(e.target);
            var val = e.target.value;
            val = val.substr(0, caret) + ',' + val.substr(caret);
            e.target.value = val;
        }
        return false;
    };
    FormHelper.processMoneyKeypressDot = function (e) {
        if (e.ctrlKey || e.altKey || e.metaKey) { // спец. сочетание - не обрабатываем
            return false;
        }
        var char = FormHelper.getChar(e);
        if (char === '0' || char === '1' || char === '2' || char === '3' || char === '4' || char === '5' || char === '6'
            || char === '7' || char === '8' || char === '9' || char === '.') {
            return true;
        }
        else if (char === '/' || char === ',' || char === '?' || char === '<' || char === '>' || char === 'б'
            || char === 'Б' || char === 'ю' || char === 'Ю') {
            var caret = FormHelper.getCaretPosition(e.target);
            var val = e.target.value;
            val = val.substr(0, caret) + '.' + val.substr(caret);
            e.target.value = val;
        }
        return false;
    };
    FormHelper.getCaretPosition = function (oField) {
        // Initialize
        var iCaretPos = 0;
        if (document.selection) { // IE Support
            // Set focus on the element
            oField.focus();
            // To get cursor position, get empty selection range
            var oSel = document.selection.createRange();
            // Move selection start to 0 position
            oSel.moveStart('character', -oField.value.length);
            // The caret position is selection length
            iCaretPos = oSel.text.length;
        }
        else if (oField.selectionStart || oField.selectionStart === '0') { // Firefox support
            iCaretPos = oField.selectionStart;
        }
        // Return results
        return iCaretPos;
    };
    FormHelper.getApplicationMoneyValidator = function () {
        return Validators.pattern(/^\d*,{0,1}\d{0,2}$/);
    };
    FormHelper.getApplicationMoneyValidatorDot = function () {
        return Validators.pattern(/^\d*\.?\d{0,2}$/);
    };
    FormHelper.toAppMoneyString = function (amount) {
        if (!amount && amount !== 0) {
            return undefined;
        }
        return amount.toString().replace(/\./g, ',');
    };
    FormHelper.fromAppMoneyString = function (amount) {
        if (!amount) {
            return undefined;
        }
        return Number.parseFloat(amount.toString().replace(/,/g, '.'));
    };
    // Этим мы боремся с вполне допустимыми значениями в пикере типа 12345-01-01, из за чего это все не может корректно
    // прийти на сервер и Spring JSON Serializer падает с соответствующей ошибкой, а пользователю приходит ничего не значащая
    // для него ошибка 500. Валидатор должнен стоять на каждом из контролов
    FormHelper.validateDateTimePicker = function () {
        return Validators.pattern(/^[1-2][0-9]{3}-\d{2}-\d{2}(T\w.*)*$/);
    };
    FormHelper.conditionalValidate = function (validatorFn, conditionFn) {
        return function (control) {
            return conditionFn(control) ? validatorFn(control) : null;
        };
    };
    FormHelper.putControlDefWithSSV = function (formGroupDef, dataObj, fieldName, serverSideErrorsProvider) {
        var validators = [];
        for (var _i = 4; _i < arguments.length; _i++) {
            validators[_i - 4] = arguments[_i];
        }
        validators.push(serverSideErrorsValidator(fieldName, serverSideErrorsProvider));
        FormHelper.putControlDef.apply(FormHelper, [formGroupDef, dataObj, fieldName].concat(validators));
    };
    FormHelper.putControlDef = function (formGroupDef, dataObj, fieldName) {
        var validators = [];
        for (var _i = 3; _i < arguments.length; _i++) {
            validators[_i - 3] = arguments[_i];
        }
        formGroupDef[fieldName] = [dataObj ? dataObj[fieldName] : undefined, Validators.compose(validators)];
    };
    FormHelper.validateCadNo = function () {
        return Validators.pattern(/^\d{2}:\d{2}:\d{7}:\d{1,7}$/);
    };
    FormHelper.validateMoney = function () {
        return Validators.pattern(/^\d{1,15}(\.\d{1,2})?$/);
    };
    FormHelper.validateMoneyThreeDecimal = function () {
        return Validators.pattern(/^\d{1,15}(\.\d{1,3})?$/);
    };
    FormHelper.validateMoneyThreeDecimalComma = function () {
        return Validators.pattern(/^\d{1,15}(,\d{1,3})?$/);
    };
    FormHelper.validateMoneyMoreTwoDecimal = function () {
        return Validators.pattern(/^\d{1,15}(\.\d{1,10})?$/);
    };
    FormHelper.validateDrugDecimal = function () {
        return Validators.pattern(/^\d{1,15}(,\d{1,5})?$/);
    };
    FormHelper.persistAgGridState = function (gridOptions, storageObj, storageFieldName) {
        if (!gridOptions) {
            return;
        }
        var storedOptions = {};
        storageObj[storageFieldName] = storedOptions;
        storedOptions.colState = gridOptions.columnApi.getColumnState();
        storedOptions.groupState = gridOptions.columnApi.getColumnGroupState();
        storedOptions.sortState = gridOptions.api.getSortModel();
        storedOptions.filterState = gridOptions.api.getFilterModel();
    };
    FormHelper.restoreAgGridState = function (gridOptions, storageObj, storageFieldName) {
        if (!gridOptions) {
            return;
        }
        var storedOptions = storageObj[storageFieldName];
        if (storedOptions) {
            gridOptions.columnApi.setColumnState(storedOptions.colState);
            gridOptions.columnApi.setColumnGroupState(storedOptions.groupState);
            gridOptions.api.setSortModel(storedOptions.sortState);
            gridOptions.api.setFilterModel(storedOptions.filterState);
        }
    };
    FormHelper.setFocusToInvalidControl = function (form) {
        var invalidControl = FormHelper.findFirstInvalidControlRecursive(form);
        if (invalidControl && invalidControl.nativeElement) {
            invalidControl.nativeElement.focus();
        }
    };
    FormHelper.findFirstInvalidControlRecursive = function (formToInvestigate) {
        var invalidControl = null;
        var recursiveFunc = function (form) {
            for (var _i = 0, _a = Object.keys(form.controls); _i < _a.length; _i++) {
                var field = _a[_i];
                var control = form.get(field);
                if (!invalidControl && control instanceof FormGroup) {
                    recursiveFunc(control);
                }
                else if (!invalidControl && control instanceof FormArray) {
                    recursiveFunc(control);
                }
                else if (!invalidControl && control.invalid && field === 'dateTo' &&
                    form.contains('dateToIncluded') && form.get('dateToIncluded').value) {
                    // если invalid контрол - это dateTo и dateToIncluded заполнен, то нужно перейти к контролу dateToIncluded
                    invalidControl = form.get('dateToIncluded');
                    break;
                }
                else if (!invalidControl && control.invalid) {
                    invalidControl = control;
                    break;
                }
            }
        };
        recursiveFunc(formToInvestigate);
        return invalidControl;
    };
    FormHelper.cloneAbstractControl = function (control) {
        var newControl;
        if (control instanceof FormGroup) {
            var formGroup_1 = new FormGroup({}, control.validator, control.asyncValidator);
            var controls_1 = control.controls;
            Object.keys(controls_1).forEach(function (key) {
                formGroup_1.addControl(key, FormHelper.cloneAbstractControl(controls_1[key]));
            });
            newControl = formGroup_1;
        }
        else if (control instanceof FormArray) {
            var formArray_1 = new FormArray([], control.validator, control.asyncValidator);
            control.controls.forEach(function (formControl) { return formArray_1.push(FormHelper.cloneAbstractControl(formControl)); });
            newControl = formArray_1;
        }
        else if (control instanceof FormControl) {
            newControl = new FormControl(control.value, control.validator, control.asyncValidator);
        }
        else {
            throw new Error('Error: unexpected control value');
        }
        if (control.disabled) {
            newControl.disable({ emitEvent: false });
        }
        newControl.setParent(control.parent);
        return newControl;
    };
    FormHelper.resetAllFields = function (control, noTouchFieldNames) {
        if (noTouchFieldNames === void 0) { noTouchFieldNames = []; }
        if (control instanceof FormArray) {
            if (control.controls.length) {
                var cloneControl = FormHelper.cloneAbstractControl(control.controls[0]);
                while (control.controls.length !== 0) {
                    control.removeAt(0);
                }
                if (cloneControl instanceof FormGroup) {
                    FormHelper.resetAllFields(cloneControl, noTouchFieldNames);
                    control.push(cloneControl);
                }
            }
        }
        else if (control instanceof FormGroup) {
            Object.keys(control.controls).forEach(function (c) {
                if (!noTouchFieldNames.includes(c)) {
                    FormHelper.resetAllFields(control.get(c), noTouchFieldNames);
                }
            });
        }
        else {
            control.reset();
        }
    };
    return FormHelper;
}());
export { FormHelper };
