import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/internal/operators';
import { StringHelper } from '../../helpers/string-helper';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var StableDataService = /** @class */ (function () {
    function StableDataService(http) {
        this.http = http;
    }
    StableDataService.prettifyCommon = function (data) {
        data['cadNo'] = StringHelper.stringCadNumberWithoutColon(data['cadNo']);
        return data;
    };
    StableDataService.prototype.searchByAgent = function (agentId, showHistory) {
        showHistory = !!showHistory;
        var qstring = environment.api + '/stable/search-by-agent/' + agentId + '/' + showHistory;
        return this.http.get(qstring, { headers: { 'interceptNoAccess': 'true' } })
            .pipe(map(function (response) {
            return response;
        }));
    };
    StableDataService.prototype.updateDefaultHiddenForStables = function (stables) {
        var qstring = environment.api + '/stable/update-default-hidden';
        return this.http.post(qstring, stables);
    };
    StableDataService.prototype.search = function (params) {
        var qstring = environment.api + '/stable/search';
        return this.http.post(qstring, params)
            .pipe(map(function (response) {
            return response;
        }));
    };
    StableDataService.prototype.getStableCommonForEdit = function (id) {
        var qstring = environment.api + '/stable/' + id.toString(10);
        return this.http.get(qstring, { headers: { 'interceptNoAccess': 'true' } });
    };
    StableDataService.prototype.getStableCommonForEditWithSync = function (id) {
        var qstring = environment.api + '/stable/resync-with-mercury/' + id.toString(10);
        return this.http.get(qstring, { headers: { 'interceptNoAccess': 'true' } })
            .pipe(map(function (item) {
            return item.data;
        }));
    };
    StableDataService.prototype.getStableCommonForLookup = function (id) {
        var qstring = environment.api + '/stable/lookup/' + id.toString(10);
        return this.http.get(qstring);
    };
    StableDataService.prototype.storeStableCommonInfo = function (model) {
        var qstring = environment.api + '/stable/store-common';
        return this.http.post(qstring, StableDataService.prettifyCommon(model))
            .pipe(map(function (response, error) {
            return response.data;
        }));
    };
    StableDataService.prototype.deleteStable = function (id) {
        var qstring = environment.api + '/stable/delete/' + id.toString(10);
        return this.http.post(qstring, {});
    };
    StableDataService.prototype.restoreDeletedStable = function (id) {
        var qstring = environment.api + '/stable/restore-deleted/' + id.toString(10);
        return this.http.post(qstring, {});
    };
    StableDataService.prototype.readStableEvents = function (stableId) {
        var qstring = environment.api + '/stable/stable-events/' + stableId;
        return this.http.get(qstring, { headers: { 'interceptNoAccess': 'true' } })
            .pipe(map(function (response) {
            return response;
        }));
    };
    StableDataService.prototype.mergeStable = function (mainStableId, sourceStableId) {
        var qstring = environment.api + '/stable/merge/' + mainStableId + '/' + sourceStableId;
        return this.http.get(qstring);
    };
    StableDataService.prototype.getAllProductSubtypes = function (stableId) {
        var qstring = environment.api + '/stable/product-subtypes/' + stableId;
        return this.http.get(qstring)
            .pipe(map(function (response) {
            return response;
        }));
    };
    StableDataService.prototype.runStableAgentsSync = function (stableId) {
        var qstring = environment.api + '/stable/run-stable-agents-sync/' + stableId.toString(10);
        return this.http.post(qstring, {});
    };
    StableDataService.prototype.startSyncIncomingProducts = function (stableId) {
        var qstring = environment.api + '/stable/start-sync-incoming-products/' + stableId;
        return this.http.post(qstring, {})
            .pipe(map(function (response) {
            return response;
        }));
    };
    StableDataService.prototype.backgroundUpdateStableProductSubtype = function (stableIds) {
        var qstring = environment.api + '/stable/background-update-stable-product-subtype';
        return this.http.post(qstring, stableIds)
            .pipe(map(function (response) {
            return response.data;
        }));
    };
    StableDataService.prototype.getProductionTemplate = function (stableId) {
        var qstring = environment.api + '/stable/production-template/' + stableId;
        return this.http.get(qstring, { headers: { 'interceptNoAccess': 'true' } })
            .pipe(map(function (response) {
            return response;
        }));
    };
    StableDataService.prototype.storeProductionTemplate = function (stableId, templates, deleted) {
        var store = {
            stableId: stableId,
            templates: templates,
            delete: deleted,
        };
        var qstring = environment.api + '/stable/production-template/store';
        return this.http.post(qstring, store)
            .pipe(map(function (response) {
            return response.data;
        }));
    };
    StableDataService.prototype.searchStableProductSubtypesFromProductionTemplates = function (stableId, productTypeId, productSubtypeId) {
        var qstring = environment.api + "/stable/production-template/search-stable-product-subtype/" + stableId + "/" + productTypeId + "/" + productSubtypeId;
        return this.http.get(qstring)
            .pipe(map(function (response) {
            return response;
        }));
    };
    StableDataService.prototype.storeStableProductSubtype = function (id, data) {
        var qstring = environment.api + '/stable/stable-product-subtype/store/' + id.toString(10);
        return this.http.post(qstring, data)
            .pipe(map(function (response) {
            return response.data;
        }));
    };
    StableDataService.ngInjectableDef = i0.defineInjectable({ factory: function StableDataService_Factory() { return new StableDataService(i0.inject(i1.HttpClient)); }, token: StableDataService, providedIn: "root" });
    return StableDataService;
}());
export { StableDataService };
