import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { EsiaService } from './app/esia/esia.service';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthGuard implements CanActivateChild {

  constructor(private esiaService: EsiaService, private router: Router) {
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const loggedIn = this.esiaService.loggedIn();

    if (childRoute.routeConfig.path.startsWith('esia')) {
      return true;
    }

    if (childRoute.routeConfig.path === 'login') {
      return !loggedIn;
    }

    if (!loggedIn) {
      this.router.navigate(['login']);
    }

    return loggedIn;
  }
}
