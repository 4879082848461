/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@clr/angular";
import * as i3 from "../../../../../node_modules/@clr/angular/clr-angular.ngfactory";
import * as i4 from "./pst-branch-cell-edit.component.ngfactory";
import * as i5 from "./pst-branch-cell-edit.component";
import * as i6 from "../../../logic/services/lookup-source.service";
import * as i7 from "../../../logic/services/app-navigation.service";
import * as i8 from "../../services/pst-report.service";
import * as i9 from "../../services/pst-report-structure.service";
import * as i10 from "./pst-branch-tab-col-edit.component";
import * as i11 from "../../../logic/services/address-person-fio-cache.service";
import * as i12 from "../../../ui/infrastructure/alert.service";
import * as i13 from "../../../logic/services/security.service";
var styles_PstBranchTabColEditComponent = [];
var RenderType_PstBranchTabColEditComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PstBranchTabColEditComponent, data: {} });
export { RenderType_PstBranchTabColEditComponent as RenderType_PstBranchTabColEditComponent };
function View_PstBranchTabColEditComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.branch.displayTitle; _ck(_v, 1, 0, currVal_0); }); }
function View_PstBranchTabColEditComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "td", [["style", "width: 30px"]], [[1, "rowspan", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "it-title-cell-overlay"], ["style", "border-width: 1px; justify-content: space-evenly"]], [[4, "left", null], [4, "right", null]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" \u2116"])), (_l()(), i0.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u043F/\u043F "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.branch.headerRows.length; _ck(_v, 0, 0, currVal_0); var currVal_1 = ((_co.scrollLeft - 1) + "px"); var currVal_2 = (("-" + (_co.scrollLeft + 1)) + "px"); _ck(_v, 1, 0, currVal_1, currVal_2); }); }
function View_PstBranchTabColEditComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "td", [["style", "width: 180px"]], [[1, "rowspan", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "it-title-cell-overlay"], ["style", "justify-content: space-evenly"]], [[4, "left", null], [4, "right", null]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" \u041D\u0430\u0438\u043C\u0435\u043D\u043E\u0432\u0430\u043D\u0438\u0435 \u043F\u0440\u0435\u0434\u043F\u0440\u0438\u044F\u0442\u0438\u044F "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.branch.headerRows.length; _ck(_v, 0, 0, currVal_0); var currVal_1 = ((_co.scrollLeft - 1) + "px"); var currVal_2 = (("-" + (_co.scrollLeft + 2)) + "px"); _ck(_v, 1, 0, currVal_1, currVal_2); }); }
function View_PstBranchTabColEditComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "td", [["class", "it-indicator"]], [[1, "colspan", 0], [1, "rowspan", 0], [4, "display", null], [4, "width", null], [2, "it-vertical", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.colSpan; var currVal_1 = _v.parent.context.$implicit.rowSpan; var currVal_2 = (_v.parent.context.$implicit.visible() ? "visible" : "none"); var currVal_3 = ((_v.parent.context.$implicit.meta.displayWidth && _v.parent.context.$implicit.lastRow) ? (_v.parent.context.$implicit.meta.displayWidth + "px") : undefined); var currVal_4 = (_v.parent.context.$implicit.meta.displayVertical && _v.parent.context.$implicit.lastRow); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _v.parent.context.$implicit.displayTitle; _ck(_v, 2, 0, currVal_5); }); }
function View_PstBranchTabColEditComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_7)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.rowSpan && _v.context.$implicit.colSpan); _ck(_v, 2, 0, currVal_0); }, null); }
function View_PstBranchTabColEditComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_4)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_5)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_6)), i0.ɵdid(6, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.context.index === 0); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.index === 0); _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit; _ck(_v, 6, 0, currVal_2); }, null); }
function View_PstBranchTabColEditComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "tr", [["class", "it-row-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "td", [], [[1, "colspan", 0]], null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.branch.headerRows[(_co.branch.headerRows.length - 1)].length + 2); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.caption; _ck(_v, 2, 0, currVal_1); }); }
function View_PstBranchTabColEditComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "td", [["class", "it-indicator it-read-only"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "a", [["class", "itech-link in-table-link edit-layout"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.editReportIndicator(_v.parent.parent.context.$implicit.indicators[_v.context.index]) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "clr-icon", [["shape", "edit"]], null, null, null, null, null)), i0.ɵdid(3, 16384, null, 0, i2.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, [" \u0433\u0440\u0430\u0444\u0430"])), (_l()(), i0.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 3, "a", [["class", "itech-link in-table-link edit-layout"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.editIndicatorType(_v.parent.parent.context.$implicit.indicators[_v.context.index]) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "clr-icon", [["shape", "edit"]], null, null, null, null, null)), i0.ɵdid(8, 16384, null, 0, i2.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, [" \u043F\u043E\u043A\u0430\u0437."]))], null, null); }
function View_PstBranchTabColEditComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "td", [["class", "it-read-only"], ["colspan", "2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["class", "it-title-cell-overlay"], ["style", "width: 210px; border-width: 0 0 1px 1px"]], [[4, "left", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_11)), i0.ɵdid(4, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.branch.headerRows[(_co.branch.headerRows.length - 1)]; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.scrollLeft - 1) + "px"); _ck(_v, 2, 0, currVal_0); }); }
function View_PstBranchTabColEditComponent_13(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "tr", [["class", "it-row-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "td", [], [[1, "colspan", 0]], null, null, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "it-title-cell-overlay"], ["style", "width: 200px; border-width: 0 0 1px 1px"]], [[4, "left", null]], null, null, null, null)), (_l()(), i0.ɵted(4, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.branch.headerRows[(_co.branch.headerRows.length - 1)].length + 2); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.caption; _ck(_v, 2, 0, currVal_1); var currVal_2 = ((_co.scrollLeft - 1) + "px"); _ck(_v, 3, 0, currVal_2); var currVal_3 = _v.parent.context.$implicit.caption; _ck(_v, 4, 0, currVal_3); }); }
function View_PstBranchTabColEditComponent_15(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "tr", [["class", "it-row-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "td", [], [[1, "colspan", 0]], null, null, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "it-title-cell-overlay"], ["style", "width: 200px; border-width: 0 0 1px 1px"]], [[4, "left", null]], null, null, null, null)), (_l()(), i0.ɵted(4, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.branch.headerRows[(_co.branch.headerRows.length - 1)].length + 2); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.caption; _ck(_v, 2, 0, currVal_1); var currVal_2 = ((_co.scrollLeft - 1) + "px"); _ck(_v, 3, 0, currVal_2); var currVal_3 = _v.parent.context.$implicit.caption; _ck(_v, 4, 0, currVal_3); }); }
function View_PstBranchTabColEditComponent_23(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "button", [["clrDropdownItem", ""], ["type", "button"]], [[8, "title", 0], [2, "disabled", null], [2, "dropdown-item", null], [1, "role", 0], [1, "aria-disabled", 0], [1, "disabled", 0], [1, "id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addAgentRow(_v.parent.parent.parent.parent.parent.parent.context.$implicit.agentId, _v.parent.parent.parent.parent.parent.parent.parent.parent.parent.parent.context.$implicit, _v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(1024, null, i2.ɵo, i2.ɵp, []), i0.ɵprd(512, [[2, 4]], i2.ɵr, i2.ɵt, [i2.ɵo, i0.ElementRef, i0.Renderer2, i0.PLATFORM_ID]), i0.ɵdid(3, 4341760, null, 0, i2.ClrDropdownItem, [i2.ClrDropdown, i0.ElementRef, i2.ɵe, i0.Renderer2, i2.ɵr], null, null), (_l()(), i0.ɵted(4, null, ["", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.caption; var currVal_1 = i0.ɵnov(_v, 3).disabled; var currVal_2 = true; var currVal_3 = "menuitem"; var currVal_4 = i0.ɵnov(_v, 3).disabled; var currVal_5 = ((i0.ɵnov(_v, 3).disabled && i0.ɵnov(_v, 3).setByDeprecatedDisabled) ? "" : null); var currVal_6 = i0.ɵnov(_v, 3).dropdownItemId; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = _v.context.$implicit.caption; _ck(_v, 4, 0, currVal_7); }); }
function View_PstBranchTabColEditComponent_22(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "clr-dropdown-menu", [], [[2, "dropdown-menu", null], [1, "role", 0], [2, "is-off-screen", null]], null, null, i3.View_ClrDropdownMenu_0, i3.RenderType_ClrDropdownMenu)), i0.ɵdid(1, 9617408, null, 1, i2.ClrDropdownMenu, [i0.Injector, [2, i2.ɵh], [3, i2.ClrDropdownMenu], i2.ɵl], { position: [0, "position"] }, null), i0.ɵqud(603979776, 2, { items: 1 }), (_l()(), i0.ɵeld(3, 0, null, 0, 2, "label", [["class", "dropdown-header"]], [[1, "for", 0]], null, null, null, null)), i0.ɵdid(4, 212992, null, 0, i2.ClrLabel, [[2, i2.ɵbe], [2, i2.ɵbf], [2, i2.ɵbg], i0.Renderer2, i0.ElementRef], null, null), (_l()(), i0.ɵted(-1, null, ["\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u043A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u044E"])), (_l()(), i0.ɵand(16777216, null, 0, 2, null, View_PstBranchTabColEditComponent_23)), i0.ɵdid(7, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_3 = "right-bottom"; _ck(_v, 1, 0, currVal_3); _ck(_v, 4, 0); var currVal_5 = i0.ɵunv(_v, 7, 0, i0.ɵnov(_v, 8).transform(_co.getAvailableSubtypes$())); _ck(_v, 7, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = "menu"; var currVal_2 = i0.ɵnov(_v, 1).isOffScreen; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = i0.ɵnov(_v, 4).forAttr; _ck(_v, 3, 0, currVal_4); }); }
function View_PstBranchTabColEditComponent_21(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "clr-dropdown", [], [[2, "dropdown", null], [2, "open", null]], null, null, i3.View_ClrDropdown_0, i3.RenderType_ClrDropdown)), i0.ɵprd(6144, null, i2.ɵh, null, [i0.ElementRef]), i0.ɵprd(6144, null, i2.ɵr, null, [i2.ɵl]), i0.ɵprd(512, null, i2.ɵd, i2.ɵd, []), i0.ɵprd(1024, null, i2.ɵe, i2.ɵf, [[3, i2.ɵe]]), i0.ɵdid(5, 180224, null, 0, i2.ClrDropdown, [[3, i2.ClrDropdown], i2.ɵd, i0.ChangeDetectorRef, i2.ɵe], null, null), i0.ɵprd(1024, null, i2.ɵo, i2.ɵp, []), i0.ɵprd(1024, null, i2.ɵi, i2.ɵj, [[3, i2.ɵi], i0.Renderer2]), i0.ɵprd(131584, null, i2.ɵl, i2.ɵl, [i2.ɵo, i0.Renderer2, [3, i2.ɵl], i2.ɵd, i2.ɵi, i0.PLATFORM_ID]), (_l()(), i0.ɵeld(9, 0, null, 0, 3, "a", [["class", "in-table-link itech-link"], ["clrDropdownToggle", ""], ["title", "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u043F\u043E\u0434\u043A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u044E"]], [[2, "dropdown-toggle", null], [2, "dropdown-item", null], [2, "expandable", null], [2, "active", null], [1, "aria-haspopup", 0], [1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 10).onDropdownTriggerClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(10, 16384, null, 0, i2.ClrDropdownTrigger, [i2.ClrDropdown, i2.ɵd, i0.ElementRef, i2.ɵl], null, null), (_l()(), i0.ɵeld(11, 0, null, null, 1, "clr-icon", [["shape", "plus"]], null, null, null, null, null)), i0.ɵdid(12, 16384, null, 0, i2.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_PstBranchTabColEditComponent_22)), i0.ɵdid(14, 147456, null, 0, i2.ClrIfOpen, [i2.ɵd, i0.TemplateRef, i0.ViewContainerRef], { open: [0, "open"] }, null)], function (_ck, _v) { var currVal_8 = null; _ck(_v, 14, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = i0.ɵnov(_v, 5).ifOpenService.open; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i0.ɵnov(_v, 10).isRootLevelToggle; var currVal_3 = !i0.ɵnov(_v, 10).isRootLevelToggle; var currVal_4 = !i0.ɵnov(_v, 10).isRootLevelToggle; var currVal_5 = i0.ɵnov(_v, 10).active; var currVal_6 = "menu"; var currVal_7 = i0.ɵnov(_v, 10).active; _ck(_v, 9, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
function View_PstBranchTabColEditComponent_20(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "it-add-subtype"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_21)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.parent.parent.context.index === (_v.parent.parent.parent.context.$implicit.children.length - 1)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_PstBranchTabColEditComponent_25(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-branch-cell-edit", [], null, null, null, i4.View_PstBranchCellEditComponent_0, i4.RenderType_PstBranchCellEditComponent)), i0.ɵdid(1, 573440, null, 0, i5.PstBranchCellEditComponent, [i6.LookupSourceService, i7.AppNavigationService, i8.PstReportService, i9.PstReportStructureService], { branch: [0, "branch"], model: [1, "model"], reportIndicator: [2, "reportIndicator"], indicatorTypeMeta: [3, "indicatorTypeMeta"], indicatorLevel: [4, "indicatorLevel"], editing: [5, "editing"], focused: [6, "focused"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.branch; var currVal_1 = _co.model; var currVal_2 = _v.parent.parent.parent.parent.parent.parent.parent.parent.context.$implicit.indicators[_v.parent.context.index]; var currVal_3 = _v.parent.parent.parent.context.$implicit.indicatorTypeMeta[_v.parent.context.index]; var currVal_4 = _v.parent.parent.parent.context.$implicit; var currVal_5 = _co.getEditing(_v.parent.parent.parent.context.$implicit, _v.parent.context.index, _v.parent.parent.parent.parent.parent.parent.parent.parent.context.$implicit); var currVal_6 = _co.getFocused(_v.parent.parent.parent.context.$implicit, _v.parent.context.index, _v.parent.parent.parent.parent.parent.parent.parent.parent.context.$implicit); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_PstBranchTabColEditComponent_24(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "td", [["class", "it-indicator"]], [[2, "it-read-only", null], [1, "colspan", 0], [4, "display", null], [2, "it-focused", null], [2, "it-editing", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cellClick(_v.parent.parent.context.$implicit, _v.context.index, _v.parent.parent.parent.parent.parent.parent.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_25)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_5 = _v.parent.parent.context.$implicit.indicatorTypeMeta[_v.context.index]; _ck(_v, 2, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getReadOnly(_v.parent.parent.context.$implicit, _v.context.index, _v.parent.parent.parent.parent.parent.parent.parent.context.$implicit); var currVal_1 = _v.context.$implicit.colSpan; var currVal_2 = (_v.context.$implicit.visible() ? "visible" : "none"); var currVal_3 = _co.getFocused(_v.parent.parent.context.$implicit, _v.context.index, _v.parent.parent.parent.parent.parent.parent.parent.context.$implicit); var currVal_4 = _co.getEditing(_v.parent.parent.context.$implicit, _v.context.index, _v.parent.parent.parent.parent.parent.parent.parent.context.$implicit); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
function View_PstBranchTabColEditComponent_19(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "td", [["class", "it-read-only"], ["style", "width: 30px"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "it-title-cell-overlay"]], [[4, "left", null], [4, "right", null]], null, null, null, null)), (_l()(), i0.ɵted(3, null, [" ", " "])), (_l()(), i0.ɵeld(4, 0, null, null, 7, "td", [["class", "it-read-only"], ["style", "width: 180px"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "a", [["class", "in-table-link itech-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigationService.performAgentEditing(_v.parent.context.$implicit.agentId) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(6, null, [" ", " "])), (_l()(), i0.ɵeld(7, 0, null, null, 4, "div", [["class", "it-title-cell-overlay"]], [[4, "left", null], [4, "right", null]], null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "a", [["class", "in-table-link itech-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigationService.performAgentEditing(_v.parent.context.$implicit.agentId) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(9, null, [" ", " "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_20)), i0.ɵdid(11, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_24)), i0.ɵdid(13, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.branch.hasSubtypes; _ck(_v, 11, 0, currVal_7); var currVal_8 = _co.branch.headerRows[(_co.branch.headerRows.length - 1)]; _ck(_v, 13, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.scrollLeft - 1) + "px"); var currVal_1 = (("-" + (_co.scrollLeft + 2)) + "px"); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = (_v.parent.context.index + 1); _ck(_v, 3, 0, currVal_2); var currVal_3 = _v.parent.context.$implicit.displayCaption(); _ck(_v, 6, 0, currVal_3); var currVal_4 = ((_co.scrollLeft - 1) + "px"); var currVal_5 = (("-" + (_co.scrollLeft + 2)) + "px"); _ck(_v, 7, 0, currVal_4, currVal_5); var currVal_6 = _v.parent.context.$implicit.displayCaption(); _ck(_v, 9, 0, currVal_6); }); }
function View_PstBranchTabColEditComponent_18(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_19)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.visible(); _ck(_v, 2, 0, currVal_0); }, null); }
function View_PstBranchTabColEditComponent_27(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "td", [["class", "it-read-only"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "a", [["class", "in-table-link itech-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigationService.performAgentEditing(_v.parent.parent.context.$implicit.agentId) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "it-title-cell-overlay"]], [[4, "left", null], [4, "right", null]], null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "a", [["class", "in-table-link itech-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigationService.performAgentEditing(_v.parent.parent.context.$implicit.agentId) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(5, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.parent.context.$implicit.caption; _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.scrollLeft - 1) + "px"); var currVal_2 = (("-" + (_co.scrollLeft + 2)) + "px"); _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_3 = _v.parent.parent.context.$implicit.caption; _ck(_v, 5, 0, currVal_3); }); }
function View_PstBranchTabColEditComponent_28(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "td", [["class", "it-read-only"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" \u0418\u0442\u043E\u0433\u043E \u043F\u043E \u00AB", "\u00BB: "])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "it-title-cell-overlay"]], [[4, "left", null], [4, "right", null]], null, null, null, null)), (_l()(), i0.ɵted(3, null, [" \u0418\u0442\u043E\u0433\u043E \u043F\u043E \u00AB", "\u00BB: "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.parent.context.$implicit.caption; _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_co.scrollLeft - 1) + "px"); var currVal_2 = (("-" + (_co.scrollLeft + 2)) + "px"); _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = _v.parent.parent.context.$implicit.caption; _ck(_v, 3, 0, currVal_3); }); }
function View_PstBranchTabColEditComponent_30(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-branch-cell-edit", [], null, null, null, i4.View_PstBranchCellEditComponent_0, i4.RenderType_PstBranchCellEditComponent)), i0.ɵdid(1, 573440, null, 0, i5.PstBranchCellEditComponent, [i6.LookupSourceService, i7.AppNavigationService, i8.PstReportService, i9.PstReportStructureService], { branch: [0, "branch"], model: [1, "model"], reportIndicator: [2, "reportIndicator"], indicatorTypeMeta: [3, "indicatorTypeMeta"], indicatorLevel: [4, "indicatorLevel"], editing: [5, "editing"], focused: [6, "focused"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.branch; var currVal_1 = _co.model; var currVal_2 = _v.parent.parent.parent.parent.parent.parent.parent.context.$implicit.indicators[_v.parent.context.index]; var currVal_3 = _v.parent.parent.parent.context.$implicit.indicatorTypeMeta[_v.parent.context.index]; var currVal_4 = _v.parent.parent.parent.context.$implicit; var currVal_5 = _co.getEditing(_v.parent.parent.parent.context.$implicit, _v.parent.context.index, _v.parent.parent.parent.parent.parent.parent.parent.context.$implicit); var currVal_6 = _co.getFocused(_v.parent.parent.parent.context.$implicit, _v.parent.context.index, _v.parent.parent.parent.parent.parent.parent.parent.context.$implicit); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_PstBranchTabColEditComponent_29(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "td", [["class", "it-indicator"]], [[2, "it-read-only", null], [1, "colspan", 0], [4, "display", null], [2, "it-focused", null], [2, "it-editing", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cellClick(_v.parent.parent.context.$implicit, _v.context.index, _v.parent.parent.parent.parent.parent.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_30)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_5 = _v.parent.parent.context.$implicit.indicatorTypeMeta[_v.context.index]; _ck(_v, 2, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getReadOnly(_v.parent.parent.context.$implicit, _v.context.index, _v.parent.parent.parent.parent.parent.parent.context.$implicit); var currVal_1 = _v.context.$implicit.colSpan; var currVal_2 = (_v.context.$implicit.visible() ? "visible" : "none"); var currVal_3 = _co.getFocused(_v.parent.parent.context.$implicit, _v.context.index, _v.parent.parent.parent.parent.parent.parent.context.$implicit); var currVal_4 = _co.getEditing(_v.parent.parent.context.$implicit, _v.context.index, _v.parent.parent.parent.parent.parent.parent.context.$implicit); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
function View_PstBranchTabColEditComponent_26(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "tr", [], [[2, "it-row-footer", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "td", [["class", "it-read-only"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "it-title-cell-overlay"]], [[4, "left", null], [4, "right", null]], null, null, null, null)), (_l()(), i0.ɵted(3, null, [" ", " "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_27)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_28)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_29)), i0.ɵdid(9, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = !_v.parent.context.$implicit.children.length; _ck(_v, 5, 0, currVal_4); var currVal_5 = _v.parent.context.$implicit.children.length; _ck(_v, 7, 0, currVal_5); var currVal_6 = _co.branch.headerRows[(_co.branch.headerRows.length - 1)]; _ck(_v, 9, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit.children.length; _ck(_v, 0, 0, currVal_0); var currVal_1 = ((_co.scrollLeft - 1) + "px"); var currVal_2 = (("-" + (_co.scrollLeft + 2)) + "px"); _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = (_v.parent.context.index + 1); _ck(_v, 3, 0, currVal_3); }); }
function View_PstBranchTabColEditComponent_17(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_18)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_26)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.children; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.visible(); _ck(_v, 4, 0, currVal_1); }, null); }
function View_PstBranchTabColEditComponent_32(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "td", [["class", "it-read-only"], ["colspan", "2"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "it-title-cell-overlay"]], [[4, "left", null], [4, "right", null]], null, null, null, null)), (_l()(), i0.ɵted(3, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.parent.context.$implicit.caption; _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_co.scrollLeft - 1) + "px"); var currVal_2 = (("-" + (_co.scrollLeft + 2)) + "px"); _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = _v.parent.parent.context.$implicit.caption; _ck(_v, 3, 0, currVal_3); }); }
function View_PstBranchTabColEditComponent_33(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "td", [["class", "it-read-only"], ["colspan", "2"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["\u0418\u0442\u043E\u0433\u043E \u043F\u043E \u00AB", "\u00BB: "])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "it-title-cell-overlay"]], [[4, "left", null], [4, "right", null]], null, null, null, null)), (_l()(), i0.ɵted(3, null, [" \u0418\u0442\u043E\u0433\u043E \u043F\u043E \u00AB", "\u00BB: "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.parent.context.$implicit.caption; _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_co.scrollLeft - 1) + "px"); var currVal_2 = (("-" + (_co.scrollLeft + 2)) + "px"); _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = _v.parent.parent.context.$implicit.caption; _ck(_v, 3, 0, currVal_3); }); }
function View_PstBranchTabColEditComponent_35(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-branch-cell-edit", [], null, null, null, i4.View_PstBranchCellEditComponent_0, i4.RenderType_PstBranchCellEditComponent)), i0.ɵdid(1, 573440, null, 0, i5.PstBranchCellEditComponent, [i6.LookupSourceService, i7.AppNavigationService, i8.PstReportService, i9.PstReportStructureService], { branch: [0, "branch"], model: [1, "model"], reportIndicator: [2, "reportIndicator"], indicatorTypeMeta: [3, "indicatorTypeMeta"], indicatorLevel: [4, "indicatorLevel"], editing: [5, "editing"], focused: [6, "focused"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.branch; var currVal_1 = _co.model; var currVal_2 = _v.parent.parent.parent.parent.parent.parent.context.$implicit.indicators[_v.parent.context.index]; var currVal_3 = _v.parent.parent.parent.context.$implicit.indicatorTypeMeta[_v.parent.context.index]; var currVal_4 = _v.parent.parent.parent.context.$implicit; var currVal_5 = _co.getEditing(_v.parent.parent.parent.context.$implicit, _v.parent.context.index, _v.parent.parent.parent.parent.parent.parent.context.$implicit); var currVal_6 = _co.getFocused(_v.parent.parent.parent.context.$implicit, _v.parent.context.index, _v.parent.parent.parent.parent.parent.parent.context.$implicit); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_PstBranchTabColEditComponent_34(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "td", [["class", "it-indicator"]], [[2, "it-read-only", null], [1, "colspan", 0], [4, "display", null], [2, "it-focused", null], [2, "it-editing", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cellClick(_v.parent.parent.context.$implicit, _v.context.index, _v.parent.parent.parent.parent.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_35)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_5 = _v.parent.parent.context.$implicit.indicatorTypeMeta[_v.context.index]; _ck(_v, 2, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getReadOnly(_v.parent.parent.context.$implicit, _v.context.index, _v.parent.parent.parent.parent.parent.context.$implicit); var currVal_1 = _v.context.$implicit.colSpan; var currVal_2 = (_v.context.$implicit.visible() ? "visible" : "none"); var currVal_3 = _co.getFocused(_v.parent.parent.context.$implicit, _v.context.index, _v.parent.parent.parent.parent.parent.context.$implicit); var currVal_4 = _co.getEditing(_v.parent.parent.context.$implicit, _v.context.index, _v.parent.parent.parent.parent.parent.context.$implicit); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
function View_PstBranchTabColEditComponent_31(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "tr", [], [[2, "it-row-footer", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_32)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_33)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_34)), i0.ɵdid(6, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_v.parent.context.$implicit.children.length; _ck(_v, 2, 0, currVal_1); var currVal_2 = _v.parent.context.$implicit.children.length; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.branch.headerRows[(_co.branch.headerRows.length - 1)]; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.children.length; _ck(_v, 0, 0, currVal_0); }); }
function View_PstBranchTabColEditComponent_16(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_17)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_31)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.children; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.visible(); _ck(_v, 4, 0, currVal_1); }, null); }
function View_PstBranchTabColEditComponent_37(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "td", [["class", "it-read-only"], ["colspan", "2"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "it-title-cell-overlay"]], [[4, "left", null], [4, "right", null]], null, null, null, null)), (_l()(), i0.ɵted(3, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.model.reportMeta.regionId && _v.parent.parent.parent.parent.context.$implicit.caption) ? _v.parent.parent.parent.parent.context.$implicit.caption : _v.parent.parent.context.$implicit.caption); _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_co.scrollLeft - 1) + "px"); var currVal_2 = (("-" + (_co.scrollLeft + 2)) + "px"); _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = ((_co.model.reportMeta.regionId && _v.parent.parent.parent.parent.context.$implicit.caption) ? _v.parent.parent.parent.parent.context.$implicit.caption : _v.parent.parent.context.$implicit.caption); _ck(_v, 3, 0, currVal_3); }); }
function View_PstBranchTabColEditComponent_38(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "td", [["class", "it-read-only"], ["colspan", "2"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" \u0418\u0442\u043E\u0433\u043E \u043F\u043E \u00AB", "\u00BB: "])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "it-title-cell-overlay"]], [[4, "left", null], [4, "right", null]], null, null, null, null)), (_l()(), i0.ɵted(3, null, [" \u0418\u0442\u043E\u0433\u043E \u043F\u043E \u00AB", "\u00BB: "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.model.reportMeta.regionId && _v.parent.parent.parent.parent.context.$implicit.caption) ? _v.parent.parent.parent.parent.context.$implicit.caption : _v.parent.parent.context.$implicit.caption); _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_co.scrollLeft - 1) + "px"); var currVal_2 = (("-" + (_co.scrollLeft + 2)) + "px"); _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = ((_co.model.reportMeta.regionId && _v.parent.parent.parent.parent.context.$implicit.caption) ? _v.parent.parent.parent.parent.context.$implicit.caption : _v.parent.parent.context.$implicit.caption); _ck(_v, 3, 0, currVal_3); }); }
function View_PstBranchTabColEditComponent_40(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-branch-cell-edit", [], null, null, null, i4.View_PstBranchCellEditComponent_0, i4.RenderType_PstBranchCellEditComponent)), i0.ɵdid(1, 573440, null, 0, i5.PstBranchCellEditComponent, [i6.LookupSourceService, i7.AppNavigationService, i8.PstReportService, i9.PstReportStructureService], { branch: [0, "branch"], model: [1, "model"], reportIndicator: [2, "reportIndicator"], indicatorTypeMeta: [3, "indicatorTypeMeta"], indicatorLevel: [4, "indicatorLevel"], editing: [5, "editing"], focused: [6, "focused"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.branch; var currVal_1 = _co.model; var currVal_2 = _v.parent.parent.parent.parent.parent.context.$implicit.indicators[_v.parent.context.index]; var currVal_3 = _v.parent.parent.parent.context.$implicit.indicatorTypeMeta[_v.parent.context.index]; var currVal_4 = _v.parent.parent.parent.context.$implicit; var currVal_5 = _co.getEditing(_v.parent.parent.parent.context.$implicit, _v.parent.context.index, _v.parent.parent.parent.parent.parent.context.$implicit); var currVal_6 = _co.getFocused(_v.parent.parent.parent.context.$implicit, _v.parent.context.index, _v.parent.parent.parent.parent.parent.context.$implicit); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_PstBranchTabColEditComponent_39(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "td", [["class", "it-indicator"]], [[1, "colspan", 0], [4, "display", null], [2, "it-read-only", null], [2, "it-focused", null], [2, "it-editing", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cellClick(_v.parent.parent.context.$implicit, _v.context.index, _v.parent.parent.parent.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_40)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_5 = _v.parent.parent.context.$implicit.indicatorTypeMeta[_v.context.index]; _ck(_v, 2, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.colSpan; var currVal_1 = (_v.context.$implicit.visible() ? "visible" : "none"); var currVal_2 = _co.getReadOnly(_v.parent.parent.context.$implicit, _v.context.index, _v.parent.parent.parent.parent.context.$implicit); var currVal_3 = _co.getFocused(_v.parent.parent.context.$implicit, _v.context.index, _v.parent.parent.parent.parent.context.$implicit); var currVal_4 = _co.getEditing(_v.parent.parent.context.$implicit, _v.context.index, _v.parent.parent.parent.parent.context.$implicit); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
function View_PstBranchTabColEditComponent_36(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "tr", [], [[2, "it-row-footer", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_37)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_38)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_39)), i0.ɵdid(6, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_v.parent.context.$implicit.children.length; _ck(_v, 2, 0, currVal_1); var currVal_2 = _v.parent.context.$implicit.children.length; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.branch.headerRows[(_co.branch.headerRows.length - 1)]; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.children.length; _ck(_v, 0, 0, currVal_0); }); }
function View_PstBranchTabColEditComponent_14(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_15)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_16)), i0.ɵdid(4, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_36)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.model.reportMeta.regionId && _v.context.$implicit.children.length); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.children; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.visible(); _ck(_v, 6, 0, currVal_2); }, null); }
function View_PstBranchTabColEditComponent_42(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "td", [["class", "it-read-only"], ["colspan", "2"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", " "])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "it-title-cell-overlay"]], [[4, "left", null], [4, "right", null]], null, null, null, null)), (_l()(), i0.ɵted(3, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.parent.context.$implicit.caption; _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_co.scrollLeft - 1) + "px"); var currVal_2 = (("-" + (_co.scrollLeft + 2)) + "px"); _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = _v.parent.parent.context.$implicit.caption; _ck(_v, 3, 0, currVal_3); }); }
function View_PstBranchTabColEditComponent_43(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "td", [["class", "it-read-only"], ["colspan", "2"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["\u0418\u0442\u043E\u0433\u043E \u043F\u043E \u00AB", "\u00BB: "])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "it-title-cell-overlay"]], [[4, "left", null], [4, "right", null]], null, null, null, null)), (_l()(), i0.ɵted(3, null, [" \u0418\u0442\u043E\u0433\u043E \u043F\u043E \u00AB", "\u00BB: "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.parent.context.$implicit.caption; _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_co.scrollLeft - 1) + "px"); var currVal_2 = (("-" + (_co.scrollLeft + 2)) + "px"); _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = _v.parent.parent.context.$implicit.caption; _ck(_v, 3, 0, currVal_3); }); }
function View_PstBranchTabColEditComponent_45(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-branch-cell-edit", [], null, null, null, i4.View_PstBranchCellEditComponent_0, i4.RenderType_PstBranchCellEditComponent)), i0.ɵdid(1, 573440, null, 0, i5.PstBranchCellEditComponent, [i6.LookupSourceService, i7.AppNavigationService, i8.PstReportService, i9.PstReportStructureService], { branch: [0, "branch"], model: [1, "model"], reportIndicator: [2, "reportIndicator"], indicatorTypeMeta: [3, "indicatorTypeMeta"], indicatorLevel: [4, "indicatorLevel"], editing: [5, "editing"], focused: [6, "focused"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.branch; var currVal_1 = _co.model; var currVal_2 = _v.parent.parent.parent.parent.context.$implicit.indicators[_v.parent.context.index]; var currVal_3 = _v.parent.parent.parent.context.$implicit.indicatorTypeMeta[_v.parent.context.index]; var currVal_4 = _v.parent.parent.parent.context.$implicit; var currVal_5 = _co.getEditing(_v.parent.parent.parent.context.$implicit, _v.parent.context.index, _v.parent.parent.parent.parent.context.$implicit); var currVal_6 = _co.getFocused(_v.parent.parent.parent.context.$implicit, _v.parent.context.index, _v.parent.parent.parent.parent.context.$implicit); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_PstBranchTabColEditComponent_44(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "td", [["class", "it-indicator"]], [[1, "colspan", 0], [4, "display", null], [2, "it-focused", null], [2, "it-editing", null], [2, "it-read-only", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cellClick(_v.parent.parent.context.$implicit, _v.context.index, _v.parent.parent.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_45)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_5 = _v.parent.parent.context.$implicit.indicatorTypeMeta[_v.context.index]; _ck(_v, 2, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.colSpan; var currVal_1 = (_v.context.$implicit.visible() ? "visible" : "none"); var currVal_2 = _co.getFocused(_v.parent.parent.context.$implicit, _v.context.index, _v.parent.parent.parent.context.$implicit); var currVal_3 = _co.getEditing(_v.parent.parent.context.$implicit, _v.context.index, _v.parent.parent.parent.context.$implicit); var currVal_4 = _co.getReadOnly(_v.parent.parent.context.$implicit, _v.context.index, _v.parent.parent.parent.context.$implicit); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
function View_PstBranchTabColEditComponent_41(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "tr", [], [[2, "it-row-footer", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_42)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_43)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_44)), i0.ɵdid(6, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_v.parent.context.$implicit.children.length; _ck(_v, 2, 0, currVal_1); var currVal_2 = _v.parent.context.$implicit.children.length; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.branch.headerRows[(_co.branch.headerRows.length - 1)]; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.children.length; _ck(_v, 0, 0, currVal_0); }); }
function View_PstBranchTabColEditComponent_12(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_13)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_14)), i0.ɵdid(4, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_41)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.model.reportMeta.regionId && _v.context.$implicit.children.length); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.children; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.visible(); _ck(_v, 6, 0, currVal_2); }, null); }
function View_PstBranchTabColEditComponent_48(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-branch-cell-edit", [], null, null, null, i4.View_PstBranchCellEditComponent_0, i4.RenderType_PstBranchCellEditComponent)), i0.ɵdid(1, 573440, null, 0, i5.PstBranchCellEditComponent, [i6.LookupSourceService, i7.AppNavigationService, i8.PstReportService, i9.PstReportStructureService], { branch: [0, "branch"], model: [1, "model"], reportIndicator: [2, "reportIndicator"], indicatorTypeMeta: [3, "indicatorTypeMeta"], indicatorLevel: [4, "indicatorLevel"], editing: [5, "editing"], focused: [6, "focused"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.branch; var currVal_1 = _co.model; var currVal_2 = _v.parent.parent.parent.context.$implicit.indicators[_v.parent.context.index]; var currVal_3 = _v.parent.parent.parent.context.$implicit.indicatorTypeMeta[_v.parent.context.index]; var currVal_4 = _v.parent.parent.parent.context.$implicit; var currVal_5 = _co.getEditing(_v.parent.parent.parent.context.$implicit, _v.parent.context.index, _v.parent.parent.parent.context.$implicit); var currVal_6 = _co.getFocused(_v.parent.parent.parent.context.$implicit, _v.parent.context.index, _v.parent.parent.parent.context.$implicit); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_PstBranchTabColEditComponent_47(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "td", [["class", "it-indicator it-read-only"]], [[1, "colspan", 0], [4, "display", null], [2, "it-focused", null], [2, "it-editing", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cellClick(_v.parent.parent.context.$implicit, _v.context.index, _v.parent.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_48)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_4 = _v.parent.parent.context.$implicit.indicatorTypeMeta[_v.context.index]; _ck(_v, 2, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.colSpan; var currVal_1 = (_v.context.$implicit.visible() ? "visible" : "none"); var currVal_2 = _co.getFocused(_v.parent.parent.context.$implicit, _v.context.index, _v.parent.parent.context.$implicit); var currVal_3 = _co.getEditing(_v.parent.parent.context.$implicit, _v.context.index, _v.parent.parent.context.$implicit); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_PstBranchTabColEditComponent_46(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "tr", [["class", "it-row-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "td", [["class", "it-read-only"], ["colspan", "2"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u0418\u0442\u043E\u0433\u043E \u043F\u043E \u043A\u0440\u0430\u044E: "])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "it-title-cell-overlay"]], [[4, "left", null], [4, "right", null]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" \u0418\u0442\u043E\u0433\u043E \u043F\u043E \u043A\u0440\u0430\u044E: "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_47)), i0.ɵdid(6, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.branch.headerRows[(_co.branch.headerRows.length - 1)]; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.scrollLeft - 1) + "px"); var currVal_1 = (("-" + (_co.scrollLeft + 2)) + "px"); _ck(_v, 3, 0, currVal_0, currVal_1); }); }
function View_PstBranchTabColEditComponent_50(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "a", [["class", "itech-link in-table-link edit-layout"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addReportIndicator() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "clr-icon", [["shape", "plus"]], null, null, null, null, null)), i0.ɵdid(3, 16384, null, 0, i2.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, [" \u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u043D\u043E\u0432\u0443\u044E \u0433\u0440\u0430\u0444\u0443 \u0432 \u0440\u0430\u0437\u0434\u0435\u043B"]))], null, null); }
function View_PstBranchTabColEditComponent_49(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "tr", [["class", "it-row-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 9, "td", [["style", "border-right: none"]], [[1, "colspan", 0]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "a", [["class", "btn-link in-table-link itech-link"], ["title", "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u043F\u0440\u0435\u0434\u043F\u0440\u0438\u044F\u0442\u0438\u0435"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.searchAgent(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "clr-icon", [["shape", "plus"]], null, null, null, null, null)), i0.ɵdid(4, 16384, null, 0, i2.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, [" \u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u043F\u0440\u0435\u0434\u043F\u0440\u0438\u044F\u0442\u0438\u0435 "])), (_l()(), i0.ɵeld(6, 0, null, null, 4, "div", [["class", "it-title-cell-overlay"], ["style", "width: 210px; border-width: 0 0 1px 1px"]], [[4, "left", null]], null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 3, "a", [["class", "btn-link in-table-link itech-link"], ["title", "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u043F\u0440\u0435\u0434\u043F\u0440\u0438\u044F\u0442\u0438\u0435"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.searchAgent(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "clr-icon", [["shape", "plus"]], null, null, null, null, null)), i0.ɵdid(9, 16384, null, 0, i2.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, [" \u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u043F\u0440\u0435\u0434\u043F\u0440\u0438\u044F\u0442\u0438\u0435 "])), (_l()(), i0.ɵeld(11, 0, null, null, 2, "td", [], [[1, "colspan", 0]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_50)), i0.ɵdid(13, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.model.showReportStructure; _ck(_v, 13, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = 2; _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_co.scrollLeft - 1) + "px"); _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.branch.headerRows[(_co.branch.headerRows.length - 1)].length; _ck(_v, 11, 0, currVal_2); }); }
function View_PstBranchTabColEditComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_9)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_10)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_12)), i0.ɵdid(6, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_46)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_PstBranchTabColEditComponent_49)), i0.ɵdid(10, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.caption; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.model.showReportStructure; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.children; _ck(_v, 6, 0, currVal_2); var currVal_3 = _v.context.$implicit.visible(); _ck(_v, 8, 0, currVal_3); var currVal_4 = (_v.context.$implicit.hasEditableCells && !i0.ɵunv(_v, 10, 0, i0.ɵnov(_v, 11).transform(_co.securityService.getUserInfo())).restrictPstAgentId); _ck(_v, 10, 0, currVal_4); }, null); }
function View_PstBranchTabColEditComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "table", [["class", "it-pst-tab-col-tbl"], ["tabindex", "0"]], [[4, "width", null]], [[null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown" === en)) {
        var pd_0 = (_co.tableKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "thead", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_3)), i0.ɵdid(3, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_8)), i0.ɵdid(6, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.branch.headerRows; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.branch.rootRowsGroup; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getSuggestedTableWidth(); _ck(_v, 0, 0, currVal_0); }); }
export function View_PstBranchTabColEditComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { tableCtnr: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, [[1, 0], ["tableCtnr", 1]], null, 2, "div", [["class", "it-pst-tab-col-tbl-wrapper"]], null, [[null, "scroll"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("scroll" === en)) {
        var pd_0 = (_co.onTableCtnrScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchTabColEditComponent_2)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.branch; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.branch && _co.branch.headerRows); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_PstBranchTabColEditComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-branch-tab-col-edit", [], null, null, null, View_PstBranchTabColEditComponent_0, RenderType_PstBranchTabColEditComponent)), i0.ɵdid(1, 573440, null, 0, i10.PstBranchTabColEditComponent, [i6.LookupSourceService, i7.AppNavigationService, i8.PstReportService, i11.AddressPersonFioCacheService, i9.PstReportStructureService, i7.AppNavigationService, i12.AlertService, i13.SecurityService], null, null)], null, null); }
var PstBranchTabColEditComponentNgFactory = i0.ɵccf("app-branch-tab-col-edit", i10.PstBranchTabColEditComponent, View_PstBranchTabColEditComponent_Host_0, { branch: "branch", model: "model" }, {}, []);
export { PstBranchTabColEditComponentNgFactory as PstBranchTabColEditComponentNgFactory };
