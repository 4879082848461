import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { AgGridLocalization } from './ag-grid-localization';
import { DataCachingService } from '../../logic/services/data-caching.service';
import { Required } from '../infrastructure/app-decotators';
import { AgGridLookupRendererComponent } from './ag-grid-lookup-renderer.component';
import { AgGridButtonStatusBarComponent } from './ag-grid-button-status-bar.component';
import { SecurityService } from '../../logic/services/security.service';
var AppGridComponent = /** @class */ (function () {
    function AppGridComponent(dataCachingService, userService) {
        var _this = this;
        this.dataCachingService = dataCachingService;
        this.userService = userService;
        this.pagination = true;
        this.rowMultiSelectWithClick = false;
        this.frameworkComponents = {};
        this.masterDetail = false;
        this.detailRowHeight = '350';
        this.gridReady = new EventEmitter();
        this.rowClicked = new EventEmitter();
        this.rowDoubleClicked = new EventEmitter();
        this.cellClicked = new EventEmitter();
        this.selectionChanged = new EventEmitter();
        this.gridSizeChanged = new EventEmitter();
        this.rowSelected = new EventEmitter();
        this.modelUpdated = new EventEmitter();
        this.agGridLocaleTextFunc = AgGridLocalization.getLocalization;
        this.defColDef = {
            sortable: true,
            filter: true,
            resizable: true
        };
        this.excelStyles = [];
        this.includeExportColumnIds = [];
        this.autoGroupColumnDef = {
            autoHeight: true,
            minWidth: 600,
        };
        this.groupRowRenderer = 'groupRowRenderer';
        this.statusBar = {
            statusPanels: [
                {
                    statusPanel: 'agTotalAndFilteredRowCountComponent',
                    align: 'left',
                },
                {
                    statusPanel: 'agSelectedRowCountComponent',
                    align: 'left',
                },
                {
                    statusPanel: 'statusBarComponent',
                    align: 'right',
                    statusPanelParams: {
                        icon: 'pinboard',
                        title: 'Сохранить настройки (порядок, видимость, сортировка, закрепление)',
                        clickCallback: function () { return _this.storeGridSettings(); },
                    }
                }
            ],
        };
        /**
         * Поле, по которому настраивается группировка, должно содержать проперти rowGroupingSettings
         * rowGroupingSettings в себе должен содержать поля:
         * 1. name: string - будет отображаться для наименования группировки
         * 2. renderParams: any - поле, ответственное за параметры группировки
         *
         * renderParams в себе содержит поля-индексы группировок (от 0 до ..., где 0 - первый уровень группировки, 1 - второй и т.д.)
         * каждое поле-индекс содержит настройки:
         * - captionGetter - функция для получения фиксированного значения (при наличии поля и не null значения - преимущество этому полю)
         * - fieldByGroup - имя другого поля в таблице, по которому будут группироваться строки (применяется при нескольких уровнях)
         * - lookupName (для асинхронной работы) - функция для получения имени справочника
         * - lookupValueGetter (для асинхронной работы) - функция для получения значения для добавления к lookupName
         * - isCombineKey (для асинхронной работы) - поле для пометки, что значение из получаемого справочника зависит от другого справочника
         * - getChildrenCount - функция для подсчета количества вложенных внутри группы строк (при отсутствии просто посчитает количество строк)
         */
        this.groupRowRendererParams = function (row) {
            var appCustomRowRendererParams = row && row.api && row.api['appCustomRowRendererParams']
                ? row.api['appCustomRowRendererParams']
                : _this.dataCachingService.getCachedData(_this.uniqueGridName + '_RowRendererParams', '1');
            if (!appCustomRowRendererParams) {
                return {};
            }
            var paramsByGroupIndex = appCustomRowRendererParams[row.node.rowGroupIndex];
            if (paramsByGroupIndex.isCombineKey) {
                if (!paramsByGroupIndex['captionGetter'] || !paramsByGroupIndex.captionGetter(row)) {
                    var valueKeys = paramsByGroupIndex.lookupValueGetter(row).split('/');
                    return AppGridComponent.getLookupGroupRowRendererParams(paramsByGroupIndex, row, paramsByGroupIndex.lookupName, valueKeys[1], valueKeys[0]);
                }
                else {
                    return AppGridComponent.getLookupGroupRowRendererParams(paramsByGroupIndex, row, paramsByGroupIndex.captionGetter(row) ? null : paramsByGroupIndex.lookupName, paramsByGroupIndex.captionGetter(row));
                }
            }
            else {
                if (!paramsByGroupIndex['captionGetter'] || !paramsByGroupIndex.captionGetter(row)) {
                    return AppGridComponent.getLookupGroupRowRendererParams(paramsByGroupIndex, row, paramsByGroupIndex.lookupName, paramsByGroupIndex.lookupValueGetter(row));
                }
                else {
                    return AppGridComponent.getLookupGroupRowRendererParams(paramsByGroupIndex, row, null, paramsByGroupIndex.captionGetter(row));
                }
            }
        };
    }
    AppGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.excelStyles.push(this.getHeaderExcelStyle(), this.getCellExcelStyle());
        if (this.columnDefs) {
            // почему-то в версии 22.1.1 не работает по умолчанию стили для всех ячеек по ид 'cell'
            // в документации не нашел, когда была введена эта функция, поэтому сами добавим cellClass,
            // добавим здесь, чтобы в каждом columnDefs во всей программе не добавлять однотипную строку
            this.columnDefs.forEach(function (colDef, ix) {
                colDef['colId'] = ix.toString();
                if (!colDef['cellClass']) {
                    colDef['cellClass'] = 'cell';
                }
                if (!colDef['excludeExport']) {
                    _this.includeExportColumnIds.push(ix.toString());
                }
            });
        }
        this.frameworkComponents['groupRowRenderer'] = AgGridLookupRendererComponent;
        this.frameworkComponents['statusBarComponent'] = AgGridButtonStatusBarComponent;
    };
    AppGridComponent.prototype.onGridReady = function (params) {
        this.api = params.api;
        this.columnApi = params.columnApi;
        // getContextMenuItems не работает с this, поэтому в параметры сетки закидываем свои поля
        params.api['appCustomExportFileName'] = this.exportFileName;
        params.api['appCustomIncludeExportColumnIds'] = this.includeExportColumnIds;
        params.api['appCustomColDefs'] = this.columnDefs;
        this.gridReady.emit(params);
    };
    AppGridComponent.prototype.sortedChange = function () {
        this.dataCachingService.addToCache(this.uniqueGridName + '_SortModel', '1', this.api.getSortModel());
    };
    AppGridComponent.prototype.filterChange = function () {
        this.dataCachingService.addToCache(this.uniqueGridName + '_FilterModel', '1', this.api.getFilterModel());
    };
    AppGridComponent.prototype.columnPinnedChange = function () {
        var _this = this;
        this.columnApi.getAllGridColumns()
            .forEach(function (col) { return _this.updateCacheByColId('_PinnedModel', col.getColId(), col.getPinned() || undefined); });
    };
    AppGridComponent.prototype.visibleChange = function () {
        var _this = this;
        this.columnApi.getAllGridColumns()
            .forEach(function (col) { return _this.updateCacheByColId('_VisibleModel', col.getColId(), col.isVisible() ? undefined : false); });
    };
    AppGridComponent.prototype.columnMovedChange = function () {
        var _this = this;
        if (this.columnApi.getAllGridColumns().every(function (col, ix) { return +col.getColId() === ix; })) {
            this.dataCachingService.removeCachedData(this.uniqueGridName + '_MovedModel', '1');
        }
        else {
            this.columnApi.getAllGridColumns()
                .forEach(function (col, ix) { return _this.updateCacheByColId('_MovedModel', col.getColId(), ix); });
        }
    };
    AppGridComponent.prototype.columnResizeChange = function () {
        var _this = this;
        this.columnApi.getAllColumns()
            .forEach(function (col) { return _this.updateCacheByColId('_ResizeModel', col.getColId(), !(col.getColDef() || {}).width || col.getColDef().width !== col.getActualWidth() ? col.getActualWidth() : undefined); });
    };
    AppGridComponent.prototype.updateCacheByColId = function (postfixCache, colId, val) {
        var existing = this.dataCachingService.getCachedData(this.uniqueGridName + postfixCache, '1');
        if (!existing) {
            this.dataCachingService.addToCache(this.uniqueGridName + postfixCache, '1', {});
            existing = this.dataCachingService.getCachedData(this.uniqueGridName + postfixCache, '1');
        }
        existing[colId] = val;
    };
    AppGridComponent.getLookupGroupRowRendererParams = function (paramsByGroupIndex, row, lookupName, value, value2) {
        if (value2 === void 0) { value2 = null; }
        return {
            lookupName: lookupName,
            value: value,
            value2: value2,
            captionIfNotFound: paramsByGroupIndex.captionIfNotFound || 'не указано',
            comment: paramsByGroupIndex['getChildrenCount'] && typeof paramsByGroupIndex['getChildrenCount'] === 'function' && row.node
                ? paramsByGroupIndex.getChildrenCount(row.node)
                : row.node
                    ? AppGridComponent.getChildrenCount(row.node)
                    : undefined,
        };
    };
    AppGridComponent.getChildrenCount = function (node) {
        return '(' + (node.allLeafChildren ? node.allLeafChildren.length : 0) + ')';
    };
    AppGridComponent.prototype.getHeaderExcelStyle = function () {
        return {
            id: 'header',
            alignment: { vertical: 'Center', horizontal: 'Center', wrapText: true },
            font: {
                fontName: 'Times New Roman',
                size: 12,
                bold: true
            },
            interior: {
                color: '#f8f8f8',
                pattern: 'Solid',
            },
            borders: {
                borderBottom: this.getBorderExcelStyle(),
                borderLeft: this.getBorderExcelStyle(),
                borderRight: this.getBorderExcelStyle(),
                borderTop: this.getBorderExcelStyle()
            },
        };
    };
    AppGridComponent.prototype.getCellExcelStyle = function () {
        return {
            id: 'cell',
            alignment: { vertical: 'Center', horizontal: 'Center', wrapText: true },
            font: {
                fontName: 'Times New Roman',
                size: 12
            },
            borders: {
                borderBottom: this.getBorderExcelStyle(),
                borderLeft: this.getBorderExcelStyle(),
                borderRight: this.getBorderExcelStyle(),
                borderTop: this.getBorderExcelStyle()
            },
        };
    };
    AppGridComponent.prototype.getBorderExcelStyle = function () {
        return {
            color: '#000000',
            lineStyle: 'Continuous',
            weight: 1,
        };
    };
    AppGridComponent.prototype.rowClickedEvent = function ($event) {
        if ($event && $event.data) {
            this.rowClicked.emit($event);
        }
    };
    AppGridComponent.prototype.rowDoubleClickedEvent = function ($event) {
        if ($event && $event.data) {
            this.rowDoubleClicked.emit($event);
        }
    };
    AppGridComponent.prototype.rowDataChanged = function (event) {
        var existingSortParams = this.dataCachingService.getCachedData(this.uniqueGridName + '_SortModel', '1');
        var existingFilterParams = this.dataCachingService.getCachedData(this.uniqueGridName + '_FilterModel', '1');
        var existingPinnedParams = this.dataCachingService.getCachedData(this.uniqueGridName + '_PinnedModel', '1');
        var existingVisibleParams = this.dataCachingService.getCachedData(this.uniqueGridName + '_VisibleModel', '1');
        var existingMovedParams = this.dataCachingService.getCachedData(this.uniqueGridName + '_MovedModel', '1');
        var existingResizeParams = this.dataCachingService.getCachedData(this.uniqueGridName + '_ResizeModel', '1');
        if (existingSortParams) {
            event.api.setSortModel(existingSortParams);
        }
        if (existingFilterParams) {
            event.api.setFilterModel(existingFilterParams);
        }
        if (existingPinnedParams) {
            Object.keys(existingPinnedParams).forEach(function (colId) { return event.columnApi.setColumnPinned(colId, existingPinnedParams[colId]); });
        }
        if (existingVisibleParams) {
            Object.keys(existingVisibleParams)
                .forEach(function (colId) { return event.columnApi.setColumnVisible(colId, !(existingVisibleParams[colId] === false)); });
        }
        if (existingMovedParams) {
            Object.keys(existingMovedParams).forEach(function (colId) { return event.columnApi.moveColumn(colId, existingMovedParams[colId]); });
        }
        if (existingResizeParams) {
            Object.keys(existingResizeParams).forEach(function (colId) { return event.columnApi.setColumnWidth(colId, existingResizeParams[colId]); });
        }
    };
    AppGridComponent.prototype.clickContextMenu = function ($event) {
        if (!this.api) {
            return;
        }
        this.contextMenuPositionLeft = $event.clientX;
        this.contextMenuPositionTop = $event.clientY - 5;
        this.api['isVisibleContextMenu'] = true;
    };
    AppGridComponent.prototype.storeGridSettings = function () {
        var store = {
            tableName: this.uniqueGridName,
            sort: JSON.stringify(this.dataCachingService.getCachedData(this.uniqueGridName + '_SortModel', '1') || {}),
            filter: JSON.stringify(this.dataCachingService.getCachedData(this.uniqueGridName + '_FilterModel', '1') || {}),
            pinned: JSON.stringify(this.dataCachingService.getCachedData(this.uniqueGridName + '_PinnedModel', '1') || {}),
            visible: JSON.stringify(this.dataCachingService.getCachedData(this.uniqueGridName + '_VisibleModel', '1') || {}),
            moved: JSON.stringify(this.dataCachingService.getCachedData(this.uniqueGridName + '_MovedModel', '1') || {}),
            widthCols: JSON.stringify(this.dataCachingService.getCachedData(this.uniqueGridName + '_ResizeModel', '1') || {}),
        };
        this.userService.storeTableSetting(store).subscribe(function () { });
    };
    tslib_1.__decorate([
        Required,
        tslib_1.__metadata("design:type", Object)
    ], AppGridComponent.prototype, "uniqueGridName", void 0);
    tslib_1.__decorate([
        Required,
        tslib_1.__metadata("design:type", Array)
    ], AppGridComponent.prototype, "columnDefs", void 0);
    return AppGridComponent;
}());
export { AppGridComponent };
