import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { AppFormGroup } from '../../../ui/controls/app-form-group';
import { ServerSideErrorsProvider } from '../../../logic/validators/server-side-errors-provider';
import { FormHelper } from '../../../ui/controls/form-helper';
import { StringHelper } from '../../../helpers/string-helper';
import { EditQuarantineStagesComponent } from './edit-quarantine-stages.component';
import { QuarantineDataService } from '../../../logic/services/quarantine-data.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { DateHelper } from '../../../helpers/date-helper';

@Component({
  selector: 'app-edit-quarantine-suspicion',
  templateUrl: './edit-quarantine-stages-suspicion.component.html',
  styleUrls: ['../edit-quarantine.all.css']
})
export class EditQuarantineStagesSuspicionComponent implements OnChanges {

  @Input() id: number;
  @Input() currentStatusId: number;
  @Input() contextFormGroup: AppFormGroup;
  @Input() serverSideErrorsProvider: ServerSideErrorsProvider;
  @Input() isAccessEdit = false;
  @Input() noSuspicionStage = false;

  @Output() dirtyFormGroup = new EventEmitter();
  @Output() needUpdateQuarantine = new EventEmitter();

  isInvalid = FormHelper.isInvalid;
  statusSuspicion = EditQuarantineStagesComponent.QUARANTINE_STAGE.SUSPICION;

  constructor(private quarantineDateService: QuarantineDataService,
              private dataCachingService: DataCachingService,
              private alertService: AlertService) {
  }

  ngOnChanges() {
    this.contextFormGroup.valueChanges.subscribe(x => this.dirtyFormGroup.next());
  }

  markAsDirty() {
    this.contextFormGroup.markAsDirty();
    this.dirtyFormGroup.next();
  }

  setNullIfWhitespace(controlName: string) {
    if (StringHelper.isNullOrWhitespace(this.contextFormGroup.get(controlName).value)) {
      this.contextFormGroup.get(controlName).setValue(null);
    }
  }

  getDeadline(dateFrom: string, maxHour: number) {
    return DateHelper.addMinutes(new Date(dateFrom), maxHour * 60);
  }
}
