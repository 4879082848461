<div *ngIf="branch">{{branch.displayTitle}}</div>
<div #tableCtnr class="it-pst-tab-col-tbl-wrapper" (scroll)="onTableCtnrScroll($event)">
  <table *ngIf="branch && branch.headerRows" class="it-pst-tab-col-tbl" tabindex="0"
         (keydown)="tableKeyDown($event)" [style.width]="getSuggestedTableWidth()">
    <thead>
    <tr *ngFor="let headerRow of branch.headerRows; index as ix">
      <td *ngIf="ix===0" [attr.rowspan]="branch.headerRows.length" style="width: 30px">
        №<br>п/п
      </td>
      <td *ngIf="ix===0" [attr.rowspan]="branch.headerRows.length" style="width: 180px">
        Наименование предприятия
        <div *ngIf="scrollLeft > 30" class="it-title-cell-overlay"
             [style.left]="(scrollLeft-31)+'px'" [style.right]="'-'+(scrollLeft-31)+'px'">
          Наименование предприятия
        </div>
      </td>
      <ng-container *ngFor="let headerCol of headerRow">
        <td *ngIf="headerCol.rowSpan && headerCol.colSpan" [attr.colspan]="headerCol.colSpan"
            [attr.rowspan]="headerCol.rowSpan"
            [style.width]="headerCol.meta.displayWidth && headerCol.lastRow? headerCol.meta.displayWidth + 'px' : undefined"
            class="it-indicator"
            [class.it-vertical]="headerCol.meta.displayVertical && headerCol.lastRow">
          <span>{{headerCol.displayTitle}}</span>
        </td>
      </ng-container>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let rootRowsGroup of branch.rootRowsGroup">
      <tr class="it-row-header" *ngIf="rootRowsGroup.caption">
        <td [attr.colspan]="branch.headerRows[branch.headerRows.length-1].length + 8">{{rootRowsGroup.caption}}</td>
      </tr>
      <!--
      Здесь древовидная структура с группировками отчета - уровень Групп МО
      -->
      <ng-container *ngFor="let group10 of rootRowsGroup.children">
        <tr class="it-row-header" *ngIf="!this.model.reportMeta.regionId && group10.children.length">
          <td [attr.colspan]="branch.headerRows[branch.headerRows.length-1].length + 8">{{group10.caption}}</td>
        </tr>
        <!--
        Здесь древовидная структура с группировками отчета - уровень МО
        -->
        <ng-container *ngFor="let group20 of group10.children">
          <tr class="it-row-header" *ngIf="!this.model.reportMeta.regionId && group20.children.length">
            <td [attr.colspan]="branch.headerRows[branch.headerRows.length-1].length + 8">{{group20.caption}}</td>
          </tr>
          <!--
          Здесь древовидная структура с группировками отчета - уровень КФХ / предприятия
          -->
          <ng-container *ngFor="let group30 of group20.children">
            <!--
            Здесь древовидная структура с группировками отчета - уровень КФХ / предприятия
            -->
            <ng-container *ngFor="let group40 of group30.children">
              <!--
              Здесь древовидная структура с группировками отчета - уровень классификаторов-подтипов
              -->
              <ng-container *ngFor="let group50 of group40.children; index as ix">
                <tr>
                  <td>{{ix + 1}}</td>
                  <td>
                    {{group50.caption}}
                    <div *ngIf="scrollLeft > 30" class="it-title-cell-overlay" style="background-color: white"
                         [style.left]="(scrollLeft-31)+'px'" [style.right]="'-'+(scrollLeft-31)+'px'">
                      {{group50.caption}}
                    </div>
                  </td>
                  <td *ngFor="let headerCol of branch.headerRows[branch.headerRows.length-1]; index as ii"
                      [class.it-read-only]="group50.indicatorTypeMeta[ii] && group50.indicatorTypeMeta[ii].formula"
                      [attr.colspan]="headerCol.colSpan"
                      (click)="cellClick(group50, ii, rootRowsGroup)"
                      [class.it-focused]="getFocused(group50, ii, rootRowsGroup)"
                      [class.it-editing]="getEditing(group50, ii, rootRowsGroup)"
                      class="it-indicator">
                    <app-branch-cell-date-edit
                      *ngIf="sellDealMeta[ii]&&sellDealMeta[ii].type==='date'"
                      [model]="model" [branch]="branch"
                      [originalCellValue]="group50.sellDealData[sellDealMeta[ii].fieldName]"
                      (changed)="sellDealChanged($event, group50, sellDealMeta[ii], group40.children)"
                      [focused]="getFocused(group50, ii, rootRowsGroup)"
                      [editing]="getEditing(group50, ii, rootRowsGroup)">
                    </app-branch-cell-date-edit>
                    <app-branch-cell-text-edit
                      *ngIf="sellDealMeta[ii]&&sellDealMeta[ii].type==='text'"
                      [model]="model" [branch]="branch"
                      [originalCellValue]="group50.sellDealData[sellDealMeta[ii].fieldName]"
                      (changed)="sellDealChanged($event, group50, sellDealMeta[ii], group40.children)"
                      [focused]="getFocused(group50, ii, rootRowsGroup)"
                      [editing]="getEditing(group50, ii, rootRowsGroup)">
                    </app-branch-cell-text-edit>
                    <app-branch-cell-lookup-edit
                      *ngIf="sellDealMeta[ii]&&sellDealMeta[ii].type==='lookup'"
                      [model]="model" [branch]="branch" [lookupName]="sellDealMeta[ii].lookupName"
                      [originalCellValue]="group50.sellDealData[sellDealMeta[ii].fieldName]"
                      (changed)="sellDealChanged($event, group50, sellDealMeta[ii], group40.children)"
                      [focused]="getFocused(group50, ii, rootRowsGroup)"
                      [editing]="getEditing(group50, ii, rootRowsGroup)">
                    </app-branch-cell-lookup-edit>
                    <app-branch-cell-edit *ngIf="group50.indicatorTypeMeta[ii]"
                                          [model]="model" [branch]="branch" [indicatorLevel]="group50"
                                          [focused]="getFocused(group50, ii, rootRowsGroup)"
                                          [indicatorTypeMeta]="group50.indicatorTypeMeta[ii]"
                                          [editing]="getEditing(group50, ii, rootRowsGroup)"
                                          [reportIndicator]="rootRowsGroup.indicators[ii]">
                    </app-branch-cell-edit>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
            <tr class="it-row-footer">
              <td colspan="2" *ngIf="!group30.children.length">{{group30.caption}}
                <div *ngIf="scrollLeft > 30" class="it-title-cell-overlay"
                     [style.left]="(scrollLeft-31)+'px'" [style.right]="'-'+(scrollLeft-31)+'px'">
                  {{group30.caption}}
                </div>
              </td>
              <td colspan="2" *ngIf="group30.children.length">Итого по «{{group30.caption}}»:
                <div *ngIf="scrollLeft > 30" class="it-title-cell-overlay"
                     [style.left]="(scrollLeft-31)+'px'" [style.right]="'-'+(scrollLeft-31)+'px'">
                  Итого по «{{group30.caption}}»:
                </div>
              </td>
              <td *ngFor="let headerCol of branch.headerRows[branch.headerRows.length-1]; index as ii"
                  [class.it-read-only]="group30.indicatorTypeMeta[ii] && group30.indicatorTypeMeta[ii].formula"
                  [attr.colspan]="headerCol.colSpan"
                  (click)="cellClick(group30, ii, rootRowsGroup)"
                  [class.it-focused]="getFocused(group30, ii, rootRowsGroup)"
                  [class.it-editing]="getEditing(group30, ii, rootRowsGroup)"
                  class="it-indicator">
                <app-branch-cell-edit *ngIf="group30.indicatorTypeMeta[ii]"
                                      [model]="model" [branch]="branch" [indicatorLevel]="group30"
                                      [focused]="getFocused(group30, ii, rootRowsGroup)"
                                      [indicatorTypeMeta]="group30.indicatorTypeMeta[ii]"
                                      [editing]="getEditing(group30, ii, rootRowsGroup)"
                                      [reportIndicator]="rootRowsGroup.indicators[ii]"></app-branch-cell-edit>
              </td>
            </tr>
          </ng-container>
          <tr class="it-row-footer">
            <td colspan="2" *ngIf="!group20.children.length">
              {{this.model.reportMeta.regionId && rootRowsGroup.caption ? rootRowsGroup.caption : group20.caption }}
              <div *ngIf="scrollLeft > 30" class="it-title-cell-overlay"
                   [style.left]="(scrollLeft-31)+'px'" [style.right]="'-'+(scrollLeft-31)+'px'">
                {{this.model.reportMeta.regionId && rootRowsGroup.caption ? rootRowsGroup.caption : group20.caption }}
              </div>
            </td>
            <td colspan="2" *ngIf="group20.children.length">
              Итого
              по «{{this.model.reportMeta.regionId && rootRowsGroup.caption ? rootRowsGroup.caption
                : group20.caption }}»:
              <div *ngIf="scrollLeft > 30" class="it-title-cell-overlay"
                   [style.left]="(scrollLeft-31)+'px'" [style.right]="'-'+(scrollLeft-31)+'px'">
                Итого
                по «{{this.model.reportMeta.regionId && rootRowsGroup.caption ? rootRowsGroup.caption
                  : group20.caption }}»:
              </div>
            </td>
            <td *ngFor="let headerCol of branch.headerRows[branch.headerRows.length-1]; index as ii"
                [attr.colspan]="headerCol.colSpan"
                [class.it-read-only]="group20.indicatorTypeMeta[ii] && group20.indicatorTypeMeta[ii].formula"
                (click)="cellClick(group20, ii, rootRowsGroup)"
                [class.it-focused]="getFocused(group20, ii, rootRowsGroup)"
                [class.it-editing]="getEditing(group20, ii, rootRowsGroup)"
                class="it-indicator">
              <app-branch-cell-edit *ngIf="group20.indicatorTypeMeta[ii]"
                                    [model]="model" [branch]="branch" [indicatorLevel]="group20"
                                    [focused]="getFocused(group20, ii, rootRowsGroup)"
                                    [indicatorTypeMeta]="group20.indicatorTypeMeta[ii]"
                                    [editing]="getEditing(group20, ii, rootRowsGroup)"
                                    [reportIndicator]="rootRowsGroup.indicators[ii]"></app-branch-cell-edit>
            </td>
          </tr>
        </ng-container>
        <tr class="it-row-footer" *ngIf="!this.model.reportMeta.regionId">
          <td colspan="2" *ngIf="!group10.children.length">{{group10.caption}}
            <div *ngIf="scrollLeft > 30" class="it-title-cell-overlay"
                 [style.left]="(scrollLeft-31)+'px'" [style.right]="'-'+(scrollLeft-31)+'px'">
              {{group10.caption}}
            </div>
          </td>
          <td colspan="2" *ngIf="group10.children.length">Итого по «{{group10.caption}}»:
            <div *ngIf="scrollLeft > 30" class="it-title-cell-overlay"
                 [style.left]="(scrollLeft-31)+'px'" [style.right]="'-'+(scrollLeft-31)+'px'">
              Итого по «{{group10.caption}}»:
            </div>
          </td>
          <td *ngFor="let headerCol of branch.headerRows[branch.headerRows.length-1]; index as ii"
              [attr.colspan]="headerCol.colSpan"
              (click)="cellClick(group10, ii, rootRowsGroup)"
              [class.it-focused]="getFocused(group10, ii, rootRowsGroup)"
              [class.it-editing]="getEditing(group10, ii, rootRowsGroup)"
              [class.it-read-only]="group10.indicatorTypeMeta[ii] && group10.indicatorTypeMeta[ii].formula"
              class="it-indicator">
            <app-branch-cell-edit *ngIf="group10.indicatorTypeMeta[ii]"
                                  [model]="model" [branch]="branch" [indicatorLevel]="group10"
                                  [focused]="getFocused(group10, ii, rootRowsGroup)"
                                  [indicatorTypeMeta]="group10.indicatorTypeMeta[ii]"
                                  [editing]="getEditing(group10, ii, rootRowsGroup)"
                                  [reportIndicator]="rootRowsGroup.indicators[ii]"></app-branch-cell-edit>
          </td>
        </tr>
      </ng-container>

      <tr class="it-row-footer" *ngIf="!this.model.reportMeta.regionId">
        <td colspan="2">Итого по краю:
          <div *ngIf="scrollLeft > 30" class="it-title-cell-overlay"
               [style.left]="(scrollLeft-31)+'px'" [style.right]="'-'+(scrollLeft-31)+'px'">
            Итого по краю:
          </div>
        </td>
        <td *ngFor="let headerCol of branch.headerRows[branch.headerRows.length-1]; index as ii"
            [attr.colspan]="headerCol.colSpan"
            (click)="cellClick(rootRowsGroup, ii, rootRowsGroup)"
            [class.it-focused]="getFocused(rootRowsGroup, ii, rootRowsGroup)"
            [class.it-editing]="getEditing(rootRowsGroup, ii, rootRowsGroup)"
            [class.it-read-only]="true"
            class="it-indicator">
          <app-branch-cell-edit *ngIf="rootRowsGroup.indicatorTypeMeta[ii]"
                                [model]="model" [branch]="branch" [indicatorLevel]="rootRowsGroup"
                                [focused]="getFocused(rootRowsGroup, ii, rootRowsGroup)"
                                [indicatorTypeMeta]="rootRowsGroup.indicatorTypeMeta[ii]"
                                [editing]="getEditing(rootRowsGroup, ii, rootRowsGroup)"
                                [reportIndicator]="rootRowsGroup.indicators[ii]"></app-branch-cell-edit>
        </td>
      </tr>

      <!--
      Строка с добавлением хоз. субъекта в отчет
      -->
      <tr class="it-row-footer" *ngIf="rootRowsGroup.hasEditableCells">
        <td [attr.colspan]="branch.headerRows[branch.headerRows.length-1].length + 8">
          <a class="btn-link in-table-link itech-link" title="Добавить объект"
             (click)="searchAgent(rootRowsGroup)">
            <clr-icon shape="plus"></clr-icon>
            Добавить объект
          </a>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
</div>
