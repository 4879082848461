<clr-tabs>
  <clr-tab>
    <button clrTabLink>Мероприятие</button>
    <clr-tab-content>
      <div class="edit-page-tab">
        <app-edit-animal-event-common [id]="id"></app-edit-animal-event-common>
      </div>
    </clr-tab-content>
  </clr-tab>
</clr-tabs>



