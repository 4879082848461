import { Component, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AppFormGroup } from '../../../ui/controls/app-form-group';
import { serverSideErrorsValidator } from '../../../logic/validators/server-side-errors-validator.directive';
import { ServerSideErrorsProvider } from '../../../logic/validators/server-side-errors-provider';

@Component({
  selector: 'app-edit-expertise-common',
  templateUrl: './edit-expertise-common.component.html',
  styleUrls: ['./edit-product-expertises.all.css']
})
export class EditExpertiseCommonComponent {

  @Input() contextFormGroup: AppFormGroup;
  @Input() productId: number;
  @Input() expertiseId: number;

  public static buildFormGroupDef(fb: FormBuilder, serverSideErrorsProvider: ServerSideErrorsProvider): any {
    return {
      version: '',
      expertiseTime: ['', Validators.compose([Validators.required, FormHelper.validateDateTimePicker(),
        serverSideErrorsValidator('expertiseTime', serverSideErrorsProvider)])],
      packageTypeId: ['', Validators.compose([
        serverSideErrorsValidator('packageTypeId', serverSideErrorsProvider)])],
      packagesCount: ['', Validators.compose([Validators.pattern(/^\d{1,6}$/),
        serverSideErrorsValidator('packagesCount', serverSideErrorsProvider)])],
      unitId: ['', Validators.compose([Validators.required,
        serverSideErrorsValidator('unitId', serverSideErrorsProvider)])],
      value: ['', Validators.compose([Validators.required, FormHelper.validateMoneyThreeDecimal(),
        serverSideErrorsValidator('value', serverSideErrorsProvider)])],
      examResult: ['', Validators.compose([
        serverSideErrorsValidator('examResult', serverSideErrorsProvider)])],
      diseaseTypeId: ['', Validators.compose([
        serverSideErrorsValidator('diseaseTypeId', serverSideErrorsProvider)])],
      testMaterialId: ['', Validators.compose([
        serverSideErrorsValidator('testMaterialId', serverSideErrorsProvider)])],
      expertisePlace: ['', Validators.compose([
        serverSideErrorsValidator('expertisePlace', serverSideErrorsProvider)])],
      diagnosticTypeId: ['', Validators.compose([
        serverSideErrorsValidator('diagnosticTypeId', serverSideErrorsProvider)])],
      productExpertiseTypeId: ['', Validators.compose([
        FormHelper.conditionalValidate(Validators.required, ctrl =>
          ctrl && ctrl.parent && +ctrl.parent.get('laboratoryEventTypeId').value &&
          +ctrl.parent.get('laboratoryEventTypeId').value !== 36),
        serverSideErrorsValidator('productExpertiseTypeId', serverSideErrorsProvider)])],
      productExpertiseSubtypeId: ['', Validators.compose([
        FormHelper.conditionalValidate(Validators.required, ctrl =>
          ctrl && ctrl.parent && +ctrl.parent.get('laboratoryEventTypeId').value &&
          +ctrl.parent.get('laboratoryEventTypeId').value !== 36),
        serverSideErrorsValidator('productExpertiseSubtypeId', serverSideErrorsProvider)])],
      laboratoryInstitutionId: ['', Validators.compose([Validators.required,
        serverSideErrorsValidator('laboratoryInstitutionId', serverSideErrorsProvider)])],
      laboratoryEventTypeId: ['', Validators.compose([Validators.required,
        serverSideErrorsValidator('laboratoryEventTypeId', serverSideErrorsProvider)])],
      mercuryNumber: '',
      mercuryGuid: '',
      conclusionDate: ['', Validators.compose([
        serverSideErrorsValidator('conclusionDate', serverSideErrorsProvider)])],
      conclusionText: ['', Validators.compose([
        serverSideErrorsValidator('conclusionText', serverSideErrorsProvider)])],
      conclusionMercuryNumber: '',
      conclusionReasonId: ['', Validators.compose([
        serverSideErrorsValidator('conclusionReasonId', serverSideErrorsProvider)])],
      conclusionResultTypeId: ['', Validators.compose([
        serverSideErrorsValidator('conclusionResultTypeId', serverSideErrorsProvider)])],
      relatedCreate: [false],
      excludeByRelated: [false],
      relatedProductExpertiseIds: fb.array([]),
    };
  }

  isInvalid(cname: string) {
    return FormHelper.isInvalid(this.contextFormGroup, cname);
  }
}
