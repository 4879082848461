
      <ng-container *ngIf="isPipe; else noPipe">
          <button *ngIf="params.value && equalsSome(params.lookupName, 'agent', 'animal', 'product', 'stable')"
                  type="button" class="btn btn-link" (click)="performAction()" [title]="'Перейти к карточке'"
                  style="position:absolute;top:7px;left:0;padding:0;margin:0;border:none;min-width:20px;max-height:20px;min-height:20px;">
              <clr-icon shape="angle right" style="vertical-align:super"></clr-icon>
          </button>
          <div style="min-height:24px;padding-left:5px"
               [title]="params.value|lookup:params.lookupName + (params.value2 || ''):true:false:false:true|async"
               [innerHtml]="params.value|lookup:params.lookupName + (params.value2 || ''):true:true:true|async"></div>
      </ng-container>
      <ng-template #noPipe>
          <div *ngIf="isGroup" (click)="onClickExpanded()"
               [class.first-grouping]="params.node.rowGroupIndex === 0"
               [class.second-grouping]="params.node.rowGroupIndex === 1"
               [class.third-grouping]="params.node.rowGroupIndex === 2"
               [class.fourth-grouping]="params.node.rowGroupIndex === 3"
               [class.fifth-grouping]="params.node.rowGroupIndex === 4">
              <clr-icon *ngIf="params.node.expanded" shape="angle up"></clr-icon>
              <clr-icon *ngIf="!params.node.expanded" shape="angle down"></clr-icon>
              {{ getLookup$() | async }}&nbsp;
              {{ params.comment ? params.comment : ''}}
          </div>
          <span *ngIf="!isGroup && params && params.navigateAction" class="itech-link in-table-link"
                (click)="navigateAction()">{{ getLookup$() | async }}</span>
          <span *ngIf="!isGroup && params && !params.navigateAction">{{ getLookup$() | async }}</span>
      </ng-template>