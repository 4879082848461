<div class="itech-common-wrap" id="interceptNoAccess">
  <div style="width: 100%">
    <app-grid [rowData]="model.searchResults" [columnDefs]="gridColumnDefs" [frameworkComponents]="frameworkComponents"
              [uniqueGridName]="'EditAnimalTicketsGrid'" [getRowStyle]="getRowStyle" (rowClicked)="clickTicket($event)"
              [exportFileName]="'Талоны приёма животного ' + (id|lookup:'animal'|async)">
    </app-grid>
  </div>

  <div class="itech-form-actions">
    <div>
      <button class="btn btn-secondary" (click)="refreshFormData()">
        <clr-icon shape="refresh"></clr-icon>&nbsp;Обновить
      </button>
    </div>
  </div>
</div>>
<app-ticket-edit-modal [model]="{ days: [] }"></app-ticket-edit-modal>
<app-ticket-delete-modal [model]="{ days: [] }"></app-ticket-delete-modal>
