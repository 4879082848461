<div class="itech-search-table-with-filter" *ngIf="securityService.isAccess('agent') | async">
  <div style="display: flex; flex-direction: row;">
    <clr-icon shape="search" size="72"></clr-icon>
    <div class="compact form itech-highlighted-sections" [formGroup]="model.filterFormGroup"
         *ngIf="model && model.filterFormGroup">
      <section class="form-block itech-block-medium">
        <h3>Поиск хозяйствующих субъектов</h3>
        <div class="form-group">
          <label style="display:flex;align-items:center"
                 [style.width.rem]="!toggleCaptionOrGuid ? 8 : undefined"
                 [style.max-width.rem]="!toggleCaptionOrGuid ? 8 : undefined">
            <button class="btn btn-icon-link" style="margin:0;padding:0;max-height:20px;min-width:20px;border:none"
                    (click)="changeToggleCaptionOrGuid()">
              <clr-icon shape="switch" style="display:block" class="has-badge"></clr-icon>
            </button>
            {{toggleCaptionOrGuid ? 'Наименование/ИД' : 'ГУИД'}}
          </label>
          <ng-container *ngIf="toggleCaptionOrGuid">
            <label for="caption" aria-haspopup="true"
                   role="tooltip" class="tooltip tooltip-validation tooltip-md"
                   [class.invalid]="isInvalid(model.filterFormGroup, 'caption')">
              <app-input-text [contextId]="'caption'" [classNames]="'itech-control-larger'"
                              [formControlObj]="model.filterFormGroup.get('caption')" [htmlType]="1"
                              (keydown)="filterEnterPressed($event)"></app-input-text>
              <app-validation-tooltip [input]="model.filterFormGroup.controls['caption']"></app-validation-tooltip>
            </label>
            <label for="id" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                   [class.invalid]="isInvalid(model.filterFormGroup, 'id')">
              <input type="text" id="id" placeholder="ИД" class="itech-control-small"
                     formControlName="id" (keypress)="processMoneyKeypress($event)" (keydown)="filterEnterPressed($event)">
              <app-validation-tooltip [input]="model.filterFormGroup.controls['id']"></app-validation-tooltip>
            </label>
          </ng-container>
          <label *ngIf="!toggleCaptionOrGuid" for="caption" aria-haspopup="true"
                 role="tooltip" class="tooltip tooltip-validation tooltip-md"
                 [class.invalid]="isInvalid(model.filterFormGroup, 'guid')">
            <input type="text" id="guid" placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx" class="itech-control-large"
                   formControlName="guid" (keydown)="filterEnterPressed($event)">
            <app-validation-tooltip [input]="model.filterFormGroup.controls['guid']"></app-validation-tooltip>
          </label>
        </div>
        <div class="form-group">
          <label>ФИО</label>
          <label for="fio" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                 [class.invalid]="isInvalid(model.filterFormGroup, 'fio')">
            <app-input-text [contextId]="'fio'" [classNames]="'itech-control-large'"
                            [formControlObj]="model.filterFormGroup.get('fio')" [htmlType]="1"
                            [placeholder]="'ФИО'" (keydown)="filterEnterPressed($event)"></app-input-text>
            <app-validation-tooltip [input]="model.filterFormGroup.controls['fio']"></app-validation-tooltip>
          </label>
          <div class="checkbox-inline">
            <input type="checkbox" id="showDeleted" formControlName="showDeleted" (change)="refreshResults()">
            <label for="showDeleted">Аннулированные</label>
          </div>
        </div>
        <div class="form-group">
          <label for="address">Адрес</label>
          <label for="address" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                 [class.invalid]="isInvalid(model.filterFormGroup, 'address')">
            <app-input-text [contextId]="'address'" [classNames]="'itech-control-xlarge'"
                            [formControlObj]="model.filterFormGroup.get('address')" [htmlType]="1"
                            [placeholder]="'Адрес'" (keydown)="filterEnterPressed($event)"></app-input-text>
            <app-validation-tooltip [input]="model.filterFormGroup.controls['address']"></app-validation-tooltip>
          </label>
        </div>
        <div class="form-group">
          <label for="cadNo">
            ИНН/{{model.filterFormGroup.controls['stableId'].value ? 'Поднадзорный объект' : 'Кадастровый номер'}}
          </label>
          <label for="inn" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                 [class.invalid]="isInvalid(model.filterFormGroup, 'inn')">
            <app-input-text [contextId]="'inn'" [classNames]="'itech-control-normal'"
                            [formControlObj]="model.filterFormGroup.get('inn')" [htmlType]="1"
                            [placeholder]="'ИНН'" (keydown)="filterEnterPressed($event)"></app-input-text>
            <app-validation-tooltip [input]="model.filterFormGroup.controls['inn']"></app-validation-tooltip>
          </label>
          <ng-container *ngIf="!model.filterFormGroup.controls['stableId'].value; else existingStableIdParams">
            <label for="cadNo" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                   [class.invalid]="isInvalid(model.filterFormGroup, 'cadNo')">
              <app-input-text [contextId]="'cadNo'" [classNames]="'itech-control-large'"
                              [formControlObj]="model.filterFormGroup.get('cadNo')" [htmlType]="1"
                              [placeholder]="'Кадастровый номер'" (keydown)="filterEnterPressed($event)"></app-input-text>
              <app-validation-tooltip [input]="model.filterFormGroup.controls['cadNo']"></app-validation-tooltip>
            </label>
          </ng-container>
          <ng-template #existingStableIdParams>
            <div class="itech-text-field itech-text-field-no-wrap" style="width:322px;max-width:322px"
                 title="{{model.filterFormGroup.controls['stableId'].value | lookup: 'stable' | async}}">
              {{model.filterFormGroup.controls['stableId'].value | lookup: 'stable' | async}}
            </div>
            &nbsp;&nbsp;
            <button *ngIf="model.filterFormGroup.get('availableChangeStableId').value"
                    type="button" class="btn btn-danger btn-link itech-inline-tool-btn" title="Указать другой поднадзорный объект"
                    (click)="model.filterFormGroup.controls['stableId'].setValue(undefined)">
              <clr-icon shape="remove"></clr-icon>
            </button>
          </ng-template>
        </div>
        <div class="form-group itech-group-whole-line" style="display:flex;align-items:center;">
          <button class="btn btn-primary" (click)="refreshResults()">
            <clr-icon shape="search" class="has-badge"></clr-icon>&nbsp;Поиск
          </button>
          <button *ngIf="model.availableCreateAgent" class="btn btn-link btn-primary"
                  (click)="performAgentCreationModal()">
            <clr-icon shape="users" class="has-badge"></clr-icon>&nbsp;Зарегистрировать новый хоз. субъект
          </button>
          <div *ngIf="model.filterFormGroup.controls['excludeId'].value" style="max-width:600px;"
               class="itech-control-desc itech-desc-error">
           {{model.filterFormGroup.controls['excludeCaption'].value}}
            #{{model.filterFormGroup.controls['excludeId'].value}}
            {{model.filterFormGroup.controls['excludeId'].value|lookup:'agent':true|async}}
          </div>
        </div>
      </section>
    </div>
  </div>

  <div>
    <app-grid [pagination]="false" [getRowStyle]="getRowStyle" [columnDefs]="gridColumnDefs" [uniqueGridName]="'AgentSearchResultGrid'"
              [rowData]="model.searchResults" (rowClicked)="rowClicked.emit($event.data)"
              [exportFileName]="'Список хозяйствующих субъектов'"></app-grid>
  </div>
</div>
