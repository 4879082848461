
      <section class="form-block itech-block-tiny"
               style="font-family:Roboto;margin:0 0 0 10px;max-height:100%;overflow-y:auto;overflow-x:hidden;min-height:100%">
        <div class="form compact" [formGroup]="contextFormGroup">
            <ng-container *ngFor="let formGroupKey of formGroupsKeys">
              <div class="form-group">
                <label *ngIf="isAllControlsVisible(formGroupKey.headerName) && !formGroupKey.isHide" style="white-space:normal"
                        for="{{'AgGridFormMasterDetailComponent' + rndToken}}"
                        [class.required]="formGroupKey.isRequiredClass">{{formGroupKey.headerName}}</label>
                <ng-container *ngFor="let control of formGroups[formGroupKey.headerName]">
                  <app-combo-lookup *ngIf="control.editorType === 10 && (!control.isVisible || control.isVisible(contextFormGroup))"
                                    class="{{control.sizeControl || 'itech-control-medium'}}"
                                    [contextControlId]="'AgGridFormMasterDetailComponent' + rndToken + '_' + control.field"
                                    [filterArrayIncludeControlName]="control.filterArrayControlName || 'id'"
                                    [filterArrayIncludeValue]="control.filterArray ? control.filterArray(contextFormGroup) : undefined"
                                    [lookupName]="control.lookupName +
                                     (control.parentFieldLookup && contextFormGroup.get(control.parentFieldLookup).value
                                       ? contextFormGroup.get(control.parentFieldLookup).value +
                                            (control.parentField2Lookup ? '/' + contextFormGroup.get(control.parentField2Lookup).value : '')
                                       : '')"
                                    [contextFormGroup]="contextFormGroup" [contextControlName]="control.field">
                  </app-combo-lookup>
                  <label *ngIf="control.editorType === 3 && (!control.isVisible || control.isVisible(contextFormGroup))"
                         for="{{'AgGridFormMasterDetailComponent' + rndToken + '_' + control.field}}" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md" [class.invalid]="isInvalid(contextFormGroup, control.field)">
                    <ng-container *ngIf="control.onlyDigits; else textInput">
                      <input type="number" min="0" [pattern]="'^[0-9]*$'"
                             id="{{'AgGridFormMasterDetailComponent' + rndToken + '_' + control.field}}"
                             [placeholder]="control.placeholder || ''" [formControlName]="control.field"
                             class="{{control.sizeControl || 'itech-control-medium'}}">
                    </ng-container>
                    <ng-template #textInput>
                      <app-input-text [contextId]="'AgGridFormMasterDetailComponent' + rndToken + '_' + control.field"
                                      [classNames]="control.sizeControl || 'itech-control-medium'"
                                      [formControlObj]="contextFormGroup.get(control.field)" [htmlType]="1"
                                      [placeholder]="control.placeholder || ''">
                      </app-input-text>
                    </ng-template>
                    <app-validation-tooltip [input]="contextFormGroup.controls[control.field]"></app-validation-tooltip>
                  </label>
                  <app-date-combo *ngIf="control.editorType === 6 && (!control.isVisible || control.isVisible(contextFormGroup))"
                                  [contextFormGroup]="contextFormGroup" [controlNameDate1]="control.field"
                                  [controlNameDate2]="control.field2" [isRequiredDayAndHour]="control.requiredDayAndHour">
                  </app-date-combo>
                  <app-datetime-picker *ngIf="control.editorType === 7 && (!control.isVisible || control.isVisible(contextFormGroup))"
                                       [contextFormGroup]="contextFormGroup" [contextControlName]="control.field"
                                       [withTime]="control.withTime"></app-datetime-picker>
                  <div class="form-group itech-group-whole-line"
                       *ngIf="control.editorType === 8 && (!control.isVisible || control.isVisible(contextFormGroup))">
                    <div class="checkbox-inline">
                      <input type="checkbox" id="{{'AgGridFormMasterDetailComponent' + rndToken + '_' + control.field}}"
                             [formControlName]="control.field" (change)="onClick(control)">
                      <label for="{{'AgGridFormMasterDetailComponent' + rndToken + '_' + control.field}}">{{control.caption}}</label>
                    </div>
                  </div>
                  <ng-container *ngIf="control.editorType === 9 && (!control.isVisible || control.isVisible(contextFormGroup))">
                      <app-ag-grid-cell-component-handler [params]="params" [colDef]="control">
                      </app-ag-grid-cell-component-handler>
                  </ng-container>
                  <label *ngIf="control.editorType === 11 && (!control.isVisible || control.isVisible(contextFormGroup))"
                         for="{{'AgGridFormMasterDetailComponent' + rndToken + '_' + control.field}}" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                         [class.invalid]="isInvalid(contextFormGroup, control.field)">
                    <app-input-text [contextId]="'AgGridFormMasterDetailComponent' + rndToken + '_' + control.field"
                                    [classNames]="'itech-control-xlarge itech-text-wall'"
                                    [formControlObj]="contextFormGroup.get(control.field)" [htmlType]="1"
                                    [placeholder]="control.placeholder || formGroupKey.headerName">
                    </app-input-text>
                    <app-validation-tooltip [input]="contextFormGroup.controls[control.field]"></app-validation-tooltip>
                  </label>
                </ng-container>
              </div>
          </ng-container>
        </div>
      </section>