import { OnChanges, SimpleChanges } from '@angular/core';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { FormBuilder } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { AnimalDataService } from '../../../logic/services/animal-data.service';
import { EditAnimalEventCommonFormComponent } from './edit-animal-event-common-form.component';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { of } from 'rxjs/index';
var EditAnimalEventCommonComponent = /** @class */ (function () {
    function EditAnimalEventCommonComponent(bookmarkService, animalDataService, alertService, cache, lookupSourceService, dataCachingService, waitingOverlay, fb, navigationService) {
        this.bookmarkService = bookmarkService;
        this.animalDataService = animalDataService;
        this.alertService = alertService;
        this.cache = cache;
        this.lookupSourceService = lookupSourceService;
        this.dataCachingService = dataCachingService;
        this.waitingOverlay = waitingOverlay;
        this.fb = fb;
        this.navigationService = navigationService;
        this.model = {};
        this.storing = false;
        this.isInvalid = FormHelper.isInvalid;
    }
    Object.defineProperty(EditAnimalEventCommonComponent.prototype, "contextFormGroup", {
        get: function () {
            return this.model ? this.model.contextFormGroup : undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditAnimalEventCommonComponent.prototype, "reportKind", {
        get: function () {
            return this.contextFormGroup
                ? +this.contextFormGroup.get('eventTypeId').value === 13
                    ? 'event-immunization'
                    : +this.contextFormGroup.get('eventTypeId').value === 16
                        ? 'event-disinfection'
                        : +this.contextFormGroup.get('eventTypeId').value === 4
                            ? 'event-allergic-tuberculin'
                            : 'event'
                : null;
        },
        enumerable: true,
        configurable: true
    });
    EditAnimalEventCommonComponent.prototype.ngOnChanges = function (changes) {
        if (this.id) {
            this.refreshFormDataInternal();
        }
    };
    EditAnimalEventCommonComponent.prototype.store = function () {
        var _this = this;
        FormHelper.markAsSubmitted(this.contextFormGroup);
        if (!this.contextFormGroup.valid) {
            return;
        }
        var dataToStore = this.contextFormGroup.value;
        if (dataToStore.drugs) {
            for (var i = 0; i < dataToStore.drugs.length; i++) {
                if (dataToStore.drugs[i].drugId) {
                    dataToStore.drugs[i].drugTypeId = null;
                }
            }
        }
        this.storing = true;
        this.animalDataService.storeAnimalEvent(dataToStore).subscribe({
            next: function (data) {
                _this.model.contextFormGroup = EditAnimalEventCommonFormComponent.createFormGroup(_this.fb, _this.model, data, function () { return _this.model.animal; });
                _this.storing = false;
                _this.cache.invalidateAnimal(_this.id);
                _this.invalidateCaches();
                _this.bookmarkService.refreshAnimalEventCardBookmark(_this.id);
                _this.contextFormGroup.markAsUntouched();
                _this.contextFormGroup.markAsPristine();
                FormHelper.setSingleFormGroupServerSideValidationErrorsWithRelationMsgKey({}, _this.model, _this.contextFormGroup);
            },
            error: function (error) {
                _this.storing = false;
                FormHelper.setSingleFormGroupServerSideValidationErrorsWithRelationMsgKey(error, _this.model, _this.contextFormGroup);
                window.document.getElementById('animal-event-common-content').scrollTop = 0;
            }
        });
    };
    EditAnimalEventCommonComponent.prototype.cancelEdit = function () {
        this.dataCachingService.removeCachedData('EditAnimalEventCommon', this.id.toString());
        this.refreshFormDataInternal();
    };
    EditAnimalEventCommonComponent.prototype.refreshFormData = function () {
        this.dataCachingService.removeCachedData('EditAnimalEventCommon', this.id.toString());
        this.refreshFormDataInternal();
    };
    EditAnimalEventCommonComponent.prototype.refreshFormDataInternal = function () {
        var _this = this;
        this.waitingOverlay.StartWaiting();
        this.model = { id: this.id, serverSideValidationErrors: [] };
        var existing = this.dataCachingService.getCachedData('EditAnimalEventCommon', this.id.toString());
        if (existing) {
            this.model = existing;
            this.waitingOverlay.EndWaiting();
        }
        else {
            this.animalDataService.getAnimalEventForEdit(this.id).subscribe({
                next: function (data) {
                    var animal$ = data.animalId
                        ? _this.cache.getAnimal(data.animalId)
                        : of(undefined);
                    animal$.subscribe(function (animal) {
                        _this.model.id = data.id;
                        _this.model.animal = animal;
                        _this.model.contextFormGroup = EditAnimalEventCommonFormComponent.createFormGroup(_this.fb, _this.model, data, function () { return _this.model.animal; });
                        _this.lookupSourceService.getLookupObj('event-type').subscribe(function (lookup) {
                            _this.model.contextFormGroup.get('eventTypeId').valueChanges.subscribe(function () {
                                _this.model.eventType = lookup['Obj' + _this.model.contextFormGroup.get('eventTypeId').value];
                            });
                            _this.model.eventType = lookup['Obj' + _this.model.contextFormGroup.get('eventTypeId').value];
                        });
                        _this.cache.invalidateAnimalEvent(_this.id);
                        _this.dataCachingService.addToCache('EditAnimalEventCommon', _this.id.toString(), _this.model);
                        _this.bookmarkService.refreshAnimalEventCardBookmark(_this.id);
                        _this.waitingOverlay.EndWaiting();
                    });
                },
                error: function () {
                    _this.waitingOverlay.EndWaiting();
                }
            });
        }
    };
    EditAnimalEventCommonComponent.prototype.deleteAnimalEvent = function () {
        var _this = this;
        this.alertService.confirmModal('Вы уверены, что хотите удалить мероприятие (событие)?')
            .subscribe(function () {
            _this.animalDataService.deleteAnimalEvent(_this.id)
                .subscribe({
                next: function () {
                    _this.invalidateCaches();
                    _this.bookmarkService.removeOpenCardBookmark(['/animal-event-edit'], _this.id.toString());
                    _this.alertService.success('Мероприятие (событие) успешно удалено!');
                },
                error: function (err) {
                    FormHelper.setSingleFormGroupServerSideValidationErrorsWithRelationMsgKey(err, _this.model, _this.contextFormGroup);
                    window.document.getElementById('animal-event-common-content').scrollTop = 0;
                }
            });
        });
    };
    EditAnimalEventCommonComponent.prototype.invalidateCaches = function () {
        if (this.model.animal) {
            this.dataCachingService.removeCachedData('EditAnimalEvents', this.model.animal.id);
        }
        if (this.model.stableId) {
            this.dataCachingService.removeCachedData('EditStableEvents', this.model.stableId);
        }
    };
    return EditAnimalEventCommonComponent;
}());
export { EditAnimalEventCommonComponent };
