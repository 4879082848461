import { EventEmitter, OnChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AddressComponent } from '../../../ui/controls/address.component';
import { serverSideErrorsValidator } from '../../../logic/validators/server-side-errors-validator.directive';
import { AppFormGroup } from '../../../ui/controls/app-form-group';
import { DateHelper } from '../../../helpers/date-helper';
import { StringHelper } from '../../../helpers/string-helper';
import { AgentSearchModalComponent } from '../../edit-agent/search/agent-search-modal.component';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { SecurityService } from '../../../logic/services/security.service';
var EditStableCommonFormComponent = /** @class */ (function () {
    function EditStableCommonFormComponent(fb, navigationService, userService) {
        this.fb = fb;
        this.navigationService = navigationService;
        this.userService = userService;
        this.syncStates = [];
        this.startSyncStableAgents = new EventEmitter();
        this.syncStateUpdated = new EventEmitter();
        this.syncCommonInfo = {};
        this.isInvalid = FormHelper.isInvalid;
        this.isInvalidControl = FormHelper.isInvalidControl;
        this.cadNumberPattern = StringHelper.cadNumberPattern;
        this.expandedAgentCounts = 2;
        this.changeFavouriteLoading = false;
    }
    EditStableCommonFormComponent.createFormGroup = function (fb, serverSideErrorsProvider, val) {
        var group = fb.group({
            __stub__history: false,
            id: val.id,
            createdTime: val.createdTime,
            createdUser: val.createdUser,
            deletedTime: val.deletedTime,
            deletedUser: val.deletedUser,
            stableTypeId: [val.stableTypeId, Validators.compose([Validators.required,
                    serverSideErrorsValidator('stableTypeId', serverSideErrorsProvider)])],
            caption: [val.caption, Validators.compose([
                    Validators.required,
                    serverSideErrorsValidator('caption', serverSideErrorsProvider)
                ])],
            cadNo: [StringHelper.stringCadNumberSplitWithColon(val.cadNo), Validators.compose([
                    serverSideErrorsValidator('cadNo', serverSideErrorsProvider),
                    FormHelper.validateCadNo(),
                ])],
            codes: fb.array((val.codes || []).map(function (el) { return fb.control(el); })),
            activityTypeCaptions: fb.array((val.activityTypeCaptions || []).map(function (el) { return fb.control(el); })),
            square: [val.square,
                Validators.compose([
                    serverSideErrorsValidator('square', serverSideErrorsProvider),
                    FormHelper.validateMoney(),
                ])],
            address: fb.group(AddressComponent.buildFormGroupDef(fb, serverSideErrorsProvider, val.address, true)),
            currentOwnerId: val.currentOwnerId,
            agents: EditStableCommonFormComponent.buildStableAgentsArray(fb, serverSideErrorsProvider, val.agents),
            trappedToQuarantine: val.trappedToQuarantine
        });
        return group;
    };
    EditStableCommonFormComponent.buildStableAgentsArray = function (fb, serverSideErrorsProvider, val) {
        if (!val || !val.length) {
            val = [];
        }
        val.sort(function (a, b) { return a.dateFrom.localeCompare(b.dateFrom); });
        return fb.array(val.map(function (agentPerson) { return EditStableCommonFormComponent.buildStableAgent(fb, agentPerson); }));
    };
    EditStableCommonFormComponent.buildStableAgent = function (fb, stableAgent) {
        return fb.group({
            __stub__editing: stableAgent.__stub__editing,
            stableId: stableAgent.stableId,
            agentId: [stableAgent.agentId, Validators.compose([Validators.required])],
            dateFrom: [stableAgent.dateFrom, Validators.compose([
                    Validators.required, FormHelper.validateDateTimePicker()
                ])],
            dateTo: [stableAgent.dateTo, Validators.compose([
                    FormHelper.validateDateTimePicker()
                ])],
        });
    };
    EditStableCommonFormComponent.addStableAgent = function (fb, agents) {
        var currentAgent = agents.controls[0];
        if (currentAgent) {
            currentAgent.get('dateTo').setValue(StringHelper.getISODate(DateHelper.addDays(new Date(), -1)));
        }
        var fg = EditStableCommonFormComponent.buildStableAgent(fb, { __stub__editing: true, dateFrom: StringHelper.getISODate(new Date()) });
        agents.insert(0, fg);
        agents.markAsDirty();
        return fg;
    };
    Object.defineProperty(EditStableCommonFormComponent.prototype, "agents", {
        get: function () {
            return this.contextFormGroup.get('agents');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditStableCommonFormComponent.prototype, "codes", {
        get: function () {
            return this.contextFormGroup.get('codes');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditStableCommonFormComponent.prototype, "activityTypeCaptions", {
        get: function () {
            return this.contextFormGroup.get('activityTypeCaptions');
        },
        enumerable: true,
        configurable: true
    });
    EditStableCommonFormComponent.prototype.ngOnChanges = function () {
        if (!this.syncStates) {
            return;
        }
        this.syncCommonInfo = this.syncStates.find(function (x) { return x && x.conversationTypeId === -2; }) || {};
    };
    EditStableCommonFormComponent.prototype.searchOwner = function () {
        var _this = this;
        this.navigationService.searchAgent(AgentSearchModalComponent).subscribe(function (val) {
            if (val) {
                _this.contextFormGroup.get('currentOwnerId').setValue(val);
                _this.contextFormGroup.markAsDirty();
            }
        });
    };
    EditStableCommonFormComponent.prototype.deleteStableAgent = function (i) {
        this.agents.removeAt(i);
        this.agents.markAsDirty();
    };
    EditStableCommonFormComponent.prototype.editStableAgent = function (i) {
        this.agents.controls[i].get('__stub__editing').setValue(true);
    };
    EditStableCommonFormComponent.prototype.showHistory = function () {
        this.contextFormGroup.get('__stub__history').setValue(!this.contextFormGroup.get('__stub__history').value);
    };
    EditStableCommonFormComponent.prototype.addActivityTypeCaption = function () {
        this.activityTypeCaptions.push(this.fb.control(undefined));
        this.contextFormGroup.markAsDirty();
    };
    EditStableCommonFormComponent.prototype.deleteActivityTypeCaption = function (index) {
        this.activityTypeCaptions.removeAt(index);
        this.activityTypeCaptions.markAsDirty();
    };
    EditStableCommonFormComponent.prototype.runStableAgentSync = function () {
        this.startSyncStableAgents.emit();
    };
    EditStableCommonFormComponent.prototype.changeFavourite = function () {
        var _this = this;
        this.changeFavouriteLoading = true;
        this.userService.changeFavourite(this.id).subscribe(function (val) { return _this.changeFavouriteLoading = false; });
    };
    return EditStableCommonFormComponent;
}());
export { EditStableCommonFormComponent };
