import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MetadataService } from '../../logic/services/metadata.service';
import { DataCachingService } from '../../logic/services/data-caching.service';
import { IModalBody, ModalResult } from '../../logic/services/app-navigation.service.models';
import { Observable } from 'rxjs';
import { AppNavigationService } from '../../logic/services/app-navigation.service';
import { environment } from '../../../environments/environment';

@Component({
  template: `
      <div style="min-height:90vh">
        <div *ngIf="loading" style="min-height:600px;text-align:center">
          <span class="spinner" style="top:288px"></span>
        </div>
        <div *ngIf="!loading">
          <button class="btn btn-link btn-primary" *ngIf="isFuncSourceFormat()" (click)="buildSourceFormat()">
              <clr-icon shape="download"></clr-icon>
              Скачать в исходном формате</button>
          <iframe #previewFile style="width:100%;height:82vh" src="" type="application/pdf"></iframe>
        </div>
      </div>`
})
export class AppModalPreviewFileComponent implements OnInit, IModalBody<any, void> {

  loading = false;
  @ViewChild('previewFile') previewFileRef: ElementRef;

  constructor(private metadataService: MetadataService,
              private dataCachingService: DataCachingService,
              private appNavigationService: AppNavigationService) {
  }

  ngOnInit() {
    this.loading = false;
    if (this.previewFileRef && this.previewFileRef.nativeElement) {
      this.previewFileRef.nativeElement.src = '';
    }

    if (!this.appNavigationService.customModalParams ||
      (!this.appNavigationService.customModalParams['fileUri'] && !this.appNavigationService.customModalParams['fileHash'])) {
      this.resetModalSettings();
      return;
    }
    this.loading = true;

    if (this.appNavigationService.customModalParams['fileUri']) {
      this.previewByFileUri(this.appNavigationService.customModalParams['fileUri']);
    } else {
      this.previewByFileHash(this.appNavigationService.customModalParams['fileHash']);
    }
  }

  previewByFileUri(fileUri: string) {
    const existing = this.dataCachingService.getCachedData('AppModalPreviewFile', fileUri);

    if (existing) {
      this.loading = false;
      setTimeout(() => this.previewFileRef.nativeElement.src = existing, 100);
      return;
    }

    if (this.metadataService.isAvailableFormatsForPreviewFile(fileUri.substring(fileUri.lastIndexOf('.') + 1))) {
      this.previewFileByFileUri(fileUri);
    } else {
      this.loadFile(fileUri);
    }
  }

  previewByFileHash(fileHash: string) {
    this.metadataService.getFileArrayBuffer(fileHash).subscribe(data => this.createBlobData(data));
  }

  previewFileByFileUri(fileUri: string) {
    this.metadataService.previewFile(fileUri).subscribe(data => this.createBlobData(data, fileUri));
  }

  createBlobData(data: any, keyCache?: string) {
    const blob = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
    this.loading = false;
    setTimeout(() => this.previewFileRef.nativeElement.src = blob, 100);
    if (keyCache) {
      this.dataCachingService.addToCache('AppModalPreviewFile', keyCache, blob);
    }
  }

  loadFile(fileUri: string) {
    this.metadataService.preloadFile(fileUri).subscribe(fileHash => {
      window.open(environment.api + '/files/get?preloadId=' + encodeURIComponent(fileHash));
    });
    this.resetModalSettings();
  }

  resetModalSettings() {
    this.appNavigationService.customModalOpened = false;
    this.loading = false;
  }

  isFuncSourceFormat() {
    return this.appNavigationService.customModalParams && this.appNavigationService.customModalParams['buildSourceFormatFunc'];
  }

  buildSourceFormat() {
    this.appNavigationService.customModalParams['buildSourceFormatFunc']();
    this.resetModalSettings();
  }

  initModalBody(data: any) {
  }

  onModalAccept$(): Observable<ModalResult<void>> {
    return undefined;
  }
}
