<div class="itech-common-wrap" id="interceptNoAccess">
  <div id="animal-common-content">
    <app-edit-animal-common-form [contextFormGroup]="contextFormGroup"
                                 (onNavigateEventsClicked)="navigateToEventsPage()"></app-edit-animal-common-form>
  </div>
  <div class="itech-form-actions">
    <ng-container *ngIf="!isExternalAnimal; else animalExternalActions">
      <div>
        <button class="btn btn-secondary" (click)="refreshFormData()">
          <clr-icon shape="refresh"></clr-icon>&nbsp;Обновить
        </button>
        <app-reports-button [reportKind]="'animal'" [objId1]="id" [dropdownPosition]="'top-left'">
        </app-reports-button>

        <clr-dropdown>
          <button class="btn btn-warning-outline" clrDropdownToggle>
            Действия <clr-icon shape="caret down"></clr-icon>
          </button>
          <clr-dropdown-menu *clrIfOpen [clrPosition]="'top-left'">
            <label class="dropdown-header">Выберите действие</label>
            <button class="btn btn-primary-outline" (click)="createProduct()"
                    [disabled]="contextFormGroup.get('animalSubtypesWithProduct').value.length">
              <clr-icon shape="plus"></clr-icon>&nbsp;Создать ВСД
            </button>
            <div class="dropdown-divider"></div>
            <button class="btn btn-primary-outline" (click)="navigationService.navigateJournal(undefined, 7, id, undefined)">
              <clr-icon shape="view-list"></clr-icon>&nbsp;Журнал
            </button>
            <div class="dropdown-divider"></div>
            <button class="btn btn-warning-outline" (click)="deleteAnimal()">
              <clr-icon shape="trash"></clr-icon> Удалить карточку
            </button>
          </clr-dropdown-menu>
        </clr-dropdown>

      </div>
      <div>
        <button class="btn btn-secondary" (click)="cloneAnimal()">
          <clr-icon shape="clone"></clr-icon>&nbsp;Создать копию
        </button>
      </div>
      <div>
        <button [clrLoading]="storing" class="btn btn-primary itech-form-save"
                [disabled]="!contextFormGroup || !contextFormGroup.dirty || storing" (click)="store()">
          <clr-icon shape="check"></clr-icon>&nbsp;Сохранить
        </button>
        <button class="btn btn-warning itech-form-cancel"
                [disabled]="!contextFormGroup || !contextFormGroup.dirty" (click)="cancelEdit()">
          <clr-icon shape="undo"></clr-icon>&nbsp;Отменить
        </button>
      </div>
    </ng-container>
    <ng-template #animalExternalActions>
      <div></div>
      <div><button [clrLoading]="storing" class="btn btn-primary itech-form-save"
                   [disabled]="!contextFormGroup || storing || !isExternalAnimal" (click)="confirmAnimalFromExternal()">
        <clr-icon shape="check"></clr-icon>&nbsp;Подтвердить
      </button>
        <button class="btn btn-warning itech-form-cancel"
                [disabled]="!contextFormGroup || storing || !isExternalAnimal" (click)="rejectAnimalExternal()">
          <clr-icon shape="times"></clr-icon>&nbsp;Отказать
        </button>
      </div>
    </ng-template>
  </div>
</div>
