<div class="itech-common-wrap" id="interceptNoAccess">
  <div id="product-common-content">
    <div class="itech-doc-container" *ngIf="contextFormGroup">
      <div class="itech-doc-image">
        <clr-icon shape="truck"></clr-icon>
        <div style="margin-top:20px">
          <app-mercury-sync-status-signpost [syncStates]="syncStates" (syncStateUpdated)="refreshSyncState()">
          </app-mercury-sync-status-signpost>
        </div>
      </div>
      <div class="itech-doc-content">
        <app-edit-product-common-form [contextFormGroup]="contextFormGroup" [serverSideErrorsProvider]="this.model">
        </app-edit-product-common-form>
      </div>
    </div>
  </div>
  <div class="itech-form-actions">
    <div>
      <button class="btn btn-secondary" (click)="refreshFormData()" [clrLoading]="loading">
        <clr-icon shape="refresh"></clr-icon>&nbsp;Обновить
      </button>
      <app-reports-button *ngIf="!isRequest" [reportKind]="'product'" [objId1]="id" [dropdownPosition]="'top-left'"
                          [disabled]="!isAvailableReports()">
      </app-reports-button>
      <button class="btn btn-primary-outline" (click)="navigationService.navigateJournal(undefined, 10, id, undefined)">
        <clr-icon shape="view-list"></clr-icon>&nbsp;Журнал
      </button>
      <clr-dropdown *ngIf="contextFormGroup && !isRequest">
        <button class="btn btn-success-outline" (click)="isAllowedToDeleteCheck()" clrDropdownToggle>
          Действия <clr-icon shape="caret down"></clr-icon>
        </button>
        <clr-dropdown-menu *clrIfOpen [clrPosition]="'top-left'">
          <label class="dropdown-header">Выберите действие</label>
          <button type="button" clrDropdownItem (click)="sendToRegistration()"
                  [disabled]="contextFormGroup.controls['status'].value !== 0 || contextFormGroup.dirty">Отправить на регистрацию</button>
          <button type="button" clrDropdownItem (click)="sendToClearance()"
                  [disabled]="!isTransportOrMovingTransaction() || contextFormGroup.controls['status'].value !== 2">Погасить документ</button>
          <div class="dropdown-divider"></div>
          <ng-container *ngIf="equalsSome(contextFormGroup.controls['status'].value, 2, 3)">
            <button type="button" clrDropdownItem (click)="deriveProduct(4)"
                    [disabled]="isTransportOrMovingTransaction() ? +contextFormGroup.controls['status'].value !== 3
                     : +contextFormGroup.controls['status'].value !== 2">Оформить новый ВСД</button>
            <div class="dropdown-divider"></div>
          </ng-container>
          <button type="button" clrDropdownItem (click)="cloneProduct()">Создать копию</button>
          <div class="dropdown-divider"></div>
          <button type="button" clrDropdownItem (click)="deletedModalOpen = true"
                  [disabled]="contextFormGroup.controls['status'].value !== 2">Аннулировать документ</button>
          <button type="button" clrDropdownItem (click)="deleteDocument()"
                  [disabled]="!isAllowedToDelete">Удалить документ</button>
        </clr-dropdown-menu>
      </clr-dropdown>
    </div>
    <div>
      <ng-container *ngIf="!isRequest; else actionRequest">
        <button [clrLoading]="storing" class="btn btn-primary itech-form-save"
                *ngIf="contextFormGroup && contextFormGroup.controls['status'].value === -100"
                [disabled]="!contextFormGroup || storing" (click)="cloneProduct(true)">
          <clr-icon shape="check"></clr-icon>&nbsp;Создать проект
        </button>
        <button [clrLoading]="storing" class="btn btn-primary itech-form-save"
                [disabled]="!contextFormGroup || !contextFormGroup.dirty || storing" (click)="store()">
          <clr-icon shape="check"></clr-icon>&nbsp;Сохранить
        </button>
        <button class="btn btn-warning itech-form-cancel"
                [disabled]="!contextFormGroup || !contextFormGroup.dirty" (click)="cancelEdit()">
          <clr-icon shape="undo"></clr-icon>&nbsp;Отменить
        </button>
      </ng-container>
      <ng-template #actionRequest>
        <app-btn-sync-web [iconName]="'check'" [caption]="'Принять'" (action)="applyRequest()"
                          [clrLoading]="storing" [btnClasses]="'btn-primary itech-form-save'"
                          [disabled]="!contextFormGroup || storing || +contextFormGroup.controls['requestStatus'].value !== 1">
        </app-btn-sync-web>
        <app-btn-sync-web [iconName]="'undo'" [caption]="'Отклонить'" (action)="rejectProductRequestModalOpen = true"
                          [clrLoading]="storing" [btnClasses]="'btn-warning itech-form-cancel'"
                          [disabled]="storing || !contextFormGroup || +contextFormGroup.controls['requestStatus'].value !== 1">
        </app-btn-sync-web>
      </ng-template>
    </div>
  </div>
</div>

<clr-modal [(clrModalOpen)]="deletedModalOpen" [clrModalSize]="'lg'">

  <h3 class="modal-title">Аннулирование сертификата</h3>

  <div class="modal-body" style="min-height: 100px">
    <div class="form compact" [formGroup]="deletedFormGroup">
      <div class="form-group itech-double-height v-resize">
        <label for="locationProsperity" class="required">Причина</label>
        <label for="locationProsperity" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
               [class.invalid]="isInvalid(deletedFormGroup, 'deletedReason')">
            <textarea type="text" id="locationProsperity" placeholder="Укажите причину, по которой аннулируется сертификат"
                      class="itech-control-xlarge itech-text-wall" formControlName="deletedReason">
            </textarea>
          <app-validation-tooltip [input]="deletedFormGroup.controls['deletedReason']"></app-validation-tooltip>
        </label>
      </div>
    </div>

    <app-doc-validation-errors [validationErrors]="deletedFormGroup.docLevelServerSideValidationErrors">
    </app-doc-validation-errors>

    <div style="margin-top: 20px">
      <div class="checkbox" style="font-size: 0.5rem">
        <input type="checkbox" id="checkbox-confirm-action" [(ngModel)]="deletedSelectedCheckboxConfirmAction">
        <label for="checkbox-confirm-action" style="padding-left: 20px">
          Я внимательно посмотрел аннулируемый документ и понимаю, что данное действие является юридически значимым и необратимым
        </label>
      </div>
    </div>
  </div>

  <div class="modal-footer itech-form-actions">
    <div></div>
    <div>
      <button type="button" class="btn btn-primary" (click)="sendToDeletion()"
              [disabled]="!deletedSelectedCheckboxConfirmAction || !deletedFormGroup.get('deletedReason').value">Отправить на аннулирование</button>
      <button type="button" class="btn btn-warning" (click)="closeDeletedModal()">Закрыть</button>
    </div>
  </div>

</clr-modal>

<clr-modal [(clrModalOpen)]="rejectProductRequestModalOpen" [clrModalSize]="'lg'">

  <h3 class="modal-title">Принятие решения об отклонении заявки</h3>

  <div class="modal-body" style="min-height: 100px">
    <div class="form compact" [formGroup]="rejectProductRequestFormGroup">
      <div class="form-group itech-double-height v-resize">
        <label for="rejectReason" class="required">Причина</label>
        <label for="rejectReason" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
               [class.invalid]="isInvalid(rejectProductRequestFormGroup, 'rejectReason')">
            <textarea type="text" id="rejectReason" placeholder="Укажите причину отклонения"
                      class="itech-control-xlarge itech-text-wall" formControlName="rejectReason">
            </textarea>
          <app-validation-tooltip [input]="rejectProductRequestFormGroup.controls['rejectReason']"></app-validation-tooltip>
        </label>
      </div>
    </div>

    <app-doc-validation-errors [validationErrors]="rejectProductRequestFormGroup.docLevelServerSideValidationErrors">
    </app-doc-validation-errors>
  </div>

  <div class="modal-footer itech-form-actions">
    <div></div>
    <div>
      <button type="button" class="btn btn-primary" (click)="processDecisionMakingRequest(3)"
              [disabled]="!rejectProductRequestFormGroup.get('rejectReason').value">Отклонить</button>
      <button type="button" class="btn btn-warning" (click)="closeRejectProductRequestModal()">Закрыть</button>
    </div>
  </div>

</clr-modal>
