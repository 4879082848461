import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { QueryService } from '../../../logic/services/query.service';
import { BehaviorSubject } from 'rxjs/index';
import { map } from 'rxjs/internal/operators';
import { Injectable } from '@angular/core';
import { JobRunnerUiService } from '../../../ui/infrastructure/job-runner-ui.service';
import { GroupOperationsService } from '../../../logic/services/group-operations.service';
import { DateHelper } from '../../../helpers/date-helper';
import { StringHelper } from '../../../helpers/string-helper';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';

@Injectable({
  providedIn: 'root',
})
export class QueryRunnerUiService {

  exporting = false;
  quering = false;

  constructor(private queryService: QueryService,
              private jobRunnerUiService: JobRunnerUiService,
              private navigationService: AppNavigationService,
              private lookupService: LookupSourceService) {
  }

  private static getPeriodValue(params: any, colName: string) {
    const periodStart = Object.getOwnPropertyDescriptor(params.data, 'periodStart' + colName.substr(4));
    const periodEnd = Object.getOwnPropertyDescriptor(params.data, 'periodEnd' + colName.substr(4));
    if (!periodStart || !periodEnd) {
      return null;
    }
    return DateHelper.durationYearMonthDay(
      new Date(periodStart.value),
      new Date(periodEnd.value));
  }

  public runQuery(model: any, queryViewId: number) {
    this.runQueryOperation(model, queryViewId,
      (m1, queryCustomParams, queryViewParams) => this.runQueryInternal(m1, queryCustomParams, queryViewId, queryViewParams));
  }

  public exportQueryCsv(model: any, queryViewId: number) {
    if (!this.exporting) {
      this.runQueryOperation(model, queryViewId,
        (m1, queryCustomParams) => this.exportQueryCsvInternal(m1, queryCustomParams, queryViewId));
    }
  }

  public runQueryOperation(model: any, queryViewId: number, qOperation: (model, queryCustomParams, queryViewParams) => void) {
    const customParams = this.queryService.queryContainsUserParams(model.query);

    const requireParamsObs = customParams
      ? this.navigationService.queryUserForQueryParams(model.query)
        .pipe(map(() => this.queryService.mergeQueryUserParams(model.query)))
      : new BehaviorSubject<any[]>(undefined);

    requireParamsObs.subscribe((queryCustomParams: any[]) => {

      this.lookupService.getLookupObj('sys-query-view' + model.entityType.id.toString())
        .subscribe(queryViewLookup => {
          const queryView = queryViewLookup['Obj' + queryViewId];
          (
            queryView && queryView.runParams && queryView.runParams.length
            ? this.navigationService.queryUserForQueryViewParams(queryView.runParams)
            : new BehaviorSubject<any>(undefined)
          )
            .subscribe(queryViewParams =>
              qOperation(model, queryCustomParams, queryViewParams));
        });
    });
  }

  public navigateRow(model: any, row: any) {
    if (model.entityType.id === 1) {
      if (row.id) {
        this.navigationService.performStableEditing(row.id);
      }
    } else if (model.entityType.id === 2) {
      if (row.id) {
        this.navigationService.performAnimalEditing(row.id);
      }
    }
    if (model.entityType.id === 3) {
      if (row.id) {
        this.navigationService.performAnimalEventEditing(row.id);
      }
    }
    if (model.entityType.id === 4) {
      if (row.id) {
        this.navigationService.performQuarantineEditing(row.id);
      }
    }
    if (model.entityType.id === 5) {
      if (row.id) {
        this.navigationService.performAgentEditing(row.id);
      }
    }
    if (model.entityType.id === 7) {
      if (row.id) {
        this.navigationService.performProductEditing(row.id);
      }
    }
    if (model.entityType.id === 8) {
      if (row.id) {
        this.navigationService.performDrugEditing(row.id);
      }
    }
  }

  private runQueryInternal(model: any, queryCustomParams: any[], queryViewId: number, queryViewParams: any) {

    model.query.results = [];
    model.query.dataColumns = [];
    model.query.aggSumRow = [];
    this.quering = true;

    setTimeout(() => {
      this.jobRunnerUiService.runOperation(GroupOperationsService.QueryRunner,
        {
          type: 'queryRunner',
          query: {
            queryId: model.query.data.id,
            queryViewId: queryViewId,
            paramValues: queryCustomParams,
            queryViewParams: queryViewParams,
          }
        }, undefined, undefined, true)
        .subscribe({
          next: (jsonStr) => {

            const data = JSON.parse(jsonStr);

            model.query.results = data;
            model.query.dataColumns = [];
            model.query.aggSumRow = [];

            const aggRow = {};

            if (Array.isArray(model.query.results) && model.query.results.length > 0) {
              const firstRow = model.query.results[0];

              const viewColumns = [];
              for (const colName in firstRow) {

                if (firstRow.hasOwnProperty(colName) && colName.startsWith('_')) {
                  viewColumns.push(colName);
                }
              }

              viewColumns.sort();

              viewColumns.forEach(colName => {
                const colNameSplit = colName.split('__');
                const aggSum = colNameSplit.includes('aggsum');
                const fixedParamIx = colNameSplit.findIndex(el => el.startsWith('fixed') && el.length > 5);
                const fixedParam = fixedParamIx >= 0 ? colNameSplit[fixedParamIx].substr(5) : null;
                if (aggSum) {
                  let sum = 0;
                  (model.query.results || []).forEach(row => sum += (+row[colName] || 0));
                  aggRow[colName] = fixedParam ? sum.toFixed(fixedParam) : sum;
                }

                model.query.dataColumns.push({
                  headerName: aggSum ? colNameSplit[0].substring(5) : colName.substr(5),
                  field: firstRow.hasOwnProperty('sorted' + colName.substr(4)) ? 'sorted' + colName.substr(4) : colName,
                  valueFormatter: params => {
                    if (!params || !params.data || !params.data.hasOwnProperty(colName)) {
                      return null;
                    }
                    if (!firstRow.hasOwnProperty('periodStart' + colName.substr(4)) &&
                        !firstRow.hasOwnProperty('periodEnd' + colName.substr(4))) {
                      return fixedParam ? (+params.value || 0).toFixed(fixedParam) : params.value;
                    }
                  },
                  valueGetter: params => {
                    if (!params || !params.data || !params.data.hasOwnProperty(colName)) {
                      return null;
                    }
                    if (firstRow.hasOwnProperty('periodStart' + colName.substr(4)) &&
                        firstRow.hasOwnProperty('periodEnd' + colName.substr(4))) {
                      return QueryRunnerUiService.getPeriodValue(params, colName);
                    }
                    return Object.getOwnPropertyDescriptor(params.data, colName).value;
                  },
                  filterParams: {
                    valueGetter: params => {
                      if (!params) {
                        return null;
                      }
                      if (firstRow.hasOwnProperty('periodStart' + colName.substr(4)) &&
                          firstRow.hasOwnProperty('periodEnd' + colName.substr(4))) {
                        return QueryRunnerUiService.getPeriodValue(params, colName);
                      }
                      return Object.getOwnPropertyDescriptor(params.data, colName).value;
                    },
                    cellRenderer: params => {
                      if (!params) {
                        return null;
                      }
                      if (params.value) {
                        return '<div title="' + StringHelper.saveQuotes(params.value) + '">' + params.value + '</div>';
                      } else {
                        return '(Пусто)';
                      }
                    }
                  },
                  tooltipField: colName
                });
              });
            }
            if (Object.keys(aggRow).length) {
              model.query.aggSumRow.push(aggRow);
            }

            this.quering = false;
          },
          error: () => {
            model.query.results = [];
            this.quering = false;
          }
        });
    }, 1);
  }

  private exportQueryCsvInternal(model: any, queryCustomParams: any[], queryViewId: number) {
    this.exporting = true;

    setTimeout(() => {
      this.jobRunnerUiService.runOperation(GroupOperationsService.QueryCsvExporter,
        {
          type: 'queryCsvExporter',
          query: {
            queryId: model.query.data.id,
            queryViewId: queryViewId,
            paramValues: queryCustomParams
          }
        })
        .subscribe({
          next: () => {
            this.exporting = false;
          }, error: () => {
            this.exporting = false;
          }
        });
    }, 1);
  }
}
