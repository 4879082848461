import { OnInit } from '@angular/core';
import { ModalResult } from '../../../logic/services/app-navigation.service.models';
import { of, Subject } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { ProductDataService } from '../../../logic/services/product-data.service';
import { FormHelper } from '../../../ui/controls/form-helper';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { EditProductCommonFormComponent } from './edit-product-common-form.component';
import { StringHelper } from '../../../helpers/string-helper';
var SendToClearanceModalComponent = /** @class */ (function () {
    function SendToClearanceModalComponent(fb, appNavigationService, lookupService, productDataService) {
        this.fb = fb;
        this.appNavigationService = appNavigationService;
        this.lookupService = lookupService;
        this.productDataService = productDataService;
        this.isInvalid = FormHelper.isInvalid;
        this.equalsSome = FormHelper.equalsSome;
        this.processMoneyKeypress = FormHelper.processMoneyKeypressDot;
    }
    Object.defineProperty(SendToClearanceModalComponent.prototype, "fullVolume", {
        get: function () {
            return this.model && this.model.get('fullVolume').value ? this.model.get('fullVolume').value : undefined;
        },
        enumerable: true,
        configurable: true
    });
    SendToClearanceModalComponent.getGroupDef = function (fb, data) {
        if (data === void 0) { data = {}; }
        return fb.group({
            productId: data.productFormGroup.value.id,
            acceptanceTypeId: [data.acceptanceTypeId || 1, Validators.required],
            volume: [data.volume, Validators.compose([
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent && FormHelper.equalsSome(+ctrl.parent.get('acceptanceTypeId').value, 1, 2); }),
                    FormHelper.conditionalValidate(FormHelper.validateMoneyThreeDecimal(), function (ctrl) { return ctrl.parent && FormHelper.equalsSome(+ctrl.parent.get('acceptanceTypeId').value, 1, 2); })
                ])],
            returnVolume: [data.returnVolume,
                Validators.compose([
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent && +ctrl.parent.get('acceptanceTypeId').value === 2; }),
                    FormHelper.conditionalValidate(FormHelper.validateMoneyThreeDecimal(), function (ctrl) { return ctrl.parent && FormHelper.equalsSome(+ctrl.parent.get('acceptanceTypeId').value, 1, 2); })
                ])],
            fullVolume: data.fullVolume,
            unitId: data.unitId,
            needRefundProduct: data.needRefundProduct || false,
            clearance5percentCaption: 'от ' + ((data.fullVolume || 0) - (data.fullVolume || 0) * 0.05) +
                ' до ' + ((data.fullVolume || 0) + (data.fullVolume || 0) * 0.05),
            issueDiscrepancyReport: data.issueDiscrepancyReport || false,
            discrepancyReportDate: [data.discrepancyReportDate || StringHelper.getISODate(new Date),
                Validators.compose([FormHelper.validateDateTimePicker(),
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent && !ctrl.parent.get('issueDiscrepancyReport').value; })])],
            discrepancyReportSerial: [data.discrepancyReportSerial],
            discrepancyReportNumber: [data.discrepancyReportNumber],
            discrepancyReportReason: [data.discrepancyReportReason],
            discrepancyReportCaption: [data.discrepancyReportCaption],
            productFormGroup: data.productFormGroup.value,
            discrepancyReportTtnTypeId: [data.ttnTypeId, FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent && !ctrl.parent.get('issueDiscrepancyReport').value; })],
            discrepancyReportTtnSerial: [data.ttnSerial],
            discrepancyReportTtnNo: [data.ttnNo],
            discrepancyReportTtnDate: [data.ttnDate, Validators.compose([FormHelper.validateDateTimePicker()])],
            discrepancyReportVesselNo: [data.vesselNo],
            discrepancyReportTrailerNo: [data.trailerNo],
            discrepancyReportContainerNo: [data.containerNo],
            discrepancyReportExpireDateKind: data.expireDateKind || 0,
            discrepancyReportExpireDate: [data.expireDate, Validators.compose([FormHelper.validateDateTimePicker()])],
            discrepancyReportExpireDateYear: [data.expireDateYear,
                Validators.compose([Validators.min(1917), Validators.max(2100)])],
            discrepancyReportExpireDateMonth: [data.expireDateMonth,
                Validators.compose([Validators.min(1), Validators.max(12)])],
            discrepancyReportExpireDateDay: [data.expireDateDay,
                Validators.compose([Validators.min(1), Validators.max(31)])],
            discrepancyReportExpireDateHour: [data.expireDateHour,
                Validators.compose([Validators.min(0), Validators.max(23)])],
            discrepancyReportExpireDate2: [data.expireDate2,
                Validators.compose([FormHelper.validateDateTimePicker()])],
            discrepancyReportExpireDate2Year: [data.expireDate2Year,
                Validators.compose([Validators.min(1917), Validators.max(2100)])],
            discrepancyReportExpireDate2Month: [data.expireDate2Month,
                Validators.compose([Validators.min(1), Validators.max(12)])],
            discrepancyReportExpireDate2Day: [data.expireDate2Day,
                Validators.compose([Validators.min(1), Validators.max(31)])],
            discrepancyReportExpireDate2Hour: [data.expireDate2Hour,
                Validators.compose([Validators.min(0), Validators.max(23)])],
            discrepancyReportStableProductSubtypeId: [data.stableProductSubtypeId,
                FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent && ctrl.parent.parent && ctrl.parent.parent.parent &&
                    ctrl.parent.parent.parent.get('issueDiscrepancyReport').value; })],
            originalStableProductSubtypeId: [data.stableProductSubtypeId],
            acceptPackages: EditProductCommonFormComponent.buildPackagesArray(fb, (data.packages || []).filter(function (el) { return el.packageLevelId; }), true),
        });
    };
    SendToClearanceModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.model = SendToClearanceModalComponent.getGroupDef(this.fb, this.appNavigationService.customModalParams);
        this.lookupService.getLookupCaption(this.model.get('unitId').value, 'drug-unit-type/product').subscribe(function (caption) { return _this.unitCaption = caption; });
    };
    SendToClearanceModalComponent.prototype.initModalBody = function (data) {
    };
    SendToClearanceModalComponent.prototype.sendToClearance = function () {
        var _this = this;
        FormHelper.markAsSubmitted(this.model);
        if (!this.model.valid) {
            return of(new ModalResult(false, undefined));
        }
        var retVal = new Subject();
        this.productDataService.sendToClearance(this.model.value)
            .subscribe(function () {
            retVal.next(new ModalResult(true, null));
            _this.appNavigationService.customModalOpened = false;
            _this.appNavigationService.customModalAcceptPressed = false;
        }, function (err) {
            _this.appNavigationService.customModalAcceptPressed = false;
            FormHelper.setSingleFormGroupServerSideValidationErrors(err, { serverSideValidationErrors: [] }, _this.model);
        });
        return retVal;
    };
    SendToClearanceModalComponent.prototype.onModalAccept$ = function () {
        return this.sendToClearance();
    };
    SendToClearanceModalComponent.prototype.volumeChange = function (controlName) {
        if (this.model && this.model.get(controlName).value) {
            this.model.get(controlName).setValue(this.model.get(controlName).value.replace(',', '.'));
            if (this.model.get(controlName).value < (this.fullVolume * 0.95)) {
                this.model.get('needRefundProduct').setValue(true);
            }
            else if (this.model.get(controlName).value > this.fullVolume) {
                this.model.get('needRefundProduct').setValue(false);
            }
        }
    };
    SendToClearanceModalComponent.prototype.isRequiredDateField = function () {
        return false;
    };
    SendToClearanceModalComponent.prototype.getLookupNameStableProductSubtype = function () {
        var stableId = (this.model.get('productFormGroup').value.producers || []).length
            ? this.model.get('productFormGroup').value.producers[0].stableId
            : +this.model.get('productFormGroup').value.transactionType === 4
                ? this.model.get('productFormGroup').value.originStableId
                : undefined;
        var sps = this.model.get('originalStableProductSubtypeId').value;
        return stableId
            ? "stable-product-subtype/stable/include-unknown/" + stableId + "/" + (sps || 'null')
            : '';
    };
    SendToClearanceModalComponent.prototype.acceptanceTypeChange = function () {
        this.model.get('needRefundProduct').setValue(false);
        if (this.equalsSome(this.model.get('acceptanceTypeId').value, 3)) {
            this.model.get('issueDiscrepancyReport').setValue(false);
        }
    };
    return SendToClearanceModalComponent;
}());
export { SendToClearanceModalComponent };
