<div id="interceptNoAccess">
  <div class="compact form itech-highlighted-sections" *ngIf="contextFormGroup" id="quarantineCommonContent"
       [class.itech-quarantine-form]="contextFormGroup.get('id').value" [formGroup]="contextFormGroup">

    <app-doc-validation-errors [validationErrors]="contextFormGroup.docLevelServerSideValidationErrors">
    </app-doc-validation-errors>

    <section class="form-block itech-block-medium" [formGroup]="contextFormGroup">
      <div class="form-group">
        <label for="diseaseTypeId" class="required">Заболевание</label>
        <div style="display:grid">
          <app-text-lookup class="itech-control-large" [validationTooltipRight]="true"
                            [contextControlId]="'diseaseTypeId'" [sorted]="true"
                            [contextControlName]="'diseaseTypeId'" [lookupName]="'disease-type/may-be-introduced-quarantine'"
                            [contextFormGroup]="contextFormGroup" [disabled]="!isAccessEdit"></app-text-lookup>
          <div *ngIf="contextFormGroup.get('diseaseTypeId').value && liquidationRegulationsFileUri">
            Правила ликвидации болезни:
            <a class="itech-link align-bottom" style="padding-right: 5px;color:#472cdc"
               (click)="previewFile(liquidationRegulationsFileUri)">
              {{getPrettyTitle(liquidationRegulationsFileUri)}}</a>
          </div>
        </div>
      </div>

      <div class="form-group" style="display:block">
        <label for="sourcePathogenAnimalTypeId" class="required">Источник возбудителя</label>
        <div *ngIf="!sourcePathogenAnimalOtherCaptionCheckbox" style="display:flex">
          <app-text-lookup class="itech-control-large" [validationTooltipRight]="true"
                            [contextControlId]="'sourcePathogenAnimalTypeId'" [sorted]="true"
                            [contextControlName]="'sourcePathogenAnimalTypeId'" [lookupName]="'animal-type'"
                            [contextFormGroup]="contextFormGroup" (change)="resetAnimalSubtypeId()"
                            [disabled]="!isAccessEdit"></app-text-lookup>
          &nbsp;
          <app-text-lookup *ngIf="contextFormGroup.get('sourcePathogenAnimalTypeId').value"
                            class="itech-control-large" [validationTooltipRight]="true"
                            [contextControlId]="'sourcePathogenAnimalSubtypeId'" [sorted]="true"
                            [contextControlName]="'sourcePathogenAnimalSubtypeId'" [lookupName]="'animal-subtype' + contextFormGroup.get('sourcePathogenAnimalTypeId').value"
                            [contextFormGroup]="contextFormGroup"
                            [disabled]="!isAccessEdit"></app-text-lookup>
        </div>
        <div *ngIf="sourcePathogenAnimalOtherCaptionCheckbox">
          <label for="sourcePathogenAnimalOtherCaption" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md">
            <input type="text" id="sourcePathogenAnimalOtherCaption" class="itech-control-xlarge"
                   formControlName="sourcePathogenAnimalOtherCaption" placeholder="Иное наименование источника"
                   [attr.disabled]="!isAccessEdit ? true : undefined">
            <app-validation-tooltip [input]="contextFormGroup.controls['sourcePathogenAnimalOtherCaption']"></app-validation-tooltip>
          </label>
        </div>
        <div class="form-group itech-group-whole-line" *ngIf="isAccessEdit">
          <div class="checkbox-inline">
            <input type="checkbox" id="sourcePathogenAnimalOtherCaptionCheckbox" [(ngModel)]="sourcePathogenAnimalOtherCaptionCheckbox"
                   [ngModelOptions]="{standalone: true}"
                   (click)="sourcePathogenAnimalOtherCaptionCheckboxChange()">
            <label for="sourcePathogenAnimalOtherCaptionCheckbox">Отсутствует в справочнике</label>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label>Восприимчивые животные</label>
        <div *ngIf="susceptibleUniqueAnimalSubtypeIds.length" style="padding-top:0.3rem">Выбрано подвидов:&nbsp;{{susceptibleUniqueAnimalSubtypeIds.length}}</div>
        <div *ngIf="!susceptibleUniqueAnimalSubtypeIds.length" style="padding-top:0.3rem">Все подвиды животных</div>
        &nbsp;&nbsp;
        <button type="button" class="btn btn-link" style="margin:0"
                (click)="susceptibleUniqueAnimalSubtypeIdsModalOpen = true">
          Открыть
        </button>
      </div>

      <div class="form-group">
        <label for="dateFrom" class="required">Дата создания</label>
        <label for="dateFrom" aria-haspopup="true" role="tooltip"
               class="tooltip tooltip-validation tooltip-md disabled"
               [class.invalid]="isInvalid(contextFormGroup, 'dateFrom')">
          <input type="date" id="dateFrom" class="itech-control-normal"
                 formControlName="dateFrom" [attr.disabled]="!isAccessEdit ? true : undefined">
          <app-validation-tooltip [input]="contextFormGroup.controls['dateFrom']"></app-validation-tooltip>
        </label>
      </div>

      <div class="form-group">
        <label for="responsibleInstitutionEmployeeId" class="required">Ответственный</label>
        <app-text-lookup class="itech-control-m-large" [validationTooltipRight]="true"
                         [contextControlId]="'responsibleInstitutionEmployeeId'" [sorted]="true"
                         [contextControlName]="'responsibleInstitutionEmployeeId'" [required]="true"
                         [visibleValidationTextError]="false"
                         [lookupName]="'institution-employee' + this.contextFormGroup.get('responsibleInstitutionId').value"
                         [contextFormGroup]="contextFormGroup" [disabled]="!isAccessEdit"></app-text-lookup>
      </div>

      <div class="form-group" style="margin-bottom:15px">
        <label for="personApprovedEventPlanPost">Лицо, утвержд. план мероприятий (должность/ФИО)</label>
        <label for="personApprovedEventPlanPost" aria-haspopup="true" role="tooltip"
               class="tooltip tooltip-validation tooltip-md disabled"
               [class.invalid]="isInvalid(contextFormGroup, 'personApprovedEventPlanPost')">
          <input type="text" id="personApprovedEventPlanPost" class="itech-control-large"
                 placeholder="должность" formControlName="personApprovedEventPlanPost">
          <app-validation-tooltip [input]="contextFormGroup.controls['personApprovedEventPlanPost']"></app-validation-tooltip>
        </label>
        <label for="personApprovedEventPlanFio" aria-haspopup="true" role="tooltip"
               class="tooltip tooltip-validation tooltip-md disabled"
               [class.invalid]="isInvalid(contextFormGroup, 'personApprovedEventPlanFio')">
          <input type="text" id="personApprovedEventPlanFio" class="itech-control-large"
                 placeholder="ФИО" formControlName="personApprovedEventPlanFio">
          <app-validation-tooltip [input]="contextFormGroup.controls['personApprovedEventPlanFio']"></app-validation-tooltip>
        </label>
      </div>

      <div class="form-group" *ngIf="isAccessEdit" style="margin-top:20px">
        <label></label>
        <div class="checkbox-inline">
          <input type="checkbox" id="noSuspicionStage" formControlName="noSuspicionStage">
          <label for="noSuspicionStage">У карантина отсутствует этап 'Подозрение'</label>
        </div>
      </div>
    </section>

    <app-edit-quarantine-common-territory [isAccessEdit]="isAccessEdit" [captionTerritory]="'Очаг'"
                                          [territories]="territories" [serverSideErrorsProvider]="serverSideErrorsProvider"
                                          [quarantineTerritoryTypeId]="QUARANTINE_TERRITORY_TYPE.SOURCE">
    </app-edit-quarantine-common-territory>

    <app-edit-quarantine-common-territory [isAccessEdit]="isAccessEdit" [captionTerritory]="'Неблагополучный пункт'"
                                          [territories]="territories" [serverSideErrorsProvider]="serverSideErrorsProvider"
                                          [quarantineTerritoryTypeId]="QUARANTINE_TERRITORY_TYPE.BAD_LOCALITY">
    </app-edit-quarantine-common-territory>

    <app-edit-quarantine-common-territory [isAccessEdit]="isAccessEdit" [captionTerritory]="'Угрожаемая зона'"
                                          [territories]="territories" [serverSideErrorsProvider]="serverSideErrorsProvider"
                                          [quarantineTerritoryTypeId]="QUARANTINE_TERRITORY_TYPE.DANGER_AREA">
    </app-edit-quarantine-common-territory>

    <app-edit-quarantine-common-territory [isAccessEdit]="isAccessEdit" [captionTerritory]="'Первая угрожаемая зона'"
                                          [territories]="territories" [serverSideErrorsProvider]="serverSideErrorsProvider"
                                          [quarantineTerritoryTypeId]="QUARANTINE_TERRITORY_TYPE.FIRST_DANGER_AREA">
    </app-edit-quarantine-common-territory>

    <app-edit-quarantine-common-territory [isAccessEdit]="isAccessEdit" [captionTerritory]="'Вторая угрожаемая зона'"
                                          [territories]="territories" [serverSideErrorsProvider]="serverSideErrorsProvider"
                                          [quarantineTerritoryTypeId]="QUARANTINE_TERRITORY_TYPE.SECOND_DANGER_AREA" [isRadius]="true">
    </app-edit-quarantine-common-territory>
  </div>
</div>

<clr-modal [(clrModalOpen)]="susceptibleUniqueAnimalSubtypeIdsModalOpen" [clrModalSize]="'lg'">

  <h3 class="modal-title">Выбор восприимчивых животных</h3>

  <div class="modal-body" style="min-height: 100px">
    <app-checkbox-select-with-parent [lookupNameParent]="'animal-type'" [lookupName]="'animal-subtype'"
                                     [contextFormGroup]="contextFormGroup" [disabled]="!isAccessEdit"
                                     [contextControlName]="'susceptibleUniqueAnimalSubtypeIds'">
    </app-checkbox-select-with-parent>
  </div>

  <div class="modal-footer itech-form-actions">
    <div></div>
    <div>
      <button type="button" class="btn btn-warning" (click)="susceptibleUniqueAnimalSubtypeIdsModalOpen = false">Закрыть</button>
    </div>
  </div>

</clr-modal>
