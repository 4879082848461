import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {LookupSourceService} from '../../../logic/services/lookup-source.service';
import {AppNavigationService} from '../../../logic/services/app-navigation.service';
import {StringHelper} from '../../../helpers/string-helper';
import {FormHelper} from '../../../ui/controls/form-helper';
import {PstReportStructureService} from '../../services/pst-report-structure.service';

@Component({
  selector: 'app-branch-cell-date-edit',
  templateUrl: './pst-branch-cell-date-edit.component.html',
})
export class PstBranchCellDateEditComponent implements OnChanges {
  @Input() branch: any;
  @Input() model: any;
  @Input() indicatorLevel: any;
  @Input() originalCellValue: any;
  @Input() editing: boolean;
  @Input() focused: boolean;

  @Output() changed = new EventEmitter();

  @ViewChild('input') inputControl: ElementRef;

  processMoneyKeypress = FormHelper.processMoneyKeypressDot;

  valueStr = '';
  valueLoaded = false;
  valueUpdated = false;

  get proposedCellValue() {
    return this.model.__cl_editingProposedValue;
  }

  get cellValueDisplay() {
    return StringHelper.getRuDate(this.originalCellValue);
  }

  get cellValueStr() {
    if (!this.valueLoaded) {
      let value = this.originalCellValue;

      if (this.proposedCellValue || this.proposedCellValue === 0) {
        this.valueUpdated = true;
        value = this.proposedCellValue;
      }

      if (!value) {
        this.valueStr = '';
      } else {
        this.valueStr = StringHelper.getRuDate(value);
      }

      this.valueLoaded = true;
    }

    return this.valueStr;
  }

  set cellValueStr(strValue: any) {
    this.valueUpdated = true;
    this.valueStr = strValue;
  }

  constructor(
    private lookupSourceService: LookupSourceService,
    private navigationService: AppNavigationService,
    private changeDetectorRef: ChangeDetectorRef,
    private pstReportStructureService: PstReportStructureService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('editing')) {
      if (this.editing) {
        setTimeout(() => {
          if (this.inputControl) {
            this.inputControl.nativeElement.focus();
          }
        }, 100);
      }

      if (!this.editing && this.valueUpdated) {
        setTimeout(() => {
          this.updateCellValueInModel();
          this.valueLoaded = false;
          this.valueUpdated = false;
        }, 100);
      }
    }
  }

  inputKeyDown($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      // заканчиваем редактирование с записью измененного значения
      this.model.__cl_editingBranch = undefined;
      this.inputControl.nativeElement.closest('table').focus();
    }
    if ($event.key === 'Escape') {
      // заканчиваем редактирование без записи измененного значения
      this.valueLoaded = false;
      this.valueUpdated = false;
      this.model.__cl_editingBranch = undefined;
      this.inputControl.nativeElement.closest('table').focus();
    }
  }

  updateCellValueInModel() {
    let strValue = this.valueStr;

    strValue = (strValue || '').replace(/[^\d.,]/g, '')
      .replace(/[,]/g, '.');

    let parsedVal;
    if (strValue) {
      parsedVal = StringHelper.getISODate(StringHelper.parseRussianDate(strValue));
    }

    if (this.changed) {
      this.changed.emit(parsedVal);
    }
  }
}
