import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/internal/operators';

@Injectable()
export class QueryService {

  constructor(private http: HttpClient) {
  }

  public runQuery(params: any): Observable<any[]> {
    const qstring = environment.api + '/query/run';
    return this.http.post(qstring, params)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  storeQuery(query: any) {
    const qstring = environment.api + '/query/store';

    return this.http.post(qstring, query)
      .pipe(map((response: any) => {
        return response.data;
      }));
  }

  loadQueries(entityTypeId: number) {
    const qstring = environment.api + '/query/by-entity-type/' + entityTypeId;

    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  byId(id: number): any {
    const qstring = environment.api + '/query/' + id;

    return this.http.get(qstring);
  }

  deleteQuery(queryId: number) {
    const qstring = environment.api + '/query/delete/' + queryId;

    return this.http.post(qstring, {})
      .pipe(map((response: any) => {
        return response.data;
      }));
  }

  updateConditionsEditStructure(conditions: any[], entityTypeId: number) {

    const ix1 = conditions.findIndex(el => el.data.conditionType === 'add' && el.isNew);
    if (ix1 >= 0) {
      conditions.splice(ix1, 1);
    }

    const ix2 = conditions.findIndex(el => el.data.conditionType === 'remove' && el.isNew);
    if (ix2 >= 0) {
      conditions.splice(ix2, 1);
    }


    conditions.push({data: {conditionType: 'add', type: this.getConditionTypeClassName(entityTypeId)}, isNew: true});
    conditions.push({data: {conditionType: 'remove', type: this.getConditionTypeClassName(entityTypeId)}, isNew: true});
  }

  getConditionTypeClassName(entityTypeId: number) {
    let type = '';
    switch (entityTypeId) {
      case 1:
        type = 'stable';
        break;
      case 2:
        type = 'animal';
        break;
      case 3:
        type = 'event';
        break;
      case 4:
        type = 'quarantine';
        break;
      case 5:
        type = 'agent';
        break;
      case 6:
        type = 'institution-ticket';
        break;
      case 7:
        type = 'product';
        break;
      case 8:
        type = 'drug';
        break;
    }

    return type;
  }

  buildConditionsForEdit(conditions: any[], entityTypeId: number) {

    if (!conditions) {
      conditions = [];
    }

    const newConditions = conditions.map(el => {
      return {data: el, isNew: false, relations: this.buildRelationsForEdit(el.relations)};
    });

    this.updateConditionsEditStructure(newConditions, entityTypeId);

    return newConditions;
  }

  buildRelationsForEdit(conditions: any[]) {

    if (!conditions) {
      conditions = [];
    }

    const newConditions = conditions.map(el => {
      return {data: el, isNew: false, relations: this.buildRelationsForEdit(el.relations)};
    });

    return newConditions.length > 0 ? newConditions : undefined;
  }

  buildConditionsForSave(conditions: any[]) {

    if (!conditions) {
      conditions = [];
    }

    const newConditions = [];

    conditions.forEach(el => {
      if (!el.isNew) {
        el.data.relations = this.buildRelationsForSave(el.relations);
        newConditions.push(el.data);
      }
    });

    return newConditions;
  }

  buildRelationsForSave(conditions: any[]) {

    if (!conditions) {
      conditions = [];
    }

    const newConditions = [];

    conditions.forEach(el => {
      if (!el.isNew) {
        el.data.relations = this.buildRelationsForSave(el.relations);
        newConditions.push(el.data);
      }
    });

    return newConditions;
  }

  buildQueryForSave(query: any) {
    const newQ: any = {};
    Object.assign(newQ, query.data);
    newQ.conditions = this.buildConditionsForSave(query.conditions);

    if (!newQ.id && newQ.conditions.length === 0) {
      newQ.conditions.push({conditionType: 'add', type: this.getConditionTypeClassName(newQ.entityType)});
    }

    return newQ;
  }

  queryContainsUserParams(query: any): boolean {

    if (!query || !query.conditions) {
      return false;
    }

    for (const condition of query.conditions) {
      if (this.relationContainsUserParams(condition)) {
        return true;
      }
    }

    return false;
  }

  relationContainsUserParams(relation: any): boolean {

    if (!relation) {
      return false;
    }

    if (this.conditionContainsUserParams(relation)) {
      return true;
    }

    if (!relation.relations) {
      return false;
    }

    for (const condition of relation.relations) {
      if (this.relationContainsUserParams(condition)) {
        return true;
      }
    }

    return false;
  }

  conditionContainsUserParams(condition: any): boolean {

    if (!condition) {
      return false;
    }

    for (const key in condition.data) {
      if (condition.data.hasOwnProperty(key) && key.endsWith('_filter') && (+condition.data[key] >= 100) && (+condition.data[key]) < 200) {
        return true;
      }
    }

    return false;
  }

  mergeQueryUserParams(query: any): any[] {

    let conditions = query.conditions;

    if (!conditions) {
      conditions = [];
    }

    const newConditions = [];

    conditions.forEach(el => {
      if (!el.isNew) {
        el.data.relations = this.mergeRelationsUserParams(query, el.relations);
        newConditions.push(this.mergeCondition(query, el.data));
      }
    });

    return newConditions;
  }

  mergeRelationsUserParams(query: any, conditions: any[]) {

    if (!conditions) {
      conditions = [];
    }

    const newConditions = [];

    conditions.forEach(el => {
      if (!el.isNew) {
        el.data.relations = this.mergeRelationsUserParams(query, el.relations);
        newConditions.push(this.mergeCondition(query, el.data));
      }
    });

    return newConditions;
  }

  mergeCondition(query: any, condition: any) {

    if (!query.userParamConditionsMap[condition.type]) {
      return condition;
    }

    const paramsToMerge = query.userParamConditionsMap[condition.type].contextFormGroup.value;

    const filteredFields = [];
    for (const key in condition) {
      if (condition.hasOwnProperty(key) && key.endsWith('_filter') && (+condition[key] >= 100) && (+condition[key]) < 200) {
        filteredFields.push(key.substring(0, key.length - 7));
      }
    }

    const retVal = {};

    Object.assign(retVal, condition);

    if (filteredFields.length > 0) {

      for (const key in paramsToMerge) {

        if (!paramsToMerge.hasOwnProperty(key)) {
          continue;
        }

        for (const filteredField of filteredFields) {
          if (key.startsWith(filteredField)) {
            retVal[key] = paramsToMerge[key];
            break;
          }
        }
      }
    }

    return retVal;
  }
}
