import { Component, HostBinding, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-query-details-agent-fields',
  template: `
      <app-query-lookup-combo-filter parameterName="created_institution" [lookupName]="'all-institution'" [contextFormGroup]="contextFormGroup"
                                     [queryUserMode]="queryUserMode">Учреждение-создатель
      </app-query-lookup-combo-filter>
      <app-query-lookup-combo-filter *ngIf="contextFormGroup.get('_par_created_institution').value" [contextFormGroup]="contextFormGroup"
                                     parameterName="created_institution_employee"
                                     [lookupName]="'institution-employee' + contextFormGroup.get('_par_created_institution').value"
                                     [queryUserMode]="queryUserMode">Пользователь-создатель
      </app-query-lookup-combo-filter>
      <app-query-date-time-filter parameterName="created_time" [contextFormGroup]="contextFormGroup"
                                  [queryUserMode]="queryUserMode">Время создания
      </app-query-date-time-filter>
      <app-query-lookup-combo-filter parameterName="deleted_institution" [lookupName]="'all-institution'"
                                     [contextFormGroup]="contextFormGroup" [queryUserMode]="queryUserMode">Удалившее учрежд.
      </app-query-lookup-combo-filter>
      <app-query-lookup-combo-filter *ngIf="contextFormGroup.get('_par_deleted_institution').value" [contextFormGroup]="contextFormGroup"
                                     parameterName="deleted_institution_employee"
                                     [lookupName]="'institution-employee' + contextFormGroup.get('_par_deleted_institution').value"
                                     [queryUserMode]="queryUserMode">Удаливший польз.
      </app-query-lookup-combo-filter>
      <app-query-date-time-filter parameterName="deleted_time" [contextFormGroup]="contextFormGroup"
                                  [queryUserMode]="queryUserMode">Время удаления
      </app-query-date-time-filter>
      <app-query-lookup-combo-filter parameterName="legal_form_id" [lookupName]="'legal-form'" [contextFormGroup]="contextFormGroup"
                                     [queryUserMode]="queryUserMode">Орг-правовая форма
      </app-query-lookup-combo-filter>
      <app-query-string-filter parameterName="title" [contextFormGroup]="contextFormGroup"
                               [queryUserMode]="queryUserMode">Наименов.
      </app-query-string-filter>
      <app-query-date-time-filter parameterName="date_tax_registered" [contextFormGroup]="contextFormGroup"
                                  [queryUserMode]="queryUserMode">Дата регистрации
      </app-query-date-time-filter>
      <app-query-date-time-filter parameterName="date_tax_included" [contextFormGroup]="contextFormGroup"
                                  [queryUserMode]="queryUserMode">Дата постановки на учёт
      </app-query-date-time-filter>
      <app-query-date-time-filter parameterName="date_tax_eliminated" [contextFormGroup]="contextFormGroup"
                                  [queryUserMode]="queryUserMode">Дата ликвидации
      </app-query-date-time-filter>
      <app-query-string-filter parameterName="address_legal" [contextFormGroup]="contextFormGroup"
                               [queryUserMode]="queryUserMode">Адрес (юр)
      </app-query-string-filter>
      <app-query-string-filter parameterName="address_fact" [contextFormGroup]="contextFormGroup"
                               [queryUserMode]="queryUserMode">Адрес (факт)
      </app-query-string-filter>
      <app-query-date-time-filter parameterName="date_registered_external_registry" [contextFormGroup]="contextFormGroup"
                                  [queryUserMode]="queryUserMode">Дата рег. в регистратуре
      </app-query-date-time-filter>`,
  styleUrls: ['./query.all.css']
})
export class QueryDetailsAgentFieldsComponent {
  @Input() contextFormGroup: FormGroup;
  @Input() queryUserMode;
  @HostBinding('attr.class') class = 'form-block itech-block-normal';
}
