import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '../../ui/infrastructure/alert.service';
import { GlobalWaitingOverlayService } from '../../ui/infrastructure/global-waiting-overlay.service';
import { AppNavigationService } from '../../logic/services/app-navigation.service';
import { PersonDataService } from '../../logic/services/person-data.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormHelper } from '../../ui/controls/form-helper';
import { SecurityService } from '../../logic/services/security.service';
import { searchParamsValidator } from '../../logic/validators/search-params.directive';
import { PersonSearchParams } from '../../model/person-search-params';
import { StringHelper } from '../../helpers/string-helper';

@Component({
  templateUrl: './person-groups-browse.component.html',
  styleUrls: ['./person-groups-browse.component.css']
})
export class PersonGroupsBrowseComponent implements OnInit {

  initialGroupId: number;
  personGroups: any[] = [];
  selectedPersonGroup: any;
  contextFormGroupBoundPersonGroup: any;
  contextFormGroup: FormGroup;
  serverSideValidationErrors: any[];
  storing = false;
  items = [];
  itemsLoading = false;

  newPeopleFormGroup: FormGroup;


  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: FormBuilder,
              private alertService: AlertService,
              private globalWaitingOverlayService: GlobalWaitingOverlayService,
              private navigationService: AppNavigationService,
              private securityService: SecurityService,
              private personDataService: PersonDataService) {
    this.newPeopleFormGroup = fb.group({
      newPersonText: ['', searchParamsValidator()]
    });
  }

  refreshPersonGroups() {
    this.globalWaitingOverlayService.StartWaiting();

    this.personDataService.getPersonGroups().subscribe(data => {
      this.personGroups = data;
      this.navigateInitialGroup();
      this.globalWaitingOverlayService.EndWaiting();
    }, () => {
      this.globalWaitingOverlayService.EndWaiting();
    });
  }

  isInvalid(fg: FormGroup, cname: string) {
    return FormHelper.isInvalid(fg, cname);
  }

  ngOnInit() {

    this.route.paramMap
      .subscribe(params => {

        this.personGroups = [];

        this.initialGroupId = +params.get('id') || undefined;

        this.navigateInitialGroup();

        this.refreshPersonGroups();
      });
  }

  deleteCurrentPersonGroup() {
    if (!this.selectedPersonGroup.id) {
      return;
    }

    this.alertService.confirmModal('Вы уверены, что хотите безвозвратно удалить группу клиентов?')
      .subscribe(() => {
        this.personDataService.deletePersonGroup(this.selectedPersonGroup.id).subscribe(() => {
          this.personGroups.splice(this.personGroups.indexOf(this.selectedPersonGroup), 1);
        });
      });
  }

  createContextFormGroup() {
    if (this.selectedPersonGroup) {
      if (!this.selectedPersonGroup.personGroupVisibility) {
        if (this.selectedPersonGroup.scopeInstitutionEmployeeId) {
          this.selectedPersonGroup.personGroupVisibility = 3;
        } else if (this.selectedPersonGroup.scopeInstitutionId) {
          this.selectedPersonGroup.personGroupVisibility = 2;
        } else {
          this.selectedPersonGroup.personGroupVisibility = 1;
        }
      }
      this.contextFormGroup = this.fb.group(this.selectedPersonGroup);

      this.items = [];

      if (!this.selectedPersonGroup.id) {
        this.contextFormGroup.markAsDirty();
      } else {
        this.itemsLoading = true;
        this.personDataService.getPersonGroupItems(this.selectedPersonGroup.id).subscribe(val => {
          this.items = val;
          this.itemsLoading = false;
        }, () => {
          this.itemsLoading = false;
        });
      }
    } else {
      this.contextFormGroup = undefined;
    }

    this.contextFormGroupBoundPersonGroup = this.selectedPersonGroup;
  }

  storePersonGroup() {
    if (this.storing) {
      return;
    }

    this.storing = true;

    FormHelper.markAsSubmitted(this.contextFormGroup);
    if (!this.contextFormGroup.valid) {
      return;
    }

    this.securityService.getUserInfo().subscribe(userInfo => {

      const dataToStore = this.contextFormGroup.value;
      if (+dataToStore.personGroupVisibility === 2) {
        dataToStore.scopeInstitutionId = userInfo.institutionId;
        dataToStore.scopeInstitutionEmployeeId = undefined;
      } else if (+dataToStore.personGroupVisibility === 3) {
        dataToStore.scopeInstitutionId = userInfo.institutionId;
        dataToStore.scopeInstitutionEmployeeId = userInfo.institutionEmployeeId;
      } else {
        dataToStore.scopeInstitutionId = undefined;
        dataToStore.scopeInstitutionEmployeeId = undefined;
      }

      this.personDataService.storePersonGroup(dataToStore).subscribe(id => {
        Object.assign(this.selectedPersonGroup, dataToStore);
        this.selectedPersonGroup.id = id;
        this.createContextFormGroup();
        this.storing = false;
      }, error => {
        this.storing = false;
        FormHelper.setSingleFormGroupServerSideValidationErrors(error, this, this.contextFormGroup);
      });
    });
  }

  addPersonGroup() {
    this.selectedPersonGroup = { id: undefined, caption: 'Новая группа', personGroupVisibility: 3 };
    this.personGroups.push(this.selectedPersonGroup);
    this.invalidateContextFormGroup();
  }

  invalidateContextFormGroup() {
    if (!this.contextFormGroup || this.contextFormGroupBoundPersonGroup !== this.selectedPersonGroup) {
      this.createContextFormGroup();
    }
  }

  selectedGroupChange() {
    if (this.selectedPersonGroup && this.selectedPersonGroup.id) {
      this.router.navigate([`person-groups-browse/${this.selectedPersonGroup.id}`]);
    } else {
      this.invalidateContextFormGroup();
    }
  }

  newPersonTextKeyDown(event: any) {
    if (event.key === 'Enter') {
      this.addClient();
    }
  }

  addClient() {
    const sp = PersonSearchParams.fromString(this.newPeopleFormGroup.controls['newPersonText'].value);

    if (sp.mode === PersonSearchParams.ModeUnknown) {
      return;
    }

    this.personDataService.searchPeople(sp).subscribe(res => {
        if (res && res.length && res.length === 1) {
          this.addMemberToForm(res[0].id);
          this.newPeopleFormGroup.reset();
        } else if (!res || !res.length || res.length === 0) {

          this.alertService.warning('При поиске гражданина для добавления в список клиентов не найдено карточек. ' +
            'Пожалуйста, уточните параметры поиска');

        } else {
          this.alertService.warning('При поиске гражданина для добавления в список клиентов найдено несколько карточек: [' +
            this.buildPersonListText(res) + ']. Пожалуйста, уточните параметры поиска');
        }
      }
    );
  }

  private buildPersonListText(res: any[]) {
    let retVal = '';
    res.forEach(val => {
      retVal = retVal + StringHelper.getPersonTitle(val) + '; ';
    });
    return retVal;
  }

  addMemberToForm(memberId: number) {
    if (!this.selectedPersonGroup.id) {
      return;
    }

    this.personDataService.addPersonGroupItem(this.selectedPersonGroup.id, memberId).subscribe(() => {
      this.items.push(memberId);
    });
  }

  deleteClient(index: number) {
    if (!this.selectedPersonGroup.id) {
      return;
    }

    this.alertService.confirmModal('Вы уверены, что хотите удалить клиента №' + (index + 1) + ' из группы?')
      .subscribe(() => {
        this.personDataService.deletePersonGroupItem(this.selectedPersonGroup.id, this.items[index]).subscribe(() => {
          this.items.splice(index, 1);
        });
      });
  }

  private navigateInitialGroup() {
    if (!this.personGroups) {
      return;
    }

    this.selectedPersonGroup = this.personGroups.find(item => (item.id === this.initialGroupId));
    this.invalidateContextFormGroup();
  }
}
