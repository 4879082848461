import { Component, Input } from '@angular/core';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AppFormGroup } from '../../../ui/controls/app-form-group';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { AgentSearchModalComponent } from '../../edit-agent/search/agent-search-modal.component';

@Component({
  selector: 'app-edit-stable-agent-form',
  templateUrl: './edit-stable-agent-form.component.html'
})
export class EditStableAgentFormComponent {

  @Input() contextFormGroup: AppFormGroup;

  rndToken = Math.floor(Math.random() * 1000000);

  isInvalid = FormHelper.isInvalid;

  constructor(public navigationService: AppNavigationService) {

  }

  searchAgent() {
    this.navigationService.searchAgent(AgentSearchModalComponent).subscribe(val => {
      if (val) {
        this.contextFormGroup.get('agentId').setValue(val);
        this.contextFormGroup.markAsDirty();
      }
    });
  }
}
