import { Component, Input } from '@angular/core';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { HolidaysService } from '../../../logic/services/holidays.service';
import {TicketsPlanningUiService} from './tickets-planning-ui-service';

@Component({
  selector: 'app-tickets-planning-day',
  templateUrl: './tickets-planning-day.component.html',
  styleUrls: ['./tickets-planning.component.css']
})
export class TicketsPlanningDayComponent {

  @Input() day = {
    date: undefined,
    tickets: [],
    continuousTickets: [],
    slots: [],
    available: true,
  };

  @Input() model: any = {};
  @Input() fixedEmployeeMode = true;
  @Input() fixedPersonId: number;
  @Input() zoomedMode: boolean = undefined;

  @Input() workingEmployeeId: number; // сотрудник, соответствующий текущему пользователю
  @Input() workingInstitutionId: number; // учреждение, соответствующее текущему пользователю

  get currentDate(): Date {
    const cDate = new Date();
    return new Date(cDate.getFullYear(), cDate.getMonth(), cDate.getDate());
  }

  constructor(public navigationService: AppNavigationService,
              public holidaysService: HolidaysService,
              public planningUi: TicketsPlanningUiService) {
  }

  toggleExpandedSlot(slot: any) {
    if (!this.zoomedMode) {
      return;
    }

    if (this.model.expandedSlot !== slot) {
      this.model.expandedSlot = slot;
    } else {
      this.model.expandedSlot = undefined;
    }
  }

  slotClick(slot: any) {
    if (!this.zoomedMode) {
      return;
    }

    if (slot.tickets.length > 0 || !this.model.personId || !this.model.institutionServiceId || !this.day.available
      || this.day.continuousTickets.length > 0) {
      this.toggleExpandedSlot(slot);
    } else {
      this.planningUi.addTicket(slot, this.model);
    }
  }

  isCurrentlyFilteredSlot(slot: any) {
    // return !!slot.tickets.find(service =>
    //   this.planningUi.isCurrentlyFilteredVSlot(service, this.fixedEmployeeMode, this.model));
  }

  isCurrentlyFilteredVSlotTail(tickets: any[]) {
    // return tickets.slice(2).find(
    //   service => this.planningUi.isCurrentlyFilteredVSlot(service, this.fixedEmployeeMode, this.model));
  }
}
