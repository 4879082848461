import {
  AbstractControl, FormArray, FormControl, FormControlDirective, FormControlName, FormGroup, NgControl, ValidationErrors, ValidatorFn,
  Validators
} from '@angular/forms';
import { ServerSideErrorsProvider } from '../../logic/validators/server-side-errors-provider';
import { serverSideErrorsValidator } from '../../logic/validators/server-side-errors-validator.directive';
import { isArray } from "util";
import { AppFormGroup } from './app-form-group';

// nativeElement добавляется во все контролы - за счет этого можно обратиться к nativeElement и вызвать focus()
// Проверить производительность. Если будет тормозить - убирать - но также нужно убрать все вызовы setFocusToInvalidControl
const originFormControlNgOnChanges = FormControlDirective.prototype.ngOnChanges;
FormControlDirective.prototype.ngOnChanges = function () {
  this.form.nativeElement = this.valueAccessor._elementRef.nativeElement;
  return originFormControlNgOnChanges.apply(this, arguments);
};

const originFormControlNameNgOnChanges = FormControlName.prototype.ngOnChanges;
FormControlName.prototype.ngOnChanges = function () {
  const result = originFormControlNameNgOnChanges.apply(this, arguments);
  this.control.nativeElement = this.valueAccessor._elementRef ? this.valueAccessor._elementRef.nativeElement : null;
  return result;
};

export class FormHelper {
  public static isInvalid(fg: FormGroup, controlName: string): boolean {
    const control = fg.controls[controlName];
    if (!control) {
      return true;
    }
    return control.invalid && (control.dirty || control.touched || FormHelper.isSubmitted(fg));
  }

  public static equalsSome(value: any, ...probeValues: any[]): boolean {
    if (!probeValues || !probeValues.length) {
      return false;
    }

    return probeValues.some(el => el == value);
  }

  public static isInvalidControl(control: AbstractControl): boolean {
    if (!control) {
      return true;
    }
    return control.invalid && (control.dirty || control.touched || FormHelper.isSubmitted(control.parent));
  }

  public static markAsSubmitted(fg: FormGroup | FormArray) {
    (fg as any).submitted = true;
    FormHelper.markAsTouched(fg);
  }

  public static markAsTouched(fg: FormGroup | FormArray) {
    Object.keys(fg.controls).forEach(field => {
      const control = fg.get(field);
      control.markAsTouched({onlySelf: true});
      control.updateValueAndValidity();

      if (control instanceof FormGroup) {
        FormHelper.markAsTouched(control as FormGroup);
      } else if (control instanceof FormArray) {
        FormHelper.markAsTouched(control as FormArray);
      }
    });
  }

  public static isSubmitted(fg: AbstractControl): boolean {
    while (fg) {
      if ((fg as any).submitted) {
        return true;
      }
      fg = fg.parent;
    }
    return false;
  }

  public static setSingleFormGroupServerSideValidationErrors(error: any,
                                                             validationErrorsHostObj: { serverSideValidationErrors: any[] },
                                                             fg: FormGroup) {
    validationErrorsHostObj.serverSideValidationErrors = error.error && error.error.data && error.error.data[0]
    && !error.error.data.localeCompare
      ? error.error.data[0]
      : undefined;

    (fg as any).docLevelServerSideValidationErrors = [];

    if (!validationErrorsHostObj.serverSideValidationErrors) {
      return;
    }

    if (!Array.isArray(validationErrorsHostObj.serverSideValidationErrors)) {
      validationErrorsHostObj.serverSideValidationErrors = [validationErrorsHostObj.serverSideValidationErrors];
    }

    setTimeout(() => {
      validationErrorsHostObj.serverSideValidationErrors.forEach(item => {
        if (item.fieldName) {
          const control = (fg as any).controls[item.fieldName];
          if (control) {
            item.badValue = control.value;
            control.updateValueAndValidity();
          }
          if (item.violationType === 'fieldBean' && !(fg as any).docLevelServerSideValidationErrors.includes(item)) {
            (fg as any).docLevelServerSideValidationErrors.push(item);
          }
        } else {
          if (!(fg as any).docLevelServerSideValidationErrors.includes(item)) {
            (fg as any).docLevelServerSideValidationErrors.push(item);
          }
        }
      });
    }, 1);
  }

  public static setSingleFormGroupServerSideValidationErrorsWithRelationMsgKey(error: any,
                                                                             validationErrorsHostObj: { serverSideValidationErrors: any[] },
                                                                             fg: FormGroup) {
    if (error && error.error && error.error.data && isArray(error.error.data[0])) {
      error.error.data[0].forEach(err => {
        if (err.relationMsgKey && !fg.contains(err.fieldName)) {
          if (fg.contains(err.relationMsgKey)) {
            FormHelper.setSingleFormGroupServerSideValidationErrors(error,
              validationErrorsHostObj, fg.get(err.relationMsgKey) as AppFormGroup);
            return;
          }

          // если пришла ошибка с relationMsgKey с разделителем (например, field-0),
          // то обрабатываем эту ошибку как массив
          // ищем по индексу (из примера выше -0) форму и передаем на обработку дальше
          const key = (err.relationMsgKey as string).split('-');
          if (key.length && key.length > 1 && fg.contains(key[0])) {
            FormHelper.setSingleFormGroupServerSideValidationErrors(error,
              validationErrorsHostObj, (fg.get(key[0]) as FormArray).at(parseInt(key[1], 10)) as AppFormGroup);
          }
        } else {
          FormHelper.setSingleFormGroupServerSideValidationErrors(error, validationErrorsHostObj, fg);
        }
      });
    }
  }

  public static getChar(event) {
    if (event.which == null) { // IE
      if (event.keyCode < 32) { // спец. символ
        return null;
      }
      return String.fromCharCode(event.keyCode);
    }

    if (event.which !== 0 && event.charCode !== 0) { // все кроме IE
      if (event.which < 32) {
        return null;  // спец. символ
      }
      return String.fromCharCode(event.which); // остальные
    }

    return null; // спец. символ
  }

  public static processMoneyKeypress(e: any): boolean {
    if (e.ctrlKey || e.altKey || e.metaKey) { // спец. сочетание - не обрабатываем
      return false;
    }

    const char = FormHelper.getChar(e);

    if (char === '0' || char === '1' || char === '2' || char === '3' || char === '4' || char === '5' || char === '6'
      || char === '7' || char === '8' || char === '9' || char === ',') {
      return true;
    } else if (char === '/' || char === '.' || char === '?' || char === '<' || char === '>' || char === 'б'
      || char === 'Б' || char === 'ю' || char === 'Ю') {

      const caret = FormHelper.getCaretPosition(e.target);
      let val = e.target.value;
      val = val.substr(0, caret) + ',' + val.substr(caret);
      e.target.value = val;
    }
    return false;
  }

  public static processMoneyKeypressDot(e: any): boolean {
    if (e.ctrlKey || e.altKey || e.metaKey) { // спец. сочетание - не обрабатываем
      return false;
    }

    const char = FormHelper.getChar(e);

    if (char === '0' || char === '1' || char === '2' || char === '3' || char === '4' || char === '5' || char === '6'
      || char === '7' || char === '8' || char === '9' || char === '.') {
      return true;
    } else if (char === '/' || char === ',' || char === '?' || char === '<' || char === '>' || char === 'б'
      || char === 'Б' || char === 'ю' || char === 'Ю') {

      const caret = FormHelper.getCaretPosition(e.target);
      let val = e.target.value;
      val = val.substr(0, caret) + '.' + val.substr(caret);
      e.target.value = val;
    }
    return false;
  }

  public static getCaretPosition(oField: any) {

    // Initialize
    let iCaretPos = 0;

    if ((document as any).selection) { // IE Support

      // Set focus on the element
      oField.focus();

      // To get cursor position, get empty selection range
      const oSel = (document as any).selection.createRange();

      // Move selection start to 0 position
      oSel.moveStart('character', -oField.value.length);

      // The caret position is selection length
      iCaretPos = oSel.text.length;
    } else if (oField.selectionStart || oField.selectionStart === '0') {    // Firefox support
      iCaretPos = oField.selectionStart;
    }

    // Return results
    return iCaretPos;
  }

  public static getApplicationMoneyValidator(): any {
    return Validators.pattern(/^\d*,{0,1}\d{0,2}$/);
  }

  public static getApplicationMoneyValidatorDot(): any {
    return Validators.pattern(/^\d*\.?\d{0,2}$/);
  }

  public static toAppMoneyString(amount: any): string {
    if (!amount && amount !== 0) {
      return undefined;
    }

    return amount.toString().replace(/\./g, ',');
  }

  public static fromAppMoneyString(amount: any): number {
    if (!amount) {
      return undefined;
    }

    return Number.parseFloat(amount.toString().replace(/,/g, '.'));
  }

  // Этим мы боремся с вполне допустимыми значениями в пикере типа 12345-01-01, из за чего это все не может корректно
  // прийти на сервер и Spring JSON Serializer падает с соответствующей ошибкой, а пользователю приходит ничего не значащая
  // для него ошибка 500. Валидатор должнен стоять на каждом из контролов
  public static validateDateTimePicker(): ValidatorFn {
    return Validators.pattern(/^[1-2][0-9]{3}-\d{2}-\d{2}(T\w.*)*$/);
  }

  public static conditionalValidate(validatorFn: ValidatorFn, conditionFn: (control: AbstractControl) => boolean): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return conditionFn(control) ? validatorFn(control) : null;
    };
  }

  public static putControlDefWithSSV(formGroupDef: any,
                                     dataObj: any,
                                     fieldName: string,
                                     serverSideErrorsProvider: ServerSideErrorsProvider,
                                     ...validators: ValidatorFn[]) {

    validators.push(serverSideErrorsValidator(fieldName, serverSideErrorsProvider));

    FormHelper.putControlDef(formGroupDef, dataObj, fieldName, ...validators);
  }

  public static putControlDef(formGroupDef: any,
                              dataObj: any,
                              fieldName: string,
                              ...validators: ValidatorFn[]) {

    formGroupDef[fieldName] = [dataObj ? dataObj[fieldName] : undefined, Validators.compose(validators)];
  }

  public static validateCadNo(): ValidatorFn {
    return Validators.pattern(/^\d{2}:\d{2}:\d{7}:\d{1,7}$/);
  }

  public static validateMoney(): ValidatorFn {
    return Validators.pattern(/^\d{1,15}(\.\d{1,2})?$/);
  }

  public static validateMoneyThreeDecimal(): ValidatorFn {
    return Validators.pattern(/^\d{1,15}(\.\d{1,3})?$/);
  }

  public static validateMoneyThreeDecimalComma(): ValidatorFn {
    return Validators.pattern(/^\d{1,15}(,\d{1,3})?$/);
  }

  public static validateMoneyMoreTwoDecimal(): ValidatorFn {
    return Validators.pattern(/^\d{1,15}(\.\d{1,10})?$/);
  }

  public static validateDrugDecimal(): ValidatorFn {
    return Validators.pattern(/^\d{1,15}(,\d{1,5})?$/);
  }

  public static persistAgGridState(gridOptions: any, storageObj: any, storageFieldName: string) {
    if (!gridOptions) {
      return;
    }

    const storedOptions: any = {};
    storageObj[storageFieldName] = storedOptions;

    storedOptions.colState = gridOptions.columnApi.getColumnState();
    storedOptions.groupState = gridOptions.columnApi.getColumnGroupState();
    storedOptions.sortState = gridOptions.api.getSortModel();
    storedOptions.filterState = gridOptions.api.getFilterModel();
  }

  public static restoreAgGridState(gridOptions: any, storageObj: any, storageFieldName: string) {
    if (!gridOptions) {
      return;
    }

    const storedOptions = storageObj[storageFieldName];

    if (storedOptions) {
      gridOptions.columnApi.setColumnState(storedOptions.colState);
      gridOptions.columnApi.setColumnGroupState(storedOptions.groupState);
      gridOptions.api.setSortModel(storedOptions.sortState);
      gridOptions.api.setFilterModel(storedOptions.filterState);
    }
  }

  public static setFocusToInvalidControl(form: FormGroup) {
    const invalidControl = FormHelper.findFirstInvalidControlRecursive(form);
    if (invalidControl && (<any>invalidControl).nativeElement) {
      (<any>invalidControl).nativeElement.focus();
    }
  }

  public static findFirstInvalidControlRecursive(formToInvestigate: FormGroup | FormArray): FormControl {
    let invalidControl = null;
    const recursiveFunc = (form: FormGroup | FormArray) => {
      for (const field of Object.keys(form.controls)) {
        const control = form.get(field);
        if (!invalidControl && control instanceof FormGroup) {
          recursiveFunc(control);
        } else if (!invalidControl && control instanceof FormArray) {
          recursiveFunc(control);
        } else if (!invalidControl && control.invalid && field === 'dateTo' &&
                  (form as FormGroup).contains('dateToIncluded') && form.get('dateToIncluded').value) {
          // если invalid контрол - это dateTo и dateToIncluded заполнен, то нужно перейти к контролу dateToIncluded
          invalidControl = form.get('dateToIncluded');
          break;
        } else if (!invalidControl && control.invalid) {
          invalidControl = control;
          break;
        }
      }
    };
    recursiveFunc(formToInvestigate);
    return invalidControl;
  }

  public static cloneAbstractControl<T extends AbstractControl>(control: T): T {
    let newControl: T;

    if (control instanceof FormGroup) {
      const formGroup = new FormGroup({}, control.validator, control.asyncValidator);
      const controls = control.controls;

      Object.keys(controls).forEach(key => {
        formGroup.addControl(key, FormHelper.cloneAbstractControl(controls[key]));
      });

      newControl = formGroup as any;
    } else if (control instanceof FormArray) {
      const formArray = new FormArray([], control.validator, control.asyncValidator);

      control.controls.forEach(formControl => formArray.push(FormHelper.cloneAbstractControl(formControl)));

      newControl = formArray as any;
    } else if (control instanceof FormControl) {
      newControl = new FormControl(control.value, control.validator, control.asyncValidator) as any;
    } else {
      throw new Error('Error: unexpected control value');
    }

    if (control.disabled) {
      newControl.disable({emitEvent: false});
    }

    newControl.setParent(control.parent);
    return newControl;
  }

  public static resetAllFields<T extends AbstractControl>(control: T, noTouchFieldNames: string[] = []) {
    if (control instanceof FormArray) {
      if (control.controls.length) {
        const cloneControl = FormHelper.cloneAbstractControl(control.controls[0]);
        while (control.controls.length !== 0) {
          control.removeAt(0);
        }
        if (cloneControl instanceof FormGroup) {
          FormHelper.resetAllFields(cloneControl, noTouchFieldNames);
          control.push(cloneControl);
        }
      }
    } else if (control instanceof FormGroup) {
      Object.keys(control.controls).forEach(c => {
        if (!noTouchFieldNames.includes(c)) {
          FormHelper.resetAllFields(control.get(c), noTouchFieldNames);
        }
      });
    } else {
      control.reset();
    }
  }
}
