/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./app-text-lookup.component.ngfactory";
import * as i2 from "./app-text-lookup.component";
import * as i3 from "../../logic/services/lookup-source.service";
import * as i4 from "./ag-grid-text-lookup-editor.component";
var styles_AgGridTextLookupEditorComponent = [];
var RenderType_AgGridTextLookupEditorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AgGridTextLookupEditorComponent, data: {} });
export { RenderType_AgGridTextLookupEditorComponent as RenderType_AgGridTextLookupEditorComponent };
export function View_AgGridTextLookupEditorComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-text-lookup", [["class", "app-ag-grid-text-lookup-editor"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.changeValue() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_AppTextLookupComponent_0, i1.RenderType_AppTextLookupComponent)), i0.ɵdid(1, 573440, null, 0, i2.AppTextLookupComponent, [i3.LookupSourceService], { contextControlName: [0, "contextControlName"], lookupName: [1, "lookupName"], contextFormGroup: [2, "contextFormGroup"], contextControlId: [3, "contextControlId"], sorted: [4, "sorted"], fixedWidthPx: [5, "fixedWidthPx"], fixedHeightPx: [6, "fixedHeightPx"], focusAfterRender: [7, "focusAfterRender"] }, { change: "change" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.controlName; var currVal_1 = (_co.params.lookupName + (_co.params.parentFieldLookup ? _co.params.data.get(_co.params.parentFieldLookup).value : "")); var currVal_2 = _co.formGroup; var currVal_3 = ("textLookupEditor" + _co.rndToken); var currVal_4 = true; var currVal_5 = (_co.params.colDef.width - 2); var currVal_6 = 30; var currVal_7 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
export function View_AgGridTextLookupEditorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-ag-grid-text-lookup-editor", [], null, null, null, View_AgGridTextLookupEditorComponent_0, RenderType_AgGridTextLookupEditorComponent)), i0.ɵdid(1, 49152, null, 0, i4.AgGridTextLookupEditorComponent, [], null, null)], null, null); }
var AgGridTextLookupEditorComponentNgFactory = i0.ɵccf("app-ag-grid-text-lookup-editor", i4.AgGridTextLookupEditorComponent, View_AgGridTextLookupEditorComponent_Host_0, {}, {}, []);
export { AgGridTextLookupEditorComponentNgFactory as AgGridTextLookupEditorComponentNgFactory };
