import { FormGroup } from '@angular/forms';
var QueryDetailsEventFieldsComponent = /** @class */ (function () {
    function QueryDetailsEventFieldsComponent() {
        this.class = 'form-block itech-block-normal';
    }
    Object.defineProperty(QueryDetailsEventFieldsComponent.prototype, "streetLookupName", {
        get: function () {
            return 'addr-street-' + this.contextFormGroup.get('_par_city_region_id').value +
                '-' + this.contextFormGroup.get('_par_city_id').value;
        },
        enumerable: true,
        configurable: true
    });
    QueryDetailsEventFieldsComponent.prototype.docTypeCtrlVisible = function (dt1, dt2, dt3) {
        return this.queryUserMode || ((dt1 && this.contextFormGroup.get('_par_doc_type').value == dt1)
            || (dt2 && this.contextFormGroup.get('_par_doc_type').value == dt2)
            || (dt3 && this.contextFormGroup.get('_par_doc_type').value == dt3));
    };
    return QueryDetailsEventFieldsComponent;
}());
export { QueryDetailsEventFieldsComponent };
