<clr-modal [(clrModalOpen)]="alertService.confirmModalOpened" style="z-index: 999">
  <h3 class="modal-title">Подтвердите действие</h3>
  <div class="modal-body">
    {{alertService.confirmModalMsg}}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="acceptModal()">
      <clr-icon shape="check"></clr-icon>&nbsp;{{alertService.confirmModalAcceptButtonText}}
    </button>
    <button type="button" class="btn btn-warning" (click)="alertService.confirmModalOpened = false">
      <clr-icon shape="undo"></clr-icon>&nbsp;Отмена
    </button>
  </div>
</clr-modal>
