import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { AppFormGroup } from '../../../ui/controls/app-form-group';
import { ProductDataService } from '../../../logic/services/product-data.service';
import { EditProductCommonFormComponent } from './edit-product-common-form.component';
import { Router } from '@angular/router';
import { MetadataService } from '../../../logic/services/metadata.service';
import { SendToClearanceModalComponent } from './send-to-clearance-modal.component';
import { serverSideErrorsValidator } from '../../../logic/validators/server-side-errors-validator.directive';

@Component({
  selector: 'app-edit-product-common',
  templateUrl: './edit-product-common.component.html'
})
export class EditProductCommonComponent implements OnInit, OnDestroy {

  equalsSome = FormHelper.equalsSome;

  private _id: number;

  isAllowedToDelete = false;

  @Input()
  get id(): number {
    return this._id;
  }

  set id(id: number) {
    if (this._id !== id) {
      this._id = id;
      this.refreshFormDataInternal();
    }
  }

  @Input() model: any = {};
  storing = false;
  loading = false;

  updateSyncStateTimer: any = {};
  updateSyncStatePending: any = {};

  deletedModalOpen = false;
  deletedFormGroup = this.fb.group({
    deletedReason: null
  }) as AppFormGroup;
  deletedSelectedCheckboxConfirmAction = false;

  rejectProductRequestModalOpen = false;
  rejectProductRequestFormGroup = this.fb.group({
    rejectReason: [null, Validators.compose([
      serverSideErrorsValidator('rejectReason', this.model)])]
  }) as AppFormGroup;

  get contextFormGroup(): AppFormGroup {
    return this.model ? this.model.contextFormGroup : undefined;
  }

  get syncStates(): any[] {
    return this.model && this.model.syncStates ? Object.values(this.model.syncStates) : undefined;
  }

  get isRequest(): boolean {
    return this.contextFormGroup && +this.contextFormGroup.controls['status'].value === -1;
  }

  static getDefaultModel(id: number = null): any {
    return {id: id, serverSideValidationErrors: [], syncStates: {}};
  }

  constructor(private bookmarkService: BookmarkService,
              private productDataService: ProductDataService,
              private alertService: AlertService,
              private cache: AddressPersonFioCacheService,
              private dataCachingService: DataCachingService,
              private waitingOverlay: GlobalWaitingOverlayService,
              private fb: FormBuilder,
              public navigationService: AppNavigationService,
              private router: Router,
              private metadataService: MetadataService) {
  }

  store() {

    FormHelper.markAsSubmitted(this.contextFormGroup);
    EditProductCommonFormComponent.prettifyFormGroup(this.contextFormGroup);

    if (!this.contextFormGroup.valid) {
      return;
    }

    this.storing = true;

    this.productDataService.storeProductCommonInfo(this.contextFormGroup.value).subscribe(val => {
      this.storing = false;
      this.cache.invalidateProduct(this.id);
      this.bookmarkService.refreshProductCardBookmark(this.id);
      this.contextFormGroup.markAsUntouched();
      this.contextFormGroup.markAsPristine();
      this.dataCachingService.removeCachedData('EditProductCommon', this.id.toString());
      this.model.contextFormGroup = EditProductCommonFormComponent.createFormGroup(this.fb, this.model, val);
      this.dataCachingService.addToCache('EditProductCommon', this.id.toString(), this.model);
      FormHelper.setSingleFormGroupServerSideValidationErrorsWithRelationMsgKey({}, this.model, this.contextFormGroup);
      this.alertService.success(`Документ успешно сохранен`);
      this.refreshSyncState();
    }, error => {
      this.storing = false;
      FormHelper.setSingleFormGroupServerSideValidationErrorsWithRelationMsgKey(error, this.model, this.contextFormGroup);
      window.document.getElementById('product-common-content').scrollTop = 0;
    });
  }

  isInvalid(fg: FormGroup, cname: string) {
    return FormHelper.isInvalid(fg, cname);
  }

  cancelEdit() {
    this.dataCachingService.removeCachedData('EditProductCommon', this.id.toString());
    this.refreshFormDataInternal();
  }

  refreshFormData() {
    this.loading = true;
    this.dataCachingService.removeCachedData('EditProductCommon', this.id.toString());
    this.refreshFormDataInternal();
  }

  refreshFormDataInternal() {
    this.waitingOverlay.StartWaiting();

    this.model = EditProductCommonComponent.getDefaultModel(this.id);

    const existing = this.dataCachingService.getCachedData('EditProductCommon', this.id.toString());

    if (existing) {
      this.model = existing;
      this.waitingOverlay.EndWaiting();
    } else {

      this.productDataService.getProductCommonForEdit(this.id).subscribe(data => {
        this.model.id = data.id;
        this.model.contextFormGroup = EditProductCommonFormComponent.createFormGroup(this.fb, this.model, data);
        this.cache.invalidateProduct(this.id);
        this.dataCachingService.addToCache('EditProductCommon', this.id.toString(), this.model);
        this.waitingOverlay.EndWaiting();

        this.refreshSyncState();
        this.loading = false;
      }, () => {
        this.waitingOverlay.EndWaiting();
        this.loading = false;
      });
    }
  }

  deleteDocument() {
    this.alertService.confirmModal('Вы уверены, что хотите удалить безвозвратно карточку продукции?').subscribe(() => {
      this.productDataService.deleteProduct(this.id).subscribe(() => {
        this.bookmarkService.removeOpenCardBookmark(['/product-edit'], this.id.toString());
        this.router.navigate(['/product-search']);
      });
    });
  }

  sendToRegistration() {
    if (!this.validateRegionalization()) {
      return;
    }
    this.alertService.confirmModal('Вы уверены, что хотите направить документ на регистрацию в Меркурий?').subscribe(() => {
      this.productDataService.sendToRegistration([this.id]).subscribe(() => {
        this.refreshFormData();
      },
        error => FormHelper.setSingleFormGroupServerSideValidationErrors(error, { serverSideValidationErrors: [] }, this.contextFormGroup));
    });
  }

  sendToDeletion() {
    this.productDataService.sendToDeletion(this.id, this.deletedFormGroup.get('deletedReason').value)
      .subscribe(
        () => {
          this.closeDeletedModal();
          this.refreshFormData();
        },
        error => FormHelper.setSingleFormGroupServerSideValidationErrors(error, { serverSideValidationErrors: [] }, this.deletedFormGroup));
  }

  sendToClearance() {
    const source = this.contextFormGroup && this.contextFormGroup.get('sources')
      ? (this.contextFormGroup.get('sources') as FormArray).at(0)
      : null;
    const path = this.contextFormGroup && this.contextFormGroup.get('path')
      ? (this.contextFormGroup.get('path') as FormArray).at(this.contextFormGroup.get('path').value.length - 2)
      : null;
    this.navigationService.sendProductToClearance(SendToClearanceModalComponent,
      {
        productId: this.id,
        volume: source ? source.get('value').value : 0,
        fullVolume: source ? source.get('value').value : 0,
        unitId: source ? source.get('unitId').value : null,
        productFormGroup: this.contextFormGroup,
        ...{
          ...(source ? source.value : {}),
          ...(path ? path.value : {}),
        }
      }).subscribe(() => this.refreshFormData(),
      error => FormHelper.setSingleFormGroupServerSideValidationErrors(error, { serverSideValidationErrors: [] }, this.contextFormGroup));
  }

  cloneProduct(fromTemplate = false) {
    this.navigationService.performProductCreation(undefined, this.id, undefined, undefined,
      undefined, undefined, fromTemplate);
  }

  deriveProduct(transactionTypeId: number) {
    this.navigationService.performProductDerivation(this.id, transactionTypeId);
  }

  public refreshSyncState() {
    this.model.syncStates = {};
    if (+this.contextFormGroup.get('status').value === 0) {
      this.refreshSyncStateInternal(3);
    } else if (+this.contextFormGroup.get('status').value > 0) {
      this.refreshSyncStateInternal(3);
      this.refreshSyncStateInternal(4);
      this.refreshSyncStateInternal(6);
    } else if (+this.contextFormGroup.get('status').value === -1) {
      this.refreshSyncStateInternal(11);
    }
  }

  private refreshSyncStateInternal(conversationTypeId: number) {

    if (!this.contextFormGroup) {
      return;
    }

    if (this.equalsSome(conversationTypeId, 3, 4, 6) && +this.contextFormGroup.get('status').value < 0) {
      return;
    } else if (this.equalsSome(conversationTypeId, 11) && +this.contextFormGroup.get('status').value !== -1) {
      return;
    }

    const state = this.model && this.model.syncStates ? this.model.syncStates[conversationTypeId] : undefined;

    // если ничего не запланировано по синхронизации - ничего и не может произойти и обновлять состояние не надо
    if (state && (!state.mercuryExchangeNextTime || new Date(state.mercuryExchangeNextTime) > new Date())) {
      return;
    }

    if (this.updateSyncStatePending[conversationTypeId]) {
      return;
    }

    this.updateSyncStatePending[conversationTypeId] = true;

    this.metadataService.getSyncState(this.id, conversationTypeId).subscribe({
      next: data => {
        if (this.contextFormGroup && data && +conversationTypeId === 11) {
          data.withoutMercuryUuid = true;

          if (this.equalsSome(this.contextFormGroup.get('requestStatus').value, -1, 2)) {
            data.lookupStage = 'product-request/accept';
          }
        }
        this.updateSyncStatePending[conversationTypeId] = false;
        this.model.syncStates[conversationTypeId] = data;

        if (this.model.syncStates[conversationTypeId]) {
          this.model.syncStates[conversationTypeId].isEditStarted = false;
        }
      }, error: () => {
        this.updateSyncStatePending[conversationTypeId] = false;
        this.model.syncStates[conversationTypeId] = {};
      }
    });
  }

  ngOnInit(): void {
    this.updateSyncStateTimer[3] = setInterval(() => this.refreshSyncStateInternal(3), 10000);
    this.updateSyncStateTimer[4] = setInterval(() => this.refreshSyncStateInternal(4), 10000);
    this.updateSyncStateTimer[6] = setInterval(() => this.refreshSyncStateInternal(6), 10000);
    this.updateSyncStateTimer[11] = setInterval(() => this.refreshSyncStateInternal(11), 10000);
  }

  ngOnDestroy(): void {
    if (this.updateSyncStateTimer) {
      Object.keys(this.updateSyncStateTimer).forEach(x => clearInterval(this.updateSyncStateTimer[x]));
      this.updateSyncStateTimer = {};
    }
  }

  isTransportOrMovingTransaction() {
    return EditProductCommonFormComponent.isTransportOrMovingTransaction(this.contextFormGroup);
  }

  isTransportTransaction() {
    return EditProductCommonFormComponent.isTransportTransaction(this.contextFormGroup);
  }

  closeDeletedModal() {
    this.deletedModalOpen = false;
    this.deletedSelectedCheckboxConfirmAction = false;
    this.deletedFormGroup.reset();
  }

  isAvailableReports() {
    return this.contextFormGroup && this.isTransportOrMovingTransaction() &&
           this.equalsSome(+this.contextFormGroup.get('status').value, 2, 3);
  }

  validateRegionalization(): boolean {
    if (!this.contextFormGroup) {
      return false;
    }

    if (!this.isTransportTransaction() || !this.contextFormGroup.get('hasRequirements').value) {
      return true;
    }

    FormHelper.markAsSubmitted(this.contextFormGroup);

    let valid = true;

    for (const path of (this.contextFormGroup.get('path') as FormArray).controls) {
      if (path.get('regionalizationRequirements').value.length) {
        for (const requirement of (path.get('regionalizationRequirements') as FormArray).controls) {
          if (!(requirement.get('conditionGroups') as FormArray).getRawValue().some(x => x.acceptedGroup)) {
            valid = false;
            (requirement.get('conditionGroups') as FormArray).controls
              .forEach(x => x.get('acceptedGroup').setErrors({'incorrect': true}));
          }
        }
      }
    }

    if (!valid) {
      this.alertService.error('Перед отправкой на регистрацию, отметьте условия регионализации, сохраните документ и повторите отправку');
    }

    return valid;
  }

  applyRequest() {
    this.alertService.confirmModal('Вы уверены, что хотите принять положительное решение по заявки и оформить по ней транзакцию?')
      .subscribe(val => {
        if (val) {
          this.processDecisionMakingRequest(2);
        }
      });
  }

  closeRejectProductRequestModal() {
    this.rejectProductRequestModalOpen = false;
    this.rejectProductRequestFormGroup.reset();
  }

  processDecisionMakingRequest(newProductRequestStatus: number) {
    this.productDataService.startSyncDecisionMakingProductRequest({
      productRequestIds: [this.id],
      newProductRequestStatus: newProductRequestStatus,
      rejectReason: this.rejectProductRequestFormGroup.get('rejectReason').value,
    })
      .subscribe(
        () => {
          this.closeRejectProductRequestModal();
          this.refreshFormData();
        },
        error => FormHelper.setSingleFormGroupServerSideValidationErrors(error, this.model, this.rejectProductRequestFormGroup));
  }

  isAllowedToDeleteCheck() {
    const find = this.syncStates.find(syncState => syncState.conversationTypeId === 3);
    if (find) {
      if ((this.equalsSome(find.mercuryExchangeStatus, 0, -1) && find.conversationStageId < 5) ||
        this.model.contextFormGroup.get('mercuryUuid').value === null ||
        !this.equalsSome(this.model.contextFormGroup.get('status').value, 2, 3, 4, 6)) {
        this.isAllowedToDelete = true;
      }
    } else {
      if (this.equalsSome(this.contextFormGroup.controls['status'].value, 0, -100)) {
        this.isAllowedToDelete = true;
      }
    }
  }
}
