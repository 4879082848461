import { ChangeDetectorRef, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AppFormGroup } from '../../../ui/controls/app-form-group';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { DrugSearchModalComponent } from '../../edit-drug/search/drug-search-modal.component';
import { AnimalDataService } from '../../../logic/services/animal-data.service';
import { DateHelper } from '../../../helpers/date-helper';
import { serverSideErrorsValidator } from '../../../logic/validators/server-side-errors-validator.directive';
import { StableSearchModalComponent } from '../../edit-stable/search/stable-search-modal.component';
import { AgentSearchModalComponent } from '../../edit-agent/search/agent-search-modal.component';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';
import { AnimalSearchModalComponent } from '../search/animal-search-modal.component';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { QuarantineDataService } from '../../../logic/services/quarantine-data.service';
import { AppGridEditableComponent } from '../../../ui/controls/app-grid-editable.component';
import { EditDrugCommonFormComponent } from '../../edit-drug/common/edit-drug-common-form.component';
var EditAnimalEventCommonFormComponent = /** @class */ (function () {
    function EditAnimalEventCommonFormComponent(appNavigationService, fb, animalDataService, cacheService, lookupSourceService, globalWaitingOverlayService, dataCachingService, quarantineDataService, cdr) {
        var _this = this;
        this.appNavigationService = appNavigationService;
        this.fb = fb;
        this.animalDataService = animalDataService;
        this.cacheService = cacheService;
        this.lookupSourceService = lookupSourceService;
        this.globalWaitingOverlayService = globalWaitingOverlayService;
        this.dataCachingService = dataCachingService;
        this.quarantineDataService = quarantineDataService;
        this.cdr = cdr;
        this.isInvalid = FormHelper.isInvalid;
        this.changeDecimalStrValue = EditDrugCommonFormComponent.changeDecimalStrValue;
        this.showAddManyAnimal = false;
        this.trappedToQuarantine = [];
        this.resultsCountsChanged = new EventEmitter();
        this.addAllAvailableDiseases = false;
        this.equalsSome = FormHelper.equalsSome;
        this.gridColumnDefs = [
            {
                headerName: '', field: '', width: 50,
                checkboxSelection: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true,
            },
            {
                headerName: '№', field: 'idNumber', width: 130,
                cellRenderer: function (params) {
                    return params.value || params.data.id;
                },
            },
            {
                headerName: 'Сертификат', field: 'hasStockEntryRecordTitle', width: 50,
                cellRenderer: function (params) {
                    return params && params.data && params.data.hasStockEntryRecord
                        ? "<clr-icon shape=\"certificate\" style=\"color:green\" title=\"\u0418\u043C\u0435\u0435\u0442\u0441\u044F \u0441\u0435\u0440\u0442\u0438\u0444\u0438\u043A\u0430\u0442\"></clr-icon>"
                        : "";
                },
                filterValueGetter: function (params) { return params.data.hasStockEntryRecord ? 'есть сертификат' : 'нет сертификата'; },
                cellStyle: { 'padding-left': '17px' },
            },
            {
                headerName: 'Наименование', field: 'caption', width: 250,
                cellRenderer: function (params) {
                    return (params.data.animalGroup ? '<clr-icon shape="layers"></clr-icon>&nbsp;' : '') + params.value;
                }
            },
            {
                headerName: 'Вид', field: 'animalTypeIdCaption', width: 150,
            },
            {
                headerName: 'Подвид', field: 'animalSubtypeIdCaption', width: 150,
            },
        ];
        this.gridResultSingleColDefs = [
            {
                headerName: 'Наименование',
                field: 'animalId',
                lookupName: 'animal',
                editorType: AppGridEditableComponent.EDITOR_TYPE_MODAL_SEARCH,
                width: 335,
            },
            {
                headerName: 'Результат', field: 'result',
                editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_LOOKUP,
                lookupName: 'exam-result',
                width: 150,
                required: function () { return true; },
                editable: function () { return true; },
            },
            {
                headerName: 'Состояние', field: 'state',
                editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_LOOKUP,
                lookupName: 'animal-state',
                width: 150,
                required: function () { return true; },
                editable: function () { return true; },
            },
            {
                headerName: 'Причина запрета на убой', field: 'reasonIsolated',
                width: 390, tooltipField: 'reasonIsolated',
                hide: true,
                editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_INPUT,
                editable: function (params) {
                    return +params.data.result === 1 && _this.eventType.id === 32;
                },
            },
        ];
        this.gridResultGroupColDefs = [
            {
                headerName: 'Наименование',
                field: 'animalId',
                lookupName: 'animal',
                editorType: AppGridEditableComponent.EDITOR_TYPE_MODAL_SEARCH,
                width: 290,
            },
            {
                headerName: 'Подвид',
                field: 'animalSubTypeId',
                width: 190,
                editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_LOOKUP,
                lookupName: 'animal-subtype',
                parentFieldLookup: 'animalTypeId',
                requiredValue2: true,
            },
            {
                headerName: 'Пол.', headerTooltip: 'Положительно', field: 'countPositive', width: 90,
                editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_INPUT,
                keyPressCallback: FormHelper.processMoneyKeypress,
                editable: function () { return true; },
            },
            {
                headerName: 'Отр.', headerTooltip: 'Отрицательно', field: 'countNegative', width: 90,
                editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_INPUT,
                keyPressCallback: FormHelper.processMoneyKeypress,
                editable: function () { return true; },
            },
            {
                headerName: 'Среаг.', headerTooltip: 'Среагировало', field: 'countRespond', width: 90,
                editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_INPUT,
                keyPressCallback: FormHelper.processMoneyKeypress,
                editable: function () { return true; },
            },
            {
                field: 'countExamined', width: 90,
                editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_INPUT,
                keyPressCallback: FormHelper.processMoneyKeypress,
                editable: function () { return true; },
                headerValueGetter: function () { return _this.getCaptionExamined(true); },
                headerTooltip: function () { return _this.getCaptionExamined(); },
            },
            {
                field: 'countIsolated', width: 90,
                editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_INPUT,
                keyPressCallback: FormHelper.processMoneyKeypress,
                editable: function () { return true; },
                headerValueGetter: function () { return _this.getCaptionIsolated(true); },
            },
            {
                headerName: 'Изм.пог.', field: 'countDiff', width: 90,
                editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_INPUT,
                editable: function () { return true; },
                valueSetter: function (params) {
                    if (params.newValue && Number.isInteger(+params.newValue)) {
                        params.data.get('countDiff').setValue(+params.newValue);
                    }
                    else {
                        params.data.get('countDiff').setValue(null);
                    }
                    return true;
                },
            },
            {
                headerName: 'Причина запрета на убой', field: 'reasonIsolated', width: 150, tooltipField: 'reasonIsolated',
                editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_INPUT,
                hide: true,
                editable: function (params) {
                    return +params.data.get('countIsolated').value > 0 && _this.eventType.id === 32;
                },
            },
        ];
        this.animalTypeIdLookup = {};
        this.processMoneyKeypress = FormHelper.processMoneyKeypressDot;
        this.processMoneyKeypressDrug = FormHelper.processMoneyKeypress;
    }
    EditAnimalEventCommonFormComponent.createFormGroup = function (fb, serverSideErrorsProvider, val, animalCountsProvider) {
        var _this = this;
        var group = fb.group({
            id: val.id,
            createdTime: val.createdTime,
            createdUser: val.createdUser,
            date: [val.date, Validators.compose([FormHelper.validateDateTimePicker(),
                    serverSideErrorsValidator('date', serverSideErrorsProvider)])],
            datePlanning: [val.datePlanning, Validators.compose([FormHelper.validateDateTimePicker(),
                    serverSideErrorsValidator('datePlanning', serverSideErrorsProvider)])],
            animalId: val.animalId,
            stableId: val.stableId,
            eventTypeId: [val.eventTypeId, Validators.compose([Validators.required,
                    serverSideErrorsValidator('eventTypeId', serverSideErrorsProvider)])],
            diseaseTypeId: [val.diseaseTypeId, Validators.compose([
                    serverSideErrorsValidator('diseaseTypeId', serverSideErrorsProvider)
                ])],
            diseaseSerotypeId: val.diseaseSerotypeId,
            eventKind: val.eventKind,
            animalGroup: val.animalGroup || false,
            examResult: [val.examResult, Validators.compose([
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent
                        && FormHelper.equalsSome(+ctrl.parent.get('eventKind').value, 1, 2, 3)
                        && !ctrl.parent.get('animalGroup').value
                        && ctrl.parent.get('id').value
                        && ctrl.parent.get('date').value; }),
                    serverSideErrorsValidator('examResult', serverSideErrorsProvider),
                ])],
            examNo: [val.examNo, Validators.compose([
                    serverSideErrorsValidator('examNo', serverSideErrorsProvider)
                ])],
            testMaterialId: val.testMaterialId,
            animalState: [val.animalState, Validators.compose([
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent
                        && FormHelper.equalsSome(+ctrl.parent.get('eventKind').value, 1, 2, 3)
                        && !ctrl.parent.get('animalGroup').value
                        && ctrl.parent.get('id').value
                        && ctrl.parent.get('date').value; }),
                    serverSideErrorsValidator('animalState', serverSideErrorsProvider),
                ])],
            slaughterPlace: val.slaughterPlace,
            sanitarySlaughter: val.sanitarySlaughter,
            slaughterInstitutionId: [val.slaughterInstitutionId, Validators.compose([
                    serverSideErrorsValidator('slaughterInstitutionId', serverSideErrorsProvider)
                ])],
            temperature: [val.temperature, Validators.compose([FormHelper.validateMoney(),
                    serverSideErrorsValidator('temperature', serverSideErrorsProvider)])],
            temperatureTo: [val.temperatureTo, Validators.compose([FormHelper.validateMoney(),
                    serverSideErrorsValidator('temperatureTo', serverSideErrorsProvider)])],
            rangeTemperature: !!val.temperatureTo,
            mucousCondition: val.mucousCondition,
            useRestriction: val.useRestriction,
            restrictedAmountKg: [val.restrictedAmountKg, Validators.compose([FormHelper.validateMoney(),
                    serverSideErrorsValidator('restrictedAmountKg', serverSideErrorsProvider)])],
            restrictedSubamountKg: [val.restrictedSubamountKg, Validators.compose([FormHelper.validateMoney(),
                    serverSideErrorsValidator('restrictedSubamountKg', serverSideErrorsProvider)])],
            eventIndicatorId: [val.eventIndicatorId, Validators.compose([
                    serverSideErrorsValidator('eventIndicatorId', serverSideErrorsProvider)
                ])],
            laboratoryInstitutionId: [val.laboratoryInstitutionId, Validators.compose([
                    serverSideErrorsValidator('laboratoryInstitutionId', serverSideErrorsProvider)
                ])],
            secondary: val.secondary,
            countPositive: [val.countPositive, Validators.pattern(/^\d{1,9}$/)],
            countNegative: [val.countNegative, Validators.pattern(/^\d{1,9}$/)],
            countRespond: [val.countRespond, Validators.pattern(/^\d{1,9}$/)],
            countExamined: [val.countExamined, Validators.pattern(/^\d{1,9}$/)],
            countIsolated: [val.countIsolated, Validators.pattern(/^\d{1,9}$/)],
            reasonIsolated: val.reasonIsolated,
            animalSubTypeId: val.animalSubTypeId,
            animalTypeId: val.animalTypeId,
            comments: val.comments,
            drugs: EditAnimalEventCommonFormComponent.getDrugsArray(fb, val.drugs),
            diagnosticTypeResults: EditAnimalEventCommonFormComponent.getDiagnosticTypeResultsArray(fb, val.diagnosticTypeResults),
            animals: fb.array(val.animals || []),
            animalEventSingleResults: EditAnimalEventCommonFormComponent.getAnimalEventSingleResultsArray(fb, serverSideErrorsProvider, val.animalEventSingleResults),
            animalEventGroupResults: EditAnimalEventCommonFormComponent.getAnimalEventGroupResultsArray(fb, serverSideErrorsProvider, val.animalEventGroupResults),
            counts: EditAnimalEventCommonFormComponent.buildAnimalEventCountsArray(fb, serverSideErrorsProvider, val.counts, animalCountsProvider),
            otherAgentId: val.otherAgentId,
            otherStableId: val.otherStableId,
            otherAnimalId: val.otherAnimalId,
            productId: val.productId,
            mercuryId: val.mercuryId,
            syncError: val.syncError,
            animalStableMode: false,
            performedByAgent: val.performedByAgent,
            quarantineId: val.quarantineId,
            files: (val.files && val.files.length
                ? fb.array(val.files.map(function (el, ix) { return fb.group(_this.buildFormGroupFile(fb, serverSideErrorsProvider, el || {}, 'files-' + ix)); }))
                : fb.array([])),
            notExecuted: val.notExecuted || false,
            stables: fb.array(val.stables || []),
            diseases: fb.array(val.diseases || []),
            square: [val.square, FormHelper.getApplicationMoneyValidatorDot()],
            deathReasonId: [val.deathReasonId, Validators.compose([
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent && +ctrl.parent.get('eventTypeId').value === 29; }),
                    serverSideErrorsValidator('deathReasonId', serverSideErrorsProvider)
                ])],
            syncWithJournalNumbers: fb.array(val.syncWithJournalNumbers || []),
            isForced: val.isForced || false,
            forcedReasonId: [val.forcedReasonId, Validators.compose([
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent && ctrl.parent.get('isForced').value; }),
                    serverSideErrorsValidator('forcedReasonId', serverSideErrorsProvider)
                ])],
        });
        return group;
    };
    EditAnimalEventCommonFormComponent.buildAnimalEventCountsArray = function (fb, serverSideErrorsProvider, val, animalCountsProvider, isForCreateAnimal, isGroupAnimal) {
        if (!val || !val.length) {
            val = [];
        }
        var countsArray = fb.array(val.map(function (count) { return EditAnimalEventCommonFormComponent
            .buildAnimalEventCount(fb, count, isForCreateAnimal, isGroupAnimal); }));
        countsArray.valueChanges.subscribe(function () {
            var i = 0;
            while (i < countsArray.length - 1) {
                if (!countsArray.controls[i].get('animalSubTypeId').value) {
                    countsArray.removeAt(i);
                }
                else {
                    i++;
                }
            }
            if (countsArray.length && countsArray.controls[countsArray.length - 1].get('animalSubTypeId').value) {
                countsArray.push(EditAnimalEventCommonFormComponent.buildAnimalEventCount(fb, animalCountsProvider() || {}, isForCreateAnimal, isGroupAnimal));
            }
        });
        countsArray.push(EditAnimalEventCommonFormComponent.buildAnimalEventCount(fb, animalCountsProvider() || {}, isForCreateAnimal, isGroupAnimal));
        return countsArray;
    };
    EditAnimalEventCommonFormComponent.buildAnimalEventCount = function (fb, animalCount, isForCreateAnimal, isGroupAnimal) {
        return fb.group({
            animalTypeId: animalCount.animalTypeId,
            animalSubTypeId: [animalCount.animalSubTypeId, Validators.compose([FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent && ctrl.parent.parent.value.length === 1
                        && isForCreateAnimal && isGroupAnimal; })])],
            countDiff: [animalCount.countDiff, Validators.compose([FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent && ctrl.parent.get('animalSubTypeId').value
                        && ctrl.parent.parent.parent && !ctrl.parent.parent.parent.controls['animalId']; }),
                    Validators.pattern(/^-?\d{1,10}$/)])],
            countBefore: '',
            gender: animalCount.gender,
        });
    };
    EditAnimalEventCommonFormComponent.buildFormGroupFile = function (fb, serverSideErrorsProvider, val, formGroupNameRelation) {
        return {
            typeId: [val.typeId,
                Validators.compose([FormHelper.validateDateTimePicker,
                    serverSideErrorsValidator('typeId', serverSideErrorsProvider, formGroupNameRelation),
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent && ctrl.parent.get('uri').value; })])],
            uri: [val.uri,
                Validators.compose([FormHelper.validateDateTimePicker,
                    serverSideErrorsValidator('uri', serverSideErrorsProvider, formGroupNameRelation),
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) { return ctrl.parent && ctrl.parent.get('typeId').value; })])],
        };
    };
    EditAnimalEventCommonFormComponent.getDrugsArray = function (fb, drugs) {
        if (!drugs) {
            drugs = [];
        }
        var drugsArray = fb.array(drugs.map(function (el) { return fb.group(EditAnimalEventCommonFormComponent.getDrugGroupDef(el)); }));
        drugsArray.valueChanges.subscribe(function () {
            var i = 0;
            while (i < drugsArray.length - 1) {
                if (!drugsArray.controls[i].get('drugId').value
                    && !drugsArray.controls[i].get('drugTypeId').value) {
                    drugsArray.removeAt(i);
                }
                else {
                    i++;
                }
            }
            if (drugsArray.length && (drugsArray.controls[drugsArray.length - 1].get('drugId').value
                || drugsArray.controls[drugsArray.length - 1].get('drugTypeId').value)) {
                drugsArray.push(fb.group(EditAnimalEventCommonFormComponent.getDrugGroupDef({})));
            }
        });
        drugsArray.push(fb.group(EditAnimalEventCommonFormComponent.getDrugGroupDef({})));
        return drugsArray;
    };
    EditAnimalEventCommonFormComponent.getDrugGroupDef = function (val) {
        return {
            id: val.id,
            __own_money: !!val.drugTypeId,
            drugId: val.drugId,
            drugTypeId: val.drugTypeId,
            spentValue: [val.spentValue],
            spentValueStr: [val.spentValue ? val.spentValue.toString().replace('.', ',') : null,
                Validators.compose([FormHelper.validateDrugDecimal()])],
            sensibility: val.sensibility,
        };
    };
    EditAnimalEventCommonFormComponent.getDiagnosticTypeResultsArray = function (fb, diagnosticTypeResults) {
        if (!diagnosticTypeResults) {
            diagnosticTypeResults = [];
        }
        var eventDiagnosticTypeArray = fb.array(diagnosticTypeResults.map(function (el) { return fb.group(EditAnimalEventCommonFormComponent.getDiagnosticTypeResultsDef(el)); }));
        eventDiagnosticTypeArray.push(fb.group(EditAnimalEventCommonFormComponent.getDiagnosticTypeResultsDef({})));
        return eventDiagnosticTypeArray;
    };
    EditAnimalEventCommonFormComponent.getDiagnosticTypeResultsDef = function (val) {
        return {
            diagnosticTypeId: val.diagnosticTypeId,
            diagnosticResultId: val.diagnosticResultId,
            diagnosticResultValue: [val.diagnosticResultValue, FormHelper.validateMoneyMoreTwoDecimal()],
        };
    };
    EditAnimalEventCommonFormComponent.getAnimalEventSingleResultsArray = function (fb, serverSideErrorsProvider, animalEventSingleResults) {
        if (!animalEventSingleResults) {
            animalEventSingleResults = [];
        }
        return fb.array(animalEventSingleResults.map(function (el) { return EditAnimalEventCommonFormComponent.getAnimalEventSingleResultsDef(fb, el); }));
    };
    EditAnimalEventCommonFormComponent.getAnimalEventSingleResultsDef = function (fb, val) {
        if (val === void 0) { val = {}; }
        return fb.group({
            animalId: val.animalId,
            caption: val.caption,
            result: val.result,
            state: val.state,
            reasonIsolated: val.reasonIsolated,
        });
    };
    EditAnimalEventCommonFormComponent.getAnimalEventGroupResultsArray = function (fb, serverSideErrorsProvider, animalEventGroupResults) {
        if (!animalEventGroupResults) {
            animalEventGroupResults = [];
        }
        return fb.array(animalEventGroupResults.map(function (el) { return EditAnimalEventCommonFormComponent.getAnimalEventGroupResultsDef(fb, el); }));
    };
    EditAnimalEventCommonFormComponent.getAnimalEventGroupResultsDef = function (fb, val) {
        if (val === void 0) { val = {}; }
        return fb.group({
            animalId: val.animalId,
            animalSubTypeId: val.animalSubTypeId,
            animalTypeId: val.animalTypeId,
            caption: val.caption,
            countPositive: val.countPositive,
            countNegative: val.countNegative,
            countRespond: val.countRespond,
            countExamined: val.countExamined,
            countIsolated: val.countIsolated,
            reasonIsolated: val.reasonIsolated,
            countDiff: val.countDiff,
            count: val.count,
        });
    };
    EditAnimalEventCommonFormComponent.prototype.diagnosticTypeIdChange = function (index) {
        if (!this.diagnosticTypeResults.controls[index].get('diagnosticTypeId').value) {
            if (index !== this.diagnosticTypeResults.length - 1) {
                this.diagnosticTypeResults.removeAt(index);
            }
        }
        else {
            if (index === this.diagnosticTypeResults.length - 1) {
                this.diagnosticTypeResults.push(this.fb.group(EditAnimalEventCommonFormComponent.getDiagnosticTypeResultsDef({})));
            }
        }
    };
    Object.defineProperty(EditAnimalEventCommonFormComponent.prototype, "files", {
        get: function () {
            return this.contextFormGroup.get('files');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditAnimalEventCommonFormComponent.prototype, "rangeTemperature", {
        get: function () {
            return this.contextFormGroup.get('rangeTemperature').value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditAnimalEventCommonFormComponent.prototype, "drugs", {
        get: function () {
            return this.contextFormGroup.get('drugs');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditAnimalEventCommonFormComponent.prototype, "diagnosticTypeResults", {
        get: function () {
            return this.contextFormGroup.get('diagnosticTypeResults');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditAnimalEventCommonFormComponent.prototype, "animals", {
        get: function () {
            return this.contextFormGroup.get('animals');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditAnimalEventCommonFormComponent.prototype, "animalEventSingleResults", {
        get: function () {
            return this.contextFormGroup.get('animalEventSingleResults');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditAnimalEventCommonFormComponent.prototype, "animalEventGroupResults", {
        get: function () {
            return this.contextFormGroup.get('animalEventGroupResults');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditAnimalEventCommonFormComponent.prototype, "counts", {
        get: function () {
            return this.contextFormGroup.get('counts');
        },
        enumerable: true,
        configurable: true
    });
    EditAnimalEventCommonFormComponent.prototype.searchDrug = function (fg) {
        this.appNavigationService.searchDrug(DrugSearchModalComponent, {})
            .subscribe(function (val) {
            if (val) {
                fg.get('drugId').setValue(val.id);
                fg.get('drugTypeId').setValue(val.drugTypeId);
                fg.markAsDirty();
            }
        });
    };
    EditAnimalEventCommonFormComponent.prototype.getRowStyle = function (params) {
        if (params.data && (params.data.deathDate || (params.data.animalGroup && !params.data.totalCount))) {
            return { color: 'silver' };
        }
    };
    EditAnimalEventCommonFormComponent.prototype.onGridReady = function (params) {
        params.api.sizeColumnsToFit();
    };
    EditAnimalEventCommonFormComponent.prototype.onGroupGridReady = function (params) {
        this.gridApiGroup = params.api;
        params.api.sizeColumnsToFit();
    };
    EditAnimalEventCommonFormComponent.prototype.onSingleGridReady = function (params) {
        this.gridApiSingle = params.api;
        params.api.sizeColumnsToFit();
    };
    EditAnimalEventCommonFormComponent.prototype.onGridSelectionChanged = function (params) {
        var _this = this;
        var selectedRows = params.api.getSelectedRows();
        this.animals.markAsDirty();
        var animalsToSave = [];
        selectedRows.forEach(function (row) {
            var find = _this.animals.controls.find(function (animal) { return animal.value === row.id; });
            if (!find) {
                animalsToSave.push(row.id);
                _this.animals.push(_this.fb.control(row.id));
            }
        });
        this.addToAnimalEventResults(animalsToSave);
    };
    EditAnimalEventCommonFormComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.hasOwnProperty('contextFormGroup')) {
            if (this.contextFormGroup && this.contextFormGroup !== this.boundContextFormGroup) {
                this.boundContextFormGroup = this.contextFormGroup;
                this.contextFormGroup.get('date').valueChanges.subscribe(function () { return setTimeout(function () { return _this.onEventDateChanged(); }, 100); });
                setTimeout(function () { return _this.onEventDateChanged(); }, 100);
            }
            if (this.animal && this.animal.id) {
                this.setAnimalTrappedToQuarantine();
            }
            else if (this.contextFormGroup.get('stableId').value) {
                this.setStableTrappedToQuarantine();
            }
            this.drugs.valueChanges.subscribe(function () { return _this.changeDrug(); });
            if (!this.contextFormGroup.get('id').value) {
                this.putAnimalsToResults();
            }
            if (!this.contextFormGroup.get('id').value) {
                this.changeResultLookupNameAndUpdateGrid();
            }
            else {
                this.changeResultItemCaption();
            }
            setTimeout(function () {
                if (_this.appGridSingleChild) {
                    _this.appGridSingleChild.recalculateHeightGrid();
                }
                if (_this.appGridGroupChild) {
                    _this.appGridGroupChild.recalculateHeightGrid();
                }
            }, 100);
            this.resultsCountsChanged.emit();
        }
    };
    EditAnimalEventCommonFormComponent.prototype.setAnimalTrappedToQuarantine = function () {
        var _this = this;
        var animal = this.dataCachingService.getCachedData('EditAnimalCommon', this.animal.id.toString());
        var animalTrappedToQuarantine = this.dataCachingService.getCachedData('EditAnimalCommon_trappedToQuarantine', this.animal.id.toString());
        if (animal && animal.contextFormGroup) {
            this.setTrappedToQuarantine(animal.contextFormGroup.value.trappedToQuarantine);
        }
        else if (animalTrappedToQuarantine) {
            this.setTrappedToQuarantine(animalTrappedToQuarantine);
        }
        else {
            this.quarantineDataService.getTrappedAnimal(this.animal.id, this.contextFormGroup.get('date').value).subscribe({
                next: function (data) {
                    _this.setTrappedToQuarantine(data);
                    _this.dataCachingService.addToCache('EditAnimalCommon_trappedToQuarantine', _this.animal.id.toString(), data);
                }
            });
        }
    };
    EditAnimalEventCommonFormComponent.prototype.setStableTrappedToQuarantine = function () {
        var _this = this;
        var stable = this.dataCachingService.getCachedData('EditStableCommon', this.contextFormGroup.get('stableId').value.toString());
        var stableTrappedToQuarantine = this.dataCachingService.getCachedData('EditStableCommon_trappedToQuarantine', this.contextFormGroup.get('stableId').value.toString());
        if (stable && stable.contextFormGroup) {
            this.setTrappedToQuarantine(stable.contextFormGroup.value.trappedToQuarantine);
        }
        else if (stableTrappedToQuarantine) {
            this.setTrappedToQuarantine(stableTrappedToQuarantine);
        }
        else {
            this.quarantineDataService.getTrappedStable(this.contextFormGroup.get('stableId').value, this.contextFormGroup.get('date').value)
                .subscribe({
                next: function (data) {
                    _this.setTrappedToQuarantine(data);
                }
            });
        }
    };
    EditAnimalEventCommonFormComponent.prototype.putAnimalsToResults = function () {
        var _this = this;
        if (!this.animals.length) {
            if (!this.animal) {
                return;
            }
            this.animals.push(this.fb.control(this.animal.id));
            if (this.animal.animalGroup) {
                if (!this.hasAnimalInResults(this.animal.id)) {
                    this.addToAnimalEventGroupResults(this.animal);
                }
            }
            else {
                if (!this.hasAnimalInResults(this.animal.id)) {
                    this.animalEventSingleResults.push(EditAnimalEventCommonFormComponent.getAnimalEventSingleResultsDef(this.fb, { animalId: this.animal.id, caption: this.animal.caption, result: null, state: null, reasonIsolated: null }));
                }
            }
        }
        else {
            if (this.animal) {
                var find = this.contextFormGroup.get('animals').value
                    .find(function (result) { return result === _this.animal.id; });
                if (!find) {
                    this.animals.push(this.fb.control(this.animal.id));
                }
            }
            this.contextFormGroup.get('animals').value.forEach(function (animal) {
                if (!_this.hasAnimalInResults(animal)) {
                    _this.addToAnimalEventResults([animal]);
                }
            });
        }
    };
    EditAnimalEventCommonFormComponent.prototype.hasAnimalInResults = function (animalId) {
        var animalEventSingleResult = this.contextFormGroup.get('animalEventSingleResults').value
            .find(function (result) { return result.animalId === animalId; });
        var animalEventGroupResult = this.contextFormGroup.get('animalEventGroupResults').value
            .find(function (result) { return result.animalId === animalId; });
        return !!(animalEventSingleResult || animalEventGroupResult);
    };
    EditAnimalEventCommonFormComponent.prototype.onEventDateChanged = function () {
        var _this = this;
        if (!this.animal || !this.animal.id || !this.contextFormGroup || !this.contextFormGroup.get('date').value ||
            this.lastLoadedCountsDate === this.contextFormGroup.get('date').value) {
            return;
        }
        if (!this.contextFormGroup.get('date').valid) {
            this.counts.controls.forEach(function (ctrl) {
                ctrl.get('countBefore').setValue(undefined);
            });
            return;
        }
        this.lastLoadedCountsDate = this.contextFormGroup.get('date').value;
        this.animalDataService.getAnimalCountsOnDate(this.animal.id, DateHelper.addDays(new Date(this.contextFormGroup.get('date').value), this.contextFormGroup.get('id').value ? 0 : 1), this.contextFormGroup.get('id').value).subscribe({
            next: function (countsArr) {
                _this.counts.controls.forEach(function (ctrl) {
                    ctrl.get('countBefore').setValue(undefined);
                });
                if (countsArr && countsArr.length) {
                    countsArr.forEach(function (el) {
                        var countFg = _this.counts.controls.find(function (ctrl) { return ctrl.get('animalSubTypeId').value === el.animalSubTypeId; });
                        if (!countFg) {
                            countFg = EditAnimalEventCommonFormComponent.buildAnimalEventCount(_this.fb, {
                                animalTypeId: el.animalTypeId,
                                animalSubTypeId: el.animalSubTypeId,
                                gender: el.gender,
                            });
                            _this.counts.push(countFg);
                        }
                        countFg.get('countBefore').setValue(el.count);
                    });
                }
            }
        });
    };
    EditAnimalEventCommonFormComponent.prototype.searchAgent = function () {
        var _this = this;
        this.appNavigationService.searchAgent(AgentSearchModalComponent).subscribe(function (val) {
            if (val) {
                _this.contextFormGroup.get('otherAgentId').setValue(val);
                _this.contextFormGroup.markAsDirty();
            }
        });
    };
    EditAnimalEventCommonFormComponent.prototype.searchStable = function () {
        var _this = this;
        this.cacheService.getAgent(this.contextFormGroup.get('otherAgentId').value).subscribe(function (agent) {
            _this.appNavigationService.searchStable(StableSearchModalComponent, {
                owner: agent.id ? agent.caption : '',
                agentId: agent.id
            })
                .subscribe(function (val) {
                if (val) {
                    _this.contextFormGroup.get('otherStableId').setValue(val);
                    _this.contextFormGroup.markAsDirty();
                }
            });
        });
    };
    EditAnimalEventCommonFormComponent.prototype.searchAnimal = function () {
        var _this = this;
        this.cacheService.getAgent(this.contextFormGroup.get('otherAgentId').value).subscribe(function (agent) {
            _this.appNavigationService.searchAnimal(AnimalSearchModalComponent)
                .subscribe(function (val) {
                if (val) {
                    _this.contextFormGroup.get('otherAnimalId').setValue(val.id);
                    _this.contextFormGroup.markAsDirty();
                }
            });
        });
    };
    EditAnimalEventCommonFormComponent.prototype.setStableAnimalMode = function () {
        var _this = this;
        this.contextFormGroup.get('animalStableMode').setValue(true);
        this.globalWaitingOverlayService.StartWaiting();
        this.animalDataService.searchByStable(this.animal.stableId, false)
            .subscribe({
            next: function (data) {
                _this.lookupSourceService.getLookupObj('animal-type').subscribe(function (animalTypeIdLookup) {
                    _this.lookupSourceService.getLookupObj('animal-subtype').subscribe(function (animalSubtypeIdLookup) {
                        _this.animal.searchResults = [];
                        data.forEach(function (el) {
                            if (el.animalGroup) {
                                return;
                            }
                            var animalSubtype = (animalSubtypeIdLookup[el.animalTypeId] || []).find(function (x) { return x.id === el.animalSubtypeId; });
                            el.animalTypeIdCaption = animalTypeIdLookup[el.animalTypeId];
                            el.animalSubtypeIdCaption = animalSubtype ? animalSubtype.caption : '<<не указан>>';
                            _this.animal.searchResults.push(el);
                        });
                        _this.globalWaitingOverlayService.EndWaiting();
                    });
                });
            }, error: function () {
                _this.globalWaitingOverlayService.EndWaiting();
            }
        });
    };
    EditAnimalEventCommonFormComponent.prototype.setTrappedToQuarantine = function (data) {
        var _this = this;
        if (!data) {
            return [];
        }
        this.trappedToQuarantine = [];
        var tmp = [];
        Object.keys(data).forEach(function (item) {
            _this.lookupSourceService.getLookupCaption(item, 'quarantine')
                .subscribe(function (caption) {
                var existing = tmp.find(function (el) { return el.id === item; });
                if (!existing) {
                    tmp.push({
                        id: item,
                        caption: caption
                    });
                }
                else {
                    existing.caption = caption;
                }
                if (tmp.length === Object.keys(data).length) {
                    _this.trappedToQuarantine = tmp;
                }
            });
        });
    };
    EditAnimalEventCommonFormComponent.prototype.addNewFile = function () {
        this.files.push(this.fb.group(EditAnimalEventCommonFormComponent.buildFormGroupFile(this.fb, this.model, {}, 'files-' + this.files.length)));
    };
    EditAnimalEventCommonFormComponent.prototype.getFormGroup = function (obj) {
        return obj;
    };
    EditAnimalEventCommonFormComponent.prototype.changeDrug = function () {
        var _this = this;
        this.availableDisease = null;
        this.availableEvents = null;
        this.drugs.getRawValue().forEach(function (drug, index) {
            if (drug.drugTypeId) {
                _this.lookupSourceService.getLookupObj('drug-type/' + drug.drugTypeId)
                    .subscribe(function (el) {
                    var _a, _b;
                    if (!_this.availableDisease) {
                        _this.availableDisease = [];
                    }
                    if (!_this.availableEvents) {
                        _this.availableEvents = [];
                    }
                    (_a = _this.availableDisease).push.apply(_a, el.diseaseTypeIds);
                    (_b = _this.availableEvents).push.apply(_b, el.eventTypeIds);
                    if (index === _this.drugs.length - 2) {
                        _this.availableDisease = !_this.availableDisease.length ? null : Array.from(new Set(_this.availableDisease));
                        _this.availableEvents = !_this.availableEvents.length ? null : Array.from(new Set(_this.availableEvents));
                    }
                });
            }
        });
    };
    EditAnimalEventCommonFormComponent.prototype.changeAddAllAvailableDiseases = function () {
        var _this = this;
        while (this.contextFormGroup.get('diseases').value.length) {
            this.contextFormGroup.get('diseases')
                .removeAt(this.contextFormGroup.get('diseases').value.length - 1);
        }
        this.contextFormGroup.get('diseaseTypeId').setValue(null);
        if (this.addAllAvailableDiseases) {
            this.availableDisease.forEach(function (diseaseId) {
                return _this.contextFormGroup.get('diseases').push(_this.fb.control(diseaseId));
            });
        }
    };
    EditAnimalEventCommonFormComponent.prototype.changeOwnDrug = function (index, event) {
        this.drugs.at(index).get('drugId').setValue(null);
        this.drugs.at(index).get('drugTypeId').setValue(null);
        if (event && this.drugs.length === 1) {
            this.drugs.at(0).get('__own_money').setValue(true);
        }
    };
    EditAnimalEventCommonFormComponent.prototype.getCaptionExamined = function (short) {
        if (short === void 0) { short = false; }
        var eventTypeId = this.contextFormGroup.get('id').value ? this.eventType.id : this.contextFormGroup.get('eventTypeId').value;
        var eventKind = this.contextFormGroup.get('id').value ? this.eventType.eventKind : this.contextFormGroup.get('eventKind').value;
        if (!eventTypeId || !eventKind) {
            return '';
        }
        return eventKind === 1
            ? eventTypeId === 32
                ? short ? 'Осм.' : 'Осмотрено'
                : short ? 'Иссл.' : 'Исследовано'
            : eventKind === 2
                ? short ? 'Пролеч.' : 'Пролечено'
                : eventTypeId === 13
                    ? short ? 'Иммун.' : 'Иммунизировано'
                    : short ? 'Обслуж.' : 'Обслужено';
    };
    EditAnimalEventCommonFormComponent.prototype.getCaptionIsolated = function (short) {
        if (short === void 0) { short = false; }
        if (!this.contextFormGroup.get('eventTypeId').value) {
            return '';
        }
        return this.contextFormGroup.get('eventTypeId').value === 32
            ? short ? 'Запрет' : 'Запрещено на убой' : short ? 'Изолир' : 'Изолировано';
    };
    EditAnimalEventCommonFormComponent.prototype.changeRangeTemperature = function () {
        this.contextFormGroup.get('rangeTemperature').setValue(!this.rangeTemperature);
        this.contextFormGroup.get('temperatureTo').setValue(null);
    };
    EditAnimalEventCommonFormComponent.prototype.changeResultItemCaption = function () {
        var _this = this;
        this.lookupSourceService.getLookup(this.equalsSome(this.contextFormGroup.get('eventTypeId').value, 32, 36) ? 'exam-result/preslaughter' : 'exam-result', true, true)
            .subscribe(function (results) {
            var find = results.find(function (item) { return item.id === 4; });
            if (find) {
                find.caption = _this.contextFormGroup.get('eventTypeId').value === 13
                    ? 'Иммунизировано'
                    : 'Обработано';
            }
            _this.availableResults = results;
        });
    };
    EditAnimalEventCommonFormComponent.prototype.changeResultLookupNameAndUpdateGrid = function () {
        var _this = this;
        if (this.contextFormGroup.get('eventTypeId').value === 32 || this.contextFormGroup.get('eventTypeId').value === 36) {
            this.gridResultSingleColDefs[1].lookupName = 'exam-result/preslaughter';
        }
        else {
            this.gridResultSingleColDefs[1].lookupName = 'exam-result';
        }
        if (this.gridApiGroup) {
            setTimeout(function () {
                _this.gridApiGroup.refreshHeader();
            }, 10);
            this.switchVisibalityReasonIsolated(this.gridApiGroup);
        }
        else {
            var groupColDef = this.gridResultGroupColDefs.find(function (def) { return def.field === 'reasonIsolated'; });
            groupColDef.hide = this.contextFormGroup.get('eventTypeId').value !== 32;
        }
        if (this.gridApiSingle) {
            this.switchVisibalityReasonIsolated(this.gridApiSingle);
        }
        else {
            var singleColDef = this.gridResultSingleColDefs.find(function (def) { return def.field === 'reasonIsolated'; });
            singleColDef.hide = this.contextFormGroup.get('eventTypeId').value !== 32;
        }
    };
    EditAnimalEventCommonFormComponent.prototype.switchVisibalityReasonIsolated = function (gridApi) {
        var reasonIsolatedColumn = gridApi.columnController.columnApi.getAllColumns()
            .find(function (x) { return x.colDef.field === 'reasonIsolated'; });
        if (this.contextFormGroup.get('eventTypeId').value === 32) {
            gridApi.columnController.columnApi.setColumnVisible(reasonIsolatedColumn, true);
        }
        else {
            gridApi.columnController.columnApi.setColumnVisible(reasonIsolatedColumn, false);
        }
    };
    EditAnimalEventCommonFormComponent.prototype.addToAnimalEventResults = function (animalIds) {
        var _this = this;
        animalIds.forEach(function (animal) {
            _this.animalDataService.getAnimalCommonForLookup(animal).subscribe(function (lookup) {
                if (lookup.animalGroup) {
                    _this.addToAnimalEventGroupResults(lookup);
                }
                else {
                    var group = EditAnimalEventCommonFormComponent.getAnimalEventSingleResultsDef(_this.fb, { animalId: animal, caption: lookup.caption, result: null, state: null, reasonIsolated: null });
                    _this.animalEventSingleResults.push(group);
                    if (_this.gridApiSingle) {
                        _this.gridApiSingle.updateRowData({ add: [group] });
                    }
                    setTimeout(function () {
                        if (_this.appGridSingleChild && _this.contextFormGroup.get('eventTypeId').value) {
                            _this.appGridSingleChild.recalculateHeightGrid();
                        }
                    }, 300);
                    _this.resultsCountsChanged.emit();
                }
            });
        });
    };
    EditAnimalEventCommonFormComponent.prototype.addToAnimalEventGroupResults = function (lookup) {
        var _this = this;
        this.animalDataService.getAnimalCountsOnDate(lookup.id, DateHelper.addDays(new Date(), 1), undefined)
            .subscribe({
            next: function (countsArr) {
                countsArr.forEach(function (count) {
                    _this.lookupSourceService.getLookupObj('animal-subtype').subscribe(function (animalSubtypeIdLookup) {
                        var animalSubtype = (animalSubtypeIdLookup[count.animalTypeId] || []).find(function (x) { return x.id === count.animalSubTypeId; });
                        if (animalSubtype) {
                            var group = EditAnimalEventCommonFormComponent.getAnimalEventGroupResultsDef(_this.fb, {
                                animalId: count.animalId,
                                animalTypeId: count.animalTypeId,
                                animalSubTypeId: count.animalSubTypeId,
                                caption: animalSubtype.caption + ' ' + count.count + ' шт. (' + lookup.caption + ')', countDiff: null,
                                countPositive: null, countNegative: null, countRespond: null, countExamined: null, countIsolated: null,
                                reasonIsolated: null, count: count.count
                            });
                            _this.animalEventGroupResults.push(group);
                            if (_this.gridApiGroup) {
                                _this.gridApiGroup.updateRowData({ add: [group] });
                            }
                            setTimeout(function () {
                                if (_this.appGridGroupChild && _this.contextFormGroup.get('eventTypeId').value) {
                                    _this.appGridGroupChild.recalculateHeightGrid();
                                }
                            }, 300);
                            _this.resultsCountsChanged.emit();
                        }
                    });
                });
            }
        });
    };
    EditAnimalEventCommonFormComponent.prototype.addManyAnimals = function () {
        var _this = this;
        this.appNavigationService.searchManyAnimals(AnimalSearchModalComponent, {}).subscribe(function (animals) {
            if (!animals || !animals.length) {
                return;
            }
            var animalsToSave = [];
            animals.forEach(function (el) {
                var find = _this.animals.controls.find(function (animal) { return animal.value === el; });
                if (!find) {
                    animalsToSave.push(el);
                    _this.animals.push(_this.fb.control(el));
                    _this.animals.markAsDirty();
                }
            });
            _this.addToAnimalEventResults(animalsToSave);
        });
    };
    EditAnimalEventCommonFormComponent.prototype.getLabelWithCounts = function (isGroup) {
        if (isGroup) {
            var totalGroupCount_1 = 0;
            this.animalEventGroupResults.controls.forEach(function (result) {
                totalGroupCount_1 += result.get('count').value;
            });
            return 'Групповой учет (добавлено ' + totalGroupCount_1 + ' жив. \\ ' +
                (this.animalEventGroupResults.controls.length === 1
                    ? '1 группа):'
                    : this.animalEventGroupResults.controls.length + ' групп):');
        }
        else {
            return 'Индивидуальный учет (добавлено ' + this.animalEventSingleResults.controls.length + ' жив.):';
        }
    };
    EditAnimalEventCommonFormComponent.prototype.deletingRow = function (data) {
        var index = this.animals.controls.findIndex(function (animal) { return animal.value === data.get('animalId').value; });
        if (index >= 0) {
            this.animals.removeAt(index);
        }
        this.resultsCountsChanged.emit();
    };
    EditAnimalEventCommonFormComponent.prototype.changeIsForced = function () {
        this.cdr.detach();
        this.contextFormGroup.get('isForced').setValue(!this.contextFormGroup.get('isForced').value);
        this.cdr.detectChanges();
        this.cdr.reattach();
    };
    return EditAnimalEventCommonFormComponent;
}());
export { EditAnimalEventCommonFormComponent };
