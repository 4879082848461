import { TicketsPlanningUiService } from './tickets-planning-ui-service';
import { FormHelper } from '../../../ui/controls/form-helper';
var TicketDeleteModalComponent = /** @class */ (function () {
    function TicketDeleteModalComponent(planningUi) {
        this.planningUi = planningUi;
        this.model = {};
        this.isInvalid = FormHelper.isInvalid;
    }
    Object.defineProperty(TicketDeleteModalComponent.prototype, "ticketFormGroup", {
        get: function () {
            return this.planningUi.ticketFormGroup;
        },
        enumerable: true,
        configurable: true
    });
    TicketDeleteModalComponent.prototype.undoModal = function () {
        this.ticketFormGroup.get('cancelCaption').setValue(null);
        this.planningUi.deleteTicketModalOpened = false;
        this.planningUi.editTicketModalOpened = true;
    };
    return TicketDeleteModalComponent;
}());
export { TicketDeleteModalComponent };
