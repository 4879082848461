<h1 class="itech-calendar-header">Производственный календарь на {{year}} год
  <clr-dropdown *ngIf="availableYears">
    <button class="btn btn-link" clrDropdownToggle>
      Другой год<clr-icon shape="caret down"></clr-icon>
    </button>
    <clr-dropdown-menu *clrIfOpen [clrPosition]="'bottom-left'">
      <label class="dropdown-header">Выберите другой год</label>
      <button *ngFor="let cyear of availableYears"
              type="button" clrDropdownItem (click)="changeYear(cyear)">
        <clr-icon shape="check" *ngIf="year==cyear"></clr-icon> {{cyear}}
      </button>
    </clr-dropdown-menu>
  </clr-dropdown>
</h1>
<div class="itech-calendar-cont">
  <div class="itech-calendar-months-wrap">
    <div class="itech-calendar-months-container">
      <div *ngFor="let month of months; index as i" class="itech-calendar-one-month">
        <h3>{{month.caption}}</h3>
        <div class="itech-calendar-week-headers">
          <div>Пн</div>
          <div>Вт</div>
          <div>Ср</div>
          <div>Чт</div>
          <div>Пт</div>
          <div class="itech-holiday">Сб</div>
          <div class="itech-holiday">Вс</div>
        </div>
        <div *ngFor="let week of month.weeks; index as j" class="itech-calendar-one-week">
          <div *ngFor="let day of week.days; index as k" class="itech-calendar-one-day" (click)="dayClick(day)"
               [class.itech-empty]="!day" [class.itech-holiday]="day? day.holiday: undefined"
               title="Щелкните, чтобы изменить рабочий/выходной">
            {{day? day.day: undefined}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
