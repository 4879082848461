<div class="itech-common-wrap no-pager">
  <div class="itech-v-header-content">
    <div class="itech-content-header">
      <clr-icon shape="file-group" size="36"></clr-icon>&nbsp;{{getMetaTitle()}}
    </div>

    <div style="padding: 5px; height: 90%">
      <app-grid [rowData]="_searchResults" [exportFileName]="getMetaTitle()" [uniqueGridName]="getMetaName()"
                (rowClicked)="editRow($event.data)" [columnDefs]="gridColumnDefs" [getRowStyle]="getRowStyle"></app-grid>
    </div>
  </div>
  <div class="itech-form-actions">
    <div>
      <button class="btn btn-link btn-primary" (click)="refreshResults()">
        <clr-icon shape="refresh" class="has-badge"></clr-icon>&nbsp;Обновить список
      </button>
      <button class="btn btn-link btn-primary" (click)="editRow({})">
        <clr-icon shape="plus" has-badge="true"></clr-icon>&nbsp;Добавить новую запись
      </button>
    </div>
  </div>
</div>


<clr-modal [(clrModalOpen)]="editModalOpened" *ngIf="editModalOpened" [clrModalClosable]="false" [clrModalSize]="'lg'">
  <h3 class="modal-title">Редактирование записи
    <span *ngIf="currentIsNew" style="color:green"> [Новая]</span></h3>
  <div class="modal-body" style="min-height:120px;overflow:hidden">
    <div class="form compact" [formGroup]="currentFormGroup">
      <section class="form-block itech-block-normal">
        <div class="form-group" style="padding-top:20px">
          <label for="id" class="required" style="margin-top:20px">Ид</label>
          <label for="id" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                 [class.invalid]="isInvalid(currentFormGroup, 'id')">
            <input type="text" id="id" placeholder="0000" class="itech-control-xlarge" formControlName="id"
                   [attr.disabled]="currentIsNew ? undefined : 1" (ngModelChange)="checkUniqueId($event, currentFormGroup)">
            <app-validation-tooltip [input]="currentFormGroup.controls['id']"></app-validation-tooltip>
          </label>
        </div>

        <div class="form-group">
          <label for="caption" class="required">Наименование</label>
          <label for="caption" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md tooltip-top-left"
                 [class.invalid]="isInvalid(currentFormGroup, 'caption')">
            <input type="text" id="caption" placeholder="Наименование" class="itech-control-xlarge"
                   formControlName="caption" (ngModelChange)="checkUniqueCaption($event, currentFormGroup)">
            <app-validation-tooltip [input]="currentFormGroup.controls['caption']"></app-validation-tooltip>
          </label>
        </div>

        <div class="form-group">
          <label for="dateDeleted">Дата удаления</label>
          <label for="dateDeleted" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md"
                 [class.invalid]="isInvalid(currentFormGroup, 'dateDeleted')">
            <input type="date" id="dateDeleted" formControlName="dateDeleted">
            <app-validation-tooltip [input]="currentFormGroup.controls['dateDeleted']"></app-validation-tooltip>
          </label>
        </div>
      </section>
    </div>
  </div>
  <div class="modal-footer itech-form-actions">
    <div>
      <button *ngIf="!currentIsNew" type="button" class="btn btn-warning-outline"
              (click)="delete(currentFormGroup.value)">
        <clr-icon shape="trash"></clr-icon>&nbsp;Удалить
      </button>
    </div>
    <div>
      <button type="button" class="btn btn-primary" (click)="addEditCompleted()"
              [disabled]="!currentFormGroup.dirty">
        <clr-icon shape="check"></clr-icon>&nbsp;Сохранить
      </button>
      <button type="button" class="btn btn-warning" (click)="editModalOpened = false">
        <clr-icon shape="undo"></clr-icon>&nbsp;Отмена
      </button>
    </div>
  </div>
</clr-modal>
