/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@clr/angular";
import * as i2 from "@angular/common";
import * as i3 from "./app-single-file.component";
import * as i4 from "../../logic/services/metadata.service";
import * as i5 from "../../logic/services/security.service";
import * as i6 from "../../logic/services/app-navigation.service";
var styles_AppSingleFileComponent = [];
var RenderType_AppSingleFileComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppSingleFileComponent, data: {} });
export { RenderType_AppSingleFileComponent as RenderType_AppSingleFileComponent };
function View_AppSingleFileComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-danger btn-link itech-inline-tool-btn"], ["style", "margin:0;vertical-align:top"], ["title", "\u0423\u0434\u0430\u043B\u0438\u0442\u044C \u0444\u0430\u0439\u043B"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteFile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "clr-icon", [["shape", "trash"], ["style", "transform: inherit;color: #d23b3b"]], null, null, null, null, null)), i0.ɵdid(2, 16384, null, 0, i1.ClrIconCustomTag, [], null, null)], null, null); }
function View_AppSingleFileComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "itech-form-group-one-line"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "a", [["class", "itech-link align-bottom"], ["style", "padding-right: 5px;color:#472cdc"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.previewFile(_co.contextFormGroup.get(_co.controlName).value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(3, null, [" ", "", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppSingleFileComponent_2)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.isAccessEdit; _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.preTitle; _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.index ? (_co.index + " ") : ""); var currVal_2 = _co.getPrettyTitle(_co.contextFormGroup.get(_co.controlName).value); _ck(_v, 3, 0, currVal_1, currVal_2); }); }
function View_AppSingleFileComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [[1, 0], ["newFileToUploadToDirectionResetFlag", 1]], null, 0, "input", [["style", "visibility: hidden;width: 0"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.fileChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_AppSingleFileComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "itech-form-group-one-line"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "label", [["style", "min-width:185px;margin-top:0"]], [[2, "invalid", null], [1, "for", 0]], null, null, null, null)), i0.ɵdid(2, 212992, null, 0, i1.ClrLabel, [[2, i1.ɵbe], [2, i1.ɵbf], [2, i1.ɵbg], i0.Renderer2, i0.ElementRef], { forAttr: [0, "forAttr"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 5, "a", [["class", "itech-link itech-control-normal"]], [[8, "id", 0], [4, "color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.fileUploadClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppSingleFileComponent_4)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 1, "clr-icon", [["shape", "upload"]], [[4, "color", null]], null, null, null, null)), i0.ɵdid(7, 16384, null, 0, i1.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, [" \u00A0\u0417\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044C \u043D\u043E\u0432\u044B\u0439 \u0444\u0430\u0439\u043B "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = i0.ɵinlineInterpolate(2, "", _co.controlName, "", _co.contextControlId, ""); _ck(_v, 2, 0, currVal_2); var currVal_5 = !_co.newFileToUploadToDirectionResetFlag; _ck(_v, 5, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isInvalid(_co.contextFormGroup, _co.controlName); var currVal_1 = i0.ɵnov(_v, 2).forAttr; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = i0.ɵinlineInterpolate(2, "", _co.controlName, "", _co.contextControlId, ""); var currVal_4 = (_co.contextFormGroup.get(_co.controlName).invalid ? "red" : ""); _ck(_v, 3, 0, currVal_3, currVal_4); var currVal_6 = (_co.contextFormGroup.get(_co.controlName).invalid ? "red" : "green"); _ck(_v, 6, 0, currVal_6); }); }
function View_AppSingleFileComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "itech-form-group-one-line"], ["style", "color: silver"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", "", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.preTitle; var currVal_1 = (_co.contextFormGroup.get(_co.controlName).value ? _co.getPrettyTitle(_co.contextFormGroup.get(_co.controlName).value) : "\u043E\u0442\u0441\u0443\u0442\u0441\u0442\u0432\u0443\u0435\u0442"); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_AppSingleFileComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { newFileToUploadToDirection: 1 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppSingleFileComponent_1)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppSingleFileComponent_3)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppSingleFileComponent_5)), i0.ɵdid(6, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.contextFormGroup.get(_co.controlName).value && (_co.isAccessView || _co.isAccessEdit)); _ck(_v, 2, 0, currVal_0); var currVal_1 = (!_co.contextFormGroup.get(_co.controlName).value && _co.isAccessEdit); _ck(_v, 4, 0, currVal_1); var currVal_2 = (!_co.isAccessEdit && !_co.isAccessView); _ck(_v, 6, 0, currVal_2); }, null); }
export function View_AppSingleFileComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-single-file", [], null, null, null, View_AppSingleFileComponent_0, RenderType_AppSingleFileComponent)), i0.ɵdid(1, 49152, null, 0, i3.AppSingleFileComponent, [i4.MetadataService, i5.SecurityService, i6.AppNavigationService], null, null)], null, null); }
var AppSingleFileComponentNgFactory = i0.ɵccf("app-single-file", i3.AppSingleFileComponent, View_AppSingleFileComponent_Host_0, { contextFormGroup: "contextFormGroup", controlName: "controlName", contextControlId: "contextControlId", userIdControlName: "userIdControlName", uriUpload: "uriUpload", index: "index", preTitle: "preTitle", isAccessEdit: "isAccessEdit", isAccessView: "isAccessView", downloadFileUri: "downloadFileUri" }, { changeFile: "changeFile" }, []);
export { AppSingleFileComponentNgFactory as AppSingleFileComponentNgFactory };
