import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { FormBuilder } from '@angular/forms';
import { GroupOperationReportsMetadataService } from './group-operation-reports-metadata.service';
import { AppVersionHistory } from '../../app.version-history';
import { AppNavigationService } from './app-navigation.service';
import { SecurityService } from './security.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/forms";
import * as i3 from "./security.service";
import * as i4 from "./group-operation-reports-metadata.service";
import * as i5 from "../../app.version-history";
import * as i6 from "./app-navigation.service";
/**
 * Для применения настроек отображения конкретного компонента страницы используй
 * userSettingService.getSetting('@namePage', '@nameComponent').@field
 * @namePage: string - наименование страницы, на которой расположен компонент
 * @nameComponent: string - наименование компонента
 * @field: property - параметр (конкретное поле компонента)
 * return: в соответствии с настройками для данного пользователя
 *        true - показать
 *        false - скрыть
 **/
var UserService = /** @class */ (function () {
    function UserService(http, fb, securityService, groupOperationReportsMetadataService, appVersionHistory, appNavigationService) {
        this.http = http;
        this.fb = fb;
        this.securityService = securityService;
        this.groupOperationReportsMetadataService = groupOperationReportsMetadataService;
        this.appVersionHistory = appVersionHistory;
        this.appNavigationService = appNavigationService;
        // эти параметры не подлежат изменению, они устанавливается автоматически из роли пользователя
        this.visibleAdministrationBlock = false;
        this.visibleObserverIpra = false;
        this.reportGroupsAccordingWithSetUi$ = new BehaviorSubject([]);
        this.updateSyncStatePending = false;
        this.getSettingsFromServer$();
    }
    UserService.defaultValueLayout = function (fb) {
        return {
            sidebar: fb.group({
                home: true,
                institution: true,
                query: true,
                paymentDocs: true,
                orders: true
            })
        };
    };
    UserService.defaultValueEditPerson = function (fb) {
        return {
            tabs: fb.group({
                common: true,
                docs: true,
                request: true,
                service: true,
                ipra: true,
                payment: true,
                scan: true,
                ipraResult: true
            })
        };
    };
    UserService.defaultValueDashboard = function (fb) {
        return {
            create: fb.group({
                newPerson: true,
                newInstitution: true
            }),
            query: fb.group({
                servicesCostTotal: true,
                servicesCostDetailed: true,
                stateOrder: true
            })
        };
    };
    UserService.defaultValueJobs = function (fb) {
        return [];
    };
    UserService.getDefaultValue = function (fb, namePage, nameComponent) {
        var resultDefaultValue;
        switch (namePage) {
            case 'layout':
                resultDefaultValue = nameComponent ?
                    UserService.defaultValueLayout(fb)[nameComponent] : UserService.defaultValueLayout(fb);
                break;
            case 'editPerson':
                resultDefaultValue = nameComponent ?
                    UserService.defaultValueEditPerson(fb)[nameComponent] : UserService.defaultValueEditPerson(fb);
                break;
            case 'jobs':
                resultDefaultValue = UserService.defaultValueJobs(fb);
                break;
            case 'dashboard':
                resultDefaultValue = nameComponent ?
                    UserService.defaultValueDashboard(fb)[nameComponent] : UserService.defaultValueDashboard(fb);
                break;
            default:
                resultDefaultValue = true;
        }
        return resultDefaultValue;
    };
    UserService.prototype.getSettingsFromServer$ = function () {
        var _this = this;
        if (!this.userSettingUi$) {
            this.userSettingUi$ = new BehaviorSubject({});
            this.securityService.getUserInfo().subscribe(function (userInfo) {
                if (userInfo && userInfo.userSettingUi) {
                    _this.applySettingToUi(userInfo.userSettingUi);
                }
            });
        }
        return this.userSettingUi$;
    };
    UserService.prototype.queryServerUserSetting$ = function () {
        return of({});
        // const qstring = environment.api + '/user-setting/ui';
        //
        // return this.http.get(qstring);
    };
    UserService.prototype.applySettingToUi = function (userSettingUi) {
        this.userSettingUi$.next(userSettingUi);
        this.visibleAdministrationBlock = userSettingUi.visibleAdministrationBlock
            ? userSettingUi.visibleAdministrationBlock : false;
        this.visibleObserverIpra = userSettingUi.visibleObserverIpra ? userSettingUi.visibleObserverIpra : false;
        this.loadAvailableReportData();
        this.checkVersionLastSession();
    };
    UserService.prototype.refreshSetting = function () {
        var _this = this;
        this.userSettingUi$ = new BehaviorSubject({});
        this.queryServerUserSetting$().subscribe(function (response) {
            _this.userSettingUi$.next(response);
            _this.loadAvailableReportData();
        });
    };
    UserService.prototype.storeUserSettings = function (settings) {
        var _this = this;
        var qstring = environment.api + '/user-setting/ui/store';
        return this.http.post(qstring, settings.value)
            .pipe(map(function (response) {
            _this.refreshSetting();
            return response;
        }));
    };
    // метод возвращает настройки отображения для |страницы/компонента страницы|,
    // если некоторые настройки отсутствуют, то он добавит их с настройками по умолчанию
    // если настройки вообще отсутствуют, то он вернет настройки по умолчанию
    UserService.prototype.getSetting = function (namePage, nameComponent) {
        if (!Object.keys(this.userSettingUi$.value).length) {
            return UserService.getDefaultValue(this.fb, namePage, nameComponent).value;
        }
        return (this.userSettingUi$.value[namePage] && this.userSettingUi$.value[namePage][nameComponent]) ?
            this.compareProperties(this.userSettingUi$.value, UserService.getDefaultValue(this.fb, namePage, nameComponent).value, namePage, nameComponent)
            : UserService.getDefaultValue(this.fb, namePage, nameComponent).value;
    };
    UserService.prototype.getSettingForEdit = function (namePage) {
        if (!Object.keys(this.userSettingUi$.value).length) {
            return this.fb.group(UserService.getDefaultValue(this.fb, namePage));
        }
        return this.fb.group(this.userSettingUi$.value[namePage] ?
            this.userSettingUi$.value[namePage] : UserService.getDefaultValue(this.fb, namePage));
    };
    UserService.prototype.getSettingForEditArray = function (namePage) {
        if (!Object.keys(this.userSettingUi$.value).length) {
            return this.fb.array(UserService.getDefaultValue(this.fb, namePage));
        }
        return this.fb.array(this.userSettingUi$.value[namePage] ?
            this.userSettingUi$.value[namePage] : UserService.getDefaultValue(this.fb, namePage));
    };
    UserService.prototype.compareProperties = function (val, defaultValue, namePage, nameComponent) {
        var prop = Object.keys(defaultValue);
        var currentProp = Object.keys(nameComponent ? val[namePage][nameComponent] : val[namePage]);
        if (!prop.length || prop.length === currentProp.length) {
            return nameComponent ? val[namePage][nameComponent] : val[namePage];
        }
        if (nameComponent) {
            for (var i = 0; i < prop.length; i++) {
                if (!val[namePage][nameComponent].hasOwnProperty(prop[i])) {
                    val[namePage][nameComponent][prop[i]] = true;
                }
            }
            return val[namePage][nameComponent];
        }
        else {
            for (var i = 0; i < prop.length; i++) {
                if (!val[namePage].hasOwnProperty(prop[i])) {
                    val[namePage][prop[i]] = true;
                }
            }
            return val[namePage];
        }
    };
    UserService.prototype.loadAvailableReportData = function () {
        var _this = this;
        this.userSettingUi$.subscribe(function (setUi) {
            _this.groupOperationReportsMetadataService.getMassReportGroups$().subscribe(function (massReportGroup) {
                var existing = [];
                massReportGroup.forEach(function (massReport) { return existing.push(Object.assign({}, massReport)); });
                existing.forEach(function (group) {
                    group.reports = group.reports.filter(function (report) {
                        return !_this.userSettingUi$.getValue().jobs || !_this.userSettingUi$.getValue().jobs.includes(report.id);
                    });
                });
                var reportGroupsAccordingSetUi = existing.filter(function (group) { return group.reports.length; });
                _this.reportGroupsAccordingWithSetUi$.next(reportGroupsAccordingSetUi);
            });
        });
    };
    UserService.prototype.getReportData$ = function () {
        return this.reportGroupsAccordingWithSetUi$;
    };
    UserService.prototype.checkVersionLastSession = function () {
        var lastVersion = localStorage.getItem('last-session-version');
        if (!lastVersion || this.appVersionHistory.versions[0].version !== lastVersion) {
            this.appNavigationService.versionHistoryOpened = true;
        }
        localStorage.setItem('last-session-version', this.appVersionHistory.versions[0].version);
    };
    UserService.prototype.getNotifications$ = function () {
        var _this = this;
        if (!this.userNotifications$) {
            this.userNotifications$ = new BehaviorSubject([]);
            this.updateSyncStateTimer = setInterval(function () { return _this.checkNewNotifications(); }, 10000);
            this.checkNewNotifications();
        }
        return this.userNotifications$;
    };
    UserService.prototype.checkNewNotifications = function () {
        var _this = this;
        if (this.updateSyncStatePending) {
            return;
        }
        this.updateSyncStatePending = true;
        this.getNotificationsFromServer()
            .subscribe(function (val) {
            val.forEach(function (x) {
                x.isNew = +x.stateId > 0;
                x.badge = +x.stateId > 0;
            });
            val = val.concat(_this.userNotifications$.value.filter(function (x) { return !val.some(function (s) { return +s.id === +x.id; }); }));
            _this.userNotifications$.next(val.sort(function (a, b) { return a.isNew ? -1 : 1; }));
            _this.updateSyncStatePending = false;
        }, function (error) { return _this.updateSyncStatePending = false; });
    };
    UserService.prototype.getNotificationsFromServer = function () {
        var qstring = environment.api + "/user-notification";
        return this.http.get(qstring)
            .pipe(map(function (response) {
            return response;
        }));
    };
    UserService.ngInjectableDef = i0.defineInjectable({ factory: function UserService_Factory() { return new UserService(i0.inject(i1.HttpClient), i0.inject(i2.FormBuilder), i0.inject(i3.SecurityService), i0.inject(i4.GroupOperationReportsMetadataService), i0.inject(i5.AppVersionHistory), i0.inject(i6.AppNavigationService)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
