import { Component, OnInit } from '@angular/core';
import { BookmarkService } from '../../logic/services/bookmark.service';
import { DataCachingService } from '../../logic/services/data-caching.service';

@Component({
  selector: 'app-personal-account',
  templateUrl: './personal-account-common.component.html',
})
export class PersonalAccountCommonComponent implements OnInit {
  model: any = PersonalAccountCommonComponent.createDefaultModel();

  static createDefaultModel(): any {
    return {
      favouriteStablesTabActive: true,
      reservedDrugsTabActive: false,
      tagsAutofillTabActive: false,
    };
  }

  constructor(private bookmarkService: BookmarkService,
              private dataCachingService: DataCachingService) {
  }

  ngOnInit() {

    const existing = this.dataCachingService.getCachedData('PersonalAccount', '1');

    if (existing) {
      this.model = existing;
    } else {
      this.model = PersonalAccountCommonComponent.createDefaultModel();
      this.dataCachingService.addToCache('PersonalAccount', '1', this.model);
    }

    this.bookmarkService.addDictionaryBookmark(
      'Личный кабинет',
      ['/personal-account']);
  }
}
