<div class="itech-common-wrap no-pager">
  <div>
    <app-product-search-results-form (rowClicked)="performItemEditing($event)" [model]="model" [refreshResultEvent]="refreshResultEvent">
    </app-product-search-results-form>
  </div>

  <div class="itech-form-actions">
    <div>
      <button class="btn btn-link btn-primary" (click)="appNavigationService.performProductCreation()">
        <clr-icon shape="truck" class="has-badge"></clr-icon>&nbsp;Зарегистр. документ (продукцию)
      </button>

      <clr-dropdown>
        <button class="btn btn-link btn-success" clrDropdownToggle
                [disabled]="model.selectedRows && model.selectedRows.length ? undefined : true">
          Действия
          <clr-icon shape="caret down"></clr-icon>
        </button>
        <clr-dropdown-menu *clrIfOpen [clrPosition]="'top-left'">
          <label class="dropdown-header">Выберите действие ({{model.selectedRows.length}} элемент(ов))</label>
          <button type="button" clrDropdownItem (click)="sendToRegistration()"
                  [disabled]="model.selectedRows && model.selectedRows.length ? undefined : true">Отправить на регистрацию</button>
          <button type="button" clrDropdownItem (click)="sendToClearance()"
                  [disabled]="model.selectedRows && model.selectedRows.length ? undefined : true">Погасить документы</button>
          <div class="dropdown-divider"></div>
          <button type="button" clrDropdownItem (click)="createTransportForSelectedDocuments()"
                  [disabled]="model.selectedRows && model.selectedRows.length ? undefined : true">Оформить транспортный ВСД</button>
          <div class="dropdown-divider"></div>
          <app-products-reports-button [model]="model">
          </app-products-reports-button>
          <div class="dropdown-divider"></div>
          <button type="button" clrDropdownItem (click)="deleteDocument()"
                  [disabled]="model.selectedRows && model.selectedRows.length ? undefined : true">Удалить документ(ы)</button>
        </clr-dropdown-menu>
      </clr-dropdown>
    </div>
  </div>
</div>
