import { OnInit } from '@angular/core';
import { AppNavigationService } from '../../logic/services/app-navigation.service';
import { QuarantineDataService } from '../../logic/services/quarantine-data.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { EditQuarantineCommonComponent } from './common/edit-quarantine-common.component';
import { FormHelper } from '../../ui/controls/form-helper';
import { DataCachingService } from '../../logic/services/data-caching.service';
import { BookmarkService } from '../../logic/services/bookmark.service';
import { StringHelper } from '../../helpers/string-helper';
var CreateQuarantineComponent = /** @class */ (function () {
    function CreateQuarantineComponent(fb, route, quarantineService, navigationService, bookmarkService, dataCachingService) {
        this.fb = fb;
        this.route = route;
        this.quarantineService = quarantineService;
        this.navigationService = navigationService;
        this.bookmarkService = bookmarkService;
        this.dataCachingService = dataCachingService;
        this.creating = false;
    }
    Object.defineProperty(CreateQuarantineComponent.prototype, "contextFormGroup", {
        get: function () {
            return this.model ? this.model.form : undefined;
        },
        enumerable: true,
        configurable: true
    });
    CreateQuarantineComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.paramMap
            .subscribe(function (params) {
            var randomUniqueTag = params.get('randomUniqueTag');
            var paramDiseaseTypeId = params.get('diseaseTypeId') ? +params.get('diseaseTypeId') : undefined;
            var existing = _this.dataCachingService.getCachedData('CreateQuarantine', randomUniqueTag);
            if (!existing) {
                _this.model = {
                    randomUniqueTag: randomUniqueTag,
                    serverSideValidationErrors: _this.serverSideValidationErrors
                };
                _this.model.form = EditQuarantineCommonComponent.createFormGroupCommon(_this.fb, _this.model, {
                    diseaseTypeId: paramDiseaseTypeId,
                });
                _this.dataCachingService.addToCache('CreateQuarantine', randomUniqueTag, _this.model, 10);
            }
            else {
                _this.model = existing;
            }
            _this.refreshCurrentBookmark();
        });
    };
    CreateQuarantineComponent.prototype.createQuarantine = function () {
        var _this = this;
        FormHelper.markAsSubmitted(this.contextFormGroup);
        if (this.contextFormGroup.invalid) {
            FormHelper.setFocusToInvalidControl(this.contextFormGroup);
            return;
        }
        this.quarantineService.storeCommon(this.contextFormGroup.value)
            .subscribe(function (res) {
            FormHelper.setSingleFormGroupServerSideValidationErrors({}, _this.model, _this.contextFormGroup);
            _this.bookmarkService.removeOpenCardBookmark(['/quarantine-create'], _this.model.randomUniqueTag);
            _this.dataCachingService.removeCachedData('CreateQuarantine', _this.model.randomUniqueTag);
            _this.dataCachingService.addToCache('EditQuarantine_Data', res.data.data.id.toString(), res.data);
            _this.navigationService.performQuarantineEditing(res.data.data.id);
        }, function (error) { return FormHelper.setSingleFormGroupServerSideValidationErrorsWithRelationMsgKey(error, _this.model, _this.contextFormGroup); });
    };
    CreateQuarantineComponent.prototype.refreshCurrentBookmark = function (skipIfNotExists) {
        this.bookmarkService.addOpenCardBookmark(this.model.randomUniqueTag, "\u041D\u043E\u0432\u044B\u0439 \u043A\u0430\u0440\u0430\u043D\u0442\u0438\u043D" +
            (this.model.form.get('dateFrom').value ? ' от ' + StringHelper.getRuDate(this.model.form.get('dateFrom').value) : ''), ['/quarantine-create', this.model.randomUniqueTag], 'flame', skipIfNotExists);
    };
    return CreateQuarantineComponent;
}());
export { CreateQuarantineComponent };
