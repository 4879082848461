import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';

@Component({
  selector: 'app-ag-grid-button-renderer',
  template: `
      <button type="button" class="btn btn-link" *ngIf="params" (click)="clicked()" [title]="params.title" [disabled]="params.disabled">
          <clr-icon *ngIf="params.icon === 'plus'" shape="plus"></clr-icon>
          <clr-icon *ngIf="params.icon === 'copy'" shape="copy"></clr-icon>
          <clr-icon *ngIf="params.icon === 'trash'" style="color:#d23b3b" shape="trash"></clr-icon>
          <clr-icon *ngIf="params.icon === 'angle left'" shape="angle left"></clr-icon>
          <clr-icon *ngIf="params.icon === 'angle down'" shape="angle down"></clr-icon>
          <clr-icon *ngIf="params.icon === 'times'" style="color:#d23b3b" shape="times"></clr-icon>
          {{params.buttonCaption}}
      </button>
  `,
})
export class AgGridButtonRendererComponent implements ICellRendererAngularComp {
  public params: any;

  agInit(params: any): void {
    this.params = params;
  }

  clicked() {
    this.params.onClickCallback(this.params);
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }
}
