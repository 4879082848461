
    <ng-container *ngFor="let item of boundArray.controls; index as i">
      <app-combo-lookup [contextControlId]="contextControlName + i"
                        [contextControlName]="i" [required]="false"
                        [lookupName]="lookupName" [sorted]="sorted" [disabled]="disabled"
                        [contextFormGroup]="boundArray"></app-combo-lookup>
      <ng-container *ngIf="!noControlsDivider">&nbsp;&nbsp;</ng-container>
    </ng-container>
    <app-combo-lookup [contextControlId]="contextControlName + 'N'"
                      [contextControlName]="'value'" [required]="false"
                      [lookupName]="lookupName" [sorted]="sorted" [disabled]="disabled"
                      [contextFormGroup]="emptyElementForm"></app-combo-lookup>
  