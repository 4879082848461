import { Component } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { IModalBody, ModalResult } from '../../../logic/services/app-navigation.service.models';
import { Observable } from 'rxjs';
import {FormHelper} from '../../../ui/controls/form-helper';
import {MetadataService} from '../../../logic/services/metadata.service';
import {map} from 'rxjs/operators';
import {DrugTypeBrowseFormComponent} from './drug-type-browse-form.component';
import {LookupSourceService} from '../../../logic/services/lookup-source.service';

@Component({
  template: `<app-drug-type-browse-form [currentFormGroup]="currentFormGroup"></app-drug-type-browse-form>`
})
export class DrugTypeBrowseCreateModalComponent implements IModalBody<any, number> {

  model: any = {};
  currentFormGroup: FormGroup;

  constructor(private fb: FormBuilder,
              private metadataService: MetadataService,
              private lookupService: LookupSourceService) {
  }

  initModalBody(data: any) {
    this.currentFormGroup = this.fb.group(DrugTypeBrowseFormComponent.getGroupDef(this.fb, data));
  }

  onModalAccept$(): Observable<ModalResult<number>> {

    FormHelper.markAsSubmitted(this.currentFormGroup);

    if (!this.currentFormGroup.valid) {
      return;
    }

    return this.metadataService.createMeta('drug-type', this.currentFormGroup.value)
      .pipe(map((response: any) => {
        this.lookupService.invalidateLookup('drug-type');
        FormHelper.setSingleFormGroupServerSideValidationErrors({}, this.model, this.currentFormGroup);
        return new ModalResult(true, response.data.id);
      },
      err => FormHelper.setSingleFormGroupServerSideValidationErrors(err, this.model, this.currentFormGroup)));
  }
}
