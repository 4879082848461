<div class="form compact">
  <app-query-details-agent-fields *ngIf="agentContextFormGroup" [contextFormGroup]="agentContextFormGroup"
                                  [queryUserMode]="query.userParamConditionsMap['agent'].data"></app-query-details-agent-fields>
  <app-query-details-stable-fields *ngIf="stableContextFormGroup" [contextFormGroup]="stableContextFormGroup"
                                   [queryUserMode]="query.userParamConditionsMap['stable'].data"></app-query-details-stable-fields>
  <app-query-details-event-fields *ngIf="eventContextFormGroup" [contextFormGroup]="eventContextFormGroup"
                                  [queryUserMode]="query.userParamConditionsMap['event'].data"></app-query-details-event-fields>
  <app-query-details-animal-fields *ngIf="animalContextFormGroup" [contextFormGroup]="animalContextFormGroup"
                                   [queryUserMode]="query.userParamConditionsMap['animal'].data"></app-query-details-animal-fields>
  <app-query-details-product-fields *ngIf="productContextFormGroup" [contextFormGroup]="productContextFormGroup"
                                    [queryUserMode]="query.userParamConditionsMap['product'].data"></app-query-details-product-fields>
  <app-query-details-quarantine-fields *ngIf="quarantineContextFormGroup"
                                       [contextFormGroup]="quarantineContextFormGroup"
                                       [queryUserMode]="query.userParamConditionsMap['quarantine'].data"></app-query-details-quarantine-fields>
  <app-query-details-institution-ticket-fields *ngIf="institutionTicketContextFormGroup"
                                               [contextFormGroup]="institutionTicketContextFormGroup"
                                               [queryUserMode]="query.userParamConditionsMap['institution-ticket'].data"></app-query-details-institution-ticket-fields>
  <app-query-details-drug-fields *ngIf="drugContextFormGroup" [contextFormGroup]="drugContextFormGroup"
                                 [queryUserMode]="query.userParamConditionsMap['drug'].data"></app-query-details-drug-fields>
</div>
