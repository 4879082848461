import { Component, Input, OnInit } from '@angular/core';
import { LookupSourceService } from '../../logic/services/lookup-source.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormArray } from '@angular/forms';

@Component({
  selector: 'app-ur-choose-object-meta-combine-key',
  template: `
    <div class="form-group itech-group-whole-line" [formGroup]="item.formGroup">
      <label>
      </label>
      <div class="radio-inline">
        <input type="radio" id="{{metaGroupName}}Kind1{{groupId}}"
               formControlName="{{metaGroupName}}Kind" [value]="1">
        <label for="{{metaGroupName}}Kind1{{groupId}}">Разрешить:</label>
      </div>
      <div class="radio-inline">
        <input type="radio" id="{{metaGroupName}}Kind2{{groupId}}"
               formControlName="{{metaGroupName}}Kind" [value]="2">
        <label for="{{metaGroupName}}Kind2{{groupId}}">Разрешить все кроме:</label>
      </div>
      <div class="radio-inline">
        <input type="radio" id="{{metaGroupName}}Kind3{{groupId}}"
               formControlName="{{metaGroupName}}Kind" [value]="3">
        <label for="{{metaGroupName}}Kind3{{groupId}}">Не проверять:</label>
      </div>
    </div>

    <div class="form-group itech-group-whole-line" *ngIf="item.toggled && item.func.metaNameCombineKey1"
         id="{{metaGroupName}}dictionary{{groupId}}">
      <label>
      </label>
      <div *ngFor="let parent of (lookupParent() | async)"
           [style.width]="parent.expanded ? '100%' : undefined">
        <button type="button" class="btn btn-outline"
                [disabled]="item.formGroup.get(metaGroupName + 'Kind').value == 3? true: undefined"
                style="background:none;border:none;margin:0;text-transform:none;color:black;letter-spacing:normal"
                (click)="parent.expanded = !parent.expanded">
          {{ parent.caption }}
          <span style="font-size:11px;color:blue">
              ({{selectedIds[parent.id] ? (selectedIds[parent.id].length) : (0) }})
          </span>
          <clr-icon *ngIf="parent.expanded" shape="angle up"></clr-icon>
          <clr-icon *ngIf="!parent.expanded" shape="angle down"></clr-icon>
        </button>
        <div *ngIf="parent.expanded">
          <app-checkbox-select [lookupName]="lookupName + parent.id" [contextControlName]="metaGroupName"
                               [contextFormGroup]="item.formGroup" [sorted]="true" [nameId]="'uniqueId'"
                               [disabled]="item.formGroup.get(metaGroupName + 'Kind').value == 3? true: undefined">
          </app-checkbox-select>
        </div>
      </div>
    </div>
  `
})
export class AppUrChooseObjectMetaCombineKeyComponent implements OnInit {

  @Input() item;
  @Input() metaGroupName;
  @Input() groupId;
  @Input() lookupName;
  @Input() lookupNameParent;

  selectedIds = {};

  parentLookupItems$ = undefined;

  constructor(private lookupService: LookupSourceService) {
  }

  ngOnInit(): void {
    if (!this.lookupName) {
      return;
    }

    (this.item.formGroup.get(this.metaGroupName) as FormArray)
      .getRawValue().forEach(stringId => this.updateSelectedIds(stringId));

    // прогрузим справочник заранее
    this.lookupService.getLookup(this.lookupName).toPromise().then();

    // при каждой отметке/снятии будем изменять наше описание о количестве выделенных элементов
    this.item.formGroup.get(this.metaGroupName).valueChanges.subscribe(stringIds => {
      this.selectedIds = {};
      (stringIds as any[]).forEach(stringId => this.updateSelectedIds(stringId));
    });
  }

  private updateSelectedIds(stringId: string) {
    if (!stringId) {
      return;
    }
    const parentId = +(stringId.substring(0, stringId.length - 9));
    const id = +(stringId.substring(stringId.length - 9, stringId.length));

    if (!this.selectedIds[parentId]) {
      this.selectedIds[parentId] = [];
    }

    (this.selectedIds[parentId] as any[]).push(id);
  }

  private lookupParent(): Observable<any[]> {

    if (this.parentLookupItems$) {
      return this.parentLookupItems$;
    } else {
      this.parentLookupItems$ = new BehaviorSubject([]);
      this.lookupService.getLookup(this.lookupNameParent)
        .subscribe(response => {
          response.forEach(el => el.expanded = false);
          response.sort((a, b) => a.caption.localeCompare(b.caption));
          this.parentLookupItems$.next(JSON.parse(JSON.stringify(response)));
        });
      return this.parentLookupItems$;
    }
  }
}
