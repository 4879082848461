import { Component, OnInit } from '@angular/core';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { AgentSearchResultsFormComponent } from './agent-search-results-form.component';
import { FormBuilder } from '@angular/forms';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { AgentDataService } from '../../../logic/services/agent-data.service';
import { IModalBody, ModalResult } from '../../../logic/services/app-navigation.service.models';
import { Observable } from 'rxjs';

@Component({
  template: `
    <div style="height: 600px; background-color: white; position: relative;">
      <app-agent-search-results-form
        [model]="model" [availableChangeStableId]="model.filterFormGroup.get('availableChangeStableId').value"
        (rowClicked)="chooseAgent($event)" [createdAgentCallback]="createdCallback">
      </app-agent-search-results-form>
    </div>
  `
})
export class AgentSearchModalComponent implements OnInit, IModalBody<any, void> {

  model = AgentSearchResultsFormComponent.getDefaultModel();

  constructor(public appNavigationService: AppNavigationService,
              private fb: FormBuilder, private alertService: AlertService,
              private agentDataService: AgentDataService) {
  }

  ngOnInit() {
    this.model = AgentSearchResultsFormComponent.getDefaultModel(this.fb, this.appNavigationService.customModalParams);
  }

  chooseAgent(agentData: any) {

    if (agentData.id) {
      this.appNavigationService.customModalAcceptExternalPromiseNumber.next(agentData.id);
      this.appNavigationService.customModalOpened = false;
    } else if (agentData.mercurySearchedUuid) {
      this.alertService.confirmModal('Нажмите "Продолжить", чтобы создать карточку хозяйствующего субъекта на ' +
        'основе данных в системе Меркурий', 'Продолжить').subscribe(() => {
        this.agentDataService.createByMercuryGuid(agentData.mercurySearchedUuid).subscribe(newAgentData => {
          this.appNavigationService.customModalAcceptExternalPromiseNumber.next(newAgentData.id);
          this.appNavigationService.customModalOpened = false;
        });
      });
    }
  }

  createdCallback(createdAgent: any) {
    this.appNavigationService.customModalAcceptExternalPromiseNumber.next(createdAgent.id);
    this.appNavigationService.customModalOpened = false;
  }

  initModalBody(data: any) {
  }

  onModalAccept$(): Observable<ModalResult<void>> {
    return undefined;
  }
}
