import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { v4 as Uuid } from 'uuid';
import { environment } from '../../environments/environment';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { StringHelper } from '../helpers/string-helper';
import { SecurityService } from '../logic/services/security.service';
import { AlertService } from '../ui/infrastructure/alert.service';
import { first } from 'rxjs/internal/operators';
import { Router } from '@angular/router';
var EsiaService = /** @class */ (function () {
    function EsiaService(injector) {
        this.injector = injector;
        this.authMultipleInstitutionForUser = false;
        if (!EsiaService.auth) {
            EsiaService.auth = JSON.parse(localStorage.getItem('esia'));
        }
    }
    Object.defineProperty(EsiaService.prototype, "http", {
        get: function () {
            return !this._http ? this._http = this.injector.get(HttpClient) : this._http;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EsiaService.prototype, "securityService", {
        get: function () {
            return !this._securityService ? this._securityService = this.injector.get(SecurityService) : this._securityService;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EsiaService.prototype, "alertService", {
        get: function () {
            return !this._alertService ? this._alertService = this.injector.get(AlertService) : this._alertService;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EsiaService.prototype, "router", {
        get: function () {
            return !this._router ? this._router = this.injector.get(Router) : this._router;
        },
        enumerable: true,
        configurable: true
    });
    EsiaService.clear = function (redirectToRoot) {
        EsiaService.auth = null;
        localStorage.removeItem('esia');
        localStorage.removeItem('state');
        if (redirectToRoot) {
            window.location.href = document.baseURI;
        }
    };
    EsiaService.validateState = function (state) {
        var storedState = localStorage.getItem('state');
        var result = storedState === state;
        if (storedState) {
            localStorage.removeItem('state');
        }
        return result;
    };
    EsiaService.prototype.init = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var code = StringHelper.getQueryParam('code');
            var state = StringHelper.getQueryParam('state');
            // Это не редирект от ЕСИА
            if (!code || !state) {
                if (!_this.loggedIn()) {
                    _this.login(); // Получаем url для аутентификации и переходим на него
                }
                else {
                    resolve(); // Мы уже вошли
                }
            }
            else { // А это редирект
                if (!EsiaService.validateState(state)) {
                    reject('Invalid state received');
                    window.location.href = document.baseURI;
                    return;
                }
                console.log('Callback is OK');
                _this.authenticate(code)
                    .subscribe(function (data) { return data && data.notFound ? reject(data.notFound) : resolve(); }, function (error) {
                    reject(error);
                    window.location.href = document.baseURI;
                });
            }
        });
    };
    EsiaService.prototype.loggedIn = function () {
        return EsiaService.auth !== null && EsiaService.auth.accessToken !== null && EsiaService.auth.loggedIn;
    };
    // Запуск процесса входа, редирект на сайт ЕСИА. ЕСИА должен сделать редирект к EsiaCallback
    EsiaService.prototype.login = function () {
        EsiaService.clear(false);
        this.getLoginUrl().subscribe(function (value) {
            if (!environment.production) {
                console.log(value.url);
            }
            window.location.href = value.url;
        });
    };
    EsiaService.prototype.authenticate = function (code, impersonateAsUserId, institutionId) {
        var _this = this;
        var params = { 'code': code, redirectUri: EsiaService.REDIRECT_URI };
        if (impersonateAsUserId) {
            params.impersonateAsUserId = impersonateAsUserId;
        }
        if (institutionId) {
            params.institutionId = institutionId;
        }
        if (EsiaService.auth && EsiaService.auth.accessToken) {
            params.accessToken = EsiaService.auth.accessToken;
        }
        return forkJoin([this.getLogoutUrlDirect().pipe(first()), this.getAuth(params).pipe(first())])
            .pipe(map(function (_a) {
            var logoutUrl = _a[0], authData = _a[1];
            if (authData.notFound) {
                try {
                    var err = JSON.parse(authData.notFound);
                    _this.authMultipleInstitutionForUser = true;
                    if (err.accessToken) {
                        _this.setAuth(err.accessToken, false, params.institutionId);
                    }
                    return _this.router.navigate(['esia/auth/found-multiple'], { state: { data: { authData: err, logoutUrl: logoutUrl } } }).then();
                }
                catch (err) {
                    _this.showNotFoundMessage(authData.notFound, logoutUrl);
                }
            }
            return authData;
        }));
    };
    EsiaService.prototype.getAuth = function (params) {
        var _this = this;
        return this.http.get(environment.api + "/esia/" + (!params.institutionId ? 'auth' : 'auth-by-institution-id'), { params: params })
            .pipe(map(function (data) {
            if (data.notFound) {
                return data;
            }
            EsiaService.auth = { accessToken: data.accessToken, loggedIn: true, authInstitutionId: params.institutionId };
            localStorage.setItem('esia', JSON.stringify(EsiaService.auth));
            _this.securityService.invalidateCurrentUser();
            return data;
        }));
    };
    EsiaService.prototype.impersonate = function (impersonateAsUserId) {
        var _this = this;
        var params = impersonateAsUserId ? { impersonateAsUserId: impersonateAsUserId } : {};
        return this.http.get(environment.api + "/esia/impersonate", { params: params })
            .pipe(map(function (data) {
            if (data.notFound) {
                _this.alertService.error(data.notFound);
            }
            else {
                EsiaService.auth = { accessToken: data.accessToken, loggedIn: true, authInstitutionId: EsiaService.auth.authInstitutionId };
                localStorage.setItem('esia', JSON.stringify(EsiaService.auth));
                _this.securityService.invalidateCurrentUser();
                _this.alertService.success('Имперсонализация прошла успешно!');
            }
            return data;
        }));
    };
    EsiaService.prototype.getToken = function () {
        if (!this.loggedIn()) {
            throw new Error('Cant`t get a token. Not logged in.');
        }
        return EsiaService.auth.accessToken;
    };
    EsiaService.prototype.getAuthInstitutionId = function () {
        return EsiaService.auth.authInstitutionId;
    };
    EsiaService.prototype.logout = function () {
        var _this = this;
        console.log('*** LOGOUT');
        localStorage.removeItem('esia');
        localStorage.removeItem('state');
        this.getLogoutUrl()
            .subscribe({
            next: function (url) {
                _this.securityService.markCurrentUserLoggingOut();
                window.location.href = url;
            },
            error: function (error) {
                console.log(error);
                window.location.href = document.baseURI;
            },
            complete: function () {
                EsiaService.auth = null;
                _this.authMultipleInstitutionForUser = false;
            }
        });
    };
    EsiaService.prototype.getLoginUrl = function () {
        var state = Uuid();
        localStorage.setItem('state', state);
        return this.http.get(environment.api + "/esia/get-login-url", { params: { 'state': state, 'redirectUri': EsiaService.REDIRECT_URI } });
    };
    EsiaService.prototype.getLogoutUrl = function () {
        return this.http.get(environment.api + "/esia/logout", { params: { 'redirectUri': document.baseURI } })
            .pipe(map(function (value) { return value.url; }));
    };
    EsiaService.prototype.getLogoutUrlDirect = function () {
        return this.http.get(environment.api + "/esia/get-logout-url", { params: { 'redirectUri': document.baseURI } })
            .pipe(map(function (value) { return value.url; }));
    };
    EsiaService.prototype.setAuth = function (accessToken, loggedIn, authInstitutionId) {
        EsiaService.auth = { accessToken: accessToken, loggedIn: loggedIn, authInstitutionId: authInstitutionId };
        localStorage.setItem('esia', JSON.stringify(EsiaService.auth));
        this.securityService.invalidateCurrentUser();
    };
    EsiaService.prototype.showNotFoundMessage = function (reason, logoutUrl) {
        var html = "<div>\n  <h1>\u0412\u0445\u043E\u0434 \u043D\u0435 \u0432\u044B\u043F\u043E\u043B\u043D\u0435\u043D</h1>\n  <h4 id=\"reason\">" + reason + "</h4>\n  <p>\u0415\u0441\u043B\u0438 \u0445\u043E\u0442\u0438\u0442\u0435 \u0432\u043E\u0439\u0442\u0438 \u0447\u0435\u0440\u0435\u0437 \u0434\u0440\u0443\u0433\u0443\u044E \u0443\u0447\u0435\u0442\u043D\u0443\u044E \u0437\u0430\u043F\u0438\u0441\u044C, \u0432\u044B\u0439\u0434\u0438\u0442\u0435 \u0438\u0437 \u0442\u0435\u043A\u0443\u0449\u0435\u0439</p>\n  <button class=\"btn btn-primary itech-link\" onclick=\"window.location.href = '" + logoutUrl + "'\">\u0412\u044B\u0439\u0442\u0438 \u0438\u0437 \u0413\u043E\u0441\u0443\u0441\u043B\u0443\u0433</button>\n</div>";
        var node = document.createElement('div');
        node.id = 'not-logged-in';
        node.style.height = '100%';
        node.innerHTML = html;
        var loadContainer = document.getElementById('load-container');
        if (!loadContainer) {
            loadContainer = document.getElementsByTagName('app-layout')[0];
        }
        var appRoot = loadContainer.parentNode;
        appRoot.removeChild(loadContainer);
        appRoot.appendChild(node);
    };
    EsiaService.REDIRECT_URI = document.baseURI;
    return EsiaService;
}());
export { EsiaService };
