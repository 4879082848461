import { Subject } from 'rxjs';
var GlobalWaitingOverlayService = /** @class */ (function () {
    function GlobalWaitingOverlayService() {
        this._waitingPromise = new Subject();
    }
    Object.defineProperty(GlobalWaitingOverlayService.prototype, "waiting", {
        get: function () {
            return this._waitingPromise;
        },
        enumerable: true,
        configurable: true
    });
    GlobalWaitingOverlayService.prototype.StartWaiting = function () {
        var _this = this;
        setTimeout(function () {
            _this._waitingPromise.next(true);
        }, 10);
    };
    GlobalWaitingOverlayService.prototype.EndWaiting = function () {
        var _this = this;
        setTimeout(function () {
            _this._waitingPromise.next(false);
        }, 10);
    };
    return GlobalWaitingOverlayService;
}());
export { GlobalWaitingOverlayService };
