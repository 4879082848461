import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { MetadataService } from '../../../logic/services/metadata.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { environment } from '../../../../environments/environment';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { BookmarkService } from '../../../logic/services/bookmark.service';

@Component({
  templateUrl: './reports-browse.component.html'
})
export class ReportsBrowseComponent implements OnInit {

  _searchResults: any[] = [];

  gridColumnDefs = [
    {headerName: '№', field: 'id', width: 80},
    {headerName: 'Код области применения', field: 'reportKind', tooltipField: 'reportKind', width: 120},
    {headerName: 'Наименование', field: 'caption', tooltipField: 'caption', width: 350},
    {
      headerName: 'Выходной формат', field: 'outputFormat', width: 200,
      valueFormatter: params => this.reportOutputFormatLookup[params.value]
    },
    {
      headerName: 'Пользовательский (да/нет)', field: 'isCustom', width: 120,
      cellRenderer: params => params.data.isCustom ? '<clr-icon shape="check"></clr-icon>' : '',
      cellStyle: {'text-align': 'center'}
    },
  ];

  reportOutputFormatLookup: any = {};

  currentFormGroup: FormGroup;
  currentIsNew = false;
  editModalOpened = false;
  newlyEnteredFile: any;

  commonTabActive = true;
  scriptsTabActive = false;

  constructor(private route: ActivatedRoute,
              private metadataService: MetadataService,
              private bookmarkService: BookmarkService,
              private alertService: AlertService,
              private globalWaitingOverlayService: GlobalWaitingOverlayService,
              private lookupService: LookupSourceService,
              private fb: FormBuilder) {
    this.lookupService.getLookupObj('report-output-format').subscribe(lookup => this.reportOutputFormatLookup = lookup);
  }

  refreshResults() {
    this.globalWaitingOverlayService.StartWaiting();

    this.metadataService.getAllReports()
      .subscribe({
        next: data => {
          this._searchResults = data;
          this.globalWaitingOverlayService.EndWaiting();
        }, error: () => {
          this.globalWaitingOverlayService.EndWaiting();
        }
      });
  }

  ngOnInit() {

    this.route.paramMap
      .subscribe(() => {

        this._searchResults = [];

        this.refreshResults();

        this.bookmarkService.addDictionaryBookmark(
          'Выходные/отчетные формы',
          ['/dictionary/reports-browse']);
      });
  }

  isInvalid(fg: FormGroup, cname: string) {
    return FormHelper.isInvalid(fg, cname);
  }

  editRow(row: any) {
    this.currentFormGroup = this.fb.group(this.getGroupDef(row));
    this.currentIsNew = row.id || row.id === 0 ? false : true;
    this.editModalOpened = true;
    this.newlyEnteredFile = undefined;
  }

  addEditCompleted() {

    FormHelper.markAsSubmitted(this.currentFormGroup);

    if (!this.currentFormGroup.valid) {
      return;
    }

    this.editModalOpened = false;
    this.metadataService.storeReportMeta(this.currentFormGroup.value, this.newlyEnteredFile)
      .subscribe(() => {
        this.refreshResults();
      });
  }

  delete(row: any) {

    this.alertService.confirmModal('Удалить выбранный шаблон отчетной формы?').subscribe(val => {
      if (val) {
        this.metadataService.deleteReportMeta(row.id)
          .subscribe(() => {
            this.editModalOpened = false;
            this.refreshResults();
          });
      }
    });
  }

  fileChanged(e) {
    this.newlyEnteredFile = e.target.files[0];
  }

  private getGroupDef(row: any) {
    return {
      id: row.id,
      caption: [row.caption, Validators.required],
      reportKind: [row.reportKind, Validators.required],
      outputFormat: [row.outputFormat, Validators.required],
      isCustom: row.isCustom,
      reportTemplateCustomUri: row.reportTemplateCustomUri,
      reportTemplateEmbeddedName: row.reportTemplateEmbeddedName,
      scriptFields: [row.scriptFields, Validators.required],
      scriptTable0: row.scriptTable0,
      scriptTable1: row.scriptTable1,
      scriptTable2: row.scriptTable2,
      scriptTable3: row.scriptTable3,
      scriptTable4: row.scriptTable4,
      scriptTable5: row.scriptTable5,
      scriptTable6: row.scriptTable6,
      scriptTable7: row.scriptTable7,
      scriptTable8: row.scriptTable8,
      scriptTable9: row.scriptTable9,
      customParameters: this.fb.array((row.customParameters || []).map(el => this.getCustomParameterControlDef(el))),
      embeddedDatasourceName: row.embeddedDatasourceName,
      jobTypeId: row.jobTypeId,
      groupCaption: row.groupCaption,
      aggBundleDictCode: row.aggBundleDictCode,
      aggBundles: this.fb.array((row.aggBundles || []).map(el => this.getAggBundleControlDef(el))),
    };
  }

  getCustomParameterControlDef(el: any) {
    return this.fb.group({
        code: [el.code, Validators.compose([Validators.required])],
        displayName: [el.displayName, Validators.compose([])],
        comments: [el.comments, Validators.compose([])],
        dataType: [el.dataType,
          Validators.compose([Validators.pattern(/^\d{1,2}$/)])],
        lookupName: el.lookupName,
        defaultValue: el.defaultValue,
      }
    );
  }

  getAggBundleControlDef(el: any) {
    return this.fb.group({
        title: [el.title, Validators.compose([Validators.required])],
        codes: this.fb.array(el.codes || []),
      }
    );
  }

  navigateCurrentReportTemplate() {
    if (!this.currentFormGroup.value.reportTemplateCustomUri) {
      return;
    }

    this.metadataService.preloadFile(this.currentFormGroup.value.reportTemplateCustomUri).subscribe(fileHash => {
      window.open(environment.api + '/files/get?preloadId=' + encodeURIComponent(fileHash));
    });
  }

  onGridReady(params: any) {
    params.api.sizeColumnsToFit();
  }

  get customParameters(): FormArray {
    return this.currentFormGroup.get('customParameters') as FormArray;
  }

  addCustomParameter() {
    this.customParameters.push(this.getCustomParameterControlDef({}));
    this.customParameters.markAsDirty();
  }

  deleteCustomParameter(ix: number) {
    this.customParameters.removeAt(ix);
    this.customParameters.markAsDirty();
  }

  get aggBundles(): FormArray {
    return this.currentFormGroup.get('aggBundles') as FormArray;
  }

  addAggBundle() {
    this.aggBundles.push(this.getAggBundleControlDef({}));
    this.aggBundles.markAsDirty();
  }

  deleteAggBundle(ix: number) {
    this.aggBundles.removeAt(ix);
    this.aggBundles.markAsDirty();
  }

  addAggBundleItem(bundle: FormGroup) {
    const codes = bundle.get('codes') as FormArray;
    codes.push(this.fb.control(undefined));
    codes.markAsDirty();
  }

  deleteAggBundleItem(bundle: FormGroup, ix: number) {
    const codes = bundle.get('codes') as FormArray;
    codes.removeAt(ix);
    codes.markAsDirty();
  }
}
