import { Injectable } from '@angular/core';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { QuarantineDataService } from '../../../logic/services/quarantine-data.service';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/internal/operators';

@Injectable({
  providedIn: 'root',
})
export class EditQuarantineEventsController {

  quarantines: any = {};

  private static buildFormGroupFile(fb: FormBuilder, val: any) {
    return fb.group({
      uri: [val.uri],
      userIdUpload: val.userIdUpload,
      dateUpload: val.dateUpload,
    });
  }

  private static getEventGroupDef(val: any = {}, fb: FormBuilder, eventTypeLookup: any) {

    const files = (val.files || []).slice();
    files.push({});

    return {
      quarantineId: val.quarantineId,
      id: val.id,
      eventTypeId: [val.eventTypeId, Validators.required],
      orderNo: val.orderNo,
      responsibleTypeId: fb.array((val.responsibleTypeId || []).map(el => fb.control(el))),
      responsibleInstitutionId: val.responsibleInstitutionId,
      responsibleOther: val.responsibleOther,
      periodTypeId: val.periodTypeId,
      periodTypeOther: val.periodTypeOther,
      dateCompleted: val.dateCompleted,
      comments: val.comments,
      files: fb.array(files.map(el => EditQuarantineEventsController.buildFormGroupFile(fb, el))),
      __editing: !!val.__editing,
      __hovered: !!val.__hovered,
      __reporting: !!val.__reporting,
      __branchId: eventTypeLookup ? (eventTypeLookup['Obj' + val.eventTypeId] || {}).branchId : undefined
    };
  }

  private static buildFormArray(eventsData: any[], fb: FormBuilder, eventTypeLookup: any) {
    eventsData.sort((x, y) => x.orderNo - y.orderNo);
    return fb.array(eventsData.map(item => fb.group(EditQuarantineEventsController.getEventGroupDef(item, fb, eventTypeLookup))));
  }

  private static buildFormGroup(quarantineId: number, fb: FormBuilder, arr: any[] = [], eventTypeLookup?: any): FormGroup {
    return fb.group({
      events: EditQuarantineEventsController.buildFormArray(arr, fb, eventTypeLookup)
    });
  }

  constructor(private quarantineDataService: QuarantineDataService,
              private alertService: AlertService,
              private waitingOverlay: GlobalWaitingOverlayService,
              private lookupSourceService: LookupSourceService,
              private fb: FormBuilder,
              public navigationService: AppNavigationService) {
  }

  getStoring(quarantineId: number): boolean {
    return this.quarantines[quarantineId] ? this.quarantines[quarantineId].storing : false;
  }

  getContextFormGroup(quarantineId: number): FormGroup {
    return this.quarantines[quarantineId] ? this.quarantines[quarantineId].contextFormGroup : undefined;
  }

  getEvents(quarantineId: number): FormArray {
    return this.getContextFormGroup(quarantineId).get('events') as FormArray;
  }

  store(quarantineId: number, ...eventItems: AbstractControl[]) {
    if (!this.getContextFormGroup(quarantineId).valid || !eventItems || !eventItems.length) {
      return;
    }

    this.quarantines[quarantineId].storing = true;

    this.quarantineDataService.storeEvents(quarantineId, eventItems.map(x => x.value), this.quarantines[quarantineId].delete)
      .subscribe({
        next: () => {
          this.refreshFormData(quarantineId);
          this.quarantines[quarantineId].storing = false;
        },
        error: () => {
          this.quarantines[quarantineId].storing = false;
        }
      });
  }

  refreshFormData(quarantineId: number) {
    delete this.quarantines[quarantineId];
    this.refreshFormDataInternal(quarantineId);
  }

  private refreshFormDataInternal(quarantineId: number) {

    if (!this.quarantines[quarantineId]) {

      this.waitingOverlay.StartWaiting();

      this.lookupSourceService.getLookupObj('quarantine-event-type').subscribe(eventTypeLookup => {
        this.quarantineDataService.getEventsForEdit(quarantineId).subscribe({
          next: data => {
            this.quarantines[quarantineId] = {
              id: quarantineId,
              delete: [],
              contextFormGroup: EditQuarantineEventsController.buildFormGroup(quarantineId, this.fb, data, eventTypeLookup)
            };
            this.waitingOverlay.EndWaiting();
          }, error: error => {
            this.waitingOverlay.EndWaiting();
          }
        });
      });
    }
  }

  addEvent(quarantineId: number) {
    this.lookupSourceService.getLookupObj('quarantine-event-type').subscribe(eventTypeLookup => {
      this.getEvents(quarantineId).push(this.fb.group(EditQuarantineEventsController.getEventGroupDef({
        orderNo: this.calculateNextOrderNo(quarantineId),
        __editing: true
      }, this.fb, eventTypeLookup)));
      this.getContextFormGroup(quarantineId).markAsDirty();
    });
  }

  deleteEvent(quarantineId: number, eventItem: FormGroup) {

    const index = this.getEvents(quarantineId).controls.findIndex(el => el === eventItem);

    if (index < 0) {
      return;
    }

    this.alertService.confirmModal('Вы уверены, что хотите безвозвратно удалить выбранную строку плана мероприятий?')
      .subscribe(() => {
        const idToDelete = this.getEvents(quarantineId).controls[index].get('id').value;
        if (idToDelete) {
          this.quarantines[quarantineId].storing = true;

          this.quarantineDataService.storeEvents(quarantineId, [], [idToDelete])
            .subscribe({
              next: () => {
                this.getEvents(quarantineId).removeAt(index);
                this.quarantines[quarantineId].storing = false;
              },
              error: () => {
                this.quarantines[quarantineId].storing = false;
              }
            });
        }
      });
  }

  singleFileChanged(quarantineId: number, eventItem: FormGroup) {

    const filesArr = (eventItem.get('files') as FormArray);

    let i = 0;
    while (i < filesArr.length) {
      const fileI = filesArr.controls[i];
      if (fileI.value && fileI.value.uri) {
        i++;
      } else {
        filesArr.removeAt(i);
      }
    }

    filesArr.push(EditQuarantineEventsController.buildFormGroupFile(this.fb, {}));

    eventItem.markAsDirty();
  }

  hasKgkuResponsible(quarantineId: number, eventItem: FormGroup) {

    for (const ctrl of (eventItem.controls['responsibleTypeId'] as FormArray).controls) {
      if (ctrl.value == 2 /*Специалисты госветслужбы КГКУ*/ || ctrl.value == 3 /*Руководитель КГКУ*/) {
        return true;
      }
    }

    return false;
  }

  private calculateNextOrderNo(quarantineId: number) {
    let maxNo = 0;
    this.getEvents(quarantineId).controls.forEach(ctrl => {
      if (ctrl.value.orderNo > maxNo) {
        maxNo = ctrl.value.orderNo;
      }
    });
    return maxNo + 1;
  }

  goEditing(quarantineId: number, eventItem: FormGroup) {
    eventItem.get('__editing').setValue(true);
    eventItem.markAsDirty();
  }

  goReporting(quarantineId: number, eventItem: FormGroup) {
    eventItem.get('__reporting').setValue(true);
    eventItem.markAsDirty();
  }

  getResponsiblesTitle(quarantineId: number, eventItem: FormGroup) {

    return combineLatest(
      this.lookupSourceService.getLookupObj('quarantine-event-responsible'),
      this.lookupSourceService.getLookupObj('institution'),
    ).pipe(map(([respLookup, institLookup]) => {
      const kgkuCaption = eventItem.get('responsibleInstitutionId').value
        ? institLookup[eventItem.get('responsibleInstitutionId').value] : undefined;
      let strResp = '';
      eventItem.value.responsibleTypeId.forEach(rt => {
        if (rt) {
          let respCaption: string = respLookup[rt];
          if (kgkuCaption) {
            respCaption = respCaption.replace('КГКУ', kgkuCaption);
          }
          strResp = strResp + (strResp ? ', ' : '') + respCaption;
        }
      });

      if (eventItem.get('responsibleOther').value) {
        strResp = strResp + (strResp ? ', ' : '') + eventItem.get('responsibleOther').value;
      }

      return strResp;
    }));
  }

  getUsedBranchIds(quarantineId: number) {
    const branchIds = [];
    const branchHash = {};
    this.getEvents(quarantineId).controls.forEach(eventItem => {
      const branchId = eventItem.get('__branchId').value;
      if (!branchHash[branchId]) {
        branchHash[branchId] = true;
        branchIds.push(branchId);
      }
    });
    return branchIds;
  }

  getEventsByBranch(quarantineId: number, branchId: any, includeNoBranch: boolean) {
    const controls = [];
    this.getEvents(quarantineId).controls.forEach(eventItem => {
      const tid = eventItem.get('__branchId').value;
      if ((tid == branchId) || (!tid && includeNoBranch)) {
        controls.push(eventItem);
      }
    });
    return controls;
  }

  moveUp(quarantineId: number, eventItem: FormGroup) {
    const index = this.getEvents(quarantineId).controls.findIndex(el => el === eventItem);

    if (index <= 0) {
      return;
    }

    this.moveItem(quarantineId, eventItem, this.getEvents(quarantineId).controls[index - 1]);
  }

  moveDown(quarantineId: number, eventItem: FormGroup) {
    const index = this.getEvents(quarantineId).controls.findIndex(el => el === eventItem);

    if (index >= this.getEvents(quarantineId).controls.length - 1) {
      return;
    }

    this.moveItem(quarantineId, eventItem, this.getEvents(quarantineId).controls[index + 1]);
  }

  private moveItem(quarantineId: number, eventItem: AbstractControl, eventItem2: AbstractControl) {
    const orderNo = eventItem.value.orderNo;
    eventItem.get('orderNo').setValue(eventItem2.value.orderNo);
    eventItem2.get('orderNo').setValue(orderNo);

    this.getEvents(quarantineId).controls.sort((x, y) => x.get('orderNo').value - y.get('orderNo').value);

    this.quarantines[quarantineId].storing = true;

    this.quarantineDataService.storeEvents(quarantineId, [eventItem.value, eventItem2.value], [])
      .subscribe({
        next: () => {
          this.quarantines[quarantineId].storing = false;
        },
        error: () => {
          this.quarantines[quarantineId].storing = false;
        }
      });
  }
}
