import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { ServerSideErrorsProvider } from '../../../logic/validators/server-side-errors-provider';
import { AppFormGroup } from '../../../ui/controls/app-form-group';
import { FormHelper } from '../../../ui/controls/form-helper';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { SecurityService } from '../../../logic/services/security.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { StringHelper } from '../../../helpers/string-helper';
import { AddressComponent } from '../../../ui/controls/address.component';
import { serverSideErrorsValidator } from '../../../logic/validators/server-side-errors-validator.directive';
import { MetadataService } from '../../../logic/services/metadata.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { AppModalPreviewFileComponent } from '../../../ui/controls/app-modal-preview-file.component';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';

@Component({
  selector: 'app-edit-quarantine-common',
  templateUrl: './edit-quarantine-common.component.html',
  styleUrls: ['../edit-quarantine.all.css']
})
export class EditQuarantineCommonComponent implements OnChanges {

  @Input() contextFormGroup: AppFormGroup;
  @Input() serverSideErrorsProvider: ServerSideErrorsProvider;
  @Input() isAccessEdit = false;

  @Output() dirtyFormGroup = new EventEmitter();

  isInvalid = FormHelper.isInvalid;

  liquidationRegulationsFileUri = null;

  susceptibleUniqueAnimalSubtypeIdsModalOpen = false;

  public sourcePathogenAnimalOtherCaptionCheckbox;

  get territories() {
    return this.contextFormGroup.get('territories') as FormArray;
  }
  get susceptibleUniqueAnimalSubtypeIds() {
    return this.contextFormGroup.get('susceptibleUniqueAnimalSubtypeIds') as FormArray;
  }

  QUARANTINE_TERRITORY_TYPE = {
    SOURCE: 1,
    BAD_LOCALITY: 2,
    DANGER_AREA: 3,
    FIRST_DANGER_AREA: 4,
    SECOND_DANGER_AREA: 5,
  };

  public static createFormGroupCommon(fb: FormBuilder, serverSideErrorsProvider: ServerSideErrorsProvider, val: any) {
    return fb.group({
      id: val.id,
      diseaseTypeId: [val.diseaseTypeId, Validators.compose([Validators.required,
        serverSideErrorsValidator('diseaseTypeId', serverSideErrorsProvider)])],
      sourcePathogenAnimalTypeId: [val.sourcePathogenAnimalTypeId,
      Validators.compose([serverSideErrorsValidator('sourcePathogenAnimalTypeId', serverSideErrorsProvider)])],
      sourcePathogenAnimalSubtypeId: [val.sourcePathogenAnimalSubtypeId,
        Validators.compose([serverSideErrorsValidator('sourcePathogenAnimalSubtypeId', serverSideErrorsProvider)])],
      sourcePathogenAnimalOtherCaption: [val.sourcePathogenAnimalOtherCaption,
        Validators.compose([serverSideErrorsValidator('sourcePathogenAnimalOtherCaption', serverSideErrorsProvider)])],
      dateFrom: [val.dateFrom || StringHelper.getISODate(new Date()),
        Validators.compose([Validators.required, FormHelper.validateDateTimePicker,
          serverSideErrorsValidator('dateFrom', serverSideErrorsProvider)])],
      responsibleInstitutionId: [val.responsibleInstitutionId,
        Validators.compose([serverSideErrorsValidator('responsibleInstitutionId', serverSideErrorsProvider)])],
      responsibleInstitutionEmployeeId: [val.responsibleInstitutionEmployeeId, Validators.compose([Validators.required,
        serverSideErrorsValidator('responsibleInstitutionEmployeeId', serverSideErrorsProvider)])],
      territories: (val.territories && val.territories.length
        ? fb.array(val.territories.map((el, ix) => fb.group(this.buildFormGroupTerritory(fb, serverSideErrorsProvider,
          el || {}, 'territories-' + ix))))
        : fb.array([])),
      currentStatusId: val.currentStatusId,
      needActualizeTrappedObjects: val.needActualizeTrappedObjects || false,
      trappedStableCount: val.trappedStableCount,
      trappedAnimalCount: val.trappedAnimalCount,
      susceptibleUniqueAnimalSubtypeIds: fb.array(val.susceptibleUniqueAnimalSubtypeIds || []),
      isViolationDeadlineFile: val.isViolationDeadlineFile,
      noSuspicionStage: val.noSuspicionStage || false,
      personApprovedEventPlanPost: [val.personApprovedEventPlanPost,
        Validators.compose([serverSideErrorsValidator('personApprovedEventPlanPost', serverSideErrorsProvider)])],
      personApprovedEventPlanFio: [val.personApprovedEventPlanFio,
        Validators.compose([serverSideErrorsValidator('personApprovedEventPlanFio', serverSideErrorsProvider)])],
    });
  }

  public static buildFormGroupTerritory(fb: FormBuilder,
                                        serverSideErrorsProvider: ServerSideErrorsProvider,
                                        val: any,
                                        formGroupNameRelation: string) {
    return {
      quarantineId: [val.quarantineId,
        Validators.compose([serverSideErrorsValidator('quarantineId', serverSideErrorsProvider, formGroupNameRelation)])],
      quarantineTerritoryTypeId: [val.quarantineTerritoryTypeId,
        Validators.compose([serverSideErrorsValidator('quarantineTerritoryTypeId', serverSideErrorsProvider, formGroupNameRelation)])],
      kind: [(val.kind || '0').toString(),
        Validators.compose([serverSideErrorsValidator('kind', serverSideErrorsProvider, formGroupNameRelation)])],
      regionId: [val.regionId,
        Validators.compose([serverSideErrorsValidator('regionId', serverSideErrorsProvider, formGroupNameRelation)])],
      cityId: [val.cityId,
        Validators.compose([serverSideErrorsValidator('cityId', serverSideErrorsProvider, formGroupNameRelation)])],
      cadNumber: [StringHelper.stringCadNumberSplitWithColon(val.cadNumber),
        Validators.compose([serverSideErrorsValidator('cadNumber', serverSideErrorsProvider, formGroupNameRelation)])],
      addressId: [val.addressId,
        Validators.compose([serverSideErrorsValidator('addressId', serverSideErrorsProvider, formGroupNameRelation)])],
      address: fb.group(AddressComponent.buildFormGroupDef(fb, serverSideErrorsProvider, val.address, true,
        val.quarantineTerritoryTypeId)),
      radius: [val.radius, Validators.compose([Validators.pattern(/^\d*$/),
        serverSideErrorsValidator('radius', serverSideErrorsProvider, formGroupNameRelation)])],
      comment: [val.comment,
        Validators.compose([serverSideErrorsValidator('comment', serverSideErrorsProvider, formGroupNameRelation)])],
      stableId: [val.stableId,
        Validators.compose([serverSideErrorsValidator('stableId', serverSideErrorsProvider, formGroupNameRelation)])],
    };
  }

  constructor (private dataCachingService: DataCachingService,
               private waitingOverlay: GlobalWaitingOverlayService,
               private userService: SecurityService,
               private metadataService: MetadataService,
               private lookupService: LookupSourceService,
               private navigationService: AppNavigationService) {
  }

  ngOnChanges() {
    if (!this.contextFormGroup) {
      return;
    }

    this.waitingOverlay.StartWaiting();

    this.userService.getUserInfo().subscribe(info => {
      this.sourcePathogenAnimalOtherCaptionCheckbox = !!this.contextFormGroup.get('sourcePathogenAnimalOtherCaption').value;

      if (!this.contextFormGroup.get('id').value) {
        this.contextFormGroup.get('responsibleInstitutionId').setValue(info.institutionId);
        this.contextFormGroup.get('responsibleInstitutionEmployeeId').setValue(info.institutionEmployeeId);
      }

      if (this.contextFormGroup.get('diseaseTypeId').value) {
        this.updateLiquidationRegulationsFileUri(this.contextFormGroup.get('diseaseTypeId').value);
      }

      this.contextFormGroup.get('diseaseTypeId').valueChanges.subscribe(change => this.updateLiquidationRegulationsFileUri(change));
      this.contextFormGroup.valueChanges.subscribe(() => this.dirtyFormGroup.next());

      this.waitingOverlay.EndWaiting();
    });
  }

  updateLiquidationRegulationsFileUri(diseaseTypeId: string) {
    this.lookupService.getLookupObj('disease-type/may-be-introduced-quarantine' + diseaseTypeId)
      .subscribe(diseaseType => this.liquidationRegulationsFileUri = diseaseType ? diseaseType.liquidationRegulationsFileUri : null);
  }

  resetAnimalSubtypeId() {
    this.contextFormGroup.get('sourcePathogenAnimalSubtypeId').reset();
    this.contextFormGroup.markAsDirty();
    this.dirtyFormGroup.next();
  }

  sourcePathogenAnimalOtherCaptionCheckboxChange() {
    this.contextFormGroup.get('sourcePathogenAnimalTypeId').reset();
    this.contextFormGroup.get('sourcePathogenAnimalSubtypeId').reset();
    this.contextFormGroup.get('sourcePathogenAnimalOtherCaption').reset();
    this.contextFormGroup.markAsDirty();
    this.dirtyFormGroup.next();
  }

  getPrettyTitle(file: any) {
    return StringHelper.getPrettyTitleFile(file);
  }

  previewFile(file: any) {
    return this.navigationService.previewFile(AppModalPreviewFileComponent, { fileUri: file });
  }
}
