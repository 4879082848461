import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/internal/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PstReportService = /** @class */ (function () {
    function PstReportService(http) {
        this.http = http;
    }
    PstReportService.prototype.getReportData = function (reportId) {
        return this.http.get(environment.api + "/pst-report/report-full-data/" + reportId);
    };
    PstReportService.prototype.getListReports = function (searchParams) {
        return this.http.post(environment.api + "/pst-report/list-reports", searchParams)
            .pipe(map(function (response) {
            return response;
        }));
    };
    PstReportService.prototype.storeIndicator = function (indicatorData, reportId) {
        return this.http.post(environment.api + "/pst-report/store-indicator/" + reportId, indicatorData);
    };
    PstReportService.prototype.addConstruction = function (reportId, data) {
        return this.http.post(environment.api + "/pst-report/add-construction/" + reportId, data);
    };
    PstReportService.prototype.updateConstruction = function (reportId, data) {
        return this.http.post(environment.api + "/pst-report/update-construction/" + reportId, data);
    };
    PstReportService.prototype.addDeal = function (reportId, data) {
        return this.http.post(environment.api + "/pst-report/add-deal/" + reportId, data);
    };
    PstReportService.prototype.updateDeal = function (reportId, data) {
        return this.http.post(environment.api + "/pst-report/update-deal/" + reportId, data);
    };
    PstReportService.prototype.getForLookup = function (id) {
        return this.http.get(environment.api + "/pst-report/lookup/" + id);
    };
    PstReportService.prototype.calcDependencies = function (indicatorKey) {
        return this.http.post(environment.api + "/pst-report/calc-dependencies", indicatorKey)
            .pipe(map(function (response) {
            return response;
        }));
    };
    PstReportService.prototype.updateReportTypeIndicator = function (data, reportTypeId, branchOrderNo, colNo, rowNo) {
        var qstring = environment.api + "/metadata/pst-report-type-indicator/update/" + reportTypeId + "/" + branchOrderNo
            + ("/" + colNo + "/" + rowNo);
        return this.http.post(qstring, data)
            .pipe(map(function (response) {
            return response.data;
        }));
    };
    PstReportService.prototype.deleteReportTypeIndicator = function (data, reportTypeId, branchOrderNo, colNo, rowNo) {
        var qstring = environment.api + "/metadata/pst-report-type-indicator/delete/" + reportTypeId + "/" + branchOrderNo
            + ("/" + colNo + "/" + rowNo);
        return this.http.post(qstring, data)
            .pipe(map(function (response) {
            return response.data;
        }));
    };
    PstReportService.prototype.createReportTypeIndicator = function (data) {
        var qstring = environment.api + "/metadata/pst-report-type-indicator";
        return this.http.post(qstring, data)
            .pipe(map(function (response) {
            return response.data;
        }));
    };
    PstReportService.prototype.updateReportTypeBranch = function (data, reportTypeId, orderNo) {
        var qstring = environment.api + "/metadata/pst-report-type-branch/update/" + reportTypeId + "/" + orderNo;
        return this.http.post(qstring, data)
            .pipe(map(function (response) {
            return response.data;
        }));
    };
    PstReportService.prototype.createReportTypeBranch = function (data) {
        var qstring = environment.api + "/metadata/pst-report-type-branch";
        return this.http.post(qstring, data)
            .pipe(map(function (response) {
            return response.data;
        }));
    };
    PstReportService.ngInjectableDef = i0.defineInjectable({ factory: function PstReportService_Factory() { return new PstReportService(i0.inject(i1.HttpClient)); }, token: PstReportService, providedIn: "root" });
    return PstReportService;
}());
export { PstReportService };
