/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@clr/angular";
import * as i2 from "./edit-institution-common-form.component.ngfactory";
import * as i3 from "./edit-institution-common-form.component";
import * as i4 from "@angular/common";
import * as i5 from "../../../ui/controls/app-reports-button.component.ngfactory";
import * as i6 from "../../../ui/controls/app-reports-button.component";
import * as i7 from "../../../logic/services/lookup-source.service";
import * as i8 from "../../../logic/services/metadata.service";
import * as i9 from "@angular/forms";
import * as i10 from "../../../logic/services/security.service";
import * as i11 from "../../../logic/services/app-navigation.service";
import * as i12 from "../../../../../node_modules/@clr/angular/clr-angular.ngfactory";
import * as i13 from "./edit-institution-common.component";
import * as i14 from "../../../logic/services/bookmark.service";
import * as i15 from "../../../logic/services/institution-data.service";
import * as i16 from "../../../ui/infrastructure/alert.service";
import * as i17 from "../../../logic/services/address-person-fio-cache.service";
import * as i18 from "../../../logic/services/data-caching.service";
import * as i19 from "../../../ui/infrastructure/global-waiting-overlay.service";
var styles_EditInstitutionCommonComponent = [];
var RenderType_EditInstitutionCommonComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EditInstitutionCommonComponent, data: {} });
export { RenderType_EditInstitutionCommonComponent as RenderType_EditInstitutionCommonComponent };
function View_EditInstitutionCommonComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "itech-doc-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "itech-doc-image"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "clr-icon", [["shape", "bank"]], null, null, null, null, null)), i0.ɵdid(3, 16384, null, 0, i1.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "itech-doc-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "app-edit-institution-common-form", [], null, null, null, i2.View_EditInstitutionCommonFormComponent_0, i2.RenderType_EditInstitutionCommonFormComponent)), i0.ɵdid(6, 49152, null, 0, i3.EditInstitutionCommonFormComponent, [], { contextFormGroup: [0, "contextFormGroup"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contextFormGroup; _ck(_v, 6, 0, currVal_0); }, null); }
export function View_EditInstitutionCommonComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 31, "div", [["class", "itech-common-wrap"], ["id", "interceptNoAccess"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EditInstitutionCommonComponent_1)), i0.ɵdid(3, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 27, "div", [["class", "itech-form-actions"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 14, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 3, "button", [["class", "btn btn-secondary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.refreshFormData() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "clr-icon", [["shape", "refresh"]], null, null, null, null, null)), i0.ɵdid(8, 16384, null, 0, i1.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, ["\u00A0\u041E\u0431\u043D\u043E\u0432\u0438\u0442\u044C "])), (_l()(), i0.ɵeld(10, 0, null, null, 1, "app-reports-button", [], null, null, null, i5.View_AppReportsButtonComponent_0, i5.RenderType_AppReportsButtonComponent)), i0.ɵdid(11, 49152, null, 0, i6.AppReportsButtonComponent, [i7.LookupSourceService, i8.MetadataService, i9.FormBuilder, i10.SecurityService, i11.AppNavigationService], { objId1: [0, "objId1"], dropdownPosition: [1, "dropdownPosition"], reportKind: [2, "reportKind"] }, null), (_l()(), i0.ɵeld(12, 0, null, null, 3, "button", [["class", "btn btn-primary-outline"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigationService.navigateJournal(undefined, 1, _co.id, undefined) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 1, "clr-icon", [["shape", "view-list"]], null, null, null, null, null)), i0.ɵdid(14, 16384, null, 0, i1.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, ["\u00A0\u0416\u0443\u0440\u043D\u0430\u043B "])), (_l()(), i0.ɵeld(16, 0, null, null, 3, "button", [["class", "btn btn-warning-outline"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteInstitution() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(17, 0, null, null, 1, "clr-icon", [["shape", "trash"]], null, null, null, null, null)), i0.ɵdid(18, 16384, null, 0, i1.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, [" \u0423\u0434\u0430\u043B\u0438\u0442\u044C "])), (_l()(), i0.ɵeld(20, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(21, 0, null, null, 6, "button", [["class", "btn btn-primary itech-form-save"]], [[1, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.store() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_ClrLoadingButton_0, i12.RenderType_ClrLoadingButton)), i0.ɵdid(22, 49152, null, 0, i1.ClrLoadingButton, [i0.ElementRef, i0.Renderer2], { disabled: [0, "disabled"] }, null), i0.ɵprd(2048, null, i1.LoadingListener, null, [i1.ClrLoadingButton]), i0.ɵdid(24, 147456, null, 0, i1.ClrLoading, [[2, i1.LoadingListener]], { loadingState: [0, "loadingState"] }, null), (_l()(), i0.ɵeld(25, 0, null, 0, 1, "clr-icon", [["shape", "check"]], null, null, null, null, null)), i0.ɵdid(26, 16384, null, 0, i1.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, 0, ["\u00A0\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C "])), (_l()(), i0.ɵeld(28, 0, null, null, 3, "button", [["class", "btn btn-warning itech-form-cancel"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelEdit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(29, 0, null, null, 1, "clr-icon", [["shape", "undo"]], null, null, null, null, null)), i0.ɵdid(30, 16384, null, 0, i1.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, ["\u00A0\u041E\u0442\u043C\u0435\u043D\u0438\u0442\u044C "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contextFormGroup; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.id; var currVal_2 = "top-left"; var currVal_3 = "institution"; _ck(_v, 11, 0, currVal_1, currVal_2, currVal_3); var currVal_5 = ((!_co.contextFormGroup || !_co.contextFormGroup.dirty) || _co.storing); _ck(_v, 22, 0, currVal_5); var currVal_6 = _co.storing; _ck(_v, 24, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = (i0.ɵnov(_v, 22).disabled ? "" : null); _ck(_v, 21, 0, currVal_4); var currVal_7 = (!_co.contextFormGroup || !_co.contextFormGroup.dirty); _ck(_v, 28, 0, currVal_7); }); }
export function View_EditInstitutionCommonComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-edit-institution-common", [], null, null, null, View_EditInstitutionCommonComponent_0, RenderType_EditInstitutionCommonComponent)), i0.ɵdid(1, 49152, null, 0, i13.EditInstitutionCommonComponent, [i14.BookmarkService, i15.InstitutionDataService, i16.AlertService, i17.AddressPersonFioCacheService, i18.DataCachingService, i19.GlobalWaitingOverlayService, i9.FormBuilder, i11.AppNavigationService], null, null)], null, null); }
var EditInstitutionCommonComponentNgFactory = i0.ɵccf("app-edit-institution-common", i13.EditInstitutionCommonComponent, View_EditInstitutionCommonComponent_Host_0, { id: "id", model: "model" }, {}, []);
export { EditInstitutionCommonComponentNgFactory as EditInstitutionCommonComponentNgFactory };
