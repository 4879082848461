
      <div *ngIf="contextFormGroup && contextFormGroup.contains(controlName) && stageId >= 0 && stageId <= quarantineCurrentStatusId"
           style="margin-top:40px;border:1px solid silver;text-align:center;padding:0 20px 0 20px;">

          <app-doc-validation-errors [validationErrors]="contextFormGroup.docLevelServerSideValidationErrors">
          </app-doc-validation-errors>
          <div style="margin-top:10px">
              <strong>{{title}}</strong>
          </div>
          <div [formGroup]="currentFormGroup" style="margin:10px">
              <div>
                  <label for="dateToInclusive{{stageId}}" style="margin-right: 5px">
                      {{stageId === 0 ? 'Дата введения этапа' : 'Дата закрытия текущего этапа'}}
                  </label>
                  <label for="dateToInclusive{{stageId}}" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md disabled">
                      <input type="date" id="dateToInclusive{{stageId}}" class="itech-control-normal"
                             formControlName="dateToInclusive" (change)="changeDateFrom()"
                             [attr.disabled]="stageId !== quarantineCurrentStatusId ? true : undefined">
                  </label>
              </div>
              <div *ngIf="stageId == _quarantineStages.DETECTION">
                  <label for="dateFromNextStage{{stageId}}" style="margin-right: 5px">Карантин вводится с/по </label>
                  <label for="dateFromNextStage{{stageId}}" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md disabled">
                      <input type="date" id="dateFromNextStage{{stageId}}" class="itech-control-normal"
                             [(ngModel)]="dateFromNextStage" [ngModelOptions]="{standalone: true}"
                             [attr.disabled]="true">
                  </label>
                  <label for="dateToInclusiveNextStage{{stageId}}" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md disabled">
                      <input type="date" id="dateToInclusiveNextStage{{stageId}}" class="itech-control-normal"
                             [(ngModel)]="dateToInclusiveNextStage" [ngModelOptions]="{standalone: true}"
                             [attr.disabled]="stageId !== quarantineCurrentStatusId ? true : undefined">
                  </label>
              </div>
              <div style="margin-top:20px" *ngIf="stageId === quarantineCurrentStatusId">
                  <button class="btn btn-primary" (click)="closeCurrentStage()"
                          [disabled]="stageId != _quarantineStages.INTRODUCTION ? !currentFormGroup.dirty : undefined">Утвердить</button>
              </div>
          </div>
      </div>