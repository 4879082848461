import * as tslib_1 from "tslib";
import { MetaBrowseBaseComponent } from '../meta-browse.base.component';
import { ActivatedRoute } from '@angular/router';
import { MetadataService } from '../../../logic/services/metadata.service';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { FormBuilder, Validators } from '@angular/forms';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { StringHelper } from '../../../helpers/string-helper';
var ProductSubtypeBrowseComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ProductSubtypeBrowseComponent, _super);
    function ProductSubtypeBrowseComponent(route, metadataService, alertService, globalWaitingOverlayService, lookupService, bookmarkService, fb, dataCachingService) {
        var _this = _super.call(this, route, metadataService, alertService, globalWaitingOverlayService, lookupService, fb, bookmarkService, dataCachingService) || this;
        _this.gridColumnDefs = [
            { headerName: '№', field: 'id', width: 100 },
            { headerName: 'Тип', field: 'productTypeCaption', tooltipField: 'productTypeCaption', width: 200 },
            { headerName: 'Наименование', field: 'caption', tooltipField: 'caption', width: 350 },
            { headerName: 'Guid (Меркурий)', field: 'mercuryGuid', tooltipField: 'mercuryGuid', width: 260 },
            {
                headerName: 'Удалено', field: 'dateDeleted', width: 100,
                valueFormatter: function (params) { return StringHelper.getRuTimeWithSeconds(params.value); },
            },
            {
                headerName: 'Требует убоя', field: 'requiredSlaughter',
                valueFormatter: function (params) { return params && params.value ? 'Да' : 'Нет'; }
            },
            {
                headerName: 'Без животных и сырья', field: 'withoutAnimalOrProduct',
                valueFormatter: function (params) { return params && params.value ? 'Да' : 'Нет'; }
            },
        ];
        _this.getRuDate = StringHelper.getRuDate;
        return _this;
    }
    ProductSubtypeBrowseComponent.prototype.getGroupDef = function (row) {
        return {
            id: [row.id, Validators.compose([Validators.required, Validators.pattern(/^\d{1,5}$/)])],
            productTypeId: [row.productTypeId, Validators.compose([Validators.required])],
            caption: [row.caption, Validators.required],
            mercuryGuid: row.mercuryGuid,
            expertiseIndicatorTypeIds: this.fb.array(row.expertiseIndicatorTypeIds || []),
            expertiseIndicatorTypeIdsLastUpdate: row.expertiseIndicatorTypeIdsLastUpdate,
            requiredSlaughter: row.requiredSlaughter || false,
            withoutAnimalOrProduct: row.withoutAnimalOrProduct || false,
            supportedManufactureDateFormatIds: this.fb.array(row.supportedManufactureDateFormatIds || []),
            supportedExpireDateFormatIds: this.fb.array(row.supportedExpireDateFormatIds || []),
        };
    };
    ProductSubtypeBrowseComponent.prototype.getMetaName = function () {
        return 'product-subtype';
    };
    ProductSubtypeBrowseComponent.prototype.getMetaTitle = function () {
        return 'Подвиды продукции';
    };
    ProductSubtypeBrowseComponent.prototype.getId = function (formGroup) {
        return formGroup.get('productTypeId').value && formGroup.get('id').value
            ? formGroup.get('productTypeId').value + '/' + formGroup.get('id').value
            : undefined;
    };
    ProductSubtypeBrowseComponent.prototype.refreshResults = function () {
        var _this = this;
        this.globalWaitingOverlayService.StartWaiting();
        this.lookupService.getLookupObj('product-type').subscribe(function (productTypeLookup) {
            _this.metadataService.getMetadata(_this.getMetaName()).subscribe({
                next: function (data) {
                    data.forEach(function (el) {
                        el.productTypeCaption = productTypeLookup[el.productTypeId] || '<<Неизвестный вид>>';
                    });
                    _this._searchResults = data;
                    _this.lookupService.invalidateLookup(_this.getMetaName());
                    _this.dataCachingService.addToCache(_this.getMetaName() + '_Data', '1', data);
                    _this.globalWaitingOverlayService.EndWaiting();
                }, error: function () {
                    _this.globalWaitingOverlayService.EndWaiting();
                }
            });
        });
    };
    ProductSubtypeBrowseComponent.prototype.getRowStyle = function (params) {
        if (params.data && params.data.dateDeleted) {
            return { color: 'silver' };
        }
    };
    ProductSubtypeBrowseComponent.prototype.clearExpertiseIndicatorTypeIds = function (row) {
        var _this = this;
        this.metadataService.clearExpertiseIndicatorTypeIdsForProductSubtype(row.productTypeId, row.id)
            .subscribe(function () { return _this.editModalOpened = false; });
    };
    return ProductSubtypeBrowseComponent;
}(MetaBrowseBaseComponent));
export { ProductSubtypeBrowseComponent };
