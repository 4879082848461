import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {ClarityModule} from '@clr/angular';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ClickOutsideModule} from 'ng-click-outside';
import {AgGridModule} from '@ag-grid-community/angular';
import {PstReportsListComponent} from './pages/pst-reports-list.component';
import {UiModule} from '../ui/ui.module';
import {PstReportEditComponent} from './pages/pst-report-edit.component';
import {PstBranchTabColEditComponent} from './pages/editors/pst-branch-tab-col-edit.component';
import {PstBranchCellEditComponent} from './pages/editors/pst-branch-cell-edit.component';
import {PstBranchTabRowEditComponent} from './pages/editors/pst-branch-tab-row-edit.component';
import {PstBranchConstructionEditComponent} from './pages/editors/pst-branch-construction-edit.component';
import {PstBranchCellDateEditComponent} from './pages/editors/pst-branch-cell-date-edit.component';
import {PstBranchCellLookupEditComponent} from './pages/editors/pst-branch-cell-lookup-edit.component';
import {PstBranchCellTextEditComponent} from './pages/editors/pst-branch-cell-text-edit.component';
import {PstBranchSellDealEditComponent} from './pages/editors/pst-branch-sell-deal-edit.component';
import {PstBranchBuyDealEditComponent} from './pages/editors/pst-branch-buy-deal-edit.component';
import {PstBranchFedHeaderComponent} from './pages/editors/pst-branch-fed-header.component';
import {IndicatorDependencyModalComponent} from './pages/indicator-dependency-modal.component';
import {AppIndDepTreeNodesComponent} from './pages/app-ind-dep-tree-nodes.component';
import {ReportIndicatorMetaEditModalComponent} from './pages/meta/report-indicator-meta-edit-modal.component';
import {PstIndicatorTypeMetaEditModalComponent} from './pages/meta/pst-indicator-type-meta-edit-modal.component';
import {PstReportTypeBrowseComponent} from './pages/meta/pst-report-type-browse.component';
import {ReportBranchMetaEditModalComponent} from './pages/meta/report-branch-meta-edit-modal.component';
import {ChangeAgentIndicatorsRunnerComponent} from './pages/change-agent-indicators-runner.component';

@NgModule({
  imports: [
    CommonModule,
    ClarityModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ClickOutsideModule,
    AgGridModule,
    UiModule,
  ],

  declarations: [
    PstReportsListComponent,
    PstReportEditComponent,
    PstBranchTabColEditComponent,
    PstBranchCellEditComponent,
    PstBranchTabRowEditComponent,
    PstBranchConstructionEditComponent,
    PstBranchCellDateEditComponent,
    PstBranchCellLookupEditComponent,
    PstBranchCellTextEditComponent,
    PstBranchSellDealEditComponent,
    PstBranchBuyDealEditComponent,
    PstBranchFedHeaderComponent,
    IndicatorDependencyModalComponent,
    AppIndDepTreeNodesComponent,
    ReportIndicatorMetaEditModalComponent,
    PstIndicatorTypeMetaEditModalComponent,
    PstReportTypeBrowseComponent,
    ReportBranchMetaEditModalComponent,
    ChangeAgentIndicatorsRunnerComponent,
  ],

  entryComponents: [
    IndicatorDependencyModalComponent,
    ReportIndicatorMetaEditModalComponent,
    PstIndicatorTypeMetaEditModalComponent,
    ReportBranchMetaEditModalComponent,
  ]
})
export class PstReportModule {
}
