import { Component, Input } from '@angular/core';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { StringHelper } from '../../../helpers/string-helper';
import { AnimalDataService } from '../../../logic/services/animal-data.service';
import { EditAnimalComponent } from '../../edit-animal/edit-animal.component';
import { AlertService } from '../../../ui/infrastructure/alert.service';

@Component({
  selector: 'app-edit-agent-animals',
  templateUrl: './edit-agent-animals.component.html'
})
export class EditAgentAnimalsComponent {

  @Input() model: any = {};

  isInvalid = FormHelper.isInvalid;

  private _id: number;
  @Input()
  get id(): number {
    return this._id;
  }

  set id(id: number) {
    if (this._id !== id) {
      this._id = id;
      this.refreshFormDataInternal();
    }
  }

  gridColumnDefs = EditAgentAnimalsComponent.getGridColumnDefs();

  public static getGridColumnDefs() {
    return [
      {
        headerName: '№', field: 'idNumber', width: 130,
        cellRenderer: params => {
          return !params.data.tempId
            ? (params.data.hasWaitingConfirmedChanges
              ? `<clr-icon shape="hourglass" style="color:#fb8c00" title="Неподтверждённые изменения"></clr-icon>&nbsp;`
              : '') + params.value || params.data.id
            : `<clr-icon shape="hourglass" style="color:#fb8c00" title="Неподтверждённая карточка"></clr-icon>`;
        },
        checkboxSelection: x => x.data.tempId,
      },
      {
        headerName: 'Сертификат', field: 'hasStockEntryRecordTitle', width: 50,
        cellRenderer: params => {
          return params && params.data && params.data.hasStockEntryRecord
            ? `<clr-icon shape="certificate" style="color:green" title="Имеется сертификат"></clr-icon>`
            : ``;
        },
        filterValueGetter: params => params.data.hasStockEntryRecord ? 'есть сертификат' : 'нет сертификата',
        cellStyle: {'padding-left': '17px'},
      },
      {
        headerName: 'Убой', field: 'examResultTitle', width: 50,
        cellRenderer: params => {
          return params && params.data && params.data.examResult
            ? [1].includes(+params.data.examResult)
              ? `<clr-icon shape="no-access" title="Запрещено на убой"></clr-icon>`
              : `<clr-icon shape="success-standard" style="color:green" title="Разрешено на убой"></clr-icon>`
            : ``;
        },
        filterValueGetter: params => params.data.examResult === 1
          ? 'Запрещено на убой'
          : params.data.examResult === 2 ? 'Разрешено на убой' : '',
        cellStyle: {'padding-left': '17px'},
      },
      {
        headerName: 'Наименование', field: 'caption', width: 250,
        cellRenderer: params => {
          const animalStateHtml = params.data.overdueEventsCount && params.data.overdueEventsCount > 0
            ? '<clr-icon shape="history" style="color:red"' +
            ' title="Имеются просроченные мероприятия: ' + params.data.overdueEventsCount + '"></clr-icon>'
            : '<clr-icon shape="check" style="color:green" title="Просроченные мероприятия отсутствуют"></clr-icon>';

          return animalStateHtml + '&nbsp;' + (params.data.animalGroup ? '<clr-icon shape="layers"></clr-icon>&nbsp;' : '') + params.value;
        }
      },
      {
        headerName: 'Состояние животного',
        field: 'overdueEventsCount',
        hide: true,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
      },
      {
        headerName: 'Кол-во', field: 'totalCount', width: 70,
      },
      {
        headerName: 'Вид', field: 'animalTypeIdCaption', width: 180,
      },
      {
        headerName: 'Подвид', field: 'animalSubtypeIdCaption', width: 150,
      },
      {
        headerName: 'Местонахождение', field: 'stable', width: 200,
      },
      {
        headerName: 'Владение с', field: 'dateFrom', width: 120,
        valueFormatter: params => StringHelper.getRuDate(params.value),
      },
      {
        headerName: 'Владение по', field: 'dateTo', width: 120,
        valueFormatter: params => StringHelper.getRuDate(params.value),
      },
      ...EditAnimalComponent.getGridGroupingColumns(),
    ];
  }

  constructor(private animalDataService: AnimalDataService,
              private dataCachingService: DataCachingService,
              private waitingOverlay: GlobalWaitingOverlayService,
              private lookupSourceService: LookupSourceService,
              public navigationService: AppNavigationService,
              private alertService: AlertService) {
  }

  refreshFormData() {
    this.dataCachingService.removeCachedData('EditAgentAnimals', this.id.toString());
    this.refreshFormDataInternal();
  }

  refreshFormDataInternal() {
    this.waitingOverlay.StartWaiting();

    const existing = this.dataCachingService.getCachedData('EditAgentAnimals', this.id.toString());

    if (existing) {
      this.model = existing;
      this.waitingOverlay.EndWaiting();
    } else {
      this.lookupSourceService.getLookupObj('animal-type').subscribe(animalTypeLookup => {
        this.lookupSourceService.getLookupObj('animal-subtype').subscribe(animalSubtypeIdLookup  => {
        this.animalDataService.searchByAgent(this.id, this.model.showHistory)
          .subscribe({
            next: data => {

              data.forEach(el => {
                const animalSubtype = (animalSubtypeIdLookup[el.animalTypeId] || []).find(x => x.id === el.animalSubtypeId);
                el.animalTypeIdCaption = el.animalTypeId ? animalTypeLookup[el.animalTypeId] : '<<Не указан>>';
                el.animalSubtypeIdCaption = animalSubtype ? animalSubtype.caption : '<<не указан>>';

                el.totalCount = el.totalCount || 1;
              });

              this.model = {searchResults: data, showHistory: this.model.showHistory};
              this.dataCachingService.addToCache('EditAgentAnimals', this.id.toString(), this.model);
              this.waitingOverlay.EndWaiting();
            }, error: () => {
              this.waitingOverlay.EndWaiting();
            }
          });
        });
      });
    }
  }

  getRowStyle(params) {
    if (params.data && params.data.dateTo) {
      return {color: 'silver'};
    }
  }

  addNew() {
    this.navigationService.performAnimalCreation(this.id);
  }

  onGridSelectionChanged(params: any) {
    this.model.selectedRows = params.api.getSelectedRows();
  }

  confirmSelected() {
    this.alertService.confirmModal('Подтвердить выделенных животных?')
      .subscribe(val => {
        if (val) {
          this.animalDataService.confirmManyAnimalExternal(this.model.selectedRows).subscribe(() => this.refreshFormData());
        }
      });
  }
}
