import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { PstReportService } from '../../services/pst-report.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { StringHelper } from '../../../helpers/string-helper';
import { FormHelper } from '../../../ui/controls/form-helper';
import { PstReportStructureService } from '../../services/pst-report-structure.service';
import { NumericHelper } from '../../../helpers/numeric-helper';
import { DateHelper } from '../../../helpers/date-helper';

@Component({
  selector: 'app-branch-cell-edit',
  templateUrl: './pst-branch-cell-edit.component.html',
})
export class PstBranchCellEditComponent implements OnChanges {
  @Input() branch: any;
  @Input() model: any;
  @Input() reportIndicator: any;
  @Input() indicatorTypeMeta: any;
  @Input() indicatorLevel: any;
  @Input() editing: boolean;
  @Input() focused: boolean;

  @ViewChild('input') inputControl: ElementRef;

  formatRuNumeric = StringHelper.formatRuNumeric;
  processMoneyKeypress = FormHelper.processMoneyKeypress;

  valueStr = '';
  valueLoaded = false;
  valueUpdated = false;

  get originalCellValue() {
    if (!this.reportIndicator || !this.indicatorTypeMeta || !this.indicatorLevel) {
      return undefined;
    }
    const rec = this.model.values[this.code];
    return rec && (rec.value || rec.value === 0) ? parseFloat(rec.value) : undefined;
  }

  get originalCellData() {
    if (!this.reportIndicator || !this.indicatorTypeMeta || !this.indicatorLevel) {
      return undefined;
    }
    const rec = this.model.values[this.code];
    return rec;
  }

  get proposedCellValue() {
    if (!this.reportIndicator || !this.indicatorTypeMeta || !this.indicatorLevel) {
      return undefined;
    }
    const rec = this.model.values[this.code];
    return rec && (rec.proposedValue || rec.proposedValue === 0) ? parseFloat(rec.proposedValue) : undefined;
  }

  get cellValueStr() {
    if (!this.valueLoaded) {
      let value = this.originalCellValue;

      if (this.proposedCellValue || this.proposedCellValue === 0) {
        this.valueUpdated = true;
        value = this.proposedCellValue;
      }

      if (!value && value !== 0) {
        this.valueStr = '';
      } else {
        this.valueStr = value.toString().replace('.', ',');
      }

      this.valueLoaded = true;
    }

    return this.valueStr;
  }

  set cellValueStr(strValue: any) {
    this.valueUpdated = true;
    this.valueStr = strValue;
  }

  private setFocusedCell(indicatorLevel: any, colIndex: any, rowGroup: any) {
    this.model.__cl_focusedBranch = this.branch;
    this.model.__cl_focusedRowGroup = rowGroup;
    this.model.__cl_focusedIndicatorLevel = indicatorLevel;
    this.model.__cl_focusedIndicatorColIndex = colIndex;
    this.model.__cl_editingBranch = undefined;
    this.model.__cl_storing_error = undefined;
  }

  private get code() {
    return this.pstReportStructureService.getIndicatorValueCode(
      this.branch,
      this.indicatorLevel,
      this.reportIndicator,
      this.indicatorTypeMeta,
    );
  }

  constructor(
    private lookupSourceService: LookupSourceService,
    private navigationService: AppNavigationService,
    private pstReportService: PstReportService,
    private pstReportStructureService: PstReportStructureService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('editing')) {
      if (this.editing) {
        setTimeout(() => {
          if (this.inputControl) {
            this.inputControl.nativeElement.focus();
          }
        }, 100);
      }

      if (!this.editing && this.valueUpdated) {
        setTimeout(() => {
          this.updateCellValueInModel();
          this.valueLoaded = false;
          this.valueUpdated = false;
        }, 100);
      }
    }
  }

  inputKeyDown($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      // заканчиваем редактирование с записью измененного значения
      this.model.__cl_editingBranch = undefined;
      this.inputControl.nativeElement.closest('table').focus();

      this.setFocusedCell(
        this.model.__cl_editingIndicatorLevel,
        this.findNextEditableIndex(this.model.__cl_editingIndicatorColIndex, this.model.__cl_editingIndicatorLevel.indicatorTypeMeta),
        this.model.__cl_editingRowGroup);
    }
    if ($event.key === 'Escape') {
      // заканчиваем редактирование без записи измененного значения
      this.valueLoaded = false;
      this.valueUpdated = false;
      this.model.__cl_editingBranch = undefined;
      this.inputControl.nativeElement.closest('table').focus();
    }
  }

  updateCellValueInModel() {
    if (!this.reportIndicator || !this.indicatorTypeMeta || !this.indicatorLevel) {
      return;
    }

    let strValue = this.valueStr;

    strValue = (strValue || '').replace(/[^\d.,]/g, '')
      .replace(/[,]/g, '.')
      .replace(/[.](?=.*[.])/g, '');

    if (strValue.endsWith('.')) {
      strValue = strValue + '0';
    }

    let parsedVal;
    if (strValue) {
      parsedVal = NumericHelper.roundDecimal(parseFloat(strValue), this.indicatorTypeMeta.precision);
    }

    this.pstReportStructureService.storeIndicatorValue(this.model, this.reportIndicator, this.indicatorTypeMeta,
      this.indicatorLevel, parsedVal);
  }

  private findNextEditableIndex(startColIndex: any, indicatorTypeMetas: any[]) {
    if (!indicatorTypeMetas) {
      return startColIndex;
    }
    let colIndex = startColIndex;
    do {
      colIndex = colIndex + 1;
    } while (colIndex < indicatorTypeMetas.length && indicatorTypeMetas[colIndex].formula);

    return colIndex < indicatorTypeMetas.length ? colIndex : startColIndex;
  }

  getIndicatorHint() {
    if (!this.originalCellData || !this.originalCellData.state || this.originalCellData.state === 1) {
      return undefined;
    }
    const stateTitlePart = this.originalCellData.state === 10 ? 'направлен' : 'принят';
    return `Показатель ${stateTitlePart} ${StringHelper.getRuTimeWithSeconds(this.originalCellData.stateTime)}`;
  }
}
