<div class="itech-content-header">
  <clr-icon shape="two-way-arrows" size="36"></clr-icon>&nbsp;Замена одного СХП на другое в электронной отчетности
</div>

<div *ngIf="contextFormGroup" class="compact form" [formGroup]="contextFormGroup">

  <section class="form-block itech-block-medium itech-block-inline">

    <div class="form-group">
      <label class="required">Откуда (предприятие)</label>
      <app-link-button [id]="contextFormGroup.controls['sourceAgentId'].value"
                       [lookupName]="'agent'"></app-link-button>
      <button type="button" class="btn btn-danger btn-link itech-inline-tool-btn" title="Выбрать СХП - источник показателей"
              (click)="searchAgent('sourceAgentId')">
        <clr-icon shape="search"></clr-icon>
      </button>
    </div>

    <div class="form-group">
      <label class="required">Куда (предприятие)</label>
      <app-link-button [id]="contextFormGroup.controls['destinationAgentId'].value"
                       [lookupName]="'agent'"></app-link-button>
      <button type="button" class="btn btn-danger btn-link itech-inline-tool-btn" title="Выбрать СХП - получатель показателей"
              (click)="searchAgent('destinationAgentId')">
        <clr-icon shape="search"></clr-icon>
      </button>
    </div>

    <div class="form-group">
      <label for="filterRegionId">Муниципальное образование</label>
      <app-text-lookup class="itech-control-xlarge" [contextControlId]="'filterRegionId'" [sorted]="true"
                       [contextControlName]="'filterRegionId'" [lookupName]="'addr-region'"
                       [contextFormGroup]="contextFormGroup"></app-text-lookup>
    </div>

  </section>

  <div>
    <button [clrLoading]="model.started && !model.completed" [disabled]="model.started" class="btn btn-primary"
            (click)="runOperation()">Выполнить
    </button>
  </div>

  <div *ngIf="model.state && !model.completed">
    <div>
      Операция выполняется
    </div>

    <div class="progress labeled">
      <progress max="10000" value="{{model.state.progress}}" data-displayval="0%"></progress>
      <span>{{getPercProgress()}}%</span>
    </div>

    <div>
      {{model.state.message}}
    </div>
  </div>

  <div *ngIf="model.state && model.completed && model.result && model.result.stateId === 3">
    <clr-icon shape="check-circle" class="is-solid" style="color: green"></clr-icon>
    &nbsp;Операция завершена
    &nbsp;<button class="btn btn-link" (click)="navigateResults()">Скачать</button>
  </div>

  <div *ngIf="model.state && model.completed && model.result && model.result.stateId === 5">
    <clr-icon shape="exclamation-circle" class="is-solid" style="color: red"></clr-icon>
    Произошла ошибка при выполнении операции
    <br>
    <clr-icon shape="exclamation-circle" class="is-solid" style="color: red"></clr-icon>
    {{model.result.message}}
  </div>
</div>
