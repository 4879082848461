import { Component, OnInit } from '@angular/core';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { MetadataService } from '../../../logic/services/metadata.service';
import { environment } from '../../../../environments/environment';
import { UserService } from '../../../logic/services/user.service';
import { SecurityService } from '../../../logic/services/security.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  availableReports = [];
  availableMapReports = [];
  currentMapReport = undefined;
  moData = {};
  printing = false;

  constructor(public appNavigationService: AppNavigationService,
              private metadataService: MetadataService,
              public userService: UserService,
              public securityService: SecurityService) {
  }

  ngOnInit(): void {
    this.securityService.isAccess('dashboard')
      .subscribe(access => {
        if (access) {
          this.metadataService.getReports('query').subscribe(reports => {
            this.availableReports = reports;
          });
          this.metadataService.getReports('map').subscribe(reports => {
            this.availableMapReports = reports || [];
            if (this.availableMapReports.length) {
              this.availableMapReports.sort((a, b) => a.id - b.id);
              this.chooseMapReport(this.availableMapReports[0]);
            }
          });
        }
      });
  }

  printForm(report: any) {
    this.printing = true;

    this.metadataService.createReport({
      reportId: report.id,
      objId1: null,
      objId2: null
    })
      .subscribe(fileHash => {
        this.printing = false;
        window.open(environment.api + '/files/get?preloadId=' + encodeURIComponent(fileHash));
      }, () => {
        this.printing = false;
      });
  }

  chooseMapReport(report: any) {

    this.currentMapReport = report;

    this.metadataService.createJsonReport({
      reportId: report.id,
      objId1: null,
      objId2: null
    })
      .subscribe(data => {
        this.moData = {};

        data.forEach(el => {
          this.moData[el.region_id] = el;
        });
      }, () => {
      });
  }
}
