import { Component, Input, HostBinding } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';

@Component({
  selector: 'app-query-lookup-combo-filter',
  template: `
    <ng-container [formGroup]="contextFormGroup" *ngIf="contextFormGroup
    && (!queryUserMode || queryUserMode['_par_' + parameterName + '_filter_mq'])">
      <label [style.background]="filtered?'linear-gradient(to right, #90ff90, white)':undefined">
        <ng-content></ng-content>
        <div class="checkbox checkbox-inline" title="Запросить у пользователя" *ngIf="!queryUserMode">
          <input type="checkbox" id="{{'_par_' + parameterName + '_filter_mq'}}" [(ngModel)]="customFilterChecked"
                 [ngModelOptions]="{standalone: true}">
          <label for="{{'_par_' + parameterName + '_filter_mq'}}">?</label>
        </div>
        <div class="checkbox checkbox-inline" title="Значение отсутствует (не указано)" *ngIf="!queryUserMode">
          <input type="checkbox" id="{{'_par_' + parameterName + '_filter_novalue'}}" [(ngModel)]="noValueChecked"
                 [ngModelOptions]="{standalone: true}">
          <label for="{{'_par_' + parameterName + '_filter_novalue'}}">X</label>
        </div>
      </label>
      <div>
        <app-combo-lookup class="itech-control-xlarge" [contextControlId]="parameterName" [required]="false"
                          [contextControlName]="'_par_' + parameterName" [lookupName]="lookupName" [sorted]="true"
                          [contextFormGroup]="contextFormGroup" [disabled]="customFilterChecked"></app-combo-lookup>
      </div>
    </ng-container>
  `
})
export class QueryLookupComboFilterComponent {
  @Input() contextFormGroup: FormGroup;
  @Input() parameterName: string;
  @Input() lookupName: string;
  @Input() queryUserMode: false;

  @HostBinding('attr.class') class = 'form-group';

  isInvalid(cname: string) {
    return FormHelper.isInvalid(this.contextFormGroup, cname);
  }

  get customFilterChecked() {
    return this.contextFormGroup && (+this.contextFormGroup.get('_par_' + this.parameterName + '_filter').value) === 101;
  }

  set customFilterChecked(val: any) {
    if (this.contextFormGroup) {
      this.contextFormGroup.get('_par_' + this.parameterName + '_filter').setValue(val ? 101 : 0);
      this.contextFormGroup.markAsDirty();
    }
  }

  get noValueChecked() {
    return this.contextFormGroup && (+this.contextFormGroup.get('_par_' + this.parameterName + '_filter').value) === 1;
  }

  set noValueChecked(val: any) {
    if (this.contextFormGroup) {
      this.contextFormGroup.get('_par_' + this.parameterName + '_filter').setValue(val ? 1 : 0);
      this.contextFormGroup.markAsDirty();
    }
  }

  get filtered() {
    return !!this.contextFormGroup.get('_par_' + this.parameterName + '_filter').value
      || !!this.contextFormGroup.get('_par_' + this.parameterName).value;
  }
}
