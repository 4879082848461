
      <clr-modal [(clrModalOpen)]="esiaService.authMultipleInstitutionForUser" [clrModalClosable]="false" [clrModalSize]="''">
          <h3 class="modal-title">Выбор учреждения</h3>
          <div class="modal-body">
              <p style="padding-bottom: 20px">Пользователь зарегистрирован в нескольких учреждениях, необходимо выбрать, в каком авторизоваться</p>
              <div *ngFor="let institution of institutions">
                  <div>
                      <button class="btn btn-primary" type="button" (click)="login(institution.id)">Войти</button>
                      <label for="{{institution.id}}">{{institution.caption}}</label>
                  </div>
              </div>
          </div>
          <div class="modal-footer">
              <button class="btn btn-outline" type="button" (click)="logout()">Выйти</button>
          </div>
      </clr-modal>
  