/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@clr/angular";
import * as i2 from "../../../../node_modules/@clr/angular/clr-angular.ngfactory";
import * as i3 from "@angular/common";
import * as i4 from "./app-products-reports-button.component";
import * as i5 from "../infrastructure/job-runner-ui.service";
import * as i6 from "../../logic/services/lookup-source.service";
import * as i7 from "../../logic/services/app-navigation.service";
import * as i8 from "../infrastructure/alert.service";
var styles_AppProductsReportsButtonComponent = [];
var RenderType_AppProductsReportsButtonComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppProductsReportsButtonComponent, data: {} });
export { RenderType_AppProductsReportsButtonComponent as RenderType_AppProductsReportsButtonComponent };
function View_AppProductsReportsButtonComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "button", [["clrDropdownItem", ""], ["type", "button"]], [[8, "title", 0], [2, "disabled", null], [2, "dropdown-item", null], [1, "role", 0], [1, "aria-disabled", 0], [1, "disabled", 0], [1, "id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.concatDocumentsReports(_v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(1024, null, i1.ɵo, i1.ɵp, []), i0.ɵprd(512, [[1, 4]], i1.ɵr, i1.ɵt, [i1.ɵo, i0.ElementRef, i0.Renderer2, i0.PLATFORM_ID]), i0.ɵdid(3, 4341760, null, 0, i1.ClrDropdownItem, [i1.ClrDropdown, i0.ElementRef, i1.ɵe, i0.Renderer2, i1.ɵr], null, null), (_l()(), i0.ɵted(4, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.caption; var currVal_1 = i0.ɵnov(_v, 3).disabled; var currVal_2 = true; var currVal_3 = "menuitem"; var currVal_4 = i0.ɵnov(_v, 3).disabled; var currVal_5 = ((i0.ɵnov(_v, 3).disabled && i0.ɵnov(_v, 3).setByDeprecatedDisabled) ? "" : null); var currVal_6 = i0.ɵnov(_v, 3).dropdownItemId; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = _v.context.$implicit.caption; _ck(_v, 4, 0, currVal_7); }); }
function View_AppProductsReportsButtonComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "clr-dropdown-menu", [], [[2, "dropdown-menu", null], [1, "role", 0], [2, "is-off-screen", null]], null, null, i2.View_ClrDropdownMenu_0, i2.RenderType_ClrDropdownMenu)), i0.ɵdid(1, 9617408, null, 1, i1.ClrDropdownMenu, [i0.Injector, [2, i1.ɵh], [3, i1.ClrDropdownMenu], i1.ɵl], { position: [0, "position"] }, null), i0.ɵqud(603979776, 1, { items: 1 }), (_l()(), i0.ɵeld(3, 0, null, 0, 2, "label", [["class", "dropdown-header"]], [[1, "for", 0]], null, null, null, null)), i0.ɵdid(4, 212992, null, 0, i1.ClrLabel, [[2, i1.ɵbe], [2, i1.ɵbf], [2, i1.ɵbg], i0.Renderer2, i0.ElementRef], null, null), (_l()(), i0.ɵted(-1, null, ["\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u043F\u0435\u0447\u0430\u0442\u043D\u0443\u044E \u0444\u043E\u0440\u043C\u0443"])), (_l()(), i0.ɵand(16777216, null, 0, 2, null, View_AppProductsReportsButtonComponent_2)), i0.ɵdid(7, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_3 = "right-bottom"; _ck(_v, 1, 0, currVal_3); _ck(_v, 4, 0); var currVal_5 = i0.ɵunv(_v, 7, 0, i0.ɵnov(_v, 8).transform(_co.lookupSourceService.getLookup("report-product"))); _ck(_v, 7, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = "menu"; var currVal_2 = i0.ɵnov(_v, 1).isOffScreen; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = i0.ɵnov(_v, 4).forAttr; _ck(_v, 3, 0, currVal_4); }); }
export function View_AppProductsReportsButtonComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "clr-dropdown", [], [[2, "dropdown", null], [2, "open", null]], null, null, i2.View_ClrDropdown_0, i2.RenderType_ClrDropdown)), i0.ɵprd(6144, null, i1.ɵh, null, [i0.ElementRef]), i0.ɵprd(6144, null, i1.ɵr, null, [i1.ɵl]), i0.ɵprd(512, null, i1.ɵd, i1.ɵd, []), i0.ɵprd(1024, null, i1.ɵe, i1.ɵf, [[3, i1.ɵe]]), i0.ɵdid(5, 180224, null, 0, i1.ClrDropdown, [[3, i1.ClrDropdown], i1.ɵd, i0.ChangeDetectorRef, i1.ɵe], null, null), i0.ɵprd(1024, null, i1.ɵo, i1.ɵp, []), i0.ɵprd(1024, null, i1.ɵi, i1.ɵj, [[3, i1.ɵi], i0.Renderer2]), i0.ɵprd(131584, null, i1.ɵl, i1.ɵl, [i1.ɵo, i0.Renderer2, [3, i1.ɵl], i1.ɵd, i1.ɵi, i0.PLATFORM_ID]), (_l()(), i0.ɵeld(9, 0, null, 0, 2, "button", [["class", "btn btn-secondary"], ["clrDropdownToggle", ""]], [[8, "disabled", 0], [2, "dropdown-toggle", null], [2, "dropdown-item", null], [2, "expandable", null], [2, "active", null], [1, "aria-haspopup", 0], [1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 10).onDropdownTriggerClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(10, 16384, null, 0, i1.ClrDropdownTrigger, [i1.ClrDropdown, i1.ɵd, i0.ElementRef, i1.ɵl], null, null), (_l()(), i0.ɵted(-1, null, [" \u0420\u0430\u0441\u043F\u0435\u0447\u0430\u0442\u0430\u0442\u044C "])), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_AppProductsReportsButtonComponent_1)), i0.ɵdid(13, 147456, null, 0, i1.ClrIfOpen, [i1.ɵd, i0.TemplateRef, i0.ViewContainerRef], { open: [0, "open"] }, null)], function (_ck, _v) { var currVal_9 = null; _ck(_v, 13, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = i0.ɵnov(_v, 5).ifOpenService.open; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = ((_co.model.selectedRows && _co.model.selectedRows.length) ? undefined : true); var currVal_3 = i0.ɵnov(_v, 10).isRootLevelToggle; var currVal_4 = !i0.ɵnov(_v, 10).isRootLevelToggle; var currVal_5 = !i0.ɵnov(_v, 10).isRootLevelToggle; var currVal_6 = i0.ɵnov(_v, 10).active; var currVal_7 = "menu"; var currVal_8 = i0.ɵnov(_v, 10).active; _ck(_v, 9, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_AppProductsReportsButtonComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-products-reports-button", [], null, null, null, View_AppProductsReportsButtonComponent_0, RenderType_AppProductsReportsButtonComponent)), i0.ɵdid(1, 49152, null, 0, i4.AppProductsReportsButtonComponent, [i5.JobRunnerUiService, i6.LookupSourceService, i7.AppNavigationService, i8.AlertService], null, null)], null, null); }
var AppProductsReportsButtonComponentNgFactory = i0.ɵccf("app-products-reports-button", i4.AppProductsReportsButtonComponent, View_AppProductsReportsButtonComponent_Host_0, { model: "model" }, {}, []);
export { AppProductsReportsButtonComponentNgFactory as AppProductsReportsButtonComponentNgFactory };
