<ng-container [formGroup]="contextFormGroup">

  <div class="form-group" *ngIf="+(contextFormGroup.controls['agentPersonTypeId'].value) !== 1">
    <label for="{{rndToken}}agentPersonTypeId" class="required">Категория/Должность</label>
    <app-combo-lookup [validationTooltipRight]="true" class="itech-control-large"
                      [contextControlId]="rndToken + 'agentPersonTypeId'" [disabledChoices]="[1]"
                      [contextControlName]="'agentPersonTypeId'"
                      [lookupName]="'agent-person-type'" [contextFormGroup]="contextFormGroup"></app-combo-lookup>
  </div>

  <div class="form-group">
    <label for="{{rndToken}}lastName" class="required">Фамилия</label>
    <label for="{{rndToken}}lastName" aria-haspopup="true" role="tooltip"
           class="tooltip tooltip-validation tooltip-md"
           [class.invalid]="isInvalid(contextFormGroup, 'lastName')">
      <input type="text" id="{{rndToken}}lastName" placeholder="Фамилия" class="itech-control-large"
             formControlName="lastName">
      <app-validation-tooltip [input]="contextFormGroup.controls['lastName']"></app-validation-tooltip>
      <clr-icon *ngIf="syncState && syncState.mercuryUuid" shape="lock" style="color:gray"></clr-icon>
    </label>
  </div>

  <div class="form-group">
    <label for="{{rndToken}}firstName" [class.required]="+(contextFormGroup.controls['agentPersonTypeId'].value) === 1">Имя</label>
    <label for="{{rndToken}}firstName" aria-haspopup="true" role="tooltip"
           class="tooltip tooltip-validation tooltip-md"
           [class.invalid]="isInvalid(contextFormGroup, 'firstName')">
      <input type="text" id="{{rndToken}}firstName" placeholder="Имя" class="itech-control-large"
             formControlName="firstName">
      <app-validation-tooltip [input]="contextFormGroup.controls['firstName']"></app-validation-tooltip>
      <clr-icon *ngIf="syncState && syncState.mercuryUuid" shape="lock" style="color:gray"></clr-icon>
    </label>
  </div>

  <div class="form-group">
    <label for="{{rndToken}}middleName">Отчество</label>
    <label for="{{rndToken}}middleName" aria-haspopup="true" role="tooltip"
           class="tooltip tooltip-validation tooltip-md"
           [class.invalid]="isInvalid(contextFormGroup, 'middleName')">
      <input type="text" id="{{rndToken}}middleName" placeholder="Отчество" class="itech-control-large"
             formControlName="middleName">
      <app-validation-tooltip [input]="contextFormGroup.controls['middleName']"></app-validation-tooltip>
      <clr-icon *ngIf="syncState && syncState.mercuryUuid" shape="lock" style="color:gray"></clr-icon>
    </label>
  </div>

  <div class="form-group" *ngIf="+(contextFormGroup.controls['agentPersonTypeId'].value) === 1">
    <label for="{{rndToken}}birthDate">
      Дата рождения / Пол
    </label>
    <label for="{{rndToken}}birthDate" aria-haspopup="true" role="tooltip"
           class="tooltip tooltip-validation tooltip-md"
           [class.invalid]="isInvalid(contextFormGroup, 'birthDate')">
      <input type="date" id="{{rndToken}}birthDate" class="itech-control-normal"
             formControlName="birthDate">
      <app-validation-tooltip [input]="contextFormGroup.controls['birthDate']"></app-validation-tooltip>
    </label>
    <app-combo-lookup class="itech-control-small" [contextControlId]="rndToken + 'gender'"
                      [contextControlName]="'gender'" [lookupName]="'gender'"
                      [contextFormGroup]="contextFormGroup"></app-combo-lookup>
  </div>

  <div class="form-group" *ngIf="+(contextFormGroup.controls['agentPersonTypeId'].value) === 1">
    <label for="{{rndToken}}inn">
      ИНН
    </label>
    <label for="{{rndToken}}inn" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
           [class.invalid]="isInvalid(contextFormGroup, 'inn')">
      <input type="text" id="{{rndToken}}inn" class="itech-control-normal"
             formControlName="inn">
      <app-validation-tooltip [input]="contextFormGroup.controls['inn']"></app-validation-tooltip>
      <clr-icon *ngIf="syncState && syncState.mercuryUuid" shape="lock" style="color:gray"></clr-icon>
    </label>
  </div>

  <div class="form-group" *ngIf="+(contextFormGroup.controls['agentPersonTypeId'].value) === 1">
    <label for="{{rndToken}}identityDocSerial">
      Удостоверение личности
    </label>
    <label for="{{rndToken}}identityDocSerial" aria-haspopup="true" role="tooltip"
           class="tooltip tooltip-validation tooltip-md tooltip-top-left"
           [class.invalid]="isInvalid(contextFormGroup, 'identityDocSerial')">
      <input type="text" id="{{rndToken}}identityDocSerial" placeholder="Серия" class="itech-control-small"
             autocomplete="off" formControlName="identityDocSerial">
      <app-validation-tooltip [input]="contextFormGroup.controls['identityDocSerial']"></app-validation-tooltip>
    </label>
    <label for="{{rndToken}}identityDocNumber" aria-haspopup="true" role="tooltip"
           class="tooltip tooltip-validation tooltip-md tooltip-top-left"
           [class.invalid]="isInvalid(contextFormGroup, 'identityDocNumber')">
      <input type="text" id="{{rndToken}}identityDocNumber" placeholder="Номер" class="itech-control-small"
             autocomplete="off" formControlName="identityDocNumber">
      <app-validation-tooltip [input]="contextFormGroup.controls['identityDocNumber']"></app-validation-tooltip>
    </label>
    <label for="{{rndToken}}identityDocDate" aria-haspopup="true" role="tooltip"
           class="tooltip tooltip-validation tooltip-md tooltip-top-left"
           [class.invalid]="isInvalid(contextFormGroup, 'identityDocDate')">
      <input type="date" id="{{rndToken}}identityDocDate" formControlName="identityDocDate">
      <app-validation-tooltip [input]="contextFormGroup.controls['identityDocDate']"></app-validation-tooltip>
    </label>
  </div>

  <div class="form-group" *ngIf="+(contextFormGroup.controls['agentPersonTypeId'].value) === 1">
    <label for="{{rndToken}}identityDocIssuer">Кем выдано</label>
    <label for="{{rndToken}}identityDocIssuer" aria-haspopup="true" role="tooltip"
           class="tooltip tooltip-validation tooltip-md tooltip-top-left"
           [class.invalid]="isInvalid(contextFormGroup, 'identityDocIssuer')">
      <input type="text" id="{{rndToken}}identityDocIssuer" placeholder="Кем выдано удостоверение личности"
             class="itech-control-large"
             formControlName="identityDocIssuer">
      <app-validation-tooltip [input]="contextFormGroup.controls['identityDocIssuer']"></app-validation-tooltip>
    </label>
  </div>

</ng-container>
