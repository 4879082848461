import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AppFormGroup } from '../../../ui/controls/app-form-group';
import { AgentSearchModalComponent } from '../../edit-agent/search/agent-search-modal.component';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';
import { StableSearchModalComponent } from '../../edit-stable/search/stable-search-modal.component';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AppGridEditableComponent } from '../../../ui/controls/app-grid-editable.component';
import { EditProductCommonFormComponent } from './edit-product-common-form.component';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { RowNode } from '@ag-grid-community/all-modules';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { ProductDataService } from '../../../logic/services/product-data.service';
import { BehaviorSubject, combineLatest, of, ReplaySubject } from 'rxjs';
import { EditProductCommonComponent } from './edit-product-common.component';
import { StringHelper } from '../../../helpers/string-helper';
import { StableDataService } from '../../../logic/services/stable-data.service';
import { DateHelper } from '../../../helpers/date-helper';

@Component({
  selector: 'app-edit-product-source-form',
  templateUrl: './edit-product-source-form.component.html'
})
export class EditProductSourceFormComponent implements OnInit {

  private static productIdsUpdateFromMercuryAccumulator = [];
  private static productIdsUpdateFromMercuryTimer = undefined;
  private static stableProductSubtypeStableIdsUpdateFromMercuryAccumulator = [];
  private static stableProductSubtypeStableIdsUpdateFromMercuryTimer = undefined;

  @Input() contextFormGroup: AppFormGroup;
  @Input() productFormGroup: AppFormGroup;
  @Input() rndToken: string;
  @Input() validityControlsEvent = new EventEmitter();
  @Input() changeTransactionTypeEmitter = new EventEmitter();

  isInvalid = FormHelper.isInvalid;
  equalsSome = FormHelper.equalsSome;
  isHideDates = false;
  forceUpdateTransportTableDataEmitter = new EventEmitter();
  forceUpdateProductBatchesTableDataEmitter = new EventEmitter();
  processMoneyKeypress = FormHelper.processMoneyKeypressDot;

  productIdsForEditAccumulator = [];
  productIdsForEditTimer = undefined;
  transportDocForCreateAccumulator = [];
  transportDocForCreateTimer = undefined;

  sourceBatchGridDef = [
    {
      headerName: 'Документ',
      field: 'productId',
      width: 400,
      editorType: AppGridEditableComponent.EDITOR_TYPE_MODAL_SEARCH,
      lookupName: 'product',
      recalculateDetailHeightAfterChange: true,
      editable: () => true,
      searchParams: rowData => {
        return {
          stableId: EditProductCommonFormComponent.isProductionTransaction(this.productFormGroup)
            ? rowData && rowData.parent && rowData.parent.parent && rowData.parent.parent.contains('stableId')
              ? rowData.parent.parent.get('stableId').value
              : undefined
            : undefined,
        };
      },
      valueChangedCallback: (newValue, rowData, rowNode, gridApi) => {

        if (rowData && rowData.parent && rowData.parent.parent) {
          (rowData.parent.parent.get('producedBatches') as FormArray).controls.forEach(produced => {
            while ((produced.get('animalIds') as FormArray).length) {
              (produced.get('animalIds') as FormArray).removeAt(0);
            }
          });
          rowData.parent.parent.get('sourceBatches').value.forEach(source => {
            if (source.productId) {

              const existing = this.dataCachingService.getCachedData('EditProductCommon', source.productId.toString()) ||
                               this.dataCachingService.getCachedData('EditProductCommon_manyProductCache',
                                 source.productId.toString());

              const subject = new BehaviorSubject({});
              if (existing) {
                subject.next(existing.contextFormGroup ? existing.contextFormGroup.value : existing);
              } else {
                this.productIdsForEditAccumulator.push(newValue);

                if (this.productIdsForEditTimer) {
                  const tmpTimer = this.productIdsForEditTimer;
                  this.productIdsForEditTimer = undefined;
                  clearTimeout(tmpTimer);
                }

                this.productIdsForEditTimer = setTimeout(() => {
                  if (this.productIdsForEditAccumulator.length) {
                    const tmpIds = this.productIdsForEditAccumulator;
                    this.productIdsForEditAccumulator = [];

                    this.productDataService.getProductCommonForEdit2(Array.from(new Set(tmpIds))).subscribe(
                      records => {
                        records.forEach(val => {
                          this.dataCachingService.addToCache('EditProductCommon', source.productId.toString(), val);
                          this.dataCachingService.addToCache('EditProductCommon_manyProductCache', source.productId.toString(), val);
                          if (+val.id === +source.productId) {
                            subject.next(val);
                          }
                        });
                      });
                  }
                }, 500);
              }
              subject.subscribe(product => {
                if (product) {
                  (product['sources'] || []).forEach(ps => {

                    const existingTemplates = this.dataCachingService.getCachedData(
                      'EditStableProductionTemplateProductionStableProductSubtypes',
                      `${rowData.parent.parent.get('stableId').value}-${ps.productTypeId}-${ps.productSubtypeId}`);

                    (existingTemplates
                      ? of(existingTemplates)
                      : this.stableDataService.searchStableProductSubtypesFromProductionTemplates(
                        rowData.parent.parent.get('stableId').value, ps.productTypeId, ps.productSubtypeId))
                      .subscribe(stableProductSubtypeIds => {

                        this.dataCachingService.addToCache('EditStableProductionTemplateProductionStableProductSubtypes',
                          `${rowData.parent.parent.get('stableId').value}-${ps.productTypeId}-${ps.productSubtypeId}`,
                          stableProductSubtypeIds);

                        (stableProductSubtypeIds || []).forEach(sps => {
                          const existingSps = (rowData.parent.parent.controls['producedBatches'].controls || [])
                            .findIndex(el => +(el.get('stableProductSubtypeId').value) === sps.stableProductSubtypeId);

                          if (existingSps < 0) {
                            (rowData.parent.parent.controls['producedBatches'] as FormArray).controls.push(
                              this.fb.group(EditProductCommonFormComponent.buildProductBatch(this.fb,
                                {
                                  stableId: rowData.parent.parent.get('stableId').value,
                                  stableProductSubtypeId: sps.stableProductSubtypeId,
                                  unitId: sps.unitId,
                                  batchId: sps.batchId,
                                  manufactureDateKind: 1,
                                  manufactureDate: StringHelper.getISODateWithHourMinute(DateHelper.startDay(new Date())),
                                  expireDateKind: 1,
                                })));
                            this.forceUpdateProductBatchesTableDataEmitter.emit();
                          }
                          if (!rowData.parent.parent.controls['producedBatches'].controls[0].get('stableProductSubtypeId').value) {
                            (rowData.parent.parent.controls['producedBatches'] as FormArray).removeAt(0);
                          }
                        });

                        [].concat.apply([], (product['sources'] || []).map(s => s.sourceAnimalIds)).forEach(animalId => {

                          (rowData.parent.parent.get('producedBatches') as FormArray).controls.forEach(p => {
                            if (!p.get('animalIds').value.includes(animalId)) {
                              (p.get('animalIds') as FormArray).push(new FormControl(animalId));
                            }
                          });
                        });
                        this.forceUpdateProductBatchesTableDataEmitter.emit();
                      });
                  });
                }
              });
            }
          });
        }
        if (newValue) {
          EditProductSourceFormComponent.runBackgroundUpdateProductFromMercury(
            newValue, this.fb, this.productDataService, this.dataCachingService);
        }
      },
    },
    {
      headerName: 'Списываемое кол-во',
      field: 'writeOff',
      width: 170,
      editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_INPUT,
      keyPressCallback: FormHelper.processMoneyKeypressDot,
      required: params => EditProductCommonFormComponent.isProductionTransaction(this.productFormGroup) &&
                          params.data.get('productId').value,
      editable: () => true,
    },
  ];

  // если будет изменена высота после изменения содержимого master detail - откорректируй в this::masterDetailCalcHeightFunc,
  // иначе некоторые поля могут скрыться за границами
  productBatchGridDef = [
      {
        headerName: 'Животные',
        field: 'animalIds',
        editorType: AppGridEditableComponent.COMPONENT,
        isInnerForm: true,
        recalculateDetailHeightAfterChange: true,
        componentType: 2,
        copyToNewRow: true,
      },
      {
        field: 'stableId', hide: true, editorType: AppGridEditableComponent.HIDDEN_FIELD, copyToNewRow: true,
      },
      {
        field: 'isAnimalProductKind', hide: true, editorType: AppGridEditableComponent.HIDDEN_FIELD,
      },
      {
        headerName: 'Номенклатура',
        field: 'stableProductSubtypeId',
        width: 400,
        editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_LOOKUP,
        lookupName: 'stable-product-subtype/stable/false/',
        parentFieldLookup: 'stableId',
        requiredValue2: true,
        required: () => EditProductCommonFormComponent.isProductionTransaction(this.productFormGroup),
        editable: () => true,
        recalculateDetailHeightAfterChange: true,
        valueChangedCallback: (newValue, rowData, rowNode, gridApi) => {
          if (newValue && rowData.parent && rowData.parent.parent &&
            rowData.parent.parent.contains('stableId') && rowData.parent.parent.get('stableId').value) {

            combineLatest([
              this.lookupService.getLookupObj('stable-product-subtype/stable/false/' + rowData.parent.parent.get('stableId').value),
              this.lookupService.getLookupObj('product-subtype')])
              .subscribe(([lookupItems, productSubtypes]) => {

                const sps = lookupItems['Obj' + newValue];

                rowData.get('isAnimalProductKind').setValue(sps.productKindId === 3);
                if (!rowData.get('isAnimalProductKind').value && !rowData.get('manufactureDateKind').value) {
                  const manDate = DateHelper.startDay(new Date());
                  rowData.get('manufactureDateKind').setValue(1);
                  rowData.get('manufactureDate').setValue(StringHelper.getISODateWithHourMinute(manDate));
                  rowData.get('expireDateYear').setValue(manDate.getFullYear());
                  rowData.get('expireDateMonth').setValue(manDate.getMonth() + 1);
                  rowData.get('expireDateDay').setValue(manDate.getDate());
                }
                if (!rowData.get('isAnimalProductKind').value && !rowData.get('expireDateKind').value) {
                  rowData.get('expireDateKind').setValue(1);
                }

                const ps = productSubtypes[sps.productTypeId].find(ptst => ptst.id === sps.productSubtypeId);
                if (ps) {
                  if ((ps.availableManufactureProductDateKindIds || []).length) {
                    ps.availableManufactureProductDateKindIds.sort();
                    rowData.get('manufactureDateKind').setValue(
                      ps.availableManufactureProductDateKindIds.length > 1 && !ps.availableManufactureProductDateKindIds[0]
                        ? ps.availableManufactureProductDateKindIds[1]
                        : ps.availableManufactureProductDateKindIds[0]
                    );
                  }
                  if ((ps.availableExpireProductDateKindIds || []).length) {
                    ps.availableExpireProductDateKindIds.sort();
                    rowData.get('expireDateKind').setValue(
                      ps.availableExpireProductDateKindIds.length > 1 && !ps.availableExpireProductDateKindIds[0]
                        ? ps.availableExpireProductDateKindIds[1]
                        : ps.availableExpireProductDateKindIds[0]
                    );
                  }
                }
              });

          } else {
            rowData.get('isAnimalProductKind').setValue(false);
          }
        },
      },
      {
        headerName: 'Кол-во',
        field: 'valueCount',
        width: 125,
        editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_INPUT,
        keyPressCallback: FormHelper.processMoneyKeypressDot,
        required: () => EditProductCommonFormComponent.isProductionTransaction(this.productFormGroup),
        editable: () => true,
      },
      {
        headerName: 'Ед.изм.',
        field: 'unitId',
        width: 125,
        editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_LOOKUP,
        lookupName: 'drug-unit-type/product',
        required: () => EditProductCommonFormComponent.isProductionTransaction(this.productFormGroup),
        editable: () => true,
      },
      {
        headerName: 'Уровень/кол-во/тип/маркировки упаковки',
        field: 'packages',
        editorType: AppGridEditableComponent.COMPONENT,
        isInnerForm: true,
        recalculateDetailHeightAfterChange: true,
        componentType: 1,
        isVisible: data => !data.get('isAnimalProductKind').value,
      },
      {
        headerName: 'Время производства',
        field: 'manufactureDate',
        field2: 'manufactureDate2',
        editorType: AppGridEditableComponent.DATE_COMBO,
        isInnerForm: true,
        recalculateDetailHeightAfterChange: true,
        requiredDayAndHour: data => EditProductCommonFormComponent.isProductionTransaction(this.productFormGroup) &&
                                    EditProductSourceFormComponent.isRequiredDateField(data),
        isVisible: data => !data.get('isAnimalProductKind').value,
        defValueFunc: (data) => {
          data.get('manufactureDateKind').setValue(1);
          const manDate = DateHelper.startDay(new Date());
          data.get('manufactureDate').setValue(StringHelper.getISODateWithHourMinute(manDate));
          data.get('manufactureDateYear').setValue(manDate.getFullYear());
          data.get('manufactureDateMonth').setValue(manDate.getMonth() + 1);
          data.get('manufactureDateDay').setValue(manDate.getDate());
        },
        valueChangedCallback: (newValue, rowData, rowNode, gridApi) => {
          if (rowData.get('stableProductSubtypeId').value &&
              rowData.parent && rowData.parent.parent && rowData.parent.parent.get('stableId').value) {
            this.lookupService.getLookupObj('stable-product-subtype/stable/false/' + rowData.parent.parent.get('stableId').value)
              .subscribe(spsLookup => {
                const sps = spsLookup['Obj' + rowData.get('stableProductSubtypeId').value];
                if ((sps || {}).calcExpireDate) {
                  const manDate = rowData.get('manufactureDate').value;
                  const calcDate = DateHelper.calcDateByFormula(sps.calcExpireDate, manDate ? new Date(manDate) : undefined);
                  if (calcDate) {
                    rowData.get('expireDateYear').setValue(calcDate.getFullYear());
                    rowData.get('expireDateMonth').setValue(calcDate.getMonth() + 1);
                    rowData.get('expireDateDay').setValue(calcDate.getDate());
                    rowData.get('expireDate').setValue(StringHelper.getISODateWithHourMinute(calcDate));
                  }
                }
              });
          }
        }
      },
      {
        headerName: 'Срок годности',
        field: 'expireDate',
        field2: 'expireDate2',
        editorType: AppGridEditableComponent.DATE_COMBO,
        isInnerForm: true,
        recalculateDetailHeightAfterChange: true,
        requiredDayAndHour: data => EditProductCommonFormComponent.isProductionTransaction(this.productFormGroup) &&
                                    EditProductSourceFormComponent.isRequiredDateField(data),
        isVisible: data => !data.get('isAnimalProductKind').value,
        required: params => true,
        defValueFunc: (data) => data.get('expireDateKind').setValue(1),
      },
      {
        headerName: 'Срок годности (доп)',
        hideHeader: true,
        field: 'perishable',
        caption: 'Скоропортящаяся продукция',
        editorType: AppGridEditableComponent.CHECKBOX,
        isInnerForm: true,
        isVisible: data => FormHelper.equalsSome(+data.get('expireDateKind').value, 1, 2, 3) &&
          !data.get('isAnimalProductKind').value,
      },
      {
        headerName: 'Номер произв.партии',
        field: 'batchId',
        editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_INPUT,
        isInnerForm: true,
        sizeControl: 'itech-control-larger',
        required: () => EditProductCommonFormComponent.isProductionTransaction(this.productFormGroup),
      },
    ];

  // если будет изменена высота после изменения содержимого master detail - откорректируй в this::transportMasterDetailCalcHeightFunc,
  // иначе некоторые поля могут скрыться за границами
  transportDocsGridDef = [
    {
      headerName: 'Место отправления',
      field: 'stableId',
      width: 150,
      editorType: AppGridEditableComponent.EDITOR_TYPE_MODAL_SEARCH,
      lookupName: 'stable',
      editable: (rowNode) => {
        if (!rowNode || !rowNode.data || !rowNode.data.parent || !rowNode.data.parent.parent) {
          return true;
        }
        return +rowNode.data.parent.parent.get('transactionType').value !== 3 && !rowNode.data.get('productId').value;
      },
      required: () => false,
      cellClassRules: {
        'app-ag-grid-no-editable-cell': params => params && params.data && params.data.parent && params.data.parent.parent &&
          +params.data.parent.parent.get('transactionType').value === 3 && !params.data.get('productId').value,
      },
    },
    {
      headerName: 'Документ',
      field: 'productId',
      width: 300,
      editorType: AppGridEditableComponent.EDITOR_TYPE_MODAL_SEARCH,
      lookupName: 'product',
      searchParams: rowData => {
        return {
          stableId: !EditProductCommonFormComponent.isProductionTransaction(this.productFormGroup)
            ? rowData.parent && rowData.parent.parent
              ? rowData.parent.parent.get('originStableId').value || rowData.get('stableId').value
              : rowData.get('stableId').value
            : undefined,
          availableChangeStableId: true,
        };
      },
      editable: () => true,
      required: params =>
        (!params.data.parent || !params.data.parent.parent && !params.data.parent.parent.get('isTemplate').value) &&
        !EditProductCommonFormComponent.isProductionTransaction(this.productFormGroup),
      valueChangedCallback: (newValue, rowData, rowNode, gridApi) => {
        if (newValue && rowData && rowData.parent && rowData.parent.parent) {
          if (!this.dataCachingService.getCachedData(`getTransportDocumentForCreate_${newValue}`,
            rowData.parent.parent.get('transactionType').value)) {
            this.dataCachingService.addToCache(`getTransportDocumentForCreate_${newValue}`,
              rowData.parent.parent.get('transactionType').value, new BehaviorSubject({}));

            this.transportDocForCreateAccumulator.push(newValue);

            if (this.transportDocForCreateTimer) {
              const tmpTimer = this.transportDocForCreateTimer;
              this.transportDocForCreateTimer = undefined;
              clearTimeout(tmpTimer);
            }

            this.transportDocForCreateTimer = setTimeout(() => {
              if (this.transportDocForCreateAccumulator.length) {
                const tmpIds = this.transportDocForCreateAccumulator;
                this.transportDocForCreateAccumulator = [];

                this.productDataService.getTransportDocumentForCreate2(
                  +rowData.parent.parent.get('transactionType').value,
                  Array.from(new Set(tmpIds)),
                ).subscribe(records => records.forEach(val => {
                  this.dataCachingService.getCachedData(`getTransportDocumentForCreate_${val.productId}`,
                    rowData.parent.parent.get('transactionType').value).next(val);
                }));
              }
            }, 500);
          }
          this.dataCachingService.getCachedData(`getTransportDocumentForCreate_${newValue}`,
            rowData.parent.parent.get('transactionType').value)
            .subscribe(val => {
              if (+rowData.parent.parent.get('transactionType').value === 1) { // Перевозка со сменой владельца
                val['destinationAgentId'] = rowData.get('destinationAgentId').value;
                val['destinationStableId'] = rowData.get('destinationStableId').value;
              } else  if (+rowData.parent.parent.get('transactionType').value === 2) { // Перевозка без смены владельца
                val['destinationStableId'] = rowData.get('destinationStableId').value;
              } else if (+rowData.parent.parent.get('transactionType').value === 3) { // Смена владельца без перевозки
                val['destinationAgentId'] = rowData.get('destinationAgentId').value;
              }
              val['valueCount'] = rowData.get('valueCount').value || val['valueCount'];
              val['locationProsperity'] = rowData.get('locationProsperity').value || val['locationProsperity'];
              val['productReasonId'] = rowData.get('productReasonId').value || val['productReasonId'];

              const stableProductSubtypeId = rowData.get('stableProductSubtypeId').value || val['stableProductSubtypeId'];
              const stableProductSubtypeIdSource = rowData.get('stableProductSubtypeIdSource').value || val['stableProductSubtypeId'];

              if (val['stableId'] && stableProductSubtypeId && stableProductSubtypeIdSource) {
                setTimeout(() => {
                  this.lookupService.getLookup('stable-product-subtype/stable/with-bonus/true/' +
                    val['stableId'] + '/' + stableProductSubtypeIdSource).subscribe(items => {
                    const stableProductSubtype = (items || []).find(el => el.id === +stableProductSubtypeId);

                    val['productTypeId'] = stableProductSubtype ? stableProductSubtype.productTypeId : undefined;

                    rowData.patchValue(EditProductCommonFormComponent.buildTransportDocumentsGroupDefs(this.fb, val).value);

                    while (rowData.get('packages').value.length) {
                      (rowData.get('packages') as FormArray).removeAt(0);
                    }
                    [...(val.packages || []), {}].forEach(pack => rowData.get('packages')
                      .push(EditProductCommonFormComponent.buildPackage(this.fb, pack, true)));

                    rowData.get('loaded').setValue(true);

                    gridApi.refreshCells({force: true});
                  });
                }, 700);
              }
            });
          EditProductSourceFormComponent.runBackgroundUpdateProductFromMercury(
            newValue, this.fb, this.productDataService, this.dataCachingService);
        }
      },
    },
    {
      field: 'stableId', hide: true, editorType: AppGridEditableComponent.HIDDEN_FIELD, copyToNewRow: true,
    },
    {
      headerName: 'Кол-во',
      field: 'valueCount',
      width: 75,
      editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_INPUT,
      keyPressCallback: FormHelper.processMoneyKeypressDot,
      required: params =>
        (!params.data.parent || !params.data.parent.parent && !params.data.parent.parent.get('isTemplate').value) &&
        !EditProductCommonFormComponent.isProductionTransaction(this.productFormGroup) &&
        params.data.get('productId').value,
      editable: () => true,
    },
    {
      headerName: 'Получатель',
      field: 'destinationAgentId',
      width: 180,
      editorType: AppGridEditableComponent.EDITOR_TYPE_MODAL_SEARCH,
      lookupName: 'agent',
      editable: (rowNode) => {
        if (!rowNode || !rowNode.data || !rowNode.data.parent || !rowNode.data.parent.parent) {
          return true;
        }
        return +rowNode.data.parent.parent.get('transactionType').value !== 2;
      },
      required: params =>
        (!params.data.parent || !params.data.parent.parent && !params.data.parent.parent.get('isTemplate').value) &&
        !EditProductCommonFormComponent.isProductionTransaction(this.productFormGroup) &&
        params.data.get('productId').value,
      searchParams: rowData => {
        return {
          stableId: !EditProductCommonFormComponent.isProductionTransaction(this.productFormGroup)
            ? rowData.get('destinationStableId').value
            : undefined,
          availableChangeStableId: true,
          forceAutoStart: !EditProductCommonFormComponent.isProductionTransaction(this.productFormGroup)
            ? !!rowData.get('destinationStableId').value
            : undefined,
        };
      },
      callbackAfterChangeValue: params => {
        if (params.data.get('destinationAgentId').value && !params.data.get('destinationStableId').value) {
          setTimeout(() =>
            params.api.startEditingCell({rowIndex: params.rowIndex, colKey: 'destinationStableId'}),
          500);
        }
      },
      cellClassRules: {
        'app-ag-grid-no-editable-cell': params => params && params.data && params.data.parent && params.data.parent.parent &&
                                                  +params.data.parent.parent.get('transactionType').value === 2,
      },
    },
    {
      headerName: 'Место получения',
      field: 'destinationStableId',
      width: 180,
      editorType: AppGridEditableComponent.EDITOR_TYPE_MODAL_SEARCH,
      lookupName: 'stable',
      editable: (rowNode) => {
        if (!rowNode || !rowNode.data || !rowNode.data.parent || !rowNode.data.parent.parent) {
          return true;
        }
        return +rowNode.data.parent.parent.get('transactionType').value !== 3;
      },
      required: params =>
        (!params.data.parent || !params.data.parent.parent && !params.data.parent.parent.get('isTemplate').value) &&
        !EditProductCommonFormComponent.isProductionTransaction(this.productFormGroup) &&
        params.data.get('productId').value,
      searchParams: rowData => {
        return {
          agentId: !EditProductCommonFormComponent.isProductionTransaction(this.productFormGroup)
            ? rowData.get('destinationAgentId').value
            : undefined,
          availableChangeAgentId: true,
          forceAutoStart: !EditProductCommonFormComponent.isProductionTransaction(this.productFormGroup)
            ? !!rowData.get('destinationAgentId').value
            : undefined,
        };
      },
      callbackAfterChangeValue: params => {
        if (params.data.get('destinationStableId').value && !params.data.get('destinationAgentId').value) {
          setTimeout(() =>
              params.api.startEditingCell({rowIndex: params.rowIndex, colKey: 'destinationAgentId'}),
            500);
        }
      },
      cellClassRules: {
        'app-ag-grid-no-editable-cell': params => params && params.data && params.data.parent && params.data.parent.parent &&
                                                  +params.data.parent.parent.get('transactionType').value === 3,
      },
    },
    {
      headerName: 'Номенклатура',
      field: 'stableProductSubtypeId',
      editorType: AppGridEditableComponent.APP_COMBO_LOOKUP,
      lookupName: 'stable-product-subtype/stable/with-bonus/true/',
      parentFieldLookup: 'stableId',
      parentField2Lookup: 'stableProductSubtypeIdSource',
      isInnerForm: true,
      required: params =>
        (!params.data.parent || !params.data.parent.parent && !params.data.parent.parent.get('isTemplate').value) &&
        !EditProductCommonFormComponent.isProductionTransaction(this.productFormGroup) &&
        params.data.get('productId').value,
    },
    {
      field: 'stableProductSubtypeIdSource', hide: true, editorType: AppGridEditableComponent.HIDDEN_FIELD, copyToNewRow: true,
    },
    {
      headerName: 'Уровень/кол-во/тип/маркировки упаковки',
      field: 'packages',
      editorType: AppGridEditableComponent.COMPONENT,
      isInnerForm: true,
      recalculateDetailHeightAfterChange: true,
      componentType: 1,
    },
    {
      headerName: 'Серия/номер защ. бланка',
      field: 'protectedDocSerial',
      editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_INPUT,
      isInnerForm: true,
      sizeControl: 'itech-control-small',
    },
    {
      headerName: 'Серия/номер защ. бланка',
      field: 'protectedDocNo',
      editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_INPUT,
      isInnerForm: true,
      sizeControl: 'itech-control-small',
    },
    {
      headerName: 'Вет.сан. экспертиза',
      field: 'expertiseStatus',
      editorType: AppGridEditableComponent.APP_COMBO_LOOKUP,
      lookupName: 'product-expertise-status',
      isInnerForm: true,
      required: params => true,
    },
    {
      headerName: 'Вет.сан. экспертиза',
      field: 'cargoInspected',
      caption: 'Контроль гос. ветврачом',
      editorType: AppGridEditableComponent.CHECKBOX,
      isInnerForm: true,
    },
    {
      headerName: 'Благополучие местности',
      field: 'locationProsperity',
      editorType: AppGridEditableComponent.TEXT_AREA,
      isInnerForm: true,
      required: params => true,
    },
    {
      headerName: 'Нахождение в таможенной зоне',
      field: 'animalSpentPeriodCode',
      editorType: AppGridEditableComponent.APP_COMBO_LOOKUP,
      lookupName: 'animal-spent-period',
      isInnerForm: true,
    },
    {
      headerName: 'Нахождение в таможенной зоне',
      hideHeader: true,
      field: 'monthsSpent',
      placeholder: 'мес',
      editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_INPUT,
      isInnerForm: true,
      sizeControl: 'itech-control-small',
      isVisible: data => data.get('animalSpentPeriodCode').value === 'IN_MONTHS',
    },
    {
      headerName: 'Карантин',
      field: 'quarantineDuration',
      placeholder: 'Дни',
      editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_INPUT,
      onlyDigits: true,
      isInnerForm: true,
      sizeControl: 'itech-control-small',
    },
    {
      headerName: 'Карантин',
      placeholder: 'Информация',
      field: 'quarantineComments',
      editorType: AppGridEditableComponent.TEXT_AREA,
      isInnerForm: true,
      isVisible: data => +data.get('quarantineDuration').value > 0,
    },
    {
      headerName: 'Некачественная продукция',
      hideHeader: true,
      field: 'badQuality',
      caption: 'Некачественная продукция',
      editorType: AppGridEditableComponent.CHECKBOX,
      isInnerForm: true,
    },
    {
      headerName: 'Цель производства/сбыта',
      field: 'productReasonId',
      editorType: AppGridEditableComponent.APP_COMBO_LOOKUP,
      lookupName: 'product-reason',
      filterArrayControlName: 'mercuryGuid',
      filterArray: data => +data.get('productTypeId').value === 609
        ? ['8670b7f0-5274-4d68-02a1-30262a34402f', '98ecd86b-01ff-e3b0-28ed-018fc16a0af0', '0bd9d889-66c3-eeca-c580-78687dd9aea0',
        'b208516c-437d-a5c0-791d-b9096f67bd69', 'cc6ed069-340b-c83e-78aa-0ef09bb61af6'] : [],
      isInnerForm: true,
      sizeControl: 'itech-control-xlarge',
      required: params => true,
    },
    {
      headerName: 'Комментарии',
      field: 'comments',
      editorType: AppGridEditableComponent.TEXT_AREA,
      isInnerForm: true,
    },
    {
      headerName: 'Другой ТТН',
      hideHeader: true,
      field: 'otherTtn',
      caption: 'Другой ТТН',
      editorType: AppGridEditableComponent.CHECKBOX,
      recalculateDetailHeightAfterChange: true,
      isInnerForm: true,
    },
    {
      headerName: 'Вид тов.-трансп. накладной',
      field: 'ttnTypeId',
      editorType: AppGridEditableComponent.APP_COMBO_LOOKUP,
      lookupName: 'ttn-type',
      recalculateDetailHeightAfterChange: true,
      isVisible: data => data.get('otherTtn').value,
      isInnerForm: true,
      sizeControl: 'itech-control-large',
    },
    {
      headerName: 'Серия номер и дата ТТН',
      field: 'ttnSerial',
      editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_INPUT,
      isInnerForm: true,
      isVisible: data => data.get('ttnTypeId').value,
      sizeControl: 'itech-control-small',
    },
    {
      headerName: 'Серия номер и дата ТТН',
      field: 'ttnNo',
      editorType: AppGridEditableComponent.EDITOR_TYPE_TEXT_INPUT,
      isInnerForm: true,
      isVisible: data => data.get('ttnTypeId').value,
      sizeControl: 'itech-control-small',
    },
    {
      headerName: 'Серия номер и дата ТТН',
      field: 'ttnDate',
      editorType: AppGridEditableComponent.DATE_PICKER,
      withTime: false,
      isInnerForm: true,
      isVisible: data => data.get('ttnTypeId').value,
      sizeControl: 'itech-control-normal',
    },
  ];

  get productId() {
    return this.productFormGroup.get('id').value;
  }

  get isDraft() {
    return !this.productId || !this.productFormGroup.get('status').value || +this.productFormGroup.get('status').value === -100;
  }

  get productTypeId(): FormControl {
    return this.contextFormGroup.get('productTypeId') as FormControl;
  }

  get batchIds(): FormArray {
    return this.contextFormGroup.get('batchId') as FormArray;
  }

  get isProductionTransaction() {
    return EditProductCommonFormComponent.isProductionTransaction(this.productFormGroup);
  }

  get isTransportOrMovingTransaction() {
    return EditProductCommonFormComponent.isTransportOrMovingTransaction(this.productFormGroup);
  }

  get packages() {
    return this.contextFormGroup.get('packages') as FormArray;
  }

  get manyProducts(): FormArray {
    return this.productFormGroup.get('manyProducts') as FormArray;
  }

  get transportDocuments(): FormArray {
    return this.productFormGroup.get('transportDocuments') as FormArray;
  }

  get lookupNameStableProductSubtype(): string {
    const stableId = this.productFormGroup.get('producers').value.length
           ? this.productFormGroup.get('producers').value[0].stableId
           : this.isProductionTransaction
             ? this.productFormGroup.get('originStableId').value
             : undefined;
    const sps = this.contextFormGroup.get('stableProductSubtypeId').value;

    return stableId
      ? `stable-product-subtype/stable/include-unknown/${stableId}/${sps ? sps : 'null'}`
      : '';
  }

  public static isRequiredDateField(formGroup: FormGroup): boolean {
    return formGroup.get('perishable').value;
  }

  public static runBackgroundUpdateProductFromMercury(newValue,
                                                      fb: FormBuilder,
                                                      productDataService: ProductDataService,
                                                      dataCachingService: DataCachingService) {

    this.productIdsUpdateFromMercuryAccumulator.push(newValue);

    if (this.productIdsUpdateFromMercuryTimer) {
      const tmpTimer = this.productIdsUpdateFromMercuryTimer;
      this.productIdsUpdateFromMercuryTimer = undefined;
      clearTimeout(tmpTimer);
    }

    this.productIdsUpdateFromMercuryTimer = setTimeout(() => {
      if (this.productIdsUpdateFromMercuryAccumulator.length) {
        const tmpIds = this.productIdsUpdateFromMercuryAccumulator;
        this.productIdsUpdateFromMercuryAccumulator = [];

        productDataService.backgroundUpdateProductFromMercury2(tmpIds).subscribe(
          records => {
            records.forEach(val => {
              const existing = dataCachingService.getCachedData('EditProductCommon', val.id.toString()) ||
                EditProductCommonComponent.getDefaultModel(val.id);
              existing.contextFormGroup = EditProductCommonFormComponent.createFormGroup(fb, existing, val);
              dataCachingService.addToCache('EditProductCommon', val.id.toString(), existing);
              dataCachingService.addToCache('EditProductCommon_manyProductCache', val.id.toString(), val);
            });
            productDataService.getProductCommonForLookup2(tmpIds).subscribe(
              records2 => records2.forEach(val2 =>
                EditProductSourceFormComponent.updateCacheProduct(val2.id, val2, dataCachingService)));
          });
      }
    }, 500);
  }

  private static updateCacheProduct(productId: number, newValue: string, dataCachingService: DataCachingService) {
    const cacheProduct = new ReplaySubject();
    dataCachingService.addToCache('AddressPersonFioCacheService_Product', productId.toString(10), cacheProduct);
    cacheProduct.next(newValue);
  }

  public static runBackgroundUpdateStableProductSubtypeFromMercury(stableId: number,
                                                                   stableDataService: StableDataService,
                                                                   lookupService: LookupSourceService) {
    this.stableProductSubtypeStableIdsUpdateFromMercuryAccumulator.push(stableId);

    if (this.stableProductSubtypeStableIdsUpdateFromMercuryTimer) {
      const tmpTimer = this.stableProductSubtypeStableIdsUpdateFromMercuryTimer;
      this.stableProductSubtypeStableIdsUpdateFromMercuryTimer = undefined;
      clearTimeout(tmpTimer);
    }

    this.stableProductSubtypeStableIdsUpdateFromMercuryTimer = setTimeout(() => {
      if (this.stableProductSubtypeStableIdsUpdateFromMercuryAccumulator.length) {
        const tmpIds = this.stableProductSubtypeStableIdsUpdateFromMercuryAccumulator;
        this.stableProductSubtypeStableIdsUpdateFromMercuryAccumulator = [];

        stableDataService.backgroundUpdateStableProductSubtype(tmpIds)
          .subscribe(records => {
            records.forEach(val => {
              lookupService.invalidateLookup('stable-product-subtype/stable/false/' + val);
            });
          });
      }
    }, 500);
}

  constructor(public navigationService: AppNavigationService,
              private cacheService: AddressPersonFioCacheService,
              public lookupService: LookupSourceService,
              private fb: FormBuilder,
              private alertService: AlertService,
              private dataCachingService: DataCachingService,
              private productDataService: ProductDataService,
              private stableDataService: StableDataService) {

  }

  ngOnInit() {
    this.changeProductTypeId();
    this.changeTransactionTypeEmitter.subscribe(() => {
      this.forceUpdateTransportTableDataEmitter.emit();
      this.changeProductTypeId();
    });
  }

  public get sourceAnimalIds(): FormArray {
    return this.contextFormGroup.get('sourceAnimalIds') as FormArray;
  }

  public get sourceProductIds(): FormArray {
    return this.contextFormGroup.get('sourceProductIds') as FormArray;
  }

  searchAgent() {
    this.navigationService.searchAgent(AgentSearchModalComponent,
      {
        stableId: this.contextFormGroup.get('destinationStableId').value,
      }
      ).subscribe(val => {
      if (val) {
        this.contextFormGroup.get('destinationAgentId').setValue(val);
        this.contextFormGroup.markAsDirty();
      }
    });
  }

  searchStableByAgent(agentId: number) {
    this.cacheService.getAgent(agentId).subscribe(agent =>
      this.searchStable({ owner: agent.id ? agent.caption : '', agentId: agent.id})
        .subscribe(val => {
          if (val) {
            this.contextFormGroup.get('destinationStableId').setValue(val);
            this.contextFormGroup.markAsDirty();
          }
        }));
  }

  searchStable(params: any = {}) {
    return this.navigationService.searchStable(StableSearchModalComponent, params);
  }

  changeProductTypeId() {
    if (!this.productTypeId.value || !this.isTransportOrMovingTransaction) {
      this.isHideDates = false;
      return;
    }
    this.lookupService.getLookupObj('product-type/' + this.productTypeId.value)
      .subscribe(value => this.isHideDates = !this.productId && value.kind === 3);
  }

  addNewTransaction() {
    this.searchStable()
      .subscribe(val => {
        if (val) {
          this.manyProducts.push(EditProductCommonFormComponent.buildManyProductsGroupDefs(this.fb, {stableId: val}));
          EditProductSourceFormComponent.runBackgroundUpdateStableProductSubtypeFromMercury(val,
            this.stableDataService, this.lookupService);
        }
      });
  }

  removeManyProduct(index: number) {
    this.alertService.confirmModal('Удалить животное со всеми вырабатываемыми продукциями?')
      .subscribe(val => {
        if (val) {
          this.manyProducts.removeAt(index);
        }
      });
  }

  copyManyProductForNewStable(manyProduct: FormGroup) {

    this.searchStable()
      .subscribe(val => {
        if (val) {
          const data = Object.assign({}, manyProduct.value);
          data.producedBatches = manyProduct.get('producedBatches').value;
          data.sourceBatches = manyProduct.get('sourceBatches').value;
          if (+data.stableId !== val) {
            data.stableId = val;
            data.producedBatches.forEach(p => {
              p.stableId = val;
              p.stableProductSubtypeId = null;
            });
          } else {
            data.producedBatches.forEach(p => p.stableId = val);
          }
          this.manyProducts.push(EditProductCommonFormComponent.buildManyProductsGroupDefs(this.fb, data));
        }
      });
  }

  masterDetailCalcHeightFunc(node: RowNode) {
    const packHeight = (node.data.get('packages').value || [])
      .map(x => 37 + (x.expandedMark ? 37 * x.markings.length : 0))
      .reduce((partialSum, a) => partialSum + a, 0);
    const manufactureDateHeight = 72 + (+node.data.get('manufactureDateKind').value === 3 ? 36 : 0);
    const expireDateHeight = 72 + (+node.data.get('expireDateKind').value === 3 ? 36 : 0);
    const animalsHeight = node.data.get('animalIds').value
      ? (node.data.get('animalIds').value.length + 1) * 17
      : 0;

    return node && node.data
      ? node.data.get('isAnimalProductKind').value
        ? 65 + animalsHeight
        : 100 + packHeight + manufactureDateHeight + expireDateHeight + animalsHeight
      : 0;
  }

  transportMasterDetailCalcHeightFunc(node: RowNode) {
    const packHeight = (node.data.get('packages').value || [])
      .map(x => 37 + (x.expandedMark ? 37 * x.markings.length : 0))
      .reduce((partialSum, a) => partialSum + a, 0);

    const isShowQuarantineInfo = node.data.get('animalSpentPeriodCode').value === 'IN_MONTHS';
    const otherTtn = node.data.get('otherTtn').value;
    const ttnTypeId = node.data.get('ttnTypeId').value;

    return 5 + (packHeight || 0) + (isShowQuarantineInfo ? 37 : 0) + (otherTtn ? +ttnTypeId ? 100 : 60 : 17) + 343;
  }

  isRequiredDateField(formGroup: FormGroup): boolean {
    return EditProductSourceFormComponent.isRequiredDateField(formGroup);
  }

  availableRowMasterDetailTransport(node: RowNode) {
    if (!node.data.get('loaded').value) {
      return undefined;
    }
    return node.data.get('productId').value;
  }

  transportGridReady(event: any) {
    if (!this.productFormGroup.get('fromTemplate').value) {
      this.forceUpdateTransportTableDataEmitter.emit();
    }
  }

  isNeedMainTtn() {
    const find = this.productFormGroup.get('transportDocuments').value.find(doc => !doc.otherTtn);
    if (!find) {
      this.contextFormGroup.get('needMainTtn').setValue(false);
    } else {
      this.contextFormGroup.get('needMainTtn').setValue(true);
    }
  }
}
