/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@clr/angular";
import * as i2 from "@angular/common";
import * as i3 from "./unavailable.component";
import * as i4 from "../../logic/services/security.service";
import * as i5 from "@angular/router";
var styles_UnavailableComponent = [];
var RenderType_UnavailableComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_UnavailableComponent, data: {} });
export { RenderType_UnavailableComponent as RenderType_UnavailableComponent };
function View_UnavailableComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "h1", [["style", "color: red"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "clr-icon", [["shape", "sad-face"], ["size", "36"]], null, null, null, null, null)), i0.ɵdid(3, 16384, null, 0, i1.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, [" \u0421\u0435\u0440\u0432\u0435\u0440 \u043D\u0435\u0434\u043E\u0441\u0442\u0443\u043F\u0435\u043D"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "h4", [["style", "color: red"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u041F\u043E\u0436\u0430\u043B\u0443\u0439\u0441\u0442\u0430, \u043F\u043E\u043F\u0440\u043E\u0431\u0443\u0439\u0442\u0435 \u043E\u0431\u043D\u043E\u0432\u0438\u0442\u044C \u0441\u0442\u0440\u0430\u043D\u0438\u0446\u0443 \u043F\u0440\u0438\u043B\u043E\u0436\u0435\u043D\u0438\u044F, \u0447\u0442\u043E\u0431\u044B \u043F\u0440\u043E\u0434\u043E\u043B\u0436\u0438\u0442\u044C \u0440\u0430\u0431\u043E\u0442\u0443."]))], null, null); }
export function View_UnavailableComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_UnavailableComponent_1)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.unavailable; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_UnavailableComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_UnavailableComponent_0, RenderType_UnavailableComponent)), i0.ɵdid(1, 114688, null, 0, i3.UnavailableComponent, [i4.SecurityService, i5.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UnavailableComponentNgFactory = i0.ɵccf("ng-component", i3.UnavailableComponent, View_UnavailableComponent_Host_0, {}, {}, []);
export { UnavailableComponentNgFactory as UnavailableComponentNgFactory };
