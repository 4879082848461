<table *ngIf="branch" class="it-fed-header-tbl" style="width: 820px;padding: 10px;border-radius: 10px">
  <tr>
    <td colspan="3" class="it-fed-header-cell" style="width: 120px">
      Отправить на адрес:<br>
      {{this.branch.meta.params['send-email']}}
    </td>
    <td colspan="1" class="it-fed-header-skip" style="width: 40px">
    </td>
    <td colspan="8" class="it-fed-header-cell it-bold" style="width: 320px">
      СИСТЕМА ГОСУДАРСТВЕННОГО ИНФОРМАЦИОННОГО ОБЕСПЕЧЕНИЯ В СФЕРЕ СЕЛЬСКОГО ХОЗЯЙСТВА
    </td>
    <td colspan="2" class="it-fed-header-skip" style="width: 80px">
    </td>
    <td colspan="6" rowspan="2" class="it-fed-header-cell it-left" style="width: 240px">
      Ответственный: _____________<br>
      тел. _____________<br>
      e-mail: ___________<br>
    </td>
  </tr>
  <tr>
    <td colspan="20" class="it-fed-header-skip-row">
    </td>
  </tr>
  <tr>
    <td colspan="4" class="it-fed-header-skip" style="width: 160px">
    </td>
    <td colspan="8" class="it-fed-header-cell" style="width: 320px">
      КОНФИДЕНЦИАЛЬНОСТЬ ГАРАНТИРУЕТСЯ ПОЛУЧАТЕЛЕМ ИНФОРМАЦИИ
    </td>
    <td colspan="8" class="it-fed-header-skip" style="width: 320px">
    </td>
  </tr>
  <tr>
    <td colspan="20" class="it-fed-header-skip-row">
    </td>
  </tr>
  <tr>
    <td colspan="2" class="it-fed-header-skip">
    </td>
    <td colspan="12" class="it-fed-header-cell it-bold">
      {{branch.displayTitle}}
    </td>
    <td colspan="6" class="it-fed-header-skip">
    </td>
  </tr>
  <tr>
    <td colspan="20" class="it-fed-header-skip-row">
    </td>
  </tr>
  <tr>
    <td colspan="8" class="it-fed-header-cell it-bold" style="width: 320px">
      Представляют:
    </td>
    <td colspan="4" class="it-fed-header-cell it-bold" style="width: 160px">
      Сроки представления:
    </td>
    <td colspan="4" class="it-fed-header-skip" style="width: 160px">
    </td>
    <td colspan="4" class="it-fed-header-cell it-bold" style="width: 160px">
      {{this.branch.meta.params['form-code']}}
    </td>
  </tr>
  <tr>
    <td colspan="8" rowspan="2" class="it-fed-header-cell it-left" style="width: 320px">
      {{this.branch.meta.params['form-sender-meta']}}
    </td>
    <td colspan="4" rowspan="2" class="it-fed-header-cell it-bold" style="width: 160px">
      {{this.branch.meta.params['form-dates']}}
    </td>
    <td colspan="4" rowspan="2" class="it-fed-header-skip" style="width: 160px">
    </td>
    <td colspan="4" rowspan="1" class="it-fed-header-skip" style="width: 160px">
    </td>
  </tr>
  <tr>
    <td colspan="4" class="it-fed-header-cell it-bold">
      {{this.branch.meta.params['form-period']}}
    </td>
  </tr>
  <tr>
    <td colspan="20" class="it-fed-header-skip-row">
    </td>
  </tr>
  <tr>
    <td colspan="6" class="it-fed-header-cell it-bold" style="width: 240px">
      Наименование отчитывающейся организации:
    </td>
    <td colspan="10" class="it-fed-header-cell it-bold" style="width: 400px">
      {{this.branch.meta.params['form-sender-title']}}
    </td>
    <td colspan="4" class="it-fed-header-skip" style="width: 160px">
    </td>
  </tr>
  <tr>
    <td colspan="6" class="it-fed-header-cell it-bold" style="width: 240px">
      Почтовый адрес:
    </td>
    <td colspan="10" class="it-fed-header-cell it-bold" style="width: 400px">
    </td>
    <td colspan="4" class="it-fed-header-skip" style="width: 160px">
    </td>
  </tr>
  <tr>
    <td colspan="2" rowspan="2" class="it-fed-header-cell" style="width: 80px">
      Код формы по ОКУД
    </td>
    <td colspan="14" class="it-fed-header-cell" style="width: 560px">
      Код
    </td>
    <td colspan="4" class="it-fed-header-skip" style="width: 160px">
    </td>
  </tr>
  <tr>
    <td colspan="2" class="it-fed-header-cell" style="width: 80px">
      отчитывающейся организации по ОКПО
    </td>
    <td colspan="2" class="it-fed-header-cell" style="width: 80px">
      вида деятельности по ОКВЭД
    </td>
    <td colspan="2" class="it-fed-header-cell" style="width: 80px">
      территории по ОКАТО
    </td>
    <td colspan="2" class="it-fed-header-cell" style="width: 80px">
      министерства (ведомства), органа управления по ОКОГУ
    </td>
    <td colspan="2" class="it-fed-header-cell" style="width: 80px">
      организационно-правовой формы по ОКОПФ
    </td>
    <td colspan="2" class="it-fed-header-cell" style="width: 80px">
      формы собственности по ОКФС
    </td>
    <td colspan="2" class="it-fed-header-cell" style="width: 80px">
    </td>
    <td colspan="4" class="it-fed-header-skip" style="width: 160px">
    </td>
  </tr>
  <tr>
    <td colspan="2" class="it-fed-header-cell" style="width: 80px">
      1
    </td>
    <td colspan="2" class="it-fed-header-cell" style="width: 80px">
      2
    </td>
    <td colspan="2" class="it-fed-header-cell" style="width: 80px">
      3
    </td>
    <td colspan="2" class="it-fed-header-cell" style="width: 80px">
      4
    </td>
    <td colspan="2" class="it-fed-header-cell" style="width: 80px">
      5
    </td>
    <td colspan="2" class="it-fed-header-cell" style="width: 80px">
      6
    </td>
    <td colspan="2" class="it-fed-header-cell" style="width: 80px">
      7
    </td>
    <td colspan="2" class="it-fed-header-cell" style="width: 80px">
      8
    </td>
    <td colspan="4" class="it-fed-header-skip" style="width: 160px">
    </td>
  </tr>
  <tr>
    <td colspan="2" class="it-fed-header-cell" style="width: 80px">

    </td>
    <td colspan="2" class="it-fed-header-cell" style="width: 80px">

    </td>
    <td colspan="2" class="it-fed-header-cell" style="width: 80px">

    </td>
    <td colspan="2" class="it-fed-header-cell" style="width: 80px">
      {{this.branch.meta.params['form-sender-okato']}}
    </td>
    <td colspan="2" class="it-fed-header-cell" style="width: 80px">

    </td>
    <td colspan="2" class="it-fed-header-cell" style="width: 80px">

    </td>
    <td colspan="2" class="it-fed-header-cell" style="width: 80px">

    </td>
    <td colspan="2" class="it-fed-header-cell" style="width: 80px">

    </td>
    <td colspan="4" class="it-fed-header-skip" style="width: 160px">
    </td>
  </tr>
</table>
