import { Component, OnInit } from '@angular/core';
import { SecurityService } from '../../logic/services/security.service';
import { DataCachingService } from '../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../ui/infrastructure/global-waiting-overlay.service';
import { BookmarkService } from '../../logic/services/bookmark.service';
import { DrugDataService } from '../../logic/services/drug-data.service';
import { AppNavigationService } from '../../logic/services/app-navigation.service';
import { AgGridLookupRendererComponent } from '../../ui/controls/ag-grid-lookup-renderer.component';

@Component({
  selector: 'app-user-reserved-drugs',
  template: `
      <div class="itech-common-wrap" id="interceptNoAccess">
        <div id="agent-common-content">
          <div class="itech-doc-container" style="min-height:100%">
            <ng-container *ngIf="reservedDrugs && reservedDrugs.length; else noReservedDrugs">
              <div style="padding:5px;width:100%;min-height:88%">
                <app-grid [rowData]="reservedDrugs" [columnDefs]="gridColumnDefs" (rowClicked)="navigateToDrug($event.data.id)"
                          [frameworkComponents]="frameworkComponents" [uniqueGridName]="'ReservedUserGrid'"
                          [exportFileName]="'Выданные препараты ' + (userService.getUserInfo()|async).id">
                </app-grid>
              </div>
            </ng-container>
            <ng-template #noReservedDrugs>
              У Вас не имеется выданных препаратов
            </ng-template>
          </div>
        </div>

        <div class="itech-form-actions">
          <div>
            <button class="btn btn-link btn-primary" (click)="refreshFormData()">
              <clr-icon shape="refresh" class="has-badge"></clr-icon>&nbsp;Обновить список
            </button>
          </div>
        </div>
      </div>
  `
})
export class UserReservedDrugsComponent implements OnInit {

  reservedDrugs: any[];

  frameworkComponents = {
    lookupRenderer: AgGridLookupRendererComponent,
  };

  gridColumnDefs = [
    {
      headerName: 'Препарат',
      field: 'id',
      cellRenderer: 'lookupRenderer',
      cellRendererParams: (row) => {
        return {
          value: row.value,
          lookupName: 'drug',
          isPipe: true,
        };
      },
      width: 500,
    },
    {
      headerName: 'Количество',
      field: 'currentValue',
      width: 140,
    },
    {
      headerName: 'Ед.изм.',
      field: 'drugUnitTypeId',
      cellRenderer: 'lookupRenderer',
      cellRendererParams: (row) => {
        return {
          value: row.value,
          lookupName: 'drug-unit-type/drug',
        };
      },
      width: 140,
    },
  ];

  constructor(public userService: SecurityService,
              private dataCachingService: DataCachingService,
              private globalWaitingOverlayService: GlobalWaitingOverlayService,
              private bookmarkService: BookmarkService,
              private drugDataService: DrugDataService,
              private navigationService: AppNavigationService) {
  }

  ngOnInit() {
    this.refreshFormDataInternal();
  }

  refreshFormData() {
    this.userService.getUserInfo().subscribe(user => {
      this.dataCachingService.removeCachedData('EditDrugCommon', user.id);
      this.refreshFormDataInternal();
    });
  }

  refreshFormDataInternal() {
    this.globalWaitingOverlayService.StartWaiting();
    this.userService.getUserInfo().subscribe(user => {
      const existing = this.dataCachingService.getCachedData('reservedUserDrugs', user.id);

      if (existing) {
        this.reservedDrugs = existing;
        this.globalWaitingOverlayService.EndWaiting();
      } else {
        this.drugDataService.getReservedByCurrentUser()
          .subscribe(res => {
            this.reservedDrugs = res;
            this.dataCachingService.addToCache('productStatistic', user.id, this.reservedDrugs);
            this.globalWaitingOverlayService.EndWaiting();
          });
      }
    });
  }

  navigateToDrug(drugId: number) {
    this.navigationService.performDrugEditing(drugId);
  }
}
