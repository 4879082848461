import { EventEmitter, Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { BehaviorSubject, Observable } from 'rxjs';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { StringHelper } from '../../../helpers/string-helper';
import { DateHelper } from '../../../helpers/date-helper';
import { InstitutionTicketDataService } from '../../../logic/services/institution-ticket-data.service';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';

@Injectable({
  providedIn: 'root',
})
export class TicketsPlanningUiService {
  editTicketModalOpened = false;
  deleteTicketModalOpened = false;
  modalValidationErrorsHost = {serverSideValidationErrors: []};
  editedTicket: any;
  ticketFormGroup: FormGroup;
  ticketReadonly: boolean;

  actionTicketEmitter: EventEmitter<boolean> = undefined;

  storing = false;
  deleting = false;

  public static getGroupDef(fb: FormBuilder, data: any) {
    return fb.group({
      id: data.id,
      isTemplate: data.isTemplate || !!data.templateCaption || false,
      isDistribute: data.isDistribute || false,
      templateCaption: [data.templateCaption, FormHelper.conditionalValidate(Validators.required,
        ctrl => ctrl.parent && ctrl.parent.get('isTemplate').value)],
      createdTime: data.createdTime,
      createdUser: data.createdUser,
      startTime: [data.startTime, Validators.compose([FormHelper.validateDateTimePicker(),
        FormHelper.conditionalValidate(Validators.required,
          ctrl => ctrl.parent && !ctrl.parent.get('isTemplate').value && !ctrl.parent.get('isDistribute').value )])],
      durationMinutes: [data.durationMinutes,  Validators.compose([Validators.pattern(/^\d{1,4}$/),
        Validators.min(0), Validators.max(1440),
        FormHelper.conditionalValidate(Validators.required,
          ctrl => ctrl.parent && !ctrl.parent.get('isTemplate').value && !ctrl.parent.get('isDistribute').value )])],
      institutionId: [data.institutionId, Validators.compose([Validators.required])],
      institutionBranchId: [data.institutionBranchId, Validators.compose([FormHelper.conditionalValidate(Validators.required,
        ctrl => ctrl.parent && !ctrl.parent.get('isTemplate').value && !ctrl.parent.get('isDistribute').value )])],
      institutionEmployeeId: [data.institutionEmployeeId, Validators.compose([FormHelper.conditionalValidate(Validators.required,
        ctrl => ctrl.parent && !ctrl.parent.get('isTemplate').value && !ctrl.parent.get('isDistribute').value )])],
      institutionTicketQuestIds: fb.array(data.institutionTicketQuestIds || []),
      selectedInstitutionTicketQuestIds: fb.array(data.selectedInstitutionTicketQuestIds || []),
      cabinetNumber: [data.cabinetNumber, Validators.compose([FormHelper.conditionalValidate(Validators.required,
        ctrl => ctrl.parent && !ctrl.parent.get('isTemplate').value && !ctrl.parent.get('isDistribute').value )])],
      comment: data.comment,
      animalTypeId: data.animalTypeId,
      animalSubtypeId: data.animalSubtypeId,
      unavailableTime: [data.unavailableTime, Validators.compose([FormHelper.validateDateTimePicker(),
        FormHelper.conditionalValidate(Validators.required,
          ctrl => ctrl.parent && !ctrl.parent.get('isTemplate').value && !ctrl.parent.get('isDistribute').value)])],
      agentId: data.agentId,
      animalId: data.animalId,
      isAnimalExternal: data.isAnimalExternal || false,
      reservationTime: [data.reservationTime, FormHelper.validateDateTimePicker()],
      reservationUser: data.reservationUser,
      cancelTime: [data.cancelTime, FormHelper.validateDateTimePicker()],
      cancelUser: data.cancelUser,
      cancelCaption: [data.cancelCaption],
      dateSuccessful: [data.dateSuccessful, FormHelper.validateDateTimePicker()],
      userSuccessful: data.userSuccessful,
      durationTypeId: [data.durationTypeId, FormHelper.conditionalValidate(Validators.required,
        ctrl => ctrl.parent && ctrl.parent.get('isDistribute').value)],
      daysOfWeek: fb.array(data.daysOfWeek || []),
      periodicityTypeId: [data.periodicityTypeId, FormHelper.conditionalValidate(Validators.required,
        ctrl => ctrl.parent && ctrl.parent.get('isDistribute').value)],
      inclusiveTimeDayStart: [data.inclusiveTimeDayStart, FormHelper.conditionalValidate(Validators.required,
        ctrl => ctrl.parent && ctrl.parent.get('isDistribute').value)],
      inclusiveTimeDayEnd: [data.inclusiveTimeDayEnd],
      exclusiveTimeDayStart: [data.exclusiveTimeDayStart],
      exclusiveTimeDayEnd: [data.exclusiveTimeDayEnd],
      isDeleteInsteadAdding: data.isDeleteInsteadAdding || false,
      unavailableHour: [data.unavailableHour,  Validators.compose([Validators.pattern(/^\d{1,3}$/),
        Validators.min(1), Validators.max(336),
        FormHelper.conditionalValidate(Validators.required, ctrl => ctrl.parent && ctrl.parent.get('isDistribute').value)])],
    });
  }

  constructor(private fb: FormBuilder,
              public lookupService: LookupSourceService,
              private institutionTicketDataService: InstitutionTicketDataService,
              private alertService: AlertService,
              private cacheService: AddressPersonFioCacheService) {
  }

  addTicket(slot: any, model: any, oneDay?: any) {
    const startDate = slot ? this.calculateFirstEmptyTime(slot.day.date, slot) : oneDay.date;

    this.editTicket({
      data: {
        startTime: StringHelper.getISODateWithHourMinute(startDate),
        unavailableTime: StringHelper.getISODateWithHourMinute(DateHelper.addDays(startDate, -1)),
        institutionId: model.institutionId,
        institutionBranchId: model.searchParams.get('institutionBranchId').value,
        institutionEmployeeId: model.institutionEmployeeId,
      },
    });
  }

  editTicket(data: any, readonly = false, actionTicketEmitter?: EventEmitter<boolean>) {
    this.editedTicket = data;
    this.ticketReadonly = readonly;
    this.editTicketModalOpened = true;
    this.modalValidationErrorsHost.serverSideValidationErrors = [];
    this.ticketFormGroup = TicketsPlanningUiService.getGroupDef(this.fb, this.editedTicket.data);
    this.actionTicketEmitter = actionTicketEmitter;
  }

  getSlotCaption(value: any): Observable<string> {
    const retVal = new BehaviorSubject<string>('');

    if (value && value.tickets && value.tickets.length > 0) {
      this.getSlotTicketCaption(value.timeStart, value.tickets, retVal);
    } else {
      retVal.next(value.timeStart + ' <<< НЕ РАСПРЕДЕЛЕНО >>>');
    }

    return retVal;
  }

  getSlotTicketCaption(time: string, tickets: any[], retVal: BehaviorSubject<string>) {
    if (tickets.length === 0) {
      return;
    }

    if (tickets.length === 1) {
      if (tickets[0].data.agentId) {
        this.cacheService.getAgent(tickets[0].data.agentId)
          .subscribe(val => {
            retVal.next(`${time} ${val.shortCaption || val.caption}`);
          });
      } else {
        retVal.next(`${time} 1 ${(tickets[0].data.cancelTime ? 'ОТМ.' : 'СВОБ.')}`);
      }
    } else {
      let reservationCount = 0;
      let cancelCount = 0;

      tickets.filter(x => {
        if (x.data.cancelTime) {
          cancelCount++;
        } else if (x.data.agentId) {
          reservationCount++;
        }
      });

      let caption = '';
      const isHaveFree = tickets.length > (reservationCount + cancelCount);

      if (isHaveFree) {
        caption += (tickets.length - reservationCount) + ' СВОБ.';
      }

      if (reservationCount > 0) {
        caption += (isHaveFree ? ' | ' : '') + reservationCount + ' ЗАРЕЗ.';
      }

      if (cancelCount > 0) {
        caption += (isHaveFree || reservationCount > 0 ? ' | ' : '') + cancelCount + ' ОТМ.';
      }

      retVal.next(time + ' ' + caption);
    }
  }

  createTemplateFromCurrentTicket() {
    this.createTemplate(Object.assign({}, this.ticketFormGroup.value));
  }

  createTemplate(data: any) {
    data.id = undefined;
    data.isTemplate = true;
    data.isDistribute = false;
    this.editTicket({ data: data } );
  }

  addEditTicketCompleted(model: any, successEmitter?: EventEmitter<boolean>) {

    FormHelper.markAsSubmitted(this.ticketFormGroup);

    if (!this.ticketFormGroup.valid) {
      return;
    }

    this.editedTicket.newData = this.ticketFormGroup.value;
    this.storing = true;
    (!this.editedTicket.newData.isTemplate
      ? this.institutionTicketDataService.storeTicket(model.days, this.editedTicket)
      : this.institutionTicketDataService.storeTicketTemplate(this.editedTicket))
      .subscribe({
      next: () => {
        if (this.editedTicket.newData.isTemplate) {
          this.institutionTicketDataService.refreshTemplates(model);
        }
        this.storingSuccess(successEmitter);
      },
      error: err => {
        this.storing = false;
        FormHelper.setSingleFormGroupServerSideValidationErrors(err, this.modalValidationErrorsHost, this.ticketFormGroup);
      }
    });
  }

  public calculateFirstEmptyTime(day: Date, slot: any): Date {
    let maxTicketEndDate = DateHelper.dateOf(day, slot.timeStart);
    const maxAllowedEndDate = DateHelper.dateOf(day, slot.timeEnd);
    slot.tickets.forEach(ticket => {
      const ticketEndDate = new Date(ticket.data.endDate);

      // если у нас есть услуги, полностью перекрывающие слот, просто возвращаем время начала слота (возможно,
      // пользователь хочет "наслоить" оказания, и/или в начале слота есть пустое место)
      if (ticketEndDate > maxAllowedEndDate) {
        return DateHelper.dateOf(day, slot.timeStart);
      } else if (ticketEndDate > maxTicketEndDate) {
        maxTicketEndDate = ticketEndDate;
      }
    });

    return maxTicketEndDate;
  }

  public deleteOrCancelTicketModal(data: any, model: any) {
    if (data.data.reservationTime) {
      this.editTicketModalOpened = false;
      this.deleteTicketModalOpened = true;
    } else {
      this.deleteTicket(model);
    }
  }

  public deleteTicket(model: any) {
    this.alertService.confirmModal('Вы действительно хотите удалить талон ?')
      .subscribe(() => {
        this.editedTicket.newData = this.ticketFormGroup.value;
        this.storing = true;
        this.institutionTicketDataService.deleteTicket(model.days, this.editedTicket.newData).subscribe({
          next: () => this.storingSuccess(this.actionTicketEmitter),
          error: err => {
            this.storing = false;
            FormHelper.setSingleFormGroupServerSideValidationErrors(err, this.modalValidationErrorsHost, this.ticketFormGroup);
          }
        });
      });
  }

  public deleteTicketTemplate(data: any, model: any) {
    this.alertService.confirmModal('Вы действительно хотите удалить шаблон талонов ?')
      .subscribe(val => {
        if (val) {
          if (!this.editedTicket) {
            this.editedTicket = {};
          }
          this.editedTicket.newData = data;
          this.storing = true;
          this.institutionTicketDataService.deleteTicketTemplate(this.editedTicket.newData).subscribe({
            next: () => {
              this.institutionTicketDataService.refreshTemplates(model);
              this.storingSuccess();
            },
            error: err => {
              this.storing = false;
              FormHelper.setSingleFormGroupServerSideValidationErrors(err, this.modalValidationErrorsHost, this.ticketFormGroup);
            }
          });
        }
      });
  }

  public distributeTickets(model: any, successEmitter?: EventEmitter<boolean>) {
    this.alertService.confirmModal('Вы действительно хотите распределить талоны по шаблону ?')
      .subscribe(val => {
        if (val) {
          if (!this.editedTicket) {
            this.editedTicket = {};
          }
          this.editedTicket.newData = this.ticketFormGroup.value;
          this.storing = true;

          this.institutionTicketDataService.distributeTickets(this.editedTicket.newData).subscribe({
            next: () => this.storingSuccess(successEmitter),
            error: err => {
              this.storing = false;
              FormHelper.setSingleFormGroupServerSideValidationErrors(err, this.modalValidationErrorsHost, this.ticketFormGroup);
            }
          });
        }
      });
  }

  public closeEditModal() {
    this.editTicketModalOpened = false;
  }

  public markSuccessful() {
    this.alertService.confirmModal('Вы действительно хотите подтвердить факт приёма по талону ?')
      .subscribe(val => {
        if (val) {
          if (!this.editedTicket) {
            return;
          }

          this.storing = true;

          this.institutionTicketDataService.markSuccessful(this.ticketFormGroup.get('id').value).subscribe({
            next: () => this.storingSuccess(this.actionTicketEmitter),
            error: err => {
              this.storing = false;
              FormHelper.setSingleFormGroupServerSideValidationErrors(err, this.modalValidationErrorsHost, this.ticketFormGroup);
            }
          });
        }
      });
  }

  storingSuccess(successEmitter?: EventEmitter<boolean>) {
    this.storing = false;
    this.editTicketModalOpened = false;
    this.deleteTicketModalOpened = false;
    this.editedTicket = undefined;
    this.ticketFormGroup = undefined;
    if (successEmitter) {
      successEmitter.emit(true);
    }
  }
}
