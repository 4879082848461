import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { FormArray, FormBuilder } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { ServerSideErrorsProvider } from '../../../logic/validators/server-side-errors-provider';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';
import { EditStableCommonFormComponent } from './edit-stable-common-form.component';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { AppFormGroup } from '../../../ui/controls/app-form-group';
import { StableDataService } from '../../../logic/services/stable-data.service';
import { StableSearchModalComponent } from '../search/stable-search-modal.component';
import { MetadataService } from '../../../logic/services/metadata.service';

@Component({
  selector: 'app-edit-stable-common',
  templateUrl: './edit-stable-common.component.html'
})
export class EditStableCommonComponent implements ServerSideErrorsProvider, OnChanges, OnInit, OnDestroy {
  @Input() id: number;
  @Input() newAgentId: number;

  model: any = {};
  storing = false;
  serverSideValidationErrors: any[];

  updateSyncStateTimer: any = {};
  updateSyncStatePending: any = {};

  isInvalid = FormHelper.isInvalid;

  clrModalOpen = false;
  formGroupMerge = this.fb.group({
    mergeSourceId: null
  }) as AppFormGroup;
  selectedCheckboxConfirmAction = false;

  get contextFormGroup(): AppFormGroup {
    return this.model ? this.model.contextFormGroup : undefined;
  }

  get syncStates(): any[] {
    return this.model && this.model.syncStates ? Object.values(this.model.syncStates) : undefined;
  }

  constructor(private bookmarkService: BookmarkService,
              private stableDataService: StableDataService,
              private alertService: AlertService,
              private cache: AddressPersonFioCacheService,
              private dataCachingService: DataCachingService,
              private waitingOverlay: GlobalWaitingOverlayService,
              private fb: FormBuilder,
              public navigationService: AppNavigationService,
              private metadataService: MetadataService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.id) {
      this.refreshFormDataInternal();
    }
  }

  store() {

    FormHelper.markAsSubmitted(this.contextFormGroup);

    if (!this.contextFormGroup.valid) {
      return;
    }

    this.storing = true;

    this.stableDataService.storeStableCommonInfo(this.contextFormGroup.value).subscribe(val => {
      this.storing = false;
      this.cache.invalidateStable(this.id);
      (this.contextFormGroup.get('agents') as FormArray).controls.forEach(agentCtrl => {
        const agentId = agentCtrl.get('agentId').value;
        if (agentId) {
          this.dataCachingService.removeCachedData('EditAgentStables', agentId.toString());
        }
      });
      this.bookmarkService.refreshStableCardBookmark(this.id, this.newAgentId);
      this.contextFormGroup.markAsUntouched();
      this.contextFormGroup.markAsPristine();
      FormHelper.setSingleFormGroupServerSideValidationErrors({}, this.model, this.contextFormGroup);
      this.updateModelSyncStateFromLookup(val);
      this.refreshSyncState(10);
      this.alertService.success(`Общая информация для [${this.contextFormGroup.get('caption').value}] успешно сохранена`);
    }, error => {
      this.storing = false;
      FormHelper.setSingleFormGroupServerSideValidationErrors(error, this.model, this.contextFormGroup);
      window.document.getElementById('stable-common-content').scrollTop = 0;
    });
  }

  cancelEdit() {
    this.dataCachingService.removeCachedData('EditStableCommon', this.id.toString());
    this.refreshFormDataInternal();
  }

  refreshFormData(syncMerc = false) {
    this.dataCachingService.removeCachedData('EditStableCommon', this.id.toString());
    this.refreshFormDataInternal(syncMerc);
  }

  refreshFormDataInternal(syncMerc = false) {
    this.waitingOverlay.StartWaiting();

    const existing = this.dataCachingService.getCachedData('EditStableCommon', this.id.toString());

    if (existing && existing.newAgentId === this.newAgentId) {
      this.model = existing;
      this.waitingOverlay.EndWaiting();
    } else {
      (syncMerc
      ? this.stableDataService.getStableCommonForEditWithSync(this.id)
      : this.stableDataService.getStableCommonForEdit(this.id))
        .subscribe(data => {
        this.model = {id: data.id, serverSideValidationErrors: []};
        this.model.contextFormGroup = EditStableCommonFormComponent.createFormGroup(this.fb, this.model, data);
        this.updateModelSyncStateFromLookup(data);
        this.refreshSyncState(10);
        this.model.newAgentId = this.newAgentId;
        this.cache.invalidateStable(this.id);
        this.dataCachingService.addToCache('EditStableCommon', this.id.toString(), this.model);

        const agents = this.model.contextFormGroup.get('agents') as FormArray;
        if (this.newAgentId && (!agents.controls.length || agents.controls[0].get('agentId').value !== this.newAgentId)) {
          const fg = EditStableCommonFormComponent.addStableAgent(this.fb, agents);
          fg.get('agentId').setValue(this.newAgentId);
        }

        this.bookmarkService.refreshStableCardBookmark(this.id, this.newAgentId);

        this.waitingOverlay.EndWaiting();
      }, () => {
        this.waitingOverlay.EndWaiting();
      });
    }
  }

  private updateModelSyncStateFromLookup(data: any) {
    data.targetObjId = this.id;
    data.conversationTypeId = -2;
    this.updateModelSyncState(data, -2);
  }

  private updateModelSyncState(data: any, conversationTypeId: number) {
    if (!this.model.syncStates) {
      this.model.syncStates = {};
    }
    this.model.syncStates[conversationTypeId] = data;
  }

  private refreshSyncState(conversationTypeId: number) {
    this.model.syncStates[conversationTypeId] = null;
    this.refreshSyncStateInternal(conversationTypeId);
  }

  private refreshSyncStateInternal(conversationTypeId: number) {

    if (!this.contextFormGroup) {
      return;
    }

    const state = this.model && this.model.syncStates ? this.model.syncStates[conversationTypeId] : undefined;

    // если ничего не запланировано по синхронизации - ничего и не может произойти и обновлять состояние не надо
    if (state && (!state.mercuryExchangeNextTime || new Date(state.mercuryExchangeNextTime) > new Date())) {
      return;
    }

    if (this.updateSyncStatePending[conversationTypeId]) {
      return;
    }

    this.updateSyncStatePending[conversationTypeId] = true;

    (conversationTypeId === -2
      ? this.stableDataService.getStableCommonForLookup(this.id)
      : this.metadataService.getSyncState(this.id, conversationTypeId))
    .subscribe({
      next: data => {
        this.updateSyncStatePending[conversationTypeId] = false;
        if (conversationTypeId === -2) {
          this.updateModelSyncStateFromLookup(data);
        } else {
          if (data) {
            data.withoutMercuryUuid = true;
          }
          this.updateModelSyncState(data, conversationTypeId);
        }
      }, error: () => {
        this.updateSyncStatePending[conversationTypeId] = false;
        if (conversationTypeId === -2) {
          this.updateModelSyncStateFromLookup({});
        } else {
          this.updateModelSyncState({}, conversationTypeId);
        }
      }
    });
  }

  ngOnInit(): void {
    this.updateSyncStateTimer[-2] = setInterval(() => this.refreshSyncStateInternal(-2), 10000);
    this.updateSyncStateTimer[10] = setInterval(() => this.refreshSyncStateInternal(10), 10000);
  }

  ngOnDestroy(): void {
    if (this.updateSyncStateTimer) {
      Object.keys(this.updateSyncStateTimer).forEach(x => clearInterval(this.updateSyncStateTimer[x]));
      this.updateSyncStateTimer = {};
    }
  }

  deleteStable() {
    this.alertService.confirmModal('Вы уверены, что хотите аннулировать поднадзорный объект?').subscribe(() => {
      this.stableDataService.deleteStable(this.id).subscribe(() => {
        this.dataCachingService.removeCachedData('EditStableCommon', this.id.toString());
        this.bookmarkService.removeOpenCardBookmark(['/stable-edit'], this.id.toString());
      });
    });
  }

  restoreDeletedStable() {
    this.alertService.confirmModal('Вы уверены, что хотите восстановить аннулированный поднадзорный объект?').subscribe(() => {
      this.stableDataService.restoreDeletedStable(this.id).subscribe(() => {
        this.dataCachingService.removeCachedData('EditStableCommon', this.id.toString());
        this.refreshFormDataInternal();
      });
    });
  }

  mergeStable() {
    this.stableDataService.mergeStable(this.id, this.formGroupMerge.get('mergeSourceId').value)
      .subscribe(
        () => {
          this.clearCacheAfterMerge();
          this.bookmarkService.removeOpenCardBookmark(['/stable-edit'], this.formGroupMerge.get('mergeSourceId').value.toString());
          this.closeMergeModal();
        },
        error => FormHelper.setSingleFormGroupServerSideValidationErrors(error, this, this.formGroupMerge));
  }

  clearCacheAfterMerge() {

    const animals = this.dataCachingService.getCachedData('EditStableAnimals', this.formGroupMerge.get('mergeSourceId').value.toString());
    if (animals && animals.searchResults) {
      animals.searchResults.forEach(animal => this.dataCachingService.removeCachedData('EditAnimalCommon', animal.id.toString()));
    }
    this.dataCachingService.removeCachedData('EditStableAnimals', this.formGroupMerge.get('mergeSourceId').value.toString());
    this.dataCachingService.removeCachedData('EditStableAnimals', this.id.toString());

    const events = this.dataCachingService.getCachedData('EditStableEvents', this.formGroupMerge.get('mergeSourceId').value.toString());
    if (events && events.searchResults) {
      events.searchResults.forEach(ev => this.dataCachingService.removeCachedData('EditAnimalEventCommon', ev.id.toString()));
    }
    this.dataCachingService.removeCachedData('EditStableEvents', this.formGroupMerge.get('mergeSourceId').value.toString());
    this.dataCachingService.removeCachedData('EditStableEvents', this.id.toString());

    const products = this.dataCachingService.getCachedData('EditStableProducts', this.formGroupMerge.get('mergeSourceId').value.toString());
    if (products && products.searchResults) {
      products.searchResults.forEach(product => this.dataCachingService.removeCachedData('EditProductCommon', product.id.toString()));
    }
    this.dataCachingService.removeCachedData('EditStableProducts', this.formGroupMerge.get('mergeSourceId').value.toString());
    this.dataCachingService.removeCachedData('EditStableProducts', this.id.toString());

    this.dataCachingService.removeCachedData('EditStableCommon', this.formGroupMerge.get('mergeSourceId').value.toString());
    this.dataCachingService.removeCachedData('EditStableCommon', this.id.toString());
  }

  searchStable() {
    this.navigationService.searchStable(StableSearchModalComponent).subscribe(val => {
      if (val) {
        this.formGroupMerge.get('mergeSourceId').setValue(val);
      }
    });
  }

  closeMergeModal() {
    this.clrModalOpen = false;
    this.selectedCheckboxConfirmAction = false;
    this.formGroupMerge.reset();
  }

  runStableAgentsSync() {
    this.stableDataService.runStableAgentsSync(this.id)
      .subscribe(() => this.refreshSyncState(10));
  }
}
