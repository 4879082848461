import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from './pages/layout/dashboard/dashboard.component';
import { InstitutionSearchResultsComponent } from './pages/edit-institution/institution-search-results.component';
import { EditInstitutionComponent } from './pages/edit-institution/edit-institution.component';
import { CreateInstitutionComponent } from './pages/edit-institution/create-institution.component';
import { GroupOperationsBrowseComponent } from './pages/common/group-operations-browse.component';
import { AddrCityBrowseComponent } from './pages/dictionary/address/addr-city-browse.component';
import { PersonGroupsBrowseComponent } from './pages/person-groups/person-groups-browse.component';
import { ReportsBrowseComponent } from './pages/dictionary/reports/reports-browse.component';
import { UnavailableComponent } from './pages/common/unavailable.component';
import { JournalComponent } from './pages/common/journal.component';
import { UserRolesBrowseComponent } from './pages/users/user-roles-browse.component';
import { AddrStreetBrowseComponent } from './pages/dictionary/address/addr-street-browse.component';
import { AddrStreetTypeBrowseComponent } from './pages/dictionary/address/addr-street-type-browse.component';
import { AddrCityTypeBrowseComponent } from './pages/dictionary/address/addr-city-type-browse.component';
import { QueryManagerComponent } from './pages/query/query-manager.component';
import { AuthGuard } from '../auth.guard';
import { InstitutionPostBrowseComponent } from './pages/dictionary/institution/institution-post-browse.component';
import { CreateAgentComponent } from './pages/edit-agent/create-agent.component';
import { EditAgentComponent } from './pages/edit-agent/edit-agent.component';
import { AgentSearchResultsComponent } from './pages/edit-agent/search/agent-search-results.component';
import { EditStableComponent } from './pages/edit-stable/edit-stable.component';
import { CreateStableComponent } from './pages/edit-stable/create-stable.component';
import { StableSearchResultsComponent } from './pages/edit-stable/search/stable-search-results.component';
import { AnimalSearchResultsComponent } from './pages/edit-animal/search/animal-search-results.component';
import { EditAnimalComponent } from './pages/edit-animal/edit-animal.component';
import { CreateAnimalComponent } from './pages/edit-animal/create-animal.component';
import { DrugSearchResultsComponent } from './pages/edit-drug/search/drug-search-results.component';
import { EditDrugComponent } from './pages/edit-drug/edit-drug.component';
import { CreateDrugComponent } from './pages/edit-drug/create-drug.component';
import { CreateAnimalEventComponent } from './pages/edit-animal/events/create-animal-event.component';
import { EditAnimalEventComponent } from './pages/edit-animal/events/edit-animal-event.component';
import { GroupOperationRunnerComponent } from './pages/common/group-operation-runner.component';
import { ProductSearchResultsComponent } from './pages/edit-product/search/product-search-results.component';
import { EditProductComponent } from './pages/edit-product/edit-product.component';
import { CreateProductComponent } from './pages/edit-product/create-product.component';
import { ExpertiseIndicatorTypeBrowseComponent } from './pages/dictionary/product/expertise-indicator-type-browse.component';
import { QueryStandaloneResultsComponent } from './pages/query/query-standalone-results.component';
import { ViewIntegrationLogComponent } from './pages/common/view-integration-log.component';
import { AnimalSubtypeBrowseComponent } from './pages/dictionary/animal/animal-subtype-browse.component';
import { AnimalBreedBrowseComponent } from './pages/dictionary/animal/animal-breed-browse.component';
import { DiseaseTypeBrowseComponent } from './pages/dictionary/animal/disease-type-browse.component';
import { EventTypeBrowseComponent } from './pages/dictionary/event/event-type-browse.component';
import { DeathReasonBrowseComponent } from './pages/dictionary/animal/death-reason-browse.component';
import { CreateQuarantineComponent } from './pages/edit-quarantine/create-quarantine.component';
import { EditQuarantineComponent } from './pages/edit-quarantine/edit-quarantine.component';
import { EventTestMaterialBrowseComponent } from './pages/dictionary/event/event-test-material-browse.component';
import { DrugPackageTypeBrowseComponent } from './pages/dictionary/drug/drug-package-type-browse.component';
import { DrugTypeBrowseComponent } from './pages/dictionary/drug/drug-type-browse.component';
import { InstitutionTypeBrowseComponent } from './pages/dictionary/institution/institution-type-browse.component';
import { NomenclatureAnimalPinBrowseComponent } from './pages/dictionary/nomenclature/nomenclature-animal-pin-browse.component';
import { AppDistributionAgentAvailablePinsComponent } from './pages/common/app-distribution-agent-available-pins.component';
import { QuarantineEventTypeBrowseComponent } from './pages/dictionary/quarantine/quarantine-event-type-browse.component';
import { QuarantineEventBranchBrowseComponent } from './pages/dictionary/quarantine/quarantine-event-branch-browse.component';
import { QuarantineEventResponsibleBrowseComponent } from './pages/dictionary/quarantine/quarantine-event-responsible-browse.component';
import { QuarantineEventPeriodBrowseComponent } from './pages/dictionary/quarantine/quarantine-event-period-browse.component';
import { QuarantineTemplateBrowseComponent } from './pages/dictionary/quarantine/quarantine-template-browse.component';
import { DiseaseSerotypeBrowseComponent } from './pages/dictionary/animal/disease-serotype-browse.component';
import { SlaughteryViewComponent } from './pages/edit-animal/search/slaughtery-view.component';
import { ProductSubtypeBrowseComponent } from './pages/dictionary/product/product-subtype-browse.component';
import { QuarantineBlockComponent } from './pages/edit-quarantine/quarantine-block.component';
import { AuthFoundMultipleComponent } from './esia/auth-found-multiple.component';
import { EventDiagnosticTypeBrowseComponent} from './pages/dictionary/event/event-diagnostic-type-browse.component';
import { EventIndicatorBrowseComponent} from './pages/dictionary/event/event-indicator-browse.component';
import { ViewIntegrationLog2Component } from './pages/common/view-integration-log2.component';
import { ProductStatisticComponent } from './pages/common/product-statistic.component';
import { TelemetryComponent } from './pages/common/telemetry.component';
import { InstitutionTicketQuestBrowseComponent } from './pages/dictionary/institution/institution-ticket-quest-browse.component';
import { CalendarBrowseComponent } from './pages/dictionary/common/calendar-browse.component';
import { PstReportsRoutes } from './pst-report/pst-report.routing';
import { InstitutionEmployeeStatisticComponent } from './pages/common/institution-employee-statistic.component';
import { PersonalAccountCommonComponent } from './pages/user-personal-account/personal-account-common.component';
import { EventForcedReasonBrowseComponent } from './pages/dictionary/event/event-forced-reason-browse.component';
import { DrugManufacturerBrowseComponent } from './pages/dictionary/drug/drug-manufacturer-browse.component';

const routes: Routes = [
  {
    path: '',
    canActivateChild: [AuthGuard],
    children: [...PstReportsRoutes.routes,
      {path: '', redirectTo: '/dashboard', pathMatch: 'full'},
      {path: 'dashboard', component: DashboardComponent},
      {path: 'unavailable', component: UnavailableComponent},
      {path: 'journal', component: JournalComponent},
      {path: 'view-integration-log', component: ViewIntegrationLogComponent},
      {path: 'view-integration-log2', component: ViewIntegrationLog2Component},
      {path: 'institution-employee-statistic', component: InstitutionEmployeeStatisticComponent},
      {path: 'product-statistic', component: ProductStatisticComponent},
      {path: 'personal-account', component: PersonalAccountCommonComponent},

      {path: 'institution-search', component: InstitutionSearchResultsComponent},
      {path: 'institution-edit/:id', component: EditInstitutionComponent},
      {path: 'institution-create/:randomUniqueTag', component: CreateInstitutionComponent},
      {path: 'agent-search', component: AgentSearchResultsComponent},
      {path: 'agent-edit/:id', component: EditAgentComponent},
      {path: 'agent-create/:randomUniqueTag', component: CreateAgentComponent},
      {path: 'stable-search', component: StableSearchResultsComponent},
      {path: 'stable-edit/:id', component: EditStableComponent},
      {path: 'stable-create/:randomUniqueTag', component: CreateStableComponent},
      {path: 'group-operation-browse', component: GroupOperationsBrowseComponent},
      {path: 'person-groups-browse/:id', component: PersonGroupsBrowseComponent},
      {path: 'person-groups-browse', component: PersonGroupsBrowseComponent},
      {path: 'animal-search', component: AnimalSearchResultsComponent},
      {path: 'animal-edit/:id', component: EditAnimalComponent},
      {path: 'animal-create/:randomUniqueTag', component: CreateAnimalComponent},
      {path: 'drug-search', component: DrugSearchResultsComponent},
      {path: 'drug-edit/:id', component: EditDrugComponent},
      {path: 'drug-create/:randomUniqueTag', component: CreateDrugComponent},
      {path: 'product-search', component: ProductSearchResultsComponent},
      {path: 'product-edit/:id', component: EditProductComponent},
      {path: 'product-edit/:id/expertises/:expertiseId', component: EditProductComponent},
      {path: 'slaughtery-view', component: SlaughteryViewComponent},

      {path: 'product-create/:randomUniqueTag', component: CreateProductComponent},

      {path: 'animal-event-edit/:id', component: EditAnimalEventComponent},
      {path: 'animal-event-create/:randomUniqueTag', component: CreateAnimalEventComponent},

      {path: 'quarantine-block', component: QuarantineBlockComponent},
      {path: 'quarantine-create/:randomUniqueTag', component: CreateQuarantineComponent},
      {path: 'quarantine-edit/:id', component: EditQuarantineComponent},

      {path: 'dictionary/addr-city', component: AddrCityBrowseComponent},
      {path: 'dictionary/addr-city-type', component: AddrCityTypeBrowseComponent},
      {path: 'dictionary/addr-street', component: AddrStreetBrowseComponent},
      {path: 'dictionary/addr-street-type', component: AddrStreetTypeBrowseComponent},
      {path: 'dictionary/reports-browse', component: ReportsBrowseComponent},
      {path: 'dictionary/institution-post', component: InstitutionPostBrowseComponent},
      {path: 'dictionary/institution-type', component: InstitutionTypeBrowseComponent},
      {path: 'dictionary/product-subtype', component: ProductSubtypeBrowseComponent},
      {path: 'dictionary/expertise-indicator-type', component: ExpertiseIndicatorTypeBrowseComponent},
      {path: 'dictionary/animal-subtype', component: AnimalSubtypeBrowseComponent},
      {path: 'dictionary/event-type', component: EventTypeBrowseComponent},
      {path: 'dictionary/test-material', component: EventTestMaterialBrowseComponent},
      {path: 'dictionary/diagnostic-type', component: EventDiagnosticTypeBrowseComponent},
      {path: 'dictionary/event-indicator', component: EventIndicatorBrowseComponent},
      {path: 'dictionary/animal-breed', component: AnimalBreedBrowseComponent},
      {path: 'dictionary/disease-type', component: DiseaseTypeBrowseComponent},
      {path: 'dictionary/disease-serotype', component: DiseaseSerotypeBrowseComponent},
      {path: 'dictionary/death-reason', component: DeathReasonBrowseComponent},
      {path: 'dictionary/nomenclature-animal-pin', component: NomenclatureAnimalPinBrowseComponent},
      {path: 'dictionary/agent-available-pins', component: AppDistributionAgentAvailablePinsComponent},
      {path: 'dictionary/drug-package-type', component: DrugPackageTypeBrowseComponent},
      {path: 'dictionary/drug-type', component: DrugTypeBrowseComponent},
      {path: 'dictionary/drug-manufacturer', component: DrugManufacturerBrowseComponent},
      {path: 'dictionary/quarantine-event-type', component: QuarantineEventTypeBrowseComponent},
      {path: 'dictionary/quarantine-event-branch', component: QuarantineEventBranchBrowseComponent},
      {path: 'dictionary/quarantine-event-responsible', component: QuarantineEventResponsibleBrowseComponent},
      {path: 'dictionary/quarantine-event-period', component: QuarantineEventPeriodBrowseComponent},
      {path: 'dictionary/quarantine-template', component: QuarantineTemplateBrowseComponent},
      {path: 'dictionary/quarantine-template/:id', component: QuarantineTemplateBrowseComponent},
      {path: 'dictionary/institution-ticket-quest', component: InstitutionTicketQuestBrowseComponent},
      {path: 'dictionary/calendar', component: CalendarBrowseComponent},
      {path: 'dictionary/event-forced-reason', component: EventForcedReasonBrowseComponent},

      {path: 'user-roles-browse/:id', component: UserRolesBrowseComponent},
      {path: 'user-roles-browse', component: UserRolesBrowseComponent},

      {path: 'query-manager', component: QueryManagerComponent},
      {path: 'query-view/:id', component: QueryStandaloneResultsComponent},

      {path: 'operations/general/:opCode/:randomUniqueTag', component: GroupOperationRunnerComponent},
      {path: 'esia/auth/found-multiple', component: AuthFoundMultipleComponent},
      {path: 'telemetry', component: TelemetryComponent}
    ],
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [
    RouterModule,
  ],
})
export class AppRoutingModule {
}
