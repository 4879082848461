<div class="itech-common-wrap no-pager">
  <div>
    <app-drug-search-results-form (rowClicked)="appNavigationService.performDrugEditing($event.id)" [model]="model">
    </app-drug-search-results-form>
  </div>

  <div class="itech-form-actions">
    <button class="btn btn-link btn-primary" (click)="appNavigationService.performDrugCreation()">
      <clr-icon shape="flask" class="has-badge"></clr-icon>&nbsp;Зарегистрировать новый препарат
    </button>
  </div>
</div>
