import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { MetadataService } from '../../../logic/services/metadata.service';
import { FormBuilder, Validators } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { environment } from '../../../../environments/environment';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { BookmarkService } from '../../../logic/services/bookmark.service';
var ReportsBrowseComponent = /** @class */ (function () {
    function ReportsBrowseComponent(route, metadataService, bookmarkService, alertService, globalWaitingOverlayService, lookupService, fb) {
        var _this = this;
        this.route = route;
        this.metadataService = metadataService;
        this.bookmarkService = bookmarkService;
        this.alertService = alertService;
        this.globalWaitingOverlayService = globalWaitingOverlayService;
        this.lookupService = lookupService;
        this.fb = fb;
        this._searchResults = [];
        this.gridColumnDefs = [
            { headerName: '№', field: 'id', width: 80 },
            { headerName: 'Код области применения', field: 'reportKind', tooltipField: 'reportKind', width: 120 },
            { headerName: 'Наименование', field: 'caption', tooltipField: 'caption', width: 350 },
            {
                headerName: 'Выходной формат', field: 'outputFormat', width: 200,
                valueFormatter: function (params) { return _this.reportOutputFormatLookup[params.value]; }
            },
            {
                headerName: 'Пользовательский (да/нет)', field: 'isCustom', width: 120,
                cellRenderer: function (params) { return params.data.isCustom ? '<clr-icon shape="check"></clr-icon>' : ''; },
                cellStyle: { 'text-align': 'center' }
            },
        ];
        this.reportOutputFormatLookup = {};
        this.currentIsNew = false;
        this.editModalOpened = false;
        this.commonTabActive = true;
        this.scriptsTabActive = false;
        this.lookupService.getLookupObj('report-output-format').subscribe(function (lookup) { return _this.reportOutputFormatLookup = lookup; });
    }
    ReportsBrowseComponent.prototype.refreshResults = function () {
        var _this = this;
        this.globalWaitingOverlayService.StartWaiting();
        this.metadataService.getAllReports()
            .subscribe({
            next: function (data) {
                _this._searchResults = data;
                _this.globalWaitingOverlayService.EndWaiting();
            }, error: function () {
                _this.globalWaitingOverlayService.EndWaiting();
            }
        });
    };
    ReportsBrowseComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.paramMap
            .subscribe(function () {
            _this._searchResults = [];
            _this.refreshResults();
            _this.bookmarkService.addDictionaryBookmark('Выходные/отчетные формы', ['/dictionary/reports-browse']);
        });
    };
    ReportsBrowseComponent.prototype.isInvalid = function (fg, cname) {
        return FormHelper.isInvalid(fg, cname);
    };
    ReportsBrowseComponent.prototype.editRow = function (row) {
        this.currentFormGroup = this.fb.group(this.getGroupDef(row));
        this.currentIsNew = row.id || row.id === 0 ? false : true;
        this.editModalOpened = true;
        this.newlyEnteredFile = undefined;
    };
    ReportsBrowseComponent.prototype.addEditCompleted = function () {
        var _this = this;
        FormHelper.markAsSubmitted(this.currentFormGroup);
        if (!this.currentFormGroup.valid) {
            return;
        }
        this.editModalOpened = false;
        this.metadataService.storeReportMeta(this.currentFormGroup.value, this.newlyEnteredFile)
            .subscribe(function () {
            _this.refreshResults();
        });
    };
    ReportsBrowseComponent.prototype.delete = function (row) {
        var _this = this;
        this.alertService.confirmModal('Удалить выбранный шаблон отчетной формы?').subscribe(function (val) {
            if (val) {
                _this.metadataService.deleteReportMeta(row.id)
                    .subscribe(function () {
                    _this.editModalOpened = false;
                    _this.refreshResults();
                });
            }
        });
    };
    ReportsBrowseComponent.prototype.fileChanged = function (e) {
        this.newlyEnteredFile = e.target.files[0];
    };
    ReportsBrowseComponent.prototype.getGroupDef = function (row) {
        var _this = this;
        return {
            id: row.id,
            caption: [row.caption, Validators.required],
            reportKind: [row.reportKind, Validators.required],
            outputFormat: [row.outputFormat, Validators.required],
            isCustom: row.isCustom,
            reportTemplateCustomUri: row.reportTemplateCustomUri,
            reportTemplateEmbeddedName: row.reportTemplateEmbeddedName,
            scriptFields: [row.scriptFields, Validators.required],
            scriptTable0: row.scriptTable0,
            scriptTable1: row.scriptTable1,
            scriptTable2: row.scriptTable2,
            scriptTable3: row.scriptTable3,
            scriptTable4: row.scriptTable4,
            scriptTable5: row.scriptTable5,
            scriptTable6: row.scriptTable6,
            scriptTable7: row.scriptTable7,
            scriptTable8: row.scriptTable8,
            scriptTable9: row.scriptTable9,
            customParameters: this.fb.array((row.customParameters || []).map(function (el) { return _this.getCustomParameterControlDef(el); })),
            embeddedDatasourceName: row.embeddedDatasourceName,
            jobTypeId: row.jobTypeId,
            groupCaption: row.groupCaption,
            aggBundleDictCode: row.aggBundleDictCode,
            aggBundles: this.fb.array((row.aggBundles || []).map(function (el) { return _this.getAggBundleControlDef(el); })),
        };
    };
    ReportsBrowseComponent.prototype.getCustomParameterControlDef = function (el) {
        return this.fb.group({
            code: [el.code, Validators.compose([Validators.required])],
            displayName: [el.displayName, Validators.compose([])],
            comments: [el.comments, Validators.compose([])],
            dataType: [el.dataType,
                Validators.compose([Validators.pattern(/^\d{1,2}$/)])],
            lookupName: el.lookupName,
            defaultValue: el.defaultValue,
        });
    };
    ReportsBrowseComponent.prototype.getAggBundleControlDef = function (el) {
        return this.fb.group({
            title: [el.title, Validators.compose([Validators.required])],
            codes: this.fb.array(el.codes || []),
        });
    };
    ReportsBrowseComponent.prototype.navigateCurrentReportTemplate = function () {
        if (!this.currentFormGroup.value.reportTemplateCustomUri) {
            return;
        }
        this.metadataService.preloadFile(this.currentFormGroup.value.reportTemplateCustomUri).subscribe(function (fileHash) {
            window.open(environment.api + '/files/get?preloadId=' + encodeURIComponent(fileHash));
        });
    };
    ReportsBrowseComponent.prototype.onGridReady = function (params) {
        params.api.sizeColumnsToFit();
    };
    Object.defineProperty(ReportsBrowseComponent.prototype, "customParameters", {
        get: function () {
            return this.currentFormGroup.get('customParameters');
        },
        enumerable: true,
        configurable: true
    });
    ReportsBrowseComponent.prototype.addCustomParameter = function () {
        this.customParameters.push(this.getCustomParameterControlDef({}));
        this.customParameters.markAsDirty();
    };
    ReportsBrowseComponent.prototype.deleteCustomParameter = function (ix) {
        this.customParameters.removeAt(ix);
        this.customParameters.markAsDirty();
    };
    Object.defineProperty(ReportsBrowseComponent.prototype, "aggBundles", {
        get: function () {
            return this.currentFormGroup.get('aggBundles');
        },
        enumerable: true,
        configurable: true
    });
    ReportsBrowseComponent.prototype.addAggBundle = function () {
        this.aggBundles.push(this.getAggBundleControlDef({}));
        this.aggBundles.markAsDirty();
    };
    ReportsBrowseComponent.prototype.deleteAggBundle = function (ix) {
        this.aggBundles.removeAt(ix);
        this.aggBundles.markAsDirty();
    };
    ReportsBrowseComponent.prototype.addAggBundleItem = function (bundle) {
        var codes = bundle.get('codes');
        codes.push(this.fb.control(undefined));
        codes.markAsDirty();
    };
    ReportsBrowseComponent.prototype.deleteAggBundleItem = function (bundle, ix) {
        var codes = bundle.get('codes');
        codes.removeAt(ix);
        codes.markAsDirty();
    };
    return ReportsBrowseComponent;
}());
export { ReportsBrowseComponent };
