import { DataCachingService } from '../../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { StringHelper } from '../../../helpers/string-helper';
import { StableDataService } from '../../../logic/services/stable-data.service';
import { DateHelper } from '../../../helpers/date-helper';
var EditStableEventsComponent = /** @class */ (function () {
    function EditStableEventsComponent(stableDataService, dataCachingService, waitingOverlay, lookupSourceService, navigationService) {
        var _this = this;
        this.stableDataService = stableDataService;
        this.dataCachingService = dataCachingService;
        this.waitingOverlay = waitingOverlay;
        this.lookupSourceService = lookupSourceService;
        this.navigationService = navigationService;
        this.model = {};
        this.isInvalid = FormHelper.isInvalid;
        this.gridColumnDefs = [
            {
                headerName: '', width: 50, excludeExport: true,
                cellRenderer: function (params) {
                    if (!params || !params.data) {
                        return '';
                    }
                    if (params.data['notExecuted']) {
                        return '<clr-icon shape="times" style="color:silver"></clr-icon>';
                    }
                    if (DateHelper.isExpiredEvent(params.data['date'], params.data['datePlanning'])) {
                        return '<clr-icon shape="history" style="color:red" title="Мероприятие просрочено"></clr-icon>';
                    }
                    else {
                        return params.data['date'] ?
                            '<clr-icon shape="check" style="color:green"></clr-icon>' :
                            '<clr-icon shape="alarm-clock" style="color:silver"></clr-icon>';
                    }
                }
            },
            {
                headerName: '№', field: 'id', width: 70,
            },
            {
                headerName: 'Дата (план)', field: 'datePlanning', width: 100,
                valueFormatter: function (params) { return StringHelper.getRuDate(params.value); },
            },
            {
                headerName: 'Дата (факт)', field: 'date', width: 100,
                valueFormatter: function (params) { return StringHelper.getRuDate(params.value); },
            },
            {
                headerName: 'Вид', field: 'eventTypeId', width: 230,
                valueFormatter: function (params) { return params.value ? _this.eventTypeIdLookup[params.value] : '<не указан>'; },
            },
            {
                headerName: 'Заболевание', field: 'diseaseTypeId', width: 250,
                valueFormatter: function (params) { return params.value ? _this.diseaseTypeIdLookup[params.value] : '<не указано>'; },
                cellStyle: function (params) {
                    return {
                        color: params.value && params.data.diseasePositive ? 'red' : 'silver'
                    };
                },
            },
            {
                headerName: 'Препараты', field: 'drugsCaption', width: 250,
                cellStyle: function (params) {
                    return {
                        color: !params.value ? 'silver' : undefined
                    };
                },
            },
        ];
        this.eventTypeIdLookup = {};
        this.diseaseTypeIdLookup = {};
        this.lookupSourceService.getLookupObj('event-type').subscribe(function (lookup) { return _this.eventTypeIdLookup = lookup; });
        this.lookupSourceService.getLookupObj('disease-type').subscribe(function (lookup) { return _this.diseaseTypeIdLookup = lookup; });
    }
    Object.defineProperty(EditStableEventsComponent.prototype, "id", {
        get: function () {
            return this._id;
        },
        set: function (id) {
            if (this._id !== id) {
                this._id = id;
                this.refreshFormDataInternal();
            }
        },
        enumerable: true,
        configurable: true
    });
    EditStableEventsComponent.prototype.refreshFormData = function () {
        this.dataCachingService.removeCachedData('EditStableEvents', this.id.toString());
        this.refreshFormDataInternal();
    };
    EditStableEventsComponent.prototype.refreshFormDataInternal = function () {
        var _this = this;
        this.waitingOverlay.StartWaiting();
        var existing = this.dataCachingService.getCachedData('EditStableEvents', this.id.toString());
        if (existing) {
            this.model = existing;
            this.waitingOverlay.EndWaiting();
        }
        else {
            this.stableDataService.readStableEvents(this.id)
                .subscribe({
                next: function (data) {
                    data.sort(function (a, b) { return (b.date || b.datePlanning).localeCompare((a.date || a.datePlanning)); });
                    _this.model = { searchResults: data };
                    _this.dataCachingService.addToCache('EditStableEvents', _this.id.toString(), _this.model);
                    _this.waitingOverlay.EndWaiting();
                },
                error: function () {
                    _this.waitingOverlay.EndWaiting();
                }
            });
        }
    };
    EditStableEventsComponent.prototype.addNew = function () {
        this.navigationService.performStableEventCreation(this.id);
    };
    EditStableEventsComponent.prototype.getRowStyle = function (params) {
        if (params.data && params.data.notExecuted) {
            return { color: 'silver' };
        }
    };
    return EditStableEventsComponent;
}());
export { EditStableEventsComponent };
