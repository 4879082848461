import * as tslib_1 from "tslib";
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { MetadataService } from '../../../logic/services/metadata.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MetaBrowseBaseComponent } from '../meta-browse.base.component';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { FormHelper } from '../../../ui/controls/form-helper';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
var ExpertiseIndicatorTypeBrowseComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ExpertiseIndicatorTypeBrowseComponent, _super);
    function ExpertiseIndicatorTypeBrowseComponent(route, metadataService, alertService, globalWaitingOverlayService, lookupService, bookmarkService, fb, dataCachingService) {
        var _this = _super.call(this, route, metadataService, alertService, globalWaitingOverlayService, lookupService, fb, bookmarkService, dataCachingService) || this;
        _this.gridColumnDefs = [
            { headerName: '№', field: 'id', width: 100 },
            { headerName: 'Наименование', field: 'caption', tooltipField: 'caption', width: 200 },
            { headerName: 'Правовой акт', field: 'legalAct', tooltipField: 'legalAct', width: 200 },
            { headerName: 'Метод исследования', field: 'method', tooltipField: 'method', width: 200 },
            { headerName: 'Нормативное значение', field: 'normativeValue', tooltipField: 'normativeValue', width: 120 },
        ];
        return _this;
    }
    ExpertiseIndicatorTypeBrowseComponent.prototype.getMetaTitle = function () {
        return 'Виды показателей';
    };
    ExpertiseIndicatorTypeBrowseComponent.prototype.getMetaName = function () {
        return 'expertise-indicator-type';
    };
    ExpertiseIndicatorTypeBrowseComponent.prototype.getGroupDef = function (row) {
        return {
            id: [row.id, Validators.compose([Validators.required, Validators.pattern(/^\d{1,5}$/)])],
            caption: [row.caption, Validators.required],
            legalAct: [row.legalAct, Validators.required],
            method: [row.method, Validators.required],
            normativeValue: [row.normativeValue, Validators.compose([Validators.required, FormHelper.validateMoney()])],
        };
    };
    return ExpertiseIndicatorTypeBrowseComponent;
}(MetaBrowseBaseComponent));
export { ExpertiseIndicatorTypeBrowseComponent };
