import { DataCachingService } from '../../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { AnimalDataService } from '../../../logic/services/animal-data.service';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';
import { EditAgentAnimalsComponent } from '../../edit-agent/animals/edit-agent-animals.component';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { JobRunnerUiService } from '../../../ui/infrastructure/job-runner-ui.service';
var EditStableAnimalsComponent = /** @class */ (function () {
    function EditStableAnimalsComponent(animalDataService, dataCachingService, waitingOverlay, cacheService, lookupSourceService, navigationService, jobRunnerUiService, alertService) {
        this.animalDataService = animalDataService;
        this.dataCachingService = dataCachingService;
        this.waitingOverlay = waitingOverlay;
        this.cacheService = cacheService;
        this.lookupSourceService = lookupSourceService;
        this.navigationService = navigationService;
        this.jobRunnerUiService = jobRunnerUiService;
        this.alertService = alertService;
        this.model = {};
        this.isInvalid = FormHelper.isInvalid;
        this.gridColumnDefs = EditAgentAnimalsComponent.getGridColumnDefs();
    }
    Object.defineProperty(EditStableAnimalsComponent.prototype, "id", {
        get: function () {
            return this._id;
        },
        set: function (id) {
            if (this._id !== id) {
                this._id = id;
                this.refreshFormDataInternal();
            }
        },
        enumerable: true,
        configurable: true
    });
    EditStableAnimalsComponent.prototype.refreshFormData = function () {
        this.dataCachingService.removeCachedData('EditStableAnimals', this.id.toString());
        this.refreshFormDataInternal();
    };
    EditStableAnimalsComponent.prototype.refreshFormDataInternal = function () {
        var _this = this;
        this.waitingOverlay.StartWaiting();
        var existing = this.dataCachingService.getCachedData('EditStableAnimals', this.id.toString());
        if (existing) {
            this.model = existing;
            this.waitingOverlay.EndWaiting();
        }
        else {
            this.lookupSourceService.getLookupObj('animal-type').subscribe(function (animalTypeLookup) {
                _this.lookupSourceService.getLookupObj('animal-subtype').subscribe(function (animalSubtypeIdLookup) {
                    _this.animalDataService.searchByStable(_this.id, _this.model.showHistory)
                        .subscribe({
                        next: function (data) {
                            data.forEach(function (el) {
                                var animalSubtype = (animalSubtypeIdLookup[el.animalTypeId] || []).find(function (x) { return x.id === el.animalSubtypeId; });
                                el.animalTypeIdCaption = el.animalTypeId ? animalTypeLookup[el.animalTypeId] : '<<Не указан>>';
                                el.animalSubtypeIdCaption = animalSubtype ? animalSubtype.caption : '<<не указан>>';
                                el.totalCount = el.animalGroup ? el.totalCount : el.deathDate ? 0 : 1;
                            });
                            _this.model = { searchResults: data, showHistory: _this.model.showHistory };
                            _this.dataCachingService.addToCache('EditStableAnimals', _this.id.toString(), _this.model);
                            _this.waitingOverlay.EndWaiting();
                        },
                        error: function () {
                            _this.waitingOverlay.EndWaiting();
                        }
                    });
                });
            });
        }
    };
    EditStableAnimalsComponent.prototype.getRowStyle = function (params) {
        if (params.data && +params.data.totalCount === 0) {
            return { color: 'silver' };
        }
    };
    EditStableAnimalsComponent.prototype.addNew = function () {
        var _this = this;
        this.cacheService.getStable(this.id).subscribe(function (stable) {
            _this.navigationService.performAnimalCreation(stable.currentAgentId, _this.id);
        });
    };
    EditStableAnimalsComponent.prototype.updateFromMercuryAndRefreshFormData = function () {
        var _this = this;
        setTimeout(function () {
            _this.jobRunnerUiService.runOperation(16, /* Обновление данных о животных из журнала продукции Меркурия */ {
                type: 'updateAnimalsFromMercuryJournalSync',
                stableId: _this.id
            }, undefined, undefined, false)
                .subscribe(function () {
                _this.refreshFormData();
                _this.alertService.success('Сведения о животных актуализированы');
            });
        }, 1);
    };
    EditStableAnimalsComponent.prototype.onGridSelectionChanged = function (params) {
        this.model.selectedRows = (params.api.getSelectedRows() || []).filter(function (x) { return x.tempId; });
    };
    EditStableAnimalsComponent.prototype.confirmSelected = function () {
        var _this = this;
        this.alertService.confirmModal('Подтвердить выделенных животных?')
            .subscribe(function (val) {
            if (val) {
                _this.animalDataService.confirmManyAnimalExternal(_this.model.selectedRows).subscribe(function () { return _this.refreshFormData(); });
            }
        });
    };
    return EditStableAnimalsComponent;
}());
export { EditStableAnimalsComponent };
