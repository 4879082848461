import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { StringHelper } from '../../helpers/string-helper';
import { FormHelper } from './form-helper';

@Component({
  selector: 'app-datetime-picker',
  template: `
    <label for="{{contextControlName}}Date" aria-haspopup="true" role="tooltip"
           class="tooltip tooltip-validation tooltip-md tooltip-right"
           [class.invalid]="isInvalid(contextControlName)">
      <input #datePortion type="date" id="{{contextControlName}}Date" required
             (change)="datePortionControlChanged($event)" [attr.disabled]="disabled ? true : undefined">
      <input *ngIf="withTime" #timePortion type="time" id="{{contextControlName}}Time" required
             (change)="timePortionControlChanged($event)" [attr.disabled]="disabled ? true : undefined">
      <app-validation-tooltip [input]="contextFormGroup.controls[contextControlName]"></app-validation-tooltip>
    </label>
  `
})
export class AppDatetimePickerComponent implements OnChanges {
  @Input() disabled;
  @Input() withTime = true;
  @Input() contextControlName;
  @Input() contextFormGroup;

  @ViewChild('datePortion') datePortion: ElementRef;
  @ViewChild('timePortion') timePortion: ElementRef;

  ignoreNextChangesCount = 0;

  boundContextFormGroup: any;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('contextFormGroup') || changes.hasOwnProperty('contextControlName')) {

      if (this.contextFormGroup && this.contextControlName && this.contextFormGroup !== this.boundContextFormGroup) {

        this.contextFormGroup.get(this.contextControlName).valueChanges.subscribe(val => {

          if (this.ignoreNextChangesCount) {

            this.ignoreNextChangesCount--;

          } else {
            if (!val || val.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}\w*/)) {
              setTimeout(() => this.datePortion.nativeElement.value = StringHelper.getDatePortionOfISODate(val), 300);
              if (this.withTime) {
                setTimeout(() => this.timePortion.nativeElement.value = StringHelper.getTimePortionOfISODate(val), 300);
              }
            } else if (!val || val.match(/^T\d{2}:\d{2}\w*/)) {
              setTimeout(() => this.datePortion.nativeElement.value = '', 300);
              if (this.withTime) {
                setTimeout(() => this.timePortion.nativeElement.value = StringHelper.getTimePortionOfISODate(val), 300);
              }
            }
          }
        });
        this.contextFormGroup.get(this.contextControlName).setValue(this.contextFormGroup.get(this.contextControlName).value);

        this.boundContextFormGroup = this.contextFormGroup;
      }
    }
  }

  datePortionControlChanged($event: any) {
    const newValue = $event.target.value + 'T' +
      (this.withTime ? StringHelper.getTimePortionOfISODate(this.contextFormGroup.get(this.contextControlName).value) : '00:00');

    if (this.contextFormGroup.get(this.contextControlName).value !== newValue) {
      this.ignoreNextChangesCount++;
      setTimeout(() => {
        this.ignoreNextChangesCount = 0;
      }, 500);

      this.contextFormGroup.get(this.contextControlName).markAsTouched();
      this.contextFormGroup.get(this.contextControlName).setValue(newValue);
      this.contextFormGroup.markAsDirty();
    }
  }

  timePortionControlChanged($event: any) {
    const newValue = StringHelper.getDatePortionOfISODate(this.contextFormGroup.get(this.contextControlName).value) +
      'T' + $event.target.value;

    if (this.contextFormGroup.get(this.contextControlName).value !== newValue) {
      this.ignoreNextChangesCount++;
      setTimeout(() => {
        this.ignoreNextChangesCount = 0;
      }, 500);

      this.contextFormGroup.get(this.contextControlName).markAsTouched();
      this.contextFormGroup.get(this.contextControlName).setValue(newValue);
      this.contextFormGroup.markAsDirty();
    }
  }

  isInvalid(cname: string) {
    return FormHelper.isInvalid(this.contextFormGroup, cname);
  }
}
