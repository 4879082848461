import { Component, Input } from '@angular/core';
import { Required } from '../../../ui/infrastructure/app-decotators';
import { StringHelper } from '../../../helpers/string-helper';

@Component({
  selector: 'app-edit-institution-agent-message-form',
  templateUrl: './edit-institution-agent-message-form.component.html',
  styles: [`
    .message-view {
      background-color:white;
      min-height:100%;
      border-radius:10px;
      width:100%;
      display:flex;
      flex-direction:column-reverse;
      overflow-y: scroll;
    }

    .message {
      width:max-content;
      margin:10px;
      padding:10px;
      border-radius:10px;
      box-shadow:
              0 3px 3px -2px rgba(0, 0, 0, 0.2),
              0 3px 4px 0 rgba(0, 0, 0, 0.14),
              0 1px 8px 0 rgba(0, 0, 0, 0.12);
    }

    .message.outcoming {
      background-color: #005598;
      color: white;
      align-self: flex-end;
    }

    .message.incoming {
      background-color: white;
      color: black;
      align-self: flex-start;
    }

    .message .message-header {
      font-size: 10px;
      color: silver;
      line-height: 0.5rem;
    }
  `]
})
export class EditInstitutionAgentMessageFormComponent {
  @Input() @Required messages: any[];

  getRuDatetime = StringHelper.getRuTime;
}
