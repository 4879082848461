import {Component, Input} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { QueryDetailsEventComponent } from './query-details-event.component';
import { QueryDetailsAnimalComponent } from './query-details-animal.component';
import { QueryDetailsStableComponent } from './query-details-stable.component';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { IModalBody, ModalResult } from '../../logic/services/app-navigation.service.models';
import { QueryDetailsQuarantineComponent } from './query-details-quarantine.component';
import { QueryDetailsAgentComponent } from './query-details-agent.component';
import { QueryDetailsInstitutionTicketComponent } from './query-details-institution-ticket.component';
import { QueryDetailsProductComponent } from './query-details-product.component';
import { FormHelper } from '../../ui/controls/form-helper';
import { QueryDetailsDrugComponent } from './query-details-drug.component';

@Component({
  templateUrl: './query-params.component.html'
})
export class QueryParamsComponent implements IModalBody<any, void> {
  query: any;

  constructor(private fb: FormBuilder) {
  }

  get eventContextFormGroup() {
    if (!this.query) {
      return false;
    }
    const condition = this.query.userParamConditionsMap['event'];

    if (!condition) {
      return false;
    } else {
      if (!condition.contextFormGroup) {
        condition.contextFormGroup = this.fb.group(QueryDetailsEventComponent.getGroupDef(condition, this.fb));
      }

      return condition.contextFormGroup;
    }
  }

  get animalContextFormGroup() {
    if (!this.query) {
      return false;
    }
    const condition = this.query.userParamConditionsMap['animal'];

    if (!condition) {
      return false;
    } else {
      if (!condition.contextFormGroup) {
        condition.contextFormGroup = this.fb.group(QueryDetailsAnimalComponent.getGroupDef(this.fb, condition));
      }

      return condition.contextFormGroup;
    }
  }

  get stableContextFormGroup() {
    if (!this.query) {
      return false;
    }
    const condition = this.query.userParamConditionsMap['stable'];

    if (!condition) {
      return false;
    } else {
      if (!condition.contextFormGroup) {
        condition.contextFormGroup = this.fb.group(QueryDetailsStableComponent.getGroupDef(condition));
      }

      return condition.contextFormGroup;
    }
  }

  get quarantineContextFormGroup() {
    if (!this.query) {
      return false;
    }
    const condition = this.query.userParamConditionsMap['quarantine'];

    if (!condition) {
      return false;
    } else {
      if (!condition.contextFormGroup) {
        condition.contextFormGroup = this.fb.group(QueryDetailsQuarantineComponent.getGroupDef(this.fb, condition));
      }

      return condition.contextFormGroup;
    }
  }

  get agentContextFormGroup() {
    if (!this.query) {
      return false;
    }
    const condition = this.query.userParamConditionsMap['agent'];

    if (!condition) {
      return false;
    } else {
      if (!condition.contextFormGroup) {
        condition.contextFormGroup = this.fb.group(QueryDetailsAgentComponent.getGroupDef(condition));
      }

      return condition.contextFormGroup;
    }
  }

  get productContextFormGroup() {
    if (!this.query) {
      return false;
    }
    const condition = this.query.userParamConditionsMap['product'];

    if (!condition) {
      return false;
    } else {
      if (!condition.contextFormGroup) {
        condition.contextFormGroup = this.fb.group(QueryDetailsProductComponent.getGroupDef(this.fb, condition));
      }

      return condition.contextFormGroup;
    }
  }

  get institutionTicketContextFormGroup() {
    if (!this.query) {
      return false;
    }
    const condition = this.query.userParamConditionsMap['institution-ticket'];

    if (!condition) {
      return false;
    } else {
      if (!condition.contextFormGroup) {
        condition.contextFormGroup = this.fb.group(QueryDetailsInstitutionTicketComponent.getGroupDef(condition));
      }

      return condition.contextFormGroup;
    }
  }

  get drugContextFormGroup() {
    if (!this.query) {
      return false;
    }
    const condition = this.query.userParamConditionsMap['drug'];

    if (!condition) {
      return false;
    } else {
      if (!condition.contextFormGroup) {
        condition.contextFormGroup = this.fb.group(QueryDetailsDrugComponent.getGroupDef(this.fb, condition));
      }

      return condition.contextFormGroup;
    }
  }

  private static detectParentParam(key: any): string {
    if (key === '_par_animal_subtype_filter') {
      return '_par_animal_type';
    } else if (FormHelper.equalsSome(key, '_par_ttn_no_filter', '_par_ttn_serial_filter')) {
      return '_par_ttn_type_id';
    }
    return '';
  }

  private buildUserParamConditions() {

    this.query.userParamConditions = [];
    this.query.userParamConditionsMap = {};

    if (!this.query || !this.query.conditions) {
      return false;
    }

    for (const condition of this.query.conditions) {
      this.gatherRelationUserParams(condition, this.query.userParamConditionsMap);
    }
  }

  gatherRelationUserParams(relation: any, paramTypeMap): boolean {

    if (!relation) {
      return;
    }

    this.gatherConditionUserParams(relation, paramTypeMap);

    if (!relation.relations) {
      return;
    }

    for (const condition of relation.relations) {
      this.gatherRelationUserParams(condition, paramTypeMap);
    }
  }

  gatherConditionUserParams(condition: any, paramTypeMap): boolean {

    if (!condition) {
      return false;
    }

    for (const key in condition.data) {
      if (condition.data.hasOwnProperty(key) && key.endsWith('_filter')
        && (+condition.data[key] >= 100) && (+condition.data[key]) < 200) {

        if (!paramTypeMap[condition.data.type]) {
          paramTypeMap[condition.data.type] = {data: {}};
          this.query.userParamConditions.push(paramTypeMap[condition.data.type]);
        }

        // Это костыль, чтобы нам оптимизировать отображение в зависимости от вида документа и при этом не потерять
        // информацию о виде документов при фильтрации отдельных параметров. от него зависящих, мы тупо копируем помимо
        // фильтровых полей еще и выбранный тип документов
        paramTypeMap[condition.data.type].data._par_doc_type = condition.data._par_doc_type;

        paramTypeMap[condition.data.type].data[key] = 0;
        paramTypeMap[condition.data.type].data[key + '_mq'] = true;

        const s = QueryParamsComponent.detectParentParam(key);
        if (s.length > 0) {
          paramTypeMap[condition.data.type].data[s] = condition.data[s];
          paramTypeMap[condition.data.type].data[s + '_filter'] = 0;
          paramTypeMap[condition.data.type].data[s + '_filter_mq'] = true;
        }
      }
    }

    return false;
  }

  initModalBody(data: any) {
    this.query = data;
    this.buildUserParamConditions();
  }

  onModalAccept$(): Observable<ModalResult<void>> {
    if ((this.stableContextFormGroup && !this.stableContextFormGroup.valid)
      || (this.animalContextFormGroup && !this.animalContextFormGroup.valid)
      || (this.eventContextFormGroup && !this.eventContextFormGroup.valid)
      || (this.quarantineContextFormGroup && !this.quarantineContextFormGroup.valid)
      || (this.agentContextFormGroup && !this.agentContextFormGroup.valid)
      || (this.institutionTicketContextFormGroup && !this.institutionTicketContextFormGroup.valid)
      || (this.drugContextFormGroup && !this.drugContextFormGroup.valid)) {

      return of(new ModalResult(false, null));
    }

    return of(new ModalResult(true, null));
  }

}
