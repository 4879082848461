<div class="itech-common-wrap" id="interceptNoAccess">
  <div>
    <div class="form compact itech-highlighted-sections" [formGroup]="contextFormGroup">

      <app-doc-validation-errors [validationErrors]="contextFormGroup.docLevelServerSideValidationErrors">
      </app-doc-validation-errors>

      <section class="form-block itech-block-medium" formArrayName="templates">

        <div *ngIf="templates.length == 0" class="itech-no-data-text">Шаблоны переработок отсутствуют</div>
        <clr-tree>
          <clr-tree-node *ngFor="let item of templates.controls; index as i" [formGroupName]="i"
                         [clrExpanded]="item.get('expanded').value"
                         (clrExpandedChange)="item.get('expanded').setValue(!item.get('expanded').value)"
                         [class.it-clr-tree-expanded]="item.get('expanded').value">
            <button class="clr-treenode-link">
              {{item.controls['caption'].value || 'новый шаблон'}}
              <button type="button" style="vertical-align: inherit;" class="btn btn-danger btn-link itech-inline-tool-btn"
                      title="Удалить шаблон" (click)="deleteTemplate(item, i)">
                <clr-icon shape="trash"></clr-icon>
              </button>
              <span *ngIf="!item.valid" class="it-invalid-badge"></span>
            </button>
            <clr-tree-node>

              <section class="form-block itech-block-medium" style="overflow: inherit">
                <div class="form-group">
                  <label>Наименование</label>
                  <label for="caption{{i}}" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                         [class.invalid]="isInvalid(item, 'caption')">
                    <app-input-text [contextId]="'caption'" [classNames]="'itech-control-large'"
                                    [formControlObj]="item.get('caption')" [htmlType]="1"
                                    [placeholder]="'Наименование'"></app-input-text>
                    <app-validation-tooltip [input]="item.controls['caption']"></app-validation-tooltip>
                  </label>
                </div>
                <div class="form-group">
                  <label style="display:flex;align-items:center">
                    Вид/подвид исходной продукции
                  </label>
                  <app-text-lookup [validationTooltipRight]="true" class="itech-control-large"
                                   [contextControlId]="'sourceProductTypeId'" [sorted]="true"
                                   [contextControlName]="'sourceProductTypeId'"
                                   [lookupName]="'product-type'" [contextFormGroup]="item"></app-text-lookup>
                  <app-text-lookup *ngIf="item.get('sourceProductTypeId').value"
                                   style="margin-left: 5px"
                                   [validationTooltipRight]="true" class="itech-control-large"
                                   [contextControlId]="'sourceProductSubtypeId'" [sorted]="true"
                                   [contextControlName]="'sourceProductSubtypeId'"
                                   [lookupName]="'product-subtype' + item.get('sourceProductTypeId').value"
                                   [contextFormGroup]="item"></app-text-lookup>
                </div>
                <h6>Вырабатываемая продукция</h6>
                <ng-container *ngFor="let productBatch of item.controls['productionStableProductSubtypes'].controls; index as j">
                  <div class="form-group">
                    <label>
                      Номенклатура/ед.изм/Номер ПП
                    </label>
                    <app-text-lookup [validationTooltipRight]="true" class="itech-control-large"
                                     [contextControlId]="j + 'stableProductSubtypeId'" [sorted]="true"
                                     [contextControlName]="'stableProductSubtypeId'"
                                     [lookupName]="'stable-product-subtype/stable/false/' + id"
                                     [contextFormGroup]="productBatch"></app-text-lookup>
                    &nbsp;&nbsp;
                    <app-combo-lookup [validationTooltipRight]="true" class="itech-control-normal"
                                      [contextControlId]="j + 'unitId'" [contextControlName]="'unitId'" [sorted]="true"
                                      [lookupName]="'drug-unit-type/product'" [contextFormGroup]="productBatch">
                    </app-combo-lookup>
                    &nbsp;&nbsp;
                    <label for="batchId{{i}}{{j}}" aria-haspopup="true" role="tooltip"
                           class="tooltip tooltip-validation tooltip-md"
                           [class.invalid]="isInvalid(productBatch, 'batchId')">
                      <app-input-text [contextId]="'batchId'+i+j" [classNames]="'itech-control-medium'"
                                      [formControlObj]="productBatch.get('batchId')" [htmlType]="1"></app-input-text>
                      <app-validation-tooltip [input]="productBatch.controls['batchId']"></app-validation-tooltip>
                    </label>
                  </div>
                </ng-container>
                <button type="button" class="btn btn-link" (click)="addProductBatch(item)">
                  <clr-icon shape="plus"></clr-icon>&nbsp;добавить
                </button>
              </section>
            </clr-tree-node>
          </clr-tree-node>
        </clr-tree>
      </section>
    </div>
  </div>

  <div class="itech-form-actions">
    <div>
      <button class="btn btn-primary-outline" (click)="navigationService.navigateJournal(undefined, 25, id, undefined)">
        <clr-icon shape="view-list"></clr-icon>&nbsp;Журнал
      </button>

      <button class="btn btn-secondary" (click)="refreshFormData()">
        <clr-icon shape="refresh"></clr-icon>&nbsp;Обновить
      </button>
      <button class="btn btn-success-outline" (click)="addTemplate()">
        <clr-icon shape="plus"></clr-icon>&nbsp;Добавить
      </button>
    </div>
    <div>
      <button [clrLoading]="storing" class="btn btn-primary itech-form-save"
              [disabled]="!contextFormGroup.dirty || storing"
              (click)="store()">
        <clr-icon shape="check"></clr-icon>&nbsp;Сохранить
      </button>
      <button class="btn btn-warning itech-form-cancel" [disabled]="!contextFormGroup.dirty" (click)="cancelEdit()">
        <clr-icon shape="undo"></clr-icon>&nbsp;Отменить
      </button>
    </div>
  </div>
</div>
