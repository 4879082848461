<div class="compact form itech-highlighted-sections" [formGroup]="contextFormGroup" *ngIf="contextFormGroup">

  <app-doc-validation-errors [validationErrors]="contextFormGroup.docLevelServerSideValidationErrors">
  </app-doc-validation-errors>

  <section class="form-block itech-block-medium">
    <h3>Общая информация</h3>

    <div class="form-group itech-group-whole-line">
      <label></label>
      <div class="itech-control-desc">
        Карточка создана:
        {{contextFormGroup.get('createdTime').value | rtime}},
        {{contextFormGroup.get('createdUser').value | lookup: 'user' | async}}
      </div>
    </div>

    <div class="form-group">
      <label for="drugTypeId" class="required">Наименование</label>
      <app-text-lookup [validationTooltipRight]="true" class="itech-control-xlarge"
                       [contextControlId]="'drugTypeId'" [contextControlName]="'drugTypeId'"
                       [commentsFieldName]="'comments'" [sorted]="true" (change)="changeDrugTypeId()"
                       [lookupName]="'drug-type'" [contextFormGroup]="contextFormGroup"></app-text-lookup>
      &nbsp;
      <clr-signpost *ngIf="contextFormGroup.get('drugTypeId').value">
        <clr-signpost-content [clrPosition]="'bottom-left'" *clrIfOpen>
          <h3>Информация о препарате</h3>
          <p *ngIf="((selectedDrugTypeInfo$|async).manualFiles || []).length" style="display:flex;flex-direction:column">
            <b>Инструкции</b>
            <span *ngFor="let fileName of (selectedDrugTypeInfo$|async).manualFiles" style="margin:0">
              <a class="itech-link align-bottom" clrSignpostTrigger (click)="previewFile(fileName)" style="margin:0">
                  {{getPrettyTitle(fileName)}}
              </a>
            </span>
          </p>
          <p *ngIf="(selectedDrugTypeInfo$|async).manufacturerId">
            <b>Производитель&nbsp;</b> <span>{{(selectedDrugTypeInfo$|async).manufacturerId|lookup:'drug-manufacturer'|async}}</span>
          </p>
          <p *ngIf="(selectedDrugTypeInfo$|async).indicationsTxt">
            <b>Показания&nbsp;</b> <span>{{(selectedDrugTypeInfo$|async).indicationsTxt}}</span>
          </p>
          <p *ngIf="(selectedDrugTypeInfo$|async).storageConditionsTxt">
            <b>Условия хранения&nbsp;</b> <span style="color: green">{{(selectedDrugTypeInfo$|async).storageConditionsTxt}}</span>
          </p>
          <p *ngIf="(selectedDrugTypeInfo$|async).contraindicationsTxt">
            <b>Противопоказания&nbsp;</b> <span style="color: maroon">{{(selectedDrugTypeInfo$|async).contraindicationsTxt}}</span>
          </p>
          <p *ngIf="(selectedDrugTypeInfo$|async).sideEffectsTxt">
            <b>Побочные эффекты&nbsp;</b> <span style="color: maroon">{{(selectedDrugTypeInfo$|async).sideEffectsTxt}}</span>
          </p>
        </clr-signpost-content>
      </clr-signpost>
    </div>

    <div class="form-group" *ngIf="!contextFormGroup.get('id').value && !contextFormGroup.get('drugTypeId').value">
      <label></label>
      <a class="itech-link in-table-link" (click)="confirmToCreateDrugType()">
        Добавить новый вид препарата
      </a>
    </div>

    <div class="form-group">
      <label for="serial" class="required">Серия/партия/код</label>
      <label for="serial" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'serial')">
        <input type="text" id="serial" placeholder="Серия" class="itech-control-normal"
               formControlName="serial">
        <app-validation-tooltip [input]="contextFormGroup.controls['serial']"></app-validation-tooltip>
      </label>
      &nbsp;/&nbsp;
      <label for="party" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'party')">
        <input type="text" id="party" placeholder="Партия" class="itech-control-normal"
               formControlName="party">
        <app-validation-tooltip [input]="contextFormGroup.controls['party']"></app-validation-tooltip>
      </label>
      &nbsp;/&nbsp;
      <label for="code" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'code')">
        <input type="text" id="code" placeholder="Номер" class="itech-control-normal"
               formControlName="code">
        <app-validation-tooltip [input]="contextFormGroup.controls['code']"></app-validation-tooltip>
      </label>
    </div>

    <div class="form-group">
      <label for="serial" class="required">Дата выработки</label>
      <label for="manufactureDate" aria-haspopup="true" role="tooltip"
             class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'manufactureDate')">
        <input type="date" id="manufactureDate" class="itech-control-normal"
               formControlName="manufactureDate">
        <app-validation-tooltip [input]="contextFormGroup.controls['manufactureDate']"></app-validation-tooltip>
      </label>
    </div>

    <div class="form-group">
      <label for="actNumber" class="required">Дата поступления/№ акта</label>
      <label for="incomingDate" aria-haspopup="true" role="tooltip"
             class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'incomingDate')">
        <input type="date" id="incomingDate" class="itech-control-normal"
               formControlName="incomingDate">
        <app-validation-tooltip [input]="contextFormGroup.controls['incomingDate']"></app-validation-tooltip>
      </label>
      &nbsp;
      <label for="actNumber" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'actNumber')">
        <input type="text" id="actNumber" placeholder="Номер акта" class="itech-control-medium"
               formControlName="actNumber">
        <app-validation-tooltip [input]="contextFormGroup.controls['actNumber']"></app-validation-tooltip>
      </label>
    </div>

    <div class="form-group">
      <label for="incomingDocTypeId">Документы о приёмке</label>
      <div>
        <div *ngFor="let incomingDoc of contextFormGroup.controls['incomingDocs'].controls; index as i" [formGroup]="incomingDoc">
          <app-combo-lookup [validationTooltipRight]="true" class="itech-control-normal"
                            [contextControlId]="'incomingDocTypeId' + i"
                            [contextControlName]="'typeId'" (change)="changeIncomingDocType(i)"
                            [lookupName]="'drug-incoming-doctype'" [contextFormGroup]="incomingDoc"></app-combo-lookup>
          &nbsp;
          <label for="incomingDocNumber{{i}}" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md"
                 [class.invalid]="isInvalid(incomingDoc, 'number')">
            <input type="text" id="incomingDocNumber{{i}}" placeholder="Номер" class="itech-control-tiny"
                   formControlName="number">
            <app-validation-tooltip [input]="incomingDoc.controls['number']"></app-validation-tooltip>
          </label>
          &nbsp;
          <label for="incomingDocDate{{i}}" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md"
                 [class.invalid]="isInvalid(incomingDoc, 'date')">
            <input type="date" id="incomingDocDate{{i}}" class="itech-control-normal" formControlName="date">
            <app-validation-tooltip [input]="incomingDoc.controls['date']"></app-validation-tooltip>
          </label>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="totalValueStr" class="required">Количество препарата</label>
      <label for="totalValueStr" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'totalValueStr')">
        <input type="text" id="totalValueStr" placeholder="кол-во" class="itech-control-normal"
               formControlName="totalValueStr" (keypress)="processMoneyKeypress($event)"
               (change)="changeStrValue(contextFormGroup, 'totalValueStr')">
        <app-validation-tooltip [input]="contextFormGroup.controls['totalValueStr']"></app-validation-tooltip>
      </label>
      <app-combo-lookup [validationTooltipRight]="true" class="itech-control-large"
                        [contextControlId]="'drugUnitTypeId'" [sorted]="true"
                        [contextControlName]="'drugUnitTypeId'"
                        [lookupName]="'drug-unit-type/drug'" [contextFormGroup]="contextFormGroup"></app-combo-lookup>
    </div>

    <div class="form-group">
      <label for="costStr" class="required">
        Цена за {{contextFormGroup.get('drugUnitTypeId').value|lookup:'drug-unit-type/drug':true|async}}
      </label>
      <label for="costStr" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'costStr')">
        <input type="text" id="costStr" placeholder="00000.00" class="itech-control-normal"
               formControlName="costStr" (change)="changeCost(contextFormGroup)"
               (keypress)="processMoneyKeypress($event)">
        <app-validation-tooltip [input]="contextFormGroup.controls['costStr']"></app-validation-tooltip>
      </label>
      &nbsp;
      <app-combo-lookup [validationTooltipRight]="true" class="itech-control-medium" style="margin-right: 2px"
                        [contextControlId]="'costTypeId'" [contextControlName]="'costTypeId'" (change)="changeCost(contextFormGroup)"
                        [lookupName]="'drug-cost-type'" [contextFormGroup]="contextFormGroup"></app-combo-lookup>
    </div>

    <div class="form-group">
      <label for="cost" class="required">
        Бюджет
      </label>
      <div>
        <app-combo-lookup [validationTooltipRight]="true" class="itech-control-medium" style="margin-right: 2px"
                          [contextControlId]="'budgetTypeId'"
                          [contextControlName]="'budgetTypeId'"
                          [lookupName]="'budget-type'" [contextFormGroup]="contextFormGroup"></app-combo-lookup>
        <app-combo-lookup *ngIf="+contextFormGroup.get('budgetTypeId').value === 1"
                          [validationTooltipRight]="true" class="itech-control-medium"
                          [contextControlId]="'budgetReceiveTypeId'"
                          [contextControlName]="'budgetReceiveTypeId'"
                          [lookupName]="'drug-budget-receive-type'" [contextFormGroup]="contextFormGroup"></app-combo-lookup>
        <div *ngIf="+contextFormGroup.get('budgetReceiveTypeId').value === 2">
          <label for="budgetReceiveComment" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                 [class.invalid]="isInvalid(contextFormGroup, 'budgetReceiveComment')">
            <input type="text" id="budgetReceiveComment" placeholder="Комментарий" class="itech-control-xlarge"
                   formControlName="budgetReceiveComment">
            <app-validation-tooltip [input]="contextFormGroup.controls['budgetReceiveComment']"></app-validation-tooltip>
          </label>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="expiresYears" class="required">Срок годности</label>
      <label for="expiresYears" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'expiresYears')">
        <input type="text" id="expiresYears" placeholder="лет" class="itech-control-small"
               formControlName="expiresYears">
        <app-validation-tooltip [input]="contextFormGroup.controls['expiresYears']"></app-validation-tooltip>
      </label>
      <label for="expiresMonths" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'expiresMonths')">
        <input type="text" id="expiresMonths" placeholder="месяцев" class="itech-control-small"
               formControlName="expiresMonths">
        <app-validation-tooltip [input]="contextFormGroup.controls['expiresMonths']"></app-validation-tooltip>
      </label>
      <label for="expiresDays" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'expiresDays')">
        <input type="text" id="expiresDays" placeholder="дней" class="itech-control-small"
               formControlName="expiresDays">
        <app-validation-tooltip [input]="contextFormGroup.controls['expiresDays']"></app-validation-tooltip>
      </label>
      <label for="expireDate" aria-haspopup="true" role="tooltip"
             class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'expireDate')">
        <input type="date" id="expireDate" class="itech-control-normal" formControlName="expireDate">
        <app-validation-tooltip [input]="contextFormGroup.controls['expireDate']"></app-validation-tooltip>
      </label>
      <label>
        {{contextFormGroup.get('expireDurationCaption').value}}
      </label>
    </div>

    <div class="form-group">
      <label for="firstPackageCountStr">Кол-во в перв. упаковке</label>
      <label for="firstPackageCountStr" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'firstPackageCountStr')">
        <input type="text" id="firstPackageCountStr" placeholder="кол-во" class="itech-control-normal"
               formControlName="firstPackageCountStr" (keypress)="processMoneyKeypress($event)"
               (change)="changeStrValue(contextFormGroup, 'firstPackageCountStr')">
        <app-validation-tooltip [input]="contextFormGroup.controls['firstPackageCountStr']"></app-validation-tooltip>
      </label>
      <app-combo-lookup [validationTooltipRight]="true" class="itech-control-medium"
                        [contextControlId]="'firstPackageUnitId'" [sorted]="true"
                        [contextControlName]="'firstPackageUnitId'"
                        [lookupName]="'drug-unit-type/drug-first-package'" [contextFormGroup]="contextFormGroup"></app-combo-lookup>
    </div>

    <app-combo-multilookup class="itech-control-x-large" [itemTitle]="'Способ введения'" [flexMode]="true"
                           [contextControlName]="'drugIntroductionTypeIds'" [lookupName]="'drug-introduction-type'"
                           [contextFormGroup]="contextFormGroup" [classControl]="'itech-control-medium'">
    </app-combo-multilookup>

    <div class="form-group itech-double-height">
      <label for="comments">Примечания</label>
      <label for="comments" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'comments')">
            <textarea type="text" id="comments" placeholder="Примечания"
                      class="itech-control-xlarge" formControlName="comments">
            </textarea>
        <app-validation-tooltip [input]="contextFormGroup.controls['comments']"></app-validation-tooltip>
      </label>
    </div>

  </section>
  <section class="form-block itech-block-medium" *ngIf="contextFormGroup.get('institutionId').value">
    <h3>Движение</h3>

    <div class="form-group">
      <label>Местонахождение</label>
      <app-link-button [id]="contextFormGroup.get('institutionId').value"
                       [lookupName]="'institution'" [useShort]="true"
                       [idComment]="contextFormGroup.get('institutionBranchId').value"
                       [lookupNameComment]="'institution-branch'" [useShortComment]="true"></app-link-button>
    </div>

    <div class="form-group" *ngIf="contextFormGroup.get('sourceDrugId').value">
      <label>Исходный препарат</label>
      <app-link-button [id]="contextFormGroup.get('sourceDrugId').value" [lookupName]="'drug'"></app-link-button>
    </div>

    <div class="form-group" *ngIf="contextFormGroup.get('sourceDrugId').value">
      <label>Дата прихода</label>
      <div class="itech-text-field itech-control-normal">
        {{contextFormGroup.get('incomingDate').value|rdate}}
      </div>
    </div>

    <div class="form-group">
      <label>Начальное количество</label>
      <div class="itech-text-field itech-control-xlarge">
        {{contextFormGroup.controls['totalValue'].value}}&nbsp;{{contextFormGroup.get('drugUnitTypeId').value|lookup:'drug-unit-type/drug':true|async}}
        <ng-container *ngIf="+contextFormGroup.controls['totalSum'].value > 0">
          (сумма {{contextFormGroup.controls['totalSum'].value.replace('.', ',')}}&nbsp;{{contextFormGroup.get('costTypeId').value|lookup:'drug-cost-type':true|async}})
        </ng-container>
      </div>
    </div>

    <div class="form-group">
      <label>Текущее количество</label>
      <div class="itech-text-field itech-control-xlarge" style="color: green"
           *ngIf="contextFormGroup.controls['currentValue'].value">
        {{contextFormGroup.controls['currentValue'].value}}&nbsp;{{contextFormGroup.get('drugUnitTypeId').value|lookup:'drug-unit-type/drug':true|async}}
        <ng-container *ngIf="+contextFormGroup.controls['currentSum'].value > 0">
          (сумма {{contextFormGroup.controls['currentSum'].value.replace('.', ',')}}&nbsp;{{contextFormGroup.get('costTypeId').value|lookup:'drug-cost-type':true|async}})
        </ng-container>
      </div>
      <div class="itech-text-field itech-control-large" style="color: red"
           *ngIf="!contextFormGroup.controls['currentValue'].value">
        Закончился
      </div>
    </div>

    <div class="form-group">
      <label>Истрачено</label>
      <div class="itech-text-field itech-control-xlarge">
        {{contextFormGroup.controls['spentValue'].value}}&nbsp;{{contextFormGroup.get('drugUnitTypeId').value|lookup:'drug-unit-type/drug':true|async}}
        <ng-container *ngIf="+contextFormGroup.controls['spentSum'].value > 0">
          (сумма {{contextFormGroup.controls['spentSum'].value.replace('.', ',')}}&nbsp;{{contextFormGroup.get('costTypeId').value|lookup:'drug-cost-type':true|async}})
        </ng-container>
      </div>
    </div>

    <div class="form-group">
      <label>Списано</label>
      <div class="itech-text-field itech-control-xlarge">
        {{contextFormGroup.controls['writeoffValue'].value}}&nbsp;{{contextFormGroup.get('drugUnitTypeId').value|lookup:'drug-unit-type/drug':true|async}}
        <ng-container *ngIf="+contextFormGroup.controls['writeoffSum'].value > 0">
          (сумма {{contextFormGroup.controls['writeoffSum'].value.replace('.', ',')}}&nbsp;{{contextFormGroup.get('costTypeId').value|lookup:'drug-cost-type':true|async}})
        </ng-container>
      </div>
    </div>

    <div class="form-group">
      <label>Передано</label>
      <div class="itech-text-field itech-control-xxlarge">
        {{contextFormGroup.controls['movedValue'].value}}&nbsp;{{contextFormGroup.get('drugUnitTypeId').value|lookup:'drug-unit-type/drug':true|async}}
        <ng-container *ngIf="+contextFormGroup.controls['movedSum'].value > 0">
          (сумма {{contextFormGroup.controls['movedSum'].value.replace('.', ',')}}&nbsp;{{contextFormGroup.get('costTypeId').value|lookup:'drug-cost-type':true|async}})
          <a *ngIf="(contextFormGroup.controls['movedSumExtra'].value || []).length"
             class="itech-link" style="font-size:12px"
             (click)="contextFormGroup.controls['movedSumExtraExpanded'].setValue(!contextFormGroup.controls['movedSumExtraExpanded'].value)">
            <clr-icon shape="caret up" *ngIf="contextFormGroup.controls['movedSumExtraExpanded'].value"></clr-icon>
            <clr-icon shape="caret down" *ngIf="!contextFormGroup.controls['movedSumExtraExpanded'].value"></clr-icon>
          </a>
          <ng-container *ngIf="contextFormGroup.controls['movedSumExtraExpanded'].value">
            <div *ngFor="let sumExtra of contextFormGroup.controls['movedSumExtra'].value"
                 style="display:flex;max-height:1rem;overflow:hidden;">
              <app-link-button [id]="sumExtra.institutionId" [widthControl]="400"
                               [lookupName]="'institution'" [useShort]="true"
                               [idComment]="sumExtra.institutionBranchId"
                               [lookupNameComment]="'institution-branch'" [useShortComment]="true"></app-link-button>
              <label>
                &nbsp;-&nbsp;
                {{sumExtra.count}}&nbsp;
                {{contextFormGroup.get('drugUnitTypeId').value|lookup:'drug-unit-type/drug':true|async}}&nbsp;
                (сумма {{sumExtra.sum}}&nbsp;{{contextFormGroup.get('costTypeId').value|lookup:'drug-cost-type':true|async}})
              </label>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <div class="form-group" *ngIf="contextFormGroup.controls['reservedValue'].value">
      <label>Выдано</label>
      <div class="itech-text-field itech-control-xlarge">
        {{contextFormGroup.controls['reservedValue'].value}}&nbsp;{{contextFormGroup.get('drugUnitTypeId').value|lookup:'drug-unit-type/drug':true|async}}
        <ng-container *ngIf="+contextFormGroup.controls['reservedSum'].value > 0">
          (сумма {{contextFormGroup.controls['reservedSum'].value.replace('.', ',')}}&nbsp;{{contextFormGroup.get('costTypeId').value|lookup:'drug-cost-type':true|async}})
        </ng-container>
      </div>
    </div>

  </section>

  <section class="form-block itech-block-medium" *ngIf="contextFormGroup.get('institutionId').value">
    <h3>Выдача</h3>
    <div class="form">
      <div *ngFor="let reserve of reserved.controls; index as i" class="form-group itech-group-whole-line" style="display:flex">
        <app-text-lookup class="itech-control-large" [contextControlId]="'reservedInstitutionEmployeeId' + i"
                         [sorted]="true" [contextControlName]="'institutionEmployeeId'" [required]="true"
                         [lookupName]="'institution-employee' + reserve.get('institutionId').value"
                         [contextFormGroup]="reserve"></app-text-lookup>
        &nbsp;
        <label [for]="'reservedCurrentValueStr' + i">Кол-во:</label>
        &nbsp;
        <label [for]="'reservedCurrentValueStr' + i" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
               [class.invalid]="isInvalid(reserve, 'currentValueStr')" [formGroup]="reserve">
          <input type="text" [id]="'reservedCurrentValueStr' + i" placeholder="кол-во" class="itech-control-small"
                 formControlName="currentValueStr" (keypress)="processMoneyKeypress($event)"
                 (change)="changeStrValue(reserve, 'currentValueStr')">
          <app-validation-tooltip [input]="reserve.controls['currentValueStr']"></app-validation-tooltip>
        </label>
        <label>{{contextFormGroup.get('drugUnitTypeId').value|lookup:'drug-unit-type/drug':true|async}}.</label>&nbsp;
        <button type="button" class="btn btn-danger btn-link itech-inline-tool-btn" style="margin-top:10px;"
                title="Удалить" (click)="deleteReserve(i)">
          <clr-icon shape="trash"></clr-icon>
        </button>
    </div>
      <button type="button" class="btn btn-link" (click)="addReserved()">
        <clr-icon shape="plus"></clr-icon>&nbsp;добавить
      </button>
    </div>
  </section>
</div>
