import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataCachingService } from '../../logic/services/data-caching.service';
import { BookmarkService } from '../../logic/services/bookmark.service';

@Component({
  selector: 'app-edit-animal',
  templateUrl: './edit-animal.component.html'
})
export class EditAnimalComponent implements OnInit {

  @Input() model: any = EditAnimalComponent.createDefaultModel();
  @Input() id: number;
  newAgentId: number;
  newStableId: number;
  isExternalAnimal = false;

  static createDefaultModel(): any {
    return {
      commonTabActive: true,
      eventsTabActive: false,
      productsTabActive: false,
      tickets: false,
    };
  }

  public static getGridGroupingColumns() {
    return [
      {
        field: 'animalTypeGroup', hide: true, excludeExport: true,
        valueGetter: params => params && params.data ? params.data['animalTypeId'] : '',
        rowGroupingSettings: {
          name: 'Виду',
          renderParams: {
            lookupName: 'animal-type',
            lookupValueGetter: row => row.value,
            getChildrenCount: node => EditAnimalComponent.getCountForGroup(node),
          }
        }
      },
      {
        field: 'animalSubtypeGroup', hide: true, excludeExport: true,
        valueGetter: params => params && params.data
            ? params.data.animalGroup
              ? 'group'
              : params.data['animalTypeId'] + '/' + params.data['animalSubtypeId']
            : '',
        rowGroupingSettings: {
          name: 'Подвиду',
          renderParams: {
            lookupName: 'animal-subtype',
            isCombineKey: true,
            captionGetter: row => row.value === 'group' ? 'групповые' : null,
            lookupValueGetter: row => row.value,
            getChildrenCount: node => EditAnimalComponent.getCountForGroup(node),
          }
        }
      },
    ];
  }

  public static getCountForGroup(node: any) {
    let allChildrenCount = 0;
    if (node.allLeafChildren) {
      node.allLeafChildren.forEach(el => allChildrenCount += (el.data ? el.data.totalCount || 0 : 0));
    }
    return '(' + allChildrenCount + ')';
  }

  constructor(private route: ActivatedRoute,
              private bookmarkService: BookmarkService,
              private dataCachingService: DataCachingService) {
  }

  ngOnInit() {

    this.route.paramMap
      .subscribe(params => {
        this.id = parseInt(params.get('id'), 10);
        this.newAgentId = !isNaN(+params.get('newAgentId')) ? +params.get('newAgentId') : undefined;
        this.newStableId = !isNaN(+params.get('newStableId')) ? +params.get('newStableId') : undefined;
        this.isExternalAnimal = params.get('isExternalAnimal') ? params.get('isExternalAnimal') === 'true' : false;

        const existing = this.dataCachingService.getCachedData(!this.isExternalAnimal ? 'EditAnimal' : 'EditAnimal_Temp', this.id.toString());

        if (!this.isExternalAnimal) {
          this.bookmarkService.refreshAnimalCardBookmark(this.id, this.newAgentId, this.newStableId);
        }

        if (existing) {
          this.model = existing;
        } else {
          this.model = EditAnimalComponent.createDefaultModel();
          this.model.newAgentId = this.newAgentId;
          this.dataCachingService.addToCache(!this.isExternalAnimal ? 'EditAnimal' : 'EditAnimal_Temp', this.id.toString(), this.model);
        }
      });
  }

  navigateEventsTab() {
    this.model.commonTabActive = false;
    this.model.eventsTabActive = true;
  }
}
