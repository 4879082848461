<div class="itech-search-table-with-filter" style="top:90px" *ngIf="securityService.isAccess('quarantine') | async">
  <div style="display: flex; flex-direction: row;">
    <clr-icon shape="search" size="72"></clr-icon>
    <div class="compact form itech-highlighted-sections" [formGroup]="filterFormGroup"
         *ngIf="model && filterFormGroup">
      <section class="form-block itech-block-normal">
        <h3>Поиск карантинов</h3>

        <div class="form-group">
          <label for="cadNo">Кадастровый номер</label>
          <label for="cadNo" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                 [class.invalid]="isInvalid(filterFormGroup, 'cadNo')">
            <input type="text" id="cadNo" placeholder="Кадастровый номер" class="itech-control-xlarge"
                   formControlName="cadNo" (keydown)="filterEnterPressed($event)">
          </label>
        </div>

        <div class="form-group">
          <label for="regionId">Мун.обр./Нас.пункт</label>
          <app-text-lookup class="itech-control-normal" [contextControlId]="'regionId'" [sorted]="true"
                           [contextControlName]="'regionId'" [lookupName]="'addr-region'"
                           [contextFormGroup]="filterFormGroup"
                           (change)="filterFormGroup.get('cityId').setValue(null)"></app-text-lookup>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <app-text-lookup class="itech-control-large" [contextControlId]="'cityId'" [sorted]="true"
                           [contextControlName]="'cityId'" [commentsFieldName]="'comments'"
                           [lookupName]="getLookupNameForCity()"
                           [contextFormGroup]="filterFormGroup"></app-text-lookup>
        </div>

        <div class="form-group">
          <label for="cityId">Заболевание</label>
          <app-text-lookup class="itech-control-xlarge" [contextControlId]="'diseaseTypeId'" [sorted]="true"
                           [contextControlName]="'diseaseTypeId'" [lookupName]="'disease-type/may-be-introduced-quarantine'"
                           [contextFormGroup]="filterFormGroup"></app-text-lookup>
        </div>

        <div class="form-group">
          <label>Срок действия с/по</label>
          <label for="dateFrom" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md itech-control-normal"
                 [class.invalid]="isInvalid(filterFormGroup, 'dateFrom')">
            <input type="date" id="dateFrom" formControlName="dateFrom" (keydown)="filterEnterPressed($event)">
          </label>
          <label for="dateToInclusive" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md itech-control-normal"
                 [class.invalid]="isInvalid(filterFormGroup, 'dateToInclusive')">
            <input type="date" id="dateToInclusive" formControlName="dateToInclusive" (keydown)="filterEnterPressed($event)">
          </label>
        </div>

        <div class="form-group">
          <label for="currentStatusId">Текущий статус</label>
          <app-combo-lookup class="itech-control-medium" [contextControlId]="'currentStatusId'"
                            [contextControlName]="'currentStatusId'" [lookupName]="'quarantine-status'"
                            [contextFormGroup]="filterFormGroup"></app-combo-lookup>
        </div>

        <div class="form-group itech-group-whole-line">
          <div class="checkbox-inline">
            <input type="checkbox" id="noFilteredByRights" formControlName="noFilteredByRights">
            <label for="noFilteredByRights">Показать недоступные для редактирования</label>
          </div>
          <div class="checkbox-inline">
            <input type="checkbox" id="onlyWithViolations" [(ngModel)]="onlyWithViolations"
                   (ngModelChange)="changeRowData()" [ngModelOptions]="{standalone: true}">
            <label for="onlyWithViolations" *ngIf="rowDataWithViolations">Показать только карантины с нарушениями:
              <label [style.color]="rowDataWithViolations.length > 0 ? 'red' : 'black'" style="padding-left:0">
                {{rowDataWithViolations.length}}
              </label>
            </label>
          </div>
        </div>

        <div class="itech-form-line">
          <button class="btn btn-primary" (click)="refreshResults()">
            <clr-icon shape="search" class="has-badge"></clr-icon>&nbsp;Поиск
          </button>
          <div *ngIf="rowData && rowData.length >= 50"
               class="itech-control-desc itech-desc-error">
            Отображены первые 50 найденных записей - часть записей могла
            быть не отображена. Уточните, при необходимости, параметры поиска.
          </div>
        </div>
      </section>
    </div>
  </div>

  <div style="top:300px">
    <app-grid [pagination]="false" [getRowStyle] = "getRowStyle" [uniqueGridName]="'QuarantineSearchResultsGrid'"
              [rowData]="rowData" (rowClicked)="performQuarantineCard($event.data)" [columnDefs]="gridColumnDefs"
              [exportFileName]="'Список карантинов'"></app-grid>
  </div>
</div>
