/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../logic/pipes/lookup-source.pipe";
import * as i3 from "../../../logic/services/lookup-source.service";
import * as i4 from "../../../logic/services/address-person-fio-cache.service";
import * as i5 from "@angular/platform-browser";
import * as i6 from "./edit-institution-agent-message-form.component";
var styles_EditInstitutionAgentMessageFormComponent = [".message-view[_ngcontent-%COMP%] {\n      background-color:white;\n      min-height:100%;\n      border-radius:10px;\n      width:100%;\n      display:flex;\n      flex-direction:column-reverse;\n      overflow-y: scroll;\n    }\n\n    .message[_ngcontent-%COMP%] {\n      width:max-content;\n      margin:10px;\n      padding:10px;\n      border-radius:10px;\n      box-shadow:\n              0 3px 3px -2px rgba(0, 0, 0, 0.2),\n              0 3px 4px 0 rgba(0, 0, 0, 0.14),\n              0 1px 8px 0 rgba(0, 0, 0, 0.12);\n    }\n\n    .message.outcoming[_ngcontent-%COMP%] {\n      background-color: #005598;\n      color: white;\n      align-self: flex-end;\n    }\n\n    .message.incoming[_ngcontent-%COMP%] {\n      background-color: white;\n      color: black;\n      align-self: flex-start;\n    }\n\n    .message[_ngcontent-%COMP%]   .message-header[_ngcontent-%COMP%] {\n      font-size: 10px;\n      color: silver;\n      line-height: 0.5rem;\n    }"];
var RenderType_EditInstitutionAgentMessageFormComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_EditInstitutionAgentMessageFormComponent, data: {} });
export { RenderType_EditInstitutionAgentMessageFormComponent as RenderType_EditInstitutionAgentMessageFormComponent };
function View_EditInstitutionAgentMessageFormComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "message"]], [[2, "outcoming", null], [2, "incoming", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "div", [["class", "message-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵeld(4, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", " "])), i0.ɵppd(6, 3), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵppd(8, 3), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(10, 0, null, null, 1, "div", [["class", "message-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(11, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.userId; var currVal_1 = !_v.context.$implicit.userId; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.getRuDatetime(_v.context.$implicit.date); _ck(_v, 3, 0, currVal_2); var currVal_3 = (_v.context.$implicit.userId ? i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 7).transform(i0.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i0.ɵnov(_v.parent, 0), (_v.context.$implicit.userId - 0), "user", true)))) : i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 9).transform(i0.ɵunv(_v, 5, 0, _ck(_v, 8, 0, i0.ɵnov(_v.parent, 0), (_v.context.$implicit.agentId - 0), "agent", true))))); _ck(_v, 5, 0, currVal_3); var currVal_4 = _v.context.$implicit.text; _ck(_v, 11, 0, currVal_4); }); }
export function View_EditInstitutionAgentMessageFormComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i2.LookupSourcePipe, [i3.LookupSourceService, i4.AddressPersonFioCacheService, i5.DomSanitizer]), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "message-view"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EditInstitutionAgentMessageFormComponent_1)), i0.ɵdid(3, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.messages; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_EditInstitutionAgentMessageFormComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-edit-institution-agent-message-form", [], null, null, null, View_EditInstitutionAgentMessageFormComponent_0, RenderType_EditInstitutionAgentMessageFormComponent)), i0.ɵdid(1, 49152, null, 0, i6.EditInstitutionAgentMessageFormComponent, [], null, null)], null, null); }
var EditInstitutionAgentMessageFormComponentNgFactory = i0.ɵccf("app-edit-institution-agent-message-form", i6.EditInstitutionAgentMessageFormComponent, View_EditInstitutionAgentMessageFormComponent_Host_0, { messages: "messages" }, {}, []);
export { EditInstitutionAgentMessageFormComponentNgFactory as EditInstitutionAgentMessageFormComponentNgFactory };
