<div class="itech-search-table-with-filter" *ngIf="securityService.isAccess('stable') | async">
  <div style="display: flex; flex-direction: row;">
    <clr-icon shape="search" size="72"></clr-icon>
    <div class="compact form itech-highlighted-sections" [formGroup]="model.filterFormGroup"
         *ngIf="model && model.filterFormGroup">
      <section class="form-block itech-block-medium">
        <h3>Поиск поднадзорных объектов</h3>
        <div class="form-group">
          <label>Наименование/ИД</label>
          <label for="caption" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                 [class.invalid]="isInvalid(model.filterFormGroup, 'caption')">
            <app-input-text [contextId]="'caption'" [classNames]="'itech-control-larger'"
                            [formControlObj]="model.filterFormGroup.get('caption')" [htmlType]="1"
                            [placeholder]="'Наименование'" (keydown)="filterEnterPressed($event)"></app-input-text>
            <app-validation-tooltip [input]="model.filterFormGroup.controls['caption']"></app-validation-tooltip>
          </label>
          <label for="id" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                 [class.invalid]="isInvalid(model.filterFormGroup, 'id')">
            <input type="text" id="id" placeholder="ИД" class="itech-control-small"
                   formControlName="id" (keypress)="processMoneyKeypress($event)" (keydown)="filterEnterPressed($event)">
            <app-validation-tooltip [input]="model.filterFormGroup.controls['id']"></app-validation-tooltip>
          </label>
        </div>
        <div class="form-group" *ngIf="!model.filterFormGroup.controls['agentId'].value">
          <label for="owner">Хоз. субъект</label>
          <label for="owner" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                 [class.invalid]="isInvalid(model.filterFormGroup, 'owner')">
            <app-input-text [contextId]="'owner'" [classNames]="'itech-control-large'"
                            [formControlObj]="model.filterFormGroup.get('owner')" [htmlType]="1"
                            [placeholder]="'Владелец'" (keydown)="filterEnterPressed($event)"></app-input-text>
            <app-validation-tooltip [input]="model.filterFormGroup.controls['owner']"></app-validation-tooltip>
          </label>
          <div class="checkbox-inline">
            <input type="checkbox" id="showDeleted" formControlName="showDeleted" (change)="refreshResults()">
            <label for="showDeleted">Аннулированные</label>
          </div>
        </div>
        <div class="form-group" *ngIf="model.filterFormGroup.controls['agentId'].value">
          <label>Владелец</label>
          <div class="itech-text-field itech-text-field-no-wrap" style="width:476px;max-width:476px"
               title="{{model.filterFormGroup.controls['agentId'].value | lookup: 'agent' | async}}">
            {{model.filterFormGroup.controls['agentId'].value | lookup: 'agent' | async}}
          </div>
          &nbsp;&nbsp;
          <button *ngIf="model.filterFormGroup.controls['availableChangeAgentId'].value"
                  type="button" class="btn btn-danger btn-link itech-inline-tool-btn" title="Указать другого владельца"
                  (click)="model.filterFormGroup.controls['agentId'].setValue(undefined)">
            <clr-icon shape="remove"></clr-icon>
          </button>
        </div>
        <div class="form-group">
          <label for="address">Адрес</label>
          <label for="address" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                 [class.invalid]="isInvalid(model.filterFormGroup, 'address')">
            <app-input-text [contextId]="'address'" [classNames]="'itech-control-xlarge'"
                            [formControlObj]="model.filterFormGroup.get('address')" [htmlType]="1"
                            [placeholder]="'Адрес'" (keydown)="filterEnterPressed($event)"></app-input-text>
            <app-validation-tooltip [input]="model.filterFormGroup.controls['address']"></app-validation-tooltip>
          </label>
        </div>
        <div class="form-group">
          <label for="address">Номер в Цербере/Кад.номер</label>
          <label for="code" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                 [class.invalid]="isInvalid(model.filterFormGroup, 'code')">
            <app-input-text [contextId]="'code'" [classNames]="'itech-control-normal'"
                            [formControlObj]="model.filterFormGroup.get('code')" [htmlType]="1"
                            [placeholder]="'Номер в Цербере'" (keydown)="filterEnterPressed($event)"></app-input-text>
            <app-validation-tooltip [input]="model.filterFormGroup.controls['code']"></app-validation-tooltip>
          </label>
          <label for="cadNo" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                 [class.invalid]="isInvalid(model.filterFormGroup, 'cadNo')">
            <app-input-text [contextId]="'cadNo'" [classNames]="'itech-control-large'"
                            [formControlObj]="model.filterFormGroup.get('cadNo')" [htmlType]="1"
                            [placeholder]="'Кадастровый номер'" (keydown)="filterEnterPressed($event)"></app-input-text>
            <app-validation-tooltip [input]="model.filterFormGroup.controls['cadNo']"></app-validation-tooltip>
          </label>
        </div>
        <div class="itech-form-line">
          <button class="btn btn-primary" (click)="refreshResults()">
            <clr-icon shape="search" class="has-badge"></clr-icon>&nbsp;Поиск
          </button>
          <button *ngIf="model.filterFormGroup.controls['agentId'].value" class="btn btn-outline-secondary"
                  (click)="updateFromMercuryAndRefreshFormData()">
            <clr-icon shape="refresh"></clr-icon>&nbsp;Обновить список из Меркурия
          </button>
          <div *ngIf="model.searchResults && model.searchResults.length >= 50"
               class="itech-control-desc itech-desc-error">
            Отображены первые 50 найденных записей - часть записей могла
            быть не отображена. Уточните, при необходимости, параметры поиска.
          </div>
        </div>
      </section>
    </div>
  </div>

  <div>
    <app-grid [pagination]="false" [getRowStyle]="getRowStyle" [uniqueGridName]="'StableSearchResultsFormGrid'"
              [rowData]="model.searchResults" (rowClicked)="rowClicked.emit($event.data)" [columnDefs]="gridColumnDefs"
              [exportFileName]="'Список поднадзорных объектов'"></app-grid>
  </div>
</div>
