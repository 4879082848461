import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormHelper } from '../../ui/controls/form-helper';
import { MetadataService } from '../../logic/services/metadata.service';
import { GlobalWaitingOverlayService } from '../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../logic/services/lookup-source.service';
import { AgentSearchModalComponent } from '../edit-agent/search/agent-search-modal.component';
import { AppNavigationService } from '../../logic/services/app-navigation.service';
import { AlertService } from '../../ui/infrastructure/alert.service';
import { AddressPersonFioCacheService } from '../../logic/services/address-person-fio-cache.service';
import { BookmarkService } from '../../logic/services/bookmark.service';
import { MetaBrowseBaseComponent } from '../dictionary/meta-browse.base.component';
import { ActivatedRoute } from '@angular/router';
import { DataCachingService } from '../../logic/services/data-caching.service';

@Component({
  selector: 'app-distribution-agent-available-pins',
  templateUrl: './app-distribution-agent-available-pins.component.html'
})
export class AppDistributionAgentAvailablePinsComponent extends MetaBrowseBaseComponent {

  _searchResults = [];
  currentFormGroup: FormGroup;
  currentIsNew = false;
  editModalOpened = false;

  isInvalid = FormHelper.isInvalid;

  gridColumnDefs = [
    {headerName: 'Хозяйствующий субъект', field: 'agentCaption', tooltipField: 'agentCaption', width: 300},
    {headerName: 'Номенклатура', field: 'nomenclatureCaption', tooltipField: 'nomenclatureCaption', width: 300},
    {headerName: 'Номер с', field: 'numberFrom', width: 100},
    {headerName: 'Номер по', field: 'numberTo', width: 100},
  ];

  constructor(public route: ActivatedRoute,
              public metadataService: MetadataService,
              public alertService: AlertService,
              public globalWaitingOverlayService: GlobalWaitingOverlayService,
              public lookupService: LookupSourceService,
              public bookmarkService: BookmarkService,
              public fb: FormBuilder,
              private personFioCacheService: AddressPersonFioCacheService,
              private navigationService: AppNavigationService,
              dataCachingService: DataCachingService) {
    super(route, metadataService, alertService, globalWaitingOverlayService, lookupService, fb, bookmarkService, dataCachingService);
  }

  refreshResults() {
    this.globalWaitingOverlayService.StartWaiting();

    this.lookupService.getLookupObj('nomenclature-animal-pin')
      .subscribe(nomenclatureLookup => {
        this.metadataService.getMetadata(this.getMetaName()).subscribe({
          next: data => {

            if (!data || !data.length) {
              this._searchResults = [];
              this.globalWaitingOverlayService.EndWaiting();
              return;
            }

            for (let i = 0; i < data.length; i++) {
              const el = data[i];
              el.nomenclatureCaption = nomenclatureLookup['Obj' + el.nomenclatureAnimalPinId].caption;

              this.personFioCacheService.getAgent(el.agentId)
                .subscribe(agent => {

                  el.agentCaption = agent['shortCaption'];

                  if (i === data.length - 1) {
                    this._searchResults = data;
                    this.lookupService.invalidateLookup(this.getMetaName());
                    this.globalWaitingOverlayService.EndWaiting();
                  }
                });
            }
          }, error: () => {
            this.globalWaitingOverlayService.EndWaiting();
          }
        });
      });
  }

  editRow(row: any) {
    this.currentFormGroup = this.fb.group(this.getGroupDef(row));
    this.currentIsNew = !this.currentFormGroup.get('id').value;

    if (!this.currentIsNew) {
      this.metadataService.getMetadataSingle(this.getMetaName(), this.currentFormGroup.get('id').value)
        .subscribe(detailedRec => {
        this.currentFormGroup = this.fb.group(this.getGroupDef(detailedRec));
        this.editModalOpened = true;
      });
    } else {
      this.editModalOpened = true;
    }
  }

  getGroupDef(row: any) {
    return {
      id: row.id,
      agentId: row.agentId,
      nomenclatureAnimalPinId: row.nomenclatureAnimalPinId,
      numberFrom: row.numberFrom,
      numberTo: row.numberTo,
    };
  }

  searchAgent() {
    this.navigationService.searchAgent(AgentSearchModalComponent).subscribe(val => {
      if (val) {
        this.currentFormGroup.get('agentId').setValue(val);
        this.currentFormGroup.markAsDirty();
      }
    });
  }

  getMetaName(): string {
    return 'agent-available-pins';
  }

  getMetaTitle(): string {
    return 'Распределение бирок животных';
  }
}
