import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LookupSourceService } from './lookup-source.service';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { StringHelper } from '../../helpers/string-helper';
import { DateHelper } from '../../helpers/date-helper';

@Injectable()
export class InstitutionTicketDataService {

  constructor(private http: HttpClient,
              private lookupSource: LookupSourceService) {
  }

  public storeTicket(days: any[], ticket: any): Observable<any> {
    return this.http.post(environment.api + '/institution-ticket/store', ticket.newData).pipe(map((response: any) => {
      Object.assign(ticket.data, response.data);
      this.deleteTicketFromDaysModel(days, ticket);
      this.addTicketToDaysModel(days, ticket);
      return response;
    }));
  }

  public storeTicketTemplate(ticket: any): Observable<any> {
    return this.http.post(environment.api + '/institution-ticket/store/template', ticket.newData)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  deleteTicketFromDaysModel(days: any[], ticket: any) {
    if (ticket.slots && ticket.slots.length) {
      ticket.slots.forEach(slot => this.tryRemoveTicket(slot.tickets, ticket));
      ticket.slots = [];
    }

    if (ticket.days && ticket.days.length) {
      ticket.days.forEach(day => this.tryRemoveTicket(day.tickets, ticket));
      ticket.days = [];
    }

    days.forEach(day => this.tryRemoveTicket(day.tickets, ticket));
  }

  private tryRemoveTicket(tickets: any[], ticket: any) {
    const ix = tickets.findIndex(x => (x.data || {}).id === (ticket.data || {}).id);
    tickets.splice(ix, 1);
  }

  public fillDaysModelTickets(days: any[],
                              institutionId: number,
                              institutionBranchId: number) {
    const retVal = new Subject<any>();
    this.readTicketsForPeriod(days[0].date, days[days.length - 1].date, institutionId, institutionBranchId)
      .subscribe(tickets => {
        tickets.sort((el1, el2) => el1.startTime.localeCompare(el2.startTime));
        tickets.forEach(ticket => this.addTicketToDaysModel(days, {data: ticket}));

        retVal.next(true);
      });
    return retVal;
  }

  private readTicketsForPeriod(startDate: Date,
                               endDate: Date,
                               institutionId: number,
                               institutionBranchId: number) {
    const qstring = environment.api + `/institution-ticket/read-for-period/` +
      `${StringHelper.getISODate(startDate)}/${StringHelper.getISODate(endDate)}/` +
      `${institutionId}/${institutionBranchId}`;

    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  private addTicketToDaysModel(days: any[], ticket: any) {

    this.lookupSource.getLookupObj('institution-ticket-quest').subscribe(ticketType => {

      const startDate = days[0].date;
      const ticketStartDate = DateHelper.roundDateToMinutes(ticket.data.startTime);
      const ticketEndDate = DateHelper.roundDateToMinutes(
        DateHelper.addMinutes(new Date(ticket.data.startTime), ticket.data.durationMinutes));
      const day_ix_from = Math.max(DateHelper.daysBetween(startDate, ticketStartDate), 0);
      const day_ix_to = Math.min(DateHelper.daysBetween(startDate, ticketEndDate), days.length - 1);

      for (let i = day_ix_from; i <= day_ix_to; i++) {

        // Если услуга пересекается с днем, то докинем её к услугам по дням
        if (ticketEndDate > days[i].date && ticketStartDate < DateHelper.addDays(days[i].date, 1)) {

          if (!days[i].tickets) {
            days[i].tickets = [];
          }
          days[i].tickets.push(ticket);

          if (!ticket.days) {
            ticket.days = [];
          }
          ticket.days.push(days[i]);
        }

        for (let j = 0; j < days[i].slots.length; j++) {
          const slotStart = DateHelper.dateOf(days[i].date, days[i].slots[j].timeStart);
          const slotEnd = DateHelper.dateOf(days[i].date, days[i].slots[j].timeEnd);

          // добавляем услугу к слоту, если услуга хоть как то пересекается со слотом
          if ((slotStart < ticketEndDate && slotEnd > ticketStartDate)
            || (ticketStartDate.getTime() === ticketEndDate.getTime() && slotStart <= ticketStartDate && slotEnd > ticketStartDate)) {
            if (!days[i].slots[j].tickets) {
              days[i].slots[j].tickets = [];
            }
            days[i].slots[j].tickets.push(ticket);
            days[i].slots[j].tickets.sort((a, b) =>
              DateHelper.isAfter(
                (((a || {}).data || {}).startTime || DateHelper.MAX_DATE_STRING),
                (((b || {}).data || {}).startTime || DateHelper.MAX_DATE_STRING)));

            if (!ticket.slots) {
              ticket.slots = [];
            }
            ticket.slots.push(days[i].slots[j]);
          }
        }
      }
    });
  }

  public deleteTicket(days: any[], ticket: any): Observable<any> {
    return this.http.post(environment.api + '/institution-ticket/delete', ticket).pipe(map((response: any) => {
      Object.assign(ticket.data || {}, response.data);
      this.deleteTicketFromDaysModel(days, ticket);
      if (ticket.data) {
        this.addTicketToDaysModel(days, ticket);
      }
      return response;
    }));
  }

  public refreshTemplates(model: any) {
    this.getTemplates(model.institutionId)
      .subscribe(val => {
        model.ticketTemplates = val;
        model.ticketTemplates.sort((a, b) => a.templateCaption.localeCompare(b.templateCaption));
      });
  }

  public getTemplates(institutionId: number): Observable<any[]> {
    return this.http.get(environment.api + '/institution-ticket/templates/' + institutionId)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  public deleteTicketTemplate(ticket: any): Observable<any> {
    return this.http.post(environment.api + '/institution-ticket/delete/template', ticket)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  public distributeTickets(ticket: any) {
    return this.http.post(environment.api + '/institution-ticket/distribute', ticket)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  public searchByAnimal(animalId: number, isExternal: boolean = false): Observable<any[]> {
    return this.http.get(`${environment.api}/institution-ticket/by-animal/${isExternal ? 1 : 0}/${animalId}`)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  public searchByAgent(agentId: number): Observable<any[]> {
    return this.http.get(environment.api + '/institution-ticket/by-agent/' + agentId)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  public markSuccessful(ticketId: number) {
    return this.http.get(environment.api + '/institution-ticket/mark-successful/' + ticketId)
      .pipe(map((response: any) => {
        return response as any;
      }));
  }
}
