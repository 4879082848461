import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { FormBuilder } from '@angular/forms';
import { ProductSearchResultsFormComponent } from './product-search-results-form.component';
import { ProductDataService } from '../../../logic/services/product-data.service';
import { AlertService } from '../../../ui/infrastructure/alert.service';

@Component({
  selector: 'app-product-search-results',
  templateUrl: './product-search-results.component.html'
})
export class ProductSearchResultsComponent implements OnInit {

  model = ProductSearchResultsFormComponent.getDefaultModel();
  refreshResultEvent = new EventEmitter();

  public static deleteManyDocuments(selectedRows: any[], alertService: AlertService,
                                    productDataService: ProductDataService, callback: () => void) {
    if (!selectedRows || !selectedRows.length) {
      return;
    }
    alertService.confirmModal('Вы уверены, что хотите удалить безвозвратно выбранные документы?')
      .subscribe(() => selectedRows.forEach((row, ix) =>
        productDataService.deleteProduct(row.id).subscribe(() => {
          if (callback && ix === selectedRows.length - 1) {
            callback();
          }
        })));
  }

  public static sendManyDocumentsToRegistration(selectedRows: any[], alertService: AlertService,
                                                productDataService: ProductDataService, callback: () => void) {
    if (!selectedRows || !selectedRows.length) {
      return;
    }
    alertService.confirmModal('Вы уверены, что хотите направить выбранные документы на регистрацию в Меркурий?')
      .subscribe(() => productDataService.sendToRegistration(selectedRows.map(x => x.id)).subscribe(() => callback()));
  }

  public static sendManyDocumentsToClearance(selectedRows: any[], alertService: AlertService,
                                             productDataService: ProductDataService, callback: () => void) {
    if (!selectedRows || !selectedRows.length) {
      return;
    }
    alertService.confirmModal('Вы уверены, что хотите направить выбранные документы на погашение в Меркурий в полном объеме?')
      .subscribe(() =>  productDataService.sendToClearance({
        manyProductIds: selectedRows.map(x => x.id),
      }).subscribe(() => callback()));
  }

  public static createTransportForSelectedDocuments(selectedRows: any[], navigationService: AppNavigationService) {
    navigationService.performProductCreation(undefined, undefined, undefined,
      undefined, undefined, selectedRows.map(x => x.id));
  }

  constructor(private route: ActivatedRoute,
              private productDataService: ProductDataService,
              private dataCachingService: DataCachingService,
              public appNavigationService: AppNavigationService,
              private fb: FormBuilder,
              private alertService: AlertService) {
  }

  ngOnInit() {

    this.route.paramMap
      .subscribe(() => {

        const existing = this.dataCachingService.getCachedData('ProductSearchResults', '1');

        if (existing) {
          this.model = existing;
        } else {
          this.model = ProductSearchResultsFormComponent.getDefaultModel(this.fb);
          this.dataCachingService.addToCache('ProductSearchResults', '1', this.model);
        }
      });
  }

  performItemEditing(productData: any) {
    this.appNavigationService.performProductEditing(productData.id);
  }

  deleteDocument() {
    ProductSearchResultsComponent.deleteManyDocuments(this.model.selectedRows, this.alertService, this.productDataService,
      () => this.refreshResultEvent.emit());
  }

  sendToRegistration() {
    ProductSearchResultsComponent.sendManyDocumentsToRegistration(this.model.selectedRows, this.alertService, this.productDataService,
      () => this.refreshResultEvent.emit());
  }

  sendToClearance() {
    ProductSearchResultsComponent.sendManyDocumentsToClearance(this.model.selectedRows, this.alertService, this.productDataService,
      () => this.refreshResultEvent.emit());
  }

  createTransportForSelectedDocuments() {
    ProductSearchResultsComponent.createTransportForSelectedDocuments(this.model.selectedRows, this.appNavigationService);
  }
}
