import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { LookupSourceService } from '../../logic/services/lookup-source.service';
import { FormArray, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-checkbox-select',
  template: `
    <div [class.app-checkbox-select-border]="!selectType" [style.padding.px]="selectType ? 0 : 5">
        <div *ngIf="!compact && !selectType" style="width:100%">
            <button type="button" class="btn btn-primary btn-link" (click)="selectAll()"
                    style="margin:0 10px 0 0;padding:0;font-size:10px">
                выбрать все
            </button>
            <button type="button" class="btn btn-primary btn-link" (click)="unselectAll()"
                    style="margin:0;padding:0;font-size:10px">
                снять все
            </button>
        </div>
        <ng-container *ngIf="!selectType; else selectTypeHtml">
            <div *ngFor="let item of items; index as i" class="checkbox itech-no-margins"
                 [class.disabled]="disabled">
                <input type="checkbox" id="check{{i}}_{{rndToken}}" [disabled]="disabled" [(ngModel)]="item.checked"
                       (change)="updateContextFormGroup(item)">
                <label for="check{{i}}_{{rndToken}}">{{useShort ? item.data.shortCaption || item.data.caption : item.data.caption}}</label>
            </div>
        </ng-container>
        <ng-template #selectTypeHtml>
            <label for="{{contextControlName}}" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md">
                <input autocomplete="off" #textInput type="text" id="{{contextControlName}}"
                       (focus)="onFocus()" (blur)="onBlur()" [class.disabled]="disabled" [attr.disabled]="disabled?true:undefined"/>
            </label>
            <div *ngIf="focusedSelectType" style="position: relative; top: 7px; left: -150px">
                <div class="itech-lookup-popup-container">
                    <div *ngFor="let item of items; index as i" (click)="updateContextFormGroup(item)"
                         class="itech-lookup-popup-element checkbox itech-no-margins" id="{{'ppp' + rndToken + '_' + i}}">
                        <input type="checkbox" id="check{{i}}_{{rndToken}}" [disabled]="disabled" [(ngModel)]="item.checked"
                               (change)="updateContextFormGroup(item)">
                        <label for="selectCheckboxes{{i}}_{{rndToken}}">
                            {{useShort ? item.data.shortCaption || item.data.caption : item.data.caption}}
                        </label>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
  `
})
export class AppCheckboxSelectComponent implements OnChanges {
  @Input() disabled;
  @Input() contextControlName;
  @Input() contextFormGroup;
  @Input() sorted = false;
  @Input() lookupName: string;
  @Input() nameId = 'id';
  @Input() compact = false;
  @Input() selectType = false;
  @Input() useShort = false;

  items = [];
  itemsLookup = {};
  rndToken = Math.floor(Math.random() * 1000000);

  loadedLookupSorted: string;

  focusedSelectType = false;
  hBlurTimeout: any;
  @ViewChild('textInput') textInput: ElementRef;

  constructor(private lookupSourceService: LookupSourceService,
              private fb: FormBuilder) {
  }

  get boundArray(): FormArray {
    return this.contextFormGroup.get(this.contextControlName) as FormArray;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.loadedLookupSorted !== this.lookupName + ':' + this.sorted) {
      this.loadedLookupSorted = this.lookupName + ':' + this.sorted;
      this.lookupSourceService.getLookup(this.lookupName).subscribe(lookupContent => {

        if (!this.sorted) {
          lookupContent.sort((item1, item2) => item1[this.nameId] - item2[this.nameId]);
        } else {
          lookupContent.sort((item1, item2) => item1.caption ? item1.caption.localeCompare(item2.caption) : 0);
        }
        this.itemsLookup = {};
        this.items = lookupContent.map(el => {
          const item = { data: el, checked: false };
          this.itemsLookup[el[this.nameId]] = item;
          return item;
        });
        this.updateItemsCheckedState();
        this.tryUpdateSelectTypeTextInput();
      });
    }

    if (changes.hasOwnProperty('contextFormGroup') || changes.hasOwnProperty('contextControlName')) {
      if (this.contextFormGroup && this.contextControlName) {
        this.updateItemsCheckedState();

        this.boundArray.valueChanges.subscribe(() => {
          this.updateItemsCheckedState();
        });
      }
    }
  }

  private updateItemsCheckedState() {
    this.items.forEach(el => {
      el.checked = false;
    });
    this.boundArray.controls.forEach(ctrl => {
      const item = this.itemsLookup[ctrl.value];
      if (item) {
        item.checked = true;
      }
    });
  }

  updateContextFormGroup(item: any) {
    if (this.selectType) {
      this.textInput.nativeElement.focus();
    }
    let index = -1;
    this.boundArray.value.forEach((value, ix) => {
      if (value.toString() === item.data[this.nameId].toString()) {
        index = ix;
      }
    });

    if (index === -1) {
      this.boundArray.push(this.fb.control(item.data[this.nameId].toString()));
    } else {
      this.boundArray.removeAt(index);
    }

    this.tryUpdateSelectTypeTextInput();
    this.boundArray.markAsDirty();
  }

  tryUpdateSelectTypeTextInput() {
    if (!this.textInput || !this.textInput.nativeElement) {
      return;
    }
    setTimeout(() => {
      if (this.boundArray.length === 0) {
        this.textInput.nativeElement.value = '-';
      } else if (this.boundArray.length === 1) {
        this.textInput.nativeElement.value = this.itemsLookup[this.boundArray.at(0).value].data.caption;
      } else {
        this.textInput.nativeElement.value = 'Выбрано ' + this.boundArray.length + ' эл.';
      }
    }, 500);
  }

  selectAll() {
    const unselectedItems = [];
    this.items.forEach(el => {
      if (!el.checked) {
        unselectedItems.push(this.fb.control(el.data[this.nameId].toString()));
        el.checked = true;
      }
    });

    if (!unselectedItems.length) {
      return;
    }

    unselectedItems.forEach(item => this.boundArray.push(item));
    this.boundArray.markAsDirty();
  }

  unselectAll() {
    const selectedItems = [];
    this.items.forEach(el => {
      if (el.checked) {
        selectedItems.push(el.data[this.nameId].toString());
        el.checked = false;
      }
    });

    if (!selectedItems.length) {
      return;
    }

    const indexes = [];
    this.boundArray.value.forEach((value, ix) => {
      if (selectedItems.includes(value.toString())) {
        indexes.push(ix);
      }
    });

    indexes.sort((a, b) => b - a); // критично отсортировать перед удалением в порядке уменьшения, чтобы начать удаление с конца
    indexes.forEach(index => this.boundArray.removeAt(index));
    this.boundArray.markAsDirty();
  }

  onFocus() {
    clearTimeout(this.hBlurTimeout);
    this.focusedSelectType = true;
  }

  onBlur() {
    clearTimeout(this.hBlurTimeout);
    this.hBlurTimeout = setTimeout(() => this.focusedSelectType = false, 200);
  }
}
