import { EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstitutionDataService } from '../../../logic/services/institution-data.service';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { SecurityService } from '../../../logic/services/security.service';
var InstitutionEmployeeModalComponent = /** @class */ (function () {
    function InstitutionEmployeeModalComponent(institutionDataService, fb, alertService, userService) {
        this.institutionDataService = institutionDataService;
        this.fb = fb;
        this.alertService = alertService;
        this.userService = userService;
        this.addEmployeeModalOpened = false;
        this.addEmployeeModalOpenedChanged = new EventEmitter();
        this.tabActive = {
            commonTabActive: true,
            accessTabActive: false,
            mercuryTabActive: false,
            mobileTabActive: false,
        };
        this.currentIsNew = false;
        this.mercuryLoading = true;
        this.deviceLoading = true;
        this.deviceStoring = false;
        this.deviceDeleting = false;
        this.deviceHasPinCode = false;
    }
    Object.defineProperty(InstitutionEmployeeModalComponent.prototype, "currentEmployee", {
        get: function () {
            return this._currentEmployee;
        },
        set: function (employee) {
            if (this._currentEmployee !== employee) {
                this._currentEmployee = employee;
            }
        },
        enumerable: true,
        configurable: true
    });
    InstitutionEmployeeModalComponent.prototype.isInvalid = function (fg, cname) {
        return FormHelper.isInvalid(fg, cname);
    };
    InstitutionEmployeeModalComponent.prototype.addEditEmployeeCompleted = function () {
        if (this.tabActive.mercuryTabActive) {
            return;
        }
        if (!this.currentFormGroup.valid) {
            FormHelper.markAsSubmitted(this.currentFormGroup);
            return;
        }
        if (this.currentIsNew) {
            this.employees.push(this.currentFormGroup.value);
        }
        else {
            Object.assign(this.currentEmployee, this.currentFormGroup.value);
        }
        this.model.dirty = true;
        this.addEmployeeModalOpened = false;
        this.addEmployeeModalOpenedChanged.next();
    };
    InstitutionEmployeeModalComponent.prototype.addEditEmployeeCancelled = function () {
        this.addEmployeeModalOpened = false;
        this.addEmployeeModalOpenedChanged.next();
    };
    InstitutionEmployeeModalComponent.prototype.storeMercuryAuthInfo = function () {
        var _this = this;
        if (!this.tabActive.mercuryTabActive) {
            return;
        }
        if (!this.mercuryAuthInfo.valid) {
            FormHelper.markAsSubmitted(this.mercuryAuthInfo);
            return;
        }
        this.institutionDataService.storeEmployeeMercuryAuthInfo(this.id, this.currentFormGroup.get('id').value, this.mercuryAuthInfo.value)
            .subscribe(function () { return _this.getMercuryAuthInfo(); });
    };
    InstitutionEmployeeModalComponent.prototype.deleteEmployee = function (employee) {
        if (employee.id) {
            this.model.delete.push(employee.id);
        }
        var index = this.employees.findIndex(function (item) { return item === employee; });
        this.employees.splice(index, 1);
        this.model.dirty = true;
        this.addEmployeeModalOpened = false;
        this.addEmployeeModalOpenedChanged.next();
    };
    InstitutionEmployeeModalComponent.prototype.regionIdCityIdChanged = function (event) {
        var _this = this;
        setTimeout(function () {
            var matches = _this.currentFormGroup.value.regionIdCityId
                ? _this.currentFormGroup.value.regionIdCityId.match(/(\d+)-(\d+)/)
                : undefined;
            _this.currentFormGroup.get('defaultRegionId').setValue(matches ? matches[1] : undefined);
            _this.currentFormGroup.get('defaultCityId').setValue(matches ? matches[2] : undefined);
        }, 300);
    };
    InstitutionEmployeeModalComponent.prototype.getMercuryAuthInfo = function () {
        var _this = this;
        if (this.tabActive.mercuryTabActive && this.currentFormGroup.get('id').value) {
            this.mercuryLoading = true;
            this.institutionDataService.getEmployeeMercuryAuthInfo(this.id, this.currentFormGroup.get('id').value)
                .subscribe(function (info) {
                _this.mercuryAuthInfo = _this.fb.group({
                    login: [info.login, Validators.required],
                    password: [info.password, Validators.required],
                    hasLogin: info.hasLogin,
                    hasPassword: info.hasPassword,
                });
                _this.mercuryLoading = false;
            }, function () { return _this.mercuryLoading = false; });
        }
    };
    InstitutionEmployeeModalComponent.prototype.getEmployeeDevice = function () {
        var _this = this;
        if (this.tabActive.mobileTabActive && this.currentFormGroup.get('id').value) {
            this.deviceLoading = true;
            this.institutionDataService.getEmployeeDevice(this.id, this.currentFormGroup.get('id').value)
                .subscribe(function (info) {
                if (!info) {
                    _this.device = null;
                }
                else {
                    _this.device = _this.fb.group({
                        uid: [info.uid],
                        uniqueUserId: [info.uniqueUserId],
                        deviceInfo: [{ value: info.deviceInfo, disabled: true }],
                        registrationRequestTime: [{ value: info.registrationRequestTime, disabled: true }],
                        lastAccessTime: [{ value: info.lastAccessTime, disabled: true }],
                        pinCode: [info.pinCode, Validators.compose([
                                Validators.required,
                                Validators.pattern('\\d+'),
                                Validators.minLength(4),
                            ])],
                    });
                    _this.deviceHasPinCode = !!_this.device.value.pinCode;
                }
                _this.deviceLoading = false;
            }, function () { return _this.deviceLoading = false; });
        }
    };
    InstitutionEmployeeModalComponent.prototype.storeEmployeeDevice = function () {
        var _this = this;
        if (!this.tabActive.mobileTabActive) {
            return;
        }
        if (!this.device.valid) {
            FormHelper.markAsSubmitted(this.device);
            return;
        }
        this.deviceStoring = true;
        this.institutionDataService.acceptEmployeeDevice(this.device.value)
            .subscribe(function () {
            _this.deviceStoring = false;
            _this.device.markAsPristine();
            _this.alertService.success('PIN-код успешно установлен');
        }, function () { return _this.deviceStoring = false; });
    };
    InstitutionEmployeeModalComponent.prototype.deleteEmployeeDevice = function () {
        var _this = this;
        if (!this.tabActive.mobileTabActive || !this.device) {
            return;
        }
        this.alertService.confirmModal('Вы действительно хотите удалить устройство?')
            .subscribe(function () {
            _this.deviceDeleting = true;
            _this.institutionDataService.deleteEmployeeDevice(_this.device.value)
                .subscribe(function () { return _this.deviceDeleting = false; }, function () { return _this.deviceDeleting = false; });
            _this.device = undefined;
        });
    };
    return InstitutionEmployeeModalComponent;
}());
export { InstitutionEmployeeModalComponent };
