<div class="itech-common-wrap no-pager" *ngIf="securityService.isAccess('quarantine') | async">
  <div>
    <clr-tabs>
      <clr-tab>
        <button clrTabLink>Общее</button>
        <ng-template [(clrIfActive)]="model.commonTabActive" (clrIfActiveChange)="refreshFormDataInternal($event)">
          <clr-tab-content>
            <div class="edit-page-tab" style="margin-left:0">
              <div *ngIf="contextFormGroupCommon" style="max-width:100px">
                <clr-icon shape="flame" size="80" style="margin-left:10px"></clr-icon>
                <div style="text-align:center"
                     [style.color]="+this.contextFormGroupCommon.get('currentStatusId').value < 3
                                    ? undefined
                                    : +this.contextFormGroupCommon.get('currentStatusId').value > 4 ? 'silver' : 'red'">
                  {{periodCaption}}
                </div>
                <div style="padding-top:20px;width:100px;font-size:10px;margin-left:10px"
                     *ngIf="!contextFormGroupCommon.get('needActualizeTrappedObjects').value">
                  <div style="display:flex"
                       [title]="'Поднадзорных органов, попавших под карантин: ' + contextFormGroupCommon.get('trappedStableCount').value">
                    <clr-icon shape="building" style="color:green" size="32"></clr-icon>
                    <p style="color:green;width:60px;margin:5px 0 0 0;vertical-align:middle;text-align:center;"
                       [style.font-size.px]="+contextFormGroupCommon.get('trappedStableCount').value < 1000000
                       ? 16 : +contextFormGroupCommon.get('trappedStableCount').value >= 100000000 ? 11 : 12">
                      {{contextFormGroupCommon.get('trappedStableCount').value}}
                    </p>
                  </div>
                  <div style="display:flex;padding-top:10px"
                       [title]="'Животных, попавших под карантин: ' + contextFormGroupCommon.get('trappedAnimalCount').value">
                    <clr-icon shape="piggy-bank" style="color:green" size="32"></clr-icon>
                    <p style="color:green;width:60px;margin:5px 0 0 0;vertical-align:middle;text-align:center;"
                      [style.font-size.px]="+contextFormGroupCommon.get('trappedAnimalCount').value < 1000000
                       ? 16 : +contextFormGroupCommon.get('trappedAnimalCount').value >= 100000000 ? 11 : 12">
                      {{contextFormGroupCommon.get('trappedAnimalCount').value}}
                    </p>
                  </div>
                </div>
                <div style="margin-top:20px" *ngIf="contextFormGroupCommon.get('needActualizeTrappedObjects').value">
                  <a style="color:red;cursor:pointer;" (click)="runCalculateTrappedObjectsOp()">
                    <clr-icon shape="refresh" style="width:90px;height:25px;display:flex;font-size:10px;text-align:center;line-height:0.5rem;">
                      Сведения устарели</clr-icon>
                  </a>
                </div>
                <div *ngIf="contextFormGroupCommon.get('isViolationDeadlineFile').value" style="margin-top:20px">
                  <a style="color:red;cursor:pointer;"
                     (click)="navigationService.navigateJournal(undefined, 21, id, -1)">
                    <clr-icon shape="history" style="width:90px;height:25px;display:flex;font-size:10px;text-align:center;line-height:0.5rem;">
                      Нарушение сроков</clr-icon>
                  </a>
                </div>
              </div>
            </div>

            <div class="itech-doc-content">
              <app-edit-quarantine-common [contextFormGroup]="contextFormGroupCommon" [serverSideErrorsProvider]="this.model"
                                          [isAccessEdit]="isAccessEdit" (dirtyFormGroup)="dirty = true"></app-edit-quarantine-common>
            </div>
          </clr-tab-content>
        </ng-template>
      </clr-tab>
      <clr-tab>
        <button clrTabLink><clr-icon shape="event"></clr-icon>Этапы</button>
        <ng-template [(clrIfActive)]="model.stagesTabActive" (clrIfActiveChange)="refreshFormDataInternal($event)">
          <clr-tab-content>
            <div class="edit-page-tab itech-quarantine-page-tab" id="interceptNoAccess">
              <app-edit-quarantine-stages *ngIf="contextFormGroupCommon" [contextFormGroup]="contextFormGroupStages"
                                          [serverSideErrorsProvider]="this.model" [isAccessEdit]="isAccessEdit"
                                          [id]="contextFormGroupCommon.get('id').value" (dirtyFormGroup)="dirty = true"
                                          [currentStatusId]="contextFormGroupCommon.get('currentStatusId').value"
                                          (needUpdateQuarantine)="refreshFormDataInternal()"
                                          [noSuspicionStage]="contextFormGroupCommon.get('noSuspicionStage').value">
              </app-edit-quarantine-stages>
            </div>
          </clr-tab-content>
        </ng-template>
      </clr-tab>
      <clr-tab>
        <button clrTabLink><clr-icon shape="bell"></clr-icon>Мероприятия</button>
        <ng-template [(clrIfActive)]="model.quaEventsTabActive">
          <clr-tab-content>
            <div class="edit-page-tab">
              <app-edit-quarantine-events [id]="id">
              </app-edit-quarantine-events>
            </div>
          </clr-tab-content>
        </ng-template>
      </clr-tab>
      <clr-tab>
        <button clrTabLink><clr-icon shape="piggy-bank"></clr-icon>Животные</button>
        <ng-template [(clrIfActive)]="model.animalTabActive">
          <clr-tab-content>
            <div class="edit-page-tab itech-quarantine-page-tab" id="interceptNoAccess">
              <div style="position:absolute;top:0;left:0">
                <button class="btn btn-primary btn-link itech-form-save" style="margin:0"
                        [disabled]="!selectedAnimalsFromGrid.length ||
                        equalsSome(contextFormGroupCommon.get('currentStatusId').value, 0, 5)"
                        (click)="createAnimalEvent()">
                  <clr-icon shape="color-picker"></clr-icon>&nbsp;Добавить мероприятие
                </button>
                <button class="btn btn-primary btn-link itech-form-save" style="margin:0"
                        [disabled]="!selectedAnimalsFromGrid.length ||
                        equalsSome(contextFormGroupCommon.get('currentStatusId').value, 0, 5)"
                        (click)="openCreateAnimalEventModal()">
                  <clr-icon shape="color-picker"></clr-icon>&nbsp;Мероприятие с результатом
                </button>
                <div *ngIf="equalsSome(contextFormGroupCommon.get('currentStatusId').value, 0, 5)"
                     class="itech-control-desc itech-desc-error">
                  Нельзя добавлять мероприятия по карантину со статусом
                  &nbsp;'{{contextFormGroupCommon.get('currentStatusId').value|lookup:'quarantine-status'|async}}'
                </div>
              </div>
              <app-grid style="position:absolute;top:50px;bottom:0;left:0;right:0" [pagination]="false"
                        [rowData]="getTrappedAllAnimal().value" (rowClicked)="navigationService.performAnimalEditing($event.data.id)"
                        [rowMultiSelectWithClick]="true" [rowSelection]="'multiple'" (rowSelected)="onAnimalGridSelectionChanged($event)"
                        [columnDefs]="gridAnimalColumnDefs" [uniqueGridName]="'EditQuarantineAnimalsGrid'" (gridReady)="animalGridReady($event)"
                        [exportFileName]="'Список животных, попавших под карантин ' + (id|lookup:'quarantine'|async)"></app-grid>
            </div>
          </clr-tab-content>
        </ng-template>
      </clr-tab>
      <clr-tab>
        <button clrTabLink><clr-icon shape="building"></clr-icon>Поднадзорные объекты</button>
        <ng-template [(clrIfActive)]="model.stableTabActive">
          <clr-tab-content>
            <div class="edit-page-tab itech-quarantine-page-tab" id="interceptNoAccess">
              <div style="position:absolute;top:0;left:0">
                <button class="btn btn-primary btn-link itech-form-save"
                        [disabled]="!selectedStableIdFromGrid.length ||
                        contextFormGroupCommon.get('currentStatusId').value === 0 ||
                        contextFormGroupCommon.get('currentStatusId').value === 5"
                        (click)="createStableEvent()">
                  <clr-icon shape="color-picker"></clr-icon>&nbsp;Запланировать мероприятие
                </button>
              </div>
              <app-grid style="position:absolute;top:50px;bottom:0;left:0;right:0" [pagination]="false"
                        [rowData]="getTrappedAllStable().value" (rowClicked)="navigationService.performStableEditing($event.data.id)"
                        [rowMultiSelectWithClick]="true" [rowSelection]="'multiple'" (rowSelected)="onStableGridSelectionChanged($event)"
                        [columnDefs]="gridStableColumnDefs" [uniqueGridName]="'EditQuarantineStablesGrid'"
                        [exportFileName]="'Список ПО, попавших под карантин ' + (id|lookup:'quarantine'|async)"></app-grid>
            </div>
          </clr-tab-content>
        </ng-template>
      </clr-tab>
      <clr-tab>
        <button clrTabLink><clr-icon shape="color-picker"></clr-icon>Исслед./лечен.</button>
        <ng-template [(clrIfActive)]="model.eventsTabActive">
          <clr-tab-content>
            <div class="edit-page-tab">
              <app-view-quarantine-animalstable-events [quarantineId]="id">
              </app-view-quarantine-animalstable-events>
            </div>
          </clr-tab-content>
        </ng-template>
      </clr-tab>
      <clr-tab>
        <button clrTabLink><clr-icon shape="chat-bubble"></clr-icon>Обсуждения</button>
        <ng-template [(clrIfActive)]="model.messageTabActive">
          <clr-tab-content>
            <div class="edit-page-tab">
              <app-edit-quarantine-message [quarantineId]="id">
              </app-edit-quarantine-message>
            </div>
          </clr-tab-content>
        </ng-template>
      </clr-tab>
    </clr-tabs>
  </div>
  <div class="itech-form-actions">
    <div>
      <button class="btn btn-secondary" (click)="refreshFormData()" [disabled]="model.messageTabActive">
        <clr-icon shape="refresh"></clr-icon>&nbsp;Обновить
      </button>
      <button class="btn btn-primary-outline" [disabled]="model.messageTabActive"
              (click)="navigationService.navigateJournal(undefined, model.quaEventsTabActive? 22: 21, id, undefined)">
        <clr-icon shape="view-list"></clr-icon>&nbsp;Журнал
      </button>
      <clr-dropdown>
        <button type="button" class="btn btn-warning-outline" [disabled]="model.messageTabActive" clrDropdownTrigger>
          Действия
          <clr-icon shape="caret down"></clr-icon>
        </button>
        <clr-dropdown-menu *clrIfOpen [clrPosition]="'top-left'">
          <button class="btn btn-primary-outline" [disabled]="!isAccessEdit" (click)="runCalculateTrappedObjectsOp()">
            <clr-icon shape="refresh"></clr-icon> Перерассчитать
          </button>
          <button class="btn btn-primary-outline" [disabled]="!isAccessEdit ||
                  !this.contextFormGroupCommon.get('currentStatusId').value.toString()"
                  (click)="openChangeStatusModal()">
            <clr-icon shape="repeat"></clr-icon>&nbsp;Изменить статус
          </button>
          <div class="dropdown-divider"></div>
          <button class="btn btn-warning-outline" [disabled]="!isAccessEdit" (click)="deleteQuarantine()">
            <clr-icon shape="trash"></clr-icon> Удалить
          </button>
        </clr-dropdown-menu>
      </clr-dropdown>
      <app-reports-button [reportKind]="'quarantine'" [objId1]="id" [dropdownPosition]="'top-right'"
                          [disabled]="model.messageTabActive">
      </app-reports-button>
    </div>
    <div>
      <button [clrLoading]="storing" class="btn btn-primary itech-form-save"
              [disabled]="!dirty || storing || !(model.commonTabActive || model.stagesTabActive)" (click)="store()">
        <clr-icon shape="check"></clr-icon>&nbsp;Сохранить
      </button>
      <button class="btn btn-warning itech-form-cancel"
              [disabled]="!dirty || !(model.commonTabActive || model.stagesTabActive)" (click)="cancelEdit()">
        <clr-icon shape="undo"></clr-icon>&nbsp;Отменить
      </button>
    </div>
  </div>
</div>

<clr-modal [(clrModalOpen)]="changeStatusModalOpened" *ngIf="changeStatusModalOpened" [clrModalClosable]="false" [clrModalSize]="'lg'">
  <h3 class="modal-title">Изменение статуса карантина</h3>
  <div class="modal-body"  style="min-height: 140px">
    <div class="form compact" [formGroup]="changeStatusFormGroup">
      <div class="form-group">
        <label for="diseaseTypeId" class="required">Новый статус</label>
        <app-combo-lookup class="itech-control-large" [validationTooltipRight]="true"
                          [contextControlId]="'changeStatusNewStatusId'" [sorted]="false"
                          [contextControlName]="'newStatusId'" [lookupName]="'quarantine-status'"
                          [disabledChoices]="getDisabledChoicesChangeStatus()"
                          (change)="changeStatusFormGroup.get('checkboxValue').setValue(false)"
                          [contextFormGroup]="changeStatusFormGroup" [disabled]="!isAccessEdit"></app-combo-lookup>
      </div>
      <div class="form-group">
        <label for="dateFromNewStatus" class="required">Дата с</label>
        <label for="dateFromNewStatus" aria-haspopup="true" role="tooltip"
               class="tooltip tooltip-validation tooltip-md disabled tooltip-bottom-right"
               [class.invalid]="isInvalid(changeStatusFormGroup, 'dateFromNewStatus')">
          <input type="date" id="dateFromNewStatus" class="itech-control-normal"
                 formControlName="dateFromNewStatus" [attr.disabled]="!isAccessEdit ? true : undefined">
          <app-validation-tooltip [input]="changeStatusFormGroup.controls['dateFromNewStatus']"></app-validation-tooltip>
        </label>
      </div>
      <div style="margin-top: 20px">
        <div class="checkbox" style="font-size: 0.5rem">
          <input type="checkbox" id="checkbox-confirm-action" formControlName="checkboxValue">
          <label for="checkbox-confirm-action" style="padding-left: 20px"
                 *ngIf="changeStatusFormGroup.get('newStatusId').value &&
                        changeStatusFormGroup.get('newStatusId').value < this.contextFormGroupCommon.get('currentStatusId').value">
            Я уведомлен, что принудительное изменение статуса удалит данные о продолжительности этапов,
            идущих после выбранного (прикрепленные файлы останутся неизмененными).
            {{changeStatusFormGroup.get('newStatusId').value == 0 ? ' Также будут очищены данные о попавших под карантин.' : ''}}
          </label>
          <label for="checkbox-confirm-action" style="padding-left: 20px"
                 *ngIf="changeStatusFormGroup.get('newStatusId').value &&
                        changeStatusFormGroup.get('newStatusId').value > this.contextFormGroupCommon.get('currentStatusId').value">
            Я уведомлен, что при принудительном изменении статуса будут пропущены статусы (этапы) между текущим и новым.
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer itech-form-actions">
    <div>
    </div>
    <div>
      <button type="button" class="btn btn-primary"
              [disabled]="!changeStatusFormGroup.get('checkboxValue').value"
              (click)="changeStatus()">
        <clr-icon shape="check"></clr-icon>&nbsp;Изменить
      </button>
      <button type="button" class="btn btn-warning"
              (click)="changeStatusModalOpened = false">
        <clr-icon shape="undo"></clr-icon>&nbsp;Отмена
      </button>
    </div>
  </div>
</clr-modal>

<clr-modal *ngIf="createAnimalEventModalOpened && createAnimalEventFormGroup"
           [(clrModalOpen)]="createAnimalEventModalOpened" [clrModalClosable]="false" [clrModalSize]="'lg'">
  <h3 class="modal-title">Добавление мероприятия</h3>
  <div class="modal-body"  style="min-height:140px;overflow:visible">

    <app-doc-validation-errors [validationErrors]="createAnimalEventFormGroup.docLevelServerSideValidationErrors">
    </app-doc-validation-errors>

    <div class="form compact" [formGroup]="createAnimalEventFormGroup">
      <div class="form-group">
        <label for="dateAnimalEvent" class="required">Дата факт / №</label>
        <label for="dateAnimalEvent" aria-haspopup="true" role="tooltip"
               class="tooltip tooltip-validation tooltip-md disabled tooltip-bottom-right"
               [class.invalid]="isInvalid(createAnimalEventFormGroup, 'date')">
          <input type="date" id="dateAnimalEvent" class="itech-control-normal"
                 formControlName="date">
          <app-validation-tooltip [input]="createAnimalEventFormGroup.controls['date']"></app-validation-tooltip>
        </label>
        <label for="examNo" aria-haspopup="true" role="tooltip"
               class="tooltip tooltip-validation tooltip-md"
               [class.invalid]="isInvalid(createAnimalEventFormGroup, 'examNo')">
          <input type="text" id="examNo" placeholder="№" class="itech-control-normal"
                 formControlName="examNo">
          <app-validation-tooltip [input]="createAnimalEventFormGroup.controls['examNo']"></app-validation-tooltip>
        </label>
      </div>
      <div class="form-group">
        <label for="diseaseTypeId" class="required">Вид мероприятия</label>
        <div style="display:grid">
          <app-text-lookup class="itech-control-large" [validationTooltipRight]="true"
                           [contextControlId]="'eventTypeId'" [sorted]="true"
                           [contextControlName]="'eventTypeId'" [lookupName]="'event-type/animal'"
                           [contextFormGroup]="createAnimalEventFormGroup"></app-text-lookup>
        </div>
      </div>
      <div class="form-group" *ngIf="createAnimalEventFormGroup.get('eventKind').value === 1">
        <label for="laboratoryInstitutionId" class="required">Лаборатория</label>
        <app-combo-lookup class="itech-control-large" [contextControlId]="'laboratoryInstitutionId'" [sorted]="true"
                          [contextControlName]="'laboratoryInstitutionId'" [lookupName]="'lab-institution'"
                          [contextFormGroup]="createAnimalEventFormGroup"></app-combo-lookup>
      </div>
      <div style="margin-top: 20px">
        <div class="checkbox" style="font-size: 0.5rem">
          <input type="checkbox" id="createAnimalEventCheckboxConfirmAction" formControlName="checkboxValue">
          <label for="createAnimalEventCheckboxConfirmAction" style="padding-left: 20px">
            Я понимаю, что для каждого выбранного животного (в том числе для каждого подвида группового животного)
            будет создано данное мероприятие с отрицательным результатом
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer itech-form-actions">
    <div>
    </div>
    <div>
      <button type="button" class="btn btn-primary" [clrLoading]="createAnimalEventStoreProcess"
              [disabled]="createAnimalEventStoreProcess || !createAnimalEventFormGroup.get('checkboxValue').value"
              (click)="storeAnimalEvent()">
        <clr-icon shape="check"></clr-icon>&nbsp;Создать
      </button>
      <button type="button" class="btn btn-warning"
              (click)="createAnimalEventModalOpened = false">
        <clr-icon shape="undo"></clr-icon>&nbsp;Отмена
      </button>
    </div>
  </div>
</clr-modal>
