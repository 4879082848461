import { Component, OnInit } from '@angular/core';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { FormBuilder } from '@angular/forms';
import { ProductSearchResultsFormComponent } from './product-search-results-form.component';
import { IModalBody, ModalResult } from '../../../logic/services/app-navigation.service.models';
import { Observable, of } from 'rxjs';

@Component({
  template: `
    <div style="height: calc(100vh - 300px); background-color: white; position: relative;">
      <app-product-search-results-form [model]="model" (selectionChanged)="selectedChanges($event)"
                                       [autoChangeExpanded]="true" (rowClicked)="chooseProduct($event.id)">
      </app-product-search-results-form>
    </div>
  `
})
export class ProductSearchModalComponent implements OnInit, IModalBody<any, any[]> {

  model = ProductSearchResultsFormComponent.getDefaultModel();
  addManyProducts = false;
  selectedProductIds: any[];

  constructor(public appNavigationService: AppNavigationService,
              private fb: FormBuilder) {
  }

  ngOnInit() {
    this.model = ProductSearchResultsFormComponent.getDefaultModel(this.fb, this.appNavigationService.customModalParams);
    this.addManyProducts = this.appNavigationService.customModalParams.addManySelected;
  }

  chooseProduct(val: number) {
    if (!this.addManyProducts) {
      this.appNavigationService.customModalAcceptExternalPromiseNumber.next(val);
      this.appNavigationService.customModalOpened = false;
    }
  }

  initModalBody(data: any) {
  }

  onModalAccept$(): Observable<ModalResult<any[]>> {
    return of(new ModalResult(true, this.selectedProductIds));
  }

  selectedChanges($event) {
    this.selectedProductIds = $event;
    this.appNavigationService.customModalAcceptButtonText = 'Выбрать' +
      (this.selectedProductIds && this.selectedProductIds.length ? ' (' + this.selectedProductIds.length + ' шт.)' : '');
  }
}
