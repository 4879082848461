import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AlertService} from '../../../ui/infrastructure/alert.service';
import {GlobalWaitingOverlayService} from '../../../ui/infrastructure/global-waiting-overlay.service';
import {MetadataService} from '../../../logic/services/metadata.service';
import {FormBuilder, Validators} from '@angular/forms';
import {LookupSourceService} from '../../../logic/services/lookup-source.service';
import {BookmarkService} from '../../../logic/services/bookmark.service';
import {DataCachingService} from '../../../logic/services/data-caching.service';
import {MetaBrowseBaseComponent} from '../../../pages/dictionary/meta-browse.base.component';
import {FormHelper} from '../../../ui/controls/form-helper';
import {StringHelper} from '../../../helpers/string-helper';

@Component({
  templateUrl: './pst-report-type-browse.component.html'
})
export class PstReportTypeBrowseComponent extends MetaBrowseBaseComponent {

  gridColumnDefs = [
    {headerName: '№', field: 'id', width: 100},
    {headerName: 'Наименование', field: 'caption', tooltipField: 'caption', width: 200},
    {headerName: 'Уровень', field: 'reportingLevelCaption', width: 120},
    {headerName: 'Периодичность', field: 'periodicityCaption', width: 120},
    {headerName: 'Дней на предост.', field: 'daysDelay', width: 120},
    {
      headerName: 'Начала сбора', field: 'startedDate', width: 120,
      valueFormatter: params => StringHelper.getRuDate(params.value),
    },
    {
      headerName: 'Окончание сбора', field: 'finishedDate', width: 120,
      valueFormatter: params => StringHelper.getRuDate(params.value),
    },
  ];

  constructor(route: ActivatedRoute,
              metadataService: MetadataService,
              alertService: AlertService,
              globalWaitingOverlayService: GlobalWaitingOverlayService,
              lookupService: LookupSourceService,
              fb: FormBuilder,
              bookmarkService: BookmarkService,
              dataCachingService: DataCachingService) {
    super(route, metadataService, alertService, globalWaitingOverlayService, lookupService, fb, bookmarkService, dataCachingService);
  }

  getMetaTitle(): string {
    return 'Виды электронных отчетов';
  }

  getMetaName(): string {
    return 'pst-report-type';
  }

  refreshResults() {
    this.globalWaitingOverlayService.StartWaiting();

    this.lookupService.getLookupObj('pst-reporting-level').subscribe(reportLevelLookup => {
      this.lookupService.getLookupObj('pst-report-periodicity').subscribe(reportingPeriodicityLookup => {
        this.metadataService.getMetadata(this.getMetaName()).subscribe({
          next: data => {
            this._searchResults = data;

            this._searchResults.forEach(el => {
              el.reportingLevelCaption = reportLevelLookup[el.reportingLevel];
              el.periodicityCaption = reportingPeriodicityLookup[el.periodicity];
            });

            this.dataCachingService.addToCache(this.getMetaName() + '_Data', '1', data);
            this.lookupService.invalidateLookup(this.getMetaName());
            this.globalWaitingOverlayService.EndWaiting();
          }, error: () => {
            this.globalWaitingOverlayService.EndWaiting();
          }
        });
      });
    });
  }

  getGroupDef(row: any) {
    return {
      id: [row.id, Validators.compose([Validators.required, Validators.pattern(/^\d{1,3}$/)])],
      caption: [row.caption, Validators.required],
      groupCaption: row.groupCaption,
      reportingLevel: [row.reportingLevel, Validators.required],
      periodicity: [row.periodicity, Validators.required],
      daysDelay: [row.daysDelay, Validators.compose([Validators.required, Validators.pattern(/^\d{1,2}$/)])],
      startedDate: [row.startedDate, Validators.compose([Validators.required, FormHelper.validateDateTimePicker()])],
      finishedDate: [row.finishedDate, FormHelper.validateDateTimePicker()],
    };
  }
}
