import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {FormHelper} from '../../ui/controls/form-helper';
import {GlobalWaitingOverlayService} from '../../ui/infrastructure/global-waiting-overlay.service';
import {AppNavigationService} from '../../logic/services/app-navigation.service';
import {LookupSourceService} from '../../logic/services/lookup-source.service';
import {SecurityService} from '../../logic/services/security.service';
import {PstReportService} from '../services/pst-report.service';
import {StringHelper} from '../../helpers/string-helper';
import {Router} from '@angular/router';
import {DataCachingService} from '../../logic/services/data-caching.service';
import {DateHelper} from '../../helpers/date-helper';
import { AgentSearchModalComponent } from '../../pages/edit-agent/search/agent-search-modal.component';

@Component({
  templateUrl: './pst-reports-list.component.html'
})
export class PstReportsListComponent implements OnInit {

  @Output() rowClicked = new EventEmitter();
  model: any;
  @Input() availableChangeStableId = true;

  toggleCaptionOrGuid = true;
  isInvalid = FormHelper.isInvalid;

  gridColumnDefs = [
    {
      headerName: '№', field: 'id', width: 50,
    },
    {
      headerName: 'Наименование', field: 'reportTypeCaption', width: 550,
      cellRenderer: params => {
        let stateHtml = '';
        if (params.data.reportState === 10) {
          stateHtml = '<clr-icon shape="lock" title="Отчет отправлен"></clr-icon>';
        }
        if (params.data.reportState === 200) {
          stateHtml = '<clr-icon shape="check" style="color:green" title="Отчет принят"></clr-icon>';
        }

        return stateHtml + '&nbsp;' + params.value;
      }
    },
    {
      headerName: 'МО', field: 'regionCaption', width: 200,
    },
    {
      headerName: 'За период с', field: 'reportFrom', width: 140,
      valueFormatter: params => StringHelper.getRuDate(params.value),
    },
    {
      headerName: 'За период по', field: 'reportTo', width: 140,
      valueFormatter: params => StringHelper.getRuDate(params.value),
    },
    {
      headerName: 'Крайний срок', field: 'dateX', width: 140,
      valueFormatter: params => StringHelper.getRuDate(params.value),
    },
  ];

  public static getDefaultModel(fb?: FormBuilder, defaultFilterParams?: any) {
    return {
      searchResults: [],
      filterFormGroup: fb ? PstReportsListComponent.buildSearchForm(fb, defaultFilterParams) : undefined
    };
  }

  public static buildSearchForm(fb: FormBuilder, defaultFilterParams: any = {}) {

    return fb.group({
      year: defaultFilterParams.year,
      regionId: defaultFilterParams.regionId,
      reportTypeId: defaultFilterParams.reportTypeId,
      mode: defaultFilterParams.mode,
      agentId: defaultFilterParams.agentId,
    });
  }

  constructor(
    private globalWaitingOverlayService: GlobalWaitingOverlayService,
    public appNavigationService: AppNavigationService,
    private lookupSourceService: LookupSourceService,
    public securityService: SecurityService,
    public pstReportService: PstReportService,
    private dataCachingService: DataCachingService,
    private fb: FormBuilder,
    private router: Router,
    private navigationService: AppNavigationService,
  ) {
  }

  ngOnInit() {
    const existing = this.dataCachingService.getCachedData('PstReportsListComponent', '1');

    if (existing) {
      this.model = existing;
    } else {
      this.model = PstReportsListComponent.getDefaultModel(this.fb, {year: new Date().getFullYear(), mode: 1});
      this.refreshResults();
      this.dataCachingService.addToCache('PstReportsListComponent', '1', this.model);
    }
  }

  refreshResults() {
    if (!this.model.filterFormGroup.valid) {
      return;
    }

    this.globalWaitingOverlayService.StartWaiting();

    this.lookupSourceService.getLookupObj('addr-region').subscribe(regionLookup => {
      this.lookupSourceService.getLookupObj('pst-report-type').subscribe(reportTypesLookup => {
        this.pstReportService.getListReports(this.model.filterFormGroup.value)
          .subscribe({
            next: data => {
              this.model.searchResults = data;
              this.model.searchResults.forEach(el => {
                el.reportTypeCaption = reportTypesLookup[`Obj${el.reportTypeId}`].caption;
                el.groupCaption = reportTypesLookup[`Obj${el.reportTypeId}`].groupCaption;
                el.regionCaption = el.regionId ? regionLookup[`Obj${el.regionId}`].caption : '';
                el.dateX = StringHelper.getISODate(DateHelper.addDays(
                  new Date(el.reportTo),
                  (reportTypesLookup[`Obj${el.reportTypeId}`].daysDelay || 0) + 1,
                ));
              });
              this.globalWaitingOverlayService.EndWaiting();
            },
            error: () => {
              this.model.searchResults = [];
              this.globalWaitingOverlayService.EndWaiting();
            }
          });
      });
    });
  }

  filterEnterPressed($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      this.refreshResults();
    }
  }

  navigateReport(reportId: any) {
    this.router.navigate(['/pst-report-edit', reportId]);
  }

  getRowStyle(params) {
    if (!params.data) {
      return;
    }

    if (params.data.reportState === 10) {
      return {color: 'green'};
    }

    if (params.data.reportState === 1) {
      if (params.data.dateX > StringHelper.getISODate(new Date())) {
        // непросроченный
        return {color: 'silver'};
      } else {
        // просроченный
        return {color: 'red'};
      }
    }
  }

  searchFilterAgent() {
    this.navigationService.searchAgent(AgentSearchModalComponent).subscribe(agentId => {
      if (agentId) {
        this.model.filterFormGroup.controls['agentId'].setValue(agentId);
      }
    });
  }
}
