<div class="itech-content-header">
  <clr-icon shape="piggy-bank" size="36" class="has-badge"></clr-icon>&nbsp;Создание новой карточки животного
</div>

<app-edit-animal-common-form *ngIf="model" [contextFormGroup]="model.form">
</app-edit-animal-common-form>

<p>
  <button [clrLoading]="creating" [disabled]="creating" class="btn btn-primary"
          (click)="createCard()">
    <clr-icon shape="check"></clr-icon>&nbsp;Создать
  </button>
<!--  <clr-dropdown>-->
<!--    <button [clrLoading]="creating" [disabled]="creating" class="btn btn-primary" clrDropdownToggle>-->
<!--      <clr-icon shape="check"></clr-icon>&nbsp;Создать с производственным ВСД <clr-icon shape="caret down"></clr-icon>-->
<!--    </button>-->
<!--    <clr-dropdown-menu *clrIfOpen [clrPosition]="'top-left'">-->
<!--      <label class="dropdown-header">Выберите действие</label>-->
<!--      <button type="button" clrDropdownItem (click)="createCard(false, true, false)"-->
<!--              [clrLoading]="creating" [disabled]="creating">Без синхронизации</button>-->
<!--      <button type="button" clrDropdownItem (click)="createCard(false, true, true)"-->
<!--              [clrLoading]="creating" [disabled]="creating">С синхронизацией</button>-->
<!--    </clr-dropdown-menu>-->
<!--  </clr-dropdown>-->
  <button [clrLoading]="creating" [disabled]="creating" class="btn btn-outline-primary"
          (click)="createCard(true)">
    Создать и продолжить&nbsp;<clr-icon shape="play"></clr-icon>
  </button>
</p>
