import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { EsiaService } from './esia.service';
import { Observable } from 'rxjs';

@Injectable()
export class EsiaBearerInterceptor implements HttpInterceptor {

  constructor(private readonly esiaService: EsiaService) {
  }

  private handleWithBearer(token: string, authInstitutionId: number, req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const request = req.clone({setHeaders: {'Authorization': `Bearer ${token}`, 'AuthInstitutionId': `${authInstitutionId}`}, withCredentials: true});
    return next.handle(request);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.startsWith(environment.api) ||
      req.url.startsWith(`${environment.api}/esia/get-login-url`) ||
      req.url.startsWith(`${environment.api}/esia/get-logout-url`)) {
      return next.handle(req);
    }

    if (req.url.startsWith(`${environment.api}/esia/auth`)) {
      return next.handle(req.clone({withCredentials: true}));
    }

    const token = this.esiaService.getToken();
    const authInstitutionId = this.esiaService.getAuthInstitutionId();
    return this.handleWithBearer(token, authInstitutionId, req, next);
  }
}
