<clr-modal [(clrModalOpen)]="planningUi.editTicketModalOpened" *ngIf="planningUi.editTicketModalOpened"
           [clrModalClosable]="false" [clrModalSize]="'lg'">
  <h3 class="modal-title" style="display:flex">
    <span *ngIf="!ticketFormGroup.get('id').value && !isDistribute" class="itech-ticket-adding-badge">[Добавление] </span>
    <span *ngIf="!ticketFormGroup.get('id').value && isDistribute" class="itech-ticket-adding-badge">[Распределение] </span>
    <span *ngIf="isTemplate" class="itech-ticket-adding-badge">[Шаблон] </span>
    Талон посещения
    &nbsp;
    <app-link-button [lookupName]="'institution'" style="font-size: 12px;line-height:1;"
                   [id]="ticketFormGroup.get('institutionId').value"></app-link-button>
  </h3>
  <div class="modal-body" style="min-height: 500px" [formGroup]="ticketFormGroup">

    <app-doc-validation-errors [validationErrors]="modalValidationErrorsHost.serverSideValidationErrors">
    </app-doc-validation-errors>

    <div class="form compact" [formGroup]="ticketFormGroup">
      <section class="form-block itech-block-medium">
        <h5>Общая информация</h5>
        <div class="form-container">
          <ng-container *ngIf="!isTemplate">
            <div class="form-group itech-group-whole-line" *ngIf="ticketFormGroup.get('createdTime').value">
              <label></label>
              <div class="itech-control-desc">
                Талон создан:
                {{ticketFormGroup.get('createdTime').value | rtime}},
                {{ticketFormGroup.get('createdUser').value | lookup: 'user' | async}}
              </div>
            </div>
            <div class="form-group itech-group-whole-line" *ngIf="ticketFormGroup.get('cancelTime').value">
              <label></label>
              <div class="itech-control-desc itech-desc-error">
                Талон отменён:
                {{ticketFormGroup.get('cancelTime').value | rtime}},
                {{ticketFormGroup.get('cancelUser').value | lookup: 'user' | async}}
              </div>
            </div>
            <div class="form-group itech-group-whole-line" *ngIf="ticketFormGroup.get('dateSuccessful').value">
              <label></label>
              <div class="itech-control-desc">
                Приём подтверждён:
                {{ticketFormGroup.get('dateSuccessful').value | rtime}},
                {{ticketFormGroup.get('userSuccessful').value | lookup: 'user' | async}}
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="isTemplate">
            <div class="form-group">
              <label for="templateCaption" class="required">
                Наименование
              </label>
              <label for="templateCaption" aria-haspopup="true" role="tooltip"
                     class="tooltip tooltip-validation tooltip-md"
                     [class.invalid]="isInvalid(ticketFormGroup, 'templateCaption')">
                <input type="text" id="templateCaption" placeholder="Наименование шаблона" class="itech-control-xlarge"
                       formControlName="templateCaption">
                <app-validation-tooltip [input]="ticketFormGroup.controls['templateCaption']"></app-validation-tooltip>
              </label>
            </div>
          </ng-container>

          <div class="form-group">
            <label for="institutionId" [class.required]="!isTemplate">Филиал</label>
            <app-combo-lookup *ngIf="ticketFormGroup.get('institutionId').value"
                              [validationTooltipRight]="true" class="itech-control-medium"
                              [contextControlId]="'institutionBranchId'" [sorted]="true"
                              [contextControlName]="'institutionBranchId'" [disabled]="readonly"
                              [lookupName]="'institution-branch' + ticketFormGroup.get('institutionId').value"
                              [contextFormGroup]="ticketFormGroup"></app-combo-lookup>
            &nbsp;&nbsp;
            <span [class.required]="!isTemplate">кабинет</span>
            <label for="cabinetNumber" aria-haspopup="true" role="tooltip"
                   class="tooltip tooltip-validation tooltip-md"
                   [class.invalid]="isInvalid(ticketFormGroup, 'cabinetNumber')">
              <input type="text" id="cabinetNumber" placeholder="Номер кабинета" class="itech-control-normal"
                     formControlName="cabinetNumber" maxlength="100" [attr.disabled]="readonly ? true : undefined">
              <app-validation-tooltip [input]="ticketFormGroup.controls['cabinetNumber']"></app-validation-tooltip>
            </label>
          </div>

          <div class="form-group" *ngIf="ticketFormGroup.get('institutionId').value">
            <label for="institutionEmployeeId" [class.required]="!isTemplate">Работник</label>
            <app-combo-lookup [validationTooltipRight]="true" class="itech-control-medium"
                              [contextControlId]="'institutionEmployeeId'" [sorted]="true"
                              [contextControlName]="'institutionEmployeeId'" [disabled]="readonly"
                              [lookupName]="'institution-employee' + ticketFormGroup.get('institutionId').value"
                              [contextFormGroup]="ticketFormGroup"></app-combo-lookup>
          </div>

          <div class="form-group">
            <label for="animalTypeId">Вид/подвид животного</label>
            <app-text-lookup [validationTooltipRight]="true" class="itech-control-medium"
                             [contextControlId]="'animalTypeId'" [sorted]="true"
                             [contextControlName]="'animalTypeId'" [disabled]="readonly"
                             [lookupName]="'animal-type/available-external'"
                             (change)="ticketFormGroup.get('animalSubtypeId').setValue(null)"
                             [contextFormGroup]="ticketFormGroup"></app-text-lookup>
            &nbsp;
            <app-text-lookup *ngIf="ticketFormGroup.get('animalTypeId').value"
                             [validationTooltipRight]="true" class="itech-control-medium"
                             [contextControlId]="'animalSubtypeId'" [sorted]="true"
                             [contextControlName]="'animalSubtypeId'"
                             [filterArrayIncludeControlName]="'availableForExternal'"
                             [filterArrayIncludeValue]="[true]" [disabled]="readonly"
                             [lookupName]="'animal-subtype' + ticketFormGroup.get('animalTypeId').value"
                             [contextFormGroup]="ticketFormGroup"></app-text-lookup>
          </div>

          <ng-container *ngIf="ticketFormGroup && (ticketFormGroup.get('selectedInstitutionTicketQuestIds').value || []).length; else notSelectedQuests">
            <app-combo-multilookup class="itech-control-x-large" [itemTitle]="'Вопросы приёма (клиент)'" [flexMode]="true" style="display:flex"
                                   [contextControlName]="'selectedInstitutionTicketQuestIds'" [lookupName]="'institution-ticket-quest'"
                                   [contextFormGroup]="ticketFormGroup" [classControl]="'itech-control-medium'" [disabled]="true">
            </app-combo-multilookup>
          </ng-container>
          <ng-template #notSelectedQuests>
            <app-combo-multilookup class="itech-control-x-large" [itemTitle]="'Вопросы приёма'" [flexMode]="true" style="display:flex"
                                   [contextControlName]="'institutionTicketQuestIds'" [lookupName]="'institution-ticket-quest'"
                                   [contextFormGroup]="ticketFormGroup" [classControl]="'itech-control-medium'" [disabled]="readonly">
            </app-combo-multilookup>
          </ng-template>

          <div class="form-group">
            <label for="comment">Комментарий</label>
            <label for="comment" aria-haspopup="true" role="tooltip"
                   class="tooltip tooltip-validation tooltip-md"
                   [class.invalid]="isInvalid(ticketFormGroup, 'comment')">
              <textarea type="text" id="comment" placeholder="Комментарий" maxlength="256" class="itech-control-xlarge"
                        formControlName="comment" style="height:30px;resize:none;font-size:10px;line-height: 1;" [attr.disabled]="readonly ? true : undefined">
              </textarea>
            </label>
          </div>
        </div>
      </section>
      <section class="form-block itech-block-medium">
        <h5>Параметры {{isDistribute || isTemplate ? 'распределения' : 'приёма'}}</h5>
        <div class="form-container">
          <div class="form-group itech-group-whole-line" *ngIf="!readonly && (isTemplate || isDistribute)">
            <div class="checkbox-inline" style="margin:0">
              <input type="checkbox" id="isDeleteInsteadAdding" formControlName="isDeleteInsteadAdding">
              <label for="isDeleteInsteadAdding">Удалить вместо добавления</label>
            </div>
          </div>

          <div class="form-group">
            <label for="durationMinutes" [class.required]="!isTemplate">
              Продолжительность {{isTemplate ? 'одного' : '' }} приёма, минут
            </label>
            <label for="durationMinutes" aria-haspopup="true" role="tooltip"
                   class="tooltip tooltip-validation tooltip-md"
                   [class.invalid]="isInvalid(ticketFormGroup, 'durationMinutes')">
              <input type="text" id="durationMinutes" placeholder="Кол-во" class="itech-control-small"
                     formControlName="durationMinutes" (keypress)="processMoneyKeypress($event)" [attr.disabled]="readonly ? true : undefined">
              <app-validation-tooltip [input]="ticketFormGroup.controls['durationMinutes']"></app-validation-tooltip>
            </label>
          </div>

          <ng-container *ngIf="!isTemplate && !isDistribute; else templateDuration">

            <div class="form-group">
              <label for="startTime" class="required">Время начала приёма</label>
              <app-datetime-picker [contextControlName]="'startTime'" [contextFormGroup]="ticketFormGroup"
                                   id="startTime" [disabled]="readonly"></app-datetime-picker>
            </div>

            <div class="form-group">
              <label for="unavailableTime" class="required">Время окончания доступности</label>
              <app-datetime-picker [contextControlName]="'unavailableTime'" [contextFormGroup]="ticketFormGroup"
                                   id="unavailableTime" [disabled]="readonly"></app-datetime-picker>
            </div>
          </ng-container>
          <ng-template #templateDuration>
            <div class="form-group">
              <label for="durationTypeId" [class.required]="isDistribute">Продолжительность распределения</label>
              <div class="radio-inline">
                <input type="radio" id="durationTypeId0" formControlName="durationTypeId" [value]="null">
                <label for="durationTypeId0">Не выбрано</label>
              </div>
              <div class="radio-inline">
                <input type="radio" id="durationTypeId1" formControlName="durationTypeId" [value]="1">
                <label for="durationTypeId1">Текущая неделя</label>
              </div>
              <div class="radio-inline">
                <input type="radio" id="durationTypeId2" formControlName="durationTypeId" [value]="2">
                <label for="durationTypeId2">Следующая неделя</label>
              </div>
              <div class="radio-inline">
                <input type="radio" id="durationTypeId3" formControlName="durationTypeId" [value]="3">
                <label for="durationTypeId3">Тек. и след. недели</label>
              </div>
            </div>
            <div class="form-group">
              <label [class.required]="isDistribute">Дни недели</label>
              <app-checkbox-select class="itech-control-x-large" [useShort]="true" [compact]="true"
                                   [contextControlName]="'daysOfWeek'" [lookupName]="'week-days'"
                                   [contextFormGroup]="ticketFormGroup"></app-checkbox-select>
            </div>
            <div class="form-group">
              <label for="periodicityTypeId" [class.required]="isDistribute">Периодичность распределения</label>
              <app-combo-lookup [validationTooltipRight]="true" class="itech-control-xlarge"
                                [contextControlId]="'periodicityTypeId'"
                                [contextControlName]="'periodicityTypeId'"
                                [lookupName]="'institution-ticket-periodicity-type'"
                                [contextFormGroup]="ticketFormGroup"></app-combo-lookup>
            </div>
            <div class="form-group">
              <label for="inclusiveTimeDayStart" [class.required]="isDistribute">Вкл.время с/по</label>
              <label for="inclusiveTimeDayStart" aria-haspopup="true" role="tooltip"
                     class="tooltip tooltip-validation tooltip-md tooltip-right"
                     [class.invalid]="isInvalid(ticketFormGroup, 'inclusiveTimeDayStart')">
                <input type="time" id="inclusiveTimeDayStart" formControlName="inclusiveTimeDayStart">
                <app-validation-tooltip [input]="ticketFormGroup.controls['inclusiveTimeDayStart']"></app-validation-tooltip>
              </label>
              &nbsp;
              <label for="inclusiveTimeDayEnd" aria-haspopup="true" role="tooltip"
                     class="tooltip tooltip-validation tooltip-md tooltip-right"
                     [class.invalid]="isInvalid(ticketFormGroup, 'inclusiveTimeDayEnd')">
                <input type="time" id="inclusiveTimeDayEnd" formControlName="inclusiveTimeDayEnd">
                <app-validation-tooltip [input]="ticketFormGroup.controls['inclusiveTimeDayEnd']"></app-validation-tooltip>
              </label>
              &nbsp;
              <span>Искл.время с/по</span>
              <label for="exclusiveTimeDayStart" aria-haspopup="true" role="tooltip"
                     class="tooltip tooltip-validation tooltip-md tooltip-right"
                     [class.invalid]="isInvalid(ticketFormGroup, 'exclusiveTimeDayStart')">
                <input type="time" id="exclusiveTimeDayStart" formControlName="exclusiveTimeDayStart">
                <app-validation-tooltip [input]="ticketFormGroup.controls['exclusiveTimeDayStart']"></app-validation-tooltip>
              </label>
              &nbsp;
              <label for="exclusiveTimeDayEnd" aria-haspopup="true" role="tooltip"
                     class="tooltip tooltip-validation tooltip-md tooltip-right"
                     [class.invalid]="isInvalid(ticketFormGroup, 'exclusiveTimeDayEnd')">
                <input type="time" id="exclusiveTimeDayEnd" formControlName="exclusiveTimeDayEnd">
                <app-validation-tooltip [input]="ticketFormGroup.controls['exclusiveTimeDayEnd']"></app-validation-tooltip>
              </label>
            </div>
            <div class="form-group" style="margin-bottom: 0.5rem">
              <label for="unavailableHour" [class.required]="isDistribute">
                Запретить резервирование за
              </label>
              <label for="unavailableHour" aria-haspopup="true" role="tooltip"
                     class="tooltip tooltip-validation tooltip-md"
                     [class.invalid]="isInvalid(ticketFormGroup, 'unavailableHour')">
                <input type="text" id="unavailableHour" placeholder="Кол-во часов" class="itech-control-small"
                       formControlName="unavailableHour" (keypress)="processMoneyKeypress($event)" [attr.disabled]="readonly ? true : undefined">
                часов
                <app-validation-tooltip [input]="ticketFormGroup.controls['unavailableHour']"></app-validation-tooltip>
              </label>
            </div>
          </ng-template>
        </div>
      </section>

      <section class="form-block itech-block-medium" *ngIf="!isTemplate">
        <h5>Резервирование</h5>
        <div class="form-container">
          <div class="form-group itech-group-whole-line">
            <label></label>
            <div class="itech-control-desc" *ngIf="ticketFormGroup.get('reservationTime').value">
              Талон зарезервирован:
              {{ticketFormGroup.get('reservationTime').value | rtime}},
              {{ticketFormGroup.get('reservationUser').value | lookup: 'user' | async}}
            </div>
          </div>
          <div class="form-group">
            <label>Владелец</label>
            <div style="display: flex">
              <app-link-button class="app-link-button-auto-width" [id]="ticketFormGroup.controls['agentId'].value"
                               [lookupName]="'agent'" (click)="planningUi.closeEditModal()"></app-link-button>
              &nbsp;&nbsp;
              <button *ngIf="!readonly && !ticketFormGroup.controls['agentId'].value"
                      type="button" class="btn btn-danger btn-link itech-inline-tool-btn" title="Выбрать хозяйствующий субъект"
                      (click)="searchAgent()">
                <clr-icon shape="search"></clr-icon>
              </button>
              <button *ngIf="!readonly && ticketFormGroup.controls['agentId'].value"
                      type="button" class="btn btn-danger btn-link itech-inline-tool-btn" title="Очистить поле"
                      (click)="removeAgent()">
                <clr-icon shape="remove"></clr-icon>
              </button>
            </div>
          </div>
          <div class="form-group"
               *ngIf="ticketFormGroup.controls['agentId'].value">
            <label>Животное</label>
            <app-link-button [id]="ticketFormGroup.controls['animalId'].value"
                             [lookupName]="!ticketFormGroup.controls['isAnimalExternal'].value ? 'animal' : 'animal-external'"
                             (click)="planningUi.closeEditModal()"></app-link-button>
            <button *ngIf="!readonly && !ticketFormGroup.controls['animalId'].value" type="button" (click)="searchAnimal()"
                    class="btn btn-danger btn-link itech-inline-tool-btn" title="Выбрать животное">
              <clr-icon shape="search"></clr-icon>
            </button>
            <button *ngIf="!readonly && ticketFormGroup.controls['animalId'].value"
                    type="button" class="btn btn-danger btn-link itech-inline-tool-btn" title="Очистить поле"
                    (click)="removeAnimal()">
              <clr-icon shape="remove"></clr-icon>
            </button>
          </div>
        </div>
      </section>
    </div>
  </div>
  <div class="modal-footer itech-form-actions">
    <div>
      <button *ngIf="planningUi.editedTicket.data.id && !planningUi.editedTicket.data.cancelTime" type="button" class="btn btn-warning-outline"
              (click)="deleteTicket()"
              [disabled]="planningUi.deleting || planningUi.storing? true: undefined" [clrLoading]="planningUi.deleting">
        <clr-icon shape="trash"></clr-icon>&nbsp;{{ticketFormGroup.get('reservationTime').value ? 'Отменить запись' : 'Удалить'}}
      </button>
    </div>
    <div>
      <button type="button" class="btn btn-primary" *ngIf="!readonly && isDistribute" (click)="distributeTickets()"
              [disabled]="planningUi.deleting || planningUi.storing? true: undefined" [clrLoading]="planningUi.storing">
        <clr-icon shape="check"></clr-icon>&nbsp;Распределить талоны
      </button>
      <button type="button" class="btn btn-primary" *ngIf="!readonly && !isDistribute" (click)="addEditTicketCompleted()"
              [disabled]="planningUi.deleting || planningUi.storing? true: undefined" [clrLoading]="planningUi.storing">
        <clr-icon shape="check"></clr-icon>&nbsp;Сохранить {{isTemplate ? 'шаблон' : 'талон'}}
      </button>
      <button type="button" class="btn btn-primary" *ngIf="readonly && !isDistribute && !isTemplate" (click)="markSuccessful()"
              [disabled]="planningUi.deleting || planningUi.storing ||
                          planningUi.editedTicket.data.dateSuccessful || planningUi.editedTicket.data.cancelTime ||
                          isAfterCurrentDate(planningUi.editedTicket.data.startTime)
                ? true
                : undefined"
              [clrLoading]="planningUi.storing">
        <clr-icon shape="check"></clr-icon>&nbsp;Подтвердить приём
      </button>
      <button type="button" class="btn btn-warning" (click)="planningUi.closeEditModal()"
              [disabled]="planningUi.deleting || planningUi.storing? true: undefined">
        <clr-icon shape="undo"></clr-icon>&nbsp;{{readonly ? 'Закрыть' : 'Отмена'}}
      </button>
    </div>
  </div>
</clr-modal>
