/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../logic/pipes/lookup-source.pipe";
import * as i2 from "../../../logic/services/lookup-source.service";
import * as i3 from "../../../logic/services/address-person-fio-cache.service";
import * as i4 from "@angular/platform-browser";
import * as i5 from "../../../ui/controls/app-grid.component.ngfactory";
import * as i6 from "../../../ui/controls/app-grid.component";
import * as i7 from "../../../logic/services/data-caching.service";
import * as i8 from "../../../logic/services/security.service";
import * as i9 from "@angular/common";
import * as i10 from "./view-quarantine-animalstable-events-search-results.component";
import * as i11 from "../../../logic/services/quarantine-data.service";
import * as i12 from "../../../logic/services/app-navigation.service";
var styles_ViewQuarantineAnimalstableEventsSearchResultsComponent = [];
var RenderType_ViewQuarantineAnimalstableEventsSearchResultsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ViewQuarantineAnimalstableEventsSearchResultsComponent, data: {} });
export { RenderType_ViewQuarantineAnimalstableEventsSearchResultsComponent as RenderType_ViewQuarantineAnimalstableEventsSearchResultsComponent };
export function View_ViewQuarantineAnimalstableEventsSearchResultsComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.LookupSourcePipe, [i2.LookupSourceService, i3.AddressPersonFioCacheService, i4.DomSanitizer]), (_l()(), i0.ɵeld(1, 0, null, null, 3, "app-grid", [], null, [[null, "rowClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("rowClicked" === en)) {
        var pd_0 = (_co.navigationService.performAnimalEventEditing($event.data.eventId) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_AppGridComponent_0, i5.RenderType_AppGridComponent)), i0.ɵdid(2, 114688, null, 0, i6.AppGridComponent, [i7.DataCachingService, i8.SecurityService], { uniqueGridName: [0, "uniqueGridName"], columnDefs: [1, "columnDefs"], rowData: [2, "rowData"], getRowStyle: [3, "getRowStyle"], pagination: [4, "pagination"], exportFileName: [5, "exportFileName"] }, { rowClicked: "rowClicked" }), i0.ɵppd(3, 2), i0.ɵpid(131072, i9.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "ViewQuarantineAnimalStableEventsSearchResultsGrid"; var currVal_1 = _co.gridColumnDefs; var currVal_2 = _co.rowData; var currVal_3 = _co.getRowStyle; var currVal_4 = false; var currVal_5 = ("\u041C\u0435\u0440\u043E\u043F\u0440\u0438\u044F\u0442\u0438\u044F \u043A\u0430\u0440\u0430\u043D\u0442\u0438\u043D\u0430 " + i0.ɵunv(_v, 2, 5, i0.ɵnov(_v, 4).transform(i0.ɵunv(_v, 2, 5, _ck(_v, 3, 0, i0.ɵnov(_v, 0), _co.quarantineId, "quarantine"))))); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_ViewQuarantineAnimalstableEventsSearchResultsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-view-quarantine-animalstable-events-search-results", [], null, null, null, View_ViewQuarantineAnimalstableEventsSearchResultsComponent_0, RenderType_ViewQuarantineAnimalstableEventsSearchResultsComponent)), i0.ɵdid(1, 573440, null, 0, i10.ViewQuarantineAnimalstableEventsSearchResultsComponent, [i11.QuarantineDataService, i12.AppNavigationService, i2.LookupSourceService], null, null)], null, null); }
var ViewQuarantineAnimalstableEventsSearchResultsComponentNgFactory = i0.ɵccf("app-view-quarantine-animalstable-events-search-results", i10.ViewQuarantineAnimalstableEventsSearchResultsComponent, View_ViewQuarantineAnimalstableEventsSearchResultsComponent_Host_0, { quarantineId: "quarantineId" }, {}, []);
export { ViewQuarantineAnimalstableEventsSearchResultsComponentNgFactory as ViewQuarantineAnimalstableEventsSearchResultsComponentNgFactory };
