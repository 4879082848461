
      <button type="button" class="btn btn-link" *ngIf="params" (click)="clicked()" [title]="params.title" [disabled]="params.disabled">
          <clr-icon *ngIf="params.icon === 'plus'" shape="plus"></clr-icon>
          <clr-icon *ngIf="params.icon === 'copy'" shape="copy"></clr-icon>
          <clr-icon *ngIf="params.icon === 'trash'" style="color:#d23b3b" shape="trash"></clr-icon>
          <clr-icon *ngIf="params.icon === 'angle left'" shape="angle left"></clr-icon>
          <clr-icon *ngIf="params.icon === 'angle down'" shape="angle down"></clr-icon>
          <clr-icon *ngIf="params.icon === 'times'" style="color:#d23b3b" shape="times"></clr-icon>
          {{params.buttonCaption}}
      </button>
  