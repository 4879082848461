import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular/dist/interfaces';

@Component({
  selector: 'app-journal-detail-cell-renderer',
  template: `
    <ng-container *ngIf="params.type === 1">
      <ng-container *ngIf="+params.data.objKindId === 1">Учреждение: {{params.data.objId1|lookup:'institution':true|async}}</ng-container>
      <ng-container *ngIf="+params.data.objKindId === 2">Филиал учреж.: {{params.data.objId1|lookup:'institution':true|async}} [{{params.data.objId1}}, params.data.objId2]</ng-container>
      <ng-container *ngIf="+params.data.objKindId === 3">Работник учреж.: {{params.data.objId1|lookup:'institution':true|async}}</ng-container>
      <ng-container *ngIf="+params.data.objKindId === 4">Роль: {{params.data.objId1|lookup:'security-role':true|async}} [{{params.data.objId1}}]</ng-container>
      <ng-container *ngIf="+params.data.objKindId === 5">Хозсубъект: {{params.data.objId1|lookup:'agent':true|async}} [{{params.data.objId1}}]</ng-container>
      <ng-container *ngIf="+params.data.objKindId === 6">Хозсубъект: {{params.data.objId1|lookup:'stable':true|async}} [{{params.data.objId1}}]</ng-container>
      <ng-container *ngIf="+params.data.objKindId === 7">Животное: {{params.data.objId1|lookup:'animal':true|async}} [{{params.data.objId1}}]</ng-container>
      <ng-container *ngIf="+params.data.objKindId === 8">Препарат: {{params.data.objId1|lookup:'drug':true|async}} [{{params.data.objId1}}]</ng-container>
      <ng-container *ngIf="+params.data.objKindId === 9">Мероприятие: {{params.data.objId1|lookup:'event':true|async}} [{{params.data.objId2}}]</ng-container>
      <ng-container *ngIf="+params.data.objKindId === 10">Продукция: {{params.data.objId1|lookup:'product':true|async}} [{{params.data.objId1}}]</ng-container>
      <ng-container *ngIf="+params.data.objKindId === 11">Экспертиза прод.: {{params.data.objId1|lookup:'product':true|async}} [{{params.data.objId2}}]</ng-container>
      <ng-container *ngIf="+params.data.objKindId === 20">Выборка: [{{params.data.objId1}}]</ng-container>
      <ng-container *ngIf="+params.data.objKindId === 21">{{params.data.objId1|lookup:'quarantine':true|async}}</ng-container>
      <ng-container *ngIf="+params.data.objKindId === 22">Мероприятие карантина: {{params.data.objId1|lookup:'quarantine':true|async}} [{{params.data.objId2}}]</ng-container>
    </ng-container>
    <ng-container *ngIf="params.type === 2">
        {{params.data.changeType|lookup:'journal-change-type'|async}}
    </ng-container>
    <ng-container *ngIf="params.type === 3">
        {{params.data.institutionId|lookup:'institution':true|async}}
    </ng-container>
    <ng-container *ngIf="params.type === 4">
        {{params.data.userId|lookup:'user'|async}}
    </ng-container>
  `
})
export class JournalLookupCellRendererComponent implements ICellRendererAngularComp {

  public static TYPE_OBJ_CAPTION = 1;
  public static TYPE_CHANGE_TYPE = 2;
  public static TYPE_INSTITUTION_CAPTION = 3;
  public static TYPE_USER_CAPTION = 4;

  params: any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    this.params = params;
    return false;
  }
}
