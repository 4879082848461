/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../logic/pipes/lookup-source.pipe";
import * as i2 from "../../../logic/services/lookup-source.service";
import * as i3 from "../../../logic/services/address-person-fio-cache.service";
import * as i4 from "@angular/platform-browser";
import * as i5 from "../../../ui/controls/app-grid.component.ngfactory";
import * as i6 from "../../../ui/controls/app-grid.component";
import * as i7 from "../../../logic/services/data-caching.service";
import * as i8 from "../../../logic/services/security.service";
import * as i9 from "@angular/common";
import * as i10 from "@clr/angular";
import * as i11 from "./edit-stable-events.component";
import * as i12 from "../../../logic/services/stable-data.service";
import * as i13 from "../../../ui/infrastructure/global-waiting-overlay.service";
import * as i14 from "../../../logic/services/app-navigation.service";
var styles_EditStableEventsComponent = [];
var RenderType_EditStableEventsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EditStableEventsComponent, data: {} });
export { RenderType_EditStableEventsComponent as RenderType_EditStableEventsComponent };
export function View_EditStableEventsComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.LookupSourcePipe, [i2.LookupSourceService, i3.AddressPersonFioCacheService, i4.DomSanitizer]), (_l()(), i0.ɵeld(1, 0, null, null, 19, "div", [["class", "itech-common-wrap"], ["id", "interceptNoAccess"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["style", "width: 100%"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 3, "app-grid", [], null, [[null, "rowClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("rowClicked" === en)) {
        var pd_0 = (_co.navigationService.performAnimalEventEditing($event.data.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_AppGridComponent_0, i5.RenderType_AppGridComponent)), i0.ɵdid(4, 114688, null, 0, i6.AppGridComponent, [i7.DataCachingService, i8.SecurityService], { uniqueGridName: [0, "uniqueGridName"], columnDefs: [1, "columnDefs"], rowData: [2, "rowData"], getRowStyle: [3, "getRowStyle"], pagination: [4, "pagination"], exportFileName: [5, "exportFileName"] }, { rowClicked: "rowClicked" }), i0.ɵppd(5, 2), i0.ɵpid(131072, i9.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(7, 0, null, null, 13, "div", [["class", "itech-form-actions"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 3, "button", [["class", "btn btn-secondary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.refreshFormData() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "clr-icon", [["shape", "refresh"]], null, null, null, null, null)), i0.ɵdid(11, 16384, null, 0, i10.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, ["\u00A0\u041E\u0431\u043D\u043E\u0432\u0438\u0442\u044C "])), (_l()(), i0.ɵeld(13, 0, null, null, 3, "button", [["class", "btn btn-primary-outline"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigationService.navigateJournal(undefined, 13, _co.id, undefined) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 1, "clr-icon", [["shape", "view-list"]], null, null, null, null, null)), i0.ɵdid(15, 16384, null, 0, i10.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, ["\u00A0\u0416\u0443\u0440\u043D\u0430\u043B "])), (_l()(), i0.ɵeld(17, 0, null, null, 3, "button", [["class", "btn btn-success-outline"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addNew() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 1, "clr-icon", [["shape", "plus"]], null, null, null, null, null)), i0.ɵdid(19, 16384, null, 0, i10.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, ["\u00A0\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "EditStableEventsGrid"; var currVal_1 = _co.gridColumnDefs; var currVal_2 = _co.model.searchResults; var currVal_3 = _co.getRowStyle; var currVal_4 = false; var currVal_5 = ("\u0421\u043F\u0438\u0441\u043E\u043A \u043C\u0435\u0440\u043E\u043F\u0440\u0438\u044F\u0442\u0438\u0439 \u0443 \u041F\u041E " + i0.ɵunv(_v, 4, 5, i0.ɵnov(_v, 6).transform(i0.ɵunv(_v, 4, 5, _ck(_v, 5, 0, i0.ɵnov(_v, 0), _co.id, "stable"))))); _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_EditStableEventsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-edit-stable-events", [], null, null, null, View_EditStableEventsComponent_0, RenderType_EditStableEventsComponent)), i0.ɵdid(1, 49152, null, 0, i11.EditStableEventsComponent, [i12.StableDataService, i7.DataCachingService, i13.GlobalWaitingOverlayService, i2.LookupSourceService, i14.AppNavigationService], null, null)], null, null); }
var EditStableEventsComponentNgFactory = i0.ɵccf("app-edit-stable-events", i11.EditStableEventsComponent, View_EditStableEventsComponent_Host_0, { model: "model", id: "id" }, {}, []);
export { EditStableEventsComponentNgFactory as EditStableEventsComponentNgFactory };
