import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { MetadataService } from '../../../logic/services/metadata.service';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MetaBrowseBaseComponent } from '../meta-browse.base.component';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { JobRunnerUiService } from '../../../ui/infrastructure/job-runner-ui.service';
import { StringHelper } from '../../../helpers/string-helper';
import { FormHelper } from '../../../ui/controls/form-helper';
import { DataCachingService } from '../../../logic/services/data-caching.service';

@Component({
  templateUrl: './animal-subtype-browse.component.html'
})
export class AnimalSubtypeBrowseComponent extends MetaBrowseBaseComponent {

  gridColumnDefs = [
    {headerName: '№', field: 'id', width: 100},
    {headerName: 'Тип', field: 'animalTypeCaption', tooltipField: 'animalTypeCaption', width: 200},
    {headerName: 'Наименование', field: 'caption', tooltipField: 'caption', width: 350},
    {headerName: 'Pin', field: 'pin', tooltipField: 'pin', width: 60},
    {headerName: 'Guid (Меркурий)', field: 'guid', tooltipField: 'guid', width: 260},
    {
      headerName: 'Удалено', field: 'dateDeleted', width: 100,
      valueFormatter: params => StringHelper.getRuTimeWithSeconds(params.value),
    },
  ];

  constructor(route: ActivatedRoute,
              metadataService: MetadataService,
              alertService: AlertService,
              globalWaitingOverlayService: GlobalWaitingOverlayService,
              lookupService: LookupSourceService,
              bookmarkService: BookmarkService,
              fb: FormBuilder,
              private jobRunnerUiService: JobRunnerUiService,
              dataCachingService: DataCachingService) {
    super(route, metadataService, alertService, globalWaitingOverlayService, lookupService, fb, bookmarkService, dataCachingService);
  }

  getMetaTitle(): string {
    return 'Подвиды животных';
  }

  getMetaName(): string {
    return 'animal-subtype';
  }

  getId(formGroup: FormGroup): any {
    return formGroup.get('animalTypeId').value && formGroup.get('id').value
      ? formGroup.get('animalTypeId').value + '/' + formGroup.get('id').value
      : undefined;
  }

  refreshResults() {
    this.globalWaitingOverlayService.StartWaiting();

    this.lookupService.getLookupObj('animal-type').subscribe(animalTypeLookup => {
      this.metadataService.getMetadata(this.getMetaName()).subscribe({
        next: data => {

          data.forEach(el => {
            el.animalTypeCaption = animalTypeLookup[el.animalTypeId] || '<<Неизвестный вид>>';
          });

          this._searchResults = data;
          this.lookupService.invalidateLookup(this.getMetaName());
          this.dataCachingService.addToCache(this.getMetaName() + '_Data', '1', data);
          this.globalWaitingOverlayService.EndWaiting();
        }, error: () => {
          this.globalWaitingOverlayService.EndWaiting();
        }
      });
    });
  }

  getGroupDef(row: any) {
    return {
      id: [row.id, Validators.compose([Validators.required, Validators.pattern(/^\d{1,5}$/)])],
      animalTypeId: [row.animalTypeId, Validators.compose([Validators.required])],
      caption: [row.caption, Validators.required],
      guid: row.guid,
      pin: row.pin,
      nomenclatureAnimalPinId: row.nomenclatureAnimalPinId,
      eventsPlanning: (row.eventsPlanning && row.eventsPlanning.length
        ? this.fb.array(row.eventsPlanning.map((el, ix) => this.fb.group(this.buildGroupDefEventPlanning(el || {})))
          .sort((a, b) =>
            (a.get('diseaseTypeId').value - b.get('diseaseTypeId').value) + (a.get('eventTypeId').value - b.get('eventTypeId').value)))
        : this.fb.array([])),
      isCustom: row.isCustom || false,
      onlyIndividualAccounting: row.onlyIndividualAccounting || false,
      availableForExternal: row.availableForExternal || false,
      captionForExternal: [row.captionForExternal, FormHelper.conditionalValidate(Validators.required,
          ctrl => ctrl && ctrl.parent && ctrl.parent.get('availableForExternal').value)],
    };
  }

  buildGroupDefEventPlanning(val: any) {
    return {
      animalTypeId: val.animalTypeId,
      animalSubtypeId: val.animalSubtypeId,
      ownerTypeId: [val.ownerTypeId, Validators.required],
      diseaseTypeId: [val.diseaseTypeId, Validators.required],
      eventTypeId: [val.eventTypeId, Validators.required],
      diagnosticTypeIds: this.fb.array((val.diagnosticTypeIds || []).map(el => this.fb.control(el))),
      testMaterialId: val.testMaterialId,
      periodicityDay: [val.periodicityDay, Validators.compose([Validators.required, Validators.pattern(/^\d{1,4}$/), Validators.min(0)])],
      dateFrom: [val.dateFrom, Validators.compose([Validators.required, FormHelper.validateDateTimePicker()])]
    };
  }
  runUpdateAnimalTypesOp() {
    setTimeout(() => {
      this.jobRunnerUiService.runOperation(8 /* "Обновление справочника животных из Меркурия"*/,
        {
          type: 'animalTypeSync'
        }, undefined, undefined, false)
        .subscribe(() => {
          this.alertService.success('Обновление справочника животных из Меркурия успешно завершено');
          this.refreshResults();
        });
    }, 1);
  }

  getRowStyle(params: any) {
    if (params.data && params.data.dateDeleted) {
      return {color: 'silver'};
    }
  }

  getEventsPlanning() {
    return this.currentFormGroup.get('eventsPlanning') as FormArray;
  }

  addNewEventPlanning() {
    this.getEventsPlanning().push(this.fb.group(this.buildGroupDefEventPlanning({})));
  }

  getFormGroup(data: any) {
    return data as FormGroup;
  }

  runFirstEventPlanningOp() {
    this.jobRunnerUiService.runOperation(14 /* "Планирование первоначальных мероприятий"*/,
      {
        type: 'firstEventPlanning',
        animalTypeId: this.currentFormGroup.get('animalTypeId').value,
        animalSubtypeId: this.currentFormGroup.get('id').value
      }, undefined, undefined, false)
      .subscribe({next: () => {}});
  }
}
