import { Component, OnInit } from '@angular/core';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { SecurityService } from '../../../logic/services/security.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  constructor(public bookmarkService: BookmarkService,
              public securityService: SecurityService) {

  }

  ngOnInit() {
  }

}
