import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { MetadataService } from '../../../logic/services/metadata.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { map } from 'rxjs/internal/operators';

@Component({
  templateUrl: './quarantine-template-browse.component.html',
  styleUrls: ['./quarantine-template-browse.component.css']
})
export class QuarantineTemplateBrowseComponent implements OnInit {

  initialTemplateId: number;
  templates: any[] = [];
  selectedTemplate: any;
  contextFormGroup: FormGroup;
  serverSideValidationErrors: any[];
  storing = false;

  isInvalid = FormHelper.isInvalid;

  private static getEventGroupDef(val: any = {}, fb: FormBuilder, eventTypeLookup: any) {

    const files = (val.files || []).slice();
    files.push({});

    return {
      templateId: val.templateId,
      id: val.id,
      eventTypeId: [val.eventTypeId, Validators.required],
      orderNo: val.orderNo,
      responsibleTypeId: fb.array((val.responsibleTypeId || []).map(el => fb.control(el))),
      periodTypeId: val.periodTypeId,
      periodTypeOther: val.periodTypeOther,
      __editing: !!val.__editing,
      __hovered: !!val.__hovered,
      __branchId: eventTypeLookup ? (eventTypeLookup['Obj' + val.eventTypeId] || {}).branchId : undefined
    };
  }

  private static buildFormArray(eventsData: any[], fb: FormBuilder, eventTypeLookup: any) {
    eventsData.sort((x, y) => x.orderNo - y.orderNo);
    return fb.array(eventsData.map(item => fb.group(QuarantineTemplateBrowseComponent.getEventGroupDef(item, fb, eventTypeLookup))));
  }

  private static buildFormGroup(fb: FormBuilder, data: any = {}, eventTypeLookup?: any): FormGroup {
    return fb.group({
      id: data.id,
      caption: data.caption,
      appliedDiseases: fb.array((data.appliedDiseases || []).map(el => fb.control(el))),
      events: QuarantineTemplateBrowseComponent.buildFormArray(data.events || [], fb, eventTypeLookup)
    });
  }

  get id() {
    return this.selectedTemplate ? this.selectedTemplate.id : undefined;
  }

  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: FormBuilder,
              private alertService: AlertService,
              private metadataService: MetadataService,
              private globalWaitingOverlayService: GlobalWaitingOverlayService,
              private navigationService: AppNavigationService,
              private lookupSourceService: LookupSourceService) {
  }

  refreshTemplates() {
    this.globalWaitingOverlayService.StartWaiting();

    this.metadataService.getMetadata('quarantine-template').subscribe({
      next: data => {
        data.sort((a, b) => a.caption.localeCompare(b.caption));
        this.templates = data;
        this.navigateInitialTemplate();
        this.globalWaitingOverlayService.EndWaiting();
      }, error: error => {
        this.alertService.error(error);
        this.globalWaitingOverlayService.EndWaiting();
      }
    });
  }

  ngOnInit() {

    this.route.paramMap
      .subscribe(params => {

        this.templates = [];

        this.initialTemplateId = +params.get('id') || undefined;

        this.navigateInitialTemplate();

        this.refreshTemplates();
      });
  }

  deleteCurrentTemplate() {
    if (!this.selectedTemplate.id) {
      return;
    }

    this.alertService.confirmModal('Вы уверены, что хотите безвозвратно удалить текущий шаблон?')
      .subscribe(() => {
        this.metadataService.deleteMeta('quarantine-template', this.selectedTemplate.id).subscribe(() => {
          this.router.navigate([`dictionary/quarantine-template`]);
        });
      });
  }

  addTemplate() {
    this.selectedTemplate = {id: undefined, caption: 'Новый шаблон', events: [], appliedDiseases: []};
    this.templates.push(this.selectedTemplate);
    this.refreshFormData();
  }

  selectedTemplateChange() {
    if (this.selectedTemplate && this.selectedTemplate.id) {
      this.router.navigate([`dictionary/quarantine-template/${this.selectedTemplate.id}`]);
    } else {
      this.refreshFormData();
    }
  }

  private navigateInitialTemplate() {
    if (!this.templates) {
      return;
    }

    this.selectedTemplate = this.templates.find(item => (item.id === this.initialTemplateId));
    this.refreshFormData();
  }

  getEvents(): FormArray {
    return this.contextFormGroup.get('events') as FormArray;
  }

  store() {
    if (!this.contextFormGroup.valid) {
      return;
    }

    this.storing = true;

    if (this.id) {
      this.metadataService.updateMeta('quarantine-template', this.contextFormGroup.value, this.id)
        .subscribe({
          next: () => {
            this.storing = false;
            this.refreshFormData();
          },
          error: () => {
            this.storing = false;
          }
        });
    } else {
      this.metadataService.createMeta('quarantine-template', this.contextFormGroup.value)
        .subscribe({
          next: (data) => {
            this.router.navigate([`dictionary/quarantine-template/${data.data.id}`]);
            this.storing = false;
          },
          error: () => {
            this.storing = false;
          }
        });
    }
  }

  refreshFormData() {
    this.refreshFormDataInternal();
  }

  private refreshFormDataInternal() {

    if (!this.selectedTemplate) {
      return;
    }

    this.globalWaitingOverlayService.StartWaiting();

    this.lookupSourceService.getLookupObj('quarantine-event-type').subscribe(eventTypeLookup => {

      if (!this.id) {
        this.contextFormGroup = QuarantineTemplateBrowseComponent.buildFormGroup(this.fb, {}, eventTypeLookup);
        this.globalWaitingOverlayService.EndWaiting();
      } else {
        this.metadataService.getMetadataSingle('quarantine-template', this.id).subscribe({
          next: data => {
            this.contextFormGroup = QuarantineTemplateBrowseComponent.buildFormGroup(this.fb, data, eventTypeLookup);

            this.globalWaitingOverlayService.EndWaiting();
          }, error: error => {
            this.alertService.error(error);
            this.globalWaitingOverlayService.EndWaiting();
          }
        });
      }
    });
  }

  addEvent() {
    this.lookupSourceService.getLookupObj('quarantine-event-type').subscribe(eventTypeLookup => {
      this.getEvents().push(this.fb.group(QuarantineTemplateBrowseComponent.getEventGroupDef({
        orderNo: this.calculateNextOrderNo(),
        __editing: true
      }, this.fb, eventTypeLookup)));
      this.contextFormGroup.markAsDirty();
    });
  }

  deleteEvent(eventItem: FormGroup) {

    const index = this.getEvents().controls.findIndex(el => el === eventItem);

    if (index < 0) {
      return;
    }

    this.getEvents().removeAt(index);

    this.contextFormGroup.markAsDirty();
  }

  private calculateNextOrderNo() {
    let maxNo = 0;
    this.getEvents().controls.forEach(ctrl => {
      if (ctrl.value.orderNo > maxNo) {
        maxNo = ctrl.value.orderNo;
      }
    });
    return maxNo + 1;
  }

  goEditing(eventItem: FormGroup) {
    eventItem.get('__editing').setValue(true);
    eventItem.markAsDirty();
  }

  goEndEditing(eventItem: FormGroup) {
    eventItem.get('__editing').setValue(false);
  }

  getResponsiblesTitle(eventItem: FormGroup) {

    return this.lookupSourceService.getLookupObj('quarantine-event-responsible').pipe(map((respLookup) => {
      let strResp = '';
      eventItem.value.responsibleTypeId.forEach(rt => {
        if (rt) {
          strResp = strResp + (strResp ? ', ' : '') + respLookup[rt];
        }
      });

      return strResp;
    }));
  }

  getUsedBranchIds() {
    const branchIds = [];
    const branchHash = {};
    this.getEvents().controls.forEach(eventItem => {
      const branchId = eventItem.get('__branchId').value;
      if (!branchHash[branchId]) {
        branchHash[branchId] = true;
        branchIds.push(branchId);
      }
    });
    return branchIds;
  }

  getEventsByBranch(branchId: any, includeNoBranch: boolean) {
    const controls = [];
    this.getEvents().controls.forEach(eventItem => {
      const tid = eventItem.get('__branchId').value;
      if ((tid == branchId) || (!tid && includeNoBranch)) {
        controls.push(eventItem);
      }
    });
    return controls;
  }

  moveUp(eventItem: FormGroup) {
    const index = this.getEvents().controls.findIndex(el => el === eventItem);

    if (index <= 0) {
      return;
    }

    this.moveItem(eventItem, this.getEvents().controls[index - 1]);
  }

  moveDown(eventItem: FormGroup) {
    const index = this.getEvents().controls.findIndex(el => el === eventItem);

    if (index >= this.getEvents().controls.length - 1) {
      return;
    }

    this.moveItem(eventItem, this.getEvents().controls[index + 1]);
  }

  private moveItem(eventItem: AbstractControl, eventItem2: AbstractControl) {
    const orderNo = eventItem.value.orderNo;
    eventItem.get('orderNo').setValue(eventItem2.value.orderNo);
    eventItem.markAsDirty();
    eventItem2.get('orderNo').setValue(orderNo);
    eventItem2.markAsDirty();

    this.getEvents().controls.sort((x, y) => x.get('orderNo').value - y.get('orderNo').value);
  }
}
