/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./edit-animal-event-common-form.component.ngfactory";
import * as i2 from "./edit-animal-event-common-form.component";
import * as i3 from "../../../logic/services/app-navigation.service";
import * as i4 from "@angular/forms";
import * as i5 from "../../../logic/services/animal-data.service";
import * as i6 from "../../../logic/services/address-person-fio-cache.service";
import * as i7 from "../../../logic/services/lookup-source.service";
import * as i8 from "../../../ui/infrastructure/global-waiting-overlay.service";
import * as i9 from "../../../logic/services/data-caching.service";
import * as i10 from "../../../logic/services/quarantine-data.service";
import * as i11 from "../../../../../node_modules/@clr/angular/clr-angular.ngfactory";
import * as i12 from "@clr/angular";
import * as i13 from "@angular/common";
import * as i14 from "./create-animal-event.component";
import * as i15 from "@angular/router";
import * as i16 from "../../../logic/services/bookmark.service";
import * as i17 from "../../../logic/services/security.service";
var styles_CreateAnimalEventComponent = [];
var RenderType_CreateAnimalEventComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CreateAnimalEventComponent, data: {} });
export { RenderType_CreateAnimalEventComponent as RenderType_CreateAnimalEventComponent };
function View_CreateAnimalEventComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-edit-animal-event-common-form", [], null, [[null, "resultsCountsChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("resultsCountsChanged" === en)) {
        var pd_0 = (_co.getSelectedAnimalsCount() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_EditAnimalEventCommonFormComponent_0, i1.RenderType_EditAnimalEventCommonFormComponent)), i0.ɵdid(1, 573440, [[1, 4], ["animalEventFormChild", 4]], 0, i2.EditAnimalEventCommonFormComponent, [i3.AppNavigationService, i4.FormBuilder, i5.AnimalDataService, i6.AddressPersonFioCacheService, i7.LookupSourceService, i8.GlobalWaitingOverlayService, i9.DataCachingService, i10.QuarantineDataService, i0.ChangeDetectorRef], { contextFormGroup: [0, "contextFormGroup"], model: [1, "model"], animal: [2, "animal"], eventType: [3, "eventType"], showAddManyAnimal: [4, "showAddManyAnimal"] }, { resultsCountsChanged: "resultsCountsChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.form; var currVal_1 = _co.model; var currVal_2 = _co.model.animal; var currVal_3 = _co.model.eventType; var currVal_4 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_CreateAnimalEventComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "p", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "button", [["class", "btn btn-primary"]], [[1, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createCard() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_ClrLoadingButton_0, i11.RenderType_ClrLoadingButton)), i0.ɵdid(2, 49152, null, 0, i12.ClrLoadingButton, [i0.ElementRef, i0.Renderer2], { disabled: [0, "disabled"] }, null), i0.ɵprd(2048, null, i12.LoadingListener, null, [i12.ClrLoadingButton]), i0.ɵdid(4, 147456, null, 0, i12.ClrLoading, [[2, i12.LoadingListener]], { loadingState: [0, "loadingState"] }, null), (_l()(), i0.ɵeld(5, 0, null, 0, 1, "clr-icon", [["shape", "check"]], null, null, null, null, null)), i0.ɵdid(6, 16384, null, 0, i12.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(7, 0, ["\u00A0\u0421\u043E\u0437\u0434\u0430\u0442\u044C", " "])), (_l()(), i0.ɵeld(8, 0, null, null, 6, "button", [["class", "btn btn-primary"]], [[1, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createCard(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_ClrLoadingButton_0, i11.RenderType_ClrLoadingButton)), i0.ɵdid(9, 49152, null, 0, i12.ClrLoadingButton, [i0.ElementRef, i0.Renderer2], { disabled: [0, "disabled"] }, null), i0.ɵprd(2048, null, i12.LoadingListener, null, [i12.ClrLoadingButton]), i0.ɵdid(11, 147456, null, 0, i12.ClrLoading, [[2, i12.LoadingListener]], { loadingState: [0, "loadingState"] }, null), (_l()(), i0.ɵeld(12, 0, null, 0, 1, "clr-icon", [["shape", "check"]], null, null, null, null, null)), i0.ɵdid(13, 16384, null, 0, i12.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, 0, ["\u00A0\u0421\u043E\u0437\u0434\u0430\u0442\u044C \u0438 \u043F\u0440\u043E\u0434\u043E\u043B\u0436\u0438\u0442\u044C "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.creating || !_co.selectedAnimalsCount); _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.creating; _ck(_v, 4, 0, currVal_2); var currVal_5 = (_co.creating || !_co.selectedAnimalsCount); _ck(_v, 9, 0, currVal_5); var currVal_6 = _co.creating; _ck(_v, 11, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i0.ɵnov(_v, 2).disabled ? "" : null); _ck(_v, 1, 0, currVal_0); var currVal_3 = ((" (\u0412\u044B\u0431\u0440\u0430\u043D\u043E " + _co.selectedAnimalsCount) + " \u0436\u0438\u0432\u043E\u0442\u043D\u044B\u0445)"); _ck(_v, 7, 0, currVal_3); var currVal_4 = (i0.ɵnov(_v, 9).disabled ? "" : null); _ck(_v, 8, 0, currVal_4); }); }
function View_CreateAnimalEventComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "p", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "button", [["class", "btn btn-primary"]], [[1, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createCard() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_ClrLoadingButton_0, i11.RenderType_ClrLoadingButton)), i0.ɵdid(2, 49152, null, 0, i12.ClrLoadingButton, [i0.ElementRef, i0.Renderer2], { disabled: [0, "disabled"] }, null), i0.ɵprd(2048, null, i12.LoadingListener, null, [i12.ClrLoadingButton]), i0.ɵdid(4, 147456, null, 0, i12.ClrLoading, [[2, i12.LoadingListener]], { loadingState: [0, "loadingState"] }, null), (_l()(), i0.ɵeld(5, 0, null, 0, 1, "clr-icon", [["shape", "check"]], null, null, null, null, null)), i0.ɵdid(6, 16384, null, 0, i12.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, 0, ["\u00A0\u0421\u043E\u0437\u0434\u0430\u0442\u044C "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.creating; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.creating; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i0.ɵnov(_v, 2).disabled ? "" : null); _ck(_v, 1, 0, currVal_0); }); }
function View_CreateAnimalEventComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CreateAnimalEventComponent_3)), i0.ɵdid(2, 16384, null, 0, i13.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["stableActions", 2]], null, 0, null, View_CreateAnimalEventComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.animal; var currVal_1 = i0.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_CreateAnimalEventComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { animalEventFormChild: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "itech-content-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "clr-icon", [["class", "has-badge"], ["shape", "color-picker"], ["size", "36"]], null, null, null, null, null)), i0.ɵdid(3, 16384, null, 0, i12.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, ["\u00A0\u0421\u043E\u0437\u0434\u0430\u043D\u0438\u0435 \u043A\u0430\u0440\u0442\u043E\u0447\u043A\u0438 \u043C\u0435\u0440\u043E\u043F\u0440\u0438\u044F\u0442\u0438\u044F\n"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CreateAnimalEventComponent_1)), i0.ɵdid(6, 16384, null, 0, i13.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CreateAnimalEventComponent_2)), i0.ɵdid(8, 16384, null, 0, i13.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.model; _ck(_v, 8, 0, currVal_1); }, null); }
export function View_CreateAnimalEventComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_CreateAnimalEventComponent_0, RenderType_CreateAnimalEventComponent)), i0.ɵdid(1, 114688, null, 0, i14.CreateAnimalEventComponent, [i15.ActivatedRoute, i15.Router, i6.AddressPersonFioCacheService, i16.BookmarkService, i5.AnimalDataService, i9.DataCachingService, i3.AppNavigationService, i17.SecurityService, i7.LookupSourceService, i0.ChangeDetectorRef, i4.FormBuilder], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CreateAnimalEventComponentNgFactory = i0.ɵccf("ng-component", i14.CreateAnimalEventComponent, View_CreateAnimalEventComponent_Host_0, { model: "model" }, {}, []);
export { CreateAnimalEventComponentNgFactory as CreateAnimalEventComponentNgFactory };
