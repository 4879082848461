import { BookmarkService } from '../../../logic/services/bookmark.service';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { InstitutionDataService } from '../../../logic/services/institution-data.service';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';
import { EditInstitutionCommonFormComponent } from './edit-institution-common-form.component';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
var EditInstitutionCommonComponent = /** @class */ (function () {
    function EditInstitutionCommonComponent(bookmarkService, institutionDataService, alertService, cache, dataCachingService, waitingOverlay, fb, navigationService) {
        this.bookmarkService = bookmarkService;
        this.institutionDataService = institutionDataService;
        this.alertService = alertService;
        this.cache = cache;
        this.dataCachingService = dataCachingService;
        this.waitingOverlay = waitingOverlay;
        this.fb = fb;
        this.navigationService = navigationService;
        this.model = {};
        this.storing = false;
    }
    Object.defineProperty(EditInstitutionCommonComponent.prototype, "id", {
        get: function () {
            return this._id;
        },
        set: function (id) {
            if (this._id !== id) {
                this._id = id;
                this.refreshFormDataInternal();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditInstitutionCommonComponent.prototype, "contextFormGroup", {
        get: function () {
            return this.model ? this.model.contextFormGroup : undefined;
        },
        enumerable: true,
        configurable: true
    });
    EditInstitutionCommonComponent.prototype.store = function () {
        var _this = this;
        FormHelper.markAsSubmitted(this.contextFormGroup);
        if (!this.contextFormGroup.valid) {
            return;
        }
        this.storing = true;
        this.institutionDataService.storeInstitutionCommonInfo(this.contextFormGroup.value).subscribe(function (val) {
            _this.storing = false;
            _this.cache.invalidateInstitution(_this.id);
            _this.bookmarkService.refreshInstitutionCardBookmark(_this.id);
            _this.contextFormGroup.markAsUntouched();
            _this.contextFormGroup.markAsPristine();
            _this.alertService.success("\u041E\u0431\u0449\u0430\u044F \u0438\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F \u0434\u043B\u044F [" + _this.contextFormGroup.get('shortCaption').value + "] \u0443\u0441\u043F\u0435\u0448\u043D\u043E \u0441\u043E\u0445\u0440\u0430\u043D\u0435\u043D\u0430");
        }, function (error) {
            _this.storing = false;
            FormHelper.setSingleFormGroupServerSideValidationErrors(error, _this.model, _this.contextFormGroup);
        });
    };
    EditInstitutionCommonComponent.prototype.isInvalid = function (fg, cname) {
        return FormHelper.isInvalid(fg, cname);
    };
    EditInstitutionCommonComponent.prototype.cancelEdit = function () {
        this.dataCachingService.removeCachedData('EditInstitutionCommon', this.id.toString());
        this.refreshFormDataInternal();
    };
    EditInstitutionCommonComponent.prototype.refreshFormData = function () {
        this.dataCachingService.removeCachedData('EditInstitutionCommon', this.id.toString());
        this.refreshFormDataInternal();
    };
    EditInstitutionCommonComponent.prototype.refreshFormDataInternal = function () {
        var _this = this;
        this.waitingOverlay.StartWaiting();
        this.model = { id: this.id, serverSideValidationErrors: [] };
        var existing = this.dataCachingService.getCachedData('EditInstitutionCommon', this.id.toString());
        if (existing) {
            this.model = existing;
            this.waitingOverlay.EndWaiting();
        }
        else {
            this.institutionDataService.getInstitutionCommonForEdit(this.id).subscribe(function (data) {
                _this.model.id = data.id;
                _this.model.contextFormGroup = EditInstitutionCommonFormComponent.createFormGroup(_this.fb, _this.model, data);
                _this.dataCachingService.addToCache('EditInstitutionCommon', _this.id.toString(), _this.model);
                _this.waitingOverlay.EndWaiting();
            }, function () {
                _this.waitingOverlay.EndWaiting();
            });
        }
    };
    EditInstitutionCommonComponent.prototype.deleteInstitution = function () {
        var _this = this;
        this.alertService.confirmModal('Вы уверены, что хотите удалить безвозвратно карточку учреждения?').subscribe(function () {
            _this.institutionDataService.deleteInstitution(_this.id).subscribe(function () {
                _this.dataCachingService.removeCachedData('EditInstitutionCommon', _this.id.toString());
                _this.bookmarkService.removeOpenCardBookmark(['/institution-edit'], _this.id.toString());
                _this.cache.invalidateInstitution(_this.id);
            }, function (error) {
                FormHelper.setSingleFormGroupServerSideValidationErrorsWithRelationMsgKey(error, _this.model, _this.model.contextFormGroup);
            });
        });
    };
    return EditInstitutionCommonComponent;
}());
export { EditInstitutionCommonComponent };
