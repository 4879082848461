import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { DataCachingService } from '../../logic/services/data-caching.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BookmarkService } from '../../logic/services/bookmark.service';
import { AppNavigationService } from '../../logic/services/app-navigation.service';
import { FormArray, FormBuilder } from '@angular/forms';
import { FormHelper } from '../../ui/controls/form-helper';
import { SecurityService } from '../../logic/services/security.service';
import { ProductDataService } from '../../logic/services/product-data.service';
import { EditProductCommonFormComponent } from './common/edit-product-common-form.component';
import { LookupSourceService } from '../../logic/services/lookup-source.service';
import { GlobalWaitingOverlayService } from '../../ui/infrastructure/global-waiting-overlay.service';
import { EditProductSourceFormComponent } from './common/edit-product-source-form.component';
import { StableDataService } from '../../logic/services/stable-data.service';

@Component({
  selector: 'app-create-product',
  templateUrl: './create-product.component.html'
})
export class CreateProductComponent implements OnInit {

  @Input() model: any;
  creating = false;
  created = false;
  sourceProductId: any;
  sourceAnimalId: any;
  transactionType: any;
  originStableId: any;
  validityControlsEvent = new EventEmitter();

  constructor(private route: ActivatedRoute,
              private router: Router,
              private bookmarkService: BookmarkService,
              private productDataService: ProductDataService,
              private dataCachingService: DataCachingService,
              private appNavigationService: AppNavigationService,
              private securityService: SecurityService,
              private fb: FormBuilder,
              private lookupSourceService: LookupSourceService,
              private waitingOverlay: GlobalWaitingOverlayService,
              private stableDataService: StableDataService) {
  }

  ngOnInit() {

    this.router.events.subscribe(() => {
      if (!this.created) {
        this.refreshCurrentBookmark(true);
      }
    });

    this.route.paramMap
      .subscribe(params => {

        const randomUniqueTag = params.get('randomUniqueTag');

        const existing = this.dataCachingService.getCachedData('CreateProduct', randomUniqueTag);
        this.sourceProductId = params.get('sourceProductId') ? +params.get('sourceProductId') : undefined;
        this.sourceAnimalId = params.get('sourceAnimalId') ? +params.get('sourceAnimalId') : undefined;
        this.transactionType = params.get('transactionType') ? +params.get('transactionType') : undefined;
        this.originStableId = params.get('originStableId') ? +params.get('originStableId') : undefined;

        if (!existing) {
          this.model = {
            randomUniqueTag: randomUniqueTag,
            serverSideValidationErrors: []
          };

          const productToCloneId = params.get('productToCloneId') ? +params.get('productToCloneId') : undefined;
          const transportProductIds = params.get('transportProductIds')
            ? params.get('transportProductIds').split(',').map(Number)
            : undefined;
          const fromTemplate = params.get('fromTemplate') ? params.get('fromTemplate') === 'true' : false;

          this.productDataService.getProductForCreate({
            transactionTypeId: this.transactionType,
            sourceAnimalId: this.sourceAnimalId,
            productToDeriveId: this.sourceProductId,
            productToCloneId: productToCloneId,
            originStableId: this.originStableId,
            transportProductIds: transportProductIds,
          }).subscribe(productForCreate => {
            this.model.form = EditProductCommonFormComponent.createFormGroup(this.fb, this.model, productForCreate || {}, fromTemplate);
            this.model.formMetadata = productForCreate.formMetadata;

            this.model.form.get('manyProducts').value.forEach(mp => {
              mp.sourceBatches.forEach(sb => {
                if (sb && sb.productId) {
                  EditProductSourceFormComponent.runBackgroundUpdateProductFromMercury(
                    sb.productId, this.fb, this.productDataService, this.dataCachingService);
                }
              });
              (mp.producedBatches || []).forEach(pb => {
                if (pb && pb.stableId) {
                  EditProductSourceFormComponent.runBackgroundUpdateStableProductSubtypeFromMercury(pb.stableId,
                    this.stableDataService, this.lookupSourceService);
                }
              });
            });

            this.refreshCurrentBookmark();
          },
          () => {
            if (productToCloneId) {
              this.appNavigationService.performProductEditing(productToCloneId);
            }
          });


          this.dataCachingService.addToCache('CreateProduct', randomUniqueTag, this.model, 10);
        } else {
          this.model = existing;
          this.refreshCurrentBookmark();
        }
      });
  }

  refreshCurrentBookmark(skipIfNotExists?: boolean) {
    if (!this.model.form) {
      return;
    }
    this.lookupSourceService.getLookupObj('transaction-type').subscribe(lookup => {
      let caption = this.model.form.get('transactionType').value ? lookup[this.model.form.get('transactionType').value] : '';
      if (caption) {
        caption = ' (' + caption + ')';
      }
      this.bookmarkService.addOpenCardBookmark(this.model.randomUniqueTag,
        `Новый документ${caption}`,
        ['/product-create', this.model.randomUniqueTag], 'truck', skipIfNotExists);
    });
  }

  createCard(sendToRegistration = false) {

    FormHelper.markAsSubmitted(this.model.form);
    EditProductCommonFormComponent.prettifyFormGroup(this.model.form);

    if (!this.model.form.valid) {
      this.validityControlsEvent.emit();
      return;
    }

    if (sendToRegistration && !this.validateRegionalization()) {
      return;
    }

    const dataToStore = this.model.form.value;

    dataToStore.sendToRegistrationAfterCreate = sendToRegistration;
    this.creating = true;

    this.waitingOverlay.StartWaiting();

    this.productDataService.storeProductCommonInfo(dataToStore).subscribe((createdProduct: any) => {
      this.creating = false;
      this.created = true;
      this.bookmarkService.removeOpenCardBookmark(['/product-create'], this.model.randomUniqueTag);
      this.appNavigationService.performProductEditing(createdProduct.id);
      this.waitingOverlay.EndWaiting();
    }, error => {
      this.creating = false;
      FormHelper.setSingleFormGroupServerSideValidationErrorsWithRelationMsgKey(error, this.model, this.model.form);
      this.waitingOverlay.EndWaiting();
    });
  }

  validateRegionalization() {

    if (!EditProductCommonFormComponent.isTransportTransaction(this.model.form)) {
      return true;
    }

    let valid = true;

    for (const section of (this.model.form.get('regionalizationRouteSections') as FormArray).controls) {
        for (const rule of (section.get('regionalizationRules') as FormArray).controls) {
          for (const requirement of (rule.get('regionalizationRequirements') as FormArray).controls) {
            if (!(requirement.get('conditionGroups') as FormArray).getRawValue().some(x => x.acceptedGroup)) {
              (requirement.get('conditionGroups') as FormArray).controls
                .forEach(x => x.get('acceptedGroup').setErrors({'incorrect': true}));
              valid = false;
          }
        }
      }
    }

    return valid;
  }
}
