import * as tslib_1 from "tslib";
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { MetadataService } from '../../../logic/services/metadata.service';
import { FormBuilder, Validators } from '@angular/forms';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { MetaBrowseBaseComponent } from '../../../pages/dictionary/meta-browse.base.component';
import { FormHelper } from '../../../ui/controls/form-helper';
import { StringHelper } from '../../../helpers/string-helper';
var PstReportTypeBrowseComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PstReportTypeBrowseComponent, _super);
    function PstReportTypeBrowseComponent(route, metadataService, alertService, globalWaitingOverlayService, lookupService, fb, bookmarkService, dataCachingService) {
        var _this = _super.call(this, route, metadataService, alertService, globalWaitingOverlayService, lookupService, fb, bookmarkService, dataCachingService) || this;
        _this.gridColumnDefs = [
            { headerName: '№', field: 'id', width: 100 },
            { headerName: 'Наименование', field: 'caption', tooltipField: 'caption', width: 200 },
            { headerName: 'Уровень', field: 'reportingLevelCaption', width: 120 },
            { headerName: 'Периодичность', field: 'periodicityCaption', width: 120 },
            { headerName: 'Дней на предост.', field: 'daysDelay', width: 120 },
            {
                headerName: 'Начала сбора', field: 'startedDate', width: 120,
                valueFormatter: function (params) { return StringHelper.getRuDate(params.value); },
            },
            {
                headerName: 'Окончание сбора', field: 'finishedDate', width: 120,
                valueFormatter: function (params) { return StringHelper.getRuDate(params.value); },
            },
        ];
        return _this;
    }
    PstReportTypeBrowseComponent.prototype.getMetaTitle = function () {
        return 'Виды электронных отчетов';
    };
    PstReportTypeBrowseComponent.prototype.getMetaName = function () {
        return 'pst-report-type';
    };
    PstReportTypeBrowseComponent.prototype.refreshResults = function () {
        var _this = this;
        this.globalWaitingOverlayService.StartWaiting();
        this.lookupService.getLookupObj('pst-reporting-level').subscribe(function (reportLevelLookup) {
            _this.lookupService.getLookupObj('pst-report-periodicity').subscribe(function (reportingPeriodicityLookup) {
                _this.metadataService.getMetadata(_this.getMetaName()).subscribe({
                    next: function (data) {
                        _this._searchResults = data;
                        _this._searchResults.forEach(function (el) {
                            el.reportingLevelCaption = reportLevelLookup[el.reportingLevel];
                            el.periodicityCaption = reportingPeriodicityLookup[el.periodicity];
                        });
                        _this.dataCachingService.addToCache(_this.getMetaName() + '_Data', '1', data);
                        _this.lookupService.invalidateLookup(_this.getMetaName());
                        _this.globalWaitingOverlayService.EndWaiting();
                    }, error: function () {
                        _this.globalWaitingOverlayService.EndWaiting();
                    }
                });
            });
        });
    };
    PstReportTypeBrowseComponent.prototype.getGroupDef = function (row) {
        return {
            id: [row.id, Validators.compose([Validators.required, Validators.pattern(/^\d{1,3}$/)])],
            caption: [row.caption, Validators.required],
            groupCaption: row.groupCaption,
            reportingLevel: [row.reportingLevel, Validators.required],
            periodicity: [row.periodicity, Validators.required],
            daysDelay: [row.daysDelay, Validators.compose([Validators.required, Validators.pattern(/^\d{1,2}$/)])],
            startedDate: [row.startedDate, Validators.compose([Validators.required, FormHelper.validateDateTimePicker()])],
            finishedDate: [row.finishedDate, FormHelper.validateDateTimePicker()],
        };
    };
    return PstReportTypeBrowseComponent;
}(MetaBrowseBaseComponent));
export { PstReportTypeBrowseComponent };
