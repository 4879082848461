import { OnDestroy, OnInit } from '@angular/core';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { FormBuilder } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';
import { EditAgentCommonFormComponent } from './edit-agent-common-form.component';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { AgentDataService } from '../../../logic/services/agent-data.service';
import { MetadataService } from '../../../logic/services/metadata.service';
import { AgentSearchModalComponent } from '../search/agent-search-modal.component';
import { AgentSearchResultsFormComponent } from '../search/agent-search-results-form.component';
var EditAgentCommonComponent = /** @class */ (function () {
    function EditAgentCommonComponent(bookmarkService, agentDataService, alertService, cache, dataCachingService, waitingOverlay, fb, navigationService, metadataService) {
        this.bookmarkService = bookmarkService;
        this.agentDataService = agentDataService;
        this.alertService = alertService;
        this.cache = cache;
        this.dataCachingService = dataCachingService;
        this.waitingOverlay = waitingOverlay;
        this.fb = fb;
        this.navigationService = navigationService;
        this.metadataService = metadataService;
        this.model = {};
        this.storing = false;
        this.updateSyncStateTimer = {};
        this.updateSyncStatePending = {};
        this.clrModalOpen = false;
        this.formGroupMerge = this.fb.group({
            mergeSourceId: null
        });
        this.selectedCheckboxConfirmAction = false;
    }
    Object.defineProperty(EditAgentCommonComponent.prototype, "id", {
        get: function () {
            return this._id;
        },
        set: function (id) {
            if (this._id !== id) {
                this._id = id;
                this.refreshFormDataInternal();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditAgentCommonComponent.prototype, "contextFormGroup", {
        get: function () {
            return this.model ? this.model.contextFormGroup : undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditAgentCommonComponent.prototype, "syncStates", {
        get: function () {
            return this.model && this.model.syncStates ? Object.values(this.model.syncStates) : undefined;
        },
        enumerable: true,
        configurable: true
    });
    EditAgentCommonComponent.prototype.store = function () {
        var _this = this;
        FormHelper.markAsSubmitted(this.contextFormGroup);
        EditAgentCommonFormComponent.prettifyFormGroup(this.contextFormGroup);
        if (!this.contextFormGroup.valid) {
            return;
        }
        this.storing = true;
        this.agentDataService.storeAgentCommonInfo(this.contextFormGroup.value).subscribe(function (val) {
            _this.storing = false;
            _this.cache.invalidateAgent(_this.id);
            _this.bookmarkService.refreshAgentCardBookmark(_this.id);
            _this.contextFormGroup.markAsUntouched();
            _this.contextFormGroup.markAsPristine();
            FormHelper.setSingleFormGroupServerSideValidationErrors({}, _this.model, _this.contextFormGroup);
            _this.updateModelSyncStateFromLookup(val);
            _this.alertService.success("\u041E\u0431\u0449\u0430\u044F \u0438\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F \u0434\u043B\u044F [" + _this.contextFormGroup.get('shortCaption').value + "] \u0443\u0441\u043F\u0435\u0448\u043D\u043E \u0441\u043E\u0445\u0440\u0430\u043D\u0435\u043D\u0430");
        }, function (error) {
            _this.storing = false;
            FormHelper.setSingleFormGroupServerSideValidationErrors(error, _this.model, _this.contextFormGroup);
            window.document.getElementById('agent-common-content').scrollTop = 0;
        });
    };
    EditAgentCommonComponent.prototype.isInvalid = function (fg, cname) {
        return FormHelper.isInvalid(fg, cname);
    };
    EditAgentCommonComponent.prototype.cancelEdit = function () {
        this.dataCachingService.removeCachedData('EditAgentCommon', this.id.toString());
        this.refreshFormDataInternal();
    };
    EditAgentCommonComponent.prototype.refreshFormData = function (resyncWithMercury) {
        this.dataCachingService.removeCachedData('EditAgentCommon', this.id.toString());
        this.refreshFormDataInternal(resyncWithMercury);
    };
    EditAgentCommonComponent.prototype.refreshFormDataInternal = function (resyncWithMercury) {
        var _this = this;
        this.waitingOverlay.StartWaiting();
        this.model = { id: this.id, serverSideValidationErrors: [], syncState: {} };
        var existing = this.dataCachingService.getCachedData('EditAgentCommon', this.id.toString());
        if (existing) {
            this.model = existing;
            this.waitingOverlay.EndWaiting();
        }
        else {
            (resyncWithMercury
                ? this.agentDataService.getAgentCommonForEditWithSync(this.id)
                : this.agentDataService.getAgentCommonForEdit(this.id)).subscribe({
                next: function (data) {
                    _this.model.id = data.id;
                    _this.model.contextFormGroup = EditAgentCommonFormComponent.createFormGroup(_this.fb, _this.model, data);
                    _this.updateModelSyncStateFromLookup(data);
                    _this.cache.invalidateAgent(_this.id);
                    _this.dataCachingService.addToCache('EditAgentCommon', _this.id.toString(), _this.model);
                    _this.waitingOverlay.EndWaiting();
                }, error: function () {
                    _this.waitingOverlay.EndWaiting();
                }
            });
        }
    };
    EditAgentCommonComponent.prototype.updateModelSyncStateFromLookup = function (data) {
        data.targetObjId = this.id;
        data.conversationTypeId = -1;
        this.updateModelSyncState(data, -1);
    };
    EditAgentCommonComponent.prototype.updateModelSyncState = function (data, conversationTypeId) {
        if (!this.model.syncStates) {
            this.model.syncStates = {};
        }
        this.model.syncStates[conversationTypeId] = data;
    };
    EditAgentCommonComponent.prototype.refreshSyncStateInternal = function (conversationTypeId) {
        var _this = this;
        if (!this.contextFormGroup) {
            return;
        }
        var state = this.model && this.model.syncStates ? this.model.syncStates[conversationTypeId] : undefined;
        // если ничего не запланировано по синхронизации - ничего и не может произойти и обновлять состояние не надо
        if (state && (!state.mercuryExchangeNextTime || new Date(state.mercuryExchangeNextTime) > new Date())) {
            return;
        }
        if (this.updateSyncStatePending[conversationTypeId]) {
            return;
        }
        this.updateSyncStatePending[conversationTypeId] = true;
        (conversationTypeId === -1
            ? this.agentDataService.getAgentCommonForLookup(this.id)
            : this.metadataService.getSyncState(this.id, conversationTypeId))
            .subscribe({
            next: function (data) {
                _this.updateSyncStatePending[conversationTypeId] = false;
                if (conversationTypeId === -1) {
                    _this.updateModelSyncStateFromLookup(data);
                }
                else {
                    _this.updateModelSyncState(data, conversationTypeId);
                }
            }, error: function () {
                if (conversationTypeId === -1) {
                    _this.updateModelSyncStateFromLookup({});
                }
                else {
                    _this.updateModelSyncState({}, conversationTypeId);
                }
            }
        });
    };
    EditAgentCommonComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.updateSyncStateTimer[-1] = setInterval(function () { return _this.refreshSyncStateInternal(-1); }, 10000);
    };
    EditAgentCommonComponent.prototype.ngOnDestroy = function () {
        var _this = this;
        if (this.updateSyncStateTimer) {
            Object.keys(this.updateSyncStateTimer).forEach(function (x) { return clearInterval(_this.updateSyncStateTimer[x]); });
            this.updateSyncStateTimer = {};
        }
    };
    EditAgentCommonComponent.prototype.deleteAgent = function () {
        var _this = this;
        this.alertService.confirmModal('Вы уверены, что хотите аннулировать хозяйствующий субъект?').subscribe(function () {
            _this.agentDataService.deleteAgent(_this.id).subscribe(function () {
                _this.dataCachingService.removeCachedData('EditAgentCommon', _this.id.toString());
                _this.bookmarkService.removeOpenCardBookmark(['/agent-edit'], _this.id.toString());
            });
        });
    };
    EditAgentCommonComponent.prototype.restoreDeletedAgent = function () {
        var _this = this;
        this.alertService.confirmModal('Вы уверены, что хотите восстановить аннулированный хозяйствующий субъект?').subscribe(function () {
            _this.agentDataService.restoreDeletedAgent(_this.id).subscribe(function () {
                _this.dataCachingService.removeCachedData('EditAgentCommon', _this.id.toString());
                _this.refreshFormDataInternal();
            });
        });
    };
    EditAgentCommonComponent.prototype.removePersonalAccountAgent = function () {
        var _this = this;
        this.alertService.confirmModal('В результате удаления личного кабинета у ХС будет удалена контактная информация,' +
            ' неподтверждённые животные, история переписок с учреждениями, отменены зарезервированные талоны. ' +
            'Вы уверены, что хотите удалить личный кабинет хозяйствующего субъекта во внешней системе?')
            .subscribe(function () {
            _this.agentDataService.removePersonalAccountAgent(_this.id).subscribe(function () {
                _this.refreshFormData();
            });
        });
    };
    EditAgentCommonComponent.prototype.searchAgent = function () {
        var _this = this;
        this.navigationService.searchAgent(AgentSearchModalComponent, {
            excludeId: this.id,
            excludeCaption: AgentSearchResultsFormComponent.EXCLUDE_CAPTIONS.CURRENT_OPEN,
        }).subscribe(function (val) {
            if (val) {
                _this.formGroupMerge.get('mergeSourceId').setValue(val);
            }
        });
    };
    EditAgentCommonComponent.prototype.closeMergeModal = function () {
        this.clrModalOpen = false;
        this.selectedCheckboxConfirmAction = false;
        this.formGroupMerge.reset();
    };
    EditAgentCommonComponent.prototype.mergeAgent = function () {
        var _this = this;
        this.agentDataService.mergeAgent(this.id, this.formGroupMerge.get('mergeSourceId').value)
            .subscribe(function () {
            _this.clearCacheAfterMerge();
            _this.bookmarkService.removeOpenCardBookmark(['/stable-edit'], _this.formGroupMerge.get('mergeSourceId').value.toString());
            _this.closeMergeModal();
        }, function (error) { return FormHelper.setSingleFormGroupServerSideValidationErrors(error, _this, _this.formGroupMerge); });
    };
    EditAgentCommonComponent.prototype.clearCacheAfterMerge = function () {
        var _this = this;
        var animals = this.dataCachingService.getCachedData('EditAgentAnimals', this.formGroupMerge.get('mergeSourceId').value.toString());
        if (animals && animals.searchResults) {
            animals.searchResults.forEach(function (animal) { return _this.dataCachingService.removeCachedData('EditAnimalCommon', animal.id.toString()); });
        }
        this.dataCachingService.removeCachedData('EditAgentAnimals', this.formGroupMerge.get('mergeSourceId').value.toString());
        this.dataCachingService.removeCachedData('EditAgentAnimals', this.id.toString());
        var stables = this.dataCachingService.getCachedData('EditAgentStables', this.formGroupMerge.get('mergeSourceId').value.toString());
        if (stables && stables.searchResults) {
            stables.searchResults.forEach(function (stable) { return _this.dataCachingService.removeCachedData('EditStableCommon', stable.id.toString()); });
        }
        this.dataCachingService.removeCachedData('EditAgentStables', this.formGroupMerge.get('mergeSourceId').value.toString());
        this.dataCachingService.removeCachedData('EditAgentStables', this.id.toString());
        this.dataCachingService.removeCachedData('EditAgentTickets', this.formGroupMerge.get('mergeSourceId').value.toString());
        this.dataCachingService.removeCachedData('EditAgentTickets', this.id.toString());
        this.dataCachingService.removeCachedData('EditAgentCommon', this.formGroupMerge.get('mergeSourceId').value.toString());
        this.dataCachingService.removeCachedData('EditAgentCommon', this.id.toString());
    };
    EditAgentCommonComponent.prototype.grantAccessOpenApi = function () {
        this.actionAccessOpenApi(true);
    };
    EditAgentCommonComponent.prototype.revokeAccessOpenApi = function () {
        this.actionAccessOpenApi(false);
    };
    EditAgentCommonComponent.prototype.actionAccessOpenApi = function (isGrant) {
        var _this = this;
        (isGrant
            ? this.agentDataService.grantAccessOpenApi(this.id)
            : this.agentDataService.revokeAccessOpenApi(this.id)).subscribe(function () { return _this.refreshFormData(); });
    };
    return EditAgentCommonComponent;
}());
export { EditAgentCommonComponent };
