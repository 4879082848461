import { StringHelper } from '../../helpers/string-helper';
import { combineLatest } from 'rxjs/index';
import { MetadataService } from './metadata.service';
import * as i0 from "@angular/core";
import * as i1 from "./metadata.service";
var HolidaysService = /** @class */ (function () {
    function HolidaysService(metadataService) {
        var _this = this;
        this.holidaysMap = {};
        this.workdaysMap = {};
        // сервис предзагружает данные только за 3 года - это чисто для визуализации, поэтому нормально
        var year = new Date().getFullYear();
        combineLatest([metadataService.getExtraHolidays(year - 1), metadataService.getExtraWorkdays(year - 1)])
            .subscribe({
            next: function (_a) {
                var holidaysMap = _a[0], workdaysMap = _a[1];
                Object.assign(_this.holidaysMap, holidaysMap);
                Object.assign(_this.workdaysMap, workdaysMap);
            }
        });
        combineLatest([metadataService.getExtraHolidays(year), metadataService.getExtraWorkdays(year)])
            .subscribe({
            next: function (_a) {
                var holidaysMap = _a[0], workdaysMap = _a[1];
                Object.assign(_this.holidaysMap, holidaysMap);
                Object.assign(_this.workdaysMap, workdaysMap);
            }
        });
        combineLatest([metadataService.getExtraHolidays(year + 1), metadataService.getExtraWorkdays(year + 1)])
            .subscribe({
            next: function (_a) {
                var holidaysMap = _a[0], workdaysMap = _a[1];
                Object.assign(_this.holidaysMap, holidaysMap);
                Object.assign(_this.workdaysMap, workdaysMap);
            }
        });
    }
    HolidaysService.prototype.isHoliday = function (date) {
        var dayInWeekNo = date.getDay();
        var weekend = dayInWeekNo === 0 || dayInWeekNo === 6;
        var holiday = (weekend && (!this.workdaysMap || !this.workdaysMap[StringHelper.getISODate(date)])) ||
            (this.holidaysMap && this.holidaysMap[StringHelper.getISODate(date)]);
        return holiday;
    };
    HolidaysService.ngInjectableDef = i0.defineInjectable({ factory: function HolidaysService_Factory() { return new HolidaysService(i0.inject(i1.MetadataService)); }, token: HolidaysService, providedIn: "root" });
    return HolidaysService;
}());
export { HolidaysService };
