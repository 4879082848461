import { ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
var AppCustomModalHostDirective = /** @class */ (function () {
    function AppCustomModalHostDirective(viewContainerRef, componentFactoryResolver) {
        this.viewContainerRef = viewContainerRef;
        this.componentFactoryResolver = componentFactoryResolver;
        this._onBodyCreateOccuried = false;
    }
    Object.defineProperty(AppCustomModalHostDirective.prototype, "appCustomModalHost", {
        get: function () {
            return this._componentType;
        },
        set: function (val) {
            if (this._componentType !== val) {
                this._componentType = val;
                // this.viewContainerRef.clear();
                if (this._componentType) {
                    var componentFactory = this.componentFactoryResolver.resolveComponentFactory(this._componentType);
                    this.bodyComponent = this.viewContainerRef.createComponent(componentFactory).instance;
                    this._onBodyCreateOccuried = false;
                    this.execOnBodyCreated();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppCustomModalHostDirective.prototype, "appCustomModalHostOnBodyCreate", {
        get: function () {
            return this._onBodyCreate;
        },
        set: function (func) {
            if (this._onBodyCreate !== func) {
                this._onBodyCreate = func;
                this._onBodyCreateOccuried = false;
                this.execOnBodyCreated();
            }
        },
        enumerable: true,
        configurable: true
    });
    AppCustomModalHostDirective.prototype.execOnBodyCreated = function () {
        if (this._onBodyCreateOccuried) {
            return;
        }
        if (this.bodyComponent != null && this._onBodyCreate != null) {
            this._onBodyCreateOccuried = true;
            this._onBodyCreate(this.bodyComponent);
        }
    };
    return AppCustomModalHostDirective;
}());
export { AppCustomModalHostDirective };
