import { ChangeDetectorRef, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { PstReportService } from '../../services/pst-report.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { AgentSearchModalComponent } from '../../../pages/edit-agent/search/agent-search-modal.component';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';
import { of } from 'rxjs';
import { PstReportStructureService } from '../../services/pst-report-structure.service';
import { NumericHelper } from '../../../helpers/numeric-helper';
import { SecurityService } from '../../../logic/services/security.service';
import { AlertService } from '../../../ui/infrastructure/alert.service';
var PstBranchBuyDealEditComponent = /** @class */ (function () {
    function PstBranchBuyDealEditComponent(lookupSourceService, navigationService, pstReportService, cacheService, pstReportStructureService, changeDetectorRef, securityService, alertService) {
        this.lookupSourceService = lookupSourceService;
        this.navigationService = navigationService;
        this.pstReportService = pstReportService;
        this.cacheService = cacheService;
        this.pstReportStructureService = pstReportStructureService;
        this.changeDetectorRef = changeDetectorRef;
        this.securityService = securityService;
        this.alertService = alertService;
        this.buyDealMeta = [
            {
                type: 'date',
                fieldName: 'dateDeal',
                title: 'Дата покупки',
                displayWidth: 80
            },
            {
                type: 'lookup', fieldName: 'indicatorSubtypeId', lookupName: 'pst-indicator-subtype/plem',
                title: 'Вид животных, порода', displayWidth: 140
            },
            { type: 'text', fieldName: 'description', title: 'Поло - возрастная группа', displayWidth: 140 },
            {
                type: 'text',
                fieldName: 'counterpartyCaption',
                title: 'Продавец (полное наименование сельхозорганизации, КФХ, ИП, ЛПХ)',
                displayWidth: 140
            },
        ];
    }
    PstBranchBuyDealEditComponent.prototype.ngOnChanges = function (changes) {
        if (changes.hasOwnProperty('branch') || changes.hasOwnProperty('model')) {
            this.rebuildHeader();
            this.startUpdatingRowsStructure();
        }
    };
    PstBranchBuyDealEditComponent.prototype.rebuildHeader = function () {
        var _this = this;
        this.branch.headerRows = [];
        this.branch.rootRowsGroup = [];
        this.branch.rootRowsGroupHash = {};
        if (!this.branch || !this.branch.indicators) {
            return;
        }
        var depth = 1;
        this.branch.indicators.forEach(function (indicator) {
            var path = (indicator.displayTitle || '').split('->');
            if (path.length > depth) {
                depth = path.length;
            }
        });
        var rowGroupCaptions = (this.branch.meta.params['row-titles'] || '').split('|');
        var _loop_1 = function (lvl) {
            var row = this_1.buyDealMeta.map(function (el) { return ({
                meta: { displayWidth: el.displayWidth },
                buyDealMeta: el,
                displayTitle: el.title,
                displayTitlePath: el.title,
                colSpan: 1,
                rowSpan: 1,
            }); });
            this_1.branch.indicators.forEach(function (indicator) {
                var path = (indicator.displayTitle || '').split('->');
                var displayTitlePath = path.slice(0, lvl < path.length ? lvl + 1 : path.length).join('->');
                // заголовок формируем только по 0-й строке
                if (!indicator.meta.rowNo) {
                    row[indicator.meta.colNo - 1 + _this.buyDealMeta.length] = {
                        buyDealMeta: undefined,
                        meta: indicator.meta,
                        displayTitle: lvl < path.length ? path[lvl] : path[path.length - 1],
                        displayTitlePath: displayTitlePath,
                        colSpan: 1,
                        rowSpan: 1,
                    };
                }
                if (!_this.branch.rootRowsGroup[indicator.meta.rowNo]) {
                    _this.branch.rootRowsGroup[indicator.meta.rowNo] = {
                        hash: {},
                        children: [],
                        caption: rowGroupCaptions[indicator.meta.rowNo],
                        indicators: [],
                        hasEditableCells: false,
                    };
                }
                var rowGroup = _this.branch.rootRowsGroup[indicator.meta.rowNo];
                rowGroup.indicators[indicator.meta.colNo - 1 + _this.buyDealMeta.length] = indicator;
                if (!rowGroup.hasEditableCells && indicator.indicatorMeta
                    && indicator.indicatorMeta.id && !indicator.indicatorMeta.formula) {
                    rowGroup.hasEditableCells = true;
                }
                if (!_this.branch.rootRowsGroupHash[indicator.meta.indicatorTypeId]) {
                    _this.branch.rootRowsGroupHash[indicator.meta.indicatorTypeId] = [];
                }
                _this.branch.rootRowsGroupHash[indicator.meta.indicatorTypeId][indicator.meta.rowNo] = rowGroup;
            });
            this_1.branch.headerRows.push(row);
        };
        var this_1 = this;
        for (var lvl = 0; lvl < depth; lvl++) {
            _loop_1(lvl);
        }
        this.pstReportStructureService.mergeHeaderCells(this.branch);
        for (var _i = 0, _a = this.branch.rootRowsGroup; _i < _a.length; _i++) {
            var rowGroup = _a[_i];
            rowGroup.level = 10;
            rowGroup.indicatorTypeMeta = this.getLevelSlice(rowGroup, 10);
        }
        this.changeDetectorRef.detectChanges();
    };
    PstBranchBuyDealEditComponent.prototype.getSuggestedTableWidth = function () {
        if (!this.branch.headerRows || !this.branch.headerRows.length) {
            return undefined;
        }
        var lastRow = this.branch.headerRows[this.branch.headerRows.length - 1];
        var width = 0;
        for (var iCol = 0; iCol < lastRow.length; iCol++) {
            width += (lastRow[iCol].meta.displayWidth || 60);
        }
        return width + 200 + "px";
    };
    PstBranchBuyDealEditComponent.prototype.searchAgent = function (rowGroup) {
        var _this = this;
        this.securityService.getUserInfo().subscribe(function (userInfo) {
            if (userInfo.restrictPstAgentId && userInfo.agentId) {
                _this.addbuyDealRow(userInfo.agentId, rowGroup, _this.branch.defaultSubtypeId);
            }
            else {
                _this.navigationService.searchAgent(AgentSearchModalComponent).subscribe(function (agentId) {
                    if (agentId) {
                        _this.addbuyDealRow(agentId, rowGroup, _this.branch.defaultSubtypeId);
                    }
                });
            }
        });
    };
    PstBranchBuyDealEditComponent.prototype.addbuyDealRow = function (agentId, rowGroup, indicatorSubtypeId) {
        var _this = this;
        this.lookupSourceService.getLookupObj('addr-region-group').subscribe(function (regionGroupLookup) {
            _this.lookupSourceService.getLookupObj('addr-region').subscribe(function (regionLookup) {
                _this.cacheService.getAgent(agentId).subscribe(function (agent) {
                    var regionId = _this.model.reportMeta.regionId;
                    var regionGroupId = regionLookup["Obj" + regionId].regionGroupId;
                    var kfhType = agent.legalFormId === 13 || agent.legalFormId === 31 || agent.legalFormId === 45 || agent.legalFormId === 0
                        ? 2
                        : 1;
                    _this.pstReportService.addDeal(_this.model.reportMeta.id, { agentId: agentId, level60ObjKind: _this.branch.meta.level60ObjKind }).subscribe(function (data) {
                        _this.addRowWithGroups(rowGroup, regionGroupLookup, regionLookup, regionGroupId, regionId, kfhType, agentId, agent.shortCaption, data.data.id, data.data);
                    });
                });
            });
        });
    };
    PstBranchBuyDealEditComponent.prototype.addRowWithGroups = function (rowGroup, regionGroupLookup, regionLookup, regionGroupId, regionId, kfhType, agentId, agentCaption, buyDealId, buyDealData) {
        if (!regionGroupId) {
            return;
        }
        // level 20: regionGroupId
        if (!rowGroup.hash[regionGroupId]) {
            rowGroup.hash[regionGroupId] = {
                caption: regionGroupLookup["Obj" + regionGroupId].caption,
                hash: {},
                children: [],
                level: 20,
                regionId: undefined,
                regionGroupId: regionGroupId,
                kfhType: undefined,
                agentId: undefined,
                subtypeId: undefined,
                buyDealId: undefined,
                indicatorTypeMeta: this.getLevelSlice(rowGroup, 20),
            };
            rowGroup.children.push(rowGroup.hash[regionGroupId]);
        }
        var lvl20node = rowGroup.hash[regionGroupId];
        if (!regionId) {
            return;
        }
        // level 30: regionId
        if (!lvl20node.hash[regionId]) {
            lvl20node.hash[regionId] = {
                caption: regionLookup["Obj" + regionId].caption,
                level: 30,
                hash: {},
                children: [],
                regionId: regionId,
                regionGroupId: regionGroupId,
                kfhType: undefined,
                agentId: undefined,
                subtypeId: undefined,
                buyDealId: undefined,
                indicatorTypeMeta: this.getLevelSlice(rowGroup, 30),
            };
            lvl20node.children.push(lvl20node.hash[regionId]);
        }
        var lvl30node = lvl20node.hash[regionId];
        if (!kfhType) {
            return;
        }
        // level 40: kfhType КФХ/предприятия
        if (!lvl30node.hash[kfhType]) {
            lvl30node.hash[kfhType] = {
                caption: kfhType === 2 ? 'Крестьянско-фермерские хозяйства' : 'Предприятия',
                hash: {},
                children: [],
                level: 40,
                regionId: regionId,
                regionGroupId: regionGroupId,
                kfhType: kfhType,
                agentId: undefined,
                subtypeId: undefined,
                buyDealId: undefined,
                indicatorTypeMeta: this.getLevelSlice(rowGroup, 40),
            };
            lvl30node.children.push(lvl30node.hash[kfhType]);
        }
        var lvl40node = lvl30node.hash[kfhType];
        if (!agentId) {
            return;
        }
        // level 50: agentId
        if (!lvl40node.hash[agentId]) {
            lvl40node.hash[agentId] = {
                agentCaption: agentCaption,
                caption: agentCaption,
                level: 50,
                regionId: regionId,
                regionGroupId: regionGroupId,
                kfhType: kfhType,
                agentId: agentId,
                subtypeId: undefined,
                buyDealId: undefined,
                hash: {},
                children: [],
                indicatorTypeMeta: this.getLevelSlice(rowGroup, 50),
            };
            lvl40node.children.push(lvl40node.hash[agentId]);
        }
        var lvl50node = lvl40node.hash[agentId];
        if (!buyDealId) {
            return;
        }
        // level 60: buyDealId
        if (!lvl50node.hash[buyDealId]) {
            lvl50node.hash[buyDealId] = {
                agentCaption: agentCaption,
                caption: agentCaption,
                level: 60,
                regionId: regionId,
                regionGroupId: regionGroupId,
                kfhType: kfhType,
                agentId: agentId,
                buyDealId: buyDealId,
                children: [],
                buyDealData: buyDealData,
                indicatorTypeMeta: this.getLevelSlice(rowGroup, 60),
            };
            lvl50node.children.push(lvl50node.hash[buyDealId]);
        }
    };
    PstBranchBuyDealEditComponent.prototype.getLevelSlice = function (rowGroup, lvl) {
        return rowGroup.indicators.map(function (indicator) {
            if (indicator) {
                return indicator.indicatorMeta;
            }
            return undefined;
        });
    };
    PstBranchBuyDealEditComponent.prototype.startUpdatingRowsStructure = function () {
        var _this = this;
        if (!this.model || !this.branch) {
            return;
        }
        this.lookupSourceService.getLookupObj('addr-region-group').subscribe(function (regionGroupLookup) {
            _this.lookupSourceService.getLookupObj('addr-region').subscribe(function (regionLookup) {
                var _loop_2 = function (buyDealData) {
                    if (buyDealData.level60ObjKind !== _this.branch.meta.level60ObjKind) {
                        return "continue";
                    }
                    (buyDealData.agentId ? _this.cacheService.getAgent(buyDealData.agentId) : of(undefined)).subscribe(function (agent) {
                        var rowGroups = _this.branch.rootRowsGroup;
                        var kfhType = agent.legalFormId === 13 || agent.legalFormId === 31 || agent.legalFormId === 45 || agent.legalFormId === 0
                            ? 2
                            : 1;
                        rowGroups.forEach(function (rowGroup) {
                            _this.addRowWithGroups(rowGroup, regionGroupLookup, regionLookup, regionLookup["Obj" + buyDealData.regionId].regionGroupId, buyDealData.regionId, kfhType, agent ? agent.id : undefined, agent ? agent.shortCaption : undefined, buyDealData.id, buyDealData);
                        });
                    });
                };
                for (var _i = 0, _a = _this.model.deals; _i < _a.length; _i++) {
                    var buyDealData = _a[_i];
                    _loop_2(buyDealData);
                }
            });
        });
    };
    PstBranchBuyDealEditComponent.prototype.cellClick = function (indicatorLevel, colIndex, rowGroup) {
        if (this.getFocused(indicatorLevel, colIndex, rowGroup)) {
            this.setEditingCell(indicatorLevel, colIndex, indicatorLevel.indicatorTypeMeta[colIndex], rowGroup, '');
        }
        else {
            this.setFocusedCell(indicatorLevel, colIndex, rowGroup);
        }
    };
    PstBranchBuyDealEditComponent.prototype.setFocusedCell = function (indicatorLevel, colIndex, rowGroup) {
        this.model.__cl_focusedBranch = this.branch;
        this.model.__cl_focusedRowGroup = rowGroup;
        this.model.__cl_focusedIndicatorLevel = indicatorLevel;
        this.model.__cl_focusedIndicatorColIndex = colIndex;
        this.model.__cl_editingBranch = undefined;
        this.model.__cl_storing_error = undefined;
    };
    PstBranchBuyDealEditComponent.prototype.setEditingCell = function (indicatorLevel, colIndex, indicatorTypeMeta, rowGroup, proposedValue) {
        if (indicatorTypeMeta && (indicatorTypeMeta.formula || indicatorLevel.level !== 60)) {
            return;
        }
        if (!indicatorTypeMeta && !this.buyDealMeta[colIndex]) {
            return;
        }
        this.setFocusedCell(indicatorLevel, colIndex, rowGroup);
        var reportIndicator = rowGroup.indicators[colIndex];
        if (indicatorTypeMeta && reportIndicator) {
            this.setProposedCellValueStr(indicatorLevel, reportIndicator, indicatorTypeMeta, proposedValue);
        }
        this.model.__cl_editingBranch = this.branch;
        this.model.__cl_editingRowGroup = rowGroup;
        this.model.__cl_editingIndicatorLevel = indicatorLevel;
        this.model.__cl_editingIndicatorColIndex = colIndex;
        this.model.__cl_editingProposedValue = proposedValue;
    };
    PstBranchBuyDealEditComponent.prototype.tableKeyDown = function ($event) {
        if (this.model.__cl_focusedBranch === this.branch) {
            var rowGroup = this.model.__cl_focusedRowGroup;
            var indicatorLevel = this.model.__cl_focusedIndicatorLevel;
            var colIndex = this.model.__cl_focusedIndicatorColIndex;
            var indicatorTypeMeta = indicatorLevel.indicatorTypeMeta[colIndex];
            var reportIndicator = rowGroup.indicators[colIndex];
            if (!this.getEditing(indicatorLevel, colIndex, rowGroup)) {
                if ($event.key >= '0' && $event.key <= '9') {
                    this.setEditingCell(indicatorLevel, colIndex, indicatorTypeMeta, rowGroup, $event.key);
                }
                if ($event.key === 'F2') {
                    this.setEditingCell(indicatorLevel, colIndex, indicatorTypeMeta, rowGroup, '');
                }
                if ($event.key === 'Delete' && !indicatorTypeMeta.formula) {
                    this.pstReportStructureService.storeIndicatorValue(this.model, reportIndicator, indicatorTypeMeta, indicatorLevel, undefined);
                }
            }
        }
    };
    PstBranchBuyDealEditComponent.prototype.getFocused = function (indicatorLevel, colIndex, rowGroup) {
        return this.model.__cl_focusedBranch === this.branch &&
            this.model.__cl_focusedRowGroup === rowGroup &&
            this.model.__cl_focusedIndicatorLevel === indicatorLevel &&
            this.model.__cl_focusedIndicatorColIndex === colIndex;
    };
    PstBranchBuyDealEditComponent.prototype.getEditing = function (indicatorLevel, colIndex, rowGroup) {
        return this.model.__cl_editingBranch === this.branch &&
            this.model.__cl_editingRowGroup === rowGroup &&
            this.model.__cl_editingIndicatorLevel === indicatorLevel &&
            this.model.__cl_editingIndicatorColIndex === colIndex;
    };
    PstBranchBuyDealEditComponent.prototype.setProposedCellValueStr = function (indicatorLevel, reportIndicator, indicatorTypeMeta, strValue) {
        if (!reportIndicator || !indicatorTypeMeta || !indicatorLevel) {
            return;
        }
        var code = this.pstReportStructureService.getIndicatorValueCode(this.branch, indicatorLevel, reportIndicator, indicatorTypeMeta);
        if (!this.model.values[code]) {
            this.model.values[code] = {
                date: reportIndicator.indicatorDate,
                indicatorTypeId: indicatorTypeMeta.id,
                regionGroupId: indicatorLevel.regionGroupId,
                regionId: indicatorLevel.regionId,
                kfhType: indicatorLevel.kfhType,
                agentId: indicatorLevel.agentId,
            };
        }
        if (strValue) {
            var parsedVal = NumericHelper.roundDecimal(parseFloat(strValue), indicatorTypeMeta.precision);
            this.model.values[code].proposedValue = parsedVal;
        }
        else {
            this.model.values[code].proposedValue = undefined;
        }
    };
    PstBranchBuyDealEditComponent.prototype.onTableCtnrScroll = function ($event) {
        this.scrollLeft = this.tableCtnr.nativeElement.scrollLeft;
    };
    PstBranchBuyDealEditComponent.prototype.buyDealChanged = function (newValue, indicatorLevel, buyDealMeta, parentDealCollection) {
        var _this = this;
        // реентерабельности тут нету - пока не сохранился предыдущий - новый не даем сохранять
        if (this.model.__cl_storing_indicator) {
            return false;
        }
        var dataToStore = {};
        Object.assign(dataToStore, indicatorLevel.buyDealData);
        dataToStore[buyDealMeta.fieldName] = newValue;
        var confirmObs = dataToStore.dateDeal
            ? of(true)
            : this.alertService.confirmModal('Удаление даты сделки приведет к её удалению. Продолжить?');
        confirmObs.subscribe(function (confirmed) {
            if (confirmed) {
                _this.model.__cl_storing_indicator = true;
                _this.pstReportService.updateDeal(_this.model.reportMeta.id, dataToStore).subscribe({
                    next: function (data) {
                        if (!data.data) {
                            parentDealCollection.splice(parentDealCollection.findIndex(function (x) { return x.buyDealId === indicatorLevel.buyDealId; }), 1);
                        }
                        else {
                            Object.assign(indicatorLevel.buyDealData, data.data);
                            var ix = _this.model.deals.findIndex(function (el) { return el.id === indicatorLevel.buyDealData.id; });
                            if (ix >= 0) {
                                _this.model.deals.splice(ix, 1);
                            }
                            _this.model.deals.push(indicatorLevel.buyDealData);
                        }
                        _this.model.__cl_storing_indicator = false;
                    }, error: function () {
                        _this.model.__cl_storing_indicator = false;
                    }
                });
            }
        });
    };
    return PstBranchBuyDealEditComponent;
}());
export { PstBranchBuyDealEditComponent };
