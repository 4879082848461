
      <div *ngIf="contextFormGroup" class="form compact" [formGroup]="contextFormGroup"
           style="max-height: 60vh; background-color: white; position: relative;">
          <section class="form-block itech-block-normal">
              <div class="form-group">
                  <label for="id" class="required">Ид / Код</label>
                  <label for="id" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                         [class.invalid]="isInvalid(contextFormGroup, 'id')">
                      <input type="text" id="id" class="itech-control-normal" readonly
                             formControlName="id">
                      <app-validation-tooltip
                              [input]="contextFormGroup.controls['id']"></app-validation-tooltip>
                  </label>
                  &nbsp;&nbsp;
                  <label for="code" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                         [class.invalid]="isInvalid(contextFormGroup, 'code')">
                      <input type="text" id="code" placeholder="Код" class="itech-control-m-large"
                             formControlName="code" maxlength="50">
                      <app-validation-tooltip
                              [input]="contextFormGroup.controls['code']"></app-validation-tooltip>
                  </label>
              </div>

              <div class="form-group">
                  <label for="caption" class="required">Наименование</label>
                  <label for="caption" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                         [class.invalid]="isInvalid(contextFormGroup, 'caption')">
                      <input type="text" id="caption" placeholder="Наименование" class="itech-control-xlarge"
                             formControlName="caption" maxlength="255">
                      <app-validation-tooltip
                              [input]="contextFormGroup.controls['caption']"></app-validation-tooltip>
                  </label>
              </div>

              <div class="form-group itech-double-height v-resize">
                  <label for="description" class="required">Описание</label>
                  <label for="description" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                         [class.invalid]="isInvalid(contextFormGroup, 'description')">
                        <textarea type="text" class="itech-control-xlarge itech-text-wall"
                                  id="description" placeholder="Описание"
                                  formControlName="description"></textarea>
                      <app-validation-tooltip
                              [input]="contextFormGroup.controls['description']"></app-validation-tooltip>
                  </label>
              </div>

              <div class="form-group">
                  <label for="periodicity" class="required">Периодичность</label>
                  <app-combo-lookup class="itech-control-xlarge" [contextControlId]="'periodicity'"
                                    [validationTooltipTopLeft]="true"
                                    [contextControlName]="'periodicity'" [lookupName]="'pst-indicator-periodicity'"
                                    [contextFormGroup]="contextFormGroup"></app-combo-lookup>
              </div>

              <div class="form-group">
                  <label for="editableLevel">Редактируемый уровень</label>
                  <app-combo-lookup class="itech-control-xlarge" [contextControlId]="'editableLevel'"
                                    [validationTooltipTopLeft]="true"
                                    [contextControlName]="'editableLevel'" [lookupName]="'pst-indicator-level'"
                                    [contextFormGroup]="contextFormGroup"></app-combo-lookup>
              </div>

              <div class="form-group">
                  <label for="levelFormula" class="required">Формула для агрегации</label>
                  <label for="levelFormula" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md tooltip-top-left"
                         [class.invalid]="isInvalid(contextFormGroup, 'levelFormula')">
                      <input type="text" id="levelFormula" placeholder="Формула" class="itech-control-xlarge"
                             formControlName="levelFormula" maxlength="255">
                      <app-validation-tooltip
                              [input]="contextFormGroup.controls['levelFormula']"></app-validation-tooltip>
                  </label>
              </div>

              <div class="form-group">
                  <label for="precision" class="required">Точность</label>
                  <label for="precision" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md tooltip-top-left"
                         [class.invalid]="isInvalid(contextFormGroup, 'precision')">
                      <input type="text" id="precision" placeholder="0" class="itech-control-normal" maxlength="1"
                             formControlName="precision">
                      <app-validation-tooltip
                              [input]="contextFormGroup.controls['precision']"></app-validation-tooltip>
                  </label>
              </div>

              <div class="form-group itech-group-whole-line">
                  <label for="cumulative"></label>
                  <div class="checkbox-inline">
                      <input type="checkbox" id="cumulative" formControlName="cumulative">
                      <label for="cumulative">Нарастающий итог</label>
                  </div>
                  <div class="checkbox-inline">
                    <input type="checkbox" id="isProtected" formControlName="isProtected">
                    <label for="isProtected">Защищенный</label>
                  </div>
              </div>

              <div class="form-group itech-double-height v-resize">
                  <label for="formula">Формула</label>
                  <label for="formula" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                         [class.invalid]="isInvalid(contextFormGroup, 'formula')">
                        <textarea type="text" class="itech-control-xlarge itech-text-wall"
                                  id="formula" placeholder="Формула"
                                  formControlName="formula"></textarea>
                      <app-validation-tooltip
                              [input]="contextFormGroup.controls['formula']"></app-validation-tooltip>
                  </label>
              </div>

              <div class="form-group itech-double-height v-resize">
                  <label for="validateFormula">Условия проверки</label>
                  <label for="validateFormula" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                         [class.invalid]="isInvalid(contextFormGroup, 'validateFormula')">
                        <textarea type="text" class="itech-control-xlarge itech-text-wall"
                                  id="validateFormula" placeholder="Условия"
                                  formControlName="validateFormula"></textarea>
                      <app-validation-tooltip
                              [input]="contextFormGroup.controls['validateFormula']"></app-validation-tooltip>
                  </label>
              </div>

              <div class="form-group">
                  <label for="lvlGroupCaption">Наименование группы подвида</label>
                  <label for="lvlGroupCaption" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md tooltip-top-left"
                         [class.invalid]="isInvalid(contextFormGroup, 'lvlGroupCaption')">
                      <input type="text" id="lvlGroupCaption" placeholder="Класс" class="itech-control-xlarge"
                             formControlName="lvlGroupCaption" maxlength="255">
                      <app-validation-tooltip
                              [input]="contextFormGroup.controls['lvlGroupCaption']"></app-validation-tooltip>
                  </label>
              </div>

              <div class="form-group">
                  <label for="dateDeleted">Время удаления</label>
                  <app-datetime-picker [contextControlName]="'dateDeleted'" [contextFormGroup]="contextFormGroup"
                                       id="dateDeleted"></app-datetime-picker>
              </div>
          </section>
      </div>
  