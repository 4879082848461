import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AppFormGroup } from '../../../ui/controls/app-form-group';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';

@Component({
  selector: 'app-edit-product-path-form',
  templateUrl: './edit-product-path-form.component.html'
})
export class EditProductPathFormComponent {

  @Input() contextFormGroup: AppFormGroup;
  @Input() showAddressControl = true;
  @Input() showWithReloadControl = true;

  rndToken = Math.floor(Math.random() * 1000000);

  isInvalid = FormHelper.isInvalid;

  constructor(private lookupSourceService: LookupSourceService,
              public cdr: ChangeDetectorRef) {
  }

  getTransportationFieldsTitle() {
    switch (+(this.contextFormGroup.controls['transportationKindId'].value)) {
      case 1:
        return '№ машины / полуприцепа / конт.';
      case 3:
        return '№ рейса';
      case 4:
        return '№ контейнера';
      case 5:
      case 6:
        return 'Номер и название судна';
      default:
        return '№ транспортного средства';
    }
  }

  getContainerNoFieldVisible() {
    switch (+(this.contextFormGroup.controls['transportationKindId'].value)) {
      case 1:
        return true;
      default:
        return false;
    }
  }

  getTrailerNoFieldVisible() {
    switch (+(this.contextFormGroup.controls['transportationKindId'].value)) {
      case 1:
        return true;
      default:
        return false;
    }
  }

  getVesselNoHelp() {
    let help = 'Для выбранной страны возможны следующие форматы номеров ТС: \n \n';
    if (this.contextFormGroup.controls['countryId'].value) {
      this.lookupSourceService.getLookup('vessel-no-format/' + this.contextFormGroup.controls['countryId'].value).subscribe(lookup => {
        if (lookup.length > 0) {
          lookup.forEach(item => help = help + ' - ' + item.template + '\n');
          help = help + ' \n ' +
            'Условные обозначения: \n' +
            '1 - любая латинская буква (для РФ буквы Кириллического алфавита из списка: А,В,Е,К,М,Н,О,Р,С,Т,У и Х); \n' +
            '0 - любая цифра; \n' +
            'для некоторых форматов может присутствовать знак тире (-).';
        } else {
          help = 'Для выбранной страны отсутствуют установленные форматы номеров ТС';
        }
      });
    }
    return help;
  }
}
