import { Component, Input, OnChanges, HostBinding } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';

@Component({
  selector: 'app-query-string-filter',
  template: `
    <ng-container [formGroup]="contextFormGroup"  *ngIf="contextFormGroup
    && (!queryUserMode || queryUserMode['_par_' + parameterName + '_filter_mq'])">
      <label [style.background]="filtered?'linear-gradient(to right, #90ff90, white)':undefined">
        <ng-content></ng-content>
        <div class="checkbox checkbox-inline" title="Запросить у пользователя" *ngIf="!queryUserMode">
          <input type="checkbox" id="{{'_par_' + parameterName + '_filter_mq'}}" [(ngModel)]="customFilterChecked"
                 [ngModelOptions]="{standalone: true}">
          <label for="{{'_par_' + parameterName + '_filter_mq'}}">?</label>
        </div>
        <div class="checkbox checkbox-inline" title="Значение отсутствует (не указано)" *ngIf="!queryUserMode">
          <input type="checkbox" id="{{'_par_' + parameterName + '_filter_novalue'}}" [(ngModel)]="noValueChecked"
                 [ngModelOptions]="{standalone: true}">
          <label for="{{'_par_' + parameterName + '_filter_novalue'}}">X</label>
        </div>
      </label>
      <label aria-haspopup="true" role="tooltip"
             class="tooltip tooltip-validation tooltip-md tooltip-right"
             [class.invalid]="isInvalid('_par_' + parameterName)">
        <input *ngIf="!customFilterChecked" [formControlName]="'_par_' + parameterName"
               type="text" class="itech-control-xlarge">
        <app-validation-tooltip
          [input]="contextFormGroup.controls['_par_' + parameterName]"></app-validation-tooltip>
      </label>
    </ng-container>
  `
})
export class QueryStringFilterComponent {
  @Input() contextFormGroup: FormGroup;
  @Input() parameterName: string;
  @Input() queryUserMode: false;
  @HostBinding('attr.class') class = 'form-group';

  isInvalid(cname: string) {
    return FormHelper.isInvalid(this.contextFormGroup, cname);
  }

  get customFilterChecked() {
    return this.contextFormGroup && (+this.contextFormGroup.get('_par_' + this.parameterName + '_filter').value) === 109;
  }

  set customFilterChecked(val: any) {
    if (this.contextFormGroup) {
      this.contextFormGroup.get('_par_' + this.parameterName + '_filter').setValue(val ? 109 : 0);
      this.contextFormGroup.markAsDirty();
    }
  }

  get noValueChecked() {
    return this.contextFormGroup && (+this.contextFormGroup.get('_par_' + this.parameterName + '_filter').value) === 1;
  }

  set noValueChecked(val: any) {
    if (this.contextFormGroup) {
      this.contextFormGroup.get('_par_' + this.parameterName + '_filter').setValue(val ? 1 : 0);
      this.contextFormGroup.markAsDirty();
    }
  }

  get filtered() {
    return !!this.contextFormGroup.get('_par_' + this.parameterName + '_filter').value
      || !!this.contextFormGroup.get('_par_' + this.parameterName).value;
  }
}
