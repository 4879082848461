import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { FormBuilder, Validators } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AnimalDataService } from '../../../logic/services/animal-data.service';
import { SecurityService } from '../../../logic/services/security.service';
import { StringHelper } from '../../../helpers/string-helper';

@Component({
  selector: 'app-slaughtery-view-form',
  templateUrl: './slaughtery-view-form.component.html'
})
export class SlaughteryViewFormComponent {

  @Output() rowClicked = new EventEmitter();
  @Output() selectionChanged = new EventEmitter();
  @Input() model = SlaughteryViewFormComponent.getDefaultModel();

  isInvalid = FormHelper.isInvalid;

  gridColumnDefs = SlaughteryViewFormComponent.getGridColumnDefs();

  public static getGridColumnDefs(addCheckboxSelection = false) {
    return [
      {
        headerName: '№ п/п', field: 'col1', width: 100,
      },
      {
        headerName: 'Дата', field: 'col2', width: 100,
      },
      {
        headerName: 'Вид животных', field: 'col3', width: 100,
      },
      {
        headerName: 'Количество', field: 'col4', width: 100,
      },
      {
        headerName: 'ФИО и адрес', field: 'col5', width: 100,
      },
      {
        headerName: 'ВСД', field: 'col6', width: 100,
      },
      {
        headerName: 'Клинический осмотр', field: 'col7', width: 100,
      },
      {
        headerName: 'Заключение', field: 'col8', width: 100,
      },
      {
        headerName: 'Болезнь', field: 'col9', width: 100,
      },
      {
        headerName: 'Кол-во случаев', field: 'col10', width: 100,
      },
      {
        headerName: 'Бактер и Трих', field: 'col11', width: 100, autoHeight: true
      },
      {
        headerName: 'На тех обраб, кг', field: 'col12', width: 100,
      },
      {
        headerName: 'На обезвреж, кг', field: 'col13', width: 100,
      },
      {
        headerName: 'Без огранич, кг', field: 'col14', width: 100,
      },
      {
        headerName: 'Ветврач', field: 'col15', width: 100,
      },
      {
        headerName: '', hide: true, field: 'col101',
      },
    ];
  }


  public static getDefaultModel(fb?: FormBuilder) {
    return {
      searchResults: [],
      filterFormGroup: fb ? SlaughteryViewFormComponent.buildSearchForm(fb) : undefined
    };
  }

  public static buildSearchForm(fb: FormBuilder) {

    const fg = fb.group({
      institutionId: undefined,
      date: [StringHelper.getISODate(new Date()), Validators.required],
    });

    return fg;
  }

  constructor(private animalDataService: AnimalDataService,
              private globalWaitingOverlayService: GlobalWaitingOverlayService,
              public appNavigationService: AppNavigationService,
              public securityService: SecurityService) {
  }

  refreshResults() {

    if (!this.model.filterFormGroup.valid) {
      return;
    }

    this.globalWaitingOverlayService.StartWaiting();

    this.animalDataService.viewSlaughtery(this.model.filterFormGroup.value)
      .subscribe({
        next: data => {

          this.model.searchResults = data;
          this.selectionChanged.emit([]);
          this.globalWaitingOverlayService.EndWaiting();
        }, error: () => {
          this.globalWaitingOverlayService.EndWaiting();
        }
      });
  }

  filterEnterPressed($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      this.refreshResults();
    }
  }

  onGridSelectionChanged(params: any) {

    const selectedRows = params.api.getSelectedRows();

    const selectedIds = [];

    selectedRows.forEach(row => {
      selectedIds.push(row.col16);
    });

    this.selectionChanged.emit(selectedIds);
  }

  getRowStyle(params) {
    if (params && params.data && params.data.col101) {
      return {color: params.data.col101};
    }
  }
}
