import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { AppFormGroup } from '../../../ui/controls/app-form-group';
import { ServerSideErrorsProvider } from '../../../logic/validators/server-side-errors-provider';
import { FormHelper } from '../../../ui/controls/form-helper';
import { StringHelper } from '../../../helpers/string-helper';
import { EditQuarantineStagesComponent } from './edit-quarantine-stages.component';
import { FormArray, FormBuilder } from '@angular/forms';
import { DateHelper } from '../../../helpers/date-helper';

@Component({
  selector: 'app-edit-quarantine-introduction',
  templateUrl: './edit-quarantine-stages-introduction.component.html',
  styleUrls: ['../edit-quarantine.all.css']
})
export class EditQuarantineStagesIntroductionComponent implements OnChanges {

  @Input() id: number;
  @Input() currentStatusId: number;
  @Input() contextFormGroup: AppFormGroup;
  @Input() serverSideErrorsProvider: ServerSideErrorsProvider;
  @Input() isAccessEdit = false;

  @Output() dirtyFormGroup = new EventEmitter();
  @Output() needUpdateQuarantine = new EventEmitter();

  statusIntroduction = EditQuarantineStagesComponent.QUARANTINE_STAGE.INTRODUCTION;

  isInvalid = FormHelper.isInvalid;

  get orderToExtend() {
    return this.contextFormGroup.get('orderToExtend') as FormArray;
  }

  constructor(private fb: FormBuilder) {
  }

  getFormGroup(order) {
    return order as AppFormGroup;
  }

  ngOnChanges() {
    this.contextFormGroup.valueChanges.subscribe(x => this.dirtyFormGroup.next());
  }

  markAsDirty() {
    this.contextFormGroup.markAsDirty();
    this.dirtyFormGroup.next();
  }

  setNullIfWhitespace(controlName: string) {
    if (StringHelper.isNullOrWhitespace(this.contextFormGroup.get(controlName).value)) {
      this.contextFormGroup.get(controlName).setValue(null);
    }
  }

  addNewOrderToExtend() {
    this.orderToExtend.push(
      this.fb.group(EditQuarantineStagesComponent.buildFormGroupFile(this.fb, this.serverSideErrorsProvider,
      {}, 'orderToExtend-' + this.orderToExtend.length)));
  }

  getDateUploadToString(dateStr: string) {
    return StringHelper.getRuTimeWithSeconds(DateHelper.toDate(dateStr));
  }

  changeNumberDoc(fg: AppFormGroup) {
    if (fg.get('number').value === '') {
      fg.get('number').setValue(null);
    }
  }
}
