import {StringHelper} from './string-helper';

export class DateHelper {

  static millisInDay = 86400000;
  static millisInMinute = 60000;

  static MIN_DATE_STRING = '1970-01-01T00:00:00:000';
  static MAX_DATE_STRING = '9999-12-31T23:59:99:999';

  public static startOfTheWeek(d: any): Date {
    d = new Date(d);
    const day = d.getDay();
    const diff = -day + (day === 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.getTime() + diff * DateHelper.millisInDay);
  }

  public static endOfTheWeek(d: any): any {
    return new Date(DateHelper.startOfTheWeek(d).getTime() + 6 * DateHelper.millisInDay);
  }

  public static endOfTheMonth(year: number, month: number): any {
    month = month + 1;
    if (month > 11) {
      month = 0;
      year = year + 1;
    }
    return DateHelper.endOfTheWeek(DateHelper.addDays(new Date(year, month, 1), -1));
  }

  public static addDays(date: Date, days: number): any {
    return new Date(date.getTime() + days * DateHelper.millisInDay);
  }

  public static addMonths(date: Date, months: number): any {
    const retVal = new Date(date.getTime());

    if (months) {
      months = +months;
      if (!isNaN(months)) {
        retVal.setMonth((date.getMonth() + months) % 12);
        retVal.setFullYear(date.getFullYear() + Math.floor((date.getMonth() + months) / 12));
      }
    }
    return retVal;
  }

  public static addYears(date: Date, years: number): any {
    return new Date(date.getFullYear() + years, date.getMonth(), date.getDate());
  }

  public static addDuration(date: Date, years: number, months: number, days: number) {
    const addedYears = DateHelper.addYears(date, years);
    const addedMonths = DateHelper.addMonths(addedYears, months);
    return DateHelper.addDays(addedMonths, days);
  }

  public static addMinutes(date: Date, minutes: number): any {
    return new Date(date.getTime() + minutes * DateHelper.millisInMinute);
  }

  public static daysBetween(date1: Date, date2: Date): number {
    return Math.floor((date2.getTime() - date1.getTime()) / DateHelper.millisInDay);
  }

  public static daysInMonth(year: number, month: number): number {
    const date = new Date(year, month, 1);
    return DateHelper.daysBetween(date, DateHelper.addMonths(date, 1));
  }

  public static dateOf(date: Date, timeStr: string): Date {
    const d = new Date(date);
    const time = timeStr.match(/(\d+)(?::(\d\d))?(?::(\d\d))?\s*(p?)/);
    d.setHours(parseInt(time[1], 10) + (time[3] ? 12 : 0));
    d.setMinutes(parseInt(time[2], 10) || 0);
    d.setSeconds(parseInt(time[3], 10) || 0, 0);

    return d;
  }

  public static roundDateToMinutes(date: any): Date {
    const d = new Date(date);
    d.setSeconds(0, 0);
    return d;
  }

  public static startOfTheQuarter(date: Date): Date {
    date = new Date(date);
    return new Date(Date.UTC(date.getFullYear(), date.getMonth() - (date.getMonth() % 3), 1));
  }

  public static startOfTheYear(date: Date): Date {
    date = new Date(date);
    return new Date(Date.UTC(date.getFullYear(), 0, 1));
  }

  public static startOfTheMonth(date: Date): Date {
    date = new Date(date);
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), 1));
  }

  public static toLocaleDateString(date: any): string {
    if (!date) { return ''; }
    if (typeof date === 'object') { return date.toLocaleDateString(); }
    return (new Date(date)).toLocaleDateString();
  }

  public static toDate(date: any): Date {
    if (!date) { return null; }
    if (typeof date === 'object') { return date as Date; }
    return (new Date(date));
  }

  public static dateToInt(date: Date): number {
    if (!date) { return null; }

    const fullDaysSinceEpoch = Math.floor(date.getTime() / 8.64e7);
    return fullDaysSinceEpoch;
  }

  public static intToDate(dateInt: number): string {
    if (!dateInt) { return null; }
    const date = new Date(dateInt * 8.64e7);
    return StringHelper.getISODate(date);
  }

  public static endDay(date: Date): Date {
    date = this.toDate(date);

    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
  }

  public static startDay(date: Date): Date {
    date = this.toDate(date);

    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
  }

  public static isAfter(dateCurrent: Date, dateForCompare: Date): number {
    dateCurrent = this.toDate(dateCurrent);
    dateForCompare = this.toDate(dateForCompare);

    const result = dateCurrent.getTime() - dateForCompare.getTime();

    if (result > 0) {
      return 1;
    } else if (result < 0) {
      return -1;
    } else {
      return 0;
    }
  }

  public static endOfTheMonthByDate(d: any): any {
    d = new Date(d);
    const month = d.getMonth();
    const year = d.getFullYear();
    return DateHelper.endOfTheMonth(year, month);
  }

  public static endOfTheYear(date: Date): Date {
    date = new Date(date);
    return new Date(Date.UTC(date.getFullYear(), 12, 31));
  }

  public static startOfTheNextMonth(date: Date): Date {
    date = new Date(date);
    return new Date(Date.UTC(date.getFullYear(), date.getMonth() + 1, 1));
  }

  public static dateComboToStringPrettify(data: any, dateName: string): string {
    if (!data) {
      return '';
    }

    if (!data[dateName] && !data[dateName + 'Year'] &&
        !data[dateName + '2'] && !data[dateName + '2Year']) {
      return '';
    }

    const isInterval = (data[dateName] || data[dateName + 'Year']) &&
                       (data[dateName + '2'] || data[dateName + '2Year']);

    if (!isInterval) {
      return DateHelper.dateComboToStringPrettifyStringValue(data, dateName);
    } else {
      return DateHelper.dateComboToStringPrettifyPeriod(data, dateName);
    }
  }

  private static dateComboToStringPrettifyPeriod(data: any, dateName: string): string {

    if (data[dateName] && data[dateName + '2']) {
      return 'с ' + StringHelper.getRuDate(data[dateName]) + ' по ' + StringHelper.getRuDate(data[dateName + '2']);
    }

    return DateHelper.dateComboToStringPrettifyStringValue(data, dateName) + ' - ' +
           DateHelper.dateComboToStringPrettifyStringValue(data, dateName + '2');
  }

  private static dateComboToStringPrettifyStringValue(data: any, dateName: string): string {
    return data[dateName]
           ? StringHelper.getRuDate(data[dateName])
           : data[dateName + 'Day']
             ? StringHelper.getRuDate(new Date(data[dateName + 'Year'], data[dateName + 'Month'] - 1, data[dateName + 'Day']))
             : new Date(data[dateName + 'Year'], data[dateName + 'Month'] - 1, 1)
                 .toLocaleString('ru', { month: 'long' }) + ' ' + data[dateName + 'Year'] + 'г.';
  }

  public static durationInDays(startDate: Date, endDate: Date, includeLastDay: boolean = false): number {
    startDate = this.toDate(startDate);
    endDate = this.toDate(endDate);

    if (!startDate || !endDate) {
      return null;
    }

    if (includeLastDay) {
      endDate = this.addDays(endDate, 1);
    }

    return Math.floor((endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24));
  }

  // формат возврата '1 мес. 10 дн.'
  public static durationInMonthsAndDays(startDate: Date, endDate: Date, includeLastDay: boolean = false): string {
    startDate = this.toDate(startDate);
    endDate = this.toDate(endDate);

    if (!startDate || !endDate) {
      return '';
    }

    let countMonth = 0;
    let tempDate = this.toDate(startDate);

    while (this.addMonths(tempDate, 1).getTime() - endDate.getTime() <= 0) {
      countMonth += 1;
      tempDate = this.addMonths(tempDate, 1);
    }

    const countDays = this.durationInDays(this.addMonths(startDate, countMonth), endDate, includeLastDay);

    return this.getPluralMonth(countMonth) + ' ' + this.getPluralMonth(countDays);
  }

  // формат возврата '1 г. 1 мес. 10 дн.'
  public static durationYearMonthDay(startDate: Date, endDate: Date, includeLastDay: boolean = false): string {
    startDate = this.toDate(this.addDays(startDate, 1));
    endDate = this.toDate(endDate);

    if (!startDate || !endDate) {
      return '';
    }

    if (this.startDay(endDate).getTime() < this.startDay(startDate).getTime()) {
      return '';
    }

    let countYear = 0;
    let countMonth = 0;
    let tempDate = this.toDate(startDate);

    while (this.addMonths(tempDate, 1).getTime() - endDate.getTime() <= 0) {
      countMonth += 1;
      tempDate = this.addMonths(tempDate, 1);
      if (countMonth % 12 === 0) {
        countYear += 1;
      }
    }

    const countDays = this.durationInDays(this.addMonths(startDate, countMonth), endDate, includeLastDay);
    countMonth = countMonth % (countYear > 0 ? countYear * 12 : 12);

    return this.getPluralYear(countYear) + ' ' + this.getPluralMonth(countMonth) + ' ' + this.getPluralDay(countDays);
  }

  public static getPluralYear(count: number) {
    return count > 0 ? count + this.plural(count, [' год ', ' года ', ' лет ']) : '';
  }

  public static getPluralMonth(count: number) {
    return count > 0 ? count + this.plural(count, [' месяц ', ' месяца ', ' месяцев ']) : '';
  }

  public static getPluralDay(count: number) {
    return count ? count + this.plural(count, [' день ', ' дня ', ' дней ']) : '';
  }

  public static plural(count: number, declension: any[]) {
    const cases = [2, 0, 1, 1, 1, 2];
    const caption = declension[
      count % 100 > 4 && count % 100 < 20
        ? 2
        : cases[(count % 10 < 5) ? count % 10 : 5]];
    return caption;
  }

  public static isExpiredEvent(dateFact: Date, datePlanning: Date): boolean {
    return datePlanning && !dateFact &&
           DateHelper.isAfter(DateHelper.startDay(new Date()), DateHelper.startDay(datePlanning)) > 0;
  }

  public static calcDateByFormula(formulaStr: string, dateStart?: Date): Date {
    if (!formulaStr) {
      return null;
    }

    const formula = formulaStr.split('|');

    if (formula.length < 1) {
      return null;
    }

    let byDate = this.getLocalDateByPrefixCalc(formula[0], dateStart);

    if (!byDate) {
      return null;
    }

    if (formula.length < 2) {
      return byDate;
    }

    for (let i = 1; i < formula.length; i++) {
      byDate = this.getCalcLocalDate(formula[i], byDate);
    }

    return byDate;
  }

  private static getLocalDateByPrefixCalc(prefix: string, dateStart: Date) {
    if (prefix === 'ДатаТек') {
      return dateStart || new Date();
    } else {
      return null;
    }
  }

  private static getCalcLocalDate(settingName: string, date: Date) {
    if (settingName === 'НачалоПредМесяца') {
      return DateHelper.startOfTheMonth(DateHelper.addMonths(date, -1));
    }
    if (settingName === 'КонецПредМесяца') {
      return DateHelper.addDays(DateHelper.startOfTheMonth(date), -1);
    }
    if (settingName === 'НачалоТекМесяца') {
      return DateHelper.startOfTheMonth(date);
    }
    if (settingName === 'КонецТекМесяца') {
      return DateHelper.endOfTheMonthByDate(date);
    }
    if (settingName === 'НачалоТекГода') {
      return DateHelper.startOfTheYear(date);
    }
    if (settingName === 'КонецТекГода') {
      return DateHelper.endOfTheYear(date);
    }
    if (settingName === 'НачалоПредГода') {
      return DateHelper.addYears(DateHelper.startOfTheYear(date), -1);
    }
    if (settingName === 'КонецПредГода') {
      return DateHelper.addDays(DateHelper.startOfTheYear(date), -1);
    }
    if (settingName.startsWith('МинусДней')) {
      const numberStr = settingName.substring(9);
      return DateHelper.addDays(date, (+numberStr || 0) * -1);
    }
    if (settingName.startsWith('МинусМес')) {
      const numberStr = settingName.substring(8);
      return DateHelper.addMonths(date, (+numberStr || 0) * -1);
    }
    if (settingName.startsWith('МинусЛет')) {
      const numberStr = settingName.substring(8);
      return DateHelper.addYears(date, (+numberStr || 0) * -1);
    }
    if (settingName.startsWith('ПлюсДней')) {
      const numberStr = settingName.substring(8);
      return DateHelper.addDays(date, (+numberStr || 0));
    }
    if (settingName.startsWith('ПлюсМес')) {
      const numberStr = settingName.substring(7);
      return DateHelper.addMonths(date, (+numberStr || 0));
    }
    if (settingName.startsWith('ПлюсЛет')) {
      const numberStr = settingName.substring(7);
      return DateHelper.addYears(date, (+numberStr || 0));
    } else {
      return date;
    }
  }
}
