<ng-container [formGroup]="contextFormGroup">

  <div class="form-group">
    <label class="required">Хозяйствующий субъект</label>
    <app-link-button [id]="contextFormGroup.controls['agentId'].value" [lookupName]="'agent'"></app-link-button>
    <button type="button" class="btn btn-danger btn-link itech-inline-tool-btn" title="Выбрать владельца"
            (click)="searchAgent()" [disabled]="!contextFormGroup.controls['__stub__editing'].value? true: undefined">
      <clr-icon shape="search"></clr-icon>
    </button>
  </div>
  <div class="form-group" *ngIf="!contextFormGroup.controls['agentId'].value">
    <label></label>
    <div class="itech-control-desc itech-desc-error">
      Выберите хозяйствующий субъект
    </div>
  </div>
  <div class="form-group">
    <label for="{{rndToken}}dateFrom" class="required">
      Период с / по
    </label>
    <div *ngIf="!contextFormGroup.controls['__stub__editing'].value" class="itech-text-field itech-control-normal">
      {{contextFormGroup.controls['dateFrom'].value | rdate}}
    </div>
    <label for="{{rndToken}}dateFrom" aria-haspopup="true" role="tooltip"
           class="tooltip tooltip-validation tooltip-md"
           *ngIf="contextFormGroup.controls['__stub__editing'].value"
           [class.invalid]="isInvalid(contextFormGroup, 'dateFrom')">
      <input type="date" id="{{rndToken}}dateFrom" class="itech-control-normal"
             formControlName="dateFrom">
      <app-validation-tooltip [input]="contextFormGroup.controls['dateFrom']"></app-validation-tooltip>
    </label>
    <div *ngIf="!contextFormGroup.controls['__stub__editing'].value" class="itech-text-field itech-control-normal">
      {{contextFormGroup.controls['dateTo'].value | rdate}}
    </div>
    <label for="{{rndToken}}dateTo" aria-haspopup="true" role="tooltip"
           class="tooltip tooltip-validation tooltip-md"
           *ngIf="contextFormGroup.controls['__stub__editing'].value"
           [class.invalid]="isInvalid(contextFormGroup, 'dateTo')">
      <input type="date" id="{{rndToken}}dateTo" class="itech-control-normal"
             formControlName="dateTo">
      <app-validation-tooltip [input]="contextFormGroup.controls['dateTo']"></app-validation-tooltip>
    </label>
  </div>

</ng-container>
