<div class="itech-common-wrap no-pager">
  <div class="itech-v-header-content not-scrolled">
    <div class="itech-content-header itech-no-margin-bottom">
      <clr-icon shape="file-group" size="36"></clr-icon>&nbsp;Выходные/отчетные формы
    </div>
    <div style="padding: 5px; height: 90%">
      <app-grid [uniqueGridName]="'ReportsBrowse'" [columnDefs]="gridColumnDefs" [rowData]="_searchResults"
                (rowClicked)="editRow($event.data)" (gridReady)="onGridReady($event)"></app-grid>
    </div>
  </div>

  <div class="itech-form-actions">
    <div>
      <button class="btn btn-link btn-primary" (click)="refreshResults()">
        <clr-icon shape="refresh"></clr-icon>&nbsp;Обновить список
      </button>
      <button class="btn btn-link btn-primary" (click)="editRow({isCustom: true})">
        <clr-icon shape="plus" has-badge="true"></clr-icon>&nbsp;Зарегистрировать новый отчет
      </button>
    </div>
  </div>
</div>


<clr-modal [(clrModalOpen)]="editModalOpened" *ngIf="editModalOpened" [clrModalClosable]="false" [clrModalSize]="'lg'">
  <h3 class="modal-title">Редактирование отчетной формы
    <span *ngIf="currentIsNew" style="color:green"> [Новая]</span></h3>
  <div class="modal-body">
    <div class="form compact" [formGroup]="currentFormGroup">

      <clr-tabs>
        <clr-tab>
          <button clrTabLink>Общее</button>
          <clr-tab-content id="commonTab">
            <div class="edit-page-tab">

              <section class="form-block itech-block-large">

                <div class="form-group">
                  <label for="caption" class="required">Наименование</label>
                  <label for="caption" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                         [class.invalid]="isInvalid(currentFormGroup, 'caption')">
                    <input type="text" id="caption" placeholder="Наименование" class="itech-control-xlarge"
                           formControlName="caption">
                    <app-validation-tooltip [input]="currentFormGroup.controls['caption']"></app-validation-tooltip>
                  </label>
                </div>

                <div class="form-group">
                  <label for="reportKind" class="required">Область применения (код)</label>
                  <label for="reportKind" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                         [class.invalid]="isInvalid(currentFormGroup, 'reportKind')">
                    <input type="text" id="reportKind" placeholder="Область применения" class="itech-control-xlarge"
                           formControlName="reportKind">
                    <app-validation-tooltip
                      [input]="currentFormGroup.controls['reportKind']"></app-validation-tooltip>
                  </label>
                  <div class="itech-control-desc">Введите код области применения шаблона, например 'requestXXX', где
                    XXX - код
                    вида обрашения, для которого применяется данный шаблон
                  </div>
                </div>

                <div class="form-group">
                  <label for="outputFormat" class="required">Выходной формат</label>
                  <app-combo-lookup class="itech-control-xlarge" [contextControlId]="'outputFormat'"
                                    [contextControlName]="'outputFormat'" [lookupName]="'report-output-format'"
                                    [contextFormGroup]="currentFormGroup"></app-combo-lookup>
                </div>

                <div class="form-group">
                  <label for="reportTemplateCustomUri">
                    <div class="radio-inline">
                      <input type="radio" name="isCustom" id="isCustomTrue" formControlName="isCustom" [value]="true">
                      <label for="isCustomTrue">Пользовательский шаблон</label>
                    </div>
                  </label>
                  <label for="reportTemplateCustomUri" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md"
                         [class.invalid]="isInvalid(currentFormGroup, 'reportTemplateCustomUri')">
                    <input type="file" id="reportTemplateCustomUri" placeholder="Шаблон печатной формы"
                           class="itech-control-xlarge" (change)="fileChanged($event)"
                           [attr.disabled]="currentFormGroup.controls['isCustom'].value ? undefined: true">
                  </label>
                  <div *ngIf="currentFormGroup.controls['reportTemplateCustomUri'].value && !newlyEnteredFile"
                       class="itech-control-desc">
                    <clr-icon shape="check-circle" class="is-solid"></clr-icon>
                    &nbsp;Шаблон отчетной формы загружен на сервер
                    &nbsp;<a class="itech-link" (click)="navigateCurrentReportTemplate()">(СКАЧАТЬ)</a>.
                    Выберите другой файл с шаблоном отчетной формы и сохраните форму, чтобы изменить текущий шаблон.
                  </div>
                </div>

                <div class="form-group">
                  <label for="reportTemplateEmbeddedName">
                    <div class="radio-inline">
                      <input type="radio" name="isCustom" id="isCustomFalse" formControlName="isCustom"
                             [value]="false">
                      <label for="isCustomFalse">Встроенный шаблон</label>
                    </div>
                  </label>
                  <label for="reportTemplateEmbeddedName" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md"
                         [class.invalid]="isInvalid(currentFormGroup, 'reportTemplateEmbeddedName')">
                    <input type="text" id="reportTemplateEmbeddedName" placeholder="Наименование встроенного шаблона"
                           class="itech-control-xlarge" formControlName="reportTemplateEmbeddedName"
                           [attr.disabled]="currentFormGroup.controls['isCustom'].value ? true: undefined">
                    <app-validation-tooltip
                      [input]="currentFormGroup.controls['reportTemplateEmbeddedName']"></app-validation-tooltip>
                  </label>
                </div>

                <div class="form-group">
                  <label for="embeddedDatasourceName">Код встроенного источника данных</label>
                  <label for="embeddedDatasourceName" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md tooltip-top-left"
                         [class.invalid]="isInvalid(currentFormGroup, 'embeddedDatasourceName')">
                    <input type="text" id="embeddedDatasourceName" class="itech-control-xlarge"
                           formControlName="embeddedDatasourceName">
                    <app-validation-tooltip
                      [input]="currentFormGroup.controls['embeddedDatasourceName']"></app-validation-tooltip>
                  </label>
                </div>

                <div class="form-group">
                  <label for="jobTypeId">Групповая операция</label>
                  <app-combo-lookup class="itech-control-xlarge" [contextControlId]="'jobTypeId'"
                                    [contextControlName]="'jobTypeId'" [lookupName]="'job-type'"
                                    [validationTooltipTopLeft]="true"
                                    [contextFormGroup]="currentFormGroup"></app-combo-lookup>
                </div>

                <div class="form-group">
                  <label for="groupCaption">Группа отчетов</label>
                  <label for="groupCaption" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md tooltip-top-left"
                         [class.invalid]="isInvalid(currentFormGroup, 'groupCaption')">
                    <input type="text" id="groupCaption" class="itech-control-xlarge"
                           formControlName="groupCaption">
                    <app-validation-tooltip
                      [input]="currentFormGroup.controls['groupCaption']"></app-validation-tooltip>
                  </label>
                </div>


              </section>

            </div>
          </clr-tab-content>
        </clr-tab>

        <clr-tab>
          <button clrTabLink>Скрипты</button>
          <clr-tab-content id="scriptsTab">
            <div class="edit-page-tab">

              <section class="form-block">

                <div class="form-group v-resize itech-height-x4">
                  <label for="scriptFields" class="required">Поля отчета</label>
                  <label for="scriptFields" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                         [class.invalid]="isInvalid(currentFormGroup, 'scriptFields')">
                      <textarea id="scriptFields" placeholder="Скрипт на получение полей отчета"
                                class="itech-control-xlarge itech-text-wall"
                                formControlName="scriptFields"></textarea>
                    <app-validation-tooltip
                      [input]="currentFormGroup.controls['scriptFields']"></app-validation-tooltip>
                  </label>
                </div>

                <div class="form-group v-resize itech-height-x4">
                  <label for="scriptTable0">Таблица№0</label>
                  <label for="scriptTable0" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md"
                         [class.invalid]="isInvalid(currentFormGroup, 'scriptTable0')">
                      <textarea id="scriptTable0" placeholder="Скрипт на получение таблицы №0 отчета"
                                class="itech-control-xlarge itech-text-wall"
                                formControlName="scriptTable0"></textarea>
                    <app-validation-tooltip
                      [input]="currentFormGroup.controls['scriptTable0']"></app-validation-tooltip>
                  </label>
                </div>

                <div class="form-group v-resize itech-height-x4">
                  <label for="scriptTable1">Таблица№1</label>
                  <label for="scriptTable1" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md"
                         [class.invalid]="isInvalid(currentFormGroup, 'scriptTable1')">
                      <textarea id="scriptTable1" placeholder="Скрипт на получение таблицы №1 отчета"
                                class="itech-control-xlarge itech-text-wall"
                                formControlName="scriptTable1"></textarea>
                    <app-validation-tooltip
                      [input]="currentFormGroup.controls['scriptTable1']"></app-validation-tooltip>
                  </label>
                </div>

                <div class="form-group v-resize itech-height-x4">
                  <label for="scriptTable2">Таблица№2</label>
                  <label for="scriptTable2" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md"
                         [class.invalid]="isInvalid(currentFormGroup, 'scriptTable2')">
                      <textarea id="scriptTable2" placeholder="Скрипт на получение таблицы №2 отчета"
                                class="itech-control-xlarge itech-text-wall"
                                formControlName="scriptTable2"></textarea>
                    <app-validation-tooltip
                      [input]="currentFormGroup.controls['scriptTable2']"></app-validation-tooltip>
                  </label>
                </div>

                <div class="form-group v-resize itech-height-x4">
                  <label for="scriptTable3">Таблица№3</label>
                  <label for="scriptTable3" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md"
                         [class.invalid]="isInvalid(currentFormGroup, 'scriptTable3')">
                      <textarea id="scriptTable3" placeholder="Скрипт на получение таблицы №3 отчета"
                                class="itech-control-xlarge itech-text-wall"
                                formControlName="scriptTable3"></textarea>
                    <app-validation-tooltip
                      [input]="currentFormGroup.controls['scriptTable3']"></app-validation-tooltip>
                  </label>
                </div>

                <div class="form-group v-resize itech-height-x4">
                  <label for="scriptTable4">Таблица№4</label>
                  <label for="scriptTable4" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md"
                         [class.invalid]="isInvalid(currentFormGroup, 'scriptTable4')">
                      <textarea id="scriptTable4" placeholder="Скрипт на получение таблицы №4 отчета"
                                class="itech-control-xlarge itech-text-wall"
                                formControlName="scriptTable4"></textarea>
                    <app-validation-tooltip
                      [input]="currentFormGroup.controls['scriptTable4']"></app-validation-tooltip>
                  </label>
                </div>

                <div class="form-group v-resize itech-height-x4">
                  <label for="scriptTable5">Таблица№5</label>
                  <label for="scriptTable5" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md"
                         [class.invalid]="isInvalid(currentFormGroup, 'scriptTable5')">
                      <textarea id="scriptTable5" placeholder="Скрипт на получение таблицы №5 отчета"
                                class="itech-control-xlarge itech-text-wall"
                                formControlName="scriptTable5"></textarea>
                    <app-validation-tooltip
                      [input]="currentFormGroup.controls['scriptTable5']"></app-validation-tooltip>
                  </label>
                </div>

                <div class="form-group v-resize itech-height-x4">
                  <label for="scriptTable6">Таблица№6</label>
                  <label for="scriptTable6" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md"
                         [class.invalid]="isInvalid(currentFormGroup, 'scriptTable6')">
                      <textarea id="scriptTable6" placeholder="Скрипт на получение таблицы №6 отчета"
                                class="itech-control-xlarge itech-text-wall"
                                formControlName="scriptTable6"></textarea>
                    <app-validation-tooltip
                      [input]="currentFormGroup.controls['scriptTable6']"></app-validation-tooltip>
                  </label>
                </div>

                <div class="form-group v-resize itech-height-x4">
                  <label for="scriptTable7">Таблица№7</label>
                  <label for="scriptTable7" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md"
                         [class.invalid]="isInvalid(currentFormGroup, 'scriptTable7')">
                      <textarea id="scriptTable7" placeholder="Скрипт на получение таблицы №7 отчета"
                                class="itech-control-xlarge itech-text-wall"
                                formControlName="scriptTable7"></textarea>
                    <app-validation-tooltip
                      [input]="currentFormGroup.controls['scriptTable7']"></app-validation-tooltip>
                  </label>
                </div>

                <div class="form-group v-resize itech-height-x4">
                  <label for="scriptTable8">Таблица№8</label>
                  <label for="scriptTable8" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md"
                         [class.invalid]="isInvalid(currentFormGroup, 'scriptTable8')">
                      <textarea id="scriptTable8" placeholder="Скрипт на получение таблицы №8 отчета"
                                class="itech-control-xlarge itech-text-wall"
                                formControlName="scriptTable8"></textarea>
                    <app-validation-tooltip
                      [input]="currentFormGroup.controls['scriptTable8']"></app-validation-tooltip>
                  </label>
                </div>

                <div class="form-group v-resize itech-height-x4">
                  <label for="scriptTable9">Таблица№9</label>
                  <label for="scriptTable9" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md"
                         [class.invalid]="isInvalid(currentFormGroup, 'scriptTable9')">
                      <textarea id="scriptTable9" placeholder="Скрипт на получение таблицы №9 отчета"
                                class="itech-control-xlarge itech-text-wall"
                                formControlName="scriptTable9"></textarea>
                    <app-validation-tooltip
                      [input]="currentFormGroup.controls['scriptTable9']"></app-validation-tooltip>
                  </label>
                </div>
              </section>

            </div>
          </clr-tab-content>
        </clr-tab>

        <clr-tab>
          <button clrTabLink>Доп. параметры</button>
          <clr-tab-content id="settingsTab">
            <div class="edit-page-tab">
              <section class="form-block itech-block-tiny" formArrayName="customParameters">
                <ng-container *ngFor="let item of customParameters.controls; index as i" [formGroupName]="i">
                  <div class="form-group">
                    <label style="font-weight: bold; font-size: 14px">
                      {{i+1}}.
                    </label>

                    <label for="code{{i}}" aria-haspopup="true" role="tooltip"
                           class="tooltip tooltip-validation tooltip-md"
                           [class.invalid]="isInvalid(item, 'code')">
                      <input type="text" id="code{{i}}" placeholder="код" class="itech-control-normal"
                             formControlName="code">
                      <app-validation-tooltip [input]="item.controls['code']"></app-validation-tooltip>
                    </label>

                    <label for="displayName{{i}}" aria-haspopup="true" role="tooltip"
                           class="tooltip tooltip-validation tooltip-md"
                           [class.invalid]="isInvalid(item, 'displayName')">
                      <input type="text" id="displayName{{i}}" placeholder="Наимен." class="itech-control-small"
                             formControlName="displayName">
                      <app-validation-tooltip [input]="item.controls['displayName']"></app-validation-tooltip>
                    </label>

                    <label for="dataType{{i}}" aria-haspopup="true" role="tooltip"
                           class="tooltip tooltip-validation tooltip-md"
                           [class.invalid]="isInvalid(item, 'dataType')">
                      <input type="text" id="dataType{{i}}" class="itech-control-small" formControlName="dataType">
                      <app-validation-tooltip [input]="item.controls['dataType']"></app-validation-tooltip>
                    </label>

                    <label for="lookupName{{i}}" aria-haspopup="true" role="tooltip"
                           class="tooltip tooltip-validation tooltip-md"
                           [class.invalid]="isInvalid(item, 'lookupName')">
                      <input type="text" id="lookupName{{i}}" placeholder="код справ." class="itech-control-small"
                             formControlName="lookupName">
                      <app-validation-tooltip [input]="item.controls['lookupName']"></app-validation-tooltip>
                    </label>

                    <label for="defaultValue{{i}}" aria-haspopup="true" role="tooltip"
                           class="tooltip tooltip-validation tooltip-md"
                           [class.invalid]="isInvalid(item, 'defaultValue')">
                      <input type="text" id="defaultValue{{i}}" placeholder="по умолч." class="itech-control-normal"
                             formControlName="defaultValue">
                      <app-validation-tooltip [input]="item.controls['defaultValue']"></app-validation-tooltip>
                    </label>

                    <button type="button" class="btn btn-danger btn-link itech-inline-tool-btn" title="Удалить запись"
                            (click)="deleteCustomParameter(i)">
                      <clr-icon shape="trash"></clr-icon>
                    </button>
                  </div>
                  <div class="form-group itech-group-whole-line">
                    <label>
                    </label>

                    <label for="comments{{i}}" aria-haspopup="true" role="tooltip"
                           class="tooltip tooltip-validation tooltip-md"
                           [class.invalid]="isInvalid(item, 'comments')">
                      <input type="text" id="comments{{i}}" placeholder="Текст примечания" class="itech-control-xlarge"
                             formControlName="comments">
                      <app-validation-tooltip [input]="item.controls['comments']"></app-validation-tooltip>
                    </label>
                  </div>
                </ng-container>

                <div class="form-group itech-group-whole-line">
                  <label></label>
                  <button type="button" class="btn btn-link itech-inline-link-btn" (click)="addCustomParameter()">
                    <clr-icon shape="plus"></clr-icon>
                    Добавить запись
                  </button>
                </div>
              </section>
            </div>
          </clr-tab-content>
        </clr-tab>

        <clr-tab>
          <button clrTabLink>Настраиваемые графы</button>
          <clr-tab-content id="aggBundlesTab">
            <div class="edit-page-tab">
              <section class="form-block itech-block-tiny" formArrayName="customParameters">

                <div class="form-group">
                  <label></label>
                  <app-combo-lookup class="itech-control-xlarge" [contextControlId]="'aggBundleDictCode'"
                                    [contextControlName]="'aggBundleDictCode'" [lookupName]="'report-agg-dict'"
                                    [contextFormGroup]="currentFormGroup"></app-combo-lookup>
                </div>

                <div class="form-group">
                  <label></label>
                  <div class="itech-control-desc">Выберите справочник, из которого будет произведена настройка граф отчета</div>
                </div>

                <ng-container *ngFor="let bundle of aggBundles.controls; index as i" [formGroup]="bundle">
                  <div class="form-group">
                    <label style="font-weight: bold; font-size: 14px">
                      {{i+1}}.
                    </label>

                    <label for="title{{i}}" aria-haspopup="true" role="tooltip"
                           class="tooltip tooltip-validation tooltip-md"
                           [class.invalid]="isInvalid(bundle, 'title')">
                      <input type="text" id="title{{i}}" placeholder="Наим" class="itech-control-normal"
                             formControlName="title">
                      <app-validation-tooltip [input]="bundle.controls['title']"></app-validation-tooltip>
                    </label>

                    <button type="button" class="btn btn-danger btn-link itech-inline-tool-btn" title="Удалить запись"
                            (click)="deleteAggBundle(i)">
                      <clr-icon shape="trash"></clr-icon>
                    </button>
                  </div>

                  <ng-container *ngIf="currentFormGroup.get('aggBundleDictCode').value">
                    <ng-container *ngFor="let bundleItem of bundle.get('codes').controls; index as k" [formGroup]="bundleItem">
                      <div class="form-group" style="margin-left: 32px">
                        <label>
                          {{i+1}}.{{k+1}}.
                        </label>

                        <app-text-lookup class="itech-control-large" [contextControlId]="'bundleItemOneCode' + k + '_' + i"
                                         [contextControlName]="k" [commentsFieldName]="'comments'"
                                         [lookupName]="currentFormGroup.get('aggBundleDictCode').value"
                                         [contextFormGroup]="bundle.get('codes')"></app-text-lookup>

                        <button type="button" class="btn btn-danger btn-link itech-inline-tool-btn"
                                title="Удалить запись"
                                (click)="deleteAggBundleItem(bundle, i)">
                          <clr-icon shape="trash"></clr-icon>
                        </button>
                      </div>
                    </ng-container>

                    <div class="form-group itech-group-whole-line" style="margin-left: 32px">
                      <label></label>
                      <button type="button" class="btn btn-link itech-inline-link-btn"
                              (click)="addAggBundleItem(bundle)">
                        <clr-icon shape="plus"></clr-icon>
                        Добавить запись справочника
                      </button>
                    </div>
                  </ng-container>
                </ng-container>

                <div class="form-group itech-group-whole-line">
                  <label></label>
                  <button type="button" class="btn btn-link itech-inline-link-btn" (click)="addAggBundle()">
                    <clr-icon shape="plus"></clr-icon>
                    Добавить графу (колонку)
                  </button>
                </div>
              </section>
            </div>
          </clr-tab-content>
        </clr-tab>

      </clr-tabs>
    </div>
  </div>
  <div class="modal-footer itech-form-actions">
    <div>
      <button *ngIf="!currentIsNew" type="button" class="btn btn-warning-outline"
              (click)="delete(currentFormGroup.value)">
        <clr-icon shape="trash"></clr-icon>&nbsp;Удалить
      </button>
    </div>
    <div>
      <button type="button" class="btn btn-primary" (click)="addEditCompleted()">
        <clr-icon shape="check"></clr-icon>&nbsp;Сохранить
      </button>
      <button type="button" class="btn btn-warning" (click)="editModalOpened = false">
        <clr-icon shape="undo"></clr-icon>&nbsp;Отмена
      </button>
    </div>
  </div>
</clr-modal>
