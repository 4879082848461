import { ChangeDetectorRef, Component, EventEmitter, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AddressComponent } from '../../../ui/controls/address.component';
import { ServerSideErrorsProvider } from '../../../logic/validators/server-side-errors-provider';
import { serverSideErrorsValidator } from '../../../logic/validators/server-side-errors-validator.directive';
import { AppFormGroup } from '../../../ui/controls/app-form-group';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { AgentSearchModalComponent } from '../../edit-agent/search/agent-search-modal.component';
import { ProductDataService } from '../../../logic/services/product-data.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { StringHelper } from '../../../helpers/string-helper';
import { DateHelper } from '../../../helpers/date-helper';

@Component({
  selector: 'app-edit-product-common-form',
  templateUrl: './edit-product-common-form.component.html'
})
export class EditProductCommonFormComponent {

  @Input() model: any;
  @Input() contextFormGroup: AppFormGroup;
  @Input() serverSideErrorsProvider: ServerSideErrorsProvider;
  @Input() validityControlsEvent = new EventEmitter();
  @Input() changeTransactionTypeEmitter = new EventEmitter();

  isInvalid = FormHelper.isInvalid;
  equalsSome = FormHelper.equalsSome;
  dateComboToStringPrettify = DateHelper.dateComboToStringPrettify;

  public static isTransportTransaction(formGroup: FormGroup) {
    return formGroup && FormHelper.equalsSome(+formGroup.get('transactionType').value, 1, 2);
  }

  public static isTransportOrMovingTransaction(formGroup: FormGroup) {
    return formGroup && FormHelper.equalsSome(+formGroup.get('transactionType').value, 1, 2, 3);
  }

  public static isProductionTransaction(formGroup: FormGroup) {
    return formGroup && +formGroup.get('transactionType').value === 4;
  }

  public static createFormGroup(fb: FormBuilder, serverSideErrorsProvider: ServerSideErrorsProvider, val: any, fromTemplate = false) {

    if (!val.templateVisibility) {
      if (val.templateInstitutionEmployeeId) {
        val.templateVisibility = 3;
      } else if (val.templateInstitutionId) {
        val.templateVisibility = 2;
      } else {
        val.templateVisibility = 1;
      }
    }

    return fb.group({
      id: val.id,
      fromTemplate: fromTemplate,
      status: val.status,
      requestStatus: val.requestStatus,
      requestMercuryNumber: val.requestMercuryNumber,
      createdTime: val.createdTime,
      createdUser: val.createdUser,
      confirmedUser: val.confirmedUser,
      transactionType: [val.transactionType, Validators.compose([Validators.required,
        serverSideErrorsValidator('transactionType', serverSideErrorsProvider)])],
      usePaperDoc: val.usePaperDoc,
      documentTargetType: [val.documentTargetType, Validators.compose([
        FormHelper.conditionalValidate(Validators.required,
          ctrl => ctrl.parent && +ctrl.parent.get('transactionType').value !== 4),
        serverSideErrorsValidator('documentTargetType', serverSideErrorsProvider)])],
      isTemplate: !!val.templateCaption,
      templateVisibility: [val.templateVisibility,
        FormHelper.conditionalValidate(Validators.required, ctrl => ctrl.parent && ctrl.parent.get('isTemplate').value)],
      templateCaption: [val.templateCaption, Validators.compose([
        FormHelper.conditionalValidate(Validators.required, ctrl => ctrl.parent && ctrl.parent.get('isTemplate').value),
        serverSideErrorsValidator('templateCaption', serverSideErrorsProvider)])],
      templateInstitutionId: [val.templateInstitutionId,
        serverSideErrorsValidator('templateInstitutionId', serverSideErrorsProvider)],
      templateInstitutionEmployeeId: [val.templateInstitutionEmployeeId,
        serverSideErrorsValidator('templateInstitutionEmployeeId', serverSideErrorsProvider)],
      storageWayId: [val.storageWayId, Validators.compose([
        FormHelper.conditionalValidate(Validators.required, ctrl => ctrl.parent &&
          FormHelper.equalsSome(+ctrl.parent.get('transactionType').value, 1, 2)),
        serverSideErrorsValidator('storageWayId', serverSideErrorsProvider)])],

      originAgentId: val.originAgentId,
      originStableId: val.originStableId,
      brokerAgentId: val.brokerAgentId,
      mercuryUuid: val.mercuryUuid,

      sources: EditProductCommonFormComponent.buildSourcesArray(fb, serverSideErrorsProvider, val.sources,
        !val.id || !val.status || +val.status === -100),
      path: EditProductCommonFormComponent.buildPathArray(fb, serverSideErrorsProvider, val.path),
      transactions: fb.array(val.transactions || []),
      producers: EditProductCommonFormComponent.buildProducersArray(fb, serverSideErrorsProvider, val.producers),

      clearedComment: val.clearedComment,
      deletedTime: val.deletedTime,
      deletedUser: val.deletedUser,
      deletedReason: val.deletedReason,
      manyProducts: EditProductCommonFormComponent.buildManyProductsArray(fb, val.manyProducts),
      transportDocuments: EditProductCommonFormComponent.buildTransportDocumentsArray(fb, val.transportDocuments, fromTemplate),
      regionalizationRouteSections: EditProductCommonFormComponent
        .buildRegionalizationRouteSectionsArray(fb, serverSideErrorsProvider, val.regionalizationRouteSections),
      hasRequirements: (val.path || []).some(p => (p.regionalizationRequirements || []).length > 0) || false,
    });
  }

  public static buildSourcesArray(fb: FormBuilder, serverSideErrorsProvider: ServerSideErrorsProvider, val: any[], isDraft?: boolean) {

    if (!val || !val.length) {
      val = [];
    }

    return fb.array(val.map(agentPerson => EditProductCommonFormComponent.buildSource(fb, agentPerson, isDraft)));
  }

  public static buildSource(fb: FormBuilder, source: any, isDraft?: boolean) {

    return fb.group({
      destinationAgentId: [source.destinationAgentId, Validators.compose([
        FormHelper.conditionalValidate(Validators.required,
            ctrl => ctrl.parent &&
                                !ctrl.parent.parent.parent.get('isTemplate').value &&
                                ctrl.parent.parent.parent.get('id').value &&
                                +ctrl.parent.parent.parent.get('transactionType').value !== 4)])],
      destinationStableId: [source.destinationStableId, Validators.compose([
        FormHelper.conditionalValidate(Validators.required,
            ctrl => ctrl.parent &&
                                !ctrl.parent.parent.parent.get('isTemplate').value &&
                                ctrl.parent.parent.parent.get('id').value &&
                                +ctrl.parent.parent.parent.get('transactionType').value !== 4)])],
      templateOriginStableId: source.templateOriginStableId,
      packages: EditProductCommonFormComponent.buildPackagesArray(fb, source.packages, isDraft),
      unitId: [source.unitId, Validators.compose([FormHelper.conditionalValidate(Validators.required,
            ctrl => ctrl.parent &&
                                !ctrl.parent.parent.parent.get('isTemplate').value &&
                                ctrl.parent.parent.parent.get('id').value)])],
      value: [source.value, Validators.compose([FormHelper.conditionalValidate(Validators.required,
        ctrl => ctrl.parent &&
                            !ctrl.parent.parent.parent.get('isTemplate').value &&
                            ctrl.parent.parent.parent.get('id').value),
        FormHelper.conditionalValidate(FormHelper.validateMoneyThreeDecimal(),
          ctrl => ctrl.parent &&
                              !ctrl.parent.parent.parent.get('isTemplate').value &&
                              ctrl.parent.parent.parent.get('id').value)])],
      currentValue: source.currentValue,
      acceptValue: source.acceptValue,
      productTypeId: [source.productTypeId, Validators.compose([FormHelper.conditionalValidate(Validators.required,
        ctrl => ctrl.parent &&
                            !ctrl.parent.parent.parent.get('isTemplate').value &&
                            ctrl.parent.parent.parent.get('id').value)])],
      productSubtypeId: [source.productSubtypeId, Validators.compose([FormHelper.conditionalValidate(Validators.required,
        ctrl => ctrl.parent &&
                            !ctrl.parent.parent.parent.get('isTemplate').value &&
                            ctrl.parent.parent.parent.get('id').value)])],
      stableProductSubtypeId: source.stableProductSubtypeId,
      manufactureDateKind: source.manufactureDateKind || 0,
      manufactureDate: [source.manufactureDate, Validators.compose([FormHelper.validateDateTimePicker()])],
      manufactureDateYear: [source.manufactureDateYear,
        Validators.compose([Validators.min(1917), Validators.max(2100)])],
      manufactureDateMonth: [source.manufactureDateMonth, Validators.compose([Validators.min(1), Validators.max(12)])],
      manufactureDateDay: [source.manufactureDateDay, Validators.compose([Validators.min(1), Validators.max(31)])],
      manufactureDateHour: [source.manufactureDateHour, Validators.compose([Validators.min(0), Validators.max(23)])],
      manufactureDate2: [source.manufactureDate2, Validators.compose([FormHelper.validateDateTimePicker()])],
      manufactureDate2Year: [source.manufactureDate2Year,
        Validators.compose([Validators.min(1917), Validators.max(2100)])],
      manufactureDate2Month: [source.manufactureDate2Month, Validators.compose([Validators.min(1), Validators.max(12)])],
      manufactureDate2Day: [source.manufactureDate2Day, Validators.compose([Validators.min(1), Validators.max(31)])],
      manufactureDate2Hour: [source.manufactureDate2Hour, Validators.compose([Validators.min(0), Validators.max(23)])],
      expireDateKind: source.expireDateKind || 0,
      expireDate: [source.expireDate, Validators.compose([FormHelper.validateDateTimePicker()])],
      expireDateYear: [source.expireDateYear,
        Validators.compose([Validators.min(1917), Validators.max(2100)])],
      expireDateMonth: [source.expireDateMonth,
        Validators.compose([Validators.min(1), Validators.max(12)])],
      expireDateDay: [source.expireDateDay,
        Validators.compose([Validators.min(1), Validators.max(31)])],
      expireDateHour: [source.expireDateHour,
        Validators.compose([Validators.min(0), Validators.max(23)])],
      expireDate2: [source.expireDate2,
        Validators.compose([FormHelper.validateDateTimePicker()])],
      expireDate2Year: [source.expireDate2Year,
        Validators.compose([Validators.min(1917), Validators.max(2100)])],
      expireDate2Month: [source.expireDate2Month,
        Validators.compose([Validators.min(1), Validators.max(12)])],
      expireDate2Day: [source.expireDate2Day,
        Validators.compose([Validators.min(1), Validators.max(31)])],
      expireDate2Hour: [source.expireDate2Hour,
        Validators.compose([Validators.min(0), Validators.max(23)])],
      perishable: source.perishable,
      expertiseStatus: [source.expertiseStatus, Validators.compose([
        FormHelper.conditionalValidate(Validators.required,
          ctrl => ctrl.parent && !ctrl.parent.parent.parent.get('isTemplate').value)])],
      comments: source.comments,
      productReasonId: [source.productReasonId, Validators.compose([FormHelper.conditionalValidate(Validators.required,
        ctrl => ctrl.parent &&
                            !ctrl.parent.parent.parent.get('isTemplate').value &&
                            ctrl.parent.parent.parent.get('id').value &&
                            +ctrl.parent.parent.parent.get('transactionType').value !== 4)])],
      badQuality: source.badQuality,
      shipmentNo: source.shipmentNo,
      protectedDocSerial: source.protectedDocSerial,
      protectedDocNo: source.protectedDocNo,
      mercuryDocNo: source.mercuryDocNo,
      ttnNo: source.ttnNo,
      ttnSerial: source.ttnSerial,
      needMainTtn: source.needMainTtn || true,
      useRestriction: source.useRestriction,
      originCountry: source.originCountry,
      ttnDate: [source.ttnDate, Validators.compose([FormHelper.validateDateTimePicker()])],
      ttnTypeId: [source.ttnTypeId, Validators.compose([
        FormHelper.conditionalValidate(Validators.required,
          ctrl => ctrl.parent &&
                              !ctrl.parent.parent.parent.get('isTemplate').value &&
                              +ctrl.parent.parent.parent.get('transactionType').value !== 4 &&
                              ctrl.parent.get('needMainTtn').value)])],
      sourceAnimalIds: fb.array((source.sourceAnimalIds || []).map(el => fb.control(el))),
      sourceProductIds: fb.array((source.sourceProductIds || []).map(el => fb.control(el))),

      cargoInspected: source.cargoInspected,
      locationProsperity: [source.locationProsperity, Validators.compose([
        FormHelper.conditionalValidate(Validators.required,
          ctrl => ctrl.parent && !ctrl.parent.parent.parent.get('isTemplate').value)])],
      animalSpentPeriodCode: source.animalSpentPeriodCode,
      monthsSpent: [source.monthsSpent, Validators.pattern(/^\d{1,9}$/)],
      quarantineDuration: [source.quarantineDuration, Validators.pattern(/^\d{1,9}$/)],
      quarantineComments: source.quarantineComments,
      batchId: fb.array((!source.batchId || !source.batchId.length ? [''] : source.batchId).map(el => fb.control(el))),
      markingTypeId: source.markingTypeId,
      marking: source.marking,
      discrepancyReports: fb.array((source.discrepancyReports || []).map(el =>
        EditProductCommonFormComponent.buildDiscrepancyReports(fb, el))),
    });
  }

  public static buildPathArray(fb: FormBuilder, serverSideErrorsProvider: ServerSideErrorsProvider, val: any[]) {

    if (!val || !val.length) {
      val = [];
    }

    if (val.length < 1) {
      val.push({withReload: true});
    }

    if (val.length < 2) {
      val.push({withReload: false});
    }

    return fb.array(val.map(el => EditProductCommonFormComponent.buildPath(fb, serverSideErrorsProvider, el)));
  }

  public static buildPath(fb: FormBuilder, serverSideErrorsProvider: ServerSideErrorsProvider, path: any) {

    return fb.group({
      addressId: path.addressId,
      withReload: [path.withReload, Validators.compose([Validators.required])],
      transportationKindId: [path.transportationKindId, Validators.compose([
        FormHelper.conditionalValidate(Validators.required, ctrl => ctrl.parent
          && ctrl.parent.get('withReload').value &&
          FormHelper.equalsSome(+ctrl.parent.parent.parent.get('transactionType').value, 1, 2))
      ])],
      vesselNo: [path.vesselNo, Validators.compose([
        FormHelper.conditionalValidate(Validators.required, ctrl => ctrl.parent
          && ctrl.parent.get('withReload').value &&
          FormHelper.equalsSome(+ctrl.parent.parent.parent.get('transactionType').value, 1, 2)),
        serverSideErrorsValidator('vesselNo', serverSideErrorsProvider)])],
      trailerNo: [path.trailerNo],
      containerNo: [path.containerNo],
      productId: path.productId,
      countryId: [path.productId ? path.countryId : 'RUS', Validators.compose([
          FormHelper.conditionalValidate(Validators.required, ctrl => ctrl.parent
            && ctrl.parent.get('withReload').value &&
            FormHelper.equalsSome(+ctrl.parent.get('transportationKindId').value, 1) &&
            FormHelper.equalsSome(+ctrl.parent.parent.parent.get('transactionType').value, 1, 2))],
      )],
      address: fb.group(AddressComponent.buildFormGroupDef(fb, serverSideErrorsProvider, path.address, false)),
      regionalizationRequirements: EditProductCommonFormComponent.buildRegionalizationRequirementsArray(
        fb, serverSideErrorsProvider, path.regionalizationRequirements),
      pointCaption: [path.pointCaption],
    });
  }

  public static buildRegionalizationRouteSectionsArray(fb: FormBuilder, serverSideErrorsProvider: ServerSideErrorsProvider, val: any[]) {
    if (!val || !val.length) {
      val = [];
    }

    return fb.array(val.map(el => EditProductCommonFormComponent.buildRegionalizationRouteSections(fb, serverSideErrorsProvider, el)));
  }

  public static buildRegionalizationRouteSections(fb: FormBuilder, serverSideErrorsProvider: ServerSideErrorsProvider, routeSection: any) {
    return fb.group({
      addressId: routeSection.addressId,
      routeSectionId: routeSection.routeSectionId,
      regionalizationRules: EditProductCommonFormComponent.buildRegionalizationRulesArray(
        fb, serverSideErrorsProvider, routeSection.regionalizationRules),
    });
  }

  public static buildRegionalizationRulesArray(fb: FormBuilder, serverSideErrorsProvider: ServerSideErrorsProvider, val: any[]) {
    if (!val || !val.length) {
      val = [];
    }

    return fb.array(val.map(el => EditProductCommonFormComponent.buildRegionalizationRule(fb, serverSideErrorsProvider, el)));
  }

  public static buildRegionalizationRule(fb: FormBuilder, serverSideErrorsProvider: ServerSideErrorsProvider, rule: any) {
    return fb.group({
      productSubtypeGuid: rule.productSubtypeGuid,
      productSubtypeCaption: rule.productSubtypeCaption,
      decision: rule.decision,
      regionalizationRequirements: EditProductCommonFormComponent.buildRegionalizationRequirementsArray(
        fb, serverSideErrorsProvider, rule.regionalizationRequirements),
    });
  }

  public static buildRegionalizationRequirementsArray(fb: FormBuilder, serverSideErrorsProvider: ServerSideErrorsProvider, val: any[]) {
    if (!val || !val.length) {
      val = [];
    }

    return fb.array(val.map(el => EditProductCommonFormComponent.buildRegionalizationRequirements(fb, serverSideErrorsProvider, el)));
  }

  public static buildRegionalizationRequirements(fb: FormBuilder, serverSideErrorsProvider: ServerSideErrorsProvider, requirement: any) {
    return fb.group({
      diseaseTypeId: requirement.diseaseTypeId,
      decision: requirement.decision,
      conditionGroups: EditProductCommonFormComponent.buildRegionalizationGroupsArray(
        fb, serverSideErrorsProvider, requirement.conditionGroups),
    });
  }

  public static buildRegionalizationGroupsArray(fb: FormBuilder, serverSideErrorsProvider: ServerSideErrorsProvider, val: any[]) {
    if (!val || !val.length) {
      val = [];
    }

    return fb.array(val.map(el => EditProductCommonFormComponent.buildRegionalizationGroups(fb, serverSideErrorsProvider, el)));
  }

  public static buildRegionalizationGroups(fb: FormBuilder, serverSideErrorsProvider: ServerSideErrorsProvider, group: any) {
    return fb.group({
      acceptedGroup: group.acceptedGroup || false,
      conditionResults: EditProductCommonFormComponent.buildRegionalizationResultsArray(
        fb, serverSideErrorsProvider, group.conditionResults),
    });
  }

  public static buildRegionalizationResultsArray(fb: FormBuilder, serverSideErrorsProvider: ServerSideErrorsProvider, val: any[]) {
    if (!val || !val.length) {
      val = [];
    }

    return fb.array(val.map(el => EditProductCommonFormComponent.buildRegionalizationResults(fb, serverSideErrorsProvider, el)));
  }

  public static buildRegionalizationResults(fb: FormBuilder, serverSideErrorsProvider: ServerSideErrorsProvider, result: any) {
    return fb.group({
      conditionId: result.conditionId,
    });
  }

  public static buildProducersArray(fb: FormBuilder, serverSideErrorsProvider: ServerSideErrorsProvider, val: any[]) {

    if (!val || !val.length) {
      val = [];
    }

    return fb.array(val.map(el => EditProductCommonFormComponent.buildProducers(fb, serverSideErrorsProvider, el)));
  }

  public static buildProducers(fb: FormBuilder, serverSideErrorsProvider: ServerSideErrorsProvider, path: any) {

    return fb.group({
      stableId: path.stableId,
      role: path.role,
    });
  }

  public static buildPackagesArray(fb: FormBuilder, val: any[], isDraft?: boolean) {

    if (!val || !val.length) {
      val = isDraft ? [] : [{}];
    }

    if (isDraft) {
      val = [...val, {}];
    }

    return fb.array(val.map(pack => EditProductCommonFormComponent.buildPackage(fb, pack, isDraft)));
  }

  public static buildPackage(fb: FormBuilder, pack: any, isDraft?: boolean) {
    return fb.group({
      packageLevelId: pack.packageLevelId,
      packageTypeId: [pack.packageTypeId,
        FormHelper.conditionalValidate(Validators.required, ctrl => ctrl.parent && ctrl.parent.get('packageLevelId').value)],
      count: [pack.count, Validators.compose([FormHelper.conditionalValidate(Validators.pattern(/^\d{1,10}$/),
            ctrl => ctrl.parent && ctrl.parent.get('packageLevelId').value),
        FormHelper.conditionalValidate(Validators.required, ctrl => ctrl.parent && ctrl.parent.get('packageLevelId').value)])],
      currentCount: pack.currentCount,
      markings: fb.array((isDraft
                            ? [...(pack.markings || []), {}]
                            : pack.markings || [])
                         .map(x => EditProductCommonFormComponent.buildMarking(fb, x))),
      expandedMark: false,
    });
  }

  public static buildMarking(fb: FormBuilder, mark: any) {
    return fb.group({
      markingTypeId: [mark.markingTypeId,
        FormHelper.conditionalValidate(Validators.required, ctrl => ctrl.parent && ctrl.parent.get('marking').value)],
      marking: [mark.marking,
        FormHelper.conditionalValidate(Validators.required, ctrl => ctrl.parent && ctrl.parent.get('markingTypeId').value)],
    });
  }

  public static buildManyProductsArray(fb: FormBuilder, val: any[]) {
    if (!val || !val.length) {
      val = [];
    }

    return fb.array(val.map(manyProduct => EditProductCommonFormComponent.buildManyProductsGroupDefs(fb, manyProduct)));
  }

  public static buildManyProductsGroupDefs(fb: FormBuilder, data: any = {}): FormGroup {
    const fg = fb.group({
      stableId: data.stableId,
      sourceBatches: fb.array([]),
      producedBatches: fb.array([]),
      expanded: false,
    });

    if (data.producedBatches && data.producedBatches.length) {
      data.producedBatches.forEach(produced => {
        produced.stableId = data.stableId;
        (fg.get('producedBatches') as FormArray).push(fb.group(EditProductCommonFormComponent.buildProductBatch(fb, produced)));
      });
    } else {
      const manDate = DateHelper.startDay(new Date());
      (fg.get('producedBatches') as FormArray)
        .push(fb.group(EditProductCommonFormComponent.buildProductBatch(fb,
          {
            stableId: data.stableId,
            manufactureDateKind: 1,
            manufactureDate: StringHelper.getISODateWithHourMinute(manDate),
            manufactureDateYear: manDate.getFullYear(),
            manufactureDateMonth: manDate.getMonth() + 1,
            manufactureDateDay: manDate.getDate(),
            expireDateKind: 1,
          })));
    }

    if (data.sourceBatches && data.sourceBatches.length) {
      data.sourceBatches.forEach(produced =>
        (fg.get('sourceBatches') as FormArray).push(fb.group(EditProductCommonFormComponent.buildSourceBatch(fb, produced))));
    } else {
      (fg.get('sourceBatches') as FormArray).push(fb.group(EditProductCommonFormComponent.buildSourceBatch(fb)));
    }

    return fg;
  }

  public static buildProductBatch(fb: FormBuilder, data: any = {}) {
    return {
      stableId: data.stableId,
      stableProductSubtypeId: data.stableProductSubtypeId,
      animalIds: fb.array(data.animalIds || []),
      isAnimalProductKind: data.isAnimalProductKind || false,
      valueCount: [data.valueCount, FormHelper.validateMoneyThreeDecimal()],
      unitId: data.unitId,
      packages: EditProductCommonFormComponent.buildPackagesArray(fb, data.packages, true),
      isDraft: true,
      batchId: data.batchId,
      manufactureDateKind: data.manufactureDateKind || 0,
      manufactureDate: [data.manufactureDate, Validators.compose([FormHelper.validateDateTimePicker()])],
      manufactureDateYear: [data.manufactureDateYear,
        Validators.compose([Validators.min(1917), Validators.max(2100)])],
      manufactureDateMonth: [data.manufactureDateMonth,
        Validators.compose([Validators.min(1), Validators.max(12)])],
      manufactureDateDay: [data.manufactureDateDay,
        Validators.compose([Validators.min(1), Validators.max(31)])],
      manufactureDateHour: [data.manufactureDateHour,
        Validators.compose([Validators.min(0), Validators.max(23)])],
      manufactureDate2: [data.manufactureDate2,
        Validators.compose([FormHelper.validateDateTimePicker()])],
      manufactureDate2Year: [data.manufactureDate2Year,
        Validators.compose([Validators.min(1917), Validators.max(2100)])],
      manufactureDate2Month: [data.manufactureDate2Month,
        Validators.compose([Validators.min(1), Validators.max(12)])],
      manufactureDate2Day: [data.manufactureDate2Day,
        Validators.compose([Validators.min(1), Validators.max(31)])],
      manufactureDate2Hour: [data.manufactureDate2Hour,
        Validators.compose([Validators.min(0), Validators.max(23)])],
      expireDateKind: data.expireDateKind || 0,
      expireDate: [data.expireDate, Validators.compose([FormHelper.validateDateTimePicker()])],
      expireDateYear: [data.expireDateYear,
        Validators.compose([Validators.min(1917), Validators.max(2100)])],
      expireDateMonth: [data.expireDateMonth,
        Validators.compose([Validators.min(1), Validators.max(12)])],
      expireDateDay: [data.expireDateDay,
        Validators.compose([Validators.min(1), Validators.max(31)])],
      expireDateHour: [data.expireDateHour,
        Validators.compose([Validators.min(0), Validators.max(23)])],
      expireDate2: [data.expireDate2,
        Validators.compose([FormHelper.validateDateTimePicker()])],
      expireDate2Year: [data.expireDate2Year,
        Validators.compose([Validators.min(1917), Validators.max(2100)])],
      expireDate2Month: [data.expireDate2Month,
        Validators.compose([Validators.min(1), Validators.max(12)])],
      expireDate2Day: [data.expireDate2Day,
        Validators.compose([Validators.min(1), Validators.max(31)])],
      expireDate2Hour: [data.expireDate2Hour,
        Validators.compose([Validators.min(0), Validators.max(23)])],
      perishable: data.perishable || false,
    };
  }

  private static buildSourceBatch(fb: FormBuilder, data: any = {}) {
    return {
      productId: data.productId,
      writeOff: [data.writeOff, FormHelper.validateMoneyThreeDecimal()],
    };
  }

  public static buildTransportDocumentsArray(fb: FormBuilder, val: any[], fromTemplate = false) {
    if (!val || !val.length) {
      val = [{}];
    }

    return fb.array(val.map(doc => EditProductCommonFormComponent.buildTransportDocumentsGroupDefs(fb, doc, fromTemplate)));
  }

  public static buildTransportDocumentsGroupDefs(fb: FormBuilder, data: any = {}, fromTemplate?: boolean) {
    return fb.group({
      productId: data.productId,
      stableId: data.stableId,
      valueCount: [data.valueCount, FormHelper.validateMoneyThreeDecimal()],
      stableProductSubtypeId: data.stableProductSubtypeId,
      stableProductSubtypeIdSource: data.stableProductSubtypeId,
      productTypeId: data.productTypeId,
      destinationAgentId: data.destinationAgentId,
      destinationStableId: data.destinationStableId,
      packages: EditProductCommonFormComponent.buildPackagesArray(fb, data.packages, true),
      protectedDocSerial: data.protectedDocSerial,
      protectedDocNo: data.protectedDocNo,
      expertiseStatus: data.expertiseStatus,
      cargoInspected: data.cargoInspected,
      locationProsperity: data.locationProsperity,
      animalSpentPeriodCode: data.animalSpentPeriodCode,
      monthsSpent: data.monthsSpent,
      quarantineDuration: data.quarantineDuration,
      quarantineComments: data.quarantineComments,
      badQuality: data.badQuality,
      productReasonId: data.productReasonId,
      comments: data.comments,
      isDraft: true,
      loaded: data.loaded || fromTemplate,
      otherTtn: data.otherTtn || false,
      ttnNo: data.ttnNo,
      ttnSerial: data.ttnSerial,
      ttnDate: [data.ttnDate, Validators.compose([FormHelper.validateDateTimePicker()])],
      ttnTypeId: [data.ttnTypeId, Validators.compose([
        FormHelper.conditionalValidate(Validators.required,
          ctrl => ctrl.parent && ctrl.parent.get('otherTtn').value &&
            +ctrl.parent.parent.parent.get('transactionType').value !== 4)])],
    });
  }

  public static buildDiscrepancyReports(fb: FormBuilder, data: any) {

    return fb.group({
      productId: data.productId,
      productSourceId: data.productSourceId,
      id: data.id,
      date: data.date,
      serial: data.serial,
      number: data.number,
      reason: data.reason,
      caption: data.caption,
      originStableProductSubtypeId: data.originStableProductSubtypeId,
      stableProductSubtypeId: data.stableProductSubtypeId,
      originVolume: data.originVolume,
      volume: data.volume,
      originExpireDateKind: data.originExpireDateKind,
      originExpireDate: data.originExpireDate,
      originExpireDateYear: data.originExpireDateYear,
      originExpireDateMonth: data.originExpireDateMonth,
      originExpireDateDay: data.originExpireDateDay,
      originExpireDateHour: data.originExpireDateHour,
      originExpireDate2: data.originExpireDate2,
      originExpireDate2Year: data.originExpireDate2Year,
      originExpireDate2Month: data.originExpireDate2Month,
      originExpireDate2Day: data.originExpireDate2Day,
      originExpireDate2Hour: data.originExpireDate2Hour,
      expireDateKind: data.expireDateKind,
      expireDate: data.expireDate,
      expireDateYear: data.expireDateYear,
      expireDateMonth: data.expireDateMonth,
      expireDateDay: data.expireDateDay,
      expireDateHour: data.expireDateHour,
      expireDate2: data.expireDate2,
      expireDate2Year: data.expireDate2Year,
      expireDate2Month: data.expireDate2Month,
      expireDate2Day: data.expireDate2Day,
      expireDate2Hour: data.expireDate2Hour,
      originTtnNo: data.originTtnNo,
      originTtnSerial: data.originTtnSerial,
      originTtnDate: data.originTtnDate,
      ttnSerial: data.ttnSerial,
      ttnNo: data.ttnNo,
      ttnDate: data.ttnDate,
      originVesselNo: data.originVesselNo,
      originTrailerNo: data.originTrailerNo,
      originContainerNo: data.originContainerNo,
      vesselNo: data.vesselNo,
      trailerNo: data.trailerNo,
      containerNo: data.containerNo,
    });
  }

  public static prettifyFormGroup(fg: FormGroup) {
  }

  constructor(private fb: FormBuilder,
              public appNavigationService: AppNavigationService,
              public globalWaitingOverlayService: GlobalWaitingOverlayService,
              public productDataService: ProductDataService,
              private lookupSourceService: LookupSourceService,
              private cdr: ChangeDetectorRef) {
  }

  public get regionalizationRouteSections(): FormArray {
    return this.contextFormGroup.get('regionalizationRouteSections') as FormArray;
  }

  public get sources(): FormArray {
    return this.contextFormGroup.get('sources') as FormArray;
  }

  public get path(): FormArray {
    return this.contextFormGroup.get('path') as FormArray;
  }

  public get producers(): FormArray {
    return this.contextFormGroup.get('producers') as FormArray;
  }

  public get manyProducts(): FormArray {
    return this.contextFormGroup.get('manyProducts') as FormArray;
  }

  public get requestStatus(): number {
    return +this.contextFormGroup.get('requestStatus').value;
  }

  public addPath() {
    const fg = EditProductCommonFormComponent.buildPath(this.fb, this.serverSideErrorsProvider, {withReload: false});
    this.path.insert(this.path.length - 1, fg);
    this.path.markAsDirty();
  }

  public deletePath(i: number) {
    this.path.removeAt(i);
    this.path.markAsDirty();
  }

  public addSource() {
    const fg = EditProductCommonFormComponent.buildSource(this.fb,
      {ttnTypeId: 0, manufactureDate: 'T00:00', expireDate: 'T00:00', originCountry: 'RUS'});
    this.sources.push(fg);
    this.sources.markAsDirty();
  }

  public deleteSource(i: number) {
    this.sources.removeAt(i);
    this.sources.markAsDirty();
    if (!this.sources.length) {
      while (this.manyProducts.length) {
        this.manyProducts.removeAt(0);
      }
      this.addSource();
    }
  }

  getAllSourceAnimals() {
    const animals = [];
    for (const sourceFg of (this.contextFormGroup.get('sources') as FormArray).controls) {
      for (const sourceAnimalFg of (sourceFg.get('sourceAnimalIds') as FormArray).controls) {
        animals.push(sourceAnimalFg);
      }
    }
    return animals;
  }

  getAllDestinationStables() {
    const stables = [];
    for (const sourceFg of (this.contextFormGroup.get(this.contextFormGroup.get('id').value ? 'sources' : 'transportDocuments') as FormArray).controls) {
      if (sourceFg.get('destinationStableId').value && !stables.some(x => x.value === sourceFg.get('destinationStableId').value)) {
        stables.push(sourceFg.get('destinationStableId'));
      }
    }
    return stables;
  }

  isTransportTransaction() {
    return EditProductCommonFormComponent.isTransportTransaction(this.contextFormGroup);
  }

  isTransportOrMovingTransaction() {
    return EditProductCommonFormComponent.isTransportOrMovingTransaction(this.contextFormGroup);
  }

  changeTransactionType() {
    // что за cdr и зачем он нужен тут - #15797
    this.cdr.detach();
    if (this.contextFormGroup.get('transactionType').value == 4) {
      this.contextFormGroup.get('documentTargetType').setValue(2); // на отправителя
    }
    this.changeTransactionTypeEmitter.emit();
    this.contextFormGroup.updateValueAndValidity();
    this.cdr.detectChanges();
    this.cdr.reattach();
  }

  getRndToken(): string {
    return this.contextFormGroup.get('id').value
      ? this.contextFormGroup.get('id').value
      : this.model && this.model.randomUniqueTag ? this.model.randomUniqueTag : Math.floor(Math.random() * 1000000);
  }

  searchBrokerAgent() {
    this.appNavigationService.searchAgent(AgentSearchModalComponent).subscribe(val => {
      if (val) {
        this.contextFormGroup.get('brokerAgentId').setValue(val);
        this.contextFormGroup.markAsDirty();
      }
    });
  }

  removeBroker() {
    this.contextFormGroup.controls['brokerAgentId'].setValue(undefined);
    this.contextFormGroup.markAsDirty();
  }

  checkRegionalization() {
    this.globalWaitingOverlayService.StartWaiting();

    const sections = this.contextFormGroup.get('regionalizationRouteSections') as FormArray;
    while (sections.length > 0) {
      sections.removeAt(0);
    }
    this.productDataService.checkRegionalization(this.contextFormGroup.value).subscribe(val => {
      val.forEach(el => {
        (this.contextFormGroup.get('regionalizationRouteSections') as FormArray)
          .push(EditProductCommonFormComponent.buildRegionalizationRouteSections(this.fb, this.serverSideErrorsProvider, el));
      });

      this.contextFormGroup.get('hasRequirements').setValue(val.some(el => (el.regionalizationRules || []).some(r => +r.decision === 2)));

      FormHelper.setSingleFormGroupServerSideValidationErrors({}, this.model, this.contextFormGroup);
      this.lookupSourceService.invalidateLookup('regionalization-condition');
      this.globalWaitingOverlayService.EndWaiting();
    }, error => {
      FormHelper.setSingleFormGroupServerSideValidationErrors(error, this.model, this.contextFormGroup);
      this.globalWaitingOverlayService.EndWaiting();
    });
  }

  markAsDirty() {
    this.contextFormGroup.markAsDirty();
  }

  changeAcceptedGroup(group: FormGroup, requirement: FormGroup) {
    if (group.get('acceptedGroup').value) {
      (requirement.get('conditionGroups') as FormArray).controls.forEach(g => {
        g.get('acceptedGroup').setValue(false);
      });
      group.get('acceptedGroup').setValue(true);
    }
  }
}
