import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { AnimalSearchModalComponent } from './search/animal-search-modal.component';
import { AppNavigationService } from '../../logic/services/app-navigation.service';
import { AddressPersonFioCacheService } from '../../logic/services/address-person-fio-cache.service';

@Component({
  selector: 'app-edit-animal-list',
  templateUrl: './edit-animal-list.component.html'
})
export class EditAnimalListComponent implements OnInit {

  @Input() animals: FormArray;
  @Input() showAddManyAnimal = true;
  @Input() contextFormGroup: FormGroup;
  @Input() showLabel = true;
  @Input() filterSearchParams = {};
  @Input() showDeleteBtn = true;

  constructor(private appNavigationService: AppNavigationService,
              private fb: FormBuilder,
              private cacheService: AddressPersonFioCacheService) {
  }

  ngOnInit() {
  }

  addManyAnimals() {
    this.appNavigationService.searchManyAnimals(AnimalSearchModalComponent, this.filterSearchParams).subscribe(animals => {
      if (!animals || !animals.length) {
        return;
      }

      const existing = {};
      if (this.contextFormGroup) {
        existing[this.contextFormGroup.get('animalId').value] = true;
      }

      this.animals.controls.forEach(el => {
        existing[el.value] = true;
      });

      animals.forEach(el => {

        if (!existing[el]) {
          this.animals.push(this.fb.control(el));
          this.animals.markAsDirty();
          existing[el] = true;
        }
      });
    });
  }

  noAnimalsSelected() {
    return this.animals.controls.length === 0 &&
      !this.contextFormGroup;
  }

  delete(index: number) {
    this.animals.removeAt(index);
  }
}
