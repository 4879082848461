import { Component } from '@angular/core';
import { MetaBrowseBaseComponent } from '../meta-browse.base.component';
import { ActivatedRoute } from '@angular/router';
import { MetadataService } from '../../../logic/services/metadata.service';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { FormBuilder, Validators } from '@angular/forms';
import { DataCachingService } from '../../../logic/services/data-caching.service';

@Component({
  templateUrl: './institution-ticket-quest-browse.component.html'
})
export class InstitutionTicketQuestBrowseComponent extends MetaBrowseBaseComponent {

  gridColumnDefs = [
    {headerName: '№', field: 'id', width: 100},
    {headerName: 'Наименование', field: 'caption', tooltipField: 'caption', width: 500},
  ];

  constructor(route: ActivatedRoute,
              metadataService: MetadataService,
              alertService: AlertService,
              globalWaitingOverlayService: GlobalWaitingOverlayService,
              lookupService: LookupSourceService,
              bookmarkService: BookmarkService,
              fb: FormBuilder,
              dataCachingService: DataCachingService) {
    super(route, metadataService, alertService, globalWaitingOverlayService, lookupService, fb, bookmarkService, dataCachingService);
  }

  getMetaTitle(): string {
    return 'Вопросы талонов приёма';
  }

  getMetaName(): string {
    return 'institution-ticket-quest';
  }

  getGroupDef(row: any) {
    return {
      id: [row.id, Validators.compose([ Validators.required, Validators.pattern(/^\d{1,3}$/)])],
      caption: [row.caption, Validators.required],
    };
  }
}
