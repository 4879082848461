import { PstReportService } from './pst-report.service';
import * as i0 from "@angular/core";
import * as i1 from "./pst-report.service";
var PstReportStructureService = /** @class */ (function () {
    function PstReportStructureService(pstReportService) {
        this.pstReportService = pstReportService;
    }
    PstReportStructureService.prototype.storeIndicatorValue = function (model, reportIndicator, indicatorTypeMeta, indicatorLevel, value) {
        // реентерабельности тут нету - пока не сохранился предыдущий - новый не даем сохранять
        if (model.__cl_storing_indicator) {
            return false;
        }
        model.__cl_storing_indicator = true;
        var indicator = {
            date: reportIndicator.indicatorDate,
            indicatorTypeId: indicatorTypeMeta.id,
            level: indicatorLevel.level,
            regionGroupId: indicatorLevel.regionGroupId,
            regionId: indicatorLevel.regionId,
            kfhType: indicatorLevel.kfhType,
            agentId: indicatorLevel.agentId,
            subtypeId: indicatorLevel.subtypeId,
            constructionId: indicatorLevel.constructionId,
            sellDealId: indicatorLevel.sellDealId,
            buyDealId: indicatorLevel.buyDealId,
            value: value,
        };
        this.pstReportService.storeIndicator(indicator, model.reportMeta.id).subscribe({
            next: function (updatedIndicators) {
                for (var key in updatedIndicators) {
                    if (updatedIndicators.hasOwnProperty(key)) {
                        model.values[key] = updatedIndicators[key];
                    }
                }
                model.__cl_storing_indicator = false;
            }, error: function (err) {
                model.__cl_storing_indicator = false;
                model.__cl_storing_error = err.error && err.error.messages && err.error.messages.length
                    ? err.error.messages[0].message
                    : 'Неизвестная ошибка';
            }
        });
        return true;
    };
    PstReportStructureService.prototype.getIndicatorValueCode = function (branch, indicatorLevel, reportIndicator, indicatorTypeMeta) {
        var useAgentId = indicatorLevel.level >= 30 && branch.meta.params['use-agent-id'] ? +branch.meta.params['use-agent-id'] : undefined;
        var useKfhType = useAgentId ? 1 : undefined;
        var useSubtypeId = reportIndicator.meta.params['use-subtype-id'] ? +reportIndicator.meta.params['use-subtype-id'] : undefined;
        var code = indicatorTypeMeta.id + ":" + indicatorLevel.level + ":" + reportIndicator.indicatorDate + ":"
            + ((indicatorLevel.regionGroupId || 'null') + ":" + (indicatorLevel.regionId || 'null') + ":")
            + ((reportIndicator.meta.kfhType || useKfhType || indicatorLevel.kfhType || 'null') + ":")
            + ((useAgentId || indicatorLevel.agentId || 'null') + ":")
            + ((useSubtypeId || indicatorLevel.subtypeId || 'null') + ":" + (indicatorLevel.constructionId || 'null') + ":")
            + ((indicatorLevel.sellDealId || 'null') + ":" + (indicatorLevel.buyDealId || 'null'));
        return code;
    };
    PstReportStructureService.prototype.mergeHeaderCells = function (branch) {
        // объединим колонки с одинаковым названием по вертикали
        var lastRow = branch.headerRows[branch.headerRows.length - 1];
        for (var iRow = 0; iRow < branch.headerRows.length; iRow++) {
            for (var iCol = 0; iCol < lastRow.length; iCol++) {
                if (!branch.headerRows[iRow][iCol]) {
                    continue;
                }
                var iRow2 = iRow + 1;
                while (iRow2 < branch.headerRows.length
                    && branch.headerRows[iRow][iCol].rowSpan
                    && branch.headerRows[iRow][iCol].displayTitle === branch.headerRows[iRow2][iCol].displayTitle) {
                    branch.headerRows[iRow][iCol].rowSpan++;
                    branch.headerRows[iRow2][iCol].rowSpan = 0;
                    iRow2++;
                }
                if (iRow2 === branch.headerRows.length && branch.headerRows[iRow][iCol].rowSpan) {
                    branch.headerRows[iRow][iCol].lastRow = true;
                }
            }
        }
        // объединим колонки с одинаковым названием по горизонтали
        for (var iCol = 0; iCol < lastRow.length; iCol++) {
            for (var iRow = 0; iRow < branch.headerRows.length; iRow++) {
                if (!branch.headerRows[iRow][iCol]) {
                    continue;
                }
                var iCol2 = iCol + 1;
                while (iCol2 < lastRow.length
                    && branch.headerRows[iRow][iCol].colSpan
                    && branch.headerRows[iRow][iCol].displayTitlePath === branch.headerRows[iRow][iCol2].displayTitlePath) {
                    if (typeof branch.headerRows[iRow][iCol2].visible !== 'function' || branch.headerRows[iRow][iCol2].visible()) {
                        branch.headerRows[iRow][iCol].colSpan++;
                    }
                    branch.headerRows[iRow][iCol2].colSpan = 0;
                    iCol2++;
                }
            }
        }
    };
    PstReportStructureService.ngInjectableDef = i0.defineInjectable({ factory: function PstReportStructureService_Factory() { return new PstReportStructureService(i0.inject(i1.PstReportService)); }, token: PstReportStructureService, providedIn: "root" });
    return PstReportStructureService;
}());
export { PstReportStructureService };
