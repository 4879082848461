import { Type } from '@angular/core';
import { Router } from '@angular/router';
import { SecurityService } from './security.service';
import { Subject } from 'rxjs';
import { StorageHelper } from '../../helpers/storage-helper';
import { GroupOperationMetadataService } from './group-operation-metadata.service';
import { QueryParamsComponent } from '../../pages/query/query-params.component';
import { ModalParams, ModalSize } from './app-navigation.service.models';
import { environment } from '../../../environments/environment';
import { MetadataService } from './metadata.service';
import { AlertService } from '../../ui/infrastructure/alert.service';
import { QueryViewParamsComponent } from '../../pages/query/query-view-params.component';
var AppNavigationService = /** @class */ (function () {
    function AppNavigationService(router, groupOperationMetadataService, securityService, metadataService, alertService) {
        this.router = router;
        this.groupOperationMetadataService = groupOperationMetadataService;
        this.securityService = securityService;
        this.metadataService = metadataService;
        this.alertService = alertService;
        this.customModalParams = {};
        this.customModalOpened = false;
        this.customModalTitle = 'Действие';
        this.customModalAcceptButtonText = 'ОК';
        this.customModalAcceptPressed = false;
        this.customModalSize = 'sm';
        this.customModalComponent = undefined;
        this.versionHistoryOpened = false;
    }
    AppNavigationService.prototype.showModal = function (component, params) {
        if (params.size) {
            switch (params.size) {
                case ModalSize.sm:
                    this.customModalSize = 'sm';
                    break;
                case ModalSize.medium:
                    this.customModalSize = '';
                    break;
                case ModalSize.lg:
                    this.customModalSize = 'lg';
                    break;
                case ModalSize.xl:
                    this.customModalSize = 'xl';
                    break;
                default:
                    this.customModalSize = '';
                    break;
            }
        }
        else {
            this.customModalSize = '';
        }
        this.customModalComponent = component;
        this.customModalTitle = params.title ? params.title : '';
        this.customModalAcceptButtonText = params.acceptText ? params.acceptText : 'OK';
        this.customModalParams = params.initBodyParams;
        this.customModalAcceptPressed = false;
        this.customModalOpened = true;
        this.customModalAcceptExternalPromise = new Subject();
        return this.customModalAcceptExternalPromise.asObservable();
    };
    AppNavigationService.prototype.performSearch = function (searchString) {
        var route = ['/person-search', searchString];
        this.router.navigate(route);
    };
    AppNavigationService.prototype.performPersonEditing = function (personId) {
        this.router.navigate(['/person-edit', personId]);
    };
    AppNavigationService.prototype.performInstitutionCreation = function () {
        var randomUniqueTag = 'new$' + Math.floor(Math.random() * 100000000000) + 1;
        this.router.navigate(['/institution-create', randomUniqueTag]);
    };
    AppNavigationService.prototype.performAgentCreation = function () {
        var randomUniqueTag = 'new$' + Math.floor(Math.random() * 100000000000) + 1;
        this.router.navigate(['/agent-create', randomUniqueTag]);
    };
    AppNavigationService.prototype.performDrugCreation = function () {
        var randomUniqueTag = 'new$' + Math.floor(Math.random() * 100000000000) + 1;
        this.router.navigate(['/drug-create', randomUniqueTag]);
    };
    AppNavigationService.prototype.performStableCreation = function (newAgentId) {
        var randomUniqueTag = 'new$' + Math.floor(Math.random() * 100000000000) + 1;
        this.router.navigate(['/stable-create', randomUniqueTag, { newAgentId: newAgentId }]);
    };
    AppNavigationService.prototype.performAnimalEventCreation = function (animalId, eventTypeId, stableId, animalIds, stableIds) {
        var randomUniqueTag = 'new$' + Math.floor(Math.random() * 100000000000) + 1;
        this.router.navigate(['/animal-event-create', randomUniqueTag, { animalId: animalId, eventTypeId: eventTypeId || '',
                stableId: stableId || '', animals: animalIds ? '[' + animalIds + ']' : '[]', stables: stableIds ? '[' + stableIds + ']' : '[]' }]);
    };
    AppNavigationService.prototype.performAnimalEventCreationManyByQuarantineId = function (quarantineId, animalId, animalIds, eventTypeId, diseaseTypeId) {
        var randomUniqueTag = 'new$' + Math.floor(Math.random() * 100000000000) + 1;
        this.router.navigate(['/animal-event-create', randomUniqueTag,
            { quarantineId: quarantineId, animalId: animalId, animals: '[' + animalIds + ']',
                eventTypeId: eventTypeId || '', diseaseTypeId: diseaseTypeId || '' }]);
    };
    AppNavigationService.prototype.performStableEventCreation = function (stableId) {
        var randomUniqueTag = 'new$' + Math.floor(Math.random() * 100000000000) + 1;
        this.router.navigate(['/animal-event-create', randomUniqueTag, { stableId: stableId }]);
    };
    AppNavigationService.prototype.performStableEventCreationManyByQuarantineId = function (quarantineId, stableId, stableIds, eventTypeId) {
        var randomUniqueTag = 'new$' + Math.floor(Math.random() * 100000000000) + 1;
        this.router.navigate(['/animal-event-create', randomUniqueTag,
            { quarantineId: quarantineId, stableId: stableId, stables: '[' + stableIds + ']', eventTypeId: eventTypeId || '' }]);
    };
    AppNavigationService.prototype.performAnimalCreation = function (newAgentId, newStableId, animalToCloneId) {
        var randomUniqueTag = 'new$' + Math.floor(Math.random() * 100000000000) + 1;
        this.router.navigate(['/animal-create', randomUniqueTag, {
                newAgentId: newAgentId, newStableId: newStableId,
                animalToCloneId: animalToCloneId
            }]);
    };
    AppNavigationService.prototype.performProductCreation = function (sourceProductId, productToCloneId, sourceAnimalId, transactionType, originStableId, transportProductIds, fromTemplate) {
        var randomUniqueTag = 'new$' + Math.floor(Math.random() * 100000000000) + 1;
        this.router.navigate(['/product-create', randomUniqueTag, {
                sourceProductId: sourceProductId || '',
                productToCloneId: productToCloneId || '',
                sourceAnimalId: sourceAnimalId || '',
                transactionType: transactionType || '',
                originStableId: originStableId || '',
                transportProductIds: transportProductIds || '',
                fromTemplate: fromTemplate || 'false',
            }]);
    };
    AppNavigationService.prototype.performProductDerivation = function (sourceProductId, transactionType) {
        var randomUniqueTag = 'new$' + Math.floor(Math.random() * 100000000000) + 1;
        this.router.navigate(['/product-create', randomUniqueTag, {
                sourceProductId: sourceProductId || '',
                transactionType: transactionType || ''
            }]);
    };
    AppNavigationService.prototype.performInstitutionEditing = function (id) {
        this.router.navigate(['/institution-edit', id]);
    };
    AppNavigationService.prototype.performAgentEditing = function (id) {
        this.router.navigate(['/agent-edit', id]);
    };
    AppNavigationService.prototype.performProductEditing = function (id) {
        this.router.navigate(['/product-edit', id]);
    };
    AppNavigationService.prototype.performProductExpertiseEditing = function (productId, id) {
        this.router.navigate(["/product-edit/" + productId + "/expertises", id]);
    };
    AppNavigationService.prototype.performProductTransactionEditing = function (id) {
        this.router.navigate(['/product-transaction', id]);
    };
    AppNavigationService.prototype.performAnimalEventEditing = function (id) {
        this.router.navigate(['/animal-event-edit', id]);
    };
    AppNavigationService.prototype.performStableEditing = function (id, newAgentId) {
        this.router.navigate(['/stable-edit', id, { newAgentId: newAgentId }]);
    };
    AppNavigationService.prototype.performDrugEditing = function (id) {
        this.router.navigate(['/drug-edit', id]);
    };
    AppNavigationService.prototype.performAnimalEditing = function (id, newAgentId, newStableId, isExternalAnimal) {
        this.router.navigate(['/animal-edit', id, { newAgentId: newAgentId, newStableId: newStableId, isExternalAnimal: isExternalAnimal }]);
    };
    AppNavigationService.prototype.performSecurityRoleEditing = function (id) {
        this.router.navigate(['/security-role', id]);
    };
    AppNavigationService.prototype.navigateGroupOperation = function (jobData) {
        var _this = this;
        var params = JSON.parse(jobData.parameters);
        params.uniqueTag = jobData.id;
        // сначала делали каждый отчет через групповые операции по отдельности, для гибкости. Потом сделали универсальный
        // компонент для запуска таких отчетов, содержащий ограниченный набор параметров, подходящих 95% отчетов.
        // Если чего-то не помещается в универсальный механизм, можно или его доработать, или если совсем "левые" параметры,
        // то по старинке сделать свою форму
        this.groupOperationMetadataService.getReportMetadataByJobTypeId$(jobData.typeId, params.reportId).subscribe(function (opMeta) {
            if (opMeta) {
                _this.performGroupOperation(opMeta.operationParamsCode, params);
            }
            else {
                if (jobData.result) {
                    _this.metadataService.preloadFile(jobData.result).subscribe(function (fileHash) {
                        window.open(environment.api + '/files/get?preloadId=' + encodeURIComponent(fileHash));
                    });
                }
                else if (jobData.message) {
                    _this.alertService.error('Результат нештатно завершившейся массовой операции: ' + jobData.message);
                }
            }
        });
    };
    AppNavigationService.prototype.performGroupOperation = function (opCode, params) {
        var _this = this;
        this.groupOperationMetadataService.getOpDefaultParams$(opCode).subscribe(function (defaultParams) {
            _this.router.navigate(['/operations/general', opCode, (params || defaultParams).uniqueTag, params || defaultParams]);
        });
    };
    AppNavigationService.prototype.navigateJournal = function (userId, objKindId, objId1, objId2) {
        this.router.navigate(['/journal', { userId: userId, objKindId: objKindId, objId1: objId1, objId2: objId2 }]);
    };
    AppNavigationService.prototype.queryUserForQueryParams = function (query) {
        return this.showModal(QueryParamsComponent, new ModalParams(ModalSize.lg, 'Ввод параметров запроса', 'Выполнить', query));
    };
    AppNavigationService.prototype.queryUserForQueryViewParams = function (viewParams) {
        return this.showModal(QueryViewParamsComponent, new ModalParams(ModalSize.medium, 'Параметры отображения результатов', 'Выполнить', viewParams));
    };
    AppNavigationService.prototype.createAgentModal = function (component, searchParams) {
        if (searchParams === void 0) { searchParams = {}; }
        this.customModalSize = 'lg';
        this.customModalAcceptInternalPromise = new Subject();
        this.customModalAcceptExternalPromiseAny = new Subject();
        this.customModalOpened = true;
        this.customModalComponent = component;
        this.customModalTitle = 'Создание нового хозяйствующего субъекта';
        this.customModalAcceptButtonText = undefined;
        this.customModalAcceptPressed = false;
        this.customModalParams = searchParams;
        return this.customModalAcceptExternalPromiseAny.asObservable();
    };
    AppNavigationService.prototype.searchAgent = function (component, searchParams) {
        if (searchParams === void 0) { searchParams = {}; }
        this.customModalSize = 'lg';
        this.customModalAcceptInternalPromise = new Subject();
        this.customModalAcceptExternalPromiseNumber = new Subject();
        this.customModalOpened = true;
        this.customModalComponent = component;
        this.customModalTitle = 'Выбор хозяйствующего субъекта';
        this.customModalAcceptButtonText = undefined;
        this.customModalAcceptPressed = false;
        this.customModalParams = searchParams;
        return this.customModalAcceptExternalPromiseNumber.asObservable();
    };
    AppNavigationService.prototype.searchAnimal = function (component, searchParams) {
        if (searchParams === void 0) { searchParams = {}; }
        this.customModalSize = 'lg';
        this.customModalAcceptInternalPromise = new Subject();
        this.customModalAcceptExternalPromise = new Subject();
        this.customModalOpened = true;
        this.customModalComponent = component;
        this.customModalTitle = 'Выбор животного';
        this.customModalAcceptButtonText = undefined;
        this.customModalAcceptPressed = false;
        searchParams.addManyAnimals = false;
        this.customModalParams = searchParams;
        return this.customModalAcceptExternalPromise.asObservable();
    };
    AppNavigationService.prototype.searchManyAnimals = function (component, searchParams) {
        if (searchParams === void 0) { searchParams = {}; }
        this.customModalSize = 'lg';
        this.customModalAcceptInternalPromise = new Subject();
        this.customModalAcceptExternalPromiseAny = new Subject();
        this.customModalOpened = true;
        this.customModalComponent = component;
        this.customModalTitle = 'Выбор животных из списка';
        this.customModalAcceptButtonText = undefined;
        this.customModalAcceptPressed = false;
        searchParams.addManyAnimals = true;
        this.customModalParams = searchParams;
        return this.customModalAcceptExternalPromiseAny.asObservable();
    };
    AppNavigationService.prototype.searchStable = function (component, searchParams) {
        if (searchParams === void 0) { searchParams = {}; }
        this.customModalSize = 'lg';
        this.customModalAcceptInternalPromise = new Subject();
        this.customModalAcceptExternalPromiseNumber = new Subject();
        this.customModalOpened = true;
        this.customModalComponent = component;
        this.customModalTitle = 'Выбор поднадзорного объекта';
        this.customModalAcceptButtonText = undefined;
        this.customModalAcceptPressed = false;
        this.customModalParams = searchParams;
        return this.customModalAcceptExternalPromiseNumber.asObservable();
    };
    AppNavigationService.prototype.searchDrug = function (component, searchParams) {
        var _this = this;
        if (searchParams === void 0) { searchParams = {}; }
        this.customModalAcceptExternalPromiseNumber = new Subject();
        this.securityService.getUserInfo().subscribe(function (userInfo) {
            _this.customModalSize = 'xl';
            _this.customModalAcceptInternalPromise = new Subject();
            _this.customModalOpened = true;
            _this.customModalComponent = component;
            _this.customModalTitle = 'Выбор препарата';
            _this.customModalAcceptButtonText = undefined;
            _this.customModalAcceptPressed = false;
            _this.customModalParams = searchParams;
            _this.customModalParams.institutionId = userInfo.institutionId;
        });
        return this.customModalAcceptExternalPromiseNumber.asObservable();
    };
    AppNavigationService.prototype.searchManyProducts = function (component, searchParams) {
        if (searchParams === void 0) { searchParams = {}; }
        this.customModalSize = 'xl';
        this.customModalAcceptInternalPromise = new Subject();
        this.customModalAcceptExternalPromise = new Subject();
        this.customModalOpened = true;
        this.customModalComponent = component;
        this.customModalTitle = 'Выбор документов из списка';
        this.customModalAcceptButtonText = undefined;
        this.customModalAcceptPressed = false;
        searchParams.addManySelected = true;
        this.customModalParams = searchParams;
        this.customModalAcceptButtonText = 'Выбрать';
        return this.customModalAcceptExternalPromise.asObservable();
    };
    AppNavigationService.prototype.previewFile = function (component, params) {
        if (params === void 0) { params = {}; }
        this.customModalSize = 'xl';
        this.customModalAcceptInternalPromise = new Subject();
        this.customModalAcceptExternalPromiseNumber = new Subject();
        this.customModalOpened = true;
        this.customModalComponent = component;
        this.customModalTitle = '';
        this.customModalAcceptButtonText = undefined;
        this.customModalAcceptPressed = false;
        this.customModalParams = params;
    };
    AppNavigationService.prototype.sendProductToClearance = function (component, params) {
        if (params === void 0) { params = {}; }
        this.customModalSize = 'lg';
        this.customModalAcceptInternalPromise = new Subject();
        this.customModalAcceptExternalPromise = new Subject();
        this.customModalOpened = true;
        this.customModalComponent = component;
        this.customModalTitle = 'Гашение документа';
        this.customModalAcceptButtonText = 'Направить';
        this.customModalAcceptPressed = false;
        this.customModalParams = params;
        return this.customModalAcceptExternalPromise.asObservable();
    };
    AppNavigationService.prototype.showIndicatorDependencies = function (component, indicatorKey, ownerReportId) {
        this.customModalSize = 'xl';
        this.customModalAcceptInternalPromise = new Subject();
        this.customModalAcceptExternalPromiseNumber = new Subject();
        this.customModalOpened = true;
        this.customModalComponent = component;
        this.customModalTitle = 'Дерево расчета показателя';
        this.customModalAcceptButtonText = undefined;
        this.customModalAcceptPressed = false;
        this.customModalParams = { indicatorKey: indicatorKey, ownerReportId: ownerReportId };
        return this.customModalAcceptExternalPromiseNumber.asObservable();
    };
    AppNavigationService.prototype.showReportIndicatorEdit = function (component, reportIndicatorMeta) {
        this.customModalSize = 'lg';
        this.customModalAcceptInternalPromise = new Subject();
        this.customModalAcceptExternalPromise = new Subject();
        this.customModalOpened = true;
        this.customModalComponent = component;
        this.customModalTitle = 'Редактирование графы отчета';
        this.customModalAcceptButtonText = 'Сохранить';
        this.customModalAcceptPressed = false;
        this.customModalParams = reportIndicatorMeta;
        return this.customModalAcceptExternalPromise.asObservable();
    };
    AppNavigationService.prototype.showReportBranchEdit = function (component, reportBranchMeta) {
        this.customModalSize = 'lg';
        this.customModalAcceptInternalPromise = new Subject();
        this.customModalAcceptExternalPromise = new Subject();
        this.customModalOpened = true;
        this.customModalComponent = component;
        this.customModalTitle = 'Редактирование раздела отчета';
        this.customModalAcceptButtonText = 'Сохранить';
        this.customModalAcceptPressed = false;
        this.customModalParams = reportBranchMeta;
        return this.customModalAcceptExternalPromise.asObservable();
    };
    AppNavigationService.prototype.showIndicatorTypeEdit = function (component, indicatorTypeMeta) {
        this.customModalSize = 'lg';
        this.customModalAcceptInternalPromise = new Subject();
        this.customModalAcceptExternalPromise = new Subject();
        this.customModalOpened = true;
        this.customModalComponent = component;
        this.customModalTitle = 'Редактирование показателя';
        this.customModalAcceptButtonText = 'Сохранить';
        this.customModalAcceptPressed = false;
        this.customModalParams = indicatorTypeMeta;
        return this.customModalAcceptExternalPromise.asObservable();
    };
    AppNavigationService.prototype.updateLastAccessObjects = function (objectId, branch) {
        if (!StorageHelper.storageAvailable()) {
            return;
        }
        var cards = this.safelyReadCardsFromLocalStorage(branch);
        var ixExisting = cards.items.findIndex(function (el) { return el === objectId; });
        if (ixExisting >= 0) {
            cards.items.splice(ixExisting, 1);
        }
        if (cards.items.length >= 10) {
            cards.items.splice(0, 1);
        }
        cards.items.push(objectId);
        window.localStorage.setItem(this.getLastAccessObjectsLocalStorageKey(branch), JSON.stringify(cards));
    };
    AppNavigationService.prototype.getLastAccessedObjects = function (branch) {
        if (!StorageHelper.storageAvailable()) {
            return [];
        }
        var cards = this.safelyReadCardsFromLocalStorage(branch);
        return cards.items.reverse();
    };
    AppNavigationService.prototype.safelyReadCardsFromLocalStorage = function (branch) {
        var branchLocalStorageKey = this.getLastAccessObjectsLocalStorageKey(branch);
        var cards = { items: [] };
        try {
            cards = JSON.parse(window.localStorage.getItem(branchLocalStorageKey));
            if (!cards) {
                cards = { items: [] };
            }
            if (cards && (!cards.items || !Array.isArray(cards.items))) {
                cards.items = [];
            }
        }
        catch (error) {
        }
        return cards;
    };
    AppNavigationService.prototype.getLastAccessObjectsLocalStorageKey = function (branch) {
        return "itech-vet-2019-last-cards[" + branch + "]";
    };
    Object.defineProperty(AppNavigationService.prototype, "favoriteQueries", {
        get: function () {
            if (!StorageHelper.storageAvailable()) {
                return [];
            }
            var favoritesObj = this.readFavoritesObj();
            var favorites = [];
            for (var queryId in favoritesObj) {
                if (favoritesObj.hasOwnProperty(queryId) && !isNaN(+queryId)) {
                    favorites.push({ id: queryId, caption: favoritesObj[queryId].caption });
                }
            }
            favorites.sort(function (a, b) { return a.caption.localeCompare(b.caption); });
            return favorites;
        },
        enumerable: true,
        configurable: true
    });
    AppNavigationService.prototype.setFavoriteQuery = function (queryId, queryCaption, favorite) {
        if (!StorageHelper.storageAvailable()) {
            return;
        }
        var favoritesObj = this.readFavoritesObj();
        if (!favorite) {
            if (favoritesObj[queryId]) {
                delete favoritesObj[queryId];
            }
        }
        else {
            favoritesObj[queryId] = { id: queryId, caption: queryCaption };
        }
        window.localStorage.setItem('itech-vet-2019-favorite-queries', JSON.stringify(favoritesObj));
    };
    AppNavigationService.prototype.getFavoriteQuery = function (queryId) {
        if (!StorageHelper.storageAvailable()) {
            return;
        }
        var favoritesObj = this.readFavoritesObj();
        return !!favoritesObj[queryId];
    };
    AppNavigationService.prototype.getFavoriteQueryCaption = function (queryId) {
        if (!StorageHelper.storageAvailable()) {
            return;
        }
        var favoritesObj = this.readFavoritesObj();
        return favoritesObj[queryId] ? favoritesObj[queryId].caption : 'Неизвестная выборка';
    };
    AppNavigationService.prototype.readFavoritesObj = function () {
        var favoritesObj = {};
        try {
            favoritesObj = JSON.parse(window.localStorage.getItem('itech-vet-2019-favorite-queries'));
            if (!favoritesObj) {
                favoritesObj = {};
            }
        }
        catch (error) {
        }
        return favoritesObj;
    };
    AppNavigationService.prototype.performQuarantineCreation = function (diseaseTypeId) {
        var randomUniqueTag = 'new$' + Math.floor(Math.random() * 100000000000) + 1;
        this.router.navigate(['/quarantine-create', randomUniqueTag, { diseaseTypeId: diseaseTypeId }]);
    };
    AppNavigationService.prototype.performQuarantineEditing = function (id) {
        this.router.navigate(['/quarantine-edit', id]);
    };
    return AppNavigationService;
}());
export { AppNavigationService };
