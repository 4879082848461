import { Component, Input, OnChanges } from '@angular/core';
import { QuarantineDataService } from '../../../logic/services/quarantine-data.service';
import { FormBuilder } from '@angular/forms';
import { StringHelper } from '../../../helpers/string-helper';
import { SecurityService } from '../../../logic/services/security.service';
import { AlertService } from '../../../ui/infrastructure/alert.service';

@Component({
  selector: 'app-edit-quarantine-message',
  templateUrl: './edit-quarantine-messages.component.html'
})
export class EditQuarantineMessagesComponent implements OnChanges {

  @Input() quarantineId: number;
  messages: any[];

  editMessage = this.fb.group(this.getFormGroupDef({}));

  constructor(private quarantineDataService: QuarantineDataService,
              private fb: FormBuilder,
              public securityService: SecurityService,
              private alertService: AlertService) {
  }

  ngOnChanges() {
    this.quarantineDataService.getAllMessages(this.quarantineId)
      .subscribe(data => {
        data.sort((a, b) => b.time.localeCompare(a.time));
        this.messages = data;
      });
  }

  private getFormGroupDef(val: any) {
    return {
      quarantineId: this.quarantineId,
      userId: val.userId,
      id: val.id,
      message: val.message,
      time: val.time
    };
  }

  store() {
    this.editMessage.get('quarantineId').setValue(this.quarantineId);
    this.quarantineDataService.storeMessage(this.editMessage.value)
      .subscribe(data => {
        this.editMessage = this.fb.group(this.getFormGroupDef({}));
        data.sort((a, b) => b.time.localeCompare(a.time));
        this.messages = data;
      });
  }

  formatTime(time: string) {
    if (!time) {
      return '';
    }
    return StringHelper.getRuTimeWithSeconds(new Date(time));
  }

  edit(message: any) {
    this.editMessage = this.fb.group(this.getFormGroupDef(message));
  }

  delete(message: any) {
    this.alertService.confirmModal('Вы уверены, что хотите удалить сообщение?')
      .subscribe(confirm => {
        if (confirm) {
          message.message = null;
          this.quarantineDataService.storeMessage(message)
            .subscribe(data => {
              this.editMessage = this.fb.group(this.getFormGroupDef({}));
              data.sort((a, b) => b.time.localeCompare(a.time));
              this.messages = data;
            });
        }
      });
  }
}
