<div class="itech-common-wrap no-pager">
  <div class="itech-v-header-content" style="overflow-y: auto;">
    <div class="itech-content-header itech-no-margin-bottom">
      <clr-icon shape="file-group" size="36"></clr-icon>&nbsp;{{getMetaTitle()}}
    </div>

    <div *ngIf="addrStreetTypeMap && addrCityMap" style="padding: 5px; height: 90%">
      <app-grid [rowData]="_searchResults" [uniqueGridName]="getMetaName()" [columnDefs]="gridColumnDefs"
                (rowClicked)="editRow($event.data)" [exportFileName]="getMetaTitle()"></app-grid>
    </div>
  </div>
  <div class="itech-form-actions">
    <div>
      <button class="btn btn-link btn-primary" (click)="refreshResults()">
        <clr-icon shape="refresh" class="has-badge"></clr-icon>&nbsp;Обновить список
      </button>
      <button class="btn btn-link btn-primary" (click)="editRow({})">
        <clr-icon shape="plus" has-badge="true"></clr-icon>&nbsp;Добавить новую запись
      </button>
    </div>
  </div>
</div>


<clr-modal [(clrModalOpen)]="editModalOpened" *ngIf="editModalOpened" [clrModalClosable]="false" [clrModalSize]="'lg'">
  <h3 class="modal-title">Редактирование улицы
    <span *ngIf="currentIsNew" style="color:green"> [Новая]</span></h3>
  <div class="modal-body">
    <div class="form compact" [formGroup]="currentFormGroup">
      <section class="form-block itech-block-large">

        <div class="form-group">
          <label for="regionIdCityId" class="required">Населенный пункт</label>
          <app-combo-lookup class="itech-control-m-large" [contextControlId]="'regionIdCityId'"
                            [contextControlName]="'regionIdCityId'" [lookupName]="'addr-city'"
                            [contextFormGroup]="currentFormGroup" (change)="regionIdCityIdChanged($event)"></app-combo-lookup>
        </div>

        <div class="form-group">
          <label for="caption" class="required">Наименование</label>
          <label for="caption" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                 [class.invalid]="isInvalid(currentFormGroup, 'caption')">
            <input type="text" id="caption" placeholder="Наименование" class="itech-control-xlarge"
                   formControlName="caption">
            <app-validation-tooltip [input]="currentFormGroup.controls['caption']"></app-validation-tooltip>
          </label>
        </div>

        <div class="form-group">
          <label for="type" class="required">Тип</label>
          <app-combo-lookup class="itech-control-xlarge" [contextControlId]="'type'"
                            [contextControlName]="'type'" [lookupName]="'addr-street-type'"
                            [contextFormGroup]="currentFormGroup"></app-combo-lookup>
        </div>

        <div class="form-group">
          <label for="codePfr62">Код ПФР 6.2</label>
          <label for="codePfr62" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                 [class.invalid]="isInvalid(currentFormGroup, 'codePfr62')">
            <input type="text" id="codePfr62" placeholder="Код ПФР 6.2" class="itech-control-xlarge"
                   formControlName="codePfr62">
            <app-validation-tooltip [input]="currentFormGroup.controls['codePfr62']"></app-validation-tooltip>
          </label>
        </div>

      </section>
    </div>
  </div>
  <div class="modal-footer itech-form-actions">
    <div>
      <button *ngIf="!currentIsNew" type="button" class="btn btn-warning-outline"
              (click)="delete(currentFormGroup.value)">
        <clr-icon shape="trash"></clr-icon>&nbsp;Удалить
      </button>
    </div>
    <div>
      <button type="button" class="btn btn-primary" (click)="addEditCompleted()">
        <clr-icon shape="check"></clr-icon>&nbsp;Сохранить
      </button>
      <button type="button" class="btn btn-warning" (click)="editModalOpened = false">
        <clr-icon shape="undo"></clr-icon>&nbsp;Отмена
      </button>
    </div>
  </div>
</clr-modal>
