import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { FormBuilder } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { ServerSideErrorsProvider } from '../../../logic/validators/server-side-errors-provider';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';
import { EditDrugCommonFormComponent } from './edit-drug-common-form.component';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { AppFormGroup } from '../../../ui/controls/app-form-group';
import { DrugDataService } from '../../../logic/services/drug-data.service';

@Component({
  selector: 'app-edit-drug-common',
  templateUrl: './edit-drug-common.component.html'
})
export class EditDrugCommonComponent implements ServerSideErrorsProvider, OnChanges {
  @Input() id: number;

  model: any = {};
  storing = false;
  serverSideValidationErrors: any[];

  isInvalid = FormHelper.isInvalid;

  get contextFormGroup(): AppFormGroup {
    return this.model ? this.model.contextFormGroup : undefined;
  }

  constructor(private bookmarkService: BookmarkService,
              private drugDataService: DrugDataService,
              private alertService: AlertService,
              private cache: AddressPersonFioCacheService,
              private dataCachingService: DataCachingService,
              private waitingOverlay: GlobalWaitingOverlayService,
              private fb: FormBuilder,
              public navigationService: AppNavigationService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.id) {
      this.refreshFormDataInternal();
    }
  }

  store() {

    FormHelper.markAsSubmitted(this.contextFormGroup);

    if (!this.contextFormGroup.valid) {
      return;
    }

    this.storing = true;

    this.drugDataService.storeDrugCommonInfo(this.contextFormGroup.value).subscribe(val => {
      this.storing = false;
      this.cache.invalidateDrug(this.id);
      FormHelper.setSingleFormGroupServerSideValidationErrors({}, this.model, this.contextFormGroup);
      this.alertService.success(`Общая информация о препарате успешно сохранена`);
      this.refreshFormData();
    }, error => {
      this.storing = false;
      FormHelper.setSingleFormGroupServerSideValidationErrors(error, this.model, this.contextFormGroup);
      window.document.getElementById('drug-common-content').scrollTop = 0;
    });
  }

  cancelEdit() {
    this.dataCachingService.removeCachedData('EditDrugCommon', this.id.toString());
    this.refreshFormDataInternal();
  }

  refreshFormData() {
    this.dataCachingService.removeCachedData('EditDrugCommon', this.id.toString());
    this.refreshFormDataInternal();
  }

  refreshFormDataInternal() {
    this.waitingOverlay.StartWaiting();

    this.model = {id: this.id, serverSideValidationErrors: []};

    const existing = this.dataCachingService.getCachedData('EditDrugCommon', this.id.toString());

    if (existing) {
      this.model = existing;
      this.waitingOverlay.EndWaiting();
    } else {
      this.drugDataService.getDrugCommonForEdit(this.id).subscribe(data => {
        this.model.id = data.id;
        this.model.contextFormGroup = EditDrugCommonFormComponent.createFormGroup(this.fb, this.model, data);
        this.cache.invalidateDrug(this.id);
        this.dataCachingService.addToCache('EditDrugCommon', this.id.toString(), this.model);

        this.bookmarkService.refreshDrugCardBookmark(this.id);

        this.waitingOverlay.EndWaiting();
      }, () => {
        this.waitingOverlay.EndWaiting();
      });
    }
  }

  deleteDrug() {
    this.alertService.confirmModal('Вы уверены, что хотите удалить препарат?').subscribe(() => {
      this.drugDataService.deleteDrug(this.id).subscribe(() => {
        this.dataCachingService.removeCachedData('EditDrugCommon', this.id.toString());
        this.bookmarkService.removeOpenCardBookmark(['/drug-edit'], this.id.toString());
      });
    });
  }
}
