/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../ui/controls/app-grid.component.ngfactory";
import * as i2 from "../../ui/controls/app-grid.component";
import * as i3 from "../../logic/services/data-caching.service";
import * as i4 from "../../logic/services/security.service";
import * as i5 from "@clr/angular";
import * as i6 from "./institution-search-results.component";
import * as i7 from "@angular/router";
import * as i8 from "../../logic/services/institution-data.service";
import * as i9 from "../../ui/infrastructure/global-waiting-overlay.service";
import * as i10 from "../../logic/services/app-navigation.service";
var styles_InstitutionSearchResultsComponent = [];
var RenderType_InstitutionSearchResultsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_InstitutionSearchResultsComponent, data: {} });
export { RenderType_InstitutionSearchResultsComponent as RenderType_InstitutionSearchResultsComponent };
export function View_InstitutionSearchResultsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [["class", "itech-common-wrap no-pager"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["style", "padding: 5px; height: 100%"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "app-grid", [], null, [[null, "rowClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("rowClicked" === en)) {
        var pd_0 = (_co.appNavigationService.performInstitutionEditing($event.data.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_AppGridComponent_0, i1.RenderType_AppGridComponent)), i0.ɵdid(4, 114688, null, 0, i2.AppGridComponent, [i3.DataCachingService, i4.SecurityService], { uniqueGridName: [0, "uniqueGridName"], columnDefs: [1, "columnDefs"], rowData: [2, "rowData"], pagination: [3, "pagination"], frameworkComponents: [4, "frameworkComponents"], paginationAutoPageSize: [5, "paginationAutoPageSize"], exportFileName: [6, "exportFileName"] }, { rowClicked: "rowClicked" }), (_l()(), i0.ɵeld(5, 0, null, null, 8, "div", [["class", "itech-form-actions"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 3, "button", [["class", "btn btn-link btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.refreshResults() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "clr-icon", [["class", "has-badge"], ["shape", "refresh"]], null, null, null, null, null)), i0.ɵdid(8, 16384, null, 0, i5.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, ["\u00A0\u041E\u0431\u043D\u043E\u0432\u0438\u0442\u044C \u0440\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442\u044B \u043F\u043E\u0438\u0441\u043A\u0430 "])), (_l()(), i0.ɵeld(10, 0, null, null, 3, "button", [["class", "btn btn-link btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.appNavigationService.performInstitutionCreation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "clr-icon", [["class", "has-badge"], ["shape", "bank"]], null, null, null, null, null)), i0.ɵdid(12, 16384, null, 0, i5.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, ["\u00A0\u0417\u0430\u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u043E\u0432\u0430\u0442\u044C \u043D\u043E\u0432\u043E\u0435 \u0443\u0447\u0440\u0435\u0436\u0434\u0435\u043D\u0438\u0435 "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "InstitutionSearchResultsGrid"; var currVal_1 = _co.gridColumnDefs; var currVal_2 = _co._searchResults; var currVal_3 = true; var currVal_4 = _co.frameworkComponents; var currVal_5 = true; var currVal_6 = "\u0421\u043F\u0438\u0441\u043E\u043A \u0443\u0447\u0440\u0435\u0436\u0434\u0435\u043D\u0438\u0439"; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_InstitutionSearchResultsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-institution-search-results", [], null, null, null, View_InstitutionSearchResultsComponent_0, RenderType_InstitutionSearchResultsComponent)), i0.ɵdid(1, 114688, null, 0, i6.InstitutionSearchResultsComponent, [i7.ActivatedRoute, i8.InstitutionDataService, i9.GlobalWaitingOverlayService, i3.DataCachingService, i10.AppNavigationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InstitutionSearchResultsComponentNgFactory = i0.ɵccf("app-institution-search-results", i6.InstitutionSearchResultsComponent, View_InstitutionSearchResultsComponent_Host_0, {}, {}, []);
export { InstitutionSearchResultsComponentNgFactory as InstitutionSearchResultsComponentNgFactory };
