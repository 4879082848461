<div class="compact form itech-highlighted-sections" [formGroup]="contextFormGroup" *ngIf="contextFormGroup">

  <app-doc-validation-errors [validationErrors]="contextFormGroup.docLevelServerSideValidationErrors">
  </app-doc-validation-errors>

  <section class="form-block itech-block-medium">
    <h3>Общая информация</h3>

    <div class="form-group itech-group-whole-line">
      <label></label>
      <div class="itech-control-desc" [class.itech-desc-error]="contextFormGroup.get('deletedTime').value">
        Карточка создана:
        {{contextFormGroup.get('createdTime').value | rtime}},
        {{contextFormGroup.get('createdUser').value | lookup: 'user' | async}}
      </div>
      <div *ngIf="contextFormGroup.get('deletedTime').value" class="itech-control-desc itech-desc-error">
        ; Карточка удалена:
        {{contextFormGroup.get('deletedTime').value | rtime}},
        {{contextFormGroup.get('deletedUser').value | lookup: 'user' | async}}
      </div>
    </div>

    <div class="form-group" *ngIf="contextFormGroup.get('servicedInstitutionId').value">
      <label for="servicedInstitutionId">Обслуживается в</label>
      <app-link-button [id]="contextFormGroup.get('servicedInstitutionId').value" [lookupName]="'institution'"
                       [idComment]="contextFormGroup.get('servicedInstitutionBranchId').value"
                       [lookupNameComment]="'institution-branch'"
                       [useShortComment]="true"></app-link-button>
    </div>

    <div class="form-group">
      <label for="legalFormId" class="required">Тип хозяйства (орг-правовая форма)</label>
      <app-text-lookup [validationTooltipRight]="true" class="itech-control-xlarge"
                        [contextControlId]="'legalFormId'" [sorted]="true"
                        [contextControlName]="'legalFormId'"
                        [lookupName]="'legal-form'" [contextFormGroup]="contextFormGroup"></app-text-lookup>
    </div>

    <ng-container *ngIf="+(contextFormGroup.controls['legalFormId'].value) === 0 ||
        +(contextFormGroup.controls['legalFormId'].value) === 45 || +(contextFormGroup.controls['legalFormId'].value) === 31 ||
        +(contextFormGroup.controls['legalFormId'].value) === 13">
      <app-edit-agent-person-form [contextFormGroup]="physAgentPersonFormGroup" [syncState]="syncCommonInfo">
      </app-edit-agent-person-form>
    </ng-container>

    <ng-container *ngIf="+(contextFormGroup.controls['legalFormId'].value)">

      <div class="form-group itech-double-height" *ngIf="+(contextFormGroup.controls['legalFormId'].value) !== 45 &&
        +(contextFormGroup.controls['legalFormId'].value) !== 31 && +(contextFormGroup.controls['legalFormId'].value) !== 13">
        <label for="caption" class="required">Полное наименование</label>
        <label for="caption" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
               [class.invalid]="isInvalid(contextFormGroup, 'caption')">
            <textarea type="text" id="caption" placeholder="Введите полное наименование учреждения"
                      class="itech-control-xlarge" formControlName="caption">
            </textarea>
          <app-validation-tooltip [input]="contextFormGroup.controls['caption']"></app-validation-tooltip>
          <clr-icon *ngIf="syncCommonInfo['mercuryUuid']" shape="lock" style="color:gray;vertical-align:top;margin-top:16px;"></clr-icon>
        </label>
      </div>

      <div class="form-group" *ngIf="+(contextFormGroup.controls['legalFormId'].value) !== 45 && +(contextFormGroup.controls['legalFormId'].value) !== 31
        && +(contextFormGroup.controls['legalFormId'].value) !== 13">
        <label for="shortCaption" class="required">Краткое наименование</label>
        <label for="shortCaption" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
               [class.invalid]="isInvalid(contextFormGroup, 'shortCaption')">
          <input type="text" id="shortCaption" placeholder="Краткое наименование" class="itech-control-xlarge"
                 formControlName="shortCaption">
          <app-validation-tooltip [input]="contextFormGroup.controls['shortCaption']"></app-validation-tooltip>
          <clr-icon *ngIf="syncCommonInfo['mercuryUuid']" shape="lock" style="color:gray"></clr-icon>
        </label>
      </div>

      <div class="form-group">
        <label for="ogrn">ОГРН / ОКПО</label>
        <label for="ogrn" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
               [class.invalid]="isInvalid(contextFormGroup, 'ogrn')">
          <input type="text" id="ogrn" placeholder="ОГРН" class="itech-control-normal"
                 formControlName="ogrn">
          <app-validation-tooltip [input]="contextFormGroup.controls['ogrn']"></app-validation-tooltip>
          <clr-icon *ngIf="syncCommonInfo['mercuryUuid']" shape="lock" style="color:gray"></clr-icon>
        </label>
        <label for="okpo" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
               [class.invalid]="isInvalid(contextFormGroup, 'okpo')">
          <input type="text" id="okpo" placeholder="ОКПО" class="itech-control-normal"
                 formControlName="okpo">
          <app-validation-tooltip [input]="contextFormGroup.controls['okpo']"></app-validation-tooltip>
        </label>
      </div>

      <div class="form-group">
        <label for="okved">ОКВЭД</label>
        <label for="okved" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
               [class.invalid]="isInvalid(contextFormGroup, 'okved')">
          <input type="text" id="okved" placeholder="ОКВЭД" class="itech-control-xlarge"
                 formControlName="okved">
          <app-validation-tooltip [input]="contextFormGroup.controls['okved']"></app-validation-tooltip>
        </label>
      </div>

      <div class="form-group" *ngIf="+(contextFormGroup.controls['legalFormId'].value) !== 45 &&
        +(contextFormGroup.controls['legalFormId'].value) !== 31 && +(contextFormGroup.controls['legalFormId'].value) !== 13">
        <label for="inn" class="required">ИНН / КПП</label>
        <label for="inn" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
               [class.invalid]="isInvalid(contextFormGroup, 'inn')">
          <input type="text" id="inn" class="itech-control-normal"
                 formControlName="inn">
          <app-validation-tooltip [input]="contextFormGroup.controls['inn']"></app-validation-tooltip>
          <clr-icon *ngIf="syncCommonInfo['mercuryUuid']" shape="lock" style="color:gray"></clr-icon>
        </label>
        <label for="kpp" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
               [class.invalid]="isInvalid(contextFormGroup, 'kpp')">
          <input type="text" id="kpp" class="itech-control-normal"
                 formControlName="kpp">
          <app-validation-tooltip [input]="contextFormGroup.controls['kpp']"></app-validation-tooltip>
          <clr-icon *ngIf="syncCommonInfo['mercuryUuid']" shape="lock" style="color:gray"></clr-icon>
        </label>
      </div>

      <div class="form-group">
        <label for="dateTaxRegistered">Дата гос. регистрации / пост. на учет</label>
        <label for="dateTaxRegistered" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
               [class.invalid]="isInvalid(contextFormGroup, 'dateTaxRegistered')">
          <input type="date" id="dateTaxRegistered" class="itech-control-normal"
                 formControlName="dateTaxRegistered">
          <app-validation-tooltip [input]="contextFormGroup.controls['dateTaxRegistered']"></app-validation-tooltip>
        </label>
        <label for="dateTaxIncluded" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
               [class.invalid]="isInvalid(contextFormGroup, 'dateTaxIncluded')">
          <input type="date" id="dateTaxIncluded" class="itech-control-normal"
                 formControlName="dateTaxIncluded">
          <app-validation-tooltip [input]="contextFormGroup.controls['dateTaxIncluded']"></app-validation-tooltip>
        </label>
      </div>

      <div class="form-group">
        <label for="dateTaxEliminated">Дата ликвидации</label>
        <label for="dateTaxEliminated" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
               [class.invalid]="isInvalid(contextFormGroup, 'dateTaxEliminated')">
          <input type="date" id="dateTaxEliminated" class="itech-control-normal" formControlName="dateTaxEliminated">
          <app-validation-tooltip [input]="contextFormGroup.controls['dateTaxEliminated']"></app-validation-tooltip>
        </label>
      </div>
    </ng-container>

    <div class="form-group" *ngIf="contextFormGroup.get('openApiKey').value">
      <label>Ключ доступа к API</label>
      <label>{{contextFormGroup.get('openApiKey').value}}</label>
    </div>

  </section>

  <section class="form-block itech-block-medium">
    <h3 *ngIf="!isPhysOrIndividOrKfh(contextFormGroup)">
      Юридический адрес
      <clr-icon *ngIf="syncCommonInfo['mercuryUuid']" shape="lock" style="color:gray"></clr-icon>
    </h3>
    <h3 *ngIf="isPhysOrIndividOrKfh(contextFormGroup)">
      Адрес регистрации по месту жительства
      <clr-icon *ngIf="syncCommonInfo['mercuryUuid']" shape="lock" style="color:gray"></clr-icon>
    </h3>
    <app-address [contextFormGroup]="contextFormGroup.get('legalAddress')" [required]="true"></app-address>
  </section>

  <section class="form-block itech-block-medium" *ngIf="+(contextFormGroup.controls['legalFormId'].value)">
    <h3>Фактический адрес</h3>
    <app-address [contextFormGroup]="contextFormGroup.get('factAddress')" [required]="false"></app-address>
  </section>

  <section class="form-block itech-block-medium" *ngIf="+(contextFormGroup.controls['legalFormId'].value)">
    <h3>Почтовый адрес</h3>
    <app-address [contextFormGroup]="contextFormGroup.get('postAddress')" [required]="false"></app-address>
  </section>

  <section class="form-block itech-block-medium" *ngIf="+(contextFormGroup.controls['legalFormId'].value)">
    <h3>Персоналии</h3>
    <ng-container *ngFor="let person of agentPersons.controls; index as i">
      <div *ngIf="+(person.get('agentPersonTypeId').value) !== 1"
           style="display: flex; flex-direction: row">
        <div style="flex: 1 1 auto; margin-right: 10px; flex-direction: column; display: flex; align-items: center">
          <clr-icon shape="user" size="72"></clr-icon>
          <a class="itech-link in-table-link" (click)="deleteAgentPerson(i)">Удалить</a>
        </div>
        <app-edit-agent-person-form style="flex: 1000 1 auto" [contextFormGroup]="person"></app-edit-agent-person-form>
      </div>
    </ng-container>

    <div class="form-group itech-group-whole-line">
      <label></label>
      <button type="button" class="btn btn-link itech-inline-link-btn" (click)="addAgentPerson()">
        <clr-icon shape="plus"></clr-icon>
        Добавить человека
      </button>
    </div>
  </section>

  <section class="form-block itech-block-medium">
    <h3>Контактные данные</h3>

    <div class="form-group">
      <label for="phone">Телефон / Факс</label>
      <label for="phone" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'phone')">
        <input type="text" id="phone" placeholder="Телефон" class="itech-control-medium"
               formControlName="phone">
        <app-validation-tooltip [input]="contextFormGroup.controls['phone']"></app-validation-tooltip>
      </label>
      <label for="fax" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'fax')">
        <input type="text" id="fax" placeholder="Факс" class="itech-control-medium"
               formControlName="fax">
        <app-validation-tooltip [input]="contextFormGroup.controls['fax']"></app-validation-tooltip>
      </label>
    </div>

    <div class="form-group">
      <label for="phone">EMail</label>
      <label for="email" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'email')">
        <input type="text" id="email" placeholder="EMail" class="itech-control-large"
               formControlName="email">
        <app-validation-tooltip [input]="contextFormGroup.controls['email']"></app-validation-tooltip>
      </label>
    </div>

    <div class="form-group itech-height-x4 v-resize">
      <label for="otherTextInfo">Дополнительная информация</label>
      <label for="otherTextInfo" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'otherTextInfo')">
            <textarea type="text" id="otherTextInfo" placeholder="Укажите дополнительную информацию"
                      class="itech-control-xlarge itech-text-wall" formControlName="otherTextInfo">
            </textarea>
        <app-validation-tooltip [input]="contextFormGroup.controls['otherTextInfo']"></app-validation-tooltip>
      </label>
    </div>

    <div class="form-group">
      <label for="bankAccount">Расчетный счет</label>
      <label for="bankAccount" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'bankAccount')">
        <input type="text" id="bankAccount" placeholder="01234567890123456789" class="itech-control-xlarge"
               formControlName="bankAccount">
        <app-validation-tooltip [input]="contextFormGroup.controls['bankAccount']"></app-validation-tooltip>
      </label>
    </div>

    <div class="form-group">
      <label for="bankPAccount">Лицевой счет</label>
      <label for="bankPAccount" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'bankPAccount')">
        <input type="text" id="bankPAccount" placeholder="01234567890123456789" class="itech-control-xlarge"
               formControlName="bankPAccount">
        <app-validation-tooltip [input]="contextFormGroup.controls['bankPAccount']"></app-validation-tooltip>
      </label>
    </div>

    <div class="form-group">
      <label for="bankBik">БИК Банка</label>
      <label for="bankBik" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'bankBik')">
        <input type="text" id="bankBik" placeholder="00000000" class="itech-control-xlarge"
               formControlName="bankBik">
        <app-validation-tooltip [input]="contextFormGroup.controls['bankBik']"></app-validation-tooltip>
      </label>
    </div>


  </section>
</div>
