import {Routes} from '@angular/router';
import {PstReportsListComponent} from './pages/pst-reports-list.component';
import {PstReportEditComponent} from './pages/pst-report-edit.component';
import {PstReportTypeBrowseComponent} from './pages/meta/pst-report-type-browse.component';
import {ChangeAgentIndicatorsRunnerComponent} from './pages/change-agent-indicators-runner.component';
import { PstIndicatorSubtypeBrowseComponent } from './pages/meta/pst-indicator-subtype-browse.component';

export class PstReportsRoutes {
  public static routes: Routes = [
    {path: 'pst-report', component: PstReportsListComponent},
    {path: 'pst-report-edit/:id', component: PstReportEditComponent},
    {path: 'dictionary/pst-report-type', component: PstReportTypeBrowseComponent},
    {path: 'dictionary/pst-indicator-subtype', component: PstIndicatorSubtypeBrowseComponent},
    {path: 'operations/pst-change-agent-indicators', component: ChangeAgentIndicatorsRunnerComponent},
  ];
}
