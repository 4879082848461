import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { UiModule } from './ui/ui.module';
import { AppRoutingModule } from './app.routing';
import { PagesModule } from './pages/pages.module';
import { AppNavigationService } from './logic/services/app-navigation.service';
import { AlertService } from './ui/infrastructure/alert.service';
import { PersonDataService } from './logic/services/person-data.service';
import { GlobalWaitingOverlayService } from './ui/infrastructure/global-waiting-overlay.service';
import { DataCachingService } from './logic/services/data-caching.service';
import { MetadataService } from './logic/services/metadata.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LookupSourceService } from './logic/services/lookup-source.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AlertsHttpInterceptor } from './logic/services/alerts-http-interceptor';
import { AddressPersonFioCacheService } from './logic/services/address-person-fio-cache.service';
import { InstitutionDataService } from './logic/services/institution-data.service';
import { HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import { SecurityService } from './logic/services/security.service';
import { GroupOperationsService } from './logic/services/group-operations.service';
import { UnavailableHttpInterceptor } from './logic/services/unavailable-http-interceptor';
import { QueryService } from './logic/services/query.service';
import { EsiaService } from './esia/esia.service';
import { EsiaBearerInterceptor } from './esia/esia-bearer.interceptor';
import { AuthGuard } from '../auth.guard';
import { ToastrModule } from 'ngx-toastr';
import { ClarityModule } from '@clr/angular';
import { UserService } from './logic/services/user.service';
import { InstitutionTicketDataService } from './logic/services/institution-ticket-data.service';
import {PstReportModule} from './pst-report/pst-report.module';

registerLocaleData(localeRu);

export function esiaInitializer(
  esiaService: EsiaService
): () => Promise<void> {
  return () => esiaService.init();
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ClarityModule,
    UiModule,
    PagesModule,
    AppRoutingModule,
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-left',
      preventDuplicates: true,
    }),
    BrowserAnimationsModule,
    PstReportModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: esiaInitializer,
      multi: true,
      deps: [EsiaService]
    },
    AppNavigationService,
    AlertService,
    PersonDataService,
    InstitutionDataService,
    InstitutionTicketDataService,
    GlobalWaitingOverlayService,
    DataCachingService,
    MetadataService,
    LookupSourceService,
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {provide: HTTP_INTERCEPTORS, useClass: EsiaBearerInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AlertsHttpInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: UnavailableHttpInterceptor, multi: true},
    AddressPersonFioCacheService,
    {provide: LOCALE_ID, useValue: 'ru'},
    SecurityService,
    GroupOperationsService,
    QueryService,
    GroupOperationsService,
    EsiaService,
    AuthGuard,
    UserService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
