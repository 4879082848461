import { Component, Input, OnChanges, HostBinding } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';

@Component({
  selector: 'app-query-address-filter',
  template: `
    <ng-container [formGroup]="contextFormGroup"  *ngIf="contextFormGroup
    && (!queryUserMode || queryUserMode['_par_' + parameterName + '_filter_mq'])">
      <label [style.background]="filtered?'linear-gradient(to right, #90ff90, white)':undefined">
        <ng-content></ng-content>
        <div class="checkbox checkbox-inline" title="Запросить у пользователя" *ngIf="!queryUserMode">
          <input type="checkbox" id="{{'_par_' + parameterName + '_filter_mq'}}" [(ngModel)]="customFilterChecked"
                 [ngModelOptions]="{standalone: true}">
          <label for="{{'_par_' + parameterName + '_filter_mq'}}">?</label>
        </div>
        <div class="checkbox checkbox-inline" title="Значение отсутствует (не указано)" *ngIf="!queryUserMode">
          <input type="checkbox" id="{{'_par_' + parameterName + '_filter_novalue'}}" [(ngModel)]="noValueChecked"
                 [ngModelOptions]="{standalone: true}">
          <label for="{{'_par_' + parameterName + '_filter_novalue'}}">X</label>
        </div>
      </label>
      <div>
        <app-address [contextFormGroup]="contextFormGroup.get('_par_' + parameterName)"
                     [required]="true"></app-address>
      </div>
    </ng-container>
  `
})
export class QueryAddressFilterComponent {
  @Input() contextFormGroup: FormGroup;
  @Input() parameterName: string;
  @Input() queryUserMode: false;
  @HostBinding('attr.class') class = 'form-group';

  isInvalid(cname: string) {
    return FormHelper.isInvalid(this.contextFormGroup, cname);
  }

  get customFilterChecked() {
    return this.contextFormGroup && (+this.contextFormGroup.get('_par_' + this.parameterName + '_filter').value) === 110;
  }

  set customFilterChecked(val: any) {
    if (this.contextFormGroup) {
      this.contextFormGroup.get('_par_' + this.parameterName + '_filter').setValue(val ? 110 : 0);
      this.contextFormGroup.markAsDirty();
    }
  }

  get noValueChecked() {
    return this.contextFormGroup && (+this.contextFormGroup.get('_par_' + this.parameterName + '_filter').value) === 1;
  }

  set noValueChecked(val: any) {
    if (this.contextFormGroup) {
      this.contextFormGroup.get('_par_' + this.parameterName + '_filter').setValue(val ? 1 : 0);
      this.contextFormGroup.markAsDirty();
    }
  }

  get filtered() {
    const addressObj = this.contextFormGroup.get('_par_' + this.parameterName).value;
    return !!this.contextFormGroup.get('_par_' + this.parameterName + '_filter').value
      || addressObj.regionId || addressObj.cityId || addressObj.other || addressObj.countryId || addressObj.subjfedId
      || addressObj.streetId || addressObj.house || addressObj.building || addressObj.room || addressObj.addressShortText
      || addressObj.cadNumber || addressObj.coordsLatitude || addressObj.coordsLongitude;
  }
}
