import { Component } from '@angular/core';
import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { AnimalSearchModalComponent } from '../../pages/edit-animal/search/animal-search-modal.component';
import { AppNavigationService } from '../../logic/services/app-navigation.service';
import { FormGroup } from '@angular/forms';
import { ProductSearchModalComponent } from '../../pages/edit-product/search/product-search-modal.component';
import { of } from 'rxjs';
import { AgentSearchModalComponent } from '../../pages/edit-agent/search/agent-search-modal.component';
import { StableSearchModalComponent } from '../../pages/edit-stable/search/stable-search-modal.component';

@Component({
  selector: 'app-ag-grid-search-modal-editor',
  template: `
    <ng-container *ngIf="getControl().value; else noValue">
        <span style="max-width:calc(100% - 20px);overflow:hidden;text-overflow:ellipsis;padding-left:10px">
          {{getControl().value|lookup:params.lookupName:true|async}}
        </span>
        <button type="button" class="btn btn-danger btn-link itech-inline-tool-btn" title="Очистить"
                (click)="clearValue()" style="position:absolute;right:0;top:0;z-index:10;color:grey">
          <clr-icon shape="times"></clr-icon>
        </button>
    </ng-container>
    <ng-template #noValue>
      <button type="button" (click)="search()" class="btn btn-danger btn-link itech-inline-tool-btn"
              [style.width]="getControl().value ? '20px' : '100%'">
        <clr-icon shape="search"></clr-icon>
        <span *ngIf="!getControl().value">Поиск...</span>
      </button>
    </ng-template>
  `,
})
export class AgGridSearchModalEditorComponent implements ICellEditorAngularComp {

  public params: any;

  constructor(private navigationService: AppNavigationService) {
  }

  agInit(params: any): void {
    this.params = params;
    if (!this.getControl().value) {
      this.search();
    }
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  getControl() {
    return (this.params.data as FormGroup).get(this.params.controlName);
  }

  search() {
    this.getNavigationServiceObservable(this.params.lookupName)
      .subscribe(val => {
        if (val && val.length) {
          if (val.length > 1) {
            this.params.manySelectedCallback(this.params, val.slice(1));
          }
          this.changeValue(val[0]);
        } else if (val) {
          this.changeValue(val);
        }
        if (this.params['callbackAfterChangeValue'] && typeof this.params['callbackAfterChangeValue'] === 'function') {
          this.params.callbackAfterChangeValue(this.params);
        }
      });
  }

  getNavigationServiceObservable(lookupName: string) {
    const params = this.params.colDef.searchParams ? this.params.colDef.searchParams(this.params.data) : {};
    switch (lookupName) {
      case 'animal': return this.navigationService.searchManyAnimals(AnimalSearchModalComponent, params);
      case 'product': return this.navigationService.searchManyProducts(ProductSearchModalComponent, params);
      case 'agent': return this.navigationService.searchAgent(AgentSearchModalComponent, params);
      case 'stable': return this.navigationService.searchStable(StableSearchModalComponent, params);
      default: return of();
    }
  }

  getValue(): any {
    return this.getControl().value;
  }

  clearValue() {
    this.changeValue(null);
  }

  changeValue(newVal: any) {
    this.getControl().setValue(newVal);
    if (this.params.colDef.valueChangedCallback) {
      this.params.colDef.valueChangedCallback(this.getValue(), this.params.data, this.params.node, this.params.api);
    }
    this.params.api.stopEditing();
    this.params.api.redrawRows();
  }
}
