import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Input } from '@angular/core';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';
import { SecurityService } from '../../../logic/services/security.service';
import { StringHelper } from '../../../helpers/string-helper';
import { DateHelper } from '../../../helpers/date-helper';
import { combineLatest } from 'rxjs';
import { InstitutionTicketDataService } from '../../../logic/services/institution-ticket-data.service';
import { TicketsPlanningUiService } from './tickets-planning-ui-service';
import { FormBuilder, Validators } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';

@Component({
  selector: 'app-tickets-planning',
  templateUrl: './tickets-planning.component.html',
  styleUrls: ['./tickets-planning.component.css']
})
export class TicketsPlanningComponent implements OnInit {

  /* режим fixedEmployeeMode=true означает, что форма запущена как отдельный route-view, предназначенный для редактирования
  * услуг конкретного работника конкретного учреждения. В этом режиме может быть достпно сколько угодно клиентов, но только
  * одно учреждение и один специалист, что обуславлиает особенности отображения режима (не показываем те поля, значения которых
  * очевидны из контекста.
  * режим fixedEmployeeMode=false означает, что форма запущена как дочернее view в карточке человека. соответственно,
  * услуги на форме могут быть показаны только для одного клиента, а работников и учреждений может быть сколько угодно.
  * В этом режиме есть 2 "под-режима" - для работника учреждения (с возможностью редактирования) и для иных работников,
  * например, работников головной организации (без возможности редактирования)*/
  @Input() fixedEmployeeMode = true;
  @Input() fixedInstitutionId: number;

  workingEmployeeId: number; // сотрудник, соответствующий текущему пользователю
  workingBranchId: number; // филиал, соответствующий текущему пользователю
  workingInstitutionId: number; // учреждение, соответствующий текущему пользователю
  model: any = {
    displayedMonth: new Date().getMonth(),
    displayedYear: new Date().getFullYear(),
    oneDayDate: StringHelper.getISODate(new Date()),
    institutionId: this.workingInstitutionId,
    institutionEmployeeId: this.workingEmployeeId,
    ticketTemplates: [],
    zoomedDayIndex: undefined,
    viewMode: 1,
    searchParams: this.fb.group({
      institutionBranchId: [null, Validators.required],
    }),
  };

  constructor(private route: ActivatedRoute,
              private bookmarkService: BookmarkService,
              private alertService: AlertService,
              private dataCachingService: DataCachingService,
              private navigationService: AppNavigationService,
              private lookupSourceService: LookupSourceService,
              private institutionTicketDataService: InstitutionTicketDataService,
              private itemsCache: AddressPersonFioCacheService,
              private securityService: SecurityService,
              public ticketPlanningUiService: TicketsPlanningUiService,
              private fb: FormBuilder) {
  }

  get currentMonthDate(): Date {
    return new Date(this.model.displayedYear, this.model.displayedMonth, 1);
  }

  ngOnInit() {
    this.initAsStandaloneView();
    this.reloadDays();
    this.refreshTemplates();
  }

  private initAsStandaloneView() {

    this.securityService.getUserInfo().subscribe(userInfo => {
      this.workingInstitutionId = this.fixedInstitutionId || userInfo.institutionId || undefined;
      this.workingEmployeeId = userInfo.institutionEmployeeId || undefined;
      this.workingBranchId = userInfo.institutionBranchId || undefined;

        const existing = this.dataCachingService.getCachedData('TicketsPlanningComponent',
          this.workingInstitutionId + '-' + this.workingEmployeeId);

        if (existing) {
          this.model = existing;
        } else {
          const cDate = new Date();
          this.model = {
            displayedMonth: cDate.getMonth(),
            displayedYear: cDate.getFullYear(),
            oneDayDate: StringHelper.getISODate(cDate),
            institutionId: this.workingInstitutionId,
            institutionEmployeeId: this.workingEmployeeId,
            institutionBranchId: this.workingBranchId,
            zoomedDayIndex: undefined,
            viewMode: 1,
            searchParams: this.fb.group({
              institutionBranchId: [null, Validators.required],
            }),
          };
          this.reloadWorkingEmployeeDepartments();
          this.reloadDays();
          this.dataCachingService.addToCache('TicketsPlanningComponent',
            this.workingInstitutionId + '-' + this.workingEmployeeId, this.model, 2);
        }
      });
  }

  private reloadWorkingEmployeeDepartments() {

    combineLatest([
      this.lookupSourceService.getLookupObj('institution-employee' + this.workingInstitutionId),
      this.lookupSourceService.getLookupObj('institution-branch' + this.workingInstitutionId)
    ]).subscribe(([employees, branches]) => {
      this.model.workingEmployeeBranches = [];

      const employee = employees['Obj' + this.workingEmployeeId];

      if (!employee || !employee.branches || !employee.branches.length) {
        return;
      }

      this.model.institutionBranchId = employee.departments[0];

      employee.branches.forEach(branchId => {

        const branch = branches['Obj' + branchId];

        if (branch) {
          this.model.workingEmployeeBranches.push(branch);
        }
      });

      this.model.workingEmployeeBranches.sort((item1, item2) => item1.caption ? item1.caption.localeCompare(item2.caption) : 0);
    });
  }

  reloadDays() {
    this.reloadDaysCalendarMode();
  }

  reloadDaysCalendarMode() {

    FormHelper.markAsSubmitted(this.model.searchParams);

    if (!this.model.searchParams.valid) {
      return;
    }

    let currentDate = DateHelper.startOfTheWeek(new Date(this.model.displayedYear, this.model.displayedMonth, 1));
    currentDate.setHours(0, 0, 0, 0);
    const endDate =  DateHelper.endOfTheMonth(this.model.displayedYear, this.model.displayedMonth);

    const days = [];
    let index = 0;

    while (currentDate <= endDate) {
      const day = {
        index: index,
        date: currentDate,
        tickets: [],
        slots: [
          {tickets: [], timeStart: '00:00', timeEnd: '08:00', day: undefined},
          {tickets: [], timeStart: '08:00', timeEnd: '09:00', day: undefined},
          {tickets: [], timeStart: '09:00', timeEnd: '10:00', day: undefined},
          {tickets: [], timeStart: '10:00', timeEnd: '11:00', day: undefined},
          {tickets: [], timeStart: '11:00', timeEnd: '12:00', day: undefined},
          {tickets: [], timeStart: '12:00', timeEnd: '13:00', day: undefined},
          {tickets: [], timeStart: '13:00', timeEnd: '14:00', day: undefined},
          {tickets: [], timeStart: '14:00', timeEnd: '15:00', day: undefined},
          {tickets: [], timeStart: '15:00', timeEnd: '16:00', day: undefined},
          {tickets: [], timeStart: '16:00', timeEnd: '17:00', day: undefined},
          {tickets: [], timeStart: '17:00', timeEnd: '18:00', day: undefined},
          {tickets: [], timeStart: '18:00', timeEnd: '23:59:59', day: undefined}
        ],
        continuousTickets: []
      };

      day.slots.forEach(elem => {
        elem.day = day;
      });

      days.push(day);

      currentDate = DateHelper.addDays(currentDate, 1);
      index = index + 1;
    }

    this.model.days = days;

    this.institutionTicketDataService.fillDaysModelTickets(days,
      this.fixedEmployeeMode ? this.workingInstitutionId : undefined,
      this.fixedEmployeeMode ? this.model.searchParams.get('institutionBranchId').value : undefined
    ).subscribe({
      next: () => {
        if (this.model.zoomedDayIndex) {
          this.zoomDay(this.model.zoomedDayIndex);
        }
      }
    });
  }

  navigatePreviousMonth() {
    this.model.displayedMonth = this.model.displayedMonth - 1;
    if (this.model.displayedMonth < 0) {
      this.model.displayedMonth = 11;
      this.model.displayedYear = this.model.displayedYear - 1;
    }

    this.reloadDays();
  }

  navigateNextMonth() {
    this.model.displayedMonth = this.model.displayedMonth + 1;
    if (this.model.displayedMonth > 11) {
      this.model.displayedMonth = 0;
      this.model.displayedYear = this.model.displayedYear + 1;
    }

    this.reloadDays();
  }

  zoomDay(i: number) {
    if (i >= this.model.days.length - 1) {
      i = this.model.days.length - 2;
    }
    if (i < 1) {
      i = 1;
    }
    this.model.zoomedDayIndex = i;
    this.model.zoomedDays = [];
    this.model.zoomedDays.push(this.model.days[i - 1]);
    this.model.zoomedDays.push(this.model.days[i]);
    this.model.zoomedDays.push(this.model.days[i + 1]);
  }

  unZoom() {
    this.model.zoomedDayIndex = undefined;
    this.model.zoomedDays = undefined;
    this.model.expandedSlot = undefined;
  }

  private refreshTemplates() {
    this.institutionTicketDataService.refreshTemplates(this.model);
  }

  public editTemplate(data: any) {
   this.ticketPlanningUiService.editTicket({ data: data });
  }

  public distributeTickets(template: any) {
    const temp = Object.assign({}, template);
    temp.isDistribute = true;
    temp.isTemplate = false;
    temp.templateCaption = undefined;
    temp.id = undefined;
    temp.institutionId = this.workingInstitutionId;
    this.ticketPlanningUiService.editTicket({ data: temp });
  }
}
