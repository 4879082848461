/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@clr/angular";
import * as i2 from "@angular/common";
import * as i3 from "./app-link-button.component";
import * as i4 from "../../logic/services/app-navigation.service";
import * as i5 from "../../logic/services/lookup-source.service";
import * as i6 from "../../logic/services/address-person-fio-cache.service";
var styles_AppLinkButtonComponent = [];
var RenderType_AppLinkButtonComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppLinkButtonComponent, data: {} });
export { RenderType_AppLinkButtonComponent as RenderType_AppLinkButtonComponent };
function View_AppLinkButtonComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "clr-icon", [["shape", "flame"], ["size", "14"], ["style", "margin: 5px 0 0 5px;vertical-align:top;"]], [[4, "color", null]], null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i1.ClrIconCustomTag, [], null, null)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.result["iconColor"]; _ck(_v, 0, 0, currVal_0); }); }
function View_AppLinkButtonComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "clr-icon", [["shape", "recycle"], ["size", "14"], ["style", "margin-left: 5px"]], null, null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i1.ClrIconCustomTag, [], null, null)], null, null); }
function View_AppLinkButtonComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "clr-icon", [["shape", "truck"], ["size", "14"], ["style", "margin-left: 5px"]], null, null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i1.ClrIconCustomTag, [], null, null)], null, null); }
function View_AppLinkButtonComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "itech-text-field itech-control-xlarge"]], [[4, "width", "px"]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "a", [["class", "itech-person-link"]], [[8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickNavigation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppLinkButtonComponent_4)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppLinkButtonComponent_5)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppLinkButtonComponent_6)), i0.ɵdid(7, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(8, null, [" ", " ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.result["iconName"] && (_co.result["iconName"] === "flame")); _ck(_v, 3, 0, currVal_2); var currVal_3 = (_co.result["iconName"] && (_co.result["iconName"] === "recycle")); _ck(_v, 5, 0, currVal_3); var currVal_4 = (_co.result["iconName"] && (_co.result["iconName"] === "truck")); _ck(_v, 7, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.widthControl; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 1, 0, currVal_1); var currVal_5 = (_co.result["shortCaption"] || _co.result["caption"]); var currVal_6 = (_co.idComment ? (("(" + (_co.resultComment["shortCaption"] || _co.resultComment["caption"])) + ")") : ""); _ck(_v, 8, 0, currVal_5, currVal_6); }); }
function View_AppLinkButtonComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "label", [["style", "font-weight:bold;"]], [[1, "for", 0]], null, null, null, null)), i0.ɵdid(1, 212992, null, 0, i1.ClrLabel, [[2, i1.ɵbe], [2, i1.ɵbf], [2, i1.ɵbg], i0.Renderer2, i0.ElementRef], null, null), (_l()(), i0.ɵted(2, null, [" ", " ", " "]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).forAttr; _ck(_v, 0, 0, currVal_0); var currVal_1 = (_co.result["shortCaption"] || _co.result["caption"]); var currVal_2 = (_co.idComment ? (("(" + (_co.resultComment["shortCaption"] || _co.resultComment["caption"])) + ")") : ""); _ck(_v, 2, 0, currVal_1, currVal_2); }); }
function View_AppLinkButtonComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppLinkButtonComponent_3)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["elseBeforeZero", 2]], null, 0, null, View_AppLinkButtonComponent_7))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.result["id"] > 0); var currVal_2 = i0.ɵnov(_v, 4); _ck(_v, 3, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.preText; _ck(_v, 1, 0, currVal_0); }); }
function View_AppLinkButtonComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppLinkButtonComponent_2)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.result; _ck(_v, 2, 0, currVal_0); }, null); }
function View_AppLinkButtonComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.noValueText; _ck(_v, 1, 0, currVal_0); }); }
function View_AppLinkButtonComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppLinkButtonComponent_9)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.noValueText; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AppLinkButtonComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppLinkButtonComponent_1)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["noValue", 2]], null, 0, null, View_AppLinkButtonComponent_8))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.id || !_co.noValueText); var currVal_1 = i0.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_AppLinkButtonComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-link-button", [], null, null, null, View_AppLinkButtonComponent_0, RenderType_AppLinkButtonComponent)), i0.ɵdid(1, 573440, null, 0, i3.AppLinkButtonComponent, [i4.AppNavigationService, i5.LookupSourceService, i6.AddressPersonFioCacheService], null, null)], null, null); }
var AppLinkButtonComponentNgFactory = i0.ɵccf("app-link-button", i3.AppLinkButtonComponent, View_AppLinkButtonComponent_Host_0, { preText: "preText", id: "id", lookupName: "lookupName", useShort: "useShort", idComment: "idComment", lookupNameComment: "lookupNameComment", useShortComment: "useShortComment", widthControl: "widthControl", noValueText: "noValueText" }, {}, []);
export { AppLinkButtonComponentNgFactory as AppLinkButtonComponentNgFactory };
