import { EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { FormBuilder } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';
import { EditAnimalCommonFormComponent } from './edit-animal-common-form.component';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { AnimalDataService } from '../../../logic/services/animal-data.service';
import { InstitutionTicketDataService } from '../../../logic/services/institution-ticket-data.service';
var EditAnimalCommonComponent = /** @class */ (function () {
    function EditAnimalCommonComponent(bookmarkService, animalDataService, alertService, cache, dataCachingService, waitingOverlay, fb, navigationService, institutionTicketDataService) {
        this.bookmarkService = bookmarkService;
        this.animalDataService = animalDataService;
        this.alertService = alertService;
        this.cache = cache;
        this.dataCachingService = dataCachingService;
        this.waitingOverlay = waitingOverlay;
        this.fb = fb;
        this.navigationService = navigationService;
        this.institutionTicketDataService = institutionTicketDataService;
        this.isExternalAnimal = false;
        this.onNavigateEventsClicked = new EventEmitter();
        this.model = {};
        this.storing = false;
        this.isInvalid = FormHelper.isInvalid;
    }
    Object.defineProperty(EditAnimalCommonComponent.prototype, "contextFormGroup", {
        get: function () {
            return this.model ? this.model.contextFormGroup : undefined;
        },
        enumerable: true,
        configurable: true
    });
    EditAnimalCommonComponent.prototype.ngOnChanges = function (changes) {
        if (this.id) {
            this.refreshFormDataInternal();
        }
    };
    EditAnimalCommonComponent.prototype.store = function () {
        var _this = this;
        FormHelper.markAsSubmitted(this.contextFormGroup);
        if (!this.contextFormGroup.valid) {
            return;
        }
        this.storing = true;
        this.animalDataService.storeAnimalCommonInfo(this.contextFormGroup.value).subscribe(function (val) {
            _this.storing = false;
            _this.invalidateCaches();
            _this.bookmarkService.refreshAnimalCardBookmark(_this.id, _this.newAgentId, _this.newStableId);
            FormHelper.setSingleFormGroupServerSideValidationErrors({}, _this.model, _this.contextFormGroup);
            _this.alertService.success('Общая информация для [' +
                (_this.contextFormGroup.get('caption').value || _this.contextFormGroup.get('pin').value) + '] успешно сохранена');
            _this.refreshFormData();
        }, function (error) {
            _this.storing = false;
            FormHelper.setSingleFormGroupServerSideValidationErrors(error, _this.model, _this.contextFormGroup);
            window.document.getElementById('animal-common-content').scrollTop = 0;
        });
    };
    EditAnimalCommonComponent.prototype.invalidateCaches = function (isExternalAnimal) {
        if (isExternalAnimal === void 0) { isExternalAnimal = false; }
        if (!isExternalAnimal) {
            this.cache.invalidateAnimal(this.id);
        }
        else {
            this.cache.invalidateAnimalExternal(this.contextFormGroup.get('tempId').value);
        }
        var agentId = this.contextFormGroup.get('currentAgentId').value;
        if (agentId) {
            this.dataCachingService.removeCachedData('EditAgentAnimals', agentId.toString());
        }
        var stableId = this.contextFormGroup.get('currentStableId').value;
        if (stableId) {
            this.dataCachingService.removeCachedData('EditStableAnimals', stableId.toString());
        }
    };
    EditAnimalCommonComponent.prototype.cancelEdit = function () {
        this.dataCachingService.removeCachedData('EditAnimalCommon', this.id.toString());
        this.refreshFormDataInternal();
    };
    EditAnimalCommonComponent.prototype.refreshFormData = function () {
        this.dataCachingService.removeCachedData('EditAnimalCommon', this.id.toString());
        this.refreshFormDataInternal();
    };
    EditAnimalCommonComponent.prototype.refreshFormDataInternal = function () {
        var _this = this;
        this.waitingOverlay.StartWaiting();
        this.model = { id: this.id, serverSideValidationErrors: [] };
        var existing = this.dataCachingService.getCachedData('EditAnimalCommon' +
            (this.isExternalAnimal ? '_External' : ''), this.id.toString());
        if (existing && existing.newAgentId === this.newAgentId && existing.newStableId === this.newStableId) {
            this.model = existing;
            this.waitingOverlay.EndWaiting();
        }
        else {
            (!this.isExternalAnimal
                ? this.animalDataService.getAnimalCommonForEdit(this.id)
                : this.animalDataService.getTempAnimalCommonForEdit(this.id))
                .subscribe({
                next: function (data) {
                    _this.model.id = data.id;
                    _this.model.contextFormGroup = EditAnimalCommonFormComponent.createFormGroup(_this.fb, _this.model, data);
                    if (_this.model.contextFormGroup.get('birthDate').value && _this.model.contextFormGroup.get('animalGroup').value) {
                        _this.model.contextFormGroup.get('birthDate').value = _this.model.contextFormGroup.get('birthDate').value + 'T00:00:00';
                    }
                    _this.model.newAgentId = _this.newAgentId;
                    _this.model.newStableId = _this.newStableId;
                    _this.cache.invalidateAnimal(_this.id);
                    _this.dataCachingService.addToCache('EditAnimalCommon' +
                        (_this.isExternalAnimal ? '_External' : ''), _this.id.toString(), _this.model);
                    if (!_this.isExternalAnimal) {
                        _this.bookmarkService.refreshAnimalCardBookmark(_this.id, _this.newAgentId, _this.newStableId);
                    }
                    _this.waitingOverlay.EndWaiting();
                }, error: function () {
                    _this.waitingOverlay.EndWaiting();
                }
            });
        }
    };
    EditAnimalCommonComponent.prototype.cloneAnimal = function () {
        this.navigationService.performAnimalCreation(undefined, undefined, this.id);
    };
    EditAnimalCommonComponent.prototype.navigateToEventsPage = function () {
        if (this.onNavigateEventsClicked) {
            this.onNavigateEventsClicked.emit();
        }
    };
    EditAnimalCommonComponent.prototype.deleteAnimal = function () {
        var _this = this;
        this.alertService.confirmModal('Вы уверены, что хотите удалить безвозвратно карточку животного?').subscribe(function () {
            _this.animalDataService.deleteAnimal(_this.id).subscribe(function () {
                _this.dataCachingService.removeCachedData('EditAnimalCommon', _this.id.toString());
                _this.bookmarkService.removeOpenCardBookmark(['/animal-edit'], _this.id.toString());
                _this.invalidateCaches();
            });
        });
    };
    EditAnimalCommonComponent.prototype.confirmAnimalFromExternal = function () {
        var _this = this;
        this.storing = true;
        this.animalDataService.confirmAnimalExternal(this.id)
            .subscribe(function (val) {
            _this.clearCacheExternal(val.id);
            _this.tryChangeAnimalInTickets(val);
            _this.alertService.success("\u041A\u0430\u0440\u0442\u043E\u0447\u043A\u0430 \u0436\u0438\u0432\u043E\u0442\u043D\u043E\u0433\u043E [" + (_this.contextFormGroup.get('caption').value
                || _this.contextFormGroup.get('pin').value) + "] \u0443\u0441\u043F\u0435\u0448\u043D\u043E \u043F\u043E\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043D\u0430");
            _this.navigationService.performAnimalEditing(val.id);
            _this.storing = false;
        }, function () {
            FormHelper.setSingleFormGroupServerSideValidationErrors({}, _this.model, _this.contextFormGroup);
            _this.storing = true;
        });
    };
    EditAnimalCommonComponent.prototype.rejectAnimalExternal = function () {
        var _this = this;
        this.alertService.confirmModal('Вы уверены, что хотите отказать пользователю внешней системы' +
            ' в регистрации карточки животного?').subscribe(function () {
            _this.animalDataService.rejectAnimalExternal(_this.id).subscribe(function () {
                _this.dataCachingService.removeCachedData('EditAnimalCommon_External', _this.id.toString());
                _this.dataCachingService.removeCachedData('AddressPersonFioCacheService_AnimalExternal', _this.id.toString(10));
                _this.invalidateCaches(true);
                if (_this.contextFormGroup.get('currentAgentId').value) {
                    _this.navigationService.performAgentEditing(_this.contextFormGroup.get('currentAgentId').value);
                }
                else if (_this.contextFormGroup.get('currentStableId').value) {
                    _this.navigationService.performStableEditing(_this.contextFormGroup.get('currentStableId').value);
                }
            });
        });
    };
    EditAnimalCommonComponent.prototype.clearCacheExternal = function (newId) {
        this.dataCachingService.removeCachedData('EditAnimalCommon', newId.toString());
        this.dataCachingService.removeCachedData('AddressPersonFioCacheService', newId.toString(10));
        this.invalidateCaches(true);
        this.dataCachingService.removeCachedData('EditAnimalCommon_External', this.id.toString());
        this.dataCachingService.removeCachedData('AddressPersonFioCacheService_AnimalExternal', this.id.toString(10));
        FormHelper.setSingleFormGroupServerSideValidationErrors({}, this.model, this.contextFormGroup);
    };
    EditAnimalCommonComponent.prototype.tryChangeAnimalInTickets = function (animal) {
        var _this = this;
        this.institutionTicketDataService.searchByAnimal(animal.id)
            .subscribe(function (tickets) {
            return tickets.forEach(function (ticket) {
                return _this.dataCachingService.removeCachedDataByStartKey('TicketsPlanningComponent', ticket.institutionId);
            });
        });
        if (animal.currentAgentId) {
            this.dataCachingService.removeCachedData('EditAgentTickets', animal.currentAgentId.toString());
        }
    };
    EditAnimalCommonComponent.prototype.createProduct = function () {
        this.navigationService.performProductCreation(undefined, undefined, this.id, 4);
    };
    return EditAnimalCommonComponent;
}());
export { EditAnimalCommonComponent };
