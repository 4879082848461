import { Component, OnInit } from '@angular/core';
import { FormHelper } from '../../ui/controls/form-helper';
import { StringHelper } from '../../helpers/string-helper';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DataCachingService } from '../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../ui/infrastructure/global-waiting-overlay.service';
import { MetadataService } from '../../logic/services/metadata.service';
import { BookmarkService } from '../../logic/services/bookmark.service';
import { DateHelper } from '../../helpers/date-helper';

@Component({
  templateUrl: './institution-employee-statistic.component.html',
})
export class InstitutionEmployeeStatisticComponent implements OnInit {

  model = InstitutionEmployeeStatisticComponent.getDefaultModel(this.fb);

  isInvalid = FormHelper.isInvalid;

  defColDef = {
    sortable: true,
    filter: true,
    resizable: true,
  };

  gridColumnDefs: any[] = [];
  gridApi: any;

  private static getDefaultModel(fb?: FormBuilder, defaultFilterParams?: any) {
    return {
      searchResults: [],
      pinnedBottomRowData: [],
      filterFormGroup: fb ? InstitutionEmployeeStatisticComponent.buildSearchForm(fb, defaultFilterParams) : undefined,
    };
  }

  public static buildSearchForm(fb: FormBuilder, defaultFilterParams: any = {}) {

    return fb.group({
      typeId: [defaultFilterParams.typeId, Validators.required],
      institutionId: defaultFilterParams.institutionId,
      dateFrom: [defaultFilterParams.dateFrom, Validators.compose([Validators.required, FormHelper.validateDateTimePicker()])],
      dateTo: [defaultFilterParams.dateTo, Validators.compose([Validators.required, FormHelper.validateDateTimePicker()])],
      viewType: 1,
    });
  }

  constructor(private route: ActivatedRoute,
              private dataCachingService: DataCachingService,
              private globalWaitingOverlayService: GlobalWaitingOverlayService,
              private metadataService: MetadataService,
              private fb: FormBuilder,
              private bookmarkService: BookmarkService) {
  }

  ngOnInit() {

    this.route.paramMap
      .subscribe(() => {

        const existing = this.dataCachingService.getCachedData('InstitutionEmployeeStatisticComponent', '1');

        if (existing) {
          this.model = existing;
        } else {
          this.model = InstitutionEmployeeStatisticComponent.getDefaultModel(this.fb, {
            dateFrom: StringHelper.getISODate(DateHelper.startOfTheWeek(DateHelper.addMonths(new Date(), -1))),
            dateTo: StringHelper.getISODate(DateHelper.addDays(DateHelper.endOfTheWeek(new Date()), 1)),
          });
          this.dataCachingService.addToCache('InstitutionEmployeeStatisticComponent', '1', this.model);
          this.refreshResults();
        }

        this.bookmarkService.addDictionaryBookmark(
          'Статистика действий пользователей',
          ['/institution-employee-statistic/']);
      });
  }

  agGridReady(params: any) {
    this.gridApi = params.api;
  }

  refreshResults() {

    FormHelper.markAsSubmitted(this.model.filterFormGroup);

    if (!this.model.filterFormGroup.valid) {
      return;
    }

    this.dataCachingService.removeCachedData('InstitutionEmployeeStatisticComponent', 'sourceData');

    this.globalWaitingOverlayService.StartWaiting();

    this.metadataService.getEmployeeStatistic(
          this.model.filterFormGroup.get('typeId').value,
          new Date(this.model.filterFormGroup.get('dateFrom').value),
          new Date(this.model.filterFormGroup.get('dateTo').value))
      .subscribe({
        next: data => {
          this.dataCachingService.addToCache('InstitutionEmployeeStatisticComponent', 'sourceData', data);
          this.buildDynamicTable(data);
          this.globalWaitingOverlayService.EndWaiting();

        }, error: () => {
          this.globalWaitingOverlayService.EndWaiting();
        }
      });
  }

  filterEnterPressed($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      this.refreshResults();
    }
  }

  buildDynamicTable(data: any[] = []) {
    this.gridColumnDefs = [];
    this.gridColumnDefs.push(this.getInstitutionColumn());
    if (+this.model.filterFormGroup.get('viewType').value === 2) {
      this.gridColumnDefs.push(this.getUserColumn());
    }
    const dates = [];
    const groupingData = {};

    data.sort((a, b) => a.dateFrom.localeCompare(b.dateFrom));
    data.forEach(el => {
      if (!dates.includes(el.dateFrom)) {
        this.gridColumnDefs.push(
          {
            headerName: StringHelper.getRuDate(el.dateFrom) + '\n' + StringHelper.getRuDate(el.dateTo),
            field: el.dateFrom,
            width: 110,
            valueGetter: params => params.data && params.data[el.dateFrom] ? params.data[el.dateFrom].count : 0,
            cellRenderer: this.countsCellRenderer
          });
        dates.push(el.dateFrom);
      }
      if (+this.model.filterFormGroup.get('viewType').value === 2) {
        this.groupingByUser(groupingData, el);
      } else {
        this.groupingByInstitution(groupingData, el);
      }
    });

    this.gridColumnDefs.push({headerName: 'Итого', sort: 'desc', field: 'sum', width: 110, cellStyle: {'font-weight': 'bold'}});

    const newData = Object.values(groupingData);

    const pinnedBottomRowData = {institutionCaption: 'Итого', sum: 0};

      newData.forEach(el => {
      if (!(dates[0] in el)) {
        el[dates[0]] = {count: 0, diff: 0};
      }
      if (!pinnedBottomRowData[dates[0]]) {
        pinnedBottomRowData[dates[0]] = {count: 0, diff: 0};
      }
      pinnedBottomRowData[dates[0]].count += el[dates[0]].count;
      pinnedBottomRowData.sum += el[dates[0]].count;
      el['sum'] = el[dates[0]].count;
    });

    for (let i = 1; i < dates.length; i++) {
      if (!pinnedBottomRowData[dates[i]]) {
        pinnedBottomRowData[dates[i]] = {count: 0, diff: 0};
      }
      newData.forEach(el => {
        if (dates[i] in el) {
          if (dates[i - 1] in el) {
            el[dates[i]].diff = el[dates[i]].count - el[dates[i - 1]].count;
          } else {
            el[dates[i]].diff = el[dates[i]].count;
          }
        } else if (dates[i - 1] in el) {
          el[dates[i]] = {count: 0, diff: el[dates[i - 1]].count * -1};
        } else {
          el[dates[i]] = {count: 0, diff: 0};
        }
        el['sum'] += el[dates[i]].count;
        pinnedBottomRowData[dates[i]].count += el[dates[i]].count;
        pinnedBottomRowData[dates[i]].diff = pinnedBottomRowData[dates[i]].count - pinnedBottomRowData[dates[i - 1]].count;
        pinnedBottomRowData.sum += el[dates[i]].count;
      });
    }

    this.model.searchResults = newData.filter(el => +el['sum']);
    this.model.pinnedBottomRowData = [pinnedBottomRowData];
    if (this.gridApi) {
      this.gridApi.setRowData(this.model.searchResults);
      this.gridApi.setPinnedBottomRowData(this.model.pinnedBottomRowData);
    }
  }

  groupingByUser(groupingData: any, rowData: any) {
    if (!groupingData[rowData.userId]) {
      groupingData[rowData.userId] = {
        userId: rowData.userId,
        userCaption: rowData.userCaption,
        institutionCaption: rowData.institutionCaption,
        institutionId: rowData.institutionId,
      };
    }
    this.fillRowData(groupingData, rowData.userId, rowData);
  }

  groupingByInstitution(groupingData: any, rowData: any) {
    if (!groupingData[rowData.institutionId]) {
      groupingData[rowData.institutionId] = {
        institutionCaption: rowData.institutionCaption,
        institutionId: rowData.institutionId,
      };
    }
    this.fillRowData(groupingData, rowData.institutionId, rowData);
  }

  fillRowData(groupingData: any, mainFieldName: string, rowData: any) {
    if (!groupingData[mainFieldName][rowData.dateFrom]) {
      groupingData[mainFieldName][rowData.dateFrom] = {count: 0, diff: 0};
    }
    groupingData[mainFieldName][rowData.dateFrom].count += (rowData[this.getFieldNameByCurrentType()] || 0);
  }

  getFieldNameByCurrentType() {
    if (+this.model.filterFormGroup.get('typeId').value === 1) {
      return 'common';
    }
    if (+this.model.filterFormGroup.get('typeId').value === 2) {
      return 'agentCreated';
    }
    if (+this.model.filterFormGroup.get('typeId').value === 3) {
      return 'agentEdited';
    }
    if (+this.model.filterFormGroup.get('typeId').value === 4) {
      return 'stableCreated';
    }
    if (+this.model.filterFormGroup.get('typeId').value === 5) {
      return 'stableEdited';
    }
    if (+this.model.filterFormGroup.get('typeId').value === 6) {
      return 'animalCreated';
    }
    if (+this.model.filterFormGroup.get('typeId').value === 7) {
      return 'animalEdited';
    }
    if (+this.model.filterFormGroup.get('typeId').value === 8) {
      return 'drugCreated';
    }
    if (+this.model.filterFormGroup.get('typeId').value === 9) {
      return 'drugEdited';
    }
    if (+this.model.filterFormGroup.get('typeId').value === 10) {
      return 'eventCreated';
    }
    if (+this.model.filterFormGroup.get('typeId').value === 11) {
      return 'eventEdited';
    }
    if (+this.model.filterFormGroup.get('typeId').value === 12) {
      return 'productExpertiseCreated';
    }
    if (+this.model.filterFormGroup.get('typeId').value === 13) {
      return 'productExpertiseEdited';
    }
    if (+this.model.filterFormGroup.get('typeId').value === 14) {
      return 'productConfirm';
    }
    if (+this.model.filterFormGroup.get('typeId').value === 15) {
      return 'productCleared';
    }
    return '';
  }

  getUserColumn() {
    return {
      headerName: 'Пользователь', field: 'userId', width: 170, pinned: 'left',
      valueGetter: params => params && params.data ? params.data.userCaption : '<<неизвестно>>',
    };
  }

  getInstitutionColumn() {
    return {
      headerName: 'Учреждение', field: 'institutionId', width: 240, pinned: 'left',
      valueGetter: params => params && params.data ? params.data.institutionCaption : '<<неизвестно>>',
    };
  }

  changeViewType() {
    const cachedData = this.dataCachingService.getCachedData('InstitutionEmployeeStatisticComponent', 'sourceData');
    if (cachedData) {
      this.buildDynamicTable(cachedData);
    } else {
      this.refreshResults();
    }
  }

  countsCellRenderer(row: any) {
    if (!row.data || !row.colDef || !row.colDef.field) {
      return 0;
    }

    const value = row.data[row.colDef.field];

    const difference = !!value.diff
      ? value.diff < 0
        ? ' <clr-icon shape="caret down" size="12" style="color:red"></clr-icon><span style="color:red">' + (-value.diff) + '</span>'
        : ' <clr-icon shape="caret up" size="12" style="color:green"></clr-icon><span style="color:green">' + value.diff + '</span>'
      : '';

    return value.count + '&nbsp;' + difference;
  }
}
