
      <div style="min-height:90vh">
        <div *ngIf="loading" style="min-height:600px;text-align:center">
          <span class="spinner" style="top:288px"></span>
        </div>
        <div *ngIf="!loading">
          <button class="btn btn-link btn-primary" *ngIf="isFuncSourceFormat()" (click)="buildSourceFormat()">
              <clr-icon shape="download"></clr-icon>
              Скачать в исходном формате</button>
          <iframe #previewFile style="width:100%;height:82vh" src="" type="application/pdf"></iframe>
        </div>
      </div>