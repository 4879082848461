<div id="interceptNoAccess">
  <div *ngIf="ctlr.getContextFormGroup(id)"
       style="position: absolute; left: 0; right: 0; top: 48px; bottom: 0; overflow-y: scroll">
    <div class="form compact itech-quarantine-form" [formGroup]="ctlr.getContextFormGroup(id)" style="padding-right:20px">
      <section class="form-block itech-block-normal itech-request-area" formArrayName="events">

        <table class="itech-qua-events-tbl">
          <thead>
          <tr>
            <td>
              №<br>п/п
            </td>
            <td>
              Наименование мероприятия
            </td>
            <td>
              Срок выполнения
            </td>
            <td>
              Ответственные
            </td>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let branchId of ctlr.getUsedBranchIds(id); index as kk">

            <tr>
              <td colspan="4" style="font-weight: bold">
                {{branchId|lookup:'quarantine-event-branch'|async}}
              </td>
            </tr>

            <ng-container
              *ngFor="let item of ctlr.getEventsByBranch(id, branchId, ctlr.getUsedBranchIds(id).length - 1 === kk); index as i"
              [formGroup]="item">

              <tr *ngIf="!item.get('__editing').value" (mouseenter)="item.get('__hovered').setValue(true)"
                  (mouseleave)="item.get('__hovered').setValue(false)">
                <td>
                  {{kk+1}}.{{i+1}}
                </td>
                <td style="position: relative">
                  {{item.get('eventTypeId').value | lookup: 'quarantine-event-type' | async}}

                  <div style="color: green; font-style: italic; font-size: 12px"
                       *ngIf="!item.get('__reporting').value && item.get('dateCompleted').value">
                    <clr-icon shape="check"></clr-icon>&nbsp;{{item.get('dateCompleted').value|rdate}}&nbsp;{{item.get('comments').value}}

                    <app-single-file *ngFor="let file of item.get('files').controls"
                                     [contextFormGroup]="file" [isAccessEdit]="false" [isAccessView]='true'
                                     [uriUpload]="'/quarantine/upload-file/' + id" (changeFile)="ctlr.singleFileChanged(id, item)"
                                     [downloadFileUri]="'quarantine/files/preload'">
                    </app-single-file>
                  </div>

                  <div *ngIf="item.get('__hovered').value && !item.get('__reporting').value"
                    style="position: absolute; right: 10px; bottom: 3px; background: #ddd; border-radius: 3px">
                    &nbsp;<a class="itech-link in-table-link" (click)="ctlr.deleteEvent(id, item)" style="color: darkred">
                    <clr-icon shape="trash"></clr-icon>&nbsp;удалить</a>
                    &nbsp;<a class="itech-link in-table-link"
                             (click)="navigationService.navigateJournal(undefined, 22, id, item.value.id)">
                    <clr-icon shape="view-list"></clr-icon>&nbsp;журнал</a>

                    &nbsp;<a class="itech-link in-table-link" (click)="ctlr.moveUp(id, item)">
                    <clr-icon shape="caret up"></clr-icon>&nbsp;вверх</a>
                    &nbsp;<a class="itech-link in-table-link" (click)="ctlr.moveDown(id, item)">
                    <clr-icon shape="caret down"></clr-icon>&nbsp;вниз</a>

                    &nbsp;<a class="itech-link in-table-link" (click)="ctlr.goEditing(id, item)">
                    <clr-icon shape="pencil"></clr-icon>&nbsp;изменить</a>
                    &nbsp;<a class="itech-link in-table-link" (click)="ctlr.goReporting(id, item)">
                    <clr-icon shape="check"></clr-icon>&nbsp;отчет</a>
                  </div>

                  <div *ngIf="item.get('__reporting').value">
                    <div class="form-group">
                      <label for="dateCompleted{{i}}">Дата исполнения</label>

                      <label for="dateCompleted{{i}}" aria-haspopup="true" role="tooltip"
                             class="tooltip tooltip-validation tooltip-md"
                             [class.invalid]="isInvalid(item, 'dateCompleted')">
                        <input type="date" id="dateCompleted{{i}}" class="itech-control-normal"
                               formControlName="dateCompleted">
                        <app-validation-tooltip [input]="item.controls['dateCompleted']"></app-validation-tooltip>
                      </label>
                      &nbsp;&nbsp;
                      <label for="comments{{i}}" aria-haspopup="true" role="tooltip"
                             class="tooltip tooltip-validation tooltip-md"
                             [class.invalid]="isInvalid(item, 'comments')">
                        <input type="text" id="comments{{i}}" placeholder="Текст отчета об исполнении"
                               class="itech-control-large"
                               formControlName="comments">
                        <app-validation-tooltip [input]="item.controls['comments']"></app-validation-tooltip>
                      </label>

                    </div>

                    <div class="form-group itech-v-resize">
                      <label></label>

                      <app-single-file *ngFor="let file of item.get('files').controls"
                                       [contextFormGroup]="file" [isAccessEdit]="true"
                                       [uriUpload]="'/quarantine/upload-file/' + id"
                                       (changeFile)="ctlr.singleFileChanged(id,item)"
                                       [downloadFileUri]="'quarantine/files/preload'">
                      </app-single-file>
                    </div>

                    <div>
                      <button [clrLoading]="ctlr.getStoring(id)" class="btn btn-link itech-form-save"
                              [disabled]="!ctlr.getContextFormGroup(id).dirty || ctlr.getStoring(id)"
                              (click)="ctlr.store(id, item)">
                        <clr-icon shape="check"></clr-icon>&nbsp;Сохранить
                      </button>
                      <button class="btn btn-link itech-form-cancel" [disabled]="!ctlr.getContextFormGroup(id).dirty"
                              (click)="ctlr.refreshFormData(id)">
                        <clr-icon shape="undo"></clr-icon>&nbsp;Отменить
                      </button>
                    </div>
                  </div>

                </td>
                <td>
                  {{item.get('periodTypeId').value == 0? item.get('periodTypeOther').value
                  : (item.get('periodTypeId').value | lookup: 'quarantine-event-period' | async)}}
                </td>
                <td>
                  {{ctlr.getResponsiblesTitle(id,item)|async}}
                </td>
              </tr>

              <tr *ngIf="item.get('__editing').value">
                <td>
                  {{kk+1}}.{{i+1}}
                <td>
                  <app-combo-lookup [validationTooltipRight]="true" class="itech-control-xxlarge" [sorted]="true"
                                    [contextControlName]="'eventTypeId'" [contextControlId]="'eventTypeId' + i"
                                    [lookupName]="'quarantine-event-type'"
                                    [contextFormGroup]="item"></app-combo-lookup>

                  <div>
                    <button [clrLoading]="ctlr.getStoring(id)" class="btn btn-link itech-form-save"
                            [disabled]="!ctlr.getContextFormGroup(id).dirty || ctlr.getStoring(id)"
                            (click)="ctlr.store(id, item)">
                      <clr-icon shape="check"></clr-icon>&nbsp;Сохранить
                    </button>
                    <button class="btn btn-link itech-form-cancel" [disabled]="!ctlr.getContextFormGroup(id).dirty"
                            (click)="ctlr.refreshFormData(id)">
                      <clr-icon shape="undo"></clr-icon>&nbsp;Отменить
                    </button>
                  </div>
                </td>
                <td>
                  <app-combo-lookup [validationTooltipBottomLeft]="true" class="itech-control-large" [sorted]="true"
                                    [contextControlName]="'periodTypeId'" [contextControlId]="'periodTypeId' + i"
                                    [lookupName]="'quarantine-event-period'"
                                    [contextFormGroup]="item"></app-combo-lookup>
                  <br>
                  <label *ngIf="item.get('periodTypeId').value == 0" for="periodTypeOther{{i}}" aria-haspopup="true"
                         role="tooltip"
                         class="tooltip tooltip-validation tooltip-md"
                         [class.invalid]="isInvalid(item, 'periodTypeOther')">
                    <input type="text" id="periodTypeOther{{i}}" placeholder="Иной срок" class="itech-control-large"
                           formControlName="periodTypeOther">
                    <app-validation-tooltip [input]="item.controls['periodTypeOther']"></app-validation-tooltip>
                  </label>
                </td>
                <td>
                  <app-combo-inline-multilookup [lookupName]="'quarantine-event-responsible'"
                                                [contextControlName]="'responsibleTypeId'" [noControlsDivider]="true"
                                                [contextFormGroup]="item" [sorted]="true">
                  </app-combo-inline-multilookup>
                  <label for="responsibleOther{{i}}" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md"
                         [class.invalid]="isInvalid(item, 'responsibleOther')">
                    <input type="text" id="responsibleOther{{i}}" placeholder="Иной ответственный"
                           class="itech-control-large" style="width:300px"
                           formControlName="responsibleOther">
                    <app-validation-tooltip [input]="item.controls['responsibleOther']"></app-validation-tooltip>
                  </label>
                  <app-combo-lookup  *ngIf="ctlr.hasKgkuResponsible(id, item)" [validationTooltipRight]="true" [sorted]="true"
                                     [contextControlName]="'responsibleInstitutionId'"
                                     [contextControlId]="'responsibleInstitutionId' + i"
                                     [lookupName]="'institution'" [contextFormGroup]="item"></app-combo-lookup>
                </td>
              </tr>
            </ng-container>
          </ng-container>

          <tr>
            <td>
            </td>
            <td>
              <button class="btn btn-link" (click)="ctlr.addEvent(id)" [disabled]="ctlr.getContextFormGroup(id).dirty">
                <clr-icon shape="plus"></clr-icon>&nbsp;Добавить
              </button>
            </td>
            <td>
            </td>
            <td>
            </td>
          </tr>

          </tbody>
        </table>
      </section>
    </div>
  </div>
</div>
