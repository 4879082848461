import { Component, Input } from '@angular/core';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstitutionDataService } from '../../../logic/services/institution-data.service';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { AddressComponent } from '../../../ui/controls/address.component';
import { AppFormGroup } from '../../../ui/controls/app-form-group';

@Component({
  selector: 'app-edit-institution-branches',
  templateUrl: './edit-institution-branches.component.html'
})
export class EditInstitutionBranchesComponent {

  @Input() model: any = {};
  storing = false;

  get contextFormGroup(): AppFormGroup {
    this.ensureFormGroup();
    return this.model.contextFormGroup;
  }

  private _id: number;
  @Input()
  get id(): number {
    return this._id;
  }

  set id(id: number) {
    if (this._id !== id) {
      this._id = id;
      this.refreshFormDataInternal();
    }
  }

  constructor(private institutionDataService: InstitutionDataService,
              private alertService: AlertService,
              private dataCachingService: DataCachingService,
              private waitingOverlay: GlobalWaitingOverlayService,
              private lookupSourceService: LookupSourceService,
              private fb: FormBuilder,
              public navigationService: AppNavigationService) {
  }

  isInvalid(fg: FormGroup, cname: string) {
    return FormHelper.isInvalid(fg, cname);
  }

  ensureFormGroup(arr: any[] = []) {
    if (!this.model.contextFormGroup) {
      this.model.contextFormGroup = this.fb.group({
        branches: this.buildFormArray(arr)
      });
    }
  }

  getBranchGroupDef(val: any = {}) {
    return {
      id: val.id,
      caption: [val.caption, Validators.required],
      isAddress: !!val.address,
      addressId: val.addressId,
      address: this.fb.group(AddressComponent.buildFormGroupDef(this.fb, this.model, val.address, false)),
      email: val.email,
      phone: val.phone,
      isVetClinic: val.isVetClinic,
    };
  }

  buildFormArray(branchesData: any[]) {
    return this.fb.array(branchesData.map(item => this.fb.group(this.getBranchGroupDef(item))));
  }

  get branches(): FormArray {
    return this.contextFormGroup.get('branches') as FormArray;
  }

  store() {

    FormHelper.markAsSubmitted(this.contextFormGroup);

    if (!this.contextFormGroup.valid) {
      return;
    }

    this.storing = true;
    this.setNullIfMissingAddress(this.contextFormGroup.value.branches);

    this.institutionDataService.storeInstitutionBranches(this.id,
      this.contextFormGroup.value.branches,
      this.model.delete)
      .subscribe(val => {
          this.storing = false;
          // обязательно обновляем форму из БД - чтобы загрузить идшники для новых бранчей
          this.refreshFormData();
          // обновим поисковый кэш филиалов (чтобы комбобоксы заполнились актуальными данными, там где филиалы используются)
          this.lookupSourceService.invalidateLookup('institution-branch' + this.id.toString());
          FormHelper.setSingleFormGroupServerSideValidationErrors({}, this.model, this.contextFormGroup);
        },
        error => {
          FormHelper.setSingleFormGroupServerSideValidationErrors(error, this.model, this.contextFormGroup);
          this.storing = false;
        });
  }

  cancelEdit() {
    this.dataCachingService.removeCachedData('EditInstitutionBranches', this.id.toString());
    this.refreshFormDataInternal();
  }

  refreshFormData() {
    this.dataCachingService.removeCachedData('EditInstitutionBranches', this.id.toString());
    this.refreshFormDataInternal();
  }

  refreshFormDataInternal() {
    this.waitingOverlay.StartWaiting();

    const existing = this.dataCachingService.getCachedData('EditInstitutionBranches', this.id.toString());

    if (existing) {
      this.model = existing;
      this.waitingOverlay.EndWaiting();
    } else {
      this.institutionDataService.getInstitutionBranchesForEdit(this.id).subscribe(data => {
        this.model = {id: this.id, delete: []};
        this.ensureFormGroup(data);
        this.dataCachingService.addToCache('EditInstitutionBranches', this.id.toString(), this.model);
        this.waitingOverlay.EndWaiting();
      }, () => {
        this.waitingOverlay.EndWaiting();
      });
    }
  }

  addBranch() {
    this.branches.push(this.fb.group(this.getBranchGroupDef()));
    this.contextFormGroup.markAsDirty();
  }

  deleteBranch(index: number) {
    const idToDelete = this.branches.controls[index].get('id').value;
    if (idToDelete) {
      this.model.delete.push(idToDelete);
    }

    this.branches.removeAt(index);
    this.branches.markAsDirty();
  }

  setNullIfMissingAddress(branches: any[]) {
    branches.forEach(branch => {
      if (!branch.isAddress || !branch.address.regionId) {
        branch.address = null;
        branch.addressId = null;
      }
    });
  }
}
