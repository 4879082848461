import { Component, OnInit } from '@angular/core';
import { EsiaService } from './esia.service';
import { Router } from '@angular/router';

@Component({
  selector: `app-auth-multiple`,
  template: `
      <clr-modal [(clrModalOpen)]="esiaService.authMultipleInstitutionForUser" [clrModalClosable]="false" [clrModalSize]="''">
          <h3 class="modal-title">Выбор учреждения</h3>
          <div class="modal-body">
              <p style="padding-bottom: 20px">Пользователь зарегистрирован в нескольких учреждениях, необходимо выбрать, в каком авторизоваться</p>
              <div *ngFor="let institution of institutions">
                  <div>
                      <button class="btn btn-primary" type="button" (click)="login(institution.id)">Войти</button>
                      <label for="{{institution.id}}">{{institution.caption}}</label>
                  </div>
              </div>
          </div>
          <div class="modal-footer">
              <button class="btn btn-outline" type="button" (click)="logout()">Выйти</button>
          </div>
      </clr-modal>
  `
})
export class AuthFoundMultipleComponent implements OnInit {
  reasonErrorAuth = '';
  institutions = [];
  logoutUrl = '';

  constructor(public esiaService: EsiaService, private router: Router) {}

  ngOnInit() {

    const authData = history.state.data.authData;
    this.logoutUrl = history.state.data.logoutUrl;

    this.reasonErrorAuth = authData['reason'];
    this.institutions = authData['institutionList'] as any[];
  }

  login(institutionId: number) {
    this.esiaService.authenticate(null, null, institutionId)
      .subscribe(authData => {

        if (!authData || authData.notFound) {
          return;
        }

        this.esiaService.authMultipleInstitutionForUser = false;
        this.router.navigateByUrl('').then();
      });
  }

  logout() {
    window.location.href = this.logoutUrl;
  }
}

