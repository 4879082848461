import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BookmarkService } from '../../logic/services/bookmark.service';
import { ActivatedRoute } from '@angular/router';
import { DataCachingService } from '../../logic/services/data-caching.service';
import { GroupOperationsService } from '../../logic/services/group-operations.service';
import { MetadataService } from '../../logic/services/metadata.service';
import { timer } from 'rxjs/index';
import { environment } from '../../../environments/environment';
import { FormHelper } from '../../ui/controls/form-helper';
import { GroupOperationMetadataService } from '../../logic/services/group-operation-metadata.service';
var GroupOperationRunnerComponent = /** @class */ (function () {
    function GroupOperationRunnerComponent(bmService, fb, dataCachingService, route, groupOperationsService, groupOperationMetadataService, metadataService) {
        this.bmService = bmService;
        this.fb = fb;
        this.dataCachingService = dataCachingService;
        this.route = route;
        this.groupOperationsService = groupOperationsService;
        this.groupOperationMetadataService = groupOperationMetadataService;
        this.metadataService = metadataService;
        this.model = GroupOperationRunnerComponent.createNewModel();
    }
    Object.defineProperty(GroupOperationRunnerComponent.prototype, "contextFormGroup", {
        get: function () {
            return this.model.form;
        },
        enumerable: true,
        configurable: true
    });
    GroupOperationRunnerComponent.createNewModel = function () {
        return {
            form: undefined,
            operationId: undefined,
            operationTypeId: undefined,
            state: undefined,
            result: undefined,
            started: false,
            completed: false,
            startedFromOtherUi: false,
            newlyEnteredFile: undefined,
        };
    };
    GroupOperationRunnerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.tickTimer = timer(1000, 1000);
        this.tickSub = this.tickTimer.subscribe(function (t) { return _this.checkCurrentOperationState(); });
        this.route.paramMap
            .subscribe(function (params) {
            _this.OperationParamsCode = params.get('opCode');
            _this.groupOperationMetadataService.getOpParamsGroupDef$(params, _this.OperationParamsCode).subscribe(function (defaultParams) {
                _this.groupOperationMetadataService.getReportMetadata$(_this.OperationParamsCode).subscribe(function (reportMetadata) {
                    _this.ReportMetadata = reportMetadata;
                    _this.OperationTypeId = reportMetadata.operationTypeId;
                    _this.ComponentModelCacheId = 'GroupOp_' + _this.OperationParamsCode;
                    var randomUniqueTag = params.get('uniqueTag');
                    var existing = _this.dataCachingService.getCachedData(_this.ComponentModelCacheId, randomUniqueTag);
                    if (!existing) {
                        if (randomUniqueTag.startsWith('new$')) {
                            _this.model.operationId = undefined;
                        }
                        else {
                            _this.model.operationId = +randomUniqueTag;
                            _this.model.started = true;
                            _this.model.startedFromOtherUi = true;
                        }
                        _this.model.operationTypeId = _this.OperationTypeId;
                        _this.model.form = _this.fb.group(defaultParams);
                        _this.dataCachingService.addToCache(_this.ComponentModelCacheId, randomUniqueTag, _this.model);
                    }
                    else {
                        _this.model = existing;
                    }
                    _this.addBookmark(params);
                });
            });
        });
    };
    GroupOperationRunnerComponent.prototype.ngOnDestroy = function () {
        if (this.tickSub) {
            this.tickSub.unsubscribe();
            this.tickSub = undefined;
        }
    };
    GroupOperationRunnerComponent.prototype.checkCurrentOperationState = function () {
        var _this = this;
        if (!this.model.operationId
            || (this.model.state && this.model.state.state === 3)
            || (this.model.state && this.model.state.state === 5)) {
            return;
        }
        this.groupOperationsService.getOperationStatus(this.model.operationTypeId, this.model.operationId)
            .subscribe(function (state) {
            _this.model.state = state;
            if (_this.model.state.state === 3 || _this.model.state.state === 5) {
                _this.completeOperation();
            }
        });
    };
    GroupOperationRunnerComponent.prototype.runOperation = function () {
        if (!this.contextFormGroup.valid || this.model.started) {
            return;
        }
        var model = this.model;
        model.started = true;
        this.groupOperationsService.runOperation(this.model.operationTypeId, this.contextFormGroup.value, this.model.newlyEnteredFile).subscribe(function (opKey) {
            model.operationId = opKey.id;
        }, function () {
            model.started = false;
        });
    };
    GroupOperationRunnerComponent.prototype.isInvalid = function (cname) {
        return FormHelper.isInvalid(this.contextFormGroup, cname);
    };
    GroupOperationRunnerComponent.prototype.completeOperation = function () {
        var _this = this;
        if (this.model.completed) {
            return;
        }
        this.model.completed = true;
        this.groupOperationsService.getOperationResult(this.model.operationTypeId, this.model.operationId)
            .subscribe(function (result) {
            _this.model.result = result;
            if (_this.model.result.result && !_this.model.startedFromOtherUi) {
                _this.navigateResults();
            }
        });
    };
    GroupOperationRunnerComponent.prototype.navigateResults = function () {
        this.metadataService.preloadFile(this.model.result.result).subscribe(function (fileHash) {
            window.open(environment.api + '/files/get?preloadId=' + encodeURIComponent(fileHash));
        });
    };
    GroupOperationRunnerComponent.prototype.getPercProgress = function () {
        return this.model.state ? Math.floor(this.model.state.progress / 100) : 0;
    };
    GroupOperationRunnerComponent.prototype.addBookmark = function (params) {
        var randomUniqueTag = params.get('uniqueTag');
        this.bmService.addGroupOperationBookmark(this.ReportMetadata.caption, randomUniqueTag, ['/operations/general', this.OperationParamsCode, randomUniqueTag, params.params]);
    };
    GroupOperationRunnerComponent.prototype.fileChanged = function (e) {
        this.model.newlyEnteredFile = e.target.files[0];
        this.contextFormGroup.get('uploadedFileUri').setValue(e.target.files[0] ? e.target.files[0].name : undefined);
        this.contextFormGroup.markAsDirty();
    };
    return GroupOperationRunnerComponent;
}());
export { GroupOperationRunnerComponent };
