/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../pages/edit-product/common/edit-product-packaging.component.ngfactory";
import * as i2 from "../../pages/edit-product/common/edit-product-packaging.component";
import * as i3 from "@angular/forms";
import * as i4 from "../../pages/edit-animal/edit-animal-list.component.ngfactory";
import * as i5 from "../../pages/edit-animal/edit-animal-list.component";
import * as i6 from "../../logic/services/app-navigation.service";
import * as i7 from "../../logic/services/address-person-fio-cache.service";
import * as i8 from "@angular/common";
import * as i9 from "./ag-grid-cell-component-handler.component";
var styles_AgGridCellComponentHandlerComponent = [];
var RenderType_AgGridCellComponentHandlerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AgGridCellComponentHandlerComponent, data: {} });
export { RenderType_AgGridCellComponentHandlerComponent as RenderType_AgGridCellComponentHandlerComponent };
function View_AgGridCellComponentHandlerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-edit-product-packaging", [], null, null, null, i1.View_EditProductPackagingComponent_0, i1.RenderType_EditProductPackagingComponent)), i0.ɵdid(1, 49152, null, 0, i2.EditProductPackagingComponent, [i3.FormBuilder], { packages: [0, "packages"], isDraft: [1, "isDraft"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.params.data.get("packages"); var currVal_1 = _co.params.data.get("isDraft"); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_AgGridCellComponentHandlerComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-edit-animal-list", [["class", "edit-animal-list-flex"]], null, null, null, i4.View_EditAnimalListComponent_0, i4.RenderType_EditAnimalListComponent)), i0.ɵdid(1, 114688, null, 0, i5.EditAnimalListComponent, [i6.AppNavigationService, i3.FormBuilder, i7.AddressPersonFioCacheService], { animals: [0, "animals"], showLabel: [1, "showLabel"], filterSearchParams: [2, "filterSearchParams"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.params.data.get(_co.colDef.field); var currVal_1 = false; var currVal_2 = _co.params.data.value; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_AgGridCellComponentHandlerComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AgGridCellComponentHandlerComponent_1)), i0.ɵdid(1, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AgGridCellComponentHandlerComponent_2)), i0.ɵdid(3, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.colDef.componentType === 1); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.colDef.componentType === 2); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_AgGridCellComponentHandlerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-ag-grid-cell-component-handler", [], null, null, null, View_AgGridCellComponentHandlerComponent_0, RenderType_AgGridCellComponentHandlerComponent)), i0.ɵdid(1, 49152, null, 0, i9.AgGridCellComponentHandlerComponent, [], null, null)], null, null); }
var AgGridCellComponentHandlerComponentNgFactory = i0.ɵccf("app-ag-grid-cell-component-handler", i9.AgGridCellComponentHandlerComponent, View_AgGridCellComponentHandlerComponent_Host_0, { params: "params", colDef: "colDef" }, {}, []);
export { AgGridCellComponentHandlerComponentNgFactory as AgGridCellComponentHandlerComponentNgFactory };
