<div class="compact form" [formGroup]="contextFormGroup">
  <section class="form-block itech-block-medium itech-expertise-area" formArrayName="indicators">

    <h1>Показатели</h1>

    <div>
      <clr-stack-view class="stack-view-expertise-indicators">
        <ng-container *ngFor="let item of indicators.controls; index as i">
          <clr-stack-block *ngIf="!contextFormGroup.get('conclusionMercuryNumber').value ||
                                  (contextFormGroup.get('conclusionMercuryNumber').value && +item.get('expertiseResultType').value > 0)">
            <clr-stack-label style="vertical-align:middle;">
              <label>
                <label *ngIf="item.get('mercuryNumber').value" title="Синхронизировано с Меркурием">
                  <clr-icon shape="success-standard" style="color: green;margin-right: 5px"></clr-icon>
                </label>
                <label *ngIf="item.get('syncErrorText').value" [title]="item.get('syncErrorText').value">
                  <clr-icon shape="error-standard" style="color: red;margin-right: 5px"></clr-icon>
                </label>
                <label *ngIf="item.get('needSync').value" title="Необходимо синхронизировать">
                  <clr-icon shape="refresh" style="color: navy;margin-right: 5px"></clr-icon>
                </label>
                <label [style.color]="getRowColor(item)">
                  {{item.get('indicatorTypeCaption').value}}
                </label>
              </label>
            </clr-stack-label>
            <clr-stack-content style="vertical-align:middle;">
              <label [style.color]="getRowColor(item)">
                {{item.get('expertiseResultType').value|lookup:'expertise-result-type'|async}}
              </label>
            </clr-stack-content>
            <clr-stack-block class="stack-view-expertise-indicators-inner-block" [formGroup]="item">
              <clr-stack-label>
                <div class="form-group">
                  <label>Нормативный акт</label>
                  <div style="padding-top: 0.3rem;">
                    {{item.get('indicatorTypeLegalActCaption').value}}
                  </div>
                </div>
                <div class="form-group">
                  <label>Метод</label>
                  <div style="padding-top: 0.3rem;">
                    {{item.get('indicatorTypeMethodCaption').value}}
                  </div>
                </div>
                <div class="form-group">
                  <label>Нормативное значение</label>
                  <div style="padding-top: 0.3rem;">
                    {{item.get('indicatorTypeNormativeValueCaption').value}}
                  </div>
                </div>
                <div class="form-group itech-height-x4">
                  <label for="value{{i}}">Фактическое значение</label>
                  <label for="value{{i}}" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md sizable"
                         [class.invalid]="isInvalid(item, 'value')">
                    <app-input-text [contextId]="'value' + i" [classNames]="'itech-control-xlarge sizable scrollable'"
                                    [formControlObj]="item.get('value')" [htmlType]="2"
                                    [placeholder]="'Введите фактическое значение'"
                                    [disabled]="contextFormGroup.get('conclusionMercuryNumber').value"></app-input-text>
                    <app-validation-tooltip [input]="item.controls['value']"></app-validation-tooltip>
                  </label>
                </div>
                <div class="form-group">
                  <label>Результат</label>
                  <app-combo-lookup class="itech-control-xlarge" [contextControlId]="'expertiseResultType'+i" [sorted]="true"
                                    [contextControlName]="'expertiseResultType'" [lookupName]="'expertise-result-type'"
                                    [contextFormGroup]="item" [disabled]="contextFormGroup.get('conclusionMercuryNumber').value">
                  </app-combo-lookup>
                </div>
                <div class="form-group itech-height-x4">
                  <label for="conclusion{{i}}">Заключение</label>
                  <label for="conclusion{{i}}" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md sizable"
                         [class.invalid]="isInvalid(item, 'conclusion')">
                    <app-input-text [contextId]="'conclusion' + i" [classNames]="'itech-control-xlarge sizable scrollable'"
                                    [formControlObj]="item.get('conclusion')" [htmlType]="2"
                                    [placeholder]="'Введите текст заключения'"
                                    [disabled]="contextFormGroup.get('conclusionMercuryNumber').value"></app-input-text>
                    <app-validation-tooltip [input]="item.controls['conclusion']"></app-validation-tooltip>
                  </label>
                </div>
              </clr-stack-label>
            </clr-stack-block>
          </clr-stack-block>
        </ng-container>
      </clr-stack-view>
    </div>

  </section>
</div>
