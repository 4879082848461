/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/@clr/angular/clr-angular.ngfactory";
import * as i2 from "@clr/angular";
import * as i3 from "@angular/common";
import * as i4 from "../../ui/controls/app-link-button.component.ngfactory";
import * as i5 from "../../ui/controls/app-link-button.component";
import * as i6 from "../../logic/services/app-navigation.service";
import * as i7 from "../../logic/services/lookup-source.service";
import * as i8 from "../../logic/services/address-person-fio-cache.service";
import * as i9 from "./user-favourite-stables.component";
import * as i10 from "../../logic/services/security.service";
import * as i11 from "../../logic/services/institution-data.service";
var styles_UserFavouriteStablesComponent = [];
var RenderType_UserFavouriteStablesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_UserFavouriteStablesComponent, data: {} });
export { RenderType_UserFavouriteStablesComponent as RenderType_UserFavouriteStablesComponent };
function View_UserFavouriteStablesComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "clr-progress-bar", [["clrLoop", ""], ["clrValue", "0"]], [[1, "id", 0], [2, "progress", null], [2, "labeled", null], [2, "progress-fade", null], [2, "loop", null], [2, "success", null], [2, "danger", null], [2, "flash", null], [2, "flash-danger", null]], null, null, i1.View_ClrProgressBar_0, i1.RenderType_ClrProgressBar)), i0.ɵprd(131584, null, i2.ɵbb, i2.ɵbb, [i0.NgZone, i3.DOCUMENT, i0.PLATFORM_ID]), i0.ɵdid(2, 49152, null, 0, i2.ClrProgressBar, [i2.ɵbb], { value: [0, "value"], clrLoop: [1, "clrLoop"] }, null)], function (_ck, _v) { var currVal_9 = "0"; var currVal_10 = ""; _ck(_v, 2, 0, currVal_9, currVal_10); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).externalId; var currVal_1 = i0.ɵnov(_v, 2).progressClass; var currVal_2 = i0.ɵnov(_v, 2).labeledClass; var currVal_3 = i0.ɵnov(_v, 2).fadeClass; var currVal_4 = i0.ɵnov(_v, 2).loopClass; var currVal_5 = i0.ɵnov(_v, 2).successClass; var currVal_6 = i0.ɵnov(_v, 2).dangerClass; var currVal_7 = i0.ɵnov(_v, 2).flashClass; var currVal_8 = i0.ɵnov(_v, 2).flashDangerClass; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
function View_UserFavouriteStablesComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "div", [["class", "form-group itech-group-whole-line itech-flex-link-with-remove"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-link-button", [], null, null, null, i4.View_AppLinkButtonComponent_0, i4.RenderType_AppLinkButtonComponent)), i0.ɵdid(3, 573440, null, 0, i5.AppLinkButtonComponent, [i6.AppNavigationService, i7.LookupSourceService, i8.AddressPersonFioCacheService], { id: [0, "id"], lookupName: [1, "lookupName"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 5, "button", [["class", "btn btn-danger btn-link itech-inline-tool-btn"], ["style", "margin-top:3px"], ["title", "\u0423\u0434\u0430\u043B\u0438\u0442\u044C \u0438\u0437 \u0438\u0437\u0431\u0440\u0430\u043D\u043D\u043E\u0433\u043E"], ["type", "button"]], [[1, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeStableFromFavourite(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ClrLoadingButton_0, i1.RenderType_ClrLoadingButton)), i0.ɵdid(5, 49152, null, 0, i2.ClrLoadingButton, [i0.ElementRef, i0.Renderer2], null, null), i0.ɵprd(2048, null, i2.LoadingListener, null, [i2.ClrLoadingButton]), i0.ɵdid(7, 147456, null, 0, i2.ClrLoading, [[2, i2.LoadingListener]], { loadingState: [0, "loadingState"] }, null), (_l()(), i0.ɵeld(8, 0, null, 0, 1, "clr-icon", [["shape", "trash"]], null, null, null, null, null)), i0.ɵdid(9, 16384, null, 0, i2.ClrIconCustomTag, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = "stable"; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_3 = _co.loading; _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var currVal_2 = (i0.ɵnov(_v, 5).disabled ? "" : null); _ck(_v, 4, 0, currVal_2); }); }
function View_UserFavouriteStablesComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UserFavouriteStablesComponent_4)), i0.ɵdid(2, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.favouriteStableIds; _ck(_v, 2, 0, currVal_0); }, null); }
function View_UserFavouriteStablesComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, [" \u041D\u0435\u0442 \u0438\u0437\u0431\u0440\u0430\u043D\u043D\u044B\u0445 \u043F\u043E\u0434\u043D\u0430\u0434\u0437\u043E\u0440\u043D\u044B\u0445 \u043E\u0431\u044A\u0435\u043A\u0442\u043E\u0432 "]))], null, null); }
function View_UserFavouriteStablesComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UserFavouriteStablesComponent_3)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["noFavouriteStables", 2]], null, 0, null, View_UserFavouriteStablesComponent_5))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.favouriteStableIds && _co.favouriteStableIds.length); var currVal_1 = i0.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_UserFavouriteStablesComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "itech-common-wrap"], ["id", "interceptNoAccess"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [["id", "agent-common-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 6, "div", [["class", "itech-doc-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 5, "div", [["class", "compact form itech-highlighted-sections"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 4, "section", [["class", "form-block itech-block-medium"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UserFavouriteStablesComponent_1)), i0.ɵdid(6, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UserFavouriteStablesComponent_2)), i0.ɵdid(8, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 6, 0, currVal_0); var currVal_1 = !_co.loading; _ck(_v, 8, 0, currVal_1); }, null); }
export function View_UserFavouriteStablesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-user-favourite-stables", [], null, null, null, View_UserFavouriteStablesComponent_0, RenderType_UserFavouriteStablesComponent)), i0.ɵdid(1, 114688, null, 0, i9.UserFavouriteStablesComponent, [i10.SecurityService, i11.InstitutionDataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserFavouriteStablesComponentNgFactory = i0.ɵccf("app-user-favourite-stables", i9.UserFavouriteStablesComponent, View_UserFavouriteStablesComponent_Host_0, {}, {}, []);
export { UserFavouriteStablesComponentNgFactory as UserFavouriteStablesComponentNgFactory };
