import { Component, HostBinding, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-query-details-event-fields',
  template: `
    <app-query-lookup-combo-filter parameterName="created_institution" [lookupName]="'all-institution'"
                                   [contextFormGroup]="contextFormGroup" [queryUserMode]="queryUserMode">
      Учреждение-создатель
    </app-query-lookup-combo-filter>
    <app-query-lookup-combo-filter *ngIf="contextFormGroup.get('_par_created_institution').value"
                                   parameterName="created_institution_employee"
                                   [lookupName]="'institution-employee' + contextFormGroup.get('_par_created_institution').value"
                                   [contextFormGroup]="contextFormGroup" [queryUserMode]="queryUserMode">
      Пользователь-создатель
    </app-query-lookup-combo-filter>
    <app-query-date-time-filter parameterName="created_time" [contextFormGroup]="contextFormGroup"
                                [queryUserMode]="queryUserMode">
      Время создания
    </app-query-date-time-filter>
    <app-query-date-time-filter parameterName="date_planning" [contextFormGroup]="contextFormGroup"
                                [queryUserMode]="queryUserMode">
      Дата (план)
    </app-query-date-time-filter>
    <app-query-date-time-filter parameterName="date" [contextFormGroup]="contextFormGroup"
                                [queryUserMode]="queryUserMode">
      Дата (факт)
    </app-query-date-time-filter>

    <app-query-lookup-list-filter parameterName="event_types" [lookupName]="'event-type'"
                                  [contextFormGroup]="contextFormGroup" [queryUserMode]="queryUserMode">
      Вид события
    </app-query-lookup-list-filter>
    <app-query-lookup-list-filter parameterName="diagnostic_types" [lookupName]="'diagnostic-type'"
                                  [contextFormGroup]="contextFormGroup" [queryUserMode]="queryUserMode">
      Способ исследования
    </app-query-lookup-list-filter>
    <app-query-lookup-combo-filter parameterName="disease_type" [lookupName]="'disease-type'"
                                  [contextFormGroup]="contextFormGroup" [queryUserMode]="queryUserMode">
      Болезнь
    </app-query-lookup-combo-filter>
    <app-query-lookup-combo-filter parameterName="exam_result" [lookupName]="'exam-result'"
                                   [contextFormGroup]="contextFormGroup" [queryUserMode]="queryUserMode">
      Результат
    </app-query-lookup-combo-filter>
  `
})
export class QueryDetailsEventFieldsComponent {
  @Input() contextFormGroup: FormGroup;
  @Input() queryUserMode;
  @HostBinding('attr.class') class = 'form-block itech-block-normal';

  public get streetLookupName() {
    return 'addr-street-' + this.contextFormGroup.get('_par_city_region_id').value +
      '-' + this.contextFormGroup.get('_par_city_id').value;
  }

  public docTypeCtrlVisible(dt1?: number, dt2?: number, dt3?: number) {
    return this.queryUserMode || ((dt1 && this.contextFormGroup.get('_par_doc_type').value == dt1)
      || (dt2 && this.contextFormGroup.get('_par_doc_type').value == dt2)
      || (dt3 && this.contextFormGroup.get('_par_doc_type').value == dt3));
  }
}
