<div class="compact form" [formGroup]="contextFormGroup">

  <app-doc-validation-errors [validationErrors]="contextFormGroup.docLevelServerSideValidationErrors">
  </app-doc-validation-errors>

  <section class="form-block itech-block-medium itech-block-inline">

    <div class="form-group itech-double-height">
      <label for="caption" class="required">Полное наименование</label>
      <label for="caption" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'caption')">
            <textarea type="text" id="caption" placeholder="Введите полное наименование учреждения"
                      class="itech-control-xlarge" formControlName="caption">
            </textarea>
        <app-validation-tooltip [input]="contextFormGroup.controls['caption']"></app-validation-tooltip>
      </label>
    </div>

    <div class="form-group">
      <label for="shortCaption" class="required">Краткое наименование</label>
      <label for="shortCaption" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'shortCaption')">
        <input type="text" id="shortCaption" placeholder="Краткое наименование" class="itech-control-xlarge"
               formControlName="shortCaption">
        <app-validation-tooltip [input]="contextFormGroup.controls['shortCaption']"></app-validation-tooltip>
      </label>
    </div>

    <div class="form-group">
      <label for="legalFormId" class="required">Организационно-правовая форма</label>
      <app-combo-lookup [validationTooltipRight]="true" class="itech-control-xlarge"
                        [contextControlId]="'legalFormId'"
                        [contextControlName]="'legalFormId'"
                        [lookupName]="'legal-form'" [contextFormGroup]="contextFormGroup"></app-combo-lookup>
    </div>

    <div class="form-group">
      <label for="institutionTypeId" class="required">Вид учреждения</label>
      <app-combo-lookup [validationTooltipRight]="true" class="itech-control-xlarge"
                        [contextControlId]="'institutionTypeId'"
                        [contextControlName]="'institutionTypeId'"
                        [lookupName]="'institution-type'"
                        [contextFormGroup]="contextFormGroup"></app-combo-lookup>
    </div>

    <div class="form-group">
      <label>Курирующее учреждение</label>
      <app-combo-lookup [validationTooltipRight]="true" class="itech-control-xlarge"
                        [contextControlName]="'headInstitutionId'"
                        [lookupName]="'head-institution'" [contextFormGroup]="contextFormGroup"></app-combo-lookup>
    </div>


    <div class="form-group">
      <label for="ogrn">ОГРН</label>
      <label for="ogrn" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'ogrn')">
        <input type="text" id="ogrn" placeholder="ОГРН" class="itech-control-xlarge"
               formControlName="ogrn">
        <app-validation-tooltip [input]="contextFormGroup.controls['ogrn']"></app-validation-tooltip>
      </label>
    </div>

    <div class="form-group">
      <label for="dateRegistered" class="required">Дата гос. регистрации</label>
      <label for="dateRegistered" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'dateRegistered')">
        <input type="date" id="dateRegistered" class="itech-control-normal"
               formControlName="dateRegistered" required>
        <app-validation-tooltip [input]="contextFormGroup.controls['dateRegistered']"></app-validation-tooltip>
      </label>
    </div>

    <app-address [contextFormGroup]="contextFormGroup.get('address')"></app-address>

    <div class="form-group">
      <label for="phone">Телефон / EMail</label>
      <label for="phone" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'phone')">
        <input type="text" id="phone" placeholder="Телефон" class="itech-control-medium"
               formControlName="phone">
        <app-validation-tooltip [input]="contextFormGroup.controls['phone']"></app-validation-tooltip>
      </label>
      <label for="email" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'email')">
        <input type="text" id="email" placeholder="EMail" class="itech-control-medium"
               formControlName="email">
        <app-validation-tooltip [input]="contextFormGroup.controls['email']"></app-validation-tooltip>
      </label>
    </div>

    <div class="form-group itech-height-x4 v-resize">
      <label for="otherTextInfo">Дополнительная информация</label>
      <label for="otherTextInfo" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'otherTextInfo')">
            <textarea type="text" id="otherTextInfo" placeholder="Укажите дополнительную информацию"
                      class="itech-control-xlarge itech-text-wall" formControlName="otherTextInfo">
            </textarea>
        <app-validation-tooltip [input]="contextFormGroup.controls['otherTextInfo']"></app-validation-tooltip>
      </label>
    </div>

    <div class="form-group">
      <label for="inn">ИНН / КПП</label>
      <label for="inn" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'inn')">
        <input type="text" id="inn" class="itech-control-normal"
               formControlName="inn">
        <app-validation-tooltip [input]="contextFormGroup.controls['inn']"></app-validation-tooltip>
      </label>
      <label for="kpp" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'kpp')">
        <input type="text" id="kpp" class="itech-control-normal"
               formControlName="kpp">
        <app-validation-tooltip [input]="contextFormGroup.controls['kpp']"></app-validation-tooltip>
      </label>
    </div>

    <div class="form-group">
      <label for="bankAccount">Расчетный счет</label>
      <label for="bankAccount" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'bankAccount')">
        <input type="text" id="bankAccount" placeholder="00000000000000000000" class="itech-control-xlarge"
               formControlName="bankAccount">
        <app-validation-tooltip [input]="contextFormGroup.controls['bankAccount']"></app-validation-tooltip>
      </label>
    </div>

    <div class="form-group">
      <label for="bankPAccount">Лицевой счет</label>
      <label for="bankPAccount" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'bankPAccount')">
        <input type="text" id="bankPAccount" placeholder="Лицевой счет" class="itech-control-xlarge"
               formControlName="bankPAccount">
        <app-validation-tooltip [input]="contextFormGroup.controls['bankPAccount']"></app-validation-tooltip>
      </label>
    </div>

    <div class="form-group">
      <label for="bankBik">БИК Банка</label>
      <label for="bankBik" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'bankBik')">
        <input type="text" id="bankBik" placeholder="00000000" class="itech-control-xlarge"
               formControlName="bankBik">
        <app-validation-tooltip [input]="contextFormGroup.controls['bankBik']"></app-validation-tooltip>
      </label>
    </div>


    <div class="form-group itech-group-whole-line">
      <label></label>
      <div>Обслуживаемые муниципальные образования</div>
    </div>
    <div class="form-group itech-group-whole-line">
      <label></label>
      <app-checkbox-select class="itech-control-x-large"
                           [contextControlName]="'workingRegionId'" [lookupName]="'addr-region'"
                           [contextFormGroup]="contextFormGroup"></app-checkbox-select>
    </div>
    <div class="form-group itech-group-whole-line">
      <label></label>
      <div class="itech-control-desc">Укажите обслуживаемые муниципальные образования</div>
    </div>


  </section>
</div>
