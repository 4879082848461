import { OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
var AppComboMultilookupComponent = /** @class */ (function () {
    function AppComboMultilookupComponent(fb) {
        var _this = this;
        this.fb = fb;
        this.sorted = false;
        this.itemTitle = 'Выбор';
        this.flexMode = false;
        this.classControl = undefined;
        this.emptyElementForm = fb.group({
            value: ''
        });
        this.emptyElementForm.valueChanges.subscribe(function () {
            if (_this.emptyElementForm.get('value').value || _this.emptyElementForm.get('value').value === 0) {
                setTimeout(function () {
                    _this.boundArray.push(_this.fb.control(_this.emptyElementForm.get('value').value));
                    _this.boundArray.markAsDirty();
                    _this.emptyElementForm.get('value').setValue(undefined);
                }, 100);
            }
        });
    }
    Object.defineProperty(AppComboMultilookupComponent.prototype, "boundArray", {
        get: function () {
            return this.contextFormGroup.get(this.contextControlName);
        },
        enumerable: true,
        configurable: true
    });
    AppComboMultilookupComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.hasOwnProperty('contextFormGroup') || changes.hasOwnProperty('contextControlName')) {
            if (this.contextFormGroup && this.contextControlName) {
                this.boundArray.valueChanges.subscribe(function () {
                    _this.prettifyBoundArray();
                });
                this.prettifyBoundArray();
            }
        }
    };
    AppComboMultilookupComponent.prototype.prettifyBoundArray = function () {
        var _this = this;
        setTimeout(function () {
            var i = 0;
            while (i < _this.boundArray.length) {
                if (!_this.boundArray.controls[i].value && _this.boundArray.controls[i].value !== 0) {
                    _this.boundArray.removeAt(i);
                    _this.boundArray.markAsDirty();
                }
                else {
                    i++;
                }
            }
        }, 100);
    };
    return AppComboMultilookupComponent;
}());
export { AppComboMultilookupComponent };
