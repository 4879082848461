/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@clr/angular";
import * as i2 from "../../ui/controls/app-grid.component.ngfactory";
import * as i3 from "../../ui/controls/app-grid.component";
import * as i4 from "../../logic/services/data-caching.service";
import * as i5 from "../../logic/services/security.service";
import * as i6 from "./group-operations-browse.component";
import * as i7 from "@angular/router";
import * as i8 from "../../logic/services/group-operations.service";
import * as i9 from "../../ui/infrastructure/alert.service";
import * as i10 from "../../ui/infrastructure/global-waiting-overlay.service";
import * as i11 from "../../logic/services/app-navigation.service";
import * as i12 from "../../logic/services/lookup-source.service";
import * as i13 from "../../logic/services/address-person-fio-cache.service";
var styles_GroupOperationsBrowseComponent = [];
var RenderType_GroupOperationsBrowseComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GroupOperationsBrowseComponent, data: {} });
export { RenderType_GroupOperationsBrowseComponent as RenderType_GroupOperationsBrowseComponent };
export function View_GroupOperationsBrowseComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 18, "div", [["class", "itech-common-wrap no-pager"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "itech-content-header itech-no-margin-bottom"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "clr-icon", [["shape", "bolt"], ["size", "36"]], null, null, null, null, null)), i0.ɵdid(4, 16384, null, 0, i1.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, ["\u00A0\u0416\u0443\u0440\u043D\u0430\u043B \u0440\u0435\u0433\u043B\u0430\u043C\u0435\u043D\u0442\u043D\u044B\u0445 \u043E\u043F\u0435\u0440\u0430\u0446\u0438\u0439 "])), (_l()(), i0.ɵeld(6, 0, null, null, 2, "div", [["style", "padding: 5px; height: 90%"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "app-grid", [], null, [[null, "gridReady"], [null, "rowClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("gridReady" === en)) {
        var pd_0 = (_co.agGridReady($event) !== false);
        ad = (pd_0 && ad);
    } if (("rowClicked" === en)) {
        var pd_1 = (_co.appNavigationService.navigateGroupOperation($event.data) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_AppGridComponent_0, i2.RenderType_AppGridComponent)), i0.ɵdid(8, 114688, null, 0, i3.AppGridComponent, [i4.DataCachingService, i5.SecurityService], { uniqueGridName: [0, "uniqueGridName"], columnDefs: [1, "columnDefs"], rowData: [2, "rowData"], getRowNodeId: [3, "getRowNodeId"] }, { gridReady: "gridReady", rowClicked: "rowClicked" }), (_l()(), i0.ɵeld(9, 0, null, null, 3, "div", [["class", "itech-control-desc itech-desc-error"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "clr-icon", [["shape", "exclamation-circle"]], null, null, null, null, null)), i0.ɵdid(11, 16384, null, 0, i1.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, [" \u041F\u043E\u043A\u0430\u0437\u0430\u043D\u044B \u043E\u043F\u0435\u0440\u0430\u0446\u0438\u0438 \u0437\u0430 \u043F\u043E\u0441\u043B\u0435\u0434\u043D\u0438\u0435 2 \u043C\u0435\u0441\u044F\u0446\u0430 "])), (_l()(), i0.ɵeld(13, 0, null, null, 5, "div", [["class", "itech-form-actions"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 3, "button", [["class", "btn btn-link btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.refreshResults() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 1, "clr-icon", [["class", "has-badge"], ["shape", "refresh"]], null, null, null, null, null)), i0.ɵdid(17, 16384, null, 0, i1.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, ["\u00A0\u041E\u0431\u043D\u043E\u0432\u0438\u0442\u044C \u0441\u043F\u0438\u0441\u043E\u043A "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "GroupOperationsBrowse"; var currVal_1 = _co.gridColumnDefs; var currVal_2 = _co._searchResults; var currVal_3 = _co.rowIdFunc; _ck(_v, 8, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_GroupOperationsBrowseComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-group-operations-browse", [], null, null, null, View_GroupOperationsBrowseComponent_0, RenderType_GroupOperationsBrowseComponent)), i0.ɵdid(1, 49152, null, 0, i6.GroupOperationsBrowseComponent, [i7.ActivatedRoute, i8.GroupOperationsService, i9.AlertService, i10.GlobalWaitingOverlayService, i11.AppNavigationService, i12.LookupSourceService, i13.AddressPersonFioCacheService], null, null)], null, null); }
var GroupOperationsBrowseComponentNgFactory = i0.ɵccf("app-group-operations-browse", i6.GroupOperationsBrowseComponent, View_GroupOperationsBrowseComponent_Host_0, {}, {}, []);
export { GroupOperationsBrowseComponentNgFactory as GroupOperationsBrowseComponentNgFactory };
