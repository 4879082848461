/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./stable-search-results-form.component.ngfactory";
import * as i2 from "./stable-search-results-form.component";
import * as i3 from "../../../logic/services/stable-data.service";
import * as i4 from "../../../ui/infrastructure/global-waiting-overlay.service";
import * as i5 from "../../../logic/services/app-navigation.service";
import * as i6 from "../../../logic/services/lookup-source.service";
import * as i7 from "../../../ui/infrastructure/job-runner-ui.service";
import * as i8 from "../../../ui/infrastructure/alert.service";
import * as i9 from "../../../logic/services/security.service";
import * as i10 from "@clr/angular";
import * as i11 from "./stable-search-results.component";
import * as i12 from "@angular/router";
import * as i13 from "../../../logic/services/data-caching.service";
import * as i14 from "@angular/forms";
var styles_StableSearchResultsComponent = [];
var RenderType_StableSearchResultsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_StableSearchResultsComponent, data: {} });
export { RenderType_StableSearchResultsComponent as RenderType_StableSearchResultsComponent };
export function View_StableSearchResultsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "itech-common-wrap no-pager"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-stable-search-results-form", [], null, [[null, "rowClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("rowClicked" === en)) {
        var pd_0 = (_co.appNavigationService.performStableEditing($event.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_StableSearchResultsFormComponent_0, i1.RenderType_StableSearchResultsFormComponent)), i0.ɵdid(3, 114688, null, 0, i2.StableSearchResultsFormComponent, [i3.StableDataService, i4.GlobalWaitingOverlayService, i5.AppNavigationService, i6.LookupSourceService, i7.JobRunnerUiService, i8.AlertService, i9.SecurityService], { model: [0, "model"] }, { rowClicked: "rowClicked" }), (_l()(), i0.ɵeld(4, 0, null, null, 4, "div", [["class", "itech-form-actions"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 3, "button", [["class", "btn btn-link btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.appNavigationService.performStableCreation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "clr-icon", [["class", "has-badge"], ["shape", "building"]], null, null, null, null, null)), i0.ɵdid(7, 16384, null, 0, i10.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, ["\u00A0\u0417\u0430\u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u043E\u0432\u0430\u0442\u044C \u043D\u043E\u0432\u044B\u0439 \u043E\u0431\u044A\u0435\u043A\u0442 "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_StableSearchResultsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-stable-search-results", [], null, null, null, View_StableSearchResultsComponent_0, RenderType_StableSearchResultsComponent)), i0.ɵdid(1, 114688, null, 0, i11.StableSearchResultsComponent, [i12.ActivatedRoute, i13.DataCachingService, i5.AppNavigationService, i14.FormBuilder], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StableSearchResultsComponentNgFactory = i0.ɵccf("app-stable-search-results", i11.StableSearchResultsComponent, View_StableSearchResultsComponent_Host_0, {}, {}, []);
export { StableSearchResultsComponentNgFactory as StableSearchResultsComponentNgFactory };
