import { Component, Input, OnInit } from '@angular/core';
import { DataCachingService } from '../../logic/services/data-caching.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BookmarkService } from '../../logic/services/bookmark.service';
import { AppNavigationService } from '../../logic/services/app-navigation.service';
import { FormBuilder } from '@angular/forms';
import { FormHelper } from '../../ui/controls/form-helper';
import { SecurityService } from '../../logic/services/security.service';
import { EditDrugCommonFormComponent } from './common/edit-drug-common-form.component';
import { DrugDataService } from '../../logic/services/drug-data.service';
import { LookupSourceService } from '../../logic/services/lookup-source.service';

@Component({
  selector: 'app-create-drug',
  templateUrl: './create-drug.component.html'
})
export class CreateDrugComponent implements OnInit {

  @Input() model: any;
  creating = false;
  created = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private bookmarkService: BookmarkService,
              private drugDataService: DrugDataService,
              private dataCachingService: DataCachingService,
              private appNavigationService: AppNavigationService,
              private securityService: SecurityService,
              private lookupSourceService: LookupSourceService,
              private fb: FormBuilder) {
  }

  ngOnInit() {

    this.securityService.getUserInfo().subscribe(currentUserInfo => {


      this.router.events.subscribe((val) => {
        if (!this.created) {
          this.refreshCurrentBookmark(true);
        }
      });

      this.route.paramMap
        .subscribe(params => {

          const randomUniqueTag = params.get('randomUniqueTag');

          const existing = this.dataCachingService.getCachedData('CreateDrug', randomUniqueTag);

          if (!existing) {
            this.model = {
              randomUniqueTag: randomUniqueTag,
              serverSideValidationErrors: []
            };
            this.model.form = EditDrugCommonFormComponent.createFormGroup(this.fb, this.model, {});

            this.dataCachingService.addToCache('CreateDrug', randomUniqueTag, this.model, 10);
          } else {
            this.model = existing;
          }

          this.refreshCurrentBookmark();
        });
    });
  }

  refreshCurrentBookmark(skipIfNotExists?: boolean) {
    this.lookupSourceService.getLookupObj('drug-type').subscribe(lookup => {

      const drugTypeId = this.model.form.get('drugTypeId').value;
      const caption = ' ' + (drugTypeId ? lookup[drugTypeId] : '')
        + (this.model.form.get('serial').value ? ' (' + (this.model.form.get('serial').value) + ')' : '');

      this.bookmarkService.addOpenCardBookmark(this.model.randomUniqueTag,
        `Новая карточка${caption}`,
        ['/drug-create', this.model.randomUniqueTag], 'flask', skipIfNotExists);
    });
  }

  createCard() {

    FormHelper.markAsSubmitted(this.model.form);

    if (!this.model.form.valid) {
      return;
    }

    const dataToStore = this.model.form.value;

    this.creating = true;

    this.drugDataService.storeDrugCommonInfo(dataToStore).subscribe((createdDrugId: any) => {
      this.creating = false;
      this.created = true;

      this.bookmarkService.removeOpenCardBookmark(['/drug-create'], this.model.randomUniqueTag);
      this.appNavigationService.performDrugEditing(createdDrugId);
    }, error => {
      this.creating = false;
      FormHelper.setSingleFormGroupServerSideValidationErrors(error, this.model, this.model.form);
    });
  }
}
