import { IModalBody, ModalResult } from '../../../logic/services/app-navigation.service.models';
import { Component, Input } from '@angular/core';
import { AppFormGroup } from '../../../ui/controls/app-form-group';
import { FormBuilder, Validators } from '@angular/forms';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { Observable, of, Subject } from 'rxjs/index';
import { FormHelper } from '../../../ui/controls/form-helper';
import { EsiaService } from '../../../esia/esia.service';

@Component({
  templateUrl: './impersonate-params.component.html'
})
export class ImpersonateParamsComponent implements IModalBody<any, void> {

  @Input() contextFormGroup: AppFormGroup;
  isInvalid = FormHelper.isInvalid;

  public serverSideValidationErrors = [];

  public static buildFormArrayDef(fb: FormBuilder): any {
    return {
      institutionId: ['', Validators.required],
      institutionEmployeeId: ['', Validators.required],
    };
  }

  constructor(private lookupSourceService: LookupSourceService,
              private esiaService: EsiaService,
              private fb: FormBuilder) {

    this.contextFormGroup = fb.group(ImpersonateParamsComponent.buildFormArrayDef(fb)) as AppFormGroup;
  }

  impersonateUser(): Observable<ModalResult<void>> {
    FormHelper.markAsSubmitted(this.contextFormGroup);

    if (!this.contextFormGroup.valid) {
      return of(new ModalResult(false, undefined));
    }

    const retVal = new Subject<ModalResult<void>>();

    this.lookupSourceService.getLookupObj('institution-employee' + this.contextFormGroup.get('institutionId').value)
      .subscribe({
        next:
          employees => {
            const employee = employees['Obj' + this.contextFormGroup.get('institutionEmployeeId').value];
            this.esiaService.impersonate(employee.uniqueUserId).subscribe(
              {
                next: () => {
                  retVal.next(new ModalResult(true, null));
                }
              });
          }
      });

    return retVal;
  }

  onModalAccept$(): Observable<ModalResult<void>> {
    return this.impersonateUser();
  }

  initModalBody(data: any) {
    this.contextFormGroup.patchValue(data);
  }
}
