<div class="query-manager-details" *ngIf="model.condition">
  <h1>
    Редактирование параметров фильтрации поднадзорных объектов
  </h1>

  <div class="itech-form-actions">
    <div>
      <button [attr.disabled]="!model.condition.isNew? undefined: true" class="btn btn-warning-outline"
              (click)="deleteCondition()">
        <clr-icon shape="trash"></clr-icon> Удалить
      </button>
    </div>

    <div>
      <clr-dropdown>
        <button [attr.disabled]="!model.condition.isNew? undefined: true" type="button" class="btn btn-link" clrDropdownTrigger>
          Добавить подчиненную связку
          <clr-icon shape="caret down"></clr-icon>
        </button>
        <clr-dropdown-menu *clrIfOpen>
          <label class="dropdown-header">Выберите вид связки</label>
          <button *ngFor="let rt of relationTypes" type="button" clrDropdownItem (click)="addRelation(rt)"
                  title="{{rt.caption}}">{{rt.caption}}
          </button>
        </clr-dropdown-menu>
      </clr-dropdown>
    </div>

    <div>
      <button [clrLoading]="storing" class="btn btn-primary itech-form-save"
              [disabled]="!contextFormGroup.dirty || storing"
              (click)="store()">
        <clr-icon shape="check"></clr-icon>&nbsp;Сохранить
      </button>
      <button class="btn btn-warning itech-form-cancel" [disabled]="!contextFormGroup.dirty"
              (click)="invalidateContextFormGroup()">
        <clr-icon shape="undo"></clr-icon>&nbsp;Отменить
      </button>
    </div>
  </div>

  <div class="form compact" [formGroup]="contextFormGroup">

    <app-query-common-info [model]="model" [contextFormGroup]="contextFormGroup"></app-query-common-info>
    <app-query-details-stable-fields [contextFormGroup]="contextFormGroup"></app-query-details-stable-fields>
  </div>
</div>
