
  <clr-modal [(clrModalOpen)]="planningUi.deleteTicketModalOpened" *ngIf="planningUi.deleteTicketModalOpened"
             [clrModalClosable]="false" [clrModalSize]="'lg'">
    <h3 class="modal-title">
      {{ticketFormGroup.get('reservationTime').value ? 'Отмена' : 'Удаление'}}&nbsp;талона
    </h3>
    <div class="modal-body" style="min-height:100px">
      <div class="form compact" *ngIf="ticketFormGroup.get('reservationTime').value" [formGroup]="ticketFormGroup">
        <section class="form-block itech-block-normal">
          <div class="form-group">
            <label for="cancelCaption">Причина отмены</label>
            <label for="cancelCaption" aria-haspopup="true" role="tooltip"
                   class="tooltip tooltip-validation tooltip-md"
                   [class.invalid]="isInvalid(ticketFormGroup, 'cancelCaption')">
              <textarea type="text" id="cancelCaption" placeholder="Опишите причину отмены талона"
                        class="itech-control-xlarge" formControlName="cancelCaption" maxlength="256">
              </textarea>
            </label>
          </div>
        </section>
      </div>
    </div>
    <div class="modal-footer itech-form-actions">
      <div></div>
      <div>
        <button type="button" class="btn btn-primary" (click)="planningUi.deleteTicket(model)"
                [disabled]="planningUi.deleting || planningUi.storing? true: undefined" [clrLoading]="planningUi.storing">
          <clr-icon shape="check"></clr-icon>&nbsp;Подтвердить&nbsp;{{ticketFormGroup.get('reservationTime').value ? 'отмену' : 'удаление'}}
        </button>
        <button type="button" class="btn btn-warning" (click)="undoModal()"
                [disabled]="planningUi.deleting || planningUi.storing? true: undefined">
          <clr-icon shape="undo"></clr-icon>&nbsp;Вернуться
        </button>
      </div>
    </div>
  </clr-modal>
  