import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BookmarkService } from '../../logic/services/bookmark.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { DataCachingService } from '../../logic/services/data-caching.service';
import { GroupOperationsService } from '../../logic/services/group-operations.service';

import { MetadataService } from '../../logic/services/metadata.service';
import { Observable, Subscription, timer } from 'rxjs/index';
import { environment } from '../../../environments/environment';
import { FormHelper } from '../../ui/controls/form-helper';
import { GroupOperationMetadataService } from '../../logic/services/group-operation-metadata.service';

@Component({
  templateUrl: './group-operation-runner.component.html'
})
export class GroupOperationRunnerComponent implements OnInit, OnDestroy {

  OperationParamsCode: string;
  OperationTypeId: number;
  ComponentModelCacheId: string;
  ReportMetadata: any;

  model: any = GroupOperationRunnerComponent.createNewModel();

  private tickTimer: Observable<number>;
  private tickSub: Subscription;

  get contextFormGroup(): FormGroup {
    return this.model.form;
  }

  static createNewModel(): any {
    return {
      form: undefined,
      operationId: undefined,
      operationTypeId: undefined,
      state: undefined,
      result: undefined,
      started: false,
      completed: false,
      startedFromOtherUi: false,
      newlyEnteredFile: undefined,
    };
  }

  constructor(private bmService: BookmarkService,
              private fb: FormBuilder,
              private dataCachingService: DataCachingService,
              private route: ActivatedRoute,
              private groupOperationsService: GroupOperationsService,
              private groupOperationMetadataService: GroupOperationMetadataService,
              private metadataService: MetadataService) {
  }

  ngOnInit(): void {
    this.tickTimer = timer(1000, 1000);
    this.tickSub = this.tickTimer.subscribe(t => this.checkCurrentOperationState());

    this.route.paramMap
      .subscribe(params => {

        this.OperationParamsCode = params.get('opCode');


        this.groupOperationMetadataService.getOpParamsGroupDef$(params, this.OperationParamsCode).subscribe(defaultParams => {
          this.groupOperationMetadataService.getReportMetadata$(this.OperationParamsCode).subscribe(reportMetadata => {

            this.ReportMetadata = reportMetadata;
            this.OperationTypeId = reportMetadata.operationTypeId;
            this.ComponentModelCacheId = 'GroupOp_' + this.OperationParamsCode;

            const randomUniqueTag = params.get('uniqueTag');

            const existing = this.dataCachingService.getCachedData(this.ComponentModelCacheId, randomUniqueTag);

            if (!existing) {

              if (randomUniqueTag.startsWith('new$')) {
                this.model.operationId = undefined;
              } else {
                this.model.operationId = +randomUniqueTag;
                this.model.started = true;
                this.model.startedFromOtherUi = true;
              }

              this.model.operationTypeId = this.OperationTypeId;

              this.model.form = this.fb.group(defaultParams);

              this.dataCachingService.addToCache(this.ComponentModelCacheId, randomUniqueTag, this.model);
            } else {
              this.model = existing;
            }

            this.addBookmark(params);
          });
        });
      });
  }

  ngOnDestroy(): void {
    if (this.tickSub) {
      this.tickSub.unsubscribe();
      this.tickSub = undefined;
    }
  }

  checkCurrentOperationState() {
    if (!this.model.operationId
      || (this.model.state && this.model.state.state === 3)
      || (this.model.state && this.model.state.state === 5)) {
      return;
    }

    this.groupOperationsService.getOperationStatus(this.model.operationTypeId, this.model.operationId)
      .subscribe(state => {
        this.model.state = state;

        if (this.model.state.state === 3 || this.model.state.state === 5) {
          this.completeOperation();
        }
      });
  }

  runOperation() {
    if (!this.contextFormGroup.valid || this.model.started) {
      return;
    }

    const model = this.model;
    model.started = true;

    this.groupOperationsService.runOperation(
      this.model.operationTypeId,
      this.contextFormGroup.value,
      this.model.newlyEnteredFile).subscribe(
      opKey => {
        model.operationId = opKey.id;
      },
      () => {
        model.started = false;
      }
    );
  }

  isInvalid(cname: string) {
    return FormHelper.isInvalid(this.contextFormGroup, cname);
  }

  private completeOperation() {

    if (this.model.completed) {
      return;
    }

    this.model.completed = true;

    this.groupOperationsService.getOperationResult(this.model.operationTypeId, this.model.operationId)
      .subscribe(result => {
        this.model.result = result;

        if (this.model.result.result && !this.model.startedFromOtherUi) {
          this.navigateResults();
        }
      });
  }

  navigateResults() {
    this.metadataService.preloadFile(this.model.result.result).subscribe(fileHash => {
      window.open(environment.api + '/files/get?preloadId=' + encodeURIComponent(fileHash));
    });
  }

  getPercProgress() {
    return this.model.state ? Math.floor(this.model.state.progress / 100) : 0;
  }

  addBookmark(params: ParamMap) {
    const randomUniqueTag = params.get('uniqueTag');
    this.bmService.addGroupOperationBookmark(this.ReportMetadata.caption, randomUniqueTag,
      ['/operations/general', this.OperationParamsCode, randomUniqueTag, (params as any).params]);
  }

  fileChanged(e: any) {
    this.model.newlyEnteredFile = e.target.files[0];
    this.contextFormGroup.get('uploadedFileUri').setValue(e.target.files[0] ? e.target.files[0].name : undefined);
    this.contextFormGroup.markAsDirty();
  }
}
