<ng-container [formGroup]="contextFormGroup">

  <app-edit-animal-list *ngIf="productId" [animals]="sourceAnimalIds" [showAddManyAnimal]="false"
                        [showDeleteBtn]="productFormGroup.controls['status'].value === 0">
  </app-edit-animal-list>

  <div class="form-group" *ngIf="productId && sourceProductIds.length">
    <label class="required">Исходная продукция</label>
    <div>
      <ng-container *ngFor="let product of sourceProductIds.controls; index as i">
        <app-link-button [lookupName]="'product'" [id]="product.value"></app-link-button>
      </ng-container>
    </div>
  </div>

  <div *ngIf="isProductionTransaction && !productId">
    <ng-container *ngFor="let manyProduct of manyProducts.controls; index as i">
      <div style="display: flex; flex-direction: row;" [style.margin-top.px]="i > 0 ? 10 : 0">
        <div>
          <div style="width:750px;display:flex">
            <button type="button" class="btn btn-danger btn-link itech-inline-tool-btn"
                    style="text-transform:none;text-align: left;"
                    (click)="manyProduct.get('expanded').setValue(!manyProduct.get('expanded').value)">
              <clr-icon [attr.shape]="'angle ' + (manyProduct.get('expanded').value ? 'down' : 'right')"
                        style="color:navy"></clr-icon>
            </button>
            <app-link-button [id]="manyProduct.controls['stableId'].value" [lookupName]="'stable'" [widthControl]="400"></app-link-button>
            &nbsp;&nbsp;
            <button type="button" class="btn btn-danger btn-link itech-inline-tool-btn" title="Удалить"
                    (click)="removeManyProduct(i)">
              <clr-icon shape="trash" style="color:#d23b3b"></clr-icon>
            </button>
          </div>
          <div *ngIf="manyProduct.get('expanded').value" style="margin-left:20px">
            <label style="font-size:.541667rem;color:#000;line-height:0.6rem;">Сырьё:</label>
            <div>
              <app-grid-editable [columnDefs]="sourceBatchGridDef"
                                 [formArray]="manyProduct.get('sourceBatches')" [widthGrid]="775">
              </app-grid-editable>
            </div>
            <label style="font-size:.541667rem;color:#000;line-height:0.6rem;">Вырабатываемая продукция:</label>
            <div>
              <app-grid-editable [columnDefs]="productBatchGridDef" [validityControlsEvent]="validityControlsEvent"
                                 [formArray]="manyProduct.get('producedBatches')" [widthGrid]="775"
                                 [forceUpdateData]="forceUpdateProductBatchesTableDataEmitter"
                                 [masterDetail]="true" [masterDetailCalcHeightFunc]="masterDetailCalcHeightFunc">
              </app-grid-editable>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <button *ngIf="isProductionTransaction && !productId" class="btn btn-link" (click)="addNewTransaction()">
    <clr-icon shape="plus"></clr-icon>&nbsp;Добавить поднадзорный объект
  </button>

  <div *ngIf="!isProductionTransaction && !productId">
    <app-grid-editable [columnDefs]="transportDocsGridDef" [masterDetail]="true" [widthGrid]="980" (cellValueChanged)="isNeedMainTtn()"
                       [forceUpdateData]="forceUpdateTransportTableDataEmitter" [validityControlsEvent]="validityControlsEvent"
                       [formArray]="transportDocuments" [masterDetailCalcHeightFunc]="transportMasterDetailCalcHeightFunc"
                       [masterDetailRowAvailableFunc]="availableRowMasterDetailTransport" (gridReady)="transportGridReady($event)">
    </app-grid-editable>
  </div>

  <div class="form-group" *ngIf="productId && isTransportOrMovingTransaction && contextFormGroup.controls['templateOriginStableId'].value">
    <label class="required">Место отправления</label>
    <app-link-button [id]="contextFormGroup.controls['templateOriginStableId'].value" [lookupName]="'stable'"></app-link-button>
  </div>

  <div class="form-group" *ngIf="productId && isTransportOrMovingTransaction">
    <label class="required">Получатель</label>
    <app-link-button [id]="contextFormGroup.controls['destinationAgentId'].value"
                     [lookupName]="'agent'"></app-link-button>
    <button type="button" class="btn btn-danger btn-link itech-inline-tool-btn" title="Выбрать получателя продукции"
            (click)="searchAgent()">
      <clr-icon shape="search"></clr-icon>
    </button>
  </div>
  <div class="form-group"
       *ngIf="productId && isTransportOrMovingTransaction && !contextFormGroup.controls['destinationAgentId'].value">
    <label></label>
    <div class="itech-control-desc itech-desc-error">
      Выберите хозяйствующий субъект - получателя продукции
    </div>
  </div>

  <div class="form-group" *ngIf="productId && isTransportOrMovingTransaction">
    <label class="required">Место получения</label>
    <app-link-button [id]="contextFormGroup.controls['destinationStableId'].value"
                     [lookupName]="'stable'"></app-link-button>
    <button type="button" class="btn btn-danger btn-link itech-inline-tool-btn" title="Выбрать поднадзорный объект"
            (click)="searchStableByAgent(contextFormGroup.controls['destinationAgentId'].value)">
      <clr-icon shape="search"></clr-icon>
    </button>
  </div>
  <div class="form-group"
       *ngIf="productId && isTransportOrMovingTransaction && !contextFormGroup.controls['destinationStableId'].value">
    <label></label>
    <div class="itech-control-desc itech-desc-error">
      Выберите поднадзорный объект, на который перемешается продукция
    </div>
  </div>

  <div class="form-group" *ngIf="productId">
    <label for="{{rndToken}}value" class="required">Кол-во / ед. изм.</label>
    <label for="{{rndToken}}value" aria-haspopup="true" role="tooltip"
           class="tooltip tooltip-validation tooltip-md"
           [class.invalid]="isInvalid(contextFormGroup, 'value')">
      <input type="text" id="{{rndToken}}value" placeholder="Кол-во" class="itech-control-small"
             formControlName="value" (keypress)="processMoneyKeypress($event)">
      <app-validation-tooltip [input]="contextFormGroup.controls['value']"></app-validation-tooltip>
    </label>
    <app-combo-lookup [validationTooltipRight]="true" class="itech-control-normal"
                      [contextControlId]="rndToken + 'unitId'"
                      [contextControlName]="'unitId'" [sorted]="true"
                      [lookupName]="'drug-unit-type/product'" [contextFormGroup]="contextFormGroup"></app-combo-lookup>
    &nbsp;&nbsp;
    <label for="{{rndToken}}value" *ngIf="contextFormGroup.controls['currentValue'].value != null">
      Текущий остаток: {{contextFormGroup.controls['currentValue'].value}}
    </label>
    <label for="{{rndToken}}value" *ngIf="contextFormGroup.controls['acceptValue'].value">
      Погашено: {{contextFormGroup.controls['acceptValue'].value}}
    </label>
  </div>

  <div class="form-group" *ngIf="productId">
    <label for="{{rndToken}}packages">Уровень/кол-во/тип/маркировки упаковки</label>
    <div>
      <app-edit-product-packaging [packages]="packages" [isDraft]="isDraft">
      </app-edit-product-packaging>
    </div>
  </div>

  <div class="form-group" *ngIf="productId && isTransportOrMovingTransaction">
    <label for="productTypeId" class="required">Вид продукции</label>
    <app-text-lookup [validationTooltipRight]="true" class="itech-control-xlarge"
                     [contextControlId]="'productTypeId'" [sorted]="true" (change)="changeProductTypeId()"
                     [contextControlName]="'productTypeId'" [commentsFieldName]="'comments'"
                     [lookupName]="'product-type'" [contextFormGroup]="contextFormGroup"></app-text-lookup>
  </div>

  <div class="form-group" *ngIf="contextFormGroup.get('productTypeId').value && productId && isTransportOrMovingTransaction">
    <label for="productSubtypeId" class="required">Подвид продукции</label>
    <app-text-lookup [validationTooltipRight]="true" class="itech-control-xlarge"
                     [contextControlId]="'productSubtypeId'" [sorted]="true"
                     [contextControlName]="'productSubtypeId'"
                     [lookupName]="'product-subtype' + contextFormGroup.get('productTypeId').value"
                     [contextFormGroup]="contextFormGroup"></app-text-lookup>
  </div>

  <div class="form-group" *ngIf="productId">
    <label for="stableProductSubtypeId">Номенклатура</label>
    <app-text-lookup [validationTooltipRight]="true" class="itech-control-xlarge"
                     [contextControlId]="'stableProductSubtypeId'" [sorted]="true"
                     [contextControlName]="'stableProductSubtypeId'"
                     [lookupName]="lookupNameStableProductSubtype"
                     [contextFormGroup]="contextFormGroup"></app-text-lookup>
  </div>

  <div class="form-group" *ngIf="productId || isProductionTransaction">
    <label for="originCountry">Страна происхождения</label>
    <app-combo-lookup [validationTooltipRight]="true" class="itech-control-xlarge"
                      [contextControlId]="'originCountry'" [sorted]="true"
                      [contextControlName]="'originCountry'"
                      [lookupName]="'country'"
                      [contextFormGroup]="contextFormGroup"></app-combo-lookup>
  </div>

  <div class="form-group" *ngIf="productId">
    <label for="manufactureDateDate" class="required">
      Время производства
    </label>
    <app-date-combo [contextFormGroup]="contextFormGroup" [isRequiredDayAndHour]="isRequiredDateField"
                    [controlNameDate1]="'manufactureDate'" [controlNameDate2]="'manufactureDate2'"></app-date-combo>
  </div>

  <div class="form-group" *ngIf="productId">
    <label for="expireDate">
      Срок годности
    </label>
    <div>
      <div>
        <app-date-combo [contextFormGroup]="contextFormGroup" [isRequiredDayAndHour]="isRequiredDateField"
                        [controlNameDate1]="'expireDate'" [controlNameDate2]="'expireDate2'"></app-date-combo>
      </div>
      <div *ngIf="equalsSome(+contextFormGroup.get('expireDateKind').value, 1, 2, 3)"
           class="form-group itech-group-whole-line">
        <div class="checkbox-inline">
          <input type="checkbox" id="{{rndToken}}perishable" formControlName="perishable">
          <label for="{{rndToken}}perishable">Скоропортящаяся продукция</label>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group" *ngIf="productId || isProductionTransaction">
    <label for="{{rndToken}}expertiseStatus" class="required">Вет.сан. экспертиза</label>
    <app-combo-lookup [validationTooltipRight]="true" class="itech-control-large"
                      [contextControlId]="rndToken + 'expertiseStatus'"
                      [contextControlName]="'expertiseStatus'"
                      [lookupName]="'product-expertise-status'"
                      [contextFormGroup]="contextFormGroup"></app-combo-lookup>

    &nbsp;&nbsp;&nbsp;
    <div class="checkbox-inline">
      <input type="checkbox" id="cargoInspected" formControlName="cargoInspected">
      <label for="cargoInspected">Контроль гос. ветврачом</label>
    </div>
  </div>

  <div class="form-group" *ngIf="productId || isProductionTransaction">
    <label for="{{rndToken}}useRestriction">Ограничения по использованию</label>
    <app-combo-lookup [validationTooltipRight]="true" class="itech-control-xlarge"
                      [contextControlId]="rndToken + 'useRestriction'"
                      [contextControlName]="'useRestriction'"
                      [lookupName]="'product-use-restriction'"
                      [contextFormGroup]="contextFormGroup"></app-combo-lookup>
  </div>

  <div class="form-group itech-double-height v-resize" *ngIf="productId || isProductionTransaction">
    <label for="locationProsperity" class="required">Благополучие местности</label>
    <label for="locationProsperity" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
           [class.invalid]="isInvalid(contextFormGroup, 'locationProsperity')">
      <app-input-text [contextId]="'locationProsperity'" [classNames]="'itech-control-xlarge itech-text-wall'"
                      [formControlObj]="contextFormGroup.get('locationProsperity')" [htmlType]="2"
                      [placeholder]="'Укажите сведения о благополучии местности'"></app-input-text>
      <app-validation-tooltip [input]="contextFormGroup.controls['locationProsperity']"></app-validation-tooltip>
    </label>
  </div>

  <div class="form-group" *ngIf="productId || isProductionTransaction">
    <label for="animalSpentPeriodCode">Нахождение в таможенной зоне</label>
    <app-combo-lookup
        [class.itech-control-xlarge]="contextFormGroup.controls['animalSpentPeriodCode'].value != 'IN_MONTHS'"
        [class.itech-control-large]="contextFormGroup.controls['animalSpentPeriodCode'].value == 'IN_MONTHS'"
        [contextControlId]="'animalSpentPeriodCode'" [sorted]="true"
        [contextControlName]="'animalSpentPeriodCode'" [lookupName]="'animal-spent-period'"
        [contextFormGroup]="contextFormGroup"></app-combo-lookup>

    <ng-container *ngIf="contextFormGroup.controls['animalSpentPeriodCode'].value == 'IN_MONTHS'">
      &nbsp;&nbsp;&nbsp;
      <label for="monthsSpent" aria-haspopup="true" role="tooltip"
             class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'monthsSpent')">
        <input type="text" id="monthsSpent" class="itech-control-normal" placeholder="мес"
               formControlName="monthsSpent">
        <app-validation-tooltip [input]="contextFormGroup.controls['monthsSpent']"></app-validation-tooltip>
      </label>
    </ng-container>
  </div>

  <div class="form-group" *ngIf="productId || isProductionTransaction">
    <label for="quarantineDuration" [class.highlight-warn]="+(contextFormGroup.controls['quarantineDuration'].value)">Карантин,
      дни</label>
    <label for="quarantineDuration" aria-haspopup="true" role="tooltip"
           class="tooltip tooltip-validation tooltip-md"
           [class.invalid]="isInvalid(contextFormGroup, 'quarantineDuration')">
      <input type="text" id="quarantineDuration" class="itech-control-small"
             formControlName="quarantineDuration">
      <app-validation-tooltip [input]="contextFormGroup.controls['quarantineDuration']"></app-validation-tooltip>
    </label>
  </div>

  <div class="form-group itech-double-height v-resize" *ngIf="+(contextFormGroup.controls['quarantineDuration'].value)">
    <label for="quarantineComments" class="highlight-warn">Карантин, информация</label>
    <label for="quarantineComments" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
           [class.invalid]="isInvalid(contextFormGroup, 'quarantineComments')">
      <app-input-text [contextId]="'quarantineComments'" [classNames]="'itech-control-xlarge itech-text-wall'"
                      [formControlObj]="contextFormGroup.get('quarantineComments')" [htmlType]="2"
                      [placeholder]="'Укажите дополнительную информацию о карантине'"></app-input-text>
      <app-validation-tooltip [input]="contextFormGroup.controls['quarantineComments']"></app-validation-tooltip>
    </label>
  </div>

  <div class="form-group itech-group-whole-line" *ngIf="productId || isProductionTransaction">
    <div class="checkbox-inline">
      <input type="checkbox" id="{{rndToken}}badQuality" formControlName="badQuality">
      <label for="{{rndToken}}badQuality">Некачественная продукция</label>
    </div>
  </div>

  <div class="form-group" *ngIf="productId || isProductionTransaction">
    <label for="{{rndToken}}productReasonId" [class.required]="productId">Цель производства /
      сбыта</label>
    <app-text-lookup [validationTooltipRight]="true" class="itech-control-xlarge"
                     [contextControlId]="rndToken + 'productReasonId'" [sorted]="true"
                     [contextControlName]="'productReasonId'"
                     [lookupName]="'product-reason'" [contextFormGroup]="contextFormGroup"></app-text-lookup>
  </div>

  <div class="form-group" *ngIf="productId">
    <label for="{{rndToken}}shipmentNo">Серия/номер защ. бланка</label>
    <label for="{{rndToken}}protectedDocSerial" aria-haspopup="true" role="tooltip"
           class="tooltip tooltip-validation tooltip-md"
           [class.invalid]="isInvalid(contextFormGroup, 'protectedDocSerial')">
      <app-input-text [contextId]="rndToken + 'protectedDocSerial'" [classNames]="'itech-control-small'"
                      [formControlObj]="contextFormGroup.get('protectedDocSerial')" [htmlType]="1"></app-input-text>
      <app-validation-tooltip [input]="contextFormGroup.controls['protectedDocSerial']"></app-validation-tooltip>
    </label>
    <label for="{{rndToken}}protectedDocNo" aria-haspopup="true" role="tooltip"
           class="tooltip tooltip-validation tooltip-md"
           [class.invalid]="isInvalid(contextFormGroup, 'protectedDocNo')">
      <app-input-text [contextId]="rndToken + 'protectedDocNo'" [classNames]="'itech-control-small'"
                      [formControlObj]="contextFormGroup.get('protectedDocNo')" [htmlType]="1"></app-input-text>
      <app-validation-tooltip [input]="contextFormGroup.controls['protectedDocNo']"></app-validation-tooltip>
    </label>
  </div>
  <div class="form-group" *ngIf="productId">
    <label for="{{rndToken}}bId">Номер произв.партии</label>
    <ng-container *ngFor="let bId of batchIds.controls; index as i">
      <label for="{{rndToken}}bId{{i}}" aria-haspopup="true" role="tooltip"
             class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid(contextFormGroup, 'batchId')">
        <app-input-text [contextId]="rndToken + bId + i" [classNames]="'itech-control-xlarge'"
                        [formControlObj]="bId" [htmlType]="1"></app-input-text>
        <app-validation-tooltip [input]="contextFormGroup.controls['batchId']"></app-validation-tooltip>
      </label>
    </ng-container>
  </div>

  <div class="form-group itech-double-height" *ngIf="productId || isProductionTransaction">
    <label for="comments">Комментарии</label>
    <label for="comments" aria-haspopup="true" role="tooltip"
           class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
           [class.invalid]="isInvalid(contextFormGroup, 'comments')">
      <app-input-text [contextId]="'comments'" [classNames]="'itech-control-xlarge itech-text-wall'"
                      [formControlObj]="contextFormGroup.get('comments')" [htmlType]="2"
                      [placeholder]="'Комментарии'"></app-input-text>
      <app-validation-tooltip [input]="contextFormGroup.controls['comments']"></app-validation-tooltip>
    </label>
  </div>

  <div class="form-group" *ngIf="isTransportOrMovingTransaction">
    <label for="{{rndToken}}ttnTypeId" class="required">Вид тов.-трансп. накладной</label>
    <app-combo-lookup [validationTooltipRight]="true" class="itech-control-large"
                      [contextControlId]="rndToken + 'ttnTypeId'"
                      [contextControlName]="'ttnTypeId'"
                      [lookupName]="'ttn-type'" [contextFormGroup]="contextFormGroup"></app-combo-lookup>
  </div>

  <div class="form-group" *ngIf="+(contextFormGroup.controls['ttnTypeId'].value)">
    <label for="{{rndToken}}ttnSerial">Серия номер и дата ТТН</label>
    <label for="{{rndToken}}ttnSerial" aria-haspopup="true" role="tooltip"
           class="tooltip tooltip-validation tooltip-md"
           [class.invalid]="isInvalid(contextFormGroup, 'ttnSerial')">
      <app-input-text [contextId]="rndToken + 'ttnSerial'" [classNames]="'itech-control-small'"
                      [formControlObj]="contextFormGroup.get('ttnSerial')" [htmlType]="1"></app-input-text>
      <app-validation-tooltip [input]="contextFormGroup.controls['ttnSerial']"></app-validation-tooltip>
    </label>
    <label for="{{rndToken}}ttnNo" aria-haspopup="true" role="tooltip"
           class="tooltip tooltip-validation tooltip-md"
           [class.invalid]="isInvalid(contextFormGroup, 'ttnNo')">
      <app-input-text [contextId]="rndToken + 'ttnNo'" [classNames]="'itech-control-small'"
                      [formControlObj]="contextFormGroup.get('ttnNo')" [htmlType]="1"></app-input-text>
      <app-validation-tooltip [input]="contextFormGroup.controls['ttnNo']"></app-validation-tooltip>
    </label>
    <label for="{{rndToken}}ttnDate" aria-haspopup="true" role="tooltip"
           class="tooltip tooltip-validation tooltip-md"
           [class.invalid]="isInvalid(contextFormGroup, 'ttnDate')">
      <input type="date" id="{{rndToken}}ttnDate" class="itech-control-normal"
             formControlName="ttnDate">
      <app-validation-tooltip [input]="contextFormGroup.controls['ttnDate']"></app-validation-tooltip>
    </label>
  </div>

</ng-container>
