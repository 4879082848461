import * as tslib_1 from "tslib";
import { QueryDetailsEntityComponent } from './query-details-entity.component';
import { QueryService } from '../../logic/services/query.service';
import { DataCachingService } from '../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../logic/services/lookup-source.service';
import { AlertService } from '../../ui/infrastructure/alert.service';
import { FormBuilder } from '@angular/forms';
import { FormHelper } from '../../ui/controls/form-helper';
import { AddressComponent } from '../../ui/controls/address.component';
var QueryDetailsProductComponent = /** @class */ (function (_super) {
    tslib_1.__extends(QueryDetailsProductComponent, _super);
    function QueryDetailsProductComponent(queryService, dataCachingService, waitingOverlay, lookupSourceService, alertService, fb) {
        var _this = _super.call(this, queryService, dataCachingService, waitingOverlay, lookupSourceService, alertService, fb) || this;
        _this.EntityType = 7; // product
        return _this;
    }
    QueryDetailsProductComponent.getGroupDef = function (fb, condition) {
        return {
            type: 'product',
            _par_created_institution: condition.data._par_created_institution,
            _par_created_institution_filter: condition.data._par_created_institution_filter || 0,
            _par_created_institution_employee: condition.data._par_created_institution_employee,
            _par_created_institution_employee_filter: condition.data._par_created_institution_employee_filter || 0,
            _par_created_time_filter: condition.data._par_created_time_filter || 0,
            _par_created_time_from: [condition.data._par_created_time_from, FormHelper.validateDateTimePicker()],
            _par_created_time_to: [condition.data._par_created_time_to, FormHelper.validateDateTimePicker()],
            _par_transaction_type: condition.data._par_transaction_type,
            _par_transaction_type_filter: condition.data._par_transaction_type_filter || 0,
            _par_stable_product_subtype_caption: condition.data._par_stable_product_subtype_caption,
            _par_stable_product_subtype_caption_filter: condition.data._par_stable_product_subtype_caption_filter || 0,
            _par_batch_id: condition.data._par_batch_id,
            _par_batch_id_filter: condition.data._par_batch_id_filter || 0,
            _par_manufacture_date_filter: condition.data._par_manufacture_date_filter || 0,
            _par_manufacture_date_from: [condition.data._par_manufacture_date_from, FormHelper.validateDateTimePicker()],
            _par_manufacture_date_to: [condition.data._par_manufacture_date_to, FormHelper.validateDateTimePicker()],
            _par_expiration_date_filter: condition.data._par_expiration_date_filter || 0,
            _par_expiration_date_from: [condition.data._par_expiration_date_from, FormHelper.validateDateTimePicker()],
            _par_expiration_date_to: [condition.data._par_expiration_date_to, FormHelper.validateDateTimePicker()],
            _par_origin_agent_id: condition.data._par_origin_agent_id,
            _par_origin_agent_id_filter: condition.data._par_origin_agent_id_filter || 0,
            _par_origin_stable_id: fb.array(condition.data._par_origin_stable_id || []),
            _par_origin_stable_id_filter: condition.data._par_origin_stable_id_filter || 0,
            _par_destination_agent_id: condition.data._par_destination_agent_id,
            _par_destination_agent_id_filter: condition.data._par_destination_agent_id_filter || 0,
            _par_destination_stable_id: fb.array(condition.data._par_destination_stable_id || []),
            _par_destination_stable_id_filter: condition.data._par_destination_stable_id_filter || 0,
            _par_origin_agent_address: fb.group(AddressComponent.buildFormGroupDef(fb, condition, condition.data._par_origin_agent_address, false)),
            _par_origin_agent_address_filter: condition.data._par_origin_agent_address_filter || 0,
            _par_origin_stable_address: fb.group(AddressComponent.buildFormGroupDef(fb, condition, condition.data._par_origin_stable_address, false)),
            _par_origin_stable_address_filter: condition.data._par_origin_stable_address_filter || 0,
            _par_destination_agent_address: fb.group(AddressComponent.buildFormGroupDef(fb, condition, condition.data._par_destination_agent_address, false)),
            _par_destination_agent_address_filter: condition.data._par_destination_agent_address_filter || 0,
            _par_destination_stable_address: fb.group(AddressComponent.buildFormGroupDef(fb, condition, condition.data._par_destination_stable_address, false)),
            _par_destination_stable_address_filter: condition.data._par_destination_stable_address_filter || 0,
            _par_storage_way_id: condition.data._par_storage_way_id,
            _par_storage_way_id_filter: condition.data._par_storage_way_id_filter || 0,
            _par_origin_country: condition.data._par_origin_country,
            _par_origin_country_filter: condition.data._par_origin_country_filter || 0,
            _par_expertise_status: condition.data._par_expertise_status,
            _par_expertise_status_filter: condition.data._par_expertise_status_filter || 0,
            _par_use_restriction: condition.data._par_use_restriction,
            _par_use_restriction_filter: condition.data._par_use_restriction_filter || 0,
            _par_location_prosperity: condition.data._par_location_prosperity,
            _par_location_prosperity_filter: condition.data._par_location_prosperity_filter || 0,
            _par_animal_spent_period_code: condition.data._par_animal_spent_period_code,
            _par_animal_spent_period_code_filter: condition.data._par_animal_spent_period_code_filter || 0,
            _par_months_spent: condition.data._par_months_spent,
            _par_months_spent_filter: condition.data._par_months_spent_filter || 0,
            _par_bad_quality_filter: condition.data._par_bad_quality_filter,
            _par_product_reason_id: condition.data._par_product_reason_id,
            _par_product_reason_id_filter: condition.data._par_product_reason_id_filter || 0,
            _par_ttn_type_id: condition.data._par_ttn_type_id,
            _par_ttn_type_id_filter: condition.data._par_ttn_type_id_filter || 0,
            _par_ttn_no: condition.data._par_ttn_no,
            _par_ttn_no_filter: condition.data._par_ttn_no_filter || 0,
            _par_ttn_serial: condition.data._par_ttn_serial,
            _par_ttn_serial_filter: condition.data._par_ttn_serial_filter || 0,
            _par_ttn_date_filter: condition.data._par_ttn_date_filter || 0,
            _par_ttn_date_from: [condition.data._par_ttn_date_from, FormHelper.validateDateTimePicker()],
            _par_ttn_date_to: [condition.data._par_ttn_date_to, FormHelper.validateDateTimePicker()],
            _par_broker_agent_id: condition.data._par_broker_agent_id,
            _par_broker_agent_id_filter: condition.data._par_broker_agent_id_filter || 0,
            _par_transportation_kind_id: condition.data._par_transportation_kind_id,
            _par_transportation_kind_id_filter: condition.data._par_transportation_kind_id_filter || 0,
            _par_comments: condition.data._par_comments,
            _par_comments_filter: condition.data._par_comments_filter || 0,
        };
    };
    QueryDetailsProductComponent.prototype.getGroupDef = function () {
        if (!this.model.condition.data['_par_ttn_type_id']) {
            this.model.condition.data['_par_ttn_no_filter'] = null;
            this.model.condition.data['_par_ttn_serial_filter'] = null;
        }
        return QueryDetailsProductComponent.getGroupDef(this.fb, this.model.condition);
    };
    return QueryDetailsProductComponent;
}(QueryDetailsEntityComponent));
export { QueryDetailsProductComponent };
