/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./common/edit-product-common-form.component.ngfactory";
import * as i2 from "./common/edit-product-common-form.component";
import * as i3 from "@angular/forms";
import * as i4 from "../../logic/services/app-navigation.service";
import * as i5 from "../../ui/infrastructure/global-waiting-overlay.service";
import * as i6 from "../../logic/services/product-data.service";
import * as i7 from "../../logic/services/lookup-source.service";
import * as i8 from "@clr/angular";
import * as i9 from "@angular/common";
import * as i10 from "../../../../node_modules/@clr/angular/clr-angular.ngfactory";
import * as i11 from "./create-product.component";
import * as i12 from "@angular/router";
import * as i13 from "../../logic/services/bookmark.service";
import * as i14 from "../../logic/services/data-caching.service";
import * as i15 from "../../logic/services/security.service";
import * as i16 from "../../logic/services/stable-data.service";
var styles_CreateProductComponent = [];
var RenderType_CreateProductComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CreateProductComponent, data: {} });
export { RenderType_CreateProductComponent as RenderType_CreateProductComponent };
function View_CreateProductComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-edit-product-common-form", [], null, null, null, i1.View_EditProductCommonFormComponent_0, i1.RenderType_EditProductCommonFormComponent)), i0.ɵdid(1, 49152, null, 0, i2.EditProductCommonFormComponent, [i3.FormBuilder, i4.AppNavigationService, i5.GlobalWaitingOverlayService, i6.ProductDataService, i7.LookupSourceService, i0.ChangeDetectorRef], { model: [0, "model"], contextFormGroup: [1, "contextFormGroup"], serverSideErrorsProvider: [2, "serverSideErrorsProvider"], validityControlsEvent: [3, "validityControlsEvent"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model; var currVal_1 = _co.model.form; var currVal_2 = _co.model; var currVal_3 = _co.validityControlsEvent; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_CreateProductComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "itech-content-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "clr-icon", [["class", "has-badge"], ["shape", "truck"], ["size", "36"]], null, null, null, null, null)), i0.ɵdid(2, 16384, null, 0, i8.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, ["\u00A0\u0421\u043E\u0437\u0434\u0430\u043D\u0438\u0435 \u043D\u043E\u0432\u043E\u0433\u043E \u0432\u0435\u0442\u0435\u0440\u0438\u043D\u0430\u0440\u043D\u043E\u0433\u043E \u0434\u043E\u043A\u0443\u043C\u0435\u043D\u0442\u0430\n"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CreateProductComponent_1)), i0.ɵdid(5, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 14, "p", [], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 6, "button", [["class", "btn btn-primary"]], [[1, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createCard() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_ClrLoadingButton_0, i10.RenderType_ClrLoadingButton)), i0.ɵdid(8, 49152, null, 0, i8.ClrLoadingButton, [i0.ElementRef, i0.Renderer2], { disabled: [0, "disabled"] }, null), i0.ɵprd(2048, null, i8.LoadingListener, null, [i8.ClrLoadingButton]), i0.ɵdid(10, 147456, null, 0, i8.ClrLoading, [[2, i8.LoadingListener]], { loadingState: [0, "loadingState"] }, null), (_l()(), i0.ɵeld(11, 0, null, 0, 1, "clr-icon", [["shape", "check"]], null, null, null, null, null)), i0.ɵdid(12, 16384, null, 0, i8.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, 0, ["\u00A0\u0421\u043E\u0437\u0434\u0430\u0442\u044C "])), (_l()(), i0.ɵeld(14, 0, null, null, 6, "button", [["class", "btn btn-primary"]], [[1, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createCard(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_ClrLoadingButton_0, i10.RenderType_ClrLoadingButton)), i0.ɵdid(15, 49152, null, 0, i8.ClrLoadingButton, [i0.ElementRef, i0.Renderer2], { disabled: [0, "disabled"] }, null), i0.ɵprd(2048, null, i8.LoadingListener, null, [i8.ClrLoadingButton]), i0.ɵdid(17, 147456, null, 0, i8.ClrLoading, [[2, i8.LoadingListener]], { loadingState: [0, "loadingState"] }, null), (_l()(), i0.ɵeld(18, 0, null, 0, 1, "clr-icon", [["shape", "check"]], null, null, null, null, null)), i0.ɵdid(19, 16384, null, 0, i8.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, 0, ["\u00A0\u0421\u043E\u0437\u0434\u0430\u0442\u044C \u0438 \u0437\u0430\u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u043E\u0432\u0430\u0442\u044C "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model; _ck(_v, 5, 0, currVal_0); var currVal_2 = _co.creating; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.creating; _ck(_v, 10, 0, currVal_3); var currVal_5 = (_co.creating || (_co.model.form && _co.model.form.get("isTemplate").value)); _ck(_v, 15, 0, currVal_5); var currVal_6 = _co.creating; _ck(_v, 17, 0, currVal_6); }, function (_ck, _v) { var currVal_1 = (i0.ɵnov(_v, 8).disabled ? "" : null); _ck(_v, 7, 0, currVal_1); var currVal_4 = (i0.ɵnov(_v, 15).disabled ? "" : null); _ck(_v, 14, 0, currVal_4); }); }
export function View_CreateProductComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-create-product", [], null, null, null, View_CreateProductComponent_0, RenderType_CreateProductComponent)), i0.ɵdid(1, 114688, null, 0, i11.CreateProductComponent, [i12.ActivatedRoute, i12.Router, i13.BookmarkService, i6.ProductDataService, i14.DataCachingService, i4.AppNavigationService, i15.SecurityService, i3.FormBuilder, i7.LookupSourceService, i5.GlobalWaitingOverlayService, i16.StableDataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CreateProductComponentNgFactory = i0.ɵccf("app-create-product", i11.CreateProductComponent, View_CreateProductComponent_Host_0, { model: "model" }, {}, []);
export { CreateProductComponentNgFactory as CreateProductComponentNgFactory };
