import { Component } from '@angular/core';
import { MetaBrowseBaseComponent } from '../meta-browse.base.component';
import { ActivatedRoute } from '@angular/router';
import { MetadataService } from '../../../logic/services/metadata.service';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { StringHelper } from '../../../helpers/string-helper';

@Component({
  templateUrl: './product-subtype-browse.component.html'
})
export class ProductSubtypeBrowseComponent extends MetaBrowseBaseComponent {

  gridColumnDefs = [
    {headerName: '№', field: 'id', width: 100},
    {headerName: 'Тип', field: 'productTypeCaption', tooltipField: 'productTypeCaption', width: 200},
    {headerName: 'Наименование', field: 'caption', tooltipField: 'caption', width: 350},
    {headerName: 'Guid (Меркурий)', field: 'mercuryGuid', tooltipField: 'mercuryGuid', width: 260},
    {
      headerName: 'Удалено', field: 'dateDeleted', width: 100,
      valueFormatter: params => StringHelper.getRuTimeWithSeconds(params.value),
    },
    {
      headerName: 'Требует убоя', field: 'requiredSlaughter',
      valueFormatter: params => params && params.value ? 'Да' : 'Нет'
    },
    {
      headerName: 'Без животных и сырья', field: 'withoutAnimalOrProduct',
      valueFormatter: params => params && params.value ? 'Да' : 'Нет'
    },
  ];

  getRuDate = StringHelper.getRuDate;

  constructor(route: ActivatedRoute,
              metadataService: MetadataService,
              alertService: AlertService,
              globalWaitingOverlayService: GlobalWaitingOverlayService,
              lookupService: LookupSourceService,
              bookmarkService: BookmarkService,
              fb: FormBuilder,
              dataCachingService: DataCachingService) {
    super(route, metadataService, alertService, globalWaitingOverlayService, lookupService, fb, bookmarkService, dataCachingService);
  }

  getGroupDef(row: any) {
    return {
      id: [row.id, Validators.compose([Validators.required, Validators.pattern(/^\d{1,5}$/)])],
      productTypeId: [row.productTypeId, Validators.compose([Validators.required])],
      caption: [row.caption, Validators.required],
      mercuryGuid: row.mercuryGuid,
      expertiseIndicatorTypeIds: this.fb.array(row.expertiseIndicatorTypeIds || []),
      expertiseIndicatorTypeIdsLastUpdate: row.expertiseIndicatorTypeIdsLastUpdate,
      requiredSlaughter: row.requiredSlaughter || false,
      withoutAnimalOrProduct: row.withoutAnimalOrProduct || false,
      supportedManufactureDateFormatIds: this.fb.array(row.supportedManufactureDateFormatIds || []),
      supportedExpireDateFormatIds: this.fb.array(row.supportedExpireDateFormatIds || []),
    };
  }

  getMetaName(): string {
    return 'product-subtype';
  }

  getMetaTitle(): string {
    return 'Подвиды продукции';
  }

  getId(formGroup: FormGroup): any {
    return formGroup.get('productTypeId').value && formGroup.get('id').value
      ? formGroup.get('productTypeId').value + '/' + formGroup.get('id').value
      : undefined;
  }

  refreshResults() {
    this.globalWaitingOverlayService.StartWaiting();

    this.lookupService.getLookupObj('product-type').subscribe(productTypeLookup => {
      this.metadataService.getMetadata(this.getMetaName()).subscribe({
        next: data => {

          data.forEach(el => {
            el.productTypeCaption = productTypeLookup[el.productTypeId] || '<<Неизвестный вид>>';
          });

          this._searchResults = data;
          this.lookupService.invalidateLookup(this.getMetaName());
          this.dataCachingService.addToCache(this.getMetaName() + '_Data', '1', data);
          this.globalWaitingOverlayService.EndWaiting();
        }, error: () => {
          this.globalWaitingOverlayService.EndWaiting();
        }
      });
    });
  }

  getRowStyle(params: any) {
    if (params.data && params.data.dateDeleted) {
      return {color: 'silver'};
    }
  }

  clearExpertiseIndicatorTypeIds(row: any) {
    this.metadataService.clearExpertiseIndicatorTypeIdsForProductSubtype(row.productTypeId, row.id)
      .subscribe(() => this.editModalOpened = false);
  }
}
