import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { MetadataService } from '../../../logic/services/metadata.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MetaBrowseBaseComponent } from '../meta-browse.base.component';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { StringHelper } from '../../../helpers/string-helper';
import { DataCachingService } from '../../../logic/services/data-caching.service';

@Component({
  templateUrl: './quarantine-event-type-browse.component.html'
})
export class QuarantineEventTypeBrowseComponent extends MetaBrowseBaseComponent {

  gridColumnDefs = [
    {headerName: '№', field: 'id', width: 100},
    {headerName: 'Раздел', field: 'branchIdCaption', tooltipField: 'branchIdCaption', width: 200},
    {headerName: 'Наименование', field: 'caption', tooltipField: 'caption', width: 350},
    {
      headerName: 'Удалено', field: 'dateDeleted', width: 100,
      valueFormatter: params => StringHelper.getRuTimeWithSeconds(params.value),
    },
  ];

  constructor(route: ActivatedRoute,
              metadataService: MetadataService,
              alertService: AlertService,
              globalWaitingOverlayService: GlobalWaitingOverlayService,
              lookupService: LookupSourceService,
              bookmarkService: BookmarkService,
              fb: FormBuilder,
              dataCachingService: DataCachingService) {
    super(route, metadataService, alertService, globalWaitingOverlayService, lookupService, fb, bookmarkService, dataCachingService);
  }

  getMetaTitle(): string {
    return 'Виды карантинных мероприятий';
  }

  getMetaName(): string {
    return 'quarantine-event-type';
  }

  refreshResults() {
    this.globalWaitingOverlayService.StartWaiting();

    this.lookupService.getLookupObj('quarantine-event-branch').subscribe(eventBranchLookup => {
      this.metadataService.getMetadata(this.getMetaName()).subscribe({
        next: data => {

          data.forEach(el => {
            el.branchIdCaption = eventBranchLookup[el.branchId] || '<<Неизвестный раздел>>';
          });

          this._searchResults = data;
          this.lookupService.invalidateLookup(this.getMetaName());
          this.dataCachingService.addToCache(this.getMetaName() + '_Data', '1', data);
          this.globalWaitingOverlayService.EndWaiting();
        }, error: () => {
          this.globalWaitingOverlayService.EndWaiting();
        }
      });
    });
  }

  getGroupDef(row: any) {
    return {
      id: [row.id, Validators.compose([Validators.required, Validators.pattern(/^\d{1,5}$/)])],
      branchId: [row.branchId, Validators.compose([Validators.required])],
      caption: [row.caption, Validators.required],
    };
  }


  getRowStyle(params: any) {
    if (params.data && params.data.dateDeleted) {
      return {color: 'silver'};
    }
  }
}
