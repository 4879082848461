import { OnInit } from '@angular/core';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { ProductDataService } from '../../../logic/services/product-data.service';
import { ProductSearchResultsFormComponent } from '../../edit-product/search/product-search-results-form.component';
import { AgGridProductTransactionDetailRendererComponent } from '../../../ui/controls/ag-grid-product-transaction-detail-renderer.component';
import { ProductSearchResultsComponent } from '../../edit-product/search/product-search-results.component';
import { MetadataService } from '../../../logic/services/metadata.service';
import { environment } from '../../../../environments/environment';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';
import { StableDataService } from '../../../logic/services/stable-data.service';
import { SecurityService } from '../../../logic/services/security.service';
import { FormBuilder } from '@angular/forms';
import { combineLatest } from 'rxjs';
var EditStableProductsComponent = /** @class */ (function () {
    function EditStableProductsComponent(productDataService, dataCachingService, waitingOverlay, lookupSourceService, navigationService, alertService, metadataService, personCacheService, securityService, fb, stableDataService) {
        var _this = this;
        this.productDataService = productDataService;
        this.dataCachingService = dataCachingService;
        this.waitingOverlay = waitingOverlay;
        this.lookupSourceService = lookupSourceService;
        this.navigationService = navigationService;
        this.alertService = alertService;
        this.metadataService = metadataService;
        this.personCacheService = personCacheService;
        this.securityService = securityService;
        this.fb = fb;
        this.stableDataService = stableDataService;
        this.model = {};
        this.isInvalid = FormHelper.isInvalid;
        this.getRowStyle = ProductSearchResultsFormComponent.getRowStyle;
        this.gridColumnDefs = ProductSearchResultsFormComponent.getGridColumnDefs();
        this.equalsSome = FormHelper.equalsSome;
        this.stableTypeIdLookup = {};
        this.detailCellRenderer = 'myDetailCellRenderer';
        this.frameworkComponents = { myDetailCellRenderer: AgGridProductTransactionDetailRendererComponent };
        this.gridIcons = {
            groupContracted: '<clr-icon shape="organization" style="cursor: pointer"></clr-icon>',
        };
        this.syncStateResult = {};
        this.updateSyncStatePending = false;
        this.lookupSourceService.getLookupObj('stable-type').subscribe(function (lookup) { return _this.stableTypeIdLookup = lookup; });
        this.model.currentDate = new Date();
    }
    Object.defineProperty(EditStableProductsComponent.prototype, "id", {
        get: function () {
            return this._id;
        },
        set: function (id) {
            if (this._id !== id) {
                this._id = id;
                this.refreshFormDataInternal();
            }
        },
        enumerable: true,
        configurable: true
    });
    EditStableProductsComponent.prototype.buildSyncState = function (item) {
        this.syncStateForUpdate = this.fb.group({
            targetObjId: item.targetObjId,
            conversationTypeId: item.conversationTypeId,
            lastAppliedTimeTo: item.lastAppliedTimeTo,
        });
    };
    EditStableProductsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.updateSyncStateTimer = setInterval(function () { return _this.refreshSyncStateInternal(true); }, 10000);
    };
    EditStableProductsComponent.prototype.refreshFormData = function () {
        this.dataCachingService.removeCachedData('EditStableProducts', this.id.toString());
        this.dataCachingService.removeCachedData('EditStableProductsSyncState', this.id.toString());
        this.dataCachingService.removeCachedData('EditStableProductsTemplates', this.id.toString());
        this.refreshFormDataInternal();
    };
    EditStableProductsComponent.prototype.getPreviousMonth = function () {
        if (this.model.currentDate.getMonth() === 0) {
            this.model.currentDate.setMonth(11);
            this.model.currentDate.setFullYear(this.model.currentDate.getFullYear() - 1);
        }
        else {
            this.model.currentDate.setMonth(this.model.currentDate.getMonth() - 1);
        }
        this.refreshFormData();
    };
    EditStableProductsComponent.prototype.getNextMonth = function () {
        if (this.model.currentDate.getMonth() === 11) {
            this.model.currentDate.setMonth(0);
            this.model.currentDate.setFullYear(this.model.currentDate.getFullYear() + 1);
        }
        else {
            this.model.currentDate.setMonth(this.model.currentDate.getMonth() + 1);
        }
        this.refreshFormData();
    };
    EditStableProductsComponent.prototype.refreshFormDataInternal = function (isBackground) {
        var _this = this;
        if (isBackground === void 0) { isBackground = false; }
        if (!isBackground) {
            this.waitingOverlay.StartWaiting();
        }
        var existing = this.dataCachingService.getCachedData('EditStableProducts', this.id.toString());
        if (existing) {
            this.model = existing;
            if (!isBackground) {
                this.waitingOverlay.EndWaiting();
            }
        }
        else {
            this.productDataService.getStableProducts(this.id, this.getDateFrom(), this.getDateTo())
                .subscribe({
                next: function (data) {
                    combineLatest([
                        _this.lookupSourceService.getLookupObj('product-subtype'),
                        _this.lookupSourceService.getLookupObj('product-status'),
                        _this.lookupSourceService.getLookupObj('transaction-type'),
                    ])
                        .subscribe(function (_a) {
                        var productSubtypeLookup = _a[0], productStatusLookup = _a[1], transactionTypeLookup = _a[2];
                        data.forEach(function (el) {
                            el.transactionTypeCaption = transactionTypeLookup[el.transactionType];
                            el.statusCaption = productStatusLookup[el.status];
                            if (el.status === -100 && el.templateCaption) {
                                el.statusCaption = 'Шаблон: ' + el.templateCaption;
                            }
                            if (el.stableProductSubtypeCaption) {
                                el.productSubtypeIdCaption = el.stableProductSubtypeCaption;
                            }
                            else {
                                var productSubtypeRec = productSubtypeLookup[el.productTypeId].find(function (ptst) { return ptst.id === el.productSubtypeId; });
                                el.productSubtypeIdCaption = productSubtypeRec ? productSubtypeRec.caption : undefined;
                            }
                        });
                        data = data.filter(function (el) { return !(_this.model.selectedRows || []).find(function (sr) { return sr.id === el.id; }); });
                        data.sort(function (a, b) { return b.id - a.id; });
                        (_this.model.selectedRows || []).forEach(function (selectedRow) { return data.splice(0, 0, selectedRow); });
                        _this.model = {
                            searchResults: data,
                            currentDate: _this.model.currentDate,
                            selectedRows: _this.model.selectedRows || [],
                            selectedRowsIds: _this.model.selectedRowsIds || [],
                        };
                        _this.dataCachingService.addToCache('EditStableProducts', _this.id.toString(), _this.model);
                        _this.refreshTemplatesInternal();
                        _this.refreshSyncState();
                        if (!isBackground) {
                            _this.waitingOverlay.EndWaiting();
                        }
                    });
                },
                error: function () {
                    if (!isBackground) {
                        _this.waitingOverlay.EndWaiting();
                    }
                }
            });
        }
        this.syncState = undefined;
        var existingSyncState = this.dataCachingService.getCachedData('EditStableProductsSyncState', this.id.toString());
        if (existingSyncState) {
            this.updateSyncState(existingSyncState);
        }
        else {
            this.refreshSyncStateInternal();
        }
    };
    EditStableProductsComponent.prototype.refreshTemplatesInternal = function () {
        var _this = this;
        var existing = this.dataCachingService.getCachedData('EditStableProductsTemplates', this.id.toString());
        if (existing) {
            this.model.templates = existing;
        }
        else {
            this.productDataService.getStableProductsTemplates(this.id)
                .subscribe({
                next: function (data) {
                    _this.model.templates = data;
                    _this.dataCachingService.addToCache('EditStableProductsTemplates', _this.id.toString(), _this.model.templates);
                }
            });
        }
    };
    EditStableProductsComponent.prototype.getDateFrom = function () {
        return new Date(this.model.currentDate.getFullYear(), this.model.currentDate.getMonth(), 1);
    };
    EditStableProductsComponent.prototype.getDateTo = function () {
        return new Date(this.model.currentDate.getFullYear(), this.model.currentDate.getMonth() + 1, 0);
    };
    EditStableProductsComponent.prototype.addNew = function () {
        this.navigationService.performProductCreation(null, null, null, null, this.id);
    };
    EditStableProductsComponent.prototype.updateFromMercuryAndRefreshFormData = function () {
        var _this = this;
        this.stableDataService.startSyncIncomingProducts(this.id).subscribe(function () { return _this.refreshSyncState(); });
    };
    EditStableProductsComponent.prototype.onGridSelectionChanged = function (params) {
        this.model.selectedRows = params.api.getSelectedRows();
        this.model.selectedRows.sort(function (a, b) { return a.id - b.id; });
        this.model.selectedRowsIds = this.model.selectedRows.map(function (x) { return x.id; });
    };
    EditStableProductsComponent.prototype.deleteDocument = function () {
        var _this = this;
        ProductSearchResultsComponent.deleteManyDocuments(this.model.selectedRows, this.alertService, this.productDataService, function () { return _this.refreshFormData(); });
    };
    EditStableProductsComponent.prototype.sendToRegistration = function () {
        var _this = this;
        ProductSearchResultsComponent.sendManyDocumentsToRegistration(this.model.selectedRows, this.alertService, this.productDataService, function () { return _this.refreshFormData(); });
    };
    EditStableProductsComponent.prototype.sendToClearance = function () {
        var _this = this;
        ProductSearchResultsComponent.sendManyDocumentsToClearance(this.model.selectedRows, this.alertService, this.productDataService, function () { return _this.refreshFormData(); });
    };
    EditStableProductsComponent.prototype.sendToAcceptManyProductRequests = function () {
        var _this = this;
        this.productDataService.startSyncDecisionMakingProductRequest({
            productRequestIds: this.model.selectedRows.map(function (x) { return x.id; }),
            newProductRequestStatus: 2,
        }).subscribe(function () { return _this.refreshFormData(); });
    };
    EditStableProductsComponent.prototype.createTransportForSelectedDocuments = function () {
        this.navigationService.performProductCreation(undefined, undefined, undefined, undefined, this.id, this.model.selectedRows.map(function (x) { return x.id; }));
    };
    EditStableProductsComponent.prototype.downloadLog = function (filename) {
        this.metadataService.preloadFile(filename).subscribe(function (fileHash) {
            window.open(environment.api + '/files/get?preloadId=' + encodeURIComponent(fileHash));
        });
    };
    EditStableProductsComponent.prototype.refreshSyncState = function () {
        this.syncState = undefined;
        this.refreshSyncStateInternal();
    };
    EditStableProductsComponent.prototype.refreshSyncStateInternal = function (isRetry) {
        var _this = this;
        if (isRetry === void 0) { isRetry = false; }
        if (!this.model) {
            return;
        }
        // если ничего не запланировано по синхронизации - ничего и не может произойти и обновлять состояние не надо
        if (this.model && this.syncState && this.syncState.mercuryExchangeStatus < 2
            && (!this.syncState.mercuryExchangeNextTime || new Date(this.syncState.mercuryExchangeNextTime) > new Date())) {
            return;
        }
        if (this.updateSyncStatePending) {
            return;
        }
        this.updateSyncStatePending = true;
        this.metadataService.getSyncState(this.id, 2).subscribe({
            next: function (data) {
                if (isRetry && _this.syncState && +_this.syncState.mercuryExchangeStatus !== +data.mercuryExchangeStatus) {
                    _this.dataCachingService.removeCachedData('EditStableProducts', _this.id.toString());
                    _this.refreshFormDataInternal(true);
                }
                _this.updateSyncStatePending = false;
                _this.updateSyncState(data);
                if (_this.syncState) {
                    _this.syncState.withoutMercuryUuid = true;
                }
                _this.dataCachingService.addToCache('EditStableProductsSyncState', _this.id.toString(), _this.syncState);
            }, error: function () {
                _this.updateSyncStatePending = false;
            }
        });
    };
    EditStableProductsComponent.prototype.updateSyncState = function (syncData) {
        if (syncData) {
            this.syncState = syncData;
            this.syncState.isEditStarted = false;
            if (syncData.mercuryExchangeLastResultText) {
                try {
                    this.syncStateResult = JSON.parse(syncData.mercuryExchangeLastResultText);
                }
                catch (ignored) {
                    this.syncStateResult = undefined;
                }
            }
        }
    };
    EditStableProductsComponent.prototype.startEditSyncState = function (state) {
        if (!this.syncStateForUpdate) {
            this.buildSyncState(state);
        }
        state.isEditStarted = !state.isEditStarted;
    };
    EditStableProductsComponent.prototype.cancelEditSyncState = function (state) {
        state.isEditStarted = false;
        this.syncStateForUpdate = undefined;
    };
    EditStableProductsComponent.prototype.saveEditSyncState = function (state) {
        var _this = this;
        state.isEditStarted = false;
        this.metadataService.updateConversation(this.syncStateForUpdate.value)
            .subscribe(function () {
            _this.syncStateForUpdate = undefined;
            _this.refreshFormData();
        });
    };
    EditStableProductsComponent.prototype.updateSelected = function ($event) {
        var _this = this;
        if ($event.api) {
            $event.api.forEachNode(function (node) {
                if (_this.model.selectedRows.some(function (el) { return el.id === node.data.id; })) {
                    $event.api.getRowNode(node.id).setSelected(true);
                }
            });
        }
    };
    return EditStableProductsComponent;
}());
export { EditStableProductsComponent };
