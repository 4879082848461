
    <ng-container *ngIf="htmlType === htmlTypeInput; else htmlTypeTextArea">
      <input #textInput type="text" [id]="contextId" [classList]="classNames" [formControl]="formControlObj" style="padding-left:20px"
             [placeholder]="placeholder" (keypress)="keyPressCallback($event)" [attr.disabled]="disabled ? true : undefined"
             (keydown)="keyDownEmitter.emit($event)">
    </ng-container>
    <ng-template #htmlTypeTextArea>
      <textarea #textInput type="text" [id]="contextId" [placeholder]="placeholder" [classList]="classNames" style="padding-left:20px"
                [formControl]="formControlObj" [attr.disabled]="disabled ? true : undefined"
                (keydown)="keyDownEmitter.emit($event)"></textarea>
    </ng-template>
    <div class="app-input-tag" style="max-width:17px;">
      <clr-dropdown>
        <button class="btn btn-link app-input-tag-icon" clrDropdownToggle>
          <clr-icon shape="tag" size="14" style="color:green"></clr-icon>
        </button>
        <clr-dropdown-menu *clrIfOpen [clrPosition]="'bottom-left'" style="padding:5px 0">
          <ng-container *ngIf="((userService.getUserInfo()|async).userSettingUi.tags || []).length">
            <ng-container *ngFor="let group of (userService.getUserInfo()|async).userSettingUi.groupingTags | keyvalue">
              <ng-container *ngIf="group.key !== '-1'; else withoutGroup">
                <clr-dropdown style="width:100%">
                  <button class="btn btn-link app-input-tag-icon"
                          style="padding-left:10px;font-size:12px;line-height:14px;height: 18px;" clrDropdownToggle>
                    {{group.key}}
                  </button>
                  <clr-dropdown-menu *clrIfOpen [clrPosition]="'right-top'" style="padding:5px 0">
                    <button *ngFor="let tag of group.value" type="button" (click)="selectTag(tag)"
                            class="btn btn-link app-input-tag-icon"
                            style="padding-left:10px;font-size:12px;line-height:14px;height: 18px;" clrDropdownItem>
                      {{tag.caption}}
                    </button>
                  </clr-dropdown-menu>
                </clr-dropdown>
              </ng-container>
              <ng-template #withoutGroup>
                <button *ngFor="let tag of group.value" type="button" (click)="selectTag(tag)"
                        class="btn btn-link app-input-tag-icon"
                        style="padding-left:10px;font-size:12px;line-height:14px;height: 18px;" clrDropdownItem>
                  {{tag.caption}}
                </button>
              </ng-template>
            </ng-container>
            <div class="dropdown-divider"></div>
          </ng-container>
          <button type="button" (click)="addTag()"
                  class="btn btn-link app-input-tag-icon"
                  style="padding-left:10px;font-size:12px;line-height:14px;height: 18px;" clrDropdownItem>
            Добавить тэг
          </button>
        </clr-dropdown-menu>
      </clr-dropdown>
    </div>
    <app-user-tags-autofill-edit-modal [modalOpenedEmit]="addNewTagModalOpened">
    </app-user-tags-autofill-edit-modal>