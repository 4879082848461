import * as tslib_1 from "tslib";
import { QueryService } from '../../logic/services/query.service';
import { DataCachingService } from '../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../logic/services/lookup-source.service';
import { FormBuilder } from '@angular/forms';
import { AlertService } from '../../ui/infrastructure/alert.service';
import { FormHelper } from '../../ui/controls/form-helper';
import { QueryDetailsEntityComponent } from './query-details-entity.component';
var QueryDetailsStableComponent = /** @class */ (function (_super) {
    tslib_1.__extends(QueryDetailsStableComponent, _super);
    function QueryDetailsStableComponent(queryService, dataCachingService, waitingOverlay, lookupSourceService, alertService, fb) {
        var _this = _super.call(this, queryService, dataCachingService, waitingOverlay, lookupSourceService, alertService, fb) || this;
        _this.EntityType = 1; // stable
        return _this;
    }
    QueryDetailsStableComponent.getGroupDef = function (condition) {
        return {
            type: 'stable',
            _par_title_filter: condition.data._par_title_filter,
            _par_title: condition.data._par_title,
            _par_region_id_filter: condition.data._par_region_id_filter || 0,
            _par_region_id: condition.data._par_region_id,
            _par_regionIdCityId_filter: condition.data._par_city_region_id_filter,
            _par_regionIdCityId: condition.data._par_city_region_id && condition.data._par_city_id
                ? condition.data._par_city_region_id.toString() + '-' + condition.data._par_city_id.toString()
                : undefined,
            _par_city_region_id_filter: condition.data._par_city_region_id_filter || 0,
            _par_city_region_id: condition.data._par_city_region_id,
            _par_city_id_filter: condition.data._par_city_id_filter || 0,
            _par_city_id: condition.data._par_city_id,
            _par_street_id_filter: condition.data._par_street_id_filter || 0,
            _par_street_id: condition.data._par_street_id,
            _par_addr_house_filter: condition.data._par_addr_house_filter,
            _par_addr_house: condition.data._par_addr_house,
            _par_other_type_address_filter: condition.data._par_other_type_address_filter,
            _par_other_text_address_filter: condition.data._par_other_text_address_filter,
            _par_other_text_address: condition.data._par_other_text_address,
            _par_created_institution_filter: condition.data._par_created_institution_filter || 0,
            _par_created_institution: condition.data._par_created_institution,
            _par_created_institution_employee_filter: condition.data._par_created_institution_employee_filter || 0,
            _par_created_institution_employee: condition.data._par_created_institution_employee,
            _par_created_time_filter: condition.data._par_created_time_filter || 0,
            _par_created_time_from: [condition.data._par_created_time_from, FormHelper.validateDateTimePicker()],
            _par_created_time_to: [condition.data._par_created_time_to, FormHelper.validateDateTimePicker()],
            _par_deleted_institution_filter: condition.data._par_deleted_institution_filter || 0,
            _par_deleted_institution: condition.data._par_deleted_institution,
            _par_deleted_institution_employee_filter: condition.data._par_deleted_institution_employee_filter || 0,
            _par_deleted_institution_employee: condition.data._par_deleted_institution_employee,
            _par_deleted_time_filter: condition.data._par_deleted_time_filter || 0,
            _par_deleted_time_from: [condition.data._par_deleted_time_from, FormHelper.validateDateTimePicker()],
            _par_deleted_time_to: [condition.data._par_deleted_time_to, FormHelper.validateDateTimePicker()],
        };
    };
    QueryDetailsStableComponent.prototype.getGroupDef = function () {
        return QueryDetailsStableComponent.getGroupDef(this.model.condition);
    };
    return QueryDetailsStableComponent;
}(QueryDetailsEntityComponent));
export { QueryDetailsStableComponent };
