
    <ng-container *ngIf="!flexMode; else showFlexMode">
        <ng-container *ngFor="let item of boundArray.controls; index as i">
            <div class="form-group">
                <label for="{{contextControlName + i}}">
                    {{itemTitle + ' №' + (i + 1).toString()}}
                </label>
                <app-text-lookup [contextControlId]="contextControlName + i" [class]="classControl"
                                 [contextControlName]="i" [required]="false"
                                 [lookupName]="lookupName" [sorted]="sorted" [disabled]="disabled"
                                 [contextFormGroup]="boundArray"></app-text-lookup>
            </div>
        </ng-container>
        <div class="form-group" *ngIf="!disabled">
            <label for="{{contextControlName + 'N'}}">
                {{itemTitle + ' №' + (boundArray.controls.length + 1).toString()}}
            </label>
            <app-text-lookup [contextControlId]="contextControlName + 'N'" [class]="classControl"
                             [contextControlName]="'value'" [required]="false"
                             [lookupName]="lookupName" [sorted]="sorted" [disabled]="disabled"
                             [contextFormGroup]="emptyElementForm"></app-text-lookup>
        </div>
    </ng-container>
    <ng-template #showFlexMode>
      <div class="form-group" style="display:flex;flex-direction:column">
        <label for="{{contextControlName + 'N'}}">
          {{itemTitle}}
        </label>
        <ng-container *ngFor="let itemFlex of boundArray.controls; index as y">
          <app-text-lookup [contextControlId]="contextControlName + y" [class]="classControl"
                           [contextControlName]="y" [required]="false"
                           [lookupName]="lookupName" [sorted]="sorted" [disabled]="disabled"
                           [contextFormGroup]="boundArray"></app-text-lookup>
        </ng-container>
        <app-text-lookup *ngIf="!disabled"
                         [contextControlId]="contextControlName + 'N'" [class]="classControl"
                         [contextControlName]="'value'" [required]="false"
                         [lookupName]="lookupName" [sorted]="sorted" [disabled]="disabled"
                         [contextFormGroup]="emptyElementForm"></app-text-lookup>
      </div>
    </ng-template>
  