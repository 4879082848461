import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/internal/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AgentDataService = /** @class */ (function () {
    function AgentDataService(http) {
        this.http = http;
    }
    AgentDataService.prototype.search = function (searchParams) {
        var qstring = environment.api + '/agent/search';
        return this.http.post(qstring, searchParams)
            .pipe(map(function (response) {
            return response;
        }));
    };
    AgentDataService.prototype.getAgentCommonForEdit = function (id) {
        var qstring = environment.api + '/agent/' + id.toString(10);
        return this.http.get(qstring, { headers: { 'interceptNoAccess': 'true' } })
            .pipe(map(function (item) {
            return item.data;
        }));
    };
    AgentDataService.prototype.getAgentCommonForEditWithSync = function (id) {
        var qstring = environment.api + '/agent/resync-with-mercury/' + id.toString(10);
        return this.http.get(qstring, { headers: { 'interceptNoAccess': 'true' } })
            .pipe(map(function (item) {
            return item.data;
        }));
    };
    AgentDataService.prototype.createByMercuryGuid = function (mercuryUuid) {
        var qstring = environment.api + '/agent/create-by-mercury-guid/' + mercuryUuid;
        return this.http.post(qstring, undefined).pipe(map(function (item) {
            return item.data;
        }));
    };
    AgentDataService.prototype.getAgentCommonForLookup = function (id) {
        var qstring = environment.api + '/agent/lookup/' + id.toString(10);
        return this.http.get(qstring);
    };
    AgentDataService.prototype.getAgentCommonForLookup2 = function (ids) {
        var qstring = environment.api + '/agent/lookup';
        return this.http.post(qstring, ids)
            .pipe(map(function (response) {
            return response;
        }));
    };
    AgentDataService.prototype.getAgentBranchesForEdit = function (id) {
        var qstring = environment.api + '/agent/branches/' + id.toString(10);
        return this.http.get(qstring).pipe(map(function (item) {
            return item;
        }));
    };
    AgentDataService.prototype.getAgentEmployeesForEdit = function (id) {
        var qstring = environment.api + '/agent/employees/' + id.toString(10);
        return this.http.get(qstring).pipe(map(function (item) {
            return item;
        }));
    };
    AgentDataService.prototype.getAgentBranchesForLookup = function (id) {
        var qstring = environment.api + '/agent/branches/lookup/' + id.toString(10);
        return this.http.get(qstring).pipe(map(function (item) {
            return item;
        }));
    };
    AgentDataService.prototype.getAgentEmployeesForLookup = function (id) {
        var qstring = environment.api + '/agent/employees/lookup/' + id.toString(10);
        return this.http.get(qstring).pipe(map(function (item) {
            return item;
        }));
    };
    AgentDataService.prototype.storeAgentCommonInfo = function (model) {
        var qstring = environment.api + '/agent/store-common';
        return this.http.post(qstring, model)
            .pipe(map(function (response, error) {
            return response.data;
        }));
    };
    AgentDataService.prototype.storeAgentBranches = function (AgentId, branches, deleted) {
        var model = {
            AgentId: AgentId,
            branches: branches,
            delete: deleted
        };
        var qstring = environment.api + '/agent/store-branches';
        return this.http.post(qstring, model)
            .pipe(map(function (response, error) {
            return true;
        }));
    };
    AgentDataService.prototype.storeAgentEmployees = function (AgentId, employees, deleted) {
        var model = {
            AgentId: AgentId,
            employees: employees,
            delete: deleted
        };
        var qstring = environment.api + '/agent/store-employees';
        return this.http.post(qstring, model)
            .pipe(map(function (response, error) {
            return true;
        }));
    };
    AgentDataService.prototype.deleteAgent = function (id) {
        var qstring = environment.api + '/agent/delete/' + id.toString(10);
        return this.http.post(qstring, {});
    };
    AgentDataService.prototype.restoreDeletedAgent = function (id) {
        var qstring = environment.api + '/agent/restore-deleted/' + id.toString(10);
        return this.http.post(qstring, {});
    };
    AgentDataService.prototype.removePersonalAccountAgent = function (id) {
        var qstring = environment.api + '/agent/remove-personal-account/' + id.toString(10);
        return this.http.post(qstring, {});
    };
    AgentDataService.prototype.mergeAgent = function (mainAgentId, sourceAgentId) {
        var qstring = environment.api + '/agent/merge/' + mainAgentId + '/' + sourceAgentId;
        return this.http.get(qstring);
    };
    AgentDataService.prototype.grantAccessOpenApi = function (agentId) {
        var qstring = environment.api + '/agent/grant-access-open-api/' + agentId;
        return this.http.get(qstring);
    };
    AgentDataService.prototype.revokeAccessOpenApi = function (agentId) {
        var qstring = environment.api + '/agent/revoke-access-open-api/' + agentId;
        return this.http.get(qstring);
    };
    AgentDataService.ngInjectableDef = i0.defineInjectable({ factory: function AgentDataService_Factory() { return new AgentDataService(i0.inject(i1.HttpClient)); }, token: AgentDataService, providedIn: "root" });
    return AgentDataService;
}());
export { AgentDataService };
