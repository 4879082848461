import { Component, Input, OnInit } from '@angular/core';
import { AddressComponent } from './address.component';
import { FormGroup } from '@angular/forms';
import { StableSearchModalComponent } from '../../pages/edit-stable/search/stable-search-modal.component';
import { AppNavigationService } from '../../logic/services/app-navigation.service';

@Component({
  selector: 'app-address-quarantine',
  templateUrl: './address.component.html',
})
export class AddressQuarantineTerritoryComponent extends AddressComponent implements OnInit {

  @Input() contextFormGroupExtended;

  kind = [
    { id: 0, caption: 'В регионе' },
    { id: 1, caption: 'Вне региона' },
    { id: 2, caption: 'Иной' },
    { id: 3, caption: 'Мун.образование' },
    { id: 4, caption: 'Поднадзорный объект' }
  ];

  constructor (private navigationService: AppNavigationService) {
    super();
  }

  ngOnInit() {
    this.contextFormGroup = this.contextFormGroupExtended.controls['address'];
    this.contextFormGroup.get('kind').valueChanges.subscribe(() =>
      this.contextFormGroupExtended.get('kind').setValue(this.contextFormGroup.get('kind').value));
  }

  searchStable(formGroup: FormGroup) {
    this.navigationService.searchStable(StableSearchModalComponent, {})
      .subscribe(val => {
        if (val) {
          formGroup.get('stableId').setValue(val);
          formGroup.markAsDirty();
        }
      });
  }
}
