import { OnChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AddressComponent } from '../../../ui/controls/address.component';
import { serverSideErrorsValidator } from '../../../logic/validators/server-side-errors-validator.directive';
import { AppFormGroup } from '../../../ui/controls/app-form-group';
var EditAgentCommonFormComponent = /** @class */ (function () {
    function EditAgentCommonFormComponent(fb) {
        this.fb = fb;
        this.syncStates = [];
        this.syncCommonInfo = {};
        this.isInvalid = FormHelper.isInvalid;
        this.isPhysOrIndividOrKfh = EditAgentCommonFormComponent.isPhysOrIndividOrKfh;
    }
    EditAgentCommonFormComponent.createFormGroup = function (fb, serverSideErrorsProvider, val) {
        return fb.group({
            id: val.id,
            createdTime: val.createdTime,
            createdUser: val.createdUser,
            deletedTime: val.deletedTime,
            deletedUser: val.deletedUser,
            legalFormId: [val.legalFormId, Validators.compose([Validators.required,
                    serverSideErrorsValidator('legalFormId', serverSideErrorsProvider)])],
            caption: [val.caption, Validators.compose([
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) { return !EditAgentCommonFormComponent.isPhysOrIndividOrKfh(ctrl.parent); }),
                    serverSideErrorsValidator('caption', serverSideErrorsProvider)
                ])],
            shortCaption: [val.shortCaption, Validators.compose([
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) { return !EditAgentCommonFormComponent.isPhysOrIndividOrKfh(ctrl.parent); }),
                    serverSideErrorsValidator('shortCaption', serverSideErrorsProvider)
                ])],
            phone: [val.phone, serverSideErrorsValidator('phone', serverSideErrorsProvider)],
            email: [val.email, serverSideErrorsValidator('email', serverSideErrorsProvider)],
            fax: [val.fax, serverSideErrorsValidator('fax', serverSideErrorsProvider)],
            otherTextInfo: [val.otherTextInfo, serverSideErrorsValidator('otherTextInfo', serverSideErrorsProvider)],
            ogrn: [val.ogrn, Validators.compose([Validators.pattern(/^\d*$/),
                    serverSideErrorsValidator('ogrn', serverSideErrorsProvider)])],
            dateTaxRegistered: [val.dateTaxRegistered, Validators.compose([
                    FormHelper.validateDateTimePicker(),
                    serverSideErrorsValidator('dateTaxRegistered', serverSideErrorsProvider)
                ])],
            dateTaxIncluded: [val.dateTaxIncluded, Validators.compose([FormHelper.validateDateTimePicker(),
                    serverSideErrorsValidator('dateTaxIncluded', serverSideErrorsProvider)])],
            dateTaxRegisterUpdated: [val.dateTaxRegisterUpdated, Validators.compose([FormHelper.validateDateTimePicker(),
                    serverSideErrorsValidator('dateTaxRegisterUpdated', serverSideErrorsProvider)])],
            dateTaxBranchUpdated: [val.dateTaxBranchUpdated, Validators.compose([FormHelper.validateDateTimePicker(),
                    serverSideErrorsValidator('dateTaxBranchUpdated', serverSideErrorsProvider)])],
            dateTaxEliminated: [val.dateTaxEliminated, Validators.compose([FormHelper.validateDateTimePicker(),
                    serverSideErrorsValidator('dateTaxEliminated', serverSideErrorsProvider)])],
            legalAddress: fb.group(AddressComponent.buildFormGroupDef(fb, serverSideErrorsProvider, val.legalAddress, true)),
            factAddress: fb.group(AddressComponent.buildFormGroupDef(fb, serverSideErrorsProvider, val.factAddress, false)),
            postAddress: fb.group(AddressComponent.buildFormGroupDef(fb, serverSideErrorsProvider, val.postAddress, false)),
            agentPersons: EditAgentCommonFormComponent.buildAgentPersonsArray(fb, serverSideErrorsProvider, val.agentPersons),
            okpo: [val.okpo, Validators.compose([
                    serverSideErrorsValidator('okpo', serverSideErrorsProvider)
                ])],
            okved: [val.okved, Validators.compose([
                    serverSideErrorsValidator('okved', serverSideErrorsProvider)
                ])],
            inn: [val.inn, Validators.compose([Validators.pattern(/^\d*$/),
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) { return !EditAgentCommonFormComponent.isPhysOrIndividOrKfh(ctrl.parent); }),
                    serverSideErrorsValidator('inn', serverSideErrorsProvider)])],
            kpp: [val.kpp, Validators.compose([Validators.pattern(/^\d*$/),
                    serverSideErrorsValidator('kpp', serverSideErrorsProvider)])],
            bankAccount: [val.bankAccount, Validators.compose([Validators.pattern(/^\d*$/),
                    serverSideErrorsValidator('bankAccount', serverSideErrorsProvider)])],
            bankPAccount: [val.bankPAccount, Validators.compose([
                    serverSideErrorsValidator('bankPAccount', serverSideErrorsProvider)
                ])],
            bankBik: [val.bankBik, Validators.compose([Validators.pattern(/^\d*$/),
                    serverSideErrorsValidator('bankBik', serverSideErrorsProvider)])],
            dateRegisteredExternalRegistry: val.dateRegisteredExternalRegistry,
            servicedInstitutionId: val.servicedInstitutionId,
            servicedInstitutionBranchId: val.servicedInstitutionBranchId,
            openApiKey: val.openApiKey,
        });
    };
    EditAgentCommonFormComponent.buildAgentPersonsArray = function (fb, serverSideErrorsProvider, val) {
        if (!val || !val.length) {
            val = [];
        }
        return fb.array(val.map(function (agentPerson) { return EditAgentCommonFormComponent.buildAgentPerson(fb, agentPerson); }));
    };
    EditAgentCommonFormComponent.buildAgentPerson = function (fb, agentPerson) {
        return fb.group({
            agentPersonTypeId: [agentPerson.agentPersonTypeId, Validators.compose([Validators.required])],
            lastName: [agentPerson.lastName, Validators.compose([Validators.required])],
            firstName: [agentPerson.firstName, Validators.compose([
                    FormHelper.conditionalValidate(Validators.required, function (ctrl) { return EditAgentCommonFormComponent.isPhysPerson(ctrl.parent); }),
                ])],
            middleName: agentPerson.middleName,
            birthDate: [agentPerson.birthDate, Validators.compose([
                    FormHelper.validateDateTimePicker()
                ])],
            identityDocSerial: [agentPerson.identityDocSerial],
            identityDocNumber: [agentPerson.identityDocNumber],
            identityDocDate: [agentPerson.identityDocDate, Validators.compose([
                    FormHelper.validateDateTimePicker(),
                ])],
            identityDocIssuer: agentPerson.identityDocIssuer,
            inn: [agentPerson.inn, Validators.compose([
                    Validators.pattern(/^\d{12}$/)
                ])],
            gender: agentPerson.gender,
        });
    };
    EditAgentCommonFormComponent.prettifyFormGroup = function (fg) {
        var agentPersons = fg.get('agentPersons');
        var legalFormId = +fg.get('legalFormId').value;
        var i = 0;
        while (i < agentPersons.length) {
            var person = agentPersons.controls[i];
            var isPhys = (+person.get('agentPersonTypeId').value) === 1;
            if (legalFormId === 0) {
                if (isPhys) {
                    i++;
                }
                else {
                    agentPersons.removeAt(i);
                }
            }
            else if (legalFormId === 45 || legalFormId === 31 || legalFormId === 13) {
                i++;
            }
            else {
                if (isPhys) {
                    agentPersons.removeAt(i);
                }
                else {
                    i++;
                }
            }
        }
    };
    EditAgentCommonFormComponent.isPhys = function (parent) {
        return parent && (+parent.get('legalFormId').value) === 0;
    };
    EditAgentCommonFormComponent.isPhysOrIndividOrKfh = function (parent) {
        return parent && ((+parent.get('legalFormId').value) === 0 ||
            (+parent.get('legalFormId').value) === 31 ||
            (+parent.get('legalFormId').value) === 13 ||
            (+parent.get('legalFormId').value) === 45);
    };
    EditAgentCommonFormComponent.isIndividOrKfh = function (parent) {
        return parent && ((+parent.get('legalFormId').value) === 31 ||
            (+parent.get('legalFormId').value) === 13 ||
            (+parent.get('legalFormId').value) === 45);
    };
    EditAgentCommonFormComponent.isPhysPerson = function (parent) {
        return parent && (+parent.get('agentPersonTypeId').value) === 1;
    };
    EditAgentCommonFormComponent.prototype.ngOnChanges = function () {
        if (!this.syncStates) {
            return;
        }
        this.syncCommonInfo = this.syncStates.find(function (x) { return x.conversationTypeId === -1; }) || {};
    };
    Object.defineProperty(EditAgentCommonFormComponent.prototype, "agentPersons", {
        get: function () {
            return this.contextFormGroup.get('agentPersons');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditAgentCommonFormComponent.prototype, "physAgentPersonFormGroup", {
        get: function () {
            if (!this.agentPersons) {
                return undefined;
            }
            var fg = this.agentPersons.controls.find(function (person) { return (+person.get('agentPersonTypeId').value) === 1; });
            if (!fg) {
                fg = EditAgentCommonFormComponent.buildAgentPerson(this.fb, {
                    agentPersonTypeId: 1,
                });
                this.agentPersons.push(fg);
                this.agentPersons.markAsDirty();
            }
            return fg;
        },
        enumerable: true,
        configurable: true
    });
    EditAgentCommonFormComponent.prototype.addAgentPerson = function () {
        var fg = EditAgentCommonFormComponent.buildAgentPerson(this.fb, {});
        this.agentPersons.push(fg);
        this.agentPersons.markAsDirty();
    };
    EditAgentCommonFormComponent.prototype.deleteAgentPerson = function (i) {
        this.agentPersons.removeAt(i);
        this.agentPersons.markAsDirty();
    };
    return EditAgentCommonFormComponent;
}());
export { EditAgentCommonFormComponent };
