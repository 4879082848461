<div class="itech-common-wrap" id="interceptNoAccess">
  <div>
    <div class="form compact" *ngIf="model && model.indicators">
      <section class="form-block itech-block-large itech-request-area">

        <div *ngFor="let group of model.indicators; index as j">

          <h3>{{group.indicatorType.caption}}</h3>

          <div *ngFor="let indicator of group.indicators; index as i">

            <ng-container *ngIf="indicator.showHistory">

              <div *ngIf="!indicator.last" class="form-group" style="margin-left: 20px">
                <label>
                  {{indicator.indicatorSubtype.caption}}
                </label>
                <div class="itech-text-field itech-control-large" style="color: silver; font-weight: normal">
                  Отсутствуют исторические значения показателя
                </div>
              </div>

              <div *ngFor="let history of indicator.indicators; index as k"
                   class="form-group" style="margin-left: 20px">
                <label for="value{{i}}{{j}}{{k}}">
                  {{k===0?indicator.indicatorSubtype.caption:''}}
                </label>
                <label for="value{{i}}{{j}}{{k}}" aria-haspopup="true" role="tooltip"
                       class="tooltip tooltip-validation tooltip-md" [formGroup]="history"
                       [class.invalid]="isInvalid(history, 'date')">
                  <input type="date" id="value{{i}}{{j}}{{k}}" class="itech-control-normal"
                         formControlName="date">
                  <app-validation-tooltip [input]="history.controls['date']"></app-validation-tooltip>
                </label>
                &nbsp;
                <label for="value{{i}}{{j}}{{k}}d" aria-haspopup="true" role="tooltip"
                       class="tooltip tooltip-validation tooltip-md" [formGroup]="history"
                       [class.invalid]="isInvalid(history, 'value')">
                  <input type="text" id="value{{i}}{{j}}{{k}}d" placeholder="0.00" class="itech-control-normal"
                         formControlName="value">
                  <app-validation-tooltip [input]="history.controls['value']"></app-validation-tooltip>
                </label>
                &nbsp;{{indicator.indicatorSubtype.unit}}&nbsp;
                <button type="button" class="btn btn-danger btn-link itech-inline-tool-btn" title="Удалить показатель"
                        (click)="deleteIndicator(indicator, history)">
                  <clr-icon shape="trash"></clr-icon>
                </button>
              </div>
            </ng-container>

            <div class="form-group" style="margin-left: 20px" *ngIf="!indicator.showHistory">
              <label for="value{{i}}{{j}}">
                {{indicator.indicatorSubtype.caption}}
              </label>

              <ng-container *ngIf="indicator.last">
                <label for="value{{i}}{{j}}d" aria-haspopup="true" role="tooltip"
                       class="tooltip tooltip-validation tooltip-md" [formGroup]="indicator.last"
                       [class.invalid]="isInvalid(indicator.last, 'date')">
                  <input type="date" id="value{{i}}{{j}}d" class="itech-control-normal" disabled
                         formControlName="date">
                  <app-validation-tooltip [input]="indicator.last.controls['date']"></app-validation-tooltip>
                </label>
                &nbsp;
                <label for="value{{i}}{{j}}" aria-haspopup="true" role="tooltip"
                       class="tooltip tooltip-validation tooltip-md" [formGroup]="indicator.last"
                       [class.invalid]="isInvalid(indicator.last, 'value')">
                  <input type="text" id="value{{i}}{{j}}" placeholder="0.00" class="itech-control-normal" disabled
                         formControlName="value">
                  <app-validation-tooltip [input]="indicator.last.controls['value']"></app-validation-tooltip>
                </label>
                &nbsp;
              </ng-container>

              <div class="itech-text-field itech-control-large" *ngIf="!indicator.last"
                   style="color: silver; font-weight: normal">
                Отсутствуют предыдущие значения
              </div>

              <label for="value{{i}}{{j}}d2" aria-haspopup="true" role="tooltip"
                     class="tooltip tooltip-validation tooltip-md" [formGroup]="indicator.next"
                     [class.invalid]="isInvalid(indicator.next, 'date')">
                <input type="date" id="value{{i}}{{j}}d2" class="itech-control-normal"
                       formControlName="date">
                <app-validation-tooltip [input]="indicator.next.controls['date']"></app-validation-tooltip>
              </label>
              &nbsp;
              <label for="value{{i}}_{{j}}_2" aria-haspopup="true" role="tooltip"
                     class="tooltip tooltip-validation tooltip-md" [formGroup]="indicator.next"
                     [class.invalid]="isInvalid(indicator.next, 'value')">
                <input type="text" id="value{{i}}_{{j}}_2" placeholder="0.00" class="itech-control-normal"
                       formControlName="value">
                <app-validation-tooltip [input]="indicator.next.controls['value']"></app-validation-tooltip>
              </label>
              &nbsp;{{indicator.indicatorSubtype.unit}}&nbsp;
              <button type="button" class="btn btn-danger btn-link itech-inline-tool-btn" title="Показать историю"
                      (click)="showHistory(indicator)">
                <clr-icon shape="history"></clr-icon>
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>

  <div class="itech-form-actions">
    <div>
      <button class="btn btn-primary-outline" (click)="navigationService.navigateJournal(undefined, 22, id, undefined)">
        <clr-icon shape="view-list"></clr-icon>&nbsp;Журнал
      </button>

      <button class="btn btn-secondary" (click)="refreshFormData()">
        <clr-icon shape="refresh"></clr-icon>&nbsp;Обновить
      </button>
    </div>
    <div>
      <button [clrLoading]="storing" class="btn btn-primary itech-form-save"
              [disabled]="!contextFormGroup || !contextFormGroup.dirty || storing"
              (click)="store()">
        <clr-icon shape="check"></clr-icon>&nbsp;Сохранить
      </button>
      <button class="btn btn-warning itech-form-cancel" [disabled]="!contextFormGroup || !contextFormGroup.dirty"
              (click)="cancelEdit()">
        <clr-icon shape="undo"></clr-icon>&nbsp;Отменить
      </button>
    </div>
  </div>
</div>
