
    <div class="container" *ngIf="params">
      <div>
        <span class="component">
          <button type="button" [style.color]="params.color" (click)="onClick()" [title]="params.title || 'выполнить действие'"
                  class="btn btn-link itech-inline-tool-btn itech-grid-status-bar-button-right">
            <clr-icon *ngIf="params.icon && params.icon === 'copy'" size="22" shape="copy"></clr-icon>
            <clr-icon *ngIf="params.icon && params.icon === 'pinboard'" size="22" shape="pinboard"></clr-icon>
            {{params.caption ? params.caption : ''}}
          </button>
        </span>
      </div>
    </div>
  