import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { ProductDataService } from '../../../logic/services/product-data.service';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormHelper } from '../../../ui/controls/form-helper';
import { EditExpertiseIndicatorsComponent } from './edit-expertise-indicators.component';
import { EditExpertiseCommonComponent } from './edit-expertise-common.component';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { MetadataService } from '../../../logic/services/metadata.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';
var Model = /** @class */ (function () {
    function Model() {
        this.valid = {};
        this.dirty = {};
        this.expertises = [];
        this.onSelectedChange = new EventEmitter();
    }
    Object.defineProperty(Model.prototype, "selected", {
        get: function () {
            return this._selected;
        },
        set: function (value) {
            if (this._selected !== value) {
                this._selected = value;
                this.onSelectedChange.next(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    return Model;
}());
var EditProductExpertisesComponent = /** @class */ (function () {
    function EditProductExpertisesComponent(productDataService, navigationService, alertService, dataCachingService, waitingOverlay, fb, router, route, metadataService, lookupService, cacheService) {
        this.productDataService = productDataService;
        this.navigationService = navigationService;
        this.alertService = alertService;
        this.dataCachingService = dataCachingService;
        this.waitingOverlay = waitingOverlay;
        this.fb = fb;
        this.router = router;
        this.route = route;
        this.metadataService = metadataService;
        this.lookupService = lookupService;
        this.cacheService = cacheService;
        this.model = new Model();
        this.updateSyncStatePending = false;
        this.storing = false;
    }
    Object.defineProperty(EditProductExpertisesComponent.prototype, "expertiseId", {
        get: function () {
            return this._expertiseId;
        },
        set: function (expertiseId) {
            if (expertiseId !== this._expertiseId) {
                this._expertiseId = expertiseId;
                this.trySelectExpertiseById(expertiseId);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditProductExpertisesComponent.prototype, "id", {
        get: function () {
            return this._id;
        },
        set: function (id) {
            if (this._id !== id) {
                if (this.model.selected && this.expertiseForm) {
                    Object.assign(this.model.selected.data, this.expertiseForm.value);
                }
                this._id = id;
                this.refreshFormDataInternal();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditProductExpertisesComponent.prototype, "expertiseForm", {
        get: function () {
            return this.model.selected ? this.model.selected.form : undefined;
        },
        enumerable: true,
        configurable: true
    });
    EditProductExpertisesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.updateSyncStateTimer = setInterval(function () { return _this.refreshSyncStateInternal(false); }, 3000);
    };
    EditProductExpertisesComponent.prototype.ngOnDestroy = function () {
        // если переходим на другую вкладку с выбранной сохраненной экспертизы - надо избавиться от хвоста в url
        if (this.router.isActive('/product-edit/' + this.id + '/expertises', false)) {
            this.router.navigate(['../..'], { relativeTo: this.route });
        }
        if (this.updateSyncStateTimer) {
            clearInterval(this.updateSyncStateTimer);
            this.updateSyncStateTimer = undefined;
        }
    };
    EditProductExpertisesComponent.prototype.refreshFormDataInternal = function () {
        var _this = this;
        this.waitingOverlay.StartWaiting();
        var existing = this.dataCachingService.getCachedData('EditProductExpertises', this.id.toString());
        if (existing && existing.expertises) {
            this.model = existing;
            if (this.expertiseId) {
                this.trySelectExpertiseById(this.expertiseId);
            }
            else {
                if (this.model.selected) {
                    this.tryNavigateToExpertise(this.model.selected.data.id);
                }
            }
            this.waitingOverlay.EndWaiting();
        }
        else {
            this.productDataService.getProductExpertises(this.id).subscribe(function (data) {
                _this.setExpertisesModel(data);
                _this.waitingOverlay.EndWaiting();
            }, function (error) {
                _this.setExpertisesModel([]);
                _this.waitingOverlay.EndWaiting();
            });
        }
    };
    EditProductExpertisesComponent.prototype.setExpertisesModel = function (data) {
        var _this = this;
        var expertisesLookup = {};
        this.model.expertises = [];
        data.sort(function (el1, el2) { return -el1.expertiseTime.localeCompare(el2.expertiseTime); });
        data.forEach(function (val) {
            var rqData = expertisesLookup[val.id];
            if (!rqData) {
                rqData = {};
                expertisesLookup[val.id] = rqData;
            }
            rqData.data = val;
            _this.model.expertises.push(rqData);
        });
        this.trySelectExpertiseById(this.expertiseId);
        this.dataCachingService.addToCache('EditProductExpertises', this.id.toString(), Object.assign({}, this.model));
    };
    EditProductExpertisesComponent.prototype.trySelectExpertiseById = function (expertiseId) {
        if (!expertiseId) {
            this.setCurrentExpertise(undefined);
            return;
        }
        var request;
        if (this.model.expertises && this.model.expertises.length > 0) {
            request = expertiseId ? this.findExpertise(expertiseId) : this.model.expertises[0];
            request = request ? request : this.model.expertises[0];
        }
        if (request) {
            this.trySelectExpertise(request);
        }
    };
    EditProductExpertisesComponent.prototype.trySelectExpertise = function (expertise) {
        var needSkipNavigate = this.model.selected && (this.model.selected === expertise
            || this.model.selected.data.id === expertise.data.id);
        this.setCurrentExpertise(expertise);
        // для firefox чтобы не вызвать цикличную навигацию по обращениям
        if (this.model.selected && expertise && expertise.data && !needSkipNavigate) {
            this.tryNavigateToExpertise(expertise.data.id);
        }
    };
    EditProductExpertisesComponent.prototype.tryNavigateToExpertise = function (expertiseId) {
        if (expertiseId) {
            this.router.navigate(['/product-edit', this.id, 'expertises', expertiseId]);
        }
    };
    EditProductExpertisesComponent.prototype.findExpertise = function (expertiseId) {
        for (var _i = 0, _a = this.model.expertises; _i < _a.length; _i++) {
            var item = _a[_i];
            if (item.data.id === expertiseId) {
                return item;
            }
        }
        return undefined;
    };
    EditProductExpertisesComponent.prototype.store = function (startSync) {
        var _this = this;
        FormHelper.markAsSubmitted(this.expertiseForm);
        this.expertiseForm.updateValueAndValidity();
        if (!this.expertiseForm.valid) {
            return;
        }
        this.storing = true;
        Object.assign(this.model.selected.data, this.expertiseForm.value);
        this.productDataService.storeProductExpertiseInfo(this.model.selected.data, startSync).subscribe(function (val) {
            _this.storing = false;
            _this.model.selected.data.id = val.id;
            _this.expertiseId = val.id;
            _this.expertiseForm.markAsPristine();
            _this.expertiseForm.markAsUntouched();
            _this.refreshCurrentExpertise();
            FormHelper.setSingleFormGroupServerSideValidationErrors({}, _this.model.selected, _this.expertiseForm);
            (val.relatedProductExpertiseIds || []).forEach(function (relatedId) {
                _this.cacheService.getProductExpertise(relatedId)
                    .subscribe(function (related) {
                    return _this.dataCachingService.removeCachedData('EditProductExpertises', related.productId.toString());
                });
            });
        }, function (error) {
            _this.storing = false;
            FormHelper.setSingleFormGroupServerSideValidationErrors(error, _this.model.selected, _this.expertiseForm);
        });
    };
    EditProductExpertisesComponent.prototype.cancelEdit = function () {
        if (this.model.selected.data.id) {
            delete this.model.selected.form;
            this.model.selected.loaded = false;
        }
        else {
            var ix = this.model.expertises.indexOf(this.model.selected);
            if (ix >= 0) {
                this.model.expertises.splice(ix, 1);
            }
            this.model.selected = this.model.expertises.length > 0 ? this.model.expertises[0] : undefined;
        }
        this.trySelectExpertise(this.model.selected);
    };
    EditProductExpertisesComponent.prototype.refreshFormData = function () {
        this.model.expertises = undefined;
        this.lookupService.invalidateLookup('expertise-indicator-type');
        this.refreshFormDataInternal();
    };
    EditProductExpertisesComponent.prototype.ensureSelectedFormGroup = function () {
        var _this = this;
        if (!this.model.selected.form) {
            var expertiseGroupDef = {
                productId: this.id
            };
            Object.assign(expertiseGroupDef, EditExpertiseCommonComponent.buildFormGroupDef(this.fb, this.model.selected));
            Object.assign(expertiseGroupDef, EditExpertiseIndicatorsComponent.buildFormArrayDef(this.fb, this.model.selected));
            this.model.selected.form = this.fb.group(expertiseGroupDef);
            this.model.selected.form.get('productExpertiseTypeId').valueChanges.subscribe(function () {
                if (_this.model.lastProductExpertiseTypeId !== _this.model.selected.form.get('productExpertiseTypeId').value) {
                    _this.model.selected.form.get('productExpertiseSubtypeId').setValue(undefined);
                    _this.model.lastProductExpertiseTypeId = _this.model.selected.form.get('productExpertiseTypeId').value;
                }
            });
        }
    };
    EditProductExpertisesComponent.prototype.refreshCurrentExpertise = function () {
        if (!this.model.selected) {
            return;
        }
        this.model.selected.loaded = false;
        this.setCurrentExpertise(this.model.selected);
    };
    EditProductExpertisesComponent.prototype.setCurrentExpertise = function (item) {
        var _this = this;
        if (this.expertiseForm && this.model.selected && !this.model.selected.noAccess) {
            Object.assign(this.model.selected.data, this.expertiseForm.value);
        }
        this.model.selected = item;
        if (!this.model.selected) {
            return;
        }
        // Вводим контейнер для полезной информации уровня представления, которая будет храниться в разрезе обращений.
        // Компоненты с целью уменьшения общей нагрузки на приложение смогут наполнять этот контейнер данными,
        // вычисленными на клиенте или полученными отдельными запросами
        this.model.selected.viewData = this.model.selected.viewData ? this.model.selected.viewData : {};
        // для запоминания открытой вкладки в обращении - общее, начисления, выплаты
        this.model.selected.viewData.tabsActive = this.model.selected.viewData.tabsActive ? this.model.selected.viewData.tabsActive : {
            commonActive: true,
            chargesActive: false,
            paymentActive: false
        };
        this.ensureSelectedFormGroup();
        if (!this.model.selected.loaded) {
            this.waitingOverlay.StartWaiting();
            this.productDataService.getProductExpertiseForEdit(this.id, this.model.selected.data.id).subscribe(function (data) {
                _this.model.selected.serverSideValidationErrors = [];
                Object.assign(_this.model.selected.data, data);
                _this.expertiseForm.patchValue(_this.model.selected.data);
                _this.setupExpertiseFormArrays();
                _this.expertiseForm.updateValueAndValidity();
                _this.model.selected.loaded = true;
                _this.refreshSyncState(true);
                _this.waitingOverlay.EndWaiting();
            }, function () {
                _this.model.selected.noAccess = true;
                _this.model.selected.loaded = true;
                _this.model.selected.data.socServices = [];
                _this.refreshSyncState(true);
                _this.waitingOverlay.EndWaiting();
            });
        }
        else {
            this.expertiseForm.patchValue(this.model.selected.data);
            this.setupExpertiseFormArrays();
            this.expertiseForm.updateValueAndValidity();
            this.refreshSyncState(true);
        }
    };
    EditProductExpertisesComponent.prototype.setupExpertiseFormArrays = function () {
        var _this = this;
        this.lookupService.getLookupObj('expertise-indicator-type')
            .subscribe(function (lookupObj) {
            var indicatorsFormArrayDef = (_this.model.selected.data.indicators || []).map(function (el) {
                var it = lookupObj['Obj' + el.indicatorTypeId] || {};
                el.indicatorTypeCaption = it.caption || '-';
                el.indicatorTypeLegalActCaption = it.legalAct || '-';
                el.indicatorTypeMethodCaption = it.method || '-';
                el.indicatorTypeNormativeValueCaption = it.normativeValue || '-';
                return EditExpertiseIndicatorsComponent.getIndicatorGroupDef(_this.fb, el);
            });
            indicatorsFormArrayDef.sort(function (a, b) {
                return +a.get('expertiseResultType').value === +b.get('expertiseResultType').value
                    ? a.get('indicatorTypeCaption').value.localeCompare(b.get('indicatorTypeCaption').value)
                    : a.get('expertiseResultType').value > b.get('expertiseResultType').value ? -1 : 1;
            });
            _this.expertiseForm.setControl('indicators', _this.fb.array(indicatorsFormArrayDef));
            _this.expertiseForm.setControl('relatedProductExpertiseIds', _this.fb.array((_this.model.selected.data.relatedProductExpertiseIds || []).map(function (el) { return _this.fb.control(el); })));
            _this.expertiseForm.get('relatedCreate').setValue((_this.model.selected.data.relatedProductExpertiseIds || []).length);
        });
    };
    EditProductExpertisesComponent.prototype.getDefaultExpertiseModel = function (expertiseData) {
        return {
            data: expertiseData,
            loaded: true,
            dummy: false
        };
    };
    EditProductExpertisesComponent.prototype.addExpertise = function (expertiseId) {
        var _this = this;
        this.waitingOverlay.StartWaiting();
        this.productDataService.getForCreateProductExpertise(expertiseId, this.productId).subscribe(function (newExpertiseData) {
            var newExpertise = _this.getDefaultExpertiseModel(newExpertiseData);
            _this.model.expertises.splice(0, 0, newExpertise);
            _this.setCurrentExpertise(newExpertise);
            _this.expertiseForm.markAsDirty();
            _this.waitingOverlay.EndWaiting();
        }, function () {
            _this.waitingOverlay.EndWaiting();
        });
    };
    EditProductExpertisesComponent.prototype.deleteExpertise = function () {
        var _this = this;
        if (!this.model.selected) {
            return;
        }
        this.alertService.confirmModal('Вы уверены, что хотите удалить экспертизу?').subscribe(function (confirm) {
            if (confirm) {
                _this.productDataService.deleteProductExpertise(_this.model.selected.data.productId, _this.model.selected.data.id)
                    .subscribe(function () {
                    _this.reloadExpertisesList();
                });
            }
        });
    };
    EditProductExpertisesComponent.prototype.reloadExpertisesList = function () {
        this.dataCachingService.removeCachedData('EditProductExpertises', this.id.toString());
        this.refreshFormData();
    };
    EditProductExpertisesComponent.prototype.refreshSyncState = function (isFirst) {
        this.model.selected.syncState = undefined;
        this.refreshSyncStateInternal(isFirst);
    };
    EditProductExpertisesComponent.prototype.refreshSyncStateInternal = function (isFirst) {
        var _this = this;
        if (!this.model || !this.model.selected || !this.model.selected.data ||
            !this.model.selected.data.id || (!isFirst && !this.model.selected.syncState)) {
            return;
        }
        // если ничего не запланировано по синхронизации - ничего и не может произойти и обновлять состояние не надо
        if (this.model && this.model.selected && this.model.selected.syncState && this.model.selected.syncState.mercuryExchangeStatus < 2
            && (!this.model.selected.syncState.mercuryExchangeNextTime ||
                new Date(this.model.selected.syncState.mercuryExchangeNextTime) > new Date())) {
            return;
        }
        if (this.updateSyncStatePending) {
            return;
        }
        this.updateSyncStatePending = true;
        var prevStageId = (this.model.selected.syncState || {}).conversationStageId;
        this.metadataService.getSyncState(this.model.selected.data.id, 8).subscribe({
            next: function (data) {
                _this.updateSyncStatePending = false;
                _this.model.selected.syncState = data;
                if (_this.model.selected.syncState) {
                    _this.model.selected.syncState.withoutMercuryUuid = true;
                    _this.model.selected.syncState.lookupStage = _this.model.selected.data.laboratoryEventTypeId === 36
                        ? 'product-expertise'
                        : 'common';
                }
                if (!isFirst && +data.mercuryExchangeStatus < 2) {
                    _this.refreshAfterSync(data.targetObjId);
                    if (prevStageId && +prevStageId < +data.conversationStageId) {
                        setTimeout(function () { _this.refreshSyncState(true); }, 1000);
                    }
                }
            }, error: function () {
                _this.updateSyncStatePending = false;
            }
        });
    };
    EditProductExpertisesComponent.prototype.refreshAfterSync = function (syncExpertiseId) {
        if (!syncExpertiseId || !this.model.selected || +this.model.selected.data.id !== syncExpertiseId) {
            return;
        }
        this.refreshFormData();
    };
    EditProductExpertisesComponent.prototype.getDisabledReports = function () {
        return [1].includes(this.model.selected.data.conclusionResultTypeId)
            ? [81]
            : [2].includes(this.model.selected.data.conclusionResultTypeId)
                ? [79, 80]
                : [79, 80, 81];
    };
    return EditProductExpertisesComponent;
}());
export { EditProductExpertisesComponent };
