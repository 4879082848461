import { Component, Input, OnChanges, HostBinding } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AgentSearchModalComponent } from '../../edit-agent/search/agent-search-modal.component';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { StableSearchModalComponent } from '../../edit-stable/search/stable-search-modal.component';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';

@Component({
  selector: 'app-query-stable-agent-filter',
  template: `
    <ng-container [formGroup]="contextFormGroup"  *ngIf="contextFormGroup
    && (!queryUserMode || queryUserMode['_par_' + parameterName + '_filter_mq'])">
      <label [style.background]="filtered?'linear-gradient(to right, #90ff90, white)':undefined">
        <ng-content></ng-content>
        <div class="checkbox checkbox-inline" title="Значение отсутствует (не указано)" *ngIf="!queryUserMode">
          <input type="checkbox" id="{{'_par_' + parameterName + '_filter_novalue'}}" [(ngModel)]="noValueChecked"
                 [ngModelOptions]="{standalone: true}">
          <label for="{{'_par_' + parameterName + '_filter_novalue'}}">X</label>
        </div>
      </label>
      <ng-container style="flex: 1000 1 auto">
        <ng-container *ngIf="this.parameterName.includes('agent_id')">
          <app-link-button [id]="contextFormGroup.controls['_par_' + parameterName].value" [lookupName]="getLookupName()"></app-link-button>
          <button *ngIf="contextFormGroup.controls['_par_' + parameterName].value" type="button"
                  (click)="clearValue()"
                  class="btn btn-danger btn-link itech-inline-tool-btn" title="Очистить">
            <clr-icon shape="times"></clr-icon>
          </button>
          <button *ngIf="!contextFormGroup.controls['_par_' + parameterName].value" type="button" (click)="search()"
                  class="btn btn-danger btn-link itech-inline-tool-btn" title="Выбрать">
            <clr-icon shape="search"></clr-icon>
          </button>
        </ng-container>

        <ng-container *ngIf="parameterName.includes('stable_id')">
          <ng-container *ngIf="!contextFormGroup.get('_par_' + parameterName).value.length">
            <button type="button" (click)="search()"
                    class="btn btn-danger btn-link itech-inline-tool-btn" title="Выбрать">
              <clr-icon shape="search"></clr-icon>
            </button>
          </ng-container>
          <ng-container *ngIf="contextFormGroup.get('_par_' + parameterName).value.length">
            <ng-container *ngFor="let stable of contextFormGroup.controls['_par_' + parameterName].value; index as i">
              <app-link-button [id]="contextFormGroup.controls['_par_' + parameterName].value[i]" [lookupName]="getLookupName()">
              </app-link-button>
              <button *ngIf="contextFormGroup.controls['_par_' + parameterName].value" type="button"
                      (click)="clearValue(i)"
                      class="btn btn-danger btn-link itech-inline-tool-btn" title="Очистить">
                <clr-icon shape="times"></clr-icon>
              </button>
              <button *ngIf="contextFormGroup.get('_par_' + parameterName).value.length == (i + 1)" type="button" (click)="search()"
                      class="btn btn-danger btn-link itech-inline-tool-btn" title="Выбрать">
                <clr-icon shape="search"></clr-icon>
              </button>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  `
})
export class QueryStableAgentFilterComponent {
  @Input() contextFormGroup: FormGroup;
  @Input() parameterName: string;
  @Input() queryUserMode: false;
  @HostBinding('attr.class') class = 'form-group';

  constructor(
    private cacheService: AddressPersonFioCacheService,
    private navigationService: AppNavigationService) {
  }

  isInvalid(cname: string) {
    return FormHelper.isInvalid(this.contextFormGroup, cname);
  }

  get customFilterChecked() {
    if (this.parameterName.includes('agent_id')) {
      return this.contextFormGroup && (+this.contextFormGroup.get('_par_' + this.parameterName + '_filter').value) === 101;
    } else if (this.parameterName.includes('stable_id')) {
      return this.contextFormGroup && (+this.contextFormGroup.get('_par_' + this.parameterName + '_filter').value) === 102;
    }
  }

  set customFilterChecked(val: any) {
    if (this.contextFormGroup) {
      this.parameterName.includes('agent_id')
        ? this.contextFormGroup.get('_par_' + this.parameterName + '_filter').setValue(val ? 101 : 0)
        : this.contextFormGroup.get('_par_' + this.parameterName + '_filter').setValue(val ? 102 : 0);
      this.contextFormGroup.markAsDirty();
    }
  }

  get noValueChecked() {
    return this.contextFormGroup && (+this.contextFormGroup.get('_par_' + this.parameterName + '_filter').value) === 1;
  }

  set noValueChecked(val: any) {
    if (this.contextFormGroup) {
      this.contextFormGroup.get('_par_' + this.parameterName + '_filter').setValue(val ? 1 : 0);
      this.contextFormGroup.markAsDirty();
    }
  }

  get filtered() {
    return !!this.contextFormGroup.get('_par_' + this.parameterName + '_filter').value
      || this.parameterName.includes('stable_id')
        ? !!this.contextFormGroup.get('_par_' + this.parameterName).value.length
        : !!this.contextFormGroup.get('_par_' + this.parameterName).value;
  }

  search() {
    if (this.parameterName.includes('agent_id')) {
      this.navigationService.searchAgent(AgentSearchModalComponent, {}).subscribe(val => {
        if (val) {
          this.contextFormGroup.controls['_par_' + this.parameterName].setValue(val);
          this.contextFormGroup.markAsDirty();
        }
      });
    } else if (this.parameterName.includes('stable_id')) {
      this.navigationService.searchStable(StableSearchModalComponent, {}).subscribe(val => {
        if (val) {
          (this.contextFormGroup.get('_par_' + this.parameterName) as FormArray).push(new FormControl(val));
          this.contextFormGroup.markAsDirty();
        }
      });
    }
  }

  clearValue(index?: number) {
    if (this.parameterName.includes('agent_id')) {
      this.contextFormGroup.controls['_par_' + this.parameterName].setValue(null);
    } else if (this.parameterName.includes('stable_id')) {
      const stableIds = this.contextFormGroup.get('_par_' + this.parameterName) as FormArray;
      stableIds.removeAt(index);
    }
    this.contextFormGroup.markAsDirty();
  }

  getLookupName() {
    if (this.parameterName.includes('agent_id')) {
      return 'agent';
    } else if (this.parameterName.includes('stable_id')) {
      return 'stable';
    }
  }
}
