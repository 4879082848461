<div class="itech-search-table-with-filter" *ngIf="securityService.isAccess('product') | async">
  <div style="display: flex; flex-direction: row;">
    <clr-icon shape="search" [attr.size]="expandedParams ? 72 : 24"></clr-icon>
    <div class="compact form itech-highlighted-sections" [formGroup]="model.filterFormGroup"
         *ngIf="model && model.filterFormGroup">
      <section class="form-block itech-block-normal">
        <h3 style="display: flex">
          Поиск продукции / документов
          <button type="button" class="btn btn-outline"
                  style="background: none;border: none;margin: 0 0 0 10px;text-transform: none;color: black;
                  letter-spacing:normal;min-width:15px;max-width:15px;max-height:24px;padding:0;display:flex;align-items:end"
                  (click)="expandedParams = !expandedParams">
            <clr-icon *ngIf="expandedParams" shape="angle up"></clr-icon>
            <clr-icon *ngIf="!expandedParams" shape="angle down"></clr-icon>
          </button>
        </h3>
        <ng-container *ngIf="expandedParams">
          <div class="form-group">
            <label style="display:flex;align-items:center"
                   [style.width.rem]="!toggleManufactureOrExpire ? 8 : undefined"
                   [style.max-width.rem]="!toggleManufactureOrExpire ? 8 : undefined">
              <button class="btn btn-icon-link" style="margin:0;padding:0;max-height:20px;min-width:20px;border:none"
                      (click)="changeToggleManufactureOrExpire()">
                <clr-icon shape="switch" style="display:block" class="has-badge"></clr-icon>
              </button>
              {{toggleManufactureOrExpire ? 'Дата произв. с/по' : 'Срок годн. с/по'}}
            </label>

            <label *ngIf="toggleManufactureOrExpire" for="manufactureDateFrom" aria-haspopup="true" role="tooltip"
                   class="tooltip tooltip-validation tooltip-md"
                   [class.invalid]="isInvalid(model.filterFormGroup, 'manufactureDateFrom')">
              <input type="date" id="manufactureDateFrom" class="itech-control-normal"
                     formControlName="manufactureDateFrom">
              <app-validation-tooltip
                [input]="model.filterFormGroup.controls['manufactureDateFrom']"></app-validation-tooltip>
            </label>
            <label *ngIf="toggleManufactureOrExpire" for="manufactureDateTo" aria-haspopup="true" role="tooltip"
                   class="tooltip tooltip-validation tooltip-md"
                   [class.invalid]="isInvalid(model.filterFormGroup, 'manufactureDateTo')">
              <input type="date" id="manufactureDateTo" class="itech-control-normal"
                     formControlName="manufactureDateTo">
              <app-validation-tooltip
                [input]="model.filterFormGroup.controls['manufactureDateTo']"></app-validation-tooltip>
            </label>

            <label *ngIf="!toggleManufactureOrExpire" for="manufactureDateFrom" aria-haspopup="true" role="tooltip"
                   class="tooltip tooltip-validation tooltip-md"
                   [class.invalid]="isInvalid(model.filterFormGroup, 'expireDateFrom')">
              <input type="date" id="expireDateFrom" class="itech-control-normal"
                     formControlName="expireDateFrom">
              <app-validation-tooltip
                [input]="model.filterFormGroup.controls['expireDateFrom']"></app-validation-tooltip>
            </label>
            <label *ngIf="!toggleManufactureOrExpire" for="expireDateTo" aria-haspopup="true" role="tooltip"
                   class="tooltip tooltip-validation tooltip-md"
                   [class.invalid]="isInvalid(model.filterFormGroup, 'expireDateTo')">
              <input type="date" id="expireDateTo" class="itech-control-normal"
                     formControlName="expireDateTo">
              <app-validation-tooltip
                [input]="model.filterFormGroup.controls['expireDateTo']"></app-validation-tooltip>
            </label>
            <app-checkbox-select class="itech-control-normal" [selectType]="true" [sorted]="true"
                                 [contextControlName]="'statuses'"
                                 [lookupName]="'product-status'" [contextFormGroup]="model.filterFormGroup"></app-checkbox-select>
          </div>
          <div class="form-group" [style.padding]="!toggleProductTypeOrCaption ? '0 0 0 8rem' : undefined">
            <label style="display:flex;align-items:center"
                   [style.width.rem]="!toggleProductTypeOrCaption ? 8 : undefined"
                   [style.max-width.rem]="!toggleProductTypeOrCaption ? 8 : undefined">
              <button class="btn btn-icon-link" style="margin:0;padding:0;max-height:20px;min-width:20px;border:none"
                      (click)="changeToggleProductTypeOrCaption()">
                <clr-icon shape="switch" style="display:block" class="has-badge"></clr-icon>
              </button>
              {{toggleProductTypeOrCaption ? 'Вид продукции' : 'Наименование продукции'}}
            </label>
            <app-text-lookup *ngIf="toggleProductTypeOrCaption" [validationTooltipRight]="true" class="itech-control-normal"
                             [contextControlId]="'productTypeId'" [sorted]="true"
                             [contextControlName]="'productTypeId'"
                             [lookupName]="'product-type'" [contextFormGroup]="model.filterFormGroup"></app-text-lookup>
            <app-text-lookup *ngIf="toggleProductTypeOrCaption && model.filterFormGroup.get('productTypeId').value"
                             style="margin-left: 5px"
                             [validationTooltipRight]="true" class="itech-control-normal"
                             [contextControlId]="'productSubtypeId'" [sorted]="true"
                             [contextControlName]="'productSubtypeId'"
                             [lookupName]="'product-subtype' + model.filterFormGroup.get('productTypeId').value"
                             [contextFormGroup]="model.filterFormGroup"></app-text-lookup>
            <label *ngIf="!toggleProductTypeOrCaption" for="stableProductSubtypeCaption" aria-haspopup="true" role="tooltip"
                   class="tooltip tooltip-validation tooltip-md"
                   [class.invalid]="isInvalid(model.filterFormGroup, 'stableProductSubtypeCaption')">
              <app-input-text [contextId]="'stableProductSubtypeCaption'" [classNames]="'itech-control-larger'"
                              [formControlObj]="model.filterFormGroup.get('stableProductSubtypeCaption')" [htmlType]="1"
                              [placeholder]="'Наименование номенклатуры'" (keyDownEmitter)="filterEnterPressed($event)">
              </app-input-text>
              <app-validation-tooltip [input]="model.filterFormGroup.controls['stableProductSubtypeCaption']"></app-validation-tooltip>
            </label>
          </div>
          <div class="form-group itech-group-whole-line">
            <label></label>
            <label *ngIf="!model.filterFormGroup.controls['agentId'].value &&
                        !model.filterFormGroup.controls['stableId'].value"
                   for="agentOrStableCaption" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                   [class.invalid]="isInvalid(model.filterFormGroup, 'agentOrStableCaption')">
              <app-input-text [contextId]="'agentOrStableCaption'" [classNames]="'itech-control-larger'"
                              [formControlObj]="model.filterFormGroup.get('agentOrStableCaption')" [htmlType]="1"
                              [placeholder]="'Хозяйствующий субъект / поднадзорный объект'" (keyDownEmitter)="filterEnterPressed($event)">
              </app-input-text>
              <app-validation-tooltip [input]="model.filterFormGroup.controls['agentOrStableCaption']"></app-validation-tooltip>
            </label>
            <ng-container *ngIf="model.filterFormGroup.controls['agentId'].value">
              <div class="itech-text-field itech-text-field-no-wrap" style="width:300px;max-width:300px"
                   title="{{model.filterFormGroup.controls['agentId'].value | lookup: 'agent' | async}}">
                {{model.filterFormGroup.controls['agentId'].value | lookup: 'agent' | async}}
              </div>
              &nbsp;&nbsp;
              <button type="button" class="btn btn-danger btn-link itech-inline-tool-btn" title="Указать другой ХС"
                      (click)="model.filterFormGroup.controls['agentId'].setValue(undefined)">
                <clr-icon shape="remove"></clr-icon>
              </button>
            </ng-container>
            <ng-container *ngIf="model.filterFormGroup.controls['stableId'].value">
              <div class="itech-text-field itech-text-field-no-wrap" style="width:300px;max-width:300px"
                   title="{{model.filterFormGroup.controls['stableId'].value | lookup: 'stable' | async}}">
                {{model.filterFormGroup.controls['stableId'].value | lookup: 'stable' | async}}
              </div>
              &nbsp;&nbsp;
              <button type="button" class="btn btn-danger btn-link itech-inline-tool-btn" title="Указать другой поднадзорный объект"
                      (click)="model.filterFormGroup.controls['stableId'].setValue(undefined)">
                <clr-icon shape="remove"></clr-icon>
              </button>
            </ng-container>
            &nbsp;&nbsp;
            <div class="checkbox-inline">
              <input type="checkbox" id="showPositiveCurrentValue" formControlName="showPositiveCurrentValue">
              <label for="showPositiveCurrentValue">Наличие остатка</label>
            </div>
          </div>
          <div class="form-group">
            <label>№ записи/UUID ВСД</label>
            <label for="mercuryDocNo" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                   [class.invalid]="isInvalid(model.filterFormGroup, 'mercuryDocNo')">
              <input type="text" id="mercuryDocNo" placeholder="№ записи журнала"
                     class="itech-control-normal" formControlName="mercuryDocNo" (keydown)="filterEnterPressed($event)">
              <app-validation-tooltip [input]="model.filterFormGroup.controls['mercuryDocNo']"></app-validation-tooltip>
            </label>
            <label for="mercuryDocUuid" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                   [class.invalid]="isInvalid(model.filterFormGroup, 'mercuryDocUuid')">
              <input type="text" id="mercuryDocUuid" placeholder="UUID ВСД (xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx)"
                     class="itech-control-m-large" formControlName="mercuryDocUuid" (keydown)="filterEnterPressed($event)">
              <app-validation-tooltip [input]="model.filterFormGroup.controls['mercuryDocUuid']"></app-validation-tooltip>
            </label>
          </div>

          <div class="form-group itech-group-whole-line">
            <button class="btn btn-primary" (click)="refreshResults()">
              <clr-icon shape="search" class="has-badge"></clr-icon>&nbsp;Поиск
            </button>
          </div>
        </ng-container>
      </section>
    </div>
  </div>

  <div [style.top.px]="expandedParams ? 220 : 30">
    <app-grid [pagination]="false" [getRowStyle] = "getRowStyle" [uniqueGridName]="'ProductSearchResultsFormGrid'"
              [rowData]="model.searchResults"  [rowMultiSelectWithClick]="true" [rowSelection]="'multiple'"
              (rowDoubleClicked)="rowClicked.emit($event.data)" (selectionChanged)="onGridSelectionChanged($event)"
              [columnDefs]="gridColumnDefs" [exportFileName]="'Список продукции'" [icons]="gridIcons"
              [masterDetail]="true" [detailRowHeight]="'450'"
              [detailCellRenderer]="detailCellRenderer" [frameworkComponents]="frameworkComponents"></app-grid>
  </div>
</div>
