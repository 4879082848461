import { Component, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { LookupSourceService } from '../../logic/services/lookup-source.service';
import { FormHelper } from './form-helper';
import { Subject } from 'rxjs';
import { map } from 'rxjs/internal/operators';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-combo-lookup',
  templateUrl: './app-combo-lookup.component.html'
})
export class AppComboLookupComponent implements OnChanges {
  @Input() contextControlName;
  @Input() contextFormGroup;
  @Input() validationTooltipRight = false;
  @Input() validationTooltipTopLeft = false;
  @Input() validationTooltipBottomLeft = false;
  @Input() validationTooltipBottomRight = false;
  @Input() contextControlId;
  @Input() disabled = false;
  @Input() disabledChoices = [];
  @Output() change = new Subject<any>();
  @Input() sorted = false;
  @Input() required = true;

  @Input() lookupItems: any[] = [];
  @Input() captionFieldName = 'caption';

  // 2 вида фильтрации:
  // - передаем значение (filterValueInArrayValue) и оно должно содержаться в массиве filterValueInArrayName объекта из lookup
  @Input() filterValueInArrayControlName1 = 'filterList1';
  @Input() filterValueInArrayValue1;
  @Input() filterValueInArrayControlName2 = 'filterList2';
  @Input() filterValueInArrayValue2;
  // - передаем массив в filterArrayIncludeValue и значение filterArrayIncludeIdName объекта из lookup должен содержаться в этом массиве
  @Input() filterArrayIncludeControlName = 'id';
  @Input() filterArrayIncludeValue: any[];

  focused = false;
  hovered = false;

  bonusDeletedItem: any;

  private _lookupName: string;
  @Input()
  get lookupName(): string {
    return this._lookupName;
  }

  get boundControl(): FormControl {

    if (!this.contextFormGroup) {
      return undefined;
    }

    return this.contextFormGroup instanceof FormArray
      ? this.contextFormGroup.controls[this.contextControlName]
      : this.contextFormGroup.get(this.contextControlName);
  }

  set lookupName(newLookupName) {
    if (this._lookupName !== newLookupName && newLookupName) {
      this._lookupName = newLookupName;
      this.initLookupItems();
    }
  }

  get controlIsDisabled(): boolean {
    return this.boundControl && this.boundControl.disabled;
  }

  constructor(private lookupSourceService: LookupSourceService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.hasOwnProperty('lookupName') || changes.hasOwnProperty('contextFormGroup')
        || changes.hasOwnProperty('contextControlName')) && this.boundControl) {
      // вот это все нам нужно, чтобы отследить, что присваивается контролу, и добавить в список удаленный элемент,
      // которого еще в списке нету (чтобы корректно отображались ранее выбранные удаленные элементы, и при этом список
      // не содержал всех удаленных элементов)
      this.boundControl.valueChanges.subscribe(() => {
        this.tryUpdateBonusDeletedItem();
      });
      this.tryUpdateBonusDeletedItem();
    }
  }

  private tryUpdateBonusDeletedItem() {
    if (!this.lookupName) {
      return;
    }

    this.lookupSourceService.getLookupObj(this.lookupName).subscribe((lookup: any[]) => {

      const newVal = this.boundControl.value;
      if (newVal && !this.lookupItems.find(el => el.id == newVal)) {
        this.bonusDeletedItem = lookup['Obj' + newVal];
      } else {
        this.bonusDeletedItem = undefined;
      }
    });
  }

  initLookupItems() {
    this.getLookup().subscribe((items) => {

      this.lookupItems = LookupSourceService.filterLookup(items, this.filterValueInArrayControlName1, this.filterValueInArrayValue1,
        this.filterValueInArrayControlName2, this.filterValueInArrayValue2,
        this.filterArrayIncludeControlName, this.filterArrayIncludeValue);

      this.tryUpdateBonusDeletedItem();
    });
  }

  isDisabledChoice(val: any) {
    if (!this.disabledChoices) {
      return false;
    }

    return (this.disabledChoices.find(item => item == val) >= 0);
  }

  focus() {
    this.focused = true;
  }

  blur() {
    this.focused = false;
  }

  getLookup() {
    return this.lookupSourceService.getLookup(this.lookupName).pipe(map(lookup => {
      if (!this.sorted) {
        lookup.sort((item1, item2) => item1.id - item2.id);
      } else {
        lookup.sort((item1, item2) => item1.caption ? item1.caption.localeCompare(item2.caption) : 0);
      }

      return lookup;
    }));
  }

  isInvalid(cname: string) {
    return FormHelper.isInvalid(this.contextFormGroup, cname);
  }

  clearValue() {
    this.contextFormGroup.controls[this.contextControlName].setValue(undefined);
    this.contextFormGroup.controls[this.contextControlName].markAsDirty();
    this.change.next({});
  }

  hover() {
    this.hovered = true;
  }

  hoverEnd() {
    this.hovered = false;
  }

  onChange(event: Event) {
    const s = event.target as HTMLSelectElement;
    if (s.value) {
      this.change.next(this.lookupItems.find(x => x.id.toString() === s.value));
    } else {
      this.change.next({});
    }
    event.stopPropagation();
  }
}
