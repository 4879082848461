/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./common/edit-agent-common-form.component.ngfactory";
import * as i2 from "./common/edit-agent-common-form.component";
import * as i3 from "@angular/forms";
import * as i4 from "@angular/common";
import * as i5 from "../../../../node_modules/@clr/angular/clr-angular.ngfactory";
import * as i6 from "@clr/angular";
import * as i7 from "./create-agent-modal.component";
import * as i8 from "../../logic/services/app-navigation.service";
import * as i9 from "../../ui/infrastructure/alert.service";
import * as i10 from "../../logic/services/agent-data.service";
var styles_CreateAgentModalComponent = [];
var RenderType_CreateAgentModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CreateAgentModalComponent, data: {} });
export { RenderType_CreateAgentModalComponent as RenderType_CreateAgentModalComponent };
function View_CreateAgentModalComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-edit-agent-common-form", [], null, null, null, i1.View_EditAgentCommonFormComponent_0, i1.RenderType_EditAgentCommonFormComponent)), i0.ɵdid(1, 573440, null, 0, i2.EditAgentCommonFormComponent, [i3.FormBuilder], { contextFormGroup: [0, "contextFormGroup"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.form; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CreateAgentModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_CreateAgentModalComponent_1)), i0.ɵdid(1, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 7, "p", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 6, "button", [["class", "btn btn-primary"]], [[1, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createCard() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ClrLoadingButton_0, i5.RenderType_ClrLoadingButton)), i0.ɵdid(4, 49152, null, 0, i6.ClrLoadingButton, [i0.ElementRef, i0.Renderer2], { disabled: [0, "disabled"] }, null), i0.ɵprd(2048, null, i6.LoadingListener, null, [i6.ClrLoadingButton]), i0.ɵdid(6, 147456, null, 0, i6.ClrLoading, [[2, i6.LoadingListener]], { loadingState: [0, "loadingState"] }, null), (_l()(), i0.ɵeld(7, 0, null, 0, 1, "clr-icon", [["shape", "check"]], null, null, null, null, null)), i0.ɵdid(8, 16384, null, 0, i6.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, 0, ["\u00A0\u0421\u043E\u0437\u0434\u0430\u0442\u044C "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model; _ck(_v, 1, 0, currVal_0); var currVal_2 = _co.creating; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.creating; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var currVal_1 = (i0.ɵnov(_v, 4).disabled ? "" : null); _ck(_v, 3, 0, currVal_1); }); }
export function View_CreateAgentModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_CreateAgentModalComponent_0, RenderType_CreateAgentModalComponent)), i0.ɵdid(1, 114688, null, 0, i7.CreateAgentModalComponent, [i8.AppNavigationService, i3.FormBuilder, i9.AlertService, i10.AgentDataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CreateAgentModalComponentNgFactory = i0.ɵccf("ng-component", i7.CreateAgentModalComponent, View_CreateAgentModalComponent_Host_0, {}, {}, []);
export { CreateAgentModalComponentNgFactory as CreateAgentModalComponentNgFactory };
