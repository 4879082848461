/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@clr/angular";
import * as i2 from "@angular/common";
import * as i3 from "./app-modal-preview-file.component";
import * as i4 from "../../logic/services/metadata.service";
import * as i5 from "../../logic/services/data-caching.service";
import * as i6 from "../../logic/services/app-navigation.service";
var styles_AppModalPreviewFileComponent = [];
var RenderType_AppModalPreviewFileComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppModalPreviewFileComponent, data: {} });
export { RenderType_AppModalPreviewFileComponent as RenderType_AppModalPreviewFileComponent };
function View_AppModalPreviewFileComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["style", "min-height:600px;text-align:center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "span", [["class", "spinner"], ["style", "top:288px"]], null, null, null, null, null))], null, null); }
function View_AppModalPreviewFileComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "button", [["class", "btn btn-link btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.buildSourceFormat() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "clr-icon", [["shape", "download"]], null, null, null, null, null)), i0.ɵdid(2, 16384, null, 0, i1.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, [" \u0421\u043A\u0430\u0447\u0430\u0442\u044C \u0432 \u0438\u0441\u0445\u043E\u0434\u043D\u043E\u043C \u0444\u043E\u0440\u043C\u0430\u0442\u0435"]))], null, null); }
function View_AppModalPreviewFileComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppModalPreviewFileComponent_3)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, [[1, 0], ["previewFile", 1]], null, 0, "iframe", [["src", ""], ["style", "width:100%;height:82vh"], ["type", "application/pdf"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isFuncSourceFormat(); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AppModalPreviewFileComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { previewFileRef: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["style", "min-height:90vh"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppModalPreviewFileComponent_1)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppModalPreviewFileComponent_2)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.loading; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_AppModalPreviewFileComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_AppModalPreviewFileComponent_0, RenderType_AppModalPreviewFileComponent)), i0.ɵdid(1, 114688, null, 0, i3.AppModalPreviewFileComponent, [i4.MetadataService, i5.DataCachingService, i6.AppNavigationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppModalPreviewFileComponentNgFactory = i0.ɵccf("ng-component", i3.AppModalPreviewFileComponent, View_AppModalPreviewFileComponent_Host_0, {}, {}, []);
export { AppModalPreviewFileComponentNgFactory as AppModalPreviewFileComponentNgFactory };
