import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { FormBuilder } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { ServerSideErrorsProvider } from '../../../logic/validators/server-side-errors-provider';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { AppFormGroup } from '../../../ui/controls/app-form-group';
import { AnimalDataService } from '../../../logic/services/animal-data.service';
import { EditAnimalEventCommonFormComponent } from './edit-animal-event-common-form.component';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { Observable, of } from 'rxjs/index';

@Component({
  selector: 'app-edit-animal-event-common',
  templateUrl: './edit-animal-event-common.component.html'
})
export class EditAnimalEventCommonComponent implements ServerSideErrorsProvider, OnChanges {
  @Input() id: number;

  model: any = {};
  storing = false;
  serverSideValidationErrors: any[];

  isInvalid = FormHelper.isInvalid;

  get contextFormGroup(): AppFormGroup {
    return this.model ? this.model.contextFormGroup : undefined;
  }

  get reportKind(): string {
    return this.contextFormGroup
      ? +this.contextFormGroup.get('eventTypeId').value === 13
        ? 'event-immunization'
        : +this.contextFormGroup.get('eventTypeId').value === 16
          ? 'event-disinfection'
          : +this.contextFormGroup.get('eventTypeId').value === 4
            ? 'event-allergic-tuberculin'
            : 'event'
      : null;
  }

  constructor(private bookmarkService: BookmarkService,
              private animalDataService: AnimalDataService,
              private alertService: AlertService,
              private cache: AddressPersonFioCacheService,
              private lookupSourceService: LookupSourceService,
              private dataCachingService: DataCachingService,
              private waitingOverlay: GlobalWaitingOverlayService,
              private fb: FormBuilder,
              public navigationService: AppNavigationService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.id) {
      this.refreshFormDataInternal();
    }
  }

  store() {

    FormHelper.markAsSubmitted(this.contextFormGroup);

    if (!this.contextFormGroup.valid) {
      return;
    }

    const dataToStore = this.contextFormGroup.value;

    if (dataToStore.drugs) {
      for (let i = 0; i < dataToStore.drugs.length; i++) {
        if (dataToStore.drugs[i].drugId) {
          dataToStore.drugs[i].drugTypeId = null;
        }
      }
    }

    this.storing = true;

    this.animalDataService.storeAnimalEvent(dataToStore).subscribe({
      next: data => {
        this.model.contextFormGroup = EditAnimalEventCommonFormComponent.createFormGroup(this.fb, this.model, data,
          () => this.model.animal);
        this.storing = false;
        this.cache.invalidateAnimal(this.id);
        this.invalidateCaches();
        this.bookmarkService.refreshAnimalEventCardBookmark(this.id);
        this.contextFormGroup.markAsUntouched();
        this.contextFormGroup.markAsPristine();
        FormHelper.setSingleFormGroupServerSideValidationErrorsWithRelationMsgKey({}, this.model, this.contextFormGroup);
      },
      error: error => {
        this.storing = false;
        FormHelper.setSingleFormGroupServerSideValidationErrorsWithRelationMsgKey(error, this.model, this.contextFormGroup);
        window.document.getElementById('animal-event-common-content').scrollTop = 0;
      }
    });
  }

  cancelEdit() {
    this.dataCachingService.removeCachedData('EditAnimalEventCommon', this.id.toString());
    this.refreshFormDataInternal();
  }

  refreshFormData() {
    this.dataCachingService.removeCachedData('EditAnimalEventCommon', this.id.toString());
    this.refreshFormDataInternal();
  }

  refreshFormDataInternal() {
    this.waitingOverlay.StartWaiting();

    this.model = {id: this.id, serverSideValidationErrors: []};

    const existing = this.dataCachingService.getCachedData('EditAnimalEventCommon', this.id.toString());

    if (existing) {
      this.model = existing;
      this.waitingOverlay.EndWaiting();
    } else {
      this.animalDataService.getAnimalEventForEdit(this.id).subscribe({
        next: data => {

          const animal$: Observable<any> = data.animalId
            ? this.cache.getAnimal(data.animalId)
            : of(undefined);

          animal$.subscribe(animal => {
            this.model.id = data.id;
            this.model.animal = animal;
            this.model.contextFormGroup = EditAnimalEventCommonFormComponent.createFormGroup(this.fb, this.model, data,
              () => this.model.animal);

            this.lookupSourceService.getLookupObj('event-type').subscribe(lookup => {
              this.model.contextFormGroup.get('eventTypeId').valueChanges.subscribe(() => {
                this.model.eventType = lookup['Obj' + this.model.contextFormGroup.get('eventTypeId').value];
              });
              this.model.eventType = lookup['Obj' + this.model.contextFormGroup.get('eventTypeId').value];
            });

            this.cache.invalidateAnimalEvent(this.id);
            this.dataCachingService.addToCache('EditAnimalEventCommon', this.id.toString(), this.model);

            this.bookmarkService.refreshAnimalEventCardBookmark(this.id);

            this.waitingOverlay.EndWaiting();
          });
        },
        error: () => {
          this.waitingOverlay.EndWaiting();
        }
      });
    }
  }

  deleteAnimalEvent() {
    this.alertService.confirmModal('Вы уверены, что хотите удалить мероприятие (событие)?')
      .subscribe(() => {
        this.animalDataService.deleteAnimalEvent(this.id)
          .subscribe({
            next: () => {
              this.invalidateCaches();
              this.bookmarkService.removeOpenCardBookmark(['/animal-event-edit'], this.id.toString());
              this.alertService.success('Мероприятие (событие) успешно удалено!');
            },
            error: err => {
              FormHelper.setSingleFormGroupServerSideValidationErrorsWithRelationMsgKey(err, this.model, this.contextFormGroup);
              window.document.getElementById('animal-event-common-content').scrollTop = 0;
            }});
      });
  }

  private invalidateCaches() {
    if (this.model.animal) {
      this.dataCachingService.removeCachedData('EditAnimalEvents', this.model.animal.id);
    }
    if (this.model.stableId) {
      this.dataCachingService.removeCachedData('EditStableEvents', this.model.stableId);
    }
  }
}
