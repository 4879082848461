import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { FormBuilder, Validators } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { DrugDataService } from '../../../logic/services/drug-data.service';
import { StringHelper } from '../../../helpers/string-helper';
import { SecurityService } from '../../../logic/services/security.service';

@Component({
  selector: 'app-drug-search-results-form',
  templateUrl: './drug-search-results-form.component.html'
})
export class DrugSearchResultsFormComponent {

  @Output() rowClicked = new EventEmitter();
  @Input() model = DrugSearchResultsFormComponent.getDefaultModel();
  @Input() autoChangeExpanded = false;

  isInvalid = FormHelper.isInvalid;

  expandedParams = true;

  gridColumnDefs = [
    {
      headerName: '№', field: 'id', width: 90,
    },
    {
      headerName: 'Наименование', field: 'drugTypeCaption', width: 200,
    },
    {
      headerName: 'Вид', field: 'drugKindId', width: 200,
      valueFormatter: params => params.value ? this.drugKindIdLookup[params.value] : '<не указан>',
    },
    {
      headerName: 'Серия', field: 'serial', width: 120,
    },
    {
      headerName: 'Количество', field: 'quantity', width: 200,
    },
    {
      headerName: 'Срок годности', field: 'expireDate', width: 180,
      valueFormatter: params => StringHelper.getRuDate(params.value),
    },
    {
      headerName: 'Местонахождение', field: 'placement', width: 350,
    },
  ];

  drugKindIdLookup: any = {};

  public static getDefaultModel(fb?: FormBuilder, defaultFilterParams?: any) {
    return {
      searchResults: [],
      filterFormGroup: fb ? DrugSearchResultsFormComponent.buildSearchForm(fb, defaultFilterParams) : undefined
    };
  }

  public static buildSearchForm(fb: FormBuilder, defaultFilterParams: any = {}) {

    const fg = fb.group({
      toggleCaptionOrDisease: defaultFilterParams.toggleCaptionOrDisease,
      toggleActOrIncoming: defaultFilterParams.toggleActOrIncoming,
      includeExhausted: defaultFilterParams.includeExhausted,
      drugTypeId: defaultFilterParams.drugTypeId,
      drugKindId: defaultFilterParams.drugKindId,
      serial: defaultFilterParams.serial,
      manufacturingFrom: [defaultFilterParams.manufacturingFrom, FormHelper.validateDateTimePicker()],
      manufacturingTo: [defaultFilterParams.manufacturingTo, FormHelper.validateDateTimePicker()],
      institutionId: defaultFilterParams.institutionId,
      institutionBranchId: defaultFilterParams.institutionBranchId,
      manufacturerCaption: defaultFilterParams.manufacturerCaption,
      registryNo: defaultFilterParams.registryNo,
      expirationDateFrom: [defaultFilterParams.expirationDateFrom, FormHelper.validateDateTimePicker()],
      expirationDateTo: [defaultFilterParams.expirationDateTo, FormHelper.validateDateTimePicker()],
      diseaseTypeId: defaultFilterParams.diseaseTypeId,
      costMin: [defaultFilterParams.costMin, Validators.pattern(/^\d*$/)],
      costMax: [defaultFilterParams.costMax, Validators.pattern(/^\d*$/)],
      eventTypeId: defaultFilterParams.eventTypeId,
      incomingDateFrom: [defaultFilterParams.incomingDateFrom, FormHelper.validateDateTimePicker()],
      incomingDateTo: [defaultFilterParams.incomingDateTo, FormHelper.validateDateTimePicker()],
      spentDateFrom: [defaultFilterParams.spentDateFrom, FormHelper.validateDateTimePicker()],
      spentDateTo: [defaultFilterParams.spentDateTo, FormHelper.validateDateTimePicker()],
      actNumber: [defaultFilterParams.actNumber],
      actDateFrom: [defaultFilterParams.actDateFrom, FormHelper.validateDateTimePicker()],
      actDateTo: [defaultFilterParams.actDateTo, FormHelper.validateDateTimePicker()],
    });

    fg.get('institutionId').valueChanges.subscribe(() => fg.get('institutionBranchId').setValue(undefined));
    fg.get('toggleCaptionOrDisease').valueChanges.subscribe(() => {
      fg.get('diseaseTypeId').setValue(null);
      fg.get('eventTypeId').setValue(null);
      fg.get('drugTypeId').setValue(null);
      fg.get('drugKindId').setValue(null);
    });
    fg.get('toggleActOrIncoming').valueChanges.subscribe(() => {
      fg.get('incomingDateFrom').setValue(null);
      fg.get('incomingDateTo').setValue(null);
      fg.get('spentDateFrom').setValue(null);
      fg.get('spentDateTo').setValue(null);
      fg.get('actNumber').setValue(null);
      fg.get('actDateFrom').setValue(null);
      fg.get('actDateTo').setValue(null);
    });

    return fg;
  }

  constructor(private drugDataService: DrugDataService,
              private globalWaitingOverlayService: GlobalWaitingOverlayService,
              public appNavigationService: AppNavigationService,
              private lookupSourceService: LookupSourceService,
              public securityService: SecurityService) {

    this.lookupSourceService.getLookupObj('drug-kind').subscribe(lookup => this.drugKindIdLookup = lookup);
  }

  refreshResults() {

    if (!this.model.filterFormGroup.valid) {
      return;
    }

    this.globalWaitingOverlayService.StartWaiting();

    this.drugDataService.search(this.model.filterFormGroup.value)
      .subscribe(data => {
        this.model.searchResults = data;
        if (this.autoChangeExpanded) {
          this.expandedParams = false;
        }
        this.globalWaitingOverlayService.EndWaiting();
      }, error => {
        this.globalWaitingOverlayService.EndWaiting();
      });
  }

  filterEnterPressed($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      this.refreshResults();
    }
  }

  getRowStyle(params) {
    if (params.data && params.data.quantity === 'Закончился') {
      return {color: 'silver'};
    } else if (params.data && params.data.expireDate < StringHelper.getISODate(new Date())) {
      return {color: 'red'};
    }
  }
}
