import { Injectable } from '@angular/core';
import { StringHelper } from '../../helpers/string-helper';
import { combineLatest } from 'rxjs/index';
import { MetadataService } from './metadata.service';

@Injectable({
  providedIn: 'root',
})
export class HolidaysService {

  holidaysMap: any = {};
  workdaysMap: any = {};

  constructor(metadataService: MetadataService) {
    // сервис предзагружает данные только за 3 года - это чисто для визуализации, поэтому нормально
    const year = new Date().getFullYear();
    combineLatest([metadataService.getExtraHolidays(year - 1), metadataService.getExtraWorkdays(year - 1)])
      .subscribe({
        next: ([holidaysMap, workdaysMap]) => {
          Object.assign(this.holidaysMap, holidaysMap);
          Object.assign(this.workdaysMap, workdaysMap);
        }
      });
    combineLatest([metadataService.getExtraHolidays(year), metadataService.getExtraWorkdays(year)])
      .subscribe({
        next: ([holidaysMap, workdaysMap]) => {
          Object.assign(this.holidaysMap, holidaysMap);
          Object.assign(this.workdaysMap, workdaysMap);
        }
      });
    combineLatest([metadataService.getExtraHolidays(year + 1), metadataService.getExtraWorkdays(year + 1)])
      .subscribe({
        next: ([holidaysMap, workdaysMap]) => {
          Object.assign(this.holidaysMap, holidaysMap);
          Object.assign(this.workdaysMap, workdaysMap);
        }
      });
  }

  public isHoliday(date: Date): boolean {
    const dayInWeekNo = date.getDay();
    const weekend = dayInWeekNo === 0 || dayInWeekNo === 6;
    const holiday = (weekend && (!this.workdaysMap || !this.workdaysMap[StringHelper.getISODate(date)])) ||
      (this.holidaysMap && this.holidaysMap[StringHelper.getISODate(date)]);
    return holiday;
  }
}
