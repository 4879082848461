/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@clr/angular";
import * as i2 from "./animal-search-results-form.component.ngfactory";
import * as i3 from "./animal-search-results-form.component";
import * as i4 from "../../../logic/services/animal-data.service";
import * as i5 from "../../../ui/infrastructure/global-waiting-overlay.service";
import * as i6 from "../../../logic/services/app-navigation.service";
import * as i7 from "../../../logic/services/lookup-source.service";
import * as i8 from "../../../logic/services/security.service";
import * as i9 from "@angular/common";
import * as i10 from "./animal-search-modal.component";
import * as i11 from "@angular/forms";
var styles_AnimalSearchModalComponent = [];
var RenderType_AnimalSearchModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AnimalSearchModalComponent, data: {} });
export { RenderType_AnimalSearchModalComponent as RenderType_AnimalSearchModalComponent };
function View_AnimalSearchModalComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "modal-footer itech-form-actions"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 3, "button", [["class", "btn btn-success"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.chooseMultipleAnimals() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "clr-icon", [["shape", "check"]], null, null, null, null, null)), i0.ɵdid(5, 16384, null, 0, i1.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(6, null, ["\u00A0\u0412\u044B\u0431\u0440\u0430\u0442\u044C ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.selectedAnimalIds || !_co.selectedAnimalIds.length); _ck(_v, 3, 0, currVal_0); var currVal_1 = ((_co.selectedAnimalIds && _co.selectedAnimalIds.length) ? ((" (" + _co.selectedAnimalIds.length.toString()) + " \u0448\u0442.)") : ""); _ck(_v, 6, 0, currVal_1); }); }
export function View_AnimalSearchModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["style", "height: 60vh; background-color: white; position: relative;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-animal-search-results-form", [], null, [[null, "selectionChanged"], [null, "rowClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectionChanged" === en)) {
        var pd_0 = ((_co.selectedAnimalIds = $event) !== false);
        ad = (pd_0 && ad);
    } if (("rowClicked" === en)) {
        var pd_1 = (_co.chooseAnimal($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_AnimalSearchResultsFormComponent_0, i2.RenderType_AnimalSearchResultsFormComponent)), i0.ɵdid(2, 573440, null, 0, i3.AnimalSearchResultsFormComponent, [i4.AnimalDataService, i5.GlobalWaitingOverlayService, i6.AppNavigationService, i7.LookupSourceService, i8.SecurityService], { model: [0, "model"], addManyAnimals: [1, "addManyAnimals"] }, { rowClicked: "rowClicked", selectionChanged: "selectionChanged" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AnimalSearchModalComponent_1)), i0.ɵdid(4, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model; var currVal_1 = _co.addManyAnimals; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.addManyAnimals; _ck(_v, 4, 0, currVal_2); }, null); }
export function View_AnimalSearchModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_AnimalSearchModalComponent_0, RenderType_AnimalSearchModalComponent)), i0.ɵdid(1, 114688, null, 0, i10.AnimalSearchModalComponent, [i6.AppNavigationService, i11.FormBuilder], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AnimalSearchModalComponentNgFactory = i0.ɵccf("ng-component", i10.AnimalSearchModalComponent, View_AnimalSearchModalComponent_Host_0, {}, {}, []);
export { AnimalSearchModalComponentNgFactory as AnimalSearchModalComponentNgFactory };
