import { FormGroup } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
var QueryAddrCityFilterComponent = /** @class */ (function () {
    function QueryAddrCityFilterComponent() {
        this.cityParameterName = 'city_id';
        this.regionParameterName = 'region_id';
        this.streetParameterName = 'street_id';
        this.class = 'form-group';
    }
    QueryAddrCityFilterComponent.prototype.isInvalid = function (cname) {
        return FormHelper.isInvalid(this.contextFormGroup, cname);
    };
    Object.defineProperty(QueryAddrCityFilterComponent.prototype, "customFilterChecked", {
        get: function () {
            return this.contextFormGroup && (+this.contextFormGroup.get('_par_' + this.parameterName + '_filter').value) === 101;
        },
        set: function (val) {
            if (this.contextFormGroup) {
                this.contextFormGroup.get('_par_' + this.parameterName + '_filter').setValue(val ? 101 : 0);
                this.contextFormGroup.get('_par_' + this.cityParameterName + '_filter').setValue(val ? 101 : 0);
                this.contextFormGroup.get('_par_' + this.regionParameterName + '_filter').setValue(val ? 101 : 0);
                this.contextFormGroup.markAsDirty();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QueryAddrCityFilterComponent.prototype, "noValueChecked", {
        get: function () {
            return this.contextFormGroup && (+this.contextFormGroup.get('_par_' + this.parameterName + '_filter').value) === 1;
        },
        set: function (val) {
            if (this.contextFormGroup) {
                this.contextFormGroup.get('_par_' + this.parameterName + '_filter').setValue(val ? 1 : 0);
                this.contextFormGroup.get('_par_' + this.cityParameterName + '_filter').setValue(val ? 1 : 0);
                this.contextFormGroup.get('_par_' + this.regionParameterName + '_filter').setValue(val ? 1 : 0);
                this.contextFormGroup.markAsDirty();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QueryAddrCityFilterComponent.prototype, "filtered", {
        get: function () {
            return !!this.contextFormGroup.get('_par_' + this.parameterName + '_filter').value
                || !!this.contextFormGroup.get('_par_' + this.parameterName).value;
        },
        enumerable: true,
        configurable: true
    });
    QueryAddrCityFilterComponent.prototype.regionIdCityIdChanged = function (event) {
        var _this = this;
        setTimeout(function () {
            var matches = (_this.contextFormGroup.get('_par_' + _this.parameterName).value || '').match(/(\d+)-(\d+)/);
            _this.contextFormGroup.get('_par_' + _this.regionParameterName).setValue(matches ? matches[1] : undefined);
            _this.contextFormGroup.get('_par_' + _this.cityParameterName).setValue(matches ? matches[2] : undefined);
            _this.contextFormGroup.get('_par_' + _this.streetParameterName).setValue(undefined);
        }, 100);
    };
    return QueryAddrCityFilterComponent;
}());
export { QueryAddrCityFilterComponent };
