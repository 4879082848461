<div class="itech-common-wrap no-pager">
  <div class="itech-v-header-content">
    <div class="itech-content-header">
      <clr-icon shape="file-group" size="36"></clr-icon>&nbsp;{{getMetaTitle()}}
    </div>

    <div style="padding: 5px; height: 90%">
      <app-grid [uniqueGridName]="getMetaName()" [exportFileName]="getMetaTitle()" [rowData]="_searchResults"
                (rowClicked)="editRow($event.data)" [columnDefs]="gridColumnDefs"></app-grid>
    </div>
  </div>
  <div class="itech-form-actions">
    <div>
      <button class="btn btn-link btn-primary" (click)="refreshResults()">
        <clr-icon shape="refresh" class="has-badge"></clr-icon>&nbsp;Обновить список
      </button>
      <button class="btn btn-link btn-primary" (click)="editRow({isCustom: true})">
        <clr-icon shape="plus" has-badge="true"></clr-icon>&nbsp;Добавить новую запись
      </button>
    </div>
  </div>
</div>


<clr-modal [(clrModalOpen)]="editModalOpened" *ngIf="editModalOpened" [clrModalClosable]="false" [clrModalSize]="'lg'">
  <h3 class="modal-title">Редактирование записи
    <span *ngIf="currentIsNew" style="color:green"> [Новая]</span></h3>
  <div class="modal-body" style="min-height:120px;overflow-y: scroll">
    <app-drug-type-browse-form [currentFormGroup]="currentFormGroup"></app-drug-type-browse-form>
  </div>
  <div class="modal-footer itech-form-actions">
    <div></div>
    <div>
      <button type="button" class="btn btn-primary" (click)="addEditCompleted()"
              [disabled]="!currentFormGroup.dirty">
        <clr-icon shape="check"></clr-icon>&nbsp;Сохранить
      </button>
      <button type="button" class="btn btn-warning" (click)="editModalOpened = false">
        <clr-icon shape="undo"></clr-icon>&nbsp;Отмена
      </button>
    </div>
  </div>
</clr-modal>
