import {ChangeDetectorRef, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {PstReportService} from '../../services/pst-report.service';
import {LookupSourceService} from '../../../logic/services/lookup-source.service';
import {AgentSearchModalComponent} from '../../../pages/edit-agent/search/agent-search-modal.component';
import {AppNavigationService} from '../../../logic/services/app-navigation.service';
import {AddressPersonFioCacheService} from '../../../logic/services/address-person-fio-cache.service';
import {of} from 'rxjs';
import {PstReportStructureService} from '../../services/pst-report-structure.service';
import {NumericHelper} from '../../../helpers/numeric-helper';
import {SecurityService} from '../../../logic/services/security.service';
import {AlertService} from '../../../ui/infrastructure/alert.service';

@Component({
  selector: 'app-branch-buy-deal-edit',
  templateUrl: './pst-branch-buy-deal-edit.component.html',
})
export class PstBranchBuyDealEditComponent implements OnChanges {
  @Input() branch: any;
  @Input() model: any;

  @ViewChild('tableCtnr')
  tableCtnr: ElementRef;
  scrollLeft: any;

  buyDealMeta = [
    {
      type: 'date',
      fieldName: 'dateDeal',
      title: 'Дата покупки',
      displayWidth: 80
    },
    {
      type: 'lookup', fieldName: 'indicatorSubtypeId', lookupName: 'pst-indicator-subtype/plem',
      title: 'Вид животных, порода', displayWidth: 140
    },
    {type: 'text', fieldName: 'description', title: 'Поло - возрастная группа', displayWidth: 140},
    {
      type: 'text',
      fieldName: 'counterpartyCaption',
      title: 'Продавец (полное наименование сельхозорганизации, КФХ, ИП, ЛПХ)',
      displayWidth: 140
    },
  ];

  constructor(
    private lookupSourceService: LookupSourceService,
    private navigationService: AppNavigationService,
    private pstReportService: PstReportService,
    private cacheService: AddressPersonFioCacheService,
    private pstReportStructureService: PstReportStructureService,
    private changeDetectorRef: ChangeDetectorRef,
    private securityService: SecurityService,
    private alertService: AlertService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('branch') || changes.hasOwnProperty('model')) {
      this.rebuildHeader();
      this.startUpdatingRowsStructure();
    }
  }

  private rebuildHeader() {
    this.branch.headerRows = [];

    this.branch.rootRowsGroup = [];
    this.branch.rootRowsGroupHash = {};

    if (!this.branch || !this.branch.indicators) {
      return;
    }

    let depth = 1;
    this.branch.indicators.forEach(indicator => {
      const path = (indicator.displayTitle || '').split('->');
      if (path.length > depth) {
        depth = path.length;
      }
    });

    const rowGroupCaptions = (this.branch.meta.params['row-titles'] || '').split('|');

    for (let lvl = 0; lvl < depth; lvl++) {
      const row = this.buyDealMeta.map(el => ({
        meta: {displayWidth: el.displayWidth},
        buyDealMeta: el,
        displayTitle: el.title,
        displayTitlePath: el.title,
        colSpan: 1,
        rowSpan: 1,
      }));
      this.branch.indicators.forEach(indicator => {
        const path = (indicator.displayTitle || '').split('->');
        const displayTitlePath = path.slice(0, lvl < path.length ? lvl + 1 : path.length).join('->');

        // заголовок формируем только по 0-й строке
        if (!indicator.meta.rowNo) {
          row[indicator.meta.colNo - 1 + this.buyDealMeta.length] = {
            buyDealMeta: undefined,
            meta: indicator.meta,
            displayTitle: lvl < path.length ? path[lvl] : path[path.length - 1],
            displayTitlePath,
            colSpan: 1,
            rowSpan: 1,
          };
        }

        if (!this.branch.rootRowsGroup[indicator.meta.rowNo]) {
          this.branch.rootRowsGroup[indicator.meta.rowNo] = {
            hash: {},
            children: [],
            caption: rowGroupCaptions[indicator.meta.rowNo],
            indicators: [],
            hasEditableCells: false,
          };
        }
        const rowGroup = this.branch.rootRowsGroup[indicator.meta.rowNo];
        rowGroup.indicators[indicator.meta.colNo - 1 + this.buyDealMeta.length] = indicator;
        if (!rowGroup.hasEditableCells && indicator.indicatorMeta
          && indicator.indicatorMeta.id && !indicator.indicatorMeta.formula) {
          rowGroup.hasEditableCells = true;
        }

        if (!this.branch.rootRowsGroupHash[indicator.meta.indicatorTypeId]) {
          this.branch.rootRowsGroupHash[indicator.meta.indicatorTypeId] = [];
        }

        this.branch.rootRowsGroupHash[indicator.meta.indicatorTypeId][indicator.meta.rowNo] = rowGroup;
      });

      this.branch.headerRows.push(row);
    }

    this.pstReportStructureService.mergeHeaderCells(this.branch);

    for (const rowGroup of this.branch.rootRowsGroup) {
      rowGroup.level = 10;
      rowGroup.indicatorTypeMeta = this.getLevelSlice(rowGroup, 10);
    }

    this.changeDetectorRef.detectChanges();
  }

  getSuggestedTableWidth() {
    if (!this.branch.headerRows || !this.branch.headerRows.length) {
      return undefined;
    }

    const lastRow = this.branch.headerRows[this.branch.headerRows.length - 1];
    let width = 0;
    for (let iCol = 0; iCol < lastRow.length; iCol++) {
      width += (lastRow[iCol].meta.displayWidth || 60);
    }

    return `${width + 200}px`;
  }

  searchAgent(rowGroup: any) {
    this.securityService.getUserInfo().subscribe(userInfo => {
      if (userInfo.restrictPstAgentId && userInfo.agentId) {
        this.addbuyDealRow(userInfo.agentId, rowGroup, this.branch.defaultSubtypeId);
      } else {
        this.navigationService.searchAgent(AgentSearchModalComponent).subscribe(agentId => {
          if (agentId) {
            this.addbuyDealRow(agentId, rowGroup, this.branch.defaultSubtypeId);
          }
        });
      }
    });
  }

  private addbuyDealRow(agentId: any, rowGroup: any, indicatorSubtypeId: any) {
    this.lookupSourceService.getLookupObj('addr-region-group').subscribe(regionGroupLookup => {
      this.lookupSourceService.getLookupObj('addr-region').subscribe(regionLookup => {
        this.cacheService.getAgent(agentId).subscribe(agent => {
          const regionId = this.model.reportMeta.regionId;
          const regionGroupId = regionLookup[`Obj${regionId}`].regionGroupId;
          const kfhType = agent.legalFormId === 13 || agent.legalFormId === 31 || agent.legalFormId === 45 || agent.legalFormId === 0
            ? 2
            : 1;

          this.pstReportService.addDeal(
            this.model.reportMeta.id,
            {agentId, level60ObjKind: this.branch.meta.level60ObjKind},
          ).subscribe(data => {
            this.addRowWithGroups(rowGroup, regionGroupLookup, regionLookup, regionGroupId,
              regionId, kfhType, agentId, agent.shortCaption, data.data.id, data.data);
          });
        });
      });
    });
  }

  private addRowWithGroups(rowGroup, regionGroupLookup, regionLookup, regionGroupId, regionId, kfhType, agentId,
                           agentCaption, buyDealId, buyDealData: any) {
    if (!regionGroupId) {
      return;
    }

    // level 20: regionGroupId
    if (!rowGroup.hash[regionGroupId]) {
      rowGroup.hash[regionGroupId] = {
        caption: regionGroupLookup[`Obj${regionGroupId}`].caption,
        hash: {},
        children: [],
        level: 20,
        regionId: undefined,
        regionGroupId,
        kfhType: undefined,
        agentId: undefined,
        subtypeId: undefined,
        buyDealId: undefined,
        indicatorTypeMeta: this.getLevelSlice(rowGroup, 20),
      };
      rowGroup.children.push(rowGroup.hash[regionGroupId]);
    }
    const lvl20node = rowGroup.hash[regionGroupId];

    if (!regionId) {
      return;
    }

    // level 30: regionId
    if (!lvl20node.hash[regionId]) {
      lvl20node.hash[regionId] = {
        caption: regionLookup[`Obj${regionId}`].caption,
        level: 30,
        hash: {},
        children: [],
        regionId,
        regionGroupId,
        kfhType: undefined,
        agentId: undefined,
        subtypeId: undefined,
        buyDealId: undefined,
        indicatorTypeMeta: this.getLevelSlice(rowGroup, 30),
      };
      lvl20node.children.push(lvl20node.hash[regionId]);
    }
    const lvl30node = lvl20node.hash[regionId];

    if (!kfhType) {
      return;
    }

    // level 40: kfhType КФХ/предприятия
    if (!lvl30node.hash[kfhType]) {
      lvl30node.hash[kfhType] = {
        caption: kfhType === 2 ? 'Крестьянско-фермерские хозяйства' : 'Предприятия',
        hash: {},
        children: [],
        level: 40,
        regionId,
        regionGroupId,
        kfhType,
        agentId: undefined,
        subtypeId: undefined,
        buyDealId: undefined,
        indicatorTypeMeta: this.getLevelSlice(rowGroup, 40),
      };
      lvl30node.children.push(lvl30node.hash[kfhType]);
    }
    const lvl40node = lvl30node.hash[kfhType];

    if (!agentId) {
      return;
    }

    // level 50: agentId
    if (!lvl40node.hash[agentId]) {
      lvl40node.hash[agentId] = {
        agentCaption,
        caption: agentCaption,
        level: 50,
        regionId,
        regionGroupId,
        kfhType,
        agentId,
        subtypeId: undefined,
        buyDealId: undefined,
        hash: {},
        children: [],
        indicatorTypeMeta: this.getLevelSlice(rowGroup, 50),
      };
      lvl40node.children.push(lvl40node.hash[agentId]);
    }
    const lvl50node = lvl40node.hash[agentId];

    if (!buyDealId) {
      return;
    }

    // level 60: buyDealId
    if (!lvl50node.hash[buyDealId]) {
      lvl50node.hash[buyDealId] = {
        agentCaption,
        caption: agentCaption,
        level: 60,
        regionId,
        regionGroupId,
        kfhType,
        agentId,
        buyDealId,
        children: [],
        buyDealData,
        indicatorTypeMeta: this.getLevelSlice(rowGroup, 60),
      };
      lvl50node.children.push(lvl50node.hash[buyDealId]);
    }
  }

  private getLevelSlice(rowGroup: any, lvl: number): any[] {
    return rowGroup.indicators.map(indicator => {
      if (indicator) {
        return indicator.indicatorMeta;
      }

      return undefined;
    });
  }

  private startUpdatingRowsStructure() {
    if (!this.model || !this.branch) {
      return;
    }

    this.lookupSourceService.getLookupObj('addr-region-group').subscribe(regionGroupLookup => {
      this.lookupSourceService.getLookupObj('addr-region').subscribe(regionLookup => {
        for (const buyDealData of this.model.deals) {
          if (buyDealData.level60ObjKind !== this.branch.meta.level60ObjKind) {
            continue;
          }

          (buyDealData.agentId ? this.cacheService.getAgent(buyDealData.agentId) : of(undefined)).subscribe(agent => {
            const rowGroups = this.branch.rootRowsGroup;

            const kfhType = agent.legalFormId === 13 || agent.legalFormId === 31 || agent.legalFormId === 45 || agent.legalFormId === 0
              ? 2
              : 1;

            rowGroups.forEach(rowGroup => {
              this.addRowWithGroups(
                rowGroup,
                regionGroupLookup,
                regionLookup,
                regionLookup[`Obj${buyDealData.regionId}`].regionGroupId,
                buyDealData.regionId,
                kfhType,
                agent ? agent.id : undefined,
                agent ? agent.shortCaption : undefined,
                buyDealData.id,
                buyDealData,
              );
            });
          });
        }
      });
    });
  }

  cellClick(indicatorLevel: any, colIndex: any, rowGroup: any) {
    if (this.getFocused(indicatorLevel, colIndex, rowGroup)) {
      this.setEditingCell(indicatorLevel, colIndex, indicatorLevel.indicatorTypeMeta[colIndex], rowGroup, '');
    } else {
      this.setFocusedCell(indicatorLevel, colIndex, rowGroup);
    }
  }

  private setFocusedCell(indicatorLevel: any, colIndex: any, rowGroup: any) {
    this.model.__cl_focusedBranch = this.branch;
    this.model.__cl_focusedRowGroup = rowGroup;
    this.model.__cl_focusedIndicatorLevel = indicatorLevel;
    this.model.__cl_focusedIndicatorColIndex = colIndex;
    this.model.__cl_editingBranch = undefined;
    this.model.__cl_storing_error = undefined;
  }

  private setEditingCell(indicatorLevel: any, colIndex: any, indicatorTypeMeta: any,
                         rowGroup: any, proposedValue: any) {
    if (indicatorTypeMeta && (indicatorTypeMeta.formula || indicatorLevel.level !== 60)) {
      return;
    }

    if (!indicatorTypeMeta && !this.buyDealMeta[colIndex]) {
      return;
    }

    this.setFocusedCell(indicatorLevel, colIndex, rowGroup);
    const reportIndicator = rowGroup.indicators[colIndex];
    if (indicatorTypeMeta && reportIndicator) {
      this.setProposedCellValueStr(indicatorLevel, reportIndicator, indicatorTypeMeta, proposedValue);
    }
    this.model.__cl_editingBranch = this.branch;
    this.model.__cl_editingRowGroup = rowGroup;
    this.model.__cl_editingIndicatorLevel = indicatorLevel;
    this.model.__cl_editingIndicatorColIndex = colIndex;
    this.model.__cl_editingProposedValue = proposedValue;
  }

  tableKeyDown($event) {
    if (this.model.__cl_focusedBranch === this.branch) {
      const rowGroup = this.model.__cl_focusedRowGroup;
      const indicatorLevel = this.model.__cl_focusedIndicatorLevel;
      const colIndex = this.model.__cl_focusedIndicatorColIndex;
      const indicatorTypeMeta = indicatorLevel.indicatorTypeMeta[colIndex];
      const reportIndicator = rowGroup.indicators[colIndex];

      if (!this.getEditing(indicatorLevel, colIndex, rowGroup)) {
        if ($event.key >= '0' && $event.key <= '9') {
          this.setEditingCell(indicatorLevel, colIndex, indicatorTypeMeta, rowGroup, $event.key);
        }
        if ($event.key === 'F2') {
          this.setEditingCell(indicatorLevel, colIndex, indicatorTypeMeta, rowGroup, '');
        }
        if ($event.key === 'Delete' && !indicatorTypeMeta.formula) {
          this.pstReportStructureService.storeIndicatorValue(
            this.model, reportIndicator, indicatorTypeMeta, indicatorLevel, undefined);
        }
      }
    }
  }

  getFocused(indicatorLevel: any, colIndex: any, rowGroup: any) {
    return this.model.__cl_focusedBranch === this.branch &&
      this.model.__cl_focusedRowGroup === rowGroup &&
      this.model.__cl_focusedIndicatorLevel === indicatorLevel &&
      this.model.__cl_focusedIndicatorColIndex === colIndex;
  }

  getEditing(indicatorLevel: any, colIndex: any, rowGroup: any) {
    return this.model.__cl_editingBranch === this.branch &&
      this.model.__cl_editingRowGroup === rowGroup &&
      this.model.__cl_editingIndicatorLevel === indicatorLevel &&
      this.model.__cl_editingIndicatorColIndex === colIndex;
  }

  setProposedCellValueStr(indicatorLevel: any, reportIndicator: any, indicatorTypeMeta: any, strValue: any) {
    if (!reportIndicator || !indicatorTypeMeta || !indicatorLevel) {
      return;
    }

    const code = this.pstReportStructureService.getIndicatorValueCode(
      this.branch,
      indicatorLevel,
      reportIndicator,
      indicatorTypeMeta,
    );

    if (!this.model.values[code]) {
      this.model.values[code] = {
        date: reportIndicator.indicatorDate,
        indicatorTypeId: indicatorTypeMeta.id,
        regionGroupId: indicatorLevel.regionGroupId,
        regionId: indicatorLevel.regionId,
        kfhType: indicatorLevel.kfhType,
        agentId: indicatorLevel.agentId,
      };
    }

    if (strValue) {
      const parsedVal = NumericHelper.roundDecimal(parseFloat(strValue), indicatorTypeMeta.precision);
      this.model.values[code].proposedValue = parsedVal;
    } else {
      this.model.values[code].proposedValue = undefined;
    }
  }

  onTableCtnrScroll($event: any) {
    this.scrollLeft = this.tableCtnr.nativeElement.scrollLeft;
  }

  buyDealChanged(newValue: any, indicatorLevel: any, buyDealMeta: any, parentDealCollection: any[]) {
    // реентерабельности тут нету - пока не сохранился предыдущий - новый не даем сохранять
    if (this.model.__cl_storing_indicator) {
      return false;
    }

    const dataToStore: any = {};
    Object.assign(dataToStore, indicatorLevel.buyDealData);
    dataToStore[buyDealMeta.fieldName] = newValue;

    const confirmObs = dataToStore.dateDeal
      ? of(true)
      : this.alertService.confirmModal(
        'Удаление даты сделки приведет к её удалению. Продолжить?',
      );

    confirmObs.subscribe(confirmed => {
      if (confirmed) {
        this.model.__cl_storing_indicator = true;

        this.pstReportService.updateDeal(this.model.reportMeta.id, dataToStore).subscribe({
          next: data => {
            if (!data.data) {
              parentDealCollection.splice(parentDealCollection.findIndex(x => x.buyDealId === indicatorLevel.buyDealId), 1);
            } else {
              Object.assign(indicatorLevel.buyDealData, data.data);

              const ix = this.model.deals.findIndex(el => el.id === indicatorLevel.buyDealData.id);
              if (ix >= 0) {
                this.model.deals.splice(ix, 1);
              }
              this.model.deals.push(indicatorLevel.buyDealData);
            }
            this.model.__cl_storing_indicator = false;
          }, error: () => {
            this.model.__cl_storing_indicator = false;
          }
        });
      }
    });
  }
}
