import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { AnimalSearchResultsFormComponent } from './animal-search-results-form.component';

@Component({
  selector: 'app-animal-search-results',
  templateUrl: './animal-search-results.component.html'
})
export class AnimalSearchResultsComponent implements OnInit {

  model = AnimalSearchResultsFormComponent.getDefaultModel();

  constructor(private route: ActivatedRoute,
              private dataCachingService: DataCachingService,
              public appNavigationService: AppNavigationService,
              private fb: FormBuilder) {
  }

  ngOnInit() {

    this.route.paramMap
      .subscribe(params => {

        const existing = this.dataCachingService.getCachedData('AnimalSearchResults', '1');

        if (existing) {
          this.model = existing;
        } else {
          this.model = AnimalSearchResultsFormComponent.getDefaultModel(this.fb, { availableShowExternal: true, });
          this.dataCachingService.addToCache('AnimalSearchResults', '1', this.model);
        }
      });
  }

  rowClickedCallback($event: any) {
    if (this.model.filterFormGroup.get('showExternal').value) {
      this.appNavigationService.performAnimalEditing($event.tempId, undefined, undefined, true);
    }
    this.appNavigationService.performAnimalEditing($event.id, undefined, undefined);
  }
}
