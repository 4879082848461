
    <label for="{{contextControlName}}Date" aria-haspopup="true" role="tooltip"
           class="tooltip tooltip-validation tooltip-md tooltip-right"
           [class.invalid]="isInvalid(contextControlName)">
      <input #datePortion type="date" id="{{contextControlName}}Date" required
             (change)="datePortionControlChanged($event)" [attr.disabled]="disabled ? true : undefined">
      <input *ngIf="withTime" #timePortion type="time" id="{{contextControlName}}Time" required
             (change)="timePortionControlChanged($event)" [attr.disabled]="disabled ? true : undefined">
      <app-validation-tooltip [input]="contextFormGroup.controls[contextControlName]"></app-validation-tooltip>
    </label>
  