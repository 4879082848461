<clr-stack-view *ngIf="contextFormGroup && id" class="itech-documents">
  <clr-stack-block>
    <clr-stack-label style="vertical-align:middle;font-size:14px;"
                     [style.color]="currentStatusId === quarantineStage.SUSPICION ? undefined : 'silver'">
      <clr-icon shape="play" style="color: green" size="20"
                *ngIf="currentStatusId === quarantineStage.SUSPICION"></clr-icon>
      {{quarantineStage.SUSPICION|lookup:'quarantine-status'|async}}
      {{getDatePeriodStage(quarantineStage.SUSPICION, noSuspicionStage ? '(отсутствует)' : undefined)}}
    </clr-stack-label>
    <clr-stack-block>
      <clr-stack-label>
        <div class="compact form">
          <app-edit-quarantine-suspicion [contextFormGroup]="contextFormGroup" [id]="id" [serverSideErrorsProvider]="serverSideErrorsProvider"
                                         [isAccessEdit]="isAccessEdit" (dirtyFormGroup)="dirtyFormGroupInChildrenComponents($event)"
                                         [currentStatusId]="currentStatusId" (needUpdateQuarantine)="needUpdateQuarantine.next()"
                                         [noSuspicionStage]="noSuspicionStage"></app-edit-quarantine-suspicion>
        </div>
      </clr-stack-label>
    </clr-stack-block>
  </clr-stack-block>
  <clr-stack-block>
    <clr-stack-label style="vertical-align:middle;font-size:14px;"
                     [style.color]="currentStatusId === quarantineStage.DETECTION ? undefined : 'silver'">
      <clr-icon shape="play" style="color: green" size="20"
                *ngIf="currentStatusId === quarantineStage.DETECTION"></clr-icon>
      {{quarantineStage.DETECTION|lookup:'quarantine-status'|async}}
      {{getDatePeriodStage(quarantineStage.DETECTION)}}
    </clr-stack-label>
    <clr-stack-block>
      <clr-stack-label>
        <div class="compact form">
          <app-edit-quarantine-detection [contextFormGroup]="contextFormGroup" [id]="id" [serverSideErrorsProvider]="serverSideErrorsProvider"
                                         [isAccessEdit]="isAccessEdit" (dirtyFormGroup)="dirtyFormGroupInChildrenComponents($event)"
                                         [currentStatusId]="currentStatusId" (needUpdateQuarantine)="needUpdateQuarantine.next()"
                                         [noSuspicionStage]="noSuspicionStage"></app-edit-quarantine-detection>
        </div>
      </clr-stack-label>
    </clr-stack-block>
  </clr-stack-block>
  <clr-stack-block>
    <clr-stack-label style="vertical-align:middle;font-size:14px;"
                     [style.color]="currentStatusId === quarantineStage.INTRODUCTION ? undefined : 'silver'">
      <clr-icon shape="play" style="color: green" size="20"
                *ngIf="currentStatusId === quarantineStage.INTRODUCTION"></clr-icon>
      {{quarantineStage.INTRODUCTION|lookup:'quarantine-status'|async}}
      {{getDatePeriodStage(quarantineStage.INTRODUCTION)}}
    </clr-stack-label>
    <clr-stack-block>
      <clr-stack-label>
        <div class="compact form">
          <app-edit-quarantine-introduction [contextFormGroup]="contextFormGroup" [id]="id" [serverSideErrorsProvider]="serverSideErrorsProvider"
                                            [isAccessEdit]="isAccessEdit" (dirtyFormGroup)="dirtyFormGroupInChildrenComponents($event)"
                                            [currentStatusId]="currentStatusId" (needUpdateQuarantine)="needUpdateQuarantine.next()"></app-edit-quarantine-introduction>
        </div>
      </clr-stack-label>
    </clr-stack-block>
  </clr-stack-block>
  <clr-stack-block>
    <clr-stack-label style="vertical-align:middle;font-size:14px;"
                     [style.color]="currentStatusId === quarantineStage.CANCEL ? undefined : 'silver'">
      <clr-icon shape="play" style="color: green" size="20"
                *ngIf="currentStatusId === quarantineStage.CANCEL"></clr-icon>
      {{quarantineStage.CANCEL|lookup:'quarantine-status'|async}}
      {{getDatePeriodStage(quarantineStage.CANCEL)}}
    </clr-stack-label>
    <clr-stack-block>
      <clr-stack-label>
        <div class="compact form">
          <app-edit-quarantine-cancel [contextFormGroup]="contextFormGroup" [id]="id" [serverSideErrorsProvider]="serverSideErrorsProvider"
                                      [isAccessEdit]="isAccessEdit" (dirtyFormGroup)="dirtyFormGroupInChildrenComponents($event)"
                                      [currentStatusId]="currentStatusId" (needUpdateQuarantine)="needUpdateQuarantine.next()"></app-edit-quarantine-cancel>
        </div>
      </clr-stack-label>
    </clr-stack-block>
  </clr-stack-block>
</clr-stack-view>
