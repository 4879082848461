import {Component, Input} from '@angular/core';
import {AlertService} from '../../../ui/infrastructure/alert.service';
import {DataCachingService} from '../../../logic/services/data-caching.service';
import {GlobalWaitingOverlayService} from '../../../ui/infrastructure/global-waiting-overlay.service';
import {LookupSourceService} from '../../../logic/services/lookup-source.service';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {InstitutionDataService} from '../../../logic/services/institution-data.service';
import {FormHelper} from '../../../ui/controls/form-helper';
import {AppNavigationService} from '../../../logic/services/app-navigation.service';
import {StringHelper} from '../../../helpers/string-helper';

@Component({
  selector: 'app-edit-institution-employees',
  templateUrl: './edit-institution-employees.component.html'
})
export class EditInstitutionEmployeesComponent {

  model: any = {};
  storing = false;
  addEmployeeModalOpened = false;

  gridColumnDefs = [
    {
      headerName: '№', field: 'id', width: 90,
      onCellClicked: data => this.editEmployee(data.data)
    },
    {
      headerName: 'Фамилия', field: 'lastName', width: 140,
      onCellClicked: data => this.editEmployee(data.data)
    },
    {
      headerName: 'Имя', field: 'firstName', width: 140,
      onCellClicked: data => this.editEmployee(data.data)
    },
    {
      headerName: 'Отчество', field: 'middleName', width: 140,
      onCellClicked: data => this.editEmployee(data.data)
    },
    {
      headerName: 'Дата рождения', field: 'birthDate', width: 120,
      valueFormatter: params => StringHelper.getRuDate(params.value),
      onCellClicked: data => this.editEmployee(data.data)
    },
    {
      headerName: 'Должность', field: 'postId',
      valueFormatter: params => this.institutionPostLookup[params.value],
      onCellClicked: data => this.editEmployee(data.data),
      comparator: (a, b) => {
        return this.stringComparator(this.institutionPostLookup[a], this.institutionPostLookup[b]);
      }
    },
    {
      headerName: 'Пункт, участок, лаборатория', field: 'branchId',
      valueFormatter: params => this.institutionBranchLookup[params.value],
      onCellClicked: data => this.editEmployee(data.data),
      comparator: (a, b) => {
        return this.stringComparator(this.institutionBranchLookup[a], this.institutionBranchLookup[b]);
      }
    },
    {
      headerName: 'Населенный пункт по умолчанию', field: 'defaultCityId',
      valueGetter: params => params.data.defaultCityId ? params.data.defaultRegionId + '-' + params.data.defaultCityId : null,
      valueFormatter: params => this.employeeRegionIdCityIdLookup[params.value] ,
      onCellClicked: data => this.editEmployee(data.data),
      comparator: (a, b) => {
          return this.stringComparator(
            this.employeeRegionIdCityIdLookup[a],
            this.employeeRegionIdCityIdLookup[b]);
      }
    },
    {
      headerName: 'Дата увольнения', field: 'retireDate',
      valueFormatter: params => StringHelper.getRuDate(params.value),
      onCellClicked: data => this.editEmployee(data.data)
    },
  ];

  currentFormGroup: FormGroup;
  currentEmployee: any;
  currentIsNew = false;
  services: any[];

  institutionBranchLookup: any = {};
  institutionPostLookup: any = {};
  employeeRegionIdCityIdLookup: any = {};

  get employees(): any[] {
    return this.model.employees;
  }

  private _id: number;
  @Input()
  get id(): number {
    return this._id;
  }

  set id(id: number) {
    if (this._id !== id) {
      this._id = id;

      this.lookupSourceService.getLookupObj('institution-post').subscribe(lookup => this.institutionPostLookup = lookup);
      this.lookupSourceService.getLookupObj('institution-branch' + id).subscribe(lookup => this.institutionBranchLookup = lookup);
      this.lookupSourceService.getLookupObj('addr-city').subscribe(lookup => this.employeeRegionIdCityIdLookup = lookup);

      this.refreshFormDataInternal();
    }
  }

  stringComparator(s1: any, s2: any): number {
    if (!s1 && !s2) {
      return 0;
    }
    if (!s1) {
      return 1;
    }
    if (!s2) {
      return -1;
    }
    if (s1.toLowerCase() < s2.toLowerCase()) {
      return -1;
    }
    if (s1.toLowerCase() > s2.toLowerCase()) {
      return 1;
    }
    return 0;
  }


  constructor(private institutionDataService: InstitutionDataService,
              private alertService: AlertService,
              private dataCachingService: DataCachingService,
              private waitingOverlay: GlobalWaitingOverlayService,
              private lookupSourceService: LookupSourceService,
              public appNavigationService: AppNavigationService,
              private fb: FormBuilder) {

  }

  getRowStyle(params) {
    if (params.data && params.data.retireDate) {
      return {color: 'silver'};
    }
  }

  isInvalid(fg: FormGroup, cname: string) {
    return FormHelper.isInvalid(fg, cname);
  }

  getEmployeeGroupDef(val: any = {}) {
    return {
      id: val.id,
      lastName: [val.lastName, Validators.required],
      firstName: [val.firstName, Validators.required],
      middleName: val.middleName,
      fullFioGen: val.fullFioGen,
      birthDate: [val.birthDate, Validators.compose([Validators.required, FormHelper.validateDateTimePicker()])],
      postId: [val.postId, Validators.required],
      branchId: [val.branchId, Validators.required],
      retireDate: [val.retireDate, FormHelper.validateDateTimePicker()],
      defaultRegionId: val.defaultRegionId,
      defaultCityId: val.defaultCityId,
      regionIdCityId: val.defaultRegionId && val.defaultCityId ? val.defaultRegionId.toString()
        + '-' + val.defaultCityId.toString() : undefined,
      asoiRoles: (val.asoiRoles && val.asoiRoles.length
        ? this.fb.array(val.asoiRoles.map(el => this.fb.control(el)))
        : this.fb.array([])),
      email: val.email,
    };
  }

  buildFormArray(branchesData: any[]) {
    return this.fb.array(branchesData.map(item => this.fb.group(this.getEmployeeGroupDef(item))));
  }

  store() {
    this.storing = true;

    this.institutionDataService.storeInstitutionEmployees(this.id,
      this.model.employees,
      this.model.delete)
      .subscribe(val => {
          this.storing = false;
          // обязательно обновляем форму из БД - чтобы загрузить идшники для новых сотрудников
          this.refreshFormData();
          // обновим поисковый кэш работников (чтобы комбобоксы заполнились актуальными данными, там где работники используются)
          this.lookupSourceService.invalidateLookup('institution-employee' + this.id.toString());
        },
        error => {
          this.storing = false;
        });
  }

  cancelEdit() {
    this.dataCachingService.removeCachedData('EditInstitutionEmployees', this.id.toString());
    this.refreshFormDataInternal();
  }

  refreshFormData() {
    this.dataCachingService.removeCachedData('EditInstitutionServices', this.id.toString());
    this.dataCachingService.removeCachedData('EditInstitutionEmployeeServices', this.id.toString());
    this.dataCachingService.removeCachedData('EditInstitutionEmployees', this.id.toString());

    this.refreshFormDataInternal();
  }

  refreshFormDataInternal() {
    this.waitingOverlay.StartWaiting();

    const existing = this.dataCachingService.getCachedData('EditInstitutionEmployees', this.id.toString());

    if (existing) {
      this.model = existing;
      this.waitingOverlay.EndWaiting();
    } else {
      this.institutionDataService.getInstitutionEmployeesForEdit(this.id).subscribe(data => {
        this.model = {id: this.id, delete: [], employees: data};
        this.dataCachingService.addToCache('EditInstitutionEmployees', this.id.toString(), this.model);
        this.waitingOverlay.EndWaiting();
      }, () => {
        this.waitingOverlay.EndWaiting();
      });
    }
  }

  addEmployee() {
    this.currentFormGroup = this.fb.group(this.getEmployeeGroupDef());
    delete this.services;
    this.currentIsNew = true;
    this.currentEmployee = this.currentFormGroup.value;
    this.addEmployeeModalOpened = true;
  }

  editEmployee(employee: any) {
    this.currentFormGroup = this.fb.group(this.getEmployeeGroupDef(employee));
    delete this.services;
    this.currentIsNew = false;
    this.currentEmployee = employee;
    this.addEmployeeModalOpened = true;
  }

  addEmployeeModalOpenedChanged() {
    this.addEmployeeModalOpened = false;

    // иначе ag-grid не перезагрузит список
    const empl = this.model.employees;
    this.model.employees = [];
    setTimeout(() => {
      this.model.employees = empl;
    });
  }
}
