import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DataCachingService} from '../../logic/services/data-caching.service';
import {BookmarkService} from '../../logic/services/bookmark.service';
import {PstReportService} from '../services/pst-report.service';
import {JobRunnerUiService} from '../../ui/infrastructure/job-runner-ui.service';
import {MetadataService} from '../../logic/services/metadata.service';
import {AlertService} from '../../ui/infrastructure/alert.service';
import {SecurityService} from '../../logic/services/security.service';
import {PstIndicatorTypeMetaEditModalComponent} from './meta/pst-indicator-type-meta-edit-modal.component';
import {AppNavigationService} from '../../logic/services/app-navigation.service';
import {ReportBranchMetaEditModalComponent} from './meta/report-branch-meta-edit-modal.component';

@Component({
  templateUrl: './pst-report-edit.component.html'
})
export class PstReportEditComponent implements OnInit {

  @Input() id: number;

  model: any;

  printing = false;

  constructor(
    private route: ActivatedRoute,
    private bookmarkService: BookmarkService,
    private dataCachingService: DataCachingService,
    private pstReportService: PstReportService,
    private jobRunnerUiService: JobRunnerUiService,
    private metadataService: MetadataService,
    private alertService: AlertService,
    public securityService: SecurityService,
    private appNavigationService: AppNavigationService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.route.paramMap
      .subscribe(params => {
        this.id = parseInt(params.get('id'), 10);

        const existing = this.dataCachingService.getCachedData('PstReportEditComponent', this.id.toString());

        this.bookmarkService.refreshPstReportBookmark(this.id);

        if (existing) {
          this.model = existing;
          this.model.__cl_editingBranch = undefined;
        } else {
          this.refreshFormData();
        }
      });
  }

  refreshFormData(showHiddenCells?: boolean, showReportStructure?: boolean) {
    this.pstReportService.getReportData(this.id).subscribe(reportData => {
      this.model = reportData;
      if (reportData.pages && reportData.pages.length) {
        reportData.pages[0].tabActive = true;
      }

      this.model.showHiddenCells = showHiddenCells;

      if (!reportData.pages || !reportData.pages.length || showReportStructure) {
        this.model.showReportStructure = true;
        this.model.showHiddenCells = true;
      }

      this.dataCachingService.addToCache('PstReportEditComponent', this.id.toString(), this.model);
    });
  }

  printReport() {
    if (this.printing) {
      return;
    }

    this.printing = true;
    this.jobRunnerUiService.runOperation(18 /* "Экспорт электронных отчетных форм в Excel"*/,
      {
        type: 'pstReportXlsExporter',
        reportId: this.id
      }, undefined, undefined, false)
      .subscribe({
        next: (data) => {
          this.metadataService.preloadFile(data).subscribe(fileHash => {
            this.printing = false;
            // window.open(environment.api + '/files/get?preloadId=' + encodeURIComponent(fileHash));
          });
        },
        error: () => {
          this.printing = false;
        }
      });
  }

  lockReport() {
    this.alertService.confirmModal('Вы действительно хотите пометить форму как отправленную?')
      .subscribe(() => {
        this.jobRunnerUiService.runOperation(24 /* Отправка / утверждение отчета */,
          {
            type: 'pstLockReport',
            reportId: this.id,
            mode: 10,
          }, undefined, undefined, false)
          .subscribe({
            next: (data) => {
              this.refreshFormData();
            }
          });
      });
  }

  approveReport() {
    this.alertService.confirmModal('Вы действительно хотите принять отчетную форму?')
      .subscribe(() => {
        this.jobRunnerUiService.runOperation(24 /* Отправка / утверждение отчета */,
          {
            type: 'pstLockReport',
            reportId: this.id,
            mode: 200,
          }, undefined, undefined, false)
          .subscribe({
            next: (data) => {
              this.refreshFormData();
            }
          });
      });
  }

  unlockReport() {
    this.alertService.confirmModal('Вы действительно хотите отменить отметку об отправке/утверждении отчета?')
      .subscribe(() => {
        this.jobRunnerUiService.runOperation(24 /* Отправка / утверждение отчета */,
          {
            type: 'pstLockReport',
            reportId: this.id,
            mode: 1,
          }, undefined, undefined, false)
          .subscribe({
            next: (data) => {
              this.refreshFormData();
            }
          });
      });
  }

  addIndicatorType() {
    this.appNavigationService.showIndicatorTypeEdit(PstIndicatorTypeMetaEditModalComponent,
      {cumulative: true, levelFormula: 'СУММА_ДОЧЕРНИЕ'})
      .subscribe(() => {
        this.alertService.success('Вид показателя успешно добавлен');
      });
  }

  editReportBranch(reportBranch: any) {
    this.appNavigationService.showReportBranchEdit(ReportBranchMetaEditModalComponent, reportBranch.meta)
      .subscribe(updated => {
        Object.assign(reportBranch.meta, updated);
        this.alertService.success('Раздел успешно изменен');
        this.refreshFormData();
      });
  }

  addReportBranch() {
    this.appNavigationService.showReportBranchEdit(ReportBranchMetaEditModalComponent,
      {
        createNew: true,
        reportTypeId: this.model.reportMeta.reportTypeId,
        orderNo: 10,
        editorKind: 'Табл_по_графам',
      })
      .subscribe(() => {
        this.alertService.success('Раздел успешно добавлен');
        this.refreshFormData();
      });
  }

  navigateNextReport(direction: number) {
    const filterRegionId = this.model.reportMeta.regionId || null;

    this.pstReportService.getListReports({
      year: new Date(this.model.reportMeta.reportTo).getFullYear(),
      regionId: filterRegionId,
      reportTypeId: this.model.reportMeta.reportTypeId,
      mode: 4, /*MODE_ALL*/
    }).subscribe({
      next: (data: any) => {
        data.sort((a, b) => a.reportFrom.localeCompare(b.reportTo) * direction);

        for (const row of data) {
          if (filterRegionId === row.regionId && row.reportTo.localeCompare(this.model.reportMeta.reportTo) * direction > 0) {
            this.router.navigate(['/pst-report-edit', row.id]);
            return;
          }
        }

        this.alertService.warning('Следующий (или предыдущий) отчет за выбранный год не найден');
      }
    });
  }
}
