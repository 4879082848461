import { Component, Input } from '@angular/core';
import { QueryService } from '../../logic/services/query.service';
import { FormBuilder, Validators } from '@angular/forms';
import { FormHelper } from '../../ui/controls/form-helper';
import { SecurityService } from '../../logic/services/security.service';
import { AlertService } from '../../ui/infrastructure/alert.service';
import { AppNavigationService } from '../../logic/services/app-navigation.service';

@Component({
  selector: 'app-query-details-query',
  templateUrl: './query-details-query.component.html',
  styleUrls: ['./query.all.css']
})
export class QueryDetailsQueryComponent {

  @Input() model = {
    data: [],
    dataColumns: [],
    nodeType: undefined,
    entityType: undefined,
    query: undefined,
    parentCondition: undefined,
    condition: undefined,
    selectedNode: undefined,
    cachedFavorite: undefined
  };

  storing = false;
  serverSideValidationErrors = [];
  isInvalid = FormHelper.isInvalid;

  constructor(private queryService: QueryService,
              private securityService: SecurityService,
              public appNavigationService: AppNavigationService,
              private alertService: AlertService,
              private fb: FormBuilder) {
  }

  invalidateContextFormGroup() {
    this.model.query.contextFormGroup = undefined;
  }

  get favorite() {
    if (!this.model) {
      return undefined;
    }
    if (this.model.query.cachedFavorite === undefined) {
      this.model.query.cachedFavorite = this.appNavigationService.getFavoriteQuery(this.model.query.data.id);
    }
    return this.model.query.cachedFavorite;
  }

  set favorite(val: boolean) {
    if (!this.model) {
      return;
    }

    this.model.query.cachedFavorite = val;

    if (this.appNavigationService.getFavoriteQuery(this.model.query.data.id) !== this.model.query.cachedFavorite) {
      this.appNavigationService.setFavoriteQuery(
        this.model.query.data.id,
        this.model.query.contextFormGroup.get('caption').value,
        this.model.query.cachedFavorite);
    }
  }

  get contextFormGroup() {
    if (!this.model.query.contextFormGroup) {

      if (this.model.query.data.scopeInstitutionEmployeeId) {
        this.model.query.personGroupVisibility = 3;
      } else if (this.model.query.data.scopeInstitutionId) {
        this.model.query.personGroupVisibility = 2;
      } else {
        this.model.query.personGroupVisibility = 1;
      }

      this.model.query.contextFormGroup = this.fb.group({
        id: this.model.query.data.id,
        caption: [this.model.query.data.caption, Validators.required],
        personGroupVisibility: [this.model.query.personGroupVisibility, Validators.required],
        entityType: [this.model.entityType.id, Validators.required],
        showOnlyCount: this.model.query.data.showOnlyCount,
      });

      if (!this.model.query.data.id) {
        this.model.query.contextFormGroup.markAsDirty();
      }
    }

    return this.model.query.contextFormGroup;
  }

  store() {
    if (this.storing) {
      return;
    }

    this.storing = true;

    FormHelper.markAsSubmitted(this.contextFormGroup);
    if (!this.contextFormGroup.valid) {
      return;
    }

    this.securityService.getUserInfo().subscribe(userInfo => {

      const dataToStore = this.contextFormGroup.value;
      if (+dataToStore.personGroupVisibility === 2) {
        dataToStore.scopeInstitutionId = userInfo.institutionId;
        dataToStore.scopeInstitutionEmployeeId = undefined;
      } else if (+dataToStore.personGroupVisibility === 3) {
        dataToStore.scopeInstitutionId = userInfo.institutionId;
        dataToStore.scopeInstitutionEmployeeId = userInfo.institutionEmployeeId;
      } else {
        dataToStore.scopeInstitutionId = undefined;
        dataToStore.scopeInstitutionEmployeeId = undefined;
      }

      Object.assign(this.model.query.data, dataToStore);

      this.queryService.storeQuery(this.queryService.buildQueryForSave(this.model.query)).subscribe(data => {

        if (!this.model.query.data.id) {
          this.model.query.conditions = [];
        }

        Object.assign(this.model.query.data, data);

        this.queryService.updateConditionsEditStructure(this.model.query.conditions, this.model.query.data.entityType);

        if (!dataToStore.id) {
          this.model.entityType.queries.push({data: {caption: 'Новая выборка'}, conditions: []});
        }

        this.invalidateContextFormGroup();

        this.storing = false;
      }, error => {
        this.storing = false;
        FormHelper.setSingleFormGroupServerSideValidationErrors(error, this, this.contextFormGroup);
      });
    });
  }

  deleteQuery() {
    this.alertService.confirmModal('Вы уверены, что хотите удалить выборку?').subscribe(val => {
      if (!val) {
        return;
      }

      this.queryService.deleteQuery(this.model.query.data.id).subscribe(() => {
        const ix = this.model.entityType.queries.findIndex(el => el === this.model.query);
        if (ix >= 0) {
          this.model.entityType.queries.splice(ix, 1);

          this.model.query = undefined;
          this.model.nodeType = undefined;
          this.model.selectedNode = undefined;
        }
      });
    });
  }

  cancelEdit() {

  }
}
