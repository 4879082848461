import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import {StringHelper} from '../../helpers/string-helper';

@Injectable({
  providedIn: 'root',
})
export class QuarantineDataService {

  readonly _mainUri = environment.api + '/quarantine/';

  constructor (private http: HttpClient) {
  }

  private static prettify(data: any) {

    if (data['territories'] && data['territories'].length) {
      data['territories'].forEach(territory =>
        territory['cadNumber'] = StringHelper.stringCadNumberWithoutColon(territory['cadNumber']));
    }
    if (data && data['stagePeriods']) {
      data['stagePeriods'] = (Object.keys(data['stagePeriods']).map(key => data['stagePeriods'][key]) as any[])
        .filter(el => el && el.quarantineId && el.quarantineStageId && el.dateFrom);
    }

    return data;
  }

  private prettifySearchParams(params: any): any {

    // есть два варианта выбора населенного пункта
    // после выбора муниципального образования и просто выбором населенного пункта без муниц образования
    // поэтому второй вариант надо обработать и сохранить в приемлемом виде
    if (!params.regionId && params.cityId) {
      const val = params.cityId.split('-');
      params.regionId = val[0];
      params.cityId = val[1];
    }

    return params;
  }

  public search(searchParams: any): Observable<any[]> {
    const qstring = this._mainUri + 'search';
    return this.http.post(qstring, this.prettifySearchParams(searchParams))
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  public storeCommon(data: any): Observable<any> {
    const qstring = this._mainUri + 'store-common';
    return this.http.post(qstring, QuarantineDataService.prettify(data))
      .pipe(map((response: any) => {
        return response as any;
      }));
  }

  public storeStages(data: any): Observable<any> {
    const qstring = this._mainUri + 'store-stages';
    return this.http.post(qstring, QuarantineDataService.prettify(data))
      .pipe(map((response: any) => {
        return response as any;
      }));
  }

  public getForEditById(id: number): Observable<any> {
    const qstring = this._mainUri + 'get-for-edit/' + id.toString();
    return this.http.get(qstring, {headers: {'interceptNoAccess': 'true'}})
      .pipe(map((response: any) => {
        return response as any;
      }));
  }

  public getForLookup(id: number): Observable<any> {
    const qstring = this._mainUri + 'lookup/' + id.toString();
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response as any;
      }));
  }

  public closeCurrentStage(quarantineId: number, dateClosed: Date, dateToInclusiveNextStage: Date = null) {
    const qstring = this._mainUri + 'close-current-stage/' + quarantineId.toString(10);
    return this.http.post(qstring,
      {
        dateFrom: StringHelper.getISODateWithMilliseconds(dateClosed),
        dateTo: (StringHelper.getISODateWithMilliseconds(dateToInclusiveNextStage) || null)
      })
      .pipe(map((response: any) => {
        return response as any;
      }));
  }

  public forceChangeStatus(quarantineId: number, newStatusId: number, dateFrom: any) {
    const qstring = this._mainUri + 'force-change-status/' +
      quarantineId + '/' + newStatusId + '/' + StringHelper.getISODate(dateFrom ? new Date(dateFrom) : new Date());
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response as any;
      }));
  }

  public getTrappedAnimal(animalId: number, date: any) {
    const qstring = environment.api + '/animal/check-trapped-quarantine/' + animalId + '/' + StringHelper.getISODate(new Date(date));
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response as any;
      }));
  }

  public getTrappedStable(stableId: number, date: any) {
    const qstring = environment.api + '/stable/check-trapped-quarantine/' + stableId + '/' + StringHelper.getISODate(new Date(date));
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response as any;
      }));
  }

  public searchAllTrappedAnimal(quarantineId: number) {
    const qstring = this._mainUri + 'trapped-all/animal/' + quarantineId;
    return this.http.get(qstring, {headers: {'interceptNoAccess': 'true'}})
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  public searchAllTrappedStable(quarantineId: number) {
    const qstring = this._mainUri + 'trapped-all/stable/' + quarantineId;
    return this.http.get(qstring, {headers: {'interceptNoAccess': 'true'}})
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  public getEventsByQuarantineId(quarantineId: number) {
    const qstring = this._mainUri + 'events/' + quarantineId;
    return this.http.get(qstring, {headers: {'interceptNoAccess': 'true'}})
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  public getAllMessages(quarantineId: number) {
    const qstring = this._mainUri + 'get-messages/' + quarantineId;
    return this.http.get(qstring, {headers: {'interceptNoAccess': 'true'}})
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  public deleteQuarantine(quarantineId: number) {
    const qstring = this._mainUri + 'delete/' + quarantineId;
    return this.http.get(qstring);
  }

  public storeMessage(data: any) {
    const qstring = this._mainUri + 'store-messages';
    return this.http.post(qstring, data)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  storeEvents(quarantineId: any, events: any[], deleted: any[]) {
    const model = {
      quarantineId: quarantineId,
      events: events,
      delete: deleted
    };

    const qstring = environment.api + '/quarantine/store-events';
    return this.http.post(qstring, model)
      .pipe(map((response: any, error: any) => {
        return true;
      }));
  }

  getEventsForEdit(id: number): Observable<any[]> {
    const qstring = environment.api + '/quarantine/qua-events/' + id.toString(10);
    return this.http.get(qstring, {headers: {'interceptNoAccess': 'true'}})
      .pipe(map(item => {
        return item as any[];
      }));
  }

}
