
    <div class="itech-common-wrap" id="interceptNoAccess">
      <div id="agent-common-content">
        <div class="itech-doc-container" style="min-height:100%">
          <div style="padding:5px;width:100%;min-height:88%">
            <app-grid [rowData]="(this.userService.getUserInfo()|async).userSettingUi.tags"
                      [columnDefs]="gridColumnDefs"
                      [uniqueGridName]="'UserTagsAutofillGrid'" [frameworkComponents]="frameworkComponents"
                      [exportFileName]="'Тэги автозаполнения ' + (userService.getUserInfo()|async).id">
            </app-grid>
          </div>
        </div>
      </div>

      <div class="itech-form-actions">
        <div>
          <button class="btn btn-success-outline" (click)="editTag({})">
            <clr-icon shape="plus"></clr-icon>&nbsp;Добавить
          </button>
        </div>
      </div>
    </div>
    <app-user-tags-autofill-edit-modal [modalOpenedEmit]="editTagModalOpened">
    </app-user-tags-autofill-edit-modal>
  