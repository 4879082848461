/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@clr/angular";
import * as i2 from "./app-text-lookup.component.ngfactory";
import * as i3 from "./app-text-lookup.component";
import * as i4 from "../../logic/services/lookup-source.service";
import * as i5 from "@angular/common";
import * as i6 from "./app-combo-multilookup.component";
import * as i7 from "@angular/forms";
var styles_AppComboMultilookupComponent = [];
var RenderType_AppComboMultilookupComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComboMultilookupComponent, data: {} });
export { RenderType_AppComboMultilookupComponent as RenderType_AppComboMultilookupComponent };
function View_AppComboMultilookupComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "label", [], [[1, "for", 0]], null, null, null, null)), i0.ɵdid(3, 212992, null, 0, i1.ClrLabel, [[2, i1.ɵbe], [2, i1.ɵbf], [2, i1.ɵbg], i0.Renderer2, i0.ElementRef], { forAttr: [0, "forAttr"] }, null), (_l()(), i0.ɵted(4, null, [" ", " "])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "app-text-lookup", [], [[8, "className", 0]], null, null, i2.View_AppTextLookupComponent_0, i2.RenderType_AppTextLookupComponent)), i0.ɵdid(6, 573440, null, 0, i3.AppTextLookupComponent, [i4.LookupSourceService], { contextControlName: [0, "contextControlName"], lookupName: [1, "lookupName"], contextFormGroup: [2, "contextFormGroup"], contextControlId: [3, "contextControlId"], disabled: [4, "disabled"], sorted: [5, "sorted"], required: [6, "required"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵinlineInterpolate(1, "", (_co.contextControlName + _v.context.index), ""); _ck(_v, 3, 0, currVal_1); var currVal_4 = _v.context.index; var currVal_5 = _co.lookupName; var currVal_6 = _co.boundArray; var currVal_7 = (_co.contextControlName + _v.context.index); var currVal_8 = _co.disabled; var currVal_9 = _co.sorted; var currVal_10 = false; _ck(_v, 6, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 3).forAttr; _ck(_v, 2, 0, currVal_0); var currVal_2 = ((_co.itemTitle + " \u2116") + (_v.context.index + 1).toString()); _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.classControl; _ck(_v, 5, 0, currVal_3); }); }
function View_AppComboMultilookupComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "label", [], [[1, "for", 0]], null, null, null, null)), i0.ɵdid(2, 212992, null, 0, i1.ClrLabel, [[2, i1.ɵbe], [2, i1.ɵbf], [2, i1.ɵbg], i0.Renderer2, i0.ElementRef], { forAttr: [0, "forAttr"] }, null), (_l()(), i0.ɵted(3, null, [" ", " "])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "app-text-lookup", [], [[8, "className", 0]], null, null, i2.View_AppTextLookupComponent_0, i2.RenderType_AppTextLookupComponent)), i0.ɵdid(5, 573440, null, 0, i3.AppTextLookupComponent, [i4.LookupSourceService], { contextControlName: [0, "contextControlName"], lookupName: [1, "lookupName"], contextFormGroup: [2, "contextFormGroup"], contextControlId: [3, "contextControlId"], disabled: [4, "disabled"], sorted: [5, "sorted"], required: [6, "required"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵinlineInterpolate(1, "", (_co.contextControlName + "N"), ""); _ck(_v, 2, 0, currVal_1); var currVal_4 = "value"; var currVal_5 = _co.lookupName; var currVal_6 = _co.emptyElementForm; var currVal_7 = (_co.contextControlName + "N"); var currVal_8 = _co.disabled; var currVal_9 = _co.sorted; var currVal_10 = false; _ck(_v, 5, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 2).forAttr; _ck(_v, 1, 0, currVal_0); var currVal_2 = ((_co.itemTitle + " \u2116") + (_co.boundArray.controls.length + 1).toString()); _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.classControl; _ck(_v, 4, 0, currVal_3); }); }
function View_AppComboMultilookupComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppComboMultilookupComponent_2)), i0.ɵdid(2, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppComboMultilookupComponent_3)), i0.ɵdid(4, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.boundArray.controls; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.disabled; _ck(_v, 4, 0, currVal_1); }, null); }
function View_AppComboMultilookupComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-text-lookup", [], [[8, "className", 0]], null, null, i2.View_AppTextLookupComponent_0, i2.RenderType_AppTextLookupComponent)), i0.ɵdid(2, 573440, null, 0, i3.AppTextLookupComponent, [i4.LookupSourceService], { contextControlName: [0, "contextControlName"], lookupName: [1, "lookupName"], contextFormGroup: [2, "contextFormGroup"], contextControlId: [3, "contextControlId"], disabled: [4, "disabled"], sorted: [5, "sorted"], required: [6, "required"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _v.context.index; var currVal_2 = _co.lookupName; var currVal_3 = _co.boundArray; var currVal_4 = (_co.contextControlName + _v.context.index); var currVal_5 = _co.disabled; var currVal_6 = _co.sorted; var currVal_7 = false; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.classControl; _ck(_v, 1, 0, currVal_0); }); }
function View_AppComboMultilookupComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-text-lookup", [], [[8, "className", 0]], null, null, i2.View_AppTextLookupComponent_0, i2.RenderType_AppTextLookupComponent)), i0.ɵdid(1, 573440, null, 0, i3.AppTextLookupComponent, [i4.LookupSourceService], { contextControlName: [0, "contextControlName"], lookupName: [1, "lookupName"], contextFormGroup: [2, "contextFormGroup"], contextControlId: [3, "contextControlId"], disabled: [4, "disabled"], sorted: [5, "sorted"], required: [6, "required"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "value"; var currVal_2 = _co.lookupName; var currVal_3 = _co.emptyElementForm; var currVal_4 = (_co.contextControlName + "N"); var currVal_5 = _co.disabled; var currVal_6 = _co.sorted; var currVal_7 = false; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.classControl; _ck(_v, 0, 0, currVal_0); }); }
function View_AppComboMultilookupComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "form-group"], ["style", "display:flex;flex-direction:column"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "label", [], [[1, "for", 0]], null, null, null, null)), i0.ɵdid(2, 212992, null, 0, i1.ClrLabel, [[2, i1.ɵbe], [2, i1.ɵbf], [2, i1.ɵbg], i0.Renderer2, i0.ElementRef], { forAttr: [0, "forAttr"] }, null), (_l()(), i0.ɵted(3, null, [" ", " "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppComboMultilookupComponent_5)), i0.ɵdid(5, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppComboMultilookupComponent_6)), i0.ɵdid(7, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵinlineInterpolate(1, "", (_co.contextControlName + "N"), ""); _ck(_v, 2, 0, currVal_1); var currVal_3 = _co.boundArray.controls; _ck(_v, 5, 0, currVal_3); var currVal_4 = !_co.disabled; _ck(_v, 7, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 2).forAttr; _ck(_v, 1, 0, currVal_0); var currVal_2 = _co.itemTitle; _ck(_v, 3, 0, currVal_2); }); }
export function View_AppComboMultilookupComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppComboMultilookupComponent_1)), i0.ɵdid(1, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["showFlexMode", 2]], null, 0, null, View_AppComboMultilookupComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.flexMode; var currVal_1 = i0.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_AppComboMultilookupComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-combo-multilookup", [], null, null, null, View_AppComboMultilookupComponent_0, RenderType_AppComboMultilookupComponent)), i0.ɵdid(1, 573440, null, 0, i6.AppComboMultilookupComponent, [i7.FormBuilder], null, null)], null, null); }
var AppComboMultilookupComponentNgFactory = i0.ɵccf("app-combo-multilookup", i6.AppComboMultilookupComponent, View_AppComboMultilookupComponent_Host_0, { disabled: "disabled", contextControlName: "contextControlName", contextFormGroup: "contextFormGroup", sorted: "sorted", lookupName: "lookupName", itemTitle: "itemTitle", flexMode: "flexMode", classControl: "classControl" }, {}, []);
export { AppComboMultilookupComponentNgFactory as AppComboMultilookupComponentNgFactory };
