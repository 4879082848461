/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./common/edit-animal-common-form.component.ngfactory";
import * as i2 from "./common/edit-animal-common-form.component";
import * as i3 from "@angular/forms";
import * as i4 from "../../logic/services/animal-data.service";
import * as i5 from "../../logic/services/app-navigation.service";
import * as i6 from "../../logic/services/address-person-fio-cache.service";
import * as i7 from "../../logic/services/lookup-source.service";
import * as i8 from "@clr/angular";
import * as i9 from "@angular/common";
import * as i10 from "../../../../node_modules/@clr/angular/clr-angular.ngfactory";
import * as i11 from "./create-animal.component";
import * as i12 from "@angular/router";
import * as i13 from "../../logic/services/bookmark.service";
import * as i14 from "../../logic/services/data-caching.service";
import * as i15 from "../../logic/services/security.service";
var styles_CreateAnimalComponent = [];
var RenderType_CreateAnimalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CreateAnimalComponent, data: {} });
export { RenderType_CreateAnimalComponent as RenderType_CreateAnimalComponent };
function View_CreateAnimalComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-edit-animal-common-form", [], null, null, null, i1.View_EditAnimalCommonFormComponent_0, i1.RenderType_EditAnimalCommonFormComponent)), i0.ɵdid(1, 573440, null, 0, i2.EditAnimalCommonFormComponent, [i3.FormBuilder, i4.AnimalDataService, i5.AppNavigationService, i6.AddressPersonFioCacheService, i5.AppNavigationService, i7.LookupSourceService], { contextFormGroup: [0, "contextFormGroup"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.form; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CreateAnimalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "itech-content-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "clr-icon", [["class", "has-badge"], ["shape", "piggy-bank"], ["size", "36"]], null, null, null, null, null)), i0.ɵdid(2, 16384, null, 0, i8.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, ["\u00A0\u0421\u043E\u0437\u0434\u0430\u043D\u0438\u0435 \u043D\u043E\u0432\u043E\u0439 \u043A\u0430\u0440\u0442\u043E\u0447\u043A\u0438 \u0436\u0438\u0432\u043E\u0442\u043D\u043E\u0433\u043E\n"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CreateAnimalComponent_1)), i0.ɵdid(5, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 14, "p", [], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 6, "button", [["class", "btn btn-primary"]], [[1, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createCard() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_ClrLoadingButton_0, i10.RenderType_ClrLoadingButton)), i0.ɵdid(8, 49152, null, 0, i8.ClrLoadingButton, [i0.ElementRef, i0.Renderer2], { disabled: [0, "disabled"] }, null), i0.ɵprd(2048, null, i8.LoadingListener, null, [i8.ClrLoadingButton]), i0.ɵdid(10, 147456, null, 0, i8.ClrLoading, [[2, i8.LoadingListener]], { loadingState: [0, "loadingState"] }, null), (_l()(), i0.ɵeld(11, 0, null, 0, 1, "clr-icon", [["shape", "check"]], null, null, null, null, null)), i0.ɵdid(12, 16384, null, 0, i8.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, 0, ["\u00A0\u0421\u043E\u0437\u0434\u0430\u0442\u044C "])), (_l()(), i0.ɵeld(14, 0, null, null, 6, "button", [["class", "btn btn-outline-primary"]], [[1, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createCard(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_ClrLoadingButton_0, i10.RenderType_ClrLoadingButton)), i0.ɵdid(15, 49152, null, 0, i8.ClrLoadingButton, [i0.ElementRef, i0.Renderer2], { disabled: [0, "disabled"] }, null), i0.ɵprd(2048, null, i8.LoadingListener, null, [i8.ClrLoadingButton]), i0.ɵdid(17, 147456, null, 0, i8.ClrLoading, [[2, i8.LoadingListener]], { loadingState: [0, "loadingState"] }, null), (_l()(), i0.ɵted(-1, 0, [" \u0421\u043E\u0437\u0434\u0430\u0442\u044C \u0438 \u043F\u0440\u043E\u0434\u043E\u043B\u0436\u0438\u0442\u044C\u00A0"])), (_l()(), i0.ɵeld(19, 0, null, 0, 1, "clr-icon", [["shape", "play"]], null, null, null, null, null)), i0.ɵdid(20, 16384, null, 0, i8.ClrIconCustomTag, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model; _ck(_v, 5, 0, currVal_0); var currVal_2 = _co.creating; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.creating; _ck(_v, 10, 0, currVal_3); var currVal_5 = _co.creating; _ck(_v, 15, 0, currVal_5); var currVal_6 = _co.creating; _ck(_v, 17, 0, currVal_6); }, function (_ck, _v) { var currVal_1 = (i0.ɵnov(_v, 8).disabled ? "" : null); _ck(_v, 7, 0, currVal_1); var currVal_4 = (i0.ɵnov(_v, 15).disabled ? "" : null); _ck(_v, 14, 0, currVal_4); }); }
export function View_CreateAnimalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-create-animal", [], null, null, null, View_CreateAnimalComponent_0, RenderType_CreateAnimalComponent)), i0.ɵdid(1, 114688, null, 0, i11.CreateAnimalComponent, [i12.ActivatedRoute, i12.Router, i13.BookmarkService, i4.AnimalDataService, i14.DataCachingService, i5.AppNavigationService, i15.SecurityService, i3.FormBuilder], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CreateAnimalComponentNgFactory = i0.ɵccf("app-create-animal", i11.CreateAnimalComponent, View_CreateAnimalComponent_Host_0, { model: "model" }, {}, []);
export { CreateAnimalComponentNgFactory as CreateAnimalComponentNgFactory };
