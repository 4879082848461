import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { MetadataService } from '../../../logic/services/metadata.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MetaBrowseBaseComponent } from '../meta-browse.base.component';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { FormHelper } from '../../../ui/controls/form-helper';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';

@Component({
  templateUrl: './expertise-indicator-type-browse.component.html'
})
export class ExpertiseIndicatorTypeBrowseComponent extends MetaBrowseBaseComponent {

  gridColumnDefs = [
    {headerName: '№', field: 'id', width: 100},
    {headerName: 'Наименование', field: 'caption', tooltipField: 'caption', width: 200},
    {headerName: 'Правовой акт', field: 'legalAct', tooltipField: 'legalAct', width: 200},
    {headerName: 'Метод исследования', field: 'method', tooltipField: 'method', width: 200},
    {headerName: 'Нормативное значение', field: 'normativeValue', tooltipField: 'normativeValue', width: 120},
  ];

  constructor(route: ActivatedRoute,
              metadataService: MetadataService,
              alertService: AlertService,
              globalWaitingOverlayService: GlobalWaitingOverlayService,
              lookupService: LookupSourceService,
              bookmarkService: BookmarkService,
              fb: FormBuilder,
              dataCachingService: DataCachingService) {
    super(route, metadataService, alertService, globalWaitingOverlayService, lookupService, fb, bookmarkService, dataCachingService);
  }

  getMetaTitle(): string {
    return 'Виды показателей';
  }

  getMetaName(): string {
    return 'expertise-indicator-type';
  }

  getGroupDef(row: any) {
    return {
      id: [row.id, Validators.compose([Validators.required, Validators.pattern(/^\d{1,5}$/)])],
      caption: [row.caption, Validators.required],
      legalAct: [row.legalAct, Validators.required],
      method: [row.method, Validators.required],
      normativeValue: [row.normativeValue, Validators.compose([Validators.required, FormHelper.validateMoney()])],
    };
  }
}
