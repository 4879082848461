import { Component } from '@angular/core';

@Component({
  selector: 'app-grid-button-status-bar',
  template: `
    <div class="container" *ngIf="params">
      <div>
        <span class="component">
          <button type="button" [style.color]="params.color" (click)="onClick()" [title]="params.title || 'выполнить действие'"
                  class="btn btn-link itech-inline-tool-btn itech-grid-status-bar-button-right">
            <clr-icon *ngIf="params.icon && params.icon === 'copy'" size="22" shape="copy"></clr-icon>
            <clr-icon *ngIf="params.icon && params.icon === 'pinboard'" size="22" shape="pinboard"></clr-icon>
            {{params.caption ? params.caption : ''}}
          </button>
        </span>
      </div>
    </div>
  `,
  styles: [`
      .btn.btn-link.itech-inline-tool-btn.itech-grid-status-bar-button-right {
        width: 100%;
        letter-spacing: normal;
        text-transform: none;
        font-weight: 350;
    }
  `]
})
export class AgGridButtonStatusBarComponent {
  public params;

  // не удалять
  agInit(params: any): void {
    this.params = params;
  }

  onClick(): void {
    if (this.params && this.params.clickCallback) {
      this.params.clickCallback();
    }
  }
}
