<div class="itech-common-wrap no-pager">
  <div>

    <div class="itech-search-table-with-filter" *ngIf="securityService.isAccess('pst-report') | async">
      <div style="display: flex; flex-direction: row;">
        <clr-icon shape="search" size="72"></clr-icon>
        <div class="compact form itech-highlighted-sections" [formGroup]="model.filterFormGroup"
             *ngIf="model && model.filterFormGroup">
          <section class="form-block itech-block-medium">
            <h3>Поиск электронных отчетов</h3>
            <div class="form-group">
              <label for="year">
                Отчетный год
              </label>
              <label for="year" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md"
                     [class.invalid]="isInvalid(model.filterFormGroup, 'year')">
                <input type="text" id="year" class="itech-control-tiny"
                       formControlName="year" (keydown)="filterEnterPressed($event)">
                <app-validation-tooltip [input]="model.filterFormGroup.controls['year']"></app-validation-tooltip>
              </label>
              <div class="radio-inline">
                <input type="radio" id="mode1" formControlName="mode" [value]="1" (change)="refreshResults()">
                <label for="mode1">Текущие к сбору</label>
              </div>
              <div class="radio-inline">
                <input type="radio" id="mode2" formControlName="mode" [value]="2" (change)="refreshResults()">
                <label for="mode2">Последние собранные</label>
              </div>
              <div class="radio-inline">
                <input type="radio" id="mode3" formControlName="mode" [value]="3" (change)="refreshResults()">
                <label for="mode3">Архивные</label>
              </div>
              <div class="radio-inline">
                <input type="radio" id="mode4" formControlName="mode" [value]="4" (change)="refreshResults()">
                <label for="mode4">Все</label>
              </div>
            </div>
            <div class="form-group">
              <label for="reportTypeId">
                Вид отчета
              </label>
              <app-text-lookup [validationTooltipRight]="true" class="itech-control-xlarge"
                               [contextControlId]="'reportTypeId'" [contextControlName]="'reportTypeId'"
                               [sorted]="true"
                               [lookupName]="'pst-report-type'" [contextFormGroup]="model.filterFormGroup">
              </app-text-lookup>
            </div>
            <div class="form-group">
              <label for="regionId">
                Муниципалитет
              </label>
              <app-text-lookup [validationTooltipRight]="true" class="itech-control-medium"
                               [contextControlId]="'regionId'" [contextControlName]="'regionId'"
                               [sorted]="true"
                               [lookupName]="'addr-region'" [contextFormGroup]="model.filterFormGroup">
              </app-text-lookup>
              &nbsp;&nbsp;
              <label for="regionId">
                Хоз. субъект:
              </label>
              <div class="itech-text-field itech-text-field-no-wrap" style="width:176px;max-width:176px"
                   title="{{model.filterFormGroup.controls['agentId'].value | lookup: 'agent' | async}}">
                {{model.filterFormGroup.controls['agentId'].value | lookup: 'agent' | async}}
              </div>
              &nbsp;&nbsp;
              <button type="button" class="btn btn-danger btn-link itech-inline-tool-btn" title="Указать СХП для поиска отчета"
                      (click)="searchFilterAgent()">
                <clr-icon shape="search"></clr-icon>
              </button>
              &nbsp;&nbsp;
              <button *ngIf="model.filterFormGroup.controls['agentId'].value" type="button"
                      class="btn btn-danger btn-link itech-inline-tool-btn" title="Очистить поле"
                      (click)="model.filterFormGroup.controls['agentId'].setValue(null)">
                <clr-icon shape="remove"></clr-icon>
              </button>
            </div>
            <div class="form-group itech-group-whole-line">
              <button class="btn btn-primary" (click)="refreshResults()">
                <clr-icon shape="search" class="has-badge"></clr-icon>&nbsp;Поиск
              </button>
            </div>
          </section>
        </div>
      </div>

      <div style="top: 200px">
        <app-grid [pagination]="false" [columnDefs]="gridColumnDefs" [uniqueGridName]="'PstReportsListGrid'"
                  [rowData]="model.searchResults" (rowClicked)="navigateReport($event.data.id)" [getRowStyle]="getRowStyle"
                  [exportFileName]="'Список электронных отчетов'"></app-grid>
      </div>
    </div>
  </div>

  <div class="itech-form-actions">
<!--    <button class="btn btn-link btn-primary" (click)="appNavigationService.performAgentCreation()">-->
<!--      <clr-icon shape="plus" class="has-badge"></clr-icon>&nbsp;Добавить отчет-->
<!--    </button>-->
  </div>
</div>
