/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@clr/angular/clr-angular.ngfactory";
import * as i2 from "@clr/angular";
import * as i3 from "../../../ui/controls/app-reports-button.component.ngfactory";
import * as i4 from "../../../ui/controls/app-reports-button.component";
import * as i5 from "../../../logic/services/lookup-source.service";
import * as i6 from "../../../logic/services/metadata.service";
import * as i7 from "@angular/forms";
import * as i8 from "../../../logic/services/security.service";
import * as i9 from "../../../logic/services/app-navigation.service";
import * as i10 from "./edit-animal-common-form.component.ngfactory";
import * as i11 from "./edit-animal-common-form.component";
import * as i12 from "../../../logic/services/animal-data.service";
import * as i13 from "../../../logic/services/address-person-fio-cache.service";
import * as i14 from "@angular/common";
import * as i15 from "./edit-animal-common.component";
import * as i16 from "../../../logic/services/bookmark.service";
import * as i17 from "../../../ui/infrastructure/alert.service";
import * as i18 from "../../../logic/services/data-caching.service";
import * as i19 from "../../../ui/infrastructure/global-waiting-overlay.service";
import * as i20 from "../../../logic/services/institution-ticket-data.service";
var styles_EditAnimalCommonComponent = [];
var RenderType_EditAnimalCommonComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EditAnimalCommonComponent, data: {} });
export { RenderType_EditAnimalCommonComponent as RenderType_EditAnimalCommonComponent };
function View_EditAnimalCommonComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 19, "clr-dropdown-menu", [], [[2, "dropdown-menu", null], [1, "role", 0], [2, "is-off-screen", null]], null, null, i1.View_ClrDropdownMenu_0, i1.RenderType_ClrDropdownMenu)), i0.ɵdid(1, 9617408, null, 1, i2.ClrDropdownMenu, [i0.Injector, [2, i2.ɵh], [3, i2.ClrDropdownMenu], i2.ɵl], { position: [0, "position"] }, null), i0.ɵqud(603979776, 1, { items: 1 }), (_l()(), i0.ɵeld(3, 0, null, 0, 2, "label", [["class", "dropdown-header"]], [[1, "for", 0]], null, null, null, null)), i0.ɵdid(4, 212992, null, 0, i2.ClrLabel, [[2, i2.ɵbe], [2, i2.ɵbf], [2, i2.ɵbg], i0.Renderer2, i0.ElementRef], null, null), (_l()(), i0.ɵted(-1, null, ["\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0435"])), (_l()(), i0.ɵeld(6, 0, null, 0, 3, "button", [["class", "btn btn-primary-outline"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createProduct() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "clr-icon", [["shape", "plus"]], null, null, null, null, null)), i0.ɵdid(8, 16384, null, 0, i2.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, ["\u00A0\u0421\u043E\u0437\u0434\u0430\u0442\u044C \u0412\u0421\u0414 "])), (_l()(), i0.ɵeld(10, 0, null, 0, 0, "div", [["class", "dropdown-divider"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, 0, 3, "button", [["class", "btn btn-primary-outline"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigationService.navigateJournal(undefined, 7, _co.id, undefined) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "clr-icon", [["shape", "view-list"]], null, null, null, null, null)), i0.ɵdid(13, 16384, null, 0, i2.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, ["\u00A0\u0416\u0443\u0440\u043D\u0430\u043B "])), (_l()(), i0.ɵeld(15, 0, null, 0, 0, "div", [["class", "dropdown-divider"]], null, null, null, null, null)), (_l()(), i0.ɵeld(16, 0, null, 0, 3, "button", [["class", "btn btn-warning-outline"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteAnimal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(17, 0, null, null, 1, "clr-icon", [["shape", "trash"]], null, null, null, null, null)), i0.ɵdid(18, 16384, null, 0, i2.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, [" \u0423\u0434\u0430\u043B\u0438\u0442\u044C \u043A\u0430\u0440\u0442\u043E\u0447\u043A\u0443 "]))], function (_ck, _v) { var currVal_3 = "top-left"; _ck(_v, 1, 0, currVal_3); _ck(_v, 4, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = "menu"; var currVal_2 = i0.ɵnov(_v, 1).isOffScreen; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = i0.ɵnov(_v, 4).forAttr; _ck(_v, 3, 0, currVal_4); var currVal_5 = _co.contextFormGroup.get("animalSubtypesWithProduct").value.length; _ck(_v, 6, 0, currVal_5); }); }
function View_EditAnimalCommonComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 40, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 22, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "button", [["class", "btn btn-secondary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.refreshFormData() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "clr-icon", [["shape", "refresh"]], null, null, null, null, null)), i0.ɵdid(4, 16384, null, 0, i2.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, ["\u00A0\u041E\u0431\u043D\u043E\u0432\u0438\u0442\u044C "])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "app-reports-button", [], null, null, null, i3.View_AppReportsButtonComponent_0, i3.RenderType_AppReportsButtonComponent)), i0.ɵdid(7, 49152, null, 0, i4.AppReportsButtonComponent, [i5.LookupSourceService, i6.MetadataService, i7.FormBuilder, i8.SecurityService, i9.AppNavigationService], { objId1: [0, "objId1"], dropdownPosition: [1, "dropdownPosition"], reportKind: [2, "reportKind"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 15, "clr-dropdown", [], [[2, "dropdown", null], [2, "open", null]], null, null, i1.View_ClrDropdown_0, i1.RenderType_ClrDropdown)), i0.ɵprd(6144, null, i2.ɵh, null, [i0.ElementRef]), i0.ɵprd(6144, null, i2.ɵr, null, [i2.ɵl]), i0.ɵprd(512, null, i2.ɵd, i2.ɵd, []), i0.ɵprd(1024, null, i2.ɵe, i2.ɵf, [[3, i2.ɵe]]), i0.ɵdid(13, 180224, null, 0, i2.ClrDropdown, [[3, i2.ClrDropdown], i2.ɵd, i0.ChangeDetectorRef, i2.ɵe], null, null), i0.ɵprd(1024, null, i2.ɵo, i2.ɵp, []), i0.ɵprd(1024, null, i2.ɵi, i2.ɵj, [[3, i2.ɵi], i0.Renderer2]), i0.ɵprd(131584, null, i2.ɵl, i2.ɵl, [i2.ɵo, i0.Renderer2, [3, i2.ɵl], i2.ɵd, i2.ɵi, i0.PLATFORM_ID]), (_l()(), i0.ɵeld(17, 0, null, 0, 4, "button", [["class", "btn btn-warning-outline"], ["clrDropdownToggle", ""]], [[2, "dropdown-toggle", null], [2, "dropdown-item", null], [2, "expandable", null], [2, "active", null], [1, "aria-haspopup", 0], [1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 18).onDropdownTriggerClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(18, 16384, null, 0, i2.ClrDropdownTrigger, [i2.ClrDropdown, i2.ɵd, i0.ElementRef, i2.ɵl], null, null), (_l()(), i0.ɵted(-1, null, [" \u0414\u0435\u0439\u0441\u0442\u0432\u0438\u044F "])), (_l()(), i0.ɵeld(20, 0, null, null, 1, "clr-icon", [["shape", "caret down"]], null, null, null, null, null)), i0.ɵdid(21, 16384, null, 0, i2.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_EditAnimalCommonComponent_2)), i0.ɵdid(23, 147456, null, 0, i2.ClrIfOpen, [i2.ɵd, i0.TemplateRef, i0.ViewContainerRef], { open: [0, "open"] }, null), (_l()(), i0.ɵeld(24, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(25, 0, null, null, 3, "button", [["class", "btn btn-secondary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cloneAnimal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(26, 0, null, null, 1, "clr-icon", [["shape", "clone"]], null, null, null, null, null)), i0.ɵdid(27, 16384, null, 0, i2.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, ["\u00A0\u0421\u043E\u0437\u0434\u0430\u0442\u044C \u043A\u043E\u043F\u0438\u044E "])), (_l()(), i0.ɵeld(29, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(30, 0, null, null, 6, "button", [["class", "btn btn-primary itech-form-save"]], [[1, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.store() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ClrLoadingButton_0, i1.RenderType_ClrLoadingButton)), i0.ɵdid(31, 49152, null, 0, i2.ClrLoadingButton, [i0.ElementRef, i0.Renderer2], { disabled: [0, "disabled"] }, null), i0.ɵprd(2048, null, i2.LoadingListener, null, [i2.ClrLoadingButton]), i0.ɵdid(33, 147456, null, 0, i2.ClrLoading, [[2, i2.LoadingListener]], { loadingState: [0, "loadingState"] }, null), (_l()(), i0.ɵeld(34, 0, null, 0, 1, "clr-icon", [["shape", "check"]], null, null, null, null, null)), i0.ɵdid(35, 16384, null, 0, i2.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, 0, ["\u00A0\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C "])), (_l()(), i0.ɵeld(37, 0, null, null, 3, "button", [["class", "btn btn-warning itech-form-cancel"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelEdit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(38, 0, null, null, 1, "clr-icon", [["shape", "undo"]], null, null, null, null, null)), i0.ɵdid(39, 16384, null, 0, i2.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, ["\u00A0\u041E\u0442\u043C\u0435\u043D\u0438\u0442\u044C "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.id; var currVal_1 = "top-left"; var currVal_2 = "animal"; _ck(_v, 7, 0, currVal_0, currVal_1, currVal_2); var currVal_11 = null; _ck(_v, 23, 0, currVal_11); var currVal_13 = ((!_co.contextFormGroup || !_co.contextFormGroup.dirty) || _co.storing); _ck(_v, 31, 0, currVal_13); var currVal_14 = _co.storing; _ck(_v, 33, 0, currVal_14); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = true; var currVal_4 = i0.ɵnov(_v, 13).ifOpenService.open; _ck(_v, 8, 0, currVal_3, currVal_4); var currVal_5 = i0.ɵnov(_v, 18).isRootLevelToggle; var currVal_6 = !i0.ɵnov(_v, 18).isRootLevelToggle; var currVal_7 = !i0.ɵnov(_v, 18).isRootLevelToggle; var currVal_8 = i0.ɵnov(_v, 18).active; var currVal_9 = "menu"; var currVal_10 = i0.ɵnov(_v, 18).active; _ck(_v, 17, 0, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_12 = (i0.ɵnov(_v, 31).disabled ? "" : null); _ck(_v, 30, 0, currVal_12); var currVal_15 = (!_co.contextFormGroup || !_co.contextFormGroup.dirty); _ck(_v, 37, 0, currVal_15); }); }
function View_EditAnimalCommonComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 6, "button", [["class", "btn btn-primary itech-form-save"]], [[1, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirmAnimalFromExternal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ClrLoadingButton_0, i1.RenderType_ClrLoadingButton)), i0.ɵdid(3, 49152, null, 0, i2.ClrLoadingButton, [i0.ElementRef, i0.Renderer2], { disabled: [0, "disabled"] }, null), i0.ɵprd(2048, null, i2.LoadingListener, null, [i2.ClrLoadingButton]), i0.ɵdid(5, 147456, null, 0, i2.ClrLoading, [[2, i2.LoadingListener]], { loadingState: [0, "loadingState"] }, null), (_l()(), i0.ɵeld(6, 0, null, 0, 1, "clr-icon", [["shape", "check"]], null, null, null, null, null)), i0.ɵdid(7, 16384, null, 0, i2.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, 0, ["\u00A0\u041F\u043E\u0434\u0442\u0432\u0435\u0440\u0434\u0438\u0442\u044C "])), (_l()(), i0.ɵeld(9, 0, null, null, 3, "button", [["class", "btn btn-warning itech-form-cancel"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.rejectAnimalExternal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "clr-icon", [["shape", "times"]], null, null, null, null, null)), i0.ɵdid(11, 16384, null, 0, i2.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, ["\u00A0\u041E\u0442\u043A\u0430\u0437\u0430\u0442\u044C "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((!_co.contextFormGroup || _co.storing) || !_co.isExternalAnimal); _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.storing; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i0.ɵnov(_v, 3).disabled ? "" : null); _ck(_v, 2, 0, currVal_0); var currVal_3 = ((!_co.contextFormGroup || _co.storing) || !_co.isExternalAnimal); _ck(_v, 9, 0, currVal_3); }); }
export function View_EditAnimalCommonComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "itech-common-wrap"], ["id", "interceptNoAccess"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["id", "animal-common-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-edit-animal-common-form", [], null, [[null, "onNavigateEventsClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onNavigateEventsClicked" === en)) {
        var pd_0 = (_co.navigateToEventsPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_EditAnimalCommonFormComponent_0, i10.RenderType_EditAnimalCommonFormComponent)), i0.ɵdid(3, 573440, null, 0, i11.EditAnimalCommonFormComponent, [i7.FormBuilder, i12.AnimalDataService, i9.AppNavigationService, i13.AddressPersonFioCacheService, i9.AppNavigationService, i5.LookupSourceService], { contextFormGroup: [0, "contextFormGroup"] }, { onNavigateEventsClicked: "onNavigateEventsClicked" }), (_l()(), i0.ɵeld(4, 0, null, null, 3, "div", [["class", "itech-form-actions"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EditAnimalCommonComponent_1)), i0.ɵdid(6, 16384, null, 0, i14.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["animalExternalActions", 2]], null, 0, null, View_EditAnimalCommonComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contextFormGroup; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.isExternalAnimal; var currVal_2 = i0.ɵnov(_v, 7); _ck(_v, 6, 0, currVal_1, currVal_2); }, null); }
export function View_EditAnimalCommonComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-edit-animal-common", [], null, null, null, View_EditAnimalCommonComponent_0, RenderType_EditAnimalCommonComponent)), i0.ɵdid(1, 573440, null, 0, i15.EditAnimalCommonComponent, [i16.BookmarkService, i12.AnimalDataService, i17.AlertService, i13.AddressPersonFioCacheService, i18.DataCachingService, i19.GlobalWaitingOverlayService, i7.FormBuilder, i9.AppNavigationService, i20.InstitutionTicketDataService], null, null)], null, null); }
var EditAnimalCommonComponentNgFactory = i0.ɵccf("app-edit-animal-common", i15.EditAnimalCommonComponent, View_EditAnimalCommonComponent_Host_0, { id: "id", newAgentId: "newAgentId", newStableId: "newStableId", isExternalAnimal: "isExternalAnimal" }, { onNavigateEventsClicked: "onNavigateEventsClicked" }, []);
export { EditAnimalCommonComponentNgFactory as EditAnimalCommonComponentNgFactory };
