<div class="itech-search-table-with-filter" *ngIf="securityService.isAccess('animal') | async">
  <div style="display: flex; flex-direction: row;">
    <clr-icon shape="search" size="72"></clr-icon>
    <div class="compact form itech-highlighted-sections" [formGroup]="model.filterFormGroup"
         *ngIf="model && model.filterFormGroup">
      <section class="form-block itech-block-medium">
        <h3>Просмотр журнала предубойного осмотра</h3>
        <div class="form-group">
          <label for="institutionId">Убойный пункт</label>
          <app-combo-lookup [validationTooltipRight]="true" class="itech-control-xlarge"
                            [contextControlId]="'institutionId'"
                            [contextControlName]="'institutionId'"
                            [lookupName]="'slaughtery-institution'" [contextFormGroup]="model.filterFormGroup"></app-combo-lookup>
        </div>
        <div class="form-group">
          <label for="date">Дата</label>
          <label for="date" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md"
                 [class.invalid]="isInvalid(model.filterFormGroup, 'date')">
            <input type="date" id="date" class="itech-control-normal"
                   formControlName="date">
            <app-validation-tooltip
              [input]="model.filterFormGroup.controls['date']"></app-validation-tooltip>
          </label>
        </div>
        <div class="form-group itech-group-whole-line">
          <button class="btn btn-primary" (click)="refreshResults()">
            <clr-icon shape="search" class="has-badge"></clr-icon>&nbsp;Поиск
          </button>
        </div>
      </section>
    </div>
  </div>

  <div>
    <app-grid [pagination]="false" [rowData]="model.searchResults" [columnDefs]="gridColumnDefs" [getRowStyle]="getRowStyle"
              (rowClicked)="rowClicked.emit($event.data)" (selectionChanged)="onGridSelectionChanged($event)"
              [uniqueGridName]="'SlaughteryViewFormGrid'" [exportFileName]="'Журнал предубойного осмотра'"></app-grid>
  </div>
</div>
