import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { DrugSearchResultsFormComponent } from './drug-search-results-form.component';
import { SecurityService } from '../../../logic/services/security.service';

@Component({
  selector: 'app-drug-search-results',
  templateUrl: './drug-search-results.component.html'
})
export class DrugSearchResultsComponent implements OnInit {

  model = DrugSearchResultsFormComponent.getDefaultModel();

  constructor(private route: ActivatedRoute,
              private securityService: SecurityService,
              private dataCachingService: DataCachingService,
              public appNavigationService: AppNavigationService,
              private fb: FormBuilder) {
  }

  ngOnInit() {

    this.route.paramMap
      .subscribe(params => {

        const existing = this.dataCachingService.getCachedData('DrugSearchResults', '1');

        if (existing) {
          this.model = existing;
        } else {
          this.securityService.getUserInfo().subscribe(userInfo => {
            this.model = DrugSearchResultsFormComponent.getDefaultModel(this.fb);
            this.model.filterFormGroup.get('institutionId').setValue(userInfo.institutionId);
            this.dataCachingService.addToCache('DrugSearchResults', '1', this.model);
          });
        }
      });
  }
}
