import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/internal/operators';
import { StringHelper } from '../../helpers/string-helper';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AnimalDataService = /** @class */ (function () {
    function AnimalDataService(http) {
        this.http = http;
    }
    AnimalDataService.prototype.searchByAgent = function (agentId, showHistory) {
        showHistory = !!showHistory;
        var qstring = environment.api + '/animal/search-by-agent/' + agentId + '/' + showHistory;
        return this.http.get(qstring, { headers: { 'interceptNoAccess': 'true' } })
            .pipe(map(function (response) {
            return response;
        }));
    };
    AnimalDataService.prototype.searchByStable = function (stableId, showHistory) {
        showHistory = !!showHistory;
        var qstring = environment.api + '/animal/search-by-stable/' + stableId + '/' + showHistory;
        return this.http.get(qstring, { headers: { 'interceptNoAccess': 'true' } })
            .pipe(map(function (response) {
            return response;
        }));
    };
    AnimalDataService.prototype.search = function (params) {
        var qstring = environment.api + '/animal/search';
        return this.http.post(qstring, params)
            .pipe(map(function (response) {
            return response;
        }));
    };
    AnimalDataService.prototype.viewSlaughtery = function (params) {
        var qstring = environment.api + ("/animal/events/report-slaughtery/" + (params.institutionId || '0') + "/" + params.date);
        return this.http.get(qstring)
            .pipe(map(function (response) {
            return response;
        }));
    };
    AnimalDataService.prototype.getAnimalCommonForEdit = function (id) {
        var qstring = environment.api + '/animal/' + id.toString(10);
        return this.http.get(qstring, { headers: { 'interceptNoAccess': 'true' } });
    };
    AnimalDataService.prototype.getTempAnimalCommonForEdit = function (id) {
        var qstring = environment.api + '/animal/external/' + id.toString(10);
        return this.http.get(qstring, { headers: { 'interceptNoAccess': 'true' } });
    };
    AnimalDataService.prototype.getAnimalEventForEdit = function (id) {
        var qstring = environment.api + '/animal/event/' + id.toString(10);
        return this.http.get(qstring, { headers: { 'interceptNoAccess': 'true' } });
    };
    AnimalDataService.prototype.getAnimalCommonForLookup = function (id) {
        var qstring = environment.api + '/animal/lookup/' + id.toString(10);
        return this.http.get(qstring);
    };
    AnimalDataService.prototype.getAnimalCommonForLookup2 = function (ids) {
        var qstring = environment.api + '/animal/lookup';
        return this.http.post(qstring, ids)
            .pipe(map(function (response) {
            return response;
        }));
    };
    AnimalDataService.prototype.getAnimalExternalForLookup = function (id) {
        var qstring = environment.api + '/animal/external/lookup/' + id.toString(10);
        return this.http.get(qstring);
    };
    AnimalDataService.prototype.getAnimalEventCommonForLookup = function (id) {
        var qstring = environment.api + '/animal/event/lookup/' + id.toString(10);
        return this.http.get(qstring);
    };
    AnimalDataService.prototype.storeAnimalCommonInfo = function (model) {
        var qstring = environment.api + '/animal/store-common';
        return this.http.post(qstring, model)
            .pipe(map(function (response, error) {
            return response.data.id;
        }));
    };
    AnimalDataService.prototype.storeAnimalEvent = function (model) {
        var qstring = environment.api + '/animal/store-event';
        return this.http.post(qstring, model)
            .pipe(map(function (response, error) {
            return response.data;
        }));
    };
    AnimalDataService.prototype.createAnimalEvents = function (model) {
        var qstring = environment.api + '/animal/create-events';
        return this.http.post(qstring, model)
            .pipe(map(function (response, error) {
            return response.data;
        }));
    };
    AnimalDataService.prototype.createAnimalEventsLazy = function (model) {
        var qstring = environment.api + '/animal/create-events/lazy';
        return this.http.post(qstring, model)
            .pipe(map(function (response, error) {
            return response.data;
        }));
    };
    AnimalDataService.prototype.deleteAnimalEvent = function (id) {
        var qstring = environment.api + '/animal/delete-event/' + id;
        return this.http.post(qstring, undefined);
    };
    AnimalDataService.prototype.deleteAnimal = function (id) {
        var qstring = environment.api + '/animal/delete/' + id;
        return this.http.post(qstring, undefined);
    };
    AnimalDataService.prototype.readAnimalEvents = function (animalId) {
        var qstring = environment.api + '/animal/animal-events/' + animalId;
        return this.http.get(qstring, { headers: { 'interceptNoAccess': 'true' } })
            .pipe(map(function (response) {
            return response;
        }));
    };
    AnimalDataService.prototype.getAnimalCountsOnDate = function (animalId, date, cutoffEventId) {
        var qstring = environment.api + '/animal/events/counts-on-date/' + animalId.toString(10) + '/' +
            StringHelper.getISODate(date) + (cutoffEventId ? '/' + cutoffEventId.toString(10) : '');
        return this.http.get(qstring)
            .pipe(map(function (response) {
            return response;
        }));
    };
    AnimalDataService.prototype.getAllAnimalEventPlans = function () {
        var qstring = environment.api + '/animal-event-plan/all';
        return this.http.get(qstring)
            .pipe(map(function (response) {
            return response;
        }));
    };
    AnimalDataService.prototype.getAnimalEventPlanValues = function (year, regionId) {
        var qstring = environment.api + '/animal-event-plan/values/' + year + '/' + regionId;
        return this.http.get(qstring)
            .pipe(map(function (response) {
            return response.data;
        }));
    };
    AnimalDataService.prototype.getAnimalEventPlanForEdit = function (year) {
        var qstring = environment.api + '/animal-event-plan/' + year;
        return this.http.get(qstring);
    };
    AnimalDataService.prototype.insertAnimalEventPlan = function (model) {
        var qstring = environment.api + '/animal-event-plan/insert-common';
        return this.http.post(qstring, model)
            .pipe(map(function (response, error) {
            return response.data.id;
        }));
    };
    AnimalDataService.prototype.updateAnimalEventPlan = function (model) {
        var qstring = environment.api + '/animal-event-plan/update-common';
        return this.http.post(qstring, model)
            .pipe(map(function (response, error) {
            return response.data.id;
        }));
    };
    AnimalDataService.prototype.updateAnimalEventPlanValues = function (year, regionId, model) {
        var qstring = environment.api + '/animal-event-plan/update-values/' + year + '/' + regionId;
        return this.http.post(qstring, model);
    };
    AnimalDataService.prototype.confirmAnimalExternal = function (animalExternalId) {
        var qstring = environment.api + '/animal/external/confirm/' + animalExternalId.toString(10);
        return this.http.get(qstring)
            .pipe(map(function (response, error) {
            return response.data;
        }));
    };
    AnimalDataService.prototype.confirmManyAnimalExternal = function (data) {
        var qstring = environment.api + "/animal/external/confirm";
        return this.http.post(qstring, data)
            .pipe(map(function (response, error) {
            return response.data;
        }));
    };
    AnimalDataService.prototype.rejectAnimalExternal = function (animalExternalId) {
        var qstring = environment.api + '/animal/external/reject/' + animalExternalId.toString(10);
        return this.http.get(qstring)
            .pipe(map(function (response, error) {
            return response.data;
        }));
    };
    AnimalDataService.ngInjectableDef = i0.defineInjectable({ factory: function AnimalDataService_Factory() { return new AnimalDataService(i0.inject(i1.HttpClient)); }, token: AnimalDataService, providedIn: "root" });
    return AnimalDataService;
}());
export { AnimalDataService };
