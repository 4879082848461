import { AlertService } from '../../../ui/infrastructure/alert.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { FormBuilder } from '@angular/forms';
import { InstitutionDataService } from '../../../logic/services/institution-data.service';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { StringHelper } from '../../../helpers/string-helper';
var EditInstitutionIndicatorsComponent = /** @class */ (function () {
    function EditInstitutionIndicatorsComponent(institutionDataService, alertService, dataCachingService, waitingOverlay, lookupSourceService, fb, navigationService) {
        this.institutionDataService = institutionDataService;
        this.alertService = alertService;
        this.dataCachingService = dataCachingService;
        this.waitingOverlay = waitingOverlay;
        this.lookupSourceService = lookupSourceService;
        this.fb = fb;
        this.navigationService = navigationService;
        this.model = {};
        this.storing = false;
    }
    Object.defineProperty(EditInstitutionIndicatorsComponent.prototype, "contextFormGroup", {
        get: function () {
            return this.model.contextFormGroup;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditInstitutionIndicatorsComponent.prototype, "id", {
        get: function () {
            return this._id;
        },
        set: function (id) {
            if (this._id !== id) {
                this._id = id;
                this.refreshFormDataInternal();
            }
        },
        enumerable: true,
        configurable: true
    });
    EditInstitutionIndicatorsComponent.prototype.isInvalid = function (fg, cname) {
        return FormHelper.isInvalid(fg, cname);
    };
    EditInstitutionIndicatorsComponent.prototype.ensureFormGroup = function (indicatorTypesList, indicatorSubtypesLookup, arr) {
        var _this = this;
        if (arr === void 0) { arr = []; }
        if (!this.model.contextFormGroup) {
            var contextFormGroupLookup_1 = {};
            this.model.contextFormGroup = this.fb.group({
                indicators: this.buildFormArray(arr, contextFormGroupLookup_1)
            });
            this.model.indicators = indicatorTypesList.map(function (indicatorType) {
                return {
                    indicatorType: indicatorType,
                    indicators: _this.mapIndicatorSubtypes(_this.model.contextFormGroup, contextFormGroupLookup_1, indicatorSubtypesLookup, indicatorType.id)
                };
            });
        }
    };
    EditInstitutionIndicatorsComponent.prototype.mapIndicatorSubtypes = function (contextFormGroup, contextFormGroupLookup, indicatorSubtypesLookup, indicatorTypeId) {
        var _this = this;
        return (indicatorSubtypesLookup[indicatorTypeId] || []).map(function (indicatorSubtype) {
            var subtypeIndicators = contextFormGroupLookup['' + indicatorTypeId + ':' + indicatorSubtype.id] || [];
            var info = {
                indicatorSubtype: indicatorSubtype,
                indicators: subtypeIndicators,
                last: subtypeIndicators.length > 0 ? subtypeIndicators[subtypeIndicators.length - 1] : undefined,
                next: _this.fb.group(_this.getIndicatorGroupDef({
                    date: StringHelper.getISODate(new Date()),
                    indicatorTypeId: indicatorSubtype.indicatorTypeId,
                    indicatorSubtypeId: indicatorSubtype.id,
                })),
                nextIsBound: false,
            };
            info.next.valueChanges.subscribe(function () {
                if (!info.nextIsBound) {
                    contextFormGroup.get('indicators').push(info.next);
                    contextFormGroup.markAsDirty();
                    info.indicators.push(info.next);
                    info.nextIsBound = true;
                }
            });
            return info;
        });
    };
    EditInstitutionIndicatorsComponent.prototype.getIndicatorGroupDef = function (val) {
        if (val === void 0) { val = {}; }
        return {
            id: val.id,
            date: [val.date, FormHelper.validateDateTimePicker()],
            indicatorTypeId: val.indicatorTypeId,
            indicatorSubtypeId: val.indicatorSubtypeId,
            value: [val.value, FormHelper.validateMoney()],
        };
    };
    EditInstitutionIndicatorsComponent.prototype.buildFormArray = function (indicatorsData, contextFormGroupLookup) {
        var _this = this;
        indicatorsData.sort(function (x, y) { return x.date.localeCompare(y.date); });
        return this.fb.array(indicatorsData.map(function (item) {
            var group = _this.fb.group(_this.getIndicatorGroupDef(item));
            var key = '' + item.indicatorTypeId + ':' + item.indicatorSubtypeId;
            if (!contextFormGroupLookup[key]) {
                contextFormGroupLookup[key] = [];
            }
            contextFormGroupLookup[key].push(group);
            return group;
        }));
    };
    EditInstitutionIndicatorsComponent.prototype.store = function () {
        var _this = this;
        if (!this.contextFormGroup.valid) {
            return;
        }
        this.storing = true;
        this.institutionDataService.storeInstitutionIndicators(this.id, this.contextFormGroup.value.indicators, this.model.delete)
            .subscribe({
            next: function () {
                _this.storing = false;
                // обязательно обновляем форму из БД - чтобы загрузить идшники для новых бранчей
                _this.refreshFormData();
            },
            error: function () {
                _this.storing = false;
            }
        });
    };
    EditInstitutionIndicatorsComponent.prototype.cancelEdit = function () {
        this.dataCachingService.removeCachedData('EditInstitutionIndicators', this.id.toString());
        this.refreshFormDataInternal();
    };
    EditInstitutionIndicatorsComponent.prototype.refreshFormData = function () {
        this.dataCachingService.removeCachedData('EditInstitutionIndicators', this.id.toString());
        this.refreshFormDataInternal();
    };
    EditInstitutionIndicatorsComponent.prototype.refreshFormDataInternal = function () {
        var _this = this;
        this.waitingOverlay.StartWaiting();
        var existing = this.dataCachingService.getCachedData('EditInstitutionIndicators', this.id.toString());
        if (existing) {
            this.model = existing;
            this.waitingOverlay.EndWaiting();
        }
        else {
            this.lookupSourceService.getLookup('institution-indicator-type', true, true).subscribe(function (indicatorTypes) {
                _this.lookupSourceService.getLookupObj('institution-indicator-subtype').subscribe(function (indicatorSubtypes) {
                    _this.institutionDataService.getInstitutionIndicatorsForEdit(_this.id).subscribe({
                        next: function (data) {
                            _this.model = { id: _this.id, delete: [] };
                            _this.ensureFormGroup(indicatorTypes, indicatorSubtypes, data);
                            _this.dataCachingService.addToCache('EditInstitutionIndicators', _this.id.toString(), _this.model);
                            _this.waitingOverlay.EndWaiting();
                        }, error: function () {
                            _this.waitingOverlay.EndWaiting();
                        }
                    });
                });
            });
        }
    };
    EditInstitutionIndicatorsComponent.prototype.showHistory = function (indicator) {
        indicator.showHistory = true;
    };
    EditInstitutionIndicatorsComponent.prototype.deleteIndicator = function (indicator, history) {
        var indicatorsFA = this.model.contextFormGroup.get('indicators');
        var index = indicatorsFA.controls.findIndex(function (x) { return x === history; });
        if (index >= 0) {
            indicatorsFA.removeAt(index);
            indicatorsFA.markAsDirty();
            var idToDelete = history.get('id').value;
            if (idToDelete) {
                this.model.delete.push(idToDelete);
            }
        }
        var index2 = indicator.indicators.findIndex(function (x) { return x === history; });
        if (index2 >= 0) {
            indicator.indicators.splice(index2, 1);
            indicator.last = indicator.indicators.length > 0 ? indicator.indicators[indicator.indicators.length - 1] : undefined;
            indicator.next = this.fb.group(this.getIndicatorGroupDef({
                date: StringHelper.getISODate(new Date()),
                indicatorTypeId: indicator.indicatorSubtype.indicatorTypeId,
                indicatorSubtypeId: indicator.indicatorSubtype.id,
            }));
        }
    };
    return EditInstitutionIndicatorsComponent;
}());
export { EditInstitutionIndicatorsComponent };
