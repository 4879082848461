import * as tslib_1 from "tslib";
import { PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
var RDatePipe = /** @class */ (function (_super) {
    tslib_1.__extends(RDatePipe, _super);
    function RDatePipe() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RDatePipe.prototype.transform = function (value, args) {
        if (args) {
            return _super.prototype.transform.call(this, value, 'yyyy г/р.');
        }
        else {
            return _super.prototype.transform.call(this, value, 'dd.MM.yyyy');
        }
    };
    return RDatePipe;
}(DatePipe));
export { RDatePipe };
