<div class="compact form" [formGroup]="contextFormGroup">
  <section class="form-block itech-block-medium" [formGroup]="contextFormGroup">

    <div *ngIf="!expertiseId"
         class="form-group itech-group-whole-line">
      <label for="relatedCreate"></label>
      <div class="checkbox" style="margin-bottom: 0">
        <input type="checkbox" id="relatedCreate" formControlName="relatedCreate">
        <label for="relatedCreate">Создать для других выработанных продукций с таким же подвидом на всё количество</label>
      </div>
      <div *ngIf="(contextFormGroup.get('relatedProductExpertiseIds').value || []).length">
        <app-link-button *ngFor="let otherProductExpertiseId of contextFormGroup.get('relatedProductExpertiseIds').value"
                         [id]="otherProductExpertiseId" [lookupName]="'product-expertise'"
                         class="itech-link-button-no-margin"></app-link-button>
      </div>
    </div>

    <div *ngIf="expertiseId && (contextFormGroup.get('relatedProductExpertiseIds').value || []).length"
         class="form-group itech-group-whole-line">
      <label for="excludeByRelated"></label>
      <div class="checkbox" style="margin-bottom: 0">
        <input type="checkbox" id="excludeByRelated" formControlName="excludeByRelated">
        <label for="excludeByRelated">Исключить из связанных</label>
      </div>
    </div>

    <div *ngIf="(contextFormGroup.get('relatedProductExpertiseIds').value || []).length"
         class="form-group">
      <label>Связанные экспертизы:</label>
      <div style="padding-top:0.2rem">
        <div *ngFor="let otherProductExpertiseId of contextFormGroup.get('relatedProductExpertiseIds').value">
          <app-link-button [id]="otherProductExpertiseId" [lookupName]="'product-expertise'"
                           class="itech-link-button-no-margin"></app-link-button>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="expertiseTime" class="required">Время проведения</label>
      <app-datetime-picker [contextControlName]="'expertiseTime'" [contextFormGroup]="contextFormGroup"
                           id="expertiseTime"></app-datetime-picker>
    </div>

    <div class="form-group">
      <label for="laboratoryEventTypeId" class="required">Вид исследования</label>
      <app-text-lookup class="itech-control-xlarge" [contextControlId]="'laboratoryEventTypeId'" [sorted]="true"
                       [contextControlName]="'laboratoryEventTypeId'" [lookupName]="'event-type/exam'"
                       [contextFormGroup]="contextFormGroup"></app-text-lookup>
    </div>

    <div class="form-group">
      <label for="value" class="required">Кол-во / ед. изм.</label>
      <label for="value" aria-haspopup="true" role="tooltip"
             class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
             [class.invalid]="isInvalid('value')">
        <input type="text" id="value" placeholder="Кол-во" class="itech-control-small"
               formControlName="value">
        <app-validation-tooltip [input]="contextFormGroup.controls['value']"></app-validation-tooltip>
      </label>
      <app-combo-lookup [validationTooltipRight]="true" class="itech-control-large"
                        [contextControlId]="'unitId'"
                        [contextControlName]="'unitId'" [sorted]="true"
                        [lookupName]="'drug-unit-type/product'" [contextFormGroup]="contextFormGroup"></app-combo-lookup>
    </div>

    <div class="form-group">
      <label></label>
      <div class="itech-control-desc">Укажите объем продукции, направленной на экспертизу</div>
    </div>

    <div class="form-group">
      <label for="packagesCount">Кол-во упаковок</label>
      <label for="packagesCount" aria-haspopup="true" role="tooltip"
             class="tooltip tooltip-validation tooltip-md"
             [class.invalid]="isInvalid('packagesCount')">
        <input type="text" id="packagesCount" placeholder="Кол-во" class="itech-control-small"
               formControlName="packagesCount">
        <app-validation-tooltip [input]="contextFormGroup.controls['packagesCount']"></app-validation-tooltip>
      </label>
    </div>

    <div class="form-group">
      <label></label>
      <div class="itech-control-desc">Укажите упаковку продукции, направленной на экспертизу</div>
    </div>

    <div class="form-group">
      <label class="required">Лаборатория</label>
      <app-combo-lookup [validationTooltipRight]="true" class="itech-control-xlarge"
                        [contextControlName]="'laboratoryInstitutionId'"
                        [lookupName]="'lab-institution'" [contextFormGroup]="contextFormGroup"></app-combo-lookup>
    </div>

    <div class="form-group" *ngIf="+contextFormGroup.get('laboratoryEventTypeId').value !== 36">
      <label for="productExpertiseTypeId" class="required">Вид экспертизы</label>
      <app-combo-lookup [validationTooltipRight]="true" class="itech-control-xlarge"
                        [contextControlId]="'productExpertiseTypeId'" [sorted]="true"
                        [contextControlName]="'productExpertiseTypeId'"
                        [lookupName]="'product-expertise-type'" [contextFormGroup]="contextFormGroup"></app-combo-lookup>
    </div>

    <div class="form-group" *ngIf="+contextFormGroup.get('laboratoryEventTypeId').value !== 36 &&
                                    contextFormGroup.get('productExpertiseTypeId').value">
      <label for="productExpertiseSubtypeId" class="required">Подвид экспертизы</label>
      <app-combo-lookup [validationTooltipRight]="true" class="itech-control-xlarge"
                        [contextControlId]="'productExpertiseSubtypeId'" [sorted]="true"
                        [contextControlName]="'productExpertiseSubtypeId'"
                        [lookupName]="'product-expertise-subtype' + contextFormGroup.get('productExpertiseTypeId').value"
                        [contextFormGroup]="contextFormGroup"></app-combo-lookup>
    </div>

    <div class="form-group">
      <label for="expertisePlace">Место проведения</label>
      <app-combo-lookup class="itech-control-xlarge" [contextControlId]="'expertisePlace'" [sorted]="true"
                        [contextControlName]="'expertisePlace'" [lookupName]="'product-expertise-place'"
                        [contextFormGroup]="contextFormGroup"></app-combo-lookup>
    </div>

    <div class="form-group">
      <label for="diagnosticTypeId">Способ диагностики</label>
      <app-combo-lookup class="itech-control-xlarge" [contextControlId]="'diagnosticTypeId'" [sorted]="true"
                        [contextControlName]="'diagnosticTypeId'" [lookupName]="'diagnostic-type'"
                        [contextFormGroup]="contextFormGroup"></app-combo-lookup>
    </div>

    <div class="form-group">
      <label for="testMaterialId">Исследуемый материал</label>
      <app-combo-lookup class="itech-control-xlarge" [contextControlId]="'testMaterialId'" [sorted]="true"
                        [contextControlName]="'testMaterialId'" [lookupName]="'test-material'"
                        [contextFormGroup]="contextFormGroup"></app-combo-lookup>
    </div>

    <ng-container *ngIf="contextFormGroup.get('laboratoryEventTypeId').value != 36">
      <div class="form-group">
        <label for="examResult">Результат</label>
        <app-combo-lookup class="itech-control-xlarge" [contextControlId]="'examResult'" [sorted]="true"
                          [contextControlName]="'examResult'" [lookupName]="'exam-result'"
                          [contextFormGroup]="contextFormGroup"></app-combo-lookup>
      </div>

      <div class="form-group" *ngIf="contextFormGroup.get('examResult').value == 1">
        <label for="diseaseTypeId">Заболевание</label>
        <app-text-lookup class="itech-control-xlarge" [contextControlId]="'diseaseTypeId'" [sorted]="true"
                         [contextControlName]="'diseaseTypeId'" [lookupName]="'disease-type'" [commentsFieldName]="'kind'"
                         [contextFormGroup]="contextFormGroup"></app-text-lookup>
      </div>
    </ng-container>

  </section>
</div>
<app-edit-expertise-indicators *ngIf="contextFormGroup.get('mercuryNumber').value"
                               [contextFormGroup]="contextFormGroup" [expertiseId]="expertiseId"
                               [productId]="productId"></app-edit-expertise-indicators>

<div class="compact form" [formGroup]="contextFormGroup" *ngIf="contextFormGroup.get('mercuryNumber').value">
  <section class="form-block itech-block-medium itech-expertise-area" [formGroup]="contextFormGroup">

    <h1>Заключение</h1>

    <div class="form-group itech-double-height">
      <label for="conclusionText">Цель</label>
      <app-text-lookup class="itech-control-xlarge" [contextControlId]="'conclusionReasonId'" [sorted]="true"
                       [contextControlName]="'conclusionReasonId'" [lookupName]="'product-reason/with-mercury-id'"
                       [contextFormGroup]="contextFormGroup"></app-text-lookup>
    </div>

    <div class="form-group">
      <label for="conclusionDate">Дата решения</label>
      <label aria-haspopup="true" role="tooltip"
             class="tooltip tooltip-validation tooltip-md tooltip-right">
        <label for="conclusionDate" aria-haspopup="true" role="tooltip"
               class="tooltip tooltip-validation tooltip-md tooltip-right"
               [class.invalid]="isInvalid('conclusionDate')">
          <input formControlName="conclusionDate" type="date" id="conclusionDate">
          <app-validation-tooltip [input]="contextFormGroup.controls['conclusionDate']"></app-validation-tooltip>
        </label>
      </label>
    </div>

    <div class="form-group itech-double-height">
      <label for="conclusionText">Результат</label>
      <app-combo-lookup class="itech-control-xlarge" [contextControlId]="'conclusionResultTypeId'" [sorted]="true"
                        [contextControlName]="'conclusionResultTypeId'" [lookupName]="'expertise-final-result'"
                        [contextFormGroup]="contextFormGroup"></app-combo-lookup>
    </div>

    <div class="form-group itech-double-height">
      <label for="conclusionText">Текст заключения</label>
      <label for="conclusionText"
             aria-haspopup="true"
             role="tooltip"
             class="tooltip tooltip-validation tooltip-md sizable"
             [class.invalid]="contextFormGroup.controls['conclusionText'].invalid &&
                              (contextFormGroup.controls['conclusionText'].dirty ||
                               contextFormGroup.controls['conclusionText'].touched || contextFormGroup.submitted)">
          <app-input-text [contextId]="'conclusionText'" [classNames]="'itech-control-xlarge sizable scrollable'"
                          [formControlObj]="contextFormGroup.get('conclusionText')" [htmlType]="2"
                          [placeholder]="'Введите текст экспертного заключения'"></app-input-text>
        <app-validation-tooltip [input]="contextFormGroup.controls['conclusionText']"></app-validation-tooltip>
      </label>
    </div>
  </section>
</div>
