<clr-tabs>
  <clr-tab>
    <button clrTabLink>Общее</button>
    <ng-template [(clrIfActive)]="model.commonTabActive">
      <clr-tab-content>
        <div class="edit-page-tab">
          <app-edit-animal-common [id]="id" [newAgentId]="newAgentId" [newStableId]="newStableId" [isExternalAnimal]="isExternalAnimal"
           (onNavigateEventsClicked)="navigateEventsTab()"></app-edit-animal-common>
        </div>
      </clr-tab-content>
    </ng-template>
  </clr-tab>
  <clr-tab>
    <button clrTabLink><clr-icon shape="color-picker"></clr-icon>Мероприятия/События</button>
    <ng-template [(clrIfActive)]="model.eventsTabActive">
      <clr-tab-content>
        <div class="edit-page-tab">
          <app-edit-animal-events [id]="id" [isExternal]="isExternalAnimal"></app-edit-animal-events>
        </div>
      </clr-tab-content>
    </ng-template>
  </clr-tab>
  <clr-tab>
    <button clrTabLink><clr-icon shape="truck"></clr-icon>Продукция/Документы</button>
    <ng-template [(clrIfActive)]="model.productsTabActive">
      <clr-tab-content>
        <div class="edit-page-tab">
          <app-edit-animal-products [id]="id" [isExternal]="isExternalAnimal"></app-edit-animal-products>
        </div>
      </clr-tab-content>
    </ng-template>
  </clr-tab>
  <clr-tab>
    <button clrTabLink><clr-icon shape="e-check" class="is-solid" style="color:green"></clr-icon>Приёмы</button>
    <ng-template [(clrIfActive)]="model.ticketsTabActive">
      <clr-tab-content>
        <div class="edit-page-tab">
          <app-animal-tickets [id]="id" [isExternal]="isExternalAnimal"></app-animal-tickets>
        </div>
      </clr-tab-content>
    </ng-template>
  </clr-tab>
</clr-tabs>
