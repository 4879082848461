
      <div *ngIf="contextFormGroup" class="form compact" [formGroup]="contextFormGroup"
           style="max-height: 60vh; background-color: white; position: relative;">
          <section class="form-block itech-block-normal">

              <div class="form-group">
                  <label for="orderNo">№ раздела</label>
                  <label for="orderNo" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md tooltip-top-left"
                         [class.invalid]="isInvalid(contextFormGroup, 'orderNo')">
                      <input type="text" id="orderNo" class="itech-control-normal"
                             formControlName="orderNo">
                      <app-validation-tooltip
                              [input]="contextFormGroup.controls['orderNo']"></app-validation-tooltip>
                  </label>
              </div>

              <div class="form-group">
                  <label for="editorKind">Редактор раздела</label>
                  <label for="editorKind" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md tooltip-top-left"
                         [class.invalid]="isInvalid(contextFormGroup, 'editorKind')">
                      <div class="select">
                          <select formControlName="editorKind" class="itech-control-xlarge">
                              <option [ngValue]="'Табл_по_графам'">
                                  Таблица по графам
                              </option>
                              <option [ngValue]="'Табл_по_строкам'">
                                  Таблица по ячейкам
                              </option>
                              <option [ngValue]="'Табл_стройки'">
                                  Таблица по стройкам
                              </option>
                              <option [ngValue]="'Табл_сделки_продажа'">
                                  Таблица со сделками о продаже
                              </option>
                              <option [ngValue]="'Табл_сделки_покупка'">
                                  Таблица со сделками о покупке
                              </option>
                          </select>
                      </div>
                  </label>
              </div>

              <div class="form-group">
                  <label for="pageTitle" class="required">Название страницы</label>
                  <label for="pageTitle" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md tooltip-top-left"
                         [class.invalid]="isInvalid(contextFormGroup, 'pageTitle')">
                      <input type="text" id="pageTitle" class="itech-control-xlarge"
                             formControlName="pageTitle">
                      <app-validation-tooltip
                              [input]="contextFormGroup.controls['pageTitle']"></app-validation-tooltip>
                  </label>
              </div>

              <div class="form-group itech-double-height v-resize">
                  <label for="displayTitle">Название раздела</label>
                  <label for="displayTitle" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                         [class.invalid]="isInvalid(contextFormGroup, 'displayTitle')">
                        <textarea type="text" id="displayTitle" class="itech-control-xlarge itech-text-wall"
                                  formControlName="displayTitle"></textarea>
                      <app-validation-tooltip
                              [input]="contextFormGroup.controls['displayTitle']"></app-validation-tooltip>
                  </label>
              </div>

              <div class="form-group">
                  <label for="indicatorLevel" class="required">Уровень показателя</label>
                  <label for="indicatorLevel" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md tooltip-top-left"
                         [class.invalid]="isInvalid(contextFormGroup, 'indicatorLevel')">
                      <input type="text" id="indicatorLevel" class="itech-control-normal"
                             formControlName="indicatorLevel">
                      <app-validation-tooltip
                              [input]="contextFormGroup.controls['indicatorLevel']"></app-validation-tooltip>
                  </label>
                  <label for="subjectIndicatorLevel">Уровень в край. сводке</label>
                  <label for="subjectIndicatorLevel" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md tooltip-top-left"
                         [class.invalid]="isInvalid(contextFormGroup, 'subjectIndicatorLevel')">
                      <input type="text" id="subjectIndicatorLevel" class="itech-control-normal"
                             formControlName="subjectIndicatorLevel">
                      <app-validation-tooltip
                              [input]="contextFormGroup.controls['subjectIndicatorLevel']"></app-validation-tooltip>
                  </label>
              </div>

              <div class="form-group itech-double-height v-resize">
                  <label for="paramsStr">Доп. параметры</label>
                  <label for="paramsStr" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                         [class.invalid]="isInvalid(contextFormGroup, 'paramsStr')">
                        <textarea type="text" id="paramsStr" class="itech-control-xlarge itech-text-wall"
                                  formControlName="paramsStr"></textarea>
                      <app-validation-tooltip
                              [input]="contextFormGroup.controls['paramsStr']"></app-validation-tooltip>
                  </label>
              </div>

              <div class="form-group">
                  <label for="level60ObjKind">Категория объектов</label>
                  <label for="level60ObjKind" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md tooltip-top-left"
                         [class.invalid]="isInvalid(contextFormGroup, 'level60ObjKind')">
                      <input type="text" id="level60ObjKind" class="itech-control-xlarge"
                             formControlName="level60ObjKind">
                      <app-validation-tooltip
                              [input]="contextFormGroup.controls['level60ObjKind']"></app-validation-tooltip>
                  </label>
              </div>
          </section>
      </div>
  