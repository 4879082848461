import { Component, HostBinding, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-query-details-quarantine-fields',
  template: `
      <app-query-lookup-combo-filter parameterName="disease_type" [lookupName]="'disease-type'" style="margin-bottom:.5rem"
                                     [contextFormGroup]="contextFormGroup" [queryUserMode]="queryUserMode">
          Заболевание
      </app-query-lookup-combo-filter>
      <app-query-lookup-combo-filter parameterName="source_pathogen_animal_type"
                                     [lookupName]="'animal-type'" style="margin-bottom:.5rem"
                                     [contextFormGroup]="contextFormGroup" [queryUserMode]="queryUserMode">
          Вид животного
      </app-query-lookup-combo-filter>
      <app-query-lookup-combo-filter *ngIf="contextFormGroup.get('_par_source_pathogen_animal_type').value"
                                     parameterName="source_pathogen_animal_subtype" style="margin-bottom:.5rem"
                                     [lookupName]="'animal-subtype' + contextFormGroup.get('_par_source_pathogen_animal_type').value"
                                     [contextFormGroup]="contextFormGroup" [queryUserMode]="queryUserMode">
          Подвид животного
      </app-query-lookup-combo-filter>
      <div class="form-group">
          <label>Восприимчивые животные</label>
          <app-checkbox-select-with-parent [lookupNameParent]="'animal-type'" [lookupName]="'animal-subtype'"
                                           [contextFormGroup]="contextFormGroup" style="margin-bottom:.5rem"
                                           [contextControlName]="'_par_susceptible_unique_animal_subtype'">
          </app-checkbox-select-with-parent>
      </div>
      <app-query-lookup-combo-filter parameterName="current_status" style="margin-bottom:.5rem"
                                     [lookupName]="'quarantine-status'"
                                     [contextFormGroup]="contextFormGroup" [queryUserMode]="queryUserMode">
          Статус
      </app-query-lookup-combo-filter>
      <app-query-date-time-filter parameterName="date" [contextFormGroup]="contextFormGroup" style="margin-bottom:.5rem"
                                  [queryUserMode]="queryUserMode">
          Дата создания
      </app-query-date-time-filter>
      <app-query-date-time-filter parameterName="start_quarantine_date" [contextFormGroup]="contextFormGroup" style="margin-bottom:.5rem"
                                  [queryUserMode]="queryUserMode">
          Дата начала карантина
      </app-query-date-time-filter>
      <app-query-date-time-filter parameterName="end_quarantine_date" [contextFormGroup]="contextFormGroup" style="margin-bottom:.5rem"
                                  [queryUserMode]="queryUserMode">
          Дата оконч. карантина
      </app-query-date-time-filter>
      <app-query-lookup-combo-filter parameterName="responsible_institution" [lookupName]="'all-institution'"
                                     style="margin-bottom:.5rem"
                                     [contextFormGroup]="contextFormGroup" [queryUserMode]="queryUserMode">
          Ответственное учреждение
      </app-query-lookup-combo-filter>
      <app-query-lookup-combo-filter *ngIf="contextFormGroup.get('_par_responsible_institution').value"
                                     parameterName="responsible_institution_employee" style="margin-bottom:.5rem"
                                     [lookupName]="'institution-employee' + contextFormGroup.get('_par_responsible_institution').value"
                                     [contextFormGroup]="contextFormGroup" [queryUserMode]="queryUserMode">
          Ответственный пользователь
      </app-query-lookup-combo-filter>
      <app-query-lookup-combo-filter parameterName="territory_type" style="margin-bottom:.5rem"
                                     [lookupName]="'/quarantine/trapped-type'"
                                     [contextFormGroup]="contextFormGroup" [queryUserMode]="queryUserMode">
          Зона карантина
      </app-query-lookup-combo-filter>
      <app-query-lookup-combo-filter parameterName="region" style="margin-bottom:.5rem"
                                     [lookupName]="'addr-region'"
                                     [contextFormGroup]="contextFormGroup" [queryUserMode]="queryUserMode">
          Муниципальное образование
      </app-query-lookup-combo-filter>
      <app-query-lookup-combo-filter *ngIf="contextFormGroup.get('_par_region').value"
                                     parameterName="city" style="margin-bottom:.5rem"
                                     [lookupName]="'addr-city' + contextFormGroup.get('_par_region').value"
                                     [contextFormGroup]="contextFormGroup" [queryUserMode]="queryUserMode">
          Населенный пункт
      </app-query-lookup-combo-filter>`,
  styleUrls: ['./query.all.css']
})
export class QueryDetailsQuarantineFieldsComponent {
  @Input() contextFormGroup: FormGroup;
  @Input() queryUserMode;
  @HostBinding('attr.class') class = 'form-block itech-block-normal';
}
