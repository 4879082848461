import { Component } from '@angular/core';
import { QueryService } from '../../logic/services/query.service';
import { DataCachingService } from '../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../logic/services/lookup-source.service';
import { FormBuilder, Validators } from '@angular/forms';
import { AlertService } from '../../ui/infrastructure/alert.service';
import { FormHelper } from '../../ui/controls/form-helper';
import { QueryDetailsEntityComponent } from './query-details-entity.component';

@Component({
  selector: 'app-query-details-animal',
  templateUrl: './query-details-animal.component.html',
  styleUrls: ['./query.all.css']
})
export class QueryDetailsAnimalComponent extends QueryDetailsEntityComponent {

  EntityType = 2; // animal

  constructor(queryService: QueryService,
              dataCachingService: DataCachingService,
              waitingOverlay: GlobalWaitingOverlayService,
              lookupSourceService: LookupSourceService,
              alertService: AlertService,
              fb: FormBuilder) {

    super(queryService, dataCachingService, waitingOverlay, lookupSourceService, alertService, fb);
  }

  static getGroupDef(fb: FormBuilder, condition: any) {
    return {
      type: 'animal',

      _par_birth_date_filter: condition.data._par_birth_date_filter || 0,
      _par_birth_date_from: [condition.data._par_birth_date_from, FormHelper.validateDateTimePicker()],
      _par_birth_date_to: [condition.data._par_birth_date_to, FormHelper.validateDateTimePicker()],
      _par_death_date_filter: condition.data._par_death_date_filter || 0,
      _par_death_date_from: [condition.data._par_death_date_from, FormHelper.validateDateTimePicker()],
      _par_death_date_to: [condition.data._par_death_date_to, FormHelper.validateDateTimePicker()],
      _par_animal_group_filter: condition.data._par_animal_group_filter,
      _par_title_filter: condition.data._par_title_filter,
      _par_title: condition.data._par_title,
      _par_animal_is_retired_filter: condition.data._par_animal_is_retired_filter,
      _par_animal_type: condition.data._par_animal_type,
      _par_animal_type_filter: condition.data._par_animal_type_filter,
      _par_animal_subtype: fb.array(condition.data._par_animal_subtype || []),
      _par_animal_subtype_filter: condition.data._par_animal_subtype_filter,
      _par_animal_breed: fb.array(condition.data._par_animal_breed || []),
      _par_animal_breed_filter: condition.data._par_animal_breed_filter,
      _par_total_count_filter: condition.data._par_total_count_filter,
      _par_total_count_from: [condition.data._par_total_count_from, Validators.pattern(/^\d{1,9}$/)],
      _par_total_count_to: [condition.data._par_total_count_to, Validators.pattern(/^\d{1,9}$/)],
      _par_color: condition.data._par_color,
      _par_color_filter: condition.data._par_color_filter,
      _par_weight_filter: condition.data._par_weight_filter,
      _par_weight_from: [condition.data._par_weight_from, FormHelper.getApplicationMoneyValidatorDot()],
      _par_weight_to: [condition.data._par_weight_to, FormHelper.getApplicationMoneyValidatorDot()],
      _par_animal_reason: condition.data._par_animal_reason,
      _par_animal_reason_filter: condition.data._par_animal_reason_filter,
      _par_animal_death_reason: condition.data._par_animal_death_reason,
      _par_animal_death_reason_filter: condition.data._par_animal_death_reason_filter,
      _par_animal_gender_filter: condition.data._par_animal_gender_filter,
      _par_animal_gender: condition.data._par_animal_gender,
      _par_origin_country: condition.data._par_origin_country,
      _par_origin_country_filter: condition.data._par_origin_country_filter,
      _par_created_institution_filter: condition.data._par_created_institution_filter || 0,
      _par_created_institution: condition.data._par_created_institution,
      _par_created_institution_employee_filter: condition.data._par_created_institution_employee_filter || 0,
      _par_created_institution_employee: condition.data._par_created_institution_employee,
      _par_created_time_filter: condition.data._par_created_time_filter || 0,
      _par_created_time_from: [condition.data._par_created_time_from, FormHelper.validateDateTimePicker()],
      _par_created_time_to: [condition.data._par_created_time_to, FormHelper.validateDateTimePicker()],
    };
  }

  getGroupDef() {
    if (!this.model.condition.data['_par_animal_type']) {
      this.model.condition.data['_par_animal_subtype_filter'] = null;
    }
    return QueryDetailsAnimalComponent.getGroupDef(this.fb, this.model.condition);
  }
}
