import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AddressComponent } from '../../../ui/controls/address.component';
import { ServerSideErrorsProvider } from '../../../logic/validators/server-side-errors-provider';
import { serverSideErrorsValidator } from '../../../logic/validators/server-side-errors-validator.directive';
import { AppFormGroup } from '../../../ui/controls/app-form-group';
import { DateHelper } from '../../../helpers/date-helper';
import { StringHelper } from '../../../helpers/string-helper';
import { AgentSearchModalComponent } from '../../edit-agent/search/agent-search-modal.component';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { SecurityService } from '../../../logic/services/security.service';

@Component({
  selector: 'app-edit-stable-common-form',
  templateUrl: './edit-stable-common-form.component.html'
})
export class EditStableCommonFormComponent implements OnChanges {

  @Input() contextFormGroup: AppFormGroup;
  @Input() syncStates: any[] = [];
  @Input() id;

  @Output() startSyncStableAgents = new EventEmitter();
  @Output() syncStateUpdated = new EventEmitter<any>();

  syncCommonInfo = {};
  isInvalid = FormHelper.isInvalid;
  isInvalidControl = FormHelper.isInvalidControl;
  cadNumberPattern = StringHelper.cadNumberPattern;
  expandedAgentCounts = 2;

  changeFavouriteLoading = false;

  public static createFormGroup(fb: FormBuilder, serverSideErrorsProvider: ServerSideErrorsProvider, val: any) {
    const group = fb.group({
      __stub__history: false,
      id: val.id,
      createdTime: val.createdTime,
      createdUser: val.createdUser,
      deletedTime: val.deletedTime,
      deletedUser: val.deletedUser,
      stableTypeId: [val.stableTypeId, Validators.compose([Validators.required,
        serverSideErrorsValidator('stableTypeId', serverSideErrorsProvider)])],
      caption: [val.caption, Validators.compose([
        Validators.required,
        serverSideErrorsValidator('caption', serverSideErrorsProvider)])],
      cadNo: [StringHelper.stringCadNumberSplitWithColon(val.cadNo), Validators.compose([
        serverSideErrorsValidator('cadNo', serverSideErrorsProvider),
        FormHelper.validateCadNo(),
      ])],
      codes: fb.array((val.codes || []).map(el => fb.control(el))),
      activityTypeCaptions: fb.array((val.activityTypeCaptions || []).map(el => fb.control(el))),
      square: [val.square,
        Validators.compose([
          serverSideErrorsValidator('square', serverSideErrorsProvider),
          FormHelper.validateMoney(),
        ])],
      address: fb.group(AddressComponent.buildFormGroupDef(fb, serverSideErrorsProvider, val.address, true)),
      currentOwnerId: val.currentOwnerId,
      agents: EditStableCommonFormComponent.buildStableAgentsArray(fb, serverSideErrorsProvider, val.agents),
      trappedToQuarantine: val.trappedToQuarantine
    });

    return group;
  }

  public static buildStableAgentsArray(fb: FormBuilder, serverSideErrorsProvider: ServerSideErrorsProvider, val: any[]) {

    if (!val || !val.length) {
      val = [];
    }

    val.sort((a, b) => a.dateFrom.localeCompare(b.dateFrom));

    return fb.array(val.map(agentPerson => EditStableCommonFormComponent.buildStableAgent(fb, agentPerson)));
  }

  public static buildStableAgent(fb: FormBuilder, stableAgent: any) {

    return fb.group({
      __stub__editing: stableAgent.__stub__editing,
      stableId: stableAgent.stableId,
      agentId: [stableAgent.agentId, Validators.compose([Validators.required])],
      dateFrom: [stableAgent.dateFrom, Validators.compose([
        Validators.required, FormHelper.validateDateTimePicker()])],
      dateTo: [stableAgent.dateTo, Validators.compose([
        FormHelper.validateDateTimePicker()])],
    });
  }

  public static addStableAgent(fb: FormBuilder, agents: FormArray): FormGroup {

    const currentAgent = agents.controls[0];

    if (currentAgent) {
      currentAgent.get('dateTo').setValue(StringHelper.getISODate(DateHelper.addDays(new Date(), -1)));
    }

    const fg = EditStableCommonFormComponent.buildStableAgent(fb,
      {__stub__editing: true, dateFrom: StringHelper.getISODate(new Date())});

    agents.insert(0, fg);
    agents.markAsDirty();

    return fg;
  }

  constructor(private fb: FormBuilder,
              private navigationService: AppNavigationService,
              private userService: SecurityService) {

  }

  public get agents(): FormArray {
    return this.contextFormGroup.get('agents') as FormArray;
  }

  get codes(): FormArray {
    return this.contextFormGroup.get('codes') as FormArray;
  }

  get activityTypeCaptions(): FormArray {
    return this.contextFormGroup.get('activityTypeCaptions') as FormArray;
  }

  ngOnChanges() {
    if (!this.syncStates) {
      return;
    }
    this.syncCommonInfo = this.syncStates.find(x => x && x.conversationTypeId === -2) || {};
  }

  searchOwner() {
    this.navigationService.searchAgent(AgentSearchModalComponent).subscribe(val => {
      if (val) {
        this.contextFormGroup.get('currentOwnerId').setValue(val);
        this.contextFormGroup.markAsDirty();
      }
    });
  }

  public deleteStableAgent(i: number) {
    this.agents.removeAt(i);
    this.agents.markAsDirty();
  }

  public editStableAgent(i: number) {
    this.agents.controls[i].get('__stub__editing').setValue(true);
  }

  public showHistory() {
    this.contextFormGroup.get('__stub__history').setValue(!this.contextFormGroup.get('__stub__history').value);
  }

  addActivityTypeCaption() {
    this.activityTypeCaptions.push(this.fb.control(undefined));
    this.contextFormGroup.markAsDirty();
  }

  deleteActivityTypeCaption(index: number) {
    this.activityTypeCaptions.removeAt(index);
    this.activityTypeCaptions.markAsDirty();
  }

  runStableAgentSync() {
    this.startSyncStableAgents.emit();
  }

  changeFavourite() {
    this.changeFavouriteLoading = true;
    this.userService.changeFavourite(this.id).subscribe(val => this.changeFavouriteLoading = false);
  }
}
