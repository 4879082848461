<clr-modal [(clrModalOpen)]="addEmployeeModalOpened" *ngIf="addEmployeeModalOpened && currentFormGroup" [clrModalSize]="'lg'">
  <h3 class="modal-title">Карточка работника</h3>
  <div class="modal-body">
    <div class="form compact" [formGroup]="currentFormGroup">

      <clr-tabs>
        <clr-tab>
          <button clrTabLink>Общее</button>
          <ng-template  [(clrIfActive)]="tabActive.commonTabActive">
            <clr-tab-content id="commonTab">
              <div class="edit-page-tab">
                <section class="form-block itech-block-normal">

                  <div class="form-group">
                    <label for="lastName" class="required">Фамилия</label>
                    <label for="lastName" aria-haspopup="true" role="tooltip"
                           class="tooltip tooltip-validation tooltip-md"
                           [class.invalid]="isInvalid(currentFormGroup, 'lastName')">
                      <input type="text" id="lastName" placeholder="Фамилия" class="itech-control-large"
                             formControlName="lastName">
                      <app-validation-tooltip [input]="currentFormGroup.controls['lastName']"></app-validation-tooltip>
                    </label>
                  </div>

                  <div class="form-group">
                    <label for="firstName" class="required">Имя</label>
                    <label for="firstName" aria-haspopup="true" role="tooltip"
                           class="tooltip tooltip-validation tooltip-md"
                           [class.invalid]="isInvalid(currentFormGroup, 'firstName')">
                      <input type="text" id="firstName" placeholder="Имя" class="itech-control-large"
                             formControlName="firstName">
                      <app-validation-tooltip [input]="currentFormGroup.controls['firstName']"></app-validation-tooltip>
                    </label>
                  </div>

                  <div class="form-group">
                    <label for="middleName">Отчество</label>
                    <label for="middleName" aria-haspopup="true" role="tooltip"
                           class="tooltip tooltip-validation tooltip-md"
                           [class.invalid]="isInvalid(currentFormGroup, 'middleName')">
                      <input type="text" id="middleName" placeholder="Отчество" class="itech-control-large"
                             formControlName="middleName">
                      <app-validation-tooltip [input]="currentFormGroup.controls['middleName']"></app-validation-tooltip>
                    </label>
                  </div>

                  <div class="form-group">
                    <label for="fullFioGen">ФИО в род. падеже</label>
                    <label for="fullFioGen" aria-haspopup="true" role="tooltip"
                           class="tooltip tooltip-validation tooltip-md"
                           [class.invalid]="isInvalid(currentFormGroup, 'fullFioGen')">
                      <input type="text" id="fullFioGen" placeholder="ФИО в родительном падеже (для отчетов)" class="itech-control-large"
                             formControlName="fullFioGen">
                      <app-validation-tooltip [input]="currentFormGroup.controls['fullFioGen']"></app-validation-tooltip>
                    </label>
                  </div>

                  <div class="form-group">
                    <label for="birthDate" class="required">Дата рождения</label>
                    <label for="birthDate" aria-haspopup="true" role="tooltip"
                           class="tooltip tooltip-validation tooltip-md"
                           [class.invalid]="isInvalid(currentFormGroup, 'birthDate')">
                      <input type="date" id="birthDate" class="itech-control-normal" required
                             formControlName="birthDate">
                      <app-validation-tooltip [input]="currentFormGroup.controls['birthDate']"></app-validation-tooltip>
                    </label>
                  </div>

                  <div class="form-group">
                    <label for="postId" class="required">Должность</label>
                    <app-combo-lookup class="itech-control-large" [contextControlId]="'postId'" [sorted]="true"
                                      [contextControlName]="'postId'" [lookupName]="'institution-post'"
                                      [contextFormGroup]="currentFormGroup"></app-combo-lookup>
                  </div>

                  <div class="form-group">
                    <label for="branchId" class="required">Пункт, участок, лаборатория</label>
                    <app-combo-lookup class="itech-control-large" [contextControlId]="'branchId'"
                                      [contextControlName]="'branchId'"
                                      [lookupName]="'institution-branch' + id.toString()"
                                      [contextFormGroup]="currentFormGroup"></app-combo-lookup>
                  </div>

                  <div class="form-group">
                    <label for="email">Эл.почта</label>
                    <label for="email" aria-haspopup="true" role="tooltip"
                           class="tooltip tooltip-validation tooltip-md"
                           [class.invalid]="isInvalid(currentFormGroup, 'email')">
                      <input type="text" id="email" placeholder="mail@mail.org" class="itech-control-large"
                             formControlName="email">
                      <app-validation-tooltip [input]="currentFormGroup.controls['email']"></app-validation-tooltip>
                    </label>
                  </div>

                  <div class="form-group">
                    <label for="retireDate">Дата увольнения</label>
                    <label for="retireDate" aria-haspopup="true" role="tooltip"
                           class="tooltip tooltip-validation tooltip-md"
                           [class.invalid]="isInvalid(currentFormGroup, 'retireDate')">
                      <input type="date" id="retireDate" class="itech-control-normal"
                             formControlName="retireDate">
                      <app-validation-tooltip [input]="currentFormGroup.controls['retireDate']"></app-validation-tooltip>
                    </label>
                  </div>

                </section>
              </div>
            </clr-tab-content>
          </ng-template>
        </clr-tab>

        <clr-tab>
          <button clrTabLink>Доступ к ИС Ветеринария</button>
          <ng-template  [(clrIfActive)]="tabActive.accessTabActive">
            <clr-tab-content id="accessTab">
              <div class="edit-page-tab">
                <section class="form-block itech-block-normal">
                  <div class="form-group itech-group-whole-line">
                    <label></label>
                    <app-checkbox-select class="itech-control-x-large"
                                         [contextControlName]="'asoiRoles'" [lookupName]="'security-role'"
                                         [contextFormGroup]="currentFormGroup"></app-checkbox-select>
                  </div>
                  <div class="form-group">
                    <label for="regionIdCityId">Населенный пункт по умолчанию</label>
                    <app-combo-lookup class="itech-control-m-large" [contextControlId]="'regionIdCityId'"
                                      [contextControlName]="'regionIdCityId'" [lookupName]="'addr-city'" [required]="false"
                                      [contextFormGroup]="currentFormGroup" (change)="regionIdCityIdChanged($event)"></app-combo-lookup>
                  </div>
                </section>
              </div>
            </clr-tab-content>
          </ng-template>
        </clr-tab>

        <clr-tab *ngIf="currentFormGroup.get('id').value">
          <button clrTabLink>Меркурий</button>
          <ng-template [(clrIfActive)]="tabActive.mercuryTabActive" (clrIfActiveChange)="getMercuryAuthInfo()">
            <clr-tab-content id="mercuryTabActive">
              <div class="edit-page-tab">
                <clr-progress-bar *ngIf="mercuryLoading" clrValue="0" clrLoop></clr-progress-bar>

                <section class="form-block itech-block-normal" *ngIf="mercuryAuthInfo" [formGroup]="mercuryAuthInfo">
                  <div class="form-group">
                    <label for="login" class="required">Логин</label>
                    <label for="login" aria-haspopup="true" role="tooltip"
                           class="tooltip tooltip-validation tooltip-md"
                           [class.invalid]="isInvalid(mercuryAuthInfo, 'login')">
                      <input type="text" id="login" class="itech-control-medium" formControlName="login">
                      <app-validation-tooltip [input]="mercuryAuthInfo.controls['login']"></app-validation-tooltip>
                    </label>
                    <label *ngIf="mercuryAuthInfo.get('hasLogin').value" style="color:green;font-size:10px">
                      Имеется ранее сохраненный логин
                    </label>
                  </div>
                  <div class="form-group">
                    <label for="password" class="required">Пароль</label>
                    <label for="password" aria-haspopup="true" role="tooltip"
                           class="tooltip tooltip-validation tooltip-md"
                           [class.invalid]="isInvalid(mercuryAuthInfo, 'password')">
                      <input type="text" id="password" class="itech-control-medium" formControlName="password">
                      <app-validation-tooltip [input]="mercuryAuthInfo.controls['password']"></app-validation-tooltip>
                    </label>
                    <label *ngIf="mercuryAuthInfo.get('hasPassword').value" style="color:green;font-size:10px">
                      Имеется ранее сохраненный пароль
                    </label>
                  </div>
                </section>
              </div>
            </clr-tab-content>
          </ng-template>
        </clr-tab>

        <clr-tab *ngIf="currentFormGroup.get('id').value">
          <button clrTabLink>Мобильные устройства</button>
          <ng-template [(clrIfActive)]="tabActive.mobileTabActive" (clrIfActiveChange)="getEmployeeDevice()">
            <clr-tab-content id="mobileTabActive">
              <div class="edit-page-tab">
                <clr-progress-bar *ngIf="deviceLoading" clrValue="0" clrLoop></clr-progress-bar>

                <section class="form-block itech-block-normal" *ngIf="device" [formGroup]="device">
                  <div class="form-group">
                    <label for="deviceInfo">Устройство</label>
                    <label for="deviceInfo" aria-haspopup="true" role="tooltip"
                           class="tooltip tooltip-validation tooltip-md">
                      <input type="text" id="deviceInfo" class="itech-control-medium" formControlName="deviceInfo">
                    </label>
                  </div>

                  <div class="form-group">
                    <label for="registrationRequestTime">Дата регистрации</label>
                    <label for="registrationRequestTime" aria-haspopup="true" role="tooltip"
                           class="tooltip tooltip-validation tooltip-md">
                      <input type="text" id="registrationRequestTime" class="itech-control-medium" disabled
                             [value]="device.controls['registrationRequestTime'].value | rtime">
                    </label>
                  </div>

                  <div class="form-group">
                    <label for="lastAccessTime">Последнее подключение</label>
                    <label for="lastAccessTime" aria-haspopup="true" role="tooltip"
                           class="tooltip tooltip-validation tooltip-md">
                      <input type="text" id="lastAccessTime" class="itech-control-medium" disabled
                             [value]="device.controls['lastAccessTime'].value | rtime">
                    </label>
                  </div>

                  <div class="form-group">
                    <label for="pinCode" class="required">PIN-код</label>
                    <label for="pinCode" aria-haspopup="true" role="tooltip"
                           class="tooltip tooltip-validation tooltip-md"
                           [class.invalid]="isInvalid(device, 'pinCode')">
                      <input type="text" id="pinCode" class="itech-control-medium" formControlName="pinCode">
                      <app-validation-tooltip [input]="device.controls['pinCode']"></app-validation-tooltip>
                    </label>
                  </div>
                </section>

                <section *ngIf="!device && !deviceLoading">Нет подключенных мобильных устройств</section>
              </div>
            </clr-tab-content>
          </ng-template>
        </clr-tab>
      </clr-tabs>
    </div>
  </div>

  <div class="modal-footer itech-form-actions">
    <div>
      <button *ngIf="!currentIsNew && tabActive.commonTabActive" type="button" class="btn btn-warning-outline"
              (click)="deleteEmployee(currentEmployee)">
        <clr-icon shape="trash"></clr-icon>&nbsp;Удалить
      </button>

      <button *ngIf="!currentIsNew && tabActive.mobileTabActive && device && !deviceLoading"
              [disabled]="deviceStoring"
              type="button" class="btn btn-warning-outline"
              (click)="deleteEmployeeDevice()">
        <clr-icon shape="trash"></clr-icon>&nbsp;Удалить устройство
      </button>
    </div>

    <div>
      <button *ngIf="tabActive.commonTabActive || tabActive.accessTabActive"
              type="button" class="btn btn-primary" (click)="addEditEmployeeCompleted()">
        <clr-icon shape="check"></clr-icon>&nbsp;Сохранить
      </button>

      <button *ngIf="tabActive.mercuryTabActive && mercuryAuthInfo" [disabled]="!mercuryAuthInfo.dirty"
              type="button" class="btn btn-primary" (click)="storeMercuryAuthInfo()">
        <clr-icon shape="check"></clr-icon>&nbsp;Сохранить учётные данные
      </button>

      <button *ngIf="tabActive.mobileTabActive && device" [disabled]="!device.dirty"
              [clrLoading]="deviceStoring"
              type="button" class="btn btn-primary" (click)="storeEmployeeDevice()">
        <clr-icon shape="check"></clr-icon>&nbsp;{{deviceHasPinCode ? 'Изменить PIN-код' : 'Установить PIN-код'}}
      </button>

      <button type="button" class="btn btn-warning" (click)="addEditEmployeeCancelled()"
              [disabled]="deviceStoring">
        <clr-icon shape="undo"></clr-icon>&nbsp;Отмена
      </button>
    </div>
  </div>
</clr-modal>
