<div class="itech-common-wrap no-pager">
  <div style="display: flex; flex-direction: column;">
    <div class="itech-content-header itech-no-margin-bottom">
      <clr-icon shape="view-list" size="36"></clr-icon>&nbsp;Статистика ВСД
    </div>
    <div style="padding: 5px; height: 88%">
      <app-grid [uniqueGridName]="'Statistic'" [exportFileName]="'Статистика ВСД'"
                [rowData]="model" [columnDefs]="gridColumnDefs"></app-grid>
    </div>
  </div>

  <div class="itech-form-actions">
    <div>
      <button class="btn btn-link btn-primary" (click)="refreshResults()">
        <clr-icon shape="refresh" class="has-badge"></clr-icon>&nbsp;Обновить список
      </button>
    </div>
  </div>
</div>
