import { Component, Input, OnChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AddressComponent } from '../../../ui/controls/address.component';
import { ServerSideErrorsProvider } from '../../../logic/validators/server-side-errors-provider';
import { serverSideErrorsValidator } from '../../../logic/validators/server-side-errors-validator.directive';
import { AppFormGroup } from '../../../ui/controls/app-form-group';

@Component({
  selector: 'app-edit-agent-common-form',
  templateUrl: './edit-agent-common-form.component.html'
})
export class EditAgentCommonFormComponent implements OnChanges {

  @Input() contextFormGroup: AppFormGroup;
  @Input() syncStates: any[] = [];

  syncCommonInfo = {};
  isInvalid = FormHelper.isInvalid;
  isPhysOrIndividOrKfh = EditAgentCommonFormComponent.isPhysOrIndividOrKfh;

  public static createFormGroup(fb: FormBuilder, serverSideErrorsProvider: ServerSideErrorsProvider, val: any) {
    return fb.group({
      id: val.id,
      createdTime: val.createdTime,
      createdUser: val.createdUser,
      deletedTime: val.deletedTime,
      deletedUser: val.deletedUser,
      legalFormId: [val.legalFormId, Validators.compose([Validators.required,
        serverSideErrorsValidator('legalFormId', serverSideErrorsProvider)])],
      caption: [val.caption, Validators.compose([
        FormHelper.conditionalValidate(Validators.required, ctrl => !EditAgentCommonFormComponent.isPhysOrIndividOrKfh(ctrl.parent)),
        serverSideErrorsValidator('caption', serverSideErrorsProvider)])],
      shortCaption: [val.shortCaption, Validators.compose([
        FormHelper.conditionalValidate(Validators.required, ctrl => !EditAgentCommonFormComponent.isPhysOrIndividOrKfh(ctrl.parent)),
        serverSideErrorsValidator('shortCaption', serverSideErrorsProvider)])],
      phone: [val.phone, serverSideErrorsValidator('phone', serverSideErrorsProvider)],
      email: [val.email, serverSideErrorsValidator('email', serverSideErrorsProvider)],
      fax: [val.fax, serverSideErrorsValidator('fax', serverSideErrorsProvider)],
      otherTextInfo: [val.otherTextInfo, serverSideErrorsValidator('otherTextInfo', serverSideErrorsProvider)],
      ogrn: [val.ogrn, Validators.compose([Validators.pattern(/^\d*$/),
        serverSideErrorsValidator('ogrn', serverSideErrorsProvider)])],
      dateTaxRegistered: [val.dateTaxRegistered, Validators.compose([
        FormHelper.validateDateTimePicker(),
        serverSideErrorsValidator('dateTaxRegistered', serverSideErrorsProvider)])],
      dateTaxIncluded: [val.dateTaxIncluded, Validators.compose([FormHelper.validateDateTimePicker(),
        serverSideErrorsValidator('dateTaxIncluded', serverSideErrorsProvider)])],
      dateTaxRegisterUpdated: [val.dateTaxRegisterUpdated, Validators.compose([FormHelper.validateDateTimePicker(),
        serverSideErrorsValidator('dateTaxRegisterUpdated', serverSideErrorsProvider)])],
      dateTaxBranchUpdated: [val.dateTaxBranchUpdated, Validators.compose([FormHelper.validateDateTimePicker(),
        serverSideErrorsValidator('dateTaxBranchUpdated', serverSideErrorsProvider)])],
      dateTaxEliminated: [val.dateTaxEliminated, Validators.compose([FormHelper.validateDateTimePicker(),
        serverSideErrorsValidator('dateTaxEliminated', serverSideErrorsProvider)])],
      legalAddress: fb.group(AddressComponent.buildFormGroupDef(fb, serverSideErrorsProvider, val.legalAddress, true)),
      factAddress: fb.group(AddressComponent.buildFormGroupDef(fb, serverSideErrorsProvider, val.factAddress, false)),
      postAddress: fb.group(AddressComponent.buildFormGroupDef(fb, serverSideErrorsProvider, val.postAddress, false)),

      agentPersons: EditAgentCommonFormComponent.buildAgentPersonsArray(fb, serverSideErrorsProvider, val.agentPersons),

      okpo: [val.okpo, Validators.compose([
        serverSideErrorsValidator('okpo', serverSideErrorsProvider)])],
      okved: [val.okved, Validators.compose([
        serverSideErrorsValidator('okved', serverSideErrorsProvider)])],

      inn: [val.inn, Validators.compose([Validators.pattern(/^\d*$/),
        FormHelper.conditionalValidate(Validators.required, ctrl => !EditAgentCommonFormComponent.isPhysOrIndividOrKfh(ctrl.parent)),
        serverSideErrorsValidator('inn', serverSideErrorsProvider)])],
      kpp: [val.kpp, Validators.compose([Validators.pattern(/^\d*$/),
        serverSideErrorsValidator('kpp', serverSideErrorsProvider)])],
      bankAccount: [val.bankAccount, Validators.compose([Validators.pattern(/^\d*$/),
        serverSideErrorsValidator('bankAccount', serverSideErrorsProvider)])],
      bankPAccount: [val.bankPAccount, Validators.compose([
        serverSideErrorsValidator('bankPAccount', serverSideErrorsProvider)])],
      bankBik: [val.bankBik, Validators.compose([Validators.pattern(/^\d*$/),
        serverSideErrorsValidator('bankBik', serverSideErrorsProvider)])],

      dateRegisteredExternalRegistry: val.dateRegisteredExternalRegistry,
      servicedInstitutionId: val.servicedInstitutionId,
      servicedInstitutionBranchId: val.servicedInstitutionBranchId,

      openApiKey: val.openApiKey,
    });
  }

  public static buildAgentPersonsArray(fb: FormBuilder, serverSideErrorsProvider: ServerSideErrorsProvider, val: any[]) {

    if (!val || !val.length) {
      val = [];
    }

    return fb.array(val.map(agentPerson => EditAgentCommonFormComponent.buildAgentPerson(fb, agentPerson)));
  }

  public static buildAgentPerson(fb: FormBuilder, agentPerson: any) {

    return fb.group({
      agentPersonTypeId: [agentPerson.agentPersonTypeId, Validators.compose([Validators.required])],
      lastName: [agentPerson.lastName, Validators.compose([Validators.required])],
      firstName: [agentPerson.firstName, Validators.compose([
        FormHelper.conditionalValidate(Validators.required, ctrl => EditAgentCommonFormComponent.isPhysPerson(ctrl.parent)),
      ])],
      middleName: agentPerson.middleName,
      birthDate: [agentPerson.birthDate, Validators.compose([
        FormHelper.validateDateTimePicker()
      ])],
      identityDocSerial: [agentPerson.identityDocSerial],
      identityDocNumber: [agentPerson.identityDocNumber],
      identityDocDate: [agentPerson.identityDocDate, Validators.compose([
        FormHelper.validateDateTimePicker(),
      ])],
      identityDocIssuer: agentPerson.identityDocIssuer,
      inn: [agentPerson.inn, Validators.compose([
        Validators.pattern(/^\d{12}$/)])],
      gender: agentPerson.gender,
    });
  }

  public static prettifyFormGroup(fg: FormGroup) {
    const agentPersons = fg.get('agentPersons') as FormArray;
    const legalFormId = +fg.get('legalFormId').value;
    let i = 0;
    while (i < agentPersons.length) {
      const person = agentPersons.controls[i];
      const isPhys = (+person.get('agentPersonTypeId').value) === 1;
      if (legalFormId === 0) {
        if (isPhys) {
          i++;
        } else {
          agentPersons.removeAt(i);
        }
      } else if (legalFormId === 45 || legalFormId === 31 || legalFormId === 13) {
        i++;
      } else {
        if (isPhys) {
          agentPersons.removeAt(i);
        } else {
          i++;
        }
      }
    }
  }

  private static isPhys(parent: FormGroup | FormArray) {
    return parent && (+parent.get('legalFormId').value) === 0;
  }

  public static isPhysOrIndividOrKfh(parent: FormGroup | FormArray) {
    return parent && ((+parent.get('legalFormId').value) === 0 ||
      (+parent.get('legalFormId').value) === 31 ||
      (+parent.get('legalFormId').value) === 13 ||
      (+parent.get('legalFormId').value) === 45);
  }

  public static isIndividOrKfh(parent: FormGroup | FormArray) {
    return parent && ((+parent.get('legalFormId').value) === 31 ||
      (+parent.get('legalFormId').value) === 13 ||
      (+parent.get('legalFormId').value) === 45);
  }

  private static isPhysPerson(parent: FormGroup | FormArray) {
    return parent && (+parent.get('agentPersonTypeId').value) === 1;
  }

  constructor(private fb: FormBuilder) {

  }

  ngOnChanges() {
    if (!this.syncStates) {
      return;
    }
    this.syncCommonInfo = this.syncStates.find(x => x.conversationTypeId === -1) || {};
  }

  public get agentPersons(): FormArray {
    return this.contextFormGroup.get('agentPersons') as FormArray;
  }

  get physAgentPersonFormGroup(): FormGroup {
    if (!this.agentPersons) {
      return undefined;
    }

    let fg = this.agentPersons.controls.find(person => (+person.get('agentPersonTypeId').value) === 1);

    if (!fg) {
      fg = EditAgentCommonFormComponent.buildAgentPerson(this.fb,
        {
          agentPersonTypeId: 1,
        });
      this.agentPersons.push(fg);
      this.agentPersons.markAsDirty();
    }

    return fg as FormGroup;
  }

  public addAgentPerson() {
    const fg = EditAgentCommonFormComponent.buildAgentPerson(this.fb,
      {});
    this.agentPersons.push(fg);
    this.agentPersons.markAsDirty();
  }

  public deleteAgentPerson(i: number) {
    this.agentPersons.removeAt(i);
    this.agentPersons.markAsDirty();
  }
}
