import * as tslib_1 from "tslib";
import { PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
var RTimePipe = /** @class */ (function (_super) {
    tslib_1.__extends(RTimePipe, _super);
    function RTimePipe() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RTimePipe.prototype.transform = function (value, args) {
        return _super.prototype.transform.call(this, value, 'dd.MM.yyyy HH:mm:ss');
    };
    return RTimePipe;
}(DatePipe));
export { RTimePipe };
