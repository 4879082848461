import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EditQuarantineStagesComponent } from './edit-quarantine-stages.component';
import { ServerSideErrorsProvider } from '../../../logic/validators/server-side-errors-provider';
import { DateHelper } from '../../../helpers/date-helper';
import { QuarantineDataService } from '../../../logic/services/quarantine-data.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AppFormGroup } from '../../../ui/controls/app-form-group';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { StringHelper } from '../../../helpers/string-helper';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';

@Component({
  selector: 'app-edit-quarantine-decision-next-stage',
  template: `
      <div *ngIf="contextFormGroup && contextFormGroup.contains(controlName) && stageId >= 0 && stageId <= quarantineCurrentStatusId"
           style="margin-top:40px;border:1px solid silver;text-align:center;padding:0 20px 0 20px;">

          <app-doc-validation-errors [validationErrors]="contextFormGroup.docLevelServerSideValidationErrors">
          </app-doc-validation-errors>
          <div style="margin-top:10px">
              <strong>{{title}}</strong>
          </div>
          <div [formGroup]="currentFormGroup" style="margin:10px">
              <div>
                  <label for="dateToInclusive{{stageId}}" style="margin-right: 5px">
                      {{stageId === 0 ? 'Дата введения этапа' : 'Дата закрытия текущего этапа'}}
                  </label>
                  <label for="dateToInclusive{{stageId}}" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md disabled">
                      <input type="date" id="dateToInclusive{{stageId}}" class="itech-control-normal"
                             formControlName="dateToInclusive" (change)="changeDateFrom()"
                             [attr.disabled]="stageId !== quarantineCurrentStatusId ? true : undefined">
                  </label>
              </div>
              <div *ngIf="stageId == _quarantineStages.DETECTION">
                  <label for="dateFromNextStage{{stageId}}" style="margin-right: 5px">Карантин вводится с/по </label>
                  <label for="dateFromNextStage{{stageId}}" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md disabled">
                      <input type="date" id="dateFromNextStage{{stageId}}" class="itech-control-normal"
                             [(ngModel)]="dateFromNextStage" [ngModelOptions]="{standalone: true}"
                             [attr.disabled]="true">
                  </label>
                  <label for="dateToInclusiveNextStage{{stageId}}" aria-haspopup="true" role="tooltip"
                         class="tooltip tooltip-validation tooltip-md disabled">
                      <input type="date" id="dateToInclusiveNextStage{{stageId}}" class="itech-control-normal"
                             [(ngModel)]="dateToInclusiveNextStage" [ngModelOptions]="{standalone: true}"
                             [attr.disabled]="stageId !== quarantineCurrentStatusId ? true : undefined">
                  </label>
              </div>
              <div style="margin-top:20px" *ngIf="stageId === quarantineCurrentStatusId">
                  <button class="btn btn-primary" (click)="closeCurrentStage()"
                          [disabled]="stageId != _quarantineStages.INTRODUCTION ? !currentFormGroup.dirty : undefined">Утвердить</button>
              </div>
          </div>
      </div>`
})
export class EditQuarantineStagesDecisionNextStageComponent implements OnChanges {
  @Input() quarantineId;
  @Input() quarantineCurrentStatusId: number;
  @Input() stageId: number;
  @Input() contextFormGroup: AppFormGroup;
  @Input() serverSideErrorsProvider: ServerSideErrorsProvider;
  @Input() title = 'Решение о переводе на следующий этап';
  @Input() noSuspicionStage = false;

  @Output() needUpdateQuarantine = new EventEmitter();

  dateToInclusiveNextStage = null;
  dateFromNextStage = null;

  _quarantineStages = EditQuarantineStagesComponent.QUARANTINE_STAGE;

  controlName = 'stagePeriods';
  currentFormGroup: FormGroup;

  constructor(private fb: FormBuilder,
              private quarantineDataService: QuarantineDataService,
              private dataCachingService: DataCachingService,
              private alertService: AlertService,
              private lookupService: LookupSourceService) {
  }

  ngOnChanges() {
    this.dateToInclusiveNextStage = null;
    this.buildCurrentFormGroup();
  }

  buildCurrentFormGroup() {

    if (!this.contextFormGroup.contains(this.controlName)) {
      return;
    }

    if ((this.contextFormGroup.get(this.controlName) as FormGroup).contains(this.stageId.toString())) {
      this.currentFormGroup = (this.contextFormGroup.get(this.controlName) as FormGroup).controls[this.stageId] as FormGroup;

      if (this.stageId === this._quarantineStages.DETECTION &&
         (this.contextFormGroup.get(this.controlName) as FormGroup).contains((this.stageId + 1).toString())) {

        this.dateToInclusiveNextStage = (this.contextFormGroup.get(this.controlName) as FormGroup)
          .get((this.stageId + 1).toString()).get('dateToInclusive').value;
      }
    } else {
      this.buildNewFormGroup();
    }
    this.setDateFromNextStage();
  }

  buildNewFormGroup() {

    (this.contextFormGroup.get(this.controlName) as FormGroup).controls[this.stageId] =
      EditQuarantineStagesComponent.buildFormGroupStagePeriod(this.fb, this.serverSideErrorsProvider, {},
        this.quarantineId, this.stageId);

    this.buildCurrentFormGroup();
  }

  changeDateFrom() {
    this.setDateFromNextStage();
    this.markAsDirty();
  }

  setDateFromNextStage() {
    if (this.currentFormGroup.get('dateToInclusive').value) {
      this.dateFromNextStage = StringHelper.getISODate(DateHelper.addDays(new Date(this.currentFormGroup.get('dateToInclusive').value), 1));
    } else {
      this.dateFromNextStage = null;
    }
  }

  markAsDirty() {
    this.currentFormGroup.updateValueAndValidity();
    this.contextFormGroup.get(this.controlName).updateValueAndValidity();
    this.contextFormGroup.markAsDirty();
  }

  closeCurrentStage() {

    this.alertService.confirmModal(this.stageId < this._quarantineStages.CANCEL
      ? 'Вы уверены, что хотите ' +
        (this.stageId === 0
          ? 'ввести этап ' + (this.noSuspicionStage ? 'выявление' : 'подозрение') + '?'
          : 'закрыть текущий этап и перевести карантин на следующий?')
      : 'Вы уверены, что хотите закрыть карантин?')
      .subscribe(() =>
        this.quarantineDataService.closeCurrentStage(this.quarantineId,
          DateHelper.toDate(this.currentFormGroup.get('dateToInclusive').value),
          DateHelper.toDate(this.dateToInclusiveNextStage))
          .subscribe(res => {
            this.dataCachingService.removeCachedData('EditQuarantine_Data', this.quarantineId.toString());
            this.lookupService.invalidateLookup('disease-type/quarantine-diseases');
            this.dataCachingService.addToCache('EditQuarantine_Data', this.quarantineId.toString(), res.data);
            this.needUpdateQuarantine.next();
          }, error => {
            FormHelper.setSingleFormGroupServerSideValidationErrors(error, this.serverSideErrorsProvider, this.contextFormGroup);
          }));
  }
}
