import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ServerSideErrorsProvider } from '../../../logic/validators/server-side-errors-provider';
import { serverSideErrorsValidator } from '../../../logic/validators/server-side-errors-validator.directive';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AppFormGroup } from '../../../ui/controls/app-form-group';
import { StringHelper } from '../../../helpers/string-helper';

@Component({
  selector: 'app-edit-quarantine-stages',
  templateUrl: './edit-quarantine-stages.component.html',
  styleUrls: ['../edit-quarantine.all.css']
})
export class EditQuarantineStagesComponent {

  public static QUARANTINE_STAGE = {
    SUSPICION: 1,
    DETECTION: 2,
    INTRODUCTION: 3,
    CANCEL: 4
  };

  @Input() id: number;
  @Input() currentStatusId: number;
  @Input() contextFormGroup: AppFormGroup;
  @Input() serverSideErrorsProvider: ServerSideErrorsProvider;
  @Input() isAccessEdit = false;
  @Input() noSuspicionStage = false;

  @Output() dirtyFormGroup = new EventEmitter();
  @Output() needUpdateQuarantine = new EventEmitter();

  isInvalid = FormHelper.isInvalid;
  quarantineStage = EditQuarantineStagesComponent.QUARANTINE_STAGE;

  public static createFormGroupStages(fb: FormBuilder, serverSideErrorsProvider: ServerSideErrorsProvider, val: any) {
    return fb.group({

      stagePeriods: (val.stagePeriods && val.stagePeriods.length
        ? fb.group(val.stagePeriods.reduce((acc, curr) => (acc[curr.quarantineStageId] = fb.group(curr), acc), {}))
        : fb.group({})),

      notification: EditQuarantineStagesComponent.buildFormArrayFiles(fb, serverSideErrorsProvider,
        val, 'notification'),
      notificationDateUpload: val.notificationDateUpload,
      sendRepresentativeToSource: EditQuarantineStagesComponent.buildFormArrayFiles(fb, serverSideErrorsProvider,
        val, 'sendRepresentativeToSource', true),
      sendRepresentativeToSourceDateUpload: val.sendRepresentativeToSourceDateUpload,
      notificationOtherSubject: EditQuarantineStagesComponent.buildFormArrayFiles(fb, serverSideErrorsProvider,
        val, 'notificationOtherSubject'),
      notificationOtherSubjectDateUpload: val.notificationOtherSubjectDateUpload,
      notificationLocalAuthority: EditQuarantineStagesComponent.buildFormArrayFiles(fb, serverSideErrorsProvider,
        val, 'notificationLocalAuthority'),
      notificationLocalAuthorityDateUpload: val.notificationLocalAuthorityDateUpload,
      notificationPeople: EditQuarantineStagesComponent.buildFormArrayFiles(fb, serverSideErrorsProvider,
        val, 'notificationPeople'),
      notificationPeopleDateUpload: val.notificationPeopleDateUpload,
      selectionSamplesToLaboratory: EditQuarantineStagesComponent.buildFormArrayFiles(fb, serverSideErrorsProvider,
        val, 'selectionSamplesToLaboratory'),
      selectionSamplesToLaboratoryDescription: [val.selectionSamplesToLaboratoryDescription,
        Validators.compose([serverSideErrorsValidator('selectionSamplesToLaboratoryDescription', serverSideErrorsProvider)])],
      selectionSamplesToLaboratoryDateUpload: val.selectionSamplesToLaboratoryDateUpload,
      resultFromLaboratory: EditQuarantineStagesComponent.buildFormArrayFiles(fb, serverSideErrorsProvider,
        val, 'resultFromLaboratory'),
      resultFromLaboratoryDateUpload: val.resultFromLaboratoryDateUpload,
      resultFromLaboratoryId: [val.resultFromLaboratoryId,
        Validators.compose([serverSideErrorsValidator('resultFromLaboratoryId', serverSideErrorsProvider)])],
      notificationConcernedAuthorityDetection: EditQuarantineStagesComponent.buildFormArrayFiles(fb, serverSideErrorsProvider,
        val, 'notificationConcernedAuthorityDetection'),
      notificationConcernedAuthorityDetectionDateUpload: val.notificationConcernedAuthorityDetectionDateUpload,
      notificationConcernedAuthorityAbsence: EditQuarantineStagesComponent.buildFormArrayFiles(fb, serverSideErrorsProvider,
        val, 'notificationConcernedAuthorityAbsence'),
      notificationConcernedAuthorityAbsenceDateUpload: val.notificationConcernedAuthorityAbsenceDateUpload,
      actEpizooticExamination: EditQuarantineStagesComponent.buildFormArrayFiles(fb, serverSideErrorsProvider,
        val, 'actEpizooticExamination'),
      actEpizooticExaminationDateUpload: val.actEpizooticExaminationDateUpload,

      sendRepresentativeToVeterinaryControl: EditQuarantineStagesComponent.buildFormArrayFiles(fb, serverSideErrorsProvider,
        val, 'sendRepresentativeToVeterinaryControl'),
      sendRepresentativeToVeterinaryControlDateUpload: val.sendRepresentativeToVeterinaryControlDateUpload,
      urgentReport: EditQuarantineStagesComponent.buildFormArrayFiles(fb, serverSideErrorsProvider,
        val, 'urgentReport', true, true),
      urgentReportDateUpload: val.urgentReportDateUpload,
      performingForcedDisinfection: EditQuarantineStagesComponent.buildFormArrayFiles(fb, serverSideErrorsProvider,
        val, 'performingForcedDisinfection'),
      performingForcedDisinfectionDateUpload: val.performingForcedDisinfectionDateUpload,
      actDestructionCorpse: EditQuarantineStagesComponent.buildFormArrayFiles(fb, serverSideErrorsProvider,
        val, 'actDestructionCorpse'),
      actDestructionCorpseDateUpload: val.actDestructionCorpseDateUpload,
      docOwnership: EditQuarantineStagesComponent.buildFormArrayFiles(fb, serverSideErrorsProvider,
        val, 'docOwnership', true, true),
      docOwnershipDateUpload: val.docOwnershipDateUpload,
      sendSubmissionToConcernedAuthority: EditQuarantineStagesComponent.buildFormArrayFiles(fb, serverSideErrorsProvider,
        val, 'sendSubmissionToConcernedAuthority'),
      sendSubmissionToConcernedAuthorityDateUpload: val.sendSubmissionToConcernedAuthorityDateUpload,
      antiEpizooticExpertCommission: EditQuarantineStagesComponent.buildFormArrayFiles(fb, serverSideErrorsProvider,
        val, 'antiEpizooticExpertCommission', true, true),
      antiEpizooticExpertCommissionDateUpload: val.antiEpizooticExpertCommissionDateUpload,
      orderOnIntroduction: EditQuarantineStagesComponent.buildFormArrayFiles(fb, serverSideErrorsProvider,
        val, 'orderOnIntroduction'),
      orderOnIntroductionDateUpload: val.orderOnIntroductionDateUpload,
      orderToExtend: EditQuarantineStagesComponent.buildFormArrayFiles(fb, serverSideErrorsProvider,
        val, 'orderToExtend'),
      orderToExtendLastDateUpload: val.orderToExtendLastDateUpload,

      informingPeopleAboutDetectionDisease: EditQuarantineStagesComponent.buildFormArrayFiles(fb, serverSideErrorsProvider,
        val, 'informingPeopleAboutDetectionDisease'),
      informingPeopleAboutDetectionDiseaseDateUpload: val.informingPeopleAboutDetectionDiseaseDateUpload,
      implementationPlanForEliminationDisease: EditQuarantineStagesComponent.buildFormArrayFiles(fb, serverSideErrorsProvider,
        val, 'implementationPlanForEliminationDisease', true),
      implementationPlanForEliminationDiseaseDescription: [val.implementationPlanForEliminationDiseaseDescription,
        Validators.compose([serverSideErrorsValidator('implementationPlanForEliminationDiseaseDescription', serverSideErrorsProvider)])],
      implementationPlanForEliminationDiseaseDateUpload: val.implementationPlanForEliminationDiseaseDateUpload,
      implementationPlanForDisinfection: EditQuarantineStagesComponent.buildFormArrayFiles(fb, serverSideErrorsProvider,
        val, 'implementationPlanForDisinfection', true),
      implementationPlanForDisinfectionDescription: [val.implementationPlanForDisinfectionDescription,
        Validators.compose([serverSideErrorsValidator('implementationPlanForDisinfectionDescription', serverSideErrorsProvider)])],
      implementationPlanForDisinfectionDateUpload: val.implementationPlanForDisinfectionDateUpload,

      confirmOfQualityFinalDisinfection: EditQuarantineStagesComponent.buildFormArrayFiles(fb, serverSideErrorsProvider,
        val, 'confirmOfQualityFinalDisinfection'),
      confirmOfQualityFinalDisinfectionDateUpload: val.confirmOfQualityFinalDisinfectionDateUpload,
      actFinalExamination: EditQuarantineStagesComponent.buildFormArrayFiles(fb, serverSideErrorsProvider,
        val, 'actFinalExamination'),
      actFinalExaminationDateUpload: val.actFinalExaminationDateUpload,
      submissionCancel: EditQuarantineStagesComponent.buildFormArrayFiles(fb, serverSideErrorsProvider,
        val, 'submissionCancel'),
      submissionCancelDateUpload: val.submissionCancelDateUpload,
      submissionCancelHighlyDangerous: EditQuarantineStagesComponent.buildFormArrayFiles(fb, serverSideErrorsProvider,
        val, 'submissionCancelHighlyDangerous', true, true),
      submissionCancelHighlyDangerousDateUpload: val.submissionCancelHighlyDangerousDateUpload,
      orderCancel: EditQuarantineStagesComponent.buildFormArrayFiles(fb, serverSideErrorsProvider, val, 'orderCancel'),
      orderCancelDateUpload: val.orderCancelDateUpload,
      reportEventsAfterCancel: EditQuarantineStagesComponent.buildFormArrayFiles(fb, serverSideErrorsProvider,
        val, 'reportEventsAfterCancel'),
      reportEventsAfterCancelDateUpload: val.reportEventsAfterCancelDateUpload,
    });
  }

  public static buildFormArrayFiles(fb: FormBuilder,
                                    serverSideErrorsProvider: ServerSideErrorsProvider,
                                    val: any,
                                    fieldName: string,
                                    withoutNumber: boolean = false,
                                    withoutDate: boolean = false) {
    return val[fieldName] && val[fieldName].length
      ? fb.array(val[fieldName].map((el, ix) => fb.group(this.buildFormGroupFile(fb, serverSideErrorsProvider,
        el || {}, fieldName + '-' + ix, withoutNumber, withoutDate))))
      : fb.array([]);
  }

  public static buildFormGroupFile(fb: FormBuilder,
                                   serverSideErrorsProvider: ServerSideErrorsProvider,
                                   val: any,
                                   formGroupNameRelation: string,
                                   withoutNumber: boolean = false,
                                   withoutDate: boolean = false) {
    return {
      date: [val.date,
        Validators.compose([FormHelper.validateDateTimePicker,
          serverSideErrorsValidator('date', serverSideErrorsProvider, formGroupNameRelation),
          FormHelper.conditionalValidate(Validators.required, ctrl => !withoutDate && ctrl.parent && ctrl.parent.get('uri').value)])],
      number: [val.number,
        Validators.compose([serverSideErrorsValidator('number', serverSideErrorsProvider, formGroupNameRelation),
          FormHelper.conditionalValidate(Validators.required, ctrl => !withoutNumber && ctrl.parent && ctrl.parent.get('uri').value)])],
      uri: [val.uri,
        Validators.compose([serverSideErrorsValidator('uri', serverSideErrorsProvider, formGroupNameRelation)])],
      userIdUpload: val.userIdUpload,
      dateToInclusive: [val.dateToInclusive,
        Validators.compose([serverSideErrorsValidator('dateToInclusive', serverSideErrorsProvider, formGroupNameRelation)])],
      dateUpload: val.dateUpload,
    };
  }

  public static buildFormGroupStagePeriod(fb: FormBuilder,
                                          serverSideErrorsProvider: ServerSideErrorsProvider,
                                          val: any,
                                          quarantineIdDef = null,
                                          quarantineStageIdDef = null) {
    return fb.group({
      quarantineId: val.quarantineId || quarantineIdDef,
      quarantineStageId: val.quarantineStageId || quarantineStageIdDef,
      dateFrom: val.dateFrom,
      dateToInclusive: val.dateToInclusive
    });
  }

  markAsDirty() {
    this.contextFormGroup.markAsDirty();
    this.dirtyFormGroup.next();
  }

  dirtyFormGroupInChildrenComponents($event: any) {
    this.markAsDirty();
  }

  getDatePeriodStage(stageId: number, comment?: string) {
    const stagePeriods = this.contextFormGroup.get('stagePeriods').value;

    if (this.currentStatusId > stageId && (!stagePeriods[stageId] ||
        !stagePeriods[stageId].dateFrom && !stagePeriods[stageId].dateToInclusive)) {
      return comment ? comment : '(пропущен)';
    }

    if (!stagePeriods[stageId] || !stagePeriods[stageId].dateFrom) {
      return '';
    }

    return 'с ' + StringHelper.getRuDate(stagePeriods[stageId].dateFrom) +
      (stagePeriods[stageId].dateToInclusive
      ? ' по ' + StringHelper.getRuDate(stagePeriods[stageId].dateToInclusive)
      : '');
  }
}
