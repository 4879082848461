/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./institution-employee-modal.component.ngfactory";
import * as i2 from "./institution-employee-modal.component";
import * as i3 from "../../../logic/services/institution-data.service";
import * as i4 from "@angular/forms";
import * as i5 from "../../../ui/infrastructure/alert.service";
import * as i6 from "../../../logic/services/security.service";
import * as i7 from "../../../logic/pipes/lookup-source.pipe";
import * as i8 from "../../../logic/services/lookup-source.service";
import * as i9 from "../../../logic/services/address-person-fio-cache.service";
import * as i10 from "@angular/platform-browser";
import * as i11 from "../../../ui/controls/app-grid.component.ngfactory";
import * as i12 from "../../../ui/controls/app-grid.component";
import * as i13 from "../../../logic/services/data-caching.service";
import * as i14 from "@angular/common";
import * as i15 from "@clr/angular";
import * as i16 from "../../../../../node_modules/@clr/angular/clr-angular.ngfactory";
import * as i17 from "./edit-institution-employees.component";
import * as i18 from "../../../ui/infrastructure/global-waiting-overlay.service";
import * as i19 from "../../../logic/services/app-navigation.service";
var styles_EditInstitutionEmployeesComponent = [];
var RenderType_EditInstitutionEmployeesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EditInstitutionEmployeesComponent, data: {} });
export { RenderType_EditInstitutionEmployeesComponent as RenderType_EditInstitutionEmployeesComponent };
function View_EditInstitutionEmployeesComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-institution-employee-modal", [], null, [[null, "addEmployeeModalOpenedChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("addEmployeeModalOpenedChanged" === en)) {
        var pd_0 = (_co.addEmployeeModalOpenedChanged() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_InstitutionEmployeeModalComponent_0, i1.RenderType_InstitutionEmployeeModalComponent)), i0.ɵdid(1, 49152, null, 0, i2.InstitutionEmployeeModalComponent, [i3.InstitutionDataService, i4.FormBuilder, i5.AlertService, i6.SecurityService], { addEmployeeModalOpened: [0, "addEmployeeModalOpened"], model: [1, "model"], currentFormGroup: [2, "currentFormGroup"], currentEmployee: [3, "currentEmployee"], currentIsNew: [4, "currentIsNew"], id: [5, "id"], employees: [6, "employees"] }, { addEmployeeModalOpenedChanged: "addEmployeeModalOpenedChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.addEmployeeModalOpened; var currVal_1 = _co.model; var currVal_2 = _co.currentFormGroup; var currVal_3 = _co.currentEmployee; var currVal_4 = _co.currentIsNew; var currVal_5 = _co.id; var currVal_6 = _co.employees; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_EditInstitutionEmployeesComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i7.LookupSourcePipe, [i8.LookupSourceService, i9.AddressPersonFioCacheService, i10.DomSanitizer]), (_l()(), i0.ɵeld(1, 0, null, null, 32, "div", [["class", "itech-common-wrap"], ["id", "interceptNoAccess"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 4, "div", [["style", "padding: 5px; height: 100%"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 3, "app-grid", [], null, null, null, i11.View_AppGridComponent_0, i11.RenderType_AppGridComponent)), i0.ɵdid(5, 114688, null, 0, i12.AppGridComponent, [i13.DataCachingService, i6.SecurityService], { uniqueGridName: [0, "uniqueGridName"], columnDefs: [1, "columnDefs"], rowData: [2, "rowData"], getRowStyle: [3, "getRowStyle"], exportFileName: [4, "exportFileName"] }, null), i0.ɵppd(6, 2), i0.ɵpid(131072, i14.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(8, 0, null, null, 25, "div", [["class", "itech-form-actions"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 3, "button", [["class", "btn btn-primary-outline"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.appNavigationService.navigateJournal(undefined, 3, _co.id, undefined) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "clr-icon", [["shape", "view-list"]], null, null, null, null, null)), i0.ɵdid(12, 16384, null, 0, i15.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, ["\u00A0\u0416\u0443\u0440\u043D\u0430\u043B "])), (_l()(), i0.ɵeld(14, 0, null, null, 3, "button", [["class", "btn btn-secondary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.refreshFormData() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 1, "clr-icon", [["shape", "refresh"]], null, null, null, null, null)), i0.ɵdid(16, 16384, null, 0, i15.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, ["\u00A0\u041E\u0431\u043D\u043E\u0432\u0438\u0442\u044C "])), (_l()(), i0.ɵeld(18, 0, null, null, 3, "button", [["class", "btn btn-success-outline"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addEmployee() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(19, 0, null, null, 1, "clr-icon", [["shape", "plus"]], null, null, null, null, null)), i0.ɵdid(20, 16384, null, 0, i15.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, ["\u00A0\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C "])), (_l()(), i0.ɵeld(22, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(23, 0, null, null, 6, "button", [["class", "btn btn-primary itech-form-save"]], [[1, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.store() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i16.View_ClrLoadingButton_0, i16.RenderType_ClrLoadingButton)), i0.ɵdid(24, 49152, null, 0, i15.ClrLoadingButton, [i0.ElementRef, i0.Renderer2], { disabled: [0, "disabled"] }, null), i0.ɵprd(2048, null, i15.LoadingListener, null, [i15.ClrLoadingButton]), i0.ɵdid(26, 147456, null, 0, i15.ClrLoading, [[2, i15.LoadingListener]], { loadingState: [0, "loadingState"] }, null), (_l()(), i0.ɵeld(27, 0, null, 0, 1, "clr-icon", [["shape", "check"]], null, null, null, null, null)), i0.ɵdid(28, 16384, null, 0, i15.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, 0, ["\u00A0\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C "])), (_l()(), i0.ɵeld(30, 0, null, null, 3, "button", [["class", "btn btn-warning itech-form-cancel"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelEdit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(31, 0, null, null, 1, "clr-icon", [["shape", "undo"]], null, null, null, null, null)), i0.ɵdid(32, 16384, null, 0, i15.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, ["\u00A0\u041E\u0442\u043C\u0435\u043D\u0438\u0442\u044C "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EditInstitutionEmployeesComponent_1)), i0.ɵdid(35, 16384, null, 0, i14.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "EditInstitutionEmployeesGrid"; var currVal_1 = _co.gridColumnDefs; var currVal_2 = _co.employees; var currVal_3 = _co.getRowStyle; var currVal_4 = ("\u0421\u043F\u0438\u0441\u043E\u043A \u0441\u043E\u0442\u0440\u0443\u0434\u043D\u0438\u043A\u043E\u0432 \u0443\u0447\u0440\u0435\u0436\u0434\u0435\u043D\u0438\u044F " + i0.ɵunv(_v, 5, 4, i0.ɵnov(_v, 7).transform(i0.ɵunv(_v, 5, 4, _ck(_v, 6, 0, i0.ɵnov(_v, 0), _co.id, "institution"))))); _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_6 = (!_co.model.dirty || _co.storing); _ck(_v, 24, 0, currVal_6); var currVal_7 = _co.storing; _ck(_v, 26, 0, currVal_7); var currVal_9 = _co.addEmployeeModalOpened; _ck(_v, 35, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_5 = (i0.ɵnov(_v, 24).disabled ? "" : null); _ck(_v, 23, 0, currVal_5); var currVal_8 = !_co.model.dirty; _ck(_v, 30, 0, currVal_8); }); }
export function View_EditInstitutionEmployeesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-edit-institution-employees", [], null, null, null, View_EditInstitutionEmployeesComponent_0, RenderType_EditInstitutionEmployeesComponent)), i0.ɵdid(1, 49152, null, 0, i17.EditInstitutionEmployeesComponent, [i3.InstitutionDataService, i5.AlertService, i13.DataCachingService, i18.GlobalWaitingOverlayService, i8.LookupSourceService, i19.AppNavigationService, i4.FormBuilder], null, null)], null, null); }
var EditInstitutionEmployeesComponentNgFactory = i0.ɵccf("app-edit-institution-employees", i17.EditInstitutionEmployeesComponent, View_EditInstitutionEmployeesComponent_Host_0, { id: "id" }, {}, []);
export { EditInstitutionEmployeesComponentNgFactory as EditInstitutionEmployeesComponentNgFactory };
