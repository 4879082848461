import { DataCachingService } from '../../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { ProductDataService } from '../../../logic/services/product-data.service';
import { ProductSearchResultsFormComponent } from '../search/product-search-results-form.component';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';
import { AgGridProductTransactionDetailRendererComponent } from '../../../ui/controls/ag-grid-product-transaction-detail-renderer.component';
import { ProductSearchResultsComponent } from '../search/product-search-results.component';
import { AlertService } from '../../../ui/infrastructure/alert.service';
var EditProductSubproductsComponent = /** @class */ (function () {
    function EditProductSubproductsComponent(productDataService, dataCachingService, waitingOverlay, lookupSourceService, navigationService, cacheService, alertService) {
        var _this = this;
        this.productDataService = productDataService;
        this.dataCachingService = dataCachingService;
        this.waitingOverlay = waitingOverlay;
        this.lookupSourceService = lookupSourceService;
        this.navigationService = navigationService;
        this.cacheService = cacheService;
        this.alertService = alertService;
        this.model = {};
        this.isInvalid = FormHelper.isInvalid;
        this.getRowStyle = ProductSearchResultsFormComponent.getRowStyle;
        this.gridColumnDefs = ProductSearchResultsFormComponent.getGridColumnDefs();
        this.detailCellRenderer = 'myDetailCellRenderer';
        this.frameworkComponents = { myDetailCellRenderer: AgGridProductTransactionDetailRendererComponent };
        this.gridIcons = {
            groupContracted: '<clr-icon shape="organization" style="cursor: pointer"></clr-icon>',
        };
        this.animalTypeIdLookup = {};
        this.lookupSourceService.getLookupObj('animal-type').subscribe(function (lookup) { return _this.animalTypeIdLookup = lookup; });
    }
    Object.defineProperty(EditProductSubproductsComponent.prototype, "id", {
        get: function () {
            return this._id;
        },
        set: function (id) {
            if (this._id !== id) {
                this._id = id;
                this.refreshFormDataInternal();
            }
        },
        enumerable: true,
        configurable: true
    });
    EditProductSubproductsComponent.prototype.refreshFormData = function () {
        this.dataCachingService.removeCachedData('EditProductSubproducts', this.id.toString());
        this.refreshFormDataInternal();
    };
    EditProductSubproductsComponent.prototype.refreshFormDataInternal = function () {
        var _this = this;
        this.waitingOverlay.StartWaiting();
        var existing = this.dataCachingService.getCachedData('EditProductSubproducts', this.id.toString());
        if (existing) {
            this.model = existing;
            this.waitingOverlay.EndWaiting();
        }
        else {
            this.productDataService.searchBySourceProduct(this.id)
                .subscribe(function (data) {
                _this.lookupSourceService.getLookupObj('product-subtype').subscribe(function (productSubtypeLookup) {
                    _this.lookupSourceService.getLookupObj('product-status').subscribe(function (productStatusLookup) {
                        _this.lookupSourceService.getLookupObj('transaction-type').subscribe(function (transactionTypeLookup) {
                            data.forEach(function (el) {
                                el.transactionTypeCaption = transactionTypeLookup[el.transactionType];
                                el.statusCaption = productStatusLookup[el.status];
                                if (el.status === -100 && el.templateCaption) {
                                    el.statusCaption = 'Шаблон: ' + el.templateCaption;
                                }
                                if (el.stableProductSubtypeCaption) {
                                    el.productSubtypeIdCaption = el.stableProductSubtypeCaption;
                                }
                                else {
                                    var productSubtypeRec = productSubtypeLookup[el.productTypeId].find(function (ptst) { return ptst.id === el.productSubtypeId; });
                                    el.productSubtypeIdCaption = productSubtypeRec ? productSubtypeRec.caption : undefined;
                                }
                            });
                            _this.model = { searchResults: data, showHistory: _this.model.showHistory, selectedRows: [] };
                            _this.dataCachingService.addToCache('EditProductSubproducts', _this.id.toString(), _this.model);
                            _this.waitingOverlay.EndWaiting();
                        });
                    });
                });
            }, function () {
                _this.waitingOverlay.EndWaiting();
            });
        }
    };
    EditProductSubproductsComponent.prototype.addNew = function () {
        this.navigationService.performProductDerivation(this.id, 4);
    };
    EditProductSubproductsComponent.prototype.onGridSelectionChanged = function (params) {
        this.model.selectedRows = params.api.getSelectedRows();
        this.model.selectedRowsIds = this.model.selectedRows.map(function (x) { return x.id; });
    };
    EditProductSubproductsComponent.prototype.deleteDocument = function () {
        var _this = this;
        ProductSearchResultsComponent.deleteManyDocuments(this.model.selectedRows, this.alertService, this.productDataService, function () { return _this.refreshFormData(); });
    };
    EditProductSubproductsComponent.prototype.sendToRegistration = function () {
        var _this = this;
        ProductSearchResultsComponent.sendManyDocumentsToRegistration(this.model.selectedRows, this.alertService, this.productDataService, function () { return _this.refreshFormData(); });
    };
    EditProductSubproductsComponent.prototype.sendToClearance = function () {
        var _this = this;
        ProductSearchResultsComponent.sendManyDocumentsToClearance(this.model.selectedRows, this.alertService, this.productDataService, function () { return _this.refreshFormData(); });
    };
    EditProductSubproductsComponent.prototype.createTransportForSelectedDocuments = function () {
        ProductSearchResultsComponent.createTransportForSelectedDocuments(this.model.selectedRows, this.navigationService);
    };
    return EditProductSubproductsComponent;
}());
export { EditProductSubproductsComponent };
