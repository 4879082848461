import { QueryService } from '../../logic/services/query.service';
import { FormBuilder, Validators } from '@angular/forms';
import { FormHelper } from '../../ui/controls/form-helper';
import { SecurityService } from '../../logic/services/security.service';
import { AlertService } from '../../ui/infrastructure/alert.service';
import { AppNavigationService } from '../../logic/services/app-navigation.service';
var QueryDetailsQueryComponent = /** @class */ (function () {
    function QueryDetailsQueryComponent(queryService, securityService, appNavigationService, alertService, fb) {
        this.queryService = queryService;
        this.securityService = securityService;
        this.appNavigationService = appNavigationService;
        this.alertService = alertService;
        this.fb = fb;
        this.model = {
            data: [],
            dataColumns: [],
            nodeType: undefined,
            entityType: undefined,
            query: undefined,
            parentCondition: undefined,
            condition: undefined,
            selectedNode: undefined,
            cachedFavorite: undefined
        };
        this.storing = false;
        this.serverSideValidationErrors = [];
        this.isInvalid = FormHelper.isInvalid;
    }
    QueryDetailsQueryComponent.prototype.invalidateContextFormGroup = function () {
        this.model.query.contextFormGroup = undefined;
    };
    Object.defineProperty(QueryDetailsQueryComponent.prototype, "favorite", {
        get: function () {
            if (!this.model) {
                return undefined;
            }
            if (this.model.query.cachedFavorite === undefined) {
                this.model.query.cachedFavorite = this.appNavigationService.getFavoriteQuery(this.model.query.data.id);
            }
            return this.model.query.cachedFavorite;
        },
        set: function (val) {
            if (!this.model) {
                return;
            }
            this.model.query.cachedFavorite = val;
            if (this.appNavigationService.getFavoriteQuery(this.model.query.data.id) !== this.model.query.cachedFavorite) {
                this.appNavigationService.setFavoriteQuery(this.model.query.data.id, this.model.query.contextFormGroup.get('caption').value, this.model.query.cachedFavorite);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QueryDetailsQueryComponent.prototype, "contextFormGroup", {
        get: function () {
            if (!this.model.query.contextFormGroup) {
                if (this.model.query.data.scopeInstitutionEmployeeId) {
                    this.model.query.personGroupVisibility = 3;
                }
                else if (this.model.query.data.scopeInstitutionId) {
                    this.model.query.personGroupVisibility = 2;
                }
                else {
                    this.model.query.personGroupVisibility = 1;
                }
                this.model.query.contextFormGroup = this.fb.group({
                    id: this.model.query.data.id,
                    caption: [this.model.query.data.caption, Validators.required],
                    personGroupVisibility: [this.model.query.personGroupVisibility, Validators.required],
                    entityType: [this.model.entityType.id, Validators.required],
                    showOnlyCount: this.model.query.data.showOnlyCount,
                });
                if (!this.model.query.data.id) {
                    this.model.query.contextFormGroup.markAsDirty();
                }
            }
            return this.model.query.contextFormGroup;
        },
        enumerable: true,
        configurable: true
    });
    QueryDetailsQueryComponent.prototype.store = function () {
        var _this = this;
        if (this.storing) {
            return;
        }
        this.storing = true;
        FormHelper.markAsSubmitted(this.contextFormGroup);
        if (!this.contextFormGroup.valid) {
            return;
        }
        this.securityService.getUserInfo().subscribe(function (userInfo) {
            var dataToStore = _this.contextFormGroup.value;
            if (+dataToStore.personGroupVisibility === 2) {
                dataToStore.scopeInstitutionId = userInfo.institutionId;
                dataToStore.scopeInstitutionEmployeeId = undefined;
            }
            else if (+dataToStore.personGroupVisibility === 3) {
                dataToStore.scopeInstitutionId = userInfo.institutionId;
                dataToStore.scopeInstitutionEmployeeId = userInfo.institutionEmployeeId;
            }
            else {
                dataToStore.scopeInstitutionId = undefined;
                dataToStore.scopeInstitutionEmployeeId = undefined;
            }
            Object.assign(_this.model.query.data, dataToStore);
            _this.queryService.storeQuery(_this.queryService.buildQueryForSave(_this.model.query)).subscribe(function (data) {
                if (!_this.model.query.data.id) {
                    _this.model.query.conditions = [];
                }
                Object.assign(_this.model.query.data, data);
                _this.queryService.updateConditionsEditStructure(_this.model.query.conditions, _this.model.query.data.entityType);
                if (!dataToStore.id) {
                    _this.model.entityType.queries.push({ data: { caption: 'Новая выборка' }, conditions: [] });
                }
                _this.invalidateContextFormGroup();
                _this.storing = false;
            }, function (error) {
                _this.storing = false;
                FormHelper.setSingleFormGroupServerSideValidationErrors(error, _this, _this.contextFormGroup);
            });
        });
    };
    QueryDetailsQueryComponent.prototype.deleteQuery = function () {
        var _this = this;
        this.alertService.confirmModal('Вы уверены, что хотите удалить выборку?').subscribe(function (val) {
            if (!val) {
                return;
            }
            _this.queryService.deleteQuery(_this.model.query.data.id).subscribe(function () {
                var ix = _this.model.entityType.queries.findIndex(function (el) { return el === _this.model.query; });
                if (ix >= 0) {
                    _this.model.entityType.queries.splice(ix, 1);
                    _this.model.query = undefined;
                    _this.model.nodeType = undefined;
                    _this.model.selectedNode = undefined;
                }
            });
        });
    };
    QueryDetailsQueryComponent.prototype.cancelEdit = function () {
    };
    return QueryDetailsQueryComponent;
}());
export { QueryDetailsQueryComponent };
