import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/internal/operators';
import { StringHelper } from '../../helpers/string-helper';

@Injectable({
  providedIn: 'root',
})
export class StableDataService {

  constructor(private http: HttpClient) {
  }

  private static prettifyCommon(data: any) {
    data['cadNo'] = StringHelper.stringCadNumberWithoutColon(data['cadNo']);
    return data;
  }

  public searchByAgent(agentId: number, showHistory: boolean): Observable<any[]> {
    showHistory = !!showHistory;
    const qstring = environment.api + '/stable/search-by-agent/' + agentId + '/' + showHistory;
    return this.http.get(qstring, {headers: {'interceptNoAccess': 'true'}})
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  public updateDefaultHiddenForStables(stables: any[]) {
    const qstring = environment.api + '/stable/update-default-hidden';
    return this.http.post(qstring, stables);
  }

  public search(params: any): Observable<any[]> {
    const qstring = environment.api + '/stable/search';
    return this.http.post(qstring, params)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  getStableCommonForEdit(id: number): Observable<any> {
    const qstring = environment.api + '/stable/' + id.toString(10);
    return this.http.get(qstring, {headers: {'interceptNoAccess': 'true'}});
  }

  getStableCommonForEditWithSync(id: number): Observable<any> {
    const qstring = environment.api + '/stable/resync-with-mercury/' + id.toString(10);
    return this.http.get(qstring, {headers: {'interceptNoAccess': 'true'}})
      .pipe(map((item: any) => {
        return item.data;
      }));
  }

  getStableCommonForLookup(id: number): Observable<any> {
    const qstring = environment.api + '/stable/lookup/' + id.toString(10);
    return this.http.get(qstring);
  }

  storeStableCommonInfo(model: any) {
    const qstring = environment.api + '/stable/store-common';
    return this.http.post(qstring, StableDataService.prettifyCommon(model))
      .pipe(map((response: any, error: any) => {
        return response.data;
      }));
  }

  deleteStable(id: number): Observable<any> {
    const qstring = environment.api + '/stable/delete/' + id.toString(10);
    return this.http.post(qstring, {});
  }

  restoreDeletedStable(id: number): Observable<any> {
    const qstring = environment.api + '/stable/restore-deleted/' + id.toString(10);
    return this.http.post(qstring, {});
  }

  readStableEvents(stableId: number) {
    const qstring = environment.api + '/stable/stable-events/' + stableId;
    return this.http.get(qstring, {headers: {'interceptNoAccess': 'true'}})
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  mergeStable(mainStableId: number, sourceStableId: number) {
    const qstring = environment.api + '/stable/merge/' + mainStableId + '/' + sourceStableId;
    return this.http.get(qstring);
  }

  getAllProductSubtypes(stableId: number) {
    const qstring = environment.api + '/stable/product-subtypes/' + stableId;
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  runStableAgentsSync(stableId: number) {
    const qstring = environment.api + '/stable/run-stable-agents-sync/' + stableId.toString(10);
    return this.http.post(qstring, {});
  }

  startSyncIncomingProducts(stableId: number) {
    const qstring = environment.api + '/stable/start-sync-incoming-products/' + stableId;
    return this.http.post(qstring, {})
      .pipe(map((response: any) => {
        return response as any;
      }));
  }

  backgroundUpdateStableProductSubtype(stableIds: number[]): Observable<any[]> {
    const qstring = environment.api + '/stable/background-update-stable-product-subtype';
    return this.http.post(qstring, stableIds)
      .pipe(map((response: any) => {
        return response.data as any[];
      }));
  }

  getProductionTemplate(stableId: number): Observable<any[]> {
    const qstring = environment.api + '/stable/production-template/' + stableId;
    return this.http.get(qstring, {headers: {'interceptNoAccess': 'true'}})
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  storeProductionTemplate(stableId: number, templates: any[], deleted: any[]): Observable<any[]> {
    const store = {
      stableId: stableId,
      templates: templates,
      delete: deleted,
    };

    const qstring = environment.api + '/stable/production-template/store';
    return this.http.post(qstring, store)
      .pipe(map((response: any) => {
        return response.data as any[];
      }));
  }

  searchStableProductSubtypesFromProductionTemplates(stableId: number, productTypeId: number, productSubtypeId: number): Observable<any[]> {
    const qstring =
      `${environment.api}/stable/production-template/search-stable-product-subtype/${stableId}/${productTypeId}/${productSubtypeId}`;
    return this.http.get(qstring)
      .pipe(map((response: any) => {
        return response as any[];
      }));
  }

  storeStableProductSubtype(id: number, data: any): Observable<any> {
    const qstring = environment.api + '/stable/stable-product-subtype/store/' + id.toString(10);
    return this.http.post(qstring, data)
      .pipe(map((response: any) => {
        return response.data as any;
      }));
  }
}
