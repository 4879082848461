/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/forms";
import * as i3 from "@clr/angular";
import * as i4 from "../../../../../node_modules/@clr/angular/clr-angular.ngfactory";
import * as i5 from "./pst-branch-cell-edit.component";
import * as i6 from "../../../logic/services/lookup-source.service";
import * as i7 from "../../../logic/services/app-navigation.service";
import * as i8 from "../../services/pst-report.service";
import * as i9 from "../../services/pst-report-structure.service";
var styles_PstBranchCellEditComponent = [];
var RenderType_PstBranchCellEditComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PstBranchCellEditComponent, data: {} });
export { RenderType_PstBranchCellEditComponent as RenderType_PstBranchCellEditComponent };
function View_PstBranchCellEditComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formatRuNumeric(_co.indicatorTypeMeta.precision, _co.originalCellValue); _ck(_v, 1, 0, currVal_0); }); }
function View_PstBranchCellEditComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["style", "margin-right: 20px"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["X"]))], null, null); }
function View_PstBranchCellEditComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "span", [["style", "margin-left: 10px"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchCellEditComponent_2)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["crossTemplate", 2]], null, 0, null, View_PstBranchCellEditComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.indicatorTypeMeta.levelFormula !== "-") || _co.indicatorTypeMeta.formula) || (_co.indicatorLevel.level === (_co.indicatorTypeMeta.editableLevel || 60))); var currVal_1 = i0.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_PstBranchCellEditComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["input", 1]], null, 7, "input", [["maxlength", "18"], ["type", "text"]], [[1, "maxlength", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "keypress"], [null, "ngModelChange"], [null, "keydown"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 2)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 2)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("keypress" === en)) {
        var pd_4 = (_co.processMoneyKeypress($event) !== false);
        ad = (pd_4 && ad);
    } if (("ngModelChange" === en)) {
        var pd_5 = ((_co.cellValueStr = $event) !== false);
        ad = (pd_5 && ad);
    } if (("keydown" === en)) {
        var pd_6 = (_co.inputKeyDown($event) !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 16384, null, 0, i2.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵdid(3, 540672, null, 0, i2.MaxLengthValidator, [], { maxlength: [0, "maxlength"] }, null), i0.ɵprd(1024, null, i2.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i2.MaxLengthValidator]), i0.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i0.ɵdid(6, 671744, null, 0, i2.NgModel, [[8, null], [6, i2.NG_VALIDATORS], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i0.ɵdid(8, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = "18"; _ck(_v, 3, 0, currVal_8); var currVal_9 = _co.cellValueStr; _ck(_v, 6, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = (i0.ɵnov(_v, 3).maxlength ? i0.ɵnov(_v, 3).maxlength : null); var currVal_1 = i0.ɵnov(_v, 8).ngClassUntouched; var currVal_2 = i0.ɵnov(_v, 8).ngClassTouched; var currVal_3 = i0.ɵnov(_v, 8).ngClassPristine; var currVal_4 = i0.ɵnov(_v, 8).ngClassDirty; var currVal_5 = i0.ɵnov(_v, 8).ngClassValid; var currVal_6 = i0.ɵnov(_v, 8).ngClassInvalid; var currVal_7 = i0.ɵnov(_v, 8).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
function View_PstBranchCellEditComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "clr-icon", [["clrTooltipTrigger", ""], ["shape", "lock"], ["style", "color: navy"], ["tabindex", "0"]], [[2, "tooltip-trigger", null], [1, "aria-describedby", 0], [1, "role", 0]], [[null, "mouseenter"], [null, "focus"], [null, "mouseleave"], [null, "blur"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).showTooltip() !== false);
        ad = (pd_0 && ad);
    } if (("focus" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).showTooltip() !== false);
        ad = (pd_1 && ad);
    } if (("mouseleave" === en)) {
        var pd_2 = (i0.ɵnov(_v, 2).hideTooltip() !== false);
        ad = (pd_2 && ad);
    } if (("blur" === en)) {
        var pd_3 = (i0.ɵnov(_v, 2).hideTooltip() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i3.ClrIconCustomTag, [], null, null), i0.ɵdid(2, 147456, null, 0, i3.ClrTooltipTrigger, [i3.ɵd, i3.ɵfb], null, null)], null, function (_ck, _v) { var currVal_0 = true; var currVal_1 = i0.ɵnov(_v, 2).ariaDescribedBy; var currVal_2 = "button"; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_PstBranchCellEditComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "clr-icon", [["clrTooltipTrigger", ""], ["shape", "check"], ["style", "color: green"], ["tabindex", "0"]], [[2, "tooltip-trigger", null], [1, "aria-describedby", 0], [1, "role", 0]], [[null, "mouseenter"], [null, "focus"], [null, "mouseleave"], [null, "blur"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).showTooltip() !== false);
        ad = (pd_0 && ad);
    } if (("focus" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).showTooltip() !== false);
        ad = (pd_1 && ad);
    } if (("mouseleave" === en)) {
        var pd_2 = (i0.ɵnov(_v, 2).hideTooltip() !== false);
        ad = (pd_2 && ad);
    } if (("blur" === en)) {
        var pd_3 = (i0.ɵnov(_v, 2).hideTooltip() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i3.ClrIconCustomTag, [], null, null), i0.ɵdid(2, 147456, null, 0, i3.ClrTooltipTrigger, [i3.ɵd, i3.ɵfb], null, null)], null, function (_ck, _v) { var currVal_0 = true; var currVal_1 = i0.ɵnov(_v, 2).ariaDescribedBy; var currVal_2 = "button"; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_PstBranchCellEditComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "clr-tooltip-content", [["clrPosition", "top-right"], ["clrSize", "lg"]], [[2, "tooltip-content", null], [4, "opacity", null], [1, "role", 0], [8, "id", 0], [2, "is-off-screen", null]], null, null, i4.View_ClrTooltipContent_0, i4.RenderType_ClrTooltipContent)), i0.ɵdid(1, 8568832, null, 0, i3.ClrTooltipContent, [i0.Injector, [2, i3.ɵh], i3.ɵo, i3.ɵfb], { position: [0, "position"], size: [1, "size"] }, null), (_l()(), i0.ɵted(2, 0, [" ", " "])), (_l()(), i0.ɵeld(3, 0, null, 0, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, 0, [" \u0414\u0430\u0442\u0430 \u043F\u043E\u043A\u0430\u0437\u0430\u0442\u0435\u043B\u044F: ", " "]))], function (_ck, _v) { var currVal_5 = "top-right"; var currVal_6 = "lg"; _ck(_v, 1, 0, currVal_5, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = 1; var currVal_2 = "tooltip"; var currVal_3 = i0.ɵnov(_v, 1).id; var currVal_4 = i0.ɵnov(_v, 1).isOffScreen; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_7 = _co.getIndicatorHint(); _ck(_v, 2, 0, currVal_7); var currVal_8 = _co.originalCellData.date; _ck(_v, 4, 0, currVal_8); }); }
function View_PstBranchCellEditComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [["class", "it-indicator-icon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 11, "clr-tooltip", [], [[2, "tooltip", null]], null, null, i4.View_ClrTooltip_0, i4.RenderType_ClrTooltip)), i0.ɵprd(4608, null, i3.ɵd, i3.ɵd, []), i0.ɵprd(4608, null, i3.ɵfb, i3.ɵfb, []), i0.ɵprd(6144, null, i3.ɵh, null, [i0.ElementRef]), i0.ɵprd(5120, null, i3.ɵo, i3.ɵp, []), i0.ɵdid(6, 49152, null, 0, i3.ClrTooltip, [], null, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_PstBranchCellEditComponent_6)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_PstBranchCellEditComponent_7)), i0.ɵdid(10, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_PstBranchCellEditComponent_8)), i0.ɵdid(12, 147456, null, 0, i3.ClrIfOpen, [i3.ɵd, i0.TemplateRef, i0.ViewContainerRef], { open: [0, "open"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.originalCellData.state === 10); _ck(_v, 8, 0, currVal_1); var currVal_2 = (_co.originalCellData.state === 200); _ck(_v, 10, 0, currVal_2); var currVal_3 = null; _ck(_v, 12, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = true; _ck(_v, 1, 0, currVal_0); }); }
function View_PstBranchCellEditComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "it-focus-border"]], [[2, "it-cell-storing", null], [2, "it-cell-error", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.__cl_storing_indicator; var currVal_1 = _co.model.__cl_storing_error; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_PstBranchCellEditComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "it-cell-error-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.__cl_storing_error; _ck(_v, 1, 0, currVal_0); }); }
export function View_PstBranchCellEditComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { inputControl: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchCellEditComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchCellEditComponent_4)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchCellEditComponent_5)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchCellEditComponent_9)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchCellEditComponent_10)), i0.ɵdid(10, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.indicatorTypeMeta && !_co.editing); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.indicatorTypeMeta && _co.editing); _ck(_v, 4, 0, currVal_1); var currVal_2 = (((_co.indicatorTypeMeta && !_co.editing) && _co.originalCellData) && ((_co.originalCellData.state === 10) || (_co.originalCellData.state === 200))); _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.focused; _ck(_v, 8, 0, currVal_3); var currVal_4 = (_co.focused && _co.model.__cl_storing_error); _ck(_v, 10, 0, currVal_4); }, null); }
export function View_PstBranchCellEditComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-branch-cell-edit", [], null, null, null, View_PstBranchCellEditComponent_0, RenderType_PstBranchCellEditComponent)), i0.ɵdid(1, 573440, null, 0, i5.PstBranchCellEditComponent, [i6.LookupSourceService, i7.AppNavigationService, i8.PstReportService, i9.PstReportStructureService], null, null)], null, null); }
var PstBranchCellEditComponentNgFactory = i0.ɵccf("app-branch-cell-edit", i5.PstBranchCellEditComponent, View_PstBranchCellEditComponent_Host_0, { branch: "branch", model: "model", reportIndicator: "reportIndicator", indicatorTypeMeta: "indicatorTypeMeta", indicatorLevel: "indicatorLevel", editing: "editing", focused: "focused" }, {}, []);
export { PstBranchCellEditComponentNgFactory as PstBranchCellEditComponentNgFactory };
