import * as tslib_1 from "tslib";
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { MetadataService } from '../../../logic/services/metadata.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MetaBrowseBaseComponent } from '../meta-browse.base.component';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
var AddrCityBrowseComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AddrCityBrowseComponent, _super);
    function AddrCityBrowseComponent(route, metadataService, alertService, globalWaitingOverlayService, lookupService, fb, bookmarkService, dataCachingService) {
        var _this = _super.call(this, route, metadataService, alertService, globalWaitingOverlayService, lookupService, fb, bookmarkService, dataCachingService) || this;
        _this.gridColumnDefs = [
            { headerName: '№', field: 'id', width: 100 },
            {
                headerName: 'Тип', field: 'type', width: 100,
                valueFormatter: function (params) { return _this.addrCityTypeMap[params.value]; }
            },
            { headerName: 'Наименование', field: 'caption', width: 300 },
            {
                headerName: 'Муниципальное образование', field: 'regionId', tooltipField: 'regionId', width: 250,
                valueFormatter: function (params) { return _this.addrRegionMap[params.value]; }
            },
            { headerName: 'РК', field: 'rk', tooltipField: 'rk', width: 100 },
            { headerName: 'Код ПФР 6.2', field: 'codePfr62', tooltipField: 'codePfr62', width: 180 }
        ];
        _this.lookupService.getLookupObj('addr-city-type').subscribe(function (dict) { _this.addrCityTypeMap = dict; });
        _this.lookupService.getLookupObj('addr-region').subscribe(function (dict) { _this.addrRegionMap = dict; });
        return _this;
    }
    AddrCityBrowseComponent.prototype.getMetaTitle = function () {
        return 'Населенные пункты';
    };
    AddrCityBrowseComponent.prototype.getMetaName = function () {
        return 'addr-city';
    };
    AddrCityBrowseComponent.prototype.getId = function (formGroup) {
        return formGroup.get('regionId').value && formGroup.get('id').value
            ? formGroup.get('regionId').value + '/' + formGroup.get('id').value
            : undefined;
    };
    AddrCityBrowseComponent.prototype.getGroupDef = function (row) {
        return {
            id: row.id,
            type: [row.type, Validators.required],
            regionId: [row.regionId, Validators.required],
            caption: [row.caption, Validators.required],
            rk: [row.rk, Validators.compose([Validators.pattern(/^\d\.\d$/), Validators.required])],
            isDefault: false,
            codePfr62: row.codePfr62,
            aoguid: row.aoguid,
        };
    };
    return AddrCityBrowseComponent;
}(MetaBrowseBaseComponent));
export { AddrCityBrowseComponent };
