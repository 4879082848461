/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./pst-branch-cell-date-edit.component.ngfactory";
import * as i3 from "./pst-branch-cell-date-edit.component";
import * as i4 from "../../../logic/services/lookup-source.service";
import * as i5 from "../../../logic/services/app-navigation.service";
import * as i6 from "../../services/pst-report-structure.service";
import * as i7 from "./pst-branch-cell-text-edit.component.ngfactory";
import * as i8 from "./pst-branch-cell-text-edit.component";
import * as i9 from "./pst-branch-cell-lookup-edit.component.ngfactory";
import * as i10 from "./pst-branch-cell-lookup-edit.component";
import * as i11 from "./pst-branch-cell-edit.component.ngfactory";
import * as i12 from "./pst-branch-cell-edit.component";
import * as i13 from "../../services/pst-report.service";
import * as i14 from "@clr/angular";
import * as i15 from "./pst-branch-buy-deal-edit.component";
import * as i16 from "../../../logic/services/address-person-fio-cache.service";
import * as i17 from "../../../logic/services/security.service";
import * as i18 from "../../../ui/infrastructure/alert.service";
var styles_PstBranchBuyDealEditComponent = [];
var RenderType_PstBranchBuyDealEditComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PstBranchBuyDealEditComponent, data: {} });
export { RenderType_PstBranchBuyDealEditComponent as RenderType_PstBranchBuyDealEditComponent };
function View_PstBranchBuyDealEditComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.branch.displayTitle; _ck(_v, 1, 0, currVal_0); }); }
function View_PstBranchBuyDealEditComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "td", [["style", "width: 30px"]], [[1, "rowspan", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" \u2116"])), (_l()(), i0.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u043F/\u043F "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.branch.headerRows.length; _ck(_v, 0, 0, currVal_0); }); }
function View_PstBranchBuyDealEditComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "it-title-cell-overlay"]], [[4, "left", null], [4, "right", null]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" \u041D\u0430\u0438\u043C\u0435\u043D\u043E\u0432\u0430\u043D\u0438\u0435 \u043F\u0440\u0435\u0434\u043F\u0440\u0438\u044F\u0442\u0438\u044F "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.scrollLeft - 31) + "px"); var currVal_1 = (("-" + (_co.scrollLeft - 31)) + "px"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_PstBranchBuyDealEditComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "td", [["style", "width: 180px"]], [[1, "rowspan", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" \u041D\u0430\u0438\u043C\u0435\u043D\u043E\u0432\u0430\u043D\u0438\u0435 \u043F\u0440\u0435\u0434\u043F\u0440\u0438\u044F\u0442\u0438\u044F "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_6)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.scrollLeft > 30); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.branch.headerRows.length; _ck(_v, 0, 0, currVal_0); }); }
function View_PstBranchBuyDealEditComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "td", [["class", "it-indicator"]], [[1, "colspan", 0], [1, "rowspan", 0], [4, "width", null], [2, "it-vertical", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.colSpan; var currVal_1 = _v.parent.context.$implicit.rowSpan; var currVal_2 = ((_v.parent.context.$implicit.meta.displayWidth && _v.parent.context.$implicit.lastRow) ? (_v.parent.context.$implicit.meta.displayWidth + "px") : undefined); var currVal_3 = (_v.parent.context.$implicit.meta.displayVertical && _v.parent.context.$implicit.lastRow); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _v.parent.context.$implicit.displayTitle; _ck(_v, 2, 0, currVal_4); }); }
function View_PstBranchBuyDealEditComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_8)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.rowSpan && _v.context.$implicit.colSpan); _ck(_v, 2, 0, currVal_0); }, null); }
function View_PstBranchBuyDealEditComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_4)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_5)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_7)), i0.ɵdid(6, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.context.index === 0); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.index === 0); _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit; _ck(_v, 6, 0, currVal_2); }, null); }
function View_PstBranchBuyDealEditComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "tr", [["class", "it-row-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "td", [], [[1, "colspan", 0]], null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.branch.headerRows[(_co.branch.headerRows.length - 1)].length + 8); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.caption; _ck(_v, 2, 0, currVal_1); }); }
function View_PstBranchBuyDealEditComponent_12(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "tr", [["class", "it-row-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "td", [], [[1, "colspan", 0]], null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.branch.headerRows[(_co.branch.headerRows.length - 1)].length + 8); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.caption; _ck(_v, 2, 0, currVal_1); }); }
function View_PstBranchBuyDealEditComponent_14(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "tr", [["class", "it-row-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "td", [], [[1, "colspan", 0]], null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.branch.headerRows[(_co.branch.headerRows.length - 1)].length + 8); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.caption; _ck(_v, 2, 0, currVal_1); }); }
function View_PstBranchBuyDealEditComponent_18(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "it-title-cell-overlay"], ["style", "background-color: white"]], [[4, "left", null], [4, "right", null]], null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.scrollLeft - 31) + "px"); var currVal_1 = (("-" + (_co.scrollLeft - 31)) + "px"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _v.parent.context.$implicit.caption; _ck(_v, 1, 0, currVal_2); }); }
function View_PstBranchBuyDealEditComponent_20(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-branch-cell-date-edit", [], null, [[null, "changed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changed" === en)) {
        var pd_0 = (_co.buyDealChanged($event, _v.parent.parent.context.$implicit, _co.buyDealMeta[_v.parent.context.index], _v.parent.parent.parent.context.$implicit.children) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_PstBranchCellDateEditComponent_0, i2.RenderType_PstBranchCellDateEditComponent)), i0.ɵdid(1, 573440, null, 0, i3.PstBranchCellDateEditComponent, [i4.LookupSourceService, i5.AppNavigationService, i0.ChangeDetectorRef, i6.PstReportStructureService], { branch: [0, "branch"], model: [1, "model"], originalCellValue: [2, "originalCellValue"], editing: [3, "editing"], focused: [4, "focused"] }, { changed: "changed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.branch; var currVal_1 = _co.model; var currVal_2 = _v.parent.parent.context.$implicit.buyDealData[_co.buyDealMeta[_v.parent.context.index].fieldName]; var currVal_3 = _co.getEditing(_v.parent.parent.context.$implicit, _v.parent.context.index, _v.parent.parent.parent.parent.parent.parent.parent.context.$implicit); var currVal_4 = _co.getFocused(_v.parent.parent.context.$implicit, _v.parent.context.index, _v.parent.parent.parent.parent.parent.parent.parent.context.$implicit); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_PstBranchBuyDealEditComponent_21(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-branch-cell-text-edit", [], null, [[null, "changed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changed" === en)) {
        var pd_0 = (_co.buyDealChanged($event, _v.parent.parent.context.$implicit, _co.buyDealMeta[_v.parent.context.index], _v.parent.parent.parent.context.$implicit.children) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_PstBranchCellTextEditComponent_0, i7.RenderType_PstBranchCellTextEditComponent)), i0.ɵdid(1, 573440, null, 0, i8.PstBranchCellTextEditComponent, [], { branch: [0, "branch"], model: [1, "model"], originalCellValue: [2, "originalCellValue"], editing: [3, "editing"], focused: [4, "focused"] }, { changed: "changed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.branch; var currVal_1 = _co.model; var currVal_2 = _v.parent.parent.context.$implicit.buyDealData[_co.buyDealMeta[_v.parent.context.index].fieldName]; var currVal_3 = _co.getEditing(_v.parent.parent.context.$implicit, _v.parent.context.index, _v.parent.parent.parent.parent.parent.parent.parent.context.$implicit); var currVal_4 = _co.getFocused(_v.parent.parent.context.$implicit, _v.parent.context.index, _v.parent.parent.parent.parent.parent.parent.parent.context.$implicit); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_PstBranchBuyDealEditComponent_22(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-branch-cell-lookup-edit", [], null, [[null, "changed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changed" === en)) {
        var pd_0 = (_co.buyDealChanged($event, _v.parent.parent.context.$implicit, _co.buyDealMeta[_v.parent.context.index], _v.parent.parent.parent.context.$implicit.children) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_PstBranchCellLookupEditComponent_0, i9.RenderType_PstBranchCellLookupEditComponent)), i0.ɵdid(1, 573440, null, 0, i10.PstBranchCellLookupEditComponent, [i4.LookupSourceService, i5.AppNavigationService, i0.ChangeDetectorRef, i6.PstReportStructureService], { branch: [0, "branch"], model: [1, "model"], lookupName: [2, "lookupName"], originalCellValue: [3, "originalCellValue"], editing: [4, "editing"], focused: [5, "focused"] }, { changed: "changed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.branch; var currVal_1 = _co.model; var currVal_2 = _co.buyDealMeta[_v.parent.context.index].lookupName; var currVal_3 = _v.parent.parent.context.$implicit.buyDealData[_co.buyDealMeta[_v.parent.context.index].fieldName]; var currVal_4 = _co.getEditing(_v.parent.parent.context.$implicit, _v.parent.context.index, _v.parent.parent.parent.parent.parent.parent.parent.context.$implicit); var currVal_5 = _co.getFocused(_v.parent.parent.context.$implicit, _v.parent.context.index, _v.parent.parent.parent.parent.parent.parent.parent.context.$implicit); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_PstBranchBuyDealEditComponent_23(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-branch-cell-edit", [], null, null, null, i11.View_PstBranchCellEditComponent_0, i11.RenderType_PstBranchCellEditComponent)), i0.ɵdid(1, 573440, null, 0, i12.PstBranchCellEditComponent, [i4.LookupSourceService, i5.AppNavigationService, i13.PstReportService, i6.PstReportStructureService], { branch: [0, "branch"], model: [1, "model"], reportIndicator: [2, "reportIndicator"], indicatorTypeMeta: [3, "indicatorTypeMeta"], indicatorLevel: [4, "indicatorLevel"], editing: [5, "editing"], focused: [6, "focused"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.branch; var currVal_1 = _co.model; var currVal_2 = _v.parent.parent.parent.parent.parent.parent.parent.context.$implicit.indicators[_v.parent.context.index]; var currVal_3 = _v.parent.parent.context.$implicit.indicatorTypeMeta[_v.parent.context.index]; var currVal_4 = _v.parent.parent.context.$implicit; var currVal_5 = _co.getEditing(_v.parent.parent.context.$implicit, _v.parent.context.index, _v.parent.parent.parent.parent.parent.parent.parent.context.$implicit); var currVal_6 = _co.getFocused(_v.parent.parent.context.$implicit, _v.parent.context.index, _v.parent.parent.parent.parent.parent.parent.parent.context.$implicit); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_PstBranchBuyDealEditComponent_19(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "td", [["class", "it-indicator"]], [[2, "it-read-only", null], [1, "colspan", 0], [2, "it-focused", null], [2, "it-editing", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cellClick(_v.parent.context.$implicit, _v.context.index, _v.parent.parent.parent.parent.parent.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_20)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_21)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_22)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_23)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = (_co.buyDealMeta[_v.context.index] && (_co.buyDealMeta[_v.context.index].type === "date")); _ck(_v, 2, 0, currVal_4); var currVal_5 = (_co.buyDealMeta[_v.context.index] && (_co.buyDealMeta[_v.context.index].type === "text")); _ck(_v, 4, 0, currVal_5); var currVal_6 = (_co.buyDealMeta[_v.context.index] && (_co.buyDealMeta[_v.context.index].type === "lookup")); _ck(_v, 6, 0, currVal_6); var currVal_7 = _v.parent.context.$implicit.indicatorTypeMeta[_v.context.index]; _ck(_v, 8, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.parent.context.$implicit.indicatorTypeMeta[_v.context.index] && _v.parent.context.$implicit.indicatorTypeMeta[_v.context.index].formula); var currVal_1 = _v.context.$implicit.colSpan; var currVal_2 = _co.getFocused(_v.parent.context.$implicit, _v.context.index, _v.parent.parent.parent.parent.parent.parent.context.$implicit); var currVal_3 = _co.getEditing(_v.parent.context.$implicit, _v.context.index, _v.parent.parent.parent.parent.parent.parent.context.$implicit); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_PstBranchBuyDealEditComponent_17(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵeld(4, 0, null, null, 3, "td", [], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, [" ", " "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_18)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_19)), i0.ɵdid(9, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.scrollLeft > 30); _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.branch.headerRows[(_co.branch.headerRows.length - 1)]; _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (_v.context.index + 1); _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit.caption; _ck(_v, 5, 0, currVal_1); }); }
function View_PstBranchBuyDealEditComponent_16(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_17)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.children; _ck(_v, 2, 0, currVal_0); }, null); }
function View_PstBranchBuyDealEditComponent_25(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "it-title-cell-overlay"]], [[4, "left", null], [4, "right", null]], null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.scrollLeft - 31) + "px"); var currVal_1 = (("-" + (_co.scrollLeft - 31)) + "px"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _v.parent.parent.context.$implicit.caption; _ck(_v, 1, 0, currVal_2); }); }
function View_PstBranchBuyDealEditComponent_24(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "td", [["colspan", "2"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", " "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_25)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.scrollLeft > 30); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.caption; _ck(_v, 1, 0, currVal_0); }); }
function View_PstBranchBuyDealEditComponent_27(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "it-title-cell-overlay"]], [[4, "left", null], [4, "right", null]], null, null, null, null)), (_l()(), i0.ɵted(1, null, [" \u0418\u0442\u043E\u0433\u043E \u043F\u043E \u00AB", "\u00BB: "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.scrollLeft - 31) + "px"); var currVal_1 = (("-" + (_co.scrollLeft - 31)) + "px"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _v.parent.parent.context.$implicit.caption; _ck(_v, 1, 0, currVal_2); }); }
function View_PstBranchBuyDealEditComponent_26(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "td", [["colspan", "2"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["\u0418\u0442\u043E\u0433\u043E \u043F\u043E \u00AB", "\u00BB: "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_27)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.scrollLeft > 30); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.caption; _ck(_v, 1, 0, currVal_0); }); }
function View_PstBranchBuyDealEditComponent_29(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-branch-cell-edit", [], null, null, null, i11.View_PstBranchCellEditComponent_0, i11.RenderType_PstBranchCellEditComponent)), i0.ɵdid(1, 573440, null, 0, i12.PstBranchCellEditComponent, [i4.LookupSourceService, i5.AppNavigationService, i13.PstReportService, i6.PstReportStructureService], { branch: [0, "branch"], model: [1, "model"], reportIndicator: [2, "reportIndicator"], indicatorTypeMeta: [3, "indicatorTypeMeta"], indicatorLevel: [4, "indicatorLevel"], editing: [5, "editing"], focused: [6, "focused"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.branch; var currVal_1 = _co.model; var currVal_2 = _v.parent.parent.parent.parent.parent.context.$implicit.indicators[_v.parent.context.index]; var currVal_3 = _v.parent.parent.context.$implicit.indicatorTypeMeta[_v.parent.context.index]; var currVal_4 = _v.parent.parent.context.$implicit; var currVal_5 = _co.getEditing(_v.parent.parent.context.$implicit, _v.parent.context.index, _v.parent.parent.parent.parent.parent.context.$implicit); var currVal_6 = _co.getFocused(_v.parent.parent.context.$implicit, _v.parent.context.index, _v.parent.parent.parent.parent.parent.context.$implicit); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_PstBranchBuyDealEditComponent_28(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "td", [["class", "it-indicator"]], [[2, "it-read-only", null], [1, "colspan", 0], [2, "it-focused", null], [2, "it-editing", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cellClick(_v.parent.context.$implicit, _v.context.index, _v.parent.parent.parent.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_29)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_4 = _v.parent.context.$implicit.indicatorTypeMeta[_v.context.index]; _ck(_v, 2, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.parent.context.$implicit.indicatorTypeMeta[_v.context.index] && _v.parent.context.$implicit.indicatorTypeMeta[_v.context.index].formula); var currVal_1 = _v.context.$implicit.colSpan; var currVal_2 = _co.getFocused(_v.parent.context.$implicit, _v.context.index, _v.parent.parent.parent.parent.context.$implicit); var currVal_3 = _co.getEditing(_v.parent.context.$implicit, _v.context.index, _v.parent.parent.parent.parent.context.$implicit); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_PstBranchBuyDealEditComponent_15(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_16)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 6, "tr", [["class", "it-row-footer"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_24)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_26)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_28)), i0.ɵdid(9, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.children; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_v.context.$implicit.children.length; _ck(_v, 5, 0, currVal_1); var currVal_2 = _v.context.$implicit.children.length; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.branch.headerRows[(_co.branch.headerRows.length - 1)]; _ck(_v, 9, 0, currVal_3); }, null); }
function View_PstBranchBuyDealEditComponent_31(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "it-title-cell-overlay"]], [[4, "left", null], [4, "right", null]], null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.scrollLeft - 31) + "px"); var currVal_1 = (("-" + (_co.scrollLeft - 31)) + "px"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = ((_co.model.reportMeta.regionId && _v.parent.parent.parent.parent.context.$implicit.caption) ? _v.parent.parent.parent.parent.context.$implicit.caption : _v.parent.parent.context.$implicit.caption); _ck(_v, 1, 0, currVal_2); }); }
function View_PstBranchBuyDealEditComponent_30(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "td", [["colspan", "2"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_31)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.scrollLeft > 30); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.model.reportMeta.regionId && _v.parent.parent.parent.context.$implicit.caption) ? _v.parent.parent.parent.context.$implicit.caption : _v.parent.context.$implicit.caption); _ck(_v, 1, 0, currVal_0); }); }
function View_PstBranchBuyDealEditComponent_33(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "it-title-cell-overlay"]], [[4, "left", null], [4, "right", null]], null, null, null, null)), (_l()(), i0.ɵted(1, null, [" \u0418\u0442\u043E\u0433\u043E \u043F\u043E \u00AB", "\u00BB: "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.scrollLeft - 31) + "px"); var currVal_1 = (("-" + (_co.scrollLeft - 31)) + "px"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = ((_co.model.reportMeta.regionId && _v.parent.parent.parent.parent.context.$implicit.caption) ? _v.parent.parent.parent.parent.context.$implicit.caption : _v.parent.parent.context.$implicit.caption); _ck(_v, 1, 0, currVal_2); }); }
function View_PstBranchBuyDealEditComponent_32(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "td", [["colspan", "2"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" \u0418\u0442\u043E\u0433\u043E \u043F\u043E \u00AB", "\u00BB: "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_33)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.scrollLeft > 30); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.model.reportMeta.regionId && _v.parent.parent.parent.context.$implicit.caption) ? _v.parent.parent.parent.context.$implicit.caption : _v.parent.context.$implicit.caption); _ck(_v, 1, 0, currVal_0); }); }
function View_PstBranchBuyDealEditComponent_35(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-branch-cell-edit", [], null, null, null, i11.View_PstBranchCellEditComponent_0, i11.RenderType_PstBranchCellEditComponent)), i0.ɵdid(1, 573440, null, 0, i12.PstBranchCellEditComponent, [i4.LookupSourceService, i5.AppNavigationService, i13.PstReportService, i6.PstReportStructureService], { branch: [0, "branch"], model: [1, "model"], reportIndicator: [2, "reportIndicator"], indicatorTypeMeta: [3, "indicatorTypeMeta"], indicatorLevel: [4, "indicatorLevel"], editing: [5, "editing"], focused: [6, "focused"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.branch; var currVal_1 = _co.model; var currVal_2 = _v.parent.parent.parent.parent.context.$implicit.indicators[_v.parent.context.index]; var currVal_3 = _v.parent.parent.context.$implicit.indicatorTypeMeta[_v.parent.context.index]; var currVal_4 = _v.parent.parent.context.$implicit; var currVal_5 = _co.getEditing(_v.parent.parent.context.$implicit, _v.parent.context.index, _v.parent.parent.parent.parent.context.$implicit); var currVal_6 = _co.getFocused(_v.parent.parent.context.$implicit, _v.parent.context.index, _v.parent.parent.parent.parent.context.$implicit); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_PstBranchBuyDealEditComponent_34(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "td", [["class", "it-indicator"]], [[1, "colspan", 0], [2, "it-read-only", null], [2, "it-focused", null], [2, "it-editing", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cellClick(_v.parent.context.$implicit, _v.context.index, _v.parent.parent.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_35)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_4 = _v.parent.context.$implicit.indicatorTypeMeta[_v.context.index]; _ck(_v, 2, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.colSpan; var currVal_1 = (_v.parent.context.$implicit.indicatorTypeMeta[_v.context.index] && _v.parent.context.$implicit.indicatorTypeMeta[_v.context.index].formula); var currVal_2 = _co.getFocused(_v.parent.context.$implicit, _v.context.index, _v.parent.parent.parent.context.$implicit); var currVal_3 = _co.getEditing(_v.parent.context.$implicit, _v.context.index, _v.parent.parent.parent.context.$implicit); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_PstBranchBuyDealEditComponent_13(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_14)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_15)), i0.ɵdid(4, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 6, "tr", [["class", "it-row-footer"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_30)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_32)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_34)), i0.ɵdid(11, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.model.reportMeta.regionId && _v.context.$implicit.children.length); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.children; _ck(_v, 4, 0, currVal_1); var currVal_2 = !_v.context.$implicit.children.length; _ck(_v, 7, 0, currVal_2); var currVal_3 = _v.context.$implicit.children.length; _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.branch.headerRows[(_co.branch.headerRows.length - 1)]; _ck(_v, 11, 0, currVal_4); }, null); }
function View_PstBranchBuyDealEditComponent_38(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "it-title-cell-overlay"]], [[4, "left", null], [4, "right", null]], null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.scrollLeft - 31) + "px"); var currVal_1 = (("-" + (_co.scrollLeft - 31)) + "px"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _v.parent.parent.parent.context.$implicit.caption; _ck(_v, 1, 0, currVal_2); }); }
function View_PstBranchBuyDealEditComponent_37(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "td", [["colspan", "2"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", " "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_38)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.scrollLeft > 30); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.caption; _ck(_v, 1, 0, currVal_0); }); }
function View_PstBranchBuyDealEditComponent_40(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "it-title-cell-overlay"]], [[4, "left", null], [4, "right", null]], null, null, null, null)), (_l()(), i0.ɵted(1, null, [" \u0418\u0442\u043E\u0433\u043E \u043F\u043E \u00AB", "\u00BB: "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.scrollLeft - 31) + "px"); var currVal_1 = (("-" + (_co.scrollLeft - 31)) + "px"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _v.parent.parent.parent.context.$implicit.caption; _ck(_v, 1, 0, currVal_2); }); }
function View_PstBranchBuyDealEditComponent_39(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "td", [["colspan", "2"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["\u0418\u0442\u043E\u0433\u043E \u043F\u043E \u00AB", "\u00BB: "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_40)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.scrollLeft > 30); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.caption; _ck(_v, 1, 0, currVal_0); }); }
function View_PstBranchBuyDealEditComponent_42(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-branch-cell-edit", [], null, null, null, i11.View_PstBranchCellEditComponent_0, i11.RenderType_PstBranchCellEditComponent)), i0.ɵdid(1, 573440, null, 0, i12.PstBranchCellEditComponent, [i4.LookupSourceService, i5.AppNavigationService, i13.PstReportService, i6.PstReportStructureService], { branch: [0, "branch"], model: [1, "model"], reportIndicator: [2, "reportIndicator"], indicatorTypeMeta: [3, "indicatorTypeMeta"], indicatorLevel: [4, "indicatorLevel"], editing: [5, "editing"], focused: [6, "focused"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.branch; var currVal_1 = _co.model; var currVal_2 = _v.parent.parent.parent.parent.context.$implicit.indicators[_v.parent.context.index]; var currVal_3 = _v.parent.parent.parent.context.$implicit.indicatorTypeMeta[_v.parent.context.index]; var currVal_4 = _v.parent.parent.parent.context.$implicit; var currVal_5 = _co.getEditing(_v.parent.parent.parent.context.$implicit, _v.parent.context.index, _v.parent.parent.parent.parent.context.$implicit); var currVal_6 = _co.getFocused(_v.parent.parent.parent.context.$implicit, _v.parent.context.index, _v.parent.parent.parent.parent.context.$implicit); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_PstBranchBuyDealEditComponent_41(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "td", [["class", "it-indicator"]], [[1, "colspan", 0], [2, "it-focused", null], [2, "it-editing", null], [2, "it-read-only", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cellClick(_v.parent.parent.context.$implicit, _v.context.index, _v.parent.parent.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_42)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_4 = _v.parent.parent.context.$implicit.indicatorTypeMeta[_v.context.index]; _ck(_v, 2, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.colSpan; var currVal_1 = _co.getFocused(_v.parent.parent.context.$implicit, _v.context.index, _v.parent.parent.parent.context.$implicit); var currVal_2 = _co.getEditing(_v.parent.parent.context.$implicit, _v.context.index, _v.parent.parent.parent.context.$implicit); var currVal_3 = (_v.parent.parent.context.$implicit.indicatorTypeMeta[_v.context.index] && _v.parent.parent.context.$implicit.indicatorTypeMeta[_v.context.index].formula); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_PstBranchBuyDealEditComponent_36(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "tr", [["class", "it-row-footer"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_37)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_39)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_41)), i0.ɵdid(6, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_v.parent.context.$implicit.children.length; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.children.length; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.branch.headerRows[(_co.branch.headerRows.length - 1)]; _ck(_v, 6, 0, currVal_2); }, null); }
function View_PstBranchBuyDealEditComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_12)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_13)), i0.ɵdid(4, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_36)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.model.reportMeta.regionId && _v.context.$implicit.children.length); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.children; _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.model.reportMeta.regionId; _ck(_v, 6, 0, currVal_2); }, null); }
function View_PstBranchBuyDealEditComponent_44(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "it-title-cell-overlay"]], [[4, "left", null], [4, "right", null]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" \u0418\u0442\u043E\u0433\u043E \u043F\u043E \u043A\u0440\u0430\u044E: "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.scrollLeft - 31) + "px"); var currVal_1 = (("-" + (_co.scrollLeft - 31)) + "px"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_PstBranchBuyDealEditComponent_46(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-branch-cell-edit", [], null, null, null, i11.View_PstBranchCellEditComponent_0, i11.RenderType_PstBranchCellEditComponent)), i0.ɵdid(1, 573440, null, 0, i12.PstBranchCellEditComponent, [i4.LookupSourceService, i5.AppNavigationService, i13.PstReportService, i6.PstReportStructureService], { branch: [0, "branch"], model: [1, "model"], reportIndicator: [2, "reportIndicator"], indicatorTypeMeta: [3, "indicatorTypeMeta"], indicatorLevel: [4, "indicatorLevel"], editing: [5, "editing"], focused: [6, "focused"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.branch; var currVal_1 = _co.model; var currVal_2 = _v.parent.parent.parent.context.$implicit.indicators[_v.parent.context.index]; var currVal_3 = _v.parent.parent.parent.context.$implicit.indicatorTypeMeta[_v.parent.context.index]; var currVal_4 = _v.parent.parent.parent.context.$implicit; var currVal_5 = _co.getEditing(_v.parent.parent.parent.context.$implicit, _v.parent.context.index, _v.parent.parent.parent.context.$implicit); var currVal_6 = _co.getFocused(_v.parent.parent.parent.context.$implicit, _v.parent.context.index, _v.parent.parent.parent.context.$implicit); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_PstBranchBuyDealEditComponent_45(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "td", [["class", "it-indicator"]], [[1, "colspan", 0], [2, "it-focused", null], [2, "it-editing", null], [2, "it-read-only", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cellClick(_v.parent.parent.context.$implicit, _v.context.index, _v.parent.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_46)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_4 = _v.parent.parent.context.$implicit.indicatorTypeMeta[_v.context.index]; _ck(_v, 2, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.colSpan; var currVal_1 = _co.getFocused(_v.parent.parent.context.$implicit, _v.context.index, _v.parent.parent.context.$implicit); var currVal_2 = _co.getEditing(_v.parent.parent.context.$implicit, _v.context.index, _v.parent.parent.context.$implicit); var currVal_3 = true; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_PstBranchBuyDealEditComponent_43(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "tr", [["class", "it-row-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "td", [["colspan", "2"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u0418\u0442\u043E\u0433\u043E \u043F\u043E \u043A\u0440\u0430\u044E: "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_44)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_45)), i0.ɵdid(6, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.scrollLeft > 30); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.branch.headerRows[(_co.branch.headerRows.length - 1)]; _ck(_v, 6, 0, currVal_1); }, null); }
function View_PstBranchBuyDealEditComponent_47(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "tr", [["class", "it-row-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "td", [], [[1, "colspan", 0]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "a", [["class", "btn-link in-table-link itech-link"], ["title", "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u043E\u0431\u044A\u0435\u043A\u0442"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.searchAgent(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "clr-icon", [["shape", "plus"]], null, null, null, null, null)), i0.ɵdid(4, 16384, null, 0, i14.ClrIconCustomTag, [], null, null), (_l()(), i0.ɵted(-1, null, [" \u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u043E\u0431\u044A\u0435\u043A\u0442 "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.branch.headerRows[(_co.branch.headerRows.length - 1)].length + 8); _ck(_v, 1, 0, currVal_0); }); }
function View_PstBranchBuyDealEditComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_10)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_11)), i0.ɵdid(4, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_43)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_47)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.caption; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.children; _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.model.reportMeta.regionId; _ck(_v, 6, 0, currVal_2); var currVal_3 = _v.context.$implicit.hasEditableCells; _ck(_v, 8, 0, currVal_3); }, null); }
function View_PstBranchBuyDealEditComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "table", [["class", "it-pst-tab-col-tbl"], ["tabindex", "0"]], [[4, "width", null]], [[null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown" === en)) {
        var pd_0 = (_co.tableKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "thead", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_3)), i0.ɵdid(3, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_9)), i0.ɵdid(6, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.branch.headerRows; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.branch.rootRowsGroup; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getSuggestedTableWidth(); _ck(_v, 0, 0, currVal_0); }); }
export function View_PstBranchBuyDealEditComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { tableCtnr: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, [[1, 0], ["tableCtnr", 1]], null, 2, "div", [["class", "it-pst-tab-col-tbl-wrapper"]], null, [[null, "scroll"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("scroll" === en)) {
        var pd_0 = (_co.onTableCtnrScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstBranchBuyDealEditComponent_2)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.branch; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.branch && _co.branch.headerRows); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_PstBranchBuyDealEditComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-branch-buy-deal-edit", [], null, null, null, View_PstBranchBuyDealEditComponent_0, RenderType_PstBranchBuyDealEditComponent)), i0.ɵdid(1, 573440, null, 0, i15.PstBranchBuyDealEditComponent, [i4.LookupSourceService, i5.AppNavigationService, i13.PstReportService, i16.AddressPersonFioCacheService, i6.PstReportStructureService, i0.ChangeDetectorRef, i17.SecurityService, i18.AlertService], null, null)], null, null); }
var PstBranchBuyDealEditComponentNgFactory = i0.ɵccf("app-branch-buy-deal-edit", i15.PstBranchBuyDealEditComponent, View_PstBranchBuyDealEditComponent_Host_0, { branch: "branch", model: "model" }, {}, []);
export { PstBranchBuyDealEditComponentNgFactory as PstBranchBuyDealEditComponentNgFactory };
