
    <ng-container [formGroup]="contextFormGroup" *ngIf="contextFormGroup
    && (!queryUserMode || queryUserMode['_par_' + parameterName + '_filter_mq'])">
      <label [style.background]="filtered?'linear-gradient(to right, #90ff90, white)':undefined">
        <ng-content></ng-content>
        <div class="checkbox checkbox-inline" title="Запросить у пользователя" *ngIf="!queryUserMode">
          <input type="checkbox" id="{{'_par_' + parameterName + '_filter_mq'}}" [(ngModel)]="customFilterChecked"
                 [ngModelOptions]="{standalone: true}">
          <label for="{{'_par_' + parameterName + '_filter_mq'}}">?</label>
        </div>
        <div class="checkbox checkbox-inline" title="Значение отсутствует (не указано)" *ngIf="!queryUserMode">
          <input type="checkbox" id="{{'_par_' + parameterName + '_filter_novalue'}}" [(ngModel)]="noValueChecked"
                 [ngModelOptions]="{standalone: true}">
          <label for="{{'_par_' + parameterName + '_filter_novalue'}}">X</label>
        </div>
      </label>
      <app-text-lookup class="itech-control-xlarge" [contextControlId]="parameterName" [sorted]="true" [disabled]="customFilterChecked"
                       [contextControlName]="'_par_' + parameterName" [lookupName]="lookupName" [commentsFieldName]="'comments'"
                       [contextFormGroup]="contextFormGroup" (change)="regionIdCityIdChanged($event)"></app-text-lookup>
    </ng-container>
  