import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { MetadataService } from '../../../logic/services/metadata.service';
import { FormBuilder, Validators } from '@angular/forms';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { MetaBrowseBaseComponent } from '../../../pages/dictionary/meta-browse.base.component';

@Component({
  templateUrl: './pst-indicator-subtype-browse.component.html'
})
export class PstIndicatorSubtypeBrowseComponent extends MetaBrowseBaseComponent {

  gridColumnDefs = [
    { headerName: '№', field: 'id', width: 100 },
    { headerName: 'Наименование', field: 'caption', tooltipField: 'caption', width: 400 },
    { headerName: 'Группа', field: 'groupCaption', tooltipField: 'groupCaption', width: 300 },
  ];

  constructor(route: ActivatedRoute,
              metadataService: MetadataService,
              alertService: AlertService,
              globalWaitingOverlayService: GlobalWaitingOverlayService,
              lookupService: LookupSourceService,
              fb: FormBuilder,
              bookmarkService: BookmarkService,
              dataCachingService: DataCachingService) {
    super(route, metadataService, alertService, globalWaitingOverlayService, lookupService, fb, bookmarkService, dataCachingService);
  }

  getMetaTitle(): string {
    return 'Подвиды показателей';
  }

  getMetaName(): string {
    return 'pst-indicator-subtype';
  }

  refreshResults() {
    this.globalWaitingOverlayService.StartWaiting();

    this.metadataService.getMetadata(this.getMetaName()).subscribe({
      next: data => {
        this._searchResults = data;

        this.dataCachingService.addToCache(this.getMetaName() + '_Data', '1', data);
        this.lookupService.invalidateLookup(this.getMetaName());
        this.globalWaitingOverlayService.EndWaiting();
      }, error: () => {
        this.globalWaitingOverlayService.EndWaiting();
      }
    });
  }

  getGroupDef(row: any) {
    return {
      id: [row.id, Validators.compose([Validators.required, Validators.pattern(/^\d{1,6}$/)])],
      caption: [row.caption, Validators.required],
      groupCaption: row.groupCaption,
      isDefault: row.isDefault,
    };
  }
}
