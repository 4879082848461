<clr-tabs>
  <clr-tab>
    <button clrTabLink>Общее</button>
    <ng-template [(clrIfActive)]="model.commonTabActive">
      <clr-tab-content>
        <div class="edit-page-tab">
          <app-edit-agent-common [id]="id"></app-edit-agent-common>
        </div>
      </clr-tab-content>
    </ng-template>
  </clr-tab>
  <clr-tab>
    <button clrTabLink><clr-icon shape="building"></clr-icon>Поднадзорные объекты</button>
    <ng-template [(clrIfActive)]="model.stablesTabActive">
      <clr-tab-content>
        <div class="edit-page-tab">
          <app-edit-agent-stables [id]="id"></app-edit-agent-stables>
        </div>
      </clr-tab-content>
    </ng-template>
  </clr-tab>
  <clr-tab>
    <button clrTabLink><clr-icon shape="piggy-bank"></clr-icon>Животные<span class="itech-page-header-extras">{{showLoadedAnimalCount()}}</span></button>
    <ng-template [(clrIfActive)]="model.animalsTabActive">
      <clr-tab-content>
        <div class="edit-page-tab">
          <app-edit-agent-animals [id]="id"></app-edit-agent-animals>
        </div>
      </clr-tab-content>
    </ng-template>
  </clr-tab>
  <clr-tab>
    <button clrTabLink><clr-icon shape="e-check" class="is-solid" style="color:green"></clr-icon>Приёмы</button>
    <ng-template [(clrIfActive)]="model.ticketsTabActive">
      <clr-tab-content>
        <div class="edit-page-tab">
          <app-agent-tickets [id]="id"></app-agent-tickets>
        </div>
      </clr-tab-content>
    </ng-template>
  </clr-tab>
</clr-tabs>
