<div class="form-group" *ngIf="animals.controls.length!==0" [style.min-height.px]="!showAddManyAnimal?36:undefined" [style.padding]="!showLabel ? 0 : undefined">
  <label *ngIf="showLabel" class="required">Животные</label>
  <div class="itech-text-field itech-control-xlarge itech-text-wall itech-many-animal">
    <ng-container *ngFor="let animal of animals.controls; index as i">
      <a class="itech-link" (click)="appNavigationService.performAnimalEditing(animal.value)" style="overflow-wrap: break-word">
        {{i+1}}.&nbsp;<span [innerHTML]="animal.value|lookup:'animal':false:true:true|async"></span>
        <button *ngIf="showDeleteBtn" type="button" class="btn btn-danger btn-link itech-inline-tool-btn" title="Удалить животное"
                (click)="delete(i)" style="margin:-3px 0 0 0">
          <clr-icon shape="trash" size="12" style="transform: inherit;color: #d23b3b"></clr-icon>
        </button>
      </a>
    </ng-container>
  </div>
</div>

<div *ngIf="showAddManyAnimal" class="form-group" [style.padding]="!showLabel ? 0 : undefined">
  <label *ngIf="showLabel" [class.required]="noAnimalsSelected()">{{noAnimalsSelected()?'Животные': ''}}</label>
  <div>
    <a class="itech-link in-table-link" (click)="addManyAnimals()">Добавить зверей...</a>
  </div>
</div>
