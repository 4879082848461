import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BookmarkService } from '../../logic/services/bookmark.service';
import { ActivatedRoute } from '@angular/router';
import { DataCachingService } from '../../logic/services/data-caching.service';
import { GroupOperationsService } from '../../logic/services/group-operations.service';
import { MetadataService } from '../../logic/services/metadata.service';
import { timer } from 'rxjs/index';
import { environment } from '../../../environments/environment';
import { FormHelper } from '../../ui/controls/form-helper';
import { GroupOperationMetadataService } from '../../logic/services/group-operation-metadata.service';
import { AgentSearchModalComponent } from '../../pages/edit-agent/search/agent-search-modal.component';
import { AppNavigationService } from '../../logic/services/app-navigation.service';
var ChangeAgentIndicatorsRunnerComponent = /** @class */ (function () {
    function ChangeAgentIndicatorsRunnerComponent(bmService, fb, dataCachingService, route, groupOperationsService, groupOperationMetadataService, metadataService, navigationService) {
        this.bmService = bmService;
        this.fb = fb;
        this.dataCachingService = dataCachingService;
        this.route = route;
        this.groupOperationsService = groupOperationsService;
        this.groupOperationMetadataService = groupOperationMetadataService;
        this.metadataService = metadataService;
        this.navigationService = navigationService;
        this.OperationParamsCode = 'pstReportChangeIndicators';
        this.OperationTypeId = 21; /*Замена хозяйствующего субъекта в электронных отчетах*/
        this.model = ChangeAgentIndicatorsRunnerComponent.createNewModel();
    }
    Object.defineProperty(ChangeAgentIndicatorsRunnerComponent.prototype, "contextFormGroup", {
        get: function () {
            return this.model.form;
        },
        enumerable: true,
        configurable: true
    });
    ChangeAgentIndicatorsRunnerComponent.createNewModel = function () {
        return {
            form: undefined,
            operationId: undefined,
            operationTypeId: undefined,
            state: undefined,
            result: undefined,
            started: false,
            completed: false,
            startedFromOtherUi: false,
            newlyEnteredFile: undefined,
        };
    };
    ChangeAgentIndicatorsRunnerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.tickTimer = timer(1000, 1000);
        this.tickSub = this.tickTimer.subscribe(function (t) { return _this.checkCurrentOperationState(); });
        this.route.paramMap
            .subscribe(function (params) {
            _this.ComponentModelCacheId = 'GroupOp_' + _this.OperationParamsCode;
            var randomUniqueTag = params.get('uniqueTag');
            var existing = _this.dataCachingService.getCachedData(_this.ComponentModelCacheId, randomUniqueTag);
            if (!existing) {
                _this.model.operationId = undefined;
                _this.model.operationTypeId = _this.OperationTypeId;
                _this.model.form = _this.fb.group({
                    type: 'pstReportChangeIndicators',
                    sourceAgentId: [null, Validators.required],
                    destinationAgentId: [null, Validators.required],
                    filterRegionId: null,
                });
                _this.dataCachingService.addToCache(_this.ComponentModelCacheId, randomUniqueTag, _this.model);
            }
            else {
                _this.model = existing;
            }
            _this.addBookmark(params);
        });
    };
    ChangeAgentIndicatorsRunnerComponent.prototype.ngOnDestroy = function () {
        if (this.tickSub) {
            this.tickSub.unsubscribe();
            this.tickSub = undefined;
        }
    };
    ChangeAgentIndicatorsRunnerComponent.prototype.checkCurrentOperationState = function () {
        var _this = this;
        if (!this.model.operationId
            || (this.model.state && this.model.state.state === 3)
            || (this.model.state && this.model.state.state === 5)) {
            return;
        }
        this.groupOperationsService.getOperationStatus(this.model.operationTypeId, this.model.operationId)
            .subscribe(function (state) {
            _this.model.state = state;
            if (_this.model.state.state === 3 || _this.model.state.state === 5) {
                _this.completeOperation();
            }
        });
    };
    ChangeAgentIndicatorsRunnerComponent.prototype.runOperation = function () {
        if (!this.contextFormGroup.valid || this.model.started) {
            return;
        }
        var model = this.model;
        model.started = true;
        this.groupOperationsService.runOperation(this.model.operationTypeId, this.contextFormGroup.value, this.model.newlyEnteredFile).subscribe(function (opKey) {
            model.operationId = opKey.id;
        }, function () {
            model.started = false;
        });
    };
    ChangeAgentIndicatorsRunnerComponent.prototype.isInvalid = function (cname) {
        return FormHelper.isInvalid(this.contextFormGroup, cname);
    };
    ChangeAgentIndicatorsRunnerComponent.prototype.completeOperation = function () {
        var _this = this;
        if (this.model.completed) {
            return;
        }
        this.model.completed = true;
        this.groupOperationsService.getOperationResult(this.model.operationTypeId, this.model.operationId)
            .subscribe(function (result) {
            _this.model.result = result;
            if (_this.model.result.result && !_this.model.startedFromOtherUi) {
                _this.navigateResults();
            }
        });
    };
    ChangeAgentIndicatorsRunnerComponent.prototype.navigateResults = function () {
        this.metadataService.preloadFile(this.model.result.result).subscribe(function (fileHash) {
            window.open(environment.api + '/files/get?preloadId=' + encodeURIComponent(fileHash));
        });
    };
    ChangeAgentIndicatorsRunnerComponent.prototype.getPercProgress = function () {
        return this.model.state ? Math.floor(this.model.state.progress / 100) : 0;
    };
    ChangeAgentIndicatorsRunnerComponent.prototype.addBookmark = function (params) {
        var randomUniqueTag = params.get('uniqueTag');
        this.bmService.addGroupOperationBookmark('Замена хозяйствующего субъекта в электронных отчетах', randomUniqueTag, ['/operations/pst-change-agent-indicators']);
    };
    ChangeAgentIndicatorsRunnerComponent.prototype.searchAgent = function (fieldName) {
        var _this = this;
        this.navigationService.searchAgent(AgentSearchModalComponent).subscribe(function (val) {
            if (val) {
                _this.contextFormGroup.get(fieldName).setValue(val);
                _this.contextFormGroup.markAsDirty();
            }
        });
    };
    return ChangeAgentIndicatorsRunnerComponent;
}());
export { ChangeAgentIndicatorsRunnerComponent };
