import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { LicenseManager, AllModules, ModuleRegistry } from '@ag-grid-enterprise/all-modules';

LicenseManager.setLicenseKey('CompanyName=Infotech Ltd.,LicensedApplication=PPO ASOI,' +
  'LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-015916,' +
  'ExpiryDate=26_May_2022_[v2]_MTY1MzUxOTYwMDAwMA==6f016150668492058accf62cc937bb0a');

if (environment.production) {
  enableProdMode();
}


if (!environment.production) {
  console.log('*** BOOTSTRAP');
}
ModuleRegistry.registerModules(AllModules);
platformBrowserDynamic().bootstrapModule(AppModule);

