import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {Observable} from 'rxjs';
import {IModalBody, ModalResult} from '../../logic/services/app-navigation.service.models';
import {AppNavigationService} from '../../logic/services/app-navigation.service';
import {PstReportService} from '../services/pst-report.service';
import {Router} from '@angular/router';

@Component({
  template: `
      <div *ngIf="deps" style="height: 60vh; background-color: white; position: relative;">
          <app-ind-dep-tree-nodes [parent]="deps" [ownerReportId]="ownerReportId"
          (onNavigateReport)="navigateReport($event)"></app-ind-dep-tree-nodes>
      </div>
  `
})
export class IndicatorDependencyModalComponent implements OnInit, IModalBody<any, void> {

  deps: any;
  ownerReportId: any;

  constructor(public appNavigationService: AppNavigationService,
              private fb: FormBuilder,
              private pstReportService: PstReportService,
              private router: Router,
  ) {
  }

  ngOnInit() {
  }

  initModalBody(data: any) {
    this.ownerReportId = data.ownerReportId;
    this.pstReportService.calcDependencies(data.indicatorKey).subscribe(deps => {
      this.deps = {children: [deps], expanded: true, selected: 0};
    });
  }

  onModalAccept$(): Observable<ModalResult<void>> {
    return undefined;
  }

  navigateReport(reportId: any) {
    this.router.navigate(['pst-report-edit', reportId]);
    this.appNavigationService.customModalOpened = false;
  }
}
