import { Component, Input } from '@angular/core';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { FormHelper } from '../../../ui/controls/form-helper';
import { StableDataService } from '../../../logic/services/stable-data.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppFormGroup } from '../../../ui/controls/app-form-group';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-edit-stable-production-template',
  templateUrl: './edit-stable-production-template.component.html',
})
export class EditStableProductionTemplateComponent {

  model: any = {};
  storing = false;

  get contextFormGroup(): AppFormGroup {
    this.ensureFormGroup();
    return this.model.contextFormGroup;
  }

  private _id: number;
  @Input()
  get id(): number {
    return this._id;
  }

  set id(id: number) {
    if (this._id !== id) {
      this._id = id;
      this.refreshFormDataInternal();
    }
  }

  get templates(): FormArray {
    return this.contextFormGroup.get('templates') as FormArray;
  }

  constructor(private fb: FormBuilder,
              private stableDataService: StableDataService,
              private waitingOverlay: GlobalWaitingOverlayService,
              private dataCachingService: DataCachingService,
              public navigationService: AppNavigationService) {
    this.model.currentDate = new Date();
  }

  isInvalid(fg: FormGroup, cname: string) {
    return FormHelper.isInvalid(fg, cname);
  }

  ensureFormGroup(arr: any[] = []) {
    if (!this.model.contextFormGroup) {
      this.model.contextFormGroup = this.fb.group({
        templates: this.buildFormArray(arr)
      });
    }
  }

  buildFormArray(templatesData: any[]) {
    return this.fb.array(templatesData.map(item => this.fb.group(this.getProductionTemplateGroupDef(item))));
  }

  getProductionTemplateGroupDef(val: any = {}) {
    return {
      id: val.id,
      stableId: val.stableId,
      caption: [val.caption, Validators.required],
      sourceProductTypeId: [val.sourceProductTypeId, Validators.required],
      sourceProductSubtypeId: [val.sourceProductSubtypeId, Validators.required],
      productionStableProductSubtypes: this.fb.array((val.productionStableProductSubtypes || []).map(el =>
        this.fb.group(this.getStableProductionTemplateProductBatchGroupDef(el)))),
      expanded: false,
    };
  }

  getStableProductionTemplateProductBatchGroupDef(val: any = {}) {
    return {
      stableProductionTemplateId: val.stableProductionTemplateId,
      stableProductSubtypeId: val.stableProductSubtypeId,
      unitId: val.unitId,
      batchId: val.batchId,
    };
  }

  refreshFormData() {
    this.dataCachingService.removeCachedData('EditStableProductionTemplate', this.id.toString());
    this.dataCachingService.removeCachedDataByStartKey('EditStableProductionTemplateProductionStableProductSubtypes',
      this.id + '-');
    this.refreshFormDataInternal();
  }

  refreshFormDataInternal() {
    this.waitingOverlay.StartWaiting();

    const existing = this.dataCachingService.getCachedData('EditStableProductionTemplate', this.id.toString());

    if (existing) {
      this.model = existing;
      this.waitingOverlay.EndWaiting();
    } else {
      this.stableDataService.getProductionTemplate(this.id).subscribe(data => {
        this.model = {id: this.id, delete: []};
        this.ensureFormGroup(data);
        this.dataCachingService.addToCache('EditStableProductionTemplate', this.id.toString(), this.model);
        this.dataCachingService.removeCachedDataByStartKey('EditStableProductionTemplateProductionStableProductSubtypes',
          this.id + '-');
        (data || []).forEach(el => {
          let existingEl = this.dataCachingService.getCachedData('EditStableProductionTemplateProductionStableProductSubtypes',
            `${this.id}-${el.sourceProductTypeId}-${el.sourceProductSubtypeId}`);
          if (!existingEl) {
            this.dataCachingService.addToCache('EditStableProductionTemplateProductionStableProductSubtypes',
              `${this.id}-${el.sourceProductTypeId}-${el.sourceProductSubtypeId}`, []);
            existingEl = this.dataCachingService.getCachedData('EditStableProductionTemplateProductionStableProductSubtypes',
              `${this.id}-${el.sourceProductTypeId}-${el.sourceProductSubtypeId}`);
          }
          existingEl.push(...el.productionStableProductSubtypes);
        });
        this.waitingOverlay.EndWaiting();
      }, () => {
        this.waitingOverlay.EndWaiting();
      });
    }
  }

  deleteTemplate(item: FormGroup, index: number) {
    const idToDelete = this.templates.controls[index].get('id').value;
    if (idToDelete) {
      this.model.delete.push(item.value);
    }

    this.templates.removeAt(index);
    this.templates.markAsDirty();
  }

  addTemplate() {
    const fg = this.fb.group(this.getProductionTemplateGroupDef());
    this.templates.push(fg);
    FormHelper.markAsSubmitted(fg);
    this.contextFormGroup.markAsDirty();
  }

  store() {
    FormHelper.markAsSubmitted(this.contextFormGroup);
    if (!this.contextFormGroup.valid) {
      return;
    }

    this.storing = true;

    this.stableDataService.storeProductionTemplate(this.id, this.templates.value, this.model.delete)
      .pipe(finalize(() => this.storing = false))
      .subscribe({
        next:
          updated => {
            this.model = {id: this.id, delete: []};
            this.ensureFormGroup(updated);
            this.dataCachingService.addToCache('EditStableProductionTemplate', this.id.toString(), this.model);
            FormHelper.setSingleFormGroupServerSideValidationErrors({}, this.model, this.contextFormGroup);
          },
        error:
          err => {
            FormHelper.setSingleFormGroupServerSideValidationErrors(err, this.model, this.contextFormGroup);
          }
      });
  }

  addProductBatch(item: FormGroup) {
    (item.get('productionStableProductSubtypes') as FormArray).push(this.fb.group(this.getStableProductionTemplateProductBatchGroupDef()));
    this.templates.markAsDirty();
    this.contextFormGroup.markAsDirty();
  }

  cancelEdit() {
    this.refreshFormData();
  }
}
