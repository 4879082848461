import { Component, Input } from '@angular/core';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { StringHelper } from '../../../helpers/string-helper';
import { StableDataService } from '../../../logic/services/stable-data.service';
import { DateHelper } from '../../../helpers/date-helper';

@Component({
  selector: 'app-edit-stable-events',
  templateUrl: './edit-stable-events.component.html'
})
export class EditStableEventsComponent {

  @Input() model: any = {};

  isInvalid = FormHelper.isInvalid;

  gridColumnDefs = [
    {
      headerName: '', width: 50, excludeExport: true,
      cellRenderer: params => {
        if (!params || !params.data) {
          return '';
        }
        if (params.data['notExecuted']) {
          return '<clr-icon shape="times" style="color:silver"></clr-icon>';
        }
        if (DateHelper.isExpiredEvent(params.data['date'], params.data['datePlanning'])) {
          return '<clr-icon shape="history" style="color:red" title="Мероприятие просрочено"></clr-icon>';
        } else {
          return params.data['date'] ?
            '<clr-icon shape="check" style="color:green"></clr-icon>' :
            '<clr-icon shape="alarm-clock" style="color:silver"></clr-icon>';
        }
      }
    },
    {
      headerName: '№', field: 'id', width: 70,
    },
    {
      headerName: 'Дата (план)', field: 'datePlanning', width: 100,
      valueFormatter: params => StringHelper.getRuDate(params.value),
    },
    {
      headerName: 'Дата (факт)', field: 'date', width: 100,
      valueFormatter: params => StringHelper.getRuDate(params.value),
    },
    {
      headerName: 'Вид', field: 'eventTypeId', width: 230,
      valueFormatter: params => params.value ? this.eventTypeIdLookup[params.value] : '<не указан>',
    },
    {
      headerName: 'Заболевание', field: 'diseaseTypeId', width: 250,
      valueFormatter: params => params.value ? this.diseaseTypeIdLookup[params.value] : '<не указано>',
      cellStyle: params => {
        return {
          color: params.value && params.data.diseasePositive ? 'red' : 'silver'
        };
      },
    },
    {
      headerName: 'Препараты', field: 'drugsCaption', width: 250,
      cellStyle: params => {
        return {
          color: !params.value ? 'silver' : undefined
        };
      },
    },
  ];

  eventTypeIdLookup: any = {};
  diseaseTypeIdLookup: any = {};

  private _id: number;
  @Input()
  get id(): number {
    return this._id;
  }

  set id(id: number) {
    if (this._id !== id) {
      this._id = id;
      this.refreshFormDataInternal();
    }
  }

  constructor(private stableDataService: StableDataService,
              private dataCachingService: DataCachingService,
              private waitingOverlay: GlobalWaitingOverlayService,
              private lookupSourceService: LookupSourceService,
              public navigationService: AppNavigationService) {

    this.lookupSourceService.getLookupObj('event-type').subscribe(lookup => this.eventTypeIdLookup = lookup);
    this.lookupSourceService.getLookupObj('disease-type').subscribe(lookup => this.diseaseTypeIdLookup = lookup);
  }

  refreshFormData() {
    this.dataCachingService.removeCachedData('EditStableEvents', this.id.toString());
    this.refreshFormDataInternal();
  }

  refreshFormDataInternal() {
    this.waitingOverlay.StartWaiting();

    const existing = this.dataCachingService.getCachedData('EditStableEvents', this.id.toString());

    if (existing) {
      this.model = existing;
      this.waitingOverlay.EndWaiting();
    } else {
      this.stableDataService.readStableEvents(this.id)
        .subscribe(
          {
            next: data => {
              data.sort((a, b) => (b.date || b.datePlanning).localeCompare((a.date || a.datePlanning)));
              this.model = {searchResults: data};
              this.dataCachingService.addToCache('EditStableEvents', this.id.toString(), this.model);
              this.waitingOverlay.EndWaiting();
            },
            error: () => {
              this.waitingOverlay.EndWaiting();
            }
          });
    }
  }

  addNew() {
    this.navigationService.performStableEventCreation(this.id);
  }

  getRowStyle(params: any) {
    if (params.data && params.data.notExecuted) {
      return {color: 'silver'};
    }
  }
}
