<div class="itech-common-wrap no-pager">
  <div class="itech-v-header-content">
    <div class="itech-content-header">
      <clr-icon shape="file-group" size="36"></clr-icon>&nbsp;Подвиды продукции
    </div>

    <div style="padding: 5px; height: 90%">
      <app-grid [rowData]="_searchResults" [columnDefs]="gridColumnDefs" [uniqueGridName]="getMetaName()"
                (rowClicked)="editRow($event.data)" [getRowStyle]="getRowStyle" [exportFileName]="getMetaTitle()">
      </app-grid>
    </div>
  </div>
  <div class="itech-form-actions">
    <div>
      <button class="btn btn-link btn-primary" (click)="refreshResults()">
        <clr-icon shape="refresh" class="has-badge"></clr-icon>&nbsp;Обновить список
      </button>
    </div>
  </div>
</div>


<clr-modal [(clrModalOpen)]="editModalOpened" *ngIf="editModalOpened" [clrModalClosable]="false" [clrModalSize]="'lg'">
  <h3 class="modal-title">Редактирование записи
    <span *ngIf="currentIsNew" style="color:green"> [Новая]</span></h3>
  <div class="modal-body">
    <div class="form compact" [formGroup]="currentFormGroup"><clr-tabs>
      <clr-tab>
        <button clrTabLink>Общее</button>
        <clr-tab-content id="commonTab">
          <div class="edit-page-tab">
            <section class="form-block itech-block-normal">
              <div class="form-group">
                <label for="animalTypeId" class="required">Вид продукции</label>
                <app-combo-lookup [validationTooltipBottomLeft]="true" class="itech-control-xlarge"
                                  [contextControlId]="'productTypeId'" [sorted]="true"
                                  [contextControlName]="'productTypeId'" [disabled]="currentFormGroup.get('id').value"
                                  [lookupName]="'product-type'" [contextFormGroup]="currentFormGroup"></app-combo-lookup>
              </div>

              <div class="form-group">
                <label for="id" class="required">Ид подвида</label>
                <label for="id" aria-haspopup="true" role="tooltip"
                       class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                       [class.invalid]="isInvalid(currentFormGroup, 'id')">
                  <input type="text" id="id" placeholder="0000" class="itech-control-normal" formControlName="id"
                         [attr.disabled]="!currentIsNew && currentFormGroup.get('id').value? 1: undefined">
                  <app-validation-tooltip [input]="currentFormGroup.controls['id']"></app-validation-tooltip>
                </label>
              </div>

              <div class="form-group">
                <label for="caption" class="required">Наименование</label>
                <label for="caption" aria-haspopup="true" role="tooltip"
                       class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                       [class.invalid]="isInvalid(currentFormGroup, 'caption')">
                  <input type="text" id="caption" placeholder="Наименование" class="itech-control-xlarge"
                         formControlName="caption" [attr.disabled]="!currentIsNew && currentFormGroup.get('id').value? 1: undefined">
                  <app-validation-tooltip [input]="currentFormGroup.controls['caption']"></app-validation-tooltip>
                </label>
              </div>

              <div class="form-group">
                <label for="mercuryGuid">Guid</label>
                <label for="mercuryGuid" aria-haspopup="true" role="tooltip"
                       class="tooltip tooltip-validation tooltip-md tooltip-top-left"
                       [class.invalid]="isInvalid(currentFormGroup, 'mercuryGuid')">
                  <input type="text" id="mercuryGuid" placeholder="00000000-aaaa-bbbb-cccc-000000000000" class="itech-control-xlarge"
                         formControlName="mercuryGuid" [attr.disabled]="!currentIsNew && currentFormGroup.get('id').value? 1: undefined">
                  <app-validation-tooltip [input]="currentFormGroup.controls['mercuryGuid']"></app-validation-tooltip>
                </label>
              </div>

              <div class="form-group">
                <label for="requiredSlaughter">Требует убоя</label>
                <input type="checkbox" id="requiredSlaughter" formControlName="requiredSlaughter" clrToggle />
              </div>

              <div class="form-group">
                <label for="withoutAnimalOrProduct">Не требуется животное или сырьё</label>
                <input type="checkbox" id="withoutAnimalOrProduct" formControlName="withoutAnimalOrProduct" clrToggle />
              </div>

              <div class="form-group itech-group-whole-line" style="margin-top:15px">
                <label></label>
                <div>Форматы даты производства</div>
              </div>
              <div class="form-group itech-group-whole-line">
                <label></label>
                <app-checkbox-select class="itech-control-x-large"
                                     [contextControlName]="'supportedManufactureDateFormatIds'" [lookupName]="'product-date-format'"
                                     [contextFormGroup]="currentFormGroup"></app-checkbox-select>
              </div>

              <div class="form-group itech-group-whole-line">
                <label></label>
                <div>Форматы срока годности</div>
              </div>
              <div class="form-group itech-group-whole-line">
                <label></label>
                <app-checkbox-select class="itech-control-x-large"
                                     [contextControlName]="'supportedExpireDateFormatIds'" [lookupName]="'product-date-format'"
                                     [contextFormGroup]="currentFormGroup"></app-checkbox-select>
              </div>
              <div class="form-group">
                <label for="expertiseIndicatorTypeIds">Показатели экспертизы</label>
                <ng-container *ngIf="currentFormGroup.get('expertiseIndicatorTypeIdsLastUpdate').value; else noExpertiseIndicatorTypeIds">
                  <div id="expertiseIndicatorTypeIds" style="margin-top:10px">
                    <div>
                      Дата последнего обновления: {{getRuDate(currentFormGroup.get('expertiseIndicatorTypeIdsLastUpdate').value)}}
                      <button type="button" class="btn btn-link" (click)="clearExpertiseIndicatorTypeIds(currentFormGroup.value)"
                              style="margin:0;padding:0;min-height:20px;min-width:20px;" [title]="'Сбросить дату'">
                        <clr-icon shape="refresh"></clr-icon>
                      </button>
                    </div>
                    <div>
                      Показатели: [
                      <ng-container *ngFor="let expertiseIndicatorTypeId of currentFormGroup.get('expertiseIndicatorTypeIds').value; index as i">
                        '{{expertiseIndicatorTypeId|lookup:'expertise-indicator-type'|async}}
                        {{i != currentFormGroup.get('expertiseIndicatorTypeIds').value.length - 1 ? '\', ' : '\'' }}
                      </ng-container>]
                    </div>
                  </div>
                </ng-container>
                <ng-template #noExpertiseIndicatorTypeIds>
                  <div style="margin-top:10px">отсутствуют</div>
                </ng-template>
              </div>
            </section>
          </div>
        </clr-tab-content>
      </clr-tab>
    </clr-tabs>

    </div>
  </div>
  <div class="modal-footer itech-form-actions">
    <div>
      <button *ngIf="!currentIsNew" type="button" class="btn btn-warning-outline"
              (click)="delete(currentFormGroup.value)">
        <clr-icon shape="trash"></clr-icon>&nbsp;Удалить
      </button>
    </div>
    <div>
      <button type="button" class="btn btn-primary" (click)="addEditCompleted()">
        <clr-icon shape="check"></clr-icon>&nbsp;Сохранить
      </button>
      <button type="button" class="btn btn-warning" (click)="editModalOpened = false">
        <clr-icon shape="undo"></clr-icon>&nbsp;Отмена
      </button>
    </div>
  </div>
</clr-modal>
