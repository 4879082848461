/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./app-ind-dep-tree-nodes.component.ngfactory";
import * as i2 from "./app-ind-dep-tree-nodes.component";
import * as i3 from "@angular/common";
import * as i4 from "./indicator-dependency-modal.component";
import * as i5 from "../../logic/services/app-navigation.service";
import * as i6 from "@angular/forms";
import * as i7 from "../services/pst-report.service";
import * as i8 from "@angular/router";
var styles_IndicatorDependencyModalComponent = [];
var RenderType_IndicatorDependencyModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_IndicatorDependencyModalComponent, data: {} });
export { RenderType_IndicatorDependencyModalComponent as RenderType_IndicatorDependencyModalComponent };
function View_IndicatorDependencyModalComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["style", "height: 60vh; background-color: white; position: relative;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-ind-dep-tree-nodes", [], null, [[null, "onNavigateReport"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onNavigateReport" === en)) {
        var pd_0 = (_co.navigateReport($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_AppIndDepTreeNodesComponent_0, i1.RenderType_AppIndDepTreeNodesComponent)), i0.ɵdid(2, 49152, null, 0, i2.AppIndDepTreeNodesComponent, [], { ownerReportId: [0, "ownerReportId"], parent: [1, "parent"] }, { onNavigateReport: "onNavigateReport" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ownerReportId; var currVal_1 = _co.deps; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_IndicatorDependencyModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_IndicatorDependencyModalComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.deps; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_IndicatorDependencyModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_IndicatorDependencyModalComponent_0, RenderType_IndicatorDependencyModalComponent)), i0.ɵdid(1, 114688, null, 0, i4.IndicatorDependencyModalComponent, [i5.AppNavigationService, i6.FormBuilder, i7.PstReportService, i8.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IndicatorDependencyModalComponentNgFactory = i0.ɵccf("ng-component", i4.IndicatorDependencyModalComponent, View_IndicatorDependencyModalComponent_Host_0, {}, {}, []);
export { IndicatorDependencyModalComponentNgFactory as IndicatorDependencyModalComponentNgFactory };
