<div class="itech-common-wrap" id="interceptNoAccess">
  <div id="agent-common-content">
    <div class="itech-doc-container" *ngIf="contextFormGroup">
      <div class="itech-doc-image">
        <clr-icon shape="users"></clr-icon>
        <app-mercury-sync-status-signpost [syncStates]="syncStates" (syncStateUpdated)="refreshFormData()">
        </app-mercury-sync-status-signpost>
        <clr-icon *ngIf="contextFormGroup.get('dateRegisteredExternalRegistry').value" style="color: green"
                  shape="link" size="60" title="Зарегистрирован во внешней системе"></clr-icon>
      </div>
      <div class="itech-doc-content">
        <app-edit-agent-common-form [contextFormGroup]="contextFormGroup" [syncStates]="syncStates">
        </app-edit-agent-common-form>
      </div>
    </div>
  </div>
  <div class="itech-form-actions">
    <div>
      <button class="btn btn-secondary" (click)="refreshFormData()">
        <clr-icon shape="refresh"></clr-icon>&nbsp;Обновить
      </button>

      <app-reports-button [reportKind]="'agent'" [objId1]="id" [dropdownPosition]="'top-left'">
      </app-reports-button>

      <clr-dropdown *ngIf="contextFormGroup">
        <button class="btn btn-warning-outline" clrDropdownToggle>
          Действия
          <clr-icon shape="caret down"></clr-icon>
        </button>
        <clr-dropdown-menu *clrIfOpen [clrPosition]="'top-left'">
          <label class="dropdown-header">Выберите действие</label>
          <button class="btn btn-primary-outline" clrDropdownItem
                  (click)="navigationService.navigateJournal(undefined, 5, id, undefined)">
            <clr-icon shape="view-list"></clr-icon>&nbsp;Журнал
          </button>
          <div class="dropdown-divider"></div>
          <button class="btn btn-primary-outline" *ngIf="!contextFormGroup.get('openApiKey').value"
                  clrDropdownItem (click)="grantAccessOpenApi()">
            <clr-icon shape="connect"></clr-icon>&nbsp;Предоставить доступ к API
          </button>
          <button class="btn btn-primary-outline" *ngIf="contextFormGroup.get('openApiKey').value"
                  clrDropdownItem (click)="revokeAccessOpenApi()">
            <clr-icon shape="disconnect"></clr-icon>&nbsp;Отозвать доступ к API
          </button>
          <div class="dropdown-divider"></div>
          <button class="btn btn-warning-outline" (click)="clrModalOpen = true" clrDropdownItem>
            <clr-icon shape="shrink"></clr-icon>
            Объединить с другой карточкой
          </button>
          <button class="btn btn-warning-outline" (click)="deleteAgent()"
                  [disabled]="contextFormGroup.get('deletedTime').value" clrDropdownItem>
            <clr-icon shape="trash"></clr-icon>
            Аннулировать
          </button>
          <button class="btn btn-warning-outline" *ngIf="contextFormGroup.get('dateRegisteredExternalRegistry').value"
                  (click)="removePersonalAccountAgent()" clrDropdownItem>
            <clr-icon shape="trash"></clr-icon>
            Удалить личный кабинет ХС
          </button>
          <button class="btn btn-warning-outline" (click)="restoreDeletedAgent()"
                  [disabled]="!contextFormGroup.get('deletedTime').value" clrDropdownItem>
            <clr-icon shape="redo"></clr-icon>
            Восстановить
          </button>
          <div class="dropdown-divider"></div>
          <button class="btn btn-secondary" (click)="refreshFormData(true)"
                  [disabled]="contextFormGroup.get('deletedTime').value" clrDropdownItem>
            <clr-icon shape="refresh"></clr-icon>&nbsp;Обновить из Меркурия
          </button>
        </clr-dropdown-menu>
      </clr-dropdown>
    </div>
    <div>
      <button [clrLoading]="storing" class="btn btn-primary itech-form-save"
              [disabled]="!contextFormGroup || !contextFormGroup.dirty || storing" (click)="store()">
        <clr-icon shape="check"></clr-icon>&nbsp;Сохранить
      </button>
      <button class="btn btn-warning itech-form-cancel"
              [disabled]="!contextFormGroup || !contextFormGroup.dirty" (click)="cancelEdit()">
        <clr-icon shape="undo"></clr-icon>&nbsp;Отменить
      </button>
    </div>
  </div>
</div>

<clr-modal [(clrModalOpen)]="clrModalOpen" [clrModalSize]="'lg'">

  <h3 class="modal-title">Выбор карточки для объединения с текущей</h3>

  <div class="modal-body" style="min-height: 100px">
    <div class="form compact">
      <div class="form-group">
        <label class="required">Карточка-дубликат</label>
        <app-link-button [id]="formGroupMerge.controls['mergeSourceId'].value" [lookupName]="'agent'"></app-link-button>
        <button type="button" class="btn btn-danger btn-link itech-inline-tool-btn" title="Выбрать владельца"
                (click)="searchAgent()">
          <clr-icon shape="search"></clr-icon>
        </button>
      </div>
    </div>

    <app-doc-validation-errors [validationErrors]="formGroupMerge.docLevelServerSideValidationErrors">
    </app-doc-validation-errors>

    <div style="margin-top: 20px">
      <div class="checkbox" style="font-size: 0.5rem">
        <input type="checkbox" id="checkbox-confirm-action" [(ngModel)]="selectedCheckboxConfirmAction">
        <label for="checkbox-confirm-action" style="padding-left: 20px">
          Я внимательно посмотрел объединяемую карточку, убедился в том, что это дубликат и понимаю, что данная операция является необратимой
        </label>
      </div>
    </div>
  </div>

  <div class="modal-footer itech-form-actions">
    <div></div>
    <div>
      <button type="button" class="btn btn-primary" (click)="mergeAgent()"
              [disabled]="!selectedCheckboxConfirmAction || !formGroupMerge.get('mergeSourceId').value">Объединить</button>
      <button type="button" class="btn btn-warning" (click)="closeMergeModal()">Закрыть</button>
    </div>
  </div>

</clr-modal>
