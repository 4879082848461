import { OnChanges, SimpleChanges } from '@angular/core';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { FormBuilder } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { AddressPersonFioCacheService } from '../../../logic/services/address-person-fio-cache.service';
import { EditDrugCommonFormComponent } from './edit-drug-common-form.component';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { DrugDataService } from '../../../logic/services/drug-data.service';
var EditDrugCommonComponent = /** @class */ (function () {
    function EditDrugCommonComponent(bookmarkService, drugDataService, alertService, cache, dataCachingService, waitingOverlay, fb, navigationService) {
        this.bookmarkService = bookmarkService;
        this.drugDataService = drugDataService;
        this.alertService = alertService;
        this.cache = cache;
        this.dataCachingService = dataCachingService;
        this.waitingOverlay = waitingOverlay;
        this.fb = fb;
        this.navigationService = navigationService;
        this.model = {};
        this.storing = false;
        this.isInvalid = FormHelper.isInvalid;
    }
    Object.defineProperty(EditDrugCommonComponent.prototype, "contextFormGroup", {
        get: function () {
            return this.model ? this.model.contextFormGroup : undefined;
        },
        enumerable: true,
        configurable: true
    });
    EditDrugCommonComponent.prototype.ngOnChanges = function (changes) {
        if (this.id) {
            this.refreshFormDataInternal();
        }
    };
    EditDrugCommonComponent.prototype.store = function () {
        var _this = this;
        FormHelper.markAsSubmitted(this.contextFormGroup);
        if (!this.contextFormGroup.valid) {
            return;
        }
        this.storing = true;
        this.drugDataService.storeDrugCommonInfo(this.contextFormGroup.value).subscribe(function (val) {
            _this.storing = false;
            _this.cache.invalidateDrug(_this.id);
            FormHelper.setSingleFormGroupServerSideValidationErrors({}, _this.model, _this.contextFormGroup);
            _this.alertService.success("\u041E\u0431\u0449\u0430\u044F \u0438\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F \u043E \u043F\u0440\u0435\u043F\u0430\u0440\u0430\u0442\u0435 \u0443\u0441\u043F\u0435\u0448\u043D\u043E \u0441\u043E\u0445\u0440\u0430\u043D\u0435\u043D\u0430");
            _this.refreshFormData();
        }, function (error) {
            _this.storing = false;
            FormHelper.setSingleFormGroupServerSideValidationErrors(error, _this.model, _this.contextFormGroup);
            window.document.getElementById('drug-common-content').scrollTop = 0;
        });
    };
    EditDrugCommonComponent.prototype.cancelEdit = function () {
        this.dataCachingService.removeCachedData('EditDrugCommon', this.id.toString());
        this.refreshFormDataInternal();
    };
    EditDrugCommonComponent.prototype.refreshFormData = function () {
        this.dataCachingService.removeCachedData('EditDrugCommon', this.id.toString());
        this.refreshFormDataInternal();
    };
    EditDrugCommonComponent.prototype.refreshFormDataInternal = function () {
        var _this = this;
        this.waitingOverlay.StartWaiting();
        this.model = { id: this.id, serverSideValidationErrors: [] };
        var existing = this.dataCachingService.getCachedData('EditDrugCommon', this.id.toString());
        if (existing) {
            this.model = existing;
            this.waitingOverlay.EndWaiting();
        }
        else {
            this.drugDataService.getDrugCommonForEdit(this.id).subscribe(function (data) {
                _this.model.id = data.id;
                _this.model.contextFormGroup = EditDrugCommonFormComponent.createFormGroup(_this.fb, _this.model, data);
                _this.cache.invalidateDrug(_this.id);
                _this.dataCachingService.addToCache('EditDrugCommon', _this.id.toString(), _this.model);
                _this.bookmarkService.refreshDrugCardBookmark(_this.id);
                _this.waitingOverlay.EndWaiting();
            }, function () {
                _this.waitingOverlay.EndWaiting();
            });
        }
    };
    EditDrugCommonComponent.prototype.deleteDrug = function () {
        var _this = this;
        this.alertService.confirmModal('Вы уверены, что хотите удалить препарат?').subscribe(function () {
            _this.drugDataService.deleteDrug(_this.id).subscribe(function () {
                _this.dataCachingService.removeCachedData('EditDrugCommon', _this.id.toString());
                _this.bookmarkService.removeOpenCardBookmark(['/drug-edit'], _this.id.toString());
            });
        });
    };
    return EditDrugCommonComponent;
}());
export { EditDrugCommonComponent };
