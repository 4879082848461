import * as tslib_1 from "tslib";
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../../../ui/infrastructure/alert.service';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { MetadataService } from '../../../logic/services/metadata.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MetaBrowseBaseComponent } from '../meta-browse.base.component';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { BookmarkService } from '../../../logic/services/bookmark.service';
import { DataCachingService } from '../../../logic/services/data-caching.service';
var AddrStreetBrowseComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AddrStreetBrowseComponent, _super);
    function AddrStreetBrowseComponent(route, metadataService, alertService, globalWaitingOverlayService, lookupService, fb, bookmarkService, dataCachingService) {
        var _this = _super.call(this, route, metadataService, alertService, globalWaitingOverlayService, lookupService, fb, bookmarkService, dataCachingService) || this;
        _this.gridColumnDefs = [
            {
                headerName: 'Населенный пункт', field: 'regionIdCityId', width: 300,
                valueFormatter: function (params) { return _this.addrCityMap[params.value]; }
            },
            { headerName: '№', field: 'id', width: 100 },
            {
                headerName: 'Тип', field: 'type', width: 100,
                valueFormatter: function (params) { return _this.addrStreetTypeMap[params.value]; }
            },
            { headerName: 'Наименование', field: 'caption', width: 300 },
            { headerName: 'Код ПФР 6.2', field: 'codePfr62', width: 180 }
        ];
        _this.lookupService.getLookupObj('addr-city').subscribe(function (dict) { _this.addrCityMap = dict; });
        _this.lookupService.getLookupObj('addr-street-type').subscribe(function (dict) { _this.addrStreetTypeMap = dict; });
        return _this;
    }
    AddrStreetBrowseComponent.prototype.getMetaTitle = function () {
        return 'Улицы';
    };
    AddrStreetBrowseComponent.prototype.getMetaName = function () {
        return 'addr-street';
    };
    AddrStreetBrowseComponent.prototype.getId = function (formGroup) {
        return formGroup.get('regionId').value && formGroup.get('cityId').value && formGroup.get('id').value
            ? formGroup.get('regionId').value + '/' + formGroup.get('cityId').value + '/' + formGroup.get('id').value
            : undefined;
    };
    AddrStreetBrowseComponent.prototype.refreshResults = function () {
        var _this = this;
        this.globalWaitingOverlayService.StartWaiting();
        this.metadataService.getMetadata(this.getMetaName()).subscribe(function (data) {
            data.forEach(function (row) {
                row.regionIdCityId = row.regionId && row.cityId ? row.regionId.toString() + '-' + row.cityId.toString() : undefined;
            });
            _this._searchResults = data;
            _this.dataCachingService.addToCache(_this.getMetaName() + '_Data', '1', data);
            _this.lookupService.invalidateLookup(_this.getMetaName());
            _this.globalWaitingOverlayService.EndWaiting();
        }, function (error) {
            _this.globalWaitingOverlayService.EndWaiting();
        });
    };
    AddrStreetBrowseComponent.prototype.getGroupDef = function (row) {
        return {
            id: row.id,
            type: [row.type, Validators.required],
            regionId: [row.regionId, Validators.required],
            cityId: [row.cityId, Validators.required],
            regionIdCityId: [row.regionId && row.cityId ? row.regionId.toString() + '-' + row.cityId.toString() : undefined,
                Validators.required],
            caption: [row.caption, Validators.required],
            codePfr62: row.codePfr62,
            aoguid: row.aoguid,
        };
    };
    AddrStreetBrowseComponent.prototype.regionIdCityIdChanged = function (event) {
        var _this = this;
        setTimeout(function () {
            var matches = _this.currentFormGroup.value.regionIdCityId.match(/(\d+)-(\d+)/);
            _this.currentFormGroup.get('regionId').setValue(matches ? matches[1] : undefined);
            _this.currentFormGroup.get('cityId').setValue(matches ? matches[2] : undefined);
        }, 300);
    };
    return AddrStreetBrowseComponent;
}(MetaBrowseBaseComponent));
export { AddrStreetBrowseComponent };
