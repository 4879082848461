import { ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { LookupSourceService } from '../../logic/services/lookup-source.service';
import { FormBuilder } from '@angular/forms';
var AppCheckboxSelectComponent = /** @class */ (function () {
    function AppCheckboxSelectComponent(lookupSourceService, fb) {
        this.lookupSourceService = lookupSourceService;
        this.fb = fb;
        this.sorted = false;
        this.nameId = 'id';
        this.compact = false;
        this.selectType = false;
        this.useShort = false;
        this.items = [];
        this.itemsLookup = {};
        this.rndToken = Math.floor(Math.random() * 1000000);
        this.focusedSelectType = false;
    }
    Object.defineProperty(AppCheckboxSelectComponent.prototype, "boundArray", {
        get: function () {
            return this.contextFormGroup.get(this.contextControlName);
        },
        enumerable: true,
        configurable: true
    });
    AppCheckboxSelectComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (this.loadedLookupSorted !== this.lookupName + ':' + this.sorted) {
            this.loadedLookupSorted = this.lookupName + ':' + this.sorted;
            this.lookupSourceService.getLookup(this.lookupName).subscribe(function (lookupContent) {
                if (!_this.sorted) {
                    lookupContent.sort(function (item1, item2) { return item1[_this.nameId] - item2[_this.nameId]; });
                }
                else {
                    lookupContent.sort(function (item1, item2) { return item1.caption ? item1.caption.localeCompare(item2.caption) : 0; });
                }
                _this.itemsLookup = {};
                _this.items = lookupContent.map(function (el) {
                    var item = { data: el, checked: false };
                    _this.itemsLookup[el[_this.nameId]] = item;
                    return item;
                });
                _this.updateItemsCheckedState();
                _this.tryUpdateSelectTypeTextInput();
            });
        }
        if (changes.hasOwnProperty('contextFormGroup') || changes.hasOwnProperty('contextControlName')) {
            if (this.contextFormGroup && this.contextControlName) {
                this.updateItemsCheckedState();
                this.boundArray.valueChanges.subscribe(function () {
                    _this.updateItemsCheckedState();
                });
            }
        }
    };
    AppCheckboxSelectComponent.prototype.updateItemsCheckedState = function () {
        var _this = this;
        this.items.forEach(function (el) {
            el.checked = false;
        });
        this.boundArray.controls.forEach(function (ctrl) {
            var item = _this.itemsLookup[ctrl.value];
            if (item) {
                item.checked = true;
            }
        });
    };
    AppCheckboxSelectComponent.prototype.updateContextFormGroup = function (item) {
        var _this = this;
        if (this.selectType) {
            this.textInput.nativeElement.focus();
        }
        var index = -1;
        this.boundArray.value.forEach(function (value, ix) {
            if (value.toString() === item.data[_this.nameId].toString()) {
                index = ix;
            }
        });
        if (index === -1) {
            this.boundArray.push(this.fb.control(item.data[this.nameId].toString()));
        }
        else {
            this.boundArray.removeAt(index);
        }
        this.tryUpdateSelectTypeTextInput();
        this.boundArray.markAsDirty();
    };
    AppCheckboxSelectComponent.prototype.tryUpdateSelectTypeTextInput = function () {
        var _this = this;
        if (!this.textInput || !this.textInput.nativeElement) {
            return;
        }
        setTimeout(function () {
            if (_this.boundArray.length === 0) {
                _this.textInput.nativeElement.value = '-';
            }
            else if (_this.boundArray.length === 1) {
                _this.textInput.nativeElement.value = _this.itemsLookup[_this.boundArray.at(0).value].data.caption;
            }
            else {
                _this.textInput.nativeElement.value = 'Выбрано ' + _this.boundArray.length + ' эл.';
            }
        }, 500);
    };
    AppCheckboxSelectComponent.prototype.selectAll = function () {
        var _this = this;
        var unselectedItems = [];
        this.items.forEach(function (el) {
            if (!el.checked) {
                unselectedItems.push(_this.fb.control(el.data[_this.nameId].toString()));
                el.checked = true;
            }
        });
        if (!unselectedItems.length) {
            return;
        }
        unselectedItems.forEach(function (item) { return _this.boundArray.push(item); });
        this.boundArray.markAsDirty();
    };
    AppCheckboxSelectComponent.prototype.unselectAll = function () {
        var _this = this;
        var selectedItems = [];
        this.items.forEach(function (el) {
            if (el.checked) {
                selectedItems.push(el.data[_this.nameId].toString());
                el.checked = false;
            }
        });
        if (!selectedItems.length) {
            return;
        }
        var indexes = [];
        this.boundArray.value.forEach(function (value, ix) {
            if (selectedItems.includes(value.toString())) {
                indexes.push(ix);
            }
        });
        indexes.sort(function (a, b) { return b - a; }); // критично отсортировать перед удалением в порядке уменьшения, чтобы начать удаление с конца
        indexes.forEach(function (index) { return _this.boundArray.removeAt(index); });
        this.boundArray.markAsDirty();
    };
    AppCheckboxSelectComponent.prototype.onFocus = function () {
        clearTimeout(this.hBlurTimeout);
        this.focusedSelectType = true;
    };
    AppCheckboxSelectComponent.prototype.onBlur = function () {
        var _this = this;
        clearTimeout(this.hBlurTimeout);
        this.hBlurTimeout = setTimeout(function () { return _this.focusedSelectType = false; }, 200);
    };
    return AppCheckboxSelectComponent;
}());
export { AppCheckboxSelectComponent };
