import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { FormBuilder } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { ProductDataService } from '../../../logic/services/product-data.service';
import { StringHelper } from '../../../helpers/string-helper';
import { DateHelper } from '../../../helpers/date-helper';
import { SecurityService } from '../../../logic/services/security.service';
import { AgGridProductTransactionDetailRendererComponent } from '../../../ui/controls/ag-grid-product-transaction-detail-renderer.component';

@Component({
  selector: 'app-product-search-results-form',
  templateUrl: './product-search-results-form.component.html'
})
export class ProductSearchResultsFormComponent implements OnInit {

  @Output() rowClicked = new EventEmitter();
  @Output() selectionChanged = new EventEmitter();
  @Input() model = ProductSearchResultsFormComponent.getDefaultModel();
  @Input() refreshResultEvent = new EventEmitter();
  @Input() autoChangeExpanded = false;

  isInvalid = FormHelper.isInvalid;
  getRowStyle = ProductSearchResultsFormComponent.getRowStyle;
  gridColumnDefs = ProductSearchResultsFormComponent.getGridColumnDefs();

  detailCellRenderer = 'myDetailCellRenderer';
  frameworkComponents = { myDetailCellRenderer: AgGridProductTransactionDetailRendererComponent };
  gridIcons = {
    groupContracted: '<clr-icon shape="organization" style="cursor: pointer"></clr-icon>',
  };
  toggleProductTypeOrCaption = false;
  toggleManufactureOrExpire = true;
  expandedParams = true;

  public static getGridColumnDefs(): any[] {
    return [
      {
        headerName: '', field: '', width: 50, excludeExport: true,
        checkboxSelection: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true,
      },
      {
        headerName: '№ журнала в Меркурии', field: 'mercuryJournalNo', width: 150,
        tooltipField: 'mercuryJournalNo',
      },
      {
        headerName: '', field: 'transactionIds', width: 30, excludeExport: true,
        cellRenderer: 'agGroupCellRenderer',
        cellStyle: {'padding-left': '5px'},
      },
      {
        headerName: '', field: 'transactionTypeCaption', width: 50,
        cellRenderer: params => {
          return params && params.data && params.data.transactionType
            ? [1, 2, 3].includes(+params.data.transactionType)
              ? (+params.data.status === 2 && !params.data.currentVolume
                ? `<clr-icon shape="alarm-clock" size="10" style="position:absolute;top:3px;right:9px"></clr-icon>`
                : '') +
                `<clr-icon shape="truck" title="${params.value}"></clr-icon>`
              : `<clr-icon shape="recycle" title="${params.value}"></clr-icon>`
            : ``;
        },
        cellStyle: {'padding-left': '17px'},
      },
      {
        headerName: 'Дата', field: 'date', width: 100,
        valueFormatter: params => StringHelper.getRuDate(params.value),
      },
      {
        headerName: 'Наименование', field: 'productSubtypeIdCaption', width: 200,
        cellRenderer: params => {
          return params && params.data
            ? (+params.data.status === -100
              ? `<clr-icon shape="file-settings"></clr-icon>&nbsp;`
              : +params.data.status === -1 ? `<clr-icon shape="users"></clr-icon>&nbsp;` : ``) + params.value
            : ``;
        },
        filterParams: {
          cellRenderer: params => {
            if (params.value) {
              return '<div title="' + StringHelper.saveQuotes(params.value) + '">' + params.value + '</div>';
            } else {
              return '(Пусто)';
            }
          }
        },
        tooltipField: 'productSubtypeIdCaption',
      },
      {
        headerName: 'Номер произв. партии', field: 'batchId', width: 200, tooltipField: 'batchId',
        filterParams: {
          cellRenderer: params => {
            if (params.value) {
              return '<div title="' + StringHelper.saveQuotes(params.value) + '">' + params.value + '</div>';
            } else {
              return '(Пусто)';
            }
          }
        },
      },
      {
        headerName: 'Объем', field: 'volume', width: 120,
      },
      {
        headerName: 'Остаток', field: 'currentVolume', width: 120,
      },
      {
        headerName: 'Дата производства',
        valueGetter: params => {
          if (!params || !params.data) {
            return '';
          } else {
            return DateHelper.dateComboToStringPrettify(params.data, 'manufactureDate');
          }
        }
      },
      {
        headerName: 'Срок годности',
        valueGetter: params => {
          if (!params || !params.data) {
            return '';
          } else {
            return DateHelper.dateComboToStringPrettify(params.data, 'expireDate');
          }
        },
        cellRenderer: params => {
          return params && params.data && params.data.expireDateKind
            ? (params.data.expiredExpirationDate
              ? `<clr-icon shape="history" style="color:red" title="Просрочено"></clr-icon>&nbsp;`
              : '') + params.value
            : ``;
        },
      },
      {
        headerName: 'Место производства', field: 'producerStableCaption', width: 280, tooltipField: 'producerStableCaption',
        filterParams: {
          cellRenderer: params => {
            if (params.value) {
              return '<div title="' + StringHelper.saveQuotes(params.value) + '">' + params.value + '</div>';
            } else {
              return '(Пусто)';
            }
          }
        },
      },
      {
        headerName: 'Источник', field: 'originCaption', width: 280, tooltipField: 'originCaption',
        filterParams: {
          cellRenderer: params => {
            if (params.value) {
              return '<div title="' + StringHelper.saveQuotes(params.value) + '">' + params.value + '</div>';
            } else {
              return '(Пусто)';
            }
          }
        },
      },
      {
        headerName: 'Получатель', field: 'destinationCaption', width: 280, tooltipField: 'destinationCaption',
        filterParams: {
          cellRenderer: params => {
            if (params.value) {
              return '<div title="' + StringHelper.saveQuotes(params.value) + '">' + params.value + '</div>';
            } else {
              return '(Пусто)';
            }
          }
        },
      },
      {
        headerName: 'Время создания', field: 'createdTime', width: 140,
        valueFormatter: params => StringHelper.getRuTime(params.value),
      },
    ];
  }

  public static getRowStyle(params) {
    if (!params || !params.data) {
      return;
    } else if (FormHelper.equalsSome(+params.data.status, 1, 5, 7)) {
      return {color: 'navy'};
    } else if (FormHelper.equalsSome(+params.data.status, 6)) {
      return {color: 'silver'};
    } else if (FormHelper.equalsSome(+params.data.status, 4)) {
      return {color: 'red'};
    } else if (FormHelper.equalsSome(+params.data.status, 2, 3)) {
      return {color: params.data.currentValue != null && +params.data.currentValue === 0 ? 'silver' : 'green'};
    } else if (FormHelper.equalsSome(+params.data.status, -1)) {
      return {color: +params.data.requestStatus === 1
          ? 'navy'
          : +params.data.requestStatus === 2
            ? 'green'
            :  +params.data.requestStatus === 3
              ? 'red'
              : undefined};
    }
  }

  public static getDefaultModel(fb?: FormBuilder, defaultFilterParams?: any) {
    return {
      searchResults: [],
      selectedRows: [],
      selectedRowsIds: [],
      filterFormGroup: fb ? ProductSearchResultsFormComponent.buildSearchForm(fb, defaultFilterParams) : undefined
    };
  }

  public static buildSearchForm(fb: FormBuilder, defaultFilterParams: any = {}) {

    const group = fb.group({
      agentOrStableCaption: defaultFilterParams.agentOrStableCaption,
      agentId: defaultFilterParams.agentId,
      stableId: defaultFilterParams.stableId,
      address: defaultFilterParams.address,
      mercuryDocUuid: defaultFilterParams.mercuryDocUuid,
      mercuryDocNo: defaultFilterParams.mercuryDocNo,
      statuses: fb.array(defaultFilterParams.statuses || []),
      manufactureDateFrom: defaultFilterParams.manufactureDateFrom,
      manufactureDateTo: defaultFilterParams.manufactureDateTo,
      expireDateFrom: defaultFilterParams.expireDateFrom,
      expireDateTo: defaultFilterParams.expireDateTo,
      productTypeId: defaultFilterParams.productTypeId,
      productSubtypeId: defaultFilterParams.productSubtypeId,
      stableProductSubtypeCaption: defaultFilterParams.stableProductSubtypeCaption,
      showPositiveCurrentValue: defaultFilterParams.showPositiveCurrentValue
    });

    group.get('productTypeId').valueChanges.subscribe(nextVal => {
      group.get('productSubtypeId').setValue(undefined);
    });

    return group;
  }

  constructor(private productDataService: ProductDataService,
              private globalWaitingOverlayService: GlobalWaitingOverlayService,
              public appNavigationService: AppNavigationService,
              private lookupSourceService: LookupSourceService,
              public securityService: SecurityService) {
  }

  ngOnInit() {
    this.refreshResultEvent.subscribe(() => this.refreshResults());
  }

  refreshResults() {

    if (!this.model.filterFormGroup.valid) {
      return;
    }

    this.globalWaitingOverlayService.StartWaiting();

    this.productDataService.search(this.model.filterFormGroup.value)
      .subscribe(data => {

        this.lookupSourceService.getLookupObj('product-subtype').subscribe(productSubtypeLookup => {
          this.lookupSourceService.getLookupObj('product-status').subscribe(productStatusLookup => {
            this.lookupSourceService.getLookupObj('transaction-type').subscribe(transactionTypeLookup => {
              data.forEach(el => {
                el.transactionTypeCaption = transactionTypeLookup[el.transactionType];
                el.statusCaption = productStatusLookup[el.status];
                if (el.status === -100 && el.templateCaption) {
                  el.statusCaption = 'Шаблон: ' + el.templateCaption;
                }

                if (el.stableProductSubtypeCaption) {
                  el.productSubtypeIdCaption = el.stableProductSubtypeCaption;
                } else {
                  const productSubtypeRec = productSubtypeLookup[el.productTypeId].find(ptst => ptst.id === el.productSubtypeId);
                  el.productSubtypeIdCaption = productSubtypeRec ? productSubtypeRec.caption : undefined;
                }
              });
              data.sort((a, b) => b.id - a.id);
              this.model.searchResults = data;
              this.model.selectedRows = [];
              if (this.autoChangeExpanded) {
                this.expandedParams = false;
              }
              this.globalWaitingOverlayService.EndWaiting();
            });
          });
        });
      }, () => {
        this.globalWaitingOverlayService.EndWaiting();
      });
  }

  filterEnterPressed($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      this.refreshResults();
    }
  }

  onGridSelectionChanged(params: any) {

    const selectedRows = params.api.getSelectedRows();
    this.model.selectedRows = selectedRows;

    const selectedIds = [];

    selectedRows.forEach(row => {
      selectedIds.push(row.id);
    });
    this.model.selectedRowsIds = selectedIds;

    this.selectionChanged.emit(selectedIds);
  }

  changeToggleProductTypeOrCaption() {
    this.toggleProductTypeOrCaption = !this.toggleProductTypeOrCaption;
    if (this.toggleProductTypeOrCaption) {
      this.model.filterFormGroup.get('stableProductSubtypeCaption').setValue(null);
    } else {
      this.model.filterFormGroup.get('productTypeId').setValue(null);
      this.model.filterFormGroup.get('productSubtypeId').setValue(null);
    }
  }

  changeToggleManufactureOrExpire() {
    this.toggleManufactureOrExpire = !this.toggleManufactureOrExpire;
    if (this.toggleManufactureOrExpire) {
      this.model.filterFormGroup.get('expireDateFrom').setValue(null);
      this.model.filterFormGroup.get('expireDateTo').setValue(null);
    } else {
      this.model.filterFormGroup.get('manufactureDateFrom').setValue(null);
      this.model.filterFormGroup.get('manufactureDateTo').setValue(null);
    }
  }
}
