import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { AppFormGroup } from '../../../ui/controls/app-form-group';
import { ServerSideErrorsProvider } from '../../../logic/validators/server-side-errors-provider';
import { FormHelper } from '../../../ui/controls/form-helper';
import { DateHelper } from '../../../helpers/date-helper';
import { EditQuarantineStagesComponent } from './edit-quarantine-stages.component';

@Component({
  selector: 'app-edit-quarantine-detection',
  templateUrl: './edit-quarantine-stages-detection.component.html',
  styleUrls: ['../edit-quarantine.all.css']
})
export class EditQuarantineStagesDetectionComponent implements OnChanges {

  @Input() id: number;
  @Input() currentStatusId: number;
  @Input() contextFormGroup: AppFormGroup;
  @Input() serverSideErrorsProvider: ServerSideErrorsProvider;
  @Input() isAccessEdit = false;
  @Input() noSuspicionStage = false;

  @Output() dirtyFormGroup = new EventEmitter();
  @Output() needUpdateQuarantine = new EventEmitter();

  isInvalid = FormHelper.isInvalid;
  statusDetection = EditQuarantineStagesComponent.QUARANTINE_STAGE.DETECTION;

  ngOnChanges() {
    this.contextFormGroup.valueChanges.subscribe(x => this.dirtyFormGroup.next());
  }

  markAsDirty() {
    this.contextFormGroup.markAsDirty();
    this.dirtyFormGroup.next();
  }

  getFormGroup(controlName: string) {
    return this.contextFormGroup.get(controlName) as AppFormGroup;
  }

  getDeadline(controlName: string, maxHour: number) {
    return this.hasDeadline(controlName)
      ? DateHelper.addMinutes(new Date(this.contextFormGroup.get('resultFromLaboratoryDateUpload').value), maxHour * 60)
      : null;
  }

  hasDeadline(controlName: string) {
    return this.contextFormGroup.get('resultFromLaboratoryDateUpload').value &&
           this.contextFormGroup.get('resultFromLaboratoryId').value != 2 &&
           !this.contextFormGroup.get(controlName).value;
  }
}
