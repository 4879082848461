
    <ng-container *ngIf="model.condition.data.relationType" [formGroup]="contextFormGroup">

      <h1>Параметры связи</h1>

      <div class="form-group">
        <label class="required">
          Арность связи с/по
        </label>
        <label aria-haspopup="true" role="tooltip"
               class="tooltip tooltip-validation tooltip-md tooltip-right"
               [class.invalid]="isInvalid('arityFrom')">
          <input formControlName="arityFrom" type="text" class="itech-control-small">
          <app-validation-tooltip
            [input]="contextFormGroup.controls['arityFrom']"></app-validation-tooltip>
        </label>
        <label aria-haspopup="true" role="tooltip"
               class="tooltip tooltip-validation tooltip-md tooltip-right"
               [class.invalid]="isInvalid('arityTo')">
          <input formControlName="arityTo" type="text" class="itech-control-small">
          <app-validation-tooltip
            [input]="contextFormGroup.controls['arityTo']"></app-validation-tooltip>
        </label>
      </div>

      <h1>Параметры фильтрации</h1>

    </ng-container>

    <ng-container [formGroup]="contextFormGroup">

      <div class="form-group itech-group-whole-line">
        <label for="filterRelatedToInstitution"></label>
        <div class="checkbox">
          <input type="checkbox" id="filterRelatedToInstitution" formControlName="filterRelatedToInstitution">
          <label for="filterRelatedToInstitution">Учитывать только объекты, связанные с учреждением</label>
        </div>
      </div>
    </ng-container>
  