
      <div [formGroup]="contextFormGroup">
          <label for="day + {{controlNameDate +'Day_' + rndToken}}" [class.required]="isRequiredDayAndHour(contextFormGroup)">День</label>
          <label for="day + {{controlNameDate +'Day_' + rndToken}}" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md"
                 [class.invalid]="isInvalid(controlNameDate +'Day')">
              <input type="number" min="1" max="31" id="day + {{controlNameDate +'Day_' + rndToken}}" class="itech-control-small"
                     formControlName="{{controlNameDate +'Day'}}" style="width:70px;padding:0;text-align:center">
          </label>
          &nbsp;&nbsp;&nbsp;
          <label for="month + {{controlNameDate +'Month_' + rndToken}}" class="required">Месяц</label>
          <label for="month + {{controlNameDate +'Month_' + rndToken}}" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md"
                 [class.invalid]="isInvalid(controlNameDate +'Month')">
              <input type="number" min="1" max="12" id="month + {{controlNameDate +'Month_' + rndToken}}" class="itech-control-small"
                     formControlName="{{controlNameDate +'Month'}}" style="width:70px;padding:0;text-align:center">
          </label>
          &nbsp;&nbsp;&nbsp;
          <label for="year + {{controlNameDate +'Year_' + rndToken}}" class="required">Год</label>
          <label for="year + {{controlNameDate +'Year_' + rndToken}}" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md"
                 [class.invalid]="isInvalid(controlNameDate +'Year')">
              <input type="number" min="1917" id="year + {{controlNameDate +'Year_' + rndToken}}" class="itech-control-small"
                     formControlName="{{controlNameDate +'Year'}}" style="width:70px;padding:0;text-align:center">
          </label>
          &nbsp;&nbsp;&nbsp;
          <label *ngIf="withTime" for="hour + {{controlNameDate +'Hour_' + rndToken}}"
                 [class.required]="isRequiredDayAndHour(contextFormGroup)">Час</label>
          <label *ngIf="withTime" for="hour + {{controlNameDate +'Hour_' + rndToken}}" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-validation tooltip-md" [class.invalid]="isInvalid(controlNameDate +'Hour')">
              <input type="number" min="0" max="23" id="hour + {{controlNameDate +'Hour_' + rndToken}}" class="itech-control-small"
                     formControlName="{{controlNameDate +'Hour'}}" style="width:75px;padding:0;text-align:center">
          </label>
      </div>