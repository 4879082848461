import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GlobalWaitingOverlayService } from '../../../ui/infrastructure/global-waiting-overlay.service';
import { AppNavigationService } from '../../../logic/services/app-navigation.service';
import { LookupSourceService } from '../../../logic/services/lookup-source.service';
import { AgentDataService } from '../../../logic/services/agent-data.service';
import { FormBuilder, Validators } from '@angular/forms';
import { FormHelper } from '../../../ui/controls/form-helper';
import { SecurityService } from '../../../logic/services/security.service';
import { CreateAgentModalComponent } from '../create-agent-modal.component';
import { StringHelper } from '../../../helpers/string-helper';

@Component({
  selector: 'app-agent-search-results-form',
  templateUrl: './agent-search-results-form.component.html'
})
export class AgentSearchResultsFormComponent implements OnInit {

  public static EXCLUDE_CAPTIONS = {
    CURRENT_OPEN: 'Из результатов исключена запись текущей открытой карточки',
  };

  @Output() rowClicked = new EventEmitter();
  @Input() model = AgentSearchResultsFormComponent.getDefaultModel();
  @Input() availableChangeStableId = true;

  processMoneyKeypress = FormHelper.processMoneyKeypress;

  toggleCaptionOrGuid = true;
  isInvalid = FormHelper.isInvalid;

  gridColumnDefs = [
    {
      headerName: '№', field: 'id', width: 100,
      cellRenderer: params => {
        if (params.data.mercurySearchedUuid && !params.data.id) {
          return '<span class="label label-info">Меркурий</span>';
        } else if (params.data.mercurySearchedUuid && params.data.id) {
          return '<clr-icon shape="check" style="color: green"></clr-icon>&nbsp;' + params.value;
        } else {
          return params.value;
        }
      }
    },
    {
      headerName: 'Наименование', field: 'shortCaption', width: 370,
      filterParams: {
        cellRenderer: params => {
          if (params.value) {
            return '<div title="' + StringHelper.saveQuotes(params.value) + '">' + params.value + '</div>';
          } else {
            return '(Пусто)';
          }
        }
      },
      cellRenderer: params => {
        if (!params.data.mercurySearchedUuid || params.data.id) {
          let imgSrc = '/assets/images/32x32/house.png';
          if (params.data.legalFormId === 45 || params.data.legalFormId === 0) {
            imgSrc = '/assets/images/32x32/user.png';
          } else if (params.data.legalFormId === 10) {
            imgSrc = '/assets/images/32x32/users_3.png';
          }
          return '<img style="width: 20px; height: 20px;" src="' + imgSrc + '"/>' + '&nbsp;' + params.value;
        } else {
          return params.value;
        }
      }
    },
    {
      headerName: 'Адрес', field: 'address', width: 350,
      filterParams: {
        cellRenderer: params => {
          if (params.value) {
            return '<div title="' + StringHelper.saveQuotes(params.value) + '">' + params.value + '</div>';
          } else {
            return '(Пусто)';
          }
        }
      },
    },
    {
      headerName: 'Телефон', field: 'phone', width: 140,
    },
    {
      headerName: 'EMail', field: 'email', width: 140,
    },
    {
      headerName: 'Орг. форма', field: 'legalFormId', width: 180,
      valueFormatter: params => params.value ? this.legalFormIdLookup[params.value] : '<не указана>',
      filterParams: {
        cellRenderer: params => {
          if (params.value) {
            return '<div title="' + StringHelper.saveQuotes(this.legalFormIdLookup[params.value]) +
              '">' + this.legalFormIdLookup[params.value] + '</div>';
          } else {
            return '(Пусто)';
          }
        }
      },
    },
  ];

  legalFormIdLookup: any = {};

  public static getDefaultModel(fb?: FormBuilder, defaultFilterParams?: any) {
    return {
      availableCreateAgent: defaultFilterParams ? defaultFilterParams.availableCreateAgent || false : false,
      searchResults: [],
      filterFormGroup: fb ? AgentSearchResultsFormComponent.buildSearchForm(fb, defaultFilterParams) : undefined
    };
  }

  public static buildSearchForm(fb: FormBuilder, defaultFilterParams: any = {}) {

    return fb.group({
      id: [defaultFilterParams.id, Validators.pattern(/^\d{1,10}$/)],
      caption: defaultFilterParams.caption,
      fio: defaultFilterParams.fio,
      address: defaultFilterParams.address,
      inn: defaultFilterParams.inn,
      cadNo: defaultFilterParams.cadNo,
      stableId: defaultFilterParams.stableId,
      availableChangeStableId: defaultFilterParams.availableChangeStableId,
      guid: defaultFilterParams.guid,
      forceAutoStart: defaultFilterParams.forceAutoStart || false,
      excludeId: defaultFilterParams.excludeId,
      excludeCaption: defaultFilterParams.excludeCaption,
      showDeleted: defaultFilterParams.showDeleted
    });
  }
  @Input() createdAgentCallback = (data) => {};

  constructor(private agentDataService: AgentDataService,
              private globalWaitingOverlayService: GlobalWaitingOverlayService,
              public appNavigationService: AppNavigationService,
              private lookupSourceService: LookupSourceService,
              public securityService: SecurityService) {

    this.lookupSourceService.getLookupObj('legal-form').subscribe(lookup => this.legalFormIdLookup = lookup);
  }

  ngOnInit() {
    if (this.model.filterFormGroup && this.model.filterFormGroup.get('forceAutoStart').value) {
      this.refreshResults();
    }
  }

  refreshResults() {

    if (!this.model.filterFormGroup.valid) {
      return;
    }

    this.globalWaitingOverlayService.StartWaiting();

    this.agentDataService.search(this.model.filterFormGroup.value)
      .subscribe({
          next: data => {
            this.model.searchResults = this.model.filterFormGroup.get('excludeId').value
              ? data.filter(x => +x.id !== +this.model.filterFormGroup.get('excludeId').value)
              : data;
            this.globalWaitingOverlayService.EndWaiting();
          },
          error: () => {
            this.globalWaitingOverlayService.EndWaiting();
          }
        });
  }

  filterEnterPressed($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      this.refreshResults();
    }
  }

  getRowStyle(params: any) {
    if (params.data && params.data.deletedTime) {
      return {color: 'silver'};
    }
  }

  changeToggleCaptionOrGuid() {
    this.toggleCaptionOrGuid = !this.toggleCaptionOrGuid;
    if (this.toggleCaptionOrGuid) {
      this.model.filterFormGroup.get('guid').setValue(null);
    } else {
      this.model.filterFormGroup.get('caption').setValue(null);
      this.model.filterFormGroup.get('id').setValue(null);
    }
  }

  performAgentCreationModal() {
    this.appNavigationService.customModalOpened = false;
    setTimeout(() =>
      this.appNavigationService.createAgentModal(CreateAgentModalComponent)
        .subscribe(val => {
          if (val && this.createdAgentCallback) {
            this.createdAgentCallback(val);
          }
        }), 100);
  }
}
