import { Component } from '@angular/core';
import { IModalBody, ModalResult } from '../../logic/services/app-navigation.service.models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormHelper } from '../../ui/controls/form-helper';
import { Observable, of } from 'rxjs';

@Component({
  template: `
<div class="form compact" *ngIf="currentFormGroup" [formGroup]="currentFormGroup">
  <ng-container *ngFor="let control of currentFormGroup.controls | keyvalue">
    <div class="form-group">
      <label>{{(params[control.key] || {}).caption}}</label>
      <ng-container *ngIf="+((params[control.key] || {}).type) === 1">
        <label [for]="control.key" aria-haspopup="true" role="tooltip"
               class="tooltip tooltip-validation tooltip-md"
               [class.invalid]="isInvalid(currentFormGroup, control.key)">
          <input type="date" [id]="control.key" class="itech-control-normal" [formControlName]="control.key">
          <app-validation-tooltip [input]="currentFormGroup.controls[control.key]"></app-validation-tooltip>
        </label>
      </ng-container>
    </div>
  </ng-container>
</div>
  `
})
export class QueryViewParamsComponent implements IModalBody<any, any> {

  currentFormGroup: FormGroup;
  params: {};
  isInvalid = FormHelper.isInvalid;

  constructor(private fb: FormBuilder) {
  }

  initModalBody(data: any) {
    this.params = {};
    this.currentFormGroup = this.buildFormGroup(data);
  }

  buildFormGroup(data: any[]): FormGroup {
    if (!data || !data.length) {
      return undefined;
    }
    const fg = this.fb.group({});
    data.forEach(param => {
      if (param.dataType && param.key) {
        if (+param.dataType === 1) {
          fg.setControl(param.key,
            this.fb.control('', Validators.compose([Validators.required, FormHelper.validateDateTimePicker])));
        } else {
          fg.setControl(param.key, this.fb.control('', Validators.compose([Validators.required])));
        }
        this.params[param.key] = {
          type: param.dataType,
          caption: param.caption || '-',
        };
      }
    });

    return fg;
  }

  onModalAccept$(): Observable<ModalResult<any>> {

    FormHelper.markAsSubmitted(this.currentFormGroup);

    if (!this.currentFormGroup.valid) {
      return of(new ModalResult(false, undefined));
    }

    return of(new ModalResult(true, this.currentFormGroup.value));
  }
}
