import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AgGridLocalization } from './ag-grid-localization';
import { DataCachingService } from '../../logic/services/data-caching.service';
import { Required } from '../infrastructure/app-decotators';
import { StatusPanelDef } from '@ag-grid-community/core/dist/es6/interfaces/iStatusPanel';
import { AgGridLookupRendererComponent } from './ag-grid-lookup-renderer.component';
import { AgGridButtonStatusBarComponent } from './ag-grid-button-status-bar.component';
import { SecurityService } from '../../logic/services/security.service';
import { ColumnApi } from '@ag-grid-community/core';

@Component({
  selector: 'app-grid',
  template: `
      <ag-grid-angular #agGrid style="width: 100%; height: 100%;" class="ag-theme-balham" [pagination]="pagination"
                       [localeTextFunc]="agGridLocaleTextFunc" [getRowStyle]="getRowStyle" [suppressPropertyNamesCheck]="true"
                       [defaultColDef]="defaultColDef ? defaultColDef : defColDef" (gridReady)="onGridReady($event)"
                       (sortChanged)="sortedChange()" (filterChanged)="filterChange()" (columnPinned)="columnPinnedChange()"
                       (columnVisible)="visibleChange()" (columnMoved)="columnMovedChange()"
                       (rowSelected)="rowSelected.emit($event)" (columnResized)="columnResizeChange()"
                       [rowData]="rowData || []" [overlayNoRowsTemplate]="'Нет данных для отображения'" [enableBrowserTooltips]="true"
                       [rowMultiSelectWithClick]="rowMultiSelectWithClick" [rowSelection]="rowSelection"
                       (rowClicked)="rowClickedEvent($event)" (rowDoubleClicked)="rowDoubleClickedEvent($event)"
                       (cellClicked)="cellClicked.emit($event)" (selectionChanged)="selectionChanged.emit($event)"
                       [columnDefs]="columnDefs" [getRowNodeId]="getRowNodeId" (gridSizeChanged)="gridSizeChanged.emit($event)"
                       [frameworkComponents]="frameworkComponents" [paginationAutoPageSize]="paginationAutoPageSize"
                       [masterDetail]="true" [detailRowHeight]="detailRowHeight" [detailCellRenderer]="detailCellRenderer"
                       [icons]="icons" [autoGroupColumnDef]="autoGroupColumnDef" [groupMultiAutoColumn]="true" [groupUseEntireRow]="true"
                       [groupRowRenderer]="groupRowRenderer" [groupRowRendererParams]="groupRowRendererParams"
                       [excelStyles]="excelStyles" (rowDataChanged)="rowDataChanged($event)" [statusBar]="statusBar"
                       (contextmenu)="clickContextMenu($event)" [suppressContextMenu]="true" [preventDefaultOnContextMenu]="true"
                       (modelUpdated)="modelUpdated.emit($event)" [suppressColumnMoveAnimation]="true"
                       [pinnedBottomRowData]="pinnedBottomRowData" [pinnedTopRowData]="pinnedTopRowData">
      </ag-grid-angular>
      <app-grid-context-menu [gridApi]="api" [uniqueGridName]="uniqueGridName"
                             [positionTop]="contextMenuPositionTop" [positionLeft]="contextMenuPositionLeft">
      </app-grid-context-menu>`
})
export class AppGridComponent implements OnInit {

  @Input() @Required uniqueGridName;

  @Input() @Required columnDefs: any[];
  @Input() rowData: any[];
  @Input() defaultColDef: any;
  @Input() getRowStyle;
  @Input() pagination = true;
  @Input() rowMultiSelectWithClick = false;
  @Input() rowSelection: any;
  @Input() getRowNodeId;
  @Input() frameworkComponents: any = {};
  @Input() masterDetail = false;
  @Input() detailRowHeight = '350';
  @Input() detailCellRenderer: any;
  @Input() detailCellRendererParams: any;
  @Input() paginationAutoPageSize: boolean;
  @Input() exportFileName;
  @Input() icons: any;
  @Input() pinnedTopRowData: any[];
  @Input() pinnedBottomRowData: any[];

  @Output() gridReady = new EventEmitter();
  @Output() rowClicked = new EventEmitter();
  @Output() rowDoubleClicked = new EventEmitter();
  @Output() cellClicked = new EventEmitter();
  @Output() selectionChanged = new EventEmitter();
  @Output() gridSizeChanged = new EventEmitter();
  @Output() rowSelected = new EventEmitter();
  @Output() modelUpdated = new EventEmitter();

  agGridLocaleTextFunc = AgGridLocalization.getLocalization;

  api: any;
  columnApi: any;

  defColDef = {
    sortable: true,
    filter: true,
    resizable: true
  };

  excelStyles = [];
  includeExportColumnIds = [];

  autoGroupColumnDef = {
    autoHeight: true,
    minWidth: 600,
  };
  groupRowRenderer = 'groupRowRenderer';

  contextMenuPositionTop;
  contextMenuPositionLeft;

  statusBar: {
    statusPanels: StatusPanelDef[];
  } = {
    statusPanels: [
      {
        statusPanel: 'agTotalAndFilteredRowCountComponent',
        align: 'left',
      },
      {
        statusPanel: 'agSelectedRowCountComponent',
        align: 'left',
      },
      {
        statusPanel: 'statusBarComponent',
        align: 'right',
        statusPanelParams: {
          icon: 'pinboard',
          title: 'Сохранить настройки (порядок, видимость, сортировка, закрепление)',
          clickCallback: () => this.storeGridSettings(),
        }
      }
    ],
  };

  constructor(private dataCachingService: DataCachingService,
              private userService: SecurityService) {
  }

  ngOnInit() {
    this.excelStyles.push(this.getHeaderExcelStyle(), this.getCellExcelStyle());
    if (this.columnDefs) {
      // почему-то в версии 22.1.1 не работает по умолчанию стили для всех ячеек по ид 'cell'
      // в документации не нашел, когда была введена эта функция, поэтому сами добавим cellClass,
      // добавим здесь, чтобы в каждом columnDefs во всей программе не добавлять однотипную строку
      this.columnDefs.forEach((colDef, ix) => {
        colDef['colId'] = ix.toString();
        if (!colDef['cellClass']) {
          colDef['cellClass'] = 'cell';
        }
        if (!colDef['excludeExport']) {
          this.includeExportColumnIds.push(ix.toString());
        }
      });
    }
    this.frameworkComponents['groupRowRenderer'] = AgGridLookupRendererComponent;
    this.frameworkComponents['statusBarComponent'] = AgGridButtonStatusBarComponent;
  }

  onGridReady(params: any) {
    this.api = params.api;
    this.columnApi = params.columnApi;

    // getContextMenuItems не работает с this, поэтому в параметры сетки закидываем свои поля
    params.api['appCustomExportFileName'] = this.exportFileName;
    params.api['appCustomIncludeExportColumnIds'] = this.includeExportColumnIds;
    params.api['appCustomColDefs'] = this.columnDefs;

    this.gridReady.emit(params);
  }

  sortedChange() {
    this.dataCachingService.addToCache(this.uniqueGridName + '_SortModel', '1', this.api.getSortModel());
  }

  filterChange() {
    this.dataCachingService.addToCache(this.uniqueGridName + '_FilterModel', '1', this.api.getFilterModel());
  }

  columnPinnedChange() {
    (this.columnApi as ColumnApi).getAllGridColumns()
      .forEach(col => this.updateCacheByColId('_PinnedModel', col.getColId(), col.getPinned() || undefined));
  }

  visibleChange() {
    (this.columnApi as ColumnApi).getAllGridColumns()
      .forEach(col => this.updateCacheByColId('_VisibleModel', col.getColId(), col.isVisible() ? undefined : false));
  }

  columnMovedChange() {
    if ((this.columnApi as ColumnApi).getAllGridColumns().every((col, ix) => +col.getColId() === ix)) {
      this.dataCachingService.removeCachedData(this.uniqueGridName + '_MovedModel', '1');
    } else {
      (this.columnApi as ColumnApi).getAllGridColumns()
        .forEach((col, ix) => this.updateCacheByColId('_MovedModel', col.getColId(), ix));
    }
  }

  columnResizeChange() {
    (this.columnApi as ColumnApi).getAllColumns()
      .forEach(col => this.updateCacheByColId('_ResizeModel', col.getColId(),
        !(col.getColDef() || {}).width || col.getColDef().width !== col.getActualWidth() ? col.getActualWidth() : undefined));
  }

  updateCacheByColId(postfixCache: string, colId: string, val: any) {
    let existing = this.dataCachingService.getCachedData(this.uniqueGridName + postfixCache, '1');
    if (!existing) {
      this.dataCachingService.addToCache(this.uniqueGridName + postfixCache, '1', {});
      existing = this.dataCachingService.getCachedData(this.uniqueGridName + postfixCache, '1');
    }
    existing[colId] = val;
  }

  /**
   * Поле, по которому настраивается группировка, должно содержать проперти rowGroupingSettings
   * rowGroupingSettings в себе должен содержать поля:
   * 1. name: string - будет отображаться для наименования группировки
   * 2. renderParams: any - поле, ответственное за параметры группировки
   *
   * renderParams в себе содержит поля-индексы группировок (от 0 до ..., где 0 - первый уровень группировки, 1 - второй и т.д.)
   * каждое поле-индекс содержит настройки:
   * - captionGetter - функция для получения фиксированного значения (при наличии поля и не null значения - преимущество этому полю)
   * - fieldByGroup - имя другого поля в таблице, по которому будут группироваться строки (применяется при нескольких уровнях)
   * - lookupName (для асинхронной работы) - функция для получения имени справочника
   * - lookupValueGetter (для асинхронной работы) - функция для получения значения для добавления к lookupName
   * - isCombineKey (для асинхронной работы) - поле для пометки, что значение из получаемого справочника зависит от другого справочника
   * - getChildrenCount - функция для подсчета количества вложенных внутри группы строк (при отсутствии просто посчитает количество строк)
   */
  groupRowRendererParams = (row) => {
    const appCustomRowRendererParams = row && row.api && row.api['appCustomRowRendererParams']
                                       ? row.api['appCustomRowRendererParams']
                                       : this.dataCachingService.getCachedData(this.uniqueGridName + '_RowRendererParams', '1');

    if (!appCustomRowRendererParams) {
      return {};
    }

    const paramsByGroupIndex = appCustomRowRendererParams[row.node.rowGroupIndex];

    if (paramsByGroupIndex.isCombineKey) {
      if (!paramsByGroupIndex['captionGetter'] || !paramsByGroupIndex.captionGetter(row)) {
        const valueKeys = paramsByGroupIndex.lookupValueGetter(row).split('/');
        return AppGridComponent.getLookupGroupRowRendererParams(paramsByGroupIndex, row, paramsByGroupIndex.lookupName,
          valueKeys[1], valueKeys[0]);
      } else {
        return AppGridComponent.getLookupGroupRowRendererParams(paramsByGroupIndex, row,
          paramsByGroupIndex.captionGetter(row) ? null : paramsByGroupIndex.lookupName,
          paramsByGroupIndex.captionGetter(row));
      }
    } else {
      if (!paramsByGroupIndex['captionGetter'] || !paramsByGroupIndex.captionGetter(row)) {
        return AppGridComponent.getLookupGroupRowRendererParams(paramsByGroupIndex, row, paramsByGroupIndex.lookupName,
          paramsByGroupIndex.lookupValueGetter(row));
      } else {
        return AppGridComponent.getLookupGroupRowRendererParams(paramsByGroupIndex, row, null,
          paramsByGroupIndex.captionGetter(row));
      }
    }
  }

  static getLookupGroupRowRendererParams(paramsByGroupIndex: any, row: any, lookupName: string, value: string, value2: string = null) {
    return {
      lookupName: lookupName,
      value: value,
      value2: value2,
      captionIfNotFound: paramsByGroupIndex.captionIfNotFound || 'не указано',
      comment: paramsByGroupIndex['getChildrenCount'] && typeof paramsByGroupIndex['getChildrenCount'] === 'function' && row.node
               ? paramsByGroupIndex.getChildrenCount(row.node)
               : row.node
                 ? AppGridComponent.getChildrenCount(row.node)
                 : undefined,
    };
  }

  static getChildrenCount(node) {
    return '(' + (node.allLeafChildren ? node.allLeafChildren.length : 0) + ')';
  }

  getHeaderExcelStyle() {
    return {
      id: 'header',
      alignment: { vertical: 'Center', horizontal: 'Center', wrapText: true },
      font: {
        fontName: 'Times New Roman',
        size: 12,
        bold: true
      },
      interior: {
        color: '#f8f8f8',
        pattern: 'Solid',
      },
      borders: {
        borderBottom: this.getBorderExcelStyle(),
        borderLeft: this.getBorderExcelStyle(),
        borderRight: this.getBorderExcelStyle(),
        borderTop: this.getBorderExcelStyle()
      },
    };
  }

  getCellExcelStyle() {
    return {
      id: 'cell',
      alignment: { vertical: 'Center', horizontal: 'Center', wrapText: true },
      font: {
        fontName: 'Times New Roman',
        size: 12
      },
      borders: {
        borderBottom: this.getBorderExcelStyle(),
        borderLeft: this.getBorderExcelStyle(),
        borderRight: this.getBorderExcelStyle(),
        borderTop: this.getBorderExcelStyle()
      },
    };
  }

  getBorderExcelStyle() {
    return {
      color: '#000000',
      lineStyle: 'Continuous',
      weight: 1,
    };
  }

  rowClickedEvent($event: any) {
    if ($event && $event.data) {
      this.rowClicked.emit($event);
    }
  }

  rowDoubleClickedEvent($event: any) {
    if ($event && $event.data) {
      this.rowDoubleClicked.emit($event);
    }
  }

  rowDataChanged(event: any) {
    const existingSortParams = this.dataCachingService.getCachedData(this.uniqueGridName + '_SortModel', '1');
    const existingFilterParams = this.dataCachingService.getCachedData(this.uniqueGridName + '_FilterModel', '1');
    const existingPinnedParams = this.dataCachingService.getCachedData(this.uniqueGridName + '_PinnedModel', '1');
    const existingVisibleParams = this.dataCachingService.getCachedData(this.uniqueGridName + '_VisibleModel', '1');
    const existingMovedParams = this.dataCachingService.getCachedData(this.uniqueGridName + '_MovedModel', '1');
    const existingResizeParams = this.dataCachingService.getCachedData(this.uniqueGridName + '_ResizeModel', '1');

    if (existingSortParams) {
      event.api.setSortModel(existingSortParams);
    }
    if (existingFilterParams) {
      event.api.setFilterModel(existingFilterParams);
    }
    if (existingPinnedParams) {
      Object.keys(existingPinnedParams).forEach(colId => event.columnApi.setColumnPinned(colId, existingPinnedParams[colId]));
    }
    if (existingVisibleParams) {
      Object.keys(existingVisibleParams)
        .forEach(colId => event.columnApi.setColumnVisible(colId, !(existingVisibleParams[colId] === false)));
    }
    if (existingMovedParams) {
      Object.keys(existingMovedParams).forEach(colId => event.columnApi.moveColumn(colId, existingMovedParams[colId]));
    }
    if (existingResizeParams) {
      Object.keys(existingResizeParams).forEach(colId => event.columnApi.setColumnWidth(colId, existingResizeParams[colId]));
    }
  }

  clickContextMenu($event) {
    if (!this.api) {
      return;
    }
    this.contextMenuPositionLeft = $event.clientX;
    this.contextMenuPositionTop = $event.clientY - 5;
    this.api['isVisibleContextMenu'] = true;
  }

  storeGridSettings() {
    const store = {
      tableName: this.uniqueGridName,
      sort: JSON.stringify(this.dataCachingService.getCachedData(this.uniqueGridName + '_SortModel', '1') || {}),
      filter: JSON.stringify(this.dataCachingService.getCachedData(this.uniqueGridName + '_FilterModel', '1') || {}),
      pinned: JSON.stringify(this.dataCachingService.getCachedData(this.uniqueGridName + '_PinnedModel', '1') || {}),
      visible: JSON.stringify(this.dataCachingService.getCachedData(this.uniqueGridName + '_VisibleModel', '1') || {}),
      moved: JSON.stringify(this.dataCachingService.getCachedData(this.uniqueGridName + '_MovedModel', '1') || {}),
      widthCols: JSON.stringify(this.dataCachingService.getCachedData(this.uniqueGridName + '_ResizeModel', '1') || {}),
    };
    this.userService.storeTableSetting(store).subscribe(() => {});
  }
}
